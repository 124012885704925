import React from 'react';
import * as Yup from 'yup';
import {Listing} from '@onroadvantage/onroadvantage-api';
import {getStopPlannedTAT} from '../../../../utils';
import {
  listingSchema,
  NUMBER_ERROR_MESSAGE,
  TemplateTable,
  TemplateTableConfig,
} from '../../../../factory/template';
import {TripEditStopListRow} from './TripEditStopListRow';
import {ITripEditTripStop, useTripEditContext} from '../tripEditContext';
import {TripEditStopListActions} from './TripEditStopListActions';
import {TripEditStopListComparison} from './TripEditStopListComparison';
import {useTripEditStopListStyles} from './TripEditStopList.style';

export interface ITripEditStopForm {
  taskTemplateNodeType: Listing | null | undefined;
  siteName: Listing | null | undefined;
  departureTime: Date | null | undefined;
  totalServiceTime: number | string | null | undefined;
  totalServiceTimeChangeReason: string | null | undefined;
}

const addSchema: Yup.SchemaOf<ITripEditStopForm> = Yup.object({
  taskTemplateNodeType: listingSchema.required('Required'),
  siteName: listingSchema.required('Required'),
  departureTime: Yup.date().nullable(),
  totalServiceTime: Yup.number().typeError(NUMBER_ERROR_MESSAGE).nullable(),
  totalServiceTimeChangeReason: Yup.string().nullable(),
});

const editSchema: Yup.SchemaOf<ITripEditStopForm> = Yup.object({
  taskTemplateNodeType: listingSchema.nullable(),
  siteName: listingSchema.nullable(),
  departureTime: Yup.date().nullable(),
  totalServiceTime: Yup.number().typeError(NUMBER_ERROR_MESSAGE).nullable(),
  totalServiceTimeChangeReason: Yup.string().nullable(),
});

export const TripEditStopList: React.FC = () => {
  const classes = useTripEditStopListStyles();
  const {
    editStopSequence,
    hasEditPermission,
    stops,
    getInitialStop,
    onSortStopsEnd,
    loadStops,
    onAddStop,
    onCancelSyncStopWithActuals,
    onDeleteStop,
    onEditStop,
  } = useTripEditContext();

  const config: TemplateTableConfig<ITripEditTripStop> = React.useMemo(
    () => ({
      columns: [
        {
          name: 'siteType',
          label: 'Site Type',
          type: 'string',
          getValue: ({node}) => node?.type,
        },
        {
          name: 'taskTemplateNodeType',
          label: 'Task Template Node Type',
          type: 'string',
          model: 'NodeType',
          enableEditing: true,
          enableAutocomplete: true,
          getValue: ({id, taskTemplateNodeType}) =>
            getInitialStop(id) ? (
              <TripEditStopListComparison
                initialValue={getInitialStop(id)?.taskTemplateNodeType}
                newValue={taskTemplateNodeType}
              />
            ) : (
              taskTemplateNodeType
            ),
          getFieldValue: ({taskTemplateNodeType}) => taskTemplateNodeType,
        },
        {
          name: 'siteName',
          label: 'Site Name',
          type: 'string',
          model: 'Node',
          enableEditing: true,
          enableAutocomplete: true,
          getValue: ({id, node}) =>
            getInitialStop(id) ? (
              <TripEditStopListComparison
                initialValue={getInitialStop(id)?.node?.name}
                newValue={node?.name}
                direction="column"
              />
            ) : (
              node?.name
            ),
          getFieldValue: ({node}) => node?.name,
        },
        {
          name: 'sequence',
          label: 'Sequence',
          type: 'string',
          getValue: ({id, sequence}) =>
            getInitialStop(id) ? (
              <TripEditStopListComparison
                initialValue={getInitialStop(id)?.sequence}
                newValue={sequence}
              />
            ) : (
              sequence
            ),
          getFieldValue: ({sequence}) => sequence,
        },
        {
          name: 'externalReference',
          label: 'External Reference',
          type: 'string',
        },
        {name: 'arrivalTime', label: 'Planned Arrival Time', type: 'datetime'},
        {
          name: 'departureTime',
          label: 'Planned Departure Time',
          type: 'datetime',
          enableEditing: (row) => row?.sequence === 1,
        },
        {
          name: 'totalServiceTime',
          label: 'Planned TAT',
          type: 'string',
          enableEditing: true,
          getValue: (row) =>
            getInitialStop(row.id) ? (
              <TripEditStopListComparison
                initialValue={
                  getInitialStop(row.id)?.totalServiceTime ??
                  getStopPlannedTAT(row)
                }
                newValue={row.totalServiceTime ?? getStopPlannedTAT(row)}
                onCancel={() => onCancelSyncStopWithActuals(row.id)}
              />
            ) : (
              row.totalServiceTime ?? getStopPlannedTAT(row)
            ),
          getFieldValue: (row) =>
            row.totalServiceTime ?? getStopPlannedTAT(row),
        },
        {
          name: 'totalServiceTimeChangeReason',
          label: 'Change TAT Reason',
          type: 'string',
          enableEditing: true,
          //TODO BE work is required
          // getValue: (stop) => <TripEditStopListComments stop={stop} />,
          // getFieldValue: ({totalServiceTimeChangeReason}) =>
          //   totalServiceTimeChangeReason,
        },
      ],
      deleteMessage: (row) => ({title: 'Stop', items: {Site: row.node?.name}}),
      getRowClass: (row) => {
        const rowExists = getInitialStop(row.id);
        if (!rowExists) {
          return classes.newRow;
        }
      },
      enableRowSorting: true,
      disablePagination: true,
      enableGrouping: false,
      identifier: 'TRIP_EDIT_STOP_LIST',
    }),
    [classes.newRow, getInitialStop, onCancelSyncStopWithActuals]
  );

  const handleRefresh = React.useCallback(
    async () => await loadStops({reload: true}),
    [loadStops]
  );

  return (
    <TemplateTable
      config={config}
      list={stops ?? []}
      sortableProps={{onSortEnd: onSortStopsEnd}}
      toolbarCustomAction={<TripEditStopListActions />}
      rowComponent={TripEditStopListRow}
      onRefresh={handleRefresh}
      onInlineAdd={
        !editStopSequence && hasEditPermission ? onAddStop : undefined
      }
      onDelete={
        !editStopSequence && hasEditPermission ? onDeleteStop : undefined
      }
      onInlineEdit={
        !editStopSequence && hasEditPermission ? onEditStop : undefined
      }
      currentPage={1}
      addValidation={addSchema}
      editValidation={editSchema}
    />
  );
};
