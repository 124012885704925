import React from 'react';
import {useHistory} from 'react-router-dom';
import * as Yup from 'yup';
import {Button} from '@mui/material';
import {MasterRouteLeg} from '@onroadvantage/onroadvantage-api';
import {
  IMasterRouteLegDetails,
  MasterRouteLegContext,
} from '../MasterRouteLegContext';
import {FormikSubmit, FormikAutocompleteWithListing} from '../../../formik';
import {MasterRouteLegDetailsProps} from './MasterRouteLegDetails';
import {
  listingSchema,
  nullableListingSchema,
  TemplateForm,
} from '../../../../factory/template';

const schema: Yup.SchemaOf<IMasterRouteLegDetails> = Yup.object({
  customer: nullableListingSchema,
  fromNode: listingSchema.required('Required'),
  toNode: listingSchema.required('Required'),
});

const getInitialValues = (
  masterRouteLeg?: MasterRouteLeg | null
): IMasterRouteLegDetails | undefined => {
  if (masterRouteLeg) {
    return {
      customer: {
        value: masterRouteLeg.customerId ?? undefined,
        label: masterRouteLeg.customer?.name ?? undefined,
      },
      fromNode: {
        value: masterRouteLeg.fromNodeId ?? undefined,
        label: masterRouteLeg.fromNode?.name ?? undefined,
      },
      toNode: {
        value: masterRouteLeg.toNodeId ?? undefined,
        label: masterRouteLeg.toNode?.name ?? undefined,
      },
    };
  }
  return undefined;
};

export const MasterRouteLegDetailsForm: React.FC<
  MasterRouteLegDetailsProps
> = ({isAdd}) => {
  const history = useHistory();
  const {
    detailsRef,
    submitting,
    masterRouteLeg,
    changedWaypoints,
    onDetailsFormSubmit,
    onRefreshLeg,
  } = React.useContext(MasterRouteLegContext);
  const [initialValues, setInitialValues] =
    React.useState<IMasterRouteLegDetails>();

  const goBack = React.useCallback(() => {
    history.goBack();
  }, [history]);

  React.useEffect(() => {
    const values = getInitialValues(masterRouteLeg);

    if (values) {
      setInitialValues(values);
    } else if (isAdd) {
      setInitialValues({
        customer: {value: undefined, label: undefined},
        fromNode: {value: undefined, label: undefined},
        toNode: {value: undefined, label: undefined},
      });
    }
    return () => setInitialValues(undefined);
  }, [isAdd, masterRouteLeg, setInitialValues]);

  if (!initialValues) {
    return <div>no initial values</div>;
  }

  return (
    <TemplateForm<IMasterRouteLegDetails>
      initialValues={initialValues}
      onSubmit={onDetailsFormSubmit}
      validationSchema={schema}
      innerRef={detailsRef}
      permission={{
        name: `${isAdd ? 'Add' : 'Edit'} MasterRoute`,
        type: isAdd ? 'Add' : 'Edit',
      }}
      disableActions
    >
      <FormikAutocompleteWithListing
        name="fromNode"
        placeholder="Enter the master route leg from site"
        label="From Site"
        model="Node"
      />
      <FormikAutocompleteWithListing
        name="toNode"
        placeholder="Enter the master route leg to site"
        label="To Site"
        model="Node"
      />
      <FormikAutocompleteWithListing
        name="customer"
        placeholder="Enter the master route leg customer"
        label="Customer"
        model="Customer"
      />
      <FormikSubmit
        loading={submitting}
        permission={{
          name: `${isAdd ? 'Add' : 'Edit'} MasterRoute`,
          type: isAdd ? 'Add' : 'Edit',
        }}
        title="Save Route"
        enabled={changedWaypoints}
      />
      {isAdd && masterRouteLeg?.id && (
        <Button onClick={onRefreshLeg}>Add more</Button>
      )}
      <Button onClick={goBack}>Go back</Button>
    </TemplateForm>
  );
};
