import React from 'react';
import {TrainingCourseUserContext} from './TrainingCourseUserContext';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../../factory/template';
import {WebUserTrainingCourseDump} from '@onroadvantage/onroadvantage-api';
import {TrainingCourseContext} from '../TrainingCourseContext';

export const TrainingCourseUserList: React.FC = () => {
  const {loadList, cleanupList} = React.useContext(TrainingCourseUserContext);
  const {trainingCourse} = React.useContext(TrainingCourseContext);

  const config: TemplateTableConfig<WebUserTrainingCourseDump> = React.useMemo(
    () => ({
      columns: [
        {
          name: 'driverFirstname',
          label: 'First Name',
          type: 'string',
        },
        {
          name: 'userEmail',
          label: 'Email',
          type: 'string',
          getValue: ({userEmail}) => userEmail,
        },
        {name: 'status', label: 'Status', type: 'string'},
        {name: 'mark', label: 'Pass Mark', type: 'number', enableFilter: false},
        {
          name: 'contractCode',
          label: 'Contract Code',
          type: 'string',
        },
        {
          name: 'driverExternalEmployeeNumber',
          label: 'External Employee Number',
          type: 'string',
        },
        {
          name: 'createdAt',
          label: 'Created At',
          type: 'datetime',
          enableFilter: false,
          hidden: true,
        },
        {
          name: 'startedAt',
          label: 'Started At',
          type: 'datetime',
          enableFilter: false,
        },
        {
          name: 'updatedAt',
          label: 'Updated At',
          type: 'datetime',
          enableFilter: false,
          hidden: true,
        },
        {
          name: 'completedAt',
          label: 'Completed At',
          type: 'datetime',
          enableFilter: false,
        },
      ],
      enableFilter: true,
      enableSort: true,
      disableToolbar: true,
      deleteMessage: (row) => ({
        title: 'Training Course User',
        items: {CourseName: trainingCourse?.name, Email: row.userEmail},
      }),
      identifier: 'TRAINING_COURSE_USER_LIST',
    }),
    [trainingCourse?.name]
  );

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [loadList, cleanupList]);

  return (
    <TemplateTableWrapper config={config} context={TrainingCourseUserContext} />
  );
};
