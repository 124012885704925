import React from 'react';
import {Table, TableHeaderRow} from '@devexpress/dx-react-grid-material-ui';
import {useTableRowStyles} from './TableRow.style';

export const TableHeaderRowComponent: React.FC<Table.RowProps> = ({
  children,
  ...props
}) => {
  const classes = useTableRowStyles();
  return (
    <TableHeaderRow.Row className={classes.root} {...props}>
      {children}
    </TableHeaderRow.Row>
  );
};
