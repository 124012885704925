import {makeStyles, createStyles} from '@mui/styles';

const TableFilterRowCellStyles = () =>
  createStyles({
    root: {
      '& th:first': {
        paddingLeft: 10,
      },
      '& th:nth-of-type(1)': {
        paddingLeft: 10,
      },
    },
  });

export const useTableFilterRowCellStyles = makeStyles(
  TableFilterRowCellStyles,
  {name: 'TableFilterRowCellStyles'}
);
