import React from 'react';
import {AutocompleteOptionType, AutocompleteProps} from '../../../autocomplete';
import {FormikAutocompleteHooks} from '../../../formik';

export const fixedDurationOptions: AutocompleteOptionType[] = [
  // TODO fix these
  {
    label: 'Today',
    value: 'today',
  },
  {
    label: 'Yesterday',
    value: 'yesterday',
  },
  {
    label: 'Last 24 hours',
    value: 'last_24_hours',
  },
  {
    label: 'Last 7 Days',
    value: 'last_7_days',
  },
  {
    label: 'Last 7 Days (include today)',
    value: 'last_7_days_include_today',
  },
  {
    label: 'Week to Date',
    value: 'week_to_date',
  },
  {
    label: 'Week to Date (include today)',
    value: 'week_to_date_include_today',
  },
  {
    label: 'Previous week',
    value: 'previous_week',
  },
  {
    label: 'Month to Date',
    value: 'month_to_date',
  },
  {
    label: 'Month to Date (include today)',
    value: 'month_to_date_include_today',
  },
];

export const ReportParameterDateRange: React.FC<
  Omit<AutocompleteProps, 'options'>
> = ({...props}) => (
  <FormikAutocompleteHooks
    {...props}
    options={fixedDurationOptions}
    multiple={false}
  />
);
