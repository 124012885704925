import {decorate, observable, action} from 'mobx';
import moment from 'moment';
import {MomentService} from '../service';

class TimelineDisplay {
  constructor() {
    this.isPlaying = false;
    this.timer = null;
    this.lastTelematicsEvent = null;
    this.firstTelematicsEvent = null;
    this.adjacentTrips = [];
    this.timelineSliderTime = null;
    this.telematicsLoading = false;
    this.telematicsEventsDateRange = {
      startDate: moment().subtract(moment.duration('03:00:00')).toISOString(),
      endDate: moment().toISOString(),
    };
  }

  togglePlaying = () => {
    if (this.isPlaying) {
      this.isPlaying = false;
      clearInterval(this.timer);
    } else {
      this.isPlaying = true;
      this.nextFrameTimelineSliderTime();
      this.timer = setInterval(() => {
        this.nextFrameTimelineSliderTime();
      }, 1000);
    }
  };

  setLastTelematicsEvent = (event) => {
    this.lastTelematicsEvent = event;
  };

  setFirstTelematicsEvent = (event) => {
    this.firstTelematicsEvent = event;
  };

  setTelematicsEventsDateRange = (dateRange) => {
    this.telematicsEventsDateRange = dateRange;
  };

  setTimelineSliderTime = (timestamp) => {
    this.timelineSliderTime = timestamp;
  };

  nextFrameTimelineSliderTime = () => {
    if (this.timelineSliderTime) {
      this.setTimelineSliderTime(
        MomentService.addDuration(this.timelineSliderTime, '00:01:00')
      );
    } else {
      this.setTimelineSliderTime(this.telematicsEventsDateRange.startDate);
    }
  };

  prevFrameTimelineSliderTime = () => {
    if (this.timelineSliderTime) {
      this.setTimelineSliderTime(
        MomentService.subtractDuration(this.timelineSliderTime, '00:01:00')
      );
    } else {
      this.setTimelineSliderTime(this.telematicsEventsDateRange.startDate);
    }
  };

  setAdjacentTrips = (trips) => {
    this.adjacentTrips = trips;
  };

  setTelematicsLoading = (bool) => {
    this.telematicsLoading = bool;
  };
}
export default decorate(TimelineDisplay, {
  lastTelematicsEvent: observable,
  firstTelematicsEvent: observable,
  adjacentTrips: observable,
  timelineSliderTime: observable,
  telematicsLoading: observable,
  telematicsEventsDateRange: observable,
  setTelematicsLoading: action,
  setTelematicsEventsDateRange: action,
  setFirstTelematicsEvent: action,
  setTimelineSliderTime: action,
  prevFrameTimelineSliderTime: action,
  nextFrameTimelineSliderTime: action,
  setLastTelematicsEvent: action,
  setAdjacentTrips: action,
  isPlaying: observable,
  togglePlaying: action,
});
