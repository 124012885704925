import React from 'react';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  graph: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    height: 'calc(100vw / 1920 * 60)',
    width: 'calc(100vw / 1920 * 900)',
    borderRadius: 'calc(100vw / 1920 * 30)',
    overflow: 'hidden',
    marginBottom: 'calc(100vw / 1920 * 20)',
  },
  graphFill: {
    height: 'calc(100vw / 1920 * 60)',
  },
  graphPad: {
    borderTopRightRadius: 'calc(100vw / 1920 * 30)',
    borderBottomRightRadius: 'calc(100vw / 1920 * 30)',
  },
  labels: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  fontStyle: {
    display: 'flex',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 'calc(100vw / 1920 * 24)',
    height: 'calc(100vw / 1920 * 30)',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'calc(100vw / 1920 * 18)',
    fontStyle: 'normal',
    fontWeight: '500',
    height: 'calc(100vw / 1920 * 32)',
    width: 'calc(100vw / 1920 * 900)',
    textTransform: 'uppercase',
  },
};

const getPosition = (index, arr) => {
  if (index === 0) {
    return 'first';
  }
  if (index === arr.length - 1) {
    return 'last';
  }
  return 'middle';
};

const RiskGraphFill = ({width, color, index, splitCount}) => {
  const graphWidth = 900 - 30 * splitCount;
  const innerStyle = {
    display: 'flex',
    flexDirection: 'row',
    width: `calc(100vw / 1920 * (${
      width / 100
    } * ${graphWidth}) + (100vw / 1920 * 30)))`,
    zIndex: 100 - index,
  };

  const fillStyle = {
    ...styles.graphFill,
    backgroundColor: color,
    width: `calc(100vw / 1920 * (${width / 100} * ${graphWidth}))`,
    zIndex: 100 - index,
    marginLeft: 'calc(100vw / 1920 * -30)',
    paddingLeft: 'calc(100vw / 1920 * 30)',
  };

  const padStyle = {
    ...styles.graphPad,
    backgroundColor: color,
    height: 'calc(100vw / 1920 * 60)',
    width: 'calc(100vw / 1920 * 30)',
    zIndex: 100 - index,
  };

  return (
    <div style={innerStyle}>
      <div style={fillStyle} />
      <div style={padStyle} />
    </div>
  );
};

const RiskGraphLabel = ({width, color}) => {
  const fontStyle = {
    ...styles.fontStyle,
    color,
    flex: `calc(100 / 1920 * (${width / 100} * 900))`,
  };

  return <div style={fontStyle}>{width.toFixed(0)}%</div>;
};

const RiskGraph = ({data, title}) => (
  <div style={styles.container}>
    <div style={styles.labels}>
      {data.length === 0 ? (
        <RiskGraphLabel width={100} color="transparent" />
      ) : null}
      {data.map((i, index, arr) => (
        <RiskGraphLabel
          {...i}
          // eslint-disable-next-line react/no-array-index-key
          key={`${index}RiskGraphLabel`}
          position={getPosition(index, arr)}
          index={index}
        />
      ))}
    </div>
    <div style={styles.graph}>
      {data.length === 0 ? (
        <RiskGraphFill
          color="#8b8b8b"
          width={100}
          // eslint-disable-next-line react/no-array-index-key
          position="first"
          index={0}
          splitCount={1}
        />
      ) : null}
      {data.map((i, index, arr) => (
        <RiskGraphFill
          {...i}
          // eslint-disable-next-line react/no-array-index-key
          key={`${index}RiskGraphFill`}
          position={getPosition(index, arr)}
          index={index}
          splitCount={arr.length}
        />
      ))}
    </div>
    <div style={styles.title}>{title}</div>
  </div>
);

export default RiskGraph;
