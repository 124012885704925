import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import {Loader} from '../../../../loader';
import {CarouselProvider, Slide, Slider} from 'pure-react-carousel';
import Button from '@mui/material/Button';
import {useTripDebriefSummaryStopTaskListModalStyles} from './TripDebriefSummaryStopTaskListModal.style';
import 'pure-react-carousel/dist/react-carousel.es.css';
import Pagination from '@mui/material/Pagination';

interface TripStopTaskModalProps {
  open: boolean;
  onClose: () => void;
  content: any;
  type?: string;
  loading: boolean;
}

export const TripDebriefSummaryStopTaskListModal: React.FC<
  TripStopTaskModalProps
> = ({open, onClose, loading, content, type}) => {
  const classes = useTripDebriefSummaryStopTaskListModalStyles();
  const [renderedContent, setRenderedContent] =
    React.useState<React.ReactNode>();
  const [currentSlide, setCurrentSlide] = React.useState(0);

  const handleClose = React.useCallback(() => {
    setCurrentSlide(0);
    onClose();
  }, [setCurrentSlide, onClose]);

  React.useEffect(() => {
    if (content) {
      if (type === 'Photo' || type === 'Signature') {
        setRenderedContent(
          <CarouselProvider
            currentSlide={currentSlide}
            totalSlides={content.length}
            dragEnabled={false}
            touchEnabled={false}
            naturalSlideHeight={5}
            naturalSlideWidth={5}
          >
            <Slider>
              {content.map((tag: any, index: number) => {
                return (
                  <Slide index={index} key={index} className={classes.slide}>
                    <Typography>
                      {index + 1} of {content.length}
                    </Typography>
                    {tag}
                  </Slide>
                );
              })}
            </Slider>
          </CarouselProvider>
        );
      } else if (type === 'Checklist') {
        setRenderedContent(
          <List disablePadding>
            {JSON.parse(content).map(
              (item: any) =>
                item.itemId && (
                  <ListItem disableGutters key={item.itemId}>
                    <ListItemText
                      className={classes.listItemText}
                      primary={item.itemName}
                      secondary={item.value ? item.value.toString() : ''}
                    />
                  </ListItem>
                )
            )}
          </List>
        );
      } else if (type === 'Questionnaire')
        setRenderedContent(
          <List disablePadding>
            {JSON.parse(content).map(
              (item: any) =>
                item.itemId && (
                  <ListItem disableGutters key={item.itemId}>
                    <ListItemText
                      className={classes.listItemText}
                      primary={item.itemName}
                      secondary={item.optionName ? item.optionName : ''}
                    />
                  </ListItem>
                )
            )}
          </List>
        );
    }
    return () => setRenderedContent(undefined);
  }, [
    classes.carousel,
    classes.listItemText,
    classes.slide,
    content,
    currentSlide,
    type,
  ]);

  const handleChange = React.useCallback(
    (_event: any, value: number) => {
      setCurrentSlide(value - 1);
    },
    [setCurrentSlide]
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={type === 'Photo' || type === 'Signature' ? 'lg' : 'sm'}
      fullWidth
    >
      <DialogTitle className={classes.title}>
        <Typography variant="h5" component="h5" color="textSecondary">
          {type}
        </Typography>
      </DialogTitle>
      <DialogContent>{loading ? <Loader /> : renderedContent}</DialogContent>
      <DialogActions className={classes.actions} disableSpacing>
        <Button onClick={handleClose} color="primary" size="large">
          Close
        </Button>
        {(type === 'Photo' || type === 'Signature') && content && (
          <Pagination
            count={content.length}
            page={currentSlide + 1}
            onChange={handleChange}
          />
        )}
      </DialogActions>
    </Dialog>
  );
};
