import React from 'react';
import {MomentService} from '../../../../service';
import PopupBase from './PopupBase';
import knownunsheduled from '../../../../img/background/knownunsheduled.png';
import dangerzonebackground from '../../../../img/background/dangerzonebackground.jpg';
import {Box, Typography} from '@mui/material';

import {makeStyles, createStyles} from '@mui/styles';

const useActualStopPopupStyles = makeStyles(() =>
  createStyles({
    headingColor: {
      color: '#767676',
    },
  })
);
interface Props {
  position: any;
  actualStop: any;
}

export default function ActualStopPopup({actualStop, position}: Props) {
  const classes = useActualStopPopupStyles();

  if (actualStop != null) {
    return (
      <PopupBase
        backgroundImage={
          actualStop?.known ? knownunsheduled : dangerzonebackground
        }
        center={{header: actualStop.title}}
      >
        {actualStop.known && (
          <Box mb={1}>
            <Typography variant="h5" className={classes.headingColor}>
              Known Site Name
            </Typography>
            <Typography variant="h6">
              {actualStop.knownNodes[0].name}
            </Typography>
          </Box>
        )}
        <Box mb={1}>
          <Typography variant="h5" className={classes.headingColor}>
            Start
          </Typography>
          <Typography variant="h6">
            {MomentService.displayTimestamp(actualStop.start)}
          </Typography>
        </Box>
        <Box mb={1}>
          <Typography variant="h5" className={classes.headingColor}>
            End
          </Typography>
          <Typography variant="h6">
            {MomentService.displayTimestamp(actualStop.end)}
          </Typography>
        </Box>
        <Box mb={1}>
          <Typography variant="h5" className={classes.headingColor}>
            Duration
          </Typography>
          <Typography variant="h6">{actualStop.duration}</Typography>
        </Box>

        {actualStop.known && (
          <Box mb={1}>
            <Typography variant="h5" className={classes.headingColor}>
              Location
            </Typography>
            <Typography variant="h6">{`${position[0]},${position[1]}`}</Typography>
          </Box>
        )}
      </PopupBase>
    );
  }
  return <></>;
}
