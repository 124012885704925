import React from 'react';
import {userApi} from '../api';
import {authStore} from '../store';
import {appNotificationStore} from '../stores/mobxStores';
import history from '../service/history';

export interface AuthConnectionProps {
  serverVersion: string | null;
  loading: boolean;
  loadingText: string | null;
}

export const AuthConnectionContext = React.createContext<AuthConnectionProps>({
  serverVersion: null,
  loading: true,
  loadingText: null,
});

export const AuthConnectionContextProvider: React.FC = ({children}) => {
  const [version, setVersion] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [loadingText, setLoadingText] = React.useState<string | null>(null);

  const checkStatus = React.useCallback(async () => {
    try {
      const user = await userApi.apiUserMeGet();
      if (!user) {
        history.push(`/logout?redirectTo=${history.location.pathname}`);
      } else {
        const {email, roles, serverVersion} = await user;
        if (roles || email) {
          authStore.updateUserMe(roles ?? [], email);
        }
        if (serverVersion) {
          setVersion(
            `v${serverVersion.majorVersion ?? '-'}.${
              serverVersion.releaseNumber ?? '-'
            }.${serverVersion.buildNumber ?? '-'}.${
              serverVersion.truncatedCommitHash ?? '-'
            } | ${serverVersion.openapiVersion ?? '-'}`
          );
        }
        setLoading(false);
      }
    } catch (e) {
      const errorString = e.toString();
      if (errorString.indexOf('TypeError: Failed to fetch') > -1) {
        appNotificationStore.enqueueNotification(
          'error',
          `Check your internet connection, unable to reach resource.`
        );

        if (loading) {
          setTimeout(() => {
            window.location.reload();
          }, 10000);
        }
        setLoadingText(`${errorString}. Reloading Page in 10 seconds.`);
      } else {
        history.push(`/logout?redirectTo=${history.location.pathname}`);
      }
    }
  }, [loading, setLoading]);

  React.useEffect(() => {
    checkStatus();
  }, [checkStatus]);

  const value: AuthConnectionProps = {
    serverVersion: version,
    loading,
    loadingText,
  };

  return (
    <AuthConnectionContext.Provider value={value}>
      {children}
    </AuthConnectionContext.Provider>
  );
};
