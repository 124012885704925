import React from 'react';
import {Button, Stack, Step, StepLabel, Stepper} from '@mui/material';
import {
  eventsBulkClosingSteps,
  EventsBulkClosingSteps,
  eventsBulkClosingStepsLabels,
} from './useEventsBulkClosing';
import {useEventsBulkCosingStyles} from './EventsBulkClosing.styles';
import {useAppNotifications} from '../../contexts';
import {useEventsBulkClosingContext} from './EventsBulkClosingContext';

interface EventsBulkClosingStepperProps {
  completedSteps: EventsBulkClosingSteps[];
  activeStep: EventsBulkClosingSteps;
  onNextStep: (currentStep: EventsBulkClosingSteps) => void;
  onPreviousStep: () => void;
  onGoToStep: (targetStep: EventsBulkClosingSteps) => void;
}

export const EventsBulkClosingStepper: React.FC<
  EventsBulkClosingStepperProps
> = ({
  completedSteps,
  activeStep,
  onNextStep,
  onPreviousStep,
  onGoToStep,
  children,
}) => {
  const notify = useAppNotifications();

  // check this later and update context values

  const classes = useEventsBulkCosingStyles();
  const activeStepIndex = eventsBulkClosingSteps.indexOf(activeStep);
  const isLastStep = activeStepIndex + 1 === eventsBulkClosingSteps.length;
  const {commentInputRef, selectedEvents, onSubmit, validSteps} =
    useEventsBulkClosingContext();

  const handleValidateNextStep = React.useCallback(() => {
    const {isValid, message} = validSteps[activeStep];
    if (!isValid) {
      notify('warning', message);
    } else {
      onNextStep(activeStep);
    }
  }, [activeStep, notify, onNextStep, validSteps]);

  const handleValidateSubmit = React.useCallback(() => {
    if (
      commentInputRef.current?.value === null ||
      (commentInputRef.current?.value.trim() === '' &&
        selectedEvents &&
        selectedEvents.length > 0)
    ) {
      notify('warning', 'comment is required.');
      return;
    }

    onSubmit();
    onGoToStep('filterEvents');
  }, [commentInputRef, onGoToStep, selectedEvents, notify, onSubmit]);

  return (
    <Stack p={2} spacing={2}>
      <Stepper activeStep={activeStepIndex + 1}>
        {eventsBulkClosingSteps.map((step) => (
          <Step
            completed={completedSteps.includes(step)}
            active={activeStep === step}
            onClick={() => onGoToStep(step)}
            color="primary"
            key={step}
            classes={{completed: classes.completedStep}}
          >
            <StepLabel>{eventsBulkClosingStepsLabels[step]}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {children}
      {activeStepIndex > 0 && (
        <Stack direction="row" spacing={1}>
          <Button
            onClick={onPreviousStep}
            variant="outlined"
            disabled={activeStepIndex === 0}
          >
            Back
          </Button>
          <Button
            onClick={isLastStep ? handleValidateSubmit : handleValidateNextStep}
            variant="contained"
          >
            {isLastStep ? 'Bulk Close' : 'Next'}
          </Button>
        </Stack>
      )}
    </Stack>
  );
};
