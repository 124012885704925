import React from 'react';
import {DriverRankIncentiveForm} from './DriverRankIncentiveForm';
import {ITemplateTabControllerTab, TemplateTabs} from '../../factory/template';
import {DriverRankIncentiveContext} from './DriverRankIncentiveContext';

export const DriverRankIncentive: React.FC = () => {
  const {driverRankIncentiveId, loading} = React.useContext(
    DriverRankIncentiveContext
  );

  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {title: 'Details', element: <DriverRankIncentiveForm />},
  ]);

  const path = React.useMemo(
    () => `/driverrankincentivelist/${driverRankIncentiveId}`,
    [driverRankIncentiveId]
  );

  return <TemplateTabs tabs={tabs} path={path} loading={loading} />;
};
