import React from 'react';
import {useHistory} from 'react-router-dom';
import {
  ApiMasterRouteGetRequest,
  Listing,
  MasterRoute as MasterRouteType,
  MasterRoutePost,
  MasterRouteList,
} from '@onroadvantage/onroadvantage-api';
import {FormikHelpers, FormikProps} from 'formik/dist/types';
import {masterRouteApi} from '../../api';
import {TemplateTableContextProps} from '../../factory/template';
import {RoleService} from '../../service';
import {useAppNotifications} from '../../contexts';
import {useTemplateTable, TLoadList} from '../../factory/template';

export interface IMasterRouteDetails
  extends Omit<MasterRoutePost, 'contractId' | 'legs'> {
  contract: Listing;
}

export interface IMasterRouteDetailsCopy {
  contracts: Listing[];
}

export interface MasterRouteContextProps
  extends TemplateTableContextProps<MasterRouteType, MasterRouteList> {
  loadMasterRoute: () => Promise<MasterRouteType | undefined | null>;
  onDetailsFormSubmit: (
    values: IMasterRouteDetails,
    formikHelpers: FormikHelpers<IMasterRouteDetails>
  ) => void;
  onCopyDetailsFormSubmit: (
    values: IMasterRouteDetailsCopy,
    formikHelpers: FormikHelpers<IMasterRouteDetailsCopy>
  ) => void;
  setMasterRouteId: (value: number | undefined) => void;
  submitting: boolean;
  masterRoute?: MasterRouteType;
  masterRouteId?: number;
  detailsRef?: React.Ref<FormikProps<IMasterRouteDetails>>;
  copyDetailsRef?: React.Ref<FormikProps<IMasterRouteDetailsCopy>>;
  hasEditPermission?: boolean;
  hasAddPermission?: boolean;
}

export const MasterRouteContext = React.createContext<MasterRouteContextProps>({
  // Template Table Defaults
  loading: false,
  list: [],
  currentPage: 1,
  // MasterRoute
  loadList: async () => {},
  loadMasterRoute: async () => null,
  cleanupList: () => null,
  onDetailsFormSubmit: () => null,
  onCopyDetailsFormSubmit: () => null,
  setMasterRouteId: () => null,
  submitting: false,
  hasEditPermission: false,
  hasAddPermission: false,
});

interface MasterRouteContextProviderProps {
  masterRouteId?: number;
}

export const MasterRouteContextProvider: React.FC<
  MasterRouteContextProviderProps
> = ({children}) => {
  const history = useHistory();
  const notify = useAppNotifications();
  // Template Table
  const [
    {
      // States
      currentPage,
      filters,
      hasPermission,
      itemTotal,
      list,
      loading,
      pageSize,
      pageTotal,
      loadingSingleItem,
      sorting,
    },
    {
      // Getters
      getDownloads,
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setList,
      setLoading,
      setLoadingSingleItem,
    },
  ] = useTemplateTable<MasterRouteType, ApiMasterRouteGetRequest>({
    editPermission: 'Edit MasterRoute',
    addPermission: 'Add MasterRoute',
    deletePermission: 'Delete MasterRoute',
    downloadPermission: 'MasterRoute ListDownload',
    viewPermission: 'MasterRoute List',
  });
  // masterRoute
  const [masterRoute, setMasterRoute] = React.useState<
    MasterRouteType | undefined
  >();
  const [masterRouteId, setMasterRouteId] = React.useState<
    number | undefined
  >();
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [hasEditPermission] = React.useState(
    RoleService.hasPermission('Edit MasterRoute', 'Edit')
  );

  const detailsRef = React.useRef<FormikProps<IMasterRouteDetails>>(null);
  const copyDetailsRef =
    React.useRef<FormikProps<IMasterRouteDetailsCopy>>(null);

  const loadList = React.useCallback<TLoadList<MasterRouteList>>(
    async (options) => {
      setLoading(true);
      try {
        const requestObj = getRequestObj(
          ['name', 'masterTripPrefix', 'contractCode'],
          options
        );
        const response = await masterRouteApi.apiMasterRouteGet(requestObj);
        return getResponse(response, options);
      } catch (e) {
        notify('error', e.message ?? 'Failed to load master route list');
      } finally {
        setLoading(false);
      }
    },
    [getRequestObj, getResponse, notify, setLoading]
  );

  const loadMasterRoute = React.useCallback(async (): Promise<
    MasterRouteType | undefined | null
  > => {
    setLoadingSingleItem(true);
    try {
      if (masterRouteId) {
        const response = await masterRouteApi.apiMasterRouteMasterRouteIdGet({
          masterRouteId,
        });
        setMasterRoute(response);
        return response;
      } else {
        setMasterRoute(undefined);
        return undefined;
      }
    } catch (e) {
      notify('error', e.message ?? 'Failed to load master route');
      return undefined;
    } finally {
      setLoadingSingleItem(false);
    }
  }, [masterRouteId, notify, setLoadingSingleItem]);

  const handleDetailsFormSubmit = React.useCallback(
    async (
      values: IMasterRouteDetails,
      formikHelpers: FormikHelpers<IMasterRouteDetails>
    ) => {
      setSubmitting(true);
      formikHelpers.setSubmitting(true);
      try {
        const {contract, ...otherValues} = values;
        if (contract.value) {
          const newValues: MasterRoutePost = {
            ...otherValues,
            contractId: contract.value,
          };

          if (masterRoute && masterRoute.id) {
            await masterRouteApi.apiMasterRouteMasterRouteIdPatch({
              masterRouteId: masterRoute.id,
              body: newValues,
            });
          } else {
            await masterRouteApi.apiMasterRoutePost({
              body: newValues,
            });
          }
          history.push('/masterroutelist');
          notify(
            'success',
            `${masterRoute?.id ? 'Updated' : 'Added'} master route`
          );
        } else notify('error', 'Contract is required');
      } catch (e) {
        notify(
          'error',
          e.message ??
            `Failed to ${masterRoute?.id ? 'update' : 'add'} master route`
        );
      } finally {
        formikHelpers.setSubmitting(false);
        setSubmitting(false);
      }
    },
    [notify, masterRoute, history]
  );
  const handleCopyDetailsFormSubmit = React.useCallback(
    async (
      values: IMasterRouteDetailsCopy,
      formikHelpers: FormikHelpers<IMasterRouteDetailsCopy>
    ) => {
      setSubmitting(true);
      formikHelpers.setSubmitting(true);
      try {
        if (masterRoute) {
          const contractIds = values.contracts.map(
            (contract) => contract.value
          );
          const legs = masterRoute.legs?.map((leg) => ({
            fromNodeId: leg.fromNodeId,
            toNodeId: leg.toNodeId,
            waypoints: leg.waypoints?.map((waypoint) => ({
              latitude: waypoint.latitude,
              longitude: waypoint.longitude,
              sequence: waypoint.sequence,
            })),
          }));
          contractIds.map(async (contractId) => {
            if (contractId) {
              if (contractId === masterRoute.contractId) return true;
              try {
                const response = await masterRouteApi.apiMasterRoutePost({
                  body: {
                    ...masterRoute,
                    legs,
                    contractId,
                  },
                });
                setList((prevList) => [...prevList, response]);
              } catch (e) {
                notify('error', e.message ?? 'Failed to copy master route');
              }
            }
          });
          history.push('/masterroutelist');
          notify('success', 'Copied master route');
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to copy master route');
      } finally {
        formikHelpers.setSubmitting(false);
        setSubmitting(false);
      }
    },
    [masterRoute, history, notify, setList]
  );

  const handleDownload = React.useCallback(async () => {
    getDownloads('masterRoutes', loadList, [
      {name: 'Name', path: 'name'},
      {name: 'Master Trip Prefix', path: 'masterTripPrefix'},
      {name: 'Contract Id', path: 'contractId'},
    ]);
  }, [getDownloads, loadList]);

  const handleDelete = React.useCallback(
    async (row: MasterRouteType) => {
      setLoading(true);
      try {
        if (row.id) {
          await masterRouteApi.apiMasterRouteMasterRouteIdDelete({
            masterRouteId: row.id,
          });
          await loadList();
          notify('success', 'Deleted master route');
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to delete master route');
      } finally {
        setLoading(false);
      }
    },
    [loadList, notify, setLoading]
  );

  const handleAdd = React.useCallback(() => {
    history.push('/masterroutelist/add');
  }, [history]);

  const handleNavigate = React.useCallback(
    (row: MasterRouteType) => {
      history.push(`/masterroutelist/${row.id}`);
    },
    [history]
  );

  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  const value: MasterRouteContextProps = {
    // Template Table
    loading: loadingSingleItem || loading,
    list,
    hasPermission,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onAdd: hasPermission.add ? handleAdd : undefined,
    onDownload: hasPermission.download ? handleDownload : undefined,
    onNavigate: handleNavigate,
    onDelete: hasPermission.delete ? handleDelete : undefined,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onRefresh: handleRefresh,
    // MasterRoute
    loadList,
    loadMasterRoute,
    cleanupList,
    onDetailsFormSubmit: handleDetailsFormSubmit,
    onCopyDetailsFormSubmit: handleCopyDetailsFormSubmit,
    setMasterRouteId,
    submitting,
    masterRoute,
    masterRouteId,
    detailsRef,
    copyDetailsRef,
    hasEditPermission,
  };

  React.useEffect(() => {
    loadMasterRoute();
    return () => setMasterRoute(undefined);
  }, [loadMasterRoute]);

  return (
    <MasterRouteContext.Provider value={value}>
      {children}
    </MasterRouteContext.Provider>
  );
};
