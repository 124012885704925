import React from 'react';
import {PermissionContext} from './PermissionContext';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../factory/template';
import {Permission} from '@onroadvantage/onroadvantage-api';

export const PermissionList: React.FC = () => {
  const {loadList, cleanupList} = React.useContext(PermissionContext);

  const [config] = React.useState<TemplateTableConfig<Permission>>({
    columns: [
      {name: 'name', label: 'Permission Name', type: 'string'},
      {name: 'description', label: 'Permission Description', type: 'string'},
      {
        name: 'type',
        label: 'Permission Type',
        type: 'string',
        enableFilter: false,
      },
    ],
    enableFilter: true,
    enableSort: true,
    deleteMessage: (row) => ({
      title: 'Permission',
      items: {
        Name: row.name,
        Description: row.description,
        Type: row.type,
      },
    }),
    identifier: 'PERMISSION_LIST',
  });

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [loadList, cleanupList]);

  return <TemplateTableWrapper config={config} context={PermissionContext} />;
};
