import React from 'react';
import {Skeleton, Stack} from '@mui/material';
import {Loader} from '../../loader';
import {useTripSetupContext} from './TripSetupContext';
import {getLengthOfStops} from './helpers/getLengthOfStops';
import {useTripSetupStyles} from './TripSetup.styles';

export const TripSetupLoader = () => {
  const classes = useTripSetupStyles();
  const {stops} = useTripSetupContext();
  return (
    <Stack flex={1} py={4} position="relative">
      {Array.from({length: getLengthOfStops(stops)}, (_, index) => (
        <Skeleton key={index} height={60} />
      ))}
      <Loader
        text="Creating Trip..."
        classes={{loaderContainer: classes.loaderContainer}}
      />
    </Stack>
  );
};
