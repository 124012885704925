import React from 'react';
import {DriverRankIncentiveContext} from './DriverRankIncentiveContext';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../factory/template';
import {DriverRankIncentiveConfigMinimal} from '@onroadvantage/onroadvantage-api';

export const DriverRankIncentiveList: React.FC = () => {
  const {loadList, cleanupList} = React.useContext(DriverRankIncentiveContext);

  const [config] = React.useState<
    TemplateTableConfig<DriverRankIncentiveConfigMinimal>
  >({
    columns: [
      {name: 'name', label: 'Incentive Name', type: 'string'},
      {
        name: 'maxAchievers',
        label: 'Max Achievers',
        type: 'number',
        enableFilter: false,
      },
      {
        name: 'period',
        label: 'Incentive Period',
        type: 'string',
        enableFilter: false,
      },
    ],
    enableFilter: true,
    enableSort: true,
    deleteMessage: (row) => ({
      title: 'Driver Rank Incentive',
      items: {Name: row.name, Message: row.message},
    }),
    identifier: 'DRIVER_RANK_INCENTIVE_LIST',
  });

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [loadList, cleanupList]);

  return (
    <TemplateTableWrapper
      config={config}
      context={DriverRankIncentiveContext}
    />
  );
};
