import React from 'react';
import {SitePostContext} from './SitePostContext';
import {useParams} from 'react-router-dom';

interface IParams {
  associateNodeId?: string;
}

export const SitePostIdHandler: React.FC = () => {
  const {setAssociateNodeId} = React.useContext(SitePostContext);

  const {associateNodeId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = associateNodeId ? parseInt(associateNodeId) : undefined;
    setAssociateNodeId(parsedId);
    return () => setAssociateNodeId(undefined);
  }, [associateNodeId, setAssociateNodeId]);

  return null;
};
