import React from 'react';
import {Theme} from '@mui/material';
import {WithStyles} from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Card from '@mui/material/Card';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {connect, FormikProps} from 'formik';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import {PlanningTripHeader} from './PlanningTripHeader';
import {PlanningTripOrders} from './PlanningTripOrders';
import {usePlanningSolutionViewStore} from '../../../stores/context';
import {PlanningSolutionFormValues} from '../planningSolution/PlanningSolutionForm';
import {Loader} from '../../loader';
import IconButton from '@mui/material/IconButton';
import SkipPrevious from '@mui/icons-material/SkipPrevious';
import SkipNext from '@mui/icons-material/SkipNext';
import {usePlanningSolutionData} from '../../../stores/context';
import _ from 'lodash';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    footer: {
      borderTopColor: theme.palette.divider,
      borderTopStyle: 'solid',
      borderTopWidth: 1,
    },
    header: {
      display: 'flex',
      flowDirection: 'row',
      justifyContent: 'space-between',
      paddingRight: theme.spacing(3),
      borderBottomColor: theme.palette.divider,
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
    },
    tabs: {},
  });

interface IParams {
  solutionId: string;
  tripId: string;
}

interface IFormikProps {
  formik: FormikProps<PlanningSolutionFormValues>;
}

interface IProps
  extends WithStyles<typeof styles>,
    IFormikProps,
    RouteComponentProps<IParams> {}

// eslint-disable-next-line import/prefer-default-export
export const PlanningTrip = withStyles(styles)(
  withRouter(
    connect<IProps & RouteComponentProps<IParams>, PlanningSolutionFormValues>(
      ({classes, formik, history, match}) => {
        /** set which tab is being used */
        const [tabValue, setTabValue] = React.useState<number>(0);

        const {isLoading} = usePlanningSolutionViewStore((store) => ({
          isLoading: store.solutionIsLoading,
        }));

        const {planningSolution} = usePlanningSolutionData((store) => ({
          planningSolution: store.planningSolution,
        }));

        /** Handle tab change */
        const tabChange = (event: any, value: number) => setTabValue(value);

        const isDisplayReady = true;

        const handleSave = async () => {
          try {
            const planningMasterTrip = _.find(
              planningSolution?.planningMasterTrips,
              ['trip.id', +match.params.tripId]
            );
            await formik.submitForm();
            if (planningMasterTrip) {
              history.push(
                `/planningsolutionlist/s/${match.params.solutionId}?planningMasterTripId=${planningMasterTrip?.id}&resetForm=1`
              );
            } else {
              history.push(
                `/planningsolutionlist/s/${match.params.solutionId}?resetForm=1`
              );
            }
          } catch (e) {
            // TODO handle
          }
        };

        const handleCancel = async () => {
          history.push(
            `/planningsolutionlist/s/${match.params.solutionId}?resetForm=1`
          );
        };

        const tripIds = planningSolution?.planningMasterTrips?.map(
          (p) => p.trip?.id
        );
        const tripIdIndex = _.indexOf(tripIds, parseInt(match.params.tripId));
        let nextTripId: number | undefined;
        let prevTripId: number | undefined;
        if (tripIds && tripIdIndex !== -1) {
          try {
            nextTripId = tripIds[tripIdIndex + 1];
            // eslint-disable-next-line no-empty
          } catch {}
          try {
            prevTripId = tripIds[tripIdIndex - 1];
            // eslint-disable-next-line no-empty
          } catch {}
        }

        let showNextTrip = false;
        let showPrevTrip = false;

        const handleNextTrip = () => {
          if (nextTripId) {
            history.push(
              `/planningsolutionlist/s/${match.params.solutionId}/t/${nextTripId}`
            );
          }
        };

        const handlePrevTrip = () => {
          if (prevTripId) {
            history.push(
              `/planningsolutionlist/s/${match.params.solutionId}/t/${prevTripId}`
            );
          }
        };

        if (tripIds && tripIds.includes(prevTripId)) {
          showPrevTrip = true;
        }
        if (tripIds && tripIds.includes(nextTripId)) {
          showNextTrip = true;
        }

        if (isLoading) {
          return (
            <>
              <div>Trips</div>
              <Loader />
            </>
          );
        }

        return (
          <Card className={classes.container} elevation={0}>
            <div className={classes.header}>
              <Tabs
                value={tabValue}
                onChange={tabChange}
                indicatorColor="primary"
                textColor="primary"
                className={classes.tabs}
              >
                <Tab label="Header" disabled={!isDisplayReady} />
                <Tab label="Orders/Stops" disabled={!isDisplayReady} />
              </Tabs>
              <div>
                <IconButton
                  onClick={handlePrevTrip}
                  disabled={!showPrevTrip}
                  size="large"
                >
                  <SkipPrevious />
                </IconButton>
                <IconButton
                  onClick={handleNextTrip}
                  disabled={!showNextTrip}
                  size="large"
                >
                  <SkipNext />
                </IconButton>
              </div>
            </div>
            <div className={classes.header}>
              <CardActions className={classes.footer}>
                <Button
                  disabled={isLoading}
                  color="primary"
                  onClick={handleSave}
                >
                  Save
                </Button>
                <Button
                  disabled={isLoading}
                  color="secondary"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </CardActions>
            </div>
            {tabValue === 0 && <PlanningTripHeader />}
            {tabValue === 1 && <PlanningTripOrders />}
            <CardActions className={classes.footer}>
              <Button disabled={isLoading} color="primary" onClick={handleSave}>
                Save
              </Button>
              <Button
                disabled={isLoading}
                color="secondary"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </CardActions>
          </Card>
        );
      }
    )
  )
);
