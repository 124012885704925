import React from 'react';
import {Button} from '@mui/material';
import {Sync} from '@mui/icons-material';
import {TripTimelineVehicleMobileSwitcher} from './TripTimelineVehicleMobileSwitcher';
import {ITimelinePlayback, TripTimelinePlayer} from './TripTimelinePlayer';
import {useTripEditContext} from '../tripEdit';

interface TripTimelineActionsProps {
  playback: ITimelinePlayback;
}

export const TripTimelineActions: React.FC<TripTimelineActionsProps> = ({
  playback,
}) => {
  const {hasEditPermission, onSyncStopsWithActuals} = useTripEditContext();

  const handleSyncStopsWithActual = React.useCallback(async () => {
    await onSyncStopsWithActuals();
  }, [onSyncStopsWithActuals]);

  if (!hasEditPermission) {
    return null;
  }

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleSyncStopsWithActual}
        endIcon={<Sync />}
      >
        Sync
      </Button>
      <TripTimelineVehicleMobileSwitcher />
      <TripTimelinePlayer playback={playback} />
    </>
  );
};
