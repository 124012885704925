import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

const PlanningTripHeaderStyles = (_theme: Theme) =>
  createStyles({
    container: {},
    content: {
      display: 'flex',
      flexDirection: 'column',
    },
    cardContent: {
      flex: 1,
      padding: 10,
      width: '40vh',
    },
    columnContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    input: {
      marginTop: 3,
      paddingBottom: 21,
    },
    inputError: {
      paddingBottom: 0,
    },
  });

export const usePlanningTripHeaderStyles = makeStyles(
  PlanningTripHeaderStyles,
  {
    name: 'PlanningTripHeaderStyles',
  }
);
