import React from 'react';
import {
  TemplateTable,
  TemplateTableConfig,
  TOnInlineAdd,
} from '../../factory/template';
import {Listing, YardFlowConfig} from '@onroadvantage/onroadvantage-api';
import {WorkflowConfigContext} from './WorkflowConfigContext';
import {workflowConfigApi} from '../../api';
import {useAppNotifications} from '../../contexts';

interface IWorkflowConfigTaskListAdd {
  nodes: Listing[];
}

export const WorkflowConfigYardFlowConfigList: React.FC = () => {
  const notify = useAppNotifications();
  const {
    workflowConfigId,
    workflowConfig,
    loading,
    loadingSingleItem,
    loadWorkflowConfig,
  } = React.useContext(WorkflowConfigContext);
  const [localSubmitting, setLocalSubmitting] = React.useState<boolean>(false);

  const [config] = React.useState<TemplateTableConfig<YardFlowConfig>>({
    columns: [
      {
        name: 'nodes',
        label: 'Site',
        type: 'string',
        model: 'Node',
        enableMulti: true,
        enableAutocomplete: true,
        enableEditing: true,
        getValue: ({node}) => node?.name,
      },
    ],
    disablePagination: true,
    disableToolbar: true,
    deleteMessage: (row) => ({
      title: 'Workflow Config Yardflow Config',
      items: {SiteName: row.node?.name},
    }),
    identifier: 'WORKFLOW_CONFIG__YARD_FLOW_CONFIG_LIST',
  });

  const handleDelete = React.useCallback(
    async (row: YardFlowConfig) => {
      setLocalSubmitting(true);
      try {
        if (row.id) {
          await workflowConfigApi.apiWorkflowConfigYardFlowConfigYardFlowConfigIdDelete(
            {
              yardFlowConfigId: row.id,
            }
          );
          notify('success', 'Deleted workflow config yard flow config');
          await loadWorkflowConfig();
        }
      } catch (e) {
        notify(
          'error',
          e.message ?? 'Failed to delete workflow config yard flow config'
        );
      } finally {
        setLocalSubmitting(false);
      }
    },
    [loadWorkflowConfig, notify]
  );

  const handleInlineAdd = React.useCallback<TOnInlineAdd>(
    async (changes) => {
      setLocalSubmitting(true);
      try {
        if (workflowConfigId) {
          const values = changes[0] as IWorkflowConfigTaskListAdd;
          const {nodes} = values;
          if (nodes.length > 0) {
            await workflowConfigApi.apiWorkflowConfigWorkflowConfigIdYardFlowConfigPost(
              {
                workflowConfigId,
                body: {nodeIds: nodes.map(({value}) => value as number)},
              }
            );
            notify('success', 'Add task template to workflow config');
            await loadWorkflowConfig();
          }
        }
      } catch (e) {
        notify(
          'error',
          e.message ?? 'Failed to assign task template to workflow config'
        );
      } finally {
        setLocalSubmitting(false);
      }
    },
    [loadWorkflowConfig, notify, workflowConfigId]
  );

  return (
    <TemplateTable
      config={config}
      currentPage={1}
      list={workflowConfig?.yardFlowConfig ?? []}
      loading={loading || loadingSingleItem || localSubmitting}
      onInlineAdd={handleInlineAdd}
      onDelete={handleDelete}
    />
  );
};
