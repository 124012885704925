import * as React from 'react';
import Paper from '@mui/material/Paper';
import {
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSelection,
  PagingState,
  SelectionState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  PagingPanel,
  Table,
  TableHeaderRow,
  TableSelection,
} from '@devexpress/dx-react-grid-material-ui';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import {MapSelectionItem} from './AdhocMapSelectStep';
import {mapDisplayStore} from '../../../stores/mobxStores';

interface Props {
  // todo: figure out selectionItems type
  items: MapSelectionItem[];
  onNext: (selectionItems: any) => void;
  onBack: () => void;
}

interface State {
  columns: {name: string; title: string}[];
  pageSizes: number[];
  items: any[];
  selection: any[];
  loading: boolean;
  pages?: number;
  perPage?: number;
  currentPage?: number;
  totalCount?: number;
}

function CheckBox({
  selected,
  onToggle,
  toggleAll,
}: {
  selected: boolean;
  onToggle: (selected?: boolean) => void;
  toggleAll?: boolean;
}) {
  return (
    <Checkbox
      onChange={() => {
        onToggle(toggleAll);
      }}
      checked={selected}
      color="primary"
    />
  );
}
const TableRow = (
  // eslint-ignore-next-line no-undef
  props: JSX.IntrinsicAttributes &
    Table.DataCellProps & {
      [x: string]: any;
      className?: string | undefined;
      style?: React.CSSProperties | undefined;
    }
) => <Table.Cell {...props} style={{padding: 0}} />;
export default class AdhocMapSelectRecipients extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      columns: [
        {name: 'id', title: 'ID'},
        {name: 'description', title: 'Description'},
        {name: 'registrationNumber', title: 'Registration Number'},
      ],
      pageSizes: [10, 25, 50, 100],
      items: [],
      // initially, set all items to be selected.
      selection: this.props.items.map((_item, index) => {
        return index;
      }),
      loading: false,
    };
  }

  componentDidMount = async () => {
    this.loadData();
  };

  changeSelection = (newSelection: any[]) => {
    this.setState({selection: newSelection});
  };

  // @eslint-disable-next-line @typescript-eslint/no-unused-vars
  loadData = async () => {
    this.setState({loading: true});

    this.setState({
      items: this.props.items,
      totalCount: this.props.items.length,
      loading: false,
    });
  };

  onNext = () => {
    const {selection, items} = this.state;
    const selectionItems = selection.map((key) => {
      return items[key];
    });
    this.props.onNext(selectionItems);
  };

  onBack = () => {
    this.setState(
      {
        items: [],
        selection: [],
      },
      () => {
        mapDisplayStore.setGeoSelectionItems([]);
        this.props.onBack();
      }
    );
  };

  render() {
    const {items, columns, pageSizes, selection} = this.state;

    const disabled = !selection.length;

    return (
      <div>
        <Paper>
          <Grid rows={items} columns={columns}>
            <FilteringState />
            <SelectionState
              selection={selection}
              onSelectionChange={(props) => this.changeSelection(props)}
            />
            <PagingState />
            <IntegratedFiltering />
            <IntegratedPaging />
            <IntegratedSelection />
            <Table cellComponent={TableRow} />
            <TableSelection
              showSelectAll
              headerCellComponent={(values) => {
                return (
                  <CheckBox
                    selected={values.allSelected}
                    onToggle={values.onToggle}
                    toggleAll={!values.allSelected}
                  />
                );
              }}
              cellComponent={(values) => {
                return (
                  <CheckBox
                    selected={values.selected}
                    onToggle={values.onToggle}
                  />
                );
              }}
            />
            <TableHeaderRow />
            <PagingPanel pageSizes={pageSizes} />
          </Grid>
        </Paper>
        <div style={{paddingTop: 20}}>
          <CardActions>
            <div>
              <Button onClick={this.onBack}>
                <Typography>Back</Typography>
              </Button>
            </div>
            <div style={{color: disabled ? 'grey' : undefined}}>
              <Button onClick={this.onNext} disabled={disabled}>
                <Typography>Next</Typography>
              </Button>
            </div>
          </CardActions>
        </div>
      </div>
    );
  }
}
