import React from 'react';
import {TextFieldProps} from '@mui/material';
import {FormikDateTimePickerContext} from './FormikDateTimePicker';
import {FormikPickerField} from './FormikPickerField';
import {useFormikContext} from 'formik';

export const FormikDateTimePickerField = (
  props: TextFieldProps
): React.ReactElement => {
  const {name, InputLabelProps} = React.useContext(FormikDateTimePickerContext);
  const {getFieldMeta} = useFormikContext();
  const {error, touched} = getFieldMeta(name);
  return (
    <FormikPickerField
      {...props}
      name={name}
      displayError={touched ? error : undefined}
      InputLabelProps={InputLabelProps}
    />
  );
};
