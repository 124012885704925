import React from 'react';
import {IconButton, PropTypes} from '@mui/material';

export interface TemplateTabControllerActionProps {
  button?: React.ReactNode;
  color?: PropTypes.Color;
  icon?: React.ReactNode;
  onClick?: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    activeTab: unknown
  ) => void;
  onRightClick?: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    activeTab: unknown
  ) => void;
  size?: 'small' | 'medium';
  style?: React.CSSProperties;
  tabValue: unknown;
}

export const TemplateTabsAction: React.FC<TemplateTabControllerActionProps> = ({
  button,
  color,
  icon,
  onClick,
  onRightClick,
  size,
  style,
  tabValue,
}) => {
  const handleClick = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (onClick) onClick(e, tabValue);
    },
    [onClick, tabValue]
  );

  const handleRightClick = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (onRightClick) {
        e.preventDefault();
        onRightClick(e, tabValue);
      }
    },
    [onRightClick, tabValue]
  );

  return (
    <>
      {button ?? (
        <IconButton
          onClick={handleClick}
          onContextMenu={handleRightClick}
          size={size}
          color={color}
          style={style}
        >
          {icon}
        </IconButton>
      )}
    </>
  );
};
