import React from 'react';
import {unstable_batchedUpdates} from 'react-dom';
import {Theme} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import {WithStyles} from '@mui/styles';
import {RouteComponentProps} from 'react-router-dom';
import {
  Listing,
  Listing as ListingType,
  PlanningSolutionPost,
} from '@onroadvantage/onroadvantage-api';
import {Form, Formik, FormikConfig, FormikValues} from 'formik';
import * as Yup from 'yup';
import {PlanningAddDetails} from './PlanningAddDetails';
import {listingApi, planningSolutionApi} from '../../../api';
import {PlanningAddReview} from './PlanningAddReview';
import {Loader} from '../../loader';
import {FormikHelpers} from 'formik/dist/types';

import {PlanningAddOrders} from './PlanningAddOrders';
import {PlanningAddVehicles} from './PlanningAddVehicles';
import {PlanningAddDrivers} from './PlanningAddDrivers';
import {appNotificationStore} from '../../../stores/mobxStores';
import history from '../../../service/history';
import {MomentService} from '../../../service';
import {usePlanningSolutionListData} from '../../../stores/context';
import _ from 'lodash';
import {DateTime} from 'luxon';
import {NUMBER_ERROR_MESSAGE} from '../../../factory/template';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      // backgroundColor: theme.palette.background.default,
      // padding: theme.spacing(1),
    },
    header: {
      borderBottomColor: theme.palette.divider,
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
    },
    content: {
      backgroundColor: theme.palette.background.default,
      // padding: 0,
      borderColor: theme.palette.divider,
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
      borderTopStyle: 'solid',
      borderTopWidth: 1,
    },
    stepper: {
      // backgroundColor: theme.palette.background.default,
      borderBottomColor: theme.palette.divider,
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
    },
  });

/** Form Values */
export interface IPlanningAddFormValues {
  /** Solution Allocated Drivers */
  allocatedDrivers: Array<number>;
  /** Solution Allocated Orders */
  allocatedOrders: Array<number>;
  /** Solution Allocated Vehicles */
  allocatedVehicles: Array<number>;
  /** Solution Contract */
  contract: ListingType | null; // TODO typing
  /** Solution End Date */
  endDate?: Date;
  /** Solution Name */
  name: string;
  /** Solution Start Date */
  startDate?: Date;
}

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  onSubmit?: () => void;
}

// const PlanningAddSchema = Yup.object<IPlanningAddFormValues>({
//   allocatedDrivers: Yup.array().of(Yup.number()),
//   allocatedOrders: Yup.array().of(Yup.number()),
//   allocatedVehicles: Yup.array().of(Yup.number()),
//   contract: Yup.object({
//     value: Yup.number().required(),
//     label: Yup.string(),
//   }).required(),
//   endDate: Yup.string().required(),
//   name: Yup.string().required(),
//   startDate: Yup.string().required(),
// });

interface PlanningAddActionsProps {
  nextDisabled?: boolean;
  nextTitle?: string;
  onNext?: () => void;
  onPrev?: () => void;
  prevDisabled?: boolean;
  prevTitle?: string;
}

export const PlanningAddActions: React.FC<PlanningAddActionsProps> = ({
  nextTitle,
  nextDisabled,
  onPrev,
  prevDisabled,
  prevTitle,
}) => (
  <CardActions>
    <Button color="secondary" onClick={onPrev} disabled={prevDisabled}>
      {prevTitle || 'back'}
    </Button>
    <Button color="primary" type="submit" disabled={nextDisabled}>
      {nextTitle || 'next'}
    </Button>
  </CardActions>
);

export interface PlanningAddStepProps
  extends Pick<FormikConfig<FormikValues>, 'children' | 'validationSchema'> {
  onSubmit?: () => void | Promise<void>;
}

export const PlanningAddStep: React.FC<PlanningAddStepProps> = ({children}) => {
  return <>{children}</>;
};

interface IPlanningAddStepperProps extends WithStyles<typeof styles> {
  loading?: boolean;
  onValueChange: (value: any | null) => void;
  steps: Array<string>;
}

export const PlanningAddStepperComponent: React.FC<
  IPlanningAddStepperProps
> = ({children, classes, loading, onValueChange, steps}) => {
  const initialValues: IPlanningAddFormValues = {
    allocatedDrivers: [],
    allocatedOrders: [],
    allocatedVehicles: [],
    name: '',
    contract: null,
    endDate: DateTime.fromJSDate(new Date(MomentService.endOfDate(new Date())))
      .minus({millisecond: 1})
      .toJSDate(),
    startDate: new Date(MomentService.startOfDate(new Date())),
  };

  const displayNotification = (message: any) => {
    appNotificationStore.enqueueNotification(message.type, message.value);
  };

  const triggerListRefresh = usePlanningSolutionListData(
    (store) => store.triggerRefresh
  );

  const [stepIndex, setStepIndex] = React.useState<number>(0);

  // TODO cannot do typing correctly because of eslint parse error
  // eslint-disable-next-line
  const childrenArr = React.Children.toArray(children) as Array<
    React.ReactElement<PlanningAddStepProps>
  >;
  const current = childrenArr[stepIndex];

  const isLastStep = stepIndex === childrenArr.length - 1;
  const isFirstStep = stepIndex === 0;

  const handlePrev = () => {
    if (stepIndex === 0) {
      history.goBack();
    }
    return stepIndex !== 0 && setStepIndex((s) => s - 1);
  };

  const handleSubmit = async (
    values: IPlanningAddFormValues,
    actions: FormikHelpers<IPlanningAddFormValues>
  ) => {
    if (isLastStep) {
      const body: PlanningSolutionPost = {
        name: values.name,
        driverIds: values.allocatedDrivers,
        vehicleIds: values.allocatedVehicles,
        planningOrderIds: values.allocatedOrders,
        // TODO handle nulls
        contractId: _.get(values, 'contract.value', -1),
        startDate: values.startDate,
        endDate: values.endDate,
      };
      try {
        const response = await planningSolutionApi.apiPlanningSolutionPost({
          body,
        });
        if (response) {
          displayNotification({
            type: 'success',
            value: 'Planning Solution Created',
          });
          triggerListRefresh();
          history.push(`/planningsolutionlist/s/${response.id}`);
        }
      } catch (e) {
        // TODO handle
        actions.setSubmitting(false);
      }
    } else {
      onValueChange({
        contract: values.contract,
        endDate: values.endDate,
        startDate: values.startDate,
      });

      actions.setSubmitting(false);
      setStepIndex((s) => s + 1);
    }

    return true;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={current.props.validationSchema}
    >
      {({isSubmitting}) => (
        <Form autoComplete="off">
          <CardContent className={classes.stepper}>
            <Stepper activeStep={stepIndex}>
              {steps.map((s) => (
                <Step key={s}>
                  <StepLabel>{s}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </CardContent>
          <PlanningAddActions
            nextDisabled={isSubmitting || loading}
            onPrev={handlePrev}
            prevDisabled={isSubmitting || loading}
            nextTitle={isLastStep ? 'submit' : 'next'}
            prevTitle={isFirstStep ? 'cancel' : 'back'}
          />
          <CardContent className={classes.content}>
            {loading ? <Loader /> : current}
          </CardContent>
          <PlanningAddActions
            nextDisabled={isSubmitting || loading}
            onPrev={handlePrev}
            prevDisabled={isSubmitting || loading}
            nextTitle={isLastStep ? 'submit' : 'next'}
            prevTitle={isFirstStep ? 'cancel' : 'back'}
          />
        </Form>
      )}
    </Formik>
  );
};

export const PlanningAddStepper = withStyles(styles)(
  PlanningAddStepperComponent
);

export const PlanningAdd = withStyles(styles)(({classes}: Props) => {
  const steps = ['Details', 'Orders', 'Vehicles', 'Drivers', 'Review'];

  const [drivers, setDrivers] = React.useState<Array<Listing>>([]);
  const [orders, setOrders] = React.useState<Array<Listing>>([]);
  const [vehicles, setVehicles] = React.useState<Array<Listing>>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  // contract
  const [contract, setContract] = React.useState<Listing | null>(null);
  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [endDate, setEndDate] = React.useState<Date | null>(null);

  React.useEffect(() => {
    const loadData = async () => {
      if (!contract || !contract.label) {
        return;
      }
      setLoading(true);

      const contractCodeStr = contract.label.split('-')[0].trim();
      const driverGetOptions = {
        model: 'Driver',
        metaData: 'external_extended_id',
        perPage: 4000,
        contractCode: contractCodeStr,
      };
      const vehicleGetOptions = {
        model: 'WebPlanningSolutionVehicle',
        metaData: 'fleet_number',
        perPage: 4000,
        contractCode: contractCodeStr,
      };
      const planningOrderGetOptions = {
        model: 'PlanningOrder',
        perPage: 4000,
        contractCode: contractCodeStr,
        metaData: 'skill',
        endDate:
          endDate ||
          DateTime.fromJSDate(new Date(MomentService.endOfDate(endDate)))
            .minus({millisecond: 1})
            .toJSDate(),
        startDate: startDate || new Date(MomentService.startOfDate(startDate)),
      };

      const [vehicleOptions, planningOrderOptions, driverOptions] =
        await Promise.all([
          listingApi.apiListingGet(vehicleGetOptions),
          listingApi.apiListingGet(planningOrderGetOptions),
          listingApi.apiListingGet(driverGetOptions),
        ]);

      // drivers
      if (driverOptions && driverOptions.items) {
        setDrivers(driverOptions.items);
      }

      // planningOrders
      if (planningOrderOptions && planningOrderOptions.items) {
        setOrders(planningOrderOptions.items);
      }

      // vehicles
      if (vehicleOptions && vehicleOptions.items) {
        setVehicles(vehicleOptions.items);
      }

      setLoading(false);
    };

    loadData();
  }, [
    contract,
    setDrivers,
    setOrders,
    setVehicles,
    setLoading,
    startDate,
    endDate,
  ]);

  interface IValueChange {
    contract: Listing | null;
    endDate: Date | null;
    startDate: Date | null;
  }

  const handleValueChange = (value: IValueChange) => {
    // https://github.com/facebook/react/issues/14259#issuecomment-439702367
    unstable_batchedUpdates(() => {
      if (contract !== value.contract) {
        setContract(value.contract);
      }
      if (endDate !== value.endDate) {
        setEndDate(value.endDate);
      }
      if (startDate !== value.startDate) {
        setStartDate(value.startDate);
      }
    });
  };

  return (
    <Card elevation={0}>
      <CardHeader className={classes.header} title="Add Planning Solution" />
      <PlanningAddStepper
        steps={steps}
        loading={loading}
        onValueChange={handleValueChange}
      >
        <PlanningAddStep
          validationSchema={Yup.object({
            contract: Yup.object({
              value: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
              label: Yup.string(),
            })
              .nullable()
              .test(
                'hasContract',
                'Please select a valid contract',
                function () {
                  // TODO ignore when not touched
                  const contract: Listing = this.resolve(Yup.ref('contract'));
                  return !!(contract && contract.value);
                }
              )
              .required(),
            endDate: Yup.date().required(),
            name: Yup.string().required(),
            startDate: Yup.date().required(),
          })}
        >
          <PlanningAddDetails />
        </PlanningAddStep>
        <PlanningAddStep
          validationSchema={Yup.object({
            allocatedOrders: Yup.array().of(
              Yup.number().typeError(NUMBER_ERROR_MESSAGE)
            ),
          })}
        >
          <PlanningAddOrders orders={orders} />
        </PlanningAddStep>
        <PlanningAddStep
          validationSchema={Yup.object({
            allocatedVehicles: Yup.array().of(
              Yup.number().typeError(NUMBER_ERROR_MESSAGE)
            ),
          })}
        >
          <PlanningAddVehicles vehicles={vehicles} />
        </PlanningAddStep>
        <PlanningAddStep
          validationSchema={Yup.object({
            allocatedDrivers: Yup.array().of(
              Yup.number().typeError(NUMBER_ERROR_MESSAGE)
            ),
          })}
        >
          <PlanningAddDrivers drivers={drivers} />
        </PlanningAddStep>
        <PlanningAddStep>
          <PlanningAddReview
            drivers={drivers}
            orders={orders}
            vehicles={vehicles}
          />
        </PlanningAddStep>
      </PlanningAddStepper>
    </Card>
  );
});
