import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

const TripEditOrderListStyles = (_theme: Theme) =>
  createStyles({
    newRow: {
      paddingLeft: 0,
      height: 50,
      backgroundColor: 'rgba(180,191,195,0.2)',
    },
  });

export const useTripEditOrderListStyles = makeStyles(TripEditOrderListStyles, {
  name: 'TripEditOrderListStyles',
});
