import React from 'react';
import {ReportContext, ReportContextProps} from './ReportContext';
import {useParams} from 'react-router-dom';
import {
  ReportScheduleContext,
  ReportScheduleContextProps,
} from './reportSchedule';

interface IParams {
  reportId?: string;
}

export const ReportIdHandler: React.FC = () => {
  const reportContext = React.useContext<ReportContextProps>(ReportContext);
  const scheduleContext = React.useContext<ReportScheduleContextProps>(
    ReportScheduleContext
  );

  const reportSetId = reportContext.setReportId;
  const scheduleSetId = scheduleContext.setReportId;

  const {reportId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = reportId ? parseInt(reportId) : undefined;
    reportSetId(parsedId);
    scheduleSetId(parsedId);

    return () => {
      reportSetId(undefined);
      scheduleSetId(undefined);
    };
  }, [reportId, reportSetId, scheduleSetId]);

  return null;
};
