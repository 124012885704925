import React from 'react';
import _ from 'lodash';
import {useContractContext, ContractContextProvider} from './contractContext';
import {ContractIdHandler} from './ContractIdHandler';
import {Contract} from './Contract';
import {
  ITemplateControllerRoute,
  TemplateController,
} from '../../factory/template';
import {ContractList, ContractListContextProvider} from './contractList';
import {ContractSetup} from './contractSetup';
import {ContractDriverEventReasonCodeListContextProvider} from './contractDriverEventReasonCodeList';
import {ContractBroadcastTemplateListContextProvider} from './contractBroadcastTemplateList';

export const ContractController: React.FC = () => {
  return (
    <ContractListContextProvider>
      <ContractContextProvider>
        <ContractDriverEventReasonCodeListContextProvider>
          <ContractBroadcastTemplateListContextProvider>
            <ContractControllerComponent />
          </ContractBroadcastTemplateListContextProvider>
        </ContractDriverEventReasonCodeListContextProvider>
      </ContractContextProvider>
    </ContractListContextProvider>
  );
};

export const ContractControllerComponent: React.FC = () => {
  const {contract, loadingContract} = useContractContext();

  const routes: ITemplateControllerRoute[] = React.useMemo(
    () => [
      {title: 'Contract List', component: ContractList},
      {
        path: '/add',
        name: 'Add Contract',
        type: 'Add',
        title: 'New Contract',
        component: ContractSetup,
      },
      {
        path: '/:contractId',
        title: (params) =>
          loadingContract
            ? 'Contract Loading...'
            : contract?.code && contract.name
            ? `${contract.code} - ${contract.name}`
            : `Contract ${_.get(params, 'contractId', 'Single')}`,
        component: Contract,
        IdHandler: ContractIdHandler,
      },
    ],
    [contract?.code, contract?.name, loadingContract]
  );

  return <TemplateController rootPath="/contractlist" routes={routes} />;
};
