import React from 'react';
import {NotificationConfigContext} from './NotificationConfigContext';
import {useParams} from 'react-router-dom';

interface IParams {
  notificationConfigId?: string;
}

export const NotificationConfigIdHandler: React.FC = () => {
  const {setNotificationConfigId} = React.useContext(NotificationConfigContext);

  const {notificationConfigId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = notificationConfigId
      ? parseInt(notificationConfigId)
      : undefined;
    setNotificationConfigId(parsedId);
    return () => setNotificationConfigId(undefined);
  }, [notificationConfigId, setNotificationConfigId]);

  return null;
};
