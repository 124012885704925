import {makeStyles, createStyles} from '@mui/styles';
import {Theme} from '@mui/material';

const TableHeaderRowCellStyles = (theme: Theme) =>
  createStyles({
    root: {
      // paddingLeft: '10px !important',
      // paddingTop: theme.spacing(1),
      paddingTop: theme.spacing(0),
      // paddingBottom: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
  });

export const useTableHeaderRowCellStyles = makeStyles(
  TableHeaderRowCellStyles,
  {name: 'TableHeaderRowCellStyles'}
);
