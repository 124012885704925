import React from 'react';
import {SiteSubNodeForm} from './SiteSubNodeForm';
import {
  Button,
  Card,
  CardContent,
  Avatar,
  Collapse,
  Typography,
  CardHeader,
} from '@mui/material';
import {VerifiedUser} from '@mui/icons-material';
import QRCode from 'qrcode.react';
import {SiteSubNodeContext} from './SiteSubNodeContext';

export const SiteSubNodeDetails: React.FC = () => {
  const {subNode} = React.useContext(SiteSubNodeContext);
  const [showQrCode, setShowQrCode] = React.useState<boolean>(false);

  const toggleShowQrCode = React.useCallback(
    () => setShowQrCode((prev) => !prev),
    []
  );

  return (
    <>
      <SiteSubNodeForm />
      <Card elevation={0}>
        <CardHeader
          title={
            <>
              <Typography>User Actions</Typography>
            </>
          }
          avatar={
            <Avatar aria-label="UserActions">
              <VerifiedUser />
            </Avatar>
          }
        />
        <CardContent>
          <Button variant="outlined" size="large" onClick={toggleShowQrCode}>
            Generate sub-node QR
          </Button>
        </CardContent>
        <Collapse in={showQrCode}>
          {subNode?.uuid ? (
            <CardContent>
              <QRCode value={subNode.uuid} size={256} level="L" />
            </CardContent>
          ) : (
            <CardContent>
              <Typography>Sub node does not have a UUID</Typography>
            </CardContent>
          )}
        </Collapse>
      </Card>
    </>
  );
};
