import React from 'react';
import {UserContext} from './UserContext';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../factory/template';
import {List, ListItem, ListItemText} from '@mui/material';
import {User} from '@onroadvantage/onroadvantage-api';
import {VantageDialogButton} from '../dialog';
import {ListOutlined} from '@mui/icons-material';

export const UserList: React.FC = () => {
  const {loadList, cleanupList} = React.useContext(UserContext);

  const [config] = React.useState<TemplateTableConfig<User>>({
    columns: [
      {name: 'email', label: 'Name', type: 'string'},
      {name: 'active', label: 'Active', type: 'bool'},
      {name: 'createdAt', label: 'Created At', type: 'datetime'},
      {
        name: 'roleName',
        label: 'Roles',
        type: 'string',
        getValue: ({roles}) =>
          roles && roles.length > 0 ? (
            <VantageDialogButton
              title={<ListOutlined />}
              color="inherit"
              declineTitle="Close"
              maxWidth="xs"
              disableTitle
              disableAccept
              iconButton
              fullWidth
            >
              <List>
                {roles?.map(({id, name}) => (
                  <ListItem key={id}>
                    <ListItemText primary={`${id}:  ${name}`} />
                  </ListItem>
                ))}
              </List>
            </VantageDialogButton>
          ) : (
            '-'
          ),
      },
      {
        name: 'contractCode',
        label: 'Contracts',
        type: 'string',
        getValue: ({contracts}) =>
          contracts && contracts.length > 0 ? (
            <VantageDialogButton
              title={<ListOutlined />}
              color="inherit"
              declineTitle="Close"
              maxWidth="xs"
              disableTitle
              disableAccept
              iconButton
              fullWidth
            >
              <List>
                {contracts?.map(({id, code, name}) => (
                  <ListItem key={id}>
                    <ListItemText primary={`${id}:  ${code} - ${name}`} />
                  </ListItem>
                ))}
              </List>
            </VantageDialogButton>
          ) : (
            '-'
          ),
      },
      {
        name: 'customers',
        label: 'Customers',
        type: 'string',
        getValue: ({customers}) =>
          customers && customers.length > 0 ? (
            <VantageDialogButton
              title={<ListOutlined />}
              color="inherit"
              declineTitle="Close"
              maxWidth="xs"
              disableTitle
              disableAccept
              iconButton
              fullWidth
            >
              <List>
                {customers?.map(({id, name}) => (
                  <ListItem key={id}>
                    <ListItemText primary={`${id}:   ${name}`} />
                  </ListItem>
                ))}
              </List>
            </VantageDialogButton>
          ) : (
            '-'
          ),
      },
    ],
    enableFilter: true,
    enableSort: true,
    deleteMessage: (row) => ({
      title: 'User',
      items: {
        Id: row.id,
        Email: row.email,
        RoleNames: row?.roles?.map(({name}) => name).join(', '),
      },
    }),
    identifier: 'USER_LIST',
  });

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [loadList, cleanupList]);

  return <TemplateTableWrapper config={config} context={UserContext} />;
};
