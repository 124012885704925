import React from 'react';
import {EditingState} from '@devexpress/dx-react-grid';

import {
  Grid,
  Table,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn,
  TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import withStyles from '@mui/styles/withStyles';
import {withRouter} from 'react-router-dom';
import styles from '../styles/Card';
import {Command, EditCell} from '../table/TableCommands';
import {MomentService} from '../../service';
import RoleService from '../../service/RoleService';
import NoDataLoading from '../table/NoDataLoading';
import {
  appNotificationStore,
  criticalEventsStore,
} from '../../stores/mobxStores';
import {criticalEventApi} from '../../api';

class CriticalEventActivities extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: props.activities,
      addedRows: [],
      rowChanges: {},
      loading: false,
    };
  }

  changeAddedRows = (addedRows) => {
    const initialized = addedRows.map((row) =>
      Object.keys(row).length ? row : {}
    );
    this.setState({addedRows: initialized});
  };

  commitChanges = async ({added}) => {
    this.setState({items: [], loading: true});
    if (added) {
      const response = await criticalEventApi.apiCriticalEventCriticalEventIdPost(
        {
          body: {
            activities: [
              {
                action: 'Comment',
                value: added[0].value,
              },
            ],
          },
          criticalEventId: this.props.event.id,
        }
      );
      const {activities} = await response;
      this.setState({items: activities});
      appNotificationStore.enqueueNotification('success', 'Comment Added');
    }
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps = async (nextProps) => {
    this.setState({
      items: nextProps.activities,
    });
  };

  componentWillUnmount = async () => {
    const response = await criticalEventApi.apiCriticalEventCriticalEventIdGet({
      criticalEventId: this.props.event.id,
    });
    criticalEventsStore.addCriticalEventActivities(
      response.activities,
      this.props.event.id
    );
  };

  render() {
    const {rowChanges, addedRows, loading} = this.state;
    const {events} = criticalEventsStore.criticalEventActivities;
    const {id} = criticalEventsStore.criticalEventActivities;
    let {items = []} = this.state;

    if (events && id === this.props.event.id) {
      items = events;
    }
    criticalEventsStore.clearCriticalEvents();

    const columns = [
      {name: 'value', title: 'Value'},
      {name: 'action', title: 'Action'},
      {name: 'createdAt', title: 'Created At'},
      {name: 'createdBy_email', title: 'Created By'},
    ];

    const Cell = withRouter((props) => {
      if (['createdAt'].includes(props.column.name)) {
        return (
          <Table.Cell value={MomentService.displayTimestamp(props.value)} />
        );
      }
      return <Table.Cell value={props.value} />;
    });
    return (
      <div style={{padding: 10}}>
        <Card style={this.props.style}>
          <CardContent>
            <form>
              <Grid rows={items} columns={columns}>
                <EditingState
                  rowChanges={rowChanges}
                  onRowChangesChange={this.changeRowChanges}
                  addedRows={addedRows}
                  onAddedRowsChange={this.changeAddedRows}
                  onCommitChanges={this.commitChanges}
                  columnExtensions={[
                    {columnName: 'id', editingEnabled: false},
                    {columnName: 'action', editingEnabled: false},
                    {columnName: 'createdAt', editingEnabled: false},
                    {columnName: 'createdBy_email', editingEnabled: false},
                  ]}
                />
                <Table
                  cellComponent={Cell}
                  noDataRowComponent={() => (
                    <NoDataLoading
                      loading={loading}
                      numberOfColumns={columns.length + 2}
                    />
                  )}
                />
                <TableHeaderRow />
                <TableEditRow
                  cellComponent={EditCell}
                  showAddCommand={RoleService.hasPermission(
                    'Add CriticalEventActivity',
                    'Add'
                  )}
                />
                <TableEditColumn
                  width={120}
                  commandComponent={Command}
                  showAddCommand={RoleService.hasPermission(
                    'Add CriticalEventActivity',
                    'Add'
                  )}
                />
                <TableColumnVisibility defaultHiddenColumnNames={['id']} />
              </Grid>
            </form>
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles)(CriticalEventActivities);
