import React from 'react';
import {
  ApiOrderGetRequest,
  Order,
  OrderList,
  ContractTripDump,
  OrderDump,
} from '@onroadvantage/onroadvantage-api';
import {useAppNotifications} from '../../../../../contexts';
import {ITripEditTripStop, useTripEditContext} from '../../tripEditContext';
import {orderApi} from '../../../../../api';
import {
  TemplateTableContextProps,
  TemplateTableProps,
  TOnSelectionChange,
  useTemplateTable,
} from '../../../../../factory/template';
import {useTripContext} from '../../../tripContext';
import {TripEditOrderAddActions} from './TripEditOrderAddActions';
import {mapOrderToStops, sortStopsBySequence} from '../../../helpers';

export interface TripEditOrderAddListContextProps
  extends TemplateTableContextProps<Order, OrderList> {
  onAddOrders: () => Promise<void>;
  onClearSelection: () => void;
}

const tripEditContextInitial: TripEditOrderAddListContextProps = {
  loading: false,
  list: [],
  currentPage: 1,
  loadList: async () => {},
  onAddOrders: async () => {},
  onClearSelection: () => null,
};

export const TripEditOrderAddListContext =
  React.createContext<TripEditOrderAddListContextProps>(tripEditContextInitial);

export const TripEditOrderAddListContextProvider: React.FC = ({children}) => {
  const notify = useAppNotifications();
  const {masterTrip} = useTripContext();
  const {orders, stops, setOrders, setStops, closeIsAddingOrders} =
    useTripEditContext();

  /** Destructure contract, need to add || ({} as ContractTripDump) for typescript, since contract is nullable. */
  const {code: contractCode, banOrderTripDoubleAllocation} =
    masterTrip?.trip?.contract || ({} as ContractTripDump);

  const [
    {
      // States
      currentPage,
      filters,
      itemTotal,
      list,
      loading,
      pageSize,
      pageTotal,
      sorting,
    },
    {
      // Getters
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
    },
  ] = useTemplateTable<Order, ApiOrderGetRequest>({
    editPermission: 'Edit MasterTrip',
    deletePermission: 'Delete Trip',
    downloadPermission: 'Trip ListDownload',
    viewPermission: 'Trip List',
  });

  // states
  const [selectedOrders, setSelectedOrders] = React.useState<
    TemplateTableProps<Order>['selection']
  >([]);
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [unassignedOrders, setUnassignedOrders] = React.useState<
    Order[] | undefined
  >();

  // helper handlers
  const handleClearSelection = React.useCallback(() => {
    setSelectedOrders([]);
    closeIsAddingOrders();
  }, [closeIsAddingOrders]);

  const handleSelectionChange = React.useCallback<TOnSelectionChange<Order>>(
    (selection) => {
      setSelectedOrders(selection);
    },
    []
  );

  // load handlers
  const loadList = React.useCallback<
    TripEditOrderAddListContextProps['loadList']
  >(
    async (options) => {
      setLoading(true);
      try {
        const requestObj = getRequestObj(
          [
            'contractCode',
            'orderNumber',
            'upliftPointName',
            'offloadPointName',
          ],
          options
        );

        // Set get_unassigned_orders_only based on ban_order_trip_double_allocation
        requestObj.getUnassignedOrdersOnly =
          banOrderTripDoubleAllocation === true;

        const response = await orderApi.apiOrderGet({
          ...requestObj,
          contractCode,
        });

        return getResponse(response, options);
      } catch (e) {
        notify('error', 'Failed to load orders');
      } finally {
        setLoading(false);
      }
    },
    [
      contractCode,
      banOrderTripDoubleAllocation,
      getRequestObj,
      getResponse,
      notify,
      setLoading,
    ]
  );

  // update handlers
  const handleAddOrders = React.useCallback(async () => {
    setSubmitting(true);
    try {
      if (selectedOrders && selectedOrders.length > 0) {
        const newOrders: OrderDump[] = [];
        const newStops: Partial<ITripEditTripStop>[] = [];

        /** retrieve the last stop as well as the last stop's sequence */
        const lastStop =
          stops && stops.length > 0 ? stops[stops.length - 1] : null;
        let lastSequence = lastStop?.sequence ?? 1;

        /** Loop over all the selectedOrders */
        for (const order of selectedOrders) {
          /** Map the order to 2 stops for the order's upliftPoint and offloadPoint */
          const stops = await mapOrderToStops(order, lastSequence);
          if (stops) {
            /** Append the order and mapped stops to the newOrders an newStops local constants */
            newOrders.push(order as OrderDump);
            newStops.push(...stops);
            /** Increment the lastSequence by 2 since we added 2 new stops */
            lastSequence += 2;
          }
        }

        /** If there are newOrders, add them to the orders state */
        if (newOrders.length > 0) {
          setOrders((prevOrders) => newOrders.concat(prevOrders ?? []));
        }

        /**
         * If there are newStops, add them to the stop's state and update the lastStop's sequence to the new
         * lastSequence. Then we also need to sort them by the sequence to get the lastStop back to the end
         */
        if (newStops.length > 0) {
          setStops((prevStops) =>
            (
              prevStops?.map((stop) =>
                stop.id === lastStop?.id
                  ? {...stop, sequence: lastSequence}
                  : stop
              ) ?? []
            )
              .concat(newStops)
              .sort(sortStopsBySequence)
          );
        }
      }
    } finally {
      /**
       * After everything's updated, close the addingOrders table, set submitting to false and set the selectedOrders
       * back to an empty array (otherwise when they decide to add more orders, it will still have the previously added
       * orders, thus duplicating the previously added orders)
       */
      closeIsAddingOrders();
      setSelectedOrders([]);
      setSubmitting(false);
    }
  }, [closeIsAddingOrders, selectedOrders, setOrders, setStops, stops]);

  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  React.useEffect(() => {
    if (list.length > 0) {
      /** Filter out all the orders that are already assigned to the trip */
      const assignedOrderIds = orders?.map(({id}) => id);

      setUnassignedOrders(
        list.filter(({id}) => !assignedOrderIds?.includes(id))
      );
    }
  }, [list, orders]);

  const value: TripEditOrderAddListContextProps = {
    loading: loading || submitting,
    list: unassignedOrders ?? [],
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    selection: selectedOrders,
    onSelectionChange: handleSelectionChange,
    onFiltersChange: handleFiltersChange,
    onSortingChange: handleSortingChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onRefresh: handleRefresh,
    loadList,
    cleanupList,
    toolbarCustomAction: <TripEditOrderAddActions />,
    onAddOrders: handleAddOrders,
    onClearSelection: handleClearSelection,
  };

  return (
    <TripEditOrderAddListContext.Provider value={value}>
      {children}
    </TripEditOrderAddListContext.Provider>
  );
};
