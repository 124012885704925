import React from 'react';
import {Button, Stack, Tab, Tabs} from '@mui/material';
import {VantageBadge} from '../../../badge';
import {PlanningBoardPlanToolbarReportButton} from './PlanningBoardPlanToolbarReportButton';
import {PlanningBoardPlanToolbarCommit} from './PlanningBoardPlanToolbarCommit';
import {useTemplateTabControllerStyles} from '../../../../factory/template/tabs/TemplateTabs.style';
import {
  usePlanningBoardContext,
  usePlanningBoardGanttContext,
} from '../../planningBoardContext';

interface PlanningBoardPlanToolbarProps {
  activeTab: number;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
}

export const PlanningBoardPlanToolbar: React.FC<
  PlanningBoardPlanToolbarProps
> = ({activeTab, setActiveTab}) => {
  const classes = useTemplateTabControllerStyles();
  const {bookingMasterTrips, unassignedOrders} = usePlanningBoardContext();
  const {setSelectedGanttItem} = usePlanningBoardGanttContext();

  const handleTabChange = React.useCallback(
    (event: any, value: number) => {
      setActiveTab(value);
    },
    [setActiveTab]
  );

  const numberOfBookingMasterTrips = bookingMasterTrips.length;

  const handleIsCreatingBooking = React.useCallback(() => {
    setSelectedGanttItem({
      id: -1,
      content: 'Creating Booking',
      start: new Date(),
      variant: 'booking',
      data: null,
      vehicle: undefined,
    });
  }, [setSelectedGanttItem]);

  return (
    <Tabs
      // className={classes.tabs}
      value={activeTab}
      // classes={{scroller: classes.tabs}}
      onChange={handleTabChange}
      // indicatorColor="primary"
      // textColor="primary"
    >
      <Tab
        classes={{root: classes.tab}}
        label={
          <VantageBadge
            color="primary"
            max={999}
            badgeContent={unassignedOrders.length}
          >
            Unassigned Orders
          </VantageBadge>
        }
      />
      <Tab
        classes={{root: classes.tab}}
        label={
          <VantageBadge
            badgeContent={numberOfBookingMasterTrips}
            color="primary"
            invisible={numberOfBookingMasterTrips === 0}
          >
            Commit
          </VantageBadge>
        }
      />
      <Tab classes={{root: classes.tab}} label="Overview" />
      {activeTab === 2 ? (
        <PlanningBoardPlanToolbarReportButton />
      ) : activeTab === 1 ? (
        <Stack
          px={1}
          flex={1}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <PlanningBoardPlanToolbarCommit />
        </Stack>
      ) : (
        <Stack
          px={1}
          flex={1}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button variant="outlined" onClick={handleIsCreatingBooking}>
            Create Booking
          </Button>
        </Stack>
      )}
    </Tabs>
  );
};
