import React from 'react';
import {
  PlanningBoardContextProvider,
  PlanningBoardSettingsContextProvider,
  PlanningBoardGanttContextProvider,
} from './planningBoardContext';
import {PlanningBoardPanels} from './planningBoardPanels';

export const PlanningBoardController: React.FC = () => {
  return (
    <PlanningBoardSettingsContextProvider>
      <PlanningBoardContextProvider>
        <PlanningBoardGanttContextProvider>
          <PlanningBoardPanels />
        </PlanningBoardGanttContextProvider>
      </PlanningBoardContextProvider>
    </PlanningBoardSettingsContextProvider>
  );
};
