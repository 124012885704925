import React from 'react';
import {Button, Collapse} from '@mui/material';
import {VantageDialogButton} from '../../../dialog';
import {usePlanningBoardContext} from '../../planningBoardContext';

export const PlanningBoardPlanToolbarCommit: React.FC = () => {
  const {
    bookingMasterTripsToCommit,
    commitBookingMasterTrips,
    setBookingMasterTripsToCommit,
  } = usePlanningBoardContext();

  const enableSaveChanges = React.useMemo(
    () => bookingMasterTripsToCommit.length > 0,
    [bookingMasterTripsToCommit]
  );

  const handleCancelChanges = React.useCallback(() => {
    setBookingMasterTripsToCommit([]);
  }, [setBookingMasterTripsToCommit]);

  return (
    <Collapse in={enableSaveChanges}>
      <VantageDialogButton
        title="Commit"
        dialogTitle="Are you sure you want to commit?"
        variant="contained"
        onAccept={commitBookingMasterTrips}
        size="small"
      />
      <Button
        size={'small'}
        style={{margin: '0 0.5rem'}}
        onClick={handleCancelChanges}
      >
        Cancel
      </Button>
    </Collapse>
  );
};
