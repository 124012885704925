import React from 'react';
import {ContactGroupContext} from './ContactGroupContext';
import {
  TemplateTable,
  TemplateTableConfig,
  TOnInlineAdd,
} from '../../factory/template';
import {Listing, VehicleGroup} from '@onroadvantage/onroadvantage-api';
import {contactGroupApi} from '../../api';
import {useAppNotifications} from '../../contexts';

export const ContactGroupVehicles: React.FC = () => {
  const notify = useAppNotifications();
  const {
    contactGroup,
    contactGroupId,
    loading,
    loadContactGroup,
    hasPermission,
  } = React.useContext(ContactGroupContext);
  const [localLoading, setLocalLoading] = React.useState<boolean>(false);

  const [config] = React.useState<TemplateTableConfig<VehicleGroup>>({
    columns: [
      {
        name: 'name',
        label: 'Name',
        type: 'string',
        model: 'VehicleGroup',
        enableEditing: true,
        enableAutocomplete: true,
        enableMulti: true,
      },
    ],
    disableToolbar: true,
    disablePagination: true,
    deleteMessage: (row) => ({
      title: 'Contact Group Vehicle',
      items: {
        ContactGroupName: contactGroup?.name,
        name: row.name,
      },
    }),
    identifier: 'CONTACT_GROUP_VEHICLES_LIST',
  });

  const handleInlineAdd = React.useCallback<TOnInlineAdd>(
    async (changes) => {
      setLocalLoading(true);
      try {
        for (const change of changes) {
          const vehicleGroupListings: Listing[] = change.name;
          const vehicleGroupIds = vehicleGroupListings
            .filter(({value}) => value)
            .map(({value}) => value as number);
          if (contactGroupId) {
            await contactGroupApi.apiContactGroupContactGroupIdAssignVehicleGroupsPost(
              {
                contactGroupId,
                body: {vehicleGroupIds},
              }
            );
            await loadContactGroup();
          }
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to assign Vehicle Group');
      } finally {
        setLocalLoading(false);
      }
    },
    [contactGroupId, loadContactGroup, notify]
  );

  const handleInlineDelete = React.useCallback(
    async (row: VehicleGroup) => {
      setLocalLoading(true);
      try {
        if (contactGroupId && row.id) {
          await contactGroupApi.apiContactGroupContactGroupIdUnassignVehicleGroupPost(
            {
              contactGroupId,
              body: {vehicleGroupId: row.id},
            }
          );
          await loadContactGroup();
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to unassign Vehicle Group');
      } finally {
        setLocalLoading(false);
      }
    },
    [contactGroupId, loadContactGroup, notify]
  );

  return (
    <TemplateTable
      config={config}
      list={contactGroup?.vehicleGroups ?? []}
      currentPage={1}
      loading={loading || localLoading}
      onDelete={hasPermission?.edit ? handleInlineDelete : undefined}
      onInlineAdd={hasPermission?.edit ? handleInlineAdd : undefined}
    />
  );
};
