import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {MenuItem, TextField} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {
  AccountBox as AccountBoxIcon,
  Assessment as AssessmentIcon,
  Assignment as AssignmentIcon,
  ChevronRight as ChevronRightIcon,
  ContactSupport as SupportIcon,
  DataObject,
  DepartureBoard as PlanningIcon,
  Dns as DnsIcon,
  EmojiObjects,
  KeyboardArrowDown as ChevronDownIcon,
  NextWeek as NextWeekIcon,
  PlaylistPlay as PlaylistPlayIcon,
  PowerSettingsNew as PowerSettingsNewIcon,
  RssFeed as RssFeedIcon,
  Search as SearchIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';

import {
  MenuLabelIconContainer,
  MenuLabelLayout,
  MenuLabelSpan,
} from './LeftSidebarStyle';
import RoleService from '../../service/RoleService';
import LeftSidebarTop from './LeftSidebarTop';
import {ThemeModeContext} from '../../contexts';

const styles = () => ({
  listText: {
    color: '#0000008a',
    fontSize: 16,
    fontWeight: 400,
    fontFamily: 'Roboto ,Helvetica, Arial, sans-serif',
    wordWrap: 'break-word',
    lineHeight: '1.5em',
  },
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
});

// populates the navigation tree.
// first layer is the sections, subItems are the menu items.
const navigationTree = {
  planning: {
    order: 0,
    label: 'Planning',
    icon: <AssignmentIcon />,
    path: '/planning',
    items: {
      optimiser: {
        order: 0,
        icon: <PlanningIcon />,
        label: 'Optimiser',
        permission: 'PlanningSolution List',
        path: '/planningsolutionlist',
      },
      masterRoutes: {
        order: 1,
        icon: <PlanningIcon />,
        label: 'Master Routes',
        permission: 'MasterRoute List',
        path: '/masterroutelist',
      },
      vehicleBookingAvailability: {
        order: 4,
        icon: null,
        label: 'Vehicle Booking Availability',
        permission: 'Booking List',
        path: '/vehicle_booking_availability',
      },
      planningBoard: {
        order: 5,
        icon: null,
        label: 'Planning Board',
        permission: 'PlanningBoard View',
        path: '/planningboard',
      },
    },
  },
  execution: {
    order: 1,
    label: 'Execution',
    icon: <PlaylistPlayIcon />,
    path: '/execution',
    items: {
      trips: {
        order: 0,
        icon: '',
        label: 'Trips',
        permission: 'Trip List',
        path: '/triplist',
      },
      orders: {
        order: 1,
        icon: '',
        label: 'Orders',
        permission: 'Order List',
        path: '/orderlist',
      },
      tasks: {
        order: 2,
        icon: '',
        label: 'Tasks',
        permission: 'Task List',
        path: '/tasklist',
      },
      workflows: {
        order: 3,
        icon: '',
        label: 'Workflows',
        permission: 'Workflow List',
        path: '/workflowlist',
      },
    },
  },
  debriefing: {
    order: 2,
    label: 'Debriefing',
    icon: <NextWeekIcon />,
    path: '/debriefing',
    items: {
      tripDebriefs: {
        order: 2,
        label: 'Trip Debriefs',
        permission: 'TripDebrief List',
        path: '/tripdebrieflist',
      },
      tripDebriefConfigs: {
        order: 3,
        icon: null,
        label: 'Trip Debrief Configs',
        permission: 'MasterTripDebriefConfig List',
        path: '/tripdebriefconfiglist',
      },
    },
  },
  analysis: {
    order: 3,
    label: 'Analysis',
    icon: <AssessmentIcon />,
    path: '/analysis',
    items: {
      dashboards: {
        order: 0,
        label: 'Dashboards',
        permission: 'Dashboard List',
        path: '/dashboardlist',
      },
      reports: {
        order: 1,
        label: 'Reports',
        permission: 'Report List',
        path: '/reportlist',
      },
    },
  },
  broadcasting: {
    order: 4,
    label: 'Broadcasting',
    icon: <RssFeedIcon />,
    path: '/broadcasting',
    items: {
      broadcast: {
        order: 0,
        label: 'Broadcast',
        permission: 'Broadcast',
        icon: null,
        path: '/broadcast',
      },
      broadcasts: {
        order: 1,
        label: 'Broadcasts',
        permission: 'Broadcast List',
        icon: null,
        path: '/broadcastlist',
      },
    },
  },
  labs: {
    order: 9,
    label: 'Vantage Labs',
    permission: 'View Vantage Labs',
    icon: <EmojiObjects />,
    path: '/labs',
    items: {
      templateEditor: {
        order: 1,
        label: 'Template Editor',
        icon: null,
        path: '/experimental/wysiwyg',
      },
      notificationConfig: {
        order: 2,
        label: 'Notification Configs',
        permission: 'NotificationConfig List',
        icon: null,
        path: '/notificationconfiglist',
      },
      documentTemplate: {
        order: 3,
        label: 'Document Templates',
        permission: 'DocumentTemplate List',
        icon: null,
        path: '/documenttemplatelist',
      },
    },
  },
  masterData: {
    order: 5,
    label: 'Master Data',
    icon: <DnsIcon />,
    path: '/masterData',
    items: {
      audiences: {
        order: 0,
        icon: null,
        label: 'Audience',
        permission: 'Contact List',
        path: '/contactlist',
      },
      audiencegroups: {
        order: 1,
        icon: null,
        label: 'Audience Groups',
        permission: 'ContactGroup List',
        path: '/contactgrouplist',
      },
      contracts: {
        order: 2,
        icon: null,
        label: 'Contracts',
        permission: 'Contract List',
        path: '/contractlist',
      },
      contractGroups: {
        order: 3,
        icon: null,
        label: 'Contract Groups',
        permission: 'ContractGroup List',
        path: '/contractgrouplist',
      },
      vehicles: {
        order: 4,
        icon: null,
        label: 'Vehicles',
        permission: 'Vehicle List',
        path: '/vehiclelist',
      },
      sites: {
        order: 5,
        icon: null,
        label: 'Sites',
        permission: 'Site List',
        path: '/sitelist',
      },
      driverDocuments: {
        order: 6,
        icon: null,
        label: 'Driver Documents',
        permission: 'DriverDocument List',
        path: '/driverdocumentlist',
      },
      drivers: {
        order: 7,
        icon: null,
        label: 'Drivers',
        permission: 'Driver List',
        path: '/driverlist',
      },
      transporters: {
        order: 8,
        icon: null,
        label: 'Transporters',
        permission: 'Transporter List',
        path: '/transporterlist',
      },
      customers: {
        order: 9,
        icon: null,
        label: 'Customers',
        permission: 'Customer List',
        path: '/customerlist',
      },
      products: {
        order: 10,
        icon: null,
        label: 'Products',
        permission: 'Product List',
        path: '/productlist',
      },
      uploadData: {
        order: 11,
        icon: null,
        label: 'Uploads',
        permission: 'Upload Data',
        path: '/upload',
      },
      vehicleTypes: {
        order: 12,
        icon: null,
        label: 'Vehicle Types',
        permission: 'VehicleType List',
        path: '/vehicletypelist',
      },
      planningSkills: {
        order: 13,
        icon: null,
        label: 'Planning Skills',
        permission: 'PlanningSkill List',
        path: '/planningskilllist',
      },
      trainingCourse: {
        order: 14,
        icon: null,
        label: 'Training Courses',
        permission: 'TrainingCourse List',
        path: '/trainingcourselist',
      },
      incentives: {
        order: 15,
        icon: null,
        label: 'Driver Rank Incentives',
        permission: 'DriverRankIncentive List',
        path: '/driverrankincentivelist',
      },
      vehicleGroups: {
        order: 16,
        icon: null,
        label: 'Vehicle Groups',
        permission: 'VehicleGroup List',
        path: '/vehiclegrouplist',
      },
      productGroups: {
        order: 17,
        icon: null,
        label: 'Product Groups',
        permission: 'ProductGroup List',
        path: '/productgrouplist',
      },
      workflowConfigs: {
        order: 18,
        icon: null,
        label: 'Workflow Configs',
        permission: 'WorkflowConfig List',
        path: '/workflowconfiglist',
      },
      driverGroups: {
        order: 19,
        icon: null,
        label: 'Driver Groups',
        permission: 'DriverGroup List',
        path: '/drivergrouplist',
      },
      speedTables: {
        order: 20,
        icon: null,
        label: 'Speed Tables',
        permission: 'SpeedTable List',
        path: '/speedtablelist',
      },
      eventTypes: {
        order: 21,
        icon: null,
        label: 'Event Types',
        permissions: [
          {permissionName: 'CriticalEventType List', type: 'View'},
          {permissionName: 'OperationalEventType List', type: 'View'},
        ],
        path: '/eventtypes',
      },
      contractEventTypeConfigs: {
        order: 21,
        icon: null,
        label: 'Contract Event Type Configs',
        permissions: [
          {
            permissionName: 'ContractCriticalEventTypeConfig List',
            type: 'View',
          },
          {
            permissionName: 'ContractOperationalEventTypeConfig List',
            type: 'View',
          },
        ],
        path: '/contracteventtypeconfigs/critical',
      },
      bulkClosing: {
        order: 22,
        icon: null,
        label: 'Bulk Closing',
        permissions: [{permissionName: 'CriticalEvent List', type: 'View'}],
        path: '/bulkClosing',
      },
    },
  },
  settings: {
    order: 6,
    label: 'Settings',
    icon: <SettingsIcon />,
    path: '/settings',
    items: {
      profiles: {
        order: 0,
        icon: null,
        label: 'Profiles',
        permission: 'Profile List',
        path: '/profilelist',
      },
      nodeTypes: {
        order: 1,
        icon: null,
        label: 'Node Types',
        permission: 'NodeType List',
        path: '/nodetypelist',
      },
      taskTemplates: {
        order: 2,
        icon: null,
        label: 'Task Templates',
        permission: 'TaskTemplate List',
        path: '/tasktemplatelist',
      },
      users: {
        order: 3,
        icon: null,
        label: 'Users',
        permission: 'User List',
        path: '/userlist',
      },
      permissions: {
        order: 4,
        icon: null,
        label: 'Permissions',
        permission: 'Permission List',
        path: '/permissionlist',
      },
      roles: {
        order: 5,
        icon: null,
        label: 'Roles',
        permission: 'Role List',
        path: '/rolelist',
      },
      criticalEventType: {
        order: 6,
        icon: null,
        label: 'Critical Event Types',
        permission: 'CriticalEventType List',
        path: '/criticaleventtypelist',
      },
      telematicsConfig: {
        order: 7,
        icon: null,
        label: 'Telematics Configs',
        // IMPORTANT: this will always be superadmin and should not be changed
        permission: 'superadmin',
        path: '/telematicsconfiglist',
      },
    },
  },
  support: {
    order: 10,
    label: 'Support',
    icon: <SupportIcon />,
    path: '/support',
    items: {
      create: {
        order: 0,
        icon: null,
        label: 'Create A Ticket',
        permission: 'Support',
        path: '/support/create',
      },
    },
  },
  user: {
    order: 11,
    label: 'User',
    icon: <AccountBoxIcon />,
    path: '/user',
    items: {
      userSettings: {
        order: 0,
        icon: null,
        label: 'User Settings',
        path: '/usersettings',
      },
      userTrainingCourse: {
        order: 0,
        icon: null,
        label: 'Training Courses',
        permission: 'UserTrainingCourse List',
        path: '/usertrainingcourselist',
      },
    },
  },
  apiDocs: {
    order: 12,
    label: 'API Documentation',
    icon: <DataObject />,
    path: '/apidocs',
    items: {
      clientApiDocs: {
        order: 0,
        label: 'Client API Docs ',
        permission: 'Client API Docs',
        path: '/clientapidocs',
      },
      developerApiDocs: {
        order: 1,
        label: 'Developer API Docs ',
        permission: 'Developer API Docs',
        path: '/developerapidocs',
      },
      integrationApiDocs: {
        order: 2,
        label: 'Integration API Docs ',
        permission: 'Integration API Docs',
        path: '/integrationapidocs',
      },
      trackingApiDocs: {
        order: 3,
        label: 'Tracking API Docs ',
        permission: 'Tracking API Docs',
        path: '/trackingapidocs',
      },
      functionalDocs: {
        order: 4,
        label: 'Functional Docs',
        permission: 'Functional Docs',
        path: '/functionaldocs',
      },
    },
  },
};

// filters menu items both on filter term, but also by checking user rights to view a section
export function filterMenu({filter = ''}) {
  // temporary value for debugging. Allows dev to see all menu items, even if doesn't have viewing rights
  const showAllItems = false;

  // reducers used to keep object based format and handle it in stages of construction
  return Object.entries(navigationTree).reduce((prevSection, currSection) => {
    let validPermission = true;

    if (
      currSection[1].permission &&
      !RoleService.hasPermission(currSection[1].permission, 'View')
    ) {
      validPermission = false;
    }

    if (
      currSection[1].permissions &&
      currSection[1].permissions.length > 0 &&
      !RoleService.hasOneOfPermissions(currSection[1].permissions)
    ) {
      validPermission = false;
    }

    if (validPermission) {
      // first construct a list of items inside of a section that passes filter and viewing rights
      const filteredItems = Object.entries(currSection[1].items).reduce(
        (prevItem, currItem) => {
          // return only items that are allowed for using the search filter
          if (currItem[1].label.toLowerCase().includes(filter)) {
            // then check whether or not the user has rights to view menu item (showAllItems = debug to see everything)
            if (
              RoleService.hasPermission(currItem[1].permission, 'View') ||
              currItem[1].permission == null ||
              showAllItems
            ) {
              return {...prevItem, [currItem[0]]: currItem[1]};
            }
            // Super Admin Section
            if (RoleService.hasRole('superadmin')) {
              return {...prevItem, [currItem[0]]: currItem[1]};
            }
          }
          return prevItem;
        },
        {}
      );
      // only add a section to the list if it has non-filtered children
      if (Object.keys(filteredItems).length > 0) {
        return {
          ...prevSection,
          [currSection[0]]: {...currSection[1], items: filteredItems},
        };
      }
    }
    return prevSection;
  }, {});
}

// handles filter based highlighting of parts of words on menu items
function HighlightedText({label, filter, isSelected}) {
  const {themeMode} = React.useContext(ThemeModeContext);

  // Split on highlight term and include term into parts, ignore case
  const labelParts = label.split(new RegExp(`(${filter})`, 'gi'));
  return (
    <span style={{paddingLeft: 35}}>
      {' '}
      {labelParts.map((part, i) => {
        const isPartSelected = part.toLowerCase() === filter.toLowerCase();
        // fixes the render bug issue
        const dynamicKey = `${label}${part}${i}`;
        return (
          <span
            key={dynamicKey}
            style={{
              color: themeMode === 'light' ? '#6C6C6C' : '#bbbbbb',
              fontWeight:
                isPartSelected || (isSelected && filter === '')
                  ? 'bold'
                  : undefined,
              textDecoration: isPartSelected ? 'underline' : undefined,
            }}
          >
            {part}
          </span>
        );
      })}{' '}
    </span>
  );
}

function SectionLabel({
  name,
  label,
  icon,
  toggleMenuOpen,
  selected,
  style = {},
  hasChildren,
}) {
  const {themeMode} = React.useContext(ThemeModeContext);
  return (
    <MenuItem
      style={{margin: 0, padding: 10, ...style}}
      onClick={() => {
        toggleMenuOpen(name);
      }}
    >
      <MenuLabelSpan
        style={{color: themeMode === 'light' ? '#6C6C6C' : '#bbbbbb'}}
      >
        <MenuLabelLayout>
          <MenuLabelIconContainer>{icon}</MenuLabelIconContainer>
          {label}
        </MenuLabelLayout>
        {hasChildren && (selected ? <ChevronDownIcon /> : <ChevronRightIcon />)}
      </MenuLabelSpan>
    </MenuItem>
  );
}

// ItemLabel handles general styling for section sub items
function ItemLabel({filter, label, onClick, isSelected}) {
  return (
    <MenuItem style={{margin: 0, padding: 10}} onClick={onClick}>
      <HighlightedText label={label} filter={filter} isSelected={isSelected} />
    </MenuItem>
  );
}

function getCurrentlyOpenMenu(pathname, menuLayout) {
  let itemKey;
  let sectionKey;
  pathname &&
    Object.entries(menuLayout).find(([mKey, menu]) => {
      if (menu.path === pathname) {
        // only open section menu
        itemKey = '';
        sectionKey = mKey;
        return true;
      }
      return Object.entries(menu.items).find(([iKey, item]) => {
        if (item.path === pathname) {
          itemKey = iKey;
          sectionKey = mKey;
          return true;
        }
        return false;
      });
    });
  return [sectionKey || '', itemKey || ''];
}

const NavigationMenu = ({menuItemClicked}) => {
  const history = useHistory();
  const shouldMenuSwitch = true; // whether or not we should toggle menu items or switch between them

  // search filter, determining which menu items are shown and which parts are highlighted
  const [filter, setFilter] = React.useState('');
  const menuLayout = filterMenu({filter});

  // get the current menu from the route:
  const currentlyOpenMenu = getCurrentlyOpenMenu(
    history?.location?.pathname,
    menuLayout
  );
  const currentlyOpenMenuKey = currentlyOpenMenu && currentlyOpenMenu[0];

  // sets initial state of all menu items to false (closed)
  const menuItemsInitialState = Object.keys(menuLayout).reduce(
    (prevItem, currItem) => {
      return {...prevItem, [currItem]: false};
    },
    {}
  );
  // controls the state of which sections are open, showing sub items
  const [menuItemsOpen, setMenuItemsOpen] = React.useState({
    ...menuItemsInitialState,
    [currentlyOpenMenuKey]: true,
  });

  // controls the currently selected menu item and includes in which menu it is found
  const [selectedMenuItem, setSelectedMenuItem] = React.useState(
    currentlyOpenMenu || ['', '']
  );

  useEffect(() =>
    history.listen(() => {
      // rerender on location changed
      const currentlyOpenMenu = getCurrentlyOpenMenu(
        history?.location?.pathname,
        menuLayout
      );
      selectedMenuItem[0] !== currentlyOpenMenu[0] &&
        currentlyOpenMenu[1] === '' &&
        setMenuItemsOpen({
          ...menuItemsInitialState,
          [currentlyOpenMenu[0]]: true,
        });
    })
  );

  // opens or closes a section, showing sub items. shouldMenuSwitch determines if it is a toggle or a switch action
  const toggleMenuOpen = (menuItem) => {
    if (shouldMenuSwitch) {
      setMenuItemsOpen({
        ...menuItemsInitialState,
        [menuItem]: !menuItemsOpen[menuItem],
      });
    } else {
      setMenuItemsOpen({
        ...menuItemsOpen,
        [menuItem]: !menuItemsOpen[menuItem],
      });
    }
  };

  if (currentlyOpenMenu && selectedMenuItem[1] !== currentlyOpenMenu[1]) {
    setSelectedMenuItem(currentlyOpenMenu);
    toggleMenuOpen(currentlyOpenMenu[0]);
  }

  // checks to see whether or not to show/hide a section because of no items present (no access or because filtered)
  const noVisibleItems = Object.keys(menuLayout).length === 0;

  function clearFilter() {
    setFilter('');
  }

  function handleMenuItemClick([itemName, item], [sectionName]) {
    // after clicking set section to be highlighted.
    if (!menuItemsOpen[sectionName]) {
      setMenuItemsOpen({
        ...menuItemsInitialState,
        [sectionName]: !menuItemsOpen[sectionName],
      });
    }
    // reset filter
    if (filter !== '') {
      clearFilter();
    }
    menuItemClicked && menuItemClicked();

    // sets selected menu item
    setSelectedMenuItem([sectionName, itemName]);

    history.push(item.path);
  }

  return (
    <div style={{width: '100%'}}>
      <TextField
        label="Search"
        size="small"
        sx={{display: 'flex', flex: 1, my: 1, mx: 1}}
        value={filter}
        onChange={(e) => {
          setFilter(e.target.value.toLowerCase());
        }}
        InputProps={{
          endAdornment: <SearchIcon htmlColor="#6C6C6C" />,
        }}
      />
      <div>
        {noVisibleItems && (
          <ItemLabel
            isSelected={false}
            filter=""
            label="No menu items found"
            onClick={() => {
              clearFilter();
            }}
          />
        )}
        {
          // iterate through menuLayout, using entries to grab the object keys as well for menu logic
          Object.entries(menuLayout).map((section) => {
            // checks if section should be shown or not
            const showSection = menuItemsOpen[section[0]];
            const highlightSection = showSection && filter === '';
            return (
              <div
                key={section[0]}
                style={{
                  backgroundColor: highlightSection
                    ? 'rgba(0, 130, 112, 0.05)'
                    : undefined,
                }}
              >
                <div
                  style={{
                    backgroundColor: highlightSection
                      ? 'rgba(0, 130, 112, 0.05)'
                      : undefined,
                  }}
                >
                  <SectionLabel
                    name={section[0]}
                    label={section[1].label}
                    icon={section[1].icon}
                    hasChildren
                    selected={showSection || filter}
                    toggleMenuOpen={toggleMenuOpen}
                  />
                </div>
                {(showSection || filter !== '') &&
                  Object.entries(section[1].items)
                    .sort((a, b) => {
                      return a < b ? -1 : a > b ? 1 : 0;
                    })
                    .map(([itemName, item]) => {
                      return (
                        <div
                          key={itemName}
                          style={{
                            backgroundColor:
                              selectedMenuItem[1] === itemName
                                ? 'rgba(0, 130, 112, 0.05)'
                                : undefined,
                          }}
                        >
                          <ItemLabel
                            isSelected={selectedMenuItem[1] === itemName}
                            filter={filter}
                            label={item.label}
                            onClick={() => {
                              handleMenuItemClick([itemName, item], section);
                            }}
                          />
                        </div>
                      );
                    })}
              </div>
            );
          })
        }
        <SectionLabel
          name="logout"
          label="Logout"
          icon={<PowerSettingsNewIcon />}
          toggleMenuOpen={() => {
            history.push('/logout');
          }}
        />
      </div>
    </div>
  );
};

const LeftSidebar = (props) => {
  const history = useHistory();
  const {classes, menuItemClicked} = props;

  return (
    <div className={classes.root}>
      <LeftSidebarTop />
      <NavigationMenu history={history} menuItemClicked={menuItemClicked} />
    </div>
  );
};

export default withStyles(styles)(LeftSidebar);
