import {makeStyles, createStyles} from '@mui/styles';

const ReportScheduleFormStyles = () =>
  createStyles({
    content: {},
    panel: {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
    },
  });

export const useReportScheduleFormStyles = makeStyles(
  ReportScheduleFormStyles,
  {name: 'ReportScheduleFormStyles'}
);
