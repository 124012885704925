import React from 'react';
import {
  TripDebriefSummaryCard,
  TripDebriefSummaryCardList,
} from './tripDebriefSummaryCard';
import {DateTimeFormatService} from '../../../service/FormatService';
import {TripDebriefContext} from '../TripDebriefContext';
import {MasterTripDebriefSnapshot} from '@onroadvantage/onroadvantage-api';

export const TripDebriefSummaryActivity: React.FC = () => {
  const {masterTripDebriefData} = React.useContext(TripDebriefContext);
  /**
   *  Destructure snapshotData, need to add || ({} as MasterTripDebriefSnapshot) for typescript, since
   *  snapshotData is nullable.
   */
  const {activities} =
    masterTripDebriefData?.snapshotData || ({} as MasterTripDebriefSnapshot);

  const list: TripDebriefSummaryCardList = React.useMemo(() => {
    if (activities && activities.length > 0) {
      return activities.map((activity) => ({
        secondary: `${activity.createdBy?.email}  (${
          activity.createdAt
            ? new DateTimeFormatService().formatDateTime(activity.createdAt)
            : '-'
        })`,
        primary: activity.value,
        type: 'string',
        changed: false,
      }));
    }
    return [];
  }, [activities]);

  return <TripDebriefSummaryCard title="Activity" list={list} />;
};
