import React from 'react';
import {useHistory} from 'react-router-dom';
import {
  ApiCustomerGetRequest,
  Customer,
  CustomerListingResponse,
  CustomerPost,
} from '@onroadvantage/onroadvantage-api';
import {FormikHelpers, FormikProps} from 'formik/dist/types';
import {useAppNotifications} from '../../contexts';
import {customerApi} from '../../api';
import {
  TemplateTableContextProps,
  TLoadList,
  useTemplateTable,
} from '../../factory/template';
import {ICustomerForm} from './CustomerForm';

export interface CustomerContextProps
  extends TemplateTableContextProps<Customer, CustomerListingResponse> {
  loadCustomer: () => void;
  onDetailsFormSubmit: (
    values: ICustomerForm,
    formikHelpers: FormikHelpers<ICustomerForm>
  ) => void;
  setCustomerId: (value: number | undefined) => void;
  submitting: boolean;
  loadingSingleItem: boolean;
  customer?: Customer;
  customerId?: number;
  detailsRef?: React.Ref<FormikProps<ICustomerForm>>;
}

export const CustomerContext = React.createContext<CustomerContextProps>({
  // Template Table Defaults
  loading: false,
  list: [],
  currentPage: 1,
  // Customer
  loadList: async () => {},
  loadCustomer: () => null,
  onDetailsFormSubmit: () => null,
  setCustomerId: () => null,
  submitting: false,
  loadingSingleItem: false,
});

interface CustomerContextProviderProps {
  customerId?: number;
}

export const CustomerContextProvider: React.FC<
  CustomerContextProviderProps
> = ({children}) => {
  const history = useHistory();
  const notify = useAppNotifications();
  // Template Table
  const [
    {
      // States
      currentPage,
      filters,
      itemTotal,
      hasPermission,
      list,
      loading,
      pageSize,
      pageTotal,
      loadingSingleItem,
      sorting,
    },
    {
      // Getters
      getDownloads,
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
      setLoadingSingleItem,
    },
  ] = useTemplateTable<Customer, ApiCustomerGetRequest>({
    editPermission: 'Edit Customer',
    addPermission: 'Add Customer',
    deletePermission: 'Delete Customer',
    downloadPermission: 'Customer ListDownload',
    viewPermission: 'Customer List',
  });

  const loadList = React.useCallback<TLoadList<CustomerListingResponse>>(
    async (options) => {
      setLoading(true);
      try {
        const requestObj = getRequestObj(
          ['name', 'description', 'accountNumber', 'contractId'],
          options
        );
        const response = await customerApi.apiCustomerGet(requestObj);
        return getResponse(response, options);
      } catch (e) {
        notify('error', e.message ?? 'Failed to load customer list');
      } finally {
        setLoading(false);
      }
    },
    [getRequestObj, getResponse, notify, setLoading]
  );

  const handleDelete = React.useCallback(
    async (row: Customer) => {
      setLoading(true);
      try {
        if (row.id) {
          await customerApi.apiCustomerCustomerIdDelete({
            customerId: row.id,
          });
          await loadList();

          notify('success', 'Deleted customer');
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to delete');
      } finally {
        setLoading(false);
      }
    },
    [loadList, notify, setLoading]
  );

  const handleDownload = React.useCallback(
    () =>
      getDownloads('customers', loadList, [
        {name: 'Name', path: 'name'},
        {name: 'Description', path: 'description'},
        {name: 'Account Number', path: 'accountNumber'},
        {name: 'Contract Code', path: 'contract.code'},
      ]),
    [getDownloads, loadList]
  );

  const handleAdd = React.useCallback(
    () => history.push('/customerlist/add'),
    [history]
  );

  const handleNavigate = React.useCallback(
    (row) => history.push(`/customerlist/${row.id}`),
    [history]
  );
  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  // Forms
  const [customer, setCustomer] = React.useState<Customer | undefined>();
  const [customerId, setCustomerId] = React.useState<number | undefined>();
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const detailsRef = React.useRef<FormikProps<ICustomerForm>>(null);

  const loadCustomer = React.useCallback(async () => {
    setLoadingSingleItem(true);
    try {
      if (customerId) {
        const response = await customerApi.apiCustomerCustomerIdGet({
          customerId,
        });
        setCustomer(response);
      } else {
        setCustomer(undefined);
      }
    } catch (e) {
      notify('error', e.message ?? 'Failed to load customer');
    } finally {
      setLoadingSingleItem(false);
    }
  }, [setLoadingSingleItem, customerId, notify]);

  const handleDetailsFormSubmit = React.useCallback(
    async (
      values: ICustomerForm,
      formikHelpers: FormikHelpers<ICustomerForm>
    ) => {
      setSubmitting(true);
      try {
        const {contract, planningSkills, ...otherValues} = values;
        if (contract.value) {
          const newValues: CustomerPost = {
            ...otherValues,
            planningSkillIds: planningSkills
              ?.filter(({value}) => !!value)
              .map(({value}) => value as number),
            contractId: contract.value,
          };

          if (customerId) {
            await customerApi.apiCustomerCustomerIdPatch({
              customerId,
              body: newValues,
            });
          } else {
            await customerApi.apiCustomerPost({
              body: newValues,
            });
          }
          history.push('/customerlist');
          notify('success', `${customerId ? 'Updated' : 'Added'} customer`);
        }
      } catch (e) {
        notify(
          'error',
          e.message ?? `Failed to ${customerId ? 'update' : 'add'} customer`
        );
      } finally {
        formikHelpers.setSubmitting(false);
        setSubmitting(false);
      }
    },
    [history, notify, setSubmitting, customerId]
  );

  const value: CustomerContextProps = {
    // Template Table
    list,
    loadList,
    hasPermission,
    loading,
    loadingSingleItem,
    cleanupList,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onAdd: hasPermission.add ? handleAdd : undefined,
    onDownload: hasPermission.download ? handleDownload : undefined,
    onNavigate: handleNavigate,
    onDelete: hasPermission.delete ? handleDelete : undefined,
    onRefresh: handleRefresh,
    // Forms
    loadCustomer,
    onDetailsFormSubmit: handleDetailsFormSubmit,
    setCustomerId,
    submitting,
    customer,
    customerId,
    detailsRef,
  };

  React.useEffect(() => {
    loadCustomer();
    return () => setCustomer(undefined);
  }, [loadCustomer]);

  return (
    <CustomerContext.Provider value={value}>
      {children}
    </CustomerContext.Provider>
  );
};
