import React from 'react';
import {TemplateTable, TemplateTableConfig} from '../../../../factory/template';
import {ITripEditTripStop, useTripEditContext} from '../index';
import {TripEditStopListComparison} from '../tripEditStopList/TripEditStopListComparison';
import {getStopPlannedTAT} from '../../../../utils';
import {useTripEditStopListStyles} from '../tripEditStopList/TripEditStopList.style';
import {TripEditSyncListComparison} from './TripEditSyncListComparison';
import {TripEditSyncListActions} from './TripEditSyncListActions';

export const TripEditSyncList: React.FC = () => {
  const classes = useTripEditStopListStyles();
  const {
    stops,
    syncingStopsWithActuals,
    getInitialStop,
    loadStops,
    onCancelSyncStopWithActuals,
  } = useTripEditContext();

  const config: TemplateTableConfig<ITripEditTripStop> = React.useMemo(
    () => ({
      columns: [
        {
          name: 'siteType',
          label: 'Site Type',
          type: 'string',
          getValue: ({node}) => node?.type,
        },
        {
          name: 'taskTemplateNodeType',
          label: 'Task Template Node Type',
          type: 'string',
          getValue: ({id, taskTemplateNodeType}) =>
            getInitialStop(id) ? (
              <TripEditStopListComparison
                initialValue={getInitialStop(id)?.taskTemplateNodeType}
                newValue={taskTemplateNodeType}
              />
            ) : (
              taskTemplateNodeType
            ),
        },
        {
          name: 'siteName',
          label: 'Site Name',
          type: 'string',
          getValue: ({id, node}) =>
            getInitialStop(id) ? (
              <TripEditStopListComparison
                initialValue={getInitialStop(id)?.node?.name}
                newValue={node?.name}
                direction="column"
              />
            ) : (
              node?.name
            ),
        },
        {
          name: 'sequence',
          label: 'Sequence',
          type: 'string',
          getValue: ({id, sequence}) =>
            getInitialStop(id) ? (
              <TripEditStopListComparison
                initialValue={getInitialStop(id)?.sequence}
                newValue={sequence}
              />
            ) : (
              sequence
            ),
        },
        {
          name: 'externalReference',
          label: 'External Reference',
          type: 'string',
        },
        {name: 'arrivalTime', label: 'Planned Arrival Time', type: 'datetime'},
        {
          name: 'departureTime',
          label: 'Planned Departure Time',
          type: 'datetime',
        },
        {
          name: 'totalServiceTime',
          label: 'Planned TAT',
          type: 'string',
          getValue: (row) =>
            getInitialStop(row.id) ? (
              <TripEditSyncListComparison
                initialValue={
                  getInitialStop(row.id)?.totalServiceTime ??
                  getStopPlannedTAT(row)
                }
                newValue={row.totalServiceTime ?? getStopPlannedTAT(row)}
                onCancel={() => onCancelSyncStopWithActuals(row.id)}
              />
            ) : (
              row.totalServiceTime ?? getStopPlannedTAT(row)
            ),
        },
        {
          name: 'totalServiceTimeChangeReason',
          label: 'Change TAT Reason',
          type: 'string',
        },
      ],
      getRowClass: (row) => {
        const rowExists = getInitialStop(row.id);
        if (!rowExists) {
          return classes.newRow;
        }
      },
      disablePagination: true,
      enableGrouping: false,
      identifier: 'TRIP_SYNC_STOP_LIST',
    }),
    [classes.newRow, getInitialStop, onCancelSyncStopWithActuals]
  );

  const handleRefresh = React.useCallback(
    async () => await loadStops({reload: true, syncActuals: true}),
    [loadStops]
  );

  return (
    <TemplateTable
      config={config}
      list={stops ?? []}
      toolbarCustomAction={<TripEditSyncListActions />}
      onRefresh={handleRefresh}
      loading={syncingStopsWithActuals}
      loadingMessage="Syncing..."
      currentPage={1}
    />
  );
};
