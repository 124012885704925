import React from 'react';
import {Button, Stack, Typography} from '@mui/material';
import {MasterTripDocumentStorageSnapshot} from '@onroadvantage/onroadvantage-api';
import {documentStorageApi} from '../../../api';
import {useAppNotifications} from '../../../contexts';
import {Loader} from '../../loader';

interface TripDebriefDocumentProps {
  document: MasterTripDocumentStorageSnapshot;
  downloadingAll: boolean;
}

type DownloadTypes = 'View' | 'Download' | null | undefined;

export const TripDebriefDocument: React.FC<TripDebriefDocumentProps> = ({
  document,
  downloadingAll,
}) => {
  const notify = useAppNotifications();
  const [downloading, setDownloading] = React.useState<boolean>(false);
  const [downloadType, setDownloadType] = React.useState<DownloadTypes>();

  const handleView = React.useCallback(async () => {
    setDownloading(true);
    setDownloadType('View');
    try {
      if (document.id) {
        const response =
          await documentStorageApi.apiDocumentStorageDocumentStorageIdDownloadLinkGet(
            {
              documentStorageId: document.id,
            }
          );

        notify('success', 'Document downloaded');

        if (response && response.downloadLink) {
          window.open(response.downloadLink, '_self');
        }
      }
    } catch (e) {
      notify('error', e.message ?? 'Failed to download document');
    } finally {
      setDownloadType(undefined);
      setTimeout(() => setDownloading(false), 2000);
    }
  }, [document.id, notify]);

  const handleDownload = React.useCallback(async () => {
    setDownloading(true);
    setDownloadType('Download');
    try {
      if (document.id) {
        const response =
          await documentStorageApi.apiDocumentStorageDocumentStorageIdDownloadLinkGet(
            {
              documentStorageId: document.id,
            }
          );

        notify('success', 'Document downloaded');

        if (response && response.downloadLink) {
          window.open(response.downloadLink, '_blank');
        }
      }
    } catch (e) {
      notify('error', e.message ?? 'Failed to download document');
    } finally {
      setDownloadType(undefined);
      setTimeout(() => setDownloading(false), 2000);
    }
  }, [document.id, notify]);

  return (
    <Stack direction="row" justifyContent="space-between">
      <Typography>{document.documentStorageTypeName}</Typography>
      <Stack direction="row">
        <Button
          disabled={downloading || downloadingAll}
          onClick={handleView}
          startIcon={
            <Loader
              size={25}
              loading={downloading && downloadType === 'View'}
            />
          }
        >
          {downloading && downloadType === 'View' ? 'Downloading...' : 'View'}
        </Button>
        <Button
          disabled={downloading || downloadingAll}
          onClick={handleDownload}
          startIcon={
            <Loader
              size={25}
              loading={downloading && downloadType === 'Download'}
            />
          }
        >
          {downloading && downloadType === 'Download'
            ? 'Downloading...'
            : 'Download'}
        </Button>
      </Stack>
    </Stack>
  );
};
