import React from 'react';
import {authStore} from '../../store';
import {Stack} from '@mui/material';
import ConfigService from '../../service/ConfigService/ConfigService';
import FreshdeskWidget from './react-freshdesk-widget';

export const FreshdeskTicketAndArticles = () => {
  const auth = authStore.getAuth;
  const {freshdeskSupportUrl} = ConfigService;
  return (
    <Stack flex={1} width="100%" pt={1}>
      {freshdeskSupportUrl ? (
        <FreshdeskWidget
          url={freshdeskSupportUrl}
          autofill={{requester: auth?.email}}
          formHeight={600}
        />
      ) : null}
    </Stack>
  );
};
