import React from 'react';
import {useHistory} from 'react-router-dom';
import {
  ApiRoleGetRequest,
  Role,
  RoleListingResponse,
  RolePost,
  RoleUpdate,
} from '@onroadvantage/onroadvantage-api';
import {FormikHelpers, FormikProps} from 'formik/dist/types';
import {useAppNotifications} from '../../contexts';
import {roleApi} from '../../api';
import {
  TemplateTableContextProps,
  TLoadList,
  useTemplateTable,
} from '../../factory/template';

export interface RoleContextProps
  extends TemplateTableContextProps<Role, RoleListingResponse> {
  onDetailsFormSubmit: (
    values: RolePost,
    formikHelpers: FormikHelpers<RolePost>
  ) => void;
  onUpdateRole: (
    updatedRole: RoleUpdate,
    type: 'Add' | 'Delete',
    loadingType?: 'internal' | 'external'
  ) => Promise<void>;
  setRoleId: (value: number | undefined) => void;
  submitting: boolean;
  role?: Role;
  roleId?: number;
  detailsRef?: React.Ref<FormikProps<RolePost>>;
}

export const RoleContext = React.createContext<RoleContextProps>({
  // Template Table Defaults
  loading: false,
  list: [],
  currentPage: 1,
  // Role
  loadList: async () => {},
  onDetailsFormSubmit: () => null,
  onUpdateRole: async () => {},
  setRoleId: () => null,
  submitting: false,
});

interface RoleContextProviderProps {
  roleId?: number;
}

export const RoleContextProvider: React.FC<RoleContextProviderProps> = ({
  children,
}) => {
  const history = useHistory();
  const notify = useAppNotifications();
  // Template Table
  const [
    {
      // States
      currentPage,
      pageSize,
      pageTotal,
      sorting,
      hasPermission,
      itemTotal,
      list,
      loading,
      loadingSingleItem,
      filters,
    },
    {
      // Getter
      getDownloads,
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
      setLoadingSingleItem,
    },
  ] = useTemplateTable<Role, ApiRoleGetRequest>({
    editPermission: 'Edit Role',
    addPermission: 'Add Role',
    deletePermission: 'Delete Role',
    downloadPermission: 'Role ListDownload',
    viewPermission: 'Role List',
  });

  const loadList = React.useCallback<TLoadList<RoleListingResponse>>(
    async (options) => {
      setLoading(true);
      try {
        const requestObj = getRequestObj(['name', 'description'], options);
        const response = await roleApi.apiRoleGet(requestObj);
        return getResponse(response, options);
      } catch (e) {
        notify('error', e.message ?? 'Failed to load role list');
      } finally {
        setLoading(false);
      }
    },
    [setLoading, getRequestObj, getResponse, notify]
  );

  const handleDelete = React.useCallback(
    async (row: Role) => {
      setLoading(true);
      try {
        if (row.id) {
          await roleApi.apiRoleRoleIdDelete({
            roleId: row.id,
          });
          await loadList();
          notify('success', 'Deleted role');
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to delete');
      } finally {
        setLoading(false);
      }
    },
    [setLoading, loadList, notify]
  );
  const handleDownload = React.useCallback(
    () =>
      getDownloads('roles', loadList, [
        {name: 'Role Name', path: 'name'},
        {name: 'Description', path: 'description'},
      ]),
    [getDownloads, loadList]
  );

  const handleAdd = React.useCallback(
    () => history.push('/rolelist/add'),
    [history]
  );
  const handleNavigate = React.useCallback(
    (row) => history.push(`/rolelist/${row.id}`),
    [history]
  );
  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  // Forms
  const [role, setRole] = React.useState<Role | undefined>();
  const [roleId, setRoleId] = React.useState<number | undefined>();
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const detailsRef = React.useRef<FormikProps<RolePost>>(null);

  const loadRole = React.useCallback(async () => {
    setLoadingSingleItem(true);
    try {
      if (roleId) {
        const response = await roleApi.apiRoleRoleIdGet({
          roleId,
        });
        setRole(response);
      } else {
        setRole(undefined);
      }
    } catch (e) {
      notify('error', e.message ?? 'Failed to load role');
    } finally {
      setLoadingSingleItem(false);
    }
  }, [setLoadingSingleItem, roleId, notify]);

  const handleDetailsFormSubmit = React.useCallback(
    async (values: RolePost, formikHelpers: FormikHelpers<RolePost>) => {
      setSubmitting(true);
      try {
        const {...otherValues} = values;
        const newValues: RolePost = {...otherValues};

        if (roleId) {
          await roleApi.apiRoleRoleIdPatch({
            roleId,
            body: newValues,
          });
        } else {
          await roleApi.apiRolePost({
            body: newValues,
          });
        }
        history.push('/rolelist');
        notify('success', `${roleId ? 'Updated' : 'Added'} Role`);
      } catch (e) {
        notify(
          'error',
          e.message ?? `Failed to ${roleId ? 'update' : 'add'} role`
        );
      } finally {
        formikHelpers.setSubmitting(false);
        setSubmitting(false);
      }
    },
    [history, notify, setSubmitting, roleId]
  );

  const handleUpdateRole = React.useCallback<RoleContextProps['onUpdateRole']>(
    async (updatedRole, type, loadingType = 'internal') => {
      if (loadingType === 'internal') {
        setLoadingSingleItem(true);
      }
      try {
        if (roleId) {
          const response = await roleApi.apiRoleRoleIdPatch({
            roleId,
            body: updatedRole,
          });
          setRole(response);
          notify('success', type + ' Role');
        }
      } catch (e) {
        notify('error', e.message ?? `Failed to ${type} to Role`);
      } finally {
        if (loadingType === 'internal') {
          setLoadingSingleItem(false);
        }
      }
    },
    [notify, roleId, setLoadingSingleItem]
  );

  const value: RoleContextProps = {
    // Template Table
    list,
    loadList,
    hasPermission,
    loading: loading || loadingSingleItem,
    cleanupList,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onAdd: hasPermission.add ? handleAdd : undefined,
    onDownload: hasPermission.download ? handleDownload : undefined,
    onNavigate: handleNavigate,
    onDelete: hasPermission.delete ? handleDelete : undefined,
    onRefresh: handleRefresh,
    // Forms
    onDetailsFormSubmit: handleDetailsFormSubmit,
    onUpdateRole: handleUpdateRole,
    setRoleId,
    submitting,
    role,
    roleId,
    detailsRef,
  };

  React.useEffect(() => {
    loadRole();
    return () => setRole(undefined);
  }, [loadRole]);

  return <RoleContext.Provider value={value}>{children}</RoleContext.Provider>;
};
