import React from 'react';
import {
  ApiDriverGetRequest,
  Driver,
  DriverListResponse,
} from '@onroadvantage/onroadvantage-api';
import {driverApi} from '../../../../api';
import {
  TemplateTableContextProps,
  TLoadList,
  useTemplateTable,
} from '../../../../factory/template';
import {useAppNotifications} from '../../../../contexts';
import {useBroadcastVantageMobileContext} from '../BroadcastVantageMobileContext';

export type BroadcastVantageMobileDriversStepContextProps =
  TemplateTableContextProps<Driver, DriverListResponse>;

export const BroadcastVantageMobileDriversStepContext =
  React.createContext<BroadcastVantageMobileDriversStepContextProps>({
    loading: false,
    list: [],
    currentPage: 1,
    loadList: async () => {},
    cleanupList: () => null,
  });

export const BroadcastVantageMobileDriversStepContextProvider: React.FC = ({
  children,
}) => {
  const notify = useAppNotifications();
  const {selectedDrivers, setSelectedDrivers} =
    useBroadcastVantageMobileContext();
  // Template Table
  const [
    {
      // States
      currentPage,
      filters,
      hasPermission,
      itemTotal,
      list,
      loading,
      pageSize,
      pageTotal,
      loadingSingleItem,
      sorting,
    },
    {
      // Getters
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
    },
  ] = useTemplateTable<Driver, ApiDriverGetRequest>({
    editPermission: 'Edit Driver',
    addPermission: 'Add Driver',
    deletePermission: 'Delete Driver',
    downloadPermission: 'Driver ListDownload',
    viewPermission: 'Driver List',
  });

  const loadList = React.useCallback<TLoadList<DriverListResponse>>(
    async (options) => {
      setLoading(true);
      try {
        const requestObj = getRequestObj(
          [
            'firstname',
            'lastname',
            'externalEmployeeNumber',
            'externalName',
            'contractCode',
            'contactNumber',
            'licenseType',
            'telematicsConfigId',
            'externalId',
          ],
          options
        );
        const response = await driverApi.apiDriverGet({
          ...requestObj,
          hasSmartphone: true,
        });
        return getResponse(response, options);
      } catch (e) {
        notify('error', 'Failed to load driver list');
      } finally {
        setLoading(false);
      }
    },
    [getRequestObj, getResponse, notify, setLoading]
  );

  const value = React.useMemo<BroadcastVantageMobileDriversStepContextProps>(
    () => ({
      // Template Table
      loadList,
      cleanupList,
      loading: loadingSingleItem || loading,
      hasPermission,
      list,
      currentPage,
      filters,
      itemTotal,
      pageSize,
      pageTotal,
      sorting,
      onFiltersChange: handleFiltersChange,
      onCurrentPageChange: handleCurrentPageChange,
      onPageSizeCountsChange: handlePageSizeCountsChange,
      onSortingChange: handleSortingChange,
      selection: selectedDrivers,
      onSelectionChange: setSelectedDrivers,
    }),
    [
      cleanupList,
      currentPage,
      filters,
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      hasPermission,
      itemTotal,
      list,
      loadList,
      loading,
      loadingSingleItem,
      pageSize,
      pageTotal,
      selectedDrivers,
      setSelectedDrivers,
      sorting,
    ]
  );

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [loadList, cleanupList]);

  return (
    <BroadcastVantageMobileDriversStepContext.Provider value={value}>
      {children}
    </BroadcastVantageMobileDriversStepContext.Provider>
  );
};
