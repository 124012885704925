import {makeStyles, createStyles} from '@mui/styles';

const AdminTelematicsConfigControllerStyles = () =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      minHeight: 10,
    },
    root: {
      // display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    link: {
      cursor: 'pointer',
    },
  });

export const useAdminTelematicsConfigControllerStyles = makeStyles(
  AdminTelematicsConfigControllerStyles,
  {
    name: 'AdminTelematicsConfigControllerStyles',
  }
);
