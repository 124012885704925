import React from 'react';
import {CriticalEventTypeContextProvider} from './CriticalEventTypeContext';
import {CriticalEventType} from './CriticalEventType';

export const CriticalEventTypeController: React.FC = () => {
  return (
    <CriticalEventTypeContextProvider>
      <CriticalEventType />
    </CriticalEventTypeContextProvider>
  );
};
