import React from 'react';
import {BroadcastContext} from '../BroadcastContext';
import {TemplateTable, TemplateTableConfig} from '../../../factory/template';
import {TwilioLogResponse, Voice} from '@onroadvantage/onroadvantage-api';

interface BroadcastNotificationsLogsProps {
  row: Voice;
}

export const BroadcastNotificationsLogs: React.FC<
  BroadcastNotificationsLogsProps
> = ({row}) => {
  const {loading, loadingSingleItem} = React.useContext(BroadcastContext);

  const [config] = React.useState<TemplateTableConfig<TwilioLogResponse>>({
    columns: [
      {name: 'status', label: 'Status', type: 'string'},
      {name: 'fromNumber', label: 'From Number', type: 'string'},
      {name: 'toNumber', label: 'To Number', type: 'string'},
      {name: 'createdAt', label: 'Created At', type: 'datetime'},
    ],
    disablePagination: true,
    disableToolbar: true,
    identifier: 'BROADCAST_NOTIFICATION_LOGS_LIST',
  });

  return (
    <TemplateTable
      config={config}
      list={row?.voiceCallLogs ?? []}
      currentPage={1}
      loading={loading || loadingSingleItem}
    />
  );
};
