import {makeStyles, createStyles} from '@mui/styles';
import {planningBoardTheme} from '../../planningBoardTheme';

const PlanningBoardOverviewStyles = () =>
  createStyles({
    booking: {backgroundColor: planningBoardTheme.booking, color: '#fff'},
    planning: {backgroundColor: planningBoardTheme.planning, color: '#fff'},
    master: {backgroundColor: planningBoardTheme.master, color: '#fff'},
  });

export const usePlanningBoardOverviewStyles = makeStyles(
  PlanningBoardOverviewStyles,
  {
    name: 'PlanningBoardOverviewStyles',
  }
);
