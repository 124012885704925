import React from 'react';
import {VehicleGroupContext} from './VehicleGroupContext';
import {useParams} from 'react-router-dom';

interface IParams {
  vehicleGroupId?: string;
}

export const VehicleGroupIdHandler: React.FC = () => {
  const {setVehicleGroupId} = React.useContext(VehicleGroupContext);

  const {vehicleGroupId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = vehicleGroupId ? parseInt(vehicleGroupId) : undefined;
    setVehicleGroupId(parsedId);
    return () => setVehicleGroupId(undefined);
  }, [vehicleGroupId, setVehicleGroupId]);

  return null;
};
