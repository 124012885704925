import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  TextField,
  CardActions,
  Button,
  Typography,
  InputAdornment,
} from '@mui/material';
import {MobileDatePicker, MobileTimePicker} from '@mui/x-date-pickers';
import GenerateAudioFile from '../components/GenerateAudioFile';
import {AccessTime} from '@mui/icons-material';

export interface onNextGroupSelectInputType {
  identifier?: string;
  message?: string;
  heading?: string;
  scheduledTime?: Date;
  scheduledDate?: Date;
}

interface AdhocMapSelectMessageStepProps {
  onNext: (input: onNextGroupSelectInputType) => void;
  onBack: () => void;
  style?: any;
  initialValues: {
    identifier?: string;
    message?: string;
    heading?: string;
    scheduledDate?: Date;
    scheduledTime?: Date;
  };
}

interface AdhocMapSelectMessageStepState {
  url?: string;
  status?: string;
  identifier?: string;
  inputType?: string;
  heading?: string;
  message?: string;
  selectedDate?: Date;
  selectedTime?: Date;
}

export default class AdhocMapSelectMessageStep extends React.Component<
  AdhocMapSelectMessageStepProps,
  AdhocMapSelectMessageStepState
> {
  constructor(props: AdhocMapSelectMessageStepProps) {
    super(props);
    const {identifier, message, heading, scheduledDate, scheduledTime} =
      this.props.initialValues;

    this.state = {
      url: undefined,
      status: undefined,
      identifier,
      inputType: 'text',
      heading,
      message,
      selectedDate: scheduledDate,
      selectedTime: scheduledTime,
    };
  }

  handleSetDate = (date: Date) => {
    this.setState({selectedDate: date});
  };

  handleSetTime = (time: Date) => {
    this.setState({selectedTime: time});
  };

  handleHeaderTextChange = (event: React.ChangeEvent<any>) => {
    this.setState({heading: event.target.value});
  };

  handleMessageUpdate = (message?: string) => {
    this.setState({message});
  };

  updateIdentifier = (
    identifier: string | undefined = undefined,
    message: string | undefined = undefined
  ) => {
    this.setState({identifier, message});
  };

  onNext = () => {
    const {identifier, message, heading, selectedTime, selectedDate} =
      this.state;
    this.props.onNext({
      identifier,
      message,
      heading,
      scheduledTime: selectedTime,
      scheduledDate: selectedDate,
    });
  };
  onBack = () => {
    this.props.onBack();
  };

  render() {
    const {identifier} = this.state;

    const disabled = !identifier;
    return (
      <Card style={this.props.style}>
        <CardHeader title="Schedule Date and Time" />
        <CardContent
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
          }}
        >
          <MobileDatePicker
            label="Scheduled Date"
            value={this.state.selectedDate}
            onChange={(date: any) => {
              this.handleSetDate(date);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                id="date-picker-dialog"
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      sx={{
                        position: 'absolute',
                        right: 10,
                        pointerEvents: 'none',
                      }}
                      position="end"
                    >
                      <AccessTime />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <MobileTimePicker
            label="Scheduled Time"
            value={this.state.selectedTime}
            onChange={(time) => this.handleSetTime(time as Date)}
            renderInput={(params) => (
              <TextField
                {...params}
                id="time-picker"
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      sx={{
                        position: 'absolute',
                        right: 10,
                        pointerEvents: 'none',
                      }}
                      position="end"
                    >
                      <AccessTime />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </CardContent>
        <CardHeader title="Message Heading" />
        <CardContent style={{display: 'flex', flexDirection: 'column'}}>
          <TextField
            placeholder="Safety Message #1..."
            rows={2}
            value={this.state.heading}
            onChange={this.handleHeaderTextChange}
          />
        </CardContent>

        <CardHeader title="Message Text" />
        <CardContent>
          <GenerateAudioFile
            controlledValue={this.state.message}
            updateMessage={this.handleMessageUpdate}
            updateIdentifier={this.updateIdentifier}
          />
        </CardContent>
        <div style={{paddingTop: 20}}>
          <CardActions>
            <div>
              <Button onClick={this.onBack}>
                <Typography>Back</Typography>
              </Button>
            </div>
            <div style={{color: disabled ? 'grey' : undefined}}>
              <Button onClick={this.onNext} disabled={disabled}>
                <Typography>Next</Typography>
              </Button>
            </div>
          </CardActions>
        </div>
      </Card>
    );
  }
}
