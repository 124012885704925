import React from 'react';
import {unstable_batchedUpdates} from 'react-dom';
import _ from 'lodash';
import {
  ApiTaskGetRequest,
  Task as TaskType,
  TaskView as TaskViewType,
} from '@onroadvantage/onroadvantage-api';
import {Filter, Sorting} from '@devexpress/dx-react-grid';
import {getStringFromSorting} from '../../service/Util';
import {taskApi} from '../../api';
import DateRangeContext from '../../contexts/DateRangeContext';
import {appNotificationStore} from '../../stores/mobxStores';
import {TemplateTableContextProps} from '../../factory/template';
import {useHistory} from 'react-router-dom';

export interface TaskContextProps extends TemplateTableContextProps<TaskType> {
  loadList: () => void;
  task?: TaskType;
  taskView?: TaskViewType;
  taskId?: number;
  setTaskId: (value: number | undefined) => void;
}

export const TaskContext = React.createContext<TaskContextProps>({
  // Template Table Defaults
  loading: false,
  list: [],
  currentPage: 1,
  // Task
  loadList: () => null,
  setTaskId: () => null,
});

interface TaskContextProviderProps {
  taskId?: number;
}

export const TaskContextProvider: React.FC<TaskContextProviderProps> = ({
  children,
}) => {
  const history = useHistory();
  const {
    dateRange: {startDate, endDate},
  } = React.useContext(DateRangeContext);
  // Template Table
  const [singleLoading, setSingleLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isDaterangeFilterActive, setIsDaterangeFilterActive] =
    React.useState(true);
  const [list, setList] = React.useState<TaskViewType[]>([]);
  const [filters, setFilters] = React.useState<Filter[]>([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(25);
  const [pageTotal, setPageTotal] = React.useState(1);
  const [itemTotal, setItemTotal] = React.useState(0);
  const [sorting, setSorting] = React.useState<Sorting[]>([]);
  // Task
  const [task, setTask] = React.useState<TaskType | undefined>();
  const [taskView, setTaskView] = React.useState<TaskViewType>();
  const [taskId, setTaskId] = React.useState<number>();

  const loadList = React.useCallback(async () => {
    setLoading(true);
    try {
      const requestObj: ApiTaskGetRequest = {
        page: currentPage,
        perPage: pageSize,
      };
      // filters
      filters.forEach(({columnName, value}) => {
        // TODO find a more effective way to handle filters
        if (
          columnName === 'templateName' ||
          columnName === 'templateTypeName' ||
          columnName === 'vehicleRegistrationNumber' ||
          columnName === 'driverTagNumber' ||
          columnName === 'tripNumber' ||
          columnName === 'orderNumber' ||
          columnName === 'status'
        )
          requestObj[columnName] = value;
      });

      if (isDaterangeFilterActive) {
        if (startDate) {
          requestObj.startDate = new Date(startDate);
        }
        if (endDate) {
          requestObj.endDate = new Date(endDate);
        }
      }

      // sorting
      const orderBy = getStringFromSorting(sorting);
      if (orderBy && orderBy !== '') {
        requestObj.orderBy = orderBy;
      }

      const response = await taskApi.apiTaskGet(requestObj);
      if (response && response.items) {
        setList(response.items);
        setPageTotal(response.pages ?? 1);
        setItemTotal(response.total ?? 0);
      } else {
        // TODO Handle
        appNotificationStore.enqueueNotification(
          'error',
          `Error retrieving Task List`
        );
      }
    } catch (e) {
      // TODO Handle
      appNotificationStore.enqueueNotification('error', e.message);
    } finally {
      setLoading(false);
    }
  }, [
    currentPage,
    pageSize,
    filters,
    isDaterangeFilterActive,
    sorting,
    startDate,
    endDate,
  ]);

  const loadTask = React.useCallback(async () => {
    setSingleLoading(true);
    try {
      if (taskId) {
        const response = await taskApi.apiTaskTaskIdGet({
          taskId,
        });
        setTaskView(list.find((taskViewItem) => taskViewItem.id === taskId));
        setTask(response);
      } else {
        setTask(undefined);
      }
    } catch (e) {
      appNotificationStore.enqueueNotification('error', e.message);
    } finally {
      setSingleLoading(false);
    }
  }, [taskId, list]);

  const handleDaterangeFilterToggle = React.useCallback(() => {
    setIsDaterangeFilterActive((prevDaterangeFilter) => !prevDaterangeFilter);
  }, []);

  const handlePageSizeCountsChange = React.useCallback((value: number) => {
    setCurrentPage(1);
    setPageSize(value);
  }, []);

  const handleCurrentPageChange = React.useCallback(
    (value: number) => {
      let newPage = value;

      if (value < 0) {
        // lower bound check
        newPage = 0;
      } else if (value > pageTotal) {
        // upper bound check
        newPage = pageTotal;
      }

      setCurrentPage(newPage + 1);
    },
    [pageTotal]
  );

  const handleFiltersChange = _.debounce((filters: Filter[]) => {
    unstable_batchedUpdates(() => {
      setCurrentPage(1);
      setFilters(filters);
    });
  }, 500);

  const handleSortingChange = _.debounce((sorting: Sorting[]) => {
    unstable_batchedUpdates(() => {
      setCurrentPage(1);
      setSorting(sorting);
    });
  }, 500);

  const handleNavigate = React.useCallback(
    (row: TaskType) => {
      history.push(`/tasklist/${row.id}`);
    },
    [history]
  );

  const value: TaskContextProps = {
    // Template Table
    loading: singleLoading || loading,
    list,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    isDaterangeFilterActive,
    onFiltersChange: handleFiltersChange,
    onSortingChange: handleSortingChange,
    onNavigate: handleNavigate,
    onDateRangeFilterToggle: handleDaterangeFilterToggle,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onRefresh: loadList,
    // Task
    loadList,
    task,
    taskView,
    taskId,
    setTaskId,
  };

  React.useEffect(() => {
    loadList();
  }, [loadList]);

  React.useEffect(() => {
    loadTask();
  }, [loadTask]);

  return <TaskContext.Provider value={value}>{children}</TaskContext.Provider>;
};
