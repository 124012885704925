import React from 'react';
import Map from '../../../map/map';
import '../../../map/map.css';
import {
  MasterRouteLeg as MasterRouteLegType,
  MasterRoute as MasterRouteType,
  MasterRouteLegWaypoint,
  MasterRouteLeg,
} from '@onroadvantage/onroadvantage-api';
import {PolylineService} from '../../../../service';
import {Loader} from '../../../loader';
import {MasterRouteLegRoutable} from '../../../../stores/MapDisplayStore';
import L from 'leaflet';
import {Container, Stack} from '@mui/material';

interface Props {
  masterRouteLeg: MasterRouteLegType;
  masterRoute: MasterRouteType;
  updateWaypoints: (filtered: MasterRouteLegWaypoint[]) => void;
  style?: React.CSSProperties;
}

const MasterRouteLegMap: React.FC<Props> = ({
  masterRouteLeg,
  updateWaypoints,
}) => {
  const [masterRouteLegState, setMasterRouteLegState] =
    React.useState<MasterRouteLeg>(masterRouteLeg);

  const [masterRouteLegRoutable, setMasterRouteLegRoutable] =
    React.useState<MasterRouteLegRoutable | null>(null);
  const [loading, setLoading] = React.useState(true);

  const generateRouteFromRoutePoints = React.useCallback(
    async (routePoints: string[]) => {
      setLoading(true);
      try {
        const polylines = await PolylineService.getOSRMRoute(routePoints);
        const geoJson = await PolylineService.polylinesToGeoJSON(polylines);
        setMasterRouteLegRoutable({
          ...masterRouteLeg,
          polylines,
          routePoints,
          geoJson,
        });
      } finally {
        setLoading(false);
      }
    },
    [masterRouteLeg]
  );

  React.useEffect(() => {
    const routePoints =
      PolylineService.getRoutePointsFromMasterRouteLeg(masterRouteLeg);
    generateRouteFromRoutePoints(routePoints);
  }, [masterRouteLeg, generateRouteFromRoutePoints]);

  const onDragMasterRouteLegWaypoint = (
    leafletId: number,
    latlng: L.LatLng,
    waypoint: MasterRouteLegWaypoint,
    remove?: boolean
  ) => {
    //TODO move to API??

    const newWaypoints: MasterRouteLegWaypoint[] = [];
    if (masterRouteLegState && masterRouteLegState.waypoints) {
      masterRouteLegState.waypoints.forEach((existingWaypoint) => {
        if (waypoint.id && waypoint.id === existingWaypoint.id) {
          existingWaypoint.latitude = latlng.lat;
          existingWaypoint.longitude = latlng.lng;
          newWaypoints.push(existingWaypoint);
        } else if (existingWaypoint.id) {
          newWaypoints.push(existingWaypoint);
        }
      });
    }

    if (!waypoint.id) {
      waypoint.latitude = latlng.lat;
      waypoint.longitude = latlng.lng;
      waypoint.sequence = masterRouteLegState.waypoints
        ? masterRouteLegState.waypoints.length + 2
        : 2;

      newWaypoints.push(waypoint);
    }

    let filtered = newWaypoints;

    if (remove) {
      filtered = newWaypoints.filter((e) => {
        if (waypoint.id) {
          return e.id !== waypoint.id;
        }
        return e.sequence !== waypoint.sequence;
      });
    }

    masterRouteLegState.waypoints = filtered;

    updateWaypoints(filtered);

    const routePoints =
      PolylineService.getRoutePointsFromMasterRouteLeg(masterRouteLegState);
    generateRouteFromRoutePoints(routePoints);
    setMasterRouteLegState(masterRouteLegState);
  };

  React.useEffect(() => {
    setMasterRouteLegState(masterRouteLeg);
    return () => {
      setMasterRouteLegRoutable(null);
      setMasterRouteLegState(masterRouteLeg);
    };
  }, [masterRouteLeg]);

  return (
    <Container style={{height: '60vh'}} disableGutters>
      {loading ? (
        <Stack p={8}>
          <Loader text="Rendering route..." />
        </Stack>
      ) : (
        <Map
          vehicle={{}}
          trip={{}}
          actualTrip={{}}
          route={{}}
          masterRouteLeg={masterRouteLegRoutable ?? undefined}
          onDragMasterRouteLegWaypoint={onDragMasterRouteLegWaypoint}
        />
      )}
    </Container>
  );
};

export default MasterRouteLegMap;
