import React from 'react';
import RiskGraph from './RiskGraph';
import {getStyle, getBrandColors} from '../helpers';

const componentStyle = (brandColors) => {
  return {
    default: {
      container: {
        color: brandColors.brandBackgroundText,
        fontFamily: 'Roboto',
        display: 'flex',
        flexDirection: 'row',
        height: 'calc(100vw / 1920 * 163)',
        width: 'calc(100vw - (100vw / 1920 * 48)',
        borderBottom: '1px solid #8B8B8B',
        marginTop: 'calc(100vw / 1920 * 20)',
        marginLeft: 'calc(100vw / 1920 * 24)',
        marginRight: 'calc(100vw / 1920 * 24)',
        overflow: 'hidden',
      },
      row1: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 'calc(100vw / 1920 * 20)',
      },
      row2: {
        display: 'flex',
        flexDirection: 'row',
      },
      contractStanding: {
        display: 'flex',
        flexDirection: 'column',
      },
      contract: {
        display: 'flex',
        height: 'calc(100vw / 1920 * 167)',
        width: 'calc(100vw / 1920 * 200)',
        alignItems: 'center',
      },
      contractTitle: {
        height: 'calc(100vw / 1920 * 36)',
        width: 'calc(100vw / 1920 * 200)',
      },
      contractTitleText: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: 'calc(100vw / 1920 * 32)',
      },
      totalActualStandingTime: {
        display: 'flex',
        flexDirection: 'row',
      },
      totalActualStandingTimeTimeBox: {
        backgroundColor: brandColors.brandPrimaryHighlight,
        display: 'flex',
        borderRadius: 'calc(100vw / 1920 * 16)',
        height: 'calc(100vw / 1920 * 60)',
        width: 'calc(100vw / 1920 * 250)',
        alignItems: 'center',
        justifyContent: 'center',
      },
      totalActualStandingTimeTimeText: {
        color: brandColors.brandPrimaryHighlightText,
        fontStyle: 'normal',
        fontWeight: '900',
        fontSize: 'calc(100vw / 1920 * 40)',
      },
      totalActualStandingTimeLabelBox: {
        display: 'flex',
        height: 'calc(100vw / 1920 * 60)',
        width: 'calc(100vw / 1920 * 250)',
        marginLeft: 'calc(100vw / 1920 * 20)',
        alignItems: 'center',
        justifyContent: 'center',
      },
      totalActualStandingTimeLabelText: {
        color: brandColors.brandBackgroundText,
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: 'calc(100vw / 1920 * 20)',
        textAlign: 'center',
      },
      graph: {
        marginLeft: 'calc(100vw / 1920 * 32)',
        height: 'calc(100vw / 1920 * 90)',
        width: 'calc(100vw / 1920 * 900)',
      },
      duration: {
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vw / 1920 * 56)',
        width: 'calc(100vw / 1920 * 340)',
        // marginRight: 'calc(100vw / 1920 * 20)',
        marginBottom: 'calc(100vw / 1920 * 20)',
      },
      durationContent: {
        display: 'flex',
        flexDirection: 'row',
      },
      durationTimeBox: {
        display: 'flex',
        borderTopLeftRadius: 'calc(100vw / 1920 * 10)',
        borderTopRightRadius: 'calc(100vw / 1920 * 10)',
        borderBottomRightRadius: 'calc(100vw / 1920 * 10)',
        height: 'calc(100vw / 1920 * 30)',
        width: 'calc(100vw / 1920 * 210)',
        alignItems: 'center',
        justifyContent: 'center',
      },
      durationTimeBoxOver: {
        backgroundColor: brandColors.brandTertiaryHighlight,
      },
      durationTimeBoxUnder: {
        backgroundColor: brandColors.brandPrimaryHighlight,
      },
      durationTimeBoxPlanned: {
        display: 'flex',
        backgroundColor: brandColors.brandQuaternaryHighLight,
        borderTopLeftRadius: 'calc(100vw / 1920 * 10)',
        borderBottomLeftRadius: 'calc(100vw / 1920 * 10)',
        height: 'calc(100vw / 1920 * 20)',
        width: 'calc(100vw / 1920 * 130)',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 'calc(100vw / 1920 * 10)',
      },
      durationTimeText: {
        color: brandColors.brandQuaternaryHighLightText,
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: 'calc(100vw / 1920 * 24)',
      },
      durationTimeTextPlanned: {
        color: brandColors.brandPrimaryHighlightText,
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: 'calc(100vw / 1920 * 14)',
      },
      durationLabelBox: {
        height: 'calc(100vw / 1920 * 46)',
        width: 'calc(100vw / 1920 * 340)',
      },
      durationLabelBoxStart: {
        marginBottom: 'calc(100vw / 1920 * 10)',
      },
      durationLabelBoxEnd: {
        marginTop: 'calc(100vw / 1920 * 10)',
      },
      durationLabelText: {
        color: brandColors.brandBackgroundText,
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: 'calc(100vw / 1920 * 14)',
        textAlign: 'right',
        textTransform: 'uppercase',
      },
      tripCount: {
        height: 'calc(100vw / 1920 * 16)',
        width: 'calc(100vw / 1920 * 200)',
      },
      tripCountText: {
        color: brandColors.brandBackgroundText,
        fontSize: 'calc(100vw / 1920 * 14)',
        textTransform: 'uppercase',
      },
      metrics: {
        display: 'flex',
        flexDirection: 'row',
        height: 'calc(100vw / 1920 * 16)',
        width: 'calc(100vw / 1920 * 712)',
        marginLeft: 'calc(100vw / 1920 * 18)',
        justifyContent: 'space-between',
      },
    },
    unitrans: {
      durationTimeBoxUnder: {
        backgroundColor: brandColors.brandPrimaryLighter,
      },
    },
  };
};

const mapPercentages = ({
  plannedStandingTimePercent,
  knownUnplannedStandingTimePercent,
  unknownUnplannedStandingTimePercent,
}) => {
  const brandColors = getBrandColors();
  const data = [];
  const planned = Math.round(plannedStandingTimePercent * 100);
  const unplanned = Math.round(knownUnplannedStandingTimePercent * 100);
  const unknown = Math.round(unknownUnplannedStandingTimePercent * 100);
  if (planned !== 0) {
    data.push({width: planned, color: brandColors.brandPrimaryHighlight});
  }

  if (unplanned !== 0) {
    data.push({width: unplanned, color: brandColors.brandSecondaryHighlight});
  }

  if (unknown !== 0) {
    data.push({width: unknown, color: brandColors.brandTertiaryHighlight});
  }

  return data;
};

const DurationCombined = ({planned, actual, label, status, labelLocation}) => {
  const styles = getStyle(componentStyle);
  return (
    <div style={styles.duration}>
      {labelLocation === 'start' ? (
        <div
          style={{...styles.durationLabelBox, ...styles.durationLabelBoxStart}}
        >
          <div style={styles.durationLabelText}>{label}</div>
        </div>
      ) : null}
      <div style={styles.durationContent}>
        <div style={styles.durationTimeBoxPlanned}>
          <div style={styles.durationTimeTextPlanned}>{planned}</div>
        </div>
        <div
          style={{
            ...styles.durationTimeBox,
            ...(status
              ? styles.durationTimeBoxUnder
              : styles.durationTimeBoxOver),
          }}
        >
          <div style={styles.durationTimeText}>{actual}</div>
        </div>
      </div>
      {labelLocation === 'end' ? (
        <div
          style={{...styles.durationLabelBox, ...styles.durationLabelBoxEnd}}
        >
          <div style={styles.durationLabelText}>{label}</div>
        </div>
      ) : null}
    </div>
  );
};

const RiskItem = ({
  contract,
  totalStandingTimeActual,
  totalStandingTimePlanned,
  totalStandingTimeStatus,
  totalDrivingTimeActual,
  totalDrivingTimePlanned,
  totalDrivingTimeStatus,
  avgStandingTimeActual,
  avgStandingTimePlanned,
  avgStandingTimeStatus,
  avgDrivingTimeActual,
  avgDrivingTimePlanned,
  avgDrivingTimeStatus,
  plannedStandingTimePercent,
  knownUnplannedStandingTimePercent,
  unknownUnplannedStandingTimePercent,
  tripCount,
}) => {
  const styles = getStyle(componentStyle);
  return (
    <div style={styles.container}>
      <div style={styles.contract}>
        <div style={styles.contractStanding}>
          <div style={styles.contractTitle}>
            <div style={styles.contractTitleText}>{contract}</div>
          </div>
          <div style={styles.tripCount}>
            <div style={styles.tripCountText}>Total Trips: {tripCount}</div>
          </div>
        </div>
      </div>
      <div style={styles.metrics}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <DurationCombined
            label="Total Driving Time"
            actual={totalDrivingTimeActual}
            planned={totalDrivingTimePlanned}
            labelLocation="start"
            status={totalDrivingTimeStatus}
          />
          <DurationCombined
            label="Avg. Driving Time"
            actual={avgDrivingTimeActual}
            planned={avgDrivingTimePlanned}
            labelLocation="end"
            status={avgDrivingTimeStatus}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: 'calc(100vw / 1920 * 32)',
          }}
        >
          <DurationCombined
            label="Total Standing Time"
            actual={totalStandingTimeActual}
            planned={totalStandingTimePlanned}
            labelLocation="start"
            status={totalStandingTimeStatus}
          />
          <DurationCombined
            label="Avg. Standing Time"
            actual={avgStandingTimeActual}
            planned={avgStandingTimePlanned}
            labelLocation="end"
            status={avgStandingTimeStatus}
          />
        </div>
      </div>
      <div style={styles.graph}>
        <RiskGraph
          data={mapPercentages({
            plannedStandingTimePercent,
            knownUnplannedStandingTimePercent,
            unknownUnplannedStandingTimePercent,
          })}
          title="Actual Standing Time Distribution"
        />
      </div>
    </div>
  );
};

export default RiskItem;
