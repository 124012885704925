import React from 'react';
import {ITemplateTabControllerTab, TemplateTabs} from '../../factory/template';
import {BroadcastContext} from './BroadcastContext';
import {BroadcastDetails} from './BroadcastDetails';
import {BroadcastNotifications} from './broadcastNotifications';

export const Broadcast: React.FC = () => {
  const {broadcastId, loading} = React.useContext(BroadcastContext);

  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {
      title: 'Details',
      element: <BroadcastDetails />,
    },
    {
      title: 'Notifications',
      element: <BroadcastNotifications />,
    },
  ]);

  const path = React.useMemo(
    () => `/broadcastlist/${broadcastId}`,
    [broadcastId]
  );

  return <TemplateTabs tabs={tabs} path={path} loading={loading} />;
};
