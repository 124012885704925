import {DataItem} from 'vis';
import {DateTime} from 'luxon';
import {
  ActualTripSummary,
  MasterTripNearbyTimeDump,
  Trip,
  TripStop,
  TripStopActualTimeline,
} from '@onroadvantage/onroadvantage-api';
import {ITelematicsEventsDateRange} from '../trip/tripContext';
import {
  getActualStopTooltip,
  getForcedWaits,
  getOrderStopContent,
  getOrderStopTooltip,
  getOrderTooltip,
  getPlannedDrivingTooltip,
  getPlannedStopTooltip,
  getTimeline,
} from './getTimelineContent';
import {LuxonService} from '../../service';

export interface getTimelineItemsParams {
  trip: Trip | undefined;
  actualTrip: ActualTripSummary | undefined;
  telematicsEventsDateRange: ITelematicsEventsDateRange | undefined;
  adjacentTrips: MasterTripNearbyTimeDump[] | undefined;
  enablePlanNested: boolean;
  enableActualNested: boolean;
}

export const getTimelineItems = ({
  trip,
  actualTrip,
  adjacentTrips,
  telematicsEventsDateRange,
  enablePlanNested,
  enableActualNested,
}: getTimelineItemsParams) => {
  const tripTimeline: DataItem[] = [];

  /** Event Filter | Group 0 */
  if (
    telematicsEventsDateRange?.startDate &&
    telematicsEventsDateRange?.endDate
  ) {
    const telematicsEventSlider = {
      id: 'eventFilter',
      group: 0,
      content: `Event Filter: ${LuxonService.format(
        telematicsEventsDateRange.startDate,
        'ff'
      )} - ${LuxonService.format(telematicsEventsDateRange.endDate, 'ff')}`,
      start: telematicsEventsDateRange.startDate,
      end: telematicsEventsDateRange.endDate,
      className: 'eventFilter',
    };
    tripTimeline.push(telematicsEventSlider);
  }

  const tripStops = trip?.stops;
  if (trip && tripStops) {
    tripStops?.forEach((stop, index) => {
      const isFirstStop = index === 0;
      const isLastStop = index + 1 === tripStops.length;
      const nextStop = tripStops[index + 1] as TripStop | undefined;

      /** Order | Group 1 */
      const orderTimeline: Omit<DataItem, 'title' | 'start'> = {
        group: 1,
        content: getOrderStopContent(stop),
        end: stop.serviceTimeEnd,
        className: 'order',
        editable: false,
      };
      const {orders} = stop;
      if (isFirstStop && trip.tripStart) {
        // First Stop Order
        tripTimeline.push({
          ...orderTimeline,
          title: getOrderStopTooltip({
            timeline: {
              start: DateTime.fromJSDate(trip.tripStart)
                .minus({hour: 1})
                .toJSDate(),
              end: stop.serviceTimeEnd,
            },
            stop,
          }),
          start: DateTime.fromJSDate(trip.tripStart)
            .minus({hour: 1})
            .toJSDate(),
        });
      } else if (isLastStop && stop.arrivalTime) {
        // Last Stop Order
        tripTimeline.push({
          ...orderTimeline,
          title: getOrderStopTooltip({
            timeline: {start: stop.arrivalTime, end: stop.serviceTimeEnd},
            stop,
          }),
          start: stop.arrivalTime,
        });
      } else if (stop.serviceTimeStart && orders && orders.length > 0) {
        // Other Stop's Order
        const order = orders[0];
        tripTimeline.push({
          ...orderTimeline,
          title: getOrderTooltip(order),
          start: stop.serviceTimeStart,
        });
      }

      /** Plan | Group 2 */
      // Planned Stop
      const plannedStopStart =
        isFirstStop &&
        stop.serviceTimeStart &&
        stop.serviceTimeStart === stop.departureTime
          ? DateTime.fromJSDate(stop.serviceTimeStart)
              .minus({hour: 1})
              .toJSDate()
          : stop.serviceTimeStart;
      if (plannedStopStart) {
        const stopTimeline: DataItem = {
          title: getPlannedStopTooltip({
            timeline: {start: plannedStopStart, end: stop.serviceTimeEnd},
            stop,
          }),
          content: stop.node?.name ?? `Stop ${index + 1}`,
          start: plannedStopStart,
          end: stop.serviceTimeEnd,
          className: 'scheduled',
          editable: false,
        };
        // Collapsed
        tripTimeline.push({...stopTimeline, group: 2});
        // Nested
        if (enablePlanNested) {
          tripTimeline.push({...stopTimeline, group: 2.2});
        }
      }

      // Driving
      if (stop.departureTime) {
        const drivingTimeline: DataItem = {
          title: getPlannedDrivingTooltip({
            timeline: {
              start: stop.serviceTimeEnd ?? stop.departureTime,
              end: nextStop?.serviceTimeStart ?? nextStop?.arrivalTime,
            },
            stop,
          }),
          content: 'Driving',
          start: stop.serviceTimeEnd ?? stop.departureTime,
          end: nextStop?.serviceTimeStart ?? nextStop?.arrivalTime,
          className: 'driving',
          editable: false,
        };
        // Collapsed
        tripTimeline.push({...drivingTimeline, group: 2});
        // Nested
        if (enablePlanNested) {
          tripTimeline.push({...drivingTimeline, group: 2.3});
        }
      }

      // Forced Waits
      stop?.forcedWaits?.forEach((forcedWait) => {
        if (forcedWait.start && forcedWait.end) {
          tripTimeline.push({
            group: 2.1,
            title: forcedWait.reason,
            content: forcedWait.type,
            start: forcedWait.start,
            end: forcedWait.end,
            className: 'forcedWait',
            editable: false,
          });
        }
      });

      // Start resting
      if (
        stop.arrivalTime &&
        stop.serviceTimeStart &&
        stop.arrivalTime !== stop.serviceTimeStart
      ) {
        tripTimeline.push({
          group: 2,
          title: getForcedWaits({
            timeline: {start: stop.arrivalTime, end: stop.serviceTimeStart},
            forcedWaits: stop.forcedWaits,
          }),
          content:
            stop.forcedWaits == null || stop.forcedWaits.length === 0
              ? 'Waiting'
              : 'Resting',
          start: stop.arrivalTime,
          end: stop.serviceTimeStart,
          className: 'forcedWait',
          editable: false,
        });
      }

      // End resting
      if (
        stop.serviceTimeEnd &&
        stop.departureTime &&
        stop.serviceTimeEnd !== stop.departureTime
      ) {
        tripTimeline.push({
          group: 2,
          title: getForcedWaits({
            timeline: {start: stop.serviceTimeEnd, end: stop.departureTime},
            forcedWaits: stop.forcedWaits,
          }),
          content:
            stop.forcedWaits == null || stop.forcedWaits.length === 0
              ? 'Waiting'
              : 'Resting',
          start: stop.serviceTimeEnd,
          end: stop.departureTime,
          className: 'forcedWait',
          editable: false,
        });
      }

      /** Live Revised Plan | Group 3 */
      // Estimated execution stop
      const estimatedExecutionStart = stop.tripStopExecution?.revisedEtss;
      const estimatedExecutionEnd = stop.tripStopExecution?.revisedEtse;
      if (estimatedExecutionStart) {
        tripTimeline.push({
          group: 3,
          title: getPlannedStopTooltip({
            timeline: {
              start: estimatedExecutionStart,
              end: estimatedExecutionEnd,
            },
            stop,
          }),
          content: stop.node?.name ?? 'Revised',
          start: estimatedExecutionStart,
          end: estimatedExecutionEnd,
          className: 'scheduled',
          editable: false,
        });
      }
      // Estimated execution driving
      const estimatedDrivingStart = stop.tripStopExecution?.revisedEtse;
      const estimatedDrivingEnd = nextStop?.tripStopExecution?.revisedEtss;
      if (estimatedDrivingStart) {
        tripTimeline.push({
          group: 3,
          title: getPlannedStopTooltip({
            timeline: {start: estimatedDrivingStart, end: estimatedDrivingEnd},
            stop,
          }),
          content: 'Estimated Driving',
          start: estimatedDrivingStart,
          end: estimatedDrivingEnd,
          className: 'driving',
          editable: false,
        });
      }

      // Live Revised Start resting
      if (
        stop.tripStopExecution?.revisedEta &&
        stop.tripStopExecution.revisedEtss &&
        stop.tripStopExecution.revisedEta !== stop.tripStopExecution.revisedEtss
      ) {
        tripTimeline.push({
          group: 3,
          title: getForcedWaits({
            timeline: {
              start: stop.tripStopExecution.revisedEta,
              end: stop.tripStopExecution.revisedEtss,
            },
            forcedWaits: stop.tripStopExecution.forcedWaits,
          }),
          content:
            stop.tripStopExecution.forcedWaits != null &&
            stop.tripStopExecution.forcedWaits.length > 0
              ? 'Resting'
              : 'Waiting',
          start: stop.tripStopExecution.revisedEta,
          end: stop.tripStopExecution.revisedEtss,
          className: 'forcedWait',
          editable: false,
        });
      }

      // End resting
      if (
        stop.tripStopExecution?.revisedEtd &&
        stop.tripStopExecution.revisedEtse &&
        stop.tripStopExecution.revisedEtd !== stop.tripStopExecution.revisedEtse
      ) {
        tripTimeline.push({
          group: 3,
          title: getForcedWaits({
            timeline: {
              start: stop.tripStopExecution.revisedEtse,
              end: stop.tripStopExecution.revisedEtd,
            },
            forcedWaits: stop.tripStopExecution.forcedWaits,
          }),
          content:
            stop.tripStopExecution.forcedWaits != null &&
            stop.tripStopExecution.forcedWaits.length > 0
              ? 'Resting'
              : 'Waiting',
          start: stop.tripStopExecution.revisedEtse,
          end: stop.tripStopExecution.revisedEtd,
          className: 'forcedWait',
          editable: false,
        });
      }
      // stop.tripStopExecution?.forcedWaits?.forEach((forcedWait) => {
      //   if (forcedWait.start && forcedWait.end) {
      //     tripTimeline.push({
      //       group: 3,
      //       title: forcedWait.reason,
      //       content: 'Resting',
      //       start: forcedWait.start,
      //       end: forcedWait.end,
      //       className: 'forcedWait',
      //       editable: false,
      //     });
      //   }
      // });
      /** Mobile Actual | Group 5 */
      const mobileActualStart = stop.tripStopExecution?.mobileArrivalTime;
      const mobileActualEnd = stop.tripStopExecution?.mobileDepartureTime;
      if (mobileActualStart && mobileActualEnd) {
        tripTimeline.push({
          group: 5,
          title: getPlannedStopTooltip({
            timeline: {start: mobileActualStart, end: mobileActualEnd},
            stop,
          }),
          content: stop.node?.name ?? '',
          start: mobileActualStart,
          end: mobileActualEnd,
          className: 'order',
          editable: false,
        });
      }
    });
  }

  /** Gps Actual | Group 4 */
  if (actualTrip?.stops && actualTrip.stops.length > 0) {
    const unknownUnscheduledActualStops = actualTrip.stops.filter(
      ({known}) => !known
    );
    const knownUnscheduledActualStops = actualTrip.stops.filter(
      ({known, scheduled}) => known && !scheduled
    );
    const knownScheduledActualStops = actualTrip.stops.filter(
      ({known, scheduled}) => known && scheduled
    );

    const getActualStop = (
      stop: TripStopActualTimeline
    ): DataItem | undefined => {
      if (!stop.start || !stop.end) return undefined;

      const duration = DateTime.fromJSDate(stop.end)
        .diff(DateTime.fromJSDate(stop.start))
        .shiftTo('hours', 'minutes', 'seconds');

      const actualTat = `${duration.hours
        .toString()
        .padStart(2, '0')}:${duration.minutes
        .toString()
        .padStart(2, '0')}:${duration.seconds.toString().padStart(2, '0')}`;

      return {
        content: `Stopped: ${actualTat}`,
        title: getActualStopTooltip({
          timeline: {start: stop.start, end: stop.end},
          stop,
        }),
        start: stop.start,
        end: stop.end,
        editable: false,
      };
    };

    // Unknown unscheduled actual stops
    unknownUnscheduledActualStops.forEach((stop) => {
      const stopTimeline = getActualStop(stop);
      if (stopTimeline) {
        if (enableActualNested) {
          tripTimeline.push({
            ...stopTimeline,
            group: 4.3,
            className: 'unknownUnscheduled',
          });
        }
        tripTimeline.push({
          ...stopTimeline,
          group: 4,
          className: 'unknownUnscheduled',
        });
      }
    });

    // Known unscheduled actual stops
    knownUnscheduledActualStops.forEach((stop) => {
      const stopTimeline = getActualStop(stop);
      if (stopTimeline) {
        if (enableActualNested) {
          tripTimeline.push({
            ...stopTimeline,
            group: 4.2,
            className: 'knownUnscheduled',
          });
        }
        tripTimeline.push({
          ...stopTimeline,
          group: 4,
          className: 'knownUnscheduled',
        });
      }
    });

    // Known scheduled actual stops
    knownScheduledActualStops.forEach((stop) => {
      const stopTimeline = getActualStop(stop);
      if (stopTimeline) {
        if (enableActualNested) {
          tripTimeline.push({
            ...stopTimeline,
            group: 4.1,
            className: 'scheduled',
          });
        }
        tripTimeline.push({
          ...stopTimeline,
          group: 4,
          className: 'scheduled',
        });
      }
    });
  }

  // No Tracking Background
  actualTrip?.noTrackingPeriods?.forEach((noTrackingPeriod) => {
    if (noTrackingPeriod.start) {
      tripTimeline.push({
        group: 4,
        content: 'No Tracking Available',
        title: 'No Tracking Available',
        type: 'background',
        className: 'noTrackingBackground',
        start: noTrackingPeriod.start,
        end: noTrackingPeriod.end,
        editable: false,
      });
    }
  });
  // END BACKGROUND TIMELINES

  /** Other Trips | Group 6  */
  adjacentTrips?.forEach((masterTrip, index) => {
    if (masterTrip.trip?.tripStart) {
      tripTimeline.push({
        group: 6,
        title: getTimeline({
          start: masterTrip.trip.tripStart,
          end: masterTrip.trip.tripEnd,
        }),
        content: masterTrip.trip?.tripNumber ?? `Other Trip ${index}`,
        start: masterTrip.trip.tripStart,
        end: masterTrip.trip.tripEnd,
        className: 'trip',
        editable: false,
      });
    }
  });

  /** Returned timeline items */
  const returnTimeline: DataItem[] = [];
  tripTimeline.forEach((item) => {
    if (item.start && item.end && item.end > item.start) {
      returnTimeline.push(item);
    }
  });
  return returnTimeline;
};
