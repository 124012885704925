import React from 'react';
import {VehicleTypeForm} from './VehicleTypeForm';
import {ITemplateTabControllerTab, TemplateTabs} from '../../factory/template';
import {VehicleTypeContext} from './VehicleTypeContext';
import {VehicleTypeCapacityList} from './vehicleTypeCapacity';
import {VehicleTypeCompartmentList} from './vehicleTypeCompartment';

export const VehicleType: React.FC = () => {
  const {vehicleTypeId, loading} = React.useContext(VehicleTypeContext);

  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {title: 'Details', element: <VehicleTypeForm />},
    {title: 'Capacity', element: <VehicleTypeCapacityList />},
    {title: 'Compartment', element: <VehicleTypeCompartmentList />},
  ]);

  const path = React.useMemo(
    () => `/vehicletypelist/${vehicleTypeId}`,
    [vehicleTypeId]
  );

  return <TemplateTabs tabs={tabs} path={path} loading={loading} />;
};
