import React from 'react';
import {NotificationConfigCriticalEventContext} from './NotificationConfigCriticalEventContext';
import {useParams} from 'react-router-dom';

interface IParams {
  notificationConfigEventId?: string;
}

export const NotificationConfigCriticalEventIdHandler: React.FC = () => {
  const {setNotificationConfigEventId} = React.useContext(
    NotificationConfigCriticalEventContext
  );

  const {notificationConfigEventId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = notificationConfigEventId
      ? parseInt(notificationConfigEventId)
      : undefined;
    setNotificationConfigEventId(parsedId);
    return () => setNotificationConfigEventId(undefined);
  }, [notificationConfigEventId, setNotificationConfigEventId]);

  return null;
};
