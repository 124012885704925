import React from 'react';
import {UserContext} from './UserContext';
import {TemplateTableConfig, TemplateTable} from '../../factory/template';
import {RoleAuth} from '@onroadvantage/onroadvantage-api';

export const UserRoles: React.FC = () => {
  const {
    user,
    submitting,
    loadingSingleItem,
    assignRoles,
    unassignRole,
    hasPermission,
  } = React.useContext(UserContext);

  const config: TemplateTableConfig<RoleAuth> = React.useMemo(
    () => ({
      columns: [
        {
          name: 'name',
          label: 'Name',
          type: 'string',
          enableEditing: true,
          enableAutocomplete: true,
          enableMulti: true,
          model: 'Role',
        },
      ],
      enableFilter: true,
      enableSort: true,
      deleteMessage: (row) => ({
        title: 'User Roles',
        items: {User: user?.email, Name: row.name},
      }),
      identifier: 'USER_ROLE_LIST',
    }),
    [user?.email]
  );

  return (
    <TemplateTable
      config={config}
      list={user?.roles ?? []}
      loading={loadingSingleItem || submitting}
      currentPage={1}
      onInlineAdd={hasPermission?.edit ? assignRoles : undefined}
      onDelete={hasPermission?.edit ? unassignRole : undefined}
    />
  );
};
