import {flatten} from './Util';
import {authStore} from '../store';
import {uploadApi} from '../api';

const UploadService = {
  query: async (params) => {
    Object.keys(params).forEach(
      // eslint-disable-next-line no-param-reassign
      (key) => params[key] == null && delete params[key]
    );
    const response = await uploadApi.apiUploadGet(params);
    return flatten(response);
  },
  loadTemplates: async () => {
    const response = await uploadApi.apiUploadTemplateGet();
    return flatten(response);
  },
  normalize: async (response) => {
    const result = flatten(await response.data);
    return result;
  },
  upload: async (params) => {
    const response = await uploadApi.apiUploadPost({
      file: params.file,
      filename: params.file.name,
    });
    return response;
  },
  createUrl(link) {
    const {auth} = authStore;
    return `${link}?auth_token=${auth.token}`;
  },
};

export default UploadService;
