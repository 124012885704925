import * as React from 'react';
import * as PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import withStyles from '@mui/styles/withStyles';
import TextField from '@mui/material/TextField';

const styles = (theme) => ({
  Cell: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  progressBar: {
    backgroundColor: 'red',
    float: 'left',
    height: theme.spacing(2),
  },
});

class EmailCellBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailErrorMessage: undefined,
      value: this.props.value,
      initialValue: props.value ? props.value : '',
    };
  }

  handleEmailChange = (value, cb) => {
    this.setState({value});
    let {emailErrorMessage} = this.state;
    if (value !== '') {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        emailErrorMessage = 'Invalid email address';
      } else {
        emailErrorMessage = undefined;
      }
    } else {
      emailErrorMessage = 'Required';
    }
    this.setState({emailErrorMessage});
    cb(value);

    this.props.column.setError(true);
    if (!emailErrorMessage) {
      this.props.column.setError(false);
      if (this.state.initialValue !== '') {
        this.props.column.setPasswordError(false);
      }
    }
  };

  UNSAFE_componentWillMount() {
    const initialValue = this.props.value ? this.props.value : '';
    if (initialValue !== '') {
      this.props.column.setError(false);
      this.props.column.setPasswordError(false);
    }
  }

  componentWillUnmount() {
    if (this.state.emailErrorMessage === undefined) {
      this.props.column.setError(true);
      this.props.column.setPasswordError(true);
    }
  }

  render() {
    const {classes, style, name, disabled, onValueChange} = this.props;
    return (
      <TableCell
        className={classes.Cell}
        style={[style, {display: 'flex', flexDirection: 'column'}]}
      >
        <TextField
          id="emailEdit"
          value={this.state.value}
          disabled={disabled}
          name={name}
          label="Email"
          onChange={(e) =>
            this.handleEmailChange(e.target.value, onValueChange, e)
          }
          style={{width: '100%'}}
          error={this.state.errorMessage !== undefined}
        />
        <br />
        {this.state.emailErrorMessage && (
          <span style={{color: 'red'}}>{this.state.emailErrorMessage}</span>
        )}
      </TableCell>
    );
  }
}

EmailCellBase.propTypes = {
  value: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};
EmailCellBase.defaultProps = {
  style: {},
};

// eslint-disable-next-line import/prefer-default-export
export const EmailCell = withStyles(styles, {name: 'EmailCell'})(EmailCellBase);
