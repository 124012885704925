import React from 'react';
import {TimelineItem} from 'vis';
import {
  WebPlanningBoardOrder,
  WebPlanningBoardVehicle,
} from '@onroadvantage/onroadvantage-api';
import {Paper, Stack} from '@mui/material';
import {usePlanningBoardGanttStyles} from './PlanningBoardGantt.style';
import {
  WebPlanningBoardMasterTripWithIdType,
  usePlanningBoardGanttContext,
  usePlanningBoardSettingsContext,
} from '../../planningBoardContext';
import {PlanningBoardGanttTimeline} from './PlanningBoardGanttTimeline';
import {PlanningBoardToolbarActions} from '../../planningBoardToolbar';

export interface IPlanningBoardGanttItem extends TimelineItem {
  variant:
    | 'booking'
    | 'masterTrip'
    | 'order'
    | 'optimalMasterTrip'
    | 'trip'
    | 'previewTrip';
  data: WebPlanningBoardOrder | WebPlanningBoardMasterTripWithIdType | null;
  vehicle: WebPlanningBoardVehicle | undefined;
}
export interface IPlanningBoardGanttItemWithInvalidItem
  extends IPlanningBoardGanttItem {
  invalidItem?: IPlanningBoardGanttItem;
}

interface PlanningBoardGanttProps {
  focused?: boolean;
}

export const PlanningBoardGantt: React.FC<PlanningBoardGanttProps> = ({
  focused,
}) => {
  const classes = usePlanningBoardGanttStyles();
  const {selectedGroupOptions, enableGanttFullscreen} =
    usePlanningBoardSettingsContext();
  const {loadGanttDetails} = usePlanningBoardGanttContext();

  // Get gantt data
  React.useEffect(() => {
    loadGanttDetails('load');
    return () => loadGanttDetails('reset');
  }, [selectedGroupOptions, loadGanttDetails]);

  if (enableGanttFullscreen) {
    return (
      <Paper className={classes.containerFullScreen}>
        <Stack p={1}>
          <PlanningBoardToolbarActions fullscreen />
        </Stack>
        <PlanningBoardGanttTimeline maxHeight="90vh" />
      </Paper>
    );
  }

  return (
    <Stack className={classes.container}>
      <PlanningBoardGanttTimeline maxHeight={focused ? '90vh' : '44vh'} />
    </Stack>
  );
};
