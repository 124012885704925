import React from 'react';
import {Card, CardHeader, CardContent, Avatar} from '@mui/material';
import {Navigation} from '@mui/icons-material';
import withStyles from '@mui/styles/withStyles';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import styles from '../../../components/styles/Card';
import Map from '../../map/map';
import '../../../components/map/map.css';
import {getInitialMapCenter} from '../../map/mapUtils';
import {Layer} from 'leaflet';
import VehicleMarker from '../../map/marker/VehicleMarker';
import {MapSelectionItem} from './AdhocMapSelectStep';
import {mapDisplayStore} from '../../../stores/mobxStores';
import {inject, observer} from 'mobx-react';

interface Props {
  zoom?: number;
  style?: any;
  items: any[];
}

interface State {
  zoom: number;
  center: [number, number];
  externalLayers: Layer | undefined;
}

class AdhocMapDisplayComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      zoom: props.zoom ? props.zoom : 9,
      center: getInitialMapCenter(props),
      externalLayers: undefined,
    };
  }

  updateGeoSelectionLayers = async (items: MapSelectionItem[]) => {
    // switching to non arrays for use with the mobX store
    const parsedItems = items.reduce(
      (itemList: any, currentItem: MapSelectionItem) => {
        return {...itemList, [currentItem.id]: currentItem};
      },
      {}
    );

    mapDisplayStore.setGeoSelectionItems(parsedItems);
  };

  render() {
    const {style} = this.props;
    const items =
      (mapDisplayStore.geoSelectionItems &&
        Object.values(mapDisplayStore.geoSelectionItems)) ||
      [];

    const updateGeoSelectionLayers = (() => {
      if (!items || items.length === 0) {
        return null;
      }
      const markers: any[] = [];
      // TODO check if vehicle or driver
      items.forEach((item: any) => {
        markers.push(
          <VehicleMarker
            key={item.id}
            position={[item.lastSeen.latitude, item.lastSeen.longitude]}
            vehicle={item}
          />
        );
      });
      return (
        <MarkerClusterGroup key={'geoSelectioVehicleLayer'}>
          {markers}
        </MarkerClusterGroup>
      );
    })();

    return (
      <Card style={style}>
        <CardHeader
          title="Adhoc Broadcast Map Selection"
          avatar={
            <Avatar aria-label="TripMap">
              <Navigation />
            </Avatar>
          }
        />
        <CardContent style={{height: '70vh'}}>
          <div className="leaflet-container">
            <Map
              zoom={7}
              geoSelector={true}
              geoSelectionType={'Vehicle Selection'}
              // @ts-expect-error upgrade
              externalLayers={updateGeoSelectionLayers}
              updateGeoSelectionLayers={this.updateGeoSelectionLayers}
            />
          </div>
        </CardContent>
      </Card>
    );
  }
}

export default inject('mapDisplayStore')(
  observer(withStyles(styles)(AdhocMapDisplayComponent))
);
