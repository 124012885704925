import React from 'react';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../../factory/template';
import {TripListContext} from './TripListContext';
import {WebMasterTripOptimised} from '@onroadvantage/onroadvantage-api';

export const TripList: React.FC = () => {
  const {loadList, cleanupList} = React.useContext(TripListContext);

  const [config] = React.useState<TemplateTableConfig<WebMasterTripOptimised>>({
    columns: [
      {name: 'tripNumber', label: 'Trip Number', type: 'string'},
      {name: 'contractCode', label: 'Contract', type: 'string'},
      {name: 'driverName', label: 'Driver', type: 'string'},
      {
        name: 'vehicleRegistrationNumber',
        label: 'Registration Number',
        type: 'string',
      },
      {name: 'fleetNumber', label: 'Fleet Number', type: 'string'},
      {name: 'upliftNodeName', label: 'Collection Location', type: 'string'},
      {name: 'offloadNodeName', label: 'Delivery Location', type: 'string'},
      {
        name: 'tripStart',
        label: 'Trip Start',
        type: 'datetime',
        enableFilter: false,
      },
      {
        name: 'tripEnd',
        label: 'Trip End',
        type: 'datetime',
        enableFilter: false,
      },
      {
        name: 'orderProgress',
        label: 'Order',
        type: 'progress',
        enableFilter: false,
        hidden: true,
      },
      {
        name: 'taskProgress',
        label: 'Task',
        type: 'progress',
        enableFilter: false,
      },
      {
        name: 'stopProgress',
        label: 'Stop',
        type: 'progress',
        enableFilter: false,
        hidden: true,
      },
      {name: 'status', label: 'Status', type: 'string'},
      {name: 'orderNumber', label: 'Orders', type: 'string'},
      {
        name: 'createdAt',
        label: 'Created At',
        type: 'datetime',
        enableFilter: false,
        hidden: true,
      },
      {
        name: 'createdBy',
        label: 'Created By',
        type: 'string',
        enableFilter: false,
        hidden: true,
      },
    ],
    enableFilter: true,
    enableGrouping: true,
    enableSort: true,
    deleteMessage: (row) => ({
      title: 'Trip',
      items: {TripNumber: row.tripNumber, Id: row.id},
    }),
    identifier: 'TRIP_LIST',
  });

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [cleanupList, loadList]);

  return <TemplateTableWrapper config={config} context={TripListContext} />;
};
