import React from 'react';
import withStyles from '@mui/styles/withStyles';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import GridOnIcon from '@mui/icons-material/GridOn';
import GridOffIcon from '@mui/icons-material/GridOff';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/SaveAlt';
import TableCell from '@mui/material/TableCell';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import {TableEditRow} from '@devexpress/dx-react-grid-material-ui';

import _ from 'lodash';
import {ActiveCell} from '../devExpressTable/activeCell';
import {PasswordCell} from '../devExpressTable/passwordCell';
import {EmailCell} from '../devExpressTable/emailCell';

export const selectStyles = (theme) => ({
  lookupEditCell: {
    paddingTop: theme.spacing(0.875),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  dialog: {
    width: 'calc(100% - 16px)',
  },
  inputRoot: {
    width: '100%',
  },
});

export const EditButton = ({onExecute}) => (
  <IconButton onClick={onExecute} title="Edit row" size="large">
    <div data-test="edit-row-button" style={{display: 'flex'}}>
      <EditIcon />
    </div>
  </IconButton>
);

export const CommitButton = ({
  onExecute,
  hasErrors,
  hasEmailErrors,
  activeStatusChanged,
}) => (
  <IconButton
    onClick={onExecute}
    title="Save changes"
    disabled={
      hasErrors === true ||
      hasEmailErrors === true ||
      activeStatusChanged === false
    }
    size="large">
    <div data-test="commit-row-button" style={{display: 'flex'}}>
      <SaveIcon />
    </div>
  </IconButton>
);

export const CancelButton = ({onExecute}) => (
  <IconButton onClick={onExecute} title="Cancel changes" size="large">
    <div data-test="cancel-row-button" style={{display: 'flex'}}>
      <CancelIcon />
    </div>
  </IconButton>
);

const deleteButtonStyles = () => ({
  dialog: {
    '& div:last-child': {
      borderRadius: '6px',
      backgroundColor: '#F1F1F1',
    },
  },
  title: {
    color: '#000000',
    '& h2': {
      fontWeight: 'normal',
      opacity: 0.87,
      size: 16,
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  yesButton: {
    color: '#008270',
    size: 13,
  },
  noButton: {
    color: '#808080',
    size: 13,
    opacity: 0.87,
  },
});

class DeleteButtonComponent extends React.Component {
  state = {
    open: false,
  };

  handleOpen = () => {
    this.setState({open: true});
  };

  handleClose = () => {
    this.setState({open: false});
  };

  handleYesAction = () => {
    this.setState({open: false}, () => this.props.onExecute());
  };

  render() {
    const {classes} = this.props;

    return <>
      <IconButton onClick={this.handleOpen} title="Delete" size="large">
        <div data-test="delete-row-button" style={{display: 'flex'}}>
          <DeleteIcon />
        </div>
      </IconButton>

      <Dialog
        open={this.state.open}
        onClose={this.handleClose}
        aria-labelledby="responsive-dialog-title"
        className={classes.dialog}
      >
        <DialogTitle id="responsive-dialog-title" className={classes.title}>
          Are you sure you want to delete?
        </DialogTitle>
        <DialogActions className={classes.actions}>
          <Button
            onClick={this.handleYesAction}
            className={classes.yesButton}
          >
            YES
          </Button>
          <Button onClick={this.handleClose} className={classes.noButton}>
            NO
          </Button>
        </DialogActions>
      </Dialog>
    </>;
  }
}

export const DeleteButton = withStyles(deleteButtonStyles)(
  DeleteButtonComponent
);

export const AddButton = ({onExecute}) => (
  <IconButton onClick={onExecute} title="Add" size="large">
    <div data-test="add-row-button" style={{display: 'flex'}}>
      <AddIcon />
    </div>
  </IconButton>
);

const LookupEditCellBase = (props) => {
  const {
    options,
    checkChange,
    classes,
    isMulti,
    disabled,
    placeholder,
    onSearchChange,
    onValueChange,
    noOptionsMessage,
    loading,
  } = props;

  return (
    <TableCell className={classes.lookupEditCell} style={{overflow: 'visible'}}>
      <Autocomplete
        id="react-select-single"
        options={options}
        onChange={(e, value) => {
          onValueChange(value);
          checkChange && checkChange(value);
        }}
        multiple={isMulti}
        disabled={disabled}
        onInputChange={(e, value) => onSearchChange && onSearchChange(value)}
        noOptionsText={noOptionsMessage}
        loading={loading}
        getOptionLabel={(option) => {
          return _.get(option, 'label', '').toString();
        }}
        renderInput={(params) => (
          <TextField {...params} placeholder={placeholder} variant="standard" />
        )}
      />
    </TableCell>
  );
};
export const LookupEditCell = withStyles(selectStyles)(LookupEditCellBase);

export const commandComponents = {
  edit: EditButton,
  commit: CommitButton,
  cancel: CancelButton,
  delete: DeleteButton,
  add: AddButton,
};

export const Command = ({
  id,
  onExecute,
  hasErrors,
  hasEmailErrors,
  activeStatusChanged,
}) => {
  const CommandButton = commandComponents[id];
  return (
    <CommandButton
      onExecute={onExecute}
      hasErrors={hasErrors}
      hasEmailErrors={hasEmailErrors}
      activeStatusChanged={activeStatusChanged}
    />
  );
};

export const EditCell = (props) => {
  const {column, editingEnabled} = props;
  // FOR SELECT CELL
  if (column.options) {
    if (!editingEnabled) {
      const label = column.getCellOption(props.row);
      return (
        <TableEditRow.Cell
          {...props}
          style={{padding: 0}}
          key={props.tableRow.key}
          editingEnabled={false}
          value={label ? label.label : column.name}
        />
      );
    }

    return (
      <LookupEditCell
        {...props}
        key={props.tableRow.key}
        isMulti={column.isMulti}
        disabled={column.disabled}
        options={column.options}
        checkChange={column.checkChange}
        onSearchChange={column.onSearchChange}
        onValueChange={props.onValueChange}
        placeholder="Type to search"
        noOptionsMessage="No options found"
        defaultValue={
          column.getCellValue ? column.getCellValue(props.row) : null
        }
      />
    );
  }

  // FOR BOOLEAN CELL
  if (column.name === 'active') {
    return <ActiveCell {...props} disabled={false} />;
  }

  // FOR TEXT EDIT
  if (['password'].includes(column.name)) {
    return (
      <PasswordCell {...props} disabled={false} name={props.column.name} />
    );
  }

  // FOR EMAIL EDIT
  if (['email'].includes(props.column.name)) {
    return <EmailCell {...props} disabled={false} name={props.column.name} />;
  }

  // FOR DEFAULT
  return <TableEditRow.Cell {...props} />;
};

export const AddCancelHeader = (props) => {
  const downloadButton = props.showDownload ? (
    <IconButton
      disabled={props.isDownloadDisabled}
      onClick={props.onDownload}
      style={
        props.style
          ? props.style
          : {
              top: 120,
              left: 20,
            }
      }
      size="large">
      <div data-test="download-header-button" style={{display: 'flex'}}>
        <DownloadIcon />
      </div>
    </IconButton>
  ) : null;

  if (!props.showAdd) {
    return downloadButton;
  }

  if (props.loading) {
    return null;
  }

  if (props.filters) {
    const filters = Object.assign({}, props.filters);
    _.remove(filters, (n) => {
      return n.columnName === 'dateRange';
    });
    if (filters.length > 0) {
      return null;
    }
  }

  if (props.sorting) {
    if (props.sorting.length > 0) {
      return null;
    }
  }

  if (!props.creatingRow) {
    return (
      <React.Fragment>
        <IconButton
          onClick={() => props.createNewRow()}
          style={
            props.style
              ? props.style
              : {
                  top: 120,
                  left: 20,
                }
          }
          size="large">
          <div data-test="add-header-button" style={{display: 'flex'}}>
            <AddIcon />
          </div>
        </IconButton>
        {downloadButton}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <IconButton
        onClick={() => props.cancelNewRow()}
        style={
          props.style
            ? props.style
            : {
                top: 120,
                left: 60,
              }
        }
        size="large">
        <div data-test="cancel-header-button" style={{display: 'flex'}}>
          <CancelIcon />
        </div>
      </IconButton>
    </React.Fragment>
  );
};

export const ToggleEditingHeader = (props) => {
  return (
    <IconButton
      onClick={() => props.toggle()}
      style={
        props.style
          ? props.style
          : {
              position: 'absolute',
              top: 149,
              left: 350,
            }
      }
      size="large">
      <div data-test="cancel-header-button" style={{display: 'flex'}}>
        {props.value ? <GridOnIcon /> : <GridOffIcon />}
      </div>
    </IconButton>
  );
};
