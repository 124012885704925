import React from 'react';
import {Listing, Trip} from '@onroadvantage/onroadvantage-api';
import {useTripContext} from '../../tripContext';
import {driverApi, masterTripListDriverApi} from '../../../../api';
import {useAppNotifications} from '../../../../contexts';
import {RoleService} from '../../../../service';
import {TAutocompleteOnChange} from '../../../autocomplete';
import {TripSummaryEditAutocomplete} from './TripSummaryEditAutocomplete';
import {TripSummaryEditDialog} from './TripSummaryEditDialog';

export const TripSummaryEditDriver: React.FC = () => {
  const notify = useAppNotifications();
  const {masterTrip, updateMasterTripPartial} = useTripContext();
  /** Destructure trip, need to add || ({} as Trip) for typescript, since trip is nullable. */
  const {driver, id: tripId} = masterTrip?.trip || ({} as Trip);
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [selectedDriver, setSelectedDriver] = React.useState<
    Listing | undefined
  >({
    label: `${driver?.externalEmployeeNumber} - ${driver?.firstname}`,
    value: driver?.id,
  });

  const handleChange = React.useCallback<TAutocompleteOnChange>(
    (_event, value) => {
      setSelectedDriver(value as Listing);
    },
    []
  );

  const handleSubmit = React.useCallback(async () => {
    setSubmitting(true);
    try {
      if (tripId) {
        //TODO Add DriverDump to the response,
        // so that it's not needed to do another call to get the updated driver details
        const response = await masterTripListDriverApi.apiTripDriverPost({
          body: {
            tripId,
            driverId: selectedDriver?.value ?? null,
          },
        });
        if (response && selectedDriver?.value) {
          const newDriver = await driverApi.apiDriverDriverIdGet({
            driverId: selectedDriver.value,
          });
          notify('success', 'Driver updated');
          updateMasterTripPartial({trip: {driver: newDriver}});
        } else {
          updateMasterTripPartial({trip: {driver: undefined}});
        }
      }
    } catch (e) {
      notify('error', e.message ?? 'Failed to update trip number');
    } finally {
      setSubmitting(false);
    }
  }, [tripId, selectedDriver?.value, notify, updateMasterTripPartial]);

  React.useEffect(() => {
    setSelectedDriver({
      label: `${driver?.externalEmployeeNumber} - ${driver?.firstname}`,
      value: driver?.id,
    });
    return () => setSelectedDriver(undefined);
  }, [driver]);

  return (
    <TripSummaryEditDialog
      hasPermission={RoleService.hasPermission('Trip Driver Assign', 'Assign')}
      loading={submitting}
      title="Driver"
      onAccept={handleSubmit}
    >
      <TripSummaryEditAutocomplete
        model="Driver"
        onChange={handleChange}
        value={selectedDriver}
        loading={submitting}
      />
    </TripSummaryEditDialog>
  );
};
