import React from 'react';
import Lottie from 'react-lottie';
import vantageLoadingAnimation from '../../img/json/vantageLoading.json';
import {useLoaderStyles} from './Loader.style';
import {ClassNameMap} from '@mui/styles';

interface LoaderProps {
  size?: number;
  text?: string;
  disableFullWidth?: boolean;
  loading?: boolean;
  classes?: ClassNameMap<'loaderContainer' | 'loaderTextContainer' | string>;
}
export const Loader: React.FC<LoaderProps> = (props) => {
  const {
    size,
    text,
    disableFullWidth,
    loading = true,
    classes: propsClasses,
  } = props;
  const classes = useLoaderStyles();
  return (
    <div
      className={propsClasses?.loaderContainer ?? classes.loaderContainer}
      style={{...(disableFullWidth ? {flex: 0, width: 'fit-content'} : {})}}
    >
      {loading && (
        <>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: vantageLoadingAnimation,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
            height={size || 60}
            width={size || 60}
            isStopped={false}
            isPaused={false}
            isClickToPauseDisabled
          />
          {text && (
            <div
              className={
                propsClasses?.loaderTextContainer ?? classes.loaderTextContainer
              }
            >
              {text}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Loader;
