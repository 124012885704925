import {DataGroup} from 'vis';
import clsx from 'clsx';
import {
  WebPlanningBoardBooking,
  WebPlanningBoardVehicle,
} from '@onroadvantage/onroadvantage-api';
import moment from 'moment/moment';
import {
  IPlanningBoardGanttItem,
  IPlanningBoardGanttItemWithInvalidItem,
  usePlanningBoardGanttStyles,
} from '../planningBoardPlan/planningBoardGantt';
import {getMasterTripTooltip} from './getMasterTripTooltip';
import {getMasterTripContent} from './getMasterTripContent';
import {IPlanningBoardGroupOptionItem} from '../planningBoardOptions';
import {
  MasterTripTypes,
  WebPlanningBoardMasterTripWithIdType,
} from '../planningBoardContext';
import {getMasterTripsWithSameVehicle} from './getMasterTripsWithSameVehicle';
import {getMasterTripsAfterCurrentTrip} from './getMasterTripsAfterCurrentTrip';
import {getActualTrip} from './getActualTrip';

export type TGetPlanningBoardGanttGroups = (
  vehicles: WebPlanningBoardVehicle[] | undefined,
  selectedGroupOptions: IPlanningBoardGroupOptionItem[]
) => DataGroup[];

export const getPlanningBoardGanttGroups: TGetPlanningBoardGanttGroups = (
  vehicles,
  selectedGroupOptions
) => {
  /*** Get Planning Board Gantt Groups which are the vehicles */
  const groups: DataGroup[] = [];
  vehicles?.forEach((vehicle) => {
    if (vehicle.id)
      groups.push({
        id: vehicle.id,
        title: vehicle.type?.skills?.map(({name}) => name).join('\n'),
        content: selectedGroupOptions
          .map(
            ({key}) =>
              (key === 'type' ? vehicle.type?.name : vehicle[key]) ?? '-'
          )
          .join(' | '),
      });
  });
  return groups;
};

export type TGetPlanningBoardGanttItems = (params: {
  bookings?: WebPlanningBoardBooking[];
  bookingMasterTrips?: WebPlanningBoardMasterTripWithIdType[];
  masterMasterTrips?: WebPlanningBoardMasterTripWithIdType[];
  optimalMasterTrips?: WebPlanningBoardMasterTripWithIdType[];
  planningMasterTrips?: WebPlanningBoardMasterTripWithIdType[];
  classes: ReturnType<typeof usePlanningBoardGanttStyles>;
  enableCheckTripActuals: boolean;
  enableCheckTripEnd: boolean;
}) => IPlanningBoardGanttItem[];

export const getPlanningBoardGanttItems: TGetPlanningBoardGanttItems = ({
  bookings,
  bookingMasterTrips,
  masterMasterTrips,
  optimalMasterTrips,
  planningMasterTrips,
  classes,
  enableCheckTripActuals,
  enableCheckTripEnd,
}) => {
  const items: IPlanningBoardGanttItem[] = [];
  /*** Made getPlanningBoardGanttItem generic function to handle duplicate code */

  const concatenatedTrips =
    bookingMasterTrips
      ?.concat(masterMasterTrips ?? [])
      .concat(planningMasterTrips ?? [])
      .concat(optimalMasterTrips ?? []) ?? [];

  bookingMasterTrips?.forEach((t) => {
    const bookingsMasterTripIds = bookings?.map(
      ({bookingMasterTripId}) => `booking${bookingMasterTripId}`
    );
    if (t.id && bookingsMasterTripIds?.includes(t.id?.toString())) {
      const addBookingMaintenanceTrip = getPlanningBoardGanttItem({
        masterTrip: t,
        type: 'maintenance',
        classes,
        concatenatedTrips,
        enableCheckTripEnd,
      });
      if (
        addBookingMaintenanceTrip &&
        !items.find(({id}) => id === addBookingMaintenanceTrip.id)
      ) {
        const {invalidItem, ...addItem} = addBookingMaintenanceTrip;
        items.push(addItem);
        if (invalidItem) items.push(invalidItem);
      }
    } else {
      const addBookingMasterTrip = getPlanningBoardGanttItem({
        masterTrip: t,
        type: 'booking',
        classes,
        concatenatedTrips,
        enableCheckTripEnd,
      });
      if (
        addBookingMasterTrip &&
        !items.find(({id}) => id === addBookingMasterTrip.id)
      ) {
        const {invalidItem, ...addItem} = addBookingMasterTrip;
        items.push(addItem);
        if (invalidItem) items.push(invalidItem);
      }
    }
  });
  masterMasterTrips?.forEach((t) => {
    const addMasterMasterTrip = getPlanningBoardGanttItem({
      masterTrip: t,
      type: 'master',
      classes,
      concatenatedTrips,
      enableCheckTripEnd,
    });
    if (
      addMasterMasterTrip &&
      !items.find(({id}) => id === addMasterMasterTrip.id)
    ) {
      const {invalidItem, ...addItem} = addMasterMasterTrip;
      items.push(addItem);
      if (invalidItem) items.push(invalidItem);
    }
    if (enableCheckTripActuals && t.trip?.vehicleId) {
      const masterTripsInRow = getMasterTripsWithSameVehicle(
        masterMasterTrips
          .concat(bookingMasterTrips ?? [])
          .concat(planningMasterTrips ?? []),
        t.trip?.vehicleId
      );

      const masterTripsAfterCurrentTrip = getMasterTripsAfterCurrentTrip(
        masterTripsInRow,
        t
      );

      let nextMasterTrip: WebPlanningBoardMasterTripWithIdType | undefined;
      masterTripsAfterCurrentTrip?.forEach((masterTrip) => {
        const tripStart =
          masterTrip.trip?.stops && masterTrip.trip.stops.length > 0
            ? masterTrip.trip.stops[masterTrip.trip.stops.length - 1]
                ?.serviceTimeStart
            : masterTrip.trip?.tripStart;

        if (!nextMasterTrip) {
          nextMasterTrip = masterTrip;
        } else {
          const nextMasterTripStart =
            nextMasterTrip.trip?.stops && nextMasterTrip.trip.stops.length > 0
              ? nextMasterTrip.trip.stops[nextMasterTrip.trip.stops.length - 1]
                  ?.serviceTimeStart
              : nextMasterTrip.trip?.tripStart;
          if (
            tripStart &&
            nextMasterTripStart &&
            tripStart < nextMasterTripStart
          ) {
            nextMasterTrip = masterTrip;
          }
        }
      });

      const addMasterMasterTripActual = getTripActualGanttItem({
        masterTrip: t,
        nextMasterTrip,
        classes,
      });

      if (addMasterMasterTripActual) {
        items.push(addMasterMasterTripActual);
      }
    }
  });
  optimalMasterTrips?.forEach((t) => {
    const addOptimalMasterTrip = getPlanningBoardGanttItem({
      masterTrip: t,
      type: 'optimal',
      classes,
      concatenatedTrips,
      enableCheckTripEnd,
    });
    if (
      addOptimalMasterTrip &&
      !items.find(({id}) => id === addOptimalMasterTrip.id)
    ) {
      const {invalidItem, ...addItem} = addOptimalMasterTrip;
      items.push(addItem);
      if (invalidItem) items.push(invalidItem);
    }
  });
  planningMasterTrips?.forEach((t) => {
    const addPlanningMasterTrip = getPlanningBoardGanttItem({
      masterTrip: t,
      type: 'planning',
      classes,
      concatenatedTrips,
      enableCheckTripEnd,
    });
    if (
      addPlanningMasterTrip &&
      !items.find(({id}) => id === addPlanningMasterTrip.id)
    ) {
      const {invalidItem, ...addItem} = addPlanningMasterTrip;
      items.push(addItem);
      if (invalidItem) items.push(invalidItem);
    }
  });

  return items;
};

interface IGetTripActualGanttItemParams {
  masterTrip: WebPlanningBoardMasterTripWithIdType;
  nextMasterTrip: WebPlanningBoardMasterTripWithIdType | undefined;
  classes: ReturnType<typeof usePlanningBoardGanttStyles>;
}
export type TGetTripActualGanttItem = (
  params: IGetTripActualGanttItemParams
) => IPlanningBoardGanttItem | undefined;

const getTripActualGanttItem: TGetTripActualGanttItem = ({
  masterTrip,
  nextMasterTrip,
  classes,
}) => {
  const actualTripResponse = getActualTrip(masterTrip, nextMasterTrip);
  if (actualTripResponse) {
    const {start, end, status, vehicleId, tripNumber, timelineId} =
      actualTripResponse;
    return {
      className: clsx(classes.ganttItem, {
        [classes.ganttItemMasterActualGreen]: status === 'safe',
        [classes.ganttItemMasterActualOrange]: status === 'late',
        [classes.ganttItemMasterActualRed]: status === 'overlaps',
      }),
      title: getMasterTripTooltip(masterTrip),
      content: `Revised ${tripNumber}`,
      group: vehicleId,
      data: masterTrip,
      start: start,
      end: end,
      id: timelineId,
      subgroup: timelineId,
      variant: 'masterTrip',
      vehicle: undefined,
      masterTrip,
    };
  }

  return undefined;
};

interface IGetPlanningBoardGanttItemParams {
  masterTrip: WebPlanningBoardMasterTripWithIdType;
  type: MasterTripTypes;
  classes: ReturnType<typeof usePlanningBoardGanttStyles>;
  concatenatedTrips: WebPlanningBoardMasterTripWithIdType[];
  enableCheckTripEnd: boolean;
}

export type TGetPlanningBoardGanttItem = (
  params: IGetPlanningBoardGanttItemParams
) => IPlanningBoardGanttItemWithInvalidItem | undefined;

export const getPlanningBoardGanttItem: TGetPlanningBoardGanttItem = ({
  masterTrip,
  type,
  classes,
  concatenatedTrips,
  enableCheckTripEnd,
}) => {
  if (
    masterTrip &&
    masterTrip.trip &&
    masterTrip.trip.tripStart &&
    masterTrip.trip.tripNumber &&
    masterTrip.trip.stops &&
    masterTrip.trip.stops.length > 0
  ) {
    let invalidItem: IPlanningBoardGanttItem | undefined;

    const serviceStart =
      masterTrip.trip.stops[0].serviceTimeStart ?? masterTrip.trip.tripStart;
    const serviceEnd =
      masterTrip.trip.stops[masterTrip.trip.stops.length - 1].serviceTimeEnd ??
      masterTrip.trip.tripEnd;
    const timelineId = (masterTrip.id ?? masterTrip.tripNumber) as
      | string
      | number;
    /**
     * Use concatenatedTrips to get all trips in the same group.
     * Get all the trips that are after the current trip.
     * Get the trip that occurs right after the current trip.
     * Then check if the EndingPoint of the current trip and the StartingPoint of the next trip match.
     * And if they don't add a red highlight to the trip.
     */
    const tripsInGroup = concatenatedTrips?.filter(
      ({trip}) => trip?.vehicleId === masterTrip.trip?.vehicleId
    );
    if (tripsInGroup && enableCheckTripEnd) {
      const masterTripsAfterCurrentTrip = tripsInGroup?.filter(
        ({trip}) =>
          trip?.tripStart &&
          masterTrip.trip?.tripEnd &&
          moment(trip.tripStart).isAfter(masterTrip.trip?.tripEnd)
      );
      if (masterTripsAfterCurrentTrip.length > 0) {
        let nextTrip: WebPlanningBoardMasterTripWithIdType | undefined;
        masterTripsAfterCurrentTrip.forEach((masterTripAfter) => {
          if (!nextTrip) {
            nextTrip = masterTripAfter;
          } else if (
            moment(nextTrip.trip?.tripStart).isAfter(
              masterTripAfter.trip?.tripStart
            )
          ) {
            nextTrip = masterTripAfter;
          }
        });
        const nextTripStops = nextTrip?.trip?.stops;
        const masterTripStops = masterTrip.trip?.stops;
        if (
          nextTripStops &&
          nextTripStops.length > 0 &&
          masterTripStops &&
          masterTripStops.length > 0
        ) {
          const currentEndingPointId =
            masterTripStops[masterTripStops.length - 1].node?.id;
          const nextLoadingPointId = nextTripStops[0].node?.id;
          const nextTripServiceStart =
            nextTripStops[0].serviceTimeStart ?? nextTrip?.trip?.tripStart;
          if (currentEndingPointId !== nextLoadingPointId) {
            invalidItem = {
              className: 'invalidEndingPoint',
              type: 'background',
              title: getMasterTripTooltip(masterTrip),
              content: '',
              group: masterTrip.trip.vehicleId,
              data: masterTrip,
              start:
                serviceEnd && serviceEnd < serviceStart
                  ? new Date(
                      1000 * 60 * 200 + +serviceStart.valueOf()
                    ).toISOString()
                  : serviceEnd ?? (masterTrip.trip.tripEnd as Date),
              end: nextTripServiceStart,
              id: `background${timelineId}`,
              subgroup: timelineId,
              variant: 'masterTrip',
              vehicle: undefined,
              editable: true,
              align: 'center',
            };
          }
        }
      }
    }

    return {
      className: clsx(classes.ganttItem, {
        [classes.ganttItemBooking]: type === 'booking',
        [classes.ganttItemMaintenance]:
          type === 'maintenance' ||
          masterTrip.trip.planningOptions?.isAppointment,
        [classes.ganttItemMaster]:
          type === 'master' && !masterTrip.trip.planningOptions?.isAppointment,
        [classes.ganttItemOptimized]: type === 'optimal',
        [classes.ganttItemPlanning]: type === 'planning',
      }),
      title: getMasterTripTooltip(masterTrip),
      content: getMasterTripContent(masterTrip),
      group: masterTrip.trip.vehicleId,
      data: masterTrip,
      start: serviceStart,
      end:
        serviceEnd && serviceEnd < serviceStart
          ? new Date(1000 * 60 * 200 + +serviceStart.valueOf()).toISOString()
          : serviceEnd,
      id: timelineId,
      subgroup: timelineId,
      variant:
        type === 'optimal'
          ? 'optimalMasterTrip'
          : type === 'maintenance'
          ? 'booking'
          : 'masterTrip',
      vehicle: undefined,
      invalidItem,
    };
  }
};
