import React from 'react';
import {PlanningMasterTripTrip} from '@onroadvantage/onroadvantage-api';
import {TripEditCard} from './tripEditOld/TripEditCard';
import {planningMasterTripApi} from '../../../api';
import {TripService} from '../../../service';

interface Props {
  trip: PlanningMasterTripTrip;
}

const PlanningTripEdit = ({trip}: Props) => {
  const handleSubmit = async (clonedTrip: any, tripStops: any) => {
    const t = TripService.formulateEditPayload(clonedTrip, tripStops);
    const responsePlanningMasterTrip =
      await planningMasterTripApi.apiPlanningMasterTripEditPost({
        body: t,
      });
    setTimeout(() => {
      window.location.replace(
        window.location.href.replace(
          `/t/${trip.id}`,
          `?planningMasterTripId=${responsePlanningMasterTrip?.id}&resetForm=1`
        )
      );
    }, 100);
  };

  return (
    <>
      <TripEditCard
        trip={trip}
        overrideSubmit={handleSubmit}
        allowDepotEdits={true}
        style={{width: '100%'}}
        withoutForm={true}
      />
    </>
  );
};

export default PlanningTripEdit;
