import React from 'react';
import {Typography} from '@mui/material';
import {MasterTripQuery} from '@onroadvantage/onroadvantage-api';
import {useTripContext} from '../tripContext';
import {Loader} from '../../loader';
import Timeline from '../../timeline/timeline';
import {ITimelinePlayback} from '../tripTimelineActions';

interface TripTimelineProps {
  playback: ITimelinePlayback;
}

export const TripGanttTimeline: React.FC<TripTimelineProps> = ({playback}) => {
  const {
    masterTrip,
    actualTrip,
    adjacentTrips,
    loadingAdjacentTrips,
    loadingTelematicsEvents,
    loadingMasterTrip,
    loadAdjacentTrips,
    loadCriticalEvents,
    loadOperationalEvents,
    loadTelematicsEvents,
  } = useTripContext();
  /** Destructure masterTrip, need to add || ({} as MasterTripQuery) for typescript, since masterTrip is nullable. */
  const {trip} = React.useMemo(
    () => masterTrip || ({} as MasterTripQuery),
    [masterTrip]
  );

  const loadingGantt =
    loadingAdjacentTrips || loadingMasterTrip || loadingTelematicsEvents;

  const hasRequiredDataForGantt = React.useMemo(
    () => !!masterTrip,
    [masterTrip]
  );

  const handleUpdateTelematicsEventsRange = React.useCallback(
    async (dateRange) => {
      await Promise.all([
        loadTelematicsEvents({reload: true, dateRange}),
        loadCriticalEvents({reload: true, dateRange}),
        loadOperationalEvents({reload: true, dateRange}),
        loadAdjacentTrips({
          reload: true,
          telematicsEventsDateRange: dateRange,
        }),
      ]);
    },
    [
      loadAdjacentTrips,
      loadCriticalEvents,
      loadOperationalEvents,
      loadTelematicsEvents,
    ]
  );

  if (loadingGantt) return <Loader text="Initializing Gantt..." />;

  if (!hasRequiredDataForGantt) {
    return (
      <Typography p={2} textAlign="center" variant="h3">
        Failed to initialize Gantt
      </Typography>
    );
  }

  return (
    <Timeline
      playback={playback}
      trip={trip}
      actualTrip={actualTrip}
      adjacentTrips={adjacentTrips}
      updateTelematicsEventsRange={handleUpdateTelematicsEventsRange}
    />
  );
};
