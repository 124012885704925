import React from 'react';
import {useLocalStore} from 'mobx-react';
import {planningSolutionStoreContext} from './PlanningSolution.context';
import {planningSolutionStore} from './PlanningSolution.store';

// eslint-disable-next-line import/prefer-default-export
export const PlanningSolutionProvider: React.FC = ({children}) => {
  const store = useLocalStore(planningSolutionStore);

  return (
    <planningSolutionStoreContext.Provider value={store}>
      {children}
    </planningSolutionStoreContext.Provider>
  );
};
