import React from 'react';
import _ from 'lodash';
import {
  ITemplateControllerRoute,
  TemplateController,
} from '../../factory/template';
import {BroadcastContext, BroadcastContextProvider} from './BroadcastContext';
import {BroadcastList} from './BroadcastList';
import {BroadcastIdHandler} from './BroadcastIdHandler';
import {Broadcast} from './Broadcast';

export const BroadcastController: React.FC = () => {
  return (
    <BroadcastContextProvider>
      <BroadcastControllerComponent />
    </BroadcastContextProvider>
  );
};

const BroadcastControllerComponent: React.FC = () => {
  const {broadcast, loading} = React.useContext(BroadcastContext);
  const routes: ITemplateControllerRoute[] = React.useMemo(
    () => [
      {title: 'Broadcast List', component: BroadcastList},
      {
        path: '/:broadcastId',
        title: (params) =>
          loading
            ? 'Broadcast Loading...'
            : broadcast?.heading ??
              `Broadcast ${_.get(params, 'broadcastId', 'Single')}`,
        component: Broadcast,
        IdHandler: BroadcastIdHandler,
      },
    ],
    [loading, broadcast?.heading]
  );
  return <TemplateController rootPath="/broadcastlist" routes={routes} />;
};
