import {WebEvent} from '@onroadvantage/onroadvantage-api';

export const sortByEventDate = (a: WebEvent, b: WebEvent) => {
  const eventDateA = a.eventDate;
  const eventDateB = b.eventDate;
  if (!eventDateA || !eventDateB) return 0;
  if (eventDateA > eventDateB) return -1;
  else if (eventDateA < eventDateB) return 1;
  else return 0;
};
