import ConfigService from './ConfigService/ConfigService';
import ResponseService from './ResponseService';
import {encodeUrl} from './Util';
import {authStore} from '../store';

const serverUrl = ConfigService.serverUri;
const queryUrl = `${serverUrl}/api/telematics_event`;

const TelematicsEventService = {
  query: async (params) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(encodeUrl(queryUrl, params), {
      method: 'GET',
      mode: 'cors',
      headers,
    });
    return ResponseService.checkStatus(response);
  },
};

export default TelematicsEventService;
