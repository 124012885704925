import React from 'react';
import {CustomerForm} from './CustomerForm';
import {ITemplateTabControllerTab, TemplateTabs} from '../../factory/template';
import {CustomerContext} from './CustomerContext';
import {CustomerSites} from './CustomerSites';

export const Customer: React.FC = () => {
  const {customerId, loading} = React.useContext(CustomerContext);

  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {title: 'Details', element: <CustomerForm />},
    {title: 'Sites', element: <CustomerSites />},
  ]);

  const path = React.useMemo(() => `/customerlist/${customerId}`, [customerId]);

  return <TemplateTabs tabs={tabs} path={path} loading={loading} />;
};
