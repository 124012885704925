import React from 'react';
import 'typeface-roboto';
import Lottie from 'react-lottie';
import {getStyle} from '../helpers';
import vantageLoading from '../../../../img/json/ex-veiwMetricsLogoOnly-bm.json';

const componentStyle = (brandColors) => ({
  default: {
    container: {
      display: 'flex',
      height: 'calc(100vw / 1920 * 100)',
      width: '100vw',
      backgroundColor: brandColors.brandBackground,
    },
    head: {
      backgroundColor: brandColors.brandPrimaryLighter,
      borderRadius: '0 0 calc(100vw / 1920 * 24) 0',
      // position: 'absolute',
      display: 'flex',
      height: 'calc(100vw / 1920 * 100)',
      // width: 'calc(100vw / 1920 * 587)',
      justifyContent: 'center',
      alignItems: 'center',
      width: 'auto',
    },
    title: {
      fontStyle: 'normal',
      fontWeight: 900,
      textTransform: 'uppercase',
      fontSize: 'calc(100vw / 1920 * 48)',
      color: brandColors.brandPrimaryText,
      fontFamily: 'Roboto',
      paddingBottom: 'calc(100vw / 1920 * 20)',
      paddingTop: 'calc(100vw / 1920 * 20)',
      paddingLeft: 'calc(100vw / 1920 * 40)',
      paddingRight: 'calc(100vw / 1920 * 31)',
      whiteSpace: 'nowrap',
    },
    side: {
      backgroundColor: brandColors.brandPrimary,
      display: 'flex',
      flex: 1,
      height: 'calc(100vw / 1920 * 80)',
      flexDirection: 'row',
      // width: '100vw',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    side1: {
      display: 'flex',
      // width: 'calc(100vw / 1920 * 822)',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    side2: {
      display: 'flex',
      width: 'calc(100vw / 1920 * 463)',
      justifyContent: 'flex-end',
      alignItems: 'center',
      paddingRight: 'calc(100vw / 1920 * 48)',
    },
    sideText: {
      color: brandColors.brandPrimaryText,
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontFamily: 'Roboto',
      fontSize: 'calc(100vw / 1920 * 48)',
    },
    logo: {
      display: 'none',
    },
  },
  unitrans: {
    head: {},
    title: {},
    side: {},
    logo: {
      display: 'flex',
      marginLeft: 'calc(100vw / 1920 * 30)',
      height: 'calc(100vw / 1920 * 80)',
      width: 'calc(100vw / 1920 * 80)',
    },
  },
});

const CriticalEventsHeader = ({title}) => {
  const styles = getStyle(componentStyle);
  return (
    <div style={styles.container}>
      <div style={styles.head}>
        <div style={styles.logo}>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: vantageLoading,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
            // height={80}
            // width={80}
            isClickToPauseDisabled
          />
        </div>
        <div style={styles.title}>{title}</div>
      </div>
      <div style={styles.side}>
        <div style={styles.side1}>
          <div style={styles.sideText}>Oldest Unassigned</div>
        </div>
        <div style={styles.side2}>
          <div style={styles.sideText}>Unassigned / Open</div>
        </div>
      </div>
    </div>
  );
};

export default CriticalEventsHeader;
