import React from 'react';
import {useFormikContext} from 'formik';
import {FormikAutocompleteHooks} from '../../formik';
import {Listing} from '@onroadvantage/onroadvantage-api';
import {nodeApi} from '../../../api';
import {appNotificationStore} from '../../../stores/mobxStores';
import {debounce} from '@mui/material';
import {ListingService} from '../../../service';
import {OrderContext} from '../OrderContext';
import {IOrderForm} from './OrderForm';
import {TAutocompleteOnInputChange} from '../../autocomplete';

interface OrderFormAutocompleteProps {
  model: 'OFFLOADING' | 'LOADING' | 'Status';
  name: string;
  label: string;
  disabled?: boolean;
  placeholder?: string;
}

export const OrderFormAutocomplete: React.FC<OrderFormAutocompleteProps> = ({
  disabled,
  name,
  label,
  model,
  placeholder,
}) => {
  const {setFieldValue, setFieldTouched, values, initialValues} =
    useFormikContext<IOrderForm>();

  const {statuses} = React.useContext(OrderContext);
  const [listing, setListing] = React.useState<Listing[]>([]);
  const [localLoading, setLocalLoading] = React.useState(false);
  const [statusListing] = React.useState(
    statuses.map((status) => ({
      label: status,
      value: statuses.indexOf(status),
    }))
  );

  const getListing = React.useCallback(
    async (filter?: string) => {
      setLocalLoading(true);
      setListing([]);
      try {
        if (model === 'Status') {
          setListing(
            filter
              ? statusListing.filter((status) =>
                  JSON.stringify(status).includes(filter)
                )
              : statusListing
          );
        } else if (model === 'OFFLOADING' || model === 'LOADING') {
          const response = await nodeApi.apiNodeListingGet({
            type: model,
            customerId: values.customer?.value,
            query: filter,
          });
          setListing(
            (await ListingService.prepareSelectOptions(response.items)) ?? []
          );
        }
      } catch (e) {
        appNotificationStore.enqueueNotification('error', e.message);
      } finally {
        setLocalLoading(false);
      }
    },
    [model, statusListing, values.customer?.value]
  );

  const getListingDebounce = React.useRef(debounce(getListing, 500)).current;

  const handleInputChange: TAutocompleteOnInputChange = async (
    _event,
    value: string
  ) => {
    setLocalLoading(true);
    await getListingDebounce(value);
  };

  const setLoadingPoints = React.useCallback(
    (action: 'initial' | 'null') => {
      if (action === 'initial') {
        setFieldValue('offloadPoint', initialValues.offloadPoint);
        setFieldValue('upliftPoint', initialValues.upliftPoint);
      } else {
        setFieldValue('offloadPoint', null);
        setFieldValue('upliftPoint', null);
      }
      setFieldTouched('offloadPoint', false, false);
      setFieldTouched('upliftPoint', false, false);
    },
    [
      initialValues.offloadPoint,
      initialValues.upliftPoint,
      setFieldTouched,
      setFieldValue,
    ]
  );

  React.useEffect(() => {
    if (values.customer?.value !== initialValues.customer?.value) {
      getListing();
      setLoadingPoints('null');
    } else {
      getListing();
      setLoadingPoints('initial');
    }
  }, [
    getListing,
    initialValues.customer,
    setFieldValue,
    setLoadingPoints,
    values.customer,
  ]);

  return (
    <FormikAutocompleteHooks
      loading={localLoading}
      onInputChange={handleInputChange}
      options={listing}
      placeholder={placeholder}
      disabled={disabled}
      label={label}
      id={name}
      name={name}
    />
  );
};
