import React from 'react';
import {
  IVehicleBookingAvailabilityForm,
  VehicleContext,
} from '../VehicleBookingAvailabilityContext';
import {
  FormikAutocompleteWithListing,
  FormikDateTimePicker,
} from '../../formik';
import * as Yup from 'yup';
import {Listing} from '@onroadvantage/onroadvantage-api';
import moment from 'moment';
import {useVehicleBookingAvailabilityFormStyles} from './VehicleBookingAvailabilityForm.style';
import {TemplateCard, TemplateForm} from '../../../factory/template';
import {Search} from '@mui/icons-material';

const listingSchema: Yup.SchemaOf<Listing> = Yup.object({
  label: Yup.string().default(undefined),
  metaData: Yup.string().default(undefined),
  value: Yup.number().default(undefined),
});

const schema: Yup.SchemaOf<IVehicleBookingAvailabilityForm | any> = Yup.object({
  contract: listingSchema.nullable().required('Required'),
  product: listingSchema.nullable().required('Required'),
});
export const VehicleBookingAvailabilityForm: React.FC = () => {
  const classes = useVehicleBookingAvailabilityFormStyles();
  const {detailsRef, loading, onDetailsFormSubmit} =
    React.useContext(VehicleContext);
  const [initialValues] = React.useState<IVehicleBookingAvailabilityForm>({
    contract: {label: undefined, value: undefined},
    product: {label: undefined, value: undefined},
    fromNode: {label: undefined, value: undefined},
    toNode: {label: undefined, value: undefined},
    endDate: moment(),
    startDate: moment(),
  });

  return (
    <TemplateCard
      title="Customer Vehicle Query"
      avatar={<Search />}
      classes={{card: classes.root}}
    >
      <TemplateForm
        initialValues={initialValues}
        onSubmit={onDetailsFormSubmit}
        validationSchema={schema}
        innerRef={detailsRef}
        submitting={loading}
      >
        <FormikAutocompleteWithListing
          id="contract"
          label="Contract"
          name="contract"
          placeholder="Enter and select a value"
          model="Contract"
          loading={loading}
        />
        <FormikAutocompleteWithListing
          id="fromNode"
          label="From Site"
          name="fromNode"
          placeholder="Enter and select a value"
          model="Node"
          loading={loading}
        />
        <FormikAutocompleteWithListing
          id="toNode"
          label="To Site"
          name="toNode"
          placeholder="Enter and select a value"
          model="Node"
          loading={loading}
        />
        <FormikAutocompleteWithListing
          id="product"
          label="Product"
          name="product"
          placeholder="Enter and select a value"
          model="Product"
          loading={loading}
        />
        <FormikDateTimePicker
          name="startDate"
          placeholder="Enter the start datetime"
          label="Start Time"
          InputLabelProps={{shrink: true}}
          fullWidth
        />
        <FormikDateTimePicker
          name="endDate"
          placeholder="Enter the end datetime"
          label="End DateTime"
          InputLabelProps={{shrink: true}}
          fullWidth
        />
      </TemplateForm>
    </TemplateCard>
  );
};
