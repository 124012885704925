import React from 'react';
import {Route, Switch} from 'react-router-dom';
import _ from 'lodash';
import {Paper} from '@mui/material';
import {useContactControllerStyles} from './ContactController.style';
import {
  ContactContext,
  ContactContextProps,
  ContactContextProvider,
} from './ContactContext';
import {
  BreadcrumbController,
  BreadcrumbRoute,
} from '../breadcrumbs/BreadcrumbController';
import {ContactList} from './ContactList';
import {ContactIdHandler} from './ContactIdHandler';
import {Contact} from './Contact';
import {ContactUser} from './contactUser';
import {AllowedRoute} from '../router';
import {ContactForm} from './contactForm';

export const ContactControllerComponent: React.FC = () => {
  const classes = useContactControllerStyles();
  const {contact, loading} =
    React.useContext<ContactContextProps>(ContactContext);

  const routes: BreadcrumbRoute[] = [
    {
      path: '/contactlist',
      matchOptions: undefined,
      title: 'Contact List',
    },
    {
      path: '/contactlist/add',
      matchOptions: undefined,
      title: 'New Contact',
    },
    {
      path: '/contactlist/:contactId',
      matchOptions: undefined,
      title: (params) =>
        loading
          ? 'Contact Loading...'
          : contact?.name ?? `Contact ${_.get(params, 'contactId', 'Single')}`,
    },
  ];

  return (
    <div className={classes.root}>
      <BreadcrumbController routes={routes} />
      <Paper className={classes.container}>
        <Route exact path="/contactlist" component={ContactList} />
        <Route path="/contactlist/:contactId" component={ContactIdHandler} />
        <Switch>
          <AllowedRoute
            exact
            path="/contactlist/add"
            name="Add Contact"
            type="Add"
            component={() => <ContactForm isAdd />}
          />
          <Route exact path="/contactlist/:contactId" component={Contact} />
        </Switch>
        <Switch>
          <Route
            exact
            path="/contactlist/:contactId/user"
            component={ContactUser}
          />
        </Switch>
      </Paper>
    </div>
  );
};

export const ContactController: React.FC = () => {
  return (
    <ContactContextProvider>
      <ContactControllerComponent />
    </ContactContextProvider>
  );
};
