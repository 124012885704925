import React from 'react';
import {TemplateCard, TemplateForm} from '../../factory/template';
import {ContactGroupContext, TContactGroupForm} from './ContactGroupContext';
import {ContactGroup} from '@onroadvantage/onroadvantage-api';
import * as Yup from 'yup';
import {FormikTextField} from '../formik';

const schema: Yup.SchemaOf<TContactGroupForm> = Yup.object({
  name: Yup.string().required('Required'),
});

const getInitialValues = (
  contactGroup?: ContactGroup | null
): TContactGroupForm | undefined => {
  if (contactGroup) {
    return {
      name: contactGroup.name,
    };
  }
  return undefined;
};

export const ContactGroupForm: React.FC<{isAdd?: boolean}> = ({isAdd}) => {
  const {detailsRef, loading, submitting, contactGroup, onDetailsFormSubmit} =
    React.useContext(ContactGroupContext);
  const [initialValues, setInitialValues] = React.useState<TContactGroupForm>();

  React.useEffect(() => {
    const values = getInitialValues(contactGroup);

    if (values) {
      setInitialValues(values);
    } else if (isAdd) {
      setInitialValues({
        name: '',
      });
    }
  }, [isAdd, contactGroup, setInitialValues]);

  return (
    <TemplateCard
      title={`ContactGroup Details ${isAdd ? 'Add' : 'Edit'}`}
      loading={loading}
    >
      <TemplateForm<TContactGroupForm>
        initialValues={initialValues}
        onSubmit={onDetailsFormSubmit}
        permission={{
          name: `${isAdd ? 'Add' : 'Edit'} ContactGroup`,
          type: isAdd ? 'Add' : 'Edit',
        }}
        submitting={submitting}
        innerRef={detailsRef}
        validationSchema={schema}
      >
        <FormikTextField
          name="name"
          placeholder="Enter the contact group name"
          label="Contact Group Name"
          fullWidth
        />
      </TemplateForm>
    </TemplateCard>
  );
};
