import React from 'react';
import {DriverGroupForm} from './DriverGroupForm';
import {ITemplateTabControllerTab, TemplateTabs} from '../../factory/template';
import {useDriverGroupContext} from './driverGroupContext';
import {DriverGroupDriverList} from './DriverGroupDriverList';

export const DriverGroup: React.FC = () => {
  const {driverGroupId, loadingDriverGroup} = useDriverGroupContext();

  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {title: 'Details', element: <DriverGroupForm />},
    {title: 'Drivers', element: <DriverGroupDriverList />},
  ]);

  const path = React.useMemo(
    () => `/drivergrouplist/${driverGroupId}`,
    [driverGroupId]
  );

  return <TemplateTabs tabs={tabs} path={path} loading={loadingDriverGroup} />;
};
