import React from 'react';
import {List, ListItem, ListItemText} from '@mui/material';
import {SpeakerNotes} from '@mui/icons-material';
import {useTripSetupContext} from '../TripSetupContext';
import {DateTimeFormatService} from '../../../../service/FormatService';
import {TemplateCard} from '../../../../factory/template';

export const TripSetupSummaryStepDetails = () => {
  const {details} = useTripSetupContext();
  return (
    <TemplateCard avatar={<SpeakerNotes />} title="Details">
      <List>
        <ListItem>
          <ListItemText
            primary="Trip Start"
            secondary={new DateTimeFormatService().formatDateTime(
              details.tripStart
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Contract"
            secondary={details.contract?.label}
          />
        </ListItem>
        <ListItem>
          <ListItemText primary="Vehicle" secondary={details.vehicle?.label} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Driver" secondary={details.driver?.label} />
        </ListItem>
      </List>
    </TemplateCard>
  );
};
