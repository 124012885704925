import React from 'react';
import {NotificationConfigContext} from '../NotificationConfigContext';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../../factory/template';
import {EventNotificationConfig} from '@onroadvantage/onroadvantage-api';
import {NotificationConfigOperationalEventContext} from './NotificationConfigOperationalEventContext';

export const NotificationConfigOperationalEventTriggersList: React.FC = () => {
  const {notificationConfig} = React.useContext(NotificationConfigContext);

  const config: TemplateTableConfig<EventNotificationConfig> = React.useMemo(
    () => ({
      columns: [
        {
          name: 'contractOperationalEventTypeConfig',
          label: 'Event Type Name',
          type: 'string',
          model: ['ContractOperationalEventTypeConfig'],
          enableEditing: false,
          enableAutocomplete: true,
          getValue: ({contractOperationalEventTypeConfig}) =>
            contractOperationalEventTypeConfig?.operationalEventType?.name,
        },
        {
          name: 'contractCode',
          label: 'Contract Code',
          type: 'string',
          enableEditing: false,
          getValue: ({contractOperationalEventTypeConfig}) =>
            contractOperationalEventTypeConfig?.contract?.code,
        },
        {
          name: 'notifyContractContactGroups',
          label: 'Contract Contact Groups',
          type: 'bool',
          enableEditing: true,
        },
        {
          name: 'notifyCustomerContactGroups',
          label: 'Customer Contact Groups',
          type: 'bool',
          enableEditing: true,
        },
        {
          name: 'notifyNodeContactGroups',
          label: 'Node Contact Groups',
          type: 'bool',
          enableEditing: true,
        },
        {
          name: 'notifyVehicleGroupContactGroups',
          label: 'Vehicle Group Contact Groups',
          type: 'bool',
          enableEditing: true,
        },
        {
          name: 'notifyDriver',
          label: 'Driver',
          type: 'bool',
          enableEditing: true,
        },
        {
          name: 'notifyVehicle',
          label: 'Vehicle',
          type: 'bool',
          enableEditing: true,
        },
        {
          name: 'notifyWeb',
          label: 'Web',
          type: 'bool',
          enableEditing: true,
        },
        {
          name: 'notifyNextStop',
          label: 'Next Stop',
          type: 'bool',
          enableEditing: true,
        },
        {
          name: 'notifyOffloadingStops',
          label: 'Offload Stops',
          type: 'bool',
          enableEditing: true,
        },
      ],
      disablePagination: true,
      disableToolbar: true,
      deleteMessage: (row) => ({
        title: 'Notification Config Operational Event',
        items: {
          NotificationConfigName: notificationConfig?.name,
          EventType:
            row?.contractOperationalEventTypeConfig?.operationalEventType?.name,
          ContractCode: row?.contractOperationalEventTypeConfig?.contract?.code,
        },
      }),
      identifier: 'NOTIFICATION_CONFIG_OPERATIONAL_EVENT_TRIGGERS_LIST',
    }),
    [notificationConfig?.name]
  );

  return (
    <TemplateTableWrapper
      config={config}
      context={NotificationConfigOperationalEventContext}
    />
  );
};
