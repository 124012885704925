import React from 'react';
import {Stack} from '@mui/material';
import {BroadcastVantageMobileStepper} from './BroadcastVantageMobileStepper';
import {
  broadcastVantageMobileStepsElements,
  useBroadcastVantageMobile,
} from './useBroadcastVantageMobile';
import {
  BroadcastVantageMobileContext,
  BroadcastVantageMobileContextProvider,
} from './BroadcastVantageMobileContext';
import {Loader} from '../../loader';
import {useBroadcastVantageMobileStyles} from './BroadcastVantageMobile.styles';

export const BroadcastVantageMobile = () => {
  const classes = useBroadcastVantageMobileStyles();
  const {activeStep, completedSteps, onNextStep, onPreviousStep, onGoToStep} =
    useBroadcastVantageMobile();

  return (
    <Stack>
      <BroadcastVantageMobileContextProvider>
        <BroadcastVantageMobileStepper
          activeStep={activeStep}
          completedSteps={completedSteps}
          onNextStep={onNextStep}
          onPreviousStep={onPreviousStep}
          onGoToStep={onGoToStep}
        >
          <BroadcastVantageMobileContext.Consumer>
            {(value) =>
              value?.submitting === true ? (
                <Loader
                  text="Sending Broadcast..."
                  classes={{loaderContainer: classes.loaderContainer}}
                />
              ) : (
                broadcastVantageMobileStepsElements[activeStep]
              )
            }
          </BroadcastVantageMobileContext.Consumer>
        </BroadcastVantageMobileStepper>
      </BroadcastVantageMobileContextProvider>
    </Stack>
  );
};
