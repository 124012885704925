import React from 'react';
import {Stack, TextField} from '@mui/material';
import {useEventsBulkClosingContext} from './EventsBulkClosingContext';

export const EventsBulkClosingCommentStep = () => {
  const {commentInputRef} = useEventsBulkClosingContext();

  return (
    <Stack spacing={2} py={2}>
      <Stack direction="row" spacing={1} alignItems="center">
        <TextField
          style={{width: 600}}
          helperText="Enter your comment here"
          label="Comment"
          multiline={true}
          rows={2}
          inputRef={commentInputRef}
        />
      </Stack>
    </Stack>
  );
};
