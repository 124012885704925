import React from 'react';
import {Loader} from '../loader';
import {Button, ButtonProps} from '@mui/material';
import {RoleService} from '../../service';
import {useFormikContext} from 'formik';
import {IPermission} from '../../factory/template';

export interface FormikSubmitProps extends ButtonProps {
  disableIsDirty?: boolean;
  loading?: boolean;
  title?: string;
  permission?: IPermission;
  enabled?: boolean;
}

export const FormikSubmit: React.FC<FormikSubmitProps> = ({
  disableIsDirty,
  loading,
  title,
  permission,
  enabled,
  disabled,
  ...buttonProps
}) => {
  const {dirty, isSubmitting} = useFormikContext();
  const [hasPermission] = React.useState(
    !permission ||
      (permission &&
        RoleService.hasPermission(permission.name, permission.type))
  );

  const isDisabled = React.useMemo(() => {
    if (enabled) return false;

    if (disabled !== undefined) return disabled;

    if (
      !hasPermission ||
      (!disableIsDirty && !dirty) ||
      loading ||
      isSubmitting
    ) {
      return true;
    }
  }, [
    dirty,
    disabled,
    disableIsDirty,
    enabled,
    hasPermission,
    loading,
    isSubmitting,
  ]);

  return (
    <Button
      disabled={isDisabled}
      variant="contained"
      {...buttonProps}
      data-cy="submit"
      startIcon={
        <Loader
          size={25}
          loading={hasPermission && (loading || isSubmitting)}
        />
      }
      type="submit"
    >
      {hasPermission && isSubmitting ? 'Submitting...' : title ?? 'Submit'}
    </Button>
  );
};
