import React from 'react';
import {
  ApiAdminCriticalEventTypeGetRequest,
  AdminCriticalEventType,
  AdminCriticalEventTypeListingResponse,
  CriticalEventBulkActionPost,
} from '@onroadvantage/onroadvantage-api';
import {useAppNotifications} from '../../contexts';
import {criticalEventApi, criticalEventTypeApi} from '../../api';
import {
  TemplateTableContextProps,
  TLoadList,
  TOnInlineEdit,
  useTemplateTable,
} from '../../factory/template';
import {FormikHelpers, FormikProps} from 'formik/dist/types';
import {ICriticalEventTypeBulkForm} from './CriticalEventTypeBulkCloseForm';

export interface CriticalEventTypeContextProps
  extends TemplateTableContextProps<
    AdminCriticalEventType,
    AdminCriticalEventTypeListingResponse
  > {
  detailsRef?: React.Ref<FormikProps<ICriticalEventTypeBulkForm>>;
  onDetailsFormSubmit: (
    values: ICriticalEventTypeBulkForm,
    formikHelpers: FormikHelpers<ICriticalEventTypeBulkForm>
  ) => void;
  submitting: boolean;
}

export const CriticalEventTypeContext =
  React.createContext<CriticalEventTypeContextProps>({
    // Template Table Defaults
    loading: false,
    list: [],
    currentPage: 1,
    // AdminCriticalEventType
    loadList: async () => {},
    onDetailsFormSubmit: () => null,
    submitting: false,
  });

interface CriticalEventTypeContextProviderProps {
  adminCriticalEventTypeId?: number;
}

export const CriticalEventTypeContextProvider: React.FC<
  CriticalEventTypeContextProviderProps
> = ({children}) => {
  const notify = useAppNotifications();
  // Template Table
  const [
    {
      // States
      currentPage,
      filters,
      hasPermission,
      itemTotal,
      list,
      loading,
      pageSize,
      pageTotal,
      sorting,
    },
    {
      // Getters
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
    },
  ] = useTemplateTable<
    AdminCriticalEventType,
    ApiAdminCriticalEventTypeGetRequest
  >({
    editPermission: 'Edit CriticalEventType',
    addPermission: 'Add CriticalEventType',
    deletePermission: 'Delete CriticalEventType',
    viewPermission: 'CriticalEventType List',
  });

  const loadList = React.useCallback<
    TLoadList<AdminCriticalEventTypeListingResponse>
  >(
    async (options) => {
      setLoading(true);
      try {
        const requestObj = getRequestObj(
          ['name', 'externalReference', 'integrationEnabled', 'autoClose'],
          options
        );
        const response =
          await criticalEventTypeApi.apiAdminCriticalEventTypeGet(requestObj);
        return getResponse(response, options);
      } catch (e) {
        notify(
          'error',
          e.message ?? 'Failed to load admin critical event type list'
        );
      } finally {
        setLoading(false);
      }
    },
    [getRequestObj, getResponse, notify, setLoading]
  );

  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  const handleInlineEdit = React.useCallback<TOnInlineEdit>(
    async (savedChanges) => {
      setLoading(true);
      try {
        for (const change of savedChanges) {
          if (change.id) {
            await criticalEventTypeApi.apiAdminCriticalEventTypeIdPatch({
              id: parseInt(change.id),
              body: change.newValues,
            });
          }
        }
        await loadList();
        notify('success', 'Updated admin critical event type');
      } catch (e) {
        notify(
          'error',
          e.message ?? 'Failed to update admin critical event type'
        );
      } finally {
        setLoading(false);
      }
    },
    [loadList, notify, setLoading]
  );

  // Forms
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const detailsRef =
    React.useRef<FormikProps<ICriticalEventTypeBulkForm>>(null);

  const handleDetailsFormSubmit = React.useCallback(
    async (
      values: ICriticalEventTypeBulkForm,
      formikHelpers: FormikHelpers<ICriticalEventTypeBulkForm>
    ) => {
      setSubmitting(true);
      const {criticalEventType, vehicle, ...otherValues} = values;
      try {
        if (criticalEventType.length > 0 && vehicle.length > 0) {
          const newValues: CriticalEventBulkActionPost = {
            ...otherValues,
            criticalEventTypeIds: criticalEventType.map(
              ({value}) => value as number
            ),
            vehicleIds: vehicle.map(({value}) => value as number),
          };
          await criticalEventApi.apiCriticalEventBulkActionPost({
            body: newValues,
          });
          notify('success', 'Bulk Close Committed');
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to close');
      } finally {
        formikHelpers.setSubmitting(false);
        formikHelpers.resetForm();
        setSubmitting(false);
      }
    },
    [notify]
  );

  const value: CriticalEventTypeContextProps = {
    // Template Table
    list,
    loadList,
    hasPermission,
    loading,
    cleanupList,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onFiltersChange: handleFiltersChange,
    onInlineEdit: handleInlineEdit,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onRefresh: handleRefresh,
    //  Form
    submitting,
    detailsRef,
    onDetailsFormSubmit: handleDetailsFormSubmit,
  };

  return (
    <CriticalEventTypeContext.Provider value={value}>
      {children}
    </CriticalEventTypeContext.Provider>
  );
};
