import React from 'react';
import _ from 'lodash';
import {
  ITemplateControllerRoute,
  TemplateController,
} from '../../factory/template';
import {
  PermissionContext,
  PermissionContextProvider,
} from './PermissionContext';
import {PermissionList} from './PermissionList';
import {PermissionIdHandler} from './PermissionIdHandler';
import {PermissionForm} from './PermissionForm';
import {Permission} from './Permission';

export const PermissionController: React.FC = () => {
  return (
    <PermissionContextProvider>
      <PermissionControllerComponent />
    </PermissionContextProvider>
  );
};

const PermissionControllerComponent: React.FC = () => {
  const {permission, loading} = React.useContext(PermissionContext);
  const routes: ITemplateControllerRoute[] = React.useMemo(
    () => [
      {title: 'Permission List', component: PermissionList},
      {
        path: '/add',
        title: 'New Permission',
        name: 'Add Permission',
        type: 'Add',
        component: () => <PermissionForm isAdd />,
      },
      {
        path: '/:permissionId',
        title: (params) =>
          loading
            ? 'Permission Loading...'
            : permission?.name ??
              `Permission ${_.get(params, 'permissionId', 'Single')}`,
        component: Permission,
        IdHandler: PermissionIdHandler,
      },
    ],
    [loading, permission?.name]
  );
  return <TemplateController rootPath="/permissionlist" routes={routes} />;
};
