import React from 'react';
import {Listing} from '@onroadvantage/onroadvantage-api';
import {debounce} from '@mui/material';
import {
  Autocomplete,
  AutocompleteOptionType,
  TAutocompleteOnChange,
  TAutocompleteOnInputChange,
} from '../../../autocomplete';
import {listingApi} from '../../../../api';
import {useAppNotifications} from '../../../../contexts';
import {
  usePlanningBoardContext,
  usePlanningBoardGanttContext,
} from '../../planningBoardContext';
import {usePlanningBoardToolbarSearchStyles} from './PlanningBoardToolbarSearch.style';
import {getMasterTripsWithSameVehicle} from '../../helpers';

export const PlanningBoardToolbarSearchEndingSite: React.FC = () => {
  const notify = useAppNotifications();
  const classes = usePlanningBoardToolbarSearchStyles();

  const {planningMasterTrips, masterMasterTrips, bookingMasterTrips, vehicles} =
    usePlanningBoardContext();
  const {
    searchedEndingSiteValues,
    setSearchedTrips,
    setSearchedEndingSiteValues,
  } = usePlanningBoardGanttContext();

  const [listing, setListing] = React.useState<Listing[]>([]);
  const [localLoading, setLocalLoading] = React.useState<boolean>(false);

  const getListing = React.useCallback(
    async (filter?: string) => {
      setLocalLoading(true);
      try {
        const response = await listingApi.apiListingGet({
          model: 'Node',
          query: filter,
        });
        setListing(response.items ?? []);
      } catch (e) {
        notify('error', `Failed to load Node listing`);
      } finally {
        setLocalLoading(false);
      }
    },
    [notify]
  );

  const getListingDebounce = React.useRef(debounce(getListing, 500)).current;

  const handleInputChange = React.useCallback<TAutocompleteOnInputChange>(
    async (event, value) => {
      setLocalLoading(true);
      await getListingDebounce(value);
    },
    [getListingDebounce]
  );

  const handleChange = React.useCallback<TAutocompleteOnChange>(
    async (event, values) => {
      setSearchedTrips([]);
      if (
        values != null &&
        typeof values === 'object' &&
        Array.isArray(values) &&
        values.length > 0 &&
        typeof values[0] === 'object'
      ) {
        setSearchedEndingSiteValues(values as AutocompleteOptionType[]);
        const allTrips = bookingMasterTrips
          .concat(masterMasterTrips)
          .concat(planningMasterTrips);
        vehicles.forEach(({id}) => {
          const tripsForVehicle = getMasterTripsWithSameVehicle(allTrips, id);

          const sortedByEndingTrips = tripsForVehicle.sort((a, b) => {
            const tripALastStop =
              a.trip?.stops && a.trip?.stops.length > 0
                ? a.trip.stops[a.trip.stops.length - 1]
                : null;
            const tripBLastStop =
              b.trip?.stops && b.trip?.stops.length > 0
                ? b.trip.stops[b.trip.stops.length - 1]
                : null;

            if (!tripALastStop?.arrivalTime || !tripBLastStop?.arrivalTime) {
              return 0;
            }

            if (tripALastStop.arrivalTime > tripBLastStop.arrivalTime) {
              return 1;
            }
            return -1;
          });

          const lastTripForVehicle =
            sortedByEndingTrips && sortedByEndingTrips.length > 0
              ? sortedByEndingTrips[sortedByEndingTrips.length - 1]
              : null;

          const lastTripStopForVehicle =
            lastTripForVehicle?.trip?.stops &&
            lastTripForVehicle.trip.stops.length > 0
              ? lastTripForVehicle.trip.stops[
                  lastTripForVehicle.trip.stops.length - 1
                ]
              : null;

          if (lastTripStopForVehicle?.nodeId) {
            values.forEach((value) => {
              if (
                typeof value === 'object' &&
                lastTripStopForVehicle.nodeId === value.value
              ) {
                setSearchedTrips((prevSearchedTrips) =>
                  lastTripForVehicle?.id
                    ? [...prevSearchedTrips, lastTripForVehicle?.id]
                    : prevSearchedTrips
                );
              }
            });
          }
        });
      } else {
        setSearchedEndingSiteValues([]);
        setSearchedTrips([]);
      }
    },
    [
      bookingMasterTrips,
      masterMasterTrips,
      planningMasterTrips,
      setSearchedEndingSiteValues,
      setSearchedTrips,
      vehicles,
    ]
  );

  React.useEffect(() => {
    getListing();
    return () => {
      setListing([]);
    };
  }, [getListing]);

  /** Display the selected (searched) items first */
  const sortedOptions = React.useMemo(
    () =>
      listing.reduce((acc, option) => {
        const accumulatedValues = acc.map(({value}) => value);

        if (option.value && !accumulatedValues.includes(option.value)) {
          return [...acc, option];
        }

        return acc;
      }, searchedEndingSiteValues),
    [listing, searchedEndingSiteValues]
  );

  return (
    <Autocomplete
      name="endingSiteSearch"
      size="small"
      classes={{input: classes.input}}
      loading={localLoading}
      options={sortedOptions}
      onInputChange={handleInputChange}
      onChange={handleChange}
      value={searchedEndingSiteValues}
      label="Search Ending Site"
      placeholder={
        searchedEndingSiteValues.length > 0
          ? `Searching ${searchedEndingSiteValues.length}`
          : undefined
      }
      renderTags={() => null}
      multiple
      fullWidth
    />
  );
};
