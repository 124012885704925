import React from 'react';
import clsx from 'clsx';
import {Divider, Tooltip, Typography} from '@mui/material';
import {usePlanningBoardGanttCompartmentTruckOrderFillStyles} from './PlanningBoardGanttCompartmentTruckOrderFill.style';
import {OrderLineWithCompartment} from './PlanningBoardGanttCompartmentTruck';
import {
  usePlanningBoardContext,
  usePlanningBoardGanttContext,
} from '../../../../planningBoardContext';
import {usePlanningBoardTripCompartmentsContext} from '../planningBoardTripCompartmentsContext';
import {ArrowDownward, ArrowUpward} from '@mui/icons-material';

interface PlanningBoardGanttCompartmentTruckOrderFillProps {
  orderLineCompartment: OrderLineWithCompartment;
  totalCapacityAllocated: number;
  horizontalLevel?: boolean;
}

export const PlanningBoardGanttCompartmentTruckOrderFill: React.FC<
  PlanningBoardGanttCompartmentTruckOrderFillProps
> = ({orderLineCompartment, totalCapacityAllocated, horizontalLevel}) => {
  const {hasPermission} = usePlanningBoardContext();
  const {selectedVehicleCapacityDimensionOption} =
    usePlanningBoardGanttContext();
  const {onDragStart} = usePlanningBoardTripCompartmentsContext();
  const classes = usePlanningBoardGanttCompartmentTruckOrderFillStyles();

  /** Need to check if the current selected capacityDimension matches the current orderLine */
  const uom =
    orderLineCompartment.line.product?.compartmentFillingPreferenceUom ??
    orderLineCompartment.line.product?.uom;
  if (
    selectedVehicleCapacityDimensionOption &&
    selectedVehicleCapacityDimensionOption.label?.toLowerCase() !==
      uom?.toLowerCase()
  ) {
    return null;
  }

  if (horizontalLevel) {
    return (
      <div
        draggable={hasPermission.edit}
        className={clsx(classes.fill, {
          [classes.fillMax]:
            totalCapacityAllocated >
            (orderLineCompartment.compartment.capacity ?? 0),
          //TODO
          [classes.fillDraggable]: true,
        })}
        style={{
          flex:
            orderLineCompartment.lineCompartment.fill &&
            orderLineCompartment.compartment.capacity
              ? orderLineCompartment.lineCompartment.fill /
                orderLineCompartment.compartment.capacity
              : 0,
        }}
        onDragStart={
          hasPermission.edit ? onDragStart(orderLineCompartment) : undefined
        }
      >
        <Typography className={classes.fillText} id="fillText">
          {orderLineCompartment.line.product?.name} |{' '}
          {orderLineCompartment.lineCompartment.fill ?? '-'}/
          {orderLineCompartment.compartment.capacity ?? '-'}
        </Typography>
      </div>
    );
  }

  return (
    <Tooltip
      classes={{tooltip: classes.tooltip, arrow: classes.tooltipArrow}}
      title={
        <div className={classes.tooltipContent}>
          <Typography variant="subtitle1" color="textPrimary">
            {orderLineCompartment.compartment.name}
          </Typography>
          <Divider />
          <Typography variant="subtitle2" color="textPrimary">
            Loading: {orderLineCompartment.compartment.loadingSequence}
          </Typography>
          <Typography variant="subtitle2" color="textPrimary">
            Offloading: {orderLineCompartment.compartment.offloadingSequence}
          </Typography>
          <Divider />
          <Typography variant="subtitle2" color="textPrimary">
            Product: {orderLineCompartment.line.product?.name}
          </Typography>
          <Typography variant="subtitle2" color="textPrimary">
            Fill: {orderLineCompartment.lineCompartment.fill ?? '-'}
            {orderLineCompartment.lineCompartment.uom}
          </Typography>
          <Typography variant="subtitle2" color="textPrimary">
            Capacity: {orderLineCompartment.compartment.capacity ?? '-'}
            {orderLineCompartment.lineCompartment.uom}
          </Typography>
          <Divider />
          <Typography fontWeight={600} variant="subtitle2" color="textPrimary">
            Order Number: {orderLineCompartment.orderNumber}
          </Typography>
        </div>
      }
      arrow
    >
      <div
        draggable
        className={clsx(classes.fill, {
          [classes.fillMax]:
            totalCapacityAllocated >
            (orderLineCompartment.compartment.capacity ?? 0),
          //TODO
          [classes.fillDraggable]: true,
          [classes.orderVariantLoading]:
            orderLineCompartment.line.variant === 'loading',
          [classes.orderVariantOffloading]:
            orderLineCompartment.line.variant === 'offloading',
          [classes.orderVariantInProgress]:
            orderLineCompartment.line.variant === 'in-progress',
        })}
        style={{
          flex:
            orderLineCompartment.lineCompartment.fill &&
            orderLineCompartment.compartment.capacity
              ? orderLineCompartment.lineCompartment.fill /
                orderLineCompartment.compartment.capacity
              : 0,
        }}
        onDragStart={onDragStart(orderLineCompartment)}
      >
        {orderLineCompartment.line.variant === 'loading' ? (
          <ArrowUpward fontSize="small" />
        ) : orderLineCompartment.line.variant === 'offloading' ? (
          <ArrowDownward fontSize="small" />
        ) : null}
      </div>
    </Tooltip>
  );
};
