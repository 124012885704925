import React from 'react';
import {useFormik, FormikHelpers} from 'formik';
import * as Yup from 'yup';
import {Button, TextField, Typography} from '@mui/material';
import {useLoginFormStyles} from './LoginForm.style';
import ConfigService from '../../../service/ConfigService/ConfigService';
import {useHistory} from 'react-router-dom';
import {authenticate} from '../../../service';
import {authStore} from '../../../store';
import {useAppNotifications} from '../../../contexts';
import {useInputStyles} from '../../formik/Input.style';
import {Loader} from '../../loader';

const {oidcRedirectUri} = ConfigService;

const schema = Yup.object({
  email: Yup.string().email('Enter a valid email').required('Required'),
  password: Yup.string().required('Required'),
});

interface LoginFormValues {
  email: string;
  password: string;
}

export const LoginForm: React.FC = ({children}) => {
  const notify = useAppNotifications();
  const classes = useLoginFormStyles();
  const classesTextField = useInputStyles();
  const history = useHistory();

  const handleSubmit = async (
    values: LoginFormValues,
    {setErrors}: FormikHelpers<LoginFormValues>
  ) => {
    const cleanedValues = {
      ...values,
      email: values.email.toLowerCase(),
    };
    const result = await authenticate(cleanedValues);

    if (result && result.auth_token) {
      try {
        authStore.setAuth({
          email: cleanedValues.email,
          token: result.auth_token,
          roles: result.roles,
          // TODO active is no longer being passed back
          active: true,
          authenticated: true,
          oauthAuthenticated: false,
        });
        // check if search has a 'redirectTo' param
        const params = new URLSearchParams(history.location.search);
        if (params.has('redirectTo')) {
          const redirectTo = params.get('redirectTo');
          if (redirectTo) {
            history.push(redirectTo);
          }
        } else {
          history.push('/');
        }
      } catch (e) {
        // TODO handle error
      }
    } else if (result) {
      switch (result.message) {
        case 'Invalid Password':
        case 'User not found':
          notify('error', 'Email or password entered was invalid');
          setErrors({
            email: 'Email or password entered was invalid',
            password: 'Email or password entered was invalid',
          });
          break;
        case 'User Inactive':
          setErrors({
            email: 'Account locked',
            password: 'Account locked',
          });
          notify('error', 'Account locked');
          break;
        default:
          notify('error', 'Unable to login');
          setErrors({email: 'Required', password: 'Required'});
          break;
      }
    } else {
      notify('error', 'Unable to login');
    }
  };

  const handleOidcClick = () => {
    history.push('/login/oidc');
  };

  const handleForgotPasswordClick = () => {
    history.push('/auth/forgot');
  };

  const formik = useFormik<LoginFormValues>({
    initialValues: {email: '', password: ''},
    onSubmit: handleSubmit,
    validateOnChange: true,
    validationSchema: schema,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        className={`${classesTextField.input} ${
          formik.touched.email && !!formik.errors.email
            ? classesTextField.inputError
            : ''
        }`}
        fullWidth
        id="email"
        label="Email"
        name="email"
        placeholder="Enter your user email address"
        disabled={formik.isSubmitting}
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && !!formik.errors.email}
        helperText={formik.touched.email && formik.errors.email}
        onBlur={formik.handleBlur}
      />
      <TextField
        className={`${classesTextField.input} ${
          formik.touched.password && !!formik.errors.password
            ? classesTextField.inputError
            : ''
        }`}
        fullWidth
        id="password"
        label="Password"
        name="password"
        placeholder="Enter your user password"
        type="password"
        onChange={formik.handleChange}
        disabled={formik.isSubmitting}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
        onBlur={formik.handleBlur}
      />
      <Typography
        color="textPrimary"
        onClick={handleForgotPasswordClick}
        style={{
          paddingTop: 8,
          fontSize: 12,
          cursor: 'pointer',
        }}
      >
        Forgot Password?
      </Typography>
      <Button
        className={classes.button}
        color="primary"
        disabled={formik.isSubmitting}
        name="submit"
        type="submit"
        startIcon={<Loader size={25} loading={formik.isSubmitting} />}
        variant="contained"
      >
        {formik.isSubmitting ? 'Submitting...' : 'Submit'}
      </Button>
      {oidcRedirectUri && oidcRedirectUri !== '' && (
        <Button
          className={classes.oidcButton}
          variant="contained"
          name="oidc"
          onClick={handleOidcClick}
        >
          SSO Login
        </Button>
      )}
      {children}
    </form>
  );
};
