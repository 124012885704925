import React from 'react';
import NotificationsActive from '@mui/icons-material/NotificationsActive';
import NotificationsNone from '@mui/icons-material/NotificationsNone';
import IconButton from '@mui/material/IconButton';
import {WindowInfoContext, EventsContext} from '../../../contexts';
import Badge from '@mui/material/Badge';
import {useNavNotificationButtonStyles} from './NavNotificationButton.style';
import clsx from 'clsx';

export interface NavNotificationButtonProps {
  onClick?: () => void;
}

export const NavNotificationButton: React.FC<NavNotificationButtonProps> = ({
  onClick,
}) => {
  const classes = useNavNotificationButtonStyles();
  const {isDesktop} = React.useContext(WindowInfoContext);
  const {eventCountCritical, eventCountOperational} =
    React.useContext(EventsContext);

  return (
    <IconButton
      data-test="eventTabToggle"
      color="inherit"
      aria-label="open right drawer"
      onClick={onClick}
      edge="end"
      size="large"
    >
      {eventCountCritical > 0 || eventCountOperational > 0 ? (
        <>
          <NotificationsActive
            className={clsx(classes.icon, {
              [classes.iconDesktop]: isDesktop,
            })}
          />
          <Badge
            key="criticalEventCount"
            badgeContent={eventCountCritical}
            classes={{badge: classes.topCriticalBadge}}
          >
            <div />
          </Badge>
          <Badge
            key="operationalEventCount"
            badgeContent={eventCountOperational}
            classes={{badge: classes.bottomOperationalBadge}}
          >
            <div />
          </Badge>
        </>
      ) : (
        <NotificationsNone
          className={clsx(classes.icon, {
            [classes.iconDesktop]: isDesktop,
          })}
        />
      )}
    </IconButton>
  );
};
