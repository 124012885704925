import React from 'react';
import {DateTime} from 'luxon';
import {List} from '@mui/material';
import {
  OrderLine,
  WebPlanningBoardOrder,
  WebPlanningBoardOrderLine,
  WebPlanningBoardTripStopOrder,
} from '@onroadvantage/onroadvantage-api';
import {usePlanningBoardTripOrdersStyles} from '../PlanningBoardTripOrders.style';
import {PlanningBoardTripOrderDetailsItem} from './PlanningBoardTripOrderDetailsItem';
import {PolylineService} from '../../../../../../service';
import {Loader} from '../../../../../loader';
import {getPlanningOrderMatrix} from '../../../../../../factory/helpers/getPlanningOrderMatrix';
import {round} from '../../../../../../factory/helpers/round';

interface PlanningBoardTripOrderDetailsProps {
  order: WebPlanningBoardOrder | WebPlanningBoardTripStopOrder;
}

export const PlanningBoardTripOrderDetails: React.FC<
  PlanningBoardTripOrderDetailsProps
> = ({order}) => {
  const classes = usePlanningBoardTripOrdersStyles();
  const [loadingRoute, setLoadingRoute] = React.useState<boolean>(true);
  const [distance, setDistance] = React.useState<string | undefined>();
  const [duration, setDuration] = React.useState<string | undefined>();

  const cleanupRoute = React.useCallback(() => {
    setDistance(undefined);
    setDuration(undefined);
  }, []);

  const getRoute = React.useCallback(async () => {
    if (
      !order.upliftPoint?.longitude ||
      !order.offloadPoint?.longitude ||
      !order.upliftPoint?.latitude ||
      !order.offloadPoint?.latitude
    ) {
      return;
    }

    setLoadingRoute(true);
    try {
      const route = await PolylineService.getOSRMRouteInfo([
        `${order.upliftPoint.longitude},${order.upliftPoint.latitude}`,
        `${order.offloadPoint.longitude},${order.offloadPoint.latitude}`,
      ]);

      if (route?.distance && route.distance > 0) {
        setDistance(`${(route.distance / 1000).toFixed(2)}km`);
      }
      if (route?.duration && route.duration > 0) {
        const totalHours = route.duration / 60 / 60;
        const hours = Math.floor(totalHours).toString();
        const totalMinutes = (totalHours % 1) * 60;
        const minutes = Math.floor(totalMinutes).toString();
        const seconds = Math.floor((totalMinutes % 1) * 60).toString();
        setDuration(
          `${hours.padStart(2, '0')}:${minutes.padStart(
            2,
            '0'
          )}:${seconds.padStart(2, '0')}`
        );
      }
    } finally {
      setLoadingRoute(false);
    }
  }, [order.offloadPoint, order.upliftPoint]);

  const getLoadBy = React.useCallback(() => {
    const loadDateTime = order.loadByDatetime as Date | string | undefined;
    if (typeof loadDateTime === 'string') {
      return DateTime.fromISO(loadDateTime).toFormat('yyyy-MM-dd HH:mm:ss');
    }
    if (loadDateTime) {
      return DateTime.fromJSDate(loadDateTime).toFormat('yyyy-MM-dd HH:mm:ss');
    }
    return undefined;
  }, [order.loadByDatetime]);

  const getDeliverBy = React.useCallback(() => {
    const deliverDateTime = order.deliverByDatetime as
      | Date
      | string
      | undefined;
    if (typeof deliverDateTime === 'string') {
      return DateTime.fromISO(deliverDateTime).toFormat('yyyy-MM-dd HH:mm:ss');
    }
    if (deliverDateTime) {
      return DateTime.fromJSDate(deliverDateTime).toFormat(
        'yyyy-MM-dd HH:mm:ss'
      );
    }
    return undefined;
  }, [order.deliverByDatetime]);

  const getContract = React.useCallback(() => {
    if (
      !('contract' in order) ||
      (!order.contract?.name && !order.contract?.code)
    ) {
      return;
    }

    return `${order.contract?.code ?? ''} - ${order.contract?.name ?? ''}`;
  }, [order]);

  const getOrderLineQuantity = React.useCallback(
    (line: WebPlanningBoardOrderLine | OrderLine) => {
      const planningOrderMatrix = getPlanningOrderMatrix(order.planningOrder);
      const uom = (
        line.product?.compartmentFillingPreferenceUom ?? line.product?.uom
      )?.toLowerCase();
      if (uom == null || line.product == null) {
        return null;
      }

      if (
        planningOrderMatrix == null ||
        planningOrderMatrix.planningOrderUoms.length === 0 ||
        !planningOrderMatrix.planningOrderUoms.includes(uom)
      ) {
        return `${line.product?.name} ${round(line.quantity) ?? 0}${
          line.product?.uom
        }`;
      }

      return `${line.product?.name} ${
        planningOrderMatrix.planningOrderCapacities[uom]
      }${line.product?.compartmentFillingPreferenceUom ?? line.product?.uom}`;
    },
    [order.planningOrder]
  );

  React.useEffect(() => {
    getRoute();
    return cleanupRoute;
  }, [cleanupRoute, getRoute]);

  return (
    <List className={classes.list} disablePadding>
      <PlanningBoardTripOrderDetailsItem
        primary="Order Number"
        secondary={order.orderNumber}
      />
      <PlanningBoardTripOrderDetailsItem
        primary="Customer Reference Number"
        secondary={order.customerReferenceNumber}
      />
      <PlanningBoardTripOrderDetailsItem
        primary="Order Tracking Number"
        secondary={order.orderTrackingNumber}
      />
      <PlanningBoardTripOrderDetailsItem
        primary="Planning Order Skill"
        secondary={order.planningOrder?.skill}
      />
      <PlanningBoardTripOrderDetailsItem
        primary="Products"
        secondary={
          order.lines && order.lines.length > 0
            ? order.lines.map(getOrderLineQuantity).join(', ')
            : undefined
        }
      />
      <PlanningBoardTripOrderDetailsItem
        primary="Distance"
        secondary={
          loadingRoute ? (
            <Loader
              text="Calculating Route..."
              size={20}
              classes={{
                loaderContainer: classes.routeLoaderContainer,
                loaderTextContainer: classes.routeLoaderText,
              }}
              disableFullWidth
            />
          ) : (
            distance
          )
        }
      />
      <PlanningBoardTripOrderDetailsItem
        primary="Duration"
        secondary={
          loadingRoute ? (
            <Loader
              text="Calculating Route..."
              size={20}
              classes={{
                loaderContainer: classes.routeLoaderContainer,
                loaderTextContainer: classes.routeLoaderText,
              }}
              disableFullWidth
            />
          ) : (
            duration
          )
        }
      />
      <PlanningBoardTripOrderDetailsItem
        primary="Loading Point"
        secondary={order.upliftPoint?.name}
      />
      <PlanningBoardTripOrderDetailsItem
        primary="Offloading Point"
        secondary={order.offloadPoint?.name}
      />
      <PlanningBoardTripOrderDetailsItem
        primary="Contract"
        secondary={getContract()}
      />
      <PlanningBoardTripOrderDetailsItem
        primary="Deliver By"
        secondary={getDeliverBy()}
      />
      <PlanningBoardTripOrderDetailsItem
        primary="Load By"
        secondary={getLoadBy()}
      />
    </List>
  );
};
