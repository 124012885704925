import React from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {getQueryStringValue} from '../../../service/Util';
import {AdminTelematicsConfigContext} from './AdminTelematicsConfigContext';
import {TabContext, TabPanel} from '@mui/lab';
import {Tabs, Tab, Divider} from '@mui/material';
import {AdminTelematicsConfigDetails} from './telematicsConfigDetails';
import {useAdminTelematicsConfigDetailsStyles} from './telematicsConfigDetails/AdminTelematicsConfigDetails.style';
import {AdminTelematicsConfigSchedule} from './telematicsConfigSchedule';

type TabValue = 'details' | 'groups' | 'configs' | 'schedule' | 'logs';

export const AdminTelematicsConfig: React.FC = () => {
  const classes = useAdminTelematicsConfigDetailsStyles();
  const {search} = useLocation();
  const history = useHistory();
  const {telematicsConfigId, telematicsConfig} = React.useContext(
    AdminTelematicsConfigContext
  );

  const [tabValue, setTabValue] = React.useState<TabValue>('details');

  const handleTabChange = React.useCallback(
    (event: any, value: number) => {
      history.push(`/telematicsconfiglist/${telematicsConfigId}?tab=${value}`);
    },
    [history, telematicsConfigId]
  );

  React.useEffect(() => {
    const tab = getQueryStringValue(search, 'tab');
    switch (tab) {
      case 'details':
      case 'groups':
      case 'configs':
      case 'schedule':
      case 'logs':
        setTabValue(tab);
        break;
      default:
        setTabValue('details');
        break;
    }
  }, [search]);

  return (
    <TabContext value={`${tabValue}`}>
      <Tabs
        value={`${tabValue}`}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Details" value="details" />
        <Tab label="Groups" value="groups" disabled />
        <Tab label="Configs" value="configs" disabled />
        <Tab
          label="Schedule/Run"
          value="schedule"
          disabled={
            !['MiX Integrate', 'Drivecam'].includes(
              telematicsConfig?.providerName ?? ''
            )
          }
        />
        <Tab label="Logs" value="logs" disabled />
      </Tabs>
      <Divider />
      <>
        <TabPanel className={classes.panel} value="details">
          <AdminTelematicsConfigDetails />
        </TabPanel>
        <TabPanel className={classes.panel} value="groups">
          <div>Groups is not available yet</div>
        </TabPanel>
        <TabPanel className={classes.panel} value="configs">
          <div>Configs is not available yet</div>
        </TabPanel>
        <TabPanel className={classes.panel} value="schedule">
          <AdminTelematicsConfigSchedule />
        </TabPanel>
        <TabPanel className={classes.panel} value="logs">
          <div>Logs is not available yet</div>
        </TabPanel>
      </>
    </TabContext>
  );
};
