import React from 'react';
import {useHistory} from 'react-router-dom';
import {DriverGroup, DriverGroupPost} from '@onroadvantage/onroadvantage-api';
import {FormikHelpers, FormikProps} from 'formik/dist/types';
import {driverGroupApi} from '../../../api';
import {IDriverGroupForm} from '../DriverGroupForm';
import {useAppNotifications} from '../../../contexts';

export const useDriverGroup = () => {
  const history = useHistory();
  const notify = useAppNotifications();
  const [driverGroup, setDriverGroup] = React.useState<
    DriverGroup | undefined
  >();
  const [driverGroupId, setDriverGroupId] = React.useState<
    number | undefined
  >();
  const [loadingDriverGroup, setLoadingDriverGroup] =
    React.useState<boolean>(false);
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const detailsRef = React.useRef<FormikProps<IDriverGroupForm>>(null);

  const loadDriverGroup = React.useCallback(async () => {
    setLoadingDriverGroup(true);
    try {
      if (driverGroupId) {
        const response = await driverGroupApi.apiDriverGroupDriverGroupIdGet({
          driverGroupId,
        });
        setDriverGroup(response);
      } else {
        setDriverGroup(undefined);
      }
    } catch (e) {
      notify('error', 'Failed to load driver group list');
    } finally {
      setLoadingDriverGroup(false);
    }
  }, [notify, driverGroupId]);

  const handleDetailsFormSubmit = React.useCallback(
    async (
      values: IDriverGroupForm,
      formikHelpers: FormikHelpers<IDriverGroupForm>
    ) => {
      setSubmitting(true);
      try {
        const {planningSkills, ...otherValues} = values;
        const planningSkillIds = planningSkills
          ?.filter(({value}) => !!value)
          ?.map(({value}) => value as number);

        const newValues: DriverGroupPost = {...otherValues, planningSkillIds};

        if (driverGroupId) {
          await driverGroupApi.apiDriverGroupDriverGroupIdPatch({
            driverGroupId,
            body: newValues,
          });
        } else {
          await driverGroupApi.apiDriverGroupPost({
            body: newValues,
          });
        }
        history.push('/drivergrouplist');
        notify(
          'success',
          `${driverGroupId ? 'Updated' : 'Added'} driver group`
        );
      } catch (e) {
        notify(
          'error',

          `Failed to ${driverGroupId ? 'update' : 'add'} driver group list`
        );
      } finally {
        formikHelpers.setSubmitting(false);
        setSubmitting(false);
      }
    },
    [history, notify, setSubmitting, driverGroupId]
  );
  return {
    onDetailsFormSubmit: handleDetailsFormSubmit,
    loadDriverGroup,
    loadingDriverGroup,
    setDriverGroup,
    setDriverGroupId,
    submitting,
    driverGroup,
    driverGroupId,
    detailsRef,
  };
};

export type useDriverGroupResponse = ReturnType<typeof useDriverGroup>;
export const useDriverGroupResponseInitial: useDriverGroupResponse = {
  loadDriverGroup: async () => {},
  onDetailsFormSubmit: async () => {},
  setDriverGroup: () => {},
  setDriverGroupId: () => {},
  submitting: false,
  loadingDriverGroup: false,
  driverGroup: undefined,
  driverGroupId: undefined,
  detailsRef: {current: null},
};
