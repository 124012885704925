import React from 'react';
import {AdminIntegrationListIntegration as AdminIntegrationListIntegrationType} from '@onroadvantage/onroadvantage-api';
import {useAdminTelematicsConfigScheduleItemStyles} from './AdminTelematicsConfigScheduleItem.style';
import {Typography, Divider} from '@mui/material';
import {integrationScheduleApi} from '../../../../api';
import {AdminTelematicsConfigContext} from '../AdminTelematicsConfigContext';
import {useAppNotificationStore} from '../../../../stores/context/notifications';
import {VantageDialogButton} from '../../../dialog';

interface AdminTelematicsConfigScheduleItemProps {
  item: AdminIntegrationListIntegrationType;
}

export const AdminTelematicsConfigScheduleItem: React.FC<
  AdminTelematicsConfigScheduleItemProps
> = ({item}) => {
  const classes = useAdminTelematicsConfigScheduleItemStyles();
  const appNotificationStore = useAppNotificationStore();
  const {telematicsConfigId} = React.useContext(AdminTelematicsConfigContext);
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleSchedule = React.useCallback(async () => {
    setLoading(true);
    try {
      const response =
        await integrationScheduleApi.apiAdminIntegrationSchedulePost({
          body: {
            telematicsConfigId,
            integration: item.name,
          },
        });
      if (response.message === 'Success') {
        appNotificationStore.enqueueNotification(
          'success',
          'Scheduled Successfully'
        );
      }
    } finally {
      setLoading(false);
    }
  }, [appNotificationStore, item, setLoading, telematicsConfigId]);

  return (
    <div className={classes.root}>
      <div className={classes.details}>
        <Typography className={classes.title}>{item.name}</Typography>
        <Divider />
        <Typography className={classes.description}>
          {item.description}
        </Typography>
      </div>
      <div className={classes.action}>
        <VantageDialogButton
          dialogTitle="Would you like to run/schedule?"
          disabled={loading}
          onAccept={handleSchedule}
          title="schedule"
          styles={{content: classes.details}}
        >
          <Typography className={classes.title}>{item.name}</Typography>
          <Divider />
          <Typography className={classes.description}>
            {item.description}
          </Typography>
        </VantageDialogButton>
      </div>
    </div>
  );
};
