import React from 'react';
import {
  CardHeader,
  Card,
  CardContent,
  //Button,
  Avatar,
  Stack,
} from '@mui/material';
import {EventMap} from './eventMap';
import {EventCard} from './eventCard';
import {EventContext} from './EventContext';
import {
  CriticalEventDump,
  OperationalEventDump,
} from '@onroadvantage/onroadvantage-api';
import {Place} from '@mui/icons-material';

export const Event: React.FC = () => {
  const {event, type} = React.useContext(EventContext);

  return (
    <Stack width={'100%'} flex={1}>
      <Stack
        direction={{xs: 'column-reverse', md: 'column'}}
        flex={1}
        spacing={2}
      >
        <EventCard
          event={event as CriticalEventDump | OperationalEventDump}
          eventType={type}
        />
        <Card>
          <CardHeader
            title="Map"
            avatar={
              <Avatar aria-label="Map">
                <Place />
              </Avatar>
            }
            sx={{paddingBottom: 0}}
          />
          <CardContent>
            <EventMap />
          </CardContent>
        </Card>
      </Stack>
    </Stack>
  );
};
