import {
  DriverDump,
  WebPlanningBoardNodeMinimal,
  WebPlanningBoardOrder,
} from '@onroadvantage/onroadvantage-api';
import {
  PlanningBoardContextProps,
  PlanningBoardGanttContextProps,
  WebPlanningBoardMasterTripWithIdType,
} from '../../../planningBoardContext';
import {IPlanningBoardTripPanelForm} from './index';

interface InitialValuesParams {
  selectedGanttItem: PlanningBoardGanttContextProps['selectedGanttItem'];
  vehicles: PlanningBoardContextProps['vehicles'];
  getMasterTripsWithSameVehicle: PlanningBoardContextProps['getMasterTripsWithSameVehicle'];
}

export const getPlanningBoardTripCreateInitialValues = ({
  selectedGanttItem,
  vehicles,
  getMasterTripsWithSameVehicle,
}: InitialValuesParams): IPlanningBoardTripPanelForm | undefined => {
  if (!selectedGanttItem || selectedGanttItem.variant !== 'order') {
    return undefined;
  }

  const order = selectedGanttItem.data as WebPlanningBoardOrder | undefined;
  const group = selectedGanttItem.group;

  if (!order || !group) {
    return undefined;
  }

  const vehicle = vehicles.find(({id}) => id === group);

  let previousTripLastNode: WebPlanningBoardNodeMinimal | undefined;
  let previousDriver: DriverDump | undefined;

  if (vehicle?.id) {
    const masterTripsWithSameVehicle = getMasterTripsWithSameVehicle(
      vehicle?.id
    );

    let masterTripBeforeCurrent:
      | WebPlanningBoardMasterTripWithIdType
      | undefined;

    const masterTripsBefore = masterTripsWithSameVehicle?.filter(
      ({trip}) =>
        trip?.tripEnd &&
        selectedGanttItem.start &&
        new Date(selectedGanttItem?.start) > new Date(trip?.tripEnd)
    );

    masterTripsBefore?.forEach((masterTrip) => {
      if (!masterTripBeforeCurrent) masterTripBeforeCurrent = masterTrip;
      else if (
        masterTrip.trip?.tripEnd &&
        masterTripBeforeCurrent.trip?.tripEnd &&
        new Date(masterTrip.trip?.tripEnd) >
          new Date(masterTripBeforeCurrent.trip?.tripEnd)
      )
        masterTripBeforeCurrent = masterTrip;
    });

    const masterTripsBeforeStopsLength =
      masterTripBeforeCurrent?.trip?.stops?.length;

    previousDriver = masterTripBeforeCurrent?.trip?.driver;

    previousTripLastNode =
      masterTripBeforeCurrent?.trip?.stops &&
      masterTripsBeforeStopsLength &&
      masterTripsBeforeStopsLength > 0
        ? masterTripBeforeCurrent.trip.stops[masterTripsBeforeStopsLength - 1]
            .node
        : undefined;
  }

  return {
    loadingArrivalTime: new Date(
      order?.loadByDatetime ?? selectedGanttItem.start
    ),
    offloadingArrivalTime: new Date(
      order.deliverByDatetime ?? selectedGanttItem.start
    ),
    selectedTimeOption: 'loadingArrivalTime',
    tripStartTime: new Date(selectedGanttItem.start),
    endingPointNode: {
      label: order.offloadPoint?.name ?? undefined,
      value: order.offloadPoint?.id ?? undefined,
    },
    endingNode: order.offloadPoint,
    startingPointNode: previousTripLastNode?.id
      ? {
          label: previousTripLastNode?.name ?? undefined,
          value: previousTripLastNode?.id ?? undefined,
        }
      : {
          label: order.upliftPoint?.name ?? undefined,
          value: order.upliftPoint?.id ?? undefined,
        },
    startingNode: previousTripLastNode,
    driver: {
      label: previousDriver?.firstname ?? undefined,
      value: previousDriver?.id ?? undefined,
    },
    vehicle: {
      label: `${vehicle?.registrationNumber} - ${vehicle?.fleetNumber}`,
      value: vehicle?.id,
    },
  };
};
