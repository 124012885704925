import React from 'react';
import {Collapse, Tab, Tabs} from '@mui/material';
import {useTripStopContext} from './tripStopContext';
import {useTripContext} from '../tripContext';
import {TripStopTaskList} from './tripStopTaskList';
import {TripStopOrderList} from './tripStopOrderList';
import {TripStopCommentList} from './TripStopCommentList';
import {TripStopDocumentList} from './tripStopDocumentList';
import {useTripStopStyles} from './TripStop.style';

export const TripStop: React.FC = () => {
  const classes = useTripStopStyles();
  const {masterTripId} = useTripContext();
  const {cleanupTripStop, loadTripStop, tripStopId} = useTripStopContext();
  const [tabValue, setTabValue] = React.useState<number>(0);

  const handleTabChange = React.useCallback((event: any, value: number) => {
    setTabValue(value);
  }, []);

  React.useEffect(() => {
    loadTripStop();
    return cleanupTripStop;
    /** Only load tripStop on initial load, or if the tripStopId or masterTripId changes */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripStopId, masterTripId, cleanupTripStop]);

  return (
    <>
      <Tabs
        className={classes.tabBar}
        value={tabValue}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Tasks" />
        <Tab label="Orders" />
        <Tab label="Comments" />
        <Tab label="Documents" />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <TripStopTaskList />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <TripStopOrderList />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <TripStopCommentList />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <TripStopDocumentList />
      </TabPanel>
    </>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Collapse in={value === index}>{children}</Collapse>
    </div>
  );
}
