import React from 'react';
import {IRecipientCardDetail} from '../MasterTripRecipientContext';
import {RecipientTripVehicle} from '@onroadvantage/onroadvantage-api';
import {VerifiedUser} from '@mui/icons-material';
import {RecipientCard} from '../recipientCard';

export const RecipientVehicleDetails: React.FC<RecipientTripVehicle> = (
  vehicle
) => {
  const details: IRecipientCardDetail[] = React.useMemo(
    () => [
      {label: 'Registration Number', value: vehicle.registrationNumber},
      {label: 'Fleet Number', value: vehicle.fleetNumber},
    ],
    [vehicle]
  );

  return (
    <RecipientCard
      title="Vehicle Details"
      avatar={<VerifiedUser />}
      details={details}
      data-cy="RecipientVehicleDetails"
    />
  );
};
