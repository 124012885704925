import React from 'react';
import {useParams, useLocation} from 'react-router-dom';
import {useTripContext} from './tripContext';

interface IParams {
  masterTripId?: string;
}

export const TripIdHandler: React.FC = () => {
  const {setCriticalEventId, setOperationalEventId, setMasterTripId} =
    useTripContext();
  const {masterTripId} = useParams<IParams>();
  const {search} = useLocation();

  React.useEffect(() => {
    const parsedTripId = masterTripId ? parseInt(masterTripId) : undefined;

    const params = new URLSearchParams(search);
    const criticalEventId = params.get('criticalEventId');
    const operationalEventId = params.get('operationalEventId');

    const parsedCriticalEventId = criticalEventId
      ? parseInt(criticalEventId)
      : undefined;

    const parsedOperationalEventId = operationalEventId
      ? parseInt(operationalEventId)
      : undefined;

    setMasterTripId(parsedTripId);
    setCriticalEventId(parsedCriticalEventId);
    setOperationalEventId(parsedOperationalEventId);

    return () => {
      setMasterTripId(undefined);
      setCriticalEventId(undefined);
      setOperationalEventId(undefined);
    };
  }, [
    masterTripId,
    setMasterTripId,
    setCriticalEventId,
    setOperationalEventId,
    search,
  ]);

  return null;
};
