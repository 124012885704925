import React from 'react';
import {SortEndHandler} from 'react-sortable-hoc';
import {changePosition} from '../../../../factory/template';
import {RoleService} from '../../../../service';

export type TripViewLayoutLabels =
  | 'Comments'
  | 'Debrief'
  | 'Events'
  | 'Gantt'
  | 'Map'
  | 'Stops';

export interface TripViewLayout {
  label: TripViewLayoutLabels;
  permission: {name: string; type: string} | null;
  hidden: boolean | null;
  pinned: boolean | null;
}

export const tripViewLayoutInitial: TripViewLayout[] = [
  {
    label: 'Debrief',
    permission: {name: 'Trip Debrief', type: 'Edit'},
    hidden: false,
    pinned: false,
  },
  {label: 'Stops', permission: null, hidden: false, pinned: false},
  {label: 'Gantt', permission: null, hidden: false, pinned: false},
  {label: 'Map', permission: null, hidden: false, pinned: false},
  {label: 'Events', permission: null, hidden: false, pinned: false},
  {label: 'Comments', permission: null, hidden: false, pinned: false},
];

interface tripViewLocalStorageSettings {
  tripViewLayout: TripViewLayout[] | undefined;
}

export const useTripSettings = () => {
  // states
  const [didLoadSettings, setDidLoadSettings] = React.useState<boolean>(false);
  const [openSettings, setOpenSettings] = React.useState<boolean>(false);
  const [tripViewLayout, setTripViewLayout] = React.useState<TripViewLayout[]>(
    tripViewLayoutInitial
  );

  // helper handlers
  const handleOpenSettings = React.useCallback(() => setOpenSettings(true), []);
  const handleCloseSettings = React.useCallback(
    () => setOpenSettings(false),
    []
  );

  // load handlers
  const handleLoadSettings = React.useCallback(() => {
    const localStorageSettings = localStorage.getItem('tripViewSettings');

    if (localStorageSettings) {
      const parsedSettings = JSON.parse(
        localStorageSettings
      ) as tripViewLocalStorageSettings;

      let parsedSettingsHasAllKeys = true;

      /**
       * Required Keys are used to check if the local storages keys matches the required keys.
       * Thus, if new columns are added they would need to be added to this requiredKeys list
       */
      const requiredKeys = ['permission', 'label', 'hidden', 'pinned'];

      if (!parsedSettings.tripViewLayout) {
        parsedSettingsHasAllKeys = false;
      }

      requiredKeys.forEach((key) => {
        if (
          parsedSettings.tripViewLayout &&
          parsedSettings.tripViewLayout.length > 0 &&
          !(key in parsedSettings.tripViewLayout[0])
        ) {
          parsedSettingsHasAllKeys = false;
        }
      });

      setTripViewLayout(
        parsedSettingsHasAllKeys &&
          parsedSettings.tripViewLayout &&
          parsedSettings.tripViewLayout.length > 0
          ? parsedSettings.tripViewLayout.filter(
              ({permission}) =>
                !permission ||
                RoleService.hasPermission(permission.name, permission.type)
            )
          : tripViewLayoutInitial.filter(
              ({permission}) =>
                permission === null ||
                RoleService.hasPermission(permission.name, permission.type)
            )
      );
    } else {
      const defaultSettings = {
        tripViewLayout: tripViewLayoutInitial.filter(
          ({permission}) =>
            permission === null ||
            RoleService.hasPermission(permission.name, permission.type)
        ),
      };
      localStorage.setItem('tripViewSettings', JSON.stringify(defaultSettings));
    }

    setDidLoadSettings(true);
  }, []);

  // update handlers
  const updateLocalStorages = React.useCallback(
    (updatedLocalStorageSettings: Partial<tripViewLocalStorageSettings>) => {
      const localStorageSettings = localStorage.getItem('tripViewSettings');

      if (localStorageSettings) {
        const parsedLocalStorageSettings = JSON.parse(
          localStorageSettings
        ) as tripViewLocalStorageSettings;

        const newLocalStorageSettings = {
          ...parsedLocalStorageSettings,
          ...updatedLocalStorageSettings,
        };
        localStorage.setItem(
          'tripViewSettings',
          JSON.stringify(newLocalStorageSettings)
        );
      }
    },
    []
  );

  const handleUpdateTripViewLayoutHidden = React.useCallback(
    (viewLabel: TripViewLayoutLabels, newHidden: boolean) => {
      setTripViewLayout((prevTripViewLayoutOrder) => {
        const newTripViewLayout: TripViewLayout[] = prevTripViewLayoutOrder.map(
          (view) => {
            if (view.label === viewLabel) {
              return {...view, hidden: newHidden};
            }
            return view;
          }
        );

        /** Update the settings of the trip view layout in localStorage */
        updateLocalStorages({tripViewLayout: newTripViewLayout});

        return newTripViewLayout;
      });
    },
    [updateLocalStorages]
  );

  const handleUpdateTripViewLayoutPosition = React.useCallback<SortEndHandler>(
    ({oldIndex, newIndex}) => {
      setTripViewLayout((prevTripViewLayoutOrder) => {
        const newTripViewLayoutOrder =
          changePosition(prevTripViewLayoutOrder, oldIndex, newIndex) ??
          prevTripViewLayoutOrder;

        /** Update the settings of the trip view layout in localStorage */
        updateLocalStorages({tripViewLayout: newTripViewLayoutOrder});

        return newTripViewLayoutOrder;
      });
    },
    [updateLocalStorages]
  );

  const resetTripSettings = React.useCallback(() => {
    const permissionFilterTripViewLayout = tripViewLayoutInitial.filter(
      ({permission}) =>
        !permission ||
        RoleService.hasPermission(permission.name, permission.type)
    );

    setTripViewLayout(permissionFilterTripViewLayout);
    updateLocalStorages({tripViewLayout: permissionFilterTripViewLayout});
  }, [updateLocalStorages]);

  return {
    // states
    didLoadSettings,
    openSettings,
    tripViewLayout,
    // state setters
    setDidLoadSettings,
    // helper handlers
    onOpenSettings: handleOpenSettings,
    onCloseSettings: handleCloseSettings,
    // load handlers
    loadSettings: handleLoadSettings,
    // update handlers
    updateTripViewLayoutHidden: handleUpdateTripViewLayoutHidden,
    updateTripViewLayoutPosition: handleUpdateTripViewLayoutPosition,
    resetTripSettings,
  };
};

export type useTripSettingsResponse = ReturnType<typeof useTripSettings>;

export const useTripSettingsResponseInitial: useTripSettingsResponse = {
  // states
  didLoadSettings: false,
  openSettings: false,
  tripViewLayout: tripViewLayoutInitial,
  // state setters
  setDidLoadSettings: () => null,
  // helper handlers
  onOpenSettings: () => null,
  onCloseSettings: () => null,
  // load handlers
  loadSettings: () => null,
  // update handlers
  updateTripViewLayoutHidden: () => null,
  updateTripViewLayoutPosition: () => null,
  resetTripSettings: () => null,
};
