import moment from 'moment';
import {logout} from '../actions';
import {appNotificationStore} from '../stores/mobxStores';
import {flatten} from './Util';

const ResponseService = {
  checkStatus: async (response, parse = true) => {
    if (response.ok) {
      // appNotificationStore.enqueueNotification('success', 'Api Call Succeeded');
      if (!parse) {
        return {
          success: true,
        };
      }
      return {
        data: await response.json(),
      };
    }
    let errorBody;
    let errorString;
    try {
      errorBody = await response.json();
      if (errorBody.message) {
        errorString = errorBody.message;
      } else {
        errorString = JSON.stringify(errorBody);
      }
    } catch (e) {
      errorString = JSON.stringify(e);
      errorBody = {error: errorString};
    }

    if (response.status === 400) {
      appNotificationStore.enqueueNotification(
        'error',
        `Bad Request 400 Occurred: ${errorString} `
      );
      return {error: '400 Occurred'};
    }
    if (response.status === 401) {
      appNotificationStore.enqueueNotification(
        'error',
        `Unauthenticated 401 Occurred: ${errorString}`
      );
      logout();
      return {error: '401 Occurred'};
    }
    if (response.status === 403) {
      appNotificationStore.enqueueNotification(
        'error',
        `Unauthorized 403 Occurred: ${errorString}`
      );
      return {error: '403 Occurred'};
    }
    if (response.status === 404) {
      appNotificationStore.enqueueNotification(
        'error',
        `NotFound 404 Occurred: ${errorString} `
      );
      return {error: '404 Occurred'};
    }
    if (response.status === 405) {
      appNotificationStore.enqueueNotification(
        'error',
        `MethodNotAllowed 405 Occurred: ${errorString} `
      );
      return {error: '405 Occurred'};
    }
    if (response.status === 422) {
      appNotificationStore.enqueueNotification(
        'error',
        `UnprocessableEntityError 422 Occurred: ${errorString}`
      );
      return {error: '422 Occurred'};
    }
    if (response.status === 429) {
      const retryAfter = response.headers.get('Retry-After');
      const resetDate = moment.unix(response.headers.get('X-RateLimit-Reset'));
      appNotificationStore.enqueueNotification(
        'error',
        `TooManyRequests 429 Occurred: Need to wait ${retryAfter} seconds`
      );
      return {error: '429 Occurred', retry: {retryAfter, resetDate}};
    }
    if (response.status === 500) {
      appNotificationStore.enqueueNotification(
        'error',
        `InternalServerError 500 Occurred: ${errorString} `
      );
      return {error: '500 Occurred'};
    }
    appNotificationStore.enqueueNotification(
      'error',
      `OtherError ${response.status} Occurred: ${errorString}`
    );
    return {error: 'Other Error Occurred', data: errorBody};
  },
  checkUpload: (response) => {
    if (response.ok) {
      return response;
    }
    if (response.status === 401) {
      // eslint-disable-next-line no-console
      console.log('logging you out due to 401');
      logout();
      // eslint-disable-next-line consistent-return
      return;
    }
    if (response.status === 500) {
      return {message: 'Internal Service Error'};
    }
    throw Error(response.json());
  },
  fetch: async (url, params) => {
    try {
      const response = await fetch(url, params);
      return ResponseService.checkStatus(response);
    } catch (e) {
      appNotificationStore.enqueueNotification(
        'error',
        `An error occurred during the request: `
      );
    }
    return {data: []};
  },
  fetchAndNormalize: async (url, params) => {
    try {
      const response = await fetch(url, params);
      return ResponseService.normalize(
        await ResponseService.checkStatus(response)
      );
    } catch (e) {
      appNotificationStore.enqueueNotification(
        'error',
        `An error occurred during the request: `
      );
    }
    return {data: []};
  },
  normalize: async (response) => {
    return flatten(await response.data);
  },
};

export default ResponseService;
