import React from 'react';
import {TransitionProps} from '@mui/material/transitions';
import {Collapse, Fade, Slide} from '@mui/material';
import {VantageDialogButtonProps} from './VantageDialogButton';

export const getTransitionComponent = (
  type: VantageDialogButtonProps['transition']
) =>
  type === 'slide'
    ? SlideTransition
    : type === 'fade'
    ? FadeTransition
    : type === 'collapse'
    ? CollapseTransition
    : undefined;

const SlideTransition = React.forwardRef(function Transition(
  props: TransitionProps & {children: React.ReactElement},
  ref: React.Ref<unknown>
) {
  return (
    <Slide direction="up" ref={ref} {...props}>
      {props.children}
    </Slide>
  );
});

const FadeTransition = React.forwardRef(function Transition(
  props: TransitionProps & {children: React.ReactElement},
  ref: React.Ref<unknown>
) {
  return (
    <Fade ref={ref} {...props}>
      {props.children}
    </Fade>
  );
});

const CollapseTransition = React.forwardRef(function Transition(
  props: TransitionProps & {children?: React.ReactElement},
  ref: React.Ref<unknown>
) {
  return <Collapse ref={ref} {...props} />;
});
