import {Badge, BadgeProps} from '@mui/material';
import {styled} from '@mui/material/styles';

export const VantageBadge = styled(Badge)<BadgeProps>(() => ({
  '& .MuiBadge-badge': {
    right: -10,
    lineHeight: 0,
    padding: 0.1,
  },
}));
