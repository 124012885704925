import React from 'react';
import {DriverRankIncentiveContext} from './DriverRankIncentiveContext';
import {useParams} from 'react-router-dom';

interface IParams {
  driverRankIncentiveId?: string;
}

export const DriverRankIncentiveIdHandler: React.FC = () => {
  const {setDriverRankIncentiveId} = React.useContext(
    DriverRankIncentiveContext
  );

  const {driverRankIncentiveId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = driverRankIncentiveId
      ? parseInt(driverRankIncentiveId)
      : undefined;
    setDriverRankIncentiveId(parsedId);
    return () => setDriverRankIncentiveId(undefined);
  }, [driverRankIncentiveId, setDriverRankIncentiveId]);

  return null;
};
