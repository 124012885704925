import React from 'react';
import {NotificationConfigContext} from '../NotificationConfigContext';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../../factory/template';
import {EscalationNotificationConfig} from '@onroadvantage/onroadvantage-api';
import {NotificationConfigOperationalEscalationContext} from './NotificationConfigOperationalEscalationContext';

export const NotificationConfigOperationalEscalationTriggersList: React.FC =
  () => {
    const {notificationConfig} = React.useContext(NotificationConfigContext);

    const config: TemplateTableConfig<EscalationNotificationConfig> =
      React.useMemo(
        () => ({
          columns: [
            {
              name: 'operationalEventEscalationConfig',
              label: 'Event Type Name',
              type: 'string',
              model: ['operationalEventEscalationConfig'],
              enableEditing: false,
              enableAutocomplete: true,
              getValue: ({operationalEventEscalationConfig}) =>
                operationalEventEscalationConfig
                  ?.contractOperationalEventTypeConfig?.operationalEventType
                  ?.name,
            },
            {
              name: 'contractCode',
              label: 'Contract Code',
              type: 'string',
              enableEditing: false,
              getValue: ({operationalEventEscalationConfig}) =>
                operationalEventEscalationConfig
                  ?.contractOperationalEventTypeConfig?.contract?.code,
            },
            {
              name: 'type',
              label: 'Type',
              type: 'string',
              model: ['operationalEventEscalationConfig'],
              enableEditing: false,
              getValue: ({operationalEventEscalationConfig}) =>
                operationalEventEscalationConfig?.type,
            },
            {
              name: 'notifyContractContactGroups',
              label: 'Contract Contact Groups',
              type: 'bool',
              enableEditing: true,
            },
            {
              name: 'notifyCustomerContactGroups',
              label: 'Customer Contact Groups',
              type: 'bool',
              enableEditing: true,
            },
            {
              name: 'notifyNodeContactGroups',
              label: 'Node Contact Groups',
              type: 'bool',
              enableEditing: true,
            },
            {
              name: 'notifyVehicleGroupContactGroups',
              label: 'Vehicle Group Contact Groups',
              type: 'bool',
              enableEditing: true,
            },
            {
              name: 'notifyDriver',
              label: 'Driver',
              type: 'bool',
              enableEditing: true,
            },
            {
              name: 'notifyVehicle',
              label: 'Vehicle',
              type: 'bool',
              enableEditing: true,
            },
            {
              name: 'notifyWeb',
              label: 'Web',
              type: 'bool',
              enableEditing: true,
            },
            {
              name: 'notifyNextStop',
              label: 'Next Stop',
              type: 'bool',
              enableEditing: true,
            },
            {
              name: 'notifyOffloadingStops',
              label: 'Offload Stops',
              type: 'bool',
              enableEditing: true,
            },
          ],
          disablePagination: true,
          disableToolbar: true,
          deleteMessage: (row) => ({
            title: 'Notification Config Operational Event',
            items: {
              NotificationConfigName: notificationConfig?.name,
              Type: row?.operationalEventEscalationConfig?.type,
              Threshold: row?.operationalEventEscalationConfig?.threshold,
            },
          }),
          identifier:
            'NOTIFICATION_CONFIG_OPERATIONAL_ESCALATION_TRIGGERS_LIST',
        }),
        [notificationConfig?.name]
      );

    return (
      <TemplateTableWrapper
        config={config}
        context={NotificationConfigOperationalEscalationContext}
      />
    );
  };
