import React from 'react';
import {SitePreContext} from './SitePreContext';
import {useParams} from 'react-router-dom';

interface IParams {
  associateNodeId?: string;
}

export const SitePreIdHandler: React.FC = () => {
  const {setAssociateNodeId} = React.useContext(SitePreContext);

  const {associateNodeId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = associateNodeId ? parseInt(associateNodeId) : undefined;
    setAssociateNodeId(parsedId);
    return () => setAssociateNodeId(undefined);
  }, [associateNodeId, setAssociateNodeId]);

  return null;
};
