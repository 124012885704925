import React from 'react';
import {RowDetailState, SelectionState} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
} from '@devexpress/dx-react-grid-material-ui';
import withStyles from '@mui/styles/withStyles';
import StopsIcon from '@mui/icons-material/List';
import {withRouter} from 'react-router-dom';
import styles from '../../styles/Card';
import {flatten, unflatten} from '../../../service/Util';
import {Loader} from '../../loader';
import {ActiveCell} from '../../devExpressTable/activeCell';
import {DurationCell} from '../../devExpressTable/durationCell';
import {MomentService} from '../../../service';
import VehicleStopLocations from './VehicleStopLocations';
import {mapDisplayStore} from '../../../stores/mobxStores';
import {VehicleCard} from '../vehicleCard';

const Cell = withRouter((props) => {
  if (['scheduled'].includes(props.column.name)) {
    return <ActiveCell value={props.value} onValueChange={() => {}} />;
  }

  if (['known'].includes(props.column.name)) {
    let knownLocations = ['Unknown'];
    if (props.row.knownNodes && props.row.knownNodes.length) {
      knownLocations = props.row.knownNodes.map((node) => node.name);
    }
    return (
      <Table.Cell
        row={props.row}
        column={props.column}
        tableColumn={props.tableColumn}
        tableRow={props.tableRow}
        value={knownLocations.join('\n')}
      />
    );
  }

  if (['tat', 'duration'].includes(props.column.name)) {
    return <DurationCell startTime={props.row.start} endTime={props.row.end} />;
  }

  if (['start', 'end'].includes(props.column.name)) {
    return (
      <Table.Cell
        row={props.row}
        column={props.column}
        tableColumn={props.tableColumn}
        tableRow={props.tableRow}
        value={MomentService.displayTimestamp(props.value)}
      />
    );
  }

  return (
    <Table.Cell
      row={props.row}
      column={props.column}
      tableColumn={props.tableColumn}
      tableRow={props.tableRow}
      value={props.value}
    />
  );
});

class VehicleStops extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actualTrip: props.actualTrip,
      loading: false,
      stops: flatten({stops: props.actualTrip.stops}).stops,
      expanded: false,
      selection: [],
    };
  }

  handleExpandClick = () => {
    this.setState((prevState) => ({expanded: !prevState.expanded}));
  };

  // eslint-disable-next-line camelcase
  async UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      stops: flatten({stops: nextProps.actualTrip.stops}).stops,
      actualTrip: nextProps.actualTrip,
      loading: false,
    });
  }

  setMapDisplayOpenStop = async (row) => {
    const data = await unflatten(row);
    mapDisplayStore.setOpenActualStop({...data, ...data.node});
  };

  changeSelection = (newSelection) => {
    const {selection, stops} = this.state;
    const difference = newSelection.filter((x) => !selection.includes(x));
    if (difference.length > 0) {
      this.setMapDisplayOpenStop(stops[difference[0]]);
      this.setState({selection: difference});
    }
  };

  render() {
    const {stops = [], loading, selection} = this.state;

    const RowDetail = ({row}) => (
      <div style={{display: 'flex', flex: 1, justifyContent: 'space-between'}}>
        <VehicleStopLocations locations={row.knownNodes} />
      </div>
    );

    return (
      <VehicleCard
        title="Actual Stops"
        label="VehicleStops"
        avatar={<StopsIcon />}
      >
        <form>
          <Grid
            rows={stops}
            columns={[
              {name: 'id', title: 'ID'},
              {name: 'known', title: 'Known Location'},
              {name: 'start', title: 'Arrival Time'},
              {name: 'end', title: 'Departure Time'},
              {name: 'tat', title: 'TAT'},
            ]}
          >
            <RowDetailState />
            <SelectionState
              selection={selection}
              onSelectionChange={(props) => this.changeSelection(props)}
            />
            <Table cellComponent={Cell} />
            <TableHeaderRow />
            <TableRowDetail contentComponent={RowDetail} />
            <TableSelection
              selectByRowClick
              highlightRow
              showSelectionColumn={false}
            />
          </Grid>
          {loading ? <Loader /> : null}
        </form>
      </VehicleCard>
    );
  }
}

export default withStyles(styles)(VehicleStops);
