import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';
import {
  NESTED_LEVEL_1_DARK,
  NESTED_LEVEL_1_LIGHT,
  NESTED_LEVEL_2_DARK,
  NESTED_LEVEL_2_LIGHT,
  NESTED_LEVEL_3_DARK,
  NESTED_LEVEL_3_LIGHT,
} from '../../TemplateTable.style';

const TableRowDetailCellStyles = (theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
      borderBottomWidth: 0,
      backgroundColor:
        theme.palette.mode === 'dark'
          ? NESTED_LEVEL_1_DARK
          : NESTED_LEVEL_1_LIGHT,
    },
    rootLevel1: {
      padding: 0,
      borderBottomWidth: 0,
      backgroundColor:
        theme.palette.mode === 'dark'
          ? NESTED_LEVEL_2_DARK
          : NESTED_LEVEL_2_LIGHT,
    },
    rootLevel2: {
      padding: 0,
      borderBottomWidth: 0,
      backgroundColor:
        theme.palette.mode === 'dark'
          ? NESTED_LEVEL_3_DARK
          : NESTED_LEVEL_3_LIGHT,
    },
  });

export const useTableRowDetailCellStyles = makeStyles(
  TableRowDetailCellStyles,
  {
    name: 'TableRowDetailCellStyles',
  }
);
