import React from 'react';
import {
  useDriverGroup,
  useDriverGroupResponse,
  useDriverGroupResponseInitial,
} from './useDriverGroup';

export type DriverGroupContextProps = useDriverGroupResponse;

export const DriverGroupContext = React.createContext<DriverGroupContextProps>(
  useDriverGroupResponseInitial
);

export const DriverGroupContextProvider: React.FC = ({children}) => {
  const value = useDriverGroup();

  const {loadDriverGroup, setDriverGroup} = value;

  React.useEffect(() => {
    loadDriverGroup();
    return () => {
      setDriverGroup(undefined);
    };
  }, [loadDriverGroup, setDriverGroup]);

  return (
    <DriverGroupContext.Provider value={value}>
      {children}
    </DriverGroupContext.Provider>
  );
};

export const useDriverGroupContext = () => React.useContext(DriverGroupContext);
