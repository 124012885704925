import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

const PlanningBoardOptionsStyles = (theme: Theme) =>
  createStyles({
    clearButton: {minWidth: 100, marginLeft: 10},
    contractButtonWrapper: {marginLeft: theme.spacing(2)},
    contractList: {},
    dialogTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    divider: {margin: '10px 0'},
    listingContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      marginBottom: theme.spacing(2),
    },
    loadButton: {minWidth: 100},
    root: {flex: 1, paddingTop: theme.spacing(1.5)},
  });

export const usePlanningBoardOptionsStyles = makeStyles(
  PlanningBoardOptionsStyles,
  {
    name: 'PlanningBoardOptionsStyles',
  }
);
