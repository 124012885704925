import {makeStyles, createStyles} from '@mui/styles';
import {Theme} from '@mui/material';

const CompartmentVerticalCompartmentStyles = (theme: Theme) =>
  createStyles({
    compartment: {
      cursor: 'pointer',
      overflow: 'hidden',
      position: 'relative',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      backgroundColor: '#bebebe',
      marginBottom: theme.spacing(2),
      borderRadius: theme.spacing(1),
      alignItems: 'stretch',
      alignContent: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
    compartmentActive: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      cursor: 'default',
    },
    compartmentDefaultPointer: {cursor: 'default'},
    compartmentName: {
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'center',
      alignItems: 'center',
      textAlign: 'center',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    compartmentContent: {
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'center',
      alignItems: 'center',
      testAlign: 'center',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    divider: {
      height: 1,
      backgroundColor: '#555',
      alignSelf: 'stretch',
      display: 'flex',
    },
    dividerActive: {backgroundColor: '#eee'},
  });

export const useCompartmentVerticalCompartmentStyles = makeStyles(
  CompartmentVerticalCompartmentStyles,
  {name: 'CompartmentVerticalCompartmentStyles'}
);
