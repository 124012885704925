import {makeStyles, createStyles} from '@mui/styles';

export const TemplateCardStyles = () =>
  createStyles({
    cardDisableBottomPadding: {padding: 0},
    /** Needs !important flag to override the CardContent's :lastChild bottom padding */
    cardContentDisablePadding: {padding: 0, paddingBottom: '0 !important'},
  });

export const useTemplateCardStyles = makeStyles(TemplateCardStyles, {
  name: 'TemplateCardStyles',
});
