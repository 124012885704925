import React from 'react';
import {EventsBulkClosingFilterStep} from './EventsBulkClosingFilterStep';
import {EventsBulkClosingConfirmStep} from './EventsBulkClosingConfirmStep';
import {EventsBulkClosingCommentStep} from './EventsBulkClosingCommentStep';

export const eventsBulkClosingSteps = [
  'filterEvents',
  'confirmation',
  'comment',
] as const;

export type EventsBulkClosingSteps = (typeof eventsBulkClosingSteps)[number];

export const eventsBulkClosingStepsElements: {
  [key in EventsBulkClosingSteps]: React.ReactNode;
} = {
  filterEvents: <EventsBulkClosingFilterStep />,
  confirmation: <EventsBulkClosingConfirmStep />,
  comment: <EventsBulkClosingCommentStep />,
};

export const eventsBulkClosingStepsLabels: {
  [key in EventsBulkClosingSteps]: string;
} = {
  filterEvents: 'SELECT FILTERS',
  confirmation: 'CONFIRMATION',
  comment: 'COMMENT',
};

const getAdjacentStep = (
  step: EventsBulkClosingSteps,
  side: 'next' | 'prev'
) => {
  const indexOfStep = eventsBulkClosingSteps.indexOf(step);
  if (side === 'next' && eventsBulkClosingSteps.length > indexOfStep + 1) {
    return eventsBulkClosingSteps[indexOfStep + 1] as EventsBulkClosingSteps;
  }
  if (side === 'prev' && indexOfStep > 0) {
    return eventsBulkClosingSteps[indexOfStep - 1] as EventsBulkClosingSteps;
  }
  return null;
};

export const useEventsBulkClosing = () => {
  const [activeStep, setActiveStep] =
    React.useState<EventsBulkClosingSteps>('filterEvents');
  const [completedSteps, setCompletedSteps] = React.useState<
    EventsBulkClosingSteps[]
  >([]);

  const handleNextStep = React.useCallback(
    (currentStep: EventsBulkClosingSteps) => {
      const nextStep = getAdjacentStep(currentStep, 'next');
      if (nextStep != null) {
        setActiveStep(nextStep);
        if (
          eventsBulkClosingSteps.indexOf(nextStep) ===
          eventsBulkClosingSteps.length - 1
        ) {
          setCompletedSteps((currentCompletedSteps) => [
            ...currentCompletedSteps,
            currentStep,
            nextStep,
          ]);
        } else {
          setCompletedSteps((currentCompletedSteps) => [
            ...currentCompletedSteps,
            currentStep,
          ]);
        }
      }
    },
    []
  );

  const handlePreviousStep = React.useCallback(() => {
    setActiveStep(
      (currentStep) => getAdjacentStep(currentStep, 'prev') ?? currentStep
    );
  }, []);

  const handleGoToStep = React.useCallback(
    (targetStep: EventsBulkClosingSteps) => {
      if (completedSteps.includes(targetStep)) {
        setActiveStep(targetStep);
      }
    },
    [completedSteps]
  );

  return {
    activeStep,
    completedSteps,
    onPreviousStep: handlePreviousStep,
    onGoToStep: handleGoToStep,
    onNextStep: handleNextStep,
  };
};
