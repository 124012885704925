import React from 'react';
import {ProductContext, ProductContextProps} from './ProductContext';
import {useParams} from 'react-router-dom';

interface IParams {
  productId?: string;
}

export const ProductIdHandler: React.FC = () => {
  const productContext = React.useContext<ProductContextProps>(ProductContext);

  const productSetId = productContext.setProductId;

  const {productId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = productId ? parseInt(productId) : undefined;
    productSetId(parsedId);

    return () => {
      productSetId(undefined);
    };
  }, [productId, productSetId]);

  return null;
};
