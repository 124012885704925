import React from 'react';
import _ from 'lodash';
import {Trip as TripType} from '@onroadvantage/onroadvantage-api';
import {TripList, TripListContextProvider} from './tripList';
import {TripContextProvider, useTripContext} from './tripContext';
import {
  ITemplateControllerRoute,
  TemplateController,
} from '../../factory/template';
import {TripIdHandler} from './TripIdHandler';
import {Trip} from './Trip';
import {
  TripEdit,
  TripEditContextProvider,
  TripEditOrderAddListContextProvider,
  useTripEditContext,
} from './tripEdit';
import {TripSettingsContextProvider} from './tripSettings';
import {TripSetup} from './tripSetup';
import {OverrideErrorsDialog} from './tripEdit/tripEditContext/OverrideErrorsDialog';

export const TripController: React.FC = () => {
  return (
    <TripListContextProvider>
      <TripContextProvider>
        <TripSettingsContextProvider>
          <TripEditContextProvider>
            <TripEditOrderAddListContextProvider>
              <OverrideErrorsDialog />
              <TripControllerComponent />
            </TripEditOrderAddListContextProvider>
          </TripEditContextProvider>
        </TripSettingsContextProvider>
      </TripContextProvider>
    </TripListContextProvider>
  );
};

export const TripControllerComponent: React.FC = () => {
  const {masterTrip, loadingMasterTrip, updatingTrip} = useTripContext();
  const {loadingStops} = useTripEditContext();

  /**
   * Destructure trip to get the tripNumber. Used trip level instead of masterTrip level because the tripNumber on
   * masterTrip level could be undefined, but trip level's tripNumber should have a value. Add ({} as TripType), since
   * the masterTrip?.trip can be undefined.
   */
  const {tripNumber} = masterTrip?.trip || ({} as TripType);

  const routes: ITemplateControllerRoute[] = React.useMemo(
    () => [
      {title: 'Trip List', component: TripList},
      {
        path: '/add',
        title: 'New Trip',
        component: TripSetup,
        name: 'Add MasterTrip',
        type: 'Add',
      },
      {
        path: '/:masterTripId',
        title: (params) =>
          updatingTrip
            ? 'Trip Updating...'
            : loadingMasterTrip
            ? 'Trip Loading...'
            : tripNumber ?? `trip ${_.get(params, 'masterTripId', 'Single')}`,
        component: Trip,
        IdHandler: TripIdHandler,
      },
      {
        path: '/:masterTripId/stops/edit',
        title: loadingStops ? 'Stops Loading...' : 'Edit Trip',
        name: 'Edit MasterTrip',
        type: 'Edit',
        component: TripEdit,
      },
    ],
    [loadingMasterTrip, loadingStops, tripNumber, updatingTrip]
  );

  return (
    <TemplateController elevation={0} rootPath="/triplist" routes={routes} />
  );
};
