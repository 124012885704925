import React from 'react';
import {NodeTypeContext} from './NodeTypeContext';
import {useParams} from 'react-router-dom';

interface IParams {
  nodeTypeId?: string;
}

export const NodeTypeIdHandler: React.FC = () => {
  const {setNodeTypeId} = React.useContext(NodeTypeContext);

  const {nodeTypeId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = nodeTypeId ? parseInt(nodeTypeId) : undefined;
    setNodeTypeId(parsedId);
    return () => setNodeTypeId(undefined);
  }, [nodeTypeId, setNodeTypeId]);

  return null;
};
