import React from 'react';
import {
  Autocomplete,
  AutocompleteOptionType,
  AutocompleteProps,
  TAutocompleteOnChange,
} from '../../autocomplete';
import {useFormikContext} from 'formik';

export const FormikAutocompleteHooks: React.FC<AutocompleteProps> = ({
  id,
  label,
  name,
  options = [],
  placeholder,
  onChange,
  multiple,
  ...otherProps
}) => {
  const {
    errors,
    isSubmitting,
    isValid,
    submitCount,
    getFieldHelpers,
    getFieldMeta,
  } = useFormikContext();
  const {
    value: formikValue,
    initialValue: formikInitialValue,
    error,
    touched,
  } = getFieldMeta(name);
  const {setValue: setFormikValue, setTouched} = getFieldHelpers(name);
  const [value, setValue] = React.useState(formikValue);

  const memoizedError = React.useMemo(() => {
    /**
     * Memoize form error. Get formErrors and fieldError, and set their potential types
     * Only go into error checks if the form is inValid and the submitCount is greater than 0 or the current form is touched
     * First try and get the error message from the formErrors with the use of formErrors[name]. Reason for this is, there were cases where the fieldError would be undefined when there was actually an error
     * Then check if the type of the error message is 'string', which is unlikely for AutocompleteFields. We typically expect {label: 'Required', value: 'Required'}. This check is just a failsafe
     * Then get the errorMessage from formError.label ?? formError.value. ?? 'Required' is also just a failSafe, but this should also never be reached
     * Then if formError wasn't found, use fieldError with the same logic as above. Situation where this will occur is on ReportScheduleForm with 'parameters' error messages
     */
    const formErrors = errors as {[key: string]: any};
    const fieldError = error as string | {[key: string]: any};
    if (!isValid && (submitCount > 0 || touched)) {
      if (formErrors && formErrors[name]) {
        const formError = formErrors[name];
        if (typeof formError === 'string') {
          return formError;
        }
        return formError.label ?? formError.value ?? 'Required';
      }

      if (fieldError) {
        if (typeof fieldError === 'string') {
          return fieldError;
        }
        return fieldError.label ?? fieldError.value ?? 'Required';
      }
    }
    return undefined;
  }, [errors, error, touched, isValid, submitCount, name]);

  const defaultValue = React.useMemo(
    () =>
      formikInitialValue && formikInitialValue !== 'null'
        ? formikInitialValue
        : null,
    [formikInitialValue]
  );

  const handleFocus = React.useCallback<
    React.FocusEventHandler<HTMLDivElement>
  >(
    (_event) => {
      setTouched(true, true);
    },
    [setTouched]
  );

  const handleChange = React.useCallback<TAutocompleteOnChange>(
    (event, value, reason, details) => {
      if (onChange) {
        onChange(event, value, reason, details);
      }
      setFormikValue(value);
      setValue(value);
    },
    [onChange, setFormikValue]
  );

  React.useEffect(() => {
    setValue(formikValue);
  }, [formikValue]);

  return (
    <Autocomplete
      disabled={isSubmitting}
      {...otherProps}
      onFocus={handleFocus}
      error={memoizedError}
      label={label ?? name}
      id={id ?? name}
      name={name}
      multiple={multiple}
      onChange={handleChange}
      options={options}
      placeholder={placeholder ?? 'Enter and select a value'}
      defaultValue={
        defaultValue as AutocompleteOptionType | AutocompleteOptionType[]
      }
      value={value as AutocompleteOptionType | AutocompleteOptionType[]}
    />
  );
};
