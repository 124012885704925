import React from 'react';
import {connect, FormikProps} from 'formik';
import clone from 'rfdc';
import {Listing} from '@onroadvantage/onroadvantage-api';
import {IBoardData, ILaneFilter, VantageBoard} from '../../board';
import {usePlanningSolutionData} from '../../../stores/context';
import {
  getFilteredCardData,
  getLaneFilters,
  listingToLaneData,
  TGetCardMoveHandlerResponse,
} from '../../../service/laneUtils';
import {PlanningSolutionFormValues} from './PlanningSolutionForm';

interface IFormikProps {
  formik: FormikProps<PlanningSolutionFormValues>;
}

export const PlanningSolutionVehiclesComponent: React.FC<IFormikProps> = ({
  formik,
}) => {
  const [boardData, setBoardData] = React.useState<IBoardData>({lanes: []});
  const [allocatedFilter, setAllocatedFilter] = React.useState<string>('');
  const [availableFilter, setAvailableFilter] = React.useState<string>('');
  const [laneFilters, setLaneFilters] = React.useState<Array<ILaneFilter>>([]);

  const {rawVehicleListings} = usePlanningSolutionData((store) => ({
    rawVehicleListings: store.rawVehicleListings,
  }));

  /** Moves a vehicle from one lane to another */
  const handleCardMove: TGetCardMoveHandlerResponse = (
    fromLaneId,
    toLaneId,
    cardId,
    index
  ) => {
    // let allocatedIndex: number;
    const allocated = clone()(formik.values.allocatedVehicles || []);

    if (fromLaneId !== toLaneId) {
      const vehicleId = parseInt(cardId, 10);
      switch (toLaneId) {
        case 'available':
          {
            const allocatedIndex = allocated.findIndex((c) => c === vehicleId);
            allocated.splice(allocatedIndex, 1);
          }
          break;
        case 'allocated':
          allocated.splice(index, 0, vehicleId);
          break;
        default:
          throw new Error('should not get here ever');
      }

      formik.setFieldValue('allocatedVehicles', allocated);
    }
  };

  /** Allocates all vehicles to solution */
  const handleAddAll = () => {
    const vehicles = rawVehicleListings.map(({value}) => value);
    formik.setFieldValue('allocatedVehicles', vehicles);
  };

  /** Removes all allocatedVehicles from a solution */
  const handleRemoveAll = () => {
    // TODO check trips if allocatedVehicles are assigned or not when saving
    formik.setFieldValue('allocatedVehicles', []);
  };

  /** Sets the filter values */
  // eslint-disable-next-line no-undef
  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filterValue: string = e.target.value;

    let allocated = laneFilters[1].value || '';
    let available = laneFilters[0].value || '';

    switch (e.target.name) {
      case 'allocated':
        allocated = filterValue;
        break;
      case 'available':
        available = filterValue;
        break;
      default:
        throw new Error('filter not found');
    }

    const filters = [
      {
        id: 'available',
        title: `Available ${available.length}`,
        value: available,
      },
      {
        id: 'allocated',
        title: `Allocated ${allocated.length}`,
        value: allocated,
      },
    ];

    setAllocatedFilter(allocated);
    setAvailableFilter(available);
    setLaneFilters(filters);
  };

  /** Calculates the allocated and unallocated vehicles */
  React.useEffect(() => {
    const al: Array<Listing> = [];
    const av: Array<Listing> = [];

    rawVehicleListings.forEach((o) => {
      if (!o.value) {
        // ignore
      } else if (
        (formik.values.allocatedVehicles || []).indexOf(o.value) > -1
      ) {
        al.push(o);
      } else {
        av.push(o);
      }
    });

    const {filteredAllocatedData, filteredAvailableData} = getFilteredCardData(
      listingToLaneData(al),
      listingToLaneData(av),
      allocatedFilter,
      availableFilter
    );

    const allocatedBoardData = {
      lanes: [
        {
          id: 'available',
          title: `Available (${filteredAvailableData.length})`,
          cards: filteredAvailableData,
        },
        {
          id: 'allocated',
          title: `Allocated (${filteredAllocatedData.length})`,
          cards: filteredAllocatedData,
        },
      ],
    };

    setBoardData(allocatedBoardData);
  }, [
    formik.values.allocatedVehicles,
    allocatedFilter,
    availableFilter,
    rawVehicleListings,
  ]);

  React.useEffect(() => {
    setLaneFilters(getLaneFilters(allocatedFilter, availableFilter));
  }, [allocatedFilter, availableFilter]);

  return (
    <VantageBoard
      addAll={handleAddAll}
      removeAll={handleRemoveAll}
      data={boardData}
      onCardMoveAcrossLanes={handleCardMove}
      onFilterChange={handleFilterChange}
      filters={laneFilters}
      isHeaderVisible
      square
      title="Planning Solution Vehicles"
    />
  );
};

export const PlanningSolutionVehicles = connect<PlanningSolutionFormValues>(
  PlanningSolutionVehiclesComponent
);
