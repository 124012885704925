import React from 'react';
import {inject, observer} from 'mobx-react';
import {ClearAll} from '@mui/icons-material';
import {TripCard} from '../tripCard';
import {useTripContext} from '../tripContext';
import {ITimelinePlayback, TripTimelineActions} from '../tripTimelineActions';
import {timelineDisplayStore} from '../../../stores/mobxStores';
import {TripGanttTimeline} from './TripGanttTimeline';

export const TripGantt = inject('timelineDisplayStore')(
  observer(() => {
    const {
      loadingAdjacentTrips,
      loadingTelematicsEvents,
      loadingMasterTrip,
      loadTelematicsEvents,
    } = useTripContext();

    const loadingGantt =
      loadingAdjacentTrips || loadingMasterTrip || loadingTelematicsEvents;

    const handleReload = React.useCallback(async () => {
      await loadTelematicsEvents({reload: true});
    }, [loadTelematicsEvents]);

    //TODO Replace Mobx timelineDisplayStore with Context. As of yet, too many legacy components are affected by the
    // timelineDisplayStore, so they would first have to be updated
    /** Get Mobx timelineDisplayStore necessary values for the playback */
    const {
      isPlaying,
      togglePlaying,
      nextFrameTimelineSliderTime,
      prevFrameTimelineSliderTime,
    } = timelineDisplayStore;

    const nextFrame = React.useCallback(() => {
      nextFrameTimelineSliderTime();
    }, [nextFrameTimelineSliderTime]);

    const clickNextFrame = React.useCallback(() => {
      nextFrame();
    }, [nextFrame]);

    const prevFrame = React.useCallback(() => {
      prevFrameTimelineSliderTime();
    }, [prevFrameTimelineSliderTime]);

    const playback: ITimelinePlayback = React.useMemo(
      () => ({
        togglePlaying: togglePlaying as () => void,
        nextFrame: clickNextFrame,
        prevFrame: prevFrame,
        isPlaying,
      }),
      [clickNextFrame, isPlaying, prevFrame, togglePlaying]
    );

    return (
      <TripCard
        title="Gantt"
        avatar={<ClearAll />}
        action={<TripTimelineActions playback={playback} />}
        loading={loadingGantt}
        loadingText="Loading Gantt..."
        onReload={handleReload}
        expandedByDefault
      >
        <TripGanttTimeline playback={playback} />
      </TripCard>
    );
  })
);
