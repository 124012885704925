import React from 'react';
import {
  Chip,
  Collapse,
  List,
  ListItem,
  ListItemText,
  Stack,
} from '@mui/material';
import {FormatListNumbered, LibraryBooks} from '@mui/icons-material';
import {
  TripSetupFormStop,
  TripSetupFormStopOrder,
  useTripSetupContext,
} from '../TripSetupContext';
import {TemplateCard} from '../../../../factory/template';

export const TripSetupSummaryStepStops = () => {
  const {stops} = useTripSetupContext();
  const [viewingOrder, setViewingOrder] =
    React.useState<TripSetupFormStopOrder | null>(null);

  const [viewingStop, setViewingStop] =
    React.useState<TripSetupFormStop | null>(null);

  const orders = React.useMemo(
    () =>
      stops.reduce((acc: TripSetupFormStopOrder[], stop) => {
        if (stop.orders && stop.orders.length > 0) {
          return [...acc, ...stop.orders];
        }
        return acc;
      }, []),
    [stops]
  );

  const totalStops = React.useMemo(
    () =>
      stops.reduce((acc: TripSetupFormStop[], stop) => {
        if (stop.orders && stop.orders.length > 0) {
          const order = stop.orders[0];
          return [
            ...acc,
            {
              node: order.upliftPointNode ?? {},
              sequence: acc.length + 1,
              orders: [order],
            },
            {
              node: order.offloadPointNode ?? {},
              sequence: acc.length + 2,
              orders: [order],
            },
          ];
        }
        return [...acc, {...stop, sequence: acc.length + 1}];
      }, []),
    [stops]
  );

  const getStopType = React.useCallback(() => {
    if (viewingStop?.orders && viewingStop?.orders.length > 0) {
      if (
        viewingStop.orders[0].upliftPointNode?.value === viewingStop.node.value
      ) {
        return 'Loading';
      }
      return 'Offloading';
    }
    return 'Depot';
  }, [viewingStop]);

  return (
    <>
      <TemplateCard avatar={<LibraryBooks />} title="Orders">
        <Stack flexWrap="wrap" direction="row" gap={1}>
          {orders.map((order) => (
            <Chip
              key={order.orderNumber}
              label={order.orderNumber}
              color={
                order.orderNumber === viewingOrder?.orderNumber
                  ? 'primary'
                  : 'default'
              }
              onClick={() =>
                setViewingOrder((currentViewingOrder) =>
                  currentViewingOrder?.orderNumber === order.orderNumber
                    ? null
                    : order
                )
              }
              clickable
            />
          ))}
        </Stack>
        <Collapse in={viewingOrder != null}>
          <List>
            <ListItem>
              <ListItemText
                primary="Loading Point"
                secondary={viewingOrder?.upliftPointNode?.label ?? '-'}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Offloading Point"
                secondary={viewingOrder?.offloadPointNode?.label ?? '-'}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Product"
                secondary={viewingOrder?.product?.label ?? '-'}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Quantity"
                secondary={viewingOrder?.quantity ?? '-'}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Delivery Note Number"
                secondary={viewingOrder?.deliveryNoteNumber ?? '-'}
              />
            </ListItem>
          </List>
        </Collapse>
      </TemplateCard>
      <TemplateCard avatar={<FormatListNumbered />} title="Stops">
        <Stack flexWrap="wrap" direction="row" gap={1}>
          {totalStops.map((stop) => (
            <Chip
              key={stop.sequence}
              label={stop.node.label}
              color={
                stop.sequence === viewingStop?.sequence ? 'primary' : 'default'
              }
              onClick={() =>
                setViewingStop((currentViewingStop) =>
                  currentViewingStop?.sequence === stop.sequence ? null : stop
                )
              }
              clickable
            />
          ))}
        </Stack>
        <Collapse in={viewingStop != null}>
          <Stack spacing={1}>
            <ListItem>
              <ListItemText
                primary="Sequence"
                secondary={viewingStop?.sequence ?? '-'}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Site"
                secondary={viewingStop?.node?.label ?? '-'}
              />
            </ListItem>
            <ListItem>
              <ListItemText primary="Site Type" secondary={getStopType()} />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Order"
                secondary={
                  viewingStop?.orders && viewingStop?.orders.length > 0
                    ? viewingStop.orders[0].orderNumber
                    : '-'
                }
              />
            </ListItem>
          </Stack>
        </Collapse>
      </TemplateCard>
    </>
  );
};
