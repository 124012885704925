import React from 'react';
import {
  usePlanningBoard,
  usePlanningBoardResponse,
  usePlanningBoardResponseInitial,
} from './usePlanningBoard';
import DateRangeContext from '../../../contexts/DateRangeContext';
import {DateTime} from 'luxon';

export type PlanningBoardContextProps = usePlanningBoardResponse;

export const PlanningBoardContext =
  React.createContext<PlanningBoardContextProps>(
    usePlanningBoardResponseInitial
  );

export const PlanningBoardContextProvider: React.FC = ({children}) => {
  const {dateRange} = React.useContext(DateRangeContext);
  const value = usePlanningBoard();

  const {
    setEndDate,
    setStartDate,
    contractIds,
    setInitialised,
    setHasLoadedOrders,
  } = value;

  React.useEffect(() => {
    setStartDate(DateTime.fromISO(dateRange.startDate));
    setEndDate(DateTime.fromISO(dateRange.endDate));
  }, [dateRange, setEndDate, setStartDate]);

  React.useEffect(() => {
    contractIds.length === 0 && setInitialised(false);
    return () => undefined;
  }, [contractIds.length, setInitialised]);

  React.useEffect(
    () => () => {
      if (dateRange) {
        setInitialised(false);
        setHasLoadedOrders(false);
      }
    },
    [dateRange, setInitialised, setHasLoadedOrders]
  );

  return (
    <PlanningBoardContext.Provider value={value}>
      {children}
    </PlanningBoardContext.Provider>
  );
};

export const usePlanningBoardContext = () =>
  React.useContext(PlanningBoardContext);
