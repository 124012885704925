import React from 'react';
import {useLeftDrawerStyles} from './LeftDrawer.style';
import {Drawer} from '@mui/material';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import {WindowInfoContext} from '../../../contexts';
import {useVantageFrame} from '../VantageFrameContext';

export interface LeftDrawerProps {
  open?: boolean;
  toggleDrawer?: () => void;
}

export const LeftDrawer: React.FC<LeftDrawerProps> = ({
  children,
  open,
  toggleDrawer,
}) => {
  const classes = useLeftDrawerStyles();
  const {isDesktop} = React.useContext(WindowInfoContext);
  const [isOver, setIsOver] = React.useState<boolean>(false);
  const {showLeftDrawerToggleButton} = useVantageFrame();

  const handleEnter = React.useCallback(() => setIsOver(true), []);
  const handleLeave = React.useCallback(() => setIsOver(false), []);

  if (!isDesktop) {
    if (!open) {
      return null;
    }
    return (
      <div className={classes.rootMobile}>
        <div className={classes.paperMobile}>{children}</div>
      </div>
    );
  }

  return (
    <>
      <Drawer
        className={classes.root}
        open={open}
        classes={{
          paper: classes.paper,
        }}
        onMouseEnter={handleEnter}
        onMouseLeave={handleLeave}
        variant="persistent"
      >
        {children}
      </Drawer>
      {showLeftDrawerToggleButton && (
        <Button
          onClick={toggleDrawer}
          onMouseEnter={handleEnter}
          onMouseLeave={handleLeave}
          className={clsx(classes.button, {
            [classes.buttonClosed]: !open,
            [classes.buttonShow]: isOver || !open,
          })}
        >
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </Button>
      )}
    </>
  );
};
