import React from 'react';
import {useFormikContext} from 'formik';
import {
  Checkbox,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
} from '@mui/material';

interface FormikCheckboxProps {
  disabled?: boolean;
  label: string;
  name: string;
  labelPlacement?: FormControlLabelProps['labelPlacement'];
}

export const FormikCheckbox: React.FC<FormikCheckboxProps> = ({
  disabled,
  label,
  labelPlacement,
  name,
  ...props
}) => {
  const {isSubmitting, getFieldMeta, handleChange} = useFormikContext();
  const {
    error,
    touched,
    initialError,
    initialTouched,
    initialValue,
    value,
    ...meta
  } = getFieldMeta<boolean>(name);

  const displayError = React.useMemo(
    () => (touched ? error : undefined),
    [error, touched]
  );

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            {...props}
            {...meta}
            id={name}
            name={name}
            onChange={handleChange}
            checked={value}
          />
        }
        disabled={disabled || isSubmitting}
        label={label}
        labelPlacement={labelPlacement}
      />
      <FormHelperText error={!!displayError}>{displayError}</FormHelperText>
    </>
  );
};
