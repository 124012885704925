import React, {useCallback} from 'react';
import * as Yup from 'yup';
import {Listing} from '@onroadvantage/onroadvantage-api';
import {
  multipleListingSchema,
  NUMBER_ERROR_MESSAGE,
  TemplateForm,
  TOnFormikSubmit,
} from '../../factory/template';
import {
  FormikAutocompleteWithListing,
  FormikSubmit,
  FormikTextField,
} from '../formik';
import {useEventsPaneSearchStyles} from '../eventsPane/eventsPaneSearch/EventsPaneSearch.style';
import {webEventApi} from '../../api';
import {DateTime} from 'luxon';
import {useEventsBulkClosingContext} from './EventsBulkClosingContext';
import {useAppNotifications} from '../../contexts';

export interface IEventsBulkClosingForm {
  eventType?: Listing[] | null | undefined;
  vehicle?: Listing[] | null | undefined;
  vehicleGroup?: Listing[] | null | undefined;
  driver?: Listing[] | null | undefined;
  contract?: Listing[] | null | undefined;
  dateRangeValue: number;
}

export const eventsBulkClosingFormSchema: Yup.SchemaOf<IEventsBulkClosingForm> =
  Yup.object({
    eventType: multipleListingSchema,
    contract: multipleListingSchema.nullable(),
    driver: multipleListingSchema.nullable(),
    vehicle: multipleListingSchema.nullable(),
    vehicleGroup: multipleListingSchema.nullable(),
    dateRangeUnit: Yup.string(),
    dateRangeValue: Yup.number()
      .typeError(NUMBER_ERROR_MESSAGE)
      .min(0, 'Min value 0.')
      .max(7, '7 days is the max.')
      .required('Required'),
  });

export const EventsBulkClosingFilterStep: React.FC = () => {
  const classes = useEventsPaneSearchStyles();
  const notify = useAppNotifications();
  const {setEventsList, setSelectedEvents, onNextStep} =
    useEventsBulkClosingContext();

  const handleSearchEvents = useCallback<
    TOnFormikSubmit<IEventsBulkClosingForm>
  >(
    async (values, formikHelpers) => {
      formikHelpers.setSubmitting(true);
      try {
        const {contract, driver, vehicle, vehicleGroup} = values;

        const criticalEventTypeListings = values.eventType?.filter(
          (eventType) => eventType.metaData === 'CriticalEventType'
        );
        const operationalEventTypeListings = values.eventType?.filter(
          (eventType) => eventType.metaData === 'OperationalEventType'
        );
        const criticalEventTypeIds =
          criticalEventTypeListings?.map(({value}) => value as number) ??
          undefined;

        const operationalEventTypeIds =
          operationalEventTypeListings?.map(({value}) => value as number) ??
          undefined;
        const driverIds =
          driver?.map(({value}) => value as number) ?? undefined;
        const contractIds =
          contract?.map(({value}) => value as number) ?? undefined;
        const vehicleIds =
          vehicle?.map(({value}) => value as number) ?? undefined;
        const vehicleGroupIds =
          vehicleGroup?.map(({value}) => value as number) ?? undefined;
        const endDate = DateTime.now().endOf('day');
        const startDate = endDate
          .minus({days: values.dateRangeValue})
          .startOf('day');

        const response = await webEventApi.apiWebEventGet({
          contractIds:
            contractIds && contractIds.length > 0 ? contractIds : undefined,
          criticalEventTypeIds:
            criticalEventTypeIds && criticalEventTypeIds.length > 0
              ? criticalEventTypeIds
              : undefined,
          operationalEventTypeIds:
            operationalEventTypeIds && operationalEventTypeIds.length > 0
              ? operationalEventTypeIds
              : undefined,
          driverIds: driverIds && driverIds.length > 0 ? driverIds : undefined,
          vehicleIds:
            vehicleIds && vehicleIds.length > 0 ? vehicleIds : undefined,
          vehicleGroupIds:
            vehicleGroupIds && vehicleGroupIds.length > 0
              ? vehicleGroupIds
              : undefined,
          startDate: startDate.toJSDate(),
          endDate: endDate.toJSDate(),
          perPage: 100,
        });
        setSelectedEvents(response.items);
        setEventsList(response.items);
        if (response.items && response.items.length > 0) {
          onNextStep('filterEvents');
        } else notify('warning', 'No Events Found');
      } finally {
        formikHelpers.setSubmitting(false);
      }
    },
    [onNextStep, notify, setSelectedEvents, setEventsList]
  );

  return (
    <TemplateForm<IEventsBulkClosingForm>
      initialValues={{
        dateRangeValue: 1,
        eventType: [],
        vehicle: [],
        vehicleGroup: [],
        driver: [],
        contract: [],
      }}
      onSubmit={handleSearchEvents}
      validationSchema={eventsBulkClosingFormSchema}
      disableActions
    >
      <FormikAutocompleteWithListing
        className={classes.field}
        name="eventType"
        placeholder="Enter or select a value"
        label="Event Type"
        model="WebEventType"
        multiple
      />
      <FormikAutocompleteWithListing
        className={classes.field}
        name="vehicle"
        placeholder="Enter or select a value"
        label="Vehicle Reg/Fleet Number"
        model="Vehicle"
        multiple
      />
      <FormikAutocompleteWithListing
        className={classes.field}
        name="vehicleGroup"
        placeholder="Enter or select a value"
        label="Vehicle Group"
        model="VehicleGroup"
        multiple
      />
      <FormikAutocompleteWithListing
        className={classes.field}
        name="driver"
        placeholder="Enter or select a value"
        label="Driver"
        model="Driver"
        multiple
      />
      <FormikAutocompleteWithListing
        className={classes.field}
        name="contract"
        placeholder="Enter or select a value"
        label="Contract"
        model="Contract"
        multiple
      />
      <FormikTextField
        fullWidth
        className={classes.field}
        name="dateRangeValue"
        type="number"
        label="Date range (days)"
      />
      <FormikSubmit title={'Next'} />
    </TemplateForm>
  );
};
