import React from 'react';
import {AutocompleteProps} from '../../../autocomplete';
import {FormikAutocompleteHooks} from '../../../formik';

interface Props extends AutocompleteProps {
  listValues: string[];
}

export const ReportParameterCustomMultiSelect: React.FC<Props> = ({
  options,
  listValues,
  ...props
}) => {
  const listOptions = React.useMemo(
    () => [
      {
        value: 0,
        label: `All ${props.label === 'node' ? 'Site' : props.label}s`,
      },
      ...(listValues?.map((item) => ({
        label: item,
        value: item,
      })) ?? []),
    ],
    [listValues, props.label]
  );

  return <FormikAutocompleteHooks {...props} options={listOptions} />;
};
