import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

const TableCellNoDataStyles = (theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      minHeight: 60,
      textAlign: 'center',
      verticalAlign: 'middle',
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
      borderBottomColor: theme.palette.divider,
    },
    noData: {
      textAlign: 'center',
      lineHeight: '60px',
      minHeight: 60,
    },
  });

export const useTableCellNoDataStyles = makeStyles(TableCellNoDataStyles, {
  name: 'TableCellNoDataStyles',
});
