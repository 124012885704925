import * as React from 'react';
import {Loader} from '../loader';

class NoDataLoading extends React.Component {
  render() {
    const {loading, numberOfColumns} = this.props;
    const heightValue = 200;
    const paddingTopValue = loading ? heightValue / 2.5 : heightValue / 2;

    return (
      <tr>
        <td colSpan={numberOfColumns}>
          <div
            style={{
              textAlign: 'center',
              height: heightValue,
            }}
          >
            <div style={{paddingTop: paddingTopValue}}>
              {loading ? <Loader /> : <div>No Data</div>}
            </div>
          </div>
        </td>
      </tr>
    );
  }
}

export default NoDataLoading;
