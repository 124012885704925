import React from 'react';
import {Listing, ApiListingGetRequest} from '@onroadvantage/onroadvantage-api';
import {debounce} from '@mui/material';
import {
  AutocompleteProps,
  TAutocompleteOnInputChange,
} from '../../../../autocomplete';
import {planningBoardApi} from '../../../../../api';
import {FormikAutocompleteHooks} from '../../../../formik';
import {useAppNotifications} from '../../../../../contexts';
import {usePlanningBoardContext} from '../../../planningBoardContext';

interface PlanningBoardFormikAutocompleteProps
  extends AutocompleteProps,
    ApiListingGetRequest {}

export const PlanningBoardFormikAutocompleteComponent: React.FC<
  PlanningBoardFormikAutocompleteProps
> = ({model, loading, onInputChange, options, ...props}) => {
  const notify = useAppNotifications();
  const {contractIds, customerIds} = usePlanningBoardContext();
  const [listing, setListing] = React.useState<Listing[]>([]);
  const [localLoading, setLocalLoading] = React.useState<boolean>(false);

  const getListing = React.useCallback(
    async (filter?: string) => {
      setLocalLoading(true);
      try {
        if (contractIds.length > 0) {
          const response = await planningBoardApi.apiWebPlanningBoardListingGet(
            {
              model,
              contractIds,
              customerIds: customerIds.length > 0 ? customerIds : undefined,
              qryFilter: filter,
            }
          );
          setListing(response.items ?? []);
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to load listing');
      } finally {
        setLocalLoading(false);
      }
    },
    [contractIds, customerIds, model, notify]
  );

  const getListingDebounce = React.useRef(debounce(getListing, 500)).current;

  const handleInputChange = React.useCallback<TAutocompleteOnInputChange>(
    async (_event, value: string) => {
      setLocalLoading(true);
      await getListingDebounce(value);
    },
    [getListingDebounce]
  );

  React.useEffect(() => {
    getListing();
    return () => setListing([]);
  }, [getListing]);

  return (
    <FormikAutocompleteHooks
      {...props}
      loading={localLoading}
      options={listing}
      onInputChange={handleInputChange}
    />
  );
};

export const PlanningBoardTripFormikAutocomplete = React.memo(
  PlanningBoardFormikAutocompleteComponent
);
