import React from 'react';
import _ from 'lodash';
import {
  ITemplateControllerRoute,
  TemplateController,
} from '../../factory/template';
import {
  ContactGroupContext,
  ContactGroupContextProvider,
} from './ContactGroupContext';
import {ContactGroupList} from './ContactGroupList';
import {ContactGroupIdHandler} from './ContactGroupIdHandler';
import {ContactGroupForm} from './ContactGroupForm';
import {ContactGroup} from './ContactGroup';

export const ContactGroupController: React.FC = () => {
  return (
    <ContactGroupContextProvider>
      <ContactGroupControllerComponent />
    </ContactGroupContextProvider>
  );
};

const ContactGroupControllerComponent: React.FC = () => {
  const {contactGroup, loading} = React.useContext(ContactGroupContext);
  const routes: ITemplateControllerRoute[] = React.useMemo(
    () => [
      {title: 'Contact Group List', component: ContactGroupList},
      {
        path: '/add',
        title: 'New Contact Group',
        name: 'Add ContactGroup',
        type: 'Add',
        component: () => <ContactGroupForm isAdd />,
      },
      {
        path: '/:contactGroupId',
        title: (params) =>
          loading
            ? 'Contact Group Loading...'
            : contactGroup?.name ??
              `Contact Group ${_.get(params, 'contactGroupId', 'Single')}`,
        component: ContactGroup,
        IdHandler: ContactGroupIdHandler,
      },
    ],
    [loading, contactGroup?.name]
  );
  return <TemplateController rootPath="/contactgrouplist" routes={routes} />;
};
