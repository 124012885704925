import React from 'react';
import {
  multipleListingSchema,
  TemplateCard,
  TemplateForm,
} from '../../factory/template';
import {VehicleGroupContext} from './VehicleGroupContext';
import {Listing, VehicleGroup} from '@onroadvantage/onroadvantage-api';
import * as Yup from 'yup';
import {FormikAutocompleteWithListing, FormikTextField} from '../formik';

export interface IVehicleGroupForm {
  name: string;
  planningSkills: Listing[] | undefined;
}

const schema: Yup.SchemaOf<IVehicleGroupForm> = Yup.object({
  name: Yup.string().required('Required'),
  planningSkills: multipleListingSchema,
});

const getInitialValues = (
  vehicleGroup?: VehicleGroup | null
): IVehicleGroupForm | undefined => {
  if (vehicleGroup) {
    return {
      name: vehicleGroup.name ?? '',
      planningSkills: vehicleGroup.skills?.map(({id, name, description}) => ({
        value: id,
        label: `${name} - ${description}`,
      })),
    };
  }
  return undefined;
};

export const VehicleGroupForm: React.FC<{isAdd?: boolean}> = ({isAdd}) => {
  const {detailsRef, loading, submitting, vehicleGroup, onDetailsFormSubmit} =
    React.useContext(VehicleGroupContext);
  const [initialValues, setInitialValues] = React.useState<IVehicleGroupForm>();

  React.useEffect(() => {
    const values = getInitialValues(vehicleGroup);

    if (values) {
      setInitialValues(values);
    } else if (isAdd) {
      setInitialValues({
        name: '',
        planningSkills: [],
      });
    }
  }, [isAdd, vehicleGroup, setInitialValues]);

  return (
    <TemplateCard
      title={`VehicleGroup Details ${isAdd ? 'Add' : 'Edit'}`}
      loading={loading}
    >
      <TemplateForm<IVehicleGroupForm>
        initialValues={initialValues}
        onSubmit={onDetailsFormSubmit}
        permission={{
          name: `${isAdd ? 'Add' : 'Edit'} VehicleGroup`,
          type: isAdd ? 'Add' : 'Edit',
        }}
        submitting={submitting}
        innerRef={detailsRef}
        validationSchema={schema}
      >
        <FormikTextField
          name="name"
          placeholder="Enter the vehicle group name"
          label="Vehicle Group Name"
          fullWidth
        />
        <FormikAutocompleteWithListing
          name="planningSkills"
          placeholder="Enter and select the vehicle group skills"
          label="Planning Skills"
          model="PlanningSkill"
          multiple
        />
      </TemplateForm>
    </TemplateCard>
  );
};
