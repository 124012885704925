import React from 'react';
import {useTripContext} from '../tripContext';
import {TripEventList} from './tripEventList';

export const TripOperationalEventList: React.FC = () => {
  const {operationalEvents, loadingOperationalEvents} = useTripContext();

  return (
    <TripEventList
      type="Operational"
      list={operationalEvents ?? []}
      loading={loadingOperationalEvents}
    />
  );
};
