import {makeStyles, createStyles} from '@mui/styles';

const TemplateControllerStyles = () =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      minHeight: 10,
    },
    root: {},
  });

export const useTemplateControllerStyles = makeStyles(
  TemplateControllerStyles,
  {name: 'TemplateControllerStyles'}
);
