import ConfigService from './ConfigService/ConfigService';

import ResponseService from './ResponseService';
import {encodeUrl, flatten} from './Util';
import {appNotificationStore} from '../stores/mobxStores';
import {logout} from '../actions';
import {authStore} from '../store';

const serverUrl = ConfigService.serverUri;
const queryUrl = `${serverUrl}/api/role`;

const RoleService = {
  query: async (params) => {
    Object.keys(params).forEach(
      // eslint-disable-next-line no-param-reassign
      (key) => params[key] == null && delete params[key]
    );
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    if (auth.oauthAuthenticated) {
      headers.set('Authorization', `Bearer ${auth.token}`);
    } else {
      headers.set('Authentication-Token', auth.token);
    }
    const response = await fetch(encodeUrl(queryUrl, params), {
      method: 'GET',
      mode: 'cors',
      headers,
    });
    return RoleService.normalize(await ResponseService.checkStatus(response));
  },
  delete: async (roleId) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    if (auth.oauthAuthenticated) {
      headers.set('Authorization', `Bearer ${auth.token}`);
    } else {
      headers.set('Authentication-Token', auth.token);
    }
    const response = await fetch(`${queryUrl}/${roleId}`, {
      method: 'DELETE',
      mode: 'cors',
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  update: async (role) => {
    if (!role.id) {
      return RoleService.create(role);
    }
    Object.keys(role).forEach(
      // eslint-disable-next-line no-param-reassign
      (key) => role[key] == null && delete role[key]
    );
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    if (auth.oauthAuthenticated) {
      headers.set('Authorization', `Bearer ${auth.token}`);
    } else {
      headers.set('Authentication-Token', auth.token);
    }
    const response = await fetch(`${queryUrl}/${role.id}`, {
      method: 'PATCH',
      mode: 'cors',
      body: JSON.stringify(role),
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  create: async (role) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    if (auth.oauthAuthenticated) {
      headers.set('Authorization', `Bearer ${auth.token}`);
    } else {
      headers.set('Authentication-Token', auth.token);
    }
    const response = await fetch(`${queryUrl}`, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(role),
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  normalize: async (response) => {
    const result = flatten(await response.data);
    return result;
  },
  getById: async (roleId) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    if (auth.oauthAuthenticated) {
      headers.set('Authorization', `Bearer ${auth.token}`);
    } else {
      headers.set('Authentication-Token', auth.token);
    }
    const response = await fetch(`${queryUrl}/${roleId}`, {
      method: 'GET',
      mode: 'cors',
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  isRole: (parentRole) => {
    const {auth} = authStore;

    if (auth && auth.roles) {
      let found = false;
      auth.roles.forEach((role) => {
        if (role.name === parentRole) {
          found = true;
        }
      });
      return found;
    }
    return false;
  },
  hasRole: (roleName) => {
    if (!roleName) {
      return false;
    }

    const {auth} = authStore;
    if (auth && auth.roles) {
      return auth.roles.some((role) => {
        return role.name === roleName;
      });
    }

    return false;
  },
  hasPermission: (permissionName, type) => {
    if (!permissionName || !type) {
      return false;
    }
    const {auth} = authStore;

    if (auth && auth.roles) {
      const hasSuperAdminRole = auth.roles.some((role) => {
        return role.name?.toLowerCase() === 'superadmin';
      });

      if (hasSuperAdminRole) {
        return true;
      }

      let found = false;

      auth.roles.forEach((role) => {
        if (!role.permissions) {
          appNotificationStore.enqueueNotification(
            'warning',
            `Could not load local user.`
          );
          logout();
          window.location.reload();
        } else {
          role.permissions.forEach((permission) => {
            if (
              permission.name.toLowerCase() === permissionName.toLowerCase() &&
              permission.type.toLowerCase() === type.toLowerCase()
            ) {
              found = true;
            }
          });
        }
      });
      return found;
    }
    return false;
  },
  hasExactPermission: (permissionName, type) => {
    if (!permissionName || !type) {
      return false;
    }
    const {auth} = authStore;

    if (auth && auth.roles) {
      let found = false;

      auth.roles.forEach((role) => {
        if (!role.permissions) {
          appNotificationStore.enqueueNotification(
            'warning',
            `Could not load local user.`
          );
          logout();
          window.location.reload();
        } else {
          role.permissions.forEach((permission) => {
            if (
              permission.name.toLowerCase() === permissionName.toLowerCase() &&
              permission.type.toLowerCase() === type.toLowerCase()
            ) {
              found = true;
            }
          });
        }
      });
      return found;
    }
    return false;
  },
  hasOneOfPermissions: (permissions) => {
    let allowed = false;
    permissions.forEach((permission) => {
      const {permissionName} = permission;
      const {type} = permission;
      if (!permissionName || !type) {
        return;
      }
      if (RoleService.hasPermission(permissionName, type)) {
        allowed = true;
      }
    });
    return allowed;
  },
  hasAllPermissions: (permissions) => {
    let allowed = false;
    let disallowed = false;
    permissions.forEach((permission) => {
      const {permissionName} = permission;
      const {type} = permission;
      if (!permissionName || !type) {
        return;
      }
      if (RoleService.hasPermission(permissionName, type)) {
        allowed = true;
      } else {
        disallowed = false;
      }
    });
    if (allowed && !disallowed) {
      return true;
    }
    return false;
  },
};

export default RoleService;
