import React from 'react';
import {Button, Divider, Stack, Tab, Tabs, TextField} from '@mui/material';
import {useEventCardAddActivityStyles} from './EventCardAddActivity.style';
import {
  Autocomplete,
  AutocompleteOptionType,
  TAutocompleteOnChange,
} from '../../autocomplete';
import {Loader} from '../../loader';
import {EventContext} from '../EventContext';
import {
  criticalEventApi,
  operationalEventApi,
  reasonCodeApi,
} from '../../../api';
import {
  ApiReasonCodeGetRequest,
  CriticalEventDump,
  OperationalEventDump,
} from '@onroadvantage/onroadvantage-api';
import {useAppNotifications} from '../../../contexts';

interface EventCardAddActivityProps {
  event: CriticalEventDump | OperationalEventDump;
}

export const EventCardAddActivity: React.FC<EventCardAddActivityProps> = ({
  event,
}) => {
  const notify = useAppNotifications();
  const {setEvent, type} = React.useContext(EventContext);
  const [activeTab, setActiveTab] = React.useState<number>(0);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [reasonCodeOptions, setReasonCodeOptions] = React.useState<
    AutocompleteOptionType[]
  >([]);
  const [comment, setComment] = React.useState<string | undefined>();
  const [selectedReasonCode, setSelectedReasonCode] = React.useState<
    AutocompleteOptionType | undefined
  >();
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const handleChangeActiveTab = React.useCallback(
    (_event: any, value: number) => setActiveTab(value),
    []
  );

  const handleChangeComment = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setComment(event.target.value);
    },
    []
  );

  const handleChangeCommentReasonCode =
    React.useCallback<TAutocompleteOnChange>((_event, value) => {
      if (value && !Array.isArray(value)) {
        setComment((value as AutocompleteOptionType).label);
        setSelectedReasonCode(value as AutocompleteOptionType);
      }
    }, []);

  const handleSave = React.useCallback(async () => {
    setSubmitting(true);
    try {
      if (comment && event.id) {
        let updatedEventResponse:
          | CriticalEventDump
          | OperationalEventDump
          | undefined;
        if (type === 'operational') {
          updatedEventResponse =
            await operationalEventApi.apiOperationalEventOperationalEventIdPost(
              {
                body: {
                  activities: [
                    {
                      action: 'Comment',
                      value: comment,
                      contractOperationalEventTypeReasonCodeId:
                        typeof selectedReasonCode?.value === 'string'
                          ? parseInt(selectedReasonCode?.value)
                          : selectedReasonCode?.value ?? undefined,
                    },
                  ],
                },
                operationalEventId: event.id,
              }
            );
        } else {
          updatedEventResponse =
            await criticalEventApi.apiCriticalEventCriticalEventIdPost({
              body: {
                activities: [
                  {
                    action: 'Comment',
                    value: comment,
                    contractCriticalEventTypeReasonCodeId:
                      typeof selectedReasonCode?.value === 'string'
                        ? parseInt(selectedReasonCode?.value)
                        : selectedReasonCode?.value ?? undefined,
                  },
                ],
              },
              criticalEventId: event.id,
            });
        }
        if (updatedEventResponse != null) {
          setEvent(updatedEventResponse);
        }
      }
    } catch (e) {
      notify('error', 'Failed to add activity');
    } finally {
      setComment('');
      setSubmitting(false);
    }
  }, [comment, event.id, type, setEvent, notify, selectedReasonCode?.value]);

  React.useEffect(() => {
    const getReasonCode = async () => {
      setLoading(true);
      try {
        if (event.type?.id && event.vehicle?.contract?.id) {
          const requestObj: ApiReasonCodeGetRequest = {
            contractId: event.vehicle.contract.id,
          };
          if (type === 'operational') {
            requestObj.operationalEventTypeId = event.type.id;
          } else {
            requestObj.criticalEventTypeId = event.type.id;
          }
          const response = await reasonCodeApi.apiReasonCodeGet(requestObj);
          const items =
            response.items?.map((item) => ({
              value: item.id,
              label: `${item.name} - ${item.description}`,
            })) ?? [];
          setReasonCodeOptions(items.length > 0 ? items : []);
        }
      } finally {
        setLoading(false);
      }
    };
    getReasonCode();
    return () => {
      setReasonCodeOptions([]);
      setComment(undefined);
    };
  }, [event.type?.id, type, event.vehicle?.contract?.id, event.type?.name]);

  return (
    <Stack>
      <Divider />
      <Tabs value={activeTab} onChange={handleChangeActiveTab}>
        <Tab label="Comment" />
        <Tab label="Reason Codes" />
      </Tabs>
      <TabPanel value={activeTab} index={0}>
        <TextField
          id="comment"
          name="add-comment"
          label="Add Comment"
          disabled={loading || submitting}
          multiline
          rows={2}
          value={comment}
          onChange={handleChangeComment}
          sx={{width: '50%'}}
        />
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <Autocomplete
          label="Reason Code"
          id="reasonCode"
          name="reasonCode"
          disabled={loading || submitting}
          onChange={handleChangeCommentReasonCode}
          options={reasonCodeOptions}
          placeholder="Enter and select a value"
          sx={{width: '50%'}}
        />
      </TabPanel>
      <Stack alignItems="flex-start">
        <Button
          size="small"
          variant="outlined"
          color="primary"
          onClick={handleSave}
          disabled={submitting || loading}
          startIcon={<Loader size={25} loading={submitting} />}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
};

interface TabPanelProps {
  value: number;
  index: number;
}

const TabPanel: React.FC<TabPanelProps> = ({value, index, children}) => {
  const classes = useEventCardAddActivityStyles();
  if (value !== index) return null;
  return <div className={classes.container}>{children}</div>;
};
