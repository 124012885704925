import React from 'react';
import * as Yup from 'yup';
import {ContractOperationalEventTypeReasonCode} from '@onroadvantage/onroadvantage-api';
import {TemplateTable} from '../../../factory/template';
import {useContractOperationalEventTypeConfigContext} from './ContractOperationalEventTypeConfigContext';

interface ContractOperationalEventTypeConfigReasonCodeListAddSchema {
  name: string;
  description: string | undefined;
}

const contractOperationalEventTypeConfigReasonCodeListAddSchema: Yup.SchemaOf<ContractOperationalEventTypeConfigReasonCodeListAddSchema> =
  Yup.object({
    name: Yup.string().required('Required'),
    description: Yup.string(),
  });

export const ContractOperationalEventTypeConfigReasonCodeList = () => {
  const {
    loading,
    submitting,
    contractOperationalEventTypeConfig,
    onAddReasonCodes,
    onEditReasonCodes,
    onDeleteReasonCode,
  } = useContractOperationalEventTypeConfigContext();

  return (
    <TemplateTable<ContractOperationalEventTypeReasonCode>
      config={{
        columns: [
          {name: 'name', label: 'Name', type: 'string', enableEditing: true},
          {
            name: 'description',
            label: 'Description',
            type: 'string',
            enableEditing: true,
          },
        ],
        disablePagination: true,
        disableToolbar: true,
        deleteMessage: (row) => ({
          title: 'Contract Operational Event Type Config Reason Code',
          items: {
            ContractOperationalEventTypeConfig: `${contractOperationalEventTypeConfig?.operationalEventType?.name} - ${contractOperationalEventTypeConfig?.contract?.name}`,
            Name: row.name,
            Description: row.description,
          },
        }),
        identifier: 'CONTRACT_OPERATIONAL_EVENT_TYPE_CONFIG_REASON_CODE',
      }}
      addValidation={contractOperationalEventTypeConfigReasonCodeListAddSchema}
      list={contractOperationalEventTypeConfig?.reasonCodes ?? []}
      currentPage={1}
      onInlineAdd={onAddReasonCodes}
      onInlineEdit={onEditReasonCodes}
      onDelete={onDeleteReasonCode}
      loading={loading || submitting}
    />
  );
};
