import React from 'react';
import * as Yup from 'yup';
import {SpeedTableBandMinimal} from '@onroadvantage/onroadvantage-api';
import {
  NUMBER_ERROR_MESSAGE,
  TemplateTable,
  TemplateTableConfig,
} from '../../../factory/template';
import {useSpeedTableContext} from '../speedTableContext';
import {RoleService} from '../../../service';
import {useSpeedTableBandList} from './useSpeedTableBandList';

export interface ISpeedTableBandList {
  name: string;
  fromKm: number;
  toKm: number;
  speedKmph: number;
}

const addValidation: Yup.SchemaOf<ISpeedTableBandList> = Yup.object({
  name: Yup.string().required('Required'),
  fromKm: Yup.number().typeError(NUMBER_ERROR_MESSAGE).required('Required'),
  toKm: Yup.number().typeError(NUMBER_ERROR_MESSAGE).required('Required'),
  speedKmph: Yup.number().typeError(NUMBER_ERROR_MESSAGE).required('Required'),
});

const editValidation: Yup.SchemaOf<Partial<ISpeedTableBandList>> = Yup.object({
  name: Yup.string(),
  fromKm: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
  toKm: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
  speedKmph: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
});

export const SpeedTableBandList: React.FC = () => {
  const {
    speedTable,
    onAddSpeedTableBand,
    onDeleteSpeedTableBand,
    onEditSpeedTableBand,
    submitting,
    loadingSpeedTable,
  } = useSpeedTableContext();
  const {list, filters, sorting, onFiltersChange, onSortingChange} =
    useSpeedTableBandList({speedTableBands: speedTable?.bands});

  const config: TemplateTableConfig<SpeedTableBandMinimal> = React.useMemo(
    () => ({
      columns: [
        {name: 'name', label: 'Name', type: 'string', enableEditing: true},
        {name: 'fromKm', label: 'From Km', type: 'number', enableEditing: true},
        {name: 'toKm', label: 'To Km', type: 'number', enableEditing: true},
        {
          name: 'speedKmph',
          label: 'Speed Kmph',
          type: 'number',
          enableEditing: true,
        },
      ],
      disableToolbar: true,
      disablePagination: true,
      enableGrouping: false,
      enableSort: true,
      enableFilter: true,
      deleteMessage: (row) => ({
        title: 'Speed Table Band',
        items: {SpeedTable: speedTable?.name, Name: row.name},
      }),
      identifier: 'SPEED_TABLE_BAND_LIST',
    }),
    [speedTable]
  );

  return (
    <TemplateTable
      config={config}
      list={list}
      currentPage={1}
      loading={loadingSpeedTable || submitting}
      filters={filters}
      sorting={sorting}
      onFiltersChange={onFiltersChange}
      onSortingChange={onSortingChange}
      addValidation={addValidation}
      editValidation={editValidation}
      onInlineAdd={
        RoleService.hasPermission('Edit SpeedTable', 'Edit')
          ? onAddSpeedTableBand
          : undefined
      }
      onInlineEdit={
        RoleService.hasPermission('Edit SpeedTable', 'Edit')
          ? onEditSpeedTableBand
          : undefined
      }
      onDelete={
        RoleService.hasPermission('Edit SpeedTable', 'Edit')
          ? onDeleteSpeedTableBand
          : undefined
      }
    />
  );
};
