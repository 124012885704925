import React from 'react';
import Paper from '@mui/material/Paper';
import {usePlanningBoardGroupOptionsStyles} from './PlanningBoardGroupOptions.style';
import {
  PlanningBoardGroupOptionContext,
  TPlanningBoardGroupOptionsVariants,
} from './PlanningBoardGroupOptionsContext';
import clsx from 'clsx';

export interface PlanningBoardGroupOptionsContainerProps {
  variant: TPlanningBoardGroupOptionsVariants;
}

export const PlanningBoardGroupOptionsContainer: React.FC<
  PlanningBoardGroupOptionsContainerProps
> = ({variant, children}) => {
  const classes = usePlanningBoardGroupOptionsStyles();
  const {draggingItem, overContainer, onDragOver, onDragLeave, onDrop} =
    React.useContext(PlanningBoardGroupOptionContext);
  const isOver = React.useMemo(
    () => draggingItem && overContainer === variant,
    [draggingItem, overContainer, variant]
  );
  return (
    <>
      <Paper
        component="div"
        className={clsx(classes.dropContainer, {
          [classes.dropContainerIsOver]: isOver,
          [classes.dropContainerSelected]: variant === 'selected',
          [classes.dropContainerUnselected]: variant === 'unselected',
        })}
        onDragOver={onDragOver(variant)}
        onDragLeave={onDragLeave(variant)}
        onDrop={onDrop(variant)}
      >
        {children}
      </Paper>
    </>
  );
};
