import React from 'react';
import {
  MasterTripDebriefSnapshotData,
  Trip,
} from '@onroadvantage/onroadvantage-api';
import {Avatar, CardHeader, TextField} from '@mui/material';
import {CheckCircle} from '@mui/icons-material';
import {FormikProps} from 'formik';
import * as Yup from 'yup';
import {
  NUMBER_ERROR_MESSAGE,
  TemplateForm,
  TOnFormikSubmit,
} from '../../../factory/template';
import {FormikDateTimePicker, FormikTextField} from '../../formik';
import {masterTripDebriefApi} from '../../../api';
import {useAppNotifications} from '../../../contexts';
import {LuxonService} from '../../../service';
import {
  ALREADY_APPROVED_WARNING_MESSAGE,
  NOT_LAST_WARNING_MESSAGE,
} from '../../tripDebrief';
import {useTripContext} from '../tripContext';
import {Loader} from '../../loader';
import {TripDuplicateErrorOccurred} from '../../tripDebrief/tripDebriefErrorOccured/TripDuplicateErrorOccured';

interface ITripDebriefBillingForm {
  approvedForBilling?: boolean | null | undefined;
  manualStartOdometer?: number | null | undefined;
  manualEndOdometer?: number | null | undefined;
  completedDatetime?: Date | null | undefined;
}

const schema: Yup.SchemaOf<ITripDebriefBillingForm> = Yup.object({
  approvedForBilling: Yup.boolean(),
  manualStartOdometer: Yup.number()
    .typeError(NUMBER_ERROR_MESSAGE)
    .required('Required')
    .test(
      'checkEndGTStart',
      'End Odo must be more than Start Odo',
      function () {
        const value1: number = this.resolve(Yup.ref('manualStartOdometer'));
        const value2: number = this.resolve(Yup.ref('manualEndOdometer'));
        return value2 > value1;
      }
    )
    .test(
      'check2000MaxDiff',
      'End Odo and Start Odo cannot be more than 2000km apart',
      function () {
        const value1: number = this.resolve(Yup.ref('manualStartOdometer'));
        const value2: number = this.resolve(Yup.ref('manualEndOdometer'));
        return Math.abs(value2 - value1) < 2000;
      }
    ),
  manualEndOdometer: Yup.number()
    .typeError(NUMBER_ERROR_MESSAGE)
    .required('Required')
    .test(
      'checkEndGTStart',
      'End Odo must be more than Start Odo',
      function () {
        const value1: number = this.resolve(Yup.ref('manualStartOdometer'));
        const value2: number = this.resolve(Yup.ref('manualEndOdometer'));
        return value2 > value1;
      }
    )
    .test(
      'check2000MaxDiff',
      'End Odo and Start Odo cannot be more than 2000km apart',
      function () {
        const value1: number = this.resolve(Yup.ref('manualStartOdometer'));
        const value2: number = this.resolve(Yup.ref('manualEndOdometer'));
        return Math.abs(value2 - value1) < 2000;
      }
    ),
  completedDatetime: Yup.date().required('Required'),
});

const getInitialValues = (
  masterTripDebrief: MasterTripDebriefSnapshotData | undefined
): ITripDebriefBillingForm | undefined => {
  if (masterTripDebrief) {
    const {completedDatetime} = masterTripDebrief;

    return {
      approvedForBilling: masterTripDebrief
        ? masterTripDebrief.approvedForBilling
        : false,
      manualStartOdometer: masterTripDebrief.manualStartOdometer,
      manualEndOdometer: masterTripDebrief.manualEndOdometer,
      completedDatetime: LuxonService.toLocalDateTime(completedDatetime),
    };
  }
  return undefined;
};

export const TripDebriefBillingForm: React.FC = () => {
  const notify = useAppNotifications();
  const {
    masterTrip,
    masterTripId,
    masterTripDebrief,
    updateMasterTripDebrief,
    loadingMasterTripDebrief,
    duplicateErrorOccurred,
    duplicateErrorMessage,
  } = useTripContext();
  /**
   * Destructure masterTripDebrief, need to add || ({} as MasterTripDebriefSnapshotData) for typescript, since
   * masterTripDebrief is nullable.
   */
  const {revision, approvedForBilling, createdBy} =
    masterTripDebrief || ({} as MasterTripDebriefSnapshotData);
  /**
   * Destructure trip, need to add || ({} as Trip) for typescript, since trip is nullable.
   */
  const {tripDistance} = masterTrip?.trip || ({} as Trip);
  const [initialValues, setInitialValues] = React.useState<
    ITripDebriefBillingForm | undefined
  >();
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const innerRef = React.useRef<FormikProps<ITripDebriefBillingForm>>(null);

  const handleSubmit = React.useCallback<
    TOnFormikSubmit<ITripDebriefBillingForm>
  >(
    async (values, formikHelpers) => {
      formikHelpers.setSubmitting(true);
      setSubmitting(true);
      try {
        if (masterTripId && revision) {
          const response =
            await masterTripDebriefApi.apiMasterTripMasterTripIdDebriefPatch({
              masterTripId,
              body: {...values, revision},
            });
          if (response.data) {
            updateMasterTripDebrief(response.data);
            notify('success', 'Debriefed trip');
          }
        }
      } catch (e) {
        if (e.status === 409) {
          if (approvedForBilling) {
            /** Can't update the debrief if it's already approved */
            notify('warning', e.message ?? ALREADY_APPROVED_WARNING_MESSAGE);
          } else {
            /** Can't update the debrief if it's revision is not the latest for the current debrief */
            notify('warning', e.message ?? NOT_LAST_WARNING_MESSAGE);
          }
        } else {
          notify('error', 'Failed to update debrief');
        }
      } finally {
        formikHelpers.setSubmitting(false);
        setSubmitting(false);
      }
    },
    [
      masterTripId,
      revision,
      approvedForBilling,
      updateMasterTripDebrief,
      notify,
    ]
  );

  const handleGetPlanDifference = React.useCallback(
    (values: ITripDebriefBillingForm) => {
      if (
        values.manualStartOdometer &&
        values.manualEndOdometer &&
        tripDistance
      ) {
        return Math.round(
          values.manualEndOdometer - values.manualStartOdometer - tripDistance
        );
      }

      return '-';
    },
    [tripDistance]
  );

  const handleGetPlanVariance = React.useCallback(
    (values: ITripDebriefBillingForm) => {
      if (
        values.manualStartOdometer &&
        values.manualEndOdometer &&
        tripDistance
      ) {
        return Math.round(
          ((values.manualEndOdometer -
            values.manualStartOdometer -
            tripDistance) /
            tripDistance) *
            100
        );
      }

      return '-';
    },
    [tripDistance]
  );

  const handleGetOdometerDifference = React.useCallback(
    (values: ITripDebriefBillingForm) => {
      if (values.manualStartOdometer && values.manualEndOdometer) {
        return values.manualEndOdometer - values.manualStartOdometer;
      }

      return '-';
    },
    []
  );

  React.useEffect(() => {
    const values = getInitialValues(masterTripDebrief);
    if (values) {
      setInitialValues(values);
    }
    return () => setInitialValues(undefined);
  }, [masterTripDebrief]);

  if (!initialValues && loadingMasterTripDebrief) {
    return <Loader text="Loading Debrief..." />;
  }

  if (duplicateErrorOccurred) {
    return <TripDuplicateErrorOccurred receivedError={duplicateErrorMessage} />;
  }

  return (
    <TemplateForm
      initialValues={initialValues}
      onSubmit={handleSubmit}
      innerRef={innerRef}
      submitting={submitting}
      validationSchema={schema}
    >
      {({values}) => (
        <>
          <FormikTextField
            disabled={!!values.approvedForBilling}
            name="manualStartOdometer"
            placeholder="Enter the Trip Start Odometer"
            label="Trip Debrief Manual Start Odometer"
            type="number"
            fullWidth
          />
          <FormikTextField
            disabled={!!values.approvedForBilling}
            name="manualEndOdometer"
            placeholder="Enter the Trip End Odometer"
            label="Trip Debrief Manual End Odometer"
            type="number"
            fullWidth
          />
          <TextField
            disabled
            label="Trip Debrief Odometer Difference"
            value={`${handleGetOdometerDifference(values)}`}
            fullWidth
            sx={{mt: 0.5, mb: 1.5}}
          />
          <TextField
            disabled
            label="Trip Debrief Km Variance from Plan"
            value={`${handleGetPlanDifference(
              values
            )}km: ${handleGetPlanVariance(values)}%`}
            fullWidth
            sx={{mt: 0.5, mb: 1.5}}
          />
          <FormikDateTimePicker
            disabled={!!values.approvedForBilling}
            name="completedDatetime"
            label="Last Drop Date"
            fullWidth
          />
          {createdBy?.email ? (
            <CardHeader
              title={`${
                approvedForBilling ? 'Approval For Billing' : 'Saved'
              } By ${createdBy.email}`}
              avatar={
                <Avatar
                  sx={{
                    width: 30,
                    height: 30,
                    bgcolor: approvedForBilling ? 'success.main' : undefined,
                  }}
                >
                  <CheckCircle fontSize="small" />
                </Avatar>
              }
              sx={{pt: 0, pl: 0.6, pb: 1}}
            />
          ) : loadingMasterTripDebrief ? (
            <Loader text="Loading trip debrief..." size={30} />
          ) : null}
        </>
      )}
    </TemplateForm>
  );
};
