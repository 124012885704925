import * as React from 'react';
import * as PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import withStyles from '@mui/styles/withStyles';
import Checkbox from '@mui/material/Checkbox';

const styles = (theme) => ({
  Cell: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  progressBar: {
    backgroundColor: 'red',
    float: 'left',
    height: theme.spacing(2),
  },
});

export const ActiveCellBase = ({
  value,
  classes,
  style,
  disabled,
  onValueChange,
  column,
}) => (
  <TableCell className={classes.Cell} style={style}>
    <Checkbox
      data-test="active"
      checked={value}
      disabled={disabled}
      onChange={(event, checked) => {
        column.valueChange();
        onValueChange(checked);
      }}
    />
  </TableCell>
);

ActiveCellBase.propTypes = {
  value: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};
ActiveCellBase.defaultProps = {
  style: {},
};

export const ActiveCell = withStyles(styles, {name: 'ActiveCell'})(
  ActiveCellBase
);
