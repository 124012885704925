import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';
import {
  NESTED_LEVEL_1_DARK,
  NESTED_LEVEL_1_LIGHT,
} from '../../../../factory/template';

const TripEventListStyles = (theme: Theme) =>
  createStyles({
    tabBar: {
      display: 'flex',
      flex: 1,
      borderBottomWidth: 1,
      borderBottomColor: theme.palette.divider,
      borderBottomStyle: 'solid',
    },
    rowDetailTable: {
      backgroundColor:
        theme.palette.mode === 'dark'
          ? NESTED_LEVEL_1_DARK
          : NESTED_LEVEL_1_LIGHT,
    },
  });

export const useTripEventListStyles = makeStyles(TripEventListStyles, {
  name: 'TripEventListStyles',
});
