import React from 'react';
import {useCompartmentVerticalCompartmentStyles} from './CompartmentVerticalCompartment.style';
import {CardActionArea, Typography} from '@mui/material';
import clsx from 'clsx';
import {VehicleCompartment} from '@onroadvantage/onroadvantage-api';

export interface CompartmentVerticalCompartmentProps {
  compartment: VehicleCompartment;
  getVerticalCompartmentContent?: (
    compartment: VehicleCompartment
  ) => React.ReactNode;
  getVerticalCompartment?: (compartment: VehicleCompartment) => React.ReactNode;
  onNavigate?: (compartment: VehicleCompartment) => void;
  onNavigateInDialog?: (compartment: VehicleCompartment) => void;
  getActive?: (compartment: VehicleCompartment) => boolean;
}

export const CompartmentVerticalCompartment: React.FC<
  CompartmentVerticalCompartmentProps
> = ({
  compartment,
  onNavigateInDialog,
  getVerticalCompartmentContent,
  getVerticalCompartment,
  getActive,
  onNavigate,
}) => {
  const classes = useCompartmentVerticalCompartmentStyles();

  const handleNavigateInDialog = React.useCallback(() => {
    if (onNavigateInDialog) onNavigateInDialog(compartment);
  }, [compartment, onNavigateInDialog]);

  if (getVerticalCompartment) return <>{getVerticalCompartment(compartment)}</>;

  return (
    <CardActionArea
      className={clsx(classes.compartment, {
        [classes.compartmentActive]: getActive && getActive(compartment),
        [classes.compartmentDefaultPointer]: !onNavigate,
      })}
      disableRipple={!onNavigate}
      disableTouchRipple={!onNavigate}
      onClick={onNavigate ? handleNavigateInDialog : undefined}
    >
      {getVerticalCompartmentContent ? (
        getVerticalCompartmentContent(compartment)
      ) : (
        <>
          <div className={classes.compartmentName}>
            <Typography variant="subtitle1">
              <strong>{compartment.name}</strong>
            </Typography>
          </div>
          <div
            className={clsx(classes.divider, {
              [classes.dividerActive]: getActive && getActive(compartment),
            })}
          />
          <div className={classes.compartmentContent}>
            <Typography gutterBottom>
              Loading: {compartment.loadingSequence}
            </Typography>
            <Typography gutterBottom>
              Offloading: {compartment.offloadingSequence}
            </Typography>
            <Typography>Position: {compartment.position}</Typography>
          </div>
        </>
      )}
    </CardActionArea>
  );
};
