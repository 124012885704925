import React from 'react';
import {Plugin, Template, TemplatePlaceholder} from '@devexpress/dx-react-core';
import {IconButton} from '@mui/material';
import {Refresh} from '@mui/icons-material';

export interface TableToolbarRefreshButtonProps {
  loading?: boolean;
  onRefresh?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

/** adds a loading indicator to the DevExpress Toolbar */
export class TableToolbarRefreshButton extends React.PureComponent<TableToolbarRefreshButtonProps> {
  pluginDependencies = [{name: 'Toolbar'}];

  render() {
    const {loading, onRefresh} = this.props;
    return (
      <Plugin dependencies={this.pluginDependencies} name="ToolbarFilter">
        <Template name="toolbarContent">
          <TemplatePlaceholder />
          <IconButton disabled={loading} onClick={onRefresh} size="large">
            <Refresh />
          </IconButton>
        </Template>
      </Plugin>
    );
  }
}
