import React from 'react';
import {adminTelematicsConfigApi} from '../../../api';
import {
  AdminTelematicsConfig as AdminTelematicsConfigType,
  ApiAdminTelematicsConfigGetRequest,
} from '@onroadvantage/onroadvantage-api';
import {AdminTelematicsConfigDetailsFormValues} from './telematicsConfigDetails';
import {FormikHelpers, FormikProps} from 'formik/dist/types';
import {Filter, Sorting} from '@devexpress/dx-react-grid';
import {getStringFromSorting} from '../../../service/Util';
import _ from 'lodash';
import {unstable_batchedUpdates} from 'react-dom';

export interface AdminTelematicsConfigContextProps {
  loading: boolean;

  // list
  list: AdminTelematicsConfigType[];
  listFilters: Filter[];
  listItemTotal: number;
  listPage: number;
  listPageSize: number;
  listPageTotal: number;
  listSorting: Sorting[];
  loadList: () => void;
  onListDelete: (value: any) => void;
  onListFilterChange: (filters: Filter[]) => void;
  onListPageChange: (value: number) => void;
  onListPageSizeChange: (value: number) => void;
  onListSortingChange: (sorting: Sorting[]) => void;

  pageSize: number;
  telematicsConfig?: AdminTelematicsConfigType;
  telematicsConfigId?: number;
  submitting: boolean;
  detailsRef?: React.Ref<FormikProps<AdminTelematicsConfigDetailsFormValues>>;
  onDetailsFormSubmit: (
    values: AdminTelematicsConfigDetailsFormValues,
    formikHelpers: FormikHelpers<AdminTelematicsConfigDetailsFormValues>
  ) => void;
  onPageSizeChange: (value: number) => void;
  setTelematicsConfigId: (value: number | undefined) => void;
}

export const AdminTelematicsConfigContext =
  React.createContext<AdminTelematicsConfigContextProps>({
    // defaults
    loading: false,

    // list
    list: [],
    listFilters: [],
    listItemTotal: 0,
    listPage: 1,
    listPageSize: 25,
    listPageTotal: 1,
    listSorting: [],
    loadList: () => null,
    onListDelete: () => null,
    onListFilterChange: () => null,
    onListPageChange: () => null,
    onListPageSizeChange: () => null,
    onListSortingChange: () => null,

    pageSize: 25,
    submitting: false,

    onDetailsFormSubmit: () => null,
    onPageSizeChange: () => null,
    setTelematicsConfigId: () => null,
  });

interface Props {
  telematicsConfigId?: number;
}

export const AdminTelematicsConfigContextProvider: React.FC<Props> = ({
  children,
}) => {
  const [singleLoading, setSingleLoading] = React.useState<boolean>(false);

  // list
  const [list, setList] = React.useState<AdminTelematicsConfigType[]>([]);
  const [listFilters, setListFilters] = React.useState<Filter[]>([]);
  const [listLoading, setListLoading] = React.useState<boolean>(false);
  const [listPage, setListPage] = React.useState<number>(1);
  const [listPageSize, setListPageSize] = React.useState<number>(25);
  const [listPageTotal, setListPageTotal] = React.useState<number>(1);
  const [listItemTotal, setListItemTotal] = React.useState<number>(0);
  const [listSorting, setListSorting] = React.useState<Sorting[]>([]);

  const [pageSize, setPageSize] = React.useState<number>(25);
  const [telematicsConfig, setTelematicsConfig] = React.useState<
    AdminTelematicsConfigType | undefined
  >();
  const [telematicsConfigId, setTelematicsConfigId] = React.useState<
    number | undefined
  >();
  const [submitting] = React.useState<boolean>(false);

  const detailsRef =
    React.useRef<FormikProps<AdminTelematicsConfigDetailsFormValues>>(null);
  const loadList = React.useCallback(async () => {
    setListLoading(true);
    try {
      const requestObj: ApiAdminTelematicsConfigGetRequest = {
        page: listPage,
        perPage: listPageSize,
      };

      // filters
      listFilters.forEach((f: Filter) => {
        // TODO possible more effective method??
        if (
          f.columnName === 'providerName' ||
          f.columnName === 'mixOrganisationGroupName'
        ) {
          requestObj[f.columnName] = f.value;
        }
      });

      // sorting
      const orderBy = getStringFromSorting(listSorting);
      if (orderBy && orderBy !== '') {
        requestObj.orderBy = orderBy;
      }

      const response =
        await adminTelematicsConfigApi.apiAdminTelematicsConfigGet(requestObj);
      if (response && response.items) {
        setList(response.items);
        setListPageTotal(response.pages ?? 1);
        setListItemTotal(response.total ?? 0);
      } else {
        // TODO handle
      }
    } finally {
      setListLoading(false);
    }
  }, [
    listFilters,
    listPage,
    listPageSize,
    listSorting,
    setList,
    setListLoading,
    setListItemTotal,
  ]);

  const loadTelematicsConfig = React.useCallback(async () => {
    setSingleLoading(true);
    try {
      if (telematicsConfigId) {
        const response =
          await adminTelematicsConfigApi.apiAdminTelematicsConfigTelematicsConfigIdGet(
            {telematicsConfigId}
          );
        setTelematicsConfig(response);
      } else {
        setTelematicsConfig(undefined);
      }
    } finally {
      setSingleLoading(false);
    }
  }, [setSingleLoading, telematicsConfigId, setTelematicsConfig]);

  const handleListPageSizeChange = (value: number) => {
    setListPage(1);
    setListPageSize(value);
  };

  const handleListPageChange = (value: number) => {
    let newPage = value;

    if (value < 0) {
      // lower bound check
      newPage = 0;
    } else if (value > listPageTotal) {
      // upper bound check
      newPage = listPageTotal;
    }

    setListPage(newPage + 1);
  };

  const handleFilterChange = _.debounce((filters: Filter[]) => {
    unstable_batchedUpdates(() => {
      setListPage(1);
      setListFilters(filters);
    });
  }, 500);

  const handleListSortingChange = _.debounce((sorting: Sorting[]) => {
    unstable_batchedUpdates(() => {
      setListPage(1);
      setListSorting(sorting);
    });
  }, 500);

  const value = {
    // list
    list,
    listFilters,
    listItemTotal,
    listPage,
    listPageSize,
    listPageTotal,
    listSorting,
    loadList,
    onListDelete: () => null,
    onListFilterChange: handleFilterChange,
    onListPageChange: handleListPageChange,
    onListPageSizeChange: handleListPageSizeChange,
    onListSortingChange: handleListSortingChange,

    loading: singleLoading || listLoading,
    onPageSizeChange: setPageSize,
    pageSize,
    telematicsConfig,
    telematicsConfigId,
    // setLoading,
    submitting,
    setTelematicsConfigId,
    // details
    detailsRef,
    onDetailsFormSubmit: () => null,
  };

  React.useEffect(() => {
    loadTelematicsConfig();
  }, [loadTelematicsConfig]);

  return (
    <AdminTelematicsConfigContext.Provider value={value}>
      {children}
    </AdminTelematicsConfigContext.Provider>
  );
};
