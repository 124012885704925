import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

const TripEventsStyles = (theme: Theme) =>
  createStyles({
    tabBar: {
      display: 'flex',
      flex: 1,
      borderBottomWidth: 1,
      borderBottomColor: theme.palette.divider,
      borderBottomStyle: 'solid',
    },
  });

export const useTripEventsStyles = makeStyles(TripEventsStyles, {
  name: 'TripEventsStyles',
});
