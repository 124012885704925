import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

const EventMapStyles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: '100',
      height: 800,
      [theme.breakpoints.down('md')]: {height: 700, minWidth: '100%'},
    },
  });

export const useEventMapStyles = makeStyles(EventMapStyles, {
  name: 'EventMapStyles',
});
