import React from 'react';
import _ from 'lodash';
import {
  ITemplateControllerRoute,
  TemplateController,
} from '../../factory/template';
import {
  VehicleTypeContext,
  VehicleTypeContextProvider,
} from './VehicleTypeContext';
import {VehicleTypeList} from './VehicleTypeList';
import {VehicleTypeIdHandler} from './VehicleTypeIdHandler';
import {VehicleTypeForm} from './VehicleTypeForm';
import {VehicleType} from './VehicleType';
import {
  VehicleTypeCapacity,
  VehicleTypeCapacityForm,
  VehicleTypeCapacityContext,
  VehicleTypeCapacityContextProvider,
  VehicleTypeCapacityIdHandler,
} from './vehicleTypeCapacity';
import {
  VehicleTypeCapacityCompartment,
  VehicleTypeCompartmentContext,
  VehicleTypeCompartmentContextProvider,
  VehicleTypeCompartmentForm,
  VehicleTypeCompartmentIdHandler,
} from './vehicleTypeCompartment';

export const VehicleTypeController: React.FC = () => {
  return (
    <VehicleTypeContextProvider>
      <VehicleTypeCapacityContextProvider>
        <VehicleTypeCompartmentContextProvider>
          <VehicleTypeControllerComponent />
        </VehicleTypeCompartmentContextProvider>
      </VehicleTypeCapacityContextProvider>
    </VehicleTypeContextProvider>
  );
};

const VehicleTypeControllerComponent: React.FC = () => {
  const {vehicleType, loading} = React.useContext(VehicleTypeContext);
  const {vehicleTypeCapacity, loading: capacityLoading} = React.useContext(
    VehicleTypeCapacityContext
  );
  const {vehicleTypeCompartment, loading: compartmentLoading} =
    React.useContext(VehicleTypeCompartmentContext);
  const routes: ITemplateControllerRoute[] = React.useMemo(
    () => [
      {title: 'Vehicle Type List', component: VehicleTypeList},
      {
        path: '/add',
        title: 'New Vehicle Type',
        component: () => <VehicleTypeForm isAdd />,
      },
      {
        path: '/:vehicleTypeId',
        title: (params) =>
          loading
            ? 'Vehicle Type Loading...'
            : vehicleType?.name ??
              `Vehicle Type ${_.get(params, 'vehicleTypeId', 'Single')}`,
        component: VehicleType,
        IdHandler: VehicleTypeIdHandler,
      },
      {
        path: '/:vehicleTypeId/capacity/add',
        title: 'New Capacity',
        name: 'Add VehicleCapacityDimension',
        type: 'Add',
        component: () => <VehicleTypeCapacityForm isAdd />,
      },
      {
        path: '/:vehicleTypeId/capacity/:capacityId',
        title: (params) =>
          capacityLoading
            ? 'Capacity Loading...'
            : vehicleTypeCapacity?.name ??
              `Capacity ${_.get(params, 'capacityId', 'Single')}`,
        component: VehicleTypeCapacity,
        IdHandler: VehicleTypeCapacityIdHandler,
      },
      {
        path: '/:vehicleTypeId/compartment/add',
        title: 'New Compartment',
        name: 'Add VehicleCompartment',
        type: 'Add',
        component: () => <VehicleTypeCompartmentForm isAdd />,
      },
      {
        path: '/:vehicleTypeId/compartment/:compartmentId',
        title: (params) =>
          compartmentLoading
            ? 'Compartment Loading...'
            : vehicleTypeCompartment?.name ??
              `Compartment ${_.get(params, 'compartmentId', 'Single')}`,
        component: VehicleTypeCapacityCompartment,
        IdHandler: VehicleTypeCompartmentIdHandler,
      },
    ],
    [
      capacityLoading,
      compartmentLoading,
      loading,
      vehicleType?.name,
      vehicleTypeCapacity?.name,
      vehicleTypeCompartment?.name,
    ]
  );
  return <TemplateController rootPath="/vehicletypelist" routes={routes} />;
};
