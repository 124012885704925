import React from 'react';
import {PlanningBoardGroupOptions} from './PlanningBoardGroupOptions';
import {PlanningBoardGroupOptionContextProvider} from './PlanningBoardGroupOptionsContext';

export const PlanningBoardGroupOptionsController: React.FC = () => {
  return (
    <PlanningBoardGroupOptionContextProvider>
      <PlanningBoardGroupOptions />
    </PlanningBoardGroupOptionContextProvider>
  );
};
