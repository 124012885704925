import * as Yup from 'yup';
import {fixedDurationOptions} from './ReportScheduleForm/parameter';
import {isMoment, Moment} from 'moment';
import {AutocompleteOptionType} from '../autocomplete';
import {NUMBER_ERROR_MESSAGE} from '../../factory/template';

export type parameterSchemaType =
  | AutocompleteOptionType
  | Array<AutocompleteOptionType>
  | Moment
  | string
  | number
  | null;

export const intSchema = Yup.object({
  label: Yup.string(),
  value: Yup.number().typeError(NUMBER_ERROR_MESSAGE).required('Required'),
});
export const intRequiredSchema = Yup.string()
  .matches(/^0$|^-?[1-9]([0-9]+)?$/, 'Value must be an integer 0-9')
  .required('Required');
export const decimalRequiredSchema = Yup.string()
  .matches(
    /^-?([1-9]\d*|\d[.]\d*|[1-9]\d*\.\d*)$/,
    'Value must be an decimal e.g. 0.123'
  )
  .required('Required');
export const arrintSchema = Yup.array(intSchema);
export const arrintRequiredSchema = Yup.array(intSchema)
  .nullable()
  .test('value', 'Select at least one', (v) => (v || []).length > 0);
export const dateSchema = Yup.mixed<Moment>()
  .test('date', 'Invalid Date', (v) => isMoment(v))
  .nullable();
export const daterangeSchema = Yup.object({
  label: Yup.string(),
  value: Yup.string().test('value', 'Select an option', (v) =>
    fixedDurationOptions.map((fd) => fd.value).includes(v)
  ),
});
export const daterangeRequiredSchema = Yup.object({
  label: Yup.string(),
  value: Yup.string()
    .required('Required')
    .test('value', 'Select an option', (v) =>
      fixedDurationOptions.map((fd) => fd.value).includes(v)
    ),
}).required('Required');

export const parameterSchema = Yup.object({
  name: Yup.string(),
  type: Yup.string().required(),
  required: Yup.boolean().required(),
  value: Yup.mixed<parameterSchemaType>()
    .nullable()
    .when(['required', 'type'], {
      // daterange (required)
      is: (required: boolean, type: string) => required && type === 'daterange',
      then: daterangeRequiredSchema,
    })
    .when(['required', 'type'], {
      // daterange (optional)
      is: (required: boolean, type: string) =>
        !required && type === 'daterange',
      then: daterangeSchema,
    })
    .when(['required', 'type'], {
      // arrint (optional)
      is: (required: boolean, type: string) => !required && type === 'arrint',
      then: arrintSchema,
    })
    .when(['required', 'type'], {
      // arrint (required)
      is: (required: boolean, type: string) => required && type === 'arrint',
      then: arrintRequiredSchema,
    })
    .when(['required', 'type'], {
      // int
      is: (required: boolean, type: string) =>
        required && (type === 'int' || type === 'integer'),
      then: intRequiredSchema,
    })
    .when(['required', 'type'], {
      // int
      is: (required: boolean, type: string) =>
        required && (type === 'decimal' || type === 'numeric'),
      then: decimalRequiredSchema,
    })
    .when('type', {
      is: 'date',
      then: dateSchema,
    }),
});

export const scheduleParameterSchema = Yup.object({
  reportScheduleId: Yup.number().typeError(NUMBER_ERROR_MESSAGE).nullable(),
  reportParameterId: Yup.number().typeError(NUMBER_ERROR_MESSAGE).nullable(),
  type: Yup.string().required(),
  required: Yup.boolean().required(),
  value: Yup.mixed<parameterSchemaType>()
    .nullable()
    .when(['required', 'type'], {
      // daterange (required)
      is: (required: boolean, type: string) => required && type === 'daterange',
      then: daterangeRequiredSchema,
    })
    .when(['required', 'type'], {
      // daterange (optional)
      is: (required: boolean, type: string) =>
        !required && type === 'daterange',
      then: daterangeSchema,
    })
    .when(['required', 'type'], {
      // arrint (optional)
      is: (required: boolean, type: string) => !required && type === 'arrint',
      then: arrintSchema,
    })
    .when(['required', 'type'], {
      // arrint (required)
      is: (required: boolean, type: string) => required && type === 'arrint',
      then: arrintRequiredSchema,
    })
    .when('type', {
      is: 'date',
      then: dateSchema,
    }),
});

export type ArrIntSchemaType = ReturnType<typeof arrintSchema.validateSync>;
export type ArrIntRequiredSchemaType = ReturnType<
  typeof arrintRequiredSchema.validateSync
>;
export type DateSchemaType = ReturnType<typeof dateSchema.validateSync>;
export type DateRangeSchemaType = ReturnType<
  typeof daterangeSchema.validateSync
>;
export type DateRangeRequiredSchemaType = ReturnType<
  typeof daterangeRequiredSchema.validateSync
>;
export type IntSchemaType = ReturnType<typeof intSchema.validateSync>;
export type ScheduleParameterSchemaType = ReturnType<
  typeof scheduleParameterSchema.validateSync
>;
export type ParameterSchemaType = ReturnType<
  typeof parameterSchema.validateSync
>;
