import React from 'react';
import {Alert, Stack} from '@mui/material';
import {PlanningBoardTripForm} from './planningBoardTripForm';
import {PlanningBoardTripOrders} from './planningBoardTripOrders';
import {usePlanningBoardTripContext} from './planningBoardTripContext';

export const PlanningBoardTripDetails: React.FC = () => {
  const {constraintErrors, masterTripType} = usePlanningBoardTripContext();

  const isCommittedTrip =
    masterTripType !== undefined && masterTripType !== 'booking';

  return (
    <Stack py={2} gap={2}>
      <PlanningBoardTripForm isCommittedTrip={isCommittedTrip} />
      {constraintErrors && constraintErrors.length > 0 ? (
        <Stack>
          {constraintErrors?.map(({error}) => (
            <Alert key={error} severity="error">
              {error}
            </Alert>
          ))}
        </Stack>
      ) : null}
      <PlanningBoardTripOrders canAdd={!isCommittedTrip} />
    </Stack>
  );
};
