import React from 'react';
import {ISiteDetailsForm, SiteContext} from '../SiteContext';
import {
  getSiteInitialValues,
  SiteDetailsForm,
  siteDetailsSchema,
} from './SiteDetailsForm';
import Grid from '@mui/material/Grid';
import {SiteDetailsMap} from './SiteDetailsMap';
import {TemplateForm} from '../../../factory/template';

interface SiteDetailsContentProps {
  isAdd?: boolean;
}

export const SiteDetailsContent: React.FC<SiteDetailsContentProps> = ({
  isAdd,
}) => {
  const {site, onDetailsFormSubmit, submitting, detailsRef} =
    React.useContext(SiteContext);
  const [initialValues, setInitialValues] = React.useState<ISiteDetailsForm>();

  React.useEffect(() => {
    const values = getSiteInitialValues(site);
    if (values) {
      setInitialValues(values);
    } else if (isAdd) {
      setInitialValues({
        name: '',
        externalReference: '',
        latitude: '',
        longitude: '',
        openTime: new Date(),
        closeTime: new Date(),
        loadingTimeInMinutes: '',
        unloadingTimeInMinutes: '',
        processingTimeInMinutes: '',
        nodeType: {value: undefined, label: undefined},
        planningSkills: [],
      });
    }
  }, [site, setInitialValues, isAdd]);

  if (!initialValues) return <>No Initial Values</>;

  return (
    <TemplateForm<ISiteDetailsForm>
      initialValues={initialValues}
      onSubmit={onDetailsFormSubmit}
      validationSchema={siteDetailsSchema}
      innerRef={detailsRef}
      submitting={submitting}
      permission={{
        name: `${isAdd ? 'Add' : 'Edit'} Site`,
        type: isAdd ? 'Add' : 'Edit',
      }}
    >
      <Grid container spacing={4} direction="row">
        <Grid item xs={12} sm={6} direction="column">
          <SiteDetailsForm isAdd={isAdd} />
        </Grid>
        <Grid item xs={12} sm={6} direction="column">
          <SiteDetailsMap />
        </Grid>
      </Grid>
    </TemplateForm>
  );
};
