import React from 'react';
import _ from 'lodash';
import {unstable_batchedUpdates} from 'react-dom';
import {useHistory} from 'react-router-dom';
import {FormikHelpers, FormikProps} from 'formik/dist/types';
import {
  Report as ReportType,
  ReportCategory,
  ReportParameter,
  ReportUpdate,
  ApiReportGetRequest,
} from '@onroadvantage/onroadvantage-api';
import {Filter, Sorting} from '@devexpress/dx-react-grid';
import {reportApi} from '../../api';
import {IReportForm} from './ReportForm';
import {getStringFromSorting} from '../../service/Util';
import {useAppNotifications} from '../../contexts';

export interface ReportContextProps {
  loading: boolean;

  // list
  list: ReportType[];
  listFilters: Filter[];
  listItemTotal: number;
  listPage: number;
  listPageSize: number;
  listPageTotal: number;
  listSorting: Sorting[];
  loadList: () => void;
  onListDelete: (value: any) => void;
  onListFilterChange: (filters: Filter[]) => void;
  onListPageChange: (value: number) => void;
  onListPageSizeChange: (value: number) => void;
  onListSortingChange: (sorting: Sorting[]) => void;

  pageSize: number;
  report?: ReportType;
  reportId?: number;
  // setLoading: (value: boolean) => void;
  submitting: boolean;
  detailsRef?: React.Ref<FormikProps<IReportForm>>;
  onDetailsFormSubmit: (
    values: IReportForm,
    formikHelpers: FormikHelpers<IReportForm>
  ) => void;
  onPageSizeChange: (value: number) => void;
  setReportId: (value: number | undefined) => void;
}

export const ReportContext = React.createContext<ReportContextProps>({
  // defaults
  loading: false,

  // list
  list: [],
  listFilters: [],
  listItemTotal: 0,
  listPage: 1,
  listPageSize: 25,
  listPageTotal: 1,
  listSorting: [],
  loadList: () => null,
  onListDelete: () => null,
  onListFilterChange: () => null,
  onListPageChange: () => null,
  onListPageSizeChange: () => null,
  onListSortingChange: () => null,

  pageSize: 25,
  submitting: false,

  onDetailsFormSubmit: () => null,
  onPageSizeChange: () => null,
  // setLoading: () => null,
  setReportId: () => null,
});

interface Props {
  reportId?: number;
}

export const ReportContextProvider: React.FC<Props> = ({children}) => {
  const history = useHistory();
  const notify = useAppNotifications();
  const [singleLoading, setSingleLoading] = React.useState<boolean>(false);

  // list
  const [list, setList] = React.useState<ReportType[]>([]);
  const [listFilters, setListFilters] = React.useState<Filter[]>([]);
  const [listLoading, setListLoading] = React.useState<boolean>(false);
  const [listPage, setListPage] = React.useState<number>(1);
  const [listPageSize, setListPageSize] = React.useState<number>(25);
  const [listPageTotal, setListPageTotal] = React.useState<number>(1);
  const [listItemTotal, setListItemTotal] = React.useState<number>(0);
  const [listSorting, setListSorting] = React.useState<Sorting[]>([]);

  const [pageSize, setPageSize] = React.useState<number>(25);
  const [report, setReport] = React.useState<ReportType | undefined>();
  const [reportId, setReportId] = React.useState<number | undefined>();
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const detailsRef = React.useRef<FormikProps<IReportForm>>(null);

  const loadList = React.useCallback(async () => {
    setListLoading(true);
    try {
      const requestObj: ApiReportGetRequest = {
        page: listPage,
        perPage: listPageSize,
      };

      // filters
      listFilters.forEach((f) => {
        // TODO find a more effective way to handle filters
        switch (f.columnName) {
          case 'name':
            requestObj.name = f.value;
            break;
          case 'version':
            requestObj.version = f.value;
            break;
          case 'description':
            requestObj.description = f.value;
            break;
        }
      });

      // sorting
      const orderBy = getStringFromSorting(listSorting);
      if (orderBy && orderBy !== '') {
        requestObj.orderBy = orderBy;
      }

      const response = await reportApi.apiReportGet(requestObj);
      if (response && response.items) {
        setList(response.items);
        setListPageTotal(response.pages ?? 1);
        setListItemTotal(response.total ?? 0);
      } else {
        // TODO handle
      }
    } finally {
      setListLoading(false);
    }
  }, [
    listFilters,
    listPage,
    listPageSize,
    listSorting,
    setList,
    setListLoading,
    setListItemTotal,
  ]);

  const loadReport = React.useCallback(async () => {
    setSingleLoading(true);
    try {
      if (reportId) {
        const response = await reportApi.apiReportReportIdGet({reportId});
        setReport(response);
      } else {
        setReport(undefined);
      }
    } finally {
      setSingleLoading(false);
    }
  }, [setSingleLoading, reportId, setReport]);

  const handleDetailsFormSubmit = React.useCallback(
    async (
      {reportCategories, reportParameters, reportType, ...values}: IReportForm,
      formikHelpers: FormikHelpers<IReportForm>
    ) => {
      setSubmitting(true);

      try {
        const newValues: ReportUpdate = {
          ...values,
        };

        if (reportType && reportType.label) {
          newValues.reportType = reportType.label;
        }

        if (reportCategories) {
          const categories: ReportCategory[] = [];
          reportCategories.forEach((r) => {
            if (r.value) {
              categories.push({id: parseInt(`${r.value}`)});
            }
          });
          newValues.reportCategories = categories;
        }
        if (reportParameters) {
          const parameters: ReportParameter[] = [];
          reportParameters.forEach((r) => {
            if (r.value) {
              parameters.push({id: parseInt(`${r.value}`)});
            }
          });
          newValues.reportParameters = parameters;
        }

        if (report && report.id) {
          await reportApi.apiReportReportIdPatch({
            reportId: report.id,
            body: {
              ...newValues,
            },
          });
        } else {
          await reportApi.apiReportPost({
            body: {
              ...newValues,
            },
          });
        }
        history.push('/reportlist');
        notify('success', `${report?.id ? 'Updated' : 'Added'} Report`);
      } finally {
        formikHelpers.setSubmitting(false);
        setSubmitting(false);
      }
    },
    [report, history, notify]
  );

  const handleListPageSizeChange = (value: number) => {
    setListPage(1);
    setListPageSize(value);
  };

  const handleListPageChange = (value: number) => {
    let newPage = value;

    if (value < 0) {
      // lower bound check
      newPage = 0;
    } else if (value > listPageTotal) {
      // upper bound check
      newPage = listPageTotal;
    }

    setListPage(newPage + 1);
  };

  const handleFilterChange = _.debounce((filters: Filter[]) => {
    unstable_batchedUpdates(() => {
      setListPage(1);
      setListFilters(filters);
    });
  }, 500);

  const handleListSortingChange = _.debounce((sorting: Sorting[]) => {
    unstable_batchedUpdates(() => {
      setListPage(1);
      setListSorting(sorting);
    });
  }, 500);

  const handleListDelete = async (row: any) => {
    setListLoading(true);
    try {
      await reportApi.apiReportReportIdDelete({reportId: row.id});
      await loadList();
      notify('success', 'Report Deleted');
    } catch (e) {
      notify('error', 'Failed to delete report');
    } finally {
      setListLoading(false);
    }
  };

  const value = {
    // list
    list,
    listFilters,
    listItemTotal,
    listPage,
    listPageSize,
    listPageTotal,
    listSorting,
    loadList,
    onListDelete: handleListDelete,
    onListFilterChange: handleFilterChange,
    onListPageChange: handleListPageChange,
    onListPageSizeChange: handleListPageSizeChange,
    onListSortingChange: handleListSortingChange,

    loading: singleLoading || listLoading,
    onPageSizeChange: setPageSize,
    pageSize,
    report,
    reportId,
    // setLoading,
    submitting,
    setReportId,
    // details
    detailsRef,
    onDetailsFormSubmit: handleDetailsFormSubmit,
    // generate
    // schedule
  };

  React.useEffect(() => {
    loadReport();
  }, [loadReport]);

  return (
    <ReportContext.Provider value={value}>{children}</ReportContext.Provider>
  );
};
