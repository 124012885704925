import {makeStyles, createStyles} from '@mui/styles';

const DriverControllerStyles = () => {
  return createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      minHeight: 10,
    },
    root: {},
  });
};

export const useDriverControllerStyles = makeStyles(DriverControllerStyles);
