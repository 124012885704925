import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {Paper} from '@mui/material';
import {useReportControllerStyles} from './ReportController.style';
import {ReportList} from './ReportList';
import {
  ReportContext,
  ReportContextProps,
  ReportContextProvider,
} from './ReportContext';
import {Report} from './Report';
import {
  ReportSchedule,
  ReportScheduleContext,
  ReportScheduleContextProps,
  ReportScheduleContextProvider,
} from './reportSchedule';
import {
  BreadcrumbController,
  BreadcrumbRoute,
} from '../breadcrumbs/BreadcrumbController';
import _ from 'lodash';
import {ReportIdHandler} from './ReportIdHandler';
import {ReportScheduleIdHandler} from './reportSchedule';
import {ReportScheduleAdd} from './ReportScheduleAdd';
import {AllowedRoute} from '../router';
import {ReportForm} from './ReportForm';

export const ReportControllerComponent: React.FC = () => {
  const classes = useReportControllerStyles();
  const {report} = React.useContext<ReportContextProps>(ReportContext);
  const {reportSchedule} = React.useContext<ReportScheduleContextProps>(
    ReportScheduleContext
  );

  const routes: BreadcrumbRoute[] = [
    {path: '/reportlist', matchOptions: undefined, title: 'Report List'},
    {path: '/reportlist/add', matchOptions: undefined, title: 'New Report'},
    {
      path: '/reportlist/:reportId',
      matchOptions: undefined,
      title: (params) =>
        report?.name ?? `Report ${_.get(params, 'reportId', 'Single')}`,
    },
    {
      path: '/reportlist/:reportId/schedule/add',
      matchOptions: undefined,
      title: 'New Report Schedule',
    },
    {
      path: '/reportlist/:reportId/schedule/:reportScheduleId',
      matchOptions: undefined,
      title: (params) =>
        reportSchedule?.name ??
        `Report Schedule ${_.get(params, 'reportScheduleId', 'Single')}`,
    },
  ];

  return (
    <div className={classes.root}>
      <BreadcrumbController routes={routes} />
      <Paper className={classes.container}>
        <Route exact path="/reportlist" component={ReportList} />
        <Route path="/reportlist/:reportId" component={ReportIdHandler} />
        <Switch>
          <AllowedRoute
            exact
            path="/reportlist/add"
            name="Add Report"
            type="Add"
            component={() => <ReportForm isAdd />}
          />
          <Route exact path="/reportlist/:reportId" component={Report} />
        </Switch>
        <Route
          path="/reportList/:reportId/schedule/:reportScheduleId"
          component={ReportScheduleIdHandler}
        />
        <Switch>
          <AllowedRoute
            exact
            path="/reportList/:reportId/schedule/add"
            name="Add ReportSchedule"
            type="Add"
            component={ReportScheduleAdd}
          />
          <Route
            exact
            path="/reportList/:reportId/schedule/:reportScheduleId"
            component={ReportSchedule}
          />
        </Switch>
      </Paper>
    </div>
  );
};

export const ReportController: React.FC = () => {
  return (
    <ReportContextProvider>
      <ReportScheduleContextProvider>
        <ReportControllerComponent />
      </ReportScheduleContextProvider>
    </ReportContextProvider>
  );
};
