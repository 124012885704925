import React from 'react';
import {useTrip, useTripResponse, useTripResponseInitial} from './useTrip';
import {timelineDisplayStore} from '../../../stores/mobxStores';
import {useLocation} from 'react-router-dom';

export type TripContextProps = useTripResponse;

export const TripContext = React.createContext<TripContextProps>(
  useTripResponseInitial
);

export const TripContextProvider: React.FC = ({children}) => {
  const {pathname} = useLocation();
  const {setTelematicsEventsDateRange} = timelineDisplayStore;
  const tripContext = useTrip({setTelematicsEventsDateRange});
  const memoizedTripContext = React.useMemo(() => tripContext, [tripContext]);

  const {cleanupState, masterTripId, loadInitialData} = memoizedTripContext;

  React.useEffect(() => {
    if (pathname === '/triplist') {
      /**
       * When the user navigates back to the trip list view, we need to reset all the previous trip's states,
       * otherwise it will keep the previous trip's state when you navigate into the new trip.
       */
      cleanupState();
    }
  }, [cleanupState, pathname]);

  React.useEffect(() => {
    if (masterTripId) {
      loadInitialData();
    }
    /** Only load the initialData on first render when it has a masterTripId */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterTripId]);

  return (
    <TripContext.Provider value={memoizedTripContext}>
      {children}
    </TripContext.Provider>
  );
};

export * from './useTrip';

export const useTripContext = () => React.useContext(TripContext);
