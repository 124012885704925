import React from 'react';
import {VehicleTypeContext} from '../VehicleTypeContext';
import {VehicleTypeCompartmentForm} from './vehicleTypeCompartmentForm';
import {VehicleTypeCompartmentContext} from './VehicleTypeCompartmentContext';
import {
  ITemplateTabControllerTab,
  TemplateTabs,
} from '../../../factory/template';

export const VehicleTypeCapacityCompartment: React.FC = () => {
  const {vehicleTypeId} = React.useContext(VehicleTypeContext);
  const {loading, vehicleTypeCompartmentId} = React.useContext(
    VehicleTypeCompartmentContext
  );

  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {title: 'Compartment Details', element: <VehicleTypeCompartmentForm />},
  ]);

  const path = React.useMemo(
    () =>
      `/vehicletypelist/${vehicleTypeId}/compartment/${vehicleTypeCompartmentId}`,
    [vehicleTypeCompartmentId, vehicleTypeId]
  );

  return <TemplateTabs path={path} tabs={tabs} loading={loading} />;
};
