import React from 'react';
import {
  MasterTripDebriefSnapshot,
  MasterTripDebriefTaskSnapshot,
  MasterTripDebriefTripStopSnapshot,
} from '@onroadvantage/onroadvantage-api';
import {TemplateTable, TemplateTableConfig} from '../../../factory/template';
import {TripDebriefContext} from '../TripDebriefContext';

interface TripDebriefTaskListProps {
  row: MasterTripDebriefTripStopSnapshot;
}

export const TripDebriefTaskList: React.FC<TripDebriefTaskListProps> = ({
  row,
}) => {
  const {masterTripDebriefData} = React.useContext(TripDebriefContext);
  /**
   *  Destructure snapshotData, need to add || ({} as MasterTripDebriefSnapshot) for typescript, since
   *  snapshotData is nullable.
   */
  const {driver, tasks} =
    masterTripDebriefData?.snapshotData || ({} as MasterTripDebriefSnapshot);

  const getTaskDetails = React.useCallback(
    (taskId: number | null | undefined) => {
      if (typeof taskId !== 'number') return undefined;

      return tasks?.find(({id}) => id === taskId);
    },
    [tasks]
  );

  const list: MasterTripDebriefTaskSnapshot[] = React.useMemo(() => {
    const returnTasks: MasterTripDebriefTaskSnapshot[] = [];

    row.tasks?.forEach(({id}) => {
      const task = getTaskDetails(id);
      if (task) returnTasks.push(task);
    });

    return returnTasks.sort((a, b) =>
      typeof a.sequence === 'number' && typeof b.sequence === 'number'
        ? a.sequence - b.sequence
        : !a.sequence && b.sequence
        ? 1
        : a.sequence && !b.sequence
        ? -1
        : 0
    );
  }, [getTaskDetails, row]);

  const config: TemplateTableConfig<MasterTripDebriefTaskSnapshot> =
    React.useMemo(
      () => ({
        columns: [
          {
            name: 'templateName',
            label: 'Name',
            type: 'string',
            getValue: ({template}) => template?.name,
          },
          {
            name: 'templateTypeName',
            label: 'Description',
            type: 'string',
            getValue: ({template}) => template?.type?.name,
          },
          {
            name: 'driverTagNumber',
            label: 'Driver Tag',
            type: 'string',
            getValue: () => driver?.externalExtendedId,
          },
          {name: 'sequence', label: 'Sequence', type: 'number'},
          {name: 'status', label: 'Status', type: 'string'},
          {name: 'eventDate', label: 'Completed At', type: 'datetime'},
        ],
        disablePagination: true,
        disableToolbar: true,
        identifier: 'TRIP_DEBRIEF_TASK_LIST',
      }),
      [driver]
    );

  return (
    <TemplateTable
      config={config}
      currentPage={1}
      list={list}
      elevation={0}
      nestedLevel={1}
    />
  );
};
