import React from 'react';
import {
  WebWorkflowOptimised,
  WorkflowConfig,
  WorkflowConfigPost,
  ApiWorkflowConfigGetRequest,
  WorkflowConfigListResponse,
  WorkflowConfigOptimisedList,
} from '@onroadvantage/onroadvantage-api';
import {workflowConfigApi} from '../../api';
import {TemplateTableContextProps} from '../../factory/template';
import {useTemplateTable, TLoadList} from '../../factory/template';
import {useAppNotifications} from '../../contexts';
import {useHistory} from 'react-router-dom';
import {FormikHelpers} from 'formik/dist/types';
import {IWorkflowConfigForm} from './WorkflowConfigForm';

export interface WorkflowConfigContextProps
  extends TemplateTableContextProps<
    WorkflowConfigOptimisedList,
    WorkflowConfigListResponse
  > {
  loadWorkflowConfig: () => Promise<void>;
  onDetailsFormSubmit: (
    values: IWorkflowConfigForm,
    formikHelpers: FormikHelpers<IWorkflowConfigForm>
  ) => void;
  workflowConfig: WorkflowConfig | undefined;
  workflowConfigId: number | undefined;
  setWorkflowConfigId: React.Dispatch<React.SetStateAction<number | undefined>>;
  submitting: boolean;
  loadingSingleItem: boolean;
}

export const WorkflowConfigContext =
  React.createContext<WorkflowConfigContextProps>({
    // Template Table Defaults
    loading: false,
    list: [],
    currentPage: 1,
    loadList: async () => {},
    // Workflow config
    loadWorkflowConfig: async () => {},
    onDetailsFormSubmit: () => null,
    workflowConfig: undefined,
    workflowConfigId: undefined,
    submitting: false,
    loadingSingleItem: false,
    setWorkflowConfigId: () => null,
  });

export const WorkflowConfigContextProvider: React.FC = ({children}) => {
  const notify = useAppNotifications();
  const history = useHistory();
  // Template Table
  const [
    {
      currentPage,
      pageSize,
      pageTotal,
      sorting,
      hasPermission,
      itemTotal,
      list,
      loading,
      loadingSingleItem,
      filters,
      isDaterangeFilterActive,
    },
    {
      // Getters
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
      setLoadingSingleItem,
    },
  ] = useTemplateTable<
    WorkflowConfigOptimisedList,
    ApiWorkflowConfigGetRequest
  >({
    enableDateRangeFilter: true,
    editPermission: 'Edit WorkflowConfig',
    addPermission: 'Add WorkflowConfig',
    deletePermission: 'Delete WorkflowConfig',
    downloadPermission: 'WorkflowConfig ListDownload',
    viewPermission: 'WorkflowConfig List',
  });

  const loadList = React.useCallback<TLoadList<WorkflowConfigListResponse>>(
    async (options) => {
      setLoading(true);
      try {
        return getResponse(
          await workflowConfigApi.apiWorkflowConfigGet(
            getRequestObj(['name', 'taskTypeName', 'contracts'], options)
          ),
          options
        );
      } catch (e) {
        notify('error', e.message ?? 'Failed to load WorkflowConfig List');
      } finally {
        setLoading(false);
      }
    },
    [getRequestObj, getResponse, notify, setLoading]
  );

  const handleRefresh = React.useCallback(async () => {
    await loadList();
  }, [loadList]);

  const handleDelete = React.useCallback(
    async (row: WebWorkflowOptimised) => {
      setLoading(true);
      try {
        if (row.id) {
          await workflowConfigApi.apiWorkflowConfigWorkflowConfigIdDelete({
            workflowConfigId: row.id,
          });
          notify('success', 'Deleted Workflow');
          await loadList();
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to delete Workflow');
      } finally {
        setLoading(false);
      }
    },
    [loadList, notify, setLoading]
  );

  const handleAdd = React.useCallback(() => {
    history.push(`/workflowconfiglist/add`);
  }, [history]);

  const handleNavigate = React.useCallback(
    (row: WebWorkflowOptimised) => {
      history.push(`/workflowconfiglist/${row.id}`);
    },
    [history]
  );

  //Workflow config
  const [workflowConfig, setWorkflowConfig] = React.useState<
    WorkflowConfig | undefined
  >();
  const [workflowConfigId, setWorkflowConfigId] = React.useState<
    number | undefined
  >();
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const handleLoadWorkflowConfig = React.useCallback(async () => {
    setLoadingSingleItem(true);
    try {
      if (workflowConfigId) {
        const response =
          await workflowConfigApi.apiWorkflowConfigWorkflowConfigIdGet({
            workflowConfigId,
          });
        setWorkflowConfig(response);
      }
    } catch (e) {
      notify('error', e.message ?? 'Failed to load workflow config');
    } finally {
      setLoadingSingleItem(false);
    }
  }, [notify, setLoadingSingleItem, workflowConfigId]);

  const handleDetailsFormSubmit = React.useCallback(
    async (
      values: IWorkflowConfigForm,
      formikHelpers: FormikHelpers<IWorkflowConfigForm>
    ) => {
      setSubmitting(true);
      try {
        const {name, taskType, contracts} = values;

        if (taskType.value) {
          const newValues: WorkflowConfigPost = {
            name,
            taskTypeId: taskType.value,
            contractIds: contracts?.map(({value}) => value as number) ?? [],
          };
          if (workflowConfigId) {
            await workflowConfigApi.apiWorkflowConfigWorkflowConfigIdPatch({
              workflowConfigId,
              body: newValues,
            });
          } else {
            await workflowConfigApi.apiWorkflowConfigPost({
              body: newValues,
            });
          }
          history.push('/workflowconfiglist');
          notify(
            'success',
            `${workflowConfigId ? 'Updated' : 'Added'} Workflow Config`
          );
        }
      } catch (e) {
        notify(
          'error',
          e.message ??
            `Failed to ${workflowConfigId ? 'update' : 'add'} Workflow Config`
        );
      } finally {
        formikHelpers.setSubmitting(false);
        setSubmitting(false);
      }
    },
    [history, notify, workflowConfigId]
  );

  React.useEffect(() => {
    handleLoadWorkflowConfig();
    return () => setWorkflowConfig(undefined);
  }, [handleLoadWorkflowConfig]);

  const value: WorkflowConfigContextProps = {
    // Template Table
    list,
    loadList,
    hasPermission,
    loading,
    cleanupList,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    isDaterangeFilterActive,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onAdd: hasPermission.add ? handleAdd : undefined,
    onDelete: hasPermission.delete ? handleDelete : undefined,
    onNavigate: handleNavigate,
    onRefresh: handleRefresh,
    // Workflow config
    loadWorkflowConfig: handleLoadWorkflowConfig,
    onDetailsFormSubmit: handleDetailsFormSubmit,
    workflowConfig,
    workflowConfigId,
    submitting,
    loadingSingleItem,
    setWorkflowConfigId,
  };

  return (
    <WorkflowConfigContext.Provider value={value}>
      {children}
    </WorkflowConfigContext.Provider>
  );
};
