import React from 'react';
import {useHistory} from 'react-router-dom';
import {
  WebMasterTripDebriefConfigListResponse,
  ApiWebMasterTripDebriefConfigGetRequest,
  WebMasterTripDebriefConfigList,
} from '@onroadvantage/onroadvantage-api';
import {
  masterTripDebriefConfigApi,
  webMasterTripDebriefConfigApi,
} from '../../../api';
import {
  TemplateTableContextProps,
  TLoadList,
  useTemplateTable,
} from '../../../factory/template';
import {useAppNotifications} from '../../../contexts';

export type TripDebriefConfigListContextProps = TemplateTableContextProps<
  WebMasterTripDebriefConfigList,
  WebMasterTripDebriefConfigListResponse
>;

export const TripDebriefConfigListContext =
  React.createContext<TripDebriefConfigListContextProps>({
    loading: false,
    list: [],
    currentPage: 1,
    loadList: () => null,
  });

export const useTripDebriefConfigListContext = () =>
  React.useContext(TripDebriefConfigListContext);

export const TripDebriefConfigListContextProvider: React.FC = ({children}) => {
  const notify = useAppNotifications();
  const history = useHistory();
  // Template Table
  const [
    {
      // States
      currentPage,
      filters,
      hasPermission,
      itemTotal,
      list,
      loading,
      pageSize,
      pageTotal,
      sorting,
    },
    {
      // Getters
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
    },
  ] = useTemplateTable<
    WebMasterTripDebriefConfigList,
    ApiWebMasterTripDebriefConfigGetRequest
  >({
    addPermission: 'Add MasterTripDebriefConfig',
    editPermission: 'Edit MasterTripDebriefConfig',
    deletePermission: 'Delete MasterTripDebriefConfig',
    viewPermission: 'MasterTripDebriefConfig List',
  });

  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const loadList = React.useCallback<
    TLoadList<WebMasterTripDebriefConfigListResponse>
  >(
    async (options) => {
      setLoading(true);
      try {
        const requestObj = getRequestObj(
          ['name', 'contractCode', 'tatThreshold', 'varianceThreshold'],
          options
        );
        const response =
          await webMasterTripDebriefConfigApi.apiWebMasterTripDebriefConfigGet(
            requestObj
          );
        return getResponse(response, options);
      } catch (e) {
        notify('error', 'Failed to load trip debrief config list');
      } finally {
        setLoading(false);
      }
    },
    [setLoading, getRequestObj, getResponse, notify]
  );

  const handleDelete = React.useCallback(
    async (row: WebMasterTripDebriefConfigList) => {
      setSubmitting(true);
      try {
        if (row.id) {
          await masterTripDebriefConfigApi.apiMasterTripDebriefConfigMasterTripDebriefConfigIdDelete(
            {
              masterTripDebriefConfigId: row.id,
            }
          );
          notify('success', 'Deleted trip debrief config');
          await loadList();
        }
      } catch (e) {
        notify('error', 'Failed to delete trip debrief config');
      } finally {
        setSubmitting(false);
      }
    },
    [loadList, notify]
  );

  const handleAdd = React.useCallback(async () => {
    history.push('/tripdebriefconfiglist/add');
  }, [history]);

  const handleNavigate = React.useCallback(
    async (row: WebMasterTripDebriefConfigList) => {
      history.push(`/tripdebriefconfiglist/${row.id}`);
    },
    [history]
  );

  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  const value: TripDebriefConfigListContextProps = {
    loadList,
    cleanupList,
    hasPermission,
    loading: submitting || loading,
    list,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onAdd: hasPermission.add ? handleAdd : undefined,
    onNavigate: handleNavigate,
    onDelete: hasPermission.delete ? handleDelete : undefined,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onRefresh: handleRefresh,
  };

  return (
    <TripDebriefConfigListContext.Provider value={value}>
      {children}
    </TripDebriefConfigListContext.Provider>
  );
};
