import React, {useState} from 'react';
import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
} from '@mui/material';
import {VantageDialogButton} from '../../dialog';
import {RoleService} from '../../../service';
import {WebMasterTripDebriefListMasterTrip} from '@onroadvantage/onroadvantage-api';

interface TripDebriefToolbarActionsProps {
  loading: boolean;
  selection: WebMasterTripDebriefListMasterTrip[];
  onApprove: () => void;
  onUnApprove: () => void;
  onCancel: () => void;
  onDelete: () => void;
}

export const TripDebriefToolbarActions: React.FC<
  TripDebriefToolbarActionsProps
> = ({loading, selection, onApprove, onUnApprove, onCancel, onDelete}) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const selectionCanApprove = React.useMemo(
    () =>
      !!selection.find(
        ({masterTripDebriefApprovedForBilling}) =>
          !masterTripDebriefApprovedForBilling
      ),
    [selection]
  );

  const selectionCanUnApprove = React.useMemo(
    () =>
      !!selection.find(
        ({masterTripDebriefApprovedForBilling}) =>
          masterTripDebriefApprovedForBilling !== false
      ),
    [selection]
  );

  const handleBulkDelete = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteConfirmed = () => {
    setIsDeleteDialogOpen(false);
    onDelete();
  };

  const handleDeleteCanceled = () => {
    setIsDeleteDialogOpen(false);
  };

  return (
    <Collapse in={selection.length > 0}>
      <Stack direction="row" spacing={1}>
        <Button
          disabled={
            !RoleService.hasExactPermission('BulkDelete Trips', 'Delete') ||
            loading
          }
          onClick={handleBulkDelete}
          size="small"
          color="error"
          variant="outlined"
        >
          Bulk Delete
        </Button>
        {selectionCanApprove ? (
          <VantageDialogButton
            disabled={
              !RoleService.hasPermission('Multi Trip Debrief Approve', 'Edit')
            }
            title="Approve"
            dialogTitle="Are you sure you want to approve the selected trips?"
            onAccept={onApprove}
            variant="contained"
            color="primary"
            size="small"
          />
        ) : null}
        {selectionCanUnApprove ? (
          <VantageDialogButton
            disabled={
              !RoleService.hasPermission(
                'Multi Trip Debrief Approve',
                'Edit'
              ) && !RoleService.hasPermission('Trip Debrief Unapprove', 'Edit')
            }
            title="UnApprove"
            dialogTitle="Are you sure you want to un-approve the selected trips?"
            onAccept={onUnApprove}
            variant={selectionCanApprove ? 'outlined' : 'contained'}
            size="small"
            color="primary"
          />
        ) : null}
        <Button disabled={loading} onClick={onCancel} size="small">
          Cancel
        </Button>
        <Divider orientation="vertical" flexItem />
      </Stack>

      <Dialog open={isDeleteDialogOpen} onClose={handleDeleteCanceled}>
        <DialogTitle>Confirm Bulk Delete</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete {selection.length} trip(s)?</p>
        </DialogContent>
        <DialogActions sx={{display: 'flex', justifyContent: 'space-around'}}>
          <Button onClick={handleDeleteConfirmed} variant="text" color="error">
            Yes
          </Button>
          <Button onClick={handleDeleteCanceled} color="inherit">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Collapse>
  );
};
