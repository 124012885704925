import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

const RecipientMapStyles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: '100%',
      minHeight: '100%',
      height: 750,
      [theme.breakpoints.down('md')]: {height: 400, minWidth: '100%'},
    },
  });

export const useRecipientMapStyles = makeStyles(RecipientMapStyles, {
  name: 'RecipientMapStyles',
});
