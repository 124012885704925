import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

const TemplateStepperContentStyles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(2),
    },
    content: {
      backgroundColor: theme.palette.background.paper,
    },
  });

export const useTemplateStepperContentStyles = makeStyles(
  TemplateStepperContentStyles,
  {name: 'TemplateStepperContentStyles'}
);
