import React from 'react';
import {
  useTripDebriefConfig,
  useTripDebriefConfigResponse,
  useTripDebriefConfigResponseInitial,
} from './useTripDebriefConfig';

export type TripDebriefConfigContextProps = useTripDebriefConfigResponse;

export const TripDebriefConfigContext =
  React.createContext<TripDebriefConfigContextProps>(
    useTripDebriefConfigResponseInitial
  );

export const TripDebriefConfigContextProvider: React.FC = ({children}) => {
  const value = useTripDebriefConfig();

  const {loadTripDebriefConfig, setMasterTripDebriefConfig} = value;

  React.useEffect(() => {
    loadTripDebriefConfig();
    return () => {
      setMasterTripDebriefConfig(undefined);
    };
  }, [loadTripDebriefConfig, setMasterTripDebriefConfig]);

  return (
    <TripDebriefConfigContext.Provider value={value}>
      {children}
    </TripDebriefConfigContext.Provider>
  );
};

export const useTripDebriefConfigContext = () =>
  React.useContext(TripDebriefConfigContext);
