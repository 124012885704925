import React from 'react';
import {
  ApiNodeMainNodeIdAssociateNodeGetRequest,
  AssociateNode,
  AssociateNodeList,
  AssociateNodePost,
} from '@onroadvantage/onroadvantage-api';
import {useAppNotifications} from '../../../contexts';
import {nodeApi} from '../../../api';
import {
  TemplateTableContextProps,
  TLoadList,
  TOnInlineAdd,
  TOnInlineEdit,
  useTemplateTable,
} from '../../../factory/template';
import {SiteContext} from '../SiteContext';

export interface SitePostContextProps
  extends TemplateTableContextProps<AssociateNode, AssociateNodeList> {
  associateNode: AssociateNode | undefined;
  associateNodeId: number | undefined;
  setAssociateNodeId: React.Dispatch<React.SetStateAction<number | undefined>>;
  submitting: boolean;
  loadingSingleItem: boolean;
  loadAssociateNode: () => Promise<void>;
}

export const SitePostContext = React.createContext<SitePostContextProps>({
  // Template Table Defaults
  loading: false,
  list: [],
  currentPage: 1,
  loadList: async () => {},

  // Post-Site
  loadAssociateNode: async () => {},
  associateNode: undefined,
  associateNodeId: undefined,
  setAssociateNodeId: () => null,
  submitting: false,
  loadingSingleItem: false,
});

interface SitePostContextProviderProps {
  associateNodeId?: number;
}

export const SitePostContextProvider: React.FC<
  SitePostContextProviderProps
> = ({children}) => {
  const notify = useAppNotifications();
  const {siteId, site} = React.useContext(SiteContext);
  // Template Table
  const [
    {
      // States
      currentPage,
      filters,
      itemTotal,
      hasPermission,
      list,
      loading,
      loadingSingleItem,
      pageSize,
      pageTotal,
      sorting,
    },
    {
      // Getters
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
      setLoadingSingleItem,
    },
  ] = useTemplateTable<AssociateNode, ApiNodeMainNodeIdAssociateNodeGetRequest>(
    {
      editPermission: 'Edit Site',
      addPermission: 'Add Site',
      deletePermission: 'Delete Site',
      downloadPermission: 'Site ListDownload',
      viewPermission: 'Site List',
    }
  );

  const loadList = React.useCallback<TLoadList<AssociateNodeList>>(
    async (options) => {
      setLoading(true);
      try {
        if (siteId) {
          const requestObj = getRequestObj(
            ['taskTemplateNodeType', 'mainNodeId', 'isPostNode'],
            options,
            {
              mainNodeId: siteId,
              isPostNode: true,
            }
          );
          const response = await nodeApi.apiNodeMainNodeIdAssociateNodeGet({
            ...requestObj,
          });
          return getResponse(response, options);
        }
      } catch (e) {
        notify(
          'error',
          e.message ?? 'Failed to load post site Associated Node list'
        );
      } finally {
        setLoading(false);
      }
    },
    [getRequestObj, getResponse, notify, setLoading, siteId]
  );

  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const handleDelete = React.useCallback(
    async (row: AssociateNode) => {
      setLoading(true);
      try {
        if (siteId && row.id) {
          await nodeApi.apiAssociateNodeAssociateNodeIdDelete({
            associateNodeId: row.id,
          });
          await loadList();
          notify('success', 'Deleted Associated node');
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to delete Associated node');
      } finally {
        setLoading(false);
      }
    },
    [loadList, notify, setLoading, siteId]
  );

  const handleInlineAdd = React.useCallback<TOnInlineAdd>(
    async (changes) => {
      setLoading(true);
      try {
        const values = changes[0];

        if (
          siteId &&
          site &&
          values.node?.value &&
          values.taskTemplateNodeType?.label
        ) {
          const requestObj: AssociateNodePost = {
            nodeId: values.node.value,
            isPostNode: true,
            sequence: values.sequence ?? list.length + 1,
            taskTemplateNodeType: values.taskTemplateNodeType?.label,
            serviceType: values.serviceType ?? site?.type,
          };

          await nodeApi.apiNodeMainNodeIdAssociateNodePost({
            mainNodeId: siteId,
            body: requestObj,
          });

          notify('success', 'Successfully linked Associate Node.');
          await loadList();
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to link Associate Node.');
      } finally {
        setLoading(false);
      }
    },
    [loadList, setLoading, siteId, site, list.length, notify]
  );

  const handleInlineEdit = React.useCallback<TOnInlineEdit>(
    async (changes) => {
      setSubmitting(true);
      try {
        for (const item of changes) {
          const associateNodeId = parseInt(item.id);
          const values = item.newValues as Partial<AssociateNode>;
          const requestObj: {
            [key: string]: unknown;
          } = {};
          Object.keys(values).forEach((key) => {
            requestObj[key] =
              values[key as keyof Partial<AssociateNode>] ?? undefined;
          });
          if (associateNodeId) {
            await nodeApi.apiAssociateNodeAssociateNodeIdPatch({
              associateNodeId,
              body: requestObj,
            });
            notify('success', 'successfully edited Post Associate Node.');
          }
        }
        await loadList();
      } catch (e) {
        notify('error', e.message ?? 'Failed to edit Post Associate Node.');
      } finally {
        setSubmitting(false);
      }
    },
    [loadList, notify]
  );

  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  // Associate Node
  const [associateNode, setAssociateNode] = React.useState<
    AssociateNode | undefined
  >();
  const [associateNodeId, setAssociateNodeId] = React.useState<
    number | undefined
  >();

  const handleLoadAssociateNode = React.useCallback(async () => {
    setLoadingSingleItem(true);
    try {
      if (siteId && associateNodeId) {
        const response = await nodeApi.apiAssociateNodeAssociateNodeIdGet({
          associateNodeId: associateNodeId,
        });
        setAssociateNode(response);
      }
    } catch (e) {
      notify('error', e.message ?? 'Failed to load associate Node');
    } finally {
      setLoadingSingleItem(false);
    }
  }, [notify, setLoadingSingleItem, siteId, associateNodeId]);

  React.useEffect(() => {
    handleLoadAssociateNode();
    return () => setAssociateNode(undefined);
  }, [handleLoadAssociateNode]);

  const value: SitePostContextProps = {
    // Template Table
    list,
    loadList,
    hasPermission,
    loading: loading || submitting,
    cleanupList,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onInlineEdit: handleInlineEdit,
    onInlineAdd: hasPermission.edit ? handleInlineAdd : undefined,
    onDelete: hasPermission.edit ? handleDelete : undefined,
    onRefresh: handleRefresh,
    submitting,
    // Associate Node
    loadAssociateNode: handleLoadAssociateNode,
    associateNode,
    associateNodeId,
    setAssociateNodeId,
    loadingSingleItem,
  };

  return (
    <SitePostContext.Provider value={value}>
      {children}
    </SitePostContext.Provider>
  );
};
