import React from 'react';
import {Stack} from '@mui/material';
import {FormikSwitch, FormikTextField} from '../../../formik';

export const ContractTripNotificationsFormFields: React.FC = () => {
  return (
    <Stack>
      <FormikTextField
        name="etaConfidence"
        placeholder="Enter the contract eta confidence"
        label="Eta Confidence"
        type="number"
        fullWidth
      />
      <FormikSwitch name="emailTripsheet" label="Email Tripsheet" />
      <FormikSwitch
        name="voiceBroadcastEnabled"
        label="Voice Broadcast Enabled"
      />
      <FormikSwitch
        name="etaCalcEnabled"
        label="Eta Calculation Enabled"
        stackProps={{pb: 1}}
      />
    </Stack>
  );
};
