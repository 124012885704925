import React from 'react';
import {ApiDocsFrame} from './ApiDocsFrame';
import {Stack} from '@mui/material';

export const ClientApiDocs: React.FC = () => {
  return (
    <Stack flex={1}>
      <ApiDocsFrame title="Client Api Docs" url="/external/swagger-ui/" />
    </Stack>
  );
};
