import React from 'react';
import {IPlanningBoardTripPanelForm} from '../planningBoardTripForm';
import {TemplateForm} from '../../../../../factory/template';
import {
  usePlanningBoardContext,
  usePlanningBoardGanttContext,
} from '../../../planningBoardContext';
import {
  usePlanningBoardTrip,
  usePlanningBoardTripResponse,
  usePlanningBoardTripResponseInitial,
} from './usePlanningBoardTrip';

export const PlanningBoardTripContext =
  React.createContext<usePlanningBoardTripResponse>(
    usePlanningBoardTripResponseInitial
  );

export const PlanningBoardTripContextProvider: React.FC = ({children}) => {
  const {
    vehicles,
    getContract,
    getMasterTrip,
    getOrder,
    getMasterTripsWithSameVehicle,
  } = usePlanningBoardContext();
  const {
    createMasterTrip,
    onAddPreviewTripToGantt,
    updateMasterTrip,
    selectedGanttItem,
    onClearSelectedGanttItem,
  } = usePlanningBoardGanttContext();
  const values = usePlanningBoardTrip({
    createMasterTrip,
    getContract,
    getMasterTrip,
    getMasterTripsWithSameVehicle,
    getOrder,
    onAddPreviewTripToGantt,
    onClearSelectedGanttItem,
    selectedGanttItem,
    updateMasterTrip,
    vehicles,
  });

  const {initialValues, onSubmit} = values;

  return (
    <PlanningBoardTripContext.Provider value={values}>
      <TemplateForm<IPlanningBoardTripPanelForm>
        initialValues={initialValues}
        permission={{name: `Edit PlanningBoard`, type: 'Edit'}}
        onSubmit={onSubmit}
        CardActionsProps={{style: {paddingLeft: 0}}}
        FormikSubmitProps={{
          color: 'primary',
          variant: 'contained',
          style: {marginLeft: 0},
        }}
        enableReinitialize
        disableActions
        disableIsDirty
      >
        {children}
      </TemplateForm>
    </PlanningBoardTripContext.Provider>
  );
};

export const usePlanningBoardTripContext = () =>
  React.useContext(PlanningBoardTripContext);
