import React from 'react';
import {Table} from '@devexpress/dx-react-grid-material-ui';
import {Typography} from '@mui/material';
import Loader from '../../../../../components/loader/Loader';
import {useTableCellNoDataStyles} from './TableCellNoData.style';
import {useInternalTableContext} from '../../TemplateTable';

export const TableCellNoData: React.FC<Table.NoDataCellProps> = ({
  getMessage,
  tableColumn,
  tableRow,
  ...props
}) => {
  const classes = useTableCellNoDataStyles();
  const {loading, loadingMessage} = useInternalTableContext();
  return (
    <td {...props} className={classes.root}>
      {loading ? (
        <Loader text={loadingMessage} />
      ) : (
        <Typography className={classes.noData}>No Data</Typography>
      )}
    </td>
  );
};
