import React from 'react';
import {TableActionButtonBase} from './TableActionButtonBase';
import {useTemplateTableActions} from '../../providers';
import {Save, Close} from '@mui/icons-material';
import {useInternalTableContext} from '../../TemplateTable';

export const TableActionButtonSaveAll: React.FC = () => {
  const {
    saveChangesHandler,
    rowChanges,
    addRowHandler,
    addedRows,
    setEditingRowIds,
  } = useTemplateTableActions();
  const {rowInitialValues} = useInternalTableContext();

  const isChanged = React.useMemo(
    () =>
      !!(
        (rowChanges &&
          rowInitialValues &&
          Object.keys(rowChanges)
            .map((key) =>
              Object.keys(rowInitialValues[key])
                .map(
                  (valueKey) =>
                    rowChanges[key][valueKey] !== undefined &&
                    rowInitialValues[key][valueKey]?.toString() !==
                      rowChanges[key][valueKey]
                )
                .includes(true)
            )
            .includes(true)) ||
        (addedRows &&
          addedRows.length > 0 &&
          addedRows
            ?.map((row) =>
              Object.keys(row)
                .map((key) => row[key] !== '')
                .includes(true)
            )
            .includes(true))
      ),
    [addedRows, rowChanges, rowInitialValues]
  );

  const handleCancel = React.useCallback(() => {
    if (addedRows && addedRows.length > 0 && addRowHandler) addRowHandler();
    setEditingRowIds([]);
  }, [addRowHandler, addedRows, setEditingRowIds]);

  const handleSave = React.useCallback(() => {
    if (saveChangesHandler) saveChangesHandler();
  }, [saveChangesHandler]);

  if (!saveChangesHandler) {
    return null;
  }

  return (
    <>
      <TableActionButtonBase
        data-cy="TemplateTableActionsSave"
        onClick={handleSave}
        disabled={!isChanged}
      >
        <Save />
      </TableActionButtonBase>
      <TableActionButtonBase
        data-cy="TemplateTableActionsCancel"
        onClick={handleCancel}
      >
        <Close />
      </TableActionButtonBase>
    </>
  );
};
