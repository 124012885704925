import React from 'react';
import {WorkflowConfigContext} from './WorkflowConfigContext';
import {useParams} from 'react-router-dom';

interface IParams {
  workflowConfigId?: string;
}

export const WorkflowConfigIdHandler: React.FC = () => {
  const {setWorkflowConfigId} = React.useContext(WorkflowConfigContext);

  const {workflowConfigId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = workflowConfigId ? parseInt(workflowConfigId) : undefined;
    setWorkflowConfigId(parsedId);
    return () => setWorkflowConfigId(undefined);
  }, [workflowConfigId, setWorkflowConfigId]);

  return null;
};
