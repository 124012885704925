import React from 'react';
import clone from 'rfdc';
import {Filter} from '@devexpress/dx-react-grid';
import {
  WebPlanningBoardOrder,
  WebPlanningBoardTripStopOrder,
} from '@onroadvantage/onroadvantage-api';
import _ from 'lodash';
import {WebPlanningBoardMasterTripWithIdType} from '../../planningBoardContext';

export interface usePlanningBoardBookingMasterTripListParams {
  bookingMasterTrips: WebPlanningBoardMasterTripWithIdType[] | undefined;
  assignedOrders: (WebPlanningBoardOrder | WebPlanningBoardTripStopOrder)[];
}

export const usePlanningBoardCommitBookingMasterTrips = ({
  bookingMasterTrips,
  assignedOrders,
}: usePlanningBoardBookingMasterTripListParams) => {
  const [list, setList] = React.useState<
    WebPlanningBoardMasterTripWithIdType[]
  >(bookingMasterTrips ?? []);
  const [filters, setFilters] = React.useState<Filter[]>();

  const handleColumnFilter = React.useCallback(
    (newFilters: Filter[]) =>
      (bookingMasterTrip: WebPlanningBoardMasterTripWithIdType) =>
        !newFilters ||
        newFilters.length === 0 ||
        !newFilters
          .map((f) => {
            if (f.value) {
              const fValue = f.value.toLowerCase();
              const trip = bookingMasterTrip.trip;
              const tripStops = bookingMasterTrip.trip?.stops;
              const firstStop = tripStops ? tripStops[0] : undefined;
              const lastStop =
                tripStops && tripStops.length > 0
                  ? tripStops[tripStops.length - 1]
                  : undefined;

              switch (f.columnName) {
                case 'orderNumbers': {
                  const orderNumbers: string[] = [];

                  tripStops?.forEach(({orders}) =>
                    orders?.forEach(({orderNumber}) => {
                      if (orderNumber && !orderNumbers.includes(orderNumber)) {
                        orderNumbers.push(orderNumber);
                      }
                    })
                  );

                  return orderNumbers
                    ?.join(', ')
                    ?.toLowerCase()
                    ?.includes(fValue);
                }
                case 'tripNumber':
                  return (bookingMasterTrip.tripNumber ?? trip?.tripNumber)
                    ?.toLowerCase()
                    ?.includes(fValue);
                case 'startingLocation':
                  return firstStop?.node?.name?.toLowerCase()?.includes(fValue);
                case 'loading': {
                  /** Need to clone the stops so that it doesn't modify the reference of the trip.stops that is passed from the list */
                  const clonedStops = clone()(tripStops);
                  /** Confirm that the stops are sorted in the correct sequence, to get the correct stop with the first order */
                  const sortedStops = clonedStops?.sort((a, b) =>
                    a.sequence && b.sequence ? a.sequence - b.sequence : 0
                  );

                  let firstOrderLoading: string | null = null;

                  sortedStops?.forEach(({orders}) => {
                    if (
                      orders &&
                      orders.length > 0 &&
                      orders[0].upliftPoint?.name &&
                      firstOrderLoading === null
                    ) {
                      firstOrderLoading = orders[0].upliftPoint.name;
                    }
                  });

                  return (firstOrderLoading ?? '')
                    .toLowerCase()
                    ?.includes(fValue);
                }
                case 'offloading': {
                  /** Need to clone the stops so that it doesn't modify the reference of the trip.stops that is passed from the list */
                  const clonedStops = clone()(tripStops);
                  /** Reverse the sorting to get the last stops first so that we can get the last order */
                  const sortedStops = clonedStops?.sort((a, b) =>
                    a.sequence && b.sequence ? b.sequence - a.sequence : 0
                  );

                  let lastOrderOffloading: string | null = null;

                  sortedStops?.forEach(({orders}) => {
                    if (
                      orders &&
                      orders.length > 0 &&
                      orders[0].offloadPoint?.name &&
                      lastOrderOffloading === null
                    ) {
                      lastOrderOffloading = orders[0].offloadPoint.name;
                    }
                  });

                  return (lastOrderOffloading ?? '')
                    .toLowerCase()
                    ?.includes(fValue);
                }
                case 'endingLocation':
                  return (lastStop?.node?.name ? lastStop?.node?.name : '')
                    .toLowerCase()
                    ?.includes(fValue);
                case 'planningSkills': {
                  const skills: string[] = [];
                  assignedOrders.forEach((assignedOrder) => {
                    trip?.stops?.forEach((stop) => {
                      stop.orders?.forEach((stopOrder) => {
                        if (
                          stopOrder.id === assignedOrder.id &&
                          assignedOrder.planningOrder?.skill &&
                          !skills.includes(assignedOrder.planningOrder.skill)
                        )
                          skills.push(
                            ...assignedOrder.planningOrder?.skill?.split(',')
                          );
                      });
                    });
                  });
                  return skills.join(', ').toLowerCase().includes(fValue);
                }
                default:
                  return true;
              }
            } else return true;
          })
          .includes(false),
    [assignedOrders]
  );

  const handleFilterOrders = React.useCallback(
    (newFilters: Filter[]) => {
      setList(bookingMasterTrips?.filter(handleColumnFilter(newFilters)) ?? []);
      setFilters(newFilters);
    },
    [handleColumnFilter, bookingMasterTrips]
  );

  // Set list and filter with selectedPlanningSkills or if there are any column filters
  React.useEffect(() => {
    setList(bookingMasterTrips ?? []);
    return () => {
      setList([]);
    };
  }, [bookingMasterTrips]);

  return {
    list,
    filters,
    onFiltersChange: _.debounce(handleFilterOrders, 700),
  };
};
