import React from 'react';
import {TemplateTable} from '../../../../factory/template';
import {ContractOperationalEventTypePrompt} from '@onroadvantage/onroadvantage-api';
import {useContractOperationalEventTypeConfigContext} from '../ContractOperationalEventTypeConfigContext';
import {useHistory} from 'react-router-dom';
import {useAppNotifications} from '../../../../contexts';
import {contractOperationalEventTypeConfigApi} from '../../../../api';

export const ContractOperationalEventTypePromptList = () => {
  const history = useHistory();
  const notify = useAppNotifications();
  const [deleting, setDeleting] = React.useState<boolean>(false);
  const {
    loading,
    contractOperationalEventTypeConfig,
    contractOperationalEventTypeConfigId,
    setContractOperationalEventTypeConfig,
  } = useContractOperationalEventTypeConfigContext();

  const handleNavigate = React.useCallback(
    (row: ContractOperationalEventTypePrompt) => {
      if (contractOperationalEventTypeConfigId != null && row.id != null) {
        history.push(
          `/contracteventtypeconfigs/operational/${contractOperationalEventTypeConfigId}/prompt/${row.id}`
        );
      }
    },
    [contractOperationalEventTypeConfigId, history]
  );

  const handleAdd = React.useCallback(() => {
    if (contractOperationalEventTypeConfigId != null) {
      history.push(
        `/contracteventtypeconfigs/operational/${contractOperationalEventTypeConfigId}/prompt/add`
      );
    }
  }, [contractOperationalEventTypeConfigId, history]);

  const handleDelete = React.useCallback(
    async (row: ContractOperationalEventTypePrompt) => {
      setDeleting(true);
      try {
        if (contractOperationalEventTypeConfigId != null && row.id != null) {
          const response =
            await contractOperationalEventTypeConfigApi.apiContractOperationalEventTypeConfigContractOperationalEventTypeConfigIdPromptPromptIdDelete(
              {
                contractOperationalEventTypeConfigId,
                promptId: row.id,
              }
            );
          if (response) {
            setContractOperationalEventTypeConfig((prevState) =>
              prevState
                ? {
                    ...prevState,
                    prompts: prevState.prompts?.filter(({id}) => id !== row.id),
                  }
                : undefined
            );
          }
        }
      } catch (e) {
        notify(
          'error',
          'Failed to delete contract operational event type prompt'
        );
      } finally {
        setDeleting(false);
      }
    },
    [
      contractOperationalEventTypeConfigId,
      notify,
      setContractOperationalEventTypeConfig,
    ]
  );

  return (
    <TemplateTable<ContractOperationalEventTypePrompt>
      config={{
        columns: [
          {name: 'type', label: 'Type', type: 'string'},
          {name: 'assistantId', label: 'Assistant ID', type: 'string'},
          {name: 'prompt', label: 'Prompt', type: 'string'},
        ],
        disablePagination: true,
        disableToolbar: true,
        deleteMessage: (row) => ({
          title: 'Contract Operational Event Type Prompt',
          items: {
            ContractOperationalEventTypeConfig: `${contractOperationalEventTypeConfig?.operationalEventType?.name} - ${contractOperationalEventTypeConfig?.contract?.code}`,
            Type: row.type,
            AssistantId: row.assistantId,
          },
        }),
      }}
      currentPage={1}
      list={contractOperationalEventTypeConfig?.prompts ?? []}
      loading={loading || deleting}
      onAdd={handleAdd}
      onNavigate={handleNavigate}
      onDelete={handleDelete}
    />
  );
};
