import React from 'react';
import {Avatar, Card, CardContent, CardHeader, Chip} from '@mui/material';
import {Assignment} from '@mui/icons-material';
import {TripDebriefSummaryStopListContext} from './TripDebriefSummaryStopListContext';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../../../../factory/template';
import {
  MasterTripDebriefContractSnapshot,
  MasterTripDebriefTripStopSnapshot,
} from '@onroadvantage/onroadvantage-api';
import {getStopActualTAT, getStopPlannedTAT} from '../../../../../utils';
import {TripDebriefContext} from '../../../TripDebriefContext';

export const TripDebriefSummaryStopList: React.FC = () => {
  const {masterTripDebriefData} = React.useContext(TripDebriefContext);
  /**
   *  Destructure snapshotDataContract, need to add || ({} as MasterTripDebriefContractSnapshot) for typescript, since
   *  snapshotDataContract is nullable.
   */
  const {tripStopTimestampPriority} =
    masterTripDebriefData?.snapshotData?.contract ||
    ({} as MasterTripDebriefContractSnapshot);

  const config: TemplateTableConfig<MasterTripDebriefTripStopSnapshot> =
    React.useMemo(
      () => ({
        columns: [
          {
            name: 'stop_type',
            label: 'Stop Type ',
            type: 'string',
            getValue: ({taskTemplateNodeType}) => (
              <Chip label={taskTemplateNodeType ?? '-'} />
            ),
          },
          {
            name: 'node.name',
            label: 'Site Name',
            type: 'string',
            getValue: ({node}) => node?.name,
          },
          {
            name: 'node.externalReference',
            label: 'External Reference',
            type: 'string',
            getValue: ({node}) => node?.externalReference,
          },
          {name: 'sequence', label: 'Sequence', type: 'number'},
          {
            name: 'arrivalTime',
            label: 'Planned Arrival Time',
            type: 'datetime',
          },
          {
            name: 'departureTime',
            label: 'Planned Departure Time',
            type: 'datetime',
          },

          {
            name: 'plannedTAT',
            label: 'Planned TAT',
            type: 'string',
            getValue: (row) => getStopPlannedTAT(row),
          },
          {
            name: 'actual.arrivalTime',
            label: 'Geofence Arrival Time',
            type: 'datetime',
            getValue: ({tripStopExecution}) =>
              !tripStopExecution
                ? null
                : tripStopTimestampPriority === 'mobile'
                ? tripStopExecution.mobileArrivalTime
                : tripStopExecution.gpsArrivalTime,
          },
          {
            name: 'actual.departureTime',
            label: 'Geofence Departure Time',
            type: 'datetime',
            getValue: ({tripStopExecution}) =>
              !tripStopExecution
                ? null
                : tripStopTimestampPriority === 'mobile'
                ? tripStopExecution.mobileDepartureTime
                : tripStopExecution.gpsDepartureTime,
          },
          {
            name: 'actualTAT',
            label: 'Geofence TAT',
            type: 'string',
            getValue: ({tripStopExecution}) =>
              getStopActualTAT(tripStopExecution, tripStopTimestampPriority),
          },
        ],
        disablePagination: true,
        disableToolbar: true,
        identifier: 'TRIP_DEBRIEF_SUMMARY_STOP_LIST',
      }),
      [tripStopTimestampPriority]
    );

  return (
    <Card elevation={3}>
      <CardHeader
        title="Trip Stop"
        avatar={
          <Avatar>
            <Assignment />
          </Avatar>
        }
      />
      <CardContent>
        <TemplateTableWrapper
          config={config}
          context={TripDebriefSummaryStopListContext}
        />
      </CardContent>
    </Card>
  );
};
