import './css/main.css';
import React from 'react';
import {Popup} from 'react-leaflet';
import {Edit as EditIcon, Delete as DeleteIcon} from '@mui/icons-material';
import {
  Theme,
  Typography,
  Box,
  Card,
  Divider,
  IconButton,
  Grid,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import close from '../../../../img/svg/Close.svg';
import {WithStyles, withStyles} from '@mui/styles';

const styles = (theme: Theme) =>
  createStyles({
    removeMargin: {
      margin: 0,
      padding: 0,
    },
    fab: {
      top: theme.spacing(1),
      right: theme.spacing(1),
      position: 'absolute',
      boxShadow: 'none',
      width: 36,
    },
    backGround: {
      height: '200px',
      position: 'relative',
    },
    speedBox: {
      position: 'absolute',
      minWidth: '150px',
      bottom: '-12%',
      BoxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.24)',
      background: 'linear-gradient(90deg,#00B7A3 0%,#008270 100%)',
      borderRadius: '12px',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    bottomFab: {
      background: 'linear-gradient(90deg, #00B7A3 0%, #008270 100%)',
      transform: 'rotate(-180deg)',
      boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.24)',
    },
    divider: {
      width: '1px',
      background: '#fff',
      marginTop: '10px',
      marginBottom: '10px',
    },
    backgrounCard: {
      borderRadius: '0px 0px 0px 0px',
      width: '100%',
      boxShadow: 'none',
      marginTop: theme.spacing(3),
    },
    headingColor: {
      color: '#767676',
    },

    eventDesc: {
      fontWeight: 400,
      fontSize: 12,
      paddingLeft: 10,
    },
    spacing: {
      margin: theme.spacing(2),
    },
    parentScrollDiv: {
      overflow: 'auto',
      marginBottom: theme.spacing(1),
      maxHeight: '400px',
      minHeight: '200px',
      borderRadius: '12px 12px 12px 12px',
    },
    iconButtonBack: {
      backgroundColor: '#e5e5e5',
    },
  });

interface actionBox {
  header?: string;
  data?: any;
  type?: 'image' | 'text' | 'button';
  icon?: 'add' | 'delete';
}

interface Props extends WithStyles<typeof styles> {
  topIconType?: 'warning' | 'default';
  mainIcon?: string;
  backgroundImage: string;
  description?: string;
  left?: actionBox;
  title?: string;
  right?: actionBox;
  center?: actionBox;
  children?: React.ReactNode;
}

class PopupBase extends React.Component<Props> {
  render() {
    const {
      children,
      classes,
      title,
      description,
      mainIcon,
      backgroundImage,
      left,
      right,
      center,
    } = this.props;

    const closeRef = React.createRef<any>();

    const handleClose = () => {
      closeRef.current?.leafletElement?.options?.leaflet?.map?.closePopup();
    };

    return (
      <Popup
        ref={closeRef}
        closeButton={false}
        className={classes.removeMargin}
      >
        <Grid container>
          <Grid item xs={10} style={{marginLeft: 10}}>
            &nbsp;&nbsp;
            {title && <Typography variant="h4">{title}</Typography>}
          </Grid>
          <Grid item xs={2} style={{zIndex: 5}}>
            <img
              src={close}
              alt=";"
              height="32"
              width="32"
              onClick={handleClose}
              className={classes.fab}
            />
          </Grid>
        </Grid>
        <div className={classes.parentScrollDiv}>
          {description && (
            <Typography className={classes.eventDesc}>{description}</Typography>
          )}

          <Box
            style={{backgroundImage: `url(${backgroundImage})`}}
            className={classes.backGround}
            display="flex"
            justifyContent="center"
          >
            <Box
              display="flex"
              justifyContent="center"
              minWidth={300}
              height="100%"
              maxWidth={300}
            >
              {mainIcon && mainIcon !== '' && (
                <img
                  src={mainIcon}
                  alt=";"
                  style={{
                    objectFit: 'contain',
                    objectPosition: 'center',
                    width: '80%',
                  }}
                />
              )}
            </Box>

            <Box
              className={classes.speedBox}
              display="flex"
              justifyContent="space-around"
              alignItems="center"
            >
              {center == null ? (
                <>
                  <Box p={1}>
                    <Typography
                      style={{color: '#fff', fontWeight: 400}}
                      variant="h5"
                    >
                      {left?.header}
                    </Typography>
                    {left?.type === 'image' && <img src={left?.data} alt=";" />}
                    {left?.type === 'text' && (
                      <Typography
                        variant="h6"
                        style={{color: '#fff', fontWeight: 700}}
                      >
                        {left?.data}
                      </Typography>
                    )}
                    {left?.type === 'button' && (
                      <IconButton
                        className={classes.iconButtonBack}
                        onClick={left.data}
                        size="large"
                      >
                        {left.icon === 'add' || left.icon == null ? (
                          <EditIcon />
                        ) : null}
                        {left.icon === 'delete' ? <DeleteIcon /> : null}
                      </IconButton>
                    )}
                  </Box>
                  <Divider
                    orientation="vertical"
                    classes={{root: classes.divider}}
                    flexItem
                  />
                  <Box p={1}>
                    <Typography
                      style={{color: '#fff', fontWeight: 400}}
                      variant="h5"
                    >
                      {right?.header}
                    </Typography>

                    {right?.type === 'image' && (
                      <img src={right?.data} alt=";" />
                    )}
                    {right?.type === 'text' && (
                      <Typography
                        variant="h6"
                        style={{color: '#fff', fontWeight: 700}}
                      >
                        {right?.data}
                      </Typography>
                    )}
                  </Box>
                </>
              ) : (
                <Box p={1}>
                  <Typography
                    style={{color: '#fff', fontWeight: 400}}
                    variant="h5"
                  >
                    {center?.header}
                  </Typography>
                  {center?.type === 'text' && (
                    <Typography
                      variant="h6"
                      style={{color: '#fff', fontWeight: 700}}
                    >
                      {center?.data}
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          </Box>
          <Card className={classes.backgrounCard}>
            <Box ml={2} mr={2}>
              {children}
            </Box>
          </Card>
        </div>
      </Popup>
    );
  }
}

export default withStyles(styles)(PopupBase);
