import React from 'react';
import {
  listingSchema,
  NUMBER_ERROR_MESSAGE,
  TemplateCard,
  TemplateForm,
} from '../../factory/template';
import {DriverRankIncentiveContext} from './DriverRankIncentiveContext';
import {
  DriverRankIncentiveConfig,
  Listing,
} from '@onroadvantage/onroadvantage-api';
import * as Yup from 'yup';
import {
  FormikAutocompleteHooks,
  FormikAutocompleteWithListing,
  FormikDatePicker,
  FormikTextField,
} from '../formik';
import moment, {Moment} from 'moment';
import {FormikUpload} from '../formik';
import {InputAdornment} from '@mui/material';

export interface IIncentiveForm {
  contract: Listing;
  currency?: string | null;
  documentStorageId: string | null;
  maxAchievers?: number | null;
  message?: string | null;
  name?: string | null;
  period?: Listing;
  qualifyingKmTravelled?: number | null;
  rankMaxDemeritRatioThreshold?: number | null;
  rankMaxDemeritThreshold?: number | null;
  rankMinMeritRatioThreshold?: number | null;
  rankMinMeritThreshold?: number | null;
  driverScoreType?: Listing;
  validFrom?: Date | Moment | string | null;
  validTo?: Date | Moment | string | null;
  value?: number | null;
}

const schema: Yup.SchemaOf<IIncentiveForm> = Yup.object({
  contract: listingSchema.nullable().required('Required'),
  documentStorageId: Yup.string().required('Required'),
  maxAchievers: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
  message: Yup.string().required('Required'),
  currency: Yup.string(),
  name: Yup.string(),
  period: listingSchema.nullable().required('Required'),
  qualifyingKmTravelled: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
  rankMaxDemeritRatioThreshold: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
  rankMaxDemeritThreshold: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
  rankMinMeritRatioThreshold: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
  rankMinMeritThreshold: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
  driverScoreType: listingSchema.required('Required'),
  validFrom: Yup.date(),
  validTo: Yup.date(),
  value: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
});

const periodOptions = [
  {label: 'Weekly', value: 0},
  {label: 'Monthly', value: 1},
  {label: 'Quarterly', value: 2},
  {label: 'Annually', value: 3},
];

const getPeriodValue = (period?: string | null) =>
  periodOptions.find(({label}) => label === period)?.value;

const getInitialValues = (
  incentive?: DriverRankIncentiveConfig | null,
  listing?: {driverScoreTypeListing?: Listing[]}
): IIncentiveForm | undefined => {
  if (incentive) {
    const driverScoreTypeList = listing?.driverScoreTypeListing?.find(
      (type) => type.value === incentive.driverScoreTypeId
    );
    return {
      contract: {
        value: incentive.contract?.id,
        label: incentive.contract?.name,
      },
      documentStorageId: incentive.documentStorageId ?? '',
      maxAchievers: incentive.maxAchievers,
      message: incentive.message,
      currency: incentive.currency,
      name: incentive.name,
      period: {
        label: incentive.period ?? undefined,
        value: getPeriodValue(incentive.period),
      },
      qualifyingKmTravelled: incentive.qualifyingKmTravelled,
      rankMaxDemeritRatioThreshold: incentive.rankMaxDemeritRatioThreshold,
      rankMaxDemeritThreshold: incentive.rankMaxDemeritThreshold,
      rankMinMeritRatioThreshold: incentive.rankMinMeritRatioThreshold,
      rankMinMeritThreshold: incentive.rankMinMeritThreshold,
      driverScoreType: {
        label: driverScoreTypeList?.label,
        value: driverScoreTypeList?.value,
      },
      validFrom: moment(incentive.validFrom),
      validTo: moment(incentive.validTo),
      value: incentive.value,
    };
  }
  return undefined;
};

export const DriverRankIncentiveForm: React.FC<{isAdd?: boolean}> = ({
  isAdd,
}) => {
  const {
    detailsRef,
    loading,
    submitting,
    driverRankIncentive,
    driverScoreTypeListing,
    onDetailsFormSubmit,
  } = React.useContext(DriverRankIncentiveContext);
  const [initialValues, setInitialValues] = React.useState<IIncentiveForm>();
  const [listingLoading, setListingLoading] = React.useState<boolean>(false);
  const [periodOptionsState] = React.useState<Listing[]>(periodOptions);

  React.useEffect(() => {
    setListingLoading(true);
    const values = getInitialValues(driverRankIncentive, {
      driverScoreTypeListing,
    });
    if (values) {
      setInitialValues(values);
    } else if (isAdd) {
      setInitialValues({
        contract: {value: undefined, label: undefined},
        documentStorageId: '',
      });
    }
    setListingLoading(false);
  }, [isAdd, driverRankIncentive, setInitialValues, driverScoreTypeListing]);

  return (
    <TemplateCard
      title={`Incentive Details ${isAdd ? 'Add' : 'Edit'}`}
      loading={loading || listingLoading}
    >
      <TemplateForm<IIncentiveForm>
        initialValues={initialValues}
        onSubmit={onDetailsFormSubmit}
        permission={{
          name: `${isAdd ? 'Add' : 'Edit'} DriverRankIncentive`,
          type: isAdd ? 'Add' : 'Edit',
        }}
        submitting={submitting}
        innerRef={detailsRef}
        validationSchema={schema}
      >
        {({values}) => (
          <>
            <FormikAutocompleteWithListing
              name="contract"
              model="Contract"
              placeholder="Enter and select the incentive contract"
              label="Contract"
            />
            <FormikTextField
              name="name"
              placeholder="Enter the incentive name"
              label="Name"
              fullWidth
            />
            <FormikTextField
              name="message"
              placeholder="Enter the incentive message"
              label="Message"
              fullWidth
            />
            <FormikAutocompleteHooks
              name="period"
              placeholder="Enter the incentive period"
              label="Period"
              options={periodOptionsState}
            />
            <FormikTextField
              name="maxAchievers"
              placeholder="Enter the incentive max achievers"
              label="Max Achievers"
              type="number"
              fullWidth
            />
            <FormikTextField
              name="currency"
              placeholder="Enter the incentive currency"
              label="Currency"
              fullWidth
            />
            <FormikTextField
              name="value"
              placeholder="Enter the incentive value"
              label="Value"
              type="number"
              InputProps={{
                startAdornment: values.currency && (
                  <InputAdornment position="start">
                    {values.currency}
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
            <FormikAutocompleteWithListing
              name="driverScoreType"
              placeholder="Enter and select the driver score type"
              label="Driver Score Type"
              model="DriverScoreType"
            />
            <FormikTextField
              name="qualifyingKmTravelled"
              placeholder="Enter the incentive rank threshold"
              label="Qualifying Km Travelled"
              type="number"
              fullWidth
            />
            <FormikTextField
              name="rankMaxDemeritRatioThreshold"
              placeholder="Enter the incentive rank threshold"
              label="Rank Max Demerit Ratio Threshold"
              type="number"
              fullWidth
            />
            <FormikTextField
              name="rankMaxDemeritThreshold"
              placeholder="Enter the incentive rank threshold"
              label="Rank Max Demerit Threshold"
              type="number"
              fullWidth
            />
            <FormikTextField
              name="rankMinMeritRatioThreshold"
              placeholder="Enter the incentive rank threshold"
              label="Rank Min Merit Ratio Threshold"
              type="number"
              fullWidth
            />
            <FormikTextField
              name="rankMinMeritThreshold"
              placeholder="Enter the incentive rank threshold"
              label="Rank Min Merit Threshold"
              type="number"
              fullWidth
            />
            <FormikDatePicker
              name="validFrom"
              label="Valid From"
              disableDefaultValue
              fullWidth
            />
            <FormikDatePicker
              name="validTo"
              label="Valid To"
              disableDefaultValue
              fullWidth
            />
            <FormikUpload
              accept="image/*"
              name="documentStorageId"
              label="Upload Incentive Photo"
              type="DriverRankIncentiveConfig"
              enablePreview
            />
          </>
        )}
      </TemplateForm>
    </TemplateCard>
  );
};
