import React from 'react';
import vantageLogoAnimation from '../../img/json/vantageLogo.json';
import Lottie from 'react-lottie';
import {useAuthenticationStyles} from './Authentication.style';
import {WindowInfoContext} from '../../contexts';

export const AuthenticationLogo: React.FC = () => {
  const {isMobile} = React.useContext(WindowInfoContext);
  const classes = useAuthenticationStyles();

  return (
    <div className={classes.logo}>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: vantageLogoAnimation,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        height={isMobile ? 60 : 75}
        width={isMobile ? 250 : 300}
        isStopped={false}
        isClickToPauseDisabled
      />
    </div>
  );
};
