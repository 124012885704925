import React from 'react';
import {SiteSubNodeContext} from './SiteSubNodeContext';
import {useParams} from 'react-router-dom';

interface IParams {
  subNodeId?: string;
}

export const SiteSubNodeIdHandler: React.FC = () => {
  const {setSubNodeId} = React.useContext(SiteSubNodeContext);

  const {subNodeId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = subNodeId ? parseInt(subNodeId) : undefined;
    setSubNodeId(parsedId);
    return () => setSubNodeId(undefined);
  }, [subNodeId, setSubNodeId]);

  return null;
};
