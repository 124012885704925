import styled from '@emotion/styled';

const MenuLabelSpan = styled.span({
  width: '100%',
  displayDirection: 'row',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontWeight: 'bold',
});
const MenuLabelLayout = styled.div({
  displayDirection: 'row',
  display: 'flex',
});
const MenuLabelIconContainer = styled.div({
  marginRight: 10,
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
});

// const MenuOverideStyle = {
//   root: {
//     ":hover":{
//       backgroundColor:'orange',
//     },
//     "&:hover":{
//       backgroundColor:'orange',
//     },
//     "&$:hover":{
//       backgroundColor:'orange',
//     },
//   },
//   // Root styles for component
//   selectionItem: {
//     // These styles are applied to the root element when
//     // when selected. This changes the color of background
//     "&$selected": {
//       color: "red",
//       backgroundColor:'blue'
//     }
//   },
//   // NOTE: You need to include a `.selected` class in your
//   // styles rules for the "&$selected" selector to work.
//   selected: {}
// };

export {
  MenuLabelSpan,
  MenuLabelLayout,
  MenuLabelIconContainer /* MenuOverideStyle */,
};
