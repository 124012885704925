import React from 'react';
import {
  ApiContractCriticalEventTypeConfigGetRequest,
  ContractCriticalEventTypeConfigListResponse,
  ContractCriticalEventTypeConfigList,
} from '@onroadvantage/onroadvantage-api';
import {contractCriticalEventTypeConfigApi} from '../../../../api';
import {
  TemplateTableContextProps,
  TLoadList,
  useTemplateTable,
} from '../../../../factory/template';
import {useAppNotifications} from '../../../../contexts';
import {useHistory} from 'react-router-dom';

export type ContractCriticalEventTypeConfigListContextProps =
  TemplateTableContextProps<
    ContractCriticalEventTypeConfigList,
    ContractCriticalEventTypeConfigListResponse
  >;

export const ContractCriticalEventTypeConfigListContext =
  React.createContext<ContractCriticalEventTypeConfigListContextProps | null>(
    null
  );

export const useContractCriticalEventTypeConfigListContext = () => {
  const contractCriticalEventTypeConfigListContext = React.useContext(
    ContractCriticalEventTypeConfigListContext
  );
  if (contractCriticalEventTypeConfigListContext == null) {
    throw new Error(
      'useContractCriticalEventTypeConfigListContext has to be used within <ContractCriticalEventTypeConfigListContext.Provider>'
    );
  }

  return contractCriticalEventTypeConfigListContext;
};

export const ContractCriticalEventTypeConfigListContextProvider: React.FC = ({
  children,
}) => {
  const notify = useAppNotifications();
  const history = useHistory();
  // Template Table
  const [
    {
      // States
      currentPage,
      filters,
      hasPermission,
      itemTotal,
      list,
      loading,
      pageSize,
      pageTotal,
      sorting,
    },
    {
      // Getters
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
    },
  ] = useTemplateTable<
    ContractCriticalEventTypeConfigList,
    ApiContractCriticalEventTypeConfigGetRequest
  >({
    addPermission: 'Add ContractCriticalEventTypeConfig',
    editPermission: 'Edit ContractCriticalEventTypeConfig',
    deletePermission: 'Delete ContractCriticalEventTypeConfig',
  });

  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const loadList = React.useCallback<
    TLoadList<ContractCriticalEventTypeConfigListResponse>
  >(
    async (options) => {
      setLoading(true);
      try {
        const requestObj = getRequestObj(
          ['allowEventMuting', 'contractCode', 'criticalEventTypeName'],
          options
        );
        const response =
          await contractCriticalEventTypeConfigApi.apiContractCriticalEventTypeConfigGet(
            requestObj
          );
        return getResponse(response, options);
      } catch (e) {
        notify('error', 'Failed to load contract critical event type configs');
      } finally {
        setLoading(false);
      }
    },
    [setLoading, getRequestObj, getResponse, notify]
  );

  const handleDelete = React.useCallback(
    async (row: ContractCriticalEventTypeConfigList) => {
      setSubmitting(true);
      try {
        if (row.id) {
          await contractCriticalEventTypeConfigApi.apiContractCriticalEventTypeConfigContractCriticalEventTypeConfigIdDelete(
            {
              contractCriticalEventTypeConfigId: row.id,
            }
          );
          notify('success', 'Deleted contract critical event type config');
          await loadList();
        }
      } catch (e) {
        notify('error', 'Failed to delete contract critical event type config');
      } finally {
        setSubmitting(false);
      }
    },
    [loadList, notify]
  );

  const handleNavigate = React.useCallback(
    (row: ContractCriticalEventTypeConfigList) => {
      history.push(`/contracteventtypeconfigs/critical/${row.id}`);
    },
    [history]
  );

  const handleAdd = React.useCallback(() => {
    history.push('/contracteventtypeconfigs/critical/add');
  }, [history]);

  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  const value: ContractCriticalEventTypeConfigListContextProps = {
    loadList,
    cleanupList,
    hasPermission,
    loading: submitting || loading,
    list,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onNavigate: handleNavigate,
    onAdd: hasPermission.add ? handleAdd : undefined,
    onDelete: hasPermission.delete ? handleDelete : undefined,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onRefresh: handleRefresh,
  };

  return (
    <ContractCriticalEventTypeConfigListContext.Provider value={value}>
      {children}
    </ContractCriticalEventTypeConfigListContext.Provider>
  );
};
