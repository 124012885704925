import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';
import {drawerWidth as leftDrawerWidth} from '../drawers/LeftDrawer.style';
import {drawerWidth as rightDrawerWidth} from '../drawers/RightDrawer.style';

const NavbarStyles = (theme: Theme) =>
  createStyles({
    content: {
      marginTop: 80,
    },
    root: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    shiftLeft: {
      width: `calc(100% - ${leftDrawerWidth}px)`,
      marginLeft: leftDrawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    shiftRight: {
      width: `calc(100% - ${rightDrawerWidth}px)`,
      marginRight: rightDrawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    shiftBoth: {
      width: `calc(100% - ${leftDrawerWidth}px - ${rightDrawerWidth}px)`,
    },
    title: {
      flexGrow: 1,
    },
  });

export const useNavbarStyles = makeStyles(NavbarStyles, {
  name: 'NavbarStyles',
});
