import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

const AuthenticationBackToLoginStyles = (theme: Theme) =>
  createStyles({
    button: {
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(3),
      },
      [theme.breakpoints.between('sm', 'lg')]: {
        marginTop: theme.spacing(4),
      },
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(4),
      },
    },
  });

export const useAuthenticationBackToLoginStyles = makeStyles(
  AuthenticationBackToLoginStyles,
  {name: 'AuthenticationBackToLoginStyles'}
);
