import React from 'react';
import Board from 'react-trello';
import {Card, CardActions, CardHeader, Avatar, Theme} from '@mui/material';
import {WithStyles} from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {SettingsApplications as SettingsApplicationsIcon} from '@mui/icons-material';
import clsx from 'clsx';
import {ILaneFilter, VantageBoardFilter} from './VantageBoardFilter';

const styles = (theme: Theme) =>
  createStyles({
    actions: {
      minHeight: 48.5,
    },
    board: {
      // the important flag is required to override the component's default
      backgroundColor: `${theme.palette.primary.main} !important`,
      maxHeight: 515,
      '& header': {
        whiteSpace: 'normal',
      },
    },
    boardCard: {
      backgroundColor: theme.palette.primary.main,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
  });

export interface ILaneCardTag {
  bgcolor: string;
  color: string;
  title: string;
}

export interface ILaneCard {
  description: string;
  id: string;
  label: string;
  title: string;
  tags?: Array<ILaneCardTag>;
}

export interface ILaneData {
  cards: ILaneCard[];
  id: string;
  title: string;
}

export interface IBoardData {
  lanes: ILaneData[];
}

interface Props extends ReactTrello.BoardProps, WithStyles<typeof styles> {
  icon?: any;
  title?: string;
  data: IBoardData;
  saveChanges?: () => void;
  addAll?: () => void;
  removeAll?: () => void;
  // eslint-disable-next-line no-undef
  onFilterChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  filters?: ILaneFilter[];
  isHeaderVisible?: boolean;
  isActionsVisible?: boolean;
  keepActionsSpace?: boolean;
  square?: boolean;
}

// eslint-disable-next-line import/prefer-default-export
export const VantageBoard = withStyles(styles)(
  ({
    classes,
    filters,
    onFilterChange,
    keepActionsSpace = true,
    isActionsVisible = true,
    isHeaderVisible = true,
    square = true,
    style,
    ...props
  }: Props) => {
    // TODO fix wrapping of long text in the card
    // TODO disable save button when no changes have been made

    return (
      <div style={style} className={classes.container}>
        {(isHeaderVisible || isActionsVisible) && (
          <Card elevation={1} square={square}>
            {isHeaderVisible && (
              <CardHeader
                title={props.title ? props.title : 'Allocation'}
                avatar={
                  <Avatar>
                    {props.icon ? props.icon : <SettingsApplicationsIcon />}
                  </Avatar>
                }
              />
            )}
            {keepActionsSpace && (
              <CardActions className={classes.actions}>
                {isActionsVisible && (
                  <>
                    <Button onClick={props.addAll}>
                      <Typography>Add All</Typography>
                    </Button>
                    <Button onClick={props.removeAll}>
                      <Typography>Remove All</Typography>
                    </Button>
                    {props.saveChanges && (
                      <Button onClick={props.saveChanges}>
                        <Typography>Save Changes</Typography>
                      </Button>
                    )}
                  </>
                )}
              </CardActions>
            )}
          </Card>
        )}
        <Card className={classes.boardCard} elevation={0} square={square}>
          <VantageBoardFilter
            filters={filters}
            onFilterChange={onFilterChange}
          />
          <Board
            {...props}
            cardStyle={{width: '100px'}}
            className={clsx(classes.board, 'react-trello-board')}
            laneStyle={{maxHeight: 500}}
            laneDraggable={false}
            collapsibleLanes={false}
            editable={false}
            editLaneTitle={false}
            hideCardDeleteIcon
          />
        </Card>
      </div>
    );
  }
);
