import React from 'react';
import {Stack} from '@mui/material';
import {PlanningBoardTripOrdersSortableItem} from './PlanningBoardTripOrdersSortableItem';
import {SortableContainer} from 'react-sortable-hoc';
import {usePlanningBoardTripContext} from '../../planningBoardTripContext';
import {usePlanningBoardTripOrdersSortableStyles} from './PlanningBoardTripOrdersSortable.style';

export const PlanningBoardTripOrdersSortable = SortableContainer(() => {
  const classes = usePlanningBoardTripOrdersSortableStyles();

  const {orders} = usePlanningBoardTripContext();
  return (
    <Stack className={classes.sortableContainer}>
      {orders.map((order, index) => (
        <PlanningBoardTripOrdersSortableItem
          key={order.orderNumber}
          order={order}
          index={index}
        />
      ))}
    </Stack>
  );
});
