import React from 'react';
import L from 'leaflet';
import BaseMarker from './BaseMarker';
import {inject, observer} from 'mobx-react';

interface Props {
  position: [number, number]; // lat , lng
  stop?: any;
  color?: string;
}

class DepotMarker extends React.Component<Props> {
  render() {
    const {position, stop} = this.props;

    const Icon = L.ExtraMarkers.icon({
      icon: 'fa-home',
      markerColor: 'green',
      shape: 'penta',
      prefix: 'fa',
    });

    return <BaseMarker position={position} icon={Icon} stop={stop} />;
  }
}

export default inject('mapDisplayStore')(observer(DepotMarker));
