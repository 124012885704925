import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

const PlanningBoardTripOrdersSortableStyles = (theme: Theme) =>
  createStyles({
    sortableItem: {
      cursor: 'move',
      padding: theme.spacing(1),
      backgroundColor: 'rgba(143,188,143,0.45)',
      zIndex: 10000,
      borderRadius: theme.shape.borderRadius,
    },
    sortableContainer: {
      borderRadius: theme.shape.borderRadius,
    },
  });

export const usePlanningBoardTripOrdersSortableStyles = makeStyles(
  PlanningBoardTripOrdersSortableStyles,
  {
    name: 'PlanningBoardTripOrdersSortableStyles',
  }
);
