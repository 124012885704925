import {makeStyles, createStyles} from '@mui/styles';

const TaskControllerStyles = () =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      minHeight: 10,
    },
    root: {
      // display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
  });

export const useTaskControllerStyles = makeStyles(TaskControllerStyles, {
  name: 'TaskControllerStyles',
});
