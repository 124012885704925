import React from 'react';
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteValue,
} from '@mui/material';
import {useAutocompleteStyles} from './Autocomplete.style';
import {AutocompleteField} from './AutocompleteField';
import {AutocompleteOption} from './AutocompleteOption';

export interface AutocompleteOptionType {
  readonly label?: string;
  readonly value?: string | number;
}

export type AutocompleteValueTypes =
  | string
  | AutocompleteOptionType
  | (AutocompleteOptionType | string)[]
  | null;

export type TAutocompleteOnChange = (
  event: React.SyntheticEvent,
  value: AutocompleteValue<
    AutocompleteOptionType,
    boolean | undefined,
    boolean | undefined,
    boolean | undefined
  >,
  reason: AutocompleteChangeReason,
  details?: AutocompleteChangeDetails<AutocompleteOptionType>
) => void;

export type TAutocompleteOnInputChange = (
  event: React.SyntheticEvent,
  value: string,
  reason: AutocompleteInputChangeReason
) => void;

export interface AutocompleteProps
  extends Omit<
    MuiAutocompleteProps<
      AutocompleteOptionType,
      boolean | undefined,
      boolean | undefined,
      boolean | undefined
    >,
    'renderInput' | 'options'
  > {
  error?: string;
  helperText?: string | undefined;
  id?: string;
  label?: string;
  name: string;
  options?: AutocompleteOptionType[];
  placeholder?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
}

export const AutocompleteContext = React.createContext<AutocompleteProps>({
  name: '',
});

export const Autocomplete: React.FC<AutocompleteProps> = (props) => {
  return (
    <AutocompleteContext.Provider value={props}>
      <AutocompleteComponent />
    </AutocompleteContext.Provider>
  );
};

const AutocompleteComponent: React.FC = () => {
  const {
    multiple,
    onChange,
    defaultValue,
    disabled,
    loading,
    options,
    value: valueProp,
    ...otherProps
  } = React.useContext(AutocompleteContext);
  /** Classes specific to autocomplete fields */
  const classes = useAutocompleteStyles();
  /** Local value state, not to be confused with the value passed from the Props */
  const [value, setValue] = React.useState<AutocompleteValueTypes>(null);
  /**
   * Purpose for isValueSet is to ensure that the value/defaultValues are only passed to the Autocomplete form once the useEffect has run, which runs after the component is rendered.
   * isValueSet is set to true once the useEffect has set the value, and back to false on unmount
   * If isValueSet is false the value won't be passed into the MuiAutocomplete component and the disabled/loading state will be enabled
   */
  const [isValueSet, setIsValueSet] = React.useState<boolean>(false);

  React.useEffect(() => {
    /**
     * On mount handle setValue.
     * If type of autocomplete is multiple, confirm that currentValue is an array, has length of greater than 0, is not undefined and also not 'null' (can happen on report generate)
     * Then use the currentValue, otherwise set the value to []
     * For normal autocomplete fields. Confirm that currentValue is not an array, currentValue is an object with more than 1 key and currentValue.value does have a value and currentValue is not undefined or 'null'
     * Then use the currentValue, otherwise set the value to null
     */
    setValue((prevValue) => {
      const currentValue = valueProp ?? prevValue;
      if (multiple) {
        if (
          currentValue === 'null' ||
          !currentValue ||
          !Array.isArray(currentValue) ||
          currentValue?.length === 0
        ) {
          return [];
        } else {
          return currentValue;
        }
      } else {
        if (
          currentValue === 'null' ||
          !currentValue ||
          Array.isArray(currentValue) ||
          Object.keys(currentValue)?.length === 0 ||
          (currentValue as AutocompleteOptionType)?.value === undefined
        ) {
          return null;
        } else {
          return currentValue;
        }
      }
    });
    setIsValueSet(true);
    return () => setIsValueSet(false);
  }, [multiple, valueProp]);

  const handleChange = React.useCallback<TAutocompleteOnChange>(
    (event, newValue, ...otherParams) => {
      setValue(newValue);
      if (onChange) {
        onChange(event, newValue, ...otherParams);
      }
    },
    [onChange]
  );

  return (
    <MuiAutocomplete
      {...otherProps}
      id={
        otherProps.id ??
        (!multiple
          ? (value as AutocompleteOptionType | undefined)?.value?.toString()
          : undefined)
      }
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      multiple={multiple}
      value={isValueSet ? value : multiple ? [] : null}
      defaultValue={isValueSet ? defaultValue : multiple ? [] : null}
      onChange={handleChange}
      disabled={!isValueSet || disabled}
      loading={!isValueSet || loading}
      loadingText="Loading..."
      classes={otherProps.classes ?? classes}
      options={options || []}
      renderOption={AutocompleteOption}
      renderInput={AutocompleteField}
    />
  );
};
