import {
  MasterTripDebriefSnapshotData,
  MasterTripDebriefTripStopExecutionSnapshot,
  MasterTripDebriefMasterTripDebriefConfig,
} from '@onroadvantage/onroadvantage-api';
import {getTatThreshold} from './getTatThreshold';
import {getTripActualDistance} from './getTripActualDistance';

export const checkRequiredFields = (
  config: MasterTripDebriefMasterTripDebriefConfig | undefined | null,
  data: MasterTripDebriefSnapshotData | undefined | null
) => {
  if (!config?.requiredFields || !data) {
    return [];
  }

  const missingFields: string[] = [];

  // Actual Trip Start and End
  const overrideStops = data.overrideData?.stops;
  const snapshotStops = data.snapshotData?.stops;

  let firstStopExecution:
    | MasterTripDebriefTripStopExecutionSnapshot
    | null
    | undefined;

  let lastStopExecution:
    | MasterTripDebriefTripStopExecutionSnapshot
    | null
    | undefined;

  if (snapshotStops && snapshotStops.length > 0) {
    firstStopExecution = snapshotStops[0]?.tripStopExecution;
    lastStopExecution =
      snapshotStops[snapshotStops.length - 1]?.tripStopExecution;
  }

  if (overrideStops && overrideStops.length > 0) {
    firstStopExecution =
      overrideStops[0]?.tripStopExecution ?? firstStopExecution;
    lastStopExecution =
      overrideStops[overrideStops.length - 1]?.tripStopExecution ??
      lastStopExecution;
  }

  if (
    config.requiredFields.actualTripStart &&
    !firstStopExecution?.mobileDepartureTime
  ) {
    missingFields.push('actualTripStart');
  }

  if (
    config.requiredFields.actualTripEnd &&
    !lastStopExecution?.mobileArrivalTime
  ) {
    missingFields.push('actualTripEnd');
  }

  // Debriefer Name
  if (config.requiredFields.debrieferName && !data.debrieferName) {
    missingFields.push('debrieferName');
  }

  // Manual Start and End Odometer
  if (config.requiredFields.manualStartOdometer && !data.manualStartOdometer) {
    missingFields.push('manualStartOdometer');
  }

  if (config.requiredFields.manualEndOdometer && !data.manualEndOdometer) {
    missingFields.push('manualEndOdometer');
  }

  // Tat Threshold Reason Code
  let tatThresholdExceeds = false;
  const {tatThresholdAbs} = getTatThreshold(data);

  if (config.tatThreshold && tatThresholdAbs > config.tatThreshold) {
    tatThresholdExceeds = true;
  }

  if (
    config.requiredFields.tatThresholdReasonCode &&
    tatThresholdExceeds &&
    !data.tatThresholdReasonCode
  ) {
    missingFields.push('tatThresholdReasonCode');
  }

  // Variance Threshold Reason Code
  let varianceExceeds = false;
  const {percentageVarianceAbs} = getTripActualDistance(data);

  if (
    config.varianceThreshold &&
    percentageVarianceAbs > config.varianceThreshold
  ) {
    varianceExceeds = true;
  }

  if (
    config.requiredFields.varianceThresholdReasonCode &&
    varianceExceeds &&
    !data.varianceThresholdReasonCode
  ) {
    missingFields.push('varianceThresholdReasonCode');
  }

  // Return
  return missingFields;
};
