import React from 'react';
import {IconButton, Stack, Typography} from '@mui/material';
import {ArrowDownward, ArrowForward, Close} from '@mui/icons-material';

interface TripEditStopListComparisonProps {
  initialValue: string | number | Date | null | undefined;
  newValue: string | number | Date | null | undefined;
  showAlways?: boolean;
  onCancel?: () => void;
  direction?: 'row' | 'column' | undefined;
}

export const TripEditStopListComparison: React.FC<
  TripEditStopListComparisonProps
> = ({initialValue, newValue, showAlways, onCancel, direction}) => {
  if (!showAlways && initialValue === newValue) {
    return <>{initialValue}</>;
  }

  return (
    <Stack direction="row" spacing={0.5} alignItems="center">
      <Stack
        direction={direction ?? 'row'}
        flexWrap="nowrap"
        alignItems={direction === 'column' ? 'flex-start' : 'center'}
        spacing={0.5}
      >
        <Typography color="error.main">{initialValue ?? '-'}</Typography>
        {direction === 'column' ? (
          <ArrowDownward fontSize="small" />
        ) : (
          <ArrowForward fontSize="small" />
        )}
        <Typography color="success.main">{newValue ?? '-'}</Typography>
      </Stack>
      {onCancel ? (
        <IconButton size="small" onClick={onCancel}>
          <Close fontSize="small" />
        </IconButton>
      ) : null}
    </Stack>
  );
};
