import React from 'react';
import {Avatar, CardContent, CardHeader} from '@mui/material';
import {WithStyles} from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import Card from '@mui/material/Card';
import {SettingsApplications as SettingsApplicationsIcon} from '@mui/icons-material';
import {connect} from 'formik';
import {FormikProps} from 'formik/dist/types';
import {IPlanningAddFormValues} from './PlanningAdd';
import {FormikAutocompleteWithListing} from '../../formik';
import {FormikDatePicker, FormikTextField} from '../../formik';

const styles = () =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
  });

interface IFormikProps {
  formik: FormikProps<IPlanningAddFormValues>;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props extends WithStyles<typeof styles> {}

export const PlanningAddDetailsComponent: React.FC<Props & IFormikProps> = ({
  classes,
  formik,
}) => {
  const handleContractChange = () => {
    // reset drivers, orders, vehicles if contract changes
    formik.setFieldValue('allocatedDrivers', []);
    formik.setFieldValue('allocatedOrders', []);
    formik.setFieldValue('allocatedVehicles', []);
  };

  return (
    <Card>
      <CardHeader
        title="Planning Solution Details Edit"
        avatar={
          <Avatar>
            <SettingsApplicationsIcon />
          </Avatar>
        }
      />
      <CardContent className={classes.container}>
        <FormikTextField
          label="Solution Name"
          name="name"
          placeholder="Enter the Solution Name"
        />
        <FormikAutocompleteWithListing
          id="contract"
          label="Solution Contract"
          name="contract"
          placeholder="Enter and select a value"
          onChange={handleContractChange}
          model="Contract"
        />
        <FormikDatePicker label="Solution Start Date" name="startDate" />
        <FormikDatePicker label="Solution End Date" name="endDate" />
      </CardContent>
    </Card>
  );
};

export const PlanningAddDetails = withStyles(styles)(
  connect<Props, IPlanningAddFormValues>(PlanningAddDetailsComponent)
);
