import {Order} from '@onroadvantage/onroadvantage-api';
import {ITripEditTripStop} from '../tripEdit';

export const mapOrderToStops = async (order: Order, baseSequence: number) => {
  const fromNode = order.upliftPoint;
  const toNode = order.offloadPoint;
  const fromSequence = baseSequence;
  const toSequence = baseSequence + 1;

  if (order.id && fromNode?.id && toNode?.id) {
    /**
     * Add negative value ids since the TemplateTable requires ids and without having ids the draggable sorting will
     * break. Reason for adding negative ids is just so that they can't conflict with any existing ids, and reason for
     * using the relevant sequences is so that they also won't conflict with any newly added stops.
     */
    const fromStop: ITripEditTripStop = {
      id: -fromSequence,
      node: {
        id: fromNode.id,
        name: fromNode.name,
        type: fromNode.type,
        externalReference: fromNode.externalReference,
        unloadingTimeInMinutes: fromNode.unloadingTimeInMinutes,
      },
      sequence: fromSequence,
      orders: [{id: order.id}],
      taskTemplateNodeType: fromNode.type ?? undefined,
    };

    const toStop: ITripEditTripStop = {
      id: -toSequence,
      node: {
        id: toNode.id,
        name: toNode.name,
        type: toNode.type,
        externalReference: toNode.externalReference,
        unloadingTimeInMinutes: toNode.unloadingTimeInMinutes,
      },
      sequence: toSequence,
      orders: [{id: order.id}],
      taskTemplateNodeType: toNode.type ?? undefined,
    };

    return [fromStop, toStop];
  }
};
