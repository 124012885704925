import React from 'react';
import {useLocation} from 'react-router-dom';
import {FormikProps} from 'formik';
import {
  WebEvent,
  ApiWebEventGetRequest,
} from '@onroadvantage/onroadvantage-api';

import {
  EVENTS_TIMEOUT,
  EventsContext,
  useAppNotifications,
} from '../../contexts';
import {
  eventsPaneSearchFormSchema,
  IEventsPaneSearchForm,
} from './eventsPaneSearch/EventsPaneSearchForm';
import {getWebEventRequestObj} from './helpers';
import {webEventApi} from '../../api';
import {TemplateForm, TOnFormikSubmit} from '../../factory/template';
import {useEventsPaneStyles} from './EventsPane.style';
import {VantageFrameContext} from '../navigation/VantageFrameContext';

export type TEventPaneTabValues = 'Open' | 'Closed';

const EVENTS_PANE_SEARCH_INITIAL_VALUES: IEventsPaneSearchForm = {
  enableCriticalSearch: true,
  enableOperationalSearch: true,
  isAdvanced: false,
  dateRangeValue: 1,
  dateRangeUnit: 'days',
  eventType: [],
  vehicleGroup: [],
  vehicle: [],
  driver: [],
  contract: [],
};

export interface ParamEvent {
  event: WebEvent | undefined;
}

export interface EventsPaneContextProps {
  // states
  collapseSearchForm: boolean;
  currentPage: number;
  didErrorOccur: boolean;
  eventsList: WebEvent[];
  itemTotal: number;
  loading: boolean;
  pageSize: number;
  pageTotal: number;
  selectedEvent: WebEvent | undefined;
  tabValue: TEventPaneTabValues;
  // handlers
  handleCurrentPageChange: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newCurrentPage: number
  ) => void;
  handlePageSizeCountsChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
  handleFormSubmit: TOnFormikSubmit<IEventsPaneSearchForm>;
  onTabChange: (
    event: React.ChangeEvent<any>,
    value: TEventPaneTabValues
  ) => void;
  onToggleCollapseSearchForm: () => void;
  onSearchVehicleEventHistory: (event: WebEvent) => void;
  // loaders
  loadEvents: () => Promise<void>;
  reloadEvents: () => Promise<void>;
}

export const EventsPaneContext = React.createContext<EventsPaneContextProps>({
  // EventsPaneContext
  // states
  collapseSearchForm: true,
  currentPage: 0,
  didErrorOccur: false,
  eventsList: [],
  itemTotal: 0,
  loading: false,
  pageSize: 25,
  pageTotal: 0,
  selectedEvent: undefined,
  tabValue: 'Open',
  // handlers
  handleCurrentPageChange: () => null,
  handleFormSubmit: () => undefined,
  handlePageSizeCountsChange: () => null,
  onTabChange: () => null,
  onToggleCollapseSearchForm: () => null,
  onSearchVehicleEventHistory: () => null,
  // loaders
  loadEvents: async () => {},
  reloadEvents: async () => undefined,
});

export const EventsPaneContextProvider: React.FC = ({children}) => {
  const classes = useEventsPaneStyles();
  const notify = useAppNotifications();
  const location = useLocation();
  const {onLoadEventCount} = React.useContext(EventsContext);
  const {rightDrawerOpen} = React.useContext(VantageFrameContext);
  // states
  /** collapseSearchForm -> Used to collapse the search form, so that the event cards has more screen real estate. If true, form is collapsed. */
  const [collapseSearchForm, setCollapseSearchForm] =
    React.useState<boolean>(true);
  /** didErrorOccur -> Used to present to the user if the /api/web/event get had an error. Will be set true in the catch */
  const [didErrorOccur, setDidErrorOccur] = React.useState<boolean>(false);
  const [eventsList, setEventsList] = React.useState<WebEvent[]>([]);
  /** formValues -> Used to save the SearchFormikFormValues in state and to set it's initialValues when un-collapsing again */
  const [formValues, setFormValues] = React.useState<
    IEventsPaneSearchForm | undefined
  >();
  const [initialValues, setInitialValues] = React.useState<
    IEventsPaneSearchForm | undefined
  >();
  const [loading, setLoading] = React.useState<boolean>(false);
  /** selectedEvent -> Used to set an active state on said event. SelectedEvent is retrieved from location.state */
  const [selectedEvent, setSelectedEvent] = React.useState<
    WebEvent | undefined
  >();
  /** tabValue -> Open=Events and Closed=History */
  const [tabValue, setTabValue] = React.useState<TEventPaneTabValues>('Open');
  // Pagination
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [itemTotal, setItemTotal] = React.useState<number>(0);
  const [pageSize, setPageSize] = React.useState<number>(25);
  const [pageTotal, setPageTotal] = React.useState<number>(0);

  // TODO this is an ugly yet functional fix, replace at a later stage
  const [isRefreshNeeded, setIsRefreshNeeded] = React.useState<boolean>(true);
  // Refs
  const formikRef = React.useRef<FormikProps<IEventsPaneSearchForm>>(null);
  /** countTimeoutRef -> Used to reload the count call every 30 seconds */
  const countTimeoutRef = React.useRef<NodeJS.Timeout>();
  /**  eventsTimeoutRef -> Used to reload the web events call every 30 seconds, except for when you are in the SearchForm */
  const eventsTimeoutRef = React.useRef<NodeJS.Timeout>();

  const clearTimeouts = React.useCallback(() => {
    if (countTimeoutRef.current) {
      clearTimeout(countTimeoutRef.current);
    }
    if (eventsTimeoutRef.current) {
      clearTimeout(eventsTimeoutRef.current);
    }
  }, []);

  // loaders
  const handleLoadEvents = React.useCallback(
    async (values?: IEventsPaneSearchForm, forceReload?: boolean) => {
      if (!isRefreshNeeded && !forceReload) {
        return;
      }
      try {
        setLoading(true);

        // clear the auto reload timeout if any should exist
        eventsTimeoutRef.current && clearTimeout(eventsTimeoutRef.current);

        /** Separated requestObj logic into its own getWebEventRequestObj helper. */
        const requestObj: ApiWebEventGetRequest = getWebEventRequestObj({
          values: values || formValues,
          tabValue,
        });

        // add pagination details
        requestObj.page = currentPage + 1;
        requestObj.perPage = pageSize;

        // make request
        const response = await webEventApi.apiWebEventGet(requestObj ?? {});

        // process response
        setEventsList(response.items ?? []);
        setItemTotal(response.total ?? 0);
        setPageTotal(response.pages ?? 1);

        await onLoadEventCount();
      } catch (e) {
        setDidErrorOccur(true);
        notify('error', e.message ?? 'Failed to load events');
      } finally {
        // clear the form's submission status
        formikRef.current && formikRef.current.setSubmitting(false);

        // setup a refresh timer should it be on the OPEN tab
        if (tabValue === 'Open') {
          /** Load events every 30s when eventsPane is opened/mounted */
          eventsTimeoutRef.current = setTimeout(
            () => setIsRefreshNeeded(true),
            EVENTS_TIMEOUT
          );
        }

        setIsRefreshNeeded(false);
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isRefreshNeeded, formValues, currentPage, pageSize, rightDrawerOpen]
  );

  const handleReloadEvents = React.useCallback<
    EventsPaneContextProps['reloadEvents']
  >(async () => {
    await handleLoadEvents(undefined, true);
  }, [handleLoadEvents]);

  // handlers
  /**
   * handleTabChange -> Triggered when changing tabs.
   * Clear any active timeouts.
   * Reset didErrorOccur, eventsList, formValues and formikRef form values to their relevant initial states.
   * When changing to Open tab we want to load the events and collapse the search form.
   * When changing to Closed tab we want to open (un-collapse) the search form.
   */
  const handleTabChange = React.useCallback<
    EventsPaneContextProps['onTabChange']
  >(
    (_event, value) => {
      // clear timeouts
      clearTimeouts();
      // clear form values
      setEventsList([]);
      setDidErrorOccur(false);
      setFormValues(EVENTS_PANE_SEARCH_INITIAL_VALUES);
      formikRef.current?.resetForm();
      // Tab specific logic for refreshing and whether the search form should collapsed
      if (value === 'Open') {
        setIsRefreshNeeded(true);
        setCollapseSearchForm(true);
      } else if (value === 'Closed') {
        setCollapseSearchForm(false);
      }
      // Navigate to the chosen tab
      setTabValue(value);
    },
    [clearTimeouts]
  );

  const handleToggleCollapseSearchForm = React.useCallback(
    () =>
      setCollapseSearchForm(
        (prevCollapseSearchForm) => !prevCollapseSearchForm
      ),
    []
  );

  const handlePageSizeCountsChange = React.useCallback<
    EventsPaneContextProps['handlePageSizeCountsChange']
  >(
    (event) => {
      clearTimeouts();
      setCurrentPage(0);
      setPageSize(parseInt(event.target.value));
      setIsRefreshNeeded(true);
    },
    [clearTimeouts]
  );

  const handleCurrentPageChange = React.useCallback<
    EventsPaneContextProps['handleCurrentPageChange']
  >(
    (_event, newCurrentPage) => {
      clearTimeouts();
      setCurrentPage(
        newCurrentPage < 0
          ? 0
          : newCurrentPage > pageTotal
          ? pageTotal
          : newCurrentPage
      );
      setIsRefreshNeeded(true);
    },
    [pageTotal, clearTimeouts]
  );

  const handleFormSubmit = React.useCallback<
    EventsPaneContextProps['handleFormSubmit']
  >(
    (values) => {
      clearTimeouts();
      setFormValues(values);
      setIsRefreshNeeded(true);
    },
    [clearTimeouts]
  );

  /**
   * handleSearchVehicleEventHistory -> Triggered when the View Vehicle History button is clicked on an Event Card
   * Clear any active timeouts.
   * Reset didErrorOccur, collapse the search form and navigate to the Closed (History) tab.
   * Set the event card's vehicle to the formikRef form's fieldValue, then also set the dateRange so that it's 12 hours.
   * Then we need to submit the form to automatically load the event's vehicle history for the last 12 hours.
   */
  const handleSearchVehicleEventHistory = React.useCallback(
    (event: WebEvent) => {
      // clear timeouts
      clearTimeouts();
      // set states to required values
      setDidErrorOccur(false);
      setCollapseSearchForm(true);
      setTabValue('Closed');
      // set the search form's values to what's required
      formikRef.current?.setFieldValue('vehicle', [
        {
          value: event.vehicle?.id,
          label: `${event.vehicle?.registrationNumber} - ${event.vehicle?.fleetNumber}`,
        },
      ]);
      formikRef.current?.setFieldValue('dateRangeUnit', 'hours');
      /**
       * We needed to make the dateRangeValue shouldValidate false, as we are changing the dateRangeUnit
       * (which defines the dateRangeValue's max) through the ref, and the dateRangeValue field will still see the Unit
       * as days (who's max is 7), thus the field will think it has an error.
       */
      formikRef.current?.setFieldValue('dateRangeValue', 12, false);
      // submit the form
      formikRef.current?.submitForm();
    },
    [clearTimeouts]
  );

  React.useEffect(() => {
    /** Get event out of location.state. Could be if they are on VehicleView or TripView */
    const state = location.state as ParamEvent | undefined;
    setSelectedEvent(state?.event);
  }, [location]);

  React.useEffect(() => {
    if (rightDrawerOpen) {
      handleLoadEvents();
    }
  }, [handleLoadEvents, rightDrawerOpen]);

  React.useEffect(() => {
    if (formValues) {
      setInitialValues(formValues);
    } else {
      setInitialValues(EVENTS_PANE_SEARCH_INITIAL_VALUES);
    }
  }, [formValues, setInitialValues]);

  React.useEffect(() => {
    return () => {
      clearTimeouts();
    };
  }, [clearTimeouts]);

  const value: EventsPaneContextProps = {
    // EventsPaneContext
    // states
    collapseSearchForm,
    currentPage,
    didErrorOccur,
    eventsList,
    itemTotal,
    loading,
    pageSize,
    pageTotal,
    selectedEvent,
    tabValue,
    // handlers
    handleCurrentPageChange,
    handleFormSubmit,
    handlePageSizeCountsChange,
    onTabChange: handleTabChange,
    onToggleCollapseSearchForm: handleToggleCollapseSearchForm,
    onSearchVehicleEventHistory: handleSearchVehicleEventHistory,
    // loaders
    loadEvents: handleLoadEvents,
    reloadEvents: handleReloadEvents,
  };

  return (
    <EventsPaneContext.Provider value={value}>
      {/** Wrapping all children with TemplateForm in order to be able to access the form values with useFormikContext, from any of the children components */}
      <TemplateForm<IEventsPaneSearchForm>
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        submitting={loading}
        validationSchema={eventsPaneSearchFormSchema}
        classes={{form: classes.form}}
        disableActions
        innerRef={formikRef}
      >
        {children}
      </TemplateForm>
    </EventsPaneContext.Provider>
  );
};
