import React from 'react';
import {Stack, Typography} from '@mui/material';
import {TripSettingsViewLayoutSortableContainer} from './TripSettingsViewLayoutSortableContainer';
import {useTripSettingsContext} from '../tripSettingsContext';
import {TripSettingsViewLayoutContainer} from './TripSettingsViewLayoutContainer';

export const TripSettingsViewLayout: React.FC = () => {
  const {updateTripViewLayoutPosition} = useTripSettingsContext();

  return (
    <Stack spacing={1.5}>
      <Stack spacing={1}>
        <Typography variant="h4">View order</Typography>
        <TripSettingsViewLayoutSortableContainer
          onSortEnd={updateTripViewLayoutPosition}
        />
      </Stack>
      <Stack spacing={1}>
        <Typography variant="h4">View visibility</Typography>
        <TripSettingsViewLayoutContainer />
      </Stack>
    </Stack>
  );
};
