import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

const BreadcrumbControllerStyles = (theme: Theme) =>
  createStyles({
    breadcrumbs: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      marginBottom: theme.spacing(2),
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      minHeight: 10,
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    link: {
      cursor: 'pointer',
    },
  });

export const useBreadcrumbControllerStyles = makeStyles(
  BreadcrumbControllerStyles,
  {name: 'BreadcrumbControllerStyles'}
);
