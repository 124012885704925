import React from 'react';
import {useHistory} from 'react-router-dom';
import {
  ApiOrderGetRequest,
  Order as OrderType,
  OrderPost,
  WebMasterTripOptimised,
  WebMasterTripOptimisedListResponse,
} from '@onroadvantage/onroadvantage-api';
import {FormikHelpers, FormikProps} from 'formik';
import {orderApi} from '../../api';
import {
  TemplateTableContextProps,
  TLoadList,
  useTemplateTable,
} from '../../factory/template';
import {IOrderForm} from './orderForm';
import {useAppNotifications} from '../../contexts';
import moment from 'moment';

export interface OrderContextProps
  extends TemplateTableContextProps<
    OrderType,
    WebMasterTripOptimisedListResponse
  > {
  statuses: string[];
  loadOrder: TLoadList<WebMasterTripOptimisedListResponse>;
  order?: OrderType;
  orderId?: number;
  submitting: boolean;
  detailsRef?: React.Ref<FormikProps<IOrderForm>>;
  onDetailsFormSubmit: (
    values: IOrderForm,
    formikHelpers: FormikHelpers<IOrderForm>
  ) => void;
  setOrderId: (value: number | undefined) => void;
}

export const OrderContext = React.createContext<OrderContextProps>({
  // Template Table
  loading: false,
  list: [],
  currentPage: 1,
  // Order
  statuses: [],
  loadList: async () => null,
  loadOrder: async () => null,
  submitting: false,
  onDetailsFormSubmit: () => null,
  setOrderId: () => null,
});

interface OrderContextProviderProps {
  orderId?: number;
}

export const OrderContextProvider: React.FC<OrderContextProviderProps> = ({
  children,
}) => {
  const history = useHistory();
  const notify = useAppNotifications();
  // Template Table
  const [
    {
      // States
      currentPage,
      filters,
      hasPermission,
      itemTotal,
      isDaterangeFilterActive,
      list,
      loading,
      pageSize,
      pageTotal,
      loadingSingleItem,
      sorting,
    },
    {
      // Getters
      getDownloads,
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleDateRangeFilterToggle,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
      setLoadingSingleItem,
    },
  ] = useTemplateTable<OrderType, ApiOrderGetRequest>({
    editPermission: 'Edit Order',
    addPermission: 'Add Order',
    deletePermission: 'Delete Order',
    downloadPermission: 'Order ListDownload',
    viewPermission: 'Order List',
    enableDateRangeFilter: true,
  });

  const loadList = React.useCallback<
    TLoadList<WebMasterTripOptimisedListResponse>
  >(
    async (options) => {
      setLoading(true);
      try {
        const requestObj = getRequestObj(
          [
            'contractCode',
            'externalTripNumber',
            'offloadPointName',
            'orderNumber',
            'primeMover',
            'status',
            'transporterName',
            'upliftPointName',
          ],
          options
        );
        const response = await orderApi.apiOrderGet(requestObj);
        return getResponse(response, options);
      } catch (e) {
        notify('error', e.message ?? 'Failed to load order list');
      } finally {
        setLoading(false);
      }
    },
    [setLoading, getRequestObj, getResponse, notify]
  );

  const handleDownload = React.useCallback(
    async () =>
      getDownloads('orders', loadList, [
        {name: 'Order Number', path: 'orderNumber'},
        {name: 'Trip Number', path: 'externalTripNumber'},
        {name: 'Status', path: 'status'},
        {name: 'Customer Name', path: 'customer.name'},
        {name: 'Contract Code', path: 'contract.code'},
        {name: 'Transporter', path: 'transporter.name'},
        {name: 'Prime Mover', path: 'primeMover'},
        {name: 'Uplift Point', path: 'upliftPoint.name'},
        {name: 'Offload Point', path: 'offloadPoint.name'},
        {name: 'Planned Depot Departure', path: 'depotDeparture'},
        {name: 'Actual Dispatch', path: 'actualDispatch'},
      ]),
    [getDownloads, loadList]
  );

  const handleListDelete = React.useCallback(
    async (row: WebMasterTripOptimised) => {
      setLoading(true);
      try {
        if (row.id) {
          await orderApi.apiOrderOrderIdDelete({
            orderId: row.id,
          });
          await loadList();
          notify('success', 'Order Deleted');
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to delete order');
      } finally {
        setLoading(false);
      }
    },
    [loadList, notify, setLoading]
  );

  const handleAdd = React.useCallback(() => {
    history.push('/orderlist/add');
  }, [history]);

  const handleNavigate = React.useCallback(
    (row: WebMasterTripOptimised) => {
      history.push(`/orderlist/${row.id}`);
    },
    [history]
  );

  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  // Order
  const [order, setOrder] = React.useState<OrderType | undefined>();
  const [orderId, setOrderId] = React.useState<number | undefined>();
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [statuses] = React.useState([
    'Planned',
    'Loaded',
    'Offloaded',
    'Completed',
    'Cancelled',
  ]);
  const detailsRef = React.useRef<FormikProps<IOrderForm>>(null);

  const loadOrder = React.useCallback(async () => {
    setLoadingSingleItem(true);
    try {
      if (orderId) {
        const response = await orderApi.apiOrderOrderIdGet({
          orderId,
        });
        setOrder(response);
      } else {
        setOrder(undefined);
      }
    } catch (e) {
      notify('error', e.message ?? 'Failed to load order');
    } finally {
      setLoadingSingleItem(false);
    }
  }, [notify, orderId, setLoadingSingleItem]);

  const handleDetailsFormSubmit = React.useCallback(
    async (values: IOrderForm, formikHelpers: FormikHelpers<IOrderForm>) => {
      setSubmitting(true);
      try {
        const newValues: OrderPost = {
          orderNumber: values.orderNumber,
          actualDispatch: moment(values.actualDispatch)?.toDate(),
          customerReferenceNumber: values.customerReferenceNumber,
          deliveryNoteNumber: values.deliveryNoteNumber,
          deliverByDateTime: moment(values.deliverByDateTime)?.toDate(),
          depotDeparture: moment(values.depotDeparture)?.toDate(),
          status: values.status?.label,
          primeMover: values.primeMover,
          contractId: values.contract?.value ?? 1,
          customerId: values.customer?.value,
          offloadPointId: values.offloadPoint?.value ?? 1,
          transporterId: values.transporter?.value,
          upliftPointId: values.upliftPoint?.value ?? 1,
        };

        if (order && order.id) {
          await orderApi.apiOrderOrderIdPatch({
            orderId: order.id,
            body: newValues,
          });
        } else {
          await orderApi.apiOrderPost({
            body: newValues,
          });
        }
        history.push('/orderlist');
        notify('success', `${order?.id ? 'Updated' : 'Added'} Order`);
      } catch (e) {
        if (e.status === 422) {
          notify('error', `Order number ${values.orderNumber} already exists!`);
        } else {
          notify('error', `Failed to ${order?.id ? 'update' : 'add'} order`);
        }
      } finally {
        formikHelpers.setSubmitting(false);
        setSubmitting(false);
      }
    },
    [order, history, notify]
  );

  const value: OrderContextProps = {
    // Template Table
    loadList,
    cleanupList,
    loading: loadingSingleItem || loading,
    list,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    isDaterangeFilterActive,
    onFiltersChange: handleFiltersChange,
    onSortingChange: handleSortingChange,
    onAdd: hasPermission.add ? handleAdd : undefined,
    onNavigate: handleNavigate,
    onDateRangeFilterToggle: handleDateRangeFilterToggle,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onDownload: hasPermission.download ? handleDownload : undefined,
    onDelete: handleListDelete,
    onRefresh: handleRefresh,
    // Order
    loadOrder,
    statuses,
    order,
    orderId,
    submitting,
    setOrderId,
    detailsRef,
    onDetailsFormSubmit: handleDetailsFormSubmit,
  };

  React.useEffect(() => {
    loadOrder();
    return () => setOrder(undefined);
  }, [loadOrder]);

  return (
    <OrderContext.Provider value={value}>{children}</OrderContext.Provider>
  );
};
