import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

const TemplateTabControllerStyles = (theme: Theme) =>
  createStyles({
    root: {},
    tabBar: {
      display: 'flex',
      flex: 1,
      borderBottomWidth: 1,
      borderBottomColor: theme.palette.divider,
      borderBottomStyle: 'solid',
    },
    tabs: {overflow: 'visible !important', alignItems: 'center'},
    tab: {overflow: 'visible !important'},
    customBadge: {backgroundColor: 'pink'},
    loaderContainer: {
      display: 'flex',
      justifySelf: 'center',
      alignSelf: 'center',
      flexDirection: 'column',
      padding: theme.spacing(1),
      paddingTop: theme.spacing(1.5),
    },
  });

export const useTemplateTabControllerStyles = makeStyles(
  TemplateTabControllerStyles,
  {name: 'TemplateTabControllerStyles'}
);
