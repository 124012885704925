import React from 'react';
import _ from 'lodash';
import {SpeedTable} from './SpeedTable';
import {
  SpeedTableContextProvider,
  useSpeedTableContext,
} from './speedTableContext';
import {
  ITemplateControllerRoute,
  TemplateController,
} from '../../factory/template';
import {SpeedTableList, SpeedTableListContextProvider} from './speedTableList';
import {SpeedTableForm} from './SpeedTableForm';
import {SpeedTableIdHandler} from './SpeedTableIdHandler';

export const SpeedTableController: React.FC = () => {
  return (
    <SpeedTableListContextProvider>
      <SpeedTableContextProvider>
        <SpeedTableControllerComponent />
      </SpeedTableContextProvider>
    </SpeedTableListContextProvider>
  );
};

export const SpeedTableControllerComponent: React.FC = () => {
  const {speedTable, loadingSpeedTable} = useSpeedTableContext();

  const routes: ITemplateControllerRoute[] = React.useMemo(
    () => [
      {title: 'Speed Table List', component: SpeedTableList},
      {
        path: '/add',
        title: 'New Speed Table',
        name: 'Add Driver',
        type: 'Add',
        component: () => <SpeedTableForm isAdd />,
      },
      {
        path: '/:speedTableId',
        title: (params) =>
          loadingSpeedTable
            ? 'Speed Table Loading...'
            : speedTable?.name ??
              `Speed Table ${_.get(params, 'speedTableId', 'Single')}`,
        component: SpeedTable,
        IdHandler: SpeedTableIdHandler,
      },
    ],
    [loadingSpeedTable, speedTable?.name]
  );

  return <TemplateController rootPath="/speedtablelist" routes={routes} />;
};
