import React from 'react';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
} from '@devexpress/dx-react-grid-material-ui';
import {RowDetailState, SelectionState} from '@devexpress/dx-react-grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import withStyles from '@mui/styles/withStyles';
import classnames from 'classnames';
import Avatar from '@mui/material/Avatar';
import ErrorIcon from '@mui/icons-material/Error';
import styles from '../styles/Card';
import {Loader} from '../loader';
import {flatten, unflatten} from '../../service/Util';
import OperationalEventDetail from './OperationalEventDetail';
import {mapDisplayStore} from '../../stores/mobxStores';
import Cell from '../devExpressTable/Cell';

class OperationalEvents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      selection: [],
    };
  }

  handleExpandClick = () => {
    this.setState((prevState) => ({expanded: !prevState.expanded}));
  };

  setMapDisplayOpenEvent = async (row) => {
    // eslint-disable-next-line no-param-reassign
    row.mapType = 'Operational';
    mapDisplayStore.setOpenEvent(unflatten(row));
  };

  changeSelection = (newSelection) => {
    const {selection} = this.state;
    const {events} = this.props;
    const difference = newSelection.filter((x) => !selection.includes(x));
    if (difference.length > 0) {
      this.setMapDisplayOpenEvent(events[difference[0]]);
      this.setState({selection: difference});
    }
  };

  render() {
    const {events = [], loading = false, classes} = this.props;
    const {selection} = this.state;

    const RowDetail = ({row}) => (
      <div style={{display: 'flex', flex: 1, justifyContent: 'space-between'}}>
        <OperationalEventDetail activities={row.activities} event={row} />
      </div>
    );

    return (
      <Card style={this.props.style}>
        <CardHeader
          style={{cursor: 'pointer'}}
          title="Operational Events"
          avatar={
            <Avatar aria-label="TripLogs">
              <ErrorIcon />
            </Avatar>
          }
          onClick={this.handleExpandClick}
          action={
            <IconButton
              className={classnames(classes.expand, {
                [classes.expandOpen]: this.state.expanded,
              })}
              aria-expanded={this.state.expanded}
              aria-label="Show more"
              size="large"
            >
              <ExpandMoreIcon />
            </IconButton>
          }
        />
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Grid
              rows={flatten({events}).events}
              columns={[
                {name: 'id', title: '#'},
                {name: 'type_name', title: 'Type'},
                {name: 'status', title: 'Status'},
                {name: 'driver_firstname', title: 'Driver Name'},
                {name: 'eventDate', title: 'Event Date'},
                {name: 'assignedTo_email', title: 'Assigned User'},
                {name: 'type_provider_name', title: 'Provider'},
                {name: 'latitude', title: 'Latitude'},
                {name: 'longitude', title: 'Longitude'},
              ]}
            >
              <RowDetailState />
              <SelectionState
                selection={selection}
                onSelectionChange={(props) => this.changeSelection(props)}
              />
              <Table cellComponent={Cell} />
              <TableHeaderRow />
              <TableRowDetail contentComponent={RowDetail} />
              <TableSelection
                selectByRowClick
                highlightRow
                showSelectionColumn={false}
              />
            </Grid>
            {loading ? <Loader /> : null}
          </CardContent>
        </Collapse>
      </Card>
    );
  }
}

export default withStyles(styles)(OperationalEvents);
