import React from 'react';
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import {useFormikContext} from 'formik';
import clsx from 'clsx';
import {useInputStyles} from './Input.style';

interface FormikTextFieldProps {
  name: string;
  password?: boolean;
  disablePasswordToggle?: boolean;
}

export const FormikTextField: React.FC<
  TextFieldProps & FormikTextFieldProps
> = ({
  name,
  disabled,
  password,
  disablePasswordToggle,
  type,
  ...otherProps
}) => {
  const classes = useInputStyles();
  const {
    getFieldMeta,
    getFieldHelpers,
    isSubmitting,
    isValid,
    submitCount,
    setFieldTouched,
    handleChange,
    handleBlur,
  } = useFormikContext();
  const {
    error,
    touched,
    initialError,
    initialTouched,
    initialValue,
    value,
    ...meta
  } = getFieldMeta(name);
  const {setValue} = getFieldHelpers(name);
  const [showPassword, setShowPassword] = React.useState<boolean>(!password);

  const memoizedError = React.useMemo(
    () =>
      !isValid && (submitCount > 0 || touched) && error ? error : undefined,
    [error, isValid, submitCount, touched]
  );

  const handleToggleShowPassword = React.useCallback(
    () => setShowPassword((prevShowPassword) => !prevShowPassword),
    []
  );

  const handleInputChange = React.useCallback(
    (e: React.ChangeEvent<any>): void => {
      setFieldTouched(name);
      if (type === 'number' && e.target.value === '') {
        setValue(undefined);
      } else {
        handleChange(e);
      }
    },
    [handleChange, name, setFieldTouched, setValue, type]
  );
  return (
    <TextField
      className={clsx(otherProps.className ?? classes.input, {
        [classes.inputError]: memoizedError,
      })}
      id={name}
      name={name}
      onChange={handleInputChange}
      error={!!memoizedError}
      helperText={memoizedError ?? <></>}
      onBlur={handleBlur}
      disabled={disabled || isSubmitting}
      {...otherProps}
      {...meta}
      value={value ?? ''}
      type={showPassword || showPassword === undefined ? 'text' : 'password'}
      InputProps={
        otherProps.InputProps ?? {
          endAdornment:
            password && !disablePasswordToggle ? (
              <InputAdornment position="end">
                <IconButton onClick={handleToggleShowPassword}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ) : undefined,
        }
      }
    />
  );
};
