// @ts-nocheck
export default function getMetaTagContent(tagName: string): string | null {
  const metas = document.getElementsByTagName('meta');
  // eslint-disable-next-line consistent-return, no-restricted-syntax
  for (const meta of metas) {
    if (meta.getAttribute('property') === tagName) {
      return meta.getAttribute('content');
    }
  }
  return null;
}
