import React from 'react';
import {CheckCircle, Warning, Error, Close, Info} from '@mui/icons-material';
import {IconButton} from '@mui/material';
import {SnackbarProvider} from 'notistack';
import {useSystemSnackbarStyles} from './SystemSnackbar.style';

export const SystemSnackbarProvider = ({children}: {children: JSX.Element}) => {
  const classes = useSystemSnackbarStyles();
  const notistackRef = React.createRef<any>();
  const onClickDismiss = React.useCallback(
    (key: any) => () => notistackRef.current?.handleCloseSnack(null, null, key),
    [notistackRef]
  );
  return (
    <SnackbarProvider
      preventDuplicate
      maxSnack={5}
      ref={notistackRef}
      classes={{
        variantError: classes.error,
        variantSuccess: classes.success,
        variantInfo: classes.info,
        variantWarning: classes.warning,
      }}
      iconVariant={{
        success: <CheckCircle className={classes.iconVariant} />,
        warning: <Warning className={classes.iconVariant} />,
        error: <Error className={classes.iconVariant} />,
        info: <Info className={classes.iconVariant} />,
      }}
      action={(key: unknown) => (
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={onClickDismiss(key)}
          size="large"
        >
          <Close className={classes.icon} />
        </IconButton>
      )}
    >
      {children}
    </SnackbarProvider>
  );
};
