import React from 'react';
import {CardActionArea} from '@mui/material';
import clsx from 'clsx';
import {IVehicleTypeCompartmentForm} from '../vehicleTypeCompartmentForm';
import {Add} from '@mui/icons-material';
import {VehicleTypeCompartmentContext} from '../VehicleTypeCompartmentContext';
import {useCompartmentTruckCompartmentStyles} from '../../../comptartment/CompartmentTruckCompartment.style';

interface VehicleTypeCompartmentTruckCompartmentAddProps {
  isAdd?: boolean;
  values?: IVehicleTypeCompartmentForm;
}

export const VehicleTypeCompartmentTruckCompartmentAdd: React.FC<
  VehicleTypeCompartmentTruckCompartmentAddProps
> = ({isAdd, values}) => {
  const classes = useCompartmentTruckCompartmentStyles();
  const {numOfCompartments, onAdd} = React.useContext(
    VehicleTypeCompartmentContext
  );

  if (!values)
    return (
      <CardActionArea
        className={clsx(classes.compartment, classes.compartmentAdd)}
        style={{flex: numOfCompartments === 0 ? 1 : 0.5}}
        onClick={onAdd}
      >
        <Add />
      </CardActionArea>
    );

  if (!isAdd) return null;

  return (
    <div className={clsx(classes.compartment, classes.compartmentActive)} />
  );
};
