import ConfigService from './ConfigService/ConfigService';
import {encodeUrl, flatten} from './Util';
import ResponseService from './ResponseService';
import {authStore} from '../store';

const serverUrl = ConfigService.serverUri;
const queryUrl = `${serverUrl}/api/node`;

const NodeService = {
  query: async (params) => {
    Object.keys(params).forEach(
      // eslint-disable-next-line no-param-reassign
      (key) => params[key] == null && delete params[key]
    );
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(encodeUrl(queryUrl, params), {
      method: 'GET',
      mode: 'cors',
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  delete: async (nodeId) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(`${queryUrl}/${nodeId}`, {
      method: 'DELETE',
      mode: 'cors',
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  update: async (node) => {
    if (node.openTime) {
      // eslint-disable-next-line no-param-reassign
      node.timeWindows = [{openTime: node.openTime, closeTime: node.closeTime}];
    }
    if (!node.id) {
      return NodeService.create(node);
    }
    Object.keys(node).forEach(
      // eslint-disable-next-line no-param-reassign
      (key) => node[key] == null && delete node[key]
    );
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(`${queryUrl}/${node.id}`, {
      method: 'PATCH',
      mode: 'cors',
      body: JSON.stringify(node),
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  create: async (node) => {
    Object.keys(node).forEach(
      // eslint-disable-next-line no-param-reassign
      (key) => node[key] == null && delete node[key]
    );
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(`${queryUrl}`, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({...node, isActive: true}),
      headers,
    });
    const createdNodes = await ResponseService.checkStatus(response);
    return createdNodes.data;
  },
  normalize: async (response) => {
    const result = flatten(await response.data);
    return result;
  },
  getById: async (nodeId) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(`${queryUrl}/${nodeId}`, {
      method: 'GET',
      mode: 'cors',
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  getNearbyNodes: async (params) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);

    const response = await fetch(encodeUrl(`${queryUrl}/nearby`, params), {
      method: 'GET',
      mode: 'cors',
      headers,
    });
    return ResponseService.checkStatus(response);
  },
};

export default NodeService;
