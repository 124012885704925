import React from 'react';
import clsx from 'clsx';
import {useFormikContext} from 'formik';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  SelectChangeEvent,
  FormControlProps,
  InputLabelProps,
  FormHelperTextProps,
  MenuItemProps,
} from '@mui/material';
import {useInputStyles} from './Input.style';

interface FormikTextFieldProps {
  name: string;
  options: string[];
  inputLabelProps?: InputLabelProps;
  formControlProps?: FormControlProps;
  formHelperTextProps?: FormHelperTextProps;
  menuItemProps?: MenuItemProps;
  helperText?: string;
}

export const FormikSelect: React.FC<
  SelectProps<string> & FormikTextFieldProps
> = ({
  name,
  options,
  disabled,
  type,
  inputLabelProps,
  formControlProps,
  formHelperTextProps,
  menuItemProps,
  ...otherProps
}) => {
  const classes = useInputStyles();
  const {
    getFieldMeta,
    getFieldHelpers,
    isSubmitting,
    isValid,
    submitCount,
    setFieldTouched,
    handleBlur,
  } = useFormikContext();
  const {
    error,
    touched,
    initialError,
    initialTouched,
    initialValue,
    value,
    ...meta
  } = getFieldMeta<string>(name);
  const {setValue} = getFieldHelpers<string>(name);
  const [fieldError, setFieldError] = React.useState<string | undefined>();

  React.useEffect(() => {
    if (!isValid && (submitCount > 0 || touched) && error) {
      setFieldError(error);
    } else {
      setFieldError(undefined);
    }
    return () => setFieldError(undefined);
  }, [error, isValid, submitCount, touched]);

  const handleInputChange = React.useCallback(
    (event: SelectChangeEvent) => {
      setFieldTouched(name);
      setValue(event.target.value);
    },
    [name, setFieldTouched, setValue]
  );

  return (
    <>
      <FormControl
        disabled={disabled || isSubmitting}
        error={!!fieldError}
        {...formControlProps}
        className={clsx(otherProps.className ?? classes.input, {
          [classes.inputError]: !!fieldError,
        })}
        fullWidth
      >
        <InputLabel {...inputLabelProps}>{otherProps.label}</InputLabel>
        <Select<string>
          id={name}
          name={name}
          value={value}
          onChange={handleInputChange}
          onBlur={handleBlur}
          {...otherProps}
          {...meta}
          fullWidth
          sx={{flex: 1}}
        >
          {options.map((option) => (
            <MenuItem value={option} key={option} {...menuItemProps}>
              {option}
            </MenuItem>
          ))}
        </Select>
        {fieldError || otherProps.helperText ? (
          <FormHelperText {...formHelperTextProps}>
            {fieldError ?? otherProps.helperText}
          </FormHelperText>
        ) : null}
      </FormControl>
    </>
  );
};
