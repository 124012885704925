import {
  MasterTripDebriefTripStopSnapshot,
  MasterTripDebriefContractSnapshot,
} from '@onroadvantage/onroadvantage-api';

export const getTripStopExecution = (
  stops: MasterTripDebriefTripStopSnapshot[] | null | undefined,
  contract: MasterTripDebriefContractSnapshot | null | undefined
) => {
  const actualsStopMethodology = contract?.actualsStopMethodology;
  const tripStopTimestampPriority = contract?.tripStopTimestampPriority;

  let firstStop: MasterTripDebriefTripStopSnapshot | null | undefined;

  let lastStop: MasterTripDebriefTripStopSnapshot | null | undefined;

  if (actualsStopMethodology === 'min_max') {
    /** Loop through each stop to determine which stop was actually first and which was last */
    stops?.forEach((stop) => {
      /**  */
      const departureKey =
        tripStopTimestampPriority === 'mobile'
          ? 'mobileDepartureTime'
          : 'gpsDepartureTime';
      const arrivalKey =
        tripStopTimestampPriority === 'mobile'
          ? 'mobileArrivalTime'
          : 'gpsArrivalTime';
      /** Determine first stop */
      if (stop.tripStopExecution && stop.tripStopExecution[departureKey]) {
        if (!firstStop?.tripStopExecution) {
          firstStop = stop;
        } else if (
          firstStop.tripStopExecution[departureKey] &&
          (stop.tripStopExecution[departureKey] as Date) <
            (firstStop.tripStopExecution[departureKey] as Date)
        ) {
          firstStop = stop;
        }
      }
      /** Determine last stop */
      if (stop.tripStopExecution && stop.tripStopExecution[arrivalKey]) {
        if (!lastStop?.tripStopExecution) {
          lastStop = stop;
        } else if (
          lastStop.tripStopExecution[arrivalKey] &&
          (stop.tripStopExecution[arrivalKey] as Date) <
            (lastStop.tripStopExecution[arrivalKey] as Date)
        ) {
          lastStop = stop;
        }
      }
    });
  } else if (stops && stops.length > 0) {
    firstStop = stops[0] ? stops[0] : undefined;
    lastStop = stops[stops.length - 1] ? stops[stops.length - 1] : undefined;
  }

  return {
    firstStop,
    lastStop,
    firstStopExecution: firstStop?.tripStopExecution,
    lastStopExecution: lastStop?.tripStopExecution,
  };
};
