import React from 'react';
import {TemplateCard, TemplateForm} from '../../factory/template';
import {PermissionContext} from './PermissionContext';
import {Permission, PermissionPost} from '@onroadvantage/onroadvantage-api';
import * as Yup from 'yup';
import {FormikTextField} from '../formik';

const schema: Yup.SchemaOf<PermissionPost> = Yup.object({
  description: Yup.string().required('Required'),
  name: Yup.string().required('Required'),
  type: Yup.string().required('Required'),
});

const getInitialValues = (
  permission?: Permission | null
): PermissionPost | undefined => {
  if (permission) {
    return {
      description: permission.description,
      name: permission.name,
      type: permission.type,
    };
  }
  return undefined;
};

export const PermissionForm: React.FC<{isAdd?: boolean}> = ({isAdd}) => {
  const {detailsRef, loading, submitting, permission, onDetailsFormSubmit} =
    React.useContext(PermissionContext);
  const [initialValues, setInitialValues] = React.useState<PermissionPost>();

  React.useEffect(() => {
    const values = getInitialValues(permission);

    if (values) {
      setInitialValues(values);
    } else if (isAdd) {
      setInitialValues({
        name: '',
        type: '',
      });
    }
  }, [isAdd, permission, setInitialValues]);

  return (
    <TemplateCard
      title={`Permission Details ${isAdd ? 'Add' : 'Edit'}`}
      loading={loading}
    >
      <TemplateForm<PermissionPost>
        initialValues={initialValues}
        onSubmit={onDetailsFormSubmit}
        permission={{
          name: `${isAdd ? 'Add' : 'Edit'} Permission`,
          type: isAdd ? 'Add' : 'Edit',
        }}
        submitting={submitting}
        innerRef={detailsRef}
        validationSchema={schema}
      >
        <FormikTextField
          name="name"
          placeholder="Enter the permission name"
          label="Name"
          fullWidth
        />
        <FormikTextField
          name="description"
          placeholder="Enter the permission description"
          label="Description"
          fullWidth
        />
        <FormikTextField
          name="type"
          placeholder="Enter the permission type"
          label="Type"
          fullWidth
        />
      </TemplateForm>
    </TemplateCard>
  );
};
