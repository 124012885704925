import React from 'react';
import * as Yup from 'yup';
import {Form, Formik, FormikHelpers, FormikProps} from 'formik';
import {
  FormikDisabler,
  FormikSubmit,
  FormikSubmitProps,
} from '../../../components/formik';
import {CardActions, CardActionsProps} from '@mui/material';
import {useTemplateFormStyles} from './TemplateForm.style';
import {Loader} from '../../../components/loader';
import {RoleService} from '../../../service';

export const NUMBER_ERROR_MESSAGE = 'Field requires a number input';

export const listingSchema = Yup.object()
  .shape({
    label: Yup.string().required('Required'),
    metaData: Yup.string(),
    value: Yup.number().required('Required'),
  })
  .nullable();

export const nullableListingSchema = Yup.object()
  .shape({
    label: Yup.string(),
    metaData: Yup.string(),
    value: Yup.number(),
  })
  .nullable();

export const requiredListingSchema = Yup.object()
  .shape({
    label: Yup.string().required('Required'),
    metaData: Yup.string(),
    value: Yup.number().required('Required'),
  })
  .required();

export const multipleListingSchema = Yup.array().of(listingSchema);

export const requiredMultipleListingSchema = Yup.array()
  .of(listingSchema)
  .min(1, 'At least 1 selection required');

type FieldType<T> =
  | ((formikParams: FormikProps<T>) => React.ReactNode)
  | React.ReactNode;

interface IFormClasses {
  actions?: string;
  form?: string;
}

export interface IPermission {
  name: string;
  type: string;
}

export type TOnFormikSubmit<T> = (
  values: T,
  formikHelpers: FormikHelpers<T>
) => void | Promise<any>;

export interface TemplateFormProps<T> {
  //required
  initialValues: T | undefined;
  //Props
  CardActionsProps?: CardActionsProps;
  FormikSubmitProps?: FormikSubmitProps;
  //other
  actions?: FieldType<T>;
  children?: FieldType<T>;
  classes?: IFormClasses;
  disableActions?: boolean;
  disableIsDirty?: boolean;
  innerRef?: React.Ref<FormikProps<T>> | undefined;
  loading?: boolean;
  disabled?: boolean;
  failedMessage?: React.ReactNode;
  onSubmit: TOnFormikSubmit<T>;
  permission?: IPermission;
  renderedFields?: FieldType<T>;
  enableReinitialize?: boolean;
  submitting?: boolean;
  validationSchema?: any | (() => any);
}

const TemplateFormComponent = <T,>(
  props: TemplateFormProps<T>
): React.ReactElement => {
  const defaultClasses = useTemplateFormStyles();
  const {
    //required
    initialValues,
    //other
    actions,
    children,
    classes,
    disableActions,
    disableIsDirty,
    innerRef,
    loading,
    failedMessage,
    onSubmit,
    permission,
    renderedFields,
    enableReinitialize,
    submitting,
    disabled,
    validationSchema,
    ...otherProps
  } = props;
  const [hasPermission] = React.useState(
    !permission || RoleService.hasPermission(permission.name, permission.type)
  );

  if (loading) return <Loader />;

  if (!initialValues) {
    if (failedMessage) return <>{failedMessage}</>;
    return <div>No initial values</div>;
  }

  return (
    <Formik<T>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      innerRef={innerRef}
      enableReinitialize={enableReinitialize}
    >
      {(formikParams) => (
        <Form className={classes?.form}>
          <FormikDisabler disabled={!hasPermission} />
          {typeof children === 'function' ? children(formikParams) : children}
          {typeof renderedFields === 'function'
            ? renderedFields(formikParams)
            : renderedFields}
          {!disableActions && (
            <CardActions
              className={classes?.actions ?? defaultClasses.actions}
              {...otherProps.CardActionsProps}
            >
              <FormikSubmit
                loading={submitting}
                permission={permission}
                disableIsDirty={disableIsDirty}
                disabled={disabled}
                {...otherProps.FormikSubmitProps}
              />
              {typeof actions === 'function' ? actions(formikParams) : actions}
            </CardActions>
          )}
        </Form>
      )}
    </Formik>
  );
};

export const TemplateForm = React.memo(
  TemplateFormComponent
) as typeof TemplateFormComponent;
