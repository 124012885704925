import React from 'react';
import {Order} from '@onroadvantage/onroadvantage-api';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../../../../factory/template';
import {TripEditOrderAddListContext} from './TripEditOrderAddListContext';

export const TripEditOrderAddList: React.FC = () => {
  const {loadList, cleanupList} = React.useContext(TripEditOrderAddListContext);

  const [config] = React.useState<TemplateTableConfig<Order>>({
    columns: [
      {name: 'orderNumber', label: 'Order Number', type: 'string'},
      {
        name: 'upliftPointName',
        label: 'Uplift Point',
        type: 'string',
        getValue: ({upliftPoint}) => upliftPoint?.name,
      },
      {
        name: 'offloadPointName',
        label: 'Offload Point',
        type: 'string',
        getValue: ({offloadPoint}) => offloadPoint?.name,
      },
    ],
    enableFilter: true,
    enableSort: true,
    identifier: 'TRIP_EDIT_ORDER_ADD_LIST',
  });

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [cleanupList, loadList]);

  return (
    <TemplateTableWrapper
      config={config}
      context={TripEditOrderAddListContext}
    />
  );
};
