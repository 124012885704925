import React from 'react';
import {
  listingSchema,
  TemplateCard,
  TemplateForm,
} from '../../../factory/template';
import {ContactContext} from '../ContactContext';
import {Contact, Listing} from '@onroadvantage/onroadvantage-api';
import * as Yup from 'yup';
import {
  FormikAutocompleteHooks,
  FormikSwitch,
  FormikTextField,
  FormikTimePicker,
} from '../../formik';
import {LuxonService} from '../../../service';
import {Collapse, FormControlLabel, Switch} from '@mui/material';
import {useContactFormStyles} from './ContactForm.style';

export interface IContactForm {
  name: string;
  email: string;
  cellphoneNumber: string;
  type: Listing;
  emailBan: boolean;
  whatsappBan: boolean;
  callBan: boolean;
  smsBan: boolean;
  notificationBanStart?: Date | null;
  notificationBanEnd?: Date | null;
}

const schema: Yup.SchemaOf<IContactForm> = Yup.object({
  name: Yup.string().required('Required'),
  email: Yup.string().email('Enter a valid email').required('Required'),
  cellphoneNumber: Yup.string()
    .matches(/^[+][0-9]*$/, {
      message: 'Requires a phone number with an international dialing code.',
      excludeEmptyString: true,
    })
    .required('Required'),
  type: listingSchema.nullable().required('Required'),
  emailBan: Yup.boolean().required('Required'),
  whatsappBan: Yup.boolean().required('Required'),
  callBan: Yup.boolean().required('Required'),
  smsBan: Yup.boolean().required('Required'),
  notificationBanStart: Yup.date().nullable(),
  notificationBanEnd: Yup.date().nullable(),
});

const getInitialValues = (
  contact?: Contact | null
): IContactForm | undefined => {
  if (contact) {
    return {
      name: contact.name ?? '',
      email: contact.email ?? '',
      cellphoneNumber: contact.cellphoneNumber ?? '',
      type: {label: 'Normal', value: contact.type ? parseInt(contact.type) : 0},
      emailBan: contact.emailBan ?? false,
      whatsappBan: contact.whatsappBan ?? false,
      callBan: contact.callBan ?? false,
      smsBan: contact.smsBan ?? false,
      notificationBanStart: contact.notificationBanStart
        ? LuxonService.fromServerTimeToLocalTimeDate(
            contact.notificationBanStart
          )
        : undefined,
      notificationBanEnd: contact.notificationBanEnd
        ? LuxonService.fromServerTimeToLocalTimeDate(contact.notificationBanEnd)
        : undefined,
    };
  }
  return undefined;
};

export const ContactForm: React.FC<{isAdd?: boolean}> = ({isAdd}) => {
  const classes = useContactFormStyles();
  const {
    disableNotifications,
    detailsRef,
    loading,
    contact,
    onDetailsFormSubmit,
    updateDisableNotifications,
    submitting,
  } = React.useContext(ContactContext);
  const [initialValues, setInitialValues] = React.useState<IContactForm>();

  React.useEffect(() => {
    const values = getInitialValues(contact);

    if (values) {
      setInitialValues(values);
    } else if (isAdd) {
      setInitialValues({
        name: '',
        email: '',
        cellphoneNumber: '',
        type: {
          label: 'Normal',
          value: 0,
        },
        emailBan: false,
        whatsappBan: false,
        callBan: false,
        smsBan: false,
      });
    }
  }, [isAdd, contact, setInitialValues]);

  return (
    <TemplateCard
      title={`Contact Details ${isAdd ? 'Add' : 'Edit'}`}
      loading={loading}
    >
      <TemplateForm<IContactForm>
        initialValues={initialValues}
        onSubmit={onDetailsFormSubmit}
        permission={{
          name: `${isAdd ? 'Add' : 'Edit'} Contact`,
          type: isAdd ? 'Add' : 'Edit',
        }}
        submitting={submitting}
        innerRef={detailsRef}
        validationSchema={schema}
      >
        <FormikTextField
          id="name"
          name="name"
          placeholder="Enter the contact name"
          label="Contact Name"
          fullWidth
        />
        <FormikTextField
          id="email"
          name="email"
          placeholder="Enter the contact email"
          label="Contact Email"
          fullWidth
        />
        <FormikTextField
          id="cellphoneNumber"
          name="cellphoneNumber"
          placeholder="Enter the contact's contact number"
          label="Contact's Contact Number"
          fullWidth
        />
        <FormikAutocompleteHooks
          name="type"
          //TODO: Update when there is listing for Notification Type
          options={[{label: 'Normal', value: 0}]}
          label="Contact Notification Type"
        />
        <FormikSwitch label="Sms Ban" name="smsBan" />
        <FormikSwitch label="Call Ban" name="callBan" />
        <FormikSwitch label="Whatsapp Ban" name="whatsappBan" />
        <FormikSwitch label="Email Ban" name="emailBan" />
        <FormControlLabel
          className={classes.input}
          control={
            <Switch
              onChange={updateDisableNotifications}
              checked={disableNotifications}
            />
          }
          label="Use Notification Bans"
        />
        <Collapse in={disableNotifications} className={classes.collapse}>
          <FormikTimePicker
            name="notificationBanStart"
            placeholder="Enter the start date"
            label="Contact Notification Ban Start"
            InputLabelProps={{shrink: true}}
            fullWidth
          />
          <FormikTimePicker
            name="notificationBanEnd"
            placeholder="Enter the end date"
            label="Contact Notification Ban End"
            InputLabelProps={{shrink: true}}
            fullWidth
          />
        </Collapse>
      </TemplateForm>
    </TemplateCard>
  );
};
