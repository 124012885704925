import React from 'react';
import {TripSetupDetailsStep} from './TripSetupDetailsStep';
import {TripSetupStopsStep} from './tripSetupStopsStep/TripSetupStopsStep';
import {TripSetupSummaryStep} from './tripSetupSummaryStep/TripSetupSummaryStep';

export const tripSetupSteps = ['details', 'stops', 'summary'] as const;

export type TripSetupSteps = (typeof tripSetupSteps)[number];

export const tripSetupStepsElements: {
  [key in TripSetupSteps]: React.ReactNode;
} = {
  details: <TripSetupDetailsStep />,
  stops: <TripSetupStopsStep />,
  summary: <TripSetupSummaryStep />,
};

export const tripSetupStepsLabels: {
  [key in TripSetupSteps]: string;
} = {
  details: 'Details',
  stops: 'Stops',
  summary: 'Summary',
};

const getAdjacentStep = (step: TripSetupSteps, side: 'next' | 'prev') => {
  const indexOfStep = tripSetupSteps.indexOf(step);
  if (side === 'next' && tripSetupSteps.length > indexOfStep + 1) {
    return tripSetupSteps[indexOfStep + 1] as TripSetupSteps;
  }
  if (side === 'prev' && indexOfStep > 0) {
    return tripSetupSteps[indexOfStep - 1] as TripSetupSteps;
  }
  return null;
};

export const useTripSetup = () => {
  const [activeStep, setActiveStep] = React.useState<TripSetupSteps>('details');
  const [completedSteps, setCompetedSteps] = React.useState<TripSetupSteps[]>(
    []
  );

  const handleNextStep = React.useCallback((currentStep: TripSetupSteps) => {
    const nextStep = getAdjacentStep(currentStep, 'next');
    if (nextStep != null) {
      setActiveStep(nextStep);
      if (tripSetupSteps.indexOf(nextStep) === tripSetupSteps.length - 1) {
        setCompetedSteps((currentCompletedSteps) => [
          ...currentCompletedSteps,
          currentStep,
          nextStep,
        ]);
      } else {
        setCompetedSteps((currentCompletedSteps) => [
          ...currentCompletedSteps,
          currentStep,
        ]);
      }
    }
  }, []);

  const handlePreviousStep = React.useCallback(() => {
    setActiveStep(
      (currentStep) => getAdjacentStep(currentStep, 'prev') ?? currentStep
    );
  }, []);

  const handleGoToStep = React.useCallback(
    (targetStep: TripSetupSteps) => {
      if (completedSteps.includes(targetStep)) {
        setActiveStep(targetStep);
      }
    },
    [completedSteps]
  );

  return {
    activeStep,
    completedSteps,
    onPreviousStep: handlePreviousStep,
    onGoToStep: handleGoToStep,
    onNextStep: handleNextStep,
  };
};
