import ConfigService from './ConfigService/ConfigService';
import {encodeUrl} from './Util';
import ResponseService from './ResponseService';
import {authStore} from '../store';

const serverUrl = ConfigService.serverUri;
const queryUrl = `${serverUrl}/api/planning_solution`;

const PlanningService = {
  query: async (params) => {
    Object.keys(params).forEach(
      // eslint-disable-next-line no-param-reassign
      (key) => params[key] == null && delete params[key]
    );
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(encodeUrl(queryUrl, params), {
      method: 'GET',
      mode: 'cors',
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  delete: async (planningSolutionId) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(`${queryUrl}/${planningSolutionId}`, {
      method: 'DELETE',
      mode: 'cors',
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  update: async (planningSolution) => {
    if (!planningSolution.id) {
      return PlanningService.create(planningSolution);
    }
    Object.keys(planningSolution).forEach(
      // eslint-disable-next-line no-param-reassign
      (key) => planningSolution[key] == null && delete planningSolution[key]
    );
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(`${queryUrl}/${planningSolution.id}`, {
      method: 'PATCH',
      mode: 'cors',
      body: JSON.stringify(planningSolution),
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  create: async (planningSolution) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(`${queryUrl}`, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(planningSolution),
      headers,
    });
    return ResponseService.checkStatus(response);
  },
};

export default PlanningService;
