import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

export const drawerWidth = 260;

const LeftDrawerStyles = (theme: Theme) =>
  createStyles({
    root: {
      width: drawerWidth,
      flexShrink: 0,
    },
    paper: {
      width: drawerWidth,

      overflowY: 'auto',
      overflowX: 'hidden',
      height: '100%',
    },
    rootMobile: {flexGrow: 1, display: 'flex', flex: 1},
    paperMobile: {flexGrow: 1, overflow: 'auto', height: '100%'},
    header: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    button: {
      position: 'fixed',
      // position: 'absolute',
      top: 52,
      marginLeft: drawerWidth - 17,

      backgroundColor: theme.palette.secondary.light,
      zIndex: 1200,
      color: 'white',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '20px',
      // borderRadiusTopRight: '20px',
      width: 34,
      minWidth: 34,
      height: 34,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      visibility: 'hidden',
      pointerEvents: 'none',
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
        // borderColor: '#0062cc',
        boxShadow: 'none',
      },
    },
    buttonShow: {visibility: 'visible', pointerEvents: 'auto'},
    buttonClosed: {
      marginLeft: -7,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  });

export const useLeftDrawerStyles = makeStyles(LeftDrawerStyles, {
  name: 'LeftDrawerStyles',
});
