import {
  ButtonBase,
  MenuList,
  Paper,
  PaperProps,
  Typography,
  useTheme,
} from '@mui/material';
import React, {useCallback, useMemo} from 'react';
import styled from '@emotion/styled';
import {useHistory} from 'react-router-dom';
import {css} from 'emotion';

interface IDashboardCardListItem {
  label: string;
  path?: string;
}

interface IDashboardCardData {
  label: string;
  icon?: React.ReactNode;
  items: IDashboardCardListItem[];
  path?: string;
}

interface IDashboardCardProps {
  cardData: IDashboardCardData;
  listItemsLimit?: number;
  onNavigated?: (path: string) => void;
}

const DashboardListItem = styled(ButtonBase)`
  padding: calc(8px * var(--scaling)) 0px;
  display: flex;
`;

const CardCustom = (props: PaperProps) => {
  const CardCustomTp = styled(Paper)`
    --text-scaling: 0.7;
    --scaling: 0.75;
    border-radius: 10px;
    width: calc(355px * var(--scaling));
    height: calc(330px * var(--scaling));
    padding: calc(25px * var(--scaling));
  `;
  return <CardCustomTp elevation={2} {...props} />;
};

const IconContainer = styled.div`
  display: inline-flex;
  & > svg {
    height: calc(38px * var(--scaling));
    width: auto;
  }
`;

const listItemTypographyClass = css`
  font-weight: 600;
  font-size: calc(24px * var(--text-scaling));
  line-height: calc(28px * var(--text-scaling));
  text-transform: capitalize;
`;
const cardTitleTypographyClass = css`
  font-size: calc(36px * var(--text-scaling));
  font-weight: 600;
  line-height: calc(42px * var(--text-scaling));
`;
const headerDivTypographyClass = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: calc(4px * var(--scaling));
  padding-bottom: calc(16px * var(--scaling));
`;

export default function DashboardCard({
  cardData: {label, icon, items, path},
  listItemsLimit,
  onNavigated,
}: IDashboardCardProps) {
  const history = useHistory();
  const theme = useTheme();

  const shouldTrimList = listItemsLimit && items.length > listItemsLimit;
  const useItems = useMemo(
    () =>
      listItemsLimit && shouldTrimList
        ? items.slice(0, listItemsLimit - 1)
        : items,
    [items, listItemsLimit, shouldTrimList]
  );

  const onMenuItemClicked = useCallback(
    (path: string) => {
      history.push(path);
      onNavigated && onNavigated(path);
    },
    [history, onNavigated]
  );

  return (
    <>
      <CardCustom>
        <div className={headerDivTypographyClass}>
          <IconContainer
            style={{marginRight: '10px', color: theme.palette.text.secondary}}
          >
            {icon}
          </IconContainer>
          <Typography
            color="text.secondary"
            component="span"
            className={cardTitleTypographyClass}
          >
            {label}
          </Typography>
        </div>
        <MenuList style={{padding: '0'}}>
          {useItems.map((item, i) => (
            <DashboardListItem
              key={i}
              onClick={() => item.path && onMenuItemClicked(item.path)}
            >
              <Typography
                color="text.secondary"
                className={listItemTypographyClass}
              >
                {item.label}
              </Typography>
            </DashboardListItem>
          ))}
          {shouldTrimList && (
            <DashboardListItem
              key={'other'}
              onClick={() => path && onMenuItemClicked(path)}
            >
              <Typography
                color="text.secondary"
                className={listItemTypographyClass}
              >
                {'Other'}
              </Typography>
            </DashboardListItem>
          )}
        </MenuList>
      </CardCustom>
    </>
  );
}
