import React from 'react';
import {Stack} from '@mui/material';
import {ApiDocsFrame} from './ApiDocsFrame';

export const TrackingApiDocs: React.FC = () => {
  return (
    <Stack flex={1}>
      <ApiDocsFrame title="Tracking Api Docs" url="/tracking/swagger-ui/" />
    </Stack>
  );
};
