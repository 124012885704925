import React from 'react';
import {ITripDebriefForm} from '../form/TripDebriefForm.types';
import * as Yup from 'yup';
import {
  MasterTripDebriefOrderLineSnapshot,
  MasterTripDebriefOrderSnapshot,
  MasterTripDebriefSnapshot,
  MasterTripDebriefSnapshotData,
} from '@onroadvantage/onroadvantage-api';
import {useAppNotifications} from '../../../contexts';
import {TripDebriefContext} from '../TripDebriefContext';
import {
  NUMBER_ERROR_MESSAGE,
  TemplateCard,
  TemplateForm,
  TOnFormikSubmit,
} from '../../../factory/template';
import {TripDebriefDisabledTextField} from '../form/TripDebriefDisabledTextField';
import {TripDebriefTextField} from '../form/TripDebriefTextField';
import {getOrder, getOrderLine} from '../helpers';

/** ITripDebriefOrderLineFormSnapshot -> For the editable field values that are overridable  */
export interface ITripDebriefOrderLineFormSnapshot {
  loadedQuantity?: number | null | undefined;
  offloadedQuantity?: number | null | undefined;
}

/**
 * ITripDebriefOrderLineForm -> For the interface typing of the form, which extends from ITripDebriefForm which is a
 * generic type that maps the ITripDebriefOrderLineFormSnapshot interface to a snapshot and override key. We then also
 * pass any other props the debrief has that is editable, but what is not overridable (in other words it's not in the
 * MasterTripDebriefSnapshot interface, only on the root MasterTripDebriefSnapshotData interface).
 */
export type ITripDebriefOrderLineForm =
  ITripDebriefForm<ITripDebriefOrderLineFormSnapshot>;

/**
 * ITripDebriefOrderLineFormDisplayOnly -> For the form values that are not editable, thus we keep them separate from
 * the formik typing so that we can ensure they can't get edited by accident
 */
export interface ITripDebriefOrderLineFormDisplayOnly {
  orderNumber?: string | null | undefined;
  productName?: string | null | undefined;
  productUom?: string | null | undefined;
  plannedQuantity?: number | null | undefined;
}

/**
 * snapshotValidation -> Validation schema for the ITripDebriefOrderLineFormSnapshot formik typed fields. We put them
 * in a separate schema so that we don't need to declare the schema twice in the root validationSchema.
 */
const snapshotValidation: Yup.SchemaOf<ITripDebriefOrderLineFormSnapshot> =
  Yup.object({
    loadedQuantity: Yup.number().typeError(NUMBER_ERROR_MESSAGE).nullable(),
    offloadedQuantity: Yup.number().typeError(NUMBER_ERROR_MESSAGE).nullable(),
  });

/** validationSchema -> Validation schema for the form's typing (ITripDebriefOrderLineForm)  put together */
const validationSchema: Yup.SchemaOf<ITripDebriefOrderLineForm> = Yup.object({
  snapshot: snapshotValidation,
  override: snapshotValidation,
});

/**
 * Reason why we want to keep the snapshot and override separate here is because in the TripDebriefTextField Component
 * we use the snapshot value as its initialValue, and the override value is its currentValue. Then when they don't match
 * we can give them the option to reset the changedValue (override value) back to the initialValue (snapshot value)
 */
const getInitialValues = (
  orderLineSnapshot: MasterTripDebriefOrderLineSnapshot | null | undefined,
  orderLineOverrideData: MasterTripDebriefOrderLineSnapshot | null | undefined
): ITripDebriefOrderLineForm | undefined => {
  if (orderLineSnapshot) {
    return {
      snapshot: {
        loadedQuantity: orderLineSnapshot.actualLoadedQuantity,
        offloadedQuantity: orderLineSnapshot.actualQuantity,
      },
      override: orderLineOverrideData
        ? {
            loadedQuantity: orderLineOverrideData.actualLoadedQuantity,
            offloadedQuantity: orderLineOverrideData.actualQuantity,
          }
        : {},
    };
  }
  return undefined;
};

/**
 * Reason for keeping this separate from the getInitialValues, is that we want to keep editable and none-editable fields
 * separate to ensure none-editable don't get edited, as the BE doesn't currently check for the none-editable fields.
 */
const getDisplayOnlyValues = (
  orderLineSnapshot: MasterTripDebriefOrderLineSnapshot | null | undefined,
  order: MasterTripDebriefOrderSnapshot | null | undefined
): ITripDebriefOrderLineFormDisplayOnly | undefined => {
  if (orderLineSnapshot) {
    return {
      orderNumber: order?.orderNumber,
      plannedQuantity: orderLineSnapshot.quantity,
      productName: orderLineSnapshot.product?.name,
      productUom: orderLineSnapshot.product?.uom,
    };
  }
  return undefined;
};
export const TripDebriefOrderLineForm: React.FC = () => {
  const notify = useAppNotifications();
  const {
    masterTripDebriefData,
    submitting,
    loading,
    orderLineId,
    onUpdateSubmit,
  } = React.useContext(TripDebriefContext);
  /**
   *  Destructure masterTripDebriefData, need to add || ({} as MasterTripDebriefSnapshotData) for typescript, since
   *  masterTripDebriefData is nullable.
   */
  const {approvedForBilling, snapshotData, overrideData} =
    masterTripDebriefData || ({} as MasterTripDebriefSnapshotData);
  /**
   *  Destructure snapshotData, need to add || ({} as MasterTripDebriefSnapshot) for typescript, since
   *  snapshotData is nullable.
   */
  const {orders} = snapshotData || ({} as MasterTripDebriefSnapshot);
  /**
   *  Destructure overrideData, need to add || ({} as MasterTripDebriefSnapshot) for typescript, since
   *  overrideData is nullable.
   */
  const {orders: ordersOverride} =
    overrideData || ({} as MasterTripDebriefSnapshot);

  const [initialValues, setInitialValues] = React.useState<
    ITripDebriefOrderLineForm | undefined
  >({override: {}, snapshot: {}});

  const [displayOnlyValues, setDisplayOnlyValues] = React.useState<
    ITripDebriefOrderLineFormDisplayOnly | undefined
  >();

  /**
   * handleSubmit -> Handles the formik's submit.
   * We extract the relevant ITripDebriefOrderLineForm values, such as override, and send them to the onUpdateSubmit
   * handler (from the Context) in the correct format whether it is overrideData (MasterTripDebriefSnapshot) or
   * debriefData (MasterTripDebrief). We also handle the formik submitting state in this formik handler, thus not making
   * it necessary for a formik innerRef.
   * We also add a notify exception for 'No data was change', but this should never be reached.
   */
  const handleSubmit = React.useCallback<
    TOnFormikSubmit<ITripDebriefOrderLineForm>
  >(
    async (values, formikHelpers) => {
      formikHelpers.setSubmitting(true);
      try {
        if (values.override) {
          const orderLine = getOrderLine(orders, orderLineId);
          const {override} = values;
          const {loadedQuantity, offloadedQuantity} = override;
          /** Order line with updated formik values */
          const newOrderLineValues: MasterTripDebriefOrderLineSnapshot = {
            ...orderLine,
            id: orderLineId,
            actualQuantity: offloadedQuantity,
            actualLoadedQuantity: loadedQuantity,
          };
          /** Check to see if the current order line was relevant lines that was already overridden on the same order */
          const orderInOverride = ordersOverride?.find(
            ({id}) => id === orderLine?.orderId
          );
          /**
           * Then if an order was found, add the newOrderLineValues onto that order's lines, and also filter out the current
           * lines with the current orderLineId just in case it already exists, and we can then safely override it cause
           * we will have it's initial overridden values sent with in any case.
           */
          const updateOrderInOverride = orderInOverride
            ? {
                ...orderInOverride,
                id: orderLine?.orderId,
                lines: [
                  ...(orderInOverride.lines?.filter(
                    ({id}) => id !== orderLineId
                  ) ?? []),
                  newOrderLineValues,
                ],
              }
            : undefined;
          /**
           * Then we can update the final overriddenOrders. If there was an orderInOverride an an updateOrderInOverride
           * We'll first filter out the existing order and then and the updateOrderInOverride to the orders array, else
           * we know it is an order that hasn't been edited yet, so we spread any potentially existing orders, and add a
           * new order with the newOrderLineValues, and since it is the first orderLine being edited for the order, we don't
           * need to do any spread operations and can only pass [newOrderLineValues] to the order's lines
           */
          const overriddenOrders: MasterTripDebriefOrderSnapshot[] =
            orderInOverride && updateOrderInOverride
              ? [
                  ...(ordersOverride?.filter(
                    ({id}) => id !== orderLine?.orderId
                  ) ?? []),
                  updateOrderInOverride,
                ]
              : [
                  ...(ordersOverride ?? []),
                  {id: orderLine?.orderId, lines: [newOrderLineValues]},
                ];
          await onUpdateSubmit({
            overrideData: {
              orders: overriddenOrders,
            },
          });
        } else {
          notify('info', 'No data was changed');
        }
      } finally {
        formikHelpers.setSubmitting(false);
      }
    },
    [orders, orderLineId, ordersOverride, onUpdateSubmit, notify]
  );

  React.useEffect(() => {
    const orderLineSnapshot = getOrderLine(orders, orderLineId);
    const orderLineOverride = getOrderLine(ordersOverride, orderLineId);

    const values = getInitialValues(orderLineSnapshot, orderLineOverride);

    const order = getOrder(orders, orderLineSnapshot?.orderId);

    setDisplayOnlyValues(getDisplayOnlyValues(orderLineSnapshot, order));

    if (values) {
      setInitialValues(values);
    }

    return () => {
      setInitialValues({override: {}, snapshot: {}});
    };
  }, [orderLineId, orders, ordersOverride]);

  /**
   * TripDebriefDisabledTextField -> Display only fields, thus none editable
   * TripDebriefTextField -> Overridable and editable fields. Also handles the reset to initialValue functionality
   */
  return (
    <TemplateCard title="Trip Order Edit" loading={loading} elevation={0}>
      <TemplateForm<ITripDebriefOrderLineForm>
        onSubmit={handleSubmit}
        initialValues={initialValues}
        submitting={submitting}
        validationSchema={validationSchema}
        permission={{name: 'Trip Debrief', type: 'Edit'}}
        disabled={approvedForBilling}
        enableReinitialize
      >
        <TripDebriefDisabledTextField
          name="orderNumber"
          label="Order Number"
          type="string"
          value={displayOnlyValues?.orderNumber}
        />
        <TripDebriefDisabledTextField
          name="productName"
          label="Product Name"
          type="string"
          value={displayOnlyValues?.productName}
        />
        <TripDebriefDisabledTextField
          name="productUom"
          label="Product Uom"
          type="string"
          value={displayOnlyValues?.productUom}
        />
        <TripDebriefDisabledTextField
          name="plannedQuantity"
          label="Planned Quantity"
          type="number"
          value={displayOnlyValues?.plannedQuantity}
        />
        <TripDebriefTextField
          name="loadedQuantity"
          label="Loaded Quantity"
          type="number"
          fullWidth
        />
        <TripDebriefTextField
          name="offloadedQuantity"
          label="Offloaded Quantity"
          type="number"
          fullWidth
        />
      </TemplateForm>
    </TemplateCard>
  );
};
