import React from 'react';
import {useTripSettingsViewLayoutStyles} from './TripSettingsViewLayout.style';
import {TripViewLayout, useTripSettingsContext} from '../tripSettingsContext';
import {IconButton, ListItem, ListItemIcon, ListItemText} from '@mui/material';
import {DragIndicator, Visibility, VisibilityOff} from '@mui/icons-material';
import clsx from 'clsx';

export interface TripSettingsViewLayoutItemProps {
  view: TripViewLayout;
  isSortable?: boolean;
}

export const TripSettingsViewLayoutItem: React.FC<
  TripSettingsViewLayoutItemProps
> = ({view, isSortable}) => {
  const classes = useTripSettingsViewLayoutStyles();
  const {updateTripViewLayoutHidden} = useTripSettingsContext();
  const memoizedView = React.useMemo(
    () => ({hidden: view.hidden, label: view.label}),
    [view]
  );

  const handleUpdateHidden = React.useCallback(() => {
    updateTripViewLayoutHidden(memoizedView.label, !memoizedView.hidden);
  }, [memoizedView, updateTripViewLayoutHidden]);

  return (
    <ListItem
      className={clsx(classes.listItem, {
        [classes.listItemSortable]: isSortable,
      })}
      secondaryAction={
        !isSortable ? (
          <IconButton
            edge="end"
            aria-label="hidden toggle"
            onClick={handleUpdateHidden}
          >
            {memoizedView.hidden ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        ) : null
      }
    >
      {isSortable ? (
        <ListItemIcon className={classes.listItemIcon}>
          <DragIndicator />
        </ListItemIcon>
      ) : null}
      <ListItemText primary={memoizedView.label} />
    </ListItem>
  );
};
