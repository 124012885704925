import React from 'react';
import {
  ApiOperationalEventTypeGetRequest,
  OperationalEventType,
  OperationalEventTypeListResponse,
} from '@onroadvantage/onroadvantage-api';
import {useAppNotifications} from '../../../contexts';
import {operationalEventTypeApi} from '../../../api';
import {
  TemplateTableContextProps,
  TLoadList,
  TOnInlineAdd,
  TOnInlineEdit,
  useTemplateTable,
} from '../../../factory/template';
import {RoleService} from '../../../service';

export type OperationalEventTypeContextProps = TemplateTableContextProps<
  OperationalEventType,
  OperationalEventTypeListResponse
>;

export const OperationalEventTypeContext =
  React.createContext<OperationalEventTypeContextProps>({
    loading: false,
    list: [],
    currentPage: 1,
    loadList: async () => {},
  });

export const OperationalEventTypeContextProvider: React.FC = ({children}) => {
  const notify = useAppNotifications();
  // Template Table
  const [
    {
      // States
      currentPage,
      filters,
      hasPermission,
      itemTotal,
      list,
      loading,
      pageSize,
      pageTotal,
      sorting,
    },
    {
      // Getters
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
    },
  ] = useTemplateTable<OperationalEventType, ApiOperationalEventTypeGetRequest>(
    {
      editPermission: 'Edit OperationalEventType',
      addPermission: 'Add OperationalEventType',
      deletePermission: 'Delete OperationalEventType',
      viewPermission: 'OperationalEventType List',
    }
  );

  const loadList = React.useCallback<
    TLoadList<OperationalEventTypeListResponse>
  >(
    async (options) => {
      setLoading(true);
      try {
        const requestObj = getRequestObj(
          [
            'name',
            'externalReference',
            'integrationEnabled',
            'telematicsConfigId',
          ],
          options
        );
        const response =
          await operationalEventTypeApi.apiOperationalEventTypeGet(requestObj);
        return getResponse(response, options);
      } catch (e) {
        notify('error', 'Failed to load operational event type list');
      } finally {
        setLoading(false);
      }
    },
    [getRequestObj, getResponse, notify, setLoading]
  );

  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  const handleInlineEdit = React.useCallback<TOnInlineEdit>(
    async (savedChanges) => {
      setLoading(true);
      try {
        for (const change of savedChanges) {
          if (change.id) {
            await operationalEventTypeApi.apiOperationalEventTypeOperationalEventTypeIdPatch(
              {
                operationalEventTypeId: parseInt(change.id),
                body: change.newValues,
              }
            );
          }
        }
        await loadList();
        notify('success', 'Updated admin operational event type');
      } catch (e) {
        notify(
          'error',
          e.message ?? 'Failed to update admin operational event type'
        );
      } finally {
        setLoading(false);
      }
    },
    [loadList, notify, setLoading]
  );

  const handleInlineAdd = React.useCallback<TOnInlineAdd>(
    async (changes) => {
      setLoading(true);
      try {
        let didCreate = false;
        for (const change of changes) {
          if ('name' in change && 'externalReference' in change) {
            const telematicsConfigId = change.telematicsConfig?.value;
            await operationalEventTypeApi.apiOperationalEventTypePost({
              body: {
                name: change.name,
                externalReference: change.externalReference,
                integrationEnabled: !!change.integrationEnabled,
                contractIds:
                  'contracts' in change &&
                  change.contracts.length > 0 &&
                  'value' in change.contracts[0]
                    ? change.contracts.reduce(
                        (acc: number[], contract: unknown) => {
                          if (
                            typeof contract === 'object' &&
                            contract != null &&
                            'value' in contract &&
                            typeof (contract as {value: unknown}).value ===
                              'number'
                          ) {
                            return [
                              ...acc,
                              (contract as {value: number}).value,
                            ];
                          }
                          return acc;
                        },
                        []
                      )
                    : [],
                telematicsConfigId: telematicsConfigId,
              },
            });
            didCreate = true;
          }
        }
        await loadList();
        if (didCreate) {
          notify('success', 'Created operational event type');
        } else {
          notify('warning', 'Could not create operational event type');
        }
      } catch (e) {
        notify('error', 'Failed to create admin operational event type');
      } finally {
        setLoading(false);
      }
    },
    [loadList, notify, setLoading]
  );

  const handleDelete = React.useCallback(
    async (row: OperationalEventType) => {
      setLoading(true);
      try {
        if (row.id) {
          await operationalEventTypeApi.apiOperationalEventTypeOperationalEventTypeIdDelete(
            {
              operationalEventTypeId: row.id,
            }
          );
          notify('success', 'Deleted operational event type');
        } else {
          notify('warning', 'Unable to delete operational event type');
        }
        await loadList();
      } catch (e) {
        notify('error', 'Failed to delete operational event type');
      } finally {
        setLoading(false);
      }
    },
    [loadList, notify, setLoading]
  );

  const value: OperationalEventTypeContextProps = {
    // Template Table
    list,
    loadList,
    hasPermission,
    loading,
    cleanupList,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onFiltersChange: handleFiltersChange,
    onInlineEdit: RoleService.hasOneOfPermissions([
      {permissionName: 'Edit OperationalEventType', type: 'Edit'},
      {permissionName: 'Edit OperationalEventTypeToggles', type: 'Edit'},
    ])
      ? handleInlineEdit
      : undefined,
    onInlineAdd: hasPermission.add ? handleInlineAdd : undefined,
    onDelete: hasPermission.delete ? handleDelete : undefined,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onRefresh: handleRefresh,
  };

  return (
    <OperationalEventTypeContext.Provider value={value}>
      {children}
    </OperationalEventTypeContext.Provider>
  );
};
