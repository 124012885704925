import React from 'react';
import {Card, CardContent, Stack, Typography} from '@mui/material';
import {useTripDebriefErrorOccurred} from './TripDebriefErrorOccurred.style';

export const TripDebriefErrorOccurred: React.FC = () => {
  const classes = useTripDebriefErrorOccurred();
  return (
    <Stack className={classes.root}>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h3" gutterBottom>
            Failed to fetch:
          </Typography>
          <Typography variant="h4">
            Please try again, or contact vantage support
          </Typography>
        </CardContent>
      </Card>
    </Stack>
  );
};
