import React from 'react';
import _ from 'lodash';
import {
  ITemplateControllerRoute,
  TemplateController,
} from '../../factory/template';
import {CustomerContext, CustomerContextProvider} from './CustomerContext';
import {CustomerList} from './CustomerList';
import {CustomerIdHandler} from './CustomerIdHandler';
import {CustomerForm} from './CustomerForm';
import {Customer} from './Customer';

export const CustomerController: React.FC = () => {
  return (
    <CustomerContextProvider>
      <CustomerControllerComponent />
    </CustomerContextProvider>
  );
};

const CustomerControllerComponent: React.FC = () => {
  const {customer, loadingSingleItem} = React.useContext(CustomerContext);
  const routes: ITemplateControllerRoute[] = React.useMemo(
    () => [
      {title: 'Customer List', component: CustomerList},
      {
        path: '/add',
        title: 'New Customer',
        name: 'Add Customer',
        type: 'Add',
        component: () => <CustomerForm isAdd />,
      },
      {
        path: '/:customerId',
        title: (params) =>
          loadingSingleItem
            ? 'Customer Loading...'
            : customer?.name ??
              `Customer ${_.get(params, 'customerId', 'Single')}`,
        component: Customer,
        IdHandler: CustomerIdHandler,
      },
    ],
    [loadingSingleItem, customer?.name]
  );
  return <TemplateController rootPath="/customerlist" routes={routes} />;
};
