import React from 'react';
import {DataTypeProvider} from '@devexpress/dx-react-grid';
import {OpenInNew} from '@mui/icons-material';
import {TableActionButtonBase} from './TableActionButtonBase';
import {useTemplateTableActions} from '../../providers';

export const TableActionButtonNavigate: React.FC<
  DataTypeProvider.ValueFormatterProps
> = ({row}) => {
  const {onAuxNavigate, onNavigate} = useTemplateTableActions();
  const handleAuxNavigate = React.useCallback(() => {
    if (onAuxNavigate) {
      onAuxNavigate(row);
    }
  }, [row, onAuxNavigate]);

  const handleNavigate = React.useCallback(() => {
    if (onNavigate) {
      onNavigate(row);
    }
  }, [row, onNavigate]);

  if (!onNavigate) {
    return null;
  }

  return (
    <TableActionButtonBase
      data-cy="TemplateTableActionsNavigate"
      onAuxClick={handleAuxNavigate}
      onClick={handleNavigate}
    >
      <OpenInNew />
    </TableActionButtonBase>
  );
};
