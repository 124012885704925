import React from 'react';
import {Listing} from '@onroadvantage/onroadvantage-api';
import {debounce} from '@mui/material';
import {
  Autocomplete,
  AutocompleteOptionType,
  TAutocompleteOnChange,
  TAutocompleteOnInputChange,
} from '../../../autocomplete';
import {listingApi} from '../../../../api';
import {useAppNotifications} from '../../../../contexts';
import {
  usePlanningBoardContext,
  usePlanningBoardGanttContext,
} from '../../planningBoardContext';
import {usePlanningBoardToolbarSearchStyles} from './PlanningBoardToolbarSearch.style';

export const PlanningBoardToolbarSearchSite: React.FC = () => {
  const notify = useAppNotifications();
  const classes = usePlanningBoardToolbarSearchStyles();

  const {planningMasterTrips, masterMasterTrips, bookingMasterTrips} =
    usePlanningBoardContext();
  const {searchedSiteValues, setSearchedTrips, setSearchedSiteValues} =
    usePlanningBoardGanttContext();

  const [listing, setListing] = React.useState<Listing[]>([]);
  const [localLoading, setLocalLoading] = React.useState<boolean>(false);

  const getListing = React.useCallback(
    async (filter?: string) => {
      setLocalLoading(true);
      try {
        const response = await listingApi.apiListingGet({
          model: 'Node',
          query: filter,
        });
        setListing(response.items ?? []);
      } catch (e) {
        notify('error', `Failed to load Node listing`);
      } finally {
        setLocalLoading(false);
      }
    },
    [notify]
  );

  const getListingDebounce = React.useRef(debounce(getListing, 500)).current;

  const handleInputChange = React.useCallback<TAutocompleteOnInputChange>(
    async (event, value) => {
      setLocalLoading(true);
      await getListingDebounce(value);
    },
    [getListingDebounce]
  );

  const handleChange = React.useCallback<TAutocompleteOnChange>(
    async (event, values) => {
      setSearchedTrips([]);
      if (
        values != null &&
        typeof values === 'object' &&
        Array.isArray(values) &&
        values.length > 0 &&
        typeof values[0] === 'object'
      ) {
        setSearchedSiteValues(values as AutocompleteOptionType[]);

        const allTrips = bookingMasterTrips
          .concat(masterMasterTrips)
          .concat(planningMasterTrips);

        allTrips.forEach(({id, trip}) => {
          if (!id || !trip || !trip.stops || trip.stops.length === 0) return;

          const nodeIds = trip.stops
            .filter(({nodeId}) => nodeId)
            .map(({nodeId}) => nodeId?.toString() as string);

          values.forEach((value) => {
            if (
              typeof value === 'object' &&
              value.value &&
              nodeIds.includes(value.value.toString())
            ) {
              setSearchedTrips((prevSearchedTrips) =>
                id ? [...prevSearchedTrips, id] : prevSearchedTrips
              );
            }
          });
        });
      } else {
        setSearchedSiteValues([]);
        setSearchedTrips([]);
      }
    },
    [
      bookingMasterTrips,
      masterMasterTrips,
      planningMasterTrips,
      setSearchedSiteValues,
      setSearchedTrips,
    ]
  );

  React.useEffect(() => {
    getListing();
    return () => {
      setListing([]);
    };
  }, [getListing]);

  /** Display the selected (searched) items first */
  const sortedOptions = React.useMemo(
    () =>
      listing.reduce((acc, option) => {
        const accumulatedValues = acc.map(({value}) => value);

        if (option.value && !accumulatedValues.includes(option.value)) {
          return [...acc, option];
        }

        return acc;
      }, searchedSiteValues),
    [listing, searchedSiteValues]
  );

  return (
    <Autocomplete
      name="siteSearch"
      size="small"
      classes={{input: classes.input}}
      loading={localLoading}
      options={sortedOptions}
      onInputChange={handleInputChange}
      onChange={handleChange}
      value={searchedSiteValues}
      label="Search Site"
      placeholder={
        searchedSiteValues.length > 0
          ? `Searching ${searchedSiteValues.length}`
          : undefined
      }
      renderTags={() => null}
      multiple
      fullWidth
    />
  );
};
