import React from 'react';
import {recipientApi} from '../../../api';
import {
  RecipientTripDriver,
  OrderRecipient,
  RecipientTripVehicle,
} from '@onroadvantage/onroadvantage-api';
import moment from 'moment';

const refreshTimeFormat = '(HH:mm:ss): MMM, DD, YYYY';

export interface IRecipientCardDetail {
  label: string;
  value?: string;
}

export interface RecipientContextProps {
  orders: OrderRecipient[];
  driver?: RecipientTripDriver;
  vehicle?: RecipientTripVehicle;
  refreshTime: string;
  loading: boolean;
  invalid: boolean;
  loadRecipients: () => Promise<void>;
}

export const OrderRecipientContext = React.createContext<RecipientContextProps>(
  {
    loading: true,
    invalid: false,
    orders: [],
    refreshTime: moment().format(refreshTimeFormat),
    loadRecipients: async () => {},
  }
);

interface RecipientContextProviderProps {
  uuid: string;
}

export const RecipientContextProvider: React.FC<
  RecipientContextProviderProps
> = ({uuid, children}) => {
  const [refreshTime, setRefreshTime] = React.useState(
    moment().format(refreshTimeFormat)
  );
  const [invalid, setInvalid] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [orders, setOrders] = React.useState<OrderRecipient[]>([]);
  const [vehicle, setVehicle] = React.useState<RecipientTripVehicle>();
  const [driver, setDriver] = React.useState<RecipientTripDriver>();
  const loadRecipients = React.useCallback(async () => {
    setLoading(true);
    try {
      const response = await recipientApi.apiOrderRecipientUuidGet({
        uuid: uuid,
      });
      if (
        response.vehicle ||
        response.driver ||
        (response.orders && response.orders.length > 0)
      ) {
        setInvalid(false);
        setVehicle(
          response.vehicle?.lastSeen?.latitude &&
            response.vehicle.lastSeen.longitude
            ? response.vehicle
            : {}
        );
        setDriver(response.driver);
        if (response.orders && response.orders.length > 0)
          setOrders(response.orders);
        else setOrders([]);
      } else {
        setInvalid(true);
      }
    } catch (e) {
      setInvalid(true);
    } finally {
      setLoading(false);
    }
  }, [uuid]);

  React.useEffect(() => {
    setRefreshTime(moment().format(refreshTimeFormat));
    loadRecipients();
    return () => setRefreshTime('');
  }, [loadRecipients]);

  const value: RecipientContextProps = {
    loading,
    loadRecipients,
    orders,
    vehicle,
    driver,
    refreshTime,
    invalid,
  };

  return (
    <OrderRecipientContext.Provider value={value}>
      {children}
    </OrderRecipientContext.Provider>
  );
};
