import React from 'react';
import {ContractGroupContext} from './ContractGroupContext';
import {
  TemplateTable,
  TemplateTableConfig,
  TOnInlineAdd,
} from '../../factory/template';
import {
  ContractGroupNestedContract,
  ContractDump,
  Listing,
} from '@onroadvantage/onroadvantage-api';

export const ContractGroupContracts: React.FC = () => {
  const {contractGroup, loading, onUpdateContractGroup, hasPermission} =
    React.useContext(ContractGroupContext);

  const config: TemplateTableConfig<ContractDump> = React.useMemo(
    () => ({
      columns: [
        {
          name: 'code',
          label: 'Contract Code',
          type: 'string',
          model: 'Contract',
          enableEditing: true,
          enableAutocomplete: true,
          enableMulti: true,
        },
        {
          name: 'name',
          label: 'Contract Name',
          type: 'string',
          enableEditing: false,
        },
      ],
      disableToolbar: true,
      disablePagination: true,
      deleteMessage: (row) => ({
        title: 'Contract Group Contract',
        items: {
          ContractGroupName: contractGroup?.name,
          ContractName: row.name,
          ContractCode: row.code,
        },
      }),
      identifier: 'CONTRACT_GROUP_CONTRACTS_LIST',
    }),
    [contractGroup?.name]
  );

  const handleInlineAdd = React.useCallback<TOnInlineAdd>(
    (changes) => {
      changes.forEach((change) => {
        const newContracts: ContractGroupNestedContract[] = [
          ...(contractGroup?.contracts?.map(({id}) => ({id: id as number})) ??
            []),
          ...change.code.map((contract: Listing) => ({id: contract.value})),
        ];

        onUpdateContractGroup({
          name: contractGroup?.name,
          users: contractGroup?.users,
          contracts: newContracts,
        });
      });
    },
    [contractGroup, onUpdateContractGroup]
  );

  const handleDelete = React.useCallback(
    (row: ContractDump) => {
      onUpdateContractGroup({
        name: contractGroup?.name,
        users: contractGroup?.users,
        contracts:
          (contractGroup?.contracts
            ?.filter(({id}) => id !== row.id)
            ?.map(({id}) => ({id})) as ContractGroupNestedContract[]) ?? [],
      });
    },
    [contractGroup, onUpdateContractGroup]
  );

  return (
    <TemplateTable
      config={config}
      list={contractGroup?.contracts ?? []}
      currentPage={1}
      loading={loading}
      onInlineAdd={hasPermission?.edit ? handleInlineAdd : undefined}
      onDelete={hasPermission?.edit ? handleDelete : undefined}
    />
  );
};
