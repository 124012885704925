import React from 'react';
import * as Yup from 'yup';
import {Button, InputAdornment, Stack, Typography} from '@mui/material';
import {IVantageDialogRef, VantageDialog} from '../../dialog';
import {
  NUMBER_ERROR_MESSAGE,
  TemplateForm,
  TOnFormikSubmit,
} from '../../../factory/template';
import {FormikSubmit, FormikTextField} from '../../formik';

export interface IEventsPaneCardMuteEventDialogForm {
  period: number;
  comment: string;
}

interface EventsPaneCardMuteEventDialogProps {
  onMuteEvent: TOnFormikSubmit<IEventsPaneCardMuteEventDialogForm>;
  onCancel: () => void;
  submitting: boolean;
}

const schema: Yup.SchemaOf<IEventsPaneCardMuteEventDialogForm> = Yup.object({
  period: Yup.number()
    .max(8, '8 hours is the max.')
    .typeError(NUMBER_ERROR_MESSAGE)
    .required('Required'),
  comment: Yup.string().required('Required'),
});

export const EventsPaneCardMuteEventDialog = React.forwardRef<
  IVantageDialogRef,
  EventsPaneCardMuteEventDialogProps
>(({onMuteEvent, onCancel, submitting}, ref) => {
  const initialValues = React.useMemo<IEventsPaneCardMuteEventDialogForm>(
    () => ({period: 0, comment: ''}),
    []
  );

  return (
    <VantageDialog
      title="Mute Event"
      ref={ref}
      maxWidth="md"
      disableAccept
      disableDecline
    >
      <Stack pt={1}>
        <TemplateForm
          initialValues={initialValues}
          onSubmit={onMuteEvent}
          validationSchema={schema}
          disableActions
        >
          <FormikTextField
            name="period"
            label="Period"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography
                    color="text.secondary"
                    component="legend"
                    variant="body1"
                  >
                    hour(s)
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
          <FormikTextField
            name="comment"
            label="Comment"
            rows={2}
            multiline
            fullWidth
          />
          <Stack justifyContent="flex-end" direction="row" spacing={1}>
            <Button variant="outlined" onClick={onCancel} disabled={submitting}>
              Cancel
            </Button>
            <FormikSubmit title="Mute" loading={submitting} />
          </Stack>
        </TemplateForm>
      </Stack>
    </VantageDialog>
  );
});
