import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Card from '@mui/material/Card';
import CriticalEventActivities from './CriticalEventActivities';
import CriticalEventMedia from './CriticalEventMedia';

class CriticalEventDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
    };
  }

  handleChange = (event, newValue) => {
    this.setState({tabValue: newValue});
  };

  render() {
    const {activities, event} = this.props;
    const {tabValue} = this.state;
    return (
      <Card style={{marginTop: 20, marginBottom: 20, padding: 20}}>
        <Tabs
          value={this.state.tabValue}
          onChange={this.handleChange}
          indicatorColor="primary"
          textColor="primary"
          style={{width: 525}}
        >
          <Tab label="Comments" />
          <Tab label="Media" />
        </Tabs>
        {tabValue === 0 && (
          <CriticalEventActivities event={event} activities={activities} />
        )}
        {tabValue === 1 && <CriticalEventMedia event={event} />}
      </Card>
    );
  }
}

export default CriticalEventDetail;
