import {WebPlanningBoardMasterTripWithIdType} from '../planningBoardContext';

export const getMasterTripsAfterCurrentTrip = (
  trips: WebPlanningBoardMasterTripWithIdType[],
  activeTrip: WebPlanningBoardMasterTripWithIdType
) => {
  if (trips.length === 0) return undefined;

  const activeTripStops = activeTrip.trip?.stops;

  const activeTripStart =
    activeTripStops && activeTripStops[0]?.serviceTimeStart
      ? activeTripStops[0]?.serviceTimeStart
      : activeTrip.trip?.tripStart;

  if (!activeTripStart) return undefined;

  return trips.filter(({trip}) => {
    const tripStart =
      trip?.stops && trip.stops[0]?.serviceTimeStart
        ? trip.stops[0].serviceTimeStart
        : trip?.tripStart;
    return tripStart && tripStart > activeTripStart;
  });
};
