import React from 'react';
import {Stack} from '@mui/material';
import {TripSetupStepper} from './TripSetupStepper';
import {tripSetupStepsElements, useTripSetup} from './useTripSetup';
import {TripSetupContext, TripSetupContextProvider} from './TripSetupContext';
import {TripSetupLoader} from './TripSetupLoader';

export const TripSetup = () => {
  const {activeStep, completedSteps, onNextStep, onPreviousStep, onGoToStep} =
    useTripSetup();

  return (
    <Stack>
      <TripSetupContextProvider>
        <TripSetupStepper
          activeStep={activeStep}
          completedSteps={completedSteps}
          onNextStep={onNextStep}
          onPreviousStep={onPreviousStep}
          onGoToStep={onGoToStep}
        >
          <TripSetupContext.Consumer>
            {(value) =>
              value?.submitting === true ? (
                <TripSetupLoader />
              ) : (
                tripSetupStepsElements[activeStep]
              )
            }
          </TripSetupContext.Consumer>
        </TripSetupStepper>
      </TripSetupContextProvider>
    </Stack>
  );
};
