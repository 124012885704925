import React from 'react';
import {
  Circle,
  FeatureGroup,
  GeoJSON,
  LayersControl,
  Map,
  Polyline,
  Popup,
  TileLayer,
} from 'react-leaflet';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'leaflet-extra-markers';
import 'leaflet-extra-markers/src/assets/less/leaflet.extra-markers.css';
// @ts-expect-error upgrade
import {EditControl} from 'react-leaflet-draw';
import L, {LatLngBounds, Layer, LeafletEvent} from 'leaflet';
import 'leaflet-pulse-icon';
import 'leaflet-pulse-icon/dist/L.Icon.Pulse.css';
import 'leaflet-contextmenu';
import 'leaflet-contextmenu/dist/leaflet.contextmenu.min.css';
import Control from 'react-leaflet-control';
import {inject, observer} from 'mobx-react';
import {autorun} from 'mobx';

import _ from 'lodash';
import DepotMarker from './marker/DepotMarker';
import VehicleMarker from './marker/VehicleMarker';
import CollectionMarker from './marker/CollectionMarker';
import DeliveryMarker from './marker/DeliveryMarker';
import RestMarker from './marker/RestMarker';
import EditLocationIcon from '../../img/icon/EditLocationIcon.svg';
import ActualStopMarker from './marker/ActualStopMarker';
import PlannedStopMarker from './marker/PlannedStopMarker';
import ActualResourceMarker from './marker/ActualResourceMarker';
import PlannedResourceMarker from './marker/PlannedResourceMarker';
import BorderMarker from './marker/BorderMarker';
import InfoMarker from './marker/InfoMarker';
import CriticalEventMarker from './marker/CriticalEventMarker';
import SitePopup from './marker/popup/SitePopup';
import MapCoordinatesStaticPopup from './marker/popup/MapCoordinatesStaticPopup';
import {Loader} from '../loader';
import SiteEditCancelCard from './SiteEditCancelCard';
import SiteEditCommitCard from './SiteEditCommitCard';
import GeoSelectionCard from './GeoSelectionCard';
import TimelinePlayerCard from './TimelinePlayerCard';
import MasterRouteStaticPopup from './marker/popup/MasterRouteStaticPopup';
import {MasterRouteDraggableMarker} from './marker/MasterRouteDraggableMarker';
import GeoSearchCard from './GeoSearchCard';
import {RoleService} from '../../service';
import {
  convertMultiplePolygons2MultiPolygon,
  convertMultiPolygon2MultiplePolygons,
  getBoundsFromMap,
  getChangeInLatLng,
  getInitialMapCenter,
  getLeafletBounds,
  getPositionByTime,
  latLng,
  movePointsByOffset,
} from './mapUtils';
import {
  appNotificationStore,
  mapDisplayStore,
  timelineDisplayStore,
} from '../../stores/mobxStores';
import {getUniqueSetNearbyNodes} from '../../service/Util';
import TileOverlay from './TileOverlay';
import TileOverlayMultiple from './TileOverlayMultiple';
import {MapSelectionItem} from '../broadcast/adhocGeoBroadcastWizard/AdhocMapSelectStep';
import {MapTheme} from '../../stores/mapThemes/themeList';
import AddressMarker from './marker/AddressMarker';
import OperationalEventMarker from './marker/OperationalEventMarker';

import {
  MasterRoute,
  MasterRouteLeg,
  MasterRouteLegWaypoint,
  PlanningMasterTrip,
  PlanningSolution,
  CriticalEventDump,
  NodeStopDump,
} from '@onroadvantage/onroadvantage-api';
// import PlanningSolutionOverviewCard from './PlanningSolutionOverviewCard';
import {IFormTrip} from '../planning/planningSolution/PlanningSolutionForm';
import ValhallaService from '../../service/ValhallaService';
import ConfigService from '../../service/ConfigService/ConfigService';
import {authStore} from '../../store';
import {nodeApi, vehicleApi} from '../../api';
import {MasterRouteLegRoutable} from '../../stores/MapDisplayStore';
import MasterRouteMapFeature from '../masterRoute/masterRouteLeg/masterRouteLegDetails/MasterRouteMapFeature';

const {BaseLayer, Overlay} = LayersControl;

interface EventLatLng {
  target: {
    _latlng: latLng;
  };
}

interface Props {
  openEventMarkerLayer?: Layer;
  openAddressMarkerLayer?: Layer;
  openStopMarkerLayer?: Layer;
  openActualStopMarkerLayer?: Layer;
  openIsochroneLayer?: Layer;
  route?: any;
  masterRoute?: MasterRoute;
  masterRouteLeg?: MasterRouteLegRoutable;
  onDragMasterRouteLegWaypoint?: (
    leafletId: number,
    latlng: L.LatLng,
    waypoint: MasterRouteLegWaypoint,
    remove: boolean
  ) => void;
  externalLayers?: Layer;
  vehicle?: any;
  disableSatellite?: boolean;
  geoSelector?: boolean;
  criticalEvents?: CriticalEventDump[];
  telematicsEvents?: {coordinates: {lat: number; lng: number}};
  siteEditCallback?: (arg: any) => void;
  siteAddCallback?: (param: any) => void;
  onAddressClick?: (address: any) => void;
  updateGeoSelectionLayers?: (param: MapSelectionItem[]) => void;
  planningMasterTrip?: PlanningMasterTrip | undefined;
  planningSolution?: PlanningSolution | undefined;
  planningMasterTripFormik?: IFormTrip | undefined;
  trip?: any;
  zoom?: number;
  actualTrip?: {
    stops?: any[];
  };
  nearbyNodes?: NodeStopDump[];
  siteEdit?: any;
  geoEdit?: any;
  mapDisplayStore?: any;
  site?: any;
  geoSelectionType?: string;
  timelineSliderTime?: Date | null;
}

interface State {
  plannedTrip: {
    stops: any[];
  };
  actualTrip?: {
    stops?: any[];
  };
  zoom: number;
  siteEdit: any;
  center: any;
  nearbyNodes: any;
  showSiteCommitButton: any;
  siteEditLoading: boolean;
  geoEditLoading: boolean;
  dynamicPopup: any;
  nearbyNodesLoadingRadius: any;
  showMapOverlay: any;
  singleOverlayTile: any;
  overlayBounds: any;
  overlayTiles: any;
  editedGeoJson: any;
  geoSelector: boolean;
  geoSelectionLoading: boolean;
  baseLayer: string;
  marker: L.Marker<any> | null;
}

class map extends React.Component<Props, State> {
  // todo: type private class params... there's a lot of them but not much info to go on

  private myMap: any;
  private prevResourceActualPosition: any;
  private prevResourcePlannedPosition: any;
  private siteEdit: any;
  private siteEditStartLatLng: any;
  private siteEditMarker: any;

  private openEventMarkerLayer: any;
  private openAddressMarkerLayer: any;
  private openStopMarkerLayer: any;
  private openActualStopMarkerLayer: any;
  private openIsochroneLayer: any;
  private plannedRouteLayer: any;
  private masterRouteLayer: any;
  private criticalEventMarkersLayer: any;
  private resourceActualMarkerLayer: any;
  private actualTripLayer: any;
  private telematicsRouteLayer: any;
  private vehicleMarkerLayer: any;
  private externalLayersLayer: any;
  private nearbyNodesGeofencesLayer:
    | FeatureGroup<{children: any[]; ref: unknown}>
    | null
    | undefined;
  private plannedTripLayer: any;
  private _editableFG: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      zoom: props.zoom ? props.zoom : 9,
      plannedTrip: props.trip,
      actualTrip: props.actualTrip,
      nearbyNodes: props.nearbyNodes,
      siteEdit: props.siteEdit,
      center: getInitialMapCenter(props),
      showSiteCommitButton: false,
      siteEditLoading: false,
      geoEditLoading: false,
      dynamicPopup: null,
      nearbyNodesLoadingRadius: null,
      showMapOverlay: false,
      singleOverlayTile: false,
      overlayBounds: null,
      overlayTiles: [],
      editedGeoJson: null,
      geoSelector: false,
      geoSelectionLoading: false,
      // sets default and saves current base layer (base map tileset)
      baseLayer: 'HERE Hybrid',
      marker: null,
    };
  }

  componentDidMount = () => {
    autorun(() => {
      if (this.props.mapDisplayStore.openEvent) {
        const openEvent = this.props.mapDisplayStore.openEvent;
        if (this.openEventMarkerLayer) {
          this.fitMapToLayer(this.openEventMarkerLayer);
        }
        if (openEvent.latitude && openEvent.longitude) {
          this.loadNearbyNodes(
            {
              latlng: {lat: openEvent.latitude, lng: openEvent.longitude},
            },
            0.01
          );
        }
      }
      if (this.props.mapDisplayStore.openAddress) {
        if (this.openAddressMarkerLayer) {
          this.fitMapToLayer(this.openAddressMarkerLayer);
        }
      }
      if (this.props.mapDisplayStore.openStop) {
        if (this.openStopMarkerLayer) {
          this.fitMapToLayer(this.openStopMarkerLayer);
        }
      }
      if (this.props.mapDisplayStore.openActualStop) {
        if (this.openActualStopMarkerLayer) {
          this.fitMapToLayer(this.openActualStopMarkerLayer);
        }
      }
      if (this.props.mapDisplayStore.openIsochrone) {
        if (this.openIsochroneLayer) {
          this.fitMapToLayer(this.openIsochroneLayer);
        }
      }
      if (this.props.mapDisplayStore.siteDetailsFormDetails) {
        if (this.siteEditMarker) {
          const details = this.props.mapDisplayStore.siteDetailsFormDetails;
          this.siteEdit.latitude = details.lat;
          this.siteEdit.longitude = details.lng;
          this.siteEditMarker.setLatLng({lat: details.lat, lng: details.lng});
          // TODO move the geofence
          this.myMap.leafletElement.panTo(
            new L.LatLng(details.lat, details.lng)
          );
        }
      }
    });
  };

  closePeripherals = () => {
    this.myMap.leafletElement.closePopup();
    mapDisplayStore.setOpenEvent(null);
    mapDisplayStore.setOpenStop(null);
    mapDisplayStore.setOpenActualStop(null);
    mapDisplayStore.setOpenIsochrone(null);
    mapDisplayStore.setOpenAddress(null);
    // TODO why is this not mapDisplayStore.clearMap
    this.siteEdit = null;
  };

  updateTimelineSliderVisuals = (timelineSliderTime: Date) => {
    const {telematicsEvents, route} = this.props;
    if (telematicsEvents && timelineSliderTime) {
      const resourceActualPosition = getPositionByTime(
        telematicsEvents,
        timelineSliderTime
      );
      let resourcePlannedPosition = getPositionByTime(
        route,
        timelineSliderTime
      );
      try {
        if (resourcePlannedPosition && resourcePlannedPosition.length > 0) {
          resourcePlannedPosition = [
            resourcePlannedPosition[1],
            resourcePlannedPosition[0],
          ];
        }
      } catch (e) {
        // console.log({e});
      }

      if (resourceActualPosition) {
        // if (resourceActualPosition !== this.prevResourceActualPosition) {
        if (
          !_.isEqual(resourceActualPosition, this.prevResourceActualPosition)
        ) {
          if (this.myMap) {
            this.myMap.leafletElement.panTo(
              // todo: fix spread + add in deconstructed props for LatLng
              new L.LatLng(...resourceActualPosition, 0)
            );
            this.closePeripherals();
          }
          this.prevResourceActualPosition = resourceActualPosition;
        }
      } else if (resourcePlannedPosition) {
        if (
          !_.isEqual(resourcePlannedPosition, this.prevResourcePlannedPosition)
        ) {
          if (this.myMap) {
            this.myMap.leafletElement.panTo(
              // todo: fix spread + add in deconstructed props for LatLng
              new L.LatLng(...resourcePlannedPosition, 0)
            );
            this.closePeripherals();
          }
        }
        this.prevResourcePlannedPosition = resourcePlannedPosition;
      }

      return {resourceActualPosition, resourcePlannedPosition};
    } else if (route && timelineSliderTime) {
      let resourcePlannedPosition = getPositionByTime(
        route,
        timelineSliderTime
      );
      try {
        if (resourcePlannedPosition && resourcePlannedPosition.length > 0) {
          resourcePlannedPosition = [
            resourcePlannedPosition[1],
            resourcePlannedPosition[0],
          ];
        }
      } catch (e) {
        // console.log({e});
      }
      return {resourceActualPosition: null, resourcePlannedPosition};
    }
    return null;
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.externalLayers !== nextProps.externalLayers) {
      this.fitMapToLayers();
    }
    this.setState({
      plannedTrip: nextProps.trip,
      actualTrip: nextProps.actualTrip,
      nearbyNodes: nextProps.nearbyNodes,
      siteEdit: nextProps.siteEdit,
    });
  }

  render() {
    const {plannedTrip, actualTrip, siteEdit} = this.state;
    const {externalLayers, vehicle, geoSelector} = this.props;

    if (plannedTrip && Object.keys(plannedTrip).length > 0) {
      return this.renderMap();
    }
    if (actualTrip && Object.keys(actualTrip).length > 0) {
      return this.renderMap();
    }
    if (siteEdit) {
      return this.renderMap();
    }
    if (externalLayers) {
      return this.renderMap();
    }
    if (vehicle) {
      return this.renderMap();
    }
    if (geoSelector === true) {
      return this.renderMap();
    }
    return <Loader />;
  }

  zoomToFeature(bounds: LatLngBounds) {
    const fitBoundsParams = {
      paddingTopLeft: [200, 10],
      paddingBottomRight: [10, 10],
    };
    if (this.myMap) {
      if (bounds.isValid()) {
        this.myMap.leafletElement.fitBounds(bounds, fitBoundsParams);
      } else {
        appNotificationStore.enqueueNotification(
          'warning',
          `Could not determine correct zoom due to bounds being invalid.`
        );
      }
    } else {
      appNotificationStore.enqueueNotification('error', `Map unable to load.`);
    }
  }

  fitMapToLayer = async (parentLayer: FeatureGroup) => {
    // todo: fix null bounds initializer + type
    // @ts-expect-error upgrade
    const bounds = L.latLngBounds(await getLeafletBounds());
    // todo: fix type based on the above
    // @ts-expect-error upgrade
    bounds.extend(await getLeafletBounds(parentLayer));
    if (bounds.isValid()) {
      this.setState({center: bounds.getCenter()});
      this.zoomToFeature(bounds);
      parentLayer.leafletElement.eachLayer((layer: Layer) => {
        layer.openPopup();
      });
    }
  };

  fitMapToLayers = async () => {
    // Setting an 'empty' bounds doesn't seem to be supported officially. ts-ignoring to keep current functionality.
    // @ts-expect-error upgrade
    const bounds = L.latLngBounds(await getLeafletBounds());
    const openEvent = await mapDisplayStore.openEvent;
    const openAddress = await mapDisplayStore.openAddress;

    // Needs to check for bounds, if they exist, before extending with bounds.extend
    const plannedRouteLayerBounds = this.plannedRouteLayer
      ? await getLeafletBounds(this.plannedRouteLayer)
      : null;

    // Needs to check for bounds, if they exist, before extending with bounds.extend
    const masterRouteLayerBounds = this.masterRouteLayer
      ? await getLeafletBounds(this.masterRouteLayer)
      : null;

    const criticalEventMarkersLayerBounds = await getLeafletBounds(
      this.criticalEventMarkersLayer
    );
    const actualTripLayerBounds = await getLeafletBounds(this.actualTripLayer);
    const plannedTripLayerBounds = await getLeafletBounds(
      this.plannedTripLayer
    );
    const telematicsRouteLayerBounds = await getLeafletBounds(
      this.telematicsRouteLayer
    );
    const vehicleMarkerLayerBounds = await getLeafletBounds(
      this.vehicleMarkerLayer
    );
    const externalLayersLayerBounds = await getLeafletBounds(
      this.externalLayersLayer
    );

    plannedRouteLayerBounds && bounds.extend(plannedRouteLayerBounds);
    masterRouteLayerBounds && bounds.extend(masterRouteLayerBounds);
    criticalEventMarkersLayerBounds &&
      bounds.extend(criticalEventMarkersLayerBounds);
    actualTripLayerBounds && bounds.extend(actualTripLayerBounds);
    plannedTripLayerBounds && bounds.extend(plannedTripLayerBounds);
    telematicsRouteLayerBounds && bounds.extend(telematicsRouteLayerBounds);
    vehicleMarkerLayerBounds && bounds.extend(vehicleMarkerLayerBounds);
    externalLayersLayerBounds && bounds.extend(externalLayersLayerBounds);

    if (openEvent) {
      const bounds: any = await getLeafletBounds(this.openEventMarkerLayer);
      if (bounds.isValid()) this.zoomToFeature(bounds as LatLngBounds);

      if (this.openEventMarkerLayer) {
        this.openEventMarkerLayer.leafletElement.eachLayer((layer: Layer) => {
          layer.openPopup();
        });
      }
    } else if (openAddress) {
      // @ts-expect-error upgrade
      this.zoomToFeature(await getLeafletBounds(this.openAddressMarkerLayer));
      if (this.openAddressMarkerLayer) {
        this.openAddressMarkerLayer.leafletElement.eachLayer((layer: Layer) => {
          layer.openPopup();
        });
      }
    } else if (bounds.isValid()) {
      this.setState({center: bounds.getCenter()});
      this.zoomToFeature(bounds);
    }
    // todo: Research and fix type for window (which doesn't apparently have a scale prop)
    // @ts-expect-error upgrade
    if (this.myMap && !window.scale) {
      // @ts-expect-error upgrade
      window.scale = L.control.scale().addTo(this.myMap.leafletElement);
    }
  };

  onSiteEditToggle = (site: any) => {
    this.setState({siteEdit: site});
    this.closePeripherals();
  };

  onEachNearbyGeofence = (feature: any, layer: any) => {
    layer.on({
      click: async (event: {latlng: {lat: number; lng: number}}) => {
        this.setState({
          dynamicPopup: {position: event.latlng, content: <Loader />},
        });
        // todo: type NodeService.js for getting feature and layer working with the call below. For now any
        const site = await nodeApi.apiNodeNodeIdGet({
          nodeId: layer.options.item.id,
        });
        if (site) {
          this.setState((prevState) => ({
            dynamicPopup: {
              position: event.latlng,
              content: (
                <SitePopup
                  site={site}
                  onEdit={this.onSiteEditToggle}
                  showEdit={
                    !prevState.siteEdit &&
                    RoleService.hasPermission('Edit Site', 'Edit')
                  }
                />
              ),
            },
          }));
        }
      },
    });
  };

  _onChange = () => {
    const geojsonData = this._editableFG.leafletElement.toGeoJSON();
    this.setState({showSiteCommitButton: true, editedGeoJson: geojsonData});
  };

  _onEdited = () => {
    this._onChange();
  };

  _onCreated = () => {
    this._onChange();
  };

  _onGeoSelectionCreated = async () => {
    const geojsonData = this._editableFG.leafletElement.toGeoJSON();
    this.setState({geoSelectionLoading: true});
    const data = convertMultiplePolygons2MultiPolygon(geojsonData);
    const vehicles = await vehicleApi.apiVehicleGeojsonPost({
      body: {
        geoJson: data,
      },
    });
    this.setState({
      geoSelectionLoading: false,
    });
    if (this.props.updateGeoSelectionLayers && vehicles.items) {
      // TODO align typings
      this.props.updateGeoSelectionLayers(vehicles.items as any);
    }
  };

  _onGeoSelectionDeleted = async () => {
    const geojsonData = this._editableFG.leafletElement.toGeoJSON();
    if (geojsonData.features.length > 0) {
      this.setState({geoSelectionLoading: true});
      const data = convertMultiplePolygons2MultiPolygon(geojsonData);
      const vehicles = await vehicleApi.apiVehicleGeojsonPost({
        body: {
          geoJson: data,
        },
      });
      this.setState({
        geoSelectionLoading: false,
      });
      if (this.props.updateGeoSelectionLayers) {
        this.props.updateGeoSelectionLayers(vehicles.items as any);
      }
    } else {
      this.setState({
        geoSelectionLoading: false,
      });
      if (this.props.updateGeoSelectionLayers) {
        this.props.updateGeoSelectionLayers([]);
      }
    }
  };

  _onDeleted = () => {
    this._onChange();
  };

  // todo: implement these methods
  _onMounted = () => {
    return null;
  };

  _onEditStart = () => {
    return null;
  };

  _onEditStop = () => {
    return null;
  };

  _onDeleteStart = () => {
    return null;
  };

  _onDeleteStop = () => {
    return null;
  };

  siteEditMarkerDragStart = (e: EventLatLng) => {
    this.siteEditStartLatLng = e.target._latlng;
  };

  siteEditMarkerDragEnd = (e: EventLatLng) => {
    const leafletFG = this._editableFG.leafletElement;
    const offsetXY = getChangeInLatLng(
      this.siteEditStartLatLng,
      e.target._latlng
    );
    leafletFG.eachLayer((layer: any) => {
      layer.editing.disable();
      if (layer.getLatLngs) {
        const points = layer.getLatLngs();
        const offset = movePointsByOffset(offsetXY, points[0], L);
        layer.setLatLngs(offset.points);
        // eslint-disable-next-line no-param-reassign
        // @ts-expect-error upgrade
        layer.editing = new L.Edit.Poly(layer);
      }
      layer.editing.enable();
    });
  };

  _addLayersFromData = (reactFGref: any) => {
    const {siteEdit, geoSelector} = this.state;
    if (!siteEdit && !geoSelector) {
      return;
    }

    // todo: check if geojson local store is needed for edit? edit... it is...
    if (siteEdit === this.siteEdit) {
      return;
    }

    const leafletGeoJSON = new L.GeoJSON(
      // todo: fix type of null given but undefined expected (or vice versa)
      // @ts-expect-error upgrade
      convertMultiPolygon2MultiplePolygons(siteEdit.geofencesGeoJson)
    );
    const Icon = L.icon({
      iconUrl: EditLocationIcon,
      iconSize: [50, 50],
    });

    this.siteEditMarker = L.marker([siteEdit.latitude, siteEdit.longitude], {
      icon: Icon,
    })
      .on('dragstart', this.siteEditMarkerDragStart)
      .on('dragend', this.siteEditMarkerDragEnd);

    const leafletFG = reactFGref.leafletElement;

    leafletGeoJSON.eachLayer((layer: Layer) => {
      leafletFG.addLayer(layer);
    });

    this.siteEditMarker && leafletFG.addLayer(this.siteEditMarker);
    this.siteEdit = siteEdit;
  };

  _onFeatureGroupReady = async (reactFGref: any) => {
    if (!reactFGref || this.state.editedGeoJson) {
      return;
    }

    this._editableFG = null;

    await this._addLayersFromData(reactFGref);

    this._editableFG = reactFGref;
  };

  onSiteEditReset = async () => {
    this.setState({showSiteCommitButton: false});
    const leafletFG = this._editableFG.leafletElement;

    leafletFG.eachLayer((layer: Layer) => {
      leafletFG.removeLayer(layer);
    });

    await this._addLayersFromData(this._editableFG);
  };

  onSiteEditCancel = async () => {
    this.setState({
      showSiteCommitButton: false,
      siteEdit: null,
      editedGeoJson: null,
    });
  };

  onSiteEditSubmit = async () => {
    const {siteEdit, editedGeoJson} = this.state;
    this.setState({siteEditLoading: true});
    siteEdit.geofencesGeoJson =
      convertMultiplePolygons2MultiPolygon(editedGeoJson);
    if (this.siteEditMarker) {
      const latlng = this.siteEditMarker.getLatLng();
      siteEdit.latitude = latlng.lat;
      siteEdit.longitude = latlng.lng;
    }
    const site = await nodeApi.apiNodeNodeIdPatch({
      nodeId: siteEdit.id,
      body: {
        ...siteEdit,
        type: siteEdit.type.label,
      },
    });
    const leafletFG = this._editableFG.leafletElement;
    leafletFG.eachLayer((layer: Layer) => {
      leafletFG.removeLayer(layer);
    });
    if (siteEdit.id) {
      if (this.props.siteEditCallback) {
        this.props.siteEditCallback(site);
      }
    } else if (this.props.siteAddCallback) {
      this.props.siteAddCallback(site);
    }
    this.setState({
      siteEditLoading: false,
      showSiteCommitButton: false,
      siteEdit: site,
      editedGeoJson: null,
    });

    if (
      window.location.pathname.indexOf('vehicle') > 0 ||
      window.location.pathname.indexOf('trip') > 0
    ) {
      window.location.reload();
    }
  };

  showCoordinatesPopup = (e: {latlng: latLng}) => {
    this.setState({
      dynamicPopup: {
        position: e.latlng,
        content: <MapCoordinatesStaticPopup latlng={e.latlng} />,
      },
    });
  };

  onDragRouteWaypoint = async (
    event: LeafletEvent,
    leg: any,
    waypoint: MasterRouteLegWaypoint,
    remove: boolean
  ) => {
    // Add waypoint by leaflet_id
    const leafletId = event.target._leaflet_id;
    const latLng = event.target._latlng;
    const {onDragMasterRouteLegWaypoint} = this.props;
    if (onDragMasterRouteLegWaypoint) {
      onDragMasterRouteLegWaypoint(leafletId, latLng, waypoint, remove);
    }
  };

  onRouteEdit = (adminLayer: L.Layer, event: any, leg: MasterRouteLeg) => {
    const newWaypoint = {
      latitude: event.latlng.lat,
      longitude: event.latlng.lng,
    } as MasterRouteLegWaypoint;
    const marker = MasterRouteDraggableMarker({
      position: event.latlng,
      onDragEnd: (event: any) =>
        this.onDragRouteWaypoint(event, leg, newWaypoint, false),
      onDrag: () => {},
    });
    this.setState({marker});
    marker.addTo(this.myMap.leafletElement);
    this.myMap.leafletElement.closePopup();
  };

  masterRouteLegOnClick = (event: any, leg: any) => {
    this.setState({
      dynamicPopup: {
        position: event.latlng,
        content: (
          <MasterRouteStaticPopup
            adminLayer={this.masterRouteLayer}
            event={event}
            leg={leg}
            onEdit={(adminLayer: any, event: any) =>
              this.onRouteEdit(adminLayer, event, leg)
            }
            showEdit={RoleService.hasPermission('Edit Site', 'Edit')} // TODO change to Edit Route
          />
        ),
      },
    });
  };

  loadNearbyNodes = async (e: {latlng: latLng}, radius: number) => {
    this.setState({
      nearbyNodesLoadingRadius: (
        <Circle center={e.latlng} radius={radius * 111400} />
      ),
      dynamicPopup: {position: e.latlng, content: <Loader />},
    });
    const newNearbyNodes = await nodeApi.apiNodeNearbyGet({
      latitude: e.latlng.lat,
      longitude: e.latlng.lng,
      radius,
    });

    if (newNearbyNodes && newNearbyNodes.items) {
      const stateNearbyNodes = this.state.nearbyNodes
        ? this.state.nearbyNodes
        : [];
      const uniqueNearbyNodes = await getUniqueSetNearbyNodes([
        ...stateNearbyNodes,
        ...newNearbyNodes.items,
      ]);
      this.setState({nearbyNodes: [...uniqueNearbyNodes], dynamicPopup: null});
    }
    this.setState({nearbyNodesLoadingRadius: null});
  };

  componentWillUnmount() {
    // this.closePeripherals();
    mapDisplayStore.setOpenIsochrone(null);
    mapDisplayStore.setOpenAddress(null);
  }

  enableRenderOverlayView = () => {
    this.setState({
      showMapOverlay: true,
      singleOverlayTile: false,
      overlayBounds: getBoundsFromMap(this.myMap),
    });
  };

  enableRenderOverlayTile = (e: {latlng: latLng}) => {
    const {overlayTiles} = this.state;
    this.setState({
      overlayTiles: [...overlayTiles, {location: e.latlng}],
    });
  };

  getFeatureStyle = (feature: any, item: any, defaults: any) => {
    const theme: MapTheme = mapDisplayStore.mapTheme;
    switch (item.type) {
      case 'DANGER ZONE':
        return theme.getFeatureStyle.dangerZone;
      default:
        return defaults;
    }
  };

  renderMap() {
    const {
      plannedTrip = {stops: []},
      actualTrip = {stops: []},
      zoom,
      siteEdit,
      center,
      nearbyNodes,
      showSiteCommitButton,
      siteEditLoading,
      dynamicPopup,
      nearbyNodesLoadingRadius,
      showMapOverlay,
      singleOverlayTile,
      overlayBounds,
      overlayTiles,
    } = this.state;

    const {
      vehicle,
      criticalEvents = [],
      externalLayers,
      telematicsEvents,
      route = {},
      masterRouteLeg = null,
      geoSelector = false,
      timelineSliderTime: timelineSliderTimeProp,
      // planningSolution,
    } = this.props;

    const theme: MapTheme = mapDisplayStore.mapTheme;

    const {openEvent, openStop, openActualStop, openAddress, openIsochrone} =
      mapDisplayStore;
    const {timelineSliderTime: timelineSliderTimeStore} = timelineDisplayStore;

    /** In order to compensate for legacy code that still uses the mobx store */
    const timelineSliderTime =
      timelineSliderTimeProp?.toISOString() ?? timelineSliderTimeStore;

    const resourceActualPosition =
      this.updateTimelineSliderVisuals(
        timelineSliderTime
      )?.resourceActualPosition;
    const resourcePlannedPosition =
      this.updateTimelineSliderVisuals(
        timelineSliderTime
      )?.resourcePlannedPosition;

    const domicile = vehicle
      ? vehicle.contract
        ? vehicle.contract.node
        : null
      : null;

    const sequenceStops: any[] = [];
    const plannedTripGeofences: any[] = [];
    let plannedTripMarkers: any[] = [];
    let actualTripMarkers: any[] = [];
    let criticalEventMarkers: (JSX.Element | null)[] | null = [];
    let actualSequenceLine: any[] | any = [];
    let plannedSequenceLine: any[] | any = [];

    const nearbyNodesGeofences = nearbyNodes
      ? nearbyNodes.map((item: any) => (
          <GeoJSON
            data={item.geofencesGeoJson}
            style={(feature) =>
              this.getFeatureStyle(feature, item, {
                color: theme.nearbyNodesGeofences.color || 'green',
                fillColor: theme.nearbyNodesGeofences.fillColor || 'green',
                fillOpacity: theme.nearbyNodesGeofences.fillOpacity || 0.2,
              })
            }
            weight={1}
            // @ts-expect-error upgrade
            item={item}
            onEachFeature={this.onEachNearbyGeofence}
            key={`nearbyNodesGeofences${item.id}`}
          />
        ))
      : null;

    if (plannedTrip.stops) {
      plannedTripMarkers = plannedTrip.stops.map((item) => {
        plannedTripGeofences.push(
          <GeoJSON
            data={item.node.geofencesGeoJson}
            style={(feature) =>
              this.getFeatureStyle(feature, item, {
                color: theme.plannedTripGeofences.color || 'green',
                fillColor: theme.plannedTripGeofences.fillColor || 'green',
                fillOpacity: theme.plannedTripGeofences.fillOpacity || 0.2,
              })
            }
            weight={1}
            // @ts-expect-error upgrade
            item={item.node}
            key={`plannedTripGeofences${item.id}`}
            onEachFeature={this.onEachNearbyGeofence}
          />
        );
        sequenceStops.push([item.node.latitude, item.node.longitude]);
        let marker: React.ReactNode | null;
        switch (item.node.type) {
          case 'REST':
            marker = (
              <RestMarker
                key={`REST${item.id}`}
                position={[item.node.latitude, item.node.longitude]}
                stop={item}
              />
            );
            break;
          case 'DEPOT':
            marker = (
              <DepotMarker
                key={`DEPOT${item.id}`}
                position={[item.node.latitude, item.node.longitude]}
                stop={item}
              />
            );
            break;
          case 'LOADING':
            marker = (
              <CollectionMarker
                key={`LOADING${item.id}`}
                position={[item.node.latitude, item.node.longitude]}
                stop={item}
              />
            );
            break;
          case 'UNLOADING':
            marker = (
              <DeliveryMarker
                key={`UNLOADING${item.id}`}
                position={[item.node.latitude, item.node.longitude]}
                stop={item}
              />
            );
            break;
          case 'OFFLOADING':
            marker = (
              <DeliveryMarker
                key={`OFFLOADING${item.id}`}
                position={[item.node.latitude, item.node.longitude]}
                stop={item}
              />
            );
            break;
          case 'BORDER':
            marker = (
              <BorderMarker
                key={`BORDER${item.id}`}
                position={[item.node.latitude, item.node.longitude]}
                stop={item}
              />
            );
            break;
          default:
            marker = (
              <InfoMarker
                key={`INFO${item.id}`}
                position={[item.node.latitude, item.node.longitude]}
                stop={item}
              />
            );
            break;
        }
        return marker;
      });

      plannedSequenceLine = (
        <Polyline
          positions={sequenceStops}
          color={theme.plannedSequenceLine.color}
          weight={3}
          opacity={0.8}
        />
      );
    }
    let actualTripStopLength = 0;

    if (actualTrip.stops && actualTrip.stops.length > 0) {
      actualTripStopLength = actualTrip.stops.length;
      actualTripMarkers = actualTrip.stops.map((item) => {
        if (item.knownNodes && item.knownNodes.length > 0) {
          sequenceStops.push([
            item.knownNodes[0].latitude,
            item.knownNodes[0].longitude,
          ]);
          return (
            <ActualStopMarker
              key={`actualTripMarkers${item.id}`}
              position={[
                item.knownNodes[0].latitude,
                item.knownNodes[0].longitude,
              ]}
              actualStop={item}
            />
          );
        }
        return (
          <ActualStopMarker
            key={`actualTripMarkers${item.id}`}
            position={[item.latitude, item.longitude]}
            actualStop={item}
          />
        );
      });
      actualSequenceLine = (
        <Polyline
          positions={sequenceStops}
          color={theme.actualSequenceLine.color}
          weight={3}
          opacity={0.8}
        />
      );
    }

    if (criticalEventMarkers && criticalEvents.length > 0) {
      criticalEventMarkers = criticalEvents
        ? criticalEvents.map((event) => {
            if (!event.latitude || !event.longitude) {
              return null;
            }
            return (
              <CriticalEventMarker
                key={`criticalEvents${event.id}`}
                position={[event.latitude, event.longitude]}
                event={event}
              />
            );
          })
        : null;
    }
    // Display OperationalEventMarker if that is the case
    const openEventMarker =
      openEvent && openEvent.latitude ? (
        <FeatureGroup
          ref={(openEventMarkerLayer) => {
            this.openEventMarkerLayer = openEventMarkerLayer;
          }}
        >
          {openEvent.mapType === 'Critical' ? (
            <CriticalEventMarker
              key={`criticalEvents${openEvent.id}`}
              position={[openEvent.latitude, openEvent.longitude]}
              event={openEvent}
            />
          ) : (
            <OperationalEventMarker
              key={`operationalEvents${openEvent.id}`}
              position={[openEvent.latitude, openEvent.longitude]}
              event={openEvent}
            />
          )}
        </FeatureGroup>
      ) : (
        <FeatureGroup
          ref={(openEventMarkerLayer) => {
            this.openEventMarkerLayer = openEventMarkerLayer;
          }}
        />
      );

    const openAddressMarker =
      openAddress && openAddress.latitude ? (
        <FeatureGroup
          ref={(openAddressMarkerLayer) => {
            this.openAddressMarkerLayer = openAddressMarkerLayer;
          }}
        >
          <AddressMarker
            key={`address${openAddress.id}`}
            position={[openAddress.latitude, openAddress.longitude]}
            address={openAddress}
          />
        </FeatureGroup>
      ) : (
        <FeatureGroup
          ref={(openAddressMarkerLayer) => {
            this.openAddressMarkerLayer = openAddressMarkerLayer;
          }}
        />
      );

    const openStopMarker = openStop ? (
      <FeatureGroup
        ref={(openStopMarkerLayer) => {
          this.openStopMarkerLayer = openStopMarkerLayer;
        }}
      >
        <PlannedStopMarker
          key={`openStop${openStop.id}`}
          position={[openStop.latitude, openStop.longitude]}
          stop={openStop}
        />
      </FeatureGroup>
    ) : (
      <FeatureGroup
        ref={(openStopMarkerLayer) => {
          this.openStopMarkerLayer = openStopMarkerLayer;
        }}
      />
    );

    const openActualStopMarker = openActualStop ? (
      <FeatureGroup
        ref={(openActualStopMarkerLayer) => {
          this.openActualStopMarkerLayer = openActualStopMarkerLayer;
        }}
      >
        <ActualStopMarker
          key={`openActualStop${openActualStop.id}`}
          position={[openActualStop.latitude, openActualStop.longitude]}
          actualStop={openActualStop}
        />
      </FeatureGroup>
    ) : (
      <FeatureGroup
        ref={(openActualStopMarkerLayer) => {
          this.openActualStopMarkerLayer = openActualStopMarkerLayer;
        }}
      />
    );

    const openIsochroneFeautureGroup = openIsochrone ? (
      <FeatureGroup
        ref={(openIsochroneLayer) => {
          this.openIsochroneLayer = openIsochroneLayer;
        }}
      >
        {openIsochrone}
      </FeatureGroup>
    ) : (
      <FeatureGroup
        ref={(openIsochroneLayer) => {
          this.openIsochroneLayer = openIsochroneLayer;
        }}
      />
    );

    const plannedRouteFeature = route.coordinates ? (
      <GeoJSON
        data={route}
        ref={(plannedRouteLayer) => {
          this.plannedRouteLayer = plannedRouteLayer;
        }}
      />
    ) : null;

    const domicileMarker = domicile ? (
      <DepotMarker
        key="domicile"
        // todo: investigate
        // site={domicile}
        // doesn't seem to be used by DepotMarker
        // stop is expected but doesn't seem to be passed.
        position={[domicile.latitude, domicile.longitude]}
      />
    ) : null;

    const vehicleMarker = vehicle ? (
      vehicle.lastSeen ? (
        vehicle.lastSeen.latitude ? (
          <VehicleMarker
            key="vehicle"
            vehicle={vehicle}
            position={[vehicle.lastSeen.latitude, vehicle.lastSeen.longitude]}
          />
        ) : null
      ) : null
    ) : null;

    const resourcePlannedMarker = resourcePlannedPosition ? (
      <PlannedResourceMarker
        key="resourcePlanned"
        position={resourcePlannedPosition}
      />
    ) : null;

    const telematicsRoute =
      telematicsEvents && telematicsEvents.coordinates ? (
        <Polyline
          // @ts-expect-error upgrade
          positions={telematicsEvents.coordinates}
          color={theme.telematicsRoute.color}
          weight={3}
          opacity={0.8}
          ref={(telematicsRouteLayer) => {
            this.telematicsRouteLayer = telematicsRouteLayer;
          }}
        />
      ) : null;

    const geoSelectorControl = geoSelector ? (
      <FeatureGroup
        ref={(reactFGref) => {
          this._onFeatureGroupReady(reactFGref);
        }}
      >
        <EditControl
          position="topleft"
          onCreated={this._onGeoSelectionCreated}
          onDeleted={this._onGeoSelectionDeleted}
          edit={{edit: false}}
          draw={{
            marker: false,
            line: false,
            polyline: false,
            circlemarker: false,
            circle: false,
          }}
        />
      </FeatureGroup>
    ) : null;

    const siteEditControl = siteEdit ? (
      <FeatureGroup
        ref={(reactFGref) => {
          this._onFeatureGroupReady(reactFGref);
        }}
      >
        <EditControl
          position="topleft"
          onEdited={this._onEdited}
          onCreated={this._onCreated}
          onDeleted={this._onDeleted}
          onMounted={this._onMounted}
          onEditStart={this._onEditStart}
          onEditStop={this._onEditStop}
          onDeleteStart={this._onDeleteStart}
          onDeleteStop={this._onDeleteStop}
          draw={{
            marker: false,
            line: false,
            polyline: false,
            circlemarker: false,
          }}
        />
      </FeatureGroup>
    ) : null;

    const renderIsochrone = (event: any) => {
      this.setState({
        dynamicPopup: {position: event.latlng, content: <Loader />},
      });
      ValhallaService.renderIsochrone(event);
    };

    const auth = authStore.getAuth;
    const contextMenuItems =
      auth && auth.authenticated
        ? [
            {
              text: 'Load Isochrone',
              callback: renderIsochrone,
            },
            {
              text: 'Show coordinates',
              callback: this.showCoordinatesPopup,
            },
            {
              text: 'Load Nearby Nodes (1km)',
              callback: (e: {latlng: latLng}) => this.loadNearbyNodes(e, 0.01),
            },
            {
              text: 'Load Nearby Nodes (5km)',
              callback: (e: {latlng: latLng}) => this.loadNearbyNodes(e, 0.05),
            },
            {
              text: 'Load Nearby Nodes (10km)',
              callback: (e: {latlng: latLng}) => this.loadNearbyNodes(e, 0.1),
            },
            {
              disabled: true,
              index: 4,
              text: 'Render Satellite View (max zoom only)',
              callback: this.enableRenderOverlayView,
            },
            {
              disabled: true,
              index: 5,
              text: 'Render Satellite Tile (max zoom only)',
              callback: this.enableRenderOverlayTile,
            },
          ]
        : [];

    const updateContextMenu = (viewport: {zoom: number}) => {
      const satViewItem = {
        index: 4,
        text: 'Render Satellite View',
        callback: this.enableRenderOverlayView,
        disabled: false,
      };

      const satViewTileItem = {
        index: 5,
        text: 'Render Satellite Tile',
        callback: this.enableRenderOverlayTile,
        disabled: false,
      };

      if (!this.myMap) {
        return;
      }

      if (viewport.zoom !== 18) {
        // Satellite View
        satViewItem.disabled = true;
        satViewItem.text += ' (max zoom only)';
        this.myMap.leafletElement.contextmenu.removeItem(satViewItem.index);
        this.myMap.leafletElement.contextmenu.insertItem(
          satViewItem,
          satViewItem.index
        );

        // Satellite Tile
        satViewTileItem.disabled = true;
        satViewTileItem.text += ' (max zoom only)';
        this.myMap.leafletElement.contextmenu.removeItem(satViewTileItem.index);
        this.myMap.leafletElement.contextmenu.insertItem(
          satViewTileItem,
          satViewTileItem.index
        );
      } else if (viewport.zoom === 18) {
        // Satellite View
        this.myMap.leafletElement.contextmenu.removeItem(satViewItem.index);
        this.myMap.leafletElement.contextmenu.insertItem(
          satViewItem,
          satViewItem.index
        );

        // Satellite Tile
        this.myMap.leafletElement.contextmenu.removeItem(satViewTileItem.index);
        this.myMap.leafletElement.contextmenu.insertItem(
          satViewTileItem,
          satViewTileItem.index
        );
      }
    };

    return (
      <Map
        onBaselayerchange={(layer: any) => {
          this.setState({baseLayer: layer.name});
          mapDisplayStore.setBaseLayer(layer.name);
        }}
        ref={(myMap) => {
          this.myMap = myMap;
        }}
        className="markercluster-map"
        center={center}
        zoom={zoom}
        // strict TS typing on map requires maxZoom to be set
        maxZoom={20}
        contextmenu
        contextmenuWidth={140}
        contextmenuItems={contextMenuItems}
        whenReady={this.fitMapToLayers}
        // @ts-expect-error upgrade
        onViewportChanged={updateContextMenu}
      >
        <div>
          <Control position="topleft">
            <GeoSearchCard onAddressClick={this.props.onAddressClick} />
          </Control>
        </div>
        {dynamicPopup && (
          <Popup
            key={dynamicPopup.position.lng}
            position={[dynamicPopup.position.lat, dynamicPopup.position.lng]}
          >
            {dynamicPopup.content}
          </Popup>
        )}
        {geoSelector ? (
          <div>
            <Control position="bottomright">
              <GeoSelectionCard
                loading={this.state.geoSelectionLoading}
                countType={
                  this.props.geoSelectionType ? this.props.geoSelectionType : ''
                }
                count={
                  (mapDisplayStore.geoSelectionItems &&
                    Object.values(mapDisplayStore.geoSelectionItems).length) ||
                  0
                }
              />
            </Control>
          </div>
        ) : null}
        {/* <Control position="bottomright">
          <div style={{height: '30vh', overflow: 'auto', zIndex: 50}}>
            {planningSolution && (
              <PlanningSolutionOverviewCard
                planningSolution={planningSolution}
                isMap={true}
              />
            )}
          </div>
        </Control> */}
        {timelineSliderTime || showSiteCommitButton || siteEdit ? (
          <Control position="bottomleft">
            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'space-between',
              }}
            >
              {timelineSliderTime && (
                <TimelinePlayerCard timelineSliderTime={timelineSliderTime} />
              )}
              {showSiteCommitButton && (
                <SiteEditCommitCard
                  onCancel={this.onSiteEditReset}
                  onSubmit={this.onSiteEditSubmit}
                  loading={siteEditLoading}
                />
              )}
              {siteEdit && !this.props.siteEditCallback && (
                <SiteEditCancelCard onCancel={this.onSiteEditCancel} />
              )}
            </div>
          </Control>
        ) : null}

        <LayersControl
          style={{display: 'none', '& a': {display: 'none'}}}
          collapsed
        >
          {!this.props.disableSatellite &&
            ConfigService.hybridTilingUri != null && (
              <BaseLayer
                name="HERE Hybrid"
                checked={mapDisplayStore.baseLayer === 'HERE Hybrid'}
              >
                <TileLayer url={ConfigService.hybridTilingUri} maxZoom={20} />
              </BaseLayer>
            )}
          <BaseLayer
            name="Vantage Streets"
            checked={mapDisplayStore.baseLayer === 'Vantage Streets'}
          >
            <TileLayer
              url={`${ConfigService.tilingEngineUrl}tile/{z}/{x}/{y}.png`}
              tileSize={256}
              maxZoom={20}
            />
          </BaseLayer>
          {siteEditControl}
          {geoSelectorControl}
          {openStopMarker}
          {openActualStopMarker}
          {openIsochroneFeautureGroup}
          {openEventMarker}
          {openAddressMarker}
          {externalLayers ? (
            <Overlay name="External Layers" checked>
              <FeatureGroup
                ref={(externalLayersLayer) => {
                  this.externalLayersLayer = externalLayersLayer;
                }}
              >
                {externalLayers}
              </FeatureGroup>
            </Overlay>
          ) : null}
          {criticalEventMarkers && criticalEventMarkers.length > 0 ? (
            <Overlay
              name={`Critical Events: ${criticalEventMarkers.length}`}
              checked
            >
              <FeatureGroup
                ref={(criticalEventMarkersLayer) => {
                  this.criticalEventMarkersLayer = criticalEventMarkersLayer;
                }}
              >
                {criticalEventMarkers}
              </FeatureGroup>
            </Overlay>
          ) : null}
          {nearbyNodesGeofences ? (
            <Overlay
              name={`Nearby Nodes Geofences: ${nearbyNodes.length}`}
              checked
            >
              <FeatureGroup
                ref={(nearbyNodesGeofencesLayer) => {
                  this.nearbyNodesGeofencesLayer = nearbyNodesGeofencesLayer;
                }}
              >
                {nearbyNodesGeofences}
                {nearbyNodesLoadingRadius}
              </FeatureGroup>
            </Overlay>
          ) : null}
          {domicileMarker ? (
            <Overlay name="Vehicle Domicile" checked>
              {domicileMarker}
            </Overlay>
          ) : null}
          {Array.isArray(plannedSequenceLine) &&
          plannedSequenceLine.length > 0 ? (
            <Overlay name="Planned Sequence Line" checked>
              {plannedSequenceLine}
            </Overlay>
          ) : null}
          {plannedRouteFeature ? (
            <Overlay name="Planned Route Line" checked>
              {plannedRouteFeature}
            </Overlay>
          ) : null}
          {masterRouteLeg ? (
            <Overlay name="Master Route Line" checked>
              <FeatureGroup
                ref={(masterRouteLayer) => {
                  this.masterRouteLayer = masterRouteLayer;
                }}
              >
                <MasterRouteMapFeature
                  masterRouteLeg={masterRouteLeg}
                  masterRouteLegOnClick={this.masterRouteLegOnClick}
                  onDragRouteWaypoint={this.onDragRouteWaypoint}
                  closeNewMarker={() =>
                    this.state.marker?.removeFrom(this.myMap.leafletElement)
                  }
                />
              </FeatureGroup>
            </Overlay>
          ) : null}
          {plannedTripMarkers.length > 0 ? (
            <Overlay name="Planned Stop Markers" checked>
              <FeatureGroup
                ref={(plannedTripLayer) => {
                  this.plannedTripLayer = plannedTripLayer;
                }}
              >
                {plannedTripMarkers}
                {plannedTripGeofences}
              </FeatureGroup>
            </Overlay>
          ) : null}
          {resourcePlannedMarker ? (
            <Overlay name="Planned Ghost Replay Asset" checked>
              {resourcePlannedMarker}
            </Overlay>
          ) : null}
          {actualSequenceLine.length > 0 ? (
            <Overlay name="Actual Sequence Line" checked>
              {actualSequenceLine}
            </Overlay>
          ) : null}
          {vehicleMarker ? (
            <Overlay name="Vehicle Last Seen" checked>
              <FeatureGroup
                ref={(vehicleMarkerLayer) => {
                  this.vehicleMarkerLayer = vehicleMarkerLayer;
                }}
              >
                {vehicleMarker}
              </FeatureGroup>
            </Overlay>
          ) : null}
          {telematicsRoute ? (
            <Overlay name="Actual Route Line" checked>
              {telematicsRoute}
            </Overlay>
          ) : null}
          {actualTripMarkers.length > 0 ? (
            <Overlay
              name={`Actual Stop Markers: ${actualTripStopLength}`}
              checked
            >
              <FeatureGroup
                ref={(actualTripLayer) => {
                  this.actualTripLayer = actualTripLayer;
                }}
              >
                {actualTripMarkers}
              </FeatureGroup>
            </Overlay>
          ) : null}
          {resourceActualPosition ? (
            <Overlay name="Actual Vehicle Replay Asset" checked>
              <FeatureGroup
                ref={(resourceActualMarkerLayer) => {
                  this.resourceActualMarkerLayer = resourceActualMarkerLayer;
                }}
              >
                <ActualResourceMarker
                  key="resourceActual"
                  vehicle={vehicle}
                  position={resourceActualPosition}
                  fillColor={mapDisplayStore.mapTheme.icons.resource}
                />
              </FeatureGroup>
            </Overlay>
          ) : null}
        </LayersControl>
        {showMapOverlay &&
        this.myMap &&
        this.myMap.leafletElement &&
        this.myMap.leafletElement._lastCenter ? (
          <TileOverlay
            bounds={overlayBounds}
            location={{
              lng: this.myMap.leafletElement._lastCenter.lng,
              lat: this.myMap.leafletElement._lastCenter.lat,
            }}
            singleTile={singleOverlayTile}
            zoom={18}
          />
        ) : null}
        <TileOverlayMultiple tiles={overlayTiles || []} />
      </Map>
    );
  }
}

export default inject('mapDisplayStore', 'timelineDisplayStore')(observer(map));
