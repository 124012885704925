import {makeStyles, createStyles} from '@mui/styles';
import {Theme} from '@mui/material';

const ContractSetupSummaryDetailsStyles = (theme: Theme) =>
  createStyles({
    label: {cursor: 'default'},
    value: {cursor: 'default'},
    valueTooltip: {fontSize: theme.typography.fontSize},
  });

export const useContractSetupSummaryDetailsStyles = makeStyles(
  ContractSetupSummaryDetailsStyles,
  {name: 'ContractSetupSummaryDetailsStyles'}
);
