import React from 'react';
import withStyles from '@mui/styles/withStyles';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

import {TableEditColumn as TableEditColumnBase} from '@devexpress/dx-react-grid';
import {VantageDialogButton} from '../dialog';
import {withRouter} from 'react-router-dom';

export const selectStyles = (theme: any) => ({
  lookupEditCell: {
    paddingTop: theme.spacing(0.875),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  dialog: {
    width: 'calc(100% - 16px)',
  },
  inputRoot: {
    width: '100%',
  },
});

export const EditButton = ({onExecute}: any) => (
  <IconButton onClick={onExecute} title="Edit row" size="large">
    <div data-test="edit-row-button" style={{display: 'flex'}}>
      <EditIcon />
    </div>
  </IconButton>
);

export const CommitButton = ({
  onExecute,
  hasErrors,
  hasEmailErrors,
  activeStatusChanged,
}: any) => (
  <IconButton
    onClick={onExecute}
    title="Save changes"
    disabled={
      hasErrors === true ||
      hasEmailErrors === true ||
      activeStatusChanged === false
    }
    size="large"
  >
    <div data-test="commit-row-button" style={{display: 'flex'}}>
      <SaveIcon />
    </div>
  </IconButton>
);

export const CancelButton = ({onExecute}: any) => (
  <IconButton onClick={onExecute} title="Cancel changes" size="large">
    <div data-test="cancel-row-button" style={{display: 'flex'}}>
      <CancelIcon />
    </div>
  </IconButton>
);

const deleteButtonStyles = () => ({
  dialog: {
    '& div:last-child': {
      borderRadius: '6px',
      backgroundColor: '#F1F1F1',
    },
  },
  title: {
    color: '#000000',
    '& h2': {
      fontWeight: 'normal',
      opacity: 0.87,
      size: 16,
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  yesButton: {
    color: '#008270',
    size: 13,
  },
  noButton: {
    color: '#808080',
    size: 13,
    opacity: 0.87,
  },
});

class DeleteButtonComponent extends React.Component<any, any> {
  state = {
    open: false,
  };

  handleOpen = () => {
    this.setState({open: true});
  };

  handleClose = () => {
    this.setState({open: false});
  };

  handleYesAction = () => {
    this.setState({open: false}, () => this.props.onExecute());
  };

  render() {
    const {classes} = this.props;
    if (this.props.match.path === '/triplist/:tripId') {
      return (
        <VantageDialogButton
          title={<DeleteIcon data-test="delete-row-button" />}
          color="inherit"
          dialogTitle={
            <>
              Are you sure you want to <strong>delete</strong> this trip stop?
            </>
          }
          acceptTitle="Accept"
          declineTitle="Cancel"
          onAccept={this.handleYesAction}
          iconButton
        >
          These changes will impact the data of the trip.
        </VantageDialogButton>
      );
    } else {
      return (
        <>
          <IconButton onClick={this.handleOpen} title="Delete" size="large">
            <div data-test="delete-row-button" style={{display: 'flex'}}>
              <DeleteIcon />
            </div>
          </IconButton>

          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="responsive-dialog-title"
            className={classes.dialog}
          >
            <DialogTitle id="responsive-dialog-title" className={classes.title}>
              Are you sure you want to delete?
            </DialogTitle>
            <DialogActions className={classes.actions}>
              <Button
                onClick={this.handleYesAction}
                className={classes.yesButton}
              >
                YES
              </Button>
              <Button onClick={this.handleClose} className={classes.noButton}>
                NO
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    }
  }
}

export const DeleteButton = withStyles(deleteButtonStyles)(
  withRouter(DeleteButtonComponent)
);

export const AddButton = ({onExecute}: any) => (
  <IconButton onClick={onExecute} title="Add" size="large">
    <div data-test="add-row-button" style={{display: 'flex'}}>
      <AddIcon />
    </div>
  </IconButton>
);

export const commandComponents: any = {
  edit: EditButton,
  commit: CommitButton,
  cancel: CancelButton,
  delete: DeleteButton,
  add: AddButton,
};

export const Command: React.ComponentType<TableEditColumnBase.CommandProps> = ({
  id,
  onExecute,
  hasErrors,
  hasEmailErrors,
  activeStatusChanged,
}: any) => {
  const CommandButton = commandComponents[id];
  return (
    <CommandButton
      onExecute={onExecute}
      hasErrors={hasErrors}
      hasEmailErrors={hasEmailErrors}
      activeStatusChanged={activeStatusChanged}
    />
  );
};
