import React from 'react';
import {Listing} from '@onroadvantage/onroadvantage-api';
import {debounce} from '@mui/material';
import {listingApi} from '../../../../api';
import {useAppNotifications} from '../../../../contexts';
import {
  Autocomplete,
  TAutocompleteOnChange,
  TAutocompleteOnInputChange,
} from '../../../autocomplete';
import {usePlanningBoardContext} from '../../planningBoardContext';

export const PlanningBoardOptionsContractAutocomplete: React.FC = () => {
  const {selectedContractListings, setSelectedContractListings} =
    usePlanningBoardContext();
  const notify = useAppNotifications();
  const [localLoading, setLocalLoading] = React.useState<boolean>(false);
  const [listings, setListings] = React.useState<Listing[]>([]);

  const handleGetListings = React.useCallback(
    async (filter?: string) => {
      setLocalLoading(true);
      try {
        const response = await listingApi.apiListingGet({
          model: 'Contract',
          query: filter,
        });
        setListings(response.items ?? []);
      } catch (e) {
        notify('error', 'Failed to load Listing');
      } finally {
        setLocalLoading(false);
      }
    },
    [notify]
  );

  const getListingDebounce = React.useRef(
    debounce(handleGetListings, 500)
  ).current;

  const handleInputChange = React.useCallback<TAutocompleteOnInputChange>(
    async (_event, value: string) => {
      await getListingDebounce(value);
    },
    [getListingDebounce]
  );

  React.useEffect(() => {
    handleGetListings();
    return () => setListings([]);
  }, [handleGetListings]);

  const handleChange = React.useCallback<TAutocompleteOnChange>(
    (_event, newValue) => {
      setSelectedContractListings(newValue as Listing[]);
    },
    [setSelectedContractListings]
  );

  return (
    <Autocomplete
      multiple
      name="contracts"
      onChange={handleChange}
      onInputChange={handleInputChange}
      options={listings}
      placeholder="Select a contract"
      label="Contract(s)"
      value={selectedContractListings}
      loading={localLoading}
    />
  );
};
