import React from 'react';
import {useHistory} from 'react-router-dom';
import {
  Contact,
  WebContactListResponse,
  ContactPost,
  ContactUpdate,
  ApiWebContactGetRequest,
  WebContactListItem,
} from '@onroadvantage/onroadvantage-api';
import {FormikHelpers, FormikProps} from 'formik/dist/types';
import {contactApi} from '../../api';
import {
  TemplateTableContextProps,
  TLoadList,
  useTemplateTable,
} from '../../factory/template';
import {useAppNotifications} from '../../contexts';
import {IContactForm} from './contactForm';
import {LuxonService} from '../../service';

export interface ContactContextProps
  extends TemplateTableContextProps<
    WebContactListItem,
    WebContactListResponse
  > {
  onDetailsFormSubmit: (
    values: IContactForm,
    formikHelpers: FormikHelpers<IContactForm>
  ) => void;
  handleSave: (value: number) => void;
  setContactId: (value: number | undefined) => void;
  submitting: boolean;
  contact?: Contact;
  contactId?: number | null;
  detailsRef?: React.Ref<FormikProps<IContactForm>>;
  disableNotifications: boolean;
  updateDisableNotifications: (
    _e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
}

export const ContactContext = React.createContext<ContactContextProps>({
  // Template Table Defaults
  loading: false,
  list: [],
  currentPage: 1,
  // Contact
  loadList: async () => {},
  onDetailsFormSubmit: () => null,
  handleSave: () => null,
  setContactId: () => null,
  submitting: false,
  disableNotifications: false,
  updateDisableNotifications: () => null,
});

interface ContactContextProviderProps {
  contactId?: number;
}

export const ContactContextProvider: React.FC<ContactContextProviderProps> = ({
  children,
}) => {
  const history = useHistory();
  const notify = useAppNotifications();
  // Template Table
  const [
    {
      // States
      currentPage,
      filters,
      hasPermission,
      itemTotal,
      list,
      loading,
      pageSize,
      pageTotal,
      loadingSingleItem,
      sorting,
    },
    {
      // Getters
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
      setLoadingSingleItem,
    },
  ] = useTemplateTable<WebContactListItem, ApiWebContactGetRequest>({
    editPermission: 'Edit Contact',
    addPermission: 'Add Contact',
    deletePermission: 'Delete Contact',
    downloadPermission: 'Contact ListDownload',
    viewPermission: 'Contact List',
  });

  const loadList = React.useCallback<TLoadList<WebContactListResponse>>(
    async (options) => {
      setLoading(true);
      try {
        const requestObj = getRequestObj(
          [
            'name',
            'description',
            'email',
            'cellphoneNumber',
            'type',
            'smsBan',
            'contactGroup',
            'callBan',
            'whatsappBan',
          ],
          options
        );
        const response = await contactApi.apiWebContactGet(requestObj);
        return getResponse(response, options);
      } catch (e) {
        notify('error', 'Failed to load contact list');
      } finally {
        setLoading(false);
      }
    },
    [setLoading, getRequestObj, getResponse, notify]
  );

  const handleDelete = React.useCallback(
    async (row: WebContactListItem) => {
      setLoading(true);
      try {
        if (row.id) {
          await contactApi.apiContactContactIdDelete({
            contactId: row.id,
          });
          notify('success', 'Contact Deleted');
          await loadList();
        }
      } catch (e) {
        notify('error', 'Failed to delete contact');
      } finally {
        setLoading(false);
      }
    },
    [loadList, notify, setLoading]
  );

  const handleAdd = React.useCallback(() => {
    history.push('/contactlist/add');
  }, [history]);

  const handleNavigate = React.useCallback(
    (row: WebContactListItem) => {
      history.push(`/contactlist/${row.id}`);
    },
    [history]
  );

  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  // contact
  const [contact, setContact] = React.useState<Contact | undefined>();
  const [contactId, setContactId] = React.useState<number | null>();
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const [disableNotifications, setDisableNotifications] = React.useState(
    !!(contact?.notificationBanStart || contact?.notificationBanEnd)
  );

  const detailsRef = React.useRef<FormikProps<IContactForm>>(null);

  const loadContact = React.useCallback(async () => {
    setLoadingSingleItem(true);
    try {
      if (contactId) {
        const response = await contactApi.apiContactContactIdGet({
          contactId,
        });
        setContact(response);
        setDisableNotifications(
          !!(response.notificationBanStart || response.notificationBanEnd)
        );
      } else {
        setContact(undefined);
      }
    } catch (e) {
      notify('error', 'Failed to load contact');
    } finally {
      setLoadingSingleItem(false);
    }
  }, [setLoadingSingleItem, contactId, notify]);

  const handleDetailsFormSubmit = React.useCallback(
    async (
      values: IContactForm,
      formikHelpers: FormikHelpers<IContactForm>
    ) => {
      setSubmitting(true);
      try {
        const {notificationBanEnd, notificationBanStart, type, ...otherValues} =
          values;
        const newValues: ContactPost = {
          ...otherValues,
          notificationBanStart:
            disableNotifications && notificationBanStart
              ? LuxonService.fromLocalDateToServerTimeOnly(notificationBanStart)
              : undefined,
          notificationBanEnd:
            disableNotifications && notificationBanEnd
              ? LuxonService.fromLocalDateToServerTimeOnly(notificationBanEnd)
              : undefined,
          type: type.value?.toString(),
        };
        if (contact && contact.id) {
          await contactApi.apiContactContactIdPatch({
            contactId: contact.id,
            body: newValues as ContactUpdate,
          });
        } else {
          await contactApi.apiContactPost({
            body: newValues,
          });
        }
        history.push('/contactlist');
        notify('success', `${contact?.id ? 'Updated' : 'Added'} Contact`);
      } catch (e) {
        notify('error', `Failed to ${contact?.id ? 'update' : 'add'} contact`);
      } finally {
        formikHelpers.setSubmitting(false);
        setSubmitting(false);
      }
    },
    [disableNotifications, contact, history, notify]
  );

  const handleSave = React.useCallback(
    async (value: number) => {
      setLoading(true);
      if (contact?.id == null) {
        return;
      }
      try {
        const response = await contactApi.apiContactContactIdPatch({
          contactId: contact?.id,
          body: {
            userId: value,
          },
        });

        if (response) {
          setContact({...contact, ...response});
          notify('success', 'Driver Linked To User');
        }
      } catch (e) {
        notify('error', 'failed');
      } finally {
        setLoading(false);
      }
    },
    [contact, notify, setLoading]
  );

  const updateDisableNotifications = React.useCallback(
    (_e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setDisableNotifications(checked);
    },
    [setDisableNotifications]
  );

  const value: ContactContextProps = {
    // Template Table
    loadList,
    cleanupList,
    loading: loadingSingleItem || loading,
    list,
    hasPermission,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onAdd: hasPermission.add ? handleAdd : undefined,
    onNavigate: handleNavigate,
    onDelete: hasPermission.delete ? handleDelete : undefined,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onRefresh: handleRefresh,
    // Contact
    onDetailsFormSubmit: handleDetailsFormSubmit,
    handleSave,
    setContactId,
    submitting,
    contact,
    contactId,
    detailsRef,
    disableNotifications,
    updateDisableNotifications,
  };

  React.useEffect(() => {
    loadContact();
    return setContact(undefined);
  }, [loadContact]);

  return (
    <ContactContext.Provider value={value}>{children}</ContactContext.Provider>
  );
};
