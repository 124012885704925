import ConfigService from './ConfigService/ConfigService';
import ResponseService from './ResponseService';
import {encodeUrl, fetchTimeout, flatten} from './Util';
import {authStore} from '../store';

const serverUrl = ConfigService.serverUri;
const authUrl = `${serverUrl}/api/`;
const queryUrl = `${serverUrl}/api/dashboard`;

const DashboardService = {
  getTripsByContract: async (startDate, endDate) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    if (auth.oauthAuthenticated) {
      headers.set('Authorization', `Bearer ${auth.token}`);
    } else {
      headers.set('Authentication-Token', auth.token);
    }
    const response = await fetch(`${authUrl}trip/integration/count`, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({startDate, endDate}),
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  getCountCriticalEvent: async (startDate, endDate) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    if (auth.oauthAuthenticated) {
      headers.set('Authorization', `Bearer ${auth.token}`);
    } else {
      headers.set('Authentication-Token', auth.token);
    }
    const response = await fetch(`${authUrl}critical_event/count`, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({startDate, endDate}),
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  getCountVehicleUpdate: async (startDate, endDate) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    if (auth.oauthAuthenticated) {
      headers.set('Authorization', `Bearer ${auth.token}`);
    } else {
      headers.set('Authentication-Token', auth.token);
    }
    const response = await fetch(`${authUrl}latest_vehicle_position/count`, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({startDate, endDate}),
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  getCountTripAllocated: async (startDate, endDate) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    if (auth.oauthAuthenticated) {
      headers.set('Authorization', `Bearer ${auth.token}`);
    } else {
      headers.set('Authentication-Token', auth.token);
    }
    const response = await fetch(`${authUrl}trip/allocated/count`, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({startDate, endDate}),
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  query: async (params) => {
    Object.keys(params).forEach(
      // eslint-disable-next-line no-param-reassign
      (key) => params[key] == null && delete params[key]
    );
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    if (auth.oauthAuthenticated) {
      headers.set('Authorization', `Bearer ${auth.token}`);
    } else {
      headers.set('Authentication-Token', auth.token);
    }
    const response = await fetch(encodeUrl(queryUrl, params), {
      method: 'GET',
      mode: 'cors',
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  delete: async (dashboardId) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    if (auth.oauthAuthenticated) {
      headers.set('Authorization', `Bearer ${auth.token}`);
    } else {
      headers.set('Authentication-Token', auth.token);
    }
    const response = await fetch(`${queryUrl}/${dashboardId}`, {
      method: 'DELETE',
      mode: 'cors',
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  update: async (dashboard) => {
    if (!dashboard.id) {
      return DashboardService.create(dashboard);
    }
    Object.keys(dashboard).forEach(
      // eslint-disable-next-line no-param-reassign
      (key) => dashboard[key] == null && delete dashboard[key]
    );
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    if (auth.oauthAuthenticated) {
      headers.set('Authorization', `Bearer ${auth.token}`);
    } else {
      headers.set('Authentication-Token', auth.token);
    }
    const response = await fetch(`${queryUrl}/${dashboard.id}`, {
      method: 'PATCH',
      mode: 'cors',
      body: JSON.stringify(dashboard),
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  create: async (dashboard) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    if (auth.oauthAuthenticated) {
      headers.set('Authorization', `Bearer ${auth.token}`);
    } else {
      headers.set('Authentication-Token', auth.token);
    }
    const response = await fetch(`${queryUrl}`, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(dashboard),
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  normalize: async (response) => {
    const result = flatten(await response.data);
    return result;
  },
  getById: async (dashboardId) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    if (auth.oauthAuthenticated) {
      headers.set('Authorization', `Bearer ${auth.token}`);
    } else {
      headers.set('Authentication-Token', auth.token);
    }

    try {
      const response = await fetchTimeout(
        `${queryUrl}/${dashboardId}/data`,
        2 * 60 * 1000, // 2 minutes
        {
          method: 'GET',
          mode: 'cors',
          headers,
        }
      );

      return ResponseService.checkStatus(response);
    } catch (e) {
      switch (e.message) {
        case 'The user aborted a request.':
          throw new Error('Connection Timeout');
        default:
          throw e;
      }
    }
  },
  getViewData: async (dashboard) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    if (auth.oauthAuthenticated) {
      headers.set('Authorization', `Bearer ${auth.token}`);
    } else {
      headers.set('Authentication-Token', auth.token);
    }
    const response = await fetch(
      `${queryUrl}/view?viewName=${dashboard.viewName}`,
      {
        method: 'GET',
        mode: 'cors',
        headers,
      }
    );
    return ResponseService.checkStatus(response);
  },
};

export default DashboardService;
