import React from 'react';
import {BreadcrumbRoute} from './BreadcrumbController';
import {Link, Typography} from '@mui/material';
import history from '../../service/history';
import {useLocation} from 'react-router-dom';

export interface Params {
  [key: string]: string;
}

interface BreadcrumbItemProps {
  isLink?: boolean;
  params?: Params;
  route: BreadcrumbRoute;
  url?: string;
}

export const BreadcrumbItem: React.FC<BreadcrumbItemProps> = ({
  isLink: isLinkParam = true,
  params,
  route,
  url,
}) => {
  const location = useLocation();
  let title: string;
  if (typeof route.title === 'string') {
    title = route.title;
  } else if (params) {
    title = route.title(params);
  } else {
    return null;
  }

  // check if the path is the current
  const isLink = location.pathname !== url && isLinkParam;

  if (isLink) {
    return (
      <Link
        color="inherit"
        key={route.path}
        onClick={() => history.push(url ?? route.path)}
        style={{cursor: 'pointer'}}
      >
        <Typography>{title}</Typography>
      </Link>
    );
  }

  return <Typography key={route.path}>{title}</Typography>;
};
