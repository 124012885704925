import {
  MasterTripDebriefOrderSnapshot,
  MasterTripDebriefOrderLineSnapshot,
} from '@onroadvantage/onroadvantage-api';

export const getOrderLine = (
  orders: MasterTripDebriefOrderSnapshot[] | null | undefined,
  orderLineId: string | number | undefined
) => {
  if (!orderLineId) return undefined;
  let foundOrderLine: MasterTripDebriefOrderLineSnapshot | null | undefined;
  orders?.forEach(
    ({lines}) =>
      (foundOrderLine = lines?.find(
        ({id}) =>
          id ===
          (typeof orderLineId === 'string'
            ? parseInt(orderLineId)
            : orderLineId)
      ))
  );
  return foundOrderLine;
};
