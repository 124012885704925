import {makeStyles, createStyles} from '@mui/styles';

const VehicleControllerStyles = () =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      minHeight: 10,
    },
    root: {},
  });

export const useVehicleControllerStyles = makeStyles(VehicleControllerStyles, {
  name: 'VehicleControllerStyles',
});
