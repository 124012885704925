import React from 'react';
import {TemplateTable, TemplateTableConfig} from '../../factory/template';
import {
  IContractTaskTemplateInlineForm,
  useContractContext,
} from './contractContext';
import {contractTaskTemplateSchema} from './contractForms';

interface ContractTaskTemplateListProps {
  settingUpContract?: boolean;
}

export const ContractTaskTemplateList: React.FC<
  ContractTaskTemplateListProps
> = ({settingUpContract}) => {
  const {
    contract,
    settingUpContractTaskTemplateList,
    onContractTaskTemplateAdd,
    onContractTaskTemplateEdit,
    onContractTaskTemplateDelete,
    onAddSetupContractTaskTemplate,
    onEditSetupContractTaskTemplate,
    onRemoveSetupContractTaskTemplate,
  } = useContractContext();

  const handleSortBySequence = React.useCallback(
    (a: IContractTaskTemplateInlineForm, b: IContractTaskTemplateInlineForm) =>
      a.sequence - b.sequence,
    []
  );

  const list: IContractTaskTemplateInlineForm[] = React.useMemo(
    () =>
      settingUpContract
        ? settingUpContractTaskTemplateList.sort(handleSortBySequence)
        : contract?.contractTaskTemplates
            ?.map(
              ({taskTemplate, nodeTypes, sequence, ...otherValues}, index) => ({
                taskTemplate: {
                  label: taskTemplate?.name ?? undefined,
                  value: taskTemplate?.id ?? undefined,
                },
                nodeTypes:
                  nodeTypes?.map(({name, id}) => ({
                    label: name ?? undefined,
                    value: id ?? undefined,
                  })) ?? [],
                sequence: sequence ?? index + 1,
                ...otherValues,
              })
            )
            .sort(handleSortBySequence) ?? [],
    [
      contract?.contractTaskTemplates,
      handleSortBySequence,
      settingUpContract,
      settingUpContractTaskTemplateList,
    ]
  );

  const initialValues: Partial<IContractTaskTemplateInlineForm> = React.useMemo(
    () => ({
      taskTemplate: undefined,
      nodeTypes: undefined,
      mandatory: false,
      sequence: list.length + 1,
      forceSequence: false,
      acknowledgeEnabled: false,
      resetEnabled: true,
    }),
    [list.length]
  );

  const config: TemplateTableConfig<IContractTaskTemplateInlineForm> =
    React.useMemo(
      () => ({
        columns: [
          {
            name: 'taskTemplate',
            label: 'Template Name',
            type: 'string',
            model: 'TaskTemplate',
            enableAutocomplete: true,
            enableEditing: true,
            getValue: ({taskTemplate}) => taskTemplate?.label,
            getFieldValue: ({taskTemplate}) => taskTemplate,
          },
          {
            name: 'nodeTypes',
            label: 'Site Type Name',
            type: 'string',
            model: 'NodeType',
            enableAutocomplete: true,
            enableMulti: true,
            enableEditing: true,
            getValue: ({nodeTypes}) =>
              nodeTypes?.map((nodeType) => nodeType.label).join(', '),
            getFieldValue: ({nodeTypes}) => nodeTypes,
          },
          {
            name: 'mandatory',
            label: 'Mandatory',
            type: 'bool',
            enableEditing: true,
          },
          {
            name: 'sequence',
            label: 'Sequence',
            type: 'number',
            enableEditing: true,
          },
          {
            name: 'forceSequence',
            label: 'Force Sequence',
            type: 'bool',
            enableEditing: true,
          },
          {
            name: 'acknowledgeEnabled',
            label: 'Acknowledge Enabled',
            type: 'bool',
            enableEditing: true,
          },
          {
            name: 'resetEnabled',
            label: 'Reset Enabled',
            type: 'bool',
            enableEditing: true,
          },
        ],
        disableToolbar: true,
        disablePagination: true,
        deleteMessage: (row) => ({
          title: 'Contract Task Template',
          items: {TemplateName: row.taskTemplate?.label},
        }),
        identifier: 'CONTRACT_TASK_TEMPLATE_LIST',
      }),
      []
    );

  return (
    <TemplateTable
      config={config}
      list={list.sort(handleSortBySequence)}
      currentPage={1}
      addInitial={initialValues}
      addValidation={contractTaskTemplateSchema}
      onInlineAdd={
        settingUpContract
          ? onAddSetupContractTaskTemplate
          : onContractTaskTemplateAdd
      }
      onInlineEdit={
        settingUpContract
          ? onEditSetupContractTaskTemplate
          : onContractTaskTemplateEdit
      }
      onDelete={
        settingUpContract
          ? onRemoveSetupContractTaskTemplate
          : onContractTaskTemplateDelete
      }
    />
  );
};
