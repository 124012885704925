/* @flow */
import getMetaTagContent from './get-meta';
import {version} from '@onroadvantage/onroadvantage-api/package.json';

const {
  NODE_ENV,
  REACT_APP_SERVER_URI = 'https://dev.vantage.run',
  REACT_APP_GOOGLE_GEOCODING_KEY = '',
  REACT_APP_FRESHDESK_SUPPORT_URL = 'https://viewmetrics.freshdesk.com/',
  ROUTING_ENGINE_URL = 'https://routing.onroadvantage.com/route/v1/driving/',
  PROMPT_URL = '',
  PROMPT_KEY = '',
  REACT_APP_MAPBOX_KEY = '',
  TILING_ENGINE_URL = 'https://tiling.onroadvantage.com/',
  VALHALLA_ENGINE_URL = 'https://valhalla.onroadvantage.com/',
  REACT_APP_OIDC_AUTHORITY = '',
  REACT_APP_OIDC_CLIENT_ID = '',
  REACT_APP_OIDC_REDIRECT_URI = '',
  REACT_APP_MAJOR_VERSION = '1',
  REACT_APP_RELEASE_NUMBER = '',
  REACT_APP_BUILD_NUMBER = '',
  REACT_APP_COMMIT_SHA = '',
} = process.env;

let serverUri: string | null;
let googleGeocodingKey: string | null;
let freshdeskSupportUrl: string | null;
let oidcAuthority: string | null;
let oidcClientId: string | null;
let oidcRedirectUri: string | null;
let routingEngineUrl: string | null;
let promptUrl: string | null;
let promptKey: string | null;
let tilingEngineUrl: string | null;
let valhallaEngineUrl: string | null;
let hybridTilingUri: string | null;
const PER_PAGE_LIMIT = 1000000;

const apiVersion = version;

if (NODE_ENV === 'development') {
  serverUri = REACT_APP_SERVER_URI;
  googleGeocodingKey = REACT_APP_GOOGLE_GEOCODING_KEY;
  routingEngineUrl = ROUTING_ENGINE_URL;
  promptUrl = PROMPT_URL;
  promptKey = PROMPT_KEY;
  tilingEngineUrl = TILING_ENGINE_URL;
  valhallaEngineUrl = VALHALLA_ENGINE_URL;
  freshdeskSupportUrl = REACT_APP_FRESHDESK_SUPPORT_URL;
  oidcAuthority = REACT_APP_OIDC_AUTHORITY;
  oidcClientId = REACT_APP_OIDC_CLIENT_ID;
  oidcRedirectUri = REACT_APP_OIDC_REDIRECT_URI;
  hybridTilingUri = REACT_APP_MAPBOX_KEY;
} else {
  serverUri = getMetaTagContent('app:server-uri');
  googleGeocodingKey = getMetaTagContent('app:google-geocoding-key');
  routingEngineUrl = getMetaTagContent('app:routing-engine-url');
  promptUrl = getMetaTagContent('app:prompt-url');
  promptKey = getMetaTagContent('app:prompt-key');
  tilingEngineUrl = getMetaTagContent('app:tiling-engine-url');
  valhallaEngineUrl = getMetaTagContent('app:valhalla-engine-url');

  oidcAuthority = getMetaTagContent('app:oidc-authority');
  if (oidcAuthority === '__APP_OIDC_AUTHORITY__') {
    oidcAuthority = null;
  }
  oidcClientId = getMetaTagContent('app:oidc-client-id');
  if (oidcClientId === '__APP_OIDC_CLIENT_ID__') {
    oidcClientId = null;
  }
  oidcRedirectUri = getMetaTagContent('app:oidc-redirect-uri');
  if (oidcRedirectUri === '__APP_OIDC_REDIRECT_URI__') {
    oidcRedirectUri = null;
  }
  freshdeskSupportUrl = getMetaTagContent('app:freshdesk-support-url');
  if (freshdeskSupportUrl === '__APP_FRESHDESK_SUPPORT_URL__') {
    freshdeskSupportUrl = REACT_APP_FRESHDESK_SUPPORT_URL;
  }
  hybridTilingUri = getMetaTagContent('app:hybrid-tiling-uri');
  if (hybridTilingUri === '__APP_HYBRID_TILING_URI__') {
    hybridTilingUri = REACT_APP_MAPBOX_KEY;
  }
}

/*
 * Retrieve the server uri from the app:server-uri meta tag when in prod mode
 * in dev mode use the REACT_APP_SERVER_URI variable
 */
export default class ConfigService {
  static buildNumber: any;
  static get serverUri(): string | null {
    if (serverUri != null) {
      // strip trailing slash
      return serverUri.replace(/\/$/, '');
    }
    return serverUri;
  }

  /**
   * Should be one of production,development,testing,staging etc.
   */
  static get stage(): string {
    if (NODE_ENV === 'development') {
      return 'development';
    }
    return getMetaTagContent('app:stage') || 'production';
  }

  static get downloadPerPageLimit(): number {
    return PER_PAGE_LIMIT;
  }

  static get googleGeocodingKey(): string | null {
    return googleGeocodingKey;
  }

  static get freshdeskSupportUrl(): string | null {
    return freshdeskSupportUrl;
  }

  static get routingEngineUrl(): string | null {
    return routingEngineUrl;
  }

  static get promptUrl(): string | null {
    return promptUrl;
  }

  static get promptKey(): string | null {
    return promptKey;
  }

  static get valhallaEngineUrl(): string | null {
    return valhallaEngineUrl;
  }

  static get tilingEngineUrl(): string | null {
    return tilingEngineUrl;
  }

  static get oidcAuthority(): string | null {
    return oidcAuthority;
  }

  static get oidcClientId(): string | null {
    return oidcClientId;
  }

  static get oidcRedirectUri(): string | null {
    return oidcRedirectUri;
  }

  static get displayAppBuildDetails(): string | null {
    return `v${REACT_APP_MAJOR_VERSION}.${REACT_APP_RELEASE_NUMBER}.${REACT_APP_BUILD_NUMBER}.${REACT_APP_COMMIT_SHA} | ${apiVersion}`;
  }

  static get apiVersion(): string | null {
    return apiVersion;
  }

  static get hybridTilingUri(): string | null {
    return hybridTilingUri;
  }
}
