import React from 'react';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import {getStopPlannedTAT} from '../../../../utils';
import {
  Grid,
  Table,
  TableColumnResizing,
  TableEditColumn,
  TableEditRow,
  TableHeaderRow,
  TableRowDetail,
} from '@devexpress/dx-react-grid-material-ui';
import {EditingState, RowDetailState} from '@devexpress/dx-react-grid';
import Cell from '../../../devExpressTable/Cell';
import NoDataLoading from '../../../table/NoDataLoading';
import {EditCell} from '../../../table/TableCommands';
import {RoleService} from '../../../../service';
import {Command} from '../../../table';
import {Stack, Typography} from '@mui/material';
import {ArrowForward} from '@mui/icons-material';
import {TripEditStopWithoutForm} from './TripEditStopWithoutForm';

const TableRow = SortableElement((props) => {
  return <Table.Row {...props}>{props.children}</Table.Row>;
});

const RowDetailWithoutForm = ({row, commitChanges, contractCode}) => {
  return (
    <div style={{display: 'flex', flex: 1, justifyContent: 'space-between'}}>
      <TripEditStopWithoutForm
        commitChanges={commitChanges}
        tripStop={row}
        contractCode={contractCode}
      />
    </div>
  );
};

export const TripEditCardSortableList = SortableContainer((props) => {
  const {
    items,
    itemsInitial,
    rowChanges,
    loading,
    expandedRowIds,
    editingRowIds,
    onExpandedRowIdsChange,
    changeRowChanges,
    changeEditingRowIds,
    commitChanges,
    editSequence,
    creatingRow,
    contractCode, // TODO replace with contractId
    allowDepotEdits,
  } = props;

  const getInitialRow = React.useCallback(
    (stopId) => {
      if (!stopId) return undefined;

      return itemsInitial.find(({id}) => id === stopId);
    },
    [itemsInitial]
  );

  const columns = [
    {name: 'siteType', title: 'Site Type'},
    {name: 'taskTemplateNodeType', title: 'Task Template Node Type'},
    {name: 'siteName', title: 'Site Name'},
    {name: 'sequence', title: 'Sequence'},
    {name: 'externalReference', title: 'External Reference'},
    {name: 'arrivalTime', title: 'Planned Arrival Time'},
    {name: 'departureTime', title: 'Planned Departure Time'},
    {
      name: 'plannedTAT',
      title: 'Planned TAT',
      getCellValue: (row) => {
        const initialStop = getInitialRow(row.id);

        const initialStopTAT = initialStop
          ? initialStop.totalServiceTime ?? getStopPlannedTAT(initialStop)
          : getStopPlannedTAT(row);

        const totalServiceTime = row.totalServiceTime;

        if (
          totalServiceTime &&
          parseInt(totalServiceTime) !==
            (typeof initialStopTAT === 'string'
              ? parseInt(initialStopTAT)
              : initialStopTAT)
        ) {
          return (
            <Stack direction="row" gap={0.5}>
              <Typography color="red">{initialStopTAT}</Typography>
              <ArrowForward fontSize="small" />
              <Typography color="green">{totalServiceTime}</Typography>
            </Stack>
          );
        }
        return initialStopTAT;
      },
    },
  ];

  return (
    <Grid rows={items} columns={columns}>
      <RowDetailState
        expandedRowIds={expandedRowIds}
        onExpandedRowIdsChange={onExpandedRowIdsChange}
      />
      <EditingState
        editingRowIds={editingRowIds}
        onEditingRowIdsChange={changeEditingRowIds}
        onRowChangesChange={changeRowChanges}
        rowChanges={rowChanges}
        onCommitChanges={commitChanges}
      />
      <Table
        cellComponent={Cell}
        rowComponent={(rowProps) => {
          const {row} = rowProps;
          const key = row
            ? row.newSequence
              ? row.newSequence
              : row.sequence
            : 0;
          // prevent first and last stops from being edited
          if ([1, items.length].includes(key) && !allowDepotEdits) {
            return <Table.Row {...rowProps}>{rowProps.children}</Table.Row>;
          }
          // prevent drag when editSequence is not active
          if (!editSequence) {
            return <Table.Row {...rowProps}>{rowProps.children}</Table.Row>;
          }
          return (
            <TableRow
              key={`item-${key}`}
              index={key}
              {...rowProps}
              style={{backgroundColor: '#8FBC8F'}}
            />
          );
        }}
        noDataRowComponent={() => (
          <NoDataLoading
            loading={loading}
            numberOfColumns={columns.length + 2}
          />
        )}
      />
      <TableColumnResizing
        defaultColumnWidths={columns.map((c) => ({
          columnName: c.name,
          width: 'auto',
        }))}
        resizingMode="nextColumn"
      />
      <TableEditRow cellComponent={EditCell} />
      <TableRowDetail
        contentComponent={(rowProps) => (
          <RowDetailWithoutForm
            {...rowProps}
            commitChanges={commitChanges}
            contractCode={contractCode}
          />
        )}
        toggleCellComponent={(rowProps) => (
          <TableRowDetail.ToggleCell
            {...rowProps}
            data-test={`expand-row-${rowProps.row.id}`}
          />
        )}
      />
      <TableHeaderRow />
      <TableEditColumn
        width={60}
        showDeleteCommand={
          RoleService.hasPermission('Delete MasterTrip', 'Delete') &&
          !creatingRow &&
          !editSequence
        }
        commandComponent={Command}
      />
    </Grid>
  );
});
