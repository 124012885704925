import clsx from 'clsx';
import {DateTime} from 'luxon';
import {
  IPlanningBoardGanttItem,
  usePlanningBoardGanttStyles,
} from '../planningBoardPlan/planningBoardGantt';
import {
  MasterTripTypes,
  WebPlanningBoardMasterTripWithIdType,
} from '../planningBoardContext';
import {
  WebPlanningBoardBooking,
  WebPlanningBoardTripStop,
} from '@onroadvantage/onroadvantage-api';
import {getDailyViewTooltip} from './getDailyViewTooltip';
import {isRestStop} from '../../../factory/template';

export type TGetVehicle24HourGanttData = (params: {
  bookings?: WebPlanningBoardBooking[];
  bookingMasterTrips?: WebPlanningBoardMasterTripWithIdType[];
  masterMasterTrips?: WebPlanningBoardMasterTripWithIdType[];
  optimalMasterTrips?: WebPlanningBoardMasterTripWithIdType[];
  planningMasterTrips?: WebPlanningBoardMasterTripWithIdType[];
  classes: ReturnType<typeof usePlanningBoardGanttStyles>;
}) => IPlanningBoardGanttItem[];

export const getVehicle24HourGanttData: TGetVehicle24HourGanttData = ({
  bookings,
  bookingMasterTrips,
  masterMasterTrips,
  planningMasterTrips,
  classes,
}) => {
  const items: IPlanningBoardGanttItem[] = [];

  bookingMasterTrips?.forEach((masterTrip) => {
    const bookingsMasterTripIds = bookings?.map(
      ({bookingMasterTripId}) => `booking${bookingMasterTripId}`
    );
    if (
      masterTrip.id &&
      bookingsMasterTripIds?.includes(masterTrip.id?.toString())
    ) {
      const stopsItems = getTripStops({
        masterTrip,
        classes,
        type: 'maintenance',
      });
      items.push(...stopsItems);
    } else {
      const stopsItems = getTripStops({
        masterTrip,
        classes,
        type: 'booking',
      });
      items.push(...stopsItems);
    }
  });

  masterMasterTrips?.forEach((masterTrip) => {
    const stopsItems = getTripStops({masterTrip, classes, type: 'master'});
    items.push(...stopsItems);
  });

  planningMasterTrips?.forEach((masterTrip) => {
    const stopsItems = getTripStops({masterTrip, classes, type: 'planning'});
    items.push(...stopsItems);
  });

  return items;
};

const getTripStops = ({
  masterTrip,
  classes,
  type,
}: {
  masterTrip: WebPlanningBoardMasterTripWithIdType;
  classes: ReturnType<typeof usePlanningBoardGanttStyles>;
  type: MasterTripTypes;
}) => {
  const items: IPlanningBoardGanttItem[] = [];

  const stopGroupedByDay: Record<string, WebPlanningBoardTripStop[]> = {};

  masterTrip.trip?.stops?.forEach((stop) => {
    const end = stop.serviceTimeEnd
      ? DateTime.fromJSDate(stop.serviceTimeEnd).toLocal()
      : stop.departureTime
      ? DateTime.fromJSDate(stop.departureTime).toLocal()
      : null;

    if (end?.day) {
      stopGroupedByDay[end.day.toString()] = [
        ...(stopGroupedByDay[end.day.toString()] ?? []),
        stop,
      ];
    }
  });

  Object.keys(stopGroupedByDay).forEach((day) => {
    const stopsForDay = stopGroupedByDay[day];

    if (stopsForDay.length > 0) {
      let stopToUse: WebPlanningBoardTripStop | undefined;

      stopsForDay.forEach((stop) => {
        if (!stopToUse) {
          stopToUse = stop;
        } else if (isRestStop(stopToUse) && !isRestStop(stop)) {
          stopToUse = stop;
        } else if (stopToUse && !isRestStop(stopToUse) && !isRestStop(stop)) {
          const end = stop.serviceTimeEnd
            ? DateTime.fromJSDate(stop.serviceTimeEnd).toLocal()
            : stop.departureTime
            ? DateTime.fromJSDate(stop.departureTime).toLocal()
            : null;
          const prevEnd = stopToUse.serviceTimeEnd
            ? DateTime.fromJSDate(stopToUse.serviceTimeEnd).toLocal()
            : stopToUse.departureTime
            ? DateTime.fromJSDate(stopToUse.departureTime).toLocal()
            : null;

          if (!prevEnd) {
            stopToUse = stop;
          } else if (end && end.diff(prevEnd).shiftTo('hours').hours > 0) {
            stopToUse = stop;
          }
        }
      });

      const end = stopToUse?.serviceTimeEnd
        ? DateTime.fromJSDate(stopToUse.serviceTimeEnd).toLocal()
        : stopToUse?.departureTime
        ? DateTime.fromJSDate(stopToUse.departureTime).toLocal()
        : null;

      if (end && stopToUse?.id && masterTrip.trip?.vehicleId && masterTrip.id) {
        items.push({
          className: clsx(classes.ganttItem, {
            [classes.ganttItemBooking]:
              type === 'booking' &&
              !masterTrip.trip?.planningOptions?.isAppointment,
            [classes.ganttItemMaintenance]:
              type === 'maintenance' ||
              masterTrip.trip?.planningOptions?.isAppointment,
            [classes.ganttItemMaster]:
              type === 'master' &&
              !masterTrip.trip?.planningOptions?.isAppointment,
            [classes.ganttItemPlanning]: type === 'planning',
          }),
          title: getDailyViewTooltip(masterTrip, stopToUse),
          content: isRestStop(stopToUse)
            ? 'In Transit'
            : stopToUse.node?.name ?? '-',
          start: end.startOf('day').toJSDate(),
          end: end.endOf('day').toJSDate(),
          data: masterTrip,
          id: stopToUse.id,
          group: masterTrip.trip?.vehicleId,
          subgroup: masterTrip.id.toString(),
          variant: type === 'maintenance' ? 'booking' : 'masterTrip',
          vehicle: undefined,
        });
      }
    }
  });

  return items;
};
