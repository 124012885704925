import React from 'react';
import {
  ApiProductGroupProductGroupIdAssignedGetRequest,
  ProductList,
  Product,
  Listing,
} from '@onroadvantage/onroadvantage-api';
import {useAppNotifications} from '../../../contexts';
import {productGroupApi} from '../../../api';
import {
  TemplateTableContextProps,
  TLoadList,
  TOnInlineAdd,
  useTemplateTable,
} from '../../../factory/template';
import {ProductGroupContext} from '../ProductGroupContext';
import {RoleService} from '../../../service';

export type ProductGroupProductsContextProps = TemplateTableContextProps<
  Product,
  ProductList
>;

export const ProductGroupProductsContext =
  React.createContext<ProductGroupProductsContextProps>({
    // Template Table Defaults
    loading: false,
    list: [],
    currentPage: 1,
    // ProductGroupProducts
    loadList: async () => {},
  });

interface ProductGroupProductsContextProviderProps {
  productGroupProductsId?: number;
}

export const ProductGroupProductsContextProvider: React.FC<
  ProductGroupProductsContextProviderProps
> = ({children}) => {
  const notify = useAppNotifications();
  const {productGroupId} = React.useContext(ProductGroupContext);
  // Template Table
  const [
    {
      // States
      currentPage,
      pageSize,
      pageTotal,
      sorting,
      hasPermission,
      itemTotal,
      list,
      loading,
      loadingSingleItem,
      filters,
    },
    {
      // Getters
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
    },
  ] = useTemplateTable<
    Product,
    ApiProductGroupProductGroupIdAssignedGetRequest
  >({
    editPermission: 'Edit ProductGroup',
    addPermission: 'Add ProductGroup',
    deletePermission: 'Delete ProductGroup',
    downloadPermission: 'ProductGroup ListDownload',
    viewPermission: 'ProductGroup List',
  });

  const loadList = React.useCallback<TLoadList<ProductList>>(
    async (options) => {
      setLoading(true);
      try {
        if (productGroupId) {
          const requestObj = getRequestObj(['description', 'name'], options, {
            productGroupId,
          });
          const response =
            await productGroupApi.apiProductGroupProductGroupIdAssignedGet(
              requestObj
            );
          return getResponse(response, options);
        }
      } catch (e) {
        notify(
          'error',
          e.message ?? 'Failed to load product Group Products list'
        );
      } finally {
        setLoading(false);
      }
    },
    [setLoading, productGroupId, getRequestObj, getResponse, notify]
  );

  const handleDelete = React.useCallback(
    async (row: Product) => {
      setLoading(true);
      try {
        if (productGroupId && row.id) {
          await productGroupApi.apiProductGroupProductGroupIdUnassignPost({
            productGroupId,
            body: {productIds: [row.id]},
          });
          await loadList();
          notify('success', 'Unassigned product from group');
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to delete');
      } finally {
        setLoading(false);
      }
    },
    [loadList, notify, setLoading, productGroupId]
  );

  const handleInlineAdd = React.useCallback<TOnInlineAdd>(
    async (changes) => {
      setLoading(true);
      const productsListing = changes[0]?.name;
      const productIds = productsListing
        ?.filter(({value}: Listing) => value)
        .map(({value}: Listing) => value) as number[];
      try {
        if (productGroupId && productIds?.length > 0) {
          await productGroupApi.apiProductGroupProductGroupIdAssignPost({
            productGroupId,
            body: {productIds},
          });
          await loadList();
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to assign products to group');
      } finally {
        setLoading(false);
      }
    },
    [loadList, notify, setLoading, productGroupId]
  );

  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  const value: ProductGroupProductsContextProps = {
    // Template Table
    list,
    loadList,
    hasPermission,
    loading: loading || loadingSingleItem,
    cleanupList,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onInlineAdd: RoleService.hasPermission('Edit ProductGroup', 'Edit')
      ? handleInlineAdd
      : undefined,
    onDelete: RoleService.hasPermission('Edit ProductGroup', 'Edit')
      ? handleDelete
      : undefined,
    onRefresh: handleRefresh,
  };

  return (
    <ProductGroupProductsContext.Provider value={value}>
      {children}
    </ProductGroupProductsContext.Provider>
  );
};
