import React from 'react';
import {Chip} from '@mui/material';
import {
  usePlanningBoardContext,
  usePlanningBoardSettingsContext,
} from '../planningBoardContext';
import {usePlanningBoardToolbarStyles} from './PlanningBoardToolbar.style';

export type TPlanningBoardFilterOptions =
  | 'contract'
  | 'customer'
  | 'optimalMasterTrips'
  | 'planningSkill';

export const PlanningBoardToolbarActiveFilters: React.FC = () => {
  const classes = usePlanningBoardToolbarStyles();
  const {
    contractIds,
    planningSkillIds,
    customerIds,
    optimalMasterTrips,
    loadVehicles,
    loadMasterTrips,
    loadOrders,
    setSelectedContractListings,
    setSelectedCustomerListings,
    setSelectedPlanningSkillListings,
    setOptimalMasterTrips,
    cleanup,
  } = usePlanningBoardContext();
  const {enableCheckTripEnd, setEnableCheckTripEnd} =
    usePlanningBoardSettingsContext();
  const [activeFilters, setActiveFilters] = React.useState<
    TPlanningBoardFilterOptions[]
  >([]);
  React.useEffect(() => {
    contractIds.length > 0 && setActiveFilters((prev) => [...prev, 'contract']);
    customerIds.length > 0 && setActiveFilters((prev) => [...prev, 'customer']);
    planningSkillIds.length > 0 &&
      setActiveFilters((prev) => [...prev, 'planningSkill']);
    optimalMasterTrips.length > 0 &&
      setActiveFilters((prev) => [...prev, 'optimalMasterTrips']);
    return () => setActiveFilters([]);
  }, [contractIds, planningSkillIds, customerIds, optimalMasterTrips]);

  const handleRemoveFilter = React.useCallback(
    (filter: TPlanningBoardFilterOptions) => (_event: any) => {
      setActiveFilters((prev) => prev.filter((item) => item !== filter));
      switch (filter) {
        case 'contract':
          setSelectedContractListings([]);
          cleanup();
          break;
        case 'customer':
          setSelectedCustomerListings([]);
          loadOrders('onrefresh', ['customer']);
          loadMasterTrips(['customer']);
          loadVehicles(['customer']);
          break;
        case 'planningSkill':
          setSelectedPlanningSkillListings([]);
          loadOrders('onrefresh', ['planningSkill']);
          loadMasterTrips(['planningSkill']);
          loadVehicles(['planningSkill']);
          break;
        case 'optimalMasterTrips':
          setOptimalMasterTrips([]);
          break;
        default:
          break;
      }
    },
    [
      cleanup,
      loadMasterTrips,
      loadOrders,
      loadVehicles,
      setOptimalMasterTrips,
      setSelectedContractListings,
      setSelectedCustomerListings,
      setSelectedPlanningSkillListings,
    ]
  );

  const handleRemoveEnableCheckTripEnd = React.useCallback(() => {
    setEnableCheckTripEnd(false);
    localStorage.setItem('enableCheckTripEnd', 'false');
  }, [setEnableCheckTripEnd]);

  return (
    <div className={classes.activeFilters}>
      {activeFilters.map((filter) => (
        <Chip
          key={filter}
          label={filter}
          onDelete={handleRemoveFilter(filter)}
          size="small"
          className={classes.activeFilter}
        />
      ))}
      {enableCheckTripEnd && (
        <Chip
          label="Enable Check Trip End"
          onDelete={handleRemoveEnableCheckTripEnd}
          size="small"
          className={classes.activeFilter}
        />
      )}
    </div>
  );
};
