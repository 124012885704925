import React from 'react';
import {useParams} from 'react-router-dom';
import {useContractCriticalEventTypePromptContext} from './ContractCriticalEventTypePromptContext';

interface IParams {
  contractCriticalEventTypePromptId?: string;
}

export const ContractCriticalEventTypePromptIdHandler = () => {
  const {setContractCriticalEventTypePromptId} =
    useContractCriticalEventTypePromptContext();

  const {contractCriticalEventTypePromptId} = useParams<IParams>();

  React.useEffect(() => {
    if (
      contractCriticalEventTypePromptId != null &&
      contractCriticalEventTypePromptId !== 'add'
    ) {
      const parsedId = contractCriticalEventTypePromptId
        ? parseInt(contractCriticalEventTypePromptId)
        : undefined;
      setContractCriticalEventTypePromptId(parsedId);
    }
    return () => setContractCriticalEventTypePromptId(undefined);
  }, [contractCriticalEventTypePromptId, setContractCriticalEventTypePromptId]);
  return null;
};
