import React, {useEffect, useRef} from 'react';
import RssFeedIcon from '@mui/icons-material/RssFeed';
import {generateUid} from '../../../service/Util';
import {useAppNotifications} from '../../../contexts';
import {useTripContext} from '../tripContext';
import {IVantageDialogRef, VantageDialog} from '../../dialog';
import {Loader} from '../../loader';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  Checkbox,
  Collapse,
  FormControl,
  Radio,
  RadioGroup,
  Button,
  TextField,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  Zoom,
} from '@mui/material';
import ReactAudioPlayer from 'react-audio-player';
import VoiceService from '../../../service/VoiceService';
import {listingApi, driverApi, broadcastApi} from '../../../api';

export const TripSummaryBroadcast: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const {masterTrip} = useTripContext();
  const notify = useAppNotifications();
  const driverObj = masterTrip?.trip?.driver;
  const vehicleObj = masterTrip?.trip?.vehicle;
  const contractId = masterTrip?.trip?.contract?.id;
  const driverId = masterTrip?.trip?.driver?.id;

  const broadcastRef = useRef<IVantageDialogRef>(null);
  const [checkedDriver, setCheckedDriver] = React.useState(true);
  const [checkedVehicle, setCheckedVehicle] = React.useState(true);
  const [hasSmartphone, setHasSmartphone] = React.useState(false);
  const [hasVoicekitNumber, setHasVoicekitNumber] = React.useState(false);
  const [canSend, setCanSend] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState('template');
  const [title, setTitle] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [smartphoneId, setSmartphoneId] = React.useState<number | undefined>(
    undefined
  );
  const [voicekitNumber, setVoicekitNumber] = React.useState<
    string | undefined
  >(undefined);
  const [chosenTemplate, setChosenTemplate] = React.useState('');
  const [generating, setGenerating] = React.useState<boolean>(false);
  const [generatedUrl, setGeneratedUrl] = React.useState<string | undefined>(
    undefined
  );
  const [messageIdentifier, setMessageIdentifier] = React.useState<string>('');
  const [templates, setTemplates] = React.useState<string[]>([]);

  const getTemplateOptions = React.useCallback(async () => {
    setLoading(true);
    try {
      const response = await listingApi.apiListingGet({
        model: 'ContractBroadcastTemplate',
        contractId: contractId,
      });
      return response.items;
    } catch (e) {
      notify('error', e.message ?? 'Failed to load template listing');
      return [];
    } finally {
      setLoading(false);
    }
  }, [notify, contractId]);

  useEffect(() => {
    async function fetchTemplates() {
      try {
        const fetchedTemplates = await getTemplateOptions();
        if (fetchedTemplates) {
          setTemplates(fetchedTemplates.flatMap((t) => t.label ?? []));
        }
      } catch (error) {
        notify('error', 'Failed to fetch templates:');
      }
    }

    fetchTemplates();
    return () => {
      setTemplates([]);
    };
  }, [notify, getTemplateOptions]);

  const getDriverDetails = React.useCallback(async () => {
    setLoading(true);
    try {
      if (driverId !== undefined) {
        const response = await driverApi.apiDriverDriverIdGet({
          driverId: driverId,
        });

        if (response) {
          if (response.user && response.user.smartphone) {
            setHasSmartphone(true);
            setCanSend(true);
            setCheckedDriver(true);
            setSmartphoneId(response.user.smartphone.id);
          } else {
            setCheckedDriver(false);
            setHasSmartphone(false);
            setSmartphoneId(undefined);
            notify('warning', 'No mobile device linked to this driver.');
          }
        }
      }
    } catch (e) {
      notify('error', e.message ?? 'Failed to get driver detail');
    } finally {
      setLoading(false);
    }
  }, [notify, driverId]);

  const getVehicleDetails = React.useCallback(async () => {
    setLoading(true);
    try {
      if (vehicleObj) {
        if (vehicleObj.voicekitNumber) {
          setHasVoicekitNumber(true);
          setCanSend(true);
          setCheckedVehicle(true);
          setVoicekitNumber(vehicleObj.voicekitNumber);
        } else {
          setHasVoicekitNumber(false);
          setCheckedVehicle(false);
          notify('warning', 'Voicekit Number not available for this vehicle.');
        }
      }
    } catch (e) {
      notify('error', e.message ?? 'Failed to get vehicle detail');
    } finally {
      setLoading(false);
    }
  }, [notify, vehicleObj]);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue((event.target as HTMLInputElement).value);
    setGeneratedUrl(undefined);
    setMessageIdentifier('');
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    if (name === 'title') {
      setTitle(value);
    } else if (name === 'message') {
      setMessage(value);
    }
  };

  const cleanup = () => {
    setHasSmartphone(false);
    setHasVoicekitNumber(false);
    setGeneratedUrl(undefined);

    if (messageIdentifier !== '') {
      setMessageIdentifier('');
    }
    if (chosenTemplate !== '') {
      setChosenTemplate('');
    }
    if (message !== '') {
      setMessage('');
    }
    if (title !== '') {
      setTitle('');
    }
    if (voicekitNumber !== '') {
      setVoicekitNumber(undefined);
    }
    if (smartphoneId) {
      setSmartphoneId(undefined);
    }
  };

  const validateFields = React.useCallback(() => {
    let isValid = true;
    const messages: string[] = [];

    if (selectedValue === 'custom') {
      if (!message) {
        messages.push('Message is required.');
        isValid = false;
      }

      if (!title) {
        messages.push('Title is required.');
        isValid = false;
      }
    } else if (selectedValue === 'template') {
      if (!chosenTemplate) {
        messages.push('Template is required.');
        isValid = false;
      }
    }

    if (!isValid) {
      notify('warning', messages.join(' '));
    }

    return isValid;
  }, [notify, message, title, selectedValue, chosenTemplate]);

  const handleSubmit = async () => {
    setLoading(true);

    if (messageIdentifier) {
      if (
        hasSmartphone &&
        hasVoicekitNumber &&
        checkedDriver &&
        checkedVehicle
      ) {
        try {
          await Promise.all([submitBroadcastToSmartphone(), submitToNumber()]);
          notify('success', 'Broadcast sent');
        } catch (e) {
          notify('error', 'Failed to send broadcast');
        } finally {
          setLoading(false);
          handleCancelDialog();
        }
      } else if (hasSmartphone && checkedDriver) {
        try {
          await submitBroadcastToSmartphone();
          notify('success', 'Broadcast sent');
        } catch (e) {
          notify('error', 'Failed to send broadcast');
        } finally {
          setLoading(false);
          handleCancelDialog();
        }
      } else if (hasVoicekitNumber && checkedVehicle) {
        try {
          await submitToNumber();
          notify('success', 'Broadcast sent');
        } catch (e) {
          notify('error', 'Failed to send broadcast');
        } finally {
          setLoading(false);
          handleCancelDialog();
        }
      } else {
        notify('warning', 'No valid target available to send the broadcast');
        setLoading(false);
      }
    } else {
      notify('warning', 'Generate message before sending');
      setLoading(false);
    }
  };
  const submitBroadcastToSmartphone = async () => {
    if (hasSmartphone && checkedDriver) {
      try {
        let callTitle = title;
        let callMessage = message;

        if (selectedValue === 'template' && chosenTemplate) {
          const template = templates.find(
            (template) => template === chosenTemplate
          );
          if (template) {
            const [templateTitle, templateMessage] = template.split(' - ');
            callTitle = templateTitle;
            callMessage = templateMessage;
          }
        }

        // Send the broadcast to the specific smartphone ID
        await broadcastApi.apiBroadcastPost({
          body: {
            smartphoneIds: smartphoneId !== undefined ? [smartphoneId] : [],
            heading: callTitle,
            messageUploadIdentifier: messageIdentifier,
            message: callMessage,
          },
        });

        notify('success', 'Broadcast sent to smartphone');
      } catch (e) {
        notify('error', 'Failed to send broadcast to smartphone');
      }
    }
  };

  const submitToNumber = async () => {
    if (hasVoicekitNumber && checkedVehicle) {
      try {
        let callTitle = title;
        let callMessage = message;

        if (selectedValue === 'template' && chosenTemplate) {
          // Handle generating call title and message from the selected template
          const template = templates.find(
            (template) => template === chosenTemplate
          );
          if (template) {
            const [templateTitle, templateMessage] = template.split(' - ');
            callTitle = templateTitle;
            callMessage = templateMessage;
          }
        }

        await VoiceService.createDirectCall({
          message: callMessage,
          heading: callTitle,
          identifier: messageIdentifier,
          toNumber: voicekitNumber,
        });

        notify('success', 'Broadcast sent to vehicle voicekit number');
      } catch (e) {
        notify('error', 'Failed to Broadcast to vehicle voicekit number');
      }
    }
  };

  const handleClickSend = async () => {
    if (messageIdentifier && !generating) {
      try {
        await handleSubmit();
      } catch (error) {
        notify('error', 'Failed to submit broadcast');
      }
    } else {
      notify('warning', 'Generate audio before sending');
    }
  };

  const handleClickGenerate = async () => {
    try {
      await generateAudioFile();
    } catch (error) {
      notify('error', 'Failed to generate Audio');
    }
  };

  const generateAudioFile = async () => {
    if (!validateFields()) {
      return;
    }
    setLoading(true);
    setGenerating(true);
    try {
      const identifier = `${generateUid()}.mp3`;
      let audioMessage = message;

      if (selectedValue === 'template' && chosenTemplate) {
        const template = templates.find(
          (template) => template === chosenTemplate
        );
        if (template) {
          const templateParts = template.split(' - ');
          audioMessage = templateParts[1];
        }
      }

      const data = await VoiceService.generateVoiceMessage({
        identifier: identifier,
        message: audioMessage,
      });

      setGeneratedUrl(data.data);
      setMessageIdentifier(identifier);
    } catch (e) {
      notify('error', 'Failed to generate message.');
    } finally {
      setGenerating(false);
      setLoading(false);
    }
  };

  const renderGenerateButton = () => {
    if (!messageIdentifier) {
      return (
        <Button variant="contained" size="small" onClick={handleClickGenerate}>
          Generate
        </Button>
      );
    }
  };

  const renderSendButton = () => {
    if (messageIdentifier) {
      return (
        <Button
          variant="contained"
          size="small"
          onClick={handleClickSend}
          disabled={!canSend}
        >
          Send
        </Button>
      );
    }
  };
  const handleCancelDialog = () => {
    if (broadcastRef.current) {
      broadcastRef.current.closeDialog();
      setSelectedValue('template');
      cleanup();
    }
  };
  const handleOpenDialog = async () => {
    if (broadcastRef.current) {
      if (driverId !== undefined) {
        await getDriverDetails();
      }

      if (vehicleObj !== undefined) {
        await getVehicleDetails();
      }

      if (hasSmartphone) {
        setCheckedDriver(true);
      }

      if (hasVoicekitNumber) {
        setCheckedVehicle(true);
      }

      broadcastRef.current.openDialog();
    }
  };

  return (
    <>
      <IconButton onClick={handleOpenDialog}>
        <RssFeedIcon />
      </IconButton>
      <VantageDialog
        ref={broadcastRef}
        maxWidth="sm"
        declineProps={{sx: {marginRight: 25}}}
        dialogTitle="Broadcasting"
        disableAccept={true}
        disableDecline={true}
        title={<RssFeedIcon />}
      >
        <div>
          <strong>Broadcast To:</strong>
          <br />
          <FormControlLabel
            control={
              <Checkbox
                checked={checkedDriver}
                onChange={() => setCheckedDriver(!checkedDriver)}
                name="checkedDriver"
                color="primary"
                disabled={!driverObj || !hasSmartphone}
              />
            }
            label={
              driverObj
                ? `${driverObj?.firstname ?? ''} - ${
                    driverObj?.externalExtendedId
                  }`
                : 'Driver Unavailable'
            }
          />
          <br />
          <FormControlLabel
            control={
              <Checkbox
                checked={checkedVehicle}
                onChange={() => setCheckedVehicle(!checkedVehicle)}
                name="checkedVehicle"
                color="primary"
                disabled={!vehicleObj || !hasVoicekitNumber}
              />
            }
            label={
              vehicleObj
                ? `${vehicleObj?.registrationNumber ?? ''} - ${
                    vehicleObj?.fleetNumber ?? ''
                  }`
                : 'Vehicle Unavailable'
            }
          />
          <br />
          <FormControl component="fieldset" sx={{marginTop: '20px'}}>
            <RadioGroup
              row
              aria-label="option"
              name="row-radio-buttons-group"
              value={selectedValue}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value="template"
                control={<Radio />}
                label="Template"
              />
              <FormControlLabel
                value="custom"
                control={<Radio />}
                label="Custom"
              />
            </RadioGroup>
          </FormControl>
          <br />

          {/* Conditionally rendering based on loading and selectedValue */}
          {loading ? (
            <Loader />
          ) : (
            <div>
              {selectedValue === 'custom' && (
                <div>
                  <TextField
                    style={{width: 300, marginTop: 20}}
                    placeholder="Enter title"
                    id="title"
                    name="title"
                    label="Title"
                    onChange={handleInputChange}
                    value={title}
                  />
                  <br />
                  <TextField
                    style={{width: 300, marginTop: 20}}
                    placeholder="Enter message"
                    id="message"
                    name="message"
                    label="Message"
                    onChange={handleInputChange}
                    value={message}
                  />
                </div>
              )}

              {selectedValue === 'template' && (
                <div
                  style={{
                    marginTop: 20,
                  }}
                >
                  <FormControl fullWidth sx={{maxWidth: 300}}>
                    <InputLabel id="broadcastTemplate">
                      Select a template
                    </InputLabel>
                    <Select
                      label="Select a template"
                      labelId="broadcastTemplate"
                      value={chosenTemplate || ''}
                      MenuProps={{PaperProps: {sx: {maxWidth: 300}}}}
                      onChange={(e) => {
                        const selectedTemplate = e.target.value as string;
                        setChosenTemplate(selectedTemplate);
                      }}
                    >
                      {templates.map((template, index) => (
                        <MenuItem value={template} key={index}>
                          <Tooltip
                            title={template}
                            placement="right"
                            TransitionComponent={Zoom}
                            PopperProps={{sx: {pointerEvents: 'none'}}}
                            arrow
                          >
                            <span>
                              {template.length > 40
                                ? `${template.substring(0, 40)}...`
                                : template}
                            </span>
                          </Tooltip>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )}
            </div>
          )}
          <br />
          <Collapse in={generatedUrl != null}>
            <ReactAudioPlayer src={generatedUrl} controls preload="none" />
          </Collapse>
          <br />

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 20,
            }}
          >
            <Button
              variant="outlined"
              size="small"
              style={{marginLeft: 140}}
              onClick={handleCancelDialog}
            >
              Cancel
            </Button>
            {renderGenerateButton()}
            {renderSendButton()}
          </div>
        </div>
      </VantageDialog>
    </>
  );
};
