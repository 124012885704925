import React from 'react';
import * as Yup from 'yup';
import {
  MasterTripDebriefConfig,
  Listing,
} from '@onroadvantage/onroadvantage-api';
import {InputAdornment} from '@mui/material';
import {
  NUMBER_ERROR_MESSAGE,
  requiredMultipleListingSchema,
  TemplateCard,
  TemplateForm,
} from '../../factory/template';
import {useTripDebriefConfigContext} from './tripDebriefConfigContext';
import {FormikAutocompleteWithListing, FormikTextField} from '../formik';

export interface ITripDebriefConfigForm {
  name: string;
  tatThreshold?: number | undefined;
  varianceThreshold?: number | undefined;
  contracts: Listing[];
}

const schema: Yup.SchemaOf<ITripDebriefConfigForm> = Yup.object({
  name: Yup.string().required('Required'),
  tatThreshold: Yup.number()
    .typeError(NUMBER_ERROR_MESSAGE)
    .min(0, 'Tat threshold cannot be less than 0'),
  varianceThreshold: Yup.number()
    .typeError(NUMBER_ERROR_MESSAGE)
    .min(0, 'Variance threshold cannot be less than 0'),
  contracts: requiredMultipleListingSchema,
});

const getInitialValues = (
  tripDebriefConfig?: MasterTripDebriefConfig | null
): ITripDebriefConfigForm | undefined => {
  if (tripDebriefConfig) {
    return {
      name: tripDebriefConfig.name,
      tatThreshold: tripDebriefConfig.tatThreshold,
      varianceThreshold: tripDebriefConfig.varianceThreshold,
      contracts:
        tripDebriefConfig.contracts?.map(({id, name, code}) => ({
          label: `${code} - ${name}`,
          value: id,
        })) ?? [],
    };
  }
  return undefined;
};

export const TripDebriefConfigForm: React.FC<{isAdd?: boolean}> = ({isAdd}) => {
  const {
    detailsRef,
    loadingTripDebriefConfig,
    submitting,
    masterTripDebriefConfig,
    onDetailsFormSubmit,
  } = useTripDebriefConfigContext();
  const [initialValues, setInitialValues] =
    React.useState<ITripDebriefConfigForm>();

  React.useEffect(() => {
    const values = getInitialValues(masterTripDebriefConfig);

    if (values) {
      setInitialValues(values);
    } else if (isAdd) {
      setInitialValues({
        name: '',
        contracts: [],
      });
    }
  }, [isAdd, masterTripDebriefConfig, setInitialValues]);

  return (
    <TemplateCard
      title={`Trip Debrief Config Details ${isAdd ? 'Add' : 'Edit'}`}
      loading={loadingTripDebriefConfig}
    >
      <TemplateForm<ITripDebriefConfigForm>
        initialValues={initialValues}
        onSubmit={onDetailsFormSubmit}
        permission={{
          name: `${isAdd ? 'Add' : 'Edit'} MasterTripDebriefConfig`,
          type: isAdd ? 'Add' : 'Edit',
        }}
        submitting={submitting}
        innerRef={detailsRef}
        validationSchema={schema}
      >
        <FormikTextField
          name="name"
          placeholder="Enter the trip debrief config name"
          label="Name"
          fullWidth
        />
        <FormikTextField
          name="tatThreshold"
          placeholder="Enter the trip debrief config tat threshold minutes"
          label="TAT Threshold (minutes)"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">minutes</InputAdornment>
            ),
          }}
          fullWidth
        />
        <FormikTextField
          name="varianceThreshold"
          placeholder="Enter the trip debrief config variance km threshold %"
          label="Variance Threshold (%)"
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          fullWidth
        />
        <FormikAutocompleteWithListing
          name="contracts"
          placeholder="Enter and select at least 1 contract for the trip debrief config"
          label="Contracts"
          model="Contract"
          multiple
        />
      </TemplateForm>
    </TemplateCard>
  );
};
