import React from 'react';
import Grid from '@mui/material/Grid';
import {MasterRouteLegDetailsForm} from './MasterRouteLegDetailsForm';
import {MasterRouteLegDetailsProps} from './MasterRouteLegDetails';
import MasterRouteLegMap from './MasterRouteLegMap';
import {MasterRouteContext} from '../../MasterRouteContext';
import {MasterRouteLegContext} from '../MasterRouteLegContext';
import {Container} from '@mui/material';
import Map from '../../../map/map';

export const MasterRouteLegDetailsContent: React.FC<
  MasterRouteLegDetailsProps
> = ({isAdd}) => {
  const {masterRoute} = React.useContext(MasterRouteContext);
  const {masterRouteLeg, updateWaypoints} = React.useContext(
    MasterRouteLegContext
  );
  return (
    <Grid container spacing={4} direction="row">
      <Grid item xs={12} sm={6}>
        <MasterRouteLegDetailsForm isAdd={isAdd} />
      </Grid>
      <Grid item xs={12} sm={6}>
        {masterRouteLeg && masterRoute ? (
          <MasterRouteLegMap
            masterRouteLeg={masterRouteLeg}
            masterRoute={masterRoute}
            updateWaypoints={updateWaypoints}
          />
        ) : (
          <Container style={{height: '60vh'}} disableGutters>
            <Map vehicle={{}} trip={{}} actualTrip={{}} route={{}} />
          </Container>
        )}
      </Grid>
    </Grid>
  );
};
