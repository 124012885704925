import React from 'react';
import {webEventApi} from '../api';
import {
  EVENT_PANE_AUTO_LOAD_NAME,
  GLOBAL_NAME,
} from '../service/SettingService';
import {SettingService} from '../service';
import {VantageFrameContext} from '../components/navigation/VantageFrameContext';

export const EVENTS_TIMEOUT = 30000;

export interface EventsContextProps {
  eventCountCritical: number;
  setEventCountCritical: React.Dispatch<React.SetStateAction<number>>;
  eventCountOperational: number;
  setEventCountOperational: React.Dispatch<React.SetStateAction<number>>;
  onLoadEventCount: () => Promise<void> | null;
}

export const EventsContext = React.createContext<EventsContextProps>({
  eventCountCritical: 0,
  setEventCountCritical: () => null,
  eventCountOperational: 0,
  setEventCountOperational: () => null,
  onLoadEventCount: () => null,
});

export const EventsContextProvider: React.FC = ({children}) => {
  const {rightDrawerWasOpened, rightDrawerOpen} =
    React.useContext(VantageFrameContext);
  const [eventCountCritical, setEventCountCritical] = React.useState<number>(0);
  const [eventCountOperational, setEventCountOperational] =
    React.useState<number>(0);
  const [autoLoad] = React.useState(
    SettingService.getSetting(EVENT_PANE_AUTO_LOAD_NAME, GLOBAL_NAME)
  );
  const timeoutRef = React.useRef<NodeJS.Timeout>();

  const handleLoadEventsCount = React.useCallback(async () => {
    try {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      const response = await webEventApi.apiWebEventCountGet();
      setEventCountCritical(response.criticalEvent ?? 0);
      setEventCountOperational(response.operationalEvent ?? 0);
    } finally {
      timeoutRef.current = setTimeout(
        () => handleLoadEventsCount(),
        EVENTS_TIMEOUT
      );
    }
  }, [timeoutRef]);

  React.useEffect(() => {
    if (autoLoad && !rightDrawerWasOpened && !rightDrawerOpen) {
      handleLoadEventsCount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoLoad, rightDrawerWasOpened, rightDrawerOpen]);

  React.useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const value: EventsContextProps = {
    eventCountCritical,
    setEventCountCritical,
    eventCountOperational,
    setEventCountOperational,
    onLoadEventCount: handleLoadEventsCount,
  };

  return (
    <EventsContext.Provider value={value}>{children}</EventsContext.Provider>
  );
};
