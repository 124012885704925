import {LatLngBoundsExpression, LatLngExpression} from 'leaflet';

export interface latLng {
  lat: number;
  lng: number;
}

interface latLngOffset {
  diffX: number;
  diffY: number;
}

const getPositionByTime = (
  movement: any,
  timestamp: Date
): [number, number] | null => {
  const max = function (arr: any) {
    return Math.max.apply(null, arr);
  };
  const min = function (arr: any) {
    return Math.min.apply(null, arr);
  };
  const nearest = function (times: any, y: any) {
    const x: number = new Date(y).getTime() / 1000;
    const l: number[] = [];
    const h: number[] = [];
    times.forEach((v: number) => {
      (v < x && l.push(v)) || (v > x && h.push(v));
    });
    return {low: times.indexOf(max(l)), high: times.indexOf(min(h))};
  };

  if (movement && movement.coordTimes) {
    const result = nearest(movement.coordTimes, timestamp);
    const response =
      result.low !== -1 ? result.low : result.high !== -1 ? result.high : 0;
    return movement.coordinates[response];
  }
  return null;
};

const getLeafletBounds = (target: {
  leafletElement: {getBounds: () => [number, number][]};
}): LatLngExpression | LatLngBoundsExpression | null => {
  if (target) {
    if (target.leafletElement) {
      return target.leafletElement.getBounds();
    }
  }
  return null;
};

const getInitialMapCenter = (props: any): [number, number] => {
  const {vehicle, siteEdit} = props;
  if (siteEdit) {
    return [siteEdit.latitude, siteEdit.longitude];
  }
  if (vehicle) {
    if (vehicle.lastSeen) {
      return [vehicle.lastSeen.latitude, vehicle.lastSeen.longitude];
    }
  }
  return [-25.783921, 28.280497];
};

const convertMultiPolygon2MultiplePolygons = (mp: {coordinates: any[]}) => {
  const features: any[] = [];
  if (!mp) {
    return null;
  }
  mp.coordinates.forEach((coords) => {
    const feat = {type: 'Polygon', coordinates: coords};
    features.push(feat);
  });
  return {
    type: 'FeatureCollection',
    features,
  };
};

const convertMultiplePolygons2MultiPolygon = (fc: {features: any[]}) => {
  const mp: any[] = [];
  fc.features.forEach((feature) => {
    if (feature.geometry.type !== 'Point') {
      mp.push(feature.geometry.coordinates);
    }
  });
  return {
    type: 'MultiPolygon',
    coordinates: mp,
  };
};

const getChangeInLatLng = (oldLE: latLng, newLE: latLng) => {
  const diffX = oldLE.lng - newLE.lng;
  const diffY = oldLE.lat - newLE.lat;
  return {diffY, diffX};
};

const movePointsByOffset = (
  offset: latLngOffset,
  points: {[key: string]: latLng},
  L: {latLng: (lat: number, lng: number) => latLng}
) => {
  const offsetPoints: latLng[] = [];
  const offsetObj: {[key: string]: latLng} = {};
  Object.keys(points).forEach((key) => {
    const point: latLng = {
      lat: points[key].lat - offset.diffY,
      lng: points[key].lng - offset.diffX,
    };
    const pointObj: latLng = L.latLng(
      points[key].lat - offset.diffY,
      points[key].lng - offset.diffX
    );
    offsetPoints.push(point);
    offsetObj[key] = pointObj;
  });
  return {points: offsetPoints, obj: offsetObj};
};

const moveMultiPolygonByOffset = (
  offset: latLngOffset,
  multiPolygon: {coordinates: any[]}
) => {
  if (!multiPolygon || !multiPolygon.coordinates) {
    return null;
  }
  multiPolygon.coordinates.forEach((polygon) => {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < polygon[0].length; i++) {
      // eslint-disable-next-line no-param-reassign
      polygon[0][i] = [
        polygon[0][i][0] - offset.diffX,
        polygon[0][i][1] - offset.diffY,
      ];
    }
  });
  return multiPolygon;
};

const getBoundsFromMap = (map: any) => {
  if (!map) {
    return null;
  }

  const bounds = map.leafletElement.getBounds();

  return {
    east: bounds.getEast(),
    south: bounds.getSouth(),
    north: bounds.getNorth(),
    west: bounds.getWest(),
  };
};

export {
  getPositionByTime,
  getLeafletBounds,
  convertMultiPolygon2MultiplePolygons,
  convertMultiplePolygons2MultiPolygon,
  getChangeInLatLng,
  movePointsByOffset,
  moveMultiPolygonByOffset,
  getInitialMapCenter,
  getBoundsFromMap,
};
