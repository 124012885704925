import React from 'react';
import {useCompartmentTruckCompartmentStyles} from './CompartmentTruckCompartment.style';
import {IVantageDialogRef, VantageDialog} from '../dialog';
import {CardActionArea, Divider, Tooltip, Typography} from '@mui/material';
import clsx from 'clsx';
import {VehicleCompartment} from '@onroadvantage/onroadvantage-api';

export interface CompartmentTruckCompartmentProps {
  compartment: VehicleCompartment;
  disableCompartmentTooltip?: boolean;
  onNavigate?: (compartment: VehicleCompartment) => void;
  onDelete?: (compartment: VehicleCompartment) => void;
  getActive?: (compartment: VehicleCompartment) => boolean;
  getCompartmentContent?: (compartment: VehicleCompartment) => React.ReactNode;
  getCompartment?: (compartment: VehicleCompartment) => React.ReactNode;
  tooltip?: React.ReactNode;
}
export const CompartmentTruckCompartment: React.FC<
  CompartmentTruckCompartmentProps
> = ({
  compartment,
  disableCompartmentTooltip,
  onNavigate,
  onDelete,
  tooltip,
  getActive,
  getCompartmentContent,
  getCompartment,
}) => {
  const classes = useCompartmentTruckCompartmentStyles();
  const dialogRef = React.useRef<IVantageDialogRef>(null);

  const handleNavigate = React.useCallback(() => {
    if (onNavigate && !dialogRef.current?.isOpen) onNavigate(compartment);
  }, [compartment, onNavigate]);

  const handleRightClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      dialogRef.current?.openDialog();
    },
    []
  );

  const handleDelete = React.useCallback(() => {
    if (onDelete) onDelete(compartment);
  }, [compartment, onDelete]);

  if (getCompartment) {
    return (
      <div className={classes.getCompartmentWrapper}>
        {getCompartment(compartment)}
      </div>
    );
  }

  return (
    <Tooltip
      classes={{
        tooltip: clsx(classes.tooltip, {
          [classes.tooltipDisabled]: disableCompartmentTooltip,
        }),
        arrow: classes.tooltipArrow,
      }}
      title={
        tooltip ?? (
          <div className={classes.tooltipContent}>
            <Typography variant="subtitle1" color="textPrimary">
              {compartment.name}
            </Typography>
            <Divider />
            <Typography variant="subtitle2" color="textPrimary">
              Loading {compartment.loadingSequence}
            </Typography>
            <Typography variant="subtitle2" color="textPrimary">
              Offloading {compartment.offloadingSequence}
            </Typography>
            <Typography variant="subtitle2" color="textPrimary">
              Position {compartment.position}
            </Typography>
          </div>
        )
      }
      arrow
    >
      <CardActionArea
        className={clsx(classes.compartment, {
          [classes.compartmentActive]: getActive && getActive(compartment),
          [classes.compartmentDefaultPointer]: !onNavigate,
        })}
        onClick={onNavigate ? handleNavigate : undefined}
        onContextMenu={handleRightClick}
        disableRipple={!onNavigate}
        disableTouchRipple={!onNavigate}
      >
        <VantageDialog
          title="Are you sure you want to delete?"
          onAccept={handleDelete}
          ref={dialogRef}
        />
        {getCompartmentContent && getCompartmentContent(compartment)}
      </CardActionArea>
    </Tooltip>
  );
};
