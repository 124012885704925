import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {Paper} from '@mui/material';
import {useOrderControllerStyles} from './OrderController.style';
import {
  OrderContext,
  OrderContextProps,
  OrderContextProvider,
} from './OrderContext';
import {
  BreadcrumbController,
  BreadcrumbRoute,
} from '../breadcrumbs/BreadcrumbController';
import _ from 'lodash';
import {OrderList} from './OrderList';
import {OrderIdHandler} from './OrderIdHandler';
import {Order} from './Order';
import {OrderLineList} from './orderLine';
import {OrderLineContextProvider} from './orderLine/OrderLineContext';
import {AllowedRoute} from '../router';
import {OrderAdd} from './OrderAdd';

export const OrderControllerComponent: React.FC = () => {
  const classes = useOrderControllerStyles();
  const {order} = React.useContext<OrderContextProps>(OrderContext);

  const routes: BreadcrumbRoute[] = [
    {
      path: '/orderlist',
      matchOptions: undefined,
      title: 'Order List',
    },
    {
      path: '/orderlist/add',
      matchOptions: undefined,
      title: 'New Order',
    },
    {
      path: '/orderlist/:orderId',
      matchOptions: undefined,
      title: (params) =>
        order?.orderNumber ?? `Order ${_.get(params, 'orderId', 'Single')}`,
    },
  ];

  return (
    <div className={classes.root}>
      <BreadcrumbController routes={routes} />
      <Paper className={classes.container}>
        <Route exact path="/orderlist" component={OrderList} />
        <Route path="/orderlist/:orderId" component={OrderIdHandler} />
        <Switch>
          <AllowedRoute
            exact
            path="/orderlist/add"
            name="Add Order"
            type="Add"
            component={OrderAdd}
          />
          <Route exact path="/orderlist/:orderId" component={Order} />
        </Switch>
        <Switch>
          <Route
            exact
            path="/orderlist/:orderId/lines/:lineListId"
            component={OrderLineList}
          />
        </Switch>
      </Paper>
    </div>
  );
};

export const OrderController: React.FC = () => {
  return (
    <OrderContextProvider>
      <OrderLineContextProvider>
        <OrderControllerComponent />
      </OrderLineContextProvider>
    </OrderContextProvider>
  );
};
