import React from 'react';
import {useParams} from 'react-router-dom';
import {useContractContext} from './contractContext';

interface IParams {
  contractId?: string;
}

export const ContractIdHandler: React.FC = () => {
  const {setContractId} = useContractContext();

  const {contractId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedContractId = contractId ? parseInt(contractId) : undefined;
    setContractId(parsedContractId);

    return () => {
      setContractId(undefined);
    };
  }, [contractId, setContractId]);

  return null;
};
