import React from 'react';
import {ContactContext, ContactContextProps} from './ContactContext';
import {useParams} from 'react-router-dom';

interface IParams {
  contactId?: string;
}

export const ContactIdHandler: React.FC = () => {
  const contactContext = React.useContext<ContactContextProps>(ContactContext);

  const contactSetId = contactContext.setContactId;

  const {contactId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = contactId ? parseInt(contactId) : undefined;
    contactSetId(parsedId);

    return () => {
      contactSetId(undefined);
    };
  }, [contactId, contactSetId]);

  return null;
};
