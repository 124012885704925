import React from 'react';
import {Listing, Trip} from '@onroadvantage/onroadvantage-api';
import {useTripContext} from '../../tripContext';
import {masterTripListVehicleApi} from '../../../../api';
import {useAppNotifications} from '../../../../contexts';
import {RoleService} from '../../../../service';
import {TAutocompleteOnChange} from '../../../autocomplete';
import {TripSummaryEditAutocomplete} from './TripSummaryEditAutocomplete';
import {TripSummaryEditDialog} from './TripSummaryEditDialog';
export const TripSummaryEditVehicle: React.FC = () => {
  const notify = useAppNotifications();
  const {masterTrip, recalculateTrip, setUpdatingTrip} = useTripContext();
  /** Destructure credit, need to add || ({} as MasterTripCreditResponse) for typescript, since credit is nullable. */
  const {vehicle, id: tripId} = masterTrip?.trip || ({} as Trip);
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [selectedVehicle, setSelectedVehicle] = React.useState<
    Listing | undefined
  >({
    label: `${vehicle?.registrationNumber} - ${vehicle?.description}`,
    value: vehicle?.id,
  });

  const handleChange = React.useCallback<TAutocompleteOnChange>(
    (_event, value) => {
      setSelectedVehicle(value as Listing);
    },
    []
  );

  const handleSubmit = React.useCallback(async () => {
    setSubmitting(true);
    setUpdatingTrip(true);
    try {
      if (tripId) {
        const response = await masterTripListVehicleApi.apiTripVehiclePost({
          body: {
            tripId,
            vehicleId: selectedVehicle?.value ?? null,
          },
        });
        if (response) {
          notify('success', 'Vehicle updated');
          await recalculateTrip();
        }
      }
    } catch (e) {
      notify('error', 'Failed to update trip number');
    } finally {
      setUpdatingTrip(false);
    }
  }, [setUpdatingTrip, tripId, selectedVehicle, notify, recalculateTrip]);

  React.useEffect(() => {
    setSelectedVehicle({
      label: `${vehicle?.registrationNumber} - ${vehicle?.description}`,
      value: vehicle?.id,
    });
    return () => setSelectedVehicle(undefined);
  }, [vehicle]);

  return (
    <TripSummaryEditDialog
      hasPermission={RoleService.hasPermission('Trip Vehicle Assign', 'Assign')}
      loading={submitting}
      title="Vehicle"
      onAccept={handleSubmit}
    >
      <TripSummaryEditAutocomplete
        model="Vehicle"
        onChange={handleChange}
        value={selectedVehicle}
        loading={submitting}
      />
    </TripSummaryEditDialog>
  );
};
