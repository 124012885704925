import React from 'react';
import clsx from 'clsx';
import {AppBar, Toolbar, Typography} from '@mui/material';
import {useNavbarStyles} from './NavBar.style';
import {useLocation} from 'react-router-dom';
import {WindowInfoContext} from '../../../contexts';
import {NavNotificationButton} from './NavNotificationButton';
import {RoleService} from '../../../service';
import {NavDateRangePicker} from './NavDateRangePicker';
import {NavMenuButton} from './NavMenuButton';

export interface NavBarProps {
  // left drawer
  leftDrawerOpen: boolean;
  onLeftDrawerToggle?: () => void;
  // right drawer
  rightDrawerOpen: boolean;
  onRightDrawerToggle?: () => void;
}

export const NavBar: React.FC<NavBarProps> = ({
  leftDrawerOpen,
  onLeftDrawerToggle,
  onRightDrawerToggle,
  rightDrawerOpen,
}) => {
  const classes = useNavbarStyles();
  const location = useLocation();
  const {isDesktop, isMobile} = React.useContext(WindowInfoContext);
  const [eventTabAllowed] = React.useState<boolean>(
    RoleService.hasPermission('EventTab', 'View')
  );
  const [title, setTitle] = React.useState<string>('Vantage');

  React.useEffect(() => {
    const path = location.pathname.split('/')[1];

    /**
     * Specify the path with a corresponding label
     *
     * The browser's tab/window will display the label field and be pre-fixed with "Vantage | "
     * The app bar will display the label field and get post-fixed with " View"
     */
    const paths = [
      {value: '', label: 'Home'},
      {value: 'dashboard', label: 'Home'},
      {value: 'broadcast', label: 'Broadcast'},
      {value: 'broadcastlist', label: 'Broadcasts'},
      {value: 'contactgrouplist', label: 'Audience Groups'},
      {value: 'contactlist', label: 'Audience'},
      {value: 'contractgrouplist', label: 'Contract Groups'},
      {value: 'contractlist', label: 'Contracts'},
      {value: 'criticaleventtypelist', label: 'Critical Event Types'},
      {value: 'customerlist', label: 'Customers'},
      {value: 'dashboardlist', label: 'Dashboards'},
      {value: 'documenttemplatelist', label: 'Document Templates'},
      {value: 'driverlist', label: 'Drivers'},
      {value: 'drivergrouplist', label: 'Driver Groups'},
      {value: 'driverdocumentlist', label: 'Driver Documents'},
      {value: 'gisanalysis', label: 'GIS Analysis'},
      {value: 'driverrankincentivelist', label: 'Incentives'},
      {value: 'masterroutelist', label: 'Master Routes'},
      {value: 'nodetypelist', label: 'Node Types'},
      {value: 'notificationconfiglist', label: 'Notification Configs'},
      {value: 'orderlist', label: 'Orders'},
      {value: 'permissionlist', label: 'Permissions'},
      {value: 'planningboard', label: 'Planning Board'},
      {value: 'planningskilllist', label: 'Planning Skills'},
      {value: 'planningsolutionlist', label: 'Optimiser'},
      {value: 'productlist', label: 'Products'},
      {value: 'productgrouplist', label: 'Product Groups'},
      {value: 'profilelist', label: 'Profiles'},
      {value: 'recipient', label: 'Recipient'},
      {value: 'master_trip_recipient', label: 'Recipient'},
      {value: 'reportlist', label: 'Reports'},
      {value: 'rolelist', label: 'Roles'},
      {value: 'sitelist', label: 'Sites'},
      {value: 'support', label: 'Support'},
      {value: 'tasklist', label: 'Tasks'},
      {value: 'tasktemplatelist', label: 'Task Templates'},
      {value: 'telematicsconfiglist', label: 'Telematics Configs'},
      {value: 'experimental', label: 'Template Editor Experimental'},
      {value: 'trainingcourselist', label: 'Training Courses'},
      {value: 'transporterlist', label: 'Transporters'},
      {value: 'trip', label: 'Trip'},
      {value: 'tripdebrieflist', label: 'Trip Debriefs'},
      {value: 'triplist', label: 'Trips'},
      {value: 'upload', label: 'Upload Data'},
      {value: 'userlist', label: 'Users'},
      {value: 'usersettings', label: 'User Settings'},
      {value: 'usertrainingcourselist', label: 'User Training Courses'},
      {value: 'vehicle', label: 'Vehicle'},
      {value: 'vehiclelist', label: 'Vehicles'},
      {value: 'vehiclegrouplist', label: 'Vehicle Groups'},
      {
        value: 'vehicle_booking_availability',
        label: 'Vehicle Booking Availability',
      },
      {value: 'vehicletypelist', label: 'Vehicle Types'},
      {value: 'workflowlist', label: 'Workflows'},
      {value: 'workflowconfiglist', label: 'Workflow Configs'},
      {value: 'speedtablelist', label: 'Speed Tables'},
      {value: 'tripdebriefconfiglist', label: 'Trip Debrief Configs'},
      {value: 'developerapidocs', label: 'Developer API Docs'},
      {value: 'clientapidocs', label: 'Client API Docs'},
      {value: 'integrationapidocs', label: 'Integration API Docs'},
      {value: 'trackingapidocs', label: 'Tracking API Docs'},
      {value: 'eventtypes', label: 'Event Types'},
      {value: 'event', label: 'Event'},
      {
        value: 'contracteventtypeconfigs',
        label: 'Contract Event Type Configs',
      },
      {value: 'functionaldocs', label: 'Functional Docs'},
      {
        value: 'bulkClosing',
        label: 'Bulk Closing',
      },
    ];

    const tempTitle = paths.find((p) => p.value === path);
    setTitle(tempTitle?.label ?? 'Default');
  }, [location.pathname, setTitle]);

  React.useEffect(() => {
    document.title = `Vantage | ${title === 'Default' ? 'Run Better' : title}`;
  }, [title]);

  return (
    <AppBar
      className={clsx(classes.root, {
        [classes.shiftLeft]: isDesktop && leftDrawerOpen,
        [classes.shiftRight]: isDesktop && rightDrawerOpen,
        [classes.shiftBoth]: isDesktop && leftDrawerOpen && rightDrawerOpen,
      })}
      position="fixed"
    >
      <Toolbar variant={isDesktop ? 'regular' : 'dense'}>
        {!isDesktop && <NavMenuButton onClick={onLeftDrawerToggle} />}
        <Typography className={classes.title} variant="h6">
          {`${title ?? 'Vantage'} View`}
        </Typography>
        {!isMobile && title !== 'Dashboard' && <NavDateRangePicker />}
        {eventTabAllowed && (
          <NavNotificationButton onClick={onRightDrawerToggle} />
        )}
      </Toolbar>
    </AppBar>
  );
};
