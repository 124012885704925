import {makeStyles, createStyles} from '@mui/styles';

const TableFixedColumnCellStyles = () =>
  createStyles({
    root: {
      paddingBottom: 0,
      paddingTop: 0,
      paddingLeft: 10,
      backgroundColor: 'transparent !important',
    },
  });

export const useTableFixedColumnCellStyles = makeStyles(
  TableFixedColumnCellStyles,
  {name: 'TableFixedColumnCellStyles'}
);
