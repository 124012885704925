import React from 'react';
import {EventsPane} from '.';
import {EventsPaneContextProvider} from './EventsPaneContext';
import {useEventsPaneStyles} from './EventsPane.style';

export const EventsPaneController: React.FC = () => {
  const classes = useEventsPaneStyles();
  return (
    <div className={classes.root}>
      <EventsPaneContextProvider>
        <EventsPane />
      </EventsPaneContextProvider>
    </div>
  );
};
