import React from 'react';
import {useHistory} from 'react-router-dom';
import {
  ApiUserGetRequest,
  User,
  UserListingResponse,
  ContractUser,
  CustomerUser,
  RoleAuth,
  Listing,
  NewUser,
} from '@onroadvantage/onroadvantage-api';
import {FormikHelpers, FormikProps} from 'formik/dist/types';
import {userApi} from '../../api';
import {TemplateTableContextProps, TOnInlineAdd} from '../../factory/template';
import {useTemplateTable, TLoadList} from '../../factory/template';
import {useAppNotifications} from '../../contexts';
import {IUserForm} from './UserForm';

export interface UserContextProps
  extends TemplateTableContextProps<User, UserListingResponse> {
  onDetailsFormSubmit: (values: any, formikHelpers: FormikHelpers<any>) => void;
  assignContracts: TOnInlineAdd;
  unassignContract: (row: ContractUser) => void;
  assignCustomers: TOnInlineAdd;
  unassignCustomer: (row: CustomerUser) => void;
  assignRoles: TOnInlineAdd;
  unassignRole: (row: RoleAuth) => void;
  setUserId: (value: number | undefined) => void;
  submitting: boolean;
  loadingSingleItem: boolean;
  user?: User;
  userId?: number;
  detailsRef?: React.Ref<FormikProps<any>>;
}

export const UserContext = React.createContext<UserContextProps>({
  // Template Table Defaults
  loading: false,
  list: [],
  currentPage: 1,
  // User
  loadList: async () => {},
  onDetailsFormSubmit: () => null,
  assignContracts: () => null,
  unassignContract: () => null,
  assignCustomers: () => null,
  unassignCustomer: () => null,
  assignRoles: () => null,
  unassignRole: () => null,
  setUserId: () => null,
  submitting: false,
  loadingSingleItem: false,
});

interface UserContextProviderProps {
  userId?: number;
}

export const UserContextProvider: React.FC<UserContextProviderProps> = ({
  children,
}) => {
  const history = useHistory();
  const notify = useAppNotifications();
  // Template Table
  const [
    {
      currentPage,
      pageSize,
      pageTotal,
      sorting,
      hasPermission,
      itemTotal,
      list,
      loading,
      loadingSingleItem,
      filters,
    },
    {
      // Getters
      getRequestObj,
      getResponse,
      getDownloads,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
      setLoadingSingleItem,
    },
  ] = useTemplateTable<User, ApiUserGetRequest>({
    editPermission: 'Edit User',
    addPermission: 'Add User',
    deletePermission: 'Delete User',
    downloadPermission: 'User ListDownload',
    viewPermission: 'User List',
  });

  const loadList = React.useCallback<TLoadList<UserListingResponse>>(
    async (options) => {
      setLoading(true);
      try {
        return getResponse(
          await userApi.apiUserGet(
            getRequestObj(
              ['email', 'roleName', 'contractCode', 'active', 'contractName'],
              options
            )
          ),
          options
        );
      } catch (e) {
        notify('error', e.message ?? 'Failed to load User List');
      } finally {
        setLoading(false);
      }
    },
    [getRequestObj, getResponse, notify, setLoading]
  );

  const handleDelete = React.useCallback(
    async (row: User) => {
      setLoading(true);
      try {
        if (row.id) {
          await userApi.apiUserUserIdDelete({
            userId: row.id,
          });
          await loadList();
          notify('success', 'Deleted User');
        }
      } catch (e) {
        notify('error', e.message);
      } finally {
        setLoading(false);
      }
    },
    [loadList, notify, setLoading]
  );
  const handleDownload = React.useCallback(
    () =>
      getDownloads('users', loadList, [
        {name: 'Email', path: 'email'},
        {name: 'Active', path: 'active'},
        {name: 'Created At', path: 'createdAt'},
        {name: 'Role Name', path: 'roleNames'},
        {name: 'Contract Code', path: 'contractCodes'},
      ]),
    [getDownloads, loadList]
  );
  const handleAdd = React.useCallback(
    () => history.push('/userlist/add'),
    [history]
  );
  const handleNavigate = React.useCallback(
    (row) => history.push(`/userlist/${row.id}`),
    [history]
  );
  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  // Forms
  const [user, setUser] = React.useState<User | undefined>();
  const [userId, setUserId] = React.useState<number>();
  const [submitting, setSubmitting] = React.useState(false);

  const detailsRef = React.useRef<FormikProps<any>>(null);

  const loadUser = React.useCallback(async () => {
    setLoadingSingleItem(true);
    try {
      if (userId) {
        const response = await userApi.apiUserUserIdGet({
          userId,
        });
        setUser(response);
      } else {
        setUser(undefined);
      }
    } catch (e) {
      notify('error', e.message ?? 'Failed to load User');
    } finally {
      setLoadingSingleItem(false);
    }
  }, [notify, setLoadingSingleItem, userId]);

  const handleDetailsFormSubmit = React.useCallback(
    async (values: IUserForm, formikHelpers: FormikHelpers<any>) => {
      setSubmitting(true);
      try {
        if (userId) {
          await userApi.apiUserUserIdUpdatePatch({
            userId,
            body: values,
          });
        } else if (values.email && values.password) {
          await userApi.apiUserNewPost({
            body: values as NewUser,
          });
        }
        history.push('/userlist');
        notify('success', `${user?.id ? 'Updated' : 'Added'} User`);
      } catch (e) {
        notify(
          'error',
          e.message ?? `Failed to ${user?.id ? 'update' : 'add'} User`
        );
      } finally {
        formikHelpers.setSubmitting(false);
        setSubmitting(false);
      }
    },
    [history, notify, user?.id, userId]
  );

  const handleAssignContracts = React.useCallback<TOnInlineAdd>(
    async (changes) => {
      setSubmitting(true);
      try {
        if (userId) {
          for (const change of changes) {
            const contracts = change.name as Listing[];
            const contractIds = contracts
              .filter(({value}) => value)
              .map(({value}) => value as number);
            await userApi.apiUserUserIdContractAssignPost({
              userId,
              body: {contractIds},
            });
            await loadUser();
          }
          notify('success', 'Assigned contract(s) to user');
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to assign contract(s) to user');
      } finally {
        setSubmitting(false);
      }
    },
    [loadUser, notify, userId]
  );

  const handleUnassignContract = React.useCallback(
    async (row: ContractUser) => {
      setSubmitting(true);
      try {
        if (userId && row.id) {
          await userApi.apiUserUserIdContractUnassignPost({
            userId,
            body: {contractId: row.id},
          });
          await loadUser();
          notify('success', 'Unassigned contract from user');
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to unassign contract from user');
      } finally {
        setSubmitting(false);
      }
    },
    [loadUser, notify, userId]
  );

  const handleAssignCustomers = React.useCallback<TOnInlineAdd>(
    async (changes) => {
      setSubmitting(true);
      try {
        if (userId) {
          for (const change of changes) {
            const customers = change.name as Listing[];
            const customerIds = customers
              .filter(({value}) => value)
              .map(({value}) => value as number);
            await userApi.apiUserUserIdCustomerAssignPost({
              userId,
              body: {customerIds},
            });
            await loadUser();
          }
          notify('success', 'Assigned customer(s) to user');
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to assign customer(s) to user');
      } finally {
        setSubmitting(false);
      }
    },
    [loadUser, notify, userId]
  );

  const handleUnassignCustomer = React.useCallback(
    async (row: CustomerUser) => {
      setSubmitting(true);
      try {
        if (userId && row.id) {
          await userApi.apiUserUserIdCustomerUnassignPost({
            userId,
            body: {customerId: row.id},
          });
          await loadUser();
          notify('success', 'Unassigned customer from user');
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to unassign customer from user');
      } finally {
        setSubmitting(false);
      }
    },
    [loadUser, notify, userId]
  );

  const handleAssignRoles = React.useCallback<TOnInlineAdd>(
    async (changes) => {
      setSubmitting(true);
      try {
        if (userId) {
          for (const change of changes) {
            const roles = change.name as Listing[];
            const roleIds = roles
              .filter(({value}) => value)
              .map(({value}) => value as number);
            await userApi.apiUserUserIdRoleAssignPost({
              userId,
              body: {roleIds},
            });
            await loadUser();
          }
          notify('success', 'Assigned role(s) to user');
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to assign role(s) to user');
      } finally {
        setSubmitting(false);
      }
    },
    [loadUser, notify, userId]
  );

  const handleUnassignRole = React.useCallback(
    async (row: RoleAuth) => {
      setSubmitting(true);
      try {
        if (userId && row.id) {
          await userApi.apiUserUserIdRoleUnassignPost({
            userId,
            body: {roleId: row.id},
          });
          await loadUser();
          notify('success', 'Unassigned role from user');
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to unassign role from user');
      } finally {
        setSubmitting(false);
      }
    },
    [loadUser, notify, userId]
  );

  const value: UserContextProps = {
    // Template Table
    list,
    loadList,
    hasPermission,
    loading: loading || loadingSingleItem,
    loadingSingleItem,
    cleanupList,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onAdd: hasPermission.add ? handleAdd : undefined,
    onNavigate: handleNavigate,
    onDelete: hasPermission.delete ? handleDelete : undefined,
    onDownload: hasPermission.download ? handleDownload : undefined,
    onRefresh: handleRefresh,
    // Forms
    onDetailsFormSubmit: handleDetailsFormSubmit,
    assignContracts: handleAssignContracts,
    unassignContract: handleUnassignContract,
    assignCustomers: handleAssignCustomers,
    unassignCustomer: handleUnassignCustomer,
    assignRoles: handleAssignRoles,
    unassignRole: handleUnassignRole,
    setUserId,
    submitting,
    user,
    userId,
    detailsRef,
  };

  React.useEffect(() => {
    loadUser();
    return () => setUser(undefined);
  }, [loadUser]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
