import React from 'react';
import {TripDebriefConfigForm} from './TripDebriefConfigForm';
import {ITemplateTabControllerTab, TemplateTabs} from '../../factory/template';
import {useTripDebriefConfigContext} from './tripDebriefConfigContext';
import {TripDebriefConfigVisibleCards} from './TripDebriefConfigVisibleCards';
import {TripDebriefConfigRequiredFields} from './TripDebriefConfigRequiredFields';
import {TripDebriefConfigReasonCodes} from './tripDebriefConfigReasonCodes';
import {TripDebriefConfigEventTypesRequiringReasonCodes} from './tripDebriefConfigEventTypesRequiringReasonCodes';

export const TripDebriefConfig: React.FC = () => {
  const {masterTripDebriefConfigId, loadingTripDebriefConfig} =
    useTripDebriefConfigContext();

  const tabs = React.useMemo<ITemplateTabControllerTab[]>(
    () => [
      {title: 'Details', element: <TripDebriefConfigForm />},
      {title: 'Visible Cards', element: <TripDebriefConfigVisibleCards />},
      {title: 'Required Fields', element: <TripDebriefConfigRequiredFields />},
      {
        title: 'Event Types Requiring Reason Codes',
        element: <TripDebriefConfigEventTypesRequiringReasonCodes />,
      },
      {title: 'Reason Codes', element: <TripDebriefConfigReasonCodes />},
    ],
    []
  );

  const path = `/tripdebriefconfiglist/${masterTripDebriefConfigId}`;

  return (
    <TemplateTabs tabs={tabs} path={path} loading={loadingTripDebriefConfig} />
  );
};
