export const round = (
  number: number | string | undefined | null,
  // TODO
  _decimalPoints = 2
) => {
  if (number == null) {
    return null;
  }
  if (typeof number === 'string') {
    return Math.floor(parseFloat(number) * 100) / 100;
  }

  return Math.floor(number * 100) / 100;
};
