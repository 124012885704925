import {makeStyles, createStyles} from '@mui/styles';

const ApiDocsStyles = () =>
  createStyles({
    frame: {
      display: 'none',
      flex: 1,
      border: 0,
    },
    frameDidLoad: {
      display: 'flex',
    },
  });

export const useApiDocsStyles = makeStyles(ApiDocsStyles, {
  name: 'ApiDocsStyles',
});
