import React from 'react';
import {
  CardHeader,
  Avatar,
  IconButton,
  Card,
  Collapse,
  CardContent,
} from '@mui/material';
import {ExpandMore} from '@mui/icons-material';
import {useVehicleCardStyles} from './VehicleCard.style';
import classnames from 'classnames';

interface VehicleCardProps {
  title: string;
  avatar: React.ReactNode;
  subtitle?: string;
  label?: string;
  defaultExpanded?: boolean;
}

export const VehicleCard: React.FC<VehicleCardProps> = ({
  title,
  avatar,
  subtitle,
  label,
  defaultExpanded,
  children,
}) => {
  const classes = useVehicleCardStyles();
  const [isExpanded, setIsExpanded] = React.useState<boolean>(
    !!defaultExpanded
  );
  const toggleIsExpanded = React.useCallback(() => {
    setIsExpanded((prevIsExpanded) => !prevIsExpanded);
  }, [setIsExpanded]);

  return (
    <Card className={classes.root}>
      <CardHeader
        className={classes.header}
        title={title}
        subtitle={subtitle}
        avatar={<Avatar aria-label={title ?? label}>{avatar}</Avatar>}
        onClick={toggleIsExpanded}
        action={
          <IconButton
            className={classnames(classes.expand, {
              [classes.expandOpen]: isExpanded,
            })}
            aria-expanded={isExpanded}
            aria-label="Show more"
            size="large"
          >
            <ExpandMore />
          </IconButton>
        }
      />
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <CardContent>{children}</CardContent>
      </Collapse>
    </Card>
  );
};
