import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import React from 'react';
import {
  DateRangePicker,
  DateRangePickerShape,
  FocusedInputShape,
} from 'react-dates';
import {IconButton} from '@mui/material';
import CalendarIcon from '../../../img/icon/CalendarIcon.svg';
import {useAppNotifications, WindowInfoContext} from '../../../contexts';
import DateRangeContext from '../../../contexts/DateRangeContext';
import moment, {Moment} from 'moment';
import {DateTime} from 'luxon';

export const NavDateRangePicker: React.FC = () => {
  const notify = useAppNotifications();
  const {isDesktop} = React.useContext(WindowInfoContext);
  const {onDatesChange, dateRange} = React.useContext(DateRangeContext);
  const [focusedInput, setFocusedInput] =
    React.useState<FocusedInputShape | null>(null);

  const validateDate = React.useCallback(
    (startDate: Moment | null, endDate: Moment | null) => {
      if (focusedInput === 'startDate') {
        if (!startDate) {
          notify('error', 'Please select a valid start date');
        }
        return false;
      } else if (focusedInput === 'endDate') {
        if (!endDate) {
          notify('error', 'Please select a valid end date');
        }
        return false;
      }
      return true;
    },
    [focusedInput, notify]
  );

  const handleDatesChange: DateRangePickerShape['onDatesChange'] =
    React.useCallback(
      ({startDate, endDate}) => {
        validateDate(startDate, endDate);

        onDatesChange(
          startDate
            ? startDate
                .set({
                  hour: 0,
                  minute: 0,
                  second: 0,
                })
                .toISOString()
            : '',
          endDate
            ? endDate.set({hour: 23, minute: 59, second: 59}).toISOString()
            : ''
        );
      },
      [onDatesChange, validateDate]
    );

  const handleToToday = React.useCallback(() => {
    onDatesChange(
      DateTime.now().startOf('day').toString(),
      DateTime.now().endOf('day').toString()
    );
  }, [onDatesChange]);

  return (
    <>
      <IconButton onClick={handleToToday} sx={{mr: 1}}>
        <img
          src={CalendarIcon}
          alt="Calendar"
          style={{
            height: isDesktop ? 40 : 28,
            width: isDesktop ? 40 : 28,
            cursor: 'pointer',
          }}
        />
      </IconButton>
      <DateRangePicker
        startDateId="startDate"
        endDateId="endDate"
        isOutsideRange={() => false}
        onDatesChange={handleDatesChange}
        onFocusChange={setFocusedInput}
        focusedInput={focusedInput}
        startDate={moment(dateRange.startDate) ?? null}
        endDate={moment(dateRange.endDate) ?? null}
        minimumNights={0}
        displayFormat="DD-MM-YYYY"
      />
    </>
  );
};
