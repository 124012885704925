import ConfigService from './ConfigService/ConfigService';

import {encodeUrl, flatten} from './Util';
import ResponseService from './ResponseService';
import {authStore} from '../store';

const serverUrl = ConfigService.serverUri;
const queryUrl = `${serverUrl}/api/vehicle`;

const VehicleService = {
  query: async (params) => {
    Object.keys(params).forEach(
      // eslint-disable-next-line no-param-reassign
      (key) => params[key] == null && delete params[key]
    );
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(encodeUrl(queryUrl, params), {
      method: 'GET',
      mode: 'cors',
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  normalize: async (response) => {
    return flatten(await response.data);
  },
  getById: async (vehicleId) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(`${queryUrl}/${vehicleId}`, {
      method: 'GET',
      mode: 'cors',
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  getTrips: async ({vehicleId, startDate, endDate}) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const mainUrl = `${queryUrl}/${vehicleId}/nearby_trips/time`;
    const response = await fetch(encodeUrl(mainUrl, {startDate, endDate}), {
      method: 'GET',
      mode: 'cors',
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  getByGeoJSON: async (geoJson) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const mainUrl = `${queryUrl}/geojson`;

    return fetch(mainUrl, {
      method: 'POST',
      mode: 'cors',
      body: geoJson,
      headers,
    });
  },
};

export default VehicleService;
