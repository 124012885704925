import {makeStyles, createStyles} from '@mui/styles';
import {Theme} from '@mui/material';

const CompartmentTruckCompartmentStyles = (theme: Theme) =>
  createStyles({
    compartment: {
      cursor: 'pointer',
      overflow: 'hidden',
      position: 'relative',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      height: '90%',
      backgroundColor: '#bebebe',
      margin: theme.spacing(0.5),
      padding: theme.spacing(0.5),
      borderRadius: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'flex-start',
      textAlign: 'center',
    },
    compartmentActive: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      cursor: 'default',
    },
    getCompartmentWrapper: {
      position: 'relative',
      overflow: 'hidden',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      height: '90%',
      backgroundColor: '#bebebe',
      margin: theme.spacing(0.5),
      borderRadius: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'flex-start',
      textAlign: 'center',
    },
    compartmentDefaultPointer: {
      cursor: 'default',
    },
    compartmentAdd: {
      backgroundColor: 'rgba(190,190,190,0.48)',
      justifyContent: 'center',
      alignItems: 'center',
    },
    tooltip: {
      background: '#eee',
      padding: theme.spacing(0.6),
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
    tooltipDisabled: {display: 'none'},
    tooltipArrow: {color: '#eee'},
    tooltipContent: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
    },
  });

export const useCompartmentTruckCompartmentStyles = makeStyles(
  CompartmentTruckCompartmentStyles,
  {name: 'CompartmentTruckCompartmentStyles'}
);
