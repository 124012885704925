import React from 'react';
import _ from 'lodash';
import {
  ITemplateControllerRoute,
  TemplateController,
} from '../../factory/template';
import {
  VehicleGroupContext,
  VehicleGroupContextProvider,
} from './VehicleGroupContext';
import {VehicleGroupList} from './VehicleGroupList';
import {VehicleGroupIdHandler} from './VehicleGroupIdHandler';
import {VehicleGroupForm} from './VehicleGroupForm';
import {VehicleGroup} from './VehicleGroup';
import {VehicleGroupVehiclesContextProvider} from './vehicleGroupVehicles';

export const VehicleGroupController: React.FC = () => {
  return (
    <VehicleGroupContextProvider>
      <VehicleGroupVehiclesContextProvider>
        <VehicleGroupControllerComponent />
      </VehicleGroupVehiclesContextProvider>
    </VehicleGroupContextProvider>
  );
};

const VehicleGroupControllerComponent: React.FC = () => {
  const {vehicleGroup, loading} = React.useContext(VehicleGroupContext);
  const routes: ITemplateControllerRoute[] = React.useMemo(
    () => [
      {title: 'Vehicle Group List', component: VehicleGroupList},
      {
        path: '/add',
        title: 'New Vehicle Group',
        name: 'Add VehicleGroup',
        type: 'Add',
        component: () => <VehicleGroupForm isAdd />,
      },
      {
        path: '/:vehicleGroupId',
        title: (params) =>
          loading
            ? 'Vehicle Group Loading...'
            : vehicleGroup?.name ??
              `Vehicle Group ${_.get(params, 'vehicleGroupId', 'Single')}`,
        component: VehicleGroup,
        IdHandler: VehicleGroupIdHandler,
      },
    ],
    [loading, vehicleGroup?.name]
  );
  return <TemplateController rootPath="/vehiclegrouplist" routes={routes} />;
};
