import React from 'react';
import {OrderRecipient} from './OrderRecipient';
import {useOrderRecipientControllerStyles} from './OrderRecipientController.style';
import {authStore} from '../../../store';
import {
  OrderRecipientContext,
  RecipientContextProvider,
} from './OrderRecipientContext';
import {useParams} from 'react-router-dom';
import {Loader} from '../../loader';

const RecipientControllerComponent: React.FC = () => {
  const auth = authStore.getAuth;
  const classes = useOrderRecipientControllerStyles();
  const {loading} = React.useContext(OrderRecipientContext);

  if (loading) return <Loader />;

  if (auth && (auth.authenticated || auth.oauthAuthenticated))
    return <OrderRecipient />;

  return (
    <div className={classes.root}>
      <header className={classes.header} data-cy="RecipientPublicHeader" />
      <main className={classes.mainContent}>
        <OrderRecipient />
      </main>
    </div>
  );
};

interface IRecipientParams {
  uuid: string;
}

export const OrderRecipientController: React.FC = () => {
  const {uuid} = useParams<IRecipientParams>();
  return (
    <RecipientContextProvider uuid={uuid}>
      <RecipientControllerComponent />
    </RecipientContextProvider>
  );
};
