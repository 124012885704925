import React from 'react';
import {ContractOperationalEventTypePrompt} from '@onroadvantage/onroadvantage-api';
import {contractOperationalEventTypeConfigApi} from '../../../../api';
import {TOnFormikSubmit} from '../../../../factory/template';
import {ContractOperationalEventTypeConfigPromptFormValues} from './ContractOperationalEventTypePromptForm';
import {useAppNotifications} from '../../../../contexts';
import {useHistory} from 'react-router-dom';
import {useContractOperationalEventTypeConfigContext} from '../ContractOperationalEventTypeConfigContext';

export interface ContractOperationalEventTypePromptContextProps {
  loading: boolean;
  submitting: boolean;
  contractOperationalEventTypePromptId: number | undefined;
  setContractOperationalEventTypePromptId: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  contractOperationalEventTypePrompt:
    | ContractOperationalEventTypePrompt
    | undefined;
  onDetailsSubmit: TOnFormikSubmit<ContractOperationalEventTypeConfigPromptFormValues>;
}

export const ContractOperationalEventTypePromptContext =
  React.createContext<ContractOperationalEventTypePromptContextProps | null>(
    null
  );

export const useContractOperationalEventTypePromptContext = () => {
  const contractOperationalEventTypeContext = React.useContext(
    ContractOperationalEventTypePromptContext
  );
  if (contractOperationalEventTypeContext == null) {
    throw new Error(
      'useContractOperationalEventTypePromptContext has to be used within <ContractOperationalEventTypePromptContext.Provider>'
    );
  }

  return contractOperationalEventTypeContext;
};

export const ContractOperationalEventTypePromptContextProvider: React.FC = ({
  children,
}) => {
  const notify = useAppNotifications();
  const history = useHistory();
  const {
    contractOperationalEventTypeConfigId,
    setContractOperationalEventTypeConfig,
  } = useContractOperationalEventTypeConfigContext();
  const [
    contractOperationalEventTypePrompt,
    setContractOperationalEventTypePrompt,
  ] = React.useState<ContractOperationalEventTypePrompt | undefined>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [
    contractOperationalEventTypePromptId,
    setContractOperationalEventTypePromptId,
  ] = React.useState<number | undefined>();

  const handleLoadContractOperationalEventTypePrompt =
    React.useCallback(async () => {
      setLoading(true);
      try {
        if (
          contractOperationalEventTypePromptId != null &&
          contractOperationalEventTypeConfigId != null
        ) {
          const response =
            await contractOperationalEventTypeConfigApi.apiContractOperationalEventTypeConfigContractOperationalEventTypeConfigIdPromptPromptIdGet(
              {
                contractOperationalEventTypeConfigId,
                promptId: contractOperationalEventTypePromptId,
              }
            );
          if (response != null) {
            setContractOperationalEventTypePrompt(response);
          }
        }
      } catch (e) {
        notify(
          'error',
          'Failed to load contract operational event type prompt'
        );
      } finally {
        setLoading(false);
      }
    }, [
      contractOperationalEventTypeConfigId,
      contractOperationalEventTypePromptId,
      notify,
    ]);

  const handleOnDetailsSubmit = React.useCallback<
    TOnFormikSubmit<ContractOperationalEventTypeConfigPromptFormValues>
  >(
    async (values, formikHelpers) => {
      setSubmitting(true);
      formikHelpers.setSubmitting(true);
      try {
        if (contractOperationalEventTypeConfigId == null) {
          return null;
        }
        if (contractOperationalEventTypePromptId != null) {
          const response =
            await contractOperationalEventTypeConfigApi.apiContractOperationalEventTypeConfigContractOperationalEventTypeConfigIdPromptPromptIdPatch(
              {
                contractOperationalEventTypeConfigId,
                promptId: contractOperationalEventTypePromptId,
                body: values,
              }
            );
          setContractOperationalEventTypeConfig((prevState) =>
            prevState
              ? {
                  ...prevState,
                  prompts: prevState.prompts?.reduce(
                    (acc: ContractOperationalEventTypePrompt[], curr) => {
                      if (curr.id === response.id) {
                        return [...acc, response];
                      }
                      return [...acc, curr];
                    },
                    []
                  ),
                }
              : undefined
          );
          notify('success', 'Updated contract operational event type prompt');
          setContractOperationalEventTypePrompt(response);
        } else {
          if (values.prompt == null) {
            notify('warning', 'No prompt specified');
            return;
          }
          if (values.type == null) {
            notify('warning', 'No type specified');
            return;
          }

          const response =
            await contractOperationalEventTypeConfigApi.apiContractOperationalEventTypeConfigContractOperationalEventTypeConfigIdPromptPost(
              {
                contractOperationalEventTypeConfigId,
                body: {...values, content: values.content ?? ''},
              }
            );
          if (response) {
            setContractOperationalEventTypeConfig(response);
          }
          notify('success', 'Created contract operational event type prompt');
        }
        history.push(
          `/contracteventtypeconfigs/operational/${contractOperationalEventTypeConfigId}?tab=Prompt`
        );
      } catch (e) {
        notify(
          'error',
          'Failed to update contract operational event type config'
        );
      } finally {
        setSubmitting(false);
        formikHelpers.setSubmitting(false);
      }
    },
    [
      contractOperationalEventTypeConfigId,
      contractOperationalEventTypePromptId,
      history,
      notify,
      setContractOperationalEventTypeConfig,
    ]
  );

  React.useEffect(() => {
    handleLoadContractOperationalEventTypePrompt();
    return () => {
      setContractOperationalEventTypePrompt(undefined);
    };
  }, [
    handleLoadContractOperationalEventTypePrompt,
    setContractOperationalEventTypePrompt,
  ]);

  return (
    <ContractOperationalEventTypePromptContext.Provider
      value={{
        loading,
        submitting,
        contractOperationalEventTypePrompt,
        contractOperationalEventTypePromptId,
        setContractOperationalEventTypePromptId,
        onDetailsSubmit: handleOnDetailsSubmit,
      }}
    >
      {children}
    </ContractOperationalEventTypePromptContext.Provider>
  );
};
