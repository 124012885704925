import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from '@mui/material/styles';

const FormikUploadStyles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    fileName: {marginTop: theme.spacing(1)},
    loader: {marginLeft: theme.spacing(1)},
    input: {display: 'none'},
    image: {
      width: '5rem',
      height: 'auto',
      objectFit: 'cover',
      objectPosition: 'center',
      marginLeft: theme.spacing(1),
    },
  });

export const useFormikUploadStyles = makeStyles(FormikUploadStyles, {
  name: 'FormikUploadStyles',
});
