import {DateTime} from 'luxon';

const fromServerTimeToLocalTimeOnly = (
  date: string | undefined | null
): string | undefined => {
  if (date) {
    const separator = ':';

    const values = date.split(separator);
    const hour = parseInt(values[0]);
    const minute = parseInt(values[1]);

    const base = DateTime.utc().startOf('day').set({hour, minute}).toLocal();

    return `${base.hour}:${base.minute}:${base.second}`;
  }

  return undefined;
};

const fromServerTimeToLocalTimeDate = (
  date: string | undefined | null
): Date | undefined => {
  if (date) {
    const separator = ':';

    const values = date.split(separator);
    const hour = parseInt(values[0]);
    const minute = parseInt(values[1]);

    const base = DateTime.utc().startOf('day');

    return base.set({hour, minute}).toLocal().toJSDate();
  }

  return undefined;
};

const fromLocalDateToServerTimeOnly = (date: Date | string | undefined) => {
  if (date) {
    const dateTime =
      typeof date === 'string'
        ? DateTime.fromISO(date)
        : DateTime.fromJSDate(date);

    const dateTimeUTC = dateTime.toUTC();

    return `${dateTimeUTC.hour}:${dateTimeUTC.minute}:${dateTimeUTC.second}`;
  }

  return undefined;
};

const toTimeOnly = (date: Date | string | undefined) => {
  if (date) {
    const dateTime =
      typeof date === 'string'
        ? DateTime.fromISO(date)
        : DateTime.fromJSDate(date);

    return `${dateTime.hour}:${dateTime.minute}:${dateTime.second}`;
  }

  return undefined;
};

const dateFormats = {ShortDateTime: 'yyyy LLL dd HH:mm'} as const;

export type TFormatDate = (
  date: Date | undefined,
  format: keyof typeof dateFormats | string
) => string | undefined;

const format: TFormatDate = (date, format) => {
  if (date) {
    const dateTime = DateTime.fromJSDate(date);
    return dateTime.toFormat(
      format in dateFormats
        ? dateFormats[format as keyof typeof dateFormats]
        : format
    );
  }

  return undefined;
};

const fromNow = (date: Date | undefined | null) => {
  if (date) {
    const duration = DateTime.fromJSDate(date)
      .diffNow()
      .shiftTo('months', 'days', 'hours', 'minutes');

    if (duration.months === -1) {
      return 'a month ago';
    }

    if (duration.months < -1) {
      return `${Math.floor(duration.months * -1)} months ago`;
    }

    if (duration.days < -2) {
      return `${Math.floor(duration.days * -1)} days ago`;
    }

    if (duration.hours === -1) {
      return 'an hour ago';
    }

    if (duration.hours < -1) {
      return `${Math.floor(duration.hours * -1)} hours ago`;
    }

    if (duration.minutes === -1) {
      return 'a minute ago';
    }

    if (duration.minutes < -1) {
      return `${Math.floor(duration.minutes * -1)} minutes ago`;
    }

    return undefined;
  }

  return undefined;
};

const toLocalDateTime = (date: Date | null | undefined): Date | undefined => {
  /**
   * Note: This is only to be used when the backend doesn't correctly save the datetime in utc format.
   * Because what happens is the frontend sends the utc datetime in the payload and when the backend resends that same
   * datetime value, the frontend doesn't know that it is in utc. So it will convert (the actual utc value) to utc again.
   */
  if (date) {
    const dateTime = DateTime.fromJSDate(date);
    const utcOffset = DateTime.fromJSDate(date).offset / 60;
    const localDateTime = dateTime.plus({hour: utcOffset});
    return localDateTime.toJSDate();
  }

  return undefined;
};

export const LuxonService = {
  toTimeOnly,
  fromServerTimeToLocalTimeOnly,
  fromServerTimeToLocalTimeDate,
  toLocalDateTime,
  fromLocalDateToServerTimeOnly,
  format,
  fromNow,
};
