import React from 'react';
import {ListItem, ListItemText} from '@mui/material';
import {usePlanningBoardTripOrdersStyles} from '../PlanningBoardTripOrders.style';

interface PlanningBoardTripOrderDetailsItemProps {
  primary: string;
  secondary: React.ReactNode;
}

export const PlanningBoardTripOrderDetailsItem: React.FC<
  PlanningBoardTripOrderDetailsItemProps
> = ({primary, secondary}) => {
  const classes = usePlanningBoardTripOrdersStyles();
  return (
    <ListItem className={classes.listItem} disableGutters>
      <ListItemText<'span', 'div'>
        className={classes.listItemText}
        primary={primary}
        secondary={secondary ?? '-'}
      />
    </ListItem>
  );
};
