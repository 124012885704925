import '../planningBoardPlan/planningBoardGantt/planningBoardGantt.css';
import React from 'react';
import {DataSet, IdType, TimelineOptionsComparisonFunction} from 'vis';
import {
  NEW_BOOKING_TRIP_ID,
  PREVIEW_TRIP_ID,
  usePlanningBoardContext,
  usePlanningBoardGanttContext,
} from '../planningBoardContext';
import {getMasterTripsWithSameVehicle} from '../helpers';
import {VisTimeline} from '../../timeline/VisTimeline';
import {getVehicle24HourGanttData} from '../helpers/getVehicle24HourGanttData';
import {
  IPlanningBoardGanttItem,
  usePlanningBoardGanttStyles,
} from '../planningBoardPlan/planningBoardGantt';
import {Listing} from '@onroadvantage/onroadvantage-api';

export const PlanningBoardVehicleViewTimeline: React.FC = () => {
  const classes = usePlanningBoardGanttStyles();
  const {
    bookings,
    masterMasterTrips,
    bookingMasterTrips,
    planningMasterTrips,
    startDate,
    endDate,
    getVehicle,
  } = usePlanningBoardContext();
  const {
    ganttGroups,
    searchedTrips,
    sortVehiclesDirection,
    onSortByVehiclesAsc,
    onSortByVehiclesDesc,
    onClearSearchedItems,
    onClearSelectedGanttItem,
    setSelectedGanttItem,
    setSelectedVehicleCapacityDimensionOption,
    setVehicleCapacityDimensionOptions,
  } = usePlanningBoardGanttContext();

  const ganttItems = React.useMemo(
    () =>
      new DataSet(
        getVehicle24HourGanttData({
          bookings,
          bookingMasterTrips,
          masterMasterTrips,
          planningMasterTrips,
          classes,
        })
      ),
    [
      bookings,
      bookingMasterTrips,
      classes,
      masterMasterTrips,
      planningMasterTrips,
    ]
  );

  const handleSearchByTripIds =
    React.useCallback<TimelineOptionsComparisonFunction>(
      (a, b) => {
        // Get the optimal trips in the current group (vehicle) for a and b
        const tripsInGroupA = getMasterTripsWithSameVehicle(
          masterMasterTrips
            .concat(bookingMasterTrips ?? [])
            .concat(planningMasterTrips ?? []),
          a?.id
        );
        const tripsInGroupB = getMasterTripsWithSameVehicle(
          masterMasterTrips
            .concat(bookingMasterTrips ?? [])
            .concat(planningMasterTrips ?? []),
          b?.id
        );

        let groupAHasSearchedTrip = false;
        let groupBHasSearchedTrip = false;

        tripsInGroupA.forEach(({id}) => {
          if (id && searchedTrips.includes(id)) {
            groupAHasSearchedTrip = true;
          }
        });

        tripsInGroupB.forEach(({id}) => {
          if (id && searchedTrips.includes(id)) {
            groupBHasSearchedTrip = true;
          }
        });

        if (groupAHasSearchedTrip && !groupBHasSearchedTrip) {
          return -1;
        } else if (!groupAHasSearchedTrip && groupBHasSearchedTrip) {
          return 1;
        }

        return sortVehiclesDirection === 'asc'
          ? onSortByVehiclesAsc(a, b)
          : onSortByVehiclesDesc(a, b);
      },
      [
        bookingMasterTrips,
        masterMasterTrips,
        onSortByVehiclesAsc,
        onSortByVehiclesDesc,
        planningMasterTrips,
        searchedTrips,
        sortVehiclesDirection,
      ]
    );

  const handleGroupOrder = React.useCallback(
    (a, b) =>
      searchedTrips.length > 0
        ? handleSearchByTripIds(a, b)
        : sortVehiclesDirection === 'asc'
        ? onSortByVehiclesAsc(a, b)
        : onSortByVehiclesDesc(a, b),
    [
      handleSearchByTripIds,
      onSortByVehiclesAsc,
      onSortByVehiclesDesc,
      searchedTrips.length,
      sortVehiclesDirection,
    ]
  );

  const handleDoubleClick = React.useCallback<(properties?: any) => void>(
    ({event, ...properties}) => {
      if (properties.what === 'background') {
        onClearSelectedGanttItem();
        onClearSearchedItems();
        return;
      }

      onClearSelectedGanttItem();

      ganttItems.remove(NEW_BOOKING_TRIP_ID);

      if (properties.item === PREVIEW_TRIP_ID) {
        return;
      }
      ganttItems.remove(PREVIEW_TRIP_ID);

      const currentItem = ganttItems.get(
        properties.item
      ) as unknown as IPlanningBoardGanttItem;
      if (currentItem && !Array.isArray(currentItem)) {
        const vehicle = getVehicle(currentItem.group);
        const capacityDimensionOptions: Listing[] =
          vehicle?.type?.capacityDimensions?.map(({id, unitOfMeasure}) => ({
            value: id ?? undefined,
            label: unitOfMeasure ?? undefined,
          })) ?? [];
        setVehicleCapacityDimensionOptions(capacityDimensionOptions);
        setSelectedVehicleCapacityDimensionOption(
          capacityDimensionOptions.length > 0
            ? capacityDimensionOptions[0]
            : undefined
        );
        setSelectedGanttItem(currentItem);
      }
    },
    [
      ganttItems,
      getVehicle,
      onClearSearchedItems,
      onClearSelectedGanttItem,
      setSelectedGanttItem,
      setSelectedVehicleCapacityDimensionOption,
      setVehicleCapacityDimensionOptions,
    ]
  );

  const handleSelection = React.useCallback(
    (items: IdType[]) => {
      if (items.length === 0) {
        onClearSelectedGanttItem();
        return [];
      }
      const subItems: IdType[] = [];
      items.forEach((item) => {
        const ganttItem = ganttItems.get(item);
        subItems.push(
          ...ganttItems
            .get({
              filter(item: IPlanningBoardGanttItem): boolean {
                return item.subgroup === ganttItem?.subgroup;
              },
            })
            .map(({id}) => id)
        );
      });

      return [...items, ...subItems];
    },
    [ganttItems, onClearSelectedGanttItem]
  );

  return (
    <VisTimeline
      groups={ganttGroups}
      items={ganttItems}
      selection={searchedTrips}
      onDoubleClick={handleDoubleClick}
      onSetSelection={handleSelection}
      options={{
        groupOrder: handleGroupOrder,
        orientation: 'top',
        verticalScroll: true,
        stack: false,
        stackSubgroups: false,
        editable: false,
        clickToUse: false,
        end: endDate?.toJSDate(),
        start: startDate?.toJSDate(),
        align: 'left',
        tooltip: {followMouse: true},
        maxHeight: '86vh',
      }}
    />
  );
};
