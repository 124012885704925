import React from 'react';
import {useHistory} from 'react-router-dom';
import {
  SpeedTable,
  SpeedTableBandMinimal,
} from '@onroadvantage/onroadvantage-api';
import {FormikHelpers, FormikProps} from 'formik/dist/types';
import {useAppNotifications} from '../../../contexts';
import {ISpeedTableForm} from '../SpeedTableForm';
import {speedTableApi} from '../../../api';
import {
  parsePotentialString,
  TOnInlineAdd,
  TOnInlineEdit,
} from '../../../factory/template';
import {ISpeedTableBandList} from '../speedTableBandList';

export const useSpeedTable = () => {
  const history = useHistory();
  const notify = useAppNotifications();
  const [speedTable, setSpeedTable] = React.useState<SpeedTable | undefined>();
  const [speedTableId, setSpeedTableId] = React.useState<number | undefined>();
  const [loadingSpeedTable, setLoadingSpeedTable] =
    React.useState<boolean>(false);
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const detailsRef = React.useRef<FormikProps<ISpeedTableForm>>(null);

  const loadSpeedTable = React.useCallback(async () => {
    setLoadingSpeedTable(true);
    try {
      if (speedTableId) {
        const response = await speedTableApi.apiSpeedTableSpeedTableIdGet({
          speedTableId,
        });
        setSpeedTable(response);
      } else {
        setSpeedTable(undefined);
      }
    } catch (e) {
      notify('error', 'Failed to load speed table list');
    } finally {
      setLoadingSpeedTable(false);
    }
  }, [notify, speedTableId]);

  const handleDetailsFormSubmit = React.useCallback(
    async (
      values: ISpeedTableForm,
      formikHelpers: FormikHelpers<ISpeedTableForm>
    ) => {
      setSubmitting(true);
      try {
        const {contracts, vehicleTypes, ...otherValues} = values;

        if (speedTableId) {
          await speedTableApi.apiSpeedTableSpeedTableIdPatch({
            speedTableId,
            body: {
              ...otherValues,
              contractIds: contracts
                .filter(({value}) => !!value)
                .map(({value}) => value as number),
              vehicleTypeIds: vehicleTypes
                ?.filter(({value}) => !!value)
                .map(({value}) => value as number),
            },
          });
        } else if (contracts.length > 0) {
          await speedTableApi.apiSpeedTablePost({
            body: {
              ...otherValues,
              contractIds: contracts
                .filter(({value}) => !!value)
                .map(({value}) => value as number),
              vehicleTypeIds: vehicleTypes
                ?.filter(({value}) => !!value)
                .map(({value}) => value as number),
            },
          });
        }
        history.push('/speedtablelist');
        notify('success', `${speedTableId ? 'Updated' : 'Added'} speed table`);
      } catch (e) {
        notify(
          'error',
          `Failed to ${speedTableId ? 'update' : 'add'} speed table`
        );
      } finally {
        formikHelpers.setSubmitting(false);
        setSubmitting(false);
      }
    },
    [history, notify, setSubmitting, speedTableId]
  );

  const handleAddSpeedTableBand = React.useCallback<TOnInlineAdd>(
    async (changes) => {
      setSubmitting(true);
      try {
        const values = changes[0] as ISpeedTableBandList;
        if (speedTableId) {
          await speedTableApi.apiSpeedTableBandPost({
            body: {...values, speedTableId},
          });
          notify('success', 'Added speed table band');
        }
      } catch (e) {
        notify('error', 'Failed to add speed table band');
      } finally {
        setSubmitting(false);
        await loadSpeedTable();
      }
    },
    [loadSpeedTable, notify, speedTableId]
  );

  const handleEditSpeedTableBand = React.useCallback<TOnInlineEdit>(
    async (changes) => {
      setSubmitting(true);
      try {
        if (speedTableId) {
          for (const {id, newValues} of changes) {
            await speedTableApi.apiSpeedTableBandSpeedTableBandIdPatch({
              speedTableBandId: parsePotentialString(id),
              body: newValues,
            });
          }
          notify('success', 'Updated speed table band');
        }
      } catch (e) {
        notify('error', 'Failed to update speed table band');
      } finally {
        setSubmitting(false);
        await loadSpeedTable();
      }
    },
    [loadSpeedTable, notify, speedTableId]
  );

  const handleDeleteSpeedTableBand = React.useCallback(
    async (row: SpeedTableBandMinimal) => {
      setSubmitting(true);
      try {
        if (row.id) {
          await speedTableApi.apiSpeedTableBandSpeedTableBandIdDelete({
            speedTableBandId: row.id,
          });
          notify('success', 'Deleted speed table band');
        }
      } catch (e) {
        notify('error', 'Failed to delete speed table band');
      } finally {
        setSubmitting(false);
        await loadSpeedTable();
      }
    },
    [loadSpeedTable, notify]
  );

  return {
    detailsRef,
    loadSpeedTable,
    loadingSpeedTable,
    onAddSpeedTableBand: handleAddSpeedTableBand,
    onDeleteSpeedTableBand: handleDeleteSpeedTableBand,
    onDetailsFormSubmit: handleDetailsFormSubmit,
    onEditSpeedTableBand: handleEditSpeedTableBand,
    setSpeedTable,
    setSpeedTableId,
    speedTable,
    speedTableId,
    submitting,
  };
};

export type useSpeedTableResponse = ReturnType<typeof useSpeedTable>;

export const useSpeedTableResponseInitial: useSpeedTableResponse = {
  detailsRef: {current: null},
  loadSpeedTable: async () => {},
  loadingSpeedTable: false,
  onAddSpeedTableBand: async () => {},
  onDeleteSpeedTableBand: async () => {},
  onDetailsFormSubmit: async () => {},
  onEditSpeedTableBand: async () => {},
  setSpeedTable: () => {},
  setSpeedTableId: () => {},
  speedTable: undefined,
  speedTableId: undefined,
  submitting: false,
};
