import React from 'react';
import {OperationalEventTypeContext} from './OperationalEventTypeContext';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../../factory/template';
import {OperationalEventType} from '@onroadvantage/onroadvantage-api';
import {EventTypeListConfigContracts} from '../EventTypeListConfigContracts';
import {RoleService} from '../../../service';

export const OperationalEventTypeList: React.FC = () => {
  const {loadList, cleanupList} = React.useContext(OperationalEventTypeContext);
  const [config] = React.useState<TemplateTableConfig<OperationalEventType>>({
    columns: [
      {
        name: 'name',
        label: 'Name',
        type: 'string',
        enableEditing: RoleService.hasPermission(
          'Edit OperationalEventType',
          'Edit'
        ),
        enableInlineAdding: true,
      },
      {
        name: 'externalReference',
        label: 'External Reference',
        type: 'string',
        enableEditing: RoleService.hasPermission(
          'Edit OperationalEventType',
          'Edit'
        ),
        enableInlineAdding: true,
      },
      {
        name: 'integrationEnabled',
        label: 'Integration',
        type: 'bool',
        enableEditing: RoleService.hasOneOfPermissions([
          {permissionName: 'Edit OperationalEventType', type: 'Edit'},
          {permissionName: 'Edit OperationalEventTypeToggles', type: 'Edit'},
        ]),
        enableInlineAdding: true,
      },
      {
        name: 'contracts',
        label: 'Contract Codes',
        type: 'string',
        enableFilter: false,
        enableSort: false,
        enableEditing: false,
        enableInlineAdding: true,
        enableAutocomplete: true,
        enableMulti: true,
        model: 'Contract',
        getValue: ({contractOperationalEventTypeConfigs}) => (
          <EventTypeListConfigContracts
            variant="operational"
            contractEventTypeConfigs={contractOperationalEventTypeConfigs?.map(
              ({id, contract}) => ({
                configId: id,
                contractCode: contract?.code,
                contractName: contract?.name,
              })
            )}
          />
        ),
      },
      {
        name: 'telematicsConfig',
        label: 'Telematics Config',
        type: 'string',
        enableFilter: false,
        enableSort: false,
        enableEditing: false,
        enableInlineAdding: true,
        enableAutocomplete: true,
        enableMulti: false,
        model: 'TelematicsConfig',
        getValue: ({telematicsConfig}) => telematicsConfig?.name,
      },
    ],
    deleteMessage: (row) => ({
      title: 'Operational Event Type',
      items: {
        Name: row.name,
        ExternalReference: row.externalReference,
        telematicsConfig: row.telematicsConfig?.name,
      },
    }),
    enableFilter: true,
    enableSort: true,
    identifier: 'OPERATIONAL_EVENT_TYPE_LIST',
  });

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [loadList, cleanupList]);

  return (
    <TemplateTableWrapper
      config={config}
      context={OperationalEventTypeContext}
    />
  );
};
