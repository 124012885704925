import React from 'react';
import _ from 'lodash';
import {
  ITemplateControllerRoute,
  TemplateController,
} from '../../factory/template';
import {
  TaskTemplateContext,
  TaskTemplateContextProvider,
} from './TaskTemplateContext';
import {TaskTemplateList} from './TaskTemplateList';
import {TaskTemplateIdHandler} from './TaskTemplateIdHandler';
import {TaskTemplateForm} from './TaskTemplateForm';
import {TaskTemplate} from './TaskTemplate';

export const TaskTemplateController: React.FC = () => {
  return (
    <TaskTemplateContextProvider>
      <TaskTemplateControllerComponent />
    </TaskTemplateContextProvider>
  );
};

const TaskTemplateControllerComponent: React.FC = () => {
  const {taskTemplate, loading} = React.useContext(TaskTemplateContext);
  const routes: ITemplateControllerRoute[] = React.useMemo(
    () => [
      {title: 'Task Template List', component: TaskTemplateList},
      {
        path: '/add',
        title: 'New Task Template',
        name: 'Add TaskTemplate',
        type: 'Add',
        component: () => <TaskTemplateForm isAdd />,
      },
      {
        path: '/:taskTemplateId',
        title: (params) =>
          loading
            ? 'Task Template Loading...'
            : taskTemplate?.name ??
              `Task Template ${_.get(params, 'taskTemplateId', 'Single')}`,
        component: TaskTemplate,
        IdHandler: TaskTemplateIdHandler,
      },
    ],
    [loading, taskTemplate?.name]
  );
  return <TemplateController rootPath="/tasktemplatelist" routes={routes} />;
};
