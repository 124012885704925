import React from 'react';

export interface RefetchContextInterface {
  refetch?: boolean;
  setRefetch: (refetch: boolean) => void;
}

export const RefetchContext = React.createContext<RefetchContextInterface>({
  refetch: false,
  setRefetch: () => {},
});

interface Props {
  children: React.ReactNode;
  props?: any;
}

export function RefetchProvider({children, ...props}: Props) {
  const [refetch, setRefetch] = React.useState<boolean>();

  return (
    <RefetchContext.Provider
      value={React.useMemo(
        () => ({refetch, setRefetch}),
        [refetch, setRefetch]
      )}
      {...props}
    >
      {children}
    </RefetchContext.Provider>
  );
}
