import React from 'react';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import EditIcon from '@mui/icons-material/Edit';
import {MasterRouteDetailsForm} from './MasterRouteDetailsForm';

export interface MasterRouteDetailsProps {
  isAdd?: boolean;
}

export const MasterRouteDetails: React.FC<MasterRouteDetailsProps> = ({
  isAdd,
}) => {
  return (
    <Card>
      <CardHeader
        title={`Master Route Details ${isAdd ? 'Add' : 'Edit'}`}
        avatar={
          <Avatar>
            <EditIcon />
          </Avatar>
        }
      />
      <CardContent>
        <MasterRouteDetailsForm isAdd={isAdd} />
      </CardContent>
    </Card>
  );
};
