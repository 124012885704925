import React from 'react';
import {
  TripDebriefSummaryCard,
  TripDebriefSummaryCardList,
} from './tripDebriefSummaryCard';
import {TripDebriefContext} from '../TripDebriefContext';
import {
  MasterTripDebriefOrderLineSnapshot,
  MasterTripDebriefSnapshot,
} from '@onroadvantage/onroadvantage-api';

export const TripDebriefSummaryOrders: React.FC = () => {
  const {masterTripDebriefData, onTabNavigate} =
    React.useContext(TripDebriefContext);
  /**
   *  Destructure snapshotData, need to add || ({} as MasterTripDebriefSnapshot) for typescript, since
   *  snapshotData is nullable.
   */
  const {orders} =
    masterTripDebriefData?.snapshotData || ({} as MasterTripDebriefSnapshot);
  /**
   *  Destructure overrideData, need to add || ({} as MasterTripDebriefSnapshot) for typescript, since
   *  overrideData is nullable.
   */
  const {orders: ordersOverride} =
    masterTripDebriefData?.overrideData || ({} as MasterTripDebriefSnapshot);

  const list: TripDebriefSummaryCardList = React.useMemo(() => {
    const groupedByProductUom: {
      [key: string]: MasterTripDebriefOrderLineSnapshot[];
    } = {};

    const linesOverride: MasterTripDebriefOrderLineSnapshot[] = [];

    let didOverrideActual = false;

    ordersOverride?.forEach(({lines}) => {
      lines?.forEach((line) => {
        linesOverride.push(line);
        if (line.actualQuantity) {
          didOverrideActual = true;
        }
      });
    });

    orders?.forEach(({lines}) =>
      lines?.forEach((line) => {
        if (line) {
          const productNameWithUom = `${
            line.product?.name ?? 'Unnamed Product'
          } (${line.product?.uom ?? 'Units'})`;
          groupedByProductUom[productNameWithUom] = [
            ...(groupedByProductUom[productNameWithUom] ?? []),
            line,
          ];
        }
      })
    );

    return [
      ...Object.keys(groupedByProductUom).map((key) => ({
        primary: `${key}: planned quantity`,
        secondary: groupedByProductUom[key].reduce(
          (acc, {quantity}) => acc + (quantity ?? 0),
          0
        ),
        type: 'number' as const,
        changed: false,
      })),
      ...Object.keys(groupedByProductUom).map((key) => ({
        primary: `${key}: actual quantity`,
        secondary: groupedByProductUom[key].reduce(
          (acc, {id, orderId, actualQuantity}) => {
            const lineOverride = linesOverride.find(
              (line) => line.id === id && line.orderId === orderId
            );
            return acc + (lineOverride?.actualQuantity || actualQuantity || 0);
          },
          0
        ),
        type: 'number' as const,
        changed: didOverrideActual,
      })),
    ];
  }, [orders, ordersOverride]);

  const handleNavigate = React.useCallback(
    () => onTabNavigate('Orders'),
    [onTabNavigate]
  );

  return (
    <TripDebriefSummaryCard
      title="Orders"
      list={list}
      onNavigate={handleNavigate}
    />
  );
};
