import React from 'react';
import {ContractCriticalEventTypePrompt} from '@onroadvantage/onroadvantage-api';
import {contractCriticalEventTypeConfigApi} from '../../../../api';
import {TOnFormikSubmit} from '../../../../factory/template';
import {ContractCriticalEventTypeConfigPromptFormValues} from './ContractCriticalEventTypePromptForm';
import {useAppNotifications} from '../../../../contexts';
import {useHistory} from 'react-router-dom';
import {useContractCriticalEventTypeConfigContext} from '../ContractCriticalEventTypeConfigContext';

export interface ContractCriticalEventTypePromptContextProps {
  loading: boolean;
  submitting: boolean;
  contractCriticalEventTypePromptId: number | undefined;
  setContractCriticalEventTypePromptId: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  contractCriticalEventTypePrompt: ContractCriticalEventTypePrompt | undefined;
  onDetailsSubmit: TOnFormikSubmit<ContractCriticalEventTypeConfigPromptFormValues>;
}

export const ContractCriticalEventTypePromptContext =
  React.createContext<ContractCriticalEventTypePromptContextProps | null>(null);

export const useContractCriticalEventTypePromptContext = () => {
  const contractCriticalEventTypeContext = React.useContext(
    ContractCriticalEventTypePromptContext
  );
  if (contractCriticalEventTypeContext == null) {
    throw new Error(
      'useContractCriticalEventTypePromptContext has to be used within <ContractCriticalEventTypePromptContext.Provider>'
    );
  }

  return contractCriticalEventTypeContext;
};

export const ContractCriticalEventTypePromptContextProvider: React.FC = ({
  children,
}) => {
  const notify = useAppNotifications();
  const history = useHistory();
  const {
    contractCriticalEventTypeConfigId,
    setContractCriticalEventTypeConfig,
  } = useContractCriticalEventTypeConfigContext();
  const [contractCriticalEventTypePrompt, setContractCriticalEventTypePrompt] =
    React.useState<ContractCriticalEventTypePrompt | undefined>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [
    contractCriticalEventTypePromptId,
    setContractCriticalEventTypePromptId,
  ] = React.useState<number | undefined>();

  const handleLoadContractCriticalEventTypePrompt =
    React.useCallback(async () => {
      setLoading(true);
      try {
        if (
          contractCriticalEventTypePromptId != null &&
          contractCriticalEventTypeConfigId != null
        ) {
          const response =
            await contractCriticalEventTypeConfigApi.apiContractCriticalEventTypeConfigContractCriticalEventTypeConfigIdPromptPromptIdGet(
              {
                contractCriticalEventTypeConfigId,
                promptId: contractCriticalEventTypePromptId,
              }
            );
          if (response != null) {
            setContractCriticalEventTypePrompt(response);
          }
        }
      } catch (e) {
        notify('error', 'Failed to load contract critical event type prompt');
      } finally {
        setLoading(false);
      }
    }, [
      contractCriticalEventTypeConfigId,
      contractCriticalEventTypePromptId,
      notify,
    ]);

  const handleOnDetailsSubmit = React.useCallback<
    TOnFormikSubmit<ContractCriticalEventTypeConfigPromptFormValues>
  >(
    async (values, formikHelpers) => {
      setSubmitting(true);
      formikHelpers.setSubmitting(true);
      try {
        if (contractCriticalEventTypeConfigId == null) {
          return null;
        }
        if (contractCriticalEventTypePromptId != null) {
          const response =
            await contractCriticalEventTypeConfigApi.apiContractCriticalEventTypeConfigContractCriticalEventTypeConfigIdPromptPromptIdPatch(
              {
                contractCriticalEventTypeConfigId,
                promptId: contractCriticalEventTypePromptId,
                body: values,
              }
            );
          setContractCriticalEventTypeConfig((prevState) =>
            prevState
              ? {
                  ...prevState,
                  prompts: prevState.prompts?.reduce(
                    (acc: ContractCriticalEventTypePrompt[], curr) => {
                      if (curr.id === response.id) {
                        return [...acc, response];
                      }
                      return [...acc, curr];
                    },
                    []
                  ),
                }
              : undefined
          );
          notify('success', 'Updated contract critical event type prompt');
          setContractCriticalEventTypePrompt(response);
        } else {
          if (values.prompt == null) {
            notify('warning', 'No prompt specified');
            return;
          }
          if (values.type == null) {
            notify('warning', 'No type specified');
            return;
          }

          const response =
            await contractCriticalEventTypeConfigApi.apiContractCriticalEventTypeConfigContractCriticalEventTypeConfigIdPromptPost(
              {
                contractCriticalEventTypeConfigId,
                body: {...values, content: values.content ?? ''},
              }
            );
          if (response) {
            setContractCriticalEventTypeConfig(response);
          }
          notify('success', 'Created contract critical event type prompt');
        }
        history.push(
          `/contracteventtypeconfigs/critical/${contractCriticalEventTypeConfigId}?tab=Prompt`
        );
      } catch (e) {
        notify('error', 'Failed to update contract critical event type config');
      } finally {
        setSubmitting(false);
        formikHelpers.setSubmitting(false);
      }
    },
    [
      contractCriticalEventTypeConfigId,
      contractCriticalEventTypePromptId,
      history,
      notify,
      setContractCriticalEventTypeConfig,
    ]
  );

  React.useEffect(() => {
    handleLoadContractCriticalEventTypePrompt();
    return () => {
      setContractCriticalEventTypePrompt(undefined);
    };
  }, [
    handleLoadContractCriticalEventTypePrompt,
    setContractCriticalEventTypePrompt,
  ]);

  return (
    <ContractCriticalEventTypePromptContext.Provider
      value={{
        loading,
        submitting,
        contractCriticalEventTypePrompt,
        contractCriticalEventTypePromptId,
        setContractCriticalEventTypePromptId,
        onDetailsSubmit: handleOnDetailsSubmit,
      }}
    >
      {children}
    </ContractCriticalEventTypePromptContext.Provider>
  );
};
