import React from 'react';
import {Button, Stack, Step, StepLabel, Stepper} from '@mui/material';
import {
  broadcastVantageMobileSteps,
  BroadcastVantageMobileSteps,
  broadcastVantageMobileStepsLabels,
} from './useBroadcastVantageMobile';
import {useBroadcastVantageMobileStyles} from './BroadcastVantageMobile.styles';
import {useBroadcastVantageMobileContext} from './BroadcastVantageMobileContext';
import {useAppNotifications} from '../../../contexts';

interface BroadcastVantageMobileStepperProps {
  completedSteps: BroadcastVantageMobileSteps[];
  activeStep: BroadcastVantageMobileSteps;
  onNextStep: (currentStep: BroadcastVantageMobileSteps) => void;
  onPreviousStep: () => void;
  onGoToStep: (targetStep: BroadcastVantageMobileSteps) => void;
}

export const BroadcastVantageMobileStepper: React.FC<
  BroadcastVantageMobileStepperProps
> = ({
  completedSteps,
  activeStep,
  onNextStep,
  onPreviousStep,
  onGoToStep,
  children,
}) => {
  const notify = useAppNotifications();
  const classes = useBroadcastVantageMobileStyles();
  const {
    onSubmit,
    validSteps,
    messageHeadingRef,
    messageRef,
    messageIdentifier,
  } = useBroadcastVantageMobileContext();
  const activeStepIndex = broadcastVantageMobileSteps.indexOf(activeStep);
  const isLastStep = activeStepIndex + 1 === broadcastVantageMobileSteps.length;

  const handleValidateNextStep = React.useCallback(() => {
    const {isValid, message} = validSteps[activeStep];
    if (!isValid) {
      notify('warning', message);
    } else {
      onNextStep(activeStep);
    }
  }, [activeStep, notify, onNextStep, validSteps]);

  const handleValidateSubmit = React.useCallback(() => {
    if (messageHeadingRef.current?.value == null) {
      notify('warning', 'Message heading is required.');
      return;
    }

    if (messageRef.current?.value == null) {
      notify('warning', 'Message is required.');
      return;
    }

    if (messageIdentifier == null) {
      notify('warning', 'First generate the message before submitting.');
      return;
    }

    onSubmit();
  }, [messageHeadingRef, messageIdentifier, messageRef, notify, onSubmit]);

  return (
    <Stack p={2} spacing={2}>
      <Stepper activeStep={activeStepIndex + 1}>
        {broadcastVantageMobileSteps.map((step) => (
          <Step
            completed={completedSteps.includes(step)}
            active={activeStep === step}
            onClick={() => onGoToStep(step)}
            color="primary"
            key={step}
            classes={{completed: classes.completedStep}}
          >
            <StepLabel>{broadcastVantageMobileStepsLabels[step]}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {children}
      <Stack direction="row" spacing={1}>
        <Button
          onClick={onPreviousStep}
          variant="outlined"
          disabled={activeStepIndex === 0}
        >
          Back
        </Button>
        <Button
          onClick={isLastStep ? handleValidateSubmit : handleValidateNextStep}
          variant="contained"
        >
          {isLastStep ? 'Send Broadcast' : 'Next'}
        </Button>
      </Stack>
    </Stack>
  );
};
