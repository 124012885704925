import React from 'react';
import {PlanningSkillNodeListContextProvider} from './planningSkillNodeList';
import {PlanningSkillProductGroupListContextProvider} from './planningSkillProductGroupList';
import {PlanningSkillVehicleTypeListContextProvider} from './planningSkillVehicleTypeList';
import {PlanningSkillVehicleGroupListContextProvider} from './planningSkillVehicleGroupList';
import {PlanningSkillNodeTypeListContextProvider} from './planningSkillNodeTypeList';
import {PlanningSkillDriverGroupListContextProvider} from './planningSkillDriverGroupList';
import {PlanningSkillCustomerListContextProvider} from './planningSkillCustomerList';

export const PlanningSkillLinkingListsProviders: React.FC = ({children}) => {
  return (
    <PlanningSkillCustomerListContextProvider>
      <PlanningSkillDriverGroupListContextProvider>
        <PlanningSkillNodeListContextProvider>
          <PlanningSkillNodeTypeListContextProvider>
            <PlanningSkillProductGroupListContextProvider>
              <PlanningSkillVehicleGroupListContextProvider>
                <PlanningSkillVehicleTypeListContextProvider>
                  {children}
                </PlanningSkillVehicleTypeListContextProvider>
              </PlanningSkillVehicleGroupListContextProvider>
            </PlanningSkillProductGroupListContextProvider>
          </PlanningSkillNodeTypeListContextProvider>
        </PlanningSkillNodeListContextProvider>
      </PlanningSkillDriverGroupListContextProvider>
    </PlanningSkillCustomerListContextProvider>
  );
};
