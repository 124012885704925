import React from 'react';

interface FreshDeskWidgetProps {
  url: string;
  type?: 'pop-up' | 'incorporated';
  buttonType?: 'text' | 'image';
  buttonText?: string;
  buttonColor?: string;
  buttonBackgroundColor?: string;
  buttonBackgroundImage?: string;
  buttonPosition?: 'left' | 'right' | 'top' | 'bottom';
  buttonOffset?: string;
  formTitle?: string;
  submitThanks?: string;
  formHeight?: number | string;
  autofill?: {[key: string]: string | undefined};
  children?: any;
}

class FreshdeskWidget extends React.Component<FreshDeskWidgetProps> {
  constructor(props: FreshDeskWidgetProps) {
    super(props);

    this.renderPopUp = this.renderPopUp.bind(this);
    this.renderWithChildren = this.renderWithChildren.bind(this);
    this.renderIncorporated = this.renderIncorporated.bind(this);
  }

  componentDidMount() {
    const script = document.createElement('script');
    script.src =
      'https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.js';
    script.type = 'text/javascript';
    document.body.appendChild(script);
  }

  componentWillUnmount() {
    const {type} = this.props;

    if (type === 'pop-up' && (window as any).FreshWidget) {
      (window as any).FreshWidget.destroy();
    }
  }

  getAlignmentByPositionLabel(label: string | undefined) {
    const alignments: {[key: string]: number} = {
      left: 4,
      right: 2,
      top: 1,
      bottom: 3,
    };

    return label ? alignments[label] : null;
  }

  renderWithChildren() {
    const {
      url,
      formTitle,
      formHeight,
      submitThanks,
      autofill = {},
    } = this.props;

    const autofills = Object.entries(autofill).map(
      ([field, value]) => `helpdesk_ticket[${field}]=${value}`
    );

    const queryString = [
      '&widgetType=popup',
      `formTitle=${formTitle}`,
      `submitThanks=${submitThanks}`,
      ...autofills,
    ].join('&');

    const params = {
      utf8: '✓',
      widgetType: 'popup',
      url,
      formTitle,
      formHeight,
      submitThanks,
      queryString,
      // thanks freshdesk for this
      offset: '-3000px',
    };

    const handleClick = () => {
      (window as any).FreshWidget.init('', params);
      (window as any).FreshWidget.init('', params);
      setTimeout(() => {
        (window as any).FreshWidget.create();
        (window as any).FreshWidget.show();
      }, 100);
    };

    const childrenWithHandleClick = React.cloneElement(this.props.children, {
      onClick: handleClick.bind(this),
    });

    return <div>{childrenWithHandleClick}</div>;
  }

  renderPopUp() {
    const {
      url,
      buttonType,
      buttonText,
      buttonColor,
      buttonBackgroundColor,
      buttonBackgroundImage,
      buttonPosition,
      buttonOffset,
      formTitle,
      submitThanks,
      formHeight,
      autofill = {},
    } = this.props;

    const autofills = Object.entries(autofill).map(
      ([field, value]) => `helpdesk_ticket[${field}]=${value}`
    );

    const queryString = [
      '&widgetType=popup',
      `formTitle=${formTitle}`,
      `submitThanks=${submitThanks}`,
      ...autofills,
    ].join('&');

    const params = {
      utf8: '✓',
      widgetType: 'popup',
      alignment: this.getAlignmentByPositionLabel(buttonPosition),
      offset: buttonOffset,
      buttonBg: buttonBackgroundColor,
      backgroundImage: buttonBackgroundImage,
      url,
      buttonType,
      buttonText,
      buttonColor,
      submitThanks,
      formHeight,
      formTitle,
      queryString,
    };

    (window as any).FreshWidget.init('', params);

    return <div id="freshdesk" />;
  }

  renderIncorporated() {
    const {
      url,
      formTitle,
      formHeight,
      submitThanks,
      autofill = {},
    } = this.props;

    const widgetUrl = `${url}/widgets/feedback_widget/new?`;

    const autofills = Object.entries(autofill).map(
      ([field, value]) => `helpdesk_ticket[${field}]=${value}`
    );

    const queryString = [
      'widgetType=embedded',
      'screenshot=no',
      `formTitle=${formTitle}`,
      `formHeight=${formHeight}`,
      `submitThanks=${submitThanks}`,
      ...autofills,
    ].join('&');

    return (
      <div>
        <iframe
          className="freshwidget-embedded-form"
          frameBorder="0"
          title="freshwidget-embedded-form"
          id="freshwidget-embedded-form"
          src={widgetUrl + queryString}
          scrolling="no"
          height={formHeight}
          width="100%"
        />
      </div>
    );
  }

  render() {
    const {type} = this.props;

    if (type === 'incorporated') {
      return this.renderIncorporated();
    }

    const hasChildElement = React.Children.count(this.props.children) >= 1;

    if (hasChildElement) {
      return this.renderWithChildren();
    }

    return this.renderPopUp();
  }

  static defaultProps = {
    type: 'incorporated',
    formTitle: 'Help and support',
    submitThanks:
      'Thank you, one of our representatives will respond to you soon! =)',
    formHeight: '500px',
    buttonType: 'text',
    buttonText: 'Support',
    buttonColor: 'white',
    buttonBackgroundColor: '#015453',
    buttonPosition: 'top',
    buttonOffset: '235px',
    autofill: {},
    children: null,
  };
}

export default FreshdeskWidget;
