import React from 'react';
import {useEventMapStyles} from './EventMap.style';
import Map from '../../map/map';
import {EventContext} from '../EventContext';

export const EventMap: React.FC = () => {
  const {event} = React.useContext(EventContext);
  const classes = useEventMapStyles();

  if (event == null) {
    return <h1>No Event</h1>;
  }

  return (
    <div className={classes.root} data-cy="RecipientMap">
      <Map geoSelector={false} zoom={15} vehicle={event?.vehicle} />
    </div>
  );
};
