import React from 'react';
import clone from 'rfdc';
import {
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import {List as ListIcon} from '@mui/icons-material';
import {WebPlanningBoardMasterTripWithIdType} from '../../index';
import {Loader} from '../../../loader';
import {TemplateTable, TemplateTableConfig} from '../../../../factory/template';
import {VantageDialogButton} from '../../../dialog';
import {usePlanningBoardContext} from '../../planningBoardContext';
import {usePlanningBoardCommitBookingMasterTripsStyles} from './PlanningBoardCommitBookingMasterTrips.style';
import {usePlanningBoardCommitBookingMasterTrips} from './usePlanningBoardCommitBookingMasterTrips';

export const PlanningBoardCommitBookingMasterTrips: React.FC = () => {
  const classes = usePlanningBoardCommitBookingMasterTripsStyles();
  const {
    assignedOrders,
    bookingMasterTrips,
    bookingMasterTripsToCommit,
    loadingCommitBookingTrips,
    loadingMasterTrips,
    setBookingMasterTripsToCommit,
  } = usePlanningBoardContext();

  const {list, onFiltersChange, filters} =
    usePlanningBoardCommitBookingMasterTrips({
      bookingMasterTrips,
      assignedOrders,
    });

  const config = React.useMemo<
    TemplateTableConfig<WebPlanningBoardMasterTripWithIdType>
  >(
    () => ({
      columns: [
        {name: 'tripNumber', label: 'Trip Number', type: 'string'},
        {
          name: 'startingLocation',
          label: 'Starting Location',
          type: 'string',
          getValue: ({trip}) =>
            (trip?.stops && trip?.stops[0]?.node?.name) ?? '-',
        },
        {
          name: 'loading',
          label: 'Loading',
          type: 'string',
          getValue: ({trip}) => {
            /** Need to clone the stops so that it doesn't modify the reference of the trip.stops that is passed from the list */
            const clonedStops = clone()(trip?.stops);
            /** Confirm that the stops are sorted in the correct sequence, to get the correct stop with the first order */
            const sortedStops = clonedStops?.sort((a, b) =>
              a.sequence && b.sequence ? a.sequence - b.sequence : 0
            );

            let firstOrderLoading: string | null = null;

            sortedStops?.forEach(({orders}) => {
              if (
                orders &&
                orders.length > 0 &&
                orders[0].upliftPoint?.name &&
                firstOrderLoading === null
              ) {
                firstOrderLoading = orders[0].upliftPoint.name;
              }
            });

            return firstOrderLoading ?? '-';
          },
        },
        {
          name: 'offloading',
          label: 'Offloading',
          type: 'string',
          getValue: ({trip}) => {
            /** Need to clone the stops so that it doesn't modify the reference of the trip.stops that is passed from the list */
            const clonedStops = clone()(trip?.stops);
            /** Reverse the sorting to get the last stops first so that we can get the last order */
            const sortedStops = clonedStops?.sort((a, b) =>
              a.sequence && b.sequence ? b.sequence - a.sequence : 0
            );

            let lastOrderOffloading: string | null = null;

            sortedStops?.forEach(({orders}) => {
              if (
                orders &&
                orders.length > 0 &&
                orders[0].offloadPoint?.name &&
                lastOrderOffloading === null
              ) {
                lastOrderOffloading = orders[0].offloadPoint.name;
              }
            });

            return lastOrderOffloading ?? '-';
          },
        },
        {
          name: 'endingLocation',
          label: 'Ending Location',
          type: 'string',
          getValue: ({trip}) =>
            (trip?.stops && trip.stops[trip.stops.length - 1]?.node?.name) ??
            '-',
        },
        {
          name: 'orderNumbers',
          label: 'Order numbers',
          type: 'string',
          getValue: ({trip}) => {
            const orderNumbers: string[] = [];

            trip?.stops?.forEach(({orders}) =>
              orders?.forEach(({orderNumber}) => {
                if (orderNumber && !orderNumbers.includes(orderNumber)) {
                  orderNumbers.push(orderNumber);
                }
              })
            );

            if (orderNumbers.length > 0) {
              return (
                <Stack alignItems="flex-start">
                  <Tooltip
                    title={orderNumbers.join(', ')}
                    classes={{tooltip: classes.tooltip}}
                    arrow
                  >
                    <Typography className={classes.orderNumberTypography}>
                      {orderNumbers.join(', ')}
                    </Typography>
                  </Tooltip>
                </Stack>
              );
            }

            return '-';
          },
        },
        {
          name: 'planningSkills',
          label: 'Planning Skills',
          type: 'string',
          getValue: ({trip}) => {
            const skills: string[] = [];
            assignedOrders.forEach((assignedOrder) => {
              trip?.stops?.forEach((stop) => {
                stop.orders?.forEach((stopOrder) => {
                  if (
                    stopOrder.id === assignedOrder.id &&
                    assignedOrder.planningOrder?.skill &&
                    !skills.includes(assignedOrder.planningOrder.skill)
                  ) {
                    skills.push(
                      ...assignedOrder.planningOrder?.skill?.split(',')
                    );
                  }
                });
              });
            });
            return (
              <>
                <VantageDialogButton
                  title={<ListIcon />}
                  color="inherit"
                  declineTitle="Close"
                  dialogTitle="Planning Skills"
                  maxWidth="sm"
                  declineProps={{sx: {width: '100%'}}}
                  fullWidth
                  iconButton
                  disableAccept
                >
                  <List disablePadding>
                    {skills
                      .filter((skill, index) => skills.indexOf(skill) === index)
                      ?.map((skill) => (
                        <ListItem key={skill}>
                          <ListItemText primary={skill} />
                        </ListItem>
                      ))}
                  </List>
                </VantageDialogButton>
              </>
            );
          },
        },
      ],
      disableToolbar: true,
      disablePagination: true,
      enableFilter: true,
      identifier: 'PLANNING_BOARD_PLANNING_MASTER_TRIPS',
    }),
    [assignedOrders, classes]
  );

  if (loadingCommitBookingTrips) return <Loader text="Committing trips..." />;

  if (loadingMasterTrips) return <Loader text="Fetching booking trips..." />;

  return (
    <Paper elevation={0}>
      <TemplateTable
        config={config}
        currentPage={1}
        list={list}
        elevation={0}
        selection={bookingMasterTripsToCommit}
        onSelectionChange={setBookingMasterTripsToCommit}
        onFiltersChange={onFiltersChange}
        filters={filters}
        scrollable
      />
    </Paper>
  );
};
