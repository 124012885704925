import React from 'react';
import Card from '@mui/material/Card';
import Map from '../../map/map';
import {
  PlanningMasterTrip,
  PlanningSolution,
} from '@onroadvantage/onroadvantage-api';
import {IFormTrip} from '../planningSolution/PlanningSolutionForm';
import {timelineDisplayStore} from '../../../stores/mobxStores';

interface Props {
  externalLayers: any;
  planningMasterTrip?: PlanningMasterTrip;
  planningMasterTripFormik?: IFormTrip;
  planningSolution?: PlanningSolution;
  route: any;
}

const PlanningTripRouteMap = ({
  externalLayers,
  planningMasterTrip,
  planningMasterTripFormik,
  planningSolution,
  route,
}: Props) => {
  const {timelineSliderTime} = timelineDisplayStore;

  return (
    <Card style={{width: '100%', height: '60vh'}}>
      <Map
        route={route}
        externalLayers={externalLayers}
        planningMasterTrip={planningMasterTrip}
        planningMasterTripFormik={planningMasterTripFormik}
        planningSolution={planningSolution}
        timelineSliderTime={timelineSliderTime}
      />
    </Card>
  );
};

export default PlanningTripRouteMap;
