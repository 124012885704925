import React from 'react';
import {MomentService} from '../../../../service';
import {Phone} from '@mui/icons-material';
import {Divider, Theme, Typography, Box, Fab} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import custImg from '../../../../img/background/popupbackground.png';
import truck from '../../../../img/svg/truck.svg';
import PopupBase from './PopupBase';
import {
  CriticalEventDump,
  CriticalEventActivity,
  OperationalEventActivity,
  OperationalEventDump,
} from '@onroadvantage/onroadvantage-api';

const useEventPopupStyles = makeStyles((theme: Theme) =>
  createStyles({
    backgroundTop: {
      backgroundImage: `url(${custImg})`,
    },
    textWrapStyle: {},
    speedBox: {
      BoxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.24)',
      background: 'linear-gradient(90deg,#00B7A3 0%,#008270 100%)',
      borderRadius: '12px',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    bottomFab: {
      background: 'linear-gradient(90deg, #00B7A3 0%, #008270 100%)',
      transform: 'rotate(-180deg)',
      boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.24)',
    },
    bottomFabDisabled: {
      background: 'linear-gradient(90deg, #999 0%, #555 100%)',
      transform: 'rotate(-180deg)',
      opacity: 0.3,
    },
    divider: {
      width: '1px',
      background: '#fff',
      marginTop: '10px',
      marginBottom: '10px',
    },
    backgroundCard: {
      borderRadius: '12px 12px 12px 12px',
      width: '100%',
      boxShadow: 'none',
    },
    headingColor: {
      color: '#767676',
    },
    fab: {
      top: theme.spacing(1),
      right: theme.spacing(1),
      position: 'absolute',
      boxShadow: 'none',

      width: 36,
    },
    eventDesc: {
      fontWeight: 400,
      fontSize: 12,
      paddingLeft: 10,
    },
    removeMargin: {
      margin: 0,
      padding: 0,
    },
    spacing: {
      margin: theme.spacing(2),
    },
    parentScrollDiv: {
      overflow: 'auto',
      height: '500px',
      borderRadius: '12px 12px 12px 12px',
    },
  })
);

interface Props {
  event: CriticalEventDump | OperationalEventDump;
}

export default function EventPopup(props: Props) {
  const classes = useEventPopupStyles();
  const {event} = props;
  const [numberMatch, setNumberMatch] = React.useState<RegExpMatchArray | null>(
    null
  );
  const [voicekitNumber] = React.useState<string | undefined | null>(
    event.vehicle?.voicekitNumber
  );
  const eventDesc = `${
    event.type && event.type.name ? event.type.name : event.description
  }`;
  const eventTitle = `${
    event.type && event.type.provider && event.type.provider.name
      ? `${event.type.provider.name}: ${eventDesc}`
      : ''
  }`;

  const assignmentActivities = React.useMemo(
    () =>
      event.activities?.filter(
        (e: unknown) => 'action' in e && e.action === 'AssignmentChange'
      ),
    [event.activities]
  );

  React.useEffect(() => {
    setNumberMatch(
      event.vehicle?.description
        ? event.vehicle.description.match(
            /[\+]?[0-9]{4}?[-\s\.]?[0-9]{5}[-\s\.]?[0-9]{5,6}/
          )
        : null
    );
  }, [event.vehicle]);

  const renderVehicleDescription = () => {
    if (numberMatch === null)
      return event.vehicle && event.vehicle.description
        ? event.vehicle.description
        : 'No Vehicle Data';
    const leftToNumber = event.vehicle?.description?.slice(
      0,
      numberMatch['index']
    );
    const rightToNumber = event.vehicle?.description?.slice(
      numberMatch['index'] ? numberMatch['index'] + numberMatch[0].length : 0,
      event.vehicle.description.length
    );
    return (
      <>
        {leftToNumber}
        <a href={`tel:${numberMatch[0]}`}>{numberMatch[0]}</a>
        {rightToNumber}
      </>
    );
  };

  return (
    <PopupBase
      topIconType="warning"
      description={eventTitle}
      backgroundImage={custImg}
      title={eventDesc}
      mainIcon={truck}
      left={{header: 'Status', data: event.status, type: 'text'}}
      right={{header: 'Speed', data: `${event.velocity} km/h`, type: 'text'}}
    >
      <Box mb={1}>
        <Typography variant="h5" className={classes.headingColor}>
          Driver name &amp; Number:{' '}
        </Typography>
        <Typography variant="h6" style={{fontWeight: 700}}>
          {event.driver
            ? `${event.driver.firstname} (${
                event.driver.contactNumber
                  ? event.driver.contactNumber
                  : 'No Data'
              })`
            : 'No Driver Assigned'}
        </Typography>
      </Box>

      <Box mb={1}>
        <Typography variant="h5" className={classes.headingColor}>
          Position
        </Typography>
        <Typography variant="h6">
          {event.latitude}, {event.longitude}
        </Typography>
      </Box>
      <Box mb={1}>
        <Typography variant="h5" className={classes.headingColor}>
          Fleet Number
        </Typography>
        <Typography variant="h6">{renderVehicleDescription()}</Typography>
      </Box>
      <Divider />
      <Box mb={1} mt={1}>
        <Typography style={{fontWeight: 'bold'}} variant="h4">
          Activity
        </Typography>
      </Box>
      <Box mb={1}>
        <Typography variant="h5" className={classes.headingColor}>
          Event Occurred:
        </Typography>
        <Typography variant="h6">
          {MomentService.displayTimestamp(event.eventDate)}
        </Typography>
      </Box>
      <Box mb={1}>
        <Typography variant="h5" className={classes.headingColor}>
          External Received:
        </Typography>
        <Typography variant="h6">
          {MomentService.displayTimestamp(event.externalReceivedDate)}
        </Typography>
      </Box>
      <Box mb={1}>
        <Typography variant="h5" className={classes.headingColor}>
          Vantage Received:
        </Typography>
        <Typography variant="h6">
          {MomentService.displayTimestamp(event.createdAt)}
        </Typography>
      </Box>

      <Divider />

      <Box mb={1}>
        <Typography variant="h5" className={classes.headingColor}>
          Assignment Changes
        </Typography>
        <Typography variant="h6" style={{fontWeight: 700}}>
          Assigned to {event.assignedTo ? event.assignedTo.email : 'no one'}
        </Typography>
        {assignmentActivities?.map(
          (e: CriticalEventActivity | OperationalEventActivity) => {
            return (
              <React.Fragment key={e.id}>
                <Typography variant="h6">{e.createdBy?.email}</Typography>
                <Typography variant="h6">
                  {MomentService.displayTimestamp(e.createdAt)}
                </Typography>
              </React.Fragment>
            );
          }
        )}
      </Box>
      <Divider />

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p={1}
        mb={1}
      >
        <Box display="flex" flexDirection="column">
          <Typography variant="h5" style={{fontWeight: 700}}>
            Contact Number
          </Typography>
          <Typography variant="h6">{event.driver?.contactNumber}</Typography>
        </Box>
        <Fab
          disabled={!voicekitNumber}
          size="medium"
          className={
            voicekitNumber ? classes.bottomFab : classes.bottomFabDisabled
          }
          href={`tel:${event.vehicle?.voicekitNumber}`}
        >
          <Phone
            aria-disabled={!voicekitNumber}
            style={{color: '#fff', transform: 'rotate(-180deg)'}}
          />
        </Fab>
      </Box>
    </PopupBase>
  );
}
