import React from 'react';
import {Stack} from '@mui/material';
import {TripSetupSummaryStepStops} from './TripSetupSummaryStepStops';
import {TripSetupSummaryStepDetails} from './tripSetupSummaryStepDetails';

export const TripSetupSummaryStep = () => {
  return (
    <Stack spacing={2}>
      <TripSetupSummaryStepDetails />
      <TripSetupSummaryStepStops />
    </Stack>
  );
};
