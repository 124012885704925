import React, {useState} from 'react';
import {Tab, Tabs} from '@mui/material';
import {useTemplateTabControllerStyles} from '../../factory/template/tabs/TemplateTabs.style';
import {NotificationConfigTaskList} from './notificationConfigTask';
import {NotificationConfigWorkflowTaskList} from './notificationConfigWorkflowTask';
const NotificationConfigTaskTriggers: React.FC = () => {
  const classes = useTemplateTabControllerStyles();
  const [tabValue, setTabValue] = useState<number>(0);

  const handleTabChange = (event: React.SyntheticEvent, value: number) => {
    setTabValue(value);
  };

  return (
    <>
      <Tabs
        className={classes.tabs}
        value={tabValue}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab classes={{root: classes.tab}} label="Contract Task Triggers" />
        <Tab classes={{root: classes.tab}} label="Workflow Task Triggers" />
      </Tabs>
      {tabValue === 0 && <NotificationConfigTaskList />}
      {tabValue === 1 && <NotificationConfigWorkflowTaskList />}
    </>
  );
};

export default NotificationConfigTaskTriggers;
