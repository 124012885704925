import React from 'react';
import {Divider, Stack, Typography} from '@mui/material';

interface TripSummaryListItem {
  label: string | undefined | null;
  value: string | undefined | null;
}

interface TripSummaryListProps {
  items: TripSummaryListItem[];
  action?: React.ReactNode;
  disableBottomDivider?: boolean;
  title?: string | undefined;
}

export const TripSummaryList: React.FC<TripSummaryListProps> = ({
  items,
  action,
  disableBottomDivider,
  title,
}) => {
  return (
    <>
      <Stack p={0.5}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {title ? (
            <Typography variant="subtitle1" fontWeight={600}>
              {title}
            </Typography>
          ) : null}
          {action}
        </Stack>
        {items.map(({label, value}, index) => (
          <Stack
            key={label ?? value ?? index}
            direction="row"
            justifyContent="space-between"
            flexWrap="wrap"
            alignItems="flex-start"
          >
            {label ? (
              <Typography whiteSpace="nowrap">{label}:</Typography>
            ) : null}
            <Typography whiteSpace="nowrap">{value ?? '-'}</Typography>
          </Stack>
        ))}
      </Stack>
      {!disableBottomDivider ? <Divider /> : null}
    </>
  );
};
