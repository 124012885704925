import React from 'react';
import {DriverDocumentContext} from './DriverDocumentContext';
import {useParams} from 'react-router-dom';

interface IParams {
  driverDocumentId?: string;
}

export const DriverDocumentIdHandler: React.FC = () => {
  const {setDriverDocumentId} = React.useContext(DriverDocumentContext);

  const {driverDocumentId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = driverDocumentId ? parseInt(driverDocumentId) : undefined;
    setDriverDocumentId(parsedId);

    return () => setDriverDocumentId(undefined);
  }, [driverDocumentId, setDriverDocumentId]);

  return null;
};
