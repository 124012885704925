import React from 'react';
import * as Yup from 'yup';
import {useFormikContext} from 'formik';
import {Button, Collapse, Stack} from '@mui/material';
import {Check} from '@mui/icons-material';
import {Listing} from '@onroadvantage/onroadvantage-api';
import {
  multipleListingSchema,
  NUMBER_ERROR_MESSAGE,
} from '../../../factory/template';
import {EventsPaneContext} from '../EventsPaneContext';
import {
  FormikAutocompleteWithListing,
  FormikSelect,
  FormikTextField,
} from '../../formik';
import {EventsPaneSearchFormActions} from './EventsPaneSearchFormActions';
import {useEventsPaneSearchStyles} from './EventsPaneSearch.style';

export interface IEventsPaneSearchForm {
  search?: string | undefined;
  eventType?: Listing[] | null | undefined;
  vehicle?: Listing[] | null | undefined;
  vehicleGroup?: Listing[] | null | undefined;
  eventId?: string | number | undefined;
  driver?: Listing[] | null | undefined;
  contract?: Listing[] | null | undefined;
  isAdvanced: boolean;
  dateRangeUnit: 'days' | 'hours';
  dateRangeValue: number;
  enableCriticalSearch: boolean;
  enableOperationalSearch: boolean;
}

export const eventsPaneSearchFormSchema: Yup.SchemaOf<IEventsPaneSearchForm> =
  Yup.object({
    search: Yup.string(),
    eventId: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
    enableCriticalSearch: Yup.boolean().required('required'),
    enableOperationalSearch: Yup.boolean().required('Required'),
    eventType: multipleListingSchema,
    contract: multipleListingSchema.nullable(),
    driver: multipleListingSchema.nullable(),
    vehicle: multipleListingSchema.nullable(),
    vehicleGroup: multipleListingSchema.nullable(),
    isAdvanced: Yup.boolean().required('Required'),
    dateRangeUnit: Yup.mixed<'days' | 'hours'>()
      .required('Required')
      .oneOf(['days', 'hours']),
    dateRangeValue: Yup.number()
      .typeError(NUMBER_ERROR_MESSAGE)
      .min(0, 'Min value 0.')
      .when('dateRangeUnit', {
        is: 'days',
        then: (schema) => schema.max(7, '7 days is the max.'),
        otherwise: (schema) => schema.max(24, '24 hours is the max.'),
      })
      .required('Required'),
  });

export const EventsPaneSearchForm: React.FC = () => {
  const classes = useEventsPaneSearchStyles();
  const {getFieldMeta, getFieldHelpers, handleSubmit, isSubmitting} =
    useFormikContext<IEventsPaneSearchForm>();
  const {value: isAdvanced} = getFieldMeta<boolean>('isAdvanced');
  const {value: enableCriticalSearch} = getFieldMeta<boolean>(
    'enableCriticalSearch'
  );
  const {setValue: setEnableCriticalSearch} = getFieldHelpers<boolean>(
    'enableCriticalSearch'
  );
  const {value: enableOperationalSearch} = getFieldMeta<boolean>(
    'enableOperationalSearch'
  );
  const {setValue: setEnableOperationalSearch} = getFieldHelpers<boolean>(
    'enableOperationalSearch'
  );
  const {collapseSearchForm, tabValue} = React.useContext(EventsPaneContext);

  const dateRangeUnitOptions: IEventsPaneSearchForm['dateRangeUnit'][] =
    React.useMemo(() => ['days', 'hours'], []);

  const handleToggleCriticalSearches = React.useCallback(() => {
    setEnableCriticalSearch(!enableCriticalSearch);
    handleSubmit();
  }, [enableCriticalSearch, handleSubmit, setEnableCriticalSearch]);

  const handleToggleOperationalSearches = React.useCallback(() => {
    setEnableOperationalSearch(!enableOperationalSearch);
    handleSubmit();
  }, [enableOperationalSearch, handleSubmit, setEnableOperationalSearch]);

  const isHistoryTab = tabValue === 'Closed';

  return (
    <>
      <Collapse in={!collapseSearchForm}>
        <div className={classes.chipActions}>
          <Button
            size="small"
            variant="contained"
            color={enableCriticalSearch ? 'secondary' : 'inherit'}
            classes={{
              root: enableCriticalSearch
                ? classes.chipButtonSelected
                : classes.chipButton,
              startIcon: classes.startIcon,
            }}
            startIcon={enableCriticalSearch ? <Check /> : undefined}
            onClick={handleToggleCriticalSearches}
            disabled={isSubmitting}
          >
            Critical
          </Button>
          <Button
            size="small"
            variant="contained"
            color={enableOperationalSearch ? 'secondary' : 'inherit'}
            classes={{
              root: enableOperationalSearch
                ? classes.chipButtonSelected
                : classes.chipButton,
              startIcon: classes.startIcon,
            }}
            startIcon={enableOperationalSearch ? <Check /> : undefined}
            onClick={handleToggleOperationalSearches}
            disabled={isSubmitting}
          >
            Operational
          </Button>
        </div>
        <Collapse in={!isAdvanced && !isHistoryTab}>
          <FormikTextField
            className={classes.field}
            name="search"
            placeholder="Enter a value"
            label="Search"
            fullWidth
            disabled={isSubmitting}
          />
        </Collapse>
        <Collapse in={isAdvanced || isHistoryTab}>
          <FormikAutocompleteWithListing
            className={classes.field}
            name="eventType"
            placeholder="Enter or select a value"
            label="Event Type"
            model={
              enableCriticalSearch && enableOperationalSearch
                ? 'WebEventType'
                : enableCriticalSearch
                ? 'WebCriticalEventType'
                : enableOperationalSearch
                ? 'WebOperationalEventType'
                : 'WebEventType'
            }
            multiple
            disabled={isSubmitting}
          />
          <FormikAutocompleteWithListing
            className={classes.field}
            name="vehicle"
            placeholder="Enter or select a value"
            label="Vehicle Reg/Fleet Number"
            model="Vehicle"
            multiple
            disabled={isSubmitting}
          />
          <FormikAutocompleteWithListing
            className={classes.field}
            name="vehicleGroup"
            placeholder="Enter or select a value"
            label="Vehicle Group"
            model="VehicleGroup"
            multiple
            disabled={isSubmitting}
          />
          <FormikAutocompleteWithListing
            className={classes.field}
            name="driver"
            placeholder="Enter or select a value"
            label="Driver"
            model="Driver"
            multiple
            disabled={isSubmitting}
          />
          <FormikAutocompleteWithListing
            className={classes.field}
            name="contract"
            placeholder="Enter or select a value"
            label="Contract"
            model="Contract"
            multiple
            disabled={isSubmitting}
          />
          <FormikTextField
            className={classes.field}
            name="eventId"
            placeholder="Enter a value"
            label="Event ID"
            type="number"
            fullWidth
            disabled={
              (enableCriticalSearch && enableOperationalSearch) || isSubmitting
            }
          />
          <Stack direction="row" spacing={1} className={classes.field}>
            <FormikTextField
              className={classes.dateRangeValueField}
              name="dateRangeValue"
              type="number"
              label="Date range"
            />
            <FormikSelect
              className={classes.dateRangeUnitField}
              name="dateRangeUnit"
              options={dateRangeUnitOptions}
              label="Unit"
            />
          </Stack>
        </Collapse>
        <EventsPaneSearchFormActions />
      </Collapse>
    </>
  );
};
