import React from 'react';
import {EventsPaneContext} from '../EventsPaneContext';
import {Card, CardContent} from '@mui/material';
import {useEventsPaneEmptyListStyles} from './EventsPaneEmptyList.style';
import clsx from 'clsx';

export const EventsPaneEmptyList: React.FC = () => {
  const classes = useEventsPaneEmptyListStyles();
  const {didErrorOccur} = React.useContext(EventsPaneContext);

  if (didErrorOccur)
    return (
      <div>
        <Card className={clsx(classes.root, classes.error)}>
          <CardContent>
            <strong>An error occurred</strong>
          </CardContent>
        </Card>
      </div>
    );

  return (
    <div>
      <Card className={classes.root}>
        <CardContent>
          <strong>No events found</strong>
        </CardContent>
      </Card>
    </div>
  );
};
