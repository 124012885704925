import React from 'react';
import * as Yup from 'yup';
import {Listing, Node} from '@onroadvantage/onroadvantage-api';
import {ISiteDetailsForm} from '../SiteContext';
import {
  FormikAutocompleteWithListing,
  FormikSwitch,
  FormikTextField,
  FormikTimePicker,
} from '../../formik';
import {LuxonService} from '../../../service';
import {multipleListingSchema} from '../../../factory/template';

const listingSchema: Yup.SchemaOf<Listing> = Yup.object({
  label: Yup.string().default(undefined),
  metaData: Yup.string().default(undefined),
  value: Yup.number().default(undefined),
});

const validTimeMinutes = {
  match: /^\d{1,3}$/,
  message: 'Please enter a time in minutes',
};

export const siteDetailsSchema: Yup.SchemaOf<ISiteDetailsForm> = Yup.object({
  name: Yup.string().required('Required'),
  externalReference: Yup.string().required('Required'),
  address: Yup.string(),
  prohibitAfterHoursProcessing: Yup.boolean(),
  latitude: Yup.string()
    .required('Required')
    .matches(
      /^([+\-])?(?:90(?:\.0{1,20})?|(?:[0-9]|[1-8][0-9])(?:\.[0-9]{1,20})?)$/,
      'This is should be a decimal number between -90 and 90'
    ),
  longitude: Yup.string()
    .required('Required')
    .matches(
      /^([+\-])?(?:180(?:\.0{1,20})?|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:\.[0-9]{1,20})?)$/,
      'This is should be a decimal number between -180 and 180'
    ),
  openTime: Yup.date().required('Required'),
  closeTime: Yup.date().required('Required'),
  loadingTimeInMinutes: Yup.string()
    .required('Required')
    .matches(validTimeMinutes.match, validTimeMinutes.message),
  unloadingTimeInMinutes: Yup.string()
    .required('Required')
    .matches(validTimeMinutes.match, validTimeMinutes.message),
  processingTimeInMinutes: Yup.string()
    .required('Required')
    .matches(validTimeMinutes.match, validTimeMinutes.message),
  nodeType: listingSchema.nullable().required('Required'),
  planningSkills: multipleListingSchema,
});

export const getSiteInitialValues = (
  site?: Node | null
): ISiteDetailsForm | undefined => {
  if (site) {
    return {
      name: site.name ?? '',
      externalReference: site.externalReference ?? '',
      prohibitAfterHoursProcessing: site.prohibitAfterHoursProcessing,
      address: site.address ?? '',
      latitude: site.latitude.toString() ?? '',
      longitude: site.longitude.toString() ?? '',
      openTime:
        (site.timeWindows &&
          site.timeWindows.length > 0 &&
          LuxonService.fromServerTimeToLocalTimeDate(
            site.timeWindows[0].openTime
          )) ||
        new Date(),
      closeTime:
        (site.timeWindows &&
          site.timeWindows.length > 0 &&
          LuxonService.fromServerTimeToLocalTimeDate(
            site.timeWindows[0].closeTime
          )) ||
        new Date(),
      loadingTimeInMinutes: site.loadingTimeInMinutes?.toString() ?? '',
      unloadingTimeInMinutes: site.unloadingTimeInMinutes?.toString() ?? '',
      processingTimeInMinutes: site.processingTimeInMinutes?.toString() ?? '',
      nodeType: {value: site.nodeTypeId, label: site.nodeType?.name ?? ''},
      planningSkills: site.skills?.map(({id, name, description}) => ({
        value: id,
        label: `${name} - ${description}`,
      })),
    };
  }
  return undefined;
};

interface SiteDetailsFormProps {
  isAdd?: boolean;
}

export const SiteDetailsForm: React.FC<SiteDetailsFormProps> = () => {
  return (
    <>
      <FormikTextField
        name="name"
        placeholder="Enter the site name"
        label="Name"
        fullWidth
      />
      <FormikTextField
        name="externalReference"
        placeholder="Enter the external reference"
        label="External Reference"
        fullWidth
      />
      <FormikTextField
        name="address"
        placeholder="e.g. 1 Newtown Drive, Newlands, Cape Town"
        label="Physical Address"
        InputLabelProps={{shrink: true}}
        fullWidth
      />
      <FormikTextField
        name="latitude"
        placeholder="Enter the site latitude coordinate"
        label="Latitude"
        fullWidth
      />
      <FormikTextField
        name="longitude"
        placeholder="Enter the site longitude coordinate"
        label="Longitude"
        fullWidth
      />
      <FormikAutocompleteWithListing
        name="nodeType"
        model="NodeType"
        label="Location Type"
      />
      <FormikTimePicker
        name="openTime"
        placeholder="Enter the open time"
        label="Open Time"
        InputLabelProps={{shrink: true}}
        disableDefaultValue
        fullWidth
      />
      <FormikTimePicker
        name="closeTime"
        placeholder="Enter the close time"
        label="Close Time"
        InputLabelProps={{shrink: true}}
        disableDefaultValue
        fullWidth
      />
      <FormikTextField
        name="loadingTimeInMinutes"
        placeholder="e.g. 30"
        label="Loading Time"
        fullWidth
      />
      <FormikTextField
        name="unloadingTimeInMinutes"
        placeholder="e.g. 30"
        label="Unloading Time"
        fullWidth
      />
      <FormikTextField
        name="processingTimeInMinutes"
        placeholder="e.g. 15"
        label="Processing Time"
        fullWidth
      />
      <FormikAutocompleteWithListing
        name="planningSkills"
        placeholder="Enter and select the site skills"
        label="Planning Skills"
        model="PlanningSkill"
        multiple
      />
      <FormikSwitch
        name="prohibitAfterHoursProcessing"
        label="Prohibit After Hours Processing"
      />
    </>
  );
};
