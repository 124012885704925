import ConfigService from './ConfigService/ConfigService';
import ResponseService from './ResponseService';
import {encodeUrl} from './Util';
import {authStore} from '../store';

const serverUrl = ConfigService.serverUri;
const queryUrl = `${serverUrl}/api`;

const ListingService = {
  query: async (modelName, params = {}) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(
      encodeUrl(`${queryUrl}/${modelName}/listing`, params),
      {
        method: 'GET',
        mode: 'cors',
        headers,
      }
    );
    return ResponseService.checkStatus(response);
  },
  prepareSelectOptions: async (
    items,
    schema = {value: 'id', label: 'name', secondary: ''}
  ) =>
    items.map((item) => {
      if (schema.secondary !== '') {
        return {
          value: item[schema.value],
          label: `${item[schema.label]} - ${item[schema.secondary]}`,
        };
      }
      return {
        value: item[schema.value],
        label: `${item[schema.label]}`,
      };
    }),
};

export default ListingService;
