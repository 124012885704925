import React from 'react';
import {
  TemplateTable,
  TemplateTableConfig,
  TOnInlineAdd,
} from '../../../../factory/template';
import {TripDebriefContext} from '../../TripDebriefContext';
import {
  MasterTripDebriefTripStopActivitySnapshot,
  MasterTripDebriefTripStopSnapshot,
} from '@onroadvantage/onroadvantage-api';
import {useAppNotifications} from '../../../../contexts';
import {tripStopApi} from '../../../../api';
import RoleService from '../../../../service/RoleService';

export const TripDebriefSummaryStopCommentList: React.FC = () => {
  const notify = useAppNotifications();
  const {loadTripDebrief, tripStop, tripStopId} =
    React.useContext(TripDebriefContext);
  /**
   *  Destructure tripStop, need to add || ({} as MasterTripDebriefSnapshot) for typescript, since
   *  tripStop is nullable.
   */
  const {activities} = tripStop || ({} as MasterTripDebriefTripStopSnapshot);
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const list: MasterTripDebriefTripStopActivitySnapshot[] = React.useMemo(
    () =>
      activities
        ?.sort((a, b) => (!a.id || !b.id ? 0 : b.id - a.id))
        .filter((row) => row.status !== 'Deleted') ?? [],
    [activities]
  );

  const [config] = React.useState<
    TemplateTableConfig<MasterTripDebriefTripStopActivitySnapshot>
  >({
    columns: [
      {name: 'value', label: 'Value', type: 'string', enableEditing: true},
      {name: 'createdAt', label: 'Created At', type: 'datetime'},
      {
        name: 'createdBy',
        label: 'Created By',
        type: 'string',
        getValue: ({createdBy}) => createdBy?.email,
      },
    ],
    disablePagination: true,
    disableToolbar: true,
    identifier: 'TRIP_DEBRIEF_SUMMARY_STOP_COMMENT_LIST',
  });

  const handleInlineAdd = React.useCallback<TOnInlineAdd>(
    async (changes) => {
      if (changes && tripStopId) {
        setSubmitting(true);
        try {
          const newValue = changes[0].value;

          await tripStopApi.apiTripStopTripStopIdPost({
            tripStopId,
            body: {
              activities: [
                {
                  action: 'Comment',
                  value: newValue,
                },
              ],
            },
          });

          notify('success', 'Added comment');

          await loadTripDebrief();
        } catch (e) {
          notify('error', e.message ?? 'Failed to add comment');
        } finally {
          setSubmitting(false);
        }
      }
    },
    [loadTripDebrief, notify, tripStopId]
  );

  if (!tripStop) return null;

  return (
    <TemplateTable
      config={config}
      currentPage={1}
      list={list}
      onInlineAdd={
        RoleService.hasPermission('Add TripStopComment', 'Add')
          ? handleInlineAdd
          : undefined
      }
      loading={submitting}
    />
  );
};
