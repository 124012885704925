import React from 'react';
import {PlanningSkillNodeMinimal} from '@onroadvantage/onroadvantage-api';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../../factory/template';
import {
  PlanningSkillNodeListContext,
  usePlanningSkillNodeListContext,
} from './PlanningSkillNodeListContext';
import {PlanningSkillContext} from '../PlanningSkillContext';

export const PlanningSkillNodeList: React.FC = () => {
  const {planningSkill} = React.useContext(PlanningSkillContext);
  const {loadList, cleanupList} = usePlanningSkillNodeListContext();

  const [config] = React.useState<
    TemplateTableConfig<PlanningSkillNodeMinimal>
  >({
    columns: [
      {
        name: 'name',
        label: 'Name',
        type: 'string',
        enableEditing: true,
        enableAutocomplete: true,
        enableMulti: true,
        model: 'Node',
      },
      {name: 'externalReference', label: 'External Reference', type: 'string'},
    ],
    enableFilter: true,
    enableSort: true,
    deleteMessage: (row) => ({
      title: 'Planning Skill Node',
      items: {
        PlanningSkill: planningSkill?.name,
        Name: row.name,
        ExternalReference: row.externalReference,
      },
    }),
    identifier: 'PLANNING_SKILL_NODE_LIST',
  });

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [cleanupList, loadList]);

  return (
    <TemplateTableWrapper
      config={config}
      context={PlanningSkillNodeListContext}
    />
  );
};
