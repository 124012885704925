import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from '@mui/material';

const EventsPaneTabsStyles = (theme: Theme) =>
  createStyles({
    appBarTablet: {marginTop: -theme.spacing(1)},
    container: {height: '100%'},
    tabs: {flex: 1},
    toolbar: {alignItems: 'stretch'},
  });

export const useEventsPaneTabsStyles = makeStyles(EventsPaneTabsStyles, {
  name: 'EventsPaneTabsStyles',
});
