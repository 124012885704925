import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

const InputStyles = (theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    input: {marginTop: theme.spacing(0.5), paddingBottom: theme.spacing(2)},
    inputError: {paddingBottom: 0},
    timeIcon: {
      position: 'absolute',
      right: theme.spacing(2),
      pointerEvents: 'none',
    },
  });

export const useInputStyles = makeStyles(InputStyles, {name: 'InputStyles'});
