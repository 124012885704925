import React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import TimerIcon from '@mui/icons-material/Timer';
import MomentService from '../../service/MomentService';

class TimelinePlayerCard extends React.Component {
  render() {
    const {timelineSliderTime} = this.props;
    return (
      <Card style={{marginRight: 10, flexGrow: 1, flex: 1}}>
        <CardHeader
          title="Trip Timeline Playback"
          avatar={
            <Avatar>
              <TimerIcon />
            </Avatar>
          }
        />
        <CardContent>
          <div>{MomentService.displayTimestamp(timelineSliderTime)}</div>
        </CardContent>
      </Card>
    );
  }
}

export default TimelinePlayerCard;
