import React from 'react';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../factory/template';
import {BookingSlot} from '@onroadvantage/onroadvantage-api';
import {VehicleContext} from './VehicleBookingAvailabilityContext';

interface BookingSlotWithId extends BookingSlot {
  id?: string;
}

export const VehicleBookingAvailabilityList: React.FC = () => {
  const [config] = React.useState<TemplateTableConfig<BookingSlotWithId>>({
    columns: [
      {
        name: 'matchViability',
        label: 'Match Viability',
        type: 'string',
      },
      {
        name: 'vehicle.registrationNumber' as 'vehicle',
        label: 'Match Viability',
        type: 'string',
        getValue: ({vehicle}) => vehicle.registrationNumber,
      },
      {
        name: 'vehicle.fleetNumber' as 'vehicle',
        label: 'Match Viability',
        type: 'string',
        getValue: ({vehicle}) => vehicle.fleetNumber,
      },
      {
        name: 'startTime',
        label: 'Booking Type',
        type: 'datetime',
      },
      {
        name: 'endTime',
        label: 'Booking Type',
        type: 'datetime',
      },
      {
        name: 'tripDistance',
        label: 'Trip Distance',
        type: 'string',
      },
      {
        name: 'tripDuration',
        label: 'Trip Duration',
        type: 'string',
      },
      {
        name: 'startingStop.node.name' as 'startingStop',
        label: 'Starting Stop Node',
        type: 'string',
        // getValue: ({startingStop}) =>
        //   startingStop?.orders && startingStop.orders[0]?.upliftPoint.name,
      },
      {
        name: 'vehicle.type.skills' as 'vehicle',
        label: 'Skills',
        type: 'string',
        getValue: ({vehicle}) =>
          vehicle.type?.skills?.map((skill) => skill.name),
      },
    ],
    enableFilter: true,
    enableSort: true,
    identifier: 'VEHICLE_BOOKING_AVAILABILITY_LIST',
  });
  return <TemplateTableWrapper config={config} context={VehicleContext} />;
};
