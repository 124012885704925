import React from 'react';
import {
  debounce,
  TextField,
  Autocomplete as MuiAutoComplete,
} from '@mui/material';
import {Listing} from '@onroadvantage/onroadvantage-api';
import {useInternalTableContext} from '../../TemplateTable';
import {TableEditRow} from '@devexpress/dx-react-grid-material-ui';
import {listingApi} from '../../../../../api';

export const TableAutoCompleteField: React.FC<TableEditRow.CellProps> = (
  props
) => {
  const {config, errors} = useInternalTableContext();
  const columnConfig = React.useMemo(
    () =>
      config.columns.find(
        (configColumn) => configColumn.name === props.column.name
      ),
    [config.columns, props.column.name]
  );
  const [value, setValue] = React.useState<Listing | Listing[] | undefined>();
  const [localLoading, setLocalLoading] = React.useState(false);
  const [listing, setListing] = React.useState<Listing[]>([]);

  const cellError = React.useMemo(
    () =>
      errors.find((error) => {
        const errorName = error.name?.toString()?.split('.');

        return errorName
          ? errorName[0] === props.column.name &&
              (error.id === props.row.id ||
                (!props.row && error.id === undefined))
          : false;
      }),
    [errors, props.column.name, props.row]
  );

  const getListing = React.useCallback(
    async (filter?: string) => {
      setLocalLoading(true);
      try {
        if (columnConfig?.options) {
          setListing(columnConfig.options);
        } else if (
          columnConfig?.model &&
          typeof columnConfig.model !== 'string' &&
          columnConfig.model.length > 0
        ) {
          const responseItems: Listing[] = [];
          for (const model of columnConfig.model) {
            const response = await listingApi.apiListingGet({
              model,
              query: filter,
            });
            if (columnConfig.filterListingsByLabel) {
              responseItems.push(
                ...(response.items?.filter(
                  ({label}) =>
                    label &&
                    !columnConfig.filterListingsByLabel?.includes(label)
                ) ?? [])
              );
            } else {
              responseItems.push(...(response.items ?? []));
            }
          }
          setListing(responseItems);
        } else if (typeof columnConfig?.model === 'string') {
          const response = await listingApi.apiListingGet({
            model: columnConfig?.model,
            query: filter,
          });

          if (columnConfig.filterListingsByLabel) {
            setListing(
              response.items?.filter(
                ({label}) =>
                  label && !columnConfig.filterListingsByLabel?.includes(label)
              ) ?? []
            );
          } else if (columnConfig.model === 'TaskType') {
            /** Remove this hardcoded filtering when we use more than the Daily and Adhoc tasks */
            setListing(
              response.items?.filter(
                ({label}) =>
                  label?.toLowerCase() === 'daily' ||
                  label?.toLowerCase() === 'adhoc'
              ) ?? []
            );
          } else {
            setListing(response.items ?? []);
          }
        }
      } finally {
        setLocalLoading(false);
      }
    },
    [columnConfig]
  );

  const getListingDebounce = React.useRef(debounce(getListing, 600)).current;

  const getListingByLabel = React.useCallback(
    (label: string | undefined) => {
      if (!label) return undefined;

      const findItemInCurrentListing = listing.find(
        (listingItem) => label === listingItem?.label
      );

      return findItemInCurrentListing ?? {label, value: label};
    },
    [listing]
  );

  const onInputChangeHandler = React.useCallback(
    async (_event: React.ChangeEvent<unknown>, filter: string) => {
      await getListingDebounce(filter);
    },
    [getListingDebounce]
  );

  const onChangeHandler = React.useCallback(
    (_event: unknown, value: Listing | Listing[] | null) => {
      setValue(value ?? undefined);
      props.onValueChange(value);
    },
    [props]
  );

  const initialValue = React.useMemo(() => {
    const columnValue = columnConfig?.getFieldValue
      ? columnConfig.getFieldValue(props.row)
      : props.value;

    if (columnConfig?.enableMulti) {
      if (Array.isArray(columnValue)) {
        return columnValue;
      }
      if (columnValue === '') {
        return [];
      }
      if (typeof columnValue === 'string') {
        return columnValue
          ?.split(',')
          .map((val: any) => getListingByLabel(val));
      }
      return [];
    } else {
      if (typeof columnValue === 'object') {
        return columnValue;
      }
      return getListingByLabel(columnValue);
    }
  }, [columnConfig, getListingByLabel, props.row, props.value]);

  React.useEffect(() => {
    getListing();
    return () => setListing([]);
  }, [getListing]);

  const enableColumn = React.useMemo(() => {
    if (props.row.id) {
      if (columnConfig?.enableEditing !== undefined) {
        if (typeof columnConfig.enableEditing === 'function') {
          return columnConfig.enableEditing(props.row);
        } else {
          return columnConfig.enableEditing;
        }
      }
    } else if (columnConfig?.enableInlineAdding !== undefined) {
      if (typeof columnConfig.enableInlineAdding === 'function') {
        return columnConfig.enableInlineAdding(props.row);
      } else {
        return columnConfig.enableInlineAdding;
      }
    }
    return true;
  }, [columnConfig, props.row]);

  return (
    <MuiAutoComplete<Listing, boolean | undefined>
      disabled={!enableColumn}
      onChange={onChangeHandler}
      onInputChange={onInputChangeHandler}
      loading={localLoading}
      value={value}
      defaultValue={initialValue}
      options={listing || []}
      size="small"
      limitTags={1}
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      renderInput={({InputLabelProps, ...params}) => (
        <TextField
          {...params}
          size="small"
          error={cellError !== undefined}
          placeholder={cellError?.message ? `*${cellError.message}` : undefined}
        />
      )}
      getOptionLabel={({label}) => label ?? ''}
      multiple={columnConfig?.enableMulti}
    />
  );
};
