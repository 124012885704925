import React from 'react';
import {useHistory} from 'react-router-dom';
import {IconButton} from '@mui/material';
import {Assignment, Launch} from '@mui/icons-material';
import {TripDebriefBillingForm} from './TripDebriefBillingForm';
import {TripCard} from '../tripCard';
import {useTripContext} from '../tripContext';

export const TripDebrief: React.FC = () => {
  const history = useHistory();
  const {masterTripId, loadingMasterTripDebrief, loadMasterTripDebrief} =
    useTripContext();

  const handleAuxNavigateToDebriefView = React.useCallback(() => {
    window.open(
      history.createHref({pathname: `/tripdebrieflist/${masterTripId}`}),
      '_blank'
    );
  }, [history, masterTripId]);

  const handleNavigateToDebriefView = React.useCallback(() => {
    history.push(`/tripdebrieflist/${masterTripId}`);
  }, [history, masterTripId]);

  const handleLoad = React.useCallback(async () => {
    await loadMasterTripDebrief();
  }, [loadMasterTripDebrief]);

  const handleReload = React.useCallback(async () => {
    await loadMasterTripDebrief({reload: true});
  }, [loadMasterTripDebrief]);

  return (
    <TripCard
      title="Trip Debrief"
      loading={loadingMasterTripDebrief}
      loadingText="Loading Debrief..."
      onLoad={handleLoad}
      onReload={handleReload}
      action={
        <IconButton
          onAuxClick={handleAuxNavigateToDebriefView}
          onClick={handleNavigateToDebriefView}
        >
          <Launch />
        </IconButton>
      }
      avatar={<Assignment />}
      gutters
    >
      <TripDebriefBillingForm />
    </TripCard>
  );
};
