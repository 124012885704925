import React from 'react';
import {useLocalStore} from 'mobx-react';
import {planningSolutionTripStoreContext} from './PlanningSolutionTrip.context';
import {planningSolutionTripStore} from './PlanningSolutionTrip.store';

// eslint-disable-next-line import/prefer-default-export
export const PlanningSolutionTripProvider: React.FC = ({children}) => {
  const store = useLocalStore(planningSolutionTripStore);

  return (
    <planningSolutionTripStoreContext.Provider value={store}>
      {children}
    </planningSolutionTripStoreContext.Provider>
  );
};
