import React from 'react';
import {DriverContext} from '../DriverContext';
import {VantageDialogButton} from '../../dialog';
import {FormikAutocompleteWithListing} from '../../formik';
import {Formik} from 'formik';

export const DriverUserPopup: React.FC = () => {
  const {onUserLink, userPopupDetailsRef} = React.useContext(DriverContext);
  return (
    <Formik
      initialValues={{user: {label: undefined, value: undefined}}}
      onSubmit={onUserLink}
      innerRef={userPopupDetailsRef}
    >
      {(params) => (
        <VantageDialogButton
          title="Link"
          dialogTitle="Link Driver to User"
          acceptTitle="Save"
          declineTitle="Cancel"
          acceptProps={{type: 'submit', disabled: !params.dirty}}
          onAccept={() => onUserLink(params.values, params)}
          maxWidth="xs"
          fullWidth
        >
          <FormikAutocompleteWithListing
            name="user"
            model="User"
            label="User"
          />
        </VantageDialogButton>
      )}
    </Formik>
  );
};
