import React from 'react';
import {useDriverGroupContext} from './driverGroupContext';
import {useParams} from 'react-router-dom';

interface IParams {
  driverGroupId?: string;
}

export const DriverGroupIdHandler: React.FC = () => {
  const {setDriverGroupId} = useDriverGroupContext();

  const {driverGroupId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = driverGroupId ? parseInt(driverGroupId) : undefined;
    setDriverGroupId(parsedId);
    return () => setDriverGroupId(undefined);
  }, [driverGroupId, setDriverGroupId]);

  return null;
};
