import React from 'react';
import {useHistory} from 'react-router-dom';
import {
  ApiProductGroupGetRequest,
  ProductGroup,
  ProductGroupList,
  ProductGroupPost,
} from '@onroadvantage/onroadvantage-api';
import {FormikHelpers, FormikProps} from 'formik/dist/types';
import {useAppNotifications} from '../../contexts';
import {productGroupApi} from '../../api';
import {
  TemplateTableContextProps,
  TLoadList,
  useTemplateTable,
} from '../../factory/template';
import {IProductGroupForm} from './ProductGroupForm';

export interface ProductGroupContextProps
  extends TemplateTableContextProps<ProductGroup, ProductGroupList> {
  loadProductGroup: () => Promise<void>;
  onDetailsFormSubmit: (
    values: IProductGroupForm,
    formikHelpers: FormikHelpers<IProductGroupForm>
  ) => void;
  setProductGroupId: (value: number | undefined) => void;
  submitting: boolean;
  loadingSingleItem: boolean;
  productGroup?: ProductGroup;
  productGroupId?: number;
  detailsRef?: React.Ref<FormikProps<IProductGroupForm>>;
}

export const ProductGroupContext =
  React.createContext<ProductGroupContextProps>({
    // Template Table Defaults
    loading: false,
    list: [],
    currentPage: 1,
    // ProductGroup
    loadList: async () => {},
    loadProductGroup: async () => {},
    onDetailsFormSubmit: () => null,
    setProductGroupId: () => null,
    submitting: false,
    loadingSingleItem: false,
  });

interface ProductGroupContextProviderProps {
  productGroupId?: number;
}

export const ProductGroupContextProvider: React.FC<
  ProductGroupContextProviderProps
> = ({children}) => {
  const history = useHistory();
  const notify = useAppNotifications();
  // Template Table
  const [
    {
      // States
      currentPage,
      filters,
      itemTotal,
      hasPermission,
      list,
      loading,
      pageSize,
      pageTotal,
      loadingSingleItem,
      sorting,
    },
    {
      // Getters
      getDownloads,
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
      setLoadingSingleItem,
    },
  ] = useTemplateTable<ProductGroup, ApiProductGroupGetRequest>({
    editPermission: 'Edit ProductGroup',
    addPermission: 'Add ProductGroup',
    deletePermission: 'Delete ProductGroup',
    downloadPermission: 'ProductGroup ListDownload',
    viewPermission: 'ProductGroup List',
  });

  const loadList = React.useCallback<TLoadList<ProductGroupList>>(
    async (options) => {
      setLoading(true);
      try {
        const requestObj = getRequestObj(['name', 'description'], options);
        const response = await productGroupApi.apiProductGroupGet(requestObj);
        return getResponse(response, options);
      } catch (e) {
        notify('error', e.message ?? 'Failed to load notification config list');
      } finally {
        setLoading(false);
      }
    },
    [getRequestObj, getResponse, notify, setLoading]
  );

  const handleDelete = React.useCallback(
    async (row: ProductGroup) => {
      setLoading(true);
      try {
        if (row.id) {
          await productGroupApi.apiProductGroupProductGroupIdDelete({
            productGroupId: row.id,
          });
          await loadList();

          notify('success', 'Deleted notification config');
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to delete');
      } finally {
        setLoading(false);
      }
    },
    [loadList, notify, setLoading]
  );

  const handleDownload = React.useCallback(
    () => getDownloads('productGroup', loadList, []),
    [getDownloads, loadList]
  );

  const handleAdd = React.useCallback(
    () => history.push('/productgrouplist/add'),
    [history]
  );

  const handleNavigate = React.useCallback(
    (row) => history.push(`/productgrouplist/${row.id}`),
    [history]
  );
  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  // Forms
  const [productGroup, setProductGroup] = React.useState<
    ProductGroup | undefined
  >();
  const [productGroupId, setProductGroupId] = React.useState<
    number | undefined
  >();
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const detailsRef = React.useRef<FormikProps<IProductGroupForm>>(null);

  const loadProductGroup = React.useCallback(async () => {
    setLoadingSingleItem(true);
    try {
      if (productGroupId) {
        const response = await productGroupApi.apiProductGroupProductGroupIdGet(
          {
            productGroupId,
          }
        );
        setProductGroup(response);
      } else {
        setProductGroup(undefined);
      }
    } catch (e) {
      notify('error', e.message ?? 'Failed to load productGroup');
    } finally {
      setLoadingSingleItem(false);
    }
  }, [setLoadingSingleItem, productGroupId, notify]);

  const handleDetailsFormSubmit = React.useCallback(
    async (
      values: IProductGroupForm,
      formikHelpers: FormikHelpers<IProductGroupForm>
    ) => {
      setSubmitting(true);
      try {
        const {products, planningSkills, ...otherValues} = values;
        const productIds = products
          ?.filter(({value}) => value)
          .map(({value}) => value as number);
        const skillIds = planningSkills
          ?.filter(({value}) => value)
          .map(({value}) => value as number);
        const newValues: ProductGroupPost = {
          ...otherValues,
          productIds: productIds ?? undefined,
          skillIds: skillIds ?? undefined,
        };
        if (productGroupId) {
          await productGroupApi.apiProductGroupProductGroupIdPatch({
            productGroupId,
            body: newValues,
          });
        } else {
          await productGroupApi.apiProductGroupPost({
            body: newValues,
          });
        }
        history.push('/productgrouplist');
        notify(
          'success',
          `${productGroupId ? 'Updated' : 'Added'} productGroup`
        );
      } catch (e) {
        notify(
          'error',
          e.message ??
            `Failed to ${productGroupId ? 'update' : 'add'} productGroup`
        );
      } finally {
        formikHelpers.setSubmitting(false);
        setSubmitting(false);
      }
    },
    [history, notify, setSubmitting, productGroupId]
  );

  const value: ProductGroupContextProps = {
    // Template Table
    list,
    loadList,
    hasPermission,
    loading: loading || loadingSingleItem,
    loadingSingleItem,
    cleanupList,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onAdd: hasPermission.add ? handleAdd : undefined,
    onDownload: hasPermission.download ? handleDownload : undefined,
    onNavigate: handleNavigate,
    onDelete: hasPermission.delete ? handleDelete : undefined,
    onRefresh: handleRefresh,
    // Forms
    loadProductGroup,
    onDetailsFormSubmit: handleDetailsFormSubmit,
    setProductGroupId,
    submitting,
    productGroup,
    productGroupId,
    detailsRef,
  };

  React.useEffect(() => {
    loadProductGroup();
    return () => setProductGroup(undefined);
  }, [loadProductGroup]);

  return (
    <ProductGroupContext.Provider value={value}>
      {children}
    </ProductGroupContext.Provider>
  );
};
