import React from 'react';
import {BroadcastContext} from '../BroadcastContext';
import {TemplateTable, TemplateTableConfig} from '../../../factory/template';
import {Voice} from '@onroadvantage/onroadvantage-api';
import {BroadcastNotificationsLogs} from './BroadcastNotificationsLogs';

export const BroadcastNotifications: React.FC = () => {
  const {broadcast, loading, loadingSingleItem} =
    React.useContext(BroadcastContext);

  const [config] = React.useState<TemplateTableConfig<Voice>>({
    columns: [
      {name: 'toNumber', label: 'To Number', type: 'string'},
      {name: 'scheduledDateTime', label: 'Scheduled Date', type: 'datetime'},
      {name: 'twilioStatus', label: 'Status', type: 'string'},
    ],
    disablePagination: true,
    disableToolbar: true,
    identifier: 'BROADCAST_NOTIFICATION_LIST',
  });

  return (
    <TemplateTable
      config={config}
      list={broadcast?.voiceCalls ?? []}
      currentPage={1}
      loading={loading || loadingSingleItem}
      rowDetail={BroadcastNotificationsLogs}
    />
  );
};
