import React from 'react';
import {Listing} from '@onroadvantage/onroadvantage-api';
import {RoleContext} from '../RoleContext';
import {
  splitByCapitals,
  TemplateTable,
  TemplateTableConfig,
  TOnInlineAdd,
} from '../../../factory/template';
import {useAppNotifications} from '../../../contexts';
import {RoleService} from '../../../service';
import {TRoleEventTypes, useRoleEventTypeList} from './useRoleEventTypesList';

export interface RoleEventTypeListProps {
  list: TRoleEventTypes[];
  type: 'CriticalEventType' | 'OperationalEventType' | 'MediaEventType';
}

export const RoleEventTypeList: React.FC<RoleEventTypeListProps> = ({
  list: eventTypeList,
  type,
}) => {
  const notify = useAppNotifications();
  const {loading, role, roleId, onUpdateRole} = React.useContext(RoleContext);
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const RoleEventTypeList = useRoleEventTypeList({
    eventTypeList,
  });

  const config = React.useMemo<TemplateTableConfig<TRoleEventTypes>>(() => {
    const columns: TemplateTableConfig<TRoleEventTypes>['columns'] = [
      {
        name: 'name',
        label: 'Name',
        type: 'string',
        enableEditing: true,
        enableAutocomplete: true,
        enableMulti: true,
        model: type,
      },
    ];

    if (type !== 'MediaEventType') {
      columns.push({
        name: 'externalReference',
        label: 'External Reference',
        type: 'string',
        enableEditing: false,
      });
    }

    return {
      columns,
      enableFilter: true,
      enableSort: true,
      disablePagination: true,
      disableToolbar: true,
      deleteMessage: (row) => {
        const items: {[key: string]: React.ReactNode} = {
          Role: role?.name,
          Name: row.name,
        };

        if ('externalReference' in row) {
          items['ExternalReference'] = row.externalReference;
        }

        return {
          title: `Role Event Type  ${splitByCapitals(type)}`,
          items,
        };
      },
      identifier: `ROLE_EVENT_TYPE_${splitByCapitals(type, {
        capabilitiesAllLetters: true,
        joinCharacter: '_',
      })}_LIST`,
    };
  }, [role?.name, type]);

  const handleInlineAdd = React.useCallback<TOnInlineAdd>(
    async (changes) => {
      setSubmitting(true);
      try {
        const newEventTypes = changes[0]?.name as Listing[] | undefined;

        if (roleId && newEventTypes && newEventTypes.length > 0) {
          const mappedEventTypes = eventTypeList
            .map(({id}) => ({id: id as number}))
            .concat(newEventTypes.map(({value}) => ({id: value as number})));

          const name: string | undefined = role?.name;
          if (type === 'CriticalEventType') {
            await onUpdateRole(
              {
                name: name,
                criticalEventTypes: mappedEventTypes,
              },
              'Add',
              'external'
            );
          } else if (type === 'OperationalEventType') {
            await onUpdateRole(
              {
                name: name,
                operationalEventTypes: mappedEventTypes,
              },
              'Add',
              'external'
            );
          } else if (type === 'MediaEventType') {
            await onUpdateRole(
              {
                name: name,
                mediaEventTypes: mappedEventTypes,
              },
              'Add',
              'external'
            );
          }
        }
      } catch (e) {
        notify(
          'error',
          `Failed to unassign ${splitByCapitals(type, {
            lowercaseAllLetters: true,
          })} to role`
        );
      } finally {
        setSubmitting(false);
      }
    },
    [roleId, role, notify, onUpdateRole, eventTypeList, type]
  );

  const handleDelete = React.useCallback(
    async (row: TRoleEventTypes) => {
      setSubmitting(true);
      try {
        if (roleId && row.id) {
          const name: string | undefined = role?.name;
          if (type === 'CriticalEventType') {
            await onUpdateRole(
              {
                name: name,
                criticalEventTypes: eventTypeList
                  .filter(({id}) => id !== row.id)
                  .map(({id}) => ({
                    id: id as number,
                  })),
              },
              'Delete',
              'external'
            );
          } else if (type === 'OperationalEventType') {
            await onUpdateRole(
              {
                name: name,
                operationalEventTypes: eventTypeList
                  .filter(({id}) => id !== row.id)
                  .map(({id}) => ({
                    id: id as number,
                  })),
              },
              'Delete',
              'external'
            );
          } else if (type === 'MediaEventType') {
            await onUpdateRole(
              {
                name: name,
                mediaEventTypes: eventTypeList
                  .filter(({id}) => id !== row.id)
                  .map(({id}) => ({
                    id: id as number,
                  })),
              },
              'Delete',
              'external'
            );
          }
        }
      } catch (e) {
        notify(
          'error',
          `Failed to delete ${splitByCapitals(type, {
            lowercaseAllLetters: true,
          })} from role`
        );
      } finally {
        setSubmitting(false);
      }
    },
    [roleId, role, notify, onUpdateRole, eventTypeList, type]
  );

  return (
    <TemplateTable
      config={config}
      currentPage={1}
      loading={loading || submitting}
      onInlineAdd={
        RoleService.hasPermission('Edit Role', 'Edit')
          ? handleInlineAdd
          : undefined
      }
      onDelete={
        RoleService.hasPermission('Edit Role', 'Edit')
          ? handleDelete
          : undefined
      }
      {...RoleEventTypeList}
    />
  );
};
