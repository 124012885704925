import {MasterTripTypes} from '../planningBoardContext';

export const setMasterTripId = (type: MasterTripTypes, id: number) =>
  type.concat(id.toString());

export const getMasterTripId = (
  type: MasterTripTypes,
  id: number | string | unknown
) =>
  typeof id === 'number' ? id : parseInt((id as string).substr(type.length));
