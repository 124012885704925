import React from 'react';
import {
  ActualTripSummary,
  CriticalEventDump,
  Node,
  Vehicle,
} from '@onroadvantage/onroadvantage-api';
import {VehicleCard} from '../vehicleCard';
import MapIcon from '@mui/icons-material/Navigation';
import Map from '../../map/map';
import {Loader} from '../../loader';

interface VehicleMapProps {
  actualTrip: ActualTripSummary | undefined;
  telematicsEvents: any;
  criticalEvents: CriticalEventDump[];
  nearbyNodes: Node[];
  loading: boolean | undefined;
  vehicle: Vehicle;
}

export const VehicleMap: React.FC<VehicleMapProps> = ({
  actualTrip,
  vehicle,
  nearbyNodes,
  criticalEvents,
  telematicsEvents,
  loading,
}) => {
  return (
    <VehicleCard title="Map" avatar={<MapIcon />} defaultExpanded>
      <div className="leaflet-container" style={{height: '70vh'}}>
        {!loading ? (
          <Map
            vehicle={
              vehicle.lastSeen?.latitude && vehicle.lastSeen?.longitude
                ? vehicle
                : undefined
            }
            trip={{}}
            actualTrip={actualTrip ?? {}}
            route={{}}
            nearbyNodes={nearbyNodes}
            telematicsEvents={telematicsEvents ?? []}
            criticalEvents={criticalEvents}
          />
        ) : (
          <Loader />
        )}
      </div>
    </VehicleCard>
  );
};
