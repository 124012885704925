import React from 'react';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../factory/template';
import {DriverContext} from './DriverContext';
import {Driver} from '@onroadvantage/onroadvantage-api';

export const DriverList: React.FC = () => {
  const {loadList, cleanupList} = React.useContext(DriverContext);

  const [config] = React.useState<TemplateTableConfig<Driver>>({
    columns: [
      {name: 'firstname', label: 'First Name', type: 'string'},
      {name: 'lastname', label: 'Last Name', type: 'string'},
      {
        name: 'externalEmployeeNumber',
        label: 'Employee Number',
        type: 'string',
      },
      {name: 'externalName', label: 'External Name', type: 'string'},
      {
        name: 'contractCode',
        label: 'Contract Code',
        type: 'string',
        getValue: ({contract}) => contract?.code,
      },
      {name: 'contactNumber', label: 'Contact Number', type: 'string'},
      {
        name: 'licenseType',
        label: 'License Type',
        type: 'string',
        hidden: true,
      },
      {
        name: 'licenseExpiry',
        label: 'License Expiry',
        type: 'datetime',
        hidden: true,
        enableFilter: false,
      },
      {name: 'telematicsConfigId', label: 'Config', type: 'string'},
      {name: 'externalId', label: 'External Id', type: 'string'},
    ],
    enableFilter: true,
    enableSort: true,
    deleteMessage: (row) => ({
      title: 'Driver',
      items: {
        Name: `${row.firstname} ${row.lastname}`,
        EmployeeNumber: row.externalEmployeeNumber,
      },
    }),
    identifier: 'DRIVER_LIST',
  });

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [cleanupList, loadList]);

  return <TemplateTableWrapper config={config} context={DriverContext} />;
};
