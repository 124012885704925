import {makeStyles, createStyles} from '@mui/styles';
import {Theme} from '@mui/material';

const ContactUserPopupStyles = (theme: Theme) =>
  createStyles({
    Actions: {
      marginTop: theme.spacing(2),
    },
    input: {
      display: 'flex',
      padding: 0,
    },
    paper: {
      marginTop: theme.spacing(1),
    },
  });

export const useContactUserPopupStyles = makeStyles(ContactUserPopupStyles);
