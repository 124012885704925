import React from 'react';
import clsx from 'clsx';
import {useHistory} from 'react-router-dom';
import {
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  List,
  Typography,
} from '@mui/material';
import {
  Home,
  KeyboardArrowDown,
  KeyboardArrowUp,
  LocalShipping,
  Person,
  VpnKey,
  Warning,
} from '@mui/icons-material';
import {WebEvent} from '@onroadvantage/onroadvantage-api';
import {useEventsPaneCardStyles} from './EventsPaneCard.style';
import {EventsPaneCardAddActivity} from './EventsPaneCardAddActivity';
import {EventsPaneCardListItem} from './EventsPaneCardListItem';
import {EventsPaneCardActivity} from './EventsPaneCardActivity';
import {EventsPaneContext} from '../EventsPaneContext';
import {MomentService} from '../../../service';
import {authStore} from '../../../store';
import {criticalEventApi, operationalEventApi} from '../../../api';
import {Loader} from '../../loader';
import {EventsPaneCardActions} from './EventsPaneCardActions';

interface EventsPaneCardProps {
  event: WebEvent;
}

export const CLOSED_STATUSES = ['Closed', 'BulkClosed', 'MutedClosed'];

export const EventsPaneCard: React.FC<EventsPaneCardProps> = ({event}) => {
  const history = useHistory();
  const {getAuth} = authStore;
  const auth = getAuth;
  const {selectedEvent, reloadEvents} = React.useContext(EventsPaneContext);
  const classes = useEventsPaneCardStyles();
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
  const [localSubmitting, setLocalSubmitting] = React.useState<boolean>(false);

  const activities = React.useMemo(
    () =>
      event.activities?.sort((a, b) =>
        !a.createdAt || !b.createdAt
          ? 0
          : a.createdAt < b.createdAt
          ? 1
          : a.createdAt > b.createdAt
          ? -1
          : 0
      ),
    [event.activities]
  );

  const assignedEmail = React.useMemo(
    () =>
      activities?.reduceRight((acc: string | undefined, activity) => {
        if (
          activity.action === 'AssignmentChange' &&
          activity.createdByEmail != null
        ) {
          return activity.createdByEmail;
        }
        return acc;
      }, undefined),
    [activities]
  );

  const isAssignedToCurrentUser =
    assignedEmail != null &&
    auth?.email != null &&
    assignedEmail === auth.email;

  const title = React.useMemo(
    () =>
      event.metaData?.model === 'OperationalEvent'
        ? `${
            event.eventType && event.eventType.name
              ? event.eventType.name
              : event.description
          }`
        : `${
            event.eventType && event.providerName
              ? `${event.providerName}: `
              : ''
          }${
            event.eventType && event.eventType.name
              ? event.eventType.name
              : event.description
          }`,
    [event]
  );

  const handleToggleIsExpanded = React.useCallback(
    () => setIsExpanded((prevIsExpanded) => !prevIsExpanded),
    []
  );

  const handleNavigateEvent = React.useCallback(() => {
    if (event.masterTrip?.id || event.vehicle?.id) {
      history.push({
        pathname: event.masterTrip
          ? `/triplist/${event.masterTrip.id}`
          : `/vehiclelist/${event.vehicle?.id}`,
        search:
          event.metaData?.model === 'OperationalEvent'
            ? `?operationalEventId=${event.id}`
            : `?criticalEventId=${event.id}`,
        state: {event},
      });
    }
  }, [event, history]);

  const handleEventActivity = React.useCallback(
    (action: string, value: string) =>
      async (
        e: React.MouseEvent<
          HTMLButtonElement | HTMLLIElement | HTMLAnchorElement,
          MouseEvent
        >
      ) => {
        e.stopPropagation();
        setLocalSubmitting(true);
        try {
          if (event.id) {
            if (event.metaData?.model === 'OperationalEvent') {
              await operationalEventApi.apiOperationalEventOperationalEventIdPost(
                {
                  body: {activities: [{action, value}]},
                  operationalEventId: event.id,
                }
              );
            } else {
              await criticalEventApi.apiCriticalEventCriticalEventIdPost({
                body: {activities: [{action, value}]},
                criticalEventId: event.id,
              });
            }
            await reloadEvents();
          }
        } finally {
          setLocalSubmitting(false);
        }
      },

    [event.id, event.metaData?.model, reloadEvents]
  );

  const handleCloseEvent = React.useCallback(
    (
      e: React.MouseEvent<
        HTMLButtonElement | HTMLLIElement | HTMLAnchorElement,
        MouseEvent
      >
    ) => {
      handleEventActivity(
        'StatusChange',
        event.status && CLOSED_STATUSES.includes(event.status)
          ? 'Open'
          : 'Closed'
      )(e);
    },
    [event.status, handleEventActivity]
  );

  const handleAssignToMe = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      handleEventActivity('AssignmentChange', 'Assigned to Me')(e);
      setIsExpanded(true);
    },
    [handleEventActivity]
  );

  const closeEventStatus =
    event?.status && CLOSED_STATUSES.includes(event.status)
      ? 'Re-Open Event'
      : 'Close Event';

  const disableCloseEvent =
    (event?.status === 'Open' && !isAssignedToCurrentUser) ||
    event?.status === 'MutedClosed';

  return (
    <Card
      className={clsx(classes.root, {
        [classes.rootSelected]: selectedEvent?.id === event.id,
      })}
    >
      <CardActionArea disabled={localSubmitting} onClick={handleNavigateEvent}>
        <CardHeader
          classes={{root: classes.header}}
          avatar={
            <Avatar
              aria-label="Event Avatar"
              className={clsx(classes.headerAvatar, {
                [classes.headerAvatarCritical]:
                  event.metaData?.model === 'CriticalEvent',
              })}
            >
              <Warning />
            </Avatar>
          }
          action={
            <Typography color="textSecondary" className={classes.status}>
              {event.status}
            </Typography>
          }
          title={title}
          subheader={
            <div>
              <div>
                {`${MomentService.displayFromNow(
                  event.eventDate
                )} @ ${MomentService.displayTimestamp(event.eventDate)}`}
              </div>
              <div>
                {assignedEmail ? (
                  <div>
                    Assigned to:
                    {assignedEmail}
                  </div>
                ) : (
                  'Unassigned'
                )}
              </div>
            </div>
          }
        />
        <CardContent className={classes.content}>
          <List classes={{root: classes.list}} dense disablePadding>
            <EventsPaneCardListItem
              Icon={<LocalShipping />}
              primary={event.vehicle?.description ?? 'No Vehicle: '}
              secondary={event.vehicle?.registrationNumber ?? 'No Vehicle'}
            />
            <EventsPaneCardListItem
              Icon={<Person />}
              primary={event.driver?.firstname ?? 'No Driver'}
            />
            <EventsPaneCardListItem
              Icon={<Home />}
              primary={
                event.vehicle
                  ? event.vehicle.contractCode ?? 'No Contract: '
                  : 'No Vehicle: '
              }
              secondary={event.masterTrip?.tripNumber ?? 'No Current Trip'}
            />
            <EventsPaneCardListItem
              Icon={<VpnKey />}
              primary="ID"
              secondary={event.id}
            />
          </List>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.actions}>
        <Button
          size="small"
          color="primary"
          variant="outlined"
          onClick={handleAssignToMe}
          disabled={localSubmitting || isAssignedToCurrentUser}
        >
          Assign to me
        </Button>
        <Loader size={36} loading={localSubmitting} />
        <EventsPaneCardActions
          event={event}
          onCloseEvent={handleCloseEvent}
          disableCloseEvent={disableCloseEvent}
          closeEventStatus={closeEventStatus}
          disabled={localSubmitting}
        />
      </CardActions>
      <CardActions onClick={handleToggleIsExpanded}>
        <Button disabled={localSubmitting} color="inherit" fullWidth>
          {isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </Button>
      </CardActions>
      <Collapse in={isExpanded} unmountOnExit mountOnEnter>
        <CardContent className={classes.content}>
          <EventsPaneCardAddActivity event={event} />
          {activities?.map((activity) => (
            <EventsPaneCardActivity
              key={activity.id}
              activity={activity}
              filteredActions={[
                'OpenEscalation',
                'Summary',
                'UnassignedEscalation',
                'Trigger',
              ]}
            />
          ))}
        </CardContent>
        <CardActions className={classes.actions}>
          <Button
            size="small"
            color="primary"
            variant="outlined"
            onClick={handleCloseEvent}
            disabled={disableCloseEvent}
          >
            {closeEventStatus}
          </Button>
        </CardActions>
      </Collapse>
    </Card>
  );
};
