import React from 'react';
import {TrainingCourseContext} from './TrainingCourseContext';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../factory/template';
import {TrainingCourse} from '@onroadvantage/onroadvantage-api';

export const TrainingCourseList: React.FC = () => {
  const {loadList, cleanupList} = React.useContext(TrainingCourseContext);

  const [config] = React.useState<TemplateTableConfig<TrainingCourse>>({
    columns: [
      {name: 'name', label: 'Name', type: 'string'},
      {
        name: 'category',
        label: 'Category',
        type: 'string',
        enableFilter: false,
      },
      {
        name: 'passMark',
        label: 'Pass Mark',
        type: 'number',
        enableFilter: false,
      },
      {
        name: 'link',
        label: 'Link',
        type: 'link',
        getValue: ({link}) => ({label: 'Go To Course', link}),
        enableFilter: false,
      },
    ],
    enableFilter: true,
    enableSort: false,
    deleteMessage: (row) => ({
      title: 'Training Course List',
      items: {Name: row.name},
    }),
    identifier: 'TRAINING_COURSE_LIST',
  });

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [loadList, cleanupList]);

  return (
    <TemplateTableWrapper config={config} context={TrainingCourseContext} />
  );
};
