import React from 'react';
import {Assignment, OpenInNew} from '@mui/icons-material';
import {
  Avatar,
  Card,
  CardContent,
  CardContentProps,
  CardHeader,
  Divider,
  List,
  ListItemText,
  Stack,
  StackProps,
  ExtendList,
  ListTypeMap,
  Collapse,
  IconButton,
  ListItemButton,
} from '@mui/material';
import {DateTimeFormatService} from '../../../../service/FormatService';
import {useTripDebriefSummaryCardStyles} from './TripDebriefSummaryCard.style';

export type TripDebriefSummaryCardListItemTypes =
  | 'string'
  | 'boolean'
  | 'number'
  | 'percentage'
  | 'date'
  | 'datetime'
  | 'time'
  | 'kilometer'
  | 'null';

export interface TripDebriefSummaryCardListItem {
  primary: string | null | undefined;
  secondary?: React.ReactNode | undefined;
  type: TripDebriefSummaryCardListItemTypes;
  changed: boolean;
  indented?: boolean;
}

export type TripDebriefSummaryCardList = TripDebriefSummaryCardListItem[];

interface TripDebriefSummaryCardProps {
  title: string;
  avatar?: React.ReactNode;
  list: TripDebriefSummaryCardList | undefined;
  onNavigate?: () => void;
  CardContentProps?: CardContentProps;
  disableList?: boolean;
  ListProps?: ExtendList<ListTypeMap>;
  ChildrenProps?: StackProps;
}

export const TripDebriefSummaryCard: React.FC<TripDebriefSummaryCardProps> = ({
  title,
  list,
  avatar,
  children,
  onNavigate,
  CardContentProps,
  ListProps,
  disableList,
  ChildrenProps,
}) => {
  /**
   * Component requires a title and a list prop, and has optional props avatar, CardContentProps ,ChildrenProps and ListProps
   * title -> The title of the CardHeader
   * list -> Array of type TripDebriefSummaryCardListItem. Requires primary text and a type which has the type TripDebriefSummaryCardListItemTypes.
   * The type is used for formatting data such as date-times and add km to kilometer. A prop secondary can also be passed
   * (primary is used as a label, and secondary as its value).
   * avatar -> Used to override the default card's avatar
   * CardContentProps -> To override any props on the CardContentComponent
   * ListProps -> To override any props on the ListComponent
   * ChildrenProps -> To override any props on the StackComponentWrapper for the children
   * */
  const classes = useTripDebriefSummaryCardStyles();

  /** Handler to add formatting to the values based on its type */
  const handleGetSecondaryValue = React.useCallback(
    (value: React.ReactNode, type: TripDebriefSummaryCardListItemTypes) => {
      switch (type) {
        case 'kilometer': {
          /** Math.round(value * 100) / 100 is to round the kilometer value to the nearest 2 decimal points */
          const parsedValue =
            typeof value === 'string' ? parseFloat(value) : value;
          const roundedValue =
            typeof parsedValue === 'number'
              ? Math.round(parsedValue * 100) / 100
              : value;
          return value ? `${roundedValue}km` : '-';
        }
        case 'percentage': {
          /** Math.round(value * 100) / 100 is to round the percentage value to the nearest 2 decimal points */
          const parsedValue =
            typeof value === 'string' ? parseFloat(value) : value;
          const roundedValue =
            typeof parsedValue === 'number'
              ? Math.round(parsedValue * 100) / 100
              : value;
          return value ? `${roundedValue}%` : '0%';
        }
        case 'boolean':
          return value ? 'Yes' : 'No';
        case 'datetime':
          return value
            ? new DateTimeFormatService().formatDateTime(value as Date)
            : '-';
        case 'null':
          return null;
        default:
          return value ?? '-';
      }
    },
    []
  );

  return (
    <Card elevation={3}>
      <CardHeader
        title={title}
        avatar={<Avatar>{avatar ?? <Assignment />}</Avatar>}
        action={
          onNavigate && (
            <IconButton onClick={onNavigate}>
              <OpenInNew />
            </IconButton>
          )
        }
      />
      {disableList ? (
        <Stack className={classes.children} {...ChildrenProps}>
          {children}
        </Stack>
      ) : (
        <Collapse in={list && list.length > 0}>
          <CardContent className={classes.content} {...CardContentProps}>
            {list ? (
              <List
                component={Stack}
                className={classes.list}
                dense
                disablePadding
                {...ListProps}
              >
                {list.length === 0
                  ? '-'
                  : list.map(
                      ({primary, secondary, type, changed}, index, array) => (
                        <>
                          <ListItemButton
                            className={classes.listItem}
                            key={primary}
                            dense
                            disableGutters
                            disableRipple
                            disableTouchRipple
                          >
                            <ListItemText
                              classes={{
                                root: classes.listItemText,
                                primary: classes.listItemTextPrimary,
                                secondary: classes.listItemTextSecondary,
                              }}
                              secondaryTypographyProps={{
                                color: changed
                                  ? 'secondary.main'
                                  : 'text.secondary',
                              }}
                              primary={primary}
                              secondary={handleGetSecondaryValue(
                                secondary,
                                type
                              )}
                            />
                          </ListItemButton>
                          {primary === 'Comment' &&
                            array.length - 1 !== index && <Divider flexItem />}
                        </>
                      )
                    )}
              </List>
            ) : null}
            <Stack className={classes.children} {...ChildrenProps}>
              {children}
            </Stack>
          </CardContent>
        </Collapse>
      )}
    </Card>
  );
};
