import React from 'react';
import {TripSettingsViewLayout} from './tripSettingsViewLayout';
import {Divider, Drawer, IconButton, Stack, Typography} from '@mui/material';
import {useTripSettingsContext} from './tripSettingsContext';
import {Refresh} from '@mui/icons-material';

export const TripSettings: React.FC = () => {
  const {openSettings, onCloseSettings, resetTripSettings} =
    useTripSettingsContext();
  return (
    <Drawer open={openSettings} onClose={onCloseSettings} anchor="right">
      <Stack spacing={2} p={2} minWidth={700}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1.5}
        >
          <Typography variant="h3" fontWeight={600}>
            Trip Settings
          </Typography>
          <IconButton size="large" onClick={resetTripSettings}>
            <Refresh fontSize="large" />
          </IconButton>
        </Stack>
        <Divider />
        <TripSettingsViewLayout />
      </Stack>
    </Drawer>
  );
};
