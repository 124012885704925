import React from 'react';
import {NodeTypeForm} from './NodeTypeForm';
import {ITemplateTabControllerTab, TemplateTabs} from '../../factory/template';
import {NodeTypeContext} from './NodeTypeContext';

export const NodeType: React.FC = () => {
  const {nodeTypeId, loading} = React.useContext(NodeTypeContext);

  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {title: 'Details', element: <NodeTypeForm />},
  ]);

  const path = React.useMemo(() => `/nodetypelist/${nodeTypeId}`, [nodeTypeId]);

  return <TemplateTabs tabs={tabs} path={path} loading={loading} />;
};
