import styled from '@emotion/styled';
import {Theme} from '@mui/material';

import createStyles from '@mui/styles/createStyles';

export const leftDrawerWidth = 260;
const rightDrawerWidth = 480;

const WebFrameMaterialStyling = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    appFrame: {
      zIndex: 1,
      position: 'relative',
      display: 'flex',
      width: '100%',
    },
    appBar: {
      position: 'fixed',
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarDesktop: {
      width: `calc(100%)`,
    },

    appBarShiftBoth: {
      width: `calc(100% - ${rightDrawerWidth}px - ${leftDrawerWidth}px)`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    appBarShiftLeft: {
      width: `calc(100% - ${leftDrawerWidth}px)`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    appBarShiftRight: {
      width: `calc(100% - ${rightDrawerWidth}px)`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    'appBarShift-left': {
      marginLeft: leftDrawerWidth,
    },
    'appBarShift-right': {
      marginRight: rightDrawerWidth,
    },
    menuButton: {
      marginLeft: 12,
      marginRight: 20,
    },
    hide: {
      display: 'none',
    },
    show: {
      display: 'block',
    },
    drawerLeftPaper: {
      position: 'fixed',
      width: leftDrawerWidth,
      left: 0,
      transition: theme.transitions.create(['left'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    drawerLeftPaperMinimized: {
      position: 'fixed',
      width: leftDrawerWidth,
      left: 17 - leftDrawerWidth,
      transition: theme.transitions.create(['left'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    drawerRightPaper: {
      position: 'fixed',
      width: rightDrawerWidth,
      overflow: 'hidden',
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
    drawerToggleButtonOpen: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '20px',
      position: 'absolute',
      left: 243,
      top: 84,
      width: 34,
      minWidth: 34,
      height: 34,
      backgroundColor: '#00B7A3',
      zIndex: 10000,
      color: 'white',
      transition: theme.transitions.create(['left'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    drawerToggleButtonClose: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '20px',
      position: 'absolute',
      left: 0,
      top: 84,
      width: 34,
      minWidth: 34,
      height: 34,
      backgroundColor: '#00B7A3',
      zIndex: 10000,
      color: 'white',
      transition: theme.transitions.create(['left'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flex: 1,
      flexGrow: 1,
      marginLeft: 17,
      backgroundColor: theme.palette.background.default,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    contentShiftLeft: {
      width: `calc(100% - ${leftDrawerWidth}px)`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    contentShiftRight: {
      width: `calc(100% - ${rightDrawerWidth}px)`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    'contentShift-right': {
      marginRight: 0.4 * rightDrawerWidth,
    },
    'contentShift-left': {
      marginLeft: leftDrawerWidth,
    },
    'contentShift-both': {
      marginRight: 0.4 * rightDrawerWidth,
      marginLeft: leftDrawerWidth,
    },
  });

const AbsoluteContainer = styled.div({
  zIndex: 99,
  position: 'absolute',
  top: 0,
  left: 0,
  margin: 0,
  width: '100vw',
  height: '100vh',
  backgroundColor: 'white',
});

const LoadingContainer = styled.div({
  display: 'flex',
  justifySelf: 'center',
  alignSelf: 'center',
});

const MenuIcon = styled.img({
  height: 20,
  width: 20,
  cursor: 'pointer',
});

// const StyledAppBar = styled(AppBar)(
//   ({isMobile, isEventsOpen}:any)=>(
//     {
//
//     }
//   )
// );

export {
  AbsoluteContainer,
  MenuIcon,
  LoadingContainer,
  WebFrameMaterialStyling,
  rightDrawerWidth,
};
