import {Router} from 'react-router-dom';
import history from '../../service/history';
import React, {useEffect} from 'react';
import {authStore} from '../../store';
import {AllowedRoute} from './AllowedRoute';
import {DashboardDataStudioFullscreen, DashboardStandAlone} from '../index';

export const DashboardRouter = () => {
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(history.location.search);
    const urlAuthToken = urlSearchParams.get('authToken');
    if (urlAuthToken) {
      authStore.setAuth({
        email: '',
        token: urlAuthToken,
        roles: [],
        // TODO active is no longer being passed back
        active: true,
        authenticated: true,
        oauthAuthenticated: false,
      });
      const queryParams: string[] = [];
      urlSearchParams.forEach((value, key) => {
        if (key !== 'authToken') {
          queryParams.push(`${key}=${value}`);
        }
      });
      if (urlSearchParams.has('reportId') && queryParams.length > 0) {
        history.push(`${history.location.pathname}?${queryParams.join('&')}`);
      }
    }
  }, []);

  return (
    <Router history={history}>
      <AllowedRoute
        exact
        path="/dashboard_standalone"
        component={DashboardStandAlone}
      />
      <AllowedRoute
        exact
        path="/dashboard_datastudio"
        component={DashboardDataStudioFullscreen}
      />
    </Router>
  );
};
