import React from 'react';
import {
  CardHeader,
  Avatar,
  CardContent,
  CardActions,
  Button,
  Divider,
  Typography,
} from '@mui/material';
import {DriverContext} from '../DriverContext';
import {isEmpty} from 'lodash';
import {DriverUserPopup} from './DriverUserPopup';
import {Person} from '@mui/icons-material';

export const DriverUserDetails: React.FC = () => {
  const {driver, onDriverUserCreate} = React.useContext(DriverContext);
  const [user] = React.useState(driver?.user);

  return (
    <>
      <CardHeader
        title="Driver User Details"
        avatar={
          <Avatar>
            <Person />
          </Avatar>
        }
      />
      {!isEmpty(driver?.user) ? (
        <>
          <CardContent>
            <Typography color="textSecondary">ID</Typography>
            <Typography gutterBottom>{user?.id}</Typography>
            <Typography color="textSecondary">Email</Typography>
            <Typography gutterBottom>{user?.email}</Typography>
          </CardContent>
        </>
      ) : (
        <>
          <CardContent>
            <div>No User allocated</div>
          </CardContent>
          <CardActions>
            <Button
              onClick={onDriverUserCreate}
              title="Create and assign a new user"
            >
              Create
            </Button>
            <Divider orientation="vertical" flexItem />
            <DriverUserPopup />
          </CardActions>
        </>
      )}
    </>
  );
};
