import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

const EventControllerStyles = (theme: Theme) =>
  createStyles({
    mainContent: {
      width: '100%',
      maxWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      margin: 'auto',
      [theme.breakpoints.down('md')]: {height: '100vh', minWidth: '100%'},
    },
    root: {
      minHeight: '100vh',
      width: '100%',
    },
  });

export const useEventControllerStyles = makeStyles(EventControllerStyles, {
  name: 'EventControllerStyles',
});
