import React from 'react';
import {InputAdornment, TextField, TextFieldProps} from '@mui/material';
import clsx from 'clsx';
import {AccessTime} from '@mui/icons-material';
import {useInputStyles} from '../Input.style';
import {Loader} from '../../loader';

export interface FormikPickerFieldProps {
  name: string;
  displayError: string | undefined;
  loading?: boolean;
}

export const FormikPickerField: React.FC<
  FormikPickerFieldProps & TextFieldProps
> = ({name, displayError, loading, InputLabelProps, ...textFieldProps}) => {
  const classes = useInputStyles();
  return (
    <TextField
      {...textFieldProps}
      name={name}
      className={clsx(classes.input, {
        [classes.inputError]: displayError,
      })}
      InputLabelProps={InputLabelProps}
      error={!!displayError}
      helperText={displayError}
      fullWidth={
        !!textFieldProps.fullWidth || textFieldProps.fullWidth === undefined
      }
      InputProps={{
        endAdornment: (
          <InputAdornment className={classes.timeIcon} position="end">
            {loading ? <Loader size={32} /> : <AccessTime />}
          </InputAdornment>
        ),
      }}
    />
  );
};
