import {makeStyles, createStyles} from '@mui/styles';
import {Theme} from '@mui/material';

const BroadcastVantageMobileStyles = (theme: Theme) =>
  createStyles({
    completedStep: {cursor: 'pointer !important'},
    incompleteStep: {cursor: 'default'},
    loaderContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
  });

export const useBroadcastVantageMobileStyles = makeStyles(
  BroadcastVantageMobileStyles,
  {
    name: 'BroadcastVantageMobileStyles',
  }
);
