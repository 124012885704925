import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {ContractOperationalEventTypeConfigIdHandler} from './ContractOperationalEventTypeConfigIdHandler';
import {ContractOperationalEventTypeConfigList} from './contractOperationalEventTypeConfigList/ContractOperationalEventTypeConfigList';
import {ContractOperationalEventTypeConfigListContextProvider} from './contractOperationalEventTypeConfigList/ContractOperationalEventTypeConfigListContext';
import {ContractOperationalEventTypeConfig} from './ContractOperationalEventTypeConfig';
import {ContractOperationalEventTypeConfigDetailsForm} from './ContractOperationalEventTypeConfigDetailsForm';
import {AllowedRoute} from '../../router';
import {ContractOperationalEventTypePromptIdHandler} from './contractOperationalEventTypeConfigPrompt/ContractOperationalEventTypePromptIdHandler';
import {ContractOperationalEventTypePromptForm} from './contractOperationalEventTypeConfigPrompt/ContractOperationalEventTypePromptForm';
import {ContractOperationalEventTypePrompt} from './contractOperationalEventTypeConfigPrompt/ContractOperationalEventTypePrompt';
import {ContractOperationalEventTypePromptContextProvider} from './contractOperationalEventTypeConfigPrompt/ContractOperationalEventTypePromptContext';

export const ContractOperationalEventTypeConfigController = () => {
  return (
    <ContractOperationalEventTypeConfigListContextProvider>
      <Route
        exact
        path="/contracteventtypeconfigs/operational"
        component={ContractOperationalEventTypeConfigList}
      />
      <Route
        path="/contracteventtypeconfigs/operational/:contractOperationalEventTypeConfigId"
        component={ContractOperationalEventTypeConfigIdHandler}
      />
      <Switch>
        <AllowedRoute
          exact
          path="/contracteventtypeconfigs/operational/add"
          component={() => (
            <ContractOperationalEventTypeConfigDetailsForm isAdd />
          )}
          name="Add ContractOperationalEventTypeConfig"
          type="Add"
        />
        <Route
          exact
          path="/contracteventtypeconfigs/operational/:contractOperationalEventTypeConfigId"
          component={ContractOperationalEventTypeConfig}
        />
        <ContractOperationalEventTypePromptContextProvider>
          <Route
            path="/contracteventtypeconfigs/operational/:contractOperationalEventTypeConfigId/prompt/:contractOperationalEventTypePromptId"
            component={ContractOperationalEventTypePromptIdHandler}
          />
          <Switch>
            <Route
              exact
              path="/contracteventtypeconfigs/operational/:contractOperationalEventTypeConfigId/prompt/add"
              component={() => <ContractOperationalEventTypePromptForm isAdd />}
            />
            <Route
              exact
              path="/contracteventtypeconfigs/operational/:contractOperationalEventTypeConfigId/prompt/:contractOperationalEventTypePromptId"
              component={ContractOperationalEventTypePrompt}
            />
          </Switch>
        </ContractOperationalEventTypePromptContextProvider>
      </Switch>
    </ContractOperationalEventTypeConfigListContextProvider>
  );
};
