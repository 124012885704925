import React from 'react';
import {NotificationConfigOperationalEscalationContext} from './NotificationConfigOperationalEscalationContext';
import {
  TemplateTable,
  TemplateTableConfig,
  TOnInlineAdd,
} from '../../../factory/template';
import {Listing, ContractContactGroup} from '@onroadvantage/onroadvantage-api';
import {escalationNotificationConfigApi} from '../../../api';
import {useAppNotifications} from '../../../contexts';
import {RoleService} from '../../../service';

export const NotificationConfigOperationalEscalationContractContacts: React.FC =
  () => {
    const notify = useAppNotifications();
    const {
      notificationConfigEventEscalation,
      notificationConfigEventEscalationId,
      loading,
      loadNotificationConfigEventEscalation,
    } = React.useContext(NotificationConfigOperationalEscalationContext);
    const [localLoading, setLocalLoading] = React.useState<boolean>(false);

    const [config] = React.useState<TemplateTableConfig<ContractContactGroup>>({
      columns: [
        {
          name: 'contactGroup',
          label: 'Contact Group Name',
          type: 'string',
          model: 'ContractContactGroup',
          enableEditing: true,
          enableAutocomplete: true,
          enableMulti: true,
          getValue: ({contactGroup}) => contactGroup?.name,
        },
        {
          name: 'contract',
          label: 'Contract Name',
          type: 'string',
          enableEditing: false,
          getValue: ({contract}) => contract?.name,
        },
      ],
      disableToolbar: true,
      disablePagination: true,
      deleteMessage: (row) => ({
        title: 'Notification Config Task Contract Contact',
        items: {
          ContactGroup: row.contactGroup?.name,
          ContractName: row.contract?.name,
          ContractCode: row.contract?.code,
        },
      }),
      identifier:
        'OPERATIONAL_ESCALATION_NOTIFICATION_CONFIG_CONTRACT_CONTACTS_LIST',
    });

    const handleInlineAdd = React.useCallback<TOnInlineAdd>(
      async (changes) => {
        setLocalLoading(true);
        try {
          for (const change of changes) {
            const contractContactListings: Listing[] = change.contactGroup;
            const contractContactIds = contractContactListings
              .filter(({value}) => value)
              .map(({value}) => value as number);
            if (notificationConfigEventEscalationId) {
              await escalationNotificationConfigApi.apiEscalationNotificationConfigEscalationNotificationConfigIdAssignContractContactsPost(
                {
                  escalationNotificationConfigId:
                    notificationConfigEventEscalationId,
                  body: {contractContactIds},
                }
              );
              await loadNotificationConfigEventEscalation();
              notify('success', 'Successfully assigned contract');
            }
          }
        } catch (e) {
          notify('error', e.message ?? 'Failed to assign contracts');
        } finally {
          setLocalLoading(false);
        }
      },
      [
        loadNotificationConfigEventEscalation,
        notificationConfigEventEscalationId,
        notify,
      ]
    );

    const handleInlineDelete = React.useCallback(
      async (row: ContractContactGroup) => {
        setLocalLoading(true);
        try {
          if (notificationConfigEventEscalationId && row.id) {
            await escalationNotificationConfigApi.apiEscalationNotificationConfigEscalationNotificationConfigIdUnassignContractContactPost(
              {
                escalationNotificationConfigId:
                  notificationConfigEventEscalationId,
                body: {contractContactId: row.id},
              }
            );
            await loadNotificationConfigEventEscalation();
            notify('success', 'Successfully unassigned contract');
          }
        } catch (e) {
          notify('error', e.message ?? 'Failed to unassign contract');
        } finally {
          setLocalLoading(false);
        }
      },
      [
        loadNotificationConfigEventEscalation,
        notificationConfigEventEscalationId,
        notify,
      ]
    );

    return (
      <TemplateTable
        config={config}
        list={notificationConfigEventEscalation?.contractContactGroups ?? []}
        currentPage={1}
        loading={loading || localLoading}
        onDelete={
          RoleService.hasPermission('Edit NotificationConfig', 'Edit')
            ? handleInlineDelete
            : undefined
        }
        onInlineAdd={
          RoleService.hasPermission('Edit NotificationConfig', 'Edit')
            ? handleInlineAdd
            : undefined
        }
      />
    );
  };
