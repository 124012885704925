import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from '@mui/material';

const EventsPaneSearchStyles = (theme: Theme) =>
  createStyles({
    chipActions: {
      display: 'flex',
      gap: theme.spacing(1.5),
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    chipButton: {borderRadius: theme.spacing(2)},
    chipButtonSelected: {
      borderRadius: theme.spacing(2),
      padding: theme.spacing(0.5),
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(1),
    },
    divider: {marginTop: theme.spacing(2)},
    dividerCollapsed: {marginTop: theme.spacing(0)},
    field: {marginBottom: theme.spacing(1.3)},
    dateRangeValueField: {flex: 1},
    dateRangeUnitField: {flex: 0.5},
    formCard: {marginTop: theme.spacing(1.5)},
    root: {
      display: 'flex',
    },
    startIcon: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '50%',
      padding: theme.spacing(0.5),
    },
    content: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      marginLeft: theme.spacing(1.5),
      marginRight: theme.spacing(1.5),
      minHeight: 0,
      height: '100%',
      maxHeight: '750px',
      overflow: 'auto',
      scrollbarWidth: 'none',
    },
  });

export const useEventsPaneSearchStyles = makeStyles(EventsPaneSearchStyles, {
  name: 'EventsPaneSearchStyles',
});
