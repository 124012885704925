import React from 'react';
import {NotificationConfigContext} from '../NotificationConfigContext';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../../factory/template';
import {EscalationNotificationConfig} from '@onroadvantage/onroadvantage-api';
import {NotificationConfigCriticalEscalationContext} from './NotificationConfigCriticalEscalationContext';

export const NotificationConfigCriticalEscalationTriggersList: React.FC =
  () => {
    const {notificationConfig} = React.useContext(NotificationConfigContext);

    const config: TemplateTableConfig<EscalationNotificationConfig> =
      React.useMemo(
        () => ({
          columns: [
            {
              name: 'criticalEventEscalationConfig',
              label: 'Event Type Name',
              type: 'string',
              model: ['criticalEventEscalationConfig'],
              enableEditing: false,
              enableAutocomplete: true,
              getValue: ({criticalEventEscalationConfig}) =>
                criticalEventEscalationConfig?.contractCriticalEventTypeConfig
                  ?.criticalEventType?.name,
            },
            {
              name: 'contractCode',
              label: 'Contract Code',
              type: 'string',
              enableEditing: false,
              getValue: ({criticalEventEscalationConfig}) =>
                criticalEventEscalationConfig?.contractCriticalEventTypeConfig
                  ?.contract?.code,
            },
            {
              name: 'type',
              label: 'Type',
              type: 'string',
              enableEditing: false,
              getValue: ({criticalEventEscalationConfig}) =>
                criticalEventEscalationConfig?.type,
            },
            {
              name: 'notifyContractContactGroups',
              label: 'Contract Contact Groups',
              type: 'bool',
              enableEditing: true,
            },
            {
              name: 'notifyCustomerContactGroups',
              label: 'Customer Contact Groups',
              type: 'bool',
              enableEditing: true,
            },
            {
              name: 'notifyNodeContactGroups',
              label: 'Node Contact Groups',
              type: 'bool',
              enableEditing: true,
            },
            {
              name: 'notifyVehicleGroupContactGroups',
              label: 'Vehicle Group Contact Groups',
              type: 'bool',
              enableEditing: true,
            },
            {
              name: 'notifyDriver',
              label: 'Driver',
              type: 'bool',
              enableEditing: true,
            },
            {
              name: 'notifyVehicle',
              label: 'Vehicle',
              type: 'bool',
              enableEditing: true,
            },
            {
              name: 'notifyWeb',
              label: 'Web',
              type: 'bool',
              enableEditing: true,
            },
            {
              name: 'notifyNextStop',
              label: 'Next Stop',
              type: 'bool',
              enableEditing: true,
            },
            {
              name: 'notifyOffloadingStops',
              label: 'Offload Stops',
              type: 'bool',
              enableEditing: true,
            },
          ],
          disablePagination: true,
          disableToolbar: true,
          deleteMessage: (row) => ({
            title: 'Notification Config Event',
            items: {
              NotificationConfigName: notificationConfig?.name,
              Type: row?.criticalEventEscalationConfig?.type,
              Threshold: row?.criticalEventEscalationConfig?.threshold,
            },
          }),
          identifier: 'NOTIFICATION_CONFIG_CRITICAL_ESCALATION_TRIGGERS_LIST',
        }),
        [notificationConfig?.name]
      );

    return (
      <TemplateTableWrapper
        config={config}
        context={NotificationConfigCriticalEscalationContext}
      />
    );
  };
