import React from 'react';
import {Theme} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import InfoIcon from '@mui/icons-material/Info';
import ListItemText from '@mui/material/ListItemText';
import {TaskContext} from '../../TaskContext';

const styles = createStyles((theme: Theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

interface JSONPayloadProps {
  keyName: string;
  valueName: string;
}

const JSONPayloadCard: React.FC<JSONPayloadProps> = ({keyName, valueName}) => {
  const {task} = React.useContext(TaskContext);

  const [key, setKey] = React.useState<string>('');
  const [value, setValue] = React.useState<string>('');

  React.useEffect(() => {
    if (task) {
      if (typeof task.payload === 'string') {
        setKey('Successful');
        setValue('Successful');
      } else if (task.payload) {
        setKey(`${JSON.parse(task.payload)[keyName]}`);
        setValue(`${JSON.parse(task.payload)[valueName]}`);
      }
    }
  }, [keyName, setKey, setValue, task, valueName]);

  return (
    <div style={{paddingTop: 10}}>
      <Card>
        <CardContent>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <InfoIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={`${key}`} secondary={`${value}`} />
          </ListItem>
        </CardContent>
      </Card>
    </div>
  );
};

export default withStyles(styles)(JSONPayloadCard);
