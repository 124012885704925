import React from 'react';
import {RoleContext} from './RoleContext';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../factory/template';
import {Role} from '@onroadvantage/onroadvantage-api';

export const RoleList: React.FC = () => {
  const {loadList, cleanupList} = React.useContext(RoleContext);

  const [config] = React.useState<TemplateTableConfig<Role>>({
    columns: [
      {name: 'name', label: 'Role Name', type: 'string'},
      {name: 'description', label: 'Role Description', type: 'string'},
    ],
    enableFilter: true,
    enableSort: true,
    deleteMessage: (row) => ({title: 'Role', items: {Name: row.name}}),
    identifier: 'ROLE_LIST',
  });

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [loadList, cleanupList]);

  return <TemplateTableWrapper config={config} context={RoleContext} />;
};
