import React from 'react';
import {MobXProviderContext} from 'mobx-react';
import TabStore from './TabStore';
import AppNotificationStore from './AppNotificationStore';
import MapDisplayStore from './MapDisplayStore';
import TimelineDisplayStore from './TimelineDisplayStore';
import ColorDisplayStore from './ColorDisplayStore';
import CriticalEventsStore from './CriticalEventActivitiesStore';
import OperationalEventsStore from './OperationalEventActivitiesStore';
import PlanningSolutionStore from './PlanningSolutionStore';
import TripTrackingTypeStore from './TripTrackingTypeStore';

// @ts-expect-error upgrade
export const tabStore = new TabStore();
// @ts-expect-error upgrade
export const appNotificationStore = new AppNotificationStore();
// @ts-expect-error upgrade
export const timelineDisplayStore = new TimelineDisplayStore();
// @ts-expect-error upgrade
export const colorDisplayStore = new ColorDisplayStore();
// @ts-expect-error upgrade
export const criticalEventsStore = new CriticalEventsStore();
// @ts-expect-error upgrade
export const operationalEventsStore = new OperationalEventsStore();
// @ts-expect-error upgrade
export const planningSolutionStore = new PlanningSolutionStore();
// @ts-expect-error upgrade
export const tripTrackingTypeStore = new TripTrackingTypeStore();
// @ts-expect-error upgrade
export const mapDisplayStore = new MapDisplayStore();

export function useStores() {
  return React.useContext(MobXProviderContext);
}
