import React from 'react';
import {useEventsPaneStyles} from '../eventsPane/EventsPane.style';
import {EventsBulkClosingContextProvider} from './EventsBulkClosingContext';
import {EventsBulkClosing} from './EventsBulkClosing';

export const EventsBulkClosingController: React.FC = () => {
  const classes = useEventsPaneStyles();
  return (
    <div className={classes.root}>
      <EventsBulkClosingContextProvider>
        <EventsBulkClosing />
      </EventsBulkClosingContextProvider>
    </div>
  );
};
