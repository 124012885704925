import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import {Theme} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import Card from '@mui/material/Card';

import {connect, FormikProps} from 'formik';
import {
  usePlanningSolutionData,
  usePlanningSolutionViewStore,
} from '../../../stores/context';
import {PlanningSolutionHeader} from './PlanningSolutionHeader';
import {PlanningSolutionOrders} from './PlanningSolutionOrders';
import {PlanningSolutionVehicles} from './PlanningSolutionVehicles';
import {PlanningSolutionDrivers} from './PlanningSolutionDrivers';
import {Loader} from '../../loader';
import {PlanningSolutionTrips} from './PlanningSolutionTrips';
import {PlanningSolutionFormValues} from './PlanningSolutionForm';
import {PlanningSolutionFormActions} from './PlanningSolutionFormActions';
import {appNotificationStore} from '../../../stores/mobxStores';
import queryString from 'query-string';
import history from '../../../service/history';
import _ from 'lodash';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    footer: {
      borderTopColor: theme.palette.divider,
      borderTopStyle: 'solid',
      borderTopWidth: 1,
    },
    header: {
      display: 'flex',
      flowDirection: 'row',
      justifyContent: 'space-between',
      paddingRight: theme.spacing(3),

      borderBottomColor: theme.palette.divider,
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
    },
    tabs: {},
  });

interface IFormikProps {
  formik: FormikProps<PlanningSolutionFormValues>;
}

type Props = WithStyles<typeof styles>;

const PlanningSolutionComponent: React.FC<Props & IFormikProps> = ({
  classes,
  formik: {isSubmitting, dirty, resetForm},
}) => {
  /** set which tab is being used */
  /** sets whether the external data is being loaded */
  const {isSolutionLoading, isMDLoading, tabValue, setTabValue} =
    usePlanningSolutionViewStore((store) => ({
      isSolutionLoading: store.solutionIsLoading,
      isMDLoading: store.solutionMDIsLoading,
      tabValue: store.solutionTabValue,
      setTabValue: store.setSolutionTabValue,
    }));

  const displayNotification = React.useCallback((message: any) => {
    appNotificationStore.enqueueNotification(message.type, message.value);
  }, []);

  const tabChange = (event: any, value: number) => {
    if (value === 1 && dirty) {
      displayNotification({
        type: 'warning',
        value: 'Please save the solution before editing trips.',
      });

      return;
    }

    setTabValue(value);
  };

  /** set the current planning solution */
  const planningSolution = usePlanningSolutionData(
    (store) => store.planningSolution
  );

  const isLoading = isSolutionLoading || isMDLoading || isSubmitting;

  /** specifies whether the data is ready to be displayed */
  const isDisplayReady = !isLoading; // || planningSolution;

  /** set whether there is a solution or not */
  const isNotFound = isDisplayReady && !planningSolution;

  React.useEffect(() => {
    const {location} = history;
    const queryStr = queryString.parse(location.search);
    if (queryStr.resetForm && queryStr.resetForm === '1') {
      resetForm();
      if (queryStr.planningMasterTripId) {
        const planningMasterTrip = _.find(
          planningSolution?.planningMasterTrips,
          ['id', +queryStr.planningMasterTripId]
        );
        history.push(`${location.pathname}/t/${planningMasterTrip?.trip?.id}`);
      } else {
        history.push(location.pathname);
      }
    }
  }, [resetForm, planningSolution?.planningMasterTrips]);

  return (
    <Card className={classes.container} elevation={0}>
      <div className={classes.header}>
        <Tabs
          value={tabValue}
          onChange={tabChange}
          indicatorColor="primary"
          textColor="primary"
          className={classes.tabs}
        >
          <Tab
            label="Header"
            disabled={(!isDisplayReady && isLoading) || isSubmitting}
          />
          <Tab
            label="Trips"
            disabled={(!isDisplayReady && isLoading) || isSubmitting}
          />
          <Tab
            label="Orders"
            disabled={(!isDisplayReady && isLoading) || isSubmitting}
          />
          <Tab
            label="Vehicles"
            disabled={(!isDisplayReady && isLoading) || isSubmitting}
          />
          <Tab
            label="Drivers"
            disabled={(!isDisplayReady && isLoading) || isSubmitting}
          />
        </Tabs>
      </div>
      {/* TODO expand not found */}
      {isNotFound && <div>Not Found</div>}
      {isLoading && <Loader />}
      {isDisplayReady && tabValue === 0 && <PlanningSolutionHeader />}
      {isDisplayReady && tabValue === 1 && <PlanningSolutionTrips />}
      {isDisplayReady && tabValue === 2 && <PlanningSolutionOrders />}
      {isDisplayReady && tabValue === 3 && <PlanningSolutionVehicles />}
      {isDisplayReady && tabValue === 4 && <PlanningSolutionDrivers />}
      <PlanningSolutionFormActions />
    </Card>
  );
};

// eslint-disable-next-line import/prefer-default-export
export const PlanningSolution = withStyles(styles)(
  connect<Props, PlanningSolutionFormValues>(PlanningSolutionComponent)
);
