import React from 'react';
import {OrderRecipientContext} from '../OrderRecipientContext';
import {RecipientOrderDetails} from './RecipientOrderDetails';
import {useRecipientDetailsStyles} from './RecipientDetails.style';
import {RecipientDriverDetails} from './RecipientDriverDetails';
import {RecipientCard} from '../recipientCard';
import {BusinessCenter} from '@mui/icons-material';

export const RecipientDetails: React.FC = () => {
  const {orders, driver} = React.useContext(OrderRecipientContext);
  const classes = useRecipientDetailsStyles();
  const [activeOrder, setActiveOrder] = React.useState<string>();

  const changeActiveOrderHandler = React.useCallback(
    (orderNumber: string | undefined) => {
      setActiveOrder(orderNumber);
    },
    []
  );

  return (
    <div className={classes.details}>
      {driver && <RecipientDriverDetails {...driver} />}
      {orders.length === 0 ? (
        <RecipientCard
          title="There are no orders"
          avatar={<BusinessCenter />}
          details={[]}
          disableCollapse
          data-cy="RecipientOrderNoDetails"
        />
      ) : (
        orders.map((order) => (
          <RecipientOrderDetails
            key={order.orderNumber}
            activeOrder={activeOrder}
            changeActiveOrderHandler={changeActiveOrderHandler}
            {...order}
          />
        ))
      )}
    </div>
  );
};
