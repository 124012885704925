import React from 'react';
import {ProductGroupForm} from './ProductGroupForm';
import {ITemplateTabControllerTab, TemplateTabs} from '../../factory/template';
import {ProductGroupContext} from './ProductGroupContext';
import {ProductGroupProducts} from './productGroupProducts';

export const ProductGroup: React.FC = () => {
  const {productGroupId, loading} = React.useContext(ProductGroupContext);

  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {title: 'Details', element: <ProductGroupForm />},
    {title: 'Products', element: <ProductGroupProducts />},
  ]);

  const path = React.useMemo(
    () => `/productgrouplist/${productGroupId}`,
    [productGroupId]
  );

  return <TemplateTabs tabs={tabs} path={path} loading={loading} />;
};
