import React from 'react';
import {PlanningSkillProductGroupMinimal} from '@onroadvantage/onroadvantage-api';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../../factory/template';
import {
  PlanningSkillProductGroupListContext,
  usePlanningSkillProductGroupListContext,
} from './PlanningSkillProductGroupListContext';
import {PlanningSkillContext} from '../PlanningSkillContext';

export const PlanningSkillProductGroupList: React.FC = () => {
  const {planningSkill} = React.useContext(PlanningSkillContext);
  const {loadList, cleanupList} = usePlanningSkillProductGroupListContext();

  const [config] = React.useState<
    TemplateTableConfig<PlanningSkillProductGroupMinimal>
  >({
    columns: [
      {
        name: 'name',
        label: 'Name',
        type: 'string',
        enableEditing: true,
        enableAutocomplete: true,
        enableMulti: true,
        model: 'ProductGroup',
      },
      {name: 'description', label: 'Description', type: 'string'},
    ],
    enableFilter: true,
    enableSort: true,
    deleteMessage: (row) => ({
      title: 'Planning Skill Product Group',
      items: {
        PlanningSkill: planningSkill?.name,
        Name: row.name,
        Description: row.description,
      },
    }),
    identifier: 'PLANNING_SKILL_PRODUCT_GROUP_LIST',
  });

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [cleanupList, loadList]);

  return (
    <TemplateTableWrapper
      config={config}
      context={PlanningSkillProductGroupListContext}
    />
  );
};
