import React from 'react';
import {
  criticalEventApi,
  getAuthHeaderValues,
  operationalEventApi,
} from '../../api';
import {
  CriticalEventDump,
  OperationalEventDump,
} from '@onroadvantage/onroadvantage-api';
import {useAppNotifications} from '../../contexts';
import {mapDisplayStore} from '../../stores/mobxStores';
import EventService from '../../service/EventService';
import FileSaver from 'file-saver';

export interface EventContextProps {
  event: CriticalEventDump | OperationalEventDump | undefined;
  setEvent: React.Dispatch<
    React.SetStateAction<CriticalEventDump | OperationalEventDump | undefined>
  >;
  type: string;
  loading: boolean;
  invalid: boolean;
  loadEvent: () => Promise<void>;
  downloadEventReport: () => Promise<void>;
}

export const EventContext = React.createContext<EventContextProps>({
  loading: false,
  invalid: false,
  event: undefined,
  type: '',
  setEvent: () => {},
  loadEvent: async () => {},
  downloadEventReport: async () => {},
});

interface EventContextProviderProps {
  type: string;
  id: string;
}

export const EventContextProvider: React.FC<EventContextProviderProps> = ({
  type,
  id,
  children,
}) => {
  const [invalid, setInvalid] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [event, setEvent] = React.useState<
    CriticalEventDump | OperationalEventDump
  >();
  const numericId = id ? parseInt(id, 10) : undefined; // Convert to number if not undefined
  const notify = useAppNotifications();

  const loadEvent = React.useCallback(async () => {
    setLoading(true);
    try {
      if (type === 'critical' && numericId) {
        const response =
          await criticalEventApi.apiCriticalEventCriticalEventIdGet({
            criticalEventId: numericId,
          });
        if (response) {
          setEvent(response);

          await mapDisplayStore.setOpenEvent({
            ...response,
            mapType: 'Critical',
          });
        } else {
          setInvalid(true);
        }
      } else if (type === 'operational' && numericId) {
        const response =
          await operationalEventApi.apiOperationalEventOperationalEventIdGet({
            operationalEventId: numericId,
          });
        if (response) {
          setEvent(response);
          await mapDisplayStore.setOpenEvent({
            ...response,
            mapType: 'Operational',
          });
        } else {
          setInvalid(true);
        }
      }
    } catch (error) {
      notify('error', 'Failed to fetch event');
    } finally {
      setLoading(false);
    }
  }, [numericId, type, notify]);

  const handleDownload = React.useCallback(async () => {
    setLoading(true);
    try {
      if (type === 'critical' && numericId && getAuthHeaderValues !== null) {
        const criticalEventId = numericId;
        const link = await EventService.getDocumentCriticalLink(
          criticalEventId,
          'Event Incident'
        );
        const headerValues = getAuthHeaderValues();
        const headers = new Headers();
        if (headerValues) headers.set(headerValues.name, headerValues.value);

        const response = await fetch(link, {
          method: 'GET',
          mode: 'cors',
          headers,
        });

        if (response.ok) {
          const blob = await response.blob();
          FileSaver.saveAs(
            blob,
            `EVENT_INCIDENT_REPORT_${criticalEventId}.pdf`
          );
        }
        notify(
          'success',
          'Successfully Downloaded Critical Event Incident Report Pdf'
        );
      } else if (
        type === 'operational' &&
        numericId &&
        getAuthHeaderValues !== null
      ) {
        const operationalEventId = numericId;
        const link = await EventService.getDocumentOperationalLink(
          operationalEventId,
          'Event Incident'
        );
        const headerValues = getAuthHeaderValues();
        const headers = new Headers();
        if (headerValues) headers.set(headerValues.name, headerValues.value);

        const response = await fetch(link, {
          method: 'GET',
          mode: 'cors',
          headers,
        });

        if (response.ok) {
          const blob = await response.blob();
          FileSaver.saveAs(
            blob,
            `EVENT_INCIDENT_REPORT_${operationalEventId}.pdf`
          );
        }
        notify(
          'success',
          'Successfully Downloaded Operational Event Incident Report Pdf'
        );
      }
    } catch (e) {
      notify('error', 'Failed to Download Event Incident Report Pdf');
    } finally {
      setLoading(false);
    }
  }, [notify, setLoading, numericId, type]);

  React.useEffect(() => {
    loadEvent();
    // eslint-disable-next-line
  }, []);

  const value: EventContextProps = {
    loading,
    loadEvent,
    event,
    type,
    invalid,
    setEvent,
    downloadEventReport: handleDownload,
  };

  return (
    <EventContext.Provider value={value}>{children}</EventContext.Provider>
  );
};
