import React from 'react';
import {useContractContext} from '../contractContext';
import {TemplateCard, TemplateForm} from '../../../factory/template';
import {contractTripNotificationsSchema} from '../contractContext';
import {ContractTripNotificationsFormFields} from './contractFormFields';

export const ContractTripNotificationsForm: React.FC = () => {
  const {
    loadingContract,
    tripNotificationsInitialValues,
    onContractTripNotificationsSubmit,
    submitting,
  } = useContractContext();

  return (
    <TemplateCard
      title="Contract Trip Notifications Edit"
      loading={loadingContract}
    >
      <TemplateForm
        initialValues={tripNotificationsInitialValues}
        onSubmit={onContractTripNotificationsSubmit}
        validationSchema={contractTripNotificationsSchema}
        loading={submitting}
        permission={{name: 'Edit Contract', type: 'Edit'}}
      >
        <ContractTripNotificationsFormFields />
      </TemplateForm>
    </TemplateCard>
  );
};
