import React from 'react';
import * as Yup from 'yup';
import {VehicleType} from '@onroadvantage/onroadvantage-api';
import {
  multipleListingSchema,
  NUMBER_ERROR_MESSAGE,
  TemplateCard,
  TemplateForm,
} from '../../factory/template';
import {
  compartmentStrategyListingOptions,
  getStrategy,
  IVehicleTypeForm,
  VehicleTypeContext,
} from './VehicleTypeContext';
import {
  FormikAutocompleteHooks,
  FormikAutocompleteWithListing,
  FormikTextField,
  FormikTimePicker,
} from '../formik';
import {AutocompleteOptionType} from '../autocomplete';
import {LuxonService} from '../../service';

const strategyListingSchema: Yup.SchemaOf<AutocompleteOptionType> = Yup.object({
  label: Yup.string(),
  value: Yup.string(),
});

const schema: Yup.SchemaOf<IVehicleTypeForm> = Yup.object({
  availableEnd: Yup.date(),
  availableStart: Yup.date(),
  compartmentLoadingStrategy: strategyListingSchema.nullable(),
  compartmentOffloadingStrategy: strategyListingSchema.nullable(),
  description: Yup.string().required('Required'),
  externalGroupId: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
  externalId: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
  externalSiteId: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
  name: Yup.string().required('Required'),
  planningDistanceCost: Yup.number()
    .typeError(NUMBER_ERROR_MESSAGE)
    .required('Required'),
  planningFixedCost: Yup.number()
    .typeError(NUMBER_ERROR_MESSAGE)
    .required('Required'),
  planningServiceCost: Yup.number()
    .typeError(NUMBER_ERROR_MESSAGE)
    .required('Required'),
  planningTimeCost: Yup.number()
    .typeError(NUMBER_ERROR_MESSAGE)
    .required('Required'),
  planningWaitCost: Yup.number()
    .typeError(NUMBER_ERROR_MESSAGE)
    .required('Required'),
  planningSkills: multipleListingSchema,
  speedTables: multipleListingSchema,
});

const getInitialValues = (
  vehicleType?: VehicleType | null
): IVehicleTypeForm | undefined => {
  if (vehicleType) {
    return {
      availableEnd: LuxonService.fromServerTimeToLocalTimeDate(
        vehicleType.availableEnd
      ),
      availableStart: LuxonService.fromServerTimeToLocalTimeDate(
        vehicleType.availableStart
      ),
      compartmentLoadingStrategy: getStrategy(
        vehicleType.compartmentLoadingStrategy
      ),
      compartmentOffloadingStrategy: getStrategy(
        vehicleType.compartmentOffloadingStrategy
      ),
      description: vehicleType.description,
      externalGroupId: vehicleType.externalGroupId,
      externalId: vehicleType.externalId,
      externalSiteId: vehicleType.externalSiteId,
      name: vehicleType.name,
      planningDistanceCost: vehicleType.planningDistanceCost ?? '',
      planningFixedCost: vehicleType.planningFixedCost ?? '',
      planningServiceCost: vehicleType.planningServiceCost ?? '',
      planningTimeCost: vehicleType.planningTimeCost ?? '',
      planningWaitCost: vehicleType.planningWaitCost ?? '',
      planningSkills: vehicleType.skills?.map(({id, name, description}) => ({
        value: id ?? undefined,
        label: `${name} - ${description}`,
      })),
      speedTables: vehicleType.speedTables?.map(({id, name}) => ({
        value: id,
        label: name,
      })),
    };
  }
  return undefined;
};

export const VehicleTypeForm: React.FC<{isAdd?: boolean}> = ({isAdd}) => {
  const {detailsRef, loading, submitting, vehicleType, onDetailsFormSubmit} =
    React.useContext(VehicleTypeContext);
  const [initialValues, setInitialValues] = React.useState<IVehicleTypeForm>();

  React.useEffect(() => {
    const values = getInitialValues(vehicleType);
    if (values) {
      setInitialValues(values);
    } else if (isAdd) {
      setInitialValues({
        description: '',
        name: '',
        compartmentLoadingStrategy: undefined,
        compartmentOffloadingStrategy: undefined,
        planningDistanceCost: 0,
        planningFixedCost: 0,
        planningServiceCost: 0,
        planningTimeCost: 0,
        planningWaitCost: 0,
      });
    }
  }, [isAdd, vehicleType, setInitialValues]);

  return (
    <TemplateCard
      title={`VehicleType Details ${isAdd ? 'Add' : 'Edit'}`}
      loading={loading}
    >
      <TemplateForm<IVehicleTypeForm>
        initialValues={initialValues}
        onSubmit={onDetailsFormSubmit}
        permission={{
          name: `${isAdd ? 'Add' : 'Edit'} VehicleType`,
          type: isAdd ? 'Add' : 'Edit',
        }}
        submitting={submitting}
        innerRef={detailsRef}
        validationSchema={schema}
      >
        <FormikTextField
          name="name"
          placeholder="Enter the vehicle type name"
          label="Name"
          fullWidth
        />
        <FormikTextField
          name="description"
          placeholder="Enter the vehicle type description"
          label="Description"
          fullWidth
        />
        <FormikTextField
          name="externalId"
          placeholder="Enter the vehicle type external id"
          label="External Id"
          type="number"
          fullWidth
        />
        <FormikTextField
          name="externalSiteId"
          placeholder="Enter the vehicle type external site id"
          label="External Site Id"
          type="number"
          fullWidth
        />
        <FormikTextField
          name="externalGroupId"
          placeholder="Enter the vehicle type external group id"
          label="External Group Id"
          type="number"
          fullWidth
        />
        <FormikTextField
          name="planningFixedCost"
          placeholder="Enter the vehicle type planning fixed cost"
          label="Planning Fixed Cost"
          fullWidth
        />
        <FormikTextField
          name="planningDistanceCost"
          placeholder="Enter the vehicle type planning distance cost"
          label="Planning Distance Cost"
          fullWidth
        />
        <FormikTextField
          name="planningTimeCost"
          placeholder="Enter the vehicle type planning time cost"
          label="Planning Time Cost"
          fullWidth
        />
        <FormikTextField
          name="planningServiceCost"
          placeholder="Enter the vehicle type planning service cost"
          label="Planning Service Cost"
          fullWidth
        />
        <FormikTextField
          name="planningWaitCost"
          placeholder="Enter the vehicle type planning wait cost"
          label="Planning Wait Cost"
          fullWidth
        />
        <FormikTimePicker
          name="availableStart"
          placeholder="Enter the start time"
          label="Availability Start"
          InputLabelProps={{shrink: true}}
          disableDefaultValue
          fullWidth
        />
        <FormikTimePicker
          name="availableEnd"
          placeholder="Enter the end time"
          label="Availability End"
          InputLabelProps={{shrink: true}}
          disableDefaultValue
          fullWidth
        />
        <FormikAutocompleteWithListing
          name="planningSkills"
          label="Planning Skills"
          placeholder="Enter and select the vehicle type skills"
          model="PlanningSkill"
          multiple
        />
        <FormikAutocompleteWithListing
          name="speedTables"
          label="Speed Tables"
          placeholder="Enter and select the vehicle type speed tables"
          model="SpeedTable"
          multiple
        />
        <FormikAutocompleteHooks
          name="compartmentLoadingStrategy"
          placeholder="Enter and select the compartment loading strategy"
          label="Compartment Loading Strategy"
          options={compartmentStrategyListingOptions}
        />
        <FormikAutocompleteHooks
          name="compartmentOffloadingStrategy"
          placeholder="Enter and select the compartment offloading strategy"
          label="Compartment Offloading Strategy"
          options={compartmentStrategyListingOptions}
        />
      </TemplateForm>
    </TemplateCard>
  );
};
