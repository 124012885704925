import React from 'react';
import {useHistory} from 'react-router-dom';
import {
  TemplateTableContextProps,
  useTemplateTable,
} from '../../../../../factory/template';
import {TripDebriefContext} from '../../../TripDebriefContext';
import {
  MasterTripDebriefSnapshot,
  MasterTripDebriefTripStopSnapshot,
} from '@onroadvantage/onroadvantage-api';

export type TripDebriefSummaryStopListContextProps = TemplateTableContextProps<
  MasterTripDebriefTripStopSnapshot,
  unknown
>;

export const TripDebriefSummaryStopListContext =
  React.createContext<TripDebriefSummaryStopListContextProps>({
    loading: false,
    list: [],
    currentPage: 1,
    loadList: async () => {},
  });

export const TripDebriefSummaryStopListContextProvider: React.FC = ({
  children,
}) => {
  const {masterTripId, masterTripDebriefData} =
    React.useContext(TripDebriefContext);
  /**
   *  Destructure snapshotData, need to add || ({} as MasterTripDebriefSnapshot) for typescript, since
   *  snapshotData is nullable.
   */
  const {stops} =
    masterTripDebriefData?.snapshotData || ({} as MasterTripDebriefSnapshot);
  const history = useHistory();
  // Template Table
  const [
    {
      currentPage,
      pageSize,
      pageTotal,
      sorting,
      hasPermission,
      itemTotal,
      list,
      loading,
      loadingSingleItem,
      filters,
      isDaterangeFilterActive,
    },
    {
      // Handlers
      handleCurrentPageChange,
      handleDateRangeFilterToggle,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setList,
    },
  ] = useTemplateTable<MasterTripDebriefTripStopSnapshot, any>({
    editPermission: 'Edit TripDebrief',
    addPermission: 'Add TripDebrief',
    deletePermission: 'Delete TripDebrief',
    downloadPermission: 'TripDebrief ListDownload',
    viewPermission: 'TripDebrief List',
  });

  const handleNavigate = React.useCallback(
    (row) => history.push(`/tripdebrieflist/${masterTripId}/stop/${row.id}`),
    [history, masterTripId]
  );

  React.useEffect(() => {
    setList(stops ?? []);
    return () => undefined;
  }, [stops, setList]);

  const value: TripDebriefSummaryStopListContextProps = {
    // Template Table
    list,
    loadList: () => {},
    hasPermission,
    loading: loading || loadingSingleItem,
    cleanupList,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    isDaterangeFilterActive,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onDateRangeFilterToggle: handleDateRangeFilterToggle,
    onSortingChange: handleSortingChange,
    onNavigate: handleNavigate,
  };
  return (
    <TripDebriefSummaryStopListContext.Provider value={value}>
      {children}
    </TripDebriefSummaryStopListContext.Provider>
  );
};
