import React from 'react';
import {
  TripDebriefSummaryCard,
  TripDebriefSummaryCardList,
} from './tripDebriefSummaryCard';
import {TripDebriefContext} from '../TripDebriefContext';
import {MasterTripDebriefSnapshot} from '@onroadvantage/onroadvantage-api';

export const TripDebriefSummaryDriver: React.FC = () => {
  const {masterTripDebriefData} = React.useContext(TripDebriefContext);
  /**
   *  Destructure snapshotData, need to add || ({} as MasterTripDebriefSnapshot) for typescript, since
   *  snapshotData is nullable.
   */
  const {driver} =
    masterTripDebriefData?.snapshotData || ({} as MasterTripDebriefSnapshot);

  const list: TripDebriefSummaryCardList = React.useMemo(
    () => [
      {
        primary: 'Full Name',
        secondary:
          driver?.externalName ??
          `${driver?.firstname ?? ''} ${driver?.lastname ?? ''}`,
        type: 'string',
        changed: false,
      },
      {
        primary: 'Tag number',
        secondary: driver?.externalExtendedId,
        type: 'string',
        changed: false,
      },
      {
        primary: 'Employee number',
        secondary: driver?.externalEmployeeNumber,
        type: 'string',
        changed: false,
      },
    ],
    [driver]
  );

  return <TripDebriefSummaryCard title="Driver" list={list} />;
};
