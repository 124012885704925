import React from 'react';
import {
  WebMasterTripDetailSingle,
  WebMasterTripOptimised,
} from '@onroadvantage/onroadvantage-api';
import {TripListContext} from './TripListContext';
import {TemplateTable, TemplateTableConfig} from '../../../factory/template';

interface TripListDetailProps {
  row: WebMasterTripOptimised;
}

export const TripListDetail: React.FC<TripListDetailProps> = ({row: trip}) => {
  const {loadDetail} = React.useContext(TripListContext);
  const [detailList, setDetailList] = React.useState<
    WebMasterTripDetailSingle[]
  >([]);
  const [detailLoading, setDetailLoading] = React.useState(true);

  const [config] = React.useState<
    TemplateTableConfig<WebMasterTripDetailSingle>
  >({
    columns: [
      {name: 'orderNumbers', label: 'Order Number', type: 'string'},
      {name: 'sequence', label: 'Stop Sequence', type: 'number'},
      {name: 'upliftNodeNames', label: 'Loading Location', type: 'string'},
      {name: 'offloadNodeNames', label: 'Offloading Location', type: 'string'},
    ],
    disableToolbar: true,
    disablePagination: true,
  });

  const handleDetailLoad = React.useCallback(async () => {
    setDetailLoading(true);
    try {
      const response = await loadDetail(trip.id);
      if (response) setDetailList(response);
    } finally {
      setDetailLoading(false);
    }
  }, [loadDetail, trip.id, setDetailLoading]);

  React.useEffect(() => {
    handleDetailLoad();
    return () => setDetailList([]);
  }, [handleDetailLoad]);

  return (
    <TemplateTable
      config={config}
      currentPage={1}
      elevation={0}
      nestedLevel={1}
      list={detailList}
      loading={detailLoading}
    />
  );
};
