import React from 'react';
import Card from '@mui/material/Card';
import {SettingService} from '../../../service';
import AdhocMapDisplayComponent from './AdhocMapDisplayComponent';

import Paper from '@mui/material/Paper';
import {RowDetailState, TableColumnWidthInfo} from '@devexpress/dx-react-grid';

import {
  TableHeaderRow,
  TableRowDetail,
  Grid,
  Table,
} from '@devexpress/dx-react-grid-material-ui';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {mapDisplayStore} from '../../../stores/mobxStores';
import {inject, observer} from 'mobx-react';

const VIEW_ID = 'SiteList';
const COL_VISIBILITY_SETTING_ID = 'col_visibility';
const DEFAULT_HIDDEN_COLUMNS: any[] = [];

export interface MapSelectionItem {
  active: boolean;
  contract: any;
  description: string;
  externalGroupId: any | null;
  externalId: any | null;
  externalSiteId: any | null;
  id: number;
  lastOdometer: any | null;
  lastSeen: {
    eventDate: Date;
    latitude: number;
    longitude: number;
  };
  profileId: number;
  registrationNumber: string;
  telematicsConfigId: number;
  vinNumber: any | null;
}

interface GroupMessageType {
  hasNext: boolean;
  hasPrev: boolean;
  items: {
    groupName: string;
    messageType: string;
    groupedBy: string;
    drivers: any[];
    id: number;
  }[];
  page: number;
  pages?: number;
  perPage: number;
  total: number;
}

interface Props {
  onNext: (drivers: any) => void;
  updateGeoSelectionLayers: (items: MapSelectionItem[]) => void;
}

interface State {
  columns: {name: string; title: string}[];
  columnWidths: Array<TableColumnWidthInfo>;
  items: any[];
  editingRowIds: any[];
  pages?: number;
  perPages: number[];
  perPage: number;
  currentPage: number;
  totalCount: number;
  loading: boolean;
  sorting: any[];
  filters: any[];
  rowChanges: any;
  expandedRowIds: any[];
  hiddenColumns: any;
}

const Cell = (props: {value: any}) => {
  // @ts-expect-error upgrade  //Table.Cell works in regular JS but needs some ts love or something
  return <Table.Cell {...props} value={props.value} />;
};

class GroupSelectMessageStep extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const mockResponse: GroupMessageType = {
      hasNext: false,
      hasPrev: false,
      items: [
        {
          groupName: 'Vehicles',
          messageType: 'Location',
          groupedBy: '',
          drivers: [],
          id: 2,
        },
      ],
      page: 1,
      pages: 1,
      perPage: 20,
      total: 1,
    };

    this.state = {
      columns: [
        {name: 'groupName', title: 'Group Name'},
        {name: 'messageType', title: 'Message Type'},
        {name: 'groupedBy', title: 'Grouped by'},
      ],
      columnWidths: [
        {columnName: 'groupName', width: 100},
        {columnName: 'messageType', width: 100},
        {columnName: 'groupedBy', width: 100},
      ],
      items: mockResponse.items,
      editingRowIds: [],
      perPages: [25, 50, 100],
      perPage: 50,
      currentPage: 1,
      totalCount: 0,
      loading: false,
      sorting: [],
      filters: [],
      rowChanges: {},
      expandedRowIds: [],
      hiddenColumns: this.loadColumnVisibility() || DEFAULT_HIDDEN_COLUMNS,
    };
  }

  componentDidMount = () => {
    // this.loadData({});
  };

  changeColumnWidths = (nextColumnWidths: Array<TableColumnWidthInfo>) => {
    this.setState({columnWidths: nextColumnWidths});
  };

  loadColumnVisibility = () => {
    const hiddenColumns = SettingService.getSetting(
      VIEW_ID,
      COL_VISIBILITY_SETTING_ID
    );
    if (hiddenColumns) {
      return hiddenColumns;
    }
    return null;
  };

  onNext = () => {
    const geoSelectionItems =
      (mapDisplayStore.geoSelectionItems &&
        Object.values(mapDisplayStore.geoSelectionItems)) ||
      [];
    this.props.onNext(geoSelectionItems);
  };

  onExpandedRowIdsChange = (expandedRowIds: React.ReactText[]) =>
    this.setState({
      expandedRowIds: [expandedRowIds[expandedRowIds.length - 1]],
    });

  changeCurrentPage = () => {
    this.setState({
      loading: true,
      items: [],
    });
  };

  changePerPage = (perPage: number) => {
    this.setState({
      loading: true,
      items: [],
      perPage,
    });
  };

  changeSorting = (sorting: any) => {
    this.setState({
      loading: true,
      items: [],
      sorting,
    });
  };

  changeFilters = (filters: any) => {
    const query: any = {};
    this.setState({
      loading: true,
      items: [],
      filters,
    });
    filters.forEach((value: {columnName: string; value: any}) => {
      query[value.columnName] = value.value;
    });
    query.page = 1;
  };

  render() {
    const {columns, items = [], expandedRowIds} = this.state;

    const geoSelectionItems = mapDisplayStore.geoSelectionItems;
    const parsedGeoSelectionItems = geoSelectionItems
      ? Object.values(geoSelectionItems)
      : [];

    const TableRow = ({row, ...restProps}: any) => (
      <Table.Row {...restProps}>{restProps.children}</Table.Row>
    );
    const RowDetail = () => (
      <div style={{flex: 1, justifyContent: 'space-between'}}>
        <AdhocMapDisplayComponent zoom={20} items={parsedGeoSelectionItems} />
      </div>
    );

    const disabled = parsedGeoSelectionItems.length === 0;

    return (
      <Card>
        <Paper>
          <Grid rows={items} columns={columns}>
            <Table
              cellComponent={Cell}
              rowComponent={TableRow}
              noDataRowComponent={() => <div>No Data</div>}
            />
            <RowDetailState
              expandedRowIds={expandedRowIds}
              onExpandedRowIdsChange={this.onExpandedRowIdsChange}
            />
            <TableHeaderRow />
            <TableRowDetail
              contentComponent={RowDetail}
              toggleCellComponent={(props) => {
                return (
                  <TableRowDetail.ToggleCell
                    {...props}
                    data-test={`expand-row-${props.row.id}`}
                  />
                );
              }}
            />
          </Grid>
        </Paper>
        <div style={{paddingTop: 20}}>
          <CardActions>
            <div style={{color: disabled ? 'grey' : undefined}}>
              <Button onClick={this.onNext} disabled={disabled}>
                <Typography>Next</Typography>
              </Button>
            </div>
          </CardActions>
        </div>
      </Card>
    );
  }
}

export default inject('mapDisplayStore')(observer(GroupSelectMessageStep));
