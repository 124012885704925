import React from 'react';
import {
  initialSelectedGroupOptions,
  initialUnselectedGroupOptions,
  IPlanningBoardGroupOptionItem,
} from '../planningBoardOptions';

export const usePlanningBoardSettings = () => {
  const [enableCheckTripActuals, setEnableCheckTripActuals] =
    React.useState<boolean>(false);
  const [enableCheckTripEnd, setEnableCheckTripEnd] = React.useState<boolean>(
    localStorage.getItem('enableCheckTripEnd') === 'true'
  );
  const [enableGanttFullscreen, setEnableGanttFullscreen] =
    React.useState<boolean>(false);
  const [isOptionsOpen, setIsOptionsOpen] = React.useState<boolean>(false);
  const [selectedGroupOptions, setSelectedGroupOptions] = React.useState<
    IPlanningBoardGroupOptionItem[]
  >(
    JSON.parse(
      localStorage.getItem('selectedItems') ?? '[]'
    ) as IPlanningBoardGroupOptionItem[]
  );
  const [unselectedGroupOptions, setUnselectedGroupOptions] = React.useState<
    IPlanningBoardGroupOptionItem[]
  >(
    JSON.parse(
      localStorage.getItem('unselectedItems') ?? '[]'
    ) as IPlanningBoardGroupOptionItem[]
  );

  // helpers
  const handleResetGroupOptions = React.useCallback(() => {
    setSelectedGroupOptions(initialSelectedGroupOptions);
    setUnselectedGroupOptions(initialUnselectedGroupOptions);
    localStorage.setItem(
      'selectedItems',
      JSON.stringify(initialSelectedGroupOptions)
    );
    localStorage.setItem(
      'unselectedItems',
      JSON.stringify(initialUnselectedGroupOptions)
    );
  }, []);

  const handleToggleEnableCheckTripEnd = React.useCallback(() => {
    setEnableCheckTripEnd((prevEnableCheckTripEnd) => {
      localStorage.setItem(
        'enableCheckTripEnd',
        (!prevEnableCheckTripEnd).toString()
      );
      return !prevEnableCheckTripEnd;
    });
  }, []);

  const handleToggleEnableGanttFullscreen = React.useCallback(() => {
    setEnableGanttFullscreen(
      (prevEnableGanttFullscreen) => !prevEnableGanttFullscreen
    );
  }, []);

  React.useEffect(() => {
    setEnableCheckTripEnd(
      localStorage.getItem('enableCheckTripEnd') === 'true'
    );
    const localGroupOptions = JSON.parse(
      localStorage.getItem('selectedItems') ?? '[]'
    ) as IPlanningBoardGroupOptionItem[];
    setSelectedGroupOptions(
      localGroupOptions.length > 0
        ? localGroupOptions
        : initialSelectedGroupOptions
    );
    return () => {
      setSelectedGroupOptions([]);
      setIsOptionsOpen(false);
    };
  }, []);

  return {
    enableCheckTripActuals,
    enableCheckTripEnd,
    enableGanttFullscreen,
    isOptionsOpen,
    resetGroupOptions: handleResetGroupOptions,
    selectedGroupOptions,
    setEnableCheckTripActuals,
    setEnableCheckTripEnd,
    setIsOptionsOpen,
    setSelectedGroupOptions,
    setUnselectedGroupOptions,
    toggleEnableCheckTripEnd: handleToggleEnableCheckTripEnd,
    toggleEnableGanttFullscreen: handleToggleEnableGanttFullscreen,
    unselectedGroupOptions,
  };
};

export type usePlanningBoardSettingsResponse = ReturnType<
  typeof usePlanningBoardSettings
>;

export const usePlanningBoardSettingsResponseInitial: usePlanningBoardSettingsResponse =
  {
    enableCheckTripActuals: false,
    enableCheckTripEnd: false,
    enableGanttFullscreen: false,
    isOptionsOpen: false,
    resetGroupOptions: () => {},
    selectedGroupOptions: JSON.parse(
      localStorage.getItem('selectedItems') ?? '[]'
    ) as IPlanningBoardGroupOptionItem[],
    setEnableCheckTripActuals: () => {},
    setEnableCheckTripEnd: () => {},
    setIsOptionsOpen: () => {},
    setSelectedGroupOptions: () => {},
    setUnselectedGroupOptions: () => {},
    toggleEnableCheckTripEnd: () => {},
    toggleEnableGanttFullscreen: () => {},
    unselectedGroupOptions: JSON.parse(
      localStorage.getItem('unselectedItems') ?? '[]'
    ) as IPlanningBoardGroupOptionItem[],
  };
