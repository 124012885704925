import ConfigService from './ConfigService/ConfigService';

import ResponseService from './ResponseService';
import {encodeUrl, flatten} from './Util';
import {authStore} from '../store';

const serverUrl = ConfigService.serverUri;
const queryUrl = `${serverUrl}/api/contract`;

const ContractService = {
  query: async (params) => {
    Object.keys(params).forEach(
      // eslint-disable-next-line no-param-reassign
      (key) => params[key] == null && delete params[key]
    );
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(encodeUrl(queryUrl, params), {
      method: 'GET',
      mode: 'cors',
      headers,
    });
    return ContractService.normalize(
      await ResponseService.checkStatus(response)
    );
  },
  normalize: async (response) => {
    const result = flatten(await response.data);
    return result;
  },
  getById: async (contractId) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(`${queryUrl}/${contractId}`, {
      method: 'GET',
      mode: 'cors',
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  transformValues: async (contract) => {
    const edges = [];
    if (contract) {
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(contract)) {
        if (key === 'defaultSite') {
          continue; // eslint-disable-line no-continue
        }
        edges.push({key, value});
      }
    }
    return edges;
  },
  assignVehicle: async (vehicleId, contractId) => {
    const {auth} = authStore;
    const headers = new Headers();
    const body = JSON.stringify({
      vehicleId,
      contractId,
    });
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(`${queryUrl}/assign`, {
      method: 'POST',
      mode: 'cors',
      headers,
      body,
    });
    return ResponseService.checkStatus(response);
  },
  update: async (contract) => {
    // eslint-disable-next-line no-param-reassign
    if (!contract.id) {
      return ContractService.create(contract);
    }
    Object.keys(contract).forEach(
      // eslint-disable-next-line no-param-reassign
      (key) => contract[key] == null && delete contract[key]
    );
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(`${queryUrl}/${contract.id}`, {
      method: 'PATCH',
      mode: 'cors',
      body: JSON.stringify(contract),
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  create: async (contract) => {
    Object.keys(contract).forEach(
      // eslint-disable-next-line no-param-reassign
      (key) => contract[key] == null && delete contract[key]
    );
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(`${queryUrl}`, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({items: [{...contract}]}),
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  delete: async (contractId) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(`${queryUrl}/${contractId}`, {
      method: 'DELETE',
      mode: 'cors',
      headers,
    });
    return ResponseService.checkStatus(response);
  },
};

export default ContractService;
