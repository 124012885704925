import React from 'react';
import {
  ApiProfileGetRequest,
  Profile,
  ProfileList,
} from '@onroadvantage/onroadvantage-api';
import {profileApi} from '../../api';
import {TemplateTableContextProps} from '../../factory/template';
import {useTemplateTable, TLoadList} from '../../factory/template';
import {useAppNotifications} from '../../contexts';
import {ProfileGenerateProfileTheme} from './ProfileGenerateProfileTheme';

export type ProfileContextProps = TemplateTableContextProps<
  Profile,
  ProfileList
>;

export const ProfileContext = React.createContext<ProfileContextProps>({
  // Template Table Defaults
  loadList: async () => {},
  loading: false,
  list: [],
  currentPage: 1,
});

interface ProfileContextProviderProps {
  profileId?: number;
}

export const ProfileContextProvider: React.FC<ProfileContextProviderProps> = ({
  children,
}) => {
  const notify = useAppNotifications();
  // Template Table
  const [
    {
      currentPage,
      pageSize,
      pageTotal,
      sorting,
      hasPermission,
      itemTotal,
      list,
      loading,
      filters,
    },
    {
      // Getters
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
    },
  ] = useTemplateTable<Profile, ApiProfileGetRequest>({
    editPermission: 'Edit Profile',
    addPermission: 'Add Profile',
    deletePermission: 'Delete Profile',
    downloadPermission: 'Profile ListDownload',
    viewPermission: 'Profile List',
  });

  const loadList = React.useCallback<TLoadList<ProfileList>>(
    async (options) => {
      setLoading(true);
      try {
        return getResponse(
          await profileApi.apiProfileGet(
            getRequestObj(['name', 'description'], options)
          ),
          options
        );
      } catch (e) {
        notify('error', e.message ?? 'Failed to load Profile List');
      } finally {
        setLoading(false);
      }
    },
    [getRequestObj, getResponse, notify, setLoading]
  );

  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  const value: ProfileContextProps = {
    // Template Table
    list,
    rowDetail: ProfileGenerateProfileTheme,
    loadList,
    hasPermission,
    loading,
    cleanupList,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onRefresh: handleRefresh,
  };

  return (
    <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
  );
};
