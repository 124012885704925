import React from 'react';
import {SortEndHandler} from 'react-sortable-hoc';
import {Stack} from '@mui/material';
import {Listing} from '@onroadvantage/onroadvantage-api';
import {usePlanningBoardGanttContext} from '../../../planningBoardContext';
import {changePosition} from '../../../../../factory/template';
import {usePlanningBoardTripContext} from '../planningBoardTripContext';
import {PlanningBoardTripOrdersActions} from './PlanningBoardTripOrdersActions';
import {PlanningBoardTripOrdersSortable} from './planningBoardTripOrderSortable';
import {PlanningBoardTripOrdersAdd} from './planningBoardTripOrdersAdd';
import {PlanningBoardTripOrder} from './planningBoardTripOrder';
import {useFormikContext} from 'formik';
import {IPlanningBoardTripPanelForm} from '../planningBoardTripForm';

interface PlanningBoardTripOrdersProps {
  canAdd?: boolean;
}

export const PlanningBoardTripOrders: React.FC<
  PlanningBoardTripOrdersProps
> = ({canAdd}) => {
  const {values} = useFormikContext<IPlanningBoardTripPanelForm>();
  const {loadTripPreview, onAddOrders, orders, setOrders} =
    usePlanningBoardTripContext();
  const {selectedGanttItem} = usePlanningBoardGanttContext();
  const [isSorting, setIsSorting] = React.useState<boolean>(false);
  const [isAdding, setIsAdding] = React.useState<boolean>(false);

  const handleToggleIsAdding = React.useCallback(() => {
    setIsAdding((prevIsAddingOrder) => !prevIsAddingOrder);
    setIsSorting(false);
  }, []);

  const handleToggleIsSorting = React.useCallback(() => {
    setIsAdding(false);
    setIsSorting((prevIsSorting) => !prevIsSorting);
  }, []);

  const handleCancel = React.useCallback(() => {
    setIsAdding(false);
    setIsSorting(false);
  }, []);

  const handleAdd = React.useCallback(
    async (addedOrders: Listing[]) => {
      const newOrders = onAddOrders(addedOrders);
      handleCancel();
      await loadTripPreview(values, newOrders);
    },
    [onAddOrders, handleCancel, loadTripPreview, values]
  );

  const handleSortEnd = React.useCallback<SortEndHandler>(
    (sort) => {
      setOrders(
        (prevOrders) =>
          changePosition(prevOrders, sort.oldIndex, sort.newIndex) ?? prevOrders
      );
    },
    [setOrders]
  );

  if (!selectedGanttItem) {
    return null;
  }

  return (
    <Stack flex={0.5}>
      {canAdd ? (
        <>
          <PlanningBoardTripOrdersAdd
            isAdding={isAdding}
            onAdd={handleAdd}
            onCancel={handleCancel}
          />
          <PlanningBoardTripOrdersActions
            isAdding={isAdding}
            isSorting={isSorting}
            onToggleIsAdding={handleToggleIsAdding}
            onToggleIsSorting={handleToggleIsSorting}
          />
        </>
      ) : null}
      {isSorting ? (
        <PlanningBoardTripOrdersSortable onSortEnd={handleSortEnd} />
      ) : (
        orders.map((order) => (
          <PlanningBoardTripOrder
            key={order.orderNumber}
            order={order}
            expandedByDefault={orders.length === 1}
          />
        ))
      )}
    </Stack>
  );
};
