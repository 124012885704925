import React from 'react';
import {
  ApiContractOperationalEventTypeConfigGetRequest,
  ContractOperationalEventTypeConfigListResponse,
  ContractOperationalEventTypeConfigList,
} from '@onroadvantage/onroadvantage-api';
import {contractOperationalEventTypeConfigApi} from '../../../../api';
import {
  TemplateTableContextProps,
  TLoadList,
  useTemplateTable,
} from '../../../../factory/template';
import {useAppNotifications} from '../../../../contexts';
import {useHistory} from 'react-router-dom';

export type ContractOperationalEventTypeConfigListContextProps =
  TemplateTableContextProps<
    ContractOperationalEventTypeConfigList,
    ContractOperationalEventTypeConfigListResponse
  >;

export const ContractOperationalEventTypeConfigListContext =
  React.createContext<ContractOperationalEventTypeConfigListContextProps | null>(
    null
  );

export const useContractOperationalEventTypeConfigListContext = () => {
  const contractOperationalEventTypeConfigListContext = React.useContext(
    ContractOperationalEventTypeConfigListContext
  );
  if (contractOperationalEventTypeConfigListContext == null) {
    throw new Error(
      'useContractOperationalEventTypeConfigListContext has to be used within <ContractOperationalEventTypeConfigListContext.Provider>'
    );
  }

  return contractOperationalEventTypeConfigListContext;
};

export const ContractOperationalEventTypeConfigListContextProvider: React.FC =
  ({children}) => {
    const notify = useAppNotifications();
    const history = useHistory();
    // Template Table
    const [
      {
        // States
        currentPage,
        filters,
        hasPermission,
        itemTotal,
        list,
        loading,
        pageSize,
        pageTotal,
        sorting,
      },
      {
        // Getters
        getRequestObj,
        getResponse,
        // Handlers
        handleCurrentPageChange,
        handleFiltersChange,
        handlePageSizeCountsChange,
        handleSortingChange,
        // Setters
        cleanupList,
        setLoading,
      },
    ] = useTemplateTable<
      ContractOperationalEventTypeConfigList,
      ApiContractOperationalEventTypeConfigGetRequest
    >({
      addPermission: 'Add ContractOperationalEventTypeConfig',
      editPermission: 'Edit ContractOperationalEventTypeConfig',
      deletePermission: 'Delete ContractOperationalEventTypeConfig',
    });

    const [submitting, setSubmitting] = React.useState<boolean>(false);

    const loadList = React.useCallback<
      TLoadList<ContractOperationalEventTypeConfigListResponse>
    >(
      async (options) => {
        setLoading(true);
        try {
          const requestObj = getRequestObj(
            [
              'allowEventMuting',
              'allowEventRepetition',
              'arrivalDelayThreshold',
              'autoClose',
              'enabled',
              'eventPostThreshold',
              'eventPreThreshold',
              'varianceAllowed',
              'contractCode',
              'operationalEventTypeName',
            ],
            options
          );
          const response =
            await contractOperationalEventTypeConfigApi.apiContractOperationalEventTypeConfigGet(
              requestObj
            );
          return getResponse(response, options);
        } catch (e) {
          notify(
            'error',
            'Failed to load contract operational event type configs'
          );
        } finally {
          setLoading(false);
        }
      },
      [setLoading, getRequestObj, getResponse, notify]
    );

    const handleDelete = React.useCallback(
      async (row: ContractOperationalEventTypeConfigList) => {
        setSubmitting(true);
        try {
          if (row.id) {
            await contractOperationalEventTypeConfigApi.apiContractOperationalEventTypeConfigContractOperationalEventTypeConfigIdDelete(
              {
                contractOperationalEventTypeConfigId: row.id,
              }
            );
            notify('success', 'Deleted contract operational event type config');
            await loadList();
          }
        } catch (e) {
          notify(
            'error',
            'Failed to delete contract operational event type config'
          );
        } finally {
          setSubmitting(false);
        }
      },
      [loadList, notify]
    );

    const handleNavigate = React.useCallback(
      (row: ContractOperationalEventTypeConfigList) => {
        history.push(`/contracteventtypeconfigs/operational/${row.id}`);
      },
      [history]
    );

    const handleAdd = React.useCallback(() => {
      history.push('/contracteventtypeconfigs/operational/add');
    }, [history]);

    const handleRefresh = React.useCallback(
      async () => await loadList(),
      [loadList]
    );

    const value: ContractOperationalEventTypeConfigListContextProps = {
      loadList,
      cleanupList,
      hasPermission,
      loading: submitting || loading,
      list,
      currentPage,
      filters,
      itemTotal,
      pageSize,
      pageTotal,
      sorting,
      onNavigate: handleNavigate,
      onAdd: hasPermission.add ? handleAdd : undefined,
      onDelete: hasPermission.delete ? handleDelete : undefined,
      onFiltersChange: handleFiltersChange,
      onCurrentPageChange: handleCurrentPageChange,
      onPageSizeCountsChange: handlePageSizeCountsChange,
      onSortingChange: handleSortingChange,
      onRefresh: handleRefresh,
    };

    return (
      <ContractOperationalEventTypeConfigListContext.Provider value={value}>
        {children}
      </ContractOperationalEventTypeConfigListContext.Provider>
    );
  };
