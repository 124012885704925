import React from 'react';
import {unstable_batchedUpdates} from 'react-dom';
import _ from 'lodash';
import {
  BookingSlot,
  Listing,
  ApiBookingAvailabilityGetRequest,
} from '@onroadvantage/onroadvantage-api';
import {FormikHelpers, FormikProps} from 'formik/dist/types';
import {Filter, Sorting} from '@devexpress/dx-react-grid';
import {bookingApi} from '../../api';
import {TemplateTableContextProps} from '../../factory/template';

export interface IVehicleBookingAvailabilityForm {
  endDate: Date | any;
  startDate: Date | any;
  contract: Listing;
  fromNode: Listing;
  toNode: Listing;
  product: Listing;
}

export interface VehicleContextProps
  extends Omit<TemplateTableContextProps<BookingSlot>, 'loadList'> {
  listRendered: boolean;
  onDetailsFormSubmit: (
    values: IVehicleBookingAvailabilityForm,
    formikHelpers: FormikHelpers<IVehicleBookingAvailabilityForm>
  ) => void;
  detailsRef?: React.Ref<FormikProps<IVehicleBookingAvailabilityForm>>;
}

export const VehicleContext = React.createContext<VehicleContextProps>({
  // Template Table Defaults
  loading: false,
  list: [],
  currentPage: 1,
  // Vehicle Booking Availability
  listRendered: false,
  onDetailsFormSubmit: () => null,
});

export const VehicleContextProvider: React.FC = ({children}) => {
  // Template Table
  const [loading, setLoading] = React.useState<boolean>(false);
  const [list, setList] = React.useState<BookingSlot[]>([]);
  const [filters, setFilters] = React.useState<Filter[]>([]);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [pageSize, setPageSize] = React.useState<number>(25);
  const [pageTotal, setPageTotal] = React.useState<number>(1);
  const [itemTotal, setItemTotal] = React.useState<number>(0);
  const [sorting, setSorting] = React.useState<Sorting[]>([]);
  // Vehicle Booking Availability
  const [listRendered, setListRendered] = React.useState<boolean>(false);

  const detailsRef =
    React.useRef<FormikProps<IVehicleBookingAvailabilityForm>>(null);

  const handleFormSubmit = React.useCallback(
    async (
      values: IVehicleBookingAvailabilityForm,
      formikHelpers: FormikHelpers<IVehicleBookingAvailabilityForm>
    ) => {
      setLoading(true);
      try {
        // const orderBy = getStringFromSorting(sorting) ?? undefined;
        const requestObj: ApiBookingAvailabilityGetRequest = {
          // TODO Add back when added in BE
          // orderBy: orderBy,
          // perPage: pageSize,
          // page: currentPage,
          fromNodeId: values.fromNode.value,
          toNodeId: values.toNode.value,
          endDate: values.endDate,
          startDate: values.startDate,
          contractId: values.contract.value,
          productId: values.product.value,
        };

        const response = await bookingApi.apiBookingAvailabilityGet(requestObj);
        setPageTotal(response.pages ?? 1);
        setItemTotal(response.total ?? 0);
        setListRendered(true);
        setList(response.items ?? []);
      } catch (e) {
        setListRendered(false);
        setList([]);
      } finally {
        formikHelpers.setSubmitting(false);
        setLoading(false);
      }
    },
    []
  );

  const handlePageSizeCountsChange = React.useCallback((value: number) => {
    setCurrentPage(1);
    setPageSize(value);
  }, []);

  const handleCurrentPageChange = React.useCallback(
    (value: number) => {
      let newPage = value;

      if (value < 0) {
        // lower bound check
        newPage = 0;
      } else if (value > pageTotal) {
        // upper bound check
        newPage = pageTotal;
      }

      setCurrentPage(newPage + 1);
    },
    [pageTotal]
  );

  const handleFiltersChange = _.debounce((filters: Filter[]) => {
    unstable_batchedUpdates(() => {
      setCurrentPage(1);
      setFilters(filters);
    });
  }, 500);

  const handleSortingChange = _.debounce((sorting: Sorting[]) => {
    unstable_batchedUpdates(() => {
      setCurrentPage(1);
      setSorting(sorting);
    });
  }, 500);

  //TODO: To use when booking?
  const handleBookNow = React.useCallback((_row: BookingSlot) => {}, []);

  const value: VehicleContextProps = {
    // Template Table
    loading: loading,
    list: list ?? [],
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onNavigate: handleBookNow,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    // Vehicle Booking Availability
    listRendered,
    onDetailsFormSubmit: handleFormSubmit,
    detailsRef,
  };

  return (
    <VehicleContext.Provider value={value}>{children}</VehicleContext.Provider>
  );
};
