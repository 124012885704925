import React from 'react';
import {TrainingCourseContext} from './TrainingCourseContext';
import {useParams} from 'react-router-dom';

interface IParams {
  trainingCourseId?: string;
}

export const TrainingCourseIdHandler: React.FC = () => {
  const {setTrainingCourseId} = React.useContext(TrainingCourseContext);
  const {trainingCourseId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = trainingCourseId ? parseInt(trainingCourseId) : undefined;
    setTrainingCourseId(parsedId);
    return () => setTrainingCourseId(undefined);
  }, [trainingCourseId, setTrainingCourseId]);

  return null;
};
