import React from 'react';
import {
  TripDebriefSummaryCard,
  TripDebriefSummaryCardList,
} from './tripDebriefSummaryCard';
import {TripDebriefContext} from '../TripDebriefContext';
import {MasterTripDebriefSnapshot} from '@onroadvantage/onroadvantage-api';

export const TripDebriefSummaryMobile: React.FC = () => {
  const {masterTripDebriefData} = React.useContext(TripDebriefContext);
  /**
   *  Destructure snapshotData, need to add || ({} as MasterTripDebriefSnapshot) for typescript, since
   *  snapshotData is nullable.
   */
  const {mobile} =
    masterTripDebriefData?.snapshotData || ({} as MasterTripDebriefSnapshot);

  const list: TripDebriefSummaryCardList = React.useMemo(
    () => [
      {
        primary: 'Mobile Id',
        secondary: mobile?.registrationNumber,
        type: 'string',
        changed: false,
      },
      {
        primary: 'Description',
        secondary: mobile?.fleetNumber,
        type: 'string',
        changed: false,
      },
    ],
    [mobile]
  );

  return <TripDebriefSummaryCard title="Mobile" list={list} />;
};
