import React from 'react';
import {
  useSpeedTable,
  useSpeedTableResponse,
  useSpeedTableResponseInitial,
} from './useSpeedTable';

export type SpeedTableContextProps = useSpeedTableResponse;

export const SpeedTableContext = React.createContext<SpeedTableContextProps>(
  useSpeedTableResponseInitial
);

export const SpeedTableContextProvider: React.FC = ({children}) => {
  const value = useSpeedTable();

  const {loadSpeedTable, setSpeedTable} = value;

  React.useEffect(() => {
    loadSpeedTable();
    return () => {
      setSpeedTable(undefined);
    };
  }, [loadSpeedTable, setSpeedTable]);

  return (
    <SpeedTableContext.Provider value={value}>
      {children}
    </SpeedTableContext.Provider>
  );
};

export const useSpeedTableContext = () => React.useContext(SpeedTableContext);
