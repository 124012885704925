import React from 'react';
import L from 'leaflet';
import {inject, observer} from 'mobx-react';
import BaseMarker from './BaseMarker';
import MomentService from '../../../service/MomentService';
import {mapDisplayStore} from '../../../stores/mobxStores';

interface Props {
  position: [number, number]; // lat , lng
  actualStop: any;
}

class ActualStopMarker extends React.Component<Props> {
  render() {
    const {position, actualStop} = this.props;
    actualStop.duration = MomentService.timeDiff(
      actualStop.start,
      actualStop.end
    );
    const theme = mapDisplayStore.mapTheme;

    // TODO use duration to make it more intense,. or larger pulse so controllers know which is worse

    let Icon;
    if (actualStop && (actualStop.scheduledNodes || actualStop.knownNodes)) {
      if (actualStop.scheduledNodes && actualStop.scheduledNodes.length > 0) {
        // @ts-expect-error upgrade
        Icon = L.icon.pulse(theme.actualStopMarker.scheduledStop);
        actualStop.title = 'Scheduled Stop';
      } else if (actualStop.knownNodes && actualStop.knownNodes.length > 0) {
        // @ts-expect-error upgrade
        Icon = L.icon.pulse(theme.actualStopMarker.knownUnscheduledStop);
        actualStop.title = 'Known Unscheduled Stop';
      } else {
        // @ts-expect-error upgrade
        Icon = L.icon.pulse(theme.actualStopMarker.illegalStop);
        actualStop.title = 'Illegal Stop';
      }
    } else {
      // @ts-expect-error upgrade
      Icon = L.icon.pulse(theme.actualStopMarker.other);
      actualStop.title = 'Other';
    }

    return (
      <BaseMarker position={position} icon={Icon} actualStop={actualStop} />
    );
  }
}
export default inject('mapDisplayStore')(observer(ActualStopMarker));
