import React from 'react';
import {Button, Stack, Step, StepLabel, Stepper} from '@mui/material';
import {
  tripSetupSteps,
  TripSetupSteps,
  tripSetupStepsLabels,
} from './useTripSetup';
import {useTripSetupStyles} from './TripSetup.styles';
import {useTripSetupContext} from './TripSetupContext';
import {useAppNotifications} from '../../../contexts';
import {IVantageDialogRef, VantageDialog} from '../../dialog';

interface TripSetupStepperProps {
  completedSteps: TripSetupSteps[];
  activeStep: TripSetupSteps;
  onNextStep: (currentStep: TripSetupSteps) => void;
  onPreviousStep: () => void;
  onGoToStep: (targetStep: TripSetupSteps) => void;
}

export const TripSetupStepper: React.FC<TripSetupStepperProps> = ({
  completedSteps,
  activeStep,
  onNextStep,
  onPreviousStep,
  onGoToStep,
  children,
}) => {
  const notify = useAppNotifications();
  const classes = useTripSetupStyles();
  const {onSubmit, validSteps, editing, orders} = useTripSetupContext();
  const dialogRef = React.useRef<IVantageDialogRef | null>(null);
  const activeStepIndex = tripSetupSteps.indexOf(activeStep);
  const isLastStep = activeStepIndex + 1 === tripSetupSteps.length;

  const handleValidateNextStep = React.useCallback(() => {
    const {isValid, message} = validSteps[activeStep];
    if (!isValid) {
      notify('warning', message);
    } else {
      onNextStep(activeStep);
    }
  }, [activeStep, notify, onNextStep, validSteps]);

  const handleValidateSubmit = React.useCallback(() => {
    if (orders.length === 0) {
      dialogRef.current?.openDialog();
    } else {
      onSubmit();
    }
  }, [onSubmit, orders]);

  return (
    <Stack p={2} spacing={2}>
      <VantageDialog
        title="Are you sure you want to create the trip without orders?"
        ref={dialogRef}
        onAccept={onSubmit}
        declineTitle="Cancel"
        acceptTitle="Create Trip"
      />
      <Stepper activeStep={activeStepIndex + 1}>
        {tripSetupSteps.map((step) => (
          <Step
            completed={completedSteps.includes(step)}
            active={activeStep === step}
            onClick={() => onGoToStep(step)}
            color="primary"
            key={step}
            classes={{completed: classes.completedStep}}
          >
            <StepLabel>{tripSetupStepsLabels[step]}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {children}
      <Stack direction="row" spacing={1}>
        <Button
          onClick={onPreviousStep}
          variant="outlined"
          disabled={editing || activeStepIndex === 0}
        >
          Back
        </Button>
        <Button
          onClick={isLastStep ? handleValidateSubmit : handleValidateNextStep}
          variant="contained"
          disabled={editing && !isLastStep}
        >
          {isLastStep ? 'Create Trip' : 'Next'}
        </Button>
      </Stack>
    </Stack>
  );
};
