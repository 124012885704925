import React from 'react';
import {Theme} from '@mui/material';
import {makeStyles} from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import {PlanningSolution} from '@onroadvantage/onroadvantage-api';
import _ from 'lodash';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import DoneIcon from '@mui/icons-material/Done';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import OpenIcon from '@mui/icons-material/OpenInNew';
import history from '../../../service/history';
import {usePlanningSolutionData} from '../../../stores/context';

const PlanningSolutionListDetailsStyles = (theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(3),
    },
    container: {},
  });

const usePlanningSolutionListDetailsStyles = makeStyles(
  PlanningSolutionListDetailsStyles,
  {name: 'PlanningSolutionListDetailsStyles'}
);

interface IProps {
  planningSolution: PlanningSolution;
}

export const PlanningSolutionListDetailsComponent: React.FC<IProps> = ({
  planningSolution,
}) => {
  const classes = usePlanningSolutionListDetailsStyles();
  const setPlanningSolution = usePlanningSolutionData(
    (store) => store.setPlanningSolution
  );

  const handleNavigate = () => {
    setPlanningSolution(planningSolution);
    history.push(`/planningsolutionlist/s/${planningSolution.id}`);
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        title={planningSolution.name || 'Planning Solution'}
        avatar={
          <Avatar>
            <DoneIcon />
          </Avatar>
        }
        action={
          <IconButton onClick={handleNavigate} size="large">
            <OpenIcon />
          </IconButton>
        }
      />
      <CardContent>
        <div>Name: {planningSolution.name}</div>
        <div>Start Date: TODO: add</div>
        <div>End Date: TODO: add</div>
        <div>Contract: {_.get(planningSolution, 'contract.code')}</div>
      </CardContent>
    </Card>
  );
};

export const PlanningSolutionListDetails = PlanningSolutionListDetailsComponent;
