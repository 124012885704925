import React from 'react';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import {useContactUserPopupStyles} from './ContactUserPopup.style';

interface ContactUserPopupProps {
  open: boolean;
  setOpen(): void;
  onSearchChange: (arg0: string) => void;
  options: any[];
  loading: boolean;
  Allocate(arg0: number): void;
}

export const ContactUserPopup: React.FC<ContactUserPopupProps> = ({
  open,
  setOpen,
  onSearchChange,
  loading,
  options,
  Allocate,
}) => {
  const classes = useContactUserPopupStyles();
  const [searchValue, setSearchValue] = React.useState<number>();

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Link Driver to User</DialogTitle>
      <DialogContent>
        <Autocomplete
          id="react-select-single"
          options={options}
          loading={loading}
          onChange={(e, value) => {
            setSearchValue(value?.value);
          }}
          onInputChange={(e, value) => onSearchChange(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              placeholder="Start typing the email you want to link to this user"
              variant="standard"
            />
          )}
          getOptionLabel={(option: any) => option.label.toString()}
        />

        <DialogActions className={classes.Actions}>
          <Button onClick={() => setOpen()} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              searchValue && Allocate(searchValue);
              setOpen();
            }}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
