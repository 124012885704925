import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from '@mui/material';

const TripDebriefConfigCardStyles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(1),
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      margin: 0,
    },
    invisible: {
      background:
        'repeating-linear-gradient(45deg, #fefefe, #fefefe 10px, #f6f6f6 10px, #f6f6f6 20px)',
    },
    content: {padding: 0},
    actions: {padding: 0},
  });

export const useTripDebriefConfigCardStyles = makeStyles(
  TripDebriefConfigCardStyles,
  {
    name: 'TripDebriefConfigCardStyles',
  }
);
