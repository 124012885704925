import React from 'react';
import {ContractCriticalEventTypeConfigList as ContractCriticalEventTypeConfigListType} from '@onroadvantage/onroadvantage-api';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../../../factory/template';
import {
  ContractCriticalEventTypeConfigListContext,
  useContractCriticalEventTypeConfigListContext,
} from './ContractCriticalEventTypeConfigListContext';

export const ContractCriticalEventTypeConfigList: React.FC = () => {
  const {loadList, cleanupList} =
    useContractCriticalEventTypeConfigListContext();

  const config: TemplateTableConfig<ContractCriticalEventTypeConfigListType> =
    React.useMemo(
      () => ({
        columns: [
          {
            name: 'criticalEventType.name',
            label: 'Critical Event Type',
            type: 'string',
            getValue: ({criticalEventType}) => criticalEventType?.name,
          },
          {
            name: 'contract.code',
            label: 'Contract Code',
            type: 'string',
            getValue: ({contract}) => contract?.code,
          },
          {name: 'allowEventMuting', label: 'Allow Event Muting', type: 'bool'},
        ],
        enableFilter: true,
        enableSort: true,
        deleteMessage: (row) => ({
          title: 'Contract Critical Event Type Config',
          items: {
            CriticalEventTypeName: row.criticalEventType?.name,
            contractCode: row.contract?.code,
          },
        }),
        identifier: 'CONTRACT_CRITICAL_EVENT_TYPE_CONFIG_LIST',
      }),
      []
    );

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [cleanupList, loadList]);

  return (
    <TemplateTableWrapper
      config={config}
      context={ContractCriticalEventTypeConfigListContext}
    />
  );
};
