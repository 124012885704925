import React from 'react';
import {
  ApiRoleRoleIdUserGetRequest,
  RoleUserListResponse,
  Listing,
  RoleUserMinimal,
} from '@onroadvantage/onroadvantage-api';
import {roleApi} from '../../../api';
import {
  TemplateTableContextProps,
  TLoadList,
  TOnInlineAdd,
  useTemplateTable,
} from '../../../factory/template';
import {useAppNotifications} from '../../../contexts';
import {RoleContext} from '../RoleContext';

export type RoleUserListContextProps = TemplateTableContextProps<
  RoleUserMinimal,
  RoleUserListResponse
>;

export const RoleUserListContext =
  React.createContext<RoleUserListContextProps>({
    loading: false,
    list: [],
    currentPage: 1,
    loadList: () => null,
  });

export const useRoleUserListContext = () =>
  React.useContext(RoleUserListContext);

export const RoleUserListContextProvider: React.FC = ({children}) => {
  const notify = useAppNotifications();
  const {roleId} = React.useContext(RoleContext);
  // Template Table
  const [
    {
      // States
      currentPage,
      filters,
      hasPermission,
      itemTotal,
      list,
      loading,
      pageSize,
      pageTotal,
      sorting,
    },
    {
      // Getters
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
    },
  ] = useTemplateTable<RoleUserMinimal, ApiRoleRoleIdUserGetRequest>({
    addPermission: 'Add UserRole',
    editPermission: 'Edit UserRole',
    deletePermission: 'Delete UserRole',
  });

  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const loadList = React.useCallback<TLoadList<RoleUserListResponse>>(
    async (options) => {
      setLoading(true);
      try {
        if (roleId) {
          const requestObj = getRequestObj(
            ['email', 'username', 'active'],
            options
          );
          const response = await roleApi.apiRoleRoleIdUserGet({
            ...requestObj,
            roleId,
          });
          return getResponse(response, options);
        }
      } catch (e) {
        notify('error', 'Failed to load users');
      } finally {
        setLoading(false);
      }
    },
    [setLoading, roleId, getRequestObj, getResponse, notify]
  );

  const handleDelete = React.useCallback(
    async (row: RoleUserMinimal) => {
      setSubmitting(true);
      try {
        if (roleId && row.id) {
          const response = await roleApi.apiRoleRoleIdUserUnassignUserIdPatch({
            roleId,
            userId: row.id,
          });
          notify('success', 'Unassigned user from role');
          return getResponse(response);
        }
      } catch (e) {
        notify('error', 'Failed to unassign user from role');
      } finally {
        setSubmitting(false);
      }
    },
    [getResponse, notify, roleId]
  );

  const handleInlineAdd = React.useCallback<TOnInlineAdd>(
    async (changes) => {
      setSubmitting(true);
      try {
        if (roleId && changes) {
          const userLists = changes[0]?.email as Listing[] | undefined;
          const userIds = userLists
            ?.filter(({value}) => !!value)
            .map(({value}) => value as number);

          if (userIds && userIds.length > 0) {
            const response = await roleApi.apiRoleRoleIdUserAssignPatch({
              roleId,
              body: {userIds},
            });
            notify('success', 'Assigned user to role');
            return getResponse(response);
          }
        }
      } catch (e) {
        notify('error', 'Failed to user to role');
      } finally {
        setSubmitting(false);
      }
    },
    [getResponse, notify, roleId]
  );

  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  const value: RoleUserListContextProps = {
    loadList,
    cleanupList,
    hasPermission,
    loading: submitting || loading,
    list,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onInlineAdd: hasPermission.add ? handleInlineAdd : undefined,
    onDelete: hasPermission.delete ? handleDelete : undefined,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onRefresh: handleRefresh,
  };

  return (
    <RoleUserListContext.Provider value={value}>
      {children}
    </RoleUserListContext.Provider>
  );
};
