import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';
import {drawerWidth as leftDrawerWidth} from './drawers/LeftDrawer.style';
import {drawerWidth as rightDrawerWidth} from './drawers/RightDrawer.style';

// const drawerWidth = 260;

const VantageFrameStyles = (theme: Theme) =>
  createStyles({
    content: {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden',
      flexDirection: 'column',
      // start drawer
      flexGrow: 1,
      padding: theme.spacing(0),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -leftDrawerWidth,
      // end drawer
    },
    contentShiftLeft: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    contentShiftRight: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: rightDrawerWidth,
    },
    contentMobile: {
      marginLeft: 0,
      marginRight: 0,
    },
    contentTablet: {
      marginLeft: 0,
      marginRight: 0,
    },
    hide: {
      display: 'none',
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-start',
    },
    main: {
      flex: '1 1 auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      overflow: 'auto',
      padding: theme.spacing(1),
    },
    root: {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden',
      flexDirection: 'row',
      // display: 'flex',
      // flexDirection: 'row',
      // flex: 1,
      // height: '100%',
      // flex: '0 0 auto',
      // flexDirection: 'column',
      // overflow: 'scroll',
      backgroundColor: theme.palette.background.default,
    },
  });

export const useVantageFrameStyles = makeStyles(VantageFrameStyles, {
  name: 'VantageFrameStyles',
});
