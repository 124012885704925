import React from 'react';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Stack,
} from '@mui/material';
import {Done, Settings} from '@mui/icons-material';
import {
  MasterTripCreditResponse,
  MasterTripQuery,
  Trip,
} from '@onroadvantage/onroadvantage-api';
import {useTripContext} from '../tripContext';
import {TRIP_CARD_GAP} from '../Trip';
import {LuxonService} from '../../../service';
import {
  TripSummaryEditCredit,
  TripSummaryEditDriver,
  TripSummaryEditVehicle,
  TripSummaryEditTripNumber,
  TripSummaryEditPlannedDeparture,
} from './tripSummaryEdit';
import {TripSummaryDriverQrGenerate} from './TripSummaryDriverQrGenerate';
import {useTripSummaryStyles} from './TripSummary.style';
import {TripSummaryList} from './TripSummaryList';
import {TripActions} from '../TripActions';
import {TripSettingsContext} from '../tripSettings';
import {DateTimeFormatService} from '../../../service/FormatService';
import {TripSummaryBroadcast} from './TripSummaryBroadcast';

export const TripSummary: React.FC = () => {
  const classes = useTripSummaryStyles();
  const {onOpenSettings} = React.useContext(TripSettingsContext);
  const {masterTrip, tripLogs} = useTripContext();
  /** Destructure masterTrip, need to add || ({} as MasterTripQuery) for typescript, since masterTrip is nullable. */
  const {credit, progress, trip, createdAt, createdBy} =
    masterTrip || ({} as MasterTripQuery);

  /** Destructure credit, need to add || ({} as MasterTripCreditResponse) for typescript, since credit is nullable. */
  const {distance: creditDistance} = credit || ({} as MasterTripCreditResponse);

  /** Destructure trip, need to add || ({} as Trip) for typescript, since trip is nullable. */
  const {
    contract,
    driver,
    mobile,
    status,
    tripDistance,
    tripDistanceActual,
    tripNumber,
    tripStart,
    vehicle,
  } = trip || ({} as Trip);

  const tripDetails = React.useMemo(() => {
    const formatKilometer = (value: number | undefined) =>
      value || value === 0 ? `${Math.round(value * 10) / 10}km` : undefined;

    const formatProgress = (value: number | undefined) =>
      value || value === 0 ? `${Math.round(value * 100)}%` : undefined;

    let actualStart: Date | undefined;
    const firstStop =
      trip.stops && trip.stops.length > 0 ? trip.stops[0] : undefined;

    if (
      contract?.tripStopTimestampPriority === 'mobile' &&
      firstStop?.tripStopExecution?.mobileDepartureTime
    ) {
      actualStart = firstStop.tripStopExecution.mobileDepartureTime;
    } else if (firstStop?.tripStopExecution?.gpsDepartureTime) {
      actualStart = firstStop.tripStopExecution.gpsDepartureTime;
    }

    return {
      tripNumber,
      status,
      taskProgress: formatProgress(progress?.taskProgress),
      orderProgress: formatProgress(progress?.orderProgress),
      stopProgress: formatProgress(progress?.stopProgress),
      tripStart: tripStart
        ? new DateTimeFormatService().formatDateTime(tripStart)
        : undefined,
      actualStart: actualStart
        ? new DateTimeFormatService().formatDateTime(actualStart)
        : undefined,
      createdAt: createdAt
        ? new DateTimeFormatService().formatDateTime(createdAt)
        : undefined,
      createdBy: createdBy?.email,
      tripDistance: formatKilometer(tripDistance),
      tripDistanceActual: formatKilometer(tripDistanceActual),
      creditDistance: formatKilometer(creditDistance),
      vehicleRegistrationNumber: vehicle?.registrationNumber,
      vehicleFleetNumber: vehicle?.fleetNumber,
      vehicleLastSeen: LuxonService.fromNow(vehicle?.lastSeen?.eventDate),
      driverName: driver?.firstname,
      driverTag: driver?.externalExtendedId,
      mobileDevice: mobile?.id
        ? mobile.fleetNumber ??
          `${mobile?.description} - ${mobile?.registrationNumber}`
        : undefined,
      safetyCompliance: tripLogs
        ? `${((4 - tripLogs.length ? tripLogs.length : 0) / 4) * 100}% - ${
            tripLogs.length ? tripLogs.length : 'no'
          } broken legs`
        : undefined,
      contractCode: contract?.code,
      contractName: contract?.name,
    };
  }, [
    contract,
    createdAt,
    createdBy,
    creditDistance,
    driver,
    mobile,
    progress,
    status,
    tripDistance,
    tripDistanceActual,
    trip,
    tripLogs,
    tripNumber,
    tripStart,
    vehicle,
  ]);

  return (
    <Stack spacing={TRIP_CARD_GAP}>
      <Stack direction="row" spacing={TRIP_CARD_GAP}>
        <Card className={classes.root}>
          <CardHeader
            title="Trip details"
            avatar={
              <Avatar aria-label="Trip Details">
                <Done />
              </Avatar>
            }
            action={
              <IconButton onClick={onOpenSettings}>
                <Settings />
              </IconButton>
            }
          />
          <CardContent sx={{pt: 0}}>
            <Stack
              direction={{xs: 'column', sm: 'row'}}
              spacing={2}
              divider={<Divider orientation="vertical" flexItem />}
            >
              <Stack flex={1}>
                <TripSummaryList
                  title="Trip"
                  items={[
                    {label: 'Trip Number', value: tripNumber},
                    {label: 'Status', value: status},
                  ]}
                  action={<TripSummaryEditTripNumber />}
                />
                <TripSummaryList
                  title="Progress"
                  items={[
                    {label: 'Tasks Completed', value: tripDetails.taskProgress},
                    {
                      label: 'Order Stops Hit',
                      value: tripDetails.orderProgress,
                    },
                    {
                      label: 'Planned Stops Hit',
                      value: tripDetails.stopProgress,
                    },
                  ]}
                />
                <TripSummaryList
                  title="Dates"
                  items={[
                    {label: 'Planned Start', value: tripDetails.tripStart},
                    {label: 'Actual Start', value: tripDetails.actualStart},
                  ]}
                  action={<TripSummaryEditPlannedDeparture />}
                />
                <TripSummaryList
                  title="Contract"
                  items={[
                    {label: 'Code', value: tripDetails.contractCode},
                    {
                      label: 'Name',
                      value: tripDetails.contractName,
                    },
                  ]}
                />
                <TripSummaryList
                  title="Plan Safety"
                  items={[
                    {label: undefined, value: tripDetails.safetyCompliance},
                  ]}
                  disableBottomDivider
                />
              </Stack>
              <Stack flex={1}>
                <TripSummaryList
                  title="Kilometers"
                  items={[
                    {
                      label: 'Planned Kilometers',
                      value: tripDetails.tripDistance,
                    },
                    {
                      label: 'Actual Kilometers',
                      value: tripDetails.tripDistanceActual,
                    },
                    {
                      label: 'Credit Kilometers',
                      value: tripDetails.creditDistance,
                    },
                  ]}
                  action={<TripSummaryEditCredit />}
                />
                <TripSummaryList
                  title="Allocated Vehicle"
                  items={[
                    {
                      label: 'Registration Number',
                      value: tripDetails.vehicleRegistrationNumber,
                    },
                    {
                      label: 'Fleet Number',
                      value: tripDetails.vehicleFleetNumber,
                    },
                    {
                      label: 'Last Seen',
                      value: String(tripDetails.vehicleLastSeen),
                    },
                  ]}
                  action={
                    <Stack direction="row" spacing={0.5}>
                      <TripSummaryBroadcast />
                      <TripSummaryEditVehicle />
                    </Stack>
                  }
                />
                <TripSummaryList
                  title="Allocated Driver"
                  items={[
                    {label: 'Driver Name', value: tripDetails.driverName},
                    {label: 'Driver Tag', value: tripDetails.driverTag},
                    {label: 'Mobile Device', value: tripDetails.mobileDevice},
                  ]}
                  action={
                    <Stack direction="row" spacing={0}>
                      <TripSummaryDriverQrGenerate />
                      <TripSummaryEditDriver />
                    </Stack>
                  }
                />
                <TripSummaryList
                  title="Created"
                  items={[
                    {label: 'Created At', value: tripDetails.createdAt},
                    {label: 'Created By', value: tripDetails.createdBy},
                  ]}
                  disableBottomDivider
                />
              </Stack>
            </Stack>
            <Stack mt={1}>
              <TripActions />
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </Stack>
  );
};
