import React from 'react';
import {RowDetailState} from '@devexpress/dx-react-grid';

import {
  Grid,
  Table,
  TableHeaderRow,
  TableRowDetail,
} from '@devexpress/dx-react-grid-material-ui';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import withStyles from '@mui/styles/withStyles';
import 'video-react/dist/video-react.css';
import styles from '../styles/Card';
import {MomentService} from '../../service';
import CriticalEventMediaPlayer from './CriticalEventMediaPlayer';
import NoDataLoading from '../table/NoDataLoading';
import {criticalEventMediaApi} from '../../api';

const Cell = (props) => {
  if (['createdAt'].includes(props.column.name)) {
    return <Table.Cell value={MomentService.displayTimestamp(props.value)} />;
  }
  return <Table.Cell value={props.value} />;
};

class CriticalEventMedia extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      url: null,
      loading: true,
      items: [],
    };
  }

  componentDidMount = async () => {
    const {event} = this.props;
    const media = await criticalEventMediaApi.apiCriticalEventCriticalEventIdMediaGet(
      {criticalEventId: event.id}
    );
    this.setState({loading: false, items: media.items});
  };

  render() {
    const {items, loading} = this.state;

    const RowDetail = ({row}) => {
      return (
        <div style={{padding: 10}}>
          <CriticalEventMediaPlayer row={row} />
        </div>
      );
    };

    const columns = [
      {name: 'sourceName', title: 'Source'},
      {name: 'createdAt', title: 'Created At'},
      {name: 'createdBy', title: 'Created By'},
    ];

    return (
      <Card
        style={{marginTop: 20, marginBottom: 20, padding: 20, minHeight: 300}}
      >
        <Collapse in timeout="auto" unmountOnExit>
          <CardContent>
            <Grid rows={items} columns={columns}>
              <RowDetailState />
              <Table
                cellComponent={Cell}
                noDataRowComponent={() => (
                  <NoDataLoading
                    loading={loading}
                    numberOfColumns={columns.length + 2}
                  />
                )}
              />
              <TableHeaderRow />
              <TableRowDetail contentComponent={RowDetail} />
            </Grid>
          </CardContent>
        </Collapse>
      </Card>
    );
  }
}

export default withStyles(styles)(CriticalEventMedia);
