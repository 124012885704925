import React from 'react';
import Paper from '@mui/material/Paper';
import {
  Column,
  CustomPaging,
  EditingState,
  EditingStateProps,
  FilteringState,
  FilteringStateProps,
  GroupingState,
  IntegratedGrouping,
  PagingState,
  PagingStateProps,
  SortingState,
  SortingStateProps,
  TableColumnResizingProps,
} from '@devexpress/dx-react-grid';
import {
  ColumnChooser,
  DragDropProvider,
  Grid,
  GroupingPanel,
  PagingPanel,
  Table,
  TableColumnReordering,
  TableColumnVisibility,
  TableEditColumn,
  TableFilterRow,
  TableColumnResizing,
  TableGroupRow,
  TableHeaderRow,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import {WebPlanningSolutionOptimised as WebPlanningSolutionOptimisedType} from '@onroadvantage/onroadvantage-api';
import NoDataLoading from '../../table/NoDataLoading';
import {
  AddCancelDownloadHeader,
  Command,
  IAddCancelDownloadProps,
  ToolbarActivityIndicator,
} from '../../table';
import {RoleService} from '../../../service';

interface Props {
  columns: Array<Column>;
  columnWidths?: TableColumnResizingProps['columnWidths'];
  currentPage?: PagingStateProps['currentPage'];
  filters?: FilteringStateProps['filters'];
  hiddenColumns?: Array<string>;
  isDownloading?: boolean;
  isLoading?: boolean;
  isRefreshing?: boolean;
  listItems: Array<WebPlanningSolutionOptimisedType>;
  pageSize?: PagingStateProps['pageSize'];
  pageSizes?: Array<number>;
  sorting?: SortingStateProps['sorting'];
  totalCount?: number;

  onAddRow?: () => void;
  onColumnWidthsChange?: TableColumnResizingProps['onColumnWidthsChange'];
  onCommitChanges: EditingStateProps['onCommitChanges'];
  onCurrentPageChange?: PagingStateProps['onCurrentPageChange'];
  onDownload?: IAddCancelDownloadProps['onDownload'];
  onFiltersChange?: FilteringStateProps['onFiltersChange'];
  onHiddenColumnNamesChange?: (value: Array<string>) => void;
  onPageSizeChange?: PagingStateProps['onPageSizeChange'];
  onSortingChange?: SortingStateProps['onSortingChange'];
}

// eslint-disable-next-line import/prefer-default-export
export const PlanningSolutionListView: React.FC<Props> = ({
  columns,
  columnWidths,
  currentPage,
  filters,
  hiddenColumns,
  isDownloading,
  isLoading,
  isRefreshing,
  listItems,
  pageSize,
  pageSizes,
  onAddRow,
  onColumnWidthsChange,
  onCommitChanges,
  onCurrentPageChange,
  onDownload,
  onFiltersChange,
  onHiddenColumnNamesChange,
  onPageSizeChange,
  onSortingChange,
  sorting,
  totalCount,
}) => (
  <Paper elevation={0}>
    <Grid rows={listItems} columns={columns}>
      <FilteringState
        filters={filters}
        onFiltersChange={onFiltersChange}
        columnFilteringEnabled={!isLoading}
        columnExtensions={[{columnName: 'actions', filteringEnabled: false}]}
      />
      <SortingState
        sorting={sorting}
        onSortingChange={onSortingChange}
        // TODO
        columnSortingEnabled={!isLoading}
        columnExtensions={[{columnName: 'actions', sortingEnabled: false}]}
      />
      <GroupingState />
      <PagingState
        currentPage={(currentPage || 1) - 1}
        onCurrentPageChange={onCurrentPageChange}
        pageSize={pageSize}
        onPageSizeChange={onPageSizeChange}
      />
      <IntegratedGrouping />
      <EditingState
        onCommitChanges={onCommitChanges}
        onAddedRowsChange={onAddRow}
      />
      <DragDropProvider />
      <Table
        noDataRowComponent={() => (
          <NoDataLoading
            loading={isLoading}
            numberOfColumns={columns.length + 2}
          />
        )}
      />
      <TableColumnResizing
        minColumnWidth={10}
        columnWidths={columnWidths}
        onColumnWidthsChange={onColumnWidthsChange}
      />
      <TableColumnReordering
        defaultOrder={columns.map((column) => column.name)}
      />
      <TableHeaderRow showSortingControls />
      <TableFilterRow />
      <TableEditColumn
        width={120}
        showDeleteCommand={RoleService.hasPermission(
          'Delete PlanningSolution',
          'Delete'
        )}
        commandComponent={Command}
      />
      {!isLoading && <PagingPanel pageSizes={pageSizes} />}
      <CustomPaging totalCount={totalCount} />
      <TableGroupRow />
      <TableColumnVisibility
        hiddenColumnNames={hiddenColumns}
        onHiddenColumnNamesChange={onHiddenColumnNamesChange}
      />
      <Toolbar />
      <ToolbarActivityIndicator
        loading={isRefreshing || isDownloading || false}
      />
      <GroupingPanel showSortingControls />
      <ColumnChooser />
      <AddCancelDownloadHeader
        loading={isLoading}
        createNewRow={onAddRow}
        showAdd={RoleService.hasPermission('Add PlanningSolution', 'Add')}
        showDownload={RoleService.hasPermission(
          'PlanningSolution ListDownload',
          'ListDownload'
        )}
        onDownload={onDownload}
        isDownloadDisabled={isDownloading}
      />
    </Grid>
  </Paper>
);
