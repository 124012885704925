import React from 'react';
import {Driver} from '@onroadvantage/onroadvantage-api';
import {useAppNotifications} from '../../../contexts';
import {BroadcastVantageMobileSteps} from './useBroadcastVantageMobile';
import {useHistory} from 'react-router-dom';
import {broadcastApi} from '../../../api';

interface BroadcastVantageMobileContextProps {
  messageIdentifier: string | undefined;
  messageHeadingRef: React.RefObject<HTMLInputElement>;
  messageRef: React.RefObject<HTMLInputElement>;
  selectedDrivers: Driver[];
  validSteps: {
    [key in BroadcastVantageMobileSteps]: {
      isValid: boolean;
      message: string;
    };
  };
  setSelectedDrivers: React.Dispatch<React.SetStateAction<Driver[]>>;
  setMessageIdentifier: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  submitting: boolean;
  onSubmit: () => Promise<void>;
}

export const BroadcastVantageMobileContext =
  React.createContext<BroadcastVantageMobileContextProps | null>(null);

export const useBroadcastVantageMobileContext = () => {
  const broadcastVantageMobileContext = React.useContext(
    BroadcastVantageMobileContext
  );

  if (broadcastVantageMobileContext == null) {
    throw new Error(
      'useBroadcastVantageMobileContext has to be used within <BroadcastVantageMobileContext.Provider>'
    );
  }

  return broadcastVantageMobileContext;
};

export const BroadcastVantageMobileContextProvider: React.FC = ({children}) => {
  const notify = useAppNotifications();
  const history = useHistory();
  const [selectedDrivers, setSelectedDrivers] = React.useState<Driver[]>([]);
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [messageIdentifier, setMessageIdentifier] = React.useState<
    string | undefined
  >();
  const messageHeadingRef = React.useRef<HTMLInputElement>(null);
  const messageRef = React.useRef<HTMLInputElement>(null);

  const cleanup = () => {
    setSelectedDrivers([]);
    setMessageIdentifier(undefined);
    if (messageRef.current != null) {
      messageRef.current.value = '';
    }
    if (messageHeadingRef.current != null) {
      messageHeadingRef.current.value = '';
    }
  };

  const handleSubmit = React.useCallback(async () => {
    setSubmitting(true);
    try {
      if (
        messageHeadingRef.current != null &&
        messageRef.current != null &&
        messageIdentifier != null &&
        selectedDrivers.length > 0
      ) {
        const smartphoneIds: number[] = [];
        selectedDrivers.forEach((driver) => {
          if (
            driver &&
            driver.user &&
            driver.user.smartphone &&
            driver.user.smartphone.id
          ) {
            smartphoneIds.push(driver.user.smartphone.id);
          }
        });
        await broadcastApi.apiBroadcastPost({
          body: {
            smartphoneIds: smartphoneIds ? smartphoneIds : [],
            heading: messageHeadingRef.current.value,
            messageUploadIdentifier: messageIdentifier,
            message: messageRef.current.value,
          },
        });
        notify('success', 'Broadcast sent');
        cleanup();
        history.push(`/broadcast`);
      } else {
        notify('warning', 'Missing required fields');
      }
    } catch (e) {
      notify('error', 'Failed to send broadcast');
    } finally {
      setSubmitting(false);
    }
  }, [history, messageIdentifier, notify, selectedDrivers]);

  React.useEffect(() => {
    return cleanup;
  }, []);

  const value = React.useMemo<BroadcastVantageMobileContextProps>(
    () => ({
      messageIdentifier,
      messageRef,
      messageHeadingRef,
      selectedDrivers,
      setMessageIdentifier,
      setSelectedDrivers,
      submitting,
      onSubmit: handleSubmit,
      validSteps: {
        drivers: {
          isValid: selectedDrivers.length > 0,
          message: 'Select at least 1 driver',
        },
        message: {isValid: true, message: 'N/A'},
      },
    }),
    [handleSubmit, messageIdentifier, selectedDrivers, submitting]
  );

  return (
    <BroadcastVantageMobileContext.Provider value={value}>
      {children}
    </BroadcastVantageMobileContext.Provider>
  );
};
