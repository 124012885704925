import React from 'react';
import {Filter, Sorting} from '@devexpress/dx-react-grid';
import {
  MasterTripDebriefConfigTatThresholdReasonCodeMinimal,
  MasterTripDebriefConfigVarianceThresholdReasonCodeMinimal,
} from '@onroadvantage/onroadvantage-api';

export type TTripDebriefConfigReasonCode =
  | MasterTripDebriefConfigTatThresholdReasonCodeMinimal
  | MasterTripDebriefConfigVarianceThresholdReasonCodeMinimal;

export interface useTripDebriefConfigReasonCodeListParams {
  reasonCodeList: TTripDebriefConfigReasonCode[];
}

const initialSorting: Sorting = {
  columnName: 'description',
  direction: 'desc',
};

export const useTripDebriefConfigReasonCodeList = ({
  reasonCodeList,
}: useTripDebriefConfigReasonCodeListParams) => {
  const [list, setList] = React.useState<TTripDebriefConfigReasonCode[]>(
    reasonCodeList ?? []
  );
  const [filters, setFilters] = React.useState<Filter[]>();
  const [sorting, setSorting] = React.useState<Sorting[]>([initialSorting]);

  const handleColumnFilter = React.useCallback(
    (newFilters: Filter[]) => (eventType: TTripDebriefConfigReasonCode) =>
      !newFilters ||
      newFilters.length === 0 ||
      !newFilters
        .map((f) => {
          if (f.value) {
            const fValue = f.value.toLowerCase();
            if (f.columnName === 'description') {
              return eventType.description?.toLowerCase()?.includes(fValue);
            } else return true;
          } else return true;
        })
        .includes(false),
    []
  );

  const handleColumnSort = React.useCallback(
    (newSorting: Sorting[]) =>
      (a: TTripDebriefConfigReasonCode, b: TTripDebriefConfigReasonCode) => {
        const column = newSorting[0]?.columnName;
        const direction = newSorting[0]?.direction;

        if (!column || !direction) {
          return 0;
        }

        switch (column) {
          case 'description': {
            if (!a.description || !b.description) {
              return 0;
            }
            if (a.description > b.description) {
              return direction === 'asc' ? -1 : 1;
            }
            return direction === 'asc' ? 1 : -1;
          }
          default:
            return 0;
        }
      },
    []
  );

  const handleFiltersChange = React.useCallback(
    (newFilters: Filter[]) => {
      setList(reasonCodeList?.filter(handleColumnFilter(newFilters)) ?? []);
      setFilters(newFilters);
    },
    [handleColumnFilter, reasonCodeList]
  );

  const handleSortingChange = React.useCallback(
    (newSorting: Sorting[]) => {
      setList((prevList) => prevList.sort(handleColumnSort(newSorting)));
      setSorting(newSorting);
    },
    [handleColumnSort]
  );

  // Set list and filter with selectedPlanningSkills or if there are any column filters
  React.useEffect(() => {
    setList(reasonCodeList.sort(handleColumnSort([initialSorting])));
  }, [reasonCodeList, handleColumnSort]);

  return {
    list,
    filters,
    sorting,
    onSortingChange: handleSortingChange,
    onFiltersChange: handleFiltersChange,
  };
};
