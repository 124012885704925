import React from 'react';
import _ from 'lodash';
import {
  ITemplateControllerRoute,
  TemplateController,
} from '../../factory/template';
import {
  DriverRankIncentiveContext,
  DriverRankIncentiveContextProvider,
} from './DriverRankIncentiveContext';
import {DriverRankIncentiveList} from './DriverRankIncentiveList';
import {DriverRankIncentiveIdHandler} from './DriverRankIncentiveIdHandler';
import {DriverRankIncentiveForm} from './DriverRankIncentiveForm';
import {DriverRankIncentive} from './DriverRankIncentive';

export const DriverRankIncentiveController: React.FC = () => {
  return (
    <DriverRankIncentiveContextProvider>
      <DriverRankIncentiveControllerComponent />
    </DriverRankIncentiveContextProvider>
  );
};

const DriverRankIncentiveControllerComponent: React.FC = () => {
  const {driverRankIncentive, loading} = React.useContext(
    DriverRankIncentiveContext
  );
  const routes: ITemplateControllerRoute[] = React.useMemo(
    () => [
      {title: 'Driver Rank Incentive List', component: DriverRankIncentiveList},
      {
        path: '/add',
        title: 'New Driver Rank Incentive',
        name: 'Add DriverRankIncentive',
        type: 'Add',
        component: () => <DriverRankIncentiveForm isAdd />,
      },
      {
        path: '/:driverRankIncentiveId',
        title: (params) =>
          loading
            ? 'Driver Rank Incentive Loading...'
            : driverRankIncentive?.name ??
              `Driver Rank Incentive ${_.get(
                params,
                'driverRankIncentiveId',
                'Single'
              )}`,
        component: DriverRankIncentive,
        IdHandler: DriverRankIncentiveIdHandler,
      },
    ],
    [loading, driverRankIncentive?.name]
  );
  return (
    <TemplateController rootPath="/driverrankincentivelist" routes={routes} />
  );
};
