import React from 'react';
import {TripDebriefContext} from './TripDebriefContext';
import {useParams} from 'react-router-dom';

interface IParams {
  masterTripId?: string;
}

export const TripDebriefIdHandler: React.FC = () => {
  const {setMasterTripId} = React.useContext(TripDebriefContext);

  const {masterTripId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = masterTripId ? parseInt(masterTripId) : undefined;
    setMasterTripId(parsedId);
    return () => setMasterTripId(undefined);
  }, [masterTripId, setMasterTripId]);

  return null;
};
