import React from 'react';
import {TemplateCard, TemplateForm} from '../../../factory/template';
import {contractDetailsSchema} from '../contractContext';
import {ContractDetailsFormFields} from './contractFormFields';
import {useContractContext} from '../contractContext';

export const ContractDetailsForm: React.FC = () => {
  const {
    loadingContract,
    detailsInitialValues,
    onContractDetailsSubmit,
    submitting,
  } = useContractContext();

  return (
    <TemplateCard title="Contract Details Edit" loading={loadingContract}>
      <TemplateForm
        initialValues={detailsInitialValues}
        onSubmit={onContractDetailsSubmit}
        validationSchema={contractDetailsSchema}
        loading={submitting}
        permission={{name: 'Edit Contract', type: 'Edit'}}
      >
        <ContractDetailsFormFields />
      </TemplateForm>
    </TemplateCard>
  );
};
