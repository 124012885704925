import _ from 'lodash';
import XLSX from 'xlsx';
import {appNotificationStore} from '../stores/mobxStores';

const mappingToHeader = (mapping) => {
  return mapping.map((i) => i.name);
};

const parseItems = (item, mapping, customParser) => {
  const i = {};
  mapping.forEach((m) => {
    i[m.name] = _.get(item, m.path);
  });

  if (customParser) {
    return {...i, ...customParser(item)};
  }

  return i;
};

const getPath = (obj, path) => {
  return path
    .split('.')
    .reduce((val, seg) => val && typeof val === 'object' && val[seg], obj);
};

const downloadSpreadsheet = (items, mapping, name, customParser = null) => {
  try {
    const parsed = items.map((i) => parseItems(i, mapping, customParser));
    const header = mappingToHeader(mapping);

    const colWidths = [];
    mapping.forEach((headerItem) => {
      let largest = headerItem.name?.length;
      items.forEach((item) => {
        const selectedItem2 = getPath(item, headerItem.path);
        if (selectedItem2?.length > largest) largest = selectedItem2?.length;
      });
      colWidths.push({wch: largest});
    });

    const ws = XLSX.utils.json_to_sheet(parsed, {header});
    const wb = XLSX.utils.book_new();

    ws['!cols'] = colWidths;
    XLSX.utils.book_append_sheet(wb, ws, name);
    XLSX.writeFile(wb, `${name}.xlsx`);
  } catch (e) {
    // might need to handle more specific errors
    appNotificationStore.enqueueNotification(
      'error',
      `Failed to retrieve the ${name}`
    );
  }
};

const viewJsonToSpreadsheet = (data, name) => {
  try {
    const ws = XLSX.utils.json_to_sheet(data.data.data ?? []);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, name.substring(0, 30));
    XLSX.writeFile(wb, `${name}.xlsx`);
  } catch (e) {
    // might need to handle more specific errors
    appNotificationStore.enqueueNotification(
      'error',
      `Failed to convert to XLSX`
    );
  }
};

const e = {
  downloadSpreadsheet,
  viewJsonToSpreadsheet,
};

export default e;
