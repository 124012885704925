import 'leaflet/dist/leaflet.css';
import '../../map/map.css';
import React from 'react';
import {inject, observer} from 'mobx-react';
import {MasterTripQuery, Trip} from '@onroadvantage/onroadvantage-api';
import {Navigation} from '@mui/icons-material';
import {useTripContext} from '../tripContext';
import {TripCard} from '../tripCard';
import Map from '../../map/map';
import {getNearbyNodes} from '../helpers';
import {useTripMapStyles} from './TripMap.style';
import {ITimelinePlayback, TripTimelineActions} from '../tripTimelineActions';
import {timelineDisplayStore} from '../../../stores/mobxStores';

export const TripMap: React.FC = inject('timelineDisplayStore')(
  observer(() => {
    const classes = useTripMapStyles();
    const {
      actualTrip,
      criticalEvents,
      telematicsEvents,
      route,
      masterTrip,
      nearbyNodes,
      setNearbyNodes,
      loadTelematicsEvents,
      loadCriticalEvents,
      loadRoute,
      loadingCriticalEvents,
      loadingTelematicsEvents,
      loadingRoute,
    } = useTripContext();
    /** Destructure masterTrip. Add ({} as MasterTripQuery), since masterTrip can be undefined. */
    const {trip} = masterTrip || ({} as MasterTripQuery);
    /** Destructure trip. Add ({} as MasterTripQuery), since trip can be undefined. */
    const {vehicle} = trip || ({} as Trip);

    /** Get singular memoized map loading state */
    const loadingMap =
      loadingCriticalEvents || loadingTelematicsEvents || loadingRoute;

    const handleMapReload = React.useCallback(async () => {
      const telematicsEvents = await loadTelematicsEvents({reload: true});
      const criticalEvents = await loadCriticalEvents({reload: true});

      if (criticalEvents?.nearbyNodes && telematicsEvents?.nearbyNodes) {
        const nearbyNodes = await getNearbyNodes(
          criticalEvents.nearbyNodes,
          telematicsEvents.nearbyNodes
        );
        setNearbyNodes(nearbyNodes);
      }

      await loadRoute({reload: true});
    }, [loadCriticalEvents, loadRoute, loadTelematicsEvents, setNearbyNodes]);

    //TODO Replace Mobx timelineDisplayStore with Context. As of yet, too many legacy components are affected by the
    // timelineDisplayStore, so they would first have to be updated
    /** Get Mobx timelineDisplayStore necessary values for the playback */
    const {
      isPlaying,
      togglePlaying,
      nextFrameTimelineSliderTime,
      prevFrameTimelineSliderTime,
    } = timelineDisplayStore;

    const nextFrame = React.useCallback(() => {
      nextFrameTimelineSliderTime();
    }, [nextFrameTimelineSliderTime]);

    const clickNextFrame = React.useCallback(() => {
      nextFrame();
    }, [nextFrame]);

    const prevFrame = React.useCallback(() => {
      prevFrameTimelineSliderTime();
    }, [prevFrameTimelineSliderTime]);

    const playback: ITimelinePlayback = React.useMemo(
      () => ({
        togglePlaying: togglePlaying as () => void,
        nextFrame: clickNextFrame,
        prevFrame: prevFrame,
        isPlaying,
      }),
      [clickNextFrame, isPlaying, prevFrame, togglePlaying]
    );

    return (
      <TripCard
        title="Trip Map"
        loadingText="Loading Map..."
        loading={loadingMap}
        onReload={handleMapReload}
        cardContentProps={{className: classes.mapContent}}
        action={<TripTimelineActions playback={playback} />}
        avatar={<Navigation />}
        expandedByDefault
      >
        <div className="leaflet-container">
          <Map
            actualTrip={actualTrip}
            nearbyNodes={nearbyNodes}
            vehicle={
              vehicle?.lastSeen?.latitude && vehicle.lastSeen.longitude
                ? vehicle
                : undefined
            }
            trip={trip}
            route={route}
            telematicsEvents={telematicsEvents}
            criticalEvents={criticalEvents}
          />
        </div>
      </TripCard>
    );
  })
);
