import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';

class SiteEditCancelCard extends React.Component {
  render() {
    const {onCancel} = this.props;
    return (
      <Card style={{marginRight: 10, flexGrow: 1, flex: 1}}>
        <CardHeader
          title="Site Edit Actions"
          avatar={
            <Avatar>
              <EditIcon />
            </Avatar>
          }
        />
        <CardContent>
          <div>
            <Button onClick={onCancel} data-test="cancel">
              Cancel
            </Button>
          </div>
        </CardContent>
      </Card>
    );
  }
}

export default SiteEditCancelCard;
