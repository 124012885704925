import {makeStyles, createStyles} from '@mui/styles';
import {Theme} from '@mui/material/styles';

//TODO Select colors
export const NESTED_LEVEL_1_LIGHT = '#f5f5f5';
export const NESTED_LEVEL_1_DARK = '#242424';
export const NESTED_LEVEL_2_LIGHT = '#eaeaea';
export const NESTED_LEVEL_2_DARK = '#323232';
export const NESTED_LEVEL_3_LIGHT = '#d1d1d1';
export const NESTED_LEVEL_3_DARK = '#484848';

export const TemplateTableStyles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: 1,
    },
    nestedLevel1: {
      backgroundColor:
        theme.palette.mode === 'dark'
          ? NESTED_LEVEL_1_DARK
          : NESTED_LEVEL_1_LIGHT,
    },
    nestedLevel2: {
      backgroundColor:
        theme.palette.mode === 'dark'
          ? NESTED_LEVEL_2_DARK
          : NESTED_LEVEL_2_LIGHT,
    },
    nestedLevel3: {
      backgroundColor:
        theme.palette.mode === 'dark'
          ? NESTED_LEVEL_3_DARK
          : NESTED_LEVEL_3_LIGHT,
    },
    rootScroll: {
      // display: 'flex',
      // flex: 1,
      // height: '100%',
    },
    tableBody: {flex: 1},
    tableFilterRow: {
      '& th:nth-of-type(1)': {
        paddingLeft: 0,
      },
    },
  });

export const useTemplateTableStyles = makeStyles(TemplateTableStyles, {
  name: 'TemplateTableStyles',
});
