import React from 'react';
import {Button, Typography} from '@mui/material';
import {ErrorRounded} from '@mui/icons-material';
import {usePasswordResetSuccessStyles} from './PasswordResetSuccess.style';
import {useHistory} from 'react-router-dom';

interface PasswordResetFailureProps {
  message?: string;
}

export const PasswordResetFailure: React.FC<PasswordResetFailureProps> = ({
  message,
}) => {
  const classes = usePasswordResetSuccessStyles();
  const history = useHistory();

  const handleButtonClick = () => {
    history.push('/login');
  };

  return (
    <div className={classes.container}>
      <ErrorRounded className={classes.icon} color="error" />
      <Typography className={classes.title}>
        <h4>{message ?? 'Failure.'}</h4>
      </Typography>
      <Button variant="contained" color="primary" onClick={handleButtonClick}>
        Back to Login
      </Button>
    </div>
  );
};
