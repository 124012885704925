import React from 'react';
import {Plugin, Template, TemplatePlaceholder} from '@devexpress/dx-react-core';
import {Tooltip, Zoom} from '@mui/material';
import {InfoOutlined} from '@mui/icons-material';
import {useTableToolbarInfoStyles} from './TableToolbarInfo.style';

interface TableToolbarInfoProps {
  toolbarInfo: React.ReactNode;
}

export const TableToolbarInfo: React.FC<TableToolbarInfoProps> = ({
  toolbarInfo,
}) => {
  const classes = useTableToolbarInfoStyles();
  return (
    <Plugin dependencies={[{name: 'Toolbar'}]} name="TableToolbarInfo">
      <Template name="toolbarContent">
        <TemplatePlaceholder />
        <Tooltip
          placement="bottom"
          classes={{tooltip: classes.tooltip}}
          TransitionComponent={Zoom}
          title={
            typeof toolbarInfo === 'function' ? toolbarInfo() : toolbarInfo
          }
        >
          <InfoOutlined className={classes.icon} color="action" />
        </Tooltip>
      </Template>
    </Plugin>
  );
};
