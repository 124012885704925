import React from 'react';
import {Profile, ProfileThemeResponse} from '@onroadvantage/onroadvantage-api';
import {profileThemeApi} from '../../api';
import {useAppNotifications} from '../../contexts';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import VerifiedUser from '@mui/icons-material/VerifiedUser';
import CardContent from '@mui/material/CardContent';
import QRCode from 'qrcode.react';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import {Loader} from '../loader';
import {Card} from '@mui/material';

interface ProfileGenerateProfileThemeProps {
  row: Profile;
}

export const ProfileGenerateProfileTheme: React.FC<
  ProfileGenerateProfileThemeProps
> = ({row}) => {
  const notify = useAppNotifications();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [profileKey, setProfileKey] = React.useState<
    ProfileThemeResponse | undefined
  >();
  const handleGenerateProfileTheme = React.useCallback(
    async function () {
      setLoading(true);
      try {
        if (row.id) {
          const response = await profileThemeApi.apiProfileThemeProfileIdGet({
            profileId: row.id,
          });
          setProfileKey(response);
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to generate QR code');
      } finally {
        setLoading(false);
      }
    },
    [notify, row.id]
  );
  if (loading) return <Loader text="Generating QR Code" disableFullWidth />;
  return (
    <Card>
      <CardHeader
        title="Profile Actions"
        avatar={
          <Avatar aria-label="ProfileActions">
            <VerifiedUser />
          </Avatar>
        }
      />
      {profileKey ? (
        <CardContent>
          <QRCode
            value={window.btoa(JSON.stringify(profileKey))}
            size={256}
            level="L"
          />
          <pre>{JSON.stringify(profileKey, null, 2)}</pre>
        </CardContent>
      ) : null}
      <CardActions disableSpacing>
        <Button size="small" onClick={handleGenerateProfileTheme}>
          Generate Theme QR
        </Button>
      </CardActions>
    </Card>
  );
};
