import React from 'react';
import {WorkflowConfigContext} from './WorkflowConfigContext';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../factory/template';
import {WorkflowConfigOptimisedList} from '@onroadvantage/onroadvantage-api';

export const WorkflowConfigList: React.FC = () => {
  const {loadList, cleanupList} = React.useContext(WorkflowConfigContext);

  const [config] = React.useState<
    TemplateTableConfig<WorkflowConfigOptimisedList>
  >({
    columns: [
      {name: 'name', label: 'Name', type: 'string'},
      {name: 'taskTypeName', label: 'Task Type', type: 'string'},
      {name: 'contracts', label: 'Contracts', type: 'string'},
    ],
    enableFilter: true,
    enableSort: true,
    deleteMessage: (row) => ({
      title: 'Workflow',
      items: {Name: row.name, TaskTypeName: row.taskTypeName},
    }),
    identifier: 'WORKFLOW_CONFIG_LIST',
  });

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [loadList, cleanupList]);

  return (
    <TemplateTableWrapper config={config} context={WorkflowConfigContext} />
  );
};
