import {makeStyles, createStyles} from '@mui/styles';
import {Theme} from '@mui/material/styles';

const TableActionBodyCellStyles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: theme.spacing(0.5),
      flex: 1,
      margin: theme.spacing(-1),
      padding: theme.spacing(1),
      backgroundColor: 'transparent',
    },
  });

export const useTableActionBodyCellStyles = makeStyles(
  TableActionBodyCellStyles,
  {name: 'TableActionBodyCellStyles'}
);
