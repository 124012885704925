import React from 'react';
import {IconButton, Stack, Typography} from '@mui/material';
import {ArrowForward, Close} from '@mui/icons-material';

interface TripSyncStopTatProps {
  initialValue: string | number | Date | null | undefined;
  newValue: string | number | Date | null | undefined;
  onCancel: () => void;
}

export const TripEditSyncListComparison: React.FC<TripSyncStopTatProps> = ({
  initialValue,
  newValue,
  onCancel,
}) => {
  return (
    <Stack direction="row" flexWrap="nowrap" spacing={0.5} alignItems="center">
      {initialValue !== newValue ? (
        <>
          <Typography color="error.main">{initialValue ?? '-'}</Typography>
          <ArrowForward fontSize="small" />
          <Typography color="success.main">{newValue ?? '-'}</Typography>
        </>
      ) : (
        <Typography>{initialValue}</Typography>
      )}
      <Stack direction="row">
        {initialValue !== newValue ? (
          <IconButton size="small" onClick={onCancel}>
            <Close fontSize="small" />
          </IconButton>
        ) : null}
      </Stack>
    </Stack>
  );
};
