import React from 'react';
import {masterTripDebriefConfigApi} from '../../../api';
import {useAppNotifications} from '../../../contexts';
import {useTripDebriefConfigContext} from '../tripDebriefConfigContext';
import {
  parsePotentialString,
  splitByCapitals,
  TemplateTable,
  TemplateTableConfig,
  TOnInlineAdd,
  TOnInlineEdit,
} from '../../../factory/template';
import {RoleService} from '../../../service';
import {
  TTripDebriefConfigReasonCode,
  useTripDebriefConfigReasonCodeList,
} from './useTripDebriefConfigReasonCodeList';

export interface TripDebriefConfigReasonCodeListProps {
  list: TTripDebriefConfigReasonCode[] | undefined;
  type: 'TatThreshold' | 'VarianceThreshold';
}

export const TripDebriefConfigReasonCodeList: React.FC<
  TripDebriefConfigReasonCodeListProps
> = ({list: reasonCodeList = [], type}) => {
  const notify = useAppNotifications();
  const {
    loadingTripDebriefConfig,
    masterTripDebriefConfig,
    masterTripDebriefConfigId,
    setMasterTripDebriefConfig,
  } = useTripDebriefConfigContext();
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const tripDebriefConfigReasonCodeList = useTripDebriefConfigReasonCodeList({
    reasonCodeList,
  });

  const config = React.useMemo<
    TemplateTableConfig<TTripDebriefConfigReasonCode>
  >(
    () => ({
      columns: [
        {
          name: 'description',
          label: 'Description',
          type: 'string',
          enableEditing: true,
        },
      ],
      enableFilter: true,
      enableSort: true,
      disablePagination: true,
      disableToolbar: true,
      deleteMessage: (row) => ({
        title: `Trip Debrief Config ${splitByCapitals(type)}`,
        items: {
          TripDebriefConfig: masterTripDebriefConfig?.name,
          Description: row.description,
        },
      }),
      identifier: `TRIP_DEBRIEF_CONFIG_${splitByCapitals(type, {
        capabilitiesAllLetters: true,
        joinCharacter: '_',
      })}_List`,
    }),
    [masterTripDebriefConfig?.name, type]
  );

  const handleAddReasonCodeToState = React.useCallback(
    (
      reasonCode: TTripDebriefConfigReasonCode,
      key: 'tatThresholdReasonCodes' | 'varianceThresholdReasonCodes'
    ) => {
      setMasterTripDebriefConfig((prevState) =>
        prevState
          ? {
              ...prevState,
              [key]: [...(prevState[key] ?? []), reasonCode],
            }
          : undefined
      );
    },
    [setMasterTripDebriefConfig]
  );

  const handleUpdateReasonCodeInState = React.useCallback(
    (
      reasonCode: TTripDebriefConfigReasonCode,
      key: 'tatThresholdReasonCodes' | 'varianceThresholdReasonCodes'
    ) => {
      setMasterTripDebriefConfig((prevState) =>
        prevState
          ? {
              ...prevState,
              [key]: [
                ...(prevState[key] ?? []).map((prevStateReasonCode) =>
                  prevStateReasonCode.id === reasonCode.id
                    ? reasonCode
                    : prevStateReasonCode
                ),
              ],
            }
          : undefined
      );
    },
    [setMasterTripDebriefConfig]
  );

  const handleRemoveReasonCodeFromState = React.useCallback(
    (
      reasonCodeId: number,
      key: 'tatThresholdReasonCodes' | 'varianceThresholdReasonCodes'
    ) => {
      setMasterTripDebriefConfig((prevState) =>
        prevState
          ? {
              ...prevState,
              [key]: prevState[key]?.filter(({id}) => id !== reasonCodeId),
            }
          : undefined
      );
    },
    [setMasterTripDebriefConfig]
  );

  const handleInlineAdd = React.useCallback<TOnInlineAdd>(
    async (changes) => {
      setSubmitting(true);
      try {
        if (
          masterTripDebriefConfigId &&
          changes[0]?.description !== undefined
        ) {
          let didSubmit = false;
          const splitDescription = changes[0]?.description.split(', ');
          switch (type) {
            case 'TatThreshold': {
              for (const description of splitDescription) {
                const response =
                  await masterTripDebriefConfigApi.apiMasterTripDebriefConfigTatThresholdReasonCodePost(
                    {
                      body: {description, masterTripDebriefConfigId},
                    }
                  );
                if (response.id) {
                  handleAddReasonCodeToState(
                    response,
                    'tatThresholdReasonCodes'
                  );
                  didSubmit = true;
                }
              }
              break;
            }
            case 'VarianceThreshold': {
              for (const description of splitDescription) {
                const response =
                  await masterTripDebriefConfigApi.apiMasterTripDebriefConfigVarianceThresholdReasonCodePost(
                    {
                      body: {description, masterTripDebriefConfigId},
                    }
                  );
                if (response.id) {
                  handleAddReasonCodeToState(
                    response,
                    'varianceThresholdReasonCodes'
                  );
                  didSubmit = true;
                }
              }
              break;
            }
            default:
              break;
          }
          if (didSubmit) {
            notify(
              'success',
              `Added ${splitByCapitals(type, {
                lowercaseAllLetters: true,
              })} reason code`
            );
          } else {
            notify(
              'error',
              `Failed to add ${splitByCapitals(type, {
                lowercaseAllLetters: true,
              })} reason code`
            );
          }
        } else {
          notify(
            'warning',
            "Couldn't find masterTripDebriefConfigId or description"
          );
        }
      } catch (e) {
        notify(
          'error',
          `Failed to add ${splitByCapitals(type, {
            lowercaseAllLetters: true,
          })} reason code`
        );
      } finally {
        setSubmitting(false);
      }
    },
    [handleAddReasonCodeToState, masterTripDebriefConfigId, notify, type]
  );

  const handleInlineEdit = React.useCallback<TOnInlineEdit>(
    async (changes) => {
      setSubmitting(true);
      try {
        for (const change of changes) {
          const reasonCodeId = parsePotentialString(change.id);
          const description = change.newValues?.description;
          if (
            masterTripDebriefConfigId &&
            reasonCodeId &&
            description !== undefined
          ) {
            let didSubmit = false;
            switch (type) {
              case 'TatThreshold': {
                const response =
                  await masterTripDebriefConfigApi.apiMasterTripDebriefConfigTatThresholdReasonCodeTatThresholdReasonCodeIdPatch(
                    {
                      tatThresholdReasonCodeId: reasonCodeId,
                      body: {description},
                    }
                  );
                if (response.id) {
                  handleUpdateReasonCodeInState(
                    response,
                    'tatThresholdReasonCodes'
                  );
                  didSubmit = true;
                }
                break;
              }
              case 'VarianceThreshold': {
                const response =
                  await masterTripDebriefConfigApi.apiMasterTripDebriefConfigVarianceThresholdReasonCodeVarianceThresholdReasonCodeIdPatch(
                    {
                      varianceThresholdReasonCodeId: reasonCodeId,
                      body: {description},
                    }
                  );
                if (response.id) {
                  handleUpdateReasonCodeInState(
                    response,
                    'varianceThresholdReasonCodes'
                  );
                  didSubmit = true;
                }
                break;
              }
              default:
                break;
            }
            if (didSubmit) {
              notify(
                'success',
                `Updated ${splitByCapitals(type, {
                  lowercaseAllLetters: true,
                })} reason code`
              );
            } else {
              notify(
                'error',
                `Failed to update ${splitByCapitals(type, {
                  lowercaseAllLetters: true,
                })} reason code`
              );
            }
          } else {
            notify(
              'warning',
              "Couldn't find reasonCodeId, masterTripDebriefConfigId or description"
            );
          }
        }
      } catch (e) {
        notify(
          'error',
          `Failed to update ${splitByCapitals(type, {
            lowercaseAllLetters: true,
          })} reason code`
        );
      } finally {
        setSubmitting(false);
      }
    },
    [handleUpdateReasonCodeInState, masterTripDebriefConfigId, notify, type]
  );

  const handleDelete = React.useCallback(
    async (row: TTripDebriefConfigReasonCode) => {
      setSubmitting(true);
      try {
        if (masterTripDebriefConfigId && row.id) {
          let didSubmit = false;
          switch (type) {
            case 'TatThreshold': {
              const response =
                await masterTripDebriefConfigApi.apiMasterTripDebriefConfigTatThresholdReasonCodeTatThresholdReasonCodeIdDelete(
                  {
                    tatThresholdReasonCodeId: row.id,
                  }
                );
              if (response) {
                handleRemoveReasonCodeFromState(
                  row.id,
                  'tatThresholdReasonCodes'
                );
                didSubmit = true;
              }
              break;
            }
            case 'VarianceThreshold': {
              const response =
                await masterTripDebriefConfigApi.apiMasterTripDebriefConfigVarianceThresholdReasonCodeVarianceThresholdReasonCodeIdDelete(
                  {
                    varianceThresholdReasonCodeId: row.id,
                  }
                );
              if (response) {
                handleRemoveReasonCodeFromState(
                  row.id,
                  'varianceThresholdReasonCodes'
                );
                didSubmit = true;
              }
              break;
            }
            default:
              break;
          }
          if (didSubmit) {
            notify(
              'success',
              `Deleted ${splitByCapitals(type, {
                lowercaseAllLetters: true,
              })} reason code`
            );
          } else {
            notify(
              'error',
              `Failed to delete ${splitByCapitals(type, {
                lowercaseAllLetters: true,
              })} reason code`
            );
          }
        } else {
          notify(
            'warning',
            "Couldn't find masterTripDebriefConfigId or reason code id"
          );
        }
      } catch (e) {
        notify(
          'error',
          `Failed to delete ${splitByCapitals(type, {
            lowercaseAllLetters: true,
          })} reason code`
        );
      } finally {
        setSubmitting(false);
      }
    },
    [handleRemoveReasonCodeFromState, masterTripDebriefConfigId, notify, type]
  );

  return (
    <TemplateTable
      config={config}
      currentPage={1}
      loading={loadingTripDebriefConfig || submitting}
      onInlineAdd={
        RoleService.hasPermission('Edit MasterTripDebriefConfig', 'Edit')
          ? handleInlineAdd
          : undefined
      }
      onInlineEdit={
        RoleService.hasPermission('Edit MasterTripDebriefConfig', 'Edit')
          ? handleInlineEdit
          : undefined
      }
      onDelete={
        RoleService.hasPermission('Edit MasterTripDebriefConfig', 'Edit')
          ? handleDelete
          : undefined
      }
      {...tripDebriefConfigReasonCodeList}
    />
  );
};
