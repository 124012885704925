import React from 'react';
import {
  TripDebriefSummaryCard,
  TripDebriefSummaryCardList,
} from './tripDebriefSummaryCard';
import {TripDebriefContext} from '../TripDebriefContext';
import {VantageChart} from '../../chart';
import {ChartData, ChartOptions} from 'chart.js';
import {Typography} from '@mui/material';
import {Loader} from '../../loader';
import {MasterTripDebriefSnapshot} from '@onroadvantage/onroadvantage-api';

interface GroupedEvent {
  count: number;
  name: string;
}

export const TripDebriefSummaryEvents: React.FC = () => {
  const {masterTripDebriefData} = React.useContext(TripDebriefContext);
  /**
   *  Destructure snapshotData, need to add || ({} as MasterTripDebriefSnapshot) for typescript, since
   *  snapshotData is nullable.
   */
  const {criticalEvents, operationalEvents} =
    masterTripDebriefData?.snapshotData || ({} as MasterTripDebriefSnapshot);
  /** data -> Used for the Events Doughnut Chart */
  const [data, setData] = React.useState<ChartData>({});
  /** list -> Used as a useState in this case instead of useMemo, because we want to set both the data and list onMount */
  const [list, setList] = React.useState<TripDebriefSummaryCardList>([]);
  /** options -> Vantage Chart Options */
  const [options] = React.useState<ChartOptions>({
    legend: {position: 'right'},
    showLines: true,
    spanGaps: true,
  });
  /** isRendered -> To only render the VantageChart after useEffect has ran and rendered */
  const [isRendered, setIsRendered] = React.useState<boolean>(false);

  React.useEffect(() => {
    /**
     * We need to group the events by there type.
     * We will have an object of objects with the name of the type and the current count of that type of event.
     * The key will also be the name of the eventType
     */
    const groupedEvents: {
      [key: string]: GroupedEvent;
    } = {};

    /** We then loop over both the critical and operational events and add the events to the relevant groupedEvents and increment the relevant count */
    criticalEvents?.forEach((event) => {
      if (event.criticalEventType?.name) {
        groupedEvents[event.criticalEventType.name] = {
          count: (groupedEvents[event.criticalEventType.name]?.count ?? 0) + 1,
          name: event.criticalEventType.name,
        };
      }
    });

    operationalEvents?.forEach((event) => {
      if (event.operationalEventType?.name) {
        groupedEvents[event.operationalEventType.name] = {
          count:
            (groupedEvents[event.operationalEventType.name]?.count ?? 0) + 1,
          name: event.operationalEventType.name,
        };
      }
    });

    /** We then set the list for the TripDebriefSummaryCard, with primary as the eventTypeName and secondary as the count */
    if (
      (!criticalEvents || criticalEvents.length === 0) &&
      (!operationalEvents || operationalEvents.length === 0)
    ) {
      setList([]);
    } else {
      setList(
        Object.keys(groupedEvents).map((key) => ({
          primary: groupedEvents[key].name,
          secondary: groupedEvents[key].count,
          type: 'number',
          changed: false,
        }))
      );
    }

    /**
     * We then want set the data for the Pie chart. Labels will be the eventTypeName, and data will be the count
     * The reason why we do an extra * 100 inside the Math.round() and divide by 100 outside it again is to round the
     * percentage to 2 decimal points.
     * Math.round(50.45123 * 100) / 100 will give you 50.45
     */
    setData({
      labels: Object.keys(groupedEvents).map((key) => groupedEvents[key].name),
      datasets: [
        {
          type: 'doughnut',
          label: 'Pie Component',
          data: Object.keys(groupedEvents).map(
            (key) => Math.round(groupedEvents[key].count * 100) / 100
          ),
        },
      ],
    });

    setIsRendered(true);

    return () => {
      /** Finally cleanup both states on unmount */
      setList([]);
      setData({});
      setIsRendered(false);
    };
  }, [criticalEvents, operationalEvents]);

  if (!isRendered) {
    return <Loader text="Rendering events..." />;
  }

  return (
    <TripDebriefSummaryCard
      title="Events"
      list={list}
      CardContentProps={{
        sx: {
          display: 'flex',
          gap: 1.5,
          flexDirection: {xs: 'column', xl: 'row'},
        },
      }}
      /** Reason for -7.5 on xl screen size is to get the children's header inline with the CardHeader (when the Content's direction is row) */
      ChildrenProps={{textAlign: 'center', mt: {xs: 1.5, xl: -7.5}}}
    >
      {list.length > 0 && (
        <>
          <Typography
            p={1}
            mb={2}
            fontWeight={600}
            color="primary.contrastText"
            bgcolor="primary.main"
          >
            Percentage of Events for Trip
          </Typography>
          <VantageChart type="doughnut" data={data} options={options} />
        </>
      )}
    </TripDebriefSummaryCard>
  );
};
