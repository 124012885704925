import {decorate, observable, action} from 'mobx';
import {PlanningSolution as PlanningSolutionType} from '@onroadvantage/onroadvantage-api';
import _ from 'lodash';

export interface ILaneCardData {
  description?: string;
  id: string | number;
  label: string;
  laneId?: string;
  title: string;
}

export interface ILaneData {
  cards: ILaneCardData[];
  currentPage?: string | number;
  id: string;
  title: string;
}

export class PlanningSolutionStore {
  private planningSolution: PlanningSolutionType | null;
  private vehicleLaneData: any;
  private driverLaneData: any;
  private unassignedOrderLaneData: any;
  private planningOrderLaneData: ILaneData[];
  listItems: PlanningSolutionType[];

  constructor() {
    this.planningSolution = null;
    this.vehicleLaneData = null;
    this.driverLaneData = null;
    this.unassignedOrderLaneData = null;
    this.planningOrderLaneData = [];
    this.listItems = [];
  }

  setPlanningSolution = (planningSolution: PlanningSolutionType | null) => {
    this.planningSolution = planningSolution;
  };

  setPlanningOrderLaneData = (planningOrderLaneData: ILaneData[] | null) => {
    if (planningOrderLaneData) {
      this.planningOrderLaneData = planningOrderLaneData;
    }
  };

  setVehicleLaneData = (vehicleLaneData: any) => {
    this.vehicleLaneData = vehicleLaneData;
  };

  setDriverLaneData = (driverLaneData: any) => {
    this.driverLaneData = driverLaneData;
  };

  setUnassignedOrderLaneData = (unassignedOrderLaneData: any) => {
    this.unassignedOrderLaneData = unassignedOrderLaneData;
  };

  setListItems = (listItems: PlanningSolutionType[]) => {
    this.listItems = listItems;
  };

  addListItem = (listItem: any) => {
    const newItems = [...this.listItems, listItem];
    _.remove(newItems, function (currentObject) {
      return Object.keys(currentObject).length < 1;
    });
    this.setListItems(newItems);
    this.setPlanningSolution(listItem);
  };

  editListItem = (listItem: any) => {
    const oldItemIndex = _.findIndex(this.listItems, {id: listItem.id});
    this.listItems.splice(oldItemIndex, 1, listItem);
    this.setPlanningSolution(listItem);
  };

  removeListItem = (listItem: any) => {
    _.remove(this.listItems, {id: listItem.id});
    this.setListItems(this.listItems);
    this.setPlanningSolution(null);
  };
}

export default decorate(PlanningSolutionStore, {
  // @ts-expect-error upgrade TODO fix this type
  planningSolution: observable,
  driverLaneData: observable,
  vehicleLaneData: observable,
  unassignedOrderLaneData: observable,
  planningOrderLaneData: observable,
  listItems: observable,
  setPlanningSolution: action,
  setPlanningOrderLaneData: action,
  setVehicleLaneData: action,
  setDriverLaneData: action,
  setUnassignedOrderLaneData: action,
  addListItem: action,
  editListItem: action,
  removeListItem: action,
  setListItems: action,
});
