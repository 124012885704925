import React from 'react';
import {useHistory} from 'react-router-dom';
import {
  ApiNodeTypeGetRequest,
  NodeType as NodeTypeType,
  NodeTypeList,
  NodeTypePost,
  NodeTypeUpdate,
} from '@onroadvantage/onroadvantage-api';
import {FormikHelpers, FormikProps} from 'formik/dist/types';
import {useAppNotifications} from '../../contexts';
import {nodeTypeApi} from '../../api';
import {
  TemplateTableContextProps,
  TLoadList,
  useTemplateTable,
} from '../../factory/template';
import {INodeTypeForm} from './NodeTypeForm';

export interface NodeTypeContextProps
  extends TemplateTableContextProps<NodeTypeType, NodeTypeList> {
  onDetailsFormSubmit: (
    values: INodeTypeForm,
    formikHelpers: FormikHelpers<INodeTypeForm>
  ) => void;
  setNodeTypeId: (value: number | undefined) => void;
  submitting: boolean;
  nodeType?: NodeTypeType;
  nodeTypeId?: number;
  detailsRef?: React.Ref<FormikProps<INodeTypeForm>>;
}

export const NodeTypeContext = React.createContext<NodeTypeContextProps>({
  // Template Table Defaults
  loading: false,
  list: [],
  currentPage: 1,
  // NodeType
  loadList: async () => {},
  onDetailsFormSubmit: () => null,
  setNodeTypeId: () => null,
  submitting: false,
});

interface NodeTypeContextProviderProps {
  nodeTypeId?: number;
}

export const NodeTypeContextProvider: React.FC<
  NodeTypeContextProviderProps
> = ({children}) => {
  const history = useHistory();
  const notify = useAppNotifications();
  // Template Table
  const [
    {
      // States
      currentPage,
      filters,
      hasPermission,
      itemTotal,
      list,
      loading,
      pageSize,
      pageTotal,
      loadingSingleItem,
      sorting,
    },
    {
      // Getters
      getDownloads,
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handlePageSizeCountsChange,
      handleFiltersChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
      setLoadingSingleItem,
    },
  ] = useTemplateTable<NodeTypeType, ApiNodeTypeGetRequest>({
    editPermission: 'Edit NodeType',
    addPermission: 'Add NodeType',
    deletePermission: 'Delete NodeType',
    downloadPermission: 'NodeType ListDownload',
    viewPermission: 'NodeType List',
  });

  const loadList = React.useCallback<TLoadList<NodeTypeList>>(
    async (options) => {
      setLoading(true);
      try {
        const requestObj = getRequestObj(['name', 'description'], options);
        return getResponse(
          await nodeTypeApi.apiNodeTypeGet(requestObj),
          options
        );
      } catch (e) {
        notify('error', e.message ?? 'Failed to load node type list');
      } finally {
        setLoading(false);
      }
    },
    [getRequestObj, getResponse, notify, setLoading]
  );

  const handleDelete = React.useCallback(
    async (row: NodeTypeType) => {
      setLoading(true);
      try {
        if (row.id) {
          await nodeTypeApi.apiNodeTypeNodeTypeIdDelete({
            nodeTypeId: row.id,
          });
          await loadList();
          notify('success', 'Deleted node type');
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to delete');
      } finally {
        setLoading(false);
      }
    },
    [loadList, notify, setLoading]
  );

  const handleDownload = React.useCallback(
    async () =>
      getDownloads('nodeTypes', loadList, [
        {name: 'Name', path: 'name'},
        {name: 'Description', path: 'description'},
      ]),
    [getDownloads, loadList]
  );

  const handleAdd = React.useCallback(
    () => history.push('/nodetypelist/add'),
    [history]
  );

  const handleNavigate = React.useCallback(
    (row) => history.push(`/nodetypelist/${row.id}`),
    [history]
  );

  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  // Forms
  const [nodeType, setNodeType] = React.useState<NodeTypeType | undefined>();
  const [nodeTypeId, setNodeTypeId] = React.useState<number | undefined>();
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const detailsRef = React.useRef<FormikProps<INodeTypeForm>>(null);

  const loadNodeType = React.useCallback(async () => {
    setLoadingSingleItem(true);
    try {
      if (nodeTypeId) {
        const response = await nodeTypeApi.apiNodeTypeNodeTypeIdGet({
          nodeTypeId,
        });
        setNodeType(response);
      } else {
        setNodeType(undefined);
      }
    } catch (e) {
      notify('error', e.message ?? 'Failed to load node type');
    } finally {
      setLoadingSingleItem(false);
    }
  }, [notify, setLoadingSingleItem, nodeTypeId]);

  const handleDetailsFormSubmit = React.useCallback(
    async (
      values: INodeTypeForm,
      formikHelpers: FormikHelpers<INodeTypeForm>
    ) => {
      setSubmitting(true);
      try {
        if (nodeTypeId) {
          const {planningSkills, ...otherValues} = values;

          const newValues: NodeTypeUpdate = {
            planningSkillIds: planningSkills
              ?.filter(({value}) => !!value)
              ?.map(({value}) => value as number),
            ...otherValues,
          };

          await nodeTypeApi.apiNodeTypeNodeTypeIdPatch({
            nodeTypeId,
            body: newValues,
          });
        } else {
          const {planningSkills, ...otherValues} = values;

          const newValues: NodeTypePost = {
            planningSkillIds: planningSkills
              ?.filter(({value}) => !!value)
              ?.map(({value}) => value as number),
            ...otherValues,
          };
          await nodeTypeApi.apiNodeTypePost({
            body: newValues,
          });
        }
        history.push('/nodetypelist');
        notify('success', `${nodeTypeId ? 'Updated' : 'Added'} node type`);
      } catch (e) {
        notify(
          'error',
          e.message ?? `Failed to ${nodeTypeId ? 'update' : 'add'} node type`
        );
      } finally {
        formikHelpers.setSubmitting(false);
        setSubmitting(false);
      }
    },
    [history, notify, setSubmitting, nodeTypeId]
  );

  const value: NodeTypeContextProps = {
    // Template Table
    list,
    loadList,
    hasPermission,
    loading: loadingSingleItem || loading,
    cleanupList,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onDownload: hasPermission.download ? handleDownload : undefined,
    onAdd: hasPermission.add ? handleAdd : undefined,
    onNavigate: handleNavigate,
    onDelete: hasPermission.delete ? handleDelete : undefined,
    onRefresh: handleRefresh,
    // Forms
    onDetailsFormSubmit: handleDetailsFormSubmit,
    setNodeTypeId,
    submitting,
    nodeType,
    nodeTypeId,
    detailsRef,
  };

  React.useEffect(() => {
    loadNodeType();
    return () => setNodeType(undefined);
  }, [loadNodeType]);

  return (
    <NodeTypeContext.Provider value={value}>
      {children}
    </NodeTypeContext.Provider>
  );
};
