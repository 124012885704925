import React from 'react';
import {WebPlanningBoardVehicle} from '@onroadvantage/onroadvantage-api';
import {insertIntoArray} from '../../../../factory/template';
import {usePlanningBoardSettingsContext} from '../../planningBoardContext';

export type TPlanningBoardGroupOptionsVariants = 'selected' | 'unselected';

export interface IPlanningBoardGroupOptionItem {
  label: string;
  key: keyof WebPlanningBoardVehicle;
}

export type TGroupOptionDragHandler = (
  variant: TPlanningBoardGroupOptionsVariants,
  item?: IPlanningBoardGroupOptionItem
) => (event: React.DragEvent<HTMLDivElement>) => void;

export interface PlanningBoardGroupOptionContextProps {
  draggingItem: IPlanningBoardGroupOptionItem | undefined;
  overItem: IPlanningBoardGroupOptionItem | undefined;
  overContainer: TPlanningBoardGroupOptionsVariants | undefined;
  loadGroupOptions: () => void;
  onDragStart: TGroupOptionDragHandler;
  onDragOver: TGroupOptionDragHandler;
  onDragLeave: TGroupOptionDragHandler;
  onDrop: TGroupOptionDragHandler;
}

export const initialSelectedGroupOptions: IPlanningBoardGroupOptionItem[] = [
  {label: 'Fleet Number', key: 'fleetNumber'},
];

export const initialUnselectedGroupOptions: IPlanningBoardGroupOptionItem[] = [
  {label: 'Registration Number', key: 'registrationNumber'},
  {label: 'Description', key: 'description'},
  {label: 'External ID', key: 'externalId'},
  {label: 'Vehicle Type', key: 'type'},
];

export const PlanningBoardGroupOptionContext =
  React.createContext<PlanningBoardGroupOptionContextProps>({
    draggingItem: undefined,
    overItem: undefined,
    overContainer: undefined,
    loadGroupOptions: () => null,
    onDragStart: () => () => null,
    onDragOver: () => () => null,
    onDragLeave: () => () => null,
    onDrop: () => () => null,
  });

export const PlanningBoardGroupOptionContextProvider: React.FC = ({
  children,
}) => {
  const {setSelectedGroupOptions, setUnselectedGroupOptions} =
    usePlanningBoardSettingsContext();
  const [draggingItem, setDraggingItem] = React.useState<
    IPlanningBoardGroupOptionItem | undefined
  >();
  const [overContainer, setOverContainer] = React.useState<
    TPlanningBoardGroupOptionsVariants | undefined
  >();
  const [overItem, setOverItem] = React.useState<
    IPlanningBoardGroupOptionItem | undefined
  >();

  const handleDragStart = React.useCallback<TGroupOptionDragHandler>(
    (variant, item) => (_event) => {
      setDraggingItem(item);
    },
    []
  );

  const handleDragOver = React.useCallback<TGroupOptionDragHandler>(
    (variant, item) => (event) => {
      event.stopPropagation();
      event.preventDefault();
      setOverContainer(variant);
      setOverItem(item);
    },
    []
  );

  const handleDragLeave = React.useCallback<TGroupOptionDragHandler>(
    (variant, item) => (_event) => {
      setOverContainer((prev) => (prev === variant ? undefined : variant));
      setOverItem((prev) => (prev === item ? undefined : item));
    },
    []
  );

  const handleDrop = React.useCallback<TGroupOptionDragHandler>(
    (variant, item) => (_event) => {
      if (draggingItem) {
        const handleMoveItem = (
          arr: IPlanningBoardGroupOptionItem[],
          variantToCheck: TPlanningBoardGroupOptionsVariants
        ) =>
          variant === variantToCheck
            ? item
              ? insertIntoArray(
                  arr.filter(({key}) => key !== draggingItem.key),
                  draggingItem,
                  arr.indexOf(item)
                )
              : !arr.find(({key}) => key === draggingItem.key)
              ? [...arr, draggingItem]
              : arr
            : arr.filter(({key}) => key !== draggingItem.key);

        setUnselectedGroupOptions((prev) => {
          const movedItems = handleMoveItem(prev, 'unselected');
          localStorage.setItem('unselectedItems', JSON.stringify(movedItems));
          return movedItems;
        });
        setSelectedGroupOptions((prev) => {
          const movedItems = handleMoveItem(prev, 'selected');
          localStorage.setItem('selectedItems', JSON.stringify(movedItems));
          return movedItems;
        });
      }
      setDraggingItem(undefined);
      setOverContainer(undefined);
      setOverItem(undefined);
    },
    [draggingItem, setSelectedGroupOptions, setUnselectedGroupOptions]
  );

  const handleLoadGroupOptions = React.useCallback(() => {
    const localSelectedItems = JSON.parse(
      localStorage.getItem('selectedItems') ?? '[]'
    ) as IPlanningBoardGroupOptionItem[];
    const localUnselectedItems = JSON.parse(
      localStorage.getItem('unselectedItems') ?? '[]'
    ) as IPlanningBoardGroupOptionItem[];
    setSelectedGroupOptions(
      localSelectedItems.length > 0 || localUnselectedItems.length > 0
        ? localSelectedItems
        : initialSelectedGroupOptions
    );
    setUnselectedGroupOptions(
      localSelectedItems.length > 0 || localUnselectedItems.length > 0
        ? localUnselectedItems
        : initialUnselectedGroupOptions
    );
  }, [setSelectedGroupOptions, setUnselectedGroupOptions]);

  React.useEffect(() => {
    handleLoadGroupOptions();
  }, [handleLoadGroupOptions]);

  const value: PlanningBoardGroupOptionContextProps = {
    draggingItem,
    overItem,
    overContainer,
    loadGroupOptions: handleLoadGroupOptions,
    onDragStart: handleDragStart,
    onDragOver: handleDragOver,
    onDragLeave: handleDragLeave,
    onDrop: handleDrop,
  };

  return (
    <PlanningBoardGroupOptionContext.Provider value={value}>
      {children}
    </PlanningBoardGroupOptionContext.Provider>
  );
};
