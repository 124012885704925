import React from 'react';
import {Skeleton} from '@mui/material';
import {usePlanningBoardGanttStyles} from './PlanningBoardGantt.style';
import {Loader} from '../../../loader';
import {
  usePlanningBoardContext,
  usePlanningBoardGanttContext,
} from '../../planningBoardContext';

export const PlanningBoardGanttSkeleton: React.FC = () => {
  const classes = usePlanningBoardGanttStyles();
  const {
    loadingCommitBookingTrips,
    loadingInitial,
    loadingMasterTrips,
    loadingOptimalTrips,
    loadingVehicles,
  } = usePlanningBoardContext();
  const {
    loadingCreateMasterTrip,
    loadingDeleteMasterTrip,
    loadingUpdateMasterTrip,
  } = usePlanningBoardGanttContext();
  const [loadingMessages, setLoadingMessages] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (loadingInitial) setLoadingMessages((prev) => [...prev, 'Initializing']);
    else {
      loadingCommitBookingTrips &&
        setLoadingMessages((prev) => [...prev, 'Committing master trips']);
      loadingMasterTrips &&
        setLoadingMessages((prev) => [...prev, 'Fetching master trips']);
      loadingVehicles &&
        setLoadingMessages((prev) => [...prev, 'Fetching vehicles']);
      loadingCreateMasterTrip &&
        setLoadingMessages((prev) => [...prev, 'Adding to gantt']);
      loadingUpdateMasterTrip &&
        setLoadingMessages((prev) => [...prev, 'Updating booking master trip']);
      loadingOptimalTrips &&
        setLoadingMessages((prev) => [...prev, 'Calculating optimal trips']);
      loadingDeleteMasterTrip &&
        setLoadingMessages((prev) => [...prev, 'Deleting master trip']);
    }
    return () => setLoadingMessages([]);
  }, [
    loadingCommitBookingTrips,
    loadingCreateMasterTrip,
    loadingDeleteMasterTrip,
    loadingInitial,
    loadingMasterTrips,
    loadingOptimalTrips,
    loadingUpdateMasterTrip,
    loadingVehicles,
  ]);

  return (
    <div className={classes.skeletonContainer}>
      <Loader
        classes={classes}
        text={
          loadingMessages.length > 0
            ? `${loadingMessages.join(', ')}`
            : undefined
        }
      />
      <Skeleton height={49} />
      <Skeleton height={49} />
      <Skeleton height={49} />
      <Skeleton height={49} />
      <Skeleton height={49} />
      <Skeleton height={49} />
      <Skeleton height={49} />
      <Skeleton height={49} />
      <Skeleton
        variant="rectangular"
        className={classes.rectSkeleton}
        height={50}
      />
    </div>
  );
};
