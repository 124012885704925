import React from 'react';
import {useFormikContext} from 'formik';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import EditIcon from '@mui/icons-material/Edit';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import queryString from 'query-string';
import {Listing} from '@onroadvantage/onroadvantage-api';
import _ from 'lodash';
import {usePlanningSolutionData} from '../../../stores/context';
import {PlanningSolutionFormValues} from '../planningSolution/PlanningSolutionForm';
import PlanningMasterTripMapCard from '../../map/PlanningMasterTripMapCard';
import {FormikAutocompleteHooks, FormikTextField} from '../../formik';
import {usePlanningTripHeaderStyles} from './PlanningTripHeader.style';

interface IParams {
  solutionId: string;
  tripId: string;
  tempId?: string;
}

export const PlanningTripHeaderComponent: React.FC<
  RouteComponentProps<IParams>
> = ({match, location}) => {
  const formik = useFormikContext<PlanningSolutionFormValues>();
  const classes = usePlanningTripHeaderStyles();

  // TODO: do not include all drivers/vehicles
  const {driverListings, vehicleListings} = usePlanningSolutionData(
    (store) => ({
      driverListings: store.rawDriverListings,
      vehicleListings: store.rawVehicleListings,
    })
  );
  const tripIndex = (formik.values.trips || []).findIndex((t) => {
    const tripId = +match.params.tripId;
    const {tempId} = queryString.parse(location.search);
    if (tripId === -1 && tempId) {
      return t.id === tripId && t.tempId === tempId;
    }
    return t.id === tripId;
  });

  // calculate solution driver listing
  const allocatedDriverListing: Listing[] = [];
  driverListings.forEach((d) => {
    if (
      formik.values.allocatedDrivers &&
      formik.values.allocatedDrivers.findIndex((i) => i === d.value) > -1
    ) {
      allocatedDriverListing.push(d);
    }
  });

  const allocatedVehicleListing: Listing[] = [];
  vehicleListings.forEach((d) => {
    if (
      formik.values.allocatedVehicles &&
      formik.values.allocatedVehicles.findIndex((i) => i === d.value) > -1
    ) {
      allocatedVehicleListing.push(d);
    }
  });

  let driver: Listing = {};
  let vehicle: Listing = {};
  if (tripIndex !== -1) {
    driver = _.get(formik.values, `trips[${tripIndex}].driver`);
    vehicle = _.get(formik.values, `trips[${tripIndex}].vehicle`);
  }

  const {planningSolution} = usePlanningSolutionData((store) => ({
    planningSolution: store.planningSolution,
  }));
  const planningMasterTripFormik = _.get(formik.values, `trips[${tripIndex}]`);
  const planningMasterTrip = _.find(
    // @ts-expect-error upgrade
    planningSolution.planningMasterTrips,
    ['trip.id', planningMasterTripFormik.id]
  );

  return (
    <div className={classes.columnContainer}>
      <div className={classes.cardContent}>
        <Card className={classes.container}>
          <CardHeader
            title="Planning Solution Trip Header Edit"
            avatar={
              <Avatar>
                <EditIcon />
              </Avatar>
            }
          />
          <CardContent className={classes.content}>
            <FormikTextField
              label="Trip Number"
              name={`trips[${tripIndex}].tripNumber`}
              placeholder="Enter the Trip Number"
            />
            <FormikAutocompleteHooks
              label="Vehicle"
              id={`trips[${tripIndex}].vehicle`}
              name={`trips[${tripIndex}].vehicle`}
              options={allocatedVehicleListing}
              placeholder="Enter and select a value"
              defaultValue={vehicle}
            />
            <FormikAutocompleteHooks
              label="Driver"
              id={`trips[${tripIndex}].driver`}
              name={`trips[${tripIndex}].driver`}
              options={allocatedDriverListing}
              placeholder="Enter and select a value"
              defaultValue={driver}
            />
          </CardContent>
        </Card>
      </div>
      {planningMasterTrip && (
        <div className={classes.cardContent}>
          <PlanningMasterTripMapCard
            planningMasterTrip={planningMasterTrip}
            planningMasterTripFormik={planningMasterTripFormik}
            planningSolution={planningSolution}
          />
        </div>
      )}
    </div>
  );
};

export const PlanningTripHeader = withRouter(PlanningTripHeaderComponent);
