import React from 'react';
import {
  ProductContext,
  ProductContextProps,
  ProductContextProvider,
} from './ProductContext';
import _ from 'lodash';
import {ProductList} from './ProductList';
import {ProductIdHandler} from './ProductIdHandler';
import {Product} from './Product';
import {
  ITemplateControllerRoute,
  TemplateController,
} from '../../factory/template';
import {ProductForm} from './ProductForm';

export const ProductControllerComponent: React.FC = () => {
  const {product, loading} =
    React.useContext<ProductContextProps>(ProductContext);
  const routes: ITemplateControllerRoute[] = React.useMemo(
    () => [
      {title: 'Product List', component: ProductList},
      {
        path: '/add',
        title: 'New Product',
        name: 'Add Product',
        type: 'Add',
        component: () => <ProductForm isAdd />,
      },
      {
        path: '/:productId',
        title: (params) =>
          loading
            ? 'Product Loading...'
            : product?.name ?? `Product${_.get(params, 'productId', 'Single')}`,
        component: Product,
        IdHandler: ProductIdHandler,
      },
    ],
    [loading, product?.name]
  );

  return <TemplateController rootPath="/productlist" routes={routes} />;
};

export const ProductController: React.FC = () => {
  return (
    <ProductContextProvider>
      <ProductControllerComponent />
    </ProductContextProvider>
  );
};
