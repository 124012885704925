import React from 'react';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {Button, TextField, Typography} from '@mui/material';
import {useForgotPasswordFormStyles} from './ForgotPasswordForm.style';
import {FormikHelpers, FormikProps} from 'formik/dist/types';
import {AuthenticationAcknowledge} from '../AuthenticationAcknowledge';
import {useInputStyles} from '../../formik/Input.style';

const schema = Yup.object({
  email: Yup.string().email('Enter a valid email').required('Required'),
});

export interface ForgotPasswordFormValues {
  email: string;
}

interface ForgotPasswordFormProps {
  formikRef?: React.Ref<FormikProps<ForgotPasswordFormValues>>;
  onSubmit: (
    values: ForgotPasswordFormValues,
    helpers: FormikHelpers<ForgotPasswordFormValues>
  ) => void;
}

export const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({
  onSubmit,
}) => {
  const classes = useForgotPasswordFormStyles();
  const classesTextField = useInputStyles();

  const formik = useFormik<ForgotPasswordFormValues>({
    initialValues: {email: ''},
    onSubmit,
    validateOnChange: true,
    validationSchema: schema,
  });

  return (
    <>
      <Typography className={classes.container} variant="h4">
        Forgot Password
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          className={`${classesTextField.input} ${
            formik.touched.email && !!formik.errors.email
              ? classesTextField.inputError
              : ''
          }`}
          disabled={formik.isSubmitting}
          fullWidth
          id="email"
          label="Email"
          name="email"
          placeholder="Enter a valid email address"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && !!formik.errors.email}
          helperText={formik.touched.email && formik.errors.email}
          onBlur={formik.handleBlur}
        />
        <Button
          className={classes.button}
          color="primary"
          disabled={formik.isSubmitting}
          name="submit"
          type="submit"
          variant="contained"
          // fullWidth
        >
          Submit
        </Button>
        <AuthenticationAcknowledge />
      </form>
    </>
  );
};
