import {DateTime} from 'luxon';
import {ReportGenerateParameterValue, Daterange} from './getDaterangeParameter';

export const getDaterange = (
  value?: ReportGenerateParameterValue
): {startDate: Date; endDate: Date} | null => {
  let range: Daterange | null = null;

  if (!value) {
    return null;
  }

  switch (value) {
    case 'today':
      range = {
        startDate: DateTime.now().startOf('day').toJSDate(),
        endDate: DateTime.now().endOf('day').toJSDate(),
      };
      break;
    case 'yesterday':
      range = {
        startDate: DateTime.now().minus({days: 1}).startOf('day').toJSDate(),
        endDate: DateTime.now().minus({days: 1}).endOf('day').toJSDate(),
      };
      break;
    case 'last_24_hours':
      range = {
        startDate: DateTime.now().minus({hours: 24}).toJSDate(),
        endDate: DateTime.now().toJSDate(),
      };
      break;
    case 'last_7_days':
      range = {
        startDate: DateTime.now().minus({days: 7}).startOf('day').toJSDate(),
        endDate: DateTime.now().minus({days: 1}).endOf('day').toJSDate(),
      };
      break;
    case 'last_7_days_include_today':
      range = {
        startDate: DateTime.now().minus({days: 7}).startOf('day').toJSDate(),
        endDate: DateTime.now().endOf('day').toJSDate(),
      };
      break;
    case 'week_to_date':
      range = {
        startDate: DateTime.now().startOf('week').toJSDate(),
        endDate: DateTime.now().minus({days: 1}).endOf('day').toJSDate(),
      };
      break;
    case 'week_to_date_include_today':
      range = {
        startDate: DateTime.now().startOf('week').toJSDate(),
        endDate: DateTime.now().endOf('day').toJSDate(),
      };
      break;
    case 'previous_week':
      range = {
        startDate: DateTime.now().startOf('week').minus({week: 1}).toJSDate(),
        endDate: DateTime.now().startOf('week').toJSDate(),
      };
      break;
    case 'month_to_date':
      range = {
        startDate: DateTime.now().startOf('month').toJSDate(),
        endDate: DateTime.now().minus({days: 1}).endOf('day').toJSDate(),
      };
      break;
    case 'month_to_date_include_today':
      range = {
        startDate: DateTime.now().startOf('month').toJSDate(),
        endDate: DateTime.now().endOf('day').toJSDate(),
      };
      break;
  }

  return range;
};
