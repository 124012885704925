import {action, computed, decorate, observable} from 'mobx';
import {branding} from '../components/dashboard/old/helpers';

class ColorDisplay {
  constructor() {
    this.colorScheme = branding.default;
  }

  get getScheme() {
    return this.colorScheme;
  }

  setScheme = (updatedScheme) => {
    // TODO do some validation
    if (updatedScheme) {
      this.colorScheme = updatedScheme;
    }
  };
}

export default decorate(ColorDisplay, {
  getScheme: computed,
  colorScheme: observable,
  setScheme: action,
});
