import React from 'react';
import {
  TemplateTable,
  TOnInlineAdd,
  TOnInlineEdit,
} from '../../../factory/template';
import {useContractOperationalEventTypeConfigContext} from './ContractOperationalEventTypeConfigContext';
import {ContractOperationalEventTypeEscalationConfig} from '@onroadvantage/onroadvantage-api';
import {operationalEventEscalationConfigApi} from '../../../api';
import {useAppNotifications} from '../../../contexts';
import * as Yup from 'yup';

const addValidation = Yup.object({
  type: Yup.string().required('Required'),
  threshold: Yup.string().required('Required'),
});

export const ContractOperationalEventTypeConfigEscalationList = () => {
  const notify = useAppNotifications();
  const {
    loading,
    contractOperationalEventTypeConfigId,
    contractOperationalEventTypeConfig,
    setContractOperationalEventTypeConfig,
  } = useContractOperationalEventTypeConfigContext();
  const [localSubmitting, setLocalSubmitting] = React.useState<boolean>(false);

  const onAddEscalation = React.useCallback<TOnInlineAdd>(
    async (changes) => {
      setLocalSubmitting(true);
      try {
        if (contractOperationalEventTypeConfigId != null) {
          for (const change of changes) {
            try {
              if ('type' in change && 'threshold' in change) {
                const response =
                  await operationalEventEscalationConfigApi.apiOperationalEventEscalationConfigPost(
                    {
                      body: {
                        contractOperationalEventTypeConfigId,
                        type: change.type,
                        threshold: change.threshold,
                      },
                    }
                  );
                setContractOperationalEventTypeConfig((prevState) =>
                  prevState
                    ? {
                        ...prevState,
                        operationalEventEscalationConfigs: [
                          ...(prevState.operationalEventEscalationConfigs ??
                            []),
                          {
                            ...response,
                          },
                        ],
                      }
                    : undefined
                );
              }
            } catch (e) {
              notify('error', `Failed to create escalation ${change.type}`);
            }
          }
        }
      } finally {
        setLocalSubmitting(false);
      }
    },
    [
      contractOperationalEventTypeConfigId,
      notify,
      setContractOperationalEventTypeConfig,
    ]
  );

  const onEditEscalation = React.useCallback<TOnInlineEdit>(
    async (changes) => {
      setLocalSubmitting(true);
      try {
        if (contractOperationalEventTypeConfigId != null) {
          for (const change of changes) {
            try {
              if (change.id) {
                const response =
                  await operationalEventEscalationConfigApi.apiOperationalEventEscalationConfigOperationalEventEscalationConfigIdPatch(
                    {
                      operationalEventEscalationConfigId: parseInt(change.id),
                      body: {
                        contractOperationalEventTypeConfigId,
                        type: change.newValues.type,
                        threshold: change.newValues.threshold,
                      },
                    }
                  );

                setContractOperationalEventTypeConfig((prevState) =>
                  prevState
                    ? {
                        ...prevState,
                        operationalEventEscalationConfigs:
                          prevState.operationalEventEscalationConfigs?.reduce(
                            (
                              acc: ContractOperationalEventTypeEscalationConfig[],
                              curr
                            ) => {
                              if (curr.id === response.id) {
                                return [
                                  ...acc,
                                  {
                                    ...response,
                                  },
                                ];
                              }
                              return [...acc, curr];
                            },
                            []
                          ),
                      }
                    : undefined
                );
              }
            } catch (e) {
              notify('error', `Failed to create escalation`);
            }
          }
        }
      } finally {
        setLocalSubmitting(false);
      }
    },
    [
      contractOperationalEventTypeConfigId,
      notify,
      setContractOperationalEventTypeConfig,
    ]
  );

  const onDelete = React.useCallback(
    async (row: ContractOperationalEventTypeEscalationConfig) => {
      setLocalSubmitting(true);
      try {
        if (row.id) {
          await operationalEventEscalationConfigApi.apiOperationalEventEscalationConfigOperationalEventEscalationConfigIdDelete(
            {
              operationalEventEscalationConfigId: row.id,
            }
          );

          setContractOperationalEventTypeConfig((prevState) =>
            prevState
              ? {
                  ...prevState,
                  operationalEventEscalationConfigs:
                    prevState.operationalEventEscalationConfigs?.filter(
                      ({id}) => id !== row.id
                    ),
                }
              : undefined
          );
        }
      } catch (e) {
        notify('error', 'Failed to delete escalation');
      } finally {
        setLocalSubmitting(false);
      }
    },
    [notify, setContractOperationalEventTypeConfig]
  );

  return (
    <TemplateTable<ContractOperationalEventTypeEscalationConfig>
      config={{
        columns: [
          {
            name: 'type',
            label: 'Type',
            type: 'string',
            enableEditing: true,
            enableSelect: true,
            selectOptions: ['Unassigned', 'Open'],
          },
          {
            name: 'threshold',
            label: 'Threshold',
            type: 'string',
            enableEditing: true,
          },
        ],
        disableToolbar: true,
        disablePagination: true,
        deleteMessage: (row) => ({
          title: 'Operational Event Type Escalation Config',
          items: {
            ContractOperationalEventTypeConfig: `${contractOperationalEventTypeConfig?.operationalEventType?.name} - ${contractOperationalEventTypeConfig?.contract?.code}`,
            Type: row.type,
            Threshold: row.threshold,
          },
        }),
        identifier: 'CONTRACT_OPERATIONAL_EVENT_TYPE_CONFIG_ESCALATION_LIST',
      }}
      addValidation={addValidation}
      currentPage={1}
      list={
        contractOperationalEventTypeConfig?.operationalEventEscalationConfigs ??
        []
      }
      onInlineEdit={onEditEscalation}
      onInlineAdd={onAddEscalation}
      onDelete={onDelete}
      loading={loading || localSubmitting}
    />
  );
};
