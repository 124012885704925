import React from 'react';

import {MomentService} from '../../../../service';
import PopupBase from './PopupBase';
import {Box, Typography} from '@mui/material';
import custImg from '../../../../img/background/popupbackground.png';
import truck from '../../../../img/svg/truck.svg';
import {createStyles, makeStyles} from '@mui/styles';

const useVehiclePopupStyles = makeStyles(() =>
  createStyles({
    headingColor: {
      color: '#767676',
    },
  })
);

interface Props {
  vehicle: any;
}

export default function VehiclePopup(props: Props) {
  const {vehicle} = props;
  const classes = useVehiclePopupStyles();

  return (
    <PopupBase
      backgroundImage={custImg}
      mainIcon={truck}
      topIconType="default"
      left={{
        header: vehicle.active ? 'Actual' : 'Planned',
      }}
      right={{header: 'Type', data: 'Vehicle', type: 'text'}}
    >
      <Box mb={1}>
        <Typography variant="h5" className={classes.headingColor}>
          Fleet Number
        </Typography>
        <Typography variant="h6" style={{fontWeight: 700}}>
          {vehicle.description}
        </Typography>
      </Box>

      <Box mb={1}>
        <Typography variant="h5" className={classes.headingColor}>
          Registration Number:
        </Typography>
        <Typography variant="h6">{vehicle.registrationNumber}</Typography>
      </Box>
      <Box mb={1}>
        <Typography variant="h5" className={classes.headingColor}>
          Last Seen
        </Typography>
        <Typography variant="h6">
          {vehicle.lastSeen
            ? MomentService.displayTimestamp(vehicle.lastSeen.eventDate)
            : 'No Last Seen Data'}
        </Typography>
      </Box>
      <Box mb={1}>
        <Typography variant="h5" className={classes.headingColor}>
          Contract
        </Typography>
        <Typography variant="h6">
          {vehicle.contract ? vehicle.contract.code : 'No Contract Data'}
        </Typography>
      </Box>
    </PopupBase>
  );
}
