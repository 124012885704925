import React from 'react';
import {RoleContext} from './RoleContext';
import {useParams} from 'react-router-dom';

interface IParams {
  roleId?: string;
}

export const RoleIdHandler: React.FC = () => {
  const {setRoleId} = React.useContext(RoleContext);

  const {roleId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = roleId ? parseInt(roleId) : undefined;
    setRoleId(parsedId);
    return () => setRoleId(undefined);
  }, [roleId, setRoleId]);

  return null;
};
