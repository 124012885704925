import ConfigService from './ConfigService/ConfigService';
import {encodeUrl, flatten} from './Util';
import ResponseService from './ResponseService';
import {authStore} from '../store';

const serverUrl = ConfigService.serverUri;
const queryUrl = `${serverUrl}/api/order`;

const OrderService = {
  getByUuid: async (uuid) => {
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    const response = await fetch(`${queryUrl}/public/${uuid}`, {
      method: 'GET',
      mode: 'cors',
      headers,
    });

    return OrderService.normalize(await ResponseService.checkStatus(response));
  },
  query: async (params) => {
    Object.keys(params).forEach(
      // eslint-disable-next-line no-param-reassign
      (key) => params[key] == null && delete params[key]
    );
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(encodeUrl(queryUrl, params), {
      method: 'GET',
      mode: 'cors',
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  update: async (order) => {
    // eslint-disable-next-line no-param-reassign
    if (!order.id) {
      return OrderService.create(order);
    }
    Object.keys(order).forEach(
      // eslint-disable-next-line no-param-reassign
      (key) => order[key] == null && delete order[key]
    );
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(`${queryUrl}/${order.id}`, {
      method: 'PATCH',
      mode: 'cors',
      body: JSON.stringify(order),
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  create: async (order) => {
    Object.keys(order).forEach(
      // eslint-disable-next-line no-param-reassign
      (key) => order[key] == null && delete order[key]
    );
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(`${queryUrl}`, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({...order}),
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  normalize: async (response) => {
    const result = flatten(await response.data);

    return result;
  },
  getById: async (orderId) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(`${queryUrl}/${orderId}`, {
      method: 'GET',
      mode: 'cors',
      headers,
    });
    return ResponseService.checkStatus(response);
  },
};

export default OrderService;
