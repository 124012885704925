import {makeStyles, createStyles} from '@mui/styles';

const VantageDialogButtonStyles = () =>
  createStyles({
    acceptButton: {
      size: 13,
    },
    actions: {
      display: 'flex',
      justifyContent: 'space-around',
    },
    declineButton: {opacity: 0.87},
    dialog: {overflow: 'hidden'},
    title: {
      '& h2': {
        fontWeight: 'normal',
        opacity: 0.87,
        size: 16,
      },
    },
  });

export const useVantageDialogButtonStyles = makeStyles(
  VantageDialogButtonStyles,
  {name: 'VantageDialogButtonStyles'}
);
