import React from 'react';
import {PlanningBoardPlan} from '../planningBoardPlan';
import {usePlanningBoardContext} from '../planningBoardContext';

export const PlanningBoardBottomPanel: React.FC = () => {
  const {activeToolbarTab} = usePlanningBoardContext();

  if (activeToolbarTab === 0) {
    return <PlanningBoardPlan />;
  }

  return null;
};
