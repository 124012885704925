import AbortController from 'abort-controller';
import moment from 'moment';
import _ from 'lodash';
import React from 'react';
import MomentService from './MomentService';
import {StorageService} from './index';

const encodeUrl = (url, params) => {
  const esc = encodeURIComponent;
  const query = Object.keys(params)
    .map((k) => `${esc(k)}=${esc(params[k])}`)
    .join('&');
  return `${url}?${query}`;
};

const merge = (objects) => {
  const out = {};
  // eslint-disable-next-line guard-for-in, no-restricted-syntax
  for (let i = 0; i < objects.length; i += 1) {
    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const p in objects[i]) {
      out[p] = objects[i][p];
    }
  }

  return out;
};

const checkZero = (obviouslyAFuckingNumber) => {
  if (obviouslyAFuckingNumber != null) {
    return obviouslyAFuckingNumber;
  }
  return '';
};

const flatten = (obj, name, stem) => {
  let out = {};
  const newStem =
    typeof stem !== 'undefined' && stem !== '' ? `${stem}_${name}` : name;
  if (typeof obj !== 'object') {
    out[newStem] = obj;
    return out;
  }
  if (Array.isArray(obj)) {
    const arr = {};
    arr[newStem] = obj.map((e) => flatten(e));
    out = merge([out, arr]);
  } else {
    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const p in obj) {
      const prop = flatten(obj[p], p, newStem);
      out = merge([out, prop]);
    }
  }
  return out;
};

const unflatten = (flatObj) => {
  // TODO make for deeper than 1 layer
  const out = {};
  Object.keys(flatObj).forEach((item) => {
    if (item.indexOf('_') < 0) {
      out[item] = flatObj[item];
    } else {
      const base = item.substr(0, item.indexOf('_'));
      const remainder = item.replace(`${base}_`, '');
      if (base in out) {
        out[base][remainder] = flatObj[item];
      } else {
        out[base] = {};
        out[base][remainder] = flatObj[item];
      }
    }
  });
  return out;
};

/* eslint-disable */
const generateUid = function () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const randomIntFromInterval = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const getVantageRgb = () => {
  // R: 0 G: 130 B: 112
  const r = 0;
  const g = randomIntFromInterval(125, 170);
  const b = 112;
  return 'rgb(' + r + ',' + g + ',' + b + ')';
};

const uploadXHR = async (file, config, checkStatus) => {
  const body = new FormData();
  for (const key in config.fields) {
    body.append(key, config.fields[key]);
  }
  body.append('file', file);
  const xhr = new XMLHttpRequest();
  xhr.open('POST', config.url, true);
  xhr.onreadystatechange = () => checkStatus(xhr);
  await xhr.send(body);
};
/* eslint-enable */

const getUniqueSetNearbyNodes = async (nodes) => {
  const result = [];
  const map = new Map();
  nodes.forEach((item) => {
    if (!map.has(item.id)) {
      map.set(item.id, true); // set any value to Map
      result.push({
        ...item,
      });
    }
  });
  return result;
};

const getNearbyNodes = async (criticalEvents, telematicsEvents) => {
  let nearbyNodes = [];

  if (criticalEvents && criticalEvents.nearbyNodes) {
    nearbyNodes = [...criticalEvents.nearbyNodes];
  }
  if (telematicsEvents && telematicsEvents.nearbyNodes) {
    nearbyNodes = [...nearbyNodes, ...telematicsEvents.nearbyNodes];
  }

  return getUniqueSetNearbyNodes(nearbyNodes);
};

const getLastTelematicsEvent = (telematicsEvents) => {
  if (
    telematicsEvents &&
    telematicsEvents.coordTimes &&
    telematicsEvents.coordTimes.length > 0
  ) {
    const timestamp = telematicsEvents.coordTimes.slice(-1)[0];
    return MomentService.toDateTime(moment(timestamp * 1000));
  }
  return null;
};

const getFirstTelematicsEvent = (telematicsEvents) => {
  if (
    telematicsEvents &&
    telematicsEvents.coordTimes &&
    telematicsEvents.coordTimes.length > 0
  ) {
    const timestamp = telematicsEvents.coordTimes.slice(0)[0];
    return MomentService.toDateTime(moment(timestamp * 1000));
  }
  return null;
};

const keyValuePairToObjectConversion = (item) => {
  const response = {};
  if ('row' in item) {
    item.row.forEach((el) => {
      // TODO use the python field types here
      if (el.type === 'timedelta') {
        response[el.name] = el;
      } else {
        response[el.name] = el.value;
      }
    });
    return response;
  }
  return item;
};

const getOption = (obj, value, label) => {
  const l = _.get(obj, label);
  const v = _.get(obj, value);
  if (!l || !v) {
    return null;
  }

  return {
    value: v,
    label: l,
  };
};

const audioIsPlaying = (audio) => {
  return (
    audio &&
    audio.currentTime > 0 &&
    !audio.paused &&
    !audio.ended &&
    audio.readyState > 2
  );
};

const getSortingFromString = (sortString) => {
  // split first
  return sortString.split(',').map((s) => {
    let direction = 'asc';
    let columnName;
    if (s[0] === '-') {
      direction = 'desc';
      columnName = s.substring(1);
    } else {
      columnName = s;
    }

    return {columnName, direction};
  });
};

const getStringFromSorting = (sorting) => {
  if (!sorting) {
    return null;
  }

  let sortString = '';
  sorting.forEach((si, index) => {
    if (index !== 0) {
      sortString += ',';
    }
    sortString += `${si.direction === 'desc' ? '-' : ''}${si.columnName}`;
  });

  return sortString;
};

const mergeDateAndTime = (params) => {
  let {date, time} = params;
  const newDate = new Date();
  if (!date) {
    date = new Date();
  }
  if (!time) {
    time = new Date();
  }
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const hour = time.getHours();
  const minute = time.getMinutes(month);
  const second = time.getSeconds(day);
  newDate.setFullYear(year, month, day);
  newDate.setHours(hour, minute, second);
  return newDate;
};

// https://stackoverflow.com/a/57888548
const fetchTimeout = (url, ms, {signal, ...options} = {}) => {
  const controller = new AbortController();
  const promise = fetch(url, {signal: controller.signal, ...options});
  if (signal) signal.addEventListener('abort', () => controller.abort());
  const timeout = setTimeout(() => controller.abort(), ms);
  return promise.finally(() => clearTimeout(timeout));
};

const getTripTAT = (tripStart, tripEnd) => {
  if (tripStart && tripEnd) {
    const response = Math.abs(new Date(tripEnd) - new Date(tripStart));
    return Math.floor(response / 1000 / 60);
  }
  return 0;
};

const getRainbowColor = (numOfSteps, step) => {
  // This function generates vibrant, "evenly spaced" colours (i.e. no clustering). This is ideal for creating easily distinguishable vibrant markers in Google Maps and other apps.
  // Adam Cole, 2011-Sept-14
  // HSV to RBG adapted from: http://mjijackson.com/2008/02/rgb-to-hsl-and-rgb-to-hsv-color-model-conversion-algorithms-in-javascript
  let r;
  let g;
  let b;
  const h = step / numOfSteps;
  const i = ~~(h * 6);
  const f = h * 6 - i;
  const q = 1 - f;
  switch (i % 6) {
    case 0:
      r = 1;
      g = f;
      b = 0;
      break;
    case 1:
      r = q;
      g = 1;
      b = 0;
      break;
    case 2:
      r = 0;
      g = 1;
      b = f;
      break;
    case 3:
      r = 0;
      g = q;
      b = 1;
      break;
    case 4:
      r = f;
      g = 0;
      b = 1;
      break;
    case 5:
      r = 1;
      g = 0;
      b = q;
      break;
    default:
      break;
  }
  const c = `#${`00${(~~(r * 255)).toString(16)}`.slice(-2)}${`00${(~~(
    g * 255
  )).toString(16)}`.slice(-2)}${`00${(~~(b * 255)).toString(16)}`.slice(-2)}`;
  return c;
};

const getImgTags = async ({value, type}) => {
  if (type === 'Photo' || type === 'Document') {
    try {
      const imageData = await JSON.parse(value);
      const imgDivs = imageData.map(async (image) => {
        const {key} = image.fields;
        const response = await StorageService.getUrl({
          key,
          type: 'Encrypted Image',
          method: 'GET',
        });
        const url = await response.data;
        return (
          <img
            key={url.url}
            src={url.url}
            alt={url.url}
            style={{width: window.innerWidth * 0.8}}
          />
        );
      });
      return await Promise.all(imgDivs);
    } catch (err) {
      return [<div key={'error'}>Could not load images</div>];
    }
  } else if (type === 'Signature') {
    return [
      <img
        key={value}
        src={`data:image/png;base64, ${value}`}
        alt="Signature"
        style={{width: window.innerWidth * 0.4}}
      />,
    ];
  } else {
    return [<div key={'error'}>Could not load images</div>];
  }
};

const setDashboardPreference = (value) => {
  const vantageDashboardUserPreferences = localStorage.getItem(
    'VantageDashboardUserPreferences'
  );
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const reportId = getQueryStringValue('reportId');
  let prefs = {reports: {}};
  // @ts-expect-error upgrade
  prefs.reports[reportId] = value;
  if (vantageDashboardUserPreferences) {
    try {
      prefs = JSON.parse(vantageDashboardUserPreferences);
      if (prefs && prefs.reports) {
        // @ts-expect-error upgrade
        prefs.reports[reportId] = value;
      }
    } catch (e) {
      // pass
    }
  }
  localStorage.setItem(
    'VantageDashboardUserPreferences',
    JSON.stringify(prefs)
  );
};

const getDashboardPreference = () => {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const reportId = getQueryStringValue('reportId');
  const dashboardPreferences = localStorage.getItem(
    'VantageDashboardUserPreferences'
  );
  try {
    const prefs = JSON.parse(dashboardPreferences);
    if (prefs && prefs.reports && prefs.reports[reportId]) {
      if (prefs.reports[reportId] < 300) {
        // less than 5 minute refresh interval is dangerous
        return 0;
      }
      return prefs.reports[reportId];
    }
  } catch (e) {
    // pass
  }
  return 0;
};

const getQueryStringValue = (searchString, key) => {
  return decodeURIComponent(
    searchString.replace(
      new RegExp(
        `^(?:.*[&\\?]${
          // eslint-disable-line no-useless-escape
          encodeURIComponent(key).replace(/[\.\+\*]/g, '\\$&') // eslint-disable-line no-useless-escape
        }(?:\\=([^&]*))?)?.*$`, // eslint-disable-line no-useless-escape
        'i'
      ),
      '$1'
    )
  );
};

const parseReadableStreamToJson = async (error) => {
  const data = (await error.getReader().read()).value;
  const str = String.fromCharCode.apply(String, data);
  return JSON.parse(str);
};

export {
  // eslint-disable-next-line import/prefer-default-export
  getQueryStringValue,
  encodeUrl,
  flatten,
  unflatten,
  checkZero,
  generateUid,
  uploadXHR,
  getNearbyNodes,
  getUniqueSetNearbyNodes,
  keyValuePairToObjectConversion,
  getVantageRgb,
  randomIntFromInterval,
  getLastTelematicsEvent,
  getFirstTelematicsEvent,
  getOption,
  audioIsPlaying,
  getSortingFromString,
  getStringFromSorting,
  mergeDateAndTime,
  fetchTimeout,
  getRainbowColor,
  getTripTAT,
  getImgTags,
  setDashboardPreference,
  getDashboardPreference,
  parseReadableStreamToJson,
};
