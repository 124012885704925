import React from 'react';
import {ProductContext, ProductContextProps} from './ProductContext';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../factory/template';
import {Product} from '@onroadvantage/onroadvantage-api';

export const ProductList: React.FC = () => {
  const {loadList, cleanupList} =
    React.useContext<ProductContextProps>(ProductContext);

  const [config] = React.useState<TemplateTableConfig<Product>>({
    columns: [
      {name: 'name', label: 'Product Name', type: 'string'},
      {name: 'description', label: 'Description', type: 'string'},
      {name: 'uom', label: 'Unit of Measure', type: 'string'},
      {
        name: 'compartmentFillingPreferenceUom',
        label: 'Compartment Filling Preference UOM',
        type: 'string',
      },
      {
        name: 'orderLineQuantityPreferenceUom',
        label: 'Order Line Quantity Preference UOM',
        type: 'string',
      },
      {
        name: 'variabilityThreshold',
        label: 'Variability Threshold (%)',
        type: 'number',
      },
    ],
    enableFilter: true,
    enableSort: true,
    deleteMessage: (row) => ({
      title: 'Product',
      items: {
        Name: row.name,
        Description: row.description,
        Uom: row.uom,
        VariabilityThreshold: row.variabilityThreshold,
      },
    }),
    identifier: 'PRODUCT_LIST',
  });

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [cleanupList, loadList]);

  return <TemplateTableWrapper config={config} context={ProductContext} />;
};
