import React from 'react';
import {
  ContractCriticalEventTypeConfig,
  ContractCriticalEventTypeReasonCode,
} from '@onroadvantage/onroadvantage-api';
import {contractCriticalEventTypeConfigApi} from '../../../api';
import {
  TOnFormikSubmit,
  TOnInlineAdd,
  TOnInlineEdit,
} from '../../../factory/template';
import {ContractCriticalEventTypeConfigDetailsFormSchema} from './ContractCriticalEventTypeConfigDetailsForm';
import {useAppNotifications} from '../../../contexts';
import {useHistory} from 'react-router-dom';

export interface ContractCriticalEventTypeConfigContextProps {
  loading: boolean;
  submitting: boolean;
  // onCreate: () => void;
  contractCriticalEventTypeConfigId: number | undefined;
  setContractCriticalEventTypeConfig: React.Dispatch<
    React.SetStateAction<ContractCriticalEventTypeConfig | undefined>
  >;
  setContractCriticalEventTypeConfigId: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  contractCriticalEventTypeConfig: ContractCriticalEventTypeConfig | undefined;
  onDetailsSubmit: TOnFormikSubmit<ContractCriticalEventTypeConfigDetailsFormSchema>;
  onAddReasonCodes: TOnInlineAdd;
  onEditReasonCodes: TOnInlineEdit;
  onDeleteReasonCode: (
    row: ContractCriticalEventTypeReasonCode
  ) => Promise<void>;

  getNotificationConfig: (
    eventNotificationConfigId: number | undefined
  ) => string | undefined;
  // loadContractCriticalEventTypeConfig: () => Promise<void>;
}

export const ContractCriticalEventTypeConfigContext =
  React.createContext<ContractCriticalEventTypeConfigContextProps | null>(null);

export const useContractCriticalEventTypeConfigContext = () => {
  const contractCriticalEventTypeConfigContext = React.useContext(
    ContractCriticalEventTypeConfigContext
  );
  if (contractCriticalEventTypeConfigContext == null) {
    throw new Error(
      'useContractCriticalEventTypeConfigContext has to be used within <ContractCriticalEventTypeConfigContext.Provider>'
    );
  }

  return contractCriticalEventTypeConfigContext;
};

export const ContractCriticalEventTypeConfigContextProvider: React.FC = ({
  children,
}) => {
  const notify = useAppNotifications();
  const history = useHistory();
  const [contractCriticalEventTypeConfig, setContractCriticalEventTypeConfig] =
    React.useState<ContractCriticalEventTypeConfig | undefined>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [
    contractCriticalEventTypeConfigId,
    setContractCriticalEventTypeConfigId,
  ] = React.useState<number | undefined>();

  const handleLoadContractCriticalEventTypeConfig =
    React.useCallback(async () => {
      setLoading(true);
      try {
        if (contractCriticalEventTypeConfigId != null) {
          const response =
            await contractCriticalEventTypeConfigApi.apiContractCriticalEventTypeConfigContractCriticalEventTypeConfigIdGet(
              {contractCriticalEventTypeConfigId}
            );
          if (response != null) {
            setContractCriticalEventTypeConfig(response);
          }
        }
      } catch (e) {
        notify('error', 'Failed to load contract critical event type config');
      } finally {
        setLoading(false);
      }
    }, [contractCriticalEventTypeConfigId, notify]);

  const handleOnDetailsSubmit = React.useCallback<
    TOnFormikSubmit<ContractCriticalEventTypeConfigDetailsFormSchema>
  >(
    async (values, formikHelpers) => {
      setSubmitting(true);
      formikHelpers.setSubmitting(true);
      try {
        if (contractCriticalEventTypeConfigId != null) {
          const response =
            await contractCriticalEventTypeConfigApi.apiContractCriticalEventTypeConfigContractCriticalEventTypeConfigIdPatch(
              {
                contractCriticalEventTypeConfigId,
                body: {
                  contractId: values.contract.value ?? undefined,
                  criticalEventTypeId:
                    values.criticalEventType.value ?? undefined,
                  allowEventMuting: values.allowEventMuting,
                },
              }
            );
          notify('success', 'Updated contract critical event type config');
          setContractCriticalEventTypeConfig(response);
        } else {
          if (values.contract.value == null) {
            notify('warning', 'No contract specified');
            return;
          }
          if (values.criticalEventType.value == null) {
            notify('warning', 'No critical event type specified');
            return;
          }

          const response =
            await contractCriticalEventTypeConfigApi.apiContractCriticalEventTypeConfigPost(
              {
                body: {
                  contractId: values.contract.value,
                  criticalEventTypeId: values.criticalEventType.value,
                  allowEventMuting: values.allowEventMuting,
                },
              }
            );
          notify('success', 'Updated contract critical event type config');
          setContractCriticalEventTypeConfigId(response.id);
          setContractCriticalEventTypeConfig(response);
          history.push(`/contracteventtypeconfigs/critical/${response.id}`);
        }
      } catch (e) {
        notify('error', 'Failed to update contract critical event type config');
      } finally {
        setSubmitting(false);
        formikHelpers.setSubmitting(false);
      }
    },
    [contractCriticalEventTypeConfigId, history, notify]
  );

  const handleOnAddReasonCodes = React.useCallback<TOnInlineAdd>(
    async (changes) => {
      setSubmitting(true);
      let response: ContractCriticalEventTypeConfig | null = null;
      try {
        if (contractCriticalEventTypeConfigId) {
          for (const change of changes) {
            try {
              if (
                'name' in change &&
                change.name != null &&
                change.name !== ''
              ) {
                response =
                  await contractCriticalEventTypeConfigApi.apiContractCriticalEventTypeConfigContractCriticalEventTypeConfigIdReasonCodePost(
                    {
                      contractCriticalEventTypeConfigId,
                      body: {
                        name: change.name,
                        description: change.description,
                      },
                    }
                  );
              }
            } catch (e) {
              notify('error', `Failed to add reason code ${change.name ?? ''}`);
            }
          }
        }
      } finally {
        if (response != null) {
          setContractCriticalEventTypeConfig(response);
        }
        setSubmitting(false);
      }
    },
    [contractCriticalEventTypeConfigId, notify]
  );

  const handleOnEditReasonCodes = React.useCallback<TOnInlineEdit>(
    async (changes) => {
      setSubmitting(true);
      const updatedReasonCodes: ContractCriticalEventTypeReasonCode[] = [];
      try {
        if (contractCriticalEventTypeConfigId) {
          for (const change of changes) {
            try {
              if (change.id) {
                const response =
                  await contractCriticalEventTypeConfigApi.apiContractCriticalEventTypeConfigContractCriticalEventTypeConfigIdReasonCodeReasonCodeIdPatch(
                    {
                      contractCriticalEventTypeConfigId,
                      reasonCodeId: parseInt(change.id),
                      body: {
                        name: change.newValues.name,
                        description: change.newValues.description,
                      },
                    }
                  );
                updatedReasonCodes.push(response);
              }
            } catch (e) {
              notify(
                'error',
                `Failed to update reason code ${change.newValues.name ?? ''}`
              );
            }
          }
        }
      } finally {
        if (updatedReasonCodes.length > 0) {
          setContractCriticalEventTypeConfig((prevState) =>
            prevState
              ? {
                  ...prevState,
                  reasonCodes: prevState?.reasonCodes?.map((reasonCode) => {
                    const updatedReasonCode = updatedReasonCodes.find(
                      ({id}) => id === reasonCode.id
                    );
                    if (updatedReasonCode) {
                      return updatedReasonCode;
                    }
                    return reasonCode;
                  }),
                }
              : undefined
          );
        }
        setSubmitting(false);
      }
    },
    [contractCriticalEventTypeConfigId, notify]
  );

  const handleOnDeleteReasonCode = React.useCallback(
    async (row: ContractCriticalEventTypeReasonCode) => {
      setSubmitting(true);
      try {
        if (contractCriticalEventTypeConfigId && row.id) {
          const response =
            await contractCriticalEventTypeConfigApi.apiContractCriticalEventTypeConfigContractCriticalEventTypeConfigIdReasonCodeReasonCodeIdDelete(
              {
                contractCriticalEventTypeConfigId,
                reasonCodeId: row.id,
              }
            );
          if (response) {
            setContractCriticalEventTypeConfig((prevState) =>
              prevState
                ? {
                    ...prevState,
                    reasonCodes: prevState.reasonCodes?.filter(
                      ({id}) => id !== row.id
                    ),
                  }
                : undefined
            );
            notify('success', 'Deleted reason code');
          }
        }
      } catch (e) {
        notify('error', 'Failed to delete reason code');
      } finally {
        setSubmitting(false);
      }
    },
    [contractCriticalEventTypeConfigId, notify]
  );

  const handleGetNotificationConfig = React.useCallback(
    (eventNotificationConfigId: number | undefined) => {
      return contractCriticalEventTypeConfig?.eventNotificationConfigs?.find(
        (eventNotificationConfig) =>
          eventNotificationConfig.id === eventNotificationConfigId
      )?.notificationConfig?.name;
    },
    [contractCriticalEventTypeConfig?.eventNotificationConfigs]
  );

  React.useEffect(() => {
    handleLoadContractCriticalEventTypeConfig();
    return () => {
      setContractCriticalEventTypeConfig(undefined);
    };
  }, [
    handleLoadContractCriticalEventTypeConfig,
    setContractCriticalEventTypeConfig,
  ]);

  return (
    <ContractCriticalEventTypeConfigContext.Provider
      value={{
        loading,
        submitting,
        contractCriticalEventTypeConfig,
        setContractCriticalEventTypeConfig,
        contractCriticalEventTypeConfigId,
        setContractCriticalEventTypeConfigId,
        onDetailsSubmit: handleOnDetailsSubmit,
        onAddReasonCodes: handleOnAddReasonCodes,
        onEditReasonCodes: handleOnEditReasonCodes,
        onDeleteReasonCode: handleOnDeleteReasonCode,
        getNotificationConfig: handleGetNotificationConfig,
      }}
    >
      {children}
    </ContractCriticalEventTypeConfigContext.Provider>
  );
};
