import React from 'react';
import {useHistory} from 'react-router-dom';
import {MasterTripDebriefConfig} from '@onroadvantage/onroadvantage-api';
import {FormikHelpers, FormikProps} from 'formik/dist/types';
import {useAppNotifications} from '../../../contexts';
import {ITripDebriefConfigForm} from '../TripDebriefConfigForm';
import {masterTripDebriefConfigApi} from '../../../api';
import {TOnFormikSubmit} from '../../../factory/template';
import {ITripDebriefConfigVisibleCards} from '../TripDebriefConfigVisibleCards';
import {ITripDebriefConfigRequiredFields} from '../TripDebriefConfigRequiredFields';

export const useTripDebriefConfig = () => {
  const history = useHistory();
  const notify = useAppNotifications();
  const [masterTripDebriefConfig, setMasterTripDebriefConfig] = React.useState<
    MasterTripDebriefConfig | undefined
  >();
  const [masterTripDebriefConfigId, setMasterTripDebriefConfigId] =
    React.useState<number | undefined>();
  const [loadingTripDebriefConfig, setLoadingTripDebriefConfig] =
    React.useState<boolean>(false);
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const detailsRef = React.useRef<FormikProps<ITripDebriefConfigForm>>(null);

  const loadTripDebriefConfig = React.useCallback(async () => {
    setLoadingTripDebriefConfig(true);
    try {
      if (masterTripDebriefConfigId) {
        const response =
          await masterTripDebriefConfigApi.apiMasterTripDebriefConfigMasterTripDebriefConfigIdGet(
            {
              masterTripDebriefConfigId,
            }
          );
        setMasterTripDebriefConfig(response);
      } else {
        setMasterTripDebriefConfig(undefined);
      }
    } catch (e) {
      notify('error', 'Failed to load trip debrief config list');
    } finally {
      setLoadingTripDebriefConfig(false);
    }
  }, [notify, masterTripDebriefConfigId]);

  const handleDetailsFormSubmit = React.useCallback(
    async (
      values: ITripDebriefConfigForm,
      formikHelpers: FormikHelpers<ITripDebriefConfigForm>
    ) => {
      setSubmitting(true);
      try {
        const {contracts, ...otherValues} = values;

        const contractIds = contracts
          .filter(({value}) => !!value)
          .map(({value}) => value as number);

        if (masterTripDebriefConfigId && contractIds.length > 0) {
          await masterTripDebriefConfigApi.apiMasterTripDebriefConfigMasterTripDebriefConfigIdPatch(
            {
              masterTripDebriefConfigId,
              body: {...otherValues, contractIds},
            }
          );
        } else if (contractIds.length > 0) {
          await masterTripDebriefConfigApi.apiMasterTripDebriefConfigPost({
            body: {...otherValues, contractIds},
          });
        }
        history.push('/tripdebriefconfiglist');
        notify(
          'success',
          `${
            masterTripDebriefConfigId ? 'Updated' : 'Added'
          } trip debrief config`
        );
      } catch (e) {
        if (e.status === 422) {
          notify(
            'warning',
            'One of the contracts exits on another trip debrief config.'
          );
        } else {
          notify(
            'error',
            `Failed to ${
              masterTripDebriefConfigId ? 'update' : 'add'
            } trip debrief config`
          );
        }
      } finally {
        formikHelpers.setSubmitting(false);
        setSubmitting(false);
      }
    },
    [masterTripDebriefConfigId, history, notify]
  );

  const handleSubmitVisibleCards = React.useCallback<
    TOnFormikSubmit<ITripDebriefConfigVisibleCards>
  >(
    async (values, formikHelpers) => {
      setSubmitting(true);
      try {
        if (masterTripDebriefConfigId) {
          const response =
            await masterTripDebriefConfigApi.apiMasterTripDebriefConfigMasterTripDebriefConfigIdVisibleCardsPatch(
              {
                masterTripDebriefConfigId,
                body: values,
              }
            );
          if (response.masterTripDebriefConfigId) {
            setMasterTripDebriefConfig((prevState) =>
              prevState ? {...prevState, visibleCards: response} : undefined
            );
            notify('success', 'Updated cards visibility');
          } else {
            notify('error', 'Failed to update cards visibility');
          }
        }
      } catch (e) {
        notify('error', 'Failed to update cards visibility');
      } finally {
        formikHelpers.setSubmitting(false);
        formikHelpers.resetForm({values});
        setSubmitting(false);
      }
    },
    [masterTripDebriefConfigId, notify]
  );

  const handleSubmitRequiredFields = React.useCallback<
    TOnFormikSubmit<ITripDebriefConfigRequiredFields>
  >(
    async (values, formikHelpers) => {
      setSubmitting(true);
      try {
        if (masterTripDebriefConfigId) {
          const response =
            await masterTripDebriefConfigApi.apiMasterTripDebriefConfigMasterTripDebriefConfigIdRequiredFieldsPatch(
              {
                masterTripDebriefConfigId,
                body: values,
              }
            );
          if (response.masterTripDebriefConfigId) {
            setMasterTripDebriefConfig((prevState) =>
              prevState ? {...prevState, requiredFields: response} : undefined
            );
            notify('success', 'Updated required fields');
          } else {
            notify('error', 'Failed to update required fields');
          }
        }
      } catch (e) {
        notify('error', 'Failed to update required fields');
      } finally {
        formikHelpers.setSubmitting(false);
        formikHelpers.resetForm({values});
        setSubmitting(false);
      }
    },
    [masterTripDebriefConfigId, notify]
  );

  return {
    detailsRef,
    loadTripDebriefConfig,
    loadingTripDebriefConfig,
    onDetailsFormSubmit: handleDetailsFormSubmit,
    onSubmitRequiredFields: handleSubmitRequiredFields,
    onSubmitVisibleCards: handleSubmitVisibleCards,
    setMasterTripDebriefConfig,
    setMasterTripDebriefConfigId,
    masterTripDebriefConfig,
    masterTripDebriefConfigId,
    submitting,
  };
};

export type useTripDebriefConfigResponse = ReturnType<
  typeof useTripDebriefConfig
>;

export const useTripDebriefConfigResponseInitial: useTripDebriefConfigResponse =
  {
    detailsRef: {current: null},
    loadTripDebriefConfig: async () => {},
    loadingTripDebriefConfig: false,
    onDetailsFormSubmit: async () => {},
    onSubmitRequiredFields: async () => {},
    onSubmitVisibleCards: async () => {},
    setMasterTripDebriefConfig: () => {},
    setMasterTripDebriefConfigId: () => {},
    masterTripDebriefConfig: undefined,
    masterTripDebriefConfigId: undefined,
    submitting: false,
  };
