import React from 'react';
import {PlanningBoardOptionsDrawer} from '../planningBoardOptions/PlanningBoardOptionsDrawer';
import {
  ArrowBackIos,
  ArrowForwardIos,
  Fullscreen,
  FullscreenExit,
  LocalShipping,
  SwapVert,
} from '@mui/icons-material';
import {
  Collapse,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import {usePlanningBoardToolbarStyles} from './PlanningBoardToolbar.style';
import {PlanningBoardToolbarLegend} from './PlanningBoardToolbarLegend';
import {Loader} from '../../loader';
import {
  usePlanningBoardContext,
  usePlanningBoardGanttContext,
  usePlanningBoardSettingsContext,
} from '../planningBoardContext';
import {PlanningBoardToolbarReload} from './PlanningBoardToolbarReload';
import {PlanningBoardToolbarSearch} from './planningBoardToolbarSearch';

export interface PlanningBoardToolbarActionsProps {
  fullscreen?: boolean;
}

export const PlanningBoardToolbarActions: React.FC<
  PlanningBoardToolbarActionsProps
> = ({fullscreen}) => {
  const classes = usePlanningBoardToolbarStyles();
  const {activeToolbarTab, vehicles, loadingVehicles} =
    usePlanningBoardContext();
  const {setSortVehiclesDirection, onClearSearchedItems} =
    usePlanningBoardGanttContext();
  const {
    enableCheckTripActuals,
    setEnableCheckTripActuals,
    toggleEnableGanttFullscreen,
  } = usePlanningBoardSettingsContext();
  const [collapsed, setCollapsed] = React.useState<boolean>(false);

  const handleToggleCollapse = () => {
    setCollapsed((prevCollapsed) => !prevCollapsed);
  };

  const handleToggleVehicleSorting = React.useCallback(() => {
    setSortVehiclesDirection((prevDirection) =>
      prevDirection === 'asc' ? 'desc' : 'asc'
    );
  }, [setSortVehiclesDirection]);

  const handleToggleEnableCheckTripActuals = React.useCallback(() => {
    onClearSearchedItems();
    setEnableCheckTripActuals(
      (prevEnableCheckTripActuals) => !prevEnableCheckTripActuals
    );
  }, [onClearSearchedItems, setEnableCheckTripActuals]);

  return (
    <div className={classes.toolbarActions}>
      <PlanningBoardToolbarSearch />

      <Collapse orientation="horizontal" in={!collapsed}>
        <Stack direction="row" alignItems="center">
          <Divider
            className={classes.toolbarDivider}
            orientation="vertical"
            flexItem
          />
          <Stack direction="row" alignItems="center">
            {loadingVehicles ? (
              <Loader size={24} />
            ) : (
              <>
                <Typography
                  variant="subtitle2"
                  component="legend"
                  className={classes.numOfVehicles}
                >
                  {vehicles.length}
                </Typography>
                <LocalShipping color="action" />
              </>
            )}
          </Stack>
          <IconButton size="medium" onClick={handleToggleVehicleSorting}>
            <SwapVert fontSize="inherit" />
          </IconButton>
          <Divider
            className={classes.toolbarDivider}
            orientation="vertical"
            flexItem
          />
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <PlanningBoardToolbarLegend />
            {activeToolbarTab === 0 ? (
              <>
                <Tooltip title="Show Actuals" arrow>
                  <IconButton
                    onClick={handleToggleEnableCheckTripActuals}
                    size="medium"
                    color={enableCheckTripActuals ? 'primary' : undefined}
                  >
                    <Typography fontSize={14} fontWeight={600}>
                      ETA
                    </Typography>
                  </IconButton>
                </Tooltip>
                <IconButton onClick={toggleEnableGanttFullscreen} size="medium">
                  {fullscreen ? (
                    <FullscreenExit fontSize="inherit" />
                  ) : (
                    <Fullscreen fontSize="inherit" />
                  )}
                </IconButton>
              </>
            ) : null}
            {!fullscreen ? (
              <>
                <PlanningBoardToolbarReload />
                <PlanningBoardOptionsDrawer />
              </>
            ) : null}
          </Stack>
        </Stack>
      </Collapse>
      <IconButton onClick={handleToggleCollapse}>
        {collapsed ? <ArrowBackIos /> : <ArrowForwardIos />}
      </IconButton>
    </div>
  );
};
