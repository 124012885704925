import React from 'react';
import SettingService, {
  EVENT_PANE_AUTO_LOAD_NAME,
  GLOBAL_NAME,
} from '../../service/SettingService';
import {WindowInfoContext} from '../../contexts';
import {RoleService} from '../../service';

export interface FrameContextProps {
  leftDrawerOpen: boolean;
  rightDrawerOpen: boolean;
  rightDrawerWasOpened: boolean;
  showLeftDrawerToggleButton: boolean;

  // actions
  onLeftDrawerOpenToggle: () => void;
  onRightDrawerOpenToggle: () => void;
}

export function useVantageFrame(): FrameContextProps {
  return React.useContext(VantageFrameContext);
}

export const VantageFrameContext = React.createContext<FrameContextProps>({
  leftDrawerOpen: false,
  rightDrawerOpen: false,
  rightDrawerWasOpened: false,
  showLeftDrawerToggleButton: true,
  onLeftDrawerOpenToggle: () => null,
  onRightDrawerOpenToggle: () => null,
});

export const VantageFrameContextProvider: React.FC = ({children}) => {
  const {isDesktop} = React.useContext(WindowInfoContext);
  const eventTabAllowed = React.useMemo<boolean>(
    () => RoleService.hasPermission('EventTab', 'View'),
    []
  );

  const [showLeftDrawerToggleButton, setShowLeftDrawerToggleButtonAux] =
    React.useState<boolean>(true);
  const [leftDrawerOpen, setLeftDrawerOpen] = React.useState<boolean>(true);
  const [rightDrawerOpen, setRightDrawerOpen] = React.useState<boolean>(false);
  const [rightDrawerWasOpened, setRightDrawerWasOpened] =
    React.useState<boolean>(
      SettingService.getSetting(EVENT_PANE_AUTO_LOAD_NAME, GLOBAL_NAME) ?? false
    );

  const setShowLeftDrawerToggleButton = React.useCallback((newValue) => {
    setShowLeftDrawerToggleButtonAux(newValue);
  }, []);

  const handleLeftDrawerToggle = React.useCallback(() => {
    if (!isDesktop) {
      setRightDrawerOpen(false);
    }
    setLeftDrawerOpen((prev) => !prev);
  }, [isDesktop]);

  const handleRightDrawerToggle = () => {
    if (!isDesktop) {
      setLeftDrawerOpen(false);
    }
    if (!eventTabAllowed) {
      setRightDrawerOpen(false);
    } else {
      setRightDrawerOpen(!rightDrawerOpen);
    }
    setRightDrawerWasOpened(true);
  };

  React.useEffect(() => {
    setLeftDrawerOpen(isDesktop);
    setRightDrawerOpen(false);
  }, [isDesktop]);

  const value: FrameContextProps = {
    leftDrawerOpen,
    rightDrawerOpen,
    rightDrawerWasOpened,
    onRightDrawerOpenToggle: handleRightDrawerToggle,
    onLeftDrawerOpenToggle: handleLeftDrawerToggle,
    get showLeftDrawerToggleButton() {
      return showLeftDrawerToggleButton;
    },
    set showLeftDrawerToggleButton(newValue: boolean) {
      setShowLeftDrawerToggleButton(newValue);
    },
  };

  return (
    <VantageFrameContext.Provider value={value}>
      {children}
    </VantageFrameContext.Provider>
  );
};
