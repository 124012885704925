import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import UtilisationHeader from './UtilisationHeader';
import UtilisationItem from './UtilisationItem';
import {keyValuePairToObjectConversion} from '../../../../service/Util';
import {getColorSchemeName} from '../helpers';

const ITEM_COUNT = 3;
const UPDATE_INTERVAL = 10000; // 10 seconds

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
};

const findContract = (data, contractCode) => {
  return data.findIndex((c) => c.contractCode === contractCode);
};

const checkNumeric = (value) => {
  if (Number.isNaN(value)) {
    return 0;
  }

  if (value === null || value === undefined) {
    return 0;
  }

  return value;
};

const prepareData = (data) => {
  const contracts = [];
  const today = moment().format('YYYY-MM-DD');
  data.forEach((cd) => {
    const {contractCode} = cd;
    const contractIndex = findContract(contracts, contractCode);

    if (contractIndex > -1) {
      if (today === cd.tripDate) {
        contracts[contractIndex].movingOwnFleetCount = checkNumeric(
          parseInt(cd.movingOwnFleet, 10)
        );
        contracts[contractIndex].movingOtherFleetCount = checkNumeric(
          parseInt(cd.movingOtherFleet, 10)
        );
        contracts[contractIndex].plannedOwnFleetCount = checkNumeric(
          parseInt(cd.plannedOwnFleet, 10)
        );
        contracts[contractIndex].plannedOtherFleetCount = checkNumeric(
          parseInt(cd.plannedOtherFleet, 10)
        );
        contracts[contractIndex].movingVehicleCount = checkNumeric(
          parseInt(cd.actualVehicleCount, 10)
        ); // actualVehicleCount
        contracts[contractIndex].unplannedVehicleCount = checkNumeric(
          parseInt(cd.unplannedOwnFleet, 10)
        ); // unplannedVehicleCount
        contracts[contractIndex].totalVehicleCount = checkNumeric(
          parseInt(cd.totalVehicles, 10)
        ); // totalVehicles
        contracts[contractIndex].tripDate = cd.tripDate; // tripDate
      }
      contracts[contractIndex].graphData.push({
        actualKm: cd.actualKm ? parseFloat(cd.actualKm) : 0, // actualKm
        budgetKm: cd.budgetKm ? parseFloat(cd.budgetKm) : 0, // budgetedKm
        plannedKm: cd.plannedKm ? parseFloat(cd.plannedKm) : 0, // plannedKm
        tripDate: cd.tripDate, // tripDate
      });
    } else {
      const {
        actualKm,
        budgetKm,
        plannedKm,
        tripDate,
        actualVehicleCount,
        movingOtherFleet,
        movingOwnFleet,
        plannedOwnFleet,
        plannedOtherFleet,
        unplannedOwnFleet,
        totalVehicles,
      } = cd;

      const c = {
        contractCode,
        graphData: [
          {
            actualKm: actualKm ? parseFloat(actualKm) : 0, // actualKm
            budgetKm: budgetKm ? parseFloat(budgetKm) : 0, // budgetedKm
            plannedKm: plannedKm ? parseFloat(plannedKm) : 0, // plannedKm
            tripDate, // tripDate
          },
        ],
      };

      if (tripDate === today) {
        c.movingOtherFleetCount = parseInt(movingOtherFleet || '0', 10);
        c.movingOwnFleetCount = parseInt(movingOwnFleet || '0', 10);
        c.plannedOwnFleetCount = parseInt(plannedOwnFleet || '0', 10);
        c.plannedOtherFleetCount = parseInt(plannedOtherFleet || '0', 10);
        c.movingVehicleCount = parseInt(actualVehicleCount, 10); // actualVehicleCount
        c.unplannedVehicleCount = parseInt(unplannedOwnFleet, 10); // unplannedVehicleCount
        c.tripDate = tripDate; // tripDate
      } else {
        c.plannedOwnFleetCount = 0; // plannedOwnFleet
        c.movingVehicleCount = 0; // actualVehicleCount
        c.unplannedVehicleCount = 0; // unplannedVehicleCount
        c.tripDate = null; // tripDate
      }

      // total vehicles
      if (!c.totalVehicleCount || c.totalVehicleCount === 0) {
        c.totalVehicleCount = parseInt(totalVehicles || '0', 10);
      }

      // unplanned vehicles
      if (!c.unplannedVehicleCount) {
        c.unplannedVehicleCount = parseInt(totalVehicles || '0', 10);
      }

      contracts.push(c);
    }
  });

  return contracts;
};

const getTitle = (name = 'Contract') => {
  const colorSchemeName = getColorSchemeName();
  if (colorSchemeName === 'unitrans')
    if (name === 'Contract KM') return 'UCT Fleet Risk Indicator';
  if (colorSchemeName === 'default') return 'Contract';
  return name;
};

class UtilisationView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      isPaused: false,
      currentIndex: 0,
      isLoading: true,
      partial: [],
      title: getTitle(),
    };
  }

  componentDidMount() {
    this.setState(
      {
        isLoading: true,
      },
      this.processData
    );
  }

  sorter = (a, b) => {
    if (a.contractCode > b.contractCode) return 1;
    if (a.contractCode < b.contractCode) return -1;
    return 0;
  };

  processData = async () => {
    const {
      dashboard: {data, name},
    } = this.props;
    const parsed = data.map(keyValuePairToObjectConversion);
    const sorted = parsed.sort(this.sorter);
    const prepared = prepareData(sorted);
    const title = getTitle(name);

    this.handleStartPageInterval();
    this.setState(
      {
        data: prepared,
        isLoading: false,
        title,
      },
      this.getCurrentSlice
    );
  };

  shouldComponentUpdate(nextProps, nextState) {
    let refresh = false;
    // check if data changed from parent
    if (this.props.lastRefreshed !== nextProps.lastRefreshed) {
      refresh = true;
    }

    // check state
    // might need to do some deep checking if objects become nested
    if (this.state !== nextState) {
      refresh = true;
    }

    return refresh;
  }

  handleStartPageInterval = () => {
    this.timer = setInterval(this.getCurrentSlice, UPDATE_INTERVAL);
    this.setState({
      isPaused: false,
    });
  };

  handleStopPageInterval = () => {
    clearInterval(this.timer);
    this.setState({
      isPaused: true,
    });
  };

  getCurrentSlice = () => {
    const {currentIndex, data} = this.state;
    const lastIndex = data.length - 1;
    let nextIndex = currentIndex;
    let partial = [];

    if (data.length <= ITEM_COUNT) {
      partial = data;
      nextIndex = 0;
    } else if (currentIndex <= lastIndex - ITEM_COUNT) {
      partial = data.slice(currentIndex, currentIndex + ITEM_COUNT);
      nextIndex = currentIndex + ITEM_COUNT;
    } else if (currentIndex > lastIndex - ITEM_COUNT) {
      partial = data.slice(currentIndex);
      nextIndex = ITEM_COUNT - partial.length;
      partial = partial.concat(data.slice(0, nextIndex));
    } else {
      partial = data.slice(0, ITEM_COUNT);
      nextIndex = ITEM_COUNT;
    }

    this.setState({
      partial,
      currentIndex: nextIndex,
    });
  };

  render() {
    const {isLoading, isPaused, partial, title} = this.state;

    if (isLoading) {
      return <div>Loading...</div>;
    }

    return (
      <div style={styles.container}>
        <UtilisationHeader
          title={title}
          isPaused={isPaused}
          onStop={this.handleStopPageInterval}
          onStart={this.handleStartPageInterval}
        />
        {partial.length === 0 ? <div>No Data</div> : null}
        {partial.map((item) => (
          <UtilisationItem key={item.contractCode} {...item} />
        ))}
      </div>
    );
  }
}

UtilisationView.propTypes = {
  lastRefreshed: PropTypes.instanceOf(Date),
};

UtilisationView.defaultProps = {
  lastRefreshed: Date(),
};

export default UtilisationView;
