import React from 'react';
import {
  usePlanningBoardSettings,
  usePlanningBoardSettingsResponse,
  usePlanningBoardSettingsResponseInitial,
} from './usePlanningBoardSettings';

export const PlanningBoardSettingsContext =
  React.createContext<usePlanningBoardSettingsResponse>(
    usePlanningBoardSettingsResponseInitial
  );

export const PlanningBoardSettingsContextProvider: React.FC = ({children}) => {
  const value = usePlanningBoardSettings();

  return (
    <PlanningBoardSettingsContext.Provider value={value}>
      {children}
    </PlanningBoardSettingsContext.Provider>
  );
};

export const usePlanningBoardSettingsContext = () =>
  React.useContext(PlanningBoardSettingsContext);
