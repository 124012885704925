import React from 'react';
import {SpeedTableBandMinimal} from '@onroadvantage/onroadvantage-api';
import {Filter, Sorting} from '@devexpress/dx-react-grid';

const initialSorting: Sorting = {
  columnName: 'name',
  direction: 'desc',
};

interface useSpeedTableBandListParams {
  speedTableBands: SpeedTableBandMinimal[] | undefined;
}

export const useSpeedTableBandList = ({
  speedTableBands,
}: useSpeedTableBandListParams) => {
  const [list, setList] = React.useState<SpeedTableBandMinimal[]>(
    speedTableBands ?? []
  );
  const [filters, setFilters] = React.useState<Filter[]>();
  const [sorting, setSorting] = React.useState<Sorting[]>([initialSorting]);

  const handleColumnFilter = React.useCallback(
    (newFilters: Filter[]) => (speedTableBand: SpeedTableBandMinimal) =>
      !newFilters ||
      newFilters.length === 0 ||
      !newFilters
        .map((f) => {
          if (f.value) {
            const fValue = f.value.toLowerCase();
            switch (f.columnName) {
              case 'name':
                return speedTableBand.name?.toLowerCase()?.includes(fValue);
              case 'fromKm':
                return speedTableBand.fromKm?.toString()?.includes(fValue);
              case 'toKm':
                return speedTableBand.toKm?.toString()?.includes(fValue);
              case 'speedKmph':
                return speedTableBand.speedKmph?.toString()?.includes(fValue);
              default:
                return true;
            }
          } else return true;
        })
        .includes(false),
    []
  );

  const handleColumnSort = React.useCallback(
    (newSorting: Sorting[]) =>
      (a: SpeedTableBandMinimal, b: SpeedTableBandMinimal) => {
        const column = newSorting[0]?.columnName;
        const direction = newSorting[0]?.direction;

        if (!column || !direction) {
          return 0;
        }

        switch (column) {
          case 'name': {
            if (a.name > b.name) {
              return direction === 'asc' ? -1 : 1;
            }
            return direction === 'asc' ? 1 : -1;
          }
          case 'fromKm': {
            return direction === 'asc'
              ? a.fromKm - b.fromKm
              : b.fromKm - a.fromKm;
          }
          case 'toKm': {
            return direction === 'asc' ? a.toKm - b.toKm : b.toKm - a.toKm;
          }
          case 'speedKmph': {
            return direction === 'asc'
              ? a.speedKmph - b.speedKmph
              : b.speedKmph - a.speedKmph;
          }
          default:
            return 0;
        }
      },
    []
  );

  const handleFilterOrders = React.useCallback(
    (newFilters: Filter[]) => {
      setList(speedTableBands?.filter(handleColumnFilter(newFilters)) ?? []);
      setFilters(newFilters);
    },
    [handleColumnFilter, speedTableBands]
  );

  const handleSortingChange = React.useCallback(
    (newSorting: Sorting[]) => {
      setList((prevList) => prevList.sort(handleColumnSort(newSorting)));
      setSorting(newSorting);
    },
    [handleColumnSort]
  );

  React.useEffect(() => {
    setList(speedTableBands?.sort(handleColumnSort([initialSorting])) ?? []);
  }, [handleColumnSort, speedTableBands]);

  return {
    list,
    filters,
    sorting,
    onSortingChange: handleSortingChange,
    onFiltersChange: handleFilterOrders,
  };
};
