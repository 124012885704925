import React from 'react';
import * as Yup from 'yup';
import {AdminTelematicsConfig as AdminTelematicsConfigType} from '@onroadvantage/onroadvantage-api';
import {Formik, Form} from 'formik';
import {AdminTelematicsConfigContext} from '../AdminTelematicsConfigContext';
import {FormikTextField, FormikSwitch} from '../../../formik';
import {Loader} from '../../../loader';
import {Button} from '@mui/material';
import {NUMBER_ERROR_MESSAGE} from '../../../../factory/template';

export interface AdminTelematicsConfigDetailsFormValues {
  telematicsConfigId?: number;
  providerName: string;
  tile38Enabled: boolean;
  mixCriticalEventsUseTimeSlice?: boolean | null;
  mixTelematicsEventsUseTimeSlice?: boolean | null;
  mixOrganisationGroupId?: number | null;
  mixOrganisationGroupName?: string | null;
  mixIntegrateLocationSyncEnabled?: boolean | null;
  mixGroupsEnabled?: boolean | null;
  mixOrganisationEnabled?: boolean | null;
}

const schema: Yup.SchemaOf<AdminTelematicsConfigDetailsFormValues> = Yup.object(
  {
    telematicsConfigId: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
    providerName: Yup.string().required('Required'),
    tile38Enabled: Yup.boolean().required('Required'),
    mixCriticalEventsUseTimeSlice: Yup.boolean().nullable(),
    mixTelematicsEventsUseTimeSlice: Yup.boolean().nullable(),
    mixOrganisationGroupId: Yup.number()
      .typeError(NUMBER_ERROR_MESSAGE)
      .nullable(),
    mixOrganisationGroupName: Yup.string().nullable(),
    mixIntegrateLocationSyncEnabled: Yup.bool().nullable(),
    mixGroupsEnabled: Yup.bool().nullable(),
    mixOrganisationEnabled: Yup.bool().nullable(),
  }
);

const getInitialValues = (
  telematicsConfig?: AdminTelematicsConfigType | null
): AdminTelematicsConfigDetailsFormValues | undefined => {
  if (telematicsConfig) {
    return {
      telematicsConfigId: telematicsConfig.id,
      providerName: telematicsConfig.providerName ?? '',
      tile38Enabled: telematicsConfig.tile38Enabled ?? false,
      mixCriticalEventsUseTimeSlice:
        telematicsConfig.mixCriticalEventsUseTimeSlice ?? false,
      mixTelematicsEventsUseTimeSlice:
        telematicsConfig.mixTelematicsEventsUseTimeSlice ?? false,
      mixOrganisationGroupId: telematicsConfig.mixOrganisationGroupId,
      mixOrganisationGroupName: telematicsConfig.mixOrganisationGroupName,
      mixIntegrateLocationSyncEnabled:
        telematicsConfig.mixIntegrateLocationSyncEnabled ?? false,
      mixGroupsEnabled: telematicsConfig.mixGroupsEnabled ?? false,
      mixOrganisationEnabled: telematicsConfig.mixOrganisationEnabled ?? false,
    };
  }

  return undefined;
};

interface Props {
  isAdd?: boolean;
}

export const AdminTelematicsConfigDetailsForm: React.FC<Props> = ({isAdd}) => {
  const {detailsRef, loading, telematicsConfig, onDetailsFormSubmit} =
    React.useContext(AdminTelematicsConfigContext);
  const [initialValues, setInitialValues] = React.useState<
    AdminTelematicsConfigDetailsFormValues | undefined
  >();

  React.useEffect(() => {
    const values = getInitialValues(telematicsConfig);

    if (values) {
      setInitialValues(values);
    } else if (isAdd) {
      setInitialValues({
        providerName: '',
        tile38Enabled: false,
        mixIntegrateLocationSyncEnabled: false,
        mixCriticalEventsUseTimeSlice: false,
        mixTelematicsEventsUseTimeSlice: false,
        mixGroupsEnabled: false,
        mixOrganisationEnabled: false,
      });
    }
  }, [isAdd, telematicsConfig, setInitialValues]);

  if (loading) {
    return null;
  }

  if (!initialValues) {
    // TODO
    return <div>no initial values</div>;
  }

  return (
    <Formik<AdminTelematicsConfigDetailsFormValues>
      initialValues={initialValues}
      onSubmit={onDetailsFormSubmit}
      validationSchema={schema}
      innerRef={detailsRef}
    >
      <Form>
        <FormikTextField
          id="providerName"
          name="providerName"
          placeholder="Enter the provider name"
          label="Provider Name"
          fullWidth
        />
        <FormikSwitch name="tile38Enabled" label="Tile38 Enabled" />
        <FormikSwitch
          name="mixCriticalEventsUseTimeSlice"
          label="MiX Critical Events use time slice"
        />
        <FormikSwitch
          name="mixCriticalEventsUseTimeSlice"
          label="MiX Telematics Events use time slice"
        />
        <FormikSwitch
          name="mixIntegrateLocationSyncEnabled"
          label="MiX Telematics Location sync enabled"
        />
        <FormikSwitch
          name="mixGroupsEnabled"
          label="MiX Telematics Groups enabled"
        />
        <FormikSwitch
          name="mixOrganisationEnabled"
          label="MiX Telematics Organisation enabled"
        />
        <FormikTextField
          id="mixOrganisationGroupId"
          name="mixOrganisationGroupId"
          placeholder="Enter the MiX Organisation Group Id"
          label="MiX Organisation Group Id"
          fullWidth
        />
        <FormikTextField
          id="mixOrganisationGroupName"
          name="mixOrganisationGroupName"
          placeholder="Enter the MiX Organisation Group Name"
          label="MiX Organisation Group Name"
          fullWidth
        />
        {loading ? (
          <Loader size={25} />
        ) : (
          <Button
            // TODO: permisssions/roles
            // disabled={!RoleService.hasPermission('Edit TelematicsConfig', 'Edit')}
            // onClick={handleSubmit}
            data-test="submit"
            type="submit"
          >
            Submit
          </Button>
        )}
      </Form>
    </Formik>
  );
};
