import React from 'react';
import {Stack} from '@mui/material';
import {TripSummary} from './tripSummary';
import {useTripContext} from './tripContext';
import {Loader} from '../loader';
import {TripSettings, useTripSettingsContext} from './tripSettings';
import {TripViewLayout} from './TripViewLayout';

export const TRIP_CARD_GAP = 1.5;

export const Trip: React.FC = () => {
  const {didLoadSettings} = useTripSettingsContext();
  const {loadingMasterTrip, loadingTripLogs, updatingTrip, masterTrip} =
    useTripContext();

  /** Render updating loading spinner when the trip is updating */
  if (updatingTrip) {
    return <Loader text="Updating Trip..." />;
  }

  /**
   * All these loading states have to be false and the settings must be loaded before the view can be displayed, because
   * they are all needed for the trip summary.
   */
  if (!didLoadSettings || loadingMasterTrip || loadingTripLogs) {
    return <Loader text="Loading Trip..." />;
  }

  if (!masterTrip) {
    return <>Master Trip Not Found</>;
  }

  return (
    <Stack spacing={TRIP_CARD_GAP}>
      <TripSettings />
      <TripSummary />
      <TripViewLayout />
    </Stack>
  );
};
