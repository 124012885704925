import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

const AutocompleteStyles = (_theme: Theme) =>
  createStyles({
    root: {},
    input: {},
    paper: {},
    loader: {},
  });

export const useAutocompleteStyles = makeStyles(AutocompleteStyles, {
  name: 'AutocompleteStyles',
});
