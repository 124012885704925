/**
 * Created by waldo on 2017/03/03.
 */
const styles = (theme) => ({
  card: {
    marginBottom: 50,
    backgroundColor: 'blue',
  },
  actions: {
    display: 'flex',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: 'auto',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: '#000000',
  },
});

export default styles;
