import React from 'react';
import {CriticalEventDump} from '@onroadvantage/onroadvantage-api';
import {
  TemplateTable,
  TemplateTableConfig,
  TOnSelectionChange,
} from '../../../../factory/template';
import {mapDisplayStore} from '../../../../stores/mobxStores';
import {TripEventListDetail} from './TripEventListDetail';

export interface TripEventListProps {
  type: 'Critical' | 'Operational';
  list: CriticalEventDump[];
  loading: boolean;
}

export const TripEventList: React.FC<TripEventListProps> = ({
  type,
  list,
  loading,
}) => {
  const [selectedEvent, setSelectedEvent] =
    React.useState<CriticalEventDump | null>(null);

  const [config] = React.useState<TemplateTableConfig<CriticalEventDump>>({
    columns: [
      {name: 'id', label: 'Id', type: 'number'},
      {
        name: 'type',
        label: 'Type',
        type: 'string',
        getValue: ({type}) => type?.name,
      },
      {name: 'status', label: 'Status', type: 'string'},
      {
        name: 'driver_firstname',
        label: 'Driver Name',
        type: 'string',
        getValue: ({driver}) => driver?.firstname,
      },
      {name: 'eventDate', label: 'Event Date', type: 'datetime'},
      {
        name: 'assignedTo',
        label: 'Assigned User',
        type: 'string',
        getValue: ({assignedTo}) => assignedTo?.email,
      },
      {
        name: 'typeProviderName',
        label: 'Provider',
        type: 'string',
        getValue: ({type}) => type?.provider?.name,
      },
      {name: 'latitude', label: 'Latitude', type: 'string'},
      {name: 'longitude', label: 'Longitude', type: 'string'},
    ],
    selectionType: 'row',
    disableToolbar: true,
    disablePagination: true,
  });

  React.useEffect(() => {
    if (selectedEvent) {
      mapDisplayStore.setOpenEvent({
        ...selectedEvent,
        mapType: type,
      });
    }
    return () => mapDisplayStore.clearOpenEvent();
  }, [selectedEvent, type]);

  const handleSelectionChange = React.useCallback<
    TOnSelectionChange<CriticalEventDump>
  >(
    (selection) => {
      if (selection.length > 0) {
        const selectedEvents = list.filter(({id}) =>
          selection.map(({id}) => id).includes(id)
        );

        setSelectedEvent(
          (prev) => selectedEvents.find((e) => e.id !== prev?.id) ?? null
        );
      }
    },
    [list]
  );

  return (
    <TemplateTable
      config={config}
      currentPage={1}
      list={list}
      selection={selectedEvent?.id ? [selectedEvent] : []}
      onSelectionChange={handleSelectionChange}
      rowDetail={({row}) => <TripEventListDetail row={row} type={type} />}
      loading={loading}
      nestedLevel={0}
    />
  );
};
