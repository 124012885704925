import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

export const VehicleBookingAvailabilityStyles = (_theme: Theme) =>
  createStyles({
    root: {},
  });

export const useVehicleBookingAvailabilityStyles = makeStyles(
  VehicleBookingAvailabilityStyles,
  {name: 'VehicleBookingAvailabilityStyles'}
);
