import React from 'react';
import {useAppNotifications} from '../../../../../contexts';
import {ApiListingGetRequest, Listing} from '@onroadvantage/onroadvantage-api';
import {listingApi} from '../../../../../api';
import {debounce} from '@mui/material';
import {
  AutocompleteProps,
  TAutocompleteOnInputChange,
} from '../../../../autocomplete';
import {TripEditStopAutocomplete} from './TripEditStopAutocomplete';

interface FormikAutocompleteWithListingProps
  extends AutocompleteProps,
    ApiListingGetRequest {}

export const TripEditStopAutocompleteWithListing: React.FC<
  FormikAutocompleteWithListingProps
> = ({model, loading, onInputChange, options, ...props}) => {
  const notify = useAppNotifications();
  const [listing, setListing] = React.useState<Listing[]>([]);
  const [localLoading, setLocalLoading] = React.useState<boolean>(false);

  const getListing = React.useCallback(
    async (filter?: string) => {
      setLocalLoading(true);
      try {
        const response = await listingApi.apiListingGet({
          model,
          query: filter,
        });
        setListing(response.items ?? []);
      } catch (e) {
        notify('error', e.message ?? `Failed to load ${model} listing`);
      } finally {
        setLocalLoading(false);
      }
    },
    [model, notify]
  );

  const getListingDebounce = React.useRef(debounce(getListing, 500)).current;

  const handleInputChange = React.useCallback<TAutocompleteOnInputChange>(
    async (event, value, reason) => {
      setLocalLoading(true);
      await getListingDebounce(value);
      if (onInputChange) onInputChange(event, value, reason);
    },
    [getListingDebounce, onInputChange]
  );

  React.useEffect(() => {
    getListing();
  }, [getListing]);

  return (
    <TripEditStopAutocomplete
      {...props}
      loading={localLoading}
      options={listing}
      onInputChange={handleInputChange}
    />
  );
};
