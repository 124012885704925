import React from 'react';
import {EventsPaneCard} from '../eventsPaneCard';
import {useEventsPaneListStyles} from './EventsPaneList.style';
import {EventsPaneContext} from '../EventsPaneContext';
import {IconButton, TablePagination} from '@mui/material';
import Loader from '../../loader/Loader';
import {EventsPaneEmptyList} from './EventsPaneEmptyList';
import {sortByEventDate} from '../helpers';
import clsx from 'clsx';
import {ExpandMore, ExpandLess} from '@mui/icons-material';

export const EventsPaneList: React.FC = () => {
  const classes = useEventsPaneListStyles();
  const {
    eventsList,
    loading,
    currentPage,
    pageSize,
    pageTotal,
    itemTotal,
    handleCurrentPageChange,
    handlePageSizeCountsChange,
    collapseSearchForm,
    onToggleCollapseSearchForm,
  } = React.useContext(EventsPaneContext);

  return (
    <div className={classes.root}>
      {loading && (
        <div className={classes.overlayLoaderContainer}>
          <Loader text="Re-loading events" />
        </div>
      )}

      <div className={classes.list}>
        {eventsList.length === 0 ? (
          <EventsPaneEmptyList />
        ) : (
          eventsList
            .sort(sortByEventDate)
            .map((event) => <EventsPaneCard key={event.id} event={event} />)
        )}
      </div>
      <div className={classes.paginationContainer}>
        <IconButton
          className={clsx(classes.search, {
            [classes.searchIsExpanded]: !collapseSearchForm,
          })}
          onClick={onToggleCollapseSearchForm}
          size="small"
        >
          {collapseSearchForm ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
        <TablePagination
          component="div"
          count={
            itemTotal !== undefined
              ? itemTotal
              : (pageSize ?? 25) * (pageTotal ?? 1)
          }
          rowsPerPage={pageSize}
          page={currentPage}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          aria-disabled={loading}
          className={classes.pagination}
          style={{pointerEvents: loading ? 'none' : 'auto'}}
          onPageChange={handleCurrentPageChange}
          onRowsPerPageChange={handlePageSizeCountsChange}
          rowsPerPageOptions={[10, 20, 25, 50, 100]}
        />
      </div>
    </div>
  );
};
