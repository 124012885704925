import React from 'react';
import {Loader} from '../../../../loader';
import {Skeleton, Stack} from '@mui/material';
import {usePlanningBoardTripSkeletonStyles} from './PlanningBoardTripSkeleton.style';
import {IPlanningBoardTripPanelForm} from '../planningBoardTripForm';
import {useFormikContext} from 'formik';

export const PlanningBoardTripSkeleton: React.FC = () => {
  const {isSubmitting} = useFormikContext<IPlanningBoardTripPanelForm>();
  const classes = usePlanningBoardTripSkeletonStyles();
  return (
    <Stack position="relative">
      <Loader
        classes={{loaderContainer: classes.loaderContainer}}
        text={isSubmitting ? 'Creating Trip...' : 'Loading Trip Preview...'}
      />
      <Skeleton height={90} />
      <Skeleton height={90} />
      <Skeleton height={90} />
      <Skeleton height={90} />
      <Skeleton height={90} />
      <Skeleton height={90} />
      <Skeleton height={90} />
      <Skeleton height={90} />
      <Skeleton height={90} />
    </Stack>
  );
};
