import React from 'react';
import {WindowInfoConsumer} from '../../../contexts/WindowInfoContext';
import DashboardCard from './DashboardCard';
import {filterMenu} from '../../navigation/LeftSidebar';
import styled from '@emotion/styled';
import vantageLogoAnimation from '../../../img/json/vantageLogo.json';
import loginBackgroundAnimation from '../../../img/json/loginBackground.json';
import Lottie, {LottieProps} from 'react-lottie';

const ImageBackdrop = styled.div<{src?: string}>`
  ${(props) => props.src && `background: url("${props.src}");`}
  background-size: 100%;
  position: absolute;
  z-index: 0;
  inset: 0;
`;

const CardContainer = styled.div`
  padding: 11px;
`;

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  position: relative;
`;

const OverlaysRelativeContainer = styled.div`
  position: relative;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  margin: -10px;
  display: flex;
  overflow: hidden;
`;

const OverlayScrollContainer = styled.div`
  position: absolute;
  height: 100%;
  overflow-y: scroll;
`;

type ILottieDimension = number | string | undefined;

const LottieAnimation = ({
  lottieData,
  width,
  height,
  ...props
}: {
  lottieData: any;
  width?: ILottieDimension;
  height?: ILottieDimension;
} & Omit<LottieProps, 'options'>) => (
  <Lottie
    options={{
      loop: true,
      autoplay: true,
      animationData: lottieData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    }}
    height={height}
    width={width}
    isStopped={false}
    isClickToPauseDisabled
    {...props}
  />
);

export default function Dashboard() {
  const dashboardCardData = filterMenu({filter: ''});

  return (
    <WindowInfoConsumer>
      {({isDesktop}) => {
        return (
          <OverlaysRelativeContainer
            style={{
              display: !isDesktop ? undefined : 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              flexWrap: 'wrap',
              height: '100%',
            }}
          >
            <ImageBackdrop style={{opacity: '0.25'}}>
              <LottieAnimation
                lottieData={loginBackgroundAnimation}
                width={undefined}
                height={'100vh'}
              />
            </ImageBackdrop>
            <OverlayScrollContainer>
              <LottieAnimation
                lottieData={vantageLogoAnimation}
                height={'unset'}
                width={'unset'}
                style={{
                  marginTop: '20px',
                  overflow: 'unset',
                  maxWidth: '550px',
                  maxHeight: '125px',
                }}
              />
              <CardsContainer>
                {Object.entries(dashboardCardData).map(
                  ([itemKey, item]: [string, any]) => (
                    <CardContainer key={itemKey}>
                      <DashboardCard
                        listItemsLimit={5}
                        cardData={{
                          label: itemKey,
                          ...item,
                          items: Object.entries(item.items).map(([_, i]) => i),
                        }}
                      />
                    </CardContainer>
                  )
                )}
              </CardsContainer>
            </OverlayScrollContainer>
          </OverlaysRelativeContainer>
        );
      }}
    </WindowInfoConsumer>
  );
}
