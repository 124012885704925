import React from 'react';
import {
  listingSchema,
  multipleListingSchema,
  TemplateCard,
  TemplateForm,
} from '../../factory/template';
import {CustomerContext} from './CustomerContext';
import {Customer, Listing} from '@onroadvantage/onroadvantage-api';
import * as Yup from 'yup';
import {FormikAutocompleteWithListing, FormikTextField} from '../formik';

export interface ICustomerForm {
  accountNumber: string;
  contract: Listing;
  planningSkills: Listing[] | undefined;
  description: string;
  name: string;
}

const schema: Yup.SchemaOf<ICustomerForm> = Yup.object({
  accountNumber: Yup.string().required('Required'),
  contract: listingSchema.nullable().required('Required'),
  planningSkills: multipleListingSchema,
  description: Yup.string().required('Required'),
  name: Yup.string().required('Required'),
});

const getInitialValues = (
  customer?: Customer | null
): ICustomerForm | undefined => {
  if (customer) {
    return {
      accountNumber: customer.accountNumber,
      contract: {value: customer.contract?.id, label: customer.contract?.name},
      description: customer.description ?? '',
      name: customer.name,
      planningSkills: customer.skills?.map(({id, name, description}) => ({
        value: id,
        label: `${name} - ${description}`,
      })),
    };
  }
  return undefined;
};

export const CustomerForm: React.FC<{isAdd?: boolean}> = ({isAdd}) => {
  const {
    detailsRef,
    loadingSingleItem,
    submitting,
    customer,
    onDetailsFormSubmit,
  } = React.useContext(CustomerContext);
  const [initialValues, setInitialValues] = React.useState<ICustomerForm>();

  React.useEffect(() => {
    const values = getInitialValues(customer);

    if (values) {
      setInitialValues(values);
    } else if (isAdd) {
      setInitialValues({
        accountNumber: '',
        contract: {value: undefined, label: undefined},
        description: '',
        name: '',
        planningSkills: [],
      });
    }
  }, [isAdd, customer, setInitialValues]);

  return (
    <TemplateCard
      title={`Customer Details ${isAdd ? 'Add' : 'Edit'}`}
      loading={loadingSingleItem}
    >
      <TemplateForm<ICustomerForm>
        initialValues={initialValues}
        onSubmit={onDetailsFormSubmit}
        permission={{
          name: `${isAdd ? 'Add' : 'Edit'} Customer`,
          type: isAdd ? 'Add' : 'Edit',
        }}
        submitting={submitting}
        innerRef={detailsRef}
        validationSchema={schema}
      >
        <FormikTextField
          name="name"
          placeholder="Enter the customer name"
          label="Name"
          fullWidth
        />
        <FormikTextField
          name="description"
          placeholder="Enter the customer description"
          label="Description"
          fullWidth
        />
        <FormikTextField
          name="accountNumber"
          placeholder="Enter the customer account number"
          label="Account Number"
          fullWidth
        />
        <FormikAutocompleteWithListing
          name="contract"
          placeholder="Enter and select the customers contract"
          label="Contract"
          model="Contract"
        />
        <FormikAutocompleteWithListing
          name="planningSkills"
          placeholder="Enter and select the customer skills"
          label="Planning Skills"
          model="PlanningSkill"
          multiple
        />
      </TemplateForm>
    </TemplateCard>
  );
};
