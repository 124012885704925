import React from 'react';
import {useHistory} from 'react-router-dom';
import {
  ApiWebMasterTripGetRequest,
  ApiWebMasterTripMasterTripIdDetailGetRequest,
  WebMasterTripDetailSingle,
  WebMasterTripOptimised,
  WebMasterTripOptimisedListResponse,
} from '@onroadvantage/onroadvantage-api';
import {tripApi, webMasterTripApi} from '../../../api';
import {
  TemplateTableContextProps,
  TLoadList,
  useTemplateTable,
} from '../../../factory/template';
import {useAppNotifications} from '../../../contexts';
import {TripListDetail} from './TripListDetail';

export interface TripListContextProps
  extends TemplateTableContextProps<
    WebMasterTripOptimised,
    WebMasterTripOptimisedListResponse
  > {
  loadDetail: (
    masterTripId?: number
  ) => Promise<WebMasterTripDetailSingle[] | undefined>;
}

export const TripListContext = React.createContext<TripListContextProps>({
  loading: false,
  list: [],
  currentPage: 1,
  loadList: async () => {},
  loadDetail: async () => [],
});

export const TripListContextProvider: React.FC = ({children}) => {
  const history = useHistory();
  const notify = useAppNotifications();

  const [
    {
      // States
      currentPage,
      filters,
      hasPermission,
      isDaterangeFilterActive,
      itemTotal,
      list,
      loading,
      pageSize,
      pageTotal,
      loadingSingleItem,
      sorting,
    },
    {
      // Getters
      getDownloads,
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleDateRangeFilterToggle,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
    },
  ] = useTemplateTable<WebMasterTripOptimised, ApiWebMasterTripGetRequest>({
    editPermission: 'Edit MasterTrip',
    addPermission: 'Add MasterTrip',
    deletePermission: 'Delete Trip',
    downloadPermission: 'Trip ListDownload',
    viewPermission: 'Trip List',
    enableDateRangeFilter: true,
  });

  const loadList = React.useCallback<
    TLoadList<WebMasterTripOptimisedListResponse>
  >(
    async (options) => {
      setLoading(true);
      try {
        const requestObj = getRequestObj(
          [
            'vehicleRegistrationNumber',
            'status',
            'tripNumber',
            'driverName',
            'fleetNumber',
            'contractCode',
            'orderNumber',
          ],
          options
        );
        const response = await webMasterTripApi.apiWebMasterTripGet(requestObj);
        return getResponse(response, options);
      } catch (e) {
        notify('error', 'Failed to load trips');
      } finally {
        setLoading(false);
      }
    },
    [setLoading, getRequestObj, getResponse, notify]
  );

  const handleDownload = React.useCallback(
    async () =>
      getDownloads('trips', loadList, [
        {name: 'Trip Number', path: 'tripNumber'},
        {name: 'Driver', path: 'driverName'},
        {name: 'Vehicle', path: 'vehicleRegistrationNumber'},
        {name: 'Fleet Number', path: 'fleetNumber'},
        {name: 'Collection Location', path: 'upliftNodeName'},
        {name: 'Delivery Location', path: 'offloadNodeName'},
        {name: 'Trip Start', path: 'tripStart'},
        {name: 'Trip End', path: 'tripEnd'},
        {name: 'Created At', path: 'createdAt'},
        {name: 'Order Progress', path: 'orderProgress'},
        {name: 'Stop Progress', path: 'stopProgress'},
        {name: 'Task Progress', path: 'taskProgress'},
        {name: 'Status', path: 'status'},
        {name: 'Orders', path: 'orderNumber'},
        {name: 'Contract', path: 'contractCode'},
      ]),
    [getDownloads, loadList]
  );

  const handleDelete = React.useCallback(
    async (row: WebMasterTripOptimised) => {
      setLoading(true);
      try {
        if (row.id) {
          await tripApi.apiTripMasterTripIdDeleteRaw({masterTripId: row.id});
          await loadList();
          notify('success', 'Trip Deleted');
        }
      } catch (e) {
        notify('error', 'Failed to delete trip');
      } finally {
        setLoading(false);
      }
    },
    [loadList, notify, setLoading]
  );

  const handleAdd = React.useCallback(() => {
    history.push(`/triplist/add`);
  }, [history]);

  const handleNavigate = React.useCallback(
    (row: WebMasterTripOptimised) => {
      history.push(`/triplist/${row.id}`);
    },
    [history]
  );

  const handleAuxNavigate = React.useCallback(
    (row: WebMasterTripOptimised) => {
      window.open(
        history.createHref({pathname: `/triplist/${row.id}`}),
        '_blank'
      );
    },
    [history]
  );

  const handleRefresh = React.useCallback(() => {
    loadList();
  }, [loadList]);

  const loadDetail = React.useCallback(
    async (masterTripId?: number) => {
      setLoading(true);
      try {
        if (masterTripId) {
          const requestObj: ApiWebMasterTripMasterTripIdDetailGetRequest = {
            masterTripId: masterTripId,
          };

          const response =
            await webMasterTripApi.apiWebMasterTripMasterTripIdDetailGet(
              requestObj
            );

          const emptyResponse: WebMasterTripDetailSingle[] = [
            {
              id: 1,
              offloadNodeNames: '-',
              orderNumbers: '-',
              sequence: '-' as unknown as number,
              upliftNodeNames: '-',
            },
          ];

          if (response && response.items) {
            if (response.items.length > 0) return response.items;
            else return emptyResponse;
          } else {
            return emptyResponse;
          }
        }
      } catch (e) {
        notify('error', 'Failed to load trip details');
      } finally {
        setLoading(false);
      }
    },
    [notify, setLoading]
  );

  const value: TripListContextProps = {
    rowDetail: TripListDetail,
    grouping: [{columnName: 'contractCode'}],
    nestedLevel: 0,
    loading: loadingSingleItem || loading,
    list,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    isDaterangeFilterActive,
    onFiltersChange: handleFiltersChange,
    onSortingChange: handleSortingChange,
    onNavigate: handleNavigate,
    onAdd: hasPermission.add ? handleAdd : undefined,
    onAuxNavigate: handleAuxNavigate,
    onDelete: hasPermission.delete ? handleDelete : undefined,
    onDownload: hasPermission.download ? handleDownload : undefined,
    onDateRangeFilterToggle: handleDateRangeFilterToggle,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onRefresh: handleRefresh,
    loadList,
    cleanupList,
    loadDetail,
  };

  return (
    <TripListContext.Provider value={value}>
      {children}
    </TripListContext.Provider>
  );
};
