import React from 'react';
import {styled} from '@mui/material/styles';
import {ChevronRight} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  AccordionSummaryProps,
  Typography,
} from '@mui/material';
import {
  MasterTripDebriefTripStopSnapshot,
  MasterTripDocumentStorageSnapshot,
} from '@onroadvantage/onroadvantage-api';
import {TripDebriefDocument} from './TripDebriefDocument';

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ChevronRight />} {...props} />
))(({theme}) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

interface TripDebriefDocumentGroupProps {
  documentGroup: {
    stop: MasterTripDebriefTripStopSnapshot | undefined;
    documents: (MasterTripDocumentStorageSnapshot | undefined | null)[];
  };
}

export const TripDebriefDocumentGroup: React.FC<
  TripDebriefDocumentGroupProps
> = ({documentGroup}) => {
  return (
    <Accordion disableGutters>
      <AccordionSummary>
        <Typography fontWeight={600} mr={1}>
          Stop:
        </Typography>
        <Typography>{documentGroup.stop?.node?.name ?? 'undefined'}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {documentGroup.documents.map((document) =>
          document ? (
            <TripDebriefDocument
              key={document.id}
              document={document}
              downloadingAll={false}
              // downloadingAll={downloading}
            />
          ) : null
        )}
      </AccordionDetails>
    </Accordion>
  );
};
