import React from 'react';
import {Stack} from '@mui/material';
import {FormikAutocompleteWithListing, FormikSwitch} from '../../../formik';

export const ContractInspectionFormFields: React.FC = () => {
  return (
    <Stack>
      <FormikAutocompleteWithListing
        name="vehicleInspectionTaskTemplate"
        placeholder="Enter and select the contract vehicle inspection task template"
        label="Vehicle Inspection Task Template"
        model="TaskTemplate"
        fullWidth
      />
      <FormikAutocompleteWithListing
        name="driverInspectionTaskTemplate"
        placeholder="Enter and select the contract driver inspection task template"
        label="Driver Inspection Task Template"
        model="TaskTemplate"
        fullWidth
      />
      <FormikSwitch
        name="mandatoryDailyVehicleInspectionEnabled"
        label="Mandatory Daily Vehicle Inspection Enabled"
      />
      <FormikSwitch
        name="mandatoryDailyDriverInspectionEnabled"
        label="Mandatory Daily Driver Inspection Enabled"
        stackProps={{pb: 1}}
      />
    </Stack>
  );
};
