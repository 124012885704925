import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';
import {tabletSize} from '../../contexts';
import {LOGIN_CONTENT_MAX_WIDTH} from './Authentication.style';

const AuthenticationVideoStyles = (theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      marginTop: theme.spacing(2),
      [theme.breakpoints.up(tabletSize)]: {
        position: 'relative',
        left: theme.spacing(-5),
        width: LOGIN_CONTENT_MAX_WIDTH,
        maxWidth: LOGIN_CONTENT_MAX_WIDTH,
      },
    },
    loader: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    iframe: {border: 0},
  });

export const useAuthenticationVideoStyles = makeStyles(
  AuthenticationVideoStyles,
  {name: 'AuthenticationVideoStyles'}
);
