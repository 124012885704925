import React from 'react';
import {useParams} from 'react-router-dom';
import {useSpeedTableContext} from './speedTableContext';

interface IParams {
  speedTableId?: string;
}

export const SpeedTableIdHandler: React.FC = () => {
  const {setSpeedTableId} = useSpeedTableContext();

  const {speedTableId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = speedTableId ? parseInt(speedTableId) : undefined;
    setSpeedTableId(parsedId);
    return () => setSpeedTableId(undefined);
  }, [speedTableId, setSpeedTableId]);

  return null;
};
