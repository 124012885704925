import React from 'react';
import {Fab, InputAdornment, Stack, TextField, Tooltip} from '@mui/material';
import {styled} from '@mui/material/styles';
import CodeMirror from 'codemirror';
import SnippetData from './utils/snippets.json';
import FileSaver from 'file-saver';
import IconButton from '@mui/material/IconButton';
import {
  ArrowForward,
  Close,
  Download,
  Refresh,
  Stop,
  Visibility,
} from '@mui/icons-material';

import gptPromptStream from './utils/gpt';
import DocumentTemplateService from '../../service/DocumentTemplateService';
import {authStore} from '../../store';
import {defaultTemplate} from './defaultTemplate';
import {documentTemplateApi} from '../../api';
import {Loader} from '../loader';

import 'codemirror/lib/codemirror.css';
import 'codemirror/addon/hint/show-hint.js';
import 'codemirror/addon/hint/show-hint.css';
import 'codemirror/addon/hint/html-hint';
import 'codemirror/addon/hint/anyword-hint.js';
import 'codemirror/theme/solarized.css';
import 'codemirror/mode/jinja2/jinja2.js';
import 'codemirror/mode/htmlembedded/htmlembedded';
import 'codemirror/mode/htmlmixed/htmlmixed.js';
import 'codemirror/mode/css/css.js';
import {useAppNotifications} from '../../contexts';

const StyledTextArea = styled('textarea')(() => ({
  display: 'flex',
  height: '100%',
}));
const StyledGptButton = styled(IconButton)(({theme}) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  marginRight: theme.spacing(1),
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
}));

interface TemplateEditorProps {
  maxHeight?: string | number | undefined;
  minHeight?: string | number | undefined;
  value?: string;
  fieldName?: string;
  updateField?: (e: string) => void;
}

export const TemplateEditor = ({
  maxHeight,
  minHeight,
  value,
  fieldName,
  updateField,
}: TemplateEditorProps) => {
  const notify = useAppNotifications();
  const [editor, setEditor] = React.useState<CodeMirror.Editor>();
  const [delay, setDelay] = React.useState<any>();
  const [prompting, setPrompting] = React.useState<boolean>(false);
  const [loadingPrompt, setLoadingPrompting] = React.useState<boolean>(false);
  const promptInputRef = React.useRef<HTMLInputElement>();
  const editorRef = React.useRef<HTMLTextAreaElement | any>();
  const websocketRef = React.useRef<WebSocket | any>({current: null});

  const promptCallback = (snippet: string) => {
    if (editor) {
      const selection = editor.getSelection();
      const cursor = editor.getCursor();
      const line = cursor.line;
      const ch = cursor.ch;

      // Calculate the end position of the selection
      const selectionEndLine = line + selection.split('\n').length - 1;
      const selectionEndCh =
        selectionEndLine === line ? ch + selection.length : selection.length;

      // Insert the snippet at the end of the selection
      editor.replaceRange(snippet, {
        line: selectionEndLine,
        ch: selectionEndCh,
      });
    }
  };

  const handlePrompt = async () => {
    if (editor) {
      setPrompting(true);
      setLoadingPrompting(true);
      const selectedCode = editor.getSelection();
      editor.replaceSelection('');
      if (promptInputRef.current?.value) {
        websocketRef.current = await gptPromptStream(
          promptInputRef.current?.value,
          selectedCode,
          promptCallback,
          () => {
            setLoadingPrompting(false);
          },
          () => {
            setPrompting(false);
          }
        );
      }
    }
  };

  const cmModeCheck = React.useCallback(() => {
    if (editor) {
      const cursor = editor.getCursor();
      const line: number = cursor.line;
      const lineString = editor.getLine(line);
      const expRangeStart = lineString.search('{{ ');
      const expRangeEnd = lineString.search('}}');
      const conRangeStart = lineString.search('{% ');
      const conRangeEnd = lineString.search('%}');

      if (
        (cursor.ch > expRangeStart && cursor.ch < expRangeEnd) ||
        (cursor.ch > conRangeStart && cursor.ch < conRangeEnd)
      ) {
        return 'jinja2';
      } else {
        return 'text/html';
      }
    }
  }, [editor]);

  const handleUpdate = React.useCallback(() => {
    const previewFrame = document.getElementById(
      fieldName ? `${fieldName}TemplateEditor` : 'templateEditor'
    ) as HTMLIFrameElement;

    const preview =
      previewFrame?.contentDocument || previewFrame.contentWindow?.document;

    if (preview && editor) {
      //? determine code mode jinja or html
      editor.setOption('mode', cmModeCheck());
      preview.open();
      preview.write(editor.getValue());
      preview.close();
    }

    if (editor != null && updateField) {
      updateField(editor.getValue());
    }
  }, [cmModeCheck, editor, fieldName, updateField]);

  // ? Initialize editor
  React.useEffect(() => {
    const textarea = editorRef.current;

    setEditor(
      CodeMirror.fromTextArea(textarea, {
        mode: 'text/html',
        lineNumbers: true,
        theme: 'solarized dark',
      })
    );

    return () => {
      textarea.toTextArea && textarea?.toTextArea();
    };
  }, []);

  React.useEffect(() => {
    if (editor) {
      editor.setOption('theme', 'solarized dark');
    }
  }, [editor]);

  function mapIndexes(json: any, mapArr: Array<any>) {
    const indxs = Object.keys(json);
    let level = mapArr[0];
    level++;
    const map = mapArr[1];
    indxs.forEach((indx) => {
      if (!('type' in json[indx]) && json[indx]['type'] !== 'stop') {
        map.set(indx, mapIndexes(json[indx], [level, new Map()]));
      } else {
        map.set(indx, 'stop');
      }
    });

    return [level, map];
  }

  function retrieveLevelData(
    defaultval: number,
    level: number,
    data: Array<any>,
    wordchain: string[]
  ): any[] {
    let counter = defaultval;
    const temp = data;
    const levelIndex = wordchain[counter];

    if (temp[1].get(levelIndex) === 'stop') {
      return temp[1].keys();
    }

    const keys = [...temp[1].keys()];
    counter++;
    const mappedIndex = temp[0];

    let newData: any[] = [];
    if (mappedIndex === level) {
      newData = [...keys];
    } else if (keys.includes(levelIndex)) {
      newData = [
        ...retrieveLevelData(
          counter,
          level,
          data[1].get(levelIndex),
          wordchain
        ),
      ];
    }

    return newData;
  }

  //? Wait for initialization, and register tools
  const [once, setOnce] = React.useState(true);
  React.useEffect(() => {
    if (once && editor) {
      editor.setSize('100%', '100%');
      editor.setValue(value ?? defaultTemplate);

      editor?.on('change', () => {
        clearTimeout(delay);

        setDelay(setTimeout(handleUpdate, 300));
      });
      const snippets = mapIndexes(SnippetData, [0, new Map()]);

      CodeMirror.commands.autocomplete = () => {
        // TODO check autocomplete functionality. Its a nice to have
        return editor.getOption('mode') === 'text/html'
          ? CodeMirror.showHint(editor, () => {
              const cursor = editor.getCursor();
              const token = editor.getTokenAt(cursor);

              const line: number = cursor.line;
              const start: number = token.start;
              const end: number = cursor.ch;
              const addedDefaults = [
                {text: '{{ }}', displayText: 'statement: {{ }}'},
                {text: '{% %}', displayText: 'expression: {% %}'},
              ];

              return {
                list: addedDefaults,
                from: CodeMirror.Pos(line, start),
                to: CodeMirror.Pos(line, end),
              };
            })
          : CodeMirror.showHint(
              editor,
              () => {
                const cursor = editor.getCursor();
                const token = editor.getTokenAt(cursor);
                const line: number = cursor.line;
                const start: number = token.start;
                const end: number = cursor.ch;
                const lineString = editor.getLine(line);
                const words = lineString.substring(0, end).split(' ');
                const wordChain = words[words.length - 1].split('.');
                const levelCount = wordChain.length;

                const levelList = retrieveLevelData(
                  0,
                  levelCount,
                  snippets,
                  wordChain
                );

                const list = levelList.map((item: any) => {
                  return {text: item, displayText: `${item}`};
                });
                ///Trip, TripStop, Order, Task, Driver, Vehicle
                return {
                  list,
                  from: CodeMirror.Pos(line, start + 1), // ? complete after token end
                  to: CodeMirror.Pos(line, end),
                };
              },
              {}
            );
      };

      editor.setOption('extraKeys', {
        'Ctrl-Space': 'autocomplete',
        'Ctrl-F': 'find',
      });

      setOnce(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay, editor, once]);

  const handleDownload = async () => {
    try {
      const link = await DocumentTemplateService.getPreviewPdfLink();
      // @ts-ignore
      const {auth} = authStore;
      const headers = new Headers();
      headers.set('Content-Type', 'application/json');
      // @ts-ignore
      headers.set('Authentication-Token', auth.token);
      const response = await fetch(link, {
        method: 'POST',
        body: JSON.stringify({
          documentBody: editor?.getValue(),
          parameters: [],
        }),
        mode: 'cors',
        headers,
      });

      if (response.ok) {
        const blob = await response.blob();

        FileSaver.saveAs(blob, `DocumentTemplatePreview.pdf`);
      }
    } catch (e) {
      // console.log({e});
    }
  };

  const resetTemplate = async () => {
    let response;
    if (editor) {
      editor.setValue(defaultTemplate);
      response = await documentTemplateApi.apiDocumentTemplatePreviewPost({
        body: {
          documentBody: editor.getValue(),
        },
      });

      if (updateField) {
        updateField(editor.getValue());
      }
    }
    const previewFrame = document.getElementById(
      fieldName ? `${fieldName}TemplateEditor` : 'templateEditor'
    ) as HTMLIFrameElement;

    const preview =
      previewFrame?.contentDocument || previewFrame?.contentWindow?.document;

    if (preview && response?.documentBody) {
      preview.open();
      preview.write(response?.documentBody);
      preview.close();
    }
  };

  const clearTemplate = async () => {
    const previewFrame = document.getElementById(
      fieldName ? `${fieldName}TemplateEditor` : 'templateEditor'
    ) as HTMLIFrameElement;

    const preview =
      previewFrame?.contentDocument || previewFrame.contentWindow?.document;
    if (editor && preview) {
      editor.setValue(``);

      preview.open();
      preview.write(editor.getValue());
      preview.close();
      if (updateField) {
        updateField(editor.getValue());
      }
    }
  };

  const generatePreview = async () => {
    try {
      let response;
      if (editor) {
        response = await documentTemplateApi.apiDocumentTemplatePreviewPost({
          body: {
            documentBody: editor?.getValue(),
          },
        });
      }
      const previewFrame = document.getElementById(
        fieldName ? `${fieldName}TemplateEditor` : 'templateEditor'
      ) as HTMLIFrameElement;

      const preview =
        previewFrame?.contentDocument || previewFrame.contentWindow?.document;

      if (preview && response?.documentBody) {
        preview.open();
        preview.write(response?.documentBody);
        preview.close();
        if (editor != null && updateField) {
          updateField(editor.getValue());
        }
      }
    } catch (e) {
      notify('error', 'Failed to Preview');
    }
  };

  return (
    <Stack
      direction="row"
      flex={1}
      spacing={2}
      overflow={'hidden'}
      maxHeight={maxHeight}
      minHeight={minHeight}
    >
      <Stack flex={1} spacing={2}>
        <TextField
          id="template prompt"
          label="Prompt"
          fullWidth={false}
          multiline
          inputRef={promptInputRef}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Loader loading={loadingPrompt} size={28} />
                {prompting ? (
                  <StyledGptButton
                    onClick={() => websocketRef.current.close()}
                    size="small"
                  >
                    <Stop />
                  </StyledGptButton>
                ) : (
                  <StyledGptButton onClick={handlePrompt} size="small">
                    <ArrowForward />
                  </StyledGptButton>
                )}
              </InputAdornment>
            ),
          }}
        />
        <Stack flex={1} position={'relative'}>
          <iframe
            title="Vantage Template Editor"
            id={fieldName ? `${fieldName}TemplateEditor` : 'templateEditor'}
            style={{
              display: 'flex',
              flex: 1,
              position: 'relative',
            }}
          ></iframe>
          <Stack
            sx={{
              position: 'absolute',
              bottom: '32px',
              left: '20px',
              zIndex: 1000,
            }}
            spacing={1.5}
          >
            <Tooltip title="Clear template" placement="right" arrow>
              <Fab onClick={clearTemplate}>
                <Close />
              </Fab>
            </Tooltip>
            <Tooltip title="Reset template" placement="right" arrow>
              <Fab onClick={resetTemplate}>
                <Refresh />
              </Fab>
            </Tooltip>
            <Tooltip title="Download template" placement="right" arrow>
              <Fab onClick={handleDownload}>
                <Download />
              </Fab>
            </Tooltip>
            <Tooltip title="Preview template" placement="right" arrow>
              <Fab onClick={generatePreview}>
                <Visibility />
              </Fab>
            </Tooltip>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction="row" flex={1} spacing={2} overflow="auto">
        <Stack flex={1}>
          <StyledTextArea id="code" ref={editorRef} />
        </Stack>
      </Stack>
    </Stack>
  );
};
