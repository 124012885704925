import {NodeStopDump} from '@onroadvantage/onroadvantage-api';
import {getUniqueSetNearbyNodes} from './getUniqueSetNearbyNodes';

export const getNearbyNodes = async (
  criticalEventNodes: NodeStopDump[],
  telematicsEventNodes: NodeStopDump[]
) => {
  let nearbyNodes: NodeStopDump[] = [];

  if (criticalEventNodes) {
    nearbyNodes = [...criticalEventNodes];
  }
  if (telematicsEventNodes) {
    nearbyNodes = [...nearbyNodes, ...telematicsEventNodes];
  }

  return getUniqueSetNearbyNodes(nearbyNodes);
};
