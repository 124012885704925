import React from 'react';
import {useHistory} from 'react-router-dom';
import {
  ApiVehicleGroupGetRequest,
  VehicleGroup as VehicleGroupType,
  VehicleGroupPost,
  VehicleGroupListResponse,
} from '@onroadvantage/onroadvantage-api';
import {FormikHelpers, FormikProps} from 'formik/dist/types';
import {useAppNotifications} from '../../contexts';
import {vehicleGroupApi} from '../../api';
import {
  TemplateTableContextProps,
  TLoadList,
  useTemplateTable,
} from '../../factory/template';
import {IVehicleGroupForm} from './VehicleGroupForm';

export interface VehicleGroupContextProps
  extends TemplateTableContextProps<
    VehicleGroupType,
    VehicleGroupListResponse
  > {
  onDetailsFormSubmit: (
    values: IVehicleGroupForm,
    formikHelpers: FormikHelpers<IVehicleGroupForm>
  ) => void;
  setVehicleGroupId: (value: number | undefined) => void;
  submitting: boolean;
  vehicleGroup?: VehicleGroupType;
  vehicleGroupId?: number;
  detailsRef?: React.Ref<FormikProps<IVehicleGroupForm>>;
}

export const VehicleGroupContext =
  React.createContext<VehicleGroupContextProps>({
    // Template Table Defaults
    loading: false,
    list: [],
    currentPage: 1,
    // VehicleGroup
    loadList: async () => {},
    onDetailsFormSubmit: () => null,
    setVehicleGroupId: () => null,
    submitting: false,
  });

interface VehicleGroupContextProviderProps {
  vehicleGroupId?: number;
}

export const VehicleGroupContextProvider: React.FC<
  VehicleGroupContextProviderProps
> = ({children}) => {
  const history = useHistory();
  const notify = useAppNotifications();
  // Template Table
  const [
    {
      currentPage,
      pageSize,
      pageTotal,
      sorting,
      hasPermission,
      itemTotal,
      list,
      loading,
      loadingSingleItem,
      filters,
    },
    {
      // Getters
      getDownloads,
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
      setLoadingSingleItem,
    },
  ] = useTemplateTable<VehicleGroupType, ApiVehicleGroupGetRequest>({
    editPermission: 'Edit VehicleGroup',
    addPermission: 'Add VehicleGroup',
    deletePermission: 'Delete VehicleGroup',
    downloadPermission: 'VehicleGroup ListDownload',
    viewPermission: 'VehicleGroup List',
  });

  const loadList = React.useCallback<TLoadList<VehicleGroupListResponse>>(
    async (options) => {
      setLoading(true);
      try {
        const requestObj = getRequestObj(['name'], options);
        const response = await vehicleGroupApi.apiVehicleGroupGet(requestObj);
        return getResponse(response, options);
      } catch (e) {
        notify('error', 'Failed to load vehicle Group list');
      } finally {
        setLoading(false);
      }
    },
    [getRequestObj, getResponse, notify, setLoading]
  );

  const handleDelete = React.useCallback(
    async (row: VehicleGroupType) => {
      setLoading(true);
      try {
        if (row.id) {
          await vehicleGroupApi.apiVehicleGroupVehicleGroupIdDelete({
            vehicleGroupId: row.id,
          });
          await loadList();
          notify('success', 'Deleted vehicle group list');
        }
      } catch (e) {
        notify('error', 'Failed to delete');
      } finally {
        setLoading(false);
      }
    },
    [loadList, notify, setLoading]
  );

  const handleDownload = React.useCallback(
    () =>
      getDownloads('vehicle group', loadList, [{name: 'Name', path: 'name'}]),
    [getDownloads, loadList]
  );

  const handleAdd = React.useCallback(
    () => history.push('/vehiclegrouplist/add'),
    [history]
  );

  const handleNavigate = React.useCallback(
    (row) => history.push(`/vehiclegrouplist/${row.id}`),
    [history]
  );
  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  // Forms
  const [vehicleGroup, setVehicleGroup] = React.useState<
    VehicleGroupType | undefined
  >();
  const [vehicleGroupId, setVehicleGroupId] = React.useState<
    number | undefined
  >();
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const detailsRef = React.useRef<FormikProps<IVehicleGroupForm>>(null);

  const loadVehicleGroup = React.useCallback(async () => {
    setLoadingSingleItem(true);
    try {
      if (vehicleGroupId) {
        const response = await vehicleGroupApi.apiVehicleGroupVehicleGroupIdGet(
          {
            vehicleGroupId,
          }
        );
        setVehicleGroup(response);
      } else {
        setVehicleGroup(undefined);
      }
    } catch (e) {
      notify('error', 'Failed to load vehicle group list');
    } finally {
      setLoadingSingleItem(false);
    }
  }, [notify, setLoadingSingleItem, vehicleGroupId]);

  const handleDetailsFormSubmit = React.useCallback(
    async (
      values: IVehicleGroupForm,
      formikHelpers: FormikHelpers<IVehicleGroupForm>
    ) => {
      setSubmitting(true);
      try {
        const {planningSkills, ...otherValues} = values;
        const newValues: VehicleGroupPost = {
          planningSkillIds: planningSkills
            ?.filter(({value}) => !!value)
            .map(({value}) => value as number),
          ...otherValues,
        };

        if (vehicleGroupId) {
          await vehicleGroupApi.apiVehicleGroupVehicleGroupIdPatch({
            vehicleGroupId,
            body: newValues,
          });
        } else {
          await vehicleGroupApi.apiVehicleGroupPost({
            body: newValues,
          });
        }
        history.push('/vehiclegrouplist');
        notify(
          'success',
          `${vehicleGroupId ? 'Updated' : 'Added'} Vehicle Group`
        );
      } catch (e) {
        notify(
          'error',

          `Failed to ${vehicleGroupId ? 'update' : 'add'} vehicle group list`
        );
      } finally {
        formikHelpers.setSubmitting(false);
        setSubmitting(false);
      }
    },
    [history, notify, setSubmitting, vehicleGroupId]
  );

  const value: VehicleGroupContextProps = {
    // Template Table
    list,
    loadList,
    hasPermission,
    loading: loading || loadingSingleItem,
    cleanupList,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onAdd: hasPermission.add ? handleAdd : undefined,
    onDownload: hasPermission.download ? handleDownload : undefined,
    onNavigate: handleNavigate,
    onDelete: hasPermission.delete ? handleDelete : undefined,
    onRefresh: handleRefresh,
    // Forms
    onDetailsFormSubmit: handleDetailsFormSubmit,
    setVehicleGroupId,
    submitting,
    vehicleGroup,
    vehicleGroupId,
    detailsRef,
  };

  React.useEffect(() => {
    loadVehicleGroup();
    return () => setVehicleGroup(undefined);
  }, [loadVehicleGroup]);

  return (
    <VehicleGroupContext.Provider value={value}>
      {children}
    </VehicleGroupContext.Provider>
  );
};
