import React from 'react';
import {useHistory} from 'react-router-dom';
import {
  ApiWebUserTrainingCourseGetRequest,
  WebUserTrainingCourseDump as WebUserTrainingCourseDumpType,
  WebUserTrainingCourseResponse,
  UserTrainingCourse,
  UserTrainingCourseUpdate,
} from '@onroadvantage/onroadvantage-api';
import {FormikHelpers, FormikProps} from 'formik/dist/types';
import {webUserTrainingCourseApi, userTrainingCourseApi} from '../../../api';
import {TemplateTableContextProps} from '../../../factory/template';
import {useTemplateTable} from '../../../factory/template';
import {TLoadList} from '../../../factory/template';
import {ITrainingCourseUserForm} from './TrainingCourseUserForm';
import {TrainingCourseContext} from '../TrainingCourseContext';
import {useAppNotifications} from '../../../contexts';
import moment from 'moment';

export interface TrainingCourseUserContextProps
  extends TemplateTableContextProps<
    WebUserTrainingCourseDumpType,
    WebUserTrainingCourseResponse
  > {
  onDetailsFormSubmit: (
    values: ITrainingCourseUserForm,
    formikHelpers: FormikHelpers<ITrainingCourseUserForm>
  ) => void;
  onAssignUsersToCourse: (users: number[]) => void;
  setTrainingCourseUserId: (value: number | undefined) => void;
  submitting: boolean;
  trainingCourseUser?: UserTrainingCourse;
  trainingCourseUserId?: number;
  detailsRef?: React.Ref<FormikProps<ITrainingCourseUserForm>>;
}

export const TrainingCourseUserContext =
  React.createContext<TrainingCourseUserContextProps>({
    // Template Table Defaults
    loading: false,
    list: [],
    currentPage: 1,
    // TrainingCourseUser
    loadList: async () => {},
    onDetailsFormSubmit: () => null,
    onAssignUsersToCourse: () => null,
    setTrainingCourseUserId: () => null,
    submitting: false,
  });

interface TrainingCourseUserContextProviderProps {
  trainingCourseUserId?: number;
}

export const TrainingCourseUserContextProvider: React.FC<
  TrainingCourseUserContextProviderProps
> = ({children}) => {
  const history = useHistory();
  const notify = useAppNotifications();
  const {trainingCourseId} = React.useContext(TrainingCourseContext);
  // Template Table
  const [
    {
      currentPage,
      pageSize,
      pageTotal,
      sorting,
      hasPermission,
      itemTotal,
      list,
      loading,
      loadingSingleItem,
      filters,
    },
    {
      // Getters
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
      setLoadingSingleItem,
    },
  ] = useTemplateTable<
    WebUserTrainingCourseDumpType,
    ApiWebUserTrainingCourseGetRequest
  >({
    editPermission: 'Edit UserTrainingCourse',
    addPermission: 'Add UserTrainingCourse',
    deletePermission: 'Delete UserTrainingCourse',
    downloadPermission: 'UserTrainingCourse ListDownload',
    viewPermission: 'UserTrainingCourse List',
  });

  const loadList = React.useCallback<TLoadList<WebUserTrainingCourseResponse>>(
    async (options) => {
      setLoading(true);
      try {
        const requestObj = getRequestObj(
          [
            'userEmail',
            'status',
            'contractCode',
            'driverFirstname',
            'driverExternalEmployeeNumber',
          ],
          options,
          {
            trainingCourseId,
          }
        );
        const response =
          await webUserTrainingCourseApi.apiWebUserTrainingCourseGet(
            requestObj
          );
        return getResponse(response, options);
      } catch (e) {
        notify('error', e.message ?? 'Failed to load List');
      } finally {
        setLoading(false);
      }
    },
    [setLoading, getRequestObj, trainingCourseId, getResponse, notify]
  );

  const handleDelete = React.useCallback(
    async (row: WebUserTrainingCourseDumpType) => {
      setLoading(true);
      try {
        if (row.id) {
          await userTrainingCourseApi.apiUserTrainingCourseUserTrainingCourseIdDelete(
            {userTrainingCourseId: row.id}
          );
          await loadList();
          notify('success', 'Deleted User from Training Course');
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to delete User');
      } finally {
        setLoading(false);
      }
    },
    [loadList, notify, setLoading]
  );

  const handleAdd = React.useCallback(
    () => history.push(`/trainingcourselist/${trainingCourseId}/user/add`),
    [history, trainingCourseId]
  );

  const handleNavigate = React.useCallback(
    (row) =>
      history.push(`/trainingcourselist/${trainingCourseId}/user/${row.id}`),
    [history, trainingCourseId]
  );

  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  // Other
  const [trainingCourseUser, setTrainingCourseUser] =
    React.useState<UserTrainingCourse>();
  const [trainingCourseUserId, setTrainingCourseUserId] =
    React.useState<number>();
  const [submitting, setSubmitting] = React.useState(false);

  const detailsRef = React.useRef<FormikProps<ITrainingCourseUserForm>>(null);

  const loadTrainingCourseUser = React.useCallback(async () => {
    setLoadingSingleItem(true);
    try {
      if (trainingCourseUserId) {
        const response =
          await userTrainingCourseApi.apiUserTrainingCourseUserTrainingCourseIdGet(
            {userTrainingCourseId: trainingCourseUserId}
          );
        setTrainingCourseUser(response);
      } else {
        setTrainingCourseUser(undefined);
      }
    } catch (e) {
      notify('error', e.message ?? 'Failed to load user');
    } finally {
      setLoadingSingleItem(false);
    }
  }, [notify, setLoadingSingleItem, trainingCourseUserId]);

  const handleAssignUsersToCourse = React.useCallback(
    async (userIds: number[]) => {
      setSubmitting(true);
      try {
        if (trainingCourseId) {
          await userTrainingCourseApi.apiUserTrainingCourseBulkPost({
            body: {trainingCourseId, userIds},
          });
          notify('success', 'Assigned users to training course');
          history.push(`/trainingcourselist/${trainingCourseId}?tab=Users`);
          await loadList();
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to assign users');
      } finally {
        setSubmitting(false);
      }
    },
    [history, loadList, notify, trainingCourseId]
  );

  const handleDetailsFormSubmit = React.useCallback(
    async (
      values: ITrainingCourseUserForm,
      formikHelpers: FormikHelpers<ITrainingCourseUserForm>
    ) => {
      setSubmitting(true);
      formikHelpers.setSubmitting(true);
      try {
        if (trainingCourseUserId) {
          const {completedAt, startedAt, ...otherValues} = values;
          const newValues: UserTrainingCourseUpdate = {
            ...otherValues,
            completedAt: completedAt ? moment(completedAt).toDate() : undefined,
            startedAt: startedAt ? moment(startedAt).toDate() : undefined,
          };
          await userTrainingCourseApi.apiUserTrainingCourseUserTrainingCourseIdPatch(
            {
              userTrainingCourseId: trainingCourseUserId,
              body: newValues,
            }
          );
          notify('success', 'Updated User Details');
          history.push(`/trainingcourselist/${trainingCourseId}?tab=Users`);
          await loadList();
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to update user details');
      } finally {
        setSubmitting(false);
        formikHelpers.setSubmitting(false);
      }
    },
    [history, loadList, notify, trainingCourseId, trainingCourseUserId]
  );

  const value: TrainingCourseUserContextProps = {
    // Template Table
    list,
    loadList,
    hasPermission,
    loading: loading || loadingSingleItem,
    cleanupList,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onAdd: hasPermission.add ? handleAdd : undefined,
    onNavigate: handleNavigate,
    onDelete: hasPermission.delete ? handleDelete : undefined,
    onRefresh: handleRefresh,
    // Forms
    onDetailsFormSubmit: handleDetailsFormSubmit,
    onAssignUsersToCourse: handleAssignUsersToCourse,
    setTrainingCourseUserId,
    submitting,
    trainingCourseUser,
    trainingCourseUserId,
    detailsRef,
  };

  React.useEffect(() => {
    loadTrainingCourseUser();
    return () => setTrainingCourseUser(undefined);
  }, [loadTrainingCourseUser]);

  return (
    <TrainingCourseUserContext.Provider value={value}>
      {children}
    </TrainingCourseUserContext.Provider>
  );
};
