import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';
import {planningBoardTheme} from '../../planningBoardTheme';

export const PlanningBoardGanttStyles = (theme: Theme) =>
  createStyles({
    actionsContainer: {marginBottom: theme.spacing(1)},
    container: {marginBottom: theme.spacing(1), position: 'relative'},
    containerFullScreen: {
      position: 'absolute',
      inset: 0,
      zIndex: 10000,
      maxHeight: '100vh',
      overflowY: 'auto',
    },
    ganttItem: {
      color: 'white',
      borderWidth: 1,
      borderColor: 'lightgrey',
      '&.vis-selected': {
        borderColor: 'lightgrey',
      },
    },
    ganttItemPreview: {
      color: 'white',
      borderWidth: 0,
      backgroundColor: '#5db50a',
    },
    ganttItemAdding: {backgroundColor: 'rgba(9,111,184,0.55)'},
    ganttItemOptimized: {backgroundColor: planningBoardTheme.optimal},
    ganttItemBooking: {backgroundColor: planningBoardTheme.booking},
    ganttItemMaintenance: {backgroundColor: planningBoardTheme.maintenance},
    ganttItemMaster: {backgroundColor: planningBoardTheme.master},
    ganttItemMasterActualGreen: {backgroundColor: '#5db50a'},
    ganttItemMasterActualOrange: {backgroundColor: '#ff7000'},
    ganttItemMasterActualRed: {backgroundColor: '#e62700'},
    ganttItemPlanning: {backgroundColor: planningBoardTheme.planning},
    loaderContainer: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      zIndex: 1,
      transform: 'translate(-50%, -50%)',
    },
    rectSkeleton: {marginTop: 10},
    skeletonContainer: {position: 'relative'},
  });

export const usePlanningBoardGanttStyles = makeStyles(
  PlanningBoardGanttStyles,
  {name: 'PlanningBoardGanttStyles'}
);
