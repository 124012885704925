import React from 'react';
import {Form, Formik, FormikProps, FormikValues} from 'formik';
import {FormikHelpers} from 'formik/dist/types';
import {Route} from 'react-router-dom';
import * as Yup from 'yup';
import {
  Listing as ListingType,
  TripStop as TripStopType,
} from '@onroadvantage/onroadvantage-api';
import _ from 'lodash';
import {PlanningSolution} from './PlanningSolution';
import {PlanningTrip} from '../planningTrip';
import {Loader} from '../../loader';
import {usePlanningSolutionData} from '../../../stores/context';
import {NUMBER_ERROR_MESSAGE} from '../../../factory/template';

const listingSchema: Yup.SchemaOf<ListingType> = Yup.object({
  label: Yup.string().default(undefined),
  metaData: Yup.string().default(undefined),
  value: Yup.number().default(undefined),
});

export interface IStop {
  id: number;
  nodeId: number;
  sequence: number;
  nodeExternalReference: number;
  nodeName: number;
  arrivalTime: number;
  departureTime: number;
  orderIds: Array<number>;
}

export const TripSchema = Yup.object({
  id: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
  driver: listingSchema.nullable(),
  orders: Yup.array(Yup.number().typeError(NUMBER_ERROR_MESSAGE)),
  vehicle: listingSchema.nullable(),
  tripNumber: Yup.string(),
});

export interface IFormTrip {
  id?: number;
  /** Trip assigned Drivers */
  driver?: ListingType | null;
  /** Trip Assigned Orders */
  orders: Array<number>;
  /** Trip Assigned Vehicle */
  vehicle?: ListingType | null;
  /** temporary id until posted to server */
  tempId?: string;

  /** Trip Trip Number */
  tripNumber?: string;
  stops?: Array<IStop>;
}

/** Form Values */
export interface PlanningSolutionFormValues {
  /** the planning solution id */
  solutionId?: number | null;
  contract?: ListingType; // TODO typing
  /** Solution End Date */
  endDate?: Date; // TODO typing
  /** Solution Name */
  name?: string;
  /** Solution Start Date */
  startDate?: Date; // TODO typing
  /** Solution Allocated Orders */
  allocatedOrders?: Array<number>;
  /** Solution Unallocated Orders */
  unallocatedOrders?: Array<number>;
  /** Solution Allocated Drivers */
  allocatedDrivers?: Array<number>;
  /** Solution Allocated Vehicles */
  allocatedVehicles?: Array<number>;
  /** Solution Trips */
  trips?: Array<IFormTrip>;
  /** Solution Unassigned Orders */
  unassignedOrders?: Array<number>;
}

// TODO expand validation
const PlanningSolutionSchema: Yup.SchemaOf<PlanningSolutionFormValues> =
  Yup.object().shape({
    // values
    solutionId: Yup.number().typeError(NUMBER_ERROR_MESSAGE).default(undefined),
    contract: listingSchema.required(),
    // TODO validation message
    endDate: Yup.date(), // .required('Please select an end date'),
    name: Yup.string().required('Please enter a solution name'),
    // // TODO validation message
    startDate: Yup.date(), // .required('Please select a start date'),
    trips: Yup.array().of(TripSchema).default([]).nullable(),
    unassignedOrders: Yup.array()
      .of(Yup.number().typeError(NUMBER_ERROR_MESSAGE))
      .default([]),
    allocatedOrders: Yup.array()
      .of(Yup.number().typeError(NUMBER_ERROR_MESSAGE))
      .default([]),
    unallocatedOrders: Yup.array()
      .of(Yup.number().typeError(NUMBER_ERROR_MESSAGE))
      .default([]),
    allocatedDrivers: Yup.array()
      .of(Yup.number().typeError(NUMBER_ERROR_MESSAGE))
      .default([]),
    allocatedVehicles: Yup.array()
      .of(Yup.number().typeError(NUMBER_ERROR_MESSAGE))
      .default([]),
  });

export interface IFormikProps {
  formik: FormikProps<PlanningSolutionFormValues>;
}

export interface IProps {
  onSave?: (
    values: FormikValues,
    actions: FormikHelpers<FormikValues>
  ) => Promise<void>;
}

// eslint-disable-next-line import/prefer-default-export
export const PlanningSolutionForm: React.FC<IProps> = ({onSave}) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [initialValues, setInitialValues] =
    React.useState<PlanningSolutionFormValues>();

  const {initialLoaded} = usePlanningSolutionData((store) => ({
    initialLoaded: store.initialLoaded,
  }));

  // planning solution data
  const {
    contractListings,
    driverListings,
    planningSolution,
    vehicleListings,

    setPlanningOrders,
  } = usePlanningSolutionData((store) => ({
    contractListings: store.rawContractListings,
    driverListings: store.rawDriverListings,
    planningSolution: store.planningSolution,
    vehicleListings: store.rawVehicleListings,

    setPlanningOrders: store.setPlanningOrders,
  }));

  const handleSubmit = async (
    values: PlanningSolutionFormValues,
    actions: FormikHelpers<PlanningSolutionFormValues>
  ) => {
    return onSave && onSave(values, actions);
  };

  React.useEffect(() => {
    if (!planningSolution || !initialLoaded) {
      return;
    }

    setLoading(true);

    const initV: PlanningSolutionFormValues = {};

    // planning orders
    // setPlanningOrders(planningSolution.planningOrders || []);
    // TODO understand implications if any

    // planning solution name
    initV.name = planningSolution.name;

    // planning solution startDate and endDate
    if (planningSolution.startDate) {
      initV.startDate = planningSolution.startDate;
    }

    if (planningSolution.endDate) {
      initV.endDate = planningSolution.endDate;
    }

    const contractLabel = planningSolution.contract
      ? `${planningSolution.contract.code} - ${planningSolution.contract.name}`
      : '';
    // contract
    initV.contract = {value: planningSolution.contractId, label: contractLabel};
    // race condition, contractListings = []. We wont be able to change contract on edit.
    // initV.contract = contractListings.find(
    //   (c) => c.value === planningSolution.contractId
    // );

    // allocatedOrders
    const tao: Array<number> = [];

    // allocated drivers
    const tad: Array<number> = [];
    (planningSolution.drivers || []).forEach(({id}) => id && tad.push(id));
    initV.allocatedDrivers = tad;

    // allocated vehicles
    const tav: Array<number> = [];
    (planningSolution.vehicles || []).forEach(({id}) => id && tav.push(id));
    initV.allocatedVehicles = tav;

    // trips
    const tt: Array<IFormTrip> = [];
    (planningSolution.planningMasterTrips || []).forEach((pt) => {
      const trip: IFormTrip = {
        orders: [],
      };

      // trip id
      const id = _.get(pt, 'trip.id', null);
      if (id) {
        trip.id = id;
      }

      // trip number
      const tripNumber = _.get(pt, 'trip.tripNumber', null);
      if (tripNumber) {
        trip.tripNumber = tripNumber;
      }

      // trip vehicle id
      const vehicleId = _.get(pt, 'trip.vehicle.id', null);
      const vehicle = (vehicleListings || []).find(
        (v) => v.value === vehicleId
      );
      if (vehicleId && vehicle) {
        trip.vehicle = vehicle;
      }

      // trip driver id
      const driverId = _.get(pt, 'trip.driver.id', null);
      const driver = (driverListings || []).find((d) => d.value === driverId);
      if (driverId && driver) {
        trip.driver = driver;
      } else {
        trip.driver = null;
      }

      // stops & planningOrders
      const orders: Array<number> = [];

      const ts: Array<TripStopType> = _.get(pt, 'trip.stops', null);
      if (ts) {
        ts.forEach((s) => {
          // trip stop planningOrders
          if (s.orders) {
            const tsOrders: Array<number> = [];
            s.orders.forEach((so) => {
              if (so.id) {
                // push allocated order from actual trip stop
                if (!tao.includes(so.id)) {
                  const p = (planningSolution.planningOrders || []).find(
                    (po) => _.get(po, 'order.id', -1) === so.id
                  );
                  if (p && p.id) {
                    tao.push(p.id);
                  }
                }
                orders.push(so.id);
                tsOrders.push(so.id);
              }
            });
          }
        });
      }
      // map orders to planning orders
      trip.orders = (planningSolution.planningOrders || [])
        .filter((po) => orders.indexOf(_.get(po, 'order.id', 0)) !== -1)
        // get ids only
        .map((o) => (o && o.id ? o.id : 0))
        // ensure ids are unique
        .filter(
          (value: number, index: number, arr: Array<number>) =>
            arr.indexOf(value) === index
        )
        // sort by id
        .sort((first, last) => {
          return first - last;
        });
      const existingTrip = _.find(tt, ['id', trip.id]);
      if (existingTrip) {
        tt.indexOf(existingTrip);
        const replacementIndex = tt.indexOf(existingTrip);
        if (replacementIndex !== -1) {
          tt[replacementIndex] = trip;
        }
      } else {
        tt.push(trip);
      }
    });
    initV.trips = tt;
    initV.allocatedOrders = tao;

    // unallocated orders
    const tuao: Array<number> = [];
    (planningSolution.planningOrders || [])
      .filter(({id = -1}) => !tao.includes(id))
      .forEach(({id}) => id && tuao.push(id));
    initV.unallocatedOrders = tuao;

    // unassigned orders
    const tuo: Array<number> = [];
    // TODO have backend calc unassigned orders correctly when saving
    //  if no trip is assigned then no unassigned orders are returned
    if (
      planningSolution.planningMasterTrips &&
      planningSolution.planningMasterTrips.length === 0
    ) {
      tuo.push(...tao);
    } else {
      (planningSolution.unassignedOrders || []).forEach(
        ({id}) => id && tuo.push(id)
      );
    }
    initV.unassignedOrders = tuo;

    initV.solutionId = planningSolution.id;

    setInitialValues(initV);

    setLoading(false);
  }, [
    contractListings,
    driverListings,
    planningSolution,
    vehicleListings,
    setLoading,
    setInitialValues,
    setPlanningOrders,
    initialLoaded,
  ]);

  if (loading || !initialValues || !initialLoaded) {
    return <Loader />;
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={PlanningSolutionSchema}
    >
      <Form autoComplete="off">
        <Route
          exact
          path="/planningsolutionlist/s/:id"
          component={PlanningSolution}
        />
        <Route
          exact
          path="/planningsolutionlist/s/:solutionId/t/:tripId"
          component={PlanningTrip}
        />
      </Form>
    </Formik>
  );
};
