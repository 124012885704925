import React from 'react';
import {EventsPaneSearch} from './eventsPaneSearch';
import {EventsPaneList} from './eventsPaneList';
import {EventsPaneTabs} from './eventsPaneTabs';
import {useEventsPaneStyles} from './EventsPane.style';
import {WindowInfoContext} from '../../contexts';
import {Divider} from '@mui/material';

export const EventsPane: React.FC = () => {
  const classes = useEventsPaneStyles();
  const {isDesktop} = React.useContext(WindowInfoContext);

  return (
    <div className={classes.root}>
      {!isDesktop && <div className={classes.appBarPlaceholder} />}
      <EventsPaneTabs />
      <EventsPaneList />
      <Divider />
      <EventsPaneSearch />
    </div>
  );
};
