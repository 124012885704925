import React from 'react';
import {withRouter, RouteComponentProps, useLocation} from 'react-router-dom';
import {useErrorBoundaryStyles} from './ErrorBoundary.style';

interface ErrorBoundaryProps extends RouteComponentProps {
  customMessage?: string;
}
interface ErrorBoundaryState {
  hasError: boolean;
  message: string;
}

const HandleError: React.FC<ErrorBoundaryState> = (props) => {
  const classes = useErrorBoundaryStyles();
  const {pathname} = useLocation();
  const [initialErrorPathname] = React.useState<string>(pathname);

  const pathHasChanged = React.useMemo(
    () => initialErrorPathname !== pathname,
    [initialErrorPathname, pathname]
  );

  React.useEffect(() => {
    if (pathHasChanged) document.location.reload();
  }, [pathHasChanged]);

  return (
    <div className={classes.root}>
      <h1>Something went wrong.</h1>
      <div className={classes.errorMessage}>
        <h3>Error message: {props.message}</h3>
      </div>
    </div>
  );
};

class ErrorBoundaryComponent extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      message: '',
    };
  }

  static getDerivedStateFromError(error: any) {
    return {
      hasError: true,
      message: error.message,
    };
  }

  componentWillUnmount() {
    this.setState({hasError: false, message: ''});
  }

  render() {
    if (this.state.hasError) return <HandleError {...this.state} />;
    return this.props.children;
  }
}

export const ErrorBoundary = withRouter(ErrorBoundaryComponent);
