import React from 'react';
import {DataTypeProvider} from '@devexpress/dx-react-grid';
import {DragIndicator} from '@mui/icons-material';
import {useTemplateTableActions} from '../../providers';
import {Stack} from '@mui/material';

export const TableActionDragAndDrop: React.FC<
  React.PropsWithChildren<DataTypeProvider.ValueFormatterProps>
> = ({row}) => {
  const {onDragStart} = useTemplateTableActions();

  const handleDragStart = React.useCallback(
    (event: React.DragEvent<HTMLTableRowElement> & {target: {id: any}}) => {
      if (onDragStart) {
        event.dataTransfer.effectAllowed = 'move';
        onDragStart(event, row);
      }
    },
    [onDragStart, row]
  );

  if (!onDragStart) return null;

  return (
    <Stack style={{cursor: 'move'}} onDragStart={handleDragStart}>
      <DragIndicator />
    </Stack>
  );
};
