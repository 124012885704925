import {decorate, observable, action, computed} from 'mobx';

class AppNotification {
  constructor() {
    this.notifications = [];
  }

  get appNotifications() {
    return this.notifications;
  }

  enqueueNotification = (type, message) => {
    if (this.notifications.length > 3) {
      this.notifications.pop();
    }
    this.notifications.push({
      key: new Date().getTime() + Math.random(),
      type,
      message,
    });
  };

  removeNotification = (notification) => {
    if (notification) {
      this.notifications.remove(notification);
    }
  };

  clearNotifications = () => {
    this.notifications = [];
  };
}

export default decorate(AppNotification, {
  notifications: observable,
  appNotifications: computed,
  enqueueNotification: action,
  removeNotification: action,
  clearNotifications: action,
});
