import {makeStyles, createStyles} from '@mui/styles';
import {Theme} from '@mui/material/styles';

const TemplateFormStyles = (theme: Theme) =>
  createStyles({
    actions: {
      display: 'flex',
      alignItems: 'center',
      margin: 0,
      padding: 0,
      marginTop: theme.spacing(0.5),
    },
  });

export const useTemplateFormStyles = makeStyles(TemplateFormStyles, {
  name: 'TemplateFormStyles',
});
