import React from 'react';
import {authStore} from '../../../store';
import {encodeUrl, getQueryStringValue} from '../../../service/Util';
import ConfigService from '../../../service/ConfigService/ConfigService';
import DashboardRefreshSpeedDial from './DashboardRefreshSpeedDial';
import {DashboardDataStudioDashboard} from './DashboardDataStudioDashboard';

interface Props {
  dashboardStyle?: React.CSSProperties;
}

export const DashboardDataStudio: React.FC<Props> = ({dashboardStyle}) => {
  const [srcUrl, setSrcUrl] = React.useState<string>();
  const [refreshKey, setRefreshKey] = React.useState<number>(Math.random());
  const [didLoad, setDidLoad] = React.useState(false);

  const handleRefresh = () => {
    setDidLoad(false);
    setRefreshKey(Math.random());
  };

  React.useEffect(() => {
    const auth = authStore.getAuth;
    const reportId = getQueryStringValue(window.location.search, 'reportId');
    const encodedReportId = window.btoa(reportId).split('=').join('');

    const params: {[key: string]: string} = {};
    // @ts-expect-error upgrade
    params[`${encodedReportId}.token`] = auth ? auth.token : null;
    // @ts-expect-error upgrade
    params[`${encodedReportId}.instance`] = ConfigService.serverUri;

    setSrcUrl(
      encodeUrl(`https://datastudio.google.com/embed/reporting/${reportId}`, {
        params: JSON.stringify(params),
      })
    );
  }, [setSrcUrl]);

  React.useEffect(() => {
    document.title = 'Vantage | Dashboard';
  }, []);

  return (
    <div style={{display: 'flex', flex: 1}}>
      <DashboardRefreshSpeedDial
        handleRefresh={handleRefresh}
        didLoad={didLoad}
      />
      <DashboardDataStudioDashboard
        key={refreshKey}
        srcUrl={srcUrl}
        style={dashboardStyle}
        onLoad={() => setDidLoad(true)}
      />
    </div>
  );
};
