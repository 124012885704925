import {Column} from '@devexpress/dx-react-grid';
import _ from 'lodash';
import {IColumnConfig, TColumnType} from '.';
import {SettingService} from '../../../service';

export const COL_VISIBILITY_SETTING_ID = 'col_visibility';

export const getColumnWidths = (columns: ReadonlyArray<Column>) => {
  return columns.map((c) => {
    if (c.name === 'actions') {
      return {
        columnName: c.name,
        width: 130,
      };
    }
    return {
      columnName: c.name,
      width: 'auto',
    };
  });
};

export const getColumnNamesByType = <GetColumnNamesGenericData>(
  columns: IColumnConfig<GetColumnNamesGenericData>[],
  type: TColumnType
) => {
  const dateColumns: string[] = [];

  columns.forEach((c) => {
    if (c.type === type) {
      dateColumns.push(c.name as string);
    }
  });

  return dateColumns;
};

export const setColumnVisibility = (
  columnNames: string[],
  viewName?: string
) => {
  if (viewName) {
    SettingService.setSetting(viewName, COL_VISIBILITY_SETTING_ID, columnNames);
  }
};

export const getColumnVisibility = <T>(
  columns: IColumnConfig<T>[],
  viewName?: string
) => {
  const columnNames = columns.map((c) => `${c.name}`);
  let hiddenColumns: string[] | undefined = undefined;

  if (viewName) {
    hiddenColumns = SettingService.getSetting(
      viewName,
      COL_VISIBILITY_SETTING_ID
    );
  }

  if (hiddenColumns) {
    // check against columns
    const hiddenMap: string[] = _.intersection(columnNames, hiddenColumns);

    if (!_.isEqual(hiddenMap, hiddenColumns)) {
      setColumnVisibility(hiddenMap, viewName);
    }

    return hiddenMap;
  } else {
    const hiddenMap: string[] = [];
    columns.forEach((i) => {
      if (i.hidden) {
        hiddenMap.push(i.name as string);
      }
    });

    return hiddenMap;
  }
};

export interface IListLoadOptions {
  dataOnly?: boolean;
  reload?: boolean;
  overrides?: {perPage?: number};
}

export type TLoadList<T> = (
  options?: IListLoadOptions
) => Promise<null | void | T>;
