import React from 'react';
import {ReportParameter} from '@onroadvantage/onroadvantage-api';
import {
  ReportParameterDate,
  ReportParameterMultiSelect,
  ReportParameterCustomMultiSelect,
  ReportParameterDateTime,
  ReportParameterDateRange,
} from './parameter';
import {useFormikContext} from 'formik';

interface Props {
  parameters?: ReportParameter[];
}

export const ReportScheduleFormParameterList: React.FC<Props> = ({
  parameters,
}) => {
  const {isSubmitting} = useFormikContext();
  const content: React.ReactNode[] = [];

  parameters?.forEach((rp, index) => {
    switch (rp.type) {
      case 'arrint':
        content.push(
          <ReportParameterMultiSelect
            disabled={isSubmitting}
            id={`parameters[${index}].value`}
            listName={rp.listName ?? ''}
            label={rp.description}
            multiple={true}
            name={`parameters[${index}].value`}
            key={rp.id}
          />
        );
        break;
      case 'customlist':
        content.push(
          <ReportParameterCustomMultiSelect
            disabled={isSubmitting}
            id={`parameters[${index}].value`}
            listValues={rp.listValues ?? []}
            label={rp.description}
            multiple={true}
            name={`parameters[${index}].value`}
            key={rp.id}
          />
        );
        break;
      case 'date':
        content.push(
          <ReportParameterDate
            disabled={isSubmitting}
            id={`parameters[${index}].value`}
            label={rp.description ?? ''}
            name={`parameters[${index}].value`}
            key={rp.id}
          />
        );
        break;
      case 'datetime':
        content.push(
          <ReportParameterDateTime
            disabled={isSubmitting}
            label={rp.description ?? ''}
            name={rp.name ?? ''}
            key={rp.id}
          />
        );
        break;
      case 'daterange':
        content.push(
          <ReportParameterDateRange
            disabled={isSubmitting}
            id={rp.name}
            label={rp.description}
            name={`parameters[${index}].value`}
            key={rp.id}
          />
        );
        break;
    }
  });

  return <>{content}</>;
};
