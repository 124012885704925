import React from 'react';
import Typography from '@mui/material/Typography';
import {MomentService} from '../../../service';
import {
  CriticalEventActivity,
  OperationalEventActivity,
} from '@onroadvantage/onroadvantage-api';
import {Stack} from '@mui/material';

interface EventCardActivityProps {
  activity: CriticalEventActivity | OperationalEventActivity;
  filteredActions?: string[];
}

export const EventCardActivity: React.FC<EventCardActivityProps> = ({
  activity,
  filteredActions = [],
}) => {
  if (filteredActions && filteredActions.includes(activity.action)) {
    return null;
  }

  return (
    <Stack direction={'row'} spacing={1} paddingTop={1}>
      <Stack direction={'column'} flex={1}>
        <Typography style={{fontWeight: 'bold'}}>{activity.action}:</Typography>
        <Typography>{activity.value}</Typography>
      </Stack>
      <Stack
        direction={'column'}
        flex={2}
        flexWrap={'wrap'}
        sx={{overflowWrap: 'break-word', wordBreak: 'break-all'}}
      >
        <Typography style={{fontWeight: 'bold'}}>
          {MomentService.displayFromNow(activity.createdAt)}
        </Typography>
        <Typography>
          {MomentService.displayTimestamp(activity.createdAt)}
        </Typography>
        <Typography>{activity.createdBy?.email}</Typography>
      </Stack>
    </Stack>
  );
};
