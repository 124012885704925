import React from 'react';
import L from 'leaflet';
import BaseMarker from './BaseMarker';

interface Props {
  position: [number, number]; // lat , lng
  vehicle: {registrationNumber?: string};
  //  forced to have fill color change come from MAP.TSX instead because of way this has been set up
  // map doesn't rerender components (mobx updates) if they're stored in layers unless on map level
  fillColor: string;
}

export default class ActualResourceMarker extends React.Component<Props> {
  render() {
    const Icon = L.ExtraMarkers.icon({
      icon: 'fa-user-astronaut',
      markerColor: 'red',
      shape: 'penta',
      prefix: 'fa',
    });

    const {position, vehicle} = this.props;

    const actualResource = {
      title: 'Actual Resource',
      vehicleRegistrationNumber: vehicle
        ? vehicle.registrationNumber
        : 'No Vehicle',
      latitude: position[0],
      longitude: position[1],
    };

    return (
      <BaseMarker
        position={position}
        icon={Icon}
        actualResource={actualResource}
      />
    );
  }
}
