import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {
  PlanningMasterTrip,
  PlanningSkill,
  VehicleCapacityDimension,
  PlanningSolution,
  PlanningOrder,
} from '@onroadvantage/onroadvantage-api';
import Chip from '@mui/material/Chip';
import _ from 'lodash';
import {List} from '@mui/material';
import truckSvg from '../../img/svg/truck.svg';
import {getTripTAT} from '../../service/Util';
import {IFormTrip} from '../planning/planningSolution/PlanningSolutionForm';

interface Props {
  planningMasterTrip: PlanningMasterTrip;
  planningMasterTripFormik: IFormTrip | undefined;
  planningSolution: PlanningSolution | null;
}

class PlanningMasterTripMapCard extends React.Component<Props> {
  render() {
    const {planningMasterTrip, planningMasterTripFormik, planningSolution} =
      this.props;

    // @ts-expect-error upgrade
    const solutionTotalDistance: number = planningMasterTrip.trip.tripDistance;
    const solutionTotalDuration: number = getTripTAT(
      _.get(planningMasterTrip, 'trip.tripStart'),
      _.get(planningMasterTrip, 'trip.tripEnd')
    );
    const vehicleRegistration = _.get(
      planningMasterTrip,
      'trip.vehicle.registrationNumber'
    );
    const vehicleTypeName = _.get(planningMasterTrip, 'trip.vehicle.type.name');
    const driverName = _.get(planningMasterTrip, 'trip.driver.firstname');
    const capacityDimensions = _.get(
      planningMasterTrip,
      'trip.vehicle.type.capacityDimensions'
    );

    const planningSkills = _.get(
      planningMasterTrip,
      'trip.vehicle.type.skills'
    );

    const tripPlanningOrders: PlanningOrder[] = [];
    let actualCapacities: number[] = [];
    let sumCapacity1 = 0;
    let sumCapacity2 = 0;
    let sumCapacity3 = 0;
    let sumCapacity4 = 0;
    if (
      planningMasterTripFormik &&
      planningSolution &&
      planningSolution.planningOrders
    ) {
      planningMasterTripFormik.orders.forEach((orderId: number) => {
        const planningOrder = _.find(planningSolution.planningOrders, {
          id: orderId,
        });
        if (planningOrder) {
          tripPlanningOrders.push(planningOrder);
          if (planningOrder.capacityDimension1) {
            sumCapacity1 += planningOrder.capacityDimension1;
          }
          if (planningOrder.capacityDimension2) {
            sumCapacity2 += planningOrder.capacityDimension2;
          }
          if (planningOrder.capacityDimension3) {
            sumCapacity3 += planningOrder.capacityDimension3;
          }
          if (planningOrder.capacityDimension4) {
            sumCapacity4 += planningOrder.capacityDimension4;
          }
        }
      });
    }

    actualCapacities = [sumCapacity1, sumCapacity2, sumCapacity3, sumCapacity4];
    return (
      <Card style={{marginRight: 10}}>
        <List style={{maxHeight: '100%', overflow: 'auto'}}>
          <CardContent>
            <img alt="Vantage Logo" src={truckSvg} style={{marginBottom: 10}} />
            {planningMasterTrip && planningMasterTrip.trip && (
              <>
                <div
                  style={{display: 'flex', flexDirection: 'column', flex: 1}}
                >
                  <div style={{flex: 1}}>Vehicle:</div>
                  <Chip key={vehicleRegistration} label={vehicleRegistration} />
                </div>
                <div
                  style={{display: 'flex', flexDirection: 'column', flex: 1}}
                >
                  <div style={{flex: 1}}>Vehicle Type:</div>
                  <Chip key={vehicleTypeName} label={vehicleTypeName} />
                </div>
                <div
                  style={{display: 'flex', flexDirection: 'column', flex: 1}}
                >
                  <div style={{flex: 1}}>Driver:</div>
                  <Chip key={driverName} label={driverName} />
                </div>
                <div
                  style={{display: 'flex', flexDirection: 'column', flex: 1}}
                >
                  <div style={{flex: 1}}>Total Distance (km):</div>
                  <Chip
                    key={'totalDistance'}
                    label={
                      !isNaN(solutionTotalDistance)
                        ? Math.round(solutionTotalDistance * 100) / 100
                        : '-'
                    }
                  />
                </div>
                <div
                  style={{display: 'flex', flexDirection: 'column', flex: 1}}
                >
                  <div style={{flex: 1}}>Total Duration (min):</div>
                  <Chip
                    key={'totalDuration'}
                    label={
                      !isNaN(solutionTotalDuration)
                        ? Math.round(solutionTotalDuration * 100) / 100
                        : '-'
                    }
                  />
                </div>
                <div
                  style={{display: 'flex', flexDirection: 'column', flex: 1}}
                >
                  <div style={{flex: 1}}>Vehicle Capacities:</div>
                  {capacityDimensions &&
                    capacityDimensions.map(
                      (
                        capacityDimension: VehicleCapacityDimension,
                        index: number
                      ) => {
                        const actualCapacity = actualCapacities[index]; // TODO will break more than 4
                        // if (
                        //   capacityDimension &&
                        //   capacityDimension.capacity &&
                        //   actualCapacity > capacityDimension.capacity
                        // ) {
                        //   // TODO popup message of going over capacity
                        //   // console.log('over capacity');
                        // }
                        return (
                          <Chip
                            key={`capacityDimensions${index}`}
                            label={`${capacityDimension.name}: ${actualCapacity} / ${capacityDimension.capacity}`}
                            style={{height: 20, marginBottom: 2}}
                          />
                        );
                      }
                    )}
                </div>
                <div
                  style={{display: 'flex', flexDirection: 'column', flex: 1}}
                >
                  <div style={{flex: 1}}>Vehicle Skills:</div>
                  {planningSkills &&
                    planningSkills.map(
                      (skill: PlanningSkill, index: number) => {
                        return (
                          <Chip
                            key={`planningSkills${index}`}
                            label={skill.name}
                            style={{height: 20, marginBottom: 2}}
                          />
                        );
                      }
                    )}
                </div>
              </>
            )}
          </CardContent>
        </List>
      </Card>
    );
  }
}

export default PlanningMasterTripMapCard;
