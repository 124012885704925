import {makeStyles, createStyles} from '@mui/styles';

const ReportParameterDateRangePickerStyles = () =>
  createStyles({
    root: {
      flexDirection: 'row',
      display: 'flex',
    },
    picker: {
      flexDirection: 'column',
      display: 'flex',
      flex: 1,
      marginRight: 12,
    },
    customPickerRoot: {
      paddingTop: 3,
      paddingBottom: 21,
    },
    customPickerRootError: {
      paddingBottom: 0,
    },
    customPickerDivider: {
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
      borderBottomColor: 'rgba(0, 0, 0, 0.42)',
    },
    customRangeButtonContainer: {
      alignSelf: 'center',
    },
  });

export const useReportParameterDateRangePickerStyles = makeStyles(
  ReportParameterDateRangePickerStyles,
  {
    name: 'ReportParameterDateRangePickerStyles',
  }
);
