import React from 'react';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import _ from 'lodash';
import {Listing, TripStop} from '@onroadvantage/onroadvantage-api';
import moment, {Moment} from 'moment';
import {listingApi} from '../../../../api';
import {useAppNotifications} from '../../../../contexts';
import {Loader} from '../../../loader';
import {getStopPlannedTAT} from '../../../../utils';
import {TripEditStopDetailsWithoutForm} from './TripEditStopDetailsWithoutForm';

export interface ITripEditStopForm {
  node: Listing | null;
  taskTemplateNodeType: Listing | undefined | null;
  departureTime: Moment | null;
  totalServiceTime: string | number | null;
  totalServiceTimeChangeReason: string | undefined | null;
}

export interface TripStopWithNodeId extends TripStop {
  nodeId?: number;
  totalServiceTimeChangeReason?: string | null;
}

interface TripEditStopContextProps {
  initialValues: ITripEditStopForm | undefined;
  tripStop: TripStopWithNodeId;
  loading: boolean;
  submitDisabled: boolean;
  departureTimeRef: React.RefObject<HTMLInputElement>;
  nodeRef: React.RefObject<HTMLInputElement>;
  taskTemplateNodeTypeRef: React.RefObject<HTMLInputElement>;
  totalServiceTimeRef: React.RefObject<HTMLInputElement>;
  totalServiceTimeChangeReasonRef: React.RefObject<HTMLInputElement>;
  onSubmit: () => void;
}

const TripEditStopContext = React.createContext<TripEditStopContextProps>({
  initialValues: undefined,
  tripStop: {},
  loading: false,
  submitDisabled: false,
  departureTimeRef: {current: null},
  nodeRef: {current: null},
  taskTemplateNodeTypeRef: {current: null},
  totalServiceTimeRef: {current: null},
  totalServiceTimeChangeReasonRef: {current: null},
  onSubmit: () => null,
});

export const useTripEditStopContext = () =>
  React.useContext(TripEditStopContext);

export interface TripEditStopProps {
  commitChanges: ({
    changed,
    added,
    deleted,
  }: {
    changed?: any;
    added?: any;
    deleted?: any;
  }) => void; // TODO typing
  tripStop: TripStopWithNodeId;
}

export const TripEditStopWithoutForm: React.FC<TripEditStopProps> = ({
  commitChanges,
  tripStop,
}) => {
  const notify = useAppNotifications();
  const [initialValues, setInitialValues] = React.useState<ITripEditStopForm>(); // {node, taskTemplateNodeType, departureTime}
  const [tabValue, setTabValue] = React.useState<number>(0);
  const [loading, setLoading] = React.useState<boolean>(false);
  /**
   * Using Refs for the fields instead of state to improve performance, otherwise if we used state the entire component
   * would rerender everytime we change or typing in a value. With refs we only need to get the current.value to get the
   * relevant field's value.
   */
  const departureTimeRef = React.useRef<HTMLInputElement>(null);
  const nodeRef = React.useRef<HTMLInputElement>(null);
  const taskTemplateNodeTypeRef = React.useRef<HTMLInputElement>(null);
  const totalServiceTimeRef = React.useRef<HTMLInputElement>(null);
  const totalServiceTimeChangeReasonRef = React.useRef<HTMLInputElement>(null);

  const tripStopAddCallback = React.useCallback(
    (value: any) => {
      commitChanges({added: value});
    },
    [commitChanges]
  );

  const tripStopEditCallback = React.useCallback(
    (value) => {
      const changed: any = {}; // TODO typing
      changed[value.id] = value;
      commitChanges({changed});
    },
    [commitChanges]
  );

  const handleSubmit = React.useCallback(async () => {
    setLoading(true);
    try {
      if (!nodeRef.current?.value || !taskTemplateNodeTypeRef.current?.value) {
        notify('info', 'Site and Task Template Node Type is required');
        return;
      }
      const node = {
        id: nodeRef.current?.id,
        name: nodeRef.current?.value,
      };
      const taskTemplateNodeType = taskTemplateNodeTypeRef.current?.value;
      const departureTime = moment(departureTimeRef.current?.value);
      const totalServiceTime = totalServiceTimeRef.current?.value;
      const totalServiceTimeChangeReason =
        totalServiceTimeChangeReasonRef.current?.value;
      if (Object.keys(tripStop).length > 0) {
        tripStop.sequence === 1
          ? tripStopEditCallback({
              ...tripStop,
              node,
              taskTemplateNodeType,
              departureTime,
              totalServiceTime,
              totalServiceTimeChangeReason,
            })
          : tripStopEditCallback({
              ...tripStop,
              node,
              taskTemplateNodeType,
              totalServiceTime,
              totalServiceTimeChangeReason,
            });
      } else {
        const addedTripStop = {nodeId: node.id, taskTemplateNodeType};
        tripStopAddCallback(addedTripStop);
      }
    } finally {
      setLoading(false);
    }
  }, [
    notify,
    nodeRef,
    departureTimeRef,
    totalServiceTimeRef,
    totalServiceTimeChangeReasonRef,
    tripStopAddCallback,
    tripStopEditCallback,
    tripStop,
    setLoading,
  ]);

  const handleTabChange = (event: React.ChangeEvent<any>, value: number) => {
    setTabValue(value);
  };

  React.useEffect(() => {
    const getTimes = () => {
      const {departureTime, arrivalTime, serviceTimeStart, serviceTimeEnd} =
        tripStop;
      return {departureTime, arrivalTime, serviceTimeStart, serviceTimeEnd};
    };

    const getNode = () => {
      if (!tripStop || !tripStop.nodeId) {
        return null;
      }

      return {
        value: tripStop.nodeId,
        label: `${_.get(tripStop, 'siteName')} - ${_.get(
          tripStop,
          'externalReference'
        )}`,
      };
    };

    const getNodeTypeOptions = async () => {
      setLoading(true);
      try {
        const response = await listingApi.apiListingGet({
          model: 'NodeType',
          query: tripStop.taskTemplateNodeType,
        });
        return response.items;
      } catch (e) {
        notify('error', e.message ?? 'Failed to load node type listing');
      } finally {
        setLoading(false);
      }
    };

    const getTaskTemplateNodeType = async () => {
      const nodeTypeOptions = await getNodeTypeOptions();
      return nodeTypeOptions?.find(
        (a) => _.get(a, 'label') === tripStop.taskTemplateNodeType
      );
    };

    const getInitialValues = async () => {
      setLoading(true);
      try {
        const taskTemplateNodeType = await getTaskTemplateNodeType();
        const node = getNode();
        const times = getTimes();
        const totalServiceTimeChangeReason =
          tripStop.totalServiceTimeChangeReason;
        const stopPlannedTAT = getStopPlannedTAT(times);
        setInitialValues({
          node,
          taskTemplateNodeType,
          departureTime: moment(times.departureTime),
          totalServiceTime:
            tripStop.totalServiceTime ??
            (stopPlannedTAT === '-' ? null : stopPlannedTAT),
          totalServiceTimeChangeReason: totalServiceTimeChangeReason ?? null,
        });
      } finally {
        setLoading(false);
      }
    };

    getInitialValues();
  }, [tripStop, setInitialValues, notify]);

  if (loading) {
    return <Loader />;
  }
  if (!initialValues) {
    return null;
  }

  return (
    <div style={{width: '100%'}}>
      <Paper style={{width: '100%'}}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          style={{width: 350}}
        >
          <Tab label="Header" />
        </Tabs>
        {tabValue === 0 && (
          <TripEditStopContext.Provider
            value={{
              initialValues,
              loading,
              tripStop,
              submitDisabled: false,
              departureTimeRef,
              nodeRef,
              taskTemplateNodeTypeRef,
              totalServiceTimeRef,
              totalServiceTimeChangeReasonRef,
              onSubmit: handleSubmit,
            }}
          >
            <TripEditStopDetailsWithoutForm />
          </TripEditStopContext.Provider>
        )}
      </Paper>
    </div>
  );
};
