import React from 'react';
import {FormikCheckbox, FormikDatePicker, FormikTimePicker} from '../../formik';
import {useReportScheduleFormScheduleRecurringStyles} from './ReportScheduleFormScheduleRecurring.style';
import {FormLabel} from '@mui/material';

export const ReportScheduleFormScheduleRecurring: React.FC = () => {
  const classes = useReportScheduleFormScheduleRecurringStyles();
  return (
    <div className={classes.container}>
      <div className={classes.days}>
        <FormLabel>Choose which day(s) to schedule:</FormLabel>
        <div className={classes.daysContent}>
          <FormikCheckbox label="Sun" name="sunday" labelPlacement="top" />
          <FormikCheckbox label="Mon" name="monday" labelPlacement="top" />
          <FormikCheckbox label="Tue" name="tuesday" labelPlacement="top" />
          <FormikCheckbox label="Wed" name="wednesday" labelPlacement="top" />
          <FormikCheckbox label="Thu" name="thursday" labelPlacement="top" />
          <FormikCheckbox label="Fri" name="friday" labelPlacement="top" />
          <FormikCheckbox label="Sat" name="saturday" labelPlacement="top" />
        </div>
      </div>
      <FormikDatePicker label="Start Date" name="startDate" />
      <FormikDatePicker label="End Date" name="endDate" />
      <FormikTimePicker
        label="Report Schedule Time"
        name="time"
        placeholder="Enter the Report Schedule Time"
      />
    </div>
  );
};
