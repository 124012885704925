import React from 'react';
import {Plugin, Template, TemplatePlaceholder} from '@devexpress/dx-react-core';
import {Button} from '@mui/material';
import {CheckBox, CheckBoxOutlineBlank} from '@mui/icons-material';

export interface TableToolbarDaterangeFilterButtonProps {
  isActive?: boolean;
  loading?: boolean;
  onToggle?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

/** adds a loading indicator to the DevExpress Toolbar */
export class TableToolbarDaterangeFilterButton extends React.PureComponent<TableToolbarDaterangeFilterButtonProps> {
  pluginDependencies = [{name: 'Toolbar'}];

  render() {
    const {isActive, loading, onToggle} = this.props;
    return (
      <Plugin
        dependencies={this.pluginDependencies}
        name="ToolbarDaterangeFilterButton"
      >
        <Template name="toolbarContent">
          <TemplatePlaceholder />
          <Button
            color={isActive === true ? 'primary' : 'inherit'}
            variant="text"
            disabled={loading}
            onClick={onToggle}
            style={{marginLeft: 8}}
            startIcon={
              isActive === true ? <CheckBox /> : <CheckBoxOutlineBlank />
            }
          >
            Daterange
          </Button>
        </Template>
      </Plugin>
    );
  }
}
