import React from 'react';
import {
  useContract,
  useContractResponse,
  useContractResponseInitial,
} from './useContract';
import {useLocation} from 'react-router-dom';

export type ContractContextProps = useContractResponse;

export const ContractContext = React.createContext<ContractContextProps>(
  useContractResponseInitial
);

export const ContractContextProvider: React.FC = ({children}) => {
  const value = useContract();
  const {pathname} = useLocation();

  const {loadContract, setContract, onReset} = value;

  React.useEffect(() => {
    loadContract();
    return () => {
      setContract(undefined);
    };
  }, [loadContract, setContract]);

  React.useEffect(() => {
    if (pathname === '/contractlist') {
      onReset();
    }
  }, [onReset, pathname]);

  return (
    <ContractContext.Provider value={value}>
      {children}
    </ContractContext.Provider>
  );
};

export const useContractContext = () => React.useContext(ContractContext);
