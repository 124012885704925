import React from 'react';
import {
  TripDebriefSummaryCard,
  TripDebriefSummaryCardList,
} from '../tripDebriefSummaryCard';
import {TripDebriefContext} from '../../TripDebriefContext';
import {TripDebriefSummaryVehicleTruck} from './TripDebriefSummaryVehicleTruck';
import {MasterTripDebriefSnapshot} from '@onroadvantage/onroadvantage-api';

export const TripDebriefSummaryVehicle: React.FC = () => {
  const {masterTripDebriefData} = React.useContext(TripDebriefContext);
  /**
   *  Destructure snapshotData, need to add || ({} as MasterTripDebriefSnapshot) for typescript, since
   *  snapshotData is nullable.
   */
  const {vehicle} =
    masterTripDebriefData?.snapshotData || ({} as MasterTripDebriefSnapshot);

  const list: TripDebriefSummaryCardList = React.useMemo(
    () => [
      {
        primary: 'Registration number',
        secondary: vehicle?.registrationNumber,
        type: 'string',
        changed: false,
      },
      {
        primary: 'Fleet number',
        secondary: vehicle?.fleetNumber,
        type: 'string',
        changed: false,
      },
      {
        primary: 'Type',
        secondary: vehicle?.type?.name,
        type: 'string',
        changed: false,
      },
      {
        primary: 'Capacity',
        secondary:
          vehicle?.type?.capacityDimensions &&
          vehicle.type.capacityDimensions.length > 0
            ? vehicle.type.capacityDimensions
                .map(
                  (dimension) =>
                    `${dimension.capacity}${dimension.unitOfMeasure}`
                )
                .join(', ')
            : '-',
        type: 'string',
        changed: false,
      },
      {
        primary: 'Compartments',
        secondary: vehicle?.type?.compartments
          ? vehicle.type.compartments.length
          : '-',
        type: 'string',
        changed: false,
      },
    ],
    [vehicle]
  );

  return (
    <TripDebriefSummaryCard title="Vehicle" list={list}>
      <TripDebriefSummaryVehicleTruck />
    </TripDebriefSummaryCard>
  );
};
