import React from 'react';
import {DriverDocumentDetails} from './DriverDocumentDetails';
import {ITemplateTabControllerTab, TemplateTabs} from '../../factory/template';
import {DriverDocumentContext} from './DriverDocumentContext';

export const DriverDocument: React.FC = () => {
  const {driverDocumentId, loading} = React.useContext(DriverDocumentContext);

  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {title: 'Details', element: <DriverDocumentDetails />},
  ]);

  const path = React.useMemo(
    () => `/driverdocumentlist/${driverDocumentId}`,
    [driverDocumentId]
  );

  return <TemplateTabs tabs={tabs} path={path} loading={loading} />;
};
