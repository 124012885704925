import React from 'react';
import {Theme} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import InfoIcon from '@mui/icons-material/Info';
import ListItemText from '@mui/material/ListItemText';
import {Loader} from '../../../loader';
import {TaskContext} from '../../TaskContext';

const styles = createStyles((theme: Theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

const TextPayloadCard: React.FC = () => {
  const {task} = React.useContext(TaskContext);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (task) {
      setLoading(false);
    }
  }, [task]);

  return (
    <div style={{paddingTop: 10}}>
      <Card>
        <CardContent>
          {loading ? (
            <Loader />
          ) : (
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <InfoIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={'Payload'}
                secondary={task != null ? task.payload : 'No Data'}
              />
            </ListItem>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default withStyles(styles)(TextPayloadCard);
