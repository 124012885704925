import {makeStyles, createStyles} from '@mui/styles';
import {Theme} from '@mui/material';

const ContractSetupWizardTruckStyles = (_theme: Theme) =>
  createStyles({
    completedStep: {cursor: 'pointer !important'},
    incompleteStep: {cursor: 'default'},
  });

export const useContractSetupWizardTruckStyles = makeStyles(
  ContractSetupWizardTruckStyles,
  {name: 'ContractSetupWizardTruckStyles'}
);
