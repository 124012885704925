import React from 'react';
import {Collapse, Stack} from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {generateUid} from '../../../service/Util';
import VoiceService from '../../../service/VoiceService';
import {useAppNotifications} from '../../../contexts';
import ReactAudioPlayer from 'react-audio-player';
import {Loader} from '../../loader';

export const BroadcastSingleMessage = () => {
  const notify = useAppNotifications();
  const [generating, setGenerating] = React.useState<boolean>(false);
  const [generatedUrl, setGeneratedUrl] = React.useState<string | undefined>();
  const [messageIdentifier, setMessageIdentifier] = React.useState<
    string | undefined
  >();
  const [number, setNumber] = React.useState<string | undefined>();
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const messageHeadingRef = React.useRef<HTMLInputElement>(null);
  const messageRef = React.useRef<HTMLInputElement>(null);
  const numberRef = React.useRef<HTMLInputElement>(null);

  const cleanup = () => {
    setMessageIdentifier(undefined);
    if (messageRef.current != null) {
      messageRef.current.value = '';
    }
    if (messageHeadingRef.current != null) {
      messageHeadingRef.current.value = '';
    }
    if (numberRef.current != null) {
      numberRef.current.value = '';
    }
  };

  const validateFields = React.useCallback(() => {
    let isValid = true;
    const messages: string[] = [];

    if (!messageHeadingRef.current?.value) {
      messages.push('Message heading is required.');
      isValid = false;
    }

    if (!messageRef.current?.value) {
      messages.push('Custom message is required.');
      isValid = false;
    }

    if (!number) {
      messages.push('Phone number is required.');
      isValid = false;
    }

    if (!isValid) {
      notify('warning', messages.join(' '));
    }

    return isValid;
  }, [number, notify]);

  const handleSubmit = React.useCallback(async () => {
    if (!validateFields()) {
      return;
    }

    setSubmitting(true);
    try {
      if (
        messageHeadingRef.current != null &&
        messageRef.current != null &&
        number != null &&
        messageIdentifier != null
      ) {
        await VoiceService.createDirectCall({
          heading: messageHeadingRef.current.value,
          identifier: messageIdentifier,
          message: messageRef.current.value,
          toNumber: number,
        });
        notify('success', 'Broadcast sent');
        cleanup();
      } else {
        notify('warning', 'Generate message before sending');
      }
    } catch (e) {
      notify('error', 'Failed to send broadcast');
    } finally {
      setSubmitting(false);
    }
  }, [messageIdentifier, validateFields, number, notify]);

  React.useEffect(() => {
    return cleanup;
  }, []);

  const generateAudioFile = async () => {
    if (!validateFields()) {
      return;
    }

    setGenerating(true);
    try {
      const identifier = `${generateUid()}.mp3`;
      const data = await VoiceService.generateVoiceMessage({
        identifier,
        message: messageRef?.current?.value,
      });
      setGeneratedUrl(data.data);
      setMessageIdentifier(identifier);
    } catch (e) {
      notify('error', 'Failed to generate message.');
    } finally {
      setGenerating(false);
    }
  };

  return (
    <Stack spacing={2} py={2} alignItems={'flex-start'}>
      <TextField
        style={{width: 200}}
        label="Number (i.e +27721231234)"
        placeholder="+27721231234"
        value={number}
        onChange={(e) => setNumber(e.target.value)}
        inputRef={numberRef}
      />
      <TextField
        style={{width: 200}}
        label="Message Heading"
        placeholder="Safety Message #1..."
        inputRef={messageHeadingRef}
      />
      <Stack direction="row" spacing={1} alignItems="center">
        <TextField
          style={{width: 600}}
          helperText="Hi Driver, please ..."
          label="Custom Message Field"
          multiline={true}
          rows={2}
          inputRef={messageRef}
        />
        <Button
          disabled={generating || submitting}
          onClick={generateAudioFile}
          startIcon={<Loader size={25} loading={generating} />}
        >
          <Typography>{generating ? 'Generating...' : 'Generate'}</Typography>
        </Button>
      </Stack>
      <Collapse in={generatedUrl != null}>
        <ReactAudioPlayer src={generatedUrl} controls preload="none" />
      </Collapse>
      <Button
        onClick={handleSubmit}
        fullWidth={false}
        variant="contained"
        size="small"
      >
        Send Broadcast
      </Button>
    </Stack>
  );
};
