import React from 'react';
import {Tab, Tabs} from '@mui/material';
import {
  CriticalEventDump,
  OperationalEventDump,
} from '@onroadvantage/onroadvantage-api';
import {TripEventListProps} from './TripEventList';
import {TripEventCommentList} from './TripEventCommentList';
import {useTripEventListStyles} from './TripEventList.style';

export type TripEventTabTypes = 'Comments';

export interface TripEventProps {
  row: CriticalEventDump | OperationalEventDump;
  type: TripEventListProps['type'];
}

export const TripEventListDetail: React.FC<TripEventProps> = ({row, type}) => {
  const classes = useTripEventListStyles();
  const [tabValue, setTabValue] = React.useState<TripEventTabTypes>('Comments');

  const handleTabChange = React.useCallback(
    (_event: unknown, value: TripEventTabTypes) => {
      setTabValue(value);
    },
    []
  );

  return (
    <>
      <Tabs
        className={classes.tabBar}
        value={tabValue}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Comments" value="Comments" />
      </Tabs>
      {tabValue === 'Comments' ? (
        <TripEventCommentList event={row} type={type} />
      ) : null}
    </>
  );
};
