import React from 'react';
import {DashboardForm} from './DashboardForm';
import {ITemplateTabControllerTab, TemplateTabs} from '../../factory/template';
import {DashboardContext} from './DashboardContext';

export const Dashboard: React.FC = () => {
  const {dashboardId, loading} = React.useContext(DashboardContext);

  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {title: 'Details', element: <DashboardForm />},
  ]);

  const path = React.useMemo(
    () => `/dashboardlist/${dashboardId}`,
    [dashboardId]
  );

  return <TemplateTabs tabs={tabs} path={path} loading={loading} />;
};
