import React from 'react';
import {PlanningSkillVehicleGroupMinimal} from '@onroadvantage/onroadvantage-api';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../../factory/template';
import {
  PlanningSkillVehicleGroupListContext,
  usePlanningSkillVehicleGroupListContext,
} from './PlanningSkillVehicleGroupListContext';
import {PlanningSkillContext} from '../PlanningSkillContext';

export const PlanningSkillVehicleGroupList: React.FC = () => {
  const {planningSkill} = React.useContext(PlanningSkillContext);
  const {loadList, cleanupList} = usePlanningSkillVehicleGroupListContext();

  const [config] = React.useState<
    TemplateTableConfig<PlanningSkillVehicleGroupMinimal>
  >({
    columns: [
      {
        name: 'name',
        label: 'Name',
        type: 'string',
        enableEditing: true,
        enableAutocomplete: true,
        enableMulti: true,
        model: 'VehicleGroup',
      },
    ],
    enableFilter: true,
    enableSort: true,
    deleteMessage: (row) => ({
      title: 'Planning Skill Vehicle Group',
      items: {PlanningSkill: planningSkill?.name, Name: row.name},
    }),
    identifier: 'PLANNING_SKILL_VEHICLE_GROUP_LIST',
  });

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [cleanupList, loadList]);

  return (
    <TemplateTableWrapper
      config={config}
      context={PlanningSkillVehicleGroupListContext}
    />
  );
};
