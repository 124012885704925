import React from 'react';
import {useParams} from 'react-router-dom';
import {useTripDebriefConfigContext} from './tripDebriefConfigContext';

interface IParams {
  masterTripDebriefConfigId?: string;
}

export const TripDebriefConfigIdHandler: React.FC = () => {
  const {setMasterTripDebriefConfigId} = useTripDebriefConfigContext();

  const {masterTripDebriefConfigId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = masterTripDebriefConfigId
      ? parseInt(masterTripDebriefConfigId)
      : undefined;
    setMasterTripDebriefConfigId(parsedId);
    return () => setMasterTripDebriefConfigId(undefined);
  }, [masterTripDebriefConfigId, setMasterTripDebriefConfigId]);

  return null;
};
