import React from 'react';
import {FormikDateTimePickerProps} from '../../formik';
import {MobileDateTimePicker} from '@mui/x-date-pickers';
import {useFormikContext} from 'formik';
import moment, {Moment} from 'moment/moment';
import {TripDebriefDateTimePickerField} from './TripDebriefDateTimePickerField';
import {TripDebriefContext} from '../TripDebriefContext';
import {MasterTripDebriefSnapshotData} from '@onroadvantage/onroadvantage-api';

export const TripDebriefDateTimePickerContext =
  React.createContext<FormikDateTimePickerProps>({name: ''});

export const TripDebriefDateTimePicker: React.FC<FormikDateTimePickerProps> = (
  props
) => {
  return (
    <TripDebriefDateTimePickerContext.Provider value={props}>
      <TripDebriefDateTimePickerComponent />
    </TripDebriefDateTimePickerContext.Provider>
  );
};

export const TripDebriefDateTimePickerComponent: React.FC = () => {
  const {masterTripDebriefData} = React.useContext(TripDebriefContext);
  const {approvedForBilling} =
    masterTripDebriefData || ({} as MasterTripDebriefSnapshotData);
  const {name, disableDefaultValue, disabled, ...props} = React.useContext(
    TripDebriefDateTimePickerContext
  );
  const {isSubmitting, getFieldMeta, getFieldHelpers} = useFormikContext();
  /**
   * Get the current fields value from the current fields relevant overrideValue. We will also use the overrideField for
   * the touched, error and other ...meta states, as this is the actual field being edited. The snapshot is just to get
   * its initialValue
   */
  const {
    touched,
    error,
    initialValue: overrideInitialValue,
    value,
    ...meta
  } = getFieldMeta(`override.${name}`);
  /** We also want to set the relevant overrideValue's state as that is what's going to be updated and sent to the api */
  const {setValue} = getFieldHelpers(`override.${name}`);

  const [parsedValue, setParsedValue] = React.useState<Moment | null>(null);

  React.useEffect(() => {
    if (
      value instanceof moment ||
      value instanceof Date ||
      typeof value === 'string'
    ) {
      setParsedValue(moment(value));
    } else if (!value && !disableDefaultValue) {
      setParsedValue(moment());
      setValue(moment());
    }
    return () => setParsedValue(null);
    // eslint-disable-next-line
  }, [value]);

  const handleChange = React.useCallback(
    (date: Date | null) => {
      setValue(date);
      setParsedValue(moment(date));
    },
    [setValue]
  );

  return (
    <div>
      <MobileDateTimePicker
        {...props}
        {...meta}
        disabled={disabled || isSubmitting || approvedForBilling}
        ampm={false}
        onChange={handleChange}
        value={parsedValue?.toDate() ?? null}
        renderInput={TripDebriefDateTimePickerField}
      />
    </div>
  );
};
