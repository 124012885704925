import React from 'react';
import {useHistory} from 'react-router-dom';
import {
  ApiNotificationConfigGetRequest,
  Listing,
  NotificationConfig,
  NotificationConfigList,
  TaskNotificationConfig,
  TaskNotificationConfigPost,
} from '@onroadvantage/onroadvantage-api';
import {useAppNotifications} from '../../../contexts';
import {taskNotificationConfigApi} from '../../../api';
import {
  TemplateTableContextProps,
  TOnInlineAdd,
  TOnInlineEdit,
  useTemplateTable,
} from '../../../factory/template';
import {NotificationConfigContext} from '../NotificationConfigContext';

export interface NotificationConfigTaskContextProps
  extends Omit<
    TemplateTableContextProps<TaskNotificationConfig, NotificationConfigList>,
    'loadList'
  > {
  loadNotificationConfigTask: () => Promise<void>;
  setNotificationConfigTaskId: (value: number | undefined) => void;
  submitting?: boolean;
  loadingSingleItem: boolean;
  notificationConfigTask?: TaskNotificationConfig;
  notificationConfigTaskId?: number;
}

export const NotificationConfigTaskContext =
  React.createContext<NotificationConfigTaskContextProps>({
    // Template Table Defaults
    loading: false,
    list: [],
    currentPage: 1,
    // NotificationConfig
    loadNotificationConfigTask: async () => {},
    setNotificationConfigTaskId: () => null,
    submitting: false,
    loadingSingleItem: false,
  });

interface NotificationConfigTaskContextProviderProps {
  notificationConfigId?: number;
}

export const NotificationConfigTaskContextProvider: React.FC<
  NotificationConfigTaskContextProviderProps
> = ({children}) => {
  const history = useHistory();
  const notify = useAppNotifications();
  const {
    notificationConfig,
    notificationConfigId,
    loadNotificationConfig,
    loadingSingleItem: loadingNotificationConfig,
  } = React.useContext(NotificationConfigContext);
  // Template Table
  const [
    {
      // States
      currentPage,
      itemTotal,
      hasPermission,
      pageSize,
      pageTotal,
      loadingSingleItem,
    },
    {
      // Handlers
      handlePageSizeCountsChange,
      // Setters
      setLoadingSingleItem,
    },
  ] = useTemplateTable<NotificationConfig, ApiNotificationConfigGetRequest>({
    editPermission: 'Edit NotificationConfig',
    addPermission: 'Add NotificationConfig',
    deletePermission: 'Delete NotificationConfig',
    downloadPermission: 'NotificationConfig ListDownload',
    viewPermission: 'NotificationConfig List',
  });
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const handleDelete = React.useCallback(
    async (row: TaskNotificationConfig) => {
      setSubmitting(true);
      try {
        if (row.id) {
          await taskNotificationConfigApi.apiTaskNotificationConfigTaskNotificationConfigIdDelete(
            {
              taskNotificationConfigId: row.id,
            }
          );
          await loadNotificationConfig();

          notify('success', 'Deleted Task Template Trigger');
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to delete Task Template Trigger');
      } finally {
        setSubmitting(false);
      }
    },
    [loadNotificationConfig, notify]
  );

  const handleInlineAdd = React.useCallback<TOnInlineAdd>(
    async (changes) => {
      setSubmitting(true);
      try {
        const values = changes[0] as TaskNotificationConfig;
        const taskTemplate = values?.contractTaskTemplate as
          | Listing
          | undefined;
        if (notificationConfigId && taskTemplate?.value) {
          const requestObj: TaskNotificationConfigPost = {
            notificationConfigId,
            contractTaskTemplateId: taskTemplate.value,
            notifyContractContactGroups: !!values.notifyContractContactGroups,
            notifyCustomerContactGroups: !!values.notifyCustomerContactGroups,
            notifyDriver: !!values.notifyDriver,
            notifyNextStop: !!values.notifyNextStop,
            notifyNodeContactGroups: !!values.notifyNodeContactGroups,
            notifyVehicleGroupContactGroups:
              !!values.notifyVehicleGroupContactGroups,
            notifyOffloadingStops: !!values.notifyOffloadingStops,
            notifyVehicle: !!values.notifyVehicle,
            notifyWeb: !!values.notifyWeb,
          };
          await taskNotificationConfigApi.apiTaskNotificationConfigPost({
            body: requestObj,
          });
          notify(
            'success',
            'Notification Config link to Task Template Trigger updated.'
          );
          await loadNotificationConfig();
        }
      } catch (e) {
        notify(
          'error',
          e.message ??
            'Failed to link Task Template Trigger to Notification Config.'
        );
      } finally {
        setSubmitting(false);
      }
    },
    [loadNotificationConfig, notificationConfigId, notify]
  );

  const handleInlineEdit = React.useCallback<TOnInlineEdit>(
    async (changes) => {
      setSubmitting(true);
      try {
        for (const item of changes) {
          const taskNotificationConfigId = parseInt(item.id);
          const values = item.newValues as Partial<TaskNotificationConfig>;
          const requestObj: {
            [key: string]: unknown;
          } = {};
          Object.keys(values).forEach((key) => {
            requestObj[key] =
              values[key as keyof Partial<TaskNotificationConfig>] ?? undefined;
          });
          if (taskNotificationConfigId) {
            await taskNotificationConfigApi.apiTaskNotificationConfigTaskNotificationConfigIdPatch(
              {
                taskNotificationConfigId,
                body: requestObj,
              }
            );
            notify('success', 'successfully edited Task Template Trigger.');
          }
        }
        await loadNotificationConfig();
      } catch (e) {
        notify('error', e.message ?? 'Failed to edit task template trigger ');
      } finally {
        setSubmitting(false);
      }
    },
    [loadNotificationConfig, notify]
  );

  const handleNavigate = React.useCallback(
    (row) =>
      history.push(
        `/notificationconfiglist/${notificationConfigId}/task/${row.id}`
      ),
    [history, notificationConfigId]
  );
  const handleRefresh = React.useCallback(
    async () => await loadNotificationConfig(),
    [loadNotificationConfig]
  );

  // Forms
  const [notificationConfigTask, setNotificationConfigTask] = React.useState<
    TaskNotificationConfig | undefined
  >();
  const [notificationConfigTaskId, setNotificationConfigTaskId] =
    React.useState<number | undefined>();

  const loadNotificationConfigTask = React.useCallback(async () => {
    setLoadingSingleItem(true);
    try {
      if (notificationConfigTaskId) {
        const response =
          await taskNotificationConfigApi.apiTaskNotificationConfigTaskNotificationConfigIdGet(
            {
              taskNotificationConfigId: notificationConfigTaskId,
            }
          );
        setNotificationConfigTask(response);
      } else {
        setNotificationConfigTask(undefined);
      }
    } catch (e) {
      notify('error', e.message ?? 'Failed to load notification config task');
    } finally {
      setLoadingSingleItem(false);
    }
  }, [setLoadingSingleItem, notificationConfigTaskId, notify]);

  const filterTaskNotificationConfigs = React.useMemo(() => {
    return (
      notificationConfig?.taskNotificationConfigs?.filter(
        (config) =>
          config.contractTaskTemplate &&
          config.contractTaskTemplate.taskTemplate
      ) ?? []
    );
  }, [notificationConfig?.taskNotificationConfigs]);

  const value: NotificationConfigTaskContextProps = {
    // Template Table
    list: filterTaskNotificationConfigs,
    hasPermission,
    loading: loadingNotificationConfig || submitting,
    loadingSingleItem,
    currentPage,
    itemTotal,
    pageSize,
    pageTotal,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onInlineAdd: hasPermission.edit ? handleInlineAdd : undefined,
    onInlineEdit: hasPermission.edit ? handleInlineEdit : undefined,
    onNavigate: handleNavigate,
    onDelete: hasPermission.delete ? handleDelete : undefined,
    onRefresh: handleRefresh,
    // Forms
    loadNotificationConfigTask,
    setNotificationConfigTaskId,
    notificationConfigTask,
    notificationConfigTaskId,
  };

  React.useEffect(() => {
    loadNotificationConfigTask();
    return () => setNotificationConfigTask(undefined);
  }, [loadNotificationConfigTask]);

  return (
    <NotificationConfigTaskContext.Provider value={value}>
      {children}
    </NotificationConfigTaskContext.Provider>
  );
};
