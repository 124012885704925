import React from 'react';
import {InputAdornment, TextField} from '@mui/material';
import {MasterTripCreditResponse} from '@onroadvantage/onroadvantage-api';
import {useTripContext} from '../../tripContext';
import {masterTripCreditApi} from '../../../../api';
import {useAppNotifications} from '../../../../contexts';
import {Loader} from '../../../loader';
import {RoleService} from '../../../../service';
import {TripSummaryEditDialog} from './TripSummaryEditDialog';

export const TripSummaryEditCredit: React.FC = () => {
  const notify = useAppNotifications();
  const {masterTrip, masterTripId, updateMasterTripPartial} = useTripContext();
  /** Destructure credit, need to add || ({} as MasterTripCreditResponse) for typescript, since credit is nullable. */
  const {distance: creditDistance} =
    masterTrip?.credit || ({} as MasterTripCreditResponse);
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [creditDistanceValue, setCreditDistanceValue] = React.useState<
    number | undefined
  >(creditDistance);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const parsedValue = parseFloat(event.target.value);
      setCreditDistanceValue(isNaN(parsedValue) ? undefined : parsedValue);
    },
    []
  );

  const handleSubmit = React.useCallback(async () => {
    setSubmitting(true);
    try {
      if (masterTripId) {
        const response = await masterTripCreditApi.apiTripCreditPost({
          body: {masterTripId, distance: creditDistanceValue},
        });
        if (response.id) {
          notify('success', 'Trip Number Updated');
          updateMasterTripPartial({credit: response});
        } else notify('error', 'Trip Number already exists.');
      }
    } catch (e) {
      notify('error', e.message ?? 'Failed to update trip number');
    } finally {
      setSubmitting(false);
    }
  }, [creditDistanceValue, masterTripId, notify, updateMasterTripPartial]);

  React.useEffect(() => {
    setCreditDistanceValue(creditDistance);
    return () => setCreditDistanceValue(undefined);
  }, [creditDistance]);

  return (
    <TripSummaryEditDialog
      title="Trip Credit KM"
      hasPermission={RoleService.hasPermission('Edit Credit KM', 'Edit')}
      loading={submitting}
      onAccept={handleSubmit}
    >
      <TextField
        disabled={submitting}
        value={creditDistanceValue}
        onChange={handleChange}
        margin="normal"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Loader size={32} loading={submitting} />
            </InputAdornment>
          ),
        }}
        fullWidth
      />
    </TripSummaryEditDialog>
  );
};
