import React from 'react';
import {
  DateRangePicker,
  DateRangePickerShape,
  FocusedInputShape,
} from 'react-dates';
import {useFormikContext} from 'formik';
import {InputLabel, FormHelperText, Button} from '@mui/material';
import {CheckBox, CheckBoxOutlineBlank} from '@mui/icons-material';
import moment, {Moment} from 'moment';
import {ReportParameterDateRange} from '../ReportScheduleForm/parameter';
import {ReportGenerateFormSchemaType} from '../reportGenerate';
import {AutocompleteOptionType} from '../../autocomplete';
import {useReportParameterDateRangePickerStyles} from './ReportParameterDateRangePicker.style';
import clsx from 'clsx';
import {getDaterange} from '../helpers';
import {DateTime} from 'luxon';

export interface ReportParameterDateRangePickerProps {
  submitting?: boolean;
  disableCustomDateRange?: boolean;
  title?: string;
}

export const ReportParameterDateRangePicker: React.FC<
  ReportParameterDateRangePickerProps
> = ({submitting, disableCustomDateRange, title}) => {
  const classes = useReportParameterDateRangePickerStyles();
  const [startDate, setStartDate] = React.useState<Moment | null>(null);
  const [endDate, setEndDate] = React.useState<Moment | null>(null);
  const [isCustomRange, setCustomRange] = React.useState<boolean>(
    !!disableCustomDateRange
  );
  const [focusedInput, setFocusedInput] =
    React.useState<FocusedInputShape | null>(null);

  const {setFieldValue, ...formik} =
    useFormikContext<ReportGenerateFormSchemaType>();

  const {value: endDateValue, error: endDateError} =
    formik.getFieldMeta('endDate');
  const {value: startDateValue, error: startDateError} =
    formik.getFieldMeta('startDate');

  const {value: dateListValue} =
    formik.getFieldMeta<AutocompleteOptionType>('date');

  const error = React.useMemo(
    () => endDateError || startDateError,
    [endDateError, startDateError]
  );

  const handleDatesChange = React.useCallback<
    DateRangePickerShape['onDatesChange']
  >(
    (dates) => {
      if (dates.endDate && dates.startDate) {
        const startDateTime = DateTime.fromJSDate(
          dates.startDate.toDate()
        ).startOf('day');
        const endDateTime = DateTime.fromJSDate(dates.endDate.toDate()).endOf(
          'day'
        );
        setStartDate(moment(startDateTime.toJSDate()));
        setEndDate(moment(endDateTime.toJSDate()));
        setFieldValue('startDate', moment(startDateTime.toJSDate()));
        setFieldValue('endDate', moment(endDateTime.toJSDate()));
      }
    },
    [setFieldValue]
  );

  const handleCheckOutsideRange = React.useCallback(
    (date: Moment) => {
      switch (focusedInput) {
        case 'startDate':
          return (
            date.hours(0).minute(0).second(0).millisecond(0) >
            (endDateValue as Moment).hours(0).minute(0).second(0).millisecond(0)
          );
        case 'endDate':
          return (
            date.hours(0).minute(0).second(0).millisecond(0) <
            (startDateValue as Moment)
              .hours(0)
              .minute(0)
              .second(0)
              .millisecond(0)
          );
        default:
          return false;
      }
    },
    [endDateValue, focusedInput, startDateValue]
  );

  React.useEffect(() => {
    const range = getDaterange(`${dateListValue?.value}`);
    if (range && !disableCustomDateRange) {
      setStartDate(moment(range.startDate));
      setEndDate(moment(range.endDate));
      setFieldValue('startDate', moment(range.startDate));
      setFieldValue('endDate', moment(range.endDate));
    }
  }, [disableCustomDateRange, dateListValue, setFieldValue]);

  const handleCustomRangeToggle = React.useCallback(() => {
    setCustomRange((prevIsCustomRange) => !prevIsCustomRange);
  }, []);

  if (disableCustomDateRange) {
    return (
      <div className={classes.root}>
        <div className={classes.picker}>
          <div
            className={clsx(classes.customPickerRoot, {
              [classes.customPickerRootError]: error,
            })}
          >
            <InputLabel shrink>Date Range</InputLabel>
            <DateRangePicker
              endDate={endDate ?? null}
              endDateId="endDate"
              isOutsideRange={() => false}
              startDate={startDate}
              startDateId="startDate"
              focusedInput={focusedInput}
              onFocusChange={setFocusedInput}
              onDatesChange={() => {}}
              customArrowIcon={() => <div>to</div>}
              noBorder
              small
              displayFormat="DD-MM-YYYY"
              minimumNights={0}
              withPortal
              disabled
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.picker}>
        {isCustomRange ? (
          <div
            className={clsx(classes.customPickerRoot, {
              [classes.customPickerRootError]: error,
            })}
          >
            <InputLabel shrink>{title ?? 'Created Date'}</InputLabel>
            <div>
              <DateRangePicker
                endDate={endDate}
                endDateId="endDate"
                isOutsideRange={handleCheckOutsideRange}
                startDate={startDate}
                startDateId="startDate"
                onDatesChange={handleDatesChange}
                focusedInput={focusedInput}
                onFocusChange={setFocusedInput}
                customArrowIcon={() => <div>to</div>}
                noBorder
                small
                displayFormat="DD-MM-YYYY"
                minimumNights={0}
                withPortal
              />
              <div className={classes.customPickerDivider} />
              {error && (
                <FormHelperText error={!!error}>{error}</FormHelperText>
              )}
            </div>
          </div>
        ) : (
          <ReportParameterDateRange
            disabled={submitting}
            id="date"
            label="Created Date"
            name="date"
            key="date"
          />
        )}
      </div>
      <div className={classes.customRangeButtonContainer}>
        <Button
          color={isCustomRange ? 'primary' : 'inherit'}
          variant={'contained'}
          onClick={handleCustomRangeToggle}
          startIcon={isCustomRange ? <CheckBox /> : <CheckBoxOutlineBlank />}
        >
          Custom Range
        </Button>
      </div>
    </div>
  );
};
