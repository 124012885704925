import React from 'react';
import {DataTypeProvider} from '@devexpress/dx-react-grid';
import {useTemplateTableActions} from '../../providers';
import {Checkbox, Radio} from '@mui/material';
import {useInternalTableContext} from '../../TemplateTable';
import {useTableActionSelectStyles} from './TableActionSelect.style';

export const TableActionSelect: React.FC<
  DataTypeProvider.ValueFormatterProps
> = ({row}) => {
  const classes = useTableActionSelectStyles();
  const {selection, config, loading} = useInternalTableContext();
  const {onSelectionChange} = useTemplateTableActions();
  const isSelected = React.useMemo(
    () => !!selection?.find(({id}) => id === row.id),
    [row.id, selection]
  );
  const isDisabled = React.useMemo(
    () => config.getSelectableRow && !config.getSelectableRow(row),
    [config, row]
  );

  const handleSelect = React.useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>) => {
      if (onSelectionChange && selection) {
        onSelectionChange(
          selection.includes(row)
            ? selection.filter(({id}) => id !== row.id)
            : [...selection, row]
        );
      }
    },
    [onSelectionChange, row, selection]
  );

  if (!selection || !onSelectionChange) {
    return null;
  }

  if (config.selectionType === 'radio') {
    return (
      <Radio
        checked={isSelected}
        onChange={handleSelect}
        disabled={isDisabled || loading}
      />
    );
  }

  return (
    <Checkbox
      icon={isDisabled ? <span className={classes.disabledIcon} /> : undefined}
      checked={isSelected}
      onChange={handleSelect}
      disabled={isDisabled || loading}
    />
  );
};
