import React from 'react';
import {CustomerContext} from './CustomerContext';
import {useParams} from 'react-router-dom';

interface IParams {
  customerId?: string;
}

export const CustomerIdHandler: React.FC = () => {
  const {setCustomerId} = React.useContext(CustomerContext);

  const {customerId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = customerId ? parseInt(customerId) : undefined;
    setCustomerId(parsedId);
    return () => setCustomerId(undefined);
  }, [customerId, setCustomerId]);

  return null;
};
