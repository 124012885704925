import React from 'react';
import {SitePostContext} from './SitePostContext';
import {
  IColumnConfig,
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../../factory/template';
import {AssociateNode} from '@onroadvantage/onroadvantage-api';
import {SiteContext} from '../SiteContext';

export const SitePostList: React.FC = () => {
  const {site} = React.useContext(SiteContext);
  const {loadList, cleanupList} = React.useContext(SitePostContext);

  const config: TemplateTableConfig<AssociateNode> = React.useMemo(() => {
    const baseColumns: IColumnConfig<AssociateNode>[] = [
      {
        name: 'node',
        label: 'Site Name',
        type: 'string',
        model: ['Node'],
        enableAutocomplete: true,
        enableEditing: false,
        getValue: ({node}) => node?.name,
      },
      {
        name: 'taskTemplateNodeType',
        label: 'Task Template Node Type',
        type: 'string',
        model: ['NodeType'],
        enableAutocomplete: true,
        enableEditing: false,
      },
      {
        name: 'sequence',
        label: 'Sequence',
        type: 'number',
        enableEditing: true,
      },
    ];

    // Conditionally add additional columns for 'Default' site type for future use
    const additionalColumns: IColumnConfig<AssociateNode>[] =
      site?.type === 'DEFAULT'
        ? [
            {
              name: 'serviceType',
              label: 'Service Type',
              type: 'string',
              enableEditing: true,
              enableSelect: true,
              selectOptions: ['LOADING', 'OFFLOADING'],
            },
          ]
        : [];

    return {
      columns: [...baseColumns, ...additionalColumns],
      enableFilter: true,
      enableSort: true,
      deleteMessage: (row) => ({
        title: 'Post Site',
        items: {
          SiteName: row.node?.name,
          TaskTemplateNodeType: row.taskTemplateNodeType,
          Sequence: row.sequence,
        },
      }),
      identifier: 'SITE_POST_LIST',
    };
  }, [site?.type]);

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [loadList, cleanupList]);

  return <TemplateTableWrapper config={config} context={SitePostContext} />;
};
