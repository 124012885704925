import React from 'react';
import {Paper, Tab, Tabs} from '@mui/material';
import {useReportStyles} from './Report.style';
import {RoleService} from '../../service';
import {Loader} from '../loader';
import {ReportContext, ReportContextProps} from './ReportContext';
import {ReportScheduleList} from './reportSchedule';
import {useHistory, useLocation} from 'react-router-dom';
import {getQueryStringValue} from '../../service/Util';
import {ReportGenerate} from './reportGenerate';
import {ReportForm} from './ReportForm';

export const Report: React.FC = () => {
  const classes = useReportStyles();
  const {search} = useLocation();
  const history = useHistory();
  const {loading, reportId} =
    React.useContext<ReportContextProps>(ReportContext);

  const [hasAddPermission] = React.useState<boolean>(
    RoleService.hasPermission('Add Report', 'Add')
  );
  const [tabValue, setTabValue] = React.useState<number>(0);

  // adjust the first tab if the user does not have correct permissions
  const first = hasAddPermission ? 0 : -1;

  const handleTabChange = (event: any, value: number) => {
    history.push(`/reportlist/${reportId}?tab=${value}`);
  };

  React.useEffect(() => {
    const tab = getQueryStringValue(search, 'tab');
    if (tab) {
      setTabValue(parseInt(tab ?? '0', 10));
    } else {
      setTabValue(0);
    }
  }, [search]);

  // get the content
  let content: React.ReactNode;
  if (loading) {
    content = <Loader loading={loading} />;
  } else {
    content = (
      <>
        {tabValue === first + 0 && hasAddPermission && <ReportForm />}
        {tabValue === first + 1 && <ReportGenerate />}
        {tabValue === first + 2 && <ReportScheduleList />}
      </>
    );
  }

  return (
    <Paper className={classes.root}>
      <Tabs
        className={classes.tabs}
        value={tabValue}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
      >
        {hasAddPermission && <Tab label="Details" />}
        <Tab label="Generate" />
        <Tab label="Schedules" />
      </Tabs>
      {content}
    </Paper>
  );
};
