import React from 'react';
import {TemplateTabs} from '../../../factory/template';
import {useContractCriticalEventTypeConfigContext} from './ContractCriticalEventTypeConfigContext';
import {ContractCriticalEventTypeConfigDetailsForm} from './ContractCriticalEventTypeConfigDetailsForm';
import {ContractCriticalEventTypeConfigReasonCodeList} from './ContractCriticalEventTypeConfigReasonCodeList';
import {ContractCriticalEventTypePromptList} from './contractCriticalEventTypeConfigPrompt/ContractCriticalEventTypePromptList';
import {ContractCriticalEventTypeConfigEscalationList} from './ContractCriticalEventTypeConfigEscalationList';

const tabs = [
  {
    title: 'Details',
    element: <ContractCriticalEventTypeConfigDetailsForm />,
  },
  {
    title: 'Prompt',
    element: <ContractCriticalEventTypePromptList />,
  },
  {
    title: 'Escalation',
    element: <ContractCriticalEventTypeConfigEscalationList />,
  },
  {
    title: 'Reason Codes',
    element: <ContractCriticalEventTypeConfigReasonCodeList />,
  },
];

export const ContractCriticalEventTypeConfig = () => {
  const {loading, contractCriticalEventTypeConfigId} =
    useContractCriticalEventTypeConfigContext();

  return (
    <TemplateTabs
      path={`/contracteventtypeconfigs/critical/${contractCriticalEventTypeConfigId}`}
      tabs={tabs}
      loading={loading}
    />
  );
};
