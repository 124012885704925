import {makeStyles, createStyles} from '@mui/styles';
import {Theme} from '@mui/material';

const PlanningBoardGanttCompartmentTruckStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  });

export const usePlanningBoardGanttCompartmentTruckStyles = makeStyles(
  PlanningBoardGanttCompartmentTruckStyles,
  {name: 'PlanningBoardGanttCompartmentTruckStyles'}
);
