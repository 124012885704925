import React from 'react';
import {Button, Menu, MenuItem} from '@mui/material';
import {useHistory} from 'react-router-dom';

interface EventTypeListConfigContractsProps {
  contractEventTypeConfigs:
    | {
        configId: number;
        contractCode: string | undefined;
        contractName: string | undefined;
      }[]
    | undefined;
  variant: 'critical' | 'operational';
}

export const EventTypeListConfigContracts: React.FC<
  EventTypeListConfigContractsProps
> = ({contractEventTypeConfigs, variant}) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  if (
    contractEventTypeConfigs == null ||
    contractEventTypeConfigs.length === 0
  ) {
    return null;
  }

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuClick = (configId: number) => {
    history.push(`/contracteventtypeconfigs/${variant}/${configId}`);
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        color="inherit"
        style={{textTransform: 'none'}}
        size="small"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {contractEventTypeConfigs
          ?.map(({contractCode}) => contractCode ?? ' - ')
          ?.join(', ')}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {contractEventTypeConfigs?.map(
          ({configId, contractCode, contractName}) => (
            <MenuItem key={configId} onClick={() => handleMenuClick(configId)}>
              {contractCode} - {contractName}
            </MenuItem>
          )
        )}
      </Menu>
    </>
  );
};
