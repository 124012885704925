import React from 'react';
import {
  usePlanningBoardContext,
  usePlanningBoardGanttContext,
} from '../../../planningBoardContext';
import {
  usePlanningBoardBooking,
  usePlanningBoardBookingResponse,
  usePlanningBoardBookingResponseInitial,
} from './usePlanningBoardBooking';

export const PlanningBoardBookingContext =
  React.createContext<usePlanningBoardBookingResponse>(
    usePlanningBoardBookingResponseInitial
  );

export const PlanningBoardBookingContextProvider: React.FC = ({children}) => {
  const {bookings, getVehicle, loadMasterTrips, getMasterTripsWithSameVehicle} =
    usePlanningBoardContext();

  const {selectedGanttItem, onClearSelectedGanttItem} =
    usePlanningBoardGanttContext();

  const values = usePlanningBoardBooking({
    bookings,
    getMasterTripsWithSameVehicle,
    getVehicle,
    loadMasterTrips,
    onClearSelectedGanttItem,
    selectedGanttItem,
  });

  return (
    <PlanningBoardBookingContext.Provider value={values}>
      {children}
    </PlanningBoardBookingContext.Provider>
  );
};

export const usePlanningBoardBookingContext = () =>
  React.useContext(PlanningBoardBookingContext);
