import React from 'react';
import {
  TripDebriefSummaryCard,
  TripDebriefSummaryCardList,
} from './tripDebriefSummaryCard';
import {TripDebriefContext} from '../TripDebriefContext';
import {useHistory} from 'react-router-dom';
import {
  MasterTripDebriefSnapshot,
  MasterTripDebriefSnapshotData,
} from '@onroadvantage/onroadvantage-api';

export const TripDebriefSummaryDetails: React.FC = () => {
  const history = useHistory();
  const {masterTripDebriefData, masterTripId} =
    React.useContext(TripDebriefContext);
  /**
   *  Destructure masterTripData, need to add || ({} as MasterTripDebriefSnapshotData) for typescript, since
   *  masterTripData is nullable.
   */
  const {approvedForBilling, updatedAt} =
    masterTripDebriefData || ({} as MasterTripDebriefSnapshotData);
  /**
   *  Destructure snapshotData, need to add || ({} as MasterTripDebriefSnapshot) for typescript, since
   *  snapshotData is nullable.
   */
  const {tripNumber, contract} =
    masterTripDebriefData?.snapshotData || ({} as MasterTripDebriefSnapshot);

  const list: TripDebriefSummaryCardList = React.useMemo(
    () => [
      {
        primary: 'Trip Number',
        secondary: tripNumber,
        type: 'string',
        changed: false,
      },
      {
        primary: 'Contract',
        secondary: contract?.name,
        type: 'string',
        changed: false,
      },
      {
        primary: 'Updated at',
        secondary: updatedAt,
        type: 'datetime',
        changed: false,
      },
      {
        primary: 'Approved for billing status',
        secondary: approvedForBilling,
        type: 'boolean',
        changed: false,
      },
    ],
    [approvedForBilling, contract?.name, tripNumber, updatedAt]
  );

  const handleNavigate = React.useCallback(() => {
    history.push(`/triplist/${masterTripId}`);
  }, [history, masterTripId]);

  return (
    <TripDebriefSummaryCard
      title="Details"
      list={list}
      onNavigate={handleNavigate}
    />
  );
};
