import React from 'react';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../../factory/template';
import {WebMasterTripDebriefListMasterTrip} from '@onroadvantage/onroadvantage-api';
import {TripDebriefListContext} from './TripDebriefListContext';
import {RoleService} from '../../../service';

export const TripDebriefList: React.FC = () => {
  const {loadList, cleanupList} = React.useContext(TripDebriefListContext);

  const config: TemplateTableConfig<WebMasterTripDebriefListMasterTrip> =
    React.useMemo(
      () => ({
        columns: [
          {
            name: 'tripNumber',
            label: 'Trip Number',
            type: 'string',
          },
          {
            name: 'vehicleRegistrationNumber',
            label: 'Vehicle Registration Number',
            type: 'string',
          },
          {
            name: 'driverName',
            label: 'Driver Name',
            type: 'string',
          },
          {
            name: 'orderNumber',
            label: 'Order Number',
            type: 'string',
          },
          {
            name: 'status',
            label: 'Status',
            type: 'string',
          },
          {
            name: 'debriefed',
            label: 'Debriefed',
            type: 'bool',
            enableFilter: RoleService.hasPermission(
              'TripDebriefTrip List',
              'View'
            ),
          },
          {
            name: 'masterTripDebriefApprovedForBilling',
            label: 'Approved For Billing',
            type: 'bool',
          },
          {
            name: 'tripDistance',
            label: 'Planned Kilometers',
            type: 'string',
            getValue: ({tripDistance}) =>
              tripDistance ? Math.round(tripDistance * 10) / 10 : 'N/A',
            enableSort: false,
            enableFilter: false,
          },
          {
            name: 'debrief',
            label: 'Debriefed Kilometers',
            type: 'number',
            getValue: ({
              masterTripDebriefManualEndOdometer,
              masterTripDebriefManualStartOdometer,
            }) => {
              if (
                masterTripDebriefManualStartOdometer &&
                masterTripDebriefManualEndOdometer
              ) {
                return Math.round(
                  masterTripDebriefManualEndOdometer -
                    masterTripDebriefManualStartOdometer
                );
              }
              return 0;
            },
            enableSort: false,
            enableFilter: false,
          },
          {
            name: 'masterTripId',
            label: 'Master Trip ID',
            type: 'number',
            hidden: true,
          },
          {
            name: 'vehicleFleetNumber',
            label: 'Vehicle Fleet Number',
            type: 'string',
            hidden: true,
          },
          {
            name: 'contractCode',
            label: 'Contract Code',
            type: 'string',
            hidden: true,
          },
          {
            name: 'contractId',
            label: 'Contract ID',
            type: 'number',
            hidden: true,
          },
          {
            name: 'upliftNodeName',
            label: 'Uplift Site',
            type: 'string',
            hidden: true,
          },
          {
            name: 'offloadNodeName',
            label: 'Offload Site',
            type: 'string',
            hidden: true,
          },
          {
            name: 'masterTripDebriefId',
            label: 'Master Trip Debrief ID',
            type: 'number',
            hidden: true,
          },
        ],
        getSelectableRow: ({
          driverName,
          vehicleRegistrationNumber,
          orderNumber,
        }) => {
          return (
            !!driverName &&
            !!vehicleRegistrationNumber &&
            orderNumber !== '' &&
            orderNumber !== undefined
          );
        },
        enableFilter: true,
        enableSort: true,
        identifier: 'TRIP_DEBRIEF_LIST',
      }),
      []
    );

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [loadList, cleanupList]);

  return (
    <TemplateTableWrapper config={config} context={TripDebriefListContext} />
  );
};
