import React from 'react';
import {ContactGroupContext} from './ContactGroupContext';
import {useParams} from 'react-router-dom';

interface IParams {
  contactGroupId?: string;
}

export const ContactGroupIdHandler: React.FC = () => {
  const {setContactGroupId} = React.useContext(ContactGroupContext);

  const {contactGroupId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = contactGroupId ? parseInt(contactGroupId) : undefined;
    setContactGroupId(parsedId);
    return () => setContactGroupId(undefined);
  }, [contactGroupId, setContactGroupId]);

  return null;
};
