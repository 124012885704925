import React from 'react';
import L from 'leaflet';
import BaseMarker from './BaseMarker';
import {inject, observer} from 'mobx-react';
import {CriticalEventDump} from '@onroadvantage/onroadvantage-api';

interface Props {
  position: [number, number]; // lat , lng
  event?: CriticalEventDump;
}

class CriticalEventMarker extends React.Component<Props> {
  render() {
    const Icon = L.ExtraMarkers.icon({
      icon: 'fa-exclamation-circle',
      markerColor: 'red',
      shape: 'star',
      prefix: 'fa',
    });

    const {position, event} = this.props;

    return <BaseMarker position={position} icon={Icon} event={event} />;
  }
}
export default inject('mapDisplayStore')(observer(CriticalEventMarker));
