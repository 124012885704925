interface parseUnknownIntOptions {
  type: 'float' | 'int';
}

export const parsePotentialString = (
  value: string | number,
  options?: parseUnknownIntOptions
) => {
  if (typeof value === 'string') {
    if (options?.type === 'float') {
      return parseFloat(value);
    }
    return parseInt(value);
  }
  return value;
};
