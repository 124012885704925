import React from 'react';
import {PlanningBoardOverview} from './planningBoardOverview';
import {PlanningBoardCommitBookingMasterTrips} from './planningBoardCommitBookingMasterTrips';
import {PlanningBoardOrders} from './planningBoardOrders';
import {PlanningBoardPlanToolbar} from './planningBoardPlanToolbar';

export const PlanningBoardPlan: React.FC = () => {
  const [tabValue, setTabValue] = React.useState<number>(0);

  return (
    <>
      <PlanningBoardPlanToolbar
        activeTab={tabValue}
        setActiveTab={setTabValue}
      />
      {tabValue === 2 ? (
        <PlanningBoardOverview />
      ) : tabValue === 1 ? (
        <PlanningBoardCommitBookingMasterTrips />
      ) : (
        <PlanningBoardOrders />
      )}
    </>
  );
};
