import React from 'react';
import {SiteContext, SiteContextProps} from './SiteContext';
import {useParams} from 'react-router-dom';

interface IParams {
  siteId?: string;
}

export const SiteIdHandler: React.FC = () => {
  const {setSiteId} = React.useContext<SiteContextProps>(SiteContext);

  const {siteId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = siteId ? parseInt(siteId) : undefined;
    setSiteId(parsedId);

    return () => {
      setSiteId(undefined);
    };
  }, [siteId, setSiteId]);

  return null;
};
