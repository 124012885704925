import React from 'react';
import {ContactGroupContext} from './ContactGroupContext';
import {
  TemplateTable,
  TemplateTableConfig,
  TOnInlineAdd,
} from '../../factory/template';
import {Listing, NodeDump} from '@onroadvantage/onroadvantage-api';
import {contactGroupApi} from '../../api';
import {useAppNotifications} from '../../contexts';

export const ContactGroupNodes: React.FC = () => {
  const notify = useAppNotifications();
  const {
    contactGroup,
    contactGroupId,
    loading,
    loadContactGroup,
    hasPermission,
  } = React.useContext(ContactGroupContext);
  const [localLoading, setLocalLoading] = React.useState<boolean>(false);

  const [config] = React.useState<TemplateTableConfig<NodeDump>>({
    columns: [
      {
        name: 'name',
        label: 'Name',
        type: 'string',
        model: 'Node',
        enableEditing: true,
        enableAutocomplete: true,
        enableMulti: true,
      },
      {
        name: 'type',
        label: 'Type',
        type: 'string',
        enableEditing: false,
      },
    ],
    disableToolbar: true,
    disablePagination: true,
    deleteMessage: (row) => ({
      title: 'Contact Group Node',
      items: {
        ContactGroupName: contactGroup?.name,
        NodeName: row.name,
        ExternalReference: row.externalReference,
      },
    }),
    identifier: 'CONTACT_GROUP_NODES_LIST',
  });

  const handleInlineAdd = React.useCallback<TOnInlineAdd>(
    async (changes) => {
      setLocalLoading(true);
      try {
        for (const change of changes) {
          const nodeListings: Listing[] = change.name;
          const nodeIds = nodeListings
            .filter(({value}) => value)
            .map(({value}) => value as number);
          if (contactGroupId) {
            await contactGroupApi.apiContactGroupContactGroupIdAssignNodesPost({
              contactGroupId,
              body: {nodeIds},
            });
            await loadContactGroup();
          }
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to assign nodes');
      } finally {
        setLocalLoading(false);
      }
    },
    [contactGroupId, loadContactGroup, notify]
  );

  const handleInlineDelete = React.useCallback(
    async (row: NodeDump) => {
      setLocalLoading(true);
      try {
        if (contactGroupId && row.id) {
          await contactGroupApi.apiContactGroupContactGroupIdUnassignNodePost({
            contactGroupId,
            body: {nodeId: row.id},
          });
          await loadContactGroup();
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to unassign node');
      } finally {
        setLocalLoading(false);
      }
    },
    [contactGroupId, loadContactGroup, notify]
  );

  return (
    <TemplateTable
      config={config}
      list={contactGroup?.nodes ?? []}
      currentPage={1}
      loading={loading || localLoading}
      onDelete={hasPermission?.edit ? handleInlineDelete : undefined}
      onInlineAdd={hasPermission?.edit ? handleInlineAdd : undefined}
    />
  );
};
