import React from 'react';
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import {useInputStyles} from '../../formik/Input.style';
import {useFormikContext} from 'formik';
import clsx from 'clsx';
import {Refresh} from '@mui/icons-material';
import {TripDebriefContext} from '../TripDebriefContext';
import {MasterTripDebriefSnapshotData} from '@onroadvantage/onroadvantage-api';

interface FormikTextFieldProps {
  name: string;
  type?: 'string' | 'number' | 'kilometers';
}

export const TripDebriefTextField: React.FC<
  TextFieldProps & FormikTextFieldProps
> = ({name, disabled, type, ...otherProps}) => {
  const classes = useInputStyles();
  const {masterTripDebriefData} = React.useContext(TripDebriefContext);
  const {approvedForBilling} =
    masterTripDebriefData || ({} as MasterTripDebriefSnapshotData);
  const {
    getFieldMeta,
    getFieldHelpers,
    isSubmitting,
    isValid,
    submitCount,
    handleBlur,
    setFieldTouched,
  } = useFormikContext();
  /** Get the current fields relevant snapshot initialValue */
  const {initialValue: snapshotInitialValue} = getFieldMeta(`snapshot.${name}`);
  /**
   * Get the current fields relevant override metaData. We will also need the override initialValue to compare with the
   * snapshot initialValue to see if the current field has been overridden, thus we can handle the reset to
   * initialValue functionality.
   */
  const {
    touched,
    error,
    initialValue: overrideInitialValue,
    value,
    ...meta
  } = getFieldMeta(`override.${name}`);
  /** We want the relevant override helpers, because the override field is what we want to update and send to the api */
  const {setValue} = getFieldHelpers(`override.${name}`);
  /**
   * To check when users have clicked on the reset button, and when the have changed the value, because if they reset
   * the value to undefined or null, we need to add (value ?? '') to the fields value prop, otherwise it would appear
   * as if no change has occurred.
   */
  const [hasBeenReset, setHasBeenReset] = React.useState<boolean>(false);

  const handleInputChange = React.useCallback(
    (e: React.ChangeEvent<any>): void => {
      setFieldTouched(`override.${name}`);
      setHasBeenReset(false);
      if (
        (type === 'number' || type === 'kilometers') &&
        e.target.value === ''
      ) {
        setValue(undefined);
      } else {
        setValue(e.target.value);
      }
    },
    [name, setFieldTouched, setValue, type]
  );

  const memoizedError = React.useMemo(
    () =>
      !isValid && (submitCount > 0 || touched) && error ? error : undefined,
    [error, isValid, submitCount, touched]
  );

  /** Handle the reset of the currentValue (overrideValue), to its initialValue (snapshotValue) */
  const handleValueReset = React.useCallback(() => {
    setHasBeenReset(true);
    setValue(snapshotInitialValue);
  }, [setValue, snapshotInitialValue]);

  return (
    <TextField
      className={clsx(otherProps.className ?? classes.input, {
        [classes.inputError]: memoizedError,
      })}
      id={`override.${name}`}
      name={`override.${name}`}
      onChange={handleInputChange}
      error={!!memoizedError}
      helperText={memoizedError ?? <></>}
      onBlur={handleBlur}
      disabled={disabled || isSubmitting || approvedForBilling}
      {...otherProps}
      {...meta}
      type="text"
      defaultValue={overrideInitialValue || snapshotInitialValue}
      /**
       * Reason why we need to add the (?? ''), is because when the initial value is null or undefined, and you reset
       * the currentValue to that initialValue, the overrideValue will have changed to undefined or null and submit
       * correctly, but the visible fieldValue will remain the same, thus we just display an empty string if the value
       * is null or undefined.
       */
      value={hasBeenReset ? value ?? '' : value}
      InputLabelProps={{shrink: true}}
      color="secondary"
      /** If the overrideValue's initialValue is not equal to the snapshot's initialValue we make the field focused */
      focused={
        overrideInitialValue !== undefined &&
        overrideInitialValue !== snapshotInitialValue
      }
      /**
       * Add a 'km' end Adornment if the type='kilometers'
       * If the overrideValue's initialValue is not equal to the snapshot's initialValue we display the Reset button,
       * which will trigger the handleValueReset handler.
       */
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {type === 'kilometers' && (
              <Typography component="legend">km</Typography>
            )}
            {overrideInitialValue !== undefined &&
              overrideInitialValue !== snapshotInitialValue && (
                <IconButton onClick={handleValueReset}>
                  <Refresh />
                </IconButton>
              )}
          </InputAdornment>
        ),
      }}
    />
  );
};
