import React from 'react';
import {withSnackbar} from 'notistack';
import {inject, observer} from 'mobx-react';
import {autorun} from 'mobx';
import {appNotificationStore} from '../../stores/mobxStores';
class Notifier extends React.Component {
  notificationsDisplayed = [];

  trackNotificationDisplayed = (id) => {
    this.notificationsDisplayed = [...this.notificationsDisplayed, id];
  };

  componentDidMount() {
    autorun(() => {
      const {
        appNotificationStore: {appNotifications, removeNotification},
        enqueueSnackbar,
      } = this.props;

      appNotifications.forEach((appNotification) => {
        if (this.notificationsDisplayed.includes(appNotification.key)) return;
        enqueueSnackbar(appNotification.message, {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          variant: appNotification.type,
          autoHideDuration: 3000,
        });
        this.trackNotificationDisplayed(appNotification.key);
        removeNotification(appNotification.key);
      });
    });
  }

  componentWillUnmount = () => {
    appNotificationStore.clearNotifications();
  };

  render() {
    return null;
  }
}

export default withSnackbar(inject('appNotificationStore')(observer(Notifier)));
