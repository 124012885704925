import React from 'react';
import {AutocompleteRenderOptionState} from '@mui/material';
import {AutocompleteOptionType} from './Autocomplete';

export type AutocompleteOptionComponentType = (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: AutocompleteOptionType,
  state: AutocompleteRenderOptionState
) => React.ReactNode;

export const AutocompleteOption: AutocompleteOptionComponentType = (
  props,
  option
) => {
  return (
    <li {...props} key={option.value}>
      {option.label}
    </li>
  );
};
