import React from 'react';
import {useFormikContext} from 'formik';
import {FormikAutocompleteWithListing} from '../../formik';

export const ReportScheduleFormRecipients: React.FC = () => {
  const {isSubmitting} = useFormikContext();

  return (
    <>
      <FormikAutocompleteWithListing
        disabled={isSubmitting}
        name="contacts.to"
        model="Contact"
        placeholder="Type/select a contact"
        label="TO: Contact(s)"
        multiple
      />
      <FormikAutocompleteWithListing
        disabled={isSubmitting}
        name="contactGroups.to"
        model="ContactGroup"
        placeholder="Type/select a contact group"
        label="TO: Contact Group(s)"
        multiple
      />
      <FormikAutocompleteWithListing
        disabled={isSubmitting}
        name="contacts.cc"
        model="Contact"
        placeholder="Type/select a contact"
        label="CC: Contact(s)"
        multiple
      />
      <FormikAutocompleteWithListing
        disabled={isSubmitting}
        name="contactGroups.cc"
        model="ContactGroup"
        placeholder="Type/select a contact group"
        label="CC: Contact Group(s)"
        multiple
      />
      <FormikAutocompleteWithListing
        disabled={isSubmitting}
        name="contacts.bcc"
        model="Contact"
        placeholder="Type/select a contact"
        label="BCC: Contact(s)"
        multiple
      />
      <FormikAutocompleteWithListing
        disabled={isSubmitting}
        name="contactGroups.bcc"
        model="ContactGroup"
        placeholder="Type/select a contact group"
        label="BCC: Contact Group(s)"
        multiple
      />
    </>
  );
};
