import React from 'react';
import {useHistory} from 'react-router-dom';
import {
  ApiPlanningSkillGetRequest,
  PlanningSkill,
  PlanningSkillResponse,
  PlanningSkillPost,
  Listing,
  PlanningSkillList,
  Contract,
} from '@onroadvantage/onroadvantage-api';
import {FormikHelpers, FormikProps} from 'formik/dist/types';
import {contractApi, planningSkillApi} from '../../api';
import {IPlanningSkillForm} from './PlanningSkillForm';
import {
  TemplateTableContextProps,
  TLoadList,
  useTemplateTable,
} from '../../factory/template';
import {useAppNotifications} from '../../contexts';

export interface PlanningSkillContextProps
  extends TemplateTableContextProps<PlanningSkillResponse, PlanningSkillList> {
  onDetailsFormSubmit: (
    values: PlanningSkillPost,
    formikHelpers: FormikHelpers<PlanningSkillPost>
  ) => void;
  setPlanningSkillId: (value: number | null | undefined) => void;
  submitting: boolean;
  planningSkill?: PlanningSkill;
  planningSkillId?: number | null;
  detailsRef?: React.Ref<FormikProps<PlanningSkillPost>>;
  contracts: Listing[];
  contract: Contract | undefined;
  getContracts: (
    contractId?: number | undefined
  ) => Promise<Contract | undefined>;
}

export const PlanningSkillContext =
  React.createContext<PlanningSkillContextProps>({
    // Template Table Defaults
    loading: false,
    list: [],
    currentPage: 1,
    // Planning Skill
    loadList: () => null,
    onDetailsFormSubmit: () => null,
    setPlanningSkillId: () => null,
    submitting: false,
    contracts: [],
    contract: undefined,
    getContracts: async () => undefined,
  });

interface PlanningSkillContextProviderProps {
  planningSkillId?: number;
}

export const PlanningSkillContextProvider: React.FC<
  PlanningSkillContextProviderProps
> = ({children}) => {
  const history = useHistory();
  const notify = useAppNotifications();
  // Template Table
  const [
    {
      // States
      currentPage,
      filters,
      hasPermission,
      itemTotal,
      list,
      loading,
      pageSize,
      pageTotal,
      loadingSingleItem,
      sorting,
    },
    {
      // Getters
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
      setLoadingSingleItem,
    },
  ] = useTemplateTable<PlanningSkillResponse, ApiPlanningSkillGetRequest>({
    editPermission: 'Edit PlanningSkill',
    addPermission: 'Add PlanningSkill',
    deletePermission: 'Delete PlanningSkill',
    downloadPermission: 'PlanningSkill ListDownload',
    viewPermission: 'PlanningSkill List',
  });
  const [contract, setContract] = React.useState<Contract | undefined>();

  const handleGetContract = React.useCallback(
    async (contractId?: number | undefined) => {
      try {
        if (contractId) {
          const response = await contractApi.apiContractContractIdGet({
            contractId,
          });
          setContract(response);
          return response;
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to load contract');
      }
    },
    [notify]
  );

  const loadList = React.useCallback<TLoadList<PlanningSkillList>>(
    async (options) => {
      setLoading(true);
      try {
        const requestObj = getRequestObj(
          ['name', 'description', 'contractCode'],
          options
        );
        const response = await planningSkillApi.apiPlanningSkillGet(requestObj);
        return getResponse(response, options);
      } catch (e) {
        notify('error', e.message ?? 'Failed to load planning skill list');
      } finally {
        setLoading(false);
      }
    },
    [setLoading, getRequestObj, getResponse, notify]
  );

  const handleDelete = React.useCallback(
    async (row: PlanningSkillResponse) => {
      setLoading(true);
      try {
        if (row.id) {
          await planningSkillApi.apiPlanningSkillPlanningSkillIdDelete({
            planningSkillId: row.id,
          });
          await loadList();
          notify('success', 'PlanningSkill Deleted');
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to delete planning skill');
      } finally {
        setLoading(false);
      }
    },
    [loadList, notify, setLoading]
  );

  const handleAdd = React.useCallback(() => {
    history.push('/planningskilllist/add');
  }, [history]);

  const handleNavigate = React.useCallback(
    (row: PlanningSkillResponse) => {
      history.push(`/planningskilllist/${row.id}`);
    },
    [history]
  );

  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  // Planning Skill
  const [planningSkill, setPlanningSkill] = React.useState<
    PlanningSkill | undefined
  >();
  const [planningSkillId, setPlanningSkillId] = React.useState<
    number | null | undefined
  >();
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [contracts] = React.useState<Listing[]>([]);

  const detailsRef = React.useRef<FormikProps<PlanningSkillPost>>(null);

  const loadPlanningSkill = React.useCallback(async () => {
    setLoadingSingleItem(true);
    try {
      if (planningSkillId) {
        const response =
          await planningSkillApi.apiPlanningSkillPlanningSkillIdGet({
            planningSkillId,
          });
        await handleGetContract(response.contractId ?? undefined);
        setPlanningSkill(response);
      } else {
        setPlanningSkill(undefined);
      }
    } catch (e) {
      notify('error', e.message ?? 'Failed to load planning skill');
    } finally {
      setLoadingSingleItem(false);
    }
  }, [setLoadingSingleItem, planningSkillId, handleGetContract, notify]);

  const handleDetailsFormSubmit = React.useCallback(
    async (
      values: IPlanningSkillForm,
      formikHelpers: FormikHelpers<IPlanningSkillForm>
    ) => {
      setSubmitting(true);
      try {
        const newValues: PlanningSkillPost = {
          ...values,
          contractId:
            typeof values.contract?.value === 'string'
              ? parseInt(values.contract.value)
              : values.contract?.value,
        };

        if (planningSkill && planningSkill.id) {
          await planningSkillApi.apiPlanningSkillPlanningSkillIdPatch({
            planningSkillId: planningSkill.id,
            body: newValues,
          });
        } else {
          await planningSkillApi.apiPlanningSkillPost({
            body: newValues,
          });
        }
        history.push('/planningskilllist');
        notify(
          'success',
          `${planningSkill?.id ? 'Updated' : 'Added'} Planning Skill`
        );
      } catch (e) {
        notify('error', e.message ?? 'Failed');
      } finally {
        formikHelpers.setSubmitting(false);
        setSubmitting(false);
      }
    },
    [planningSkill, history, notify]
  );

  const value: PlanningSkillContextProps = {
    // Template Table
    loadList,
    cleanupList,
    hasPermission,
    loading: loadingSingleItem || loading,
    list,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onAdd: hasPermission.add ? handleAdd : undefined,
    onNavigate: handleNavigate,
    onDelete: hasPermission.delete ? handleDelete : undefined,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onRefresh: handleRefresh,
    // Planning Skill
    getContracts: handleGetContract,
    contract,
    onDetailsFormSubmit: handleDetailsFormSubmit,
    setPlanningSkillId,
    submitting,
    planningSkill,
    planningSkillId,
    contracts,
    detailsRef,
  };

  React.useEffect(() => {
    loadPlanningSkill();
  }, [loadPlanningSkill]);

  return (
    <PlanningSkillContext.Provider value={value}>
      {children}
    </PlanningSkillContext.Provider>
  );
};
