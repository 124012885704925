import React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import {Loader} from '../loader';

import {OpenStreetMapProvider, GoogleProvider} from 'leaflet-geosearch';
import 'leaflet-geosearch/assets/css/leaflet.css';
import ConfigService from '../../service/ConfigService/ConfigService';
import _ from 'lodash';
import {appNotificationStore, mapDisplayStore} from '../../stores/mobxStores';
import {useGeoSearchCardStyles} from './GeoSearchCard.style';
import {List, ListItem, ListItemText} from '@mui/material';

const {googleGeocodingKey} = ConfigService;

let googleProvider: any = null;

if (googleGeocodingKey) {
  googleProvider = new GoogleProvider({
    params: {
      key: googleGeocodingKey,
    },
  });
}

const osmProvider = new OpenStreetMapProvider();

interface GeoSearchCardProps {
  onAddressClick?: (address: any) => void;
}

const GeoSearchCard: React.FC<GeoSearchCardProps> = ({onAddressClick}) => {
  const classes = useGeoSearchCardStyles();
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [result, setResult] = React.useState([]);
  const [highlight, setHighlight] = React.useState<number | null>(null);

  const provider = googleProvider || osmProvider;

  const cleanup = React.useCallback(() => {
    mapDisplayStore.setOpenAddress(null);
    mapDisplayStore.setSiteDetailsFormDetails(null);
    setResult([]);
    setLoading(false);
    setExpanded(false);
    setHighlight(null);
  }, []);

  const toggleExpanded = React.useCallback(
    () => setExpanded((prevExpanded) => !prevExpanded),
    []
  );

  const geoSearch = React.useCallback(
    async (value: string) => {
      setLoading(true);
      try {
        const results = await provider.search({query: value, address: value});
        setResult(results);
      } catch (e) {
        appNotificationStore.enqueueNotification(
          'error',
          e.message ?? 'Catch: Error loading locations'
        );
      } finally {
        setLoading(false);
      }
    },
    [provider]
  );

  const getListingDebounce = React.useRef(_.debounce(geoSearch, 500)).current;

  const handleInputChange = React.useCallback(
    async (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setLoading(true);
      await getListingDebounce(e.target.value.toLowerCase());
    },
    [getListingDebounce]
  );

  const createAddressMarker = React.useCallback(
    (location: any, index: number) => () => {
      setHighlight(index);
      const address = {
        lat: location.y,
        lng: location.x,
        label: location.label,
      };
      if (window.location.pathname.includes('sitelist')) {
        mapDisplayStore.setSiteDetailsFormDetails(address);
        if (onAddressClick) onAddressClick(address);
      } else {
        mapDisplayStore.setOpenAddress({
          latitude: address.lat,
          longitude: address.lng,
          label: address.label,
        });
      }
      setExpanded(false);
    },
    [onAddressClick]
  );

  React.useEffect(() => cleanup, [cleanup]);

  return expanded ? (
    <Card className={classes.root}>
      <CardHeader
        title={`Address Search by ${googleProvider ? 'Google' : 'Vantage'}`}
        className={classes.header}
        avatar={
          <Avatar>
            <LocationSearchingIcon />
          </Avatar>
        }
        onClick={toggleExpanded}
      />
      <TextField
        label="Search"
        className={classes.inputField}
        onChange={handleInputChange}
      />
      {loading ? (
        <div className={classes.loader}>
          <Loader size={40} />
        </div>
      ) : (
        <List
          aria-labelledby={`Address List Searched by ${
            googleProvider ? 'Google' : 'Vantage'
          }`}
        >
          {result.map((location: any, index) => (
            <ListItem
              key={location.label}
              data-lat={location.y}
              data-lon={location.x}
              className={classes.list}
              data-label={location.label}
              onClick={createAddressMarker(location, index)}
              selected={index === highlight}
              button
            >
              <ListItemText primary={location.label} />
            </ListItem>
          ))}
        </List>
      )}
    </Card>
  ) : (
    <Avatar>
      <LocationSearchingIcon
        className={classes.searchIcon}
        onClick={toggleExpanded}
      />
    </Avatar>
  );
};

export default GeoSearchCard;
