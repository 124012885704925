import React from 'react';
import {
  ForgotPasswordForm,
  ForgotPasswordFormValues,
} from './ForgotPasswordForm';
import {AuthenticationContainer} from '../AuthenticationContainer';
import {authorizationApi} from '../../../api';
import {FormikProps} from 'formik';
import {Loader} from '../../loader';
import {PasswordResetSuccess} from '../PasswordResetSuccess';
import {AuthenticationBackToLogin} from '../AuthenticationBackToLogin';
import {useAppNotifications} from '../../../contexts';

export const ForgotPassword = () => {
  const notify = useAppNotifications();
  const formikRef = React.useRef<FormikProps<ForgotPasswordFormValues>>(null);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [success, setSuccess] = React.useState<boolean>();

  const handleSubmit = React.useCallback(
    async (
      values: ForgotPasswordFormValues
      // utils: FormikHelpers<ForgotPasswordFormValues>
    ) => {
      setLoading(true);
      try {
        await authorizationApi.apiAuthPasswordResetPost({
          body: {email: values.email},
        });
        setSuccess(true);
      } catch (e) {
        const b = await e.json();
        if (b.errors && b.errors.json && b.errors.json.email) {
          notify('error', b.errors.json.email);
        } else {
          setSuccess(true);
        }
      } finally {
        setLoading(false);
      }
    },
    [notify, setSuccess, setLoading]
  );

  React.useEffect(() => {
    document.title = 'Vantage | Forgot Password';
  }, []);

  let content: React.ReactNode;
  if (loading) {
    content = <Loader />;
  } else if (success) {
    content = (
      <PasswordResetSuccess
        message="If this is a registered email address you should receive an
        email containing information to reset your password shortly."
      />
    );
  } else {
    content = (
      <>
        <ForgotPasswordForm formikRef={formikRef} onSubmit={handleSubmit} />
        <AuthenticationBackToLogin variant="outlined" />
      </>
    );
  }

  return <AuthenticationContainer>{content}</AuthenticationContainer>;
};
