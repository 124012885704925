import React from 'react';
import {
  NUMBER_ERROR_MESSAGE,
  TemplateCard,
  TemplateForm,
} from '../../../factory/template';
import {VehicleTypeCapacityContext} from './VehicleTypeCapacityContext';
import {VehicleCapacityDimension} from '@onroadvantage/onroadvantage-api';
import * as Yup from 'yup';
import {FormikTextField} from '../../formik';
import {Typography} from '@mui/material';
import {VehicleTypeCapacityCompartmentTruck} from './vehicleTypeCapacityCompartmentTruck/VehicleTypeCapacityCompartmentTruck';

export interface ICapacityCompartment {
  id: number | null | undefined;
  capacity: number | null | undefined;
  compartmentId: number | null | undefined;
}

export interface IVehicleTypeCapacityForm {
  capacity: number;
  name: string;
  unitOfMeasure: string;
  capacityCompartments: ICapacityCompartment[];
}

const capacityCompartmentSchema: Yup.SchemaOf<ICapacityCompartment> =
  Yup.object({
    id: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
    capacity: Yup.number().typeError(NUMBER_ERROR_MESSAGE).required('Required'),
    compartmentId: Yup.number()
      .typeError(NUMBER_ERROR_MESSAGE)
      .required('Required'),
  });

const schema: Yup.SchemaOf<IVehicleTypeCapacityForm> = Yup.object({
  capacity: Yup.number().typeError(NUMBER_ERROR_MESSAGE).required('Required'),
  name: Yup.string().required('Required'),
  unitOfMeasure: Yup.string().required('Required'),
  capacityCompartments: Yup.array().of(capacityCompartmentSchema),
});

const getInitialValues = (
  vehicleTypeCapacity?: VehicleCapacityDimension | null
): IVehicleTypeCapacityForm | undefined => {
  if (vehicleTypeCapacity) {
    return {
      capacity: vehicleTypeCapacity.capacity ?? 0,
      name: vehicleTypeCapacity.name ?? '',
      unitOfMeasure: vehicleTypeCapacity.unitOfMeasure ?? '',
      capacityCompartments:
        vehicleTypeCapacity.compartmentCapacities?.map((c) => ({
          id: c.id,
          capacity: c.compartmentCapacity,
          compartmentId: c.vehicleCompartment?.id,
        })) ?? [],
    };
  }
  return undefined;
};

export const VehicleTypeCapacityForm: React.FC<{isAdd?: boolean}> = ({
  isAdd,
}) => {
  const {
    detailsRef,
    loading,
    submitting,
    vehicleTypeCapacity,
    onDetailsFormSubmit,
  } = React.useContext(VehicleTypeCapacityContext);
  const [initialValues, setInitialValues] =
    React.useState<IVehicleTypeCapacityForm>();

  React.useEffect(() => {
    const values = getInitialValues(vehicleTypeCapacity);

    if (values) {
      setInitialValues(values);
    } else if (isAdd) {
      setInitialValues({
        capacity: 1,
        name: '',
        unitOfMeasure: '',
        capacityCompartments: [],
      });
    }
  }, [isAdd, vehicleTypeCapacity, setInitialValues]);

  return (
    <TemplateCard
      title={`VehicleTypeCapacity Details ${isAdd ? 'Add' : 'Edit'}`}
      loading={loading}
    >
      <TemplateForm<IVehicleTypeCapacityForm>
        initialValues={initialValues}
        onSubmit={onDetailsFormSubmit}
        permission={{
          name: `${isAdd ? 'Add' : 'Edit'} VehicleType`,
          type: isAdd ? 'Add' : 'Edit',
        }}
        submitting={submitting}
        innerRef={detailsRef}
        validationSchema={schema}
      >
        {({values}) => (
          <>
            <FormikTextField
              name="name"
              placeholder="Enter the vehicle type capacity name"
              label="Name"
              fullWidth
            />
            <FormikTextField
              name="capacity"
              placeholder="Enter the vehicle type capacity"
              type="number"
              label="Capacity"
              fullWidth
            />
            <FormikTextField
              name="unitOfMeasure"
              placeholder="Enter the vehicle type capacity unit of measure"
              label="Unit of Measure"
              fullWidth
            />
            <Typography>
              <strong>
                Total allocated:{' '}
                {values.capacityCompartments.reduce(
                  (acc, {capacity}) => acc + (capacity ?? 0),
                  0
                )}
                {values.unitOfMeasure}
              </strong>
            </Typography>{' '}
            <Typography>
              <strong>
                Total available:{' '}
                {values.capacity -
                  values.capacityCompartments.reduce(
                    (acc, {capacity}) => acc + (capacity ?? 0),
                    0
                  )}
                {values.unitOfMeasure}
              </strong>
            </Typography>
            <VehicleTypeCapacityCompartmentTruck />
          </>
        )}
      </TemplateForm>
    </TemplateCard>
  );
};
