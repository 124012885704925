import React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {observer} from 'mobx-react';
import {authStore} from '../../../store';
import {useAuth} from 'oidc-react';
import history from '../../../service/history';

export type ILogoutProps = RouteComponentProps;

export const Logout: React.FC = observer(() => {
  const oidcAuth = useAuth();
  const auth = authStore.getAuth;

  React.useEffect(() => {
    const logout = async () => {
      if (auth?.oauthAuthenticated) {
        if (!auth?.isOidcSigningOut) {
          authStore.setOidcSigningOut(true);
          await oidcAuth.signOutRedirect();
        }
      } else {
        try {
          await authStore.setAuth(null);
          await authStore.clearStore();
        } catch {
          // todo
        } finally {
          history.push('/login' + history.location.search);
        }
      }
    };
    logout();
  }, [oidcAuth, auth?.isOidcSigningOut, auth?.oauthAuthenticated]);

  if (auth && (auth.authenticated || auth.oauthAuthenticated)) {
    return <div>Logging out...</div>;
  }

  return <div>Redirecting...</div>;
});
