export const checkQuantityExceedsVariability = ({
  quantity,
  actualQuantity,
  variabilityThreshold,
}: {
  quantity: number;
  actualQuantity: number;
  variabilityThreshold: number;
}) => {
  if (quantity > 0 && actualQuantity > 0) {
    if (variabilityThreshold === 0) {
      return quantity === actualQuantity;
    }
    const variabilityRange = quantity * (variabilityThreshold / 100);
    const max = quantity + variabilityRange;
    const min = quantity - variabilityRange;
    return min <= actualQuantity && actualQuantity <= max;
  }
  return true;
};
