import React from 'react';
import {Box, Typography} from '@mui/material';
import {makeStyles, createStyles} from '@mui/styles';
import custImg from '../../../../img/background/popupbackground.png';
import truck from '../../../../img/svg/truck.svg';

import PopupBase from './PopupBase';

const useActualResourcePopupStyles = makeStyles(() =>
  createStyles({
    textWrapStyle: {
      wordBreak: 'break-all',
      paddingBottom: 5,
    },
    headingColor: {
      color: '#767676',
    },
  })
);

interface Props {
  actualResource: any;
}

export default function ActualResourcePopup({actualResource}: Props) {
  const classes = useActualResourcePopupStyles();

  return (
    <PopupBase
      backgroundImage={custImg}
      mainIcon={truck}
      left={{header: 'Actual'}}
      right={{header: 'Resource Current Location'}}
    >
      <Box mb={1}>
        <Typography variant="h5" className={classes.headingColor}>
          {actualResource.title}
        </Typography>
      </Box>
      <Box mb={1}>
        <Typography variant="h5" className={classes.headingColor}>
          Position
        </Typography>
        <Typography variant="h6">
          {actualResource.latitude}, {actualResource.longitude}
        </Typography>
      </Box>
    </PopupBase>
  );
}
