import React from 'react';
import {useHistory} from 'react-router-dom';
import {useAppNotifications} from '../../../../contexts';
import {useTripEditResponse} from './useTripEdit';

interface syncStopWithActualsOptions {
  redirectUrl?: string;
  disableRedirect?: boolean;
}

interface useTripEditSyncParams {
  masterTripId: number | undefined;
  setStops: useTripEditResponse['setStops'];
  getInitialStop: useTripEditResponse['getInitialStop'];
  getStopActuals: useTripEditResponse['getStopActuals'];
  getTripTimestampPriorities: useTripEditResponse['getTripTimestampPriorities'];
  loadStops: useTripEditResponse['loadStops'];
}

export const useTripEditSync = (params: useTripEditSyncParams) => {
  const {
    masterTripId,
    setStops,
    getInitialStop,
    getStopActuals,
    getTripTimestampPriorities,
    loadStops,
  } = params;
  const history = useHistory();
  const notify = useAppNotifications();

  // loading states
  const [syncingStopsWithActuals, setSyncingStopsWithActuals] =
    React.useState<boolean>(false);

  // helper handlers
  /**
   * Handler to reset a single stop's actuals back to its initialStop's planned values, which is possible due to the
   * getInitialStop handler and the stopsInitial state
   */
  const handleCancelSyncStopWithActuals = React.useCallback(
    (stopId: number | null | undefined) => {
      const initialStop = getInitialStop(stopId);

      setStops((prevStops) =>
        prevStops?.map((stop, index) => {
          if (stopId !== stop.id || !initialStop) return stop;

          if (index === 0) {
            return {...stop, departureTime: initialStop.departureTime};
          }

          return {...stop, totalServiceTime: initialStop.totalServiceTime};
        })
      );
    },
    [getInitialStop, setStops]
  );

  /**
   * Handler to reset all the stop' actuals back to their initialStop's planned values, which is possible due to the
   * getInitialStop handler and the stopsInitial state
   */
  const handleCancelSyncStopsWithActuals = React.useCallback(() => {
    setStops((prevStops) =>
      prevStops?.map((stop, index) => {
        const initialStop = getInitialStop(stop.id);

        if (!initialStop) return stop;

        if (index === 0) {
          return {...stop, departureTime: initialStop.departureTime};
        }

        return {...stop, totalServiceTime: initialStop.totalServiceTime};
      })
    );
  }, [getInitialStop, setStops]);

  /**
   * Handle syncing of a single stop with its actuals. This will be used when a user has canceled syncing on a stop, but
   * then decides to sync it again. This way they don't have to sync all the stops again, just to get a certain stop's
   * actuals back.
   */
  const handleSyncStopWithActuals = React.useCallback(
    (stopId: number | null | undefined) => {
      setStops((prevStops) => {
        if (!prevStops) return undefined;

        const {arrivalTimestamp, departureTimestamp} =
          getTripTimestampPriorities(prevStops);

        return prevStops?.map((stop, index) =>
          stop.id === stopId
            ? getStopActuals({
                stop,
                index,
                arrivalTimestamp,
                departureTimestamp,
              })
            : stop
        );
      });
    },
    [getStopActuals, getTripTimestampPriorities, setStops]
  );

  /** Handle sync actuals load */
  const handleSyncStopsWithActuals = React.useCallback(
    async (options?: syncStopWithActualsOptions) => {
      const {disableRedirect, redirectUrl} =
        options || ({} as syncStopWithActualsOptions);
      setSyncingStopsWithActuals(true);
      try {
        /**
         * If the disableRedirect option is true, ignore all the redirect logic and just load the stops and return the
         * loadStops' function response
         */
        if (disableRedirect) {
          return await loadStops({
            syncActuals: true,
            disableErrorMessage: true,
          });
        }

        if (redirectUrl) {
          history.push(redirectUrl);
        } else if (
          masterTripId &&
          !history.location.pathname.includes('Sync')
        ) {
          history.push(`/triplist/${masterTripId}/stops/edit?tab=Sync`);
        }

        return await loadStops({syncActuals: true, disableErrorMessage: true});
      } catch (e) {
        notify('error', 'Failed to sync with actuals');
      } finally {
        setSyncingStopsWithActuals(false);
      }
    },
    [history, loadStops, masterTripId, notify]
  );

  return {
    // loading states
    syncingStopsWithActuals,
    // helper handlers
    onCancelSyncStopWithActuals: handleCancelSyncStopWithActuals,
    onCancelSyncStopsWithActuals: handleCancelSyncStopsWithActuals,
    onSyncStopWithActuals: handleSyncStopWithActuals,
    onSyncStopsWithActuals: handleSyncStopsWithActuals,
  };
};

export type useTripEditSyncResponse = ReturnType<typeof useTripEditSync>;

export const useTripEditSyncResponseInitial: useTripEditSyncResponse = {
  // loading states
  syncingStopsWithActuals: false,
  // helper handlers
  onCancelSyncStopWithActuals: () => null,
  onCancelSyncStopsWithActuals: () => null,
  onSyncStopWithActuals: async () => undefined,
  onSyncStopsWithActuals: async () => undefined,
};
