import {NodeStopDump} from '@onroadvantage/onroadvantage-api';

export const getUniqueSetNearbyNodes = async (nodes: NodeStopDump[]) => {
  const result: NodeStopDump[] = [];
  const map = new Map();
  nodes.forEach((item) => {
    if (!map.has(item.id)) {
      map.set(item.id, true); // set any value to Map
      result.push({
        ...item,
      });
    }
  });
  return result;
};
