import React from 'react';
import moment from 'moment';
import {MomentService, SettingService} from '../service';
import {unstable_batchedUpdates} from 'react-dom';

const VIEW_ID = 'GLOBAL';
const DATERANGE_ID = 'daterange_picker';

export interface DateRangeContextProps {
  dateRange: {
    endDate: string;
    startDate: string;
  };
  onDatesChange: (startDate: string, endDate: string) => void;
}

const DateRangeContext = React.createContext<DateRangeContextProps>({
  dateRange: {
    endDate: MomentService.endOfDate(moment()),
    startDate: MomentService.startOfDate(moment()),
  },
  onDatesChange: () => null,
});

export const DateRangeProvider: React.FC = ({children}) => {
  const [endDate, setEndDate] = React.useState<string>(
    MomentService.endOfDate(moment())
  );
  const [startDate, setStartDate] = React.useState<string>(
    MomentService.startOfDate(moment())
  );

  const handleDatesChange = (startDate: string, endDate: string) => {
    if (startDate && endDate) {
      SettingService.setSetting(VIEW_ID, `${DATERANGE_ID}start`, startDate);
      SettingService.setSetting(VIEW_ID, `${DATERANGE_ID}end`, endDate);
      unstable_batchedUpdates(() => {
        setStartDate(startDate);
        setEndDate(endDate);
      });
    }
  };

  React.useEffect(() => {
    const end = SettingService.getSetting(VIEW_ID, `${DATERANGE_ID}end`);
    const start = SettingService.getSetting(VIEW_ID, `${DATERANGE_ID}start`);

    unstable_batchedUpdates(() => {
      setEndDate(end ?? MomentService.endOfDate(moment()));
      setStartDate(start ?? MomentService.startOfDate(moment()));
    });
  }, [setEndDate, setStartDate]);

  const value = {
    dateRange: {
      endDate,
      startDate,
    },
    onDatesChange: handleDatesChange,
  };

  return (
    <DateRangeContext.Provider value={value}>
      {children}
    </DateRangeContext.Provider>
  );
};

// TODO typing
export const connectDateRange = (C: any) => (props: any) =>
  (
    <DateRangeContext.Consumer>
      {({dateRange}) => <C {...props} {...dateRange} />}
    </DateRangeContext.Consumer>
  );

export default DateRangeContext;
