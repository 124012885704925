import React from 'react';
import CardContent from '@mui/material/CardContent';
import {Avatar, Card, CardHeader, Chip} from '@mui/material';
import {SettingsApplications as SettingsApplicationsIcon} from '@mui/icons-material';
import {useFormikContext} from 'formik';
import {ReportScheduleAddFormValues} from './ReportScheduleAdd';
import Typography from '@mui/material/Typography';
import {useReportScheduleAddReviewStyles} from './ReportScheduleAddReview.style';
import clsx from 'clsx';
import _ from 'lodash';
import {ArrIntSchemaType} from '../ReportSchema';
import moment from 'moment';

interface SingleEntryProps {
  first?: boolean;
  title: string;
  value?: string | React.ReactNode;
}

const SingleEntry: React.FC<SingleEntryProps> = ({first, title, value}) => {
  const classes = useReportScheduleAddReviewStyles();
  return (
    <span
      className={clsx(classes.single, classes.item, {
        [classes.first]: first,
      })}
    >
      <Typography className={classes.singleTitle}>{title}: </Typography>
      <Typography className={classes.singleValue}>
        {value && value !== '' ? value : '-'}
      </Typography>
    </span>
  );
};

interface MultipleEntryProps {
  first?: boolean;
  title: string;
  value?: string | React.ReactNode | React.ReactNodeArray;
}

const MultipleEntry: React.FC<MultipleEntryProps> = ({first, title, value}) => {
  const classes = useReportScheduleAddReviewStyles();
  return (
    <span
      className={clsx(classes.multiple, classes.item, {
        [classes.first]: first,
      })}
    >
      <Typography className={classes.multipleTitle}>{title}: </Typography>
      <div className={classes.multipleValue}>{value ?? '-'}</div>
    </span>
  );
};

export const ReportScheduleAddReview: React.FC = () => {
  const classes = useReportScheduleAddReviewStyles();
  const {values} = useFormikContext<ReportScheduleAddFormValues>();

  const parameters = values.parameters?.map((p, index) => {
    switch (p.type) {
      case 'daterange':
        return (
          <SingleEntry
            first={index === 0}
            title={p?.description ?? ''}
            value={_.get(p, 'value.label', '')}
          />
        );
      case 'arrint':
        return (
          <MultipleEntry
            first={index === 0}
            title={p?.description ?? ''}
            value={((p.value as ArrIntSchemaType) || []).map((v) => (
              <Chip className={classes.chip} key={v.label} label={v.label} />
            ))}
          />
        );
      case 'customlist':
        return (
          <MultipleEntry
            first={index === 0}
            title={p?.description ?? ''}
            value={((p.value as ArrIntSchemaType) || []).map((v) => (
              <Chip className={classes.chip} key={v.label} label={v.label} />
            ))}
          />
        );
      default:
        return '';
    }
  });

  const contacts = {
    bcc: values?.contacts.bcc?.map((c) => (
      <Chip className={classes.chip} key={c.value} label={c.label} />
    )),
    cc: values?.contacts.cc?.map((c) => (
      <Chip className={classes.chip} key={c.value} label={c.label} />
    )),
    to: values?.contacts.to?.map((c) => (
      <Chip className={classes.chip} key={c.value} label={c.label} />
    )),
  };
  const contactGroups = {
    bcc: values?.contactGroups?.bcc?.map((c) => (
      <Chip className={classes.chip} key={c.value} label={c.label} />
    )),
    cc: values?.contactGroups?.cc?.map((c) => (
      <Chip className={classes.chip} key={c.value} label={c.label} />
    )),
    to: values?.contactGroups?.to?.map((c) => (
      <Chip className={classes.chip} key={c.value} label={c.label} />
    )),
  };

  return (
    <>
      <CardHeader
        title="Review Report Schedule"
        avatar={
          <Avatar>
            <SettingsApplicationsIcon />
          </Avatar>
        }
      />
      <CardContent>
        <Card elevation={0} square className={classes.content}>
          <CardHeader className={classes.header} subheader="Details" />
          <CardContent>
            <SingleEntry first title="Name" value={values.name} />
            <SingleEntry title="Description" value={values.description} />
            <SingleEntry title="Type" value={_.capitalize(values.type)} />
            <SingleEntry
              title="Time"
              value={values.time ? moment(values.time).format('LT') : '-'}
            />
            <SingleEntry
              title="Start Date"
              value={
                values.startDate ? moment(values.startDate).format('L') : '-'
              }
            />
            <SingleEntry
              title="End Date"
              value={values.endDate ? moment(values.endDate).format('L') : '-'}
            />
            {values.type === 'recurring' && (
              <>
                <SingleEntry
                  title="Sunday"
                  value={values.sunday ? 'Yes' : 'No'}
                />
                <SingleEntry
                  title="Monday"
                  value={values.monday ? 'Yes' : 'No'}
                />
                <SingleEntry
                  title="Tuesday"
                  value={values.tuesday ? 'Yes' : 'No'}
                />
                <SingleEntry
                  title="Wednesday"
                  value={values.wednesday ? 'Yes' : 'No'}
                />
                <SingleEntry
                  title="Thursday"
                  value={values.thursday ? 'Yes' : 'No'}
                />
                <SingleEntry
                  title="Friday"
                  value={values.friday ? 'Yes' : 'No'}
                />
                <SingleEntry
                  title="Saturday"
                  value={values.saturday ? 'Yes' : 'No'}
                />
              </>
            )}
          </CardContent>
        </Card>
        <Card
          elevation={0}
          square
          style={{display: 'flex', flexDirection: 'column'}}
        >
          <CardHeader className={classes.header} subheader="Parameters" />
          <CardContent>{parameters}</CardContent>
        </Card>
        <Card
          elevation={0}
          square
          style={{display: 'flex', flexDirection: 'column'}}
        >
          <CardHeader className={classes.header} subheader="Recipients" />
          <CardContent>
            <MultipleEntry first title="Contacts TO" value={contacts.to} />
            <MultipleEntry first title="Contacts CC" value={contacts.cc} />
            <MultipleEntry first title="Contacts BCC" value={contacts.bcc} />
            <MultipleEntry title="Contact Groups TO" value={contactGroups.to} />
            <MultipleEntry title="Contact Groups CC" value={contactGroups.cc} />
            <MultipleEntry
              title="Contact Groups BCC"
              value={contactGroups.bcc}
            />
          </CardContent>
        </Card>
      </CardContent>
    </>
  );
};
