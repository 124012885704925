import {makeStyles, createStyles} from '@mui/styles';
import {Theme} from '@mui/material';

const VehicleTypeCapacityCompartmentTruckCompartmentStyles = (theme: Theme) =>
  createStyles({
    compartment: {
      position: 'relative',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      height: '90%',
      backgroundColor: '#bebebe',
      margin: theme.spacing(0.5),
      padding: theme.spacing(0.5),
      borderRadius: theme.spacing(1),
      alignSelf: 'stretch',
      alignItems: 'center',
      justifyContent: 'flex-start',
      textAlign: 'center',
    },
    divider: {
      height: 1,
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.6),
      backgroundColor: '#555',
      width: '100%',
      display: 'flex',
    },
    fieldContainer: {
      padding: theme.spacing(1.5),
      flex: 1,
      alignSelf: 'stretch',
    },
  });

export const useVehicleTypeCapacityCompartmentTruckCompartmentStyles =
  makeStyles(VehicleTypeCapacityCompartmentTruckCompartmentStyles, {
    name: 'VehicleTypeCapacityCompartmentTruckCompartmentStyles',
  });
