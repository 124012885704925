import React from 'react';
import {Checkbox} from '@mui/material';
import {DataTypeProvider} from '@devexpress/dx-react-grid';
import {useInternalTableContext} from '../../TemplateTable';

export const TableFilterBooleanCell: React.FC<
  DataTypeProvider.ValueEditorProps
> = ({column, value, onValueChange}) => {
  const {config} = useInternalTableContext();
  const [checked, setChecked] = React.useState<boolean | undefined>(value);
  const [columnConfig] = React.useState(
    config.columns.find((c) => c.name === column.name)
  );

  const handleChange = React.useCallback(() => {
    let newValue: boolean | undefined;

    switch (checked) {
      case true:
        newValue = false;
        break;
      case false:
        newValue = undefined;
        break;
      case undefined:
        newValue = true;
        break;
      default:
        newValue = undefined;
    }

    onValueChange(newValue);
    setChecked(newValue);
  }, [checked, onValueChange]);

  return (
    <Checkbox
      disabled={!config.enableFilter || columnConfig?.enableFilter === false}
      checked={checked ?? false}
      indeterminate={checked === undefined}
      onChange={handleChange}
      value={value}
    />
  );
};
