import React from 'react';
import {Button, Stack} from '@mui/material';
import {useTripEditContext} from './tripEditContext';
import {useTripContext} from '../tripContext';
import {Loader} from '../../loader';

export const TripEditActions: React.FC = () => {
  const {loadingMasterTrip, updatingTrip} = useTripContext();
  const {stops, stopsInitial, loadingStops, onResetStops, onSubmitStops} =
    useTripEditContext();

  const stopsIsDirty = React.useMemo(
    () =>
      stopsInitial &&
      stops &&
      JSON.stringify(stopsInitial) !== JSON.stringify(stops),
    [stops, stopsInitial]
  );

  const disableActions = React.useMemo(
    () => loadingMasterTrip || loadingStops || updatingTrip,
    [loadingMasterTrip, loadingStops, updatingTrip]
  );

  return (
    <Stack direction="row" alignItems="center" spacing={1} pr={1} ml="auto">
      {updatingTrip ? <Loader size={32} /> : null}
      {stopsIsDirty ? (
        <>
          <Button
            disabled={disableActions}
            variant="contained"
            onClick={onSubmitStops}
          >
            Update and Recalculate
          </Button>
          <Button
            disabled={disableActions}
            color="inherit"
            variant="outlined"
            onClick={onResetStops}
          >
            Reset
          </Button>
        </>
      ) : (
        <Button
          disabled={disableActions}
          variant="contained"
          onClick={onSubmitStops}
        >
          Recalculate
        </Button>
      )}
    </Stack>
  );
};
