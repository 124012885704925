import React from 'react';
import {WebContractDump} from '@onroadvantage/onroadvantage-api';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../../factory/template';
import {
  ContractListContext,
  useContractListContext,
} from './ContractListContext';

export const ContractList: React.FC = () => {
  const {loadList, cleanupList} = useContractListContext();

  const config: TemplateTableConfig<WebContractDump> = React.useMemo(
    () => ({
      columns: [
        {name: 'name', label: 'Name', type: 'string'},
        {name: 'code', label: 'Code', type: 'string'},
      ],
      enableFilter: true,
      enableSort: true,
      deleteMessage: (row) => ({
        title: 'Contract',
        items: {Name: row.name, code: row.code},
      }),
      identifier: 'CONTRACT_LIST',
    }),
    []
  );

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [cleanupList, loadList]);

  return <TemplateTableWrapper config={config} context={ContractListContext} />;
};
