import React from 'react';
import {
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material';
import {Room, PhoneAndroid, Sync} from '@mui/icons-material';
import {TimestampPriorities, useTripEditContext} from '../tripEditContext';

export const TripEditSyncListActions: React.FC = () => {
  const {
    syncingStopsWithActuals,
    onSyncStopsWithActuals,
    timestampPriority,
    onChangeTimestampPriority,
  } = useTripEditContext();

  const handleSyncStopsWithActuals = React.useCallback(async () => {
    await onSyncStopsWithActuals();
  }, [onSyncStopsWithActuals]);

  const handleChangePriority = React.useCallback(
    (
      event: React.MouseEvent<HTMLElement>,
      newAlignment: TimestampPriorities | null
    ) => {
      if (newAlignment) {
        onChangeTimestampPriority(newAlignment);
      }
    },
    [onChangeTimestampPriority]
  );

  return (
    <>
      <ToggleButtonGroup
        value={timestampPriority}
        exclusive
        onChange={handleChangePriority}
        aria-label="Timestamp Priority"
        color="primary"
        size="small"
        sx={{mr: 0.5}}
      >
        <ToggleButton size="small" value="gps" aria-label="gps">
          <Tooltip title="Gps" arrow>
            <Room fontSize="small" />
          </Tooltip>
        </ToggleButton>
        <ToggleButton size="small" value="mobile" aria-label="mobile">
          <Tooltip title="Mobile" arrow>
            <PhoneAndroid fontSize="small" />
          </Tooltip>
        </ToggleButton>
      </ToggleButtonGroup>
      <Tooltip title="Sync stops with actuals" arrow>
        <IconButton
          disabled={syncingStopsWithActuals}
          onClick={handleSyncStopsWithActuals}
        >
          <Sync />
        </IconButton>
      </Tooltip>
    </>
  );
};
