import React from 'react';
import {Button, CardActions} from '@mui/material';

export interface TemplateStepperActionsProps {
  nextDisabled?: boolean;
  nextTitle?: string;
  onNext?: () => void;
  onPrev?: () => void;
  prevDisabled?: boolean;
  prevTitle?: string;
}

export const TemplateStepperActions: React.FC<TemplateStepperActionsProps> = ({
  nextDisabled,
  nextTitle,
  onNext,
  onPrev,
  prevDisabled,
  prevTitle,
}) => (
  <CardActions>
    <Button color="secondary" onClick={onPrev} disabled={prevDisabled}>
      {prevTitle || 'back'}
    </Button>
    <Button
      color="primary"
      onClick={onNext}
      // type="submit"
      disabled={nextDisabled}
    >
      {nextTitle || 'next'}
    </Button>
  </CardActions>
);
