import React from 'react';
import clsx from 'clsx';
import {useApiDocsStyles} from './ApiDocsFrame.style';
import {Loader} from '../loader';
import ConfigService from '../../service/ConfigService/ConfigService';
import {useLocation} from 'react-router-dom';
import {authStore} from '../../store';

interface ApiDocsFrameProps {
  title: string;
  url: string;
}

export const ApiDocsFrame: React.FC<ApiDocsFrameProps> = ({title, url}) => {
  const {hash} = useLocation();
  const classes = useApiDocsStyles();
  const [didLoad, setDidLoad] = React.useState<boolean>(false);

  const handleDidLoad = React.useCallback(
    (_event: React.SyntheticEvent<HTMLIFrameElement, Event>) => {
      setDidLoad(true);
    },
    []
  );

  const handleError = React.useCallback(
    (_event: React.SyntheticEvent<HTMLIFrameElement, Event>) => {
      setDidLoad(false);
    },
    []
  );

  const auth = authStore.getAuth;

  const src = `${ConfigService.serverUri}${url}${hash}?auth_token=${
    auth ? auth.token : ''
  }`;

  return (
    <>
      <iframe
        className={clsx(classes.frame, {[classes.frameDidLoad]: didLoad})}
        title={title}
        src={src}
        width="100%"
        height="100%"
        allowFullScreen={true}
        loading="eager"
        onLoad={handleDidLoad}
        // TODO handle error?
        onError={handleError}
      />
      {!didLoad ? <Loader text={`Rendering ${title}...`} /> : null}
    </>
  );
};
