import React from 'react';
import {Stack, Typography} from '@mui/material';
import {useTripDebriefErrorOccurred} from './TripDebriefErrorOccurred.style';
import {TripDebriefContext} from '../TripDebriefContext';
import {useTripContext} from '../../trip/tripContext';
import {VantageDialogButton} from '../../dialog';
import {useAppNotifications} from '../../../contexts';

interface ErrorProps {
  receivedError: string | undefined;
}

export const TripDuplicateErrorOccurred: React.FC<ErrorProps> = ({
  receivedError,
}: ErrorProps) => {
  const classes = useTripDebriefErrorOccurred();
  const notify = useAppNotifications();
  const {
    handleOverrideDuplicateError,
    setOverrideDuplicateError,
    inDebrief,
    setInDebrief,
    loading,
  } = React.useContext(TripDebriefContext);
  const {
    loadMasterTripDebrief,
    inTripView,
    setInTripView,
    loadingMasterTripDebrief,
  } = useTripContext();

  const onSubmit = React.useCallback(async () => {
    try {
      if (inDebrief && !inTripView) {
        await handleOverrideDuplicateError();
        if (!loading) {
          setInDebrief(false);
        }
      }

      if (inTripView && !inDebrief) {
        await loadMasterTripDebrief({reload: true, override: true});
        if (!loadingMasterTripDebrief) {
          setInTripView(false);
        }
      }
    } catch (e) {
      notify('error', 'Failed to override duplicate error');
    } finally {
      setOverrideDuplicateError(false);
    }
  }, [
    loading,
    inDebrief,
    setInDebrief,
    handleOverrideDuplicateError,
    loadMasterTripDebrief,
    loadingMasterTripDebrief,
    setOverrideDuplicateError,
    inTripView,
    setInTripView,
    notify,
  ]);

  return (
    <Stack>
      <Stack className={classes.root}>
        <Typography variant="h4" gutterBottom>
          Duplicate Trip Detected
        </Typography>
        <Typography variant="h5">{receivedError}</Typography>
      </Stack>
      <Stack className={classes.overrideButton}>
        <VantageDialogButton
          disabled={loading || loadingMasterTripDebrief}
          title={<Typography>Continue Debriefing</Typography>}
          titleSx={{textAlign: 'center'}}
          color="primary"
          dialogTitle={'Override Duplicate Trip'}
          onAccept={onSubmit}
          acceptTitle="Yes"
          declineTitle="No"
          variant="contained"
          enableDividers
        >
          Are you sure this trip is not a duplicate and needs debriefing
        </VantageDialogButton>
      </Stack>
    </Stack>
  );
};
