import React from 'react';
import {
  VehicleListResponse,
  Vehicle,
  Listing,
  ApiVehicleGroupVehicleGroupIdUnassignedGetRequest,
} from '@onroadvantage/onroadvantage-api';
import {useAppNotifications} from '../../../contexts';
import {vehicleGroupApi} from '../../../api';
import {
  TemplateTableContextProps,
  TLoadList,
  TOnInlineAdd,
  useTemplateTable,
} from '../../../factory/template';
import {VehicleGroupContext} from '../VehicleGroupContext';
import {RoleService} from '../../../service';

export type VehicleGroupVehiclesContextProps = TemplateTableContextProps<
  Vehicle,
  VehicleListResponse
>;

export const VehicleGroupVehiclesContext =
  React.createContext<VehicleGroupVehiclesContextProps>({
    // Template Table Defaults
    loading: false,
    list: [],
    currentPage: 1,
    // VehicleGroupVehicles
    loadList: async () => {},
  });

interface VehicleGroupVehiclesContextProviderProps {
  vehicleGroupVehiclesId?: number;
}

export const VehicleGroupVehiclesContextProvider: React.FC<
  VehicleGroupVehiclesContextProviderProps
> = ({children}) => {
  const notify = useAppNotifications();
  const {vehicleGroupId} = React.useContext(VehicleGroupContext);
  // Template Table
  const [
    {
      // States
      currentPage,
      pageSize,
      pageTotal,
      sorting,
      hasPermission,
      itemTotal,
      list,
      loading,
      loadingSingleItem,
      filters,
    },
    {
      // Getters
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
    },
  ] = useTemplateTable<
    Vehicle,
    ApiVehicleGroupVehicleGroupIdUnassignedGetRequest
  >({
    editPermission: 'Edit VehicleGroupVehicles',
    addPermission: 'Add VehicleGroupVehicles',
    deletePermission: 'Delete VehicleGroupVehicles',
    downloadPermission: 'VehicleGroupVehicles ListDownload',
    viewPermission: 'VehicleGroupVehicles List',
  });

  const loadList = React.useCallback<TLoadList<VehicleListResponse>>(
    async (options) => {
      setLoading(true);
      try {
        if (vehicleGroupId) {
          const requestObj = getRequestObj(
            [
              'description',
              'registrationNumber',
              'fleetNumber',
              'vehicleTypeName',
              'contractCode',
            ],
            options,
            {vehicleGroupId}
          );
          const response =
            await vehicleGroupApi.apiVehicleGroupVehicleGroupIdAssignedGet(
              requestObj
            );
          return getResponse(response, options);
        }
      } catch (e) {
        notify(
          'error',
          e.message ?? 'Failed to load vehicle Group Vehicles list'
        );
      } finally {
        setLoading(false);
      }
    },
    [getRequestObj, getResponse, notify, setLoading, vehicleGroupId]
  );

  const handleDelete = React.useCallback(
    async (row: Vehicle) => {
      setLoading(true);
      try {
        if (vehicleGroupId && row.id) {
          await vehicleGroupApi.apiVehicleGroupVehicleGroupIdUnassignPost({
            vehicleGroupId,
            body: {vehicleIds: [row.id]},
          });
          await loadList();
          notify('success', 'Unassigned vehicle from group');
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to delete');
      } finally {
        setLoading(false);
      }
    },
    [loadList, notify, setLoading, vehicleGroupId]
  );

  const handleInlineAdd = React.useCallback<TOnInlineAdd>(
    async (changes) => {
      setLoading(true);
      const vehiclesListing = changes[0]?.registrationNumber;
      const vehicleIds = vehiclesListing
        ?.filter(({value}: Listing) => value)
        .map(({value}: Listing) => value) as number[];
      try {
        if (vehicleGroupId && vehicleIds?.length > 0) {
          await vehicleGroupApi.apiVehicleGroupVehicleGroupIdAssignPost({
            vehicleGroupId,
            body: {vehicleIds: vehicleIds},
          });
          await loadList();
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to assign vehicles to group');
      } finally {
        setLoading(false);
      }
    },
    [loadList, notify, setLoading, vehicleGroupId]
  );

  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  const value: VehicleGroupVehiclesContextProps = {
    // Template Table
    list,
    loadList,
    hasPermission,
    loading: loading || loadingSingleItem,
    cleanupList,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onInlineAdd: RoleService.hasPermission(
      'Vehicle VehicleGroup Assign',
      'Assign'
    )
      ? handleInlineAdd
      : undefined,
    onDelete: RoleService.hasPermission('Vehicle VehicleGroup Assign', 'Assign')
      ? handleDelete
      : undefined,
    onRefresh: handleRefresh,
  };

  return (
    <VehicleGroupVehiclesContext.Provider value={value}>
      {children}
    </VehicleGroupVehiclesContext.Provider>
  );
};
