import React from 'react';
import {
  ApiNodeNodeIdSubNodeGetRequest,
  SubNode,
  SubNodeList,
} from '@onroadvantage/onroadvantage-api';
import {useAppNotifications} from '../../../contexts';
import {nodeApi} from '../../../api';
import {
  TemplateTableContextProps,
  TLoadList,
  useTemplateTable,
} from '../../../factory/template';
import {SiteContext} from '../SiteContext';
import {FormikHelpers} from 'formik/dist/types';
import {ISiteSubNodeForm} from './SiteSubNodeForm';
import {useHistory} from 'react-router-dom';

export interface SiteSubNodeContextProps
  extends TemplateTableContextProps<SubNode, SubNodeList> {
  subNode: SubNode | undefined;
  subNodeId: number | undefined;
  setSubNodeId: React.Dispatch<React.SetStateAction<number | undefined>>;
  submitting: boolean;
  loadingSingleItem: boolean;
  loadSubNode: () => Promise<void>;
  onDetailsFormSubmit: (
    values: ISiteSubNodeForm,
    formikHelpers: FormikHelpers<ISiteSubNodeForm>
  ) => void;
}

export const SiteSubNodeContext = React.createContext<SiteSubNodeContextProps>({
  // Template Table Defaults
  loading: false,
  list: [],
  currentPage: 1,
  loadList: async () => {},
  // SiteSubNode
  loadSubNode: async () => {},
  onDetailsFormSubmit: () => {},
  subNode: undefined,
  subNodeId: undefined,
  setSubNodeId: () => null,
  submitting: false,
  loadingSingleItem: false,
});

interface SiteSubNodeContextProviderProps {
  siteSubNodeId?: number;
}

export const SiteSubNodeContextProvider: React.FC<
  SiteSubNodeContextProviderProps
> = ({children}) => {
  const history = useHistory();
  const notify = useAppNotifications();
  const {siteId} = React.useContext(SiteContext);
  // Template Table
  const [
    {
      // States
      currentPage,
      filters,
      itemTotal,
      hasPermission,
      list,
      loading,
      loadingSingleItem,
      pageSize,
      pageTotal,
      sorting,
    },
    {
      // Getters
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
      setLoadingSingleItem,
    },
  ] = useTemplateTable<SubNode, ApiNodeNodeIdSubNodeGetRequest>({
    editPermission: 'Edit Site',
    addPermission: 'Add Site',
    deletePermission: 'Delete Site',
    downloadPermission: 'Site ListDownload',
    viewPermission: 'Site List',
  });

  const loadList = React.useCallback<TLoadList<SubNodeList>>(
    async (options) => {
      setLoading(true);
      try {
        if (siteId) {
          const requestObj = getRequestObj(
            ['name', 'description', 'externalReference', 'taskTemplateName'],
            options,
            {
              nodeId: siteId,
            }
          );
          const response = await nodeApi.apiNodeNodeIdSubNodeGet({
            ...requestObj,
          });
          return getResponse(response, options);
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to load site sub node list');
      } finally {
        setLoading(false);
      }
    },
    [getRequestObj, getResponse, notify, setLoading, siteId]
  );
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const handleDelete = React.useCallback(
    async (row: SubNode) => {
      setLoading(true);
      try {
        if (siteId && row.id) {
          await nodeApi.apiNodeNodeIdSubNodeSubNodeIdDelete({
            nodeId: siteId,
            subNodeId: row.id,
          });
          await loadList();
          notify('success', 'Deleted sub-node');
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to delete sub-node');
      } finally {
        setLoading(false);
      }
    },
    [loadList, notify, setLoading, siteId]
  );

  const handleAdd = React.useCallback(() => {
    if (siteId) {
      history.push(`/sitelist/${siteId}/subnodelist/add`);
    }
  }, [history, siteId]);

  const handleNavigate = React.useCallback(
    (row: SubNode) => {
      if (siteId && row.id) {
        history.push(`/sitelist/${siteId}/subnodelist/${row.id}`);
      }
    },
    [history, siteId]
  );

  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  // Sub Node
  const [subNode, setSubNode] = React.useState<SubNode | undefined>();
  const [subNodeId, setSubNodeId] = React.useState<number | undefined>();

  const handleLoadSubNode = React.useCallback(async () => {
    setLoadingSingleItem(true);
    try {
      if (siteId && subNodeId) {
        const response = await nodeApi.apiNodeNodeIdSubNodeSubNodeIdGet({
          nodeId: siteId,
          subNodeId: subNodeId,
        });
        setSubNode(response);
      }
    } catch (e) {
      notify('error', e.message ?? 'Failed to load sub node');
    } finally {
      setLoadingSingleItem(false);
    }
  }, [notify, setLoadingSingleItem, siteId, subNodeId]);

  const handleDetailsFormSubmit = React.useCallback<
    SiteSubNodeContextProps['onDetailsFormSubmit']
  >(
    async (values, formikHelpers) => {
      formikHelpers.setSubmitting(true);
      setSubmitting(true);
      try {
        if (siteId) {
          const {taskTemplate, ...otherValues} = values;
          if (subNodeId) {
            await nodeApi.apiNodeNodeIdSubNodeSubNodeIdPatch({
              nodeId: siteId,
              subNodeId: subNodeId,
              body: {taskTemplateId: taskTemplate.value, ...otherValues},
            });
            notify('success', 'Updated sub node');
            history.goBack();
          } else if (taskTemplate.value) {
            await nodeApi.apiNodeNodeIdSubNodePost({
              nodeId: siteId,
              body: {taskTemplateId: taskTemplate.value, ...otherValues},
            });
            notify('success', 'Added sub node');
            history.goBack();
          }
        }
      } finally {
        formikHelpers.setSubmitting(false);
        setSubmitting(false);
      }
    },
    [history, notify, siteId, subNodeId]
  );

  React.useEffect(() => {
    handleLoadSubNode();
    return () => setSubNode(undefined);
  }, [handleLoadSubNode]);

  const value: SiteSubNodeContextProps = {
    // Template Table
    list,
    loadList,
    hasPermission,
    loading: loading || submitting,
    cleanupList,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    // onInlineAdd: hasPermission.edit ? handleInlineAdd : undefined,
    // onInlineEdit: hasPermission.edit ? handleInlineEdit : undefined,
    onAdd: hasPermission.edit ? handleAdd : undefined,
    onNavigate: handleNavigate,
    onDelete: hasPermission.edit ? handleDelete : undefined,
    onRefresh: handleRefresh,
    submitting,
    // Sub Node
    loadSubNode: handleLoadSubNode,
    onDetailsFormSubmit: handleDetailsFormSubmit,
    subNode,
    loadingSingleItem,
    subNodeId,
    setSubNodeId,
  };

  return (
    <SiteSubNodeContext.Provider value={value}>
      {children}
    </SiteSubNodeContext.Provider>
  );
};
