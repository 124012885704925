import React from 'react';
import {useOrderRecipientStyles} from './OrderRecipient.style';
import {RecipientDetails} from './recipientDetails';
import {Typography} from '@mui/material';
import {OrderRecipientContext} from './OrderRecipientContext';
import {RecipientMap} from './recipientMap';
import {RecipientInvalid} from '../recipientInvalid';

export const OrderRecipient: React.FC = () => {
  const classes = useOrderRecipientStyles();
  const {invalid, refreshTime} = React.useContext(OrderRecipientContext);

  if (invalid) return <RecipientInvalid />;

  return (
    <div className={classes.root}>
      <div className={classes.refreshBanner}>
        <Typography variant="subtitle1">
          Last Auto Refresh at {refreshTime}
        </Typography>
      </div>
      <Typography component="h1" variant="h3" className={classes.title}>
        Receipt
      </Typography>
      <div className={classes.mainLayout}>
        <RecipientMap />
        <RecipientDetails />
      </div>
    </div>
  );
};
