import React from 'react';
import * as Yup from 'yup';
import {Driver, Listing} from '@onroadvantage/onroadvantage-api';
import {DriverContext, DriverContextProps, IDriverForm} from '../DriverContext';
import {
  FormikAutocompleteWithListing,
  FormikDatePicker,
  FormikTextField,
} from '../../formik';
import {Loader} from '../../loader';
import {NUMBER_ERROR_MESSAGE} from '../../../factory/template';
import {TemplateForm} from '../../../factory/template';

const listingSchema: Yup.SchemaOf<Listing> = Yup.object({
  label: Yup.string().default(undefined),
  metaData: Yup.string().default(undefined),
  value: Yup.number().typeError(NUMBER_ERROR_MESSAGE).default(undefined),
});
const schema: Yup.SchemaOf<IDriverForm> = Yup.object({
  contactNumber: Yup.string()
    .nullable()
    .matches(/^[+][0-9]*$/, {
      message: 'Requires a phone number with an international dialing code.',
      excludeEmptyString: true,
    })
    .required(),
  contract: listingSchema.nullable().required('Required'),
  externalExtendedId: Yup.string().required(),
  externalEmployeeNumber: Yup.string().required(),
  firstname: Yup.string().required('Required'),
  lastname: Yup.string().required(),
  licenseType: Yup.string().required(),
  licenseExpiry: Yup.date().required(),
});

const getInitialValues = (driver?: Driver | null): IDriverForm | undefined => {
  if (driver) {
    return {
      contactNumber: driver.contactNumber ?? '',
      contract: {value: driver.contract?.id, label: driver.contract?.name},
      externalExtendedId: driver.externalExtendedId ?? '',
      externalEmployeeNumber: driver.externalEmployeeNumber ?? '',
      firstname: driver.firstname ?? '',
      lastname: driver.lastname ?? '',
      licenseType: driver.licenseType ?? '',
      licenseExpiry: driver.licenseExpiry ?? new Date(),
    };
  }
  return undefined;
};

interface DriverDetailsFormProps {
  isAdd?: boolean;
}

export const DriverDetailsForm: React.FC<DriverDetailsFormProps> = ({
  isAdd,
}) => {
  const {detailsRef, loading, submitting, driver, onDetailsFormSubmit} =
    React.useContext<DriverContextProps>(DriverContext);
  const [initialValues, setInitialValues] = React.useState<IDriverForm>();

  React.useEffect(() => {
    const values = getInitialValues(driver);

    if (values) {
      setInitialValues(values);
    } else if (isAdd) {
      setInitialValues({
        contactNumber: '',
        contract: {value: undefined, label: undefined},
        externalExtendedId: '',
        externalEmployeeNumber: '',
        firstname: '',
        lastname: '',
        licenseType: '',
        licenseExpiry: new Date(),
      });
    }
  }, [isAdd, driver, setInitialValues]);

  if (loading) {
    return <Loader />;
  }

  if (!initialValues) {
    return <div>no initial values</div>;
  }

  return (
    <TemplateForm<IDriverForm>
      initialValues={initialValues}
      onSubmit={onDetailsFormSubmit}
      validationSchema={schema}
      innerRef={detailsRef}
      submitting={submitting}
      permission={{
        name: `${isAdd ? 'Add' : 'Edit'} Driver`,
        type: isAdd ? 'Add' : 'Edit',
      }}
    >
      <FormikTextField
        name="firstname"
        placeholder="Enter the driver first name"
        label="Driver First Name"
        fullWidth
      />
      <FormikTextField
        name="lastname"
        placeholder="Enter the driver last name"
        label="Driver Last Name"
        fullWidth
      />
      <FormikTextField
        name="externalEmployeeNumber"
        placeholder="Enter the driver employee number"
        label="Driver Employee Number"
        fullWidth
      />
      <FormikTextField
        name="externalExtendedId"
        placeholder="Enter the driver tag"
        label="Driver Tag"
        fullWidth
      />
      <FormikTextField
        name="mixOrganisationGroupName"
        placeholder="Enter the driver organisation name"
        label="Driver Organisation Name"
        fullWidth
        disabled
      />
      <FormikTextField
        name="licenseType"
        placeholder="Enter the driver license type"
        label="Driver License Type"
        fullWidth
      />
      <FormikDatePicker
        InputLabelProps={{shrink: true}}
        name="licenseExpiry"
        label="Driver License Expiry"
        fullWidth
      />
      <FormikTextField
        name="contactNumber"
        placeholder="Enter the driver contact number"
        label="Driver Contact Number"
        fullWidth
      />
      <FormikAutocompleteWithListing
        id="contract"
        name="contract"
        label="Contract"
        placeholder="Select a contract"
        model="Contract"
      />
    </TemplateForm>
  );
};
