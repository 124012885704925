import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

const MasterTripRecipientControllerStyles = (theme: Theme) =>
  createStyles({
    header: {
      background: theme.palette.primary.light,
      padding: '0.5rem',
      width: '100%',
      height: '3rem',
      maxWidth: '100vw',
      display: 'grid',
      placeContent: 'center',
    },
    mainContent: {
      width: '100%',
      maxWidth: '90rem',
      height: '90%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      margin: 'auto',
    },
    root: {
      minHeight: '100vh',
      width: '100%',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
  });

export const useMasterTripRecipientControllerStyles = makeStyles(
  MasterTripRecipientControllerStyles,
  {name: 'MasterTripRecipientControllerStyles'}
);
