import React from 'react';
import L from 'leaflet';
import BaseMarker from './BaseMarker';
import {inject, observer} from 'mobx-react';

interface Props {
  position: [number, number]; // lat , lng
  stop?: any;
  number: any;
}

class NumberMarker extends React.Component<Props> {
  render() {
    const {position, number} = this.props;

    const Icon = L.ExtraMarkers.icon({
      icon: 'fa-number',
      markerColor: 'green',
      shape: 'penta',
      prefix: 'fa',
      number: `${number}`,
    });

    return <BaseMarker position={position} icon={Icon} />;
  }
}

export default inject('mapDisplayStore')(observer(NumberMarker));
