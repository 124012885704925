import React from 'react';
import {ApiDocsFrame} from './ApiDocsFrame';
import {Stack} from '@mui/material';

export const DeveloperApiDocs: React.FC = () => {
  return (
    <Stack flex={1}>
      <ApiDocsFrame title="Developer Api Docs" url="/package/swagger-ui/" />
    </Stack>
  );
};
