import React from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';

import {RefreshOutlined, StopOutlined, Timer} from '@mui/icons-material';
import {Box, CircularProgress, styled} from '@mui/material';
import {
  getDashboardPreference,
  setDashboardPreference,
} from '../../../service/Util';
import {useLocation} from 'react-router-dom';
import {createStyles, makeStyles} from '@mui/styles';

const actions = [
  {icon: <Timer />, name: '1 minute', value: 60},
  {icon: <Timer />, name: '5 minutes', value: 5 * 60},
  {icon: <Timer />, name: '15 minutes', value: 15 * 60},
  {icon: <Timer />, name: '30 minutes', value: 30 * 60},
  {icon: <Timer />, name: '60 minutes', value: 60 * 60},
];

interface Props {
  didLoad: boolean;
  handleRefresh: () => void;
}

const StyledSpeedDialBox = styled(Box)(({theme}) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: theme.zIndex.speedDial,
}));

const StyledSpeedDial = styled(SpeedDial)(() => ({
  padding: '3px',
}));

const StyledCircularProgress = styled(CircularProgress)(() => ({
  position: 'absolute',
  pointerEvents: 'none',
}));

const useStyles = makeStyles(() =>
  createStyles({tooltip: {whiteSpace: 'nowrap'}})
);

export default function DashboardRefreshSpeedDial({
  didLoad,
  handleRefresh,
}: Props) {
  const classes = useStyles();
  const {search} = useLocation();
  const [counter, setCounter] = React.useState(1);
  const [refreshPoint, setRefreshPoint] = React.useState(0);

  React.useEffect(() => {
    if (new URLSearchParams(search).has('refreshTimeout')) {
      setRefreshPoint(
        Number(new URLSearchParams(search).get('refreshTimeout')) >= 60
          ? Number(new URLSearchParams(search).get('refreshTimeout'))
          : 60
      );
    }
    return () => {
      setRefreshPoint(0);
    };
  }, [search]);

  React.useEffect(() => {
    const existingDashboardPreference = getDashboardPreference();
    if (existingDashboardPreference > 0) {
      setRefreshPoint(existingDashboardPreference);
    }
  }, []);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (refreshPoint !== 0 && counter + 1 === refreshPoint) {
        setCounter(1);
        handleRefresh();
      } else if (didLoad) {
        setCounter(counter + 1);
      }
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [counter, refreshPoint, handleRefresh, didLoad]);

  const handleTimerSelection = (value: number) => {
    setDashboardPreference(value);
    setRefreshPoint(value);
    setCounter(1);
  };

  const refreshProgress =
    refreshPoint && counter ? (counter / refreshPoint) * 100 : 0;

  return (
    <StyledSpeedDialBox>
      <StyledCircularProgress
        color="secondary"
        size={64}
        variant="determinate"
        value={refreshProgress}
      />
      <StyledSpeedDial
        ariaLabel="Refresh speed dial"
        icon={<RefreshOutlined />}
        direction="down"
      >
        {refreshPoint > 0 && (
          <SpeedDialAction
            key="Stop Refresh"
            icon={<StopOutlined />}
            classes={{staticTooltipLabel: classes.tooltip}}
            tooltipTitle="Stop Refresh"
            tooltipOpen
            onClick={() => handleTimerSelection(0)}
          />
        )}
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={
              <Timer
                color={refreshPoint === action.value ? 'primary' : 'action'}
              />
            }
            tooltipTitle={action.name}
            classes={{staticTooltipLabel: classes.tooltip}}
            tooltipOpen
            onClick={() => handleTimerSelection(action.value)}
          />
        ))}
        {new URLSearchParams(search).has('refreshTimeout') &&
          Number(new URLSearchParams(search).get('refreshTimeout')) > 60 && (
            <SpeedDialAction
              key="Stop"
              icon={
                <Timer
                  color={
                    refreshPoint ===
                    Number(new URLSearchParams(search).get('refreshTimeout'))
                      ? 'primary'
                      : 'action'
                  }
                />
              }
              classes={{staticTooltipLabel: classes.tooltip}}
              tooltipTitle={`${
                Number(new URLSearchParams(search).get('refreshTimeout')) / 60
              } minutes`}
              tooltipOpen
            />
          )}
      </StyledSpeedDial>
    </StyledSpeedDialBox>
  );
}
