import React from 'react';
import {useHistory} from 'react-router-dom';
import {
  Listing,
  MasterRouteLeg as MasterRouteLegType,
  MasterRouteLegWaypoint,
  MasterRoutePost,
} from '@onroadvantage/onroadvantage-api';
import {FormikHelpers, FormikProps} from 'formik/dist/types';
import {masterRouteApi} from '../../../api';
import {TemplateTableContextProps} from '../../../factory/template';
import {MasterRouteContext} from '../MasterRouteContext';
import {useAppNotifications} from '../../../contexts';
import {useTemplateTable} from '../../../factory/template';

export interface IMasterRouteLegDetails {
  fromNode: Listing;
  toNode: Listing;
  customer: Listing;
}

export interface MasterRouteLegContextProps
  extends TemplateTableContextProps<MasterRouteLegType> {
  changedWaypoints?: boolean;
  loadList: () => void;
  onDetailsFormSubmit: (
    values: IMasterRouteLegDetails,
    formikHelpers: FormikHelpers<IMasterRouteLegDetails>
  ) => void;
  setMasterRouteLegId: (value: number | undefined) => void;
  submitting: boolean;
  onRefreshLeg: () => void;
  masterRouteLeg?: MasterRouteLegType;
  updateWaypoints: (filtered: MasterRouteLegWaypoint[]) => void;
  masterRouteLegId?: number;
  detailsRef?: React.Ref<FormikProps<IMasterRouteLegDetails>>;
}

export const MasterRouteLegContext =
  React.createContext<MasterRouteLegContextProps>({
    // Template Table Defaults
    loading: false,
    list: [],
    currentPage: 1,
    // MasterRouteLeg
    loadList: async () => {},
    cleanupList: () => null,
    updateWaypoints: () => null,
    onDetailsFormSubmit: () => null,
    setMasterRouteLegId: () => null,
    onRefreshLeg: () => null,
    submitting: false,
  });

interface MasterRouteLegContextProviderProps {
  masterRouteLegId?: number;
}

export const MasterRouteLegContextProvider: React.FC<
  MasterRouteLegContextProviderProps
> = ({children}) => {
  const history = useHistory();
  const notify = useAppNotifications();
  const {
    loading: masterRouteLoading,
    masterRoute,
    masterRouteId,
    loadMasterRoute,
  } = React.useContext(MasterRouteContext);
  // Template Table
  const [
    {loading, loadingSingleItem, list, currentPage},
    {setList, setLoading, setLoadingSingleItem, cleanupList},
  ] = useTemplateTable<MasterRouteLegType, any>();
  // masterRouteLeg
  const [changedWaypoints, setChangedWaypoints] =
    React.useState<boolean>(false);
  const [masterRouteLeg, setMasterRouteLeg] = React.useState<
    MasterRouteLegType | undefined
  >();
  const [masterRouteLegId, setMasterRouteLegId] = React.useState<
    number | undefined
  >();
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const detailsRef = React.useRef<FormikProps<IMasterRouteLegDetails>>(null);

  const loadList = React.useCallback(async () => {
    setLoading(true);
    setList(masterRoute?.legs ?? []);
    setLoading(false);
  }, [masterRoute?.legs, setList, setLoading]);

  const loadMasterRouteLeg = React.useCallback(async () => {
    setLoadingSingleItem(true);
    try {
      if (masterRouteId) {
        const response = await masterRouteApi.apiMasterRouteMasterRouteIdGet({
          masterRouteId,
        });
        if (
          response.legs &&
          response.legs.find((leg) => leg.id === masterRouteLegId)
        ) {
          setList(response.legs);
          setMasterRouteLeg(
            response.legs.find((leg) => leg.id === masterRouteLegId)
          );
        }
      } else {
        setMasterRouteLeg(undefined);
      }
    } catch (e) {
      notify('error', e.message ?? 'Error loading Master Route');
    } finally {
      setLoadingSingleItem(false);
    }
  }, [masterRouteId, masterRouteLegId, notify, setList, setLoadingSingleItem]);

  const handleDetailsFormSubmit = React.useCallback(
    async (
      values: IMasterRouteLegDetails,
      formikHelpers: FormikHelpers<IMasterRouteLegDetails>
    ) => {
      setSubmitting(true);
      try {
        if (masterRoute) {
          const newLeg: MasterRouteLegType = {
            customerId: values.customer.value ?? undefined,
            fromNodeId: values.fromNode.value ?? null,
            toNodeId: values.toNode.value ?? null,
            waypoints: masterRouteLeg?.waypoints ?? [],
          };

          const prevItems =
            masterRoute.legs?.filter((leg) => leg.id !== masterRouteLeg?.id) ??
            [];
          const newValues: MasterRoutePost = {
            ...masterRoute,
            legs: [...prevItems, newLeg],
          };
          const response =
            await masterRouteApi.apiMasterRouteMasterRouteIdPatch({
              masterRouteId: masterRoute.id,
              body: newValues,
            });
          setMasterRouteLegId(
            response.legs?.find(
              (leg) =>
                leg.customer?.id === values.customer.value &&
                leg.fromNode?.id === values.fromNode.value &&
                leg.toNode?.id === values.toNode.value
            )?.id ?? undefined
          );
          setMasterRouteLeg(
            response.legs?.find(
              (leg) =>
                leg.customer?.id === values.customer.value &&
                leg.fromNode?.id === values.fromNode.value &&
                leg.toNode?.id === values.toNode.value
            ) ?? undefined
          );
          await loadMasterRoute();
          setList(response.legs ?? []);
        }
        notify(
          'success',
          `${masterRouteLegId ? 'Updated' : 'Added'} Master Route Leg`
        );
      } catch (e) {
        notify(
          'error',
          e.message ??
            `Error ${masterRouteLegId ? 'updating' : 'adding'} Master Route Leg`
        );
      } finally {
        formikHelpers.setSubmitting(false);
        setSubmitting(false);
      }
    },
    [
      masterRoute,
      notify,
      masterRouteLegId,
      masterRouteLeg?.waypoints,
      masterRouteLeg?.id,
      loadMasterRoute,
      setList,
    ]
  );

  const handleDelete = React.useCallback(
    async (row: MasterRouteLegType) => {
      setLoading(true);
      try {
        if (row.id && masterRoute?.legs) {
          const newValues: MasterRoutePost = {
            ...masterRoute,
            legs: list.filter((leg) => leg.id !== row.id) ?? [],
          };
          const response =
            await masterRouteApi.apiMasterRouteMasterRouteIdPatch({
              masterRouteId: masterRoute.id,
              body: newValues,
            });
          if (response.legs) setList(response.legs);
          else await loadList();
          notify('success', 'Master Route Leg Deleted');
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to delete master route leg');
      } finally {
        setLoading(false);
      }
    },
    [list, loadList, masterRoute, notify, setList, setLoading]
  );

  const handleUpdateWaypoints = React.useCallback((filtered) => {
    setMasterRouteLeg((prevLeg) =>
      prevLeg ? {...prevLeg, waypoints: filtered} : undefined
    );
    setChangedWaypoints(true);
  }, []);

  const handleAdd = React.useCallback(() => {
    history.push(`/masterroutelist/${masterRouteId}/leg/add`);
  }, [history, masterRouteId]);

  const handleNavigate = React.useCallback(
    (row: MasterRouteLegType) => {
      history.push(`/masterroutelist/${masterRouteId}/leg/${row.id}`);
    },
    [history, masterRouteId]
  );
  const handleRefreshLeg = React.useCallback(async () => {
    setLoadingSingleItem(true);
    setMasterRouteLegId(undefined);
    setMasterRouteLeg(undefined);
    // await loadMasterRouteLeg();
    setLoadingSingleItem(false);
  }, [setLoadingSingleItem]);

  const value: MasterRouteLegContextProps = {
    // Template Table
    loading: masterRouteLoading || loadingSingleItem || loading,
    list,
    currentPage,
    onAdd: handleAdd,
    onNavigate: handleNavigate,
    onDelete: handleDelete,
    onRefresh: loadMasterRoute,
    // MasterRouteLeg
    loadList,
    cleanupList,
    changedWaypoints,
    onDetailsFormSubmit: handleDetailsFormSubmit,
    setMasterRouteLegId,
    updateWaypoints: handleUpdateWaypoints,
    onRefreshLeg: handleRefreshLeg,
    submitting,
    masterRouteLeg,
    masterRouteLegId,
    detailsRef,
  };

  React.useEffect(() => {
    loadMasterRouteLeg();
    return () => {
      setMasterRouteLeg(undefined);
      setMasterRouteLegId(undefined);
    };
  }, [loadMasterRouteLeg]);

  return (
    <MasterRouteLegContext.Provider value={value}>
      {children}
    </MasterRouteLegContext.Provider>
  );
};
