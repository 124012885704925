import {makeStyles, createStyles} from '@mui/styles';
import {Theme} from '@mui/material/styles';

export const PlanningBoardOrdersStyles = (_theme: Theme) =>
  createStyles({
    paper: {display: 'flex', flex: 1, overflowX: 'auto'},
  });

export const usePlanningBoardOrdersStyles = makeStyles(
  PlanningBoardOrdersStyles,
  {
    name: 'PlanningBoardOrdersStyles',
  }
);
