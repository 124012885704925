import React from 'react';
import {VehicleDetails} from './vehicleDetails';
import VehicleGantt from './vehicleGantt/VehicleGantt';
import {VehicleMap} from './vehicleMap';
import RoleService from '../../service/RoleService';
import CriticalEvents from '../events/CriticalEvents';
import OperationalEvents from '../events/OperationalEvents';
import VehicleStops from './vehicleStops/VehicleStops';
import {VehicleContract} from './vehicleContract';
import {VehicleContext} from './VehicleContext';
import {Loader} from '../loader';
import {Typography} from '@mui/material';
import {
  ActualTripSummary,
  CriticalEventDump,
  OperationalEventDump,
  Node,
} from '@onroadvantage/onroadvantage-api';
import {mapDisplayStore, timelineDisplayStore} from '../../stores/mobxStores';
import {
  criticalEventApi,
  operationalEventApi,
  telematicsEventApi,
  vehicleApi,
} from '../../api';
import {MomentService} from '../../service';
import {
  getFirstTelematicsEvent,
  getLastTelematicsEvent,
  getNearbyNodes,
} from '../../service/Util';
import {useLocation} from 'react-router-dom';
import moment from 'moment';
import {DateTime} from 'luxon';
import {TelematicsGeojson} from '../trip/tripContext';

const styles = {
  card: {
    marginBottom: 12,
  },
};

interface EventType extends CriticalEventDump {
  mapType?: string;
}

export const Vehicle: React.FC = () => {
  const {search} = useLocation();
  const {vehicle, vehicleId, loading} = React.useContext(VehicleContext);
  const [hasPermissions] = React.useState({
    criticalEvent: RoleService.hasPermission('CriticalEvent List', 'View'),
    operationalEvent: RoleService.hasPermission(
      'OperationalEvent List',
      'View'
    ),
  });
  const [actualTrip, setActualTrip] = React.useState<
    ActualTripSummary | undefined
  >({});
  const [nearbyNodes, setNearbyNodes] = React.useState<Node[]>([]);
  const [criticalEvents, setCriticalEvents] = React.useState<
    CriticalEventDump[]
  >([]);
  const [telematicsEvents, setTelematicsEvents] = React.useState<any>([]);
  const [operationalEvents, setOperationalEvents] = React.useState<
    OperationalEventDump[]
  >([]);
  const [eventsLoading, setEventsLoading] = React.useState<boolean>(true);
  const [telematicsLoading, setTelematicsLoading] =
    React.useState<boolean>(true);

  const updateTelematicsEventsRange = React.useCallback(
    async (dateRange: any) => {
      setTelematicsLoading(true);
      try {
        if (vehicleId) {
          timelineDisplayStore.setTelematicsLoading(true);
          !search &&
            timelineDisplayStore.setTelematicsEventsDateRange(dateRange);

          const [
            telematicsEventsResponse,
            criticalEventsResponse,
            operationalEventsResponse,
            adjacentTripsResponse,
          ] = await Promise.all([
            telematicsEventApi.apiTelematicsEventGet({
              vehicleId,
              startDate: dateRange?.startDate
                ? new Date(dateRange.startDate)
                : undefined,
              endDate: dateRange?.endDate
                ? new Date(dateRange.endDate)
                : undefined,
              nearbyNodes: false,
            }),
            hasPermissions.criticalEvent
              ? criticalEventApi.apiCriticalEventGet({
                  vehicleId,
                  startDate: dateRange?.startDate
                    ? new Date(
                        MomentService.startOfDate(dateRange.startDate)
                      ).toISOString()
                    : undefined,
                  endDate: dateRange?.endDate
                    ? new Date(
                        MomentService.endOfDate(dateRange.endDate)
                      ).toISOString()
                    : undefined,
                  perPage: 500,
                  page: 1,
                })
              : undefined,
            hasPermissions.operationalEvent
              ? operationalEventApi.apiOperationalEventGet({
                  vehicleId,
                  startDate: dateRange?.startDate
                    ? new Date(
                        MomentService.startOfDate(dateRange.startDate)
                      ).toISOString()
                    : undefined,
                  endDate: dateRange?.endDate
                    ? new Date(
                        MomentService.endOfDate(dateRange.endDate)
                      ).toISOString()
                    : undefined,
                  nearbyNodes: false,
                  perPage: 500,
                  page: 1,
                })
              : undefined,
            dateRange?.startDate && dateRange?.endDate
              ? vehicleApi.apiVehicleVehicleIdNearbyTripsTimeGet({
                  vehicleId2: vehicleId,
                  startDate: new Date(dateRange.startDate),
                  endDate: new Date(dateRange.endDate),
                })
              : undefined,
          ]);
          timelineDisplayStore.setAdjacentTrips(
            adjacentTripsResponse
              ? adjacentTripsResponse.items
                ? adjacentTripsResponse.items
                : []
              : []
          );

          const nearbyNodes = await getNearbyNodes(
            criticalEventsResponse,
            telematicsEventsResponse
          );
          timelineDisplayStore.setTelematicsLoading(false);
          setOperationalEvents(operationalEventsResponse?.items ?? []);
          setCriticalEvents(criticalEventsResponse?.items ?? []);
          setActualTrip(telematicsEventsResponse?.actualTrip ?? {});
          setNearbyNodes(nearbyNodes);
          let geojson: TelematicsGeojson | undefined;
          if (
            telematicsEventsResponse.geojson != null &&
            'coordinates' in telematicsEventsResponse.geojson &&
            'coordTimes' in telematicsEventsResponse.geojson
          ) {
            geojson = {
              ...(telematicsEventsResponse.geojson as TelematicsGeojson),
              coordinates: (
                telematicsEventsResponse.geojson as TelematicsGeojson
              ).coordinates.map((coordinate: number[]) => [
                coordinate[1],
                coordinate[0],
              ]),
            };
          }
          setTelematicsEvents(geojson);
        }
      } finally {
        setTelematicsLoading(false);
      }
    },
    [
      hasPermissions.criticalEvent,
      hasPermissions.operationalEvent,
      search,
      vehicleId,
    ]
  );

  React.useEffect(() => {
    const initialize = async () => {
      const {telematicsEventsDateRange} = timelineDisplayStore;
      if (
        vehicleId &&
        telematicsEventsDateRange.endDate &&
        telematicsEventsDateRange.startDate
      ) {
        const endDate = moment(telematicsEventsDateRange.endDate ?? undefined)
          .subtract(moment.duration('03:00:00'))
          .toDate();
        const startDate = moment(
          telematicsEventsDateRange.startDate ?? undefined
        )
          .subtract(moment.duration('03:00:00'))
          .toDate();
        const adjacentTrips =
          await vehicleApi.apiVehicleVehicleIdNearbyTripsTimeGet({
            vehicleId2: vehicleId, // TODO why vehicleId2???
            startDate,
            endDate,
          });
        timelineDisplayStore.setAdjacentTrips(
          adjacentTrips ? (adjacentTrips.items ? adjacentTrips.items : []) : []
        );
      }
      await updateTelematicsEventsRange(telematicsEventsDateRange);
    };
    initialize();
  }, [updateTelematicsEventsRange, vehicleId]);

  React.useEffect(() => {
    const getEvents = async () => {
      setEventsLoading(true);
      try {
        if (search) {
          const params = new URLSearchParams(search);
          const criticalEventId = params.get('criticalEventId');
          const operationalEventId = params.get('operationalEventId');
          let event: EventType | null = null;

          if (criticalEventId) {
            event = await criticalEventApi.apiCriticalEventCriticalEventIdGet({
              criticalEventId: parseInt(criticalEventId),
            });
            event.mapType = 'Critical';
          }

          if (operationalEventId) {
            event =
              await operationalEventApi.apiOperationalEventOperationalEventIdGet(
                {
                  operationalEventId: parseInt(operationalEventId),
                }
              );
            event.mapType = 'Operational';
          }
          const tripStart = actualTrip?.tripStart
            ? DateTime.fromJSDate(actualTrip.tripStart)
            : undefined;
          const tripEnd = actualTrip?.tripEnd
            ? DateTime.fromJSDate(actualTrip.tripEnd)
            : undefined;

          const tripDiff =
            tripStart && tripEnd ? tripEnd.diff(tripStart) : undefined;
          const tripDurationInHours = tripDiff?.milliseconds
            ? Math.floor(tripDiff?.milliseconds / 3600000)
            : 0;
          if (event) {
            const eventDate = DateTime.fromJSDate(event.eventDate);
            if (tripDurationInHours > 6) {
              timelineDisplayStore.setTelematicsEventsDateRange({
                startDate: tripStart?.toLocal().toJSDate(),
                endDate: tripEnd?.toLocal().toJSDate(),
              });
            } else {
              timelineDisplayStore.setTelematicsEventsDateRange({
                startDate: eventDate.toLocal().minus({hour: 3}).toJSDate(),
                endDate: eventDate.toLocal().plus({hour: 3}).toJSDate(),
              });
            }
          }
          await mapDisplayStore.setOpenEvent(event);
        }
      } finally {
        setEventsLoading(false);
      }
    };
    getEvents();
  }, [actualTrip, search]);

  React.useEffect(() => {
    const lastTelematicsEvent = getLastTelematicsEvent(telematicsEvents);
    timelineDisplayStore.setLastTelematicsEvent(lastTelematicsEvent);

    const firstTelematicsEvent = getFirstTelematicsEvent(telematicsEvents);
    timelineDisplayStore.setFirstTelematicsEvent(firstTelematicsEvent);
    return () => undefined;
  }, [telematicsEvents]);

  React.useEffect(() => {
    document.title = `Vantage | ${
      vehicle?.registrationNumber ? vehicle.registrationNumber : 'Vehicle'
    }`;
  }, [vehicle?.registrationNumber]);

  if (loading || eventsLoading || telematicsLoading) {
    return <Loader />;
  }

  if (!vehicle || !vehicle?.id)
    return (
      <Typography variant="h4" component="h4" align="center">
        No Vehicle Data Found
      </Typography>
    );

  return (
    <div style={{width: '100%'}} key={vehicle.id}>
      <VehicleDetails view />
      <VehicleDetails />
      {actualTrip && (
        <VehicleGantt
          vehicle={vehicle}
          style={styles.card}
          updateTelematicsEventsRange={updateTelematicsEventsRange}
          actualTrip={actualTrip}
        />
      )}
      <VehicleMap
        nearbyNodes={nearbyNodes}
        criticalEvents={criticalEvents ? criticalEvents.slice(0, 50) : []}
        telematicsEvents={telematicsEvents}
        vehicle={vehicle}
        loading={loading}
        actualTrip={actualTrip}
      />
      {hasPermissions.criticalEvent ? (
        <CriticalEvents
          events={criticalEvents ? criticalEvents.slice(0, 50) : []}
          loading={loading}
          style={styles.card}
        />
      ) : null}
      {hasPermissions.operationalEvent ? (
        <OperationalEvents
          events={operationalEvents ? operationalEvents.slice(0, 50) : []}
          loading={loading}
          style={styles.card}
        />
      ) : null}
      {vehicle.lastSeen && actualTrip ? (
        <VehicleStops
          vehicle={vehicle}
          loading={loading}
          style={styles.card}
          actualTrip={actualTrip}
        />
      ) : null}
      <VehicleContract
        vehicle={vehicle}
        loading={loading}
        style={styles.card}
      />
    </div>
  );
};
