import React from 'react';
import {
  TemplateTable,
  TemplateTableConfig,
  TOnInlineAdd,
} from '../../factory/template';
import {
  DriverWorkflowConfigTaskTemplate,
  Listing,
} from '@onroadvantage/onroadvantage-api';
import {WorkflowConfigContext} from './WorkflowConfigContext';
import {workflowConfigApi} from '../../api';
import {useAppNotifications} from '../../contexts';

interface IWorkflowConfigTemplateTaskListAdd {
  taskTemplate: Listing;
  mandatory: boolean | undefined;
  sequence: number | undefined;
  forceSequence: boolean | undefined;
  acknowledgeEnabled: boolean | undefined;
  resetEnabled: boolean | undefined;
}

export const WorkflowConfigTaskTemplateList: React.FC = () => {
  const notify = useAppNotifications();
  const {
    workflowConfigId,
    workflowConfig,
    loading,
    loadingSingleItem,
    loadWorkflowConfig,
  } = React.useContext(WorkflowConfigContext);
  const [localSubmitting, setLocalSubmitting] = React.useState<boolean>(false);

  const [config] = React.useState<
    TemplateTableConfig<DriverWorkflowConfigTaskTemplate>
  >({
    columns: [
      {
        name: 'taskTemplate',
        label: 'Template Name',
        type: 'string',
        model: 'TaskTemplate',
        filterListingsByLabel: ['Order', 'Order Details', 'Order Line', 'Trip'],
        enableAutocomplete: true,
        enableEditing: true,
        getValue: ({taskTemplate}) => taskTemplate?.name,
      },
      {
        name: 'mandatory',
        label: 'Mandatory',
        type: 'bool',
        enableEditing: true,
      },
      {
        name: 'sequence',
        label: 'Sequence',
        type: 'number',
        enableEditing: true,
      },
      {
        name: 'forceSequence',
        label: 'Force Sequence',
        type: 'bool',
        enableEditing: true,
      },
      {
        name: 'acknowledgeEnabled',
        label: 'Acknowledge Enabled',
        type: 'bool',
        enableEditing: true,
      },
      {
        name: 'resetEnabled',
        label: 'Reset Enabled',
        type: 'bool',
        enableEditing: true,
      },
    ],
    disablePagination: true,
    disableToolbar: true,
    deleteMessage: (row) => ({
      title: 'Workflow Config Task Template',
      items: {TemplateName: row.taskTemplate?.name},
    }),
    identifier: 'WORKFLOW_CONFIG_TASK_TEMPLATE_LIST',
  });

  const handleDelete = React.useCallback(
    async (row: DriverWorkflowConfigTaskTemplate) => {
      setLocalSubmitting(true);
      try {
        if (row.id) {
          await workflowConfigApi.apiWorkflowConfigTaskTemplateWorkflowConfigTaskTemplateIdDelete(
            {
              workflowConfigTaskTemplateId: row.id,
            }
          );
          notify('success', 'Deleted workflow config task template');
          await loadWorkflowConfig();
        }
      } catch (e) {
        notify(
          'error',
          e.message ?? 'Failed to delete workflow config task template'
        );
      } finally {
        setLocalSubmitting(false);
      }
    },
    [loadWorkflowConfig, notify]
  );

  const handleInlineAdd = React.useCallback<TOnInlineAdd>(
    async (changes) => {
      setLocalSubmitting(true);
      try {
        if (workflowConfigId) {
          const values = changes[0] as IWorkflowConfigTemplateTaskListAdd;
          const {taskTemplate, ...otherValues} = values;
          if (taskTemplate.value) {
            await workflowConfigApi.apiWorkflowConfigTaskTemplatePost({
              body: {
                workflowConfigId,
                taskTemplateId: taskTemplate.value,
                mandatory: otherValues.mandatory,
                sequence: otherValues.sequence,
                forceSequence: otherValues.forceSequence,
                acknowledgeEnabled: otherValues.acknowledgeEnabled,
                resetEnabled: otherValues.resetEnabled,
              },
            });
            notify('success', 'Add task template to workflow config');
            await loadWorkflowConfig();
          }
        }
      } catch (e) {
        notify(
          'error',
          e.message ?? 'Failed to assign task template to workflow config'
        );
      } finally {
        setLocalSubmitting(false);
      }
    },
    [loadWorkflowConfig, notify, workflowConfigId]
  );

  return (
    <TemplateTable
      config={config}
      currentPage={1}
      list={workflowConfig?.taskTemplates ?? []}
      loading={loading || loadingSingleItem || localSubmitting}
      onInlineAdd={handleInlineAdd}
      onDelete={handleDelete}
      onDownload={undefined}
    />
  );
};
