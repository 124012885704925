import React from 'react';
import {
  TemplateTable,
  TemplateTableConfig,
  TemplateTableProps,
} from './TemplateTable';
import {TLoadList} from './TemplateTableHelpers';
import {ITemplateTablePermissions} from './useTemplateTable';

export interface ITemplatePermissions {
  add?: boolean;
  delete?: boolean;
  edit?: boolean;
  view?: boolean;
}

export interface TemplateTableContextProps<ResponseItem, ListResponse = void>
  extends Omit<TemplateTableProps<ResponseItem>, 'config'> {
  loadList: TLoadList<ListResponse> | (() => void);
  hasPermission?: ITemplateTablePermissions;
}

export interface TemplateTableContextProviderProps<T, K = void> {
  config: TemplateTableConfig<T>;
  context: React.Context<TemplateTableContextProps<T, K> & any>;
}

export const TemplateTableWrapper = <T, K = void>({
  config,
  context,
}: TemplateTableContextProviderProps<T, K>): React.ReactElement => {
  const values = React.useContext(context);
  return <TemplateTable config={config} {...values} />;
};
