import React from 'react';
import {Toolbar} from '@mui/material';
import {PlanningBoardToolbarTabs} from './PlanningBoardToolbarTabs';
import {PlanningBoardToolbarActions} from './PlanningBoardToolbarActions';
import {PlanningBoardToolbarActiveFilters} from './PlanningBoardToolbarActiveFilters';
import {usePlanningBoardToolbarStyles} from './PlanningBoardToolbar.style';

export const PlanningBoardToolbar: React.FC = () => {
  const classes = usePlanningBoardToolbarStyles();
  return (
    <Toolbar variant="dense" className={classes.toolbar} disableGutters>
      <PlanningBoardToolbarTabs />
      <PlanningBoardToolbarActiveFilters />
      <PlanningBoardToolbarActions />
    </Toolbar>
  );
};
