import {TripSetupFormStop} from '../TripSetupContext';
import {
  MasterTripPostOrder,
  MasterTripPostStop,
} from '@onroadvantage/onroadvantage-api';

export const prepareStops = (
  stops: TripSetupFormStop[]
): MasterTripPostStop[] => {
  return stops.reduce((acc: MasterTripPostStop[], stop: TripSetupFormStop) => {
    const order = stop.orders && stop.orders.length > 0 ? stop.orders[0] : null;
    if (
      order?.upliftPointNode?.value != null &&
      order?.offloadPointNode?.value != null
    ) {
      return [
        ...acc,
        {
          nodeId: order.upliftPointNode?.value,
          orders: stop.orders?.map(
            (stopOrder): MasterTripPostOrder => ({
              ...stopOrder,
              offloadPointNodeId: stopOrder.offloadPointNode?.value,
              upliftPointNodeId: stopOrder.upliftPointNode?.value,
              productId: stopOrder.product?.value,
            })
          ),
          sequence: acc.length + 1,
        },
        {
          nodeId: order.offloadPointNode?.value,
          orders: stop.orders?.map(
            (stopOrder): MasterTripPostOrder => ({
              ...stopOrder,
              offloadPointNodeId: stopOrder.offloadPointNode?.value,
              upliftPointNodeId: stopOrder.upliftPointNode?.value,
              productId: stopOrder.product?.value,
            })
          ),
          sequence: acc.length + 2,
        },
      ];
    }

    if (stop.node.value != null) {
      return [...acc, {nodeId: stop.node.value, sequence: stop.sequence}];
    }

    return acc;
  }, []);
};
