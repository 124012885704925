import React from 'react';
import {useCompartmentVerticalStyles} from './CompartmentVertical.style';
import {VehicleCompartment} from '@onroadvantage/onroadvantage-api';
import {IVantageDialogRef, VantageDialog} from '../dialog';
import {
  CompartmentVerticalCompartment,
  CompartmentVerticalCompartmentProps,
} from './CompartmentVerticalCompartment';

interface CompartmentVerticalProps
  extends Omit<CompartmentVerticalCompartmentProps, 'compartment'> {
  compartments: VehicleCompartment[];
  compartmentDialogRef: React.RefObject<IVantageDialogRef>;
  onNavigate?: (compartment: VehicleCompartment) => void;
}

export const CompartmentVertical: React.FC<CompartmentVerticalProps> = ({
  compartments,
  compartmentDialogRef,
  onNavigate,
  getVerticalCompartmentContent,
  getActive,
}) => {
  const classes = useCompartmentVerticalStyles();

  const handleNavigateInDialog = React.useCallback(
    (compartment: VehicleCompartment) => {
      if (onNavigate) {
        compartmentDialogRef.current?.closeDialog();
        onNavigate(compartment);
      }
    },
    [compartmentDialogRef, onNavigate]
  );

  return (
    <VantageDialog
      ref={compartmentDialogRef}
      title="Vehicle Vertical Compartment View"
      maxWidth="sm"
      transition="slide"
      declineTitle="Close"
      declineProps={{sx: {flex: 1}}}
      disableTitle
      disableAccept
      fullWidth
    >
      <div className={classes.root}>
        {compartments.map((compartment, index) => (
          <CompartmentVerticalCompartment
            key={compartment.id ?? index}
            compartment={compartment}
            onNavigateInDialog={handleNavigateInDialog}
            getVerticalCompartmentContent={getVerticalCompartmentContent}
            getActive={getActive}
          />
        ))}
      </div>
    </VantageDialog>
  );
};
