import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

const RecipientCardStyles = (theme: Theme) =>
  createStyles({
    avatar: {backgroundColor: theme.palette.primary.light},
    content: {paddingTop: 0},
    header: {paddingBottom: 0, cursor: 'pointer'},
    label: {
      width: 100,
      marginRight: '1.5rem',
      color: theme.palette.text.secondary,
    },
    root: {
      width: 400,
      maxWidth: 400,
      boxShadow: '2px 2px 8px 1px rgba(155, 155, 155, 0.2)',
      paddingX: 0,
      [theme.breakpoints.down('md')]: {width: '100%', maxWidth: '100%'},
    },
    row: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
      marginTop: '1rem',
    },
    value: {whiteSpace: 'nowrap'},
  });

export const useRecipientCardStyles = makeStyles(RecipientCardStyles, {
  name: 'RecipientCardStyles',
});
