import React from 'react';
import {DateTime} from 'luxon';
import Paper from '@mui/material/Paper';
import {withRouter} from 'react-router-dom';
import {
  Grid,
  PagingPanel,
  Table,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import {CustomPaging, PagingState} from '@devexpress/dx-react-grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import _ from 'lodash';
import {MomentService, UploadService} from '../../service';
import {Loader} from '../loader';
import {connectDateRange} from '../../contexts';
import NoDataLoading from '../table/NoDataLoading';
import {getAuthHeaderValues, uploadApi} from '../../api';

class Upload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: this.props.startDate,
      endDate: this.props.endDate,
      loading: true,
      uploading: false,
      tabValue: 0,
      // log
      items: [],
      logPerPages: [25, 50, 100],
      logPerPage: 50,
      logCurrentPage: 1,
      logTotalCount: 0,
    };
  }

  onFileChange = async (e) => {
    if (e.target.files.length > 0) {
      this.setState({uploading: true});
      await UploadService.upload({
        file: e.target.files[0],
      });
      await this.loadData();
    }
    this.Uploader.remove();
    this.Uploader = document.getElementById('Uploader');
    this.Uploader.onchange = this.onFileChange;
  };

  componentDidMount = async () => {
    const {items} = await UploadService.loadTemplates();
    this.setState({
      templates: items,
      loading: false,
    });
    try {
      await this.loadData();
      this.Uploader = document.getElementById('Uploader');
      this.Uploader.onchange = this.onFileChange;
    } finally {
      this.setState({uploading: false});
    }
  };

  loadData = async (params = {}) => {
    try {
      const {
        perPage = this.state.logPerPage,
        page = this.state.logCurrentPage,
      } = params;
      const {startDate, endDate} = this.state;
      const response = await UploadService.query({
        page,
        perPage,
        startDate: DateTime.fromISO(startDate).toJSDate(),
        endDate: DateTime.fromISO(endDate).toJSDate(),
      });
      this.setState({
        logCurrentPage: response.page,
        logPages: response.pages,
        logPerPage: response.perPage,
        logTotalCount: response.total,
        items: response.items,
        uploading: false,
      });
    } catch (e) {
      // pass
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  // eslint-disable-next-line camelcase
  async UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({loading: true});
    try {
      await this.loadData();
      this.setState({
        startDate: nextProps.startDate,
        endDate: nextProps.endDate,
      });
    } catch (e) {
      // pass
    } finally {
      this.setState({loading: false});
    }
  }

  handleChange = (event, value) => {
    this.setState({tabValue: value});
  };

  logChangeCurrentPage = (nextPage) => {
    this.setState({
      loading: true,
      items: [],
    });
    this.loadData({page: nextPage + 1});
  };

  logChangePerPage = (perPage) => {
    this.setState({
      loading: true,
      items: [],
      perPage,
    });
    this.loadData({perPage, page: 1});
  };

  render() {
    const {
      items = [],
      templates = [],
      loading,
      uploading,
      tabValue,
      // log
      logPerPages,
      logPerPage,
      logTotalCount,
      logCurrentPage,
    } = this.state;

    const onNavigate = async ({history, row}) => {
      const authHeader = getAuthHeaderValues();
      let authHeaders = {}
      authHeaders[authHeader['name']] = authHeader['value'];

      if (row.status === 'Completed') {
        const result = await uploadApi.apiUploadDumpUploadIdGetRaw({uploadId: row.id})
        const blob = await result.raw.blob()
        const url = window.URL.createObjectURL(blob)
        const linkRef = document.createElement('a');

        linkRef.href = url;
        linkRef.setAttribute('download', _.get(row, 'name', 'upload.xlsx'))

        linkRef.click()
      }
      if (tabValue === 1 && row.link) {
        window.open(history.createHref({pathname: row.link}), '_blank');
      }
    };

    const Cell = withRouter((params) => {
      if (['createdAt'].includes(params.column.name)) {
        return (
          <Table.Cell
            onClick={() => onNavigate({...params, history: this.props.history})}
            style={{cursor: 'pointer'}}
            value={MomentService.displayTimestamp(params.value)}
          />
        );
      }

      return (
        <Table.Cell
          onClick={() => onNavigate({...params, history: this.props.history})}
          style={{cursor: 'pointer'}}
          value={params.value}
        />
      );
    });

    const gridColumns = [
      {name: 'name', title: 'Name'},
      {name: 'interface', title: 'Interface'},
      {name: 'status', title: 'Status'},
      {name: 'createdBy_email', title: 'Created By'},
      {name: 'createdAt', title: 'Uploaded At'},
    ];

    return (
      <div>
        <Paper>
          <div style={{paddingBottom: 20}}>
            <div style={{padding: 10}}>
              {!uploading ? (
                <input id="Uploader" type="file" name="data" accept="*" />
              ) : (
                <Loader />
              )}
            </div>
          </div>
          <Tabs
            value={tabValue}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            style={{width: 350}}
          >
            <Tab label="Log" />
            <Tab label="Templates" />
          </Tabs>
          {tabValue === 0 && (
            <div>
              <Grid rows={items} columns={gridColumns}>
                <Table
                  cellComponent={Cell}
                  noDataRowComponent={() => (
                    <NoDataLoading
                      loading={items.length === 0 && loading}
                      numberOfColumns={gridColumns.length}
                    />
                  )}
                />
                <TableHeaderRow />
                <PagingState
                  currentPage={logCurrentPage - 1}
                  onCurrentPageChange={this.logChangeCurrentPage}
                  pageSize={logPerPage}
                  onPageSizeChange={this.logChangePerPage}
                />
                <CustomPaging totalCount={logTotalCount} />
                {!loading && <PagingPanel pageSizes={logPerPages} />}
              </Grid>
              {items.length > 0 && loading ? <Loader /> : null}
            </div>
          )}
          {tabValue === 1 && (
            <div>
              <Grid
                rows={templates}
                columns={[
                  {name: '_interface', title: 'Interface'},
                  {name: 'linkz', title: 'Click to Download'},
                ]}
              >
                <Table cellComponent={Cell} />
                <TableHeaderRow />
              </Grid>
              {loading ? <Loader /> : null}
            </div>
          )}
        </Paper>
      </div>
    );
  }
}

export default connectDateRange(Upload);
