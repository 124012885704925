import React from 'react';
import {
  PlanningSkillVehicleGroupMinimal,
  PlanningSkillVehicleGroupListResponse,
  ApiWebPlanningSkillPlanningSkillIdVehicleGroupsGetRequest,
  Listing,
} from '@onroadvantage/onroadvantage-api';
import {webPlanningSkillVehicleGroupApi} from '../../../api';
import {
  TemplateTableContextProps,
  TLoadList,
  TOnInlineAdd,
  useTemplateTable,
} from '../../../factory/template';
import {useAppNotifications} from '../../../contexts';
import {PlanningSkillContext} from '../PlanningSkillContext';

export type PlanningSkillVehicleGroupListContextProps =
  TemplateTableContextProps<
    PlanningSkillVehicleGroupMinimal,
    PlanningSkillVehicleGroupListResponse
  >;

export const PlanningSkillVehicleGroupListContext =
  React.createContext<PlanningSkillVehicleGroupListContextProps>({
    loading: false,
    list: [],
    currentPage: 1,
    loadList: () => null,
  });

export const usePlanningSkillVehicleGroupListContext = () =>
  React.useContext(PlanningSkillVehicleGroupListContext);

export const PlanningSkillVehicleGroupListContextProvider: React.FC = ({
  children,
}) => {
  const notify = useAppNotifications();
  const {planningSkillId} = React.useContext(PlanningSkillContext);
  // Template Table
  const [
    {
      // States
      currentPage,
      filters,
      hasPermission,
      itemTotal,
      list,
      loading,
      pageSize,
      pageTotal,
      sorting,
    },
    {
      // Getters
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
    },
  ] = useTemplateTable<
    PlanningSkillVehicleGroupMinimal,
    ApiWebPlanningSkillPlanningSkillIdVehicleGroupsGetRequest
  >({
    editPermission: 'Edit PlanningSkill',
  });

  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const loadList = React.useCallback<
    TLoadList<PlanningSkillVehicleGroupListResponse>
  >(
    async (options) => {
      setLoading(true);
      try {
        if (planningSkillId) {
          const requestObj = getRequestObj(['name'], options, {
            planningSkillId,
          });
          const response =
            await webPlanningSkillVehicleGroupApi.apiWebPlanningSkillPlanningSkillIdVehicleGroupsGet(
              {...requestObj, planningSkillId}
            );
          return getResponse(response, options);
        }
      } catch (e) {
        notify('error', 'Failed to load vehicle groups for planning skill');
      } finally {
        setLoading(false);
      }
    },
    [setLoading, planningSkillId, getRequestObj, getResponse, notify]
  );

  const handleDelete = React.useCallback(
    async (row: PlanningSkillVehicleGroupMinimal) => {
      setSubmitting(true);
      try {
        if (planningSkillId && row.id) {
          const response =
            await webPlanningSkillVehicleGroupApi.apiWebPlanningSkillPlanningSkillIdVehicleGroupVehicleGroupIdDelete(
              {planningSkillId, vehicleGroupId: row.id}
            );
          notify('success', 'Unassigned planning skill from vehicle group');
          return getResponse(response);
        }
      } catch (e) {
        notify('error', 'Failed to unassign planning skill from vehicle group');
      } finally {
        setSubmitting(false);
      }
    },
    [getResponse, notify, planningSkillId]
  );

  const handleInlineAdd = React.useCallback<TOnInlineAdd>(
    async (changes) => {
      setSubmitting(true);
      try {
        if (planningSkillId && changes) {
          const vehicleGroupLists = changes[0]?.name as Listing[] | undefined;
          const vehicleGroupIds = vehicleGroupLists
            ?.filter(({value}) => !!value)
            .map(({value}) => value as number);
          if (vehicleGroupIds && vehicleGroupIds.length > 0) {
            const response =
              await webPlanningSkillVehicleGroupApi.apiWebPlanningSkillPlanningSkillIdVehicleGroupsPost(
                {
                  planningSkillId,
                  body: {vehicleGroupIds},
                }
              );
            notify('success', 'Assigned planning skill to vehicle groups');
            return getResponse(response);
          }
        }
      } catch (e) {
        notify('error', 'Failed to assign planning skill to vehicle groups');
      } finally {
        setSubmitting(false);
      }
    },
    [getResponse, notify, planningSkillId]
  );

  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  const value: PlanningSkillVehicleGroupListContextProps = {
    loadList,
    cleanupList,
    hasPermission,
    loading: submitting || loading,
    list,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onInlineAdd: hasPermission.edit ? handleInlineAdd : undefined,
    onDelete: hasPermission.edit ? handleDelete : undefined,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onRefresh: handleRefresh,
  };

  return (
    <PlanningSkillVehicleGroupListContext.Provider value={value}>
      {children}
    </PlanningSkillVehicleGroupListContext.Provider>
  );
};
