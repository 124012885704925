import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

const PlanningBoardGroupOptionsStyles = (theme: Theme) =>
  createStyles({
    dropContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
      flexWrap: 'wrap',
      padding: theme.spacing(2),
      touchAction: 'none',
      borderRadius: '5px',
      margin: '1rem 0',
      backgroundColor: '#fafafa',
      border: 'dashed 3px transparent',
    },
    dropContainerIsOver: {borderColor: '#555'},
    dropContainerSelected: {},
    dropContainerUnselected: {},
    chip: {
      border: 'dashed 2px transparent',
      cursor: 'move',
      margin: theme.spacing(0.5),
    },
    chipIsDragging: {opacity: 0.3},
    chipIsOver: {borderColor: '#555'},
  });

export const usePlanningBoardGroupOptionsStyles = makeStyles(
  PlanningBoardGroupOptionsStyles,
  {name: 'PlanningBoardGroupOptionsStyles'}
);
