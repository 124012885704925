import React from 'react';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../../../../factory/template';
import {WebListDocumentStorageDump} from '@onroadvantage/onroadvantage-api';
import {TripDebriefSummaryStopDocumentListContext} from './TripDebriefSummaryStopDocumentListContext';

export const TripDebriefSummaryStopDocumentList: React.FC = () => {
  const {loadList, cleanupList} = React.useContext(
    TripDebriefSummaryStopDocumentListContext
  );

  const [config] = React.useState<
    TemplateTableConfig<WebListDocumentStorageDump>
  >({
    columns: [
      {name: 'id', label: 'Id', type: 'string'},
      {name: 'documentType', label: 'Document Type', type: 'string'},
      {name: 'status', label: 'Status', type: 'string'},
      {name: 'createdAt', label: 'Created Date', type: 'datetime'},
    ],
    disablePagination: true,
    disableToolbar: true,
    identifier: 'TRIP_DEBRIEF_SUMMARY_STOP_COMMENT_LIST',
  });

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [loadList, cleanupList]);

  return (
    <TemplateTableWrapper
      config={config}
      context={TripDebriefSummaryStopDocumentListContext}
    />
  );
};
