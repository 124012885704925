import React from 'react';
import {Button, Stack} from '@mui/material';
import {TripEditOrderAddListContext} from './TripEditOrderAddListContext';

export const TripEditOrderAddActions: React.FC = () => {
  const {selection, onAddOrders, onClearSelection} = React.useContext(
    TripEditOrderAddListContext
  );

  return (
    <Stack direction="row" spacing={1}>
      <Button
        disabled={!selection || selection.length === 0}
        variant="contained"
        onClick={onAddOrders}
      >
        Add
      </Button>
      <Button variant="outlined" onClick={onClearSelection}>
        Cancel
      </Button>
    </Stack>
  );
};
