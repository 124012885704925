import React from 'react';
import {useEventsPaneCardStyles} from './EventsPaneCard.style';
import {ListItem, ListItemIcon, ListItemText} from '@mui/material';

interface EventsPaneCardListItemProps {
  Icon: React.ReactNode;
  primary: React.ReactNode;
  secondary?: React.ReactNode;
}

export const EventsPaneCardListItem: React.FC<EventsPaneCardListItemProps> = ({
  Icon,
  primary,
  secondary,
}) => {
  const classes = useEventsPaneCardStyles();
  return (
    <ListItem classes={{root: classes.listItem}} disableGutters dense>
      <ListItemIcon classes={{root: classes.listItemIcon}}>{Icon}</ListItemIcon>
      <ListItemText
        className={classes.listItemText}
        primary={primary}
        secondary={secondary}
      />
    </ListItem>
  );
};
