import React from 'react';
import {
  DriverGroupDriverMinimal,
  Listing,
} from '@onroadvantage/onroadvantage-api';
import {
  TemplateTable,
  TemplateTableConfig,
  TOnInlineAdd,
} from '../../factory/template';
import {useDriverGroupContext} from './driverGroupContext';
import {driverGroupApi} from '../../api';
import {useAppNotifications} from '../../contexts';

interface IDriverGroupDriverAssign {
  firstname: Listing[] | undefined;
}

export const DriverGroupDriverList: React.FC = () => {
  const notify = useAppNotifications();
  const {loadingDriverGroup, driverGroup, setDriverGroup, driverGroupId} =
    useDriverGroupContext();
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const [config] = React.useState<
    TemplateTableConfig<DriverGroupDriverMinimal>
  >({
    columns: [
      {
        name: 'firstname',
        label: 'First Name',
        type: 'string',
        model: 'Driver',
        enableEditing: true,
        enableAutocomplete: true,
        enableMulti: true,
      },
      {name: 'lastname', label: 'Last Name', type: 'string'},
      {
        name: 'externalEmployeeNumber',
        label: 'Employee Number',
        type: 'string',
      },
      {name: 'externalExtendedId', label: 'Driver Tag', type: 'string'},
    ],
    deleteMessage: (row) => ({
      title: 'Driver Group',
      items: {
        Firstname: row.firstname,
        Lastname: row.lastname,
        EmployeeNumber: row.externalEmployeeNumber,
        DriverTag: row.externalExtendedId,
      },
    }),
    identifier: 'DRIVER_GROUP_DRIVER_LIST',
  });

  const handleInlineAdd = React.useCallback<TOnInlineAdd>(
    async (changes) => {
      setSubmitting(true);
      try {
        if (driverGroupId) {
          const values = changes[0] as IDriverGroupDriverAssign | undefined;
          if (values?.firstname) {
            const driverIds = values.firstname
              .filter(({value}) => !!value)
              .map(({value}) => value as number);

            const response =
              await driverGroupApi.apiDriverGroupDriverGroupIdAssignPatch({
                driverGroupId,
                body: {driverIds},
              });

            setDriverGroup(response);
            notify('success', 'Assigned drivers to driver group');
          }
        }
      } catch (e) {
        notify('error', 'Failed to assign drivers to driver group');
      } finally {
        setSubmitting(false);
      }
    },
    [driverGroupId, notify, setDriverGroup]
  );

  const handleDelete = React.useCallback(
    async (row: DriverGroupDriverMinimal) => {
      setSubmitting(true);
      try {
        if (driverGroupId && row.id) {
          const response =
            await driverGroupApi.apiDriverGroupDriverGroupIdUnassignDriverIdPatch(
              {driverId: row.id, driverGroupId}
            );

          setDriverGroup(response);

          notify('success', 'Unassigned driver from driver group');
        }
      } catch (e) {
        notify('error', 'Failed to unassign driver from driver group');
      } finally {
        setSubmitting(false);
      }
    },
    [driverGroupId, notify, setDriverGroup]
  );

  return (
    <TemplateTable
      config={config}
      list={driverGroup?.drivers ?? []}
      currentPage={1}
      onInlineAdd={handleInlineAdd}
      onDelete={handleDelete}
      loading={submitting || loadingDriverGroup}
    />
  );
};
