import React from 'react';
import {VehicleContext} from '../VehicleContext';
import {Card, CardHeader, Avatar, CardContent} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import {MomentService} from '../../../service';

export const VehicleDetailsView: React.FC = () => {
  const {vehicle} = React.useContext(VehicleContext);

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
        paddingBottom: 10,
      }}
    >
      <Card style={{marginRight: 10, flexGrow: 1, flex: 1}}>
        <CardHeader
          title="Vehicle Registration: "
          avatar={
            <Avatar aria-label="VehicleRegistration">
              <DoneIcon />
            </Avatar>
          }
        />
        <CardContent>{vehicle?.registrationNumber}</CardContent>
      </Card>
      <Card style={{marginRight: 10, flexGrow: 1, flex: 1}}>
        <CardHeader
          title="Last Odometer: "
          avatar={
            <Avatar aria-label="LastOdometer">
              <DoneIcon />
            </Avatar>
          }
        />
        <CardContent>
          {vehicle?.lastSeen?.latitude ? vehicle.lastOdometer : 'No Data'}
        </CardContent>
      </Card>
      <Card style={{marginRight: 10, flexGrow: 1, flex: 1}}>
        <CardHeader
          title="Assgined Contract: "
          avatar={
            <Avatar aria-label="VehicleAssignedContract">
              <DoneIcon />
            </Avatar>
          }
        />
        <CardContent>
          {vehicle?.contract ? vehicle.contract.code : 'Not assigned'}
        </CardContent>
      </Card>
      <Card style={{marginRight: 10, flexGrow: 1, flex: 1}}>
        <CardHeader
          title="Last Seen: "
          avatar={
            <Avatar aria-label="VehicleActions">
              <DoneIcon />
            </Avatar>
          }
        />
        <CardContent>
          {vehicle?.lastSeen?.eventDate
            ? MomentService.displayFromNow(vehicle.lastSeen.eventDate)
            : 'No Data'}
        </CardContent>
      </Card>
      {/*<Card style={{flexGrow: 1, flex: 1}}>*/}
      {/*  <CardHeader*/}
      {/*    title="7 Day Utilization: "*/}
      {/*    avatar={*/}
      {/*      <Avatar aria-label="VehicleActions">*/}
      {/*        <DoneIcon />*/}
      {/*      </Avatar>*/}
      {/*    }*/}
      {/*  />*/}
      {/*  <CardContent>{vehicle?.lastSeen ? '72%' : 'No Data'}</CardContent>*/}
      {/*</Card>*/}
    </div>
  );
};
