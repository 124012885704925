import React from 'react';
import {WebPlanningBoardTrip} from '@onroadvantage/onroadvantage-api';
import {usePlanningBoardOverviewStyles} from './PlanningBoardOverview.style';
import {PlanningBoardOverviewTripList} from './PlanningBoardOverviewTripList';
import {PlanningBoardOverviewVehicleList} from './PlanningBoardOverviewVehicleList';
import {usePlanningBoardContext} from '../../planningBoardContext';
import {Tab, Tabs} from '@mui/material';
import {VantageBadge} from '../../../badge';
import {useTemplateTabControllerStyles} from '../../../../factory/template/tabs/TemplateTabs.style';

export const PlanningBoardOverview: React.FC = () => {
  const templateTabClasses = useTemplateTabControllerStyles();
  const classes = usePlanningBoardOverviewStyles();
  const {bookingMasterTrips, planningMasterTrips, masterMasterTrips, vehicles} =
    usePlanningBoardContext();

  const [tabValue, setTabValue] = React.useState<unknown | undefined>(
    'booking'
  );

  const handleTabChange = React.useCallback((event: any, value: string) => {
    setTabValue(value);
  }, []);

  const bookingTrips = React.useMemo(
    () =>
      bookingMasterTrips
        .filter(({trip}) => trip)
        .map(({trip}) => trip as WebPlanningBoardTrip),
    [bookingMasterTrips]
  );
  const planningTrips = React.useMemo(
    () =>
      planningMasterTrips
        .filter(({trip}) => trip)
        .map(({trip}) => trip as WebPlanningBoardTrip),
    [planningMasterTrips]
  );
  const masterTrips = React.useMemo(
    () =>
      masterMasterTrips
        .filter(({trip}) => trip)
        .map(({trip}) => trip as WebPlanningBoardTrip),
    [masterMasterTrips]
  );

  return (
    <>
      <Tabs
        className={templateTabClasses.tabs}
        value={tabValue}
        classes={{scroller: templateTabClasses.tabs}}
        onChange={handleTabChange}
        indicatorColor="primary"
        // textColor="primary"
      >
        <Tab
          classes={{root: templateTabClasses.tab}}
          label={
            <VantageBadge
              classes={{badge: classes.booking}}
              max={999}
              badgeContent={bookingTrips.length}
            >
              Booking Trips
            </VantageBadge>
          }
          value="booking"
        />
        <Tab
          classes={{root: templateTabClasses.tab}}
          label={
            <VantageBadge
              classes={{badge: classes.planning}}
              max={999}
              badgeContent={planningTrips.length}
            >
              Planning Trips
            </VantageBadge>
          }
          value="planning"
        />
        <Tab
          classes={{root: templateTabClasses.tab}}
          label={
            <VantageBadge
              classes={{badge: classes.master}}
              color="primary"
              max={999}
              badgeContent={masterTrips.length}
            >
              Master Trips
            </VantageBadge>
          }
          value="master"
        />
        <Tab
          classes={{root: templateTabClasses.tab}}
          label={
            <VantageBadge
              color="primary"
              max={999}
              badgeContent={vehicles.length}
            >
              Vehicles
            </VantageBadge>
          }
          value="vehicle"
        />
      </Tabs>
      {tabValue === 'vehicle' ? (
        <PlanningBoardOverviewVehicleList />
      ) : tabValue === 'master' ? (
        <PlanningBoardOverviewTripList
          trips={masterTrips ?? []}
          identifier="MASTER"
        />
      ) : tabValue === 'planning' ? (
        <PlanningBoardOverviewTripList
          trips={planningTrips ?? []}
          identifier="PLANNING"
        />
      ) : (
        <PlanningBoardOverviewTripList
          trips={bookingTrips ?? []}
          identifier="BOOKING"
        />
      )}
    </>
  );
};
