import React from 'react';
import _ from 'lodash';
import {
  ITemplateControllerRoute,
  TemplateController,
} from '../../factory/template';
import {
  TripDebriefContext,
  TripDebriefContextProvider,
} from './TripDebriefContext';
import {TripDebriefList} from './tripDebriefList';
import {TripDebriefIdHandler} from './TripDebriefIdHandler';
import {TripDebrief} from './TripDebrief';
import {TripDebriefListContextProvider} from './tripDebriefList';
import {TripDebriefSummaryStop} from './tripDebriefSummary/tripDebriefSummaryStop/TripDebriefSummaryStop';
import {
  TripDebriefSummaryStopDocumentListContextProvider,
  TripDebriefSummaryStopIdHandler,
} from './tripDebriefSummary';
import {TripDebriefSummaryStopListContextProvider} from './tripDebriefSummary/tripDebriefSummaryStop/tripDebriefSummaryStopList';
import {
  TripDebriefOrderLine,
  TripDebriefOrderLineIdHandler,
} from './tripDebriefOrderLine';
import {getOrderLine, getTripStopSiteName} from './helpers';
import {MasterTripDebriefSnapshot} from '@onroadvantage/onroadvantage-api';

export const TripDebriefController: React.FC = () => {
  return (
    <TripDebriefListContextProvider>
      <TripDebriefContextProvider>
        <TripDebriefSummaryStopListContextProvider>
          <TripDebriefSummaryStopDocumentListContextProvider>
            <TripDebriefControllerComponent />
          </TripDebriefSummaryStopDocumentListContextProvider>
        </TripDebriefSummaryStopListContextProvider>
      </TripDebriefContextProvider>
    </TripDebriefListContextProvider>
  );
};

const TripDebriefControllerComponent: React.FC = () => {
  const {masterTripDebriefData, loading} = React.useContext(TripDebriefContext);
  /**
   *  Destructure snapshotData, need to add || ({} as MasterTripDebriefSnapshot) for typescript, since
   *  snapshotData is nullable.
   */
  const {orders, stops, tripNumber} =
    masterTripDebriefData?.snapshotData || ({} as MasterTripDebriefSnapshot);

  const routes: ITemplateControllerRoute[] = React.useMemo(
    () => [
      {title: 'Trip Debrief List', component: TripDebriefList},
      {
        path: '/:masterTripId',
        title: (params) =>
          loading
            ? 'Trip Debrief Loading...'
            : tripNumber ??
              `Trip Debrief ${_.get(params, 'masterTripId', 'Single')}`,
        component: TripDebrief,
        IdHandler: TripDebriefIdHandler,
      },
      {
        path: '/:masterTripId/stop/:tripStopId',
        title: (params) =>
          loading
            ? 'Trip Stop Loading...'
            : getTripStopSiteName(
                stops,
                _.get(params, 'tripStopId', undefined)
              ) ?? `Trip Stop ${_.get(params, 'tripStopId', 'Single')}`,
        component: TripDebriefSummaryStop,
        IdHandler: TripDebriefSummaryStopIdHandler,
      },
      {
        path: '/:masterTripId/orderline/:orderLineId',
        title: (params) =>
          loading
            ? 'Order Line Loading...'
            : getOrderLine(orders, _.get(params, 'orderLineId', undefined))
                ?.product?.name ??
              `Order Line ${_.get(params, 'orderLineId', 'Single')}`,
        component: TripDebriefOrderLine,
        IdHandler: TripDebriefOrderLineIdHandler,
      },
    ],
    [loading, orders, stops, tripNumber]
  );

  return <TemplateController rootPath="/tripdebrieflist" routes={routes} />;
};
