import React from 'react';
import {TaskTemplateContext} from './TaskTemplateContext';
import {
  TemplateTable,
  TemplateTableConfig,
  TOnInlineAdd,
} from '../../factory/template';
import {TaskTemplateItem} from '@onroadvantage/onroadvantage-api';
import {RoleService} from '../../service';

export const TaskTemplateItems: React.FC = () => {
  const {taskTemplate, loading, onUpdateTaskTemplate} =
    React.useContext(TaskTemplateContext);

  const config: TemplateTableConfig<TaskTemplateItem> = React.useMemo(
    () => ({
      columns: [
        {name: 'name', label: 'Item Name', type: 'string', enableEditing: true},
      ],
      disablePagination: true,
      disableToolbar: true,
      deleteMessage: (row) => ({
        title: 'Task Template Item',
        items: {TemplateName: taskTemplate?.name, ItemName: row.name},
      }),
      identifier: 'TASK_TEMPLATE_ITEMS_LIST',
    }),
    [taskTemplate?.name]
  );

  const handleInlineAdd = React.useCallback<TOnInlineAdd>(
    (changes) => {
      changes.forEach((change) => {
        if (taskTemplate?.name && taskTemplate.id) {
          onUpdateTaskTemplate({
            id: taskTemplate.id,
            name: taskTemplate?.name,

            items: [
              ...(taskTemplate?.items ? taskTemplate.items : []),
              {name: change.name, value: change.name},
            ],
          });
        }
      });
    },
    [
      onUpdateTaskTemplate,
      taskTemplate?.id,
      taskTemplate?.items,
      taskTemplate?.name,
    ]
  );

  const handleDelete = React.useCallback(
    (row: TaskTemplateItem) => {
      if (taskTemplate?.name && taskTemplate.id) {
        onUpdateTaskTemplate({
          id: taskTemplate.id,
          name: taskTemplate?.name,
          items: taskTemplate.items?.filter((item) => item.id !== row.id),
        });
      }
    },
    [
      onUpdateTaskTemplate,
      taskTemplate?.id,
      taskTemplate?.items,
      taskTemplate?.name,
    ]
  );

  return (
    <TemplateTable
      config={config}
      list={taskTemplate?.items ?? []}
      currentPage={1}
      loading={loading}
      onDelete={
        RoleService.hasPermission('Delete TaskTemplateOption', 'Delete')
          ? handleDelete
          : undefined
      }
      onInlineAdd={
        RoleService.hasPermission('Add TaskTemplateOption', 'Add')
          ? handleInlineAdd
          : undefined
      }
    />
  );
};
