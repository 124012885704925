import React from 'react';
import {useLocalStore} from 'mobx-react';
import {planningSolutionListStoreContext} from './PlanningSolutionList.context';
import {planningSolutionListStore} from './PlanningSolutionList.store';

// eslint-disable-next-line import/prefer-default-export
export const PlanningSolutionListProvider: React.FC = ({children}) => {
  const store = useLocalStore(planningSolutionListStore);

  return (
    <planningSolutionListStoreContext.Provider value={store}>
      {children}
    </planningSolutionListStoreContext.Provider>
  );
};
