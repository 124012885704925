import React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {observer} from 'mobx-react';
import {FormikValues} from 'formik';
import clone from 'rfdc';
import {
  usePlanningSolutionData,
  usePlanningSolutionViewStore,
} from '../../../stores/context';
import {listingApi, planningOrderApi, planningSolutionApi} from '../../../api';
import {Loader} from '../../loader';
import {useAppNotificationStore} from '../../../stores/context/notifications';
import {
  IFormTrip,
  PlanningSolutionForm,
  PlanningSolutionFormValues,
} from './PlanningSolutionForm';
import {useInterval} from '../../../service/useInterval';
import OptimisationLargeLoader from '../../loader/OptimisationLargeLoader';
import {PlanningOrder as PlanningOrderType} from '@onroadvantage/onroadvantage-api';

export const DEFAULT_OPTIMISE_TICK = 10000;

interface IParams {
  id: string;
}

export const PlanningSolutionControllerComponent: React.FC<
  RouteComponentProps<IParams>
> = ({match}) => {
  const {
    optimiseTick,
    isLoading,
    isMDLoading,
    isSolutionCommitting,
    isSolutionOptimising,
    loadingError,
    setIsSolutionLoading,
    setOptimising,
    setMDLoading,
    setLoadingError,
    setOptimiseTick,
  } = usePlanningSolutionViewStore((store) => ({
    optimiseTick: store.optimiseTick,
    isLoading: store.solutionIsLoading,
    isMDLoading: store.solutionMDIsLoading,
    isSolutionCommitting: store.solutionIsCommitting,
    isSolutionOptimising: store.solutionIsOptimising,
    loadingError: store.loadingError,
    setMDLoading: store.setSolutionMDIsLoading,
    setIsSolutionLoading: store.setSolutionIsLoading,
    setOptimising: store.setSolutionIsOptimising,
    setOptimiseTick: store.setOptimizeTick,
    setLoadingError: store.setLoadingError,
  }));

  /** notification service */
  const appNotificationStore = useAppNotificationStore();
  const displayNotification = React.useCallback(
    (message: {type: string; value: string}) => {
      appNotificationStore.enqueueNotification(message.type, message.value);
    },
    [appNotificationStore]
  );

  /** active planning solution */
  const {
    // values
    initialLoaded,
    planningSolution,
    // actions
    setAllListings,
    setPlanningSolution,
    setPlanningOrders,
  } = usePlanningSolutionData((store) => ({
    setAllListings: store.setAllListings,
    initialLoaded: store.initialLoaded,

    planningSolution: store.planningSolution,
    setPlanningSolution: store.setPlanningSolution,
    setPlanningOrders: store.setPlanningOrders,
  }));

  const handleSave = async (values: FormikValues) => {
    if (!planningSolution || !planningSolution.id) {
      displayNotification({
        type: 'error',
        value: 'Save Failed',
      });
      return;
    }

    // set loading/submitting
    setIsSolutionLoading(true);

    try {
      const mapTrip = ({
        tempId,
        driver,
        vehicle,
        orders,
        stops,
        ...t
      }: IFormTrip) => ({
        ...t,
        driverId: (driver || {}).value,
        vehicleId: (vehicle || {}).value,
        orderIds: orders,
        stops: stops,
      });

      const mapForm = ({
        allocatedDrivers,
        allocatedOrders,
        unallocatedOrders,
        allocatedVehicles,
        contract = {},
        trips = [],
        unassignedOrders,
        startDate,
        endDate,
        ...f
      }: PlanningSolutionFormValues) => ({
        ...f,
        allocatedDriverIds: allocatedDrivers,
        allocatedOrderIds: (allocatedOrders || []).concat(
          unallocatedOrders || []
        ),
        allocatedVehicleIds: allocatedVehicles,
        contractId: contract.value,
        trips: trips.map(mapTrip),
        unassignedOrderIds: unassignedOrders,
      });

      const body = mapForm(clone()(values));

      const response =
        await planningSolutionApi.apiPlanningSolutionPlanningSolutionIdPatch({
          body,
          planningSolutionId: planningSolution.id,
        });

      setPlanningSolution(response);

      displayNotification({
        type: 'success',
        value: 'Save Successful',
      });
    } catch (e) {
      displayNotification({
        type: 'error',
        value: 'Save Failed',
      });
    } finally {
      setIsSolutionLoading(false);
    }
  };

  const loadData = React.useCallback(async () => {
    if (!match.params.id) {
      setIsSolutionLoading(false);
      return;
    }

    try {
      setIsSolutionLoading(true);
      const response =
        await planningSolutionApi.apiPlanningSolutionPlanningSolutionIdStatusGet(
          {
            planningSolutionId: parseInt(match.params.id, 10),
          }
        );

      if (response.planningSolution) {
        setPlanningSolution(response.planningSolution);
      }

      if (
        response.status &&
        ['OPTIMISING', 'SCHEDULED'].includes(response.status)
      ) {
        setOptimiseTick(DEFAULT_OPTIMISE_TICK);
        setOptimising(true);
      } else {
        setOptimiseTick(null);
        setOptimising(false);
      }
    } catch (e) {
      setLoadingError(e.message || e.statusText);
      displayNotification({type: 'error', value: e.message});
    } finally {
      setIsSolutionLoading(false);
    }
  }, [
    displayNotification,
    match.params.id,
    setPlanningSolution,
    setIsSolutionLoading,
    setLoadingError,
    setOptimising,
    setOptimiseTick,
  ]);

  useInterval(() => {
    loadData();
  }, optimiseTick);

  React.useEffect(() => {
    loadData();
  }, [loadData]);

  // load raw masterdata
  React.useEffect(() => {
    const loadMasterData = async () => {
      let fullPlanningOrders: PlanningOrderType[] = [];
      if (!(planningSolution && planningSolution.contract)) {
        return;
      }
      if (`${planningSolution.id}` !== match.params.id) {
        return;
      }
      const startDate: Date = planningSolution.startDate || new Date();
      const endDate: Date = planningSolution.endDate || new Date();

      const driverGetOptions = {
        model: 'Driver',
        metaData: 'external_extended_id',
        perPage: 4000,
        contractCode: planningSolution.contract.code,
      };
      const vehicleGetOptions = {
        model: 'WebPlanningSolutionVehicle',
        metaData: 'fleet_number',
        perPage: 4000,
        contractCode: planningSolution.contract.code,
      };
      const planningOrderGetOptions = {
        model: 'PlanningOrder',
        perPage: 4000,
        metaData: 'skill',
        contractCode: planningSolution.contract.code,
        startDate,
        endDate,
      };
      const contractGetOptions = {
        model: 'Contract',
        perPage: 4000,
        startDate,
        endDate,
      };

      // IMPORTANT keep the order of returned items
      const [
        contractOptions,
        driverOptions,
        planningOrderOptions,
        vehicleOptions,
      ] = await Promise.all([
        listingApi.apiListingGet(contractGetOptions),
        listingApi.apiListingGet(driverGetOptions),
        listingApi.apiListingGet(planningOrderGetOptions),
        listingApi.apiListingGet(vehicleGetOptions),
      ]);

      // retrieve full orders
      if (planningSolution.contract.id) {
        const response = await planningOrderApi.apiPlanningOrderGet({
          contractId: `${planningSolution.contract.id}`,
          startDate,
          endDate,
          isPagination: false,
        });
        if (response.items) {
          fullPlanningOrders = response.items;
        }
      }

      setAllListings({
        contracts: contractOptions.items || [],
        drivers: driverOptions.items || [],
        orders: planningOrderOptions.items || [],
        vehicles: vehicleOptions.items || [],
      });
      setPlanningOrders(fullPlanningOrders);

      // stop loading spinner
      setMDLoading(false);
    };

    loadMasterData();
  }, [
    planningSolution,
    setMDLoading,
    setAllListings,
    setPlanningOrders,
    match.params.id,
  ]);

  if (loadingError) {
    return <div>Error loading data</div>;
  }

  if (optimiseTick || isSolutionOptimising) {
    return (
      <div>
        <OptimisationLargeLoader size={window.innerWidth * 0.8} />
        {/*<Link to="/planningsolutionlist">Back</Link>*/}
      </div>
    );
  }

  if (isLoading || isMDLoading || !initialLoaded || isSolutionCommitting) {
    return <Loader />;
  }

  if (!planningSolution) {
    return <div>Not found</div>;
  }

  return <PlanningSolutionForm onSave={handleSave} />;
};

export const PlanningSolutionController = observer(
  PlanningSolutionControllerComponent
);
