import React from 'react';
import {
  ApiUserTrainingCourseGetRequest as ApiUserTrainingCourseGetRequestType,
  ApiUserTrainingCourseUserTrainingCourseIdPatchRequest,
  UserTrainingCourse as UserTrainingCourseType,
  UserTrainingCourseList as UserTrainingCourseListResponseType,
} from '@onroadvantage/onroadvantage-api';

import {
  IListLoadOptions,
  TemplateTableContextProps,
} from '../../factory/template';
import {Filter, Sorting} from '@devexpress/dx-react-grid';
import {getStringFromSorting} from '../../service/Util';
import {userTrainingCourseApi} from '../../api';
import _ from 'lodash';
import {unstable_batchedUpdates} from 'react-dom';
import {useAppNotifications} from '../../contexts';

type FLoadList = (
  options?: IListLoadOptions
) => Promise<null | void | UserTrainingCourseListResponseType>;

export interface UserTrainingCourseContextProps
  extends TemplateTableContextProps<UserTrainingCourseType> {
  userTrainingCourseId?: string;
  loadList: FLoadList;
  onCourseStart: (
    value: any,
    row?: UserTrainingCourseType
  ) => Promise<null | void> | null | void;
}

export const UserTrainingCourseContext =
  React.createContext<UserTrainingCourseContextProps>({
    currentPage: 0,
    list: [],
    loadList: async () => {},
    onCourseStart: () => {},
  });

export const UserTrainingCourseContextProvider: React.FC = ({children}) => {
  const notify = useAppNotifications();
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [filters, setFilters] = React.useState<Filter[]>([]);
  const [itemTotal, setItemTotal] = React.useState<number>(0);
  const [list, setList] = React.useState<UserTrainingCourseType[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [pageSize, setPageSize] = React.useState<number>(25);
  const [pageTotal, setPageTotal] = React.useState<number>(1);
  const [sorting, setSorting] = React.useState<Sorting[]>([]);

  const loadList = React.useCallback<FLoadList>(
    async (options) => {
      const dataOnly = options?.dataOnly ?? false;
      const overrides = options?.overrides;

      setLoading(true);
      try {
        const requestObj: ApiUserTrainingCourseGetRequestType = {
          page: dataOnly ? 1 : currentPage,
          perPage:
            dataOnly && overrides?.perPage ? overrides.perPage : pageSize,
        };

        // filters
        filters.forEach((f: Filter) => {
          if (f.columnName === 'status' || f.columnName === 'mark') {
            requestObj[f.columnName] = f.value;
          }
        });

        // sorting
        const orderBy = getStringFromSorting(sorting);
        if (orderBy && orderBy !== '') {
          requestObj.orderBy = orderBy;
        }

        const response = await userTrainingCourseApi.apiUserTrainingCourseGet(
          requestObj
        );
        if (dataOnly) {
          return response;
        }
        if (response && response.items) {
          setList(response.items);
          setPageTotal(response.pages ?? 1);
          setItemTotal(response.total ?? 0);
        } else {
          // TODO handle
          notify('error', 'Error retrieving User Training Course List');
        }
      } catch (e) {
        notify(
          'error',
          e.message ?? 'Failed to load user training course list'
        );
      } finally {
        setLoading(false);
      }
    },
    [currentPage, filters, notify, pageSize, sorting]
  );

  const handleCurrentPageChange = React.useCallback(
    (value: number) => {
      let newPage = value;

      if (value < 0) {
        // lower bound check
        newPage = 0;
      } else if (value > pageTotal) {
        // upper bound check
        newPage = pageTotal;
      }

      setCurrentPage(newPage + 1);
    },
    [pageTotal]
  );

  const handlePageSizeCountsChange = React.useCallback((value: number) => {
    setCurrentPage(1);
    setPageSize(value);
  }, []);

  const handleFiltersChange = _.debounce((filters: Filter[]) => {
    unstable_batchedUpdates(() => {
      setCurrentPage(1);
      setFilters(filters);
    });
  }, 500);

  const handleSortingChange = _.debounce((sorting: Sorting[]) => {
    unstable_batchedUpdates(() => {
      setCurrentPage(1);
      setSorting(sorting);
    });
  }, 500);

  const handleRefresh = React.useCallback(() => {
    loadList();
  }, [loadList]);

  const handleCourseStart = async (
    value: any,
    row?: UserTrainingCourseType
  ) => {
    try {
      if (row && row.id) {
        const requestBody: ApiUserTrainingCourseUserTrainingCourseIdPatchRequest =
          {
            userTrainingCourseId: row.id,
            body: {
              startedAt: new Date(),
              status: 'Started',
            },
          };
        const response =
          await userTrainingCourseApi.apiUserTrainingCourseUserTrainingCourseIdPatch(
            requestBody
          );
        if (response) {
          await loadList();
        }
      }
    } catch (e) {
      notify('error', e.message ?? 'Failed to start course');
    } finally {
      setLoading(false);
    }
  };

  const value = {
    filters,
    currentPage,
    itemTotal,
    list,
    loadList,
    onFiltersChange: handleFiltersChange,
    onCourseStart: handleCourseStart,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onRefresh: handleRefresh,
    pageSize,
    pageTotal,
    sorting,
    loading,
  };

  return (
    <UserTrainingCourseContext.Provider value={value}>
      {children}
    </UserTrainingCourseContext.Provider>
  );
};
