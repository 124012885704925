import React from 'react';
import {
  PlanningSkillDriverGroupMinimal,
  PlanningSkillDriverGroupListResponse,
  ApiWebPlanningSkillPlanningSkillIdDriverGroupsGetRequest,
  Listing,
} from '@onroadvantage/onroadvantage-api';
import {webPlanningSkillDriverGroupApi} from '../../../api';
import {
  TemplateTableContextProps,
  TLoadList,
  TOnInlineAdd,
  useTemplateTable,
} from '../../../factory/template';
import {useAppNotifications} from '../../../contexts';
import {PlanningSkillContext} from '../PlanningSkillContext';

export type PlanningSkillDriverGroupListContextProps =
  TemplateTableContextProps<
    PlanningSkillDriverGroupMinimal,
    PlanningSkillDriverGroupListResponse
  >;

export const PlanningSkillDriverGroupListContext =
  React.createContext<PlanningSkillDriverGroupListContextProps>({
    loading: false,
    list: [],
    currentPage: 1,
    loadList: () => null,
  });

export const usePlanningSkillDriverGroupListContext = () =>
  React.useContext(PlanningSkillDriverGroupListContext);

export const PlanningSkillDriverGroupListContextProvider: React.FC = ({
  children,
}) => {
  const notify = useAppNotifications();
  const {planningSkillId} = React.useContext(PlanningSkillContext);
  // Template Table
  const [
    {
      // States
      currentPage,
      filters,
      hasPermission,
      itemTotal,
      list,
      loading,
      pageSize,
      pageTotal,
      sorting,
    },
    {
      // Getters
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
    },
  ] = useTemplateTable<
    PlanningSkillDriverGroupMinimal,
    ApiWebPlanningSkillPlanningSkillIdDriverGroupsGetRequest
  >({
    editPermission: 'Edit PlanningSkill',
  });

  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const loadList = React.useCallback<
    TLoadList<PlanningSkillDriverGroupListResponse>
  >(
    async (options) => {
      setLoading(true);
      try {
        if (planningSkillId) {
          const requestObj = getRequestObj(['name'], options, {
            planningSkillId,
          });
          const response =
            await webPlanningSkillDriverGroupApi.apiWebPlanningSkillPlanningSkillIdDriverGroupsGet(
              {...requestObj, planningSkillId}
            );
          return getResponse(response, options);
        }
      } catch (e) {
        notify('error', 'Failed to load driver groups for planning skill');
      } finally {
        setLoading(false);
      }
    },
    [setLoading, planningSkillId, getRequestObj, getResponse, notify]
  );

  const handleDelete = React.useCallback(
    async (row: PlanningSkillDriverGroupMinimal) => {
      setSubmitting(true);
      try {
        if (planningSkillId && row.id) {
          const response =
            await webPlanningSkillDriverGroupApi.apiWebPlanningSkillPlanningSkillIdDriverGroupDriverGroupIdDelete(
              {planningSkillId, driverGroupId: row.id}
            );
          notify('success', 'Unassigned planning skill from driver group');
          return getResponse(response);
        }
      } catch (e) {
        notify('error', 'Failed to unassign planning skill from driver group');
      } finally {
        setSubmitting(false);
      }
    },
    [getResponse, notify, planningSkillId]
  );

  const handleInlineAdd = React.useCallback<TOnInlineAdd>(
    async (changes) => {
      setSubmitting(true);
      try {
        if (planningSkillId && changes) {
          const driverGroupLists = changes[0]?.name as Listing[] | undefined;
          const driverGroupIds = driverGroupLists
            ?.filter(({value}) => !!value)
            .map(({value}) => value as number);
          if (driverGroupIds && driverGroupIds.length > 0) {
            const response =
              await webPlanningSkillDriverGroupApi.apiWebPlanningSkillPlanningSkillIdDriverGroupsPost(
                {
                  planningSkillId,
                  body: {driverGroupIds},
                }
              );
            notify('success', 'Assigned planning skill to driver groups');
            return getResponse(response);
          }
        }
      } catch (e) {
        notify('error', 'Failed to assign planning skill to driver groups');
      } finally {
        setSubmitting(false);
      }
    },
    [getResponse, notify, planningSkillId]
  );

  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  const value: PlanningSkillDriverGroupListContextProps = {
    loadList,
    cleanupList,
    hasPermission,
    loading: submitting || loading,
    list,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onInlineAdd: hasPermission.edit ? handleInlineAdd : undefined,
    onDelete: hasPermission.edit ? handleDelete : undefined,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onRefresh: handleRefresh,
  };

  return (
    <PlanningSkillDriverGroupListContext.Provider value={value}>
      {children}
    </PlanningSkillDriverGroupListContext.Provider>
  );
};
