import React from 'react';
import {TemplateTable} from '../../../../factory/template';
import {ContractCriticalEventTypePrompt} from '@onroadvantage/onroadvantage-api';
import {useContractCriticalEventTypeConfigContext} from '../ContractCriticalEventTypeConfigContext';
import {useHistory} from 'react-router-dom';
import {useAppNotifications} from '../../../../contexts';
import {contractCriticalEventTypeConfigApi} from '../../../../api';

export const ContractCriticalEventTypePromptList = () => {
  const history = useHistory();
  const notify = useAppNotifications();
  const [deleting, setDeleting] = React.useState<boolean>(false);
  const {
    loading,
    contractCriticalEventTypeConfig,
    contractCriticalEventTypeConfigId,
    setContractCriticalEventTypeConfig,
  } = useContractCriticalEventTypeConfigContext();

  const handleNavigate = React.useCallback(
    (row: ContractCriticalEventTypePrompt) => {
      if (contractCriticalEventTypeConfigId != null && row.id != null) {
        history.push(
          `/contracteventtypeconfigs/critical/${contractCriticalEventTypeConfigId}/prompt/${row.id}`
        );
      }
    },
    [contractCriticalEventTypeConfigId, history]
  );

  const handleAdd = React.useCallback(() => {
    if (contractCriticalEventTypeConfigId != null) {
      history.push(
        `/contracteventtypeconfigs/critical/${contractCriticalEventTypeConfigId}/prompt/add`
      );
    }
  }, [contractCriticalEventTypeConfigId, history]);

  const handleDelete = React.useCallback(
    async (row: ContractCriticalEventTypePrompt) => {
      setDeleting(true);
      try {
        if (contractCriticalEventTypeConfigId != null && row.id != null) {
          const response =
            await contractCriticalEventTypeConfigApi.apiContractCriticalEventTypeConfigContractCriticalEventTypeConfigIdPromptPromptIdDelete(
              {
                contractCriticalEventTypeConfigId,
                promptId: row.id,
              }
            );
          if (response) {
            setContractCriticalEventTypeConfig((prevState) =>
              prevState
                ? {
                    ...prevState,
                    prompts: prevState.prompts?.filter(({id}) => id !== row.id),
                  }
                : undefined
            );
          }
        }
      } catch (e) {
        notify('error', 'Failed to delete contract critical event type prompt');
      } finally {
        setDeleting(false);
      }
    },
    [
      contractCriticalEventTypeConfigId,
      notify,
      setContractCriticalEventTypeConfig,
    ]
  );

  return (
    <TemplateTable<ContractCriticalEventTypePrompt>
      config={{
        columns: [
          {name: 'type', label: 'Type', type: 'string'},
          {name: 'assistantId', label: 'Assistant ID', type: 'string'},
          {name: 'prompt', label: 'Prompt', type: 'string'},
        ],
        disablePagination: true,
        disableToolbar: true,
        deleteMessage: (row) => ({
          title: 'Contract Critical Event Type Prompt',
          items: {
            ContractCriticalEventTypeConfig: `${contractCriticalEventTypeConfig?.criticalEventType?.name} - ${contractCriticalEventTypeConfig?.contract?.code}`,
            Type: row.type,
            AssistantId: row.assistantId,
          },
        }),
      }}
      currentPage={1}
      list={contractCriticalEventTypeConfig?.prompts ?? []}
      loading={loading || deleting}
      onAdd={handleAdd}
      onNavigate={handleNavigate}
      onDelete={handleDelete}
    />
  );
};
