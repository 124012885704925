import React from 'react';
import * as Yup from 'yup';
import {DriverGroup, Listing} from '@onroadvantage/onroadvantage-api';
import {FormikAutocompleteWithListing, FormikTextField} from '../formik';
import {
  multipleListingSchema,
  TemplateCard,
  TemplateForm,
} from '../../factory/template';
import {useDriverGroupContext} from './driverGroupContext';

export interface IDriverGroupForm {
  name: string;
  planningSkills: Listing[] | undefined;
}

const schema: Yup.SchemaOf<IDriverGroupForm> = Yup.object({
  name: Yup.string().required('Required'),
  planningSkills: multipleListingSchema,
});

const getInitialValues = (
  driverGroup?: DriverGroup | null
): IDriverGroupForm | undefined => {
  if (driverGroup) {
    return {
      name: driverGroup.name ?? '',
      planningSkills: driverGroup.skills?.map(({id, name, description}) => ({
        value: id,
        label: `${name} - ${description}`,
      })),
    };
  }
  return undefined;
};

export const DriverGroupForm: React.FC<{isAdd?: boolean}> = ({isAdd}) => {
  const {
    detailsRef,
    loadingDriverGroup,
    submitting,
    driverGroup,
    onDetailsFormSubmit,
  } = useDriverGroupContext();
  const [initialValues, setInitialValues] = React.useState<IDriverGroupForm>();

  React.useEffect(() => {
    const values = getInitialValues(driverGroup);

    if (values) {
      setInitialValues(values);
    } else if (isAdd) {
      setInitialValues({name: '', planningSkills: []});
    }
  }, [isAdd, driverGroup, setInitialValues]);

  return (
    <TemplateCard
      title={`Driver Group Details ${isAdd ? 'Add' : 'Edit'}`}
      loading={loadingDriverGroup}
    >
      <TemplateForm<IDriverGroupForm>
        initialValues={initialValues}
        onSubmit={onDetailsFormSubmit}
        permission={{
          name: `${isAdd ? 'Add' : 'Edit'} DriverGroup`,
          type: isAdd ? 'Add' : 'Edit',
        }}
        submitting={submitting}
        innerRef={detailsRef}
        validationSchema={schema}
      >
        <FormikTextField
          name="name"
          placeholder="Enter the driver group name"
          label="Name"
          fullWidth
        />
        <FormikAutocompleteWithListing
          name="planningSkills"
          placeholder="Enter and select the driver group skills"
          label="Planning Skills"
          model="PlanningSkill"
          multiple
        />
      </TemplateForm>
    </TemplateCard>
  );
};
