import {
  TripStopExecution,
  MasterTripDebriefTripStopExecutionSnapshot,
} from '@onroadvantage/onroadvantage-api';
import {DateTime} from 'luxon';

type TATTime = Date | string | null | undefined;

interface executionTAT {
  gpsArrivalTime?: TATTime;
  gpsDepartureTime?: TATTime;
  mobileArrivalTime?: TATTime;
  mobileDepartureTime?: TATTime;
}

interface stopPlannedTATTimes {
  arrivalTime?: TATTime;
  serviceTimeStart?: TATTime;
  serviceTimeEnd?: TATTime;
  departureTime?: TATTime;
  tripStopExecution?: executionTAT | null | undefined;
}

export const getStopPlannedTAT = (
  times: stopPlannedTATTimes,
  priority?: string | null | undefined
) => {
  const {
    serviceTimeStart,
    serviceTimeEnd,
    arrivalTime,
    departureTime,
    tripStopExecution,
  } = times;
  const {
    gpsDepartureTime,
    gpsArrivalTime,
    mobileDepartureTime,
    mobileArrivalTime,
  } =
    tripStopExecution ||
    ({} as TripStopExecution | MasterTripDebriefTripStopExecutionSnapshot);

  let endDateTime: DateTime | undefined;

  let startDateTime: DateTime | undefined;

  if (priority === 'mobile' && mobileDepartureTime && mobileArrivalTime) {
    endDateTime = DateTime.fromJSDate(new Date(mobileDepartureTime));
    startDateTime = DateTime.fromJSDate(new Date(mobileArrivalTime));
  } else if (priority === 'gps' && gpsDepartureTime && gpsArrivalTime) {
    endDateTime = DateTime.fromJSDate(new Date(gpsDepartureTime));
    startDateTime = DateTime.fromJSDate(new Date(gpsArrivalTime));
  } else if (arrivalTime && departureTime) {
    endDateTime = DateTime.fromJSDate(new Date(departureTime));
    startDateTime = DateTime.fromJSDate(new Date(arrivalTime));
  } else if (serviceTimeStart && serviceTimeEnd) {
    endDateTime = DateTime.fromJSDate(new Date(serviceTimeEnd));
    startDateTime = DateTime.fromJSDate(new Date(serviceTimeStart));
  }

  if (endDateTime && startDateTime) {
    return Math.floor(
      endDateTime.diff(startDateTime).shiftTo('minutes').minutes
    );
  }

  return '-';
};
