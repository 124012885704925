import React from 'react';
import 'typeface-roboto';
import {getStyle} from '../helpers';

const componentStyle = (brandColors) => ({
  default: {
    container: {
      display: 'flex',
      height: 'calc(100vw / 1920 * 80)',
      margin: 'calc(100vw / 1920 * 20) 1.25vw 0 1.25vw',
      padding: 'calc(100vw / 1920 * 10) 1.25vw calc(100vw / 1920 * 10) 1.25vw',
      borderRadius: 'calc(100vw / 1920 * 24)',
      flexDirection: 'row',
      alignItems: 'center',
    },
    containerStatus: {
      clear: {
        backgroundColor: brandColors.brandBackground,
        border: '2px solid #8B8B8B',
        padding: 'calc(100vw / 1920 * 8) 1.25vw calc(100vw / 1920 * 8) 1.25vw',
      },
      critical: {
        backgroundColor: brandColors.brandTertiaryHighlightLighter,
      },
      warning: {
        backgroundColor: brandColors.brandSecondaryHighlight,
      },
      normal: {
        backgroundColor: brandColors.brandPrimaryHighlight,
      },
    },
    field1: {
      display: 'flex',
      flex: '1 0 auto',
      width: 'calc(100vw / 1920 * 1030)',
      alignItems: 'center',
      paddingRight: 'calc(100vw / 1920 * 24)',
    },
    field1Text: {
      fontSize: 'calc(100vw / 1920 * 64)',
      fontStyle: 'normal',
      fontWeight: 'bold',
      color: brandColors.brandPrimaryText,
      letterSpacing: '0.06em',
      fontFamily: 'Roboto',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    field2: {
      display: 'flex',
      flex: '1 0 auto',
      width: 'calc(100vw / 1920 * 308)',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    field2Text: {
      fontSize: 'calc(100vw / 1920 * 64)',
      fontStyle: 'normal',
      fontWeight: '900',
      color: brandColors.brandPrimaryText,
      letterSpacing: '0.06em',
      fontFamily: 'Roboto',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    field3: {
      display: 'flex',
      flex: '1 0 auto',
      width: 'calc(100vw / 1920 * 456)',
      alignSelf: 'flex-end',
      marginLeft: 'auto',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    field3Text: {
      fontSize: 'calc(100vw / 1920 * 64)',
      fontStyle: 'normal',
      fontWeight: '500',
      color: brandColors.brandPrimaryText,
      letterSpacing: '0.06em',
      fontFamily: 'Roboto',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    fieldTextStatus: {
      critical: {color: brandColors.brandTertiaryHighlightText},
      warning: {color: brandColors.brandSecondaryHighlightText},
      normal: {color: brandColors.brandPrimaryHighlightText},
      clear: {color: brandColors.brandBackgroundText},
    },
  },
  unitrans: {},
});

class CriticalEventsItem extends React.Component {
  render() {
    const {field1, field2, field3, status = 'normal'} = this.props;
    const styles = getStyle(componentStyle);
    return (
      <div style={{...styles.container, ...styles.containerStatus[status]}}>
        <div style={styles.field1}>
          <div
            style={{...styles.field1Text, ...styles.fieldTextStatus[status]}}
          >
            {field1 || 'No Data'}
          </div>
        </div>
        <div style={styles.field2}>
          <div
            style={{...styles.field2Text, ...styles.fieldTextStatus[status]}}
          >
            {field2 || 'No Data'}
          </div>
        </div>
        <div style={styles.field3}>
          <div
            style={{...styles.field3Text, ...styles.fieldTextStatus[status]}}
          >
            {field3 || 'No Data'}
          </div>
        </div>
      </div>
    );
  }
}

export default CriticalEventsItem;
