import React from 'react';
import L from 'leaflet';
import BaseMarker from './BaseMarker';

interface Props {
  position?: any;
  vehicle?: any;
}

export default class VehicleMarker extends React.Component<Props> {
  render() {
    // @ts-expect-error upgrade
    const Icon = L.icon.pulse({
      iconSize: [25, 25],
      fillColor: 'blue',
      color: 'blue',
    });

    const {position, vehicle} = this.props;
    return <BaseMarker position={position} icon={Icon} vehicle={vehicle} />;
  }
}
