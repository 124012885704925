import React from 'react';
import {useHistory} from 'react-router-dom';
import {
  ApiTaskTemplateGetRequest,
  TaskTemplate,
  TaskTemplateList,
  TaskTemplatePost,
  TaskTemplateUpdate,
} from '@onroadvantage/onroadvantage-api';
import {FormikHelpers, FormikProps} from 'formik/dist/types';
import {useAppNotifications} from '../../contexts';
import {taskTemplateApi} from '../../api';
import {
  TemplateTableContextProps,
  TLoadList,
  useTemplateTable,
} from '../../factory/template';
import {ITaskTemplateForm} from './TaskTemplateForm';

export interface TaskTemplateContextProps
  extends TemplateTableContextProps<TaskTemplate, TaskTemplateList> {
  onUpdateTaskTemplate: (updatedTaskTemplate: TaskTemplateUpdate) => void;
  onDetailsFormSubmit: (
    values: ITaskTemplateForm,
    formikHelpers: FormikHelpers<ITaskTemplateForm>
  ) => void;
  setTaskTemplateId: (value: number | undefined) => void;
  submitting: boolean;
  taskTemplate?: TaskTemplate;
  taskTemplateId?: number;
  detailsRef?: React.Ref<FormikProps<ITaskTemplateForm>>;
}

export const TaskTemplateContext =
  React.createContext<TaskTemplateContextProps>({
    // Template Table Defaults
    loading: false,
    list: [],
    currentPage: 1,
    // TaskTemplate
    loadList: async () => {},
    onUpdateTaskTemplate: () => null,
    onDetailsFormSubmit: () => null,
    setTaskTemplateId: () => null,
    submitting: false,
  });

interface TaskTemplateContextProviderProps {
  taskTemplateId?: number;
}

export const TaskTemplateContextProvider: React.FC<
  TaskTemplateContextProviderProps
> = ({children}) => {
  const history = useHistory();
  const notify = useAppNotifications();
  // Template Table
  const [
    {
      // States
      currentPage,
      pageSize,
      pageTotal,
      sorting,
      hasPermission,
      itemTotal,
      list,
      loading,
      loadingSingleItem,
      filters,
    },
    {
      // Getters
      getDownloads,
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
      setLoadingSingleItem,
    },
  ] = useTemplateTable<TaskTemplate, ApiTaskTemplateGetRequest>({
    editPermission: 'Edit TaskTemplate',
    addPermission: 'Add TaskTemplate',
    deletePermission: 'Delete TaskTemplate',
    downloadPermission: 'TaskTemplate ListDownload',
    viewPermission: 'TaskTemplate List',
  });

  const loadList = React.useCallback<TLoadList<TaskTemplateList>>(
    async (options) => {
      setLoading(true);
      try {
        const requestObj = getRequestObj(['name'], options);
        const response = await taskTemplateApi.apiTaskTemplateGet(requestObj);
        return getResponse(response, options);
      } catch (e) {
        notify('error', e.message ?? 'Failed to load task Template list');
      } finally {
        setLoading(false);
      }
    },
    [getRequestObj, getResponse, notify, setLoading]
  );

  const handleDelete = React.useCallback(
    async (row: TaskTemplate) => {
      setLoading(true);
      try {
        if (row.id) {
          await taskTemplateApi.apiTaskTemplateTaskTemplateIdDelete({
            taskTemplateId: row.id,
          });
          await loadList();
          notify('success', 'Deleted task Template');
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to delete');
      } finally {
        setLoading(false);
      }
    },
    [loadList, notify, setLoading]
  );

  const handleDownload = React.useCallback(
    () =>
      getDownloads('taskTemplates', loadList, [
        {name: 'Name', path: 'name'},
        {name: 'Type Name', path: 'type.name'},
        {name: 'Type Id', path: 'typeId'},
      ]),
    [getDownloads, loadList]
  );

  const handleAdd = React.useCallback(
    () => history.push('/tasktemplatelist/add'),
    [history]
  );

  const handleNavigate = React.useCallback(
    (row) => history.push(`/tasktemplatelist/${row.id}`),
    [history]
  );
  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  // Forms
  const [taskTemplate, setTaskTemplate] = React.useState<
    TaskTemplate | undefined
  >();
  const [taskTemplateId, setTaskTemplateId] = React.useState<
    number | undefined
  >();
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const detailsRef = React.useRef<FormikProps<ITaskTemplateForm>>(null);

  const loadTaskTemplate = React.useCallback(async () => {
    setLoadingSingleItem(true);
    try {
      if (taskTemplateId) {
        const response = await taskTemplateApi.apiTaskTemplateTaskTemplateIdGet(
          {
            taskTemplateId,
          }
        );
        setTaskTemplate(response);
      } else {
        setTaskTemplate(undefined);
      }
    } catch (e) {
      notify('error', e.message ?? 'Failed to load task Template');
    } finally {
      setLoadingSingleItem(false);
    }
  }, [notify, setLoadingSingleItem, taskTemplateId]);

  const handleUpdateTaskTemplate = React.useCallback(
    async (updatedTaskTemplate: TaskTemplateUpdate) => {
      setLoadingSingleItem(true);
      try {
        if (taskTemplateId) {
          const response =
            await taskTemplateApi.apiTaskTemplateTaskTemplateIdPatch({
              taskTemplateId,
              body: updatedTaskTemplate,
            });
          setTaskTemplate(response);
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to update task template');
      } finally {
        setLoadingSingleItem(false);
      }
    },
    [notify, setLoadingSingleItem, taskTemplateId]
  );

  const handleDetailsFormSubmit = React.useCallback(
    async (
      values: ITaskTemplateForm,
      formikHelpers: FormikHelpers<ITaskTemplateForm>
    ) => {
      setSubmitting(true);
      try {
        const {type, ...otherValues} = values;
        const newValues: TaskTemplatePost = {
          ...otherValues,
          typeId: type.value,
        };

        if (taskTemplateId) {
          await taskTemplateApi.apiTaskTemplateTaskTemplateIdPatch({
            taskTemplateId,
            body: {...taskTemplate, ...newValues},
          });
        } else {
          await taskTemplateApi.apiTaskTemplatePost({
            body: newValues,
          });
        }
        history.push('/tasktemplatelist');
        notify(
          'success',
          `${taskTemplateId ? 'Updated' : 'Added'} Task Template`
        );
      } catch (e) {
        notify(
          'error',
          e.message ??
            `Failed to ${taskTemplateId ? 'update' : 'add'} Task Template`
        );
      } finally {
        formikHelpers.setSubmitting(false);
        setSubmitting(false);
      }
    },
    [history, notify, taskTemplate, taskTemplateId]
  );

  const value: TaskTemplateContextProps = {
    // Template Table
    list,
    loadList,
    hasPermission,
    loading: loading || loadingSingleItem,
    cleanupList,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onAdd: hasPermission.add ? handleAdd : undefined,
    onDownload: hasPermission.download ? handleDownload : undefined,
    onNavigate: handleNavigate,
    onDelete: hasPermission.delete ? handleDelete : undefined,
    onRefresh: handleRefresh,
    // Forms
    onUpdateTaskTemplate: handleUpdateTaskTemplate,
    onDetailsFormSubmit: handleDetailsFormSubmit,
    setTaskTemplateId,
    submitting,
    taskTemplate,
    taskTemplateId,
    detailsRef,
  };

  React.useEffect(() => {
    loadTaskTemplate();
    return () => setTaskTemplate(undefined);
  }, [loadTaskTemplate]);

  return (
    <TaskTemplateContext.Provider value={value}>
      {children}
    </TaskTemplateContext.Provider>
  );
};
