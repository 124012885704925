import {makeStyles, createStyles} from '@mui/styles';
import {Theme} from '@mui/material';

export const illustrationRatio = 0.3;

const TripDebriefSummaryVehicleTruckStyles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      position: 'relative',
      zIndex: 0,
      flex: 1,
      marginTop: theme.spacing(2),
    },
    truck: {
      display: 'flex',
      flex: 1,
      objectFit: 'contain',
      objectPosition: 'top',
      position: 'relative',
      zIndex: 0,
      minWidth: 0,
    },
    container: {
      position: 'absolute',
      top: 1,
      display: 'flex',
      alignItems: 'center',
      zIndex: 1,
      flex: 1,
    },
    compartment: {
      overflow: 'hidden',
      position: 'relative',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      height: '90%',
      backgroundColor: '#bebebe',
      margin: theme.spacing(0.5),
      padding: theme.spacing(0.5),
      borderRadius: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'flex-start',
      textAlign: 'center',
    },
  });

export const useTripDebriefSummaryVehicleTruckStyles = makeStyles(
  TripDebriefSummaryVehicleTruckStyles,
  {name: 'TripDebriefSummaryVehicleTruckStyles'}
);
