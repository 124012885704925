import React from 'react';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../../factory/template';
import {VehicleCapacityDimension} from '@onroadvantage/onroadvantage-api';
import {VehicleTypeCapacityContext} from './VehicleTypeCapacityContext';
import {VehicleTypeContext} from '../VehicleTypeContext';

export const VehicleTypeCapacityList: React.FC = () => {
  const {vehicleType} = React.useContext(VehicleTypeContext);
  const {loadList, cleanupList} = React.useContext(VehicleTypeCapacityContext);

  const config: TemplateTableConfig<VehicleCapacityDimension> = React.useMemo(
    () => ({
      columns: [
        {name: 'name', label: 'Name', type: 'string'},
        {name: 'capacity', label: 'Capacity', type: 'number'},
        {name: 'unitOfMeasure', label: 'Unit Of Measure', type: 'string'},
      ],
      enableFilter: true,
      enableSort: true,
      deleteMessage: (row) => ({
        title: 'Vehicle Capacity',
        items: {
          VehicleType: vehicleType?.name,
          Name: row.name,
          Capacity: `${row.capacity}${row.unitOfMeasure}`,
        },
      }),
      identifier: 'VEHICLE_TYPE_CAPACITY_LIST',
      disableToolbar: true,
      enableGrouping: false,
    }),
    [vehicleType?.name]
  );

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [loadList, cleanupList]);

  return (
    <TemplateTableWrapper
      config={config}
      context={VehicleTypeCapacityContext}
    />
  );
};
