import React from 'react';
import {connect, FormikProps} from 'formik';
import {PlanningOrder} from '@onroadvantage/onroadvantage-api';
import clone from 'rfdc';
import Fuse from 'fuse.js';
import {Theme, CardHeader, Avatar} from '@mui/material';
import {WithStyles} from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import {usePlanningSolutionData} from '../../../stores/context';
import {IBoardData, ILaneFilter, VantageBoard} from '../../board';
import {
  getFilteredOrderCardData,
  planningOrderListingToLaneData,
  TGetCardMoveHandlerResponse,
} from '../../../service/laneUtils';
import {PlanningSolutionFormValues} from './PlanningSolutionForm';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {SettingsApplications as SettingsApplicationsIcon} from '@mui/icons-material';

// TODO cleanup styles
const styles = (theme: Theme) =>
  createStyles({
    container: {
      padding: 0,
    },
    header: {
      display: 'flex',
      flowDirection: 'row',
      justifyContent: 'space-between',
      paddingRight: theme.spacing(3),

      borderBottomColor: theme.palette.divider,
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
    },
    tabs: {},
    details: {
      borderBottomColor: theme.palette.divider,
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
    },
    planning: {
      display: 'flex',
      flexDirection: 'row',
      flexFlow: 'row wrap',
      // marginRight: theme.spacing(2),
      backgroundColor: 'rgb(250, 250, 250)',
      padding: 0,
    },
    tripStopsContainer: {
      paddingBottom: 0,
      marginBottom: 0,
    },
    tripStops: {
      display: 'flex',
      flexDirection: 'row',
      flexFlow: 'row wrap',
      margin: 0,
      padding: 0,
      marginTop: 35,
      paddingBottom: '0 !important',
      marginBottom: 0,
      // backgroundColor: theme.palette.primary.main,
    },
  });

interface IFormikProps {
  formik: FormikProps<PlanningSolutionFormValues>;
}

type IProps = WithStyles<typeof styles>;

export const PlanningSolutionOrdersComponent: React.FC<
  IProps & IFormikProps
> = ({formik, classes}) => {
  const [boardData, setBoardData] = React.useState<IBoardData>({lanes: []});
  const [allocatedBoardData, setAllocatedBoardData] =
    React.useState<IBoardData>({
      lanes: [],
    });
  const [allocatedFilter, setAllocatedFilter] = React.useState<string>('');
  const [unallocatedFilter, setUnAllocatedFilter] = React.useState<string>('');
  const [availableFilter, setAvailableFilter] = React.useState<string>('');
  const [laneFilters, setLaneFilters] = React.useState<Array<ILaneFilter>>([]);
  const [allocatedLaneFilters, setAllocatedLaneFilters] = React.useState<
    Array<ILaneFilter>
  >([]);

  const {rawOrderListings, ordersPlanningFull} = usePlanningSolutionData(
    (store) => ({
      rawOrderListings: store.rawOrderListings,
      ordersPlanningFull: store.planningOrders,
    })
  );

  /** Moves an order from one lane to another */
  const handleCardMove: TGetCardMoveHandlerResponse = (
    fromLaneId,
    toLaneId,
    cardId,
    index
  ) => {
    // let allocatedIndex: number;
    const unallocated = clone()(formik.values.unallocatedOrders || []);

    if (fromLaneId === 'allocated' || toLaneId === 'allocated') {
      // don't move
    } else if (fromLaneId !== toLaneId) {
      const orderId = parseInt(cardId, 10);
      switch (toLaneId) {
        case 'available':
          {
            const allocatedIndex = unallocated.findIndex((c) => c === orderId);
            unallocated.splice(allocatedIndex, 1);
          }
          break;
        case 'unallocated':
          unallocated.splice(index, 0, orderId);
          break;
        default:
          throw new Error('should not get here ever');
      }

      formik.setFieldValue('unallocatedOrders', unallocated);
    }
  };

  /** Allocates all planningOrders to solution */
  const handleAddAll = () => {
    const orders = rawOrderListings.map(({value}) => value);
    let unallocated: (number | undefined)[];

    // calculate available orders
    const available = orders
      .filter((v = -1) => !(formik.values.allocatedOrders || []).includes(v))
      .filter((b = -1) => !(formik.values.unallocatedOrders || []).includes(b));

    const availableListing = rawOrderListings.filter(({value}) =>
      available.includes(value)
    );

    // filter available orders if necessary
    if (availableFilter !== '') {
      // assign filter fields
      const filterOptions = {
        includeMatches: true,
        keys: ['label', 'value'],
      };
      const fFilter = new Fuse(availableListing, filterOptions);
      unallocated = fFilter.search(availableFilter).map(({item}) => item.value);
    } else {
      unallocated = orders.filter(
        (v = -1) => !(formik.values.allocatedOrders || []).includes(v)
      );
    }

    // ensure to add the list of available orders to the currently unallocated orders
    formik.setFieldValue(
      'unallocatedOrders',
      unallocated.concat(formik.values.unallocatedOrders)
    );
  };

  /** Removes all planningOrders from a solution */
  const handleRemoveAll = () => {
    // TODO check trips if planningOrders are assigned or not when saving
    formik.setFieldValue('unallocatedOrders', []);
  };

  /** Sets the filter values */
  // eslint-disable-next-line no-undef
  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filterValue: string = e.target.value;

    let allocated = allocatedLaneFilters[0].value || '';
    let unallocated = laneFilters[1].value || '';
    let available = laneFilters[0].value || '';

    switch (e.target.name) {
      case 'allocated':
        allocated = filterValue;
        break;
      case 'available':
        available = filterValue;
        break;
      case 'unallocated':
        unallocated = filterValue;
        break;
      default:
        throw new Error('filter not found');
    }

    const filters = [
      {
        id: 'available',
        title: `Available ${available.length}`,
        value: available,
      },
      {
        id: 'unallocated',
        title: `Unallocated ${unallocated.length}`,
        value: unallocated,
      },
    ];

    const allocatedFilters = [
      {
        id: 'allocated',
        title: 'Allocated',
        value: allocated,
      },
    ];

    setAllocatedFilter(allocated);
    setAvailableFilter(available);
    setUnAllocatedFilter(unallocated);
    setLaneFilters(filters);
    setAllocatedLaneFilters(allocatedFilters);
  };

  /** Calculates the allocated and unallocated planningOrders */
  React.useEffect(() => {
    const al: Array<PlanningOrder> = []; // allocated
    const av: Array<PlanningOrder> = []; // available
    const un: Array<PlanningOrder> = []; // unallocated

    ordersPlanningFull.forEach((o) => {
      if (!o.id) {
        // ignore
      } else if ((formik.values.unallocatedOrders || []).indexOf(o.id) > -1) {
        un.push(o);
      } else if ((formik.values.allocatedOrders || []).indexOf(o.id) > -1) {
        al.push(o);
      } else {
        av.push(o);
      }
    });

    const filteredUnallocatedData = getFilteredOrderCardData(
      planningOrderListingToLaneData(un),
      unallocatedFilter
    );
    const filteredAllocatedData = getFilteredOrderCardData(
      planningOrderListingToLaneData(al),
      allocatedFilter
    );
    const filteredAvailableData = getFilteredOrderCardData(
      planningOrderListingToLaneData(av),
      availableFilter
    );

    const avunBoardData = {
      lanes: [
        {
          id: 'available',
          title: `Available (${filteredAvailableData.length})`,
          cards: filteredAvailableData,
        },
        {
          id: 'unallocated',
          title: `Unallocated (${filteredUnallocatedData.length})`,
          cards: filteredUnallocatedData,
        },
      ],
    };
    const alBoardData = {
      lanes: [
        {
          id: 'allocated',
          title: `Allocated (${filteredAllocatedData.length})`,
          cards: filteredAllocatedData,
        },
      ],
    };

    setBoardData(avunBoardData);
    setAllocatedBoardData(alBoardData);
  }, [
    formik.values.allocatedOrders,
    formik.values.unallocatedOrders,
    formik.values.unassignedOrders,
    allocatedFilter,
    availableFilter,
    unallocatedFilter,
    rawOrderListings,
    ordersPlanningFull,
  ]);

  React.useEffect(() => {
    setLaneFilters([
      {
        id: 'available',
        title: 'Available',
        value: availableFilter,
      },
      {
        id: 'unallocated',
        title: 'Unallocated',
        value: unallocatedFilter,
      },
    ]);
    setAllocatedLaneFilters([
      {
        id: 'allocated',
        title: 'Allocated',
        value: allocatedFilter,
      },
    ]);
  }, [allocatedFilter, availableFilter, unallocatedFilter]);

  return (
    <Card className={classes.container}>
      <CardContent className={classes.planning}>
        <VantageBoard
          addAll={handleAddAll}
          removeAll={handleRemoveAll}
          data={boardData}
          onCardMoveAcrossLanes={handleCardMove}
          onFilterChange={handleFilterChange}
          filters={laneFilters}
          isHeaderVisible
          square
          title="Planning Solution Orders"
          style={{marginRight: 16}}
        />
        <Card className={classes.tripStopsContainer} square>
          <CardHeader
            title="Allocated Orders"
            avatar={
              <Avatar>
                <SettingsApplicationsIcon />
              </Avatar>
            }
          />
          <CardContent className={classes.tripStops}>
            <VantageBoard
              data={allocatedBoardData}
              onFilterChange={handleFilterChange}
              filters={allocatedLaneFilters}
              isHeaderVisible={false}
              isActionsVisible={false}
              keepActionsSpace
              style={{marginTop: 16}}
            />
          </CardContent>
        </Card>
      </CardContent>
    </Card>
  );
};

export const PlanningSolutionOrders = withStyles(styles)(
  connect<IProps, PlanningSolutionFormValues>(PlanningSolutionOrdersComponent)
);
