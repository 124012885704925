import React from 'react';
import {TemplateCard} from '../../factory/template';
import {BroadcastContext} from './BroadcastContext';
import {TextField} from '@mui/material';

export const BroadcastDetails: React.FC = () => {
  const {loading, loadingSingleItem, broadcast} =
    React.useContext(BroadcastContext);
  const values = React.useMemo(() => broadcast, [broadcast]);

  return (
    <TemplateCard
      title="Broadcast Details"
      loading={loading || loadingSingleItem}
    >
      <TextField
        name="heading"
        label="Heading"
        margin="normal"
        value={values?.heading}
        InputLabelProps={{shrink: true}}
        disabled
        fullWidth
      />
      <TextField
        name="message"
        label="Message"
        margin="normal"
        value={values?.message}
        InputLabelProps={{shrink: true}}
        multiline
        disabled
        fullWidth
      />
      <TextField
        name="scheduledDateTime"
        label="Scheduled Date"
        margin="normal"
        value={values?.scheduledDateTime}
        InputLabelProps={{shrink: true}}
        disabled
        fullWidth
      />
      <TextField
        name="status"
        label="Status"
        margin="normal"
        value={values?.status}
        InputLabelProps={{shrink: true}}
        disabled
        fullWidth
      />
    </TemplateCard>
  );
};
