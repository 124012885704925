import {makeStyles, createStyles} from '@mui/styles';
import {Theme} from '@mui/material/styles';

export const AdminTelematicsConfigDetailsItemStyles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      maxWidth: 600,
      justifyContent: 'space-between',
      margin: theme.spacing(1),
    },
    title: {
      fontWeight: theme.typography.fontWeightMedium,
      marginRight: theme.spacing(1),
    },
    value: {},
  });

export const useAdminTelematicsConfigDetailsItem = makeStyles(
  AdminTelematicsConfigDetailsItemStyles,
  {
    name: 'AdminTelematicsConfigDetailsItemStyles',
  }
);
