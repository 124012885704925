import React from 'react';
import {
  PlanningSkillContext,
  PlanningSkillContextProps,
} from './PlanningSkillContext';
import {PlanningSkillForm} from './PlanningSkillForm';
import {ITemplateTabControllerTab, TemplateTabs} from '../../factory/template';
import {PlanningSkillNodeList} from './planningSkillNodeList';
import {PlanningSkillProductGroupList} from './planningSkillProductGroupList';
import {PlanningSkillVehicleTypeList} from './planningSkillVehicleTypeList';
import {PlanningSkillNodeTypeList} from './planningSkillNodeTypeList';
import {PlanningSkillVehicleGroupList} from './planningSkillVehicleGroupList';
import {PlanningSkillDriverGroupList} from './planningSkillDriverGroupList';
import {PlanningSkillCustomerList} from './planningSkillCustomerList';

export const PlanningSkill: React.FC = () => {
  const {loading, planningSkillId} =
    React.useContext<PlanningSkillContextProps>(PlanningSkillContext);

  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {title: 'Details', element: <PlanningSkillForm />},
    {title: 'Site', element: <PlanningSkillNodeList />},
    {title: 'Node Type', element: <PlanningSkillNodeTypeList />},
    {title: 'Vehicle Type', element: <PlanningSkillVehicleTypeList />},
    {title: 'Vehicle Group', element: <PlanningSkillVehicleGroupList />},
    {title: 'Driver Group', element: <PlanningSkillDriverGroupList />},
    {title: 'Product Group', element: <PlanningSkillProductGroupList />},
    {title: 'Customer', element: <PlanningSkillCustomerList />},
  ]);

  const path = React.useMemo(
    () => `/planningskilllist/${planningSkillId}`,
    [planningSkillId]
  );

  return <TemplateTabs path={path} tabs={tabs} loading={loading} />;
};
