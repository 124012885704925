import React from 'react';
import {
  requiredMultipleListingSchema,
  TemplateCard,
  TemplateForm,
} from '../../factory/template';
import {CriticalEventTypeContext} from './CriticalEventTypeContext';
import {Listing} from '@onroadvantage/onroadvantage-api';
import * as Yup from 'yup';
import {FormikAutocompleteWithListing, FormikTextField} from '../formik';
import {CancelPresentation} from '@mui/icons-material';

export interface ICriticalEventTypeBulkForm {
  comment: string;
  vehicle: Listing[];
  criticalEventType: Listing[];
}

const schema: Yup.SchemaOf<ICriticalEventTypeBulkForm> = Yup.object({
  comment: Yup.string().required('Required'),
  vehicle: requiredMultipleListingSchema,
  criticalEventType: requiredMultipleListingSchema,
});

export const CriticalEventTypeBulkCloseForm: React.FC = () => {
  const {detailsRef, loading, submitting, onDetailsFormSubmit} =
    React.useContext(CriticalEventTypeContext);
  const [initialValues] = React.useState<ICriticalEventTypeBulkForm>({
    comment: '',
    vehicle: [],
    criticalEventType: [],
  });

  return (
    <TemplateCard
      title={`Bulk Closing`}
      avatar={<CancelPresentation />}
      loading={loading}
    >
      <TemplateForm<ICriticalEventTypeBulkForm>
        initialValues={initialValues}
        onSubmit={onDetailsFormSubmit}
        permission={{name: 'CriticalEvent BulkAction', type: 'Edit'}}
        submitting={submitting}
        innerRef={detailsRef}
        validationSchema={schema}
      >
        <FormikAutocompleteWithListing
          label="Vehicle"
          name="vehicle"
          model="Vehicle"
          placeholder="Enter and select a value"
          selectAll="Select All Vehicles"
          multiple
        />
        <FormikAutocompleteWithListing
          label="Critical Event Type"
          name="criticalEventType"
          model="WebCriticalEventType"
          selectAll="Select All Critical Event Types"
          placeholder="Enter and select a value"
          multiple
        />
        <FormikTextField
          label="Comment"
          name="comment"
          placeholder="Enter a comment"
          fullWidth
        />
      </TemplateForm>
    </TemplateCard>
  );
};
