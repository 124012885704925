import React from 'react';
import {SpeedTableForm} from './SpeedTableForm';
import {ITemplateTabControllerTab, TemplateTabs} from '../../factory/template';
import {useSpeedTableContext} from './speedTableContext';
import {SpeedTableBandList} from './speedTableBandList';

export const SpeedTable: React.FC = () => {
  const {speedTableId, loadingSpeedTable} = useSpeedTableContext();

  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {title: 'Details', element: <SpeedTableForm />},
    {title: 'Speed Table Bands', element: <SpeedTableBandList />},
  ]);

  const path = `/speedtablelist/${speedTableId}`;

  return <TemplateTabs tabs={tabs} path={path} loading={loadingSpeedTable} />;
};
