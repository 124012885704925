import React from 'react';
import * as Yup from 'yup';
import {SpeedTable, Listing} from '@onroadvantage/onroadvantage-api';
import {
  multipleListingSchema,
  requiredMultipleListingSchema,
  TemplateCard,
  TemplateForm,
} from '../../factory/template';
import {useSpeedTableContext} from './speedTableContext';
import {FormikAutocompleteWithListing, FormikTextField} from '../formik';

export interface ISpeedTableForm {
  name: string;
  contracts: Listing[];
  vehicleTypes?: Listing[] | undefined;
}

const schema: Yup.SchemaOf<ISpeedTableForm> = Yup.object({
  name: Yup.string().required('Required'),
  contracts: requiredMultipleListingSchema,
  vehicleTypes: multipleListingSchema,
});

const getInitialValues = (
  speedTable?: SpeedTable | null
): ISpeedTableForm | undefined => {
  if (speedTable) {
    return {
      name: speedTable.name ?? '',
      contracts: speedTable.contracts?.map(({id, code, name}) => ({
        value: id,
        label: `${code} - ${name}`,
      })),
      vehicleTypes: speedTable.vehicleTypes?.map(({id, name}) => ({
        value: id ?? undefined,
        label: name,
      })),
    };
  }
  return undefined;
};

export const SpeedTableForm: React.FC<{isAdd?: boolean}> = ({isAdd}) => {
  const {
    detailsRef,
    loadingSpeedTable,
    submitting,
    speedTable,
    onDetailsFormSubmit,
  } = useSpeedTableContext();
  const [initialValues, setInitialValues] = React.useState<ISpeedTableForm>();

  React.useEffect(() => {
    const values = getInitialValues(speedTable);

    if (values) {
      setInitialValues(values);
    } else if (isAdd) {
      setInitialValues({
        name: '',
        contracts: [],
      });
    }
  }, [isAdd, speedTable, setInitialValues]);

  return (
    <TemplateCard
      title={`Speed Table Details ${isAdd ? 'Add' : 'Edit'}`}
      loading={loadingSpeedTable}
    >
      <TemplateForm<ISpeedTableForm>
        initialValues={initialValues}
        onSubmit={onDetailsFormSubmit}
        permission={{
          name: `${isAdd ? 'Add' : 'Edit'} SpeedTable`,
          type: isAdd ? 'Add' : 'Edit',
        }}
        submitting={submitting}
        innerRef={detailsRef}
        validationSchema={schema}
      >
        <FormikTextField
          name="name"
          placeholder="Enter the speed table name"
          label="Name"
          fullWidth
        />
        <FormikAutocompleteWithListing
          name="contracts"
          placeholder="Enter and select the speed table contracts"
          label="Contracts"
          model="Contract"
          multiple
        />
        <FormikAutocompleteWithListing
          name="vehicleTypes"
          placeholder="Enter and select the speed table vehicle types"
          label="Vehicle Types"
          model="VehicleType"
          multiple
        />
      </TemplateForm>
    </TemplateCard>
  );
};
