import React from 'react';
import {
  MasterRoute,
  MasterRouteLegWaypoint,
} from '@onroadvantage/onroadvantage-api';
import hash from 'object-hash';

import {GeoJSON, Marker} from 'react-leaflet';
import {MasterRouteLegRoutable} from '../../../../stores/MapDisplayStore';
import L from 'leaflet';
import NumberMarker from '../../../map/marker/NumberMarker';
import MaserRouteWaypoint from '../../../map/marker/popup/MaserRouteWaypoint';

interface Props {
  masterRoute?: MasterRoute;
  masterRouteLeg: MasterRouteLegRoutable | null;
  masterRouteLegOnClick: any;
  onDragRouteWaypoint: any;
  closeNewMarker: () => void;
}

const MasterRouteMapFeature: React.FC<Props> = (props) => {
  const {masterRouteLeg, masterRouteLegOnClick, onDragRouteWaypoint} = props;

  if (!masterRouteLeg || !masterRouteLeg.geoJson) {
    return null;
  }

  const masterRouteFeature = masterRouteLeg && (
    <GeoJSON
      key={`${hash(masterRouteLeg.geoJson)}`}
      data={masterRouteLeg.geoJson}
      onEachFeature={(feature, layer) => {
        layer.on({
          click: (event) => masterRouteLegOnClick(event, masterRouteLeg, layer),
        });
      }}
    />
  );

  const masterRouteWaypointMarkers =
    masterRouteLeg.waypoints &&
    masterRouteLeg.waypoints.length > 0 &&
    masterRouteLeg.waypoints.map((waypoint: MasterRouteLegWaypoint) => {
      const Icon = L.ExtraMarkers.icon({
        icon: 'fa-number',
        markerColor: 'green',
        shape: 'penta',
        prefix: 'fa',
        number: `${waypoint.sequence}`,
      });

      return (
        <Marker
          icon={Icon}
          key={`${waypoint.id}`}
          position={[waypoint.latitude, waypoint.longitude]}
          onDragEnd={(event: any) =>
            onDragRouteWaypoint(event, masterRouteLeg, waypoint, false)
          }
          onDrag={() => {}}
          draggable={true}
        >
          <MaserRouteWaypoint
            onEdit={() => {
              onDragRouteWaypoint(
                {
                  target: {
                    _latlng: {
                      lat: waypoint.latitude,
                      lng: waypoint.longitude,
                    },
                  },
                },
                masterRouteLeg,
                waypoint,
                true
              );
              props.closeNewMarker();
            }}
            position={{
              latitude: waypoint.latitude,
              longitude: waypoint.longitude,
            }}
            sequence={waypoint.sequence}
          />
        </Marker>
      );
    });

  return (
    <>
      {masterRouteFeature}
      {masterRouteLeg.fromNode && (
        <NumberMarker
          key="masterRouteLegFromNode"
          position={[
            masterRouteLeg.fromNode.latitude,
            masterRouteLeg.fromNode.longitude,
          ]}
          number={1}
        />
      )}
      {masterRouteLeg.toNode && (
        <NumberMarker
          key="masterRouteLegToNode"
          position={[
            masterRouteLeg.toNode.latitude,
            masterRouteLeg.toNode.longitude,
          ]}
          number={
            masterRouteLeg.waypoints ? masterRouteLeg.waypoints.length + 2 : ''
          }
        />
      )}
      {masterRouteWaypointMarkers}
    </>
  );
};

export default MasterRouteMapFeature;
