import React from 'react';
import {
  ITemplateTabControllerTab,
  TemplateTabs,
} from '../../../factory/template';
import {NotificationConfigOperationalEscalationNodeContacts} from './NotificationConfigOperationalEscalationNodeContacts';
import {NotificationConfigContext} from '../NotificationConfigContext';
import {NotificationConfigOperationalEscalationContext} from './NotificationConfigOperationalEscalationContext';
import {NotificationConfigOperationalEscalationContractContacts} from './NotificationConfigOperationalEscalationContractContacts';
import {NotificationConfigOperationalEscalationCustomerContacts} from './NotificationConfigOperationalEscalationCustomerContacts';
import {NotificationConfigOperationalEscalationVehicleGroupContacts} from './NotificationConfigOperationalEscalationVehicleGroupContacts';

export const NotificationConfigOperationalEscalation: React.FC = () => {
  const {notificationConfigId} = React.useContext(NotificationConfigContext);
  const {notificationConfigEventEscalationId, loading} = React.useContext(
    NotificationConfigOperationalEscalationContext
  );

  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {
      title: 'Contract Contact',
      element: <NotificationConfigOperationalEscalationContractContacts />,
    },
    {
      title: 'Customer Contact',
      element: <NotificationConfigOperationalEscalationCustomerContacts />,
    },
    {
      title: 'Node Contact',
      element: <NotificationConfigOperationalEscalationNodeContacts />,
    },
    {
      title: 'Vehicle Group Contact',
      element: <NotificationConfigOperationalEscalationVehicleGroupContacts />,
    },
  ]);

  const path = React.useMemo(
    () =>
      `/notificationconfiglist/${notificationConfigId}/escalation/${notificationConfigEventEscalationId}`,
    [notificationConfigId, notificationConfigEventEscalationId]
  );

  return <TemplateTabs tabs={tabs} path={path} loading={loading} />;
};
