import React, {useState} from 'react';
import {Tab, Tabs} from '@mui/material';
import {useTemplateTabControllerStyles} from '../../factory/template/tabs/TemplateTabs.style';
import {NotificationConfigOperationalEventTriggersList} from './notificationConfigOperationalEvent/NotificationConfigOperationalEventTriggersList';
import {NotificationConfigCriticalEventTriggersList} from './notificationConfigCriticalEvent/NotificationConfigCriticalEventTriggersList';
const NotificationConfigEventTriggers: React.FC = () => {
  const classes = useTemplateTabControllerStyles();
  const [tabValue, setTabValue] = useState<number>(0);

  const handleTabChange = (event: React.SyntheticEvent, value: number) => {
    setTabValue(value);
  };

  return (
    <>
      <Tabs
        className={classes.tabs}
        value={tabValue}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab classes={{root: classes.tab}} label="Critical Event  Triggers" />
        <Tab classes={{root: classes.tab}} label="Operational Event Triggers" />
      </Tabs>
      {tabValue === 0 && <NotificationConfigCriticalEventTriggersList />}
      {tabValue === 1 && <NotificationConfigOperationalEventTriggersList />}
    </>
  );
};

export default NotificationConfigEventTriggers;
