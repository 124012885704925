import React from 'react';
import {
  ApiContractMediaEventDriverReasonCodeGetRequest,
  ContractMediaEventDriverReasonCode,
  ContractMediaEventDriverReasonCodeList,
} from '@onroadvantage/onroadvantage-api';
import {contractMediaEventDriverReasonCodeApi} from '../../../api';
import {
  TemplateTableContextProps,
  TLoadList,
  TOnInlineAdd,
  TOnInlineEdit,
  useTemplateTable,
} from '../../../factory/template';
import {useAppNotifications} from '../../../contexts';
import {useContractContext} from '../contractContext';

export type ContractDriverEventReasonCodeListContextProps =
  TemplateTableContextProps<
    ContractMediaEventDriverReasonCode,
    ContractMediaEventDriverReasonCodeList
  >;

export const ContractDriverEventReasonCodeListContext =
  React.createContext<ContractDriverEventReasonCodeListContextProps>({
    loading: false,
    list: [],
    currentPage: 1,
    loadList: async () => {},
  });

export const useContractDriverEventReasonCodeListContext = () =>
  React.useContext(ContractDriverEventReasonCodeListContext);

export const ContractDriverEventReasonCodeListContextProvider: React.FC = ({
  children,
}) => {
  const {contractId, loadingContract} = useContractContext();
  const notify = useAppNotifications();

  const [
    {
      // States
      currentPage,
      filters,
      itemTotal,
      hasPermission,
      list,
      loading,
      pageSize,
      pageTotal,
      sorting,
    },
    {
      // Getters
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
    },
  ] = useTemplateTable<
    ContractMediaEventDriverReasonCode,
    ApiContractMediaEventDriverReasonCodeGetRequest
  >({
    editPermission: 'Edit Contract',
    addPermission: 'Add Contract',
    deletePermission: 'Delete Contract',
    downloadPermission: 'Contract ListDownload',
    viewPermission: 'Contract List',
  });
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const loadList = React.useCallback<
    TLoadList<ContractMediaEventDriverReasonCodeList>
  >(
    async (options) => {
      setLoading(true);
      try {
        const requestObj = getRequestObj(['description'], options);
        const response =
          await contractMediaEventDriverReasonCodeApi.apiContractMediaEventDriverReasonCodeGet(
            {...requestObj, contractIds: contractId ? [contractId] : undefined}
          );
        return getResponse(response, options);
      } catch (e) {
        notify('error', 'Failed to load contract event reason code list');
      } finally {
        setLoading(false);
      }
    },
    [contractId, getRequestObj, getResponse, notify, setLoading]
  );

  const handleDelete = React.useCallback(
    async (row: ContractMediaEventDriverReasonCode) => {
      setLoading(true);
      try {
        if (row.id) {
          await contractMediaEventDriverReasonCodeApi.apiContractMediaEventDriverReasonCodeContractMediaEventDriverReasonCodeIdDelete(
            {
              contractMediaEventDriverReasonCodeId: row.id,
            }
          );
          notify('success', 'Deleted contract event reason code');
          await loadList();
        }
      } catch (e) {
        notify('error', 'Failed to delete contract event reason code');
      } finally {
        setLoading(false);
      }
    },
    [loadList, notify, setLoading]
  );

  const handleInlineAdd = React.useCallback<TOnInlineAdd>(
    async (changes) => {
      setSubmitting(true);
      try {
        const description = (
          changes.length === 0 ? undefined : changes[0].description
        ) as string | undefined;
        if (contractId && description) {
          await contractMediaEventDriverReasonCodeApi.apiContractMediaEventDriverReasonCodePost(
            {body: {contractId, description}}
          );
          notify('success', 'Added contract event reason code');
          await loadList();
        }
      } catch (e) {
        notify('error', 'Failed to add contract event reason code');
      } finally {
        setSubmitting(false);
      }
    },
    [contractId, loadList, notify]
  );

  const handleInlineEdit = React.useCallback<TOnInlineEdit>(
    async (changes) => {
      setSubmitting(true);
      try {
        if (contractId) {
          for (const change of changes) {
            const contractMediaEventDriverReasonCodeId = parseInt(change.id);
            const description = change.newValues.description as
              | string
              | undefined;
            if (contractMediaEventDriverReasonCodeId) {
              await contractMediaEventDriverReasonCodeApi.apiContractMediaEventDriverReasonCodeContractMediaEventDriverReasonCodeIdPatch(
                {
                  contractMediaEventDriverReasonCodeId,
                  body: {contractId, description},
                }
              );
              notify('success', 'Updated contract event reason code');
            }
          }
          await loadList();
        }
      } catch (e) {
        notify('error', 'Failed to update contract event reason code');
      } finally {
        setSubmitting(false);
      }
    },
    [contractId, loadList, notify]
  );

  const handleRefresh = React.useCallback(async () => {
    await loadList();
  }, [loadList]);

  const value: ContractDriverEventReasonCodeListContextProps = {
    loading: loadingContract || loading || submitting,
    list,
    hasPermission,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onInlineAdd: hasPermission.edit ? handleInlineAdd : undefined,
    onInlineEdit: hasPermission.edit ? handleInlineEdit : undefined,
    onDelete: hasPermission.edit ? handleDelete : undefined,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onRefresh: handleRefresh,
    loadList,
    cleanupList,
  };

  return (
    <ContractDriverEventReasonCodeListContext.Provider value={value}>
      {children}
    </ContractDriverEventReasonCodeListContext.Provider>
  );
};
