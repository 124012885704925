import React from 'react';
import {useHistory} from 'react-router-dom';
import {
  MasterTripDebriefMasterTripDebriefConfig,
  MasterTripDebriefSnapshot,
  MasterTripDebriefSnapshotData,
  MasterTripDebriefTripStopSnapshot,
  MasterTripDebriefPostSnapshot,
} from '@onroadvantage/onroadvantage-api';
import {useAppNotifications} from '../../contexts';
import {masterTripDebriefApi} from '../../api';

export const NOT_LAST_WARNING_MESSAGE =
  'The Debrief you are trying to update is not the latest';
export const ALREADY_APPROVED_WARNING_MESSAGE =
  'The Debrief has already been approved';

export type TripDebriefTabs =
  | 'Kilometers'
  | 'Times'
  | 'Orders'
  | 'Documents'
  | 'Notifications'
  | 'Tasks';

export interface TripDebriefContextProps {
  loadTripDebrief: () => Promise<void>;
  onUpdateSubmit: (params: {
    debriefData?:
      | Omit<
          Partial<MasterTripDebriefPostSnapshot>,
          'overrideData' | 'snapshotData'
        >
      | undefined;
    overrideData?: MasterTripDebriefSnapshot | undefined;
  }) => Promise<void>;
  onTabNavigate: (tab: TripDebriefTabs) => void;
  submitting: boolean;
  loading: boolean;
  errorOccurred: boolean;
  overrideDuplicateError: boolean;
  duplicateErrorOccurred: boolean;
  inDebrief: boolean;
  duplicateErrorMessage: string | undefined;
  handleOverrideDuplicateError: () => Promise<void>;
  masterTripDebriefData: MasterTripDebriefSnapshotData | null | undefined;
  masterTripDebriefConfig:
    | MasterTripDebriefMasterTripDebriefConfig
    | null
    | undefined;
  masterTripId: number | undefined;
  tripStop: MasterTripDebriefTripStopSnapshot | undefined;
  tripStopId: number | undefined;
  orderLineId: number | undefined;
  setMasterTripDebriefData: React.Dispatch<
    React.SetStateAction<MasterTripDebriefSnapshotData | null | undefined>
  >;
  setMasterTripId: React.Dispatch<React.SetStateAction<number | undefined>>;
  setTripStopId: React.Dispatch<React.SetStateAction<number | undefined>>;
  setOrderLineId: React.Dispatch<React.SetStateAction<number | undefined>>;
  setDuplicateErrorMessage: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  setOverrideDuplicateError: React.Dispatch<React.SetStateAction<boolean>>;
  setInDebrief: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TripDebriefContext = React.createContext<TripDebriefContextProps>({
  loadTripDebrief: async () => {},
  onUpdateSubmit: async () => {},
  onTabNavigate: () => null,
  setMasterTripDebriefData: () => null,
  setMasterTripId: () => null,
  setTripStopId: () => null,
  setOrderLineId: () => null,
  setDuplicateErrorMessage: () => undefined,
  setOverrideDuplicateError: () => false,
  setInDebrief: () => false,
  submitting: false,
  loading: false,
  errorOccurred: false,
  duplicateErrorOccurred: false,
  overrideDuplicateError: false,
  duplicateErrorMessage: undefined,
  inDebrief: false,
  handleOverrideDuplicateError: async () => {},
  masterTripDebriefData: undefined,
  masterTripDebriefConfig: undefined,
  masterTripId: undefined,
  tripStop: undefined,
  tripStopId: undefined,
  orderLineId: undefined,
});

export const TripDebriefContextProvider: React.FC = ({children}) => {
  const history = useHistory();
  const notify = useAppNotifications();
  /** Root masterTripDebriefData state, set when the MasterTripDebrief is loaded */
  const [masterTripDebriefData, setMasterTripDebriefData] = React.useState<
    MasterTripDebriefSnapshotData | null | undefined
  >();
  const [masterTripDebriefConfig, setMasterTripDebriefConfig] = React.useState<
    MasterTripDebriefMasterTripDebriefConfig | null | undefined
  >();
  /**
   * To set the active stop the user navigates into on the TripDebriefSummaryStops table. Will be set when there is the
   * tripStopId state has been set.
   */
  const [tripStop, setTripStop] = React.useState<
    MasterTripDebriefTripStopSnapshot | undefined
  >();
  /** The masterTripId of the current tripDebrief you are currently viewing. It's set in the TripDebriefIdHandler */
  const [masterTripId, setMasterTripId] = React.useState<number | undefined>();
  /**
   * The stopId of the stop you are currently viewing on the TripDebriefSummaryStops. It's set in the
   * TripDebriefSummaryStopIdHandler component.
   */
  const [tripStopId, setTripStopId] = React.useState<number | undefined>();
  /**
   * The orderLineId of the orderLine you are currently viewing on either the TripDebriefSummaryStopOrderLineList or
   * TripDebriefOrderLineList. It's set in the TripDebriefOrderLineIdHandler component.
   */
  const [orderLineId, setOrderLineId] = React.useState<number | undefined>();
  /**
   * The duplicateErrorMessage is used to store the error message received from backend if any regarding duplicate trips
   * when loading tripDebrief
   * */
  const [duplicateErrorMessage, setDuplicateErrorMessage] = React.useState<
    string | undefined
  >();

  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  /** To handle whenever the loadTripDebrief call fails or gives an error */
  const [errorOccurred, setErrorOccurred] = React.useState<boolean>(false);

  /** Used for the duplicate trips error logic**/
  const [duplicateErrorOccurred, setDuplicateErrorOccurred] =
    React.useState<boolean>(false);
  const [overrideDuplicateError, setOverrideDuplicateError] =
    React.useState<boolean>(false);
  const [inDebrief, setInDebrief] = React.useState<boolean>(false);

  /** Load the current masterTripDebrief  */
  const loadTripDebrief = React.useCallback(async () => {
    setLoading(true);
    try {
      if (masterTripId) {
        const response =
          await masterTripDebriefApi.apiMasterTripMasterTripIdDebriefGet({
            masterTripId,
          });

        setErrorOccurred(false);
        setDuplicateErrorOccurred(false);
        setOverrideDuplicateError(false);

        if (response.data) {
          setMasterTripDebriefData(response.data);
        }
        if (response.config) {
          setMasterTripDebriefConfig(response.config);
        }
      } else {
        setMasterTripDebriefData(undefined);
      }
    } catch (e) {
      setMasterTripDebriefData(undefined);
      if (
        e.message.includes(
          'cannot be debriefed because duplicate trip/s exists with trip number/s'
        )
      ) {
        setInDebrief(true);
        setDuplicateErrorOccurred(true);
        const errorMessageObject = JSON.parse(e.message);
        const errorMessage = errorMessageObject.message.replace(/\/s/g, '(s)');
        setDuplicateErrorMessage(errorMessage);
      } else {
        setErrorOccurred(true);
        notify('error', e.message ?? 'Failed to load trip Debrief');
      }
    } finally {
      setLoading(false);
    }
  }, [masterTripId, notify]);

  const handleOverrideDuplicateError = React.useCallback(async () => {
    setLoading(true);
    setOverrideDuplicateError(true);
    try {
      if (masterTripId) {
        const response =
          await masterTripDebriefApi.apiMasterTripMasterTripIdDebriefGet({
            masterTripId,
            overrideDuplicateError: true,
          });

        setErrorOccurred(false);
        setDuplicateErrorOccurred(false);
        setDuplicateErrorMessage(undefined);

        if (response.data) {
          setMasterTripDebriefData(response.data);
        }

        if (response.config) {
          setMasterTripDebriefConfig(response.config);
        }
      } else {
        setMasterTripDebriefData(undefined);
      }
    } catch (e) {
      setMasterTripDebriefData(undefined);
      setErrorOccurred(true);
      notify('error', e.message ?? 'Failed to load trip Debrief');
    } finally {
      setLoading(false);
    }
  }, [masterTripId, notify]);
  // notify
  /**
   * Handle trip debrief updates. Will be used in any of the Debrief Forms, such as TripDebriefKilometersForm.
   * The Formik forms will handle their own separate submit and pass the relevant overrideData or debriefData that needs
   * to be updated.
   * Since the apiMasterTripMasterTripIdDebriefPatch call return the updated masterTripDebriefData, we just set and
   * update the masterTripDebriefData state, instead of call the loadTripDebrief handler
   * again.
   * When the user gets 409 error the revision number was not the latest, which means we need to reload the debriefData
   * to  get the latest revision number
   */
  const handleUpdateSubmit = React.useCallback<
    TripDebriefContextProps['onUpdateSubmit']
  >(
    async ({overrideData, debriefData}) => {
      setSubmitting(true);
      try {
        const {
          revision,
          overrideData: prevOverrideData,
          ...prevMasterTripDebriefData
        } = masterTripDebriefData || ({} as MasterTripDebriefSnapshotData);
        if (masterTripId && revision) {
          const response =
            await masterTripDebriefApi.apiMasterTripMasterTripIdDebriefPatch({
              masterTripId,
              body: {
                ...prevMasterTripDebriefData,
                ...debriefData,
                revision,
                overrideData: {
                  ...prevOverrideData,
                  ...overrideData,
                },
              },
            });
          setLoading(true);
          if (response.data) {
            setMasterTripDebriefData(response.data);
          }
        }
        notify('success', 'Updated TripDebrief');
      } catch (e) {
        if (e.status === 409) {
          if (masterTripDebriefData?.approvedForBilling) {
            /** Can't update the debrief if it's already approved */
            notify('warning', e.message ?? ALREADY_APPROVED_WARNING_MESSAGE);
          } else {
            /** Can't update the debrief if it's revision is not the latest for the current debrief */
            notify('warning', e.message ?? NOT_LAST_WARNING_MESSAGE);
          }
          await loadTripDebrief();
        } else {
          notify('error', e.message ?? 'Failed to update trip Debrief');
        }
      } finally {
        setSubmitting(false);
        setLoading(false);
      }
    },
    [masterTripId, notify, masterTripDebriefData, loadTripDebrief]
  );

  /** Handle tab navigations from the Summary view's cards */
  const handleTabChange = React.useCallback<
    TripDebriefContextProps['onTabNavigate']
  >(
    (tab) => history.push(`/tripdebrieflist/${masterTripId}?tab=${tab}`),
    [history, masterTripId]
  );

  const value: TripDebriefContextProps = {
    loadTripDebrief,
    handleOverrideDuplicateError,
    inDebrief,
    onUpdateSubmit: handleUpdateSubmit,
    onTabNavigate: handleTabChange,
    setMasterTripDebriefData,
    setMasterTripId,
    setTripStopId,
    setOrderLineId,
    setDuplicateErrorMessage,
    setOverrideDuplicateError,
    setInDebrief,
    submitting,
    loading,
    errorOccurred,
    duplicateErrorOccurred,
    duplicateErrorMessage,
    overrideDuplicateError,
    masterTripDebriefData,
    masterTripDebriefConfig,
    tripStop,
    masterTripId,
    tripStopId,
    orderLineId,
  };

  React.useEffect(() => {
    /**
     * When there is a tripStopId, meaning they navigated into a TripDebriefSummaryStop, we find that stop in the
     * snapshotData and set to the tripStop state.
     */
    if (tripStopId && masterTripDebriefData) {
      if (
        masterTripDebriefData?.overrideData?.stops &&
        masterTripDebriefData?.overrideData.stops.find(
          ({id}) => id === tripStopId
        )
      ) {
        setTripStop(
          masterTripDebriefData?.overrideData.stops.find(
            ({id}) => id === tripStopId
          )
        );
      } else {
        setTripStop(
          masterTripDebriefData.snapshotData?.stops?.find(
            ({id}) => id === tripStopId
          )
        );
      }
    }
    return () => setTripStop(undefined);
  }, [masterTripDebriefData, tripStopId]);

  React.useEffect(() => {
    /** Load state onMount */
    loadTripDebrief();
    return () => {
      /** Cleanup state on unUnmount */
      setMasterTripDebriefData(undefined);
    };
  }, [loadTripDebrief]);

  return (
    <TripDebriefContext.Provider value={value}>
      {children}
    </TripDebriefContext.Provider>
  );
};
