import React from 'react';
import {useHistory} from 'react-router-dom';
import {
  ApiDriverRankIncentiveConfigGetRequest,
  DriverRankIncentiveConfigMinimal,
  DriverRankIncentiveConfigList,
  DriverRankIncentiveConfigPost,
  DriverRankIncentiveConfig,
  Listing,
} from '@onroadvantage/onroadvantage-api';
import {FormikHelpers, FormikProps} from 'formik/dist/types';
import {useAppNotifications} from '../../contexts';
import {driverRankIncentiveApi, listingApi} from '../../api';
import {
  TemplateTableContextProps,
  TLoadList,
  useTemplateTable,
} from '../../factory/template';
import {IIncentiveForm} from './DriverRankIncentiveForm';
import moment from 'moment';

export interface IncentiveContextProps
  extends TemplateTableContextProps<
    DriverRankIncentiveConfigMinimal,
    DriverRankIncentiveConfigList
  > {
  onDetailsFormSubmit: (
    values: IIncentiveForm,
    formikHelpers: FormikHelpers<IIncentiveForm>
  ) => void;
  setDriverRankIncentiveId: (value: number | undefined) => void;
  submitting: boolean;
  driverScoreTypeListing?: Listing[];
  driverRankIncentive?: DriverRankIncentiveConfig;
  driverRankIncentiveId?: number;
  detailsRef?: React.Ref<FormikProps<IIncentiveForm>>;
}

export const DriverRankIncentiveContext =
  React.createContext<IncentiveContextProps>({
    // Template Table Defaults
    loading: false,
    list: [],
    currentPage: 1,
    // Incentive
    loadList: async () => {},
    onDetailsFormSubmit: () => null,
    setDriverRankIncentiveId: () => null,
    submitting: false,
  });

interface IncentiveContextProviderProps {
  incentiveId?: number;
}

export const DriverRankIncentiveContextProvider: React.FC<
  IncentiveContextProviderProps
> = ({children}) => {
  const history = useHistory();
  const notify = useAppNotifications();
  // Template Table
  const [
    {
      currentPage,
      pageSize,
      pageTotal,
      sorting,
      hasPermission,
      itemTotal,
      list,
      loading,
      loadingSingleItem,
      filters,
    },
    {
      // Getters
      getDownloads,
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
      setLoadingSingleItem,
    },
  ] = useTemplateTable<
    DriverRankIncentiveConfigMinimal,
    ApiDriverRankIncentiveConfigGetRequest
  >({
    editPermission: 'Edit DriverRankIncentive',
    addPermission: 'Add DriverRankIncentive',
    deletePermission: 'Delete DriverRankIncentive',
    downloadPermission: 'DriverRankIncentive ListDownload',
    viewPermission: 'DriverRankIncentive List',
  });

  const loadList = React.useCallback<TLoadList<DriverRankIncentiveConfigList>>(
    async (options) => {
      setLoading(true);
      try {
        const requestObj = getRequestObj(['name', 'description'], options);
        const response =
          await driverRankIncentiveApi.apiDriverRankIncentiveConfigGet(
            requestObj
          );
        return getResponse(response, options);
      } catch (e) {
        notify(
          'error',
          e.message ?? 'Failed to load driver rank driverRankIncentive list'
        );
      } finally {
        setLoading(false);
      }
    },
    [getRequestObj, getResponse, notify, setLoading]
  );

  const handleDelete = React.useCallback(
    async (row: DriverRankIncentiveConfigMinimal) => {
      setLoading(true);
      try {
        if (row.id) {
          await driverRankIncentiveApi.apiDriverRankIncentiveConfigDriverRankIncentiveConfigIdDelete(
            {
              driverRankIncentiveConfigId: row.id,
            }
          );
          await loadList();
          notify('success', 'Deleted driver rank driverRankIncentive');
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to delete');
      } finally {
        setLoading(false);
      }
    },
    [loadList, notify, setLoading]
  );

  const handleDownload = React.useCallback(
    () =>
      getDownloads('driverRankIncentive', loadList, [
        {name: 'Name', path: 'name'},
        {name: 'Max Achievers', path: 'maxAchievers'},
        {name: 'Incentive Period', path: 'period'},
      ]),
    [getDownloads, loadList]
  );

  const handleAdd = React.useCallback(
    () => history.push('/driverrankincentivelist/add'),
    [history]
  );

  const handleNavigate = React.useCallback(
    (row) => history.push(`/driverrankincentivelist/${row.id}`),
    [history]
  );
  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  // Forms
  const [driverRankIncentive, setDriverRankIncentive] = React.useState<
    DriverRankIncentiveConfig | undefined
  >();
  const [driverRankIncentiveId, setDriverRankIncentiveId] = React.useState<
    number | undefined
  >();
  const [driverScoreTypeListing, setDriverScoreTypeListing] =
    React.useState<Listing[]>();
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const detailsRef = React.useRef<FormikProps<IIncentiveForm>>(null);

  const loadIncentive = React.useCallback(async () => {
    setLoadingSingleItem(true);
    try {
      if (driverRankIncentiveId) {
        const response =
          await driverRankIncentiveApi.apiDriverRankIncentiveConfigDriverRankIncentiveConfigIdGet(
            {
              driverRankIncentiveConfigId: driverRankIncentiveId,
            }
          );
        setDriverRankIncentive(response);
      } else {
        setDriverRankIncentive(undefined);
      }
    } catch (e) {
      notify('error', e.message ?? 'Failed to load driver rank incentive');
    } finally {
      setLoadingSingleItem(false);
    }
  }, [setLoadingSingleItem, driverRankIncentiveId, notify]);

  const loadListing = React.useCallback(async () => {
    try {
      const driverScoreResponse = await listingApi.apiListingGet({
        model: 'DriverScoreType',
      });
      setDriverScoreTypeListing(driverScoreResponse.items);
    } catch (e) {
      notify(
        'error',
        e.message ?? 'Failed to load Driver Incentive Type Listing'
      );
    }
  }, [notify]);

  const handleDetailsFormSubmit = React.useCallback(
    async (
      values: IIncentiveForm,
      formikHelpers: FormikHelpers<IIncentiveForm>
    ) => {
      setSubmitting(true);
      try {
        const {
          contract,
          driverScoreType,
          period,
          validFrom,
          validTo,
          ...otherValues
        } = values;
        if (contract.value && driverScoreType?.value) {
          const newValues: DriverRankIncentiveConfigPost = {
            ...otherValues,
            maxAchievers: otherValues.maxAchievers
              ? parseFloat(otherValues.maxAchievers.toString())
              : undefined,
            qualifyingKmTravelled: otherValues.qualifyingKmTravelled
              ? parseFloat(otherValues.qualifyingKmTravelled.toString())
              : undefined,
            rankMaxDemeritRatioThreshold:
              otherValues.rankMaxDemeritRatioThreshold
                ? parseFloat(
                    otherValues.rankMaxDemeritRatioThreshold.toString()
                  )
                : undefined,
            rankMaxDemeritThreshold: otherValues.rankMaxDemeritThreshold
              ? parseFloat(otherValues.rankMaxDemeritThreshold.toString())
              : undefined,
            rankMinMeritRatioThreshold: otherValues.rankMinMeritRatioThreshold
              ? parseFloat(otherValues.rankMinMeritRatioThreshold.toString())
              : undefined,
            rankMinMeritThreshold: otherValues.rankMinMeritThreshold
              ? parseFloat(otherValues.rankMinMeritThreshold.toString())
              : undefined,
            value: otherValues.value
              ? parseFloat(otherValues.value.toString())
              : undefined,
            contractId: contract.value,
            driverScoreTypeId: driverScoreType?.value,
            period: period?.label,
            uploadId: otherValues.documentStorageId,
            validFrom: moment(validFrom).toDate(),
            validTo: moment(validTo).toDate(),
          };

          if (driverRankIncentiveId) {
            await driverRankIncentiveApi.apiDriverRankIncentiveConfigDriverRankIncentiveConfigIdPatch(
              {
                driverRankIncentiveConfigId: driverRankIncentiveId,
                body: newValues,
              }
            );
          } else {
            await driverRankIncentiveApi.apiDriverRankIncentiveConfigPost({
              body: newValues,
            });
          }
          history.push('/driverrankincentivelist');
          notify(
            'success',
            `${driverRankIncentiveId ? 'Updated' : 'Added'} Incentive`
          );
        }
      } catch (e) {
        notify(
          'error',
          e.message ??
            `Failed to ${driverRankIncentiveId ? 'update' : 'add'} incentive`
        );
      } finally {
        formikHelpers.setSubmitting(false);
        setSubmitting(false);
      }
    },
    [history, notify, setSubmitting, driverRankIncentiveId]
  );

  const value: IncentiveContextProps = {
    // Template Table
    list,
    loadList,
    hasPermission,
    loading: loading || loadingSingleItem,
    cleanupList,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onAdd: hasPermission.add ? handleAdd : undefined,
    onDownload: hasPermission.download ? handleDownload : undefined,
    onNavigate: handleNavigate,
    onDelete: hasPermission.delete ? handleDelete : undefined,
    onRefresh: handleRefresh,
    // Forms
    onDetailsFormSubmit: handleDetailsFormSubmit,
    setDriverRankIncentiveId,
    driverScoreTypeListing,
    submitting,
    driverRankIncentive: driverRankIncentive,
    driverRankIncentiveId,
    detailsRef,
  };

  React.useEffect(() => {
    loadIncentive();
    loadListing();
    return () => {
      setDriverRankIncentive(undefined);
      setDriverScoreTypeListing(undefined);
    };
  }, [loadListing, loadIncentive]);

  return (
    <DriverRankIncentiveContext.Provider value={value}>
      {children}
    </DriverRankIncentiveContext.Provider>
  );
};
