import React from 'react';
import {useHistory} from 'react-router-dom';
import {
  ApiPermissionGetRequest,
  Permission,
  PermissionListResponse,
  PermissionPost,
} from '@onroadvantage/onroadvantage-api';
import {FormikHelpers, FormikProps} from 'formik/dist/types';
import {useAppNotifications} from '../../contexts';
import {permissionApi} from '../../api';
import {
  TemplateTableContextProps,
  TLoadList,
  useTemplateTable,
} from '../../factory/template';

export interface PermissionContextProps
  extends TemplateTableContextProps<Permission, PermissionListResponse> {
  onDetailsFormSubmit: (
    values: PermissionPost,
    formikHelpers: FormikHelpers<PermissionPost>
  ) => void;
  setPermissionId: (value: number | undefined) => void;
  submitting: boolean;
  permission?: Permission;
  permissionId?: number;
  detailsRef?: React.Ref<FormikProps<PermissionPost>>;
}

export const PermissionContext = React.createContext<PermissionContextProps>({
  // Template Table Defaults
  loading: false,
  list: [],
  currentPage: 1,
  // Permission
  loadList: async () => {},
  onDetailsFormSubmit: () => null,
  setPermissionId: () => null,
  submitting: false,
});

interface PermissionContextProviderProps {
  permissionId?: number;
}

export const PermissionContextProvider: React.FC<
  PermissionContextProviderProps
> = ({children}) => {
  const history = useHistory();
  const notify = useAppNotifications();
  // Template Table
  const [
    {
      // States
      currentPage,
      pageSize,
      pageTotal,
      sorting,
      hasPermission,
      itemTotal,
      list,
      loading,
      loadingSingleItem,
      filters,
    },
    {
      // Getters
      getDownloads,
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
      setLoadingSingleItem,
    },
  ] = useTemplateTable<Permission, ApiPermissionGetRequest>({
    editPermission: 'Edit Permission',
    addPermission: 'Add Permission',
    deletePermission: 'Delete Permission',
    downloadPermission: 'Permission ListDownload',
    viewPermission: 'Permission List',
  });

  const loadList = React.useCallback<TLoadList<PermissionListResponse>>(
    async (options) => {
      setLoading(true);
      try {
        const requestObj = getRequestObj(['name', 'description'], options);
        const response = await permissionApi.apiPermissionGet(requestObj);
        return getResponse(response, options);
      } catch (e) {
        notify('error', e.message ?? 'Failed to load permission list');
      } finally {
        setLoading(false);
      }
    },
    [setLoading, getRequestObj, getResponse, notify]
  );

  const handleDelete = React.useCallback(
    async (row: Permission) => {
      setLoading(true);
      try {
        if (row.id) {
          await permissionApi.apiPermissionPermissionIdDelete({
            permissionId: row.id,
          });
          await loadList();
          notify('success', 'Deleted permission');
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to delete');
      } finally {
        setLoading(false);
      }
    },
    [setLoading, loadList, notify]
  );
  const handleDownload = React.useCallback(
    () =>
      getDownloads('permissions', loadList, [
        {name: 'Permission Name', path: 'name'},
        {name: 'Description', path: 'description'},
        {name: 'Type', path: 'type'},
      ]),
    [getDownloads, loadList]
  );
  const handleAdd = React.useCallback(
    () => history.push('/permissionlist/add'),
    [history]
  );
  const handleNavigate = React.useCallback(
    (row) => history.push(`/permissionlist/${row.id}`),
    [history]
  );
  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  // Forms
  const [permission, setPermission] = React.useState<Permission | undefined>();
  const [permissionId, setPermissionId] = React.useState<number | undefined>();
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const detailsRef = React.useRef<FormikProps<PermissionPost>>(null);

  const loadPermission = React.useCallback(async () => {
    setLoadingSingleItem(true);
    try {
      if (permissionId) {
        const response = await permissionApi.apiPermissionPermissionIdGet({
          permissionId,
        });
        setPermission(response);
      } else {
        setPermission(undefined);
      }
    } catch (e) {
      notify('error', e.message ?? 'Failed to load permission');
    } finally {
      setLoadingSingleItem(false);
    }
  }, [notify, permissionId, setLoadingSingleItem]);

  const handleDetailsFormSubmit = React.useCallback(
    async (
      values: PermissionPost,
      formikHelpers: FormikHelpers<PermissionPost>
    ) => {
      setSubmitting(true);
      try {
        const {...otherValues} = values;
        const newValues: PermissionPost = {...otherValues};

        if (permissionId) {
          await permissionApi.apiPermissionPermissionIdPatch({
            permissionId,
            body: newValues,
          });
        } else {
          await permissionApi.apiPermissionPost({
            body: newValues,
          });
        }
        history.push('/permissionlist');
        notify('success', `${permissionId ? 'Updated' : 'Added'} Permission`);
      } catch (e) {
        notify(
          'error',
          e.message ?? `Failed to ${permissionId ? 'update' : 'add'} permission`
        );
      } finally {
        formikHelpers.setSubmitting(false);
        setSubmitting(false);
      }
    },
    [history, notify, setSubmitting, permissionId]
  );

  const value: PermissionContextProps = {
    // Template Table
    list,
    loadList,
    hasPermission,
    loading: loading || loadingSingleItem,
    grouping: [{columnName: 'type'}],
    cleanupList,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onAdd: hasPermission.add ? handleAdd : undefined,
    onDownload: hasPermission.download ? handleDownload : undefined,
    onNavigate: handleNavigate,
    onDelete: hasPermission.delete ? handleDelete : undefined,
    onRefresh: handleRefresh,
    // Forms
    onDetailsFormSubmit: handleDetailsFormSubmit,
    setPermissionId,
    submitting,
    permission,
    permissionId,
    detailsRef,
  };

  React.useEffect(() => {
    loadPermission();
    return () => setPermission(undefined);
  }, [loadPermission]);

  return (
    <PermissionContext.Provider value={value}>
      {children}
    </PermissionContext.Provider>
  );
};
