import React from 'react';
import {Stack, Typography} from '@mui/material';
import {splitByCapitals} from '../../../../factory/template';
import {useContractSetupSummaryDetailsStyles} from './ContractSetupSummaryDetails.style';
import {
  ContractSetupSummaryDetail,
  ContractSetupSummaryDetailProps,
} from './ContractSetupSummaryDetail';

export interface ContractSetupSummaryDetailsProps {
  details: {[key: string]: ContractSetupSummaryDetailProps['detail']};
}

export const ContractSetupSummaryDetails: React.FC<
  ContractSetupSummaryDetailsProps
> = ({details}) => {
  const classes = useContractSetupSummaryDetailsStyles();

  return (
    <Stack spacing={1}>
      {Object.keys(details).map((key) => (
        <Stack direction="row" spacing={1} key={key}>
          <Typography fontWeight={500} className={classes.label}>
            {splitByCapitals(key, {
              capabilitiesFirstLetter: true,
            })}
            :
          </Typography>
          <ContractSetupSummaryDetail detail={details[key]} />
        </Stack>
      ))}
    </Stack>
  );
};
