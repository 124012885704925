import React from 'react';
import {useLocation} from 'react-router-dom';
import {useTripContext} from '../../tripContext';
import {
  useTripEdit,
  useTripEditResponse,
  useTripEditResponseInitial,
} from './useTripEdit';
import {
  useTripEditSync,
  useTripEditSyncResponse,
  useTripEditSyncResponseInitial,
} from './useTripEditSync';

export type TripEditContextProps = useTripEditResponse &
  useTripEditSyncResponse;

export const TripEditContext = React.createContext<TripEditContextProps>({
  ...useTripEditResponseInitial,
  ...useTripEditSyncResponseInitial,
});

export const TripEditContextProvider: React.FC = ({children}) => {
  const {pathname, search} = useLocation();

  const {
    masterTrip,
    masterTripId,
    loadInitialData,
    setUpdatingTrip,
    updateMasterTrip,
  } = useTripContext();

  const tripEditValues = useTripEdit({
    allowDepotEdits: true,
    masterTrip,
    masterTripId,
    loadInitialData,
    setUpdatingTrip,
    updateMasterTrip,
  });

  const tripEditSyncValues = useTripEditSync({
    masterTripId,
    setStops: tripEditValues.setStops,
    getInitialStop: tripEditValues.getInitialStop,
    getStopActuals: tripEditValues.getStopActuals,
    getTripTimestampPriorities: tripEditValues.getTripTimestampPriorities,
    loadStops: tripEditValues.loadStops,
  });

  const {loadStops, cleanup} = tripEditValues;

  React.useEffect(() => {
    if (pathname === '/triplist') {
      /**
       * When the user navigates back to the trip list view, we need to reset all the previous trip's states,
       * otherwise it will keep the previous trip's state when you navigate into the new trip.
       */
      cleanup();
    }
  }, [cleanup, pathname]);

  React.useEffect(() => {
    /** Don't do initial load when on Sync Tab, otherwise the loaded actuals will be overwritten */
    if (masterTripId) {
      loadStops({syncActuals: search.includes('Sync')});
    }
    /** Only load the initialData on first render when it has a masterTripId */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterTripId, search]);

  return (
    <TripEditContext.Provider
      value={{...tripEditValues, ...tripEditSyncValues}}
    >
      {children}
    </TripEditContext.Provider>
  );
};

export const useTripEditContext = () => React.useContext(TripEditContext);
