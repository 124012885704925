import React from 'react';
import {SortableElement} from 'react-sortable-hoc';
import {
  TripSettingsViewLayoutItem,
  TripSettingsViewLayoutItemProps,
} from './TripSettingsViewLayoutItem';

export const TripSettingsViewLayoutSortableItem = SortableElement(
  (props: TripSettingsViewLayoutItemProps) => {
    return <TripSettingsViewLayoutItem {...props} isSortable />;
  }
);
