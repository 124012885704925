import React from 'react';
import {PlanningBoardTripContextProvider} from './planningBoardTripContext';
import {PlanningBoardTrip} from './PlanningBoardTrip';

export const PlanningBoardTripController: React.FC = () => {
  return (
    <PlanningBoardTripContextProvider>
      <PlanningBoardTrip />
    </PlanningBoardTripContextProvider>
  );
};
