import React from 'react';
import {ContractMediaEventDriverReasonCode} from '@onroadvantage/onroadvantage-api';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../../factory/template';
import {useContractContext} from '../contractContext';
import {
  ContractDriverEventReasonCodeListContext,
  useContractDriverEventReasonCodeListContext,
} from './ContractDriverEventReasonCodeListContext';

export const ContractDriverEventReasonCodeList: React.FC = () => {
  const {contract} = useContractContext();
  const {loadList, cleanupList} = useContractDriverEventReasonCodeListContext();

  const config: TemplateTableConfig<ContractMediaEventDriverReasonCode> =
    React.useMemo(
      () => ({
        columns: [
          {
            name: 'description',
            label: 'Description',
            type: 'string',
            enableEditing: true,
          },
        ],
        enableSort: true,
        enableFilter: true,
        enableGrouping: false,
        deleteMessage: (row) => ({
          title: 'Contract Driver Event Reason Code',
          items: {
            ContractCode: contract?.code,
            ContractName: contract?.name,
            Description: row.description,
          },
        }),
        identifier: 'CONTRACT_DRIVER_EVENT_REASON_CODE_LIST',
      }),
      [contract?.code, contract?.name]
    );

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [cleanupList, loadList]);

  return (
    <TemplateTableWrapper
      config={config}
      context={ContractDriverEventReasonCodeListContext}
    />
  );
};
