import {createStyles, makeStyles} from '@mui/styles';

const PlanningBoardToolbarSearchStyles = () =>
  createStyles({
    input: {
      display: 'flex',
      marginTop: 0,
      paddingBottom: 0,
      flex: 1,
    },
  });

export const usePlanningBoardToolbarSearchStyles = makeStyles(
  PlanningBoardToolbarSearchStyles,
  {name: 'PlanningBoardToolbarSearchStyles'}
);
