import React from 'react';
import {TrainingCourseUserForm} from './TrainingCourseUserForm';
import {
  ITemplateTabControllerTab,
  TemplateTabs,
} from '../../../factory/template';
import {TrainingCourseUserContext} from './TrainingCourseUserContext';

export const TrainingCourseUser: React.FC = () => {
  const {trainingCourseUserId, loading} = React.useContext(
    TrainingCourseUserContext
  );

  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {title: 'Details', element: <TrainingCourseUserForm />},
  ]);

  const path = React.useMemo(
    () => `/trainingcourselist/${trainingCourseUserId}`,
    [trainingCourseUserId]
  );

  return <TemplateTabs tabs={tabs} path={path} loading={loading} />;
};
