import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  DialogContentText,
  Button,
} from '@mui/material';
import {Link} from 'react-router-dom';

export const RecipientInvalid: React.FC = () => {
  return (
    <Dialog
      open={true}
      aria-labelledby="invalid-recipient"
      aria-describedby="uuid-provided-was-invalid"
      fullWidth
      maxWidth="xs"
      data-cy="RecipientInvalid"
    >
      <DialogTitle id="Page cannot be loaded">
        <Typography variant="h4">Page cannot be loaded</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="Invalid user ID">
          Invalid user ID
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button component={Link} to="/" color="primary">
          Take me home
        </Button>
      </DialogActions>
    </Dialog>
  );
};
