import {makeStyles, createStyles} from '@mui/styles';

const VehicleTypeCapacityCompartmentTruckActionsStyles = () =>
  createStyles({
    actions: {
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'flex-end',
      justifySelf: 'flex-end',
      marginTop: 'auto',
    },
  });

export const useVehicleTypeCapacityCompartmentTruckStyles = makeStyles(
  VehicleTypeCapacityCompartmentTruckActionsStyles,
  {name: 'VehicleTypeCapacityCompartmentTruckStyles'}
);
