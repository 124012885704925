import React from 'react';
import {Breadcrumbs, Paper} from '@mui/material';
import {NavigateNext} from '@mui/icons-material';
import {useBreadcrumbControllerStyles} from '../breadcrumbs/BreadcrumbController.style';
import {matchPath, useLocation} from 'react-router-dom';
import {BreadcrumbItem} from '../breadcrumbs/BreadcrumbItem';
import {BreadcrumbRoute} from '../breadcrumbs/BreadcrumbController';
import {useContractCriticalEventTypeConfigContext} from './contractCriticalEventTypeConfig/ContractCriticalEventTypeConfigContext';
import _ from 'lodash';
import {useContractOperationalEventTypeConfigContext} from './contractOperationalEventTypeConfig/ContractOperationalEventTypeConfigContext';

export const ContractEventTypeConfigsBreadcrumbs = () => {
  const classes = useBreadcrumbControllerStyles();
  const location = useLocation();
  const {loading: loadingCritical, contractCriticalEventTypeConfig} =
    useContractCriticalEventTypeConfigContext();
  const {loading: loadingOperational, contractOperationalEventTypeConfig} =
    useContractOperationalEventTypeConfigContext();

  const contractEventTypeConfigRoutes: BreadcrumbRoute[] = React.useMemo(
    () => [
      {
        path: '/contracteventtypeconfigs/critical',
        title: 'Contract Critical Event Type Configs',
      },
      {
        path: '/contracteventtypeconfigs/critical/add',
        title: 'New Contract Critical Event Type Config',
      },
      {
        path: '/contracteventtypeconfigs/critical/:contractCriticalEventTypeConfigId',
        title: (params) =>
          loadingCritical
            ? 'Contract Critical Event Type Config Loading...'
            : contractCriticalEventTypeConfig?.criticalEventType?.name &&
              contractCriticalEventTypeConfig?.contract?.code
            ? `${contractCriticalEventTypeConfig.criticalEventType?.name} -
              ${contractCriticalEventTypeConfig.contract?.code}`
            : `Contract Critical Event Type Config ${_.get(
                params,
                'contractCriticalEventTypeConfigId',
                'Single'
              )}`,
      },
      {
        path: '/contracteventtypeconfigs/operational',
        title: 'Contract Operational Event Type Configs',
      },
      {
        path: '/contracteventtypeconfigs/operational/add',
        title: 'New Contract Operational Event Type Config',
      },
      {
        path: '/contracteventtypeconfigs/operational/:contractOperationalEventTypeConfigId',
        title: (params) =>
          loadingOperational
            ? 'Contract Operational Event Type Config Loading...'
            : contractOperationalEventTypeConfig?.operationalEventType?.name &&
              contractOperationalEventTypeConfig?.contract?.code
            ? `${contractOperationalEventTypeConfig.operationalEventType?.name} -
              ${contractOperationalEventTypeConfig.contract?.code}`
            : `Contract Operational Event Type Config ${_.get(
                params,
                'contractOperationalEventTypeConfigId',
                'Single'
              )}`,
      },
    ],
    [
      contractCriticalEventTypeConfig?.contract?.code,
      contractCriticalEventTypeConfig?.criticalEventType?.name,
      contractOperationalEventTypeConfig?.contract?.code,
      contractOperationalEventTypeConfig?.operationalEventType?.name,
      loadingCritical,
      loadingOperational,
    ]
  );

  const getBreadcrumbItems = React.useCallback(() => {
    const items: React.ReactNode[] = [];

    location.pathname
      .replace(/\$/, '')
      .split('/')
      .reduce((previous, current) => {
        const pathSection = `${previous}/${current}`;

        const match = contractEventTypeConfigRoutes.find(({path}) => {
          return matchPath(pathSection, {exact: true, path});
        });
        if (match != null) {
          const {path} = match;
          const routePath = matchPath(pathSection, {exact: true, path});
          items.push(
            <BreadcrumbItem
              params={routePath?.params}
              route={match}
              url={routePath?.url}
              key={routePath?.url}
            />
          );
        }
        return pathSection;
      });

    return items;
  }, [contractEventTypeConfigRoutes, location.pathname]);

  return (
    <Paper className={classes.breadcrumbs}>
      <Breadcrumbs separator={<NavigateNext fontSize="small" />}>
        {getBreadcrumbItems()}
      </Breadcrumbs>
    </Paper>
  );
};
