import React from 'react';
import {WebEvent} from '@onroadvantage/onroadvantage-api';
import {Button, ButtonGroup, Menu, MenuItem} from '@mui/material';
import {ArrowDropDown} from '@mui/icons-material';
import {EventsPaneContext} from '../EventsPaneContext';
import {IVantageDialogRef} from '../../dialog';
import {
  EventsPaneCardMuteEventDialog,
  IEventsPaneCardMuteEventDialogForm,
} from './EventsPaneCardMuteEventDialog';
import {TOnFormikSubmit} from '../../../factory/template';
import {
  criticalEventApi,
  mutedCriticalEventTypeApi,
  mutedOperationalEventTypeApi,
  operationalEventApi,
} from '../../../api';
import {useAppNotifications} from '../../../contexts';
import {RoleService} from '../../../service';

interface EventsPaneCardActionsProps {
  event: WebEvent;
  onCloseEvent: (
    e: React.MouseEvent<
      HTMLLIElement | HTMLButtonElement | HTMLAnchorElement,
      MouseEvent
    >
  ) => void;
  closeEventStatus: string;
  disableCloseEvent: boolean;
  disabled: boolean;
}

export const EventsPaneCardActions: React.FC<EventsPaneCardActionsProps> = ({
  event,
  onCloseEvent,
  closeEventStatus,
  disableCloseEvent,
  disabled,
}) => {
  const notify = useAppNotifications();
  const {onSearchVehicleEventHistory, reloadEvents} =
    React.useContext(EventsPaneContext);
  const [open, setOpen] = React.useState<boolean>(false);
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const muteEventRef = React.useRef<IVantageDialogRef>(null);

  const handleSearchVehicleEventHistory = React.useCallback(() => {
    onSearchVehicleEventHistory(event);
    setOpen(false);
  }, [event, onSearchVehicleEventHistory]);

  const handleOpenMuteEventDialog = React.useCallback(() => {
    muteEventRef.current?.openDialog();
    setOpen(false);
  }, []);

  const handleToggle = React.useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const handleCloseEvent = React.useCallback(
    (event) => {
      onCloseEvent(event);
      setOpen(false);
    },
    [onCloseEvent]
  );

  const handleCancelMuteEvent = React.useCallback(() => {
    setOpen(false);
    muteEventRef.current?.closeDialog();
  }, []);

  const handleSubmitMuteEvent = React.useCallback<
    TOnFormikSubmit<IEventsPaneCardMuteEventDialogForm>
  >(
    async (values, formikHelpers) => {
      setSubmitting(true);
      try {
        if (event.id && event.eventType?.id && event.vehicle?.id) {
          if (event.metaData?.model === 'OperationalEvent') {
            await mutedOperationalEventTypeApi.apiMutedOperationalEventTypePost(
              {
                body: {
                  operationalEventTypeId: event.eventType?.id,
                  duration: values.period,
                  vehicleId: event.vehicle?.id,
                },
              }
            );
            await operationalEventApi.apiOperationalEventOperationalEventIdPost(
              {
                body: {
                  activities: [
                    {action: 'Comment', value: values.comment},
                    {action: 'StatusChange', value: 'MutedClosed'},
                  ],
                },
                operationalEventId: event.id,
              }
            );
          } else {
            await mutedCriticalEventTypeApi.apiMutedCriticalEventTypePost({
              body: {
                criticalEventTypeId: event.eventType?.id,
                duration: values.period,
                vehicleId: event.vehicle?.id,
              },
            });
            await criticalEventApi.apiCriticalEventCriticalEventIdPost({
              body: {
                activities: [
                  {action: 'Comment', value: values.comment},
                  {action: 'StatusChange', value: 'MutedClosed'},
                ],
              },
              criticalEventId: event.id,
            });
          }
          notify('success', 'Muted Event');
        }
      } catch (e) {
        notify('error', 'Failed to mute event');
      } finally {
        formikHelpers.setSubmitting(false);
        setSubmitting(false);
        setOpen(false);
        muteEventRef.current?.closeDialog();
        await reloadEvents();
      }
    },
    [event, notify, reloadEvents]
  );

  return (
    <>
      <EventsPaneCardMuteEventDialog
        onMuteEvent={handleSubmitMuteEvent}
        onCancel={handleCancelMuteEvent}
        submitting={submitting}
        ref={muteEventRef}
      />
      <ButtonGroup
        color="primary"
        size="small"
        variant="outlined"
        aria-label="events pane card actinos split button"
        disabled={disabled}
        ref={anchorRef}
      >
        <Button onClick={handleSearchVehicleEventHistory}>
          View Vehicle History
        </Button>
        <Button size="small" onClick={handleToggle}>
          <ArrowDropDown />
        </Button>
      </ButtonGroup>
      <Menu
        id="events-pane-card-actions-menu"
        anchorEl={anchorRef.current}
        open={open}
        onClose={handleClose}
        anchorOrigin={{horizontal: 15, vertical: 30}}
      >
        <MenuItem onClick={handleSearchVehicleEventHistory}>
          View Vehicle History
        </MenuItem>
        <MenuItem
          onClick={handleOpenMuteEventDialog}
          disabled={
            !RoleService.hasPermission(
              event.metaData?.model === 'OperationalEvent'
                ? `Add MutedOperationalEventType`
                : 'Add MutedCriticalEventType',
              'Add'
            )
          }
        >
          Mute Event
        </MenuItem>
        <MenuItem onClick={handleCloseEvent} disabled={disableCloseEvent}>
          {closeEventStatus}
        </MenuItem>
      </Menu>
    </>
  );
};
