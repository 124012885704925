import React from 'react';
import {useInternalTableContext} from '../../TemplateTable';
import {TableRowDetail} from '@devexpress/dx-react-grid-material-ui';
import {useTableRowDetailCellStyles} from './TableRowDetailCell.style';
import clsx from 'clsx';

export const TableRowDetailCell: React.FC<TableRowDetail.CellProps> = (
  props
) => {
  const defaultClasses = useTableRowDetailCellStyles();
  const {classes, nestedLevel} = useInternalTableContext();
  return (
    <TableRowDetail.Cell
      {...props}
      className={
        classes?.rowDetail ??
        clsx({
          [defaultClasses.root]: nestedLevel === 0,
          [defaultClasses.rootLevel1]: nestedLevel === 1,
          [defaultClasses.rootLevel2]: nestedLevel === 2,
        })
      }
    >
      {props.children}
    </TableRowDetail.Cell>
  );
};
