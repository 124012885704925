import React from 'react';
import {
  BreadcrumbController,
  BreadcrumbRoute,
} from '../breadcrumbs/BreadcrumbController';
import {useUserTrainingCourseControllerStyles} from './UserTrainingCourseController.style';
import {UserTrainingCourseContextProvider} from './UserTrainingCourseContext';
import {Route} from 'react-router-dom';
import {UserTrainingCourseList} from './UserTrainingCourseList';

export const UserTrainingCourseControllerComponent: React.FC = () => {
  const classes = useUserTrainingCourseControllerStyles();
  const routes: BreadcrumbRoute[] = [];

  return (
    <div className={classes.root}>
      <BreadcrumbController routes={routes} />
      <div className={classes.container}>
        <Route
          exact
          path="/usertrainingcourselist"
          component={UserTrainingCourseList}
        />
      </div>
    </div>
  );
};

export const UserTrainingCourseController: React.FC = ({children}) => (
  <UserTrainingCourseContextProvider>
    <UserTrainingCourseControllerComponent>
      {children}
    </UserTrainingCourseControllerComponent>
  </UserTrainingCourseContextProvider>
);
