import React from 'react';
import Chip from '@mui/material/Chip';
import {
  IPlanningBoardGroupOptionItem,
  PlanningBoardGroupOptionContext,
  TPlanningBoardGroupOptionsVariants,
} from './PlanningBoardGroupOptionsContext';
import {usePlanningBoardGroupOptionsStyles} from './PlanningBoardGroupOptions.style';
import clsx from 'clsx';

export interface PlanningBoardGroupOptionsItemProps {
  variant: TPlanningBoardGroupOptionsVariants;
  item: IPlanningBoardGroupOptionItem;
}

export const PlanningBoardGroupOptionsItem: React.FC<
  PlanningBoardGroupOptionsItemProps
> = ({variant, item}) => {
  const classes = usePlanningBoardGroupOptionsStyles();
  const {draggingItem, overItem, onDragStart, onDragOver, onDragLeave, onDrop} =
    React.useContext(PlanningBoardGroupOptionContext);

  const isDragging = React.useMemo(
    () => draggingItem === item,
    [draggingItem, item]
  );

  const isOver = React.useMemo(() => overItem === item, [overItem, item]);

  return (
    <Chip
      key={item.key}
      label={item.label}
      size={variant === 'selected' ? 'medium' : 'small'}
      color={variant === 'unselected' || isOver ? 'default' : 'primary'}
      onDragStart={onDragStart(variant, item)}
      onDragOver={onDragOver(variant, item)}
      onDragLeave={onDragLeave(variant, item)}
      onDrop={onDrop(variant, item)}
      className={clsx(classes.chip, {
        [classes.chipIsDragging]: isDragging,
        [classes.chipIsOver]: isOver,
      })}
      draggable
    />
  );
};
