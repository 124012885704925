import {makeStyles, createStyles} from '@mui/styles';

const TableStubCellStyles = () =>
  createStyles({
    root: {
      padding: 0,
    },
  });

export const useTableStubCellStyles = makeStyles(TableStubCellStyles, {
  name: 'TableStubCellStyles',
});
