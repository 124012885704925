import React from 'react';
import {MasterRouteLegContext} from './MasterRouteLegContext';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../../factory/template';
import {MasterRouteLeg} from '@onroadvantage/onroadvantage-api';

export const MasterRouteLegList: React.FC = () => {
  const {loadList, cleanupList} = React.useContext(MasterRouteLegContext);

  const [config] = React.useState<TemplateTableConfig<MasterRouteLeg>>({
    columns: [
      {
        name: 'fromNode.name' as 'fromNode',
        label: 'From Site',
        type: 'string',
        getValue: ({fromNode}) => fromNode?.name,
      },
      {
        name: 'toNode.name' as 'toNode',
        label: 'To Site',
        type: 'string',
        getValue: ({toNode}) => toNode?.name,
      },
      {
        name: 'customer.name' as 'customer',
        label: 'Customer',
        type: 'string',
        getValue: ({customer}) => customer?.name,
      },
      {name: 'distance', label: 'Distance', type: 'number'},
      {
        name: 'duration',
        label: 'Duration',
        type: 'number',
        getValue: ({duration}) => {
          if (duration) {
            const totalHours = duration / 60;
            const hours = Math.floor(totalHours).toString();
            const totalMinutes = (totalHours % 1) * 60;
            const minutes = Math.floor(totalMinutes).toString();
            const seconds = Math.floor((totalMinutes % 1) * 60).toString();
            return `${hours.padStart(2, '0')}:${minutes.padStart(
              2,
              '0'
            )}:${seconds.padStart(2, '0')}`;
          }
          return '-';
        },
      },
    ],
    disablePagination: true,
    disableToolbar: true,
    deleteMessage: (row) => ({
      title: 'Master Route Leg',
      items: {
        Customer: row.customer?.name,
        FromSite: row.fromNode?.name,
        ToSite: row.toNode?.name,
      },
    }),
    identifier: 'MASTER_ROUTE_LEG_LIST',
  });

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [cleanupList, loadList]);

  return (
    <TemplateTableWrapper config={config} context={MasterRouteLegContext} />
  );
};
