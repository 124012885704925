import React from 'react';
import {UserContext} from './UserContext';
import {TemplateTableConfig, TemplateTable} from '../../factory/template';
import {CustomerUser} from '@onroadvantage/onroadvantage-api';

export const UserCustomers: React.FC = () => {
  const {
    user,
    loadingSingleItem,
    submitting,
    assignCustomers,
    unassignCustomer,
    hasPermission,
  } = React.useContext(UserContext);

  const config: TemplateTableConfig<CustomerUser> = React.useMemo(
    () => ({
      columns: [
        {
          name: 'name',
          label: 'Name',
          type: 'string',
          enableEditing: true,
          enableAutocomplete: true,
          enableMulti: true,
          model: 'Customer',
        },
      ],
      enableFilter: true,
      enableSort: true,
      deleteMessage: (row) => ({
        title: 'User Customer',
        items: {User: user?.email, Name: row.name},
      }),
      identifier: 'USER_CUSTOMER_LIST',
    }),
    [user?.email]
  );

  return (
    <TemplateTable
      config={config}
      list={user?.customers ?? []}
      loading={loadingSingleItem || submitting}
      currentPage={1}
      onInlineAdd={hasPermission?.edit ? assignCustomers : undefined}
      onDelete={hasPermission?.edit ? unassignCustomer : undefined}
    />
  );
};
