import React from 'react';
import {TableHeaderRow} from '@devexpress/dx-react-grid-material-ui';
import {useTableHeaderRowCellStyles} from './TableHeaderRowCell.style';

export const TableHeaderRowCell: React.FC<TableHeaderRow.CellProps> = ({
  children,
  ...props
}) => {
  const classes = useTableHeaderRowCellStyles();
  return (
    <TableHeaderRow.Cell {...props} className={classes.root}>
      {children}
    </TableHeaderRow.Cell>
  );
};
