import React from 'react';
import {Event} from './Event';
import {useEventControllerStyles} from './EventController.style';
import {EventContext, EventContextProvider} from './EventContext';
import {useParams} from 'react-router-dom';
import {Loader} from '../loader';

const EventControllerComponent: React.FC = () => {
  const classes = useEventControllerStyles();
  const {loading} = React.useContext(EventContext);

  if (loading) return <Loader text="Loading event..." />;

  return (
    <div className={classes.root}>
      <main className={classes.mainContent}>
        <Event />
      </main>
    </div>
  );
};

interface EventParams {
  type: string;
  id: string;
}

export const EventController: React.FC = () => {
  const {id, type} = useParams<EventParams>();
  return (
    <EventContextProvider id={id} type={type}>
      <EventControllerComponent />
    </EventContextProvider>
  );
};
