import React from 'react';
import {VehicleContext, VehicleContextProps} from './VehicleContext';
import {useParams} from 'react-router-dom';

interface IParams {
  vehicleId?: string;
}

export const VehicleIdHandler: React.FC = () => {
  const vehicleContext = React.useContext<VehicleContextProps>(VehicleContext);

  const vehicleSetId = vehicleContext.setVehicleId;

  const {vehicleId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = vehicleId ? parseInt(vehicleId) : undefined;
    vehicleSetId(parsedId);

    return () => {
      vehicleSetId(undefined);
    };
  }, [vehicleId, vehicleSetId]);

  return null;
};
