import React from 'react';
import {WorkflowDetailsContext} from './WorkflowDetailsContext';
import {Loader} from '../../loader';
import {Stack} from '@mui/material';
import {WorkflowDetailsTaskList} from './workflowDetailsTask';

export const WorkflowDetails: React.FC = () => {
  const {loadingDetails} = React.useContext(WorkflowDetailsContext);

  if (loadingDetails) {
    return <Loader text="Loading Workflow Details..." />;
  }

  return (
    <Stack>
      <WorkflowDetailsTaskList />
    </Stack>
  );
};
