import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

const PasswordResetSuccessStyles = (theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(1),
      },
      [theme.breakpoints.between('sm', 'lg')]: {
        paddingTop: theme.spacing(2),
      },
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(5),
      },
    },
    icon: {
      [theme.breakpoints.down('md')]: {
        fontSize: 30,
      },
      [theme.breakpoints.between('sm', 'lg')]: {
        fontSize: 50,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 80,
      },
      alignSelf: 'center',
    },
    title: {
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(3),
      },
      [theme.breakpoints.between('sm', 'lg')]: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4),
      },
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4),
      },
    },
    button: {
      marginTop: theme.spacing(1.5),
    },
  });

export const usePasswordResetSuccessStyles = makeStyles(
  PasswordResetSuccessStyles,
  {
    name: 'PasswordResetSuccessStyles',
  }
);
