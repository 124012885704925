import React from 'react';
import {MobileDateTimePicker} from '@mui/x-date-pickers';
import {EditTripStop} from '@onroadvantage/onroadvantage-api';
import {useTripContext} from '../../tripContext';
import {tripApi} from '../../../../api';
import {useAppNotifications} from '../../../../contexts';
import {RoleService} from '../../../../service';
import {TripSummaryEditDialog} from './TripSummaryEditDialog';
import {getStopEditPayload} from '../../helpers';
import {FormikPickerField} from '../../../formik/formikPickers/FormikPickerField';

export const TripSummaryEditPlannedDeparture: React.FC = () => {
  const notify = useAppNotifications();
  const {masterTrip, loadInitialData} = useTripContext();
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [plannedDepartureDateTime, setPlannedDepartureDateTime] =
    React.useState<Date | null>(null);

  const handleSubmit = React.useCallback(async () => {
    setSubmitting(true);
    try {
      const stops = masterTrip?.trip.stops ?? [];
      const tripId = masterTrip?.trip.id;
      if (tripId && stops.length > 0) {
        const response = await tripApi.apiMasterTripEditPost({
          body: {
            id: tripId,
            /**
             * First ensure the stops are in the correct sequence and have a valid stopEditPayload.
             * Then we can just pass the plannedDepartureDateTime to the getStopEditPayload.
             * We don't have to enforce that it only updates on the first stop here, as we already have a check to only
             * be able to update the first stop's departure time in the getStopEditPayload.
             */
            stops: stops
              .map((stop, index) => ({
                ...stop,
                sequence: stop.sequence ?? index + 1,
              }))
              .sort((a, b) => a.sequence - b.sequence)
              .filter((stop) => !!getStopEditPayload(stop))
              .map(
                (stop) =>
                  getStopEditPayload({
                    ...stop,
                    departureTime: plannedDepartureDateTime,
                  }) as EditTripStop
              ),
          },
        });
        if (response.id) {
          notify('success', 'Planned Departure Updated');
          await loadInitialData({reload: true});
        }
      }
    } catch (e) {
      notify('error', 'Failed to update planned departure');
    } finally {
      setSubmitting(false);
    }
  }, [
    loadInitialData,
    masterTrip?.trip.id,
    masterTrip?.trip.stops,
    notify,
    plannedDepartureDateTime,
  ]);

  React.useEffect(() => {
    const stops = masterTrip?.trip.stops ?? [];
    const firstStop = stops[0] ?? undefined;
    if (firstStop.departureTime) {
      setPlannedDepartureDateTime(new Date(firstStop.departureTime));
    }
    return () => setPlannedDepartureDateTime(null);
  }, [masterTrip?.trip.stops]);

  return (
    <TripSummaryEditDialog
      title="Planned Departure"
      hasPermission={RoleService.hasPermission('Edit MasterTrip', 'Edit')}
      loading={submitting}
      onAccept={handleSubmit}
    >
      <MobileDateTimePicker
        ampm={false}
        disabled={submitting}
        onChange={setPlannedDepartureDateTime}
        value={plannedDepartureDateTime}
        renderInput={(props) => (
          <FormikPickerField
            {...props}
            name="plannedDepartureDateTime"
            displayError={undefined}
            loading={submitting}
          />
        )}
      />
    </TripSummaryEditDialog>
  );
};
