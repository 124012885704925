import React from 'react';
import {
  NUMBER_ERROR_MESSAGE,
  TemplateCard,
  TemplateForm,
} from '../../factory/template';
import {UserContext} from './UserContext';
import {User} from '@onroadvantage/onroadvantage-api';
import * as Yup from 'yup';
import {FormikTextField} from '../formik';

export interface IUserForm {
  id?: number;
  email?: string;
  password?: string;
}

const getInitialValues = (user?: User | null): IUserForm | undefined => {
  if (user) {
    return {
      id: user.id,
      email: user.email ?? '',
      password: '',
    };
  }
  return undefined;
};

export const UserForm: React.FC<{isAdd?: boolean}> = ({isAdd}) => {
  const {detailsRef, loading, submitting, user, onDetailsFormSubmit} =
    React.useContext(UserContext);
  const [initialValues, setInitialValues] = React.useState<IUserForm>();

  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  const emailErrorMessage = 'Invalid email address';
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@$!%*?&])[A-Za-z\d#$@$!%*?&]{8,}/;
  const passwordErrorMessage =
    'Password needs at least one uppercase, one lowercase, one number, one special character and 8 characters total';

  const schema: Yup.SchemaOf<IUserForm> = Yup.object({
    id: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
    email: isAdd
      ? Yup.string().required('Required').matches(emailRegex, emailErrorMessage)
      : Yup.string().matches(emailRegex, emailErrorMessage),
    password: isAdd
      ? Yup.string()
          .required('Required')
          .matches(passwordRegex, passwordErrorMessage)
      : Yup.string().matches(passwordRegex, passwordErrorMessage),
  });

  React.useEffect(() => {
    const values = getInitialValues(user);

    if (values) {
      setInitialValues(values);
    } else if (isAdd) {
      setInitialValues({
        email: '',
        password: '',
      });
    }
  }, [isAdd, user, setInitialValues]);

  return (
    <TemplateCard
      title={`User Details ${isAdd ? 'Add' : 'Edit'}`}
      loading={loading}
    >
      <TemplateForm<IUserForm>
        initialValues={initialValues}
        onSubmit={onDetailsFormSubmit}
        permission={{
          name: `${isAdd ? 'Add' : 'Edit'} User`,
          type: isAdd ? 'Add' : 'Edit',
        }}
        submitting={submitting}
        innerRef={detailsRef}
        validationSchema={schema}
      >
        {!isAdd && <FormikTextField name="id" label="Id" disabled fullWidth />}
        <FormikTextField
          name="email"
          placeholder="Enter the user email"
          label="Email"
          fullWidth
        />
        <FormikTextField
          name="password"
          placeholder="Enter the user password"
          label="Password"
          password
          fullWidth
        />
      </TemplateForm>
    </TemplateCard>
  );
};
