import React from 'react';
import {Link} from 'react-router-dom';
import {OrderDump, WebMasterTripStop} from '@onroadvantage/onroadvantage-api';
import {TemplateTable, TemplateTableConfig} from '../../../../factory/template';
import {useTripStopContext} from '../tripStopContext';
import {TripStopOrderLineList} from './TripStopOrderLineList';
import {useTripStopOrderListStyles} from './TripStopOrderList.style';

export const TripStopOrderList: React.FC = () => {
  const classes = useTripStopOrderListStyles();
  const {loadingTripStop, tripStop, onRefresh} = useTripStopContext();
  /** Destructure tripStop, need to add || ({} as WebMasterTripStop) for typescript, since tripStop is nullable. */
  const {orders} = tripStop || ({} as WebMasterTripStop);

  const [config] = React.useState<TemplateTableConfig<OrderDump>>({
    columns: [
      {name: 'orderNumber', label: 'Order Number', type: 'string'},
      {
        name: 'offloadPointName',
        label: 'Offload Point',
        type: 'string',
        getValue: ({offloadPoint}) => offloadPoint?.name,
      },
      {
        name: 'upliftPointName',
        label: 'Uplift Point',
        type: 'string',
        getValue: ({upliftPoint}) => upliftPoint?.name,
      },
      {
        name: 'uuid',
        label: 'Recipient Link',
        type: 'string',
        getValue: ({uuid}) =>
          uuid ? (
            <Link
              to={{pathname: `/recipient/${uuid}`}}
              target="_blank"
              className={classes.recipientLink}
            >
              {uuid}
            </Link>
          ) : null,
      },
      {name: 'deliverByDateTime', label: 'Deliver By', type: 'datetime'},
      {name: 'status', label: 'Status', type: 'string'},
    ],
    disablePagination: true,
    enableGrouping: false,
    identifier: 'TRIP_STOP_ORDER_LIST',
  });

  const listing = React.useMemo(() => orders ?? [], [orders]);

  return (
    <TemplateTable
      rowDetail={TripStopOrderLineList}
      config={config}
      currentPage={1}
      elevation={0}
      list={listing}
      loading={loadingTripStop}
      onRefresh={onRefresh}
      nestedLevel={1}
    />
  );
};
