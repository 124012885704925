import React from 'react';
import {Paper} from '@mui/material';
import {TemplateTable, TemplateTableConfig} from '../../factory/template';
import {useEventsBulkClosingContext} from './EventsBulkClosingContext';
import {WebEvent} from '@onroadvantage/onroadvantage-api';

export const EventsBulkClosingConfirmStep: React.FC = () => {
  //const classes = usePlanningBoardCommitBookingMasterTripsStyles();
  const {selectedEvents, eventsList, setSelectedEvents, localLoading} =
    useEventsBulkClosingContext();

  const config = React.useMemo<TemplateTableConfig<WebEvent>>(
    () => ({
      columns: [
        {
          name: 'model',
          label: 'Model',
          type: 'string',
          getValue: (row) => row.metaData?.model,
        },
        {
          name: 'eventType',
          label: 'Event Type',
          type: 'string',
          getValue: (row) => row.eventType?.name,
        },
        {
          name: 'eventDate',
          label: 'Event Date',
          type: 'datetime',
        },
        {
          name: 'vehicle',
          label: 'Vehicle',
          type: 'string',
          getValue: (row) => row.vehicle?.registrationNumber, //ask travis reg or fleet
        },
        {
          name: 'Driver',
          label: 'Driver',
          type: 'string',
          getValue: (row) => row.driver?.firstname,
        },
        {
          name: 'contract',
          label: 'Contract',
          type: 'string',
          getValue: (row) => row.vehicle?.contractCode,
        },
        {
          name: 'tripNumber',
          label: 'Trip Number',
          type: 'string',
          getValue: (row) => row.masterTrip?.tripNumber,
        },
      ],
      disableToolbar: true,
      disablePagination: true,
      identifier: 'EVENTS_BULK_CLOSING_CONFIRM_STEP',
    }),
    []
  );

  return (
    <Paper elevation={0}>
      <TemplateTable
        loading={localLoading}
        config={config}
        currentPage={1}
        list={eventsList ?? []}
        elevation={0}
        selection={selectedEvents ?? []}
        onSelectionChange={setSelectedEvents}
        scrollable
      />
    </Paper>
  );
};
