import React from 'react';
import {
  ITemplateTabControllerTab,
  TemplateTabs,
} from '../../../factory/template';
import {NotificationConfigContext} from '../NotificationConfigContext';
import {NotificationConfigCriticalEscalationContext} from './NotificationConfigCriticalEscalationContext';
import {NotificationConfigCriticalEscalationContractContacts} from './NotificationConfigCriticalEscalationContractContacts';
import {NotificationConfigCriticalEscalationCustomerContacts} from './NotificationConfigCriticalEscalationCustomerContacts';
import {NotificationConfigCriticalEscalationNodeContacts} from './NotificationConfigCriticalEscalationNodeContacts';
import {NotificationConfigCriticalEscalationVehicleGroupContacts} from './NotificationConfigCriticalEscalationVehicleGroupContacts';

export const NotificationConfigCriticalEscalation: React.FC = () => {
  const {notificationConfigId} = React.useContext(NotificationConfigContext);
  const {notificationConfigEventEscalationId, loading} = React.useContext(
    NotificationConfigCriticalEscalationContext
  );

  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {
      title: 'Contract Contact',
      element: <NotificationConfigCriticalEscalationContractContacts />,
    },
    {
      title: 'Customer Contact',
      element: <NotificationConfigCriticalEscalationCustomerContacts />,
    },
    {
      title: 'Node Contact',
      element: <NotificationConfigCriticalEscalationNodeContacts />,
    },
    {
      title: 'Vehicle Group Contact',
      element: <NotificationConfigCriticalEscalationVehicleGroupContacts />,
    },
  ]);

  const path = React.useMemo(
    () =>
      `/notificationconfiglist/${notificationConfigId}/event/${notificationConfigEventEscalationId}`,
    [notificationConfigId, notificationConfigEventEscalationId]
  );

  return <TemplateTabs tabs={tabs} path={path} loading={loading} />;
};
