import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Icon,
  IconButton,
  Typography,
} from '@mui/material';
import {Close, ExpandMore} from '@mui/icons-material';
import {
  WebPlanningBoardOrder,
  WebPlanningBoardTripStopOrder,
} from '@onroadvantage/onroadvantage-api';
import {usePlanningBoardTripOrdersStyles} from '../PlanningBoardTripOrders.style';
import {usePlanningBoardTripContext} from '../../planningBoardTripContext';
import {PlanningBoardTripOrderDetails} from './PlanningBoardTripOrderDetails';
import {useFormikContext} from 'formik';
import {IPlanningBoardTripPanelForm} from '../../planningBoardTripForm';

interface PlanningBoardTripOrderProps {
  order: WebPlanningBoardOrder | WebPlanningBoardTripStopOrder;
  expandedByDefault?: boolean;
}

export const PlanningBoardTripOrder: React.FC<PlanningBoardTripOrderProps> = ({
  order,
  expandedByDefault,
}) => {
  const {values} = useFormikContext<IPlanningBoardTripPanelForm>();
  const classes = usePlanningBoardTripOrdersStyles();
  const {loadTripPreview, onRemoveOrder, addedOrders} =
    usePlanningBoardTripContext();

  const handleRemoveOrder = React.useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      const newOrders = onRemoveOrder(order);
      await loadTripPreview(values, newOrders);
    },
    [loadTripPreview, onRemoveOrder, order, values]
  );

  if (!order) {
    return null;
  }

  return (
    <Accordion defaultExpanded={expandedByDefault}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        classes={{content: classes.accordionSummaryContent}}
      >
        {addedOrders.map(({id}) => id).includes(order.id) ? (
          <IconButton
            onClick={handleRemoveOrder}
            size="small"
            className={classes.accordionSummaryContentRemove}
          >
            <Close fontSize="small" />
          </IconButton>
        ) : addedOrders.length > 0 ? (
          <IconButton
            size="small"
            className={classes.accordionSummaryContentRemove}
            disabled
          >
            <Icon fontSize="small" />
          </IconButton>
        ) : null}
        <Typography fontWeight={600}>{order.orderNumber}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <PlanningBoardTripOrderDetails order={order} />
      </AccordionDetails>
    </Accordion>
  );
};
