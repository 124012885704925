import React from 'react';
import {useFormikContext} from 'formik';
import {Alert, Button, Stack, Typography} from '@mui/material';
import {usePlanningBoardTripContext} from './planningBoardTripContext';
import {IPlanningBoardTripPanelForm} from './planningBoardTripForm';

interface PlanningBoardTripSubmitWithErrorsProps {
  onCloseSubmitWithErrorsDialog: () => void;
}

export const PlanningBoardTripSubmitWithErrors: React.FC<
  PlanningBoardTripSubmitWithErrorsProps
> = ({onCloseSubmitWithErrorsDialog}) => {
  const {submitForm} = useFormikContext<IPlanningBoardTripPanelForm>();
  const {constraintErrors} = usePlanningBoardTripContext();

  const handleSubmitWithErrors = React.useCallback(async () => {
    onCloseSubmitWithErrorsDialog();
    await submitForm();
  }, [onCloseSubmitWithErrorsDialog, submitForm]);

  return (
    <Stack gap={2}>
      <Typography>
        Are you sure you want to create the trip with the following errors?
      </Typography>
      <Stack>
        {constraintErrors?.map(({error}) => (
          <Alert key={error} severity="error">
            {error}
          </Alert>
        ))}
      </Stack>
      <Stack direction="row">
        <Button variant="contained" onClick={handleSubmitWithErrors} fullWidth>
          Yes
        </Button>
        <Button onClick={onCloseSubmitWithErrorsDialog} fullWidth>
          No
        </Button>
      </Stack>
    </Stack>
  );
};
