import React from 'react';
import {
  TripDebriefSummaryCard,
  TripDebriefSummaryCardList,
} from './tripDebriefSummaryCard';
import {TripDebriefContext} from '../TripDebriefContext';
import {MasterTripDebriefSnapshot} from '@onroadvantage/onroadvantage-api';

export const TripDebriefSummaryTasks: React.FC = () => {
  const {masterTripDebriefData, onTabNavigate} =
    React.useContext(TripDebriefContext);
  /**
   *  Destructure snapshotData, need to add || ({} as MasterTripDebriefSnapshot) for typescript, since
   *  snapshotData is nullable.
   */
  const {tasks} =
    masterTripDebriefData?.snapshotData || ({} as MasterTripDebriefSnapshot);

  const list: TripDebriefSummaryCardList = React.useMemo(() => {
    /** We then want to calculate the total amount of tasks that have been completed so that we can get the % Completion */
    let totalCompleted = 0;

    tasks?.forEach(({status}) => {
      if (status === 'Completed') {
        totalCompleted = totalCompleted + 1;
      }
    });

    return [
      {
        primary: 'Task Completion',
        secondary:
          tasks && tasks.length > 0 ? (totalCompleted / tasks.length) * 100 : 0,
        type: 'percentage',
        changed: false,
      },
    ];
  }, [tasks]);

  const handleNavigate = React.useCallback(
    () => onTabNavigate('Tasks'),
    [onTabNavigate]
  );

  return (
    <TripDebriefSummaryCard
      title="Tasks"
      list={list}
      onNavigate={handleNavigate}
    />
  );
};
