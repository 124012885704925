import React from 'react';
import {Tab, Tabs} from '@mui/material';
import {NotificationImportant} from '@mui/icons-material';
import {useTripEventsStyles} from './TripEvents.style';
import {TripCriticalEventList} from './TripCriticalEventList';
import {TripOperationalEventList} from './TripOperationalEventList';
import {TripCard} from '../tripCard';
import {useTripContext} from '../tripContext';

export type TripEventsTabTypes = 'Critical' | 'Operational';

export const TripEvents: React.FC = () => {
  const classes = useTripEventsStyles();
  const {loadCriticalEvents, loadOperationalEvents} = useTripContext();

  const [tabValue, setTabValue] =
    React.useState<TripEventsTabTypes>('Critical');

  const handleTabChange = React.useCallback(
    (_event: unknown, value: TripEventsTabTypes) => {
      setTabValue(value);
    },
    []
  );

  const handleReload = React.useCallback(async () => {
    await loadCriticalEvents({reload: true});
    await loadOperationalEvents({reload: true});
  }, [loadCriticalEvents, loadOperationalEvents]);

  return (
    <TripCard
      title={`Events`}
      onReload={handleReload}
      avatar={<NotificationImportant />}
    >
      <Tabs
        className={classes.tabBar}
        value={tabValue}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Critical" value="Critical" />
        <Tab label="Operational" value="Operational" />
      </Tabs>
      {tabValue === 'Critical' ? (
        <TripCriticalEventList />
      ) : tabValue === 'Operational' ? (
        <TripOperationalEventList />
      ) : null}
    </TripCard>
  );
};
