import React from 'react';
import * as Yup from 'yup';
import {Listing, MasterRoute} from '@onroadvantage/onroadvantage-api';
import {MasterRouteContext, IMasterRouteDetails} from '../MasterRouteContext';
import {
  FormikTextField,
  FormikSubmit,
  FormikAutocompleteWithListing,
} from '../../formik';
import {MasterRouteDetailsCopy} from './MasterRouteDetailsCopy';
import {useMasterRouteDetailsFormStyles} from './MasterRouteDetailsForm.style';
import {TemplateForm} from '../../../factory/template';

const listingSchema: Yup.SchemaOf<Listing> = Yup.object({
  label: Yup.string().default(undefined),
  metaData: Yup.string().default(undefined),
  value: Yup.number().default(undefined),
});
const schema: Yup.SchemaOf<IMasterRouteDetails> = Yup.object({
  name: Yup.string().required('Required'),
  masterTripPrefix: Yup.string().required('Required'),
  contract: listingSchema.nullable().required('Required'),
});

const getInitialValues = (
  masterRoute?: MasterRoute | null
): IMasterRouteDetails | undefined => {
  if (masterRoute) {
    return {
      name: masterRoute.name,
      masterTripPrefix: masterRoute.masterTripPrefix,
      contract: {
        value: masterRoute.contractId,
        label: masterRoute.contract.code,
      },
    };
  }
  return undefined;
};

interface MasterRouteDetailsFormProps {
  isAdd?: boolean;
}

export const MasterRouteDetailsForm: React.FC<MasterRouteDetailsFormProps> = ({
  isAdd,
}) => {
  const classes = useMasterRouteDetailsFormStyles();
  const {detailsRef, loading, submitting, masterRoute, onDetailsFormSubmit} =
    React.useContext(MasterRouteContext);
  const [initialValues, setInitialValues] =
    React.useState<IMasterRouteDetails>();

  React.useEffect(() => {
    const values = getInitialValues(masterRoute);

    if (values) {
      setInitialValues(values);
    } else if (isAdd) {
      setInitialValues({
        name: '',
        masterTripPrefix: '',
        contract: {value: undefined, label: undefined},
      });
    }
  }, [isAdd, masterRoute, setInitialValues]);

  if (loading) {
    return null;
  }

  if (!initialValues) {
    return <div>no initial values</div>;
  }

  return (
    <TemplateForm<IMasterRouteDetails>
      initialValues={initialValues}
      onSubmit={onDetailsFormSubmit}
      validationSchema={schema}
      innerRef={detailsRef}
      permission={{
        name: `${isAdd ? 'Add' : 'Edit'} MasterRoute`,
        type: isAdd ? 'Add' : 'Edit',
      }}
      disableActions
    >
      <FormikTextField
        name="name"
        placeholder="Enter the master route name"
        label="Name"
        fullWidth
      />
      <FormikTextField
        name="masterTripPrefix"
        placeholder="Enter the master route master trip prefix"
        label="Master Trip Prefix"
        fullWidth
      />
      <FormikAutocompleteWithListing
        id="contract"
        name="contract"
        label="Contract"
        placeholder="Select a contract"
        model="Contract"
      />
      <div className={classes.actions}>
        <FormikSubmit
          loading={submitting}
          permission={{
            name: `${isAdd ? 'Add' : 'Edit'} MasterRoute`,
            type: isAdd ? 'Add' : 'Edit',
          }}
        />
        <MasterRouteDetailsCopy />
      </div>
    </TemplateForm>
  );
};
