import React from 'react';
import {useAppNotifications, useOnlineStatus} from '../../contexts';

export const OnlineChecker: React.FC = () => {
  const notify = useAppNotifications();
  const online = useOnlineStatus();
  const [previousOnlineStatus, setPreviousOnlineStatus] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    if (online) {
      if (online !== previousOnlineStatus) {
        notify('success', 'You are online.');
        setPreviousOnlineStatus(true);
      }
    } else if (previousOnlineStatus) {
      notify('error', 'You are offline.');
      setPreviousOnlineStatus(false);
    }
    return () => undefined;
  }, [notify, online, previousOnlineStatus]);

  return null;
};
