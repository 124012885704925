import React from 'react';
import {
  ITemplateTabControllerTab,
  TemplateTabs,
} from '../../../factory/template';
import {useContractOperationalEventTypeConfigContext} from './ContractOperationalEventTypeConfigContext';
import {ContractOperationalEventTypeConfigDetailsForm} from './ContractOperationalEventTypeConfigDetailsForm';
import {ContractOperationalEventTypeConfigReasonCodeList} from './ContractOperationalEventTypeConfigReasonCodeList';
import {ContractOperationalEventTypeConfigNodeTypeList} from './ContractOperationalEventTypeConfigNodeTypeList';
import {ContractOperationalEventTypePromptList} from './contractOperationalEventTypeConfigPrompt/ContractOperationalEventTypePromptList';
import {ContractOperationalEventTypeConfigEscalationList} from './ContractOperationalEventTypeConfigEscalationList';

const tabs: ITemplateTabControllerTab[] = [
  {
    title: 'Details',
    element: <ContractOperationalEventTypeConfigDetailsForm />,
  },
  {
    title: 'Prompt',
    element: <ContractOperationalEventTypePromptList />,
  },
  {
    title: 'Escalation',
    element: <ContractOperationalEventTypeConfigEscalationList />,
  },
  {
    title: 'Reason Codes',
    element: <ContractOperationalEventTypeConfigReasonCodeList />,
  },
  {
    title: 'Node Types',
    element: <ContractOperationalEventTypeConfigNodeTypeList />,
  },
];

export const ContractOperationalEventTypeConfig = () => {
  const {loading, contractOperationalEventTypeConfigId} =
    useContractOperationalEventTypeConfigContext();

  return (
    <TemplateTabs
      path={`/contracteventtypeconfigs/operational/${contractOperationalEventTypeConfigId}`}
      tabs={tabs}
      loading={loading}
    />
  );
};
