import React from 'react';
import {
  ITemplateTabControllerTab,
  TemplateTabs,
} from '../../../factory/template';
import {NotificationConfigContext} from '../NotificationConfigContext';
import {NotificationConfigCriticalEventContext} from './NotificationConfigCriticalEventContext';
import {NotificationConfigCriticalEventContractContacts} from './NotificationConfigCriticalEventContractContacts';
import {NotificationConfigCriticalEventCustomerContacts} from './NotificationConfigCriticalEventCustomerContacts';
import {NotificationConfigCriticalEventNodeContacts} from './NotificationConfigCriticalEventNodeContacts';
import {NotificationConfigCriticalEventVehicleGroupContacts} from './NotificationConfigCriticalEventVehicleGroupContacts';

export const NotificationConfigCriticalEvent: React.FC = () => {
  const {notificationConfigId} = React.useContext(NotificationConfigContext);
  const {notificationConfigEventId, loading} = React.useContext(
    NotificationConfigCriticalEventContext
  );

  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {
      title: 'Contract Contact',
      element: <NotificationConfigCriticalEventContractContacts />,
    },
    {
      title: 'Customer Contact',
      element: <NotificationConfigCriticalEventCustomerContacts />,
    },
    {
      title: 'Node Contact',
      element: <NotificationConfigCriticalEventNodeContacts />,
    },
    {
      title: 'Vehicle Group Contact',
      element: <NotificationConfigCriticalEventVehicleGroupContacts />,
    },
  ]);

  const path = React.useMemo(
    () =>
      `/notificationconfiglist/${notificationConfigId}/event/${notificationConfigEventId}`,
    [notificationConfigId, notificationConfigEventId]
  );

  return <TemplateTabs tabs={tabs} path={path} loading={loading} />;
};
