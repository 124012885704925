import React from 'react';
import {PlanningSkillContext} from './PlanningSkillContext';
import {TemplateTableConfig} from '../../factory/template';
import {PlanningSkillResponse} from '@onroadvantage/onroadvantage-api';
import {TemplateTableWrapper} from '../../factory/template';

export const PlanningSkillList: React.FC = () => {
  const {loadList, cleanupList} = React.useContext(PlanningSkillContext);

  const [config] = React.useState<TemplateTableConfig<PlanningSkillResponse>>({
    columns: [
      {name: 'name', label: 'Name', type: 'string'},
      {name: 'description', label: 'Description', type: 'string'},
      {
        name: 'contractCode',
        label: 'Contract Code',
        type: 'string',
        enableSort: false,
        getValue: (value) => value?.contract?.code,
      },
    ],
    enableFilter: true,
    enableSort: true,
    deleteMessage: (row) => ({
      title: 'Planning Skill',
      items: {Name: row.name, Description: row.description},
    }),
    identifier: 'PLANNING_SKILL_LIST',
  });

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [cleanupList, loadList]);

  return (
    <TemplateTableWrapper config={config} context={PlanningSkillContext} />
  );
};
