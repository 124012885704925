import React from 'react';
import {Tab, Tabs} from '@mui/material';
import {useTripDebriefConfigContext} from '../tripDebriefConfigContext';
import {Loader} from '../../loader';
import {useTemplateTabControllerStyles} from '../../../factory/template/tabs/TemplateTabs.style';
import {
  TripDebriefConfigReasonCodeList,
  TripDebriefConfigReasonCodeListProps,
} from './TripDebriefConfigReasonCodeList';

export const TripDebriefConfigReasonCodes: React.FC = () => {
  const classes = useTemplateTabControllerStyles();
  const [tabValue, setTabValue] = React.useState<number>(0);
  const {masterTripDebriefConfig, loadingTripDebriefConfig} =
    useTripDebriefConfigContext();

  const handleTabChange = React.useCallback((event: any, value: number) => {
    setTabValue(value);
  }, []);

  const list = React.useMemo(() => {
    switch (tabValue) {
      case 1:
        return masterTripDebriefConfig?.varianceThresholdReasonCodes;
      case 0:
        return masterTripDebriefConfig?.tatThresholdReasonCodes;
      default:
        return [];
    }
  }, [
    tabValue,
    masterTripDebriefConfig?.tatThresholdReasonCodes,
    masterTripDebriefConfig?.varianceThresholdReasonCodes,
  ]);

  const type = React.useMemo<
    TripDebriefConfigReasonCodeListProps['type']
  >(() => {
    switch (tabValue) {
      case 1:
        return 'VarianceThreshold';
      case 0:
        return 'TatThreshold';
      default:
        return 'TatThreshold';
    }
  }, [tabValue]);

  return (
    <>
      <Tabs
        className={classes.tabs}
        value={tabValue}
        classes={{scroller: classes.tabs}}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab classes={{root: classes.tab}} label="Tat Threshold" />
        <Tab classes={{root: classes.tab}} label="Variance KM % Threshold" />
      </Tabs>
      {loadingTripDebriefConfig ? (
        <Loader
          text="Rendering Content..."
          classes={{loaderContainer: classes.loaderContainer}}
        />
      ) : (
        <TripDebriefConfigReasonCodeList list={list} type={type} />
      )}
    </>
  );
};
