import React from 'react';
import {PlanningSkillDriverGroupMinimal} from '@onroadvantage/onroadvantage-api';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../../factory/template';
import {
  PlanningSkillDriverGroupListContext,
  usePlanningSkillDriverGroupListContext,
} from './PlanningSkillDriverGroupListContext';
import {PlanningSkillContext} from '../PlanningSkillContext';

export const PlanningSkillDriverGroupList: React.FC = () => {
  const {planningSkill} = React.useContext(PlanningSkillContext);
  const {loadList, cleanupList} = usePlanningSkillDriverGroupListContext();

  const [config] = React.useState<
    TemplateTableConfig<PlanningSkillDriverGroupMinimal>
  >({
    columns: [
      {
        name: 'name',
        label: 'Name',
        type: 'string',
        enableEditing: true,
        enableAutocomplete: true,
        enableMulti: true,
        model: 'DriverGroup',
      },
    ],
    enableFilter: true,
    enableSort: true,
    deleteMessage: (row) => ({
      title: 'Planning Skill Driver Group',
      items: {PlanningSkill: planningSkill?.name, Name: row.name},
    }),
    identifier: 'PLANNING_SKILL_DRIVER_GROUP_LIST',
  });

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [cleanupList, loadList]);

  return (
    <TemplateTableWrapper
      config={config}
      context={PlanningSkillDriverGroupListContext}
    />
  );
};
