import React from 'react';
import {AdminTelematicsConfigContext} from '../AdminTelematicsConfigContext';
import {AdminTelematicsConfigDetailsItem} from './AdminTelematicsConfigDetailsItem';
import {CardContent, Typography, Divider} from '@mui/material';
import {useAdminTelematicsConfigDetailsStyles} from './AdminTelematicsConfigDetails.style';

export const AdminTelematicsConfigDetails: React.FC = () => {
  const classes = useAdminTelematicsConfigDetailsStyles();
  const {telematicsConfig} = React.useContext(AdminTelematicsConfigContext);

  return (
    <CardContent className={classes.details}>
      <Typography className={classes.header}>Generic</Typography>
      <Divider />
      <AdminTelematicsConfigDetailsItem
        title="ID"
        value={telematicsConfig?.id}
      />
      <AdminTelematicsConfigDetailsItem
        title="Provider Name"
        value={telematicsConfig?.providerName}
      />
      <AdminTelematicsConfigDetailsItem
        title="Last Critical Event Token"
        value={telematicsConfig?.lastCriticalEventToken}
      />
      <AdminTelematicsConfigDetailsItem
        title="Last Critical Event Date"
        value={telematicsConfig?.lastEventDatetime?.toISOString()}
      />
      <AdminTelematicsConfigDetailsItem
        title="Last Critical Event External ID"
        value={telematicsConfig?.lastExternalEventId}
      />
      <AdminTelematicsConfigDetailsItem
        title="Last Telematics Event Token"
        value={telematicsConfig?.lastTelematicsEventToken}
      />
      <AdminTelematicsConfigDetailsItem
        title="Last Telematics Event Date"
        value={telematicsConfig?.lastGpsDatetime?.toISOString()}
      />
      <AdminTelematicsConfigDetailsItem
        title="Last Tile38 Telematics Event Id"
        value={telematicsConfig?.lastTile38TelematicsEventId}
      />
      {telematicsConfig?.providerName === 'MiX Integrate' && (
        <>
          <Typography className={classes.header}>MiX Integrate</Typography>
          <Divider />
          <AdminTelematicsConfigDetailsItem
            title="Organisation Group ID"
            value={telematicsConfig?.mixOrganisationGroupId}
          />
          <AdminTelematicsConfigDetailsItem
            title="Organisation Group Name"
            value={telematicsConfig?.mixOrganisationGroupName}
          />
          <AdminTelematicsConfigDetailsItem
            title="Critical Events use timeslice"
            value={telematicsConfig?.mixCriticalEventsUseTimeSlice}
          />
          <AdminTelematicsConfigDetailsItem
            title="Telematics Events use timeslice"
            value={telematicsConfig?.mixTelematicsEventsUseTimeSlice}
          />
          <AdminTelematicsConfigDetailsItem
            title="Location Sync Enabled"
            value={telematicsConfig?.mixIntegrateLocationSyncEnabled}
          />
          <AdminTelematicsConfigDetailsItem
            title="Organisation Enabled"
            value={telematicsConfig?.mixOrganisationEnabled}
          />
          <AdminTelematicsConfigDetailsItem
            title="Groups Enabled"
            value={telematicsConfig?.mixGroupsEnabled}
          />
        </>
      )}
    </CardContent>
  );
};
