import React from 'react';
import {Step, StepLabel, Stepper} from '@mui/material';

export interface TemplateStepperStepperProps {
  activeStep?: number;
  steps?: string[];
}

export const TemplateStepperStepper: React.FC<TemplateStepperStepperProps> = ({
  activeStep,
  steps,
}) => (
  <Stepper activeStep={activeStep}>
    {steps?.map((s) => (
      <Step key={s}>
        <StepLabel>{s}</StepLabel>
      </Step>
    ))}
  </Stepper>
);
