import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {ContractCriticalEventTypeConfigIdHandler} from './ContractCriticalEventTypeConfigIdHandler';
import {ContractCriticalEventTypeConfigList} from './contractCriticalEventTypeConfigList/ContractCriticalEventTypeConfigList';
import {ContractCriticalEventTypeConfigListContextProvider} from './contractCriticalEventTypeConfigList/ContractCriticalEventTypeConfigListContext';
import {ContractCriticalEventTypeConfig} from './ContractCriticalEventTypeConfig';
import {ContractCriticalEventTypeConfigDetailsForm} from './ContractCriticalEventTypeConfigDetailsForm';
import {AllowedRoute} from '../../router';
import {ContractCriticalEventTypePromptContextProvider} from './contractCriticalEventTypeConfigPrompt/ContractCriticalEventTypePromptContext';
import {ContractCriticalEventTypePromptForm} from './contractCriticalEventTypeConfigPrompt/ContractCriticalEventTypePromptForm';
import {ContractCriticalEventTypePrompt} from './contractCriticalEventTypeConfigPrompt/ContractCriticalEventTypePrompt';
import {ContractCriticalEventTypePromptIdHandler} from './contractCriticalEventTypeConfigPrompt/ContractCriticalEventTypePromptIdHandler';

export const ContractCriticalEventTypeConfigController = () => {
  return (
    <ContractCriticalEventTypeConfigListContextProvider>
      <Route
        exact
        path="/contracteventtypeconfigs/critical"
        component={ContractCriticalEventTypeConfigList}
      />
      <Route
        path="/contracteventtypeconfigs/critical/:contractCriticalEventTypeConfigId"
        component={ContractCriticalEventTypeConfigIdHandler}
      />
      <Switch>
        <AllowedRoute
          exact
          path="/contracteventtypeconfigs/critical/add"
          component={() => <ContractCriticalEventTypeConfigDetailsForm isAdd />}
          name="Add ContractCriticalEventTypeConfig"
          type="Add"
        />
        <Route
          exact
          path="/contracteventtypeconfigs/critical/:contractCriticalEventTypeConfigId"
          component={ContractCriticalEventTypeConfig}
        />
        <ContractCriticalEventTypePromptContextProvider>
          <Route
            path="/contracteventtypeconfigs/critical/:contractCriticalEventTypeConfigId/prompt/:contractCriticalEventTypePromptId"
            component={ContractCriticalEventTypePromptIdHandler}
          />
          <Switch>
            <AllowedRoute
              exact
              path="/contracteventtypeconfigs/critical/:contractCriticalEventTypeConfigId/prompt/add"
              component={() => <ContractCriticalEventTypePromptForm isAdd />}
              name="Edit ContractCriticalEventTypeConfig"
              type="Edit"
            />
            <Route
              exact
              path="/contracteventtypeconfigs/critical/:contractCriticalEventTypeConfigId/prompt/:contractCriticalEventTypePromptId"
              component={ContractCriticalEventTypePrompt}
            />
          </Switch>
        </ContractCriticalEventTypePromptContextProvider>
      </Switch>
    </ContractCriticalEventTypeConfigListContextProvider>
  );
};
