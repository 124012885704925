import {renderToString} from 'react-dom/server';
import React from 'react';
import {WebPlanningBoardMasterTripWithIdType} from '../planningBoardContext';

const TooltipContainer: React.FC = ({children}) => (
  <div
    style={{
      background: '#eee',
      margin: -5,
      padding: '5px 8px',
      lineHeight: 1.8,
    }}
  >
    {children}
  </div>
);

export const getMasterTripTooltip = (
  masterTrip: WebPlanningBoardMasterTripWithIdType
) => {
  if (!masterTrip.trip?.stops || masterTrip.trip.stops.length === 0) {
    return undefined;
  }

  const distance = masterTrip.trip.tripDistance
    ? masterTrip.trip.tripDistance.toFixed()
    : 0;
  const preDistance = masterTrip.trip.preDeadLegDistance
    ? masterTrip.trip.preDeadLegDistance.toFixed()
    : 0;

  const html = (
    <TooltipContainer>
      <div key="orderNumber"># {masterTrip?.tripNumber}</div>
      {masterTrip.trip.stops.length === 1 ? (
        <div key="site">Site: {masterTrip.trip.stops[0].node?.name}</div>
      ) : (
        <>
          <div key="upliftPoint">
            From: {masterTrip.trip.stops[0].node?.name}
          </div>
          <div key="offloadPoint">
            To:{' '}
            {masterTrip.trip.stops[masterTrip.trip.stops.length - 1].node?.name}
          </div>
        </>
      )}
      <div key="distance">Distance: {distance}km</div>
      <div key="preDeadLegDistance">Pre Dead Leg Distance: {preDistance}km</div>
    </TooltipContainer>
  );
  return renderToString(html);
};
