import React from 'react';
import {useParams} from 'react-router-dom';
import {
  ReportScheduleContext,
  ReportScheduleContextProps,
} from './ReportScheduleContext';

interface IParams {
  reportScheduleId?: string;
}

export const ReportScheduleIdHandler: React.FC = () => {
  const {setReportScheduleId} = React.useContext<ReportScheduleContextProps>(
    ReportScheduleContext
  );
  const {reportScheduleId} = useParams<IParams>();

  React.useEffect(() => {
    setReportScheduleId(
      reportScheduleId ? parseInt(reportScheduleId) : undefined
    );

    return () => {
      setReportScheduleId(undefined);
    };
  }, [reportScheduleId, setReportScheduleId]);

  return null;
};
