import {makeStyles, createStyles} from '@mui/styles';
import {Theme} from '@mui/material/styles';

const TripSettingsViewLayoutStyles = (theme: Theme) =>
  createStyles({
    root: {flex: 1, cursor: 'default'},
    list: {
      backgroundColor: theme.palette.mode === 'dark' ? '#333' : '#eee',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
      padding: theme.spacing(1),
      borderRadius: theme.spacing(0.5),
    },
    listItem: {
      display: 'flex',
      flex: 1,
      background: theme.palette.background.default,
      cursor: 'default',
      borderRadius: theme.spacing(0.5),
    },
    listItemSortable: {
      cursor: 'move',
      /** Needs zIndex 10000 when sorting, because it is in a Drawer */
      zIndex: 10000,
    },
    listItemIcon: {minWidth: 'fit-content', paddingRight: theme.spacing(1)},
  });

export const useTripSettingsViewLayoutStyles = makeStyles(
  TripSettingsViewLayoutStyles,
  {
    name: 'TripSettingsViewLayoutStyles',
  }
);
