import React from 'react';
import {
  ApiOrderGetRequest,
  Order as OrderType,
} from '@onroadvantage/onroadvantage-api';
import _ from 'lodash';
import {
  CustomPaging,
  Filter,
  FilteringState,
  PagingState,
  SelectionState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableColumnVisibility,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
} from '@devexpress/dx-react-grid-material-ui';
import {SettingService} from '../../../../../service';
import {orderApi} from '../../../../../api';
import {CardActions} from '@mui/material';
import Button from '@mui/material/Button';
import {appNotificationStore} from '../../../../../stores/mobxStores';
import {unstable_batchedUpdates} from 'react-dom';
import {
  TableCell,
  TableComponent,
  TableContainerComponent,
  TableFilterRowCell,
  TableFilterRowComponent,
  TableRow,
} from '../../../../../factory/template';
import {Loader} from '../../../../loader';
import Typography from '@mui/material/Typography';

const VIEW_ID = 'TripEditOrders';
const COL_VISIBILITY_SETTING_ID = 'col_visibility';
const DEFAULT_HIDDEN_COLUMNS: string[] = ['id'];

interface TripEditOrderTableAddProps {
  contractCode: string;
  tripOrders: OrderType[];
  onOrdersAdd: (orders: OrderType[]) => void;
  setIsAdding: React.Dispatch<React.SetStateAction<boolean>>;
}

const getRowId = (row: any) => row.id;

export const TripEditOrderTableAdd: React.FC<TripEditOrderTableAddProps> = ({
  contractCode,
  tripOrders,
  onOrdersAdd,
  setIsAdding,
}) => {
  const [columns] = React.useState([
    {name: 'id', title: 'ID'},
    {name: 'orderNumber', title: 'Number'},
    {
      name: 'upliftPointName',
      title: 'Uplift Point',
      getCellValue: (row: OrderType) => _.get(row, 'upliftPoint.name'),
    },
    {
      name: 'offloadPointName',
      title: 'Offload Point',
      getCellValue: (row: OrderType) => _.get(row, 'offloadPoint.name'),
    },
  ]);
  const [orders, setOrders] = React.useState<OrderType[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [offloadInputValue, setOffloadInputValue] = React.useState<string>();
  const [upliftInputValue, setUpliftInputValue] = React.useState<string>();
  const [orderNumberInputValue, setOrderNumberInputValue] =
    React.useState<string>();
  const [selection, setSelection] = React.useState<Array<number | string>>([]);
  const [filters, setFilters] = React.useState<Array<Filter>>([]);
  const [hiddenColumns, setHiddenColumns] = React.useState<Array<string>>(
    SettingService.getSetting(VIEW_ID, COL_VISIBILITY_SETTING_ID) ||
      DEFAULT_HIDDEN_COLUMNS
  );
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(15);
  const [pageTotal, setPageTotal] = React.useState(1);
  const [itemTotal, setItemTotal] = React.useState(0);

  const getOrders = React.useCallback(async () => {
    setLoading(true);
    setOrders([]);
    try {
      const requestObj: ApiOrderGetRequest = {
        contractCode,
        offloadPointName: offloadInputValue,
        orderNumber: orderNumberInputValue,
        upliftPointName: upliftInputValue,
        page: currentPage,
        perPage: pageSize,
      };
      filters.forEach((f) => {
        if (
          f.columnName === 'offloadPointName' ||
          f.columnName === 'upliftPointName' ||
          f.columnName === 'orderNumber'
        ) {
          requestObj[f.columnName] = f.value;
        }
      });
      const contractOrders = await orderApi.apiOrderGet(requestObj);

      // exclude existing orders
      const unassignedOrders: OrderType[] = [];
      (contractOrders.items || []).forEach((co) => {
        if (!(tripOrders || []).some((to) => to.id === co.id)) {
          unassignedOrders.push(co);
        }
      });
      setPageTotal(contractOrders.pages ?? 1);
      setItemTotal(contractOrders.total ?? 0);
      setOrders(unassignedOrders);
    } catch (e) {
      appNotificationStore.enqueueNotification('error', e.message);
    } finally {
      setLoading(false);
    }
  }, [
    contractCode,
    currentPage,
    filters,
    offloadInputValue,
    orderNumberInputValue,
    pageSize,
    tripOrders,
    upliftInputValue,
  ]);

  const handleOrdersAddSubmit = () => {
    const ordersToSubmit = orders.filter(
      (o) => o && o.id && selection.indexOf(o.id) > -1
    );
    if (onOrdersAdd) {
      onOrdersAdd(ordersToSubmit);
    }
    setSelection([]);
    setIsAdding(false);
  };

  const handleOrdersAddCancel = () => {
    setSelection([]);
    setOffloadInputValue(undefined);
    setOrderNumberInputValue(undefined);
    setUpliftInputValue(undefined);
    setIsAdding(false);
  };

  const handleColumnVisibilityChange = (value: Array<string>) => {
    SettingService.setSetting(VIEW_ID, COL_VISIBILITY_SETTING_ID, value);
    setHiddenColumns(value);
  };

  const handleFiltersChange = _.debounce((newFilters: Filter[]) => {
    unstable_batchedUpdates(() => {
      setCurrentPage(1);
      setFilters(newFilters);
    });
  }, 600);

  const handlePageSizeCountsChange = React.useCallback((value: number) => {
    setCurrentPage(1);
    setPageSize(value);
  }, []);

  const handleCurrentPageChange = React.useCallback(
    (value: number) => {
      let newPage = value;

      if (value < 0) {
        // lower bound check
        newPage = 0;
      } else if (value > pageTotal) {
        // upper bound check
        newPage = pageTotal;
      }

      setCurrentPage(newPage + 1);
    },
    [pageTotal]
  );

  React.useEffect(() => {
    getOrders();
  }, [getOrders]);

  return (
    <>
      <Grid columns={columns} getRowId={getRowId} rows={orders}>
        <SelectionState
          selection={selection}
          onSelectionChange={setSelection}
        />
        <FilteringState
          columnExtensions={[{columnName: 'id', filteringEnabled: false}]}
          columnFilteringEnabled={!loading}
          defaultFilters={filters}
          onFiltersChange={handleFiltersChange}
        />
        <PagingState
          defaultCurrentPage={1}
          currentPage={(currentPage ?? 1) - 1}
          onCurrentPageChange={handleCurrentPageChange}
          pageSize={pageSize}
          onPageSizeChange={handlePageSizeCountsChange}
        />
        <Table
          containerComponent={TableContainerComponent}
          tableComponent={TableComponent}
          rowComponent={TableRow}
          cellComponent={TableCell}
          noDataCellComponent={(props) => (
            <td {...props}>
              {loading ? <Loader /> : <Typography>No Data</Typography>}
            </td>
          )}
        />
        <TableColumnResizing
          resizingMode="nextColumn"
          defaultColumnWidths={columns.map((column) => ({
            columnName: column.name,
            width: 'auto',
          }))}
          columnExtensions={[
            {columnName: 'actions', maxWidth: 200, minWidth: 90.5},
          ]}
        />
        <TableSelection selectByRowClick highlightRow />
        <TableHeaderRow />
        <TableFilterRow
          cellComponent={TableFilterRowCell}
          rowComponent={TableFilterRowComponent}
        />
        <PagingPanel
          // containerComponent={TablePagingPanelContainer}
          pageSizes={[10, 15, 25, 50, 100]}
        />
        <CustomPaging
          totalCount={
            itemTotal !== undefined
              ? itemTotal
              : (pageSize ?? 25) * (pageTotal ?? 1)
          }
        />
        <TableColumnVisibility
          hiddenColumnNames={hiddenColumns}
          onHiddenColumnNamesChange={handleColumnVisibilityChange}
        />
      </Grid>
      <CardActions>
        <Button
          disabled={loading || selection.length < 1}
          color="primary"
          variant="text"
          type="submit"
          onClick={handleOrdersAddSubmit}
        >
          Add Orders
        </Button>
        <Button
          disabled={loading}
          variant="text"
          type="submit"
          onClick={handleOrdersAddCancel}
        >
          Cancel
        </Button>
      </CardActions>
    </>
  );
};
