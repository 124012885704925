import React from 'react';
import {FormikProps} from 'formik/dist/types';
import {connect} from 'formik';
import {Listing} from '@onroadvantage/onroadvantage-api';
import {IPlanningAddFormValues} from './PlanningAdd';
import {PlanningAddBoard} from './PlanningAddBoard';
import {listingToLaneData} from '../../../service/laneUtils';
import {ILaneCardData} from '../../../stores/PlanningSolutionStore';

interface IFormikProps {
  formik: FormikProps<IPlanningAddFormValues>;
}

interface Props {
  orders: Array<Listing>;
}

export const PlanningAddOrdersComponent = ({
  formik,
  orders,
}: Props & IFormikProps) => {
  // TODO why is the planningOrders not available when this renders.

  // const {ordersPlanningFull} = usePlanningSolutionData((store) => ({
  //   ordersPlanningFull: store.planningOrders,
  // }));
  // const planningOrderIds = orders.map((po: Listing) => po.value);
  // const planningOrders = ordersPlanningFull.filter((o) =>
  //   planningOrderIds.includes(o.id)
  // );
  const all = listingToLaneData(orders);
  const allocated = listingToLaneData(
    orders.filter((o) => {
      if (o && o.value) {
        return formik.values.allocatedOrders.indexOf(o.value) !== -1;
      }
      return false;
    })
  );
  const available = listingToLaneData(
    orders.filter((o) => {
      if (o && o.value) {
        return formik.values.allocatedOrders.indexOf(o.value) === -1;
      }

      return false;
    })
  );

  const handleAllocatedChange = (values: ILaneCardData[]) => {
    formik.setFieldValue(
      'allocatedOrders',
      values.map((v) => parseInt(`${v.id}`, 10))
    );
  };

  return (
    <PlanningAddBoard
      all={all}
      allocated={allocated}
      available={available}
      title="Allocation of Orders"
      updateAllocated={handleAllocatedChange}
      updateAvailable={() => null}
    />
  );
};

export const PlanningAddOrders = connect<Props, IPlanningAddFormValues>(
  PlanningAddOrdersComponent
);
