import React from 'react';
import {TableActionBase, TableActionBodyCell} from '../components';
import {DataTypeProvider} from '@devexpress/dx-react-grid';
import {TemplateTableDefault, TemplateTableProps} from '../TemplateTable';

export type rowChangesType<T extends TemplateTableDefault> = {
  [key: string]: T;
};

interface ContextProps<GenericData> {
  rowDetail?: TemplateTableProps<GenericData>['rowDetail'];
  onAdd?: TemplateTableProps<GenericData>['onAdd'];
  onAuxNavigate?: TemplateTableProps<GenericData>['onAuxNavigate'];
  onDelete?: TemplateTableProps<GenericData>['onDelete'];
  onDownload?: TemplateTableProps<GenericData>['onDownload'];
  onDownloadItem?: TemplateTableProps<GenericData>['onDownloadItem'];
  onNavigate?: TemplateTableProps<GenericData>['onNavigate'];
  onDragStart?: TemplateTableProps<GenericData>['onDragStart'];
  onWarning?: TemplateTableProps<GenericData>['onWarning'];
  saveChangesHandler?: () => void;
  rowChanges?: rowChangesType<any> | undefined;
  enableInlineAdd?: boolean;
  enableInlineEdit?: boolean;
  enableInlineEditAll?: boolean;
  expandRowsHandler?: (row: GenericData) => void;
  addedRows?: {[key: string]: any}[];
  expandedRowIds?: (string | number)[];
  editingRowIds?: (string | number)[];
  addRowHandler?: () => void;
  enableRowEdit?: (row: GenericData) => boolean;
  setEditingRowsHandler?: (row: GenericData) => void;
  setEditingRowIds: React.Dispatch<React.SetStateAction<(string | number)[]>>;
  onSelectionChange?: TemplateTableProps<GenericData>['onSelectionChange'];
}

function createTableActionContext<TableGenericData = any>() {
  return React.createContext<ContextProps<TableGenericData>>({
    setEditingRowIds: () => {},
  });
}

const TableActionContext = createTableActionContext();

export const useTemplateTableActions = () =>
  React.useContext(TableActionContext);

export const ActionsTypeProvider = <T,>(
  value: ContextProps<T>
): React.ReactElement => (
  <DataTypeProvider
    for={['actions']}
    editorComponent={(props) => (
      <TableActionContext.Provider value={value}>
        {props.row ? (
          <TableActionBodyCell {...props} editor />
        ) : (
          <TableActionBase {...props} />
        )}
      </TableActionContext.Provider>
    )}
    formatterComponent={(props) => (
      <TableActionContext.Provider value={value}>
        <TableActionBodyCell {...props} />
      </TableActionContext.Provider>
    )}
  />
);
