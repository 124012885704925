import React from 'react';
import {TransporterContext} from './TransporterContext';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../factory/template';
import {Transporter} from '@onroadvantage/onroadvantage-api';

export const TransporterList: React.FC = () => {
  const {loadList, cleanupList} = React.useContext(TransporterContext);

  const [config] = React.useState<TemplateTableConfig<Transporter>>({
    columns: [
      {name: 'name', label: 'Name', type: 'string'},
      {name: 'description', label: 'Description', type: 'string'},
    ],
    enableFilter: true,
    enableSort: true,
    deleteMessage: (row) => ({
      title: 'Transporter',
      items: {Name: row.name, Description: row.description},
    }),
    identifier: 'TRANSPORTER_LIST',
  });

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [cleanupList, loadList]);

  return <TemplateTableWrapper config={config} context={TransporterContext} />;
};
