import React from 'react';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import QRCode from 'qrcode.react';
import RoleService from '../../../service/RoleService';
import {UserService} from '../../../service';
import {useAppNotifications} from '../../../contexts';
import {useTripContext} from '../tripContext';
import {driverApi} from '../../../api';
import {VantageDialogButton} from '../../dialog';
import {Loader} from '../../loader';
import {useDownloadQRAsPDF} from './useDownloadQrAsPdf';
import {SaveAlt} from '@mui/icons-material';

export const TripSummaryDriverQrGenerate: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const {masterTrip} = useTripContext();
  const [userKey, setUserKey] = React.useState('');
  const notify = useAppNotifications();
  const driverObj = masterTrip?.trip?.driver;
  const {downloadQRasPDF, qrRef} = useDownloadQRAsPDF(userKey, driverObj);

  const generateUserQr = React.useCallback(async () => {
    try {
      setLoading(true);
      if (masterTrip?.trip?.driver?.id) {
        const driverResponse = await driverApi.apiDriverDriverIdGet({
          driverId: masterTrip.trip.driver.id,
        });
        const driver = driverResponse?.user;
        const userId = driver?.id;
        if (userId) {
          const response = await UserService.getUserQr(userId);
          const data = response.data;
          setUserKey(data.key);
          notify('success', 'QR Generated Successfully');
        } else {
          // Display warning message if userId is not available
          notify('warning', 'User ID is not available');
        }
      } else {
        // Display warning message if driver is not available
        notify('warning', 'Driver information is not available');
      }
    } catch (e) {
      // Handle error, e.g., display error message
      notify('error', e.message ?? 'Failed to Generate QR');
    } finally {
      setLoading(false);
    }
  }, [masterTrip, setLoading, notify]);

  const handleDownload = async () => {
    try {
      if (driverObj && userKey) {
        await downloadQRasPDF({userKey, driver: driverObj});
      }
    } catch (error) {
      notify('error', 'Failed to download pdf');
    }
  };

  return (
    <VantageDialogButton
      iconButton={true}
      size="small"
      reverseActions={false}
      declineProps={{sx: {marginLeft: 20}}}
      dialogTitle="DRIVER QR"
      declineTitle="Cancel"
      acceptProps={{
        startIcon: <SaveAlt />,
        sx: {marginRight: 15},
        color: 'inherit',
      }}
      acceptTitle={''}
      title={<QrCodeScannerIcon />}
      disabled={!RoleService.hasPermission('User QR', 'View')}
      onClick={generateUserQr}
      onAccept={handleDownload}
    >
      {loading ? (
        <Loader loading={loading} />
      ) : userKey ? (
        <div ref={qrRef}>
          <QRCode value={userKey} size={256} level="L" />
        </div>
      ) : (
        <div>No QR code available.</div>
      )}
    </VantageDialogButton>
  );
};
