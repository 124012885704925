import React from 'react';
import {
  TemplateCard,
  TemplateTable,
  TemplateTableConfig,
} from '../../../factory/template';
import {
  MasterTripDebriefOrderLineSnapshot,
  MasterTripDebriefSnapshot,
} from '@onroadvantage/onroadvantage-api';
import {TripDebriefContext} from '../TripDebriefContext';
import {useHistory} from 'react-router-dom';
import {getOrder} from '../helpers';
import {Typography} from '@mui/material';
import {Assignment} from '@mui/icons-material';

interface MasterTripDebriefOrderLineSnapshotWithChangedChecker
  extends MasterTripDebriefOrderLineSnapshot {
  changedActualQuantity: boolean;
  changedActualLoadedQuantity: boolean;
}

export const TripDebriefOrderLineList: React.FC = () => {
  const history = useHistory();
  const {masterTripId, masterTripDebriefData} =
    React.useContext(TripDebriefContext);
  /**
   *  Destructure snapshotData, need to add || ({} as MasterTripDebriefSnapshot) for typescript, since
   *  snapshotData is nullable.
   */
  const {orders} =
    masterTripDebriefData?.snapshotData || ({} as MasterTripDebriefSnapshot);
  /**
   *  Destructure overrideData, need to add || ({} as MasterTripDebriefSnapshot) for typescript, since
   *  overrideData is nullable.
   */
  const {orders: ordersOverride} =
    masterTripDebriefData?.overrideData || ({} as MasterTripDebriefSnapshot);
  const [list, setList] = React.useState<
    MasterTripDebriefOrderLineSnapshotWithChangedChecker[]
  >([]);

  const [config, setConfig] = React.useState<
    | TemplateTableConfig<MasterTripDebriefOrderLineSnapshotWithChangedChecker>
    | undefined
  >();

  const handleNavigate = React.useCallback(
    (row: MasterTripDebriefOrderLineSnapshotWithChangedChecker) => {
      if (masterTripId) {
        history.push(`/tripdebrieflist/${masterTripId}/orderline/${row.id}`);
      }
    },
    [history, masterTripId]
  );

  React.useEffect(() => {
    setConfig({
      columns: [
        {
          name: 'orderNumber',
          label: 'Order Number',
          type: 'string',
          getValue: ({orderId}) => getOrder(orders, orderId)?.orderNumber,
        },
        {
          name: 'productName',
          label: 'Product Name',
          type: 'string',
          getValue: ({product}) => product?.name,
        },
        {
          name: 'productUom',
          label: 'Product UOM',
          type: 'string',
          getValue: ({product}) => product?.uom,
        },
        {
          name: 'quantity',
          label: 'Planned Quantity',
          type: 'number',
        },
        {
          name: 'actualLoadedQuantity',
          label: 'Loaded Quantity',
          type: 'number',
          getValue: ({changedActualLoadedQuantity, actualLoadedQuantity}) => (
            <Typography
              color={
                changedActualLoadedQuantity ? 'secondary.main' : 'text.primary'
              }
            >
              {actualLoadedQuantity}
            </Typography>
          ),
        },
        {
          name: 'actualQuantity',
          label: 'Offloaded Quantity',
          type: 'number',
          getValue: ({changedActualQuantity, actualQuantity}) => (
            <Typography
              color={changedActualQuantity ? 'secondary.main' : 'text.primary'}
            >
              {actualQuantity}
            </Typography>
          ),
        },
      ],
      disablePagination: true,
      disableToolbar: true,
      identifier: 'TRIP_DEBRIEF_ORDER_LINE_LIST',
    });
    const orderLinesOverride: MasterTripDebriefOrderLineSnapshot[] = [];

    ordersOverride?.forEach(({lines}) => {
      if (lines) {
        orderLinesOverride.push(...lines);
      }
    });
    const orderLines: MasterTripDebriefOrderLineSnapshotWithChangedChecker[] =
      [];

    orders?.forEach(({lines}) => {
      if (lines) {
        lines.forEach((line) => {
          if (orderLinesOverride.map(({id}) => id).includes(line.id)) {
            const overrideLine = orderLinesOverride.find(
              ({id}) => id === line.id
            );
            orderLines.push({
              ...line,
              actualQuantity:
                overrideLine?.actualQuantity ?? line.actualQuantity,
              actualLoadedQuantity:
                overrideLine?.actualLoadedQuantity ?? line.actualLoadedQuantity,
              changedActualQuantity: !!overrideLine?.actualQuantity,
              changedActualLoadedQuantity: !!overrideLine?.actualLoadedQuantity,
            });
          } else {
            orderLines.push({
              ...line,
              changedActualQuantity: false,
              changedActualLoadedQuantity: false,
            });
          }
        });
      }
    });

    setList(orderLines);
    return () => {
      setList([]);
      setConfig(undefined);
    };
  }, [orders, ordersOverride]);

  return (
    <TemplateCard title="Trip Orders" avatar={<Assignment />} loading={!config}>
      {config && (
        <TemplateTable
          config={config}
          currentPage={1}
          list={list}
          onNavigate={handleNavigate}
          elevation={0}
        />
      )}
    </TemplateCard>
  );
};
