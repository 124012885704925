import React from 'react';
import {ContractOperationalEventTypeConfigNodeType} from '@onroadvantage/onroadvantage-api';
import {TemplateTable} from '../../../factory/template';
import {useContractOperationalEventTypeConfigContext} from './ContractOperationalEventTypeConfigContext';

interface ContractOperationalEventTypeConfigNodeTypeWithId
  extends ContractOperationalEventTypeConfigNodeType {
  id: number | undefined;
}

export const ContractOperationalEventTypeConfigNodeTypeList = () => {
  const {
    loading,
    submitting,
    contractOperationalEventTypeConfig,
    onAddNodeTypes,
    onDeleteNodeType,
  } = useContractOperationalEventTypeConfigContext();

  return (
    <TemplateTable<ContractOperationalEventTypeConfigNodeTypeWithId>
      config={{
        columns: [
          {
            name: 'nodeType',
            label: 'Node Type',
            type: 'string',
            enableEditing: false,
            enableInlineAdding: true,
            enableAutocomplete: true,
            enableMulti: true,
            model: 'NodeType',
            getValue: ({nodeType}) => nodeType?.name,
          },
          {
            name: 'triggerThreshold',
            label: 'Trigger Threshold',
            type: 'string',
            enableEditing: true,
            hidden: true,
          },
        ],
        disablePagination: true,
        disableToolbar: true,
        deleteMessage: (row) => ({
          title: 'Contract Operational Event Type Config Node Type',
          items: {
            ContractOperationalEventTypeConfig: `${contractOperationalEventTypeConfig?.operationalEventType?.name} - ${contractOperationalEventTypeConfig?.contract?.name}`,
            NodeType: row.nodeType?.name,
            TriggerThreshold: row.triggerThreshold,
          },
        }),
        identifier: 'CONTRACT_OPERATIONAL_EVENT_TYPE_CONFIG_NODE_TYPE',
      }}
      list={
        contractOperationalEventTypeConfig?.nodeTypes?.map((nodeType) => ({
          ...nodeType,
          id: nodeType.nodeTypeId,
        })) ?? []
      }
      currentPage={1}
      onInlineAdd={onAddNodeTypes}
      onDelete={onDeleteNodeType}
      loading={loading || submitting}
    />
  );
};
