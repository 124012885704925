import React from 'react';

import {Box, Card, Typography, Theme, IconButton, Divider} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import waypoint from '../../../../img/svg/vantageLocation.svg';
import {makeStyles} from '@mui/styles';
import custImg from '../../../../img/background/popupbackground.png';
import {Add as AddIcon} from '@mui/icons-material';

const useMasterRouteStaticPopupStyles = makeStyles((theme: Theme) =>
  createStyles({
    removeMargin: {
      margin: 0,
      padding: 0,
    },
    headingColor: {
      color: '#767676',
    },
    fab: {
      top: theme.spacing(1),
      right: theme.spacing(1),
      position: 'absolute',
      boxShadow: 'none',
      width: 36,
    },
    speedBox: {
      position: 'absolute',

      bottom: '-12%',
      BoxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.24)',
      background: 'linear-gradient(90deg,#00B7A3 0%,#008270 100%)',
      borderRadius: '12px',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    bottomFab: {
      background: 'linear-gradient(90deg, #00B7A3 0%, #008270 100%)',
      transform: 'rotate(-180deg)',
      boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.24)',
    },
    divider: {
      width: '1px',
      background: '#fff',
      marginTop: '10px',
      marginBottom: '10px',
    },
    backgrounCard: {
      borderRadius: '12px 12px 12px 12px',
      width: '100%',
      boxShadow: 'none',
      marginTop: theme.spacing(3),
    },

    eventDesc: {
      fontWeight: 400,
      fontSize: 12,
      paddingLeft: 10,
    },
    spacing: {
      margin: theme.spacing(2),
    },
    parentScrollDiv: {
      overflow: 'auto',
      maxHeight: '400px',
      minHeight: '200px',
      borderRadius: '12px 12px 12px 12px',
    },
    custButton: {
      backgroundColor: '#E5E5E5',
    },
    backGround: {
      height: '200px',
      position: 'relative',
    },
  })
);

interface Props {
  leg: any;
  event: any;
  adminLayer: L.Layer;
  onEdit: (param: any, param2: any) => void;
  showEdit: boolean;
}

export default function MasterRouteStaticPopup({
  adminLayer,
  onEdit,

  event,
  leg,
}: Props) {
  const classes = useMasterRouteStaticPopupStyles();
  return (
    <div>
      <Box display="flex" alignItems="center" m={1}>
        &nbsp;&nbsp;
        <Typography variant="h4">Waypoint</Typography>
      </Box>

      <div className={classes.parentScrollDiv}>
        <Box
          style={{backgroundImage: `url(${custImg})`}}
          className={classes.backGround}
          display="flex"
          justifyContent="center"
        >
          <Box display="flex" justifyContent="center" ml={2} mr={2}>
            <img src={waypoint} alt=";" />
          </Box>

          <Box
            className={classes.speedBox}
            display="flex"
            justifyContent="space-around"
            alignItems="center"
            mt={2}
            mb={1}
          >
            <Box p={1}>
              <IconButton
                color="inherit"
                className={classes.custButton}
                size="medium"
                onClick={() => onEdit(adminLayer, event)}
              >
                <AddIcon />
              </IconButton>
            </Box>
            <Divider
              orientation="vertical"
              classes={{root: classes.divider}}
              flexItem
            />
            <Box p={1}>
              <Typography style={{color: '#fff', fontWeight: 400}} variant="h5">
                Type
              </Typography>

              <Typography variant="h6" style={{color: '#fff', fontWeight: 700}}>
                Waypoint
              </Typography>
            </Box>
          </Box>
        </Box>

        <Card className={classes.backgrounCard}>
          <Box ml={1} mr={1}>
            <Box mb={1}>
              <Typography variant="h5" className={classes.headingColor}>
                {`Route Leg ${leg.id}`}
              </Typography>
            </Box>
            <Box mb={1}>
              <Typography variant="h5" className={classes.headingColor}>
                From
              </Typography>
              <Typography variant="h6">{leg.toNode.name}</Typography>
            </Box>
            <Box mb={1}>
              <Typography variant="h5" className={classes.headingColor}>
                To
              </Typography>
              <Typography variant="h6">{leg.fromNode.name}</Typography>
            </Box>
            <Box mb={1}>
              <Typography variant="h5" className={classes.headingColor}>
                Distance
              </Typography>
              <Typography variant="h6">{leg.distance}</Typography>
            </Box>
            <Box mb={1}>
              <Typography variant="h5" className={classes.headingColor}>
                Duration
              </Typography>
              <Typography variant="h6">{leg.duration}</Typography>
            </Box>

            <Box mb={1}>
              <Typography variant="h5" className={classes.headingColor}>
                Waypoints
              </Typography>

              {leg.waypoints.map((waypoint: any) => {
                return (
                  <div key={waypoint.id} style={{flex: 1, width: 200}}>
                    Id: {waypoint.id}
                  </div>
                );
              })}
            </Box>
          </Box>
        </Card>
      </div>
    </div>
  );
}
