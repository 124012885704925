import React from 'react';
import {AppBar, Tabs, Tab, Typography} from '@mui/material';
import AdhocWizard from '../adhocGeoBroadcastWizard/AdhocWizard';
// import GroupBroadcastWizard from '../groupMessageWizard/GroupBroadcastWizard';
import {BroadcastVantageMobile} from '../broadcastVantageMobile/BroadcastVantageMobile';
import {BroadcastSingleMessage} from '../broadcastSingleMessage/BroadcastSingleMessage';

interface LinkTabProps {
  label?: string;
  href?: string;
  disabled?: boolean;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </Typography>
  );
}

function LinkTab(props: LinkTabProps) {
  return (
    <Tab
      component="a"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

// adds aria usability values to ids
function a11yProps(index: any) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  };
}

export default class VantageVoice extends React.Component {
  // todo: prop typing
  constructor(props: any) {
    super(props);
    this.state = {
      value: 0,
    };
  }

  public handleChange = (
    _changedValue: React.ChangeEvent<any>,
    newValue: number
  ) => {
    this.setState({value: newValue});
  };

  render() {
    // @ts-expect-error upgrade
    const {value} = this.state;
    return (
      <div>
        <AppBar position="static" color="default">
          <Tabs
            variant="fullWidth"
            value={value}
            onChange={this.handleChange}
            aria-label="nav tabs example"
            indicatorColor="primary"
            textColor="primary"
          >
            <LinkTab label="Ad-hoc Broadcasting" {...a11yProps(0)} />
            {/*<LinkTab*/}
            {/*  label="Recurring Broadcasting"*/}
            {/*  {...a11yProps(1)}*/}
            {/*  disabled={true}*/}
            {/*/>*/}
            <LinkTab label="Single Message" {...a11yProps(1)} />
            <LinkTab label="Vantage Mobile Broadcast" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <AdhocWizard />
        </TabPanel>
        {/*<TabPanel value={value} index={1}>*/}
        {/*  <GroupBroadcastWizard />*/}
        {/*</TabPanel>*/}
        <TabPanel value={value} index={1}>
          <BroadcastSingleMessage />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <BroadcastVantageMobile />
        </TabPanel>
      </div>
    );
  }
}
