import React from 'react';
import {TripViewLayoutLabels, useTripSettingsContext} from './tripSettings';
import {TripGantt} from './tripGantt';
import {TripMap} from './tripMap';
import {TripEvents} from './tripEvents';
import {TripDebrief} from './tripDebrief';
import {TripStopList} from './tripStop';
import {TripCommentList} from './TripCommentList';
import {RoleService} from '../../service';

interface TripViewLayoutRenderOption {
  element: React.ReactNode;
  label: TripViewLayoutLabels;
}

export const TripViewLayout: React.FC = () => {
  const {tripViewLayout} = useTripSettingsContext();

  const [renderViewOptions] = React.useState<TripViewLayoutRenderOption[]>([
    {element: <TripDebrief />, label: 'Debrief'},
    {element: <TripStopList />, label: 'Stops'},
    {element: <TripGantt />, label: 'Gantt'},
    {element: <TripMap />, label: 'Map'},
    {element: <TripEvents />, label: 'Events'},
    {element: <TripCommentList />, label: 'Comments'},
  ]);

  const sortedView: (TripViewLayoutRenderOption | null)[] =
    React.useMemo(() => {
      if (tripViewLayout) {
        return tripViewLayout.map((view) => {
          if (
            view.permission &&
            !RoleService.hasPermission(
              view.permission.name,
              view.permission.type
            )
          ) {
            return null;
          }
          if (view.hidden) return null;
          return (
            renderViewOptions.find(({label}) => label === view.label) ?? null
          );
        });
      }
      return renderViewOptions;
    }, [renderViewOptions, tripViewLayout]);

  return (
    <>
      {sortedView.map((view, index) => (
        <React.Fragment key={view?.label ?? index}>
          {view ? view.element : null}
        </React.Fragment>
      ))}
    </>
  );
};
