// Base64 to Hex
import {authStore} from '../store';

export const GLOBAL_NAME = 'GLOBAL';
export const EVENT_PANE_AUTO_LOAD_NAME = 'EVENTPANEAUTOLOAD';
export const DATE_FORMAT_NAME = 'DATEFORMAT';

const base64ToHex = (str) => {
  const hex = [];
  const bin = window.atob(str.replace(/[ \r\n]+$/, ''));
  for (let i = 0; i < bin.length; i += 1) {
    let tmp = bin.charCodeAt(i).toString(16);
    if (tmp.length === 1) tmp = `0${tmp}`;
    hex[hex.length] = tmp;
  }
  return hex.join('');
};

const encodeString = (value) => {
  return base64ToHex(window.btoa(value));
};

const getEncodedUserId = () => {
  const {auth} = authStore;

  if (!auth) {
    return null;
  }

  return encodeString(auth.email);
};

const getEncodedKey = (id, view) => {
  // return `${encodeString(id)}_${encodeString(view)}_${getEncodedUserId()}`;
  return `${id}_${view}_${getEncodedUserId()}`;
};

const SettingService = {
  setSetting: (id, view, value) => {
    const key = getEncodedKey(id, view);
    // eslint-disable-next-line no-undef
    localStorage.setItem(key, JSON.stringify(value));
  },
  getSetting: (id, view) => {
    const key = getEncodedKey(id, view);
    // eslint-disable-next-line no-undef
    return JSON.parse(localStorage.getItem(key));
  },
  loadColumnVisibility: (viewId, settingId) => {
    const hiddenColumns = SettingService.getSetting(viewId, settingId);
    if (hiddenColumns) {
      return hiddenColumns;
    }
    return null;
  },
};

export default SettingService;
