import React from 'react';
import {WorkflowConfigList} from './WorkflowConfigList';
import {
  ITemplateControllerRoute,
  TemplateController,
} from '../../factory/template';
import {
  WorkflowConfigContext,
  WorkflowConfigContextProvider,
} from './WorkflowConfigContext';
import {WorkflowConfigIdHandler} from './WorkflowConfigIdHandler';
import {WorkflowConfig} from './WorkflowConfig';
import _ from 'lodash';
import {WorkflowConfigForm} from './WorkflowConfigForm';

export const WorkflowConfigController: React.FC = () => {
  return (
    <WorkflowConfigContextProvider>
      <WorkflowConfigControllerComponent />
    </WorkflowConfigContextProvider>
  );
};

const WorkflowConfigControllerComponent: React.FC = () => {
  const {loading, workflowConfig} = React.useContext(WorkflowConfigContext);

  const routes: ITemplateControllerRoute[] = React.useMemo(
    () => [
      {title: 'Workflow Config List', component: WorkflowConfigList},
      {
        path: '/add',
        title: 'New Workflow Config',
        name: 'Add WorkflowConfig',
        type: 'Add',
        component: () => <WorkflowConfigForm isAdd />,
      },
      {
        path: '/:workflowConfigId',
        title: (params) =>
          loading
            ? 'Transporter Loading...'
            : workflowConfig?.name ??
              `workflowConfig ${_.get(params, 'workflowConfigId', 'Single')}`,
        component: WorkflowConfig,
        IdHandler: WorkflowConfigIdHandler,
      },
    ],
    [loading, workflowConfig?.name]
  );

  return <TemplateController rootPath="/workflowconfiglist" routes={routes} />;
};
