import React from 'react';
import {useHistory} from 'react-router-dom';
import {useAuthenticationBackToLoginStyles} from './AuthenticationBackToLogin.style';
import {Button} from '@mui/material';
import {MuiButtonColorTypes, MuiButtonVariantTypes} from '../../typings/mui';

interface AuthenticationBackToLoginProps {
  color?: MuiButtonColorTypes;
  variant?: MuiButtonVariantTypes;
}

export const AuthenticationBackToLogin: React.FC<
  AuthenticationBackToLoginProps
> = ({color, variant}) => {
  const classes = useAuthenticationBackToLoginStyles();
  const history = useHistory();

  const handleButtonClick = () => {
    history.push('/login');
  };

  return (
    <div>
      <Button
        className={classes.button}
        variant={variant ?? 'contained'}
        color={color ?? 'primary'}
        onClick={handleButtonClick}
      >
        Back to Login
      </Button>
    </div>
  );
};
