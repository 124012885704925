import React from 'react';
import {Collapse, Stack} from '@mui/material';
import TextField from '@mui/material/TextField';
import {useBroadcastVantageMobileContext} from './BroadcastVantageMobileContext';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {generateUid} from '../../../service/Util';
import VoiceService from '../../../service/VoiceService';
import {useAppNotifications} from '../../../contexts';
import ReactAudioPlayer from 'react-audio-player';
import {Loader} from '../../loader';

export const BroadcastVantageMobileMessageStep = () => {
  const notify = useAppNotifications();
  const {messageHeadingRef, messageRef, setMessageIdentifier} =
    useBroadcastVantageMobileContext();
  const [generating, setGenerating] = React.useState<boolean>(false);
  const [generatedUrl, setGeneratedUrl] = React.useState<string | undefined>();

  const validateFields = React.useCallback(() => {
    let isValid = true;
    const messages: string[] = [];

    if (!messageHeadingRef.current?.value) {
      messages.push('Message heading is required.');
      isValid = false;
    }

    if (!messageRef.current?.value) {
      messages.push('Custom message is required.');
      isValid = false;
    }

    if (!isValid) {
      notify('warning', messages.join(' '));
    }

    return isValid;
  }, [messageRef, messageHeadingRef, notify]);
  const generateAudioFile = async () => {
    if (!validateFields()) {
      return;
    }
    setGenerating(true);
    try {
      const identifier = `${generateUid()}.mp3`;
      const data = await VoiceService.generateVoiceMessage({
        identifier,
        message: messageRef?.current?.value,
      });
      setGeneratedUrl(data.data);
      setMessageIdentifier(identifier);
    } catch (e) {
      notify('error', 'Failed to generate message.');
    } finally {
      setGenerating(false);
    }
  };

  return (
    <Stack spacing={2} py={2}>
      <TextField
        style={{width: 200}}
        label="Message Heading"
        placeholder="Safety Message #1..."
        inputRef={messageHeadingRef}
      />
      <Stack direction="row" spacing={1} alignItems="center">
        <TextField
          style={{width: 600}}
          helperText="Hi Driver, please ..."
          label="Custom Message Field"
          multiline={true}
          rows={2}
          inputRef={messageRef}
        />
        <Button
          disabled={generating}
          onClick={generateAudioFile}
          startIcon={<Loader size={25} loading={generating} />}
        >
          <Typography>{generating ? 'Generating...' : 'Generate'}</Typography>
        </Button>
      </Stack>
      <Collapse in={generatedUrl != null}>
        <ReactAudioPlayer src={generatedUrl} controls preload="none" />
      </Collapse>
    </Stack>
  );
};
