import React from 'react';
import {BigPlayButton, Player} from 'video-react';
import {Loader} from '../loader';
import {mediaApi} from '../../api';

class CriticalEventMediaPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      mediumUrl: null,
    };
  }

  componentDidMount = async () => {
    const {row} = this.props;
    const medium = await mediaApi.apiMediaMediaIdUrlGet({mediaId: row.id});
    this.setState({mediumUrl: medium.url, loading: false});
  };

  render() {
    const {loading, mediumUrl} = this.state;

    if (loading) {
      return <Loader />;
    }
    return (
      <Player>
        <source src={mediumUrl} />
        <BigPlayButton position="center" />
      </Player>
    );
  }
}

export default CriticalEventMediaPlayer;
