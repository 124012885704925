import React from 'react';
import {
  listingSchema,
  TemplateCard,
  TemplateForm,
} from '../../factory/template';
import {PlanningSkillContext} from './PlanningSkillContext';
import {Contract, PlanningSkill} from '@onroadvantage/onroadvantage-api';
import * as Yup from 'yup';
import {FormikAutocompleteWithListing, FormikTextField} from '../formik';
import {AutocompleteOptionType} from '../autocomplete';

export interface IPlanningSkillForm {
  name: string;
  description: string;
  contract?: AutocompleteOptionType | null;
}

const schema: Yup.SchemaOf<IPlanningSkillForm> = Yup.object({
  name: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  contract: listingSchema.nullable().required('Required'),
});

const getInitialValues = (
  planningSkill?: PlanningSkill | null,
  contract?: Contract | undefined
): IPlanningSkillForm | undefined => {
  if (planningSkill) {
    return {
      name: planningSkill.name ?? '',
      description: planningSkill.description ?? '',
      contract: contract ? {value: contract.id, label: contract.name} : null, //TODO Add contract name/code/label to PlanningSkill schema?
    };
  }
  return undefined;
};

export const PlanningSkillForm: React.FC<{isAdd?: boolean}> = ({isAdd}) => {
  const {
    detailsRef,
    loading,
    submitting,
    planningSkill,
    onDetailsFormSubmit,
    contract,
  } = React.useContext(PlanningSkillContext);
  const [initialValues, setInitialValues] =
    React.useState<IPlanningSkillForm>();

  React.useEffect(() => {
    const values = getInitialValues(planningSkill, contract);

    if (values) {
      setInitialValues(values);
    } else if (isAdd) {
      setInitialValues({
        name: '',
        description: '',
      });
    }
  }, [contract, isAdd, planningSkill, setInitialValues]);

  return (
    <TemplateCard
      title={`Planning Skill Details ${isAdd ? 'Add' : 'Edit'}`}
      loading={loading}
    >
      <TemplateForm<IPlanningSkillForm>
        initialValues={initialValues}
        onSubmit={onDetailsFormSubmit}
        permission={{
          name: `${isAdd ? 'Add' : 'Edit'} PlanningSkill`,
          type: isAdd ? 'Add' : 'Edit',
        }}
        submitting={submitting}
        innerRef={detailsRef}
        validationSchema={schema}
      >
        <FormikTextField
          id="name"
          name="name"
          placeholder="Enter the planning skill name"
          label="Planning Skill Name"
          fullWidth
        />
        <FormikTextField
          id="description"
          name="description"
          placeholder="Enter the planning skill description"
          label="Planning Skill Description"
          fullWidth
        />
        <FormikAutocompleteWithListing
          id="contract"
          name="contract"
          label="Contract"
          model="Contract"
          placeholder="Enter and select a value"
        />
      </TemplateForm>
    </TemplateCard>
  );
};
