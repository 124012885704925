import React from 'react';
import {DataTypeProvider} from '@devexpress/dx-react-grid';
import {TableActionButtonBase} from './TableActionButtonBase';
import {useTemplateTableActions} from '../../providers';
import {Save, Close} from '@mui/icons-material';
import {useInternalTableContext} from '../../TemplateTable';

export const TableActionButtonSave: React.FC<
  DataTypeProvider.ValueFormatterProps
> = ({row}) => {
  const {
    saveChangesHandler,
    setEditingRowsHandler,
    rowChanges,
    addRowHandler,
    addedRows,
  } = useTemplateTableActions();
  const {rowInitialValues} = useInternalTableContext();

  const isChanged = React.useMemo(
    () =>
      !!(
        (row.id &&
          rowChanges &&
          rowInitialValues &&
          Object.keys(rowChanges)
            .map((key) =>
              Object.keys(rowInitialValues[key])
                .map(
                  (valueKey) =>
                    rowChanges[key][valueKey] !== undefined &&
                    rowInitialValues[key][valueKey]?.toString() !==
                      rowChanges[key][valueKey]
                )
                .includes(true)
            )
            .includes(true)) ||
        (!row.id &&
          addedRows &&
          addedRows.length > 0 &&
          addedRows
            ?.map((row) =>
              Object.keys(row)
                .map((key) => row[key] !== '')
                .includes(true)
            )
            .includes(true))
      ),
    [addedRows, row.id, rowChanges, rowInitialValues]
  );

  const handleCancel = React.useCallback(() => {
    if (addedRows && addedRows.length > 0 && addRowHandler) addRowHandler();
    else if (setEditingRowsHandler) setEditingRowsHandler(row);
  }, [addRowHandler, addedRows, row, setEditingRowsHandler]);

  const handleSave = React.useCallback(() => {
    if (saveChangesHandler) saveChangesHandler();
  }, [saveChangesHandler]);

  if (!saveChangesHandler) {
    return null;
  }

  return (
    <>
      <TableActionButtonBase
        data-cy="TemplateTableActionsSave"
        onClick={handleSave}
        disabled={!isChanged}
      >
        <Save />
      </TableActionButtonBase>
      <TableActionButtonBase
        data-cy="TemplateTableActionsCancel"
        onClick={handleCancel}
      >
        <Close />
      </TableActionButtonBase>
    </>
  );
};
