import React from 'react';
import {NotificationConfigCriticalEventContext} from './NotificationConfigCriticalEventContext';
import {
  TemplateTable,
  TemplateTableConfig,
  TOnInlineAdd,
} from '../../../factory/template';
import {
  Listing,
  VehicleGroupContactGroup,
} from '@onroadvantage/onroadvantage-api';
import {eventNotificationConfigApi} from '../../../api';
import {useAppNotifications} from '../../../contexts';
import {RoleService} from '../../../service';

export const NotificationConfigCriticalEventVehicleGroupContacts: React.FC =
  () => {
    const notify = useAppNotifications();
    const {
      notificationConfigEvent,
      notificationConfigEventId,
      loading,
      loadNotificationConfigEvent,
    } = React.useContext(NotificationConfigCriticalEventContext);
    const [localLoading, setLocalLoading] = React.useState<boolean>(false);

    const [config] = React.useState<
      TemplateTableConfig<VehicleGroupContactGroup>
    >({
      columns: [
        {
          name: 'contactGroup',
          label: 'Contact Group Name',
          type: 'string',
          model: 'VehicleGroupContactGroup',
          enableEditing: true,
          enableAutocomplete: true,
          enableMulti: true,
          getValue: ({contactGroup}) => contactGroup?.name,
        },
        {
          name: 'vehicleGroup',
          label: 'Vehicle Group Name',
          type: 'string',
          enableEditing: false,
          getValue: ({vehicleGroup}) => vehicleGroup?.name,
        },
      ],
      disableToolbar: true,
      disablePagination: true,
      deleteMessage: (row) => ({
        title: 'Notification Config Vehicle Group Contact',
        items: {
          ContactGroup: row.contactGroup?.name,
          VehicleGroup: row.vehicleGroup?.name,
        },
      }),
      identifier:
        'CRITICAL_EVENT_NOTIFICATION_CONFIG_VEHICLE_GROUP_CONTACTS_LIST',
    });

    const handleInlineAdd = React.useCallback<TOnInlineAdd>(
      async (changes) => {
        setLocalLoading(true);
        try {
          for (const change of changes) {
            const vehicleGroupContactListings: Listing[] = change.contactGroup;
            const vehicleGroupContactIds = vehicleGroupContactListings
              .filter(({value}) => value)
              .map(({value}) => value as number);
            if (notificationConfigEventId) {
              await eventNotificationConfigApi.apiEventNotificationConfigEventNotificationConfigIdAssignVehicleGroupContactsPost(
                {
                  eventNotificationConfigId: notificationConfigEventId,
                  body: {vehicleGroupContactIds},
                }
              );
              notify('success', 'Successfully assigned vehicle group contact');
              await loadNotificationConfigEvent();
            }
          }
        } catch (e) {
          notify(
            'error',
            e.message ?? 'Failed to assign vehicle group contact'
          );
        } finally {
          setLocalLoading(false);
        }
      },
      [loadNotificationConfigEvent, notificationConfigEventId, notify]
    );

    const handleInlineDelete = React.useCallback(
      async (row: VehicleGroupContactGroup) => {
        setLocalLoading(true);
        try {
          if (notificationConfigEventId && row.id) {
            await eventNotificationConfigApi.apiEventNotificationConfigEventNotificationConfigIdUnassignVehicleGroupContactPost(
              {
                eventNotificationConfigId: notificationConfigEventId,
                body: {vehicleGroupContactId: row.id},
              }
            );
            notify('success', 'Successfully unassigned vehicle group contact');
            await loadNotificationConfigEvent();
          }
        } catch (e) {
          notify(
            'error',
            e.message ?? 'Failed to unassign vehicle group contact'
          );
        } finally {
          setLocalLoading(false);
        }
      },
      [loadNotificationConfigEvent, notificationConfigEventId, notify]
    );

    return (
      <TemplateTable
        config={config}
        list={notificationConfigEvent?.vehicleGroupContactGroups ?? []}
        currentPage={1}
        loading={loading || localLoading}
        onDelete={
          RoleService.hasPermission('Edit NotificationConfig', 'Edit')
            ? handleInlineDelete
            : undefined
        }
        onInlineAdd={
          RoleService.hasPermission('Edit NotificationConfig', 'Edit')
            ? handleInlineAdd
            : undefined
        }
      />
    );
  };
