import React from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {Theme, Typography, Link} from '@mui/material';
import {makeStyles, WithStyles} from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Paper from '@mui/material/Paper';
import {observer} from 'mobx-react';
import _ from 'lodash';
import {Route, RouteComponentProps} from 'react-router-dom';
import {ApiPlanningSolutionGetRequest} from '@onroadvantage/onroadvantage-api';
import moment from 'moment';

import {
  PlanningSolutionListProvider,
  usePlanningSolutionListData,
  PlanningSolutionProvider,
  usePlanningSolutionData,
  PlanningSolutionViewProvider,
  usePlanningSolutionViewStore,
} from '../../stores/context';
import {PlanningAdd} from './planningAdd';
import {PlanningSolutionController} from './planningSolution';
import {webPlanningSolutionApi} from '../../api';
import {PlanningSolutionList} from '../index';
import {connectDateRange} from '../../contexts';

const PlanningControllerStyles = (theme: Theme) =>
  createStyles({
    breadcrumbs: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      marginBottom: theme.spacing(2),

      borderBottomColor: theme.palette.divider,
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
    },
    backButton: {
      height: 15,
      width: 15,
      marginRight: theme.spacing(2),
    },
    container: {
      minHeight: 10,
    },
    root: {},
  });

const usePlanningControllerStyles = makeStyles(PlanningControllerStyles, {
  name: 'PlanningControllerStyles',
});

interface IParams {
  id?: string;
}

// @ts-ignore
interface IProps extends RouteComponentProps<IParams>, WithStyles<any> {
  classes: ReturnType<typeof PlanningControllerStyles>;
  endDate?: Date;
  startDate?: Date;
}

const PlanningControllerComponent = observer(
  ({location, history, startDate, endDate}: IProps) => {
    const classes = usePlanningControllerStyles();
    // solution list data
    const {
      // values
      filters,
      page,
      perPage,
      refreshTrigger,
      // actions
      setPages,
      setTotalCount,
      setListItems,
    } = usePlanningSolutionListData((store) => ({
      // values
      filters: store.filters,
      page: store.currentPage,
      perPage: store.perPage,
      refreshTrigger: store.refreshTrigger,
      // actions
      setPages: store.setPages,
      setTotalCount: store.setTotalCount,
      setListItems: store.setListItems,
    }));

    // solution data
    const {planningSolution} = usePlanningSolutionData((store) => ({
      planningSolution: store.planningSolution,
    }));

    // view data
    const {setIsListLoading} = usePlanningSolutionViewStore((store) => ({
      setIsListLoading: store.setListLoading,
    }));

    const loadListData = React.useCallback(
      async ({name, contractCode}: ApiPlanningSolutionGetRequest) => {
        setIsListLoading(true);
        // const name = _(filters)
        //   .chain()
        //   .find((i) => i.columnName === 'name')
        //   .get('value')
        //   .value();
        // const contractCode = _(filters)
        //   .chain()
        //   .find((i) => i.columnName === 'contract')
        //   .get('value')
        //   .value();
        try {
          const response =
            await webPlanningSolutionApi.apiWebPlanningSolutionGet({
              // TODO add pagination to API
              name,
              contractCode,
              page,
              perPage,
              startDate: moment(startDate).toDate(),
              endDate: moment(endDate).toDate(),
            });
          response.items && setListItems(response.items);
          response.pages && setPages(response.pages);
          response.total && setTotalCount(response.total);
        } catch (e) {
          // TODO handle
        } finally {
          setIsListLoading(false);
        }
      },
      [
        // filters,
        page,
        perPage,
        endDate,
        startDate,
        setIsListLoading,
        setListItems,
        setPages,
        setTotalCount,
      ]
    );

    React.useEffect(() => {
      let aborted = false;
      let timerId: NodeJS.Timeout | null = null;
      const name = _(filters)
        .chain()
        .find((i) => i.columnName === 'name')
        .get('value')
        .value();
      const contractCode = _(filters)
        .chain()
        .find((i) => i.columnName === 'contract')
        .get('value')
        .value();
      if (refreshTrigger) {
        // simply do nothing as it is only to be used as an observable value
      }

      // TODO sorting
      if (name || contractCode) {
        timerId = setTimeout(() => {
          if (!aborted) {
            loadListData({name, contractCode});
          }
        }, 500);
      } else {
        loadListData({});
      }

      return () => {
        aborted = true;
        if (timerId) {
          clearTimeout(timerId);
        }
      };
    }, [filters, loadListData, refreshTrigger]);

    // get path names
    const pathnames = location.pathname.split('/').filter((x) => x);

    /** breadcrumb paths */
    const paths: Array<React.ReactElement> = [];
    pathnames.forEach((p, idx, arr) => {
      // TODO document logic
      switch (idx) {
        case 0:
          paths.push(
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <Link
              key="/planningsolutionlist"
              onClick={() => history.push('/planningsolutionlist')}
              color="inherit"
              style={{cursor: 'pointer'}}
            >
              Solution List
            </Link>
          );
          break;
        case 1:
          if (p === 's') {
            let path = `/planningsolutionlist/s/${arr[2]}`;
            if (arr.length >= 4 && arr[3] === 't') {
              path = `/planningsolutionlist/s/${arr[2]}?resetForm=1`;
            }

            const title = _.get(planningSolution, 'name', `Solution ${arr[2]}`);
            if (arr.length < 4) {
              paths.push(<Typography key={path}>{title}</Typography>);
            } else {
              paths.push(
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <Link
                  key={path}
                  onClick={() => history.push(path)}
                  color="inherit"
                  style={{cursor: 'pointer'}}
                >
                  {title}
                </Link>
              );
            }
          } else if (p === 'add') {
            paths.push(
              <Typography key="add-solution">New Solution</Typography>
            );
          }
          break;
        case 2:
          break;
        case 3:
          if (p === 't') {
            const path = `/planningsolutionlist/s/${arr[2]}/t/${arr[4]}`;
            let title = 'new';
            if (+arr[4] !== -1) {
              const planningMasterTrip = _.find(
                planningSolution?.planningMasterTrips,
                ['trip.id', +arr[4]]
              );
              if (planningMasterTrip && planningMasterTrip.tripNumber) {
                title = `Trip ID: ${arr[4]} > ${planningMasterTrip.tripNumber}`;
              }
            }
            if (arr.length < 6) {
              paths.push(<Typography key={path}>{title}</Typography>);
            } else {
              paths.push(
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <Link
                  key={path}
                  onClick={() => history.push(path)}
                  color="inherit"
                  style={{cursor: 'pointer'}}
                >
                  {arr[4]}
                </Link>
              );
            }
          }
          break;
        case 4:
          break;
        case 5:
          break;
        case 6:
          break;
        default:
          break;
      }
    });

    return (
      <div className={classes.root}>
        {pathnames.length > 1 && (
          <Paper className={classes.breadcrumbs}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
              {paths}
            </Breadcrumbs>
          </Paper>
        )}
        <Paper className={classes.container}>
          <Route
            exact
            path="/planningsolutionlist"
            component={PlanningSolutionList}
          />
          <Route
            exact
            path="/planningsolutionlist/add"
            component={PlanningAdd}
          />
          <Route
            path="/planningsolutionlist/s/:id"
            component={PlanningSolutionController}
          />
        </Paper>
      </div>
    );
  }
);

export const PlanningController = connectDateRange((props: IProps) => (
  <PlanningSolutionListProvider>
    <PlanningSolutionProvider>
      <PlanningSolutionViewProvider>
        <PlanningControllerComponent {...props} />
      </PlanningSolutionViewProvider>
    </PlanningSolutionProvider>
  </PlanningSolutionListProvider>
));
