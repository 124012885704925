import React from 'react';
import {FormikProps} from 'formik/dist/types';
import {connect} from 'formik';
import {Listing} from '@onroadvantage/onroadvantage-api';
import {IPlanningAddFormValues} from './PlanningAdd';
import {PlanningAddBoard} from './PlanningAddBoard';
import {listingToLaneData} from '../../../service/laneUtils';
import {ILaneCardData} from '../../../stores/PlanningSolutionStore';

interface IFormikProps {
  formik: FormikProps<IPlanningAddFormValues>;
}

interface Props {
  vehicles: Array<Listing>;
}

export const PlanningAddVehiclesComponent = ({
  formik,
  vehicles,
}: Props & IFormikProps) => {
  const all = listingToLaneData(vehicles);
  const allocated = listingToLaneData(
    vehicles.filter((o) => {
      if (o && o.value) {
        return formik.values.allocatedVehicles.indexOf(o.value) !== -1;
      }
      return false;
    })
  );
  const available = listingToLaneData(
    vehicles.filter((o) => {
      if (o && o.value) {
        return formik.values.allocatedVehicles.indexOf(o.value) === -1;
      }

      return false;
    })
  );

  const handleAllocatedChange = (values: ILaneCardData[]) => {
    formik.setFieldValue(
      'allocatedVehicles',
      values.map((v) => parseInt(`${v.id}`, 10))
    );
  };

  return (
    <PlanningAddBoard
      all={all}
      allocated={allocated}
      available={available}
      title="Allocation of Vehicles"
      updateAllocated={handleAllocatedChange}
      updateAvailable={() => null}
    />
  );
};

export const PlanningAddVehicles = connect<Props, IPlanningAddFormValues>(
  PlanningAddVehiclesComponent
);
