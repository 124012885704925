import React from 'react';
import {SiteContext} from './SiteContext';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../factory/template';
import {WebNodeListDump} from '@onroadvantage/onroadvantage-api';
import {Chip} from '@mui/material';
import {MomentService} from '../../service';

export const SiteList: React.FC = () => {
  const {loadList, cleanupList} = React.useContext(SiteContext);

  const [config] = React.useState<
    TemplateTableConfig<WebNodeListDump & {openTimes: React.ReactNode}>
  >({
    columns: [
      {name: 'name', label: 'Name', type: 'string'},
      {name: 'externalReference', label: 'External Reference', type: 'string'},
      {name: 'type', label: 'Type', type: 'string'},
      {name: 'latitude', label: 'Latitude', type: 'number'},
      {name: 'longitude', label: 'Longitude', type: 'number'},
      {
        name: 'loadingTimeInMinutes',
        label: 'Loading Time',
        type: 'number',
        enableFilter: false,
      },
      {
        name: 'unloadingTimeInMinutes',
        label: 'Unloading Time',
        type: 'number',
        enableFilter: false,
      },
      {
        name: 'processingTimeInMinutes',
        label: 'Processing Time',
        type: 'number',
        enableFilter: false,
      },
      {
        name: 'openTimes',
        label: 'Open Times',
        type: 'string',
        enableFilter: false,
        getValue: ({timeWindows}) =>
          timeWindows?.map((timeWindow, index) => (
            <Chip
              key={index}
              label={`${MomentService.localiseTimeOnly(
                timeWindow.openTime
              )} - ${MomentService.localiseTimeOnly(timeWindow.closeTime)}`}
            />
          )),
      },
      {
        name: 'createdAt',
        label: 'Created At',
        type: 'datetime',
        enableFilter: false,
      },
      {
        name: 'telematicsConfigId' as 'telematicsConfig',
        label: 'Telematics Config',
        type: 'number',
        hidden: true,
        getValue: ({telematicsConfig}) => telematicsConfig?.id,
      },
      {
        name: 'providerName' as 'telematicsConfig',
        label: 'Provider Name',
        type: 'string',
        hidden: true,
        getValue: ({telematicsConfig}) => telematicsConfig?.providerName,
      },
      {
        name: 'mixOrganisationGroupName' as 'telematicsConfig',
        label: 'Group Name',
        type: 'string',
        hidden: true,
        getValue: ({telematicsConfig}) =>
          telematicsConfig?.mixOrganisationGroupName,
      },
    ],
    enableFilter: true,
    enableSort: true,
    deleteMessage: (row) => ({
      title: 'Site',
      items: {Name: row.name, ExternalReference: row.externalReference},
    }),
    identifier: 'SITE_LIST',
  });

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [cleanupList, loadList]);

  return <TemplateTableWrapper config={config} context={SiteContext} />;
};
