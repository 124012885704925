import './templateTable.css';
import React from 'react';
import {
  ColumnChooser,
  DragDropProvider,
  Grid,
  GroupingPanel,
  PagingPanel,
  Table,
  TableColumnReordering,
  TableColumnResizing,
  TableColumnVisibility,
  TableEditRow,
  TableFilterRow,
  TableFixedColumns,
  TableGroupRow,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  Toolbar,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import {
  ChangeSet,
  Column,
  CustomPaging,
  EditingState,
  Filter,
  FilteringState,
  Grouping,
  GroupingState,
  IntegratedGrouping,
  IntegratedSelection,
  PagingState,
  RowDetailState,
  SelectionState,
  Sorting,
  SortingState,
  TableColumnWidthInfo,
} from '@devexpress/dx-react-grid';
import {useTemplateTableStyles} from './TemplateTable.style';
import {Paper} from '@mui/material';
import {
  getColumnNamesByType,
  getColumnVisibility,
  getColumnWidths,
  setColumnVisibility,
} from './TemplateTableHelpers';
import * as Yup from 'yup';
import {
  ActionsTypeProvider,
  DateTimeTypeProvider,
  DateTypeProvider,
  LinkTypeProvider,
  ProgressTypeProvider,
  BooleanTypeProvider,
  rowChangesType,
} from './providers';
import {
  MapMessage,
  TableCell,
  TableCellNoData,
  TableComponent,
  TableContainerComponent,
  TableFilterRowCell,
  TableFilterRowComponent,
  TableFixedColumnsCell,
  TableHeaderRowCell,
  TablePagingPanelContainer,
  TableRow,
  TableToolbarActivityIndicator,
  TableToolbarDaterangeFilterButton,
  TableToolbarRefreshButton,
  TableToolbarRoot,
  TOnRightClick,
  TableToolbarCustomAction,
  TableToolbarInfo,
  TableStubCell,
  TableHeaderRowComponent,
  TableEditField,
  TableRowDetailCell,
  TableSelectionRow,
} from './components';
import clsx from 'clsx';
import {Listing} from '@onroadvantage/onroadvantage-api';
import {SortableContainer, SortableContainerProps} from 'react-sortable-hoc';

export type TColumnType =
  | 'string'
  | 'number'
  | 'date'
  | 'datetime'
  | 'time'
  | 'bool'
  | 'link'
  | 'progress';

export type TemplateTableDefault = {id?: string | null | number};

export interface IColumnConfig<GenericData extends TemplateTableDefault> {
  name: keyof GenericData | string;
  label: string;
  type: TColumnType;
  hidden?: boolean;
  enableFilter?: boolean;
  enableSort?: boolean;
  enableEditing?: ((i?: GenericData) => boolean) | boolean;
  enableInlineAdding?: ((i?: GenericData) => boolean) | boolean;
  options?: Listing[] | undefined;
  selectOptions?: string[] | undefined;
  enableSelect?: boolean;
  enableAutocomplete?: boolean;
  enableMulti?: boolean;
  filterListingsByLabel?: string[];
  model?: string | string[];
  getLabel?: (i: GenericData) => string | undefined;
  getValue?: (
    i: GenericData
  ) => string | number | boolean | Date | React.ReactNode | undefined;
  getFieldValue?: (
    i: GenericData
  ) =>
    | string
    | number
    | boolean
    | Date
    | Listing
    | Listing[]
    | null
    | undefined;
}

export interface ITemplateTableConfigDeleteMessage {
  title: string;
  items: {[key: string]: React.ReactNode};
}

export interface IActionsColumnConfig {
  width: number;
}

export interface TemplateTableConfig<GenericData extends TemplateTableDefault> {
  columns: IColumnConfig<GenericData>[];
  actionsColumn?: IActionsColumnConfig;
  enableFilter?: boolean;
  disablePagination?: boolean;
  disableSelectAll?: boolean;
  disableToolbar?: boolean;
  enableGrouping?: boolean;
  enableSort?: boolean;
  enableRowSorting?: boolean;
  deleteMessage?: (row: GenericData) => ITemplateTableConfigDeleteMessage;
  mapMessage?: MapMessage<GenericData>;
  mapMessageTitle?: MapMessage<GenericData>;
  getSelectableRow?: (i: GenericData) => boolean;
  selectionType?: 'row' | 'radio' | 'checkbox' | undefined;
  getRowClass?: (i: GenericData) => string | undefined;
  identifier?: string;
}

export interface IError<GenericData extends TemplateTableDefault> {
  id: string | number | undefined;
  name: keyof GenericData;
  message: string;
}

export type TOnInlineEdit = (
  savedChanges: {id: string; newValues: {[key: string]: any}}[]
) => void;

export type TOnInlineAdd = (changes: {[key: string]: any}[]) => void;

export type TOnDragStart<GenericData> = (
  event: React.DragEvent<HTMLTableRowElement> & {target: {id: any}},
  row: GenericData
) => void;

export type TOnSelectionChange<GenericData> = (
  selection: GenericData[]
) => void;

interface ITemplateTableClasses {
  container?: string;
  rowDetail?: string;
  paper?: string;
  root?: string;
}

export interface onWarningResponse {
  visible: boolean;
  message: string;
}

export type TOnWarning<T = any> = (row: T) => onWarningResponse;

export interface TemplateTableProps<GenericData extends TemplateTableDefault> {
  config: TemplateTableConfig<GenericData>;
  currentPage: number;
  classes?: ITemplateTableClasses;
  cleanupList?: () => void;
  filters?: Filter[];
  grouping?: Grouping[];
  isDaterangeFilterActive?: boolean;
  itemTotal?: number;
  list: GenericData[];
  loading?: boolean;
  loadingMessage?: string;
  elevation?: number;
  rowDetail?: React.FC<{row: GenericData}>;
  toolbarCustomAction?: React.ReactNode;
  toolbarInfo?: React.ReactNode;
  onAdd?: (
    event: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>
  ) => void;
  onInlineAdd?: TOnInlineAdd;
  addInitial?: Partial<GenericData>;
  addValidation?: Yup.ObjectSchema<any>;
  editValidation?: Yup.ObjectSchema<any>;
  nestedLevel?: 0 | 1 | 2 | 3;
  onAuxNavigate?: (row: GenericData) => void;
  onCollapse?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onCurrentPageChange?: (value: number) => void;
  onDateRangeFilterToggle?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  onDelete?: (row: GenericData) => void;
  onDownload?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onDownloadItem?: (row: GenericData) => void;
  onDragStart?: TOnDragStart<GenericData>;
  onWarning?: TOnWarning;
  onRightClick?: TOnRightClick;
  onFiltersChange?: (filters: Filter[]) => void;
  onGroupingChange?: (grouping: Grouping[]) => void;
  onNavigate?: (row: GenericData) => void;
  onEnableRowEdit?: (row: GenericData) => boolean;
  onInlineEdit?: TOnInlineEdit;
  onInlineEditAll?: TOnInlineEdit;
  onPageSizeCountsChange?: (value: number) => void;
  onRefresh?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onSortingChange?: (sorting: Sorting[]) => void;
  pageSize?: number;
  pageSizeCounts?: number[];
  pageTotal?: number;
  scrollable?: boolean;
  sortColumns?: SortingState.ColumnExtension[];
  sorting?: Sorting[];
  selection?: GenericData[];
  onSelectionChange?: TOnSelectionChange<GenericData>;
  rowComponent?: React.FC<Table.DataRowProps>;
  sortableProps?: SortableContainerProps;
}

export interface InternalTableContextProps<
  GenericData extends TemplateTableDefault
> {
  config: TemplateTableProps<GenericData>['config'];
  classes: TemplateTableProps<GenericData>['classes'];
  list: TemplateTableProps<GenericData>['list'];
  errors: IError<GenericData>[];
  nestedLevel?: TemplateTableProps<GenericData>['nestedLevel'];
  selection: TemplateTableProps<GenericData>['selection'];
  rowInitialValues?: rowChangesType<GenericData>;
  updateRowInitialValues: (id: string | number, value: GenericData) => void;
  onDragStart?: TemplateTableProps<GenericData>['onDragStart'];
  onRightClick?: TOnRightClick;
  loading?: boolean;
  loadingMessage?: string;
}

function createInternalTableContext<T = any>() {
  return React.createContext<InternalTableContextProps<T>>({
    config: {columns: []},
    classes: undefined,
    list: [],
    selection: [],
    updateRowInitialValues: () => null,
    errors: [],
  });
}

const InternalTableContext = createInternalTableContext();

export const useInternalTableContext = () =>
  React.useContext(InternalTableContext);

export const TemplateTable = <TableGenericData extends TemplateTableDefault>({
  config,
  currentPage,
  classes: classesProp,
  filters,
  grouping,
  isDaterangeFilterActive,
  onDateRangeFilterToggle,
  itemTotal,
  list,
  loading,
  loadingMessage: loadingMessageProp,
  rowDetail,
  toolbarCustomAction,
  toolbarInfo,
  onAdd,
  addValidation,
  editValidation,
  nestedLevel,
  onInlineAdd,
  addInitial = {},
  onAuxNavigate,
  onCurrentPageChange,
  onDelete,
  onDownload,
  onDownloadItem,
  onDragStart,
  onWarning,
  onRightClick,
  onFiltersChange,
  onGroupingChange,
  onNavigate,
  onEnableRowEdit,
  onInlineEdit,
  onInlineEditAll,
  onPageSizeCountsChange,
  onRefresh,
  onSortingChange,
  pageSize,
  pageSizeCounts,
  pageTotal,
  scrollable,
  sorting,
  elevation,
  selection,
  onSelectionChange,
  rowComponent,
  sortableProps,
}: TemplateTableProps<TableGenericData>): React.ReactElement | null => {
  const classes = useTemplateTableStyles();

  const [columns, setColumns] = React.useState<Column[]>([]);
  const [filterColumns, setFilterColumns] = React.useState<
    FilteringState.ColumnExtension[]
  >([]);
  const [filterRowEnabled, setFilterRowEnabled] =
    React.useState<boolean>(false);
  const [groupingEnabled, setGroupingEnabled] = React.useState<boolean>(false);
  const [hiddenColumns, setHiddenColumns] = React.useState<Array<string>>([]);
  const [sortColumns, setSortColumns] = React.useState<
    SortingState.ColumnExtension[]
  >([]);
  const [editingStateColumns, setEditingStateColumns] =
    React.useState<EditingState.ColumnExtension[]>();
  const [sortEnabled, setSortEnabled] = React.useState<boolean>(false);
  const [paginationDisabled, setPaginationDisabled] =
    React.useState<boolean>(false);
  const [toolbarDisabled, setToolbarDisabled] = React.useState<boolean>(false);
  const [initialising, setInitialising] = React.useState<boolean>(true);
  const [columnWidths, setColumnWidths] = React.useState<
    Array<TableColumnWidthInfo>
  >(getColumnWidths(columns));
  const [amountOfActions, setAmountOfActions] = React.useState(0);
  const [pageSizes, setPageSizes] = React.useState<Array<number>>();
  const [errors, setErrors] = React.useState<IError<TableGenericData>[]>([]);

  const loadingMessage = React.useMemo(
    () => loadingMessageProp,
    [loadingMessageProp]
  );

  // column types
  const [dateColumns] = React.useState<Array<string>>(
    getColumnNamesByType(config.columns, 'date')
  );
  const [dateTimeColumns] = React.useState<Array<string>>(
    getColumnNamesByType(config.columns, 'datetime')
  );
  const [progressColumns] = React.useState<Array<string>>(
    getColumnNamesByType(config.columns, 'progress')
  );
  const [booleanColumns] = React.useState<Array<string>>(
    getColumnNamesByType(config.columns, 'bool')
  );
  const [linkColumns] = React.useState<Array<string>>(
    getColumnNamesByType(config.columns, 'link')
  );

  const [expandedRowIds, setExpandedRowIds] = React.useState<
    (number | string)[]
  >([]);

  const [addedRows, setAddedRows] = React.useState<any[]>([]);
  const [editingRowIds, setEditingRowIds] = React.useState<(number | string)[]>(
    []
  );
  const [rowChanges, setRowChanges] =
    React.useState<rowChangesType<TableGenericData>>();
  const [rowInitialValues, setRowInitialValues] =
    React.useState<rowChangesType<TableGenericData>>();

  // Effects
  React.useEffect(() => {
    const actionColumn = {
      name: 'actions',
      title: 'Actions',
    };
    const columnMap = config.columns.map((i) => ({
      name: i.name as string,
      title: i.label,
      getCellValue: i.getValue ?? undefined,
    }));
    if (
      onAdd ||
      onDownload ||
      onNavigate ||
      onAuxNavigate ||
      onDelete ||
      onInlineAdd ||
      onInlineEdit ||
      onInlineEditAll ||
      rowDetail ||
      onDownloadItem ||
      selection ||
      onDragStart ||
      onWarning
    ) {
      setColumns([actionColumn, ...columnMap]);
    } else {
      setColumns(columnMap);
    }
  }, [
    config.columns,
    onAdd,
    onAuxNavigate,
    onDelete,
    onDownload,
    onDownloadItem,
    onDragStart,
    onInlineEdit,
    onInlineEditAll,
    onNavigate,
    onWarning,
    selection,
    rowDetail,
    setColumns,
    onInlineAdd,
  ]);

  // filter columns
  React.useEffect(() => {
    const actionColumn = {columnName: 'actions', filteringEnabled: false};
    const filterMap = config.columns.map((i) => ({
      columnName: i.name as string,
      filteringEnabled: config.enableFilter ? i.enableFilter ?? true : false,
    }));
    setFilterColumns([actionColumn, ...filterMap]);
  }, [config.columns, config.enableFilter, setFilterColumns]);

  // sort columns
  React.useEffect(() => {
    const actionColumn = {columnName: 'actions', sortingEnabled: false};
    const sortMap = config.columns.map((i) => ({
      columnName: i.name as string,
      sortingEnabled: i.enableSort ?? true,
    }));
    setSortColumns([actionColumn, ...sortMap]);
  }, [config.columns, setSortColumns]);

  // editing columns
  React.useEffect(() => {
    setEditingStateColumns(
      config.columns.map((column) => ({
        columnName: column.name as string,
        editingEnabled:
          column.enableEditing !== undefined
            ? typeof column.enableEditing === 'function'
              ? column.enableEditing()
              : column.enableEditing
            : true,
      }))
    );
  }, [config.columns, setEditingStateColumns]);

  // enable filter row
  React.useEffect(() => {
    setFilterRowEnabled(
      (config.enableFilter ?? false) ||
        onAdd !== undefined ||
        onInlineAdd !== undefined ||
        onInlineEditAll !== undefined ||
        onDownload !== undefined ||
        (selection !== undefined && !config.disableSelectAll)
    );
  }, [
    config.enableFilter,
    config.disableSelectAll,
    onAdd,
    onDownload,
    onInlineAdd,
    onInlineEditAll,
    selection,
    setFilterRowEnabled,
  ]);

  React.useEffect(() => {
    setGroupingEnabled(config.enableGrouping ?? true);
  }, [config.enableGrouping, setGroupingEnabled]);

  // sorting enabled
  React.useEffect(() => {
    setSortEnabled(config.enableSort ?? false);
  }, [config.enableSort, setSortEnabled]);

  // pagination enabled
  React.useEffect(() => {
    setPaginationDisabled(config.disablePagination ?? false);
  }, [config.disablePagination, setPaginationDisabled]);

  // toolbar enabled
  React.useEffect(() => {
    setToolbarDisabled(config.disableToolbar ?? false);
  }, [config.disableToolbar, setToolbarDisabled]);

  React.useEffect(() => {
    /**
     * This is to calculate the amount of actions buttons within the action column exist
     */
    let actionCount = 0;
    let inlineActionCount = 0;
    (onAdd || onInlineAdd) && actionCount++;
    onDownload && actionCount++;
    onInlineEditAll && actionCount++;
    onDelete && inlineActionCount++;
    onDownloadItem && inlineActionCount++;
    (onNavigate || onAuxNavigate) && inlineActionCount++;
    rowDetail && inlineActionCount++;
    onInlineEdit && inlineActionCount++;
    onWarning && inlineActionCount++;
    onDragStart && inlineActionCount++;
    selection && inlineActionCount++;
    setAmountOfActions(
      inlineActionCount >= actionCount ? inlineActionCount : actionCount
    );
  }, [
    onInlineEditAll,
    onDelete,
    onDownloadItem,
    onInlineEdit,
    onNavigate,
    onAuxNavigate,
    rowDetail,
    onAdd,
    onDownload,
    onInlineAdd,
    onDragStart,
    onWarning,
    selection,
  ]);

  // column widths
  React.useEffect(() => {
    /**
     * This is to calculate the width of the actions columns based on the amount of items it contains
     */
    const widths: TableColumnWidthInfo[] = columns.map((c) => {
      if (c.name === 'actions') {
        if (config.actionsColumn?.width) {
          return {
            columnName: c.name,
            width: config.actionsColumn.width,
          };
        }
        return {
          columnName: c.name,
          width:
            ((onInlineAdd || onInlineEdit || onInlineEditAll) &&
            amountOfActions === 1
              ? 70
              : 25) +
            amountOfActions * 45,
        };
      }
      return {
        columnName: c.name,
        width: 'auto',
      };
    });
    setColumnWidths(widths);
  }, [
    amountOfActions,
    columns,
    config.actionsColumn,
    onInlineAdd,
    onInlineEdit,
    onInlineEditAll,
    onWarning,
    rowDetail,
    setColumnWidths,
  ]);

  // hidden columns
  React.useEffect(() => {
    const hiddenMap = getColumnVisibility(config.columns, config.identifier);
    setHiddenColumns(hiddenMap);
  }, [config.columns, config.identifier, setHiddenColumns]);

  // Initialising
  React.useEffect(() => {
    if (columns.length > 0 && columns.length === columnWidths.length) {
      setInitialising(false);
    }
  }, [columns, columnWidths.length]);

  // Page Sizes
  React.useEffect(() => {
    if (pageSizeCounts) {
      setPageSizes(pageSizeCounts);
    } else {
      setPageSizes([10, 25, 50, 100]);
    }
  }, [pageSizeCounts]);

  // Handlers
  const handleHiddenColumnNamesChange = (hiddenColumnNames: Array<string>) => {
    setColumnVisibility(hiddenColumnNames, config.identifier);
    setHiddenColumns(hiddenColumnNames);
  };

  const expandRowsHandler = React.useCallback((row: TableGenericData) => {
    if (row.id !== undefined && row.id !== null) {
      const id = typeof row.id === 'string' ? +row.id : row.id;

      setExpandedRowIds((prev) =>
        prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
      );
    }
  }, []);

  const setEditingRowsHandler = React.useCallback((row: TableGenericData) => {
    if (row.id !== undefined && row.id !== null) {
      const id = typeof row.id === 'string' ? +row.id : row.id;
      setEditingRowIds((prev) =>
        prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
      );
    }
  }, []);

  const addRowHandler = React.useCallback(() => {
    if (addedRows.length > 0) setAddedRows([]);
    else setAddedRows([addInitial]);
  }, [addInitial, addedRows]);

  const commitChanges: (row: ChangeSet) => void =
    React.useCallback(() => {}, []);

  // Format Saved changes
  const savedChanges: {id: string; newValues: TableGenericData}[] | undefined =
    React.useMemo(() => {
      if (rowChanges)
        return Object.keys(rowChanges).map((key) => {
          return {id: key, newValues: rowChanges[key]};
        });
    }, [rowChanges]);

  // Return Saved Changes for Edit and Add
  const saveChangesHandler = React.useCallback(async () => {
    if (addedRows.length > 0 && onInlineAdd) {
      try {
        await addValidation?.validate(addedRows[0]);
        onInlineAdd(addedRows);
        setErrors([]);
        setAddedRows([]);
      } catch (e) {
        setErrors((prevErrors) => [
          ...prevErrors,
          {id: undefined, name: e.path, message: e.message},
        ]);
      }
    }
    if (savedChanges && onInlineEdit) {
      const editValid = savedChanges.map(async ({id, newValues}) => {
        try {
          await editValidation?.validate(newValues);
          return true;
        } catch (e) {
          setErrors((prevErrors) => [
            ...prevErrors,
            {id, name: e.path, message: e.message},
          ]);
          return false;
        }
      });
      if (editValid) {
        onInlineEdit(savedChanges);
        setErrors([]);
        setRowChanges(undefined);
        setEditingRowIds([]);
      }
    }
    if (savedChanges && onInlineEditAll) {
      const editValid = savedChanges.map(async ({id, newValues}) => {
        try {
          await editValidation?.validate(newValues);
          return true;
        } catch (e) {
          setErrors((prevErrors) => [
            ...prevErrors,
            {id, name: e.path, message: e.message},
          ]);
          return false;
        }
      });
      if (editValid) {
        onInlineEditAll(savedChanges);
        setErrors([]);
        setRowChanges(undefined);
        setEditingRowIds([]);
      }
    }
  }, [
    addValidation,
    addedRows,
    editValidation,
    onInlineAdd,
    onInlineEdit,
    onInlineEditAll,
    savedChanges,
  ]);

  const updateRowInitialValues = React.useCallback(
    (id: string | number, value: TableGenericData) => {
      setRowInitialValues((prevValues) => ({
        ...prevValues,
        [id]: {...(prevValues && prevValues[id]), ...value},
      }));
    },
    [setRowInitialValues]
  );

  // ensure the table does not get displayed until initialised
  if (initialising) {
    return <div>Initialising...</div>;
  }

  return (
    <SortableProvider sortable={!!config.enableRowSorting} {...sortableProps}>
      <InternalTableContext.Provider
        value={{
          errors,
          config,
          classes: classesProp,
          list,
          nestedLevel,
          selection,
          loading,
          loadingMessage,
          onDragStart,
          onRightClick,
          rowInitialValues,
          updateRowInitialValues,
        }}
      >
        <Paper
          className={clsx(
            classesProp?.root ??
              (scrollable ? classes.rootScroll : classes.root),
            {
              [classes.nestedLevel1]: nestedLevel === 1,
              [classes.nestedLevel2]: nestedLevel === 2,
              [classes.nestedLevel3]: nestedLevel === 3,
            }
          )}
          elevation={elevation}
        >
          <Grid columns={columns} rows={list} getRowId={(row) => row.id}>
            {dateColumns.length > 0 && <DateTypeProvider for={dateColumns} />}
            {dateTimeColumns.length > 0 && (
              <DateTimeTypeProvider for={dateTimeColumns} />
            )}
            {progressColumns.length > 0 && (
              <ProgressTypeProvider for={progressColumns} />
            )}
            {booleanColumns.length > 0 && (
              <BooleanTypeProvider for={booleanColumns} />
            )}
            {linkColumns.length > 0 && <LinkTypeProvider for={linkColumns} />}
            <ActionsTypeProvider
              rowDetail={rowDetail}
              onAdd={onAdd}
              onAuxNavigate={onAuxNavigate}
              onDelete={onDelete}
              onDownload={onDownload}
              onDownloadItem={onDownloadItem}
              onDragStart={onDragStart}
              onWarning={onWarning}
              onNavigate={onNavigate}
              saveChangesHandler={saveChangesHandler}
              rowChanges={rowChanges}
              expandRowsHandler={expandRowsHandler}
              expandedRowIds={expandedRowIds}
              editingRowIds={editingRowIds}
              addedRows={addedRows}
              addRowHandler={addRowHandler}
              setEditingRowsHandler={setEditingRowsHandler}
              setEditingRowIds={setEditingRowIds}
              enableInlineAdd={onInlineAdd !== undefined}
              enableInlineEdit={onInlineEdit !== undefined}
              enableInlineEditAll={onInlineEditAll !== undefined}
              enableRowEdit={onEnableRowEdit}
              onSelectionChange={onSelectionChange}
            />
            {(onInlineEdit || onInlineEditAll || onInlineAdd) && (
              <EditingState
                editingRowIds={editingRowIds}
                rowChanges={rowChanges}
                onRowChangesChange={setRowChanges}
                onAddedRowsChange={setAddedRows}
                onCommitChanges={commitChanges}
                columnExtensions={editingStateColumns}
                addedRows={addedRows}
              />
            )}
            {(rowDetail || amountOfActions > 0 || onAdd || onDownload) && (
              <RowDetailState expandedRowIds={expandedRowIds} />
            )}
            {filterRowEnabled && (
              <FilteringState
                columnExtensions={filterColumns}
                columnFilteringEnabled={!loading}
                defaultFilters={filters} // keep in uncontrolled mode to prevent lockups
                onFiltersChange={onFiltersChange}
              />
            )}
            {sortEnabled && (
              <SortingState
                columnExtensions={sortColumns}
                columnSortingEnabled={!loading}
                defaultSorting={sorting} // keep in uncontrolled mode to prevent lockups
                onSortingChange={onSortingChange}
              />
            )}
            {selection && onSelectionChange && (
              <SelectionState
                selection={selection.map((selected) =>
                  typeof selected.id !== 'number'
                    ? parseInt(String(selected.id))
                    : selected.id
                )}
              />
            )}
            {groupingEnabled && (
              <GroupingState
                defaultGrouping={groupingEnabled ? grouping : []}
                onGroupingChange={onGroupingChange}
                columnGroupingEnabled={!loading}
              />
            )}
            <PagingState
              defaultCurrentPage={0}
              currentPage={(currentPage ?? 1) - 1}
              onCurrentPageChange={onCurrentPageChange}
              pageSize={pageSize}
              onPageSizeChange={onPageSizeCountsChange}
            />
            {groupingEnabled && <IntegratedGrouping />}
            {!toolbarDisabled && groupingEnabled && <DragDropProvider />}
            {selection && <IntegratedSelection />}
            {scrollable ? (
              <VirtualTable
                tableComponent={TableComponent}
                rowComponent={rowComponent ?? TableRow}
                cellComponent={TableCell}
                noDataCellComponent={TableCellNoData}
                stubCellComponent={TableStubCell}
              />
            ) : (
              <Table
                containerComponent={TableContainerComponent}
                tableComponent={TableComponent}
                rowComponent={rowComponent ?? TableRow}
                cellComponent={TableCell}
                noDataCellComponent={TableCellNoData}
                stubCellComponent={TableStubCell}
              />
            )}
            <TableColumnResizing
              defaultColumnWidths={columnWidths}
              resizingMode="nextColumn"
              columnExtensions={[
                {columnName: 'actions', maxWidth: 200, minWidth: 90.5},
              ]}
            />
            {groupingEnabled && <TableColumnReordering />}
            <TableHeaderRow
              cellComponent={TableHeaderRowCell}
              showSortingControls={sortEnabled}
              rowComponent={TableHeaderRowComponent}
            />
            {filterRowEnabled && (
              <TableFilterRow
                cellComponent={TableFilterRowCell}
                rowComponent={TableFilterRowComponent}
              />
            )}
            {(onInlineEdit || onInlineEditAll || onInlineAdd) && (
              <TableEditRow cellComponent={TableEditField} />
            )}
            {(rowDetail || amountOfActions > 0 || onAdd || onDownload) && (
              <TableRowDetail
                toggleColumnWidth={0}
                contentComponent={rowDetail}
                cellComponent={TableRowDetailCell}
                /** To remove the unwanted dropdown arrow in the actions column return empty <td /> element  */
                toggleCellComponent={() => <td />}
              />
            )}
            {selection &&
              onSelectionChange &&
              config.selectionType === 'row' && (
                <TableSelection
                  selectByRowClick
                  highlightRow
                  showSelectionColumn={false}
                  rowComponent={TableSelectionRow}
                />
              )}
            {!paginationDisabled && (
              <PagingPanel
                containerComponent={TablePagingPanelContainer}
                pageSizes={pageSizes}
              />
            )}
            {!paginationDisabled && (
              <CustomPaging
                totalCount={
                  itemTotal !== undefined
                    ? itemTotal
                    : (pageSize ?? 25) * (pageTotal ?? 1)
                }
              />
            )}
            {groupingEnabled && (
              <TableGroupRow
                cellComponent={(props) => (
                  <TableGroupRow.Cell
                    {...props}
                    data-cy="TemplateTableActionsGrouping"
                  />
                )}
                indentColumnWidth={0}
              />
            )}
            <TableColumnVisibility
              defaultHiddenColumnNames={hiddenColumns}
              onHiddenColumnNamesChange={handleHiddenColumnNamesChange}
            />

            {!toolbarDisabled && <Toolbar rootComponent={TableToolbarRoot} />}
            {!toolbarDisabled && groupingEnabled && (
              <GroupingPanel showSortingControls={false} />
            )}
            {!toolbarDisabled && (
              <TableToolbarActivityIndicator loading={loading} />
            )}
            {!toolbarDisabled && toolbarCustomAction && (
              <TableToolbarCustomAction
                toolbarCustomAction={toolbarCustomAction}
              />
            )}
            {!toolbarDisabled && onDateRangeFilterToggle && (
              <TableToolbarDaterangeFilterButton
                isActive={isDaterangeFilterActive}
                loading={loading}
                onToggle={onDateRangeFilterToggle}
              />
            )}
            {!toolbarDisabled && onRefresh && (
              <TableToolbarRefreshButton
                loading={loading}
                onRefresh={onRefresh}
              />
            )}
            {!toolbarDisabled && <ColumnChooser />}
            {!toolbarDisabled && toolbarInfo && (
              <TableToolbarInfo toolbarInfo={toolbarInfo} />
            )}
            <TableFixedColumns
              leftColumns={['actions']}
              cellComponent={TableFixedColumnsCell}
            />
          </Grid>
        </Paper>
      </InternalTableContext.Provider>
    </SortableProvider>
  );
};

interface SortableProviderProps extends SortableContainerProps {
  sortable: boolean;
}

export const SortableProvider: React.FC<SortableProviderProps> = ({
  children,
  sortable,
  ...sortableProps
}) => {
  if (sortable) {
    return (
      <SortableProviderComponent {...sortableProps}>
        {children}
      </SortableProviderComponent>
    );
  }
  return <>{children}</>;
};

export const SortableProviderComponent = SortableContainer(
  ({children}: {children: React.ReactNode}) => {
    return <>{children}</>;
  }
);
