import React from 'react';
import {
  listingSchema,
  TemplateCard,
  TemplateForm,
} from '../../factory/template';
import {
  DriverDocumentContext,
  IDriverDocumentDetails,
} from './DriverDocumentContext';
import {DriverDocument} from '@onroadvantage/onroadvantage-api';
import * as Yup from 'yup';
import {
  FormikAutocompleteWithListingComponent,
  FormikDateTimePicker,
  FormikSwitch,
  FormikTextField,
} from '../formik';
import {Field} from 'formik';
import {generateUid, uploadXHR} from '../../service/Util';
import {StorageService} from '../../service';
import moment from 'moment';

const schema: Yup.SchemaOf<IDriverDocumentDetails> = Yup.object({
  actionByDate: Yup.date(),
  active: Yup.boolean(),
  contract: listingSchema.nullable().required('Required'),
  driverDocumentType: listingSchema.nullable().required('Required'),
  description: Yup.string(),
  uploadId: Yup.string(),
});

const getInitialValues = (
  driverDocument?: DriverDocument | null
): IDriverDocumentDetails | undefined => {
  if (driverDocument) {
    return {
      actionByDate: moment(driverDocument.actionByDate),
      active: !!driverDocument.active,
      contract: {
        value: driverDocument.contract?.id,
        label: driverDocument.contract?.name,
      },
      driverDocumentType: {
        value: driverDocument.driverDocumentType?.id,
        label: driverDocument.driverDocumentType?.name,
      },
      description: driverDocument.description ?? '',
      uploadId: driverDocument.id.toString(),
    };
  }
  return undefined;
};

export const DriverDocumentDetails: React.FC<{isAdd?: boolean}> = ({isAdd}) => {
  const {detailsRef, loading, submitting, driverDocument, onDetailsFormSubmit} =
    React.useContext(DriverDocumentContext);
  const [initialValues, setInitialValues] =
    React.useState<IDriverDocumentDetails>();

  React.useEffect(() => {
    const values = getInitialValues(driverDocument);

    if (values) {
      setInitialValues(values);
    } else if (isAdd) {
      setInitialValues({
        actionByDate: moment().hour(0).minute(0),
        active: false,
        driverDocumentType: {value: undefined, label: undefined},
      });
    }
  }, [isAdd, driverDocument, setInitialValues]);

  const handleSelectPdf = React.useCallback(
    (
        setFieldValue: (
          field: string,
          value: any,
          shouldValidate?: boolean | undefined
        ) => void
      ) =>
      (e: any) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = async () => {
          const response = await StorageService.getUrl({
            key: generateUid(),
            type: 'Document',
            method: 'POST',
          });
          const storageConfig = await response.data;
          await uploadXHR(file, storageConfig, () => {});
          setFieldValue('uploadId', storageConfig.key, false);
        };
        reader.readAsDataURL(file);
      },
    []
  );

  return (
    <TemplateCard
      title={`Driver Document Details ${isAdd ? 'Add' : 'Edit'}`}
      loading={loading}
    >
      <TemplateForm<IDriverDocumentDetails>
        initialValues={initialValues}
        onSubmit={onDetailsFormSubmit}
        permission={{
          name: `${isAdd ? 'Add' : 'Edit'} DriverDocument`,
          type: isAdd ? 'Add' : 'Edit',
        }}
        submitting={submitting}
        innerRef={detailsRef}
        validationSchema={schema}
      >
        {({setFieldValue}) => (
          <>
            <FormikAutocompleteWithListingComponent
              name="contract"
              model="Contract"
              label="Contract"
            />
            <FormikAutocompleteWithListingComponent
              name="driverDocumentType"
              model="DriverDocumentType"
              label="Document Type"
            />
            <FormikTextField
              name="description"
              label="Description"
              placeholder="Enter the driver document description"
              fullWidth
            />
            <FormikDateTimePicker
              name="actionByDate"
              label="Action By Date"
              // format="MMMM dd, yyyy HH:mm" //TODO
              ampm={false}
              fullWidth
            />
            <Field
              name="template"
              type="file"
              accept=".pdf"
              label="Report Template"
              onChange={handleSelectPdf(setFieldValue)}
              style={{padding: 8}}
            />
            <FormikSwitch name="active" label="Active" />
          </>
        )}
      </TemplateForm>
    </TemplateCard>
  );
};
