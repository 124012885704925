import {decorate, observable, action, computed, toJS} from 'mobx';

class OperationalEventsActivities {
  constructor() {
    this.operationalEvents = {};
  }

  get operationalEventActivities() {
    return toJS(this.operationalEvents);
  }

  addOperationalEventActivities = (events, id) => {
    if (events && id) {
      this.operationalEvents.events = events;
      this.operationalEvents.id = id;
    }
  };

  clearOperationalEvents = () => {
    this.operationalEvents = {};
  };
}

export default decorate(OperationalEventsActivities, {
  operationalEvents: observable,
  operationalEventActivities: computed,
  addOperationalEventActivities: action,
  clearOperationalEvents: action,
});
