import React from 'react';
import {BroadcastContext} from './BroadcastContext';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../factory/template';
import {IconButton} from '@mui/material';
import {Broadcast} from '@onroadvantage/onroadvantage-api';
import {Pause, PlayArrow} from '@mui/icons-material';
import {Loader} from '../loader';

export const BroadcastList: React.FC = () => {
  const {
    loadList,
    audioPlayingBroadcastId,
    loadingAudio,
    isPlaying,
    onPlayAudio,
    onPauseAudio,
    cleanupList,
  } = React.useContext(BroadcastContext);

  const config = React.useMemo<TemplateTableConfig<Broadcast>>(
    () => ({
      columns: [
        {name: 'heading', label: 'Heading', type: 'string'},
        {name: 'status', label: 'Status', type: 'string'},
        {name: 'scheduledDateTime', label: 'Status', type: 'datetime'},
        {name: 'message', label: 'Message', type: 'string'},
        {
          name: 'playAudioFile',
          label: 'Play',
          type: 'string',
          getValue: (row) => {
            const activeAudio = audioPlayingBroadcastId === row.id;
            return (
              <IconButton
                onClick={() =>
                  activeAudio && isPlaying && !loadingAudio
                    ? onPauseAudio(row)
                    : onPlayAudio(row)
                }
                title={`${
                  activeAudio && isPlaying && !loadingAudio ? 'Pause' : 'Play'
                } Audio`}
                disabled={activeAudio && loadingAudio}
                size="large"
              >
                {activeAudio ? (
                  loadingAudio ? (
                    <Loader size={24} />
                  ) : isPlaying ? (
                    <Pause />
                  ) : (
                    <PlayArrow />
                  )
                ) : (
                  <PlayArrow />
                )}
              </IconButton>
            );
          },
        },
      ],
      enableFilter: true,
      enableSort: true,
      deleteMessage: (row) => ({
        title: 'Broadcast',
        items: {Heading: row.heading, Status: row.status, Message: row.message},
      }),
      identifier: 'BROADCAST_LIST',
    }),
    [
      audioPlayingBroadcastId,
      isPlaying,
      loadingAudio,
      onPauseAudio,
      onPlayAudio,
    ]
  );

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [loadList, cleanupList]);

  return <TemplateTableWrapper config={config} context={BroadcastContext} />;
};
