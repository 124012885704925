import React from 'react';
import * as Yup from 'yup';
import {ContractCriticalEventTypeReasonCode} from '@onroadvantage/onroadvantage-api';
import {TemplateTable} from '../../../factory/template';
import {useContractCriticalEventTypeConfigContext} from './ContractCriticalEventTypeConfigContext';

interface ContractCriticalEventTypeConfigReasonCodeListAddSchema {
  name: string;
  description: string | undefined;
}

const contractCriticalEventTypeConfigReasonCodeListAddSchema: Yup.SchemaOf<ContractCriticalEventTypeConfigReasonCodeListAddSchema> =
  Yup.object({
    name: Yup.string().required('Required'),
    description: Yup.string(),
  });

export const ContractCriticalEventTypeConfigReasonCodeList = () => {
  const {
    contractCriticalEventTypeConfig,
    loading,
    submitting,
    onAddReasonCodes,
    onEditReasonCodes,
    onDeleteReasonCode,
  } = useContractCriticalEventTypeConfigContext();

  return (
    <TemplateTable<ContractCriticalEventTypeReasonCode>
      config={{
        columns: [
          {name: 'name', label: 'Name', type: 'string', enableEditing: true},
          {
            name: 'description',
            label: 'Description',
            type: 'string',
            enableEditing: true,
          },
        ],
        disablePagination: true,
        disableToolbar: true,
        deleteMessage: (row) => ({
          title: 'Contract Critical Event Type Config Reason Code',
          items: {
            ContractCriticalEventTypeConfig: `${contractCriticalEventTypeConfig?.criticalEventType?.name} - ${contractCriticalEventTypeConfig?.contract?.name}`,
            Name: row.name,
            Description: row.description,
          },
        }),
        identifier: 'CONTRACT_CRITICAL_EVENT_TYPE_CONFIG_REASON_CODE',
      }}
      addValidation={contractCriticalEventTypeConfigReasonCodeListAddSchema}
      list={contractCriticalEventTypeConfig?.reasonCodes ?? []}
      currentPage={1}
      onInlineAdd={onAddReasonCodes}
      onInlineEdit={onEditReasonCodes}
      onDelete={onDeleteReasonCode}
      loading={submitting || loading}
    />
  );
};
