import React from 'react';
import {VantageDialog} from '../../../dialog';
import {WebPlanningBoardMasterTripWithIdType} from '../../planningBoardContext';
import {
  usePlanningBoardContext,
  usePlanningBoardGanttContext,
} from '../../planningBoardContext';

export const PlanningBoardGanttDeleteDialog: React.FC = () => {
  const {deleteDialogRef, selectedGanttItem, deleteMasterTrip} =
    usePlanningBoardGanttContext();
  const {hasPermission} = usePlanningBoardContext();
  return (
    <VantageDialog
      title={`Are you sure you want to delete ${
        (selectedGanttItem?.data as WebPlanningBoardMasterTripWithIdType)
          ?.tripNumber
      }?`}
      ref={deleteDialogRef}
      onAccept={deleteMasterTrip}
      disableAccept={!hasPermission.edit}
    />
  );
};
