import * as React from 'react';
import * as PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import withStyles from '@mui/styles/withStyles';
import Chip from '@mui/material/Chip';
import {MomentService} from '../../service';

const styles = (theme) => ({
  Cell: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  progressBar: {
    backgroundColor: 'red',
    float: 'left',
    height: theme.spacing(2),
  },
});

export const DurationCellBase = ({classes, style, startTime, endTime}) => (
  <TableCell className={classes.Cell} style={style}>
    <Chip label={MomentService.stopTat({start: startTime, end: endTime})} />
  </TableCell>
);

DurationCellBase.propTypes = {
  value: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};
DurationCellBase.defaultProps = {
  style: {},
};

export const DurationCell = withStyles(styles, {name: 'DurationCell'})(
  DurationCellBase
);
