import React from 'react';
import {TripDebriefContext} from '../TripDebriefContext';
import {useParams} from 'react-router-dom';

interface IParams {
  orderLineId?: string;
}

export const TripDebriefOrderLineIdHandler: React.FC = () => {
  const {setOrderLineId} = React.useContext(TripDebriefContext);

  const {orderLineId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = orderLineId ? parseInt(orderLineId) : undefined;
    setOrderLineId(parsedId);
    return () => setOrderLineId(undefined);
  }, [orderLineId, setOrderLineId]);

  return null;
};
