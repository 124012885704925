import React from 'react';
import {SortableElement} from 'react-sortable-hoc';
import {
  WebPlanningBoardOrder,
  WebPlanningBoardTripStopOrder,
} from '@onroadvantage/onroadvantage-api';
import {Stack, Typography} from '@mui/material';
import {ArrowForward, DragIndicator} from '@mui/icons-material';
import {usePlanningBoardTripOrdersSortableStyles} from './PlanningBoardTripOrdersSortable.style';

interface PlanningBoardTripOrdersSortableProps {
  order: WebPlanningBoardOrder | WebPlanningBoardTripStopOrder;
}
export const PlanningBoardTripOrdersSortableItem = SortableElement(
  ({order}: PlanningBoardTripOrdersSortableProps) => {
    const classes = usePlanningBoardTripOrdersSortableStyles();
    return (
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        className={classes.sortableItem}
      >
        <DragIndicator />
        <Typography fontWeight={600}>{order.orderNumber}</Typography>
        <Stack
          direction="row"
          flex={1}
          spacing={1}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Typography variant="subtitle2">{order.upliftPoint?.name}</Typography>
          <ArrowForward fontSize="small" />
          <Typography variant="subtitle2">
            {order.offloadPoint?.name}
          </Typography>
        </Stack>
      </Stack>
    );
  }
);
