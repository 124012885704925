import React from 'react';
import {Card, CardContent} from '@mui/material';
import KeyValuePairTable from '../../table/KeyValuePairTable';
import {TaskPayload} from './TaskPayload';
import {TaskContext, TaskContextProps} from '../TaskContext';

export const TaskDetails: React.FC = () => {
  const {task, taskView} = React.useContext<TaskContextProps>(TaskContext);
  const items = [
    {key: 'Template', value: String(taskView?.templateName)},
    {key: 'Template Type', value: String(taskView?.templateTypeName)},
    {
      key: 'Vehicle Registration',
      value: String(taskView?.vehicleRegistrationNumber),
    },
    {key: 'Driver Tag', value: String(taskView?.driverTagNumber)},
    {key: 'Trip Number', value: String(taskView?.tripNumber)},
    {key: 'Order Number', value: String(taskView?.orderNumber)},
    {key: 'Status', value: String(task ? task.status : '')},
    {key: 'Latitude', value: String(task ? task.latitude : '')},
    {key: 'Longitude', value: String(task ? task.longitude : '')},
    {key: 'Event Date', value: String(task ? task.eventDate : '')},
  ];
  return (
    <Card>
      <CardContent>
        <KeyValuePairTable items={items} />
        <TaskPayload />
      </CardContent>
    </Card>
  );
};
