import React from 'react';
import {SiteContext} from './SiteContext';
import {SiteDetails} from './siteDetails';
import {ITemplateTabControllerTab, TemplateTabs} from '../../factory/template';
import {SiteSubNodeList} from './siteSubNode';
import {SitePreList} from './sitePre';
import {SitePostList} from './sitePost';

export const Site: React.FC = () => {
  const {siteId, siteType, loading} = React.useContext(SiteContext);

  const siteTypes: string[] = ['LOADING', 'OFFLOADING', 'DEFAULT'];

  const tabs: ITemplateTabControllerTab[] = [
    {title: 'Details', element: <SiteDetails />},
    {title: 'Sub-Nodes', element: <SiteSubNodeList />},
  ];
  if (siteType && siteTypes.includes(siteType)) {
    tabs.push({title: 'Pre-Site', element: <SitePreList />});
    tabs.push({title: 'Post-Site', element: <SitePostList />});
  }

  const path = React.useMemo(() => `/sitelist/${siteId}`, [siteId]);

  return <TemplateTabs path={path} tabs={tabs} loading={loading} />;
};
