import React from 'react';
import {
  MobileTimePicker,
  MobileTimePickerProps,
  StaticTimePicker,
} from '@mui/x-date-pickers';
import {InputLabelProps} from '@mui/material';
import {useFormikContext} from 'formik';
import {FormikTimePickerField} from './FormikTimePickerField';

interface FormikTimePickerProps
  extends Omit<
    MobileTimePickerProps<Date, Date>,
    'value' | 'onChange' | 'renderInput'
  > {
  id?: string;
  name: string;
  placeholder?: string;
  displayStaticWrapperAs?: 'desktop' | 'mobile';
  InputLabelProps?: Partial<InputLabelProps>;
  fullWidth?: boolean;
  disableDefaultValue?: boolean;
  staticMode?: boolean;
}

export const FormikTimePickerContext =
  React.createContext<FormikTimePickerProps>({name: ''});

export const FormikTimePicker: React.FC<FormikTimePickerProps> = (props) => {
  return (
    <FormikTimePickerContext.Provider value={props}>
      <FormikTimePickerComponent />
    </FormikTimePickerContext.Provider>
  );
};

const FormikTimePickerComponent: React.FC = () => {
  const {name, disableDefaultValue, disabled, ...props} = React.useContext(
    FormikTimePickerContext
  );
  const {isSubmitting, getFieldMeta, getFieldHelpers} = useFormikContext();
  const {value, ...meta} = getFieldMeta(name);
  const {setValue} = getFieldHelpers(name);

  const [parsedValue, setParsedValue] = React.useState<Date | null>(null);

  React.useEffect(() => {
    if (value instanceof Date || typeof value === 'string') {
      setParsedValue(new Date(value));
    } else if (!value && !disableDefaultValue) {
      setParsedValue(new Date());
      setValue(new Date());
    }
    return () => setParsedValue(null);
    // eslint-disable-next-line
  }, [value]);

  const handleChange = React.useCallback(
    (date: Date | null) => {
      setValue(date);
      setParsedValue(date);
    },
    [setValue]
  );

  return (
    <HandleTimePickerState
      {...props}
      {...meta}
      disabled={disabled || isSubmitting}
      ampm={false}
      onChange={handleChange}
      name={name}
      value={parsedValue ?? null}
      renderInput={FormikTimePickerField}
    />
  );
};

export const HandleTimePickerState: React.FC<
  FormikTimePickerProps & MobileTimePickerProps<Date, Date>
> = ({staticMode, displayStaticWrapperAs, ...props}) => {
  return staticMode ? (
    <StaticTimePicker
      {...props}
      displayStaticWrapperAs={displayStaticWrapperAs}
    />
  ) : (
    <MobileTimePicker {...props} />
  );
};
