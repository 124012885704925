import {observable, toJS} from 'mobx';
import clone from 'rfdc';
import {
  PlanningSolution as PlanningSolutionType,
  WebPlanningSolutionOptimised as WebPlanningSolutionOptimisedType,
} from '@onroadvantage/onroadvantage-api';
import {useStoreData} from '../helpers';
import {planningSolutionListStoreContext} from './PlanningSolutionList.context';
import _ from 'lodash';
import {
  Sorting as SortingProps,
  Filter as FilterProps,
} from '@devexpress/dx-react-grid';

interface IPagination {
  currentPage: number;
  pages: number;
  perPage: number;
  totalCount: number;
}

export const planningSolutionListStore = () => {
  const store = {
    // pagination
    bPagination: observable.box<IPagination>({
      currentPage: 1,
      pages: 0,
      perPage: 50,
      totalCount: 0,
    }),
    get pagination() {
      return this.bPagination.get();
    },
    get currentPage() {
      return this.bPagination.get().currentPage;
    },
    setCurrentPage: (value: number) => {
      store.bPagination.set({...store.bPagination.get(), currentPage: value});
    },
    setPages: (value: number) => {
      store.bPagination.set({...store.bPagination.get(), pages: value});
    },
    get perPage() {
      return this.bPagination.get().perPage;
    },
    setPerPage: (value: number) => {
      store.bPagination.set({...store.bPagination.get(), perPage: value});
    },
    setTotalCount: (value: number) => {
      store.bPagination.set({...store.bPagination.get(), totalCount: value});
    },

    // list items
    bListItems: observable.box<Array<WebPlanningSolutionOptimisedType>>([]),
    get listItems() {
      return store.bListItems.get();
    },
    addItem: (planningSolution: WebPlanningSolutionOptimisedType) => {
      const newItems = clone()(toJS(store.bListItems.get()));
      store.bListItems.set([planningSolution, ...newItems]);
    },
    removeItem: (planningSolution: PlanningSolutionType) => {
      _.remove(store.bListItems.get(), {id: planningSolution.id});
    },
    updateItem: (planningSolution: PlanningSolutionType) => {
      const newListItems = clone()(toJS(store.bListItems.get()));
      const oldItemIndex = _.findIndex(newListItems, {id: planningSolution.id});
      newListItems.splice(oldItemIndex, 1, planningSolution);

      store.bListItems.set(newListItems);
    },
    setListItems: (items: WebPlanningSolutionOptimisedType[]) => {
      store.bListItems.set(items);
    },

    // list status
    bShouldRefresh: observable.box<boolean>(false),
    get shouldRefresh() {
      return this.bShouldRefresh.get();
    },
    setShouldRefresh: (value: boolean) => {
      store.bShouldRefresh.set(value);
    },
    // simple number that gets incremented to trigger a refresh
    bRefreshTrigger: observable.box<number>(0),
    get refreshTrigger() {
      return this.bRefreshTrigger.get();
    },
    // call this function to trigger a refresh
    triggerRefresh: () => {
      store.bRefreshTrigger.set(store.bRefreshTrigger.get() + 1);
    },

    bSorting: observable.box<Array<SortingProps>>([]),
    get sorting() {
      return this.bSorting.get();
    },
    setSorting: (sorting: SortingProps[]) => {
      store.bSorting.set(sorting);
      store.setCurrentPage(1);
    },
    bFilters: observable.box<Array<FilterProps>>([]),
    get filters() {
      return this.bFilters.get();
    },
    setFilters: (filters: FilterProps[]) => {
      store.bFilters.set(filters);
      store.setCurrentPage(1);
    },
  };
  return store;
};
export type TPlanningSolutionListStore = ReturnType<
  typeof planningSolutionListStore
>;

export function usePlanningSolutionListData<Selection>(
  dataSelector: (store: TPlanningSolutionListStore) => Selection
) {
  return useStoreData(
    planningSolutionListStoreContext,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    (contextData) => contextData!,
    dataSelector
  );
}
