import React from 'react';
import _ from 'lodash';
import {
  ITemplateControllerRoute,
  TemplateController,
} from '../../factory/template';
import {UserContext, UserContextProvider} from './UserContext';
import {UserList} from './UserList';
import {UserIdHandler} from './UserIdHandler';
import {UserForm} from './UserForm';
import {User} from './User';

export const UserController: React.FC = () => {
  return (
    <UserContextProvider>
      <UserControllerComponent />
    </UserContextProvider>
  );
};

const UserControllerComponent: React.FC = () => {
  const {user, loading} = React.useContext(UserContext);
  const routes: ITemplateControllerRoute[] = React.useMemo(
    () => [
      {title: 'User List', component: UserList},
      {
        path: '/add',
        title: 'New User',
        component: () => <UserForm isAdd />,
      },
      {
        path: '/:userId',
        title: (params) =>
          loading
            ? 'User Loading...'
            : user?.email ?? `User ${_.get(params, 'userId', 'Single')}`,
        component: User,
        IdHandler: UserIdHandler,
      },
    ],
    [loading, user?.email]
  );
  return <TemplateController rootPath="/userlist" routes={routes} />;
};
