import React from 'react';
import {useTripEditContext} from './TripEditContext';
import {Loader} from '../../../loader';
import {VantageDialog} from '../../../dialog';
import {useAppNotifications} from '../../../../contexts';

export const OverrideErrorsDialog: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const notify = useAppNotifications();
  const {dialogRef, onOverrideErrors, currentError} = useTripEditContext();
  const handleDialogConfirm = async () => {
    setLoading(true); // Start loading when the operation begins
    try {
      await onOverrideErrors();
      notify('success', 'Errors overridden successfully!');
    } catch (error) {
      notify('error', 'Failed to override errors!');
    } finally {
      setLoading(false);
    }
  };

  return (
    <VantageDialog
      ref={dialogRef}
      onAccept={handleDialogConfirm}
      dialogTitle="Override Errors"
      declineTitle="No"
      acceptTitle="Yes"
      title=""
    >
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <div>
          {currentError ? (
            <>
              <strong>Error:</strong> {currentError}
              <br />
              Do you want to override this error?
            </>
          ) : (
            'Specific constraint error occurred. Do you want to override this error?'
          )}
        </div>
      )}
    </VantageDialog>
  );
};
