import React from 'react';
import PropTypes from 'prop-types';
import RiskHeader from './RiskHeader';
import RiskItem from './RiskItem';
import {keyValuePairToObjectConversion} from '../../../../service/Util';
import {getColorSchemeName} from '../helpers';

const ITEM_COUNT = 5;
const UPDATE_INTERVAL = 10000; // 10 seconds

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
};

const getPadded = (value) => `${value}`.padStart(2, '0');

const getFromTimedelta = (item) => {
  if (!item) {
    return `${getPadded(0)}:${getPadded(0)}`;
  }

  if (item.day !== 0) {
    return `${item.day}d ${getPadded(item.hour)}:${getPadded(item.min)}`;
  }
  return `${getPadded(item.hour)}:${getPadded(item.min)}`;
};

const getUtilisation = (actual, planned) => {
  if (!actual || !planned) {
    return null;
  }

  const calculatedActual = actual.day * 1440 + actual.hour * 60 + actual.min;
  const calculatedPlanned =
    planned.day * 1440 + planned.hour * 60 + planned.min;

  return calculatedActual < calculatedPlanned;
};

const mapData = ({
  contractCode: contract,
  totalPlannedTat: totalStandingTimePlanned,
  totalActualTat: totalStandingTimeActual,
  totalPlannedDrivingTime: totalDrivingTimePlanned,
  totalActualDrivingTime: totalDrivingTimeActual,
  avgActualTat: avgStandingTimeActual,
  avgPlannedTat: avgStandingTimePlanned,
  avgActualDrivingTime: avgDrivingTimeActual,
  avgPlannedDrivingTime: avgDrivingTimePlanned,
  knownScheduledActualTatPerc: plannedStandingTimePercent = 0,
  knownUnscheduledActualTatPerc: knownUnplannedStandingTimePercent = 0,
  unknownUnscheduledActualTatPerc: unknownUnplannedStandingTimePercent = 0,
  tripCount,
}) => ({
  contract,
  metrics: [
    {
      actual: getFromTimedelta(totalStandingTimeActual),
      planned: getFromTimedelta(totalStandingTimePlanned),
      status: getUtilisation(totalStandingTimeActual, totalStandingTimePlanned),
      label: 'Total Standing Time',
    },
    {
      actual: getFromTimedelta(avgStandingTimeActual),
      planned: getFromTimedelta(avgStandingTimePlanned),
      status: getUtilisation(avgStandingTimeActual, avgStandingTimePlanned),
      label: 'Avg. Standing Time',
    },
    {
      actual: getFromTimedelta(totalDrivingTimeActual),
      planned: getFromTimedelta(totalDrivingTimePlanned),
      status: getUtilisation(totalDrivingTimeActual, totalDrivingTimePlanned),
      label: 'Total Driving Time',
    },
    {
      actual: getFromTimedelta(avgDrivingTimeActual),
      planned: getFromTimedelta(avgDrivingTimePlanned),
      status: getUtilisation(avgDrivingTimeActual, avgDrivingTimePlanned),
      label: 'Avg. Driving Time',
    },
  ],
  totalStandingTimeActual: getFromTimedelta(totalStandingTimeActual),
  totalStandingTimePlanned: getFromTimedelta(totalStandingTimePlanned),
  totalStandingTimeStatus: getUtilisation(
    totalStandingTimeActual,
    totalStandingTimePlanned
  ),
  totalDrivingTimeActual: getFromTimedelta(totalDrivingTimeActual),
  totalDrivingTimePlanned: getFromTimedelta(totalDrivingTimePlanned),
  totalDrivingTimeStatus: getUtilisation(
    totalDrivingTimeActual,
    totalDrivingTimePlanned
  ),
  avgStandingTimeActual: getFromTimedelta(avgStandingTimeActual),
  avgStandingTimePlanned: getFromTimedelta(avgStandingTimePlanned),
  avgStandingTimeStatus: getUtilisation(
    avgStandingTimeActual,
    avgStandingTimePlanned
  ),
  avgDrivingTimeActual: getFromTimedelta(avgDrivingTimeActual),
  avgDrivingTimePlanned: getFromTimedelta(avgDrivingTimePlanned),
  avgDrivingTimeStatus: getUtilisation(
    avgDrivingTimeActual,
    avgDrivingTimePlanned
  ),
  plannedStandingTimePercent: parseFloat(
    plannedStandingTimePercent || 0
  ).toFixed(2),
  knownUnplannedStandingTimePercent: parseFloat(
    knownUnplannedStandingTimePercent || 0
  ).toFixed(2),
  unknownUnplannedStandingTimePercent: parseFloat(
    unknownUnplannedStandingTimePercent || 0
  ).toFixed(2),
  tripCount,
});

const prepareData = (rows) => {
  const prepared = rows.map(keyValuePairToObjectConversion);

  return prepared.map(mapData);
};

const getTitle = (name = 'Contract') => {
  const colorSchemeName = getColorSchemeName();
  if (colorSchemeName === 'unitrans')
    if (name === 'Contract Time Analysis')
      return 'UCT Contract Performance Indicator';
  if (colorSchemeName === 'default') return 'Risk';
  return name;
};

class RiskView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      isPaused: false,
      currentIndex: 0,
      isLoading: true,
      partial: [],
      title: getTitle(),
    };
  }

  componentDidMount() {
    this.setState(
      {
        isLoading: true,
      },
      this.processData
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    // check if data changed from parent
    if (this.props.lastRefreshed !== nextProps.lastRefreshed) {
      return true;
    }

    // check state
    // might need to do some deep checking if objects become nested
    if (this.state !== nextState) {
      return true;
    }

    return false;
  }

  handleStartPageInterval = () => {
    this.timer = setInterval(this.getCurrentSlice, UPDATE_INTERVAL);
    this.setState({
      isPaused: false,
    });
  };

  handleStopPageInterval = () => {
    clearInterval(this.timer);
    this.setState({
      isPaused: true,
    });
  };

  sorter = (a, b) => {
    if (a.plannedStandingTimePercent < b.plannedStandingTimePercent) return 1;
    if (a.plannedStandingTimePercent > b.plannedStandingTimePercent) return -1;
    if (
      a.knownUnplannedStandingTimePercent < b.knownUnplannedStandingTimePercent
    )
      return 1;
    if (
      a.knownUnplannedStandingTimePercent > b.knownUnplannedStandingTimePercent
    )
      return -1;
    if (
      a.unknownUnplannedStandingTimePercent <
      b.unknownUnplannedStandingTimePercent
    )
      return 1;
    if (
      a.unknownUnplannedStandingTimePercent >
      b.unknownUnplannedStandingTimePercent
    )
      return -1;
    return 0;
  };

  processData = async () => {
    const {
      dashboard: {data, name},
    } = this.props;
    const prepared = prepareData(data);
    prepared.sort(this.sorter);
    const title = getTitle(name);

    this.handleStartPageInterval();
    this.setState(
      {
        data: prepared,
        isLoading: false,
        title,
      },
      this.getCurrentSlice
    );
  };

  getCurrentSlice = () => {
    const {currentIndex, data} = this.state;
    const lastIndex = data.length - 1;
    let nextIndex = currentIndex;
    let partial = [];

    if (data.length <= ITEM_COUNT) {
      partial = data;
      nextIndex = 0;
    } else if (currentIndex <= lastIndex - ITEM_COUNT) {
      partial = data.slice(currentIndex, currentIndex + ITEM_COUNT);
      nextIndex = currentIndex + ITEM_COUNT;
    } else if (currentIndex > lastIndex - ITEM_COUNT) {
      partial = data.slice(currentIndex);
      nextIndex = ITEM_COUNT - partial.length;
      partial = partial.concat(data.slice(0, nextIndex));
    } else {
      partial = data.slice(0, ITEM_COUNT);
      nextIndex = ITEM_COUNT;
    }

    this.setState({
      partial,
      currentIndex: nextIndex,
    });
  };

  render() {
    const {isLoading, isPaused, partial, title} = this.state;

    if (isLoading) {
      return <div>Loading...</div>;
    }

    return (
      <div style={styles.container}>
        <RiskHeader
          title={title}
          isPaused={isPaused}
          onStop={this.handleStopPageInterval}
          onStart={this.handleStartPageInterval}
        />
        {partial.map((i) => {
          return <RiskItem {...i} key={i.contract} />;
        })}
      </div>
    );
  }
}

RiskView.propTypes = {
  lastRefreshed: PropTypes.instanceOf(Date),
};

RiskView.defaultProps = {
  lastRefreshed: Date(),
};

export default RiskView;
