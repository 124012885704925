import React from 'react';
import {Order as OrderType} from '@onroadvantage/onroadvantage-api';
import {TripEditOrderTableAdd} from './TripEditOrderTableAdd';
import {TripEditOrderTable} from './TripEditOrderTable';

interface TripEditOrdersProps {
  loading?: boolean;
  tripOrders: OrderType[];
  contractCode: string;
  onOrdersAdd: (orders: OrderType[]) => void;
  onOrdersRemove: (order: OrderType) => void;
}

export const TripEditOrders: React.FC<TripEditOrdersProps> = ({
  contractCode,
  loading,
  onOrdersAdd,
  tripOrders,
  onOrdersRemove,
}) => {
  const [isAdding, setIsAdding] = React.useState(false);

  const handleAdd = () => {
    setIsAdding(true);
  };

  if (isAdding)
    return (
      <TripEditOrderTableAdd
        contractCode={contractCode}
        tripOrders={tripOrders}
        onOrdersAdd={onOrdersAdd}
        setIsAdding={setIsAdding}
      />
    );

  return (
    <TripEditOrderTable
      loading={loading}
      onAdd={handleAdd}
      onOrdersRemove={onOrdersRemove}
      tripOrders={tripOrders}
    />
  );
};
