import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

const ReportScheduleAddReviewStyles = (theme: Theme) =>
  createStyles({
    content: {
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      borderBottomColor: theme.palette.divider,
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
    },
    item: {
      paddingTop: theme.spacing(1),
    },
    multiple: {
      display: 'flex',
      flexDirection: 'column',
    },
    multipleTitle: {
      fontWeight: theme.typography.fontWeightBold,
      paddingRight: theme.spacing(1),
    },
    multipleValue: {},
    single: {
      display: 'flex',
      flexDirection: 'row',
    },
    singleTitle: {
      fontWeight: theme.typography.fontWeightBold,
      paddingRight: theme.spacing(1),
    },
    singleValue: {},
    chip: {
      margin: 2,
    },
    first: {
      paddingTop: 0,
    },
  });

export const useReportScheduleAddReviewStyles = makeStyles(
  ReportScheduleAddReviewStyles,
  {name: 'ReportScheduleAddReviewStyles'}
);
