import React from 'react';
import {VehicleDetailsView} from './VehicleDetailsView';
import {VehicleForm} from './VehicleForm';
import {VehicleCard} from '../vehicleCard';
import {Edit} from '@mui/icons-material';

interface VehicleDetailsProps {
  view?: boolean;
}

export const VehicleDetails: React.FC<VehicleDetailsProps> = ({view}) => {
  return view ? (
    <VehicleDetailsView />
  ) : (
    <VehicleCard title="Vehicle Details Edit" avatar={<Edit />}>
      <VehicleForm />
    </VehicleCard>
  );
};
