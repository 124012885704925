import React from 'react';
import {
  NUMBER_ERROR_MESSAGE,
  TemplateCard,
  TemplateForm,
} from '../../factory/template';
import {ProductContext} from './ProductContext';
import {Product} from '@onroadvantage/onroadvantage-api';
import * as Yup from 'yup';
import {FormikTextField} from '../formik';
import {InputAdornment} from '@mui/material';

export interface IProductForm {
  name: string;
  description?: string | undefined;
  uom?: string | undefined;
  compartmentFillingPreferenceUom?: string | undefined;
  orderLineQuantityPreferenceUom?: string | undefined;
  variabilityThreshold?: number | undefined;
}

const schema: Yup.SchemaOf<IProductForm> = Yup.object({
  name: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  uom: Yup.string(),
  compartmentFillingPreferenceUom: Yup.string(),
  orderLineQuantityPreferenceUom: Yup.string(),
  variabilityThreshold: Yup.number()
    .typeError(NUMBER_ERROR_MESSAGE)
    .min(0, 'Variability threshold cannot be less than 0'),
});

const getInitialValues = (
  product?: Product | null
): IProductForm | undefined => {
  if (product) {
    return {
      name: product.name,
      description: product.description,
      uom: product.uom,
      compartmentFillingPreferenceUom: product.compartmentFillingPreferenceUom,
      orderLineQuantityPreferenceUom: product.orderLineQuantityPreferenceUom,
      variabilityThreshold: product.variabilityThreshold,
    };
  }
  return undefined;
};

export const ProductForm: React.FC<{isAdd?: boolean}> = ({isAdd}) => {
  const {detailsRef, loading, submitting, product, onDetailsFormSubmit} =
    React.useContext(ProductContext);
  const [initialValues, setInitialValues] = React.useState<IProductForm>();

  React.useEffect(() => {
    const values = getInitialValues(product);

    if (values) {
      setInitialValues(values);
    } else if (isAdd) {
      setInitialValues({
        name: '',
        description: '',
        variabilityThreshold: 50,
      });
    }
  }, [isAdd, product, setInitialValues]);

  return (
    <TemplateCard
      title={`Product Details ${isAdd ? 'Add' : 'Edit'}`}
      loading={loading}
    >
      <TemplateForm<IProductForm>
        initialValues={initialValues}
        onSubmit={onDetailsFormSubmit}
        permission={{
          name: `${isAdd ? 'Add' : 'Edit'} Product`,
          type: isAdd ? 'Add' : 'Edit',
        }}
        submitting={submitting}
        innerRef={detailsRef}
        validationSchema={schema}
      >
        <FormikTextField
          id="name"
          name="name"
          placeholder="Enter the product name"
          label="Name"
          fullWidth
        />
        <FormikTextField
          id="description"
          name="description"
          placeholder="Enter the product description"
          label="Description"
          fullWidth
        />
        <FormikTextField
          id="uom"
          name="uom"
          placeholder="Enter the product uom"
          label="Unit of Measure"
          fullWidth
        />
        <FormikTextField
          id="compartmentFillingPreferenceUom"
          name="compartmentFillingPreferenceUom"
          placeholder="Enter the product compartment filling preference uom"
          label="Compartment Filling Preference Unit of Measure"
          fullWidth
        />
        <FormikTextField
          id="orderLineQuantityPreferenceUom"
          name="orderLineQuantityPreferenceUom"
          placeholder="Enter the product order line quantity preference uom"
          label="Order Line Quantity Preference Unit of Measure"
          fullWidth
        />
        <FormikTextField
          id="variabilityThreshold"
          name="variabilityThreshold"
          placeholder="Enter the product variability threshold"
          label="Variability Threshold"
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          fullWidth
        />
      </TemplateForm>
    </TemplateCard>
  );
};
