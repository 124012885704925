import React from 'react';
import {AdminIntegrationListIntegration as AdminIntegrationListIntegrationType} from '@onroadvantage/onroadvantage-api';
import {AdminTelematicsConfigContext} from '../AdminTelematicsConfigContext';
import {integrationScheduleApi} from '../../../../api';
import {unstable_batchedUpdates} from 'react-dom';
import {Loader} from '../../../loader';
import {AdminTelematicsConfigScheduleItem} from './AdminTelematicsConfigScheduleItem';

export const AdminTelematicsConfigSchedule: React.FC = () => {
  const {telematicsConfigId} = React.useContext(AdminTelematicsConfigContext);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [integrationSchedules, setIntegrationSchedules] =
    React.useState<AdminIntegrationListIntegrationType[]>();
  // const [providerName, setProviderName] = React.useState<string>();

  const loadSchedules = React.useCallback(async () => {
    setLoading(true);
    try {
      const response =
        await integrationScheduleApi.apiAdminIntegrationScheduleGet({
          telematicsConfigId,
        });
      if (response) {
        unstable_batchedUpdates(() => {
          setIntegrationSchedules(response.integrations);
          // setProviderName(response.providerName);
        });
      }
    } finally {
      setLoading(false);
    }
  }, [telematicsConfigId, setLoading, setIntegrationSchedules]);

  React.useEffect(() => {
    loadSchedules();
  }, [loadSchedules]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      {integrationSchedules?.map((is) => (
        <AdminTelematicsConfigScheduleItem key={is.name} item={is} />
      ))}
    </div>
  );
};
