import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

const TripStopOrderListStyles = (theme: Theme) =>
  createStyles({
    recipientLink: {color: theme.palette.text.primary},
  });

export const useTripStopOrderListStyles = makeStyles(TripStopOrderListStyles, {
  name: 'TripStopOrderListStyles',
});
