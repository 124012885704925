import React from 'react';
import {
  DriverDocumentContext,
  DriverDocumentContextProvider,
} from './DriverDocumentContext';
import {
  ITemplateControllerRoute,
  TemplateController,
} from '../../factory/template';
import {DriverDocumentList} from './DriverDocumentList';
import {DriverDocumentIdHandler} from './DriverDocumentIdHandler';
import {DriverDocumentDetails} from './DriverDocumentDetails';
import {DriverDocument} from './DriverDocument';
import _ from 'lodash';

export const DriverDocumentController: React.FC = () => {
  return (
    <DriverDocumentContextProvider>
      <DriverDocumentControllerComponent />
    </DriverDocumentContextProvider>
  );
};

const DriverDocumentControllerComponent: React.FC = () => {
  const {driverDocument, loading} = React.useContext(DriverDocumentContext);
  const routes: ITemplateControllerRoute[] = React.useMemo(
    () => [
      {title: 'Driver Document List', component: DriverDocumentList},
      {
        path: '/add',
        title: 'New Driver Document',
        name: 'Add DriverDocument',
        type: 'Add',
        component: () => <DriverDocumentDetails isAdd />,
      },
      {
        path: '/:driverDocumentId',
        title: (params) =>
          loading
            ? 'Driver Document Loading...'
            : driverDocument?.driverDocumentType?.name ??
              `Driver Document ${_.get(params, 'driverDocumentId', 'Single')}`,
        component: DriverDocument,
        IdHandler: DriverDocumentIdHandler,
      },
    ],
    [driverDocument?.driverDocumentType?.name, loading]
  );
  return <TemplateController rootPath="/driverdocumentlist" routes={routes} />;
};
