import React from 'react';
import {useHistory} from 'react-router-dom';
import {
  VehicleCompartment,
  VehicleCompartmentPost,
  VehicleType,
} from '@onroadvantage/onroadvantage-api';
import {FormikHelpers, FormikProps} from 'formik/dist/types';
import {vehicleCompartmentApi, vehicleTypeApi} from '../../../api';
import {TemplateTableContextProps} from '../../../factory/template';
import {VehicleTypeContext} from '../VehicleTypeContext';
import {useAppNotifications} from '../../../contexts';
import {RoleService} from '../../../service';
import {IVehicleTypeCompartmentForm} from './vehicleTypeCompartmentForm';

export interface VehicleTypeCompartmentContextProps
  extends TemplateTableContextProps<VehicleCompartment, VehicleType> {
  loadList: () => void;
  cleanupList: () => void;
  onDetailsFormSubmit: (
    values: IVehicleTypeCompartmentForm,
    formikHelpers: FormikHelpers<IVehicleTypeCompartmentForm>
  ) => void;
  setVehicleTypeCompartmentId: (value: number | undefined) => void;
  submitting: boolean;
  vehicleTypeCompartment?: VehicleCompartment;
  vehicleTypeCompartmentId?: number;
  numOfCompartments: number;
  setNumOfCompartments: React.Dispatch<React.SetStateAction<number>>;
  detailsRef?: React.Ref<FormikProps<IVehicleTypeCompartmentForm>>;
}

export const VehicleTypeCompartmentContext =
  React.createContext<VehicleTypeCompartmentContextProps>({
    // Template Table Defaults
    loading: false,
    list: [],
    currentPage: 1,
    // VehicleTypeCompartment
    loadList: async () => {},
    cleanupList: () => null,
    onDetailsFormSubmit: () => null,
    setVehicleTypeCompartmentId: () => null,
    numOfCompartments: 0,
    setNumOfCompartments: () => {},
    submitting: false,
  });

interface VehicleTypeCompartmentContextProviderProps {
  vehicleTypeCompartmentId?: number;
}

export const VehicleTypeCompartmentContextProvider: React.FC<
  VehicleTypeCompartmentContextProviderProps
> = ({children}) => {
  const history = useHistory();
  const notify = useAppNotifications();
  const {vehicleTypeId} = React.useContext(VehicleTypeContext);
  // Template Table
  const [singleLoading, setSingleLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [list, setList] = React.useState<VehicleCompartment[]>([]);

  const cleanupList = React.useCallback(() => setList([]), [setList]);

  const loadList = React.useCallback(async () => {
    setLoading(true);
    try {
      if (vehicleTypeId) {
        const response = await vehicleTypeApi.apiVehicleTypeVehicleTypeIdGet({
          vehicleTypeId: vehicleTypeId,
        });
        if (response) {
          setList(
            response.compartments?.sort((a, b) =>
              a.position > b.position ? 1 : a.position < b.position ? -1 : 0
            ) ?? []
          );
          if (response.compartments && response.compartments.length > 0)
            setNumOfCompartments(response.compartments.length);
        } else {
          notify('error', 'Error retrieving Vehicle Type Compartment List');
        }
      }
    } catch (e) {
      notify(
        'error',
        e.message ?? 'Error loading Vehicle Type Compartment List'
      );
    } finally {
      setLoading(false);
    }
  }, [notify, vehicleTypeId]);

  const handleDelete = React.useCallback(
    async (row: VehicleCompartment) => {
      setLoading(true);
      try {
        if (row.id) {
          await vehicleCompartmentApi.apiVehicleCompartmentVehicleCompartmentIdDelete(
            {vehicleCompartmentId: row.id}
          );
          await loadList();
        }
      } finally {
        setLoading(false);
      }
    },
    [loadList]
  );

  const handleAdd = React.useCallback(() => {
    history.push(`/vehicletypelist/${vehicleTypeId}/compartment/add`);
  }, [history, vehicleTypeId]);

  const handleNavigate = React.useCallback(
    (row: VehicleCompartment) => {
      history.push(`/vehicletypelist/${vehicleTypeId}/compartment/${row.id}`);
    },
    [history, vehicleTypeId]
  );
  // vehicleTypeCompartmentDimension
  const [vehicleTypeCompartment, setVehicleTypeCompartment] = React.useState<
    VehicleCompartment | undefined
  >();
  const [vehicleTypeCompartmentId, setVehicleTypeCompartmentId] =
    React.useState<number>();
  const [submitting, setSubmitting] = React.useState(false);
  const [numOfCompartments, setNumOfCompartments] = React.useState<number>(0);

  const detailsRef =
    React.useRef<FormikProps<IVehicleTypeCompartmentForm>>(null);

  const loadVehicleTypeCompartment = React.useCallback(async () => {
    setSingleLoading(true);
    try {
      if (vehicleTypeCompartmentId) {
        const response =
          await vehicleCompartmentApi.apiVehicleCompartmentVehicleCompartmentIdGet(
            {
              vehicleCompartmentId: vehicleTypeCompartmentId,
            }
          );
        setVehicleTypeCompartment(response);
      } else {
        setVehicleTypeCompartment(undefined);
      }
    } catch (e) {
      notify('error', e.message ?? 'Error loading Vehicle Type Compartment');
    } finally {
      setSingleLoading(false);
    }
  }, [vehicleTypeCompartmentId, notify]);

  const handleDetailsFormSubmit = React.useCallback(
    async (
      values: IVehicleTypeCompartmentForm,
      formikHelpers: FormikHelpers<IVehicleTypeCompartmentForm>
    ) => {
      setSubmitting(true);
      try {
        if (vehicleTypeId) {
          const newValues: Omit<VehicleCompartmentPost, 'position'> = {
            ...values,
            vehicleTypeId: vehicleTypeId,
          };
          if (vehicleTypeCompartment && vehicleTypeCompartment.id) {
            await vehicleCompartmentApi.apiVehicleCompartmentVehicleCompartmentIdPatch(
              {
                vehicleCompartmentId: vehicleTypeCompartment.id,
                body: {...newValues, position: vehicleTypeCompartment.position},
              }
            );
          } else {
            await vehicleCompartmentApi.apiVehicleCompartmentPost({
              body: {...newValues, position: list.length + 1},
            });
          }
          history.goBack();
          notify(
            'success',
            `${
              vehicleTypeCompartmentId ? 'Updated' : 'Added'
            } Vehicle Type Compartment`
          );
        } else {
          notify(
            'error',
            `Error ${
              vehicleTypeCompartmentId ? 'updating' : 'adding'
            } Vehicle Type Compartment`
          );
        }
      } catch (e) {
        notify(
          'error',
          e.message ??
            `Error ${
              vehicleTypeCompartmentId ? 'updating' : 'adding'
            } Vehicle Type Compartment`
        );
      } finally {
        formikHelpers.setSubmitting(false);
        setSubmitting(false);
      }
    },
    [
      history,
      list.length,
      notify,
      vehicleTypeCompartment,
      vehicleTypeCompartmentId,
      vehicleTypeId,
    ]
  );

  const value: VehicleTypeCompartmentContextProps = {
    // Template Table
    loading: singleLoading || loading,
    list,
    currentPage: 1,
    onAdd: RoleService.hasPermission('Add VehicleCompartment', 'Add')
      ? handleAdd
      : undefined,
    onDelete: handleDelete,
    onNavigate: handleNavigate,
    onRefresh: loadList,
    // VehicleTypeCompartment
    loadList,
    cleanupList,
    onDetailsFormSubmit: handleDetailsFormSubmit,
    numOfCompartments,
    setNumOfCompartments,
    setVehicleTypeCompartmentId,
    submitting,
    vehicleTypeCompartment,
    vehicleTypeCompartmentId,
    detailsRef,
  };

  React.useEffect(() => {
    loadVehicleTypeCompartment();
    loadList();
    return () => {
      setVehicleTypeCompartment(undefined);
      cleanupList();
    };
  }, [cleanupList, loadList, loadVehicleTypeCompartment]);

  return (
    <VehicleTypeCompartmentContext.Provider value={value}>
      {children}
    </VehicleTypeCompartmentContext.Provider>
  );
};
