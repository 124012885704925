import React from 'react';
import {Table} from '@devexpress/dx-react-grid-material-ui';
import {useTableStubCellStyles} from './TableStubCell.style';

export const TableStubCell: React.FC<Table.CellProps> = ({
  children,
  ...props
}) => {
  const classes = useTableStubCellStyles();
  return (
    <Table.StubCell className={classes.root} {...props}>
      {children}
    </Table.StubCell>
  );
};
