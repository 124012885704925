import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {
  PlanningOrder,
  PlanningSolution,
} from '@onroadvantage/onroadvantage-api';
import truckFleetPng from '../../img/svg/truckFleet.png';
import {getTripTAT} from '../../service/Util';
import _ from 'lodash';
import Chip from '@mui/material/Chip';

interface Props {
  planningSolution: PlanningSolution;
  isMap?: boolean;
}

class PlanningSolutionOverviewCard extends React.Component<Props> {
  render() {
    const {planningSolution} = this.props;

    let solutionTotalDistance = 0;
    let solutionTotalDuration = 0;
    if (planningSolution && planningSolution.planningMasterTrips) {
      planningSolution.planningMasterTrips.forEach((planningMasterTrip) => {
        // @ts-expect-error upgrade
        solutionTotalDistance += planningMasterTrip.trip.tripDistance;
        const tripTAT = getTripTAT(
          _.get(planningMasterTrip, 'trip.tripStart'),
          _.get(planningMasterTrip, 'trip.tripEnd')
        );
        solutionTotalDuration += tripTAT;
      });
    }

    const skills: {name: string; count: number}[] = [];
    const priorities: {name: number; count: number}[] = [];

    if (planningSolution && planningSolution.planningOrders) {
      planningSolution.planningOrders.forEach(
        (planningOrder: PlanningOrder) => {
          const skillNames = skills.map((skill) => skill.name);
          if (planningOrder.skill && skillNames.includes(planningOrder.skill)) {
            const skill = _.find(skills, {name: planningOrder.skill});
            if (skill) {
              skill.count += 1;
            }
          } else if (
            planningOrder.skill &&
            !skillNames.includes(planningOrder.skill)
          ) {
            skills.push({name: planningOrder.skill, count: 1});
          }
          const priorityNames = priorities.map((priority) => priority.name);
          if (
            planningOrder.priority &&
            priorityNames.includes(planningOrder.priority)
          ) {
            const priority = _.find(priorities, {name: planningOrder.priority});
            if (priority) {
              priority.count += 1;
            }
          } else if (
            planningOrder.priority &&
            !priorityNames.includes(planningOrder.priority)
          ) {
            priorities.push({name: planningOrder.priority, count: 1});
          }
        }
      );
    }
    //add flag

    return (
      <Card style={{maxWidth: 'fit-content'}}>
        <CardContent>
          <img
            alt="Vantage Logo"
            src={truckFleetPng}
            style={{marginBottom: 10}}
          />
          {planningSolution &&
            planningSolution.planningMasterTrips &&
            planningSolution.vehicles &&
            planningSolution.unassignedOrders && (
              <div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                  }}
                >
                  <div style={{flex: 1}}>Solution Status:</div>
                  <Chip
                    key={'status'}
                    label={`${planningSolution.status}: ${planningSolution.unassignedOrders.length} Unallocated`}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                  }}
                >
                  <div style={{flex: 1}}>Number of Fleet Used/Unused:</div>
                  <Chip
                    key={'planningSolution.planningMasterTrips.length'}
                    label={`${planningSolution.planningMasterTrips.length}/ ${
                      planningSolution.vehicles.length -
                      planningSolution.planningMasterTrips.length
                    }`}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                  }}
                >
                  <div style={{flex: 1}}>Total Distance (km):</div>
                  <Chip
                    key={'totalDistance'}
                    label={
                      !isNaN(solutionTotalDistance)
                        ? Math.round(solutionTotalDistance * 100) / 100
                        : '-'
                    }
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                  }}
                >
                  <div style={{flex: 1}}>Total Duration (min):</div>
                  <Chip
                    key={'totalDuration'}
                    label={Math.round(solutionTotalDuration * 100) / 100}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                  }}
                >
                  <div style={{flex: 1}}>Required Skills:</div>
                  {skills.map((skill, index) => {
                    return (
                      <Chip
                        key={index}
                        label={`${skill.name}: ${skill.count}`}
                        style={{height: 20, marginBottom: 2}}
                      />
                    );
                  })}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                  }}
                >
                  <div style={{flex: 1}}>Required Priorities:</div>
                  {priorities.map((priority, index) => {
                    return (
                      <Chip
                        key={index}
                        label={`${priority.name}: ${priority.count}`}
                        style={{height: 20, marginBottom: 2}}
                      />
                    );
                  })}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                  }}
                >
                  <div style={{flex: 1}}>Contract Optimisation Options:</div>
                  {planningSolution.contract?.planningOptimisationOptions?.map(
                    (option, index) => {
                      return (
                        <Chip
                          key={index}
                          label={`${_.startCase(option.key)}: ${option.value}`}
                          style={{height: 20, marginBottom: 2}}
                        />
                      );
                    }
                  )}
                </div>
              </div>
            )}
        </CardContent>
      </Card>
    );
  }
}

export default PlanningSolutionOverviewCard;
