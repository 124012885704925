import {Listing} from '@onroadvantage/onroadvantage-api';

export const CONTRACT_PRIORITY_OPTIONS: Listing[] = [
  {label: 'gps', value: 1},
  {label: 'mobile', value: 2},
];

export interface IContractDetailsForm {
  name: string | undefined /** Required **/;
  code: string | undefined /** Required **/;
  node: Listing | undefined /** Required **/;
  telematicsConfig: Listing | undefined /** Required **/;
  honorRestSchedule: boolean /** Required, Default True **/;
  driverScoringEnabled: boolean /** Required **/;
}

export interface IContractTaskTemplateInlineForm {
  acknowledgeEnabled?: boolean | undefined;
  forceSequence?: boolean | undefined;
  id?: number | undefined;
  mandatory?: boolean | undefined;
  nodeTypes?: Listing[];
  resetEnabled?: boolean | undefined;
  sequence: number;
  taskTemplate?: Listing;
}

export interface IContractTripTimesForm {
  shortBreakTrigger: number | undefined;
  shortBreakDuration: number | undefined;
  longBreakTrigger: number | undefined;
  longBreakDuration: number | undefined;
  maxShiftDrivingTime: number | undefined;
  shiftDuration: number | undefined;
  dailyBreakDuration: number | undefined;
  maxWeeklyDuty: number | undefined;
  weeklyBreak: number | undefined;
  drivingBanStart: Date | undefined /** Required **/;
  drivingBanEnd: Date | undefined /** Required **/;
  actualCalculationPostBuffer: number | undefined;
  actualCalculationPreBuffer: number | undefined;
  targetHoursPerDay: number | undefined;
  targetHoursPerVehiclePerDay: number | undefined;
  targetKmPerDay: number | undefined;
  targetKmPerVehiclePerDay: number | undefined;
  driverTripQueryPreToleranceHours: number | undefined /** Required **/;
  driverTripQueryPostToleranceHours: number | undefined /** Required **/;
}

export interface IContractTripNotificationsForm {
  emailTripsheet: boolean /** Required **/;
  etaConfidence: number | undefined /** Default 0.9 **/;
  voiceBroadcastEnabled: boolean | undefined;
  etaCalcEnabled: boolean | undefined;
}

export interface IContractTripExecutionForm {
  tripStopArrivalTimestampTaskTemplate?: Listing | undefined;
  tripStopDepartureTimestampTaskTemplate?: Listing | undefined;
  tripStopArrivalOdometerTaskTemplate?: Listing | undefined;
  tripStopDepartureOdometerTaskTemplate?: Listing | undefined;
  tripStopServiceStartTimestampTaskTemplate?: Listing | undefined;
  tripStopServiceEndTimestampTaskTemplate?: Listing | undefined;
  tripStopOdometerPriority: Listing | undefined /** Default gps **/;
  tripStopTimestampPriority: Listing | undefined /** Default gps **/;
  actualsStopMethodology: Listing | undefined /** Default gps **/;
  banOrderTripDoubleAllocation: boolean | undefined;
  tripEndDepartureTaskBan: boolean | undefined /** Default True **/;
  tripStartArrivalTaskBan: boolean | undefined /** Default True **/;
  orderLineActualLoadedQuantityEnabled:
    | boolean
    | undefined /** Default False **/;
  allowMultipleOrdersInCompartment: boolean | undefined /** Default False **/;
  speedTables: Listing[];
}

export interface IContractInspectionForm {
  mandatoryDailyVehicleInspectionEnabled:
    | boolean
    | undefined /** Default False **/;
  mandatoryDailyDriverInspectionEnabled:
    | boolean
    | undefined /** Default False **/;
  vehicleInspectionTaskTemplate?: Listing | undefined;
  driverInspectionTaskTemplate?: Listing | undefined;
}

export type IContractSetupForm = IContractDetailsForm &
  IContractTripTimesForm &
  IContractTripNotificationsForm &
  IContractTripExecutionForm &
  IContractInspectionForm;

export const CONTRACT_DETAILS_INITIAL_VALUES: IContractDetailsForm = {
  name: '',
  code: '',
  node: {value: undefined, label: undefined},
  telematicsConfig: {value: undefined, label: undefined},
  honorRestSchedule: true,
  driverScoringEnabled: false,
};

export const CONTRACT_TRIP_TIMES_INITIAL_VALUES: IContractTripTimesForm = {
  shortBreakTrigger: undefined,
  shortBreakDuration: undefined,
  longBreakTrigger: undefined,
  longBreakDuration: undefined,
  maxShiftDrivingTime: undefined,
  shiftDuration: undefined,
  dailyBreakDuration: undefined,
  maxWeeklyDuty: undefined,
  weeklyBreak: undefined,
  drivingBanStart: undefined,
  drivingBanEnd: undefined,
  actualCalculationPostBuffer: undefined,
  actualCalculationPreBuffer: undefined,
  targetHoursPerDay: undefined,
  targetHoursPerVehiclePerDay: undefined,
  targetKmPerDay: undefined,
  targetKmPerVehiclePerDay: undefined,
  driverTripQueryPreToleranceHours: undefined,
  driverTripQueryPostToleranceHours: undefined,
};

export const CONTRACT_TRIP_NOTIFICATIONS_INITIAL_VALUES: IContractTripNotificationsForm =
  {
    etaConfidence: 0.9,
    emailTripsheet: true,
    voiceBroadcastEnabled: false,
    etaCalcEnabled: false,
  };

export const CONTRACT_TRIP_EXECUTION_INITIAL_VALUES: IContractTripExecutionForm =
  {
    tripStopOdometerPriority: {label: 'gps', value: 1},
    tripStopTimestampPriority: {label: 'gps', value: 1},
    banOrderTripDoubleAllocation: false,
    tripEndDepartureTaskBan: true,
    tripStartArrivalTaskBan: true,
    orderLineActualLoadedQuantityEnabled: false,
    actualsStopMethodology: {label: 'gps', value: 1},
    allowMultipleOrdersInCompartment: false,
    speedTables: [],
  };

export const CONTRACT_INSPECTION_INITIAL_VALUES: IContractInspectionForm = {
  mandatoryDailyVehicleInspectionEnabled: false,
  mandatoryDailyDriverInspectionEnabled: false,
};

export const CONTRACT_SETUP_INITIAL_VALUES: IContractSetupForm = {
  ...CONTRACT_DETAILS_INITIAL_VALUES,
  ...CONTRACT_TRIP_TIMES_INITIAL_VALUES,
  ...CONTRACT_TRIP_NOTIFICATIONS_INITIAL_VALUES,
  ...CONTRACT_TRIP_EXECUTION_INITIAL_VALUES,
  ...CONTRACT_INSPECTION_INITIAL_VALUES,
};

export const CONTRACT_SETUP_STEPS = [
  'Details',
  'Task Templates By Site Type',
  'Trip Times',
  'Trip Execution',
  'Trip Notifications',
  'Inspection',
  'Review',
] as const;

export const CONTRACT_STEP_OPTIONS: {
  title: (typeof CONTRACT_SETUP_STEPS)[number];
  requiredFields: (keyof IContractSetupForm)[] | null;
}[] = [
  {
    title: 'Details',
    requiredFields: [
      'name',
      'code',
      'node',
      'telematicsConfig',
      'honorRestSchedule',
      'driverScoringEnabled',
    ],
  },
  {title: 'Task Templates By Site Type', requiredFields: null},
  {
    title: 'Trip Times',
    requiredFields: [
      'drivingBanEnd',
      'drivingBanStart',
      'driverTripQueryPreToleranceHours',
      'driverTripQueryPostToleranceHours',
    ],
  },
  {title: 'Trip Execution', requiredFields: ['emailTripsheet']},
  {title: 'Trip Notifications', requiredFields: null},
  {title: 'Inspection', requiredFields: null},
  {title: 'Review', requiredFields: null},
];
