import React from 'react';
import {DriverContext, DriverContextProps} from './DriverContext';
import {DriverDetails} from './driverDetails';
import {DriverUser} from './driverUser';
import {ITemplateTabControllerTab, TemplateTabs} from '../../factory/template';

export const Driver: React.FC = () => {
  const {loading, driverId} =
    React.useContext<DriverContextProps>(DriverContext);

  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {title: 'Details', element: <DriverDetails />},
    {title: 'User', element: <DriverUser />},
  ]);

  const path = React.useMemo(() => `/driverlist/${driverId}`, [driverId]);

  return <TemplateTabs path={path} tabs={tabs} loading={loading} />;
};
