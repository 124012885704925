import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

export const PlanningBoardTripSkeletonStyles = (_theme: Theme) =>
  createStyles({
    loaderContainer: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      zIndex: 1,
      transform: 'translate(-50%, -50%)',
    },
    rectSkeleton: {marginTop: 10},
  });

export const usePlanningBoardTripSkeletonStyles = makeStyles(
  PlanningBoardTripSkeletonStyles,
  {name: 'PlanningBoardTripSkeletonStyles'}
);
