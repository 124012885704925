import React from 'react';
import {Delete} from '@mui/icons-material';
import {DataTypeProvider} from '@devexpress/dx-react-grid';
import {VantageDialogButton} from '../../../../../components/dialog';
import {useTemplateTableActions} from '../../providers';
import {useInternalTableContext} from '../../TemplateTable';
import {Divider, Stack, Typography} from '@mui/material';
import {splitByCapitals} from '../../../../helpers';

export type MapMessage<T> = (row: T) => React.ReactNode | string | null;

const MapDeleteMessage: React.FC<DataTypeProvider.ValueFormatterProps> = ({
  row,
}) => {
  const {config} = useInternalTableContext();
  if (config.deleteMessage) {
    const title = config.deleteMessage(row).title;
    const items = config.deleteMessage(row).items;
    return (
      <div>
        <Typography variant="h4">{title}</Typography>
        <Divider sx={{my: 1, mx: -2.2}} />
        {Object.keys(items).map((key) => (
          <Stack
            key={key}
            direction="row"
            justifyContent="space-between"
            mb={0.5}
          >
            <Typography fontWeight={700}>{splitByCapitals(key)}:</Typography>
            <Typography>{items[key]}</Typography>
          </Stack>
        ))}
      </div>
    );
  }
  if (config.mapMessage) return <>{config.mapMessage(row)}</>;
  return null;
};

export const TableActionButtonDelete: React.FC<
  DataTypeProvider.ValueFormatterProps
> = ({row, ...otherProps}) => {
  const {onDelete} = useTemplateTableActions();
  const {loading, config} = useInternalTableContext();
  const [messageTitle] = React.useState<
    React.ReactNode | string | null | undefined
  >(config.mapMessageTitle && config.mapMessageTitle(row));

  const handleDelete = React.useCallback(() => {
    if (onDelete) {
      onDelete(row);
    }
  }, [row, onDelete]);

  if (!onDelete) {
    return null;
  }

  return (
    <VantageDialogButton
      title={<Delete />}
      dialogTitle={messageTitle ?? 'Are you sure you want to delete?'}
      dataCy="TemplateTableActionsDelete"
      onAccept={handleDelete}
      maxWidth="xs"
      disabled={loading}
      iconButton
      fullWidth
      enableDividers
    >
      <MapDeleteMessage row={row} {...otherProps} />
    </VantageDialogButton>
  );
};
