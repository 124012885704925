import React from 'react';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {SnackbarProvider} from 'notistack';
import {
  AppNotificationsContextProvider,
  SystemSnackbarProvider,
  RefetchProvider,
  WindowInfoContextProvider,
} from '.';

export const GlobalContextProvider: React.FC = ({children}) => (
  <SystemSnackbarProvider>
    {/*Context SnackbarProvider*/}
    <AppNotificationsContextProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <WindowInfoContextProvider>
          <SnackbarProvider maxSnack={3}>
            {/*Mobx SnackbarProvider*/}
            <RefetchProvider>{children}</RefetchProvider>
          </SnackbarProvider>
        </WindowInfoContextProvider>
      </LocalizationProvider>
    </AppNotificationsContextProvider>
  </SystemSnackbarProvider>
);
