import {decorate, observable, action} from 'mobx';
import {RoleAuth} from '@onroadvantage/onroadvantage-api';
import {
  clearPersist,
  stopPersist,
  isSynchronized,
  persistence,
  StorageAdapter,
} from 'mobx-persist-store';

function readStore(name: string) {
  return new Promise((resolve) => {
    const data = localStorage.getItem(name);
    resolve(data);
  });
}

function writeStore(name: string, content: string) {
  return new Promise<void>((resolve) => {
    localStorage.setItem(name, content);
    resolve();
  });
}

interface Auth {
  isOidcSigningOut?: boolean;
  authenticated: boolean;
  oauthAuthenticated: boolean;
  active: boolean;
  token: string;
  email: string;
  roles: RoleAuth[];
}

export default class AuthStore {
  private auth: Auth | null = null;

  get getAuth() {
    return this.auth;
  }

  setAuth = (auth: Auth | null) => {
    this.auth = auth;
  };

  setBugSnagUser(event: any) {
    event.setUser('X', this.auth?.email, this.auth?.email);
  }

  updateUserMe = (roles: RoleAuth[], email?: string | undefined) => {
    if (this.auth) {
      this.auth.roles = roles;
      if (
        (this.auth.email == null || this.auth.email === '') &&
        email != null
      ) {
        this.auth.email = email;
      }
    }
  };

  /* persist function */
  clearStore = () => {
    return clearPersist(this);
  };

  /* persist function */
  stopPersist = () => {
    stopPersist(this);
  };

  /* persist function */
  get isSynchronized() {
    return isSynchronized(this);
  }

  setOidcSigningOut = (value: boolean) => {
    if (this.auth) {
      this.auth.isOidcSigningOut = value;
    }
  };
}

decorate(AuthStore, {
  // @ts-expect-error upgrade
  auth: observable,
  setAuth: action,
  setBugSnagUser: action,
});

persistence({
  name: 'AuthStore',
  properties: ['auth'],
  adapter: new StorageAdapter({
    // @ts-expect-error upgrade
    read: readStore,
    write: writeStore,
  }),
})(AuthStore);
