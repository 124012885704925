import React from 'react';
import {
  CardHeader,
  CardContent,
  Card,
  Avatar,
  Typography,
  IconButton,
  Collapse,
} from '@mui/material';
import {ExpandMore} from '@mui/icons-material';
import {useRecipientCardStyles} from './RecipientCard.style';
import {IRecipientCardDetail} from '../OrderRecipientContext';

interface RecipientCardProps {
  title: string;
  avatar: React.ReactNode;
  orderNumber?: string;
  details: IRecipientCardDetail[];
  externalOpen?: boolean;
  externalHandler?: () => void;
  disableCollapse?: boolean;
  defaultOpen?: boolean;
}

export const RecipientCard: React.FC<RecipientCardProps> = ({
  title,
  avatar,
  details,
  orderNumber,
  children,
  externalOpen,
  externalHandler,
  disableCollapse = false,
  defaultOpen = true,
  ...other
}) => {
  const classes = useRecipientCardStyles();
  const [open, setOpen] = React.useState(defaultOpen);
  const toggle = () =>
    setOpen((prevCollapse) =>
      disableCollapse === false ? !prevCollapse : false
    );

  const openState = React.useMemo(
    () => (externalOpen === undefined ? open : externalOpen),
    [open, externalOpen]
  );

  return (
    <Card className={classes.root} {...other}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            {avatar}
          </Avatar>
        }
        title={title}
        subheader={orderNumber}
        titleTypographyProps={{
          component: 'h3',
          variant: 'subtitle1',
          color: 'textSecondary',
        }}
        className={classes.header}
        onClick={externalHandler ?? toggle}
        action={
          !disableCollapse && (
            <IconButton size="large">
              <ExpandMore
                style={{
                  transform: openState ? 'rotate(180deg)' : 'none',
                  transition: 'transform 0.4s ease-in-out',
                }}
              />
            </IconButton>
          )
        }
      />
      <CardContent
        component={Collapse}
        in={openState}
        className={classes.content}
      >
        {details.map((detail) => (
          <div key={detail.label} className={classes.row}>
            <Typography variant="body1" className={classes.label}>
              {detail.label}
            </Typography>
            <Typography variant="body1">{detail.value}</Typography>
          </div>
        ))}
        {children}
      </CardContent>
    </Card>
  );
};
