import React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import MicIcon from '@mui/icons-material/Mic';
import Avatar from '@mui/material/Avatar';
import AdhocMapSelectStep, {MapSelectionItem} from './AdhocMapSelectStep';
import {Loader} from '../../loader';
import {onNextGroupSelectInputType} from './AdhocSelectMessageStep';
import AdhocMapSelectRecipients from './AdhocMapSelectRecipients';
import AdhocMapSelectMessageStep from './AdhocMapSelectMessageStep';
import AdhocMapConfirmBroadcastStep, {
  BroadCastInfoType,
} from './AdhocMapConfirmBroadcastStep';
import {broadcastApi} from '../../../api';
import {mergeDateAndTime} from '../../../service/Util';

interface Props {
  style?: any;
}

interface State {
  specificItems: MapSelectionItem[];
  messageType: any[];
  loading: boolean;
  finished: boolean;
  stepIndex: number;
  message?: string;
  messageHeading: string | null;
  uploadIdentifier?: string;
  identifier?: string;
  heading?: string;
  scheduledDate?: Date;
  scheduledTime?: Date;
  mapSelectionItems: MapSelectionItem[];
}

export default class AdhocWizard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      specificItems: [],
      messageType: [],
      loading: false,
      finished: false,
      stepIndex: 0,
      message: undefined,
      identifier: undefined,
      heading: undefined,
      messageHeading: null,
      uploadIdentifier: undefined,
      scheduledDate: undefined,
      scheduledTime: undefined,
      mapSelectionItems: [],
    };
  }

  handleNext = () => {
    const {stepIndex} = this.state;
    this.setState({
      stepIndex: stepIndex + 1,
      finished: stepIndex >= 3,
    });
  };

  handleBack = () => {
    const {stepIndex} = this.state;
    this.setState({
      stepIndex: stepIndex - 1,
    });
  };

  handleMessageUpdate = ({
    identifier,
    message,
    heading,
    scheduledDate,
    scheduledTime,
  }: onNextGroupSelectInputType) => {
    this.setState({identifier, message, heading, scheduledDate, scheduledTime});
    this.handleNext();
  };

  handleChangeMapItems = (mapSelectionItems: MapSelectionItem[]) => {
    this.setState({mapSelectionItems});
    this.handleNext();
  };

  handleSpecifyMapItems = (specificItems: MapSelectionItem[]) => {
    this.setState({specificItems});
    this.handleNext();
  };

  startAdhocCall = () => {
    this.setState({loading: true});
    this.handleAdhocCall();
    this.handleNext();
  };

  handleAdhocCall = async () => {
    const {
      specificItems,
      message,
      heading,
      identifier,
      scheduledDate,
      scheduledTime,
    } = this.state;
    const mergedDateTime = mergeDateAndTime({
      date: scheduledDate,
      time: scheduledTime,
    });

    const vehicleIds = specificItems.map((item) => {
      return item.id;
    });

    const requestParameters: any = {
      body: {
        heading: heading ?? '',
        message: message ?? '',
        messageUploadIdentifier: identifier ?? '',
        vehicleIds,
        scheduledDateTime: mergedDateTime ? mergedDateTime : new Date(),
      },
    };

    await broadcastApi.apiBroadcastPost(requestParameters);
    this.setState({loading: false});
  };

  getStepContent = (stepIndex: number) => {
    const {
      specificItems,
      messageType,
      message,
      uploadIdentifier,
      identifier,
      heading,
      scheduledDate,
      scheduledTime,
    } = this.state;

    const callInfo: BroadCastInfoType = {
      specificItems,
      messageType,
      message,
      uploadIdentifier,
      identifier,
      heading,
      scheduledDate,
      scheduledTime,
    };

    switch (stepIndex) {
      case 0:
        return (
          <AdhocMapSelectStep
            updateGeoSelectionLayers={() => {}}
            onNext={this.handleChangeMapItems}
          />
        );

      case 1:
        return (
          <AdhocMapSelectRecipients
            items={this.state.mapSelectionItems}
            onNext={this.handleSpecifyMapItems}
            onBack={this.handleBack}
          />
        );

      case 2:
        return (
          <AdhocMapSelectMessageStep
            initialValues={{
              identifier,
              message,
              heading,
              scheduledDate,
              scheduledTime,
            }}
            onBack={this.handleBack}
            onNext={this.handleMessageUpdate}
          />
        );

      case 3:
        return (
          <AdhocMapConfirmBroadcastStep
            broadcastInfo={callInfo}
            onNext={this.startAdhocCall}
            onBack={this.handleBack}
          />
        );

      // case 2:
      //   return <SelectNumberStep onNext={this.handleNumberUpdate} />;
      default:
        return '';
    }
  };

  render() {
    const {finished, stepIndex} = this.state;
    const contentStyle = {margin: '0 16px'};

    return (
      <Card style={this.props.style}>
        <CardHeader
          title="Create Group Broadcast"
          avatar={
            <Avatar>
              <MicIcon />
            </Avatar>
          }
        />
        <CardContent>
          <div>
            <div style={{width: '100%', maxWidth: 1200, margin: 'auto'}}>
              <Stepper activeStep={stepIndex}>
                <Step>
                  <StepLabel>Map Recipients</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Confirm Vehicles</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Select Message</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Confirm and Send</StepLabel>
                </Step>
              </Stepper>
              <div style={contentStyle}>
                {finished ? (
                  <div>
                    {this.state.loading ? (
                      <Loader size={50} />
                    ) : (
                      <div>Your voice broadcast submission was successful.</div>
                    )}
                  </div>
                ) : (
                  <div>
                    <div>{this.getStepContent(stepIndex)}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    );
  }
}
