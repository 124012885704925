import {DateTime} from 'luxon';
import {IEventsPaneSearchForm} from '../eventsPaneSearch/EventsPaneSearchForm';

export const getDateRange = (
  value: number,
  unit: IEventsPaneSearchForm['dateRangeUnit']
) => {
  if (unit === 'days') {
    const endDate = DateTime.now().endOf('day');
    const startDate = endDate.minus({days: value}).startOf('day');

    return {startDate: startDate.toJSDate(), endDate: endDate.toJSDate()};
  }

  const endDate = DateTime.now();
  const startDate = endDate.minus({hours: value});

  return {startDate: startDate.toJSDate(), endDate: endDate.toJSDate()};
};
