import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import {DateTimeFormatService} from '../../../../service/FormatService';

export interface DateTimeTypeFormatterProps {
  value: any;
}

export const DateTimeTypeFormatter: React.FC<DateTimeTypeFormatterProps> = ({
  value,
}) => {
  if (!value) {
    return <>-</>;
  }

  return <>{new DateTimeFormatService().formatDateTime(value)}</>;
};

export const DateTimeTypeProvider: React.ComponentType<
  DataTypeProviderProps
> = (props) => (
  <DataTypeProvider
    data-test="datetime-provider"
    formatterComponent={DateTimeTypeFormatter}
    {...props}
  />
);
