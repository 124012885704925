import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

const ReportControllerStyles = (theme: Theme) =>
  createStyles({
    breadcrumbs: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      marginBottom: theme.spacing(2),

      borderBottomColor: theme.palette.divider,
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      minHeight: 10,
    },
    root: {},
    link: {
      cursor: 'pointer',
    },
  });

export const useReportControllerStyles = makeStyles(ReportControllerStyles, {
  name: 'ReportControllerStyles',
});
