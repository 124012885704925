import React from 'react';
import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Zoom,
} from '@mui/material';
import {FiberManualRecord, InfoOutlined} from '@mui/icons-material';
import {usePlanningBoardToolbarStyles} from './PlanningBoardToolbar.style';

export const PlanningBoardToolbarLegend: React.FC = () => {
  const classes = usePlanningBoardToolbarStyles();
  return (
    <Tooltip
      placement="bottom"
      classes={{tooltip: classes.tooltip}}
      TransitionComponent={Zoom}
      title={
        <React.Fragment>
          <ListItem>
            <ListItemIcon>
              <FiberManualRecord className={classes.bookingMasterTrips} />
            </ListItemIcon>
            <ListItemText primary="Booking Trips" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <FiberManualRecord className={classes.maintenanceMasterTrips} />
            </ListItemIcon>
            <ListItemText primary="Maintenance Trips" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <FiberManualRecord className={classes.masterMasterTrips} />
            </ListItemIcon>
            <ListItemText primary="Master Trips" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <FiberManualRecord className={classes.planningMasterTrips} />
            </ListItemIcon>
            <ListItemText primary="Planning Trips" />
          </ListItem>
        </React.Fragment>
      }
    >
      <IconButton
        className={classes.infoIcon}
        disableFocusRipple
        disableRipple
        disableTouchRipple
        size="medium"
      >
        <InfoOutlined fontSize="inherit" />
      </IconButton>
    </Tooltip>
  );
};
