import React from 'react';
import {
  NUMBER_ERROR_MESSAGE,
  TemplateCard,
  TemplateForm,
} from '../../factory/template';
import {DashboardContext} from './DashboardContext';
import {Dashboard} from '@onroadvantage/onroadvantage-api';
import * as Yup from 'yup';
import {
  FormikAutocompleteHooks,
  FormikSwitch,
  FormikTextField,
} from '../formik';
import {AutocompleteOptionType, TAutocompleteOnChange} from '../autocomplete';
import {getOption} from '../../service/Util';

export interface IDashboardForm {
  name: string;
  viewName: string;
  type: AutocompleteOptionType | null;
  externalReportId: string;
  lastRefresh: Date | null | undefined;
  refreshFrequency: number;
  filterCriticalEventTypes: boolean;
  filterOperationalEventTypes: boolean;
}

const schema: Yup.SchemaOf<IDashboardForm> = Yup.object({
  name: Yup.string().required('Required'),
  viewName: Yup.string().required('Required'),
  type: Yup.object()
    .shape({
      value: Yup.string().required('Required'),
      label: Yup.string(),
    })
    .nullable()
    .required('Required'),
  externalReportId: Yup.string().required('Required'),
  lastRefresh: Yup.date().nullable(),
  refreshFrequency: Yup.number()
    .typeError(NUMBER_ERROR_MESSAGE)
    .required('Required'),
  filterCriticalEventTypes: Yup.bool().required('Required'),
  filterOperationalEventTypes: Yup.bool().required('Required'),
});

const getInitialValues = (
  dashboard?: Dashboard | null
): IDashboardForm | undefined => {
  if (dashboard) {
    return {
      name: dashboard.name,
      viewName: dashboard.viewName,
      type: getOption(dashboard, 'type', 'type') ?? {},
      externalReportId: dashboard.externalReportId,
      lastRefresh: dashboard.lastRefresh,
      refreshFrequency: dashboard.refreshFrequency,
      filterCriticalEventTypes:
        dashboard.type !== 'Native'
          ? false
          : dashboard.filterCriticalEventTypes,
      filterOperationalEventTypes:
        dashboard.type !== 'Native'
          ? false
          : dashboard.filterOperationalEventTypes,
    };
  }
  return undefined;
};

export const DashboardForm: React.FC<{isAdd?: boolean}> = ({isAdd}) => {
  const {
    detailsRef,
    loading,
    loadingSingleItem,
    submitting,
    dashboard,
    onDetailsFormSubmit,
  } = React.useContext(DashboardContext);
  const [initialValues, setInitialValues] = React.useState<IDashboardForm>();
  const [dashboardTypes] = React.useState<AutocompleteOptionType[]>([
    {
      value: 'DataStudio',
      label: 'DataStudio',
    },
    {
      value: 'Native',
      label: 'Native',
    },
    {
      value: 'OQLIS',
      label: 'OQLIS',
    },
  ]);

  React.useEffect(() => {
    const values = getInitialValues(dashboard);

    if (values) {
      setInitialValues(values);
    } else if (isAdd) {
      setInitialValues({
        name: '',
        viewName: '',
        type: {value: undefined, label: undefined},
        externalReportId: '',
        lastRefresh: null,
        filterCriticalEventTypes: false,
        filterOperationalEventTypes: false,
        refreshFrequency: 0,
      });
    }
  }, [isAdd, dashboard, setInitialValues]);

  const handleChange = React.useCallback<TAutocompleteOnChange>(
    (_event, value) => {
      if (
        !Array.isArray(value) &&
        (value as AutocompleteOptionType)?.value !== 'Native'
      ) {
        detailsRef?.current?.setFieldValue('filterCriticalEventTypes', false);
        detailsRef?.current?.setFieldValue(
          'filterOperationalEventTypes',
          false
        );
      }
    },
    [detailsRef]
  );

  return (
    <TemplateCard
      title={`Dashboard Details ${isAdd ? 'Add' : 'Edit'}`}
      loading={loading || loadingSingleItem}
    >
      <TemplateForm<IDashboardForm>
        initialValues={initialValues}
        onSubmit={onDetailsFormSubmit}
        permission={{
          name: `${isAdd ? 'Add' : 'Edit'} Dashboard`,
          type: isAdd ? 'Add' : 'Edit',
        }}
        failedMessage="No values found. Make sure view_name was correct."
        submitting={submitting}
        innerRef={detailsRef}
        validationSchema={schema}
      >
        {({values}) => (
          <>
            <FormikTextField
              name="name"
              placeholder="Enter the dashboard name"
              label="Name"
              fullWidth
            />
            <FormikTextField
              name="viewName"
              placeholder="Enter the dashboard view name (Native Dashes only)"
              label="DB View Name (Native Dashes only)"
              fullWidth
            />
            <FormikAutocompleteHooks
              id="type"
              label="Type"
              name="type"
              options={dashboardTypes}
              onChange={handleChange}
              placeholder="Enter and select a value"
            />
            <FormikTextField
              name="category"
              placeholder="Enter the dashboard category"
              label="Category"
              fullWidth
            />
            <FormikTextField
              name="externalReportId"
              placeholder="Enter the dashboard external report id"
              label="External Report Id (i.e 1nn-qtYOURqkGOkNwe1qHuWqcA0a2xlzW/page/iSiM)"
              fullWidth
            />
            <FormikTextField
              name="refreshFrequency"
              type="number"
              placeholder="Enter the dashboard refresh frequency (in seconds) (Native Dashes only)"
              label="Refresh Frequency (in seconds) (Native Dashes only)"
              fullWidth
            />
            <FormikTextField
              disabled
              name="lastRefresh"
              placeholder="Last Refresh Occurred:"
              label="Last Refresh Occurred:"
              fullWidth
            />
            <FormikSwitch
              label="Filter Critical Event Types (Native Dashes only)"
              name="filterCriticalEventTypes"
              disabled={values.type?.value !== 'Native'}
            />
            <FormikSwitch
              label="Filter Operational Event Types (Native Dashes only)"
              name="filterOperationalEventTypes"
              disabled={values.type?.value !== 'Native'}
            />
          </>
        )}
      </TemplateForm>
    </TemplateCard>
  );
};
