import React from 'react';
import {
  ApiWorkflowGetRequest,
  TransporterListingResponse,
  WebWorkflowOptimised,
  WebWorkflowOptimisedListResponse,
} from '@onroadvantage/onroadvantage-api';
import {workflowApi, getAuthHeaderValues} from '../../api';
import {TemplateTableContextProps} from '../../factory/template';
import {useTemplateTable, TLoadList} from '../../factory/template';
import {useAppNotifications} from '../../contexts';
import {WorkflowDetailsController} from './workflowDetails';
import WorkflowService from '../../service/WorkflowService';
import FileSaver from 'file-saver';

export interface WorkflowContextProps
  extends TemplateTableContextProps<
    WebWorkflowOptimised,
    WebWorkflowOptimisedListResponse
  > {
  submitting?: boolean;
}
export const WorkflowContext = React.createContext<WorkflowContextProps>({
  loading: false,
  list: [],
  currentPage: 1,
  loadList: async () => {},
});

export const WorkflowContextProvider: React.FC = ({children}) => {
  const notify = useAppNotifications();
  const [
    {
      currentPage,
      pageSize,
      pageTotal,
      sorting,
      hasPermission,
      itemTotal,
      list,
      loading,
      filters,
      isDaterangeFilterActive,
    },
    {
      getRequestObj,
      getResponse,
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      cleanupList,
      setLoading,
    },
  ] = useTemplateTable<WebWorkflowOptimised, ApiWorkflowGetRequest>({
    enableDateRangeFilter: true,
    editPermission: 'Edit Workflow',
    addPermission: 'Add Workflow',
    deletePermission: 'Delete Workflow',
    downloadPermission: 'Workflow ListDownload',
    viewPermission: 'Workflow List',
  });

  const loadList = React.useCallback<TLoadList<TransporterListingResponse>>(
    async (options) => {
      setLoading(true);
      try {
        const requestObj = getRequestObj(
          [
            'contractCode',
            'driverName',
            'endDate',
            'startDate',
            'taskTypeName',
            'vehicleFleetNumber',
            'vehicleRegistrationNumber',
            'workflowConfigName',
            'yardName',
          ],
          options
        );

        return getResponse(
          await workflowApi.apiWorkflowGet({
            ...requestObj,
            orderBy: requestObj.orderBy ?? 'taskProgress',
          }),
          options
        );
      } catch (e) {
        notify('error', e.message ?? 'Failed to load Transporter List');
      } finally {
        setLoading(false);
      }
    },

    [getRequestObj, getResponse, notify, setLoading]
  );

  const handleRefresh = React.useCallback(async () => {
    await loadList();
  }, [loadList]);

  const handleDelete = React.useCallback(
    async (row: WebWorkflowOptimised) => {
      setLoading(true);
      try {
        if (row.id) {
          await workflowApi.apiWorkflowWorkflowIdDelete({workflowId: row.id});
          notify('success', 'Deleted Workflow');
          await loadList();
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to delete Workflow');
      } finally {
        setLoading(false);
      }
    },
    [loadList, notify, setLoading]
  );
  const handleDownload = React.useCallback(
    async (row: WebWorkflowOptimised) => {
      setLoading(true);
      try {
        const workflowId = row.id;
        if (getAuthHeaderValues !== null && workflowId) {
          const link = await WorkflowService.getDocumentLink(
            workflowId,
            'Workflow'
          );
          const headerValues = getAuthHeaderValues();
          const headers = new Headers();
          if (headerValues) headers.set(headerValues.name, headerValues.value);

          const response = await fetch(link, {
            method: 'GET',
            mode: 'cors',
            headers,
          });

          if (response.ok) {
            const blob = await response.blob();
            FileSaver.saveAs(blob, `WORKFLOW_${workflowId}.pdf`);
          }
          notify('success', 'Successfully Downloaded Workflow Pdf');
        }
      } catch (e) {
        notify('error', 'Failed to download WORKFLOW');
      } finally {
        setLoading(false);
      }
    },
    [notify, setLoading]
  );

  const value: WorkflowContextProps = {
    list,
    loadList,
    hasPermission,
    loading,
    cleanupList,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    isDaterangeFilterActive,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onDownloadItem: handleDownload,
    onDelete: handleDelete,
    onRefresh: handleRefresh,
    rowDetail: WorkflowDetailsController,
    grouping: [{columnName: 'status'}],
  };

  return (
    <WorkflowContext.Provider value={value}>
      {children}
    </WorkflowContext.Provider>
  );
};
