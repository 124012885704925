import {makeStyles, createStyles} from '@mui/styles';

const TripStopTaskModalStyles = () =>
  createStyles({
    actions: {justifyContent: 'flex-start'},
    carousel: {
      outline: 0,
      pointerEvents: 'none',
      '& focus': {outline: 0, border: 0},
      '& active': {outline: 0, border: 0},
    },
    listItemText: {margin: 0},
    slide: {
      '& img': {
        pointerEvent: 'image',
        marginBottom: 10,
        minWidth: '100%',
        maxWidth: '100%',
        maxHeight: window.innerHeight * 0.7,
        objectFit: 'contain',
        objectPosition: 'center',
      },
    },
    taskActions: {paddingTop: 20, paddingBottom: 0},
    title: {paddingBottom: 0},
  });

export const useTripStopTaskModalStyles = makeStyles(TripStopTaskModalStyles, {
  name: 'TripStopTaskModalStyles',
});
