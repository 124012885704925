import React from 'react';
import {useFormikContext} from 'formik';
import {Stack, Tab, Tabs} from '@mui/material';
import {usePlanningBoardGanttContext} from '../../planningBoardContext';
import {usePlanningBoardTripContext} from './planningBoardTripContext';
import {IPlanningBoardTripPanelForm} from './planningBoardTripForm';
import {PlanningBoardTripDetails} from './PlanningBoardTripDetails';
import {PlanningBoardTripCompartments} from './planningBoardTripCompartments';
import {PlanningBoardTripSkeleton} from './planningBoardTripSkeleton';
import {Loader} from '../../../loader';
import {PlanningBoardTripSummary} from './PlanningBoardTripSummary';
import {PlanningBoardTripClose} from './PlanningBoardTripClose';

export const PlanningBoardTrip: React.FC = () => {
  const {initialValues, isSubmitting} =
    useFormikContext<IPlanningBoardTripPanelForm>();
  const {
    masterTrip,
    loadingTripPreview,
    loadingInitialDetails,
    onLoadInitialDetails,
    setMasterTrip,
    setMasterTripType,
  } = usePlanningBoardTripContext();
  const {loadingCreateMasterTrip, selectedGanttItem} =
    usePlanningBoardGanttContext();
  const [tabValue, setTabValue] = React.useState<string>('Details');

  const handleTabChange = React.useCallback((event: any, value: string) => {
    setTabValue(value);
  }, []);

  React.useEffect(() => {
    if (!loadingCreateMasterTrip && selectedGanttItem?.id) {
      onLoadInitialDetails(initialValues);
    }
    return () => {
      setMasterTrip(undefined);
      setMasterTripType(undefined);
    };
  }, [
    loadingCreateMasterTrip,
    selectedGanttItem,
    initialValues,
    onLoadInitialDetails,
    setMasterTrip,
    setMasterTripType,
  ]);

  if (loadingInitialDetails || isSubmitting || loadingTripPreview) {
    return (
      <Stack flex={1}>
        <PlanningBoardTripSkeleton />
      </Stack>
    );
  }

  if (!masterTrip || !selectedGanttItem?.data) {
    return null;
  }

  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between" pr={2}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          // textColor="primary"
        >
          <Tab label="Details" value="Details" />
          <Tab label="Compartments" value="Compartments" />
        </Tabs>
        {loadingTripPreview ? (
          <Loader size={35} disableFullWidth />
        ) : (
          <PlanningBoardTripClose />
        )}
      </Stack>
      <PlanningBoardTripSummary />
      {tabValue === 'Details' ? (
        <PlanningBoardTripDetails />
      ) : tabValue === 'Compartments' ? (
        <PlanningBoardTripCompartments />
      ) : null}
    </Stack>
  );
};
