import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import {DateTimeFormatService} from '../../../../service/FormatService';

export interface DateTypeFormatterProps {
  value: any;
}

export const DateTypeFormatter: React.FC<DateTypeFormatterProps> = ({
  value,
}) => {
  if (!value) {
    return <>-</>;
  }

  return <>{new DateTimeFormatService().formatDate(value)}</>;
};

export const DateTypeProvider: React.ComponentType<DataTypeProviderProps> = (
  props
) => (
  <DataTypeProvider
    data-test="date-provider"
    formatterComponent={DateTypeFormatter}
    {...props}
  />
);
