import React from 'react';
import {
  ApiContractBroadcastTemplateGetRequest,
  ContractBroadcastTemplate,
  ContractBroadcastTemplateList,
} from '@onroadvantage/onroadvantage-api';
import {contractBroadcastTemplateApi} from '../../../api';
import {
  TemplateTableContextProps,
  TLoadList,
  TOnInlineAdd,
  TOnInlineEdit,
  useTemplateTable,
} from '../../../factory/template';
import {useAppNotifications} from '../../../contexts';
import {useContractContext} from '../contractContext';
import * as Yup from 'yup';

export type ContractBroadcastTemplateListContextProps =
  TemplateTableContextProps<
    ContractBroadcastTemplate,
    ContractBroadcastTemplateList
  >;

export const ContractBroadcastTemplateListContext =
  React.createContext<ContractBroadcastTemplateListContextProps>({
    loading: false,
    list: [],
    currentPage: 1,
    loadList: async () => {},
  });

export const useContractBroadcastTemplateListContext = () =>
  React.useContext(ContractBroadcastTemplateListContext);

export const ContractBroadcastTemplateListContextProvider: React.FC = ({
  children,
}) => {
  const {contractId, loadingContract} = useContractContext();
  const notify = useAppNotifications();

  const [
    {
      // States
      currentPage,
      filters,
      itemTotal,
      hasPermission,
      list,
      loading,
      pageSize,
      pageTotal,
      sorting,
    },
    {
      // Getters
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
    },
  ] = useTemplateTable<
    ContractBroadcastTemplate,
    ApiContractBroadcastTemplateGetRequest
  >({
    editPermission: 'Edit Contract',
    addPermission: 'Add Contract',
    deletePermission: 'Delete Contract',
    downloadPermission: 'Contract ListDownload',
    viewPermission: 'Contract List',
  });
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const loadList = React.useCallback<TLoadList<ContractBroadcastTemplateList>>(
    async (options) => {
      setLoading(true);
      try {
        const requestObj = getRequestObj(['title', 'message'], options);
        const response =
          await contractBroadcastTemplateApi.apiContractBroadcastTemplateGet({
            ...requestObj,
            contractIds: contractId ? [contractId] : undefined,
          });
        return getResponse(response, options);
      } catch (e) {
        notify('error', 'Failed to load contract broadcast template list');
      } finally {
        setLoading(false);
      }
    },
    [contractId, getRequestObj, getResponse, notify, setLoading]
  );

  const handleDelete = React.useCallback(
    async (row: ContractBroadcastTemplate) => {
      setLoading(true);
      try {
        if (row.id) {
          await contractBroadcastTemplateApi.apiContractBroadcastTemplateContractBroadcastTemplateIdDelete(
            {
              contractBroadcastTemplateId: row.id,
            }
          );
          notify('success', 'Deleted contract broadcast template');
          await loadList();
        }
      } catch (e) {
        notify('error', 'Failed to delete contract broadcast template');
      } finally {
        setLoading(false);
      }
    },
    [loadList, notify, setLoading]
  );

  const handleInlineAdd = React.useCallback<TOnInlineAdd>(
    async (changes) => {
      setSubmitting(true);
      try {
        if (contractId && changes.length > 0) {
          const {title, message} = changes[0];

          if (title && message) {
            await contractBroadcastTemplateApi.apiContractBroadcastTemplatePost(
              {
                body: {contractId, title, message},
              }
            );
            notify('success', 'Added contract broadcast template');
            await loadList();
          } else {
            notify('error', 'Title and Message are required');
          }
        }
      } catch (e) {
        notify('error', 'Failed to add contract broadcast template');
      } finally {
        setSubmitting(false);
      }
    },
    [contractId, loadList, notify]
  );

  // Separate Functions for validating whether the 'title' or 'message' field is a non-empty string
  const isValidTitle = (title: string | undefined) => !!title?.trim();
  const isValidMessage = (message: string | undefined) => !!message?.trim();

  // The main function for handling inline edits of contract broadcast templates
  const handleInlineEdit = React.useCallback<TOnInlineEdit>(
    async (changes) => {
      setSubmitting(true); // Indicate that an update is in progress
      try {
        // if Contract ID is missing, notify the user and return early
        if (!contractId) {
          notify('error', 'Contract ID is missing.');
          return;
        }

        // Filter out changes that have a contract broadcast template ID present in the current 'list'
        const validChanges = changes.filter((change) => {
          const contractBroadcastTemplateId = parseInt(change.id);
          return list.some((item) => item.id === contractBroadcastTemplateId);
        });

        let hasUpdated = false; // Flag to check if any successful updates occurred
        let hasValidationErrors = false; // Flag to check if any validation errors occurred

        for (const change of validChanges) {
          const contractBroadcastTemplateId = parseInt(change.id);
          const title = change.newValues.title as string | undefined;
          const message = change.newValues.message as string | undefined;

          if (isValidTitle(title) || isValidMessage(message)) {
            // At least one of 'title' or 'message' is a non-empty string
            const updatedFields: {title?: string; message?: string} = {};

            if (isValidTitle(title)) {
              updatedFields.title = title;
            }

            if (isValidMessage(message)) {
              updatedFields.message = message;
            }

            try {
              // Perform the API call to update the contract broadcast template
              await contractBroadcastTemplateApi.apiContractBroadcastTemplateContractBroadcastTemplateIdPatch(
                {
                  contractBroadcastTemplateId,
                  body: {contractId, ...updatedFields},
                }
              );
              hasUpdated = true; // Set the flag to true for successful update
            } catch (error) {
              // An error occurred during the API call, extract error message from API response
              const errorMessage =
                error.response?.data?.message ||
                'Failed to update contract broadcast template.';
              notify('error', errorMessage);
            }
          } else {
            hasValidationErrors = true;
            notify('error', 'Title or message cannot be empty');
          }
        }

        if (hasUpdated && !hasValidationErrors) {
          // Show success notification only if at least one successful update occurred and no validation errors found
          notify('success', 'Updated contract broadcast template');
        }

        // Reload the list to view changes
        await loadList();
      } catch (e) {
        // failed to update contract broadcast template for some unexpected reason
        notify(
          'error',
          'Failed to update contract broadcast template unexpectedly'
        );
      } finally {
        setSubmitting(false);
      }
    },
    [contractId, loadList, notify, list]
  );

  const handleRefresh = React.useCallback(async () => {
    await loadList();
  }, [loadList]);

  // Validation schema for the 'handleInlineAdd' function to enforce 'title' and 'message' as required fields
  const addValidation = Yup.object().shape({
    title: Yup.string().required('*required'),
    message: Yup.string().required('*required'),
  });

  const value: ContractBroadcastTemplateListContextProps = {
    loading: loadingContract || loading || submitting,
    list,
    hasPermission,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    addValidation,
    onInlineAdd: hasPermission.edit ? handleInlineAdd : undefined,
    onInlineEdit: hasPermission.edit ? handleInlineEdit : undefined,
    onDelete: hasPermission.edit ? handleDelete : undefined,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onRefresh: handleRefresh,
    loadList,
    cleanupList,
  };

  return (
    <ContractBroadcastTemplateListContext.Provider value={value}>
      {children}
    </ContractBroadcastTemplateListContext.Provider>
  );
};
