import React from 'react';
import {
  listingSchema,
  TemplateCard,
  TemplateForm,
} from '../../../factory/template';
import {OrderContext} from '../OrderContext';
import {Listing, Order} from '@onroadvantage/onroadvantage-api';
import * as Yup from 'yup';
import {
  FormikAutocompleteWithListing,
  FormikDateTimePicker,
  FormikTextField,
} from '../../formik';
import {OrderFormAutocomplete} from './OrderFormAutocomplete';
import {RoleService} from '../../../service';
import moment from 'moment/moment';

export interface IOrderForm {
  actualDispatch: moment.Moment;
  contract?: Listing;
  customer?: Listing;
  customerReferenceNumber?: string;
  deliverByDateTime: moment.Moment;
  deliveryNoteNumber?: string;
  depotDeparture: moment.Moment;
  id?: number;
  offloadPoint?: Listing;
  orderNumber?: string;
  primeMover?: string;
  product?: string;
  status?: Listing;
  transporter?: Listing;
  upliftPoint?: Listing;
  uuid?: string;
}

const schema = Yup.object({
  orderNumber: Yup.string(),
  transporter: listingSchema.nullable().required('Required'),
  customer: listingSchema.nullable().required('Required'),
  primeMover: Yup.string(),
  upliftPoint: listingSchema.nullable().required('Required'),
  offloadPoint: listingSchema.nullable().required('Required'),
  status: listingSchema.nullable().required('Required'),
  depotDeparture: Yup.date().required('Required'),
  actualDispatch: Yup.date(),
  deliverByDateTime: Yup.date(),
  tripDate: Yup.date(),
  contract: listingSchema.nullable().required('Required'),
  deliveryNoteNumber: Yup.string(),
  customerReferenceNumber: Yup.string(),
});

const getInitialValues = (
  order?: Order | null,
  statuses?: string[]
): IOrderForm | undefined => {
  if (order) {
    return {
      orderNumber: order.orderNumber ?? '',
      customer: {
        value: order.customerId,
        label: order.customer?.name,
      },
      primeMover: order.primeMover ?? '',
      upliftPoint: {
        value: order.upliftPointId,
        label: order.upliftPoint.name,
      },
      offloadPoint: {
        value: order.offloadPointId,
        label: order.offloadPoint.name,
      },
      transporter: {
        value: order.transporterId,
        label: order.transporter?.name,
      },
      status: {
        value: order.status ? statuses?.indexOf(order.status) : 0,
        label: order.status ?? 'Planned',
      },
      depotDeparture: moment(order.depotDeparture) ?? moment(),
      actualDispatch: moment(order.actualDispatch) ?? moment(),
      deliverByDateTime: moment(order.deliverByDateTime) ?? moment(),
      contract: {value: order.contractId, label: order.contract?.name},
      deliveryNoteNumber: order.deliveryNoteNumber ?? '',
      customerReferenceNumber: order.customerReferenceNumber || '',
    };
  }
  return undefined;
};

export const OrderForm: React.FC<{isAdd?: boolean}> = ({isAdd}) => {
  const {
    detailsRef,
    loading,
    submitting,
    order,
    statuses,
    onDetailsFormSubmit,
  } = React.useContext(OrderContext);
  const [initialValues, setInitialValues] = React.useState<IOrderForm>();

  const checkBlockedField = React.useCallback(
    (field: string) =>
      RoleService.hasExactPermission(
        `BlockField OrderHeader ${field}`,
        'BlockField'
      ),
    []
  );

  React.useEffect(() => {
    const values = getInitialValues(order, statuses);

    if (values) {
      setInitialValues(values);
    } else if (isAdd) {
      setInitialValues({
        actualDispatch: moment(),
        deliverByDateTime: moment(),
        depotDeparture: moment(),
      });
    }
  }, [isAdd, order, setInitialValues, statuses]);

  return (
    <TemplateCard
      title={`Order Details ${isAdd ? 'Add' : 'Edit'}`}
      loading={loading}
    >
      <TemplateForm<IOrderForm>
        initialValues={initialValues}
        onSubmit={onDetailsFormSubmit}
        permission={{
          name: `${isAdd ? 'Add' : 'Edit'} Order`,
          type: isAdd ? 'Add' : 'Edit',
        }}
        submitting={submitting}
        innerRef={detailsRef}
        validationSchema={schema}
      >
        <FormikTextField
          name="orderNumber"
          placeholder="Enter the order number"
          label="Order Number"
          disabled={!RoleService.hasPermission('Edit OrderNumber', 'Edit')}
          fullWidth
        />
        <FormikDateTimePicker
          name="depotDeparture"
          placeholder="Enter the depot departure datetime"
          label="Planned Depot Departure DateTime"
          InputLabelProps={{shrink: true}}
          fullWidth
          disabled={checkBlockedField('Planned Depot Departure')}
        />
        <FormikDateTimePicker
          name="actualDispatch"
          placeholder="Enter the depot actual dispatch datetime"
          label="Actual Dispatch DateTime"
          InputLabelProps={{shrink: true}}
          fullWidth
          disabled={checkBlockedField('Actual Dispatch')}
        />
        <FormikDateTimePicker
          name="deliverByDateTime"
          placeholder="Enter the desired delivery arrival time."
          label="Deliver By DateTime"
          InputLabelProps={{shrink: true}}
          fullWidth
          disabled={checkBlockedField('Actual Dispatch')}
        />
        <FormikAutocompleteWithListing
          name="customer"
          label="Customer"
          model="Customer"
          disabled={checkBlockedField('Customer')}
        />
        <FormikTextField
          name="deliveryNoteNumber"
          placeholder="Enter the delivery note number"
          label="Delivery Note Number"
          fullWidth
          disabled={checkBlockedField('Delivery Note Number')}
        />
        <FormikTextField
          name="customerReferenceNumber"
          placeholder="Enter the customer reference number"
          label="Customer Reference Number"
          fullWidth
          disabled={checkBlockedField('Customer Reference Number')}
        />
        <FormikAutocompleteWithListing
          name="transporter"
          label="Transporter"
          model="Transporter"
          disabled={checkBlockedField('Transporter')}
        />
        <FormikTextField
          name="primeMover"
          placeholder="Enter the vehicle registration number"
          label="Prime Mover"
          fullWidth
          disabled={checkBlockedField('Prime Mover')}
        />
        <OrderFormAutocomplete
          name="upliftPoint"
          label="Uplift Point"
          model="LOADING"
          disabled={checkBlockedField('Uplift Point')}
        />
        <OrderFormAutocomplete
          name="offloadPoint"
          label="Offload Point"
          model="OFFLOADING"
          disabled={checkBlockedField('Offload Point')}
        />
        <OrderFormAutocomplete
          name="status"
          label="Status"
          model="Status"
          disabled={checkBlockedField('Status')}
        />
        <FormikAutocompleteWithListing
          name="contract"
          label="Contract"
          model="Contract"
          disabled={checkBlockedField('Contract')}
        />
      </TemplateForm>
    </TemplateCard>
  );
};
