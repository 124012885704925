import React from 'react';
import _ from 'lodash';
import {
  ITemplateControllerRoute,
  TemplateController,
} from '../../factory/template';
import {RoleContext, RoleContextProvider} from './RoleContext';
import {RoleList} from './RoleList';
import {RoleIdHandler} from './RoleIdHandler';
import {RoleForm} from './RoleForm';
import {Role} from './Role';
import {RoleUserListContextProvider} from './roleUserList';

export const RoleController: React.FC = () => {
  return (
    <RoleContextProvider>
      <RoleUserListContextProvider>
        <RoleControllerComponent />
      </RoleUserListContextProvider>
    </RoleContextProvider>
  );
};

const RoleControllerComponent: React.FC = () => {
  const {role, loading} = React.useContext(RoleContext);

  const routes: ITemplateControllerRoute[] = React.useMemo(
    () => [
      {title: 'Role List', component: RoleList},
      {
        path: '/add',
        title: 'New Role',
        name: 'Add Role',
        type: 'Add',
        component: () => <RoleForm isAdd />,
      },
      {
        path: '/:roleId',
        title: (params) =>
          loading
            ? 'Role Loading...'
            : role?.name ?? `Role ${_.get(params, 'roleId', 'Single')}`,
        component: Role,
        IdHandler: RoleIdHandler,
      },
    ],
    [loading, role?.name]
  );

  return <TemplateController rootPath="/rolelist" routes={routes} />;
};
