import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from '@mui/material';

const EventsPaneCardActivityStyles = (theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      flex: 1,
    },
    info: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      flexWrap: 'wrap',
    },
    infoBreakable: {
      display: 'flex',
      flexDirection: 'column',
      flex: 2,
      flexWrap: 'wrap',
      overflowWrap: 'break-word',
      wordBreak: 'break-all',
    },
  });

export const useEventsPaneCardActivityStyles = makeStyles(
  EventsPaneCardActivityStyles,
  {name: 'EventsPaneCardActivityStyles'}
);
