import React from 'react';
import {TransporterContext} from './TransporterContext';
import {useParams} from 'react-router-dom';

interface IParams {
  transporterId?: string;
}

export const TransporterIdHandler: React.FC = () => {
  const {setTransporterId} = React.useContext(TransporterContext);

  const {transporterId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = transporterId ? parseInt(transporterId) : undefined;
    setTransporterId(parsedId);
    return () => setTransporterId(undefined);
  }, [transporterId, setTransporterId]);

  return null;
};
