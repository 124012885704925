import React from 'react';
import {Button, FormControlLabel, Switch} from '@mui/material';
import {useFormikContext} from 'formik';
import {useEventsPaneSearchFormActionsStyles} from './EventsPaneSearchFormActions.style';
import {EventsPaneContext} from '../EventsPaneContext';

export const EventsPaneSearchFormActions: React.FC = () => {
  const classes = useEventsPaneSearchFormActionsStyles();
  const {tabValue} = React.useContext(EventsPaneContext);
  const {getFieldMeta, getFieldHelpers, isValid, isSubmitting} =
    useFormikContext();
  const {value: isAdvanced} = getFieldMeta('isAdvanced');
  const {setValue} = getFieldHelpers('isAdvanced');

  const handleToggleIsAdvanced = React.useCallback(() => {
    setValue(!isAdvanced);
  }, [setValue, isAdvanced]);

  return (
    <div className={classes.root}>
      {tabValue === 'Open' && (
        <FormControlLabel
          control={
            <Switch
              checked={isAdvanced === true}
              onClick={handleToggleIsAdvanced}
              name="advanced"
              disabled={isSubmitting}
            />
          }
          label="Advanced"
        />
      )}
      <Button
        color="primary"
        type="submit"
        variant="outlined"
        className={classes.searchButton}
        disabled={!isValid || isSubmitting}
      >
        Search
      </Button>
    </div>
  );
};
