import {makeStyles, createStyles} from '@mui/styles';
import {Theme} from '@mui/material';

const VehicleTypeCapacityCompartmentTruckLayersViewStyles = (theme: Theme) =>
  createStyles({
    titleContainer: {display: 'flex'},
    divider: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  });

export const useVehicleTypeCapacityCompartmentTruckLayersViewStyles =
  makeStyles(VehicleTypeCapacityCompartmentTruckLayersViewStyles, {
    name: 'VehicleTypeCapacityCompartmentTruckLayersViewStyles',
  });
