import React from 'react';
import {PermissionContext} from './PermissionContext';
import {useParams} from 'react-router-dom';

interface IParams {
  permissionId?: string;
}

export const PermissionIdHandler: React.FC = () => {
  const {setPermissionId} = React.useContext(PermissionContext);

  const {permissionId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = permissionId ? parseInt(permissionId) : undefined;
    setPermissionId(parsedId);
    return () => setPermissionId(undefined);
  }, [permissionId, setPermissionId]);

  return null;
};
