import React from 'react';
import {OrderLine} from '@onroadvantage/onroadvantage-api';
import {useRecipientOrderDetailsStyles} from './RecipientOrderDetails.style';
import {Button, Typography, Collapse} from '@mui/material';
import {ExpandMore} from '@mui/icons-material';

export const RecipientOrderDetailsLine: React.FC<OrderLine> = (orderLine) => {
  const classes = useRecipientOrderDetailsStyles();
  const [collapse, setCollapse] = React.useState(true);
  const toggle = () => setCollapse((prevOpen) => !prevOpen);
  return (
    <>
      <Button
        onClick={toggle}
        size="small"
        fullWidth
        className={classes.buttonRow}
      >
        <Typography variant="body1" className={classes.value}>
          {orderLine.productName}
        </Typography>
        <ExpandMore
          style={{
            transform: collapse ? 'none' : 'rotate(180deg)',
            transition: 'transform 0.4s ease-in-out',
          }}
        />
      </Button>
      <Collapse in={!collapse}>
        <div className={classes.rowLine}>
          <Typography variant="body1" className={classes.label}>
            Ref No.
          </Typography>
          <Typography variant="body1">{orderLine.orderId ?? '-'}</Typography>
        </div>
        <div className={classes.rowLine}>
          <Typography variant="body1" className={classes.label}>
            Planned Quantity
          </Typography>
          <Typography variant="body1">{orderLine.quantity ?? '-'}</Typography>
        </div>
        <div className={classes.rowLine}>
          <Typography variant="body1" className={classes.label}>
            Actual Quantity
          </Typography>
          <Typography variant="body1">
            {orderLine.actualQuantity ?? '-'}
          </Typography>
        </div>
      </Collapse>
    </>
  );
};
