import {PlanningOrder} from '@onroadvantage/onroadvantage-api';
import {round} from './round';

export const getPlanningOrderMatrix = (
  planningOrder: PlanningOrder | undefined | null
) => {
  if (planningOrder == null) {
    return null;
  }

  const planningOrderCapacities: {[key: string]: number} = {};

  const planningOrderUoms = Array.from({length: 4}, (_, index) => {
    const capacitySelector = `capacityDimension${index + 1}`;
    const uomSelector = `capacityDimension${index + 1}Unit`;

    const capacityDimension =
      capacitySelector in planningOrder
        ? planningOrder[capacitySelector as keyof PlanningOrder]
        : null;

    const capacityDimensionUom =
      uomSelector in planningOrder
        ? planningOrder[uomSelector as keyof PlanningOrder]
        : null;

    if (typeof capacityDimensionUom === 'string') {
      if (typeof capacityDimension !== 'object') {
        const roundedDimension = round(capacityDimension);
        if (roundedDimension != null) {
          planningOrderCapacities[capacityDimensionUom.toLowerCase()] =
            roundedDimension;
        }
      }
      return capacityDimensionUom.toLowerCase();
    }

    return null;
  });

  return {
    planningOrderUoms: planningOrderUoms.filter(
      (uom) => uom != null
    ) as string[],
    planningOrderCapacities,
  };
};
