import React from 'react';
import L from 'leaflet';
import BaseMarker from './BaseMarker';
import {inject, observer} from 'mobx-react';

interface Props {
  position: [number, number]; // lat , lng
  stop: any;
}

function InfoMarker({position, stop}: Props) {
  const Icon = L.ExtraMarkers.icon({
    icon: 'fa-info-circle',
    markerColor: 'blue',
    shape: 'penta',
    prefix: 'fa',
  });

  return <BaseMarker position={position} icon={Icon} stop={stop} />;
}

export default inject('mapDisplayStore')(observer(InfoMarker));
