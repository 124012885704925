import React from 'react';
import L from 'leaflet';
import BaseMarker from './BaseMarker';
import {inject, observer} from 'mobx-react';

interface Props {
  position: [number, number]; // lat , lng
  address?: any;
}

class AddressMarker extends React.Component<Props> {
  render() {
    const Icon = L.ExtraMarkers.icon({
      icon: 'fa-home',
      markerColor: 'green',
      shape: 'penta',
      prefix: 'fa',
    });

    const {position, address} = this.props;

    return <BaseMarker position={position} icon={Icon} address={address} />;
  }
}
export default inject('mapDisplayStore')(observer(AddressMarker));
