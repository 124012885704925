import React from 'react';
import {ProductContext, ProductContextProps} from './ProductContext';
import {ProductForm} from './ProductForm';
import {ITemplateTabControllerTab, TemplateTabs} from '../../factory/template';

export const Product: React.FC = () => {
  const {loading, productId} =
    React.useContext<ProductContextProps>(ProductContext);

  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {title: 'Details', element: <ProductForm />},
  ]);

  const path = React.useMemo(() => `/productlist/${productId}}`, [productId]);

  return <TemplateTabs path={path} tabs={tabs} loading={loading} />;
};
