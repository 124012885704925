import React from 'react';
import {RoleContext} from './RoleContext';
import {TemplateTable, TemplateTableConfig} from '../../factory/template';
import {
  DashboardDump,
  Listing,
  RoleDashboard,
  RoleUpdate,
} from '@onroadvantage/onroadvantage-api';
import {RoleService} from '../../service';

export const RoleDashboards: React.FC = () => {
  const {loading, role, onUpdateRole} = React.useContext(RoleContext);

  const config: TemplateTableConfig<DashboardDump> = React.useMemo(
    () => ({
      columns: [
        {
          name: 'name',
          label: 'Name',
          type: 'string',
          model: 'Dashboard',
          enableMulti: true,
          enableEditing: true,
          enableAutocomplete: true,
        },
      ],
      disableToolbar: true,
      disablePagination: true,
      deleteMessage: (row) => ({
        title: 'Role Dashboard',
        items: {RoleName: role?.name, DashboardName: row.name},
      }),
      identifier: 'ROLE_DASHBOARDS_LIST',
    }),
    [role?.name]
  );

  const handleAddDashboard = React.useCallback(
    (changes: {[key: string]: any}[]) => {
      changes.forEach((change) => {
        const addedDashboards = change.name.map((dashboard: Listing) => ({
          id: dashboard.value,
        }));
        onUpdateRole(
          {
            ...(role as RoleUpdate),
            dashboards: [
              ...(role?.dashboards
                ? role.dashboards.map(({id}) => ({id: parseInt(id)}))
                : []),
              ...addedDashboards,
            ],
          },
          'Add'
        );
      });
    },
    [onUpdateRole, role]
  );
  const handleDeleteDashboard = React.useCallback(
    (row: DashboardDump) => {
      const filteredDashboards: RoleDashboard[] =
        role?.dashboards
          ?.filter((dashboards) => dashboards.id !== row.id)
          .map(({id}) => ({id: parseInt(id)})) ?? [];
      onUpdateRole(
        {
          ...(role as RoleUpdate),
          dashboards: filteredDashboards,
        },
        'Delete'
      );
    },
    [onUpdateRole, role]
  );

  return (
    <TemplateTable
      config={config}
      list={role?.dashboards ?? []}
      currentPage={1}
      loading={loading}
      onInlineAdd={
        RoleService.hasPermission('Edit Role', 'Edit')
          ? handleAddDashboard
          : undefined
      }
      onDelete={
        RoleService.hasPermission('Edit Role', 'Edit')
          ? handleDeleteDashboard
          : undefined
      }
    />
  );
};
