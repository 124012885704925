import React from 'react';
import {Stack} from '@mui/material';
import {
  FormikAutocompleteWithListing,
  FormikSwitch,
  FormikTextField,
} from '../../../formik';

export const ContractDetailsFormFields: React.FC = () => {
  return (
    <Stack>
      <FormikTextField
        name="name"
        placeholder="Enter the contract name"
        label="Name"
        fullWidth
      />
      <FormikTextField
        name="code"
        placeholder="Enter the contract code"
        label="Code"
        fullWidth
      />
      <FormikAutocompleteWithListing
        name="node"
        model="Node"
        placeholder="Enter and select the contract default site"
        label="Default Site"
      />
      <FormikAutocompleteWithListing
        name="telematicsConfig"
        model="TelematicsConfig"
        placeholder="Enter and select the contract telematics config"
        label="Telematics Config"
      />
      <FormikSwitch name="honorRestSchedule" label="Honor Rest Schedule" />
      <FormikSwitch
        name="driverScoringEnabled"
        label="Driver Scoring Enabled"
        stackProps={{pb: 1}}
      />
    </Stack>
  );
};
