import React from 'react';
import {WorkflowDetailsContextProvider} from './WorkflowDetailsContext';
import {WorkflowDetails} from './WorkflowDetails';
import {WebWorkflowOptimised} from '@onroadvantage/onroadvantage-api';

interface WorkflowDetailsControllerProps {
  row: WebWorkflowOptimised;
}

export const WorkflowDetailsController: React.FC<
  WorkflowDetailsControllerProps
> = ({row}) => {
  const workflowId = React.useMemo(() => row.id, [row.id]);

  return (
    <WorkflowDetailsContextProvider workflowId={workflowId}>
      <WorkflowDetails />
    </WorkflowDetailsContextProvider>
  );
};
