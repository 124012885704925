import React from 'react';
import {DriverDocumentContext} from './DriverDocumentContext';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../factory/template';
import {DriverDocument} from '@onroadvantage/onroadvantage-api';

export const DriverDocumentList: React.FC = () => {
  const {loadList, cleanupList} = React.useContext(DriverDocumentContext);

  const [config] = React.useState<TemplateTableConfig<DriverDocument>>({
    columns: [
      {name: 'description', label: 'Description', type: 'string'},
      {
        name: 'actionByDate',
        label: 'Action By Date',
        type: 'date',
        enableFilter: false,
      },
      {
        name: 'contract',
        label: 'Contract',
        type: 'string',
        enableFilter: false,
        getValue: ({contract}) => contract?.name,
      },
      {
        name: 'name' as 'driverDocumentType',
        label: 'Type Name',
        type: 'string',
        getValue: (values) => values.driverDocumentType?.name,
      },
    ],
    enableFilter: true,
    enableSort: true,
    deleteMessage: (row) => ({
      title: 'Driver Document',
      items: {
        Contract: row.contract?.name,
        DriverDocumentType: row.driverDocumentType?.name,
        Description: row.description,
      },
    }),
    identifier: 'DRIVER_DOCUMENT_LIST',
  });

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [loadList, cleanupList]);

  return (
    <TemplateTableWrapper config={config} context={DriverDocumentContext} />
  );
};
