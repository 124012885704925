import React from 'react';
import {AuthenticationContainer} from '../AuthenticationContainer';
import {
  ForgotPasswordForm,
  ForgotPasswordResetFormValues,
} from './ResetPasswordForm';
import {useLocation} from 'react-router-dom';
import {ResetTokenConfig} from '@onroadvantage/onroadvantage-api';
import {FormikProps} from 'formik';
import {authorizationApi} from '../../../api';
import {Loader} from '../../loader';
import {ResetPasswordCriteria} from './ResetPasswordCriteria';
import {PasswordResetSuccess} from '../PasswordResetSuccess';
import {PasswordResetFailure} from '../PasswordResetFailure';
import {AuthenticationBackToLogin} from '../AuthenticationBackToLogin';
import {useAppNotifications} from '../../../contexts';

export const ResetPassword: React.FC = () => {
  const location = useLocation();
  const notify = useAppNotifications();
  const formikRef =
    React.useRef<FormikProps<ForgotPasswordResetFormValues>>(null);

  const [criteria, setCriteria] = React.useState<
    ResetTokenConfig | undefined
  >();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [resetToken, setResetToken] = React.useState<string>();
  const [success, setSuccess] = React.useState<boolean>();

  const handleSubmit = React.useCallback(
    async (
      values: ForgotPasswordResetFormValues
      // utils: FormikHelpers<ForgotPasswordResetFormValues>
    ) => {
      if (resetToken) {
        try {
          const response = await authorizationApi.apiAuthResetTokenPost({
            body: {
              resetToken,
              password: values.password,
            },
          });
          const {message} = response;
          if (message) {
            switch (message) {
              case 'success':
                notify('success', 'Password was reset successfully');
                setSuccess(true);
                break;
              case 'failure':
                notify('error', message);
                setSuccess(false);
                break;
              default:
                notify('error', 'Failed to reset password');
                setSuccess(false);
                break;
            }
          } else {
            notify('error', 'Failed to reset password');
            setSuccess(false);
          }
        } catch (e) {
          const b = await e.json();
          if (b && b.errors && b.errors.json.password) {
            // schema errors
            b.errors.json.password.forEach((i: string) => {
              notify('error', i);
            });
          } else if (b.message) {
            // other errors
            notify('error', b.message);
          } else {
            // unknown errors
            notify('error', e);
            setSuccess(false);
          }
        }
      }
    },
    [notify, setSuccess, resetToken]
  );

  React.useEffect(() => {
    const getTokenConfig = async (token: string) => {
      try {
        const response = await authorizationApi.apiAuthResetTokenGet({
          token,
        });

        if (response.valid) {
          setResetToken(token);
          setCriteria(response.config);
        }
      } catch (e) {
        notify('error', e);
      } finally {
        setLoading(false);
      }
    };
    const resetTokenParam = new URLSearchParams(location.search).get(
      'resetToken'
    );
    if (resetTokenParam) {
      getTokenConfig(resetTokenParam);
    } else {
      setLoading(false);
    }
  }, [notify, location, setResetToken, setCriteria]);

  React.useEffect(() => {
    document.title = 'Vantage | Password Reset';
  }, []);

  let content: React.ReactNode;
  if (loading) {
    content = <Loader />;
  } else if (!resetToken) {
    content = (
      <PasswordResetFailure message="Reset token invalid or expired." />
    );
  } else if (success) {
    content = <PasswordResetSuccess message="Password changed successfully!" />;
  } else {
    content = (
      <>
        <ResetPasswordCriteria criteria={criteria} />
        <ForgotPasswordForm formikRef={formikRef} onSubmit={handleSubmit} />
        <AuthenticationBackToLogin variant="outlined" />
      </>
    );
  }

  return <AuthenticationContainer>{content}</AuthenticationContainer>;
};
