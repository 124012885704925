import React from 'react';
import {
  multipleListingSchema,
  TemplateCard,
  TemplateForm,
} from '../../factory/template';
import {NodeTypeContext} from './NodeTypeContext';
import {Listing, NodeType} from '@onroadvantage/onroadvantage-api';
import * as Yup from 'yup';
import {FormikAutocompleteWithListing, FormikTextField} from '../formik';

export interface INodeTypeForm {
  name: string;
  description: string;
  planningSkills: Listing[] | undefined;
}

const schema: Yup.SchemaOf<INodeTypeForm> = Yup.object({
  description: Yup.string().required('Required'),
  name: Yup.string().required('Required'),
  planningSkills: multipleListingSchema,
});

const getInitialValues = (
  nodeType?: NodeType | null
): INodeTypeForm | undefined => {
  if (nodeType) {
    return {
      name: nodeType.name,
      description: nodeType.description,
      planningSkills: nodeType.skills?.map(({id, name, description}) => ({
        value: id,
        label: `${name} - ${description}`,
      })),
    };
  }
  return undefined;
};

export const NodeTypeForm: React.FC<{isAdd?: boolean}> = ({isAdd}) => {
  const {detailsRef, loading, submitting, nodeType, onDetailsFormSubmit} =
    React.useContext(NodeTypeContext);
  const [initialValues, setInitialValues] = React.useState<INodeTypeForm>();

  React.useEffect(() => {
    const values = getInitialValues(nodeType);

    if (values) {
      setInitialValues(values);
    } else if (isAdd) {
      setInitialValues({
        name: '',
        description: '',
        planningSkills: [],
      });
    }
  }, [isAdd, nodeType, setInitialValues]);

  return (
    <TemplateCard
      title={`Node Type Details ${isAdd ? 'Add' : 'Edit'}`}
      loading={loading}
    >
      <TemplateForm<INodeTypeForm>
        initialValues={initialValues}
        onSubmit={onDetailsFormSubmit}
        permission={{
          name: `${isAdd ? 'Add' : 'Edit'} NodeType`,
          type: isAdd ? 'Add' : 'Edit',
        }}
        submitting={submitting}
        innerRef={detailsRef}
        validationSchema={schema}
      >
        <FormikTextField
          name="name"
          placeholder="Enter the node type name"
          label="Name"
          fullWidth
        />
        <FormikTextField
          name="description"
          placeholder="Enter the node type description"
          label="Description"
          fullWidth
        />
        <FormikAutocompleteWithListing
          name="planningSkills"
          placeholder="Enter and select the node type planning skills"
          label="Planning Skills"
          model="PlanningSkill"
          multiple
        />
      </TemplateForm>
    </TemplateCard>
  );
};
