import {EditTripStop} from '@onroadvantage/onroadvantage-api';
import {ITripEditTripStop} from '../tripEdit';

export const getStopEditPayload = (
  stop: ITripEditTripStop
): EditTripStop | undefined =>
  stop.node?.id && stop.sequence
    ? {
        id: stop.id != null && stop.id > 0 ? stop.id : undefined,
        associateNodeId: stop.associateNodeId ?? undefined,
        node: {id: stop.node.id},
        sequence: stop.sequence,
        taskTemplateNodeType: stop.taskTemplateNodeType ?? undefined,
        totalServiceTime: stop.totalServiceTime,
        totalServiceTimeChangeReason: stop.totalServiceTimeChangeReason,
        orders: (stop.orders || []).map((order) => ({
          id: order?.id ?? undefined,
        })),
        isMainStop: stop.isMainStop ?? undefined,
        serviceType: stop.serviceType ?? undefined,

        departureTime:
          stop.sequence === 1 && stop.departureTime
            ? new Date(stop.departureTime)
            : undefined,
      }
    : undefined;
