import React from 'react';
import * as Yup from 'yup';
import {
  WorkflowConfigTaskTemplateLink,
  Listing,
  WorkflowConfig,
} from '@onroadvantage/onroadvantage-api';
import {
  listingSchema,
  NUMBER_ERROR_MESSAGE,
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../../factory/template';
import {WorkflowConfigTaskTemplateLinkingListContext} from './WorkflowConfigTaskLinkingListContext';
import {WorkflowConfigContext} from '../WorkflowConfigContext';

export interface IWorkflowConfigTaskLinkingListAdd {
  startWorkflowConfigTaskTemplate: Listing;
  endWorkflowConfigTaskTemplate: Listing;
  linkType: Listing;
  description: string;
  amberDuration: number;
  redDuration: number;
}

export const workflowConfigTaskLinkingAddValidation: Yup.SchemaOf<IWorkflowConfigTaskLinkingListAdd> =
  Yup.object({
    startWorkflowConfigTaskTemplate: listingSchema.required('Required'),
    endWorkflowConfigTaskTemplate: listingSchema.required('Required'),
    linkType: listingSchema.required('Required'),
    description: Yup.string().required('Required'),
    amberDuration: Yup.number()
      .typeError(NUMBER_ERROR_MESSAGE)
      .required('Required'),
    redDuration: Yup.number()
      .typeError(NUMBER_ERROR_MESSAGE)
      .required('Required'),
  });

export const WorkflowConfigTaskLinkingList: React.FC = () => {
  const {workflowConfig} = React.useContext(WorkflowConfigContext);
  const {getWorkflowConfigTaskTemplate, loadList, cleanupList} =
    React.useContext(WorkflowConfigTaskTemplateLinkingListContext);

  const {taskTemplates} = workflowConfig || ({} as WorkflowConfig);

  const taskTemplateOptions: Listing[] = React.useMemo(
    () =>
      taskTemplates?.map(({id, taskTemplate}) => ({
        value: id,
        label: taskTemplate.name,
      })) ?? [],
    [taskTemplates]
  );

  const config: TemplateTableConfig<WorkflowConfigTaskTemplateLink> =
    React.useMemo(
      () => ({
        columns: [
          {
            name: 'description',
            label: 'Description',
            type: 'string',
            enableEditing: true,
          },
          {
            name: 'startWorkflowConfigTaskTemplate',
            label: 'Start Task Template',
            type: 'string',
            options: taskTemplateOptions,
            enableAutocomplete: true,
            enableEditing: true,
            getValue: ({startWorkflowConfigTaskTemplateId}) =>
              getWorkflowConfigTaskTemplate(startWorkflowConfigTaskTemplateId)
                ?.taskTemplate.name,
          },
          {
            name: 'endWorkflowConfigTaskTemplate',
            label: 'End Task Template',
            type: 'string',
            options: taskTemplateOptions,
            enableAutocomplete: true,
            enableEditing: true,
            getValue: ({endWorkflowConfigTaskTemplateId}) =>
              getWorkflowConfigTaskTemplate(endWorkflowConfigTaskTemplateId)
                ?.taskTemplate.name,
          },
          {
            name: 'linkType',
            label: 'Link Type',
            type: 'string',
            model: 'WorkflowConfigTaskTemplateLinkType',
            enableAutocomplete: true,
            enableEditing: true,
            getValue: ({linkType}) => linkType?.name,
          },
          {
            name: 'amberDuration',
            label: 'AmberDuration',
            type: 'number',
            enableEditing: true,
          },
          {
            name: 'redDuration',
            label: 'RedDuration',
            type: 'number',
            enableEditing: true,
          },
        ],
        enableFilter: true,
        enableSort: true,
        deleteMessage: (row) => ({
          title: 'Workflow Config Task Linking',
          items: {Description: row.description},
        }),
        identifier: 'WORKFLOW_CONFIG_TASK_LINKING_LIST',
      }),
      [getWorkflowConfigTaskTemplate, taskTemplateOptions]
    );

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [loadList, cleanupList]);

  return (
    <TemplateTableWrapper
      config={config}
      context={WorkflowConfigTaskTemplateLinkingListContext}
    />
  );
};
