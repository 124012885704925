import React from 'react';
import {useContractContext} from '../contractContext';
import {TemplateCard, TemplateForm} from '../../../factory/template';
import {contractInspectionSchema} from '../contractContext';
import {ContractInspectionFormFields} from './contractFormFields';

export const ContractInspectionForm: React.FC = () => {
  const {
    loadingContract,
    inspectionInitialValues,
    onContractInspectionSubmit,
    submitting,
  } = useContractContext();

  return (
    <TemplateCard title="Contract Inspection Edit" loading={loadingContract}>
      <TemplateForm
        initialValues={inspectionInitialValues}
        onSubmit={onContractInspectionSubmit}
        validationSchema={contractInspectionSchema}
        loading={submitting}
        permission={{name: 'Edit Contract', type: 'Edit'}}
      >
        <ContractInspectionFormFields />
      </TemplateForm>
    </TemplateCard>
  );
};
