import React from 'react';
import {RoleContext} from './RoleContext';
import {TemplateTable, TemplateTableConfig} from '../../factory/template';
import {
  Listing,
  Permission,
  RoleUpdate,
} from '@onroadvantage/onroadvantage-api';
import {RoleService} from '../../service';
import _ from 'lodash';
import {Filter} from '@devexpress/dx-react-grid';
import {unstable_batchedUpdates} from 'react-dom';

export const RolePermissions: React.FC = () => {
  const {loading, role, onUpdateRole} = React.useContext(RoleContext);
  const [filters, setFilters] = React.useState<Filter[]>([]);
  const [list, setList] = React.useState<Permission[]>(role?.permissions ?? []);

  const config: TemplateTableConfig<Permission> = React.useMemo(
    () => ({
      columns: [
        {
          name: 'name',
          label: 'Name',
          type: 'string',
          model: 'Permission',
          enableMulti: true,
          enableEditing: true,
          enableAutocomplete: true,
        },
        {name: 'description', label: 'Description', type: 'string'},
        {name: 'type', label: 'Type', type: 'string'},
      ],
      enableFilter: true,
      disablePagination: true,
      deleteMessage: (row) => ({
        title: 'Role Permission',
        items: {
          RoleName: role?.name,
          PermissionName: row.name,
          type: row.type,
          description: row.description,
        },
      }),
      identifier: 'ROLE_PERMISSIONS_LIST',
    }),
    [role?.name]
  );

  const handleAddPermission = React.useCallback(
    (changes: {[key: string]: any}[]) => {
      changes.forEach((change) => {
        const addedPermissions = change.name.map((permission: Listing) => ({
          id: permission.value,
        }));
        onUpdateRole(
          {
            ...(role as RoleUpdate),
            permissions: [
              ...(role?.permissions
                ? role.permissions.map(({id}) => ({id}))
                : []),
              ...addedPermissions,
            ],
          },
          'Add'
        );
      });
    },
    [onUpdateRole, role]
  );

  const handleDeletePermission = React.useCallback(
    (row: Permission) => {
      const filteredPermissions = role?.permissions?.filter(
        (permission) => permission.id !== row.id
      );
      onUpdateRole(
        {
          ...(role as RoleUpdate),
          permissions: filteredPermissions,
        },
        'Delete'
      );
    },
    [onUpdateRole, role]
  );

  const handleFiltersChangeDebounce = _.debounce(
    (newFilters: Filter[]) =>
      unstable_batchedUpdates(() => {
        setFilters(newFilters);
        setList(
          (role?.permissions ?? []).filter((permission) => {
            if (newFilters.length === 0) {
              return true;
            }

            let matchesPermission = false;

            newFilters.forEach(({columnName, value}) => {
              if (
                permission[columnName as keyof Permission]
                  ?.toString()
                  ?.toLowerCase()
                  ?.includes(value.toLowerCase())
              ) {
                matchesPermission = true;
              }
            });
            return matchesPermission;
          })
        );
      }),
    500
  );

  const handleFiltersChange = React.useCallback(
    (newFilters: Filter[]) => handleFiltersChangeDebounce(newFilters),
    [handleFiltersChangeDebounce]
  );

  React.useEffect(() => {
    setList(role?.permissions ?? []);
    return () => {
      setList([]);
    };
  }, [role?.permissions]);

  return (
    <TemplateTable
      config={config}
      list={list}
      currentPage={1}
      loading={loading}
      onInlineAdd={
        RoleService.hasPermission('Edit Role', 'Edit')
          ? handleAddPermission
          : undefined
      }
      onDelete={
        RoleService.hasPermission('Edit Role', 'Edit')
          ? handleDeletePermission
          : undefined
      }
      filters={filters}
      onFiltersChange={handleFiltersChange}
    />
  );
};
