import React from 'react';
import Button from '@mui/material/Button';
import {Loader} from '../../loader';
import {CardActions} from '@mui/material';
import {useFormikContext} from 'formik';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import {ReportGenerateFormSchemaType} from './ReportGenerateForm';
import {reportApi} from '../../../api';
import {ReportService} from '../../../service';
import {useAppNotificationStore} from '../../../stores/context/notifications';
import {
  getArrInt,
  getCustomlist,
  getDaterangeParameter,
  ReportGenerateParameter,
  ReportGenerateParameterValue,
} from '../helpers';

export interface ReportGenerateFormActionsProps {
  isDateEnabled?: boolean;
  loading?: boolean;
  reportId: number | undefined;
  params?: {[key: string]: any};
  Action?: React.ReactNode;
}

export const ReportGenerateFormActions: React.FC<
  ReportGenerateFormActionsProps
> = ({isDateEnabled, loading, reportId, Action, params: propParams}) => {
  const {isSubmitting, isValid, setSubmitting, validateForm, values} =
    useFormikContext<ReportGenerateFormSchemaType>();
  const appNotificationStore = useAppNotificationStore();

  const [link, setLink] = React.useState<string | null | undefined>();

  const parseValues = React.useCallback(() => {
    const newValues: ReportGenerateParameter[] = [];
    let newValue: ReportGenerateParameter | null | undefined;
    values?.parameters?.forEach((p) => {
      switch (p.type) {
        case 'daterange':
          newValue = getDaterangeParameter(p);
          break;
        case 'date':
          break;
        case 'arrint':
          newValue = getArrInt(p);
          break;
        case 'customlist':
          newValue = getCustomlist(p);
          break;
        default:
          break;
      }

      if (newValue) {
        newValues.push(newValue);
      }
    });

    if (isDateEnabled && values.endDate && values.startDate) {
      newValues.push({
        type: 'daterange',
        name: 'date',
        value: {
          endDate: values.endDate.toDate(),
          startDate: values.startDate.toDate(),
        },
      });
    }

    const params = newValues.reduce(
      (acc: {[key: string]: ReportGenerateParameterValue}, cur) => {
        const {name, value} = cur;
        if (name in acc) {
          acc[name] = value;
        } else {
          acc[name] = value;
        }

        return acc;
      },
      {}
    );

    return Buffer.from(
      JSON.stringify({...params, ...(propParams ?? {})})
    ).toString('base64');
  }, [propParams, values, isDateEnabled]);

  const handleGenerateToken = React.useCallback(async () => {
    if (!reportId) {
      return;
    }
    await validateForm();

    if (isValid) {
      const encodedString = parseValues();
      const token = await reportApi.apiReportGenerateTokenGet({
        reportId: reportId,
        reportParameters: encodedString,
      });

      if (token) {
        return token;
      }
    } else {
      // TODO handle errors???
    }
  }, [isValid, parseValues, reportId, validateForm]);

  const generateLink = React.useCallback(async () => {
    const token = await handleGenerateToken();
    if (token) {
      return await ReportService.generateLink({
        reportToken: token.reportToken,
      });
    }
  }, [handleGenerateToken]);

  const handleGetLink = React.useCallback(async () => {
    try {
      setSubmitting(true);
      const value = await generateLink();

      setLink(value);
    } finally {
      setSubmitting(false);
    }
  }, [generateLink, setSubmitting]);

  const handleCopyLink = React.useCallback(async () => {
    appNotificationStore.enqueueNotification('success', 'Link Copied');
    setLink(null);
  }, [appNotificationStore, setLink]);

  const handleGenerate = React.useCallback(async () => {
    try {
      setSubmitting(true);
      const newLink = await generateLink();
      window.open(`${newLink}`, '_blank');
    } finally {
      setSubmitting(false);
    }
  }, [generateLink, setSubmitting]);

  return (
    <CardActions>
      <Button
        disabled={isSubmitting || loading || !isValid}
        data-test="generate"
        onClick={handleGenerate}
      >
        Generate
      </Button>
      <Button
        disabled={isSubmitting || loading || !isValid}
        data-test="get-link"
        onClick={handleGetLink}
      >
        Get Link
      </Button>
      <CopyToClipboard text={link ?? ''} key="copy-link">
        <Button
          disabled={isSubmitting || loading || !link || !isValid}
          data-test="copy-link-button"
          onClick={handleCopyLink}
        >
          Copy Link
        </Button>
      </CopyToClipboard>
      {Action}
      {(isSubmitting || loading) && <Loader size={30} />}
    </CardActions>
  );
};
