import React from 'react';
import {SiteSubNodeContext} from './SiteSubNodeContext';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../../factory/template';
import {SubNode} from '@onroadvantage/onroadvantage-api';
import {SiteContext} from '../SiteContext';

export const SiteSubNodeList: React.FC = () => {
  const {site} = React.useContext(SiteContext);
  const {loadList, cleanupList} = React.useContext(SiteSubNodeContext);

  const config: TemplateTableConfig<SubNode> = React.useMemo(
    () => ({
      columns: [
        {name: 'name', label: 'Name', type: 'string'},
        {name: 'description', label: 'Description', type: 'string'},
        {
          name: 'externalReference',
          label: 'External Reference',
          type: 'string',
        },
        {
          name: 'taskTemplate.name',
          label: 'Task Template',
          type: 'string',
          getValue: ({taskTemplate}) => taskTemplate && taskTemplate?.name,
        },
      ],
      enableFilter: true,
      enableSort: true,
      deleteMessage: (row) => ({
        title: 'Site Sub Node',
        items: {
          SiteName: site?.name,
          SubNodeName: row.name,
          Description: row.description,
        },
      }),
      identifier: 'SITE_SUB_NODE_LIST',
    }),
    [site?.name]
  );

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [loadList, cleanupList]);

  return <TemplateTableWrapper config={config} context={SiteSubNodeContext} />;
};
