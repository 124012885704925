import {makeStyles, createStyles} from '@mui/styles';

const VehicleCardStyles = () =>
  createStyles({
    header: {cursor: 'pointer'},
    expand: {transition: 'transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'},
    expandOpen: {transform: 'rotate(180deg)'},
    root: {marginBottom: 12},
  });

export const useVehicleCardStyles = makeStyles(VehicleCardStyles, {
  name: 'VehicleCardStyles',
});
