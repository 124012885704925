import React from 'react';
import {Plugin, Template, TemplatePlaceholder} from '@devexpress/dx-react-core';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import DownloadIcon from '@mui/icons-material/SaveAlt';
import ListIcon from '@mui/icons-material/List';

import _ from 'lodash';
import {Loader} from '../loader';

type TClickEvent = (event: React.MouseEvent) => void;

export interface IFilter {
  columnName: string;
}

export interface IAddCancelDownloadProps {
  cancelNewRow?: TClickEvent;
  createNewRow?: TClickEvent;
  creatingRow?: boolean;
  filters?: IFilter[];
  isDownloadDisabled?: boolean;
  loading?: boolean;
  onDownload?: TClickEvent;
  showAdd?: boolean;
  showDownload?: boolean;
  onSpecialAction?: TClickEvent;
  showSpecialAction?: boolean;
  sorting?: IFilter[];
  style?: any;
}

export const AddCancelDownload = (props: IAddCancelDownloadProps) => {
  const addButton = (
    <IconButton onClick={props.createNewRow} size="large">
      <div data-test="add-header-button" style={{display: 'flex'}}>
        <AddIcon />
      </div>
    </IconButton>
  );

  const cancelButton = (
    <IconButton onClick={props.cancelNewRow} style={{left: 48}} size="large">
      <div data-test="cancel-header-button" style={{display: 'flex'}}>
        <CancelIcon />
      </div>
    </IconButton>
  );

  const addCancelButton = props.creatingRow ? cancelButton : addButton;

  const downloadButton = (
    <IconButton
      disabled={props.isDownloadDisabled}
      onClick={props.onDownload}
      size="large"
    >
      <div data-test="download-header-button" style={{display: 'flex'}}>
        <DownloadIcon />
      </div>
    </IconButton>
  );

  const specialActionButton = (
    <IconButton onClick={props.onSpecialAction} size="large">
      <div data-test="specialaction-header-button" style={{display: 'flex'}}>
        <ListIcon />
      </div>
    </IconButton>
  );

  if (
    props.loading ||
    (!props.showDownload && !props.showAdd && !props.showSpecialAction)
  ) {
    return null;
  }

  if (props.filters) {
    const filters = Object.assign({}, props.filters);
    _.remove(filters, (n) => {
      return n.columnName === 'dateRange';
    });
    if (filters.length > 0) {
      return null;
    }
  }

  if (props.sorting) {
    if (props.sorting.length > 0) {
      return null;
    }
  }

  return (
    <div style={props.style}>
      {props.showAdd ? addCancelButton : null}
      {props.showDownload && !props.creatingRow ? downloadButton : null}
      {props.showSpecialAction ? specialActionButton : null}
    </div>
  );
};

/**
 * Adds the AddCancelDownload functionality to the
 * DevExpress TableFilterRow Plugin
 */
export class AddCancelDownloadHeader extends React.PureComponent<IAddCancelDownloadProps> {
  pluginDependencies = [{name: 'Table'}, {name: 'TableHeaderRow'}];

  render() {
    const {style, ...withoutStyle} = this.props;

    const newStyle = {
      marginBottom: -48,
      left: 20,
      position: 'relative',
      top: 4, // important: if the filter row height changes, change here too
      ...style,
    };
    return (
      <Plugin
        dependencies={this.pluginDependencies}
        name="AddCancelDownloadHeader"
      >
        <Template name="tableLayout">
          <AddCancelDownload {...withoutStyle} style={newStyle} />
          <TemplatePlaceholder />
        </Template>
      </Plugin>
    );
  }
}

export interface IToolbarActivityIndicatorProps {
  loading?: boolean;
}

/** adds a loading indicator to the DevExpress Toolbar */
export class ToolbarActivityIndicator extends React.PureComponent<IToolbarActivityIndicatorProps> {
  pluginDependencies = [{name: 'Toolbar'}];

  render() {
    const {loading} = this.props;
    return (
      <Plugin dependencies={this.pluginDependencies} name="ToolbarFilter">
        <Template name="toolbarContent">
          <TemplatePlaceholder />
          <div>{loading ? <Loader size={32} /> : null}</div>
        </Template>
      </Plugin>
    );
  }
}
