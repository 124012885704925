import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material';
import React from 'react';
import {useFormikContext} from 'formik';
import {useInputStyles} from '../../formik/Input.style';
import clsx from 'clsx';
import {AccessTime, Refresh} from '@mui/icons-material';
import {TripDebriefDateTimePickerContext} from './TripDebriefDateTimePicker';

export const TripDebriefDateTimePickerField = (
  props: TextFieldProps
): React.ReactElement => {
  const classes = useInputStyles();
  const {name, InputLabelProps} = React.useContext(
    TripDebriefDateTimePickerContext
  );
  const {getFieldMeta, getFieldHelpers} = useFormikContext();
  /** Get the current fields initialValue from the current fields relevant snapshotValue */
  const {initialValue: snapshotInitialValue} = getFieldMeta(`snapshot.${name}`);
  /**
   * Get the current fields value from the current fields relevant overrideValue. We will also use the overrideField for
   * the touched, error and other ...meta states, as this is the actual field being edited. The snapshot is just to get
   * its initialValue
   */
  const {
    touched,
    error,
    initialValue: overrideInitialValue,
  } = getFieldMeta(`override.${name}`);
  /** We also want to set the relevant overrideValue's state as that is what's going to be updated and sent to the api */
  const {setValue} = getFieldHelpers(`override.${name}`);

  const displayError = React.useMemo(
    () => (touched ? error : undefined),
    [touched, error]
  );

  /** Handle the reset of the currentValue (overrideValue), to its initialValue (snapshotValue) */
  const handleValueReset = React.useCallback(() => {
    setValue(snapshotInitialValue);
  }, [setValue, snapshotInitialValue]);

  return (
    <TextField
      {...props}
      name={name}
      className={clsx(classes.input, {
        [classes.inputError]: displayError,
      })}
      InputLabelProps={InputLabelProps}
      error={!!displayError}
      helperText={displayError}
      fullWidth={!!props.fullWidth || props.fullWidth === undefined}
      color="secondary"
      /** If the overrideValue's initialValue is not equal to the snapshot's initialValue we make the field focused */
      focused={
        overrideInitialValue !== undefined &&
        overrideInitialValue !== snapshotInitialValue
      }
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {overrideInitialValue !== undefined &&
              overrideInitialValue !== snapshotInitialValue && (
                <IconButton onClick={handleValueReset}>
                  <Refresh />
                </IconButton>
              )}
            <AccessTime />
          </InputAdornment>
        ),
      }}
    />
  );
};
