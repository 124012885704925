import React from 'react';
import {Typography} from '@mui/material';
import {CheckCircle} from '@mui/icons-material';
import {usePasswordResetSuccessStyles} from './PasswordResetSuccess.style';
import {AuthenticationBackToLogin} from './AuthenticationBackToLogin';

interface PasswordResetSuccessProps {
  message?: string;
}

export const PasswordResetSuccess: React.FC<PasswordResetSuccessProps> = ({
  message,
}) => {
  const classes = usePasswordResetSuccessStyles();

  return (
    <div className={classes.container}>
      <CheckCircle className={classes.icon} color="primary" />
      <Typography className={classes.title}>
        <h4>{message ?? 'Success!'}</h4>
      </Typography>
      <AuthenticationBackToLogin />
    </div>
  );
};
