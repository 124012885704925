import {useStoreData} from '../helpers';
import {planningSolutionViewStoreContext} from './PlanningSolutionView.context';
import {action, observable} from 'mobx';
import {RowDetailStateProps} from '@devexpress/dx-react-grid';

/**
 * Views:
 * - List = PlanningSolutionList
 * - Solution = PlanningSolution
 * - Trip = PlanningSolutionTrip
 * - Stop = PlanningSolutionStop
 */
export const planningSolutionViewStore = () => {
  const store = {
    /**
     * View: List
     * Description: Specifies if the list is loading any external data
     * */
    bListIsLoading: observable.box<boolean>(false),
    get isListLoading() {
      return this.bListIsLoading.get();
    },
    setListLoading: (value: boolean) => {
      store.bListIsLoading.set(value);
    },
    /**
     * View: List
     * Description: Specifies if the list is being downloaded
     * */
    bListIsDownloading: observable.box<boolean>(false),
    get isListDownloading() {
      return this.bListIsDownloading.get();
    },
    setListDownloading: (value: boolean) => {
      store.bListIsDownloading.set(value);
    },
    /**
     * View: List
     * Description: Specifies if there are unsaved data
     * */
    // listIsDirty: observable.box<boolean>(false),
    resetList: action(() => {
      store.setListLoading(false);
      store.setListDownloading(false);
    }),
    /**
     * View: Solution
     * Description: Specifies if the data is being downloaded
     */
    bSolutionIsLoading: observable.box<boolean>(false),
    get solutionIsLoading() {
      return this.bSolutionIsLoading.get();
    },
    setSolutionIsLoading: (value: boolean) =>
      store.bSolutionIsLoading.set(value),
    /**
     * View: Solution
     * Description: Specifies of the masterdata is being downloaded
     */
    bSolutionMDIsLoading: observable.box<boolean>(false),
    get solutionMDIsLoading() {
      return this.bSolutionMDIsLoading.get();
    },
    setSolutionMDIsLoading: (value: boolean) =>
      store.bSolutionMDIsLoading.set(value),
    /**
     * View: Solution
     * Description: Specifies if the solution is being optimised
     */
    bSolutionIsOptimising: observable.box<boolean>(false),
    get solutionIsOptimising() {
      return this.bSolutionIsOptimising.get();
    },
    setSolutionIsOptimising: (value: boolean) =>
      store.bSolutionIsOptimising.set(value),
    /**
     * View: Solution
     * Description: Specifies which tab is selected
     */
    bSolutionTabValue: observable.box<number>(0),
    get solutionTabValue() {
      return this.bSolutionTabValue.get();
    },
    setSolutionTabValue: (value: number) => {
      store.bSolutionTabValue.set(value);
    },
    /**
     * View: Solution
     * Description: Specifies if the commit prompt is open
     */
    bSolutionCommitPromptOpen: observable.box<boolean>(false),
    get solutionCommitPromptOpen() {
      return this.bSolutionCommitPromptOpen.get();
    },
    setSolutionCommitPromptOpen: (value: boolean) => {
      store.bSolutionCommitPromptOpen.set(value);
    },
    /**
     * View: Solution
     * Description: Specifies if the solution is being committed
     */
    bSolutionIsCommitting: observable.box<boolean>(false),
    get solutionIsCommitting() {
      return this.bSolutionIsCommitting.get();
    },
    setSolutionIsCommitting: (value: boolean) =>
      store.bSolutionIsCommitting.set(value),
    /**
     * View: Solution
     * Description: Stores the expanded trip rows
     */
    bSolutionExpandedRowIds: observable.box<
      RowDetailStateProps['expandedRowIds']
    >([]),
    /**
     * View: Solution
     * Description: Specifies the expanded trip rows
     */
    get solutionExpandedRowIds() {
      return this.bSolutionExpandedRowIds.get();
    },
    /**
     * View: Solution
     * Description: Sets which rows are expanded
     * */
    setSolutionExpandedRowIds: (value: RowDetailStateProps['expandedRowIds']) =>
      store.bSolutionExpandedRowIds.set(value),
    /**
     * View: Solution
     * Description: Resets the solution to defaults
     */
    resetSolution: action(() => {
      store.setSolutionIsLoading(true);
      store.setSolutionTabValue(0);
      store.setSolutionCommitPromptOpen(false);
    }),
    /**
     * View: Trip
     * Description: Specifies which tab is selected
     */
    tripTabValue: observable.box<number>(0),
    /**
     * View: Solution
     * Description: Specifies if there is an error in loading data
     */
    bLoadingError: observable.box<string | null>(null),
    get loadingError() {
      return this.bLoadingError.get();
    },
    setLoadingError: (value: string | null) => {
      store.bLoadingError.set(value);
    },
    /**
     * View: Solution
     * Description: Specifies the interval at which the solution's optimising
     * should be polled. A null value signifies the polling to stop.
     */
    bOptimiseTick: observable.box<number | null>(null),
    get optimiseTick() {
      return this.bOptimiseTick.get();
    },
    setOptimizeTick: (value: number | null) => {
      store.bOptimiseTick.set(value);
    },
  };

  return store;
};
export type TPlanningSolutionViewStore = ReturnType<
  typeof planningSolutionViewStore
>;

export function usePlanningSolutionViewStore<Selection>(
  dataSelector: (store: TPlanningSolutionViewStore) => Selection
) {
  return useStoreData(
    planningSolutionViewStoreContext,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    (contextData) => contextData!,
    dataSelector
  );
}
