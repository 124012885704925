import React from 'react';
import {Button, Collapse, Stack} from '@mui/material';
import {usePlanningBoardOptionsStyles} from './PlanningBoardOptions.style';
import {
  PlanningBoardOptionsContractAutocomplete,
  PlanningBoardOptionsCustomerAutocomplete,
  PlanningBoardOptionsPlanningSkillAutocomplete,
  PlanningBoardOptionsVehicleGroupAutocomplete,
} from './planningBoardOptionsAutocomplete';
import {usePlanningBoardContext} from '../planningBoardContext';

interface PlanningBoardOptionsProps {
  onActionClick?: () => void;
}

export const PlanningBoardOptions: React.FC<PlanningBoardOptionsProps> = ({
  onActionClick,
}) => {
  const classes = usePlanningBoardOptionsStyles();
  const {
    setInitialised,
    selectedContractListings,
    loadInitialDetails,
    resetListings,
  } = usePlanningBoardContext();

  const handleClear = React.useCallback(() => {
    if (onActionClick) onActionClick();
    resetListings();
    setInitialised(false);
  }, [onActionClick, resetListings, setInitialised]);

  const handleLoad = React.useCallback(() => {
    if (onActionClick) onActionClick();
    loadInitialDetails(['customer', 'planningSkill']);
  }, [loadInitialDetails, onActionClick]);

  return (
    <Stack className={classes.root}>
      <PlanningBoardOptionsContractAutocomplete />
      <Collapse
        in={selectedContractListings && selectedContractListings.length > 0}
        unmountOnExit
        mountOnEnter
      >
        <PlanningBoardOptionsVehicleGroupAutocomplete />
        <PlanningBoardOptionsCustomerAutocomplete />
        <PlanningBoardOptionsPlanningSkillAutocomplete />
        <Button
          variant="contained"
          color="primary"
          onClick={handleLoad}
          className={classes.loadButton}
        >
          Load
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleClear}
          className={classes.clearButton}
        >
          Clear
        </Button>
      </Collapse>
    </Stack>
  );
};
