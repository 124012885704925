import _ from 'lodash';
import {authStore} from '../../../store';

export const branding = {
  default: {
    brandBackground: '#ffffff',
    brandBackgroundText: '#3E4345',

    brandPrimary: '#008270',
    brandPrimaryLighter: '#00AA96',
    brandPrimaryDarker: '#008270',
    brandPrimaryText: '#ffffff',

    brandSecondary: '',
    brandSecondaryLighter: '',
    brandSecondaryDarker: '',
    brandSecondaryText: '',

    brandPrimaryHighlight: '#008270',
    brandPrimaryHighlightLighter: '#00AA96',
    brandPrimaryHighlightDarker: '#008270',
    brandPrimaryHighlightText: '#ffffff',

    brandSecondaryHighlight: '#f3aa18',
    brandSecondaryHighlightLighter: '#f3aa18',
    brandSecondaryHighlightDarker: '#f3aa18',
    brandSecondaryHighlightText: '#ffffff',

    brandTertiaryHighlight: '#d12121',
    brandTertiaryHighlightLighter: '#ff775e',
    brandTertiaryHighlightDarker: '#d12121',
    brandTertiaryHighlightText: '#ffffff',

    brandQuaternaryHighLight: '#8b8b8b',
    brandQuaternaryHighLightLighter: '#8b8b8b',
    brandQuaternaryHighLightDarker: '#8b8b8b',
    brandQuaternaryHighLightText: '#ffffff',
  },
  unitrans: {
    brandPrimary: '#103670',
    brandPrimaryLighter: '#001844',
    brandPrimaryDarker: '#103670',
    brandPrimaryText: '#ffffff',

    brandSecondaryHighlight: '#c88f1f',
    brandSecondaryHighlightLighter: '#c88f1f',
    brandSecondaryHighlightDarker: '#c88f1f',
    brandSecondaryHighlightText: '#ffffff',

    brandTertiaryHighlight: '#d65927',
    brandTertiaryHighlightLighter: '#d65927',
    brandTertiaryHighlightDarker: '#d65927',
    brandTertiaryHighlightText: '#ffffff',

    brandQuaternaryHighLight: '#6d6e71',
    brandQuaternaryHighLightLighter: '#6d6e71',
    brandQuaternaryHighLightDarker: '#6d6e71',
    brandQuaternaryHighLightText: '#ffffff',
  },
};

export const getColorSchemeName = () => {
  /**
   * Here we write logic to select a given color scheme name. Basically it only
   * need to return a string value signifying which scheme name to use.
   *
   * If no scheme name is matched the "default" scheme name is returned
   */

  // unitrans
  const {auth} = authStore;
  if (auth.email && auth.email.includes('@unitrans.co.za')) return 'unitrans';

  //
  // default
  // keep last
  return 'default';
};

export const getBrandColors = (brandName) => {
  /**
   * Brand colors will be merged with the "default" color scheme. Any properties
   * not overridden in the "branding" object for the particular scheme will
   * be inherited from the "default" scheme.
   */
  let colorSchemeName;
  if (!brandName) {
    colorSchemeName = getColorSchemeName();
  } else {
    colorSchemeName = brandName;
  }

  if (colorSchemeName === 'default') {
    return branding.default;
  }
  if (colorSchemeName === 'unitrans') {
    return _.merge({}, branding.default, branding.unitrans);
  }

  return branding.default;
};

export const getStyle = (styleFunction) => {
  /**
   * Requires a function to be provided accepting one parameter. The parameter
   * will be an object with brandColors.
   *
   * The function further should return an object(style) with at least a
   * "default" property. Should the object have a property matching the
   * brandName it will override the defaults withthe specified brandName
   * styling.
   */
  const brandName = getColorSchemeName();
  const brandColors = getBrandColors(brandName);
  const styles = styleFunction(brandColors);
  if (brandName === 'default') {
    return styles.default;
  }
  if (brandName === 'unitrans') {
    return _.merge({}, styles.default, styles.unitrans);
  }

  return styles.default;
};
