import React from 'react';
import {Listing} from '@onroadvantage/onroadvantage-api';
import {Button, Stack} from '@mui/material';
import {Add} from '@mui/icons-material';
import {useTripSetupContext} from '../TripSetupContext';
import {TripSetupAutocompleteNode} from '../components';
import {useTripSetupStopsStepContext} from './TripSetupStopsStepContext';

export const TripSetupStopsStepDepot = () => {
  const {setStops, setEditing} = useTripSetupContext();
  const {setViewState} = useTripSetupStopsStepContext();
  const [values, setValues] = React.useState<Listing[] | null>([]);

  const handleAddToStops = React.useCallback(() => {
    values?.forEach((node) => {
      setStops((currentStops) => [
        ...currentStops,
        {node, sequence: currentStops.length + 1},
      ]);
    });
    setValues([]);
    setEditing(false);
    setViewState('sortStops');
  }, [setEditing, setStops, setViewState, values]);

  const handleChangeValue = React.useCallback(
    (value: Listing[] | null) => {
      setValues(value);
      if (value && value.length > 0) {
        setEditing(true);
      } else {
        setEditing(false);
      }
    },
    [setEditing]
  );

  return (
    <Stack spacing={2.5}>
      <TripSetupAutocompleteNode
        type="depot"
        label="Node"
        name="node"
        value={values}
        onChange={handleChangeValue}
        placeholder="Search and select at least 1 site"
        multiple
      />
      <Button
        sx={{alignSelf: 'flex-start'}}
        variant="contained"
        color="primary"
        onClick={handleAddToStops}
        disabled={!values || values.length === 0}
        fullWidth={false}
        startIcon={<Add />}
      >
        Add {values && values.length > 1 ? 'Depots' : 'Depot'}
      </Button>
    </Stack>
  );
};
