import React from 'react';
import {ITemplateTabControllerTab, TemplateTabs} from '../../factory/template';
import {TripDebriefContext} from './TripDebriefContext';
import {TripDebriefSummary} from './tripDebriefSummary';
import {TripDebriefTimesForm} from './TripDebriefTimesForm';
import {TripDebriefKilometersForm} from './TripDebriefKilometersForm';
import {TripDebriefDocuments} from './tripDebriefDocuments';
import {TripDebriefNotificationList} from './tripDebriefNotificationList';
import {TripDebriefOrderLineList} from './tripDebriefOrderLine';
import {TripDebriefErrorOccurred} from './tripDebriefErrorOccured';
import {TripDebriefStopTaskList} from './tripDebriefStopTaskList';
import {Loader} from '../loader';
import {TripDuplicateErrorOccurred} from './tripDebriefErrorOccured/TripDuplicateErrorOccured';

export const TripDebrief: React.FC = () => {
  const {
    errorOccurred,
    duplicateErrorOccurred,
    duplicateErrorMessage,
    masterTripId,
    loading,
  } = React.useContext(TripDebriefContext);

  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {title: 'Summary', element: <TripDebriefSummary />},
    {title: 'Times', element: <TripDebriefTimesForm />},
    {title: 'Kilometers', element: <TripDebriefKilometersForm />},
    {title: 'Orders', element: <TripDebriefOrderLineList />},
    {title: 'Documents', element: <TripDebriefDocuments />},
    {title: 'Notifications', element: <TripDebriefNotificationList />},
    {title: 'Tasks', element: <TripDebriefStopTaskList />},
  ]);

  const path = React.useMemo(
    () => `/tripdebrieflist/${masterTripId}`,
    [masterTripId]
  );

  if (loading) {
    return <Loader text="Loading Trip Debrief..." />;
  }

  if (errorOccurred) return <TripDebriefErrorOccurred />;

  if (duplicateErrorOccurred)
    return <TripDuplicateErrorOccurred receivedError={duplicateErrorMessage} />;

  return <TemplateTabs tabs={tabs} path={path} />;
};
