import React from 'react';
import Chart from 'chart.js';
import PropTypes from 'prop-types';
import {getVantageRgb} from '../../service/Util';

let chartId = 0;

const nextId = () => `chartjs-${chartId++}`; // eslint-disable-line no-plusplus

const colors = Array(100).fill().map(getVantageRgb);

const styles = {
  colours: {
    background: colors,
    border: colors,
  },
};

export default class MyChart extends React.Component {
  constructor(props) {
    super(props);

    props.data.datasets.forEach((dataset) => {
      if (!('backgroundColor' in dataset)) {
        // eslint-disable-next-line no-param-reassign
        dataset.backgroundColor = styles.colours.background;
      }

      if (!('borderColor' in dataset)) {
        // eslint-disable-next-line no-param-reassign
        dataset.borderColor = styles.colours.border;
      }
    });

    this.state = {
      ctx: nextId(),
      options: props.options,
      data: props.data,
    };
  }

  componentDidMount() {
    const {type = 'bar'} = this.props;
    const {ctx, data, options} = this.state;

    this.chart = new Chart(ctx, {
      type,
      data,
      options,
    });
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps = async (nextProps) => {
    if (this.props !== nextProps) {
      nextProps.data.datasets.forEach((dataset) => {
        if (!('backgroundColor' in dataset)) {
          // eslint-disable-next-line no-param-reassign
          dataset.backgroundColor = styles.colours.background;
        }

        if (!('borderColor' in dataset)) {
          // eslint-disable-next-line no-param-reassign
          dataset.borderColor = styles.colours.border;
        }
      });
      await this.chart.destroy();
      const {type = 'bar'} = this.props;
      const {ctx} = this.state;
      const {data, options} = nextProps;

      this.chart = new Chart(ctx, {
        type,
        data,
        options,
      });
    }
  };

  componentWillUnmount() {
    this.chart.destroy();
  }

  render() {
    const {height, width} = this.props;
    const {ctx} = this.state;

    return (
      <div style={{width, height, flexGrow: 1}}>
        <canvas id={ctx} width={width} height={height} />
      </div>
    );
  }
}

MyChart.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export {styles};
