import React from 'react';
import {TemplateCard, TemplateForm} from '../../factory/template';
import {NotificationConfigContext} from './NotificationConfigContext';
import {NotificationConfig} from '@onroadvantage/onroadvantage-api';
import * as Yup from 'yup';
import {FormikSwitch, FormikTextField, FormikTemplateEditor} from '../formik';

export interface INotificationConfigForm {
  callEnabled: boolean;
  callTemplate?: string;
  emailEnabled: boolean;
  emailTemplate?: string;
  name: string;
  smsEnabled: boolean;
  smsTemplate?: string;
  whatsappEnabled: boolean;
  whatsappTemplate?: string;
}

const schema: Yup.SchemaOf<INotificationConfigForm> = Yup.object({
  callEnabled: Yup.boolean().required('Required'),
  callTemplate: Yup.string(),
  emailEnabled: Yup.boolean().required('Required'),
  emailTemplate: Yup.string(),
  name: Yup.string().required('Required'),
  smsEnabled: Yup.boolean().required('Required'),
  smsTemplate: Yup.string(),
  whatsappEnabled: Yup.boolean().required('Required'),
  whatsappTemplate: Yup.string(),
});

const getInitialValues = (
  notificationConfig?: NotificationConfig | null
): INotificationConfigForm | undefined => {
  if (notificationConfig) {
    return {
      callEnabled: !!notificationConfig.callEnabled,
      callTemplate: notificationConfig.callTemplate,
      emailEnabled: !!notificationConfig.emailEnabled,
      emailTemplate: notificationConfig.emailTemplate,
      name: notificationConfig.name ?? '',
      smsEnabled: !!notificationConfig.smsEnabled,
      smsTemplate: notificationConfig.smsTemplate,
      whatsappEnabled: !!notificationConfig.whatsappEnabled,
      whatsappTemplate: notificationConfig.whatsappTemplate,
    };
  }
  return undefined;
};

export const NotificationConfigForm: React.FC<{isAdd?: boolean}> = ({
  isAdd,
}) => {
  const {
    detailsRef,
    loadingSingleItem,
    submitting,
    notificationConfig,
    onDetailsFormSubmit,
  } = React.useContext(NotificationConfigContext);
  const [initialValues, setInitialValues] =
    React.useState<INotificationConfigForm>();

  React.useEffect(() => {
    const values = getInitialValues(notificationConfig);

    if (values) {
      setInitialValues(values);
    } else if (isAdd) {
      setInitialValues({
        callEnabled: false,
        emailEnabled: false,
        name: '',
        smsEnabled: false,
        whatsappEnabled: false,
      });
    }
  }, [isAdd, notificationConfig, setInitialValues]);

  return (
    <TemplateCard
      title={`NotificationConfig Details ${isAdd ? 'Add' : 'Edit'}`}
      loading={loadingSingleItem}
    >
      <TemplateForm<INotificationConfigForm>
        initialValues={initialValues}
        onSubmit={onDetailsFormSubmit}
        permission={{
          name: `${isAdd ? 'Add' : 'Edit'} NotificationConfig`,
          type: isAdd ? 'Add' : 'Edit',
        }}
        submitting={submitting}
        innerRef={detailsRef}
        validationSchema={schema}
      >
        <FormikTextField
          name="name"
          placeholder="Enter the notification config name"
          label="Name"
          fullWidth
        />
        <FormikSwitch name="smsEnabled" label="SMS Enabled" />
        <FormikSwitch name="emailEnabled" label="Email Enabled" />
        <FormikSwitch name="whatsappEnabled" label="Whatsapp Enabled" />
        <FormikSwitch name="callEnabled" label="Call Enabled" />
        <FormikTemplateEditor
          name="smsTemplate"
          label="SMS Template"
          minHeight="30rem"
          maxHeight="50rem"
        />
        <FormikTemplateEditor
          name="emailTemplate"
          label="Email Template"
          minHeight="30rem"
          maxHeight="50rem"
        />
        <FormikTemplateEditor
          name="whatsappTemplate"
          label="Whatsapp Template"
          minHeight="30rem"
          maxHeight="50rem"
        />
        <FormikTemplateEditor
          name="callTemplate"
          label="Call Template"
          minHeight="30rem"
          maxHeight="50rem"
        />
      </TemplateForm>
    </TemplateCard>
  );
};
