import React from 'react';
import {ProductGroupContext} from '../ProductGroupContext';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../../factory/template';
import {Product} from '@onroadvantage/onroadvantage-api';
import {ProductGroupProductsContext} from './ProductGroupProductsContext';

export const ProductGroupProducts: React.FC = () => {
  const {productGroup} = React.useContext(ProductGroupContext);
  const {loadList, cleanupList} = React.useContext(ProductGroupProductsContext);

  const config: TemplateTableConfig<Product> = React.useMemo(
    () => ({
      columns: [
        {
          name: 'name',
          label: 'Name',
          type: 'string',
          model: 'Product',
          enableEditing: true,
          enableAutocomplete: true,
          enableMulti: true,
        },
        {
          name: 'description',
          label: 'Description',
          type: 'string',
          enableEditing: false,
        },
      ],
      enableFilter: true,
      enableSort: true,
      disableToolbar: true,
      mapMessageTitle: () => 'Are you sure you want to unassign this product?',
      deleteMessage: (row) => ({
        title: 'Product Group',
        items: {
          ProductGroupName: productGroup?.name,
          Name: row.name,
          Description: row.description,
        },
      }),
      identifier: 'VEHICLE_GROUP_VEHICLES',
    }),
    [productGroup?.name]
  );

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [loadList, cleanupList]);

  return (
    <TemplateTableWrapper
      config={config}
      context={ProductGroupProductsContext}
    />
  );
};
