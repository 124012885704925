import React from 'react';
import {PlanningBoardTripCompartmentsStopList} from './PlanningBoardTripCompartmentsStopList';
import {PlanningBoardTripCompartmentsContextProvider} from './planningBoardTripCompartmentsContext';
import {PlanningBoardGanttCompartmentTruck} from './planningBoardTripCompartmentsTruck';

export const PlanningBoardTripCompartments: React.FC = () => {
  return (
    <PlanningBoardTripCompartmentsContextProvider>
      <PlanningBoardGanttCompartmentTruck />
      <PlanningBoardTripCompartmentsStopList />
    </PlanningBoardTripCompartmentsContextProvider>
  );
};
