import React from 'react';
import moment from 'moment';
import 'typeface-roboto';
import ReactResizeDetector from 'react-resize-detector';

import MyChart from '../../../chart/Chart';
import DropGraph from './DropGraph';
import {getStyle} from '../helpers';

const componentStyle = (brandColors) => ({
  default: {
    container: {
      paddingTop: 'calc(100vw / 1920 * 20)',
      paddingBottom: 'calc(100vw / 1920 * 8)',
      fontFamily: 'Roboto',
      display: 'flex',
      flexDirection: 'row',
      height: 'calc(100vw / 1920 * 277)',
      width: 'calc(100vw -(100vw / (1920 * 48))',
      borderBottom: '1px solid #8B8B8B',
      marginLeft: 'calc(100vw / 1920 * 24)',
      marginRight: 'calc(100vw / 1920 * 24)',
    },
    contractCode: {
      display: 'flex',
      // backgroundColor: 'green',
      height: 'calc(100vw / 1920 * 259)',
      width: 'calc(100vw / 1920 * 238)',
      alignItems: 'center',
      justifyContent: 'center',
    },
    contractCodeText: {
      color: brandColors.brandBackgroundText,
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 'calc(100vw / 1920 * 40)',
    },
    graph: {
      display: 'flex',
      height: 'calc(100vw / 1920 * 260)',
      width: 'calc(100vw / 1920 * 750)',
      marginLeft: 'calc(100vw / 1920 * 24)',
    },
    circleGraph: {
      height: 'calc(100vw / 1920 * 259)',
      width: 'calc(100vw / 1920 * 195)',
      marginLeft: 'calc(100vw / 1920 * 20)',
    },
    circleWrapper: {
      height: 'calc(100vw / 1920 * 195)',
      width: 'calc(100vw / 1920 * 195)',
    },
    circleGraphLabel: {
      display: 'flex',
      height: 'calc(100vw / 1920 * 44)',
      width: 'calc(100vw / 1920 * 195)',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 'calc(100vw / 1920 * 20)',
    },
    circleGraphLabelText: {
      color: brandColors.brandBackgroundText,
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: 'calc(100vw / 1920 * 36)',
    },
    numberBox: {
      height: 'calc(100vw / 1920 * 259)',
      width: 'calc(100vw / 1920 * 195)',
      marginLeft: 'calc(100vw / 1920 * 20)',
    },
    clearBox: {
      color: brandColors.brandBackgroundText,
      display: 'flex',
      height: 'calc(100vw / 1920 * 193)',
      width: 'calc(100vw / 1920 * 193)',
      border: '1px solid #8B8B8B',
      borderRadius: 'calc(100vw / 1920 * 16)',
      alignItems: 'center',
      justifyContent: 'center',
    },
    filledBox: {
      color: brandColors.brandTertiaryHighlightText,
      display: 'flex',
      backgroundColor: brandColors.brandTertiaryHighlight,
      height: 'calc(100vw / 1920 * 195)',
      width: 'calc(100vw / 1920 * 195)',
      borderRadius: 'calc(100vw / 1920 * 16)',
      alignItems: 'center',
      justifyContent: 'center',
    },
    boxText: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '900',
      fontSize: 'calc(100vw / 1920 * 80)',
      letterSpacing: '0.06em',
    },
    boxLabel: {
      display: 'flex',
      height: 'calc(100vw / 1920 * 44)',
      width: 'calc(100vw / 1920 * 195)',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 'calc(100vw / 1920 * 20)',
    },
    boxLabelText: {
      color: brandColors.brandBackgroundText,
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: 'calc(100vw / 1920 * 36)',
      textAlign: 'center',
    },
    circleGraphOverlay: {
      display: 'flex',
      color: brandColors.brandBackgroundText,
      position: 'relative',
      fontSize: 'calc(100vw / 1920 * 48)',
      top: 'calc(100vw / 1920 * -195)',
      marginBottom: 'calc(100vw / 1920 * -195)',
      height: 'calc(100vw / 1920 * 195)',
      width: 'calc(100vw / 1920 * 195)',
      alignItems: 'center',
      justifyContent: 'center',
    },
    doughnut: {
      colorPrimary: brandColors.brandPrimaryHighlight,
      colorSecondary: brandColors.brandSecondaryHighlight,
      colorTertiary: brandColors.brandTertiaryHighlight,
    },
    boxFill: {
      colorPrimary: brandColors.brandPrimaryHighlight,
      colorSecondary: brandColors.brandSecondaryHighlight,
      colorTertiary: brandColors.brandTertiaryHighlight,
    },
    graphActual: {
      borderColor: brandColors.brandSecondaryHighlight,
      backgroundColor: `${brandColors.brandSecondaryHighlight}60`,
    },
    graphPlanned: {
      borderColor: brandColors.brandPrimaryHighlight,
      backgroundColor: brandColors.brandPrimaryHighlight,
    },
    graphBudget: {
      borderColor: brandColors.brandTertiaryHighlight,
      backgroundColor: `${brandColors.brandTertiaryHighlight}20`,
    },
  },
  unitrans: {
    graphActual: {
      borderColor: brandColors.brandTertiaryHighlight,
      backgroundColor: `${brandColors.brandTertiaryHighlight}60`,
    },
    graphPlanned: {
      borderColor: brandColors.brandPrimary,
      backgroundColor: brandColors.brandPrimary,
    },
    graphBudget: {
      borderColor: brandColors.brandPrimaryHighlight,
      backgroundColor: `${brandColors.brandPrimaryHighlight}20`,
    },
  },
});

const getDoughnutColor = (partial = 0, total = 0, styles) => {
  if (partial === null || total === null || (partial === 0 && total === 0)) {
    return ['#00000020', '#00000000'];
  }

  const percentage = parseFloat(partial) / parseFloat(total);

  if (percentage < 0.25) {
    return [
      styles.doughnut.colorTertiary,
      `${styles.doughnut.colorTertiary}20`,
    ];
  }
  if (percentage >= 0.25 && percentage < 0.75) {
    return [
      styles.doughnut.colorSecondary,
      `${styles.doughnut.colorSecondary}20`,
    ];
  }
  if (percentage >= 0.75) {
    return [styles.doughnut.colorPrimary, `${styles.doughnut.colorPrimary}20`];
  }

  return ['#00000020', '#00000000'];
};

const getBoxColor = (partial, total, styles) => {
  if (partial === null || total === null) {
    return '#ffffff';
  }

  if (partial === 0 || total === 0) {
    return styles.boxFill.colorPrimary;
  }

  const percentage = partial / total;

  if (percentage < 0.25) {
    return styles.boxFill.colorPrimary;
  }
  if (percentage >= 0.25 && percentage < 0.75) {
    return styles.boxFill.colorSecondary;
  }
  if (percentage >= 0.75) {
    return styles.boxFill.colorTertiary;
  }

  return '#ffffff';
};

const getDurationArray = () => {
  const days = 15;

  const dayArray = [];
  for (let i = days - 1; i >= 0; i -= 1) {
    dayArray.push(moment().subtract(i, 'days').format('YYYY-MM-DD'));
  }

  return dayArray;
};

const fillMissingDates = (arr) => {
  const da = getDurationArray();
  return da.map((d) => {
    const value = arr.find((v) => v.x === d);
    return value && value !== -1 ? value.y : 0;
  });
};

const prepareGraphData = (data) => {
  // getDurationArray(); // ???
  const actualKm = [];
  const budgetKm = [];
  const plannedKm = [];

  data
    .sort((a, b) => a.tripDate - b.tripDate)
    .forEach((p) => {
      const mDate = moment(p.tripDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
      // labels.push(mDate);
      actualKm.push({x: mDate, y: p.actualKm});
      budgetKm.push({x: mDate, y: p.budgetKm});
      plannedKm.push({x: mDate, y: p.plannedKm});
    });

  return {
    labels: getDurationArray(),
    actualKm: fillMissingDates(actualKm),
    budgetKm: fillMissingDates(budgetKm),
    plannedKm: fillMissingDates(plannedKm),
  };
};

// dummy data
const UtilisationItem = ({
  contractCode,
  graphData,
  movingOwnFleetCount = 0,
  movingOtherFleetCount = 0,
  plannedOwnFleetCount = 0,
  plannedOtherFleetCount = 0,
  totalVehicleCount,
  unplannedVehicleCount,
}) => {
  const styles = getStyle(componentStyle);
  const prepared = prepareGraphData(graphData);
  const totalMovingFleetCount = movingOwnFleetCount + movingOtherFleetCount;
  const totalPlannedFleetCount = plannedOwnFleetCount + plannedOtherFleetCount;
  const plannedDropColor = getDoughnutColor(
    plannedOwnFleetCount,
    totalPlannedFleetCount,
    styles
  );
  const movingDropColor = getDoughnutColor(
    movingOwnFleetCount,
    totalMovingFleetCount,
    styles
  );

  const kmData = {
    labels: prepared.labels,
    datasets: [
      {
        borderColor: styles.graphActual.borderColor,
        backgroundColor: styles.graphActual.backgroundColor,
        data: prepared.actualKm,
        pointRadius: 8,
        label: 'actual',
        type: 'line',
        fill: false,
      },
      {
        borderColor: styles.graphPlanned.borderColor,
        backgroundColor: styles.graphPlanned.backgroundColor,
        data: prepared.plannedKm,
        label: 'planned',
        type: 'bar', // cant use x, y on data for "bar" https://github.com/chartjs/Chart.js/issues/3994
      },
      {
        borderColor: 'transparent',
        backgroundColor: styles.graphActual.backgroundColor,
        data: prepared.actualKm,
        pointRadius: 8,
        label: 'actual',
        type: 'line',
      },
      {
        borderColor: styles.graphBudget.borderColor,
        backgroundColor: styles.graphBudget.backgroundColor,
        label: 'budgeted',
        pointRadius: 0,
        data: prepared.budgetKm,
        type: 'line',
        fill: false,
      },
    ],
  };

  const kmOptions = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          display: true,
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          display: false,
        },
      ],
    },
    layout: {
      padding: {
        bottom: 10,
      },
    },
  };
  return (
    <div style={styles.container}>
      <div style={styles.contractCode}>
        <div style={styles.contractCodeText}>{contractCode}</div>
      </div>
      <div style={styles.graph}>
        <ReactResizeDetector
          handleWidth
          handleHeight
          render={({width, height}) => (
            <MyChart
              height={height}
              width={width}
              data={kmData}
              type="bar"
              options={kmOptions}
            />
          )}
        />
      </div>
      <div style={styles.circleGraph}>
        <div style={styles.circleWrapper}>
          <ReactResizeDetector
            handleHeight
            handleWidth
            render={({height, width}) => (
              <DropGraph
                height={height}
                own={plannedOwnFleetCount || 0}
                other={plannedOtherFleetCount || 0}
                width={width}
                color1={plannedDropColor[1]}
                color2={plannedDropColor[0]}
              />
            )}
          />
        </div>
        <div style={styles.circleGraphLabel}>
          <div style={styles.circleGraphLabelText}>Planned</div>
        </div>
      </div>
      <div style={styles.circleGraph}>
        <div style={styles.circleWrapper}>
          <ReactResizeDetector
            handleHeight
            handleWidth
            render={({height, width}) => (
              <DropGraph
                height={height}
                own={movingOwnFleetCount || 0}
                other={movingOtherFleetCount || 0}
                width={width}
                color1={movingDropColor[1]}
                color2={movingDropColor[0]}
              />
            )}
          />
        </div>
        <div style={styles.circleGraphLabel}>
          <div style={styles.circleGraphLabelText}>Moving</div>
        </div>
      </div>
      <div style={styles.numberBox}>
        <div style={styles.clearBox}>
          <div style={styles.boxText}>{totalVehicleCount}</div>
        </div>
        <div style={styles.boxLabel}>
          <div style={styles.boxLabelText}>Total</div>
        </div>
      </div>
      <div style={styles.numberBox}>
        <div
          style={{
            ...(totalVehicleCount > 0 ? styles.filledBox : styles.clearBox),
            backgroundColor: getBoxColor(
              unplannedVehicleCount,
              totalVehicleCount,
              styles
            ),
          }}
        >
          <div style={styles.boxText}>{unplannedVehicleCount}</div>
        </div>
        <div style={styles.boxLabel}>
          <div style={styles.boxLabelText}>Unplanned Moving</div>
        </div>
      </div>
      <div style={{display: 'flex', flex: 1}} />
    </div>
  );
};

export default UtilisationItem;
