import React from 'react';
import {SiteContext, SiteContextProvider} from './SiteContext';
import _ from 'lodash';
import {SiteList} from './SiteList';
import {SiteIdHandler} from './SiteIdHandler';
import {SiteDetails} from './siteDetails';
import {Site} from './Site';
import {
  ITemplateControllerRoute,
  TemplateController,
} from '../../factory/template';
import {
  SitePreContext,
  SitePreContextProvider,
  SitePreIdHandler,
  SitePreList,
} from './sitePre';
import {
  SitePostContext,
  SitePostContextProvider,
  SitePostIdHandler,
  SitePostList,
} from './sitePost';
import {
  SiteSubNode,
  SiteSubNodeContext,
  SiteSubNodeContextProvider,
  SiteSubNodeForm,
  SiteSubNodeIdHandler,
} from './siteSubNode';

export const SiteControllerComponent: React.FC = () => {
  const {site, loading} = React.useContext(SiteContext);
  const {subNode, loadingSingleItem} = React.useContext(SiteSubNodeContext);
  const {associateNode, loadingSingleItem: associateLoading} =
    React.useContext(SitePreContext);
  const {
    associateNode: postAssociateNode,
    loadingSingleItem: postAssociateLoading,
  } = React.useContext(SitePostContext);
  const routes: ITemplateControllerRoute[] = React.useMemo(
    () => [
      {title: 'Site List', component: SiteList},
      {
        path: '/add',
        title: 'New Site',
        name: 'Add Site',
        type: 'Add',
        component: () => <SiteDetails isAdd />,
      },
      {
        path: '/:siteId',
        title: (params) =>
          loading
            ? 'Site Loading...'
            : site?.name ?? `Site ${_.get(params, 'siteId', 'Single')}`,
        component: Site,
        IdHandler: SiteIdHandler,
      },
      {
        path: '/:siteId/subnodelist/add',
        title: 'New Sub Node',
        name: 'Edit Site',
        type: 'Edit',
        component: () => <SiteSubNodeForm isAdd />,
      },
      {
        path: '/:siteId/subnodelist/:subNodeId',
        title: (params) =>
          loadingSingleItem
            ? 'Sub Node Loading...'
            : subNode?.name ??
              `Sub Node ${_.get(params, 'subNodeId', 'Single')}`,
        component: SiteSubNode,
        IdHandler: SiteSubNodeIdHandler,
      },
      {
        path: '/:siteId/sitePrelist/:associateNodeId',
        title: (params) =>
          associateLoading
            ? 'Associate Node Loading...'
            : associateNode?.mainNode?.name ??
              `Associate Node ${_.get(params, 'associateNodeId', 'Single')}`,
        component: SitePreList,
        IdHandler: SitePreIdHandler,
      },
      {
        path: '/:siteId/sitePostlist/:associateNodeId',
        title: (params) =>
          postAssociateLoading
            ? 'Associate Node Loading...'
            : postAssociateNode?.mainNode?.name ??
              `Associate Node ${_.get(params, 'associateNodeId', 'Single')}`,
        component: SitePostList,
        IdHandler: SitePostIdHandler,
      },
    ],
    [
      loading,
      loadingSingleItem,
      associateLoading,
      postAssociateLoading,
      site?.name,
      subNode?.name,
      associateNode?.mainNode?.name,
      postAssociateNode?.mainNode?.name,
    ]
  );

  return <TemplateController rootPath="/sitelist" routes={routes} />;
};

export const SiteController: React.FC = () => {
  return (
    <SiteContextProvider>
      <SiteSubNodeContextProvider>
        <SitePreContextProvider>
          <SitePostContextProvider>
            <SiteControllerComponent />
          </SitePostContextProvider>
        </SitePreContextProvider>
      </SiteSubNodeContextProvider>
    </SiteContextProvider>
  );
};
