import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import {Loader} from '../loader';

class SiteEditCommitCard extends React.Component {
  render() {
    const {onCancel, onSubmit, loading} = this.props;
    return (
      <Card style={{marginRight: 10, flexGrow: 1, flex: 1}}>
        <CardHeader
          title="Site Edit Actions"
          avatar={
            <Avatar>
              <EditIcon />
            </Avatar>
          }
        />
        <CardContent>
          {loading ? (
            <Loader size={25} />
          ) : (
            <div>
              <Button onClick={onSubmit} data-test="submit">
                Submit
              </Button>
              <Button onClick={onCancel} data-test="reset">
                Reset
              </Button>
            </div>
          )}
        </CardContent>
      </Card>
    );
  }
}

export default SiteEditCommitCard;
