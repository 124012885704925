import React from 'react';
import {
  CriticalEventActivity,
  CriticalEventDump,
  OperationalEventActivity,
  OperationalEventDump,
} from '@onroadvantage/onroadvantage-api';
import {
  TemplateTable,
  TemplateTableConfig,
  TOnInlineAdd,
} from '../../../../factory/template';
import {TripEventListProps} from './TripEventList';
import {useAppNotifications} from '../../../../contexts';
import {criticalEventApi, operationalEventApi} from '../../../../api';
import RoleService from '../../../../service/RoleService';
import {useTripContext} from '../../tripContext';
import {useTripEventListStyles} from './TripEventList.style';

interface TripEventCommentListProps {
  event: CriticalEventDump | OperationalEventDump;
  type: TripEventListProps['type'];
}

export const TripEventCommentList: React.FC<TripEventCommentListProps> = ({
  event,
  type,
}) => {
  const classes = useTripEventListStyles();
  const notify = useAppNotifications();
  const {updateCriticalEvent, updateOperationalEvent} = useTripContext();
  const [items, setItems] = React.useState<
    CriticalEventActivity[] | OperationalEventActivity[] | undefined
  >(event.activities);
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [config] = React.useState<
    TemplateTableConfig<CriticalEventActivity | OperationalEventActivity>
  >({
    columns: [
      {name: 'value', label: 'Value', type: 'string', enableEditing: true},
      {name: 'action', label: 'Action', type: 'string'},
      {name: 'createdAt', label: 'Created At', type: 'datetime'},
      {
        name: 'createdBy',
        label: 'Created By',
        type: 'string',
        getValue: ({createdBy}) => createdBy?.email,
      },
    ],
    disableToolbar: true,
    disablePagination: true,
  });

  const handleInlineAdd = React.useCallback<TOnInlineAdd>(
    async (changes) => {
      setSubmitting(true);
      try {
        if (changes) {
          const value = changes[0].value;
          if (value) {
            let successResponse: boolean;

            if (type === 'Operational') {
              const response =
                await operationalEventApi.apiOperationalEventOperationalEventIdPost(
                  {
                    body: {
                      activities: [
                        {
                          action: 'Comment',
                          value,
                        },
                      ],
                    },
                    operationalEventId: event.id,
                  }
                );
              updateOperationalEvent(response);
              successResponse = true;
            } else {
              const response =
                await criticalEventApi.apiCriticalEventCriticalEventIdPost({
                  body: {
                    activities: [
                      {
                        action: 'Comment',
                        value,
                      },
                    ],
                  },
                  criticalEventId: event.id,
                });
              updateCriticalEvent(response);
              successResponse = true;
            }

            if (successResponse) {
              notify('success', 'Added comment');
            }
          }
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to add comment');
      } finally {
        setSubmitting(false);
      }
    },
    [event.id, notify, type, updateCriticalEvent, updateOperationalEvent]
  );

  React.useEffect(() => {
    setItems(event.activities ?? []);
    return () => setItems([]);
  }, [event.activities]);

  return (
    <TemplateTable
      elevation={0}
      config={config}
      currentPage={1}
      nestedLevel={1}
      list={items ?? []}
      loading={submitting}
      onInlineAdd={
        RoleService.hasPermission(`Add ${type}EventActivity`, 'Add')
          ? handleInlineAdd
          : undefined
      }
      classes={{root: classes.rowDetailTable}}
    />
  );
};
