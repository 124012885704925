import React from 'react';
import _ from 'lodash';
import {
  ITemplateControllerRoute,
  TemplateController,
} from '../../factory/template';
import {
  ContractGroupContext,
  ContractGroupContextProvider,
} from './ContractGroupContext';
import {ContractGroupList} from './ContractGroupList';
import {ContractGroupIdHandler} from './ContractGroupIdHandler';
import {ContractGroupForm} from './ContractGroupForm';
import {ContractGroup} from './ContractGroup';

export const ContractGroupController: React.FC = () => {
  return (
    <ContractGroupContextProvider>
      <ContractGroupControllerComponent />
    </ContractGroupContextProvider>
  );
};

const ContractGroupControllerComponent: React.FC = () => {
  const {contractGroup, loading} = React.useContext(ContractGroupContext);
  const routes: ITemplateControllerRoute[] = React.useMemo(
    () => [
      {title: 'Contract Group List', component: ContractGroupList},
      {
        path: '/add',
        title: 'New Contract Group',
        name: 'Add ContractGroup',
        type: 'Add',
        component: () => <ContractGroupForm isAdd />,
      },
      {
        path: '/:contractGroupId',
        title: (params) =>
          loading
            ? 'Contract Group Loading...'
            : contractGroup?.name ??
              `Contract Group ${_.get(params, 'contractGroupId', 'Single')}`,
        component: ContractGroup,
        IdHandler: ContractGroupIdHandler,
      },
    ],
    [loading, contractGroup?.name]
  );
  return <TemplateController rootPath="/contractgrouplist" routes={routes} />;
};
