import * as React from 'react';
import * as PropTypes from 'prop-types';
import {Table} from '@devexpress/dx-react-grid-material-ui';
import withStyles from '@mui/styles/withStyles';
import {Link} from 'react-router-dom';

const styles = (theme) => ({
  Cell: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  Link: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: 'black',
  },
});

export const LinkCellBase = ({classes, style, value, link, ...props}) => {
  return (
    <Table.Cell {...props} className={classes.Cell} style={style}>
      <Link className={classes.Link} to={{pathname: link}} target="_blank">
        {value}
      </Link>
    </Table.Cell>
  );
};

LinkCellBase.propTypes = {
  link: PropTypes.string, // eslint-disable-line react/forbid-prop-types
  value: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};
LinkCellBase.defaultProps = {
  style: {},
};

export const LinkCell = withStyles(styles, {name: 'LinkCell'})(LinkCellBase);
