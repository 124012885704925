import React from 'react';
import {Box, Typography, Theme} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import {makeStyles} from '@mui/styles';
import custImg from '../../../../img/background/popupbackground.png';

import PopupBase from './PopupBase';
import waypoint from '../../../../img/svg/vantageLocation.svg';

const useMasterRouteWaypointStyles = makeStyles((theme: Theme) =>
  createStyles({
    removeMargin: {
      margin: 0,
      padding: 0,
    },
    headingColor: {
      color: '#767676',
    },
    fab: {
      top: theme.spacing(1),
      right: theme.spacing(1),
      position: 'absolute',
      boxShadow: 'none',
      width: 36,
    },
    speedBox: {
      BoxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.24)',
      background: 'linear-gradient(90deg,#00B7A3 0%,#008270 100%)',
      borderRadius: '12px',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    bottomFab: {
      background: 'linear-gradient(90deg, #00B7A3 0%, #008270 100%)',
      transform: 'rotate(-180deg)',
      boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.24)',
    },
    divider: {
      width: '1px',
      background: '#fff',
      marginTop: '10px',
      marginBottom: '10px',
    },
    backgrounCard: {
      borderRadius: '12px 12px 12px 12px',
      width: '100%',
      boxShadow: 'none',
    },

    eventDesc: {
      fontWeight: 400,
      fontSize: 12,
      paddingLeft: 10,
    },
    spacing: {
      margin: theme.spacing(2),
    },
    parentScrollDiv: {
      overflow: 'auto',
      maxHeight: '400px',
      minHeight: '200px',
      borderRadius: '12px 12px 12px 12px',
    },
    custButton: {
      backgroundColor: '#E5E5E5',
    },
  })
);

interface Props {
  position: {latitude: any; longitude: any};
  onEdit: (args: any) => void;
  sequence: any;
}

export default function MasterRouteWaypoint({
  onEdit,
  position,
  sequence,
}: Props) {
  const classes = useMasterRouteWaypointStyles();
  return (
    <PopupBase
      backgroundImage={custImg}
      mainIcon={waypoint}
      left={{data: onEdit, type: 'button', icon: 'delete'}}
      right={{header: 'Type', data: 'Waypoint', type: 'text'}}
    >
      <Box mb={1}>
        <Typography variant="h5" className={classes.headingColor}>
          Position
        </Typography>
        <Typography variant="h6">{`${position.latitude}, ${position.longitude}`}</Typography>
      </Box>
      <Box mb={1}>
        <Typography variant="h5" className={classes.headingColor}>
          Sequence
        </Typography>
        <Typography variant="h6">{sequence}</Typography>
      </Box>
    </PopupBase>
  );
}
