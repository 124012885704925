import React from 'react';
import {FormikTextField} from '../../formik';
import {TextFieldProps} from '@mui/material';

interface Props {
  id: any;
  disabled?: boolean;
  label?: string;
  name: string;
  type?: 'date' | 'datetime' | 'integer';
}

export const ReportParameterRegex: React.FC<TextFieldProps & Props> = ({
  type,

  ...props
}) => <FormikTextField fullWidth {...props} />;
