import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from '@mui/material';

const EventsPaneSearchFormActionsStyles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      gap: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    searchButton: {},
  });

export const useEventsPaneSearchFormActionsStyles = makeStyles(
  EventsPaneSearchFormActionsStyles,
  {name: 'EventsPaneSearchFormActionsStyles'}
);
