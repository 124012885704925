import React from 'react';
import {DataTypeProvider} from '@devexpress/dx-react-grid';
import {TableActionButtonBase} from './TableActionButtonBase';
import {ChevronRight} from '@mui/icons-material';
import {useTemplateTableActions} from '../../providers';

export const TableActionButtonExpand: React.FC<
  DataTypeProvider.ValueFormatterProps
> = ({row}) => {
  const {rowDetail, expandedRowIds, expandRowsHandler} =
    useTemplateTableActions();

  const expanded = React.useMemo(
    () => expandedRowIds?.includes(row.id),
    [expandedRowIds, row.id]
  );

  const handleExpand = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      if (expandRowsHandler) expandRowsHandler(row);
    },
    [expandRowsHandler, row]
  );

  if (!rowDetail) {
    return null;
  }

  return (
    <TableActionButtonBase
      data-cy="TemplateTableActionsNavigate"
      onClick={handleExpand}
    >
      <ChevronRight style={{transform: expanded ? 'rotate(90deg)' : 'none'}} />
    </TableActionButtonBase>
  );
};
