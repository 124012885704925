import React from 'react';
import {Listing} from '@onroadvantage/onroadvantage-api';
import {useTripDebriefConfigContext} from '../tripDebriefConfigContext';
import {
  splitByCapitals,
  TemplateTable,
  TemplateTableConfig,
  TOnInlineAdd,
} from '../../../factory/template';
import {masterTripDebriefConfigApi} from '../../../api';
import {useAppNotifications} from '../../../contexts';
import {RoleService} from '../../../service';
import {
  TDebriefConfigEventTypes,
  useTripDebriefConfigEventTypeList,
} from './useTripDebriefConfigEventTypeList';

export interface TripDebriefConfigEventTypeListProps {
  list: TDebriefConfigEventTypes[];
  type: 'CriticalEventType' | 'OperationalEventType' | 'MediaEventType';
}

export const TripDebriefConfigEventTypeList: React.FC<
  TripDebriefConfigEventTypeListProps
> = ({list: eventTypeList, type}) => {
  const notify = useAppNotifications();
  const {
    loadingTripDebriefConfig,
    masterTripDebriefConfig,
    masterTripDebriefConfigId,
    setMasterTripDebriefConfig,
  } = useTripDebriefConfigContext();
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const tripDebriefConfigEventTypeList = useTripDebriefConfigEventTypeList({
    eventTypeList,
  });

  const config = React.useMemo<
    TemplateTableConfig<TDebriefConfigEventTypes>
  >(() => {
    const columns: TemplateTableConfig<TDebriefConfigEventTypes>['columns'] = [
      {
        name: 'name',
        label: 'Name',
        type: 'string',
        enableEditing: true,
        enableAutocomplete: true,
        enableMulti: true,
        model: type,
      },
    ];

    if (type !== 'MediaEventType') {
      columns.push({
        name: 'externalReference',
        label: 'External Reference',
        type: 'string',
        enableEditing: false,
      });
    }

    return {
      columns,
      enableFilter: true,
      enableSort: true,
      disablePagination: true,
      disableToolbar: true,
      deleteMessage: (row) => {
        const items: {[key: string]: React.ReactNode} = {
          TripDebriefConfig: masterTripDebriefConfig?.name,
          Name: row.name,
        };

        if ('externalReference' in row) {
          items['ExternalReference'] = row.externalReference;
        }

        return {
          title: `Trip Debrief Config  ${splitByCapitals(type)}`,
          items,
        };
      },
      identifier: `TRIP_DEBRIEF_CONFIG_${splitByCapitals(type, {
        capabilitiesAllLetters: true,
        joinCharacter: '_',
      })}_LIST`,
    };
  }, [masterTripDebriefConfig?.name, type]);

  const handleInlineAdd = React.useCallback<TOnInlineAdd>(
    async (changes) => {
      setSubmitting(true);
      try {
        const eventTypes = changes[0]?.name as Listing[] | undefined;

        if (masterTripDebriefConfigId && eventTypes && eventTypes.length > 0) {
          const response =
            await masterTripDebriefConfigApi.apiMasterTripDebriefConfigMasterTripDebriefConfigIdEventTypesAssignPost(
              {
                masterTripDebriefConfigId,
                body: {
                  model: type,
                  eventTypeIds: eventTypes
                    .filter(({value}) => !!value)
                    .map(({value}) => value as number),
                },
              }
            );
          if (response) {
            setMasterTripDebriefConfig(response);
            notify(
              'success',
              `Assigned ${splitByCapitals(type, {
                lowercaseAllLetters: true,
              })} to trip debrief config`
            );
          } else {
            notify(
              'error',
              `Failed to assign ${splitByCapitals(type, {
                lowercaseAllLetters: true,
              })} to trip debrief config`
            );
          }
        }
      } catch (e) {
        notify(
          'error',
          `Failed to assign ${splitByCapitals(type, {
            lowercaseAllLetters: true,
          })} to trip debrief config`
        );
      } finally {
        setSubmitting(false);
      }
    },
    [masterTripDebriefConfigId, notify, setMasterTripDebriefConfig, type]
  );

  const handleDelete = React.useCallback(
    async (row: TDebriefConfigEventTypes) => {
      setSubmitting(true);
      try {
        if (masterTripDebriefConfigId && row.id) {
          const response =
            await masterTripDebriefConfigApi.apiMasterTripDebriefConfigMasterTripDebriefConfigIdEventTypeUnassignEventTypeIdPost(
              {
                masterTripDebriefConfigId,
                eventTypeId: row.id,
                body: {model: type},
              }
            );
          if (response) {
            setMasterTripDebriefConfig(response);
            notify(
              'success',
              `Unassigned ${splitByCapitals(type, {
                lowercaseAllLetters: true,
              })} from trip debrief config`
            );
          } else {
            notify(
              'error',
              `Failed to unassign ${splitByCapitals(type, {
                lowercaseAllLetters: true,
              })} from trip debrief config`
            );
          }
        }
      } catch (e) {
        notify(
          'error',
          `Failed to unassign ${splitByCapitals(type, {
            lowercaseAllLetters: true,
          })} from trip debrief config`
        );
      } finally {
        setSubmitting(false);
      }
    },
    [masterTripDebriefConfigId, notify, setMasterTripDebriefConfig, type]
  );

  return (
    <TemplateTable
      config={config}
      currentPage={1}
      loading={loadingTripDebriefConfig || submitting}
      onInlineAdd={
        RoleService.hasPermission('Edit MasterTripDebriefConfig', 'Edit')
          ? handleInlineAdd
          : undefined
      }
      onDelete={
        RoleService.hasPermission('Edit MasterTripDebriefConfig', 'Edit')
          ? handleDelete
          : undefined
      }
      {...tripDebriefConfigEventTypeList}
    />
  );
};
