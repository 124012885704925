import React from 'react';
import _ from 'lodash';
import {
  PlanningSkillContext,
  PlanningSkillContextProps,
  PlanningSkillContextProvider,
} from './PlanningSkillContext';
import {PlanningSkillList} from './PlanningSkillList';
import {PlanningSkill} from './PlanningSkill';
import {PlanningSkillForm} from './PlanningSkillForm';
import {
  ITemplateControllerRoute,
  TemplateController,
} from '../../factory/template';
import {PlanningSkillIdHandler} from './PlanningSkillIdHandler';
import {PlanningSkillLinkingListsProviders} from './PlanningSkillLinkingListsProviders';

export const PlanningSkillControllerComponent: React.FC = () => {
  const {planningSkill, loading} =
    React.useContext<PlanningSkillContextProps>(PlanningSkillContext);

  const routes: ITemplateControllerRoute[] = React.useMemo(
    () => [
      {title: 'Planning Skill List', component: PlanningSkillList},
      {
        path: '/add',
        title: 'New Planning Skill',
        name: 'Add PlanningSkill',
        type: 'Add',
        component: () => <PlanningSkillForm isAdd />,
      },
      {
        path: '/:planningSkillId',
        title: (params) =>
          loading
            ? 'Planning Skill Loading...'
            : planningSkill?.name ??
              `Planning Skill ${_.get(params, 'planningSkillId', 'Single')}`,
        component: PlanningSkill,
        IdHandler: PlanningSkillIdHandler,
      },
    ],
    [loading, planningSkill?.name]
  );

  return <TemplateController rootPath="/planningskilllist" routes={routes} />;
};
export const PlanningSkillController: React.FC = () => {
  return (
    <PlanningSkillContextProvider>
      <PlanningSkillLinkingListsProviders>
        <PlanningSkillControllerComponent />
      </PlanningSkillLinkingListsProviders>
    </PlanningSkillContextProvider>
  );
};
