import React from 'react';
import {
  ITemplateTabControllerTab,
  TemplateTabs,
} from '../../../factory/template';
import {NotificationConfigOperationalEventNodeContacts} from './NotificationConfigOperationalEventNodeContacts';
import {NotificationConfigContext} from '../NotificationConfigContext';
import {NotificationConfigOperationalEventContext} from './NotificationConfigOperationalEventContext';
import {NotificationConfigOperationalEventContractContacts} from './NotificationConfigOperationalEventContractContacts';
import {NotificationConfigOperationalEventCustomerContacts} from './NotificationConfigOperationalEventCustomerContacts';
import {NotificationConfigOperationalEventVehicleGroupContacts} from './NotificationConfigOperationalEventVehicleGroupContacts';

export const NotificationConfigOperationalEvent: React.FC = () => {
  const {notificationConfigId} = React.useContext(NotificationConfigContext);
  const {notificationConfigEventId, loading} = React.useContext(
    NotificationConfigOperationalEventContext
  );

  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {
      title: 'Contract Contact',
      element: <NotificationConfigOperationalEventContractContacts />,
    },
    {
      title: 'Customer Contact',
      element: <NotificationConfigOperationalEventCustomerContacts />,
    },
    {
      title: 'Node Contact',
      element: <NotificationConfigOperationalEventNodeContacts />,
    },
    {
      title: 'Vehicle Group Contact',
      element: <NotificationConfigOperationalEventVehicleGroupContacts />,
    },
  ]);

  const path = React.useMemo(
    () =>
      `/notificationconfiglist/${notificationConfigId}/event/${notificationConfigEventId}`,
    [notificationConfigId, notificationConfigEventId]
  );

  return <TemplateTabs tabs={tabs} path={path} loading={loading} />;
};
