import React from 'react';
import {UserTrainingCourse as UserTrainingCourseType} from '@onroadvantage/onroadvantage-api';
import {TemplateTable, TemplateTableConfig} from '../../factory/template';
import {UserTrainingCourseContext} from './UserTrainingCourseContext';
import _ from 'lodash';

export interface UserTrainingCourseListProps {
  id?: string;
}

export const UserTrainingCourseList: React.FC<
  UserTrainingCourseListProps
> = () => {
  const {loadList, onCourseStart, ...context} = React.useContext(
    UserTrainingCourseContext
  );

  const [config] = React.useState<TemplateTableConfig<UserTrainingCourseType>>({
    columns: [
      {
        name: 'trainingCourse.name' as 'trainingCourse',
        label: 'Training Course Name',
        enableFilter: false,
        enableSort: false,
        getValue: (i) => _.get(i, 'trainingCourse.name'),
        type: 'string',
      },
      {
        name: 'mark',
        label: 'Mark',
        type: 'number',
        enableFilter: false,
        enableSort: false,
      },
      {
        name: 'startedAt',
        label: 'Started At',
        type: 'datetime',
        enableFilter: false,
        enableSort: false,
      },
      {
        name: 'completedAt',
        label: 'Completed At',
        type: 'datetime',
        enableFilter: false,
        enableSort: false,
      },
      {
        name: 'status',
        label: 'Status',
        type: 'string',
        enableFilter: false,
        enableSort: false,
      },
      {
        name: 'trainingCourse.link' as 'trainingCourse', // TODO
        label: 'Link',
        type: 'link',
        getValue: (i) => ({
          label: 'Go To Course',
          link: _.get(i, 'trainingCourse.link'),
          action: onCourseStart,
        }),
      },
    ],
    disablePagination: false,
    disableToolbar: false,
    enableFilter: false,
    enableGrouping: false,
    enableSort: false,
    identifier: 'USER_TRAINING_COURSE_LIST',
  });

  React.useEffect(() => {
    loadList();
  }, [loadList]);

  return <TemplateTable config={config} {...context} />;
};
