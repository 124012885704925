import React from 'react';
import {NotificationConfigContext} from './NotificationConfigContext';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../factory/template';
import {NotificationConfig} from '@onroadvantage/onroadvantage-api';

export const NotificationConfigList: React.FC = () => {
  const {loadList, cleanupList} = React.useContext(NotificationConfigContext);

  const [config] = React.useState<TemplateTableConfig<NotificationConfig>>({
    columns: [
      {name: 'name', label: 'Name', type: 'string'},
      {
        name: 'smsEnabled',
        label: 'SMS Enabled',
        type: 'bool',
        enableFilter: false,
      },
      {
        name: 'emailEnabled',
        label: 'Email Enabled',
        type: 'bool',
        enableFilter: false,
      },
      {
        name: 'whatsappEnabled',
        label: 'WhatsApp Enabled',
        type: 'bool',
        enableFilter: false,
      },
      {
        name: 'callEnabled',
        label: 'Call Enabled',
        type: 'bool',
        enableFilter: false,
      },
    ],
    enableFilter: true,
    enableSort: true,
    deleteMessage: (row) => ({
      title: 'Notification Config',
      items: {Name: row?.name},
    }),
    identifier: 'NOTIFICATION_CONFIG_LIST',
  });

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [loadList, cleanupList]);

  return (
    <TemplateTableWrapper config={config} context={NotificationConfigContext} />
  );
};
