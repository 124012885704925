import React from 'react';
import {DialogContentText} from '@mui/material';
import {Edit} from '@mui/icons-material';
import {VantageDialogButton} from '../../../dialog';

interface TripSummaryEditDialogProps {
  hasPermission: boolean;
  loading: boolean;
  title: string;
  onAccept: () => Promise<void>;
}

export const TripSummaryEditDialog: React.FC<TripSummaryEditDialogProps> = ({
  hasPermission,
  loading,
  title,
  onAccept,
  children,
}) => {
  if (!hasPermission) {
    return null;
  }

  return (
    <VantageDialogButton
      title={<Edit />}
      dialogTitle={title}
      onAccept={onAccept}
      declineProps={{variant: 'outlined', color: 'primary'}}
      acceptProps={{variant: 'contained', sx: {mx: 1}}}
      declineTitle="Cancel"
      acceptTitle="Save"
      actionsStyle={{justifyContent: 'flex-start'}}
      dialogActionOptions={{closeDialog: 'before'}}
      disableAccept={loading}
      disableDecline={loading}
      reverseActions
      iconButton
    >
      <DialogContentText>
        Type the <strong>{title}</strong> you wish to allocate to this trip
      </DialogContentText>
      {children}
    </VantageDialogButton>
  );
};
