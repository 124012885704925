import React from 'react';
import {TableFixedColumns as TableFixedColumnsBase} from '@devexpress/dx-react-grid';
import {TableFixedColumns} from '@devexpress/dx-react-grid-material-ui';
import {useTableFixedColumnCellStyles} from './TableFixedColumnCell.style';

export const TableFixedColumnsCell: React.FC<
  TableFixedColumnsBase.CellProps
> = (props) => {
  const classes = useTableFixedColumnCellStyles();
  return (
    <TableFixedColumns.Cell
      {...props}
      className={classes.root}
      showLeftDivider={false}
      showRightDivider={true}
    >
      {props.children}
    </TableFixedColumns.Cell>
  );
};
