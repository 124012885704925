import React from 'react';
import {
  MasterTripDebriefOrderLineSnapshot,
  MasterTripDebriefOrderSnapshot,
  MasterTripDebriefSnapshot,
} from '@onroadvantage/onroadvantage-api';
import {
  TemplateTable,
  TemplateTableConfig,
} from '../../../../../factory/template';
import {useHistory} from 'react-router-dom';
import {TripDebriefContext} from '../../../TripDebriefContext';
import {Typography} from '@mui/material';

interface MasterTripDebriefOrderLineSnapshotWithChangedChecker
  extends MasterTripDebriefOrderLineSnapshot {
  changedActualQuantity: boolean;
  changedActualLoadedQuantity: boolean;
}

interface TripDebriefSummaryStopOrderLineListProps {
  row: MasterTripDebriefOrderSnapshot;
}

export const TripDebriefSummaryStopOrderLineList: React.FC<
  TripDebriefSummaryStopOrderLineListProps
> = ({row}) => {
  const history = useHistory();
  const {masterTripId, masterTripDebriefData} =
    React.useContext(TripDebriefContext);
  /**
   *  Destructure overrideData, need to add || ({} as MasterTripDebriefSnapshot) for typescript, since
   *  overrideData is nullable.
   */
  const {orders: ordersOverride} =
    masterTripDebriefData?.overrideData || ({} as MasterTripDebriefSnapshot);

  const [config] = React.useState<
    TemplateTableConfig<MasterTripDebriefOrderLineSnapshotWithChangedChecker>
  >({
    columns: [
      {
        name: 'product',
        label: 'Product Name',
        type: 'string',
        model: 'Product',
        enableAutocomplete: true,
        enableEditing: false,
        getValue: ({product}) => product?.name,
        enableFilter: false,
      },
      {
        name: 'description',
        label: 'Description',
        type: 'string',
        enableEditing: false,
        enableFilter: false,
      },
      {
        name: 'quantity',
        label: 'Planned Quantity',
        type: 'number',
        enableEditing: false,
        enableFilter: false,
      },
      {
        name: 'actualLoadedQuantity',
        label: 'Loaded Quantity',
        type: 'number',
        getValue: ({changedActualLoadedQuantity, actualLoadedQuantity}) => (
          <Typography
            color={
              changedActualLoadedQuantity ? 'secondary.main' : 'text.primary'
            }
          >
            {actualLoadedQuantity}
          </Typography>
        ),
      },
      {
        name: 'actualQuantity',
        label: 'Offloaded Quantity',
        type: 'number',
        getValue: ({changedActualQuantity, actualQuantity}) => (
          <Typography
            color={changedActualQuantity ? 'secondary.main' : 'text.primary'}
          >
            {actualQuantity}
          </Typography>
        ),
      },
    ],
    disableToolbar: true,
    disablePagination: true,
  });

  const list = React.useMemo(() => {
    const orderLinesOverride: MasterTripDebriefOrderLineSnapshot[] =
      ordersOverride?.find(({id}) => id === row.id)?.lines ?? [];

    const orderLines: MasterTripDebriefOrderLineSnapshotWithChangedChecker[] =
      [];

    row.lines?.forEach((line) => {
      if (orderLinesOverride.map(({id}) => id).includes(line.id)) {
        const overrideLine = orderLinesOverride.find(({id}) => id === line.id);
        orderLines.push({
          ...line,
          actualQuantity: overrideLine?.actualQuantity ?? line.actualQuantity,
          actualLoadedQuantity:
            overrideLine?.actualLoadedQuantity ?? line.actualLoadedQuantity,
          changedActualQuantity: !!overrideLine?.actualQuantity,
          changedActualLoadedQuantity: !!overrideLine?.actualLoadedQuantity,
        });
      } else {
        orderLines.push({
          ...line,
          changedActualQuantity: false,
          changedActualLoadedQuantity: false,
        });
      }
    });
    return orderLines;
  }, [ordersOverride, row.id, row.lines]);

  const handleNavigate = React.useCallback(
    (row: MasterTripDebriefOrderLineSnapshot) => {
      if (masterTripId) {
        history.push(`/tripdebrieflist/${masterTripId}/orderline/${row.id}`);
      }
    },
    [history, masterTripId]
  );

  return (
    <TemplateTable
      config={config}
      currentPage={1}
      list={list}
      onNavigate={handleNavigate}
    />
  );
};
