import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import {TableFilterBooleanCell} from '../components/tableCell/TableFilterBooleanCell';

export const BooleanTypeProvider: React.ComponentType<DataTypeProviderProps> = (
  props
) => (
  <DataTypeProvider
    formatterComponent={({value}) => (
      <>{value === undefined ? '-' : value ? 'YES' : 'NO'}</>
    )}
    editorComponent={TableFilterBooleanCell}
    {...props}
  />
);
