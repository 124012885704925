import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Timeline from '../../timeline/timeline';
import {PlanningMasterTripTrip} from '@onroadvantage/onroadvantage-api';
import {timelineDisplayStore} from '../../../stores/mobxStores';
import {TripService} from '../../../service';
import {planningMasterTripApi} from '../../../api';

interface Props {
  trip: PlanningMasterTripTrip;
  style?: any;
}

interface State {
  isPlaying: boolean;
}

class PlanningTripGantt extends React.Component<Props, State> {
  updateTripStopStartTime = async ({stopDepartureTime}: any) => {
    const {trip} = this.props;
    timelineDisplayStore.setTelematicsLoading(true);
    const tripStops = TripService.loadEditTripStops(trip);
    const t = TripService.formulateEditPayload(trip, tripStops);
    t.stops[0].departureTime = stopDepartureTime;
    const responsePlanningMasterTrip =
      await planningMasterTripApi.apiPlanningMasterTripEditPost({body: t});

    if (
      responsePlanningMasterTrip &&
      responsePlanningMasterTrip.trip &&
      responsePlanningMasterTrip.trip.id
    ) {
      setTimeout(() => {
        window.location.replace(
          window.location.href.replace(
            `/t/${trip.id}`,
            // @ts-expect-error upgrade
            `/t/${responsePlanningMasterTrip.trip.id}`
          ) + '?resetForm=1'
        ); // TODO change tripId while you at it
      }, 1000);
    }
  };

  render() {
    const {trip} = this.props;

    timelineDisplayStore.setTelematicsEventsDateRange({
      startDate: trip.tripStart,
      endDate: trip.tripEnd,
    });
    timelineDisplayStore.setTelematicsLoading(false);

    const {isPlaying, togglePlaying} = timelineDisplayStore;

    const playback = {
      togglePlaying,
      nextFrame: () => {},
      prevFrame: () => {},
      isPlaying,
    };

    return (
      <Card style={{width: '100%'}}>
        <CardContent>
          <Timeline
            playback={playback}
            loading={false}
            trip={trip}
            actualTrip={{}}
            updateTelematicsEventsRange={() => {}}
            updateTripStopStartTime={this.updateTripStopStartTime}
          />
        </CardContent>
      </Card>
    );
  }
}

export default PlanningTripGantt;
