import React from 'react';
import {Card} from '@mui/material';
import {DriverContext} from '../DriverContext';
import {DriverUserDetails} from './DriverUserDetails';
import {DriverUserActions} from './DriverUserActions';
import {Loader} from '../../loader';

export const DriverUser: React.FC = () => {
  const {loading} = React.useContext(DriverContext);
  if (loading) {
    return <Loader />;
  }
  return (
    <Card>
      <DriverUserDetails />
      <DriverUserActions />
    </Card>
  );
};
