import React from 'react';
import * as Yup from 'yup';
import {Grid, Stack, Typography} from '@mui/material';
import {TemplateForm} from '../../factory/template';
import {useTripDebriefConfigContext} from './tripDebriefConfigContext';
import {Loader} from '../loader';
import {TripDebriefConfigCard} from './tripDebriefConfigCard';

export interface ITripDebriefConfigRequiredFields {
  actualTripEnd: boolean;
  actualTripStart: boolean;
  debrieferName: boolean;
  criticalEventTypeReasonCode: boolean;
  operationalEventTypeReasonCode: boolean;
  mediaEventTypeReasonCode: boolean;
  manualEndOdometer: boolean;
  manualStartOdometer: boolean;
  tatThresholdReasonCode: boolean;
  varianceThresholdReasonCode: boolean;
}

const schema: Yup.SchemaOf<ITripDebriefConfigRequiredFields> = Yup.object({
  actualTripEnd: Yup.boolean().required('Required'),
  actualTripStart: Yup.boolean().required('Required'),
  debrieferName: Yup.boolean().required('Required'),
  criticalEventTypeReasonCode: Yup.boolean().required('Required'),
  operationalEventTypeReasonCode: Yup.boolean().required('Required'),
  mediaEventTypeReasonCode: Yup.boolean().required('Required'),
  manualEndOdometer: Yup.boolean().required('Required'),
  manualStartOdometer: Yup.boolean().required('Required'),
  tatThresholdReasonCode: Yup.boolean().required('Required'),
  varianceThresholdReasonCode: Yup.boolean().required('Required'),
});

export const TripDebriefConfigRequiredFields: React.FC = () => {
  const {
    loadingTripDebriefConfig,
    masterTripDebriefConfig,
    submitting,
    onSubmitRequiredFields,
  } = useTripDebriefConfigContext();

  const initialValues = React.useMemo<ITripDebriefConfigRequiredFields>(
    () => ({
      actualTripEnd: !!masterTripDebriefConfig?.requiredFields?.actualTripEnd,
      actualTripStart:
        !!masterTripDebriefConfig?.requiredFields?.actualTripStart,
      debrieferName: !!masterTripDebriefConfig?.requiredFields?.debrieferName,
      criticalEventTypeReasonCode:
        !!masterTripDebriefConfig?.requiredFields?.criticalEventTypeReasonCode,
      operationalEventTypeReasonCode:
        !!masterTripDebriefConfig?.requiredFields
          ?.operationalEventTypeReasonCode,
      mediaEventTypeReasonCode:
        !!masterTripDebriefConfig?.requiredFields?.mediaEventTypeReasonCode,
      manualEndOdometer:
        !!masterTripDebriefConfig?.requiredFields?.manualEndOdometer,
      manualStartOdometer:
        !!masterTripDebriefConfig?.requiredFields?.manualStartOdometer,
      tatThresholdReasonCode:
        !!masterTripDebriefConfig?.requiredFields?.tatThresholdReasonCode,
      varianceThresholdReasonCode:
        !!masterTripDebriefConfig?.requiredFields?.varianceThresholdReasonCode,
    }),
    [masterTripDebriefConfig?.requiredFields]
  );

  if (loadingTripDebriefConfig) {
    return <Loader text="Loading Trip Debrief Config..." />;
  }

  if (!initialValues) {
    return (
      <Typography variant="h3" textAlign="center">
        No Initial Values
      </Typography>
    );
  }

  return (
    <Stack p={2}>
      <TemplateForm
        onSubmit={onSubmitRequiredFields}
        initialValues={initialValues}
        validationSchema={schema}
        submitting={submitting}
      >
        <Grid pb={2} spacing={3} container>
          <TripDebriefConfigCard
            name="actualTripEnd"
            title="Actual Trip End"
            toggle="switch"
          />
          <TripDebriefConfigCard
            name="actualTripStart"
            title="Actual Trip Start"
            toggle="switch"
          />
          <TripDebriefConfigCard
            name="manualEndOdometer"
            title="Manual End Odometer"
            toggle="switch"
          />
          <TripDebriefConfigCard
            name="manualStartOdometer"
            title="Manual Start Odometer"
            toggle="switch"
          />
          <TripDebriefConfigCard
            name="debrieferName"
            title="Debriefer Name"
            toggle="switch"
          />
          <TripDebriefConfigCard
            name="criticalEventTypeReasonCode"
            title="Critical Event Type Reason Code"
            toggle="switch"
          />
          <TripDebriefConfigCard
            name="operationalEventTypeReasonCode"
            title="Operational Event Type Reason Code"
            toggle="switch"
          />
          <TripDebriefConfigCard
            name="mediaEventTypeReasonCode"
            title="Media Event Type Reason Code"
            toggle="switch"
          />
          <TripDebriefConfigCard
            name="tatThresholdReasonCode"
            title="Tat Threshold Reason Code"
            toggle="switch"
          />
          <TripDebriefConfigCard
            name="varianceThresholdReasonCode"
            title="Variance Threshold Reason Code"
            toggle="switch"
          />
        </Grid>
      </TemplateForm>
    </Stack>
  );
};
