import React from 'react';
import {MasterTripRecipientContext} from '../MasterTripRecipientContext';
import {RecipientOrderDetails} from './RecipientOrderDetails';
import {useRecipientDetailsStyles} from './RecipientDetails.style';
import {RecipientDriverDetails} from './RecipientDriverDetails';
import {RecipientVehicleDetails} from './RecipientVehicleDetails';

export const RecipientDetails: React.FC = () => {
  const {masterTrip} = React.useContext(MasterTripRecipientContext);
  const classes = useRecipientDetailsStyles();
  const [activeOrder, setActiveOrder] = React.useState<string>();

  const changeActiveOrderHandler = React.useCallback(
    (orderNumber: string | undefined) => {
      setActiveOrder(orderNumber);
    },
    []
  );

  if (!masterTrip) {
    return null;
  }

  return (
    <div className={classes.details}>
      {masterTrip.trip.vehicle && (
        <RecipientVehicleDetails {...masterTrip.trip.vehicle} />
      )}
      {masterTrip.trip.driver && (
        <RecipientDriverDetails {...masterTrip.trip.driver} />
      )}
      {masterTrip.trip.stops.map((stop) => {
        return stop.orders.map((order) => {
          return (
            <RecipientOrderDetails
              plannedArrival={stop.arrivalTime}
              stopEta={stop.tripStopExecution?.revisedEta}
              key={order.orderNumber}
              activeOrder={activeOrder}
              changeActiveOrderHandler={changeActiveOrderHandler}
              {...order}
            />
          );
        });
      })}
    </div>
  );
};
