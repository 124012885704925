import React from 'react';
import {PlanningBoardGroupOptionsContainer} from './PlanningBoardGroupOptionsContainer';
import {PlanningBoardGroupOptionsItem} from './PlanningBoardGroupOptionsItem';
import {usePlanningBoardSettingsContext} from '../../planningBoardContext';

export const PlanningBoardGroupOptions: React.FC = () => {
  const {selectedGroupOptions, unselectedGroupOptions} =
    usePlanningBoardSettingsContext();
  return (
    <>
      <PlanningBoardGroupOptionsContainer variant="selected">
        {selectedGroupOptions.map((item) => (
          <PlanningBoardGroupOptionsItem
            key={item.key}
            variant="selected"
            item={item}
          />
        ))}
      </PlanningBoardGroupOptionsContainer>
      <PlanningBoardGroupOptionsContainer variant="unselected">
        {unselectedGroupOptions.map((item) => (
          <PlanningBoardGroupOptionsItem
            key={item.key}
            variant="unselected"
            item={item}
          />
        ))}
      </PlanningBoardGroupOptionsContainer>
    </>
  );
};
