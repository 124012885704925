import React from 'react';
import {useParams} from 'react-router-dom';
import {useContractOperationalEventTypePromptContext} from './ContractOperationalEventTypePromptContext';

interface IParams {
  contractOperationalEventTypePromptId?: string;
}

export const ContractOperationalEventTypePromptIdHandler = () => {
  const {setContractOperationalEventTypePromptId} =
    useContractOperationalEventTypePromptContext();

  const {contractOperationalEventTypePromptId} = useParams<IParams>();

  React.useEffect(() => {
    if (
      contractOperationalEventTypePromptId != null &&
      contractOperationalEventTypePromptId !== 'add'
    ) {
      const parsedId = contractOperationalEventTypePromptId
        ? parseInt(contractOperationalEventTypePromptId)
        : undefined;
      setContractOperationalEventTypePromptId(parsedId);
    }
    return () => setContractOperationalEventTypePromptId(undefined);
  }, [
    contractOperationalEventTypePromptId,
    setContractOperationalEventTypePromptId,
  ]);
  return null;
};
