import React from 'react';
import {
  VehicleTypeContext,
  VehicleTypeContextProps,
} from './VehicleTypeContext';
import {useParams} from 'react-router-dom';

interface IParams {
  vehicleTypeId?: string;
}

export const VehicleTypeIdHandler: React.FC = () => {
  const {setVehicleTypeId} =
    React.useContext<VehicleTypeContextProps>(VehicleTypeContext);
  const {vehicleTypeId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = vehicleTypeId ? parseInt(vehicleTypeId) : undefined;
    setVehicleTypeId(parsedId);
    return () => setVehicleTypeId(undefined);
  }, [vehicleTypeId, setVehicleTypeId]);

  return null;
};
