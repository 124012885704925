import React from 'react';
import {ReactMouseEvent, VantageDialogButtonProps} from './VantageDialogButton';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Dialog,
} from '@mui/material';
import {useVantageDialogButtonStyles} from './VantageDialogButton.style';
import {getTransitionComponent} from './TransitionComponents';

export interface IVantageDialogRef {
  openDialog: () => void;
  closeDialog: () => void;
  toggleDialog: () => void;
  setState: React.Dispatch<React.SetStateAction<boolean>>;
  getState: () => boolean;
  isOpen?: boolean;
}

export interface VantageDialogProps
  extends Omit<
    VantageDialogButtonProps,
    'iconButton' | 'IconButtonProps' | 'dataCy'
  > {
  children?: React.ReactNode;
}

export const VantageDialog = React.forwardRef<
  IVantageDialogRef,
  VantageDialogProps
>((props, ref) => {
  const classes = useVantageDialogButtonStyles();
  const {
    acceptProps,
    acceptTitle,
    actionsSx,
    children,
    contentSx,
    declineProps,
    declineTitle,
    DialogTitleProps,
    dialogSx,
    dialogTitle,
    disableAccept,
    disableDecline,
    disableTitle,
    enableDividers,
    fullScreen,
    fullWidth,
    maxWidth,
    onAccept,
    onClose,
    onDecline,
    PaperComponent,
    reverseActions,
    styles,
    title,
    titleSx,
    transition,
  } = props;
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const handleClose = React.useCallback(
    (event, reason) => {
      setOpenDialog(false);
      onClose && onClose(event, reason);
    },
    [onClose]
  );
  const handleOpen = React.useCallback(() => setOpenDialog(true), []);
  const handleToggle = React.useCallback(
    () => setOpenDialog((prevState) => !prevState),
    []
  );
  const handleGetState = React.useCallback(() => openDialog, [openDialog]);
  const handleClick = React.useCallback(
    (action: 'Accept' | 'Decline') => async (e: ReactMouseEvent) => {
      setOpenDialog(false);
      if (action === 'Accept' && onAccept) {
        await onAccept(e);
      } else if (action === 'Decline' && onDecline) {
        await onDecline(e);
      }
    },
    [onAccept, onDecline]
  );
  React.useImperativeHandle(ref, () => ({
    openDialog: handleOpen,
    closeDialog: () => setOpenDialog(false),
    toggleDialog: handleToggle,
    setState: setOpenDialog,
    getState: handleGetState,
    isOpen: openDialog,
  }));
  return (
    <Dialog
      className={styles?.dialog ?? classes.dialog}
      sx={dialogSx}
      open={openDialog}
      onClose={handleClose}
      maxWidth={maxWidth}
      aria-label={`${title} - Vantage Dialog`}
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      PaperComponent={PaperComponent}
      TransitionComponent={getTransitionComponent(transition)}
    >
      {!disableTitle && (
        <DialogTitle
          className={styles?.title ?? classes.title}
          sx={titleSx}
          aria-label={`${title} - Vantage Dialog Title`}
          {...DialogTitleProps}
        >
          {dialogTitle ?? title}
        </DialogTitle>
      )}
      {children && (
        <DialogContent
          className={styles?.content}
          sx={contentSx}
          dividers={enableDividers}
          aria-label={`${title} - Vantage Dialog Content`}
        >
          {children}
        </DialogContent>
      )}
      {disableAccept && disableDecline ? null : (
        <DialogActions
          sx={{
            ...(reverseActions && {flexDirection: 'row-reverse'}),
            ...actionsSx,
          }}
          className={styles?.actions ?? classes.actions}
          aria-label={`${title} - Vantage Dialog Actions`}
        >
          {!disableAccept ? (
            <Button
              type={acceptProps?.type ?? 'button'}
              disabled={acceptProps?.disabled}
              onClick={handleClick('Accept')}
              color={acceptProps?.color ?? 'primary'}
              variant={acceptProps?.variant}
              className={styles?.acceptButton ?? classes.acceptButton}
              sx={acceptProps?.sx}
              data-cy="VantageDialogAccept"
            >
              {acceptTitle ?? 'Yes'}
            </Button>
          ) : null}
          {!disableDecline ? (
            <Button
              type={declineProps?.type ?? 'button'}
              disabled={declineProps?.disabled}
              onClick={handleClick('Decline')}
              color={declineProps?.color ?? 'inherit'}
              className={styles?.declineButton ?? classes.declineButton}
              sx={declineProps?.sx}
              variant={declineProps?.variant}
              data-cy="VantageDialogDecline"
            >
              {declineTitle ?? 'No'}
            </Button>
          ) : null}
        </DialogActions>
      )}
    </Dialog>
  );
});
