import React from 'react';
import {TOnInlineEdit} from '../../../factory/template';
import {SequencedTask, VehicleWorkflow} from '@onroadvantage/onroadvantage-api';
import {taskApi, workflowApi} from '../../../api';
import {parseInt} from 'lodash';
import {useAppNotifications} from '../../../contexts';

export interface WorkflowDetailsContextProps {
  workflowDetails: VehicleWorkflow | undefined;
  loadingDetails: boolean;
  submitting: boolean;
  loadDetails: () => Promise<void>;
  onTaskInlineEdit: TOnInlineEdit;
}

export const WorkflowDetailsContext =
  React.createContext<WorkflowDetailsContextProps>({
    workflowDetails: undefined,
    loadingDetails: false,
    submitting: false,
    loadDetails: async () => {},
    onTaskInlineEdit: () => null,
  });

interface WorkflowDetailsContextProviderProps {
  workflowId: number | undefined;
}

export const WorkflowDetailsContextProvider: React.FC<
  WorkflowDetailsContextProviderProps
> = ({workflowId, children}) => {
  const notify = useAppNotifications();
  const [workflowDetails, setWorkflowDetails] = React.useState<
    VehicleWorkflow | undefined
  >();
  const [loadingDetails, setLoadingDetails] = React.useState<boolean>(false);
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const loadDetails = React.useCallback(async () => {
    setLoadingDetails(true);
    try {
      if (workflowId) {
        const response = await workflowApi.apiWorkflowWorkflowIdGet({
          workflowId,
        });
        setWorkflowDetails(response);
      }
    } finally {
      setLoadingDetails(false);
    }
  }, [workflowId]);

  const handleTaskInlineEdit = React.useCallback(
    (changes?: {id: string; newValues: Partial<SequencedTask>}[]) => {
      setSubmitting(true);
      changes?.forEach(async (change) => {
        try {
          const changedValues = {
            ...(change.newValues.eventDate && {
              eventDate: new Date(change.newValues.eventDate),
            }),
            ...(change.newValues.payload && {
              payload: change.newValues.payload,
            }),
          };
          if (changedValues.payload || changedValues.eventDate) {
            await taskApi.apiTaskTaskIdPatch({
              taskId: parseInt(change.id),
              body: changedValues,
            });
            notify('success', 'Order Line Edited');
            await loadDetails();
          }
        } finally {
          setSubmitting(false);
        }
      });
    },
    [loadDetails, notify]
  );

  React.useEffect(() => {
    loadDetails();
    return () => setWorkflowDetails(undefined);
  }, [loadDetails]);

  const value: WorkflowDetailsContextProps = {
    workflowDetails,
    loadingDetails,
    submitting,
    loadDetails,
    onTaskInlineEdit: handleTaskInlineEdit,
  };

  return (
    <WorkflowDetailsContext.Provider value={value}>
      {children}
    </WorkflowDetailsContext.Provider>
  );
};
