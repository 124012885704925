import React from 'react';
import {useVehicleBookingAvailabilityControllerStyles} from './VehicleBookingAvailabilityController.style';
import {VehicleContextProvider} from './VehicleBookingAvailabilityContext';
import {VehicleBookingAvailability} from './VehicleBookingAvailability';

export const VehicleBookingAvailabilityController: React.FC = () => {
  const classes = useVehicleBookingAvailabilityControllerStyles();
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <VehicleContextProvider>
          <VehicleBookingAvailability />
        </VehicleContextProvider>
      </div>
    </div>
  );
};
