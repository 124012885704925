import React from 'react';
import {
  NUMBER_ERROR_MESSAGE,
  TemplateCard,
  TemplateForm,
} from '../../../factory/template';
import {planningOrderApi} from '../../../api';
import * as Yup from 'yup';
import {FormikDateTimePicker, FormikTextField} from '../../formik';
import {useAppNotifications} from '../../../contexts';
import {FormikHelpers} from 'formik';
export interface IPlanningOrderForm {
  shipmentNumber: string;
  upliftPointDuration?: number;
  upliftPointTimeWindows?: string;
  offloadPointDuration?: number;
  offloadPointTimeWindows?: string;
  skill?: string;
  priority?: number;
  capacityDimension1: number;
  capacityDimension1Unit: string;
  capacityDimension2?: number;
  capacityDimension2Unit?: string;
  collectionDurationInMinutes: number;
  collectionExternalReference: string;
  contractCode: string;
  deliverByDatetime: Date;
  deliveryDurationInMinutes: number;
  deliveryExternalReference: string;
}

const schema = Yup.object({
  shipmentNumber: Yup.string().required(),
  upliftPointDuration: Yup.number().typeError(NUMBER_ERROR_MESSAGE).required(),
  upliftPointTimeWindows: Yup.string(),
  offloadPointDuration: Yup.number().typeError(NUMBER_ERROR_MESSAGE).required(),
  offloadPointTimeWindows: Yup.string(),
  skill: Yup.string(),
  priority: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
  capacityDimension1: Yup.number().typeError(NUMBER_ERROR_MESSAGE).required(),
  capacityDimension1Unit: Yup.string().required(),
  capacityDimension2: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
  capacityDimension2Unit: Yup.string(),
  collectionDurationInMinutes: Yup.number()
    .typeError(NUMBER_ERROR_MESSAGE)
    .required(),
  collectionExternalReference: Yup.string().required(),
  contractCode: Yup.string().required(),
  deliverByDatetime: Yup.string().required(),
  deliveryDurationInMinutes: Yup.number()
    .typeError(NUMBER_ERROR_MESSAGE)
    .required(),
  deliveryExternalReference: Yup.string().required(),
});

export const PlanningOrderCreateForm: React.FC = () => {
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const notify = useAppNotifications();
  const [initialValues] = React.useState<IPlanningOrderForm>({
    shipmentNumber: '',
    upliftPointDuration: 0,
    upliftPointTimeWindows: '',
    offloadPointDuration: 0,
    offloadPointTimeWindows: '',
    skill: '',
    priority: 0,
    capacityDimension1: 0,
    capacityDimension1Unit: '',
    collectionDurationInMinutes: 0,
    collectionExternalReference: '',
    contractCode: '',
    deliverByDatetime: new Date(),
    deliveryDurationInMinutes: 0,
    deliveryExternalReference: '',
  });

  const handleSubmit = React.useCallback(
    async (
      values: IPlanningOrderForm,
      formikHelpers: FormikHelpers<IPlanningOrderForm>
    ) => {
      setSubmitting(true);
      try {
        await planningOrderApi.apiPlanningOrderPost({
          body: values,
        });
        notify('success', 'Planning Order created successfully');
      } catch (error) {
        notify('error', 'Failed to create planning order');
      } finally {
        // Set 'isSubmitting' to 'false' to indicate that the form submission has completed
        formikHelpers.setSubmitting(false);
        setSubmitting(false);
      }
    },
    [notify]
  );

  return (
    <TemplateCard title={`Planning Order Details Add`}>
      <TemplateForm<IPlanningOrderForm>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        permission={{
          name: `Add PlanningOrder`,
          type: 'Add',
        }}
        submitting={submitting}
        validationSchema={schema}
      >
        <FormikTextField
          name="shipmentNumber"
          placeholder="Enter the shipment number"
          label="Shipment Number"
          fullWidth
        />
        <FormikTextField
          name="upliftPointDuration"
          placeholder="Enter the uplift point duration"
          label="uplift Point Duration"
          fullWidth
        />
        <FormikTextField
          name="upliftPointTimeWindows"
          placeholder="Enter the uplift point time windows"
          label="Uplift Point Time Windows"
          fullWidth
        />
        <FormikTextField
          name="offloadPointDuration"
          placeholder="Enter the offload point duration"
          label="Offload Point Duration"
          fullWidth
        />
        <FormikTextField
          name="offloadPointTimeWindows"
          label="Offload Point Time Windows"
          fullWidth
        />
        <FormikTextField
          name="skill"
          placeholder="Enter skill"
          label="Skill"
          fullWidth
        />
        <FormikTextField
          name="priority"
          placeholder="Enter the priority"
          label="Priority"
          fullWidth
        />
        <FormikTextField
          name="capacityDimension1"
          placeholder="Enter capacity dimension 1"
          label="Capacity Dimension 1"
          fullWidth
        />
        <FormikTextField
          name="capacityDimension1Unit"
          placeholder="Enter capacity dimension 1 unit"
          label="Capacity Dimension 1 Unit"
          fullWidth
        />
        <FormikTextField
          name="capacityDimension2"
          placeholder="Enter capacity dimension 2"
          label="Capacity Dimension 2"
          fullWidth
        />
        <FormikTextField
          name="capacityDimension2Unit"
          placeholder="Enter capacity dimension 2 unit"
          label="Capacity Dimension 2 Unit"
          fullWidth
        />

        {/*additional fields*/}
        <FormikTextField
          name="collectionDurationInMinutes"
          placeholder="Enter collection duration in minutes"
          label="Collection Duration (Minutes)"
          fullWidth
        />
        <FormikTextField
          name="collectionExternalReference"
          placeholder="Enter collection external reference"
          label="Collection External Reference"
          fullWidth
        />
        <FormikTextField
          name="contractCode"
          placeholder="Enter contract code"
          label="Contract Code"
          fullWidth
        />
        <FormikDateTimePicker
          name="deliverByDatetime"
          placeholder="Enter deliver by date and time"
          label="Deliver By Date and Time"
          fullWidth
        />
        <FormikTextField
          name="deliveryDurationInMinutes"
          placeholder="Enter delivery duration in minutes"
          label="Delivery Duration (Minutes)"
          fullWidth
        />
        <FormikTextField
          name="deliveryExternalReference"
          placeholder="Enter delivery external reference"
          label="Delivery External Reference"
          fullWidth
        />
      </TemplateForm>
    </TemplateCard>
  );
};
