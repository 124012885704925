import React from 'react';
import {Plugin, Template, TemplatePlaceholder} from '@devexpress/dx-react-core';

interface TableToolbarCustomActionProps {
  toolbarCustomAction: React.ReactNode;
}

export const TableToolbarCustomAction: React.FC<
  TableToolbarCustomActionProps
> = ({toolbarCustomAction}) => {
  return (
    <Plugin dependencies={[{name: 'Toolbar'}]} name="TableToolbarCustomAction">
      <Template name="toolbarContent">
        <TemplatePlaceholder />
        {typeof toolbarCustomAction === 'function'
          ? toolbarCustomAction()
          : toolbarCustomAction}
      </Template>
    </Plugin>
  );
};
