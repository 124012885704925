import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

const PlanningBoardLayoutStyles = (theme: Theme) =>
  createStyles({
    collapse: {
      transition: 'width 0.4s ease, flex 0.4s ease, scale 0.3s ease',
    },
    collapseHeight: {
      transition: 'height 0.4s ease, flex 0.4s ease, scale 0.3s ease',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      alignItems: 'stretch',
    },
    containerPadding: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
    tabs: {marginTop: 0, paddingTop: 0, paddingBottom: 0},
    layout: {},
    rightPanel: {
      position: 'relative',
      flex: 1,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      overflowX: 'hidden',
      overflowY: 'scroll',
      minWidth: '40rem',
      width: '40rem',
      maxWidth: '40rem',
    },
  });

export const usePlanningBoardLayoutStyles = makeStyles(
  PlanningBoardLayoutStyles,
  {
    name: 'PlanningBoardLayoutStyles',
  }
);
