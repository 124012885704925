import React from 'react';
import clsx from 'clsx';
import {NavBar} from './navbar';
import {useVantageFrameStyles} from './VantageFrame.style';
import LeftSidebar from './LeftSidebar';
import {LeftDrawer, RightDrawer} from './drawers';
import {RoleService} from '../../service';
import {AuthConnectionContext, WindowInfoContext} from '../../contexts';
import Loader from '../loader/Loader';
import {LoadingContainer} from './WebFrameStyle/WebFrameStyle';
import {OnlineChecker} from './OnlineChecker';
import {useHistory} from 'react-router-dom';
import {EventsPaneController} from '../eventsPane';
import {VantageFrameContext} from './VantageFrameContext';

export const standalonePaths = [
  'dashboard_standalone',
  'dashboard_datastudio',
  'logout',
  // error pages
  'error-401',
];

export const VantageFrameComponent: React.FC = ({children}) => {
  const classes = useVantageFrameStyles();
  const {isDesktop, isMobile, isTablet} = React.useContext(WindowInfoContext);
  const {
    leftDrawerOpen,
    rightDrawerOpen,
    rightDrawerWasOpened,
    onLeftDrawerOpenToggle,
    onRightDrawerOpenToggle,
  } = React.useContext(VantageFrameContext);

  const [eventTabAllowed] = React.useState<boolean>(
    RoleService.hasPermission('EventTab', 'View')
  );

  return (
    <div className={classes.root}>
      <NavBar
        leftDrawerOpen={leftDrawerOpen}
        onLeftDrawerToggle={onLeftDrawerOpenToggle}
        onRightDrawerToggle={onRightDrawerOpenToggle}
        rightDrawerOpen={rightDrawerOpen}
      />
      <LeftDrawer open={leftDrawerOpen} toggleDrawer={onLeftDrawerOpenToggle}>
        <LeftSidebar />
      </LeftDrawer>
      <div
        className={clsx(classes.content, {
          [classes.contentShiftLeft]: leftDrawerOpen,
          [classes.contentShiftRight]: rightDrawerOpen,
          [classes.contentMobile]: isMobile,
          [classes.contentTablet]: isTablet,
          [classes.hide]: !isDesktop && (leftDrawerOpen || rightDrawerOpen),
        })}
      >
        <div className={classes.drawerHeader} />
        <div className={classes.main}>{children}</div>
      </div>
      <RightDrawer open={rightDrawerOpen}>
        {rightDrawerWasOpened && eventTabAllowed && <EventsPaneController />}
      </RightDrawer>
    </div>
  );
};

export const VantageFrame: React.FC = ({children}) => {
  const {loading, loadingText} = React.useContext(AuthConnectionContext);
  const history = useHistory();
  const [standalone, setStandalone] = React.useState<boolean>(false);

  React.useEffect(() => {
    const activePage = history.location.pathname.split('/')[1];

    if (standalonePaths.includes(activePage)) {
      setStandalone(true);
    } else {
      setStandalone(false);
    }
  }, [history.location.pathname, setStandalone]);

  if (loading) {
    return (
      <div>
        <Loader />
        {loadingText && <LoadingContainer>{loadingText}</LoadingContainer>}
      </div>
    );
  }

  if (standalone) {
    return (
      <>
        {children}
        <OnlineChecker />
      </>
    );
  }

  return (
    <VantageFrameComponent>
      {children}
      <OnlineChecker />
    </VantageFrameComponent>
  );
};
