import React from 'react';
import {
  TemplateTable,
  TemplateTableConfig,
} from '../../../../../factory/template';
import {
  MasterTripDebriefOrderSnapshot,
  MasterTripDebriefSnapshot,
  MasterTripDebriefTripStopSnapshot,
} from '@onroadvantage/onroadvantage-api';
import {TripDebriefContext} from '../../../TripDebriefContext';
import {TripDebriefSummaryStopOrderLineList} from './TripDebriefSummaryStopOrderLineList';

export const TripDebriefSummaryStopOrderList: React.FC = () => {
  const {tripStop, masterTripDebriefData} =
    React.useContext(TripDebriefContext);
  /**
   *  Destructure tripStop, need to add || ({} as MasterTripDebriefTripStopSnapshot) for typescript, since
   *  tripStop is nullable.
   */
  const {orders} = tripStop || ({} as MasterTripDebriefTripStopSnapshot);
  /**
   *  Destructure snapshotData, need to add || ({} as MasterTripDebriefSnapshot) for typescript, since
   *  snapshotData is nullable.
   */
  const {orders: ordersSnapshot} =
    masterTripDebriefData?.snapshotData || ({} as MasterTripDebriefSnapshot);

  const [config] = React.useState<
    TemplateTableConfig<MasterTripDebriefOrderSnapshot>
  >({
    columns: [
      {name: 'orderNumber', label: 'Order Number', type: 'string'},
      {
        name: 'offloadPointName',
        label: 'Offloading Point',
        type: 'string',
        getValue: ({offloadPoint}) => offloadPoint?.name,
      },
      {
        name: 'upliftPointName',
        label: 'Uplift Point',
        type: 'string',
        getValue: ({upliftPoint}) => upliftPoint?.name,
      },
      {name: 'uuid', label: 'Order Recipient Link', type: 'string'},
      {
        name: 'deliverByDateTime',
        label: 'Deliver By',
        type: 'datetime',
      },
      {
        name: 'status',
        label: 'Status',
        type: 'string',
      },
    ],
    disablePagination: true,
    disableToolbar: true,
    identifier: 'TRIP_DEBRIEF_SUMMARY_STOP_ORDER_LIST',
  });

  const list: MasterTripDebriefOrderSnapshot[] = React.useMemo(
    () =>
      ordersSnapshot?.filter(({id}) =>
        orders?.map(({id}) => id)?.includes(id)
      ) ?? [],
    [ordersSnapshot, orders]
  );

  if (!tripStop) return null;

  return (
    <TemplateTable
      config={config}
      currentPage={1}
      list={list}
      rowDetail={TripDebriefSummaryStopOrderLineList}
    />
  );
};
