import React from 'react';
import {useAppNotifications} from '../../../../contexts';
import {Listing} from '@onroadvantage/onroadvantage-api';
import {nodeApi} from '../../../../api';
import {debounce} from '@mui/material';
import {TripSetupAutocomplete} from './TripSetupAutocomplete';

interface TripSetupAutocompleteProps<T extends Listing | Listing[]> {
  type: 'loading' | 'offloading' | 'depot';
  name: string;
  label: string;
  value: T | null;
  onChange: (value: T | null) => void;
  multiple?: boolean;
  placeholder?: string;
}

export const TripSetupAutocompleteNode = <T extends Listing | Listing[]>({
  type,
  name,
  label,
  value,
  onChange,
  multiple,
  placeholder,
}: TripSetupAutocompleteProps<T>) => {
  const notify = useAppNotifications();
  const [listing, setListing] = React.useState<Listing[]>([]);
  const [localLoading, setLocalLoading] = React.useState<boolean>(false);

  const getListing = React.useCallback(
    async (filter?: string) => {
      setLocalLoading(true);
      try {
        if (type === 'depot') {
          const response = await nodeApi.apiNodeListingGet({
            type: 'DEPOT',
            query: filter,
          });
          setListing(
            response.items?.map(({id, name}) => ({label: name, value: id})) ??
              []
          );
        } else {
          const response = await nodeApi.apiNodeListingGet({
            type: type.toUpperCase(),
            query: filter,
          });
          const responseGeneric = await nodeApi.apiNodeListingGet({
            type: 'LOADING / OFFLOADING',
            query: filter,
          });
          setListing(
            response.items
              ?.concat(responseGeneric.items ?? [])
              ?.map(({id, name}) => ({label: name, value: id})) ?? []
          );
        }
      } catch (e) {
        notify('error', 'Failed to load Order listing');
      } finally {
        setLocalLoading(false);
      }
    },
    [type, notify]
  );

  const getListingDebounce = React.useRef(debounce(getListing, 500)).current;

  const handleInputChange = React.useCallback(
    async (_event, value) => {
      setLocalLoading(true);
      await getListingDebounce(value);
    },
    [getListingDebounce]
  );

  React.useEffect(() => {
    getListing();
    return () => setListing([]);
  }, [getListing]);

  return (
    <TripSetupAutocomplete
      options={listing}
      value={value}
      name={name}
      label={label}
      onChange={onChange}
      multiple={multiple}
      onInputChange={handleInputChange}
      loading={localLoading}
      placeholder={placeholder}
    />
  );
};
