import React from 'react';
import {TableSelection} from '@devexpress/dx-react-grid-material-ui';
import {useTableRowStyles} from './TableRow.style';

export const TableSelectionRow: React.FC<TableSelection.RowProps> = (props) => {
  const classes = useTableRowStyles();
  /**
   * TableSelection's typing for some reason doesn't have the Row option, although it should exist and doing this does
   * give the desired result. Which is why the `// @ts-ignore`s was needed
   * TODO: Investigate why the TableSelection has TableSelection.RowProps but not TableSelection.Row
   */
  return (
    // @ts-ignore
    <TableSelection.Row {...props} className={classes.root}>
      {props.children}
      {/*// @ts-ignore*/}
    </TableSelection.Row>
  );
};
