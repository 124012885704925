import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import {TableLinkCell} from '../components';

export const LinkTypeProvider: React.ComponentType<DataTypeProviderProps> = (
  props
) => <DataTypeProvider formatterComponent={TableLinkCell} {...props} />;
