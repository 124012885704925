import {makeStyles, createStyles} from '@mui/styles';

const VehicleBookingAvailabilityControllerStyles = () =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      minHeight: 10,
    },
    root: {},
  });

export const useVehicleBookingAvailabilityControllerStyles = makeStyles(
  VehicleBookingAvailabilityControllerStyles,
  {name: 'VehicleBookingAvailabilityControllerStyles'}
);
