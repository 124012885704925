import React from 'react';
import {CriticalEventTypeList} from './criticalEventType/CriticalEventTypeList';
import {CriticalEventTypeContextProvider} from './criticalEventType/CriticalEventTypeContext';
import {ITemplateTabControllerTab, TemplateTabs} from '../../factory/template';
import {OperationalEventTypeList} from './operationalEventType/OperationalEventTypeList';
import {OperationalEventTypeContextProvider} from './operationalEventType/OperationalEventTypeContext';

export const EventTypes = () => {
  const tabs = React.useMemo<ITemplateTabControllerTab[]>(
    () => [
      {
        title: 'Critical Event Types',
        permission: {name: 'CriticalEventType List', type: 'View'},
        element: <CriticalEventTypeList />,
      },
      {
        title: 'Operational Event Types',
        permission: {name: 'OperationalEventType List', type: 'View'},
        element: <OperationalEventTypeList />,
      },
    ],
    []
  );
  return (
    <CriticalEventTypeContextProvider>
      <OperationalEventTypeContextProvider>
        <TemplateTabs tabs={tabs} path="/eventtypes" />;
      </OperationalEventTypeContextProvider>
    </CriticalEventTypeContextProvider>
  );
};
