import React from 'react';
import {Loader} from '../loader';
import {useAuthenticationVideoStyles} from './AuthenticationVideo.style';

export const AuthenticationVideo: React.FC = () => {
  const classes = useAuthenticationVideoStyles();

  const [loading, setLoading] = React.useState<boolean>(true);

  return (
    <div className={classes.container}>
      <iframe
        className={classes.iframe}
        width="100%"
        height={300}
        src={`https://www.youtube.com/embed/CPgW3TlMF3I`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
        onLoad={() => {
          setLoading(false);
        }}
      />
      {loading && (
        <div className={classes.loader}>
          <Loader />
        </div>
      )}
    </div>
  );
};
