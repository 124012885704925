import {makeStyles, createStyles} from '@mui/styles';
import {Theme} from '@mui/material/styles';

const TableActionBaseStyles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: theme.spacing(0.5),
      flex: 1,
      marginTop: theme.spacing(-1),
      marginBottom: theme.spacing(-1),
      padding: theme.spacing(0.5),
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      backgroundColor: 'transparent',
    },
  });

export const useTableActionBaseStyles = makeStyles(TableActionBaseStyles, {
  name: 'TableActionBaseStyles',
});
