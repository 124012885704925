import React from 'react';
import {useHistory} from 'react-router-dom';
import {
  ApiNotificationConfigGetRequest,
  NotificationConfig,
  NotificationConfigList,
} from '@onroadvantage/onroadvantage-api';
import {FormikHelpers, FormikProps} from 'formik/dist/types';
import {useAppNotifications} from '../../contexts';
import {notificationConfigApi} from '../../api';
import {
  TemplateTableContextProps,
  TLoadList,
  useTemplateTable,
} from '../../factory/template';
import {INotificationConfigForm} from './NotificationConfigForm';

export interface NotificationConfigContextProps
  extends TemplateTableContextProps<
    NotificationConfig,
    NotificationConfigList
  > {
  loadNotificationConfig: () => Promise<void>;
  onDetailsFormSubmit: (
    values: INotificationConfigForm,
    formikHelpers: FormikHelpers<INotificationConfigForm>
  ) => void;
  setNotificationConfigId: (value: number | undefined) => void;
  submitting: boolean;
  loadingSingleItem: boolean;
  notificationConfig?: NotificationConfig;
  notificationConfigId?: number;
  detailsRef?: React.Ref<FormikProps<INotificationConfigForm>>;
}

export const NotificationConfigContext =
  React.createContext<NotificationConfigContextProps>({
    // Template Table Defaults
    loading: false,
    list: [],
    currentPage: 1,
    // NotificationConfig
    loadList: async () => {},
    loadNotificationConfig: async () => {},
    onDetailsFormSubmit: () => null,
    setNotificationConfigId: () => null,
    submitting: false,
    loadingSingleItem: false,
  });

interface NotificationConfigContextProviderProps {
  notificationConfigId?: number;
}

export const NotificationConfigContextProvider: React.FC<
  NotificationConfigContextProviderProps
> = ({children}) => {
  const history = useHistory();
  const notify = useAppNotifications();
  // Template Table
  const [
    {
      // States
      currentPage,
      filters,
      itemTotal,
      hasPermission,
      list,
      loading,
      pageSize,
      pageTotal,
      loadingSingleItem,
      sorting,
    },
    {
      // Getters
      getDownloads,
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
      setLoadingSingleItem,
    },
  ] = useTemplateTable<NotificationConfig, ApiNotificationConfigGetRequest>({
    editPermission: 'Edit NotificationConfig',
    addPermission: 'Add NotificationConfig',
    deletePermission: 'Delete NotificationConfig',
    downloadPermission: 'NotificationConfig ListDownload',
    viewPermission: 'NotificationConfig List',
  });

  const loadList = React.useCallback<TLoadList<NotificationConfigList>>(
    async (options) => {
      setLoading(true);
      try {
        const requestObj = getRequestObj(['name', 'description'], options);
        const response = await notificationConfigApi.apiNotificationConfigGet(
          requestObj
        );
        return getResponse(response, options);
      } catch (e) {
        notify('error', e.message ?? 'Failed to load notification config list');
      } finally {
        setLoading(false);
      }
    },
    [getRequestObj, getResponse, notify, setLoading]
  );

  const handleDelete = React.useCallback(
    async (row: NotificationConfig) => {
      setLoading(true);
      try {
        if (row.id) {
          await notificationConfigApi.apiNotificationConfigNotificationConfigIdDelete(
            {
              notificationConfigId: row.id,
            }
          );
          await loadList();

          notify('success', 'Deleted notification config');
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to delete');
      } finally {
        setLoading(false);
      }
    },
    [loadList, notify, setLoading]
  );

  const handleDownload = React.useCallback(
    () => getDownloads('notificationConfig', loadList, []),
    [getDownloads, loadList]
  );

  const handleAdd = React.useCallback(
    () => history.push('/notificationconfiglist/add'),
    [history]
  );

  const handleNavigate = React.useCallback(
    (row) => history.push(`/notificationconfiglist/${row.id}`),
    [history]
  );
  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  // Forms
  const [notificationConfig, setNotificationConfig] = React.useState<
    NotificationConfig | undefined
  >();
  const [notificationConfigId, setNotificationConfigId] = React.useState<
    number | undefined
  >();
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const detailsRef = React.useRef<FormikProps<INotificationConfigForm>>(null);

  const loadNotificationConfig = React.useCallback(async () => {
    setLoadingSingleItem(true);
    try {
      if (notificationConfigId) {
        const response =
          await notificationConfigApi.apiNotificationConfigNotificationConfigIdGet(
            {
              notificationConfigId,
            }
          );
        setNotificationConfig(response);
      } else {
        setNotificationConfig(undefined);
      }
    } catch (e) {
      notify('error', e.message ?? 'Failed to load notificationConfig');
    } finally {
      setLoadingSingleItem(false);
    }
  }, [setLoadingSingleItem, notificationConfigId, notify]);

  const handleDetailsFormSubmit = React.useCallback(
    async (
      values: INotificationConfigForm,
      formikHelpers: FormikHelpers<INotificationConfigForm>
    ) => {
      setSubmitting(true);
      try {
        if (notificationConfigId) {
          await notificationConfigApi.apiNotificationConfigNotificationConfigIdPatch(
            {
              notificationConfigId,
              body: values,
            }
          );
        } else {
          await notificationConfigApi.apiNotificationConfigPost({
            body: values,
          });
        }
        history.push('/notificationconfiglist');
        notify(
          'success',
          `${notificationConfigId ? 'Updated' : 'Added'} notificationConfig`
        );
      } catch (e) {
        notify(
          'error',
          e.message ??
            `Failed to ${
              notificationConfigId ? 'update' : 'add'
            } notificationConfig`
        );
      } finally {
        formikHelpers.setSubmitting(false);
        setSubmitting(false);
      }
    },
    [history, notify, setSubmitting, notificationConfigId]
  );

  const value: NotificationConfigContextProps = {
    // Template Table
    list,
    loadList,
    hasPermission,
    loading,
    loadingSingleItem,
    cleanupList,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onAdd: hasPermission.add ? handleAdd : undefined,
    onDownload: hasPermission.download ? handleDownload : undefined,
    onNavigate: handleNavigate,
    onDelete: hasPermission.delete ? handleDelete : undefined,
    onRefresh: handleRefresh,
    // Forms
    loadNotificationConfig,
    onDetailsFormSubmit: handleDetailsFormSubmit,
    setNotificationConfigId,
    submitting,
    notificationConfig,
    notificationConfigId,
    detailsRef,
  };

  React.useEffect(() => {
    loadNotificationConfig();
    return () => setNotificationConfig(undefined);
  }, [loadNotificationConfig]);

  return (
    <NotificationConfigContext.Provider value={value}>
      {children}
    </NotificationConfigContext.Provider>
  );
};
