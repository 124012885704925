import React from 'react';
import {Button, ButtonProps} from '@mui/material';
import {useAppNotifications} from '../../contexts';
import {Loader} from '../loader';
import {reportApi} from '../../api';
import {Report} from '@onroadvantage/onroadvantage-api';
import {IVantageDialogRef, VantageDialog} from '../dialog';
import {ReportGenerateForm} from '../report/reportGenerate';

export interface VantageReportButtonProps extends ButtonProps {
  name: string;
  disableFormFields?: boolean;
  disableCustomDateRange?: boolean;
  initialValues?: {[key: string]: any};
  params?: {[key: string]: any};
}

export const VantageReportButton: React.FC<VantageReportButtonProps> = ({
  name,
  disableFormFields,
  disableCustomDateRange,
  initialValues,
  params,
  ...buttonProps
}) => {
  const notify = useAppNotifications();
  const dialogRef = React.useRef<IVantageDialogRef>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [reportResponse, setReportResponse] = React.useState<
    Report | undefined
  >();

  const handleGetReport = React.useCallback(async () => {
    setLoading(true);
    try {
      const response = await reportApi.apiReportEmbeddedGet({
        reportName: name,
      });
      setReportResponse(response);
      dialogRef.current?.openDialog();
    } catch (e) {
      notify('error', e.message ?? 'Failed to find report');
    } finally {
      setLoading(false);
    }
  }, [name, notify]);

  const handleCloseDialog = React.useCallback(() => {
    dialogRef.current?.closeDialog();
  }, []);

  React.useEffect(() => {
    return () => {
      setLoading(false);
      setReportResponse(undefined);
    };
  }, []);

  return (
    <>
      <Button
        variant="text"
        disabled={loading}
        onClick={handleGetReport}
        startIcon={loading && <Loader size={22} />}
        {...buttonProps}
      >
        {name.replace(/([a-z])([A-Z])/g, '$1 $2')}
      </Button>
      <VantageDialog
        title={`Generate Report`}
        ref={dialogRef}
        maxWidth="md"
        disableAccept
        disableDecline
        fullWidth
      >
        <ReportGenerateForm
          report={reportResponse}
          disableFormFields={disableFormFields}
          disableCustomDateRange={disableCustomDateRange}
          initialValues={initialValues}
          params={params}
          Action={<Button onClick={handleCloseDialog}>Cancel</Button>}
        />
      </VantageDialog>
    </>
  );
};
