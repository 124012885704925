import React from 'react';
import {ContractOperationalEventTypeConfigList as ContractOperationalEventTypeConfigListType} from '@onroadvantage/onroadvantage-api';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../../../factory/template';
import {
  ContractOperationalEventTypeConfigListContext,
  useContractOperationalEventTypeConfigListContext,
} from './ContractOperationalEventTypeConfigListContext';

export const ContractOperationalEventTypeConfigList: React.FC = () => {
  const {loadList, cleanupList} =
    useContractOperationalEventTypeConfigListContext();

  const config: TemplateTableConfig<ContractOperationalEventTypeConfigListType> =
    React.useMemo(
      () => ({
        columns: [
          {
            name: 'operationalEventType.name',
            label: 'Operational Event Type',
            type: 'string',
            getValue: ({operationalEventType}) => operationalEventType?.name,
          },
          {
            name: 'contract.code',
            label: 'Contract Code',
            type: 'string',
            getValue: ({contract}) => contract?.code,
          },
          {
            name: 'eventPreThreshold',
            label: 'Event Pre Threshold',
            type: 'number',
            hidden: true,
          },
          {
            name: 'eventPostThreshold',
            label: 'Event Post Threshold',
            type: 'number',
            hidden: true,
          },
          {
            name: 'arrivalDelayThreshold',
            label: 'Arrival Delay Threshold',
            type: 'number',
            hidden: true,
          },
          {
            name: 'varianceAllowed',
            label: 'Variance Allowed',
            type: 'number',
            hidden: true,
          },
          {name: 'enabled', label: 'Enabled', type: 'bool'},
          {name: 'autoClose', label: 'Auto Close', type: 'bool'},
          {name: 'allowEventMuting', label: 'Allow Event Muting', type: 'bool'},
          {
            name: 'allowEventRepetition',
            label: 'Allow Event Repetition',
            type: 'bool',
          },
        ],
        enableFilter: true,
        enableSort: true,
        deleteMessage: (row) => ({
          title: 'Contract Operational Event Type Config',
          items: {
            OperationalEventTypeName: row.operationalEventType?.name,
            contractCode: row.contract?.code,
          },
        }),
        identifier: 'CONTRACT_OPERATIONAL_EVENT_TYPE_CONFIG_LIST',
      }),
      []
    );

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [cleanupList, loadList]);

  return (
    <TemplateTableWrapper
      config={config}
      context={ContractOperationalEventTypeConfigListContext}
    />
  );
};
