import React from 'react';
import {Tabs, Tab} from '@mui/material';
import {usePlanningBoardToolbarStyles} from './PlanningBoardToolbar.style';
import {usePlanningBoardContext} from '../planningBoardContext';

export const PlanningBoardToolbarTabs: React.FC = () => {
  const classes = usePlanningBoardToolbarStyles();

  const {activeToolbarTab, onUpdateActiveToolbarTab} =
    usePlanningBoardContext();

  const handleChange = React.useCallback(
    (_event: any, value: number) => {
      onUpdateActiveToolbarTab(value);
    },
    [onUpdateActiveToolbarTab]
  );

  return (
    <Tabs
      className={classes.tabs}
      value={activeToolbarTab}
      onChange={handleChange}
      indicatorColor="primary"
      // textColor="primary"
      variant="scrollable"
      scrollButtons={false}
    >
      <Tab sx={{px: 1.5, minWidth: 'auto'}} label="Plan" />
      <Tab sx={{px: 1.5, minWidth: 'auto'}} label="Daily Vehicle" />
    </Tabs>
  );
};
