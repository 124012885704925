import React from 'react';
import {SortableElement} from 'react-sortable-hoc';
import {Table} from '@devexpress/dx-react-grid-material-ui';
import {useTripEditStopListStyles} from './TripEditStopList.style';
import {ITripEditTripStop, useTripEditContext} from '../tripEditContext';

export const TripEditStopListRow: React.FC<Table.DataRowProps> = (props) => {
  const {row} = props;
  const {allowDepotEdits, editStopSequence, stops, getInitialStop} =
    useTripEditContext();
  const stop = row as ITripEditTripStop | undefined;
  const classes = useTripEditStopListStyles();

  const key = stop?.sequence ? stop.sequence : 0;

  /** prevent editing if no key or stop was found */
  if (!stop || !key) {
    return (
      <Table.Row {...props} className={classes.row}>
        {props.children}
      </Table.Row>
    );
  }

  /** prevent first and last stops from being edited */
  if (stops && [1, stops.length].includes(key) && !allowDepotEdits) {
    return (
      <Table.Row {...props} className={classes.row}>
        {props.children}
      </Table.Row>
    );
  }

  /** prevent drag when editSequence is not active **/
  if (!editStopSequence) {
    /** add different styling to newly added rows **/
    if (!getInitialStop(stop.id)) {
      return (
        <Table.Row {...props} className={classes.newRow}>
          {props.children}
        </Table.Row>
      );
    }

    return (
      <Table.Row {...props} className={classes.row}>
        {props.children}
      </Table.Row>
    );
  }

  return (
    <TripEditStopSortableRowComponent
      key={`stop-${key}`}
      index={key ?? 0}
      {...props}
    >
      {props.children}
    </TripEditStopSortableRowComponent>
  );
};

const TripEditStopSortableRowComponent = SortableElement(
  (props: Table.DataRowProps) => {
    const classes = useTripEditStopListStyles();

    return (
      <Table.Row {...props} className={classes.sortableRow}>
        {props.children}
      </Table.Row>
    );
  }
);
