import ConfigService from './ConfigService/ConfigService';
import {authStore} from '../store';

const serverUrl = ConfigService.serverUri;

const WorkflowService = {
  getDocumentLink: async (workflowId, documentType) => {
    const {auth} = authStore;
    const url = `${serverUrl}/api/document_template/download?documentType=${documentType}&workflowId=${workflowId}&documentFormat=pdf`;
    return `${url}&auth_token=${auth.token}`;
  },
};

export default WorkflowService;
