import React from 'react';
import {TemplateCard, TemplateForm} from '../../../factory/template';
import {SiteSubNodeContext} from './SiteSubNodeContext';
import {Listing, SubNode} from '@onroadvantage/onroadvantage-api';
import * as Yup from 'yup';
import {FormikAutocompleteWithListing, FormikTextField} from '../../formik';
import {listingSchema} from '../../../factory/template';

export interface ISiteSubNodeForm {
  name: string;
  description: string;
  externalReference?: string | undefined;
  taskTemplate: Listing;
}

const schema: Yup.SchemaOf<ISiteSubNodeForm> = Yup.object({
  name: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  externalReference: Yup.string(),
  taskTemplate: listingSchema.required('Required'),
});

const getInitialValues = (
  subNode?: SubNode | null
): ISiteSubNodeForm | undefined => {
  if (subNode) {
    return {
      name: subNode.name,
      description: subNode.description,
      externalReference: subNode.externalReference,
      taskTemplate: {
        value: subNode.taskTemplateId,
        label: subNode.taskTemplate.name,
      },
    };
  }
  return undefined;
};

export const SiteSubNodeForm: React.FC<{isAdd?: boolean}> = ({isAdd}) => {
  const {loading, submitting, subNode, onDetailsFormSubmit} =
    React.useContext(SiteSubNodeContext);
  const [initialValues, setInitialValues] = React.useState<ISiteSubNodeForm>();

  React.useEffect(() => {
    const values = getInitialValues(subNode);

    if (values) {
      setInitialValues(values);
    } else if (isAdd) {
      setInitialValues({
        name: '',
        description: '',
        taskTemplate: {},
      });
    }
  }, [isAdd, subNode, setInitialValues]);

  return (
    <TemplateCard
      title={`Sub Node Details ${isAdd ? 'Add' : 'Edit'}`}
      loading={loading}
      CardProps={{elevation: 0}}
    >
      <TemplateForm<ISiteSubNodeForm>
        initialValues={initialValues}
        onSubmit={onDetailsFormSubmit}
        permission={{name: 'Edit Site', type: 'Edit'}}
        submitting={submitting}
        validationSchema={schema}
      >
        <FormikTextField
          name="name"
          placeholder="Enter the sub node name"
          label="Name"
          fullWidth
        />
        <FormikTextField
          name="description"
          placeholder="Enter the sub node description"
          label="Description"
          fullWidth
        />
        <FormikTextField
          name="externalReference"
          placeholder="Enter the sub node external reference"
          label="External Reference"
          fullWidth
        />
        <FormikAutocompleteWithListing
          name="taskTemplate"
          placeholder="Enter and select a task template"
          label="Task Template"
          model="TaskTemplate"
        />
      </TemplateForm>
    </TemplateCard>
  );
};
