import React from 'react';
import {useAdminTelematicsConfigControllerStyles} from './AdminTelematicsConfigController.style';
import {
  BreadcrumbController,
  BreadcrumbRoute,
} from '../../breadcrumbs/BreadcrumbController';
import {Paper} from '@mui/material';
import {Route, Switch} from 'react-router-dom';
import {AdminTelematicsConfigList} from './AdminTelematicsConfigList';
import {
  AdminTelematicsConfigContext,
  AdminTelematicsConfigContextProvider,
} from './AdminTelematicsConfigContext';
import {AdminTelematicsConfigIdHandler} from './AdminTelematicsConfigIdHandler';
import _ from 'lodash';
import {AdminTelematicsConfig} from './AdminTelematicsConfig';
import {Params} from '../../breadcrumbs/BreadcrumbItem';

export const AdminTelematicsConfigControllerComponent: React.FC = () => {
  const classes = useAdminTelematicsConfigControllerStyles();
  const {telematicsConfig} = React.useContext(AdminTelematicsConfigContext);

  const [routes, setRoutes] = React.useState<Array<BreadcrumbRoute>>([]);

  const getSingleTitle = React.useCallback(
    (params: Params): string => {
      if (telematicsConfig) {
        let title = '';
        if (telematicsConfig.id) {
          title += `${telematicsConfig.id}`;
        }
        if (telematicsConfig.providerName) {
          title += ` - ${telematicsConfig?.providerName}`;
        }
        if (
          telematicsConfig?.providerName === 'MiX Integrate' &&
          telematicsConfig.mixOrganisationGroupName
        ) {
          title += ` - ${telematicsConfig.mixOrganisationGroupName}`;
        }
        return title;
      }

      return `Telematics Config ${_.get(
        params,
        'telematicsConfigId',
        'Single'
      )}`;
    },
    [telematicsConfig]
  );

  React.useEffect(() => {
    setRoutes([
      {
        path: '/telematicsconfiglist',
        matchOptions: undefined,
        title: 'Telematics Config List',
      },
      {
        path: '/telematicsconfiglist/add',
        matchOptions: undefined,
        title: 'New Telematics Config',
      },
      {
        path: '/telematicsconfiglist/:telematicsConfigId',
        matchOptions: undefined,
        title: (params) => getSingleTitle(params),
      },
    ]);
  }, [getSingleTitle]);

  return (
    <div className={classes.root}>
      <BreadcrumbController routes={routes} />
      <Paper className={classes.container}>
        <Route
          exact
          path="/telematicsconfiglist"
          component={AdminTelematicsConfigList}
        />
        <Route
          exact
          path="/telematicsconfiglist/:telematicsConfigId"
          component={AdminTelematicsConfigIdHandler}
        />
        <Switch>
          <Route
            exact
            path="/telematicsconfiglist/add"
            component={() => <div>Add</div>}
          />
          <Route
            exact
            path="/telematicsconfiglist/:telematicsConfigId"
            component={AdminTelematicsConfig}
          />
        </Switch>
      </Paper>
    </div>
  );
};

export const AdminTelematicsConfigController: React.FC = () => {
  return (
    <AdminTelematicsConfigContextProvider>
      <AdminTelematicsConfigControllerComponent />
    </AdminTelematicsConfigContextProvider>
  );
};
