import React from 'react';
import {
  Listing,
  VehicleCompartment,
  WebPlanningBoardMasterTrip,
  WebPlanningBoardTripStop,
  WebPlanningBoardTripStopOrder,
} from '@onroadvantage/onroadvantage-api';
import {
  CompartmentsWithCapacities,
  OrderLineWithCompartment,
  OrderLineWithVariant,
  VehicleCompartmentWithOrderLine,
  VehicleCompartmentWithOrderLines,
  WebPlanningBoardTripStopOrderWithVariant,
  WebPlanningBoardTripStopWithOrderVariant,
} from '../planningBoardTripCompartmentsTruck';
import {orderLineCompartmentApi} from '../../../../../../api';
import {useAppNotifications} from '../../../../../../contexts';
import {
  usePlanningBoardGanttResponse,
  usePlanningBoardResponse,
  WebPlanningBoardMasterTripWithIdType,
} from '../../../../planningBoardContext';
import {setMasterTripId} from '../../../../helpers';
import {usePlanningBoardTripResponse} from '../../planningBoardTripContext';

type TDragStartHandler = (
  compartmentLine?: OrderLineWithCompartment
) => (event: React.DragEvent<HTMLDivElement>) => void;
type TDragOverHandler = (
  compartment: VehicleCompartmentWithOrderLines
) => (event: React.DragEvent<HTMLDivElement>) => void;

interface usePlanningBoardTripCompartmentsParams {
  hasPermission: usePlanningBoardResponse['hasPermission'];
  getVehicle: usePlanningBoardResponse['getVehicle'];
  getMasterTrip: usePlanningBoardResponse['getMasterTrip'];
  loadMasterTrips: usePlanningBoardResponse['loadMasterTrips'];
  onClearSelectedGanttItem: usePlanningBoardGanttResponse['onClearSelectedGanttItem'];
  onClearTripState: usePlanningBoardTripResponse['onClearTripState'];
  selectedGanttItem: usePlanningBoardGanttResponse['selectedGanttItem'];
  setSelectedGanttItem: usePlanningBoardGanttResponse['setSelectedGanttItem'];
  selectedVehicleCapacityDimensionOption: usePlanningBoardGanttResponse['selectedVehicleCapacityDimensionOption'];
}
export const usePlanningBoardTripCompartments = ({
  hasPermission,
  getVehicle,
  getMasterTrip,
  loadMasterTrips,
  onClearSelectedGanttItem,
  onClearTripState,
  selectedGanttItem,
  setSelectedGanttItem,
  selectedVehicleCapacityDimensionOption,
}: usePlanningBoardTripCompartmentsParams) => {
  const notify = useAppNotifications();

  const [isChanged, setIsChanged] = React.useState<boolean>(false);
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [draggingItem, setDraggingItem] = React.useState<
    OrderLineWithCompartment | undefined
  >();
  const [overCompartment, setOverCompartment] = React.useState<
    VehicleCompartmentWithOrderLines | undefined
  >();
  const [compartments, setCompartments] = React.useState<
    VehicleCompartmentWithOrderLines[]
  >([]);
  const [overItem, setOverItem] = React.useState<
    OrderLineWithCompartment | undefined
  >();
  const [changedOrderLineCompartments, setChangedOrderLineCompartments] =
    React.useState<VehicleCompartmentWithOrderLine[]>([]);
  const [selectedStop, setSelectedStop] = React.useState<
    WebPlanningBoardTripStop | undefined
  >();

  const vehicle = React.useMemo(
    () => getVehicle(selectedGanttItem?.group),
    [getVehicle, selectedGanttItem?.group]
  );

  const handleDragStart = React.useCallback<TDragStartHandler>(
    (item) => (_event) => {
      setDraggingItem(item);
    },
    []
  );

  const handleDragOver = React.useCallback<TDragOverHandler>(
    (compartment) => (event) => {
      event.stopPropagation();
      event.preventDefault();
      setOverCompartment(compartment);
    },
    []
  );

  const handleDragLeave = React.useCallback<TDragOverHandler>(
    (_compartment) => (_event) => {
      setOverCompartment(undefined);
      // setOverContainer((prev) => (prev === variant ? undefined : variant));
      // setOverItem((prev) => (prev === item ? undefined : item));
    },
    []
  );

  const handleDrop = React.useCallback<TDragOverHandler>(
    (compartment) => (_event) => {
      if (!hasPermission.edit) {
        notify('warning', "You don't have the correct permissions");
        return;
      }
      if (draggingItem && compartment.id !== draggingItem.compartment.id) {
        setCompartments(() => {
          const foundOldCompartment = compartments.find(
            (prevItem) => prevItem.id === draggingItem.compartment.id
          );
          const foundTargetCompartment = compartments.find(
            (prevItem) => prevItem.id === compartment?.id
          );
          if (foundTargetCompartment && foundOldCompartment) {
            const unchangedCompartments: VehicleCompartmentWithOrderLines[] =
              compartments
                .filter(({id}) => id !== foundTargetCompartment.id)
                .filter(({id}) => id !== foundOldCompartment.id);
            const changedTargetCompartment: VehicleCompartmentWithOrderLines = {
              ...foundTargetCompartment,
              lines: [
                ...(foundTargetCompartment.lines ?? []),
                {
                  compartment: compartment,
                  line: draggingItem.line,
                  lineCompartment: draggingItem.lineCompartment,
                  orderNumber: draggingItem.orderNumber,
                },
              ],
            };
            const changedOldCompartment: VehicleCompartmentWithOrderLines = {
              ...foundOldCompartment,
              lines: foundOldCompartment.lines?.filter(
                (oldLine) => oldLine !== draggingItem
              ),
            };
            setChangedOrderLineCompartments((prevState) => [
              ...prevState,
              {
                ...foundTargetCompartment,
                line: {
                  compartment: compartment,
                  line: draggingItem.line,
                  lineCompartment: draggingItem.lineCompartment,
                  orderNumber: draggingItem.orderNumber,
                },
              },
            ]);
            const updatedCompartments: VehicleCompartmentWithOrderLines[] = [
              ...unchangedCompartments,
              changedTargetCompartment,
              changedOldCompartment,
            ];
            setIsChanged(true);
            return updatedCompartments;
          }
          return compartments;
        });
      }
      setDraggingItem(undefined);
      setOverCompartment(undefined);
      setOverItem(undefined);
    },
    [compartments, draggingItem, hasPermission, notify]
  );

  const getStopWithOrderVariants = React.useCallback(
    (
      selectedStop: WebPlanningBoardTripStop | undefined
    ): WebPlanningBoardTripStopWithOrderVariant | undefined => {
      if (!selectedStop) return undefined;

      let stop: WebPlanningBoardTripStopWithOrderVariant | undefined;

      const stops = (
        selectedGanttItem?.data as
          | WebPlanningBoardMasterTripWithIdType
          | undefined
      )?.trip?.stops;

      const indexOfSelectedStop = stops?.indexOf(selectedStop);

      const stopsBeforeSelectedStop = stops?.filter(
        (stop, index) =>
          indexOfSelectedStop !== undefined && index < indexOfSelectedStop
      );

      const ordersBeforeSelectedStop: WebPlanningBoardTripStopOrder[] = [];

      stopsBeforeSelectedStop?.forEach((stop) => {
        if (stop.orders) {
          ordersBeforeSelectedStop.push(...stop.orders);
        }
      });

      const stopsAfterSelectedStop = stops?.filter(
        (stop, index) =>
          indexOfSelectedStop !== undefined && index > indexOfSelectedStop
      );

      const orderIdsBefore: number[] = [];

      const orderIdsAfter: number[] = [];

      const orderIdsBeforeAndAfter: number[] = [];

      const inProgressOrders: WebPlanningBoardTripStopOrderWithVariant[] = [];

      stopsBeforeSelectedStop?.forEach(({orders}) => {
        orders?.forEach(({id}) => {
          if (id && !orderIdsBefore.includes(id)) {
            orderIdsBefore.push(id);
          }
        });
      });

      stopsAfterSelectedStop?.forEach(({orders}) => {
        orders?.forEach(({id}) => {
          if (id && !orderIdsAfter.includes(id)) {
            orderIdsAfter.push(id);
          }
        });
      });

      orderIdsBefore.forEach((orderIdBefore) => {
        if (orderIdsAfter.includes(orderIdBefore)) {
          orderIdsBeforeAndAfter.push(orderIdBefore);
        }
      });

      orderIdsBeforeAndAfter.forEach((id) => {
        const order = ordersBeforeSelectedStop.find((order) => order.id === id);
        if (order && !inProgressOrders.map(({id}) => id).includes(order.id)) {
          inProgressOrders.push({...order, variant: 'in-progress'});
        }
      });

      if (inProgressOrders && inProgressOrders.length > 0) {
        const selectedStopOrders: WebPlanningBoardTripStopOrderWithVariant[] =
          selectedStop?.orders?.map((order) => ({
            ...order,
            variant:
              order.id && orderIdsBefore.includes(order.id)
                ? 'offloading'
                : 'loading',
          })) ?? [];
        stop = {
          ...selectedStop,
          orders: [...selectedStopOrders, ...inProgressOrders],
        };
      } else {
        const selectedStopOrders: WebPlanningBoardTripStopOrderWithVariant[] =
          selectedStop?.orders?.map((order) => ({
            ...order,
            variant:
              order.id && orderIdsBefore.includes(order.id)
                ? 'offloading'
                : 'loading',
          })) ?? [];
        stop = {...selectedStop, orders: selectedStopOrders};
      }

      return stop;
    },
    [selectedGanttItem?.data]
  );

  const handleSetCompartments = React.useCallback(
    (
      stop: WebPlanningBoardTripStop | undefined,
      capacityDimension?: Listing | undefined
    ) => {
      const capacityDimensions = vehicle?.type?.capacityDimensions;
      if (capacityDimensions && capacityDimensions.length > 0) {
        const compartmentCapacities =
          capacityDimensions.find(({id}) => id === capacityDimension?.value)
            ?.compartmentCapacities ??
          capacityDimensions[0].compartmentCapacities;
        const compartmentsWithCapacities:
          | CompartmentsWithCapacities[]
          | undefined = compartmentCapacities?.map(
          ({compartmentCapacity, vehicleCompartment}) => ({
            ...(vehicleCompartment as VehicleCompartment),
            capacity: compartmentCapacity,
          })
        );

        const compartmentsWithOrderLines: VehicleCompartmentWithOrderLines[] =
          [];
        compartmentsWithCapacities?.forEach((compartment) => {
          if (compartment.id) {
            const stopWithOrderVariants = getStopWithOrderVariants(stop);
            const orderLines: OrderLineWithVariant[] = [];
            const orders = stopWithOrderVariants?.orders;

            stopWithOrderVariants?.orders?.forEach(({lines, variant}) =>
              lines?.forEach(
                (line) =>
                  !orderLines.includes({...line, variant}) &&
                  orderLines.push({...line, variant})
              )
            );

            const compartmentLines: OrderLineWithCompartment[] = [];

            orderLines.forEach((line) => {
              const foundLineCompartment = line.compartments?.find(
                ({vehicleCompartmentId}) =>
                  vehicleCompartmentId === compartment.id
              );

              if (foundLineCompartment) {
                compartmentLines.push({
                  line,
                  lineCompartment: foundLineCompartment,
                  compartment,
                  orderNumber: orders?.find(({id}) => id === line.orderId)
                    ?.orderNumber,
                });
              }
            });
            compartmentsWithOrderLines.push({
              ...compartment,
              ...(compartmentLines.length > 0
                ? {lines: compartmentLines}
                : undefined),
            });
          }
        });
        setCompartments(compartmentsWithOrderLines);
      }
    },
    [getStopWithOrderVariants, vehicle?.type?.capacityDimensions]
  );

  const handleSaveChanges = React.useCallback(async () => {
    if (!hasPermission.edit) {
      notify('warning', "You don't have the correct permissions");
      return;
    }
    setSubmitting(true);
    try {
      for (const change of changedOrderLineCompartments) {
        const orderLineCompartmentId = change.line?.lineCompartment.id;
        const compartmentId = change.line?.compartment?.id;
        const capacity = change.line?.compartment?.capacity;
        if (orderLineCompartmentId && compartmentId && capacity) {
          await orderLineCompartmentApi.apiOrderLineCompartmentOrderLineCompartmentIdPatch(
            {
              orderLineCompartmentId,
              body: {compartmentId, capacity},
            }
          );
        }
      }
      const response = await loadMasterTrips();
      let updatedMasterTrip: WebPlanningBoardMasterTrip | undefined;
      const {masterTrip, type} = getMasterTrip(selectedGanttItem?.id);
      if (type === 'booking') {
        updatedMasterTrip = response?.data?.booking?.find(
          ({id}) => id === masterTrip?.id
        );
      } else if (type === 'master') {
        updatedMasterTrip = response?.data?.master?.find(
          ({id}) => id === masterTrip?.id
        );
      } else if (type === 'planning') {
        updatedMasterTrip = response?.data?.planning?.find(
          ({id}) => id === masterTrip?.id
        );
      }
      if (updatedMasterTrip?.id && type) {
        setSelectedGanttItem((prevSelectedGanttItem) =>
          prevSelectedGanttItem
            ? {
                ...prevSelectedGanttItem,
                data: updatedMasterTrip?.id
                  ? {
                      ...updatedMasterTrip,
                      id: setMasterTripId(type, updatedMasterTrip.id),
                    }
                  : prevSelectedGanttItem.data,
              }
            : undefined
        );
        if (updatedMasterTrip?.trip?.stops) {
          handleSetCompartments(
            updatedMasterTrip.trip.stops[0],
            selectedVehicleCapacityDimensionOption
          );
        }
      } else {
        onClearSelectedGanttItem();
        onClearTripState();
      }
    } finally {
      setIsChanged(false);
      setSubmitting(false);
    }
  }, [
    changedOrderLineCompartments,
    getMasterTrip,
    handleSetCompartments,
    hasPermission.edit,
    loadMasterTrips,
    notify,
    onClearSelectedGanttItem,
    onClearTripState,
    selectedGanttItem?.id,
    selectedVehicleCapacityDimensionOption,
    setSelectedGanttItem,
  ]);

  const handleResetChanges = React.useCallback(() => {
    handleSetCompartments(selectedStop);
    setIsChanged(false);
  }, [handleSetCompartments, selectedStop]);

  React.useEffect(() => {
    handleSetCompartments(selectedStop, selectedVehicleCapacityDimensionOption);

    return () => {
      setCompartments([]);
      setIsChanged(false);
      setDraggingItem(undefined);
    };
  }, [
    selectedVehicleCapacityDimensionOption,
    handleSetCompartments,
    selectedStop,
    selectedGanttItem?.data,
  ]);

  return {
    isChanged,
    submitting,
    compartments,
    draggingItem,
    overItem,
    overCompartment,
    onDragStart: handleDragStart,
    onDragOver: handleDragOver,
    onDragLeave: handleDragLeave,
    onDrop: handleDrop,
    onSaveChanges: handleSaveChanges,
    onResetChanges: handleResetChanges,
    selectedStop,
    setSelectedStop,
  };
};

export type usePlanningBoardTripCompartmentsResponse = ReturnType<
  typeof usePlanningBoardTripCompartments
>;

export const usePlanningBoardTripCompartmentsResponseInitial: usePlanningBoardTripCompartmentsResponse =
  {
    isChanged: false,
    submitting: false,
    compartments: [],
    draggingItem: undefined,
    overItem: undefined,
    overCompartment: undefined,
    onDragStart: () => () => null,
    onDragOver: () => () => null,
    onDragLeave: () => () => null,
    onDrop: () => () => null,
    onSaveChanges: async () => {},
    onResetChanges: () => {},
    selectedStop: undefined,
    setSelectedStop: () => {},
  };
