import React from 'react';
import {useHistory} from 'react-router-dom';
import {
  ApiTrainingCourseGetRequest,
  TrainingCourse,
  TrainingCourseList,
} from '@onroadvantage/onroadvantage-api';
import {FormikHelpers, FormikProps} from 'formik/dist/types';
import {trainingCourseApi} from '../../api';
import {TemplateTableContextProps} from '../../factory/template';
import {useTemplateTable} from '../../factory/template';
import {TLoadList} from '../../factory/template';
import {ITrainingCourseForm} from './TrainingCourseForm';
import {ITrainingCourseUserForm} from './TrainingCourseUserForm';
import {useAppNotifications} from '../../contexts';

export interface TrainingCourseContextProps
  extends TemplateTableContextProps<TrainingCourse, TrainingCourseList> {
  onDetailsFormSubmit: (
    values: ITrainingCourseForm,
    formikHelpers: FormikHelpers<ITrainingCourseForm>
  ) => void;
  onUserDetailsFormSubmit: (
    values: ITrainingCourseUserForm,
    formikHelpers: FormikHelpers<ITrainingCourseUserForm>
  ) => void;
  setTrainingCourseId: (value: number | undefined) => void;
  submitting: boolean;
  trainingCourse?: TrainingCourse;
  trainingCourseId?: number;
  detailsRef?: React.Ref<FormikProps<ITrainingCourseForm>>;
}

export const TrainingCourseContext =
  React.createContext<TrainingCourseContextProps>({
    // Template Table Defaults
    loading: false,
    list: [],
    currentPage: 1,
    // TrainingCourse
    loadList: async () => {},
    onDetailsFormSubmit: () => null,
    onUserDetailsFormSubmit: () => null,
    setTrainingCourseId: () => null,
    submitting: false,
  });

interface TrainingCourseContextProviderProps {
  trainingCourseId?: number;
}

export const TrainingCourseContextProvider: React.FC<
  TrainingCourseContextProviderProps
> = ({children}) => {
  const history = useHistory();
  const notify = useAppNotifications();
  // Template Table
  const [
    {
      // States
      currentPage,
      pageSize,
      pageTotal,
      sorting,
      hasPermission,
      itemTotal,
      list,
      loading,
      loadingSingleItem,
      filters,
    },
    {
      // Getters
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
      setLoadingSingleItem,
    },
  ] = useTemplateTable<TrainingCourse, ApiTrainingCourseGetRequest>({
    editPermission: 'Edit TrainingCourse',
    addPermission: 'Add TrainingCourse',
    deletePermission: 'Delete TrainingCourse',
    downloadPermission: 'TrainingCourse ListDownload',
    viewPermission: 'TrainingCourse List',
  });

  const loadList = React.useCallback<TLoadList<TrainingCourseList>>(
    async (options) => {
      setLoading(true);
      try {
        const requestObj = getRequestObj(['name', 'description'], options);
        const response = await trainingCourseApi.apiTrainingCourseGet(
          requestObj
        );
        getResponse(response, options);
      } catch (e) {
        notify('error', e.message ?? 'Failed to load Training Course List');
      } finally {
        setLoading(false);
      }
    },
    [getRequestObj, getResponse, notify, setLoading]
  );

  const handleDelete = React.useCallback(
    async (row: TrainingCourse) => {
      setLoading(true);
      try {
        if (row.id) {
          await trainingCourseApi.apiTrainingCourseTrainingCourseIdDelete({
            trainingCourseId: row.id,
          });
          await loadList();
          notify('success', 'Deleted Training Course');
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to delete training course');
      } finally {
        setLoading(false);
      }
    },
    [loadList, notify, setLoading]
  );
  const handleAdd = React.useCallback(
    () => history.push('/trainingcourselist/add'),
    [history]
  );
  const handleNavigate = React.useCallback(
    (row) => history.push(`/trainingcourselist/${row.id}`),
    [history]
  );
  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  // Forms
  const [trainingCourse, setTrainingCourse] = React.useState<
    TrainingCourse | undefined
  >();
  const [trainingCourseId, setTrainingCourseId] = React.useState<
    number | undefined
  >();
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const detailsRef = React.useRef<FormikProps<ITrainingCourseForm>>(null);

  const loadTrainingCourse = React.useCallback(async () => {
    setLoadingSingleItem(true);
    try {
      if (trainingCourseId) {
        const response =
          await trainingCourseApi.apiTrainingCourseTrainingCourseIdGet({
            trainingCourseId,
          });
        setTrainingCourse(response);
      } else {
        setTrainingCourse(undefined);
      }
    } catch (e) {
      notify('error', e.message ?? 'Failed to load Training Course');
    } finally {
      setLoadingSingleItem(false);
    }
  }, [notify, setLoadingSingleItem, trainingCourseId]);

  const handleDetailsFormSubmit = React.useCallback(
    async (
      values: ITrainingCourseForm,
      formikHelpers: FormikHelpers<ITrainingCourseForm>
    ) => {
      setSubmitting(true);
      try {
        if (trainingCourse && trainingCourse.id) {
          await trainingCourseApi.apiTrainingCourseTrainingCourseIdPatch({
            trainingCourseId: trainingCourse.id,
            body: values,
          });
        } else {
          await trainingCourseApi.apiTrainingCoursePost({
            body: values,
          });
        }
        history.push('/trainingcourselist');
        notify(
          'success',
          `${trainingCourse?.id ? 'Updated' : 'Added'} Training Course`
        );
      } catch (e) {
        notify(
          'error',
          e.message ??
            `Failed to ${trainingCourse?.id ? 'update' : 'add'} training course`
        );
      } finally {
        formikHelpers.setSubmitting(false);
        setSubmitting(false);
      }
    },
    [history, notify, trainingCourse]
  );
  const handleUserDetailsFormSubmit = React.useCallback(
    async (
      values: ITrainingCourseUserForm,
      formikHelpers: FormikHelpers<ITrainingCourseUserForm>
    ) => {
      setSubmitting(true);
      try {
        if (trainingCourse && trainingCourse.id) {
          await trainingCourseApi.apiTrainingCourseTrainingCourseIdPatch({
            trainingCourseId: trainingCourse.id,
            body: values,
          });
        } else {
          await trainingCourseApi.apiTrainingCoursePost({
            body: values,
          });
        }
        history.push('/trainingcourselist');
        notify(
          'success',
          `${trainingCourse?.id ? 'Updated' : 'Added'} Training Course`
        );
      } catch (e) {
        notify(
          'error',
          e.message ??
            `Failed to ${trainingCourse?.id ? 'update' : 'add'} training Course`
        );
      } finally {
        formikHelpers.setSubmitting(false);
        setSubmitting(false);
      }
    },
    [history, notify, trainingCourse]
  );

  const value: TrainingCourseContextProps = {
    // Template Table
    list,
    loadList,
    hasPermission,
    loading: loading || loadingSingleItem,
    cleanupList,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onAdd: hasPermission.add ? handleAdd : undefined,
    onNavigate: handleNavigate,
    onDelete: hasPermission.delete ? handleDelete : undefined,
    onRefresh: handleRefresh,
    // Forms
    onDetailsFormSubmit: handleDetailsFormSubmit,
    onUserDetailsFormSubmit: handleUserDetailsFormSubmit,
    setTrainingCourseId,
    submitting,
    trainingCourse,
    trainingCourseId,
    detailsRef,
  };

  React.useEffect(() => {
    loadTrainingCourse();
    return () => setTrainingCourse(undefined);
  }, [loadTrainingCourse]);

  return (
    <TrainingCourseContext.Provider value={value}>
      {children}
    </TrainingCourseContext.Provider>
  );
};
