import {Theme} from '@mui/material/styles';
import {createStyles, makeStyles} from '@mui/styles';

export const PlanningBoardCommitBookingMasterTripsStyles = (theme: Theme) =>
  createStyles({
    tooltip: {fontSize: theme.typography.fontSize},
    orderNumberTypography: {cursor: 'default'},
  });

export const usePlanningBoardCommitBookingMasterTripsStyles = makeStyles(
  PlanningBoardCommitBookingMasterTripsStyles,
  {
    name: 'PlanningBoardCommitBookingMasterTripsStyles',
  }
);
