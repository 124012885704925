import {makeStyles, createStyles} from '@mui/styles';
import {Theme} from '@mui/material';

const TripSetupStopsStepStyles = (theme: Theme) =>
  createStyles({
    speedDialTooltip: {
      whiteSpace: 'nowrap',
    },
    stop: {
      borderRadius: 8,
      backgroundColor: theme.palette.grey['100'],
      display: 'flex',
      padding: theme.spacing(1),
      paddingRight: theme.spacing(2),
    },
  });

export const useTripSetupStopsStepStyles = makeStyles(
  TripSetupStopsStepStyles,
  {
    name: 'TripSetupStopsStepStyles',
  }
);
