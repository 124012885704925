interface splitByCapitalsOptions {
  capabilitiesFirstLetter?: boolean;
  capabilitiesAllLetters?: boolean;
  lowercaseAllLetters?: boolean;
  joinCharacter?: string;
}

export const splitByCapitals = (
  name: string,
  options?: splitByCapitalsOptions
) => {
  const splitName = name.split(/(?=[A-Z])/);
  if (options) {
    const {
      capabilitiesFirstLetter = false,
      capabilitiesAllLetters = false,
      lowercaseAllLetters = false,
      joinCharacter = ' ',
    } = options;
    let modifiedSplitName = splitName;

    if (lowercaseAllLetters) {
      modifiedSplitName = splitName.map((name) => name.toLowerCase());
    }

    if (capabilitiesFirstLetter) {
      modifiedSplitName = splitName.map(
        (name) => name.charAt(0).toUpperCase() + name.slice(1)
      );
    }

    if (capabilitiesAllLetters) {
      modifiedSplitName = splitName.map((name) => name.toUpperCase());
    }

    return modifiedSplitName.join(joinCharacter);
  }

  return splitName.join(' ');
};
