import React from 'react';
import throttle from 'lodash.throttle';

// todo: to be abstracted somewhere else...
export const mobileSize = 768;
export const tabletSize = 1024;
// handles throttling of the resize function to ensure performance
const throttleTime = 300;

// Exposed type for Window Info
export interface WindowInfoType {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  windowWidth: number;
  windowHeight: number;
}

// Set up initial values to coincide with window size on launch
export const defaultWindowInfo: WindowInfoType = {
  isMobile: window.innerWidth < mobileSize,
  isTablet: window.innerWidth < tabletSize && window.innerWidth >= mobileSize,
  isDesktop: window.innerWidth >= tabletSize,
  windowWidth: window.innerWidth,
  windowHeight: window.innerHeight,
};

const WindowInfoContext = React.createContext(defaultWindowInfo);
const {Provider: WindowInfoProvider, Consumer: WindowInfoConsumer} =
  WindowInfoContext;

class WindowInfoContextProvider extends React.Component {
  // Initial state is taken from default values defined earlier
  public state: WindowInfoType = defaultWindowInfo;

  public throttledHandleWindowResize = throttle(
    () => {
      const {isMobile, isTablet, isDesktop} = this.state;
      const currentMobile = window.innerWidth < mobileSize; // Checks if window is currently using mobile dimensions.
      const currentTablet = !currentMobile && window.innerWidth < tabletSize;
      const currentDesktop = window.innerWidth >= tabletSize;

      // Since we only have two 'states' to look out for, we can do speed optimizations
      const shouldUpdate =
        (!isMobile && currentMobile) ||
        (!isTablet && currentTablet) ||
        (!isDesktop && currentDesktop);

      if (shouldUpdate) {
        this.setState({
          isMobile: currentMobile,
          isTablet: currentTablet,
          isDesktop: currentDesktop,
          windowWidth: window.innerWidth,
          windowHeight: window.innerHeight,
        });
      }
    },
    throttleTime,
    {trailing: true}
  );

  componentDidMount(): void {
    window.addEventListener('resize', this.throttledHandleWindowResize);
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', this.throttledHandleWindowResize);
  }

  public render() {
    return (
      <WindowInfoProvider value={this.state}>
        {this.props.children}
      </WindowInfoProvider>
    );
  }
}

export {WindowInfoContextProvider, WindowInfoConsumer, WindowInfoContext};
