import ConfigService from '../../../service/ConfigService/ConfigService';

const gptPromptStream = async (
  prompt: string,
  code: string,
  promptCallback: (snippet: string) => void,
  onOpenCallback: (event: Event) => void,
  onCloseCallback: (event: CloseEvent) => void
) => {
  const uri = ConfigService.promptUrl;
  if (!uri) {
    return;
  }
  const websocket = new WebSocket(uri);

  const payload = `Using: ${code} \n\n ${prompt}`;

  // Prepare the payload
  const data = {
    type: 'GPT_REQUEST',
    payload: payload,
    key: ConfigService.promptKey,
  };

  websocket.onopen = (event) => {
    websocket.send(JSON.stringify(data));
    onOpenCallback(event);
  };

  websocket.onmessage = (event) => {
    promptCallback(JSON.parse(event.data).payload);
  };
  websocket.onclose = (event) => {
    onCloseCallback(event);
  };

  websocket.onerror = (errorEvent) => {
    // eslint-disable-next-line no-console
    console.error('WebSocket Error', errorEvent);
  };

  return websocket;
};

export default gptPromptStream;
