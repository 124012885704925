import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

const TableToolbarInfoStyles = (theme: Theme) =>
  createStyles({
    tooltip: {
      backgroundColor: '#fefefe',
      border: '1px solid #dadde9',
      color: '#111',
    },
    icon: {marginLeft: theme.spacing(1)},
  });

export const useTableToolbarInfoStyles = makeStyles(TableToolbarInfoStyles, {
  name: 'TableToolbarInfoStyles',
});
