import React from 'react';
import {FormControl, Select, MenuItem, SelectChangeEvent} from '@mui/material';
import {useInternalTableContext} from '../../TemplateTable';
import {TableEditRow} from '@devexpress/dx-react-grid-material-ui';

export const TableSelectField: React.FC<TableEditRow.CellProps> = (props) => {
  const {config, errors} = useInternalTableContext();
  const columnConfig = React.useMemo(
    () =>
      config.columns.find(
        (configColumn) => configColumn.name === props.column.name
      ),
    [config.columns, props.column.name]
  );

  const cellError = React.useMemo(
    () =>
      errors.find((error) => {
        const errorName = error.name?.toString()?.split('.');

        return errorName
          ? errorName[0] === props.column.name &&
              (error.id === props.row.id ||
                (!props.row && error.id === undefined))
          : false;
      }),
    [errors, props.column.name, props.row]
  );

  const onChangeHandler = React.useCallback(
    (event: SelectChangeEvent) => {
      props.onValueChange(event.target.value);
    },
    [props]
  );

  const enableColumn = React.useMemo(() => {
    if (props.row.id) {
      if (columnConfig?.enableEditing !== undefined) {
        if (typeof columnConfig.enableEditing === 'function') {
          return columnConfig.enableEditing(props.row);
        } else {
          return columnConfig.enableEditing;
        }
      }
    } else if (columnConfig?.enableInlineAdding !== undefined) {
      if (typeof columnConfig.enableInlineAdding === 'function') {
        return columnConfig.enableInlineAdding(props.row);
      } else {
        return columnConfig.enableInlineAdding;
      }
    }
    return true;
  }, [columnConfig, props.row]);

  return (
    <FormControl disabled={!enableColumn} error={!!cellError} fullWidth>
      <Select<string>
        size="small"
        value={props.value}
        onChange={onChangeHandler}
        fullWidth
        sx={{flex: 1}}
      >
        {columnConfig?.selectOptions?.map((option) => (
          <MenuItem value={option} key={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
