import React from 'react';
import {
  Card,
  CardProps,
  CardHeader,
  CardHeaderProps,
  CardContent,
  CardContentProps,
  Avatar,
  AvatarProps,
} from '@mui/material';
import {Edit} from '@mui/icons-material';
import {Loader} from '../../../components/loader';
import {useTemplateCardStyles} from './TemplateCard.style';
import clsx from 'clsx';

interface ICardClasses {
  card?: string;
  content?: string;
  header?: string;
}

export interface TemplateCardProps extends CardProps {
  title: string;
  //Props
  AvatarProps?: AvatarProps;
  CardProps?: CardProps;
  CardContentProps?: CardContentProps;
  CardHeaderProps?: CardHeaderProps;
  //other
  classes?: ICardClasses;
  avatar?: React.ReactNode;
  loading?: boolean;
  validationSchema?: any | (() => any);
  disableContentPadding?: boolean;
}

export const TemplateCard: React.FC<TemplateCardProps> = (props) => {
  const {
    //required
    title,
    children,
    //other
    AvatarProps,
    classes: classesProp,
    avatar,
    loading,
    CardProps,
    CardContentProps,
    CardHeaderProps,
    disableContentPadding,
    ...cardProps
  } = props;
  const classes = useTemplateCardStyles();

  return (
    <Card
      className={clsx({
        [classesProp?.card ?? '']: !!classesProp?.card,
        [classes.cardDisableBottomPadding]: disableContentPadding,
      })}
      {...cardProps}
      {...CardProps}
    >
      <CardHeader
        title={title}
        avatar={<Avatar {...AvatarProps}>{avatar ?? <Edit />}</Avatar>}
        className={classesProp?.header}
        {...CardHeaderProps}
      />
      <CardContent
        className={clsx({
          [classesProp?.card ?? '']: !!classesProp?.content,
          [classes.cardContentDisablePadding]: disableContentPadding,
        })}
        {...CardContentProps}
      >
        {loading ? <Loader /> : children}
      </CardContent>
    </Card>
  );
};
