import React from 'react';
import {CriticalEventTypeBulkCloseForm} from './CriticalEventTypeBulkCloseForm';
import {ITemplateTabControllerTab, TemplateTabs} from '../../factory/template';
import {CriticalEventTypeList} from './CriticalEventTypeList';

export const CriticalEventType: React.FC = () => {
  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {title: 'Bulk Close', element: <CriticalEventTypeBulkCloseForm />},
    {title: 'Critical Event Types', element: <CriticalEventTypeList />},
  ]);

  const [path] = React.useState('/criticaleventtypelist');

  return <TemplateTabs tabs={tabs} path={path} />;
};
