import React from 'react';
import clsx from 'clsx';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  List,
  Stack,
  Typography,
} from '@mui/material';
import {
  Home,
  KeyboardArrowDown,
  KeyboardArrowUp,
  LocalShipping,
  Person,
  VpnKey,
  Warning,
} from '@mui/icons-material';
import {
  CriticalEventActivity,
  OperationalEventActivity,
  CriticalEventDump,
  OperationalEventDump,
} from '@onroadvantage/onroadvantage-api';
import {EventCardAddActivity} from './EventCardAddActivity';
import {EventCardListItem} from './EventCardListItem';
import {EventCardActivity} from './EventCardActivity';
import {MomentService} from '../../../service';
import {authStore} from '../../../store';
import {criticalEventApi, operationalEventApi} from '../../../api';
import {Loader} from '../../loader';
import {EventContext} from '../EventContext';
import {useEventCardStyles} from './EventCard.style';
import {useAppNotifications} from '../../../contexts';
import {mapDisplayStore} from '../../../stores/mobxStores';

interface EventCardProps {
  event: CriticalEventDump | OperationalEventDump | undefined;
  eventType: string;
}

export const CLOSED_STATUSES = ['Closed', 'BulkClosed', 'MutedClosed'];

export const EventCard: React.FC<EventCardProps> = ({event, eventType}) => {
  const notify = useAppNotifications();
  const {getAuth} = authStore;
  const auth = getAuth;
  const {setEvent, downloadEventReport} = React.useContext(EventContext);
  const classes = useEventCardStyles();
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
  const [submittingState, setSubmittingState] = React.useState<
    'assignToMe' | 'closeEvent' | 'downloadingReport' | undefined
  >();

  const activities = React.useMemo(
    () =>
      event?.activities?.sort(
        (
          a: CriticalEventActivity | OperationalEventActivity,
          b: CriticalEventActivity | OperationalEventActivity
        ) =>
          !a.createdAt || !b.createdAt
            ? 0
            : a.createdAt < b.createdAt
            ? 1
            : a.createdAt > b.createdAt
            ? -1
            : 0
      ),
    [event?.activities]
  );

  const assignedEmail = React.useMemo(
    () =>
      (activities as CriticalEventActivity[] | undefined)?.reduceRight(
        (
          acc: string | undefined,
          activity: CriticalEventActivity | OperationalEventActivity | undefined
        ) => {
          if (
            activity?.action === 'AssignmentChange' &&
            activity.createdBy?.email != null
          ) {
            return activity.createdBy.email;
          }
          return acc;
        },
        undefined
      ),
    [activities]
  );

  const isAssignedToCurrentUser =
    assignedEmail != null &&
    auth?.email != null &&
    assignedEmail === auth.email;

  const title =
    eventType === 'operational'
      ? `${
          event?.type && event?.type?.name
            ? event?.type?.name
            : event?.description
        }`
      : `${
          event?.type && event?.type?.provider && event?.type?.provider?.name
            ? `${event?.type?.provider?.name}: `
            : ''
        }${
          event?.type && event?.type?.name
            ? event?.type?.name
            : event?.description
        }`;

  const handleToggleIsExpanded = React.useCallback(
    () => setIsExpanded((prevIsExpanded) => !prevIsExpanded),
    []
  );

  const handleEventActivity = React.useCallback(
    async (action: string, value: string) => {
      try {
        if (event?.id) {
          if (eventType === 'operational') {
            const updatedEventResponse: OperationalEventDump | undefined =
              await operationalEventApi.apiOperationalEventOperationalEventIdPost(
                {
                  body: {activities: [{action, value}]},
                  operationalEventId: event.id,
                }
              );
            if (updatedEventResponse != null) {
              setEvent(updatedEventResponse);
              await mapDisplayStore.setOpenEvent({
                ...updatedEventResponse,
                mapType: 'Operational',
              });
            }
          } else {
            const updatedEventResponse: CriticalEventDump | undefined =
              await criticalEventApi.apiCriticalEventCriticalEventIdPost({
                body: {activities: [{action, value}]},
                criticalEventId: event.id,
              });
            if (updatedEventResponse != null) {
              setEvent(updatedEventResponse);
              await mapDisplayStore.setOpenEvent({
                ...updatedEventResponse,
                mapType: 'Critical',
              });
            }
          }
        }
      } catch (e) {
        notify('error', 'Failed to update event');
      }
    },

    [eventType, setEvent, event, notify]
  );

  const handleCloseEvent = React.useCallback(
    async (
      e: React.MouseEvent<
        HTMLButtonElement | HTMLLIElement | HTMLAnchorElement,
        MouseEvent
      >
    ) => {
      e.stopPropagation();
      setSubmittingState('closeEvent');
      try {
        await handleEventActivity(
          'StatusChange',
          event?.status && CLOSED_STATUSES.includes(event.status)
            ? 'Open'
            : 'Closed'
        );
      } finally {
        setSubmittingState(undefined);
      }
    },
    [event?.status, handleEventActivity]
  );

  const handleAssignToMe = React.useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      setSubmittingState('assignToMe');
      try {
        await handleEventActivity('AssignmentChange', 'Assigned to Me');
      } finally {
        setSubmittingState(undefined);
        setIsExpanded(true);
      }
    },
    [handleEventActivity]
  );

  const closeEventStatus =
    event?.status && CLOSED_STATUSES.includes(event.status)
      ? 'Re-Open Event'
      : 'Close Event';

  const disableCloseEvent =
    (event?.status === 'Open' && !isAssignedToCurrentUser) ||
    event?.status === 'MutedClosed';

  return (
    <Card className={classes.root}>
      <CardHeader
        classes={{root: classes.header}}
        avatar={
          <Avatar
            aria-label="Event Avatar"
            className={clsx(classes.headerAvatar, {
              [classes.headerAvatarCritical]: eventType === 'critical',
            })}
          >
            <Warning />
          </Avatar>
        }
        action={
          <Typography color="textSecondary" className={classes.status}>
            {event?.status}
          </Typography>
        }
        title={title}
        subheader={
          <div>
            <div>
              {`${MomentService.displayFromNow(
                event?.eventDate
              )} @ ${MomentService.displayTimestamp(event?.eventDate)}`}
            </div>
            <div>
              {assignedEmail ? (
                <div>
                  Assigned to:
                  {assignedEmail}
                </div>
              ) : (
                'Unassigned'
              )}
            </div>
          </div>
        }
      />
      <CardContent className={classes.content}>
        <List classes={{root: classes.list}} dense disablePadding>
          <EventCardListItem
            Icon={<LocalShipping />}
            primary={event?.vehicle?.description ?? 'No Vehicle: '}
            secondary={event?.vehicle?.registrationNumber ?? 'No Vehicle'}
          />
          <EventCardListItem
            Icon={<Person />}
            primary={event?.driver?.firstname ?? 'No Driver'}
          />
          <EventCardListItem
            Icon={<Home />}
            primary={
              event?.vehicle
                ? event.vehicle.contract?.code ?? 'No Contract: '
                : 'No Vehicle: '
            }
            secondary={event?.trip?.tripNumber ?? 'No Current Trip'}
          />
          <EventCardListItem
            Icon={<VpnKey />}
            primary="ID"
            secondary={event?.id}
          />
        </List>
      </CardContent>
      <Stack direction={'row'} py={1} spacing={2}>
        <CardActions>
          <Button
            size="small"
            color="primary"
            variant="outlined"
            onClick={handleAssignToMe}
            disabled={submittingState != null || isAssignedToCurrentUser}
            startIcon={
              <Loader size={25} loading={submittingState === 'assignToMe'} />
            }
          >
            Assign to me
          </Button>
        </CardActions>
        <CardActions>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={downloadEventReport}
            disabled={submittingState != null || !isAssignedToCurrentUser}
            startIcon={
              <Loader
                size={25}
                loading={submittingState === 'downloadingReport'}
              />
            }
          >
            Download Incident Report
          </Button>
        </CardActions>
      </Stack>
      <CardActions onClick={handleToggleIsExpanded}>
        <Button disabled={submittingState != null} color="inherit" fullWidth>
          {isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </Button>
      </CardActions>
      <Collapse in={isExpanded} unmountOnExit mountOnEnter>
        <CardContent className={classes.content}>
          {event && <EventCardAddActivity event={event} />}
          {activities?.map(
            (activity: CriticalEventActivity | OperationalEventActivity) => (
              <EventCardActivity
                key={activity.id}
                activity={activity}
                filteredActions={[]} // used to filter activities e.g 'Trigger'
              />
            )
          )}
        </CardContent>
        <CardActions className={classes.actions}>
          <Button
            size="small"
            color="primary"
            variant="outlined"
            onClick={handleCloseEvent}
            disabled={disableCloseEvent || submittingState != null}
            startIcon={
              <Loader size={25} loading={submittingState === 'closeEvent'} />
            }
          >
            {closeEventStatus}
          </Button>
        </CardActions>
      </Collapse>
    </Card>
  );
};
