import React from 'react';
import {DateTime} from 'luxon';
import {
  ApiWebPlanningBoardOrderGetRequest,
  Listing,
  WebPlanningBoardBooking,
  WebPlanningBoardContract,
  WebPlanningBoardMasterTrip,
  WebPlanningBoardOrder,
  WebPlanningBoardTripStopOrder,
  WebPlanningBoardVehicle,
} from '@onroadvantage/onroadvantage-api';
import {useAppNotifications} from '../../../contexts';
import {RoleService} from '../../../service';
import {
  getListingIds,
  getMasterTripId,
  getMasterTripsWithSameVehicle,
  setMasterTripId,
} from '../helpers';
import {IdType} from 'vis';
import {planningBoardApi} from '../../../api';

export type LoadFunctionTypes = 'onmount' | 'onrefresh';

export type TResetFilterOptions = 'customer' | 'planningSkill' | 'vehicleGroup';

export type MasterTripTypes =
  | 'booking'
  | 'planning'
  | 'maintenance'
  | 'master'
  | 'optimal';

export interface WebPlanningBoardMasterTripWithIdType
  extends Omit<WebPlanningBoardMasterTrip, 'id'> {
  id?: number | string | undefined;
}

export interface WebPlanningBoardBookingWithMasterTrip
  extends WebPlanningBoardBooking {
  bookingMasterTrip: WebPlanningBoardMasterTripWithIdType | undefined;
}

export const usePlanningBoard = () => {
  const notify = useAppNotifications();
  // local states
  const [hasLoadedOrders, setHasLoadedOrders] = React.useState<boolean>(false);
  // public states
  const [activeToolbarTab, setActiveToolbarTab] = React.useState<number>(0);
  const [assignedOrders, setAssignedOrders] = React.useState<
    (WebPlanningBoardOrder | WebPlanningBoardTripStopOrder)[]
  >([]);
  const [bookingMasterTripOrders, setBookingMasterTripOrders] = React.useState<
    WebPlanningBoardTripStopOrder[]
  >([]);
  const [bookings, setBookings] = React.useState<
    WebPlanningBoardBookingWithMasterTrip[]
  >([]);
  const [bookingMasterTrips, setBookingMasterTrips] = React.useState<
    WebPlanningBoardMasterTripWithIdType[]
  >([]);
  const [bookingMasterTripsToCommit, setBookingMasterTripsToCommit] =
    React.useState<WebPlanningBoardMasterTripWithIdType[]>([]);
  const [contracts, setContracts] = React.useState<WebPlanningBoardContract[]>(
    []
  );
  const [endDate, setEndDate] = React.useState<DateTime>(
    DateTime.local().endOf('day')
  );
  const [initialised, setInitialised] = React.useState<boolean>(false);
  const [masterMasterTrips, setMasterMasterTrips] = React.useState<
    WebPlanningBoardMasterTripWithIdType[]
  >([]);
  const [maxOptionsPerVehicle, setMaxOptionsPerVehicle] =
    React.useState<number>(2);
  const [optimalMasterTrips, setOptimalMasterTrips] = React.useState<
    WebPlanningBoardMasterTripWithIdType[]
  >([]);
  const [optimalVehicleGroups, setOptimalVehicleGroups] = React.useState<
    number[] | undefined
  >(undefined);
  const [planningMasterTrips, setPlanningMasterTrips] = React.useState<
    WebPlanningBoardMasterTripWithIdType[]
  >([]);
  const [selectedContractListings, setSelectedContractListings] =
    React.useState<Listing[]>([]);
  const [selectedCustomerListings, setSelectedCustomerListings] =
    React.useState<Listing[]>([]);
  const [selectedPlanningSkillListings, setSelectedPlanningSkillListings] =
    React.useState<Listing[]>([]);
  const [selectedVehicleGroupListings, setSelectedVehicleGroupListings] =
    React.useState<Listing[]>([]);
  const [startDate, setStartDate] = React.useState<DateTime>(
    DateTime.local().startOf('day')
  );
  const [totalOptionCount, setTotalOptionCount] = React.useState<number>(15);
  const [unassignedOrders, setUnassignedOrders] = React.useState<
    (WebPlanningBoardOrder | WebPlanningBoardTripStopOrder)[]
  >([]);
  const [vehicles, setVehicles] = React.useState<WebPlanningBoardVehicle[]>([]);
  // loading
  const [loadingCommitBookingTrips, setLoadingCommitBookingTrips] =
    React.useState<boolean>(false);
  const [loadingContracts, setLoadingContracts] =
    React.useState<boolean>(false);
  const [loadingInitial, setLoadingInitial] = React.useState<boolean>(false);
  const [loadingMasterTrips, setLoadingMasterTrips] =
    React.useState<boolean>(false);
  const [loadingOptimalTrips, setLoadingOptimalTrips] =
    React.useState<boolean>(false);
  const [loadingOrders, setLoadingOrders] = React.useState<boolean>(false);
  const [loadingSubmitMasterTrip, setLoadingSubmitMasterTrip] =
    React.useState<boolean>(false);
  const [loadingVehicles, setLoadingVehicles] = React.useState<boolean>(false);
  // memos
  const contractIds = React.useMemo(
    () => getListingIds(selectedContractListings),
    [selectedContractListings]
  );
  const customerIds = React.useMemo(
    () => getListingIds(selectedCustomerListings),
    [selectedCustomerListings]
  );
  const vehicleGroupIds = React.useMemo(
    () => getListingIds(selectedVehicleGroupListings),
    [selectedVehicleGroupListings]
  );
  const hasPermission = React.useMemo(
    () => ({
      edit: RoleService.hasPermission('Edit PlanningBoard', 'Edit'),
      view: RoleService.hasPermission('PlanningBoard View', 'View'),
    }),
    []
  );
  const planningSkillIds = React.useMemo(
    () => getListingIds(selectedPlanningSkillListings),
    [selectedPlanningSkillListings]
  );

  // helpers
  const handleCleanup = React.useCallback(() => {
    setActiveToolbarTab(0);
    setInitialised(false);
    setLoadingInitial(false);
    setLoadingCommitBookingTrips(false);
    setLoadingOptimalTrips(false);
    setLoadingMasterTrips(false);
    setLoadingSubmitMasterTrip(false);
    setBookings([]);
    setBookingMasterTrips([]);
    setBookingMasterTripOrders([]);
    setBookingMasterTripsToCommit([]);
    setMasterMasterTrips([]);
    setOptimalMasterTrips([]);
    setOptimalVehicleGroups(undefined);
    setPlanningMasterTrips([]);
    setLoadingOrders(false);
    setHasLoadedOrders(false);
    setAssignedOrders([]);
    setUnassignedOrders([]);
    setLoadingContracts(false);
    setLoadingVehicles(false);
    setContracts([]);
    setVehicles([]);
  }, []);

  const handleGetContract = React.useCallback(
    (contractId: IdType | undefined) =>
      contractId ? contracts.find(({id}) => id === contractId) : undefined,
    [contracts]
  );

  const handleUpdateActiveToolbarTab = React.useCallback(
    (newTab: number) =>
      setActiveToolbarTab((prevActiveTab) =>
        prevActiveTab !== newTab ? newTab : prevActiveTab
      ),
    []
  );

  const handleGetOrder = React.useCallback(
    (orderId: IdType | undefined) =>
      orderId ? unassignedOrders.find(({id}) => id === orderId) : undefined,
    [unassignedOrders]
  );

  const handleGetVehicle = React.useCallback(
    (vehicleId: IdType | undefined) =>
      vehicleId ? vehicles.find(({id}) => id === vehicleId) : undefined,
    [vehicles]
  );

  const handleGetOrderPlanningOrderIds = React.useCallback(
    (orderId: IdType | undefined) => {
      if (!orderId) return undefined;
      const order = unassignedOrders.find(({id}) => id === orderId);
      if (!order) return undefined;
      const planningOrderId = order.planningOrder?.id;
      if (!planningOrderId) return undefined;
      return [planningOrderId];
    },
    [unassignedOrders]
  );

  const handleGetMasterTrip = React.useCallback(
    (masterTripId: IdType | undefined) => {
      const findBookingMasterTrip = bookingMasterTrips.find(
        ({id}) => id === masterTripId
      );
      const findPlanningMasterTrip = planningMasterTrips.find(
        ({id}) => id === masterTripId
      );
      const findMasterMasterTrip = masterMasterTrips.find(
        ({id}) => id === masterTripId
      );
      const findOptimalMasterTrip = optimalMasterTrips.find(
        ({id}) => id === masterTripId
      );
      const type: MasterTripTypes | undefined = findBookingMasterTrip
        ? 'booking'
        : findPlanningMasterTrip
        ? 'planning'
        : findMasterMasterTrip
        ? 'master'
        : findOptimalMasterTrip
        ? 'optimal'
        : undefined;
      const masterTrip =
        findBookingMasterTrip ??
        findPlanningMasterTrip ??
        findMasterMasterTrip ??
        findOptimalMasterTrip ??
        undefined;
      return {
        type,
        masterTrip:
          masterTrip && type
            ? {...masterTrip, id: getMasterTripId(type, masterTrip.id)}
            : undefined,
      };
    },
    [
      bookingMasterTrips,
      masterMasterTrips,
      optimalMasterTrips,
      planningMasterTrips,
    ]
  );

  const handleGetOptimalMasterTripByVehicleId = React.useCallback(
    (vehicleId: IdType | undefined) =>
      optimalMasterTrips.filter(({trip}) => trip?.vehicleId === vehicleId),
    [optimalMasterTrips]
  );

  const handleGetMasterTripsWithSameVehicle = React.useCallback(
    (vehicleId: IdType | undefined) =>
      getMasterTripsWithSameVehicle(
        planningMasterTrips
          .concat(bookingMasterTrips)
          .concat(masterMasterTrips),
        vehicleId
      ),
    [bookingMasterTrips, masterMasterTrips, planningMasterTrips]
  );

  const handleGetMasterTripPlanningOrderIds = React.useCallback(
    (masterTripId: IdType | undefined) => {
      if (!masterTripId) return undefined;
      const {masterTrip} = handleGetMasterTrip(masterTripId);
      const planningOrderIds: number[] = [];
      masterTrip?.trip?.stops?.forEach(({orders}) =>
        orders?.forEach(
          ({planningOrder}) =>
            planningOrder?.id && planningOrderIds.push(planningOrder.id)
        )
      );
      if (planningOrderIds.length === 0) return undefined;
      return planningOrderIds;
    },
    [handleGetMasterTrip]
  );

  const handleUpdateBookingMasterTrips = React.useCallback(
    (
      newBookingMasterTrips: WebPlanningBoardMasterTripWithIdType[] | undefined
    ) => {
      if (newBookingMasterTrips) {
        setBookingMasterTrips(newBookingMasterTrips);
        setBookingMasterTripOrders([]);
        newBookingMasterTrips.forEach(({trip}) =>
          trip?.stops?.forEach(({orders}) =>
            orders?.forEach((order) =>
              setBookingMasterTripOrders((prevList) =>
                prevList.some(
                  ({id, orderNumber}) =>
                    id === order.id && orderNumber === order.orderNumber
                )
                  ? prevList
                  : [...prevList, order]
              )
            )
          )
        );
      }
    },
    []
  );

  const handleParseDateTime = React.useCallback(
    (date: Date | undefined) =>
      date
        ? DateTime.fromISO(JSON.parse(JSON.stringify(date))).toJSDate()
        : undefined,
    []
  );

  const handleParseMasterTrip = React.useCallback(
    (
      masterTrips: WebPlanningBoardMasterTrip[] | undefined,
      type: MasterTripTypes
    ): WebPlanningBoardMasterTripWithIdType[] => {
      if (!masterTrips) return [];
      return masterTrips.map(({id, trip, ...masterTrip}) => {
        const parsedTripStart = handleParseDateTime(trip?.tripStart);
        const parsedTripEnd = handleParseDateTime(trip?.tripEnd);
        return {
          ...masterTrip,
          id: setMasterTripId(type, id as number),
          trip: {
            ...trip,
            tripStart: parsedTripStart,
            tripEnd: parsedTripEnd,
            stops: trip?.stops?.map(
              ({
                arrivalTime,
                departureTime,
                serviceTimeStart,
                serviceTimeEnd,
                orders,
                ...stop
              }) => {
                const parsedArrivalTime = handleParseDateTime(arrivalTime);
                const parsedDepartureTime = handleParseDateTime(departureTime);
                const parsedServiceTimeStart =
                  handleParseDateTime(serviceTimeStart);
                const parsedServiceTimeEnd =
                  handleParseDateTime(serviceTimeEnd);
                return {
                  arrivalTime: parsedArrivalTime,
                  departureTime: parsedDepartureTime,
                  serviceTimeStart: parsedServiceTimeStart,
                  serviceTimeEnd: parsedServiceTimeEnd,
                  orders: orders?.map(
                    ({deliverByDatetime, loadByDatetime, ...order}) => {
                      const parsedDeliverByDatetime =
                        handleParseDateTime(deliverByDatetime);
                      const parsedLoadByDateTime =
                        handleParseDateTime(loadByDatetime);
                      return {
                        deliverByDatetime: parsedDeliverByDatetime,
                        loadByDatetime: parsedLoadByDateTime,
                        ...order,
                      };
                    }
                  ),
                  ...stop,
                };
              }
            ),
          },
        };
      });
    },
    [handleParseDateTime]
  );

  const handleResetListings = React.useCallback(() => {
    setSelectedContractListings([]);
    setSelectedCustomerListings([]);
    setSelectedPlanningSkillListings([]);
  }, []);

  // loaders
  const handleLoadContracts = React.useCallback(async () => {
    setLoadingContracts(true);
    try {
      if (contractIds.length > 0) {
        const response = await planningBoardApi.apiWebPlanningBoardContractGet({
          contractIds,
          page: 1,
          perPage: 1000,
          orderBy: 'name',
        });
        setContracts(response.items ?? []);
      }
    } catch (e) {
      notify('error', 'Failed to load contract details');
    } finally {
      setLoadingContracts(false);
    }
  }, [contractIds, notify]);

  const handleLoadVehicles = React.useCallback(
    async (resetFilters?: TResetFilterOptions[]) => {
      setLoadingVehicles(true);
      try {
        if (contractIds.length > 0) {
          const removedPlanningSkillFilter = resetFilters?.find(
            (filter) => filter === 'planningSkill'
          );
          const removedVehicleGroupFilter = resetFilters?.find(
            (filter) => filter === 'vehicleGroup'
          );
          const response = await planningBoardApi.apiWebPlanningBoardVehicleGet(
            {
              contractIds,
              planningSkillIds:
                planningSkillIds.length > 0 && !removedPlanningSkillFilter
                  ? planningSkillIds
                  : undefined,
              vehicleGroupIds:
                vehicleGroupIds.length > 0 && !removedVehicleGroupFilter
                  ? vehicleGroupIds
                  : undefined,
              perPage: 500,
            }
          );
          if (response.items && response.items.length >= 500) {
            notify('warning', 'Cannot load more than 500 vehicles');
          }
          setVehicles(response.items ?? []);
        }
      } catch (e) {
        notify('error', 'Failed to load vehicles');
      } finally {
        setLoadingVehicles(false);
      }
    },
    [contractIds, notify, planningSkillIds, vehicleGroupIds]
  );

  const handleLoadOrders = React.useCallback(
    async (type?: LoadFunctionTypes, resetFilters?: TResetFilterOptions[]) => {
      /*** Pass onmount to only load when component loads and onrefresh to force trigger call */
      if (type === 'onmount' && hasLoadedOrders)
        return {assigned: assignedOrders, unassigned: unassignedOrders};
      else if (contractIds.length > 0) {
        setLoadingOrders(true);
        try {
          const removedCustomerFilter = resetFilters?.find(
            (filter) => filter === 'customer'
          );
          const requestObj: ApiWebPlanningBoardOrderGetRequest = {
            contractIds,
            customerIds:
              customerIds.length > 0 && !removedCustomerFilter
                ? customerIds
                : undefined,
            startDate: startDate.toJSDate(),
            endDate: endDate.toJSDate(),
          };
          const response = await planningBoardApi.apiWebPlanningBoardOrderGet(
            requestObj
          );
          setHasLoadedOrders(true);
          if (response.data) {
            setAssignedOrders(response.data.assigned ?? []);
            setUnassignedOrders(response.data.unassigned ?? []);
          }
        } catch (e) {
          notify('error', 'Failed to load orders');
        } finally {
          setLoadingOrders(false);
        }
      }
    },
    [
      assignedOrders,
      contractIds,
      customerIds,
      endDate,
      hasLoadedOrders,
      notify,
      startDate,
      unassignedOrders,
    ]
  );

  const handleLoadMasterTrips = React.useCallback(
    async (resetFilters?: TResetFilterOptions[]) => {
      setLoadingMasterTrips(true);
      try {
        if (contractIds.length > 0) {
          const removedCustomerFilter = resetFilters?.find(
            (filter) => filter === 'customer'
          );
          const removedVehicleGroupFilter = resetFilters?.find(
            (filter) => filter === 'vehicleGroup'
          );
          const response =
            await planningBoardApi.apiWebPlanningBoardMasterTripGet({
              startDate: startDate.toJSDate(),
              endDate: endDate.toJSDate(),
              contractIds: contractIds,
              customerIds:
                customerIds.length > 0 && !removedCustomerFilter
                  ? customerIds
                  : undefined,
              vehicleGroupIds:
                vehicleGroupIds.length > 0 && !removedVehicleGroupFilter
                  ? vehicleGroupIds
                  : undefined,
            });
          if (response.data) {
            const responseBookingMasterTrips = handleParseMasterTrip(
              response.data.booking,
              'booking'
            );

            handleUpdateBookingMasterTrips(responseBookingMasterTrips);

            setBookings(
              response.data.bookingMaintenance?.map((booking) => {
                return {
                  ...booking,
                  bookingMasterTrip: (response.data?.booking ?? []).find(
                    ({id}) => id === booking.bookingMasterTripId
                  ),
                };
              }) ?? []
            );

            setMasterMasterTrips(
              handleParseMasterTrip(response.data.master, 'master')
            );
            setPlanningMasterTrips(
              handleParseMasterTrip(response.data.planning, 'planning')
            );
            return response;
          }
        }
      } catch (e) {
        notify('error', 'Failed to load master trips');
      } finally {
        setLoadingMasterTrips(false);
      }
    },
    [
      contractIds,
      customerIds,
      endDate,
      handleParseMasterTrip,
      handleUpdateBookingMasterTrips,
      notify,
      startDate,
      vehicleGroupIds,
    ]
  );

  const handleLoadOptimalTrips = React.useCallback(
    async (order: WebPlanningBoardOrder | WebPlanningBoardTripStopOrder) => {
      setLoadingOptimalTrips(true);
      try {
        if (order.id) {
          const response =
            await planningBoardApi.apiWebPlanningBoardBookingAvailabilityPost({
              body: {
                contractIds: contractIds,
                endDate: endDate.toJSDate(),
                orderId: order.id,
                skill: order.planningOrder?.skill,
                startDate: startDate.toJSDate(),
                maxOptionsPerVehicle,
                totalOptionCount,
              },
            });
          setOptimalMasterTrips(
            response.items?.map((trip) => ({
              ...trip,
              id: trip.tripNumber,
            })) ?? []
          );
          setOptimalVehicleGroups(response.vehicleIds as number[]);
        }
      } finally {
        setLoadingOptimalTrips(false);
      }
    },
    [contractIds, endDate, maxOptionsPerVehicle, startDate, totalOptionCount]
  );

  const handleCommitBookingMasterTrips = React.useCallback(async () => {
    if (!hasPermission.edit) {
      notify('warning', "You don't have the correct permissions");
      return;
    }

    setLoadingCommitBookingTrips(true);
    try {
      const bookingMasterTripIds = bookingMasterTripsToCommit
        .filter(({id}) => id)
        .map(({id}) => getMasterTripId('booking', id as unknown as string));
      if (bookingMasterTripIds.length > 0) {
        const response =
          await planningBoardApi.apiWebPlanningBoardBookingCommitPost({
            body: {bookingMasterTripIds},
          });
        setBookingMasterTripsToCommit((prevBookingMasterTripsToCommit) =>
          prevBookingMasterTripsToCommit.filter(({id}) =>
            response.masterTripIds?.includes(
              getMasterTripId('booking', id as unknown)
            )
          )
        );
        await handleLoadMasterTrips();
      }
    } catch (e) {
      notify('error', 'Failed to commit trips');
    } finally {
      setLoadingCommitBookingTrips(false);
    }
  }, [
    bookingMasterTripsToCommit,
    handleLoadMasterTrips,
    hasPermission,
    notify,
  ]);

  const handleLoadInitialDetails = React.useCallback(
    async (resetFilters?: TResetFilterOptions[]) => {
      setInitialised(true);
      setLoadingInitial(true);
      try {
        await Promise.all([
          handleLoadVehicles(resetFilters),
          handleLoadOrders('onmount'),
          handleLoadMasterTrips(resetFilters),
          handleLoadContracts(),
        ]);
      } catch {
        setInitialised(false);
      } finally {
        setLoadingInitial(false);
      }
    },
    [
      handleLoadVehicles,
      handleLoadOrders,
      handleLoadMasterTrips,
      handleLoadContracts,
    ]
  );

  const handleReloadInitialDetails = React.useCallback(async () => {
    setLoadingInitial(true);
    try {
      await Promise.all([
        handleLoadOrders('onrefresh'),
        handleLoadMasterTrips(),
      ]);
    } catch {
      setInitialised(false);
    } finally {
      setLoadingInitial(false);
    }
  }, [handleLoadOrders, handleLoadMasterTrips]);

  return {
    activeToolbarTab,
    assignedOrders,
    bookingMasterTripOrders,
    bookingMasterTrips,
    bookingMasterTripsToCommit,
    bookings,
    cleanup: handleCleanup,
    commitBookingMasterTrips: handleCommitBookingMasterTrips,
    contractIds,
    contracts,
    customerIds,
    endDate,
    getContract: handleGetContract,
    getMasterTrip: handleGetMasterTrip,
    getMasterTripPlanningOrderIds: handleGetMasterTripPlanningOrderIds,
    getMasterTripsWithSameVehicle: handleGetMasterTripsWithSameVehicle,
    getOptimalMasterTripByVehicleId: handleGetOptimalMasterTripByVehicleId,
    getOrder: handleGetOrder,
    getOrderPlanningOrderIds: handleGetOrderPlanningOrderIds,
    getVehicle: handleGetVehicle,
    hasPermission,
    initialised,
    loadContracts: handleLoadContracts,
    loadInitialDetails: handleLoadInitialDetails,
    loadMasterTrips: handleLoadMasterTrips,
    loadOptimalTrips: handleLoadOptimalTrips,
    loadOrders: handleLoadOrders,
    loadVehicles: handleLoadVehicles,
    loadingCommitBookingTrips,
    loadingContracts,
    loadingInitial,
    loadingMasterTrips,
    loadingOptimalTrips,
    loadingOrders,
    loadingSubmitMasterTrip,
    loadingVehicles,
    masterMasterTrips,
    maxOptionsPerVehicle,
    onUpdateActiveToolbarTab: handleUpdateActiveToolbarTab,
    optimalMasterTrips,
    optimalVehicleGroups,
    planningMasterTrips,
    planningSkillIds,
    reloadInitialDetails: handleReloadInitialDetails,
    resetListings: handleResetListings,
    selectedContractListings,
    selectedCustomerListings,
    selectedPlanningSkillListings,
    selectedVehicleGroupListings,
    setBookingMasterTripsToCommit,
    setEndDate,
    setInitialised,
    setMasterMasterTrips,
    setMaxOptionsPerVehicle,
    setHasLoadedOrders,
    setOptimalMasterTrips,
    setPlanningMasterTrips,
    setSelectedContractListings,
    setSelectedCustomerListings,
    setSelectedPlanningSkillListings,
    setSelectedVehicleGroupListings,
    setStartDate,
    setTotalOptionCount,
    setUnassignedOrders,
    setVehicles,
    startDate,
    totalOptionCount,
    unassignedOrders,
    vehicles,
  };
};

export type usePlanningBoardResponse = ReturnType<typeof usePlanningBoard>;

export const usePlanningBoardResponseInitial: usePlanningBoardResponse = {
  activeToolbarTab: 0,
  assignedOrders: [],
  bookingMasterTripOrders: [],
  bookingMasterTrips: [],
  bookingMasterTripsToCommit: [],
  bookings: [],
  cleanup: () => {},
  commitBookingMasterTrips: async () => {},
  contractIds: [],
  contracts: [],
  customerIds: [],
  endDate: DateTime.local().endOf('day'),
  getContract: () => undefined,
  getMasterTrip: () => ({type: undefined, masterTrip: undefined}),
  getMasterTripPlanningOrderIds: () => undefined,
  getMasterTripsWithSameVehicle: () => [],
  getOptimalMasterTripByVehicleId: () => [],
  getOrder: () => undefined,
  getOrderPlanningOrderIds: () => undefined,
  getVehicle: () => undefined,
  hasPermission: {
    edit: RoleService.hasPermission('Edit PlanningBoard', 'Edit'),
    view: RoleService.hasPermission('PlanningBoard View', 'View'),
  },
  initialised: false,
  loadContracts: async () => {},
  loadInitialDetails: async () => {},
  loadMasterTrips: async () => undefined,
  loadOptimalTrips: async () => {},
  loadOrders: async () => undefined,
  loadVehicles: async () => {},
  loadingCommitBookingTrips: false,
  loadingContracts: false,
  loadingInitial: false,
  loadingMasterTrips: false,
  loadingOptimalTrips: false,
  loadingOrders: false,
  loadingSubmitMasterTrip: false,
  loadingVehicles: false,
  masterMasterTrips: [],
  maxOptionsPerVehicle: 2,
  onUpdateActiveToolbarTab: () => {},
  optimalMasterTrips: [],
  optimalVehicleGroups: undefined,
  planningMasterTrips: [],
  planningSkillIds: [],
  reloadInitialDetails: async () => {},
  resetListings: () => {},
  selectedContractListings: [],
  selectedCustomerListings: [],
  selectedPlanningSkillListings: [],
  selectedVehicleGroupListings: [],
  setBookingMasterTripsToCommit: () => {},
  setEndDate: () => {},
  setInitialised: () => {},
  setMasterMasterTrips: () => {},
  setMaxOptionsPerVehicle: () => {},
  setHasLoadedOrders: () => {},
  setOptimalMasterTrips: () => {},
  setPlanningMasterTrips: () => {},
  setSelectedContractListings: () => {},
  setSelectedCustomerListings: () => {},
  setSelectedPlanningSkillListings: () => {},
  setSelectedVehicleGroupListings: () => {},
  setStartDate: () => {},
  setTotalOptionCount: () => {},
  setUnassignedOrders: () => {},
  setVehicles: () => {},
  startDate: DateTime.local().startOf('day'),
  totalOptionCount: 15,
  unassignedOrders: [],
  vehicles: [],
};
