import React from 'react';
import CardContent from '@mui/material/CardContent';
import {ReportScheduleFormRecipients} from '../ReportScheduleForm/ReportScheduleFormRecipients';

export const ReportScheduleAddRecipients: React.FC = () => {
  return (
    <CardContent style={{display: 'flex', flexDirection: 'column'}}>
      <ReportScheduleFormRecipients />
    </CardContent>
  );
};
