import React from 'react';
import {VehicleGroupForm} from './VehicleGroupForm';
import {ITemplateTabControllerTab, TemplateTabs} from '../../factory/template';
import {VehicleGroupContext} from './VehicleGroupContext';
import {VehicleGroupVehicles} from './vehicleGroupVehicles';

export const VehicleGroup: React.FC = () => {
  const {vehicleGroupId, loading} = React.useContext(VehicleGroupContext);

  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {title: 'Details', element: <VehicleGroupForm />},
    {title: 'Vehicles', element: <VehicleGroupVehicles />},
  ]);

  const path = React.useMemo(
    () => `/vehiclegrouplist/${vehicleGroupId}`,
    [vehicleGroupId]
  );

  return <TemplateTabs tabs={tabs} path={path} loading={loading} />;
};
