import React from 'react';
import {ContractGroupContext} from './ContractGroupContext';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../factory/template';
import {ContractGroup} from '@onroadvantage/onroadvantage-api';

export const ContractGroupList: React.FC = () => {
  const {loadList, cleanupList} = React.useContext(ContractGroupContext);

  const [config] = React.useState<TemplateTableConfig<ContractGroup>>({
    columns: [{name: 'name', label: 'Contract Group Name', type: 'string'}],
    enableFilter: true,
    enableSort: true,
    deleteMessage: (row) => ({
      title: 'Contract Group',
      items: {Name: row.name},
    }),
    identifier: 'CONTRACT_GROUP_LIST',
  });

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [loadList, cleanupList]);

  return (
    <TemplateTableWrapper config={config} context={ContractGroupContext} />
  );
};
