import React from 'react';
import {useRightDrawerStyles} from './RightDrawer.style';
import {Drawer} from '@mui/material';
import {WindowInfoContext} from '../../../contexts';

export interface RightDrawerProps {
  open?: boolean;
}

export const RightDrawer: React.FC<RightDrawerProps> = ({children, open}) => {
  const classes = useRightDrawerStyles();

  const {isDesktop} = React.useContext(WindowInfoContext);

  if (!isDesktop) {
    if (!open) {
      return null;
    }
    return <>{children}</>;
  }

  return (
    <Drawer
      anchor="right"
      className={classes.root}
      open={open}
      classes={{
        paper: classes.paper,
      }}
      variant="persistent"
    >
      {children}
    </Drawer>
  );
};
