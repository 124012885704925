import ConfigService from './ConfigService/ConfigService';
import {encodeUrl, flatten} from './Util';
import ResponseService from './ResponseService';
import {authStore} from '../store';

const serverUrl = ConfigService.serverUri;
const queryUrl = `${serverUrl}/api/report`;

const ReportService = {
  query: async (params) => {
    Object.keys(params).forEach(
      // eslint-disable-next-line no-param-reassign
      (key) => params[key] == null && delete params[key]
    );
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(encodeUrl(queryUrl, params), {
      method: 'GET',
      mode: 'cors',
      headers,
    });
    return ReportService.normalize(await ResponseService.checkStatus(response));
  },
  generateLink: async (params) => {
    Object.keys(params).forEach(
      // eslint-disable-next-line no-param-reassign
      (key) => params[key] == null && delete params[key]
    );
    return encodeUrl(`${queryUrl}/generate`, params);
  },
  getToken: async (params) => {
    Object.keys(params).forEach(
      // eslint-disable-next-line no-param-reassign
      (key) => params[key] == null && delete params[key]
    );
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(
      encodeUrl(`${queryUrl}/generate_token`, params),
      {
        method: 'GET',
        mode: 'cors',
        headers,
      }
    );
    return ResponseService.checkStatus(response);
  },
  normalize: async (response) => {
    const result = flatten(await response.data);
    result.items = result.items.map((resultItem) => {
      // eslint-disable-next-line no-param-reassign
      resultItem.category = resultItem.reportCategories
        .map((category) => category.name)
        .join(', ');
      return resultItem;
    });
    return result;
  },
  getById: async (reportId) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(`${queryUrl}/${reportId}`, {
      method: 'GET',
      mode: 'cors',
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  update: async (report) => {
    if (!report.id) {
      return ReportService.create(report);
    }
    Object.keys(report).forEach(
      // eslint-disable-next-line no-param-reassign
      (key) => report[key] == null && delete report[key]
    );
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(`${queryUrl}/${report.id}`, {
      method: 'PATCH',
      mode: 'cors',
      body: JSON.stringify(report),
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  create: async (report) => {
    Object.keys(report).forEach(
      // eslint-disable-next-line no-param-reassign
      (key) => report[key] == null && delete report[key]
    );
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(`${queryUrl}`, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({...report}),
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  delete: async (reportId) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(`${queryUrl}/${reportId}`, {
      method: 'DELETE',
      mode: 'cors',
      headers,
    });
    return ResponseService.checkStatus(response);
  },
};

export default ReportService;
