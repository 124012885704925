import React from 'react';
import {useTripSettingsViewLayoutStyles} from './TripSettingsViewLayout.style';
import {useTripSettingsContext} from '../tripSettingsContext';
import {List} from '@mui/material';
import {TripSettingsViewLayoutItem} from './TripSettingsViewLayoutItem';
import {TripSettingsViewLayoutSortableItem} from './TripSettingsViewLayoutSortableItem';

interface TripSettingsViewLayoutContainerProps {
  isSortable?: boolean;
}

export const TripSettingsViewLayoutContainer: React.FC<
  TripSettingsViewLayoutContainerProps
> = ({isSortable}) => {
  const classes = useTripSettingsViewLayoutStyles();
  const {tripViewLayout} = useTripSettingsContext();

  return (
    <List className={classes.list}>
      {isSortable
        ? tripViewLayout?.map((view, index) => (
            <TripSettingsViewLayoutSortableItem
              key={view.label}
              view={view}
              index={index}
              isSortable
            />
          ))
        : tripViewLayout?.map((view) => (
            <TripSettingsViewLayoutItem key={view.label} view={view} />
          ))}
    </List>
  );
};
