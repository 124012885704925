import React from 'react';

export type ThemeModeTypes = 'light' | 'dark';

export interface ThemeContextProps {
  themeMode: ThemeModeTypes;
  toggleThemeMode: () => void;
}

export const ThemeModeContext = React.createContext<ThemeContextProps>({
  themeMode: 'light',
  toggleThemeMode: () => {},
});

export const ThemeModeContextProvider: React.FC = ({children}) => {
  const [themeMode, setThemeMode] = React.useState<ThemeModeTypes>('light');

  const handleToggleThemeMode = React.useCallback(() => {
    setThemeMode((prevTheme) => {
      localStorage.setItem(
        'themeMode',
        prevTheme === 'light' ? 'dark' : 'light'
      );
      return prevTheme === 'light' ? 'dark' : 'light';
    });
  }, []);

  React.useEffect(() => {
    const getTheme = localStorage.getItem('themeMode');

    if (!getTheme) {
      localStorage.setItem('themeMode', 'light');
    }

    const currentMode =
      getTheme === 'light' || getTheme === 'dark' ? getTheme : 'light';

    setThemeMode(currentMode);
  }, []);

  const value: ThemeContextProps = {
    themeMode,
    toggleThemeMode: handleToggleThemeMode,
  };

  return (
    <ThemeModeContext.Provider value={value}>
      {children}
    </ThemeModeContext.Provider>
  );
};
