import React from 'react';
import {UserContext} from './UserContext';
import {useParams} from 'react-router-dom';

interface IParams {
  userId?: string;
}

export const UserIdHandler: React.FC = () => {
  const {setUserId} = React.useContext(UserContext);

  const {userId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = userId ? parseInt(userId) : undefined;
    setUserId(parsedId);
    return () => setUserId(undefined);
  }, [userId, setUserId]);

  return null;
};
