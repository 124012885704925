import React from 'react';
import _ from 'lodash';
import {
  ITemplateControllerRoute,
  TemplateController,
} from '../../factory/template';
import {
  ProductGroupContext,
  ProductGroupContextProvider,
} from './ProductGroupContext';
import {ProductGroupList} from './ProductGroupList';
import {ProductGroupIdHandler} from './ProductGroupIdHandler';
import {ProductGroupForm} from './ProductGroupForm';
import {ProductGroup} from './ProductGroup';
import {ProductGroupProductsContextProvider} from './productGroupProducts';

export const ProductGroupController: React.FC = () => {
  return (
    <ProductGroupContextProvider>
      <ProductGroupProductsContextProvider>
        <ProductGroupControllerComponent />
      </ProductGroupProductsContextProvider>
    </ProductGroupContextProvider>
  );
};

const ProductGroupControllerComponent: React.FC = () => {
  const {productGroup, loading} = React.useContext(ProductGroupContext);
  const routes: ITemplateControllerRoute[] = React.useMemo(
    () => [
      {title: 'Product Group List', component: ProductGroupList},
      {
        path: '/add',
        title: 'New Product Group',
        component: () => <ProductGroupForm isAdd />,
      },
      {
        path: '/:productGroupId',
        title: (params) =>
          loading
            ? 'Product Group Loading...'
            : productGroup?.name ??
              `Product Group ${_.get(params, 'productGroupId', 'Single')}`,
        component: ProductGroup,
        IdHandler: ProductGroupIdHandler,
      },
    ],
    [loading, productGroup?.name]
  );
  return <TemplateController rootPath="/productgrouplist" routes={routes} />;
};
