import React from 'react';
import {
  MasterTripDebriefSnapshot,
  MasterTripDebriefTripStopSnapshot,
  MasterTripDocumentStorageSnapshot,
} from '@onroadvantage/onroadvantage-api';
// import {Button} from '@mui/material';
import {Assignment} from '@mui/icons-material';
import {TemplateCard} from '../../../factory/template';
import {TripDebriefContext} from '../TripDebriefContext';
// import {documentStorageApi} from '../../../api';
// import {useAppNotifications} from '../../../contexts';
// import {Loader} from '../../loader';
import {TripDebriefDocumentGroup} from './TripDebriefDocumentGroup';

export interface DocumentGroupedByStop {
  [keu: string]: {
    stop: MasterTripDebriefTripStopSnapshot | undefined;
    documents: (MasterTripDocumentStorageSnapshot | undefined | null)[];
  };
}

export const TripDebriefDocuments: React.FC = () => {
  // const notify = useAppNotifications();
  const {loading, masterTripDebriefData} = React.useContext(TripDebriefContext);
  /**
   *  Destructure snapshotData, need to add || ({} as MasterTripDebriefSnapshot) for typescript, since
   *  snapshotData is nullable.
   */
  const {documents, stops} =
    masterTripDebriefData?.snapshotData || ({} as MasterTripDebriefSnapshot);
  const [documentsGrouped, setDocumentsGrouped] =
    React.useState<DocumentGroupedByStop>({});
  // const [downloading, setDownloading] = React.useState<boolean>(false);

  React.useEffect(() => {
    const groupedByStops: DocumentGroupedByStop = {};

    documents?.forEach((document) => {
      const foundStop = stops?.find(({id}) => id === document?.tripStopId);
      const stopIdToUse = document?.tripStopId?.toString() ?? 'undefined';

      if (groupedByStops) {
        groupedByStops[stopIdToUse] = {
          stop: foundStop,
          documents: [
            ...(groupedByStops[stopIdToUse]?.documents ?? []),
            document,
          ],
        };
      }
    });

    setDocumentsGrouped(groupedByStops);

    return () => setDocumentsGrouped({});
  }, [stops, documents]);

  // const handleDownloadAll = React.useCallback(async () => {
  //   setDownloading(true);
  //   try {
  //     if (documents && documents.length > 0) {
  //       const links: string[] = [];
  //       for (const document of documents) {
  //         if (document.documentStorage?.id) {
  //           const response = await documentStorageApi.apiDocumentStorageDocumentStorageIdDownloadLinkGet(
  //             {
  //               documentStorageId: document.documentStorage?.id,
  //             }
  //           );
  //
  //           if (response && response.downloadLink) {
  //             links.push(response.downloadLink);
  //           }
  //         }
  //       }
  //       if (links.length > 0) {
  //         notify('success', 'Document downloaded');
  //
  //         links.forEach((link) => window.open(link, '_blank'));
  //       }
  //     }
  //   } catch (e) {
  //     notify('error', e.message ?? 'Failed to download document');
  //   } finally {
  //     setTimeout(() => setDownloading(false), 2000);
  //   }
  // }, [documents, notify]);

  return (
    <TemplateCard
      title="Trip Documents"
      avatar={<Assignment />}
      loading={loading}
      disableContentPadding
    >
      {Object.keys(documentsGrouped).map((key) => (
        <TripDebriefDocumentGroup
          key={key}
          documentGroup={documentsGrouped[key]}
        />
      ))}
      {/*<Button*/}
      {/*  onClick={handleDownloadAll}*/}
      {/*  disabled={downloading || !documents || documents.length === 0}*/}
      {/*  startIcon={<Loader size={25} loading={downloading} />}*/}
      {/*>*/}
      {/*  {downloading ? 'Downloading all documents...' : 'Download All'}*/}
      {/*</Button>*/}
    </TemplateCard>
  );
};
