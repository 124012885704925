import React from 'react';
import {TemplateCard, TemplateForm} from '../../../../factory/template';
import {FormikSelect, FormikSubmit, FormikTextField} from '../../../formik';
import {useContractOperationalEventTypePromptContext} from './ContractOperationalEventTypePromptContext';
import {openaiAssistantPreviewApi} from '../../../../api';
import {ContractCriticalEventTypeConfigPromptFormValues} from '../../contractCriticalEventTypeConfig/contractCriticalEventTypeConfigPrompt/ContractCriticalEventTypePromptForm';
import {useAppNotifications} from '../../../../contexts';
import {Button, Stack, Typography} from '@mui/material';
import {Loader} from '../../../loader';

export interface ContractOperationalEventTypeConfigPromptFormValues {
  type: string;
  role: string;
  assistantId?: string | undefined;
  prompt: string;
  content?: string | undefined;
}

export const ContractOperationalEventTypePromptForm: React.FC<{
  isAdd?: boolean;
}> = ({isAdd}) => {
  const notify = useAppNotifications();
  const {
    loading,
    submitting,
    contractOperationalEventTypePrompt,
    onDetailsSubmit,
  } = useContractOperationalEventTypePromptContext();
  const [preview, setPreview] = React.useState<string | null>(null);
  const [previewStatus, setPreviewStatus] = React.useState<
    'loading' | 'loaded' | 'missingData' | 'error' | null
  >(null);

  const initialValues =
    React.useMemo<ContractOperationalEventTypeConfigPromptFormValues>(() => {
      if (isAdd || contractOperationalEventTypePrompt == null) {
        return {
          type: '',
          prompt: '',
          content: '',
          role: 'user',
        };
      }
      return {
        type: contractOperationalEventTypePrompt.type ?? '',
        prompt: contractOperationalEventTypePrompt.prompt ?? '',
        assistantId: contractOperationalEventTypePrompt.assistantId,
        content: contractOperationalEventTypePrompt.content,
        role: contractOperationalEventTypePrompt.role ?? 'user',
      };
    }, [contractOperationalEventTypePrompt, isAdd]);

  const handleGeneratePreview = React.useCallback(
    async (values: ContractCriticalEventTypeConfigPromptFormValues) => {
      setPreviewStatus('loading');
      try {
        if (values.assistantId == null || values.assistantId === '') {
          notify('warning', 'No assistantId provided');
          setPreviewStatus('error');
          return;
        }
        if (values.content == null || values.content === '') {
          notify('warning', 'No event data provided');
          setPreviewStatus('error');
          return;
        }
        const res =
          await openaiAssistantPreviewApi.apiOpenaiAssistantPreviewGet({
            assistantId: values.assistantId,
            prompt: values.prompt,
            content: values.content,
            role: values.role,
          });
        setPreviewStatus('loaded');
        setPreview(res.response ?? null);
      } catch (e) {
        setPreviewStatus('error');
        notify('error', 'Failed to generate preview');
      }
    },
    [notify]
  );

  return (
    <TemplateCard
      title={`Contract Operational Event Type Prompt ${isAdd ? 'Add' : 'Edit'}`}
      loading={loading}
    >
      <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
        <Stack flex={1}>
          <TemplateForm
            initialValues={initialValues}
            onSubmit={onDetailsSubmit}
            submitting={submitting}
            permission={{
              name: 'Edit ContractCriticalEventTypeConfig',
              type: 'Edit',
            }}
            disableActions
          >
            {({values}) => (
              <>
                <FormikSelect
                  options={[
                    'Trigger',
                    'Unassigned Escalation',
                    'Open Escalation',
                    'Summary',
                  ]}
                  name="type"
                  label="Type"
                />
                <FormikTextField
                  name="assistantId"
                  label="Assistant ID"
                  fullWidth
                />
                <FormikSelect
                  name="role"
                  label="Role"
                  options={['user']}
                  fullWidth
                />
                <FormikTextField
                  name="prompt"
                  label="Instruction"
                  maxRows={4}
                  multiline
                  fullWidth
                />
                <FormikTextField
                  name="content"
                  label="Event Data"
                  maxRows={6}
                  multiline
                  fullWidth
                />
                <Stack direction="row" spacing={2}>
                  <FormikSubmit />
                  <Button
                    disabled={
                      values.assistantId == null ||
                      values.assistantId === '' ||
                      values.prompt == null ||
                      values.prompt === '' ||
                      values.content == null ||
                      values.content === ''
                    }
                    onClick={() => handleGeneratePreview(values)}
                  >
                    Preview
                  </Button>
                </Stack>
              </>
            )}
          </TemplateForm>
        </Stack>
        <Stack flex={1} flexGrow={1}>
          <Typography fontSize="1rem" gutterBottom>
            Prompt Preview:
          </Typography>
          <Stack
            border="1px solid rgba(0, 0, 0, 0.23)"
            borderRadius="4px"
            flex={1}
            p={2}
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            {previewStatus === 'loading' ? (
              <Loader text="Generating preview" />
            ) : previewStatus === 'missingData' ? (
              <Typography>Missing required data</Typography>
            ) : previewStatus === 'error' ? (
              <Typography>Failed to generate preview</Typography>
            ) : (
              <Typography>{preview}</Typography>
            )}
          </Stack>
        </Stack>
      </Stack>
    </TemplateCard>
  );
};
