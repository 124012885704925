import React from 'react';
import {NotificationConfigForm} from './NotificationConfigForm';
import {ITemplateTabControllerTab, TemplateTabs} from '../../factory/template';
import {NotificationConfigContext} from './NotificationConfigContext';
import {NotificationConfigDocuments} from './NotificationConfigDocuments';
import NotificationConfigTaskTriggers from './NotificationConfigTaskTriggers';
import NotificationConfigEventTriggers from './NotificationConfigEventTriggers';
import NotificationConfigEscalationTriggers from './NotificationConfigEscalationTriggers';

export const NotificationConfig: React.FC = () => {
  const {notificationConfigId, loading} = React.useContext(
    NotificationConfigContext
  );

  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {title: 'Details', element: <NotificationConfigForm />},
    {
      title: 'Task Template Triggers',
      element: <NotificationConfigTaskTriggers />,
    },
    {
      title: 'Event Type Triggers',
      element: <NotificationConfigEventTriggers />,
    },
    {
      title: 'Escalation Triggers',
      element: <NotificationConfigEscalationTriggers />,
    },
    {title: 'Documents', element: <NotificationConfigDocuments />},
  ]);

  return (
    <TemplateTabs
      tabs={tabs}
      path={`/notificationconfiglist/${notificationConfigId}`}
      loading={loading}
    />
  );
};
