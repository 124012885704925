import React from 'react';
import {ReportContext, ReportContextProps} from './ReportContext';
import HistoryService from '../../service/history';
import {
  Report,
  ReportCategory as ReportCategoryType,
} from '@onroadvantage/onroadvantage-api';
import {TemplateTable, TemplateTableConfig} from '../../factory/template';

interface ReportListProps {
  id?: string;
}

interface IReport extends Report {
  category?: string;
}

export const ReportList: React.FC<
  ReportListProps
> = (): React.ReactElement | null => {
  const {
    loading,
    loadList,
    list,
    listFilters,
    listItemTotal,
    listPage,
    listPageSize,
    listPageTotal,
    listSorting,
    onListDelete,
    onListFilterChange,
    onListPageChange,
    onListPageSizeChange,
    onListSortingChange,
  } = React.useContext<ReportContextProps>(ReportContext);

  const [config] = React.useState<TemplateTableConfig<IReport>>({
    columns: [
      {name: 'name', label: 'Name', type: 'string'},
      {name: 'version', label: 'Version', type: 'string'},
      {
        name: 'category',
        label: 'Category',
        type: 'string',
        enableFilter: false,
        enableSort: false,
        getValue: (i) =>
          (i.reportCategories ?? [])
            .map((c: ReportCategoryType) => c.name)
            .join(', '),
      },
      {
        name: 'createdAt',
        label: 'Created At',
        type: 'datetime',
        enableFilter: false,
      },
      {
        name: 'updatedAt',
        label: 'Updated At',
        type: 'datetime',
        enableFilter: false,
      },
    ],
    enableFilter: true,
    enableSort: true,
    deleteMessage: (row) => ({
      title: 'Report',
      items: {Name: row.name, Version: row.version, Id: row.id},
    }),
    identifier: 'REPORT_LIST',
  });

  const handleAdd = () => {
    HistoryService.push('/reportlist/add');
  };

  const handleNavigate = (row: any) => {
    HistoryService.push(`/reportlist/${row.id}`);
  };

  React.useEffect(() => {
    loadList();
  }, [loadList]);

  return (
    <TemplateTable
      config={config}
      currentPage={listPage}
      filters={listFilters}
      itemTotal={listItemTotal}
      list={list}
      loading={loading}
      onAdd={handleAdd}
      onDelete={onListDelete}
      onCurrentPageChange={onListPageChange}
      onFiltersChange={onListFilterChange}
      onNavigate={handleNavigate}
      onPageSizeCountsChange={onListPageSizeChange}
      onRefresh={loadList}
      onSortingChange={onListSortingChange}
      pageSize={listPageSize}
      pageTotal={listPageTotal}
      sorting={listSorting}
    />
  );
};
