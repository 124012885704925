import React from 'react';
import {DataTypeProvider} from '@devexpress/dx-react-grid';
import {TableActionButtonBase} from './TableActionButtonBase';
import {Edit} from '@mui/icons-material';
import {useTemplateTableActions} from '../../providers';

export const TableActionButtonEdit: React.FC<
  DataTypeProvider.ValueFormatterProps
> = ({row}) => {
  const {setEditingRowsHandler, enableInlineEdit, enableRowEdit} =
    useTemplateTableActions();

  const handleSelectRow = React.useCallback(() => {
    if (setEditingRowsHandler) setEditingRowsHandler(row);
  }, [setEditingRowsHandler, row]);

  if (
    !enableInlineEdit ||
    (enableRowEdit !== undefined && !enableRowEdit(row))
  ) {
    return null;
  }

  return (
    <TableActionButtonBase
      data-cy="TemplateTableActionsEdit"
      onClick={handleSelectRow}
    >
      <Edit />
    </TableActionButtonBase>
  );
};
