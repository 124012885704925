import React from 'react';
import PropTypes from 'prop-types';
import {getStyle} from '../helpers';

const componentStyle = (brandColors) => ({
  default: {
    darkFont: {
      color: brandColors.brandPrimaryText,
    },
    lightFont: {
      color: brandColors.brandBackgroundText,
    },
  },
});

const DropSVG = ({color, height}) => (
  <svg
    height={height}
    viewBox="0 0 100 147"
    xmlns="http://www.w3.org/2000/svg"
    style={{display: 'block', marginLeft: 'auto', marginRight: 'auto'}}
  >
    <path
      d="M50 147 L5 66 A48.1 48.1 40 1 1 95 66 L50 148z"
      fill={color}
      stroke="#6D6E71"
      strokeWidth="1"
    />
  </svg>
);

DropSVG.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
};

const DropGraph = ({own, other, color1, color2, width, height}) => {
  const styles = getStyle(componentStyle) || {};
  const total = own + other;
  const labelPadding = width * 0.2;
  const labelSize = width * 0.15;
  const ownScale = own / total;
  const ownHeight = (height - labelPadding * 2) * ownScale + labelPadding;

  if (own === 0 && other === 0) {
    return (
      <div style={{maxWidth: width, maxHeight: height}}>
        <DropSVG color={color2} height={height} />
        <div
          style={{
            ...styles.lightFont,
            fontSize: labelSize,
            marginTop: -height,
            textAlign: 'center',
          }}
        >
          0
        </div>
      </div>
    );
  }
  if (own === 0) {
    return (
      <div style={{maxWidth: width, maxHeight: height}}>
        <DropSVG color={color1} height={height} />
        <div
          style={{
            ...styles.lightFont,
            fontSize: labelSize,
            marginTop: -height,
            textAlign: 'center',
          }}
        >
          {other}
        </div>
      </div>
    );
  }

  if (other === 0) {
    return (
      <div style={{maxWidth: width, maxHeight: height}}>
        <DropSVG color={color2} height={height} />
        <div
          style={{
            ...styles.darkFont,
            fontSize: labelSize,
            marginTop: -height,
            textAlign: 'center',
          }}
        >
          {own}
        </div>
      </div>
    );
  }

  return (
    <div style={{maxWidth: width, maxHeight: height}}>
      <DropSVG color={color1} height={height} />
      <div
        style={{
          maxWidth: width,
          height: ownHeight,
          marginTop: -(ownHeight - 1),
          textAlign: 'center',
        }}
      >
        <DropSVG color={color2} height={ownHeight} />
        <div
          style={{
            ...styles.darkFont,
            fontSize: labelSize,
            marginTop: -(ownHeight - (ownHeight - labelPadding) / 3),
          }}
        >
          {own}
        </div>
      </div>
      <div
        style={{
          ...styles.lightFont,
          fontSize: labelSize,
          marginTop: -height,
          textAlign: 'center',
        }}
      >
        {other}
      </div>
    </div>
  );
};

DropGraph.propTypes = {
  own: PropTypes.number.isRequired,
  other: PropTypes.number.isRequired,
  color1: PropTypes.string,
  color2: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default DropGraph;
