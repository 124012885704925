import React from 'react';
import {WebSpeedTableList} from '@onroadvantage/onroadvantage-api';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../../factory/template';
import {
  useSpeedTableListContext,
  SpeedTableListContext,
} from './SpeedTableListContext';

export const SpeedTableList: React.FC = () => {
  const {loadList, cleanupList} = useSpeedTableListContext();

  const config: TemplateTableConfig<WebSpeedTableList> = React.useMemo(
    () => ({
      columns: [
        {name: 'name', label: 'Name', type: 'string'},
        {name: 'contractCode', label: 'Contract Code', type: 'string'},
        {name: 'vehicleTypeName', label: 'Vehicle Type Name', type: 'string'},
      ],
      enableFilter: true,
      enableSort: true,
      deleteMessage: (row) => ({
        title: 'Speed Table',
        items: {
          Name: row.name,
          ContractCode: row.contractCode,
          VehicleTypeName: row.vehicleTypeName,
        },
      }),
      identifier: 'SPEED_TABLE_LIST',
    }),
    []
  );

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [loadList, cleanupList]);

  return (
    <TemplateTableWrapper config={config} context={SpeedTableListContext} />
  );
};
