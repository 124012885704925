import React from 'react';
import clsx from 'clsx';
import {useFormikContext} from 'formik';
import {
  IconButton,
  Card,
  CardActions,
  CardContent,
  Typography,
  Grid,
  Tooltip,
  Badge,
  Switch,
} from '@mui/material';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import {useTripDebriefConfigCardStyles} from './TripDebriefConfigCard.style';
import {ITripDebriefConfigVisibleCards} from '../TripDebriefConfigVisibleCards';
import {ITripDebriefConfigRequiredFields} from '../TripDebriefConfigRequiredFields';

interface TripDebriefConfigCardProps {
  name:
    | keyof ITripDebriefConfigVisibleCards
    | keyof ITripDebriefConfigRequiredFields;
  title: string;
  toggle: 'visibility' | 'switch';
}

export const TripDebriefConfigCard: React.FC<TripDebriefConfigCardProps> = ({
  name,
  title,
  toggle,
}) => {
  const classes = useTripDebriefConfigCardStyles();
  const {isSubmitting, getFieldHelpers, getFieldMeta} = useFormikContext<
    ITripDebriefConfigVisibleCards | ITripDebriefConfigRequiredFields
  >();
  const {value, error} = getFieldMeta(name);
  const {setValue} = getFieldHelpers(name);

  const handleToggleVisibility = React.useCallback(
    () => setValue(!value),
    [setValue, value]
  );

  return (
    <Grid xs={12} sm={6} item>
      <Card
        className={clsx(classes.root, {[classes.invisible]: !value})}
        onClick={handleToggleVisibility}
      >
        <CardContent className={classes.content}>
          <Typography variant="h5" component="div">
            {title}
          </Typography>
        </CardContent>
        <CardActions className={classes.actions}>
          {error ? (
            <Tooltip title={error} arrow>
              <Badge variant="dot" color="error">
                {toggle === 'visibility' ? (
                  <IconButton disabled={isSubmitting}>
                    {value ? <Visibility color="primary" /> : <VisibilityOff />}
                  </IconButton>
                ) : (
                  <Switch checked={!!value} disabled={isSubmitting} />
                )}
              </Badge>
            </Tooltip>
          ) : toggle === 'visibility' ? (
            <IconButton disabled={isSubmitting}>
              {value ? <Visibility color="primary" /> : <VisibilityOff />}
            </IconButton>
          ) : (
            <Switch checked={!!value} disabled={isSubmitting} />
          )}
        </CardActions>
      </Card>
    </Grid>
  );
};
