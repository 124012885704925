import './planningBoardGantt.css';
import React from 'react';
import {IdType, TimelineOptionsComparisonFunction} from 'vis';
import {
  usePlanningBoardSettingsContext,
  usePlanningBoardContext,
  usePlanningBoardGanttContext,
} from '../../planningBoardContext';
import {getActualTrip, getMasterTripsWithSameVehicle} from '../../helpers';
import {getMasterTripsAfterCurrentTrip} from '../../helpers/getMasterTripsAfterCurrentTrip';
import {WebPlanningBoardMasterTripWithIdType} from '../../planningBoardContext';
import {VisTimeline} from '../../../timeline/VisTimeline';
import {PlanningBoardGanttDeleteDialog} from './PlanningBoardGanttDeleteDialog';

interface PlanningBoardGanttTimelineProps {
  maxHeight?: number | string;
}

export const PlanningBoardGanttTimeline: React.FC<
  PlanningBoardGanttTimelineProps
> = ({maxHeight}) => {
  const {enableCheckTripActuals} = usePlanningBoardSettingsContext();
  const {
    optimalMasterTrips,
    masterMasterTrips,
    bookingMasterTrips,
    planningMasterTrips,
    getOptimalMasterTripByVehicleId,
    startDate,
    endDate,
    hasPermission,
  } = usePlanningBoardContext();
  const {
    ganttGroups,
    ganttItems,
    onAdd,
    onClearSelectedGanttItem,
    onMove,
    onRemove,
    onDoubleClick,
    searchedTrips,
    sortVehiclesDirection,
    onSortByVehiclesAsc,
    onSortByVehiclesDesc,
    startSnap,
  } = usePlanningBoardGanttContext();

  const handleSearchByTripIds =
    React.useCallback<TimelineOptionsComparisonFunction>(
      (a, b) => {
        // Get the optimal trips in the current group (vehicle) for a and b
        const tripsInGroupA = getMasterTripsWithSameVehicle(
          masterMasterTrips
            .concat(bookingMasterTrips ?? [])
            .concat(planningMasterTrips ?? []),
          a?.id
        );
        const tripsInGroupB = getMasterTripsWithSameVehicle(
          masterMasterTrips
            .concat(bookingMasterTrips ?? [])
            .concat(planningMasterTrips ?? []),
          b?.id
        );

        let groupAHasSearchedTrip = false;
        let groupBHasSearchedTrip = false;

        tripsInGroupA.forEach(({id}) => {
          if (id && searchedTrips.includes(id)) {
            groupAHasSearchedTrip = true;
          }
        });

        tripsInGroupB.forEach(({id}) => {
          if (id && searchedTrips.includes(id)) {
            groupBHasSearchedTrip = true;
          }
        });

        if (groupAHasSearchedTrip && !groupBHasSearchedTrip) {
          return -1;
        } else if (!groupAHasSearchedTrip && groupBHasSearchedTrip) {
          return 1;
        }

        return sortVehiclesDirection === 'asc'
          ? onSortByVehiclesAsc(a, b)
          : onSortByVehiclesDesc(a, b);
      },
      [
        bookingMasterTrips,
        masterMasterTrips,
        onSortByVehiclesAsc,
        onSortByVehiclesDesc,
        planningMasterTrips,
        searchedTrips,
        sortVehiclesDirection,
      ]
    );

  const handleOptimalTripGroupOrdering =
    React.useCallback<TimelineOptionsComparisonFunction>(
      (a, b) => {
        // Get the optimal trips in the current group (vehicle) for a and b
        const tripsInGroupA = getOptimalMasterTripByVehicleId(a.id);
        const tripsInGroupB = getOptimalMasterTripByVehicleId(b.id);
        // If they haven't filtered for optimalTrips disregard the sorting and return 0
        if (optimalMasterTrips.length > 0 && tripsInGroupA && tripsInGroupB) {
          let shortestDistanceInA: number | undefined;
          let shortestDistanceInB: number | undefined;
          // Get the shortest distance for a trip in tripsInGroupA
          tripsInGroupA?.forEach(({trip}) => {
            if (!shortestDistanceInA && trip?.tripDistance) {
              shortestDistanceInA = trip.tripDistance;
            }
            if (
              shortestDistanceInA &&
              trip?.tripDistance &&
              trip.tripDistance < shortestDistanceInA
            ) {
              shortestDistanceInA = trip?.tripDistance;
            }
          });
          // Get the shortest distance for a trip in tripsInGroupB
          tripsInGroupB?.forEach(({trip}) => {
            if (!shortestDistanceInB && trip?.tripDistance) {
              shortestDistanceInB = trip.tripDistance;
            }
            if (
              shortestDistanceInB &&
              trip?.tripDistance &&
              trip.tripDistance < shortestDistanceInB
            ) {
              shortestDistanceInB = trip?.tripDistance;
            }
          });
          // When (a) has a shortestDistance and (b) doesn't, it means that group (vehicle) (a) has a trip,
          // but (b) doesn't have a trip option. And we want the vehicles with trip options to first and foremost be showed first
          if (shortestDistanceInA && !shortestDistanceInB) {
            return -1;
            // Otherwise do the opposite
          } else if (!shortestDistanceInA && shortestDistanceInB) {
            return 1;
          } else if (shortestDistanceInA && shortestDistanceInB) {
            // Then when they both have the shortest distances check which one is shorter,
            // and then display the shortest one first
            if (shortestDistanceInA < shortestDistanceInB) {
              return -1;
            } else {
              return 1;
            }
            // Otherwise if none of these are true, leave sorting unchanged
          } else {
            return sortVehiclesDirection === 'asc'
              ? onSortByVehiclesAsc(a, b)
              : onSortByVehiclesDesc(a, b);
          }
        }

        return sortVehiclesDirection === 'asc'
          ? onSortByVehiclesAsc(a, b)
          : onSortByVehiclesDesc(a, b);
      },
      [
        getOptimalMasterTripByVehicleId,
        onSortByVehiclesAsc,
        onSortByVehiclesDesc,
        optimalMasterTrips.length,
        sortVehiclesDirection,
      ]
    );

  const handleActualTripGroupOrdering =
    React.useCallback<TimelineOptionsComparisonFunction>(
      (a, b) => {
        // Get the optimal trips in the current group (vehicle) for a and b
        const tripsInGroupA = getMasterTripsWithSameVehicle(
          masterMasterTrips
            .concat(bookingMasterTrips ?? [])
            .concat(planningMasterTrips ?? []),
          a?.id
        );
        const tripsInGroupB = getMasterTripsWithSameVehicle(
          masterMasterTrips
            .concat(bookingMasterTrips ?? [])
            .concat(planningMasterTrips ?? []),
          b?.id
        );
        // If they haven't filtered for optimalTrips disregard the sorting and return 0
        if (tripsInGroupA && tripsInGroupB) {
          let mostUrgentPriorityInA: number | undefined;
          let mostUrgentPriorityInB: number | undefined;

          // Get the  most urgent priority for a trip in tripsInGroupA
          tripsInGroupA?.forEach((masterTrip) => {
            const masterTripsAfterCurrentTrip = getMasterTripsAfterCurrentTrip(
              tripsInGroupA,
              masterTrip
            );

            let nextMasterTripInA:
              | WebPlanningBoardMasterTripWithIdType
              | undefined;

            masterTripsAfterCurrentTrip?.forEach((masterTrip) => {
              const tripStart =
                masterTrip.trip?.stops && masterTrip.trip.stops.length > 0
                  ? masterTrip.trip.stops[masterTrip.trip.stops.length - 1]
                      ?.serviceTimeStart
                  : masterTrip.trip?.tripStart;

              if (nextMasterTripInA === undefined) {
                nextMasterTripInA = masterTrip;
              } else {
                const nextMasterTripStart =
                  nextMasterTripInA.trip?.stops &&
                  nextMasterTripInA.trip.stops.length > 0
                    ? nextMasterTripInA.trip.stops[
                        nextMasterTripInA.trip.stops.length - 1
                      ]?.serviceTimeStart
                    : nextMasterTripInA.trip?.tripStart;
                if (
                  tripStart &&
                  nextMasterTripStart &&
                  tripStart < nextMasterTripStart
                ) {
                  nextMasterTripInA = masterTrip;
                }
              }
            });
            const actualTripInfo = getActualTrip(masterTrip, nextMasterTripInA);
            if (actualTripInfo?.priority) {
              if (
                mostUrgentPriorityInA === undefined ||
                actualTripInfo.priority > mostUrgentPriorityInA
              ) {
                mostUrgentPriorityInA = actualTripInfo.priority;
              }
            }
          });

          // Get the most urgent priority for a trip in tripsInGroupB
          tripsInGroupB?.forEach((masterTrip) => {
            const masterTripsAfterCurrentTrip = getMasterTripsAfterCurrentTrip(
              tripsInGroupB,
              masterTrip
            );
            let nextMasterTripInB:
              | WebPlanningBoardMasterTripWithIdType
              | undefined;
            masterTripsAfterCurrentTrip?.forEach((masterTrip) => {
              const tripStart =
                masterTrip.trip?.stops && masterTrip.trip.stops.length > 0
                  ? masterTrip.trip.stops[masterTrip.trip.stops.length - 1]
                      ?.serviceTimeStart
                  : masterTrip.trip?.tripStart;

              if (nextMasterTripInB === undefined) {
                nextMasterTripInB = masterTrip;
              } else {
                const nextMasterTripStart =
                  nextMasterTripInB.trip?.stops &&
                  nextMasterTripInB.trip.stops.length > 0
                    ? nextMasterTripInB.trip.stops[
                        nextMasterTripInB.trip.stops.length - 1
                      ]?.serviceTimeStart
                    : nextMasterTripInB.trip?.tripStart;
                if (
                  tripStart &&
                  nextMasterTripStart &&
                  tripStart < nextMasterTripStart
                ) {
                  nextMasterTripInB = masterTrip;
                }
              }
            });
            const actualTripInfo = getActualTrip(masterTrip, nextMasterTripInB);
            if (actualTripInfo?.priority) {
              if (
                mostUrgentPriorityInB === undefined ||
                actualTripInfo.priority > mostUrgentPriorityInB
              ) {
                mostUrgentPriorityInB = actualTripInfo.priority;
              }
            }
          });

          // Then when they both have the shortest distances check which one is shorter,
          // and then display the shortest one first
          if (mostUrgentPriorityInA && mostUrgentPriorityInB === undefined) {
            return -1;
          } else if (
            mostUrgentPriorityInA === undefined &&
            mostUrgentPriorityInB
          ) {
            return 1;
          } else if (
            mostUrgentPriorityInA !== undefined &&
            mostUrgentPriorityInB !== undefined &&
            mostUrgentPriorityInA < mostUrgentPriorityInB
          ) {
            return 1;
          } else {
            return -1;
          }
          // Otherwise if none of these are true, leave sorting unchanged
        } else {
          return sortVehiclesDirection === 'asc'
            ? onSortByVehiclesAsc(a, b)
            : onSortByVehiclesDesc(a, b);
        }
      },
      [
        bookingMasterTrips,
        masterMasterTrips,
        onSortByVehiclesAsc,
        onSortByVehiclesDesc,
        planningMasterTrips,
        sortVehiclesDirection,
      ]
    );

  const handleSelection = React.useCallback(
    (items: IdType[]) => {
      if (items.length === 0) {
        onClearSelectedGanttItem();
        return [];
      }
      return [
        ...items,
        ...items.map((item) => `${item.toString().substr('actual'.length)}`),
        ...items.map((item) => `actual${item}`),
      ];
    },
    [onClearSelectedGanttItem]
  );

  const handleGroupOrder = React.useCallback(
    (a, b) =>
      searchedTrips.length > 0
        ? handleSearchByTripIds(a, b)
        : enableCheckTripActuals
        ? handleActualTripGroupOrdering(a, b)
        : optimalMasterTrips.length > 0
        ? handleOptimalTripGroupOrdering(a, b)
        : sortVehiclesDirection === 'asc'
        ? onSortByVehiclesAsc(a, b)
        : onSortByVehiclesDesc(a, b),
    [
      enableCheckTripActuals,
      handleActualTripGroupOrdering,
      handleOptimalTripGroupOrdering,
      handleSearchByTripIds,
      onSortByVehiclesAsc,
      onSortByVehiclesDesc,
      optimalMasterTrips.length,
      searchedTrips.length,
      sortVehiclesDirection,
    ]
  );

  return (
    <>
      <VisTimeline
        groups={ganttGroups}
        items={ganttItems}
        onDoubleClick={onDoubleClick}
        onSetSelection={handleSelection}
        selection={searchedTrips}
        moveTo={startSnap ? {time: startSnap} : undefined}
        options={{
          groupOrder: handleGroupOrder,
          orientation: 'top',
          verticalScroll: true,
          stack: true,
          editable: {
            add: hasPermission.edit,
            updateTime: hasPermission.edit,
            remove: hasPermission.edit,
            updateGroup: hasPermission.edit,
          },
          end: endDate?.toJSDate(),
          start: startDate?.toJSDate(),
          align: 'left',
          tooltip: {followMouse: true},
          maxHeight,
          onMove,
          onAdd,
          onRemove,
        }}
      />
      <PlanningBoardGanttDeleteDialog />
    </>
  );
};
