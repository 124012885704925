import React from 'react';
import {Tooltip, Typography} from '@mui/material';
import {LuxonService} from '../../../../service';
import {useContractSetupSummaryDetailsStyles} from './ContractSetupSummaryDetails.style';

export type valueTypes = Date | string | number | boolean | undefined | null;

export interface ContractSetupSummaryDetailProps {
  detail: {value: valueTypes; tooltip?: string};
}

export const ContractSetupSummaryDetail: React.FC<
  ContractSetupSummaryDetailProps
> = ({detail}) => {
  const classes = useContractSetupSummaryDetailsStyles();

  const handleGetValue = React.useCallback((value: valueTypes) => {
    if (value === undefined || value === null) {
      return '-';
    }

    if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No';
    }

    if (typeof value === 'object') {
      return LuxonService.toTimeOnly(value);
    }

    return value;
  }, []);

  if (detail.tooltip) {
    return (
      <Tooltip
        title={detail.tooltip}
        classes={{tooltip: classes.valueTooltip}}
        arrow
      >
        <Typography className={classes.value}>
          {handleGetValue(detail.value)}
        </Typography>
      </Tooltip>
    );
  }

  return (
    <Typography className={classes.value}>
      {handleGetValue(detail.value)}
    </Typography>
  );
};
