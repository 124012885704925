import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import {TableProgressCell} from '../components';

export const ProgressTypeProvider: React.ComponentType<
  DataTypeProviderProps
> = (props) => (
  <DataTypeProvider
    data-test="progress-provider"
    formatterComponent={TableProgressCell}
    {...props}
  />
);
