import React from 'react';
import {Menu, MenuItem, MenuProps} from '@mui/material';

interface IVantageMenuCoordinates {
  mouseX: number | null;
  mouseY: number | null;
}

export interface IVantageMenuRef {
  anchorEl: HTMLElement | null;
  coordinates: IVantageMenuCoordinates;
  isOpen?: boolean;
  onClick: (event: React.MouseEvent<any>) => void;
  onClose: () => void;
}

export interface IVantageMenuOption {
  label: string;
  value: number | string;
  onClick: (event: React.MouseEvent<any>, value: number | string) => void;
}

interface VantageMenuProps extends Partial<MenuProps> {
  options: IVantageMenuOption[];
  enableContextMenu?: boolean;
}

const initialCoordinates = {
  mouseX: null,
  mouseY: null,
};

export const VantageMenu = React.forwardRef<IVantageMenuRef, VantageMenuProps>(
  (props, ref) => {
    const {enableContextMenu, options} = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [coordinates, setCoordinates] = React.useState<{
      mouseX: null | number;
      mouseY: null | number;
    }>(initialCoordinates);

    const open = React.useMemo(
      () =>
        enableContextMenu ? coordinates.mouseY !== null : Boolean(anchorEl),
      [anchorEl, coordinates.mouseY, enableContextMenu]
    );

    const handleClose = React.useCallback(() => {
      setCoordinates(initialCoordinates);
    }, []);

    const handleClick = React.useCallback(
      (event: React.MouseEvent<any>) => {
        enableContextMenu
          ? setCoordinates({
              mouseX: event.clientX - 2,
              mouseY: event.clientY - 4,
            })
          : setAnchorEl(event.currentTarget);
      },
      [enableContextMenu]
    );

    React.useImperativeHandle(ref, () => ({
      anchorEl,
      coordinates,
      isOpen: open,
      onClick: handleClick,
      onClose: handleClose,
    }));

    return (
      <Menu
        keepMounted
        open={open}
        onClose={handleClose}
        anchorReference={enableContextMenu ? 'anchorPosition' : 'anchorEl'}
        anchorPosition={
          coordinates.mouseY !== null && coordinates.mouseX !== null
            ? {top: coordinates.mouseY, left: coordinates.mouseX}
            : undefined
        }
        anchorEl={anchorEl}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            onClick={(event) => {
              handleClose();
              option.onClick(event, option.value);
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    );
  }
);
