import React from 'react';
import {Divider, Paper, Stack, Typography} from '@mui/material';
import {PlanningBoardToolbar} from '../planningBoardToolbar';
import {
  usePlanningBoardContext,
  usePlanningBoardGanttContext,
} from '../planningBoardContext';
import {PlanningBoardOptions} from '../planningBoardOptions';
import {PlanningBoardBottomPanel} from './PlanningBoardBottomPanel';
import {PlanningBoardRightPanel} from './PlanningBoardRightPanel';
import {PlanningBoardGanttPanel} from './PlanningBoardGanttPanel';
import {usePlanningBoardLayoutStyles} from './PlanningBoardPanelsStyles';

export const PlanningBoardPanels: React.FC = () => {
  const classes = usePlanningBoardLayoutStyles();
  const {initialised} = usePlanningBoardContext();
  const {selectedGanttItem} = usePlanningBoardGanttContext();

  if (!initialised) {
    return (
      <Paper className={classes.containerPadding}>
        <Typography variant="subtitle1">
          Select at least one contract first
        </Typography>
        <Divider />
        <PlanningBoardOptions />
      </Paper>
    );
  }

  return (
    <Paper
      style={{
        display: 'flex',
        flex: 1,
        minHeight: '100%',
        minWidth: '100%',
        flexDirection: 'column',
        justifyContent: 'flex-start',
      }}
    >
      <Stack flex={1} mr={selectedGanttItem ? '40rem' : 0}>
        {selectedGanttItem ? null : <PlanningBoardToolbar />}
        <PlanningBoardGanttPanel focused={!!selectedGanttItem} />
        {selectedGanttItem ? null : <PlanningBoardBottomPanel />}
      </Stack>
      <PlanningBoardRightPanel />
    </Paper>
  );
};
