import React from 'react';
import clsx from 'clsx';
import {Divider, Typography, Tooltip} from '@mui/material';
import {VehicleCompartmentWithOrderLines} from './PlanningBoardGanttCompartmentTruck';
import {usePlanningBoardGanttCompartmentTruckCompartmentStyles} from './PlanningBoardGanttCompartmentTruckCompartment.style';
import {PlanningBoardGanttCompartmentTruckOrderFill} from './PlanningBoardGanttCompartmentTruckOrderFill';
import {usePlanningBoardContext} from '../../../../planningBoardContext';
import {usePlanningBoardTripCompartmentsContext} from '../planningBoardTripCompartmentsContext';

interface PlanningBoardGanttCompartmentTruckCompartmentProps {
  compartmentWithOrderLines: VehicleCompartmentWithOrderLines;
  horizontalLevel?: boolean;
}

export const PlanningBoardGanttCompartmentTruckCompartment: React.FC<
  PlanningBoardGanttCompartmentTruckCompartmentProps
> = ({compartmentWithOrderLines, horizontalLevel}) => {
  const classes = usePlanningBoardGanttCompartmentTruckCompartmentStyles();
  const {hasPermission} = usePlanningBoardContext();
  const {overCompartment, onDragOver, onDragLeave, onDrop} =
    usePlanningBoardTripCompartmentsContext();
  const lines = React.useMemo(
    () => compartmentWithOrderLines.lines,
    [compartmentWithOrderLines.lines]
  );
  const [dipstickReading, setDipstickReading] = React.useState<
    number | undefined
  >();
  const totalCapacityAllocated = React.useMemo(
    () =>
      lines?.reduce(
        (acc, {lineCompartment}) => acc + (lineCompartment.fill ?? 0),
        0
      ) ?? 0,
    [lines]
  );

  React.useEffect(() => {
    let hasDipstick = false;
    lines?.forEach(({lineCompartment}) => {
      if (lineCompartment.dipStickReading !== undefined) hasDipstick = true;
    });
    if (hasDipstick) {
      setDipstickReading(
        lines?.reduce(
          (acc, {lineCompartment}) =>
            acc + (lineCompartment.dipStickReading ?? 0),
          0
        ) ?? 0
      );
    }
    return () => setDipstickReading(undefined);
  }, [lines]);

  return (
    <Tooltip
      classes={{
        tooltip: clsx(classes.tooltip, {
          [classes.tooltipDisabled]:
            (lines && lines.length > 0) || horizontalLevel,
        }),
        arrow: classes.tooltipArrow,
      }}
      title={
        <div className={classes.tooltipContent}>
          <Typography variant="subtitle1" color="textPrimary">
            {compartmentWithOrderLines.name}
          </Typography>
          <Divider />
          <Typography variant="subtitle2" color="textPrimary">
            Loading {compartmentWithOrderLines.loadingSequence}
          </Typography>
          <Typography variant="subtitle2" color="textPrimary">
            Offloading {compartmentWithOrderLines.offloadingSequence}
          </Typography>
          <Typography variant="subtitle2" color="textPrimary">
            Position {compartmentWithOrderLines.position}
          </Typography>
        </div>
      }
      arrow
    >
      <div
        className={clsx(classes.compartment, {
          [classes.compartmentActive]:
            overCompartment?.id === compartmentWithOrderLines.id,
        })}
        onDragOver={
          hasPermission.edit ? onDragOver(compartmentWithOrderLines) : undefined
        }
        onDragLeave={
          hasPermission.edit
            ? onDragLeave(compartmentWithOrderLines)
            : undefined
        }
        onDrop={
          hasPermission.edit ? onDrop(compartmentWithOrderLines) : undefined
        }
      >
        <div className={classes.fillContainer}>
          {lines?.map((line, index) => (
            <PlanningBoardGanttCompartmentTruckOrderFill
              key={index}
              orderLineCompartment={line}
              horizontalLevel={horizontalLevel}
              totalCapacityAllocated={totalCapacityAllocated}
            />
          ))}
        </div>
        {horizontalLevel && (
          <div className={classes.content} id="compartmentContent">
            <Typography variant="subtitle1">
              <strong>{compartmentWithOrderLines.name}</strong>
            </Typography>
            <div className={classes.divider} />
            <Typography gutterBottom>
              Loading: {compartmentWithOrderLines.loadingSequence}
            </Typography>
            <Typography gutterBottom>
              Offloading: {compartmentWithOrderLines.offloadingSequence}
            </Typography>
            <Typography gutterBottom>
              Capacity: {compartmentWithOrderLines.capacity}
            </Typography>
            {dipstickReading !== undefined && (
              <Typography gutterBottom>
                Dipstick Reading: {dipstickReading}
                cm
              </Typography>
            )}
          </div>
        )}
      </div>
    </Tooltip>
  );
};
