import React from 'react';
import {VehicleTypeCapacityContext} from './VehicleTypeCapacityContext';
import {useParams} from 'react-router-dom';

interface IParams {
  capacityId?: string;
}

export const VehicleTypeCapacityIdHandler: React.FC = () => {
  const {setVehicleTypeCapacityId} = React.useContext(
    VehicleTypeCapacityContext
  );
  const {capacityId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = capacityId ? parseInt(capacityId) : undefined;
    setVehicleTypeCapacityId(parsedId);
    return () => setVehicleTypeCapacityId(undefined);
  }, [capacityId, setVehicleTypeCapacityId]);

  return null;
};
