import React from 'react';
import {MasterTripRecipient} from './MasterTripRecipient';
import {useMasterTripRecipientControllerStyles} from './MasterTripRecipientController.style';
import {authStore} from '../../../store';
import {
  MasterTripRecipientContext,
  RecipientContextProvider,
} from './MasterTripRecipientContext';
import {useParams} from 'react-router-dom';
import {Loader} from '../../loader';

const RecipientControllerComponent: React.FC = () => {
  const auth = authStore.getAuth;
  const classes = useMasterTripRecipientControllerStyles();
  const {loading} = React.useContext(MasterTripRecipientContext);

  if (loading) return <Loader />;

  if (auth && (auth.authenticated || auth.oauthAuthenticated))
    return <MasterTripRecipient />;

  return (
    <div className={classes.root}>
      <header className={classes.header} data-cy="RecipientPublicHeader" />
      <main className={classes.mainContent}>
        <MasterTripRecipient />
      </main>
    </div>
  );
};

interface IRecipientParams {
  uuid: string;
}

export const MasterTripRecipientController: React.FC = () => {
  const {uuid} = useParams<IRecipientParams>();
  return (
    <RecipientContextProvider uuid={uuid}>
      <RecipientControllerComponent />
    </RecipientContextProvider>
  );
};
