import React from 'react';
import {
  TemplateTable,
  TemplateTableConfig,
} from '../../../../../factory/template';
import {Order as OrderType} from '@onroadvantage/onroadvantage-api';

interface TripEditOrderTableProps {
  tripOrders: OrderType[];
  loading?: boolean;
  onAdd: () => void;
  onOrdersRemove: (order: OrderType) => void;
}

export const TripEditOrderTable: React.FC<TripEditOrderTableProps> = ({
  tripOrders,
  loading,
  onAdd,
  onOrdersRemove,
}) => {
  const [config] = React.useState<TemplateTableConfig<OrderType>>({
    columns: [
      {
        name: 'orderNumber',
        label: 'Number',
        type: 'number',
        enableFilter: false,
      },
      {
        name: 'upliftPoint',
        label: 'Uplift Point',
        type: 'string',
        getValue: ({upliftPoint}) => upliftPoint.name,
        enableFilter: false,
      },
      {
        name: 'offloadPoint',
        label: 'Offload Point',
        type: 'string',
        getValue: ({offloadPoint}) => offloadPoint.name,
        enableFilter: false,
      },
    ],
    enableFilter: true,
    disablePagination: true,
  });

  return (
    <TemplateTable
      config={config}
      currentPage={1}
      list={tripOrders ?? []}
      loading={loading}
      onAdd={onAdd}
      onDelete={onOrdersRemove}
      elevation={0}
    />
  );
};
