import React from 'react';
import {EventsContextProvider} from './EventsContext';
import {DateRangeProvider} from './DateRangeContext';
import {AuthConnectionContextProvider} from './AuthConnectionContext';
import {VantageFrameContextProvider} from '../components/navigation/VantageFrameContext';

export const GlobalAuthedContextProvider: React.FC = ({children}) => (
  <AuthConnectionContextProvider>
    <VantageFrameContextProvider>
      <EventsContextProvider>
        <DateRangeProvider>{children}</DateRangeProvider>
      </EventsContextProvider>
    </VantageFrameContextProvider>
  </AuthConnectionContextProvider>
);
