import React from 'react';
import {IconButton, Tooltip} from '@mui/material';
import {GridOff, GridOn, Sync} from '@mui/icons-material';
import {useTripEditContext} from '../tripEditContext';

export const TripEditStopListActions: React.FC = () => {
  const {
    isSortable,
    editStopSequence,
    toggleEditStopSequence,
    syncingStopsWithActuals,
    onSyncStopsWithActuals,
  } = useTripEditContext();

  const handleSyncStopsWithActuals = React.useCallback(async () => {
    await onSyncStopsWithActuals();
  }, [onSyncStopsWithActuals]);

  return (
    <>
      <IconButton
        disabled={!isSortable}
        color={editStopSequence ? 'secondary' : undefined}
        onClick={toggleEditStopSequence}
      >
        {editStopSequence ? <GridOn /> : <GridOff />}
      </IconButton>
      <Tooltip title="Sync stops with actuals" arrow>
        <IconButton
          disabled={syncingStopsWithActuals}
          onClick={handleSyncStopsWithActuals}
        >
          <Sync />
        </IconButton>
      </Tooltip>
    </>
  );
};
