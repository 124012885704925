import React from 'react';
import {ProfileContext} from './ProfileContext';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../factory/template';
import {Profile} from '@onroadvantage/onroadvantage-api';

export const ProfileList: React.FC = () => {
  const {loadList, cleanupList} = React.useContext(ProfileContext);

  const [config] = React.useState<TemplateTableConfig<Profile>>({
    columns: [
      {name: 'name', label: 'Profile Name', type: 'string'},
      {name: 'description', label: 'Profile Description', type: 'string'},
    ],
    enableFilter: true,
    enableSort: true,
    identifier: 'PROFILE_LIST',
  });

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [loadList, cleanupList]);

  return <TemplateTableWrapper config={config} context={ProfileContext} />;
};
