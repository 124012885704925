import React from 'react';
import {Card, CardHeader, CardContent, Avatar} from '@mui/material';
import {ClearAll} from '@mui/icons-material';
import {inject, observer} from 'mobx-react';
import Timeline from '../../timeline/timeline';
import {Loader} from '../../loader';
import TimelinePlayer from '../../map/TimelinePlayer';
import {timelineDisplayStore} from '../../../stores/mobxStores';

class VehicleGantt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actualTrip: {},
      expanded: true,
      isPlaying: false,
    };
    this.timer = null;
  }

  nextFrame = () => {
    timelineDisplayStore.nextFrameTimelineSliderTime();
  };

  togglePlaying = () => {
    const {isPlaying} = this.state;
    if (isPlaying) {
      this.setState({isPlaying: false});
      clearInterval(this.timer);
    } else {
      this.setState({isPlaying: true});
      this.nextFrame();
      this.timer = setInterval(() => {
        this.nextFrame();
      }, 1000);
    }
  };

  clickNextFrame = () => {
    this.nextFrame();
  };

  prevFrame = () => {
    timelineDisplayStore.prevFrameTimelineSliderTime();
  };

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    const {isPlaying} = this.state;

    const {actualTrip, updateTelematicsEventsRange} = this.props;

    const {telematicsLoading} = timelineDisplayStore;

    const playback = {
      togglePlaying: this.togglePlaying,
      nextFrame: this.clickNextFrame,
      prevFrame: this.prevFrame,
      isPlaying,
    };

    return (
      <Card style={this.props.style}>
        <CardHeader
          title="Gantt Chart"
          avatar={
            <Avatar aria-label="TripGantt">
              <ClearAll />
            </Avatar>
          }
          action={
            telematicsLoading ? (
              <Loader size={40} />
            ) : (
              <TimelinePlayer playback={playback} />
            )
          }
        />
        <CardContent>
          <Timeline
            key={updateTelematicsEventsRange.startDate}
            playback={playback}
            loading={telematicsLoading}
            trip={{}}
            actualTrip={actualTrip}
            updateTelematicsEventsRange={updateTelematicsEventsRange}
            disableActualNestedGroups
          />
        </CardContent>
      </Card>
    );
  }
}

export default inject('timelineDisplayStore')(observer(VehicleGantt));
