import React from 'react';
import {InputAdornment, TextField} from '@mui/material';
import {useTripContext} from '../../tripContext';
import {tripApi} from '../../../../api';
import {useAppNotifications} from '../../../../contexts';
import {Loader} from '../../../loader';
import {RoleService} from '../../../../service';
import {TripSummaryEditDialog} from './TripSummaryEditDialog';

export const TripSummaryEditTripNumber: React.FC = () => {
  const notify = useAppNotifications();
  const {masterTrip, masterTripId, updateMasterTrip} = useTripContext();
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [tripNumber, setTripNumber] = React.useState<string | undefined>(
    masterTrip?.tripNumber ?? masterTrip?.trip?.tripNumber
  );

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setTripNumber(event.target.value);
    },
    []
  );

  const handleSubmit = React.useCallback(async () => {
    setSubmitting(true);
    try {
      if (masterTripId) {
        const response = await tripApi.apiMasterTripMasterTripIdTripNumberPatch(
          {
            masterTripId,
            body: {tripNumber},
          }
        );
        if (response.id) {
          notify('success', 'Trip Number Updated');
          updateMasterTrip(response);
        } else notify('error', 'Trip Number already exists.');
      }
    } catch (e) {
      notify('error', e.message ?? 'Failed to update trip number');
    } finally {
      setSubmitting(false);
    }
  }, [masterTripId, notify, tripNumber, updateMasterTrip]);

  React.useEffect(() => {
    setTripNumber(masterTrip?.tripNumber ?? masterTrip?.trip?.tripNumber);
    return () => setTripNumber(undefined);
  }, [masterTrip?.trip?.tripNumber, masterTrip?.tripNumber]);

  return (
    <TripSummaryEditDialog
      title="Trip Number"
      hasPermission={RoleService.hasPermission('Edit TripNumber', 'Edit')}
      loading={submitting}
      onAccept={handleSubmit}
    >
      <TextField
        disabled={submitting}
        value={tripNumber}
        onChange={handleChange}
        margin="normal"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Loader size={32} loading={submitting} />
            </InputAdornment>
          ),
        }}
        fullWidth
      />
    </TripSummaryEditDialog>
  );
};
