import React, {useState} from 'react';
import {Tab, Tabs} from '@mui/material';
import {useTemplateTabControllerStyles} from '../../factory/template/tabs/TemplateTabs.style';
import {NotificationConfigOperationalEscalationTriggersList} from './notificationConfigOperationalEscalation/NotificationConfigOperationalEscalationTriggersList';
import {NotificationConfigCriticalEscalationTriggersList} from './notificationConfigCriticalEscalation/NotificationConfigCriticalEscalationTriggersList';
const NotificationConfigEscalationTriggers: React.FC = () => {
  const classes = useTemplateTabControllerStyles();
  const [tabValue, setTabValue] = useState<number>(0);

  const handleTabChange = (event: React.SyntheticEvent, value: number) => {
    setTabValue(value);
  };

  return (
    <>
      <Tabs
        className={classes.tabs}
        value={tabValue}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab
          classes={{root: classes.tab}}
          label="Critical Escalation  Triggers"
        />
        <Tab
          classes={{root: classes.tab}}
          label="Operational Escalation Triggers"
        />
      </Tabs>
      {tabValue === 0 && <NotificationConfigCriticalEscalationTriggersList />}
      {tabValue === 1 && (
        <NotificationConfigOperationalEscalationTriggersList />
      )}
    </>
  );
};

export default NotificationConfigEscalationTriggers;
