import React from 'react';
import {TextFieldProps} from '@mui/material';
import {FormikTimePickerContext} from './FormikTimePicker';
import {FormikPickerField} from './FormikPickerField';
import {useFormikContext} from 'formik';

export const FormikTimePickerField = (
  props: TextFieldProps
): React.ReactElement => {
  const {name, InputLabelProps} = React.useContext(FormikTimePickerContext);
  const {getFieldMeta} = useFormikContext();
  const {error, touched} = getFieldMeta(name);
  return (
    <FormikPickerField
      {...props}
      name={name}
      displayError={touched ? error : undefined}
      InputLabelProps={InputLabelProps}
    />
  );
};
