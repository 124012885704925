import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from '@mui/material';

const EventCardAddActivityStyles = (theme: Theme) =>
  createStyles({
    root: {padding: 0},
    content: {padding: 0},
    container: {paddingTop: theme.spacing(2), paddingBottom: theme.spacing(2)},
  });

export const useEventCardAddActivityStyles = makeStyles(
  EventCardAddActivityStyles,
  {name: 'EventCardAddActivityStyles'}
);
