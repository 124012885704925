import React from 'react';
import {IconButton} from '@mui/material';
import {useInternalTableContext} from '../../TemplateTable';

interface TableActionButtonBaseProps {
  disabled?: boolean;
  onAuxClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const TableActionButtonBase: React.FC<TableActionButtonBaseProps> = ({
  disabled,
  onAuxClick,
  onClick,
  children,
  ...otherProps
}) => {
  const {loading} = useInternalTableContext();

  return (
    <IconButton
      {...otherProps}
      disabled={loading || disabled}
      onAuxClick={onAuxClick}
      onClick={onClick}
    >
      {children}
    </IconButton>
  );
};
