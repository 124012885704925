import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

const TripEditStopListStyles = (_theme: Theme) =>
  createStyles({
    row: {paddingLeft: 0, height: 50},
    sortableRow: {
      cursor: 'move',
      paddingLeft: 0,
      height: 50,
      backgroundColor: 'rgba(143,188,143,0.45)',
    },
    newRow: {
      paddingLeft: 0,
      height: 50,
      backgroundColor: 'rgba(180,191,195,0.2)',
    },
  });

export const useTripEditStopListStyles = makeStyles(TripEditStopListStyles, {
  name: 'TripEditStopListStyles',
});
