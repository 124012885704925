import React from 'react';
import {Route, Switch} from 'react-router-dom';
import _ from 'lodash';
import {useVehicleControllerStyles} from './VehicleController.style';
import {
  VehicleContext,
  VehicleContextProps,
  VehicleContextProvider,
} from './VehicleContext';
import {
  BreadcrumbController,
  BreadcrumbRoute,
} from '../breadcrumbs/BreadcrumbController';
import {VehicleList} from './VehicleList';
import {VehicleIdHandler} from './VehicleIdHandler';
import {Vehicle} from './Vehicle';
import {AllowedRoute} from '../router';
import {VehicleForm} from './vehicleDetails';

export const VehicleControllerComponent: React.FC = () => {
  const classes = useVehicleControllerStyles();
  const {vehicle, loading} =
    React.useContext<VehicleContextProps>(VehicleContext);

  const routes: BreadcrumbRoute[] = [
    {
      path: '/vehiclelist',
      matchOptions: undefined,
      title: 'Vehicle List',
    },
    {
      path: '/vehiclelist/add',
      matchOptions: undefined,
      title: 'New Vehicle',
    },
    {
      path: '/vehiclelist/:vehicleId',
      matchOptions: undefined,
      title: (params) =>
        loading
          ? 'Vehicle Loading...'
          : vehicle?.registrationNumber?.toString() ??
            `Vehicle ${_.get(params, 'vehicleId', 'Single')}`,
    },
  ];

  return (
    <div className={classes.root}>
      <BreadcrumbController routes={routes} />
      <div className={classes.container}>
        <Route exact path="/vehiclelist" component={VehicleList} />
        <Route path="/vehiclelist/:vehicleId" component={VehicleIdHandler} />
        <Switch>
          <AllowedRoute
            exact
            path="/vehiclelist/add"
            name="Add Vehicle"
            type="Add"
            component={() => <VehicleForm isAdd />}
          />
          <Route exact path="/vehiclelist/:vehicleId" component={Vehicle} />
        </Switch>
      </div>
    </div>
  );
};

export const VehicleController: React.FC = () => {
  return (
    <VehicleContextProvider>
      <VehicleControllerComponent />
    </VehicleContextProvider>
  );
};
