import React from 'react';
import loginBackgroundAnimation from '../../img/json/loginBackground.json';
import Lottie from 'react-lottie';

export const AuthenticationBackground: React.FC = () => (
  <Lottie
    options={{
      loop: true,
      autoplay: true,
      animationData: loginBackgroundAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    }}
    height="100vh"
    isStopped={false}
    isPaused={false}
    isClickToPauseDisabled
    style={{zIndex: -1}}
  />
);
