import React from 'react';
import {
  MobileDatePicker,
  MobileDatePickerProps,
  StaticDatePicker,
  CalendarPickerView,
} from '@mui/x-date-pickers';
import {useFormikContext} from 'formik';
import moment, {Moment} from 'moment';
import {InputLabelProps} from '@mui/material';
import {FormikDatePickerField} from './FormikDatePickerField';

export interface FormikDatePickerProps
  extends Omit<
    MobileDatePickerProps<Date, Date>,
    'value' | 'onChange' | 'renderInput'
  > {
  id?: string;
  name: string;
  displayStaticWrapperAs?: 'desktop' | 'mobile';
  InputLabelProps?: Partial<InputLabelProps>;
  fullWidth?: boolean;
  openTo?: CalendarPickerView;
  disableDefaultValue?: boolean;
  staticMode?: boolean;
}

export const FormikDatePickerContext =
  React.createContext<FormikDatePickerProps>({name: ''});

export const FormikDatePicker: React.FC<FormikDatePickerProps> = (props) => {
  return (
    <FormikDatePickerContext.Provider value={props}>
      <FormikDatePickerComponent />
    </FormikDatePickerContext.Provider>
  );
};

const FormikDatePickerComponent: React.FC = () => {
  const {name, disableDefaultValue, disabled, ...otherProps} = React.useContext(
    FormikDatePickerContext
  );
  const {isSubmitting, getFieldMeta, getFieldHelpers} = useFormikContext();
  const {value, ...meta} = getFieldMeta(name);
  const {setValue} = getFieldHelpers(name);

  const [parsedValue, setParsedValue] = React.useState<Moment | null>(null);

  React.useEffect(() => {
    if (!value && !disableDefaultValue) {
      setParsedValue(moment());
      setValue(moment());
    } else if (value) {
      setParsedValue(moment(value as Date));
    }
    return () => setParsedValue(null);
    // eslint-disable-next-line
  }, []);

  const handleChange = React.useCallback(
    (date: Date | null) => {
      setValue(date);
      setParsedValue(moment(date));
    },
    [setValue]
  );

  return (
    <HandleDatePickerState
      {...otherProps}
      {...meta}
      disabled={disabled || isSubmitting}
      onChange={handleChange}
      name={name}
      value={parsedValue?.toDate() ?? null}
      renderInput={FormikDatePickerField}
    />
  );
};

export const HandleDatePickerState: React.FC<
  FormikDatePickerProps & MobileDatePickerProps<Date, Date>
> = ({staticMode, displayStaticWrapperAs, ...props}) => {
  return staticMode ? (
    <StaticDatePicker
      {...props}
      displayStaticWrapperAs={displayStaticWrapperAs}
    />
  ) : (
    <MobileDatePicker {...props} />
  );
};
