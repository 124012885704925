import React from 'react';
import {useTemplateStepperContentStyles} from './TemplateStepperContent.style';
import {Paper, PaperProps} from '@mui/material';

export const TemplateStepperContent: React.FC<PaperProps> = ({
  children,
  ...props
}) => {
  const classes = useTemplateStepperContentStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.content} {...props}>
        {children}
      </Paper>
    </div>
  );
};
