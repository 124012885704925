import React from 'react';
import {ProductGroupContext} from './ProductGroupContext';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../factory/template';
import {ProductGroup} from '@onroadvantage/onroadvantage-api';

export const ProductGroupList: React.FC = () => {
  const {loadList, cleanupList} = React.useContext(ProductGroupContext);

  const [config] = React.useState<TemplateTableConfig<ProductGroup>>({
    columns: [
      {name: 'name', label: 'Name', type: 'string'},
      {name: 'description', label: 'Description', type: 'string'},
    ],
    enableFilter: true,
    enableSort: true,
    deleteMessage: (row) => ({
      title: 'Product Group',
      items: {Name: row.name, Description: row.description},
    }),
    identifier: 'PRODUCT_GROUP_LIST',
  });

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [loadList, cleanupList]);

  return <TemplateTableWrapper config={config} context={ProductGroupContext} />;
};
