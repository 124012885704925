import React from 'react';
import {TaskContext} from '../TaskContext';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../../factory/template';
import {TaskView as TaskViewType} from '@onroadvantage/onroadvantage-api';

export const TaskList: React.FC = () => {
  const {loadList} = React.useContext(TaskContext);

  const [config] = React.useState<TemplateTableConfig<TaskViewType>>({
    columns: [
      {name: 'templateName', label: 'Name', type: 'string'},
      {name: 'templateTypeName', label: 'Description', type: 'string'},
      {name: 'vehicleRegistrationNumber', label: 'Vehicle Reg', type: 'string'},
      {name: 'driverTagNumber', label: 'Driver Tag', type: 'string'},
      {name: 'tripNumber', label: 'Trip Number', type: 'string'},
      {name: 'orderNumber', label: 'Order Number', type: 'string'},
      {name: 'status', label: 'Status', type: 'string'},
    ],
    enableFilter: true,
    enableSort: false,
    identifier: 'TASK_LIST',
  });

  React.useEffect(() => {
    loadList();
  }, [loadList]);

  return <TemplateTableWrapper config={config} context={TaskContext} />;
};
