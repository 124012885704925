import React from 'react';
import {useTemplateTabControllerStyles} from './TemplateTabs.style';
import {BadgeProps, Paper, Tab, TabProps, Tabs} from '@mui/material';
import {getQueryStringValue} from '../../../service/Util';
import {useHistory, useLocation} from 'react-router-dom';
import {
  TemplateTabsAction,
  TemplateTabControllerActionProps,
} from './TemplateTabsAction';
import {Loader} from '../../../components/loader';
import {VantageBadge} from '../../../components/badge';
import {IPermission} from '../form';
import {RoleService} from '../../../service';

export interface ITemplateTabControllerTab extends TabProps {
  badge?: React.ReactNode;
  badgeProps?: BadgeProps;
  element?: React.ReactNode;
  hidden?: boolean;
  permission?: IPermission;
  title: string;
}

export interface ITemplateTabControllerAction
  extends Omit<TemplateTabControllerActionProps, 'tabValue'> {
  placement: 'left' | 'right';
}

export interface TemplateTabControllerProps {
  path: string;
  tabs: ITemplateTabControllerTab[];
  actions?: ITemplateTabControllerAction[];
  endActions?: React.ReactNode;
  elevation?: number;
  loading?: boolean;
  loaderText?: string;
}

export const TemplateTabs: React.FC<TemplateTabControllerProps> = ({
  path,
  tabs,
  elevation,
  actions,
  endActions,
  loading,
  loaderText,
}) => {
  const classes = useTemplateTabControllerStyles();
  const history = useHistory();
  const {search} = useLocation();
  const [tabValue, setTabValue] = React.useState<unknown | undefined>(
    tabs[0]?.title
  );

  const leftActions = React.useMemo(
    () => actions?.filter((action) => action.placement === 'left'),
    [actions]
  );
  const rightActions = React.useMemo(
    () => actions?.filter((action) => action.placement === 'right'),
    [actions]
  );

  const handleTabChange = React.useCallback(
    (event: any, value: number) => {
      history.push(`${path}?tab=${value}`);
    },
    [history, path]
  );

  React.useEffect(() => {
    const tab = getQueryStringValue(search, 'tab');
    if (tab) {
      setTabValue(tab);
    }
    return () => {
      setTabValue(undefined);
    };
  }, [search]);

  return (
    <Paper className={classes.root} elevation={elevation}>
      <div className={classes.tabBar}>
        {leftActions?.map((action, index) => (
          <TemplateTabsAction key={index} tabValue={tabValue} {...action} />
        ))}
        <Tabs
          className={classes.tabs}
          value={tabValue}
          classes={{scroller: classes.tabs}}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
        >
          {tabs
            .reduce(
              (
                acc: ITemplateTabControllerTab[],
                tab: ITemplateTabControllerTab
              ) => {
                if (
                  tab.permission != null &&
                  !RoleService.hasPermission(
                    tab.permission.name,
                    tab.permission.type
                  )
                ) {
                  return acc;
                }
                return [...acc, tab];
              },
              []
            )
            .map(
              ({badge, badgeProps, hidden, title, ...tabProps}) =>
                !hidden && (
                  <Tab
                    classes={{root: classes.tab}}
                    key={title}
                    label={
                      <VantageBadge
                        color="primary"
                        max={999}
                        badgeContent={badge}
                        {...badgeProps}
                      >
                        {title}
                      </VantageBadge>
                    }
                    value={title}
                    {...tabProps}
                  />
                )
            )}
        </Tabs>
        {rightActions?.map((action, index) => (
          <TemplateTabsAction key={index} tabValue={tabValue} {...action} />
        ))}
        {endActions ?? null}
      </div>
      {loading ? (
        <Loader
          text={loaderText ?? 'Rendering Content...'}
          classes={{loaderContainer: classes.loaderContainer}}
        />
      ) : (
        tabs.find(({title}) => tabValue === title)?.element
      )}
    </Paper>
  );
};
