import React from 'react';
import {Stack} from '@mui/material';
import {PlanningBoardToolbarSearchEndingSite} from './PlanningBoardToolbarSearchEndingSite';
import {PlanningBoardToolbarSearchOrder} from './PlanningBoardToolbarSearchOrder';
import {
  PlanningBoardToolbarSearchToggle,
  TPlanningBoardSelectedSearchOptions,
} from './PlanningBoardToolbarSearchToggle';
import {PlanningBoardToolbarSearchSite} from './PlanningBoardToolbarSearchSite';

export const PlanningBoardToolbarSearch: React.FC = () => {
  const [selectedSearchOption, setSelectedSearchOption] =
    React.useState<TPlanningBoardSelectedSearchOptions>('order');

  return (
    <Stack
      flex={1}
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
      spacing={0.5}
      maxWidth="20rem"
      minWidth="13rem"
    >
      {selectedSearchOption === 'endingSite' ? (
        <PlanningBoardToolbarSearchEndingSite />
      ) : selectedSearchOption === 'site' ? (
        <PlanningBoardToolbarSearchSite />
      ) : (
        <PlanningBoardToolbarSearchOrder />
      )}
      <PlanningBoardToolbarSearchToggle
        selectedSearchOption={selectedSearchOption}
        setSelectedSearchOption={setSelectedSearchOption}
      />
    </Stack>
  );
};
