import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Typography,
  Paper,
  Divider,
} from '@mui/material';
import styled from '@emotion/styled';
import {MapSelectionItem} from './AdhocMapSelectStep';
import {MomentService} from '../../../service';
import {mergeDateAndTime} from '../../../service/Util';
import {mapDisplayStore} from '../../../stores/mobxStores';

const SummaryTitle = styled.div({
  color: '#000000',
  fontFamily: 'Roboto',
  fontSize: 18,
  marginBottom: 5,
});

export interface BroadCastInfoType {
  specificItems: MapSelectionItem[];
  messageType: any[];
  message?: string;
  uploadIdentifier?: string;
  identifier?: string;
  heading?: string;
  scheduledDate?: Date;
  scheduledTime?: Date;
}

interface Props {
  broadcastInfo: BroadCastInfoType;
  onNext: () => void;
  onBack: () => void;
}

export default class AdhocMapConfirmBroadcastStep extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  onNext = async () => {
    this.setState({}, () => {
      mapDisplayStore.setGeoSelectionItems([]);
      this.props.onNext();
    });
  };

  onBack = async () => {
    this.props.onBack();
  };

  render() {
    const disabled = false;
    const {specificItems, message, heading, scheduledTime, scheduledDate} =
      this.props.broadcastInfo;
    const mergedDateTime = mergeDateAndTime({
      date: scheduledDate,
      time: scheduledTime,
    });

    return (
      <>
        <CardHeader title="Confirm Broadcast" />
        <Paper
          elevation={0}
          style={{background: '#fafafa', border: '#E0E0E0 1px solid'}}
        >
          <CardContent
            style={{display: 'flex', flex: 1, flexDirection: 'column'}}
          >
            <div style={{display: 'flex', flex: 1, flexDirection: 'row'}}>
              <Card style={{flex: 1, marginRight: 8, padding: 16}}>
                <SummaryTitle>Message to:</SummaryTitle>
                <div>{'Vehicles'}</div>
              </Card>

              <Card style={{flex: 1, marginLeft: 8, padding: 16}}>
                <SummaryTitle>Amount of recipients:</SummaryTitle>
                {specificItems.length}
              </Card>

              <Card style={{flex: 1, marginLeft: 8, padding: 16}}>
                <SummaryTitle>Scheduled Time:</SummaryTitle>
                <div
                  style={{display: 'flex', flex: 1, flexDirection: 'column'}}
                >
                  <div>{MomentService.displayTimestamp(mergedDateTime)}</div>
                  <div>({MomentService.displayFromNow(mergedDateTime)})</div>
                </div>
              </Card>
            </div>
            <Card style={{flex: 1, marginTop: 16, padding: 16}}>
              <SummaryTitle>Message Heading:</SummaryTitle>
              <div>{heading}</div>
              <Divider style={{margin: 20}} />

              <SummaryTitle>Message Text:</SummaryTitle>
              <div>{message}</div>
            </Card>
          </CardContent>
        </Paper>

        <div style={{paddingTop: 20}}>
          <CardActions>
            <div>
              <Button onClick={this.onBack}>
                <Typography>Back</Typography>
              </Button>
            </div>
            <div style={{color: disabled ? 'grey' : undefined}}>
              <Button onClick={this.onNext} disabled={disabled}>
                <Typography>Send Broadcast</Typography>
              </Button>
            </div>
          </CardActions>
        </div>
      </>
    );
  }
}
