import React from 'react';
import {Stack} from '@mui/material';
import {ApiDocsFrame} from './ApiDocsFrame';

export const IntegrationApiDocs: React.FC = () => {
  return (
    <Stack flex={1}>
      <ApiDocsFrame
        title="Integration Api Docs"
        url="/internal_integration/swagger-ui/"
      />
    </Stack>
  );
};
