import React from 'react';
import {MasterTripDebriefNotificationSnapshot} from '@onroadvantage/onroadvantage-api';
import {TemplateTable, TemplateTableConfig} from '../../../factory/template';
import {GroupedNotifications} from './TripDebriefNotificationList';

interface TripDebriefNotificationListProps {
  row: GroupedNotifications;
}

export const TripDebriefNotificationDetailList: React.FC<
  TripDebriefNotificationListProps
> = ({row}) => {
  const [config] = React.useState<
    TemplateTableConfig<MasterTripDebriefNotificationSnapshot>
  >({
    columns: [
      {
        name: 'type',
        label: 'Type Name',
        type: 'string',
      },
      {
        name: 'text',
        label: 'Description',
        type: 'string',
      },
      {name: 'scheduledDatetime', label: 'Time sent', type: 'datetime'},
    ],
    disablePagination: true,
    disableToolbar: true,
    identifier: 'TRIP_DEBRIEF_NOTIFICATION_DETAIL_LIST',
  });

  return (
    <TemplateTable
      config={config}
      currentPage={1}
      list={row.list}
      elevation={0}
    />
  );
};
