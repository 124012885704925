import React from 'react';
import {IVehicleTypeCompartmentForm} from '../vehicleTypeCompartmentForm';
import {VehicleTypeCompartmentContext} from '../VehicleTypeCompartmentContext';
import {VehicleCompartment} from '@onroadvantage/onroadvantage-api';
import {CompartmentTruck} from '../../../comptartment/CompartmentTruck';
import {VehicleTypeCompartmentTruckCompartmentAdd} from './VehicleTypeCompartmentTruckCompartmentAdd';

interface VehicleTypeCompartmentTruckProps {
  isAdd?: boolean;
  values?: IVehicleTypeCompartmentForm | undefined;
}

export const VehicleTypeCompartmentTruck: React.FC<
  VehicleTypeCompartmentTruckProps
> = ({isAdd, values}) => {
  const {
    list: vehicleTypeCompartments,
    vehicleTypeCompartmentId,
    onNavigate,
    onDelete,
  } = React.useContext(VehicleTypeCompartmentContext);
  const [compartments, setCompartments] = React.useState<VehicleCompartment[]>(
    vehicleTypeCompartments
  );

  React.useEffect(() => {
    setCompartments((prevCompartments) => {
      if (!values) {
        return vehicleTypeCompartments;
      }
      return prevCompartments.map((prevItem) =>
        prevItem.id === vehicleTypeCompartmentId
          ? {...prevItem, ...values}
          : prevItem
      );
    });
    return () => undefined;
  }, [values, vehicleTypeCompartmentId, vehicleTypeCompartments]);

  return (
    <CompartmentTruck
      compartments={compartments}
      getActive={({id}) => id === vehicleTypeCompartmentId}
      onNavigate={onNavigate}
      onDelete={onDelete}
    >
      <VehicleTypeCompartmentTruckCompartmentAdd
        isAdd={isAdd}
        values={values}
      />
    </CompartmentTruck>
  );
};
