import ConfigService from './ConfigService/ConfigService';
import ResponseService from './ResponseService';
import {encodeUrl, flatten} from './Util';
import {authStore} from '../store';

const serverUrl = ConfigService.serverUri;
const queryUrl = `${serverUrl}/api/user`;

const UserService = {
  getMe: async () => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    if (auth.oauthAuthenticated) {
      headers.set('Authorization', `Bearer ${auth.token}`);
    } else {
      headers.set('Authentication-Token', auth.token);
    }
    const response = await fetch(`${queryUrl}/me`, {
      method: 'GET',
      mode: 'cors',
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  query: async (params) => {
    Object.keys(params).forEach(
      // eslint-disable-next-line no-param-reassign
      (key) => params[key] == null && delete params[key]
    );
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    if (auth.oauthAuthenticated) {
      headers.set('Authorization', `Bearer ${auth.token}`);
    } else {
      headers.set('Authentication-Token', auth.token);
    }
    const response = await fetch(encodeUrl(queryUrl, params), {
      method: 'GET',
      mode: 'cors',
      headers,
    });
    return UserService.normalize(await ResponseService.checkStatus(response));
  },
  normalize: async (response) => {
    const result = flatten(await response.data);
    result.items = result.items.map((resultItem) => {
      // eslint-disable-next-line no-param-reassign
      resultItem.contractCode = resultItem.contracts
        .map((contract) => contract.code)
        .join(', ');
      // eslint-disable-next-line no-param-reassign
      resultItem.roleName = resultItem.roles
        .map((item) => item.name)
        .join(', ');
      return resultItem;
    });
    return result;
  },
  getById: async (userId) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    if (auth.oauthAuthenticated) {
      headers.set('Authorization', `Bearer ${auth.token}`);
    } else {
      headers.set('Authentication-Token', auth.token);
    }
    const response = await fetch(`${queryUrl}/${userId}`, {
      method: 'GET',
      mode: 'cors',
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  update: async (users) => {
    const {auth} = authStore;
    const headers = new Headers();
    const body = JSON.stringify({
      items: users,
    });
    headers.set('Content-Type', 'application/json');
    if (auth.oauthAuthenticated) {
      headers.set('Authorization', `Bearer ${auth.token}`);
    } else {
      headers.set('Authentication-Token', auth.token);
    }
    const response = await fetch(`${queryUrl}`, {
      method: 'PATCH',
      body,
      mode: 'cors',
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  create: async (params) => {
    const {auth} = authStore;
    const headers = new Headers();
    const body = JSON.stringify({
      ...params,
    });
    headers.set('Content-Type', 'application/json');
    if (auth.oauthAuthenticated) {
      headers.set('Authorization', `Bearer ${auth.token}`);
    } else {
      headers.set('Authentication-Token', auth.token);
    }
    const response = await fetch(`${queryUrl}/new`, {
      method: 'POST',
      body,
      mode: 'cors',
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  delete: async (userId) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    if (auth.oauthAuthenticated) {
      headers.set('Authorization', `Bearer ${auth.token}`);
    } else {
      headers.set('Authentication-Token', auth.token);
    }
    const response = await fetch(`${queryUrl}/${userId}`, {
      method: 'DELETE',
      mode: 'cors',
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  getUserQr: async (userId) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    if (auth.oauthAuthenticated) {
      headers.set('Authorization', `Bearer ${auth.token}`);
    } else {
      headers.set('Authentication-Token', auth.token);
    }
    const response = await fetch(`${queryUrl}/qr/${userId}`, {
      method: 'GET',
      mode: 'cors',
      headers,
    });
    return ResponseService.checkStatus(response);
  },
};

export default UserService;
