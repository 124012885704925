import React from 'react';
import {TaskTemplateContext} from './TaskTemplateContext';
import {useParams} from 'react-router-dom';

interface IParams {
  taskTemplateId?: string;
}

export const TaskTemplateIdHandler: React.FC = () => {
  const {setTaskTemplateId} = React.useContext(TaskTemplateContext);

  const {taskTemplateId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = taskTemplateId ? parseInt(taskTemplateId) : undefined;
    setTaskTemplateId(parsedId);
    return () => setTaskTemplateId(undefined);
  }, [taskTemplateId, setTaskTemplateId]);

  return null;
};
