import React from 'react';
import {VehicleContext} from './VehicleContext';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../factory/template';
import {Vehicle} from '@onroadvantage/onroadvantage-api';
import _ from 'lodash';
import MomentService from '../../service/MomentService';
import {Chip} from '@mui/material';

export const VehicleList: React.FC = () => {
  const {loadList, cleanupList} = React.useContext(VehicleContext);

  const [config] = React.useState<TemplateTableConfig<Vehicle>>({
    columns: [
      {
        name: 'registrationNumber',
        label: 'Vehicle Registration',
        type: 'string',
      },
      {name: 'description', label: 'Description', type: 'string'},
      {
        name: 'lastOdometer',
        label: 'Last Odometer',
        type: 'string',
        enableFilter: false,
      },
      {
        name: 'contractCode',
        label: 'Contract Code',
        type: 'string',
        getValue: ({contract}) => contract?.code,
      },
      {
        name: 'vehicleType',
        label: 'Vehicle Type',
        type: 'string',
        getValue: ({type}) => type?.name,
      },
      {
        name: 'lastSeenEventDate',
        label: 'Last Seen',
        type: 'string',
        enableFilter: false,
        getValue: (row) => (
          <Chip
            label={
              row.lastSeen?.eventDate
                ? MomentService.displayFromNow(row.lastSeen?.eventDate)
                : 'Never'
            }
          />
        ),
      },
      {
        name: 'telematicsConfigId',
        label: 'Config',
        type: 'string',
        enableFilter: false,
        hidden: true,
      },
      {
        name: 'fleetNumber',
        label: 'Fleet Number',
        type: 'string',
      },
      {
        name: 'vehicleGroups',
        label: 'Groups',
        type: 'string',
        getValue: ({vehicleGroups}) =>
          vehicleGroups?.map(({name}) => name)?.join(', '),
      },
      {
        name: 'providerName',
        label: 'Provider Name',
        type: 'string',
        hidden: true,
        getValue: (row) => _.get(row, 'telematicsConfig.providerName'),
      },
      {
        name: 'mixOrganisationGroupName',
        label: 'Group Name',
        type: 'string',
        hidden: true,
        getValue: (row) =>
          _.get(row, 'telematicsConfig.mixOrganisationGroupName'),
      },
    ],
    enableFilter: true,
    enableSort: true,
    identifier: 'VEHICLE_LIST',
  });

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [cleanupList, loadList]);

  return <TemplateTableWrapper config={config} context={VehicleContext} />;
};
