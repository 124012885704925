import {
  WebPlanningBoardOrder,
  WebPlanningBoardTripStopOrder,
} from '@onroadvantage/onroadvantage-api';

export const parseOrderOption = (
  order: WebPlanningBoardOrder | WebPlanningBoardTripStopOrder
) => {
  return {
    label: `${order.orderNumber} - ${order.customerReferenceNumber ?? ''} - ${
      order.orderTrackingNumber ?? ''
    }`,
    value: order.id,
  };
};
