import {makeStyles, createStyles} from '@mui/styles';
import {Theme} from '@mui/material';

const TripSetupStyles = (_theme: Theme) =>
  createStyles({
    completedStep: {cursor: 'pointer !important'},
    incompleteStep: {cursor: 'default'},
    loaderContainer: {
      position: 'absolute',
      inset: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
  });

export const useTripSetupStyles = makeStyles(TripSetupStyles, {
  name: 'TripSetupStyles',
});
