import React from 'react';
import {NotificationConfigOperationalEscalationContext} from './NotificationConfigOperationalEscalationContext';
import {
  TemplateTable,
  TemplateTableConfig,
  TOnInlineAdd,
} from '../../../factory/template';
import {Listing, NodeContactGroup} from '@onroadvantage/onroadvantage-api';
import {escalationNotificationConfigApi} from '../../../api';
import {useAppNotifications} from '../../../contexts';
import {RoleService} from '../../../service';

export const NotificationConfigOperationalEscalationNodeContacts: React.FC =
  () => {
    const notify = useAppNotifications();
    const {
      notificationConfigEventEscalation,
      notificationConfigEventEscalationId,
      loading,
      loadNotificationConfigEventEscalation,
    } = React.useContext(NotificationConfigOperationalEscalationContext);
    const [localLoading, setLocalLoading] = React.useState<boolean>(false);

    const [config] = React.useState<TemplateTableConfig<NodeContactGroup>>({
      columns: [
        {
          name: 'contactGroup',
          label: 'Contact Group Name',
          type: 'string',
          model: 'NodeContactGroup',
          enableEditing: true,
          enableAutocomplete: true,
          enableMulti: true,
          getValue: ({contactGroup}) => contactGroup?.name,
        },
        {
          name: 'node',
          label: 'Node Name',
          type: 'string',
          enableEditing: false,
          getValue: ({node}) => node?.name,
        },
      ],
      disableToolbar: true,
      disablePagination: true,
      deleteMessage: (row) => ({
        title: 'Notification Config Task Node Contact',
        items: {
          ContactGroup: row.contactGroup?.name,
          NodeName: row.node?.name,
        },
      }),
      identifier:
        'OPERATIONAL_ESCALATION_NOTIFICATION_CONFIG_NODE_CONTACTS_LIST',
    });

    const handleInlineAdd = React.useCallback<TOnInlineAdd>(
      async (changes) => {
        setLocalLoading(true);
        try {
          for (const change of changes) {
            const nodeContactListings: Listing[] = change.contactGroup;
            const nodeContactIds = nodeContactListings
              .filter(({value}) => value)
              .map(({value}) => value as number);
            if (notificationConfigEventEscalationId) {
              await escalationNotificationConfigApi.apiEscalationNotificationConfigEscalationNotificationConfigIdAssignNodeContactsPost(
                {
                  escalationNotificationConfigId:
                    notificationConfigEventEscalationId,
                  body: {nodeContactIds},
                }
              );
              notify('success', 'Successfully assigned node contact');
              await loadNotificationConfigEventEscalation();
            }
          }
        } catch (e) {
          notify('error', e.message ?? 'Failed to assign node contact');
        } finally {
          setLocalLoading(false);
        }
      },
      [
        loadNotificationConfigEventEscalation,
        notificationConfigEventEscalationId,
        notify,
      ]
    );

    const handleInlineDelete = React.useCallback(
      async (row: NodeContactGroup) => {
        setLocalLoading(true);
        try {
          if (notificationConfigEventEscalationId && row.id) {
            await escalationNotificationConfigApi.apiEscalationNotificationConfigEscalationNotificationConfigIdUnassignNodeContactPost(
              {
                escalationNotificationConfigId:
                  notificationConfigEventEscalationId,
                body: {nodeContactId: row.id},
              }
            );
            notify('success', 'Successfully unassigned node contact');
            await loadNotificationConfigEventEscalation();
          }
        } catch (e) {
          notify('error', e.message ?? 'Failed to unassign node contact');
        } finally {
          setLocalLoading(false);
        }
      },
      [
        loadNotificationConfigEventEscalation,
        notificationConfigEventEscalationId,
        notify,
      ]
    );

    return (
      <TemplateTable
        config={config}
        list={notificationConfigEventEscalation?.nodeContactGroups ?? []}
        currentPage={1}
        loading={loading || localLoading}
        onDelete={
          RoleService.hasPermission('Edit NotificationConfig', 'Edit')
            ? handleInlineDelete
            : undefined
        }
        onInlineAdd={
          RoleService.hasPermission('Edit NotificationConfig', 'Edit')
            ? handleInlineAdd
            : undefined
        }
      />
    );
  };
