import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from '@mui/material';

const EventsPaneStyles = (theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      overflow: 'hidden',
    },
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      overflow: 'hidden',
    },
    form: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      minHeight: 0,
    },
    appBarPlaceholder: {
      ...theme.mixins.toolbar,
      paddingBottom: theme.spacing(0),
      [theme.breakpoints.up('md')]: {
        paddingBottom: theme.spacing(0.8),
      },
    },
  });

export const useEventsPaneStyles = makeStyles(EventsPaneStyles, {
  name: 'EventsPaneStyles',
});
