import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

export const illustrationRatio = 0.3;

const CompartmentTruckStyles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      position: 'relative',
      zIndex: 0,
      flex: 1,
      maxWidth: '90rem',
      margin: 'auto',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    truck: {
      cursor: 'pointer',
      display: 'flex',
      flex: 1,
      objectFit: 'contain',
      objectPosition: 'top',
      position: 'relative',
      zIndex: 0,
    },
    container: {
      position: 'absolute',
      top: 1,
      display: 'flex',
      alignItems: 'center',
      zIndex: 1,
      flex: 1,
    },
    pageView: {position: 'absolute', top: 0, left: 0, color: 'grey'},
  });

export const useCompartmentTruckStyles = makeStyles(CompartmentTruckStyles, {
  name: 'CompartmentTruckStyles',
});
