import React from 'react';
import {TemplateTableWrapper} from '../../../../factory/template';
import {
  BroadcastVantageMobileDriversStepContext,
  BroadcastVantageMobileDriversStepContextProvider,
} from './BroadcastVantageMobileDriversStepContext';
import {Driver} from '@onroadvantage/onroadvantage-api';

export const BroadcastVantageMobileDriversStep = () => {
  return (
    <BroadcastVantageMobileDriversStepContextProvider>
      <TemplateTableWrapper<Driver>
        config={{
          columns: [
            {name: 'firstname', label: 'Driver First Name', type: 'string'},
            {
              name: 'externalExtendedId',
              label: 'Driver Tag',
              type: 'string',
              enableFilter: false,
            },
            {
              name: 'contractCode',
              label: 'Contract Code',
              type: 'string',
              getValue: ({contract}) => contract?.code ?? '-',
            },
          ],
          disableToolbar: true,
          enableGrouping: false,
          enableFilter: true,
          enableSort: true,
          identifier: 'BroadcastVantageMobileDriversStep',
        }}
        context={BroadcastVantageMobileDriversStepContext}
      />
    </BroadcastVantageMobileDriversStepContextProvider>
  );
};
