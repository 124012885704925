import React from 'react';
import {ResetTokenConfig} from '@onroadvantage/onroadvantage-api';
import {Typography} from '@mui/material';
import {useResetPasswordCriteriaStyles} from './ResetPasswordCriteria.style';

const CriteriaItem: React.FC = ({children}) => (
  <li>
    <Typography>{children}</Typography>
  </li>
);

interface ResetPasswordCriteriaProps {
  criteria?: ResetTokenConfig;
}

export const ResetPasswordCriteria: React.FC<ResetPasswordCriteriaProps> = ({
  criteria,
}) => {
  const classes = useResetPasswordCriteriaStyles();
  return (
    <div className={classes.container}>
      <Typography variant="h4">Password Criteria</Typography>
      <ul>
        {criteria?.history && (
          <CriteriaItem>
            Must not be one of the past {criteria.history} password(s) used
          </CriteriaItem>
        )}
        {criteria?.minLength && (
          <CriteriaItem>
            Must be at least {criteria.minLength} character(s) long
          </CriteriaItem>
        )}
        {criteria?.maxLength && (
          <CriteriaItem>
            Must be at most {criteria.maxLength} character(s) long
          </CriteriaItem>
        )}
        {criteria?.numericCharactersCount && (
          <CriteriaItem>
            Must contain at least {criteria.numericCharactersCount} numeric
            characters
          </CriteriaItem>
        )}
        {criteria?.specialCharactersCount && criteria.specialCharacters && (
          <CriteriaItem>
            Must contain at least {criteria.specialCharactersCount} of{' '}
            {criteria.specialCharacters} character(s)
          </CriteriaItem>
        )}
        {criteria?.lowercaseCount && (
          <CriteriaItem>
            Must contain at least {criteria.lowercaseCount} lowercase
            character(s)
          </CriteriaItem>
        )}
        {criteria?.uppercaseCount && (
          <CriteriaItem>
            Must contain at least {criteria.uppercaseCount} uppercase
            character(s)
          </CriteriaItem>
        )}
      </ul>
    </div>
  );
};
