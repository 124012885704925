import React from 'react';
import {Stack} from '@mui/material';
import {Masonry} from '@mui/lab';
import {MasterTripDebriefConfigVisibleCardsMinimal} from '@onroadvantage/onroadvantage-api';
import {
  TripDebriefSummaryActions,
  TripDebriefSummaryTimes,
  TripDebriefSummaryKilometers,
  TripDebriefSummaryDetails,
  TripDebriefSummaryNotifications,
  TripDebriefSummaryActivity,
  TripDebriefSummaryComment,
  TripDebriefSummaryEvents,
  TripDebriefSummaryVehicle,
  TripDebriefSummaryDriver,
  TripDebriefSummaryOrders,
  TripDebriefSummaryTasks,
  TripDebriefSummaryMobile,
  TripDebriefSummaryStopList,
  TripDebriefSummaryDebrieferName,
} from '.';
import {TripDebriefContext} from '../TripDebriefContext';

export const TripDebriefSummary: React.FC = () => {
  const {masterTripDebriefData} = React.useContext(TripDebriefContext);

  if (!masterTripDebriefData) return null;

  return (
    <Stack gap={2} p={1.5} pr={0}>
      <Masonry columns={{xs: 1, lg: 2}} spacing={1.5}>
        <RenderCardVisibility name="detailCard">
          <TripDebriefSummaryDetails />
        </RenderCardVisibility>
        <RenderCardVisibility name="timeCard">
          <TripDebriefSummaryTimes />
        </RenderCardVisibility>
        <RenderCardVisibility name="kmCard">
          <TripDebriefSummaryKilometers />
        </RenderCardVisibility>
        <RenderCardVisibility name="notificationCard">
          <TripDebriefSummaryNotifications />
        </RenderCardVisibility>
        <RenderCardVisibility name="activityCard">
          <TripDebriefSummaryActivity />
        </RenderCardVisibility>
        <RenderCardVisibility name="driverCard">
          <TripDebriefSummaryDriver />
        </RenderCardVisibility>
        <RenderCardVisibility name="orderCard">
          <TripDebriefSummaryOrders />
        </RenderCardVisibility>
        <RenderCardVisibility name="mobileCard">
          <TripDebriefSummaryMobile />
        </RenderCardVisibility>
        <RenderCardVisibility name="taskCard">
          <TripDebriefSummaryTasks />
        </RenderCardVisibility>
        <RenderCardVisibility name="eventCard">
          <TripDebriefSummaryEvents />
        </RenderCardVisibility>
        <RenderCardVisibility name="vehicleCard">
          <TripDebriefSummaryVehicle />
        </RenderCardVisibility>
        <RenderCardVisibility name="additionalCommentsCard">
          <TripDebriefSummaryComment />
        </RenderCardVisibility>
        <RenderCardVisibility name="debrieferNameCard">
          <TripDebriefSummaryDebrieferName />
        </RenderCardVisibility>
      </Masonry>
      <TripDebriefSummaryActions />
      <RenderCardVisibility name="tripStopCard">
        <Stack pr={1.5}>
          <TripDebriefSummaryStopList />
        </Stack>
      </RenderCardVisibility>
    </Stack>
  );
};

interface RenderCardVisibilityProps {
  name: keyof MasterTripDebriefConfigVisibleCardsMinimal;
  children: JSX.Element;
}

const RenderCardVisibility = ({name, children}: RenderCardVisibilityProps) => {
  const {masterTripDebriefConfig} = React.useContext(TripDebriefContext);

  if (
    masterTripDebriefConfig?.visibleCards &&
    !masterTripDebriefConfig.visibleCards[name]
  ) {
    return null;
  }

  return children;
};
