import React from 'react';
import {useRecipientMapStyles} from './RecipientMap.style';
import Map from '../../../map/map';
import {authStore} from '../../../../store';
import {MasterTripRecipientContext} from '../MasterTripRecipientContext';

export const RecipientMap: React.FC = () => {
  const auth = authStore.getAuth;
  const {masterTrip} = React.useContext(MasterTripRecipientContext);
  const classes = useRecipientMapStyles();

  const vehicle = React.useMemo(() => {
    if (
      masterTrip?.trip.vehicle?.lastSeen?.latitude &&
      masterTrip.trip.vehicle.lastSeen.longitude
    )
      return masterTrip?.trip.vehicle;
    else return {};
  }, [masterTrip?.trip.vehicle]);

  if (!masterTrip) {
    return null;
  }

  return (
    <div className={classes.root} data-cy="RecipientMap">
      <Map
        geoSelector={false}
        zoom={15}
        vehicle={vehicle}
        disableSatellite={!auth?.authenticated}
      />
    </div>
  );
};
