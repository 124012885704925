import React from 'react';
import {
  Grid,
  Table,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import withStyles from '@mui/styles/withStyles';
import ContractIcon from '@mui/icons-material/Receipt';
import styles from '../../styles/Card';
import {ContractService} from '../../../service';
import {Loader} from '../../loader';
import {VehicleCard} from '../vehicleCard';

const TableRow = (props) => <Table.Cell {...props} />;
class VehicleContract extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      contract: {},
    };
  }

  // eslint-disable-next-line camelcase
  async UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      loading: false,
      contract: nextProps.vehicle.contract,
      values: await ContractService.transformValues(nextProps.vehicle.contract),
    });
  }

  handleExpandClick = () => {
    this.setState((prevState) => ({expanded: !prevState.expanded}));
  };

  render() {
    const {values = []} = this.state;

    if (values.length === 0) {
      return null;
    }

    return (
      <VehicleCard
        title="Contract"
        avatar={<ContractIcon />}
        subtitle={`${this.state.contract.code} - ${this.state.contract.name}`}
        label="VehicleContract"
      >
        <Grid
          rows={values}
          columns={[
            {name: 'key', title: 'Key'},
            {name: 'value', title: 'Value'},
          ]}
        >
          <Table cellComponent={TableRow} />
          <TableHeaderRow cellComponent={TableRow} />
        </Grid>
        {this.state.loading ? <Loader /> : null}
      </VehicleCard>
    );
  }
}

export default withStyles(styles)(VehicleContract);
