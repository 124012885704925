import React from 'react';
import {OrderContext} from './OrderContext';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../factory/template';
import {Order} from '@onroadvantage/onroadvantage-api';

export const OrderList: React.FC = () => {
  const {loadList, cleanupList} = React.useContext(OrderContext);

  const [config] = React.useState<TemplateTableConfig<Order>>({
    columns: [
      {name: 'orderNumber', label: 'Order Number', type: 'string'},
      {name: 'externalTripNumber', label: 'Trip Number', type: 'string'},
      {name: 'status', label: 'Status', type: 'string'},
      {
        name: 'customerId',
        label: 'Customer Id',
        type: 'number',
        hidden: true,
      },
      {
        name: 'customer.name',
        label: 'Customer Name',
        type: 'string',
        enableFilter: false,
        getValue: ({customer}) => customer?.name,
      },
      {
        name: 'contractId',
        label: 'Contract Id',
        type: 'string',
        hidden: true,
      },
      {
        name: 'contract.code',
        label: 'Contract Code',
        type: 'string',
        getValue: ({contract}) => contract?.code,
      },
      {
        name: 'transporterId',
        label: 'Transporter Id',
        type: 'string',
        hidden: true,
      },
      {
        name: 'transporter.name',
        label: 'Transporter',
        type: 'string',
        enableFilter: false,
        getValue: ({transporter}) => transporter?.name,
      },
      {name: 'primeMover', label: 'Prime Mover', type: 'string'},
      {
        name: 'upliftPointId',
        label: 'Uplift Point Id',
        type: 'string',
        hidden: true,
      },
      {
        name: 'upliftPoint.name',
        label: 'Uplift Point',
        type: 'string',
        getValue: ({upliftPoint}) => upliftPoint?.name,
      },
      {
        name: 'offloadPointId',
        label: 'Offload Point Id',
        type: 'string',
        hidden: true,
      },
      {
        name: 'offloadPoint.name',
        label: 'Offload Point',
        type: 'string',
        getValue: ({offloadPoint}) => offloadPoint?.name,
      },
      {
        name: 'depotDeparture',
        label: 'Planned Depot Departure',
        type: 'datetime',
        // TODO sorting doesn't work?
        enableSort: false,
        enableFilter: false,
      },
      {
        name: 'actualDispatch',
        label: 'Actual Dispatch',
        type: 'datetime',
        // TODO sorting doesn't work?
        enableSort: false,
        enableFilter: false,
      },
    ],
    enableFilter: true,
    enableSort: true,
    deleteMessage: (row) => ({
      title: 'Order',
      items: {OrderNumber: row.orderNumber},
    }),
    identifier: 'ORDER_LIST',
  });

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [cleanupList, loadList]);

  return <TemplateTableWrapper config={config} context={OrderContext} />;
};
