import React from 'react';
import {
  OrderLineUpdate,
  WebMasterTripStop,
} from '@onroadvantage/onroadvantage-api';
import {useAppNotifications} from '../../../../contexts';
import {
  orderLineApi,
  taskApi,
  tripStopApi,
  webMasterTripApi,
} from '../../../../api';
import {
  parsePotentialString,
  TOnInlineAdd,
  TOnInlineEdit,
} from '../../../../factory/template';

interface loadOptions {
  reload?: boolean;
}

export interface useTripStopParams {
  masterTripId: number | undefined;
  tripStopId: number | undefined;
}

export const useTripStop = ({masterTripId, tripStopId}: useTripStopParams) => {
  const notify = useAppNotifications();

  // states
  const [tripStop, setTripStop] = React.useState<
    WebMasterTripStop | undefined
  >();
  // loading
  const [loadingTripStop, setLoadingTripStop] = React.useState<boolean>(false);
  const [submittingComment, setSubmittingComment] =
    React.useState<boolean>(false);
  const [submittingOrderLine, setSubmittingOrderLine] =
    React.useState<boolean>(false);
  const [submittingTask, setSubmittingTask] = React.useState<boolean>(false);

  // helper handlers
  const handleCleanupTripStop = React.useCallback(() => {
    setTripStop(undefined);
    setLoadingTripStop(false);
  }, []);

  // load handlers
  const loadTripStop = React.useCallback(
    async (options?: loadOptions) => {
      const {reload} = options || ({} as loadOptions);

      /**
       * Only load the tripStop if the tripStop has not been loaded yet or if it is reloaded, and if there is a
       * masterTripId and tripStopId.
       */
      if ((!reload && tripStop !== undefined) || !masterTripId || !tripStopId) {
        return;
      }

      setLoadingTripStop(true);
      try {
        const response =
          await webMasterTripApi.apiWebMasterTripMasterTripIdStopTripStopIdGet({
            masterTripId,
            tripStopId,
          });
        if (response) {
          setTripStop(response);
          return response;
        }
      } catch (e) {
        notify('error', 'Failed to load stops');
      } finally {
        setLoadingTripStop(false);
      }
    },
    [masterTripId, notify, tripStop, tripStopId]
  );

  const handleRefresh = React.useCallback(async () => {
    await loadTripStop({reload: true});
  }, [loadTripStop]);

  // update handlers
  const handleAddComment = React.useCallback<TOnInlineAdd>(
    async (changes) => {
      setSubmittingComment(true);
      try {
        const value = changes[0]?.value;
        if (tripStopId && value) {
          const data = await tripStopApi.apiTripStopTripStopIdPost({
            tripStopId,
            body: {activities: [{action: 'Comment', value}]},
          });
          setTripStop(data);
        }
      } finally {
        setSubmittingComment(false);
      }
    },
    [tripStopId]
  );

  const handleEditOrderLine = React.useCallback<TOnInlineEdit>(
    async (changes) => {
      setSubmittingTask(true);
      try {
        for (const change of changes) {
          try {
            const orderLineId = parsePotentialString(change.id);
            const newValues = change.newValues;
            if (orderLineId && newValues) {
              const body: OrderLineUpdate = {};

              if (newValues.actualQuantity) {
                body.actualQuantity = parsePotentialString(
                  newValues.actualQuantity,
                  {type: 'float'}
                );
              }

              if (newValues.product) {
                body.productId = newValues.value;
                body.productName = newValues.label;
              }

              if (newValues.description) {
                body.actualQuantity = newValues.description;
              }

              if (newValues.quantity) {
                body.quantity = parsePotentialString(newValues.quantity);
              }

              const orderLineResponse =
                await orderLineApi.apiOrderLineOrderLineIdPatch({
                  orderLineId,
                  body,
                });

              setTripStop((prevStop) => {
                return {
                  ...prevStop,
                  orders: prevStop?.orders?.map((order) => {
                    if (order.id === orderLineResponse.orderId) {
                      return {
                        ...order,
                        lines: order?.lines?.map((line) => {
                          if (line.id === orderLineResponse.id) {
                            return orderLineResponse;
                          }
                          return line;
                        }),
                      };
                    }
                    return order;
                  }),
                };
              });
              notify('success', 'Updated order line');
            }
          } catch (e) {
            notify('error', 'Failed to update order line');
          }
        }
      } finally {
        setSubmittingTask(false);
      }
    },
    [notify]
  );

  const handleEditTask = React.useCallback<TOnInlineEdit>(
    async (changes) => {
      setSubmittingOrderLine(true);
      try {
        for (const change of changes) {
          try {
            const taskId = parseInt(change.id);
            const payload = change.newValues?.payload;

            if (taskId && payload) {
              const taskResponse = await taskApi.apiTaskTaskIdPatch({
                taskId,
                body: {payload},
              });

              setTripStop((prevStop) => ({
                ...prevStop,
                tasks: prevStop?.tasks?.map((task) => {
                  if (task.id === taskResponse.id) {
                    return taskResponse;
                  }
                  return task;
                }),
              }));
              notify('success', 'Updated task');
            }
          } catch (e) {
            notify('error', `Failed to edit task`);
          }
        }
      } finally {
        setSubmittingOrderLine(false);
      }
    },
    [notify]
  );

  return {
    cleanupTripStop: handleCleanupTripStop,
    loadTripStop,
    loadingTripStop,
    onAddComment: handleAddComment,
    onEditOrderLine: handleEditOrderLine,
    onEditTask: handleEditTask,
    onRefresh: handleRefresh,
    submittingComment,
    submittingOrderLine,
    submittingTask,
    tripStop,
    tripStopId,
  };
};

export type useTripStopResponse = ReturnType<typeof useTripStop>;

export const useTripStopResponseInitial: useTripStopResponse = {
  cleanupTripStop: () => null,
  loadTripStop: async () => undefined,
  loadingTripStop: false,
  onAddComment: () => null,
  onEditOrderLine: () => null,
  onEditTask: () => null,
  onRefresh: async () => {},
  submittingComment: false,
  submittingOrderLine: false,
  submittingTask: false,
  tripStop: undefined,
  tripStopId: undefined,
};
