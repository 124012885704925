import React from 'react';
import {
  DataGroup,
  DataItem,
  DataSet,
  IdType,
  TimelineItem,
  TimelineOptionsComparisonFunction,
  TimelineOptionsItemCallbackFunction,
} from 'vis';
import clsx from 'clsx';
import {DateTime} from 'luxon';
import {
  Listing,
  WebPlanningBoardBookingMasterTripCreationRequest,
  WebPlanningBoardBookingMasterTripUpdate,
  WebPlanningBoardMasterTrip,
  WebPlanningBoardOrder,
  WebPlanningBoardPlanningOrderSequenced,
  WebPlanningBoardTripStopOrder,
} from '@onroadvantage/onroadvantage-api';
import {
  IPlanningBoardGanttItem,
  usePlanningBoardGanttStyles,
} from '../planningBoardPlan/planningBoardGantt';
import {useAppNotifications} from '../../../contexts';
import {IVantageDialogRef} from '../../dialog';
import {AutocompleteOptionType, AutocompleteProps} from '../../autocomplete';
import {
  getMasterTripId,
  getPlanningBoardGanttGroups,
  getPlanningBoardGanttItems,
} from '../helpers';
import {
  bookingApi,
  bookingMasterTripApi,
  planningBoardApi,
  planningMasterTripApi,
  tripApi,
} from '../../../api';
import {usePlanningBoardResponse} from './usePlanningBoard';
import {usePlanningBoardSettingsResponse} from './usePlanningBoardSettings';
import {IPlanningBoardTripPanelForm} from '../planningBoardPlan/planningBoardTrip';
import {RoleService} from '../../../service';

export const PREVIEW_TRIP_ID = 'previewMasterTrip';
export const NEW_BOOKING_TRIP_ID = 'newBookingMasterTrip';

export type TVisTimelineFunction = (
  item: IPlanningBoardGanttItem,
  callback: (item: TimelineItem | null) => void
) => void;

export const usePlanningBoardGantt = (
  params: usePlanningBoardResponse & usePlanningBoardSettingsResponse
) => {
  const {
    enableCheckTripActuals,
    enableCheckTripEnd,
    vehicles,
    selectedGroupOptions,
    getVehicle,
    hasPermission,
    bookingMasterTrips,
    bookings,
    masterMasterTrips,
    optimalMasterTrips,
    optimalVehicleGroups,
    planningMasterTrips,
    reloadInitialDetails,
    setOptimalMasterTrips,
    getMasterTrip,
  } = params;
  const ganttClasses = usePlanningBoardGanttStyles();
  const notify = useAppNotifications();
  // Gantt DataSets
  const [ganttGroups] = React.useState<DataSet<DataGroup>>(
    new DataSet<DataGroup>()
  );
  const [ganttItems] = React.useState<DataSet<DataItem>>(
    new DataSet<DataItem>({
      type: 'range',
    })
  );
  const [startSnap, setStartSnap] = React.useState<Date | undefined>();
  // States
  const [loadingCreateMasterTrip, setLoadingCreateMasterTrip] =
    React.useState<boolean>(false);
  const [loadingDeleteMasterTrip, setLoadingDeleteMasterTrip] =
    React.useState<boolean>(false);
  const [loadingUpdateMasterTrip, setLoadingUpdateMasterTrip] =
    React.useState<boolean>(false);
  const [searchedTrips, setSearchedTrips] = React.useState<IdType[]>([]);
  const [searchedEndingSiteValues, setSearchedEndingSiteValues] =
    React.useState<AutocompleteOptionType[]>([]);
  const [searchedSiteValues, setSearchedSiteValues] = React.useState<
    AutocompleteOptionType[]
  >([]);
  const [selectedGanttItem, setSelectedGanttItem] = React.useState<
    IPlanningBoardGanttItem | undefined
  >();
  const [
    selectedVehicleCapacityDimensionOption,
    setSelectedVehicleCapacityDimensionOption,
  ] = React.useState<Listing | undefined>();
  const [sortVehiclesDirection, setSortVehiclesDirection] = React.useState<
    'asc' | 'desc'
  >('desc');

  const [vehicleCapacityDimensionOptions, setVehicleCapacityDimensionOptions] =
    React.useState<Listing[]>([]);

  const deleteDialogRef = React.useRef<IVantageDialogRef | null>(null);
  const searchOrderRef = React.useRef<HTMLInputElement>(null);

  // Helpers
  const handleClearSelectedGanttItem = React.useCallback(() => {
    ganttItems.remove(PREVIEW_TRIP_ID);
    setSelectedGanttItem((prevSelectedGanttItem) => {
      if (prevSelectedGanttItem?.variant === 'order') {
        ganttItems.remove(prevSelectedGanttItem.id);
      }
      return undefined;
    });
    setStartSnap(undefined);
  }, [ganttItems]);

  const handleClearGanttItemPreview = React.useCallback(() => {
    ganttItems.remove(PREVIEW_TRIP_ID);
    setStartSnap(undefined);
  }, [ganttItems]);

  const handleClearSearchedItems = React.useCallback(() => {
    setSearchedTrips([]);
    setSearchedEndingSiteValues([]);
    setSearchedSiteValues([]);
    if (searchOrderRef.current) {
      searchOrderRef.current.value = '';
    }
  }, []);

  const handleChangeSelectedVehicleCapacityDimension: AutocompleteProps['onChange'] =
    React.useCallback((event, value) => {
      setSelectedVehicleCapacityDimensionOption(value);
    }, []);

  const handleAddPreviewTripToGantt = React.useCallback(
    (previewMasterTrip: WebPlanningBoardMasterTrip) => {
      if (
        previewMasterTrip.trip?.tripStart &&
        previewMasterTrip.trip.tripEnd &&
        previewMasterTrip.trip.tripNumber &&
        previewMasterTrip.trip.stops &&
        previewMasterTrip.trip.stops.length > 0
      ) {
        const serviceStart =
          previewMasterTrip.trip.stops[0].serviceTimeStart ??
          previewMasterTrip.trip.tripStart;
        const serviceEnd =
          previewMasterTrip.trip.stops[previewMasterTrip.trip.stops.length - 1]
            .serviceTimeEnd ?? previewMasterTrip.trip.tripEnd;

        setStartSnap(serviceStart);

        const ganttItem: DataItem = {
          className: ganttClasses.ganttItemPreview,
          title: PREVIEW_TRIP_ID,
          content:
            previewMasterTrip.tripNumber ||
            previewMasterTrip.trip?.tripNumber ||
            '',
          group: previewMasterTrip.trip?.vehicleId,
          start: serviceStart,
          end:
            serviceEnd && serviceEnd < serviceStart
              ? new Date(
                  1000 * 60 * 200 + +serviceStart.valueOf()
                ).toISOString()
              : serviceEnd,
          id: PREVIEW_TRIP_ID,
          subgroup: PREVIEW_TRIP_ID,
        };
        const existingPreviewTrips = ganttItems.getIds({
          filter: (item) => item.id === PREVIEW_TRIP_ID,
        });

        const existingOrders = ganttItems.getIds({
          filter: (item) => item.id === 'order',
        });

        if (existingOrders.length > 0) {
          ganttItems.remove('order');
        }
        if (existingPreviewTrips.length > 0) {
          ganttItems.remove(PREVIEW_TRIP_ID);
        }

        ganttItems.add(ganttItem);
      }
    },
    [ganttClasses.ganttItemPreview, ganttItems]
  );

  const handleSortByVehicleAsc =
    React.useCallback<TimelineOptionsComparisonFunction>(
      (a, b) => {
        const vehicleA = getVehicle(a.id);
        const vehicleB = getVehicle(b.id);

        if (!vehicleA || !vehicleB) return 0;

        const vehicleLabelA = selectedGroupOptions
          .map(
            ({key}) =>
              (key === 'type' ? vehicleA.type?.name : vehicleA[key]) ?? '-'
          )
          .join(' | ');

        const vehicleLabelB = selectedGroupOptions
          .map(
            ({key}) =>
              (key === 'type' ? vehicleB.type?.name : vehicleB[key]) ?? '-'
          )
          .join(' | ');

        if (vehicleLabelA.toLowerCase() > vehicleLabelB.toLowerCase()) {
          return -1;
        } else if (vehicleLabelA.toLowerCase() < vehicleLabelB.toLowerCase()) {
          return 1;
        }

        return 0;
      },
      [getVehicle, selectedGroupOptions]
    );

  const handleSortByVehicleDesc =
    React.useCallback<TimelineOptionsComparisonFunction>(
      (a, b) => {
        const vehicleA = getVehicle(a.id);
        const vehicleB = getVehicle(b.id);

        if (!vehicleA || !vehicleB) return 0;

        const vehicleLabelA = selectedGroupOptions
          .map(
            ({key}) =>
              (key === 'type' ? vehicleA.type?.name : vehicleA[key]) ?? '-'
          )
          .join(' | ');

        const vehicleLabelB = selectedGroupOptions
          .map(
            ({key}) =>
              (key === 'type' ? vehicleB.type?.name : vehicleB[key]) ?? '-'
          )
          .join(' | ');

        if (vehicleLabelA.toLowerCase() > vehicleLabelB.toLowerCase()) {
          return 1;
        } else if (vehicleLabelA.toLowerCase() < vehicleLabelB.toLowerCase()) {
          return -1;
        }

        return 0;
      },
      [getVehicle, selectedGroupOptions]
    );

  // Load handlers
  const handleLoadGanttDetails = React.useCallback(
    (type?: 'load' | 'reset') => {
      const optimalVehicles = vehicles.filter(
        ({id}) => id && optimalVehicleGroups?.includes(id)
      );
      ganttGroups.clear();
      ganttItems.clear();
      if (type !== 'reset') {
        ganttGroups.add(
          getPlanningBoardGanttGroups(
            optimalVehicles.length > 0 ? optimalVehicles : vehicles,
            selectedGroupOptions
          )
        );
        ganttItems.add(
          getPlanningBoardGanttItems({
            bookings,
            bookingMasterTrips,
            masterMasterTrips,
            optimalMasterTrips,
            planningMasterTrips,
            classes: ganttClasses,
            enableCheckTripEnd,
            enableCheckTripActuals,
          }) as DataItem[]
        );
      }
    },
    [
      bookingMasterTrips,
      bookings,
      enableCheckTripActuals,
      enableCheckTripEnd,
      ganttClasses,
      ganttGroups,
      ganttItems,
      masterMasterTrips,
      optimalMasterTrips,
      optimalVehicleGroups,
      planningMasterTrips,
      selectedGroupOptions,
      vehicles,
    ]
  );
  // Api handlers
  const handleCreateMasterTrip = React.useCallback(
    async (
      values: IPlanningBoardTripPanelForm,
      orders: (WebPlanningBoardOrder | WebPlanningBoardTripStopOrder)[],
      overrideErrors?: boolean
    ) => {
      /** Master trips will only be created when dragging orders onto the gantt. Trips will use the update */
      if (selectedGanttItem?.variant !== 'order') return;
      if (!hasPermission.edit) {
        notify('warning', "You don't have the correct permissions");
        return;
      }
      const mainSelectedOrder = selectedGanttItem?.data as
        | WebPlanningBoardOrder
        | undefined;
      const vehicleId = selectedGanttItem?.group;

      if (!mainSelectedOrder?.id || !vehicleId) {
        notify('warning', "Couldn't drag add order");
        return;
      }

      setLoadingCreateMasterTrip(true);
      try {
        const {
          selectedTimeOption,
          endingPointNode,
          startingPointNode,
          vehicle,
          driver,
          offloadingArrivalTime,
          loadingArrivalTime,
        } = values;

        const planningOrders: WebPlanningBoardPlanningOrderSequenced[] = [];
        let sequence = 0;

        orders.forEach(({planningOrder}) => {
          if (
            planningOrder?.id &&
            !planningOrders.map(({id}) => id).includes(planningOrder.id)
          ) {
            sequence++;
            planningOrders.push({id: planningOrder.id, sequence});
          }
        });

        if (planningOrders.length > 0 && vehicle?.value) {
          const requestObj: WebPlanningBoardBookingMasterTripCreationRequest = {
            vehicleId: vehicle.value,
            driverId: driver?.value,
            startingPointNodeId: startingPointNode?.value,
            endingPointNodeId: endingPointNode?.value,
            planningOrders: planningOrders.map(({id}, index) => ({
              id,
              /** Insure we pass the correct sequence */
              sequence: index + 1,
            })),
            overrideErrors,
          };
          // Add the relative selected times depending on the selectedTimeOption
          if (selectedTimeOption === 'loadingOffloadingArrivalTime') {
            requestObj.loadingArrivalTime = loadingArrivalTime;
            requestObj.offloadingArrivalTime = offloadingArrivalTime;
          } else if (selectedTimeOption) {
            requestObj[selectedTimeOption] = values[selectedTimeOption];
          }
          // Post call
          const response =
            await planningBoardApi.apiWebPlanningBoardMasterTripPost({
              body: requestObj,
            });
          if (response) {
            setOptimalMasterTrips([]);
            notify('success', 'Created booking master trip');
            //TODO
            // NOTE: This is not a 100% fix yet, as there might still be cases where users are editing "old" data
            //
            // Clear selectedGanttItem state and set loading to false before reloading the initialDetails, so that the
            // drawer can be closed, since by this point the booking master trip has been created, and we want to start
            // a new loading state, which is to reload the initial data to prevent any potential updates to the planning
            // board that might have occurred in the time they have updated/created the current trip.
            handleClearSelectedGanttItem();
            setLoadingCreateMasterTrip(false);
            await reloadInitialDetails();
          } else {
            notify('warning', `Failed to create booking master trip`);
          }
        } else {
          notify('warning', `Could not find any planning order ids`);
        }
      } catch (e) {
        notify('error', 'Failed to create booking master trip');
      } finally {
        handleClearSelectedGanttItem();
        setLoadingCreateMasterTrip(false);
      }
    },
    [
      handleClearSelectedGanttItem,
      hasPermission.edit,
      notify,
      reloadInitialDetails,
      selectedGanttItem?.data,
      selectedGanttItem?.group,
      selectedGanttItem?.variant,
      setOptimalMasterTrips,
    ]
  );

  const handleUpdateMasterTrip = React.useCallback(
    async (
      values: IPlanningBoardTripPanelForm,
      orders: (WebPlanningBoardOrder | WebPlanningBoardTripStopOrder)[],
      overrideErrors?: boolean
    ) => {
      if (!hasPermission.edit) {
        notify('warning', "You don't have the correct permissions");
        return;
      }
      setLoadingUpdateMasterTrip(true);
      let warningMessage = 'Failed to updated master trip';
      try {
        const {
          endingPointNode,
          startingPointNode,
          vehicle,
          driver,
          offloadingArrivalTime,
          loadingArrivalTime,
          selectedTimeOption,
        } = values;
        const {masterTrip} = getMasterTrip(selectedGanttItem?.id);

        const planningOrders: WebPlanningBoardPlanningOrderSequenced[] = [];
        let sequence = 0;

        orders.forEach(({planningOrder}) => {
          if (
            planningOrder?.id &&
            !planningOrders.map(({id}) => id).includes(planningOrder.id)
          ) {
            sequence++;
            planningOrders.push({id: planningOrder.id, sequence});
          }
        });

        if (vehicle?.value && masterTrip?.id && planningOrders) {
          const requestObj: WebPlanningBoardBookingMasterTripUpdate = {
            vehicleId: vehicle.value,
            bookingMasterTripId: masterTrip.id,
            driverId: driver?.value,
            endingPointNodeId: endingPointNode?.value,
            startingPointNodeId: startingPointNode?.value,
            planningOrders: planningOrders.map(({id}, index) => ({
              id,
              /** Insure we pass the correct sequence */
              sequence: index + 1,
            })),
            overrideErrors,
          };
          if (selectedTimeOption === 'loadingOffloadingArrivalTime') {
            requestObj.loadingArrivalTime = loadingArrivalTime;
            requestObj.offloadingArrivalTime = offloadingArrivalTime;
          } else if (selectedTimeOption) {
            requestObj[selectedTimeOption] = values[selectedTimeOption];
          }
          const response =
            await planningBoardApi.apiWebPlanningBoardMasterTripPatch({
              body: requestObj,
            });
          if (response) {
            notify('success', `Updated booking master trip`);
            //TODO
            // NOTE: This is not a 100% fix yet, as there might still be cases where users are editing "old" data
            //
            // Clear selectedGanttItem state and set loading to false before reloading the initialDetails, so that the
            // drawer can be closed, since by this point the booking master trip has been created, and we want to start
            // a new loading state, which is to reload the initial data to prevent any potential updates to the planning
            // board that might have occurred in the time they have updated/created the current trip.
            handleClearSelectedGanttItem();
            setLoadingUpdateMasterTrip(false);
            await reloadInitialDetails();
          } else {
            notify('error', warningMessage);
          }
        } else {
          if (!planningOrders || planningOrders.length === 0) {
            warningMessage = 'Failed to find planning order ids';
          }
          if (!vehicle?.value) {
            warningMessage = 'No vehicle selected';
          }
          if (!masterTrip) {
            warningMessage = 'Failed to find master trip';
          }
          notify('warning', warningMessage);
        }
      } catch (e) {
        notify('error', 'Failed to update trip');
      } finally {
        setLoadingUpdateMasterTrip(false);
      }
    },
    [
      getMasterTrip,
      handleClearSelectedGanttItem,
      hasPermission.edit,
      notify,
      reloadInitialDetails,
      selectedGanttItem?.id,
    ]
  );

  const handleDeleteMasterTrip = React.useCallback(async () => {
    setLoadingDeleteMasterTrip(true);
    handleClearSelectedGanttItem();
    try {
      const masterTripId = selectedGanttItem?.id;
      if (masterTripId) {
        const {type, masterTrip} = getMasterTrip(masterTripId);
        if (type === 'booking') {
          /**
           * If the booking master trip the user wants to delete has a booking (maintenance).
           * Then the booking should be deleted, which will also delete the booking master
           * trip for us.
           */
          const bookingFound = bookings.find(
            ({bookingMasterTripId}) => bookingMasterTripId === masterTrip?.id
          );
          if (bookingFound?.id) {
            await bookingApi.apiBookingBookingIdDelete({
              bookingId: bookingFound.id,
            });
          } else {
            await bookingMasterTripApi.apiBookingMasterTripBookingMasterTripIdDelete(
              {
                bookingMasterTripId: getMasterTripId('booking', masterTripId),
              }
            );
          }
        }
        if (type === 'planning') {
          await planningMasterTripApi.apiPlanningMasterTripPlanningMasterTripIdDelete(
            {
              planningMasterTripId: getMasterTripId('planning', masterTripId),
            }
          );
        }
        if (type === 'master') {
          await tripApi.apiMasterTripMasterTripIdDelete({
            masterTripId: getMasterTripId('master', masterTripId),
          });
        }
      }
    } finally {
      setLoadingDeleteMasterTrip(false);
      await reloadInitialDetails();
    }
  }, [
    bookings,
    getMasterTrip,
    handleClearSelectedGanttItem,
    reloadInitialDetails,
    selectedGanttItem?.id,
  ]);

  // Gantt option handlers
  const handleAdd = React.useCallback<TVisTimelineFunction>(
    (item, callback) => {
      /** Ignore function and return when user doesn't have edit permission */
      if (!hasPermission.edit) {
        notify('warning', "You don't have the correct permissions");
        return;
      }
      /**
       * Double-clicking on the gantt on the Bookings tab, they want to create a booking/maintenance trip
       */
      if (item.content === 'new item') {
        const existingBookingItem = ganttItems.get(NEW_BOOKING_TRIP_ID);
        if (existingBookingItem) {
          ganttItems.remove(NEW_BOOKING_TRIP_ID);
        }
        const vehicle = getVehicle(item.group);
        const callbackItem: IPlanningBoardGanttItem = {
          ...item,
          id: NEW_BOOKING_TRIP_ID,
          className: clsx(ganttClasses.ganttItem, ganttClasses.ganttItemAdding),
          variant: 'booking',
          data: null,
          vehicle,
        };
        setSelectedGanttItem(callbackItem);
        return callback(callbackItem);
      }
      const {id: ganttItemId} = item;
      const vehicle = getVehicle(item.group);
      /**
       * prevfix the id with the variant if the variant is not equal to a masterTrip.
       * Because an order and a MasterTrip can have the same ids
       */
      if (
        ganttItems &&
        ganttItems.getIds({
          filter: (item) => item.id?.toString() === ganttItemId.toString(),
        }).length > 0
      ) {
        notify('warning', 'This already exists');
      } else {
        const callbackItem: IPlanningBoardGanttItem = {
          ...item,
          id: ganttItemId,
          className: clsx(ganttClasses.ganttItem, ganttClasses.ganttItemAdding),
          end: new Date(1000 * 60 * 100 + +item.start.valueOf()).toISOString(),
          vehicle,
        };
        callback(callbackItem);
        setSelectedGanttItem(callbackItem);
      }
    },
    [
      hasPermission.edit,
      getVehicle,
      ganttItems,
      notify,
      ganttClasses.ganttItem,
      ganttClasses.ganttItemAdding,
    ]
  );

  const handleMove = React.useCallback<TVisTimelineFunction>(
    (item, callback) => {
      const initialState = ganttItems.get(item.id);

      if (!initialState) return;

      if (item.id === NEW_BOOKING_TRIP_ID) {
        const vehicle = getVehicle(item.group);
        const callbackItem: IPlanningBoardGanttItem = {
          ...item,
          vehicle,
        };
        setSelectedGanttItem(callbackItem);
        return callback(callbackItem);
      }

      /**
       * Get the initial duration of the timeline, then the duration of the timeline after they moved it.
       * Then ensure these durations are equal before continuing with the update, otherwise return the initialState to the callback.
       * Reason for this is they are only allowed to move a timeline, they cannot expand or shrink it.
       */
      const initialDuration = DateTime.fromJSDate(
        initialState.end as Date
      ).diff(DateTime.fromJSDate(initialState.start as Date));
      const newDuration = DateTime.fromJSDate(item.end as Date).diff(
        DateTime.fromJSDate(item.start as Date)
      );
      const {type} = getMasterTrip(item.id);

      if (!hasPermission.edit) {
        notify('warning', "You don't have the correct permissions");
        callback(initialState as TimelineItem);
        return;
      }

      /**
       * If the targeted masterTrip is not a bookingMaster trip or if the duration aren't equal (meaning the expanded the trip) reset it to it's initial state and return from the function
       */
      if (type !== 'booking' || !initialDuration.equals(newDuration)) {
        notify('warning', 'Only a booking master trip can be edited');
        callback(initialState as TimelineItem);
        return;
      }

      const callbackItem: IPlanningBoardGanttItem = {
        ...item,
        className: clsx(ganttClasses.ganttItem, ganttClasses.ganttItemAdding),
      };
      setSelectedGanttItem(callbackItem);
      //TODO Can I remove this as well?
      // updatedMasterTripsHandler(item.id, {
      //   group: item.group,
      //   start: new Date(item.start).toISOString(),
      //   end: item.end ? new Date(item.end).toISOString() : undefined,
      // });

      callback(callbackItem);
    },
    [
      ganttClasses.ganttItem,
      ganttClasses.ganttItemAdding,
      ganttItems,
      getMasterTrip,
      getVehicle,
      hasPermission.edit,
      notify,
    ]
  );

  const handleRemove = React.useCallback<TVisTimelineFunction>(
    (item, callback) => {
      const masterTripId = item?.id;
      const {type} = getMasterTrip(masterTripId);
      if (
        type === 'booking' &&
        !RoleService.hasPermission('Delete BookingMasterTrip', 'Delete')
      ) {
        notify(
          'warning',
          'You don not have the permission to delete a booking trip'
        );
        return;
      }
      if (
        type === 'planning' &&
        !RoleService.hasPermission('Delete PlanningMasterTrip', 'Delete')
      ) {
        notify(
          'warning',
          'You don not have the permission to delete a planning trip'
        );
        return;
      }
      if (
        type === 'master' &&
        !RoleService.hasPermission('Delete Trip', 'Delete')
      ) {
        notify(
          'warning',
          'You don not have the permission to delete a committed trip'
        );
        return;
      }

      if (item.data?.status === undefined) {
        setOptimalMasterTrips((prevOptimalMasterTrips) =>
          prevOptimalMasterTrips.filter(({id}) => id !== item.id)
        );
        callback(item);
        return;
      }
      //TODO Can I remove this
      // if (item.variant === 'masterTrip') {
      //   /**
      //    * Only need to pass the id as the updateMasterTrip will know that it needs to be removed if there are no update details passed along with the function
      //    */
      //   updatedMasterTripsHandler(item.id);
      // }
      setSelectedGanttItem(item);
      deleteDialogRef.current?.openDialog();
    },
    [getMasterTrip, notify, setOptimalMasterTrips]
  );

  // Gantt event handlers
  const handleDoubleClick = React.useCallback<(properties?: any) => void>(
    ({event, ...properties}) => {
      if (properties.what === 'background') {
        handleClearSearchedItems();
        return;
      }

      handleClearSelectedGanttItem();

      ganttItems.remove(NEW_BOOKING_TRIP_ID);

      if (properties.item === PREVIEW_TRIP_ID) {
        return;
      }
      ganttItems.remove(PREVIEW_TRIP_ID);

      const currentItem = ganttItems.get(
        properties.item
      ) as unknown as IPlanningBoardGanttItem;
      if (currentItem && !Array.isArray(currentItem)) {
        const vehicle = getVehicle(currentItem.group);
        const capacityDimensionOptions: Listing[] =
          vehicle?.type?.capacityDimensions?.map(({id, unitOfMeasure}) => ({
            value: id ?? undefined,
            label: unitOfMeasure ?? undefined,
          })) ?? [];
        setVehicleCapacityDimensionOptions(capacityDimensionOptions);
        setSelectedVehicleCapacityDimensionOption(
          capacityDimensionOptions.length > 0
            ? capacityDimensionOptions[0]
            : undefined
        );
        setSelectedGanttItem(currentItem);
      }
    },
    [
      ganttItems,
      getVehicle,
      handleClearSearchedItems,
      handleClearSelectedGanttItem,
    ]
  );

  // Reset DataSets on unmount
  React.useEffect(() => {
    return () => {
      ganttGroups.clear();
      ganttItems.clear();
    };
  }, [ganttGroups, ganttItems]);

  return {
    createMasterTrip: handleCreateMasterTrip,
    deleteDialogRef,
    deleteMasterTrip: handleDeleteMasterTrip,
    ganttGroups,
    ganttItems,
    loadGanttDetails: handleLoadGanttDetails,
    loadingCreateMasterTrip,
    loadingDeleteMasterTrip,
    loadingUpdateMasterTrip,
    onAddPreviewTripToGantt: handleAddPreviewTripToGantt,
    onAdd: handleAdd as TimelineOptionsItemCallbackFunction,
    onChangeCapacityDimension: handleChangeSelectedVehicleCapacityDimension,
    onClearSearchedItems: handleClearSearchedItems,
    onClearGanttItemPreview: handleClearGanttItemPreview,
    onClearSelectedGanttItem: handleClearSelectedGanttItem,
    onDoubleClick: handleDoubleClick,
    onMove: handleMove as TimelineOptionsItemCallbackFunction,
    onRemove: handleRemove as TimelineOptionsItemCallbackFunction,
    onSortByVehiclesAsc: handleSortByVehicleAsc,
    onSortByVehiclesDesc: handleSortByVehicleDesc,
    searchedTrips,
    searchOrderRef,
    searchedEndingSiteValues,
    searchedSiteValues,
    selectedGanttItem,
    selectedVehicleCapacityDimensionOption,
    setLoadingCreateMasterTrip,
    setLoadingDeleteMasterTrip,
    setLoadingUpdateMasterTrip,
    setSearchedTrips,
    setSearchedEndingSiteValues,
    setSearchedSiteValues,
    setSelectedGanttItem,
    setSortVehiclesDirection,
    setStartSnap,
    setVehicleCapacityDimensionOptions,
    setSelectedVehicleCapacityDimensionOption,
    sortVehiclesDirection,
    startSnap,
    updateMasterTrip: handleUpdateMasterTrip,
    vehicleCapacityDimensionOptions,
  };
};

export type usePlanningBoardGanttResponse = ReturnType<
  typeof usePlanningBoardGantt
>;

export const usePlanningBoardGanttResponseInitial: usePlanningBoardGanttResponse =
  {
    createMasterTrip: async () => {},
    deleteDialogRef: {current: null},
    deleteMasterTrip: async () => {},
    ganttGroups: new DataSet<DataGroup>(),
    ganttItems: new DataSet<DataItem>({
      type: 'range',
    }),
    loadGanttDetails: async () => {},
    loadingCreateMasterTrip: false,
    loadingDeleteMasterTrip: false,
    loadingUpdateMasterTrip: false,
    onAddPreviewTripToGantt: () => {},
    onAdd: () => {},
    onChangeCapacityDimension: () => {},
    onClearSearchedItems: () => {},
    onClearGanttItemPreview: () => {},
    onClearSelectedGanttItem: () => {},
    onDoubleClick: () => {},
    onMove: () => {},
    onRemove: () => {},
    onSortByVehiclesAsc: () => 0,
    onSortByVehiclesDesc: () => 0,
    searchedTrips: [],
    searchOrderRef: {current: null},
    searchedEndingSiteValues: [],
    searchedSiteValues: [],
    selectedGanttItem: undefined,
    selectedVehicleCapacityDimensionOption: undefined,
    setLoadingCreateMasterTrip: () => {},
    setLoadingDeleteMasterTrip: () => {},
    setLoadingUpdateMasterTrip: () => {},
    setSearchedTrips: () => {},
    setSearchedEndingSiteValues: () => {},
    setSearchedSiteValues: () => {},
    setSelectedGanttItem: () => {},
    setSortVehiclesDirection: () => {},
    setStartSnap: () => {},
    setSelectedVehicleCapacityDimensionOption: () => {},
    setVehicleCapacityDimensionOptions: () => {},
    sortVehiclesDirection: 'desc',
    startSnap: undefined,
    updateMasterTrip: async () => {},
    vehicleCapacityDimensionOptions: [],
  };
