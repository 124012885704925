import React from 'react';
import Lottie from 'react-lottie';
import truckIconPopAnimation from '../../img/json/truckIconPop.json';

export const AuthenticationPopupTruck: React.FC = () => {
  const [x, setX] = React.useState<number>(0);
  const [y, setY] = React.useState<number>(0);

  const update = React.useCallback(() => {
    setX(Math.floor(Math.random() * (window.innerWidth - 400) + 1));
    setY(Math.floor(Math.random() * window.innerHeight) - 100);
  }, [setX, setY]);

  React.useEffect(() => {
    update();
  }, [update]);

  const popupData = {
    x,
    y,
    id: `${x}-${y}`,
  };

  return (
    <div
      style={{
        zIndex: 1,
        position: 'absolute',
        marginLeft: popupData.x > 480 ? popupData.x : 480 + popupData.x,
        marginTop: Math.abs(popupData.y),
      }}
      id={popupData.id}
    >
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: truckIconPopAnimation,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        eventListeners={[
          {
            eventName: 'loopComplete',
            callback: () => {
              update();
            },
          },
        ]}
        height={80}
        width={80}
        isClickToPauseDisabled
      />
    </div>
  );
};
