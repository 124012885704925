import React from 'react';
import {WorkflowList} from './WorkflowList';
import {
  ITemplateControllerRoute,
  TemplateController,
} from '../../factory/template';
import {WorkflowContextProvider} from './WorkflowContext';

export const WorkflowController: React.FC = () => {
  return (
    <WorkflowContextProvider>
      <WorkflowControllerComponent />
    </WorkflowContextProvider>
  );
};

const WorkflowControllerComponent: React.FC = () => {
  const [routes] = React.useState<ITemplateControllerRoute[]>([
    {title: 'Workflow List', component: WorkflowList},
  ]);

  return <TemplateController rootPath="/workflowlist" routes={routes} />;
};
