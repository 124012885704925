import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

const LoginFormStyles = (theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1.5),
    },
    oidcButton: {
      marginTop: theme.spacing(1.5),
      marginLeft: theme.spacing(2),
    },
    loaderContainer: {
      paddingTop: 30,
      height: 5,
      paddingBottom: theme.spacing(2),
    },
  });

export const useLoginFormStyles = makeStyles(LoginFormStyles, {
  name: 'LoginFormStyles',
});
