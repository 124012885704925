import React from 'react';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui';
import {Card, CardContent} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import styles from '../../styles/Card';

const TableRow = (props) => <Table.Cell {...props} style={{padding: 0}} />;
class VehicleStopLocations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: this.props.locations,
      row: this.props.row,
    };
  }

  render() {
    const {items = []} = this.state;

    return (
      <div style={{padding: 10}}>
        <Card style={this.props.style}>
          <CardContent>
            <Grid
              rows={items}
              columns={[
                {name: 'id', title: 'ID'},
                {name: 'name', title: 'Stop Name'},
                {name: 'type', title: 'Stop Type'},
                {name: 'externalReference', title: 'External Reference'},
                {name: 'latitude', title: 'Latitude'},
                {name: 'longitude', title: 'Longitude'},
              ]}
            >
              <Table cellComponent={TableRow} />
              <TableHeaderRow cellComponent={TableRow} />
              <TableColumnVisibility defaultHiddenColumnNames={['id']} />
            </Grid>
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles)(VehicleStopLocations);
