import React from 'react';
import {useHistory} from 'react-router-dom';
import {
  ApiProductGetRequest,
  Product,
  ProductList,
  ProductPost,
} from '@onroadvantage/onroadvantage-api';
import {FormikHelpers, FormikProps} from 'formik/dist/types';
import {productApi} from '../../api';
import {
  TemplateTableContextProps,
  TLoadList,
  useTemplateTable,
} from '../../factory/template';
import {useAppNotifications} from '../../contexts';
import {IProductForm} from './ProductForm';

export interface ProductContextProps
  extends TemplateTableContextProps<Product, ProductList> {
  submitting: boolean;
  onDetailsFormSubmit: (
    values: IProductForm,
    formikHelpers: FormikHelpers<IProductForm>
  ) => void;
  setProductId: (value: number | undefined) => void;
  product?: Product;
  productId?: number;
  detailsRef?: React.Ref<FormikProps<IProductForm>>;
}

export const ProductContext = React.createContext<ProductContextProps>({
  // defaults
  loading: false,
  list: [],
  currentPage: 1,
  // Product
  loadList: () => null,
  onDetailsFormSubmit: () => null,
  setProductId: () => null,
  submitting: false,
});

interface ProductContextProviderProps {
  productId?: number;
}

export const ProductContextProvider: React.FC<ProductContextProviderProps> = ({
  children,
}) => {
  const history = useHistory();
  const notify = useAppNotifications();
  // Template Table

  const [
    {
      // States
      currentPage,
      filters,
      hasPermission,
      itemTotal,
      list,
      loading,
      pageSize,
      pageTotal,
      loadingSingleItem,
      sorting,
    },
    {
      // Getters
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
      setLoadingSingleItem,
    },
  ] = useTemplateTable<Product, ApiProductGetRequest>({
    editPermission: 'Edit Product',
    addPermission: 'Add Product',
    deletePermission: 'Delete Product',
    downloadPermission: 'Product ListDownload',
    viewPermission: 'Product List',
  });

  const loadList = React.useCallback<TLoadList<ProductList>>(
    async (options) => {
      setLoading(true);
      try {
        const requestObj = getRequestObj(
          ['name', 'description', 'uom'],
          options
        );
        const response = await productApi.apiProductGet(requestObj);
        return getResponse(response, options);
      } catch (e) {
        notify('error', e.message ?? 'Failed to load List');
      } finally {
        setLoading(false);
      }
    },
    [setLoading, getRequestObj, getResponse, notify]
  );

  const handleDelete = React.useCallback(
    async (row: any) => {
      setLoading(true);
      try {
        await productApi.apiProductProductIdDelete({
          productId: row.id,
        });
        await loadList();
        notify('success', 'Product Deleted');
      } catch (e) {
        notify('error', e.message ?? 'Error deleting Product');
      } finally {
        setLoading(false);
      }
    },
    [loadList, notify, setLoading]
  );

  const handleAdd = React.useCallback(() => {
    history.push('/productlist/add');
  }, [history]);

  const handleNavigate = React.useCallback(
    (row: Product) => {
      history.push(`/productlist/${row.id}`);
    },
    [history]
  );

  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  // Product
  const [product, setProduct] = React.useState<Product | undefined>();
  const [productId, setProductId] = React.useState<number | undefined>();
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const detailsRef = React.useRef<FormikProps<ProductPost>>(null);

  const loadProduct = React.useCallback(async () => {
    setLoadingSingleItem(true);
    try {
      if (productId) {
        const response = await productApi.apiProductProductIdGet({
          productId,
        });
        setProduct(response);
      } else {
        setProduct(undefined);
      }
    } catch (e) {
      notify('error', e.message ?? 'Error loading Product');
    } finally {
      setLoadingSingleItem(false);
    }
  }, [setLoadingSingleItem, productId, notify]);

  const handleDetailsFormSubmit = React.useCallback(
    async (
      values: IProductForm,
      formikHelpers: FormikHelpers<IProductForm>
    ) => {
      setSubmitting(true);
      try {
        const newValues: ProductPost = values;

        if (product && product.id) {
          await productApi.apiProductProductIdPatch({
            productId: product.id,
            body: newValues,
          });
        } else {
          await productApi.apiProductPost({
            body: newValues,
          });
        }
        history.push('/productlist');
        notify('success', `${product?.id ? 'Updated' : 'Added'} Product`);
      } catch (e) {
        notify(
          'error',
          e.message ?? `Error ${product?.id ? 'updating' : 'adding'} Product`
        );
      } finally {
        formikHelpers.setSubmitting(false);
        setSubmitting(false);
      }
    },
    [product, history, notify]
  );

  const value: ProductContextProps = {
    // Template Table
    loadList,
    cleanupList,
    loading: loadingSingleItem || loading,
    list,
    currentPage,
    filters,
    hasPermission,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onAdd: hasPermission.add ? handleAdd : undefined,
    onNavigate: handleNavigate,
    onDelete: hasPermission.delete ? handleDelete : undefined,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onRefresh: handleRefresh,
    // Product
    onDetailsFormSubmit: handleDetailsFormSubmit,
    setProductId,
    submitting,
    product,
    productId,
    detailsRef,
  };

  React.useEffect(() => {
    loadProduct();
    return () => setProduct(undefined);
  }, [loadProduct]);

  return (
    <ProductContext.Provider value={value}>{children}</ProductContext.Provider>
  );
};
