import React from 'react';
import {useTableContainerComponentStyles} from './TableContainerComponent.style';
import {useInternalTableContext} from '../../TemplateTable';

export const TableContainerComponent: React.FC = ({children, ...props}) => {
  const {classes: classesProp} = useInternalTableContext();
  const classes = useTableContainerComponentStyles();
  return (
    <div className={classesProp?.container ?? classes.container} {...props}>
      {children}
    </div>
  );
};

export const VirtualTableContainerComponent: React.FC = ({
  children,
  ...props
}) => {
  const {classes: classesProp} = useInternalTableContext();
  const classes = useTableContainerComponentStyles();
  return (
    <div
      className={classesProp?.container ?? classes.virtualContainer}
      {...props}
    >
      {children}
    </div>
  );
};
