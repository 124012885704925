import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

const TripMapStyles = (_theme: Theme) =>
  createStyles({
    mapContent: {
      /** Need to add height to the maps container as the map only takes up the available space */
      height: '70vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 0,
      '&:last-child': {paddingBottom: 0},
    },
  });

export const useTripMapStyles = makeStyles(TripMapStyles, {
  name: 'TripMapStyles',
});
