import React from 'react';
import FileSaver from 'file-saver';
import {Button} from '@mui/material';
import {useAppNotifications} from '../../../../contexts';
import {getAuthHeaderValues} from '../../../../api';
import TripStopService from '../../../../service/TripStopService';
import {Loader} from '../../../loader';
import {useTripStopContext} from '../tripStopContext';

export const TripStopTaskListActions: React.FC = () => {
  // TODO fix this so the auth token is not included in the URI
  //  Further it cannot be implemented via SSO as it does not accept the bearer
  //  token via url but via the headers.
  const {tripStopId} = useTripStopContext();
  const notify = useAppNotifications();
  const [generating, setGenerating] = React.useState(false);

  const handleDownload = React.useCallback(async () => {
    setGenerating(true);
    try {
      if (getAuthHeaderValues !== null && tripStopId) {
        const link = await TripStopService.getDocumentLink(tripStopId, 'PoD');
        const headerValues = getAuthHeaderValues();
        const headers = new Headers();
        if (headerValues) headers.set(headerValues.name, headerValues.value);

        const response = await fetch(link, {
          method: 'GET',
          mode: 'cors',
          headers,
        });

        if (response.ok) {
          const blob = await response.blob();
          FileSaver.saveAs(blob, `POD_${tripStopId}.pdf`);
        }
      }
    } catch (e) {
      notify('error', 'Failed to download POD');
    } finally {
      setGenerating(false);
    }
  }, [notify, tripStopId]);

  if (generating) return <Loader />;

  return (
    <Button variant="contained" onClick={handleDownload}>
      Generate Stop PoD
    </Button>
  );
};
