import React from 'react';
import {
  multipleListingSchema,
  TemplateCard,
  TemplateForm,
} from '../../factory/template';
import {ProductGroupContext} from './ProductGroupContext';
import {Listing, ProductGroup} from '@onroadvantage/onroadvantage-api';
import * as Yup from 'yup';
import {FormikAutocompleteWithListing, FormikTextField} from '../formik';

export interface IProductGroupForm {
  name: string;
  description?: string | undefined;
  products?: Listing[] | undefined;
  planningSkills?: Listing[] | undefined;
}

const schema: Yup.SchemaOf<IProductGroupForm> = Yup.object({
  name: Yup.string().required('Required'),
  description: Yup.string(),
  products: multipleListingSchema,
  planningSkills: multipleListingSchema,
});

const getInitialValues = (
  productGroup?: ProductGroup | null
): IProductGroupForm | undefined => {
  if (productGroup) {
    return {
      name: productGroup.name ?? '',
      description: productGroup.description,
      products: productGroup.products?.map((product) => ({
        value: product.id,
        label: product.name,
      })),
      planningSkills: productGroup.skills?.map(({id, name, description}) => ({
        value: id,
        label: `${name} - ${description}`,
      })),
    };
  }
  return undefined;
};

export const ProductGroupForm: React.FC<{isAdd?: boolean}> = ({isAdd}) => {
  const {detailsRef, loading, submitting, productGroup, onDetailsFormSubmit} =
    React.useContext(ProductGroupContext);
  const [initialValues, setInitialValues] = React.useState<IProductGroupForm>();

  React.useEffect(() => {
    const values = getInitialValues(productGroup);

    if (values) {
      setInitialValues(values);
    } else if (isAdd) {
      setInitialValues({
        name: '',
      });
    }
  }, [isAdd, productGroup, setInitialValues]);

  return (
    <TemplateCard
      title={`Product Group Details ${isAdd ? 'Add' : 'Edit'}`}
      loading={loading}
    >
      <TemplateForm<IProductGroupForm>
        initialValues={initialValues}
        onSubmit={onDetailsFormSubmit}
        permission={{
          name: `${isAdd ? 'Add' : 'Edit'} ProductGroup`,
          type: isAdd ? 'Add' : 'Edit',
        }}
        submitting={submitting}
        innerRef={detailsRef}
        validationSchema={schema}
      >
        <FormikTextField
          name="name"
          placeholder="Enter the product group name"
          label="Name"
          fullWidth
        />
        <FormikTextField
          name="description"
          placeholder="Enter the product group description"
          label="Description"
          fullWidth
        />
        <FormikAutocompleteWithListing
          name="products"
          label="Products"
          model="Product"
          multiple
        />
        <FormikAutocompleteWithListing
          name="planningSkills"
          placeholder="Enter and select the product group skills"
          label="Planning Skills"
          model="PlanningSkill"
          multiple
        />
      </TemplateForm>
    </TemplateCard>
  );
};
