import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {Paper} from '@mui/material';
import _ from 'lodash';
import {useDriverControllerStyles} from './DriverController.style';
import {
  DriverContext,
  DriverContextProps,
  DriverContextProvider,
} from './DriverContext';
import {
  BreadcrumbController,
  BreadcrumbRoute,
} from '../breadcrumbs/BreadcrumbController';
import {DriverList} from './DriverList';
import {DriverIdHandler} from './DriverIdHandler';
import {DriverAdd} from './driverAdd';
import {Driver} from './Driver';
import {AllowedRoute} from '../router';

export const DriverControllerComponent: React.FC = () => {
  const classes = useDriverControllerStyles();
  const {driver, loading} = React.useContext<DriverContextProps>(DriverContext);

  const routes: BreadcrumbRoute[] = [
    {
      path: '/driverlist',
      matchOptions: undefined,
      title: 'Driver List',
    },
    {
      path: '/driverlist/add',
      matchOptions: undefined,
      title: 'New Driver',
    },
    {
      path: '/driverlist/:driverId',
      matchOptions: undefined,
      title: (params) =>
        loading
          ? 'Driver Loading...'
          : driver?.firstname?.concat(` ${driver?.lastname}`) ??
            `Driver ${_.get(params, 'driverId', 'Single')}`,
    },
  ];

  return (
    <div className={classes.root}>
      <BreadcrumbController routes={routes} />
      <Paper className={classes.container}>
        <Route exact path="/driverlist" component={DriverList} />
        <Route path="/driverlist/:driverId" component={DriverIdHandler} />
        <Switch>
          <AllowedRoute
            exact
            path="/driverlist/add"
            component={DriverAdd}
            name="Add Driver"
            type="Add"
          />
          <Route exact path="/driverlist/:driverId" component={Driver} />
        </Switch>
      </Paper>
    </div>
  );
};

export const DriverController: React.FC = () => {
  return (
    <DriverContextProvider>
      <DriverControllerComponent />
    </DriverContextProvider>
  );
};
