import React from 'react';
import {useAppNotifications} from '../../../../../contexts';
import {
  TemplateTableContextProps,
  TLoadList,
  useTemplateTable,
} from '../../../../../factory/template';
import {TripDebriefContext} from '../../../TripDebriefContext';
import {
  ApiWebDocumentStorageTripStopTripStopIdGetRequest,
  WebListDocumentStorageDump,
  WebListDocumentStorageListResponse,
} from '@onroadvantage/onroadvantage-api';
import {documentStorageApi, webDocumentStorageApi} from '../../../../../api';

export type TripDebriefSummaryStopDocumentListContextProps =
  TemplateTableContextProps<
    WebListDocumentStorageDump,
    WebListDocumentStorageListResponse
  >;

export const TripDebriefSummaryStopDocumentListContext =
  React.createContext<TripDebriefSummaryStopDocumentListContextProps>({
    loading: false,
    list: [],
    currentPage: 1,
    loadList: async () => {},
  });

export const TripDebriefSummaryStopDocumentListContextProvider: React.FC = ({
  children,
}) => {
  const {tripStopId} = React.useContext(TripDebriefContext);
  const notify = useAppNotifications();
  // Template Table
  const [
    {
      currentPage,
      pageSize,
      pageTotal,
      sorting,
      hasPermission,
      itemTotal,
      list,
      loading,
      filters,
      isDaterangeFilterActive,
    },
    {
      // Getters
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleDateRangeFilterToggle,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
    },
  ] = useTemplateTable<
    WebListDocumentStorageDump,
    ApiWebDocumentStorageTripStopTripStopIdGetRequest
  >({
    editPermission: 'Edit TripDebrief',
    addPermission: 'Add TripDebrief',
    deletePermission: 'Delete TripDebrief',
    downloadPermission: 'TripDebrief ListDownload',
    viewPermission: 'TripDebrief List',
  });

  const loadList = React.useCallback<
    TLoadList<WebListDocumentStorageListResponse>
  >(
    async (options) => {
      setLoading(true);
      try {
        if (tripStopId) {
          const requestObj = getRequestObj(
            ['documentType', 'tripStopId', 'status'],
            options,
            {tripStopId}
          );
          const response =
            await webDocumentStorageApi.apiWebDocumentStorageTripStopTripStopIdGet(
              {...requestObj, tripStopId}
            );
          return getResponse(response, options);
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to load trip stop documents');
      } finally {
        setLoading(false);
      }
    },
    [getRequestObj, getResponse, notify, setLoading, tripStopId]
  );

  const handleDownloadItem = React.useCallback(
    async (document: WebListDocumentStorageDump) => {
      if (document && document.id) {
        const response =
          await documentStorageApi.apiDocumentStorageDocumentStorageIdDownloadLinkGet(
            {
              documentStorageId: document.id,
            }
          );

        if (response && response.downloadLink) {
          window.open(response.downloadLink, '_blank');
        }
      }
    },
    []
  );

  const value: TripDebriefSummaryStopDocumentListContextProps = {
    // Template Table
    list,
    loadList,
    hasPermission,
    loading: loading,
    cleanupList,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    isDaterangeFilterActive,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onDateRangeFilterToggle: handleDateRangeFilterToggle,
    onSortingChange: handleSortingChange,
    onDownloadItem: handleDownloadItem,
  };
  return (
    <TripDebriefSummaryStopDocumentListContext.Provider value={value}>
      {children}
    </TripDebriefSummaryStopDocumentListContext.Provider>
  );
};
