import React from 'react';
import {renderToString} from 'react-dom/server';
import _ from 'lodash';
import {
  ForcedWait,
  TripStop,
  TripStopActualTimeline,
} from '@onroadvantage/onroadvantage-api';
import {DateTime} from 'luxon';

interface GetTimelineProps {
  start: Date;
  end: Date | undefined;
}

const getTimelineHtml = ({start, end}: GetTimelineProps) => {
  return (
    <span>
      <strong>
        {DateTime.fromJSDate(start).toFormat('dd/MM, T')} &rarr;{' '}
        {DateTime.fromJSDate(end ?? start).toFormat('dd/MM, T')}
      </strong>
    </span>
  );
};

export const getTimeline = (timeline: GetTimelineProps) => {
  const timelineHtml = getTimelineHtml(timeline);

  return renderToString(timelineHtml);
};

interface ActualStopWithTimeline {
  timeline: GetTimelineProps;
  stop: TripStopActualTimeline;
}
export const getActualStopTooltip = ({
  timeline,
  stop,
}: ActualStopWithTimeline) => {
  const timelineHtml = getTimelineHtml(timeline);

  let bodyHtml: React.ReactNode = <div>Illegal Stop</div>;

  if (stop.scheduledNodes && stop.scheduledNodes.length > 0) {
    bodyHtml = (
      <div>
        {stop.scheduledNodes &&
          stop.scheduledNodes.map((el) => {
            return <div key={el.id}>{el.name}</div>;
          })}
      </div>
    );
  } else if (stop.knownNodes && stop.knownNodes.length > 0) {
    bodyHtml = (
      <div>
        {stop.knownNodes.map((el) => {
          return <div key={el.id}>{el.name}</div>;
        })}
      </div>
    );
  }

  const html = (
    <div>
      {timelineHtml}
      <hr />
      {bodyHtml}
    </div>
  );

  return renderToString(html);
};

interface StopWithTimelineProps {
  timeline: GetTimelineProps;
  stop: TripStop;
}

export const getPlannedStopTooltip = ({
  timeline,
  stop,
}: StopWithTimelineProps) => {
  const timelineHtml = getTimelineHtml(timeline);

  const html = (
    <div>
      {timelineHtml}
      <hr />
      <div>Site: {stop.node?.name ?? '-'}</div>
      <div>External Reference: {stop.node?.externalReference ?? '-'}</div>
    </div>
  );

  return renderToString(html);
};

export const getPlannedDrivingTooltip = ({timeline}: StopWithTimelineProps) => {
  const timelineHtml = getTimelineHtml(timeline);

  return renderToString(timelineHtml);
};

interface ForcedWaitsProps {
  timeline: GetTimelineProps;
  forcedWaits: ForcedWait[] | undefined;
}

export const getForcedWaits = ({timeline, forcedWaits}: ForcedWaitsProps) => {
  const timelineHtml = getTimelineHtml(timeline);
  let bodyHtml: React.ReactNode | undefined;
  if (forcedWaits && forcedWaits.length > 0) {
    bodyHtml = (
      <div>
        <hr />
        <div>
          {forcedWaits.map((el) => (
            <div key={el.id}>{el.type}</div>
          ))}
        </div>
      </div>
    );
  }

  const html = (
    <div>
      {timelineHtml}
      {bodyHtml ?? null}
    </div>
  );

  return renderToString(html);
};

export const getOrderStopTooltip = ({
  timeline,
  stop,
}: StopWithTimelineProps) => {
  const timelineHtml = getTimelineHtml(timeline);

  const html = (
    <div>
      {timelineHtml}
      <hr />
      <div>Site: {stop.node?.name ?? '-'}</div>
      <div>External Reference: {stop.node?.externalReference ?? '-'}</div>
    </div>
  );

  return renderToString(html);
};

export const getOrderStopContent = (stop: TripStop) => {
  const loadingOrders = _.filter(
    stop.orders,
    (o) => o.upliftPoint.id === stop.node?.id
  );
  const offloadingOrders = _.filter(
    stop.orders,
    (o) => o.offloadPoint.id === stop.node?.id
  );
  const loadingOrderNumbers = _.map(loadingOrders, 'orderNumber').join(', ');
  const offloadingOrderNumbers = _.map(offloadingOrders, 'orderNumber').join(
    ', '
  );
  if (loadingOrderNumbers || offloadingOrderNumbers) {
    const html = (
      <div>
        <div key="upliftments">Loading: {loadingOrderNumbers || 'None'}</div>
        <div key="offloads">Offloading: {offloadingOrderNumbers || 'None'}</div>
      </div>
    );
    return renderToString(html);
  }
  return `${stop.node?.name}`;
};

export const getOrderTooltip = (order: any) => {
  const html = (
    <div>
      <div key="orderNumber">
        <strong># {order.orderNumber}</strong>
      </div>
      <hr />
      <div key="upliftPoint">From: {order.upliftPoint.name}</div>
      <div key="offloadPoint">To: {order.offloadPoint.name}</div>
    </div>
  );
  return renderToString(html);
};
