import React from 'react';
import {
  NUMBER_ERROR_MESSAGE,
  TemplateCard,
  TemplateForm,
} from '../../../../factory/template';
import {VehicleTypeCompartmentContext} from '../VehicleTypeCompartmentContext';
import * as Yup from 'yup';
import {FormikSubmit, FormikTextField} from '../../../formik';
import {useVehicleTypeCompartmentFormStyles} from './VehicleTypeCompartmentForm.style';
import {VehicleTypeCompartmentTruck} from '../vehicleTypeCompartmentTruck';

export interface IVehicleTypeCompartmentForm {
  loadingSequence: number;
  position?: number;
  name: string;
  offloadingSequence: number;
}

export const VehicleTypeCompartmentForm: React.FC<{isAdd?: boolean}> = ({
  isAdd,
}) => {
  const classes = useVehicleTypeCompartmentFormStyles();
  const {
    detailsRef,
    loading,
    submitting,
    vehicleTypeCompartment,
    onDetailsFormSubmit,
    numOfCompartments,
  } = React.useContext(VehicleTypeCompartmentContext);
  const [initialValues, setInitialValues] =
    React.useState<IVehicleTypeCompartmentForm>();
  const [schema, setSchema] =
    React.useState<Yup.SchemaOf<IVehicleTypeCompartmentForm>>();
  const currentNumOfCompartments = React.useMemo(
    () => numOfCompartments + 1,
    [numOfCompartments]
  );

  React.useEffect(() => {
    if (vehicleTypeCompartment) {
      setInitialValues({
        loadingSequence:
          vehicleTypeCompartment.loadingSequence ?? currentNumOfCompartments,
        name: vehicleTypeCompartment.name ?? '',
        offloadingSequence:
          vehicleTypeCompartment.offloadingSequence ?? currentNumOfCompartments,
      });
    } else if (isAdd) {
      setInitialValues({
        loadingSequence: currentNumOfCompartments,
        offloadingSequence: currentNumOfCompartments,
        name: `Compartment ${currentNumOfCompartments}`,
      });
    }
    setSchema(
      Yup.object({
        loadingSequence: Yup.number()
          .typeError(NUMBER_ERROR_MESSAGE)
          .max(currentNumOfCompartments)
          .required('Required'),
        offloadingSequence: Yup.number()
          .typeError(NUMBER_ERROR_MESSAGE)
          .max(currentNumOfCompartments)
          .required('Required'),
        position: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
        name: Yup.string().required('Required'),
      })
    );
    return () => {
      setInitialValues(undefined);
      setSchema(undefined);
    };
  }, [isAdd, vehicleTypeCompartment, currentNumOfCompartments]);

  return (
    <TemplateCard
      title={`Vehicle Type Compartment ${isAdd ? 'Add' : 'Edit'}`}
      loading={loading}
    >
      <TemplateForm<IVehicleTypeCompartmentForm>
        initialValues={initialValues}
        onSubmit={onDetailsFormSubmit}
        permission={{name: 'Edit VehicleType', type: 'Edit'}}
        submitting={submitting}
        innerRef={detailsRef}
        validationSchema={schema}
        disableActions
        disableIsDirty
      >
        {({values}) => (
          <div className={classes.root}>
            <div className={classes.form}>
              <FormikTextField
                name="name"
                placeholder="Enter the vehicle type capacity name"
                label="Name"
                fullWidth
              />
              <FormikTextField
                name="loadingSequence"
                placeholder="Enter the vehicle type loading sequence"
                label="Loading Sequence"
                fullWidth
              />
              <FormikTextField
                name="offloadingSequence"
                placeholder="Enter the vehicle type offloading sequence"
                label="Offloading Sequence"
                fullWidth
              />
              <FormikSubmit
                loading={submitting}
                permission={{name: 'Edit VehicleType', type: 'Edit'}}
                disableIsDirty
              />
            </div>
            <VehicleTypeCompartmentTruck values={values} isAdd={isAdd} />
          </div>
        )}
      </TemplateForm>
    </TemplateCard>
  );
};
