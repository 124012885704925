import React from 'react';
import {TripSetupStopsStepViewStates} from './TripSetupStopsStep';

interface TripSetupStopsStepContextProps {
  setViewState: React.Dispatch<
    React.SetStateAction<TripSetupStopsStepViewStates>
  >;
}

export const TripSetupStopsStepContext =
  React.createContext<TripSetupStopsStepContextProps | null>(null);

export const useTripSetupStopsStepContext = () => {
  const tripSetupStopsStepContext = React.useContext(TripSetupStopsStepContext);

  if (tripSetupStopsStepContext == null) {
    throw new Error(
      'useTripSetupStopsStepContext has to be used within <TripSetupStopsStepContext.Provider>'
    );
  }

  return tripSetupStopsStepContext;
};
