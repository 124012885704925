import React from 'react';
import {Drawer, Stack} from '@mui/material';
import {usePlanningBoardGanttContext} from '../planningBoardContext';
import {PlanningBoardTripController} from '../planningBoardPlan/planningBoardTrip/PlanningBoardTripController';
import {usePlanningBoardLayoutStyles} from './PlanningBoardPanelsStyles';
import {PlanningBoardBookingController} from '../planningBoardPlan/planningBoardBooking';

export const PlanningBoardRightPanel: React.FC = () => {
  const classes = usePlanningBoardLayoutStyles();
  const {selectedGanttItem, onClearSelectedGanttItem} =
    usePlanningBoardGanttContext();

  return (
    <Drawer
      open={!!selectedGanttItem}
      onClose={onClearSelectedGanttItem}
      variant="persistent"
      anchor="right"
    >
      <Stack className={classes.rightPanel}>
        {selectedGanttItem?.variant === 'booking' ? (
          <PlanningBoardBookingController />
        ) : (
          <PlanningBoardTripController />
        )}
      </Stack>
    </Drawer>
  );
};
