import React from 'react';
import {MasterTripQuery} from '@onroadvantage/onroadvantage-api';
import {ToggleButton, ToggleButtonGroup} from '@mui/material';
import {LocalShipping, PhoneAndroid} from '@mui/icons-material';
import {TripContextProps, useTripContext} from '../tripContext';
import {timelineDisplayStore} from '../../../stores/mobxStores';
import {inject, observer} from 'mobx-react';

export const TripTimelineVehicleMobileSwitcher = inject('timelineDisplayStore')(
  observer(() => {
    const {masterTrip, trackingType, updateTrackingType, loadTelematicsEvents} =
      useTripContext();
    const {trip} = masterTrip || ({} as MasterTripQuery);
    const {telematicsEventsDateRange} = timelineDisplayStore;

    const tripHasVehicle = React.useMemo(
      () => trip?.vehicle?.id,
      [trip?.vehicle?.id]
    );

    const tripHasMobile = React.useMemo(
      () => trip?.mobile?.id,
      [trip?.mobile?.id]
    );

    const handleChangeTrackingType = React.useCallback(
      async (
        _event: React.MouseEvent<HTMLElement>,
        newValue: TripContextProps['trackingType'] | null
      ) => {
        if (!newValue) {
          return;
        }

        const didUpdate = updateTrackingType(newValue);

        /** Only reload the telematics events if the trackingType did update */
        if (didUpdate) {
          await loadTelematicsEvents({
            reload: true,
            trackingType: newValue,
            dateRange: telematicsEventsDateRange,
          });
        }
      },
      [loadTelematicsEvents, telematicsEventsDateRange, updateTrackingType]
    );

    return (
      <ToggleButtonGroup
        value={trackingType}
        exclusive={true}
        onChange={handleChangeTrackingType}
        aria-label="device"
      >
        <ToggleButton
          value="vehicle"
          aria-label="vehicle"
          disabled={!tripHasVehicle}
        >
          <LocalShipping />
        </ToggleButton>
        <ToggleButton
          value="mobile"
          aria-label="mobile"
          disabled={!tripHasMobile}
        >
          <PhoneAndroid />
        </ToggleButton>
      </ToggleButtonGroup>
    );
  })
);
