import React from 'react';
import {useHistory} from 'react-router-dom';
import _ from 'lodash';
import {
  ApiVehicleGetRequest,
  Vehicle,
  VehiclePost,
  VehicleUpdate,
  VehicleListResponse,
} from '@onroadvantage/onroadvantage-api';
import {FormikHelpers, FormikProps} from 'formik/dist/types';
import {vehicleApi} from '../../api';
import {
  TemplateTableContextProps,
  TLoadList,
  useTemplateTable,
} from '../../factory/template';
import {useAppNotifications} from '../../contexts';
import {IVehicleForm} from './vehicleDetails';

export interface VehicleContextProps
  extends TemplateTableContextProps<Vehicle, VehicleListResponse> {
  onDetailsFormSubmit: (
    values: IVehicleForm,
    formikHelpers: FormikHelpers<IVehicleForm>
  ) => void;
  setVehicleId: (value: number | undefined) => void;
  submitting: boolean;
  vehicle?: Vehicle;
  vehicleId?: number;
  detailsRef?: React.Ref<FormikProps<IVehicleForm>>;
}

export const VehicleContext = React.createContext<VehicleContextProps>({
  // Template Table Defaults
  loading: false,
  list: [],
  currentPage: 1,
  // Vehicle
  loadList: async () => {},
  onDetailsFormSubmit: () => null,
  setVehicleId: () => null,
  submitting: false,
});

interface VehicleContextProviderProps {
  vehicleId?: number;
}

export const VehicleContextProvider: React.FC<VehicleContextProviderProps> = ({
  children,
}) => {
  const history = useHistory();
  const notify = useAppNotifications();
  // Template Table
  const [
    {
      loading,
      loadingSingleItem,
      sorting,
      list,
      pageSize,
      pageTotal,
      hasPermission,
      itemTotal,
      currentPage,
      filters,
    },
    {
      // Getters
      getDownloads,
      getResponse,
      getRequestObj,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
      setLoadingSingleItem,
    },
  ] = useTemplateTable<Vehicle, ApiVehicleGetRequest>({
    editPermission: 'Edit Vehicle',
    addPermission: 'Add Vehicle',
    deletePermission: 'Delete Vehicle',
    downloadPermission: 'Vehicle ListDownload',
    viewPermission: 'Vehicle List',
  });
  // vehicle
  const [vehicle, setVehicle] = React.useState<Vehicle>();
  const [vehicleId, setVehicleId] = React.useState<number | undefined>();
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const detailsRef = React.useRef<FormikProps<IVehicleForm>>(null);

  const loadList = React.useCallback<TLoadList<VehicleListResponse>>(
    async (options) => {
      setLoading(true);
      try {
        const requestObj = getRequestObj(
          [
            'registrationNumber',
            'description',
            'contractCode',
            'vehicleType',
            'fleetNumber',
            'providerName',
            'mixOrganisationGroupName',
          ],
          options
        );
        const response = await vehicleApi.apiVehicleGet(requestObj);
        return getResponse(response, options);
      } catch (e) {
        notify('error', 'Failed to load Vehicle List');
      } finally {
        setLoading(false);
      }
    },
    [getRequestObj, getResponse, notify, setLoading]
  );

  const loadVehicle = React.useCallback(async () => {
    setLoadingSingleItem(true);
    try {
      if (vehicleId) {
        const response = await vehicleApi.apiVehicleVehicleIdGet({
          vehicleId,
        });
        setVehicle(response);
      } else {
        setVehicle(undefined);
      }
    } catch (e) {
      notify('error', 'Failed to load Vehicle');
    } finally {
      setLoadingSingleItem(false);
    }
  }, [notify, setLoadingSingleItem, vehicleId]);

  const handleDetailsFormSubmit = React.useCallback(
    async (
      values: IVehicleForm,
      formikHelpers: FormikHelpers<IVehicleForm>
    ) => {
      setSubmitting(true);
      try {
        const {contract, vehicleGroups, vehicleType, ...otherValues} = values;
        const vehicleGroupIds = values.vehicleGroups
          ?.filter(({value}) => value)
          ?.map(({value}) => value as number);
        const newValues = {
          ...vehicle,
          ...otherValues,
          contractId: parseInt(_.get(values, 'contract.value'), 10),
          vehicleGroupIds: vehicleGroupIds ? vehicleGroupIds : undefined,
          vehicleTypeId: parseInt(_.get(values, 'vehicleType.value'), 10),
        };
        if (vehicleId) {
          await vehicleApi.apiVehicleVehicleIdPatch({
            vehicleId,
            body: newValues as VehicleUpdate,
          });
        } else {
          await vehicleApi.apiVehiclePost({
            body: newValues as VehiclePost,
          });
        }
        history.push('/vehiclelist');
        notify('success', `${vehicleId ? 'Updated' : 'Added'} Vehicle`);
      } finally {
        formikHelpers.setSubmitting(false);
        setSubmitting(false);
      }
    },
    [history, notify, vehicle, vehicleId]
  );

  const handleDownload = React.useCallback(async () => {
    getDownloads('vehicles', loadList, [
      {name: 'Vehicle Registration', path: 'registrationNumber'},
      {name: 'Description/Fleet', path: 'description'},
      {name: 'Fleet Number', path: 'fleetNumber'},
      {name: 'Last Odometer', path: 'lastOdometer'},
      {name: 'Contract Code', path: 'contract.code'},
      {name: 'Vehicle Type', path: 'type.name'},
      {name: 'Last Seen', path: 'lastSeen.eventDate'},
      {name: 'Config', path: 'telematicsConfigId'},
    ]);
  }, [getDownloads, loadList]);

  const handleAdd = React.useCallback(
    () => history.push('/vehiclelist/add'),
    [history]
  );

  const handleNavigate = React.useCallback(
    (row: Vehicle) => history.push(`/vehiclelist/${row.id}`),
    [history]
  );

  const handleAuxNavigate = React.useCallback(
    (row: Vehicle) =>
      window.open(
        history.createHref({pathname: `/vehiclelist/${row.id}`}),
        '_blank'
      ),
    [history]
  );

  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  const value: VehicleContextProps = {
    // Template Table
    loading: loadingSingleItem || loading,
    hasPermission,
    list,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onAdd: hasPermission.add ? handleAdd : undefined,
    onNavigate: handleNavigate,
    onAuxNavigate: handleAuxNavigate,
    onDownload: hasPermission.download ? handleDownload : undefined,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onRefresh: handleRefresh,
    // Vehicle
    loadList,
    cleanupList,
    onDetailsFormSubmit: handleDetailsFormSubmit,
    setVehicleId,
    submitting,
    vehicle,
    vehicleId,
    detailsRef,
  };

  React.useEffect(() => {
    loadVehicle();
    return () => setVehicle(undefined);
  }, [loadVehicle]);

  return (
    <VehicleContext.Provider value={value}>{children}</VehicleContext.Provider>
  );
};
