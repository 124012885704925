import {DateTime} from 'luxon';
import {SettingService} from '..';
import {DATE_FORMAT_NAME, GLOBAL_NAME} from '../SettingService';

export class DateTimeFormatService {
  isDate = (d: Date | string): boolean => {
    return d instanceof Date;
  };

  toDateTime = (date: Date | DateTime | string | any): DateTime | undefined => {
    if (date instanceof DateTime) {
      return date;
    } else if (date instanceof Date) {
      return DateTime.fromJSDate(date);
    } else if (typeof date === 'string') {
      try {
        return DateTime.fromISO(date);
      } catch (e) {
        return undefined;
      }
    } else {
      return undefined;
    }
  };

  formatDateTimeToLocale = (
    date: Date | DateTime | string,
    withSeconds = false
  ): string => {
    const dt = this.toDateTime(date);

    if (!dt) {
      return '-';
    }

    if (withSeconds) {
      return dt.toLocaleString({...DateTime.DATETIME_SHORT_WITH_SECONDS});
    }
    return dt.toLocaleString({...DateTime.DATETIME_SHORT});
  };

  formatDateToLocale = (date: Date | DateTime | string): string => {
    const dt = this.toDateTime(date);

    if (!dt) {
      return '-';
    }

    return dt.toLocaleString({...DateTime.DATE_SHORT});
  };

  formatDateTimeToDefault = (
    date: Date | DateTime | string,
    withSeconds = false
  ): string => {
    const dt = this.toDateTime(date);
    if (!dt) {
      return '-';
    }
    if (withSeconds) {
      return dt.toFormat('yyyy-MM-dd HH:mm:ss');
    }
    return dt.toFormat('yyyy-MM-dd HH:mm');
  };

  formatDateTime = (date: Date | string, withSeconds = false): string => {
    const dateFormat = SettingService.getSetting(DATE_FORMAT_NAME, GLOBAL_NAME);
    switch (dateFormat) {
      case 'locale':
        return this.formatDateTimeToLocale(date, withSeconds);
      case 'default':
      default:
        return this.formatDateTimeToDefault(date, withSeconds);
    }

    // should never get here
    return '-';
  };

  formatDate = (date: Date | DateTime | string): string => {
    const dateFormat = SettingService.getSetting(DATE_FORMAT_NAME, GLOBAL_NAME);
    switch (dateFormat) {
      case 'locale':
        return this.formatDateToLocale(date);
      case 'default':
      default:
        return this.formatDateTimeToDefault(date);
    }
  };
}
