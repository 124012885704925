import React from 'react';
import {inject, observer} from 'mobx-react';
import {CircleMarker} from 'react-leaflet';
import StopPopup from './popup/StopPopup';

interface Props {
  position: [number, number]; // lat , lng
  stop: any;
  color?: string;
}

class PlanningMarker extends React.Component<Props> {
  render() {
    const {position, stop, color} = this.props;

    return (
      <CircleMarker center={position} radius={5} color={color} fill={true}>
        {stop && <StopPopup stop={stop} />}
      </CircleMarker>
    );
  }
}

export default inject('mapDisplayStore')(observer(PlanningMarker));
