import React from 'react';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../factory/template';
import {ContactContext} from './ContactContext';
import {Contact} from '@onroadvantage/onroadvantage-api';

export const ContactList: React.FC = () => {
  const {loadList, cleanupList} = React.useContext(ContactContext);

  const [config] = React.useState<TemplateTableConfig<Contact>>({
    columns: [
      {name: 'name', label: 'Contact Name', type: 'string'},
      {name: 'email', label: 'Email', type: 'string'},
      {name: 'cellphoneNumber', label: 'Cellphone Number', type: 'string'},
      {
        name: 'type',
        label: 'Notification Type',
        type: 'string',
        getValue: ({type}) => (type === '0' || !type ? 'Normal' : type),
      },
      {
        name: 'contactGroup',
        label: 'Contact Group',
        type: 'string',
      },
      {name: 'smsBan', label: 'Sms Ban', type: 'bool'},
      {name: 'callBan', label: 'Call Ban', type: 'bool'},
      {name: 'whatsappBan', label: 'Whatsapp Ban', type: 'bool'},
      {name: 'emailBan', label: 'Email Ban', type: 'bool', enableFilter: false},
      {
        name: 'notificationBanStart',
        label: 'Notification Ban Start',
        type: 'time',
        enableFilter: false,
      },
      {
        name: 'notificationBanEnd',
        label: 'Notification Ban End',
        type: 'time',
        enableFilter: false,
      },
    ],
    enableFilter: true,
    enableSort: true,
    deleteMessage: (row) => ({
      title: 'Contact',
      items: {Name: row.name, Email: row.email, Description: row.description},
    }),
    identifier: 'CONTACT_LIST',
  });

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [cleanupList, loadList]);

  return <TemplateTableWrapper config={config} context={ContactContext} />;
};
