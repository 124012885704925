import React from 'react';
import Lottie from 'react-lottie';
import optimisationLoaderAnimation from '../../img/json/optimisationLoader.json';
import vantageLoadingAnimation from '../../img/json/vantageLoading.json';

const styles = {
  container: {
    display: 'flex',
    justifySelf: 'center',
    alignSelf: 'center',
    flexDirection: 'column',
  },
  textContainer: {
    display: 'flex',
    justifySelf: 'center',
    alignSelf: 'center',
    flexDirection: 'column',
    padding: 10,
    fontSize: 20,
  },
};

export default class OptimisationLargeLoader extends React.Component {
  render() {
    return (
      <div style={styles.container}>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: optimisationLoaderAnimation,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
          height="100%"
          width="100%"
          isStopped={false}
          isPaused={false}
          isClickToPauseDisabled
        />
        <div
          style={{
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            top: '40%',
          }}
        >
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: vantageLoadingAnimation,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
            height={60}
            width={60}
            isStopped={false}
            isPaused={false}
            isClickToPauseDisabled
          />
          <div style={styles.textContainer}>Optimising...</div>
        </div>
      </div>
    );
  }
}
