import React from 'react';
import {VehicleGroupContext} from './VehicleGroupContext';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../factory/template';
import {VehicleGroup} from '@onroadvantage/onroadvantage-api';

export const VehicleGroupList: React.FC = () => {
  const {loadList, cleanupList} = React.useContext(VehicleGroupContext);

  const [config] = React.useState<TemplateTableConfig<VehicleGroup>>({
    columns: [{name: 'name', label: 'Vehicle Group Name', type: 'string'}],
    enableFilter: true,
    enableSort: true,
    deleteMessage: (row) => ({title: 'Vehicle Group', items: {Name: row.name}}),
    identifier: 'VEHICLE_GROUP_LIST',
  });

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [loadList, cleanupList]);

  return <TemplateTableWrapper config={config} context={VehicleGroupContext} />;
};
