import L from 'leaflet';

interface Props {
  position: [number, number]; // lat , lng
  onDrag: (arg: any) => void;
  onDragEnd: (arg: any) => void;
}

const MasterRouteDraggableMarker = (props: Props) => {
  const Icon = L.ExtraMarkers.icon({
    icon: 'fa-number',
    markerColor: 'green',
    shape: 'penta',
    prefix: 'fa',
    number: `new`,
  });

  const {position, onDrag, onDragEnd} = props;

  return new L.Marker(position, {draggable: true, icon: Icon})
    .on('drag', onDrag)
    .on('dragend', onDragEnd);
};

export {MasterRouteDraggableMarker};
