import React from 'react';
import {Typography} from '@mui/material';
import {useAdminTelematicsConfigDetailsItem} from './AdminTelematicsConfigDetailsItem.style';
import {DateTimeFormatService} from '../../../../service/FormatService';

interface Props {
  title: string;
  value?: string | number | boolean | Date;
}

export const AdminTelematicsConfigDetailsItem: React.FC<Props> = ({
  title,
  value,
}) => {
  const classes = useAdminTelematicsConfigDetailsItem();

  const [parsedValue, setParsedValue] = React.useState<string | number>();

  React.useEffect(() => {
    if (value === undefined) {
      setParsedValue('-');
    } else if (typeof value === 'boolean') {
      setParsedValue(value ? 'Yes' : 'No');
    } else if (typeof value === 'number') {
      setParsedValue(value ?? '-');
    } else if (
      value instanceof Date ||
      new DateTimeFormatService().toDateTime(value)?.isValid
    ) {
      setParsedValue(new DateTimeFormatService().formatDateTime(value));
    } else {
      setParsedValue(value);
    }
  }, [setParsedValue, value]);

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>{title}:</Typography>
      <Typography className={classes.value}>{parsedValue}</Typography>
    </div>
  );
};
