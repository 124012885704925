import React from 'react';
import {
  TemplateTable,
  TemplateTableConfig,
  TOnInlineAdd,
} from '../../../factory/template';
import {Listing, CustomerContactGroup} from '@onroadvantage/onroadvantage-api';
import {eventNotificationConfigApi} from '../../../api';
import {useAppNotifications} from '../../../contexts';
import {RoleService} from '../../../service';
import {NotificationConfigOperationalEventContext} from './NotificationConfigOperationalEventContext';

export const NotificationConfigOperationalEventCustomerContacts: React.FC =
  () => {
    const notify = useAppNotifications();
    const {
      notificationConfigEvent,
      notificationConfigEventId,
      loading,
      loadNotificationConfigEvent,
    } = React.useContext(NotificationConfigOperationalEventContext);
    const [localLoading, setLocalLoading] = React.useState<boolean>(false);

    const [config] = React.useState<TemplateTableConfig<CustomerContactGroup>>({
      columns: [
        {
          name: 'contactGroup',
          label: 'Contact Group Name',
          type: 'string',
          model: 'CustomerContactGroup',
          enableEditing: true,
          enableAutocomplete: true,
          enableMulti: true,
          getValue: ({contactGroup}) => contactGroup?.name,
        },
        {
          name: 'customer',
          label: 'Customer Name',
          type: 'string',
          enableEditing: false,
          getValue: ({customer}) => customer?.name,
        },
      ],
      disableToolbar: true,
      disablePagination: true,
      deleteMessage: (row) => ({
        title: 'Notification Config Task Customer Contact',
        items: {
          DocumentType: row.contactGroup?.name,
          Customer: row.customer?.name,
        },
      }),
      identifier:
        'OPERATIONAL_EVENT_NOTIFICATION_CONFIG_CUSTOMER_CONTACTS_LIST',
    });

    const handleInlineAdd = React.useCallback<TOnInlineAdd>(
      async (changes) => {
        setLocalLoading(true);
        try {
          for (const change of changes) {
            const customerContactListings: Listing[] = change.contactGroup;
            const customerContactIds = customerContactListings
              .filter(({value}) => value)
              .map(({value}) => value as number);
            if (notificationConfigEventId) {
              await eventNotificationConfigApi.apiEventNotificationConfigEventNotificationConfigIdAssignCustomerContactsPost(
                {
                  eventNotificationConfigId: notificationConfigEventId,
                  body: {customerContactIds},
                }
              );
              await loadNotificationConfigEvent();
              notify('success', 'Successfully assigned customers');
            }
          }
        } catch (e) {
          notify('error', e.message ?? 'Failed to assign customers');
        } finally {
          setLocalLoading(false);
        }
      },
      [loadNotificationConfigEvent, notificationConfigEventId, notify]
    );

    const handleInlineDelete = React.useCallback(
      async (row: CustomerContactGroup) => {
        setLocalLoading(true);
        try {
          if (notificationConfigEventId && row.id) {
            await eventNotificationConfigApi.apiEventNotificationConfigEventNotificationConfigIdUnassignCustomerContactPost(
              {
                eventNotificationConfigId: notificationConfigEventId,
                body: {customerContactId: row.id},
              }
            );
            notify('success', 'Successfully unassigned customer');
            await loadNotificationConfigEvent();
          }
        } catch (e) {
          notify('error', e.message ?? 'Failed to unassign customer');
        } finally {
          setLocalLoading(false);
        }
      },
      [loadNotificationConfigEvent, notificationConfigEventId, notify]
    );

    return (
      <TemplateTable
        config={config}
        list={notificationConfigEvent?.customerContactGroups ?? []}
        currentPage={1}
        loading={loading || localLoading}
        onDelete={
          RoleService.hasPermission('Edit NotificationConfig', 'Edit')
            ? handleInlineDelete
            : undefined
        }
        onInlineAdd={
          RoleService.hasPermission('Edit NotificationConfig', 'Edit')
            ? handleInlineAdd
            : undefined
        }
      />
    );
  };
