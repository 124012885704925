import React from 'react';
import {TripDebriefContext} from '../../TripDebriefContext';
import {useParams} from 'react-router-dom';

interface IParams {
  tripStopId?: string;
}

export const TripDebriefSummaryStopIdHandler: React.FC = () => {
  const {setTripStopId} = React.useContext(TripDebriefContext);

  const {tripStopId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = tripStopId ? parseInt(tripStopId) : undefined;
    setTripStopId(parsedId);
    return () => setTripStopId(undefined);
  }, [tripStopId, setTripStopId]);

  return null;
};
