import React from 'react';
import {useHistory} from 'react-router-dom';
import {
  Dashboard,
  DashboardList,
  DashboardPost,
  ApiDashboardGetRequest,
} from '@onroadvantage/onroadvantage-api';
import {FormikHelpers, FormikProps} from 'formik/dist/types';
import {dashboardApi} from '../../api';
import {
  TemplateTableContextProps,
  TLoadList,
  useTemplateTable,
} from '../../factory/template';
import {useAppNotifications} from '../../contexts';
import {IDashboardForm} from './DashboardForm';

export interface DashboardContextProps
  extends TemplateTableContextProps<Dashboard, DashboardList> {
  onDetailsFormSubmit: (
    values: IDashboardForm,
    formikHelpers: FormikHelpers<IDashboardForm>
  ) => void;
  setDashboardId: (value: number | undefined) => void;
  submitting: boolean;
  loadingSingleItem: boolean;
  dashboard?: Dashboard;
  dashboardId?: number | null;
  detailsRef?: React.RefObject<FormikProps<IDashboardForm>>;
}

export const DashboardContext = React.createContext<DashboardContextProps>({
  // Template Table Defaults
  loading: false,
  list: [],
  currentPage: 1,
  // Dashboard
  loadList: async () => {},
  onDetailsFormSubmit: () => null,
  setDashboardId: () => null,
  submitting: false,
  loadingSingleItem: false,
});

interface DashboardContextProviderProps {
  dashboardId?: number;
}

export const DashboardContextProvider: React.FC<
  DashboardContextProviderProps
> = ({children}) => {
  const history = useHistory();
  const notify = useAppNotifications();
  // Template Table
  const [
    {
      // States
      currentPage,
      filters,
      hasPermission,
      itemTotal,
      list,
      loading,
      pageSize,
      pageTotal,
      loadingSingleItem,
      sorting,
    },
    {
      // Getters
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
      setLoadingSingleItem,
    },
  ] = useTemplateTable<Dashboard, ApiDashboardGetRequest>({
    editPermission: 'Edit Dashboard',
    addPermission: 'Add Dashboard',
    deletePermission: 'Delete Dashboard',
    downloadPermission: 'Dashboard ListDownload',
    viewPermission: 'Dashboard List',
  });

  const loadList = React.useCallback<TLoadList<DashboardList>>(
    async (options) => {
      setLoading(true);
      try {
        const requestObj = getRequestObj(['name'], options);
        const response = await dashboardApi.apiDashboardGet({...requestObj});
        return getResponse(response, options);
      } catch (e) {
        notify('error', e.message ?? 'Failed to load dashboard list');
      } finally {
        setLoading(false);
      }
    },
    [setLoading, getRequestObj, getResponse, notify]
  );

  const handleDelete = React.useCallback(
    async (row: Dashboard) => {
      setLoading(true);
      try {
        if (row.id) {
          await dashboardApi.apiDashboardDashboardIdDelete({
            dashboardId: row.id,
          });
          await loadList();
          notify('success', 'Dashboard Deleted');
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to delete dashboard');
      } finally {
        setLoading(false);
      }
    },
    [loadList, notify, setLoading]
  );

  const handleAdd = React.useCallback(() => {
    history.push('/dashboardlist/add');
  }, [history]);

  const handleNavigate = React.useCallback(
    (row: Dashboard) => {
      history.push(`/dashboardlist/${row.id}`);
    },
    [history]
  );

  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  // dashboard
  const [dashboard, setDashboard] = React.useState<Dashboard | undefined>();
  const [dashboardId, setDashboardId] = React.useState<number | null>();
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const detailsRef = React.useRef<FormikProps<IDashboardForm>>(null);

  const loadDashboard = React.useCallback(async () => {
    setLoadingSingleItem(true);
    try {
      if (dashboardId) {
        const response = await dashboardApi.apiDashboardDashboardIdGet({
          dashboardId,
        });
        setDashboard(response);
      } else {
        setDashboard(undefined);
      }
    } catch (e) {
      notify('error', e.message ?? 'Failed to load dashboard');
    } finally {
      setLoadingSingleItem(false);
    }
  }, [setLoadingSingleItem, dashboardId, notify]);

  const handleDetailsFormSubmit = React.useCallback(
    async (
      values: IDashboardForm,
      formikHelpers: FormikHelpers<IDashboardForm>
    ) => {
      setSubmitting(true);
      try {
        const {type, ...otherValues} = values;
        if (type?.value) {
          const newValues: DashboardPost = {
            ...otherValues,
            lastRefresh: new Date(),
            type: String(type?.value),
          };
          if (dashboard && dashboard.id) {
            await dashboardApi.apiDashboardDashboardIdPatch({
              dashboardId: dashboard.id,
              body: newValues,
            });
          } else {
            await dashboardApi.apiDashboardPost({
              body: newValues,
            });
          }
          history.push('/dashboardlist');
          notify('success', `${dashboard?.id ? 'Updated' : 'Added'} Dashboard`);
        }
      } catch (e) {
        notify(
          'error',
          e.message ?? `Failed to ${dashboard?.id ? 'update' : 'add'} dashboard`
        );
      } finally {
        formikHelpers.setSubmitting(false);
        setSubmitting(false);
      }
    },
    [dashboard, history, notify]
  );

  const value: DashboardContextProps = {
    // Template Table
    loadList,
    cleanupList,
    loading,
    list,
    hasPermission,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    grouping: [{columnName: 'category'}],
    onAdd: hasPermission.add ? handleAdd : undefined,
    onNavigate: handleNavigate,
    onDelete: hasPermission.delete ? handleDelete : undefined,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onRefresh: handleRefresh,
    // Dashboard
    onDetailsFormSubmit: handleDetailsFormSubmit,
    loadingSingleItem,
    setDashboardId,
    submitting,
    dashboard,
    dashboardId,
    detailsRef,
  };

  React.useEffect(() => {
    loadDashboard();
    return setDashboard(undefined);
  }, [loadDashboard]);

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
};
