import React from 'react';
import {ContactGroupForm} from './ContactGroupForm';
import {ITemplateTabControllerTab, TemplateTabs} from '../../factory/template';
import {ContactGroupContext} from './ContactGroupContext';
import {ContactGroupContacts} from './ContactGroupContacts';
import {ContactGroupContracts} from './ContactGroupContracts';
import {ContactGroupNodes} from './ContactGroupNodes';
import {ContactGroupCustomers} from './ContactGroupCustomers';
import {ContactGroupVehicles} from './ContactGroupVehicles';

export const ContactGroup: React.FC = () => {
  const {contactGroupId, loading} = React.useContext(ContactGroupContext);

  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {title: 'Details', element: <ContactGroupForm />},
    {title: 'Contacts', element: <ContactGroupContacts />},
    {title: 'Contracts', element: <ContactGroupContracts />},
    {title: 'Customers', element: <ContactGroupCustomers />},
    {title: 'Nodes', element: <ContactGroupNodes />},
    {title: 'Vehicle Groups', element: <ContactGroupVehicles />},
  ]);

  const path = React.useMemo(
    () => `/contactgrouplist/${contactGroupId}`,
    [contactGroupId]
  );

  return <TemplateTabs tabs={tabs} path={path} loading={loading} />;
};
