import React from 'react';
import {Listing} from '@onroadvantage/onroadvantage-api';
import {debounce} from '@mui/material';
import {planningBoardApi} from '../../../../api';
import {useAppNotifications} from '../../../../contexts';
import {
  Autocomplete,
  TAutocompleteOnChange,
  TAutocompleteOnInputChange,
} from '../../../autocomplete';
import {usePlanningBoardContext} from '../../planningBoardContext';

export const PlanningBoardOptionsPlanningSkillAutocomplete: React.FC = () => {
  const notify = useAppNotifications();
  const {
    contractIds,
    customerIds,
    selectedPlanningSkillListings,
    setSelectedPlanningSkillListings,
  } = usePlanningBoardContext();
  const [localLoading, setLocalLoading] = React.useState<boolean>(false);
  const [listings, setListings] = React.useState<Listing[]>([]);

  const handleGetListings = React.useCallback(
    async (filter?: string) => {
      setLocalLoading(true);
      try {
        if (contractIds && contractIds.length > 0) {
          const response = await planningBoardApi.apiWebPlanningBoardListingGet(
            {
              contractIds: contractIds,
              customerIds: customerIds.length > 0 ? customerIds : undefined,
              model: 'PlanningSkill',
              qryFilter: filter,
            }
          );
          setListings(response.items ?? []);
        }
      } catch (e) {
        notify('error', 'Failed to load Listing');
      } finally {
        setLocalLoading(false);
      }
    },
    [contractIds, customerIds, notify]
  );

  const getListingDebounce = React.useRef(
    debounce(handleGetListings, 500)
  ).current;

  const handleInputChange = React.useCallback<TAutocompleteOnInputChange>(
    async (_event, value: string) => {
      await getListingDebounce(value);
    },
    [getListingDebounce]
  );

  React.useEffect(() => {
    handleGetListings();
    return () => setListings([]);
  }, [handleGetListings]);

  const handleChange = React.useCallback<TAutocompleteOnChange>(
    (_event, newValue) => {
      setSelectedPlanningSkillListings(newValue as Listing[]);
    },
    [setSelectedPlanningSkillListings]
  );

  return (
    <Autocomplete
      multiple
      name="planningSkill"
      onChange={handleChange}
      onInputChange={handleInputChange}
      options={listings}
      placeholder="Select a planning skill"
      label="Planning Skill(s)"
      value={selectedPlanningSkillListings}
      loading={localLoading}
    />
  );
};
