import * as Yup from 'yup';
import {
  IContractDetailsForm,
  IContractInspectionForm,
  IContractSetupForm,
  IContractTaskTemplateInlineForm,
  IContractTripExecutionForm,
  IContractTripNotificationsForm,
  IContractTripTimesForm,
} from './contractConstants';
import {
  listingSchema,
  multipleListingSchema,
  nullableListingSchema,
  NUMBER_ERROR_MESSAGE,
  requiredMultipleListingSchema,
} from '../../../factory/template';

export const contractDetailsSchema: Yup.SchemaOf<IContractDetailsForm> =
  Yup.object({
    name: Yup.string().required('Required'),
    code: Yup.string().required('Required'),
    node: listingSchema.required('Required'),
    telematicsConfig: listingSchema.required('Required'),
    honorRestSchedule: Yup.boolean().required('Required'),
    driverScoringEnabled: Yup.boolean().required('Required'),
  });

export const contractTaskTemplateSchema: Yup.SchemaOf<
  Omit<IContractTaskTemplateInlineForm, 'id'>
> = Yup.object({
  taskTemplate: listingSchema.required('Required'),
  nodeTypes: requiredMultipleListingSchema.required('Required'),
  mandatory: Yup.boolean(),
  sequence: Yup.number().typeError(NUMBER_ERROR_MESSAGE).required('Required'),
  forceSequence: Yup.boolean(),
  acknowledgeEnabled: Yup.boolean(),
  resetEnabled: Yup.boolean(),
});

export const contractTripTimesSchema: Yup.SchemaOf<IContractTripTimesForm> =
  Yup.object({
    shortBreakTrigger: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
    shortBreakDuration: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
    longBreakTrigger: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
    longBreakDuration: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
    maxShiftDrivingTime: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
    shiftDuration: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
    dailyBreakDuration: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
    maxWeeklyDuty: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
    weeklyBreak: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
    drivingBanStart: Yup.date().required('Required'),
    drivingBanEnd: Yup.date().required('Required'),
    actualCalculationPostBuffer: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
    actualCalculationPreBuffer: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
    targetHoursPerDay: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
    targetHoursPerVehiclePerDay: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
    targetKmPerDay: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
    targetKmPerVehiclePerDay: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
    driverTripQueryPreToleranceHours: Yup.number()
      .typeError(NUMBER_ERROR_MESSAGE)
      .required('Required'),
    driverTripQueryPostToleranceHours: Yup.number()
      .typeError(NUMBER_ERROR_MESSAGE)
      .required('Required'),
  });

export const contractTripNotificationsSchema: Yup.SchemaOf<IContractTripNotificationsForm> =
  Yup.object({
    emailTripsheet: Yup.boolean().required('Required'),
    etaConfidence: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
    voiceBroadcastEnabled: Yup.boolean(),
    etaCalcEnabled: Yup.boolean(),
  });

export const contractTripExecutionSchema: Yup.SchemaOf<IContractTripExecutionForm> =
  Yup.object({
    tripStopArrivalTimestampTaskTemplate: nullableListingSchema,
    tripStopDepartureTimestampTaskTemplate: nullableListingSchema,
    tripStopOdometerPriority: nullableListingSchema,
    tripStopTimestampPriority: nullableListingSchema,
    tripStopArrivalOdometerTaskTemplate: nullableListingSchema,
    tripStopDepartureOdometerTaskTemplate: nullableListingSchema,
    tripStopServiceEndTimestampTaskTemplate: nullableListingSchema,
    tripStopServiceStartTimestampTaskTemplate: nullableListingSchema,
    actualsStopMethodology: nullableListingSchema,
    banOrderTripDoubleAllocation: Yup.boolean(),
    tripEndDepartureTaskBan: Yup.boolean(),
    tripStartArrivalTaskBan: Yup.boolean(),
    orderLineActualLoadedQuantityEnabled: Yup.boolean(),
    allowMultipleOrdersInCompartment: Yup.boolean(),
    speedTables: multipleListingSchema,
  });

export const contractInspectionSchema: Yup.SchemaOf<IContractInspectionForm> =
  Yup.object({
    mandatoryDailyVehicleInspectionEnabled: Yup.boolean(),
    mandatoryDailyDriverInspectionEnabled: Yup.boolean(),
    vehicleInspectionTaskTemplate: nullableListingSchema,
    driverInspectionTaskTemplate: nullableListingSchema,
  });

export const contractSetupSchema: Yup.SchemaOf<IContractSetupForm> =
  Yup.object()
    .shape(contractDetailsSchema.fields)
    .shape(contractTripTimesSchema.fields)
    .shape(contractTripExecutionSchema.fields)
    .shape(contractTripNotificationsSchema.fields)
    .shape(contractInspectionSchema.fields);
