import React from 'react';
import {inject, observer} from 'mobx-react';
import _ from 'lodash';
import {CriticalEventsView} from './criticalEvents';
import {RiskView} from './risk';
import {UtilisationView} from './utilisation';
import DashboardService from '../../../service/DashboardService';
import {appNotificationStore} from '../../../stores/mobxStores';
import {Loader} from '../../loader';

class DashboardStandAlone extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dashboard: null,
      loading: true,
      refreshing: false,
      lastRefreshed: null,
      refreshFrequency: 60 * 1000,
      // retryEnabled - failsafe for componentWIllUnmount
      retryEnabled: true,
    };
  }

  componentDidMount = () => {
    this.getDashboardData();
    this.setRefreshInterval();
  };

  componentWillUnmount = () => {
    this.setState({retryEnabled: false});
    this.clearNetworkErrorTimer();
    this.clearRefreshInterval();
  };

  /**
   * Starts the refresh interval
   */
  setRefreshInterval = () => {
    if (!this.state.retryEnabled) {
      this.clearRefreshInterval();
      return;
    }
    const {refreshFrequency} = this.state;
    this.refreshIntervalHandle = setInterval(
      this.getDashboardData,
      refreshFrequency
    );
  };

  /**
   * Clears the refresh interval
   */
  clearRefreshInterval = () => {
    if (this.refreshIntervalHandle) {
      clearInterval(this.refreshIntervalHandle);
      this.refreshIntervalHandle = 0;
    }
  };

  /**
   * Sets the network error timer
   */
  setNetworkErrorTimer = () => {
    this.networkTimeoutHandle = setTimeout(this.setRefreshInterval, 60 * 1000);
    this.clearRefreshInterval();
  };

  /**
   * Clears the network error timer
   */
  clearNetworkErrorTimer = () => {
    if (this.networkTimeoutHandle) {
      clearTimeout(this.networkTimeoutHandle);
      this.networkTimeoutHandle = 0;
    }
  };

  /**
   * Sets the network too many requests timer
   */
  setNetworkTooManyRequests = (secondsFromNow) => {
    this.networkTimeoutHandle = setTimeout(
      this.getDashboardData,
      secondsFromNow * 1000
    );
    this.clearRefreshInterval();
  };

  getDashboardData = async () => {
    const {refreshing} = this.state;

    // skip attempt to load data if a previous request has not been completed
    if (refreshing) {
      return;
    }

    this.setState({refreshing: true});
    const {history} = this.props;

    try {
      const params = new URLSearchParams(this.props.location.search);
      const dashboardId = params.get('dashboardId');
      if (!dashboardId) {
        // TODO handle error
      }
      const intervalResponse = await DashboardService.getById(dashboardId);
      const dashboard = await intervalResponse.data;

      if (!dashboard && intervalResponse.error === '429 Occurred') {
        // handle 429
        const retry = parseInt(
          _.get(intervalResponse, 'retry.retryAfter', 60),
          10
        );
        this.setNetworkTooManyRequests(retry);
      } else if (!dashboard && intervalResponse.error === '404 Occurred') {
        // handle 404
        appNotificationStore.enqueueNotification(
          'error',
          `Dashboard not found`
        );
        history.push('/dashboardlist');
      } else if (!dashboard && intervalResponse.error === '401 Occurred') {
        this.clearNetworkErrorTimer();
        this.clearRefreshInterval();
        history.push(`/logout?redirectTo=${history.location.pathname}`);
      } else if (!dashboard && intervalResponse.error) {
        appNotificationStore.enqueueNotification(
          'error',
          `Unable to retrieve data`
        );
      } else {
        const newRefreshFrequency =
          _.get(dashboard, 'refreshFrequency', 60) * 1000;
        this.setState({
          dashboard,
          refreshFrequency: newRefreshFrequency,
          refreshing: false,
          lastRefreshed: new Date(),
        });
      }
    } catch (e) {
      switch (e.message) {
        case 'Connection Timeout':
        case 'NetworkError when attempting to fetch resource.': // FF
        case 'Failed to fetch': // Chrome
          appNotificationStore.enqueueNotification(
            'error',
            'Unable to reach server. Attempt reconnection in 1 minute.'
          );
          this.setNetworkErrorTimer();
          break;
        default:
          appNotificationStore.enqueueNotification(
            'error',
            'Unknown error occurred'
          );
      }
    }

    // ensure the page does not get "stuck" on refreshing or loading status
    this.setState({refreshing: false, loading: false});
  };

  render() {
    const {dashboard, loading, refreshing, lastRefreshed} = this.state;

    if (loading) {
      return <Loader />;
    }

    let DashboardObject;
    switch (_.get(dashboard, 'viewName')) {
      case 'dashboard_contract_fleet_risk_indicator':
        DashboardObject = UtilisationView;
        break;
      case 'dashboard_critical_event':
      case 'dashboard_operational_event':
        DashboardObject = CriticalEventsView;
        break;
      case 'dashboard_contract_time_analysis':
        DashboardObject = RiskView;
        break;
      default:
        // TODO redirect to 404???
        DashboardObject = () => <div>No dashboard found</div>;
    }

    return (
      <div
        style={{
          margin: 0,
          padding: 0,
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column',
          width: '100vw',
          height: '100vh',
          justifyContent: 'stretch',
          overflowX: 'hidden',
          overflowY: 'auto',
        }}
      >
        {loading ? (
          <Loader />
        ) : (
          <DashboardObject
            dashboard={dashboard}
            refreshing={refreshing}
            lastRefreshed={lastRefreshed}
          />
        )}
      </div>
    );
  }
}

export default inject('appNotificationStore')(observer(DashboardStandAlone));
