import {makeStyles, createStyles} from '@mui/styles';

const MasterRouteControllerStyles = () =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      minHeight: 10,
    },
    root: {},
  });

export const useMasterRouteControllerStyles = makeStyles(
  MasterRouteControllerStyles,
  {name: 'MasterRouteControllerStyles'}
);
