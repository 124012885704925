import React from 'react';
import {TripDebriefContext} from './TripDebriefContext';
import * as Yup from 'yup';
import {
  MasterTripDebriefSnapshotData,
  MasterTripDebriefTripStopExecutionSnapshot,
} from '@onroadvantage/onroadvantage-api';
import {
  TemplateCard,
  TemplateForm,
  TOnFormikSubmit,
} from '../../factory/template';
import {TripDebriefDisabledTextField} from './form/TripDebriefDisabledTextField';
import {useAppNotifications} from '../../contexts';
import {ITripDebriefForm} from './form/TripDebriefForm.types';
import {TripDebriefDateTimePicker} from './form/TripDebriefDateTimePicker';
import {getTripStopExecution} from './helpers';
import {DateTime} from 'luxon';
import {FormikDateTimePicker} from '../formik';

/** ITripDebriefTimesFormSnapshot -> For the editable field values that are overridable  */
export interface ITripDebriefTimesFormSnapshot {
  actualTripStart?: Date | null | undefined;
  actualTripEnd?: Date | null | undefined;
}

/**
 * ITripDebriefTimesForm -> For the interface typing of the form, which extends from ITripDebriefForm which is a
 * generic type that maps the ITripDebriefTimesFormSnapshot interface to a snapshot and override key. We then also
 * pass any other props the debrief has that is editable, but what is not overridable (in other words it's not in the
 * MasterTripDebriefSnapshot interface, only on the root MasterTripDebriefSnapshotData interface).
 */
export interface ITripDebriefTimesForm
  extends ITripDebriefForm<ITripDebriefTimesFormSnapshot> {
  completedDatetime?: Date | null | undefined;
}

/**
 * ITripDebriefTimesFormDisplayOnly -> For the form values that are not editable, thus we keep them separate from
 * the formik typing so that we can ensure they can't get edited by accident
 */
export interface ITripDebriefTimesFormDisplayOnly {
  plannedTripStart?: Date | null | undefined;
  actualTripStart?: Date | null | undefined;
  plannedTripEnd?: Date | null | undefined;
  actualTripEnd?: Date | null | undefined;
}

/**
 * snapshotValidation -> Validation schema for the ITripDebriefTimesFormSnapshot formik typed fields. We put them
 * in a separate schema so that we don't need to declare the schema twice in the root validationSchema.
 */
const snapshotValidation: Yup.SchemaOf<ITripDebriefTimesFormSnapshot> =
  Yup.object({
    actualTripStart: Yup.date().nullable(),
    actualTripEnd: Yup.date().nullable(),
  });

/** validationSchema -> Validation schema for the form's typing (ITripDebriefTimesForm)  put together */
const validationSchema: Yup.SchemaOf<ITripDebriefTimesForm> = Yup.object({
  completedDatetime: Yup.date().nullable(),
  snapshot: snapshotValidation,
  override: snapshotValidation,
});

/**
 * Reason why we want to keep the snapshot and override separate here is because in the TripDebriefTextField Component
 * we use the snapshot value as its initialValue, and the override value is its currentValue. Then when they don't match
 * we can give them the option to reset the changedValue (override value) back to the initialValue (snapshot value)
 */
const getInitialValues = (
  masterTripDebriefData: MasterTripDebriefSnapshotData | null | undefined
): ITripDebriefTimesForm | undefined => {
  if (masterTripDebriefData?.snapshotData) {
    const {snapshotData, overrideData, completedDatetime} =
      masterTripDebriefData;
    const {firstStopExecution, lastStopExecution} = getTripStopExecution(
      snapshotData?.stops,
      snapshotData?.contract
    );

    const overrideStops = overrideData?.stops;

    let firstStopExecutionOverride:
      | MasterTripDebriefTripStopExecutionSnapshot
      | null
      | undefined;

    let lastStopExecutionOverride:
      | MasterTripDebriefTripStopExecutionSnapshot
      | null
      | undefined;

    if (overrideStops && overrideStops.length > 0) {
      firstStopExecutionOverride = overrideStops[0]?.tripStopExecution;
      lastStopExecutionOverride =
        overrideStops[overrideStops.length - 1]?.tripStopExecution;
    }

    return {
      completedDatetime,
      snapshot: {
        actualTripStart: firstStopExecution?.mobileDepartureTime,
        actualTripEnd: lastStopExecution?.mobileArrivalTime,
      },
      override:
        firstStopExecutionOverride || lastStopExecutionOverride
          ? {
              actualTripStart: firstStopExecutionOverride?.mobileDepartureTime,
              actualTripEnd: lastStopExecutionOverride?.mobileArrivalTime,
            }
          : {},
    };
  }
  return undefined;
};

/**
 * Reason for keeping this separate from the getInitialValues, is that we want to keep editable and none-editable fields
 * separate to ensure none-editable don't get edited, as the BE doesn't currently check for the none-editable fields.
 */
const getDisplayOnlyValues = (
  masterTripDebriefData: MasterTripDebriefSnapshotData | null | undefined
): ITripDebriefTimesFormDisplayOnly | undefined => {
  if (masterTripDebriefData?.snapshotData) {
    const {snapshotData} = masterTripDebriefData;
    const {firstStopExecution, lastStopExecution} = getTripStopExecution(
      snapshotData?.stops,
      snapshotData?.contract
    );
    return {
      plannedTripStart: snapshotData?.plannedStartTime,
      actualTripStart: firstStopExecution?.gpsDepartureTime,
      plannedTripEnd: snapshotData?.plannedEndTime,
      actualTripEnd: lastStopExecution?.gpsArrivalTime,
    };
  }
  return undefined;
};

export const TripDebriefTimesForm: React.FC = () => {
  const notify = useAppNotifications();
  const {masterTripDebriefData, submitting, loading, onUpdateSubmit} =
    React.useContext(TripDebriefContext);
  /**
   *  Destructure masterTripDebriefData, need to add || ({} as MasterTripDebriefSnapshotData) for typescript, since
   *  masterTripDebriefData is nullable.
   */
  const {approvedForBilling} =
    masterTripDebriefData || ({} as MasterTripDebriefSnapshotData);

  const [initialValues, setInitialValues] = React.useState<
    ITripDebriefTimesForm | undefined
  >({override: {}, snapshot: {}});

  const [displayOnlyValues, setDisplayOnlyValues] = React.useState<
    ITripDebriefTimesFormDisplayOnly | undefined
  >();

  /**
   * handleSubmit -> Handles the formik's submit.
   * We extract the relevant ITripDebriefTimesForm values, such as override, and send them to the onUpdateSubmit
   * handler (from the Context) in the correct format whether it is overrideData (MasterTripDebriefSnapshot) or
   * debriefData (MasterTripDebrief). We also handle the formik submitting state in this formik handler, thus not making
   * it necessary for a formik innerRef.
   * We also add a notify exception for 'No data was change', but this should never be reached.
   */
  const handleSubmit = React.useCallback<
    TOnFormikSubmit<ITripDebriefTimesForm>
  >(
    async (values, formikHelpers) => {
      formikHelpers.setSubmitting(true);
      try {
        if (values.override) {
          const {override, completedDatetime} = values;
          const {actualTripStart, actualTripEnd} = override;

          const actualTripStartDateTime = actualTripStart
            ? DateTime.fromJSDate(actualTripStart).toJSDate()
            : undefined;

          const actualTripEndDateTime = actualTripEnd
            ? DateTime.fromJSDate(actualTripEnd).toJSDate()
            : undefined;

          await onUpdateSubmit({
            debriefData: {completedDatetime},
            overrideData: {
              stops: [
                {
                  tripStopExecution: {
                    mobileDepartureTime: actualTripStartDateTime,
                  },
                },
                {
                  tripStopExecution: {
                    mobileArrivalTime: actualTripEndDateTime,
                  },
                },
              ],
            },
          });
        } else {
          notify('info', 'No data was changed');
        }
      } finally {
        formikHelpers.setSubmitting(false);
      }
    },
    [notify, onUpdateSubmit]
  );

  React.useEffect(() => {
    const values = getInitialValues(masterTripDebriefData);

    if (values) {
      setInitialValues(values);
    }

    setDisplayOnlyValues(getDisplayOnlyValues(masterTripDebriefData));
    return () => {
      setInitialValues({override: {}, snapshot: {}});
    };
  }, [masterTripDebriefData]);

  /**
   * TripDebriefDisabledTextField -> Display only fields, thus none editable
   * TripDebriefDateTimePicker -> Overridable and editable datetime fields. Also handles the reset to initialValue functionality
   */
  return (
    <TemplateCard title="Trip Times Edit" loading={loading} elevation={0}>
      <TemplateForm<ITripDebriefTimesForm>
        onSubmit={handleSubmit}
        initialValues={initialValues}
        submitting={submitting}
        validationSchema={validationSchema}
        permission={{name: 'Trip Debrief', type: 'Edit'}}
        disabled={approvedForBilling}
        enableReinitialize
      >
        <TripDebriefDisabledTextField
          name="plannedTripStart"
          label="Planned Trip Start"
          type="datetime"
          value={displayOnlyValues?.plannedTripStart}
        />
        <TripDebriefDateTimePicker
          name="actualTripStart"
          label="Actual Trip Start (Mobile)"
          disableDefaultValue
        />
        <TripDebriefDisabledTextField
          name="actualTripStart"
          label="Actual Trip Start (Geofence Trigger)"
          type="datetime"
          value={displayOnlyValues?.actualTripStart}
        />
        <TripDebriefDisabledTextField
          name="plannedTripEnd"
          label="Planned Trip End"
          type="datetime"
          value={displayOnlyValues?.plannedTripEnd}
        />
        <TripDebriefDateTimePicker
          name="actualTripEnd"
          label="Actual Trip End (Mobile)"
          disableDefaultValue
        />
        <FormikDateTimePicker
          name="completedDatetime"
          label="Last Drop Date"
          InputLabelProps={{shrink: true}}
          disabled={approvedForBilling}
          disableDefaultValue
        />
        <TripDebriefDisabledTextField
          name="actualTripEnd"
          label="Actual Trip End (Geofence Trigger)"
          type="datetime"
          value={displayOnlyValues?.actualTripEnd}
        />
      </TemplateForm>
    </TemplateCard>
  );
};
