import React from 'react';
import {
  TripStopActivity,
  WebMasterTripStop,
} from '@onroadvantage/onroadvantage-api';
import {TemplateTable, TemplateTableConfig} from '../../../factory/template';
import {useTripStopContext} from './tripStopContext';
import RoleService from '../../../service/RoleService';

export const TripStopCommentList: React.FC = () => {
  const {
    loadingTripStop,
    tripStop,
    submittingComment,
    onAddComment,
    onRefresh,
  } = useTripStopContext();
  /** Destructure tripStop, need to add || ({} as WebMasterTripStop) for typescript, since tripStop is nullable. */
  const {activities} = tripStop || ({} as WebMasterTripStop);

  const [config] = React.useState<TemplateTableConfig<TripStopActivity>>({
    columns: [
      {
        name: 'value',
        label: 'Comment',
        type: 'string',
        enableEditing: true,
      },
      {
        name: 'createdBy',
        label: 'Created By',
        type: 'string',
        getValue: ({createdBy}) => createdBy?.email,
        enableEditing: false,
      },
      {
        name: 'createdAt',
        label: 'Created At',
        type: 'datetime',
        enableEditing: false,
      },
    ],
    disablePagination: true,
    enableGrouping: false,
    identifier: 'TRIP_STOP_COMMENT_LIST',
  });

  const listing = React.useMemo(() => activities ?? [], [activities]);

  return (
    <TemplateTable
      config={config}
      currentPage={1}
      elevation={0}
      nestedLevel={1}
      list={listing}
      loading={loadingTripStop || submittingComment}
      onRefresh={onRefresh}
      onInlineAdd={
        RoleService.hasPermission('Add TripStopComment', 'Add')
          ? onAddComment
          : undefined
      }
    />
  );
};
