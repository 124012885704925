import React from 'react';
import {
  AutocompleteProps,
  TAutocompleteOnInputChange,
} from '../../autocomplete';
import {FormikAutocompleteHooks} from './FormikAutocompleteHooks';
import {Listing, ApiListingGetRequest} from '@onroadvantage/onroadvantage-api';
import {listingApi} from '../../../api';
import {debounce} from '@mui/material';
import {useAppNotifications} from '../../../contexts';

interface FormikAutocompleteWithListingProps
  extends AutocompleteProps,
    ApiListingGetRequest {
  selectAll?: string;
}

export const FormikAutocompleteWithListingComponent: React.FC<
  FormikAutocompleteWithListingProps
> = ({
  model,
  loading,
  onInputChange,
  selectAll,
  contractId,
  contractCode,
  ...props
}) => {
  const notify = useAppNotifications();
  const [listing, setListing] = React.useState<Listing[]>([]);
  const [localLoading, setLocalLoading] = React.useState<boolean>(false);

  const getListing = React.useCallback(
    async (filter?: string) => {
      setLocalLoading(true);
      try {
        const response = await listingApi.apiListingGet({
          model,
          query: filter,
          contractId,
          contractCode,
        });
        setListing(response.items ?? []);
      } catch (e) {
        notify('error', e.message ?? `Failed to load ${model} listing`);
      } finally {
        setLocalLoading(false);
      }
    },
    [contractCode, contractId, model, notify]
  );

  const getListingDebounce = React.useRef(debounce(getListing, 500)).current;

  const handleInputChange = React.useCallback<TAutocompleteOnInputChange>(
    async (event, value, reason) => {
      setLocalLoading(true);
      await getListingDebounce(value);
      if (onInputChange) onInputChange(event, value, reason);
    },
    [getListingDebounce, onInputChange]
  );

  React.useEffect(() => {
    getListing();
    return () => setListing([]);
  }, [getListing]);

  const memoizedOptions = React.useMemo(
    () => (selectAll ? [{value: -1, label: selectAll}, ...listing] : listing),
    [listing, selectAll]
  );

  return (
    <FormikAutocompleteHooks
      {...props}
      loading={localLoading}
      options={memoizedOptions}
      onInputChange={handleInputChange}
    />
  );
};

export const FormikAutocompleteWithListing = React.memo(
  FormikAutocompleteWithListingComponent
);
