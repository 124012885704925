import React from 'react';
import {
  TemplateTable,
  TemplateTableConfig,
  TOnInlineEdit,
} from '../../../../factory/template';
import {vehicleCompartmentCapacityDimensionLayerApi} from '../../../../api';
import {
  ICapacityCompartment,
  IVehicleTypeCapacityForm,
} from '../VehicleTypeCapacityForm';
import {VCCDL} from '@onroadvantage/onroadvantage-api';
import {IVantageDialogRef, VantageDialog} from '../../../dialog';
import {Divider, IconButton, Typography} from '@mui/material';
import {Reorder} from '@mui/icons-material';
import {useAppNotifications} from '../../../../contexts';
import {useVehicleTypeCapacityCompartmentTruckLayersViewStyles} from './VehicleTypeCapacityCompartmentTruckLayersView.style';
import {VehicleCompartmentWithCapacity} from './VehicleTypeCapacityCompartmentTruckCompartment';

interface VehicleTypeCapacityCompartmentTruckLayersViewProps {
  compartment: VehicleCompartmentWithCapacity;
  values: IVehicleTypeCapacityForm;
  vehicleCompartmentCapacityDimension?: ICapacityCompartment | undefined;
}
export const VehicleTypeCapacityCompartmentTruckLayersView: React.FC<
  VehicleTypeCapacityCompartmentTruckLayersViewProps
> = ({compartment, values, vehicleCompartmentCapacityDimension}) => {
  const classes = useVehicleTypeCapacityCompartmentTruckLayersViewStyles();
  const notify = useAppNotifications();
  const viewLayerRef = React.useRef<IVantageDialogRef>(null);
  /**VCCDL = VehicleCompartmentCapacityDimensionLayer*/
  const [list, setList] = React.useState<VCCDL[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const totalListCapacity = React.useMemo(
    () => list.reduce((acc, {capacity}) => acc + (capacity ?? 0), 0),
    [list]
  );
  const totalCompartmentCapacity = React.useMemo(
    () => vehicleCompartmentCapacityDimension?.capacity ?? 0,
    [vehicleCompartmentCapacityDimension?.capacity]
  );
  const config: TemplateTableConfig<VCCDL> = React.useMemo(
    () => ({
      columns: [
        {
          name: 'value',
          label: 'Value',
          type: 'number',
          enableEditing: false,
          getValue: ({value}) => `${value}cm`,
        },
        {
          name: 'capacity',
          label: `Capacity (${values.unitOfMeasure})`,
          type: 'number',
          enableEditing: true,
        },
      ],
      identifier: 'VEHICLE_TYPE_CAPACITY_LIST',
      disablePagination: true,
      disableToolbar: true,
      enableGrouping: false,
    }),
    [values.unitOfMeasure]
  );

  const loadList = React.useCallback(async () => {
    setLoading(true);
    setList([]);
    try {
      if (vehicleCompartmentCapacityDimension?.id) {
        const response =
          await vehicleCompartmentCapacityDimensionLayerApi.apiVehicleCompartmentCapacityDimensionVehicleCompartmentCapacityDimensionIdLayerGet(
            {
              vehicleCompartmentCapacityDimensionId:
                vehicleCompartmentCapacityDimension.id,
            }
          );
        setList(
          response.items?.sort((a, b) =>
            a.value && b.value
              ? a.value < b.value
                ? 1
                : a.value > b.value
                ? -1
                : 0
              : 0
          ) ?? []
        );
      }
    } finally {
      setLoading(false);
    }
  }, [vehicleCompartmentCapacityDimension?.id]);

  const handleOpen = React.useCallback(() => {
    viewLayerRef.current?.openDialog();
    loadList();
  }, [loadList]);

  const handleInlineEdit = React.useCallback<TOnInlineEdit>(
    async (changes) => {
      setLoading(true);
      setList([]);
      try {
        if (vehicleCompartmentCapacityDimension?.id) {
          await vehicleCompartmentCapacityDimensionLayerApi.apiVehicleCompartmentCapacityDimensionVehicleCompartmentCapacityDimensionIdLayerPatch(
            {
              vehicleCompartmentCapacityDimensionId:
                vehicleCompartmentCapacityDimension.id,
              body: {
                layers: changes.map((change) => ({
                  id: parseInt(change.id),
                  capacity: parseInt(change.newValues.capacity) || 0,
                })),
              },
            }
          );
          await loadList();
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to updated layer');
      } finally {
        setLoading(false);
      }
    },
    [loadList, notify, vehicleCompartmentCapacityDimension?.id]
  );

  return (
    <>
      <IconButton onClick={handleOpen} size="large">
        <Reorder />
      </IconButton>
      <VantageDialog
        title={
          <div className={classes.titleContainer}>
            <Typography>{compartment.name} layers</Typography>
            <Divider
              orientation="vertical"
              className={classes.divider}
              flexItem
            />
            <Typography
              color={
                totalListCapacity > totalCompartmentCapacity
                  ? 'error'
                  : 'inherit'
              }
            >
              Allocated {totalListCapacity}
              {values.unitOfMeasure}
            </Typography>
            <Divider
              orientation="vertical"
              className={classes.divider}
              flexItem
            />
            <Typography>
              Available{' '}
              {totalCompartmentCapacity - totalListCapacity < 0
                ? 0
                : totalCompartmentCapacity - totalListCapacity}
              {values.unitOfMeasure}
            </Typography>
          </div>
        }
        ref={viewLayerRef}
        maxWidth="md"
        declineTitle="Cancel"
        declineProps={{sx: {flex: 1}}}
        fullWidth
        disableAccept
      >
        <TemplateTable
          config={config}
          currentPage={1}
          list={list}
          loading={loading}
          onInlineEditAll={handleInlineEdit}
        />
      </VantageDialog>
    </>
  );
};
