import React from 'react';
import {Stack} from '@mui/material';
import {
  usePlanningBoardContext,
  usePlanningBoardGanttContext,
} from '../planningBoardContext';
import {
  PlanningBoardGantt,
  PlanningBoardGanttSkeleton,
} from '../planningBoardPlan/planningBoardGantt';
import {PlanningBoardVehicleViewGantt} from '../planningBoardDailyView/PlanningBoardVehicleViewGantt';

interface PlanningBoardGanttPanelProps {
  focused?: boolean;
}

export const PlanningBoardGanttPanel: React.FC<
  PlanningBoardGanttPanelProps
> = ({focused}) => {
  const {
    activeToolbarTab,
    loadingCommitBookingTrips,
    loadingInitial,
    loadingMasterTrips,
    loadingOptimalTrips,
    loadingVehicles,
  } = usePlanningBoardContext();
  const {
    loadingCreateMasterTrip,
    loadingDeleteMasterTrip,
    loadingUpdateMasterTrip,
  } = usePlanningBoardGanttContext();

  const loadingGantt =
    loadingCommitBookingTrips ||
    loadingCreateMasterTrip ||
    loadingDeleteMasterTrip ||
    loadingInitial ||
    loadingMasterTrips ||
    loadingOptimalTrips ||
    loadingUpdateMasterTrip ||
    loadingVehicles;

  return (
    <Stack>
      {loadingGantt ? (
        <PlanningBoardGanttSkeleton />
      ) : activeToolbarTab === 1 ? (
        <PlanningBoardVehicleViewGantt />
      ) : (
        <PlanningBoardGantt focused={focused} />
      )}
    </Stack>
  );
};
