import React from 'react';
import {Step} from '../TemplateStepperContext';
import {FormikConfig, FormikValues} from 'formik';
import _ from 'lodash';

export interface FormikStep
  extends Step,
    Pick<FormikConfig<FormikValues>, 'validationSchema'> {}

export interface TemplateStepperFormikContextProps {
  activeIndex: number;
  activeStep: FormikStep | null;
  maxSteps: number;
  onNextStep: () => void;
  onPrevStep: () => void;
  previousDisabled?: boolean;
  steps: FormikStep[];
}

export interface TemplateStepperFormikContextProviderProps {
  steps: FormikStep[];
}

export const TemplateStepperFormikContext =
  React.createContext<TemplateStepperFormikContextProps>({
    activeIndex: 0,
    activeStep: null,
    maxSteps: 0,
    onNextStep: () => null,
    onPrevStep: () => null,
    steps: [],
  });

export const TemplateStepperFormikContextProvider: React.FC<
  TemplateStepperFormikContextProviderProps
> = ({children, steps}) => {
  const [activeIndex, setActiveIndex] = React.useState<number>(0);
  const [activeStep, setActiveStep] = React.useState<FormikStep | null>(null);
  const [maxSteps, setMaxSteps] = React.useState<number>(0);
  // const [nextTitle, setNextTitle] = React.useState<string>('Next');
  // const [prevTitle, setPrevTitle] = React.useState<string>('Back');
  const [previousDisabled, setPreviousDisabled] =
    React.useState<boolean>(false);

  const handleNextStep = () => {
    if (maxSteps === activeIndex + 1) {
      // onLastStepNext && onLastStepNext();
    } else {
      setActiveIndex(activeIndex + 1);
    }
  };

  const handlePreviousStep = () => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
    }
  };

  const value = {
    activeIndex,
    activeStep,
    maxSteps,
    setActiveStep,
    onNextStep: handleNextStep,
    onPrevStep: handlePreviousStep,
    previousDisabled,
    steps,
  };

  React.useEffect(() => {
    setMaxSteps(steps?.length);
  }, [setMaxSteps, steps]);
  React.useEffect(() => {
    setPreviousDisabled(activeIndex <= 0);
  }, [activeIndex, steps]);
  React.useEffect(() => {
    setActiveStep(_.nth(steps, activeIndex) ?? null);
  }, [activeIndex, setActiveStep, steps]);

  return (
    <TemplateStepperFormikContext.Provider value={value}>
      {children}
    </TemplateStepperFormikContext.Provider>
  );
};
