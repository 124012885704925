import React from 'react';
import {GeoJSON} from 'react-leaflet';
import ConfigService from './ConfigService/ConfigService';
import IsochroneMarker from '../components/map/marker/IsochroneMarker';
import {mapDisplayStore} from '../stores/mobxStores';

const {valhallaEngineUrl} = ConfigService;

export interface IValhallaService {
  getIsochroneFromPoint: any;
  getIsochroneMapLayers: any;
  renderIsochrone: any;
  updateOpenIsochrone: any;
  clearIsochrone: any;
}

const ValhallaService: IValhallaService = {
  clearIsochrone: async () => {
    mapDisplayStore.setOpenIsochrone(null);
  },
  updateOpenIsochrone: async (distanceInMinutes: any, timeOfDay: any) => {
    if (mapDisplayStore && mapDisplayStore.openIsochrone) {
      const openIsochrone = mapDisplayStore.openIsochrone;
      const isochrone = await ValhallaService.getIsochroneFromPoint(
        openIsochrone.props.children.props.position[0],
        openIsochrone.props.children.props.position[1],
        distanceInMinutes,
        timeOfDay
      );
      mapDisplayStore.setOpenIsochrone(isochrone);
    }
  },
  renderIsochrone: async (e: any) => {
    if (mapDisplayStore) {
      const isochrone = await ValhallaService.getIsochroneFromPoint(
        e.latlng.lat,
        e.latlng.lng,
        30,
        0
      );
      mapDisplayStore.setOpenIsochrone(isochrone);
    }
  },
  getIsochroneMapLayers: async (
    latitude: number,
    longitude: number,
    geoJSON: any,
    identifier: string,
    distanceInMinutes: number,
    timeOfDay: number
  ) => {
    return (
      <GeoJSON
        data={geoJSON}
        key={`${identifier}externalLayer${'GeoJSONIsochrone'}`}
      >
        <IsochroneMarker
          position={[latitude, longitude]}
          distanceInMinutes={distanceInMinutes}
          timeOfDay={timeOfDay}
          updateOpenIsochrone={ValhallaService.updateOpenIsochrone}
        />
      </GeoJSON>
    );
  },
  getIsochroneFromPoint: async (
    latitude: number,
    longitude: number,
    distanceInMinutes: number,
    timeOfDay: number
  ) => {
    const body = `{"locations":[{"lat":${latitude},"lon":${longitude}}],"costing":"truck", "polygons":true, "contours":[{"time":${distanceInMinutes},"color":"ff0000"}]}`;

    const response = await fetch(`${valhallaEngineUrl}isochrone`, {
      method: 'POST',
      body: body,
      mode: 'cors',
    });
    const responseJson = await response.json();
    return await ValhallaService.getIsochroneMapLayers(
      latitude,
      longitude,
      responseJson,
      'IsochroneDepotMarker',
      distanceInMinutes,
      timeOfDay
    );
  },
};

export default ValhallaService;
