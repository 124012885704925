import React from 'react';
import {CustomerContext} from './CustomerContext';
import {
  TemplateTableConfig,
  TemplateTable,
  TOnInlineAdd,
} from '../../factory/template';
import {CustomerNode} from '@onroadvantage/onroadvantage-api';
import {customerNodeApi} from '../../api';
import {useAppNotifications} from '../../contexts';
import {RoleService} from '../../service';

export const CustomerSites: React.FC = () => {
  const notify = useAppNotifications();
  const {customer, customerId, loadCustomer, loadingSingleItem} =
    React.useContext(CustomerContext);
  const [localLoading, setLocalLoading] = React.useState<boolean>(false);
  const list = React.useMemo(() => customer?.nodes ?? [], [customer?.nodes]);
  const customerName = React.useMemo(() => customer?.name, [customer?.name]);

  const [config] = React.useState<TemplateTableConfig<CustomerNode>>({
    columns: [
      {
        name: 'node',
        label: 'Site Name',
        type: 'string',
        getValue: ({node}) => node?.name,
        enableEditing: true,
        enableAutocomplete: true,
        model: 'Node',
      },
      {name: 'nodeAlias', label: 'Alias', type: 'string', enableEditing: true},
    ],
    disablePagination: true,
    disableToolbar: true,
    enableGrouping: false,
    deleteMessage: (row) => ({
      title: 'Customer Sites',
      items: {
        Customer: customerName,
        SiteName: row.node?.name,
        Alias: row.nodeAlias,
      },
    }),
    identifier: 'CUSTOMER_SITES_LIST',
  });

  const handleInlineAdd = React.useCallback<TOnInlineAdd>(
    async (changes) => {
      setLocalLoading(true);
      try {
        if (customerId) {
          for (const change of changes) {
            await customerNodeApi.apiCustomerNodePost({
              body: {
                customerId,
                nodeId: change.node.value,
                nodeAlias: change.nodeAlias,
              },
            });
          }
          await loadCustomer();
          notify('success', 'Added Site');
        }
      } catch (e) {
        notify('error', 'Failed to add Site');
      } finally {
        setLocalLoading(false);
      }
    },
    [customerId, loadCustomer, notify]
  );

  const handleDelete = React.useCallback(
    async (row: CustomerNode) => {
      setLocalLoading(true);
      try {
        if (row.id) {
          await customerNodeApi.apiCustomerNodeCustomerNodeIdDelete({
            customerNodeId: row.id,
          });
          await loadCustomer();
          notify('success', 'Deleted Site');
        }
      } catch (e) {
        notify('error', 'Failed to delete site');
      } finally {
        setLocalLoading(false);
      }
    },
    [loadCustomer, notify]
  );

  return (
    <TemplateTable
      config={config}
      list={list}
      currentPage={1}
      loading={loadingSingleItem || localLoading}
      onInlineAdd={
        RoleService.hasPermission('Edit CustomerSite', 'Edit')
          ? handleInlineAdd
          : undefined
      }
      onDelete={
        RoleService.hasPermission('Delete CustomerSite', 'Delete')
          ? handleDelete
          : undefined
      }
    />
  );
};
