import React from 'react';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import {Theme} from '@mui/material';
import {WithStyles} from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import {connect} from 'formik';
import * as Yup from 'yup';
import {
  IFormikProps,
  IFormTrip,
  PlanningSolutionFormValues,
} from './PlanningSolutionForm';
import {usePlanningSolutionViewStore} from '../../../stores/context';
import {useAppNotificationStore} from '../../../stores/context/notifications';
import {usePlanningSolutionData} from '../../../stores/context';
import {planningSolutionApi} from '../../../api';
import {DEFAULT_OPTIMISE_TICK} from './PlanningSolutionController';
import {VantageDialogButton} from '../../dialog';
import {VantageReportButton} from '../../reportButton';
import {NUMBER_ERROR_MESSAGE} from '../../../factory/template';

const styles = (theme: Theme) =>
  createStyles({
    footer: {
      borderTopColor: theme.palette.divider,
      borderTopStyle: 'solid',
      borderTopWidth: 1,
    },
  });

type IProps = WithStyles<typeof styles>;

// eslint-disable-next-line import/prefer-default-export
export const PlanningSolutionFormActions = withStyles(styles)(
  connect<IProps, PlanningSolutionFormValues>(
    ({
      classes,
      formik: {isSubmitting, isValid, dirty, values, resetForm},
    }: IProps & IFormikProps) => {
      /** sets whether the external data is being loaded */
      const {
        setOptimiseTick,
        setSolutionIsOptimising,
        setSolutionIsCommitting,
      } = usePlanningSolutionViewStore((store) => ({
        setOptimiseTick: store.setOptimizeTick,
        setSolutionIsOptimising: store.setSolutionIsOptimising,
        setSolutionIsCommitting: store.setSolutionIsCommitting,
      }));

      const {planningSolution} = usePlanningSolutionData((store) => ({
        planningSolution: store.planningSolution,
      }));

      /** notification service */
      const appNotificationStore = useAppNotificationStore();
      const displayNotification = React.useCallback(
        (message: {type: string; value: string}) => {
          appNotificationStore.enqueueNotification(message.type, message.value);
        },
        [appNotificationStore]
      );

      const handleCommitError = async (e: any) => {
        try {
          const response = await e.json();
          displayNotification({type: 'error', value: response.message});
        } catch (e) {
          displayNotification({type: 'error', value: e});
        }
      };

      const handleCommitSolution = async () => {
        // ensure the dialog closes
        const tripOrderSchema: Yup.SchemaOf<Pick<IFormTrip, 'orders'>> =
          Yup.object().shape({
            orders: Yup.array()
              .of(Yup.number().typeError(NUMBER_ERROR_MESSAGE))
              .min(1, 'Assign at least one order to each trip')
              .default([]),
          });

        const valuesSchema = Yup.object().shape({
          trips: Yup.array(tripOrderSchema).min(
            1,
            'Assign at least one trip to the solution before committing.'
          ),
        });

        try {
          await valuesSchema.validate(values);
          // TODO commit
        } catch (e) {
          if (e instanceof Yup.ValidationError) {
            displayNotification({type: 'error', value: e.message});
          } else {
            displayNotification({type: 'error', value: 'An error occurred.'});
          }
        }

        // commit to server
        try {
          setSolutionIsCommitting(true);
          if (values.solutionId) {
            await planningSolutionApi.apiPlanningSolutionCommitMasterTripsPlanningSolutionIdGet(
              {planningSolutionId: values.solutionId}
            );
            displayNotification({type: 'success', value: 'Solution committed'});
          }
        } catch (e) {
          handleCommitError(e);
        } finally {
          // ensure the loader stops
          setSolutionIsCommitting(false);
        }
      };

      const handleOptimise = async () => {
        const valuesSchema = Yup.object().shape({
          allocatedOrders: Yup.array(
            Yup.number().typeError(NUMBER_ERROR_MESSAGE)
          ).min(
            1,
            'Assign at least one order to the solution before optimising.'
          ),
        });
        try {
          await valuesSchema.validate({
            allocatedOrders: [
              ...(values.allocatedOrders || []),
              ...(values.unallocatedOrders || []),
            ],
          });

          if (planningSolution && planningSolution.id) {
            setSolutionIsOptimising(true);

            // submit request to optimize
            await planningSolutionApi.apiPlanningSolutionOptimisePlanningSolutionIdGet(
              {planningSolutionId: planningSolution.id}
            );

            // set the optimisation poll tick
            setOptimiseTick(DEFAULT_OPTIMISE_TICK);
          }
        } catch (e) {
          if (e instanceof Yup.ValidationError) {
            displayNotification({type: 'error', value: e.message});
          } else {
            displayNotification({type: 'error', value: 'An error occurred.'});
          }
        } finally {
          setSolutionIsOptimising(false);
        }
      };

      const handleReset = async () => {
        resetForm();
      };

      return (
        <div>
          <CardActions className={classes.footer}>
            <Button disabled={isSubmitting} type="submit" color="primary">
              Save
            </Button>
            <VantageDialogButton
              title="Optimise"
              color="inherit"
              dialogTitle="Optimise Planning Solution"
              onAccept={handleOptimise}
              disabled={!isValid || dirty || isSubmitting}
            >
              Are you sure you want to optimise planning solution?
            </VantageDialogButton>
            <VantageDialogButton
              title="Commit"
              color="inherit"
              dialogTitle="Commit Planning Solution"
              onAccept={handleCommitSolution}
              disabled={!isValid || dirty || isSubmitting}
            >
              Are you sure you want to commit the planning solution?
            </VantageDialogButton>
            <Button
              color="secondary"
              disabled={!dirty || isSubmitting}
              onClick={handleReset}
            >
              Reset
            </Button>
            <VantageReportButton name="Planning Solution Report" />
          </CardActions>
        </div>
      );
    }
  )
);
