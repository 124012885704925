import React from 'react';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from '@mui/material';
import {Person} from '@mui/icons-material';
import debounce from 'lodash/debounce';
import {userApi} from '../../../api';
import RoleService from '../../../service/RoleService';
import {ContactUserPopup} from './ContactUserPopup';
import {useContactUserStyles} from './ContactUser.style';
import {ContactContext} from '../ContactContext';

export const ContactUser: React.FC = () => {
  const classes = useContactUserStyles();
  const {contact, handleSave} = React.useContext(ContactContext);
  const [user] = React.useState(contact?.user);

  const [open, setOpen] = React.useState<boolean>(false);
  const [options, setOptions] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);

  const ld = React.useCallback(async (value) => {
    if (value) {
      const query = await userApi.apiUserGet({active: true, email: value});

      if (query != null) {
        const retrieved = query?.items?.map((item) => {
          return {
            value: item.id,
            label: `${item.roles?.map((e) => e.name).join(', ')} - ${
              item.email
            }`,
            email: item.email,
          };
        });

        setOptions(retrieved ?? []);
      }
    }
    setLoading(false);
  }, []);

  const loadData = debounce(ld, 1000);

  const onSearchChange = React.useCallback(
    async (value: string) => {
      if (value != null) {
        setOptions([]);
        setLoading(true);
        await loadData(value);
      }
    },
    [loadData]
  );

  // shady
  const [once, setOnce] = React.useState<boolean>(true);
  React.useEffect(() => {
    if (once) {
      loadData('');
      setOnce(false);
    }
  }, [loadData, once]);

  return (
    <Card>
      <CardHeader
        title="Driver User Details"
        avatar={
          <Avatar>
            <Person />
          </Avatar>
        }
      />

      {user ? (
        <>
          <CardContent>
            <Typography color="textSecondary" className={classes.label}>
              ID
            </Typography>
            <Typography gutterBottom className={classes.detail}>
              {user.id}
            </Typography>
            <Typography color="textSecondary" className={classes.label}>
              Email
            </Typography>
            <Typography gutterBottom className={classes.detail}>
              {user.email}
            </Typography>
          </CardContent>
        </>
      ) : (
        <>
          <CardContent>
            <div>No User allocated</div>
          </CardContent>
          <CardActions>
            <Button
              onClick={() => setOpen((e) => !e)}
              disabled={!RoleService.hasPermission('Driver Link', 'Edit')}
            >
              Link
            </Button>
          </CardActions>
          <ContactUserPopup
            open={open}
            setOpen={() => setOpen((e) => !e)}
            onSearchChange={onSearchChange}
            options={options}
            loading={loading}
            Allocate={handleSave}
          />
        </>
      )}
    </Card>
  );
};
