import React from 'react';
import {Stack} from '@mui/material';
import {FormikDatePicker, FormikTimePicker} from '../../formik';

export const ReportScheduleFormScheduleOnce: React.FC = () => {
  return (
    <Stack direction="row" flexWrap="wrap" flexGrow={1}>
      <Stack>
        <FormikDatePicker
          toolbarTitle={null}
          label="Report Schedule Date"
          name="date"
          openTo="day"
          orientation="portrait"
          displayStaticWrapperAs="desktop"
          showToolbar
          staticMode
        />
      </Stack>
      <Stack>
        <FormikTimePicker
          toolbarTitle={null}
          label="Report Schedule Time"
          name="time"
          placeholder="Enter the Report Schedule Time"
          orientation="portrait"
          displayStaticWrapperAs="desktop"
          showToolbar
          staticMode
        />
      </Stack>
    </Stack>
  );
};
