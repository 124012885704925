import React from 'react';
import {IRecipientCardDetail} from '../MasterTripRecipientContext';
import {RecipientTripDriver} from '@onroadvantage/onroadvantage-api';
import {VerifiedUser} from '@mui/icons-material';
import {RecipientCard} from '../recipientCard';

export const RecipientDriverDetails: React.FC<RecipientTripDriver> = (
  driver
) => {
  const driverDetails: IRecipientCardDetail[] = React.useMemo(
    () => [{label: 'Name', value: driver.firstname}],
    [driver]
  );

  return (
    <RecipientCard
      title="Driver Details"
      avatar={<VerifiedUser />}
      details={driverDetails}
      data-cy="RecipientDriverDetails"
    />
  );
};
