import React from 'react';
import {useContractContext} from '../contractContext';
import {TemplateCard, TemplateForm} from '../../../factory/template';
import {contractTripTimesSchema} from '../contractContext';
import {ContractTripTimesFormFields} from './contractFormFields';

export const ContractTripTimesForm: React.FC = () => {
  const {
    loadingContract,
    tripTimesInitialValues,
    onContractTripTimesSubmit,
    submitting,
  } = useContractContext();

  return (
    <TemplateCard title="Contract Trip Times Edit" loading={loadingContract}>
      <TemplateForm
        initialValues={tripTimesInitialValues}
        onSubmit={onContractTripTimesSubmit}
        validationSchema={contractTripTimesSchema}
        loading={submitting}
        permission={{name: 'Edit Contract', type: 'Edit'}}
      >
        <ContractTripTimesFormFields />
      </TemplateForm>
    </TemplateCard>
  );
};
