import ConfigService from './ConfigService/ConfigService';
import {DriverService, DriverGroupService} from './DriverService';
import NodeService from './NodeService';
import OrderService from './OrderService';
import ContractService from './ContractService';
import TripService from './TripService';
import TripLogService from './TripLogService';
import VoiceService from './VoiceService';
import ReportService from './ReportService';
import TelematicsEventService from './TelematicsEventService';
import RouteService from './RouteService';
import VehicleService from './VehicleService';
import UserService from './UserService';
import RoleService from './RoleService';
import MomentService from './MomentService';
import StorageService from './StorageService';
import UploadService from './UploadService';
import ListingService from './ListingService';
import TransporterService from './TransporterService';
import ProductService from './ProductService';
import CustomerService from './CustomerService';
import CustomerNodeService from './CustomerNodeService';
import OrderLineService from './OrderLineService';
import PermissionService from './PermissionService';
import DashboardService from './DashboardService';
import ContractGroupService from './ContractGroupService';
import SettingService from './SettingService';
import PlanningService from './PlanningService';
import XLSXService from './XLSXService';
import PolylineService from './PolylineService';
import ValhallaService from './ValhallaService';

const serverUrl = ConfigService.serverUri;
const authUrl = `${serverUrl}/api/auth/login`;

const authenticate = async (payload) => {
  const headers = new Headers();
  headers.set('Content-Type', 'application/json');

  const response = await fetch(authUrl, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(payload),
    headers,
  });

  return response.json();
};

export * from './MomentService';
export * from './LuxonService';
export {
  authenticate,
  serverUrl,
  DriverService,
  DriverGroupService,
  NodeService,
  OrderService,
  ContractService,
  TripService,
  TripLogService,
  VoiceService,
  ReportService,
  TelematicsEventService,
  RouteService,
  VehicleService,
  UserService,
  RoleService,
  MomentService,
  StorageService,
  UploadService,
  ListingService,
  TransporterService,
  CustomerService,
  CustomerNodeService,
  ProductService,
  OrderLineService,
  PermissionService,
  DashboardService,
  ContractGroupService,
  SettingService,
  XLSXService,
  PlanningService,
  PolylineService,
  ValhallaService,
};
