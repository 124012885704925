import React from 'react';
import {MasterRouteContext} from './MasterRouteContext';
import {useParams} from 'react-router-dom';

interface IParams {
  masterRouteId?: string;
}

export const MasterRouteIdHandler: React.FC = () => {
  const {setMasterRouteId} = React.useContext(MasterRouteContext);
  const {masterRouteId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = masterRouteId ? parseInt(masterRouteId) : undefined;
    setMasterRouteId(parsedId);
    return () => setMasterRouteId(undefined);
  }, [masterRouteId, setMasterRouteId]);

  return null;
};
