import React from 'react';
import {BroadcastContext} from './BroadcastContext';
import {useParams} from 'react-router-dom';

interface IParams {
  broadcastId?: string;
}

export const BroadcastIdHandler: React.FC = () => {
  const {setBroadcastId} = React.useContext(BroadcastContext);

  const {broadcastId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = broadcastId ? parseInt(broadcastId) : undefined;
    setBroadcastId(parsedId);
    return () => setBroadcastId(undefined);
  }, [broadcastId, setBroadcastId]);

  return null;
};
