import React from 'react';
import {MobXProviderContext, useObserver} from 'mobx-react';

// eslint-disable-next-line import/prefer-default-export
export const useStoreData = <Selection, ContextData, Store>(
  context: React.Context<ContextData>,
  storeSelector: (contextData: ContextData) => Store,
  dataSelector: (store: Store) => Selection
) => {
  const value = React.useContext(context);
  if (!value) {
    throw new Error();
  }
  const store = storeSelector(value);
  return useObserver(() => {
    return dataSelector(store);
  });
};

export const useStores = () => {
  return React.useContext(MobXProviderContext);
};
