import React from 'react';
import {DocumentTemplateContext} from './DocumentTemplateContext';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../factory/template';
import {DocumentTemplate} from '@onroadvantage/onroadvantage-api';

export const DocumentTemplateList: React.FC = () => {
  const {loadList, cleanupList} = React.useContext(DocumentTemplateContext);

  const [config] = React.useState<TemplateTableConfig<DocumentTemplate>>({
    columns: [
      {name: 'name', label: 'Name', type: 'string'},
      {
        name: 'documentType',
        label: 'Type',
        type: 'string',
        enableFilter: false,
      },
    ],
    enableFilter: true,
    enableSort: true,
    deleteMessage: (row) => ({
      title: 'Document Template',
      items: {Name: row.name, Type: row.documentType},
    }),
    identifier: 'DOCUMENT_TEMPLATE_LIST',
  });

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [loadList, cleanupList]);

  return (
    <TemplateTableWrapper config={config} context={DocumentTemplateContext} />
  );
};
