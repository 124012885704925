import React from 'react';
import {IVantageDialogRef, VantageDialog} from '../../../dialog';
import {PlaylistAdd} from '@mui/icons-material';
import {
  listingSchema,
  NUMBER_ERROR_MESSAGE,
  TemplateForm,
} from '../../../../factory/template';
import {FormikAutocompleteHooks, FormikTextField} from '../../../formik';
import * as Yup from 'yup';
import {IconButton, InputAdornment, Typography} from '@mui/material';
import {ICapacityCompartment} from '../VehicleTypeCapacityForm';
import {FormikHelpers} from 'formik/dist/types';
import {vehicleCompartmentCapacityDimensionLayerApi} from '../../../../api';
import {useAppNotifications} from '../../../../contexts';
import {Listing} from '@onroadvantage/onroadvantage-api';
import {Loader} from '../../../loader';

export interface IVehicleTypeCapacityCompartmentLayerForm {
  maxHeight: number;
  increment: Listing;
  vehicleCompartmentCapacityDimensionId?: number | null | undefined;
}
const capacityCompartmentLayerSchema: Yup.SchemaOf<IVehicleTypeCapacityCompartmentLayerForm> =
  Yup.object({
    vehicleCompartmentCapacityDimensionId:
      Yup.number().typeError(NUMBER_ERROR_MESSAGE),
    maxHeight: Yup.number()
      .typeError(NUMBER_ERROR_MESSAGE)
      .required('Required'),
    increment: listingSchema.required('Required'),
  });

interface VehicleTypeCapacityCompartmentAddLayerProps {
  vehicleCompartmentCapacityDimension?: ICapacityCompartment;
}
export const VehicleTypeCapacityCompartmentTruckLayersAdd: React.FC<
  VehicleTypeCapacityCompartmentAddLayerProps
> = ({vehicleCompartmentCapacityDimension}) => {
  const notify = useAppNotifications();
  const addLayerDialogRef = React.useRef<IVantageDialogRef>(null);
  const deleteLayerDialogRef = React.useRef<IVantageDialogRef>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [incrementOptions] = React.useState<Listing[]>([
    {label: '1cm', value: 1},
    {label: '2cm', value: 2},
    {label: '5cm', value: 5},
    {label: '10cm', value: 10},
    {label: '100cm', value: 100},
  ]);

  const handleLayerDetailsFormSubmit = React.useCallback(
    async (
      values: IVehicleTypeCapacityCompartmentLayerForm,
      formikHelpers: FormikHelpers<IVehicleTypeCapacityCompartmentLayerForm>
    ) => {
      setLoading(true);
      formikHelpers.setSubmitting(true);
      try {
        if (
          values.vehicleCompartmentCapacityDimensionId &&
          values.increment.value
        ) {
          await vehicleCompartmentCapacityDimensionLayerApi.apiVehicleCompartmentCapacityDimensionVehicleCompartmentCapacityDimensionIdLayerPost(
            {
              vehicleCompartmentCapacityDimensionId:
                values.vehicleCompartmentCapacityDimensionId,
              body: {
                maxHeight: parseInt(String(values.maxHeight)),
                increment: parseInt(String(values.increment.value)),
              },
            }
          );
        }
        notify('success', `Added Vehicle Type Capacity`);
      } catch (e) {
        notify('error', e.message ?? `Failed Vehicle Type Capacity`);
      } finally {
        formikHelpers.setSubmitting(false);
        setLoading(false);
        addLayerDialogRef.current?.closeDialog();
      }
    },
    [notify]
  );

  const handleDeleteLayers = React.useCallback(async () => {
    setLoading(true);
    try {
      if (vehicleCompartmentCapacityDimension?.id) {
        await vehicleCompartmentCapacityDimensionLayerApi.apiVehicleCompartmentCapacityDimensionVehicleCompartmentCapacityDimensionIdLayerDelete(
          {
            vehicleCompartmentCapacityDimensionId:
              vehicleCompartmentCapacityDimension.id,
          }
        );
      }
    } catch (e) {
      notify('error', e.message ?? 'Failed to delete layers');
    } finally {
      setLoading(false);
      deleteLayerDialogRef.current?.closeDialog();
    }
  }, [notify, vehicleCompartmentCapacityDimension?.id]);

  const handleOpen = React.useCallback(async () => {
    setLoading(true);
    try {
      if (vehicleCompartmentCapacityDimension?.id) {
        const response =
          await vehicleCompartmentCapacityDimensionLayerApi.apiVehicleCompartmentCapacityDimensionVehicleCompartmentCapacityDimensionIdLayerGet(
            {
              vehicleCompartmentCapacityDimensionId:
                vehicleCompartmentCapacityDimension.id,
            }
          );
        if (response.items && response.items.length > 0) {
          deleteLayerDialogRef.current?.openDialog();
        } else {
          addLayerDialogRef.current?.openDialog();
        }
      }
    } finally {
      setLoading(false);
    }
  }, [vehicleCompartmentCapacityDimension?.id]);

  return (
    <>
      <IconButton onClick={handleOpen} disabled={loading} size="large">
        {loading ? <Loader size={22} /> : <PlaylistAdd />}
      </IconButton>
      <VantageDialog
        title="You first have to delete the existing layers to create new layer capacities."
        ref={deleteLayerDialogRef}
        onAccept={handleDeleteLayers}
        maxWidth="sm"
        fullWidth
      >
        <Typography variant="subtitle2">
          Are you sure you want to delete the existing layers?
        </Typography>
      </VantageDialog>
      <VantageDialog
        title="Add compartment layer dimension"
        ref={addLayerDialogRef}
        maxWidth="sm"
        fullWidth
        disableAccept
        disableDecline
      >
        <TemplateForm<IVehicleTypeCapacityCompartmentLayerForm>
          initialValues={{
            vehicleCompartmentCapacityDimensionId:
              vehicleCompartmentCapacityDimension?.id,
            maxHeight: 1,
            increment: {label: '10cm', value: 10},
          }}
          onSubmit={handleLayerDetailsFormSubmit}
          permission={{name: 'Edit VehicleType', type: 'Edit'}}
          submitting={loading}
          validationSchema={capacityCompartmentLayerSchema}
        >
          {() => (
            <>
              <FormikTextField
                name="maxHeight"
                placeholder="Enter the max height"
                label="Max Height"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">cm</InputAdornment>
                  ),
                }}
                fullWidth
              />
              <FormikAutocompleteHooks
                name="increment"
                placeholder="Enter the increment"
                label="Increment"
                options={incrementOptions}
              />
            </>
          )}
        </TemplateForm>
      </VantageDialog>
    </>
  );
};
