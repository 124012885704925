import React from 'react';
import {Paper} from '@mui/material';
import truck from '../../../../img/background/compartmentBackground.png';
import {TripDebriefContext} from '../../TripDebriefContext';
import {
  illustrationRatio,
  useTripDebriefSummaryVehicleTruckStyles,
} from './TripDebriefSummaryVehicleTruck.style';
import {MasterTripDebriefVehicleTypeSnapshot} from '@onroadvantage/onroadvantage-api';

export const TripDebriefSummaryVehicleTruck: React.FC = () => {
  const classes = useTripDebriefSummaryVehicleTruckStyles();
  const {masterTripDebriefData} = React.useContext(TripDebriefContext);
  /**
   *  Destructure vehicleType, need to add || ({} as MasterTripDebriefVehicleTypeSnapshot) for typescript, since
   *  vehicleType is nullable.
   */
  const {compartments} =
    masterTripDebriefData?.snapshotData?.vehicle?.type ||
    ({} as MasterTripDebriefVehicleTypeSnapshot);
  const [totalWidth, setTotalWidth] = React.useState<number>(0);
  const [imageLoaded, setImageLoaded] = React.useState<boolean>(false);
  const parentRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (parentRef.current) {
      setTotalWidth(parentRef?.current?.offsetWidth);
    }
    window.onresize = () => {
      if (parentRef.current) {
        setTotalWidth(parentRef?.current?.offsetWidth);
      }
    };
    return () => setTotalWidth(0);
  }, []);

  const handleImageLoad = React.useCallback(() => setImageLoaded(true), []);

  return (
    <Paper elevation={0} ref={parentRef} className={classes.root}>
      <img
        src={truck}
        className={classes.truck}
        alt="background"
        onLoad={handleImageLoad}
      />
      {imageLoaded && (
        <div
          className={classes.container}
          style={{
            left: totalWidth * 0.23,
            width: totalWidth * 0.97 - totalWidth * 0.23,
            height: totalWidth * illustrationRatio * 0.61,
          }}
        >
          {compartments?.map((compartment, index) => (
            <div
              key={compartment.id ?? index}
              className={classes.compartment}
            />
          ))}
        </div>
      )}
    </Paper>
  );
};
