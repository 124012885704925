import React from 'react';
import {useHistory} from 'react-router-dom';
import {
  Contract,
  ContractPost,
  ContractTaskTemplatePatch,
  ContractUpdate,
  Listing,
} from '@onroadvantage/onroadvantage-api';
import {FormikHelpers, FormikProps} from 'formik/dist/types';
import {contractApi, contractTaskTemplateApi} from '../../../api';
import {useAppNotifications} from '../../../contexts';
import {
  CONTRACT_DETAILS_INITIAL_VALUES,
  CONTRACT_INSPECTION_INITIAL_VALUES,
  CONTRACT_PRIORITY_OPTIONS,
  CONTRACT_SETUP_INITIAL_VALUES,
  CONTRACT_SETUP_STEPS,
  CONTRACT_STEP_OPTIONS,
  CONTRACT_TRIP_EXECUTION_INITIAL_VALUES,
  CONTRACT_TRIP_NOTIFICATIONS_INITIAL_VALUES,
  CONTRACT_TRIP_TIMES_INITIAL_VALUES,
  IContractDetailsForm,
  IContractInspectionForm,
  IContractSetupForm,
  IContractTaskTemplateInlineForm,
  IContractTripExecutionForm,
  IContractTripNotificationsForm,
  IContractTripTimesForm,
} from './contractConstants';
import {LuxonService} from '../../../service';
import {
  parsePotentialString,
  TOnInlineAdd,
  TOnInlineEdit,
} from '../../../factory/template';
import {
  getContractDetailsInitialValues,
  getContractInspectionInitialValues,
  getContractTripExecutionInitialValues,
  getContractTripNotificationsInitialValues,
  getContractTripTimesInitialValues,
} from './getContractInitialValues';

export const useContract = () => {
  const history = useHistory();
  const notify = useAppNotifications();
  const [contract, setContract] = React.useState<Contract | undefined>();
  const [
    settingUpContractTaskTemplateList,
    setSettingUpContractTaskTemplateList,
  ] = React.useState<IContractTaskTemplateInlineForm[]>([]);
  const [contractId, setContractId] = React.useState<number | undefined>();
  const [loadingContract, setLoadingContract] = React.useState<boolean>(false);
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [activeStep, setActiveStep] = React.useState<number>(1);
  const [completedSteps, setCompletedSteps] = React.useState<number[]>([]);
  const detailsRef = React.useRef<FormikProps<IContractSetupForm>>(null);

  const steps = CONTRACT_SETUP_STEPS;

  const stepOptions = CONTRACT_STEP_OPTIONS;

  const priorityOptions = CONTRACT_PRIORITY_OPTIONS;

  const detailsInitialValues: IContractDetailsForm = React.useMemo(
    () => getContractDetailsInitialValues(contract),
    [contract]
  );

  const tripTimesInitialValues: IContractTripTimesForm = React.useMemo(
    () => getContractTripTimesInitialValues(contract),
    [contract]
  );

  const tripExecutionInitialValues: IContractTripExecutionForm = React.useMemo(
    () => getContractTripExecutionInitialValues(contract, priorityOptions),
    [contract, priorityOptions]
  );

  const tripNotificationsInitialValues: IContractTripNotificationsForm =
    React.useMemo(
      () => getContractTripNotificationsInitialValues(contract),
      [contract]
    );

  const inspectionInitialValues: IContractInspectionForm = React.useMemo(
    () => getContractInspectionInitialValues(contract),
    [contract]
  );

  const setupInitialValues: IContractSetupForm = CONTRACT_SETUP_INITIAL_VALUES;

  const handleNext = React.useCallback(() => {
    setActiveStep((prevActiveStep) => {
      const newActiveStep = prevActiveStep + 1;
      setCompletedSteps((prevCompletedSteps) =>
        prevCompletedSteps.includes(prevActiveStep)
          ? prevCompletedSteps
          : newActiveStep === steps.length
          ? [...prevCompletedSteps, prevActiveStep, steps.length]
          : [...prevCompletedSteps, prevActiveStep]
      );

      return newActiveStep;
    });
  }, [steps.length]);

  const handleGoToStep = React.useCallback(
    (step: number) => () => {
      if (completedSteps.includes(step)) {
        setActiveStep(step);
      }
    },
    [completedSteps]
  );

  const handleBack = React.useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, []);

  const handleReset = React.useCallback(() => {
    setActiveStep(1);
    setCompletedSteps([]);
    setSettingUpContractTaskTemplateList([]);
  }, []);

  const handleAddSetupContractTaskTemplate = React.useCallback<TOnInlineAdd>(
    (changes) => {
      const values = changes[0] as IContractTaskTemplateInlineForm | undefined;
      if (values) {
        setSettingUpContractTaskTemplateList((prevAddingContractTaskTemplate) =>
          [
            ...prevAddingContractTaskTemplate,
            {id: (prevAddingContractTaskTemplate.length + 1) * -1, ...values},
          ].sort((a, b) => a.sequence - b.sequence)
        );
      }
    },
    []
  );

  const handleEditSetupContractTaskTemplate = React.useCallback<TOnInlineEdit>(
    (changes) => {
      changes.forEach(({id, newValues}) => {
        setSettingUpContractTaskTemplateList((prevAddingContractTaskTemplate) =>
          prevAddingContractTaskTemplate
            .map((contractTaskTemplate) =>
              contractTaskTemplate.id === parsePotentialString(id)
                ? {...contractTaskTemplate, ...newValues}
                : contractTaskTemplate
            )
            .sort((a, b) => a.sequence - b.sequence)
        );
      });
    },
    []
  );

  const handleRemoveSetupContractTaskTemplate = React.useCallback(
    (row: IContractTaskTemplateInlineForm) => {
      setSettingUpContractTaskTemplateList((prevAddingContractTaskTemplate) =>
        prevAddingContractTaskTemplate.filter(({id}) => id !== row.id)
      );
    },
    []
  );

  const loadContract = React.useCallback(async () => {
    setLoadingContract(true);
    try {
      if (contractId) {
        const response = await contractApi.apiContractContractIdGet({
          contractId,
        });
        setContract(response);
      } else {
        setContract(undefined);
      }
    } catch (e) {
      notify('error', 'Failed to load contract');
    } finally {
      setLoadingContract(false);
    }
  }, [notify, contractId]);

  const handleContractSetupSubmit = React.useCallback(
    async (
      values: IContractSetupForm,
      formikHelpers: FormikHelpers<IContractSetupForm>
    ) => {
      setSubmitting(true);
      try {
        const {
          node,
          telematicsConfig,
          drivingBanStart,
          drivingBanEnd,
          tripStopArrivalTimestampTaskTemplate,
          tripStopDepartureTimestampTaskTemplate,
          tripStopArrivalOdometerTaskTemplate,
          tripStopDepartureOdometerTaskTemplate,
          tripStopServiceStartTimestampTaskTemplate,
          tripStopServiceEndTimestampTaskTemplate,
          speedTables,
          tripStopOdometerPriority,
          tripStopTimestampPriority,
          actualsStopMethodology,
          vehicleInspectionTaskTemplate,
          driverInspectionTaskTemplate,
        } = values;

        const drivingBanStartTime =
          LuxonService.fromLocalDateToServerTimeOnly(drivingBanStart);

        const drivingBanEndTime =
          LuxonService.fromLocalDateToServerTimeOnly(drivingBanEnd);

        if (
          values.name &&
          values.code &&
          node?.value &&
          telematicsConfig?.value &&
          drivingBanStartTime &&
          drivingBanEndTime &&
          values.driverTripQueryPreToleranceHours &&
          values.driverTripQueryPostToleranceHours
        ) {
          const body: ContractPost = {
            // Details
            name: values.name,
            code: values.code,
            nodeId: node?.value,
            telematicsConfigId: telematicsConfig?.value,
            honorRestSchedule: values.honorRestSchedule,
            driverScoringEnabled: values.driverScoringEnabled,
            // Trip Times
            shortBreakTrigger: values.shortBreakTrigger,
            shortBreakDuration: values.shortBreakDuration,
            longBreakTrigger: values.longBreakTrigger,
            longBreakDuration: values.longBreakDuration,
            maxShiftDrivingTime: values.maxShiftDrivingTime,
            shiftDuration: values.shiftDuration,
            dailyBreakDuration: values.dailyBreakDuration,
            maxWeeklyDuty: values.maxWeeklyDuty,
            weeklyBreak: values.weeklyBreak,
            drivingBanStart: drivingBanStartTime,
            drivingBanEnd: drivingBanEndTime,
            actualCalculationPostBuffer: values.actualCalculationPostBuffer,
            actualCalculationPreBuffer: values.actualCalculationPreBuffer,
            targetHoursPerDay: values.targetHoursPerDay,
            targetHoursPerVehiclePerDay: values.targetHoursPerVehiclePerDay,
            targetKmPerDay: values.targetKmPerDay,
            targetKmPerVehiclePerDay: values.targetKmPerVehiclePerDay,
            driverTripQueryPreToleranceHours:
              values.driverTripQueryPreToleranceHours,
            driverTripQueryPostToleranceHours:
              values.driverTripQueryPostToleranceHours,
            // Trip Notifications
            emailTripsheet: values.emailTripsheet,
            etaConfidence: values.etaConfidence,
            voiceBroadcastEnabled: values.voiceBroadcastEnabled,
            etaCalcEnabled: values.etaCalcEnabled,
            // Trip Execution
            tripStopArrivalTimestampTaskTemplateId:
              tripStopArrivalTimestampTaskTemplate?.value,
            tripStopDepartureTimestampTaskTemplateId:
              tripStopDepartureTimestampTaskTemplate?.value,
            tripStopArrivalOdometerTaskTemplateId:
              tripStopArrivalOdometerTaskTemplate?.value,
            tripStopDepartureOdometerTaskTemplateId:
              tripStopDepartureOdometerTaskTemplate?.value,
            tripStopServiceStartTimestampTaskTemplateId:
              tripStopServiceStartTimestampTaskTemplate?.value,
            tripStopServiceEndTimestampTaskTemplateId:
              tripStopServiceEndTimestampTaskTemplate?.value,
            tripStopOdometerPriority: tripStopOdometerPriority?.label,
            tripStopTimestampPriority: tripStopTimestampPriority?.label,
            actualsStopMethodology: actualsStopMethodology?.label,
            banOrderTripDoubleAllocation: values.banOrderTripDoubleAllocation,
            tripEndDepartureTaskBan: values.tripEndDepartureTaskBan,
            tripStartArrivalTaskBan: values.tripStartArrivalTaskBan,
            orderLineActualLoadedQuantityEnabled:
              values.orderLineActualLoadedQuantityEnabled,
            allowMultipleOrdersInCompartment:
              values.allowMultipleOrdersInCompartment,
            speedTableIds: speedTables
              .filter(({value}) => !!value)
              .map(({value}) => value as number),
            // Inspection
            mandatoryDailyVehicleInspectionEnabled:
              values.mandatoryDailyVehicleInspectionEnabled,
            mandatoryDailyDriverInspectionEnabled:
              values.mandatoryDailyDriverInspectionEnabled,
            vehicleInspectionTaskTemplateId:
              vehicleInspectionTaskTemplate?.value,
            driverInspectionTaskTemplateId: driverInspectionTaskTemplate?.value,
            // Other
            contractTaskTemplates: settingUpContractTaskTemplateList
              .filter(({taskTemplate}) => taskTemplate?.value)
              .map((contractTaskTemplate) => ({
                acknowledgeEnabled: contractTaskTemplate.acknowledgeEnabled,
                forceSequence: contractTaskTemplate.forceSequence,
                mandatory: contractTaskTemplate.mandatory,
                nodeTypeIds:
                  contractTaskTemplate.nodeTypes
                    ?.filter(({value}) => value)
                    .map(({value}) => value as number) ?? [],
                resetEnabled: contractTaskTemplate.resetEnabled,
                sequence: contractTaskTemplate.sequence,
                taskTemplateId: contractTaskTemplate.taskTemplate
                  ?.value as number,
              })),
          };
          const response = await contractApi.apiContractPost({body});
          if (response) {
            notify('success', 'Created contract');
            history.push('/contractlist');
          } else {
            notify('error', 'Failed to create contract');
          }
        } else {
          notify('warning', 'Missing required fields');
        }
      } catch (e) {
        notify('error', 'Failed to create contract');
      } finally {
        formikHelpers.setSubmitting(false);
        setSubmitting(false);
        setActiveStep(1);
        setCompletedSteps([]);
        setSettingUpContractTaskTemplateList([]);
      }
    },
    [history, notify, settingUpContractTaskTemplateList]
  );

  const handleContractEditSubmit = React.useCallback(
    async <T>(body: ContractUpdate, formikHelpers: FormikHelpers<T>) => {
      setSubmitting(true);
      try {
        if (contractId) {
          const response = await contractApi.apiContractContractIdPatch({
            contractId,
            body: body,
          });
          setContract(response);
          notify('success', 'Updated contract');
        }
      } catch (e) {
        notify('error', 'Failed to update contract');
      } finally {
        formikHelpers.setSubmitting(false);
        setSubmitting(false);
      }
    },
    [notify, setSubmitting, contractId]
  );

  const handleContractDetailsSubmit = React.useCallback(
    async (
      values: IContractDetailsForm,
      formikHelpers: FormikHelpers<IContractDetailsForm>
    ) => {
      const {node, telematicsConfig, ...otherValues} = values;
      await handleContractEditSubmit(
        {
          ...otherValues,
          nodeId: node?.value,
          telematicsConfigId: telematicsConfig?.value,
        },
        formikHelpers
      );
    },
    [handleContractEditSubmit]
  );

  const handleContractTaskTemplateAdd = React.useCallback<TOnInlineAdd>(
    async (changes) => {
      setSubmitting(true);
      try {
        const values = changes[0] as
          | IContractTaskTemplateInlineForm
          | undefined;

        const {taskTemplate, nodeTypes, ...otherValues} =
          values || ({} as IContractTaskTemplateInlineForm);
        if (contractId && taskTemplate?.value) {
          await contractTaskTemplateApi.apiContractTaskTemplatePost({
            body: {
              contractId,
              taskTemplateId: taskTemplate.value,
              nodeTypeIds: nodeTypes?.map(({value}) => value as number) ?? [],
              ...otherValues,
            },
          });
          notify('success', 'Created contract task template');
          await loadContract();
        }
      } catch (e) {
        notify('error', 'Failed to create contract task template');
      } finally {
        setSubmitting(false);
      }
    },
    [contractId, loadContract, notify]
  );

  const handleContractTaskTemplateEdit = React.useCallback<TOnInlineEdit>(
    async (changes) => {
      setSubmitting(true);
      try {
        for (const {id, newValues} of changes) {
          if (contractId && id) {
            const body: ContractTaskTemplatePatch = {};

            if ('taskTemplate' in newValues) {
              body.taskTemplateId = newValues.taskTemplate.value;
            }

            if ('nodeTypes' in newValues) {
              body.nodeTypeIds = newValues.nodeTypes.map(
                ({value}: Listing) => value
              );
            }

            if ('acknowledgeEnabled' in newValues) {
              body.acknowledgeEnabled = newValues.acknowledgeEnabled;
            }

            if ('forceSequence' in newValues) {
              body.forceSequence = newValues.forceSequence;
            }

            if ('mandatory' in newValues) {
              body.mandatory = newValues.mandatory;
            }

            if ('resetEnabled' in newValues) {
              body.resetEnabled = newValues.resetEnabled;
            }

            if ('sequence' in newValues) {
              body.sequence = newValues.sequence;
            }

            await contractTaskTemplateApi.apiContractTaskTemplateContractTaskTemplateIdPatch(
              {
                contractTaskTemplateId: parsePotentialString(id),
                body: body,
              }
            );
            notify('success', 'Updated contract task template');
            await loadContract();
          }
        }
      } catch (e) {
        notify('error', 'Failed to update contract task template');
      } finally {
        setSubmitting(false);
      }
    },
    [contractId, loadContract, notify]
  );

  const handleContractTaskTemplateDelete = React.useCallback(
    async (row: IContractTaskTemplateInlineForm) => {
      setSubmitting(true);
      try {
        if (contractId && row.id) {
          await contractTaskTemplateApi.apiContractTaskTemplateContractTaskTemplateIdDelete(
            {
              contractTaskTemplateId: row.id,
            }
          );
          await loadContract();
          notify('success', 'Deleted contract task template');
        }
      } catch (e) {
        notify('error', 'Failed to delete contract task template');
      } finally {
        setSubmitting(false);
      }
    },
    [contractId, loadContract, notify]
  );

  const handleContractTripTimesSubmit = React.useCallback(
    async (
      values: IContractTripTimesForm,
      formikHelpers: FormikHelpers<IContractTripTimesForm>
    ) => {
      const {drivingBanEnd, drivingBanStart, ...otherValues} = values;

      const drivingBanStartTime =
        LuxonService.fromLocalDateToServerTimeOnly(drivingBanStart);

      const drivingBanEndTime =
        LuxonService.fromLocalDateToServerTimeOnly(drivingBanEnd);
      await handleContractEditSubmit(
        {
          ...otherValues,
          drivingBanStart: drivingBanStartTime,
          drivingBanEnd: drivingBanEndTime,
        },
        formikHelpers
      );
    },
    [handleContractEditSubmit]
  );

  const handleContractTripExecutionSubmit = React.useCallback(
    async (
      values: IContractTripExecutionForm,
      formikHelpers: FormikHelpers<IContractTripExecutionForm>
    ) => {
      const {
        actualsStopMethodology,
        tripStopOdometerPriority,
        tripStopTimestampPriority,
        tripStopArrivalOdometerTaskTemplate,
        tripStopArrivalTimestampTaskTemplate,
        tripStopDepartureOdometerTaskTemplate,
        tripStopDepartureTimestampTaskTemplate,
        tripStopServiceEndTimestampTaskTemplate,
        tripStopServiceStartTimestampTaskTemplate,
        speedTables,
        ...otherValues
      } = values;
      await handleContractEditSubmit(
        {
          actualsStopMethodology: actualsStopMethodology?.label,
          tripStopOdometerPriority: tripStopOdometerPriority?.label,
          tripStopTimestampPriority: tripStopTimestampPriority?.label,
          tripStopArrivalOdometerTaskTemplateId:
            tripStopArrivalOdometerTaskTemplate?.value,
          tripStopArrivalTimestampTaskTemplateId:
            tripStopArrivalTimestampTaskTemplate?.value,
          tripStopDepartureOdometerTaskTemplateId:
            tripStopDepartureOdometerTaskTemplate?.value,
          tripStopDepartureTimestampTaskTemplateId:
            tripStopDepartureTimestampTaskTemplate?.value,
          tripStopServiceEndTimestampTaskTemplateId:
            tripStopServiceEndTimestampTaskTemplate?.value,
          tripStopServiceStartTimestampTaskTemplateId:
            tripStopServiceStartTimestampTaskTemplate?.value,
          speedTableIds: speedTables
            .filter(({value}) => !!value)
            .map(({value}) => value as number),
          ...otherValues,
        },
        formikHelpers
      );
    },
    [handleContractEditSubmit]
  );

  const handleContractTripNotificationsSubmit = React.useCallback(
    async (
      values: IContractTripNotificationsForm,
      formikHelpers: FormikHelpers<IContractTripNotificationsForm>
    ) => {
      await handleContractEditSubmit(values, formikHelpers);
    },
    [handleContractEditSubmit]
  );

  const handleContractInspectionSubmit = React.useCallback(
    async (
      values: IContractInspectionForm,
      formikHelpers: FormikHelpers<IContractInspectionForm>
    ) => {
      const {
        driverInspectionTaskTemplate,
        vehicleInspectionTaskTemplate,
        ...otherValues
      } = values;
      await handleContractEditSubmit(
        {
          driverInspectionTaskTemplateId: driverInspectionTaskTemplate?.value,
          vehicleInspectionTaskTemplateId: vehicleInspectionTaskTemplate?.value,
          ...otherValues,
        },
        formikHelpers
      );
    },
    [handleContractEditSubmit]
  );

  return {
    activeStep,
    completedSteps,
    contract,
    contractId,
    detailsInitialValues,
    detailsRef,
    inspectionInitialValues,
    loadContract,
    loadingContract,
    onAddSetupContractTaskTemplate: handleAddSetupContractTaskTemplate,
    onBack: handleBack,
    onContractDetailsSubmit: handleContractDetailsSubmit,
    onContractInspectionSubmit: handleContractInspectionSubmit,
    onContractSetupSubmit: handleContractSetupSubmit,
    onContractTaskTemplateAdd: handleContractTaskTemplateAdd,
    onContractTaskTemplateDelete: handleContractTaskTemplateDelete,
    onContractTaskTemplateEdit: handleContractTaskTemplateEdit,
    onContractTripExecutionSubmit: handleContractTripExecutionSubmit,
    onContractTripNotificationsSubmit: handleContractTripNotificationsSubmit,
    onContractTripTimesSubmit: handleContractTripTimesSubmit,
    onDetailsFormSubmit: handleContractEditSubmit,
    onEditSetupContractTaskTemplate: handleEditSetupContractTaskTemplate,
    onGoToStep: handleGoToStep,
    onNext: handleNext,
    onRemoveSetupContractTaskTemplate: handleRemoveSetupContractTaskTemplate,
    onReset: handleReset,
    priorityOptions,
    setContract,
    setContractId,
    settingUpContractTaskTemplateList,
    setupInitialValues,
    stepOptions,
    steps,
    submitting,
    tripExecutionInitialValues,
    tripNotificationsInitialValues,
    tripTimesInitialValues,
  };
};

export type useContractResponse = ReturnType<typeof useContract>;

export const useContractResponseInitial: useContractResponse = {
  activeStep: 1,
  completedSteps: [],
  contract: undefined,
  contractId: undefined,
  detailsInitialValues: CONTRACT_DETAILS_INITIAL_VALUES,
  detailsRef: {current: null},
  inspectionInitialValues: CONTRACT_INSPECTION_INITIAL_VALUES,
  loadContract: async () => {},
  loadingContract: false,
  onAddSetupContractTaskTemplate: () => {},
  onBack: () => {},
  onContractDetailsSubmit: async () => {},
  onContractInspectionSubmit: async () => {},
  onContractSetupSubmit: async () => {},
  onContractTaskTemplateAdd: () => {},
  onContractTaskTemplateDelete: async () => {},
  onContractTaskTemplateEdit: () => {},
  onContractTripExecutionSubmit: async () => {},
  onContractTripNotificationsSubmit: async () => {},
  onContractTripTimesSubmit: async () => {},
  onDetailsFormSubmit: async () => {},
  onEditSetupContractTaskTemplate: () => {},
  onGoToStep: () => () => {},
  onNext: () => {},
  onRemoveSetupContractTaskTemplate: () => {},
  onReset: () => {},
  priorityOptions: CONTRACT_PRIORITY_OPTIONS,
  setContract: () => {},
  setContractId: () => {},
  settingUpContractTaskTemplateList: [],
  setupInitialValues: CONTRACT_SETUP_INITIAL_VALUES,
  stepOptions: CONTRACT_STEP_OPTIONS,
  steps: CONTRACT_SETUP_STEPS,
  submitting: false,
  tripExecutionInitialValues: CONTRACT_TRIP_EXECUTION_INITIAL_VALUES,
  tripNotificationsInitialValues: CONTRACT_TRIP_NOTIFICATIONS_INITIAL_VALUES,
  tripTimesInitialValues: CONTRACT_TRIP_TIMES_INITIAL_VALUES,
};
