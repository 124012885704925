import React from 'react';
import {userTrainingCourseApi} from '../../../api';
import {
  Badge,
  List,
  ListItem,
  Button,
  ListItemText,
  Tooltip,
} from '@mui/material';
import {List as ListIcon} from '@mui/icons-material';
import {TemplateTable, TemplateTableConfig} from '../../../factory/template';
import {Loader} from '../../loader';
import {
  UserTrainingCourseUnassignedUser,
  ApiUserTrainingCourseUnassignedUserGetRequest,
} from '@onroadvantage/onroadvantage-api';
import {useTemplateTable} from '../../../factory/template';
import {useAppNotifications} from '../../../contexts';
import {TrainingCourseContext} from '../TrainingCourseContext';
import {TrainingCourseUserContext} from './TrainingCourseUserContext';

export const TrainingCourseUserAddTable: React.FC = () => {
  const notify = useAppNotifications();
  const {trainingCourseId} = React.useContext(TrainingCourseContext);
  const {onAssignUsersToCourse, submitting} = React.useContext(
    TrainingCourseUserContext
  );
  const [config] = React.useState<
    TemplateTableConfig<UserTrainingCourseUnassignedUser>
  >({
    columns: [{name: 'email', label: 'Email', type: 'string'}],
    enableFilter: true,
    disableSelectAll: true,
  });
  const [
    {list, filters, loading, currentPage, pageSize, pageTotal, itemTotal},
    {
      // Getters
      getResponse,
      getRequestObj,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      // Setters
      cleanupList,
      setLoading,
    },
  ] = useTemplateTable<
    UserTrainingCourseUnassignedUser,
    ApiUserTrainingCourseUnassignedUserGetRequest
  >();
  const [selection, setSelection] = React.useState<
    UserTrainingCourseUnassignedUser[]
  >([]);

  const getUnassignedUsers = React.useCallback(async () => {
    setLoading(true);
    try {
      if (trainingCourseId) {
        const requestObj = getRequestObj(['email']);
        return getResponse(
          await userTrainingCourseApi.apiUserTrainingCourseUnassignedUserGet({
            ...requestObj,
            trainingCourseId,
          })
        );
      }
    } catch (e) {
      notify('error', e.message ?? 'Failed to load users');
    } finally {
      setLoading(false);
    }
  }, [setLoading, trainingCourseId, getRequestObj, getResponse, notify]);

  const handleOrdersAddSubmit = React.useCallback(() => {
    onAssignUsersToCourse(selection.map(({id}) => id as number));
    setSelection([]);
  }, [onAssignUsersToCourse, selection]);

  const handleOrdersAddCancel = () => setSelection([]);

  React.useEffect(() => {
    getUnassignedUsers();
    return () => {
      cleanupList();
      setSelection([]);
    };
  }, [cleanupList, getUnassignedUsers]);

  return (
    <>
      <TemplateTable
        config={config}
        currentPage={currentPage}
        itemTotal={itemTotal}
        pageSize={pageSize}
        pageTotal={pageTotal}
        onCurrentPageChange={handleCurrentPageChange}
        onPageSizeCountsChange={handlePageSizeCountsChange}
        filters={filters}
        onFiltersChange={handleFiltersChange}
        list={list ?? []}
        selection={selection}
        onSelectionChange={setSelection}
        loading={loading}
        toolbarCustomAction={
          <>
            {selection.length > 0 && (
              <Tooltip
                title={
                  <List dense disablePadding>
                    {selection.map(({email}) => (
                      <ListItem dense disablePadding disableGutters key={email}>
                        <ListItemText primary={email} />
                      </ListItem>
                    ))}
                  </List>
                }
                arrow
              >
                <Badge badgeContent={selection.length}>
                  <ListIcon />
                </Badge>
              </Tooltip>
            )}
            <Button
              disabled={submitting || loading || selection.length < 1}
              color="primary"
              variant="contained"
              type="submit"
              onClick={handleOrdersAddSubmit}
              startIcon={<Loader size={25} loading={submitting} />}
              sx={{mx: 2}}
            >
              Add Users
            </Button>
            <Button
              disabled={submitting || loading || selection.length < 1}
              variant="outlined"
              type="button"
              onClick={handleOrdersAddCancel}
            >
              Cancel
            </Button>
          </>
        }
      />
    </>
  );
};
