import React from 'react';
import {withStyles, WithStyles} from '@mui/styles';
import {Table} from '@devexpress/dx-react-grid-material-ui';
import {TemplateTableStyles} from '../TemplateTable.style';

export const TableComponentBase = ({
  classes,
  ...restProps
}: WithStyles<typeof TemplateTableStyles>) => (
  <div style={{display: 'flex', flex: 1}}>
    <Table.Table {...restProps} className={classes.tableBody} />
  </div>
);

export const TableComponent = withStyles(TemplateTableStyles, {
  name: 'TableComponent',
})(TableComponentBase);
