import React from 'react';
import {
  PlanningSolution as PlanningSolutionType,
  WebPlanningSolutionOptimised as WebPlanningSolutionOptimisedType,
} from '@onroadvantage/onroadvantage-api';

import {
  ChangeSet,
  Column,
  Filter as FilterProps,
  TableColumnWidthInfo,
} from '@devexpress/dx-react-grid';
import {RouteComponentProps} from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import OpenIcon from '@mui/icons-material/OpenInNew';
import {
  usePlanningSolutionListData,
  usePlanningSolutionViewStore,
} from '../../../stores/context';
import {planningSolutionApi} from '../../../api';
import ConfigService from '../../../service/ConfigService/ConfigService';
import {getStringFromSorting} from '../../../service/Util';
import {SettingService, XLSXService} from '../../../service';
import {PlanningSolutionListView} from './PlanningSolutionListView';
import {appNotificationStore} from '../../../stores/mobxStores';
import HistoryService from '../../../service/history';

const VIEW_ID = 'PlanningSolutionList';
const COL_VISIBILITY_SETTING_ID = 'col_visibility';
const DEFAULT_HIDDEN_COLUMNS: Array<string> = ['contractCode', 'createdAt'];

// TODO move
// export type TFilterQuery = {[key: string]: any};
export type TDownloadParams = {[key: string]: any};

const SolutionAction = (row: WebPlanningSolutionOptimisedType) => {
  // TODO update planning solution with row value to eliminate a reload
  const handleNavigate = () => {
    HistoryService.push(`/planningsolutionlist/s/${row.id}`);
  };

  return (
    <IconButton onClick={handleNavigate} size="large">
      <OpenIcon />
    </IconButton>
  );
};

type Props = RouteComponentProps;

// eslint-disable-next-line import/prefer-default-export
export const PlanningSolutionList: React.FC<Props> = ({history}) => {
  const {
    // values
    listItems,
    pagination,
    filters,
    sorting,

    // actions
    setCurrentPage,
    setPerPage,
    setFilters,
    setSorting,

    addItem,
    removeItem,
    updateItem,
  } = usePlanningSolutionListData((store) => ({
    listItems: store.listItems,
    // TODO fix pagination
    pagination: store.pagination,

    setCurrentPage: store.setCurrentPage,
    setPages: store.setPages,
    setPerPage: store.setPerPage,
    setTotalCount: store.setTotalCount,

    setListItems: store.setListItems,
    addItem: store.addItem,
    removeItem: store.removeItem,
    updateItem: store.updateItem,

    filters: store.filters,
    setFilters: store.setFilters,
    sorting: store.sorting,
    setSorting: store.setSorting,
  }));

  const {isDownloading, isLoading, setDownloading, setLoading} =
    usePlanningSolutionViewStore((store) => ({
      isDownloading: store.isListDownloading,
      isLoading: store.isListLoading,
      setDownloading: store.setListDownloading,
      setLoading: store.setListLoading,
    }));

  // TODO fix perpage on api endpoint - [25, 50, 100]
  const [perPages] = React.useState<Array<25 | 50 | 100>>([25, 50, 100]);
  const [columnWidths, setColumnWidths] = React.useState<
    Array<TableColumnWidthInfo>
  >([
    {columnName: 'name', width: 300},
    {columnName: 'contractName', width: 200},
    {columnName: 'contractCode', width: 200},
    {columnName: 'status', width: 100},
    {columnName: 'assignedOrderCount', width: 100},
    {columnName: 'vehicleCount', width: 100},
    {columnName: 'plannedPerc', width: 100},
    {columnName: 'planKm', width: 100},
    {columnName: 'tripCount', width: 100},
    {columnName: 'actions', width: 100},
    // {columnName: 'createdAt', width: 100},
  ]);
  const [hiddenColumns, setHiddenColumns] = React.useState<Array<string>>(
    SettingService.loadColumnVisibility(VIEW_ID, COL_VISIBILITY_SETTING_ID) ||
      DEFAULT_HIDDEN_COLUMNS
  );

  const handleAdd = () => history.push('/planningsolutionlist/add');

  // TODO redundant?
  const isRefreshing = isLoading;
  const columns: Column[] = [
    {name: 'actions', title: 'Actions', getCellValue: SolutionAction},
    {
      name: 'name',
      title: 'Planning Solution Name',
    },
    {
      name: 'contractName',
      title: 'Contract name',
    },
    {
      name: 'contractCode',
      title: 'Contract code',
    },
    {
      name: 'status',
      title: 'Status',
    },
    {
      name: 'assignedOrderCount',
      title: 'Scope Orders',
    },
    {
      name: 'vehicleCount',
      title: 'Scope Vehicles',
    },
    {
      name: 'plannedPerc',
      title: '% Planned',
      getCellValue: ({
        assignedOrderCount,
        unassignedOrderCount,
      }: WebPlanningSolutionOptimisedType) => {
        if (
          unassignedOrderCount === undefined ||
          assignedOrderCount === undefined ||
          assignedOrderCount === 0
        ) {
          return '-';
        }

        return `${Math.round(
          (1 - unassignedOrderCount / assignedOrderCount) * 100
        )}%`;
      },
    },
    {
      name: 'planKm',
      title: 'Plan KM',
      getCellValue: (row: WebPlanningSolutionOptimisedType) => {
        return row.planKm ? Math.round(row.planKm) : '-';
      },
    },
    {
      name: 'tripCount',
      title: 'Plan Trips',
    },
    // {
    //   name: 'createdAt',
    //   title: 'Created At',
    // },
  ];

  const handleSortingChange = (value: Array<any>) => {
    setSorting(value);
  };

  const handleDownload = async () => {
    setDownloading(true);
    const params: TDownloadParams = {
      perPage: ConfigService.downloadPerPageLimit,
    };

    // sorting
    const orderBy = getStringFromSorting(sorting);
    if (orderBy && orderBy !== '') {
      params.orderBy = orderBy;
    }

    // filters
    filters.forEach((value: FilterProps) => {
      params[value.columnName] = value.value;
    });

    // request
    const response = await planningSolutionApi.apiPlanningSolutionGet(params);

    const responseMapping = [
      {
        name: 'PlanningSolution name',
        path: 'name',
      },
    ];
    XLSXService.downloadSpreadsheet(
      response.items,
      responseMapping,
      'planningSolutions',
      null
    );

    setDownloading(false);
  };

  const handleCurrentPageChange = (value: number) => {
    setCurrentPage(value + 1);
  };

  const handlePageSizeChange = (value: number) => {
    setPerPage(value);
  };

  const handleFiltersChange = (value: Array<FilterProps>) => {
    setFilters(value);
  };

  const handleHiddenColumnNamesChange = (value: Array<string>) => {
    SettingService.setSetting(VIEW_ID, COL_VISIBILITY_SETTING_ID, value);
    setHiddenColumns(value);
  };

  const postCommitChanges = () => {
    setFilters([]);
    setSorting([]);
  };

  const handleCommitChanges = async ({changed, added, deleted}: ChangeSet) => {
    setLoading(true);

    if (changed) {
      changed.forEach((i: PlanningSolutionType) => {
        updateItem(i);
      });
      appNotificationStore.enqueueNotification(
        'success',
        'PlanningSolution Updated'
      );
    }

    if (added) {
      added.forEach((i: PlanningSolutionType) => {
        addItem(i);
      });
      appNotificationStore.enqueueNotification(
        'success',
        'PlanningSolution Added'
      );
    }

    if (deleted) {
      await planningSolutionApi.apiPlanningSolutionPlanningSolutionIdDelete({
        // @ts-expect-error upgrade
        planningSolutionId: listItems[deleted].id,
      });
      appNotificationStore.enqueueNotification(
        'success',
        'PlanningSolution Deleted'
      );
      // @ts-expect-error upgrade
      removeItem(listItems[deleted]);
    }
    postCommitChanges();
    setLoading(false);
  };

  return (
    <PlanningSolutionListView
      columns={columns}
      columnWidths={columnWidths}
      currentPage={pagination.currentPage}
      filters={filters}
      hiddenColumns={hiddenColumns}
      isDownloading={isDownloading}
      isLoading={isLoading}
      isRefreshing={isRefreshing}
      listItems={listItems}
      pageSize={pagination.perPage}
      pageSizes={perPages}
      onAddRow={handleAdd}
      onColumnWidthsChange={setColumnWidths}
      onCommitChanges={handleCommitChanges}
      onCurrentPageChange={handleCurrentPageChange}
      onDownload={handleDownload}
      onFiltersChange={handleFiltersChange}
      onHiddenColumnNamesChange={handleHiddenColumnNamesChange}
      onPageSizeChange={handlePageSizeChange}
      onSortingChange={handleSortingChange}
      sorting={sorting}
      totalCount={pagination.totalCount}
    />
  );
};
