import React from 'react';
import {useHistory} from 'react-router-dom';
import {Button, IconButton, Stack, Tooltip} from '@mui/material';
import {Edit, List} from '@mui/icons-material';
import {
  ContractTripDump,
  MasterTripDebriefResponse,
  Trip,
  TripStop as TripStopType,
} from '@onroadvantage/onroadvantage-api';
import {TripCard} from '../tripCard';
import {TemplateTable, TemplateTableConfig} from '../../../factory/template';
import {useTripContext} from '../tripContext';
import {getStopActualTAT, getStopPlannedTAT} from '../../../utils';
import {TripStopContextProvider} from './tripStopContext';
import {TripStop} from './TripStop';

export const TripStopList: React.FC = () => {
  const history = useHistory();
  const {
    hasPermission,
    masterTrip,
    masterTripId,
    loadingMasterTrip,
    recalculateTrip,
  } = useTripContext();
  /** Destructure debrief, need to add || ({} as Trip) for typescript, since debrief is nullable. */
  const {approvedForBilling} =
    masterTrip?.debrief || ({} as MasterTripDebriefResponse);
  /** Destructure trip, need to add || ({} as Trip) for typescript, since trip is nullable. */
  const {stops, contract} = masterTrip?.trip || ({} as Trip);
  /** Destructure masterTrip, need to add || ({} as MasterTripQuery) for typescript, since masterTrip is nullable. */
  const {tripStopTimestampPriority} = contract || ({} as ContractTripDump);

  const config: TemplateTableConfig<TripStopType> = React.useMemo(
    () => ({
      columns: [
        {
          name: 'stop_type',
          label: 'Site Type ',
          type: 'string',
          getValue: ({node}) => node?.type,
        },
        {
          name: 'taskTemplateNodeType',
          label: 'Task Template Node Type ',
          type: 'string',
        },
        {
          name: 'node.name',
          label: 'Site Name',
          type: 'string',
          getValue: ({node}) => node?.name,
        },
        {
          name: 'node.externalReference',
          label: 'External Reference',
          type: 'string',
          getValue: ({node}) => node?.externalReference,
        },
        {name: 'sequence', label: 'Sequence', type: 'number'},
        {name: 'arrivalTime', label: 'Planned Arrival Time', type: 'datetime'},
        {
          name: 'departureTime',
          label: 'Planned Departure Time',
          type: 'datetime',
        },

        {
          name: 'plannedTAT',
          label: 'Planned TAT',
          type: 'string',
          getValue: (row) => row.totalServiceTime ?? getStopPlannedTAT(row),
        },
        {
          name: 'actual.arrivalTime',
          label: 'Geofence Arrival Time',
          type: 'datetime',
          getValue: ({tripStopExecution}) =>
            !tripStopExecution
              ? null
              : tripStopTimestampPriority === 'mobile'
              ? tripStopExecution.mobileArrivalTime
              : tripStopExecution.gpsArrivalTime,
        },
        {
          name: 'actual.departureTime',
          label: 'Geofence Departure Time',
          type: 'datetime',
          getValue: ({tripStopExecution}) =>
            !tripStopExecution
              ? null
              : tripStopTimestampPriority === 'mobile'
              ? tripStopExecution.mobileDepartureTime
              : tripStopExecution.gpsDepartureTime,
        },
        {
          name: 'actualTAT',
          label: 'Geofence TAT',
          type: 'string',
          getValue: ({tripStopExecution}) =>
            getStopActualTAT(tripStopExecution, tripStopTimestampPriority),
        },
      ],
      disableToolbar: true,
      disablePagination: true,
      enableGrouping: false,
    }),
    [tripStopTimestampPriority]
  );

  const list = React.useMemo(() => stops ?? [], [stops]);

  const handleNavigateToEdit = React.useCallback(() => {
    if (masterTripId) {
      history.push(`/triplist/${masterTripId}/stops/edit`);
    }
  }, [history, masterTripId]);

  return (
    <TripCard
      title="Stops & Editing"
      avatar={<List />}
      action={
        <Stack direction="row" spacing={2}>
          <Button variant="contained" onClick={recalculateTrip}>
            Recalculate
          </Button>
          <Tooltip title="Trip Edit" arrow>
            <IconButton
              disabled={!hasPermission.edit || approvedForBilling}
              onClick={handleNavigateToEdit}
            >
              <Edit />
            </IconButton>
          </Tooltip>
        </Stack>
      }
    >
      <TemplateTable
        config={config}
        currentPage={1}
        list={list}
        loading={loadingMasterTrip}
        rowDetail={({row}) => (
          <TripStopContextProvider row={row}>
            <TripStop />
          </TripStopContextProvider>
        )}
        nestedLevel={0}
      />
    </TripCard>
  );
};
