import moment, {DurationInputArg1, Moment} from 'moment';

export type TConvertTime = (
  date?: moment.MomentInput | Moment | Date | string,
  formatOverride?: string
) => string | undefined;

const toDateFromTime = (time?: moment.MomentInput) => {
  if (!time) return undefined;
  return moment(time, 'HH:mm:ss');
};

const displayTimestamp = (timestamp: moment.MomentInput) => {
  if (timestamp) {
    return moment(timestamp).local().format('lll');
  }
  return '-';
};

const toServerTimeOnly: TConvertTime = (date, formatOverride) => {
  if (!date) return undefined;

  if (moment(date).isUTC()) {
    return moment(date).format(formatOverride ?? 'HH:mm:ss');
  } else
    return moment(date)
      .utc()
      .format(formatOverride ?? 'HH:mm:ss');
};

const toLocalTimeOnly: TConvertTime = (date, formatOverride) => {
  if (!date) return undefined;

  if (typeof date === 'string') {
    const timeSplit = date.toString().split(':');
    return moment()
      .hours(Number(timeSplit[0]))
      .minutes(Number(timeSplit[1]))
      .seconds(Number(timeSplit[2] ?? 0))
      .subtract(new Date().getTimezoneOffset(), 'minutes')
      .toDate()
      .toLocaleTimeString();
  } else {
    return moment(date)
      .local()
      .format(formatOverride ?? 'HH:mm:ss');
  }
};

/**
 * @deprecated Use toLocalTimeOnly.
 */
const localiseTimeOnly = (time: moment.MomentInput) => {
  if (!time) {
    return null;
  }

  if (typeof time !== 'string') {
    time = toDateFromTime(time)?.format('HH:mm:ss') ?? time;
  }

  const timeSplit = time.toString().split(':');

  if (timeSplit.length === 3) {
    const testTimeLocal = moment()
      .hours(Number(timeSplit[0]))
      .minutes(Number(timeSplit[1]))
      .seconds(Number(timeSplit[2]));
    return testTimeLocal
      .subtract(new Date().getTimezoneOffset(), 'minutes')
      .toDate()
      .toLocaleTimeString();
  } else {
    const testTimeLocal = moment()
      .hours(Number(timeSplit[0]))
      .minutes(Number(timeSplit[1]))
      .seconds(0);
    return testTimeLocal
      .subtract(new Date().getTimezoneOffset(), 'minutes')
      .toDate()
      .toLocaleTimeString();
  }
};

/**
 * @deprecated Use toServerTimeOnly.
 */
const convertToServerTimeOnly = (time: moment.MomentInput) => {
  if (!time) {
    return undefined;
  }

  //think this is crucial, to convert any time or date related stuff to a date object and pass that around instead of some time string
  if (typeof time !== 'string') {
    time = toDateFromTime(time)?.format('HH:mm:ss') ?? time;
  }

  const timeSplit = time.toString().split(':');
  if (timeSplit.length === 3) {
    const testTimeLocal = moment()
      .hours(Number(timeSplit[0]))
      .minutes(Number(timeSplit[1]))
      .seconds(Number(timeSplit[2]));
    return testTimeLocal
      .add(new Date().getTimezoneOffset(), 'minutes')
      .toDate()
      .toLocaleTimeString();
  } else {
    const testTimeLocal = moment()
      .hours(Number(timeSplit[0]))
      .minutes(Number(timeSplit[1]))
      .seconds(0);
    return testTimeLocal
      .add(new Date().getTimezoneOffset(), 'minutes')
      .toDate()
      .toLocaleTimeString();
  }
};

const displayShortTimestamp = (timestamp: moment.MomentInput) => {
  if (timestamp) {
    return moment(timestamp).local().format('MMM DD hh:mm a');
  }
  return '-';
};

const displayShortTimestamp24hour = (timestamp: moment.MomentInput) => {
  if (timestamp) {
    return moment(timestamp).local().format('MMM DD HH:mm');
  }
  return '-';
};

const displayTimestampWithSec = (timestamp: moment.MomentInput) => {
  if (timestamp) {
    return moment(timestamp).local().format('MMMM Do YYYY, h:mm:ss a');
  }
  return '-';
};

const displayFromNow = (timestamp: moment.MomentInput) =>
  moment(timestamp).fromNow();

const startOfDate = (timestamp: moment.MomentInput) =>
  moment(timestamp)
    .set({hour: 0, minute: 0, second: 0, millisecond: 0})
    .toISOString();

const endOfDate = (timestamp: moment.MomentInput) =>
  moment(timestamp)
    .set({hour: 0, minute: 0, second: 0, millisecond: 0})
    .add(1, 'day')
    .toISOString();

const ganttInputFormat = (timestamp: moment.MomentInput) => {
  return moment(timestamp).format('YYYY-MM-DDTHH:mm:ss');
};

const getMaterialFieldDateTimeFormat = (timestamp: moment.MomentInput) => {
  return moment(timestamp)
    .add(moment(timestamp).local().utcOffset() / 60, 'hour')
    .format('YYYY-MM-DDTHH:mm:ss');
};

const toString = (timestamp: moment.MomentInput) =>
  moment(timestamp).toISOString();

const subtractDuration = (
  timestamp: moment.MomentInput,
  duration: DurationInputArg1
) => moment(timestamp).subtract(moment.duration(duration)).toISOString();

const addDuration = (
  timestamp: moment.MomentInput,
  duration: DurationInputArg1
) => moment(timestamp).add(moment.duration(duration)).toISOString();

type TMomentStop = (stop: {
  end?: moment.MomentInput;
  start?: moment.MomentInput;
  tripEnd?: moment.MomentInput;
  tripStart?: moment.MomentInput;
}) => string | undefined;

const stopTat: TMomentStop = (stop) =>
  moment
    .utc(
      moment
        .duration(moment(stop.end).diff(moment(stop.start)))
        .as('milliseconds')
    )
    .format('HH:mm:ss');

const tripExecutionTat: TMomentStop = (stop) =>
  moment
    .utc(
      moment
        .duration(moment(stop.tripEnd).diff(moment(stop.tripStart)))
        .as('milliseconds')
    )
    .format('HH:mm:ss');

const timeDiff = (start: moment.MomentInput, end: moment.MomentInput) =>
  moment(end).diff(moment(start), 'minutes');

const toDate = (datetimeString: moment.MomentInput) =>
  moment(datetimeString).format('LL');
const toLTDate = (datetimeString: moment.MomentInput) =>
  moment(datetimeString).format('LT');
const customFormatAMPM = (datetimeString: moment.MomentInput) =>
  moment(datetimeString, 'hh:mm A');

const toDateTime = (datetimeString: moment.MomentInput) =>
  moment(datetimeString).format('YYYY-MM-DDTHH:mm:ss');
const toServerDateTime = (userTimeZoneDateTime: moment.MomentInput) => {
  return MomentService.toDateTime(moment.utc(moment(userTimeZoneDateTime)));
};

const MomentService = {
  toDateFromTime,
  displayTimestamp,
  toServerTimeOnly,
  toLocalTimeOnly,
  localiseTimeOnly,
  convertToServerTimeOnly,
  displayShortTimestamp,
  displayShortTimestamp24hour,
  displayTimestampWithSec,
  displayFromNow,
  startOfDate,
  endOfDate,
  ganttInputFormat,
  getMaterialFieldDateTimeFormat,
  toString,
  subtractDuration,
  addDuration,
  stopTat,
  tripExecutionTat,
  timeDiff,
  toDate,
  toLTDate,
  customFormatAMPM,
  toDateTime,
  toServerDateTime,
};

export {MomentService as default, MomentService};
