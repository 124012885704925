import React from 'react';
import {
  Listing,
  OrderLine as OrderLineType,
  OrderLinePost,
  OrderLineUpdate,
  WebMasterTripOptimisedListResponse,
} from '@onroadvantage/onroadvantage-api';
import {orderLineApi, productApi} from '../../../api';
import {
  checkQuantityExceedsVariability,
  TemplateTableContextProps,
  TOnInlineEdit,
} from '../../../factory/template';
import {OrderContext} from '../OrderContext';
import * as Yup from 'yup';
import {useAppNotifications} from '../../../contexts';
import {RoleService} from '../../../service';
import {IVantageDialogRef, VantageDialog} from '../../dialog';

export type OrderLineContextProps = TemplateTableContextProps<
  OrderLineType,
  WebMasterTripOptimisedListResponse
>;

export const OrderLineContext = React.createContext<OrderLineContextProps>({
  loading: false,
  currentPage: 1,
  list: [],
  loadList: async () => null,
  onInlineAdd: () => null,
  onInlineEdit: () => null,
});

interface IOrderLinePost {
  product: Listing;
  quantity: number | string | undefined | null;
  actualLoadedQuantity: number | string | undefined | null;
  actualQuantity: number | string | undefined | null;
}

interface OrderLineContextProviderProps {
  orderId?: number;
}

export const OrderLineContextProvider: React.FC<
  OrderLineContextProviderProps
> = ({children}) => {
  const notify = useAppNotifications();
  const {loadOrder, order} = React.useContext(OrderContext);
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [editChanges, setEditChanges] = React.useState<
    {id: string; newValues: {[key: string]: any}}[]
  >([]);
  const editCapacityDialogRef = React.useRef<IVantageDialogRef>(null);

  const handleEditOrderLine = React.useCallback(
    async (id: number, requestObj: OrderLineUpdate) => {
      try {
        await orderLineApi.apiOrderLineOrderLineIdPatch({
          orderLineId: id,
          body: requestObj,
        });
        notify('success', 'Order Line Edited');
      } catch (e) {
        notify('error', 'Failed to edit order line');
      }
    },
    [notify]
  );

  const handleGetMatchingCapacityDimension = React.useCallback(
    async (orderLineId: number, newProductId?: number) => {
      const planningOrder = order?.planningOrder || {};
      const currentOrderLine = order?.lines?.find(({id}) => id === orderLineId);
      let uom: string | undefined | null;
      /** Get the currently selected product's unit of measure (uom) */
      if (newProductId && newProductId !== currentOrderLine?.product?.id) {
        /** get new product uom */
        try {
          const response = await productApi.apiProductProductIdGet({
            productId: newProductId,
          });
          uom = response.uom;
        } catch (e) {
          uom = currentOrderLine?.product?.uom;
        }
      } else {
        uom = currentOrderLine?.product?.uom;
      }

      if (!uom) {
        notify('warning', 'Could not find a product uom.');
        return null;
      }

      const capacityDimensionUnits = [
        planningOrder.capacityDimension1Unit,
        planningOrder.capacityDimension2Unit,
        planningOrder.capacityDimension3Unit,
        planningOrder.capacityDimension4Unit,
      ];

      let matchingUnit: string | null | undefined = null;
      /** Match the current product's uom to the corresponding capacityDimensionUnit */
      for (const unit of capacityDimensionUnits) {
        if (unit?.toLowerCase() === uom.toLowerCase()) {
          matchingUnit = unit;
        }
      }

      if (matchingUnit) {
        const matchingIndex = capacityDimensionUnits.indexOf(matchingUnit);
        /** Note! Important that this order stays the same as the `capacityDimensionUnits`'s order. */
        const capacityDimensions = [
          'capacityDimension1',
          'capacityDimension2',
          'capacityDimension3',
          'capacityDimension4',
        ] as const;

        let selectedCapacityDimension = null;

        if (matchingIndex !== -1) {
          selectedCapacityDimension = capacityDimensions[matchingIndex];
        }

        if (selectedCapacityDimension) {
          return selectedCapacityDimension;
        } else {
          notify('warning', 'Could not update a capacity dimension.');
        }
      } else {
        notify(
          'warning',
          `Product uom ${uom} did not match any planning order capacity dimension units.`
        );
      }
      return null;
    },
    [notify, order?.lines, order?.planningOrder]
  );

  const handleEditConfirmation = React.useCallback(
    async (action: 'accept' | 'decline') => {
      setSubmitting(true);
      try {
        for (const {id: orderLineId, newValues} of editChanges) {
          const requestObj: OrderLineUpdate = {};
          if ('product' in newValues && newValues.product?.value) {
            requestObj.productId = newValues.product.value;
            requestObj.productName = newValues.product.label;
          }
          if ('quantity' in newValues && newValues.quantity) {
            const parsedQuantity =
              typeof newValues.quantity === 'string'
                ? parseFloat(newValues.quantity)
                : newValues.quantity;
            requestObj.quantity = parsedQuantity;

            /**
             * Also update the planning order's relevant capacity dimension if the user accepts and order contains
             * a capacity dimension
             */
            if (action === 'accept') {
              const newProductId =
                'product' in newValues && newValues.product?.value
                  ? newValues.product?.value
                  : undefined;
              const capacityDimensionToUpdate =
                await handleGetMatchingCapacityDimension(
                  parseInt(orderLineId),
                  newProductId
                );
              if (capacityDimensionToUpdate) {
                requestObj[capacityDimensionToUpdate] = parsedQuantity;
              }
            }
          }

          if ('quantity' in newValues && newValues.quantity === '') {
            requestObj.quantity = null;

            /**
             * Also update the planning order's relevant capacity dimension if the user accepts and order contains
             * a capacity dimension
             */
            if (action === 'accept' && order?.planningOrder?.id != null) {
              const newProductId =
                'product' in newValues && newValues.product?.value
                  ? newValues.product?.value
                  : undefined;
              const capacityDimensionToUpdate =
                await handleGetMatchingCapacityDimension(
                  parseInt(orderLineId),
                  newProductId
                );
              if (capacityDimensionToUpdate) {
                requestObj[capacityDimensionToUpdate] = null;
              }
            }
          }

          if ('actualQuantity' in newValues && newValues.actualQuantity) {
            requestObj.actualQuantity =
              typeof newValues.actualQuantity === 'string'
                ? parseFloat(newValues.actualQuantity)
                : newValues.actualQuantity;
          }

          if (
            'actualQuantity' in newValues &&
            newValues.actualQuantity === ''
          ) {
            requestObj.actualQuantity = null;
          }

          if (
            'actualLoadedQuantity' in newValues &&
            newValues.actualLoadedQuantity
          ) {
            requestObj.actualLoadedQuantity =
              typeof newValues.actualLoadedQuantity === 'string'
                ? parseFloat(newValues.actualLoadedQuantity)
                : newValues.actualLoadedQuantity;
          }

          if (
            'actualLoadedQuantity' in newValues &&
            newValues.actualLoadedQuantity === ''
          ) {
            requestObj.actualLoadedQuantity = null;
          }

          await handleEditOrderLine(parseInt(orderLineId), requestObj);
        }
      } finally {
        setSubmitting(false);
        await loadOrder();
      }
    },
    [
      editChanges,
      handleEditOrderLine,
      handleGetMatchingCapacityDimension,
      loadOrder,
      order?.planningOrder?.id,
    ]
  );

  const handleInlineEdit = React.useCallback<TOnInlineEdit>(
    async (changes) => {
      let changesIncludesQuantityChange = false;

      changes.forEach(({newValues}) => {
        /** Check whether the planned quantity has been changed */
        if ('quantity' in newValues) {
          changesIncludesQuantityChange = true;
        }
      });

      if (order?.planningOrder?.id != null && changesIncludesQuantityChange) {
        setEditChanges(changes);
        editCapacityDialogRef.current?.openDialog();
      } else {
        setSubmitting(true);
        try {
          for (const change of changes) {
            const {quantity, actualQuantity, actualLoadedQuantity, product} =
              change.newValues;
            const requestObj: OrderLineUpdate = {};
            if (product) {
              requestObj.productId = product.value;
              requestObj.productName = product.label;
            }
            if (quantity) {
              requestObj.quantity =
                typeof quantity === 'string' ? parseFloat(quantity) : quantity;
            }
            if (quantity === '') {
              requestObj.quantity = null;
            }
            if (actualQuantity && change.id) {
              const orderLine = order?.lines?.find(
                (line) => line.id === parseInt(change.id)
              );
              const quantityToUse = quantity ?? orderLine?.quantity;

              if (product?.value && quantityToUse) {
                const productResponse = await productApi.apiProductProductIdGet(
                  {productId: product?.value}
                );

                const inRange = checkQuantityExceedsVariability({
                  quantity: quantityToUse,
                  actualQuantity:
                    typeof actualQuantity === 'string'
                      ? parseFloat(actualQuantity)
                      : actualQuantity,
                  variabilityThreshold: productResponse.variabilityThreshold,
                });
                if (!inRange) {
                  notify(
                    'warning',
                    `Actual quantity not in variability range ${productResponse.variabilityThreshold}%`
                  );
                  return;
                }
              } else if (
                orderLine?.product?.variabilityThreshold &&
                quantityToUse
              ) {
                const inRange = checkQuantityExceedsVariability({
                  quantity:
                    typeof quantityToUse === 'string'
                      ? parseFloat(quantityToUse)
                      : quantityToUse,
                  actualQuantity:
                    typeof actualQuantity === 'string'
                      ? parseFloat(actualQuantity)
                      : actualQuantity,
                  variabilityThreshold: orderLine.product.variabilityThreshold,
                });
                if (!inRange) {
                  notify(
                    'warning',
                    `Actual quantity not in variability range ${orderLine.product.variabilityThreshold}%`
                  );
                  return;
                }
              }
              requestObj.actualQuantity =
                typeof actualQuantity === 'string'
                  ? parseFloat(actualQuantity)
                  : actualQuantity;
            }
            if (actualQuantity === '') {
              requestObj.actualQuantity = null;
            }
            if (actualLoadedQuantity && change.id) {
              const orderLine = order?.lines?.find(
                (line) => line.id === parseInt(change.id)
              );
              const quantityToUse = quantity ?? orderLine?.quantity;

              if (product?.value && quantityToUse) {
                const productResponse = await productApi.apiProductProductIdGet(
                  {productId: product?.value}
                );

                const inRange = checkQuantityExceedsVariability({
                  quantity: quantityToUse,
                  actualQuantity:
                    typeof actualLoadedQuantity === 'string'
                      ? parseFloat(actualLoadedQuantity)
                      : actualLoadedQuantity,
                  variabilityThreshold: productResponse.variabilityThreshold,
                });
                if (!inRange) {
                  notify(
                    'warning',
                    `Actual quantity not in variability range ${productResponse.variabilityThreshold}%`
                  );
                  return;
                }
              } else if (
                orderLine?.product?.variabilityThreshold &&
                quantityToUse
              ) {
                const inRange = checkQuantityExceedsVariability({
                  quantity:
                    typeof quantityToUse === 'string'
                      ? parseFloat(quantityToUse)
                      : quantityToUse,
                  actualQuantity:
                    typeof actualLoadedQuantity === 'string'
                      ? parseFloat(actualLoadedQuantity)
                      : actualLoadedQuantity,
                  variabilityThreshold: orderLine.product.variabilityThreshold,
                });
                if (!inRange) {
                  notify(
                    'warning',
                    `Actual quantity not in variability range ${orderLine.product.variabilityThreshold}%`
                  );
                  return;
                }
              }
              requestObj.actualLoadedQuantity =
                typeof actualLoadedQuantity === 'string'
                  ? parseFloat(actualLoadedQuantity)
                  : actualLoadedQuantity;
            }
            if (actualLoadedQuantity === '') {
              requestObj.actualLoadedQuantity = null;
            }
            await handleEditOrderLine(parseInt(change.id), requestObj);
          }
        } catch (e) {
          notify('error', 'Failed to edit order line');
        } finally {
          setSubmitting(false);
          await loadOrder();
        }
      }
    },
    [
      handleEditOrderLine,
      loadOrder,
      notify,
      order?.lines,
      order?.planningOrder?.id,
    ]
  );

  const handleInlineAdd = React.useCallback(
    (newValues: Partial<IOrderLinePost>[]) => {
      setSubmitting(true);
      newValues?.forEach(async (change) => {
        try {
          if (order?.id) {
            const {quantity, actualQuantity, actualLoadedQuantity, product} =
              change;
            const requestObj: OrderLinePost = {orderId: order?.id};
            if (product) {
              requestObj.productId = product.value;
              requestObj.productName = product.label;
            }
            if (quantity) {
              requestObj.quantity =
                typeof quantity === 'string' ? parseFloat(quantity) : quantity;
            }
            if (quantity === '') {
              requestObj.quantity = undefined;
            }
            if (actualQuantity) {
              const findOrderLine = order.lines?.find(
                (line) => line.product?.id === product?.value
              );
              const findProduct = findOrderLine?.product;
              const quantityToUse =
                typeof quantity === 'string' ? parseFloat(quantity) : quantity;
              if (quantityToUse && findProduct) {
                const inRange = checkQuantityExceedsVariability({
                  quantity: quantityToUse,
                  actualQuantity:
                    typeof actualQuantity === 'string'
                      ? parseFloat(actualQuantity)
                      : actualQuantity,
                  variabilityThreshold: findProduct.variabilityThreshold,
                });
                if (!inRange) {
                  notify(
                    'warning',
                    `Actual quantity not in variability range ${findProduct.variabilityThreshold}%`
                  );
                  return;
                }
              } else if (product?.value && quantity) {
                const productResponse = await productApi.apiProductProductIdGet(
                  {productId: product?.value}
                );

                const inRange = checkQuantityExceedsVariability({
                  quantity:
                    typeof quantity === 'string'
                      ? parseFloat(quantity)
                      : quantity,
                  actualQuantity:
                    typeof actualQuantity === 'string'
                      ? parseFloat(actualQuantity)
                      : actualQuantity,
                  variabilityThreshold: productResponse.variabilityThreshold,
                });
                if (!inRange) {
                  notify(
                    'warning',
                    `Actual quantity not in variability range ${productResponse.variabilityThreshold}%`
                  );
                  return;
                }
              }
              requestObj.actualQuantity =
                typeof actualQuantity === 'string'
                  ? parseFloat(actualQuantity)
                  : actualQuantity;
            }
            if (actualQuantity === '') {
              requestObj.actualQuantity = undefined;
            }
            if (actualLoadedQuantity) {
              const findOrderLine = order.lines?.find(
                (line) => line.product?.id === product?.value
              );
              const findProduct = findOrderLine?.product;
              const quantityToUse =
                typeof quantity === 'string' ? parseFloat(quantity) : quantity;
              if (quantityToUse && findProduct) {
                const inRange = checkQuantityExceedsVariability({
                  quantity: quantityToUse,
                  actualQuantity:
                    typeof actualLoadedQuantity === 'string'
                      ? parseFloat(actualLoadedQuantity)
                      : actualLoadedQuantity,
                  variabilityThreshold: findProduct.variabilityThreshold,
                });
                if (!inRange) {
                  notify(
                    'warning',
                    `Actual quantity not in variability range ${findProduct.variabilityThreshold}%`
                  );
                  return;
                }
              } else if (product?.value && quantity) {
                const productResponse = await productApi.apiProductProductIdGet(
                  {productId: product?.value}
                );

                const inRange = checkQuantityExceedsVariability({
                  quantity:
                    typeof quantity === 'string'
                      ? parseFloat(quantity)
                      : quantity,
                  actualQuantity:
                    typeof actualLoadedQuantity === 'string'
                      ? parseFloat(actualLoadedQuantity)
                      : actualLoadedQuantity,
                  variabilityThreshold: productResponse.variabilityThreshold,
                });
                if (!inRange) {
                  notify(
                    'warning',
                    `Actual quantity not in variability range ${productResponse.variabilityThreshold}%`
                  );
                  return;
                }
              }
              //TODO
              // requestObj.actualLoadedQuantity =
              //   typeof actualLoadedQuantity === 'string'
              //     ? parseFloat(actualLoadedQuantity)
              //     : actualLoadedQuantity;
            }
            if (actualLoadedQuantity === '') {
              //TODO
              // requestObj.actualLoadedQuantity = undefined;
            }
            await orderLineApi.apiOrderLinePost({
              body: requestObj,
            });
            notify('success', 'Order Line Added');
            await loadOrder();
          }
        } catch (e) {
          notify('error', 'Failed to add order line');
        } finally {
          setSubmitting(false);
        }
      });
    },
    [loadOrder, notify, order?.id, order?.lines]
  );

  const handleDelete = React.useCallback(
    async (row: OrderLineType) => {
      setSubmitting(true);
      try {
        if (row.id) {
          await orderLineApi.apiOrderLineOrderLineIdDelete({
            orderLineId: row.id,
          });
          await loadOrder();
          notify('success', 'Order Line Deleted');
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to delete order line');
      } finally {
        setSubmitting(false);
      }
    },
    [loadOrder, notify]
  );

  const handleRefresh = React.useCallback(
    async () => await loadOrder(),
    [loadOrder]
  );

  const addValidation = Yup.object().shape({
    quantity: Yup.string().required('*required'),
  });

  const value: OrderLineContextProps = {
    list: order?.lines ?? [],
    currentPage: 1,
    addValidation,
    loading: submitting,
    loadList: loadOrder,
    onRefresh: handleRefresh,
    onDelete: RoleService.hasPermission('Delete OrderLine', 'Delete')
      ? handleDelete
      : undefined,
    onInlineAdd: RoleService.hasPermission('Add OrderLine', 'Add')
      ? handleInlineAdd
      : undefined,
    onInlineEdit: RoleService.hasPermission('Edit OrderLine', 'Edit')
      ? handleInlineEdit
      : undefined,
  };

  /**
   * Handles the accept action on handleEditConfirmation Function
   **/

  const handleAccept = React.useCallback(async () => {
    await handleEditConfirmation('accept');
  }, [handleEditConfirmation]);

  /**
   * Handles the decline action on handleEditConfirmation Function
   **/
  const handleDecline = React.useCallback(async () => {
    await handleEditConfirmation('decline');
  }, [handleEditConfirmation]);

  return (
    <OrderLineContext.Provider value={value}>
      <VantageDialog
        title={'Would you like to update the relevant capacity dimension'}
        ref={editCapacityDialogRef}
        onAccept={handleAccept}
        onDecline={handleDecline}
      />
      {children}
    </OrderLineContext.Provider>
  );
};
