import React from 'react';
import {ContactGroupContext} from './ContactGroupContext';
import {
  TemplateTable,
  TemplateTableConfig,
  TOnInlineAdd,
} from '../../factory/template';
import {Listing, ContractDump} from '@onroadvantage/onroadvantage-api';

export const ContactGroupContracts: React.FC = () => {
  const {contactGroup, loading, onUpdateContactGroup, hasPermission} =
    React.useContext(ContactGroupContext);

  const [config] = React.useState<TemplateTableConfig<ContractDump>>({
    columns: [
      {
        name: 'code',
        label: 'Contract Code',
        type: 'string',
        model: 'Contract',
        enableEditing: true,
        enableAutocomplete: true,
        enableMulti: true,
      },
      {
        name: 'name',
        label: 'Contract Name',
        type: 'string',
        enableEditing: false,
      },
    ],
    disableToolbar: true,
    disablePagination: true,
    deleteMessage: (row) => ({
      title: 'Contact Group Contract',
      items: {
        ContactGroupName: contactGroup?.name,
        ContractName: row.name,
        ContractCode: row.code,
      },
    }),
    identifier: 'CONTACT_GROUP_CONTRACTS_LIST',
  });

  const handleInlineAdd = React.useCallback<TOnInlineAdd>(
    (changes) => {
      if (contactGroup) {
        changes.forEach((change) => {
          const newContracts = change.code.map((item: Listing) => ({
            id: item.value,
          }));
          onUpdateContactGroup({
            name: contactGroup.name,
            contacts: contactGroup.contacts?.map(({id}) => ({
              id: id ?? undefined,
            })),
            contracts: [...(contactGroup.contracts ?? []), ...newContracts],
          });
        });
      }
    },
    [contactGroup, onUpdateContactGroup]
  );
  const handleInlineDelete = React.useCallback(
    (row: ContractDump) => {
      if (contactGroup) {
        onUpdateContactGroup({
          name: contactGroup.name,
          contacts: contactGroup.contacts?.map(({id}) => ({
            id: id ?? undefined,
          })),
          contracts: contactGroup.contracts
            ?.filter(({id}) => id !== row.id)
            ?.map(({id}) => ({id: id ?? undefined})),
        });
      }
    },
    [contactGroup, onUpdateContactGroup]
  );

  return (
    <TemplateTable
      config={config}
      list={contactGroup?.contracts ?? []}
      currentPage={1}
      loading={loading}
      onDelete={hasPermission?.edit ? handleInlineDelete : undefined}
      onInlineAdd={hasPermission?.edit ? handleInlineAdd : undefined}
    />
  );
};
