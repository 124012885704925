import React from 'react';
import CardContent from '@mui/material/CardContent';
import {useFormikContext} from 'formik';
import {
  ReportParameterDate,
  ReportParameterDateRange,
  ReportParameterDateTime,
  ReportParameterMultiSelect,
  ReportParameterCustomMultiSelect,
} from '../ReportScheduleForm/parameter';
import {ReportScheduleAddFormValues} from './ReportScheduleAdd';

export const ReportScheduleAddParameters: React.FC = () => {
  const {isSubmitting, values} =
    useFormikContext<ReportScheduleAddFormValues>();
  const content: React.ReactNode[] = [];

  values.parameters?.forEach((rp, index) => {
    switch (rp.type) {
      case 'arrint':
        content.push(
          <ReportParameterMultiSelect
            disabled={isSubmitting}
            id={`parameters[${index}].reportParameterId`}
            listName={rp.listName ?? ''}
            label={rp.description ?? 'parameter'}
            multiple={true}
            name={`parameters[${index}].value`}
            key={`parameters[${index}].reportParameterId`}
          />
        );
        break;
      case 'customlist':
        content.push(
          <ReportParameterCustomMultiSelect
            disabled={isSubmitting}
            id={`parameters[${index}].reportParameterId`}
            listValues={rp.listValues ?? []}
            label={rp.description ?? 'parameter'}
            multiple={true}
            name={`parameters[${index}].value`}
            key={`parameters[${index}].reportParameterId`}
          />
        );
        break;
      case 'date':
        content.push(
          <ReportParameterDate
            disabled={isSubmitting}
            id={`parameters[${index}].reportParameterId`}
            label={rp.description ?? ''}
            name={`parameters[${index}].value`}
            key={`parameters[${index}].reportParameterId`}
          />
        );
        break;
      case 'datetime':
        content.push(
          <ReportParameterDateTime
            disabled={isSubmitting}
            label={rp.description ?? ''}
            name={`parameters[${index}].value`}
            key={`parameters[${index}].reportParameterId`}
          />
        );
        break;
      case 'daterange':
        content.push(
          <ReportParameterDateRange
            disabled={isSubmitting}
            id={`parameters[${index}].reportParameterId`}
            label={rp.description ?? 'parameter'}
            name={`parameters[${index}].value`}
            key={`parameters[${index}].reportParameterId`}
          />
        );
        break;
    }
  });

  return (
    <CardContent style={{display: 'flex', flexDirection: 'column'}}>
      {content}
    </CardContent>
  );
};
