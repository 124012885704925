import React from 'react';
import {useContractContext} from '../contractContext';
import {TemplateCard, TemplateForm} from '../../../factory/template';
import {contractTripExecutionSchema} from '../contractContext';
import {ContractTripExecutionFormFields} from './contractFormFields';

export const ContractTripExecutionForm: React.FC = () => {
  const {
    loadingContract,
    tripExecutionInitialValues,
    onContractTripExecutionSubmit,
    submitting,
  } = useContractContext();

  return (
    <TemplateCard
      title="Contract Trip Execution Edit"
      loading={loadingContract}
    >
      <TemplateForm
        initialValues={tripExecutionInitialValues}
        onSubmit={onContractTripExecutionSubmit}
        validationSchema={contractTripExecutionSchema}
        loading={submitting}
        permission={{name: 'Edit Contract', type: 'Edit'}}
      >
        <ContractTripExecutionFormFields />
      </TemplateForm>
    </TemplateCard>
  );
};
