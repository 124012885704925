import React from 'react';
import {SnackbarOrigin} from '@mui/material';
import {useSnackbar} from 'notistack';

export type NotificationTypes = 'error' | 'info' | 'success' | 'warning' | null;

export interface INotification {
  key: string;
  type: NotificationTypes;
  message: string;
  autoHideDuration?: number;
  anchorOrigin?: SnackbarOrigin;
}

type AppNotificationsContextProps = (
  type: NotificationTypes,
  message: string | null,
  overrides?: Partial<INotification>
) => void;

export const AppNotificationsContext =
  React.createContext<AppNotificationsContextProps>(() => null);

export const useAppNotifications = () =>
  React.useContext(AppNotificationsContext);

export const AppNotificationsContextProvider: React.FC = ({children}) => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const handleEnqueueSnackbar = React.useCallback<AppNotificationsContextProps>(
    (type, message, overrides) => {
      const options: INotification = {
        key: `${type?.toUpperCase()}: ${message}}`,
        message: overrides?.message ?? message ?? 'Success',
        type: overrides?.type ?? type,
        autoHideDuration: overrides?.autoHideDuration ?? 3500,
        anchorOrigin: overrides?.anchorOrigin ?? {
          vertical: 'bottom',
          horizontal: 'right',
        },
      };
      enqueueSnackbar(options.message, {
        autoHideDuration: options.autoHideDuration,
        anchorOrigin: options.anchorOrigin,
        variant: options.type ?? 'success',
        key: options.key,
        'aria-label': options.type + ' ' + options.message,
      });
    },
    [enqueueSnackbar]
  );

  React.useEffect(() => closeSnackbar, [closeSnackbar]);

  return (
    <AppNotificationsContext.Provider value={handleEnqueueSnackbar}>
      {children}
    </AppNotificationsContext.Provider>
  );
};
