import React from 'react';
import {useFormikContext} from 'formik';
import {Divider, IconButton, Stack, Typography} from '@mui/material';
import {Launch} from '@mui/icons-material';
import {IContractSetupForm, useContractContext} from '../../contractContext';
import {splitByCapitals} from '../../../../factory/template';
import {
  ContractSetupSummaryDetails,
  ContractSetupSummaryDetailsProps,
} from './ContractSetupSummaryDetails';

export const ContractSetupSummary: React.FC = () => {
  const {onGoToStep, settingUpContractTaskTemplateList} = useContractContext();
  const {values} = useFormikContext<IContractSetupForm>();

  const summaryDetails: {
    [key: string]: ContractSetupSummaryDetailsProps['details'];
  } = React.useMemo(() => {
    const taskTemplateBySiteType: ContractSetupSummaryDetailsProps['details'] =
      {};

    settingUpContractTaskTemplateList.forEach(
      ({taskTemplate, nodeTypes, sequence}) => {
        taskTemplateBySiteType[`taskTemplate (${sequence})`] = {
          value: taskTemplate?.label,
          tooltip: nodeTypes?.map(({label}) => label).join(', '),
        };
      }
    );

    return {
      Details: {
        name: {value: values.name},
        code: {value: values.code},
        node: {value: values.node?.label},
        telematicsConfig: {value: values.telematicsConfig?.label},
        honorRestSchedule: {value: values.honorRestSchedule},
        driverScoringEnabled: {value: values.driverScoringEnabled},
      },
      TaskTemplateBySiteType: taskTemplateBySiteType,
      TripTimes: {
        shortBreakTrigger: {value: values.shortBreakTrigger},
        shortBreakDuration: {value: values.shortBreakDuration},
        longBreakTrigger: {value: values.longBreakTrigger},
        longBreakDuration: {value: values.longBreakDuration},
        maxShiftDrivingTime: {value: values.maxShiftDrivingTime},
        shiftDuration: {value: values.shiftDuration},
        dailyBreakDuration: {value: values.dailyBreakDuration},
        maxWeeklyDuty: {value: values.maxWeeklyDuty},
        weeklyBreak: {value: values.weeklyBreak},
        drivingBanStart: {value: values.drivingBanStart},
        drivingBanEnd: {value: values.drivingBanEnd},
        actualCalculationPostBuffer: {
          value: values.actualCalculationPostBuffer,
        },
        actualCalculationPreBuffer: {value: values.actualCalculationPreBuffer},
        targetHoursPerDay: {value: values.targetHoursPerDay},
        targetHoursPerVehiclePerDay: {
          value: values.targetHoursPerVehiclePerDay,
        },
        targetKmPerDay: {value: values.targetKmPerDay},
        targetKmPerVehiclePerDay: {value: values.targetKmPerVehiclePerDay},
        driverTripQueryPreToleranceHours: {
          value: values.driverTripQueryPreToleranceHours,
        },
        driverTripQueryPostToleranceHours: {
          value: values.driverTripQueryPostToleranceHours,
        },
      },
      TripExecution: {
        tripStopArrivalTimestampTaskTemplate: {
          value: values.tripStopArrivalTimestampTaskTemplate?.label,
        },
        tripStopDepartureTimestampTaskTemplate: {
          value: values.tripStopDepartureTimestampTaskTemplate?.label,
        },
        tripStopArrivalOdometerTaskTemplate: {
          value: values.tripStopArrivalOdometerTaskTemplate?.label,
        },
        tripStopDepartureOdometerTaskTemplate: {
          value: values.tripStopDepartureOdometerTaskTemplate?.label,
        },
        tripStopServiceStartTimestampTaskTemplate: {
          value: values.tripStopServiceStartTimestampTaskTemplate?.label,
        },
        tripStopServiceEndTimestampTaskTemplate: {
          value: values.tripStopServiceEndTimestampTaskTemplate?.label,
        },
        tripStopOdometerPriority: {
          value: values.tripStopOdometerPriority?.label,
        },
        tripStopTimestampPriority: {
          value: values.tripStopTimestampPriority?.label,
        },
        actualsStopMethodology: {value: values.actualsStopMethodology?.label},
        banOrderTripDoubleAllocation: {
          value: values.banOrderTripDoubleAllocation,
        },
        tripEndDepartureTaskBan: {value: values.tripEndDepartureTaskBan},
        tripStartArrivalTaskBan: {value: values.tripStartArrivalTaskBan},
        orderLineActualLoadedQuantityEnabled: {
          value: values.orderLineActualLoadedQuantityEnabled,
        },
      },
      TripNotifications: {
        emailTripsheet: {value: values.emailTripsheet},
        etaConfidence: {value: values.etaConfidence},
        voiceBroadcastEnabled: {value: values.voiceBroadcastEnabled},
        etaCalcEnabled: {value: values.etaCalcEnabled},
      },
      Inspection: {
        mandatoryDailyVehicleInspectionEnabled: {
          value: values.mandatoryDailyVehicleInspectionEnabled,
        },
        mandatoryDailyDriverInspectionEnabled: {
          value: values.mandatoryDailyDriverInspectionEnabled,
        },
        vehicleInspectionTaskTemplate: {
          value: values.vehicleInspectionTaskTemplate?.label,
        },
        driverInspectionTaskTemplate: {
          value: values.driverInspectionTaskTemplate?.label,
        },
      },
    };
  }, [settingUpContractTaskTemplateList, values]);

  return (
    <Stack spacing={2} divider={<Divider flexItem />}>
      {Object.keys(summaryDetails).map((key, index) => (
        <Stack key={key} spacing={2}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={0.5}
          >
            <Typography variant="h4">{splitByCapitals(key)}</Typography>
            <IconButton onClick={onGoToStep(index + 1)}>
              <Launch fontSize="small" />
            </IconButton>
          </Stack>
          <ContractSetupSummaryDetails details={summaryDetails[key]} />
        </Stack>
      ))}
    </Stack>
  );
};
