import React from 'react';
import {Button, Stack, Step, StepLabel, Stepper} from '@mui/material';
import {useFormikContext} from 'formik';
import {IContractSetupForm, useContractContext} from '../contractContext';
import {useContractSetupWizardTruckStyles} from './ContractSetupWizard.style';
import {findCommonElements} from '../../../factory/template';
import {FormikSubmit} from '../../formik';

export const ContractSetupWizard: React.FC = ({children}) => {
  const classes = useContractSetupWizardTruckStyles();
  const {
    activeStep,
    completedSteps,
    onBack,
    onGoToStep,
    onNext,
    onReset,
    stepOptions,
    steps,
    submitting,
  } = useContractContext();
  const {values, setTouched, validateForm} =
    useFormikContext<IContractSetupForm>();

  const handleValidateNext = React.useCallback(async () => {
    /**
     * Confirm whether the user has completed all the required fields for the current step before continuing to the
     * next step.
     * We also need to set the required formik fields as touched so that the user can see which fields are required for
     * the current step.
     */
    const activeStepOptions = stepOptions.find(
      ({title}) => steps.indexOf(title) + 1 === activeStep
    );

    if (
      !activeStepOptions ||
      !activeStepOptions.requiredFields ||
      activeStepOptions.requiredFields.length === 0
    ) {
      onNext();
      return;
    }

    const touchRequiredFields: {[key: string]: boolean} = {};

    activeStepOptions.requiredFields?.forEach((requiredField) => {
      touchRequiredFields[requiredField] = true;
    });

    let stepHasErrors = false;

    const isValid = await validateForm(values);

    if (
      Object.keys(isValid).length > 0 &&
      findCommonElements(Object.keys(isValid), activeStepOptions.requiredFields)
    ) {
      stepHasErrors = true;
    }

    if (stepHasErrors) {
      setTouched(touchRequiredFields);
    } else {
      onNext();
    }
  }, [
    activeStep,
    onNext,
    setTouched,
    stepOptions,
    steps,
    validateForm,
    values,
  ]);

  return (
    <Stack p={2} spacing={2}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step
            completed={completedSteps.includes(index + 1)}
            key={label}
            active={activeStep === index + 1}
            onClick={onGoToStep(index + 1)}
          >
            <StepLabel
              classes={{
                completed: classes.completedStep,
                label: classes.incompleteStep,
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      {children}
      <Stack direction="row">
        {activeStep === steps.length ? (
          <>
            <Button sx={{mr: 1}} variant="outlined" onClick={onReset}>
              Reset
            </Button>
            <FormikSubmit
              permission={{name: 'Add Contract', type: 'Add'}}
              loading={submitting}
            />
          </>
        ) : (
          <>
            {activeStep > 1 ? (
              <Button onClick={onBack} sx={{mr: 1}} variant="outlined">
                Back
              </Button>
            ) : null}
            <Button onClick={handleValidateNext} variant="contained">
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </>
        )}
      </Stack>
    </Stack>
  );
};
