import {useCallback, useRef, useEffect} from 'react';
import {PDFDocument} from 'pdf-lib';
import {useAppNotifications} from '../../../contexts';
import {Driver, DriverDump} from '@onroadvantage/onroadvantage-api';

export const useDownloadQRAsPDF = (
  userKey: string,
  driver: Driver | DriverDump | undefined
) => {
  const qrRef = useRef<HTMLDivElement>(null);
  const notify = useAppNotifications();

  useEffect(() => {
    if (userKey && qrRef.current) {
      // The canvas already has the QR rendered, so no need to regenerate it.
    }
  }, [userKey]);

  const downloadQRasPDF = useCallback(
    async (_params: {
      // unused param for future use
      userKey: string;
      driver: Driver | DriverDump | undefined;
    }) => {
      try {
        const canvas = qrRef.current?.querySelector('canvas');
        if (!canvas) return;

        const pdfDoc = await PDFDocument.create();
        const page = pdfDoc.addPage([595.28, 841.89]); // A4 size
        const qrImage = await pdfDoc.embedPng(canvas.toDataURL('image/png'));
        const fontSize = 20;
        const titleFontSize = 30;

        // Heading
        const heading = 'Driver Details';
        page.drawText(heading, {
          x: (595.28 - (titleFontSize * heading.length) / 2) / 2, // Attempt to center the heading
          y: 800,
          size: titleFontSize,
        });

        // Driver Name
        page.drawText('Driver Name:', {
          x: 50,
          y: 740,
          size: fontSize,
        });
        const driverName = driver?.firstname ?? '';
        page.drawText(driverName, {
          x: 200, // Adjust this value to position the name properly
          y: 740,
          size: fontSize,
        });

        // Driver Tag
        page.drawText('Driver Tag:', {
          x: 50,
          y: 710, // adjust this y value to be a bit below the driver name
          size: fontSize,
        });
        const driverTag = driver?.externalExtendedId ?? '';
        page.drawText(driverTag, {
          x: 200, // Adjust this value to position the tag properly
          y: 710,
          size: fontSize,
        });

        // Employee Number
        page.drawText('Employee Num:', {
          x: 50,
          y: 680, // Adjust this y value to position driver tag
          size: fontSize,
        });
        const employeeNumber = driver?.externalEmployeeNumber ?? '';
        page.drawText(employeeNumber, {
          x: 200, // Adjust this value to position the employee number properly
          y: 680,
          size: fontSize,
        });

        // Driver QR
        page.drawText('Driver QR:', {
          x: 50,
          y: 640, // Adjust this y value to position QR code properly
          size: fontSize,
        });
        page.drawImage(qrImage, {x: 50, y: 360, width: 256, height: 256}); // Adjust position and size as needed

        const pdfBytes = await pdfDoc.save();
        const blob = new Blob([pdfBytes], {type: 'application/pdf'});
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${driver?.externalExtendedId}.pdf`;
        link.click();
        notify('success', 'QR Downloaded Successfully');
      } catch (error) {
        notify('error', 'Failed to download QR Code as PDF');
      }
    },
    [
      driver?.externalExtendedId,
      driver?.externalEmployeeNumber,
      driver?.firstname,
      notify,
    ]
  );

  return {downloadQRasPDF, qrRef};
};
