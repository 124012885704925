import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

export const PlanningBoardTripOrdersStyles = (theme: Theme) =>
  createStyles({
    loaderContainer: {textAlign: 'center', margin: theme.spacing(1)},
    accordionSummaryContent: {alignItems: 'center'},
    accordionSummaryContentRemove: {marginRight: theme.spacing(0.5)},
    list: {flex: 1.3},
    listItem: {padding: 0},
    listItemText: {whiteSpace: 'pre'},
    routeLoaderContainer: {display: 'flex', alignItems: 'center'},
    routeLoaderText: {
      display: 'flex',
      padding: 0,
      paddingLeft: theme.spacing(0.8),
    },
  });

export const usePlanningBoardTripOrdersStyles = makeStyles(
  PlanningBoardTripOrdersStyles,
  {name: 'PlanningBoardTripOrdersStyles'}
);
