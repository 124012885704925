import {makeStyles, createStyles} from '@mui/styles';
import {Theme} from '@mui/material/styles';

const TableEditFieldStyles = (theme: Theme) =>
  createStyles({
    root: {paddingTop: 3.33, paddingBottom: 3.32},
    timeIcon: {
      position: 'absolute',
      right: theme.spacing(2),
      pointerEvents: 'none',
    },
  });

export const useTableEditFieldStyles = makeStyles(TableEditFieldStyles, {
  name: 'TableEditFieldStyles',
});
