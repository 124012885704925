import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

const RecipientOrderDetailsStyles = (theme: Theme) =>
  createStyles({
    buttonRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
      marginTop: '0',
      '& p': {
        textTransform: 'none',
        margin: 0,
        width: 'fit-content',
      },
    },
    label: {
      width: 100,
      marginRight: '1.5rem',
      color: theme.palette.text.secondary,
    },
    row: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
      marginTop: '1rem',
    },
    rowLine: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
      margin: '0.5rem',
      whiteSpace: 'nowrap',
      '&:first-child': {
        margin: '0.3rem 0.5rem',
      },
    },
    value: {whiteSpace: 'nowrap'},
  });

export const useRecipientOrderDetailsStyles = makeStyles(
  RecipientOrderDetailsStyles,
  {name: 'RecipientOrderDetailsStyles'}
);
