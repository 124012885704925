export const defaultTemplate = `<!DOCTYPE html>
<html lang="en">
    <head>
        <meta charset="UTF-8">
        <title>Unitrans ePOD Base Template</title>
        <style>
            .super {
                display: flex;
                flex-direction: column;
                margin-top: 0;
                padding-top:0;
                line-height: normal;
            }
            body {
                font-family: "Arial", sans-serif;
                font-weight: normal;
                max-width: 25.7cm;
                width: 25.7cm;
                display: flex;
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .header {
                margin: 0;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
            header {
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                justify-content: space-between;
            }
            table {
                position: relative;
                border: 1px solid white;
                border-collapse: collapse;
                vertical-align: top;
                text-align: left;
            }
            .Table th {
                color: grey;
                border: 1px solid grey;
                padding: 0 5pt 0 5pt;
            }
            .Table td {
                border: 1px solid grey;
                padding: 5pt 5pt 5pt 5pt;
                height: 15pt;
            }
            #table1 {
                display: flex;
                flex-direction: row;
            }
            #table1 table, #table3 table {
                border-top: 0 solid white;
                border-left: 0 solid white;
                border-right: 0 solid white;
            }
            #table1 td {
                border-top: 1px solid white;
                border-left: 0 solid white;
                border-right: 0 solid white;
            }
            #table1 th {
                border-right: 0 solid white;
                border-left: 0 solid white;
                border-top: 1px solid white;
            }
            #table1 table, #table2 table, #table3 table {
                max-width: 25.7cm;
                width: 25.7cm;
            }
            #table2 th {
                color: black;
                background-color: lightgray;
            }
            #table2 td {
                border: 1px solid grey;
            }
            #num{
                text-align: right;
            }
            #table3 td {
                border-top: 1px solid white;
                border-left: 0 solid white;
                border-right: 0 solid white;
            }
            #table3 th {
                color: black;
                border-right: 0 solid white;
                border-left: 0 solid white;
                border-top: 0 solid white;
            }
             #table3 table{
                page-break-inside: avoid;
            }
            .header-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .header-container h1 {
                margin: 0;
            }
            #logo {
                max-width: 240px; /* adjust size as needed */
                margin-left: auto;
                padding: 10pt;
                height: 120pt;
                width: 240pt;
            }
            #image_key {
                width: 100%;
                max-height: 130mm;
                object-fit: contain;
            }
            #signature{
                display: block;
                width: 30pt;
                height: 30pt;
            }
            .supplier-info {
                display: flex;
                justify-content: space-between;
            }
            .delivery-details table {
                padding-top: 30px;
            }
            .wide-cell {
                width: 25%;
            }
            .widest-cell {
                width: 10%;
            }
            .small-cell {
                width: 0;
            }
            .attachment {
                page-break-before: always;
            }
            @page {
                size: A4 landscape;
            }
            @media print {
                }
        </style>
    </head>
        <!-- ************ Head Ends Here ************-->
    <body>
    <div class="super">
        <div class="page">
            <div class="header">
                <header class="header-container">
                    <h1>Delivery Note</h1>
                    <img id="logo" src=" https://kap.co.za/wp-content/uploads/2023/05/optix-logo-01.svg" alt="logo">
                </header>
                <div class="supplier-info">
                    <h2>
                        <span>
                            Supplier: {% if master_trip and master_trip.trip.contract
                            and master_trip.trip.contract.name %}
                                {{ master_trip.trip.contract.name }}
                            {% endif %}
                        </span>
                    </h2>
                    <h2>
                        <span>
                            Customer: {% if trip_stop and trip_stop.orders and
                            trip_stop.orders|length > 0 and
                            trip_stop.orders[0].customer and
                            trip_stop.orders[0].customer.name %}
                                {{ trip_stop.orders[0].customer.name}}
                            {% endif%}
                        </span>
                    </h2>
                </div>
            </div>
             <!-- table 1 section -->
            <div id="table1">
                <table class="Table">
                    <tbody>
                    <tr>
                        <th class="wide-cell">
                                Shipment Date:
                        </th>
                        <td>
                            <b>
                                {% if trip_stop and trip_stop.orders and
                                trip_stop.orders|length > 0 and
                                trip_stop.orders[0].deliver_by_datetime %}
                                    {{ trip_stop.orders[0].deliver_by_datetime.strftime('%Y-%m-%d') }}
                                {% endif %}
                            </b>
                        </td>
                        <th class="wide-cell">
                            Receipt No:
                        </th>
                        <td>
                            <b>
                                {% if trip_stop and trip_stop.orders and
                                trip_stop.orders|length > 0 and
                                trip_stop.orders[0].delivery_note_number %}
                                    {{ trip_stop.orders[0].delivery_note_number }}
                                {% endif %}
                            </b>
                        </td>
                    </tr>
                    <tr>
                        <th class="wide-cell">
                                Collection Address:
                        </th>
                        <td>
                            <b>
                                {% if trip_stop and trip_stop.orders and
                                trip_stop.orders|length > 0 and
                                trip_stop.orders[0].uplift_point.name %}
                                    {{ trip_stop.orders[0].uplift_point.name }}
                                {% endif %}
                            </b>
                        </td>
                        <th class="wide-cell">
                            Delivery Address:
                        </th>
                        <td>
                            <b>
                                {% if trip_stop and trip_stop.orders and
                                trip_stop.orders|length > 0 and
                                trip_stop.orders[0].offload_point.name %}
                                    {{ trip_stop.orders[0].offload_point.name }}
                                {% endif %}
                            </b>
                        </td>
                    </tr>
                    <tr>
                        <th class="wide-cell">
                            Trip Number:
                        </th>
                        <td>
                            <b>
                                {% if master_trip and master_trip.trip and master_trip.trip.trip_number %}
                                    {{ master_trip.trip.trip_number }}
                                {% endif %}
                            </b>
                        </td>
                        <th class="wide-cell">
                            Fleet Number:
                        </th>
                        <td>
                            <b>
                                {% if master_trip and master_trip.trip and master_trip.trip.vehicle
                                and master_trip.trip.vehicle.fleet_number %}
                                    {{ master_trip.trip.vehicle.fleet_number }}
                                {%endif%}
                            </b>
                        </td>
                    </tr>
                    <tr>
                        <th class="widest-cell">
                            Driver Name:
                        </th>
                        <td colspan="3">
                            <b>
                                {% if master_trip and master_trip.trip and
                                master_trip.trip.driver and master_trip.trip.driver.firstname %}
                                    {{ master_trip.trip.driver.firstname }}
                                {% endif %}
                            </b>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <br>
             <!-- table 2 section -->
            <div id="table2">
                <table class="Table">
                    <thead>
                    <tr>
                        <th>Order Number</th>
                        <th>Shipment Number</th>
                        <th>Product</th>
                        <th>Ordered Quantity</th>
                        <th>Loaded Quantity</th>
                        <th>Delivered Quantity</th>
                        <th>Comment</th>
                    </tr>
                    </thead>
                    <tbody>
                     {% for order in trip_stop.orders %} {% for order_line in order.lines %}
                    <tr>
                        <td>
                            {% if order and order.order_number %}
                                {{order.order_number}}
                            {% endif %}
                        </td>
                        <td>
                            {% if master_trip and master_trip.trip and master_trip.trip.reference_number %}
                                {{ master_trip.trip.reference_number }}
                            {% endif %}
                        </td>
                        <td>
                            {% if order_line and order_line.product_name %}
                                {{ order_line.product_name }}
                            {% endif %}
                        </td>
                        <td id="num">
                            {% if order_line and order_line.quantity %}
                                {{ '%0.2f'|format(order_line.quantity|int) }}
                            {% endif %}
                        </td>
                        <td class="num">
                            {% if order_line and order_line.actual_loaded_quantity %}
                                {{ '%0.2f'|format(order_line.actual_loaded_quantity|int) }}
                            {% endif %}
                        </td>
                        <td id="num">
                            {% if order_line and order_line.actual_quantity %}
                                {{ '%0.2f'|format(order_line.actual_quantity|int) }}
                            {% endif %}
                        </td>
                        <td>&nbsp;</td>
                    </tr>
                    {% endfor %}  {% endfor %}
                    </tbody>
                </table>
            </div>
             <!-- table 3 section -->
            <div id="table3">
                <table class="Table">
                    <thead>
                    <tr>
                        <td colspan="8">
                            <h2>
                                <span>Goods received in good order</span>
                            </h2>
                        </td>
                    </tr>
                    </thead>
                    <tbody>
                        {% set counter = 1 %}
                             {% for task in trip_stop.tasks %}
                                {% if task.template.name =='Receiver Name' %}
                                    <tr>
                                        <th class="small-cell">#</th>
                                        <td>{{ counter }}</td>
                                        <th>
                                            Name:
                                        </th>
                                        <td>
                                            <span>
                                                {% if task and task.template and task.template.name and task.template.name == 'Receiver Name' %}
                                                    {{ task.payload }}
                                                {% endif %}
                                            </span>
                                        </td>
                                    {% endif %} {% endfor %}
                                    {% set signature_task = trip_stop.tasks|selectattr('template.type.name', 'equalto', 'Signature')|first %}
                                    {% if signature_task %}
                                        <th>
                                            Signature:
                                        </th>
                                        <td>
                                            <img alt="" src="data:image/png;base64,
                                            {% if signature_task and signature_task.payload %} {{ signature_task.payload }} {% endif %} " id="signature">
                                        </td>
                                        <th>
                                            Date:
                                        </th>
                                        <td>
                                            <span>
                                                {% if signature_task and signature_task.event_date %}
                                                    {{ localize_time(signature_task.event_date).strftime('%Y-%m-%d %H:%M:%S') }}
                                                {% endif %}
                                            </span>
                                        </td>
                                    {% endif %}
                                    </tr>
                            {% set counter = counter + 1 %}
                    </tbody>
                </table>
            </div>
            </div>
            <br>
             <!-- file upload section -->
            {% for task in trip_stop.tasks %}
                {% if task.template.type.name in ['Photo', 'Document'] and images %}
                  {% for task_image_key in task.image_keys %}
                <div>
                    <br>
                    <div class="attachment page">
                        <h3>
                            {% if task and task.template and task.template.name %}
                                {{ task.template.name }}
                            {% endif %}
                        </h3>
                    </div>
                    <div id="image-attached">
                        <img id="image_key" alt="image" src="data:image/png;base64,{{ images[task_image_key] }}"/>
                    </div>
                </div>
            {% endfor %} {% endif %} {% endfor %}
        </div>
    </body>
</html>
`;
