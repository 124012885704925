import React from 'react';
import {
  ITemplateTabControllerTab,
  TemplateTabs,
} from '../../../../factory/template';
import {TripDebriefContext} from '../../TripDebriefContext';
import {TripDebriefSummaryStopCommentList} from './TripDebriefSummaryStopCommentList';
import {TripDebriefSummaryStopDocumentList} from './tripDebriefSummaryStopDocumentList';
import {TripDebriefSummaryStopOrderList} from './tripDebriefSummaryStopOrderList';
import {TripDebriefSummaryStopTaskList} from './tripDebriefSummaryStopTaskList';

export const TripDebriefSummaryStop: React.FC = () => {
  const {masterTripId, tripStopId, loading} =
    React.useContext(TripDebriefContext);

  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {title: 'Tasks', element: <TripDebriefSummaryStopTaskList />},
    {title: 'Orders', element: <TripDebriefSummaryStopOrderList />},
    {title: 'Comments', element: <TripDebriefSummaryStopCommentList />},
    {title: 'Documents', element: <TripDebriefSummaryStopDocumentList />},
  ]);

  const path = React.useMemo(
    () => `/tripdebrieflist/${masterTripId}/stop/${tripStopId}`,
    [masterTripId, tripStopId]
  );

  return <TemplateTabs tabs={tabs} path={path} loading={loading} />;
};
