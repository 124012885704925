import 'mobx-react/batchingForReactDom';

import React from 'react';
import ReactDOM from 'react-dom';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import {Provider} from 'mobx-react';

import {App} from './App';
import './index.css';
import AuthStore from './stores/AuthStore';
import {authStore} from './store';
import ConfigService from './service/ConfigService/ConfigService';
import {ThemeModeContextProvider, ThemeContextProvider} from './contexts';

const {NODE_ENV} = process.env;

if (NODE_ENV === 'development') {
  ReactDOM.render(
    <ThemeModeContextProvider>
      <ThemeContextProvider>
        <App />
      </ThemeContextProvider>
    </ThemeModeContextProvider>,
    document.getElementById('root')
  );
} else {
  const bugsnagClient = bugsnag({
    apiKey: 'd31c4cee3db65be93081207c28ead509',
    onError: (event: any) => authStore.setBugSnagUser(event),
    buildNumber: ConfigService.buildNumber,
    apiVersion: ConfigService.apiVersion,
    serverUrl: ConfigService.serverUri,
  });
  bugsnagClient.use(bugsnagReact, React);
  const ErrorBoundary = bugsnagClient.getPlugin('react');
  ReactDOM.render(
    <ErrorBoundary>
      <ThemeModeContextProvider>
        <ThemeContextProvider>
          <Provider AuthStore={AuthStore}>
            <App />
          </Provider>
        </ThemeContextProvider>
      </ThemeModeContextProvider>
    </ErrorBoundary>,
    document.getElementById('root')
  );
}
