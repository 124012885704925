import React from 'react';
import {Filter, Sorting} from '@devexpress/dx-react-grid';
import {
  MasterTripDebriefConfigCriticalEventTypeMinimal,
  MasterTripDebriefConfigMediaEventTypeMinimal,
  MasterTripDebriefConfigOperationalEventTypeMinimal,
} from '@onroadvantage/onroadvantage-api';

export type TDebriefConfigEventTypes =
  | MasterTripDebriefConfigCriticalEventTypeMinimal
  | MasterTripDebriefConfigOperationalEventTypeMinimal
  | MasterTripDebriefConfigMediaEventTypeMinimal;

export interface useTripDebriefConfigEventTypeListParams {
  eventTypeList: TDebriefConfigEventTypes[];
}

const initialSorting: Sorting = {
  columnName: 'name',
  direction: 'desc',
};

export const useTripDebriefConfigEventTypeList = ({
  eventTypeList,
}: useTripDebriefConfigEventTypeListParams) => {
  const [list, setList] = React.useState<TDebriefConfigEventTypes[]>(
    eventTypeList ?? []
  );
  const [filters, setFilters] = React.useState<Filter[]>();
  const [sorting, setSorting] = React.useState<Sorting[]>([initialSorting]);

  const handleColumnFilter = React.useCallback(
    (newFilters: Filter[]) => (eventType: TDebriefConfigEventTypes) =>
      !newFilters ||
      newFilters.length === 0 ||
      !newFilters
        .map((f) => {
          if (f.value) {
            const fValue = f.value.toLowerCase();
            if (
              f.columnName === 'externalReference' &&
              'externalReference' in eventType
            ) {
              return eventType.externalReference
                ?.toLowerCase()
                ?.includes(fValue);
            }
            if (f.columnName === 'name') {
              return eventType.name?.toLowerCase()?.includes(fValue);
            } else return true;
          } else return true;
        })
        .includes(false),
    []
  );

  const handleColumnSort = React.useCallback(
    (newSorting: Sorting[]) =>
      (a: TDebriefConfigEventTypes, b: TDebriefConfigEventTypes) => {
        const column = newSorting[0]?.columnName;
        const direction = newSorting[0]?.direction;

        if (!column || !direction) {
          return 0;
        }

        switch (column) {
          case 'externalReference': {
            if ('externalReference' in a && 'externalReference' in b) {
              if (!a.externalReference || !b.externalReference) {
                return 0;
              }
              if (a.externalReference > b.externalReference) {
                return direction === 'asc' ? -1 : 1;
              }
              return direction === 'asc' ? 1 : -1;
            }
            return 0;
          }
          case 'name': {
            if (!a.name || !b.name) {
              return 0;
            }
            if (a.name > b.name) {
              return direction === 'asc' ? -1 : 1;
            }
            return direction === 'asc' ? 1 : -1;
          }
          default:
            return 0;
        }
      },
    []
  );

  const handleFiltersChange = React.useCallback(
    (newFilters: Filter[]) => {
      setList(eventTypeList?.filter(handleColumnFilter(newFilters)) ?? []);
      setFilters(newFilters);
    },
    [handleColumnFilter, eventTypeList]
  );

  const handleSortingChange = React.useCallback(
    (newSorting: Sorting[]) => {
      setList((prevList) => prevList.sort(handleColumnSort(newSorting)));
      setSorting(newSorting);
    },
    [handleColumnSort]
  );

  // Set list and filter with selectedPlanningSkills or if there are any column filters
  React.useEffect(() => {
    setList(eventTypeList.sort(handleColumnSort([initialSorting])));
  }, [eventTypeList, handleColumnSort]);

  return {
    list,
    filters,
    sorting,
    onSortingChange: handleSortingChange,
    onFiltersChange: handleFiltersChange,
  };
};
