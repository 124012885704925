import React from 'react';
import _ from 'lodash';
import {
  ITemplateControllerRoute,
  TemplateController,
} from '../../factory/template';
import {DashboardContext, DashboardContextProvider} from './DashboardContext';
import {DashboardList} from './DashboardList';
import {DashboardIdHandler} from './DashboardIdHandler';
import {DashboardForm} from './DashboardForm';
import {Dashboard} from './Dashboard';

export const DashboardController: React.FC = () => {
  return (
    <DashboardContextProvider>
      <DashboardControllerComponent />
    </DashboardContextProvider>
  );
};

const DashboardControllerComponent: React.FC = () => {
  const {dashboard, loading} = React.useContext(DashboardContext);
  const routes: ITemplateControllerRoute[] = React.useMemo(
    () => [
      {title: 'Dashboard List', component: DashboardList},
      {
        path: '/add',
        title: 'New Dashboard',
        component: () => <DashboardForm isAdd />,
      },
      {
        path: '/:dashboardId',
        title: (params) =>
          loading
            ? 'Dashboard Loading...'
            : dashboard?.name ??
              `Dashboard ${_.get(params, 'dashboardId', 'Single')}`,
        component: Dashboard,
        IdHandler: DashboardIdHandler,
      },
    ],
    [loading, dashboard?.name]
  );
  return <TemplateController rootPath="/dashboardlist" routes={routes} />;
};
