import React from 'react';
import {ProductGroupContext} from './ProductGroupContext';
import {useParams} from 'react-router-dom';

interface IParams {
  productGroupId?: string;
}

export const ProductGroupIdHandler: React.FC = () => {
  const {setProductGroupId} = React.useContext(ProductGroupContext);

  const {productGroupId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = productGroupId ? parseInt(productGroupId) : undefined;
    setProductGroupId(parsedId);
    return () => setProductGroupId(undefined);
  }, [productGroupId, setProductGroupId]);

  return null;
};
