import React from 'react';
import {
  usePlanningBoardGantt,
  usePlanningBoardGanttResponse,
  usePlanningBoardGanttResponseInitial,
} from './usePlanningBoardGantt';
import {usePlanningBoardContext} from './PlanningBoardContext';
import {usePlanningBoardSettingsContext} from './PlanningBoardSettingsContext';

export type PlanningBoardGanttContextProps = usePlanningBoardGanttResponse;

export const PlanningBoardGanttContext =
  React.createContext<PlanningBoardGanttContextProps>(
    usePlanningBoardGanttResponseInitial
  );

export const PlanningBoardGanttContextProvider: React.FC = ({children}) => {
  const planningBoardContext = usePlanningBoardContext();
  const planningBoardSettingsContext = usePlanningBoardSettingsContext();

  const values = usePlanningBoardGantt({
    ...planningBoardContext,
    ...planningBoardSettingsContext,
  });

  return (
    <PlanningBoardGanttContext.Provider value={values}>
      {children}
    </PlanningBoardGanttContext.Provider>
  );
};

export const usePlanningBoardGanttContext = () =>
  React.useContext(PlanningBoardGanttContext);
