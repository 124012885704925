import React from 'react';
import {TemplateEditor} from '../../template/TemplateEditor';
import {useFormikContext} from 'formik';
import {FormHelperText, Stack, Typography} from '@mui/material';

interface FormikTemplateEditorProps {
  name: string;
  label: string;
  maxHeight?: string | number | undefined;
  minHeight?: string | number | undefined;
}

export const FormikTemplateEditor = ({
  name,
  label,
  maxHeight,
  minHeight,
}: FormikTemplateEditorProps) => {
  const {getFieldHelpers, getFieldMeta} = useFormikContext();
  const {setValue, setTouched} = getFieldHelpers(name);
  const {error, value} = getFieldMeta(name);

  const updateField = React.useCallback(
    (value: string) => {
      setValue(value);
      setTouched(true);
    },
    [setTouched, setValue]
  );

  return (
    <Stack my={1.5}>
      <Typography variant="subtitle1" component="legend" gutterBottom>
        {label}:
      </Typography>
      {error && <FormHelperText error>{error}</FormHelperText>}
      <TemplateEditor
        value={value as string}
        updateField={updateField}
        fieldName={name}
        maxHeight={maxHeight}
        minHeight={minHeight}
      />
    </Stack>
  );
};
