import {makeStyles, createStyles} from '@mui/styles';
import {Theme} from '@mui/material/styles';

const AdminTelematicsConfigScheduleItemStyles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      margin: theme.spacing(2),
      paddingBottom: theme.spacing(1),
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: theme.palette.divider,
      maxWidth: 800,
    },
    details: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      marginRight: theme.spacing(2),
    },
    title: {
      fontWeight: theme.typography.fontWeightBold,
    },
    description: {
      paddingTop: theme.spacing(1),
    },
    action: {},
  });

export const useAdminTelematicsConfigScheduleItemStyles = makeStyles(
  AdminTelematicsConfigScheduleItemStyles,
  {
    name: 'AdminTelematicsConfigScheduleItemStyles',
  }
);
