import React from 'react';
import {
  ReportSchedule as ReportScheduleType,
  ReportSchedulePost,
  ApiReportScheduleGetRequest,
  ReportSchedulePatch,
} from '@onroadvantage/onroadvantage-api';
import {Filter, Sorting} from '@devexpress/dx-react-grid';
import {reportScheduleApi} from '../../../api';
import _ from 'lodash';
import {unstable_batchedUpdates} from 'react-dom';
import {getStringFromSorting} from '../../../service/Util';
import {useHistory} from 'react-router-dom';
import {useAppNotificationStore} from '../../../stores/context/notifications';

export interface ReportScheduleContextProps {
  loading: boolean;
  reportId?: number;
  setReportId: (value: number | undefined) => void;
  reportScheduleId?: number;
  setReportScheduleId: (value: number | undefined) => void;

  // list
  list: ReportScheduleType[];
  listFilters: Filter[];
  listItemTotal: number;
  listPage: number;
  listPageSize: number;
  listPageTotal: number;
  listSorting: Sorting[];
  loadList: () => void;
  onListDelete: (value: any) => void;
  onListFilterChange: (filters: Filter[]) => void;
  onListPageChange: (value: number) => void;
  onListPageSizeChange: (value: number) => void;
  onListSortingChange: (sorting: Sorting[]) => void;

  // single
  singleLoading: boolean;
  reportSchedule?: ReportScheduleType;
  onSingleUpdate: ({body}: {body: ReportSchedulePatch}) => void;
  onSingleCreate: ({body}: {body: ReportSchedulePost}) => void;
}

export const ReportScheduleContext =
  React.createContext<ReportScheduleContextProps>({
    // defaults
    loading: false,
    setReportId: () => null,
    setReportScheduleId: () => null,

    // list
    list: [],
    listFilters: [],
    listItemTotal: 0,
    listPage: 1,
    listPageSize: 25,
    listPageTotal: 1,
    listSorting: [],
    loadList: () => null,
    onListDelete: () => null,
    onListFilterChange: () => null,
    onListPageChange: () => null,
    onListPageSizeChange: () => null,
    onListSortingChange: () => null,

    // list
    singleLoading: false,
    onSingleUpdate: () => null,
    onSingleCreate: () => null,
  });

export const ReportScheduleContextProvider: React.FC = ({children}) => {
  const history = useHistory();
  const appNotificationStore = useAppNotificationStore();
  // general
  const [reportId, setReportId] = React.useState<number | undefined>();

  // list
  const [list, setList] = React.useState<ReportScheduleType[]>([]);
  const [listFilters, setListFilters] = React.useState<Filter[]>([]);
  const [listLoading, setListLoading] = React.useState<boolean>(false);
  const [listPage, setListPage] = React.useState<number>(1);
  const [listPageSize, setListPageSize] = React.useState<number>(25);
  const [listPageTotal, setListPageTotal] = React.useState<number>(1);
  const [listItemTotal, setListItemTotal] = React.useState<number>(0);
  const [listSorting, setListSorting] = React.useState<Sorting[]>([]);

  // single
  const [reportScheduleId, setReportScheduleId] = React.useState<
    number | undefined
  >();
  const [reportSchedule, setReportSchedule] = React.useState<
    ReportScheduleType | undefined
  >();
  const [singleLoading, setSingleLoading] = React.useState<boolean>(false);

  const loadList = React.useCallback(async () => {
    setListLoading(true);
    try {
      const requestObj: ApiReportScheduleGetRequest = {
        page: listPage,
        perPage: listPageSize,
        reportId,
      };

      // filters
      listFilters.forEach((f) => {
        // TODO find a more effective way to handle filters
        switch (f.columnName) {
          case 'name':
            requestObj.name = f.value;
            break;
          case 'description':
            requestObj.description = f.value;
            break;
          case 'type':
            requestObj.type = f.value;
            break;
        }
      });

      // sorting
      const orderBy = getStringFromSorting(listSorting);
      if (orderBy && orderBy !== '') {
        requestObj.orderBy = orderBy;
      }

      const response = await reportScheduleApi.apiReportScheduleGet(requestObj);
      if (response && response.items) {
        setList(response.items);
        setListPageTotal(response.pages ?? 1);
        setListItemTotal(response.total ?? 0);
      } else {
        // TODO handle
      }
    } finally {
      setListLoading(false);
    }
  }, [
    listFilters,
    listPage,
    listPageSize,
    listSorting,
    reportId,
    setList,
    setListLoading,
    setListItemTotal,
  ]);

  const loadSchedule = React.useCallback(async () => {
    setSingleLoading(true);
    try {
      if (reportScheduleId) {
        const response =
          await reportScheduleApi.apiReportScheduleReportScheduleIdGet({
            reportScheduleId,
          });
        setReportSchedule(response);
      } else {
        setReportSchedule(undefined);
      }
    } finally {
      setSingleLoading(false);
    }
  }, [reportScheduleId, setReportSchedule, setSingleLoading]);

  const handleListDelete = async (row: any) => {
    setListLoading(true);
    try {
      await reportScheduleApi.apiReportScheduleReportScheduleIdDelete({
        reportScheduleId: row.id,
      });
      await loadList();
      appNotificationStore.enqueueNotification('success', 'Report Deleted');
    } catch (e) {
      appNotificationStore.enqueueNotification('error', e.message);
    } finally {
      setListLoading(false);
    }
  };

  const updateSchedule = React.useCallback(
    async ({body}: {body: ReportSchedulePatch}) => {
      setSingleLoading(true);
      try {
        if (reportScheduleId) {
          const response =
            await reportScheduleApi.apiReportScheduleReportScheduleIdPatch({
              reportScheduleId,
              body: body,
            });
          setReportSchedule(response);
          appNotificationStore.enqueueNotification(
            'success',
            'Report Schedule Updated'
          );
        } else {
          setReportSchedule(undefined);
        }
      } finally {
        setSingleLoading(false);
      }
    },
    [appNotificationStore, reportScheduleId]
  );

  const createSchedule = React.useCallback(
    async ({body}: {body: ReportSchedulePost}) => {
      setSingleLoading(true);
      try {
        await reportScheduleApi.apiReportSchedulePost({
          body,
        });

        appNotificationStore.enqueueNotification(
          'success',
          'Report Schedule Created'
        );
        history.push(`/reportlist/${reportId}?tab=2`);
      } finally {
        setSingleLoading(false);
      }
    },
    [appNotificationStore, setSingleLoading, history, reportId]
  );

  const handleFilterChange = _.debounce((filters: Filter[]) => {
    unstable_batchedUpdates(() => {
      setListPage(1);
      setListFilters(filters);
    });
  }, 500);

  const handleListPageChange = (value: number) => {
    let newPage = value;

    if (value < 0) {
      // lower bound check
      newPage = 0;
    } else if (value > listPageTotal) {
      // upper bound check
      newPage = listPageTotal;
    }

    setListPage(newPage + 1);
  };

  const handleListPageSizeChange = (value: number) => {
    setListPage(1);
    setListPageSize(value);
  };

  const handleListSortingChange = _.debounce((sorting: Sorting[]) => {
    unstable_batchedUpdates(() => {
      setListPage(1);
      setListSorting(sorting);
    });
  }, 500);

  const value = {
    // general
    loading: listLoading || singleLoading,
    reportId,
    setReportId,

    // list
    list,
    listFilters,
    listItemTotal,
    listPage,
    listPageSize,
    listPageTotal,
    listSorting,
    loadList,
    onListDelete: handleListDelete,
    onListFilterChange: handleFilterChange,
    onListPageChange: handleListPageChange,
    onListPageSizeChange: handleListPageSizeChange,
    onListSortingChange: handleListSortingChange,

    // single
    reportSchedule,
    reportScheduleId,
    setReportScheduleId,
    onSingleUpdate: updateSchedule,
    onSingleCreate: createSchedule,
    singleLoading,
  };

  React.useEffect(() => {
    loadSchedule();
  }, [loadSchedule]);

  return (
    <ReportScheduleContext.Provider value={value}>
      {children}
    </ReportScheduleContext.Provider>
  );
};
