import React from 'react';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
import {grey} from '@mui/material/colors';

import {ThemeModeContext} from './ThemeModeContext';
import CssBaseline from '@mui/material/CssBaseline';

export const ThemeContextProvider: React.FC = ({children}) => {
  const {themeMode} = React.useContext(ThemeModeContext);

  const theme = createTheme({
    spacing: 10,
    typography: {
      fontFamily: 'Roboto, sans-serif',
      fontSize: 13,
      h4: {fontSize: '20px', fontWeight: 700},
      h5: {
        //eventcard mini heading
        fontSize: '14px',
        fontWeight: 700,
      },
      h6: {
        // eventcard base
        fontSize: '16px',
        fontWeight: 400,
      },
    },
    palette: {
      mode: themeMode,
      ...(themeMode === 'light'
        ? {
            primary: {main: '#008270', light: 'rgba(0,130,112,0.72)'},
            secondary: {main: '#00b7a3', contrastText: grey[300]},
            warning: {main: '#F57C00'},
          }
        : {
            primary: {main: '#008270', light: 'rgba(0,130,112,0.72)'},
            secondary: {main: '#00b7a3', contrastText: grey[300]},
            warning: {main: '#F57C00'},
            success: {main: '#008270'},
            text: {
              primary: '#fff',
              secondary: grey[500],
            },
            background: {paper: '#121212', default: '#121212'},
          }),
    },
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
