import React from 'react';
import {OrderContext} from './OrderContext';
import {useParams} from 'react-router-dom';

interface IParams {
  orderId?: string;
}

export const OrderIdHandler: React.FC = () => {
  const {setOrderId} = React.useContext(OrderContext);

  const {orderId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = orderId ? parseInt(orderId) : undefined;
    setOrderId(parsedId);

    return () => {
      setOrderId(undefined);
    };
  }, [orderId, setOrderId]);

  return null;
};
