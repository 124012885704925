import React from 'react';
import {OrderDump, OrderLine} from '@onroadvantage/onroadvantage-api';
import {TemplateTable, TemplateTableConfig} from '../../../../factory/template';
import {useTripStopContext} from '../tripStopContext';

interface TripStopOrderLineListProps {
  row: OrderDump;
}

export const TripStopOrderLineList: React.FC<TripStopOrderLineListProps> = ({
  row,
}) => {
  const {loadingTripStop, onEditOrderLine} = useTripStopContext();

  const [config] = React.useState<TemplateTableConfig<OrderLine>>({
    columns: [
      {
        name: 'product',
        label: 'Product Name',
        type: 'string',
        model: 'Product',
        enableAutocomplete: true,
        enableEditing: false,
        getValue: ({productName, product}) => productName ?? product?.name,
      },
      {
        name: 'description',
        label: 'Description',
        type: 'string',
        enableEditing: false,
      },
      {
        name: 'quantity',
        label: 'Planned Quantity',
        type: 'string',
        enableEditing: false,
      },
      {
        name: 'actualQuantity',
        label: 'Actual Quantity',
        type: 'string',
        enableEditing: true,
      },
    ],
    disableToolbar: true,
    disablePagination: true,
    enableGrouping: false,
    identifier: 'TRIP_STOP_ORDER_LINE_LIST',
  });

  const list = React.useMemo(() => row.lines ?? [], [row.lines]);

  return (
    <TemplateTable
      config={config}
      list={list}
      elevation={0}
      currentPage={1}
      onInlineEdit={onEditOrderLine}
      loading={loadingTripStop}
      nestedLevel={2}
    />
  );
};
