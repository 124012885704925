import React from 'react';
import {connect, FormikProps} from 'formik';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {Theme} from '@mui/material';
import {WithStyles} from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import _ from 'lodash';
import clone from 'rfdc';
import queryString from 'query-string';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {
  Listing,
  Node as NodeType,
  PlanningMasterTrip,
  PlanningMasterTripTripStop,
  PlanningOrder as PlanningOrderType,
} from '@onroadvantage/onroadvantage-api';
import {VantageBoard} from '../../board';
import {usePlanningSolutionData} from '../../../stores/context';
import {
  planningOrderListingToLaneData,
  TGetCardMoveHandlerResponse,
} from '../../../service/laneUtils';
import {
  IStop,
  PlanningSolutionFormValues,
} from '../planningSolution/PlanningSolutionForm';
import PlanningTripRoute from './PlanningTripRoute';
import PlanningTripGantt from './PlanningTripGantt';
import PlanningTripEdit from './PlanningTripEdit';
import PlanningMasterTripMapCard from '../../map/PlanningMasterTripMapCard';
import PlanningSolutionOverviewCard from '../../map/PlanningSolutionOverviewCard';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      overflow: 'auto',
      backgroundColor: 'rgb(250, 250, 250)',
    },
    header: {
      display: 'flex',
      flowDirection: 'row',
      justifyContent: 'space-between',
      paddingRight: theme.spacing(3),
      borderBottomColor: theme.palette.divider,
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
    },
    tabs: {},
    details: {
      borderBottomColor: theme.palette.divider,
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
    },
    planning: {
      display: 'flex',
      width: 'fit-content',
      backgroundColor: 'rgb(250, 250, 250)',
    },
    planningMap: {
      display: 'flex',
      width: 'auto',
      backgroundColor: 'rgb(250, 250, 250)',
    },

    item: {
      marginLeft: theme.spacing(2),
    },
    tripStops: {
      marginTop: 65,
    },
  });

interface IFormikProps {
  formik: FormikProps<PlanningSolutionFormValues>;
}

interface IParams {
  solutionId: string;
  tripId: string;
}

type IProps = WithStyles<typeof styles>;

export const PlanningTripOrdersComponent: React.FC<
  IProps & RouteComponentProps<IParams> & IFormikProps
> = ({classes, formik, match, location}) => {
  const {ordersPlanningFull, rawOrderListings} = usePlanningSolutionData(
    (store) => ({
      ordersPlanningFull: store.planningOrders,
      rawOrderListings: store.rawOrderListings,
    })
  );

  // get trip
  const tripId = +match.params.tripId;
  const {tempId} = queryString.parse(location.search);

  const tripIndex = (formik.values.trips || []).findIndex((t) => {
    if (tripId === -1 && tempId) {
      return t.id === tripId && t.tempId === tempId;
    }
    return t.id === tripId;
  });

  const trip = (formik.values.trips || [])[tripIndex];

  const {planningSolution} = usePlanningSolutionData((store) => ({
    planningSolution: store.planningSolution,
  }));

  // @ts-expect-error upgrade
  const planningMasterTrip = _.find(planningSolution.planningMasterTrips, [
    'trip.id',
    trip.id,
  ]);

  // get assigned planning order ids
  const assignedPlanningOrderIds: Array<number> = _.get(trip, 'orders', []);

  // get full planning orders
  // assumption: there will never be an id of 0
  const assignedPlanningOrdersFull = ordersPlanningFull.filter((o) => {
    return assignedPlanningOrderIds.indexOf(o.id || 0) !== -1;
  });

  const planningOrderIds = rawOrderListings.map((po: Listing) => po.value);
  const planningOrders = ordersPlanningFull.filter((o) =>
    planningOrderIds.includes(o.id)
  );

  // get assigned planning order listings
  const assignedPlanningOrderListings = planningOrders
    .filter((l) => {
      // assumption: there will never be an id of 0
      return assignedPlanningOrderIds.indexOf(l.id || 0) !== -1;
    })
    .sort((f, l) => {
      if (f.id && l.id) {
        return f.id < l.id ? -1 : 1;
      }
      return 0;
    });

  // get unassigned planning planningOrders
  const unassignedPlanningOrderListings = planningOrders
    .filter((l) => {
      // assumption: there will never be an value of 0
      return (formik.values.unallocatedOrders || []).indexOf(l.id || 0) !== -1;
    })
    .sort((f, l) => {
      if (f.id && l.id) {
        return f.id < l.id ? -1 : 1;
      }
      return 0;
    });

  const orderBoardData = {
    lanes: [
      {
        id: 'unassigned',
        title: `Unassigned (${unassignedPlanningOrderListings.length})`,
        cards: planningOrderListingToLaneData(unassignedPlanningOrderListings),
      },
      {
        id: 'assigned',
        title: `Assigned (${assignedPlanningOrderListings.length})`,
        cards: planningOrderListingToLaneData(assignedPlanningOrderListings),
      },
    ],
  };

  const getPlanningMasterTripIStops = (
    planningMasterTrip: PlanningMasterTrip
  ) => {
    const stops: Array<IStop> = [];
    if (planningMasterTrip.trip && planningMasterTrip.trip.stops) {
      planningMasterTrip.trip.stops.forEach(
        (ts: PlanningMasterTripTripStop) => {
          if (ts.node && ts.orders) {
            const iStop = {
              id: ts.id,
              nodeId: ts.node.id,
              sequence: ts.sequence,
              arrivalTime: ts.arrivalTime,
              departureTime: ts.departureTime,
              nodeExternalReference: ts.node.externalReference,
              nodeName: ts.node.name,
              orderIds: ts.orders.map((o) => {
                return o.id;
              }),
            };
            // @ts-expect-error upgrade
            stops.push(iStop);
          }
        }
      );
    }

    return stops;
  };

  // get trip stops from assigned planningOrders
  const allStops: Array<IStop> = [];
  const stopsLoading: Array<NodeType> = [];
  const stopsUnloading: Array<NodeType> = [];

  assignedPlanningOrdersFull.forEach((o: PlanningOrderType) => {
    const up = _.get(o, 'order.upliftPoint');
    const off = _.get(o, 'order.offloadPoint');

    // check if stop already in list
    const checkExists = (id: number, list: Array<NodeType>) =>
      list.findIndex((n) => {
        return (n.id || 0) === id;
      });

    if (up && checkExists(up.id, stopsLoading) === -1) {
      stopsLoading.push(up);
    }

    if (off && checkExists(off.id, stopsUnloading) === -1) {
      stopsUnloading.push(off);
    }
  });

  if (trip && trip.stops && trip.stops.length > 0) {
    trip.stops.forEach((tripStop: IStop) => {
      if (tripStop) {
        allStops.push(tripStop);
      }
    });
  } else {
    if (planningMasterTrip) {
      const stops = getPlanningMasterTripIStops(planningMasterTrip);
      stops.forEach((stop: IStop) => {
        allStops.push(stop);
      });
    }
  }

  /** Moves an order from one lane to another */
  const handleCardMove: TGetCardMoveHandlerResponse = (
    fromLaneId,
    toLaneId,
    cardId,
    index
  ) => {
    if (!trip) {
      return;
    }

    let assigned = clone()(trip.orders);
    let unassigned = clone()(formik.values.unallocatedOrders);

    if (fromLaneId !== toLaneId) {
      const orderId = parseInt(cardId, 10);
      switch (toLaneId) {
        case 'assigned':
          assigned.splice(index, 0, orderId);
          unassigned = (unassigned || []).filter((c) => c !== orderId);
          break;
        case 'unassigned':
          assigned = assigned.filter((c) => c !== orderId);
          (unassigned || []).splice(index, 0, orderId);
          break;
        default:
          throw new Error('should not get here ever');
      }

      /* as the bug with deep assignment and typescript the whole trips
       * object has to be cloned
       * https://github.com/formik/formik/issues/1948
       */
      const trips = clone()(formik.values.trips || []);
      trip.orders = assigned;
      // TODO set new stops based on orders that are allocated. This will require us to do live route planning

      trips[tripIndex] = trip;

      formik.setFieldValue('trips', trips);
      formik.setFieldValue('unallocatedOrders', unassigned);
    }

    if (fromLaneId === toLaneId && fromLaneId === 'stops') {
      // Re-sequencing happening
      let stops = clone()(trip.stops);
      if (!stops && planningMasterTrip) {
        stops = getPlanningMasterTripIStops(planningMasterTrip);
      }
      // Do re-sequence
      stops = _.orderBy(stops, ['sequence'], ['asc']);
      const intCardId = parseInt(cardId, 10);
      const preMoveStop = stops.find((stop: IStop) => {
        return stop.id === intCardId;
      });
      const cardSequence = index + 1; // cards start at 0, sequence at 1
      const returnStops: Array<IStop> = [];
      if (preMoveStop) {
        const preMoveSequence = preMoveStop.sequence;
        stops.forEach((stop: IStop) => {
          if (stop.id === intCardId) {
            stop.sequence = cardSequence;
          }
          if (preMoveSequence - cardSequence < 0) {
            // moving down
            if (
              stop.sequence > preMoveSequence &&
              stop.sequence < cardSequence + 1
            ) {
              if (stop.id === intCardId) {
                stop.sequence = cardSequence;
              } else {
                // console.log('It moves down, we move up', stop.id);
                stop.sequence = stop.sequence - 1;
              }
            }
          } else {
            // moving up
            if (
              stop.sequence < preMoveSequence &&
              stop.sequence > cardSequence - 1
            ) {
              if (stop.id === intCardId) {
                stop.sequence = cardSequence;
              } else {
                // console.log('It moves up, we move down', stop.id);
                stop.sequence = stop.sequence + 1;
              }
            }
          }
          returnStops.push(stop);
        });
      }

      const trips = clone()(formik.values.trips || []);
      trip.stops = returnStops;
      trips[tripIndex] = trip;
      formik.setFieldValue('trips', trips);
    }
  };

  const handleAddAll = () => {
    if (!trip) {
      return;
    }

    const assigned = [
      ...clone()(formik.values.unallocatedOrders || []),
      ...clone()(trip.orders),
    ];

    /* as the bug with deep assignment and typescript the whole trips
     * object has to be cloned
     * https://github.com/formik/formik/issues/1948
     */
    const trips = clone()(formik.values.trips || []);
    trip.orders = assigned;
    // const tripIndex = trips.findIndex((t) => t.id == trip.id);
    trips[tripIndex] = trip;

    formik.setFieldValue('trips', trips);
    formik.setFieldValue('unallocatedOrders', []);
  };

  const handleRemoveAll = () => {
    if (!trip) {
      return;
    }

    const unassigned = [
      ...clone()(formik.values.unallocatedOrders || []),
      ...clone()(trip.orders),
    ];

    /* as the bug with deep assignment and typescript the whole trips
     * object has to be cloned
     * https://github.com/formik/formik/issues/1948
     */
    const trips = clone()(formik.values.trips || []);
    trip.orders = [];
    // const tripIndex = trips.findIndex((t) => t.id == trip.id);
    trips[tripIndex] = trip;

    formik.setFieldValue('trips', trips);
    formik.setFieldValue('unallocatedOrders', unassigned);
  };

  // TODO: implement filters on orders
  // TODO: rerender trip based on sequence change
  // TODO: is this sequence correct? [...stopsLoading, ...stopsUnloading]
  // TODO: pull in correct Depot node here: depotNode
  const heightRef = React.useRef<HTMLDivElement>(null);
  return (
    <Card className={classes.container}>
      {/*<div style={{}}>*/}
      {planningMasterTrip &&
      planningMasterTrip.trip &&
      planningMasterTrip.trip.stops &&
      planningMasterTrip.trip.stops.length > 0 ? (
        <>
          <CardContent className={classes.planningMap}>
            <PlanningTripEdit trip={planningMasterTrip.trip} />
          </CardContent>
        </>
      ) : null}

      <CardContent className={classes.planning}>
        <VantageBoard
          data={orderBoardData}
          isHeaderVisible={false}
          title="Order Assignment"
          onFilterChange={() => null}
          onCardMoveAcrossLanes={handleCardMove}
          addAll={handleAddAll}
          removeAll={handleRemoveAll}
        />

        <Card
          className={classes.item}
          style={{
            height: heightRef.current
              ? heightRef.current.clientHeight
              : undefined,
          }}
        ></Card>
        <div className={classes.item}>
          {planningMasterTrip && planningSolution && (
            <PlanningMasterTripMapCard
              planningMasterTrip={planningMasterTrip}
              planningSolution={planningSolution}
              planningMasterTripFormik={trip}
            />
          )}
        </div>
        <div className={classes.item} ref={heightRef}>
          {planningSolution && (
            <PlanningSolutionOverviewCard
              planningSolution={planningSolution}
              isMap={true}
            />
          )}
        </div>
      </CardContent>

      {planningMasterTrip &&
      planningMasterTrip.trip &&
      planningMasterTrip.trip.stops &&
      planningMasterTrip.trip.stops.length > 0 ? (
        <>
          <CardContent className={classes.planningMap}>
            <PlanningTripGantt trip={planningMasterTrip.trip} />
          </CardContent>
        </>
      ) : null}

      <CardContent className={classes.planningMap}>
        <PlanningTripRoute
          planningMasterTripFormik={trip}
          loadingNodes={stopsLoading}
          offloadingNodes={stopsUnloading}
          depotNode={stopsLoading[0]}
          planningMasterTrip={planningMasterTrip}
        />
      </CardContent>
      {/*</div>*/}
    </Card>
  );
};

export const PlanningTripOrders = withStyles(styles)(
  withRouter(
    connect<IProps & RouteComponentProps<IParams>, PlanningSolutionFormValues>(
      PlanningTripOrdersComponent
    )
  )
);
