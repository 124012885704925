import React from 'react';
import {IconButton} from '@mui/material';
import {Refresh} from '@mui/icons-material';
import {IVantageMenuOption, IVantageMenuRef, VantageMenu} from '../../menu';
import {usePlanningBoardContext} from '../planningBoardContext';

export const PlanningBoardToolbarReload: React.FC = () => {
  const {
    loadInitialDetails,
    loadOrders,
    loadMasterTrips,
    setOptimalMasterTrips,
  } = usePlanningBoardContext();

  const menuRef = React.useRef<IVantageMenuRef>(null);

  const handleHardRefresh = React.useCallback(async () => {
    setOptimalMasterTrips([]);
    await loadOrders('onrefresh');
    await loadInitialDetails();
  }, [loadInitialDetails, loadOrders, setOptimalMasterTrips]);

  const reloadMenuOptions: IVantageMenuOption[] = React.useMemo(
    () => [
      {
        value: 1,
        label: `Hard Reload`,
        onClick: handleHardRefresh,
      },
      {
        value: 2,
        label: `Reload Orders`,
        onClick: () => loadOrders('onrefresh'),
      },
      {
        value: 3,
        label: `Reload Planning Trips`,
        onClick: () => loadMasterTrips(),
      },
    ],
    [handleHardRefresh, loadMasterTrips, loadOrders]
  );

  const handleRightClickRefresh = React.useCallback((event) => {
    event.preventDefault();
    menuRef.current?.onClick(event);
  }, []);

  return (
    <>
      <IconButton
        onClick={handleHardRefresh}
        onContextMenu={handleRightClickRefresh}
        size="medium"
      >
        <Refresh fontSize="inherit" />
      </IconButton>
      <VantageMenu
        options={reloadMenuOptions}
        ref={menuRef}
        enableContextMenu
      />
    </>
  );
};
