import React from 'react';
import {AuthenticationBackground} from './AuthenticationBackground';
import {useAuthenticationStyles} from './Authentication.style';
import {Paper, Typography} from '@mui/material';
import {AuthenticationLogo} from './AuthenticationLogo';
import {AuthenticationContext} from './AuthenticationContext';
import {AuthenticationCookiePolicy} from './AuthenticationCookiePolicy';
import {AuthenticationPopupTruck} from './AuthenticationPopupTruck';
import {WindowInfoContext} from '../../contexts';

export const AuthenticationContainer: React.FC = ({children}) => {
  const classes = useAuthenticationStyles();
  const {isMobile, isTablet} = React.useContext(WindowInfoContext);
  const {isCookiePolicyOpen, setIsCookiePolicyOpen} = React.useContext(
    AuthenticationContext
  );

  const toggleCookiePolicy = () => {
    if (setIsCookiePolicyOpen) {
      setIsCookiePolicyOpen(!isCookiePolicyOpen);
    }
  };

  return (
    <div className={classes.container}>
      {isCookiePolicyOpen && (
        <AuthenticationCookiePolicy onToggle={toggleCookiePolicy} />
      )}
      <div className={classes.background}>
        <AuthenticationBackground />
      </div>
      <Paper square className={classes.content}>
        <AuthenticationLogo />
        <div className={classes.children}>{children}</div>
        <div className={classes.bottom}>
          <Typography
            className={classes.bottomCookieText}
            onClick={toggleCookiePolicy}
            variant="body1"
          >
            VANTAGE Cookie Policy
          </Typography>
        </div>
      </Paper>
      {!isMobile && !isTablet && !isCookiePolicyOpen && (
        <AuthenticationPopupTruck />
      )}
    </div>
  );
};
