import React from 'react';
import {Button, Divider, Stack, Tooltip, Typography} from '@mui/material';
import {Info} from '@mui/icons-material';
import {
  MasterTripDebriefSnapshot,
  MasterTripDebriefSnapshotData,
} from '@onroadvantage/onroadvantage-api';
import {VantageReportButton} from '../../reportButton';
import {
  NOT_LAST_WARNING_MESSAGE,
  TripDebriefContext,
} from '../TripDebriefContext';
import {masterTripDebriefApi} from '../../../api';
import {useAppNotifications} from '../../../contexts';
import {Loader} from '../../loader';
import {checkRequiredFields} from '../helpers/checkRequiredFields';
import {splitByCapitals} from '../../../factory/template';

const AUTO_HIDE_DURATION = 7000;

const getUnAllocatedErrorMessage = (type: string) =>
  `Trip does not have ${type} allocated. Please allocate a ${type}`;

export const TripDebriefSummaryActions: React.FC = () => {
  const notify = useAppNotifications();
  const {
    masterTripDebriefConfig,
    masterTripDebriefData,
    loadTripDebrief,
    masterTripId,
  } = React.useContext(TripDebriefContext);
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  /**
   * Destructure masterTripDebriefData, need to add || ({} as MasterTripDebriefSnapshotData) for typescript, since
   * masterTripDebriefData is nullable.
   */
  const {
    approvedForBilling,
    manualStartOdometer,
    manualEndOdometer,
    completedDatetime,
    revision,
    snapshotData,
  } = masterTripDebriefData || ({} as MasterTripDebriefSnapshotData);
  /**
   *  Destructure masterTripDebriefData, need to add || ({} as MasterTripDebriefSnapshot) for typescript, since
   *  snapshot is nullable.
   */
  const {vehicle, driver, orders} =
    snapshotData || ({} as MasterTripDebriefSnapshot);

  const handleApproval = React.useCallback(async () => {
    setSubmitting(true);
    try {
      /**
       * unallocatedData is used to check if there is any data that is not assigned or allocated to the trip,
       * meaning the trip cannot be approved or unapproved yet.
       * Required values are:
       * manualStartOdometer and manualEndOdometer
       * vehicle
       * driver
       * completedDatetime
       */
      let unallocatedData = false;

      if (!approvedForBilling) {
        if (!manualStartOdometer || !manualEndOdometer) {
          notify('warning', getUnAllocatedErrorMessage('manual odometers'), {
            autoHideDuration: AUTO_HIDE_DURATION,
          });
          unallocatedData = true;
        }

        if (!vehicle) {
          notify('warning', getUnAllocatedErrorMessage('vehicle'), {
            autoHideDuration: AUTO_HIDE_DURATION,
          });
          unallocatedData = true;
        }

        if (!driver) {
          notify('warning', getUnAllocatedErrorMessage('driver'), {
            autoHideDuration: AUTO_HIDE_DURATION,
          });
          unallocatedData = true;
        }

        if (!completedDatetime) {
          notify('warning', getUnAllocatedErrorMessage('last drop date'), {
            autoHideDuration: AUTO_HIDE_DURATION,
          });
          unallocatedData = true;
        }

        if (orders?.length === 0) {
          notify('warning', getUnAllocatedErrorMessage('orders'), {
            autoHideDuration: AUTO_HIDE_DURATION,
          });
          unallocatedData = true;
        }
      }

      if (unallocatedData) {
        return;
      }

      if (masterTripId && revision) {
        /** If the trip has been approved, do the un-approve patch call, otherwise do the approve patch call */
        if (approvedForBilling) {
          await masterTripDebriefApi.apiMasterTripMasterTripIdDebriefUnapprovePatch(
            {masterTripId, body: {revision}}
          );
          notify('success', 'Unapproved trip');
        } else {
          await masterTripDebriefApi.apiMasterTripMasterTripIdDebriefApprovePatch(
            {masterTripId, body: {revision}}
          );
          notify('success', 'Approved trip');
        }
        /** Reload the tripDebrief to get the latest approval state, as well as the the latest revision number  */
        await loadTripDebrief();
      }
    } catch (e) {
      if (e.status === 409) {
        /** Can't update the debrief if it's revision is not the latest for the current debrief */
        notify('warning', e.message ?? NOT_LAST_WARNING_MESSAGE);
        await loadTripDebrief();
      } else {
        notify(
          'error',
          e.message ??
            `Failed to ${approvedForBilling ? 'un-approve' : 'approve'} trip`
        );
      }
    } finally {
      setSubmitting(false);
    }
  }, [
    manualStartOdometer,
    manualEndOdometer,
    vehicle,
    driver,
    completedDatetime,
    orders?.length,
    masterTripId,
    revision,
    notify,
    approvedForBilling,
    loadTripDebrief,
  ]);

  const missingRequiredFields = React.useMemo(
    () => checkRequiredFields(masterTripDebriefConfig, masterTripDebriefData),
    [masterTripDebriefConfig, masterTripDebriefData]
  );

  const hasMissingRequiredFields =
    missingRequiredFields && missingRequiredFields.length > 0;

  const requiredFieldsMessage = hasMissingRequiredFields
    ? `Missing required fields: ${missingRequiredFields
        ?.map((field) =>
          splitByCapitals(field, {capabilitiesFirstLetter: true})
        )
        .join(', ')}`
    : null;

  const handleCheckRequiredFieldsOnApproval = React.useCallback(async () => {
    if (hasMissingRequiredFields) {
      notify('warning', requiredFieldsMessage);
    } else {
      await handleApproval();
    }
  }, [handleApproval, hasMissingRequiredFields, notify, requiredFieldsMessage]);

  return (
    <Stack
      pr={1.5}
      pb={1.5}
      direction="row"
      divider={<Divider orientation="vertical" sx={{mx: 1}} flexItem />}
    >
      <VantageReportButton
        name="Generate Summary Report"
        variant="outlined"
        disabled
      />
      <Button
        color={hasMissingRequiredFields ? 'inherit' : 'primary'}
        variant="contained"
        onClick={handleCheckRequiredFieldsOnApproval}
        disabled={submitting}
        startIcon={<Loader size={25} loading={submitting} />}
        endIcon={
          hasMissingRequiredFields ? (
            <Tooltip
              title={
                <Typography variant="subtitle1">
                  {requiredFieldsMessage}
                </Typography>
              }
              arrow
            >
              <Info color="warning" />
            </Tooltip>
          ) : null
        }
      >
        {submitting
          ? 'Submitting...'
          : masterTripDebriefData?.approvedForBilling
          ? 'Un-approve For Billing'
          : 'Approve For Billing'}
      </Button>
    </Stack>
  );
};
