import React from 'react';
import {TemplateCard, TemplateForm} from '../../factory/template';
import {TransporterContext} from './TransporterContext';
import {Transporter, TransporterPost} from '@onroadvantage/onroadvantage-api';
import * as Yup from 'yup';
import {FormikTextField} from '../formik';

const schema: Yup.SchemaOf<TransporterPost> = Yup.object({
  name: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
});

const getInitialValues = (
  transporter?: Transporter | null
): TransporterPost | undefined => {
  if (transporter) {
    return {
      name: transporter.name ?? '',
      description: transporter.description ?? '',
    };
  }
  return undefined;
};

export const TransporterDetails: React.FC<{isAdd?: boolean}> = ({isAdd}) => {
  const {detailsRef, loading, submitting, transporter, onDetailsFormSubmit} =
    React.useContext(TransporterContext);
  const [initialValues, setInitialValues] = React.useState<
    TransporterPost | undefined
  >();

  React.useEffect(() => {
    const values = getInitialValues(transporter);

    if (values) {
      setInitialValues(values);
    } else if (isAdd) {
      setInitialValues({
        name: '',
        description: '',
      });
    }
  }, [isAdd, transporter, setInitialValues]);

  return (
    <TemplateCard
      title={`Transporter Details ${isAdd ? 'Add' : 'Edit'}`}
      loading={loading}
    >
      <TemplateForm<TransporterPost>
        initialValues={initialValues}
        onSubmit={onDetailsFormSubmit}
        permission={{
          name: `${isAdd ? 'Add' : 'Edit'} Transporter`,
          type: isAdd ? 'Add' : 'Edit',
        }}
        submitting={submitting}
        innerRef={detailsRef}
        validationSchema={schema}
      >
        <FormikTextField
          name="name"
          placeholder="Enter the transporter name"
          label="Transporter Name"
          fullWidth
        />
        <FormikTextField
          name="description"
          placeholder="Enter the transporter description"
          label="Transporter Description"
          fullWidth
        />
      </TemplateForm>
    </TemplateCard>
  );
};
