import ConfigService from './ConfigService/ConfigService';

import ResponseService from './ResponseService';
import {authStore} from '../store';

const serverUrl = ConfigService.serverUri;
const authUrl = `${serverUrl}/api/triplog`;

const TripLogService = {
  getByTaskId: async (taskId) => {
    const postTaskId = taskId || 'randombinaryhere';
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(authUrl, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({taskId: postTaskId}),
      headers,
    });
    return ResponseService.checkStatus(response);
  },
};

export default TripLogService;
