import React from 'react';
import {TripStop} from '@onroadvantage/onroadvantage-api';
import {useTripContext} from '../../tripContext';
import {
  useTripStop,
  useTripStopResponse,
  useTripStopResponseInitial,
} from './useTripStop';

export const TripStopContext = React.createContext<useTripStopResponse>(
  useTripStopResponseInitial
);

interface TripStopContextProviderProps {
  row: TripStop;
}

export const TripStopContextProvider: React.FC<
  TripStopContextProviderProps
> = ({row, children}) => {
  const {masterTripId} = useTripContext();
  const values = useTripStop({masterTripId, tripStopId: row.id});

  return (
    <TripStopContext.Provider value={values}>
      {children}
    </TripStopContext.Provider>
  );
};

export const useTripStopContext = () => React.useContext(TripStopContext);
