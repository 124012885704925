import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';
import {planningBoardTheme} from '../planningBoardTheme';

const PlanningBoardToolbarStyles = (theme: Theme) =>
  createStyles({
    activeFilter: {margin: 1},
    activeFilters: {
      background: 'transparent',
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    bookingMasterTrips: {color: planningBoardTheme.booking},
    maintenanceMasterTrips: {color: planningBoardTheme.maintenance},
    infoIcon: {cursor: 'default'},
    masterMasterTrips: {color: planningBoardTheme.master},
    numOfVehicles: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(1),
      color: theme.palette.text.secondary,
      whiteSpace: 'nowrap',
    },
    planningMasterTrips: {color: planningBoardTheme.planning},
    tabs: {marginTop: 0, paddingTop: 0, paddingBottom: 0},
    toolbar: {justifyContent: 'space-between'},
    toolbarActions: {
      display: 'flex',
      flex: 1,
      spacing: 10,
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    toolbarDivider: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
    tooltip: {
      marginTop: -5,
      backgroundColor: '#fefefe',
      border: '1px solid #dadde9',
      color: '#111',
    },
  });

export const usePlanningBoardToolbarStyles = makeStyles(
  PlanningBoardToolbarStyles,
  {name: 'PlanningBoardToolbarStyles'}
);
