import React from 'react';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import EditIcon from '@mui/icons-material/Edit';
import {SiteDetailsContent} from './SiteDetailsContent';

interface SiteDetailsProps {
  isAdd?: boolean;
}

export const SiteDetails: React.FC<SiteDetailsProps> = ({isAdd}) => {
  return (
    <Card>
      <CardHeader
        title={`Site Details ${isAdd ? 'Add' : 'Edit'}`}
        avatar={
          <Avatar>
            <EditIcon />
          </Avatar>
        }
      />
      <CardContent>
        <SiteDetailsContent isAdd={isAdd} />
      </CardContent>
    </Card>
  );
};
