import React from 'react';
import {AdminTelematicsConfigContext} from './AdminTelematicsConfigContext';
import {useParams} from 'react-router-dom';

interface IParams {
  telematicsConfigId?: string;
}

export const AdminTelematicsConfigIdHandler: React.FC = () => {
  const adminTelematicsConfigContext = React.useContext(
    AdminTelematicsConfigContext
  );

  const setTelematicsConfigId =
    adminTelematicsConfigContext.setTelematicsConfigId;

  const {telematicsConfigId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = telematicsConfigId
      ? parseInt(telematicsConfigId)
      : undefined;
    setTelematicsConfigId(parsedId);

    return () => {
      setTelematicsConfigId(undefined);
    };
  }, [telematicsConfigId, setTelematicsConfigId]);

  return null;
};
