import ConfigService from '../../service/ConfigService/ConfigService';
import React, {useContext} from 'react';
import {AuthConnectionContext} from '../../contexts';

export const VersionStamp = () => {
  const {serverVersion} = useContext(AuthConnectionContext);
  return (
    <div
      style={{
        fontSize: 'xx-small',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      {`API: ${serverVersion}`}
      {`APP: ${ConfigService.displayAppBuildDetails}`}
    </div>
  );
};
