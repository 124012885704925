import React from 'react';
import {TableActionButtonBase} from './TableActionButtonBase';
import {Edit} from '@mui/icons-material';
import {useTemplateTableActions} from '../../providers';
import {useInternalTableContext} from '../../TemplateTable';
import {TableActionButtonSaveAll} from './TableActionButtonSaveAll';

export const TableActionButtonEditAll: React.FC = () => {
  const {enableInlineEditAll, editingRowIds, setEditingRowIds} =
    useTemplateTableActions();
  const {list} = useInternalTableContext();

  const handleSelectAllRows = React.useCallback(() => {
    setEditingRowIds(list.map(({id}) => id as number | string));
  }, [setEditingRowIds, list]);

  if (!enableInlineEditAll) {
    return null;
  }

  return (
    <>
      {editingRowIds && editingRowIds.length > 0 ? (
        <TableActionButtonSaveAll />
      ) : (
        <TableActionButtonBase
          data-cy="TemplateTableActionsEdit"
          onClick={handleSelectAllRows}
        >
          <Edit />
        </TableActionButtonBase>
      )}
    </>
  );
};
