import {MasterTripDebriefSnapshotData} from '@onroadvantage/onroadvantage-api';
import {isNegativeNumber} from '../../../factory/helpers';

export const getTripActualDistance = (
  masterTripDebriefData: MasterTripDebriefSnapshotData | null | undefined
) => {
  let actualDistance: number;
  const plannedTripDistance =
    masterTripDebriefData?.overrideData?.plannedTripDistance ??
    masterTripDebriefData?.snapshotData?.plannedTripDistance ??
    0;

  const actualStopMethodology =
    masterTripDebriefData?.overrideData?.contract?.actualsStopMethodology ??
    masterTripDebriefData?.snapshotData?.contract?.actualsStopMethodology ??
    'mobile';

  if (actualStopMethodology === 'gps') {
    // gps logic
    actualDistance =
      masterTripDebriefData?.overrideData?.actualTripDistance ??
      masterTripDebriefData?.snapshotData?.actualTripDistance ??
      0;
  } else {
    // mobile logic
    actualDistance = Math.round(
      (masterTripDebriefData?.manualEndOdometer ?? 0) -
        (masterTripDebriefData?.manualStartOdometer ?? 0)
    );
  }

  const variance = actualDistance - plannedTripDistance;

  const percentageVariance = Math.abs(
    Math.round((variance / plannedTripDistance) * 100)
  );

  const percentageVarianceAbs = Math.abs(percentageVariance);

  const displayVariance =
    actualDistance === 0 || plannedTripDistance === 0
      ? '-'
      : `${Math.abs(variance).toFixed(2)}km and ${percentageVariance}% (${
          isNegativeNumber(variance) ? 'less' : 'more'
        } than planned)`;

  return {
    actualDistance,
    percentageVariance,
    percentageVarianceAbs,
    plannedTripDistance,
    displayVariance,
  };
};
