import React from 'react';
import {
  ContractDump,
  Listing,
  WebPlanningBoardBookingMasterTripCreationRequest,
  WebPlanningBoardConstraintError,
  WebPlanningBoardMasterTrip,
  WebPlanningBoardOrder,
  WebPlanningBoardPlanningOrderSequenced,
  WebPlanningBoardTripStopOrder,
  WebPlanningBoardVehicle,
} from '@onroadvantage/onroadvantage-api';
import {IPlanningBoardGanttItem} from '../../planningBoardGantt';
import {
  getPlanningBoardTripCreateInitialValues,
  getPlanningBoardTripUpdateInitialValues,
  IPlanningBoardTripPanelForm,
} from '../planningBoardTripForm';
import {planningBoardApi} from '../../../../../api';
import {TOnFormikSubmit} from '../../../../../factory/template';
import {
  MasterTripTypes,
  usePlanningBoardGanttResponse,
  usePlanningBoardResponse,
  WebPlanningBoardMasterTripWithIdType,
} from '../../../planningBoardContext';
import {useAppNotifications} from '../../../../../contexts';

interface usePlanningBoardTripParams {
  createMasterTrip: usePlanningBoardGanttResponse['createMasterTrip'];
  onAddPreviewTripToGantt: usePlanningBoardGanttResponse['onAddPreviewTripToGantt'];
  getContract: usePlanningBoardResponse['getContract'];
  getMasterTrip: usePlanningBoardResponse['getMasterTrip'];
  getOrder: usePlanningBoardResponse['getOrder'];
  getMasterTripsWithSameVehicle: usePlanningBoardResponse['getMasterTripsWithSameVehicle'];
  onClearSelectedGanttItem: usePlanningBoardGanttResponse['onClearSelectedGanttItem'];
  updateMasterTrip: usePlanningBoardGanttResponse['updateMasterTrip'];
  selectedGanttItem: IPlanningBoardGanttItem | undefined;
  vehicles: WebPlanningBoardVehicle[];
}

export const usePlanningBoardTrip = ({
  createMasterTrip,
  onAddPreviewTripToGantt,
  getMasterTripsWithSameVehicle,
  getContract,
  getMasterTrip,
  getOrder,
  onClearSelectedGanttItem,
  updateMasterTrip,
  selectedGanttItem,
  vehicles,
}: usePlanningBoardTripParams) => {
  const notify = useAppNotifications();

  const [addedOrders, setAddedOrders] = React.useState<
    (WebPlanningBoardOrder | WebPlanningBoardTripStopOrder)[]
  >([]);
  const [orders, setOrders] = React.useState<
    (WebPlanningBoardTripStopOrder | WebPlanningBoardOrder)[]
  >([]);
  const [tripPreview, setTripPreview] = React.useState<
    WebPlanningBoardMasterTrip | undefined
  >();
  const [constraintErrors, setConstraintErrors] = React.useState<
    WebPlanningBoardConstraintError[] | undefined
  >();
  const [loadingTripPreview, setLoadingTripPreview] =
    React.useState<boolean>(false);
  const [loadingInitialDetails, setLoadingInitialDetails] =
    React.useState<boolean>(false);
  const [masterTrip, setMasterTrip] = React.useState<
    WebPlanningBoardMasterTrip | undefined
  >();
  const [masterTripType, setMasterTripType] = React.useState<
    MasterTripTypes | undefined
  >();

  const initialValues = React.useMemo(
    () =>
      selectedGanttItem?.variant === 'order'
        ? getPlanningBoardTripCreateInitialValues({
            selectedGanttItem,
            vehicles,
            getMasterTripsWithSameVehicle,
          })
        : getPlanningBoardTripUpdateInitialValues({
            selectedGanttItem,
            vehicles,
          }),
    [selectedGanttItem, vehicles, getMasterTripsWithSameVehicle]
  );

  const handleClearTripState = React.useCallback(() => {
    setAddedOrders([]);
    setConstraintErrors([]);
    setTripPreview(undefined);
  }, []);

  const handleAddOrders = React.useCallback(
    (selectedOrders: Listing[]) => {
      const ordersToAdd: (
        | WebPlanningBoardOrder
        | WebPlanningBoardTripStopOrder
      )[] = [];

      selectedOrders.forEach(({value}) => {
        const order = getOrder(value);
        if (order && !ordersToAdd.map(({id}) => id).includes(value)) {
          ordersToAdd.push(order);
        }
      });

      setAddedOrders(ordersToAdd);

      const newOrders = [
        ...orders.filter(({id}) => !ordersToAdd.map(({id}) => id).includes(id)),
        ...ordersToAdd,
      ];

      setOrders(newOrders);

      return newOrders;
    },
    [getOrder, orders]
  );

  const handleRemoveOrder = React.useCallback(
    (order: WebPlanningBoardOrder | WebPlanningBoardTripStopOrder) => {
      if (!order.id) {
        return orders;
      }
      const newOrders = orders.filter(({id}) => id !== order.id);

      setAddedOrders((prevOrders) =>
        prevOrders.filter(({id}) => id !== order.id)
      );

      setOrders(newOrders);

      return newOrders;
    },
    [orders]
  );

  const handleLoadTripPreview = React.useCallback(
    async (
      values: IPlanningBoardTripPanelForm,
      orders: (WebPlanningBoardOrder | WebPlanningBoardTripStopOrder)[]
    ) => {
      setLoadingTripPreview(true);
      setConstraintErrors([]);
      setTripPreview(undefined);
      try {
        const {
          selectedTimeOption,
          endingPointNode,
          startingPointNode,
          vehicle,
          driver,
          offloadingArrivalTime,
          loadingArrivalTime,
        } = values;
        if (vehicle?.value && selectedTimeOption) {
          const planningOrders: WebPlanningBoardPlanningOrderSequenced[] = [];
          let sequence = 0;

          orders.forEach(({planningOrder}) => {
            if (
              planningOrder?.id &&
              !planningOrders.map(({id}) => id).includes(planningOrder.id)
            ) {
              sequence++;
              planningOrders.push({id: planningOrder.id, sequence});
            }
          });

          const requestObj: WebPlanningBoardBookingMasterTripCreationRequest = {
            vehicleId: vehicle.value,
            driverId: driver?.value,
            startingPointNodeId: startingPointNode?.value,
            endingPointNodeId: endingPointNode?.value,
            planningOrders: planningOrders.map(({id}, index) => ({
              id,
              /** Insure we pass the correct sequence */
              sequence: index + 1,
            })),
          };
          if (selectedTimeOption === 'loadingOffloadingArrivalTime') {
            requestObj.loadingArrivalTime = loadingArrivalTime;
            requestObj.offloadingArrivalTime = offloadingArrivalTime;
          } else {
            requestObj[selectedTimeOption] = values[selectedTimeOption];
          }
          const response =
            await planningBoardApi.apiWebPlanningBoardMasterTripPreviewPost({
              body: requestObj,
            });
          setConstraintErrors(response.constraintErrors);
          setTripPreview(response);

          if (response) {
            onAddPreviewTripToGantt(response);
          }

          return response;
        }
      } catch (e) {
        notify('error', 'Failed to load trip preview');
      } finally {
        setLoadingTripPreview(false);
      }
    },
    [onAddPreviewTripToGantt, notify]
  );

  const handleGetMasterTripOrders = React.useCallback(
    (masterTrip: WebPlanningBoardMasterTripWithIdType | undefined) => {
      if (masterTrip?.trip?.stops && masterTrip.trip.stops.length > 0) {
        const masterTripOrders: WebPlanningBoardTripStopOrder[] = [];

        masterTrip.trip.stops.forEach(({orders}) => {
          orders?.forEach((order) => {
            if (!masterTripOrders.map(({id}) => id).includes(order.id)) {
              masterTripOrders.push(order);
            }
          });
        });

        return masterTripOrders;
      }

      return undefined;
    },
    []
  );

  const handleLoadInitialDetails = React.useCallback(
    async (values: IPlanningBoardTripPanelForm) => {
      setLoadingInitialDetails(true);
      setAddedOrders([]);
      try {
        const ordersArray: (
          | WebPlanningBoardTripStopOrder
          | WebPlanningBoardOrder
        )[] = [];

        if (selectedGanttItem?.variant === 'order' && selectedGanttItem.data) {
          ordersArray.push(selectedGanttItem.data as WebPlanningBoardOrder);
          const masterTrip = await handleLoadTripPreview(values, [
            selectedGanttItem.data as WebPlanningBoardOrder,
          ]);
          setMasterTrip(masterTrip);
        } else {
          const {masterTrip, type} = getMasterTrip(selectedGanttItem?.data?.id);
          const masterTripOrders = handleGetMasterTripOrders(masterTrip);
          if (type === 'booking') {
            const tripOrders: WebPlanningBoardTripStopOrder[] = [];
            masterTrip?.trip?.stops?.forEach(({orders}) => {
              orders?.forEach((order) => {
                if (
                  order &&
                  order.lines &&
                  order.lines.length > 0 &&
                  !tripOrders.map(({id}) => id).includes(order.id)
                ) {
                  tripOrders.push(order);
                }
              });
            });
            const masterTripPreview = await handleLoadTripPreview(
              values,
              tripOrders
            );
            if (
              (masterTripPreview?.constraintErrors &&
                masterTripPreview.constraintErrors.length > 0) ||
              !masterTripPreview
            ) {
              setMasterTrip(masterTrip);
            } else {
              setMasterTrip(masterTripPreview);
            }
          } else {
            setMasterTrip(masterTrip);
          }

          if (masterTripOrders) {
            const contract = getContract(masterTrip?.trip?.contractId);
            const contractDump: ContractDump | undefined =
              contract?.code && contract?.name && contract?.id
                ? {
                    code: contract.code,
                    name: contract.name,
                    id: contract.id,
                  }
                : undefined;
            masterTripOrders.forEach((order) => {
              ordersArray.push({...order, contract: contractDump});
            });
          }
          setMasterTripType(type);
        }
        setOrders(ordersArray);
      } finally {
        setLoadingInitialDetails(false);
      }
    },
    [
      getContract,
      getMasterTrip,
      handleGetMasterTripOrders,
      handleLoadTripPreview,
      selectedGanttItem,
      setAddedOrders,
      setOrders,
    ]
  );

  const handleGenerateTripPreview = React.useCallback(
    async (values: IPlanningBoardTripPanelForm) => {
      if (selectedGanttItem?.variant === 'order' && selectedGanttItem.data) {
        const masterTrip = await handleLoadTripPreview(values, orders);
        setMasterTrip(masterTrip);
      } else {
        const {masterTrip, type} = getMasterTrip(selectedGanttItem?.id);
        setMasterTripType(type);
        if (type === 'booking') {
          const masterTripPreview = await handleLoadTripPreview(values, orders);
          setMasterTrip(masterTripPreview);
        } else {
          setMasterTrip(masterTrip);
        }
      }
    },
    [getMasterTrip, handleLoadTripPreview, orders, selectedGanttItem]
  );

  const handleSubmit = React.useCallback<
    TOnFormikSubmit<IPlanningBoardTripPanelForm>
  >(
    async (values, formikHelpers) => {
      if (!selectedGanttItem || !selectedGanttItem.id) {
        notify('warning', `Couldn't upload to Gantt`);
        return;
      }
      formikHelpers.setSubmitting(true);
      try {
        const overrideErrors = constraintErrors && !!constraintErrors.length;
        if (selectedGanttItem?.variant === 'order') {
          await createMasterTrip(values, orders, overrideErrors);
        } else {
          await updateMasterTrip(values, orders, overrideErrors);
        }
      } finally {
        formikHelpers.setSubmitting(false);
        onClearSelectedGanttItem();
        handleClearTripState();
      }
    },
    [
      selectedGanttItem,
      notify,
      constraintErrors,
      createMasterTrip,
      orders,
      updateMasterTrip,
      onClearSelectedGanttItem,
      handleClearTripState,
    ]
  );

  return {
    addedOrders,
    constraintErrors,
    onGenerateTripPreview: handleGenerateTripPreview,
    onLoadInitialDetails: handleLoadInitialDetails,
    initialValues,
    loadTripPreview: handleLoadTripPreview,
    loadingInitialDetails,
    loadingTripPreview,
    masterTrip,
    masterTripType,
    onAddOrders: handleAddOrders,
    onClearTripState: handleClearTripState,
    onRemoveOrder: handleRemoveOrder,
    onSubmit: handleSubmit,
    orders,
    setAddedOrders,
    setMasterTrip,
    setMasterTripType,
    setOrders,
    tripPreview,
  };
};

export type usePlanningBoardTripResponse = ReturnType<
  typeof usePlanningBoardTrip
>;
export const usePlanningBoardTripResponseInitial: usePlanningBoardTripResponse =
  {
    addedOrders: [],
    constraintErrors: undefined,
    onGenerateTripPreview: async () => {},
    onLoadInitialDetails: async () => {},
    initialValues: undefined,
    loadTripPreview: async () => undefined,
    loadingInitialDetails: false,
    loadingTripPreview: false,
    masterTrip: undefined,
    masterTripType: undefined,
    onAddOrders: () => [],
    onClearTripState: () => {},
    onRemoveOrder: () => [],
    onSubmit: () => {},
    orders: [],
    setAddedOrders: () => {},
    setMasterTrip: () => {},
    setMasterTripType: () => {},
    setOrders: () => {},
    tripPreview: undefined,
  };
