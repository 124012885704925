import React from 'react';
import {ContractGroupForm} from './ContractGroupForm';
import {ITemplateTabControllerTab, TemplateTabs} from '../../factory/template';
import {ContractGroupContext} from './ContractGroupContext';
import {ContractGroupContracts} from './ContractGroupContracts';
import {ContractGroupUsers} from './ContractGroupUsers';

export const ContractGroup: React.FC = () => {
  const {contractGroupId, loading} = React.useContext(ContractGroupContext);

  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {title: 'Details', element: <ContractGroupForm />},
    {title: 'Contracts', element: <ContractGroupContracts />},
    {title: 'Users', element: <ContractGroupUsers />},
  ]);

  const path = React.useMemo(
    () => `/contractgrouplist/${contractGroupId}`,
    [contractGroupId]
  );

  return <TemplateTabs tabs={tabs} path={path} loading={loading} />;
};
