import React from 'react';
import {EventsPaneSearchForm} from './EventsPaneSearchForm';
import {Divider} from '@mui/material';
import {EventsPaneContext} from '../EventsPaneContext';
import {useEventsPaneSearchStyles} from './EventsPaneSearch.style';

export const EventsPaneSearch: React.FC = () => {
  const classes = useEventsPaneSearchStyles();
  const {collapseSearchForm} = React.useContext(EventsPaneContext);
  return (
    <div className={classes.root}>
      <Divider
        className={
          collapseSearchForm ? classes.dividerCollapsed : classes.divider
        }
      />
      <div className={classes.content}>
        <EventsPaneSearchForm />
      </div>
    </div>
  );
};
