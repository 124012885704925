import {decorate, observable, action} from 'mobx';
import {Trip} from '@onroadvantage/onroadvantage-api';

class TripTrackingType {
  private trackingType: 'truck' | 'mobile';

  constructor() {
    this.trackingType = 'truck';
  }

  getTripTrackingVehicleId = (trip: Trip) => {
    if (this.trackingType === 'truck' && trip.vehicle) {
      return trip.vehicle.id;
    } else if (this.trackingType === 'mobile' && trip.mobile) {
      return trip.mobile.id;
    }
    return null;
  };

  setTrackingType = (trackingType: 'truck' | 'mobile') => {
    this.trackingType = trackingType;
  };
}

export default decorate(TripTrackingType, {
  // @ts-expect-error upgrade
  trackingType: observable,
  setTrackingType: action,
  getTripTrackingVehicleId: action,
});
