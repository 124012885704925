import React from 'react';
import {Table} from '@devexpress/dx-react-grid-material-ui';
import {useTableCellStyles} from './TableCell.style';

export const TableCell: React.FC<Table.DataCellProps> = ({
  children,
  ...props
}) => {
  const classes = useTableCellStyles();
  return (
    <Table.Cell className={classes.root} {...props}>
      {children}
    </Table.Cell>
  );
};
