import React from 'react';
import {
  CardHeader,
  Avatar,
  CardContent,
  CardActions,
  Button,
} from '@mui/material';
import {SaveAlt, VerifiedUser} from '@mui/icons-material';
import QRCode from 'qrcode.react';
import RoleService from '../../../service/RoleService';
import {isEmpty} from 'lodash';
import {UserService} from '../../../service';
import {DriverContext} from '../DriverContext';
import {useDownloadQRAsPDF} from '../../trip/tripSummary/useDownloadQrAsPdf';
import {useAppNotifications} from '../../../contexts';

export const DriverUserActions: React.FC = () => {
  const {driver} = React.useContext(DriverContext);
  const [userKey, setUserKey] = React.useState('');
  const [user] = React.useState(driver?.user);
  const driverObj = driver;
  const {downloadQRasPDF, qrRef} = useDownloadQRAsPDF(userKey, driverObj);
  const notify = useAppNotifications();

  const generateUserQr = React.useCallback(async () => {
    const response = await UserService.getUserQr(user?.id);
    const data = await response.data;
    setUserKey(data.key);
  }, [user?.id]);

  const handleDownload = async () => {
    try {
      if (driverObj && userKey) {
        await downloadQRasPDF({userKey, driver: driverObj});
      }
    } catch (error) {
      notify('error', 'Failed to download pdf');
    }
  };

  return (
    <>
      <CardHeader
        title="User Actions"
        avatar={
          <Avatar aria-label="UserActions">
            <VerifiedUser />
          </Avatar>
        }
      />
      {userKey && (
        <CardContent>
          <div ref={qrRef}>
            <QRCode value={userKey} size={256} level="L" />
          </div>
        </CardContent>
      )}

      <CardActions>
        {!userKey && (
          <Button
            variant="outlined"
            size="small"
            disabled={
              !RoleService.hasPermission('User QR', 'View') || isEmpty(user)
            }
            onClick={generateUserQr}
          >
            Generate Login QR
          </Button>
        )}
        {userKey && (
          <Button
            variant="outlined"
            size="small"
            onClick={handleDownload}
            startIcon={<SaveAlt />}
          >
            DOWNLOAD QR
          </Button>
        )}
      </CardActions>
    </>
  );
};
