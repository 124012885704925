import React from 'react';
import {
  MasterTripDebriefNotificationSnapshot,
  MasterTripDebriefTaskSnapshot,
  MasterTripDebriefSnapshot,
} from '@onroadvantage/onroadvantage-api';
import {Assignment} from '@mui/icons-material';
import {
  TemplateCard,
  TemplateTable,
  TemplateTableConfig,
  TemplateTableDefault,
} from '../../../factory/template';
import {TripDebriefContext} from '../TripDebriefContext';
import {TripDebriefNotificationDetailList} from './TripDebriefNotificationDetailList';

export interface GroupedNotifications extends TemplateTableDefault {
  id: number;
  stopNode: string;
  trigger: string;
  list: MasterTripDebriefNotificationSnapshot[];
}

interface TripDebriefNotificationListProps {
  disableCard?: boolean;
}

export const TripDebriefNotificationList: React.FC<
  TripDebriefNotificationListProps
> = ({disableCard}) => {
  const {loading, masterTripDebriefData} = React.useContext(TripDebriefContext);
  /**
   *  Destructure snapshotData, need to add || ({} as MasterTripDebriefSnapshot) for typescript, since
   *  snapshotData is nullable.
   */
  const {notifications, stops, tasks} =
    masterTripDebriefData?.snapshotData || ({} as MasterTripDebriefSnapshot);

  const [config] = React.useState<TemplateTableConfig<GroupedNotifications>>({
    columns: [
      {
        name: 'trigger',
        label: 'Trigger',
        type: 'string',
      },
      {
        name: 'list',
        label: 'Total Count',
        type: 'number',
        getValue: ({list}) => list.length || 0,
      },
      {name: 'stopNode', label: 'Stop', type: 'string'},
    ],
    disablePagination: true,
    disableToolbar: true,
    identifier: 'TRIP_DEBRIEF_NOTIFICATION_LIST',
  });

  const list: any[] | undefined = React.useMemo(() => {
    const groupedNotifications: {[key: string]: GroupedNotifications} = {};

    notifications?.forEach((notification) => {
      if (notification.id) {
        const {taskId} = notification;

        const task: MasterTripDebriefTaskSnapshot | undefined = tasks?.find(
          ({id}) => id === taskId
        );

        const taskTemplateName = task?.template?.name;
        const foundStop = stops?.find(
          ({tasks}) => !!tasks?.find(({id}) => id === taskId)
        );

        if (taskId && taskTemplateName) {
          groupedNotifications[taskId] = {
            id: notification.id,
            trigger: taskTemplateName,
            stopNode: foundStop?.node?.name ?? 'undefined',
            list: [...(groupedNotifications[taskId]?.list ?? []), notification],
          };
        }
      }
    });

    return Object.keys(groupedNotifications).map((key, index) => ({
      id: index,
      trigger: groupedNotifications[key].trigger,
      stopNode: groupedNotifications[key].stopNode ?? 'undefined',
      list: groupedNotifications[key].list,
    }));
  }, [notifications, stops, tasks]);

  if (disableCard) {
    return (
      <TemplateTable
        config={config}
        currentPage={1}
        list={list ?? []}
        elevation={0}
        rowDetail={TripDebriefNotificationDetailList}
        grouping={[{columnName: 'stopNode'}]}
      />
    );
  }

  return (
    <TemplateCard
      title="Trip Notifications"
      avatar={<Assignment />}
      loading={loading}
      disableContentPadding
    >
      <TemplateTable
        config={config}
        currentPage={1}
        list={list ?? []}
        elevation={0}
        rowDetail={TripDebriefNotificationDetailList}
        grouping={[{columnName: 'stopNode'}]}
      />
    </TemplateCard>
  );
};
