import React from 'react';
import {
  usePlanningBoardGanttResponse,
  usePlanningBoardResponse,
  WebPlanningBoardMasterTripWithIdType,
} from '../../../planningBoardContext';
import {IPlanningBoardBookingForm} from '../PlanningBoardBookingForm';
import {TOnFormikSubmit} from '../../../../../factory/template';
import {bookingApi} from '../../../../../api';
import {useAppNotifications} from '../../../../../contexts';
import {getMasterTripId} from '../../../helpers';
import {BookingPost} from '@onroadvantage/onroadvantage-api';

export interface usePlanningBoardBookingParams {
  bookings: usePlanningBoardResponse['bookings'];
  getMasterTripsWithSameVehicle: usePlanningBoardResponse['getMasterTripsWithSameVehicle'];
  getVehicle: usePlanningBoardResponse['getVehicle'];
  loadMasterTrips: usePlanningBoardResponse['loadMasterTrips'];
  onClearSelectedGanttItem: usePlanningBoardGanttResponse['onClearSelectedGanttItem'];
  selectedGanttItem: usePlanningBoardGanttResponse['selectedGanttItem'];
}

const PLANNING_BOARD_BOOKING_INITIAL_VALUES: IPlanningBoardBookingForm = {
  number: '',
  customerReferenceNumber: '',
  bookingType: {value: undefined, label: undefined},
  startNode: {value: undefined, label: undefined},
  appointmentNode: {value: undefined, label: undefined},
  endNode: {value: undefined, label: undefined},
  vehicle: {value: undefined, label: undefined},
  startDate: new Date(),
  endDate: new Date(),
};

export const usePlanningBoardBooking = ({
  bookings,
  getVehicle,
  getMasterTripsWithSameVehicle,
  loadMasterTrips,
  onClearSelectedGanttItem,
  selectedGanttItem,
}: usePlanningBoardBookingParams) => {
  const notify = useAppNotifications();
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const booking = React.useMemo(
    () =>
      selectedGanttItem?.data === null
        ? undefined
        : bookings.find(
            ({bookingMasterTripId}) =>
              getMasterTripId('booking', selectedGanttItem?.id) ===
              bookingMasterTripId
          ),
    [bookings, selectedGanttItem]
  );

  const initialValues = React.useMemo<IPlanningBoardBookingForm>(() => {
    if (booking) {
      const trip = booking.bookingMasterTrip?.trip;
      const vehicle = getVehicle(trip?.vehicleId);
      const stops = trip?.stops;
      const appointmentNode = booking.appointmentNode;
      const startNode = stops ? stops[0].node : booking.appointmentNode;
      const endNode = stops
        ? stops[stops.length - 1].node
        : booking.appointmentNode;
      let startDate = new Date();
      let endDate = new Date();

      const appointmentStop = stops?.find(
        ({nodeId}) => nodeId === appointmentNode?.id
      );

      if (appointmentStop?.serviceTimeStart) {
        startDate = appointmentStop.serviceTimeStart;
      } else if (trip?.tripStart) {
        startDate = trip.tripStart;
      } else if (selectedGanttItem?.start) {
        startDate = new Date(selectedGanttItem.start);
      }

      if (appointmentStop?.serviceTimeEnd) {
        endDate = appointmentStop?.serviceTimeEnd;
      } else if (trip?.tripEnd) {
        endDate = trip.tripEnd;
      } else if (selectedGanttItem?.end) {
        endDate = new Date(selectedGanttItem.end);
      }

      return {
        number: booking.number,
        customerReferenceNumber: booking.customerReferenceNumber,
        bookingType: {
          value: booking.bookingTypeId ?? undefined,
          label: booking.bookingType.name,
        },
        startNode: {value: startNode?.id, label: startNode?.name},
        appointmentNode: {
          value: appointmentNode?.id,
          label: appointmentNode?.name,
        },
        endNode: {value: endNode?.id, label: endNode?.name},
        vehicle: {
          value: vehicle?.id,
          label: `${vehicle?.registrationNumber} - ${vehicle?.fleetNumber}`,
        },
        startDate,
        endDate,
        duration: undefined,
        durationUnit: 'days',
      };
    }

    const vehicle = getVehicle(selectedGanttItem?.group);
    const tripsWithSameVehicle = getMasterTripsWithSameVehicle(
      selectedGanttItem?.group
    );
    const tripsBeforeSelectedItem = tripsWithSameVehicle?.filter(({trip}) => {
      return !!(
        trip?.tripEnd &&
        selectedGanttItem?.start &&
        new Date(trip.tripEnd) <= new Date(selectedGanttItem.start)
      );
    });

    let closestTrip: WebPlanningBoardMasterTripWithIdType | undefined;

    tripsBeforeSelectedItem?.forEach((masterTrip) => {
      if (!closestTrip) {
        closestTrip = masterTrip;
      } else if (
        masterTrip.trip?.tripEnd &&
        closestTrip.trip?.tripEnd &&
        new Date(masterTrip.trip.tripEnd) >= new Date(closestTrip.trip.tripEnd)
      ) {
        closestTrip = masterTrip;
      }
    });

    const closestTripStops = closestTrip?.trip?.stops;

    const lastSeenSite =
      closestTripStops && closestTripStops.length > 0
        ? closestTripStops[closestTripStops.length - 1].node
        : null;

    return {
      ...PLANNING_BOARD_BOOKING_INITIAL_VALUES,
      startNode: lastSeenSite
        ? {value: lastSeenSite.id, label: lastSeenSite.name}
        : {value: undefined, label: undefined},
      appointmentNode: lastSeenSite
        ? {value: lastSeenSite.id, label: lastSeenSite.name}
        : {value: undefined, label: undefined},
      endNode: lastSeenSite
        ? {value: lastSeenSite.id, label: lastSeenSite.name}
        : {value: undefined, label: undefined},
      vehicle: vehicle
        ? {
            value: vehicle.id,
            label: `${vehicle.registrationNumber} - ${vehicle.fleetNumber}`,
          }
        : {value: undefined, label: undefined},
      startDate: selectedGanttItem?.start
        ? new Date(selectedGanttItem.start)
        : new Date(),
      endDate: selectedGanttItem?.start
        ? new Date(selectedGanttItem.start)
        : new Date(),
    };
  }, [booking, getMasterTripsWithSameVehicle, getVehicle, selectedGanttItem]);

  const handleSubmit = React.useCallback<
    TOnFormikSubmit<IPlanningBoardBookingForm>
  >(
    async (values, helpers) => {
      setSubmitting(true);
      try {
        const startNodeId = values.startNode.value;
        const appointmentNodeId = values.appointmentNode.value;
        const endNodeId = values.endNode.value;
        const bookingTypeId = values.bookingType.value;
        const vehicleId = values.vehicle.value;

        if (
          startNodeId &&
          appointmentNodeId &&
          endNodeId &&
          bookingTypeId &&
          vehicleId
        ) {
          const body: BookingPost = {
            number: values.number,
            customerReferenceNumber: values.customerReferenceNumber,
            appointment: {
              startNodeId,
              appointmentNodeId,
              endNodeId,
              bookingTypeId,
              vehicleId,
              startDate: values.startDate,
              endDate: values.endDate,
            },
            overrideErrors: true,
          };
          if (selectedGanttItem?.data === null) {
            await bookingApi.apiBookingPost({body});
            onClearSelectedGanttItem();
            helpers.resetForm();
            notify('success', 'Created booking');
            await loadMasterTrips();
          } else if (booking?.id) {
            await bookingApi.apiBookingBookingIdPatch({
              bookingId: booking.id,
              body,
            });
            onClearSelectedGanttItem();
            helpers.resetForm();
            notify('success', 'Updated booking');
            await loadMasterTrips();
          }
        }
      } catch (e) {
        notify('error', 'Failed to create booking');
      } finally {
        setSubmitting(false);
        helpers.setSubmitting(false);
      }
    },
    [
      booking?.id,
      loadMasterTrips,
      notify,
      onClearSelectedGanttItem,
      selectedGanttItem?.data,
    ]
  );

  return {booking, initialValues, onSubmit: handleSubmit, submitting};
};

export type usePlanningBoardBookingResponse = ReturnType<
  typeof usePlanningBoardBooking
>;

export const usePlanningBoardBookingResponseInitial: usePlanningBoardBookingResponse =
  {
    booking: undefined,
    initialValues: PLANNING_BOARD_BOOKING_INITIAL_VALUES,
    onSubmit: () => {},
    submitting: false,
  };
