export interface IInsertIntoArrayOptions {
  useReduce?: boolean;
  useSplice?: boolean;
}

export const insertIntoArray = <T = any>(
  arr: T[],
  item: T,
  index: number,
  options?: IInsertIntoArrayOptions | undefined
): T[] => {
  if (options?.useReduce) {
    arr.reduce((prevArray: T[], currentItem, currentIndex) => {
      currentIndex === index
        ? prevArray.push(item, currentItem)
        : prevArray.push(currentItem);
      return prevArray ?? [];
    }, []);
  } else if (!options || options.useSplice) {
    arr.splice(index, 0, item);
    return arr ?? [];
  }
  return arr;
};
