import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

export const drawerWidth = 480;

const RightDrawerStyles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: '0 0 auto',
      overflow: 'hidden',
      marginRight: -drawerWidth,
      width: drawerWidth,
      flexShrink: 0,
    },
    paper: {
      display: 'flex',
      flex: '0 0 auto',
      overflow: 'hidden',
      width: drawerWidth,
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
  });

export const useRightDrawerStyles = makeStyles(RightDrawerStyles, {
  name: 'RightDrawerStyles',
});
