import React from 'react';
import {TextFieldProps} from '@mui/material';
import {FormikPickerField} from '../../../../formik/formikPickers/FormikPickerField';
import {
  MobileDateTimePicker,
  MobileDateTimePickerProps,
} from '@mui/x-date-pickers';
import {Moment} from 'moment';

interface TripEditStopDateTimePickerProps
  extends Omit<
    MobileDateTimePickerProps<Date, Date>,
    'value' | 'onChange' | 'renderInput'
  > {
  id?: string;
  name: string;
  fullWidth?: boolean;
  disableDefaultValue?: boolean;
  defaultValue?: Moment | null;
  staticMode?: boolean;
  format?: string;
}

export const TripEditStopDateTimePicker: React.FC<
  TripEditStopDateTimePickerProps
> = ({name, disableDefaultValue, disabled, defaultValue, ...props}) => {
  const [value, setValue] = React.useState<Date | null>(
    defaultValue?.toDate() ?? null
  );

  React.useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue.toDate());
    }
  }, [defaultValue]);

  return (
    <MobileDateTimePicker
      {...props}
      disabled={disabled}
      ampm={false}
      value={value}
      onChange={setValue}
      renderInput={TripEditStopDateTimePickerField}
    />
  );
};

export const TripEditStopDateTimePickerField = (
  props: TextFieldProps
): React.ReactElement => {
  return (
    <FormikPickerField
      {...props}
      name="departureTime"
      displayError={undefined}
    />
  );
};
