import React from 'react';
import {useEventCardStyles} from './EventCard.style';
import {ListItem, ListItemIcon, ListItemText} from '@mui/material';

interface EventCardListItemProps {
  Icon: React.ReactNode;
  primary: React.ReactNode;
  secondary?: React.ReactNode;
}

export const EventCardListItem: React.FC<EventCardListItemProps> = ({
  Icon,
  primary,
  secondary,
}) => {
  const classes = useEventCardStyles();
  return (
    <ListItem classes={{root: classes.listItem}} disableGutters dense>
      <ListItemIcon classes={{root: classes.listItemIcon}}>{Icon}</ListItemIcon>
      <ListItemText
        className={classes.listItemText}
        primary={primary}
        secondary={secondary}
      />
    </ListItem>
  );
};
