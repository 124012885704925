import ConfigService from './ConfigService/ConfigService';
import {encodeUrl, flatten} from './Util';
import ResponseService from './ResponseService';
import {authStore} from '../store';

const serverUrl = ConfigService.serverUri;
const queryUrl = `${serverUrl}/api/order_line`;

const OrderLineService = {
  getByUuid: async (uuid) => {
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    const response = await fetch(`${queryUrl}/public/${uuid}`, {
      method: 'GET',
      mode: 'cors',
      headers,
    });
    return OrderLineService.normalize(
      await ResponseService.checkStatus(response)
    );
  },
  query: async (params) => {
    Object.keys(params).forEach(
      // eslint-disable-next-line no-param-reassign
      (key) => params[key] == null && delete params[key]
    );
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(encodeUrl(queryUrl, params), {
      method: 'GET',
      mode: 'cors',
      headers,
    });
    return OrderLineService.normalize(
      await ResponseService.checkStatus(response)
    );
  },
  update: async (orderLine) => {
    // eslint-disable-next-line no-param-reassign
    if (!orderLine.id) {
      return OrderLineService.create(orderLine);
    }
    Object.keys(orderLine).forEach(
      // eslint-disable-next-line no-param-reassign
      (key) => orderLine[key] == null && delete orderLine[key]
    );
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(`${queryUrl}/${orderLine.id}`, {
      method: 'PATCH',
      mode: 'cors',
      body: JSON.stringify(orderLine),
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  create: async (orderLine) => {
    Object.keys(orderLine).forEach(
      // eslint-disable-next-line no-param-reassign
      (key) => orderLine[key] == null && delete orderLine[key]
    );
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(`${queryUrl}`, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({...orderLine}),
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  normalize: async (response) => {
    const result = flatten(await response.data);
    return result;
  },
  getById: async (orderLineId) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(`${queryUrl}/${orderLineId}`, {
      method: 'GET',
      mode: 'cors',
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  delete: async (orderLineId) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(`${queryUrl}/${orderLineId}`, {
      method: 'DELETE',
      mode: 'cors',
      headers,
    });
    return ResponseService.checkStatus(response);
  },
};

export default OrderLineService;
