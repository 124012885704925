import React from 'react';
import {useTripContext} from '../tripContext';
import {TripEventList} from './tripEventList';

export const TripCriticalEventList: React.FC = () => {
  const {criticalEvents, loadingCriticalEvents} = useTripContext();

  return (
    <TripEventList
      type="Critical"
      list={criticalEvents ?? []}
      loading={loadingCriticalEvents}
    />
  );
};
