import {makeStyles, createStyles} from '@mui/styles';

const SiteDetailsMapStyles = () =>
  createStyles({
    container: {height: '100%', width: '100%', paddingBottom: 15},
  });

export const useSiteDetailsMapStyles = makeStyles(SiteDetailsMapStyles, {
  name: 'SiteDetailsMapStyles',
});
