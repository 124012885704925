import React from 'react';
import Card from '@mui/material/Card';
import {PlanningSolution} from '@onroadvantage/onroadvantage-api';
import Map from '../../../map/map';
import {Loader} from '../../../loader';

interface Props {
  loading: boolean;
  externalLayers: any[];
  planningSolution: PlanningSolution;
}

const PlanningTripsRoutesMap = ({
  externalLayers,
  loading,
  planningSolution,
}: Props) => {
  return (
    <Card style={{width: '100%', height: '70vh'}}>
      {loading || !externalLayers ? (
        <div
          style={{
            height: '60vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Loader />
        </div>
      ) : (
        <Map
          // @ts-expect-error upgrade
          externalLayers={externalLayers}
          planningSolution={planningSolution}
          // TODO add ability to drag a route
        />
      )}
    </Card>
  );
};

export default PlanningTripsRoutesMap;
