import React from 'react';
import {useHistory} from 'react-router-dom';
import {
  ApiDocumentTemplateGetRequest,
  DocumentTemplate,
  DocumentTemplateList,
  DocumentTemplatePost,
} from '@onroadvantage/onroadvantage-api';
import {FormikHelpers, FormikProps} from 'formik/dist/types';
import {useAppNotifications} from '../../contexts';
import {documentTemplateApi} from '../../api';
import {
  TemplateTableContextProps,
  TLoadList,
  useTemplateTable,
} from '../../factory/template';
import {IDocumentTemplateForm} from './DocumentTemplateForm';

export interface IDocumentTemplateWithTemplateHtml extends DocumentTemplate {
  templateHtml?: string | null;
}

export interface DocumentTemplateContextProps
  extends TemplateTableContextProps<DocumentTemplate, DocumentTemplateList> {
  onDetailsFormSubmit: (
    values: IDocumentTemplateForm,
    formikHelpers: FormikHelpers<IDocumentTemplateForm>
  ) => void;
  setDocumentTemplateId: (value: number | undefined) => void;
  submitting: boolean;
  documentTemplate?: IDocumentTemplateWithTemplateHtml;
  documentTemplateId?: number;
  detailsRef?: React.Ref<FormikProps<IDocumentTemplateForm>>;
}

export const DocumentTemplateContext =
  React.createContext<DocumentTemplateContextProps>({
    // Template Table Defaults
    loading: false,
    list: [],
    currentPage: 1,
    // DocumentTemplate
    loadList: async () => {},
    onDetailsFormSubmit: () => null,
    setDocumentTemplateId: () => null,
    submitting: false,
  });

interface DocumentTemplateContextProviderProps {
  documentTemplateId?: number;
}

export const DocumentTemplateContextProvider: React.FC<
  DocumentTemplateContextProviderProps
> = ({children}) => {
  const history = useHistory();
  const notify = useAppNotifications();
  // Template Table
  const [
    {
      // States
      currentPage,
      filters,
      itemTotal,
      hasPermission,
      list,
      loading,
      pageSize,
      pageTotal,
      loadingSingleItem,
      sorting,
    },
    {
      // Getters
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
      setLoadingSingleItem,
    },
  ] = useTemplateTable<DocumentTemplate, ApiDocumentTemplateGetRequest>({
    editPermission: 'Edit DocumentTemplate',
    addPermission: 'Add DocumentTemplate',
    deletePermission: 'Delete DocumentTemplate',
    downloadPermission: 'DocumentTemplate ListDownload',
    viewPermission: 'DocumentTemplate List',
  });

  const loadList = React.useCallback<TLoadList<DocumentTemplateList>>(
    async (options) => {
      setLoading(true);
      try {
        const requestObj = getRequestObj(['name', 'description'], options);
        const response = await documentTemplateApi.apiDocumentTemplateGet(
          requestObj
        );
        return getResponse(response, options);
      } catch (e) {
        notify('error', e.message ?? 'Failed to load document template list');
      } finally {
        setLoading(false);
      }
    },
    [getRequestObj, getResponse, notify, setLoading]
  );

  const handleDelete = React.useCallback(
    async (row: DocumentTemplate) => {
      setLoading(true);
      try {
        if (row.id) {
          await documentTemplateApi.apiDocumentTemplateDocumentTemplateIdDelete(
            {
              documentTemplateId: row.id,
            }
          );
          await loadList();
          notify('success', 'Deleted document template');
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to delete');
      } finally {
        setLoading(false);
      }
    },
    [setLoading, loadList, notify]
  );

  const handleAdd = React.useCallback(
    () => history.push('/documenttemplatelist/add'),
    [history]
  );

  const handleNavigate = React.useCallback(
    (row) => history.push(`/documenttemplatelist/${row.id}`),
    [history]
  );
  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  // Forms
  const [documentTemplate, setDocumentTemplate] = React.useState<
    IDocumentTemplateWithTemplateHtml | undefined
  >();
  const [documentTemplateId, setDocumentTemplateId] = React.useState<
    number | undefined
  >();
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const detailsRef = React.useRef<FormikProps<IDocumentTemplateForm>>(null);

  const loadDocumentTemplate = React.useCallback(async () => {
    setLoadingSingleItem(true);
    try {
      if (documentTemplateId) {
        const response =
          await documentTemplateApi.apiDocumentTemplateDocumentTemplateIdGet({
            documentTemplateId,
          });
        const templateHtmlResponse =
          await documentTemplateApi.apiDocumentTemplateDocumentTemplateIdRawGet(
            {
              documentTemplateId,
            }
          );
        setDocumentTemplate({...response, ...templateHtmlResponse});
      } else {
        setDocumentTemplate(undefined);
      }
    } catch (e) {
      notify('error', e.message ?? 'Failed to load document template');
    } finally {
      setLoadingSingleItem(false);
    }
  }, [setLoadingSingleItem, notify, documentTemplateId]);

  const handleDetailsFormSubmit = React.useCallback(
    async (
      values: IDocumentTemplateForm,
      formikHelpers: FormikHelpers<IDocumentTemplateForm>
    ) => {
      setSubmitting(true);
      try {
        const {contract, customer, documentType, ...otherValues} = values;
        if (documentType.label) {
          const newValues: DocumentTemplatePost = {
            ...otherValues,
            contractId: contract.value,
            customerId: customer.value,
            documentType: documentType.label,
          };

          if (documentTemplateId) {
            await documentTemplateApi.apiDocumentTemplateDocumentTemplateIdPatch(
              {
                documentTemplateId,
                body: newValues,
              }
            );
          } else {
            await documentTemplateApi.apiDocumentTemplatePost({
              body: newValues,
            });
          }
          history.push('/documenttemplatelist');
          notify(
            'success',
            `${documentTemplateId ? 'Updated' : 'Added'} Document Template`
          );
        }
      } catch (e) {
        notify(
          'error',
          e.message ??
            `Failed to ${
              documentTemplateId ? 'update' : 'add'
            } document template`
        );
      } finally {
        formikHelpers.setSubmitting(false);
        setSubmitting(false);
      }
    },
    [history, notify, setSubmitting, documentTemplateId]
  );

  const value: DocumentTemplateContextProps = {
    // Template Table
    list,
    loadList,
    hasPermission,
    loading: loading || loadingSingleItem,
    cleanupList,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onAdd: hasPermission.add ? handleAdd : undefined,
    onNavigate: handleNavigate,
    onDelete: hasPermission.delete ? handleDelete : undefined,
    onRefresh: handleRefresh,
    // Forms
    onDetailsFormSubmit: handleDetailsFormSubmit,
    setDocumentTemplateId,
    submitting,
    documentTemplate,
    documentTemplateId,
    detailsRef,
  };

  React.useEffect(() => {
    loadDocumentTemplate();
    return () => setDocumentTemplate(undefined);
  }, [loadDocumentTemplate]);

  return (
    <DocumentTemplateContext.Provider value={value}>
      {children}
    </DocumentTemplateContext.Provider>
  );
};
