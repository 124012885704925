import React from 'react';

import PopupBase from './PopupBase';
import {Box, Typography, Divider} from '@mui/material';
import custImg from '../../../../img/background/popupbackground.png';
import truck from '../../../../img/svg/truck.svg';
import {createStyles, makeStyles} from '@mui/styles';

import depot from '../../../../img/svg/depot.svg';
import warehouse from '../../../../img/svg/warehouse.svg';

import reststopbackground from '../../../../img/background/reststopbackground.png';

import fuelpump from '../../../../img/svg/fuelpump.svg';
import gaspipe from '../../../../img/svg/gaspipe.svg';

import tools from '../../../../img/svg/tools.svg';
import truckwitharrow from '../../../../img/svg/truckwitharrow.svg';
import dangerzone from '../../../../img/background/dangerzonebackground.jpg';
import MomentService from '../../../../service/MomentService';

const useStopPopupStyles = makeStyles(() =>
  createStyles({
    headingColor: {
      color: '#767676',
    },
  })
);

interface Props {
  stop: any;
}

export default function StopPopup(props: Props) {
  const {stop} = props;

  const classes = useStopPopupStyles();
  const [icon, setIcon] = React.useState(truck);
  const [background, setBackground] = React.useState(custImg);

  React.useEffect(() => {
    switch ((stop.node.type as string).toLowerCase()) {
      case 'offloading':
        setIcon(warehouse);
        break;
      case 'loading':
        setIcon(warehouse);
        break;
      case 'fueling':
        setIcon(gaspipe);
        break;
      case 'depot':
        setIcon(depot);
        break;
      case 'rest':
        setBackground(reststopbackground);
        setIcon('');
        break;
      case 'danger zone':
        setBackground(dangerzone);
        setIcon('');
        break;
      case 'workshop':
        setIcon(tools);
        break;
      case 'weighbridge':
        setIcon(truckwitharrow);
        break;
      case 'feulstation':
        setIcon(fuelpump);
        break;
    }
  }, [stop.node.type]);

  return (
    <PopupBase
      backgroundImage={background}
      mainIcon={icon}
      topIconType="default"
      center={{header: 'Type', data: stop.node.type, type: 'text'}}
    >
      <Box mb={1}>
        <Typography variant="h5" className={classes.headingColor}>
          Site
        </Typography>
        <Typography variant="h6" style={{fontWeight: 700}}>
          {stop.node.name}
        </Typography>
      </Box>
      <Divider />
      <Box mb={1}>
        <Typography variant="h4">Information</Typography>
      </Box>
      <Box mb={1}>
        <Typography variant="h5" className={classes.headingColor}>
          Open Times
        </Typography>
        <Typography variant="h6" style={{fontWeight: 700}}>
          {stop.node.timeWindows && stop.node.timeWindows.length > 0
            ? MomentService.localiseTimeOnly(stop.node.timeWindows[0].openTime)
            : 'None'}
          -
          {stop.node.timeWindows && stop.node.timeWindows.length > 0
            ? MomentService.localiseTimeOnly(stop.node.timeWindows[0].closeTime)
            : 'None'}
        </Typography>
      </Box>
      <Box mb={1}>
        <Typography variant="h5" className={classes.headingColor}>
          Processing Time
        </Typography>
        <Typography variant="h6" style={{fontWeight: 700}}>
          {stop.node.processingTimeInMinutes}
        </Typography>
      </Box>
      <Box mb={1}>
        <Typography variant="h5" className={classes.headingColor}>
          Loading Time
        </Typography>
        <Typography variant="h6" style={{fontWeight: 700}}>
          {stop.node.loadingTimeInMinutes}
        </Typography>
      </Box>
      <Box mb={1}>
        <Typography variant="h5" className={classes.headingColor}>
          Unloading Time
        </Typography>
        <Typography variant="h6" style={{fontWeight: 700}}>
          {stop.node.unloadingTimeInMinutes}
        </Typography>
      </Box>
    </PopupBase>
  );
}
