import React from 'react';
import {TableEditRow} from '@devexpress/dx-react-grid-material-ui';
import {MobileDateTimePicker} from '@mui/x-date-pickers';
import {InputAdornment, Checkbox, TextField} from '@mui/material';
import {AccessTime} from '@mui/icons-material';
import {useTableEditFieldStyles} from './TableEditField.style';
import {useInternalTableContext} from '../../TemplateTable';
import {TableAutoCompleteField} from './TableAutoCompleteField';
import {TableSelectField} from './TableSelectField';

export const TableEditField: React.FC<TableEditRow.CellProps> = ({
  children,
  ...props
}) => {
  const {errors, config, updateRowInitialValues} = useInternalTableContext();
  const classes = useTableEditFieldStyles();

  const columnConfig = React.useMemo(
    () =>
      config?.columns.find(
        (configColumn) => configColumn.name === props.column.name
      ),
    [config?.columns, props.column.name]
  );

  const [initialValue] = React.useState(
    columnConfig?.getFieldValue
      ? columnConfig.getFieldValue(props.row)
      : props.value
  );

  const fieldValue = React.useMemo(
    () =>
      columnConfig?.getFieldValue
        ? columnConfig.getFieldValue(props.row)
        : props.value,
    [columnConfig, props.row, props.value]
  );

  React.useEffect(() => {
    updateRowInitialValues(props.row.id, {[props.column.name]: initialValue});
  }, [props.column.name, props.row.id, initialValue, updateRowInitialValues]);

  const enableColumn = React.useMemo(() => {
    if (props.row.id) {
      if (columnConfig?.enableEditing !== undefined) {
        if (typeof columnConfig.enableEditing === 'function') {
          return columnConfig.enableEditing(props.row);
        } else {
          return columnConfig.enableEditing;
        }
      }
    } else if (columnConfig?.enableInlineAdding !== undefined) {
      if (typeof columnConfig.enableInlineAdding === 'function') {
        return columnConfig.enableInlineAdding(props.row);
      } else {
        return columnConfig.enableInlineAdding;
      }
    }
    return true;
  }, [columnConfig, props.row]);

  const cellError = React.useMemo(
    () =>
      errors.find(
        (error) =>
          error.name === props.column.name &&
          props.row.id ===
            (typeof error.id === 'string' ? parseInt(error.id) : error.id)
      ),
    [errors, props.column.name, props.row.id]
  );

  return (
    <TableEditRow.Cell {...props}>
      <div className={classes.root}>
        {props.column.name !== 'actions' ? (
          columnConfig?.enableAutocomplete ? (
            <TableAutoCompleteField {...props} />
          ) : columnConfig?.enableSelect ? (
            <TableSelectField {...props} />
          ) : columnConfig?.type === 'datetime' ? (
            <MobileDateTimePicker
              disabled={!enableColumn}
              ampm={false}
              value={fieldValue}
              onChange={(newDate) => props.onValueChange(newDate)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  name={props.column.name}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        className={classes.timeIcon}
                        position="end"
                      >
                        <AccessTime />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          ) : columnConfig?.type === 'bool' ? (
            <Checkbox
              style={{padding: 0}}
              size="small"
              checked={fieldValue}
              disabled={!columnConfig?.enableEditing}
              onChange={(event, checked) => {
                props.onValueChange(checked);
              }}
            />
          ) : (
            <TextField
              id={props.column.name.concat(props.row.id)}
              disabled={!enableColumn}
              type={
                config.columns.find(
                  (configColumn) => configColumn.name === props.column.name
                )?.type === 'datetime'
                  ? 'datetime-local'
                  : 'string'
              }
              name={props.column.name}
              onChange={(e) => props.onValueChange(e.target.value)}
              defaultValue={typeof fieldValue === 'object' ? 'NA' : fieldValue}
              size="small"
              placeholder={
                cellError?.message ? `*${cellError.message}` : undefined
              }
              error={cellError !== undefined}
              fullWidth
            />
          )
        ) : (
          children
        )}
      </div>
    </TableEditRow.Cell>
  );
};
