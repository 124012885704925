import {
  ApiWebEventGetEventModelsEnum,
  ApiWebEventGetRequest,
} from '@onroadvantage/onroadvantage-api';
import {IEventsPaneSearchForm} from '../eventsPaneSearch/EventsPaneSearchForm';
import {getDateRange} from './getDateRange';
import {TEventPaneTabValues} from '../EventsPaneContext';
import {parsePotentialString} from '../../../factory/template';

interface IGetWebEventRequestObjParams {
  values: IEventsPaneSearchForm | undefined;
  tabValue: TEventPaneTabValues;
}

export const getWebEventRequestObj = ({
  values,
  tabValue,
}: IGetWebEventRequestObjParams) => {
  if (!values) return {};

  let requestObj: ApiWebEventGetRequest | undefined;

  if (values.isAdvanced || tabValue === 'Closed') {
    const {
      contract,
      driver,
      enableCriticalSearch,
      enableOperationalSearch,
      eventType,
      dateRangeValue,
      dateRangeUnit,
      vehicle,
      vehicleGroup,
      eventId,
    } = values;

    const dateRange = getDateRange(dateRangeValue, dateRangeUnit);

    const criticalEventTypeListings = eventType?.filter(
      (eventType) => eventType.metaData === 'CriticalEventType'
    );

    const operationalEventTypeListings = eventType?.filter(
      (eventType) => eventType.metaData === 'OperationalEventType'
    );

    const criticalEventTypeIds =
      criticalEventTypeListings?.map(({value}) => value as number) ?? undefined;

    const operationalEventTypeIds =
      operationalEventTypeListings?.map(({value}) => value as number) ??
      undefined;

    const criticalEventId =
      enableCriticalSearch && !enableOperationalSearch && eventId
        ? parsePotentialString(eventId)
        : undefined;

    const operationalEventId =
      !enableCriticalSearch && enableOperationalSearch && eventId
        ? parsePotentialString(eventId)
        : undefined;
    const vehicleIds = vehicle?.map(({value}) => value as number) ?? undefined;
    const vehicleGroupIds =
      vehicleGroup?.map(({value}) => value as number) ?? undefined;
    const driverIds = driver?.map(({value}) => value as number) ?? undefined;
    const contractIds =
      contract?.map(({value}) => value as number) ?? undefined;

    const status: string[] = [];

    if (tabValue === 'Open') status.push('Open');
    else if (tabValue === 'Closed')
      status.push('Closed', 'BulkClosed', 'MutedClosed');

    requestObj = {
      contractIds:
        contractIds && contractIds.length > 0 ? contractIds : undefined,
      criticalEventId,
      criticalEventTypeIds:
        criticalEventTypeIds && criticalEventTypeIds.length > 0
          ? criticalEventTypeIds
          : undefined,
      driverIds: driverIds && driverIds.length > 0 ? driverIds : undefined,
      eventModels: (enableCriticalSearch && enableOperationalSearch
        ? undefined
        : enableCriticalSearch
        ? 'CriticalEvent'
        : enableOperationalSearch
        ? 'OperationalEvent'
        : undefined) as unknown as ApiWebEventGetEventModelsEnum,
      operationalEventId,
      operationalEventTypeIds:
        operationalEventTypeIds && operationalEventTypeIds.length > 0
          ? operationalEventTypeIds
          : undefined,
      endDate: dateRange.endDate ?? undefined,
      startDate: dateRange.startDate ?? undefined,
      status,
      vehicleIds: vehicleIds && vehicleIds.length > 0 ? vehicleIds : undefined,
      vehicleGroupIds:
        vehicleGroupIds && vehicleGroupIds.length > 0
          ? vehicleGroupIds
          : undefined,
    };
  } else {
    const {enableCriticalSearch, enableOperationalSearch, search} = values;
    const status: string[] = [];

    if (tabValue === 'Open') status.push('Open');
    else if (tabValue === 'Closed')
      status.push('Closed', 'BulkClosed', 'MutedClosed');

    requestObj = {
      eventModels: (enableCriticalSearch && enableOperationalSearch
        ? undefined
        : enableCriticalSearch
        ? 'CriticalEvent'
        : enableOperationalSearch
        ? 'OperationalEvent'
        : undefined) as unknown as ApiWebEventGetEventModelsEnum,
      filter: search,
      status,
    };
  }

  return requestObj;
};
