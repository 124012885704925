import React from 'react';
import {usePlanningBoardTripFormStyles} from './PlanningBoardTripForm.style';
import {FormikDateTimePicker} from '../../../../formik';
import {IPlanningBoardTripPanelForm} from './PlanningBoardTripForm';

interface IPlanningBoardTripDateTimePickerRadioOptions {
  label: string;
  value: IPlanningBoardTripPanelForm['selectedTimeOption'];
}

export const planningBoardTripDateTimePickerRadioOptions: IPlanningBoardTripDateTimePickerRadioOptions[] =
  [
    {label: 'Trip Start', value: 'tripStartTime'},
    {label: 'Loading Start', value: 'loadingArrivalTime'},
    {label: 'Offloading Start', value: 'offloadingArrivalTime'},
    {
      label: 'Loading and Offloading Start',
      value: 'loadingOffloadingArrivalTime',
    },
  ];

interface PlanningBoardTripDateTimePickerProps {
  name: IPlanningBoardTripPanelForm['selectedTimeOption'];
  disabled?: boolean;
}

export const PlanningBoardTripFormDateTimePicker: React.FC<
  PlanningBoardTripDateTimePickerProps
> = ({name, disabled}) => {
  const classes = usePlanningBoardTripFormStyles();

  if (!name) return null;

  return (
    <FormikDateTimePicker
      name={name}
      label={
        planningBoardTripDateTimePickerRadioOptions.find(
          ({value}) => value === name
        )?.label
      }
      className={classes.selectTimePicker}
      disabled={disabled}
      fullWidth
    />
  );
};
