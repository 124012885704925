import {observable} from 'mobx';
import {
  PlanningOrder as PlanningOrderType,
  PlanningSolution as PlanningSolutionType,
} from '@onroadvantage/onroadvantage-api';
import {planningSolutionStoreContext} from './PlanningSolution.context';
import {useStoreData} from '../helpers';
import {ILaneCard} from '../../../components/board';
import {Listing} from '@onroadvantage/onroadvantage-api';

export type TFilterValue = string;

export interface IListData {
  label: string;
  value: string;
}

export interface IGroupData {
  all: Array<ILaneCard>;
  allocated: Array<ILaneCard>;
  available: Array<ILaneCard>;
}

const defaultGroupData = {all: [], allocated: [], available: []};

export const planningSolutionStore = () => {
  const store = {
    /** Stores planning solution */
    bPlanningSolution: observable.box<PlanningSolutionType | null>(null),
    get planningSolution() {
      return this.bPlanningSolution.get();
    },
    setPlanningSolution: (value: PlanningSolutionType | null) =>
      store.bPlanningSolution.set(value),
    /* drivers */
    bAllDrivers: observable.box<ILaneCard[]>([]),
    bAllocatedDrivers: observable.box<ILaneCard[]>([]),
    bAvailableDrivers: observable.box<ILaneCard[]>([]),

    // gets
    get allDrivers() {
      return this.bAllDrivers.get();
    },
    get allocatedDrivers() {
      return this.bAllocatedDrivers.get();
    },
    get availableDrivers() {
      return this.bAvailableDrivers.get();
    },
    get drivers(): IGroupData {
      return {
        all: this.bAllDrivers.get(),
        allocated: this.bAllDrivers.get(),
        available: this.bAvailableDrivers.get(),
      };
    },

    // sets
    setAllDrivers: (value: Array<ILaneCard>) => {
      store.bAllDrivers.set(value);
    },
    setAllocatedDrivers: (value: Array<ILaneCard>) => {
      store.bAllocatedDrivers.set(value);
    },
    setAvailableDrivers: (value: Array<ILaneCard>) => {
      store.bAvailableDrivers.set(value);
    },
    setDrivers: (value: IGroupData) => {
      store.bAllDrivers.set(value.all);
      store.bAllocatedDrivers.set(value.allocated);
      store.bAvailableDrivers.set(value.available);
    },

    /* planningOrders */
    bRawOrderListings: observable.box<Array<Listing>>([]),
    get rawOrderListings() {
      return this.bRawOrderListings.get();
    },
    setRawOrderListings: (value: Array<Listing>) =>
      store.bRawOrderListings.set(value),
    bAllocatedOrderIds: observable.box<Array<number>>([]),
    get allocatedOrderIds() {
      return this.bAllocatedOrderIds.get();
    },
    setAllocatedOrderIds: (value: Array<number>) =>
      store.bAllocatedOrderIds.set(value),
    // bAssignedOrderIds: observable.box<Array<number>>([]),
    // get assignedOrderIds() {
    //   return this.bAssignedOrderIds.get();
    // },
    // setAssignedOrderIds: (value: Array<number>) => {
    //   store.bAssignedOrderIds.set(value)
    // },
    bUnassignedOrderIds: observable.box<Array<number>>([]),
    get unassignedOrderIds() {
      return this.bUnassignedOrderIds.get();
    },
    setUnassignedOrderIds: (value: Array<number>) => {
      store.bUnassignedOrderIds.set(value);
    },
    bPlanningOrders: observable.box<Array<PlanningOrderType>>([]),
    get planningOrders() {
      return this.bPlanningOrders.get();
    },
    setPlanningOrders: (value: Array<PlanningOrderType>) =>
      store.bPlanningOrders.set(value),
    allOrders: observable.box<ILaneCard[]>([]),
    allocatedOrders: observable.box<ILaneCard[]>([]),
    availableOrders: observable.box<ILaneCard[]>([]),

    /* vehicles */
    bVehicles: observable.box<IGroupData>({...defaultGroupData}),
    get vehicles() {
      return this.bVehicles.get();
    },
    setVehicles: (value: IGroupData) => store.bVehicles.set(value),
    // TODO check to implement "observable.deep"
    allVehicles: observable.box<ILaneCard[]>([]),
    allocatedVehicles: observable.box<ILaneCard[]>([]),
    availableVehicles: observable.box<ILaneCard[]>([]),

    bRawVehicleListings: observable.box<Array<Listing>>([]),
    get rawVehicleListings() {
      return this.bRawVehicleListings.get();
    },
    setRawVehicleListings: (value: Array<Listing>) =>
      store.bRawVehicleListings.set(value),

    /* unassigned planningOrders */
    unassignedOrdersAvailable: observable.box<ILaneCard[]>([]),

    /* filters */

    // contracts
    bRawContractListings: observable.box<Array<Listing>>([]),
    get rawContractListings() {
      return this.bRawContractListings.get();
    },
    setRawContractListings: (value: Array<Listing>) =>
      store.bRawContractListings.set(value),

    // drivers
    allocatedDriversFilter: observable.box<TFilterValue>(),
    availableDriversFilter: observable.box<TFilterValue>(),

    bRawDriverListings: observable.box<Array<Listing>>([]),
    get rawDriverListings() {
      return this.bRawDriverListings.get();
    },
    setRawDriverListings: (value: Array<Listing>) =>
      store.bRawDriverListings.set(value),

    // planningOrders
    availableOrdersFilter: observable.box<TFilterValue>(),
    allocatedOrdersFilter: observable.box<TFilterValue>(),

    // vehicles
    availableVehiclesFilter: observable.box<TFilterValue>(),
    allocatedVehiclesFilter: observable.box<TFilterValue>(),

    /* listing */
    contractList: observable.box<IListData[]>(),
    contractListFilter: observable.box<TFilterValue>(),

    /* all listings */
    setAllListings({
      contracts,
      drivers,
      orders,
      vehicles,
    }: {
      contracts: Array<Listing>;
      drivers: Array<Listing>;
      orders: Array<Listing>;
      vehicles: Array<Listing>;
    }) {
      this.bRawContractListings.set(contracts);
      this.bRawDriverListings.set(drivers);
      this.bRawOrderListings.set(orders);
      this.bRawVehicleListings.set(vehicles);

      // TODO this is a messy fix and might need to be reworked...
      //  this is to avoid a race condition as all 4 values don't update at once
      //  each individual set call causes a rerender
      this.bInitialLoaded.set(true);
    },
    bInitialLoaded: observable.box<boolean>(false),
    get initialLoaded() {
      return this.bInitialLoaded.get();
    },
  };
  return store;
};
export type TPlanningSolutionStore = ReturnType<typeof planningSolutionStore>;

export function usePlanningSolutionData<Selection>(
  dataSelector: (store: TPlanningSolutionStore) => Selection
) {
  return useStoreData<Selection, any, any>(
    planningSolutionStoreContext,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    (contextData) => contextData!,
    dataSelector
  );
}
