import React from 'react';
import {Plugin, Template, TemplatePlaceholder} from '@devexpress/dx-react-core';
import Loader from '../../../../../components/loader/Loader';

export interface TableToolbarActivityIndicatorProps {
  loading?: boolean;
}

/** adds a loading indicator to the DevExpress Toolbar */
export class TableToolbarActivityIndicator extends React.PureComponent<TableToolbarActivityIndicatorProps> {
  pluginDependencies = [{name: 'Toolbar'}];

  render() {
    const {loading} = this.props;
    return (
      <Plugin dependencies={this.pluginDependencies} name="ToolbarFilter">
        <Template name="toolbarContent">
          <TemplatePlaceholder />
          <div>{loading ? <Loader size={32} /> : null}</div>
        </Template>
      </Plugin>
    );
  }
}
