import React from 'react';
import {PermissionForm} from './PermissionForm';
import {ITemplateTabControllerTab, TemplateTabs} from '../../factory/template';
import {PermissionContext} from './PermissionContext';

export const Permission: React.FC = () => {
  const {permissionId, loading} = React.useContext(PermissionContext);

  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {title: 'Details', element: <PermissionForm />},
  ]);

  const path = React.useMemo(
    () => `/permissionlist/${permissionId}`,
    [permissionId]
  );

  return <TemplateTabs tabs={tabs} path={path} loading={loading} />;
};
