import React from 'react';
import {
  ITemplateTabControllerTab,
  TemplateTabs,
} from '../../../factory/template';
import {NotificationConfigTaskContext} from './NotificationConfigTaskContext';
import {NotificationConfigTaskContractContacts} from './NotificationConfigTaskContractContacts';
import {NotificationConfigTaskCustomerContacts} from './NotificationConfigTaskCustomerContacts';
import {NotificationConfigTaskNodeContacts} from './NotificationConfigTaskNodeContacts';
import {NotificationConfigContext} from '../NotificationConfigContext';
import {NotificationConfigTaskVehicleGroupContacts} from '../notificationConfigTask';

export const NotificationConfigTask: React.FC = () => {
  const {notificationConfigId} = React.useContext(NotificationConfigContext);
  const {notificationConfigTaskId, loading} = React.useContext(
    NotificationConfigTaskContext
  );

  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {
      title: 'Contract Contact',
      element: <NotificationConfigTaskContractContacts />,
    },
    {
      title: 'Customer Contact',
      element: <NotificationConfigTaskCustomerContacts />,
    },
    {title: 'Node Contact', element: <NotificationConfigTaskNodeContacts />},
    {
      title: 'Vehicle Group Contact',
      element: <NotificationConfigTaskVehicleGroupContacts />,
    },
  ]);

  const path = React.useMemo(
    () =>
      `/notificationconfiglist/${notificationConfigId}/task/${notificationConfigTaskId}`,
    [notificationConfigId, notificationConfigTaskId]
  );

  return <TemplateTabs tabs={tabs} path={path} loading={loading} />;
};
