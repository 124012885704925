import React from 'react';
import {Node, PlanningMasterTrip} from '@onroadvantage/onroadvantage-api';
import {PolylineService, RouteService} from '../../../service';
import PlanningTripRouteMap from './PlanningTripRouteMap';
import {IFormTrip} from '../planningSolution/PlanningSolutionForm';
import {usePlanningSolutionData} from '../../../stores/context';
import {Loader} from '../../loader';
import Card from '@mui/material/Card';

interface Props {
  loadingNodes: any;
  offloadingNodes: any;
  depotNode: any;
  planningMasterTripFormik: IFormTrip;
  planningMasterTrip?: PlanningMasterTrip;
}

const PlanningTripRoute = ({
  loadingNodes,
  offloadingNodes,
  depotNode,
  planningMasterTrip,
}: Props) => {
  const [route, setRoute] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [externalLayers, setExternalLayers] = React.useState([]);
  const {planningSolution} = usePlanningSolutionData((store) => ({
    planningSolution: store.planningSolution,
  }));

  React.useEffect(() => {
    const waypoints: any[] = [];
    [depotNode, ...loadingNodes, ...offloadingNodes, depotNode].forEach(
      (node: Node) => {
        if (node) {
          waypoints.push(`${node.longitude},${node.latitude}`);
        }
      }
    );

    async function asyncUseEffectTrigger() {
      const waypointMapLayers = await PolylineService.getWaypointMapLayers({
        loadingNodes,
        offloadingNodes,
        depotNode,
      });

      // @ts-expect-error upgrade
      setExternalLayers([...waypointMapLayers]);
      if (planningMasterTrip && planningMasterTrip.trip) {
        const route = await RouteService.getByTripId(
          planningMasterTrip.trip.id
        );
        setRoute(route.data.geojson);
      }

      setLoading(false);
    }

    if (waypoints.length > 1 && planningMasterTrip && planningMasterTrip.trip) {
      setLoading(true);
      asyncUseEffectTrigger();
    } else {
      setLoading(false);
    }
  }, [loadingNodes, offloadingNodes, depotNode, planningMasterTrip]);

  return (
    <>
      {loading ? (
        <Card style={{width: '100%', height: '800px'}}>
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Loader />
          </div>
        </Card>
      ) : (
        planningSolution &&
        planningMasterTrip &&
        route && (
          <PlanningTripRouteMap route={route} externalLayers={externalLayers} />
        )
      )}
    </>
  );
};

export default PlanningTripRoute;
