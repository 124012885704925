import React from 'react';
import {ContractBroadcastTemplate} from '@onroadvantage/onroadvantage-api';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../../factory/template';
import {useContractContext} from '../contractContext';
import {
  ContractBroadcastTemplateListContext,
  useContractBroadcastTemplateListContext,
} from './ContractBroadcastTemplateListContext';

export const ContractBroadcastTemplateList: React.FC = () => {
  const {contract} = useContractContext();
  const {loadList, cleanupList} = useContractBroadcastTemplateListContext();

  const config: TemplateTableConfig<ContractBroadcastTemplate> = React.useMemo(
    () => ({
      columns: [
        {
          name: 'title',
          label: 'Title',
          type: 'string',
          enableEditing: true,
        },
        {
          name: 'message',
          label: 'Message',
          type: 'string',
          enableEditing: true,
        },
      ],
      enableSort: true,
      enableFilter: true,
      enableGrouping: false,
      deleteMessage: (row) => ({
        title: 'Contract Broadcast Template',
        items: {
          ContractCode: contract?.code,
          ContractName: contract?.name,
          Title: row.title,
          Message: row.message,
        },
      }),
      identifier: 'CONTRACT_BROADCAST_TEMPLATE_LIST',
    }),
    [contract?.code, contract?.name]
  );

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [cleanupList, loadList]);

  return (
    <TemplateTableWrapper
      config={config}
      context={ContractBroadcastTemplateListContext}
    />
  );
};
