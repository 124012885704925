import React from 'react';
import {
  ApiCriticalEventTypeGetRequest,
  CriticalEventType,
  CriticalEventTypeListResponse,
} from '@onroadvantage/onroadvantage-api';
import {useAppNotifications} from '../../../contexts';
import {criticalEventTypeApi} from '../../../api';
import {
  TemplateTableContextProps,
  TLoadList,
  TOnInlineAdd,
  TOnInlineEdit,
  useTemplateTable,
} from '../../../factory/template';
import {RoleService} from '../../../service';

export type CriticalEventTypeContextProps = TemplateTableContextProps<
  CriticalEventType,
  CriticalEventTypeListResponse
>;

export const CriticalEventTypeContext =
  React.createContext<CriticalEventTypeContextProps>({
    loading: false,
    list: [],
    currentPage: 1,
    loadList: async () => {},
  });

export const CriticalEventTypeContextProvider: React.FC = ({children}) => {
  const notify = useAppNotifications();
  // Template Table
  const [
    {
      // States
      currentPage,
      filters,
      hasPermission,
      itemTotal,
      list,
      loading,
      pageSize,
      pageTotal,
      sorting,
    },
    {
      // Getters
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
    },
  ] = useTemplateTable<CriticalEventType, ApiCriticalEventTypeGetRequest>({
    editPermission: 'Edit CriticalEventType',
    addPermission: 'Add CriticalEventType',
    deletePermission: 'Delete CriticalEventType',
    viewPermission: 'CriticalEventType List',
  });

  const loadList = React.useCallback<TLoadList<CriticalEventTypeListResponse>>(
    async (options) => {
      setLoading(true);
      try {
        const requestObj = getRequestObj(
          [
            'name',
            'telematicsConfigId',
            'externalReference',
            'integrationEnabled',
            'autoClose',
          ],
          options
        );
        const response = await criticalEventTypeApi.apiCriticalEventTypeGet(
          requestObj
        );
        return getResponse(response, options);
      } catch (e) {
        notify('error', 'Failed to load critical event type list');
      } finally {
        setLoading(false);
      }
    },
    [getRequestObj, getResponse, notify, setLoading]
  );

  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  const handleInlineEdit = React.useCallback<TOnInlineEdit>(
    async (savedChanges) => {
      setLoading(true);
      try {
        for (const change of savedChanges) {
          if (change.id) {
            const {contracts, ...newValues} = change.newValues;
            await criticalEventTypeApi.apiCriticalEventTypeCriticalEventTypeIdPatch(
              {
                criticalEventTypeId: parseInt(change.id),
                body: {...newValues},
              }
            );
          }
        }
        await loadList();
        notify('success', 'Updated admin critical event type');
      } catch (e) {
        notify(
          'error',
          e.message ?? 'Failed to update admin critical event type'
        );
      } finally {
        setLoading(false);
      }
    },
    [loadList, notify, setLoading]
  );

  const handleInlineAdd = React.useCallback<TOnInlineAdd>(
    async (changes) => {
      setLoading(true);
      try {
        let didCreate = false;
        for (const change of changes) {
          if ('name' in change && 'externalReference' in change) {
            const telematicsConfigId = change.telematicsConfig?.value;
            await criticalEventTypeApi.apiCriticalEventTypePost({
              body: {
                name: change.name,
                externalReference: change.externalReference,
                autoClose: !!change.autoClose,
                integrationEnabled: !!change.integrationEnabled,
                contractIds:
                  'contracts' in change &&
                  change.contracts.length > 0 &&
                  'value' in change.contracts[0]
                    ? change.contracts.reduce(
                        (acc: number[], config: unknown) => {
                          if (
                            typeof config === 'object' &&
                            config != null &&
                            'value' in config &&
                            typeof (config as {value: unknown}).value ===
                              'number'
                          ) {
                            return [...acc, (config as {value: number}).value];
                          }
                          return acc;
                        },
                        []
                      )
                    : [],
                telematicsConfigId: telematicsConfigId,
              },
            });
            didCreate = true;
          }
        }
        await loadList();
        if (didCreate) {
          notify('success', 'Created critical event type');
        } else {
          notify('warning', 'Could not create critical event type');
        }
      } catch (e) {
        notify('error', 'Failed to create admin critical event type');
      } finally {
        setLoading(false);
      }
    },
    [loadList, notify, setLoading]
  );

  const handleDelete = React.useCallback(
    async (row: CriticalEventType) => {
      setLoading(true);
      try {
        if (row.id) {
          await criticalEventTypeApi.apiCriticalEventTypeCriticalEventTypeIdDelete(
            {
              criticalEventTypeId: row.id,
            }
          );
          notify('success', 'Deleted critical event type');
        } else {
          notify('warning', 'Unable to delete critical event type');
        }
        await loadList();
      } catch (e) {
        notify('error', 'Failed to delete critical event type');
      } finally {
        setLoading(false);
      }
    },
    [loadList, notify, setLoading]
  );

  const value: CriticalEventTypeContextProps = {
    // Template Table
    list,
    loadList,
    hasPermission,
    loading,
    cleanupList,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onFiltersChange: handleFiltersChange,
    onInlineEdit: RoleService.hasOneOfPermissions([
      {permissionName: 'Edit CriticalEventType', type: 'Edit'},
      {permissionName: 'Edit CriticalEventTypeToggles', type: 'Edit'},
    ])
      ? handleInlineEdit
      : undefined,
    onInlineAdd: hasPermission.add ? handleInlineAdd : undefined,
    onDelete: hasPermission.delete ? handleDelete : undefined,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onRefresh: handleRefresh,
  };

  return (
    <CriticalEventTypeContext.Provider value={value}>
      {children}
    </CriticalEventTypeContext.Provider>
  );
};
