import ConfigService from './ConfigService/ConfigService';

import ResponseService from './ResponseService';
import {authStore} from '../store';

const serverUrl = ConfigService.serverUri;
const queryUrl = `${serverUrl}/api/trip_stop`;

const TripStopService = {
  createActivities: async ({activities, id}) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(`${queryUrl}/${id}`, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({activities}),
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  deleteActivities: async (id, activityId) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(`${queryUrl}/${id}/activities/${activityId}`, {
      method: 'DELETE',
      mode: 'cors',
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  getDocumentLink: async (tripStopId, documentType) => {
    const {auth} = authStore;
    const url = `${serverUrl}/api/document_template/download?documentType=${documentType}&tripStopId=${tripStopId}&documentFormat=pdf`;
    return `${url}&auth_token=${auth.token}`;
  },
};

export default TripStopService;
