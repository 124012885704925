import React from 'react';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';

interface Props {
  updateOpenIsochrone: any;
  distanceInMinutes: number;
  timeOfDay: number;
  setPopupLoading: any;
}

export default function IsochroneTravelTimeSlider({distanceInMinutes}: Props) {
  const [travelTime] = React.useState(distanceInMinutes);
  return (
    <div style={{width: 300, padding: 8}}>
      <Typography id="discrete-slider" gutterBottom>
        Travel Time: {distanceInMinutes} mins
      </Typography>
      <Slider
        defaultValue={travelTime}
        value={travelTime}
        step={1}
        min={1}
        max={120}
      />
    </div>
  );
}
