import {makeStyles, createStyles} from '@mui/styles';

const MasterRouteDetailsFormStyles = () =>
  createStyles({
    actions: {display: 'flex'},
  });

export const useMasterRouteDetailsFormStyles = makeStyles(
  MasterRouteDetailsFormStyles
);
