import React from 'react';
import {AppBar, Toolbar, Tabs, Tab} from '@mui/material';
import {EventsPaneContext} from '../EventsPaneContext';
import {useEventsPaneTabsStyles} from './EventsPaneTabs.style';
import {WindowInfoContext} from '../../../contexts';

export const EventsPaneTabs: React.FC = () => {
  const classes = useEventsPaneTabsStyles();
  const {isDesktop} = React.useContext(WindowInfoContext);
  const {tabValue, onTabChange} = React.useContext(EventsPaneContext);
  return (
    <AppBar
      className={!isDesktop ? classes.appBarTablet : undefined}
      position="relative"
      color="default"
    >
      <Toolbar classes={{root: classes.toolbar}} disableGutters>
        <Tabs
          classes={{root: classes.tabs, flexContainer: classes.container}}
          value={tabValue}
          onChange={onTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Events" value="Open" />
          <Tab label="History" value="Closed" />
        </Tabs>
      </Toolbar>
    </AppBar>
  );
};
