import React from 'react';
import {useContractOperationalEventTypeConfigContext} from './ContractOperationalEventTypeConfigContext';
import {useParams} from 'react-router-dom';

interface IParams {
  contractOperationalEventTypeConfigId?: string;
}

export const ContractOperationalEventTypeConfigIdHandler: React.FC = () => {
  const {setContractOperationalEventTypeConfigId} =
    useContractOperationalEventTypeConfigContext();

  const {contractOperationalEventTypeConfigId} = useParams<IParams>();

  React.useEffect(() => {
    if (
      contractOperationalEventTypeConfigId != null &&
      contractOperationalEventTypeConfigId !== 'add'
    ) {
      const parsedId = contractOperationalEventTypeConfigId
        ? parseInt(contractOperationalEventTypeConfigId)
        : undefined;
      setContractOperationalEventTypeConfigId(parsedId);
    }
    return () => setContractOperationalEventTypeConfigId(undefined);
  }, [
    contractOperationalEventTypeConfigId,
    setContractOperationalEventTypeConfigId,
  ]);

  return null;
};
