import React from 'react';
import {Autocomplete, AutocompleteProps} from '../../../../autocomplete';

export const TripEditStopAutocomplete: React.FC<AutocompleteProps> = ({
  id,
  label,
  name,
  options = [],
  placeholder,
  onChange,
  multiple,
  defaultValue,
  ...otherProps
}) => {
  const [value, setValue] = React.useState(defaultValue ?? null);

  React.useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <Autocomplete
      {...otherProps}
      defaultValue={defaultValue}
      value={value}
      label={label ?? name}
      name={name}
      multiple={multiple}
      onChange={onChange}
      options={options}
      placeholder={placeholder ?? 'Enter and select a value'}
    />
  );
};
