import React from 'react';
import {DashboardContext} from './DashboardContext';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../factory/template';
import {IconButton} from '@mui/material';
import {Dashboard} from '@onroadvantage/onroadvantage-api';
import {DurationService} from '../../service/DurationService';
import {Fullscreen, SystemUpdate} from '@mui/icons-material';
import {dashboardApi} from '../../api';
import {XLSXService} from '../../service';

export const DashboardList: React.FC = () => {
  const {loadList, cleanupList} = React.useContext(DashboardContext);

  const handleLoadDashboardFullScreen = React.useCallback(
    async (row: Dashboard) => {
      if (row.type === 'DataStudio') {
        window.open(
          `/dashboard_datastudio?reportId=${row.externalReportId}`,
          '_blank'
        );
      } else {
        window.open(`/dashboard_standalone?dashboardId=${row.id}`, '_blank');
      }
    },
    []
  );

  const handleDownloadDashboard = React.useCallback(async (row: Dashboard) => {
    const data = await dashboardApi.apiDashboardViewGet({
      ...row,
    });
    XLSXService.viewJsonToSpreadsheet({data}, row.name);
  }, []);

  const [config] = React.useState<TemplateTableConfig<Dashboard>>({
    columns: [
      {name: 'name', label: 'Name', type: 'string'},
      {
        name: 'dashboardActions',
        label: 'Dashboard Actions',
        type: 'string',
        enableFilter: false,
        getValue: (row) => (
          <>
            <IconButton
              title="Load Dashboard Full Screen"
              onClick={() => handleLoadDashboardFullScreen(row)}
              onAuxClick={() => handleLoadDashboardFullScreen(row)}
            >
              <Fullscreen />
            </IconButton>
            <IconButton
              title="Download Dashboard Data"
              onClick={() => handleDownloadDashboard(row)}
              disabled={row.type !== 'DataStudio'}
            >
              <SystemUpdate />
            </IconButton>
          </>
        ),
      },
      {
        name: 'category',
        label: 'Category',
        type: 'string',
        enableFilter: false,
      },
      {name: 'type', label: 'Type', type: 'string', enableFilter: false},
      {
        name: 'lastRefresh',
        label: 'Last Refresh',
        type: 'datetime',
        enableFilter: false,
      },
      {
        name: 'refreshFrequency',
        label: 'Refresh Frequency',
        type: 'string',
        enableFilter: false,
        getValue: ({refreshFrequency}) =>
          DurationService.getDDHHMMSS(refreshFrequency),
      },
      {
        name: 'filterCriticalEventTypes',
        label: 'Filter Critical Event Types',
        type: 'bool',
        enableFilter: false,
      },
    ],
    enableFilter: true,
    deleteMessage: (row) => ({
      title: 'Dashboard',
      items: {Name: row.name, Category: row.category, Type: row.type},
    }),
    identifier: 'DASHBOARD_LIST',
  });

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [loadList, cleanupList]);

  return <TemplateTableWrapper config={config} context={DashboardContext} />;
};
