import React from 'react';
import {useHistory} from 'react-router-dom';
import {
  ApiWebMasterTripDebriefGetRequest,
  WebMasterTripDebriefListMasterTrip,
  WebMasterTripDebriefListResponse,
} from '@onroadvantage/onroadvantage-api';
import {useAppNotifications} from '../../../contexts';
import {
  webMasterTripDebriefApi,
  masterTripDebriefApi,
  masterTripApi,
} from '../../../api';
import {
  TemplateTableContextProps,
  TLoadList,
  useTemplateTable,
} from '../../../factory/template';
import {TripDebriefToolbarActions} from './index';
import {RoleService} from '../../../service';

export type TripDebriefListContextProps = TemplateTableContextProps<
  WebMasterTripDebriefListMasterTrip,
  WebMasterTripDebriefListResponse
>;

export const TripDebriefListContext =
  React.createContext<TripDebriefListContextProps>({
    loading: false,
    list: [],
    currentPage: 1,
    loadList: async () => {},
  });

export const TripDebriefListContextProvider: React.FC = ({children}) => {
  const history = useHistory();
  const notify = useAppNotifications();
  const [
    {
      currentPage,
      pageSize,
      pageTotal,
      sorting,
      hasPermission,
      itemTotal,
      list,
      loading,
      loadingSingleItem,
      filters,
      isDaterangeFilterActive,
    },
    {
      // Getters
      getDownloads,
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleDateRangeFilterToggle,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
    },
  ] = useTemplateTable<
    WebMasterTripDebriefListMasterTrip,
    ApiWebMasterTripDebriefGetRequest
  >({
    enableDateRangeFilter: true,
    editPermission: 'Edit MasterTrip',
    addPermission: 'Add MasterTrip',
    deletePermission: 'Delete MasterTrip',
    downloadPermission: 'MasterTrip ListDownload',
    viewPermission: 'MasterTrip List',
  });

  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [selection, setSelection] = React.useState<
    WebMasterTripDebriefListMasterTrip[]
  >([]);

  const filterNonDebriefedTrips = React.useMemo(
    () => RoleService.hasPermission('TripDebriefTrip List', 'View'),
    []
  );

  const loadList = React.useCallback<
    TLoadList<WebMasterTripDebriefListResponse>
  >(
    async (options) => {
      setLoading(true);
      try {
        const requestObj = getRequestObj(
          [
            'masterTripId',
            'tripNumber',
            'vehicleRegistrationNumber',
            'vehicleFleetNumber',
            'driverName',
            'orderNumber',
            'status',
            'contractId',
            'contractCode',
            'offloadNodeName',
            'upliftNodeName',
            'masterTripDebriefId',
            'masterTripDebriefApprovedForBilling',
            'debriefed',
            'startDate',
            'endDate',
          ],
          options
        );
        const response =
          await webMasterTripDebriefApi.apiWebMasterTripDebriefGet(requestObj);
        return getResponse(response, options);
      } catch {
        notify('error', 'Failed to load trip Debrief list');
      } finally {
        setLoading(false);
      }
    },
    [getRequestObj, getResponse, notify, setLoading]
  );

  const handleDelete = async () => {
    setSubmitting(true);
    try {
      await masterTripApi.apiMasterTripBulkDeleteDelete({
        body: {
          masterTripIds: selection
            .filter((item) => item.masterTripId != null)
            .map((item) => item.masterTripId) as number[],
        },
      });
    } catch (error) {
      notify('error', 'Failed to delete selected items. Please try again.');
    } finally {
      await loadList();
      setSelection([]);
      setSubmitting(false);
    }
  };

  const handleDownload = React.useCallback(
    () => getDownloads('tripDebrief', loadList, [{name: 'Name', path: 'name'}]),
    [getDownloads, loadList]
  );

  const handleNavigate = React.useCallback(
    (row) => history.push(`/tripdebrieflist/${row.id}`),
    [history]
  );
  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  const handleCancelSelection = React.useCallback(() => setSelection([]), []);

  const handleApprove = React.useCallback(async () => {
    setSubmitting(true);
    try {
      for (const {
        id: masterTripId,
        tripNumber,
        masterTripDebriefApprovedForBilling,
        masterTripDebriefRevision,
      } of selection) {
        try {
          if (
            masterTripId &&
            masterTripDebriefRevision &&
            !masterTripDebriefApprovedForBilling
          ) {
            await masterTripDebriefApi.apiMasterTripMasterTripIdDebriefApprovePatch(
              {masterTripId, body: {revision: masterTripDebriefRevision}}
            );
          }
        } catch {
          notify('error', `Failed to approve trip ${tripNumber}. Try again.`);
        }
      }
    } finally {
      await loadList();
      setSelection([]);
      setSubmitting(false);
    }
  }, [loadList, notify, selection]);

  const handleUnApprove = React.useCallback(async () => {
    setSubmitting(true);
    try {
      for (const {
        id: masterTripId,
        tripNumber,
        masterTripDebriefApprovedForBilling,
        masterTripDebriefRevision,
      } of selection) {
        try {
          if (
            masterTripId &&
            masterTripDebriefRevision &&
            masterTripDebriefApprovedForBilling !== false
          ) {
            await masterTripDebriefApi.apiMasterTripMasterTripIdDebriefUnapprovePatch(
              {masterTripId, body: {revision: masterTripDebriefRevision}}
            );
          }
        } catch {
          notify(
            'error',
            `Failed to un-approve trip ${tripNumber}. Try again.`
          );
        }
      }
    } finally {
      await loadList();
      setSelection([]);
      setSubmitting(false);
    }
  }, [loadList, notify, selection]);

  const value: TripDebriefListContextProps = {
    toolbarCustomAction: (
      <TripDebriefToolbarActions
        loading={loading || submitting}
        selection={selection}
        onApprove={handleApprove}
        onUnApprove={handleUnApprove}
        onCancel={handleCancelSelection}
        onDelete={handleDelete}
      />
    ),
    list,
    loadList,
    hasPermission,
    loading: loading || loadingSingleItem || submitting,
    cleanupList,
    currentPage,
    filters: filterNonDebriefedTrips
      ? filters
      : [...filters, {columnName: 'debriefed', value: true}],
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    isDaterangeFilterActive,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onDateRangeFilterToggle: handleDateRangeFilterToggle,
    onSortingChange: handleSortingChange,
    onDownload: hasPermission.download ? handleDownload : undefined,
    onNavigate: handleNavigate,
    onRefresh: handleRefresh,
    selection: RoleService.hasPermission('Multi Trip Debrief Approve', 'Edit')
      ? selection
      : undefined,
    onSelectionChange: RoleService.hasPermission(
      'Multi Trip Debrief Approve',
      'Edit'
    )
      ? setSelection
      : undefined,
  };
  return (
    <TripDebriefListContext.Provider value={value}>
      {children}
    </TripDebriefListContext.Provider>
  );
};
