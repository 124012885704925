import React from 'react';
import {CardHeader} from '@mui/material';

export interface TemplateStepperHeaderProps {
  title?: string;
}

export const TemplateStepperHeader: React.FC<TemplateStepperHeaderProps> = ({
  title,
}) => <CardHeader title={title ?? 'Title'} />;
