import React from 'react';
import {RoleContext} from './RoleContext';
import {TemplateTable, TemplateTableConfig} from '../../factory/template';
import {
  Listing,
  ReportDump,
  RoleUpdate,
  RoleReport,
} from '@onroadvantage/onroadvantage-api';
import {RoleService} from '../../service';

export const RoleReports: React.FC = () => {
  const {loading, role, onUpdateRole} = React.useContext(RoleContext);

  const config: TemplateTableConfig<ReportDump> = React.useMemo(
    () => ({
      columns: [
        {
          name: 'name',
          label: 'Name',
          type: 'string',
          model: 'Report',
          enableMulti: true,
          enableEditing: true,
          enableAutocomplete: true,
        },
      ],
      disableToolbar: true,
      disablePagination: true,
      deleteMessage: (row) => ({
        title: 'Role Report',
        items: {RoleName: role?.name, ReportName: row.name},
      }),
      identifier: 'ROLE_REPORTS_LIST',
    }),
    [role?.name]
  );

  const handleAddReport = React.useCallback(
    (changes: {[key: string]: any}[]) => {
      changes.forEach((change) => {
        const addedReports = change.name.map((report: Listing) => ({
          id: report.value,
        }));
        onUpdateRole(
          {
            ...(role as RoleUpdate),
            reports: [
              ...(role?.reports
                ? role.reports.map(({id}) => ({id: parseInt(id)}))
                : []),
              ...addedReports,
            ],
          },
          'Add'
        );
      });
    },
    [onUpdateRole, role]
  );
  const handleDeleteReport = React.useCallback(
    (row: ReportDump) => {
      const filteredReports: RoleReport[] =
        role?.reports
          ?.filter((report) => report.id !== row.id)
          .map(({id}) => ({id: parseInt(id)})) ?? [];
      onUpdateRole(
        {
          ...(role as RoleUpdate),
          reports: filteredReports,
        },
        'Delete'
      );
    },
    [onUpdateRole, role]
  );

  return (
    <TemplateTable
      config={config}
      list={role?.reports ?? []}
      loading={loading}
      currentPage={1}
      onInlineAdd={
        RoleService.hasPermission('Edit Role', 'Edit')
          ? handleAddReport
          : undefined
      }
      onDelete={
        RoleService.hasPermission('Edit Role', 'Edit')
          ? handleDeleteReport
          : undefined
      }
    />
  );
};
