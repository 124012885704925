import React from 'react';
import {NavigateNext} from '@mui/icons-material';
import {Breadcrumbs, Paper} from '@mui/material';
import {matchPath, useLocation} from 'react-router-dom';
import {useBreadcrumbControllerStyles} from './BreadcrumbController.style';
import {RouteProps} from 'react-router';
import {BreadcrumbItem, Params} from './BreadcrumbItem';

export interface BreadcrumbRoute {
  path: string;
  isLink?: boolean;
  matchOptions?: RouteProps;
  title: string | ((params: Params) => string);
  component?: React.ReactNode;
}

interface BreadcrumbControllerProps {
  routes: BreadcrumbRoute[];
}

const DEFAULT_MATCH_OPTIONS: string | string[] | RouteProps = {exact: true};

export const BreadcrumbController: React.FC<BreadcrumbControllerProps> = ({
  routes,
}) => {
  const classes = useBreadcrumbControllerStyles();
  const location = useLocation();

  const paths: React.ReactNode[] = [];

  location.pathname
    .replace(/\$/, '')
    .split('/')
    .reduce((previous, current) => {
      const pathSection = `${previous}/${current}`;

      const match = routes.find(({matchOptions, path}) => {
        return matchPath(pathSection, {
          ...(matchOptions || DEFAULT_MATCH_OPTIONS),
          path,
        });
      });

      if (match) {
        const {matchOptions, path} = match;
        const routePath = matchPath(pathSection, {
          ...(matchOptions || DEFAULT_MATCH_OPTIONS),
          path,
        });

        paths.push(
          <BreadcrumbItem
            params={routePath?.params}
            route={match}
            isLink={match.isLink}
            url={routePath?.url}
            key={routePath?.url}
          />
        );
      }
      return pathSection;
    });

  if (paths.length === 0) {
    return null;
  }

  return (
    <Paper className={classes.breadcrumbs}>
      <Breadcrumbs separator={<NavigateNext fontSize="small" />}>
        {paths}
      </Breadcrumbs>
    </Paper>
  );
};
