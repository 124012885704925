import React from 'react';
import {ContractGroupContext} from './ContractGroupContext';
import {
  TemplateTable,
  TemplateTableConfig,
  TOnInlineAdd,
} from '../../factory/template';
import {
  Listing,
  UserDump,
  ContractGroupNestedUser,
  ContractGroupNestedContract,
} from '@onroadvantage/onroadvantage-api';

export const ContractGroupUsers: React.FC = () => {
  const {contractGroup, loading, onUpdateContractGroup, hasPermission} =
    React.useContext(ContractGroupContext);

  const config: TemplateTableConfig<UserDump> = React.useMemo(
    () => ({
      columns: [
        {
          name: 'email',
          label: 'Email',
          type: 'string',
          model: 'User',
          enableEditing: true,
          enableAutocomplete: true,
          enableMulti: true,
        },
      ],
      disableToolbar: true,
      disablePagination: true,
      deleteMessage: (row) => ({
        title: 'Contract Group User',
        items: {ContractGroupName: contractGroup?.name, Email: row.email},
      }),
      identifier: 'CONTRACT_GROUP_USERS_LIST',
    }),
    [contractGroup?.name]
  );

  const handleInlineAdd = React.useCallback<TOnInlineAdd>(
    (changes) => {
      changes.forEach((change) => {
        const newUsers: ContractGroupNestedUser[] = [
          ...(contractGroup?.users?.map(({id}) => ({id: id as number})) ?? []),
          ...change.email.map((user: Listing) => ({id: user.value})),
        ];

        onUpdateContractGroup({
          name: contractGroup?.name,
          contracts: contractGroup?.contracts?.map(({id}) => ({
            id,
          })) as ContractGroupNestedContract[],
          users: newUsers,
        });
      });
    },
    [contractGroup, onUpdateContractGroup]
  );

  const handleDelete = React.useCallback(
    (row: UserDump) => {
      onUpdateContractGroup({
        name: contractGroup?.name,
        users: contractGroup?.users
          ?.filter(({id}) => id !== row.id)
          ?.map(({id}) => ({id})) as ContractGroupNestedUser[],
        contracts: contractGroup?.contracts?.map(({id}) => ({
          id,
        })) as ContractGroupNestedContract[],
      });
    },
    [contractGroup, onUpdateContractGroup]
  );

  return (
    <TemplateTable
      config={config}
      list={contractGroup?.users ?? []}
      loading={loading}
      currentPage={1}
      onInlineAdd={hasPermission?.edit ? handleInlineAdd : undefined}
      onDelete={hasPermission?.edit ? handleDelete : undefined}
    />
  );
};
