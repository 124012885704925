import React from 'react';
import {Listing} from '@onroadvantage/onroadvantage-api';
import {debounce} from '@mui/material';
import {
  Autocomplete,
  TAutocompleteOnChange,
  TAutocompleteOnInputChange,
} from '../../../autocomplete';
import {useAppNotifications} from '../../../../contexts';
import {listingApi} from '../../../../api';
import {useTripContext} from '../../tripContext';
import {TripService} from '../../../../service';

interface TripSummaryEditAutocompleteProps {
  model: string;
  loading: boolean;
  onChange: TAutocompleteOnChange;
  value: Listing | undefined;
}

export const TripSummaryEditAutocomplete: React.FC<
  TripSummaryEditAutocompleteProps
> = ({model, loading, onChange, value}) => {
  const {masterTrip} = useTripContext();

  const notify = useAppNotifications();
  const [listing, setListing] = React.useState<Listing[]>([]);
  const [localLoading, setLocalLoading] = React.useState<boolean>(false);

  const getListing = React.useCallback(
    async (filter?: string) => {
      setLocalLoading(true);
      try {
        if (['Vehicle', 'Driver'].includes(model)) {
          const response = await TripService.listing(model.toLowerCase(), {
            query: filter,
            tripId: masterTrip?.trip.id,
          });

          if (model === 'Vehicle') {
            setListing(
              response.data.items.map((vehicle: any) => {
                const rawLabel = `${vehicle.registrationNumber} - ${vehicle.description}`;
                const label = rawLabel.replace(/(^.{50}).*$/, '$1...');
                return {
                  value: vehicle.id,
                  label,
                };
              }) ?? []
            );
          } else {
            setListing(
              response.data.items.map((driver: any) => ({
                value: driver.id,
                label: driver.externalExtendedId
                  ? `${driver.externalExtendedId} - ${driver.firstname}`
                  : driver.firstname,
              })) ?? []
            );
          }
        } else {
          const response = await listingApi.apiListingGet({
            model,
            query: filter,
          });
          setListing(response.items ?? []);
        }
      } catch (e) {
        notify('error', e.message ?? `Failed to load ${model} listing`);
      } finally {
        setLocalLoading(false);
      }
    },
    [masterTrip?.trip.id, model, notify]
  );

  const getListingDebounce = React.useRef(debounce(getListing, 500)).current;

  const handleInputChange = React.useCallback<TAutocompleteOnInputChange>(
    async (event, value) => {
      setLocalLoading(true);
      await getListingDebounce(value);
    },
    [getListingDebounce]
  );

  React.useEffect(() => {
    getListing();
  }, [getListing]);

  return (
    <Autocomplete
      name={`${model}-trip-edit`}
      value={value}
      loading={loading || localLoading}
      options={listing}
      onInputChange={handleInputChange}
      onChange={onChange}
    />
  );
};
