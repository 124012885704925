import React from 'react';
import {NotificationConfigWorkflowTaskContext} from './NotificationConfigWorkflowTaskContext';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../../factory/template';
import {TaskNotificationConfig} from '@onroadvantage/onroadvantage-api';
import {NotificationConfigContext} from '../NotificationConfigContext';

export const NotificationConfigWorkflowTaskList: React.FC = () => {
  const {notificationConfig} = React.useContext(NotificationConfigContext);

  const config: TemplateTableConfig<TaskNotificationConfig> = React.useMemo(
    () => ({
      columns: [
        {
          name: 'workflowConfigTaskTemplate',
          label: 'Task Template',
          type: 'string',
          model: 'WorkflowConfigTaskTemplate',
          enableEditing: false,
          enableAutocomplete: true,
          getValue: ({workflowConfigTaskTemplate}) =>
            workflowConfigTaskTemplate?.taskTemplate?.name,
        },
        {
          name: 'WorkflowConfigName',
          label: 'Workflow Config Name',
          type: 'string',
          enableEditing: false,
          getValue: ({workflowConfigTaskTemplate}) =>
            workflowConfigTaskTemplate?.workflowConfig?.name,
        },
        {
          name: 'notifyContractContactGroups',
          label: 'Contract Contact Groups',
          type: 'bool',
          enableEditing: true,
        },
        {
          name: 'notifyCustomerContactGroups',
          label: 'Customer Contact Groups',
          type: 'bool',
          enableEditing: true,
        },
        {
          name: 'notifyNodeContactGroups',
          label: 'Node Contact Groups',
          type: 'bool',
          enableEditing: true,
        },
        {
          name: 'notifyVehicleGroupContactGroups',
          label: 'Vehicle Group Contact Groups',
          type: 'bool',
          enableEditing: true,
        },
        {
          name: 'notifyDriver',
          label: 'Driver',
          type: 'bool',
          enableEditing: true,
        },
        {
          name: 'notifyVehicle',
          label: 'Vehicle',
          type: 'bool',
          enableEditing: true,
        },
        {
          name: 'notifyWeb',
          label: 'Web',
          type: 'bool',
          enableEditing: true,
        },
        {
          name: 'notifyNextStop',
          label: 'Next Stop',
          type: 'bool',
          enableEditing: true,
        },
        {
          name: 'notifyOffloadingStops',
          label: 'Offload Stops',
          type: 'bool',
          enableEditing: true,
        },
      ],
      disablePagination: true,
      disableToolbar: true,
      deleteMessage: (row) => ({
        title: 'Notification Config Task',
        items: {
          NotificationConfigName: notificationConfig?.name,
          TaskTemplate:
            row.workflowConfigTaskTemplate?.taskTemplate?.type?.name,
          WorkflowConfigName:
            row.workflowConfigTaskTemplate?.workflowConfig?.name,
        },
      }),
      identifier: 'NOTIFICATION_CONFIG_WORKFLOW_TASK_TRIGGERS_LIST',
    }),
    [notificationConfig?.name]
  );

  return (
    <TemplateTableWrapper
      config={config}
      context={NotificationConfigWorkflowTaskContext}
    />
  );
};
