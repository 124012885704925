import ConfigService from './ConfigService/ConfigService';

const serverUrl = ConfigService.serverUri;

const DocumentTemplateService = {
  getPreviewPdfLink: async () => {
    return `${serverUrl}/api/document_template/preview/pdf`;
  },
};

export default DocumentTemplateService;
