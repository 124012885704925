import React from 'react';
import {Stack} from '@mui/material';
import {
  FormikAutocompleteHooks,
  FormikAutocompleteWithListing,
  FormikSwitch,
} from '../../../formik';
import {useContractContext} from '../../contractContext';

export const ContractTripExecutionFormFields: React.FC = () => {
  const {priorityOptions} = useContractContext();
  return (
    <Stack>
      <FormikAutocompleteWithListing
        name="tripStopArrivalTimestampTaskTemplate"
        placeholder="Enter and select the contract trip stop arrival timestamp task template"
        label="Trip Stop Arrival Timestamp Task Template"
        model="TaskTemplate"
        fullWidth
      />
      <FormikAutocompleteWithListing
        name="tripStopDepartureTimestampTaskTemplate"
        placeholder="Enter and select the contract trip stop departure timestamp task template"
        label="Trip Stop Departure Timestamp Task Template"
        model="TaskTemplate"
        fullWidth
      />
      <FormikAutocompleteWithListing
        name="tripStopArrivalOdometerTaskTemplate"
        placeholder="Enter and select the contract trip stop arrival odometer task template"
        label="Trip Stop Arrival Odometer Task Template"
        model="TaskTemplate"
        fullWidth
      />
      <FormikAutocompleteWithListing
        name="tripStopDepartureOdometerTaskTemplate"
        placeholder="Enter and select the contract trip stop departure odometer task template"
        label="Trip Stop Departure Odometer Task Template"
        model="TaskTemplate"
        fullWidth
      />
      <FormikAutocompleteWithListing
        name="tripStopServiceStartTimestampTaskTemplate"
        placeholder="Enter and select the contract trip stop service start timestamp task template"
        label="Trip Stop Service Start Timestamp Task Template"
        model="TaskTemplate"
        fullWidth
      />
      <FormikAutocompleteWithListing
        name="tripStopServiceEndTimestampTaskTemplate"
        placeholder="Enter and select the contract trip stop service end timestamp task template"
        label="Trip Stop Service End Timestamp Task Template"
        model="TaskTemplate"
        fullWidth
      />
      <FormikAutocompleteWithListing
        name="speedTables"
        placeholder="Enter and select the contract speed tables"
        label="Speed Tables"
        model="SpeedTable"
        multiple
        fullWidth
      />
      <FormikAutocompleteHooks
        name="tripStopOdometerPriority"
        placeholder="Enter and select the contract trip stop odometer priority"
        label="Trip Stop Odometer Priority"
        options={priorityOptions}
        fullWidth
      />
      <FormikAutocompleteHooks
        name="tripStopTimestampPriority"
        placeholder="Enter and select the contract trip stop timestamp priority"
        label="Trip Stop Timestamp Priority"
        options={priorityOptions}
        fullWidth
      />
      <FormikAutocompleteHooks
        name="actualsStopMethodology"
        placeholder="Enter and select the contract actual stop methodology"
        label="Actual Stop Methodology"
        options={priorityOptions}
        fullWidth
      />
      <FormikSwitch
        name="tripStartArrivalTaskBan"
        label="Trip Start Arrival Task Ban"
      />
      <FormikSwitch
        name="tripEndDepartureTaskBan"
        label="Trip End Departure Task Ban"
      />
      <FormikSwitch
        name="banOrderTripDoubleAllocation"
        label="Ban Order Trip Double Allocation"
      />
      <FormikSwitch
        name="orderLineActualLoadedQuantityEnabled"
        label="Order Line Actual Loaded Quantity Enabled"
      />
      <FormikSwitch
        name="allowMultipleOrdersInCompartment"
        label="Allow Multiple Orders In Compartment"
        stackProps={{pb: 1}}
      />
    </Stack>
  );
};
