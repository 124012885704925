import React from 'react';
import {TransporterDetails} from './TransporterDetails';
import {ITemplateTabControllerTab, TemplateTabs} from '../../factory/template';
import {TransporterContext} from './TransporterContext';

export const Transporter: React.FC = () => {
  const {transporterId, loading} = React.useContext(TransporterContext);

  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {title: 'Details', element: <TransporterDetails />},
  ]);

  const path = React.useMemo(
    () => `/transporterlist/${transporterId}`,
    [transporterId]
  );

  return <TemplateTabs tabs={tabs} path={path} loading={loading} />;
};
