import React from 'react';
import {Form, Formik} from 'formik';
import {VantageDialogButton} from '../../dialog';
import {FormikAutocompleteWithListing} from '../../formik';
import {
  IMasterRouteDetailsCopy,
  MasterRouteContext,
} from '../MasterRouteContext';
import {Typography} from '@mui/material';

export const MasterRouteDetailsCopy: React.FC = () => {
  const {onCopyDetailsFormSubmit, copyDetailsRef} =
    React.useContext(MasterRouteContext);
  return (
    <div>
      <Formik<IMasterRouteDetailsCopy>
        initialValues={{contracts: []}}
        onSubmit={onCopyDetailsFormSubmit}
        innerRef={copyDetailsRef}
      >
        {({values, ...formikHelpers}) => (
          <Form>
            <VantageDialogButton
              title="Copy"
              dialogTitle="Copy Master Route"
              acceptProps={{type: 'submit'}}
              acceptTitle="Submit"
              declineTitle="Cancel"
              onAccept={() => {
                onCopyDetailsFormSubmit(values, formikHelpers);
                formikHelpers.resetForm();
              }}
              onDecline={() => formikHelpers.resetForm()}
              color="inherit"
            >
              <Typography>
                Please select contracts to which you want to copy the master
                route.
              </Typography>
              <FormikAutocompleteWithListing
                name="contracts"
                multiple
                model="Contract"
                placeholder="Select contract(s)"
                label=""
              />
            </VantageDialogButton>
          </Form>
        )}
      </Formik>
    </div>
  );
};
