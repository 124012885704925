import React from 'react';
import {NotificationConfigWorkflowTaskContext} from './NotificationConfigWorkflowTaskContext';
import {
  TemplateTable,
  TemplateTableConfig,
  TOnInlineAdd,
} from '../../../factory/template';
import {Listing, NodeContactGroup} from '@onroadvantage/onroadvantage-api';
import {taskNotificationConfigApi} from '../../../api';
import {useAppNotifications} from '../../../contexts';
import {RoleService} from '../../../service';

export const NotificationConfigWorkflowTaskNodeContacts: React.FC = () => {
  const notify = useAppNotifications();
  const {
    notificationConfigTask,
    notificationConfigTaskId,
    loading,
    loadNotificationConfigTask,
  } = React.useContext(NotificationConfigWorkflowTaskContext);
  const [localLoading, setLocalLoading] = React.useState<boolean>(false);

  const [config] = React.useState<TemplateTableConfig<NodeContactGroup>>({
    columns: [
      {
        name: 'contactGroup',
        label: 'Contact Group Name',
        type: 'string',
        model: 'NodeContactGroup',
        enableEditing: true,
        enableAutocomplete: true,
        enableMulti: true,
        getValue: ({contactGroup}) => contactGroup?.name,
      },
      {
        name: 'node',
        label: 'Node Name',
        type: 'string',
        enableEditing: false,
        getValue: ({node}) => node?.name,
      },
    ],
    disableToolbar: true,
    disablePagination: true,
    deleteMessage: (row) => ({
      title: 'Notification Config Task Node Contact',
      items: {
        ContactGroup: row.contactGroup?.name,
        Node: row.node?.name,
      },
    }),
    identifier: 'TASK_NOTIFICATION_CONFIG_NODE_CONTACTS_LIST',
  });

  const handleInlineAdd = React.useCallback<TOnInlineAdd>(
    async (changes) => {
      setLocalLoading(true);
      try {
        for (const change of changes) {
          const nodeContactListings: Listing[] = change.contactGroup;
          const nodeContactIds = nodeContactListings
            .filter(({value}) => value)
            .map(({value}) => value as number);
          if (notificationConfigTaskId) {
            await taskNotificationConfigApi.apiTaskNotificationConfigTaskNotificationConfigIdAssignNodeContactsPost(
              {
                taskNotificationConfigId: notificationConfigTaskId,
                body: {nodeContactIds},
              }
            );
            await loadNotificationConfigTask();
          }
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to assign nodes');
      } finally {
        setLocalLoading(false);
      }
    },
    [loadNotificationConfigTask, notificationConfigTaskId, notify]
  );

  const handleInlineDelete = React.useCallback(
    async (row: NodeContactGroup) => {
      setLocalLoading(true);
      try {
        if (notificationConfigTaskId && row.id) {
          await taskNotificationConfigApi.apiTaskNotificationConfigTaskNotificationConfigIdUnassignNodeContactPost(
            {
              taskNotificationConfigId: notificationConfigTaskId,
              body: {nodeContactId: row.id},
            }
          );
          await loadNotificationConfigTask();
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to unassign node');
      } finally {
        setLocalLoading(false);
      }
    },
    [loadNotificationConfigTask, notificationConfigTaskId, notify]
  );

  return (
    <TemplateTable
      config={config}
      list={notificationConfigTask?.nodeContactGroups ?? []}
      currentPage={1}
      loading={loading || localLoading}
      onDelete={
        RoleService.hasPermission('Edit NotificationConfig', 'Edit')
          ? handleInlineDelete
          : undefined
      }
      onInlineAdd={
        RoleService.hasPermission('Edit NotificationConfig', 'Edit')
          ? handleInlineAdd
          : undefined
      }
    />
  );
};
