import React from 'react';
import {TaskContext, TaskContextProps} from './TaskContext';
import {useParams} from 'react-router-dom';

interface IParams {
  taskId?: string;
}

export const TaskIdHandler: React.FC = () => {
  const {setTaskId} = React.useContext<TaskContextProps>(TaskContext);

  const {taskId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = taskId ? parseInt(taskId) : undefined;
    setTaskId(parsedId);

    return () => {
      setTaskId(undefined);
    };
  }, [taskId, setTaskId]);

  return null;
};
