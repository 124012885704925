import React from 'react';
import {Button, Divider, Stack, Tab, Tabs, TextField} from '@mui/material';
import {useEventsPaneCardAddActivityStyles} from './EventsPaneCardAddActivity.style';
import {
  Autocomplete,
  AutocompleteOptionType,
  TAutocompleteOnChange,
} from '../../autocomplete';
import {EventsPaneContext} from '../EventsPaneContext';
import {
  criticalEventApi,
  operationalEventApi,
  reasonCodeApi,
} from '../../../api';
import {
  ApiReasonCodeGetRequest,
  WebEvent,
} from '@onroadvantage/onroadvantage-api';

interface EventsPaneCardAddActivityProps {
  event: WebEvent;
}

export const EventsPaneCardAddActivity: React.FC<
  EventsPaneCardAddActivityProps
> = ({event}) => {
  const {reloadEvents} = React.useContext(EventsPaneContext);
  const [activeTab, setActiveTab] = React.useState<number>(0);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [reasonCodeOptions, setReasonCodeOptions] = React.useState<
    AutocompleteOptionType[]
  >([]);
  const [comment, setComment] = React.useState<string | undefined>();
  const [selectedReasonCode, setSelectedReasonCode] = React.useState<
    AutocompleteOptionType | undefined
  >();
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const handleChangeActiveTab = React.useCallback(
    (_event: any, value: number) => setActiveTab(value),
    []
  );

  const handleChangeComment = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setComment(event.target.value);
    },
    []
  );

  const handleChangeCommentReasonCode =
    React.useCallback<TAutocompleteOnChange>((_event, value) => {
      if (value && !Array.isArray(value)) {
        setComment((value as AutocompleteOptionType).label);
        setSelectedReasonCode(value as AutocompleteOptionType);
      }
    }, []);

  const handleSave = React.useCallback(async () => {
    setSubmitting(true);
    try {
      if (comment && event.id) {
        if (event.metaData?.model === 'OperationalEvent') {
          await operationalEventApi.apiOperationalEventOperationalEventIdPost({
            body: {
              activities: [
                {
                  action: 'Comment',
                  value: comment,
                  contractOperationalEventTypeReasonCodeId:
                    typeof selectedReasonCode?.value === 'string'
                      ? parseInt(selectedReasonCode?.value)
                      : selectedReasonCode?.value ?? undefined,
                },
              ],
            },
            operationalEventId: event.id,
          });
        } else {
          await criticalEventApi.apiCriticalEventCriticalEventIdPost({
            body: {
              activities: [
                {
                  action: 'Comment',
                  value: comment,
                  contractCriticalEventTypeReasonCodeId:
                    typeof selectedReasonCode?.value === 'string'
                      ? parseInt(selectedReasonCode?.value)
                      : selectedReasonCode?.value ?? undefined,
                },
              ],
            },
            criticalEventId: event.id,
          });
        }
        await reloadEvents();
      }
    } finally {
      setComment('');
      setSubmitting(false);
    }
  }, [
    comment,
    event.id,
    event.metaData?.model,
    reloadEvents,
    selectedReasonCode?.value,
  ]);

  React.useEffect(() => {
    const getReasonCode = async () => {
      setLoading(true);
      try {
        if (event.eventType?.id && event.vehicle?.contractId) {
          const requestObj: ApiReasonCodeGetRequest = {
            contractId: event.vehicle.contractId,
          };
          if (event.metaData?.model === 'OperationalEvent') {
            requestObj.operationalEventTypeId = event.eventType.id;
          } else {
            requestObj.criticalEventTypeId = event.eventType.id;
          }
          const response = await reasonCodeApi.apiReasonCodeGet(requestObj);
          const items =
            response.items?.map((item) => ({
              value: item.id,
              label: `${item.name} - ${item.description}`,
            })) ?? [];
          setReasonCodeOptions(items.length > 0 ? items : []);
        }
      } finally {
        setLoading(false);
      }
    };
    getReasonCode();
    return () => {
      setReasonCodeOptions([]);
      setComment(undefined);
    };
  }, [event.eventType?.id, event.vehicle?.contractId, event.metaData?.model]);

  return (
    <Stack>
      <Divider />
      <Tabs
        value={activeTab}
        onChange={handleChangeActiveTab}
        variant="fullWidth"
      >
        <Tab label="Comment" />
        <Tab label="Reason Codes" />
      </Tabs>
      <TabPanel value={activeTab} index={0}>
        <TextField
          id="comment"
          name="add-comment"
          label="Add Comment"
          disabled={loading}
          multiline
          rows={2}
          value={comment}
          onChange={handleChangeComment}
          fullWidth
        />
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <Autocomplete
          label="Reason Code"
          id="reasonCode"
          name="reasonCode"
          disabled={loading}
          onChange={handleChangeCommentReasonCode}
          options={reasonCodeOptions}
          placeholder="Enter and select a value"
        />
      </TabPanel>
      <Stack alignItems="flex-start">
        <Button
          size="small"
          variant="outlined"
          color="primary"
          onClick={handleSave}
          disabled={submitting}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
};

interface TabPanelProps {
  value: number;
  index: number;
}

const TabPanel: React.FC<TabPanelProps> = ({value, index, children}) => {
  const classes = useEventsPaneCardAddActivityStyles();
  if (value !== index) return null;
  return <div className={classes.container}>{children}</div>;
};
