import React from 'react';
import * as Yup from 'yup';
import {Listing} from '@onroadvantage/onroadvantage-api';
import {listingSchema, TemplateForm} from '../../../../factory/template';
import {usePlanningBoardBookingContext} from './planningBoardBookingContext';
import {
  FormikAutocompleteHooks,
  FormikAutocompleteWithListing,
  FormikDateTimePicker,
  FormikTextField,
} from '../../../formik';
import {usePlanningBoardContext} from '../../planningBoardContext';

export interface IPlanningBoardBookingForm {
  number: string;
  customerReferenceNumber?: string | undefined;
  bookingType: Listing;
  startNode: Listing;
  appointmentNode: Listing;
  endNode: Listing;
  vehicle: Listing;
  startDate: Date;
  endDate: Date;
}

export const schema: Yup.SchemaOf<IPlanningBoardBookingForm> = Yup.object({
  number: Yup.string().required('Required'),
  customerReferenceNumber: Yup.string(),
  bookingType: listingSchema.required('Required'),
  vehicle: listingSchema.required('Required'),
  startNode: listingSchema.required('Required'),
  appointmentNode: listingSchema.required('Required'),
  endNode: listingSchema.required('Required'),
  startDate: Yup.date().required('Required'),
  endDate: Yup.date()
    .min(Yup.ref('startDate'), 'Can not be less than the start date.')
    .required('Required'),
});

export const PlanningBoardBookingForm: React.FC<{isAdd?: boolean}> = ({
  isAdd,
}) => {
  const {initialValues, onSubmit, submitting} =
    usePlanningBoardBookingContext();
  const {vehicles} = usePlanningBoardContext();
  const vehicleListing = React.useMemo<Listing[]>(
    () =>
      vehicles.map(({id, registrationNumber, fleetNumber}) => ({
        value: id,
        label: `${registrationNumber} - ${fleetNumber}`,
      })),
    [vehicles]
  );

  return (
    <TemplateForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={schema}
      submitting={submitting}
      permission={{
        name: isAdd ? 'Add Booking' : 'Edit Booking',
        type: isAdd ? 'Add' : 'Edit',
      }}
      enableReinitialize
    >
      <FormikTextField name="number" label="Trip Number" fullWidth />
      <FormikTextField
        name="customerReferenceNumber"
        label="Customer Reference Number"
        fullWidth
      />
      <FormikAutocompleteWithListing
        name="bookingType"
        label="Booking Type"
        model="BookingType"
        fullWidth
      />
      <FormikAutocompleteWithListing
        name="startNode"
        label="Start Site"
        model="Node"
        fullWidth
      />
      <FormikAutocompleteWithListing
        name="appointmentNode"
        label="Appointment Site"
        model="Node"
        fullWidth
      />
      <FormikAutocompleteWithListing
        name="endNode"
        label="End Site"
        model="Node"
        fullWidth
      />
      <FormikAutocompleteHooks
        name="vehicle"
        label="Vehicle"
        options={vehicleListing}
        fullWidth
      />
      <FormikDateTimePicker
        name="startDate"
        label="Appointment Start Date"
        fullWidth
      />
      <FormikDateTimePicker
        name="endDate"
        label="Appointment End Date"
        minReference="startDate"
        disableDefaultValue
        fullWidth
      />
    </TemplateForm>
  );
};
