import {
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import React from 'react';
import {
  SortableContainer,
  SortableElement,
  SortEndHandler,
} from 'react-sortable-hoc';
import {useTripSetupContext} from '../TripSetupContext';
import {changePosition} from '../../../../factory/template';
import {Delete, Sort} from '@mui/icons-material';
import {TripSetupStopsStepSortingStop} from './TripSetupStopsStepSortingStop';

const SortableProviderComponent = SortableContainer(
  ({children}: {children: React.ReactNode}) => {
    return <>{children}</>;
  }
);

const SortableElementComponent = SortableElement(
  ({children}: {children: React.ReactNode}) => {
    return <>{children}</>;
  }
);

export const TripSetupStopsStepSorting = () => {
  const {stops, setStops} = useTripSetupContext();
  const [editingState, setEditingState] = React.useState<
    'sorting' | 'removing'
  >('sorting');

  const handleSortStopsEnd = React.useCallback<SortEndHandler>(
    (sort) => {
      const oldIndex = sort.oldIndex - 1;
      const newIndex = sort.newIndex - 1;
      setStops((prevStops) => {
        return (
          changePosition(prevStops, oldIndex, newIndex)?.map((stop, index) =>
            index + 1 !== stop.sequence ? {...stop, sequence: index + 1} : stop
          ) ?? []
        );
      });
    },
    [setStops]
  );

  if (stops.length === 0) {
    return (
      <Stack bgcolor={'grey.100'} p={2} borderRadius="8px">
        <Typography>No stops added yet</Typography>
      </Stack>
    );
  }

  return (
    <SortableProviderComponent onSortEnd={handleSortStopsEnd}>
      <Stack bgcolor={'background.paper'} borderRadius={8} spacing={1}>
        <ToggleButtonGroup
          size="small"
          value={editingState}
          exclusive
          onChange={(_e, value) => {
            setEditingState(value);
          }}
          aria-label="text alignment"
        >
          <ToggleButton value="sorting" aria-label="sorting aligned">
            <Sort />
          </ToggleButton>
          <ToggleButton value="removing" aria-label="removing">
            <Delete />
          </ToggleButton>
        </ToggleButtonGroup>
        {stops.map((stop) =>
          editingState === 'sorting' ? (
            <SortableElementComponent
              key={`stop-${stop.sequence}`}
              index={stop.sequence}
            >
              <TripSetupStopsStepSortingStop
                editingState="sorting"
                stop={stop}
              />
            </SortableElementComponent>
          ) : (
            <TripSetupStopsStepSortingStop
              key={`stop-${stop.sequence}`}
              editingState="removing"
              stop={stop}
            />
          )
        )}
      </Stack>
    </SortableProviderComponent>
  );
};
