import React from 'react';
import {OrderRecipient} from '@onroadvantage/onroadvantage-api';
import {Typography} from '@mui/material';
import {BusinessCenter} from '@mui/icons-material';
import {RecipientCard} from '../recipientCard';
import {IRecipientCardDetail} from '../MasterTripRecipientContext';
import {useRecipientOrderDetailsStyles} from './RecipientOrderDetails.style';
import {RecipientOrderDetailsLine} from './RecipientOrderDetailsLine';
import moment from 'moment';

const dateFormat = 'MMM DD, YYYY, HH:mm a';

interface RecipientOrderDetailsProps extends OrderRecipient {
  activeOrder: string | undefined;
  changeActiveOrderHandler: (orderNumber: string | undefined) => void;
  plannedArrival: Date | undefined;
  stopEta: Date | undefined;
}

export const RecipientOrderDetails: React.FC<RecipientOrderDetailsProps> = ({
  activeOrder,
  changeActiveOrderHandler,
  plannedArrival,
  stopEta,
  ...order
}) => {
  const classes = useRecipientOrderDetailsStyles();
  const orderDetails: IRecipientCardDetail[] = React.useMemo(
    () => [
      {label: 'From', value: order.upliftPoint.name ?? '-'},
      {label: 'To', value: order.offloadPoint.name ?? '-'},
      {
        label: 'Customer',
        value: order.customer?.name ?? '-',
      },
      {
        label: 'Original ETA',
        value: plannedArrival ? moment(plannedArrival).format(dateFormat) : '-',
      },
      {
        label: 'Revised ETA',
        value: stopEta ? moment(stopEta).format(dateFormat) : '-',
      },
    ],
    [order, plannedArrival, stopEta]
  );

  const orderOpen = React.useMemo(
    () => activeOrder === order.orderNumber,
    [activeOrder, order.orderNumber]
  );

  const openHandler = React.useCallback(() => {
    changeActiveOrderHandler(
      order.orderNumber === activeOrder ? undefined : order.orderNumber
    );
  }, [activeOrder, changeActiveOrderHandler, order.orderNumber]);

  return (
    <RecipientCard
      details={orderDetails}
      avatar={<BusinessCenter />}
      title="Order Details"
      orderNumber={order.orderNumber}
      externalOpen={orderOpen}
      externalHandler={openHandler}
      data-cy="RecipientOrderDetails"
    >
      {order.lines && (
        <div className={classes.row}>
          <Typography variant="body1" className={classes.label}>
            Products
          </Typography>
        </div>
      )}
      {order.lines?.map((orderLine, index) => (
        <RecipientOrderDetailsLine key={index} {...orderLine} />
      ))}
    </RecipientCard>
  );
};
