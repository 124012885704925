import {makeStyles, createStyles} from '@mui/styles';

const NavMenuButtonStyles = () =>
  createStyles({
    icon: {
      color: 'white',
      fontSize: 30,
    },
    iconDesktop: {
      fontSize: 40,
    },
  });

export const useNavMenuButtonStyles = makeStyles(NavMenuButtonStyles, {
  name: 'NavMenuButtonStyles',
});
