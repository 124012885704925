import React from 'react';
import {IconButton, Menu, MenuItem} from '@mui/material';
import {FilterList} from '@mui/icons-material';
import {usePlanningBoardGanttContext} from '../../planningBoardContext';

export type TPlanningBoardSelectedSearchOptions =
  | 'order'
  | 'endingSite'
  | 'site';

interface PlanningBoardToolbarSearchToggleProps {
  selectedSearchOption: TPlanningBoardSelectedSearchOptions;
  setSelectedSearchOption: React.Dispatch<
    React.SetStateAction<TPlanningBoardSelectedSearchOptions>
  >;
}

export const PlanningBoardToolbarSearchToggle: React.FC<
  PlanningBoardToolbarSearchToggleProps
> = ({selectedSearchOption, setSelectedSearchOption}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const {onClearSearchedItems} = usePlanningBoardGanttContext();

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleSelectOption = React.useCallback(
    (value: TPlanningBoardSelectedSearchOptions) =>
      (_e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setSelectedSearchOption(value);
        onClearSearchedItems();
        setAnchorEl(null);
      },
    [onClearSearchedItems, setSelectedSearchOption]
  );

  return (
    <>
      <IconButton
        aria-controls={open ? 'toggle-planning-board-search-menu' : undefined}
        aria-haspopup="true"
        size="medium"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <FilterList fontSize="inherit" />
      </IconButton>
      <Menu
        id="toggle-planning-board-search-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'toggle-planning-board-search-button',
        }}
      >
        <MenuItem
          selected={selectedSearchOption === 'order'}
          onClick={handleSelectOption('order')}
        >
          Search by Order
        </MenuItem>
        <MenuItem
          selected={selectedSearchOption === 'endingSite'}
          onClick={handleSelectOption('endingSite')}
        >
          Search by Ending Site
        </MenuItem>
        <MenuItem
          selected={selectedSearchOption === 'site'}
          onClick={handleSelectOption('site')}
        >
          Search by Site
        </MenuItem>
      </Menu>
    </>
  );
};
