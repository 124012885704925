import React from 'react';
import {Popup} from 'react-leaflet';

interface Props {
  address?: any;
}

export default class AddressPopup extends React.Component<Props> {
  render() {
    const {address} = this.props;
    return (
      <Popup>
        <span>
          <div>Name: {address.label}</div>
          <div>Latitude: {address.latitude}</div>
          <div>Longitude: {address.longitude}</div>
        </span>
      </Popup>
    );
  }
}
