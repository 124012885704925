import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import {IconButton, Avatar} from '@mui/material';
import {Marker, Popup} from 'react-leaflet';
import L from 'leaflet';
import IsochroneTimeOfDaySlider from './IsochroneTimeOfDaySlider';
import IsochroneTravelTimeSlider from './IsochroneTravelTimeSlider';
import {mapDisplayStore} from '../../../stores/mobxStores';
import {Loader} from '../../loader';

interface Props {
  distanceInMinutes: number;
  timeOfDay: number;
  updateOpenIsochrone: any;
  position: any;
}

export default function IsochroneMarker({
  updateOpenIsochrone,
  distanceInMinutes,
  timeOfDay,
  position,
}: Props) {
  const [popupLoading, setPopupLoading] = React.useState<boolean>(false);

  const icon = L.ExtraMarkers.icon({
    icon: 'fa-clock',
    markerColor: 'blue',
    shape: 'circle',
    prefix: 'fa',
  });

  return (
    <Marker position={position} icon={icon}>
      <Popup>
        {popupLoading ? (
          <Loader />
        ) : (
          <span>
            <div style={{display: 'flex', flexDirection: 'row', padding: 3}}>
              Road Travel Time Query
            </div>
            <IsochroneTimeOfDaySlider
              updateOpenIsochrone={updateOpenIsochrone}
              distanceInMinutes={distanceInMinutes}
              timeOfDay={timeOfDay}
              setPopupLoading={setPopupLoading}
            />
            <IsochroneTravelTimeSlider
              updateOpenIsochrone={updateOpenIsochrone}
              distanceInMinutes={distanceInMinutes}
              timeOfDay={timeOfDay}
              setPopupLoading={setPopupLoading}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                paddingTop: 10,
                justifyContent: 'space-between',
              }}
            >
              <Avatar aria-label="RenderIsochrone">
                <IconButton
                  onClick={() => mapDisplayStore.setOpenIsochrone(null)}
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </Avatar>
              <Avatar aria-label="QueryVehiclesByIsochrone">
                <IconButton
                  onClick={() => mapDisplayStore.setOpenIsochrone(null)}
                  size="large"
                >
                  <LocalShippingIcon />
                </IconButton>
              </Avatar>
              <Avatar aria-label="QueryDriversByIsochrone">
                <IconButton
                  onClick={() => mapDisplayStore.setOpenIsochrone(null)}
                  size="large"
                >
                  <PersonIcon />
                </IconButton>
              </Avatar>
              <Avatar aria-label="QueryTrailersByIsochrone">
                <IconButton
                  onClick={() => mapDisplayStore.setOpenIsochrone(null)}
                  size="large"
                >
                  <ShoppingCartIcon />
                </IconButton>
              </Avatar>
            </div>
          </span>
        )}
      </Popup>
    </Marker>
  );
}
