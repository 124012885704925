import {ResponseContext} from '@onroadvantage/onroadvantage-api';
import {appNotificationStore} from '../../stores/mobxStores';

const postFetchMiddleware = async ({response}: ResponseContext) => {
  const {status} = response;

  let type = '';
  let message = '';
  let displayNotification = false;

  if (!status) {
    // this should never happen
    type = 'warn';
    displayNotification = true;
    message = 'An unknown issue occurred';
  } else if (status < 200) {
    /* status code of 100-199 */
    type = 'warn';
    message = '100';
  } else if (status < 300) {
    /* status code of 200-299 */
    type = 'success';
    message = '200';
  } else if (status < 400) {
    /* status code of 300-301 */
    type = 'warn';
    message = '300';
  } else if (status < 500) {
    /* status code of 400-499 */
    type = 'error';
    message = '400';

    switch (status) {
      case 401:
        // handle unauthenticated
        message = 'The request was not authenticated';
        // history.replace(
        //   encodeURI(`/error-401?redirectTo=${history.location.pathname}`)
        // );
        break;
      case 403:
        // handle unauthorised
        message = 'Not authorised to make request';
        break;
      case 404:
        message = 'Not found';
        break;
      case 422:
        message = 'Unable to process data';
        break;
      case 429:
        message = 'Too many requests have been made';
        displayNotification = true;
        break;
      default:
        break;
    }
  } else if (status < 600) {
    /* status code of 500-599 */
    type = 'error';
    message = 'An error occurred on the server';
    displayNotification = true;
  }

  if (displayNotification) {
    appNotificationStore.enqueueNotification(type, message);
  }

  return response;
};

export const StatusMiddleware = {
  post: postFetchMiddleware,
};
