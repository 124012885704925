import {makeStyles, createStyles} from '@mui/styles';

const NavNotificationButtonStyles = () =>
  createStyles({
    bottomOperationalBadge: {
      top: 12,
      backgroundColor: 'orange',
      color: 'white',
    },
    icon: {
      color: 'white',
      fontSize: 30,
    },
    iconDesktop: {
      fontSize: 40,
    },
    topCriticalBadge: {
      backgroundColor: 'red',
      color: 'white',
      top: -12,
    },
  });

export const useNavNotificationButtonStyles = makeStyles(
  NavNotificationButtonStyles,
  {
    name: 'NavNotificationButtonStyles',
  }
);
