import {makeStyles, createStyles} from '@mui/styles';
import {Theme} from '@mui/material';

const CompartmentVerticalStyles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'stretch',
      flex: 1,
      padding: theme.spacing(2.5),
    },
  });

export const useCompartmentVerticalStyles = makeStyles(
  CompartmentVerticalStyles,
  {name: 'CompartmentVerticalStyles'}
);
