import React from 'react';
import {useFormikContext} from 'formik';
import {
  FormHelperText,
  FormControlLabel,
  Switch,
  Stack,
  StackProps,
} from '@mui/material';

interface FormikSwitchProps {
  disabled?: boolean;
  label: string;
  name: string;
  stackProps?: StackProps;
}

export const FormikSwitch: React.FC<FormikSwitchProps> = ({
  disabled,
  label,
  name,
  stackProps,
  ...props
}) => {
  const {isSubmitting, getFieldMeta, getFieldHelpers} = useFormikContext();
  const {
    error,
    touched,
    initialError,
    initialTouched,
    initialValue,
    value,
    ...meta
  } = getFieldMeta<boolean>(name);
  const {setValue} = getFieldHelpers<boolean>(name);

  const displayError = React.useMemo(
    () => (touched ? error : undefined),
    [error, touched]
  );

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setValue(checked);
    },
    [setValue]
  );

  return (
    <Stack {...stackProps}>
      <FormControlLabel
        control={
          <Switch
            disabled={disabled || isSubmitting}
            {...props}
            {...meta}
            id={name}
            name={name}
            onChange={handleChange}
            checked={value}
          />
        }
        label={label}
      />
      <FormHelperText error={!!displayError}>{displayError}</FormHelperText>
    </Stack>
  );
};
