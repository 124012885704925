import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';
import {green, amber} from '@mui/material/colors';

const SystemSnackbarStyles = (theme: Theme) =>
  createStyles({
    success: {backgroundColor: green[600]},
    error: {backgroundColor: theme.palette.error.dark},
    info: {backgroundColor: theme.palette.primary.dark},
    warning: {backgroundColor: amber[700]},
    icon: {fontSize: 20},
    iconVariant: {fontSize: 20, opacity: 0.9, marginRight: theme.spacing(1)},
  });

export const useSystemSnackbarStyles = makeStyles(SystemSnackbarStyles);
