import React from 'react';
import {Card, CardProps, Divider} from '@mui/material';
import {
  TemplateStepperActions,
  TemplateStepperActionsProps,
} from './TemplateStepperActions';
import {
  TemplateStepperHeader,
  TemplateStepperHeaderProps,
} from './TemplateStepperHeader';
import {
  TemplateStepperStepper,
  TemplateStepperStepperProps,
} from './TemplateStepperStepper';
import {TemplateStepperContent} from './TemplateStepperContent';

export interface TemplateStepperProps
  extends CardProps,
    TemplateStepperActionsProps,
    TemplateStepperHeaderProps,
    TemplateStepperStepperProps {}

export const TemplateStepper: React.FC<TemplateStepperProps> = ({
  activeStep,
  children,
  nextDisabled,
  nextTitle,
  onNext,
  onPrev,
  prevDisabled,
  prevTitle,
  steps,
  title,
}) => (
  <Card elevation={0}>
    <TemplateStepperHeader title={title} />
    <Divider />
    <TemplateStepperStepper activeStep={activeStep} steps={steps} />
    <Divider />
    <TemplateStepperActions
      nextDisabled={nextDisabled}
      nextTitle={nextTitle}
      onNext={onNext}
      onPrev={onPrev}
      prevDisabled={prevDisabled}
      prevTitle={prevTitle}
    />
    <Divider />
    <TemplateStepperContent>{children}</TemplateStepperContent>
    <Divider />
    <TemplateStepperActions
      nextDisabled={nextDisabled}
      nextTitle={nextTitle}
      onNext={onNext}
      onPrev={onPrev}
      prevDisabled={prevDisabled}
      prevTitle={prevTitle}
    />
  </Card>
);
