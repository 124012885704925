import React from 'react';
import {
  Button,
  Divider,
  SpeedDial,
  SpeedDialAction,
  Stack,
} from '@mui/material';
import {Sort, LibraryAdd, LibraryBooks, Warehouse} from '@mui/icons-material';
import {TripSetupStopsStepSorting} from './TripSetupStopsStepSorting';
import {TripSetupStopsStepExistingOrder} from './TripSetupStopsStepExistingOrder';
import {TripSetupStopsStepDepot} from './TripSetupStopsStepDepot';
import {TripSetupStopsStepNewOrder} from './TripSetupStopsStepNewOrder';
import {useTripSetupStopsStepStyles} from './TripSetupStopsStep.styles';
import {useTripSetupContext} from '../TripSetupContext';
import {TemplateCard} from '../../../../factory/template';
import {getLengthOfStops} from '../helpers/getLengthOfStops';
import {TripSetupStopsStepContext} from './TripSetupStopsStepContext';

const tripSetupStopsStepViewStates = [
  'sortStops',
  'existingOrder',
  'newOrder',
  'depot',
] as const;

export type TripSetupStopsStepViewStates =
  (typeof tripSetupStopsStepViewStates)[number];

const tripSetupStopsStepAddingFormsElements: {
  [key in TripSetupStopsStepViewStates]: React.ReactNode;
} = {
  sortStops: <TripSetupStopsStepSorting />,
  existingOrder: <TripSetupStopsStepExistingOrder />,
  newOrder: <TripSetupStopsStepNewOrder />,
  depot: <TripSetupStopsStepDepot />,
};

const tripSetupStopsStepAddingTitle: {
  [key in TripSetupStopsStepViewStates]: string;
} = {
  existingOrder: 'Adding existing order(s)',
  newOrder: 'Adding new order',
  depot: 'Adding depot',
  sortStops: 'Sorting/Removing stops',
};

const tripSetupStopsStepAddingOptions: {
  [key in TripSetupStopsStepViewStates]: {
    icon: React.ReactNode;
    label: string;
  };
} = {
  sortStops: {
    icon: <Sort />,
    label: 'Sort Stops',
  },
  existingOrder: {
    icon: <LibraryBooks />,
    label: 'Existing Order',
  },
  newOrder: {
    icon: <LibraryAdd />,
    label: 'New Order',
  },
  depot: {
    icon: <Warehouse />,
    label: 'Depot',
  },
};

export const TripSetupStopsStep = () => {
  const classes = useTripSetupStopsStepStyles();
  const {stops, orders} = useTripSetupContext();
  const [viewState, setViewState] =
    React.useState<TripSetupStopsStepViewStates>(
      stops.length > 0 ? 'sortStops' : 'newOrder'
    );

  const [open, setOpen] = React.useState(false);

  const handleSetViewViewState = (
    newViewState: TripSetupStopsStepViewStates
  ) => {
    setOpen(false);
    setViewState((currentAddingState) =>
      currentAddingState === newViewState ? 'sortStops' : newViewState
    );
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <TemplateCard
      title={tripSetupStopsStepAddingTitle[viewState]}
      avatar={tripSetupStopsStepAddingOptions[viewState].icon}
      style={{position: 'relative'}}
    >
      <Stack minHeight={130} justifyContent="center" flex={1}>
        <TripSetupStopsStepContext.Provider value={{setViewState}}>
          {tripSetupStopsStepAddingFormsElements[viewState]}
        </TripSetupStopsStepContext.Provider>
      </Stack>
      <Stack
        pt={2}
        spacing={1}
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
      >
        <Button
          size="small"
          color="inherit"
          sx={{textTransform: 'none'}}
          onClick={() => setViewState('sortStops')}
        >
          {stops.length > 0 ? getLengthOfStops(stops) : 'NO'} Stops
        </Button>
        <Button
          size="small"
          color="inherit"
          sx={{textTransform: 'none'}}
          onClick={() => setViewState('existingOrder')}
        >
          {orders.length > 0 ? orders.length : 'NO'} Orders
        </Button>
      </Stack>
      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        sx={{
          position: 'absolute',
          bottom: 16,
          right: 16,
        }}
        icon={tripSetupStopsStepAddingOptions[viewState].icon}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        FabProps={{size: 'small'}}
      >
        {tripSetupStopsStepViewStates.map((action) => (
          <SpeedDialAction
            key={action}
            color={action === viewState ? 'primary' : 'inherit'}
            icon={tripSetupStopsStepAddingOptions[action].icon}
            tooltipTitle={tripSetupStopsStepAddingOptions[action].label}
            classes={{staticTooltip: classes.speedDialTooltip}}
            onClick={() => handleSetViewViewState(action)}
            tooltipOpen
          />
        ))}
      </SpeedDial>
    </TemplateCard>
  );
};
