import React from 'react';
import _ from 'lodash';
import {
  ITemplateControllerRoute,
  TemplateController,
} from '../../factory/template';
import {
  TrainingCourseContext,
  TrainingCourseContextProvider,
} from './TrainingCourseContext';
import {TrainingCourseList} from './TrainingCourseList';
import {TrainingCourseIdHandler} from './TrainingCourseIdHandler';
import {TrainingCourseForm} from './TrainingCourseForm';
import {TrainingCourse} from './TrainingCourse';
import {
  TrainingCourseUser,
  TrainingCourseUserContext,
  TrainingCourseUserContextProvider,
  TrainingCourseUserIdHandler,
} from './trainingCourseUser';
import {TrainingCourseUserAddTable} from './trainingCourseUser/TrainingCourseUserAddTable';

export const TrainingCourseController: React.FC = () => {
  return (
    <TrainingCourseContextProvider>
      <TrainingCourseUserContextProvider>
        <TrainingCourseControllerComponent />
      </TrainingCourseUserContextProvider>
    </TrainingCourseContextProvider>
  );
};

const TrainingCourseControllerComponent: React.FC = () => {
  const {trainingCourse, loading} = React.useContext(TrainingCourseContext);
  const {trainingCourseUser, loading: userLoading} = React.useContext(
    TrainingCourseUserContext
  );
  const routes: ITemplateControllerRoute[] = React.useMemo(
    () => [
      {title: 'Training Course List', component: TrainingCourseList},
      {
        path: '/add',
        title: 'New Training Course',
        name: 'Add TrainingCourse',
        type: 'Add',
        component: () => <TrainingCourseForm isAdd />,
      },
      {
        path: '/:trainingCourseId',
        title: (params) =>
          loading
            ? 'Training Course Loading...'
            : trainingCourse?.name ??
              `Training Course ${_.get(params, 'trainingCourseId', 'Single')}`,
        component: TrainingCourse,
        IdHandler: TrainingCourseIdHandler,
      },
      {
        path: '/:trainingCourseId/user/add',
        title: 'Assign Users To Training Course',
        name: 'Add UserTrainingCourse',
        type: 'Add',
        component: TrainingCourseUserAddTable,
      },
      {
        path: '/:trainingCourseId/user/:trainingCourseUserId',
        title: (params) =>
          userLoading
            ? 'User Training Course Loading...'
            : trainingCourseUser?.user?.email ??
              `User Training Course ${_.get(
                params,
                'trainingCourseUserId',
                'Single'
              )}`,
        component: TrainingCourseUser,
        IdHandler: TrainingCourseUserIdHandler,
      },
    ],
    [
      loading,
      trainingCourse?.name,
      trainingCourseUser?.user?.email,
      userLoading,
    ]
  );
  return <TemplateController rootPath="/trainingcourselist" routes={routes} />;
};
