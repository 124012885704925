import React from 'react';
import {Close} from '@mui/icons-material';
import {IconButton} from '@mui/material';
import {usePlanningBoardGanttContext} from '../../planningBoardContext';
import {usePlanningBoardTripContext} from './planningBoardTripContext';

export const PlanningBoardTripClose: React.FC = () => {
  const {onClearSelectedGanttItem} = usePlanningBoardGanttContext();
  const {onClearTripState} = usePlanningBoardTripContext();

  const handleClose = React.useCallback(() => {
    onClearTripState();
    onClearSelectedGanttItem();
  }, [onClearSelectedGanttItem, onClearTripState]);

  return (
    <IconButton onClick={handleClose} size="medium">
      <Close fontSize="large" />
    </IconButton>
  );
};
