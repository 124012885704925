import React from 'react';
import PropTypes from 'prop-types';
import {ImageOverlay} from 'react-leaflet';
import ConfigService from "../../service/ConfigService/ConfigService";

// util functions
// https://wiki.openstreetmap.org/wiki/Slippy_map_tilenames#Lon..2Flat._to_tile_numbers
const lon2tile = (lon, zoom) => Math.floor(((lon + 180) / 360) * 2 ** zoom);
const lat2tile = (lat, zoom) => {
  return Math.floor(
    ((1 -
      Math.log(
        Math.tan((lat * Math.PI) / 180) + 1 / Math.cos((lat * Math.PI) / 180)
      ) /
        Math.PI) /
      2) *
      2 ** zoom
  );
};
const tile2lon = (x, z) => (x / 2 ** z) * 360 - 180;
const tile2lat = (y, z) => {
  const n = Math.PI - (2 * Math.PI * y) / 2 ** z;
  return (180 / Math.PI) * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n)));
};

class TileOverlay extends React.Component {
  render() {
    const {bounds, location, singleTile, zoom} = this.props;
    const tiles = [];
    const baseUrl =
      `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=${ConfigService.mapboxAccessToken}`;
    const regX = new RegExp('{x}', 'g');
    const regY = new RegExp('{y}', 'g');
    const regZ = new RegExp('{z}', 'g');

    if (singleTile) {
      // calculate tile
      const x = lon2tile(location.lng, zoom);
      const y = lat2tile(location.lat, zoom);
      const bounds_ = [
        [tile2lat(y, zoom), tile2lon(x, zoom)],
        [tile2lat(y + 1, zoom), tile2lon(x + 1, zoom)],
      ];

      tiles.push({
        bounds: bounds_,
        url: baseUrl.replace(regX, x).replace(regY, y).replace(regZ, zoom),
      });
    } else {
      const east = lon2tile(bounds.east, zoom);
      const north = lat2tile(bounds.north, zoom);
      const south = lat2tile(bounds.south, zoom);
      const west = lon2tile(bounds.west, zoom);

      for (let x = west; x <= east; x += 1) {
        for (let y = north; y <= south; y += 1) {
          const bounds_ = [
            [tile2lat(y, zoom), tile2lon(x, zoom)],
            [tile2lat(y + 1, zoom), tile2lon(x + 1, zoom)],
          ];

          tiles.push({
            bounds: bounds_,
            url: baseUrl.replace(regX, x).replace(regY, y).replace(regZ, zoom),
          });
        }
      }
    }

    return (
      <React.Fragment>
        {tiles.map((args) => (
          <ImageOverlay key={args.url} bounds={args.bounds} url={args.url} />
        ))}
      </React.Fragment>
    );
  }
}

TileOverlay.propTypes = {
  bounds: PropTypes.shape({
    east: PropTypes.number.isRequired,
    north: PropTypes.number.isRequired,
    south: PropTypes.number.isRequired,
    west: PropTypes.number.isRequired,
  }),
  location: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }).isRequired,
  singleTile: PropTypes.bool,
  zoom: PropTypes.number,
};

TileOverlay.defaultProps = {
  singleTile: true,
  zoom: 9,
};

export default TileOverlay;
