import React from 'react';
import {Paper, IconButton, Typography} from '@mui/material';
import {Cancel} from '@mui/icons-material';
import {useAuthenticationCookiePolicyStyles} from './AuthenticationCookiePolicy.style';

interface AuthenticationCookiePolicyProps {
  onToggle: () => void;
}

export const AuthenticationCookiePolicy: React.FC<
  AuthenticationCookiePolicyProps
> = ({onToggle}) => {
  const classes = useAuthenticationCookiePolicyStyles();
  return (
    <div className={classes.container}>
      <Paper className={classes.content}>
        <div className={classes.closeIcon}>
          <IconButton onClick={onToggle} size="large">
            <Cancel />
          </IconButton>
        </div>
        <Typography className={classes.header}>
          VANTAGE
          <br />
          Cookie Policy
        </Typography>
        <Typography variant="body1" component="div" className={classes.body}>
          <p>
            What are cookies and how does Vantage and Viewmetrics use cookies on
            their sites?
          </p>
          <p>
            We may use cookies to track your preferences and activities on a
            Viewmetrics Affiliate{"'"}s website.
          </p>
          <p>
            Cookies are small data files transferred to your computer{"'"}s
            hard-drive by a website. They keep a record of your preferences
            making subsequent visits to the site more efficient. Cookies may
            store a variety of information, including, the number of times that
            you access a site, registration information and the number of times
            that you view a particular page or other item on the site.
            <br />
            The use of cookies is a common practice adopted by most major sites
            to better serve their clients. Most browsers are designed to accept
            cookies, but they can be easily modified to block cookies; see your
            browser{"'"}s help files for details on how to block cookies, how to
            know when you have received cookies and not to disable cookies
            completely.
          </p>
        </Typography>
      </Paper>
    </div>
  );
};
