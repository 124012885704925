import React from 'react';
import {FormikErrors as FormikErrorsType} from 'formik/dist/types';
import {FormikTouched, useFormikContext} from 'formik';
import _ from 'lodash';
import {Badge, ButtonBaseProps} from '@mui/material';

interface Props extends ButtonBaseProps {
  fields: string[];
}

const getHasFieldErrors = (
  errors: FormikErrorsType<any>,
  touched: FormikTouched<any>,
  fields: string[]
): boolean => {
  const errorFields = _.keys(errors);
  const touchedFields = _.keys(touched);

  return _.intersection(fields, touchedFields, errorFields).length > 0;
};

export const ReportScheduleFormTab: React.FC<Props> = ({
  children,
  fields,
  ...props
}) => {
  const {errors, touched} = useFormikContext();
  return (
    <button type="button" {...props}>
      <Badge
        variant="dot"
        color="error"
        invisible={!getHasFieldErrors(errors, touched, fields)}
      >
        {children}
      </Badge>
    </button>
  );
};
