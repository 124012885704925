import React from 'react';
import {authStore} from '../../store';
import {observer} from 'mobx-react';
import {AuthedRouter} from './AuthedRouter';
import {UnAuthedRouter} from './UnAuthedRouter';
import history from '../../service/history';
import {DashboardRouter} from './DashboardRouter';

const dashboardPaths = ['/dashboard_standalone', '/dashboard_datastudio'];

export const MasterRouterComponent: React.FC = () => {
  const auth = authStore.getAuth;

  if (
    new URLSearchParams(history.location.search).has('authToken') &&
    dashboardPaths.includes(history.location.pathname)
  ) {
    return <DashboardRouter />;
  }

  if (auth && (auth.authenticated || auth.oauthAuthenticated)) {
    return <AuthedRouter />;
  }

  return <UnAuthedRouter />;
};

export const MasterRouter = observer(MasterRouterComponent);
