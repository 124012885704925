import {makeStyles, createStyles} from '@mui/styles';
import {Theme} from '@mui/material';

const VehicleTypeCapacityCompartmentTruckStyles = (theme: Theme) =>
  createStyles({
    root: {margin: theme.spacing(2)},
  });

export const useVehicleTypeCapacityCompartmentTruckStyles = makeStyles(
  VehicleTypeCapacityCompartmentTruckStyles,
  {
    name: 'VehicleTypeCapacityCompartmentTruckStyles',
  }
);
