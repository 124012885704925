import React from 'react';
import {Icon} from '@mui/material';
import {DataTypeProvider} from '@devexpress/dx-react-grid';
import {TableActionButtonDelete} from './TableActionButtonDelete';
import {TableActionButtonNavigate} from './TableActionButtonNavigate';
import {useTableActionBodyCellStyles} from './TableActionBodyCell.style';
import {TableActionButtonExpand} from './TableActionButtonExpand';
import {TableActionButtonEdit} from './TableActionButtonEdit';
import {TableActionButtonSave} from './TableActionButtonSave';
import {TableActionButtonDownload} from './TableActionButtonDownload';
import {TableActionSelect} from './TableActionSelect';
import {useTemplateTableActions} from '../../providers';
import {TableActionButtonBase} from './TableActionButtonBase';
import {useInternalTableContext} from '../../TemplateTable';
import {TableActionDragAndDrop} from './TableActionDragAndDrop';
import {TableActionWarning} from './TableActionWarning';

interface TableActionBodyCellProps
  extends DataTypeProvider.ValueFormatterProps {
  editor?: boolean;
}

export const TableActionBodyCell: React.FC<TableActionBodyCellProps> = ({
  editor,
  ...otherProps
}) => {
  const {selection} = useInternalTableContext();
  const {
    onNavigate,
    onDelete,
    enableRowEdit,
    rowDetail,
    onDownloadItem,
    enableInlineEdit,
    enableInlineAdd,
    onDragStart,
    onWarning,
  } = useTemplateTableActions();
  const classes = useTableActionBodyCellStyles();
  const renderEditorComponents = () => (
    <>
      {(enableInlineEdit || enableInlineAdd) && (
        <TableActionButtonSave {...otherProps} />
      )}
    </>
  );
  const renderFormatterComponents = () => {
    /**
     * Reason we do this is so that when the action column is visible with one of the editor comp are provided (onAdd...)
     * and there is no formatter (inline) actions, we add this placeholder to fix the styling bug when we chose to have
     * different background from white, which was apparent in the dark mode.
     */
    if (
      !selection &&
      !onNavigate &&
      !onDelete &&
      (!enableInlineEdit ||
        (enableRowEdit !== undefined && !enableRowEdit(otherProps.row))) &&
      !rowDetail &&
      !onDownloadItem &&
      !onDragStart &&
      !onWarning
    ) {
      return (
        <TableActionButtonBase disabled>
          <Icon />
        </TableActionButtonBase>
      );
    }
    return (
      <>
        <TableActionSelect {...otherProps} />
        <TableActionButtonNavigate {...otherProps} />
        <TableActionButtonDelete {...otherProps} />
        <TableActionButtonEdit {...otherProps} />
        <TableActionButtonExpand {...otherProps} />
        <TableActionButtonDownload {...otherProps} />
        <TableActionDragAndDrop {...otherProps} />
        <TableActionWarning {...otherProps} />
      </>
    );
  };
  return (
    <div className={classes.root}>
      {editor ? renderEditorComponents() : renderFormatterComponents()}
    </div>
  );
};
