import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

const PlanningBoardTripFormStyles = (theme: Theme) =>
  createStyles({
    divider: {marginTop: theme.spacing(1), marginBottom: theme.spacing(2)},
    dividerVertical: {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      flex: 2,
    },
    formContainer: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    selectTimeContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    selectedTripStart: {
      alignSelf: 'flex-start',
      flex: 2,
      marginTop: theme.spacing(2),
    },
    selectedLoadingStart: {
      alignSelf: 'center',
      flex: 2,
      marginTop: theme.spacing(1),
    },
    selectedOffloadingStart: {
      alignSelf: 'flex-end',
      flex: 2,
      marginBottom: theme.spacing(1),
    },
    radio: {flex: 1, display: 'flex'},
    selectTimePicker: {marginBottom: theme.spacing(1)},
    expanded: {alignItems: 'center'},
  });

export const usePlanningBoardTripFormStyles = makeStyles(
  PlanningBoardTripFormStyles,
  {
    name: 'PlanningBoardTripFormStyles',
  }
);
