import React from 'react';
import {ITemplateTabControllerTab, TemplateTabs} from '../../factory/template';
import {WorkflowConfigContext} from './WorkflowConfigContext';
import {WorkflowConfigForm} from './WorkflowConfigForm';
import {WorkflowConfigTaskTemplateList} from './WorkflowConfigTaskTemplateList';
import {WorkflowConfigYardFlowConfigList} from './WorkflowConfigYardFlowConfigList';
import {
  WorkflowConfigTaskLinkingList,
  WorkflowConfigTaskTemplateLinkingListContextProvider,
} from './workflowConfigTaskLinkingList';

export const WorkflowConfig: React.FC = () => {
  const {workflowConfigId, loading, loadingSingleItem} = React.useContext(
    WorkflowConfigContext
  );

  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {title: 'Details', element: <WorkflowConfigForm />},
    {title: 'Template Tasks', element: <WorkflowConfigTaskTemplateList />},
    {title: 'Yardflow Configs', element: <WorkflowConfigYardFlowConfigList />},
    {
      title: 'Task Linking',
      element: (
        <WorkflowConfigTaskTemplateLinkingListContextProvider>
          <WorkflowConfigTaskLinkingList />
        </WorkflowConfigTaskTemplateLinkingListContextProvider>
      ),
    },
  ]);

  const path = React.useMemo(
    () => `/workflowconfiglist/${workflowConfigId}`,
    [workflowConfigId]
  );

  return (
    <TemplateTabs
      tabs={tabs}
      path={path}
      loading={loading || loadingSingleItem}
    />
  );
};
