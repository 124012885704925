import React from 'react';
import {InputAdornment, TextField, Typography} from '@mui/material';
import {useFormikContext} from 'formik';
import {
  ICapacityCompartment,
  IVehicleTypeCapacityForm,
} from '../VehicleTypeCapacityForm';
import clone from 'rfdc';
import clsx from 'clsx';
import {useVehicleTypeCapacityCompartmentTruckCompartmentStyles} from './VehicleTypeCapacityCompartmentTruckCompartment.style';
import {VehicleCompartment} from '@onroadvantage/onroadvantage-api';

export interface VehicleCompartmentWithCapacity extends VehicleCompartment {
  capacity?: number | null | undefined;
}

interface VehicleTypeCapacityCompartmentTruckCompartmentProps {
  compartmentWithCapacity: VehicleCompartmentWithCapacity | undefined;
  fieldFocus: number | undefined;
}

export const VehicleTypeCapacityCompartmentTruckCompartment: React.FC<
  VehicleTypeCapacityCompartmentTruckCompartmentProps
> = ({compartmentWithCapacity, fieldFocus}) => {
  const classes = useVehicleTypeCapacityCompartmentTruckCompartmentStyles();

  const {setFieldValue, values} = useFormikContext<IVehicleTypeCapacityForm>();

  const [compartmentCapacity, setCompartmentCapacity] = React.useState<
    number | null | undefined
  >(compartmentWithCapacity?.capacity);

  const handleChangeCapacity = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCompartmentCapacity(parseInt(e.target.value) || 0);
      const capDimensions = clone()(values.capacityCompartments);

      if (capDimensions) {
        const changedCompartment = capDimensions.find(
          ({compartmentId}) => compartmentId === compartmentWithCapacity?.id
        );
        if (changedCompartment) {
          changedCompartment.capacity = parseInt(e.target.value) || 0;
          setFieldValue('capacityCompartments', capDimensions);
        } else {
          const newValue: ICapacityCompartment = {
            id: undefined,
            capacity: parseInt(e.target.value) || 0,
            compartmentId: compartmentWithCapacity?.id,
          };
          setFieldValue('capacityCompartments', [...capDimensions, newValue]);
        }
      }
    },
    [compartmentWithCapacity?.id, setFieldValue, values.capacityCompartments]
  );

  return (
    <div className={clsx(classes.compartment)}>
      <Typography variant="subtitle1">
        <strong>{compartmentWithCapacity?.name}</strong>
      </Typography>
      <div className={clsx(classes.divider)} />
      <div className={classes.fieldContainer}>
        <TextField
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={fieldFocus === compartmentWithCapacity?.id}
          name={`${compartmentWithCapacity?.name} Capacity`}
          label="Capacity"
          value={compartmentCapacity}
          onChange={handleChangeCapacity}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Typography component="legend" color="textSecondary">
                  {values?.unitOfMeasure}
                </Typography>
              </InputAdornment>
            ),
          }}
          helperText={
            values?.capacity && 0 > values?.capacity
              ? `Total capacity is ${values.capacity}${values.unitOfMeasure}`
              : undefined
          }
          error={!!(values?.capacity && 0 > values?.capacity)}
          fullWidth
        />
      </div>
    </div>
  );
};
