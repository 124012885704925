import React from 'react';
import {Filter, Sorting} from '@devexpress/dx-react-grid';
import _ from 'lodash';
import {unstable_batchedUpdates} from 'react-dom';
import {getStringFromSorting} from '../../../service/Util';
import {ITemplatePermissions} from './TemplateTableWrapper';
import {RoleService, XLSXService} from '../../../service';
import {IListLoadOptions, TLoadList} from './TemplateTableHelpers';
import {useAppNotifications} from '../../../contexts';
import DateRangeContext from '../../../contexts/DateRangeContext';

export interface ITemplateTablePermissions {
  add?: boolean;
  delete?: boolean;
  download?: boolean;
  edit?: boolean;
  view?: boolean;
}

type ReactSetState<T> = React.Dispatch<React.SetStateAction<T>>;

type TUseTemplateTableResponse<ResponseItem, ListRequest> = [
  {
    currentPage: number;
    filters: Filter[];
    hasPermission: ITemplateTablePermissions;
    isDaterangeFilterActive: boolean;
    itemTotal: number;
    list: ResponseItem[];
    loading: boolean;
    loadingSingleItem: boolean;
    pageSize: number;
    pageTotal: number;
    sorting: Sorting[];
  },
  {
    // Getters
    getDownloads: (
      name: string,
      loadList: TLoadList<any>,
      responseMapping: {
        name: string;
        path: keyof ResponseItem | string;
      }[],
      customParser?: any
    ) => void;
    getPermissions: (
      type: string,
      overridePermissions?: {
        add?: boolean;
        delete?: boolean;
        edit?: boolean;
      }
    ) => ITemplatePermissions;
    getResponse: (
      response: any,
      options?: IListLoadOptions
    ) => {items: ResponseItem[]} | undefined;
    getRequestObj: (
      columns: Array<keyof ListRequest>,
      options?: IListLoadOptions,
      params?: ListRequest
    ) => ListRequest;
    // Handlers
    cleanupList: () => void;
    handleCurrentPageChange: (newCurrentPage: number) => void;
    handleDateRangeFilterToggle: () => void;
    handleFiltersChange: (newFilters: Filter[]) => void;
    handlePageSizeCountsChange: (newPageSize: number) => void;
    handleSortingChange: (newSorting: Sorting[]) => void;
    // Setters
    setCurrentPage: ReactSetState<number>;
    setFilters: ReactSetState<Filter[]>;
    setHasPermission: ReactSetState<ITemplateTablePermissions>;
    setItemTotal: ReactSetState<number>;
    setIsDaterangeFilterActive: ReactSetState<boolean>;
    setList: ReactSetState<ResponseItem[]>;
    setLoading: ReactSetState<boolean>;
    setLoadingSingleItem: ReactSetState<boolean>;
    setPageSize: ReactSetState<number>;
    setPageTotal: ReactSetState<number>;
    setSorting: ReactSetState<Sorting[]>;
  }
];

export const useTemplateTable = <
  ResponseItem,
  ListRequest extends {page?: number; perPage?: number}
>(options?: {
  addPermission?: string;
  deletePermission?: string;
  downloadPermission?: string;
  editPermission?: string;
  enableDateRangeFilter?: boolean;
  viewPermission?: string;
}): TUseTemplateTableResponse<ResponseItem, ListRequest> => {
  const notify = useAppNotifications();
  const {
    dateRange: {startDate, endDate},
  } = React.useContext(DateRangeContext);
  // Conditionals
  const [isDaterangeFilterActive, setIsDaterangeFilterActive] =
    React.useState<boolean>(!!options?.enableDateRangeFilter);
  const [hasPermission, setHasPermission] =
    React.useState<ITemplateTablePermissions>({});
  // Loading
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loadingSingleItem, setLoadingSingleItem] =
    React.useState<boolean>(false);
  // Pagination
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [itemTotal, setItemTotal] = React.useState<number>(0);
  const [pageSize, setPageSize] = React.useState<number>(25);
  const [pageTotal, setPageTotal] = React.useState<number>(0);
  // Lists
  const [filters, setFilters] = React.useState<Filter[]>([]);
  const [list, setList] = React.useState<ResponseItem[]>([]);
  const [sorting, setSorting] = React.useState<Sorting[]>([]);

  const getResponse = React.useCallback<
    TUseTemplateTableResponse<ResponseItem, ListRequest>[1]['getResponse']
  >(
    (response, options) => {
      if (options?.dataOnly) return response;
      else {
        if (response) {
          setItemTotal(response.total ?? 0);
          setList(response.items ?? []);
          setPageTotal(response.pages ?? 1);
          return response;
        }
        notify('error', 'Failed to load list');
      }
    },
    [notify]
  );

  const getDownloads = React.useCallback<
    TUseTemplateTableResponse<ResponseItem, ListRequest>[1]['getDownloads']
  >(
    async (name, loadList, responseMapping, customParser) => {
      setLoading(true);
      try {
        const data = await loadList({
          dataOnly: true,
          overrides: {perPage: 10000},
        });
        if (!data) notify('warning', `No ${name} Data`);

        XLSXService.downloadSpreadsheet(
          data.items,
          responseMapping,
          name,
          customParser ?? null
        );
        notify('success', `Downloaded ${name} List`);
      } catch (e) {
        notify('error', e.message ?? `Failed to download ${name} list`);
      } finally {
        setLoading(false);
      }
    },
    [notify]
  );

  const getPermissions = React.useCallback<
    TUseTemplateTableResponse<ResponseItem, ListRequest>[1]['getPermissions']
  >(
    (type, overridePermission) => ({
      add:
        overridePermission?.add ??
        RoleService.hasPermission(`Add ${type}`, 'Add'),
      delete:
        overridePermission?.delete ??
        RoleService.hasPermission(`Delete ${type}`, 'Delete'),
      edit:
        overridePermission?.edit ??
        RoleService.hasPermission(`Edit ${type}`, 'Edit'),
    }),
    []
  );

  const getRequestObj = React.useCallback<
    TUseTemplateTableResponse<ResponseItem, ListRequest>[1]['getRequestObj']
  >(
    (columns, options, defaults) => {
      const requestObj: {[key: string]: any} = {
        page: options?.dataOnly ? 1 : currentPage,
        perPage:
          options?.dataOnly && options.overrides?.perPage
            ? options.overrides.perPage
            : pageSize,
        ...defaults,
      };
      // filters
      filters?.forEach((f) => {
        if (columns.includes(f.columnName as (typeof columns)[number])) {
          requestObj[f.columnName] = f.value;
        }
      });
      if (isDaterangeFilterActive) {
        if (startDate) {
          requestObj.startDate = new Date(startDate);
        }
        if (endDate) {
          requestObj.endDate = new Date(endDate);
        }
      }
      // sorting
      const orderBy = getStringFromSorting(sorting);
      if (orderBy && orderBy !== '') {
        requestObj.orderBy = orderBy;
      }
      return requestObj as ListRequest;
    },
    [
      currentPage,
      endDate,
      filters,
      isDaterangeFilterActive,
      pageSize,
      sorting,
      startDate,
    ]
  );

  const cleanupList = React.useCallback(() => {
    setList([]);
  }, []);

  const handleDateRangeFilterToggle = React.useCallback(() => {
    setIsDaterangeFilterActive(
      (prevIsDaterangeFilterActive) => !prevIsDaterangeFilterActive
    );
  }, []);

  const handlePageSizeCountsChange = React.useCallback(
    (newPageSize: number) => {
      setCurrentPage(1);
      setPageSize(newPageSize);
    },
    []
  );
  const handleCurrentPageChange = React.useCallback(
    (newCurrentPage: number) => {
      setCurrentPage(
        newCurrentPage < 0
          ? 0
          : newCurrentPage > pageTotal
          ? pageTotal
          : newCurrentPage + 1
      );
    },
    [pageTotal]
  );

  const handleFiltersChangeDebounce = _.debounce(
    (newFilters: Filter[]) =>
      unstable_batchedUpdates(() => {
        setCurrentPage(1);
        setFilters(
          newFilters.map((newFilter) => {
            const splitColumn = newFilter.columnName.split('.');
            let columnName = newFilter.columnName;
            if (splitColumn.length > 1) {
              columnName = splitColumn
                .map((word, index) =>
                  index !== 0 ? word[0].toUpperCase() + word.slice(1) : word
                )
                .join('');
            }
            return {...newFilter, columnName};
          })
        );
      }),
    500
  );
  const handleFiltersChange = React.useCallback(
    (newFilters: Filter[]) => handleFiltersChangeDebounce(newFilters),
    [handleFiltersChangeDebounce]
  );

  const handleSortingChangeDebounce = _.debounce(
    (newSorting: Sorting[]) =>
      unstable_batchedUpdates(() => {
        setCurrentPage(1);
        setSorting(newSorting);
      }),
    500
  );
  const handleSortingChange = React.useCallback(
    (newSorting: Sorting[]) => handleSortingChangeDebounce(newSorting),
    [handleSortingChangeDebounce]
  );

  const addPermission = options?.addPermission;
  const deletePermission = options?.deletePermission;
  const downloadPermission = options?.downloadPermission;
  const editPermission = options?.editPermission;
  const viewPermission = options?.viewPermission;
  React.useEffect(() => {
    setHasPermission({
      add: RoleService.hasPermission(addPermission, 'Add'),
      delete: RoleService.hasPermission(deletePermission, 'Delete'),
      download: RoleService.hasPermission(downloadPermission, 'ListDownload'),
      edit: RoleService.hasPermission(editPermission, 'Edit'),
      view: RoleService.hasPermission(viewPermission, 'View'),
    });
    return () => undefined;
  }, [
    addPermission,
    deletePermission,
    downloadPermission,
    editPermission,
    viewPermission,
  ]);

  return [
    {
      // States
      currentPage,
      filters,
      hasPermission,
      isDaterangeFilterActive,
      itemTotal,
      list,
      loading,
      loadingSingleItem,
      pageSize,
      pageTotal,
      sorting,
    },
    {
      // Getters
      getDownloads,
      getPermissions,
      getResponse,
      getRequestObj,
      // Handlers
      cleanupList,
      handleCurrentPageChange,
      handleDateRangeFilterToggle,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      setCurrentPage,
      setFilters,
      setHasPermission,
      setItemTotal,
      setIsDaterangeFilterActive,
      setList,
      setLoading,
      setLoadingSingleItem,
      setPageSize,
      setPageTotal,
      setSorting,
    },
  ];
};
