import {makeStyles, createStyles} from '@mui/styles';
import {Theme} from '@mui/material/styles';

const TripSummaryStyles = (_theme: Theme) =>
  createStyles({
    root: {flex: 1, cursor: 'default'},
  });

export const useTripSummaryStyles = makeStyles(TripSummaryStyles, {
  name: 'TripSummaryStyles',
});
