import {Contract, Listing} from '@onroadvantage/onroadvantage-api';
import {
  CONTRACT_DETAILS_INITIAL_VALUES,
  CONTRACT_INSPECTION_INITIAL_VALUES,
  CONTRACT_TRIP_EXECUTION_INITIAL_VALUES,
  CONTRACT_TRIP_NOTIFICATIONS_INITIAL_VALUES,
  CONTRACT_TRIP_TIMES_INITIAL_VALUES,
  IContractDetailsForm,
  IContractInspectionForm,
  IContractTripExecutionForm,
  IContractTripNotificationsForm,
  IContractTripTimesForm,
} from './contractConstants';
import {LuxonService} from '../../../service';

export const getContractDetailsInitialValues = (
  contract: Contract | undefined
): IContractDetailsForm => {
  if (!contract) {
    return CONTRACT_DETAILS_INITIAL_VALUES;
  }

  return {
    code: contract.code,
    name: contract.name,
    driverScoringEnabled: !!contract.driverScoringEnabled,
    honorRestSchedule: !!contract.honorRestSchedule,
    node: {label: contract.node?.name, value: contract.nodeId},
    telematicsConfig: {
      label: contract.telematicsConfig?.providerName,
      value: contract.telematicsConfigId,
    },
  };
};

export const getContractTripTimesInitialValues = (
  contract: Contract | undefined
): IContractTripTimesForm => {
  if (!contract) {
    return CONTRACT_TRIP_TIMES_INITIAL_VALUES;
  }

  return {
    shortBreakTrigger: contract.shortBreakTrigger,
    shortBreakDuration: contract.shortBreakDuration,
    longBreakTrigger: contract.longBreakTrigger,
    longBreakDuration: contract.longBreakDuration,
    maxShiftDrivingTime: contract.maxShiftDrivingTime,
    shiftDuration: contract.shiftDuration,
    dailyBreakDuration: contract.dailyBreakDuration,
    maxWeeklyDuty: contract.maxWeeklyDuty,
    weeklyBreak: contract.weeklyBreak,
    drivingBanStart: LuxonService.fromServerTimeToLocalTimeDate(
      contract.drivingBanStart
    ),
    drivingBanEnd: LuxonService.fromServerTimeToLocalTimeDate(
      contract.drivingBanEnd
    ),
    actualCalculationPostBuffer: contract.actualCalculationPostBuffer,
    actualCalculationPreBuffer: contract.actualCalculationPreBuffer,
    targetHoursPerDay: contract.targetHoursPerDay,
    targetHoursPerVehiclePerDay: contract.targetHoursPerVehiclePerDay,
    targetKmPerDay: contract.targetKmPerDay,
    targetKmPerVehiclePerDay: contract.targetKmPerVehiclePerDay,
    driverTripQueryPreToleranceHours: contract.driverTripQueryPreToleranceHours,
    driverTripQueryPostToleranceHours:
      contract.driverTripQueryPostToleranceHours,
  };
};

export const getContractTripExecutionInitialValues = (
  contract: Contract | undefined,
  priorityOptions: Listing[]
): IContractTripExecutionForm => {
  if (!contract) {
    return CONTRACT_TRIP_EXECUTION_INITIAL_VALUES;
  }

  const getPriorityOptionByLabel = (label: string | undefined) => {
    if (!label) return undefined;

    return priorityOptions.find((option) => label === option.label);
  };

  return {
    tripStopArrivalTimestampTaskTemplate: {
      label: contract.tripStopArrivalTimestampTaskTemplate?.name,
      value: contract.tripStopArrivalTimestampTaskTemplateId,
    },
    tripStopDepartureTimestampTaskTemplate: {
      label: contract.tripStopDepartureTimestampTaskTemplate?.name,
      value: contract.tripStopDepartureTimestampTaskTemplateId,
    },
    tripStopArrivalOdometerTaskTemplate: {
      label: contract.tripStopArrivalOdometerTaskTemplate?.name,
      value: contract.tripStopArrivalOdometerTaskTemplateId,
    },
    tripStopDepartureOdometerTaskTemplate: {
      label: contract.tripStopDepartureOdometerTaskTemplate?.name,
      value: contract.tripStopDepartureOdometerTaskTemplateId,
    },
    tripStopServiceStartTimestampTaskTemplate: {
      label: contract.tripStopServiceStartTimestampTaskTemplate?.name,
      value: contract.tripStopServiceStartTimestampTaskTemplateId,
    },
    tripStopServiceEndTimestampTaskTemplate: {
      label: contract.tripStopServiceEndTimestampTaskTemplate?.name,
      value: contract.tripStopServiceEndTimestampTaskTemplateId,
    },
    tripStopOdometerPriority: getPriorityOptionByLabel(
      contract.tripStopOdometerPriority
    ),
    tripStopTimestampPriority: getPriorityOptionByLabel(
      contract.tripStopTimestampPriority
    ),
    actualsStopMethodology: getPriorityOptionByLabel(
      contract.actualsStopMethodology
    ),
    banOrderTripDoubleAllocation: contract.banOrderTripDoubleAllocation,
    tripEndDepartureTaskBan: contract.tripEndDepartureTaskBan,
    tripStartArrivalTaskBan: contract.tripStartArrivalTaskBan,
    orderLineActualLoadedQuantityEnabled:
      contract.orderLineActualLoadedQuantityEnabled,
    allowMultipleOrdersInCompartment: contract.allowMultipleOrdersInCompartment,
    speedTables:
      contract.speedTables?.map(({id, name}) => ({value: id, label: name})) ??
      [],
  };
};

export const getContractTripNotificationsInitialValues = (
  contract: Contract | undefined
): IContractTripNotificationsForm => {
  if (!contract) {
    return CONTRACT_TRIP_NOTIFICATIONS_INITIAL_VALUES;
  }

  return {
    emailTripsheet: !!contract.emailTripsheet,
    etaConfidence: contract.etaConfidence,
    voiceBroadcastEnabled: contract.voiceBroadcastEnabled,
    etaCalcEnabled: contract.etaCalcEnabled,
  };
};

export const getContractInspectionInitialValues = (
  contract: Contract | undefined
): IContractInspectionForm => {
  if (!contract) {
    return CONTRACT_INSPECTION_INITIAL_VALUES;
  }

  return {
    mandatoryDailyVehicleInspectionEnabled:
      contract.mandatoryDailyVehicleInspectionEnabled,
    mandatoryDailyDriverInspectionEnabled:
      contract.mandatoryDailyDriverInspectionEnabled,
    vehicleInspectionTaskTemplate: {
      label: contract.vehicleInspectionTaskTemplate?.name,
      value: contract.vehicleInspectionTaskTemplateId,
    },
    driverInspectionTaskTemplate: {
      label: contract.driverInspectionTaskTemplate?.name,
      value: contract.driverInspectionTaskTemplateId,
    },
  };
};
