import React from 'react';
import {
  listingSchema,
  NUMBER_ERROR_MESSAGE,
  TemplateCard,
  TemplateForm,
} from '../../factory/template';
import {ReportContext} from './ReportContext';
import {
  Report,
  ReportCategory as ReportCategoryType,
  ReportParameter as ReportParameterType,
} from '@onroadvantage/onroadvantage-api';
import * as Yup from 'yup';
import {
  FormikAutocompleteHooks,
  FormikAutocompleteWithListing,
  FormikTextField,
} from '../formik';
import {Field} from 'formik';
import {generateUid, uploadXHR} from '../../service/Util';
import _ from 'lodash';
import {AutocompleteOptionType} from '../autocomplete';
import {StorageService} from '../../service';

const reportTypeOptions = [
  {value: 1, label: 'XLSX'},
  {value: 2, label: 'BIRT'},
];

export interface IReportForm {
  reportId?: number;
  name: string;
  description: string;
  version: string;
  templateConfig: string;
  templateName: string;
  reportType?: AutocompleteOptionType;
  dbFunctionName?: string;
  reportCategories: AutocompleteOptionType[];
  reportParameters: AutocompleteOptionType[];
}

const schema: Yup.SchemaOf<IReportForm> = Yup.object({
  reportId: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
  name: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  version: Yup.string().required('Required'),
  templateConfig: Yup.string().required('Required'),
  templateName: Yup.string().required('Required'),
  dbFunctionName: Yup.string(),
  reportType: listingSchema.default(reportTypeOptions[0]),
  reportCategories: Yup.array().of(listingSchema).default([]),
  reportParameters: Yup.array().of(listingSchema).default([]),
});

const getInitialValues = (report?: Report | null): IReportForm | undefined => {
  if (report) {
    return {
      reportId: report.id,
      name: report.name ?? '',
      description: report.description ?? '',
      version: report.version ?? '',
      templateConfig: _.get(report, 'templateConfig', ''),
      templateName: report.templateName ?? '',
      dbFunctionName: report.dbFunctionName || '',
      reportCategories: _.get(report, 'reportCategories', []).map(
        (param: ReportCategoryType) => ({
          value: _.get(param, 'id'),
          label: _.get(param, 'description'),
        })
      ),
      reportParameters: _.get(report, 'reportParameters', []).map(
        (param: ReportParameterType) => ({
          value: _.get(param, 'id'),
          label: _.get(param, 'description'),
        })
      ),
      reportType: reportTypeOptions.find(
        (rt) => rt.label === _.get(report, 'reportType')
      ),
    };
  }
  return undefined;
};

export const ReportForm: React.FC<{isAdd?: boolean}> = ({isAdd}) => {
  const {detailsRef, loading, submitting, report, onDetailsFormSubmit} =
    React.useContext(ReportContext);
  const [initialValues, setInitialValues] = React.useState<IReportForm>();

  React.useEffect(() => {
    const values = getInitialValues(report);

    if (values) {
      setInitialValues(values);
    } else if (isAdd) {
      setInitialValues({
        name: '',
        description: '',
        version: '',
        templateConfig: '',
        templateName: '',
        dbFunctionName: '',
        reportCategories: [],
        reportParameters: [],
      });
    }
  }, [isAdd, report, setInitialValues]);

  return (
    <TemplateCard
      title={`Report Details ${isAdd ? 'Add' : 'Edit'}`}
      loading={loading}
    >
      <TemplateForm<IReportForm>
        initialValues={initialValues}
        onSubmit={onDetailsFormSubmit}
        permission={{
          name: `${isAdd ? 'Add' : 'Edit'} Report`,
          type: isAdd ? 'Add' : 'Edit',
        }}
        submitting={submitting}
        innerRef={detailsRef}
        validationSchema={schema}
      >
        {({setFieldValue}) => (
          <>
            <FormikTextField
              id="name"
              name="name"
              placeholder="Enter the report name"
              label="Report Name"
              fullWidth
            />
            <FormikTextField
              id="description"
              name="description"
              placeholder="Enter the report description"
              label="Report Description"
              fullWidth
            />
            <FormikTextField
              name="version"
              placeholder="Enter the report version"
              label="Report Version"
              fullWidth
            />
            <FormikAutocompleteHooks
              name="reportType"
              placeholder="Select a report type"
              label="Report Type"
              options={reportTypeOptions}
            />
            <Field
              name="template"
              type="file"
              accept=".xlsx,.rptdesign"
              label="Report Template"
              onChange={(e: any) => {
                const file = e.target.files[0];
                const reader = new FileReader();
                reader.onload = async () => {
                  const response = await StorageService.getUrl({
                    key: generateUid(),
                    type: 'Report Template',
                    method: 'POST',
                  });
                  const storageConfig = await response.data;

                  // eslint-disable-next-line no-console
                  await uploadXHR(file, storageConfig, console.log);
                  setFieldValue(
                    'templateConfig',
                    JSON.stringify(storageConfig)
                  );
                  setFieldValue('templateName', file.name);
                };
                reader.readAsDataURL(file);
              }}
              style={{paddingTop: 8, paddingBottom: 18}}
            />
            <FormikTextField
              name="templateConfig"
              placeholder="Enter the template Config"
              label="Template Config"
              fullWidth
              disabled
            />
            <FormikTextField
              name="templateName"
              placeholder="Enter the template name"
              label="Template Name"
              fullWidth
              disabled
            />
            <FormikTextField
              name="dbFunctionName"
              placeholder="Enter the DB Function Name"
              label="Report DB Function Name"
              fullWidth
            />
            <FormikTextField
              name="dbFunction"
              placeholder="Enter the DB Function SQL"
              label="Report DB Function SQL"
              fullWidth
              InputProps={{
                multiline: true,
                maxRows: 24,
              }}
            />
            <FormikAutocompleteWithListing
              id="reportCategories"
              name="reportCategories"
              label="Report Categories"
              placeholder="Enter and select a value"
              model="ReportCategory"
              multiple
            />
            <FormikAutocompleteWithListing
              id="reportParameters"
              name="reportParameters"
              label="Report Parameters"
              placeholder="Enter and select a value"
              model="ReportParameter"
              multiple
            />
          </>
        )}
      </TemplateForm>
    </TemplateCard>
  );
};
