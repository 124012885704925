import {Listing, PlanningOrder} from '@onroadvantage/onroadvantage-api';
import {IObservableValue, toJS} from 'mobx';
import Fuse from 'fuse.js';
import clone from 'rfdc';
import React from 'react';
import {ILaneCard, ILaneFilter, IBoardData} from '../components/board';
import {TFilterValue} from '../stores/context';

const formatCardText = (text: string | undefined) => {
  if (!text) {
    return 'Card Title';
  }
  return text;
};

const getSkillTags = (metaData?: string) => {
  if (!metaData) {
    return [];
  }
  return metaData.split(',').map((el) => {
    return {
      bgcolor: '#0079BF',
      color: 'white',
      title: el,
    };
  });
};

const getVolumeTag = (planningOrder: PlanningOrder) => {
  return [
    {
      bgcolor: 'red',
      color: 'white',
      title: `Cap1: ${planningOrder.capacityDimension1}`,
    },
  ];
};

const getAllTags = (planningOrder: PlanningOrder) => {
  const tags = [];
  tags.push(...getVolumeTag(planningOrder));
  tags.push(...getSkillTags(planningOrder.skill));
  // tags.push(...getOrderSites(planningOrder));
  return tags;
};

export const listingToLaneData = (options: Array<Listing>): Array<ILaneCard> =>
  (options || []).map((option: Listing) => ({
    id: `${option.value}`,
    title: formatCardText(option.label) || '',
    description: formatCardText(`${option.metaData ? option.metaData : 'TBC'}`),
    label: formatCardText(`ID: ${option.value}`),
  }));

export const planningOrderListingToLaneData = (
  planningOrders: Array<PlanningOrder>
): Array<ILaneCard> => {
  return (planningOrders || []).map((planningOrder: PlanningOrder) => {
    return {
      id: `${planningOrder.id}`,
      title: formatCardText(`${planningOrder.order?.orderNumber}`),
      description: formatCardText(
        `${planningOrder.order?.upliftPoint.externalReference} -> ${planningOrder.order?.offloadPoint.externalReference}`
      ),
      label: formatCardText(`ID: ${planningOrder.id}`),
      tags: getAllTags(planningOrder),
      cardStyle: {width: '20px'},
    };
  });
};

export type TGetLaneFilters = (
  allocated: string,
  available: string
) => ILaneFilter[];
export const getLaneFilters: TGetLaneFilters = (allocated, available) => [
  {
    id: 'available',
    title: 'Available',
    value: available,
  },
  {
    id: 'allocated',
    title: 'Allocated',
    value: allocated,
  },
];

export interface IGetFilteredCardDataResponse {
  filteredAllocatedData: ILaneCard[];
  filteredAvailableData: ILaneCard[];
}

export type TGetFilteredCardData = (
  allocated: ILaneCard[],
  available: ILaneCard[],
  allocatedFilterValue: string,
  availableFilterValue: string
) => IGetFilteredCardDataResponse;
export const getFilteredCardData: TGetFilteredCardData = (
  allocated,
  available,
  allocatedFilterValue,
  availableFilterValue
) => {
  let filteredAllocatedData: ILaneCard[] = [];
  let filteredAvailableData: ILaneCard[] = [];
  const filterOptions = {
    includeMatches: true,
    keys: ['title', 'description'],
    threshold: 0.1,
  };

  if (allocatedFilterValue !== '') {
    const allocatedFilter = new Fuse(allocated, filterOptions);
    filteredAllocatedData = allocatedFilter
      .search(allocatedFilterValue)
      .map(({item}) => item);
  } else {
    filteredAllocatedData = clone()(allocated);
  }

  if (availableFilterValue !== '') {
    const availableFilter = new Fuse(available, filterOptions);
    filteredAvailableData = availableFilter
      .search(availableFilterValue)
      .map(({item}) => item);
  } else {
    filteredAvailableData = clone()(available);
  }

  return {
    filteredAllocatedData,
    filteredAvailableData,
  };
};

export type TGetFilteredOrderCardData = (
  data: ILaneCard[],
  filter: string
) => ILaneCard[];

export const getFilteredOrderCardData: TGetFilteredOrderCardData = (
  data,
  filter
) => {
  let filteredData: ILaneCard[] = [];
  const filterOptions = {
    includeMatches: true,
    keys: ['title', 'description'],
  };

  if (filter !== '') {
    const fFilter = new Fuse(data, filterOptions);
    filteredData = fFilter.search(filter).map(({item}) => item);
  } else {
    filteredData = clone()(data);
  }

  return filteredData;
};

export type TGetBoardData = (
  allocated: ILaneCard[],
  available: ILaneCard[]
) => IBoardData;
export const getBoardData: TGetBoardData = (allocated, available) => ({
  // IMPORTANT keep order otherwise lanes might get swapped
  // 0 - available
  // 1 - allocated
  lanes: [
    {
      id: 'available',
      title: `Available (${available.length})`,
      cards: available,
    },
    {
      id: 'allocated',
      title: `Allocated (${allocated.length})`,
      cards: allocated,
    },
  ],
});

export type TGetFilterChangeHandlerResponse = (
  // eslint-disable-next-line no-undef
  e: React.ChangeEvent<HTMLInputElement>
) => void;
export type TGetFilterChangeHandler = (
  allocated: IObservableValue<string>,
  available: IObservableValue<string>
) => TGetFilterChangeHandlerResponse;
export const getFilterChangeHandler: TGetFilterChangeHandler =
  (allocated, available) => (e) => {
    const filterValue: TFilterValue = e.target.value;
    let filterStore: IObservableValue<TFilterValue>;
    switch (e.target.name) {
      case 'allocated':
        filterStore = allocated;
        break;
      case 'available':
        filterStore = available;
        break;
      default:
        throw new Error('filter not found');
    }

    filterStore.set(filterValue);
  };

export type TGetCardMoveHandlerResponse = (
  fromLaneId: string,
  toLaneId: string,
  cardId: string,
  index: number
) => void;
export type TGetCardMoveHandler = (
  mobxAll: IObservableValue<ILaneCard[]>,
  mobxAllocated: IObservableValue<ILaneCard[]>,
  mobxAvailable: IObservableValue<ILaneCard[]>
) => TGetCardMoveHandlerResponse;
// TODO unused???
export const getCardMoveHandler: TGetCardMoveHandler =
  (mobxAll, mobxAllocated, mobxAvailable) =>
  (fromLaneId, toLaneId, cardId, index) => {
    let allocatedIndex: number;
    let availableIndex: number;

    const all = clone()(toJS(mobxAll.get()));
    const allocated = clone()(toJS(mobxAllocated.get()));
    const available = clone()(toJS(mobxAvailable.get()));

    const card = toJS(all.find((c) => c.id === cardId));

    if (!card) {
      // TODO handle error
      throw new Error(`card not found ${cardId}`);
    }

    if (fromLaneId === toLaneId) {
      // order of cards in same lane
      // TODO handle???
    } else {
      // from one lane to the other
      switch (toLaneId) {
        case 'available':
          availableIndex = index;
          allocatedIndex = allocated.findIndex((c) => c.id === cardId);
          allocated.splice(allocatedIndex, 1);
          available.splice(availableIndex, 0, card);
          break;
        case 'allocated':
          availableIndex = available.findIndex((c) => c.id === cardId);
          allocatedIndex = index;
          allocated.splice(allocatedIndex, 0, card);
          available.splice(availableIndex, 1);
          break;
        default:
          throw new Error('should not get here ever');
      }

      mobxAllocated.set(allocated);
      mobxAvailable.set(available);
    }
  };

export {};
