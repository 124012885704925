import React from 'react';
import {TextField} from '@mui/material';

export interface ILaneFilter {
  id?: string;
  title?: string;
  value?: string;
}

interface IInputProps extends ILaneFilter {
  // eslint-disable-next-line no-undef
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit?: () => void;
}

interface IContainerProps {
  // eslint-disable-next-line no-undef
  onFilterChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  filters?: ILaneFilter[];
}

const inputStyle = {
  container: {
    display: 'inline-flex',
    flexGrow: 1,
    backgroundColor: '#e3e3e3',
    marginTop: 5,
    marginRight: 5,
    marginLeft: 5,
    padding: 10,
    borderRadius: 3,
    minWidth: 250,
    maxWidth: 270,
  },
};

const containerStyle = {
  container: {
    display: 'flex',
    paddingTop: 5,
    paddingRight: 5,
    paddingLeft: 5,
  },
};

export const VantageBoardFilterInput = ({
  id,
  onChange,
  title,
  value,
}: IInputProps) => (
  <div style={inputStyle.container}>
    <TextField
      name={id}
      placeholder={`Filter ${title}`}
      fullWidth
      onChange={onChange}
      value={value}
    />
  </div>
);

export const VantageBoardFilter = ({
  filters = [],
  onFilterChange,
}: IContainerProps) => {
  return (
    <div style={containerStyle.container}>
      {filters.map(({id, title, value}) => (
        <VantageBoardFilterInput
          key={id}
          id={id}
          title={title}
          onChange={onFilterChange}
          value={value}
        />
      ))}
    </div>
  );
};
