import {makeStyles, createStyles} from '@mui/styles';

const TableContainerComponentStyles = () =>
  createStyles({
    container: {display: 'flex', flex: 1},
    virtualContainer: {height: '100%', overflowX: 'hidden'},
  });

export const useTableContainerComponentStyles = makeStyles(
  TableContainerComponentStyles,
  {name: 'TableContainerComponentStyles'}
);
