import React from 'react';
import {
  listingSchema,
  TemplateCard,
  TemplateForm,
} from '../../factory/template';
import {TaskTemplateContext} from './TaskTemplateContext';
import {Listing, TaskTemplate} from '@onroadvantage/onroadvantage-api';
import * as Yup from 'yup';
import {FormikAutocompleteWithListing, FormikTextField} from '../formik';

export interface ITaskTemplateForm {
  type: Listing;
  name: string;
}

const schema: Yup.SchemaOf<ITaskTemplateForm> = Yup.object({
  name: Yup.string().required('Required'),
  type: listingSchema.nullable().required('Required'),
});

const getInitialValues = (
  taskTemplate?: TaskTemplate | null
): ITaskTemplateForm | undefined => {
  if (taskTemplate) {
    return {
      name: taskTemplate.name ?? '',
      type: {value: taskTemplate.type?.id, label: taskTemplate.type?.name},
    };
  }
  return undefined;
};

export const TaskTemplateForm: React.FC<{isAdd?: boolean}> = ({isAdd}) => {
  const {detailsRef, loading, submitting, taskTemplate, onDetailsFormSubmit} =
    React.useContext(TaskTemplateContext);
  const [initialValues, setInitialValues] = React.useState<ITaskTemplateForm>();

  React.useEffect(() => {
    const values = getInitialValues(taskTemplate);

    if (values) {
      setInitialValues(values);
    } else if (isAdd) {
      setInitialValues({
        name: '',
        type: {},
      });
    }
  }, [isAdd, taskTemplate, setInitialValues]);

  return (
    <TemplateCard
      title={`Task Template Details ${isAdd ? 'Add' : 'Edit'}`}
      loading={loading}
    >
      <TemplateForm<ITaskTemplateForm>
        initialValues={initialValues}
        onSubmit={onDetailsFormSubmit}
        permission={{
          name: `${isAdd ? 'Add' : 'Edit'} TaskTemplate`,
          type: isAdd ? 'Add' : 'Edit',
        }}
        submitting={submitting}
        innerRef={detailsRef}
        validationSchema={schema}
      >
        <FormikAutocompleteWithListing
          name="type"
          model="TaskTemplateType"
          placeholder="Enter and select a value"
          label="Type"
        />
        <FormikTextField
          name="name"
          placeholder="Enter the task template name"
          label="Name"
          fullWidth
        />
      </TemplateForm>
    </TemplateCard>
  );
};
