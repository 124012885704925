import React from 'react';
import {
  NUMBER_ERROR_MESSAGE,
  TemplateCard,
  TemplateForm,
} from '../../../factory/template';
import {TrainingCourseUserContext} from './TrainingCourseUserContext';
import {UserTrainingCourse} from '@onroadvantage/onroadvantage-api';
import * as Yup from 'yup';
import {FormikDateTimePicker, FormikTextField} from '../../formik';
import {Moment} from 'moment';
import {MomentService} from '../../../service';

export interface ITrainingCourseUserForm {
  completedAt?: Moment | Date | string;
  mark?: number;
  startedAt?: Moment | Date | string;
  status?: string;
}

const schema: Yup.SchemaOf<ITrainingCourseUserForm> = Yup.object({
  completedAt: Yup.date(),
  mark: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
  startedAt: Yup.date(),
  status: Yup.string(),
});

const getInitialValues = (
  trainingCourseUser?: UserTrainingCourse | null
): ITrainingCourseUserForm | undefined => {
  if (trainingCourseUser) {
    return {
      completedAt: trainingCourseUser.completedAt
        ? MomentService.toDate(
            MomentService.localiseTimeOnly(trainingCourseUser.completedAt)
          )
        : undefined,
      mark: trainingCourseUser.mark,
      startedAt: trainingCourseUser.startedAt
        ? MomentService.toDate(
            MomentService.localiseTimeOnly(trainingCourseUser.startedAt)
          )
        : undefined,
      status: trainingCourseUser.status ?? '',
    };
  }
  return undefined;
};

export const TrainingCourseUserForm: React.FC = () => {
  const {
    detailsRef,
    loading,
    submitting,
    trainingCourseUser,
    onDetailsFormSubmit,
  } = React.useContext(TrainingCourseUserContext);
  const [initialValues, setInitialValues] =
    React.useState<ITrainingCourseUserForm>();

  React.useEffect(() => {
    const values = getInitialValues(trainingCourseUser);
    if (values) {
      setInitialValues(values);
    }
  }, [trainingCourseUser, setInitialValues]);

  return (
    <TemplateCard title={`User Details Edit`} loading={loading}>
      <TemplateForm<ITrainingCourseUserForm>
        initialValues={initialValues}
        onSubmit={onDetailsFormSubmit}
        permission={{name: 'Edit TrainingCourse', type: 'Edit'}}
        submitting={submitting}
        innerRef={detailsRef}
        validationSchema={schema}
      >
        <FormikTextField
          name="status"
          placeholder="Enter the user status"
          label="Status"
          fullWidth
        />
        <FormikTextField
          name="mark"
          placeholder="Enter the user mark"
          label="Mark"
          fullWidth
        />
        <FormikDateTimePicker
          name="startedAt"
          placeholder="Enter the user start date"
          label="Started At"
          disableDefaultValue
          fullWidth
        />
        <FormikDateTimePicker
          name="completedAt"
          placeholder="Enter the user complete date"
          label="Completed At"
          disableDefaultValue
          fullWidth
        />
      </TemplateForm>
    </TemplateCard>
  );
};
