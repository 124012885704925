import React from 'react';
import {TripDebriefContext} from '../TripDebriefContext';
import {
  ITemplateTabControllerTab,
  TemplateTabs,
} from '../../../factory/template';
import {TripDebriefOrderLineForm} from './TripDebriefOrderLineForm';

export const TripDebriefOrderLine: React.FC = () => {
  const {masterTripId, orderLineId, loading} =
    React.useContext(TripDebriefContext);

  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {title: 'Order Details', element: <TripDebriefOrderLineForm />},
  ]);

  const path = React.useMemo(
    () => `/tripdebrieflist/${masterTripId}/orderline/${orderLineId}`,
    [masterTripId, orderLineId]
  );

  return <TemplateTabs tabs={tabs} path={path} loading={loading} />;
};
