import React, {useCallback, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {
  ApiDriverGetRequest,
  Driver,
  DriverListResponse,
  DriverPatch,
  DriverPost,
  Listing,
} from '@onroadvantage/onroadvantage-api';
import {FormikHelpers, FormikProps} from 'formik/dist/types';
import {driverApi} from '../../api';
import {
  TemplateTableContextProps,
  TLoadList,
  useTemplateTable,
} from '../../factory/template';
import moment, {Moment} from 'moment';
import {useAppNotifications} from '../../contexts';
import {DriverQrDialog} from './DriverQrDialog';

export interface IDriverForm {
  contactNumber: string;
  contract: Listing;
  externalExtendedId: string;
  externalEmployeeNumber: string;
  firstname: string;
  lastname: string;
  licenseType: string;
  licenseExpiry?: Moment | Date | string | null;
}

interface IDriverUserPopupForm {
  user: Listing;
}

export interface DriverContextProps
  extends TemplateTableContextProps<Driver, DriverListResponse> {
  loadDriver: () => void;
  onDetailsFormSubmit: (
    values: IDriverForm,
    formikHelpers: FormikHelpers<IDriverForm>
  ) => void;
  onDriverUserCreate: () => void;
  onUserLink: (
    values: IDriverUserPopupForm,
    formikHelpers: FormikHelpers<IDriverUserPopupForm>
  ) => void;
  setDriverId: (value: number | undefined) => void;
  submitting: boolean;
  startDownload: boolean;
  setStartDownload: (value: boolean) => void;
  userId: number | null;
  selectedDriver: Driver | undefined;
  onDownloadItem: (row: Driver) => void;
  driver?: Driver;
  driverId?: number | null;
  detailsRef?: React.Ref<FormikProps<IDriverForm>>;
  userPopupDetailsRef?: React.Ref<FormikProps<IDriverUserPopupForm>>;
}

export const DriverContext = React.createContext<DriverContextProps>({
  // Template Table Defaults
  loading: false,
  list: [],
  currentPage: 1,
  // Driver
  loadList: async () => {},
  cleanupList: () => null,
  loadDriver: () => null,
  onDetailsFormSubmit: () => null,
  onDriverUserCreate: () => null,
  onUserLink: () => null,
  setDriverId: () => null,
  setStartDownload: () => null,
  submitting: false,
  startDownload: false,
  onDownloadItem: () => null,
  userId: null,
  selectedDriver: undefined,
});

interface DriverContextProviderProps {
  driverId?: number;
}

export const DriverContextProvider: React.FC<DriverContextProviderProps> = ({
  children,
}) => {
  const history = useHistory();
  const [userId, setUserId] = useState<number | null>(null);
  const [selectedDriver, setSelectedDriver] = useState<Driver | undefined>(
    undefined
  );
  const notify = useAppNotifications();
  // Template Table
  const [
    {
      // States
      currentPage,
      filters,
      hasPermission,
      itemTotal,
      list,
      loading,
      pageSize,
      pageTotal,
      loadingSingleItem,
      sorting,
    },
    {
      // Getters
      getDownloads,
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
      setLoadingSingleItem,
    },
  ] = useTemplateTable<Driver, ApiDriverGetRequest>({
    editPermission: 'Edit Driver',
    addPermission: 'Add Driver',
    deletePermission: 'Delete Driver',
    downloadPermission: 'Driver ListDownload',
    viewPermission: 'Driver List',
  });

  const loadList = React.useCallback<TLoadList<DriverListResponse>>(
    async (options) => {
      setLoading(true);
      try {
        const requestObj = getRequestObj(
          [
            'firstname',
            'lastname',
            'externalEmployeeNumber',
            'externalName',
            'contractCode',
            'contactNumber',
            'licenseType',
            'telematicsConfigId',
            'externalId',
          ],
          options
        );
        const response = await driverApi.apiDriverGet(requestObj);
        return getResponse(response, options);
      } catch (e) {
        notify('error', e.message ?? 'Failed to load driver list');
      } finally {
        setLoading(false);
      }
    },
    [getRequestObj, getResponse, notify, setLoading]
  );

  const handleDownload = React.useCallback(
    async () =>
      getDownloads('drivers', loadList, [
        {name: 'First Name', path: 'firstname'},
        {name: 'Last Name', path: 'lastname'},
        {name: 'Employee Number', path: 'externalEmployeeNumber'},
        {name: 'External Name', path: 'externalName'},
        {name: 'Contract Code', path: 'contract.code'},
        {name: 'Contact Number', path: 'contactNumber'},
        {name: 'License Type', path: 'licenseType'},
        {name: 'License Expiry', path: 'licenseExpiry'},
        {name: 'Config', path: 'telematicsConfigId'},
        {name: 'ExternalId', path: 'externalId'},
      ]),
    [getDownloads, loadList]
  );

  const handleDelete = React.useCallback(
    async (row: Driver) => {
      setLoading(true);
      try {
        if (row.id) {
          await driverApi.apiDriverDriverIdDelete({
            driverId: row.id,
          });
          await loadList();
          notify('success', 'Driver Deleted');
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to delete driver');
      } finally {
        setLoading(false);
      }
    },
    [loadList, notify, setLoading]
  );

  const handleAdd = React.useCallback(
    () => history.push('/driverlist/add'),
    [history]
  );

  const handleNavigate = React.useCallback(
    (row: Driver) => history.push(`/driverlist/${row.id}`),
    [history]
  );

  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  // driver
  const [driver, setDriver] = React.useState<Driver | undefined>();
  const [driverId, setDriverId] = React.useState<number | null>();
  const [submitting, setSubmitting] = React.useState(false);

  const detailsRef = React.useRef<FormikProps<IDriverForm>>(null);
  const userPopupDetailsRef =
    React.useRef<FormikProps<IDriverUserPopupForm>>(null);

  const loadDriver = React.useCallback(async () => {
    setLoadingSingleItem(true);
    try {
      if (driverId) {
        const response = await driverApi.apiDriverDriverIdGet({
          driverId,
        });
        setDriver(response);
      } else {
        setDriver(undefined);
      }
    } catch (e) {
      notify('error', e.message ?? 'Failed to load Driver');
    } finally {
      setLoadingSingleItem(false);
    }
  }, [setLoadingSingleItem, driverId, notify]);

  const handleDetailsFormSubmit = React.useCallback(
    async (values: IDriverForm, formikHelpers: FormikHelpers<IDriverForm>) => {
      setSubmitting(true);
      const {contract, licenseExpiry, ...otherValues} = values;
      try {
        const newValues: DriverPatch = {
          ...otherValues,
          contractId: contract.value,
          licenseExpiry: moment(licenseExpiry).toDate(),
        };
        if (driver && driver.id) {
          await driverApi.apiDriverDriverIdPatch({
            driverId: driver.id,
            body: newValues,
          });
        } else {
          await driverApi.apiDriverPost({
            body: newValues as DriverPost,
          });
        }
        history.push('/driverlist');
        notify('success', `${driver?.id ? 'Updated' : 'Added'} Driver`);
      } catch (e) {
        notify(
          'error',
          e.message ??
            `Driver Tag ${values.externalExtendedId} has already been taken.`
        );
      } finally {
        formikHelpers.setSubmitting(false);
        setSubmitting(false);
      }
    },
    [driver, history, notify]
  );

  const handleDriverUserCreate = React.useCallback(async () => {
    setLoading(true);
    try {
      if (driver?.id) {
        const response = await driverApi.apiDriverDriverIdUserPost({
          driverId: driver?.id,
        });
        if (response) {
          notify('success', 'Created User');
        }
        await loadDriver();
      }
    } catch (e) {
      notify('error', e.message ?? 'Username already in use');
    } finally {
      setLoading(false);
    }
  }, [driver?.id, loadDriver, notify, setLoading]);

  const handleUserLink = React.useCallback(
    async (
      values: IDriverUserPopupForm,
      formikHelpers: FormikHelpers<IDriverUserPopupForm>
    ) => {
      setSubmitting(true);
      formikHelpers.setSubmitting(true);
      try {
        if (driver?.id) {
          const response = await driverApi.apiDriverDriverIdPatch({
            driverId: driver.id,
            body: {
              contractId: driver.contract?.id,
              userId: values.user.value,
            },
          });
          if (response) {
            notify('success', 'Driver Linked To User');
          }
          await loadDriver();
        }
      } catch (e) {
        notify('error', e.message ?? 'Username already in use');
      } finally {
        setSubmitting(false);
        formikHelpers.setSubmitting(false);
      }
    },
    [driver?.contract?.id, driver?.id, loadDriver, notify]
  );

  const [startDownload, setStartDownload] = useState(false);

  const handleDownloadQr = useCallback((row: Driver) => {
    // set the state to trigger the download in `DriverQrDialog`
    setUserId(row.user?.id ?? null);
    setSelectedDriver(row);
    setStartDownload(true);
  }, []);

  const value: DriverContextProps = {
    // Template Table
    loadList,
    cleanupList,
    loading: loadingSingleItem || loading,
    hasPermission,
    list,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onAdd: hasPermission.add ? handleAdd : undefined,
    onDownload: hasPermission.download ? handleDownload : undefined,
    onNavigate: handleNavigate,
    onDownloadItem: handleDownloadQr,
    userId,
    startDownload,
    selectedDriver,
    setStartDownload,
    onDelete: hasPermission.delete ? handleDelete : undefined,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onRefresh: handleRefresh,
    // Driver
    loadDriver,
    onDetailsFormSubmit: handleDetailsFormSubmit,
    onDriverUserCreate: handleDriverUserCreate,
    onUserLink: handleUserLink,
    setDriverId,
    submitting,
    driver,
    driverId,
    detailsRef,
    userPopupDetailsRef,
  };

  React.useEffect(() => {
    (async () => {
      try {
        await loadDriver();
      } catch (error) {
        notify('error', 'Failed to load driver');
      }
    })();

    return () => setDriver(undefined);
  }, [loadDriver, notify]);

  return (
    <DriverContext.Provider value={value}>
      {children} <DriverQrDialog />
    </DriverContext.Provider>
  );
};
