import React from 'react';
import {useAppNotifications} from '../../../../contexts';
import {Listing} from '@onroadvantage/onroadvantage-api';
import {debounce} from '@mui/material';
import {orderApi} from '../../../../api';
import DateRangeContext from '../../../../contexts/DateRangeContext';
import {DateTime} from 'luxon';
import {TripSetupAutocomplete} from './TripSetupAutocomplete';
import {useTripSetupContext} from '../TripSetupContext';

interface TripSetupStopsStepNewOrderAutocompleteProps {
  value: Listing[];
  onChange: (value: Listing[] | null) => void;
}

export const TripSetupAutocompleteOrders = ({
  value,
  onChange,
}: TripSetupStopsStepNewOrderAutocompleteProps) => {
  const notify = useAppNotifications();
  const {orders} = useTripSetupContext();
  const {dateRange} = React.useContext(DateRangeContext);
  const [listing, setListing] = React.useState<Listing[]>([]);
  const [localLoading, setLocalLoading] = React.useState<boolean>(false);

  const getListing = React.useCallback(
    async (filter?: string) => {
      setLocalLoading(true);
      try {
        const response = await orderApi.apiOrderGet({
          getUnassignedOrdersOnly: true,
          orderNumber: filter,
          orderBy: 'orderNumber',
          startDate: new Date(dateRange.startDate),
          endDate: new Date(dateRange.endDate),
          page: 1,
          perPage: 25,
        });
        setListing(
          response.items
            ?.filter(
              ({orderNumber}) =>
                !orders.map((order) => order.orderNumber).includes(orderNumber)
            )
            .map((order) => ({
              value: order.id,
              metaData: JSON.stringify(order),
              label: order.orderNumber,
            })) ?? []
        );
      } catch (e) {
        notify('error', 'Failed to load Order listing');
      } finally {
        setLocalLoading(false);
      }
    },
    [dateRange, notify, orders]
  );

  const getListingDebounce = React.useRef(debounce(getListing, 500)).current;

  const handleInputChange = React.useCallback(
    async (_event, value) => {
      setLocalLoading(true);
      await getListingDebounce(value);
    },
    [getListingDebounce]
  );

  React.useEffect(() => {
    getListing();
    return () => setListing([]);
  }, [getListing]);

  const startDate = DateTime.fromISO(dateRange.startDate).toFormat(
    'dd/MM/yyyy'
  );
  const endDate = DateTime.fromISO(dateRange.endDate).toFormat('dd/MM/yyyy');

  const helperText = `*Orders within current date range (${startDate} - ${endDate})`;

  return (
    <TripSetupAutocomplete<Listing[]>
      label="Orders"
      name="orders"
      placeholder="Search and select at least 1 order"
      options={listing}
      value={value}
      onChange={onChange}
      multiple
      onInputChange={handleInputChange}
      loading={localLoading}
      helperText={helperText}
    />
  );
};
