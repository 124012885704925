import React from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';

import ReactAudioPlayer from 'react-audio-player';
import VoiceService from '../../../service/VoiceService';
import {generateUid} from '../../../service/Util';

interface Props {
  controlledValue?: string;
  updateMessage?: (message: string) => void;
  updateIdentifier: (identifier?: string, message?: string) => void;
}

interface State {
  message: string;
  generating: boolean;
  generatedMessage: string;
  url: string | null;
  identifier?: string;
}

export default class GenerateAudioFile extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      message: this.props.controlledValue || '',
      generating: false,
      generatedMessage: '',
      url: null,
      identifier: undefined,
    };
  }

  renderPlayer = () => {
    const {url} = this.state;
    if (url) {
      return <ReactAudioPlayer src={url} controls preload="none" />;
    }
    return null;
  };

  generateAudioFile = async () => {
    const {message} = this.state;
    this.setState({
      generating: true,
    });
    const identifier = `${generateUid()}.mp3`;
    const data = await VoiceService.generateVoiceMessage({
      identifier,
      message,
    });

    this.setState(
      {
        generating: false,
        generatedMessage: message,
        identifier,
        url: data.data,
      },
      () => this.props.updateIdentifier(identifier, message)
    );
  };

  handleTextChange = (event: React.ChangeEvent<any>) => {
    const message = event.target.value;
    this.props.updateMessage && this.props.updateMessage(message);
    this.setState({message});
  };

  render() {
    return (
      <div>
        <TextField
          value={this.props.controlledValue || this.state.message}
          style={{width: 600}}
          helperText="Hi Driver, please ..."
          label="Custom Message Field"
          multiline={true}
          rows={2}
          onChange={this.handleTextChange}
        />
        <Button
          disabled={!this.state.message.length || this.state.generating}
          style={{marginTop: 10}}
          onClick={this.generateAudioFile}
        >
          <Typography>
            {this.state.generating ? 'Generating...' : 'Generate'}
          </Typography>
        </Button>
        <CardContent>{this.renderPlayer()}</CardContent>
      </div>
    );
  }
}
