import React from 'react';
import {WebPlanningBoardTripStop} from '@onroadvantage/onroadvantage-api';
import {
  TemplateTable,
  TemplateTableConfig,
  TOnSelectionChange,
} from '../../../../../factory/template';
import {
  usePlanningBoardGanttContext,
  WebPlanningBoardMasterTripWithIdType,
} from '../../../planningBoardContext';
import {usePlanningBoardTripContext} from '../planningBoardTripContext';
import {usePlanningBoardTripCompartmentsContext} from './planningBoardTripCompartmentsContext';

export const PlanningBoardTripCompartmentsStopList: React.FC = () => {
  const {tripPreview} = usePlanningBoardTripContext();
  const {selectedGanttItem} = usePlanningBoardGanttContext();
  const {selectedStop, setSelectedStop} =
    usePlanningBoardTripCompartmentsContext();

  const stops: WebPlanningBoardTripStop[] | undefined = React.useMemo(() => {
    if (selectedGanttItem?.variant === 'order') {
      return (
        tripPreview?.trip?.stops?.map((stop) => ({
          ...stop,
          id: stop.sequence,
        })) ?? []
      );
    }

    return (
      (
        selectedGanttItem?.data as
          | WebPlanningBoardMasterTripWithIdType
          | undefined
      )?.trip?.stops ?? []
    );
  }, [selectedGanttItem, tripPreview]);

  const config: TemplateTableConfig<WebPlanningBoardTripStop> = React.useMemo(
    () => ({
      columns: [
        {
          name: 'node',
          label: 'Site Name',
          type: 'string',
          getValue: ({node}) => node?.name,
        },
        {name: 'sequence', label: 'Sequence', type: 'number'},
        {
          name: 'nodeType',
          label: 'Site Type',
          type: 'string',
          getValue: ({node}) => node?.nodeType?.name,
        },
      ],
      disableToolbar: true,
      disablePagination: true,
      disableSelectAll: true,
      selectionType: 'radio',
      identifier: `PLANNING_BOARD_GANTT_STOP_LIST`,
    }),
    []
  );

  const handleSelectionChange = React.useCallback<
    TOnSelectionChange<WebPlanningBoardTripStop>
  >(
    (selection) => {
      if (selection.length > 0) {
        const selectedEvents = stops.filter(({id}) =>
          selection.map(({id}) => id).includes(id)
        );

        setSelectedStop(
          (prev) => selectedEvents.find((e) => e.id !== prev?.id) ?? undefined
        );
      }
    },
    [setSelectedStop, stops]
  );

  React.useEffect(() => {
    setSelectedStop(stops[0]);
    return () => {
      setSelectedStop(undefined);
    };
  }, [setSelectedStop, stops]);

  return (
    <TemplateTable
      config={config}
      currentPage={1}
      list={stops}
      selection={selectedStop ? [selectedStop] : []}
      onSelectionChange={handleSelectionChange}
    />
  );
};
