import {
  NodeDump,
  WebPlanningBoardNodeMinimal,
  WebPlanningBoardTripPlanningOptions,
} from '@onroadvantage/onroadvantage-api';
import {
  PlanningBoardContextProps,
  PlanningBoardGanttContextProps,
  WebPlanningBoardMasterTripWithIdType,
} from '../../../planningBoardContext';
import {
  IPlanningBoardTripPanelForm,
  TPlanningBoardTripFormSelectedTimeOptions,
} from './index';
import {DateTime} from 'luxon';

interface InitialValuesParams {
  selectedGanttItem: PlanningBoardGanttContextProps['selectedGanttItem'];
  vehicles: PlanningBoardContextProps['vehicles'];
}

export const getPlanningBoardTripUpdateInitialValues = ({
  selectedGanttItem,
  vehicles,
}: InitialValuesParams): IPlanningBoardTripPanelForm | undefined => {
  if (!selectedGanttItem || selectedGanttItem.variant === 'order') {
    return undefined;
  }

  const masterTrip = selectedGanttItem.data as
    | WebPlanningBoardMasterTripWithIdType
    | undefined;

  const group = selectedGanttItem.group;

  if (!masterTrip || !group) {
    return undefined;
  }

  const {tripStartTime, loadingArrivalTime, offloadingArrivalTime} =
    masterTrip.trip?.planningOptions ||
    ({} as WebPlanningBoardTripPlanningOptions);

  const vehicle = vehicles.find(({id}) => id === group);
  const tripStops = masterTrip.trip?.stops;

  let loadingPoint: WebPlanningBoardNodeMinimal | undefined;
  let offloadingPoint: WebPlanningBoardNodeMinimal | undefined;
  let selectedTimeOption: TPlanningBoardTripFormSelectedTimeOptions = null;

  if (tripStops && tripStops.length > 0) {
    loadingPoint = tripStops[0].node;
    offloadingPoint = tripStops[tripStops.length - 1].node;
  }

  if (tripStartTime) {
    selectedTimeOption = 'tripStartTime';
  }

  if (loadingArrivalTime) {
    selectedTimeOption = 'loadingArrivalTime';
  }

  if (offloadingArrivalTime) {
    selectedTimeOption = 'offloadingArrivalTime';
  }

  if (loadingArrivalTime && offloadingArrivalTime) {
    selectedTimeOption = 'loadingOffloadingArrivalTime';
  }

  return {
    loadingArrivalTime: loadingArrivalTime
      ? DateTime.fromISO(loadingArrivalTime).toJSDate()
      : undefined,
    offloadingArrivalTime: offloadingArrivalTime
      ? DateTime.fromISO(offloadingArrivalTime).toJSDate()
      : undefined,
    selectedTimeOption,
    tripStartTime: tripStartTime
      ? DateTime.fromISO(tripStartTime).toJSDate()
      : undefined,
    endingPointNode: {
      label: offloadingPoint?.name ?? undefined,
      value: offloadingPoint?.id ?? undefined,
    },
    endingNode: offloadingPoint as NodeDump,
    startingPointNode: {
      label: loadingPoint?.name ?? undefined,
      value: loadingPoint?.id ?? undefined,
    },
    startingNode: loadingPoint,
    driver: {
      label: masterTrip.trip?.driver?.firstname ?? undefined,
      value: masterTrip.trip?.driver?.id ?? undefined,
    },
    vehicle: {
      label: `${vehicle?.registrationNumber} - ${vehicle?.fleetNumber}`,
      value: vehicle?.id,
    },
  };
};
