/*
 * Borrowed from: https://github.com/jaredpalmer/formik/issues/1233#issuecomment-806846117
 */
import React from 'react';
import {useFormikContext} from 'formik';

interface FormikDisablerProps {
  disabled?: boolean;
}

export const FormikDisabler: React.FC<FormikDisablerProps> = ({disabled}) => {
  const {setSubmitting} = useFormikContext();
  React.useEffect(() => {
    if (disabled) {
      setSubmitting(true);
    } else {
      setSubmitting(false);
    }
  }, [disabled, setSubmitting]);
  return null;
};
