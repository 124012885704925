import React from 'react';
import {useHistory} from 'react-router-dom';
import {
  VehicleCapacityDimension,
  VehicleCapacityDimensionPost,
  ApiVehicleCapacityDimensionGetRequest,
  VehicleCapacityDimensionPatch,
} from '@onroadvantage/onroadvantage-api';
import {FormikHelpers, FormikProps} from 'formik/dist/types';
import {vehicleCapacityDimensionApi} from '../../../api';
import {
  TemplateTableContextProps,
  TLoadList,
  useTemplateTable,
} from '../../../factory/template';
import {VehicleTypeContext} from '../VehicleTypeContext';
import {useAppNotifications} from '../../../contexts';
import {IVehicleTypeCapacityForm} from './VehicleTypeCapacityForm';

export interface VehicleTypeCapacityContextProps
  extends TemplateTableContextProps<VehicleCapacityDimension> {
  loadList: () => void;
  loadVehicleTypeCapacity: () => Promise<void>;
  cleanupList: () => void;
  onDetailsFormSubmit: (
    values: IVehicleTypeCapacityForm,
    formikHelpers: FormikHelpers<IVehicleTypeCapacityForm>
  ) => void;
  setVehicleTypeCapacityId: (value: number | undefined) => void;
  submitting: boolean;
  vehicleTypeCapacity?: VehicleCapacityDimension;
  vehicleTypeCapacityId?: number;
  detailsRef?: React.Ref<FormikProps<IVehicleTypeCapacityForm>>;
}

export const VehicleTypeCapacityContext =
  React.createContext<VehicleTypeCapacityContextProps>({
    // Template Table Defaults
    loading: false,
    list: [],
    currentPage: 1,
    // VehicleTypeCapacity
    loadList: async () => {},
    loadVehicleTypeCapacity: async () => {},
    cleanupList: () => null,
    onDetailsFormSubmit: () => null,
    setVehicleTypeCapacityId: () => null,
    submitting: false,
  });

interface VehicleTypeCapacityContextProviderProps {
  vehicleTypeCapacityId?: number;
}

export const VehicleTypeCapacityContextProvider: React.FC<
  VehicleTypeCapacityContextProviderProps
> = ({children}) => {
  const {vehicleTypeId, loadVehicleType} = React.useContext(VehicleTypeContext);
  const history = useHistory();
  const notify = useAppNotifications();
  // Template Table
  const [
    {
      // States
      currentPage,
      filters,
      hasPermission,
      itemTotal,
      list,
      loading,
      pageSize,
      pageTotal,
      loadingSingleItem,
      sorting,
    },
    {
      // Getters
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
      setLoadingSingleItem,
    },
  ] = useTemplateTable<
    VehicleCapacityDimension,
    ApiVehicleCapacityDimensionGetRequest
  >({
    editPermission: 'Edit VehicleCapacityDimension',
    addPermission: 'Add VehicleCapacityDimension',
    deletePermission: 'Delete VehicleCapacityDimension',
    downloadPermission: 'VehicleCapacityDimension ListDownload',
    viewPermission: 'VehicleCapacityDimension List',
  });

  const loadList = React.useCallback<TLoadList<any>>(
    async (options) => {
      setLoading(true);
      try {
        if (vehicleTypeId) {
          const requestObj = getRequestObj(
            ['name', 'capacity', 'unitOfMeasure'],
            options,
            {vehicleTypeId}
          );
          const response =
            await vehicleCapacityDimensionApi.apiVehicleCapacityDimensionGet({
              ...requestObj,
              vehicleTypeId: vehicleTypeId,
            });
          return getResponse(response, options);
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to load');
      } finally {
        setLoading(false);
      }
    },
    [setLoading, vehicleTypeId, getRequestObj, getResponse, notify]
  );

  const handleDelete = React.useCallback(
    async (row: VehicleCapacityDimension) => {
      setLoading(true);
      try {
        if (row.id) {
          await vehicleCapacityDimensionApi.apiVehicleCapacityDimensionVehicleCapacityDimensionIdDelete(
            {
              vehicleCapacityDimensionId: row.id,
            }
          );
          await loadList();
          notify('success', 'Vehicle Type Capacity Dimension Deleted');
        }
      } catch (e) {
        notify('error', e.message ?? 'Deleting Vehicle Type Capacity');
      } finally {
        setLoading(false);
      }
    },
    [loadList, notify, setLoading]
  );

  const handleAdd = React.useCallback(() => {
    history.push(`/vehicletypelist/${vehicleTypeId}/capacity/add`);
  }, [history, vehicleTypeId]);

  const handleNavigate = React.useCallback(
    (row: VehicleCapacityDimension) => {
      history.push(`/vehicletypelist/${vehicleTypeId}/capacity/${row.id}`);
    },
    [history, vehicleTypeId]
  );

  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  // vehicleTypeCapacity
  const [vehicleTypeCapacity, setVehicleTypeCapacity] = React.useState<
    VehicleCapacityDimension | undefined
  >();
  const [vehicleTypeCapacityId, setVehicleTypeCapacityId] = React.useState<
    number | undefined
  >();
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const detailsRef = React.useRef<FormikProps<IVehicleTypeCapacityForm>>(null);

  const loadVehicleTypeCapacity = React.useCallback(async () => {
    setLoadingSingleItem(true);
    try {
      if (vehicleTypeCapacityId) {
        const response =
          await vehicleCapacityDimensionApi.apiVehicleCapacityDimensionVehicleCapacityDimensionIdGet(
            {
              vehicleCapacityDimensionId: vehicleTypeCapacityId,
            }
          );
        setVehicleTypeCapacity(response);
      } else {
        setVehicleTypeCapacity(undefined);
      }
    } catch (e) {
      notify('error', e.message ?? 'Loading Vehicle Type Capacity');
    } finally {
      setLoadingSingleItem(false);
    }
  }, [setLoadingSingleItem, vehicleTypeCapacityId, notify]);

  const handleDetailsFormSubmit = React.useCallback(
    async (
      values: IVehicleTypeCapacityForm,
      formikHelpers: FormikHelpers<IVehicleTypeCapacityForm>
    ) => {
      setSubmitting(true);
      formikHelpers.setSubmitting(true);
      try {
        const {capacityCompartments, ...otherValues} = values;
        if (vehicleTypeCapacity && vehicleTypeCapacity.id) {
          const newValues: VehicleCapacityDimensionPatch = {
            ...otherValues,
            vehicleTypeId: vehicleTypeCapacity?.vehicleTypeId ?? vehicleTypeId,
            capacityCompartments: capacityCompartments.map(
              ({id, capacity, compartmentId}) => ({
                id: id as number,
                capacity: capacity as number,
                compartmentId: compartmentId as number,
              })
            ),
          };
          await vehicleCapacityDimensionApi.apiVehicleCapacityDimensionVehicleCapacityDimensionIdPatch(
            {
              vehicleCapacityDimensionId: vehicleTypeCapacity.id,
              body: newValues,
            }
          );
        } else {
          const newValues: VehicleCapacityDimensionPost = {
            ...otherValues,
            vehicleTypeId: vehicleTypeCapacity?.vehicleTypeId ?? vehicleTypeId,
            capacityCompartments: capacityCompartments
              .filter(({capacity, compartmentId}) => capacity && compartmentId)
              .map(({capacity, compartmentId}) => ({
                capacity: capacity as number,
                compartmentId: compartmentId as number,
              })),
          };
          await vehicleCapacityDimensionApi.apiVehicleCapacityDimensionPost({
            body: newValues,
          });
        }
        // const first = hasEditPermission ? 0 : -1;
        history.goBack();
        notify(
          'success',
          `${
            vehicleTypeCapacity?.id ? 'Updated' : 'Added'
          } Vehicle Type Capacity`
        );
      } catch (e) {
        notify(
          'error',
          e.message ??
            `Error ${
              vehicleTypeCapacity?.id ? 'Updating' : 'Adding'
            } Vehicle Type Capacity`
        );
      } finally {
        formikHelpers.setSubmitting(false);
        setSubmitting(false);
      }
    },
    [vehicleTypeCapacity, vehicleTypeId, history, notify]
  );

  const value: VehicleTypeCapacityContextProps = {
    // Template Table
    loading: loadingSingleItem || loading,
    list,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onAdd: hasPermission.add ? handleAdd : undefined,
    onNavigate: handleNavigate,
    onDelete: hasPermission.delete ? handleDelete : undefined,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onRefresh: handleRefresh,
    // VehicleTypeCapacity
    loadList,
    loadVehicleTypeCapacity,
    cleanupList,
    onDetailsFormSubmit: handleDetailsFormSubmit,
    setVehicleTypeCapacityId,
    submitting,
    vehicleTypeCapacity,
    vehicleTypeCapacityId,
    detailsRef,
  };

  React.useEffect(() => {
    loadVehicleType();
    loadVehicleTypeCapacity();
    return () => setVehicleTypeCapacity(undefined);
  }, [loadVehicleType, loadVehicleTypeCapacity]);

  return (
    <VehicleTypeCapacityContext.Provider value={value}>
      {children}
    </VehicleTypeCapacityContext.Provider>
  );
};
