import React from 'react';
import {withRouter} from 'react-router-dom';
import {Table} from '@devexpress/dx-react-grid-material-ui';
import IconButton from '@mui/material/IconButton';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import SystemUpdateIcon from '@mui/icons-material/SystemUpdate';
import PlayArrow from '@mui/icons-material/PlayArrow';
import {makeStyles, createStyles} from '@mui/styles';
import Pause from '@mui/icons-material/Pause';
import MomentService from '../../service/MomentService';
import {LinkCell} from './linkCell';
import {SentenceCaseCell} from './sentenceCaseCell';
import {ActionsCell} from './actionsCell';
import {LastViewedCell} from './lastViewedCell';
import {Loader} from '../loader';

const LoadDashboardFullScreenButton = ({onClick}) => (
  <IconButton
    size="medium"
    title="Load Dashboard Full Screen"
    onClick={onClick}
  >
    <FullscreenIcon />
  </IconButton>
);

const LoadDashboardWindowedButton = ({onClick}) => (
  <IconButton size="medium" title="Load Dashboard In Vantage" onClick={onClick}>
    <FullscreenExitIcon />
  </IconButton>
);

const DownloadDashboardDataButton = ({onClick}) => (
  <IconButton size="medium" title="Download Dashboard Data" onClick={onClick}>
    <SystemUpdateIcon />
  </IconButton>
);

const PlayAudioFileButton = ({onClick}) => (
  <IconButton title="Play Audio" onClick={onClick} size="large">
    <PlayArrow />
  </IconButton>
);

const PauseAudioFileButton = ({onClick}) => (
  <IconButton title="Pause Audio" onClick={onClick} size="large">
    <Pause />
  </IconButton>
);

const useCellStyles = makeStyles(() =>
  createStyles({
    cell: {
      // paddingTop: 0,
      // paddingBottom: 0,
    },
  })
);

const Cell = withRouter((props) => {
  const classes = useCellStyles();

  if (
    [
      'arrivalTime',
      'departureTime',
      'actualDepartureTime',
      'actualArrivalTime',
      'eventDate',
      'depotDeparture',
      'actualDispatch',
      'createdAt',
      'lastRefresh',
      'scheduledDateTime',
    ].includes(props.column.name)
  ) {
    return (
      <Table.Cell
        {...props}
        value={MomentService.displayTimestamp(props.value)}
        className={classes.cell}
      />
    );
  }
  if (['scheduledDate'].includes(props.column.name)) {
    const newDate = new Date(props.value);
    return (
      <Table.Cell
        {...props}
        value={MomentService.toDate(newDate)}
        className={classes.cell}
      />
    );
  }
  if (['link'].includes(props.column.name)) {
    return <LinkCell value={props.value} link={props.value} />;
  }

  // if (['orderNumber'].includes(props.column.name)) {
  //   //TODO standardize this
  //
  //   const link = `/trip/${props.row.externalTripNumber}`;
  //   const CustomLinkCell = ({link}) => {
  //     const [route, setRoute] = React.useState('');
  //     const [loading, setLoading] = React.useState(true);
  //     React.useEffect(() => {
  //       const getLink = async () => {
  //         const newLink = await RoutingCoversion(link);
  //         setRoute(newLink);
  //       };
  //       getLink();
  //       setLoading(false);
  //     }, [link]);
  //
  //     if (loading) {
  //       return <div>loading...</div>;
  //     }
  //
  //     return (
  //       <>
  //         {route ? (
  //           <LinkCell {...props} link={route} value={props.value} />
  //         ) : (
  //           <Table.Cell className={classes.Cell} {...props}>
  //             <div
  //               style={{
  //                 cursor: 'pointer',
  //                 textDecoration: 'none',
  //                 color: 'black',
  //               }}
  //               onClick={() => {
  //                 appNotificationStore.enqueueNotification(
  //                   'error',
  //                   'trip not found'
  //                 );
  //               }}
  //             >
  //               {props.value}
  //             </div>
  //           </Table.Cell>
  //         )}
  //       </>
  //     );
  //   };
  //
  //   return (
  //     <React.Suspense fallback={<div>loading...</div>}>
  //       <CustomLinkCell link={link} />
  //     </React.Suspense>
  //   );
  // }
  if (['uuid'].includes(props.column.name)) {
    return <LinkCell link={`/recipient/${props.value}`} value={props.value} />;
  }

  if (['key'].includes(props.column.name)) {
    return <SentenceCaseCell value={props.value} />;
  }

  if (props.column.name === 'loadDashboardFullScreen') {
    return (
      <ActionsCell>
        <LoadDashboardFullScreenButton
          value={props.value}
          onClick={() => props.column.onClick(props.row)}
        />
      </ActionsCell>
    );
  }
  if (props.column.name === 'loadDashboardWindowed') {
    return (
      <ActionsCell>
        <LoadDashboardWindowedButton
          value={props.value}
          onClick={() => props.column.onClick(props.row)}
        />
      </ActionsCell>
    );
  }
  if (props.column.name === 'downloadDashboardData') {
    return (
      <ActionsCell>
        <DownloadDashboardDataButton
          value={props.value}
          onClick={() => props.column.onClick(props.row)}
        />
      </ActionsCell>
    );
  }
  if (props.column.name === 'playAudioFile') {
    if (
      props.column.audio &&
      props.column.audio.identifier === props.row.id &&
      props.column.audio.loading
    ) {
      return (
        <ActionsCell>
          <Loader size={40} />
        </ActionsCell>
      );
    }
    if (
      props.column.audio &&
      props.column.audio.identifier === props.row.id &&
      props.column.audio.isPlaying
    ) {
      return (
        <ActionsCell>
          <PauseAudioFileButton
            value={props.value}
            onClick={() => props.column.onPause(props.row)}
          />
        </ActionsCell>
      );
    }
    return (
      <ActionsCell>
        <PlayAudioFileButton
          value={props.value}
          onClick={() => props.column.onClick(props.row)}
        />
      </ActionsCell>
    );
  }
  if (['lastSeenEventDate'].includes(props.column.name)) {
    if (props.value) {
      return <LastViewedCell value={props.value} style={{cursor: 'pointer'}} />;
    }
  }
  return <Table.Cell {...props} value={props.value} className={classes.cell} />;
});

export default Cell;
