import React from 'react';
import {Stack} from '@mui/material';
import {
  eventsBulkClosingStepsElements,
  useEventsBulkClosing,
} from './useEventsBulkClosing';
import {
  EventsBulkClosingContext,
  EventsBulkClosingContextProvider,
} from './EventsBulkClosingContext';
import {Loader} from '../loader';
import {useEventsBulkCosingStyles} from './EventsBulkClosing.styles';
import {EventsBulkClosingStepper} from './EventsBulkClosingStepper';

export const EventsBulkClosing = () => {
  const classes = useEventsBulkCosingStyles();
  const {activeStep, completedSteps, onNextStep, onPreviousStep, onGoToStep} =
    useEventsBulkClosing();

  return (
    <Stack>
      <EventsBulkClosingContextProvider onNextStep={onNextStep}>
        <EventsBulkClosingStepper
          activeStep={activeStep}
          completedSteps={completedSteps}
          onNextStep={onNextStep}
          onPreviousStep={onPreviousStep}
          onGoToStep={onGoToStep}
        >
          <EventsBulkClosingContext.Consumer>
            {(value) =>
              value?.submitting === true ? (
                <Loader
                  text="Bulk Closing Events..."
                  classes={{loaderContainer: classes.loaderContainer}}
                />
              ) : (
                eventsBulkClosingStepsElements[activeStep]
              )
            }
          </EventsBulkClosingContext.Consumer>
        </EventsBulkClosingStepper>
      </EventsBulkClosingContextProvider>
    </Stack>
  );
};
