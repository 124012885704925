import React from 'react';
import {Button, Collapse, Stack} from '@mui/material';
import {
  OrderLine,
  VehicleCompartment,
  OrderLineCompartment,
  WebPlanningBoardTripStopOrder,
  WebPlanningBoardTripStop,
} from '@onroadvantage/onroadvantage-api';
import {Loader} from '../../../../../loader';
import {VantageDialogButton} from '../../../../../dialog';
import {CompartmentTruck} from '../../../../../comptartment/CompartmentTruck';
import {PlanningBoardGanttCompartmentTruckCompartment} from './PlanningBoardGanttCompartmentTruckCompartment';
import {usePlanningBoardGanttCompartmentTruckStyles} from './PlanningBoardGanttCompartmentTruck.style';
import {Autocomplete} from '../../../../../autocomplete';
import {usePlanningBoardGanttContext} from '../../../../planningBoardContext';
import {usePlanningBoardTripCompartmentsContext} from '../planningBoardTripCompartmentsContext';

export interface CompartmentsWithCapacities extends VehicleCompartment {
  capacity: number | null | undefined;
}

export type OrderVariants = 'loading' | 'offloading' | 'in-progress';

export interface WebPlanningBoardTripStopOrderWithVariant
  extends WebPlanningBoardTripStopOrder {
  variant: OrderVariants;
}

export interface WebPlanningBoardTripStopWithOrderVariant
  extends Omit<WebPlanningBoardTripStop, 'orders'> {
  orders: WebPlanningBoardTripStopOrderWithVariant[];
}

export interface OrderLineWithVariant extends OrderLine {
  variant: OrderVariants;
}
export interface OrderLineWithCompartment {
  line: OrderLineWithVariant;
  lineCompartment: OrderLineCompartment;
  compartment: CompartmentsWithCapacities;
  orderNumber: string | undefined;
}

export interface VehicleCompartmentWithOrderLines
  extends CompartmentsWithCapacities {
  lines?: OrderLineWithCompartment[];
}
export interface VehicleCompartmentWithOrderLine
  extends CompartmentsWithCapacities {
  line?: OrderLineWithCompartment;
}
export const PlanningBoardGanttCompartmentTruck: React.FC = () => {
  const classes = usePlanningBoardGanttCompartmentTruckStyles();
  const {compartments, isChanged, onSaveChanges, onResetChanges, submitting} =
    usePlanningBoardTripCompartmentsContext();
  const {
    vehicleCapacityDimensionOptions,
    onChangeCapacityDimension,
    selectedVehicleCapacityDimensionOption,
  } = usePlanningBoardGanttContext();

  const getCompartmentWithOrderLines = (
    compartmentId: number | null | undefined
  ) => compartments.find(({id}) => id === compartmentId);

  if (submitting) return <Loader text="Updating order lines..." />;

  return (
    <Stack p={1.5} flex={1}>
      <Collapse in={isChanged}>
        <VantageDialogButton
          title="Save"
          dialogTitle="Are you sure you want to save your changes"
          onAccept={onSaveChanges}
        />
        <Button onClick={onResetChanges}>Cancel</Button>
      </Collapse>
      <CompartmentTruck
        compartments={compartments}
        maxWidth="100%"
        paperClass={classes.root}
        getVerticalCompartmentContent={(compartment) => {
          const compartmentWithOrderLines = getCompartmentWithOrderLines(
            compartment.id
          );
          if (!compartmentWithOrderLines) return null;
          return (
            <PlanningBoardGanttCompartmentTruckCompartment
              compartmentWithOrderLines={compartmentWithOrderLines}
              horizontalLevel
            />
          );
        }}
        getCompartment={(compartment) => {
          const compartmentWithOrderLines = getCompartmentWithOrderLines(
            compartment.id
          );
          if (!compartmentWithOrderLines) return null;
          return (
            <PlanningBoardGanttCompartmentTruckCompartment
              compartmentWithOrderLines={compartmentWithOrderLines}
            />
          );
        }}
      />
      <Autocomplete
        name="capacityDimension"
        options={vehicleCapacityDimensionOptions}
        value={
          selectedVehicleCapacityDimensionOption ??
          vehicleCapacityDimensionOptions[0]
        }
        onChange={onChangeCapacityDimension}
      />
    </Stack>
  );
};
