import React from 'react';
import {
  listingSchema,
  multipleListingSchema,
  TemplateCard,
  TemplateForm,
} from '../../factory/template';
import {WorkflowConfigContext} from './WorkflowConfigContext';
import {Listing, WorkflowConfig} from '@onroadvantage/onroadvantage-api';
import * as Yup from 'yup';
import {FormikAutocompleteWithListing, FormikTextField} from '../formik';

export interface IWorkflowConfigForm {
  name: string;
  taskType: Listing;
  contracts: Listing[] | undefined;
}

const schema: Yup.SchemaOf<IWorkflowConfigForm> = Yup.object({
  name: Yup.string().required('Required'),
  taskType: listingSchema.required('Required'),
  contracts: multipleListingSchema,
});

const getInitialValues = (
  workflowConfig?: WorkflowConfig | null
): IWorkflowConfigForm | undefined => {
  if (workflowConfig) {
    return {
      name: workflowConfig.name ?? '',
      taskType: {
        label: workflowConfig.taskType.name,
        value: workflowConfig.taskType.id,
      },
      contracts: workflowConfig.contracts.map(({id, code, name}) => ({
        label: `${code} - ${name}`,
        value: id,
      })),
    };
  }
  return undefined;
};

export const WorkflowConfigForm: React.FC<{isAdd?: boolean}> = ({isAdd}) => {
  const {loading, submitting, workflowConfig, onDetailsFormSubmit} =
    React.useContext(WorkflowConfigContext);
  const [initialValues, setInitialValues] =
    React.useState<IWorkflowConfigForm>();

  React.useEffect(() => {
    const values = getInitialValues(workflowConfig);

    if (values) {
      setInitialValues(values);
    } else if (isAdd) {
      setInitialValues({
        name: '',
        taskType: {},
        contracts: [],
      });
    }
  }, [isAdd, workflowConfig, setInitialValues]);

  return (
    <TemplateCard
      title={`WorkflowConfig Details ${isAdd ? 'Add' : 'Edit'}`}
      loading={loading}
    >
      <TemplateForm<IWorkflowConfigForm>
        initialValues={initialValues}
        onSubmit={onDetailsFormSubmit}
        permission={{
          name: `${isAdd ? 'Add' : 'Edit'} WorkflowConfig`,
          type: isAdd ? 'Add' : 'Edit',
        }}
        submitting={submitting}
        validationSchema={schema}
      >
        <FormikTextField
          name="name"
          placeholder="Enter the workflow config name"
          label="Name"
          fullWidth
        />
        <FormikAutocompleteWithListing
          name="taskType"
          placeholder="Enter and select the workflow config task type"
          label="Task Type"
          model="TaskType"
        />
        <FormikAutocompleteWithListing
          name="contracts"
          placeholder="Enter and select the workflow config contracts"
          label="Contracts"
          model="Contract"
          multiple
        />
      </TemplateForm>
    </TemplateCard>
  );
};
