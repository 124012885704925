import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from '@mui/material';
import {red, orange} from '@mui/material/colors';

const EventsPaneCardStyles = (theme: Theme) =>
  createStyles({
    actions: {
      flex: '1 1 auto',
      display: 'flex',
      padding: theme.spacing(1),
      paddingBottom: theme.spacing(0.5),
      justifyContent: 'space-between',
    },
    actionsLoadingContainer: {display: 'flex'},
    content: {
      padding: theme.spacing(1.5),
      paddingTop: 0,
      paddingBottom: theme.spacing(1),
    },
    header: {paddingLeft: theme.spacing(1.5), paddingRight: theme.spacing(1.5)},
    headerAvatar: {backgroundColor: orange[400]},
    headerAvatarCritical: {backgroundColor: red[700]},
    list: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: theme.spacing(0.3),
    },
    listItem: {padding: 0, alignItems: 'flex-start'},
    listItemIcon: {paddingRight: theme.spacing(1), minWidth: 'fit-content'},
    listItemText: {
      margin: 0,
      marginTop: theme.spacing(0.1),
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      flexWrap: 'nowrap',
      flex: 1,
      columnGap: theme.spacing(1.5),
    },
    openInNew: {margin: theme.spacing(1)},
    root: {
      overflow: 'visible',
      backgroundColor: theme.palette.background.paper,
      paddingBottom: theme.spacing(0.5),
    },
    rootSelected: {
      cursor: 'default',
      backgroundColor: theme.palette.mode === 'dark' ? '#313131' : '#d1d1d1',
    },
    status: {marginTop: theme.spacing(1.5), marginRight: theme.spacing(1.5)},
  });

export const useEventsPaneCardStyles = makeStyles(EventsPaneCardStyles, {
  name: 'EventsPaneCardStyles',
});
