import React from 'react';
import {CriticalEventTypeContext} from './CriticalEventTypeContext';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../factory/template';
import {AdminCriticalEventType} from '@onroadvantage/onroadvantage-api';

export const CriticalEventTypeList: React.FC = () => {
  const {loadList, cleanupList} = React.useContext(CriticalEventTypeContext);

  const [config] = React.useState<TemplateTableConfig<AdminCriticalEventType>>({
    columns: [
      {name: 'name', label: 'Name', type: 'string'},
      {name: 'externalReference', label: 'External Reference', type: 'string'},
      {
        name: 'integrationEnabled',
        label: 'Integration',
        type: 'bool',
        enableEditing: true,
      },
      {
        name: 'autoClose',
        label: 'Auto Close',
        type: 'bool',
        enableEditing: true,
      },
    ],
    enableFilter: true,
    enableSort: true,
    identifier: 'ADMIN_CRITICAL_EVENT_TYPE_LIST',
  });

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [loadList, cleanupList]);

  return (
    <TemplateTableWrapper config={config} context={CriticalEventTypeContext} />
  );
};
