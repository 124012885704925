import React from 'react';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../../factory/template';
import {VehicleCompartment} from '@onroadvantage/onroadvantage-api';
import {VehicleTypeCompartmentContext} from './VehicleTypeCompartmentContext';
import {VehicleTypeCompartmentTruck} from './vehicleTypeCompartmentTruck';

export const VehicleTypeCompartmentList: React.FC = () => {
  const [config] = React.useState<TemplateTableConfig<VehicleCompartment>>({
    columns: [
      {name: 'name', label: 'Name', type: 'string'},
      {name: 'loadingSequence', label: 'Loading Sequence', type: 'number'},
      {
        name: 'offloadingSequence',
        label: 'Offloading Sequence',
        type: 'number',
      },
    ],
    deleteMessage: (row) => ({
      title: 'Vehicle Compartment',
      items: {Name: row.name},
    }),
    identifier: 'VEHICLE_TYPE_COMPARTMENT_LIST',
    disablePagination: true,
    disableToolbar: true,
    enableGrouping: false,
  });

  return (
    <>
      <VehicleTypeCompartmentTruck />
      <TemplateTableWrapper
        config={config}
        context={VehicleTypeCompartmentContext}
      />
    </>
  );
};
