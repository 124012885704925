import {makeStyles, createStyles} from '@mui/styles';

const TripDebriefSummaryStopTaskListStyles = () =>
  createStyles({
    card: {padding: 0},
    cardContent: {padding: 0},
  });

export const useTripDebriefSummaryStopTaskListStyles = makeStyles(
  TripDebriefSummaryStopTaskListStyles,
  {
    name: 'TripDebriefSummaryStopTaskListStyles',
  }
);
