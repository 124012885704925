import React from 'react';
import {TemplateTable, TemplateTableConfig} from '../../../../factory/template';
import {getImgTags} from '../../../../service/Util';
import {RoleService} from '../../../../service';
import {WorkflowDetailsTaskModal} from './WorkflowDetailsTaskModal';
import {WorkflowDetailsTaskPayload} from './WorkflowDetailsTaskPayload';
import {Card, CardContent} from '@mui/material';
import {WorkflowDetailsContext} from '../WorkflowDetailsContext';
import {
  DriverWorkflowConfigTaskTemplate,
  Task,
} from '@onroadvantage/onroadvantage-api';
import {Loader} from '../../../loader';

interface SequencedTask extends Task {
  sequence: number | undefined;
}

export const WorkflowDetailsTaskList: React.FC = () => {
  const {loadingDetails, submitting, workflowDetails, onTaskInlineEdit} =
    React.useContext(WorkflowDetailsContext);
  const [open, setOpen] = React.useState<boolean>(false);
  const [modalType, setModalType] = React.useState<string | undefined>();
  const [modalContent, setModalContent] = React.useState<React.ReactNode>(null);
  const [modalLoading, setModalLoading] = React.useState<boolean>(false);

  const handlePayloadPress = React.useCallback(
    async (type, value?: string | null) => {
      setOpen(true);
      setModalLoading(true);
      try {
        setModalType(type);
        if (type === 'Checklist' || type === 'Questionnaire')
          setModalContent(value);
        else setModalContent(await getImgTags({value, type}));
      } finally {
        setModalLoading(false);
      }
    },
    []
  );

  const config: TemplateTableConfig<SequencedTask> = React.useMemo(
    () => ({
      columns: [
        {
          name: 'id',
          label: 'ID',
          type: 'number',
          enableEditing: false,
        },
        {
          name: 'template.name' as 'template',
          label: 'Task Name',
          type: 'string',
          enableEditing: false,
          getValue: ({template}) => template?.name,
        },
        {
          name: 'templateTypeName' as 'template',
          label: 'Task Type',
          type: 'string',
          enableEditing: false,
          getValue: ({template}) => template?.type?.name,
        },
        {
          name: 'status',
          label: 'Task Status',
          type: 'string',
          enableEditing: false,
        },
        {
          name: 'payload',
          label: 'Value',
          type: 'string',
          getValue: (value) =>
            value.template?.type?.name === 'Number Input' ? (
              submitting ? (
                <Loader size={32} />
              ) : (
                value.payload
              )
            ) : (
              <WorkflowDetailsTaskPayload
                row={value}
                value={value.payload}
                onPress={handlePayloadPress}
              />
            ),
          enableEditing: (value) =>
            value?.template?.type?.name === 'Number Input' &&
            RoleService.hasPermission('Edit Task', 'Edit'),
        },
        {
          name: 'eventDate',
          label: 'Event Date',
          type: 'datetime',
          enableEditing: false,
        },
        {
          name: 'latitude',
          label: 'Latitude',
          type: 'number',
          enableEditing: false,
        },
        {
          name: 'longitude',
          label: 'Longitude',
          type: 'number',
          enableEditing: false,
        },
        {
          name: 'sequence',
          label: 'Sequence',
          type: 'number',
          enableEditing: false,
        },
      ],
      disableToolbar: true,
      disablePagination: true,
    }),
    [handlePayloadPress, submitting]
  );

  const handleModalClose = () => {
    setOpen(false);
    setModalType(undefined);
    setModalContent(undefined);
    setModalLoading(false);
  };

  const taskList: SequencedTask[] = React.useMemo(() => {
    /** Extract tasks on the workflow */
    const tasks: Task[] = workflowDetails?.tasks ?? [];
    /**
     * Extract taskTemplates on the workflowConfig, which is not the TaskTemplate model, but actually
     * WorkflowConfigTaskTemplate.
     */
    const taskTemplates: DriverWorkflowConfigTaskTemplate[] =
      workflowDetails?.workflowConfig?.taskTemplates ?? [];
    /**
     * Declare a sequencedTasks array where we will add all the workflowTasks with their sequences which we will find
     * on the workflowConfigTaskTemplate
     */
    const sequencedTasks: SequencedTask[] = [];

    /**
     * We now loop over all the workflowTasks and workflowConfigTaskTemplates and match them by workflowTask.template.id
     * and workflowConfigTaskTemplate.taskTemplate.id, and if they mach we append the current workflowTask and add the
     * workflowConfigTaskTemplate.sequence to the sequencedTasks array
     */
    tasks?.forEach((task) => {
      taskTemplates.forEach((taskTemplate) => {
        if (taskTemplate.taskTemplate.id === task.template?.id) {
          sequencedTasks.push({...task, sequence: taskTemplate.sequence});
        }
      });
    });

    /**
     * Just in case there were workflowTasks that didn't mach workflowConfigTaskTemplates, we'll add the unmatched
     * workflowTasks to the sequencedTasks, but only with sequence as undefined.
     */
    tasks.forEach((task) => {
      if (!sequencedTasks.find(({id}) => id === task.id)) {
        sequencedTasks.push({...task, sequence: undefined});
      }
    });

    return (
      sequencedTasks.sort((a, b) =>
        a.sequence && b.sequence ? a.sequence - b.sequence : 0
      ) ?? []
    );
  }, [workflowDetails?.tasks, workflowDetails?.workflowConfig?.taskTemplates]);

  const onInlineEnableEdit = React.useCallback(
    ({template}: SequencedTask) => template?.type?.name === 'Number Input',
    []
  );

  return (
    <Card>
      <CardContent>
        <TemplateTable
          config={config}
          currentPage={1}
          elevation={0}
          list={taskList}
          loading={loadingDetails || submitting}
          onEnableRowEdit={onInlineEnableEdit}
          onInlineEdit={onTaskInlineEdit}
        />
        <WorkflowDetailsTaskModal
          open={open}
          onClose={handleModalClose}
          content={modalContent}
          loading={modalLoading}
          type={modalType}
        />
      </CardContent>
    </Card>
  );
};
