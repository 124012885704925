import React from 'react';
import {Listing, WebPlanningBoardOrder} from '@onroadvantage/onroadvantage-api';
import {Autocomplete, AutocompleteProps} from '../../../../../autocomplete';
import {
  usePlanningBoardContext,
  usePlanningBoardGanttContext,
  WebPlanningBoardMasterTripWithIdType,
} from '../../../../planningBoardContext';
import {parseOrderOption} from '../../../../helpers';
import {usePlanningBoardTripContext} from '../../planningBoardTripContext';

interface PlanningBoardTripOrderAutocompleteProps {
  selectedOrders: Listing[];
  onChange: AutocompleteProps['onChange'];
}

export const PlanningBoardTripOrdersAddAutocomplete: React.FC<
  PlanningBoardTripOrderAutocompleteProps
> = ({selectedOrders, onChange}) => {
  const {selectedGanttItem} = usePlanningBoardGanttContext();
  const {addedOrders} = usePlanningBoardTripContext();
  const {loadingOrders, unassignedOrders} = usePlanningBoardContext();

  const options: Listing[] = React.useMemo(() => {
    const existingOrderIds: number[] = [];

    if (selectedGanttItem?.variant === 'order') {
      const order = selectedGanttItem.data as WebPlanningBoardOrder | undefined;
      if (order?.id) {
        existingOrderIds.push(order.id);
      }
    } else {
      const masterTrip = selectedGanttItem?.data as
        | WebPlanningBoardMasterTripWithIdType
        | undefined;
      masterTrip?.trip?.stops?.forEach(({orders}) => {
        orders?.forEach(({id}) => {
          if (id && !existingOrderIds.includes(id)) {
            existingOrderIds.push(id);
          }
        });
      });
    }

    addedOrders.forEach(({id}) => {
      if (id && !existingOrderIds.includes(id)) {
        existingOrderIds.push(id);
      }
    });

    return unassignedOrders
      .map(parseOrderOption)
      .filter(({value}) => value && !existingOrderIds.includes(value));
  }, [addedOrders, selectedGanttItem, unassignedOrders]);

  return (
    <Autocomplete
      name="addOrder"
      options={options}
      label="Add Order"
      onChange={onChange}
      loading={loadingOrders}
      value={selectedOrders}
      multiple
    />
  );
};
