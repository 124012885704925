import React from 'react';
import {
  getCapacityDimension,
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../../factory/template';
import {OrderLine, Product} from '@onroadvantage/onroadvantage-api';
import {OrderLineContext} from './OrderLineContext';
import {OrderContext} from '../OrderContext';

export const OrderLineList: React.FC = () => {
  const {order} = React.useContext(OrderContext);
  const getUom = (product: Product | undefined) =>
    product?.orderLineQuantityPreferenceUom &&
    product.orderLineQuantityPreferenceUom !== ''
      ? product.orderLineQuantityPreferenceUom
      : product?.uom;

  const [config] = React.useState<TemplateTableConfig<OrderLine>>({
    columns: [
      {
        name: 'product',
        label: 'Product Name',
        type: 'string',
        model: 'Product',
        enableAutocomplete: true,
        enableEditing: true,
        getValue: ({product, productName}) => product?.name ?? productName,
        enableFilter: false,
      },
      {
        name: 'quantity',
        label: 'Planned Quantity',
        type: 'number',
        enableEditing: true,
        enableFilter: false,
        getFieldValue: ({quantity}) => quantity,
        getValue: ({quantity, product}) => {
          const capacityDimension = getCapacityDimension(
            product?.orderLineQuantityPreferenceUom,
            order?.planningOrder
          );

          if (capacityDimension != null) {
            return `${capacityDimension}${product?.orderLineQuantityPreferenceUom}`;
          }

          return `${quantity}${product?.uom}`;
        },
      },
      {
        name: 'actualLoadedQuantity',
        label: 'Actual Loaded Quantity',
        type: 'number',
        enableEditing: true,
        enableFilter: false,
        getFieldValue: ({actualLoadedQuantity}) => actualLoadedQuantity,
        getValue: ({actualLoadedQuantity, product}) =>
          actualLoadedQuantity
            ? `${actualLoadedQuantity}${getUom(product)}`
            : '-',
      },
      {
        name: 'actualQuantity',
        label: 'Actual Offloaded Quantity',
        type: 'number',
        enableEditing: true,
        enableFilter: false,
        getFieldValue: ({actualQuantity}) => actualQuantity,
        getValue: ({actualQuantity, product}) =>
          actualQuantity ? `${actualQuantity}${getUom(product)}` : '-',
      },
    ],
    deleteMessage: (row) => ({
      title: 'Order Line',
      items: {
        Name: row.productName ?? row.product?.name,
        Description: row.product?.description,
        OrderLineQuantityPreferenceUom:
          row.product?.orderLineQuantityPreferenceUom,
        CompartmentFillingPreferenceUom:
          row.product?.compartmentFillingPreferenceUom,
        VariabilityThreshold: row.product?.variabilityThreshold,
      },
    }),
    enableFilter: true,
    disableToolbar: true,
    disablePagination: true,
  });

  return <TemplateTableWrapper config={config} context={OrderLineContext} />;
};
