import ConfigService from './ConfigService/ConfigService';

import ResponseService from './ResponseService';
import {authStore} from '../store';

const serverUrl = ConfigService.serverUri;
const voiceCallsQueryUrl = `${serverUrl}/api/voice`;

const VoiceService = {
  createDirectCall: async (body) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    if (auth.oauthAuthenticated) {
      headers.set('Authorization', `Bearer ${auth.token}`);
    } else {
      headers.set('Authentication-Token', auth.token);
    }
    const response = await fetch(`${voiceCallsQueryUrl}/twilio/direct`, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(body),
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  getVoiceMessageUploadDetails: async (identifier) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    if (auth.oauthAuthenticated) {
      headers.set('Authorization', `Bearer ${auth.token}`);
    } else {
      headers.set('Authentication-Token', auth.token);
    }
    const body = JSON.stringify({identifier});
    const response = await fetch(
      `${serverUrl}/api/voice_message/generate/presigned/post`,
      {
        method: 'POST',
        mode: 'cors',
        body,
        headers,
      }
    );
    return ResponseService.checkStatus(response);
  },
  generateVoiceMessage: async ({identifier, message}) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    if (auth.oauthAuthenticated) {
      headers.set('Authorization', `Bearer ${auth.token}`);
    } else {
      headers.set('Authentication-Token', auth.token);
    }
    const body = JSON.stringify({identifier, message});
    const response = await fetch(
      `${serverUrl}/api/voice_message/generate/audio/file`,
      {
        method: 'POST',
        mode: 'cors',
        body,
        headers,
      }
    );
    return ResponseService.checkStatus(response);
  },
};

export default VoiceService;
