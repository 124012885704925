import React from 'react';
import {Button, Stack, Typography} from '@mui/material';
import {Trip} from '@onroadvantage/onroadvantage-api';
import ConfigService from '../../service/ConfigService/ConfigService';
import {useTripContext} from './tripContext';
import TripService from '../../service/TripService';
import {useAppNotifications} from '../../contexts';

export const TripActions: React.FC = () => {
  const notify = useAppNotifications();
  const {masterTrip} = useTripContext();
  /** Destructure trip, need to add || ({} as Trip) for typescript, since trip is nullable. */
  const {taskId, tripNumber} = masterTrip?.trip || ({} as Trip);

  const handleNavigate = React.useCallback(() => {
    if (taskId) {
      window.open(
        `${ConfigService.serverUri}/api/trip/trip_sheet/${taskId}`,
        '_blank'
      );
    }
  }, [taskId]);

  const handleDownloadPDF = React.useCallback(async () => {
    try {
      const pdfBlob = await TripService.downloadPDF(taskId, tripNumber);
      if (pdfBlob.error) {
        notify('error', "Can't download the tripsheet, try again later");
      }
    } catch (e) {
      notify('error', "Can't download the tripsheet, try again later");
    }
  }, [notify, taskId, tripNumber]);

  return (
    <Stack direction="row" spacing={1.5}>
      <Button variant="contained" disabled={!taskId} onClick={handleNavigate}>
        <Typography>View Tripsheet</Typography>
      </Button>
      <Button variant="outlined" disabled={!taskId} onClick={handleDownloadPDF}>
        <Typography>Download Tripsheet (PDF)</Typography>
      </Button>
    </Stack>
  );
};
