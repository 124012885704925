import React from 'react';
import {
  MasterTripActivity,
  MasterTripQuery,
} from '@onroadvantage/onroadvantage-api';
import {TripCard} from './tripCard';
import {
  TemplateTable,
  TemplateTableConfig,
  TOnInlineAdd,
} from '../../factory/template';
import {tripApi} from '../../api';
import {useAppNotifications} from '../../contexts';
import {useTripContext} from './tripContext';
import RoleService from '../../service/RoleService';

export const TripCommentList: React.FC = () => {
  const notify = useAppNotifications();
  const {masterTrip, masterTripId, loadingMasterTrip, updateMasterTrip} =
    useTripContext();
  /** Destructure masterTrip, need to add || ({} as MasterTripQuery) for typescript, since masterTrip is nullable. */
  const {activities} = masterTrip || ({} as MasterTripQuery);

  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const [config] = React.useState<TemplateTableConfig<MasterTripActivity>>({
    columns: [
      {name: 'value', label: 'Value', type: 'string', enableEditing: true},
      {
        name: 'createdAt',
        label: 'Created At',
        type: 'datetime',
        enableEditing: false,
      },
      {
        name: 'createdBy',
        label: 'Created by',
        type: 'string',
        enableEditing: false,
        getValue: ({createdBy}) => createdBy?.email,
      },
    ],
    disableToolbar: true,
    disablePagination: true,
  });

  const list = React.useMemo(() => activities ?? [], [activities]);

  const handleInlineAdd = React.useCallback<TOnInlineAdd>(
    async (changes) => {
      setSubmitting(true);
      try {
        if (masterTripId && changes && changes.length > 0) {
          const value = changes[0].value;
          const response = await tripApi.apiTripMasterTripIdPost({
            body: {activities: [{action: 'Comment', value}]},
            masterTripId,
          });

          if (response) {
            notify('success', 'Added comment');
            updateMasterTrip(response);
          }
        }
      } catch (e) {
        notify('error', 'Failed to add comment');
      } finally {
        setSubmitting(false);
      }
    },
    [masterTripId, notify, updateMasterTrip]
  );

  return (
    <TripCard
      title="Trip Comment"
      loading={loadingMasterTrip}
      loadingText="Loading Comments..."
    >
      <TemplateTable
        config={config}
        currentPage={1}
        list={list}
        loading={submitting}
        onInlineAdd={
          RoleService.hasPermission('Add TripComment', 'Add')
            ? handleInlineAdd
            : undefined
        }
      />
    </TripCard>
  );
};
