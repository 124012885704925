import React from 'react';
import {
  PlanningSkillContext,
  PlanningSkillContextProps,
} from './PlanningSkillContext';
import {useParams} from 'react-router-dom';

interface IParams {
  planningSkillId?: string;
}

export const PlanningSkillIdHandler: React.FC = () => {
  const planningSkillContext =
    React.useContext<PlanningSkillContextProps>(PlanningSkillContext);

  const planningSkillSetId = planningSkillContext.setPlanningSkillId;

  const {planningSkillId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = planningSkillId ? parseInt(planningSkillId) : undefined;
    planningSkillSetId(parsedId);

    return () => {
      planningSkillSetId(undefined);
    };
  }, [planningSkillId, planningSkillSetId]);

  return null;
};
