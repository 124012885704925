import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

const ForgotPasswordFormStyles = (theme: Theme) =>
  createStyles({
    container: {
      // [theme.breakpoints.down('sm')]: {
      //   padding: 10,
      // },
      // [theme.breakpoints.between('sm', 'md')]: {
      //   padding: 30,
      // },
      // [theme.breakpoints.up('md')]: {
      //   padding: 50,
      // },
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    button: {
      marginTop: theme.spacing(1.5),
    },
  });

export const useForgotPasswordFormStyles = makeStyles(
  ForgotPasswordFormStyles,
  {name: 'ForgotPasswordFormStyles'}
);
