import React from 'react';
import {
  ITemplateTabControllerTab,
  TemplateTabs,
} from '../../../factory/template';
import {SiteSubNodeContext} from './SiteSubNodeContext';
import {SiteContext} from '../SiteContext';
import {SiteSubNodeDetails} from './SiteSubNodeDetails';

export const SiteSubNode: React.FC = () => {
  const {siteId} = React.useContext(SiteContext);
  const {subNodeId, loading} = React.useContext(SiteSubNodeContext);
  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {title: 'Details', element: <SiteSubNodeDetails />},
  ]);

  const path = React.useMemo(
    () => `/sitelist/${siteId}/subnodelist/${subNodeId}`,
    [siteId, subNodeId]
  );

  return <TemplateTabs tabs={tabs} path={path} loading={loading} />;
};
