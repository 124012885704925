import React from 'react';
import {useHistory} from 'react-router-dom';
import _ from 'lodash';
import {
  ApiWebNodeGetRequest,
  WebNodeListDump,
  Node,
  NodeUpdate,
  WebNodeList,
  Listing,
} from '@onroadvantage/onroadvantage-api';
import {FormikHelpers, FormikProps} from 'formik/dist/types';
import {webNodeApi, nodeApi} from '../../api';
import {
  TemplateTableContextProps,
  TLoadList,
  useTemplateTable,
} from '../../factory/template';
import {LuxonService} from '../../service';
import {useAppNotifications} from '../../contexts';

export interface ISiteDetailsForm {
  name: string;
  externalReference: string;
  prohibitAfterHoursProcessing?: boolean;
  address?: string;
  latitude: string;
  longitude: string;
  openTime: Date;
  closeTime: Date;
  loadingTimeInMinutes: string;
  unloadingTimeInMinutes: string;
  processingTimeInMinutes: string;
  nodeType: Listing;
  planningSkills: Listing[] | undefined;
}

export interface SiteContextProps
  extends TemplateTableContextProps<WebNodeListDump, WebNodeList> {
  onDetailsFormSubmit: (
    values: ISiteDetailsForm,
    formikHelpers: FormikHelpers<ISiteDetailsForm>
  ) => void;
  siteEditCallback: (site: Node) => void;
  siteAddCallback: (site: Node) => void;
  setSiteId: (value: number | undefined) => void;
  submitting: boolean;
  site?: Node;
  siteId?: number;
  siteType?: string | null | undefined;
  detailsRef?: React.Ref<FormikProps<ISiteDetailsForm>>;
}

export const SiteContext = React.createContext<SiteContextProps>({
  // Template Table Defaults
  loading: false,
  list: [],
  currentPage: 1,
  // Site
  loadList: async () => {},
  cleanupList: () => {},
  onDetailsFormSubmit: () => null,
  siteEditCallback: () => {},
  siteAddCallback: () => {},
  setSiteId: () => null,
  submitting: false,
});

interface SiteContextProviderProps {
  siteId?: number;
}

export const SiteContextProvider: React.FC<SiteContextProviderProps> = ({
  children,
}) => {
  const history = useHistory();
  const notify = useAppNotifications();
  // Template Table

  const [
    {
      // States
      currentPage,
      filters,
      hasPermission,
      itemTotal,
      list,
      loading,
      pageSize,
      pageTotal,
      loadingSingleItem,
      sorting,
    },
    {
      // Getters
      getDownloads,
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
      setLoadingSingleItem,
    },
  ] = useTemplateTable<WebNodeListDump, ApiWebNodeGetRequest>({
    editPermission: 'Edit Site',
    addPermission: 'Add Site',
    deletePermission: 'Delete Site',
    downloadPermission: 'Site ListDownload',
    viewPermission: 'Site List',
  });

  const loadList = React.useCallback<TLoadList<WebNodeList>>(
    async (options) => {
      setLoading(true);
      try {
        const requestObj = getRequestObj(
          [
            'name',
            'nodeType',
            'type',
            'externalReference',
            'telematicsConfigId',
            'externalId',
            'providerName',
            'mixOrganisationGroupName',
          ],
          options
        );
        const response = await webNodeApi.apiWebNodeGet(requestObj);
        return getResponse(response, options);
      } catch (e) {
        notify('error', e.message ?? 'Failed to load sites');
      } finally {
        setLoading(false);
      }
    },
    [setLoading, getRequestObj, getResponse, notify]
  );

  const handleDownload = React.useCallback(async () => {
    const customParser = (item: any) => {
      const s: {[key: string]: any} = {};
      const timeWindows = _.get(item, 'timeWindows');
      if (timeWindows && timeWindows.length > 0) {
        const timeWindow = timeWindows[0];
        s['Open Times'] = `${LuxonService.fromServerTimeToLocalTimeOnly(
          timeWindow.openTime
        )} - ${LuxonService.fromServerTimeToLocalTimeOnly(
          timeWindow.closeTime
        )}`;
      }
      return s;
    };
    getDownloads(
      'sites',
      loadList,
      [
        {name: 'Name', path: 'name'},
        {name: 'External Reference', path: 'externalReference'},
        {name: 'Type', path: 'nodeType.name'},
        {name: 'Latitude', path: 'latitude'},
        {name: 'Longitude', path: 'longitude'},
        {name: 'Loading Time', path: 'loadingTimeInMinutes'},
        {name: 'Unloading Time', path: 'unloadingTimeInMinutes'},
        {name: 'Processing Time', path: 'processingTimeInMinutes'},
      ],
      customParser
    );
  }, [getDownloads, loadList]);

  const handleDelete = React.useCallback(
    async (row: WebNodeListDump) => {
      setLoading(true);
      try {
        if (row.id) {
          await nodeApi.apiNodeNodeIdDelete({
            nodeId: row.id,
          });
          await loadList();
          notify('success', 'Site Deleted');
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to delete site');
      } finally {
        setLoading(false);
      }
    },
    [loadList, notify, setLoading]
  );

  const handleAdd = React.useCallback(() => {
    history.push('/sitelist/add');
  }, [history]);

  const handleNavigate = React.useCallback(
    (row: WebNodeListDump) => {
      history.push(`/sitelist/${row.id}`);
    },
    [history]
  );

  const handleRefresh = React.useCallback(async () => {
    await loadList();
  }, [loadList]);

  // site
  const [site, setSite] = React.useState<Node | undefined>();
  const [siteId, setSiteId] = React.useState<number>();
  const [siteType, setSiteType] = React.useState<string | null | undefined>();
  const [submitting, setSubmitting] = React.useState(false);

  const detailsRef = React.useRef<FormikProps<ISiteDetailsForm>>(null);

  const loadSite = React.useCallback(async () => {
    setLoadingSingleItem(true);
    try {
      if (siteId) {
        const response = await nodeApi.apiNodeNodeIdGet({
          nodeId: siteId,
        });
        setSite(response);
        setSiteType(response.type);
      } else {
        setSite(undefined);
        setSiteType(undefined);
      }
    } catch (e) {
      notify('error', e.message ?? 'Failed to load site');
    } finally {
      setLoadingSingleItem(false);
    }
  }, [setLoadingSingleItem, siteId, notify]);

  const handleDetailsFormSubmit = React.useCallback(
    async (
      values: ISiteDetailsForm,
      formikHelpers: FormikHelpers<ISiteDetailsForm>
    ) => {
      setSubmitting(true);
      try {
        const {
          nodeType,
          latitude,
          longitude,
          loadingTimeInMinutes,
          processingTimeInMinutes,
          unloadingTimeInMinutes,
          prohibitAfterHoursProcessing,
          openTime,
          closeTime,
          planningSkills,
          ...otherValue
        } = values;
        const newValues: NodeUpdate = {
          ...otherValue,
          nodeTypeId: nodeType.value,
          prohibitAfterHoursProcessing,
          latitude: latitude ? parseFloat(latitude) : undefined,
          longitude: longitude ? parseFloat(longitude) : undefined,
          loadingTimeInMinutes: loadingTimeInMinutes
            ? parseFloat(loadingTimeInMinutes)
            : undefined,
          processingTimeInMinutes: processingTimeInMinutes
            ? parseFloat(processingTimeInMinutes)
            : undefined,
          unloadingTimeInMinutes: unloadingTimeInMinutes
            ? parseFloat(unloadingTimeInMinutes)
            : undefined,
          planningSkillIds: planningSkills
            ?.filter(({value}) => !!value)
            .map(({value}) => value as number),
          ...(closeTime &&
            openTime && {
              timeWindows: [
                {
                  openTime: LuxonService.fromLocalDateToServerTimeOnly(
                    openTime
                  ) as string,
                  closeTime: LuxonService.fromLocalDateToServerTimeOnly(
                    closeTime
                  ) as string,
                },
              ],
            }),
        };

        if (site && site.id) {
          await nodeApi.apiNodeNodeIdPatch({
            nodeId: site.id,
            body: newValues,
          });
        } else {
          await nodeApi.apiNodePost({
            body: newValues,
          });
        }
        history.push('/sitelist');
        notify('success', `${site?.id ? 'Updated' : 'Added'} Site`);
      } catch (e) {
        notify('error', e.message ?? 'Failed to update site');
      } finally {
        formikHelpers.setSubmitting(false);
        setSubmitting(false);
      }
    },
    [history, notify, site]
  );

  const siteEditCallback = React.useCallback(
    async (site: Node) => {
      notify('success', 'Site Updated');
      setSite(site);
      await loadList();
    },
    [loadList, notify]
  );

  const siteAddCallback = React.useCallback(
    async (site: Node) => {
      notify('success', 'Site Added');
      setSite(site);
      await loadList();
    },
    [loadList, notify]
  );

  const value: SiteContextProps = {
    // Template Table
    loading: loadingSingleItem || loading,
    list,
    hasPermission,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onAdd: hasPermission.add ? handleAdd : undefined,
    onDownload: hasPermission.download ? handleDownload : undefined,
    onNavigate: handleNavigate,
    onDelete: hasPermission.delete ? handleDelete : undefined,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onRefresh: handleRefresh,
    // Site
    loadList,
    cleanupList,
    onDetailsFormSubmit: handleDetailsFormSubmit,
    siteEditCallback,
    siteAddCallback,
    setSiteId,
    submitting,
    siteType,
    site,
    siteId,
    detailsRef,
  };

  React.useEffect(() => {
    loadSite();
    return () => setSite(undefined);
  }, [loadSite]);

  return <SiteContext.Provider value={value}>{children}</SiteContext.Provider>;
};
