import React from 'react';
import Chart, {ChartData, ChartOptions, ChartType} from 'chart.js';
import {getVantageRgb} from '../../service/Util';
import {Stack, StackProps} from '@mui/material';
import {ThemeModeContext} from '../../contexts';

export interface VantageChartProps extends Omit<StackProps, 'id'> {
  width?: number;
  height?: number;
  ctx?:
    | string
    | CanvasRenderingContext2D
    | HTMLCanvasElement
    | ArrayLike<CanvasRenderingContext2D | HTMLCanvasElement>;
  data?: ChartData;
  type?: ChartType;
  options: ChartOptions;
}

const colors = Array(100).fill([]).map(getVantageRgb);

const styles = {
  colors: {
    background: colors,
    borderLight: '#ddd',
    borderDark: '#333',
  },
};

export const VantageChart: React.FC<VantageChartProps> = ({
  width,
  height,
  data,
  type = 'bar',
  options,
  ...stackProps
}) => {
  const {themeMode} = React.useContext(ThemeModeContext);
  // TODO this the best option?
  const [ctx] = React.useState<string>(
    `${Date.now()}${Math.floor(Math.random() * 100)}`
  );

  React.useEffect(() => {
    const mapDatasets = data?.datasets?.map((dataset) => {
      const currentDataset = dataset;
      if (!('backgroundColor' in dataset)) {
        currentDataset.backgroundColor = styles.colors.background;
      }
      if (!('borderColor' in dataset)) {
        currentDataset.borderWidth = 1;
        currentDataset.borderColor =
          themeMode === 'light'
            ? styles.colors.borderLight
            : styles.colors.borderDark;
      }
      return currentDataset;
    });

    const chart = new Chart(ctx, {
      type,
      data: {...data, datasets: mapDatasets},
      options,
    });

    return () => {
      chart?.destroy();
    };
  }, [ctx, data, options, themeMode, type]);

  return (
    <Stack flexGrow={1} width={width} height={height} {...stackProps}>
      <canvas id={ctx} width={width} height={height} />
    </Stack>
  );
};
