import React from 'react';
import {ContactGroupContext} from './ContactGroupContext';
import {
  TemplateTable,
  TemplateTableConfig,
  TOnInlineAdd,
} from '../../factory/template';
import {Contact, Listing} from '@onroadvantage/onroadvantage-api';

export const ContactGroupContacts: React.FC = () => {
  const {contactGroup, loading, onUpdateContactGroup, hasPermission} =
    React.useContext(ContactGroupContext);
  const list = React.useMemo(
    () => contactGroup?.contacts ?? [],
    [contactGroup?.contacts]
  );

  const [config] = React.useState<TemplateTableConfig<Contact>>({
    columns: [
      {
        name: 'name',
        label: 'Contact Name',
        type: 'string',
        model: 'Contact',
        enableEditing: true,
        enableAutocomplete: true,
        enableMulti: true,
      },
    ],
    disableToolbar: true,
    disablePagination: true,
    deleteMessage: (row) => ({
      title: 'Contact Group Contact',
      items: {
        ContactGroupName: contactGroup?.name,
        ContactName: row.name,
        Email: row.email,
      },
    }),
    identifier: 'CONTACT_GROUP_CONTACTS_LIST',
  });

  const handleInlineAdd = React.useCallback<TOnInlineAdd>(
    (changes) => {
      if (contactGroup) {
        changes.forEach((change) => {
          const newContacts = change.name
            ?.filter(
              (item: Listing) =>
                item.value !== undefined && item.label !== undefined
            )
            .map(
              (item: Listing) => ({id: item.value, name: item.label} as Contact)
            );
          onUpdateContactGroup({
            name: contactGroup.name,
            contracts: contactGroup.contracts?.map(({id}) => ({id})),
            contacts: [...(contactGroup.contacts ?? []), ...newContacts],
          });
        });
      }
    },
    [contactGroup, onUpdateContactGroup]
  );
  const handleInlineDelete = React.useCallback(
    (row: Contact) => {
      if (contactGroup) {
        onUpdateContactGroup({
          name: contactGroup.name,
          contracts: contactGroup.contracts?.map(({id}) => ({id})),
          contacts: contactGroup.contacts
            ?.filter((contact) => contact.id !== row.id)
            ?.map(({id}) => ({id: id ?? undefined})),
        });
      }
    },
    [contactGroup, onUpdateContactGroup]
  );

  return (
    <TemplateTable
      config={config}
      currentPage={1}
      list={list}
      loading={loading}
      onDelete={hasPermission?.edit ? handleInlineDelete : undefined}
      onInlineAdd={hasPermission?.edit ? handleInlineAdd : undefined}
    />
  );
};
