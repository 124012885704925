import React from 'react';
import Typography from '@mui/material/Typography';
import {useEventsPaneCardActivityStyles} from './EventsPaneCardActivity.style';
import {MomentService} from '../../../service';
import {WebEventActivity} from '@onroadvantage/onroadvantage-api';

interface EventsPaneCardActivityProps {
  activity: WebEventActivity;
  filteredActions?: string[];
}

export const EventsPaneCardActivity: React.FC<EventsPaneCardActivityProps> = ({
  activity,
  filteredActions = [],
}) => {
  const classes = useEventsPaneCardActivityStyles();

  if (filteredActions && filteredActions.includes(activity.action as string)) {
    return null;
  }

  return (
    <div className={classes.root}>
      <div className={classes.info}>
        <Typography style={{fontWeight: 'bold'}}>{activity.action}:</Typography>
        <Typography>{activity.value}</Typography>
      </div>
      <div className={classes.infoBreakable}>
        <Typography style={{fontWeight: 'bold'}}>
          {MomentService.displayFromNow(activity.createdAt)}
        </Typography>
        <Typography>
          {MomentService.displayTimestamp(activity.createdAt)}
        </Typography>
        <Typography>{activity.createdByEmail}</Typography>
      </div>
    </div>
  );
};
