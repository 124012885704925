import React from 'react';
import {SequencedTask} from '@onroadvantage/onroadvantage-api';
import IconButton from '@mui/material/IconButton';
import CameraIcon from '@mui/icons-material/Camera';
import EditIcon from '@mui/icons-material/Edit';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import ListIcon from '@mui/icons-material/List';

interface WorkflowDetailsTaskPayloadProps {
  value?: string | null;
  row: SequencedTask;
  onPress: (type: string, value?: string | null | undefined) => Promise<void>;
}

export const WorkflowDetailsTaskPayload = ({
  value,
  row,
  onPress,
}: WorkflowDetailsTaskPayloadProps): JSX.Element => {
  const [content, setContent] = React.useState<React.ReactNode>();

  const handleCameraPress = React.useCallback(() => {
    onPress('Photo', value);
  }, [onPress, value]);

  const handleSignaturePress = React.useCallback(() => {
    onPress('Signature', value);
  }, [onPress, value]);

  const handleChecklistPress = React.useCallback(() => {
    onPress('Checklist', value);
  }, [onPress, value]);

  const handleQuestionnairePress = React.useCallback(() => {
    onPress('Questionnaire', value);
  }, [onPress, value]);

  React.useEffect(() => {
    switch (row.template?.type?.name) {
      case 'Photo':
      case 'Document':
        setContent(
          value && (
            <IconButton size="small" onClick={handleCameraPress}>
              <CameraIcon />
            </IconButton>
          )
        );
        break;
      case 'Signature':
        setContent(
          value && (
            <IconButton size="small" onClick={handleSignaturePress}>
              <EditIcon />
            </IconButton>
          )
        );
        break;
      case 'SegmentedControl':
        try {
          if (value) {
            setContent(`${JSON.parse(value).name} ${JSON.parse(value).value}`);
          }
        } catch (e) {
          setContent(`Error: ${JSON.stringify(e)}`);
        }
        break;
      case 'Checklist':
        setContent(
          value && (
            <IconButton size="small" onClick={handleChecklistPress}>
              <PlaylistAddCheckIcon />
            </IconButton>
          )
        );
        break;
      case 'Questionnaire':
        setContent(
          value && (
            <IconButton size="small" onClick={handleQuestionnairePress}>
              <ListIcon />
            </IconButton>
          )
        );
        break;
      default:
        setContent(value);
    }
    return () => setContent(undefined);
  }, [
    value,
    row.template?.type?.name,
    handleCameraPress,
    handleSignaturePress,
    handleChecklistPress,
    handleQuestionnairePress,
  ]);

  return <>{content}</>;
};
