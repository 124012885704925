import {makeStyles, createStyles} from '@mui/styles';
import {Theme} from '@mui/material/styles';

const AdminTelematicsConfigDetailsStyles = (theme: Theme) =>
  createStyles({
    panel: {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
    },
    details: {
      maxWidth: 600,
      flex: 1,
    },
    header: {
      fontWeight: theme.typography.fontWeightMedium,
      marginTop: theme.spacing(1),
    },
  });

export const useAdminTelematicsConfigDetailsStyles = makeStyles(
  AdminTelematicsConfigDetailsStyles,
  {
    name: 'AdminTelematicsConfigDetailsStyles',
  }
);
