import React from 'react';
import {MasterRouteContext} from './MasterRouteContext';
import {MasterRouteDetails} from './masterRouteDetails';
import {MasterRouteLegList} from './masterRouteLeg';
import {ITemplateTabControllerTab, TemplateTabs} from '../../factory/template';

export const MasterRoute: React.FC = () => {
  const {loading, masterRouteId} = React.useContext(MasterRouteContext);

  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {title: 'Details', element: <MasterRouteDetails />},
    {title: 'Legs', element: <MasterRouteLegList />},
  ]);

  const path = React.useMemo(
    () => `/masterroutelist/${masterRouteId}`,
    [masterRouteId]
  );

  return <TemplateTabs path={path} tabs={tabs} loading={loading} />;
};
