import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from '@mui/material';

const EventsPaneListStyles = (theme: Theme) =>
  createStyles({
    list: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      overflowY: 'scroll',
      minHeight: 0,
      gap: theme.spacing(1.5),
      padding: theme.spacing(1.5),
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: theme.palette.divider,
    },
    loaderContainer: {marginTop: theme.spacing(1.5)},
    overlayLoaderContainer: {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1,
      background: 'rgba(100,100,100,0.3)',
    },
    pagination: {
      display: 'flex',
      flexGrow: 0,
      flexShrink: 1,
      alignItems: 'center',
      alignSelf: 'flex-end',
      overflow: 'hidden',
    },
    paginationContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    root: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      minHeight: 0,
      // overflow: 'hidden',
    },
    search: {
      background: theme.palette.primary.main,
      color: '#fefefe',
      position: 'absolute',
      bottom: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      left: theme.spacing(3.5),
      transition: 'transform 0.4s linear',
      '&:hover': {
        background: theme.palette.primary.light,
      },
      zIndex: 100,
    },
    searchIsExpanded: {
      transition: 'transform 0.4s linear',
    },
  });

export const useEventsPaneListStyles = makeStyles(EventsPaneListStyles, {
  name: 'EventsPaneListStyles',
});
