import React from 'react';
import {useHistory} from 'react-router-dom';
import {
  ApiBroadcastGetRequest,
  Broadcast,
  BroadcastListResponse,
} from '@onroadvantage/onroadvantage-api';
import {broadcastApi} from '../../api';
import {TemplateTableContextProps} from '../../factory/template';
import {useTemplateTable, TLoadList} from '../../factory/template';
import {useAppNotifications} from '../../contexts';
import {VoiceService} from '../../service';
import {generateUid} from '../../service/Util';

export interface BroadcastContextProps
  extends TemplateTableContextProps<Broadcast, BroadcastListResponse> {
  onPlayAudio: (broadCast: Broadcast) => void;
  onPauseAudio: (broadCast: Broadcast) => void;
  setBroadcastId: (value: number | undefined) => void;
  audioPlayingBroadcastId: number | undefined;
  loadingAudio: boolean;
  loadingSingleItem: boolean;
  isPlaying: boolean;
  broadcast?: Broadcast;
  broadcastId?: number;
}

export const BroadcastContext = React.createContext<BroadcastContextProps>({
  // Template Table Defaults
  loading: false,
  list: [],
  currentPage: 1,
  // Broadcast
  loadList: async () => {},
  onPlayAudio: () => null,
  onPauseAudio: () => null,
  setBroadcastId: () => null,
  audioPlayingBroadcastId: undefined,
  loadingAudio: false,
  loadingSingleItem: false,
  isPlaying: false,
});

interface BroadcastContextProviderProps {
  broadcastId?: number;
}

export const BroadcastContextProvider: React.FC<
  BroadcastContextProviderProps
> = ({children}) => {
  const history = useHistory();
  const notify = useAppNotifications();
  // Template Table
  const [
    {
      currentPage,
      pageSize,
      pageTotal,
      sorting,
      hasPermission,
      itemTotal,
      list,
      loading,
      loadingSingleItem,
      filters,
    },
    {
      // Getters
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
      setLoadingSingleItem,
    },
  ] = useTemplateTable<Broadcast, ApiBroadcastGetRequest>({
    editPermission: 'Edit Broadcast',
    addPermission: 'Add Broadcast',
    deletePermission: 'Delete Broadcast',
    downloadPermission: 'Broadcast ListDownload',
    viewPermission: 'Broadcast List',
  });

  const loadList = React.useCallback<TLoadList<BroadcastListResponse>>(
    async (options) => {
      setLoading(true);
      try {
        return getResponse(
          await broadcastApi.apiBroadcastGet(
            getRequestObj(['heading', 'message', 'status'], options)
          ),
          options
        );
      } catch (e) {
        notify('error', e.message ?? 'Failed to load Broadcast List');
      } finally {
        setLoading(false);
      }
    },
    [getRequestObj, getResponse, notify, setLoading]
  );

  const handleDelete = React.useCallback(
    async (row: Broadcast) => {
      setLoading(true);
      try {
        if (row.id) {
          await broadcastApi.apiBroadcastBroadcastIdDelete({
            broadcastId: row.id,
          });
          await loadList();
          notify('success', 'Deleted Broadcast');
        }
      } catch (e) {
        notify('error', e.message);
      } finally {
        setLoading(false);
      }
    },
    [loadList, notify, setLoading]
  );

  const handleNavigate = React.useCallback(
    (row) => history.push(`/broadcastlist/${row.id}`),
    [history]
  );

  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  // Forms
  const [broadcast, setBroadcast] = React.useState<Broadcast | undefined>();
  const [broadcastId, setBroadcastId] = React.useState<number>();
  const [audioPlayingBroadcastId, setAudioPlayingBroadcastId] = React.useState<
    number | undefined
  >();
  const [isPlaying, setIsPlaying] = React.useState<boolean>(false);
  const [loadingAudio, setLoadingAudio] = React.useState<boolean>(false);
  const [audioRef, setAudioRef] = React.useState<HTMLAudioElement>(new Audio());

  const loadBroadcast = React.useCallback(async () => {
    setLoadingSingleItem(true);
    try {
      if (broadcastId) {
        const response = await broadcastApi.apiBroadcastBroadcastIdGet({
          broadcastId,
        });
        setBroadcast(response);
      } else {
        setBroadcast(undefined);
      }
    } catch (e) {
      notify('error', e.message ?? 'Failed to load Broadcast');
    } finally {
      setLoadingSingleItem(false);
    }
  }, [notify, setLoadingSingleItem, broadcastId]);

  const handlePlayAudio = React.useCallback<
    BroadcastContextProps['onPlayAudio']
  >(
    async (broadcast) => {
      if (audioRef) {
        await audioRef.pause();
      }
      setLoadingAudio(true);
      setAudioPlayingBroadcastId(broadcast.id);
      try {
        const response = await VoiceService.generateVoiceMessage({
          message: broadcast.message,
          identifier: generateUid(),
        });
        if (response.data) {
          const newRef = new Audio(response.data);
          setIsPlaying(true);
          setLoadingAudio(false);
          await newRef.play();
          setAudioRef(newRef);
        }
      } finally {
        setLoadingAudio(false);
      }
    },
    [audioRef]
  );

  const handlePauseAudio = React.useCallback<
    BroadcastContextProps['onPauseAudio']
  >(async () => {
    await audioRef.pause();
    setAudioPlayingBroadcastId(undefined);
  }, [audioRef]);

  React.useEffect(() => {
    audioRef.addEventListener('ended', () => setIsPlaying(false));
    return () => {
      audioRef.removeEventListener('ended', () => setIsPlaying(false));
    };
  }, [audioRef]);

  const value: BroadcastContextProps = {
    // Template Table
    list,
    loadList,
    hasPermission,
    loading,
    cleanupList,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onNavigate: handleNavigate,
    onDelete: hasPermission.delete ? handleDelete : undefined,
    onRefresh: handleRefresh,
    // Forms
    onPlayAudio: handlePlayAudio,
    onPauseAudio: handlePauseAudio,
    audioPlayingBroadcastId,
    loadingSingleItem,
    setBroadcastId,
    loadingAudio,
    broadcast,
    broadcastId,
    isPlaying,
  };

  React.useEffect(() => {
    loadBroadcast();
    return () => setBroadcast(undefined);
  }, [loadBroadcast]);

  return (
    <BroadcastContext.Provider value={value}>
      {children}
    </BroadcastContext.Provider>
  );
};
