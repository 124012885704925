import React from 'react';
import {CustomerContext} from './CustomerContext';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../factory/template';
import {Customer} from '@onroadvantage/onroadvantage-api';

export const CustomerList: React.FC = () => {
  const {loadList, cleanupList} = React.useContext(CustomerContext);

  const [config] = React.useState<TemplateTableConfig<Customer>>({
    columns: [
      {name: 'name', label: 'Name', type: 'string'},
      {name: 'description', label: 'Description', type: 'string'},
      {name: 'accountNumber', label: 'Account Number', type: 'string'},
      {
        name: 'contractCode',
        label: 'Contract Code',
        type: 'string',
        getValue: ({contract}) => contract?.code,
        enableFilter: false,
      },
    ],
    enableFilter: true,
    enableSort: true,
    deleteMessage: (row) => ({
      title: 'Customer',
      items: {
        Name: row.name,
        AccountNumber: row.accountNumber,
        ContractCode: row.contract?.code,
      },
    }),
    identifier: 'CUSTOMER_LIST',
  });

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [loadList, cleanupList]);

  return <TemplateTableWrapper config={config} context={CustomerContext} />;
};
