import React from 'react';
import {useAppNotifications} from '../../../../contexts';
import {Listing} from '@onroadvantage/onroadvantage-api';
import {listingApi} from '../../../../api';
import {debounce} from '@mui/material';
import {TripSetupAutocomplete} from './TripSetupAutocomplete';

interface TripSetupAutocompleteModelProps<T extends Listing | Listing[]> {
  model: string;
  name: string;
  label: string;
  value: T | null;
  onChange: (value: T | null) => void;
  multiple?: boolean;
  placeholder?: string;
  helperText?: string;
}

export const TripSetupAutocompleteModel = <T extends Listing | Listing[]>({
  model,
  name,
  label,
  value,
  onChange,
  multiple,
  placeholder,
  helperText,
}: TripSetupAutocompleteModelProps<T>) => {
  const notify = useAppNotifications();
  const [listing, setListing] = React.useState<Listing[]>([]);
  const [localLoading, setLocalLoading] = React.useState<boolean>(false);

  const getListing = React.useCallback(
    async (filter?: string) => {
      setLocalLoading(true);
      try {
        const response = await listingApi.apiListingGet({
          model,
          query: filter,
        });
        setListing(response.items ?? []);
      } catch (e) {
        notify('error', 'Failed to load Order listing');
      } finally {
        setLocalLoading(false);
      }
    },
    [model, notify]
  );

  const getListingDebounce = React.useRef(debounce(getListing, 500)).current;

  const handleInputChange = React.useCallback(
    async (_event, value) => {
      setLocalLoading(true);
      await getListingDebounce(value);
    },
    [getListingDebounce]
  );

  React.useEffect(() => {
    getListing();
    return () => setListing([]);
  }, [getListing]);

  return (
    <TripSetupAutocomplete
      options={listing}
      value={value}
      name={name}
      label={label}
      onChange={onChange}
      multiple={multiple}
      onInputChange={handleInputChange}
      loading={localLoading}
      placeholder={placeholder}
      helperText={helperText}
    />
  );
};
