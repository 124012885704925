import React from 'react';
import {ContractGroupContext} from './ContractGroupContext';
import {useParams} from 'react-router-dom';

interface IParams {
  contractGroupId?: string;
}

export const ContractGroupIdHandler: React.FC = () => {
  const {setContractGroupId} = React.useContext(ContractGroupContext);

  const {contractGroupId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = contractGroupId ? parseInt(contractGroupId) : undefined;
    setContractGroupId(parsedId);
    return () => setContractGroupId(undefined);
  }, [contractGroupId, setContractGroupId]);

  return null;
};
