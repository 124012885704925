import React from 'react';

const styles = {
  container: {
    margin: 8,
  },
};

export const LoginLoader: React.FC = () => (
  <div style={styles.container}>Please wait while being redirected</div>
);
