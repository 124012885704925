import React from 'react';
import {useFormikContext} from 'formik';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  FormHelperText,
} from '@mui/material';

export interface RadioOption {
  label: string;
  value: string | null;
}

interface FormikRadioGroupProps {
  className?: string | undefined;
  disabled?: boolean;
  direction?: 'column' | 'column-reverse' | 'row' | 'row-reverse';
  name: string;
  options: RadioOption[];
  title: string;
}

export const FormikRadioGroup: React.FC<FormikRadioGroupProps> = ({
  className,
  disabled,
  direction,
  name,
  options,
  title,
}) => {
  const {
    getFieldMeta,
    setFieldTouched,
    handleChange: formikHandleChange,
    isSubmitting,
  } = useFormikContext();
  const {
    error,
    touched,
    initialError,
    initialTouched,
    initialValue,
    value,
    ...meta
  } = getFieldMeta<RadioOption>(name);

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<any>): void => {
      setFieldTouched(name);
      formikHandleChange(e);
    },
    [formikHandleChange, name, setFieldTouched]
  );

  const displayError = React.useMemo(
    () => (touched ? error : undefined),
    [error, touched]
  );

  return (
    <FormControl
      disabled={disabled || isSubmitting}
      {...meta}
      component="fieldset"
      className={className}
      fullWidth
    >
      <FormLabel component="legend" error={!!displayError}>
        {title}
      </FormLabel>
      <RadioGroup
        aria-label={title}
        name={name}
        value={value}
        onChange={handleChange}
        style={{flexDirection: direction ?? 'column'}}
      >
        {options.map((o) => (
          <FormControlLabel
            disabled={disabled || isSubmitting}
            key={o.label}
            value={o.value}
            control={<Radio />}
            label={o.label}
          />
        ))}
      </RadioGroup>
      <FormHelperText error={!!displayError}>{displayError}</FormHelperText>
    </FormControl>
  );
};
