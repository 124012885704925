import React from 'react';
import {TaskTemplateContext} from './TaskTemplateContext';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../factory/template';
import {TaskTemplate} from '@onroadvantage/onroadvantage-api';

export const TaskTemplateList: React.FC = () => {
  const {loadList, cleanupList} = React.useContext(TaskTemplateContext);

  const [config] = React.useState<TemplateTableConfig<TaskTemplate>>({
    columns: [
      {name: 'name', label: 'Template Name', type: 'string'},
      {
        name: 'type.name',
        label: 'Type Name',
        type: 'string',
        getValue: ({type}) => type?.name,
        enableFilter: false,
      },
    ],
    enableFilter: true,
    enableSort: true,
    deleteMessage: (row) => ({
      title: 'Task Template',
      items: {TemplateName: row.name, TypeName: row.type?.name},
    }),
    identifier: 'TASK_TEMPLATE_LIST',
  });

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [loadList, cleanupList]);

  return <TemplateTableWrapper config={config} context={TaskTemplateContext} />;
};
