import React from 'react';
import {Formik, Form} from 'formik';
import {
  dateSchema,
  parameterSchema,
  ParameterSchemaType,
} from '../ReportSchema';
import * as Yup from 'yup';
import {
  fixedDurationOptions,
  ReportParameterDate,
  ReportParameterDateRange,
  ReportParameterDateTime,
  ReportParameterMultiSelect,
  ReportParameterCustomMultiSelect,
} from '../ReportScheduleForm/parameter';
import {CardContent, Divider} from '@mui/material';
import {
  Report as ReportType,
  ReportParameter,
} from '@onroadvantage/onroadvantage-api';
import {AutocompleteOptionType} from '../../autocomplete';
import moment from 'moment';
import {paramType, paramTypes} from '../ReportScheduleForm';
import {ReportGenerateFormActions} from './ReportGenerateFormActions';
import {DateTime} from 'luxon';
import {
  ReportParameterDateRangePicker,
  ReportParameterRegex,
} from '../parameters';
import _ from 'lodash';

const getDefaultValue = (rp: ReportParameter) => {
  let value: ParameterSchemaType['value'];
  switch (rp.type) {
    case 'arrint':
    case 'customlist':
      value = [];
      break;
    case 'date':
    case 'datetime':
      value = moment();
      break;
    case 'daterange':
      value = fixedDurationOptions[1];
      break;
    case 'decimal':
    case 'numeric':
    case 'int':
    case 'integer':
      value = 0;
      break;
  }

  if (rp.defaultValue) {
    value = rp.defaultValue;
  }

  return value;
};

const reportGenerateFormSchema = Yup.object({
  parameters: Yup.array(parameterSchema),
  date: Yup.mixed<AutocompleteOptionType>(),
  endDate: dateSchema,
  startDate: dateSchema,
});

export type ReportGenerateFormSchemaType = ReturnType<
  typeof reportGenerateFormSchema.validateSync
>;

const getReportParameterValues = (
  report?: ReportType
): ReportGenerateFormSchemaType => {
  // check if all params were on the existing object
  const defaultParams: ParameterSchemaType[] = [];
  if (report) {
    report.reportParameters?.forEach((rp) => {
      if (
        rp.type &&
        paramTypes.includes(rp.type as paramType) &&
        rp.name !== 'date'
      ) {
        const value: ParameterSchemaType['value'] = getDefaultValue(rp);
        if (rp.id) {
          defaultParams.push({
            name: rp.name,
            type: rp.type ?? 'string',
            required: rp.required ?? false, // TODO
            value,
          });
        }
      }
    });
  }

  return {
    parameters: defaultParams,
    date: fixedDurationOptions[1],
    endDate: moment(DateTime.now().minus({days: 1}).endOf('day').toJSDate()),
    startDate: moment(
      DateTime.now().minus({days: 1}).startOf('day').toJSDate()
    ),
  };
};

const getDateEnabled = (report?: ReportType) => {
  let found = false;
  if (report) {
    report.reportParameters?.forEach((rp) => {
      if (rp.name === 'date') {
        found = true;
      }
    });
  }

  return found;
};

interface ReportGenerateFormProps {
  report: ReportType | undefined;
  submitting?: boolean;
  loading?: boolean;
  disableCustomDateRange?: boolean;
  disableFormFields?: boolean;
  initialValues?: {[key: string]: any};
  params?: {[key: string]: any};
  Action?: React.ReactNode;
}

export const ReportGenerateForm: React.FC<ReportGenerateFormProps> = ({
  report,
  submitting,
  loading,
  disableCustomDateRange,
  disableFormFields,
  initialValues: propInitialValues,
  params,
  Action,
}) => {
  const initialValues = React.useMemo<ReportGenerateFormSchemaType>(
    () => getReportParameterValues(report),
    [report]
  );

  const isDateEnabled = React.useMemo<boolean>(
    () => getDateEnabled(report),
    [report]
  );

  if (!initialValues) {
    return null;
  }

  return (
    <Formik<ReportGenerateFormSchemaType>
      initialValues={{...initialValues, ...propInitialValues}}
      // there are 3 different "submit" buttons/actions
      onSubmit={() => {}}
      validationSchema={reportGenerateFormSchema}
    >
      <Form>
        <CardContent>
          {isDateEnabled && (
            <ReportParameterDateRangePicker
              submitting={submitting}
              disableCustomDateRange={disableCustomDateRange}
            />
          )}
          {_.filter(
            report?.reportParameters || [],
            (r) => r.name !== 'date'
          )?.map((rp, index) => {
            switch (rp.type) {
              case 'arrint':
                return (
                  <ReportParameterMultiSelect
                    disabled={submitting ?? disableFormFields}
                    id={`parameters[${index}].value`}
                    listName={rp.listName ?? ''}
                    label={rp.description}
                    multiple={true}
                    name={`parameters[${index}].value`}
                    key={rp.name}
                  />
                );
              case 'customlist':
                return (
                  <ReportParameterCustomMultiSelect
                    disabled={submitting ?? disableFormFields}
                    id={`parameters[${index}].value`}
                    listValues={rp.listValues ?? []}
                    label={rp.description}
                    multiple={true}
                    name={`parameters[${index}].value`}
                    key={rp.name}
                  />
                );
              case 'date':
                return (
                  <ReportParameterDate
                    disabled={submitting ?? disableFormFields}
                    id={`parameters[${index}].value`}
                    label={rp.description ?? ''}
                    name={`parameters[${index}].value`}
                    key={rp.name}
                  />
                );
              case 'datetime':
                return (
                  <ReportParameterDateTime
                    disabled={submitting ?? disableFormFields}
                    label={rp.description ?? ''}
                    name={rp.name ?? ''}
                    key={rp.name}
                  />
                );
              case 'daterange':
                return (
                  <ReportParameterDateRange
                    disabled={submitting}
                    id={rp.name}
                    label={rp.description}
                    name={`parameters[${index}].value`}
                    key={rp.name}
                  />
                );
              case 'decimal':
              case 'int':
              case 'integer':
              case 'numeric':
                return (
                  <ReportParameterRegex
                    disabled={submitting ?? disableFormFields}
                    id={rp.name}
                    label={rp.description}
                    name={`parameters[${index}].value`}
                    key={rp.name}
                  />
                );
              default:
                return null;
            }
          })}
        </CardContent>
        <Divider />
        <ReportGenerateFormActions
          isDateEnabled={isDateEnabled}
          params={params}
          loading={loading}
          reportId={report?.id}
          Action={Action}
        />
      </Form>
    </Formik>
  );
};
