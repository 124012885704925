import * as React from 'react';
import * as PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import withStyles from '@mui/styles/withStyles';
import lodash from 'lodash';

const styles = (theme) => ({
  Cell: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
});

export const SentenceCaseCellBase = ({classes, style, value}) => (
  <TableCell className={classes.Cell} style={style}>
    {lodash.startCase(value)}
  </TableCell>
);

SentenceCaseCellBase.propTypes = {
  value: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};
SentenceCaseCellBase.defaultProps = {
  style: {},
};

export const SentenceCaseCell = withStyles(styles, {name: 'SentenceCaseCell'})(
  SentenceCaseCellBase
);
