import React from 'react';
import {TemplateCard, TemplateForm} from '../../factory/template';
import {RoleContext} from './RoleContext';
import {Role, RolePost} from '@onroadvantage/onroadvantage-api';
import * as Yup from 'yup';
import {FormikTextField} from '../formik';

const schema: Yup.SchemaOf<RolePost> = Yup.object({
  name: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
});

const getInitialValues = (role?: Role | null): RolePost | undefined => {
  if (role) {
    return {
      name: role.name ?? '',
      description: role.description ?? '',
    };
  }
  return undefined;
};

export const RoleForm: React.FC<{isAdd?: boolean}> = ({isAdd}) => {
  const {detailsRef, loading, submitting, role, onDetailsFormSubmit} =
    React.useContext(RoleContext);
  const [initialValues, setInitialValues] = React.useState<RolePost>();

  React.useEffect(() => {
    const values = getInitialValues(role);

    if (values) {
      setInitialValues(values);
    } else if (isAdd) {
      setInitialValues({
        name: '',
        description: '',
      });
    }
  }, [isAdd, role, setInitialValues]);

  return (
    <TemplateCard
      title={`Role Details ${isAdd ? 'Add' : 'Edit'}`}
      loading={loading}
    >
      <TemplateForm<RolePost>
        initialValues={initialValues}
        onSubmit={onDetailsFormSubmit}
        permission={{
          name: `${isAdd ? 'Add' : 'Edit'} Role`,
          type: isAdd ? 'Add' : 'Edit',
        }}
        submitting={submitting}
        innerRef={detailsRef}
        validationSchema={schema}
      >
        <FormikTextField
          name="name"
          placeholder="Enter the role name"
          label="Name"
          fullWidth
        />
        <FormikTextField
          name="description"
          placeholder="Enter the role description"
          label="Description"
          fullWidth
        />
      </TemplateForm>
    </TemplateCard>
  );
};
