import React from 'react';
import {Link} from 'react-router-dom';
import {DataTypeProvider} from '@devexpress/dx-react-grid';
import {Tooltip, IconButton} from '@mui/material';
import {OpenInNewOutlined} from '@mui/icons-material';
import validator from 'validator';

export const TableLinkCell: React.FC<DataTypeProvider.ValueFormatterProps> = ({
  row,
  value,
}) => {
  const {link, action, label} = value;

  if (!validator.isURL(link || '')) {
    return null;
  }

  const handleClick = () => {
    action && action(value, row);
  };

  return (
    <Link
      to={{pathname: link}}
      target="_blank"
      rel="external noreferrer"
      onClick={handleClick}
    >
      <Tooltip title={label} arrow>
        <IconButton
          aria-label={label}
          style={{
            marginTop: -12,
            marginBottom: -12,
            paddingTop: 12,
            paddingBottom: 12,
          }}
          size="large"
        >
          <OpenInNewOutlined />
        </IconButton>
      </Tooltip>
    </Link>
  );
};
