import React from 'react';
import IconButton from '@mui/material/IconButton';
import TimerIcon from '@mui/icons-material/Timer';
import SkipNext from '@mui/icons-material/SkipNext';
import SkipPrevious from '@mui/icons-material/SkipPrevious';
import PlayArrow from '@mui/icons-material/PlayArrow';
import Pause from '@mui/icons-material/Pause';

class TimelinePlayerCard extends React.Component {
  render() {
    const {playback} = this.props;
    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <IconButton disabled size="large">
          <TimerIcon color="action" />
        </IconButton>
        <IconButton onClick={this.props.playback.prevFrame} size="large">
          <SkipPrevious />
        </IconButton>
        {!playback.isPlaying ? (
          <IconButton onClick={this.props.playback.togglePlaying} size="large">
            <PlayArrow />
          </IconButton>
        ) : (
          <IconButton onClick={this.props.playback.togglePlaying} size="large">
            <Pause />
          </IconButton>
        )}
        <IconButton onClick={this.props.playback.nextFrame} size="large">
          <SkipNext />
        </IconButton>
      </div>
    );
  }
}

export default TimelinePlayerCard;
