import React from 'react';
import {InputAdornment, Stack, TextField} from '@mui/material';
import {MobileDateTimePicker} from '@mui/x-date-pickers';
import {AccessTime} from '@mui/icons-material';
import {useTripSetupContext} from './TripSetupContext';
import {TripSetupAutocompleteModel} from './components';

export const TripSetupDetailsStep = () => {
  const {details, setDetails} = useTripSetupContext();

  const setTripStart = React.useCallback(
    (tripStart) => {
      setDetails((prevDetails) => ({...prevDetails, tripStart}));
    },
    [setDetails]
  );

  const setContract = React.useCallback(
    (contract) => {
      setDetails((prevDetails) => ({...prevDetails, contract}));
    },
    [setDetails]
  );

  const setDriver = React.useCallback(
    (driver) => {
      setDetails((prevDetails) => ({...prevDetails, driver}));
    },
    [setDetails]
  );

  const setVehicle = React.useCallback(
    (vehicle) => {
      setDetails((prevDetails) => ({...prevDetails, vehicle}));
    },
    [setDetails]
  );

  return (
    <Stack spacing={2.5}>
      <MobileDateTimePicker<Date>
        ampm={false}
        onChange={setTripStart}
        value={details.tripStart}
        label={'Trip Start'}
        renderInput={(props) => (
          <TextField
            {...props}
            name="tripStart"
            // className={clsx(classes.input, {
            //   [classes.inputError]: displayError,
            // })}
            // InputLabelProps={InputLabelProps}
            // error={!!displayError}
            // helperText={displayError}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment
                  // className={classes.timeIcon}
                  position="end"
                >
                  <AccessTime />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
      <TripSetupAutocompleteModel
        name="contract"
        label="Contract"
        model="Contract"
        value={details.contract}
        onChange={setContract}
        placeholder="Select a contract"
      />
      <TripSetupAutocompleteModel
        name="driver"
        label="Driver"
        model="Driver"
        value={details.driver}
        onChange={setDriver}
        placeholder="Select a driver"
      />
      <TripSetupAutocompleteModel
        name="vehicle"
        label="Vehicle"
        model="Vehicle"
        value={details.vehicle}
        onChange={setVehicle}
        placeholder="Select a vehicle"
      />
    </Stack>
  );
};
