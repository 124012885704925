import React, {useRef} from 'react';
import {SortEndHandler} from 'react-sortable-hoc';
import {
  EditTripStop,
  MasterTripQuery,
  OrderDump,
  Trip,
  TripStop,
  TripStopExecution,
  WebMasterTripStopList,
  WebMasterTripStopListResponse,
} from '@onroadvantage/onroadvantage-api';
import {
  changePosition,
  TOnInlineAdd,
  TOnInlineEdit,
} from '../../../../factory/template';
import {tripApi, webMasterTripApi} from '../../../../api';
import {ITripEditStopForm} from '../tripEditStopList';
import {
  getStopEditPayload,
  mapToNewStop,
  sortStopsBySequence,
} from '../../helpers';
import {useAppNotifications} from '../../../../contexts';
import {getStopPlannedTAT} from '../../../../utils';
import {RoleService} from '../../../../service';
import {useTripResponse} from '../../tripContext';
import {IVantageDialogRef} from '../../../dialog';

/**
 * DeepPartial -> Custom generic type which makes the root object as well as all it's nested objects Partial
 * Reason -> Need to add DeepPartial, because when editing details on the stops we update the state and only submit the
 * changes when the user has made all their changes, and because it's "local" changes, we don't always have the entire
 * schema values for the changed values, for e.g. if they change the `node` we only have the id and name of the new
 * `node`. Thus, DeepPartial makes it possible to only add the node.id and node.name without needing to add the entire
 * Node schema's values
 */
export type ITripEditTripStop = DeepPartial<TripStop> & {
  totalServiceTimeChangeReason?: string | undefined;
  hasActuals?: boolean | undefined;
};

export type TimestampPriorities = 'gps' | 'mobile';

interface loadOptions {
  reload?: boolean;
  disableErrorMessage?: boolean;
  errorMessage?: (e: unknown) => string;
}

interface loadStopOptions extends loadOptions {
  syncActuals?: boolean;
}

interface getStopActualsParams {
  stop: ITripEditTripStop;
  index: number;
  arrivalTimestamp: keyof TripStopExecution;
  departureTimestamp: keyof TripStopExecution;
}

interface useTripEditParams {
  allowDepotEdits: boolean;
  loadInitialData: useTripResponse['loadInitialData'];
  masterTrip: MasterTripQuery | undefined;
  masterTripId: number | undefined;
  updateMasterTrip: (masterTrip: MasterTripQuery) => void;
  setUpdatingTrip: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useTripEdit = (params: useTripEditParams) => {
  const {
    allowDepotEdits,
    masterTrip,
    masterTripId,
    loadInitialData,
    setUpdatingTrip,
    updateMasterTrip,
  } = params;

  const notify = useAppNotifications();
  // states
  const [editStopSequence, setEditStopSequence] =
    React.useState<boolean>(false);
  const [hasEditPermission] = React.useState<boolean>(
    RoleService.hasPermission('Edit MasterTrip', 'Edit')
  );
  const [isAddingOrders, setIsAddingOrders] = React.useState<boolean>(false);
  const [orders, setOrders] = React.useState<OrderDump[] | undefined>();
  const [ordersInitial, setOrdersInitial] = React.useState<
    OrderDump[] | undefined
  >();
  const [stops, setStops] = React.useState<ITripEditTripStop[] | undefined>();
  const [stopsInitial, setStopsInitial] = React.useState<
    ITripEditTripStop[] | undefined
  >();
  const [timestampPriority, setTimestampPriority] =
    React.useState<TimestampPriorities>('gps');
  // loading
  const [loadingStops, setLoadingStops] = React.useState<boolean>(false);

  // memos
  /**
   * isDirty is to check if the stops or orders have been changed or altered in any way. Since objects are compared by
   * reference
   * */
  const isDirty = React.useMemo(
    () =>
      !!(
        stopsInitial &&
        stops &&
        JSON.stringify(stopsInitial) !== JSON.stringify(stops)
      ),
    [stops, stopsInitial]
  );

  /**
   * Stops will only be sortable (ability to change sequence) when:
   * allowDepotEdits === True AND stops.length > 1
   * OR
   * allowDepotEdits === False AND stops.length > 3
   */
  const isSortable = React.useMemo(
    () =>
      !!(
        stops &&
        ((stops.length > 3 && !allowDepotEdits) ||
          (stops.length > 1 && allowDepotEdits))
      ),

    [stops, allowDepotEdits]
  );

  // helper handlers
  const handleCleanup = React.useCallback(() => {
    setOrders(undefined);
    setStops(undefined);
  }, []);

  const handleCloseIsAddingOrders = React.useCallback(
    () => setIsAddingOrders(false),
    []
  );

  /**
   * Handler to get any given order's initial state, which is possible through the ordersInitial state that's only set
   * on the initial load and not updated along with the orders state
   */
  const handleGetInitialOrder = React.useCallback(
    (orderId: number | null | undefined): OrderDump | undefined => {
      if (orderId) {
        return ordersInitial?.find(({id}) => id === orderId);
      }

      return undefined;
    },
    [ordersInitial]
  );

  /**
   * Handler to get any given stop's initial state, which is possible through the stopsInitial state that's only set
   * on the initial load and not updated along with the stops state
   */
  const handleGetInitialStop = React.useCallback(
    (stopId: number | null | undefined): ITripEditTripStop | undefined => {
      if (stopId) {
        return stopsInitial?.find(({id}) => id === stopId);
      }

      return undefined;
    },
    [stopsInitial]
  );

  const handleChangeTimestampPriority = React.useCallback(
    (newPriority: TimestampPriorities) => {
      setTimestampPriority(newPriority);
    },
    []
  );

  /**
   * Function handler to get the relevant actual timestamps the current trip needs to use. Which will be needed when we
   * sync the actuals with the planned. Logic is used more than once, which is why we extracted it into its own function
   */
  const handleGetTripTimestampPriorities = React.useCallback(
    (stops: ITripEditTripStop[]) => {
      let hasMobileArrivalActuals = false;
      let hasMobileDepartureActuals = false;

      stops.forEach(({tripStopExecution}) => {
        if (tripStopExecution?.mobileArrivalTime) {
          hasMobileArrivalActuals = true;
        }
        if (tripStopExecution?.mobileDepartureTime) {
          hasMobileDepartureActuals = true;
        }
      });

      /** Use the mobile timestamps if the priority is mobile and the trip has both arrival and departure mobile actuals */
      const arrivalTimestamp: keyof TripStopExecution =
        hasMobileArrivalActuals &&
        hasMobileDepartureActuals &&
        timestampPriority === 'mobile'
          ? 'mobileArrivalTime'
          : 'gpsArrivalTime';

      const departureTimestamp: keyof TripStopExecution =
        hasMobileArrivalActuals &&
        hasMobileDepartureActuals &&
        timestampPriority === 'mobile'
          ? 'mobileDepartureTime'
          : 'gpsDepartureTime';

      return {arrivalTimestamp, departureTimestamp};
    },
    [timestampPriority]
  );

  /**
   * Function handler to get a singular stop's actuals and then setting those actuals to their relevant planned
   * counterparts. This is the core functionality of Sync-Planned-With-Actuals
   */
  const handleGetStopActuals = React.useCallback(
    ({
      stop,
      index,
      arrivalTimestamp,
      departureTimestamp,
    }: getStopActualsParams): ITripEditTripStop => {
      const actualArrivalTime = stop.tripStopExecution
        ? stop.tripStopExecution[arrivalTimestamp]
        : undefined;

      const actualDepartureTime = stop.tripStopExecution
        ? stop.tripStopExecution[departureTimestamp]
        : undefined;

      const actualTAT = getStopPlannedTAT(
        {tripStopExecution: stop.tripStopExecution},
        timestampPriority
      );

      if (index === 0 && actualDepartureTime) {
        return {
          ...stop,
          departureTime: actualDepartureTime as Date,
        };
      } else if (actualArrivalTime && actualDepartureTime && actualTAT) {
        return {
          ...stop,
          totalServiceTime:
            actualTAT === '-'
              ? stop.totalServiceTime
              : typeof actualTAT === 'string'
              ? parseInt(actualTAT)
              : actualTAT,
        };
      }
      return stop;
    },
    [timestampPriority]
  );

  /**
   * Handler to get the actuals of the given stops provided to the function. This handler is dependent on both the
   * handleGetTripTimestampPriorities and handleGetStopActuals handlers.
   */
  const handleGetStopsActuals = React.useCallback(
    (stops: ITripEditTripStop[] | undefined) => {
      if (!stops) return undefined;

      const {arrivalTimestamp, departureTimestamp} =
        handleGetTripTimestampPriorities(stops);

      return stops?.map((stop, index) =>
        handleGetStopActuals({
          stop,
          index,
          arrivalTimestamp,
          departureTimestamp,
        })
      );
    },
    [handleGetStopActuals, handleGetTripTimestampPriorities]
  );

  const handleOpenIsAddingOrders = React.useCallback(
    () => setIsAddingOrders(true),
    []
  );

  /** Reset the stops and orders states to their relevant initial states. */
  const handleResetStops = React.useCallback(() => {
    setStops(stopsInitial);
    setOrders(ordersInitial);
    setEditStopSequence(false);
  }, [ordersInitial, stopsInitial]);

  /**
   * Handler to set the initial values of the stops and orders, which is either on initial load or when the stops have
   * been updated and submitted. Additionally, we can pass options to this handler which is the same as the initial load
   * handler. With the syncActuals option we then load the stops state with its relevant actuals
   */
  const handleSetInitialState = React.useCallback(
    (
      stops: (WebMasterTripStopList | TripStop)[],
      options: loadStopOptions | undefined
    ) => {
      const {syncActuals} = options || ({} as loadStopOptions);

      setStops(syncActuals ? handleGetStopsActuals(stops) : stops);
      setStopsInitial(stops);

      const stopsOrders: OrderDump[] = [];

      stops?.forEach(({orders}) => {
        orders?.forEach((order) => {
          if (order.id && !stopsOrders.map(({id}) => id).includes(order.id)) {
            stopsOrders.push(order);
          }
        });
      });

      setOrders(stopsOrders);
      setOrdersInitial(stopsOrders);
    },
    [handleGetStopsActuals]
  );

  /** Handle the stops' onSortEnd, which is when they drag and drop the stops to change a stop's sequences */
  const handleSortStopsEnd = React.useCallback<SortEndHandler>((sort) => {
    const oldIndex = sort.oldIndex - 1;
    const newIndex = sort.newIndex - 1;

    setStops((prevStops) =>
      changePosition(prevStops, oldIndex, newIndex)?.map((stop, index) =>
        index + 1 !== stop.sequence ? {...stop, sequence: index + 1} : stop
      )
    );
  }, []);

  const handleToggleEditStopSequence = React.useCallback(
    () => setEditStopSequence((prevEditStopSequence) => !prevEditStopSequence),
    []
  );

  // load handlers
  const loadStops = React.useCallback(
    async (
      options?: loadStopOptions
    ): Promise<WebMasterTripStopListResponse | undefined> => {
      const {reload, syncActuals, disableErrorMessage, errorMessage} =
        options || ({} as loadStopOptions);

      /**
       * Only load the stops if the stops has not been loaded yet or if it is reloaded, and if there is a
       * masterTripId.
       */
      if ((!reload && stops !== undefined) || !masterTripId) {
        /** If the syncActuals option is true and the stops have already been loaded, set the stops to their actuals */
        if (syncActuals) {
          setStops((prevStops) => handleGetStopsActuals(prevStops));
        }
        return;
      }

      setLoadingStops(true);
      try {
        const response =
          await webMasterTripApi.apiWebMasterTripMasterTripIdStopsGet({
            masterTripId,
          });
        if (response) {
          handleSetInitialState(response.items ?? [], options);
          return response;
        }
      } catch (e) {
        if (!disableErrorMessage) {
          notify(
            'error',
            errorMessage ? errorMessage(e) : 'Failed to load stops'
          );
        }
      } finally {
        setLoadingStops(false);
      }
    },
    [handleGetStopsActuals, handleSetInitialState, masterTripId, notify, stops]
  );

  // update handlers
  const handleAddStop = React.useCallback<TOnInlineAdd>(
    async (changes) => {
      const values = changes[0] as ITripEditStopForm | undefined;
      if (values && Object.keys(values).length > 0) {
        let errorMessage: string | undefined;
        const {taskTemplateNodeType, siteName} = values;

        if (!siteName?.value) {
          errorMessage = 'Site name is required';
        }

        if (!taskTemplateNodeType?.label) {
          errorMessage = 'Task template node type is required';
        }

        if (errorMessage) {
          notify('info', errorMessage);
          /** Return out of the function if neither of the required fields were filled out */
          return;
        }

        const lastStop =
          stops && stops.length > 0 ? stops[stops.length - 1] : undefined;

        const lastSequence = lastStop?.sequence ?? 1;

        /** Map the added values to the stops interface. */
        const mappedStop = await mapToNewStop(values, lastSequence);

        if (!mappedStop || !mappedStop?.node?.id) {
          notify('info', 'Failed to map to stop. Try Again.');
          /**
           * Return out of the function if mapToNewStop function failed to return a mappedStop, even though this should
           * never get reached.
           */
          return;
        }

        /**
         * Add the new stop to the stop's state and update the lastStop's sequence to the lastSequence + 1.
         * Then we also need to sort them by the sequence to get the lastStop back to the end.
         */
        setStops((prevStops) =>
          (
            prevStops?.map((prevStop) =>
              prevStop.id === lastStop?.id
                ? {...prevStop, sequence: lastSequence + 1}
                : prevStop
            ) ?? []
          )
            .concat(mappedStop)
            .sort(sortStopsBySequence)
        );
      }
    },
    [notify, stops]
  );

  const handleDeleteOrder = React.useCallback(async (row: OrderDump) => {
    /** Remove order from stops state */
    setStops((prevStops) =>
      prevStops?.map((stop) => ({
        ...stop,
        orders: stop.orders?.filter((stopOrder) => stopOrder?.id !== row.id),
      }))
    );

    /** Remove order from orders state */
    setOrders((prevOrders) => prevOrders?.filter(({id}) => id !== row.id));
  }, []);

  const handleDeleteStop = React.useCallback(
    async (row: ITripEditTripStop) => {
      if (!stops) {
        /** Should never get reached */
        return;
      }

      if (stops.length <= 2) {
        /**
         * A trip can never have less than 2 stops, thus we return out of this function if they are trying to delete stops
         * and there are only 2 stops. Added <= as an insurance
         */
        notify('info', 'Trip can not have less than 2 stops');
        return;
      }

      /** Remove the deleted stop from the stops state, and update the rest of the stops' sequences if applicable */
      setStops((prevStops) =>
        prevStops
          ?.filter(({id}) => id !== row.id)
          .map((stop, index) =>
            stop.sequence !== index + 1 ? {...stop, sequence: index + 1} : stop
          )
      );
    },
    [notify, stops]
  );

  const handleEditStop = React.useCallback<TOnInlineEdit>(async (changes) => {
    changes?.forEach((change) => {
      const id = change.id;
      const newValues = change.newValues as ITripEditStopForm | undefined;

      if (id && newValues && Object.keys(newValues).length > 0) {
        const {
          taskTemplateNodeType,
          siteName,
          departureTime,
          totalServiceTime,
          totalServiceTimeChangeReason,
        } = newValues;

        setStops((prevStops) =>
          prevStops?.map((prevStop) => {
            if (parseInt(id) !== prevStop.id) {
              return prevStop;
            }

            const updatedValues: Partial<ITripEditTripStop> = {};

            if (taskTemplateNodeType?.label) {
              updatedValues.taskTemplateNodeType = taskTemplateNodeType.label;
            }

            if (siteName?.value && siteName.label) {
              updatedValues.node = {id: siteName.value, name: siteName.label};
            }

            if (departureTime) {
              updatedValues.departureTime = new Date(departureTime);
            }

            if (totalServiceTime) {
              updatedValues.totalServiceTime =
                typeof totalServiceTime === 'string'
                  ? parseFloat(totalServiceTime)
                  : totalServiceTime;
            }

            if (totalServiceTimeChangeReason) {
              updatedValues.totalServiceTimeChangeReason =
                totalServiceTimeChangeReason;
            }

            return {...prevStop, ...updatedValues};
          })
        );
      }
    });
  }, []);

  const {id: tripId} = masterTrip?.trip || ({} as Trip);
  const dialogRef = useRef<IVantageDialogRef>(null);
  const [currentError, setCurrentError] = React.useState<string | null>(null);

  const handleSubmitStops = React.useCallback(async () => {
    setUpdatingTrip(true);
    setEditStopSequence(false);
    try {
      if (tripId && stops && stops.length > 0) {
        const response = await tripApi.apiMasterTripEditPost({
          body: {
            id: tripId,
            stops: stops
              .filter((stop) => !!getStopEditPayload(stop))
              .map((stop) => getStopEditPayload(stop) as EditTripStop),
            overrideErrors: false,
          },
        });

        if (response) {
          if (
            response.constraintErrors &&
            response.constraintErrors.length > 0
          ) {
            setCurrentError(response.constraintErrors[0]);
            dialogRef.current?.openDialog();
          } else {
            updateMasterTrip(response);
            handleSetInitialState(response.trip.stops ?? [], undefined);
            notify('success', `Updated trip ${response.trip.tripNumber}`);
          }
        }
      }
    } catch (e) {
      notify('error', 'Failed to updated trip');
    } finally {
      setUpdatingTrip(false);
      await loadInitialData({reload: true});
    }
  }, [
    handleSetInitialState,
    loadInitialData,
    notify,
    setUpdatingTrip,
    stops,
    tripId,
    updateMasterTrip,
  ]);

  // Handler to resend the request with overrideErrors flag set to true

  const handleOverrideErrors = async () => {
    setUpdatingTrip(true);
    try {
      if (tripId && stops && stops.length > 0) {
        const response = await tripApi.apiMasterTripEditPost({
          body: {
            id: tripId,
            stops: stops
              .filter((stop) => !!getStopEditPayload(stop))
              .map((stop) => getStopEditPayload(stop) as EditTripStop),
            overrideErrors: true,
          },
        });

        if (response) {
          updateMasterTrip(response);
          handleSetInitialState(response.trip.stops ?? [], undefined);
          notify(
            'success',
            `Updated trip ${response.trip.tripNumber} and errors overridden`
          );
        }
      }
    } catch (e) {
      notify('error', 'Failed to override errors and update trip');
    } finally {
      dialogRef.current?.closeDialog();
      setCurrentError(null);
      setUpdatingTrip(false);
      await loadInitialData({reload: true});
    }
  };

  return {
    dialogRef,
    currentError,
    allowDepotEdits,
    editStopSequence,
    hasEditPermission,
    isAddingOrders,
    isDirty,
    isSortable,
    orders,
    ordersInitial,
    stops,
    stopsInitial,
    timestampPriority,
    // loading
    loadingStops,
    // state setters
    setStops,
    setOrders,
    // helper handlers
    cleanup: handleCleanup,
    closeIsAddingOrders: handleCloseIsAddingOrders,
    getInitialOrder: handleGetInitialOrder,
    getInitialStop: handleGetInitialStop,
    getStopActuals: handleGetStopActuals,
    getTripTimestampPriorities: handleGetTripTimestampPriorities,
    onChangeTimestampPriority: handleChangeTimestampPriority,
    onResetStops: handleResetStops,
    onSortStopsEnd: handleSortStopsEnd,
    openIsAddingOrders: handleOpenIsAddingOrders,
    toggleEditStopSequence: handleToggleEditStopSequence,
    // load handlers
    loadStops,
    // update handlers
    onAddStop: handleAddStop,
    onDeleteOrder: handleDeleteOrder,
    onDeleteStop: handleDeleteStop,
    onEditStop: handleEditStop,
    onSubmitStops: handleSubmitStops,
    onOverrideErrors: handleOverrideErrors,
  };
};

export type useTripEditResponse = ReturnType<typeof useTripEdit>;

export const useTripEditResponseInitial: useTripEditResponse = {
  dialogRef: {current: null},
  currentError: null,
  allowDepotEdits: false,
  editStopSequence: false,
  hasEditPermission: false,
  isAddingOrders: false,
  isDirty: false,
  isSortable: false,
  orders: undefined,
  ordersInitial: undefined,
  stops: undefined,
  stopsInitial: undefined,
  timestampPriority: 'gps',
  // loading
  loadingStops: false,
  // state setters
  setStops: () => null,
  setOrders: () => null,
  // helper handlers
  cleanup: () => null,
  closeIsAddingOrders: () => null,
  getInitialOrder: () => undefined,
  getInitialStop: () => undefined,
  getStopActuals: () => ({}),
  getTripTimestampPriorities: () => ({
    arrivalTimestamp: 'gpsArrivalTime',
    departureTimestamp: 'gpsDepartureTime',
  }),
  onChangeTimestampPriority: () => null,
  onSortStopsEnd: () => null,
  onResetStops: () => null,
  openIsAddingOrders: () => null,
  toggleEditStopSequence: () => null,
  // load handlers
  loadStops: async () => undefined,
  // update handlers
  onAddStop: () => null,
  onDeleteOrder: async () => {},
  onDeleteStop: async () => {},
  onEditStop: () => null,
  onSubmitStops: async () => {},
  onOverrideErrors: async () => {},
};
