import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';
import {tabletSize} from '../../contexts';

export const LOGIN_CONTENT_MAX_WIDTH = 480;

const AuthenticationStyles = (theme: Theme) =>
  createStyles({
    background: {
      [theme.breakpoints.down(tabletSize)]: {
        display: 'hidden',
      },
      [theme.breakpoints.up(tabletSize)]: {
        width: '100%',
      },
      zIndex: -1,
      position: 'absolute',
    },
    container: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'transparent',
      flexDirection: 'column',
      height: '100vh',
      minHeight: 0,
      overflow: 'hidden',
    },
    content: {
      [theme.breakpoints.down('md')]: {
        paddingLeft: 10,
        paddingRight: 10,
      },
      [theme.breakpoints.between('sm', tabletSize)]: {
        paddingLeft: 30,
        paddingRight: 30,
      },
      [theme.breakpoints.up(tabletSize)]: {
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        maxWidth: LOGIN_CONTENT_MAX_WIDTH,
      },
      zIndex: 2,
      background:
        theme.palette.mode === 'dark'
          ? 'rgba(0, 0, 0, 0.4)'
          : 'rgba(255, 255, 255, 0.4)',
      borderRadius: '16px',
      boxShadow:
        theme.palette.mode === 'dark'
          ? '0 4px 30px rgba(255, 255, 255, 0.1)'
          : '0 4px 30px rgba(0, 0, 0, 0.1)',
      backdropFilter: 'blur(5px)',
      webkitBackdropFilter: 'blur(5px)',
      border:
        theme.palette.mode === 'dark'
          ? '1px solid rgba(0, 0, 0, 0.3)'
          : '1px solid rgba(255, 255, 255, 0.3)',
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      overflowX: 'hidden',
      minHeight: 0,
      margin: theme.spacing(2),
    },
    logo: {
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: theme.spacing(0.5),
      paddingLeft: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(2),
      },
    },
    children: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    bottom: {
      justifySelf: 'flex-end',
      alignSelf: 'center',
      padding: theme.spacing(2),
    },
    bottomCookieText: {
      cursor: 'pointer',
    },
  });

export const useAuthenticationStyles = makeStyles(AuthenticationStyles, {
  name: 'AuthenticationStyles',
});
