import React from 'react';
import PropTypes from 'prop-types';
import TileOverlay from './TileOverlay';

const TileOverlayMultiple = ({tiles}) => {
  return (
    <React.Fragment>
      {tiles.map(({location}) => (
        <TileOverlay
          key={location.toString()}
          location={location}
          singleTile
          zoom={18}
        />
      ))}
    </React.Fragment>
  );
};

TileOverlayMultiple.propTypes = {
  tiles: PropTypes.arrayOf(
    PropTypes.shape({
      location: PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
      }),
    })
  ).isRequired,
};

export default TileOverlayMultiple;
