import React from 'react';
import clsx from 'clsx';
import {TextField, AutocompleteRenderInputParams} from '@mui/material';
import {Loader} from '../loader';
import {useInputStyles} from '../formik/Input.style';
import {AutocompleteContext} from './Autocomplete';
import {useAutocompleteStyles} from './Autocomplete.style';

export const AutocompleteField: React.FC<AutocompleteRenderInputParams> = ({
  ...params
}) => {
  /** Classes specific to autocomplete fields */
  const classes = useAutocompleteStyles();
  /** Generic inputClasses used by all input fields */
  const inputClasses = useInputStyles();
  const {
    inputRef,
    label,
    loading,
    placeholder,
    error,
    helperText,
    classes: propClasses,
  } = React.useContext(AutocompleteContext);
  return (
    <TextField
      {...params}
      placeholder={placeholder}
      error={!!error}
      className={
        propClasses?.input ??
        clsx(inputClasses.input, {
          [inputClasses.inputError]: !!error,
        })
      }
      helperText={helperText ?? error}
      label={label}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <React.Fragment>
            {loading ? (
              <Loader size={20} classes={{loaderContainer: classes.loader}} />
            ) : null}
            {params.InputProps.endAdornment}
          </React.Fragment>
        ),
      }}
      inputRef={inputRef}
    />
  );
};
