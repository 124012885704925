import React from 'react';
import {DataTypeProvider} from '@devexpress/dx-react-grid';
import {useTemplateTableActions} from '../../providers';
import {SaveAlt} from '@mui/icons-material';
import {TableActionButtonBase} from './TableActionButtonBase';

export const TableActionButtonDownload: React.FC<
  DataTypeProvider.ValueFormatterProps
> = ({row}) => {
  const {onDownloadItem} = useTemplateTableActions();

  const handleDownload = React.useCallback(() => {
    if (onDownloadItem) {
      onDownloadItem(row);
    }
  }, [row, onDownloadItem]);

  if (!onDownloadItem) {
    return null;
  }

  return (
    <TableActionButtonBase
      data-cy="TemplateTableActionsDownloadItem"
      onClick={handleDownload}
    >
      <SaveAlt />
    </TableActionButtonBase>
  );
};
