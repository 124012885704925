import React from 'react';
import {WebPlanningBoardVehicle} from '@onroadvantage/onroadvantage-api';
import {List, ListItem, ListItemText, Stack} from '@mui/material';
import {List as ListIcon} from '@mui/icons-material';
import {Loader} from '../../../loader';
import {TemplateTable, TemplateTableConfig} from '../../../../factory/template';
import {VantageDialogButton} from '../../../dialog';
import {usePlanningBoardContext} from '../../planningBoardContext';

export const PlanningBoardOverviewVehicleList: React.FC = () => {
  const {vehicles, loadingVehicles, getContract} = usePlanningBoardContext();
  const config = React.useMemo<TemplateTableConfig<WebPlanningBoardVehicle>>(
    () => ({
      columns: [
        {
          name: 'registrationNumber',
          label: 'Reg Number',
          type: 'string',
        },
        {
          name: 'fleetNumber',
          label: 'Fleet Number',
          type: 'string',
        },
        {
          name: 'contract',
          label: 'Contract',
          type: 'string',
          getValue: ({contractId}) =>
            `${getContract(contractId)?.code} | ${
              getContract(contractId)?.name
            }`,
        },
        {
          name: 'capacity',
          label: 'Capacity',
          type: 'string',
          getValue: ({type}) =>
            type?.capacityDimensions && type?.capacityDimensions.length > 0
              ? `${type.capacityDimensions[0].capacity}${type.capacityDimensions[0].unitOfMeasure}`
              : '-',
        },
        {
          name: 'type',
          label: 'Type',
          type: 'string',
          getValue: ({type}) => type?.name,
        },
        {
          name: 'skills',
          label: 'Skills',
          type: 'string',
          getValue: ({type}) => (
            <>
              <VantageDialogButton
                title={<ListIcon />}
                color="inherit"
                declineTitle="Close"
                dialogTitle="Order Numbers"
                maxWidth="sm"
                declineProps={{sx: {width: '100%'}}}
                fullWidth
                iconButton
                disableAccept
              >
                <List disablePadding>
                  {type?.skills?.map(({id, name}) => (
                    <ListItem key={id}>
                      <ListItemText primary={name} />
                    </ListItem>
                  ))}
                </List>
              </VantageDialogButton>
            </>
          ),
        },
      ],
      disableToolbar: true,
      disablePagination: true,
      identifier: `PLANNING_BOARD_VEHICLE`,
    }),
    [getContract]
  );

  if (loadingVehicles) return <Loader text="Fetching vehicles..." />;

  return (
    <Stack flex={1}>
      <TemplateTable
        config={config}
        currentPage={1}
        list={vehicles}
        elevation={0}
        scrollable
      />
    </Stack>
  );
};
