import clone from 'rfdc';

/**
 * changePosition -> If you want to move an item in an array from one index to another.
 * Function requires an array (which is nullable), a from (which is the index from which you want to move the item) and
 * a to (which is the index where you want to move the item to).
 */
export const changePosition = <T>(
  arr: T[] | null | undefined,
  from: number,
  to: number
) => {
  if (!arr) return undefined;

  /** Firstly clone the array so that we can alter the values of the array, without affecting the original array */
  const arrCopy = clone()(arr);

  /** Remove the element that is required to move, with the use of `.splice()` */
  const element = arrCopy.splice(from, 1)[0];

  /** Again with the use of the `.splice()` method, we add the element back into the array at its new position */
  arrCopy.splice(to, 0, element);

  /** Finally we return the altered (clone()) array back */
  return arrCopy;
};
