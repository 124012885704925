import React from 'react';
import {CompartmentTruck} from '../../../comptartment/CompartmentTruck';
import {VehicleTypeContext} from '../../VehicleTypeContext';
import {useFormikContext} from 'formik';
import {IVehicleTypeCapacityForm} from '../VehicleTypeCapacityForm';
import {
  VehicleCompartmentWithCapacity,
  VehicleTypeCapacityCompartmentTruckCompartment,
} from './VehicleTypeCapacityCompartmentTruckCompartment';
import {VehicleTypeCapacityCompartmentTruckActions} from './VehicleTypeCapacityCompartmentTruckActions';
import {IVantageDialogRef} from '../../../dialog';
import {useVehicleTypeCapacityCompartmentTruckStyles} from './VehicleTypeCapacityCompartmentTruck.style';

export const VehicleTypeCapacityCompartmentTruck: React.FC = () => {
  const classes = useVehicleTypeCapacityCompartmentTruckStyles();
  const {vehicleType} = React.useContext(VehicleTypeContext);
  const {values} = useFormikContext<IVehicleTypeCapacityForm>();
  const [fieldFocus, setFieldFocus] = React.useState<number | undefined>();
  const compartmentDialogRef = React.useRef<IVantageDialogRef>(null);

  const compartments: VehicleCompartmentWithCapacity[] = React.useMemo(
    () =>
      vehicleType?.compartments
        ?.map((c) => {
          const getCapacity = values.capacityCompartments.find(
            ({compartmentId}) => compartmentId === c.id
          )?.capacity;
          return getCapacity ? {...c, capacity: getCapacity} : c;
        })
        ?.sort((a, b) =>
          a.position > b.position ? 1 : a.position < b.position ? -1 : 0
        ) ?? [],
    [values.capacityCompartments, vehicleType?.compartments]
  );

  const getCompartmentWithCapacity = (
    compartmentId: number | null | undefined
  ) => compartments.find((c) => c.id === compartmentId);

  return (
    <CompartmentTruck
      paperClass={classes.root}
      compartments={compartments}
      compartmentDialogRef={compartmentDialogRef}
      getVerticalCompartmentContent={({id}) => {
        const compartmentWithCapacity = getCompartmentWithCapacity(id);
        return (
          <VehicleTypeCapacityCompartmentTruckCompartment
            compartmentWithCapacity={compartmentWithCapacity}
            fieldFocus={fieldFocus}
          />
        );
      }}
      getCompartmentContent={({id}) => {
        const compartmentWithCapacity = getCompartmentWithCapacity(id);
        return (
          <VehicleTypeCapacityCompartmentTruckActions
            compartmentWithCapacity={compartmentWithCapacity}
            compartmentDialogRef={compartmentDialogRef}
            setFieldFocus={setFieldFocus}
          />
        );
      }}
    />
  );
};
