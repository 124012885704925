import React from 'react';
import {VehicleContext} from '../VehicleContext';
import {Listing, Vehicle} from '@onroadvantage/onroadvantage-api';
import * as Yup from 'yup';
import {FormikTextField} from '../../formik';
import {FormikAutocompleteWithListing} from '../../formik';
import {
  TemplateForm,
  listingSchema,
  multipleListingSchema,
} from '../../../factory/template';

export interface IVehicleForm {
  registrationNumber: string;
  description: string;
  fleetNumber?: string;
  vinNumber?: string;
  contract?: Listing;
  vehicleGroups?: Listing[];
  vehicleType?: Listing;
  voicekitNumber?: string;
}

const schema: Yup.SchemaOf<IVehicleForm> = Yup.object({
  registrationNumber: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  contract: listingSchema.required('Required'),
  vehicleGroups: multipleListingSchema,
  vehicleType: listingSchema.required('Required'),
  fleetNumber: Yup.string(),
  vinNumber: Yup.string(),
  voicekitNumber: Yup.string(),
});

const getInitialValues = (
  vehicle?: Vehicle | null
): IVehicleForm | undefined => {
  if (vehicle) {
    return {
      contract: {label: vehicle.contract?.name, value: vehicle.contract?.id},
      vehicleType: {label: vehicle.type?.name, value: vehicle.vehicleTypeId},
      vehicleGroups: vehicle.vehicleGroups?.map((group) => ({
        label: group.name,
        value: group.id,
      })),
      description: vehicle.description ?? '',
      registrationNumber: vehicle.registrationNumber ?? '',
      fleetNumber: vehicle.fleetNumber ?? '',
      vinNumber: vehicle.vinNumber ?? '',
      voicekitNumber: vehicle.voicekitNumber ?? '',
    };
  }
  return undefined;
};

export const VehicleForm: React.FC<{isAdd?: boolean}> = ({isAdd}) => {
  const {detailsRef, loading, submitting, vehicle, onDetailsFormSubmit} =
    React.useContext(VehicleContext);
  const [initialValues, setInitialValues] = React.useState<IVehicleForm>();
  const disableAll = React.useMemo(
    () =>
      !isAdd &&
      vehicle?.telematicsConfig?.providerName?.toLowerCase() !== 'vantage',
    [isAdd, vehicle?.telematicsConfig?.providerName]
  );
  const disableWhenTypeMobile = React.useMemo(
    () => !isAdd && vehicle?.type?.name?.toLowerCase() === 'mobile',
    [isAdd, vehicle?.type?.name]
  );

  React.useEffect(() => {
    const values = getInitialValues(vehicle);

    if (values) {
      setInitialValues(values);
    } else if (isAdd) {
      setInitialValues({
        description: '',
        registrationNumber: '',
      });
    }
  }, [isAdd, vehicle, setInitialValues]);

  return (
    <TemplateForm<IVehicleForm>
      initialValues={initialValues}
      onSubmit={onDetailsFormSubmit}
      permission={{
        name: `${isAdd ? 'Add' : 'Edit'} Vehicle`,
        type: isAdd ? 'Add' : 'Edit',
      }}
      submitting={submitting}
      innerRef={detailsRef}
      validationSchema={schema}
    >
      <FormikTextField
        disabled={disableAll || disableWhenTypeMobile}
        name="registrationNumber"
        placeholder="Enter the vehicle Registration Number"
        label="Vehicle Registration Number"
        fullWidth
      />
      <FormikTextField
        disabled={disableAll || disableWhenTypeMobile}
        name="description"
        placeholder="Enter the vehicle description"
        label="Vehicle Description"
        fullWidth
      />
      <FormikTextField
        disabled={disableAll}
        name="fleetNumber"
        placeholder="Enter the Fleet Number"
        label="Fleet Number"
        fullWidth
      />
      <FormikTextField
        disabled={disableAll}
        name="vinNumber"
        placeholder="Enter the Vin Number"
        label="Vin Number"
        fullWidth
      />
      <FormikTextField
        disabled={disableAll}
        name="voicekitNumber"
        placeholder="Enter the Voice Kit Number"
        label="Voice Kit Number"
        fullWidth
      />
      <FormikAutocompleteWithListing
        id="contract"
        label="Contract"
        name="contract"
        placeholder="Enter and select a value"
        model="Contract"
        loading={loading}
      />
      <FormikAutocompleteWithListing
        disabled={disableAll}
        id="vehicleGroups"
        label="Vehicle Groups"
        name="vehicleGroups"
        placeholder="Enter and select a value"
        model="VehicleGroup"
        loading={loading}
        multiple
      />
      <FormikAutocompleteWithListing
        disabled={disableWhenTypeMobile}
        id="vehicleType"
        label="Vehicle Type"
        name="vehicleType"
        model="VehicleType"
        placeholder="Enter and select a value"
        loading={loading}
      />
    </TemplateForm>
  );
};
