import React from 'react';
import {MasterRouteLegContext} from './MasterRouteLegContext';
import {useParams} from 'react-router-dom';

interface IParams {
  masterRouteLegId?: string;
}

export const MasterRouteLegIdHandler: React.FC = () => {
  const {setMasterRouteLegId} = React.useContext(MasterRouteLegContext);
  const {masterRouteLegId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = masterRouteLegId ? parseInt(masterRouteLegId) : undefined;
    setMasterRouteLegId(parsedId);

    return () => setMasterRouteLegId(undefined);
  }, [masterRouteLegId, setMasterRouteLegId]);

  return null;
};
