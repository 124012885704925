import React from 'react';
import * as Yup from 'yup';
import {Grid, Stack, Typography} from '@mui/material';
import {TripDebriefConfigCard} from './tripDebriefConfigCard';
import {TemplateForm} from '../../factory/template';
import {useTripDebriefConfigContext} from './tripDebriefConfigContext';
import {Loader} from '../loader';

export interface ITripDebriefConfigVisibleCards {
  activityCard: boolean;
  additionalCommentsCard: boolean;
  debrieferNameCard: boolean;
  detailCard: boolean;
  driverCard: boolean;
  eventCard: boolean;
  kmCard: boolean;
  mobileCard: boolean;
  notificationCard: boolean;
  orderCard: boolean;
  taskCard: boolean;
  timeCard: boolean;
  tripStopCard: boolean;
  vehicleCard: boolean;
}

const schema: Yup.SchemaOf<ITripDebriefConfigVisibleCards> = Yup.object({
  activityCard: Yup.boolean().required('Required'),
  additionalCommentsCard: Yup.boolean().required('Required'),
  debrieferNameCard: Yup.boolean().required('Required'),
  detailCard: Yup.boolean().required('Required'),
  driverCard: Yup.boolean().required('Required'),
  eventCard: Yup.boolean().required('Required'),
  kmCard: Yup.boolean().required('Required'),
  mobileCard: Yup.boolean().required('Required'),
  notificationCard: Yup.boolean().required('Required'),
  orderCard: Yup.boolean().required('Required'),
  taskCard: Yup.boolean().required('Required'),
  timeCard: Yup.boolean().required('Required'),
  tripStopCard: Yup.boolean().required('Required'),
  vehicleCard: Yup.boolean().required('Required'),
});

export const TripDebriefConfigVisibleCards: React.FC = () => {
  const {
    loadingTripDebriefConfig,
    masterTripDebriefConfig,
    submitting,
    onSubmitVisibleCards,
  } = useTripDebriefConfigContext();

  const initialValues = React.useMemo<ITripDebriefConfigVisibleCards>(
    () => ({
      activityCard: !!masterTripDebriefConfig?.visibleCards?.activityCard,
      additionalCommentsCard:
        !!masterTripDebriefConfig?.visibleCards?.additionalCommentsCard,
      debrieferNameCard:
        !!masterTripDebriefConfig?.visibleCards?.debrieferNameCard,
      detailCard: !!masterTripDebriefConfig?.visibleCards?.detailCard,
      driverCard: !!masterTripDebriefConfig?.visibleCards?.driverCard,
      eventCard: !!masterTripDebriefConfig?.visibleCards?.eventCard,
      kmCard: !!masterTripDebriefConfig?.visibleCards?.kmCard,
      mobileCard: !!masterTripDebriefConfig?.visibleCards?.mobileCard,
      notificationCard:
        !!masterTripDebriefConfig?.visibleCards?.notificationCard,
      orderCard: !!masterTripDebriefConfig?.visibleCards?.orderCard,
      taskCard: !!masterTripDebriefConfig?.visibleCards?.taskCard,
      timeCard: !!masterTripDebriefConfig?.visibleCards?.timeCard,
      tripStopCard: !!masterTripDebriefConfig?.visibleCards?.tripStopCard,
      vehicleCard: !!masterTripDebriefConfig?.visibleCards?.vehicleCard,
    }),
    [masterTripDebriefConfig?.visibleCards]
  );

  if (loadingTripDebriefConfig) {
    return <Loader text="Loading Trip Debrief Config..." />;
  }

  if (!initialValues) {
    return (
      <Typography variant="h3" textAlign="center">
        No Initial Values
      </Typography>
    );
  }

  return (
    <Stack p={2}>
      <TemplateForm
        onSubmit={onSubmitVisibleCards}
        initialValues={initialValues}
        validationSchema={schema}
        submitting={submitting}
      >
        <Grid pb={2} spacing={3} container>
          <TripDebriefConfigCard
            name="detailCard"
            title="Details"
            toggle="visibility"
          />
          <TripDebriefConfigCard
            name="timeCard"
            title="Times"
            toggle="visibility"
          />
          <TripDebriefConfigCard
            name="kmCard"
            title="Kilometers"
            toggle="visibility"
          />
          <TripDebriefConfigCard
            name="notificationCard"
            title="Notifications"
            toggle="visibility"
          />
          <TripDebriefConfigCard
            name="activityCard"
            title="Activity"
            toggle="visibility"
          />
          <TripDebriefConfigCard
            name="driverCard"
            title="Driver"
            toggle="visibility"
          />
          <TripDebriefConfigCard
            name="orderCard"
            title="Orders"
            toggle="visibility"
          />
          <TripDebriefConfigCard
            name="mobileCard"
            title="Mobile"
            toggle="visibility"
          />
          <TripDebriefConfigCard
            name="taskCard"
            title="Tasks"
            toggle="visibility"
          />
          <TripDebriefConfigCard
            name="eventCard"
            title="Events"
            toggle="visibility"
          />
          <TripDebriefConfigCard
            name="vehicleCard"
            title="Vehicle"
            toggle="visibility"
          />
          <TripDebriefConfigCard
            name="additionalCommentsCard"
            title="Additional Debriefing Comments"
            toggle="visibility"
          />
          <TripDebriefConfigCard
            name="debrieferNameCard"
            title="Debrief Name"
            toggle="visibility"
          />
          <TripDebriefConfigCard
            name="tripStopCard"
            title="Trip Stops"
            toggle="visibility"
          />
        </Grid>
      </TemplateForm>
    </Stack>
  );
};
