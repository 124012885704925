import * as React from 'react';
import * as PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import withStyles from '@mui/styles/withStyles';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

const styles = (theme) => ({
  Cell: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  progressBar: {
    backgroundColor: 'red',
    float: 'left',
    height: theme.spacing(2),
  },
});

const TextCellBase = class extends React.Component {
  state = {
    errorMessage: undefined,
  };

  handleTextChange = (value, cb) => {
    let {errorMessage} = this.state;
    if (value !== '') {
      if (
        !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@$!%*?&])[A-Za-z\d#$@$!%*?&]{8,}/.test(
          value
        )
      ) {
        errorMessage = 'Password not secure';
      } else {
        errorMessage = undefined;
      }
    } else {
      errorMessage = 'Required';
    }
    this.setState({errorMessage});

    if (!errorMessage) {
      this.props.column.setError(false);
      cb(value);
    } else {
      this.props.column.setError(true);
    }
  };

  render() {
    const {value, classes, style, name, disabled, onValueChange} = this.props;

    return (
      <TableCell
        className={classes.Cell}
        style={[style, {display: 'flex', flexDirection: 'column'}]}
      >
        <Tooltip title="Password needs at least one uppercase, one lowercase, one number, one special character and 8 characters total">
          <TextField
            id="passwordEdit"
            defaultValue={value}
            disabled={disabled}
            name={name}
            label="Password"
            onChange={(e) =>
              this.handleTextChange(e.target.value, onValueChange)
            }
            style={{width: '100%'}}
            error={this.state.errorMessage !== undefined}
          />
        </Tooltip>
        <br />
        {this.state.errorMessage && (
          <span style={{color: 'red'}}>{this.state.errorMessage}</span>
        )}
      </TableCell>
    );
  }
};

TextCellBase.propTypes = {
  value: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};
TextCellBase.defaultProps = {
  style: {},
};

// eslint-disable-next-line import/prefer-default-export
export const PasswordCell = withStyles(styles, {name: 'TextCell'})(
  TextCellBase
);
