import {Theme} from '@mui/material';
import {makeStyles, createStyles} from '@mui/styles';

const TripDebriefErrorOccurred = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      padding: theme.spacing(3),
    },
    card: {backgroundColor: '#fcd2d2'},
    overrideButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      textAlign: 'center',
      padding: theme.spacing(1),
    },
  });

export const useTripDebriefErrorOccurred = makeStyles(
  TripDebriefErrorOccurred,
  {
    name: 'TripDebriefErrorOccurred',
  }
);
