import {decorate, observable, action} from 'mobx';
import themeList from './mapThemes/themeList';
import {MapSelectionItem} from '../components/broadcast/adhocGeoBroadcastWizard/AdhocMapSelectStep';
import {MasterRouteLeg} from '@onroadvantage/onroadvantage-api';

export interface MasterRouteLegRoutable extends MasterRouteLeg {
  geoJson?: any; // geojson
  polylines?: any; // encoded skwiggles
  routePoints?: any; // starting + waypoints + ending
}

class MapDisplay {
  private siteDetailsFormDetails: any;
  private openAddress: any;
  private openEvent: any;
  private openStop: any;
  private openActualStop: any;
  private openIsochrone: any;
  private geoSelectionItems: MapSelectionItem[] | null;
  private baseLayer: any;
  private mapTheme: any;

  constructor() {
    const defaultTheme = 'Vantage Streets';

    this.siteDetailsFormDetails = null;
    this.openAddress = null;
    this.openEvent = null;
    this.openStop = null;
    this.openActualStop = null;
    this.openIsochrone = null;
    this.geoSelectionItems = null;
    this.baseLayer = defaultTheme;
    this.mapTheme = this.getThemeFile(defaultTheme);
  }

  clearMap = () => {
    this.openAddress = null;
    this.openEvent = null;
    this.openActualStop = null;
    this.openIsochrone = null;
    this.geoSelectionItems = null;
    this.siteDetailsFormDetails = null;
    this.baseLayer = 'Vantage Streets';
  };

  clearOpenEvent = () => {
    this.openEvent = null;
  };

  getThemeFile = (themeName: string) => {
    const cleanedFileName = themeName.replace(' ', '');
    return themeList[cleanedFileName];
  };

  setOpenEvent = (event: any) => {
    this.openEvent = event;
  };

  setOpenAddress = (address: any) => {
    this.openAddress = address;
  };

  setSiteDetailsFormDetails = (details: any) => {
    // TODO add correct type
    this.siteDetailsFormDetails = details;
  };

  setOpenIsochrone = (isochrone: any) => {
    this.openIsochrone = isochrone;
  };

  setOpenStop = (stop: any) => {
    this.openStop = stop;
  };

  setOpenActualStop = (stop: any) => {
    this.openActualStop = stop;
  };

  setGeoSelectionItems = (items: MapSelectionItem[]) => {
    this.geoSelectionItems = items;
  };

  // The following sets and clears the chosen selected map / baseLayer
  setBaseLayer = (baseLayer: string) => {
    this.mapTheme = this.getThemeFile(baseLayer);
    this.baseLayer = baseLayer;
  };

  clearBaseLayer = () => {
    this.baseLayer = 'HERE Hybrid';
  };
}

export default decorate(MapDisplay, {
  // @ts-expect-error upgrade
  siteDetailsFormDetails: observable,
  openAddress: observable,
  openEvent: observable,
  openStop: observable,
  openActualStop: observable,
  openIsochrone: observable,
  baseLayer: observable,
  geoSelectionItems: observable,
  mapThemeFile: observable,
  clearMap: action,
  clearOpenEvent: action,
  setOpenAddress: action,
  setSiteDetailsFormDetails: action,
  setOpenEvent: action,
  setOpenActualStop: action,
  setOpenStop: action,
  setGeoSelectionItems: action,
  setBaseLayer: action,
  setOpenIsochrone: action,
  clearBaseLayer: action,
});
