import React from 'react';
import {useHistory} from 'react-router-dom';
import {MasterTripDebriefResponse} from '@onroadvantage/onroadvantage-api';
import {useTripContext} from '../tripContext';
import {
  ITemplateTabControllerTab,
  TemplateTabs,
} from '../../../factory/template';
import {TripEditOrderList} from './tripEditOrderList';
import {TripEditStopList} from './tripEditStopList';
import {useTripEditContext} from './tripEditContext';
import {TripEditActions} from './TripEditActions';
import {TripEditSyncList} from './tripEditSyncList';

export const TripEdit: React.FC = () => {
  const {goBack} = useHistory();
  const {masterTrip, masterTripId, loadingMasterTrip, updatingTrip} =
    useTripContext();
  const {loadingStops} = useTripEditContext();
  const {approvedForBilling} =
    masterTrip?.debrief || ({} as MasterTripDebriefResponse);

  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {title: 'Edit Stops', element: <TripEditStopList />},
    {title: 'Orders', element: <TripEditOrderList />},
    {title: 'Sync', element: <TripEditSyncList />},
  ]);

  const path = `/triplist/${masterTripId}/stops/edit`;

  React.useEffect(() => {
    if (approvedForBilling) {
      /**
       * If trip has been approved for billing. Redirect the user back, as they can't edit trips that has been approved
       * for billing.
       */
      goBack();
    }
  }, [approvedForBilling, goBack]);

  return (
    <TemplateTabs
      tabs={tabs}
      path={path}
      loading={loadingStops || loadingMasterTrip || updatingTrip}
      endActions={<TripEditActions />}
      loaderText={updatingTrip ? 'Updating Trip...' : undefined}
    />
  );
};
