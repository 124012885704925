import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

const TripGanttStyles = (_theme: Theme) =>
  createStyles({
    eventFilter: {backgroundColor: '#5a6969', color: '#fff', top: 0},
    order: {backgroundColor: '#007069', color: '#fff'},
    knownUnscheduled: {backgroundColor: '#e29500', color: '#fff'},
    noTrackingBackground: {backgroundColor: '#d12121', opacity: 0.6},
    forcedWait: {backgroundColor: '#029292', color: '#fff'},
    vehicleAvailability: {backgroundColor: '#007069', opacity: 0.4},
    unknownUnscheduled: {backgroundColor: '#d12121', color: '#fff'},
    scheduled: {backgroundColor: '#007000', color: '#fff'},
    driving: {backgroundColor: '#334f8a', color: '#fff'},
    trip: {backgroundColor: '#9370DBFF', color: '#fff'},
  });

export const useTripGanttStyles = makeStyles(TripGanttStyles, {
  name: 'TripGanttStyles',
});

export type TripGanttStylesType = ReturnType<typeof useTripGanttStyles>;
