import React from 'react';
import 'typeface-roboto';
import Lottie from 'react-lottie';
import _ from 'lodash';
import PlayCircleFilled from '@mui/icons-material/PlayCircleFilled';
import PauseCircleFilled from '@mui/icons-material/PauseCircleFilled';
import {getStyle} from '../helpers';
import vantageLoading from '../../../../img/json/ex-veiwMetricsLogoOnly-bm.json';

const componentStyle = (brandColors) => ({
  default: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100vw / 1920 * 160)',
      width: '100vw',
      backgroundColor: brandColors.brandBackground,
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
    },
    content: {
      height: 'calc(100vw / 1920 * 160)',
      // width: 'calc(100vw / 1920 * 1717)',
      marginTop: 'calc(100vw / 1920 * -20)',
    },
    head: {
      backgroundColor: brandColors.brandPrimaryLighter,
      borderRadius: '0 0 calc(100vw / 1920 * 24) 0',
      display: 'flex',
      height: 'calc(100vw / 1920 * 100)',
      width: 'calc(100vw / 1920 * 203)',
      justifyContent: 'center',
      alignItems: 'center',
    },
    title: {
      fontStyle: 'normal',
      fontWeight: 900,
      textTransform: 'uppercase',
      fontSize: 'calc(100vw / 1920 * 48)',
      color: brandColors.brandPrimaryText,
      fontFamily: 'Roboto',
    },
    side: {
      backgroundColor: brandColors.brandPrimary,
      display: 'flex',
      flex: 1,
      height: 'calc(100vw / 1920 * 80)',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    controls: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      paddingRight: 'calc(100vw / 1920 * 32)',
    },
    legend: {
      display: 'flex',
      flexDirection: 'row',
      // width: 'calc(100vw / 1920 * 1717)',
    },
    legendItem: {
      display: 'flex',
      flexDirection: 'row',
      height: 'calc(100vw / 1920 * 40)',
      margin:
        'calc(100vw / 1920 * 20) 0 calc(100vw / 1920 * 20) calc(100vw / 1920 * 32)',
      alignItems: 'center',
    },
    legendItemDot: {
      borderRadius: 'calc(100vw / 1920 * 40)',
      height: 'calc(100vw / 1920 * 30)',
      width: 'calc(100vw / 1920 * 30)',
    },
    legendItemTitle: {
      marginLeft: 'calc(100vw / 1920 * 12)',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: 'calc(100vw / 1920 * 14)',
      textTransform: 'uppercase',
    },
    control: {
      display: 'flex',
      flexDirection: 'row',
      borderRadius: 'calc(100vw / 1920 * 8)',
      backgroundColor: brandColors.brandBackground,
      height: 'calc(100vw / 1920 * 40)',
      width: 'calc(100vw / 1920 * 197)',
      marginRight: 'calc(100vw / 1920 * 32)',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderColor: 'transparent',
    },
    controlIcon: {
      fontSize: 'calc(100vw / 1920 * 24)',
    },
    controlText: {
      color: brandColors.brandPrimary,
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: 'calc(100vw / 1920 * 18)',
      textTransform: 'uppercase',
      marginLeft: 'calc(100vw / 1920 * 12)',
    },
    legendItemPlanned: {
      background: brandColors.brandQuaternaryHighLight,
      marginLeft: 'calc(100vw / 1920 * 277)',
    },
    legendItemActual: {
      background: `linear-gradient(135deg, ${brandColors.brandPrimaryHighlight} 50.26%, ${brandColors.brandTertiaryHighlight} 50.26%)`,
    },
    legendItemPlannedStandingTime: {
      marginLeft: 'calc(100vw / 1920 * 308)',
      background: brandColors.brandPrimaryHighlight,
    },
    legendItemKnownUnplannedStandingTime: {
      background: brandColors.brandSecondaryHighlight,
    },
    legendItemUnknownUnplannedStandingTime: {
      background: brandColors.brandTertiaryHighlight,
    },
    logo: {
      display: 'none',
    },
  },
  unitrans: {
    content: {
      width: 'auto',
    },
    controls: {
      flexDirection: 'column',
      justifyContent: 'auto',
      alignItems: 'flex-end',
    },
    head: {
      width: 'auto',
      whiteSpace: 'nowrap',
    },
    side: {
      width: 'auto',
    },
    legend: {
      alignItems: 'flex-end',
      width: 'auto',
    },
    legendItemTitle: {
      marginLeft: 'calc(100vw / 1920 * 7)',
    },
    logo: {
      display: 'flex',
      marginLeft: 'calc(100vw / 1920 * 30)',
      height: 'calc(100vw / 1920 * 80)',
      width: 'calc(100vw / 1920 * 80)',
    },
    title: {
      paddingLeft: 'calc(100vw / 1920 * 40)',
      paddingRight: 'calc(100vw / 1920 * 31)',
    },
    legendItemPlanned: {
      background: brandColors.brandQuaternaryHighLight,
    },
    legendItemActual: {
      background: `linear-gradient(135deg, ${brandColors.brandPrimary} 50.26%, ${brandColors.brandTertiaryHighlight} 50.26%)`,
    },
    legendItemPlannedStandingTime: {
      background: brandColors.brandPrimaryHighlight,
      marginLeft: 'calc(100vw / 1920 * 306)',
    },
    control: {
      width: 'auto',
    },
  },
});

const Legend = ({children}) => {
  const styles = getStyle(componentStyle);
  return <div style={styles.legend}>{children}</div>;
};

const LegendItem = ({title, background = 'transparent', style}) => {
  const styles = getStyle(componentStyle);
  return (
    <div style={{...styles.legendItem, ...style}}>
      <div style={{...styles.legendItemDot, background}} />
      <div style={styles.legendItemTitle}>{title}</div>
    </div>
  );
};

const UtilisationHeader = ({isPaused, onStart, onStop, title}) => {
  const styles = getStyle(componentStyle);

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div style={styles.head}>
          <div style={styles.logo}>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: vantageLoading,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              isClickToPauseDisabled
            />
          </div>
          <div style={styles.title}>{title}</div>
        </div>
        <div style={styles.side}>
          <button
            type="button"
            style={styles.control}
            onClick={isPaused ? onStart : onStop}
          >
            {isPaused ? (
              <PlayCircleFilled color="primary" style={styles.controlIcon} />
            ) : (
              <PauseCircleFilled color="primary" style={styles.controlIcon} />
            )}
            <div style={styles.controlText}>
              {isPaused ? 'Play' : 'Pause'} Screen
            </div>
          </button>
        </div>
      </div>
      <div style={styles.content}>
        <div style={styles.controls}>
          <Legend>
            <LegendItem
              background={styles.legendItemPlanned.background}
              title="Planned"
              style={_.omit(styles.legendItemPlanned, 'background')}
            />
            <LegendItem
              background={styles.legendItemActual.background}
              title="Actual"
            />
            <LegendItem
              background={styles.legendItemPlannedStandingTime.background}
              title="Planned Standing Time"
              style={_.omit(styles.legendItemPlannedStandingTime, 'background')}
            />
            <LegendItem
              background={
                styles.legendItemKnownUnplannedStandingTime.background
              }
              title="Known Unplanned Standing Time"
            />
            <LegendItem
              background={
                styles.legendItemUnknownUnplannedStandingTime.background
              }
              title="Unknown Unplanned Standing Time"
            />
          </Legend>
        </div>
      </div>
    </div>
  );
};

export default UtilisationHeader;
