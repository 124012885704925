import ConfigService from './ConfigService/ConfigService';
import {authStore} from '../store';
import {encodeUrl} from './Util';
import ResponseService from './ResponseService';

const serverUrl = ConfigService.serverUri;
const queryUrl = `${serverUrl}/api/driver`;
const driverGroupQueryUrl = `${serverUrl}/api/driver_group`;

export const DriverService = {
  query: async (params) => {
    Object.keys(params).forEach(
      // eslint-disable-next-line no-param-reassign
      (key) => params[key] == null && delete params[key]
    );
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(encodeUrl(queryUrl, params), {
      method: 'GET',
      mode: 'cors',
      headers,
    });

    return ResponseService.checkStatus(response);
  },
  getById: async (driverId) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(`${queryUrl}/${driverId}`, {
      method: 'GET',
      mode: 'cors',
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  update: async (driverId, values) => {
    // eslint-disable-next-line no-param-reassign
    Object.keys(values).forEach(
      // eslint-disable-next-line no-param-reassign
      (key) => values[key] == null && delete values[key]
    );

    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(`${queryUrl}/${driverId}`, {
      method: 'PATCH',
      mode: 'cors',
      headers,
      body: JSON.stringify(values),
    });
    return ResponseService.checkStatus(response);
  },
  createUser: async (driverId) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(`${queryUrl}/${driverId}/user`, {
      method: 'POST',
      mode: 'cors',
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  create: async (params) => {
    const {auth} = authStore;
    const headers = new Headers();
    const body = JSON.stringify(params);
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(queryUrl, {
      body,
      headers,
      method: 'POST',
      mode: 'cors',
    });
    return ResponseService.checkStatus(response);
  },
  delete: async (driverId) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(`${queryUrl}/${driverId}`, {
      method: 'DELETE',
      mode: 'cors',
      headers,
    });
    return ResponseService.checkStatus(response);
  },
};

export const DriverGroupService = {
  query: async (params) => {
    Object.keys(params).forEach(
      // eslint-disable-next-line no-param-reassign
      (key) => params[key] == null && delete params[key]
    );
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(encodeUrl(driverGroupQueryUrl, params), {
      method: 'GET',
      mode: 'cors',
      headers,
    });

    return ResponseService.checkStatus(response);
  },
  getById: async (driverGroupId) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(`${driverGroupQueryUrl}/${driverGroupId}`, {
      method: 'GET',
      mode: 'cors',
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  update: async (params) => {
    const {driverGroupId} = params;
    // eslint-disable-next-line no-param-reassign
    delete params.driverGroupId;
    Object.keys(params).forEach(
      // eslint-disable-next-line no-param-reassign
      (key) => params[key] == null && delete params[key]
    );

    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(
      encodeUrl(`${driverGroupQueryUrl}/${driverGroupId}`, params),
      {
        method: 'PUT',
        mode: 'cors',
        headers,
      }
    );
    return ResponseService.checkStatus(response);
  },
  assign: async (driverGroupId, drivers) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(
      `${driverGroupQueryUrl}/${driverGroupId}/assign`,
      {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({driverGroupId, drivers}),
        headers,
      }
    );
    return ResponseService.checkStatus(response);
  },
  unassign: async (driverGroupId, drivers) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(
      `${driverGroupQueryUrl}/${driverGroupId}/unassign`,
      {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({driverGroupId, drivers}),
        headers,
      }
    );
    return ResponseService.checkStatus(response);
  },
  getUnassigned: async (params) => {
    const {driverGroupId} = params;
    // eslint-disable-next-line no-param-reassign
    delete params.driverGroupId;
    Object.keys(params).forEach(
      // eslint-disable-next-line no-param-reassign
      (key) => params[key] == null && delete params[key]
    );

    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(
      encodeUrl(`${driverGroupQueryUrl}/${driverGroupId}/unassigned`, params),
      {
        method: 'GET',
        mode: 'cors',
        headers,
      }
    );
    return ResponseService.checkStatus(response);
  },
  getAssigned: async (params) => {
    const {driverGroupId} = params;
    // eslint-disable-next-line no-param-reassign
    delete params.driverGroupId;
    Object.keys(params).forEach(
      (key) =>
        // eslint-disable-next-line no-param-reassign
        (params[key] === null || params[key] === '') && delete params[key]
    );
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(
      encodeUrl(`${driverGroupQueryUrl}/${driverGroupId}/assigned`, params),
      {
        method: 'GET',
        mode: 'cors',
        headers,
      }
    );
    return ResponseService.checkStatus(response);
  },
  create: async (driverGroupName) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(`${driverGroupQueryUrl}`, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({name: driverGroupName}),
      headers,
    });
    return ResponseService.checkStatus(response);
  },
};
