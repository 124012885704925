import React from 'react';
import * as Yup from 'yup';
import {Form, Formik} from 'formik';
import {
  ReportSchedule as ReportScheduleType,
  ReportSchedulePatchContact as ReportSchedulePatchContactType,
  ReportSchedulePatchContactGroup as ReportSchedulePatchContactGroupType,
  ReportScheduleParameter,
  ReportSchedulePatch,
} from '@onroadvantage/onroadvantage-api';
import {ReportScheduleContext} from '../reportSchedule';
import {ReportScheduleFormSchedule} from './ReportScheduleFormSchedule';
import moment, {Moment} from 'moment';
import {
  Button,
  Divider,
  Tabs,
  Tab,
  CardActions,
  CardContent,
} from '@mui/material';
import {TabContext, TabPanel} from '@mui/lab';
import {RoleService} from '../../../service';
import {useReportScheduleFormStyles} from './ReportScheduleForm.style';
import {ReportScheduleFormParameterList} from './ReportScheduleFormParameterList';
import _ from 'lodash';
import {AutocompleteOptionType} from '../../autocomplete';
import {
  arrintRequiredSchema,
  arrintSchema,
  ArrIntSchemaType,
  IntSchemaType,
  scheduleParameterSchema,
  ScheduleParameterSchemaType,
} from '../ReportSchema';
import {Loader} from '../../loader';
import {ReportScheduleFormRecipients} from './ReportScheduleFormRecipients';
import {ReportScheduleFormTab} from './ReportScheduleFormTab';
import {NUMBER_ERROR_MESSAGE} from '../../../factory/template';

export type paramType =
  | 'arrint'
  | 'customlist'
  | 'date'
  | 'datetime'
  | 'daterange'
  | 'int'
  | 'integer'
  | 'decimal'
  | 'numeric';
export const paramTypes: paramType[] = [
  'arrint',
  'customlist',
  'date',
  'datetime',
  'daterange',
  'int',
  'integer',
  'decimal',
  'numeric',
];
const scheduleFields = [
  'name',
  'description',
  'type',
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

const contactsSchema = Yup.object({
  bcc: arrintSchema,
  cc: arrintSchema,
  to: arrintRequiredSchema,
});

const contactGroupsSchema = Yup.object({
  bcc: arrintSchema,
  cc: arrintSchema,
  to: arrintSchema,
});

const reportScheduleFormSchema = Yup.object({
  reportId: Yup.number().typeError(NUMBER_ERROR_MESSAGE).notRequired(),
  reportScheduleId: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
  name: Yup.string().nullable().required('Required'),
  description: Yup.string(),
  type: Yup.string().nullable().required('Required'),
  date: Yup.mixed().nullable(), // TODO typing
  startDate: Yup.mixed<Moment>().nullable(),
  endDate: Yup.mixed<Moment>().nullable(),
  time: Yup.mixed().nullable(),
  sunday: Yup.boolean(),
  monday: Yup.boolean(),
  tuesday: Yup.boolean(),
  wednesday: Yup.boolean(),
  thursday: Yup.boolean(),
  friday: Yup.boolean(),
  saturday: Yup.boolean(),
  parameters: Yup.array(scheduleParameterSchema),
  contacts: contactsSchema,
  contactGroups: contactGroupsSchema,
});
export type ReportScheduleFormValues = ReturnType<
  typeof reportScheduleFormSchema.validateSync
>;

export const getParamValue = (
  parameterId: number,
  type: paramType,
  parameters: ReportScheduleParameter[] = [],
  defaultValue: ScheduleParameterSchemaType['value'] = null
): ScheduleParameterSchemaType['value'] => {
  let value: ScheduleParameterSchemaType['value'];
  const param = parameters.find((p) => p.reportParameterId === parameterId);
  if (param) {
    switch (type) {
      case 'arrint':
      case 'customlist':
        // TODO add validation
        value = param.value as AutocompleteOptionType[];
        break;
      case 'date':
      case 'datetime':
        value = moment(param.value);
        break;
      case 'daterange':
        // TODO add validation
        value = param.value as AutocompleteOptionType;
        break;
    }
  }
  return value ?? defaultValue;
};

const getReportScheduleParameterValues = (
  reportSchedule: ReportScheduleType
) => {
  // check if all params were on the existing object
  const defaultParams: ScheduleParameterSchemaType[] = [];
  if (reportSchedule.id) {
    reportSchedule?.report?.reportParameters?.forEach((rp) => {
      if (rp.type && paramTypes.includes(rp.type as paramType)) {
        // TODO default values
        let value: AutocompleteOptionType[] | Moment | string = '';
        switch (rp.type) {
          case 'arrint':
          case 'customlist':
            value = [];
            break;
          case 'date':
          case 'datetime':
            value = moment();
            break;
          case 'daterange':
            value = '';
            break;
        }
        if (reportSchedule.id && rp.id) {
          defaultParams.push({
            reportScheduleId: reportSchedule.id,
            reportParameterId: rp.id,
            type: rp.type ?? 'string',
            required: rp.required ?? false, // TODO
            value: getParamValue(
              rp.id,
              rp.type as paramType,
              reportSchedule.parameters,
              value
            ),
          });
        }
      }
    });
  }

  return defaultParams;
};

const getContacts = (reportSchedule: ReportScheduleType) => {
  interface ContactsList {
    bcc: IntSchemaType[];
    cc: IntSchemaType[];
    to: IntSchemaType[];
  }
  const contacts: ContactsList = {bcc: [], cc: [], to: []};

  reportSchedule.contacts?.forEach(
    ({contact: {id, name, email} = {}, recipientType}) => {
      if (id) {
        const contact = {value: id, label: `${name} - ${email}`};
        switch (recipientType) {
          case 'bcc':
            contacts.bcc.push(contact);
            break;
          case 'cc':
            contacts.cc.push(contact);
            break;
          case 'to':
          default:
            contacts.to.push(contact);
            break;
        }
      }
    }
  );

  return contacts;
};

const getContactGroups = (reportSchedule: ReportScheduleType) => {
  interface ContactGroupsList {
    bcc: IntSchemaType[];
    cc: IntSchemaType[];
    to: IntSchemaType[];
  }
  const contactGroups: ContactGroupsList = {bcc: [], cc: [], to: []};

  reportSchedule.contactGroups?.forEach(
    ({contactGroup: {id, name} = {}, recipientType}) => {
      if (id) {
        const group = {value: id, label: name};
        switch (recipientType) {
          case 'bcc':
            contactGroups.bcc.push(group);
            break;
          case 'cc':
            contactGroups.cc.push(group);
            break;
          case 'to':
          default:
            contactGroups.to.push(group);
            break;
        }
      }
    }
  );

  return contactGroups;
};

const getInitialValues = (
  reportSchedule?: ReportScheduleType | null
): ReportScheduleFormValues | undefined => {
  if (reportSchedule?.id) {
    return {
      endDate: reportSchedule.endDate
        ? moment(reportSchedule.endDate)
        : moment(),
      startDate: reportSchedule.startDate
        ? moment(reportSchedule.startDate)
        : moment(),
      reportId: reportSchedule.reportId,
      reportScheduleId: reportSchedule.id,
      name: reportSchedule.name ?? '',
      description: reportSchedule.description ?? '',
      type: reportSchedule.type ?? '',
      date: reportSchedule.date ? moment(reportSchedule.date) : undefined,
      time: reportSchedule.time
        ? moment.utc(reportSchedule.time, 'HH:mm:ss')
        : undefined,
      sunday: reportSchedule.sunday ?? false,
      monday: reportSchedule.monday ?? false,
      tuesday: reportSchedule.tuesday ?? false,
      wednesday: reportSchedule.wednesday ?? false,
      thursday: reportSchedule.thursday ?? false,
      friday: reportSchedule.friday ?? false,
      saturday: reportSchedule.saturday ?? false,
      parameters: getReportScheduleParameterValues(reportSchedule),
      contacts: getContacts(reportSchedule),
      contactGroups: getContactGroups(reportSchedule),
    };
  }
  return undefined;
};

export const ReportScheduleForm: React.FC = () => {
  const classes = useReportScheduleFormStyles();
  const {onSingleUpdate, reportSchedule, singleLoading} = React.useContext(
    ReportScheduleContext
  );

  const [initialValues, setInitialValues] = React.useState<
    ReportScheduleFormValues | undefined
  >(getInitialValues(reportSchedule));
  const [tabValue, setTabValue] = React.useState<number>(0);

  const handleSubmit = async (
    values: ReportScheduleFormValues
    // utils: FormikHelpers<ReportScheduleFormValues>
  ) => {
    const mainValues = _.omit(values, [
      'time',
      'parameters',
      'date',
      'endDate',
      'startDate',
      'contacts',
      'contactGroups',
    ]);

    // time
    const time = moment(values.time).utc().format('HH:mm:ss');
    const date = values.date;
    const endDate = moment(values.endDate)
      ?.hour(23)
      .minute(59)
      .second(59)
      .millisecond(999)
      .toDate();
    const startDate = moment(values.startDate)
      ?.hour(0)
      .minute(0)
      .second(0)
      .millisecond(0)
      .toDate();

    // parameters
    const parameters: ReportScheduleParameter[] = [];
    values.parameters?.forEach((p) => {
      if (p.reportScheduleId && p.reportParameterId && p.value) {
        parameters.push({
          reportScheduleId: p.reportScheduleId,
          reportParameterId: p.reportParameterId,
          value: p.value as any,
        });
      }
    });

    // contacts
    const getContactList = (
      contacts: ArrIntSchemaType,
      recipientType: string
    ) => {
      const contactList: ReportSchedulePatchContactType[] = [];
      contacts?.forEach((c) => {
        if (c.value) {
          contactList.push({contactId: c.value, recipientType});
        }
      });

      return contactList;
    };
    const contacts: ReportSchedulePatchContactType[] = [];
    contacts.push(...getContactList((values.contacts ?? {}).to ?? [], 'to'));
    contacts.push(...getContactList(values.contacts?.bcc, 'bcc'));
    contacts.push(...getContactList(values.contacts?.cc, 'cc'));

    // contact groups
    const getContactGroupList = (
      contactGroups: ArrIntSchemaType,
      recipientType: string
    ) => {
      const contactGroupList: ReportSchedulePatchContactGroupType[] = [];
      contactGroups?.forEach((c) => {
        if (c.value) {
          contactGroupList.push({contactGroupId: c.value, recipientType});
        }
      });

      return contactGroupList;
    };
    const contactGroups: ReportSchedulePatchContactGroupType[] = [];
    contactGroups.push(
      ...getContactGroupList(values.contactGroups?.bcc, 'bcc')
    );
    contactGroups.push(...getContactGroupList(values.contactGroups?.cc, 'cc'));
    contactGroups.push(...getContactGroupList(values.contactGroups?.to, 'to'));

    const body: ReportSchedulePatch = {
      date,
      endDate,
      startDate,
      time,

      // nested
      contactGroups,
      contacts,
      parameters,

      // other values
      ...mainValues,
    };

    // action
    await onSingleUpdate({body});
    setInitialValues(undefined);
  };

  const handleTabChange = (event: any, value: number) => {
    setTabValue(value);
  };

  React.useEffect(() => {
    setInitialValues(getInitialValues(reportSchedule));
  }, [reportSchedule]);

  React.useEffect(() => {
    if (!initialValues) {
      setInitialValues(getInitialValues(reportSchedule));
    }
  }, [initialValues, reportSchedule]);

  if (!initialValues) {
    return <div>no initial values</div>;
  }

  return (
    <Formik<ReportScheduleFormValues>
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleSubmit}
      validationSchema={reportScheduleFormSchema}
    >
      {({isSubmitting}) => (
        <>
          <Form>
            <TabContext value={`${tabValue}`}>
              <Tabs
                value={`${tabValue}`}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab
                  disabled={isSubmitting}
                  label="Header"
                  component={ReportScheduleFormTab}
                  value="0"
                  fields={scheduleFields}
                />
                <Tab
                  disabled={isSubmitting}
                  label="Parameters"
                  component={ReportScheduleFormTab}
                  value="1"
                  fields={['parameters']}
                />
                <Tab
                  disabled={isSubmitting}
                  label="Recipients"
                  component={ReportScheduleFormTab}
                  value="2"
                  fields={['contacts', 'contactGroups']}
                />
              </Tabs>
              <Divider />
              <CardContent className={classes.content}>
                <>
                  <TabPanel className={classes.panel} value="0">
                    <ReportScheduleFormSchedule />
                  </TabPanel>
                  <TabPanel className={classes.panel} value="1">
                    <ReportScheduleFormParameterList
                      parameters={reportSchedule?.report?.reportParameters}
                    />
                  </TabPanel>
                  <TabPanel className={classes.panel} value="2">
                    <ReportScheduleFormRecipients />
                  </TabPanel>
                </>
              </CardContent>
              <Divider />
              <CardActions>
                <Button
                  disabled={
                    !RoleService.hasPermission('Edit ReportSchedule', 'Edit') ||
                    isSubmitting ||
                    singleLoading
                  }
                  data-test="submit"
                  type="submit"
                >
                  Submit
                </Button>
                {isSubmitting && <Loader size={30} />}
              </CardActions>
            </TabContext>
          </Form>
        </>
      )}
    </Formik>
  );
};
