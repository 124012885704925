import React from 'react';
import {UserContext} from './UserContext';
import {TemplateTableConfig, TemplateTable} from '../../factory/template';
import {ContractUser} from '@onroadvantage/onroadvantage-api';

export const UserContracts: React.FC = () => {
  const {
    user,
    loadingSingleItem,
    submitting,
    assignContracts,
    unassignContract,
    hasPermission,
  } = React.useContext(UserContext);

  const config: TemplateTableConfig<ContractUser> = React.useMemo(
    () => ({
      columns: [
        {
          name: 'name',
          label: 'Name',
          type: 'string',
          enableEditing: true,
          enableAutocomplete: true,
          enableMulti: true,
          model: 'Contract',
        },
        {
          name: 'code',
          label: 'Code',
          type: 'string',
          enableEditing: false,
        },
      ],
      enableFilter: true,
      enableSort: true,
      deleteMessage: (row) => ({
        title: 'User Roles',
        items: {User: user?.email, Name: row.name, Code: row.code},
      }),
      identifier: 'USER_CONTRACT_LIST',
    }),
    [user?.email]
  );

  return (
    <TemplateTable
      config={config}
      list={user?.contracts ?? []}
      loading={loadingSingleItem || submitting}
      currentPage={1}
      onInlineAdd={hasPermission?.edit ? assignContracts : undefined}
      onDelete={hasPermission?.edit ? unassignContract : undefined}
    />
  );
};
