import React from 'react';
import {ContractCriticalEventTypeConfigController} from './contractCriticalEventTypeConfig/ContractCriticalEventTypeConfigController';
import {ContractCriticalEventTypeConfigContextProvider} from './contractCriticalEventTypeConfig/ContractCriticalEventTypeConfigContext';
import {Paper, Tab, Tabs} from '@mui/material';
import {useTemplateTabControllerStyles} from '../../factory/template/tabs/TemplateTabs.style';
import {useHistory, useParams} from 'react-router-dom';
import {ContractEventTypeConfigsBreadcrumbs} from './ContractEventTypeConfigsBreadcrumbs';
import {RoleService} from '../../service';
import {ContractOperationalEventTypeConfigController} from './contractOperationalEventTypeConfig/ContractOperationalEventTypeConfigController';
import {ContractOperationalEventTypeConfigContextProvider} from './contractOperationalEventTypeConfig/ContractOperationalEventTypeConfigContext';

const contractEventTypeConfigTabs = ['critical', 'operational'] as const;

type ContractEventTypeConfigTab = (typeof contractEventTypeConfigTabs)[number];

const contractEventTypeConfigTabPermissions: {
  [key in ContractEventTypeConfigTab]: string;
} = {
  critical: 'ContractCriticalEventTypeConfig List',
  operational: 'ContractOperationalEventTypeConfig List',
};

const contractEventTypeConfigTabElements: {
  [key in ContractEventTypeConfigTab]: React.ReactNode;
} = {
  critical: <ContractCriticalEventTypeConfigController />,
  operational: <ContractOperationalEventTypeConfigController />,
};

const contractEventTypeConfigTabLabels: {
  [key in ContractEventTypeConfigTab]: string;
} = {
  critical: 'Contract Critical Event Type Config',
  operational: 'Contract Operational  Event Type Config',
};

export interface ContractEventTypeConfigsParams {
  eventVariant?: ContractEventTypeConfigTab;
}
export const ContractEventTypeConfigs = () => {
  const classes = useTemplateTabControllerStyles();
  const history = useHistory();
  const {eventVariant} = useParams<ContractEventTypeConfigsParams>();
  const [tabValue, setTabValue] =
    React.useState<ContractEventTypeConfigTab | null>('critical');

  const handleTabChange = React.useCallback(
    (_event: unknown, value: ContractEventTypeConfigTab) => {
      setTabValue(value);
      history.push(`/contracteventtypeconfigs/${value}`);
    },
    [history]
  );

  React.useLayoutEffect(() => {
    if (
      eventVariant != null &&
      contractEventTypeConfigTabs.includes(eventVariant)
    ) {
      if (
        eventVariant === 'critical' &&
        RoleService.hasPermission(
          contractEventTypeConfigTabPermissions['critical'],
          'View'
        )
      ) {
        setTabValue('critical');
      } else if (
        RoleService.hasPermission(
          contractEventTypeConfigTabPermissions['operational'],
          'View'
        )
      ) {
        setTabValue('operational');
      } else {
        setTabValue(null);
      }
    }
  }, [eventVariant]);

  return (
    <ContractCriticalEventTypeConfigContextProvider>
      <ContractOperationalEventTypeConfigContextProvider>
        <ContractEventTypeConfigsBreadcrumbs />
        <Paper className={classes.root}>
          <Tabs
            value={tabValue}
            classes={{root: classes.root, scroller: classes.tabs}}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            {contractEventTypeConfigTabs.map((tab) =>
              RoleService.hasPermission(
                contractEventTypeConfigTabPermissions[tab],
                'View'
              ) ? (
                <Tab
                  classes={{root: classes.tabBar}}
                  key={tab}
                  label={contractEventTypeConfigTabLabels[tab]}
                  value={tab}
                />
              ) : null
            )}
          </Tabs>
          {tabValue &&
          RoleService.hasPermission(
            contractEventTypeConfigTabPermissions[tabValue],
            'View'
          )
            ? contractEventTypeConfigTabElements[tabValue]
            : null}
        </Paper>
      </ContractOperationalEventTypeConfigContextProvider>
    </ContractCriticalEventTypeConfigContextProvider>
  );
};
