import React from 'react';
import {DataTypeProvider} from '@devexpress/dx-react-grid';
import {Add, Download} from '@mui/icons-material';
import {useTemplateTableActions} from '../../providers';
import {useInternalTableContext} from '../../TemplateTable';
import {TableActionButtonBase} from './TableActionButtonBase';
import Loader from '../../../../../components/loader/Loader';
import {TableActionSelectAll} from './TableActionSelectAll';
import {TableActionButtonEditAll} from './TableActionButtonEditAll';
import {useTableActionBaseStyles} from './TableActionBase.style';

export const TableActionBase: React.FC<
  DataTypeProvider.ValueEditorProps
> = () => {
  const {
    onAdd,
    onDownload,
    addRowHandler,
    enableInlineAdd,
    enableInlineEditAll,
  } = useTemplateTableActions();
  const {loading, config} = useInternalTableContext();
  const classes = useTableActionBaseStyles();

  const editAll = <TableActionButtonEditAll />;
  const addButton = (
    <TableActionButtonBase
      onClick={enableInlineAdd ? addRowHandler : onAdd}
      data-cy="TemplateTableActionsAdd"
    >
      <Add />
    </TableActionButtonBase>
  );
  const downloadButton = (
    <TableActionButtonBase
      onClick={onDownload}
      data-cy="TemplateTableActionsDownload"
    >
      <Download />
    </TableActionButtonBase>
  );

  return config.disableToolbar && loading ? (
    <Loader size={32} />
  ) : (
    <div className={classes.root}>
      <TableActionSelectAll />
      {enableInlineEditAll && editAll}
      {(onAdd || enableInlineAdd) && addButton}
      {onDownload && downloadButton}
    </div>
  );
};
