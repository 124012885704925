import React from 'react';
import {PagingPanel} from '@devexpress/dx-react-grid-material-ui';
import {useTablePagingPanelContainerStyles} from './TablePagingPanelContainer.style';

export const TablePagingPanelContainer: React.FC<
  PagingPanel.ContainerProps
> = ({children, ...otherProps}) => {
  const classes = useTablePagingPanelContainerStyles();
  return (
    <PagingPanel.Container className={classes.root} {...otherProps}>
      {children}
    </PagingPanel.Container>
  );
};
