import React from 'react';
import {Listing} from '@onroadvantage/onroadvantage-api';
import {Button, Stack, TextField} from '@mui/material';
import {TripSetupFormStopOrder, useTripSetupContext} from '../TripSetupContext';
import {Add} from '@mui/icons-material';
import {useAppNotifications} from '../../../../contexts';
import {
  TripSetupAutocompleteModel,
  TripSetupAutocompleteNode,
} from '../components';

export const TripSetupStopsStepNewOrder = () => {
  const notify = useAppNotifications();
  const {setEditing, setStops, setOrders} = useTripSetupContext();
  const orderNumberRef = React.useRef<HTMLInputElement>(null);
  const quantityRef = React.useRef<HTMLInputElement>(null);
  const deliveryNoteNumberRef = React.useRef<HTMLInputElement>(null);
  const [upliftPointNode, setUpliftPointNode] = React.useState<Listing | null>(
    null
  );
  const [offloadPointNode, setOffloadPointNode] =
    React.useState<Listing | null>(null);
  const [product, setProduct] = React.useState<Listing | null>(null);

  const handleSetUpliftPoint = React.useCallback(
    (value: Listing | null) => {
      if (value?.value) {
        setEditing(true);
      }
      setUpliftPointNode(value);
    },
    [setEditing]
  );

  const handleSetOffloadPoint = React.useCallback(
    (value: Listing | null) => {
      if (value?.value) {
        setEditing(true);
      }
      setOffloadPointNode(value);
    },
    [setEditing]
  );

  const handleSetProduct = React.useCallback(
    (value: Listing | null) => {
      if (value?.value) {
        setEditing(true);
      }
      setProduct(value);
    },
    [setEditing]
  );

  const handleAddOrder = React.useCallback(() => {
    if (
      orderNumberRef.current?.value == null ||
      orderNumberRef.current?.value === ''
    ) {
      notify('warning', 'Order number is required');
      return;
    }

    if (upliftPointNode?.value == null) {
      notify('warning', 'Loading point is required');
      return;
    }

    if (offloadPointNode?.value == null) {
      notify('warning', 'Offloading point is required');
      return;
    }

    if (product?.value == null) {
      notify('warning', 'Product is required');
      return;
    }

    if (
      quantityRef.current?.value == null ||
      quantityRef.current?.value === ''
    ) {
      notify('warning', 'Quantity is required');
      return;
    }

    if (isNaN(parseFloat(quantityRef.current.value))) {
      notify('warning', 'Quantity must be a number');
      return;
    }
    const order: TripSetupFormStopOrder = {
      orderNumber: orderNumberRef.current?.value ?? 'Something went wrong',
      quantity: parseFloat(quantityRef.current?.value ?? 'NaN'),
      deliveryNoteNumber: deliveryNoteNumberRef.current?.value,
      product,
      upliftPointNode,
      offloadPointNode,
    };

    setOrders((currentOrders) => [...currentOrders, order]);
    setStops((currentStops) => [
      ...currentStops,
      {
        node: {},
        sequence: currentStops.length + 1,
        orders: [order],
      },
    ]);
    orderNumberRef.current.value = '';
    quantityRef.current.value = '';
    if (deliveryNoteNumberRef.current?.value) {
      deliveryNoteNumberRef.current.value = '';
    }
    setUpliftPointNode(null);
    setOffloadPointNode(null);
    setProduct(null);
    setEditing(false);
  }, [
    notify,
    offloadPointNode,
    product,
    setEditing,
    setOrders,
    setStops,
    upliftPointNode,
  ]);

  return (
    <Stack spacing={2.5}>
      <TextField
        label="Order number"
        name="orderNumber"
        inputRef={orderNumberRef}
      />
      <TripSetupAutocompleteNode
        label="Loading Point"
        name="upliftPoint"
        type="loading"
        onChange={handleSetUpliftPoint}
        value={upliftPointNode}
      />
      <TripSetupAutocompleteNode
        label="Offloading Point"
        name="offloadPoint"
        type="offloading"
        onChange={handleSetOffloadPoint}
        value={offloadPointNode}
      />
      <TripSetupAutocompleteModel
        label="Product"
        name="product"
        model="Product"
        onChange={handleSetProduct}
        value={product}
      />
      <TextField label="Quantity" name="quantity" inputRef={quantityRef} />
      <TextField
        label="Delivery note number"
        name="deliveryNoteNumber"
        inputRef={deliveryNoteNumberRef}
      />
      <Button
        sx={{alignSelf: 'flex-start'}}
        variant="contained"
        color="primary"
        onClick={handleAddOrder}
        disabled={
          !upliftPointNode?.value && !offloadPointNode?.value && !product?.value
        }
        fullWidth={false}
        startIcon={<Add />}
      >
        Add Order
      </Button>
    </Stack>
  );
};
