import React from 'react';
import TextPayloadCard from './templates/TextPayloadCard';
import PhotoPayloadCard from './templates/PhotoPayloadCard';
import JSONPayloadCard from './templates/JSONPayloadCard';
import {TaskContext} from '../TaskContext';

export const TaskPayload: React.FC = () => {
  const {taskView} = React.useContext(TaskContext);

  switch (taskView?.templateTypeName?.toString()) {
    case 'GeoTimestamp':
      return <TextPayloadCard />;
    case 'Signature':
      return <PhotoPayloadCard type={'Signature'} />;
    case 'Document':
      return <PhotoPayloadCard type={'Document'} />;
    case 'Navigate':
      return <TextPayloadCard />;
    case 'Information':
      return <TextPayloadCard />;
    case 'Select':
      return <TextPayloadCard />;
    case 'SegmentedControl':
      return <JSONPayloadCard keyName={'name'} valueName={'value'} />;
    case 'Number Input':
      return <TextPayloadCard />;
    case 'Photo':
      return <PhotoPayloadCard type={'Photo'} />;
    // case 'Questionnaire':
    //   return <JSONPayloadCard />;
    // case 'BarcodeScanSingle':
    //   return <JSONPayloadCard />;
    // case 'BarcodeScanMulti':
    //   return <JSONPayloadCard />;
    default:
      return <TextPayloadCard />;
  }
};
