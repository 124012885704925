import React from 'react';
import {DriverContext, DriverContextProps} from './DriverContext';
import {useParams} from 'react-router-dom';

interface IParams {
  driverId?: string;
}

export const DriverIdHandler: React.FC = () => {
  const driverContext = React.useContext<DriverContextProps>(DriverContext);

  const driverSetId = driverContext.setDriverId;

  const {driverId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = driverId ? parseInt(driverId) : undefined;
    driverSetId(parsedId);

    return () => {
      driverSetId(undefined);
    };
  }, [driverId, driverSetId]);

  return null;
};
