import React from 'react';
import {ContractSetupWizard} from './ContractSetupWizard';
import {
  ContractDetailsFormFields,
  ContractInspectionFormFields,
  contractSetupSchema,
  ContractTripExecutionFormFields,
  ContractTripNotificationsFormFields,
  ContractTripTimesFormFields,
} from '../contractForms';
import {TemplateForm} from '../../../factory/template';
import {useContractContext} from '../contractContext';
import {ContractSetupSummary} from './contractSetupSummary';
import {ContractTaskTemplateList} from '../ContractTaskTemplateList';

export const ContractSetup: React.FC = () => {
  const {activeStep, setupInitialValues, onContractSetupSubmit} =
    useContractContext();
  const [activeStepForm, setActiveStepForm] = React.useState<React.ReactNode>(
    <ContractDetailsFormFields />
  );

  React.useEffect(() => {
    switch (activeStep) {
      case 1:
        setActiveStepForm(<ContractDetailsFormFields />);
        break;
      case 2:
        setActiveStepForm(<ContractTaskTemplateList settingUpContract />);
        break;
      case 3:
        setActiveStepForm(<ContractTripTimesFormFields />);
        break;
      case 4:
        setActiveStepForm(<ContractTripExecutionFormFields />);
        break;
      case 5:
        setActiveStepForm(<ContractTripNotificationsFormFields />);
        break;
      case 6:
        setActiveStepForm(<ContractInspectionFormFields />);
        break;
      case 7:
        setActiveStepForm(<ContractSetupSummary />);
        break;
      default:
        setActiveStepForm(<ContractDetailsFormFields />);
    }
    return () => {
      setActiveStepForm(<ContractDetailsFormFields />);
    };
  }, [activeStep]);

  return (
    <TemplateForm
      initialValues={setupInitialValues}
      onSubmit={onContractSetupSubmit}
      validationSchema={contractSetupSchema}
      disableActions
    >
      <ContractSetupWizard>{activeStepForm}</ContractSetupWizard>
    </TemplateForm>
  );
};
