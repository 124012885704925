import React from 'react';
import {TemplateCard, TemplateForm} from '../../factory/template';
import {ContractGroupContext} from './ContractGroupContext';
import {ContractGroup} from '@onroadvantage/onroadvantage-api';
import * as Yup from 'yup';
import {FormikTextField} from '../formik';

export interface IContractGroupForm {
  name: string;
}

const schema: Yup.SchemaOf<IContractGroupForm> = Yup.object({
  name: Yup.string().required('Required'),
});

const getInitialValues = (
  contractGroup?: ContractGroup | null
): IContractGroupForm | undefined => {
  if (contractGroup) {
    return {
      name: contractGroup.name ?? '',
    };
  }
  return undefined;
};

export const ContractGroupForm: React.FC<{isAdd?: boolean}> = ({isAdd}) => {
  const {detailsRef, loading, submitting, contractGroup, onDetailsFormSubmit} =
    React.useContext(ContractGroupContext);
  const [initialValues, setInitialValues] =
    React.useState<IContractGroupForm>();

  React.useEffect(() => {
    const values = getInitialValues(contractGroup);

    if (values) {
      setInitialValues(values);
    } else if (isAdd) {
      setInitialValues({
        name: '',
      });
    }
  }, [isAdd, contractGroup, setInitialValues]);

  return (
    <TemplateCard
      title={`Contract Group Details ${isAdd ? 'Add' : 'Edit'}`}
      loading={loading}
    >
      <TemplateForm<IContractGroupForm>
        initialValues={initialValues}
        onSubmit={onDetailsFormSubmit}
        permission={{
          name: `${isAdd ? 'Add' : 'Edit'} ContractGroup`,
          type: isAdd ? 'Add' : 'Edit',
        }}
        submitting={submitting}
        innerRef={detailsRef}
        validationSchema={schema}
      >
        <FormikTextField
          name="name"
          placeholder="Enter the contract group name"
          label="Name"
          fullWidth
        />
      </TemplateForm>
    </TemplateCard>
  );
};
