import React from 'react';
import {InputAdornment, TextField, Typography} from '@mui/material';
import {useInputStyles} from '../../formik/Input.style';
import {DateTimeFormatService} from '../../../service/FormatService';

interface TripDebriefDisabledTextFieldProps {
  name: string;
  label: string;
  value: string | number | Date | null | undefined;
  type?: 'string' | 'number' | 'datetime' | 'kilometers';
}

export const TripDebriefDisabledTextField: React.FC<
  TripDebriefDisabledTextFieldProps
> = ({name, label, value, type}) => {
  const classes = useInputStyles();

  /** Add formatting to the value relevant to its type */
  const memoizedValue = React.useMemo(() => {
    switch (type) {
      case 'datetime':
        if (value && typeof value !== 'number') {
          return new DateTimeFormatService().formatDateTimeToLocale(value);
        }
        return value;
      default:
        return value;
    }
  }, [type, value]);

  return (
    <TextField
      className={classes.input}
      name={name}
      label={label}
      value={memoizedValue}
      InputLabelProps={{shrink: memoizedValue != null}}
      InputProps={{
        endAdornment: type === 'kilometers' && (
          <InputAdornment position="end">
            <Typography component="legend">km</Typography>
          </InputAdornment>
        ),
      }}
      disabled
      fullWidth
    />
  );
};
