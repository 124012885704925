import {makeStyles, createStyles} from '@mui/styles';
import {Theme} from '@mui/material/styles';

const TripCardStyles = (theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
      paddingTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    header: {cursor: 'pointer', paddingTop: 0, paddingBottom: 0},
    actions: {
      cursor: 'default',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    content: {padding: 0, '&:last-child': {paddingBottom: 0}},
    contentGutters: {
      padding: theme.spacing(1.6),
      '&:last-child': {paddingBottom: theme.spacing(1.6)},
    },
    expand: {
      transform: 'rotate(0deg)',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
      marginLeft: 'auto',
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
  });

export const useTripCardStyles = makeStyles(TripCardStyles, {
  name: 'TripCardStyles',
});
