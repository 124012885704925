import React from 'react';

interface AuthenticationContextProps {
  isCookiePolicyOpen: boolean;
  setIsCookiePolicyOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AuthenticationContext =
  React.createContext<AuthenticationContextProps>({
    isCookiePolicyOpen: false,
  });

export const AuthenticationContextProvider: React.FC = ({children}) => {
  const [isCookiePolicyOpen, setIsCookiePolicyOpen] =
    React.useState<boolean>(false);
  const value = {
    isCookiePolicyOpen,
    setIsCookiePolicyOpen,
  };

  return (
    <AuthenticationContext.Provider value={value}>
      {children}
    </AuthenticationContext.Provider>
  );
};
