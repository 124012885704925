import React from 'react';
import {ContactGroupContext} from './ContactGroupContext';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../factory/template';
import {ContactGroup} from '@onroadvantage/onroadvantage-api';

export const ContactGroupList: React.FC = () => {
  const {loadList, cleanupList} = React.useContext(ContactGroupContext);

  const [config] = React.useState<TemplateTableConfig<ContactGroup>>({
    columns: [
      {name: 'name', label: 'Contact Group Name', type: 'string'},
      {
        name: 'contractCode',
        label: 'Contract Code',
        type: 'string',
        getValue: ({contracts}) =>
          contracts?.map((contract) => contract.code).join(', '),
      },
    ],
    enableFilter: true,
    enableSort: true,
    deleteMessage: (row) => ({
      title: 'Contact Group',
      items: {
        Name: row.name,
        ContractCodes: row.contracts
          ?.map((contract) => contract.code)
          .join(', '),
      },
    }),
    identifier: 'CONTACT_GROUP_LIST',
  });

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [loadList, cleanupList]);

  return <TemplateTableWrapper config={config} context={ContactGroupContext} />;
};
