import React from 'react';
import {Table, TableFilterRow} from '@devexpress/dx-react-grid-material-ui';
import {useTemplateTableStyles} from '../../TemplateTable.style';

export const TableFilterRowComponent: React.FC<Table.RowProps> = ({
  children,
  ...props
}) => {
  const classes = useTemplateTableStyles();
  return (
    <TableFilterRow.Row {...props} className={classes.tableFilterRow}>
      {children}
    </TableFilterRow.Row>
  );
};
