import React from 'react';
import {Stack} from '@mui/material';
import {usePlanningBoardGanttStyles} from '../planningBoardPlan/planningBoardGantt';
import {
  usePlanningBoardGanttContext,
  usePlanningBoardSettingsContext,
} from '../planningBoardContext';
import {PlanningBoardVehicleViewTimeline} from './PlanningBoardVehicleViewTimeline';

interface PlanningBoardGanttProps {
  focused?: boolean;
}

export const PlanningBoardVehicleViewGantt: React.FC<
  PlanningBoardGanttProps
> = () => {
  const classes = usePlanningBoardGanttStyles();
  const {selectedGroupOptions} = usePlanningBoardSettingsContext();
  const {loadGanttDetails} = usePlanningBoardGanttContext();

  // Get gantt data
  React.useEffect(() => {
    loadGanttDetails('load');
    return () => loadGanttDetails('reset');
  }, [selectedGroupOptions, loadGanttDetails]);

  return (
    <Stack className={classes.container}>
      <PlanningBoardVehicleViewTimeline />
    </Stack>
  );
};
