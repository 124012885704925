import React from 'react';

export default class MapCoordinatesStaticPopup extends React.Component {
  render() {
    const {latlng} = this.props;
    return (
      <span>
        <div style={{fontWeight: 'bold'}}>Coordinates:</div>
        <div style={{display: 'flex', flex: 1}}>
          <div style={{flex: 1}}>Latitude:</div>
          <div style={{flex: 1}}>{latlng.lat}</div>
        </div>
        <div style={{display: 'flex', flex: 1}}>
          <div style={{flex: 1}}>Longitude:</div>
          <div style={{flex: 1}}>{latlng.lng}</div>
        </div>
      </span>
    );
  }
}
