import React from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import EditIcon from '@mui/icons-material/Edit';
import {Loader} from '../../../loader';
import RoleService from '../../../../service/RoleService';
import {Stack, TextField} from '@mui/material';
import {useTripEditStopContext} from './TripEditStopWithoutForm';
import {TripEditStopAutocompleteWithListing} from './tripEditStopWithoutForm/TripEditStopAutocompleteWithListing';
import {TripEditStopDateTimePicker} from './tripEditStopWithoutForm/TripEditStopDateTimePicker';

interface TripEditStopDetailsWithoutFormProps {
  style?: React.CSSProperties;
}

export const TripEditStopDetailsWithoutForm: React.FC<
  TripEditStopDetailsWithoutFormProps
> = ({style}) => {
  const {
    tripStop,
    departureTimeRef,
    nodeRef,
    taskTemplateNodeTypeRef,
    totalServiceTimeRef,
    totalServiceTimeChangeReasonRef,
    loading,
    submitDisabled,
    onSubmit,
    initialValues,
  } = useTripEditStopContext();
  return (
    <Card style={style}>
      <CardHeader
        title="TripStop Details Edit"
        avatar={
          <Avatar>
            <EditIcon />
          </Avatar>
        }
      />
      <CardContent>
        <div>
          {tripStop.sequence === 1 && (
            <TripEditStopDateTimePicker
              label="Departure Date"
              name="departureTime"
              id="departureTime"
              defaultValue={initialValues?.departureTime}
              fullWidth
              inputRef={departureTimeRef}
            />
          )}
          <TripEditStopAutocompleteWithListing
            name="node"
            model="Node"
            label="Site"
            defaultValue={initialValues?.node}
            inputRef={nodeRef}
          />
          <TripEditStopAutocompleteWithListing
            name="taskTemplateNodeType"
            model="NodeType"
            label="Task Template Node Type"
            defaultValue={initialValues?.taskTemplateNodeType}
            inputRef={taskTemplateNodeTypeRef}
          />
          <Stack
            direction={{sm: 'row', xs: 'column'}}
            gap={{sm: 2, xs: 0}}
            mb={2}
          >
            <TextField
              name="totalServiceTime"
              label="Planned TAT"
              fullWidth
              defaultValue={initialValues?.totalServiceTime}
              inputRef={totalServiceTimeRef}
            />
            <TextField
              name="totalServiceTimeChangeReason"
              label="Reason for changing Planned TAT"
              defaultValue={initialValues?.totalServiceTimeChangeReason}
              fullWidth
              inputRef={totalServiceTimeChangeReasonRef}
            />
          </Stack>
          <div>
            {loading ? (
              <Loader size={25} />
            ) : (
              <Button
                disabled={
                  !RoleService.hasPermission('Edit TripStop', 'Edit') ||
                  submitDisabled
                }
                variant="contained"
                type="button"
                onClick={onSubmit}
              >
                {tripStop.id ? 'EDIT STOP' : 'ADD STOP'}
              </Button>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
