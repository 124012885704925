import React from 'react';
import {TripDebriefSummaryCard} from './tripDebriefSummaryCard';
import {TripDebriefContext} from '../TripDebriefContext';
import {TripDebriefNotificationList} from '../tripDebriefNotificationList';

export const TripDebriefSummaryNotifications: React.FC = () => {
  const {onTabNavigate} = React.useContext(TripDebriefContext);

  const handleNavigate = React.useCallback(() => {
    onTabNavigate('Notifications');
  }, [onTabNavigate]);

  return (
    <TripDebriefSummaryCard
      title="Notifications"
      list={undefined}
      onNavigate={handleNavigate}
      disableList
    >
      <TripDebriefNotificationList disableCard />
    </TripDebriefSummaryCard>
  );
};
