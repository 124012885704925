import React from 'react';
import {useRecipientMapStyles} from './RecipientMap.style';
import Map from '../../../map/map';
import {authStore} from '../../../../store';
import {OrderRecipientContext} from '../OrderRecipientContext';

export const RecipientMap: React.FC = () => {
  const auth = authStore.getAuth;
  const {vehicle} = React.useContext(OrderRecipientContext);
  const classes = useRecipientMapStyles();

  return (
    <div className={classes.root} data-cy="RecipientMap">
      <Map
        geoSelector={false}
        zoom={15}
        vehicle={vehicle}
        disableSatellite={!auth?.authenticated}
      />
    </div>
  );
};
