import React from 'react';
import {Typography} from '@mui/material';
import {useAuthenticationAcknowledgeStyles} from './AuthenticationAcknowledge.style';
import {AuthenticationContext} from './AuthenticationContext';

export const AuthenticationAcknowledge: React.FC = () => {
  const classes = useAuthenticationAcknowledgeStyles();
  const {setIsCookiePolicyOpen} = React.useContext(AuthenticationContext);

  const toggleCookiePolicy = React.useCallback(() => {
    if (setIsCookiePolicyOpen) {
      setIsCookiePolicyOpen((prev) => !prev);
    }
  }, [setIsCookiePolicyOpen]);

  return (
    <div className={classes.root}>
      <Typography
        component="span"
        variant="body1"
        color="textSecondary"
        className={classes.text}
      >
        By clicking SUBMIT you agree to Vantage&apos;s{' '}
        <strong onClick={toggleCookiePolicy} className={classes.link}>
          Cookie Policy
        </strong>{' '}
        and{' '}
        <a
          href="https://uploads-ssl.webflow.com/5eafbd1ee37f5e48a37b095e/6225eab9f6bfa558cf0f2a73_Viewmetrics Privacy Policy.pdf"
          target="_blank"
          rel="noreferrer"
          className={classes.link}
        >
          <strong>Privacy Policy</strong>
        </a>
      </Typography>
    </div>
  );
};
