import React from 'react';
import {Button, Collapse, Stack} from '@mui/material';
import {Listing} from '@onroadvantage/onroadvantage-api';
import {PlanningBoardTripOrdersAddAutocomplete} from './PlanningBoardTripOrdersAddAutocomplete';
import {usePlanningBoardTripContext} from '../../planningBoardTripContext';
import {AutocompleteProps} from '../../../../../autocomplete';
import {parseOrderOption} from '../../../../helpers';

interface PlanningBoardTripOrdersAddProps {
  isAdding: boolean;
  onAdd: (newOrders: Listing[]) => void;
  onCancel: () => void;
}

export const PlanningBoardTripOrdersAdd: React.FC<
  PlanningBoardTripOrdersAddProps
> = ({isAdding, onAdd, onCancel}) => {
  const {addedOrders, loadingTripPreview} = usePlanningBoardTripContext();
  const [selectedOrders, setSelectedOrders] = React.useState<Listing[]>([]);

  const handleChange: AutocompleteProps['onChange'] = React.useCallback(
    (e, value) => {
      setSelectedOrders(value);
    },
    []
  );

  const handleAdd = React.useCallback(() => {
    onAdd(selectedOrders);
  }, [onAdd, selectedOrders]);

  React.useEffect(() => {
    setSelectedOrders(addedOrders.map(parseOrderOption));

    return () => {
      setSelectedOrders([]);
    };
  }, [addedOrders]);

  return (
    <Collapse in={isAdding}>
      <Stack my={2}>
        <PlanningBoardTripOrdersAddAutocomplete
          selectedOrders={selectedOrders}
          onChange={handleChange}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <Button
          variant="contained"
          onClick={handleAdd}
          disabled={loadingTripPreview}
          fullWidth
        >
          Add selected orders
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={onCancel}
          disabled={loadingTripPreview}
          fullWidth
        >
          Cancel
        </Button>
      </Stack>
    </Collapse>
  );
};
