import React from 'react';
import {Theme} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import InfoIcon from '@mui/icons-material/Info';
import ListItemText from '@mui/material/ListItemText';
import {getImgTags} from '../../../../service/Util';
import {Loader} from '../../../loader';
import {TaskContext} from '../../TaskContext';

const styles = createStyles((theme: Theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

interface PhotoPayloadCardProps {
  type: string;
}

const PhotoPayloadCard: React.FC<PhotoPayloadCardProps> = ({type}) => {
  const [imgTags, setImgTags] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const {task} = React.useContext(TaskContext);

  React.useEffect(() => {
    setLoading(true);
    const loadImages = async () => {
      if (task && task.payload) {
        const tags = await getImgTags({
          value: task.payload,
          type: type,
        });
        setImgTags(tags);
        setLoading(false);
      } else {
        setLoading(false);
      }
    };
    loadImages();
  }, [task, type]);

  return (
    <div style={{paddingTop: 10}}>
      <Card>
        <CardContent>
          {loading ? (
            <Loader />
          ) : (
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <InfoIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={type} />
            </ListItem>
          )}
        </CardContent>
        {imgTags}
      </Card>
    </div>
  );
};

export default withStyles(styles)(PhotoPayloadCard);
