import React from 'react';
import * as Yup from 'yup';
import {
  requiredListingSchema,
  TemplateCard,
  TemplateForm,
} from '../../../factory/template';
import {FormikAutocompleteWithListing, FormikSwitch} from '../../formik';
import {useContractCriticalEventTypeConfigContext} from './ContractCriticalEventTypeConfigContext';
import {Listing} from '@onroadvantage/onroadvantage-api';

export interface ContractCriticalEventTypeConfigDetailsFormSchema {
  criticalEventType: Listing;
  contract: Listing;
  allowEventMuting: boolean;
}

const contractCriticalEventTypeConfigDetailsFormSchema: Yup.SchemaOf<ContractCriticalEventTypeConfigDetailsFormSchema> =
  Yup.object({
    criticalEventType: requiredListingSchema,
    contract: requiredListingSchema,
    allowEventMuting: Yup.boolean().required('Required'),
  });

export const ContractCriticalEventTypeConfigDetailsForm: React.FC<{
  isAdd?: boolean;
}> = ({isAdd}) => {
  const {
    loading,
    submitting,
    contractCriticalEventTypeConfig,
    onDetailsSubmit,
  } = useContractCriticalEventTypeConfigContext();
  const initialValues =
    React.useMemo<ContractCriticalEventTypeConfigDetailsFormSchema>(() => {
      if (isAdd || contractCriticalEventTypeConfig == null) {
        return {criticalEventType: {}, contract: {}, allowEventMuting: false};
      }

      return {
        criticalEventType: {
          value: contractCriticalEventTypeConfig.criticalEventTypeId,
          label: contractCriticalEventTypeConfig.criticalEventType?.name,
        },
        contract: {
          value: contractCriticalEventTypeConfig.contractId,
          label: contractCriticalEventTypeConfig.contract?.name,
        },
        allowEventMuting: contractCriticalEventTypeConfig.allowEventMuting,
      };
    }, [contractCriticalEventTypeConfig, isAdd]);

  return (
    <TemplateCard
      title={`Contract Critical Event Type Config Details ${
        isAdd ? 'Add' : 'Edit'
      }`}
      loading={loading}
    >
      <TemplateForm<ContractCriticalEventTypeConfigDetailsFormSchema>
        initialValues={initialValues}
        onSubmit={onDetailsSubmit}
        permission={{
          name: `${isAdd ? 'Add' : 'Edit'} ContractCriticalEventTypeConfig`,
          type: isAdd ? 'Add' : 'Edit',
        }}
        submitting={submitting}
        validationSchema={contractCriticalEventTypeConfigDetailsFormSchema}
      >
        <FormikAutocompleteWithListing
          name="criticalEventType"
          model="CriticalEventType"
          label="Critical Event Type"
        />
        <FormikAutocompleteWithListing
          name="contract"
          model="Contract"
          label="Contract"
        />
        <FormikSwitch label="Allow Event Muting" name="allowEventMuting" />
      </TemplateForm>
    </TemplateCard>
  );
};
