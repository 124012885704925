import React from 'react';
import {ContactGroupContext} from './ContactGroupContext';
import {
  TemplateTable,
  TemplateTableConfig,
  TOnInlineAdd,
} from '../../factory/template';
import {Listing, CustomerDump} from '@onroadvantage/onroadvantage-api';
import {contactGroupApi} from '../../api';
import {useAppNotifications} from '../../contexts';

export const ContactGroupCustomers: React.FC = () => {
  const notify = useAppNotifications();
  const {
    contactGroup,
    contactGroupId,
    loading,
    loadContactGroup,
    hasPermission,
  } = React.useContext(ContactGroupContext);
  const [localLoading, setLocalLoading] = React.useState<boolean>(false);

  const [config] = React.useState<TemplateTableConfig<CustomerDump>>({
    columns: [
      {
        name: 'name',
        label: 'Name',
        type: 'string',
        model: 'Customer',
        enableEditing: true,
        enableAutocomplete: true,
        enableMulti: true,
      },
      {
        name: 'description',
        label: 'Description',
        type: 'string',
        enableEditing: false,
      },
    ],
    disableToolbar: true,
    disablePagination: true,
    deleteMessage: (row) => ({
      title: 'Contact Group Customer',
      items: {
        ContactGroupName: contactGroup?.name,
        CustomerName: row.name,
        CustomerDescription: row.description,
      },
    }),
    identifier: 'CONTACT_GROUP_CUSTOMERS_LIST',
  });

  const handleInlineAdd = React.useCallback<TOnInlineAdd>(
    async (changes) => {
      setLocalLoading(true);
      try {
        for (const change of changes) {
          const customerListings: Listing[] = change.name;
          const customerIds = customerListings
            .filter(({value}) => value)
            .map(({value}) => value as number);
          if (contactGroupId) {
            await contactGroupApi.apiContactGroupContactGroupIdAssignCustomersPost(
              {
                contactGroupId,
                body: {customerIds},
              }
            );
            await loadContactGroup();
          }
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to assign customers');
      } finally {
        setLocalLoading(false);
      }
    },
    [contactGroupId, loadContactGroup, notify]
  );

  const handleInlineDelete = React.useCallback(
    async (row: CustomerDump) => {
      setLocalLoading(true);
      try {
        if (contactGroupId && row.id) {
          await contactGroupApi.apiContactGroupContactGroupIdUnassignCustomerPost(
            {
              contactGroupId,
              body: {customerId: row.id},
            }
          );
          await loadContactGroup();
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to unassign customer');
      } finally {
        setLocalLoading(false);
      }
    },
    [contactGroupId, loadContactGroup, notify]
  );

  return (
    <TemplateTable
      config={config}
      list={contactGroup?.customers ?? []}
      currentPage={1}
      loading={loading || localLoading}
      onDelete={hasPermission?.edit ? handleInlineDelete : undefined}
      onInlineAdd={hasPermission?.edit ? handleInlineAdd : undefined}
    />
  );
};
