import React from 'react';
import {
  SequencedTask,
  WebMasterTripStop,
} from '@onroadvantage/onroadvantage-api';
import {useTripStopContext} from '../tripStopContext';
import {TemplateTable, TemplateTableConfig} from '../../../../factory/template';
import {RoleService} from '../../../../service';
import {
  TripStopTaskListPayload,
  TripStopTaskListPayloadProps,
} from './TripStopTaskListPayload';
import {getImgTags} from '../../../../service/Util';
import {TripStopTaskListModal} from './TripStopTaskListModal';
import {TripStopTaskListActions} from './TripStopTaskListActions';

export const TripStopTaskList: React.FC = () => {
  const {tripStop, loadingTripStop, onEditTask} = useTripStopContext();
  /** Destructure tripStop, need to add || ({} as WebMasterTripStop) for typescript, since tripStop is nullable. */
  const {tasks} = tripStop || ({} as WebMasterTripStop);

  const [open, setOpen] = React.useState<boolean>(false);
  const [modalType, setModalType] = React.useState<string | undefined>();
  const [modalContent, setModalContent] = React.useState<React.ReactNode>();
  const [modalLoading, setModalLoading] = React.useState<boolean>(false);

  const handleModalClose = React.useCallback(() => {
    setOpen(false);
    setModalType(undefined);
    setModalContent(undefined);
    setModalLoading(false);
  }, []);

  const handlePayloadPress = React.useCallback<
    TripStopTaskListPayloadProps['onPress']
  >(async (type, value) => {
    setOpen(true);
    setModalLoading(true);
    try {
      setModalType(type);
      if (type === 'Checklist' || type === 'Questionnaire')
        setModalContent(value);
      else setModalContent(await getImgTags({value, type}));
    } finally {
      setModalLoading(false);
    }
  }, []);

  const [config] = React.useState<TemplateTableConfig<SequencedTask>>({
    columns: [
      {name: 'id', label: 'ID', type: 'number', enableEditing: false},
      {
        name: 'templateName',
        label: 'Template Name',
        type: 'string',
        enableEditing: false,
        getValue: ({template}) => template?.name,
      },
      {
        name: 'templateTypeName',
        label: 'Template Type',
        type: 'string',
        enableEditing: false,
        getValue: ({template}) => template?.type?.name,
      },
      {name: 'status', label: 'Status', type: 'string', enableEditing: false},
      {
        name: 'payload',
        label: 'Value',
        type: 'string',
        enableEditing: (value) =>
          value?.template?.type?.name === 'Number Input' &&
          RoleService.hasPermission('Edit Task', 'Edit'),
        getValue: (value) =>
          value.template?.type?.name === 'Number Input' ? (
            value.payload
          ) : (
            <TripStopTaskListPayload
              row={value}
              value={value.payload}
              onPress={handlePayloadPress}
            />
          ),
      },
      {
        name: 'eventDate',
        label: 'Event Date',
        type: 'datetime',
        enableEditing: false,
      },
      {
        name: 'latitude',
        label: 'Latitude',
        type: 'string',
        hidden: true,
        enableEditing: false,
      },
      {
        name: 'longitude',
        label: 'Longitude',
        type: 'string',
        hidden: true,
        enableEditing: false,
      },
    ],
    disablePagination: true,
    enableGrouping: false,
    identifier: 'TRIP_STOP_TASK_LIST',
  });

  const list = React.useMemo(() => tasks ?? [], [tasks]);

  return (
    <>
      <TemplateTable
        config={config}
        currentPage={1}
        elevation={0}
        nestedLevel={1}
        list={list}
        loading={loadingTripStop}
        onEnableRowEdit={({template}) =>
          template?.type?.name === 'Number Input'
        }
        onInlineEdit={onEditTask}
        toolbarCustomAction={<TripStopTaskListActions />}
      />
      <TripStopTaskListModal
        open={open}
        onClose={handleModalClose}
        content={modalContent}
        loading={modalLoading}
        type={modalType}
      />
    </>
  );
};
