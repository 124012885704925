import React from 'react';
import {
  ApiWorkflowConfigWorkflowConfigIdLinkedTasksGetRequest,
  DriverWorkflowConfigTaskTemplate,
  WebWorkflowOptimised,
  WorkflowConfig,
  WorkflowConfigLinkedTasksListResponse,
  WorkflowConfigTaskTemplateLink,
  WorkflowConfigTaskTemplateLinkPost,
} from '@onroadvantage/onroadvantage-api';
import {workflowConfigApi} from '../../../api';
import {
  TemplateTableContextProps,
  useTemplateTable,
  TLoadList,
  TOnInlineAdd,
} from '../../../factory/template';
import {useAppNotifications} from '../../../contexts';
import {
  IWorkflowConfigTaskLinkingListAdd,
  workflowConfigTaskLinkingAddValidation,
} from './WorkflowConfigTaskLinkingList';
import {WorkflowConfigContext} from '../WorkflowConfigContext';

export interface WorkflowConfigTaskTemplateLinkingListContextProps
  extends TemplateTableContextProps<
    WorkflowConfigTaskTemplateLink,
    WorkflowConfigLinkedTasksListResponse
  > {
  getWorkflowConfigTaskTemplate: (
    taskTemplateId: number | undefined
  ) => DriverWorkflowConfigTaskTemplate | undefined;
}

export const WorkflowConfigTaskTemplateLinkingListContext =
  React.createContext<WorkflowConfigTaskTemplateLinkingListContextProps>({
    loading: false,
    list: [],
    currentPage: 1,
    loadList: async () => {},
    getWorkflowConfigTaskTemplate: () => undefined,
  });

export const WorkflowConfigTaskTemplateLinkingListContextProvider: React.FC = ({
  children,
}) => {
  const notify = useAppNotifications();
  const {workflowConfig, workflowConfigId} = React.useContext(
    WorkflowConfigContext
  );
  // Template Table
  const [
    {
      currentPage,
      pageSize,
      pageTotal,
      sorting,
      hasPermission,
      itemTotal,
      list,
      loading,
      filters,
      isDaterangeFilterActive,
    },
    {
      // Getters
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
      setList,
    },
  ] = useTemplateTable<
    WorkflowConfigTaskTemplateLink,
    ApiWorkflowConfigWorkflowConfigIdLinkedTasksGetRequest
  >({
    enableDateRangeFilter: true,
    editPermission: 'Edit WorkflowConfig',
    addPermission: 'Add WorkflowConfig',
    deletePermission: 'Delete WorkflowConfig',
    downloadPermission: 'WorkflowConfig ListDownload',
    viewPermission: 'WorkflowConfig List',
  });

  const loadList = React.useCallback<
    TLoadList<WorkflowConfigLinkedTasksListResponse>
  >(
    async (options) => {
      setLoading(true);
      try {
        if (workflowConfigId) {
          const requestObj = getRequestObj(
            ['description', 'workflowConfigId'],
            options
          );

          const response =
            await workflowConfigApi.apiWorkflowConfigWorkflowConfigIdLinkedTasksGet(
              {...requestObj, workflowConfigId}
            );

          return getResponse(response, options);
        }
      } catch (e) {
        notify(
          'error',
          e.message ?? 'Failed to load workflow config task template linking'
        );
      } finally {
        setLoading(false);
      }
    },
    [getRequestObj, getResponse, notify, workflowConfigId, setLoading]
  );

  const handleRefresh = React.useCallback(async () => {
    await loadList();
  }, [loadList]);

  const handleDelete = React.useCallback(
    async (row: WebWorkflowOptimised) => {
      setLoading(true);
      try {
        if (row.id) {
          const response =
            await workflowConfigApi.apiWorkflowConfigTaskTemplateLinkWorkflowConfigTaskTemplateLinkIdDelete(
              {
                workflowConfigTaskTemplateLinkId: row.id,
              }
            );
          if (response.message) {
            setList((prevList) => prevList.filter(({id}) => id !== row.id));
            notify('success', 'Deleted workflow config task template link');
          }
        }
      } catch (e) {
        notify(
          'error',
          e.message ?? 'Failed to delete workflow config task template link'
        );
      } finally {
        setLoading(false);
      }
    },
    [notify, setList, setLoading]
  );

  const handleAdd = React.useCallback<TOnInlineAdd>(
    async (changes) => {
      setLoading(true);
      try {
        const values = changes[0] as
          | IWorkflowConfigTaskLinkingListAdd
          | undefined;
        if (
          workflowConfigId &&
          values &&
          values.endWorkflowConfigTaskTemplate?.value &&
          values.startWorkflowConfigTaskTemplate?.value &&
          values.linkType?.value
        ) {
          const newValues: WorkflowConfigTaskTemplateLinkPost = {
            amberDuration: values.amberDuration,
            description: values.description,
            endWorkflowConfigTaskTemplateId:
              values.endWorkflowConfigTaskTemplate.value,
            greenDuration: 0,
            linkTypeId: values.linkType.value,
            redDuration: values.redDuration,
            startWorkflowConfigTaskTemplateId:
              values.startWorkflowConfigTaskTemplate.value,
          };

          const response =
            await workflowConfigApi.apiWorkflowConfigWorkflowConfigIdLinkedTasksPost(
              {workflowConfigId, body: newValues}
            );
          if (response) {
            setList((prevList) => [response, ...prevList]);
            notify('success', 'Added workflow config task template link');
          }
        }
      } catch (e) {
        notify(
          'error',
          e.message ?? 'Failed to add workflow config task template link'
        );
      } finally {
        setLoading(false);
      }
    },
    [notify, setLoading, setList, workflowConfigId]
  );

  const {taskTemplates} = workflowConfig || ({} as WorkflowConfig);

  const handleGetWorkflowConfigTaskTemplate = React.useCallback<
    WorkflowConfigTaskTemplateLinkingListContextProps['getWorkflowConfigTaskTemplate']
  >(
    (taskTemplateId) => {
      if (!taskTemplateId) return undefined;

      return taskTemplates?.find(({id}) => id === taskTemplateId);
    },
    [taskTemplates]
  );

  const value: WorkflowConfigTaskTemplateLinkingListContextProps = {
    // Template Table
    list,
    loadList,
    hasPermission,
    loading,
    cleanupList,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    isDaterangeFilterActive,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onInlineAdd: hasPermission.view ? handleAdd : undefined,
    onDelete: hasPermission.view ? handleDelete : undefined,
    onRefresh: handleRefresh,
    addValidation: workflowConfigTaskLinkingAddValidation,
    getWorkflowConfigTaskTemplate: handleGetWorkflowConfigTaskTemplate,
  };

  return (
    <WorkflowConfigTaskTemplateLinkingListContext.Provider value={value}>
      {children}
    </WorkflowConfigTaskTemplateLinkingListContext.Provider>
  );
};
