import {renderToString} from 'react-dom/server';
import React from 'react';
import {WebPlanningBoardMasterTripWithIdType} from '../planningBoardContext';
import {WebPlanningBoardTripStop} from '@onroadvantage/onroadvantage-api';
import {DateTimeFormatService} from '../../../service/FormatService';
import {isRestStop} from '../../../factory/template';

const TooltipContainer: React.FC = ({children}) => (
  <div
    style={{
      background: '#eee',
      margin: -5,
      padding: '5px 8px',
      lineHeight: 1.8,
    }}
  >
    {children}
  </div>
);

export const getDailyViewTooltip = (
  masterTrip: WebPlanningBoardMasterTripWithIdType,
  stop: WebPlanningBoardTripStop
) => {
  const start = stop.serviceTimeStart ?? stop.arrivalTime ?? null;
  const end = stop.serviceTimeEnd ?? stop.departureTime ?? null;
  const tripStart = masterTrip.trip?.tripStart;
  const tripEnd = masterTrip.trip?.tripEnd;
  const isRest = isRestStop(stop);
  const html = (
    <TooltipContainer>
      <strong key={`${stop.id} trip number`}># {masterTrip?.tripNumber}</strong>
      <hr />
      <div key={`${stop.id} trip start`}>
        Trip:{' '}
        {tripStart ? new DateTimeFormatService().formatDate(tripStart) : '-'}{' '}
        &rarr; {tripEnd ? new DateTimeFormatService().formatDate(tripEnd) : '-'}
      </div>
      {!isRest ? (
        <div key={`${stop.id} stop start`}>
          Stop: {start ? new DateTimeFormatService().formatDate(start) : '-'}{' '}
          &rarr; {end ? new DateTimeFormatService().formatDate(end) : '-'}
        </div>
      ) : null}
    </TooltipContainer>
  );
  return renderToString(html);
};
