import {ParameterSchemaType} from '../ReportSchema';
import {ReportGenerateParameter} from './getDaterangeParameter';

export const getCustomlist = ({
  name,
  value,
}: ParameterSchemaType): ReportGenerateParameter | null => {
  const values: ReportGenerateParameter['value'] = [];
  if (Array.isArray(value)) {
    value.forEach((v) => {
      v.value !== undefined && values.push(v.value);
    });
  }

  if (values.length > 0) {
    return {
      type: 'type' || 'unknown',
      name: name || 'unknown',
      value: values,
    };
  }

  return null;
};
