import React from 'react';
import {NotificationConfigWorkflowTaskContext} from './NotificationConfigWorkflowTaskContext';
import {useParams} from 'react-router-dom';

interface IParams {
  notificationConfigTaskId?: string;
}

export const NotificationConfigWorkflowTaskIdHandler: React.FC = () => {
  const {setNotificationConfigTaskId} = React.useContext(
    NotificationConfigWorkflowTaskContext
  );

  const {notificationConfigTaskId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = notificationConfigTaskId
      ? parseInt(notificationConfigTaskId)
      : undefined;
    setNotificationConfigTaskId(parsedId);
    return () => setNotificationConfigTaskId(undefined);
  }, [notificationConfigTaskId, setNotificationConfigTaskId]);

  return null;
};
