import React from 'react';
import {GeoJSON} from 'react-leaflet';
import {
  MasterRouteLeg,
  MasterRouteLegWaypoint,
  Node,
} from '@onroadvantage/onroadvantage-api';
import DeliveryMarker from '../components/map/marker/DeliveryMarker';
import CollectionMarker from '../components/map/marker/CollectionMarker';
import DepotMarker from '../components/map/marker/DepotMarker';
import ConfigService from './ConfigService/ConfigService';
import _ from 'lodash';
import PlanningMarker from '../components/map/marker/PlanningMarker';

// @ts-expect-error upgrade
import polyline from '@mapbox/polyline';

const {routingEngineUrl} = ConfigService;

interface IWaypointLayerNodes {
  loadingNodes: Node[];
  offloadingNodes: Node[];
  depotNode: Node;
}

export interface IPolylineService {
  getPolylineMapLayers: (geoJSONs: any[], identifier: string) => any;
  getRoutePolylineMapLayers: (route: any) => any;
  getWaypointMapLayers: (items: IWaypointLayerNodes) => any;
  getRouteWaypointMapLayers: (route: any) => any;
  waypointsToPolylines: (waypoints: string[]) => Promise<any>;
  getOSRMRouteSimple: (waypoints: string[]) => Promise<any>;
  getOSRMRoute: (waypoints: string[]) => Promise<any>;
  getOSRMRouteInfo: (waypoints: string[]) => Promise<any>;
  extractSkwiggles: (route: any[]) => string[];
  extractRouteLines: (route: any[]) => any[];
  polylineToGeoJSON: (skwiggle: string) => Promise<any>;
  polylinesToGeoJSON: (skwiggle: string[]) => Promise<any>;
  getRoutePointsFromMasterRouteLeg: (leg: MasterRouteLeg) => Array<string>;
}

const PolylineService: IPolylineService = {
  polylineToGeoJSON: async (skwiggle: string) => {
    return polyline.toGeoJSON(skwiggle);
  },
  polylinesToGeoJSON: async (skwiggles: string[]) => {
    return skwiggles.map((skwiggle: string) => {
      return polyline.toGeoJSON(skwiggle);
    });
  },
  extractRouteLines: (route: any) => {
    const routeLines: any[] = [];
    route.legs.forEach((leg: any) => {
      routeLines.push({geoJson: polyline.toGeoJSON(leg.polyline), ...leg});
    });
    return routeLines;
  },
  waypointsToPolylines: async (waypoints: any[]) => {
    const skwiggles = await PolylineService.getOSRMRouteSimple(waypoints);
    return skwiggles.map((skwiggle: string) => polyline.toGeoJSON(skwiggle));
  },
  getPolylineMapLayers: async (geoJSONs: any[], identifier: string) => {
    return geoJSONs.map((geoJSON: any, index) => {
      return (
        <GeoJSON data={geoJSON} key={`${identifier}externalLayer${index}`} />
      );
    });
  },
  getRoutePolylineMapLayers: async (route: any) => {
    return route.polylines.map((geoJSON: any, index: number) => {
      const routeStyle = {
        color: route.color,
      };
      const key = `externalLayer${route.tripNumber}-${index}`;
      // TODO add on click for route to see what trip it is
      // TODO add drag functionality for the trip
      return <GeoJSON data={geoJSON} style={routeStyle} key={key}></GeoJSON>;
    });
  },
  getWaypointMapLayers: async ({loadingNodes, offloadingNodes, depotNode}) => {
    const responseLayers: any[] = [];
    const loadingLayers = loadingNodes.map((item: Node, index: number) => {
      return (
        <CollectionMarker
          key={`LOADING${item.id}-${index}${Math.random()}`}
          position={[item.latitude, item.longitude]}
          stop={{name: item.name, node: item}}
        />
      );
    });

    const offloadingLayers = offloadingNodes.map(
      (item: Node, index: number) => {
        return (
          <DeliveryMarker
            key={`OFFLOADING${item.id}-${index}${Math.random()}`}
            position={[item.latitude, item.longitude]}
            stop={{name: item.name, node: item}}
          />
        );
      }
    );
    if (depotNode) {
      const depotLayer = (
        <DepotMarker
          key={`DEPOT${depotNode.id}-${1}${Math.random()}`}
          position={[depotNode.latitude, depotNode.longitude]}
          stop={{name: depotNode.name, node: depotNode}}
        />
      );
      responseLayers.push(depotLayer);
    }

    return [...offloadingLayers, ...loadingLayers, ...responseLayers];
  },
  getRouteWaypointMapLayers: async (route: any) => {
    const responseLayers: any[] = [];
    const loadingLayers = route.loadingNodes.map(
      (item: Node, index: number) => {
        return (
          <PlanningMarker
            key={`LOADING${item.id}-${index}${Math.random()}`}
            position={[item.latitude, item.longitude]}
            stop={{name: item.name, node: item}}
            color={route.color}
          />
        );
      }
    );

    const offloadingLayers = route.offloadingNodes.map(
      (item: Node, index: number) => {
        return (
          <PlanningMarker
            key={`OFFLOADING${item.id}-${index}${Math.random()}`}
            position={[item.latitude, item.longitude]}
            stop={{name: item.name, node: item}}
            color={route.color}
          />
        );
      }
    );
    if (route.depotNode) {
      const depotLayer = (
        <DepotMarker
          key={`OFFLOADING${route.tripNumber}-${route.depotNode.id}`}
          position={[route.depotNode.latitude, route.depotNode.longitude]}
          stop={{name: route.depotNode.name, node: route.depotNode}}
          color={route.color}
        />
      );
      responseLayers.push(depotLayer);
    }

    return [...offloadingLayers, ...loadingLayers, ...responseLayers];
  },
  extractSkwiggles: (route: any) => {
    const skwiggles: string[] = [];
    route.legs.forEach((leg: any) => {
      leg.steps.forEach((step: any) => {
        skwiggles.push(step.geometry);
      });
    });
    return skwiggles;
  },
  getOSRMRouteSimple: async (waypoints: string[]) => {
    // waypoints ['lon,lat', 'lon,lat']
    const response = await fetch(`${routingEngineUrl}${waypoints.join(';')}`, {
      method: 'GET',
      mode: 'cors',
    });
    // TODO error handling
    const responseJson = await response.json();
    if (responseJson.routes && responseJson.routes.length > 0) {
      return [responseJson.routes[0].geometry];
    }
    return [];
  },
  getOSRMRoute: async (waypoints: string[]) => {
    // waypoints ['lon,lat', 'lon,lat']
    const response = await fetch(
      `${routingEngineUrl}${waypoints.join(';')}?steps=true`,
      {
        method: 'GET',
        mode: 'cors',
      }
    );
    // TODO error handling
    const responseJson = await response.json();
    if (responseJson.routes && responseJson.routes.length > 0) {
      return PolylineService.extractSkwiggles(responseJson.routes[0]);
    }
    return [];
  },
  getOSRMRouteInfo: async (waypoints: string[]) => {
    // waypoints ['lon,lat', 'lon,lat']
    try {
      const response = await fetch(
        `${routingEngineUrl}${waypoints.join(';')}?steps=true`,
        {
          method: 'GET',
          mode: 'cors',
        }
      );
      // TODO error handling
      const responseJson = await response.json();
      if (responseJson.routes && responseJson.routes.length > 0) {
        return responseJson.routes[0];
      }
    } catch {
      return [];
    }
  },
  getRoutePointsFromMasterRouteLeg: (leg: MasterRouteLeg) => {
    const sortedWaypoints = _.orderBy(leg.waypoints, ['sequence'], ['asc']);
    const routePoints = sortedWaypoints
      ? sortedWaypoints.map(
          (waypoint: MasterRouteLegWaypoint) =>
            `${waypoint.longitude},${waypoint.latitude}`
        )
      : [];
    if (leg.fromNode) {
      routePoints.unshift(`${leg.fromNode.longitude},${leg.fromNode.latitude}`);
    }
    if (leg.toNode) {
      routePoints.push(`${leg.toNode.longitude},${leg.toNode.latitude}`);
    }
    return routePoints;
  },
};

export default PolylineService;
