import React from 'react';
import {FormikRadioGroup, FormikTextField} from '../../formik';
import {useFormikContext} from 'formik';
import {
  ReportScheduleFormScheduleOnce,
  ReportScheduleFormScheduleRecurring,
} from '../ReportScheduleForm';
import CardContent from '@mui/material/CardContent';

export const ReportScheduleAddDetails: React.FC = () => {
  const {values, isSubmitting} = useFormikContext<any>(); // TODO typing

  let content: React.ReactElement | null;
  switch (values.type) {
    case 'once':
      content = <ReportScheduleFormScheduleOnce />;
      break;
    case 'recurring':
      content = <ReportScheduleFormScheduleRecurring />;
      break;
    default:
      content = null;
      break;
  }

  return (
    <CardContent style={{display: 'flex', flexDirection: 'column'}}>
      <FormikTextField
        disabled={isSubmitting}
        id="name"
        name="name"
        placeholder="Enter the report schedule name"
        label="Report Schedule Name"
        // fullWidth
      />
      <FormikTextField
        disabled={isSubmitting}
        id="description"
        name="description"
        placeholder="Enter the report schedule description"
        label="Report Schedule Description"
        // fullWidth
      />
      <FormikRadioGroup
        name="type"
        options={[
          {label: 'Once', value: 'once'},
          {label: 'Recurring', value: 'recurring'},
        ]}
        title="Schedule Type"
      />
      <div style={{display: 'flex', flex: 1}}>{content}</div>
    </CardContent>
  );
};
