import React from 'react';
import {VehicleTypeContext} from '../VehicleTypeContext';
import {VehicleTypeCapacityContext} from './VehicleTypeCapacityContext';
import {VehicleTypeCapacityForm} from './VehicleTypeCapacityForm';
import {
  ITemplateTabControllerTab,
  TemplateTabs,
} from '../../../factory/template';

export const VehicleTypeCapacity: React.FC = () => {
  const {vehicleTypeId} = React.useContext(VehicleTypeContext);
  const {vehicleTypeCapacityId, loading} = React.useContext(
    VehicleTypeCapacityContext
  );

  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {title: 'Details', element: <VehicleTypeCapacityForm />},
  ]);

  const path = React.useMemo(
    () => `/vehicletypelist/${vehicleTypeId}/capacity/${vehicleTypeCapacityId}`,
    [vehicleTypeCapacityId, vehicleTypeId]
  );

  return <TemplateTabs tabs={tabs} path={path} loading={loading} />;
};
