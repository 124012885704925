import React from 'react';
import {IconButton, Stack, Typography} from '@mui/material';
import {Close} from '@mui/icons-material';
import {PlanningBoardBookingForm} from './PlanningBoardBookingForm';
import {
  NEW_BOOKING_TRIP_ID,
  usePlanningBoardGanttContext,
} from '../../planningBoardContext';
import {usePlanningBoardBookingContext} from './planningBoardBookingContext';
import {DateTimeFormatService} from '../../../../service/FormatService';

export const PlanningBoardBooking: React.FC = () => {
  const {selectedGanttItem, ganttItems, onClearSelectedGanttItem} =
    usePlanningBoardGanttContext();
  const {booking} = usePlanningBoardBookingContext();

  const handleClose = React.useCallback(() => {
    onClearSelectedGanttItem();
    ganttItems.remove(NEW_BOOKING_TRIP_ID);
  }, [ganttItems, onClearSelectedGanttItem]);

  const isCreating = selectedGanttItem?.data === null;

  const trip = React.useMemo(
    () => booking?.bookingMasterTrip?.trip,
    [booking?.bookingMasterTrip?.trip]
  );

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h3" fontWeight={500}>
          {isCreating ? 'Create Booking' : 'Edit Booking'}
        </Typography>
        <IconButton onClick={handleClose} size="medium">
          <Close fontSize="large" />
        </IconButton>
      </Stack>
      {!isCreating && trip ? (
        <Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography fontWeight={500}>Trip Start:</Typography>
            <Typography>
              {trip.tripStart
                ? new DateTimeFormatService().formatDateTime(trip.tripStart)
                : '-'}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography fontWeight={500}>Trip End:</Typography>
            <Typography>
              {trip.tripEnd
                ? new DateTimeFormatService().formatDateTime(trip.tripEnd)
                : '-'}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography fontWeight={500}>Trip Distance:</Typography>
            <Typography>
              {trip.tripDistance
                ? `${Math.round(trip.tripDistance * 10) / 10}km`
                : '-'}
            </Typography>
          </Stack>
        </Stack>
      ) : null}

      <PlanningBoardBookingForm isAdd={selectedGanttItem?.data === null} />
    </Stack>
  );
};
