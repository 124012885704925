import React from 'react';
import {OrderContext, OrderContextProps} from './OrderContext';
import {OrderLineList} from './orderLine';
import {OrderForm} from './orderForm';
import {ITemplateTabControllerTab, TemplateTabs} from '../../factory/template';

export const Order: React.FC = () => {
  const {loading, orderId} = React.useContext<OrderContextProps>(OrderContext);

  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {title: 'Details', element: <OrderForm />},
    {title: 'Lines', element: <OrderLineList />},
  ]);

  const path = React.useMemo(() => `/orderlist/${orderId}`, [orderId]);

  return <TemplateTabs path={path} tabs={tabs} loading={loading} />;
};
