import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {Paper} from '@mui/material';
import {useTaskControllerStyles} from './TaskController.style';
import {
  TaskContext,
  TaskContextProps,
  TaskContextProvider,
} from './TaskContext';
import {
  BreadcrumbController,
  BreadcrumbRoute,
} from '../breadcrumbs/BreadcrumbController';
import _ from 'lodash';
import {TaskList} from './taskList';
import {TaskIdHandler} from './TaskIdHandler';
import {Task} from './Task';

export const TaskControllerComponent: React.FC = () => {
  const classes = useTaskControllerStyles();
  const {taskView} = React.useContext<TaskContextProps>(TaskContext);

  const routes: BreadcrumbRoute[] = [
    {
      path: '/tasklist',
      matchOptions: undefined,
      title: 'Task List',
    },
    {
      path: '/tasklist/add',
      matchOptions: undefined,
      title: 'New Task',
    },
    {
      path: '/tasklist/:taskId',
      matchOptions: undefined,
      title: (params) =>
        taskView?.templateName?.toString() ??
        `Task ${_.get(params, 'taskId', 'Single')}`,
    },
  ];

  return (
    <div className={classes.root}>
      <BreadcrumbController routes={routes} />
      <Paper className={classes.container}>
        <Route exact path="/tasklist" component={TaskList} />
        <Route path="/tasklist/:taskId" component={TaskIdHandler} />
        <Switch>
          <Route exact path="/tasklist/:taskId" component={Task} />
        </Switch>
      </Paper>
    </div>
  );
};

export const TaskController: React.FC = () => {
  return (
    <TaskContextProvider>
      <TaskControllerComponent />
    </TaskContextProvider>
  );
};
