import React from 'react';
import {WebWorkflowOptimised} from '@onroadvantage/onroadvantage-api';
import {WorkflowContext} from './WorkflowContext';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../factory/template';
import {DateTime} from 'luxon';

export const WorkflowList: React.FC = () => {
  const {loadList, cleanupList} = React.useContext(WorkflowContext);

  const [config] = React.useState<TemplateTableConfig<WebWorkflowOptimised>>({
    columns: [
      {name: 'driverName', label: 'Driver Name', type: 'string'},
      {name: 'contractCode', label: 'Contract Code', type: 'string'},
      {name: 'fleetNumber', label: 'Fleet Number', type: 'string'},
      {name: 'yardName', label: 'Yard Name', type: 'string'},
      {name: 'taskTypeName', label: 'Task Type', type: 'string'},
      {name: 'workflowConfigName', label: 'Workflow Config', type: 'string'},
      {
        name: 'taskProgress',
        label: 'Progress',
        type: 'progress',
        enableFilter: false,
      },
      {
        name: 'vehicleRegistrationNumber',
        label: 'Vehicle Registration Number',
        type: 'string',
      },
      {
        name: 'startedAt',
        label: 'Started At',
        type: 'datetime',
        enableFilter: false,
      },
      {
        name: 'completedAt',
        label: 'Completed At',
        type: 'datetime',
        enableFilter: false,
      },
      {
        name: 'status',
        label: 'Status',
        type: 'string',
        enableFilter: false,
        getValue: ({startedAt, completedAt}) =>
          startedAt && completedAt
            ? 'Completed'
            : startedAt
            ? 'In Progress'
            : 'Pending',
      },
      {
        name: 'duration',
        label: 'Duration',
        type: 'string',
        getValue: ({startedAt, completedAt}) => {
          if (!startedAt || !completedAt) return '-';
          const duration = DateTime.fromJSDate(completedAt)
            .diff(DateTime.fromJSDate(startedAt))
            .shiftTo('days', 'hours', 'minutes', 'seconds');
          const day = duration.days;
          const hour = `${duration.hours}`.padStart(2, '0');
          const minute = `${duration.minutes}`.padStart(2, '0');
          const second = `${Math.floor(duration.seconds)}`.padStart(2, '0');
          const time = `${hour}:${minute}:${second}`;
          let finalDuration = time;
          if (day === 1) {
            finalDuration = `${day} Day ${time}`;
          } else if (day > 1) {
            finalDuration = `${day} Days ${time}`;
          }
          return finalDuration;
        },
        enableFilter: false,
      },
    ],
    enableFilter: true,
    enableSort: true,
    deleteMessage: (row) => ({
      title: 'Workflow',
      items: {
        WorkflowConfigName: row.workflowConfigName,
        ContractCode: row.contractCode,
        TaskTypeName: row.taskTypeName,
        YardName: row.yardName,
      },
    }),
    identifier: 'WORKFLOW_LIST',
  });

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [loadList, cleanupList]);

  return <TemplateTableWrapper config={config} context={WorkflowContext} />;
};
