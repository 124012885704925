import {
  WebPlanningBoardOrder,
  WebPlanningBoardVehicle,
} from '@onroadvantage/onroadvantage-api';

export type TCheckHasRequiredSkill = (
  order: WebPlanningBoardOrder,
  vehicle: WebPlanningBoardVehicle
) => boolean;

export const checkHasRequiredSkill: TCheckHasRequiredSkill = (
  order,
  vehicle
) => {
  const requiredSkills = order?.planningOrder?.skill?.split(', ');
  const vehicleSkills = vehicle?.type?.skills?.map(({name}) => name);
  let doesHaveSkill = false;
  if (vehicleSkills) {
    if (!requiredSkills || requiredSkills.length === 0) doesHaveSkill = true;
    else
      requiredSkills.forEach((skill) => {
        if (vehicleSkills.includes(skill)) doesHaveSkill = true;
      });
  }
  return doesHaveSkill;
};
