import React from 'react';
import * as Yup from 'yup';

import {Moment} from 'moment';
import {
  arrintRequiredSchema,
  arrintSchema,
  ArrIntSchemaType,
  daterangeRequiredSchema,
  daterangeSchema,
  dateSchema,
  parameterSchemaType,
} from '../ReportSchema';
import {
  FormikStep,
  TemplateStepperFormikContextProvider,
} from '../../../factory/template/stepper/formik/TemplateStepperFormikContext';
import {TemplateStepperFormikStepper} from '../../../factory/template/stepper/formik/TemplateStepperFormikStepper';
import {ReportScheduleAddParameters} from './ReportScheduleAddParameters';
import {ReportScheduleAddRecipients} from './ReportScheduleAddRecipients';
import {ReportScheduleAddDetails} from './ReportScheduleAddDetails';
import {ReportScheduleAddReview} from './ReportScheduleAddReview';
import {paramType, paramTypes} from '../ReportScheduleForm';
import {ReportContext} from '../ReportContext';
import moment from 'moment';
import {
  Report as ReportType,
  ReportParameter,
  ReportSchedulePost,
  ReportSchedulePostContact,
  ReportSchedulePostContactGroup,
  ReportSchedulePostParameter,
} from '@onroadvantage/onroadvantage-api';
import {FormikValues} from 'formik';
import {ReportScheduleContext} from '../reportSchedule';
import {NUMBER_ERROR_MESSAGE} from '../../../factory/template';

export const parameterSchema = Yup.object({
  description: Yup.string().nullable(),
  listName: Yup.string().nullable(),
  listValues: Yup.array().of(Yup.string().required()),
  reportParameterId: Yup.number().typeError(NUMBER_ERROR_MESSAGE).nullable(),
  type: Yup.string().required(),
  required: Yup.boolean().required(),
  value: Yup.mixed<parameterSchemaType>()
    .nullable()
    .when(['required', 'type'], {
      // daterange (required)
      is: (required: boolean, type: string) => required && type === 'daterange',
      then: daterangeRequiredSchema,
    })
    .when(['required', 'type'], {
      // daterange (optional)
      is: (required: boolean, type: string) =>
        !required && type === 'daterange',
      then: daterangeSchema,
    })
    .when(['required', 'type'], {
      // arrint (optional)
      is: (required: boolean, type: string) => !required && type === 'arrint',
      then: arrintSchema,
    })
    .when(['required', 'type'], {
      // arrint (required)
      is: (required: boolean, type: string) => required && type === 'arrint',
      then: arrintRequiredSchema,
    })
    .when('type', {
      is: 'date',
      then: dateSchema,
    }),
});

export type ReportScheduleAddParameter = ReturnType<
  typeof parameterSchema.validateSync
>;

const detailSchema = Yup.object({
  description: Yup.string(),
  name: Yup.string().nullable().required('Required'),
  type: Yup.string().required(),
  date: Yup.mixed().nullable(), // TODO typing
  startDate: Yup.mixed<Moment>().nullable(),
  endDate: Yup.mixed<Moment>().nullable(),
  time: Yup.mixed().nullable(),
  sunday: Yup.boolean(),
  monday: Yup.boolean(),
  tuesday: Yup.boolean(),
  wednesday: Yup.boolean(),
  thursday: Yup.boolean(),
  friday: Yup.boolean(),
  saturday: Yup.boolean(),
});
const paramSchema = Yup.object({
  parameters: Yup.array(parameterSchema),
});
const recipientSchema = Yup.object({
  contacts: Yup.object({
    bcc: arrintSchema,
    cc: arrintSchema,
    to: arrintRequiredSchema,
  }),
  contactGroups: Yup.object({
    bcc: arrintSchema.optional().nullable(),
    cc: arrintSchema.optional().nullable(),
    to: arrintSchema.optional().nullable(),
  }),
});
const fullSchema = Yup.object({
  reportId: Yup.number().typeError(NUMBER_ERROR_MESSAGE).required('Required'),
})
  .concat(detailSchema)
  .concat(paramSchema)
  .concat(recipientSchema);

export type ReportScheduleAddFormValues = ReturnType<
  typeof fullSchema.validateSync
>;

const getDefaultValue = (param: ReportParameter) => {
  if (param.type && paramTypes.includes(param.type as paramType)) {
    switch (param.type) {
      case 'date':
      case 'datetime':
        return moment();
      case 'arrint':
      case 'customlist':
        return [];
      case 'daterange':
        // TODO
        break;
    }
  }
  return undefined;
};

const getReportScheduleParameterValues = (report: ReportType) => {
  const defaultParams: ReportScheduleAddParameter[] = [];
  report.reportParameters?.forEach((rp) => {
    if (rp.type && paramTypes.includes(rp.type as paramType)) {
      defaultParams.push({
        description: rp.description ?? 'Parameter',
        reportParameterId: rp.id,
        type: rp.type ?? 'string',
        required: rp.required ?? false,
        value: getDefaultValue(rp),
        listName: rp.listName,
        listValues: rp.listValues,
      });
    }
  });
  return defaultParams;
};

const getInitialValues = (report: ReportType | undefined) => {
  if (!report || !report.id) {
    return undefined;
  }

  return {
    reportId: report.id,
    endDate: moment(),
    startDate: moment(),
    name: '',
    description: '',
    type: '',
    date: undefined,
    time: undefined,
    sunday: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    parameters: getReportScheduleParameterValues(report),
    contacts: {
      bcc: [],
      cc: [],
      to: [],
    },
    contactGroups: {
      bcc: [],
      cc: [],
      to: [],
    },
  };
};

export const ReportScheduleAdd: React.FC = () => {
  const {report} = React.useContext(ReportContext);
  const {onSingleCreate} = React.useContext(ReportScheduleContext);
  const [steps] = React.useState<Array<FormikStep>>([
    {
      nextTitle: 'next',
      title: 'Details',
      prevTitle: 'back',
      validationSchema: detailSchema,
    },
    {
      nextTitle: 'next',
      title: 'Parameters',
      prevTitle: 'back',
      validationSchema: paramSchema,
    },
    {
      nextTitle: 'next',
      title: 'Recipients',
      prevTitle: 'back',
      validationSchema: recipientSchema,
    },
    {
      nextTitle: 'submit',
      title: 'Review',
      prevTitle: 'back',
      validationSchema: fullSchema,
    },
  ]);
  const [initialValues, setInitialValues] = React.useState<
    ReportScheduleAddFormValues | undefined
  >();

  const handleSubmit = async (
    values: FormikValues
    // utils: FormikHelpers<ReportScheduleAddFromValues>
  ) => {
    const {
      contacts,
      contactGroups,
      parameters,
      startDate,
      endDate,
      time,
      ...validated
    } = values as ReportScheduleAddFormValues;

    const bodyParameters: ReportSchedulePostParameter[] = [];

    parameters?.forEach((p) => {
      if (p.reportParameterId && p.value) {
        bodyParameters.push({
          reportParameterId: p.reportParameterId,
          value: p.value as any,
        });
      }
    });

    const bodyContacts: ReportSchedulePostContact[] = [];
    const pushContact = (cs: ArrIntSchemaType, recipientType: string) => {
      cs?.forEach((c) => {
        if (c.value) {
          bodyContacts.push({contactId: c.value, recipientType: recipientType});
        }
      });
    };
    pushContact(contacts?.bcc || [], 'bcc');
    pushContact(contacts?.cc || [], 'cc');
    pushContact(contacts?.to || [], 'to');

    const bodyContactGroups: ReportSchedulePostContactGroup[] = [];
    const pushContactGroup = (cgs: ArrIntSchemaType, recipientType: string) => {
      cgs?.forEach((cg) => {
        if (cg.value) {
          bodyContactGroups.push({
            contactGroupId: cg.value,
            recipientType: recipientType,
          });
        }
      });
    };
    pushContactGroup(contactGroups?.bcc ?? [], 'bcc');
    pushContactGroup(contactGroups?.cc ?? [], 'cc');
    pushContactGroup(contactGroups?.to ?? [], 'to');

    // parameters?.map((p) => ({reportParameterId: p.reportParameterId, value: p.value}))

    const body: ReportSchedulePost = {
      ...validated,
      // TODO convert to luxon
      endDate: moment(endDate)
        ?.hour(23)
        .minute(59)
        .second(59)
        .millisecond(999)
        .toDate(),
      // TODO convert to luxon
      startDate: moment(startDate)
        ?.hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
        .toDate(),
      time: moment(time).utc().format('HH:mm:ss'),
      parameters: bodyParameters,
      contacts: bodyContacts,
      contactGroups: bodyContactGroups,
    };

    await onSingleCreate({body});
  };

  // wait for the parent to load the report and set initial values
  React.useEffect(() => {
    setInitialValues(getInitialValues(report));
  }, [report]);

  if (!initialValues) {
    return <div>Initializing...</div>;
  }

  return (
    <TemplateStepperFormikContextProvider steps={steps}>
      <TemplateStepperFormikStepper
        onSubmit={handleSubmit}
        initialValues={initialValues}
        title="Add Report Schedule"
        style={{display: 'flex', flexDirection: 'column'}}
      >
        {[
          <ReportScheduleAddDetails key="Details" />,
          <ReportScheduleAddParameters key="Parameters" />,
          <ReportScheduleAddRecipients key="Recipients" />,
          <ReportScheduleAddReview key="Review" />,
        ]}
      </TemplateStepperFormikStepper>
    </TemplateStepperFormikContextProvider>
  );
};
