import React from 'react';
import {useTemplateTableActions} from '../../providers';
import {Checkbox} from '@mui/material';
import {useInternalTableContext} from '../../TemplateTable';

export const TableActionSelectAll: React.FC = () => {
  const {selection, config, list, loading} = useInternalTableContext();
  const {onSelectionChange} = useTemplateTableActions();
  const disableSelectAll = React.useMemo(
    () => config.disableSelectAll,
    [config.disableSelectAll]
  );
  const filteredListBySelectableRows = React.useMemo(
    () =>
      list.filter((row) =>
        !config.getSelectableRow
          ? true
          : config.getSelectableRow && config.getSelectableRow(row)
      ),
    [config, list]
  );

  const handleSelectAll = React.useCallback(() => {
    if (onSelectionChange && selection) {
      onSelectionChange(
        selection.length > 0 &&
          selection.length === filteredListBySelectableRows.length
          ? []
          : filteredListBySelectableRows
      );
    }
  }, [filteredListBySelectableRows, onSelectionChange, selection]);

  const isSelectDisabled = React.useMemo(
    () => list.length === 0 || filteredListBySelectableRows.length === 0,
    [filteredListBySelectableRows.length, list.length]
  );
  const isAllSelected = React.useMemo(
    () =>
      selection &&
      selection.length > 0 &&
      selection.length === filteredListBySelectableRows.length,
    [filteredListBySelectableRows.length, selection]
  );

  const isIndeterminate = React.useMemo(
    () =>
      selection &&
      selection.length > 0 &&
      selection.length < filteredListBySelectableRows.length,
    [filteredListBySelectableRows.length, selection]
  );

  if (!selection || !onSelectionChange || disableSelectAll) {
    return null;
  }

  return (
    <Checkbox
      onChange={handleSelectAll}
      disabled={isSelectDisabled || loading}
      checked={isAllSelected}
      indeterminate={isIndeterminate}
    />
  );
};
