import ConfigService from './ConfigService/ConfigService';
import {encodeUrl, flatten} from './Util';
import ResponseService from './ResponseService';
import {authStore} from '../store';

const serverUrl = ConfigService.serverUri;
const queryUrl = `${serverUrl}/api/transporter`;

const TransporterService = {
  query: async (params) => {
    Object.keys(params).forEach(
      // eslint-disable-next-line no-param-reassign
      (key) => params[key] == null && delete params[key]
    );
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(encodeUrl(queryUrl, params), {
      method: 'GET',
      mode: 'cors',
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  delete: async (transporterId) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(`${queryUrl}/${transporterId}`, {
      method: 'DELETE',
      mode: 'cors',
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  update: async (transporter) => {
    if (!transporter.id) {
      return TransporterService.create(transporter);
    }
    Object.keys(transporter).forEach(
      // eslint-disable-next-line no-param-reassign
      (key) => transporter[key] == null && delete transporter[key]
    );
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(`${queryUrl}/${transporter.id}`, {
      method: 'PATCH',
      mode: 'cors',
      body: JSON.stringify(transporter),
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  create: async (transporter) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(`${queryUrl}`, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(transporter),
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  normalize: async (response) => {
    const result = flatten(await response.data);
    return result;
  },
  getById: async (transporterId) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(`${queryUrl}/${transporterId}`, {
      method: 'GET',
      mode: 'cors',
      headers,
    });
    return ResponseService.checkStatus(response);
  },
};

export default TransporterService;
