import React from 'react';
import {TrainingCourseUserContext} from './TrainingCourseUserContext';
import {useParams} from 'react-router-dom';

interface IParams {
  trainingCourseUserId?: string;
}

export const TrainingCourseUserIdHandler: React.FC = () => {
  const {setTrainingCourseUserId} = React.useContext(TrainingCourseUserContext);

  const {trainingCourseUserId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = trainingCourseUserId
      ? parseInt(trainingCourseUserId)
      : undefined;
    setTrainingCourseUserId(parsedId);
    return () => setTrainingCourseUserId(undefined);
  }, [trainingCourseUserId, setTrainingCourseUserId]);

  return null;
};
