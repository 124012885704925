import React from 'react';
import {Listing} from '@onroadvantage/onroadvantage-api';
import {useAppNotifications} from '../../../contexts';
import {TripSetupSteps} from './useTripSetup';
import {tripApi} from '../../../api';
import {prepareStops} from './helpers/prepareStops';
import {useHistory} from 'react-router-dom';
import {getLengthOfStops} from './helpers/getLengthOfStops';

export interface TripSetupFormStopOrder {
  orderNumber: string;
  upliftPointNode?: Listing;
  offloadPointNode?: Listing;
  product?: Listing;
  quantity?: number;
  deliveryNoteNumber?: string;
}

export interface TripSetupFormStop {
  sequence: number;
  node: Listing;
  orders?: TripSetupFormStopOrder[];
}

export interface TripSetupFormDetails {
  contract: Listing | null;
  vehicle: Listing | null;
  driver: Listing | null;
  tripStart: Date;
}

interface TripSetupContextProps {
  details: TripSetupFormDetails;
  orders: TripSetupFormStopOrder[];
  stops: TripSetupFormStop[];
  validSteps: {[key in TripSetupSteps]: {isValid: boolean; message: string}};
  setDetails: React.Dispatch<React.SetStateAction<TripSetupFormDetails>>;
  setOrders: React.Dispatch<React.SetStateAction<TripSetupFormStopOrder[]>>;
  setStops: React.Dispatch<React.SetStateAction<TripSetupFormStop[]>>;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
  editing: boolean;
  submitting: boolean;
  onSubmit: () => Promise<void>;
}

export const TripSetupContext =
  React.createContext<TripSetupContextProps | null>(null);

export const useTripSetupContext = () => {
  const tripSetupContext = React.useContext(TripSetupContext);

  if (tripSetupContext == null) {
    throw new Error(
      'useTripSetupContext has to be used within <TripSetupContext.Provider>'
    );
  }

  return tripSetupContext;
};

export const TripSetupContextProvider: React.FC = ({children}) => {
  const notify = useAppNotifications();
  const history = useHistory();
  const [details, setDetails] = React.useState<TripSetupFormDetails>({
    contract: null,
    vehicle: null,
    driver: null,
    tripStart: new Date(),
  });
  const [stops, setStops] = React.useState<TripSetupFormStop[]>([]);
  const [orders, setOrders] = React.useState<TripSetupFormStopOrder[]>([]);
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [editing, setEditing] = React.useState<boolean>(false);

  const handleSubmit = React.useCallback(async () => {
    setSubmitting(true);
    let masterTripId: number | undefined;
    try {
      const contractId = details.contract?.value;
      const driverId = details.driver?.value;
      const vehicleId = details.vehicle?.value;
      const tripStart = details.tripStart;
      if (contractId != null && tripStart) {
        const response = await tripApi.apiMasterTripPost({
          body: {
            contractId,
            tripStart,
            stops: prepareStops(stops),
            sendNotification: true,
            driverId,
            vehicleId,
          },
        });
        masterTripId = response.id;
        setDetails({
          contract: null,
          vehicle: null,
          driver: null,
          tripStart: new Date(),
        });
        setStops([]);
      }
    } catch (e) {
      notify('error', 'Failed to create trip');
    } finally {
      setSubmitting(false);

      if (masterTripId) {
        history.push(`/triplist/${masterTripId}`);
      }
    }
  }, [details, history, notify, stops]);

  const value = React.useMemo<TripSetupContextProps>(
    () => ({
      details,
      orders,
      stops,
      setDetails,
      setOrders,
      setStops,
      submitting,
      editing,
      setEditing,
      onSubmit: handleSubmit,
      validSteps: {
        details: {
          isValid: details.contract?.value != null && details.tripStart != null,
          message: 'Contract and trip start is required',
        },
        stops: {
          isValid: getLengthOfStops(stops) >= 2,
          message: 'A minimum of 2 stops are required',
        },
        summary: {isValid: true, message: 'N/A'},
      },
    }),
    [details, editing, handleSubmit, orders, stops, submitting]
  );

  return (
    <TripSetupContext.Provider value={value}>
      {children}
    </TripSetupContext.Provider>
  );
};
