import React from 'react';
import {
  ContractOperationalEventTypeConfig,
  ContractOperationalEventTypeConfigNodeType,
  ContractOperationalEventTypeReasonCode,
} from '@onroadvantage/onroadvantage-api';
import {contractOperationalEventTypeConfigApi} from '../../../api';
import {
  TOnFormikSubmit,
  TOnInlineAdd,
  TOnInlineEdit,
} from '../../../factory/template';
import {ContractOperationalEventTypeConfigDetailsFormSchema} from './ContractOperationalEventTypeConfigDetailsForm';
import {useAppNotifications} from '../../../contexts';
import {useHistory} from 'react-router-dom';

export interface ContractOperationalEventTypeConfigContextProps {
  loading: boolean;
  submitting: boolean;
  contractOperationalEventTypeConfigId: number | undefined;
  setContractOperationalEventTypeConfigId: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  contractOperationalEventTypeConfig:
    | ContractOperationalEventTypeConfig
    | undefined;
  setContractOperationalEventTypeConfig: React.Dispatch<
    React.SetStateAction<ContractOperationalEventTypeConfig | undefined>
  >;
  onDetailsSubmit: TOnFormikSubmit<ContractOperationalEventTypeConfigDetailsFormSchema>;
  onAddReasonCodes: TOnInlineAdd;
  onEditReasonCodes: TOnInlineEdit;
  onDeleteReasonCode: (
    row: ContractOperationalEventTypeReasonCode
  ) => Promise<void>;
  onAddNodeTypes: TOnInlineAdd;
  onDeleteNodeType: (
    row: ContractOperationalEventTypeConfigNodeType
  ) => Promise<void>;
  getNotificationConfig: (
    eventNotificationConfigId: number | undefined
  ) => string | undefined;
}

export const ContractOperationalEventTypeConfigContext =
  React.createContext<ContractOperationalEventTypeConfigContextProps | null>(
    null
  );

export const useContractOperationalEventTypeConfigContext = () => {
  const contractOperationalEventTypeConfigContext = React.useContext(
    ContractOperationalEventTypeConfigContext
  );
  if (contractOperationalEventTypeConfigContext == null) {
    throw new Error(
      'useContractOperationalEventTypeConfigContext has to be used within <ContractOperationalEventTypeConfigContext.Provider>'
    );
  }

  return contractOperationalEventTypeConfigContext;
};

export const ContractOperationalEventTypeConfigContextProvider: React.FC = ({
  children,
}) => {
  const notify = useAppNotifications();
  const history = useHistory();
  const [
    contractOperationalEventTypeConfig,
    setContractOperationalEventTypeConfig,
  ] = React.useState<ContractOperationalEventTypeConfig | undefined>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [
    contractOperationalEventTypeConfigId,
    setContractOperationalEventTypeConfigId,
  ] = React.useState<number | undefined>();

  const handleLoadContractOperationalEventTypeConfig =
    React.useCallback(async () => {
      setLoading(true);
      try {
        if (contractOperationalEventTypeConfigId != null) {
          const response =
            await contractOperationalEventTypeConfigApi.apiContractOperationalEventTypeConfigContractOperationalEventTypeConfigIdGet(
              {contractOperationalEventTypeConfigId}
            );
          if (response != null) {
            setContractOperationalEventTypeConfig(response);
          }
        }
      } catch (e) {
        notify(
          'error',
          'Failed to load contract operational event type config'
        );
      } finally {
        setLoading(false);
      }
    }, [contractOperationalEventTypeConfigId, notify]);

  const handleOnDetailsSubmit = React.useCallback<
    TOnFormikSubmit<ContractOperationalEventTypeConfigDetailsFormSchema>
  >(
    async (values, formikHelpers) => {
      setSubmitting(true);
      formikHelpers.setSubmitting(true);
      try {
        if (contractOperationalEventTypeConfigId != null) {
          const response =
            await contractOperationalEventTypeConfigApi.apiContractOperationalEventTypeConfigContractOperationalEventTypeConfigIdPatch(
              {
                contractOperationalEventTypeConfigId,
                body: {
                  contractId: values.contract.value ?? undefined,
                  operationalEventTypeId:
                    values.operationalEventType.value ?? undefined,
                  allowEventMuting: values.allowEventMuting,
                  allowEventRepetition: values.allowEventRepetition,
                  autoClose: values.autoClose,
                  enabled: values.enabled,
                  arrivalDelayThreshold:
                    values.arrivalDelayThreshold ?? undefined,
                  eventPostThreshold: values.eventPostThreshold ?? undefined,
                  eventPreThreshold: values.eventPreThreshold ?? undefined,
                  varianceAllowed: values.varianceAllowed ?? undefined,
                },
              }
            );
          notify('success', 'Updated contract operational event type config');
          setContractOperationalEventTypeConfig(response);
        } else {
          if (values.contract.value == null) {
            notify('warning', 'No contract specified');
            return;
          }
          if (values.operationalEventType.value == null) {
            notify('warning', 'No operational event type specified');
            return;
          }

          const response =
            await contractOperationalEventTypeConfigApi.apiContractOperationalEventTypeConfigPost(
              {
                body: {
                  contractId: values.contract.value,
                  operationalEventTypeId: values.operationalEventType.value,
                  allowEventMuting: values.allowEventMuting,
                  allowEventRepetition: values.allowEventRepetition,
                  autoClose: values.autoClose,
                  enabled: values.enabled,
                  arrivalDelayThreshold:
                    values.arrivalDelayThreshold ?? undefined,
                  eventPostThreshold: values.eventPostThreshold ?? undefined,
                  eventPreThreshold: values.eventPreThreshold ?? undefined,
                  varianceAllowed: values.varianceAllowed ?? undefined,
                },
              }
            );
          notify('success', 'Updated contract operational event type config');
          setContractOperationalEventTypeConfigId(response.id);
          setContractOperationalEventTypeConfig(response);
          history.push(`/contracteventtypeconfigs/operational/${response.id}`);
        }
      } catch (e) {
        notify(
          'error',
          'Failed to update contract operational event type config'
        );
      } finally {
        setSubmitting(false);
        formikHelpers.setSubmitting(false);
      }
    },
    [contractOperationalEventTypeConfigId, history, notify]
  );

  const handleOnAddReasonCodes = React.useCallback<TOnInlineAdd>(
    async (changes) => {
      setSubmitting(true);
      let response: ContractOperationalEventTypeConfig | null = null;
      try {
        if (contractOperationalEventTypeConfigId) {
          for (const change of changes) {
            try {
              if (
                'name' in change &&
                change.name != null &&
                change.name !== ''
              ) {
                response =
                  await contractOperationalEventTypeConfigApi.apiContractOperationalEventTypeConfigContractOperationalEventTypeConfigIdReasonCodePost(
                    {
                      contractOperationalEventTypeConfigId,
                      body: {
                        name: change.name,
                        description: change.description,
                      },
                    }
                  );
              }
            } catch (e) {
              notify('error', `Failed to add reason code ${change.name ?? ''}`);
            }
          }
        }
      } finally {
        if (response != null) {
          setContractOperationalEventTypeConfig(response);
        }
        setSubmitting(false);
      }
    },
    [contractOperationalEventTypeConfigId, notify]
  );

  const handleOnEditReasonCodes = React.useCallback<TOnInlineEdit>(
    async (changes) => {
      setSubmitting(true);
      const updatedReasonCodes: ContractOperationalEventTypeReasonCode[] = [];
      try {
        if (contractOperationalEventTypeConfigId) {
          for (const change of changes) {
            try {
              if (change.id) {
                const response =
                  await contractOperationalEventTypeConfigApi.apiContractOperationalEventTypeConfigContractOperationalEventTypeConfigIdReasonCodeReasonCodeIdPatch(
                    {
                      contractOperationalEventTypeConfigId,
                      reasonCodeId: parseInt(change.id),
                      body: {
                        name: change.newValues.name,
                        description: change.newValues.description,
                      },
                    }
                  );
                updatedReasonCodes.push(response);
              }
            } catch (e) {
              notify(
                'error',
                `Failed to update reason code ${change.newValues.name ?? ''}`
              );
            }
          }
        }
      } finally {
        if (updatedReasonCodes.length > 0) {
          setContractOperationalEventTypeConfig((prevState) =>
            prevState
              ? {
                  ...prevState,
                  reasonCodes: prevState?.reasonCodes?.map((reasonCode) => {
                    const updatedReasonCode = updatedReasonCodes.find(
                      ({id}) => id === reasonCode.id
                    );
                    if (updatedReasonCode) {
                      return updatedReasonCode;
                    }
                    return reasonCode;
                  }),
                }
              : undefined
          );
        }
        setSubmitting(false);
      }
    },
    [contractOperationalEventTypeConfigId, notify]
  );

  const handleOnDeleteReasonCode = React.useCallback(
    async (row: ContractOperationalEventTypeReasonCode) => {
      setSubmitting(true);
      try {
        if (contractOperationalEventTypeConfigId && row.id) {
          const response =
            await contractOperationalEventTypeConfigApi.apiContractOperationalEventTypeConfigContractOperationalEventTypeConfigIdReasonCodeReasonCodeIdDelete(
              {
                contractOperationalEventTypeConfigId,
                reasonCodeId: row.id,
              }
            );
          if (response) {
            setContractOperationalEventTypeConfig((prevState) =>
              prevState
                ? {
                    ...prevState,
                    reasonCodes: prevState.reasonCodes?.filter(
                      ({id}) => id !== row.id
                    ),
                  }
                : undefined
            );
            notify('success', 'Deleted reason code');
          }
        }
      } catch (e) {
        notify('error', 'Failed to delete reason code');
      } finally {
        setSubmitting(false);
      }
    },
    [contractOperationalEventTypeConfigId, notify]
  );

  const handleOnAddNodeTypes = React.useCallback<TOnInlineAdd>(
    async (changes) => {
      setSubmitting(true);
      let response: ContractOperationalEventTypeConfig | null = null;
      try {
        if (contractOperationalEventTypeConfigId) {
          let addedNodeTypes = false;
          for (const change of changes) {
            try {
              if (
                'nodeType' in change &&
                Array.isArray(change.nodeType) &&
                change.nodeType.length > 0
              ) {
                const nodeTypeIds = change.nodeType
                  .filter(
                    (nodeTypeListing) =>
                      'value' in nodeTypeListing &&
                      nodeTypeListing != null &&
                      typeof nodeTypeListing.value === 'number'
                  )
                  .map(({value}) => value as number);
                response =
                  await contractOperationalEventTypeConfigApi.apiContractOperationalEventTypeConfigContractOperationalEventTypeConfigIdNodeTypePost(
                    {
                      contractOperationalEventTypeConfigId,
                      body: {
                        nodeTypeIds,
                        triggerThreshold: change.triggerThreshold,
                      },
                    }
                  );
                addedNodeTypes = true;
              }
            } catch (e) {
              notify('error', 'Failed to add node types');
            }
          }
          if (addedNodeTypes) {
            notify('success', 'Added node types');
          }
        }
      } finally {
        if (response != null) {
          setContractOperationalEventTypeConfig(response);
        }
        setSubmitting(false);
      }
    },
    [contractOperationalEventTypeConfigId, notify]
  );

  const handleOnDeleteNodeType = React.useCallback(
    async (row: ContractOperationalEventTypeConfigNodeType) => {
      setSubmitting(true);
      try {
        if (contractOperationalEventTypeConfigId && row.nodeTypeId) {
          const response =
            await contractOperationalEventTypeConfigApi.apiContractOperationalEventTypeConfigContractOperationalEventTypeConfigIdNodeTypeNodeTypeIdDelete(
              {
                contractOperationalEventTypeConfigId,
                nodeTypeId: row.nodeTypeId,
              }
            );
          if (response) {
            setContractOperationalEventTypeConfig((prevState) =>
              prevState
                ? {
                    ...prevState,
                    nodeTypes: prevState.nodeTypes?.filter(
                      ({nodeTypeId}) => nodeTypeId !== row.nodeTypeId
                    ),
                  }
                : undefined
            );
            notify('success', 'Deleted node type');
          }
        }
      } catch (e) {
        notify('error', 'Failed to delete node type');
      } finally {
        setSubmitting(false);
      }
    },
    [contractOperationalEventTypeConfigId, notify]
  );

  const handleGetNotificationConfig = React.useCallback(
    (eventNotificationConfigId: number | undefined) => {
      return contractOperationalEventTypeConfig?.eventNotificationConfigs?.find(
        (eventNotificationConfig) =>
          eventNotificationConfig.id === eventNotificationConfigId
      )?.notificationConfig?.name;
    },
    [contractOperationalEventTypeConfig?.eventNotificationConfigs]
  );

  React.useEffect(() => {
    handleLoadContractOperationalEventTypeConfig();
    return () => {
      setContractOperationalEventTypeConfig(undefined);
    };
  }, [
    handleLoadContractOperationalEventTypeConfig,
    setContractOperationalEventTypeConfig,
  ]);

  return (
    <ContractOperationalEventTypeConfigContext.Provider
      value={{
        loading,
        submitting,
        contractOperationalEventTypeConfig,
        setContractOperationalEventTypeConfig,
        contractOperationalEventTypeConfigId,
        setContractOperationalEventTypeConfigId,
        onDetailsSubmit: handleOnDetailsSubmit,
        onAddReasonCodes: handleOnAddReasonCodes,
        onEditReasonCodes: handleOnEditReasonCodes,
        onDeleteReasonCode: handleOnDeleteReasonCode,
        onAddNodeTypes: handleOnAddNodeTypes,
        onDeleteNodeType: handleOnDeleteNodeType,
        getNotificationConfig: handleGetNotificationConfig,
      }}
    >
      {children}
    </ContractOperationalEventTypeConfigContext.Provider>
  );
};
