import React from 'react';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Collapse,
  Stack,
  CardContentProps,
} from '@mui/material';
import {Comment, ExpandMore, Refresh} from '@mui/icons-material';
import clsx from 'clsx';
import {useTripCardStyles} from './TripCard.style';
import {Loader} from '../../loader';
import {IPermission} from '../../../factory/template';
import {RoleService} from '../../../service';

interface TripCardProps {
  title: string;
  permission?: IPermission;
  cardContentProps?: CardContentProps;
  loading?: boolean;
  loadingText?: string;
  gutters?: boolean;
  avatar?: React.ReactNode;
  action?: React.ReactNode;
  expandedByDefault?: boolean;
  onLoad?: () => Promise<void>;
  onReload?: () => Promise<void>;
}

export const TripCard: React.FC<TripCardProps> = ({
  title,
  permission,
  loading,
  loadingText,
  gutters,
  avatar,
  action,
  cardContentProps,
  expandedByDefault,
  onLoad,
  onReload,
  children,
}) => {
  const classes = useTripCardStyles();
  const [expanded, setExpanded] = React.useState<boolean>(!!expandedByDefault);

  const handleExpandToggle = React.useCallback(() => {
    setExpanded((prevExpanded) => !prevExpanded);
  }, []);

  const handlePreventToggle = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation(),
    []
  );

  React.useEffect(() => {
    setExpanded(!!expandedByDefault);
  }, [expandedByDefault]);

  /** Card permission check */
  if (
    permission &&
    !RoleService.hasPermission(permission.name, permission.type)
  ) {
    return null;
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        className={classes.header}
        title={title}
        avatar={<Avatar aria-label={title}>{avatar ?? <Comment />}</Avatar>}
        onClick={handleExpandToggle}
        action={
          <Stack
            direction="row"
            alignItems="center"
            spacing={1.5}
            onClick={handlePreventToggle}
            className={classes.actions}
          >
            {action}
            {onReload ? (
              <IconButton disabled={loading} onClick={onReload}>
                {loading ? <Loader size={24} /> : <Refresh />}
              </IconButton>
            ) : null}
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              aria-expanded={expanded}
              aria-label="Show more"
              size="large"
              onClick={handleExpandToggle}
            >
              <ExpandMore />
            </IconButton>
          </Stack>
        }
      />
      <Collapse in={expanded} mountOnEnter={!!onLoad}>
        <TripCardExpandableContent
          onLoad={onLoad}
          gutters={gutters}
          cardContentProps={cardContentProps}
        >
          {loading ? <Loader text={loadingText} /> : children}
        </TripCardExpandableContent>
      </Collapse>
    </Card>
  );
};

interface TripCardContentProps {
  gutters?: boolean;
  cardContentProps?: CardContentProps;
  onLoad?: () => Promise<void>;
}
export const TripCardExpandableContent: React.FC<TripCardContentProps> = ({
  gutters,
  onLoad,
  cardContentProps,
  children,
}) => {
  const classes = useTripCardStyles();

  const handleLoad = React.useCallback(async () => {
    if (onLoad) {
      await onLoad();
    }
  }, [onLoad]);

  React.useEffect(() => {
    handleLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CardContent
      className={clsx(classes.content, {[classes.contentGutters]: gutters})}
      {...cardContentProps}
    >
      {children}
    </CardContent>
  );
};
