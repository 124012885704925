import React from 'react';
import {RoleForm} from './RoleForm';
import {ITemplateTabControllerTab, TemplateTabs} from '../../factory/template';
import {RoleContext} from './RoleContext';
import {RolePermissions} from './RolePermissions';
import {RoleReports} from './RoleReports';
import {RoleDashboards} from './RoleDashboards';
import {RoleUserList} from './roleUserList';
import {RoleEventTypes} from './roleEventTypes';

export const Role: React.FC = () => {
  const {roleId, loading} = React.useContext(RoleContext);

  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {title: 'Details', element: <RoleForm />},
    {title: 'Users', element: <RoleUserList />},
    {title: 'Permissions', element: <RolePermissions />},
    {title: 'Reports', element: <RoleReports />},
    {title: 'Dashboards', element: <RoleDashboards />},
    {title: 'Hide Event Types', element: <RoleEventTypes />},
  ]);

  const path = `/rolelist/${roleId}`;

  return <TemplateTabs tabs={tabs} path={path} loading={loading} />;
};
