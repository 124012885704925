import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Paper from '@mui/material/Paper';
import {useTemplateControllerStyles} from './TemplateController.style';
import {
  BreadcrumbController,
  BreadcrumbRoute,
} from '../../../components/breadcrumbs/BreadcrumbController';
import {AllowedRoute} from '../../../components/router';

export interface ITemplateControllerRoute
  extends Omit<BreadcrumbRoute, 'path'> {
  component: React.ComponentType;
  IdHandler?: React.ComponentType;
  path?: string | undefined;
  name?: string;
  type?: string;
}

export interface TemplateControllerProps {
  elevation?: number;
  disableBreadcrumbs?: boolean;
  rootPath: string;
  routes: ITemplateControllerRoute[];
}

export const TemplateController: React.FC<TemplateControllerProps> = (
  props
) => {
  /**
   * Since the rootPath is passed directly to Controller. It is not necessary to pass the rootPath (e.g. '/transporterlist') to each route
   */
  const {elevation, rootPath, routes, disableBreadcrumbs} = props;
  const classes = useTemplateControllerStyles();
  const [idHandlerRoutes] = React.useState(
    routes.filter((route) => route.IdHandler)
  );
  /**
   * The idHandlerRoutes (e.g. '/transporterlist/:transporterId' without exact and outside Switch) is determined by whether a idHandler component is passed on a route
   */
  const [rootRoute] = React.useState(routes.find((route) => !route.path));
  /**
   * The rootRoute (e.g. '/transporterlist' with exact and outside Switch) is determined by whether a path is not passed on a route
   */
  const [subRoutes] = React.useState(routes.filter((route) => route.path));
  /**
   * The subRoutes (e.g. '/transporterlist/:transporterId' with exact and inside Switch) is determined by whether a path is passed on a route
   */
  const breadcrumbRoutes = React.useMemo(
    () =>
      routes.map((route) => ({
        ...route,
        path: rootPath.concat(route.path ?? ''),
        title: route.title,
        matchOptions: route.matchOptions,
      })),
    [routes, rootPath]
  );
  /**
   * The breadcrumbRoutes uses a useMemo hook, because the route. title could potentially update when loading. The breadcrumbRoutes that are passed to the BreadcrumbController maps over the provided routes to add the externally passed rootPath and concat the route's path if it exists
   */
  return (
    <div className={classes.root}>
      {!disableBreadcrumbs && (
        <BreadcrumbController routes={breadcrumbRoutes} />
      )}
      <Paper elevation={elevation} className={classes.container}>
        {rootRoute && (
          <Route
            exact
            path={rootPath}
            component={rootRoute.component}
            {...rootRoute.matchOptions}
          />
        )}
        {idHandlerRoutes?.map((idHandlerRoute) => (
          <Route
            key={idHandlerRoute.path}
            path={rootPath + idHandlerRoute.path}
            component={idHandlerRoute.IdHandler}
            {...idHandlerRoute.matchOptions}
          />
        ))}
        <Switch>
          {subRoutes.map((route) =>
            route.name ? (
              <AllowedRoute
                exact
                key={route.path}
                path={rootPath + route.path}
                component={route.component}
                name={route.name}
                type={route.type}
                {...route.matchOptions}
              />
            ) : (
              <Route
                exact
                key={route.path}
                path={rootPath + route.path}
                component={route.component}
                {...route.matchOptions}
              />
            )
          )}
        </Switch>
      </Paper>
    </div>
  );
};
