import React from 'react';
import {
  listingSchema,
  TemplateCard,
  TemplateForm,
} from '../../factory/template';
import {
  DocumentTemplateContext,
  IDocumentTemplateWithTemplateHtml,
} from './DocumentTemplateContext';
import {Listing} from '@onroadvantage/onroadvantage-api';
import * as Yup from 'yup';
import {
  FormikAutocompleteHooks,
  FormikAutocompleteWithListing,
  FormikTextField,
} from '../formik';
import {FormikTemplateEditor} from '../formik';

export interface IDocumentTemplateForm {
  contract: Listing;
  customer: Listing;
  documentType: Listing;
  name: string;
  templateHtml?: string | null;
}

const schema: Yup.SchemaOf<IDocumentTemplateForm> = Yup.object({
  contract: listingSchema.nullable().required('Required'),
  customer: listingSchema.nullable().required('Required'),
  documentType: listingSchema.nullable().required('Required'),
  name: Yup.string().required('Required'),
  templateHtml: Yup.string(),
});

const documentTypeOptions: Listing[] = [
  {value: 0, label: 'PoD'},
  {value: 1, label: 'Event Report'},
  {value: 2, label: 'Vehicle Inspection'},
  {value: 3, label: 'Workflow'},
  {value: 4, label: 'Event Incident'},
];

const getInitialValues = (
  documentTemplate?: IDocumentTemplateWithTemplateHtml | null
): IDocumentTemplateForm | undefined => {
  if (documentTemplate) {
    return {
      contract: {
        value: documentTemplate.contract?.id,
        label: documentTemplate.contract?.name,
      },
      customer: {
        value: documentTemplate.customer?.id,
        label: documentTemplate.customer?.name,
      },
      documentType: {
        value: documentTypeOptions.find(
          ({label}) => label === documentTemplate.documentType
        )?.value,
        label: documentTemplate.documentType,
      },
      name: documentTemplate.name,
      templateHtml: documentTemplate.templateHtml,
    };
  }
  return undefined;
};

export const DocumentTemplateForm: React.FC<{isAdd?: boolean}> = ({isAdd}) => {
  const {
    detailsRef,
    loading,
    submitting,
    documentTemplate,
    onDetailsFormSubmit,
  } = React.useContext(DocumentTemplateContext);
  const [initialValues, setInitialValues] =
    React.useState<IDocumentTemplateForm>();

  React.useEffect(() => {
    const values = getInitialValues(documentTemplate);

    if (values) {
      setInitialValues(values);
    } else if (isAdd) {
      setInitialValues({
        contract: {},
        customer: {},
        documentType: {},
        name: '',
      });
    }
  }, [isAdd, documentTemplate, setInitialValues]);

  return (
    <TemplateCard
      title={`Document Template Details ${isAdd ? 'Add' : 'Edit'}`}
      loading={loading}
    >
      <TemplateForm<IDocumentTemplateForm>
        initialValues={initialValues}
        onSubmit={onDetailsFormSubmit}
        permission={{
          name: `${isAdd ? 'Add' : 'Edit'} DocumentTemplate`,
          type: isAdd ? 'Add' : 'Edit',
        }}
        submitting={submitting}
        innerRef={detailsRef}
        validationSchema={schema}
      >
        <FormikTextField
          name="name"
          placeholder="Enter the document template name"
          label="Name"
          fullWidth
        />
        <FormikAutocompleteWithListing
          name="contract"
          placeholder="Enter and select the document template contract"
          label="Contract"
          model="Contract"
        />
        <FormikAutocompleteWithListing
          name="customer"
          placeholder="Enter and select the document template customer"
          label="Customer"
          model="Customer"
        />
        <FormikAutocompleteHooks
          name="documentType"
          placeholder="Enter and select the document template type"
          label="DocumentType"
          options={documentTypeOptions}
        />
        <FormikTemplateEditor
          label="Template Html"
          name="templateHtml"
          maxHeight={900}
        />
      </TemplateForm>
    </TemplateCard>
  );
};
