import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from '@mui/material';

const EventsPaneEmptyListStyles = (theme: Theme) =>
  createStyles({
    divider: {marginTop: theme.spacing(2), marginBottom: theme.spacing(2)},
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: theme.spacing(1.5),
      marginTop: theme.spacing(3),
      backgroundColor: theme.palette.background.paper,
    },
    error: {backgroundColor: '#fcd2d2'},
  });

export const useEventsPaneEmptyListStyles = makeStyles(
  EventsPaneEmptyListStyles,
  {name: 'EventsPaneEmptyListStyles'}
);
