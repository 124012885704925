import {DateTime} from 'luxon';

type TATTime = Date | string | null | undefined;

interface stopActualTATTimes {
  gpsArrivalTime?: TATTime;
  gpsDepartureTime?: TATTime;
  mobileArrivalTime?: TATTime;
  mobileDepartureTime?: TATTime;
}

export const getStopActualTAT = (
  times: stopActualTATTimes | undefined | null,
  priority: string | null | undefined
) => {
  if (!times) return '-';

  const {
    gpsDepartureTime,
    gpsArrivalTime,
    mobileDepartureTime,
    mobileArrivalTime,
  } = times;

  let endDateTime: DateTime | undefined;

  let startDateTime: DateTime | undefined;

  if (priority === 'mobile' && mobileDepartureTime && mobileArrivalTime) {
    endDateTime = DateTime.fromJSDate(new Date(mobileDepartureTime));
    startDateTime = DateTime.fromJSDate(new Date(mobileArrivalTime));
  } else if (priority === 'gps' && gpsDepartureTime && gpsArrivalTime) {
    endDateTime = DateTime.fromJSDate(new Date(gpsDepartureTime));
    startDateTime = DateTime.fromJSDate(new Date(gpsArrivalTime));
  }

  if (endDateTime && startDateTime) {
    return Math.floor(
      endDateTime.diff(startDateTime).shiftTo('minutes').minutes
    );
  }

  return '-';
};
