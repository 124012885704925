import React from 'react';
import {useHistory} from 'react-router-dom';
import {
  WebSpeedTableList,
  WebSpeedTableListResponse,
  ApiWebSpeedTableGetRequest,
} from '@onroadvantage/onroadvantage-api';
import {speedTableApi, webSpeedTableApi} from '../../../api';
import {
  TemplateTableContextProps,
  TLoadList,
  useTemplateTable,
} from '../../../factory/template';
import {useAppNotifications} from '../../../contexts';

export type SpeedTableListContextProps = TemplateTableContextProps<
  WebSpeedTableList,
  WebSpeedTableListResponse
>;

export const SpeedTableListContext =
  React.createContext<SpeedTableListContextProps>({
    loading: false,
    list: [],
    currentPage: 1,
    loadList: () => null,
  });

export const useSpeedTableListContext = () =>
  React.useContext(SpeedTableListContext);

export const SpeedTableListContextProvider: React.FC = ({children}) => {
  const history = useHistory();
  const notify = useAppNotifications();
  const [
    {
      // States
      currentPage,
      filters,
      hasPermission,
      itemTotal,
      list,
      loading,
      pageSize,
      pageTotal,
      sorting,
    },
    {
      // Getters
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
    },
  ] = useTemplateTable<WebSpeedTableList, ApiWebSpeedTableGetRequest>({
    viewPermission: 'SpeedTable List',
    editPermission: 'Edit SpeedTable',
    addPermission: 'Add SpeedTable',
    deletePermission: 'Delete SpeedTable',
  });

  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const loadList = React.useCallback<TLoadList<WebSpeedTableListResponse>>(
    async (options) => {
      setLoading(true);
      try {
        const requestObj = getRequestObj(
          ['name', 'contractCode', 'vehicleTypeName'],
          options
        );
        const response = await webSpeedTableApi.apiWebSpeedTableGet(requestObj);
        return getResponse(response, options);
      } catch (e) {
        notify('error', 'Failed to load speed tables');
      } finally {
        setLoading(false);
      }
    },
    [setLoading, getRequestObj, getResponse, notify]
  );

  const handleDelete = React.useCallback(
    async (row: WebSpeedTableList) => {
      setSubmitting(true);
      try {
        if (row.id) {
          await speedTableApi.apiSpeedTableSpeedTableIdDelete({
            speedTableId: row.id,
          });
          notify('success', 'Deleted speed table');
          await loadList();
        }
      } catch (e) {
        notify('error', 'Failed to delete speed table');
      } finally {
        setSubmitting(false);
      }
    },
    [loadList, notify]
  );

  const handleAdd = React.useCallback(() => {
    history.push('/speedtablelist/add');
  }, [history]);

  const handleNavigate = React.useCallback(
    (row: WebSpeedTableList) => {
      if (row.id) {
        history.push(`/speedtablelist/${row.id}`);
      }
    },
    [history]
  );

  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  const value: SpeedTableListContextProps = {
    loadList,
    cleanupList,
    hasPermission,
    loading: submitting || loading,
    list,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onAdd: hasPermission.add ? handleAdd : undefined,
    onNavigate: handleNavigate,
    onDelete: hasPermission.edit ? handleDelete : undefined,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onRefresh: handleRefresh,
  };

  return (
    <SpeedTableListContext.Provider value={value}>
      {children}
    </SpeedTableListContext.Provider>
  );
};
