import React from 'react';
import {NotificationConfigOperationalEscalationContext} from './NotificationConfigOperationalEscalationContext';
import {useParams} from 'react-router-dom';

interface IParams {
  notificationConfigEventEscalationId?: string;
}

export const NotificationConfigOperationalEscalationIdHandler: React.FC =
  () => {
    const {setNotificationConfigEventEscalationId} = React.useContext(
      NotificationConfigOperationalEscalationContext
    );

    const {notificationConfigEventEscalationId} = useParams<IParams>();

    React.useEffect(() => {
      const parsedId = notificationConfigEventEscalationId
        ? parseInt(notificationConfigEventEscalationId)
        : undefined;
      setNotificationConfigEventEscalationId(parsedId);
      return () => setNotificationConfigEventEscalationId(undefined);
    }, [
      notificationConfigEventEscalationId,
      setNotificationConfigEventEscalationId,
    ]);

    return null;
  };
