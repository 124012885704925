import React from 'react';
import {recipientApi} from '../../../api';
import {MasterTripRecipient} from '@onroadvantage/onroadvantage-api';
import moment from 'moment';

const refreshTimeFormat = '(HH:mm:ss): MMM, DD, YYYY';

export interface IRecipientCardDetail {
  label: string;
  value?: string;
}

export interface RecipientContextProps {
  masterTrip: MasterTripRecipient | null;
  refreshTime: string;
  loading: boolean;
  invalid: boolean;
  loadRecipients: () => Promise<void>;
}

export const MasterTripRecipientContext =
  React.createContext<RecipientContextProps>({
    loading: true,
    invalid: false,
    masterTrip: null,
    refreshTime: moment().format(refreshTimeFormat),
    loadRecipients: async () => {},
  });

interface RecipientContextProviderProps {
  uuid: string;
}

export const RecipientContextProvider: React.FC<
  RecipientContextProviderProps
> = ({uuid, children}) => {
  const [refreshTime, setRefreshTime] = React.useState(
    moment().format(refreshTimeFormat)
  );
  const [invalid, setInvalid] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [masterTrip, setMasterTrip] =
    React.useState<MasterTripRecipient | null>(null);
  const loadRecipients = React.useCallback(async () => {
    setLoading(true);
    try {
      const response = await recipientApi.apiMasterTripRecipientUuidGet({
        uuid: uuid,
      });
      if (response && response.trip) {
        setInvalid(false);
        setMasterTrip(response);
      } else {
        setInvalid(true);
      }
    } catch (e) {
      setInvalid(true);
    } finally {
      setLoading(false);
    }
  }, [uuid]);

  React.useEffect(() => {
    setRefreshTime(moment().format(refreshTimeFormat));
    loadRecipients();
    return () => setRefreshTime('');
  }, [loadRecipients]);

  const value: RecipientContextProps = {
    loading,
    loadRecipients,
    refreshTime,
    invalid,
    masterTrip,
  };

  return (
    <MasterTripRecipientContext.Provider value={value}>
      {children}
    </MasterTripRecipientContext.Provider>
  );
};
