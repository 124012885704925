import React from 'react';
import {Button, Divider, Stack, Typography} from '@mui/material';
import {Launch} from '@mui/icons-material';
import {DateTime} from 'luxon';
import {usePlanningBoardTripContext} from './planningBoardTripContext';
import {DateTimeFormatService} from '../../../../service/FormatService';

export const PlanningBoardTripSummary: React.FC = () => {
  const {masterTrip, masterTripType} = usePlanningBoardTripContext();

  const tripDetails = React.useMemo(() => {
    let distance: string | undefined;
    let duration: string | undefined;
    if (masterTrip?.trip) {
      distance = `${
        masterTrip.trip.tripDistance
          ? Math.round((masterTrip.trip.tripDistance + Number.EPSILON) * 100) /
            100
          : '-'
      }km`;
      if (masterTrip.trip.tripEnd && masterTrip.trip.tripStart) {
        const tripDuration = DateTime.fromJSDate(masterTrip.trip.tripEnd)
          .diff(DateTime.fromJSDate(masterTrip.trip.tripStart))
          .shiftTo('hours', 'minutes', 'seconds');
        duration = `${tripDuration.hours
          .toString()
          .padStart(2, '0')}:${tripDuration.minutes
          .toString()
          .padStart(2, '0')}:${Math.floor(tripDuration.seconds)
          .toString()
          .padStart(2, '0')}`;
      }
    }

    return {
      distance,
      duration,
      createdAt: masterTrip?.createdAt
        ? new DateTimeFormatService().formatDateTime(masterTrip.createdAt)
        : undefined,
      createdBy: masterTrip?.createdBy?.email,
    };
  }, [masterTrip]);

  return (
    <Stack pt={2} spacing={2}>
      <Stack spacing={1}>
        <Stack direction="row" spacing={2}>
          <Typography variant="h4" fontWeight={600}>
            Trip Distance:
          </Typography>
          <Typography variant="h4" fontWeight={400}>
            {tripDetails.distance}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
          <Typography variant="h4" fontWeight={600}>
            Trip Duration:
          </Typography>
          <Typography variant="h4" fontWeight={400}>
            {tripDetails.duration}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
          <Typography variant="h4" fontWeight={600}>
            Created At:
          </Typography>
          <Typography variant="h4" fontWeight={400}>
            {tripDetails.createdAt}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
          <Typography variant="h4" fontWeight={600}>
            Created By:
          </Typography>
          <Typography variant="h4" fontWeight={400}>
            {tripDetails.createdBy}
          </Typography>
        </Stack>
      </Stack>
      {masterTripType === 'master' ? (
        <Button
          variant="contained"
          endIcon={<Launch />}
          href={`/triplist/${masterTrip?.id}`}
          target="_blank"
          fullWidth
        >
          {masterTrip?.trip?.tripNumber}
        </Button>
      ) : null}
      <Divider />
    </Stack>
  );
};
