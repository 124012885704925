import React from 'react';
import {DashboardContext} from './DashboardContext';
import {useParams} from 'react-router-dom';

interface IParams {
  dashboardId?: string;
}

export const DashboardIdHandler: React.FC = () => {
  const {setDashboardId} = React.useContext(DashboardContext);

  const {dashboardId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = dashboardId ? parseInt(dashboardId) : undefined;
    setDashboardId(parsedId);
    return () => setDashboardId(undefined);
  }, [dashboardId, setDashboardId]);

  return null;
};
