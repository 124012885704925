import React from 'react';
import {
  TemplateTable,
  TemplateTableConfig,
} from '../../../../../factory/template';
import {taskApi} from '../../../../../api';
import {parseInt} from 'lodash';
import {Loader} from '../../../../loader';
import {
  MasterTripDebriefSnapshot,
  MasterTripDebriefTaskSnapshot,
  MasterTripDebriefTripStopSnapshot,
} from '@onroadvantage/onroadvantage-api';
import {Card, CardContent} from '@mui/material';
import {TripDebriefSummaryStopTaskListActions} from './TripDebriefSummaryStopTaskListActions';
import {TripDebriefSummaryStopTaskListModal} from './TripDebriefSummaryStopTaskListModal';
import {getImgTags} from '../../../../../service/Util';
import {TripDebriefSummaryStopTaskListPayload} from './TripDebriefSummaryStopTaskListPayload';
import {useTripDebriefSummaryStopTaskListStyles} from './TripDebriefSummaryStopTaskList.style';
import {RoleService} from '../../../../../service';
import {TripDebriefContext} from '../../../TripDebriefContext';
import {useAppNotifications} from '../../../../../contexts';

export const TripDebriefSummaryStopTaskList: React.FC = () => {
  const notify = useAppNotifications();
  const classes = useTripDebriefSummaryStopTaskListStyles();
  const {tripStop, masterTripDebriefData} =
    React.useContext(TripDebriefContext);
  /**
   *  Destructure snapshotData, need to add || ({} as MasterTripDebriefSnapshot) for typescript, since
   *  snapshotData is nullable.
   */
  const {tasks} =
    masterTripDebriefData?.snapshotData || ({} as MasterTripDebriefSnapshot);
  /**
   *  Destructure tripStop, need to add || ({} as MasterTripDebriefSnapshot) for typescript, since
   *  tripStop is nullable.
   */
  const {tasks: stopTaskIds} =
    tripStop || ({} as MasterTripDebriefTripStopSnapshot);
  const {hasPermission} = RoleService;
  const [singleLoading, setSingleLoading] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState<boolean>(false);
  const [modalType, setModalType] = React.useState<string | undefined>();
  const [modalContent, setModalContent] = React.useState<any>();
  const [modalLoading, setModalLoading] = React.useState<boolean>(false);
  const [isRendered, setIsRendered] = React.useState<boolean>(false);

  const handleModalClose = () => {
    setOpen(false);
    setModalType(undefined);
    setModalContent(undefined);
    setModalLoading(false);
  };

  const mappedTasks: MasterTripDebriefTaskSnapshot[] = React.useMemo(() => {
    const stopTasks: MasterTripDebriefTaskSnapshot[] = [];

    /**
     * Need to loop over all the stop's taskIds, which has the taskIds relevant to the stop. We then use
     * the stopTaskId to find the relevant task from the tasks list on the root of the snapshot.
     */
    stopTaskIds?.forEach(({id: stopTaskId}) => {
      const task = tasks?.find(({id}) => id === stopTaskId);
      if (task) {
        stopTasks.push(task);
      }
    });

    return stopTasks;
  }, [stopTaskIds, tasks]);

  const [taskList, setTaskList] = React.useState<
    MasterTripDebriefTaskSnapshot[]
  >(
    mappedTasks?.sort((a, b) =>
      a.contractTaskTemplate?.sequence && b.contractTaskTemplate?.sequence
        ? a.contractTaskTemplate.sequence - b.contractTaskTemplate.sequence
        : 0
    ) ?? []
  );

  const handlePayloadPress = React.useCallback(
    async (type, value?: string | null) => {
      setOpen(true);
      setModalLoading(true);
      try {
        setModalType(type);
        if (type === 'Checklist' || type === 'Questionnaire')
          setModalContent(value);
        else setModalContent(await getImgTags({value, type}));
      } finally {
        setModalLoading(false);
      }
    },
    []
  );

  const [config] = React.useState<
    TemplateTableConfig<MasterTripDebriefTaskSnapshot>
  >({
    columns: [
      {
        name: 'id',
        label: 'ID',
        type: 'number',
        enableEditing: false,
      },
      {
        name: 'template.name',
        label: 'Task Name',
        type: 'string',
        enableEditing: false,
        getValue: ({template}) => template?.name,
      },
      {
        name: 'templateTypeName',
        label: 'Task Type',
        type: 'string',
        enableEditing: false,
        getValue: ({template}) => template?.type?.name,
      },
      {
        name: 'status',
        label: 'Task Status',
        type: 'string',
        enableEditing: false,
      },
      {
        name: 'payload',
        label: 'Value',
        type: 'string',
        getValue: (value) =>
          value.template?.type?.name === 'Number Input' ? (
            value.payload
          ) : (
            <TripDebriefSummaryStopTaskListPayload
              row={value}
              value={value.payload}
              onPress={handlePayloadPress}
            />
          ),
        enableEditing: (value) =>
          value?.template?.type?.name === 'Number Input' &&
          hasPermission('Edit Task', 'Edit'),
      },
      {
        name: 'eventDate',
        label: 'Event Date',
        type: 'datetime',
        enableEditing: false,
      },
      {
        name: 'latitude',
        label: 'Latitude',
        type: 'number',
        enableEditing: false,
      },
      {
        name: 'longitude',
        label: 'Longitude',
        type: 'number',
        enableEditing: false,
      },
    ],
    disableToolbar: true,
    disablePagination: true,
  });

  const handleInlineEdit = React.useCallback(
    (
      changes?: {
        id: string;
        newValues: Partial<MasterTripDebriefTaskSnapshot>;
      }[]
    ) => {
      setSingleLoading(true);
      changes?.forEach(async (change) => {
        try {
          const changedValues = {
            ...(change.newValues.eventDate && {
              eventDate: new Date(change.newValues.eventDate),
            }),
            ...(change.newValues.payload && {
              payload: change.newValues.payload,
            }),
          };
          if (changedValues.payload || changedValues.eventDate) {
            await taskApi.apiTaskTaskIdPatch({
              taskId: parseInt(change.id),
              body: changedValues,
            });
            notify('success', 'Order Line Edited');
            setTaskList((prevTaskList) =>
              [
                ...prevTaskList.filter(
                  (task) => task.id !== parseInt(change.id)
                ),
                {
                  ...prevTaskList.find(
                    (task) => task.id === parseInt(change.id)
                  ),
                  ...changedValues,
                },
              ].sort((a, b) =>
                a.contractTaskTemplate?.sequence &&
                b.contractTaskTemplate?.sequence
                  ? a.contractTaskTemplate.sequence -
                    b.contractTaskTemplate.sequence
                  : 0
              )
            );
          }
        } finally {
          setSingleLoading(false);
        }
      });
    },
    [notify]
  );

  React.useEffect(() => {
    setTaskList(
      mappedTasks?.sort((a, b) =>
        a.contractTaskTemplate?.sequence && b.contractTaskTemplate?.sequence
          ? a.contractTaskTemplate.sequence - b.contractTaskTemplate.sequence
          : 0
      ) ?? []
    );
    setIsRendered(true);
    return () => {
      setTaskList([]);
      setIsRendered(false);
    };
  }, [mappedTasks]);

  if (singleLoading || !isRendered) return <Loader />;

  if (!tripStop) return null;

  return (
    <Card className={classes.card} square>
      <CardContent className={classes.cardContent}>
        <TemplateTable
          config={config}
          currentPage={1}
          elevation={0}
          list={taskList}
          onEnableRowEdit={(row) =>
            row?.template?.type?.name === 'Number Input'
          }
          onInlineEdit={handleInlineEdit}
        />
        <TripDebriefSummaryStopTaskListActions />
        <TripDebriefSummaryStopTaskListModal
          open={open}
          onClose={handleModalClose}
          content={modalContent}
          loading={modalLoading}
          type={modalType}
        />
      </CardContent>
    </Card>
  );
};
