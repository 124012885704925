import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

const VehicleBookingAvailabilityFormStyles = (theme: Theme) =>
  createStyles({
    header: {paddingBottom: 0},
    root: {marginBottom: theme.spacing(2)},
  });

export const useVehicleBookingAvailabilityFormStyles = makeStyles(
  VehicleBookingAvailabilityFormStyles,
  {name: 'VehicleBookingAvailabilityFormStyles'}
);
