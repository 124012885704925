import React from 'react';
import {
  MasterTripDebriefContractSnapshot,
  MasterTripDebriefSnapshot,
  MasterTripDebriefTripStopSnapshot,
} from '@onroadvantage/onroadvantage-api';
import {Assignment} from '@mui/icons-material';
import {
  TemplateCard,
  TemplateTable,
  TemplateTableConfig,
} from '../../../factory/template';
import {TripDebriefContext} from '../TripDebriefContext';
import {Chip} from '@mui/material';
import {getStopActualTAT, getStopPlannedTAT} from '../../../utils';
import {TripDebriefTaskList} from './TripDebriefTaskList';

export const TripDebriefStopTaskList: React.FC = () => {
  const {loading, masterTripDebriefData} = React.useContext(TripDebriefContext);
  /**
   *  Destructure snapshotData, need to add || ({} as MasterTripDebriefSnapshot) for typescript, since
   *  snapshotData is nullable.
   */
  const {stops, contract} =
    masterTripDebriefData?.snapshotData || ({} as MasterTripDebriefSnapshot);

  /**
   *  Destructure contract, need to add || ({} as MasterTripDebriefContractSnapshot) for typescript, since
   *  contract is nullable.
   */
  const {tripStopTimestampPriority} =
    contract || ({} as MasterTripDebriefContractSnapshot);

  const config: TemplateTableConfig<MasterTripDebriefTripStopSnapshot> =
    React.useMemo(
      () => ({
        columns: [
          {
            name: 'stop_type',
            label: 'Stop Type ',
            type: 'string',
            getValue: ({taskTemplateNodeType}) => (
              <Chip label={taskTemplateNodeType ?? '-'} />
            ),
          },
          {
            name: 'node.name',
            label: 'Site Name',
            type: 'string',
            getValue: ({node}) => node?.name,
          },
          {
            name: 'node.externalReference',
            label: 'External Reference',
            type: 'string',
            getValue: ({node}) => node?.externalReference,
          },
          {name: 'sequence', label: 'Sequence', type: 'number'},
          {
            name: 'arrivalTime',
            label: 'Planned Arrival Time',
            type: 'datetime',
          },
          {
            name: 'departureTime',
            label: 'Planned Departure Time',
            type: 'datetime',
          },

          {
            name: 'plannedTAT',
            label: 'Planned TAT',
            type: 'string',
            getValue: (row) => getStopPlannedTAT(row),
          },
          {
            name: 'actual.arrivalTime',
            label: 'Geofence Arrival Time',
            type: 'datetime',
            getValue: ({tripStopExecution}) =>
              !tripStopExecution
                ? null
                : tripStopTimestampPriority === 'mobile'
                ? tripStopExecution.mobileArrivalTime
                : tripStopExecution.gpsArrivalTime,
          },
          {
            name: 'actual.departureTime',
            label: 'Geofence Departure Time',
            type: 'datetime',
            getValue: ({tripStopExecution}) =>
              !tripStopExecution
                ? null
                : tripStopTimestampPriority === 'mobile'
                ? tripStopExecution.mobileDepartureTime
                : tripStopExecution.gpsDepartureTime,
          },
          {
            name: 'actualTAT',
            label: 'Geofence TAT',
            type: 'datetime',
            getValue: ({tripStopExecution}) =>
              getStopActualTAT(tripStopExecution, tripStopTimestampPriority),
          },
        ],
        disablePagination: true,
        disableToolbar: true,
        identifier: 'TRIP_DEBRIEF_STOP_TASK_LIST',
      }),
      [tripStopTimestampPriority]
    );

  const list: MasterTripDebriefTripStopSnapshot[] = React.useMemo(
    () => stops ?? [],
    [stops]
  );

  return (
    <TemplateCard
      title="Trip Tasks"
      avatar={<Assignment />}
      loading={loading}
      disableContentPadding
    >
      <TemplateTable
        config={config}
        currentPage={1}
        list={list}
        elevation={0}
        nestedLevel={0}
        rowDetail={TripDebriefTaskList}
      />
    </TemplateCard>
  );
};
