import React from 'react';
import {IconButton, Stack} from '@mui/material';
import {
  Timer,
  SkipPrevious,
  PlayArrow,
  Pause,
  SkipNext,
} from '@mui/icons-material';

export interface ITimelinePlayback {
  isPlaying: boolean;
  togglePlaying: () => void;
  prevFrame: () => void;
  nextFrame: () => void;
}

interface TripTimelinePlayerProps {
  playback: ITimelinePlayback;
}

export const TripTimelinePlayer: React.FC<TripTimelinePlayerProps> = ({
  playback,
}) => {
  const {isPlaying, togglePlaying, prevFrame, nextFrame} = React.useMemo(
    () => playback,
    [playback]
  );

  return (
    <Stack direction="row" alignItems="center">
      <IconButton disabled>
        <Timer color={isPlaying ? 'secondary' : 'action'} />
      </IconButton>
      <IconButton onClick={prevFrame}>
        <SkipPrevious />
      </IconButton>
      <IconButton onClick={togglePlaying}>
        {!isPlaying ? <PlayArrow /> : <Pause />}
      </IconButton>
      <IconButton onClick={nextFrame}>
        <SkipNext />
      </IconButton>
    </Stack>
  );
};
