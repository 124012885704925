import React from 'react';
import {RoleService} from '../../service';
import {Route, RouteProps} from 'react-router-dom';
import {ErrorUnauthorized} from '../error';

interface Props extends RouteProps {
  permissions?: {name: string; type?: string}[];
  name?: string;
  type?: string;
}

export const AllowedRoute: React.FC<Props> = ({
  permissions,
  name,
  type,
  ...otherProps
}) => {
  if (permissions) {
    let isAuthorized = false;
    permissions.forEach((permission) => {
      const result = RoleService.hasPermission(
        permission.name,
        permission.type ?? 'View'
      );
      if (result) isAuthorized = true;
    });
    if (isAuthorized) {
      return <Route {...otherProps} />;
    }
    if (otherProps.path) {
      if (
        window.location.href.includes(otherProps.path as string) &&
        otherProps.path !== '/'
      ) {
        return <ErrorUnauthorized />;
      }
    }
    return null;
  }
  if (name) {
    const result = RoleService.hasPermission(name, type ?? 'View');
    if (result) {
      return <Route {...otherProps} />;
    }
    if (otherProps.path) {
      if (
        window.location.href.includes(otherProps.path as string) &&
        otherProps.path !== '/'
      ) {
        return <ErrorUnauthorized />;
      }
    }
    return null;
  }
  return <Route {...otherProps} />;
};
