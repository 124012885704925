import React from 'react';
import {RoleUserMinimal} from '@onroadvantage/onroadvantage-api';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../../factory/template';
import {
  RoleUserListContext,
  useRoleUserListContext,
} from './RoleUserListContext';
import {RoleContext} from '../RoleContext';

export const RoleUserList: React.FC = () => {
  const {role} = React.useContext(RoleContext);
  const {loadList, cleanupList} = useRoleUserListContext();

  const [config] = React.useState<TemplateTableConfig<RoleUserMinimal>>({
    columns: [
      {
        name: 'email',
        label: 'Email',
        type: 'string',
        enableEditing: true,
        enableAutocomplete: true,
        enableMulti: true,
        model: 'User',
      },
    ],
    enableFilter: true,
    enableSort: true,
    deleteMessage: (row) => ({
      title: 'Role User',
      items: {Role: role?.name, Email: row.email},
    }),
    identifier: 'ROLE_USER_LIST',
  });

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [cleanupList, loadList]);

  return <TemplateTableWrapper config={config} context={RoleUserListContext} />;
};
