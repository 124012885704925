import React from 'react';
import {TaskTemplateForm} from './TaskTemplateForm';
import {ITemplateTabControllerTab, TemplateTabs} from '../../factory/template';
import {TaskTemplateContext} from './TaskTemplateContext';
import {TaskTemplateOptions} from './TaskTemplateOptions';
import {TaskTemplateItems} from './TaskTemplateItems';

export const TaskTemplate: React.FC = () => {
  const {taskTemplateId, loading} = React.useContext(TaskTemplateContext);

  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {title: 'Details', element: <TaskTemplateForm />},
    {title: 'Options', element: <TaskTemplateOptions />},
    {title: 'Items', element: <TaskTemplateItems />},
  ]);

  const path = React.useMemo(
    () => `/tasktemplatelist/${taskTemplateId}`,
    [taskTemplateId]
  );

  return <TemplateTabs tabs={tabs} path={path} loading={loading} />;
};
