import React from 'react';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import EditIcon from '@mui/icons-material/Edit';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import {useFormikContext} from 'formik';
import {usePlanningSolutionData} from '../../../stores/context';
import {PlanningSolutionFormValues} from './PlanningSolutionForm';
import PlanningSolutionOverviewCard from '../../map/PlanningSolutionOverviewCard';
import {usePlanningSolutionHeaderStyles} from './PlanningSolutionHeader.style';
import {
  FormikAutocompleteHooks,
  FormikDatePicker,
  FormikTextField,
} from '../../formik';

export const PlanningSolutionHeader: React.FC = () => {
  const classes = usePlanningSolutionHeaderStyles();
  const formik = useFormikContext<PlanningSolutionFormValues>();
  const contractListings = usePlanningSolutionData(
    (store) => store.rawContractListings
  );
  const {planningSolution} = usePlanningSolutionData((store) => ({
    planningSolution: store.planningSolution,
  }));

  return (
    <div className={classes.columnContainer}>
      <div className={classes.cardContent}>
        <Card className={classes.container}>
          <CardHeader
            title="Planning Solution Header Edit"
            avatar={
              <Avatar>
                <EditIcon />
              </Avatar>
            }
          />
          <CardContent className={classes.content}>
            <FormikTextField
              label="Solution Name"
              name="name"
              placeholder="Enter the Solution Name"
            />
            <FormikAutocompleteHooks
              label="Solution Contract"
              id="contract"
              name="contract"
              disabled={true}
              options={contractListings}
              placeholder="Enter and select a value"
              defaultValue={formik.values.contract}
            />
            <FormikDatePicker
              disabled
              label="Solution Start Date"
              name="startDate"
            />
            <FormikDatePicker
              disabled
              label="Solution End Date"
              name="endDate"
            />
          </CardContent>
        </Card>
      </div>
      {planningSolution && (
        <div className={classes.cardContent}>
          <PlanningSolutionOverviewCard planningSolution={planningSolution} />
        </div>
      )}
    </div>
  );
};
