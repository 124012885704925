import {WebPlanningBoardMasterTripWithIdType} from '../planningBoardContext';
import {DateTime} from 'luxon';

export const getActualTrip = (
  currentMasterTrip: WebPlanningBoardMasterTripWithIdType,
  nextMasterTrip: WebPlanningBoardMasterTripWithIdType | undefined
) => {
  const masterTripId = currentMasterTrip.id;
  const tripNumber = currentMasterTrip.trip?.tripNumber;
  const vehicleId = currentMasterTrip.trip?.vehicleId;
  const tripStops = currentMasterTrip.trip?.stops;

  if (
    masterTripId &&
    vehicleId &&
    tripNumber &&
    tripStops &&
    tripStops.length > 0
  ) {
    const plannedTripStart =
      tripStops[0]?.serviceTimeStart ?? currentMasterTrip.trip?.tripStart;

    let start: Date | undefined;
    let end: Date | undefined;
    tripStops.forEach(({tripStopExecution}) => {
      if (!start) {
        if (tripStopExecution?.revisedEta) {
          start = tripStopExecution.revisedEta;
        } else if (tripStopExecution?.revisedEtss) {
          start = tripStopExecution.revisedEtss;
        } else if (tripStopExecution?.mobileServiceStartTime) {
          start = tripStopExecution.mobileServiceStartTime;
        } else if (tripStopExecution?.mobileArrivalTime) {
          start = tripStopExecution.mobileArrivalTime;
        }
      }
      if (start) {
        if (
          tripStopExecution?.revisedEtd &&
          (!end || tripStopExecution?.revisedEtd > end)
        ) {
          end = tripStopExecution.revisedEtd;
        } else if (
          tripStopExecution?.revisedEtse &&
          (!end || tripStopExecution?.revisedEtse > end)
        ) {
          end = tripStopExecution.revisedEtse;
        } else if (
          tripStopExecution?.mobileServiceEndTime &&
          (!end || tripStopExecution?.mobileServiceEndTime > end)
        ) {
          end = tripStopExecution.mobileServiceEndTime;
        } else if (
          tripStopExecution?.mobileDepartureTime &&
          (!end || tripStopExecution?.mobileDepartureTime > end)
        ) {
          end = tripStopExecution.mobileDepartureTime;
        }
      }
    });

    if (start && end) {
      const timelineId = `actual${masterTripId}`;
      let tripOverlapsWithNext = false;
      let tripWithin2Hours = true;
      if (nextMasterTrip?.trip) {
        const nextTripStops = nextMasterTrip.trip.stops;
        const nextTripStart =
          nextTripStops && nextTripStops[0]?.serviceTimeStart
            ? nextTripStops[0].serviceTimeStart
            : nextMasterTrip.trip.tripStart;

        if (nextTripStart) {
          tripOverlapsWithNext = end > nextTripStart;
        }
      }

      if (plannedTripStart && start > plannedTripStart) {
        const startDiffInHours = DateTime.fromJSDate(start)
          .diff(DateTime.fromJSDate(plannedTripStart))
          .shiftTo('hours').hours;

        tripWithin2Hours = startDiffInHours <= 2;
      }
      return {
        priority: tripOverlapsWithNext ? 3 : tripWithin2Hours ? 1 : 2,
        status: tripOverlapsWithNext
          ? 'overlaps'
          : tripWithin2Hours
          ? 'safe'
          : 'late',
        start,
        end,
        vehicleId,
        timelineId,
        tripNumber,
        masterTripId,
      };
    }
  }
};
