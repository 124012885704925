import React from 'react';
import Card from '@mui/material/Card';
import {ReportScheduleForm} from '../ReportScheduleForm';

export const ReportSchedule: React.FC = () => {
  return (
    <Card>
      <ReportScheduleForm />
    </Card>
  );
};
