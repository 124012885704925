import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

const ReportScheduleFormScheduleRecurringStyles = (theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
      flex: 1,
    },
    days: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(2),
    },
    daysContent: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: theme.spacing(2),
      flexWrap: 'wrap',
    },
  });

export const useReportScheduleFormScheduleRecurringStyles = makeStyles(
  ReportScheduleFormScheduleRecurringStyles,
  {name: 'ReportScheduleFormScheduleRecurringStyles'}
);
