import React from 'react';
import * as Yup from 'yup';
import {useFormik, FormikHelpers, FormikProps} from 'formik';
import {Button, TextField, Typography} from '@mui/material';
import {useForgotPasswordFormStyles} from '../forgotPassword/ForgotPasswordForm.style'; // TODO
import {AuthenticationAcknowledge} from '../AuthenticationAcknowledge';
import {useInputStyles} from '../../formik/Input.style';

export const forgotPasswordResetFormSchema = Yup.object({
  password: Yup.string().required('Required'),
  passwordConfirm: Yup.string()
    .when('password', {
      is: (val: string) => !!(val && val.length > 0),
      then: Yup.string().oneOf([Yup.ref('password')], 'Passwords do not match'),
    })
    .required('Required'),
});

export interface ForgotPasswordResetFormValues {
  password: string;
  passwordConfirm: string;
}

interface ForgotPasswordFormProps {
  formikRef?: React.Ref<FormikProps<ForgotPasswordResetFormValues>>;
  onSubmit: (
    values: ForgotPasswordResetFormValues,
    helpers: FormikHelpers<ForgotPasswordResetFormValues>
  ) => void;
}

export const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({
  formikRef,
  onSubmit,
}) => {
  const classes = useForgotPasswordFormStyles();
  const classesTextField = useInputStyles();

  const formik = useFormik<ForgotPasswordResetFormValues>({
    initialValues: {password: '', passwordConfirm: ''},
    onSubmit: onSubmit,
    validateOnChange: true,
    validationSchema: forgotPasswordResetFormSchema,
    innerRef: formikRef,
  });

  return (
    <>
      <Typography variant="h4">Password Reset</Typography>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          className={`${classesTextField.input} ${
            formik.touched.password && !!formik.errors.password
              ? classesTextField.inputError
              : ''
          }`}
          disabled={formik.isSubmitting}
          fullWidth
          id="password"
          label="New Password"
          name="password"
          placeholder="Enter a new password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && !!formik.errors.password}
          helperText={formik.touched.password && formik.errors.password}
          onBlur={formik.handleBlur}
          type="password"
        />
        <TextField
          className={`${classesTextField.input} ${
            formik.touched.password && !!formik.errors.password
              ? classesTextField.inputError
              : ''
          }`}
          disabled={formik.isSubmitting}
          fullWidth
          id="passwordConfirm"
          label="Confirm New Password"
          name="passwordConfirm"
          placeholder="Re-enter the new password"
          value={formik.values.passwordConfirm}
          onChange={formik.handleChange}
          error={
            formik.touched.passwordConfirm && !!formik.errors.passwordConfirm
          }
          helperText={
            formik.touched.passwordConfirm && formik.errors.passwordConfirm
          }
          onBlur={formik.handleBlur}
          type="password"
        />
        <Button
          className={classes.button}
          color="primary"
          disabled={formik.isSubmitting}
          name="submit"
          type="submit"
          variant="contained"
          // fullWidth
        >
          Submit
        </Button>
        <AuthenticationAcknowledge />
      </form>
    </>
  );
};
