import React from 'react';
import {TrainingCourseForm} from './TrainingCourseForm';
import {ITemplateTabControllerTab, TemplateTabs} from '../../factory/template';
import {TrainingCourseContext} from './TrainingCourseContext';
import {TrainingCourseUserList} from './trainingCourseUser';

export const TrainingCourse: React.FC = () => {
  const {trainingCourseId, loading} = React.useContext(TrainingCourseContext);

  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {title: 'Details', element: <TrainingCourseForm />},
    {title: 'Users', element: <TrainingCourseUserList />},
  ]);

  const path = React.useMemo(
    () => `/trainingcourselist/${trainingCourseId}`,
    [trainingCourseId]
  );

  return <TemplateTabs tabs={tabs} path={path} loading={loading} />;
};
