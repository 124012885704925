import React from 'react';
import {useAppNotifications} from '../../../contexts';
import {
  ActualTripSummary,
  ApiTelematicsEventGetRequest,
  Contract,
  CriticalEventDump,
  CriticalEventListResponse,
  EditTripStop,
  FixedRoute,
  MasterTripDebriefSnapshotData,
  MasterTripDebriefSnapshotResponse,
  MasterTripNearbyTimeDump,
  MasterTripNearbyTimeResponse,
  MasterTripQuery,
  NodeStopDump,
  OperationalEventDump,
  OperationalEventListResponse,
  Trip,
  TripEventGeoJSON,
  TripLog,
  TripLogList,
} from '@onroadvantage/onroadvantage-api';
import {DateTime} from 'luxon';
import {mapDisplayStore} from '../../../stores/mobxStores';
import {
  contractApi,
  criticalEventApi,
  fixedRouteApi,
  masterTripDebriefApi,
  operationalEventApi,
  telematicsEventApi,
  tripApi,
  tripLogApi,
} from '../../../api';
import {getNearbyNodes, getStopEditPayload} from '../helpers';
import {RoleService} from '../../../service';

export const EVENT_FILTER_DURATION = 4;

export type TrackingTypes = 'vehicle' | 'mobile';

export type EventWithMapType = (CriticalEventDump | OperationalEventDump) & {
  mapType: string | undefined;
};

interface loadOptions {
  reload?: boolean;
  override?: boolean;
}

export interface ITelematicsEventsDateRange {
  startDate: Date;
  endDate: Date;
}

interface loadAdjacentTripsOptions extends loadOptions {
  telematicsEventsDateRange: ITelematicsEventsDateRange;
}

interface loadContractOptions extends loadOptions {
  contractId: number | undefined;
}

interface loadCriticalEventsOptions extends loadOptions {
  masterTrip?: MasterTripQuery | undefined;
  dateRange?: ITelematicsEventsDateRange | undefined;
}

interface loadOperationalEventsOptions extends loadOptions {
  masterTrip?: MasterTripQuery | undefined;
  dateRange?: ITelematicsEventsDateRange | undefined;
}

interface loadRouteOptions extends loadOptions {
  tripId?: number | undefined;
}

interface loadTelematicsEventsOptions extends loadOptions {
  masterTrip?: MasterTripQuery | undefined;
  trackingType?: TrackingTypes | undefined;
  dateRange?: ITelematicsEventsDateRange | undefined;
}

interface loadTripLogsOptions extends loadOptions {
  taskId?: string | undefined;
}

/**
 * Type that is returned from the apiTelematicsEventGet call is 'object', thus we need to make a type for it in the FE
 * to allow all the typing to work.
 * TODO possibly add more detailed type in the BE?
 */
export interface TelematicsGeojson {
  coordTimes: any;
  coordinates: any;
  type: string;
}

export interface useTripParams {
  setTelematicsEventsDateRange: React.Dispatch<
    React.SetStateAction<ITelematicsEventsDateRange>
  >;
}

export const useTrip = ({setTelematicsEventsDateRange}: useTripParams) => {
  const notify = useAppNotifications();
  // states
  const [actualTrip, setActualTrip] = React.useState<
    ActualTripSummary | undefined
  >();
  const [adjacentTrips, setAdjacentTrips] = React.useState<
    MasterTripNearbyTimeDump[] | undefined
  >();
  const [contract, setContract] = React.useState<Contract | undefined>();
  const [criticalEvents, setCriticalEvents] = React.useState<
    CriticalEventDump[] | undefined
  >();
  const [criticalEventNearbyNodes, setCriticalEventNearbyNodes] =
    React.useState<NodeStopDump[] | undefined>();
  const [duplicateErrorMessage, setDuplicateErrorMessage] = React.useState<
    string | undefined
  >();
  const [duplicateErrorOccurred, setDuplicateErrorOccurred] =
    React.useState<boolean>(false);
  const [masterTrip, setMasterTrip] = React.useState<
    MasterTripQuery | undefined
  >();
  const [masterTripDebrief, setMasterTripDebrief] = React.useState<
    MasterTripDebriefSnapshotData | undefined
  >();
  const [nearbyNodes, setNearbyNodes] = React.useState<NodeStopDump[]>([]);

  const [operationalEvents, setOperationalEvents] = React.useState<
    OperationalEventDump[] | undefined
  >();
  const [operationalEventNearbyNodes, setOperationalEventNearbyNodes] =
    React.useState<NodeStopDump[] | undefined>();
  const [route, setRoute] = React.useState<FixedRoute['geojson'] | undefined>();
  const [searchParamEvent, setSearchParamEvent] = React.useState<
    EventWithMapType | null | undefined
  >();
  const [telematicsEvents, setTelematicsEvents] = React.useState<
    TelematicsGeojson | undefined
  >();
  const [telematicsEventNearbyNodes, setTelematicsEventNearbyNodes] =
    React.useState<NodeStopDump[] | undefined>();
  const [trackingType, setTrackingType] =
    React.useState<TrackingTypes>('vehicle');
  const [tripLogs, setTripLogs] = React.useState<TripLog[] | undefined>();
  // loading states
  const [loadingCriticalEvents, setLoadingCriticalEvents] =
    React.useState<boolean>(false);
  const [loadingContract, setLoadingContract] = React.useState<boolean>(false);
  const [loadingAdjacentTrips, setLoadingAdjacentTrips] =
    React.useState<boolean>(false);
  const [loadingMasterTrip, setLoadingMasterTrip] =
    React.useState<boolean>(false);
  const [loadingMasterTripDebrief, setLoadingMasterTripDebrief] =
    React.useState<boolean>(false);
  const [loadingOperationalEvents, setLoadingOperationalEvents] =
    React.useState<boolean>(false);
  const [loadingRoute, setLoadingRoute] = React.useState<boolean>(false);
  const [loadingSearchParamEvent, setLoadingSearchParamEvent] =
    React.useState<boolean>(false);
  const [loadingTelematicsEvents, setLoadingTelematicsEvents] =
    React.useState<boolean>(false);
  const [
    loadingTelematicsEventsDateRange,
    setLoadingTelematicsEventsDateRange,
  ] = React.useState<boolean>(false);
  const [loadingTripLogs, setLoadingTripLogs] = React.useState<boolean>(false);
  const [updatingTrip, setUpdatingTrip] = React.useState<boolean>(false);
  // id states
  const [criticalEventId, setCriticalEventId] = React.useState<
    number | undefined
  >();
  const [operationalEventId, setOperationalEventId] = React.useState<
    number | undefined
  >();
  const [masterTripId, setMasterTripId] = React.useState<number | undefined>();

  /** Used for the override duplicate trips error logic**/
  const [inTripView, setInTripView] = React.useState<boolean>(false);

  /** Destructure masterTrip, need to add || ({} as MasterTripQuery) for typescript, since masterTrip is nullable. */
  const {trip} = masterTrip || ({} as MasterTripQuery);

  /** Destructure trip, need to add || ({} as Trip) for typescript, since trip is nullable. */
  const {
    contract: tripContract,
    taskId: tripTaskId,
    vehicle: tripVehicle,
    tripStart: tripTripStart,
    tripEnd: tripTripEnd,
  } = trip || ({} as Trip);

  const hasPermission = React.useMemo(
    () => ({
      view: RoleService.hasPermission('Trip List', 'View'),
      edit: RoleService.hasPermission('Edit MasterTrip', 'Edit'),
    }),
    []
  );

  // helper handlers
  const handleCleanupState = React.useCallback(() => {
    setActualTrip(undefined);
    setAdjacentTrips(undefined);
    setContract(undefined);
    setCriticalEvents(undefined);
    setCriticalEventNearbyNodes(undefined);
    setMasterTrip(undefined);
    setMasterTripDebrief(undefined);
    setNearbyNodes([]);
    setOperationalEvents(undefined);
    setOperationalEventNearbyNodes(undefined);
    setRoute(undefined);
    setSearchParamEvent(undefined);
    setTelematicsEvents(undefined);
    setTelematicsEventNearbyNodes(undefined);
    setTrackingType('vehicle');
    setTripLogs(undefined);
  }, []);

  /**
   * After the initial data is loaded, set the telematicsEventsDateRange which is used in the gantt and map.
   * If the trip's duration is longer than 6 hours, use the trip's start and end, then add 4 hours to the end and
   * subtract 4 hours from the start.
   * Else use the searchParamEvent's date and also add 4 hours for the endDate and subtract 4 hours for the startDate
   */
  const handleTelematicsEventsDateRange = React.useCallback(
    async (trip: Trip | undefined, eventDate: Date | undefined) => {
      setLoadingTelematicsEventsDateRange(true);
      try {
        if (!trip) return undefined;
        let telematicsEventsDateRange: ITelematicsEventsDateRange | undefined;

        /** If there is no eventDate use the tripStart and tripEnd */
        if (!eventDate && trip.tripStart && trip.tripEnd) {
          const {tripStart, tripEnd} = trip;
          /** We minus and add (x) hours in order to increase the initial event filter on the gantt */
          telematicsEventsDateRange = {
            startDate: DateTime.fromJSDate(tripStart)
              ?.toLocal()
              .minus({hour: EVENT_FILTER_DURATION})
              .toJSDate(),
            endDate: DateTime.fromJSDate(tripEnd)
              ?.toLocal()
              .plus({hour: EVENT_FILTER_DURATION})
              .toJSDate(),
          };
          setTelematicsEventsDateRange(telematicsEventsDateRange);
        } else if (eventDate) {
          /** Otherwise, continue with the eventDate logic */
          const {tripStart, tripEnd} = trip;

          const tripStartDateTime = tripStart
            ? DateTime.fromJSDate(tripStart)
            : undefined;

          const tripEndDateTime = tripEnd
            ? DateTime.fromJSDate(tripEnd)
            : undefined;

          const tripDiffInHours =
            tripStartDateTime && tripEndDateTime
              ? Math.floor(
                  tripEndDateTime.diff(tripEndDateTime).shiftTo('hours').hours
                )
              : undefined;

          /** If the trip's length was greater than 6 hours, use the trip's start and end, otherwise use the eventDate */
          if (
            tripStartDateTime &&
            tripEndDateTime &&
            tripDiffInHours &&
            tripDiffInHours > 6
          ) {
            /** We minus and add (x) hours in order to increase the initial event filter on the gantt */
            telematicsEventsDateRange = {
              startDate: tripStartDateTime
                .toLocal()
                .minus({hour: EVENT_FILTER_DURATION})
                .toJSDate(),
              endDate: tripEndDateTime
                .toLocal()
                .plus({hour: EVENT_FILTER_DURATION})
                .toJSDate(),
            };

            setTelematicsEventsDateRange(telematicsEventsDateRange);
          } else {
            const eventDateDateTime = DateTime.fromJSDate(eventDate);
            /** We minus and add (x) hours in order to increase the initial event filter on the gantt */
            telematicsEventsDateRange = {
              startDate: eventDateDateTime
                .toLocal()
                .minus({hour: EVENT_FILTER_DURATION})
                .toJSDate(),
              endDate: eventDateDateTime
                .toLocal()
                .plus({hour: EVENT_FILTER_DURATION})
                .toJSDate(),
            };
            setTelematicsEventsDateRange(telematicsEventsDateRange);
          }
        }
        return telematicsEventsDateRange;
      } finally {
        setLoadingTelematicsEventsDateRange(false);
      }
    },
    [setTelematicsEventsDateRange]
  );

  /**
   * Handle critical event updates through state in order to prevent long reloads after each update.
   * Pass the full criticalEvent that was updated, since the 'apiCriticalEventCriticalEventIdPost' call returns the
   * updated criticalEvent you can just pass the response of the update call to this handler.
   * To update the criticalEvents state, map over all the prevCriticalEvents and where the prevCriticalEvent's id
   * matches the updatedCriticalEvent's id, return the updatedCriticalEvent instead of the prevCriticalEvent. This will
   * then update the necessary state for us.
   */
  const handleUpdateCriticalEvents = React.useCallback(
    (updatedCriticalEvent: CriticalEventDump) => {
      setCriticalEvents((prevCriticalEvents) =>
        prevCriticalEvents?.map((prevCriticalEvent) =>
          prevCriticalEvent.id === updatedCriticalEvent.id
            ? updatedCriticalEvent
            : prevCriticalEvent
        )
      );
    },
    []
  );

  /**
   * Handle master trip updates through state in order to prevent long reloads after each update.
   * Most update calls for the masterTrip should return the same MasterTripQuery as we receive on the get, so we can the
   * just pass the response from the updates and set the masterTrip state to the updatedMasterTrip
   */
  const handleUpdateMasterTrip = React.useCallback(
    (updatedMasterTrip: MasterTripQuery) => {
      setMasterTrip(updatedMasterTrip);
    },
    []
  );

  /** Same as handleUpdateMasterTrip */
  const handleUpdateMasterTripDebrief = React.useCallback(
    (updatedMasterTripDebrief: MasterTripDebriefSnapshotData) => {
      setMasterTripDebrief(updatedMasterTripDebrief);
    },
    []
  );

  /**
   * Handle partial master trip updates through state in order to prevent long reloads after each update.
   * Partial meaning you only have to pass the values that are changed, through the use of the setState callback
   * function we can then only update the values that have changed.
   * For example if we want to update the tripNumber:
   * -> Usage: updateMasterTrip({trip: {tripNumber: newTripNumber}});
   * -> Through destructuring the previous masterTrip's state, we can pass through the values that has changed.
   * -> Notice how we also extract the trip object from the prevMasterTrip and newMasterTrip. We do this so that we can
   * also change values individually on the trip, instead of needing to pass the entire trip object everytime we update.
   */
  const handleUpdateMasterTripPartial = React.useCallback(
    (newMasterTripValues: Partial<MasterTripQuery>) => {
      setMasterTrip((prevMasterTrip) => {
        if (!prevMasterTrip) return undefined;
        const {
          credit: prevCredit,
          progress: prevProgress,
          lastViewed: prevLastViewed,
          trip: prevTrip,
          ...prevMasterTripRoot
        } = prevMasterTrip;
        const {
          credit: newCredit,
          progress: newProgress,
          lastViewed: newLastViewed,
          trip: newTrip,
          ...newMasterTripRoot
        } = newMasterTripValues;
        return {
          ...prevMasterTripRoot,
          ...(newMasterTripRoot ?? {}),
          credit: {...(prevCredit ?? {}), ...(newCredit ?? {})},
          progress: {...(prevProgress ?? {}), ...(newProgress ?? {})},
          lastViewed: {...(prevLastViewed ?? {}), ...(newLastViewed ?? {})},
          trip: {...prevTrip, ...(newTrip ?? {})},
        };
      });
    },
    []
  );

  /** Same as handleUpdateCriticalEvents */
  const handleUpdateOperationalEvents = React.useCallback(
    (updatedOperationalEvent: OperationalEventDump) => {
      setOperationalEvents((prevOperationalEvents) =>
        prevOperationalEvents?.map((prevOperationalEvent) =>
          prevOperationalEvent.id === updatedOperationalEvent.id
            ? updatedOperationalEvent
            : prevOperationalEvent
        )
      );
    },
    []
  );

  /**
   * Handler to update the trackingType value if a newValue has been passed. Also set the newValue in localStorage so
   * that it can be retrieved on reload. Only update when the previous trackingType doesn't match the new or if the
   * localStorage value for trackingType was not found. Handler also returns a boolean so that we know if the value has
   * been updated.
   */
  const handleUpdateTrackingType = React.useCallback(
    (newTrackingType: TrackingTypes) => {
      let didUpdate = false;

      const localStorageTrackingType = localStorage.getItem('trackingType') as
        | TrackingTypes
        | undefined;

      setTrackingType((prevTrackingType) => {
        if (
          prevTrackingType === newTrackingType &&
          newTrackingType === localStorageTrackingType
        ) {
          return prevTrackingType;
        }
        localStorage.setItem('trackingType', newTrackingType);
        didUpdate = true;
        return newTrackingType;
      });

      return didUpdate;
    },
    []
  );

  // load handlers
  const loadAdjacentTrips = React.useCallback(
    async ({
      reload,
      telematicsEventsDateRange,
    }: loadAdjacentTripsOptions): Promise<
      MasterTripNearbyTimeResponse | undefined
    > => {
      /**  Only load the adjacentTrips if the adjacentTrips has not been loaded yet or if it is reloaded */
      if (!reload && adjacentTrips !== undefined) {
        return;
      }

      setLoadingAdjacentTrips(true);
      try {
        if (masterTripId) {
          const response = await tripApi.apiTripMasterTripIdNearbyTimeGet({
            masterTripId,
            startDate: telematicsEventsDateRange.startDate,
            endDate: telematicsEventsDateRange.endDate,
          });

          if (response) {
            setAdjacentTrips(response.items);
            return response;
          }
        }
      } catch (e) {
        notify('error', 'Failed to load adjacent trips');
      } finally {
        setLoadingAdjacentTrips(false);
      }
    },
    [adjacentTrips, masterTripId, notify]
  );

  const loadContract = React.useCallback(
    async (options?: loadContractOptions): Promise<Contract | undefined> => {
      const {contractId: contractIdOption, reload} =
        options || ({} as loadContractOptions);
      /**
       * Get the contractId from the contract id option if it is passed, otherwise try and get the contract id from
       * the master trip state.
       */
      const contractId = contractIdOption ?? tripContract?.id;

      /**
       * Only load the contract if the contract has not been loaded yet or if it is reloaded, and if there is a
       * contractId
       */
      if ((!reload && contract !== undefined) || !contractId) {
        return;
      }

      setLoadingContract(true);
      try {
        const response = await contractApi.apiContractContractIdGet({
          contractId,
        });

        if (response) {
          setContract(response);
          return response;
        }
      } catch (e) {
        notify('error', 'Failed to load contract');
      } finally {
        setLoadingContract(false);
      }
    },
    [contract, tripContract, notify]
  );

  const loadCriticalEvents = React.useCallback(
    async (
      options?: loadCriticalEventsOptions
    ): Promise<CriticalEventListResponse | undefined> => {
      const {
        masterTrip: masterTripOption,
        reload,
        dateRange,
      } = options || ({} as loadCriticalEventsOptions);

      /**
       * Only load the criticalEvents if the criticalEvents has not been loaded yet or if it is reloaded, and if the
       * user has the 'CriticalEvent List' permission
       */
      if (
        (!reload && criticalEvents !== undefined) ||
        !RoleService.hasPermission('CriticalEvent List', 'View')
      ) {
        return;
      }

      setLoadingCriticalEvents(true);
      try {
        /** Get the relevant values, whether it's passed from the option or used from the state. */
        const vehicle = masterTripOption?.trip?.vehicle ?? tripVehicle;
        const tripStart = masterTripOption?.trip?.tripStart ?? tripTripStart;
        const tripEnd = masterTripOption?.trip?.tripEnd ?? tripTripEnd;

        if (vehicle?.id) {
          const startDate = tripStart
            ? DateTime.fromJSDate(tripStart)
                .minus({hour: 1})
                .toUTC()
                .toISODate()
            : undefined;

          const endDate = tripEnd
            ? DateTime.fromJSDate(tripEnd).plus({hour: 1}).toUTC().toISODate()
            : undefined;

          const response = await criticalEventApi.apiCriticalEventGet({
            vehicleId: vehicle.id,
            nearbyNodes: false,
            perPage: 500,
            page: 1,
            ...(dateRange
              ? {
                  startDate: dateRange.startDate.toISOString(),
                  endDate: dateRange.endDate.toISOString(),
                }
              : {
                  startDate,
                  endDate,
                }),
          });

          if (response) {
            setCriticalEvents(response.items);
            return response;
          }
        }
      } catch (e) {
        notify('error', 'Failed to load critical events');
      } finally {
        setLoadingCriticalEvents(false);
      }
    },
    [criticalEvents, tripVehicle, tripTripStart, tripTripEnd, notify]
  );

  const loadMasterTrip = React.useCallback(
    async (options?: loadOptions): Promise<MasterTripQuery | undefined> => {
      const {reload} = options || ({} as loadOptions);

      /**
       * Only load the masterTrip if the masterTrip has not been loaded yet or if it is reloaded, and there is a
       * masterTripId.
       */
      if ((!reload && masterTrip !== undefined) || !masterTripId) {
        return;
      }

      setLoadingMasterTrip(true);
      try {
        const response = await tripApi.apiTripMasterTripIdGet({
          masterTripId,
        });

        if (response) {
          setMasterTrip(response);
          return response;
        }
      } catch (e) {
        notify('error', 'Failed to load master trip');
      } finally {
        setLoadingMasterTrip(false);
      }
    },
    [masterTrip, masterTripId, notify]
  );

  const loadMasterTripDebrief = React.useCallback(
    async (
      options?: loadOptions
    ): Promise<MasterTripDebriefSnapshotResponse | undefined> => {
      const {reload} = options || ({} as loadOptions);
      const {override} = options || ({} as loadOptions);

      /**
       * Only load the masterTripDebrief if the masterTripDebrief has not been loaded yet or if it is reloaded, and if
       * there is a masterTripId.
       */
      if ((!reload && masterTripDebrief !== undefined) || !masterTripId) {
        return;
      }
      setLoadingMasterTripDebrief(true);
      try {
        const response =
          await masterTripDebriefApi.apiMasterTripMasterTripIdDebriefGet({
            masterTripId,
            overrideDuplicateError: override ? true : undefined,
          });
        setDuplicateErrorOccurred(false);

        if (response.data) {
          setMasterTripDebrief(response.data);
          return response;
        }
      } catch (e) {
        if (
          e.message.includes(
            'cannot be debriefed because duplicate trip/s exists with trip number/s'
          )
        ) {
          setInTripView(true);
          setDuplicateErrorOccurred(true);
          const errorMessageObject = JSON.parse(e.message);
          const errorMessage = errorMessageObject.message.replace(
            /\/s/g,
            '(s)'
          );
          setDuplicateErrorMessage(errorMessage);
        }
        notify('error', 'Failed to load master trip debrief');
      } finally {
        setLoadingMasterTripDebrief(false);
      }
    },
    [masterTripDebrief, masterTripId, notify]
  );

  const loadOperationalEvents = React.useCallback(
    async (
      options?: loadOperationalEventsOptions
    ): Promise<OperationalEventListResponse | undefined> => {
      const {
        masterTrip: masterTripOption,
        reload,
        dateRange,
      } = options || ({} as loadOperationalEventsOptions);

      /**
       * Only load the operationalEvents if the operationalEvents has not been loaded yet or if it is reloaded, and if
       * the user has the 'OperationalEvent List' permission.
       */
      if (
        (!reload && operationalEvents !== undefined) ||
        !RoleService.hasPermission('OperationalEvent List', 'View')
      ) {
        return;
      }

      setLoadingOperationalEvents(true);
      try {
        /** Get the relevant values, whether it's passed from the option or used from the state. */
        const vehicle = masterTripOption?.trip?.vehicle ?? tripVehicle;
        const tripStart = masterTripOption?.trip?.tripStart ?? tripTripStart;
        const tripEnd = masterTripOption?.trip?.tripEnd ?? tripTripEnd;

        if (vehicle?.id) {
          const startDate = tripStart
            ? DateTime.fromJSDate(tripStart)
                .minus({hour: 1})
                .toUTC()
                .toISODate()
            : undefined;

          const endDate = tripEnd
            ? DateTime.fromJSDate(tripEnd).plus({hour: 1}).toUTC().toISODate()
            : undefined;

          const response = await operationalEventApi.apiOperationalEventGet({
            vehicleId: vehicle.id,
            nearbyNodes: false,
            perPage: 500,
            page: 1,
            ...(dateRange
              ? {
                  startDate: dateRange.startDate.toISOString(),
                  endDate: dateRange.endDate.toISOString(),
                }
              : {
                  startDate,
                  endDate,
                }),
          });

          if (response) {
            setOperationalEvents(response.items);
            return response;
          }
        }
      } catch (e) {
        notify('error', 'Failed to load operational events');
      } finally {
        setLoadingOperationalEvents(false);
      }
    },
    [notify, operationalEvents, tripTripEnd, tripTripStart, tripVehicle]
  );

  const loadRoute = React.useCallback(
    async (options?: loadRouteOptions): Promise<FixedRoute | undefined> => {
      const {tripId: tripIdOption, reload} =
        options || ({} as loadRouteOptions);

      /** Only load the route if the route has not been loaded yet or if it is reloaded */
      if (!reload && route !== undefined) {
        return;
      }

      setLoadingRoute(true);
      try {
        /** Use the trip id from the option if it is passed, otherwise use the trip id from the state */
        const tripId = tripIdOption ?? trip?.id;

        if (tripId) {
          const response = await fixedRouteApi.apiRouteTripTripIdGet({
            tripId,
          });

          if (response) {
            setRoute(response.geojson);
            return response;
          }
        }
      } catch (e) {
        notify('error', 'Failed to load route');
      } finally {
        setLoadingRoute(false);
      }
    },
    [notify, route, trip]
  );

  const loadSearchParamEvent = React.useCallback(
    async (
      options?: loadOptions
    ): Promise<EventWithMapType | null | undefined> => {
      const {reload} = options || ({} as loadOptions);

      /**
       * Only load the searchParamEvent if the searchParamEvent has not been loaded yet or if it is reloaded, and if
       * either of the eventIds were provided
       */
      if (
        (!reload && searchParamEvent !== undefined) ||
        (!criticalEventId && !operationalEventId)
      ) {
        return;
      }

      setLoadingSearchParamEvent(true);
      try {
        let response: EventWithMapType | null = null;

        if (criticalEventId) {
          const criticalEventResponse =
            await criticalEventApi.apiCriticalEventCriticalEventIdGet({
              criticalEventId,
            });
          response = {...criticalEventResponse, mapType: 'Critical'};
        } else if (operationalEventId) {
          const operationalEventResponse =
            await operationalEventApi.apiOperationalEventOperationalEventIdGet({
              operationalEventId,
            });
          response = {...operationalEventResponse, mapType: 'Operational'};
        }

        if (response) {
          setSearchParamEvent(response);
          /** If there's a response, set it to the openEvent for the mapDisplayStore  */
          // TODO move mapDisplayStore to Context. Too many affected legacy components currently to do the move
          await mapDisplayStore.setOpenEvent(response);
          return response;
        }
      } catch (e) {
        if (criticalEventId || operationalEventId) {
          notify(
            'error',
            `Failed to load ${
              criticalEventId ? 'critical' : 'operational'
            } event`
          );
        }
      } finally {
        setLoadingSearchParamEvent(false);
      }
    },
    [criticalEventId, notify, operationalEventId, searchParamEvent]
  );

  const loadTelematicsEvents = React.useCallback(
    async (
      options?: loadTelematicsEventsOptions
    ): Promise<TripEventGeoJSON | undefined> => {
      const {
        masterTrip: masterTripOption,
        trackingType: trackingTypeOption,
        reload,
        dateRange,
      } = options || ({} as loadTelematicsEventsOptions);

      /** Only load the telematicsEvents if the telematicsEvents has not been loaded yet or if it is reloaded */
      if (
        !reload &&
        (telematicsEvents !== undefined ||
          actualTrip !== undefined ||
          telematicsEventNearbyNodes !== undefined)
      ) {
        return;
      }

      setLoadingTelematicsEvents(true);
      try {
        const trackingTypeToUse = trackingTypeOption ?? trackingType;
        const requestParameters: ApiTelematicsEventGetRequest = {
          nearbyNodes: false,
          ...(dateRange
            ? {startDate: dateRange.startDate, endDate: dateRange.endDate}
            : {}),
        };

        if (trackingTypeToUse === 'mobile') {
          requestParameters.vehicleId =
            masterTripOption?.trip.mobile?.id ?? trip?.mobile?.id;
        } else {
          requestParameters.tripId = masterTripOption?.trip?.id ?? trip?.id;
        }

        const response = await telematicsEventApi.apiTelematicsEventGet(
          requestParameters
        );

        if (response) {
          let geojson: TelematicsGeojson | undefined;
          if (
            response.geojson != null &&
            'coordinates' in response.geojson &&
            'coordTimes' in response.geojson
          ) {
            geojson = {
              ...(response.geojson as TelematicsGeojson),
              coordinates: (
                response.geojson as TelematicsGeojson
              ).coordinates.map((coordinate: number[]) => [
                coordinate[1],
                coordinate[0],
              ]),
            };
          }
          setActualTrip(response.actualTrip);
          setTelematicsEvents(geojson);
          return response;
        }
      } catch (e) {
        notify('error', 'Failed to load telematics events');
      } finally {
        setLoadingTelematicsEvents(false);
      }
    },
    [
      actualTrip,
      notify,
      telematicsEventNearbyNodes,
      telematicsEvents,
      trackingType,
      trip,
    ]
  );

  const loadTripLogs = React.useCallback(
    async (options?: loadTripLogsOptions): Promise<TripLogList | undefined> => {
      const {taskId, reload} = options || ({} as loadTripLogsOptions);

      /** Only load the tripLogs if the tripLogs has not been loaded yet or if it is reloaded */
      if (!reload && tripLogs !== undefined) {
        return;
      }

      setLoadingTripLogs(true);
      try {
        const response = await tripLogApi.apiTriplogPost({
          body: {
            taskId: taskId ?? tripTaskId ?? 'randombinaryhere',
          },
        });

        if (response) {
          setTripLogs(response.data);
          return response;
        }
      } catch (e) {
        notify('error', 'Failed to load trip logs');
      } finally {
        setLoadingTripLogs(false);
      }
    },
    [tripTaskId, notify, tripLogs]
  );

  const loadInitialData = React.useCallback(
    async (options?: loadOptions) => {
      /** Set the route loading state to true, so that we don't get an "Unable to Load Map" error in between re-renders */
      setLoadingRoute(true);
      /**
       * First load the masterTrip, then do all the other calls required on initial load.
       * IMPORTANT: For initial calls that need the masterTrip, use the masterTripResponse, otherwise they will see
       * masterTrip as undefined on initial load as the masterTrip state isn't set yet.
       */
      const masterTripResponse = await loadMasterTrip(options);

      /** Set the document title to the current trip's tripNumber */
      if (
        masterTripResponse?.tripNumber ||
        masterTripResponse?.trip?.tripNumber
      ) {
        document.title = `Vantage | ${
          masterTripResponse.tripNumber ?? masterTripResponse.trip.tripNumber
        }`;
      }

      /**
       * Retrieve the trackingType from localStorage. Then, if the localStorageTrackingType either doesn't exist or it
       * is equal to 'mobile' and the trip doesn't have a mobile to track, set/update the trackingType to 'vehicle'
       */
      const localStorageTrackingType = localStorage.getItem('trackingType') as
        | TrackingTypes
        | undefined;
      if (
        !localStorageTrackingType ||
        (localStorageTrackingType === 'mobile' &&
          !masterTripResponse?.trip?.mobile?.id)
      ) {
        handleUpdateTrackingType('vehicle');
      }

      /** Load the tripLogs which is needed for the summary */
      await loadTripLogs({
        ...options,
        taskId: masterTripResponse?.trip?.taskId,
      });

      /** Load event from searchParam '?criticalEventId=1' or '?operationalEventId=1' */
      const eventResponse = await loadSearchParamEvent(options);

      /** Handle and set the telematics event date range */
      const telematicsEventsDateRangeResponse =
        await handleTelematicsEventsDateRange(
          masterTripResponse?.trip,
          eventResponse?.eventDate
        );

      /** Load the telematicsEvents which is needed for the map and actuals on the summary */
      const telematicsEvents = await loadTelematicsEvents({
        ...options,
        masterTrip: masterTripResponse,
        trackingType: localStorageTrackingType,
        dateRange: telematicsEventsDateRangeResponse,
      });

      /** Load adjacent trips with the telematicsEventsDateRangeResponse */
      if (telematicsEventsDateRangeResponse) {
        await loadAdjacentTrips({
          ...options,
          telematicsEventsDateRange: telematicsEventsDateRangeResponse,
        });
      }

      /** Load the operationalEvents, then with the helper get the nearby nodes for the map */
      await loadOperationalEvents({...options, masterTrip: masterTripResponse});

      /** Load the criticalEvents, then with the helper get the nearby nodes for the map */
      const criticalEvents = await loadCriticalEvents({
        ...options,
        masterTrip: masterTripResponse,
      });

      if (criticalEvents?.nearbyNodes && telematicsEvents?.nearbyNodes) {
        const nearbyNodes = await getNearbyNodes(
          criticalEvents.nearbyNodes,
          telematicsEvents.nearbyNodes
        );
        setNearbyNodes(nearbyNodes);
      }

      /** Finally load the route for the map */
      await loadRoute({...options, tripId: masterTripResponse?.trip?.id});
    },
    [
      loadMasterTrip,
      loadTelematicsEvents,
      loadTripLogs,
      loadSearchParamEvent,
      handleTelematicsEventsDateRange,
      loadCriticalEvents,
      loadOperationalEvents,
      loadRoute,
      handleUpdateTrackingType,
      loadAdjacentTrips,
    ]
  );

  /** Recalculate the trip */
  const handleRecalculateTrip = React.useCallback(async () => {
    setUpdatingTrip(true);
    try {
      if (
        masterTrip?.trip.id &&
        masterTrip.trip.stops &&
        masterTrip.trip.stops.length > 0
      ) {
        const response = await tripApi.apiMasterTripEditPost({
          body: {
            id: masterTrip?.trip.id,
            stops: masterTrip.trip.stops
              .filter((stop) => !!getStopEditPayload(stop))
              .map((stop) => getStopEditPayload(stop) as EditTripStop),
          },
        });

        if (response) {
          notify('success', `Recalculated: ${response.trip.tripNumber}`);
        }
      }
    } catch (e) {
      notify('error', 'Failed to recalculate trip');
    } finally {
      setUpdatingTrip(false);
      await loadInitialData({reload: true});
    }
  }, [loadInitialData, masterTrip, notify]);

  return {
    actualTrip,
    adjacentTrips,
    duplicateErrorMessage,
    duplicateErrorOccurred,
    contract,
    criticalEvents,
    criticalEventNearbyNodes,
    masterTrip,
    masterTripDebrief,
    nearbyNodes,
    operationalEvents,
    operationalEventNearbyNodes,
    route,
    searchParamEvent,
    telematicsEvents,
    telematicsEventNearbyNodes,
    trackingType,
    tripLogs,
    inTripView,
    hasPermission,
    // loading states
    loadingAdjacentTrips,
    loadingContract,
    loadingCriticalEvents,
    loadingMasterTrip,
    loadingMasterTripDebrief,
    loadingOperationalEvents,
    loadingRoute,
    loadingSearchParamEvent,
    loadingTelematicsEvents,
    loadingTelematicsEventsDateRange,
    loadingTripLogs,
    updatingTrip,
    // ids
    criticalEventId,
    masterTripId,
    operationalEventId,
    // state setters
    setInTripView,
    setCriticalEventId,
    setMasterTripId,
    setNearbyNodes,
    setOperationalEventId,
    setUpdatingTrip,
    // helper handlers
    cleanupState: handleCleanupState,
    updateCriticalEvent: handleUpdateCriticalEvents,
    updateMasterTrip: handleUpdateMasterTrip,
    updateMasterTripDebrief: handleUpdateMasterTripDebrief,
    updateMasterTripPartial: handleUpdateMasterTripPartial,
    updateOperationalEvent: handleUpdateOperationalEvents,
    updateTrackingType: handleUpdateTrackingType,
    // load handlers
    loadAdjacentTrips,
    loadContract,
    loadCriticalEvents,
    loadInitialData,
    loadMasterTrip,
    loadMasterTripDebrief,
    loadOperationalEvents,
    loadRoute,
    loadSearchParamEvent,
    loadTelematicsEvents,
    loadTripLogs,
    recalculateTrip: handleRecalculateTrip,
  };
};

export type useTripResponse = ReturnType<typeof useTrip>;

export const useTripResponseInitial: useTripResponse = {
  actualTrip: undefined,
  adjacentTrips: undefined,
  duplicateErrorMessage: undefined,
  duplicateErrorOccurred: false,
  contract: undefined,
  criticalEvents: undefined,
  criticalEventNearbyNodes: undefined,
  masterTrip: undefined,
  masterTripDebrief: undefined,
  nearbyNodes: [],
  operationalEvents: undefined,
  operationalEventNearbyNodes: undefined,
  route: undefined,
  searchParamEvent: undefined,
  telematicsEvents: undefined,
  telematicsEventNearbyNodes: undefined,
  trackingType: 'vehicle',
  tripLogs: undefined,
  inTripView: false,
  hasPermission: {view: false, edit: false},
  // loading
  loadingAdjacentTrips: false,
  loadingContract: false,
  loadingCriticalEvents: false,
  loadingMasterTrip: false,
  loadingMasterTripDebrief: false,
  loadingOperationalEvents: false,
  loadingRoute: false,
  loadingSearchParamEvent: false,
  loadingTelematicsEvents: false,
  loadingTelematicsEventsDateRange: false,
  loadingTripLogs: false,
  updatingTrip: false,
  // ids
  criticalEventId: undefined,
  masterTripId: undefined,
  operationalEventId: undefined,
  // state setters
  setCriticalEventId: () => null,
  setInTripView: () => false,
  setMasterTripId: () => null,
  setNearbyNodes: () => null,
  setOperationalEventId: () => null,
  setUpdatingTrip: () => null,
  // helper handlers
  cleanupState: () => null,
  updateCriticalEvent: () => null,
  updateMasterTrip: () => null,
  updateMasterTripDebrief: () => null,
  updateMasterTripPartial: () => null,
  updateOperationalEvent: () => null,
  updateTrackingType: () => false,
  // load handlers
  loadAdjacentTrips: async () => undefined,
  loadContract: async () => undefined,
  loadCriticalEvents: async () => undefined,
  loadInitialData: async () => undefined,
  loadMasterTrip: async () => undefined,
  loadMasterTripDebrief: async () => undefined,
  loadOperationalEvents: async () => undefined,
  loadRoute: async () => undefined,
  loadSearchParamEvent: async () => undefined,
  loadTelematicsEvents: async () => undefined,
  loadTripLogs: async () => undefined,
  recalculateTrip: async () => undefined,
};
