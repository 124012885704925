import React from 'react';
import {Collapse, IconButton, Stack} from '@mui/material';
import {Add, GridOff, GridOn} from '@mui/icons-material';
import {usePlanningBoardTripContext} from '../planningBoardTripContext';

interface PlanningBoardTripOrdersActionsProps {
  isAdding: boolean;
  isSorting: boolean;
  onToggleIsAdding: () => void;
  onToggleIsSorting: () => void;
}

export const PlanningBoardTripOrdersActions: React.FC<
  PlanningBoardTripOrdersActionsProps
> = ({isAdding, isSorting, onToggleIsAdding, onToggleIsSorting}) => {
  const {orders, loadingTripPreview} = usePlanningBoardTripContext();

  return (
    <Collapse in={!isAdding}>
      <Stack direction="row" mb={1}>
        {orders.length > 1 ? (
          <IconButton
            color="inherit"
            onClick={onToggleIsSorting}
            disabled={loadingTripPreview}
          >
            {isSorting ? <GridOn /> : <GridOff />}
          </IconButton>
        ) : null}
        <IconButton
          color="inherit"
          onClick={onToggleIsAdding}
          disabled={loadingTripPreview}
        >
          <Add />
        </IconButton>
      </Stack>
    </Collapse>
  );
};
