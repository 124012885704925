import React from 'react';
import {Tab, Tabs} from '@mui/material';
import {useTripDebriefConfigContext} from '../tripDebriefConfigContext';
import {useTemplateTabControllerStyles} from '../../../factory/template/tabs/TemplateTabs.style';
import {Loader} from '../../loader';
import {
  TripDebriefConfigEventTypeList,
  TripDebriefConfigEventTypeListProps,
} from './TripDebriefConfigEventTypeList';

export const TripDebriefConfigEventTypesRequiringReasonCodes: React.FC = () => {
  const classes = useTemplateTabControllerStyles();
  const [tabValue, setTabValue] = React.useState<number>(0);
  const {masterTripDebriefConfig, loadingTripDebriefConfig} =
    useTripDebriefConfigContext();

  const handleTabChange = React.useCallback((event: unknown, value: number) => {
    setTabValue(value);
  }, []);

  const criticalEventTypeList = React.useMemo(
    () => masterTripDebriefConfig?.criticalEventTypes ?? [],
    [masterTripDebriefConfig?.criticalEventTypes]
  );

  const operationalEventTypeList = React.useMemo(
    () => masterTripDebriefConfig?.operationalEventTypes ?? [],
    [masterTripDebriefConfig?.operationalEventTypes]
  );

  const mediaEventTypeList = React.useMemo(
    () => masterTripDebriefConfig?.mediaEventTypes ?? [],
    [masterTripDebriefConfig?.mediaEventTypes]
  );

  const getList = React.useCallback(() => {
    switch (tabValue) {
      case 2:
        return mediaEventTypeList;
      case 1:
        return operationalEventTypeList;
      case 0:
        return criticalEventTypeList;
      default:
        return [];
    }
  }, [
    criticalEventTypeList,
    mediaEventTypeList,
    operationalEventTypeList,
    tabValue,
  ]);

  const getType = React.useCallback<
    () => TripDebriefConfigEventTypeListProps['type']
  >(() => {
    switch (tabValue) {
      case 2:
        return 'MediaEventType';
      case 1:
        return 'OperationalEventType';
      case 0:
        return 'CriticalEventType';
      default:
        return 'CriticalEventType';
    }
  }, [tabValue]);

  return (
    <>
      <Tabs
        className={classes.tabs}
        value={tabValue}
        classes={{scroller: classes.tabs}}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab classes={{root: classes.tab}} label="Critical Event Types" />
        <Tab classes={{root: classes.tab}} label="Operational Event Types" />
        <Tab classes={{root: classes.tab}} label="Media Event Types" />
      </Tabs>
      {loadingTripDebriefConfig ? (
        <Loader
          text="Rendering Content..."
          classes={{loaderContainer: classes.loaderContainer}}
        />
      ) : (
        <TripDebriefConfigEventTypeList list={getList()} type={getType()} />
      )}
    </>
  );
};
