import React from 'react';
import {VehicleTypeCompartmentContext} from './VehicleTypeCompartmentContext';
import {useParams} from 'react-router-dom';

interface IParams {
  compartmentId?: string;
}

export const VehicleTypeCompartmentIdHandler: React.FC = () => {
  const {setVehicleTypeCompartmentId} = React.useContext(
    VehicleTypeCompartmentContext
  );
  const {compartmentId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = compartmentId ? parseInt(compartmentId) : undefined;
    setVehicleTypeCompartmentId(parsedId);
    return () => setVehicleTypeCompartmentId(undefined);
  }, [compartmentId, setVehicleTypeCompartmentId]);

  return null;
};
