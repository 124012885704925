import React from 'react';
import {MasterRouteLegContext} from './MasterRouteLegContext';
import {MasterRouteLegDetails} from './masterRouteLegDetails';
import {MasterRouteContext} from '../MasterRouteContext';
import {
  ITemplateTabControllerTab,
  TemplateTabs,
} from '../../../factory/template';

export const MasterRouteLeg: React.FC = () => {
  const {masterRouteId} = React.useContext(MasterRouteContext);
  const {loading, masterRouteLegId} = React.useContext(MasterRouteLegContext);

  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {title: 'Details', element: <MasterRouteLegDetails />},
  ]);

  const path = React.useMemo(
    () => `/masterroutelist/${masterRouteId}/leg/${masterRouteLegId}`,
    [masterRouteId, masterRouteLegId]
  );

  return <TemplateTabs path={path} tabs={tabs} loading={loading} />;
};
