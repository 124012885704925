import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

const AuthenticationAcknowledgeStyles = (theme: Theme) =>
  createStyles({
    root: {
      fontSize: 12,
      display: 'flex',
      paddingTop: theme.spacing(1.6),
    },
    text: {
      fontSize: theme.spacing(1.2),
    },
    link: {
      cursor: 'pointer',
      color: theme.palette.text.secondary,
      textDecoration: 'none',
      '&:hover': {opacity: 0.65},
    },
  });

export const useAuthenticationAcknowledgeStyles = makeStyles(
  AuthenticationAcknowledgeStyles,
  {name: 'AuthenticationAcknowledgeStyles'}
);
