import React from 'react';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import moment from 'moment';

interface Props {
  updateOpenIsochrone: any;
  distanceInMinutes: number;
  timeOfDay: number;
  setPopupLoading: any;
}

export default function IsochroneTimeOfDaySlider({timeOfDay}: Props) {
  // TODO convert with moment
  const [timeOfDayState] = React.useState(timeOfDay ? timeOfDay : 0);

  const displayTime = moment(new Date())
    .add(timeOfDayState, 'h')
    .format('HH:mm A');
  return (
    <div style={{width: 300, padding: 8}}>
      <Typography id="discrete-slider" gutterBottom>
        Time of Day:{' '}
        {timeOfDayState === 0
          ? 'Now'
          : `${timeOfDayState} hrs future view - ${displayTime}`}
      </Typography>
      <Slider
        defaultValue={timeOfDayState}
        value={timeOfDayState}
        step={0.5} // half hour increments
        min={0}
        max={24}
      />
    </div>
  );
}
