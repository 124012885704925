import React from 'react';
import {useFormikContext} from 'formik';
import {IconButton, Button, Typography} from '@mui/material';
import {PhotoCamera} from '@mui/icons-material';
import {StorageService} from '../../../service';
import {generateUid, uploadXHR} from '../../../service/Util';
import {useAppNotifications} from '../../../contexts';
import {Loader} from '../../loader';
import {MuiButtonColorTypes} from '../../../typings/mui';
import {useFormikUploadStyles} from './FormikUpload.style';

interface FormikUploadProps {
  // required
  name: string;
  label: string;
  type: string;
  // optional
  accept?: string;
  color?: MuiButtonColorTypes;
  enablePreview?: boolean;
  iconButton?: boolean;
  method?: string;
  size?: 'small' | 'medium' | 'large';
  variant?: 'text' | 'outlined' | 'contained';
}

export const FormikUpload: React.FC<FormikUploadProps> = ({
  name,
  label,
  type,
  accept,
  color,
  enablePreview,
  iconButton,
  size,
  variant,
  ...buttonProps
}) => {
  const notify = useAppNotifications();
  const classes = useFormikUploadStyles();
  const [fileName, setFileName] = React.useState<string | undefined>();
  const {isValid, submitCount, getFieldMeta, getFieldHelpers} =
    useFormikContext();
  const {error, touched} = getFieldMeta(name);
  const {setValue} = getFieldHelpers(name);
  const [imageSrc, setImageSrc] = React.useState<any>();
  const [loading, setLoading] = React.useState<boolean>(false);

  const memoizedError = React.useMemo(
    () =>
      !isValid && (submitCount > 0 || touched) && error ? error : undefined,
    [error, isValid, submitCount, touched]
  );

  const handleSelect = React.useCallback(
    (e: any) => {
      setLoading(true);
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = async () => {
          try {
            const response = await StorageService.getUrl({
              key: generateUid(),
              type: type ?? 'Document',
              method: 'POST',
            });
            if (response) {
              setValue(response.data?.fields?.key);
              const storageConfig = await response.data;
              await uploadXHR(file, storageConfig, () => {});
              setFileName(file.name);
              setImageSrc(reader.result);
            } else {
              notify('error', 'Failed to upload');
            }
          } finally {
            setLoading(false);
          }
        };
        reader.readAsDataURL(file);
      } else {
        notify('info', 'No files selected');
      }
    },
    [notify, setValue, type]
  );

  React.useEffect(
    () => () => {
      setFileName(undefined);
      setImageSrc(undefined);
    },
    [name]
  );

  return (
    <div className={classes.root}>
      <div>
        <input
          accept={accept ?? '.pdf'}
          className={classes.input}
          id={name}
          type="file"
          onChange={handleSelect}
        />
        <label htmlFor={name}>
          {iconButton ? (
            <IconButton
              {...buttonProps}
              aria-label={label}
              component="span"
              size={size !== 'large' ? size : 'medium'}
              color={color ?? 'primary'}
            >
              <PhotoCamera />
            </IconButton>
          ) : (
            <Button
              {...buttonProps}
              aria-label={label}
              component="span"
              variant={variant ?? 'outlined'}
              size={size ?? 'medium'}
              color={color ?? 'primary'}
            >
              {label}
            </Button>
          )}
        </label>
        {memoizedError && (
          <Typography variant="caption" component="legend" color="error">
            *{memoizedError}
          </Typography>
        )}
        <Typography
          variant="caption"
          component="legend"
          className={classes.fileName}
        >
          {fileName}
        </Typography>
      </div>
      {loading ? (
        <div className={classes.loader}>
          <Loader size={30} disableFullWidth />
        </div>
      ) : (
        enablePreview &&
        imageSrc && (
          <img src={imageSrc} alt={imageSrc} className={classes.image} />
        )
      )}
    </div>
  );
};
