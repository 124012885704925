import _ from 'lodash';
import ConfigService from './ConfigService/ConfigService';

import {encodeUrl, flatten} from './Util';
import ResponseService from './ResponseService';
import MomentService from './MomentService';
import {authStore} from '../store';

const serverUrl = ConfigService.serverUri;
const queryUrl = `${serverUrl}/api/trip`;

const TripService = {
  query: async (params) => {
    Object.keys(params).forEach(
      // eslint-disable-next-line no-param-reassign
      (key) => params[key] == null && delete params[key]
    );
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(encodeUrl(queryUrl, params), {
      method: 'GET',
      mode: 'cors',
      headers,
    });

    return ResponseService.checkStatus(response);
  },
  normalize: async (response) => {
    const result = flatten(await response.data);
    return result;
  },
  getById: async (tripId) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(`${queryUrl}/${tripId}`, {
      method: 'GET',
      mode: 'cors',
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  formulateEditPayload: (trip, tripStops) => {
    // formulate body
    const t = {};
    t.id = trip.id;
    t.stops = tripStops.map(
      ({
        id,
        sequence,
        nodeId,
        taskTemplateNodeType,
        totalServiceTime,
        totalServiceTimeChangeReason,
        orders,
        departureTime,
      }) => ({
        id,
        node: {id: nodeId},
        sequence,
        taskTemplateNodeType,
        totalServiceTime,
        totalServiceTimeChangeReason,
        isMainStop: true,
        orders: (orders || []).map(({id}) => ({id})),
        departureTime:
          sequence === 1 && departureTime ? new Date(departureTime) : undefined,
      })
    );
    return t;
  },
  loadEditTripStops: (props) => {
    const {stops} = props;
    // mapping because deep nested objects would not update on UI when updated
    const mapStops = (stop) => {
      return {
        id: stop.id,
        nodeId: _.get(stop, 'node.id', null),

        // visible elements
        siteType: _.get(stop, 'node.type', null),
        taskTemplateNodeType:
          _.get(stop, 'taskTemplateNodeType', null) ||
          _.get(stop, 'node.type', null),
        siteName: _.get(stop, 'node.name', null),
        totalServiceTime: _.get(stop, 'totalServiceTime', null),
        sequence: _.get(stop, 'sequence', null),
        externalReference: _.get(stop, 'node.externalReference', null),
        arrivalTime: _.get(stop, 'arrivalTime', null),
        departureTime: _.get(stop, 'departureTime', null),
        serviceTimeStart: _.get(stop, 'serviceTimeStart', null),
        serviceTimeEnd: _.get(stop, 'serviceTimeEnd', null),
        totalServiceTimeChangeReason: _.get(
          stop,
          'totalServiceTimeChangeReason',
          null
        ),
        unloadingTimeInMinutes: _.get(
          stop,
          'node.unloadingTimeInMinutes',
          null
        ),
        orders: _.get(stop, 'orders', []),
      };
    };
    const tripStops = stops.map(mapStops);
    return TripService.sortBySequence(tripStops);
  },
  sortBySequence: (stops = []) => {
    return stops.sort((a, b) => {
      if (a.sequence < b.sequence) {
        return -1;
      }
      if (a.sequence > b.sequence) {
        return 1;
      }
      return 0;
    });
  },
  editTrip: async (trip) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(`${queryUrl}/edit`, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(trip),
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  assignVehicle: async (params) => {
    const {auth} = authStore;
    const headers = new Headers();
    const body = JSON.stringify(params);
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(`${queryUrl}/vehicle`, {
      method: 'POST',
      mode: 'cors',
      headers,
      body,
    });
    return ResponseService.checkStatus(response);
  },
  assignDriver: async (params) => {
    const {auth} = authStore;
    const headers = new Headers();
    const body = JSON.stringify(params);
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(`${queryUrl}/driver`, {
      method: 'POST',
      mode: 'cors',
      headers,
      body,
    });
    return ResponseService.checkStatus(response);
  },
  createDebrief: async (params) => {
    const {auth} = authStore;
    const headers = new Headers();
    const body = JSON.stringify(params);
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(`${queryUrl}/debrief`, {
      method: 'POST',
      mode: 'cors',
      headers,
      body,
    });
    return ResponseService.checkStatus(response);
  },
  createActivities: async ({activities, id}) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(`${queryUrl}/${id}`, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({activities}),
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  getAdjacentTrips: async ({masterTripId, startDate, endDate}) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const mainUrl = `${queryUrl}/${masterTripId}/nearby/time`;
    const response = await fetch(encodeUrl(mainUrl, {startDate, endDate}), {
      method: 'GET',
      mode: 'cors',
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  downloadPDF: async (tripTaskID, tripNumber) => {
    const url = `${serverUrl}/api/trip/trip_sheet/download/${tripTaskID}?format=pdf`;
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/pdf');
    if (auth.oauthAuthenticated) {
      headers.set('Authorization', `Bearer ${auth.token}`);
    } else {
      headers.set('Authentication-Token', auth.token);
    }
    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers,
    });

    // TODO move this to the ResponseService to be reused
    if (response.ok) {
      const blob = await response.blob();
      const newBlob = new Blob([blob], {type: 'application/pdf'}); // eslint-disable-line no-undef

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE
        window.navigator.msSaveOrOpenBlob(newBlob);
      } else {
        // Other browsers
        const data = window.URL.createObjectURL(newBlob);
        const link = document.createElement('a');
        link.href = data;
        link.download = `Tripsheet_${tripNumber} Created_${MomentService.displayTimestamp(
          new Date()
        )}.pdf`;
        link.click();
        setTimeout(() => {
          // Firefox
          window.URL.revokeObjectURL(data);
        }, 100);
      }
      return {
        data: 'Ok',
      };
    }
    return {
      error: 'Server error',
    };
  },
  listing: async (modelName, params = {}) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(
      encodeUrl(`${queryUrl}/listing/${modelName}`, params),
      {
        method: 'GET',
        mode: 'cors',
        headers,
      }
    );
    return ResponseService.checkStatus(response);
  },
  remove: async (tripId) => {
    const {auth} = authStore;
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authentication-Token', auth.token);
    const response = await fetch(`${queryUrl}/${tripId}`, {
      method: 'DELETE',
      mode: 'cors',
      headers,
    });
    return ResponseService.checkStatus(response, false);
  },
  getNodeFromStops: (stops, type) => {
    /**
     * type - upliftPoint/offloadPoint
     */
    let node = null;
    if (!type) {
      type = 'upliftPoint';
    }
    const newStops = stops.slice();
    if (type === 'offloadPoint') {
      newStops.reverse();
    }

    newStops.some((stop) => {
      if (stop.orders.length > 0 && stop.orders[0][type].name) {
        node = stop.orders[0][type];
        return true;
      }
      return false;
    });

    // this will happen if no orders are assigned
    if (!node) {
      newStops.some((stop) => {
        if (stop.node) {
          node = stop.node;
          return true;
        }
        return false;
      });
    }
    return node;
  },
};

export default TripService;
