import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormLabel,
  Avatar,
  Divider,
  Switch,
} from '@mui/material';
import {AccountCircle} from '@mui/icons-material';
import {CardActions} from '@mui/material';
import {SettingService} from '../../service';
import {
  DATE_FORMAT_NAME,
  EVENT_PANE_AUTO_LOAD_NAME,
  GLOBAL_NAME,
} from '../../service/SettingService';
import {DateTimeFormatService} from '../../service/FormatService';
import {Form, Formik, FormikHelpers} from 'formik';
import * as Yup from 'yup';
import {FormikRadioGroup, FormikSubmit, FormikSwitch} from '../formik';
import {ThemeModeContext, useAppNotifications} from '../../contexts';

const userSettingsFormSchema = Yup.object({
  dateFormat: Yup.string().nullable().default('default'),
  eventPaneAutoload: Yup.boolean().nullable().default(false),
});

type UserSettingsFormValues = ReturnType<
  typeof userSettingsFormSchema.validateSync
>;

export const UserSettings: React.FC = () => {
  const notify = useAppNotifications();
  const {themeMode, toggleThemeMode} = React.useContext(ThemeModeContext);
  const [initialValues, setInitialValues] = React.useState({
    dateFormat:
      SettingService.getSetting(DATE_FORMAT_NAME, GLOBAL_NAME) ?? 'default',
    eventPaneAutoload:
      SettingService.getSetting(EVENT_PANE_AUTO_LOAD_NAME, GLOBAL_NAME) ||
      false,
  });

  const handleSubmit = React.useCallback(
    (
      values: UserSettingsFormValues,
      formikHelpers: FormikHelpers<UserSettingsFormValues>
    ) => {
      try {
        SettingService.setSetting(
          EVENT_PANE_AUTO_LOAD_NAME,
          GLOBAL_NAME,
          values.eventPaneAutoload
        );
        SettingService.setSetting(
          DATE_FORMAT_NAME,
          GLOBAL_NAME,
          values.dateFormat
        );
      } finally {
        setInitialValues({
          dateFormat: values.dateFormat,
          eventPaneAutoload: values.eventPaneAutoload,
        });
        notify('success', 'Updated settings');
        formikHelpers.setSubmitting(false);
      }
    },
    [notify]
  );

  return (
    <Formik<UserSettingsFormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={userSettingsFormSchema}
      enableReinitialize
    >
      {() => (
        <Form>
          <Card>
            <CardHeader
              avatar={
                <Avatar>
                  <AccountCircle />
                </Avatar>
              }
              title="User Settings"
            />
            <Divider />
            <CardContent style={{flexDirection: 'column', display: 'flex'}}>
              <FormikRadioGroup
                name="dateFormat"
                options={[
                  {
                    label: `Vantage Default - Example: ${new DateTimeFormatService().formatDateTimeToDefault(
                      new Date(),
                      true
                    )}`,
                    value: 'default',
                  },
                  {
                    label: `Machine Locale - Example: ${new DateTimeFormatService().formatDateTimeToLocale(
                      new Date(),
                      true
                    )}`,
                    value: 'locale',
                  },
                ]}
                title="Date Format"
              />
              <FormControl component="fieldset">
                <FormLabel component="legend">Events Pane</FormLabel>
                <FormikSwitch label="Auto-load" name="eventPaneAutoload" />
              </FormControl>
              <FormControl component="fieldset">
                <FormLabel component="legend">Dark Mode (Beta)</FormLabel>
                <Switch
                  checked={themeMode === 'dark'}
                  onClick={toggleThemeMode}
                />
              </FormControl>
            </CardContent>
            <Divider />
            <CardActions>
              <FormikSubmit title="save" />
            </CardActions>
          </Card>
        </Form>
      )}
    </Formik>
  );
};
