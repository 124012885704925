const login = (token, roles) => ({
  type: 'login',
  payload: {
    token,
    roles,
  },
});

const logout = () => ({
  type: 'logout',
});

const updateUser = (active, roles, email) => ({
  type: 'updateUser',
  payload: {
    active,
    roles,
    email,
  },
});

export {login, logout, updateUser};
