import React from 'react';
import TripStopService from '../../../../../service/TripStopService';
import {getAuthHeaderValues} from '../../../../../api';
import FileSaver from 'file-saver';
import {CardActions, Button} from '@mui/material';
import {Loader} from '../../../../loader';
import {useTripDebriefSummaryStopTaskListModalStyles} from './TripDebriefSummaryStopTaskListModal.style';
import {useAppNotifications} from '../../../../../contexts';
import {TripDebriefContext} from '../../../TripDebriefContext';

export const TripDebriefSummaryStopTaskListActions: React.FC = () => {
  const {tripStopId} = React.useContext(TripDebriefContext);
  // TODO fix this so the auth token is not included in the URI
  //  Further it cannot be implemented via SSO as it does not accept the bearer
  //  token via url but via the headers.
  const notify = useAppNotifications();
  const classes = useTripDebriefSummaryStopTaskListModalStyles();
  const [loading, setLoading] = React.useState(false);
  const handleDownload = React.useCallback(async () => {
    setLoading(true);
    try {
      if (getAuthHeaderValues !== null && tripStopId) {
        const link = await TripStopService.getDocumentLink(tripStopId, 'PoD');
        const headerValues = getAuthHeaderValues();
        const headers = new Headers();
        if (headerValues) headers.set(headerValues.name, headerValues.value);

        const response = await fetch(link, {
          method: 'GET',
          mode: 'cors',
          headers,
        });

        if (response.ok) {
          const blob = await response.blob();
          FileSaver.saveAs(blob, `POD_${tripStopId}.pdf`);
        }
      }
    } catch (e) {
      notify('error', 'Failed to download POD');
    } finally {
      setLoading(false);
    }
  }, [notify, tripStopId]);

  return (
    <CardActions className={classes.taskActions}>
      {loading ? (
        <Loader size={40} />
      ) : (
        <Button onClick={handleDownload}>Generate Stop PoD</Button>
      )}
    </CardActions>
  );
};
