import {makeStyles, createStyles} from '@mui/styles';

const TableCellStyles = () =>
  createStyles({
    root: {},
  });

export const useTableCellStyles = makeStyles(TableCellStyles, {
  name: 'TableCellStyles',
});
