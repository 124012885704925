import React from 'react';
import _ from 'lodash';
import {TripDebriefConfig} from './TripDebriefConfig';
import {
  TripDebriefConfigContextProvider,
  useTripDebriefConfigContext,
} from './tripDebriefConfigContext';
import {
  ITemplateControllerRoute,
  TemplateController,
} from '../../factory/template';
import {
  TripDebriefConfigList,
  TripDebriefConfigListContextProvider,
} from './tripDebriefConfigList';
import {TripDebriefConfigForm} from './TripDebriefConfigForm';
import {TripDebriefConfigIdHandler} from './TripDebriefConfigIdHandler';

export const TripDebriefConfigController: React.FC = () => {
  return (
    <TripDebriefConfigListContextProvider>
      <TripDebriefConfigContextProvider>
        <TripDebriefConfigControllerComponent />
      </TripDebriefConfigContextProvider>
    </TripDebriefConfigListContextProvider>
  );
};

export const TripDebriefConfigControllerComponent: React.FC = () => {
  const {masterTripDebriefConfig, loadingTripDebriefConfig} =
    useTripDebriefConfigContext();

  const routes: ITemplateControllerRoute[] = React.useMemo(
    () => [
      {title: 'Trip Debrief Config List', component: TripDebriefConfigList},
      {
        path: '/add',
        title: 'New Trip Debrief Config',
        name: 'Add MasterTripDebriefConfig',
        type: 'Add',
        component: () => <TripDebriefConfigForm isAdd />,
      },
      {
        path: '/:masterTripDebriefConfigId',
        title: (params) =>
          loadingTripDebriefConfig
            ? 'Trip Debrief Config Loading...'
            : masterTripDebriefConfig?.name ??
              `Trip Debrief Config ${_.get(
                params,
                'masterTripDebriefConfigId',
                'Single'
              )}`,
        component: TripDebriefConfig,
        IdHandler: TripDebriefConfigIdHandler,
      },
    ],
    [loadingTripDebriefConfig, masterTripDebriefConfig?.name]
  );

  return (
    <TemplateController rootPath="/tripdebriefconfiglist" routes={routes} />
  );
};
