import React from 'react';
import {DriverGroup} from '@onroadvantage/onroadvantage-api';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../../factory/template';
import {
  useDriverGroupListContext,
  DriverGroupListContext,
} from './DriverGroupListContext';

export const DriverGroupList: React.FC = () => {
  const {loadList, cleanupList} = useDriverGroupListContext();

  const [config] = React.useState<TemplateTableConfig<DriverGroup>>({
    columns: [{name: 'name', label: 'Driver Group Name', type: 'string'}],
    enableFilter: true,
    enableSort: true,
    deleteMessage: (row) => ({title: 'Driver Group', items: {Name: row.name}}),
    identifier: 'DRIVER_GROUP_LIST',
  });

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [loadList, cleanupList]);

  return (
    <TemplateTableWrapper config={config} context={DriverGroupListContext} />
  );
};
