import React from 'react';
import history from '../../service/history';
import {Redirect, Route, Router, Switch} from 'react-router-dom';
import {OrderRecipientController, MasterTripRecipientController} from '..';
import {
  ForgotPassword,
  Login,
  LoginLoader,
  LoginOIDC,
  Logout,
  ResetPassword,
} from '../authentication';
import {AuthenticationContextProvider} from '../authentication/AuthenticationContext';
import {ErrorBoundary} from '../../factory/template';

// TODO on "/oidc/login/callback" implement the redirect of the last path
export const UnAuthedRouter: React.FC = () => (
  <Router history={history}>
    <ErrorBoundary>
      <AuthenticationContextProvider>
        <Switch>
          <Route exact path="/auth/forgot" component={ForgotPassword} />
          <Route exact path="/auth/password_reset" component={ResetPassword} />
          <Route path="/recipient/:uuid" component={OrderRecipientController} />
          <Route
            path="/master_trip_recipient/:uuid"
            component={MasterTripRecipientController}
          />
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/login/oidc" component={LoginOIDC} />
          <Route exact path="/login/oidc/callback" component={LoginLoader} />
          <Route path="*" render={() => <Redirect to="/login" />} />
        </Switch>
      </AuthenticationContextProvider>
    </ErrorBoundary>
  </Router>
);
