import React from 'react';
import CriticalEventsHeader from './CriticalEventsHeader';
import CriticalEventsItem from './CriticalEventsItem';
import {keyValuePairToObjectConversion} from '../../../../service/Util';
import {MomentService} from '../../../../service';
import {getColorSchemeName} from '../helpers';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
};

const getTitle = (dashboard = 'Critical Events') => {
  const defaultTitle = dashboard.name || 'Critical Events';
  const colorSchemeName = getColorSchemeName();
  if (colorSchemeName === 'unitrans') {
    if (defaultTitle === '.UNC Critical Events') return 'UCT Critical Events';
    if (defaultTitle === '.UNC DriveCam Events') return 'UCT DriveCam Events';
    if (defaultTitle === '.UNC PnP Events') return 'PnP Operational Events';
    if (defaultTitle === '.UA Ops Events') return 'UA Operational Events';
  }
  return defaultTitle;
};
const getStatus = (unassigned, total) => {
  const value = unassigned / total; // || 0);

  let status = 'clear';
  let sort = 0;
  if (value < 0.25) {
    status = 'normal';
    sort = 3;
  } else if (value >= 0.25 && value < 0.75) {
    status = 'warning';
    sort = 2;
  } else if (value >= 0.75) {
    status = 'critical';
    sort = 1;
  }
  return {value, status, sort};
};

const getPadded = (value) => `${value}`.padStart(2, '0');

const prepareDuration = (item) => {
  if (!item) {
    return '00:00:00';
  }
  if (item.day !== 0) {
    return `${item.day}d ${getPadded(item.hour)}:${getPadded(item.min)}`;
  }
  return `${getPadded(item.hour)}:${getPadded(item.min)}:${getPadded(
    item.sec
  )}`;
};
const prepareData = (items) => {
  const parsed = items.map(keyValuePairToObjectConversion);
  const prepared = parsed.map(
    ({
      eventDescription,
      oldestEventUnassigned,
      unassignedEventCount,
      openEventCount,
    }) => {
      const {value, status, sort} = getStatus(
        unassignedEventCount,
        openEventCount
      );
      const duration = prepareDuration(oldestEventUnassigned);
      return {
        field1: eventDescription,
        field2: duration,
        field3: `${unassignedEventCount}/${openEventCount}`,
        value,
        status,
        sort,
      };
    }
  );
  // sort
  return prepared.sort((a, b) => b.sort - a.sort);
};

class CriticalEventsView extends React.Component {
  render() {
    const {dashboard, lastRefreshed} = this.props;
    const data = prepareData(dashboard.data);
    return (
      <div style={styles.container}>
        <CriticalEventsHeader title={getTitle(dashboard)} />
        {data.map((item) => {
          return <CriticalEventsItem key={item.field1} {...item} />;
        })}
        <div
          style={{
            position: 'fixed',
            bottom: 0,
            height: 'auto',
            marginTop: 40,
            width: '100%',
            textAlign: 'center',
          }}
        >
          Last Data Refresh:{' '}
          {MomentService.displayTimestampWithSec(lastRefreshed)}
        </div>
      </div>
    );
  }
}

export default CriticalEventsView;
