import React from 'react';
import {TemplateTable, TemplateTableConfig} from '../../../factory/template';
import HistoryService from '../../../service/history';
import {ReportContext, ReportContextProps} from '../ReportContext';
import {
  ReportScheduleContext,
  ReportScheduleContextProps,
} from './ReportScheduleContext';
import {ReportSchedule as ReportScheduleType} from '@onroadvantage/onroadvantage-api';

interface IReportSchedule extends ReportScheduleType {
  readonly createdAt?: Date;
  readonly updatedAt?: Date;
}

export const ReportScheduleList: React.FC = () => {
  const {reportId} = React.useContext<ReportContextProps>(ReportContext);
  const {
    loading,
    list,
    listFilters,
    listItemTotal,
    listPage,
    listPageSize,
    listPageTotal,
    listSorting,
    loadList,
    onListDelete,
    onListFilterChange,
    onListPageChange,
    onListPageSizeChange,
    onListSortingChange,
  } = React.useContext<ReportScheduleContextProps>(ReportScheduleContext);

  const [config] = React.useState<TemplateTableConfig<IReportSchedule>>({
    columns: [
      {name: 'name', label: 'Name', type: 'string'},
      {name: 'description', label: 'Description', type: 'string'},
      {name: 'type', label: 'Type', type: 'string'},
      {name: 'startDate', label: 'Start Date', type: 'date'},
      {name: 'endDate', label: 'End Date', type: 'date'},
      {name: 'date', label: 'Date', type: 'date', hidden: true},
      {name: 'time', label: 'Time', type: 'time', hidden: true}, // TODO
      {name: 'sunday', label: 'Sunday', type: 'bool', hidden: true},
      {name: 'monday', label: 'Monday', type: 'bool', hidden: true},
      {name: 'tuesday', label: 'Tuesday', type: 'bool', hidden: true},
      {name: 'wednesday', label: 'Wednesday', type: 'bool', hidden: true},
      {name: 'thursday', label: 'Thursday', type: 'bool', hidden: true},
      {name: 'friday', label: 'Friday', type: 'bool', hidden: true},
      {name: 'saturday', label: 'Saturday', type: 'bool', hidden: true},
      {
        name: 'createdAt',
        label: 'Created At',
        type: 'datetime',
        enableFilter: false,
        hidden: true,
      },
      {
        name: 'updatedAt',
        label: 'Updated At',
        type: 'datetime',
        enableFilter: false,
        hidden: true,
      },
    ],
    enableFilter: true,
    enableSort: true,
    identifier: 'REPORT_SCHEDULE_LIST',
  });

  const handleAdd = () => {
    HistoryService.push(`/reportlist/${reportId}/schedule/add`);
  };

  const handleNavigate = (row: any) => {
    HistoryService.push(`/reportlist/${reportId}/schedule/${row.id}`);
  };

  React.useEffect(() => {
    loadList();
  }, [loadList]);

  return (
    <TemplateTable
      config={config}
      currentPage={listPage}
      filters={listFilters}
      itemTotal={listItemTotal}
      list={list}
      loading={loading}
      onAdd={handleAdd}
      onDelete={onListDelete}
      onCurrentPageChange={onListPageChange}
      onFiltersChange={onListFilterChange}
      onNavigate={handleNavigate}
      onPageSizeCountsChange={onListPageSizeChange}
      onRefresh={loadList}
      onSortingChange={onListSortingChange}
      pageSize={listPageSize}
      pageTotal={listPageTotal}
      sorting={listSorting}
    />
  );
};
