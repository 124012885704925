import React from 'react';
import {
  Divider,
  Drawer,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import {Settings, Close, Refresh} from '@mui/icons-material';
import {PlanningBoardOptions} from './PlanningBoardOptions';
import {usePlanningBoardOptionsStyles} from './PlanningBoardOptions.style';
import {PlanningBoardGroupOptionsController} from './planningBoardGroupOptions';
import {
  usePlanningBoardContext,
  usePlanningBoardSettingsContext,
} from '../planningBoardContext';

interface PlanningBoardOptionsDrawerProps {
  className?: string;
}

export const PlanningBoardOptionsDrawer: React.FC<
  PlanningBoardOptionsDrawerProps
> = ({className}) => {
  const classes = usePlanningBoardOptionsStyles();
  const {
    maxOptionsPerVehicle,
    totalOptionCount,
    setMaxOptionsPerVehicle,
    setTotalOptionCount,
  } = usePlanningBoardContext();
  const {
    setIsOptionsOpen,
    isOptionsOpen,
    enableCheckTripEnd,
    resetGroupOptions,
    toggleEnableCheckTripEnd,
  } = usePlanningBoardSettingsContext();

  const handleClose = React.useCallback(
    () => setIsOptionsOpen(false),
    [setIsOptionsOpen]
  );

  const handleOpen = React.useCallback(
    () => setIsOptionsOpen(true),
    [setIsOptionsOpen]
  );

  const handleChangeTotalOptionCount = React.useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setTotalOptionCount(parseInt(e.target.value));
    },
    [setTotalOptionCount]
  );

  const handleChangeMaxOptionsPerVehicle = React.useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setMaxOptionsPerVehicle(parseInt(e.target.value));
    },
    [setMaxOptionsPerVehicle]
  );

  return (
    <>
      <IconButton
        onClick={handleOpen}
        aria-label="open-planning-board-options"
        className={className}
        size="medium"
      >
        <Settings fontSize="inherit" />
      </IconButton>
      <Drawer open={isOptionsOpen} onClose={handleClose} anchor="right">
        <Stack p={2} minWidth={600}>
          <Stack direction="row" className={classes.dialogTitle}>
            <Typography variant="h6" component="h6">
              Filter Options:
            </Typography>
            <IconButton
              onClick={handleClose}
              aria-label="close-planning-board-options"
              size="large"
            >
              <Close />
            </IconButton>
          </Stack>
          <Stack mb={2}>
            <PlanningBoardOptions onActionClick={handleClose} />
          </Stack>
          <Divider />
          <Stack my={1}>
            <TextField
              type="number"
              margin="normal"
              name="maxOptionsPerVehicle"
              label="Max Options Per Vehicle:"
              value={maxOptionsPerVehicle}
              onChange={handleChangeMaxOptionsPerVehicle}
              fullWidth
            />
            <TextField
              type="number"
              margin="normal"
              name="totalOptionCount"
              label="Total Option Count:"
              value={totalOptionCount}
              onChange={handleChangeTotalOptionCount}
              fullWidth
            />
            <FormControlLabel
              control={
                <Switch
                  checked={enableCheckTripEnd}
                  onChange={toggleEnableCheckTripEnd}
                  name="enableCheckTripEnd"
                  color="primary"
                />
              }
              label="Enable Check Trip End"
            />
          </Stack>
          <Divider />
          <Stack my={1}>
            <Typography variant="h6" component="h6">
              View Group Options:
              <IconButton onClick={resetGroupOptions} size="large">
                <Refresh />
              </IconButton>
            </Typography>
            <PlanningBoardGroupOptionsController />
          </Stack>
        </Stack>
      </Drawer>
    </>
  );
};
