import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import _ from 'lodash';
import {PolylineService} from '../../../../service';
import PlanningTripsRoutesMap from './PlanningTripsRoutesMap';
import {usePlanningSolutionData} from '../../../../stores/context';
import {getRainbowColor} from '../../../../service/Util';
import {PlanningSolution} from '@onroadvantage/onroadvantage-api';

interface Props {
  routes: any[] | undefined;
  planningSolution: PlanningSolution;
}

const PlanningTripsRoutes = ({routes, planningSolution}: Props) => {
  const [externalLayers, setExternalLayers] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const {planningOrders} = usePlanningSolutionData((store) => ({
    planningOrders: store.planningOrders,
  }));

  React.useEffect(() => {
    const waypointRoutes: any[] = [];
    if (!routes) {
      return;
    }
    routes.forEach((route: any, index: number) => {
      const waypoints: any[] = [];
      const loadingNodes: any[] = [];
      const offloadingNodes: any[] = [];
      const planningMasterTrip = _.find(planningSolution.planningMasterTrips, {
        tripNumber: route.tripNumber,
      });
      planningMasterTrip?.trip?.stops?.forEach((stop: any) => {
        waypoints.push(`${stop.node.longitude},${stop.node.latitude}`);
      });
      route.orders.forEach((orderId: number) => {
        const order = _.find(planningOrders, {id: orderId});
        if (order?.order) {
          loadingNodes.push(order.order.upliftPoint);
          offloadingNodes.push(order.order.offloadPoint);
        }
      });
      waypointRoutes.push({
        ...route,
        loadingNodes,
        offloadingNodes,
        depotNode: loadingNodes.length > 0 ? loadingNodes[0] : null,
        waypoints,
        color: getRainbowColor(routes.length, index),
      });
    });

    async function asyncUseEffectTrigger() {
      setLoading(true);
      const layers = waypointRoutes.map(async (route) => {
        const {waypoints} = route;
        const polylines = await PolylineService.waypointsToPolylines(
          await Promise.all(waypoints)
        );

        route.polylines = await Promise.all(polylines);

        const waypointMapLayers =
          await PolylineService.getRouteWaypointMapLayers(route);

        const polylineMapLayers =
          await PolylineService.getRoutePolylineMapLayers(route);
        return [...polylineMapLayers, ...waypointMapLayers];
      });
      const response = await Promise.all(layers);
      if (response) {
        const merged = [].concat.apply([], response as never[]);
        setExternalLayers(merged);
      }
      setLoading(false);
    }
    if (waypointRoutes.length > 0) {
      asyncUseEffectTrigger();
    }
  }, [planningOrders, routes, planningSolution]);

  return (
    <Card elevation={0}>
      <CardContent>
        <div
          style={{display: 'flex', flexDirection: 'row', flexFlow: 'row wrap'}}
        >
          {planningSolution &&
            planningSolution.planningMasterTrips &&
            planningSolution.planningMasterTrips.length > 0 && (
              <PlanningTripsRoutesMap
                loading={loading}
                externalLayers={externalLayers}
                planningSolution={planningSolution}
              />
            )}
        </div>
      </CardContent>
    </Card>
  );
};

export default PlanningTripsRoutes;
