import React from 'react';
import {NodeTypeContext} from './NodeTypeContext';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../factory/template';
import {NodeType} from '@onroadvantage/onroadvantage-api';

export const NodeTypeList: React.FC = () => {
  const {loadList, cleanupList} = React.useContext(NodeTypeContext);

  const [config] = React.useState<TemplateTableConfig<NodeType>>({
    columns: [
      {name: 'name', label: 'Name', type: 'string'},
      {name: 'description', label: 'Description', type: 'string'},
    ],
    enableFilter: true,
    enableSort: true,
    deleteMessage: (row) => ({
      title: 'Node Type',
      items: {Name: row.name, Description: row.description},
    }),
    identifier: 'NODE_TYPE_LIST',
  });

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [loadList, cleanupList]);

  return <TemplateTableWrapper config={config} context={NodeTypeContext} />;
};
