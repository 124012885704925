import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import {Box, Card, IconButton, Typography, Theme, Divider} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import truck from '../../../../img/svg/truck.svg';
import depot from '../../../../img/svg/depot.svg';
import warehouse from '../../../../img/svg/warehouse.svg';
import fuelpump from '../../../../img/svg/fuelpump.svg';
import gaspipe from '../../../../img/svg/gaspipe.svg';

import tools from '../../../../img/svg/tools.svg';
import truckwitharrow from '../../../../img/svg/truckwitharrow.svg';
import dangerzone from '../../../../img/background/dangerzonebackground.jpg';

import custImg from '../../../../img/background/popupbackground.png';
import reststopbackground from '../../../../img/background/reststopbackground.png';
import MomentService from '../../../../service/MomentService';

const useSitePopupStyles = makeStyles((theme: Theme) =>
  createStyles({
    speedBox: {
      BoxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.24)',
      background: 'linear-gradient(90deg,#00B7A3 0%,#008270 100%)',
      borderRadius: '12px',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    bottomFab: {
      background: 'linear-gradient(90deg, #00B7A3 0%, #008270 100%)',
      transform: 'rotate(-180deg)',
      boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.24)',
    },
    divider: {
      width: '1px',
      background: '#fff',
      marginTop: '10px',
      marginBottom: '10px',
    },
    backgrounCard: {
      borderRadius: '12px 12px 12px 12px',
      width: '100%',
      boxShadow: 'none',
    },
    backGround: {
      height: '200px',
      position: 'relative',
    },
    headingColor: {
      color: '#767676',
    },
    fab: {
      top: theme.spacing(1),
      right: theme.spacing(1),
      position: 'absolute',
      boxShadow: 'none',

      width: 36,
    },
    eventDesc: {
      fontWeight: 400,
      fontSize: 12,
      paddingLeft: 10,
    },
    removeMargin: {
      margin: 0,
      padding: 0,
    },
    spacing: {
      margin: theme.spacing(2),
    },
    parentScrollDiv: {
      overflow: 'auto',
      marginBottom: theme.spacing(1),
      height: '500px',
      borderRadius: '12px 12px 12px 12px',
    },
    custButton: {
      backgroundColor: '#E5E5E5',
    },
  })
);

interface Props {
  site: any;
  onEdit?(val: any): void;
  showEdit?: boolean;
}

export default function SitePopup({site, onEdit}: Props) {
  const classes = useSitePopupStyles();

  const [icon, setIcon] = React.useState(truck);
  const [background, setBackground] = React.useState(custImg);
  React.useEffect(() => {
    switch ((site.type as string).toLowerCase()) {
      case 'offloading':
        setIcon(warehouse);
        break;
      case 'loading':
        setIcon(warehouse);
        break;
      case 'fueling':
        setIcon(gaspipe);
        break;
      case 'depot':
        setIcon(depot);
        break;
      case 'rest':
        setBackground(reststopbackground);
        setIcon('');
        break;
      case 'danger zone':
        setBackground(dangerzone);
        setIcon('');
        break;
      case 'workshop':
        setIcon(tools);
        break;
      case 'weighbridge':
        setIcon(truckwitharrow);
        break;
      case 'feulstation':
        setIcon(fuelpump);
        break;
    }
  }, [site.type]);

  return (
    <div>
      <div className={classes.parentScrollDiv}>
        <Box
          style={{backgroundImage: `url(${background})`}}
          className={classes.backGround}
        >
          <Box display="flex" justifyContent="center" ml={2} mr={2}>
            {icon && <img src={icon} alt=";" />}
          </Box>

          <Box
            className={classes.speedBox}
            display="flex"
            justifyContent="space-around"
            alignItems="center"
            mt={2}
            mb={1}
          >
            <Box p={1}>
              <IconButton
                color="inherit"
                className={classes.custButton}
                size="medium"
                onClick={() => onEdit && onEdit(site)}
              >
                <EditIcon />
              </IconButton>
            </Box>
            <Divider
              orientation="vertical"
              classes={{root: classes.divider}}
              flexItem
            />
            <Box p={1}>
              <Typography style={{color: '#fff', fontWeight: 400}} variant="h5">
                Type
              </Typography>

              <Typography variant="h6" style={{color: '#fff', fontWeight: 700}}>
                {site.type}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Card className={classes.backgrounCard}>
          <Box ml={2} mr={2}>
            <Box mb={1}>
              <Typography variant="h5" className={classes.headingColor}>
                Site
              </Typography>
              <Typography variant="h6" style={{fontWeight: 700}}>
                {site.name}
              </Typography>
            </Box>
            <Divider />
            <Box mb={1}>
              <Typography variant="h4">Information</Typography>
            </Box>
            <Box mb={1}>
              <Typography variant="h5" className={classes.headingColor}>
                Open Times
              </Typography>
              <Typography variant="h6" style={{fontWeight: 700}}>
                {site.timeWindows && site.timeWindows.length > 0
                  ? MomentService.localiseTimeOnly(site.timeWindows[0].openTime)
                  : 'None'}
                -
                {site.timeWindows && site.timeWindows.length > 0
                  ? MomentService.localiseTimeOnly(
                      site.timeWindows[0].closeTime
                    )
                  : 'None'}
              </Typography>
            </Box>
            <Box mb={1}>
              <Typography variant="h5" className={classes.headingColor}>
                Processing Time
              </Typography>
              <Typography variant="h6" style={{fontWeight: 700}}>
                {site.processingTimeInMinutes}
              </Typography>
            </Box>
            <Box mb={1}>
              <Typography variant="h5" className={classes.headingColor}>
                Loading Time
              </Typography>
              <Typography variant="h6" style={{fontWeight: 700}}>
                {site.loadingTimeInMinutes}
              </Typography>
            </Box>
            <Box mb={1}>
              <Typography variant="h5" className={classes.headingColor}>
                Unloading Time
              </Typography>
              <Typography variant="h6" style={{fontWeight: 700}}>
                {site.unloadingTimeInMinutes}
              </Typography>
            </Box>
          </Box>
        </Card>
      </div>
    </div>
  );
}
