import React from 'react';
import {useLocalStore} from 'mobx-react';
import {planningSolutionViewStoreContext} from './PlanningSolutionView.context';
import {planningSolutionViewStore} from './PlanningSolutionView.store';

// eslint-disable-next-line import/prefer-default-export
export const PlanningSolutionViewProvider: React.FC = ({children}) => {
  const store = useLocalStore(planningSolutionViewStore);

  return (
    <planningSolutionViewStoreContext.Provider value={store}>
      {children}
    </planningSolutionViewStoreContext.Provider>
  );
};
