import React from 'react';
import Loader from '../../loader/Loader';

interface Props {
  srcUrl?: string;
  onLoad: () => void;
  style?: React.CSSProperties;
}

export const DashboardDataStudioDashboard: React.FC<Props> = ({
  srcUrl,
  onLoad,
  style,
}) => {
  const [fstyle, setFstyle] = React.useState<React.CSSProperties>({
    visibility: 'hidden',
  });

  // dirty fix to force gds to render correctly
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      style && setFstyle(style);
    }, 5000);
    return () => {
      clearTimeout(timeout);
    };
  }, [style]);

  return (
    <>
      {fstyle.visibility === 'hidden' && <Loader />}
      <iframe
        title="gds-dashboard"
        style={{border: 0, ...fstyle}}
        src={srcUrl}
        onLoad={onLoad}
        allowFullScreen
        frameBorder={0}
      />
    </>
  );
};
