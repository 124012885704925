import React from 'react';
import {WebMasterTripDebriefConfigList} from '@onroadvantage/onroadvantage-api';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../../factory/template';
import {
  TripDebriefConfigListContext,
  useTripDebriefConfigListContext,
} from './TripDebriefConfigListContext';

export const TripDebriefConfigList: React.FC = () => {
  const {loadList, cleanupList} = useTripDebriefConfigListContext();

  const config = React.useMemo<
    TemplateTableConfig<WebMasterTripDebriefConfigList>
  >(
    () => ({
      columns: [
        {name: 'name', label: 'Name', type: 'string'},
        {name: 'tatThreshold', label: 'Tat Threshold', type: 'number'},
        {
          name: 'varianceThreshold',
          label: 'Variance % KM Threshold',
          type: 'number',
        },
        {name: 'contractCode', label: 'Contract Code', type: 'string'},
      ],
      enableFilter: true,
      enableSort: true,
      deleteMessage: (row) => ({
        title: 'Trip Debrief Config',
        items: {
          Name: row.name,
          ContractCode: row.contractCode,
        },
      }),
      identifier: 'TRIP_DEBRIEF_CONFIG_LIST',
    }),
    []
  );

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [cleanupList, loadList]);

  return (
    <TemplateTableWrapper
      config={config}
      context={TripDebriefConfigListContext}
    />
  );
};
