import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';

const styles = {
  container: {
    margin: 48,
    maxWidth: 500,
  },
};

export const ErrorUnauthorized = () => {
  return (
    <Card style={styles.container}>
      <CardHeader title="Unauthorized" />
      <CardContent>
        <Typography>
          You are not authorized to access the page that you have requested.
          Please contact your system administrator.
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ErrorUnauthorized;
