import React from 'react';
import {useHistory} from 'react-router-dom';
import {
  ApiWebContractGetRequest,
  WebContractDump,
  WebContractListResponse,
} from '@onroadvantage/onroadvantage-api';
import {contractApi, webContractApi} from '../../../api';
import {
  TemplateTableContextProps,
  TLoadList,
  useTemplateTable,
} from '../../../factory/template';
import {useAppNotifications} from '../../../contexts';

export type ContractListContextProps = TemplateTableContextProps<
  WebContractDump,
  WebContractListResponse
>;

export const useContractListContext = () =>
  React.useContext(ContractListContext);

export const ContractListContext =
  React.createContext<ContractListContextProps>({
    loading: false,
    list: [],
    currentPage: 1,
    loadList: async () => {},
  });

export const ContractListContextProvider: React.FC = ({children}) => {
  const history = useHistory();
  const notify = useAppNotifications();

  const [
    {
      // States
      currentPage,
      filters,
      hasPermission,
      itemTotal,
      list,
      loading,
      pageSize,
      pageTotal,
      sorting,
    },
    {
      // Getters
      getDownloads,
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
      setList,
    },
  ] = useTemplateTable<WebContractDump, ApiWebContractGetRequest>({
    editPermission: 'Edit Contract',
    addPermission: 'Add Contract',
    deletePermission: 'Delete Contract',
    downloadPermission: 'Contract ListDownload',
    viewPermission: 'Contract List',
  });

  const loadList = React.useCallback<TLoadList<WebContractListResponse>>(
    async (options) => {
      setLoading(true);
      try {
        const requestObj = getRequestObj(['name', 'code'], options);
        const response = await webContractApi.apiWebContractGet(requestObj);
        return getResponse(response, options);
      } catch (e) {
        notify('error', 'Failed to load contracts');
      } finally {
        setLoading(false);
      }
    },
    [setLoading, getRequestObj, getResponse, notify]
  );

  const handleDownload = React.useCallback(
    async () =>
      getDownloads('contracts', loadList, [
        {name: 'Contract Name', path: 'name'},
        {name: 'Contract Code', path: 'code'},
      ]),
    [getDownloads, loadList]
  );

  const handleDelete = React.useCallback(
    async (row: WebContractDump) => {
      setLoading(true);
      try {
        if (row.id) {
          const response = await contractApi.apiContractContractIdDelete({
            contractId: row.id,
          });
          if (response) {
            setList((prevList) => prevList.filter(({id}) => id !== row.id));
          }
          notify('success', 'Deleted contract');
        }
      } catch (e) {
        notify('error', 'Failed to delete contract');
      } finally {
        setLoading(false);
      }
    },
    [notify, setList, setLoading]
  );

  const handleAdd = React.useCallback(() => {
    history.push(`/contractlist/add`);
  }, [history]);

  const handleNavigate = React.useCallback(
    (row: WebContractDump) => {
      history.push(`/contractlist/${row.id}`);
    },
    [history]
  );

  const handleRefresh = React.useCallback(() => {
    loadList();
  }, [loadList]);

  const value: ContractListContextProps = {
    loading,
    list,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onFiltersChange: handleFiltersChange,
    onSortingChange: handleSortingChange,
    onNavigate: handleNavigate,
    onAdd: hasPermission.add ? handleAdd : undefined,
    onDelete: hasPermission.delete ? handleDelete : undefined,
    onDownload: hasPermission.download ? handleDownload : undefined,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onRefresh: handleRefresh,
    loadList,
    cleanupList,
  };

  return (
    <ContractListContext.Provider value={value}>
      {children}
    </ContractListContext.Provider>
  );
};
