import React from 'react';
import {
  NUMBER_ERROR_MESSAGE,
  TemplateCard,
  TemplateForm,
} from '../../factory/template';
import {TrainingCourseContext} from './TrainingCourseContext';
import {TrainingCourse} from '@onroadvantage/onroadvantage-api';
import * as Yup from 'yup';
import {FormikTextField} from '../formik';
import validator from 'validator';

export interface ITrainingCourseForm {
  trainingCourseId?: number;
  name: string;
  category?: string;
  link?: string;
  passMark?: number;
}

const schema: Yup.SchemaOf<ITrainingCourseForm> = Yup.object({
  trainingCourseId: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
  name: Yup.string().required('Required'),
  category: Yup.string(),
  link: Yup.string().test('is-url', 'Not a valid link', (value: any) => {
    if (value) {
      return validator.isURL(value);
    }
    return true;
  }),
  passMark: Yup.number().typeError(NUMBER_ERROR_MESSAGE),
});

const getInitialValues = (
  trainingCourse?: TrainingCourse | null
): ITrainingCourseForm | undefined => {
  if (trainingCourse) {
    return {
      trainingCourseId: trainingCourse.id || undefined,
      name: trainingCourse.name ?? '',
      category: trainingCourse.category ?? '',
      link: trainingCourse.link ?? '',
      passMark: trainingCourse.passMark || undefined,
    };
  }
  return undefined;
};

export const TrainingCourseForm: React.FC<{isAdd?: boolean}> = ({isAdd}) => {
  const {detailsRef, loading, submitting, trainingCourse, onDetailsFormSubmit} =
    React.useContext(TrainingCourseContext);
  const [initialValues, setInitialValues] = React.useState<
    ITrainingCourseForm | undefined
  >();

  React.useEffect(() => {
    const values = getInitialValues(trainingCourse);

    if (values) {
      setInitialValues(values);
    } else if (isAdd) {
      setInitialValues({name: ''});
    }
  }, [isAdd, trainingCourse, setInitialValues]);

  return (
    <TemplateCard
      title={`Training Course Details ${isAdd ? 'Add' : 'Edit'}`}
      loading={loading}
    >
      <TemplateForm<ITrainingCourseForm>
        initialValues={initialValues}
        onSubmit={onDetailsFormSubmit}
        permission={{
          name: `${isAdd ? 'Add' : 'Edit'} TrainingCourse`,
          type: isAdd ? 'Add' : 'Edit',
        }}
        submitting={submitting}
        innerRef={detailsRef}
        validationSchema={schema}
      >
        <FormikTextField
          name="name"
          placeholder="Enter the Course name"
          label="Training Course Name"
          fullWidth
        />
        <FormikTextField
          name="category"
          placeholder="Enter the Course Category"
          label="Training Course Category"
          fullWidth
        />
        <FormikTextField
          name="link"
          placeholder="Enter the Course Link"
          label="Training Course Link"
          fullWidth
        />
        <FormikTextField
          name="passMark"
          placeholder="Enter the Course Pass Mark"
          label="Training Course Pass Mark"
          fullWidth
        />
      </TemplateForm>
    </TemplateCard>
  );
};
