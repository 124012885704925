import {makeStyles, createStyles} from '@mui/styles';

const GeoSearchCardStyles = () =>
  createStyles({
    header: {cursor: 'pointer'},
    inputField: {
      display: 'flex',
      flex: 1,
      marginLeft: 28,
      marginRight: 28,
      paddingBottom: 10,
    },
    list: {padding: 10, paddingLeft: 30},
    loader: {margin: 20, padding: 20, paddingLeft: 50},
    root: {
      marginRight: 10,
      flexGrow: 1,
      flex: 1,
      width: 300,
      paddingBottom: 30,
    },
    searchIcon: {cursor: 'pointer'},
  });

export const useGeoSearchCardStyles = makeStyles(GeoSearchCardStyles, {
  name: 'GeoSearchCardStyles',
});
