import React from 'react';
import {
  ApiWebDocumentStorageTripStopTripStopIdGetRequest,
  WebListDocumentStorageDump,
  WebListDocumentStorageListResponse,
} from '@onroadvantage/onroadvantage-api';
import {useAppNotifications} from '../../../../contexts';
import {documentStorageApi, webDocumentStorageApi} from '../../../../api';
import {
  TemplateTableContextProps,
  useTemplateTable,
} from '../../../../factory/template';
import {useTripStopContext} from '../tripStopContext';

export type TripStopDocumentListContextProps = TemplateTableContextProps<
  WebListDocumentStorageDump,
  WebListDocumentStorageListResponse
>;

const tripStopDocumentListContextInitial: TripStopDocumentListContextProps = {
  loading: false,
  list: [],
  currentPage: 1,
  loadList: async () => {},
};

export const TripStopDocumentListContext =
  React.createContext<TripStopDocumentListContextProps>(
    tripStopDocumentListContextInitial
  );

export const TripStopDocumentListContextProvider: React.FC = ({children}) => {
  const notify = useAppNotifications();
  const {tripStopId} = useTripStopContext();

  const [
    {
      // States
      currentPage,
      filters,
      itemTotal,
      list,
      loading,
      pageSize,
      pageTotal,
      sorting,
    },
    {
      // Getters
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
    },
  ] = useTemplateTable<
    WebListDocumentStorageDump,
    ApiWebDocumentStorageTripStopTripStopIdGetRequest
  >();

  // load handlers
  const loadList = React.useCallback<
    TripStopDocumentListContextProps['loadList']
  >(
    async (options) => {
      setLoading(true);
      try {
        if (tripStopId) {
          const requestObj = getRequestObj(
            ['tripStopId', 'documentType', 'status'],
            options,
            {tripStopId}
          );

          const response =
            await webDocumentStorageApi.apiWebDocumentStorageTripStopTripStopIdGet(
              {
                ...requestObj,
                tripStopId,
              }
            );
          return getResponse(response, options);
        }
      } catch (e) {
        notify('error', 'Failed to load documents');
      } finally {
        setLoading(false);
      }
    },
    [getRequestObj, getResponse, notify, setLoading, tripStopId]
  );

  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  const handleDownloadItem = React.useCallback(
    async (document: WebListDocumentStorageDump) => {
      if (document?.id) {
        const response =
          await documentStorageApi.apiDocumentStorageDocumentStorageIdDownloadLinkGet(
            {
              documentStorageId: document.id,
            }
          );

        if (response && response.downloadLink) {
          window.open(response.downloadLink, '_blank');
        }
      }
    },
    []
  );

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [cleanupList, loadList]);

  const value: TripStopDocumentListContextProps = {
    loading: loading,
    list,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    elevation: 0,
    nestedLevel: 1,
    onFiltersChange: handleFiltersChange,
    onSortingChange: handleSortingChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onDownloadItem: handleDownloadItem,
    onRefresh: handleRefresh,
    loadList,
    cleanupList,
  };

  return (
    <TripStopDocumentListContext.Provider value={value}>
      {children}
    </TripStopDocumentListContext.Provider>
  );
};
