import React from 'react';
import {
  MasterTripDebriefSnapshot,
  MasterTripDebriefSnapshotData,
} from '@onroadvantage/onroadvantage-api';
import {Collapse, Divider, Stack, Typography} from '@mui/material';
import {
  TripDebriefSummaryCard,
  TripDebriefSummaryCardList,
} from '../tripDebriefSummaryCard';
import {TripDebriefContext} from '../../TripDebriefContext';
import {TripDebriefSummaryKilometersForm} from './TripDebriefSummaryKilometersForm';
import {getTripActualDistance} from '../../helpers/getTripActualDistance';

export const TripDebriefSummaryKilometers: React.FC = () => {
  const {masterTripDebriefConfig, masterTripDebriefData, onTabNavigate} =
    React.useContext(TripDebriefContext);
  /**
   *  Destructure masterTripDebriefData, need to add || ({} as MasterTripDebriefSnapshotData) for typescript, since
   *  masterTripDebriefData is nullable.
   */
  const {snapshotData, overrideData} =
    masterTripDebriefData || ({} as MasterTripDebriefSnapshotData);
  /**
   *  Destructure snapshotData, need to add || ({} as MasterTripDebriefSnapshot) for typescript, since
   *  snapshotData is nullable.
   */
  const {plannedTripDistance, creditKilometers} =
    snapshotData || ({} as MasterTripDebriefSnapshot);
  /**
   *  Destructure overrideData, need to add || ({} as MasterTripDebriefSnapshot) for typescript, since
   *  overrideData is nullable.
   */
  const {
    actualTripDistance: actualTripDistanceOverride,
    creditKilometers: creditKilometersOverride,
  } = overrideData || ({} as MasterTripDebriefSnapshot);

  const [varianceExceeds, setVarianceExceeds] = React.useState<boolean>(false);

  const {actualDistance, displayVariance, percentageVariance} = React.useMemo(
    () => getTripActualDistance(masterTripDebriefData),
    [masterTripDebriefData]
  );

  const list = React.useMemo<TripDebriefSummaryCardList>(() => {
    return [
      {
        primary: 'Planned trip distance',
        secondary: plannedTripDistance,
        type: 'kilometer',
        changed: false,
      },
      {
        primary: 'Actual debriefed trip distance',
        secondary: actualDistance,
        type: 'kilometer',
        changed: !!actualTripDistanceOverride,
      },
      {
        primary: 'Credit kilometers',
        secondary: creditKilometersOverride ?? creditKilometers,
        type: 'kilometer',
        changed: !!creditKilometersOverride,
      },
      {
        primary: 'Variance of trip distance',
        secondary: displayVariance,
        type: 'string',
        changed: false,
      },
    ];
  }, [
    actualDistance,
    actualTripDistanceOverride,
    creditKilometers,
    creditKilometersOverride,
    displayVariance,
    plannedTripDistance,
  ]);

  const handleNavigate = React.useCallback(() => {
    onTabNavigate('Kilometers');
  }, [onTabNavigate]);

  React.useEffect(() => {
    if (
      masterTripDebriefConfig?.varianceThreshold &&
      percentageVariance > masterTripDebriefConfig?.varianceThreshold
    ) {
      setVarianceExceeds(true);
    }

    return () => {
      setVarianceExceeds(false);
    };
  }, [
    masterTripDebriefConfig?.varianceThreshold,
    masterTripDebriefData,
    percentageVariance,
  ]);

  return (
    <TripDebriefSummaryCard
      title="Kilometers"
      list={list}
      onNavigate={handleNavigate}
    >
      <Collapse in={varianceExceeds}>
        <Stack spacing={2}>
          <Divider />
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2">Config Variance Threshold: </Typography>
            <Typography variant="body2" color="text.secondary">
              {masterTripDebriefConfig?.varianceThreshold}%
            </Typography>
          </Stack>
          <TripDebriefSummaryKilometersForm />
        </Stack>
      </Collapse>
    </TripDebriefSummaryCard>
  );
};
