import React from 'react';
import {
  usePlanningBoardTripCompartments,
  usePlanningBoardTripCompartmentsResponse,
  usePlanningBoardTripCompartmentsResponseInitial,
} from './usePlanningBoardTripCompartments';
import {
  usePlanningBoardContext,
  usePlanningBoardGanttContext,
} from '../../../../planningBoardContext';
import {usePlanningBoardTripContext} from '../../planningBoardTripContext';

export const PlanningBoardTripCompartmentsContext =
  React.createContext<usePlanningBoardTripCompartmentsResponse>(
    usePlanningBoardTripCompartmentsResponseInitial
  );

export const PlanningBoardTripCompartmentsContextProvider: React.FC = ({
  children,
}) => {
  const {getVehicle, getMasterTrip, hasPermission, loadMasterTrips} =
    usePlanningBoardContext();
  const {
    onClearSelectedGanttItem,
    selectedGanttItem,
    setSelectedGanttItem,
    selectedVehicleCapacityDimensionOption,
  } = usePlanningBoardGanttContext();
  const {onClearTripState} = usePlanningBoardTripContext();

  const value = usePlanningBoardTripCompartments({
    hasPermission,
    getVehicle,
    getMasterTrip,
    onClearSelectedGanttItem,
    onClearTripState,
    selectedGanttItem,
    setSelectedGanttItem,
    selectedVehicleCapacityDimensionOption,
    loadMasterTrips,
  });

  return (
    <PlanningBoardTripCompartmentsContext.Provider value={value}>
      {children}
    </PlanningBoardTripCompartmentsContext.Provider>
  );
};

export const usePlanningBoardTripCompartmentsContext = () =>
  React.useContext(PlanningBoardTripCompartmentsContext);
