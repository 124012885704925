import React from 'react';
import {Card, CardContent} from '@mui/material';
import Timeline from '../../timeline/timeline';
import {MasterTripRecipientContext} from './MasterTripRecipientContext';

export const MasterTripRecipientGantt: React.FC = () => {
  const {masterTrip} = React.useContext(MasterTripRecipientContext);
  const [trip] = React.useState(masterTrip?.trip);
  if (!trip) return null;
  return (
    <Card>
      <CardContent>
        <Timeline
          trip={masterTrip?.trip}
          height={200}
          actualTrip={[]}
          disableEventFilter
          disableOtherTrip
          disableActual
          enableExecution
        />
      </CardContent>
    </Card>
  );
};
