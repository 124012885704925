import React from 'react';
import {IconButton, InputAdornment, TextField} from '@mui/material';
import {Close, Search} from '@mui/icons-material';
import {
  usePlanningBoardContext,
  usePlanningBoardGanttContext,
} from '../../planningBoardContext';
import {usePlanningBoardToolbarSearchStyles} from './PlanningBoardToolbarSearch.style';

export const PlanningBoardToolbarSearchOrder: React.FC = () => {
  const classes = usePlanningBoardToolbarSearchStyles();
  const {planningMasterTrips, masterMasterTrips, bookingMasterTrips} =
    usePlanningBoardContext();
  const {searchedTrips, searchOrderRef, setSearchedTrips} =
    usePlanningBoardGanttContext();

  const handleSubmitOrderNumberSearch = React.useCallback(() => {
    setSearchedTrips([]);

    const searchValue =
      searchOrderRef.current?.value?.toLowerCase() ?? undefined;

    if (!searchValue) {
      setSearchedTrips([]);
    } else {
      const allTrips = bookingMasterTrips
        .concat(masterMasterTrips)
        .concat(planningMasterTrips);
      allTrips.forEach(({id, trip}) => {
        if (!id || !trip) return;

        const orderNumbers: string[] = [];
        const orderTrackingNumbers: string[] = [];
        const customerReferenceNumbers: string[] = [];
        let didOrderNumberMatchSimilar = false;

        trip?.stops?.forEach(({orders}) => {
          orders?.forEach(
            ({orderNumber, orderTrackingNumber, customerReferenceNumber}) => {
              if (orderNumber && !orderNumbers.includes(orderNumber)) {
                orderNumbers.push(orderNumber.toLowerCase());
                if (orderNumber.toLowerCase().includes(searchValue)) {
                  didOrderNumberMatchSimilar = true;
                }
              }
              if (
                orderTrackingNumber &&
                !orderTrackingNumbers.includes(orderTrackingNumber)
              ) {
                orderTrackingNumbers.push(orderTrackingNumber.toLowerCase());
              }
              if (
                customerReferenceNumber &&
                !customerReferenceNumbers.includes(customerReferenceNumber)
              ) {
                customerReferenceNumbers.push(
                  customerReferenceNumber.toLowerCase()
                );
              }
            }
          );
        });

        if (
          didOrderNumberMatchSimilar ||
          orderNumbers.includes(searchValue) ||
          orderTrackingNumbers.includes(searchValue) ||
          customerReferenceNumbers.includes(searchValue)
        ) {
          setSearchedTrips((prevSearchedTrips) =>
            id ? [...prevSearchedTrips, id] : prevSearchedTrips
          );
        }
      });
    }
  }, [
    bookingMasterTrips,
    masterMasterTrips,
    planningMasterTrips,
    searchOrderRef,
    setSearchedTrips,
  ]);

  const handleEnterSubmit = React.useCallback<React.KeyboardEventHandler>(
    (event) => {
      if (event.key === 'Enter') {
        handleSubmitOrderNumberSearch();
      }
    },
    [handleSubmitOrderNumberSearch]
  );

  const handleClearSearch = React.useCallback(() => {
    setSearchedTrips([]);
    if (searchOrderRef.current?.value) {
      searchOrderRef.current.value = '';
    }
  }, [searchOrderRef, setSearchedTrips]);

  return (
    <TextField
      inputRef={searchOrderRef}
      name="search"
      label="Search Order"
      placeholder="Search Order"
      size="small"
      onKeyDown={handleEnterSubmit}
      className={classes.input}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {searchedTrips.length > 0 ? (
              <IconButton size="small" onClick={handleClearSearch}>
                <Close fontSize="small" />
              </IconButton>
            ) : null}
            <IconButton size="small" onClick={handleSubmitOrderNumberSearch}>
              <Search fontSize="small" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
