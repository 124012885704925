import React from 'react';
import _ from 'lodash';
import {DriverGroup} from './DriverGroup';
import {
  DriverGroupContextProvider,
  useDriverGroupContext,
} from './driverGroupContext';
import {
  ITemplateControllerRoute,
  TemplateController,
} from '../../factory/template';
import {
  DriverGroupList,
  DriverGroupListContextProvider,
} from './driverGroupList';
import {DriverGroupForm} from './DriverGroupForm';
import {DriverGroupIdHandler} from './DriverGroupIdHandler';

export const DriverGroupController: React.FC = () => {
  return (
    <DriverGroupListContextProvider>
      <DriverGroupContextProvider>
        <DriverGroupControllerComponent />
      </DriverGroupContextProvider>
    </DriverGroupListContextProvider>
  );
};

export const DriverGroupControllerComponent: React.FC = () => {
  const {driverGroup, loadingDriverGroup} = useDriverGroupContext();

  const routes: ITemplateControllerRoute[] = React.useMemo(
    () => [
      {title: 'Driver Group List', component: DriverGroupList},
      {
        path: '/add',
        title: 'New Driver Group',
        name: 'Add Driver',
        type: 'Add',
        component: () => <DriverGroupForm isAdd />,
      },
      {
        path: '/:driverGroupId',
        title: (params) =>
          loadingDriverGroup
            ? 'Driver Group Loading...'
            : driverGroup?.name ??
              `Driver Group ${_.get(params, 'driverGroupId', 'Single')}`,
        component: DriverGroup,
        IdHandler: DriverGroupIdHandler,
      },
    ],
    [loadingDriverGroup, driverGroup?.name]
  );
  return <TemplateController rootPath="/drivergrouplist" routes={routes} />;
};
