import * as React from 'react';
import * as PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import withStyles from '@mui/styles/withStyles';

const styles = (theme) => ({
  Cell: {
    paddingBottom: 0,
    paddingTop: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
});

export const ActionsCellBase = ({classes, style, children}) => (
  <TableCell className={classes.Cell} style={style}>
    {children}
  </TableCell>
);

ActionsCellBase.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};
ActionsCellBase.defaultProps = {
  style: {},
};

export const ActionsCell = withStyles(styles, {name: 'ActionsCell'})(
  ActionsCellBase
);
