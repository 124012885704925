import React from 'react';
import _ from 'lodash';
import {
  ITemplateControllerRoute,
  TemplateController,
} from '../../factory/template';
import {
  DocumentTemplateContext,
  DocumentTemplateContextProvider,
} from './DocumentTemplateContext';
import {DocumentTemplateList} from './DocumentTemplateList';
import {DocumentTemplateIdHandler} from './DocumentTemplateIdHandler';
import {DocumentTemplateForm} from './DocumentTemplateForm';
import {DocumentTemplate} from './DocumentTemplate';

export const DocumentTemplateController: React.FC = () => {
  return (
    <DocumentTemplateContextProvider>
      <DocumentTemplateControllerComponent />
    </DocumentTemplateContextProvider>
  );
};

const DocumentTemplateControllerComponent: React.FC = () => {
  const {documentTemplate, loading} = React.useContext(DocumentTemplateContext);
  const routes: ITemplateControllerRoute[] = React.useMemo(
    () => [
      {title: 'Document Template List', component: DocumentTemplateList},
      {
        path: '/add',
        title: 'New Document Template',
        name: 'Add DocumentTemplate',
        type: 'Add',
        component: () => <DocumentTemplateForm isAdd />,
      },
      {
        path: '/:documentTemplateId',
        title: (params) =>
          loading
            ? 'Document Template Loading...'
            : documentTemplate?.name ??
              `Document Template ${_.get(
                params,
                'documentTemplateId',
                'Single'
              )}`,
        component: DocumentTemplate,
        IdHandler: DocumentTemplateIdHandler,
      },
    ],
    [loading, documentTemplate?.name]
  );
  return (
    <TemplateController rootPath="/documenttemplatelist" routes={routes} />
  );
};
