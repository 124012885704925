import React from 'react';
import {NotificationConfigContext} from '../NotificationConfigContext';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../../factory/template';
import {EventNotificationConfig} from '@onroadvantage/onroadvantage-api';
import {NotificationConfigCriticalEventContext} from './NotificationConfigCriticalEventContext';

export const NotificationConfigCriticalEventTriggersList: React.FC = () => {
  const {notificationConfig} = React.useContext(NotificationConfigContext);

  const config: TemplateTableConfig<EventNotificationConfig> = React.useMemo(
    () => ({
      columns: [
        {
          name: 'contractCriticalEventTypeConfig',
          label: 'Event Type Name',
          type: 'string',
          model: ['ContractCriticalEventTypeConfig'],
          enableEditing: false,
          enableAutocomplete: true,
          getValue: ({contractCriticalEventTypeConfig}) =>
            contractCriticalEventTypeConfig?.criticalEventType?.name,
        },
        {
          name: 'contractCode',
          label: 'Contract Code',
          type: 'string',
          enableEditing: false,
          getValue: ({contractCriticalEventTypeConfig}) =>
            contractCriticalEventTypeConfig?.contract?.code,
        },
        {
          name: 'notifyContractContactGroups',
          label: 'Contract Contact Groups',
          type: 'bool',
          enableEditing: true,
        },
        {
          name: 'notifyCustomerContactGroups',
          label: 'Customer Contact Groups',
          type: 'bool',
          enableEditing: true,
        },
        {
          name: 'notifyNodeContactGroups',
          label: 'Node Contact Groups',
          type: 'bool',
          enableEditing: true,
        },
        {
          name: 'notifyVehicleGroupContactGroups',
          label: 'Vehicle Group Contact Groups',
          type: 'bool',
          enableEditing: true,
        },
        {
          name: 'notifyDriver',
          label: 'Driver',
          type: 'bool',
          enableEditing: true,
        },
        {
          name: 'notifyVehicle',
          label: 'Vehicle',
          type: 'bool',
          enableEditing: true,
        },
        {
          name: 'notifyWeb',
          label: 'Web',
          type: 'bool',
          enableEditing: true,
        },
        {
          name: 'notifyNextStop',
          label: 'Next Stop',
          type: 'bool',
          enableEditing: true,
        },
        {
          name: 'notifyOffloadingStops',
          label: 'Offload Stops',
          type: 'bool',
          enableEditing: true,
        },
      ],
      disablePagination: true,
      disableToolbar: true,
      deleteMessage: (row) => ({
        title: 'Notification Config Event',
        items: {
          NotificationConfigName: notificationConfig?.name,
          EventType:
            row?.contractCriticalEventTypeConfig?.criticalEventType?.name,
          ContractCode: row?.contractCriticalEventTypeConfig?.contract?.code,
        },
      }),
      identifier: 'NOTIFICATION_CONFIG_EVENT_TRIGGERS_LIST',
    }),
    [notificationConfig?.name]
  );

  return (
    <TemplateTableWrapper
      config={config}
      context={NotificationConfigCriticalEventContext}
    />
  );
};
