import React from 'react';
import {
  MobileDateTimePicker,
  MobileDateTimePickerProps,
  StaticDateTimePicker,
} from '@mui/x-date-pickers';
import {useFormikContext} from 'formik';
import moment, {Moment} from 'moment';
import {InputLabelProps} from '@mui/material';
import {FormikDateTimePickerField} from './FormikDateTimePickerField';

export interface FormikDateTimePickerProps
  extends Omit<
    MobileDateTimePickerProps<Date, Date>,
    'value' | 'onChange' | 'renderInput'
  > {
  id?: string;
  name: string;
  placeholder?: string;
  minReference?: string;
  maxReference?: string;
  displayStaticWrapperAs?: 'desktop' | 'mobile';
  InputLabelProps?: Partial<InputLabelProps>;
  fullWidth?: boolean;
  disableDefaultValue?: boolean;
  staticMode?: boolean;
  format?: string;
}

export const FormikDateTimePickerContext =
  React.createContext<FormikDateTimePickerProps>({name: ''});

export const FormikDateTimePicker: React.FC<FormikDateTimePickerProps> = (
  props
) => {
  return (
    <FormikDateTimePickerContext.Provider value={props}>
      <FormikDateTimePickerComponent />
    </FormikDateTimePickerContext.Provider>
  );
};

const FormikDateTimePickerComponent: React.FC = () => {
  const {
    name,
    disableDefaultValue,
    disabled,
    minReference,
    maxReference,
    ...props
  } = React.useContext(FormikDateTimePickerContext);
  const {isSubmitting, getFieldMeta, getFieldHelpers} = useFormikContext();
  const {value, ...meta} = getFieldMeta(name);
  const {setValue} = getFieldHelpers(name);
  const minReferenceFieldMeta = minReference
    ? getFieldMeta(minReference)
    : undefined;
  const maxReferenceFieldMeta = maxReference
    ? getFieldMeta(maxReference)
    : undefined;

  const [parsedValue, setParsedValue] = React.useState<Moment | null>(null);

  React.useEffect(() => {
    if (
      value instanceof moment ||
      value instanceof Date ||
      typeof value === 'string'
    ) {
      setParsedValue(moment(value));
    } else if (!value && !disableDefaultValue) {
      setParsedValue(moment());
      setValue(moment());
    }
    return () => setParsedValue(null);
    // eslint-disable-next-line
  }, [value]);

  const handleChange = React.useCallback(
    (date: Date | null) => {
      setValue(date);
      setParsedValue(moment(date));
    },
    [setValue]
  );

  return (
    <HandleDateTimePickerState
      {...props}
      {...meta}
      disabled={disabled || isSubmitting}
      minDateTime={
        minReferenceFieldMeta?.value
          ? new Date(minReferenceFieldMeta.value as string | Date)
          : undefined
      }
      maxDateTime={
        maxReferenceFieldMeta?.value
          ? new Date(maxReferenceFieldMeta.value as string | Date)
          : undefined
      }
      ampm={false}
      onChange={handleChange}
      name={name}
      value={parsedValue?.toDate() ?? null}
      renderInput={FormikDateTimePickerField}
    />
  );
};

export const HandleDateTimePickerState: React.FC<
  FormikDateTimePickerProps & MobileDateTimePickerProps<Date, Date>
> = ({staticMode, displayStaticWrapperAs, ...props}) => {
  return staticMode ? (
    <StaticDateTimePicker
      {...props}
      displayStaticWrapperAs={displayStaticWrapperAs}
    />
  ) : (
    <MobileDateTimePicker {...props} />
  );
};
