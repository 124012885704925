import React from 'react';
import vis from 'vis';
import 'vis/dist/vis.min.css';
import {withRouter} from 'react-router-dom';
import debounce from 'lodash/debounce';
import './custom.css';
import {inject, observer} from 'mobx-react';
import {autorun} from 'mobx';
import {RoleService} from '../../service';
import {timelineDisplayStore} from '../../stores/mobxStores';
import PropTypes from 'prop-types';
import {getTimelineItems} from './getTimelineItems';
import moment from "moment/moment";

class Timeline extends React.Component {
  state = {
    trip: this.props.trip,
    actualTrip: this.props.actualTrip,
    adjacentTrips: this.props.adjacentTrips,
  };

  componentDidMount = async () => {
    const {trip, actualTrip, adjacentTrips, playback} = this.props;

    if (this.Timeline) {
      if (this.Timeline.dom) {
        await this.updateTimeline({trip, actualTrip, adjacentTrips, playback});
      } else {
        await this.initialize({
          trip,
          actualTrip,
          adjacentTrips,
        });
      }
    } else {
      await this.initialize({
        trip,
        actualTrip,
        adjacentTrips,
      });
    }
    autorun(() => {
      if (timelineDisplayStore.timelineSliderTime) {
        this.onIncomingTimeChange(timelineDisplayStore.timelineSliderTime);
      }
    });
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps = async (nextProps) => {
    this.setState({
      trip: nextProps.trip,
      actualTrip: nextProps.actualTrip,
      adjacentTrips: nextProps.adjacentTrips,
    });
    const {telematicsEventsDateRange} = timelineDisplayStore;
    this.Timeline?.setItems(
      this.getItems({
        trip: nextProps.trip,
        actualTrip: nextProps.actualTrip,
        adjacentTrips: nextProps.adjacentTrips,
        telematicsEventsDateRange: {
          startDate: telematicsEventsDateRange.startDate,
          endDate: telematicsEventsDateRange.endDate,
        },
      })
    );
  };

  componentWillUnmount() {
    this.Timeline.destroy();
    timelineDisplayStore.setTimelineSliderTime({
      startDate: moment().subtract(moment.duration('03:00:00')).toISOString(),
      endDate: moment().toISOString(),
    });
  }

  updateTimeline = async ({trip, actualTrip, adjacentTrip, playback}) => {
    if (playback) {
      this.onIncomingTimeChange(timelineDisplayStore.timelineSliderTime);
    } else if (
      trip &&
      ((Object.keys(trip).length > 0 ? trip !== this.state.trip : false) ||
        actualTrip !== this.state.actualTrip)
    ) {
      const {telematicsEventsDateRange} = timelineDisplayStore;
      this.setState({
        trip,
        actualTrip,
        adjacentTrip,
      });
      if (telematicsEventsDateRange.startDate) {
        this.Timeline.setCustomTime(
          telematicsEventsDateRange.startDate,
          'slider-with-tab'
        );
      }
    }
  };

  onIncomingTimeChange = (time) => {
    this.Timeline.setCustomTime(time, 'slider-with-tab');
    this.onSliderDateTimeChange({time});
  };

  onSliderDateTimeChange = debounce((properties) => {
    timelineDisplayStore.setTimelineSliderTime(properties.time);
  }, 3);

  bodySelect = (event) => {
    if ('items' in event) {
      if (event && event.event) {
        if (event.items.length > 0) {
          if (event.items[0].indexOf('/triplist/') > -1) {
            window.open(
              this.props.history.createHref({pathname: `${event.items[0]}`}),
              '_blank'
            );
          }
        }
      }
    }
  };

  getItems = ({trip, actualTrip, adjacentTrips, telematicsEventsDateRange}) => {
    const tripTimeline = getTimelineItems({
      trip,
      actualTrip,
      adjacentTrips: adjacentTrips ?? timelineDisplayStore.adjacentTrips,
      telematicsEventsDateRange,
      enablePlanNested: !this.props.disablePlanNestedGroups,
      enableActualNested: !this.props.disableActualNestedGroups,
    });

    const items = new vis.DataSet(tripTimeline);
    // TODO Is the onUpdate listener being added multiple times?
    items.on('update', async (event, properties) => {
      const {updateTelematicsEventsRange} = this.props;
      const updatedItem = properties.items[0];
      if (updatedItem === 'eventFilter' && updateTelematicsEventsRange) {
        timelineDisplayStore.setTelematicsEventsDateRange({
          startDate: properties.data[0].start,
          endDate: properties.data[0].end,
        });
        await updateTelematicsEventsRange({
          startDate: properties.data[0].start,
          endDate: properties.data[0].end,
        });
        this.Timeline.setItems(
          this.getItems({
            trip: this.state.trip,
            actualTrip: this.state.actualTrip,
            adjacentTrips: this.state.adjacentTrips,
            telematicsEventsDateRange: {
              startDate: properties.data[0].start,
              endDate: properties.data[0].end,
            },
          })
        );
      }
    });
    return items;
  };

  initialize({trip, actualTrip, adjacentTrips}) {
    const {telematicsEventsDateRange} = timelineDisplayStore;

    const options = {
      start: telematicsEventsDateRange.startDate,
      end: telematicsEventsDateRange.endDate,
      width: '100%',
      verticalScroll: true,
      stack: false,
      editable: {
        updateTime: true,
      },
    };

    if (this.props.height) {
      options.height = this.props.height;
    }

    const groups = new vis.DataSet();

    if (!this.props.disableEventFilter) {
      groups.add({
        id: 0,
        content: 'Event Filter: ',
      });
    }

    if (Object.keys(trip).length > 0) {
      if (!this.props.disableOrder) {
        groups.add({
          id: 1,
          content: 'Order: ',
        });
      }

      if (!this.props.disablePlanNestedGroups) {
        groups.add({
          id: 2.1,
          content: 'Forced Waits: ',
        });
        groups.add({
          id: 2.2,
          content: 'Stops: ',
        });
        groups.add({
          id: 2.3,
          content: 'Driving: ',
        });
      }

      if (!this.props.disablePlan) {
        groups.add({
          id: 2,
          content: 'Plan: ',
          nestedGroups: !this.props.disablePlanNestedGroups
            ? [2.1, 2.2, 2.3]
            : undefined,
          showNested: false,
        });
      }
    }

    if (
      RoleService.hasPermission('Revised ETA', 'View') &&
      !this.props.disableLiveRevisedPlan
    ) {
      groups.add({
        id: 3,
        content: 'Live Revised Plan: ',
      });
    }

    if (!this.props.disableActualNestedGroups) {
      groups.add({
        id: 4.1,
        content: 'Scheduled: ',
      });
      groups.add({
        id: 4.2,
        content: 'Unscheduled: ',
      });
      groups.add({
        id: 4.3,
        content: 'Illegal stops: ',
      });
    }

    if (!this.props.disableGpsActual) {
      groups.add({
        id: 4,
        content: 'GPS Actual: ',
        nestedGroups: !this.props.disableActualNestedGroups
          ? [4.1, 4.2, 4.3]
          : undefined,
        showNested: false,
      });
    }

    if (!this.props.disableMobileActual) {
      groups.add({
        id: 5,
        content: 'Mobile Actual: ',
      });
    }

    if (!this.props.disableOtherTrip) {
      groups.add({
        id: 6,
        content: 'Other Trips: ',
      });
    }

    const container = document.getElementById('timeline');
    const items = this.getItems({
      trip,
      actualTrip,
      adjacentTrips,
      telematicsEventsDateRange,
    });

    this.Timeline = new vis.Timeline(container, items, groups, options);
    if (telematicsEventsDateRange.startDate) {
      this.Timeline.addCustomTime(
        telematicsEventsDateRange.startDate,
        'slider-with-tab'
      );
      this.Timeline.on('timechange', this.onSliderDateTimeChange);
    }
    this.Timeline.on('select', this.bodySelect);
  }

  render() {
    return <div id="timeline" />;
  }

  /* eslint-enable */
}

Timeline.propTypes = {
  actualTrip: PropTypes.any,
  enableExecution: PropTypes.bool,
  disableGpsActual: PropTypes.bool,
  disableMobileActual: PropTypes.bool,
  disableActualNestedGroups: PropTypes.bool,
  disableEventFilter: PropTypes.bool,
  disableLiveRevisedPlan: PropTypes.bool,
  disableOrder: PropTypes.bool,
  disableOtherTrip: PropTypes.bool,
  disablePlan: PropTypes.bool,
  disablePlanNestedGroups: PropTypes.bool,
  height: PropTypes.number,
  trip: PropTypes.any,
  adjacentTrips: PropTypes.any,
};

export default inject('timelineDisplayStore')(observer(withRouter(Timeline)));
