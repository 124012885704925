import React from 'react';
import {NotificationConfigCriticalEscalationContext} from './NotificationConfigCriticalEscalationContext';
import {useParams} from 'react-router-dom';

interface IParams {
  notificationConfigEventEscalationId?: string;
}

export const NotificationConfigCriticalEscalationIdHandler: React.FC = () => {
  const {setNotificationConfigEventEscalationId} = React.useContext(
    NotificationConfigCriticalEscalationContext
  );

  const {notificationConfigEventEscalationId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = notificationConfigEventEscalationId
      ? parseInt(notificationConfigEventEscalationId)
      : undefined;
    setNotificationConfigEventEscalationId(parsedId);
    return () => setNotificationConfigEventEscalationId(undefined);
  }, [
    notificationConfigEventEscalationId,
    setNotificationConfigEventEscalationId,
  ]);

  return null;
};
