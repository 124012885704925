import React from 'react';
import {useContractContext} from './contractContext';
import {ITemplateTabControllerTab, TemplateTabs} from '../../factory/template';
import {ContractDriverEventReasonCodeList} from './contractDriverEventReasonCodeList';
import {ContractBroadcastTemplateList} from './contractBroadcastTemplateList';
import {ContractTaskTemplateList} from './ContractTaskTemplateList';
import {
  ContractDetailsForm,
  ContractInspectionForm,
  ContractTripExecutionForm,
  ContractTripNotificationsForm,
  ContractTripTimesForm,
} from './contractForms';

export const Contract: React.FC = () => {
  const {loadingContract, contractId} = useContractContext();

  const tabs: ITemplateTabControllerTab[] = React.useMemo(
    () => [
      {title: 'Details', element: <ContractDetailsForm />},
      {
        title: 'Task Templates By Site Type',
        element: <ContractTaskTemplateList />,
      },
      {title: 'Trip Times', element: <ContractTripTimesForm />},
      {title: 'Trip Execution', element: <ContractTripExecutionForm />},
      {title: 'Trip Notifications', element: <ContractTripNotificationsForm />},
      {title: 'Inspection', element: <ContractInspectionForm />},
      {
        title: 'Driver Event Reason Codes',
        element: <ContractDriverEventReasonCodeList />,
      },
      {
        title: 'Broadcast Template',
        element: <ContractBroadcastTemplateList />,
      },
    ],
    []
  );

  const path = `/contractlist/${contractId}`;

  return <TemplateTabs path={path} tabs={tabs} loading={loadingContract} />;
};
