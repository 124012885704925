import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

export const ReportStyles = (theme: Theme) =>
  createStyles({
    root: {},
    tabs: {
      borderBottomWidth: 1,
      borderBottomColor: theme.palette.divider,
      borderBottomStyle: 'solid',
    },
  });

export const useReportStyles = makeStyles(ReportStyles, {name: 'ReportStyles'});
