import ConfigService from './ConfigService/ConfigService';
import {authStore} from '../store';

const serverUrl = ConfigService.serverUri;

const EventService = {
  getDocumentCriticalLink: async (criticalEventId, documentType) => {
    const {auth} = authStore;
    const url = `${serverUrl}/api/document_template/download?documentType=${documentType}&criticalEventId=${criticalEventId}&documentFormat=pdf`;
    return `${url}&auth_token=${auth.token}`;
  },
  getDocumentOperationalLink: async (operationalEventId, documentType) => {
    const {auth} = authStore;
    const url = `${serverUrl}/api/document_template/download?documentType=${documentType}&operationalEventId=${operationalEventId}&documentFormat=pdf`;
    return `${url}&auth_token=${auth.token}`;
  },
};

export default EventService;
