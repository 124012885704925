import React from 'react';
import {useAuth} from 'oidc-react';
import {LoginLoader} from './LoginLoader';

export const LoginOIDC: React.FC = () => {
  const auth = useAuth();
  React.useEffect(() => {
    auth.signIn();
  }, [auth]);
  return <LoginLoader />;
};
