import React from 'react';
import {TaskContext, TaskContextProps} from './TaskContext';
import {TaskDetails} from './taskDetails';
import {ITemplateTabControllerTab, TemplateTabs} from '../../factory/template';

export const Task: React.FC = () => {
  const {loading, taskId} = React.useContext<TaskContextProps>(TaskContext);

  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {title: 'Task Manifest', element: <TaskDetails />},
  ]);

  const path = React.useMemo(() => `/tasktemplatelist/${taskId}`, [taskId]);

  return <TemplateTabs path={path} tabs={tabs} loading={loading} />;
};
