import React from 'react';
import {PlanningBoardBooking} from './PlanningBoardBooking';
import {PlanningBoardBookingContextProvider} from './planningBoardBookingContext';

export const PlanningBoardBookingController: React.FC = () => {
  return (
    <PlanningBoardBookingContextProvider>
      <PlanningBoardBooking />
    </PlanningBoardBookingContextProvider>
  );
};
