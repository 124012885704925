import React from 'react';
import {DataTypeProvider} from '@devexpress/dx-react-grid';
import {Stack, Tooltip} from '@mui/material';
import {Warning} from '@mui/icons-material';
import {useTemplateTableActions} from '../../providers';

export const TableActionWarning: React.FC<
  DataTypeProvider.ValueFormatterProps
> = ({row}) => {
  const {onWarning} = useTemplateTableActions();

  if (!onWarning) {
    return null;
  }

  const warning = onWarning(row);

  if (!warning.visible) {
    return null;
  }

  return (
    <Tooltip title={warning.message} arrow>
      <Stack>
        <Warning color="warning" />
      </Stack>
    </Tooltip>
  );
};
