import React from 'react';
import {useHistory} from 'react-router-dom';
import {
  ApiContactGroupGetRequest,
  ContactGroup,
  ContactGroupList,
  ContactGroupUpdate,
  ContractContactGroup,
} from '@onroadvantage/onroadvantage-api';
import {FormikHelpers, FormikProps} from 'formik/dist/types';
import {useAppNotifications} from '../../contexts';
import {contactGroupApi, contractContactGroupApi} from '../../api';
import {
  TemplateTableContextProps,
  TLoadList,
  useTemplateTable,
} from '../../factory/template';

export interface TContactGroupForm {
  name: string;
}

export interface ContactGroupContextProps
  extends TemplateTableContextProps<ContactGroup, ContactGroupList> {
  loadContractList: TLoadList<ContractContactGroup>;
  loadContactGroup: () => Promise<void>;
  cleanupContractList: () => void;
  onDetailsFormSubmit: (
    values: TContactGroupForm,
    formikHelpers: FormikHelpers<TContactGroupForm>
  ) => void;
  onUpdateContactGroup: (updatedContactGroup: ContactGroupUpdate) => void;
  setContactGroupId: (value: number | undefined) => void;
  submitting: boolean;
  contractList: ContractContactGroup[];
  contactGroup?: ContactGroup;
  contactGroupId?: number;
  detailsRef?: React.Ref<FormikProps<TContactGroupForm>>;
}

export const ContactGroupContext =
  React.createContext<ContactGroupContextProps>({
    // Template Table Defaults
    loading: false,
    list: [],
    currentPage: 1,
    // ContactGroup
    loadContactGroup: async () => {},
    contractList: [],
    cleanupContractList: () => null,
    loadContractList: async () => {},
    loadList: async () => {},
    onUpdateContactGroup: () => null,
    onDetailsFormSubmit: () => null,
    setContactGroupId: () => null,
    submitting: false,
  });

interface ContactGroupContextProviderProps {
  contactGroupId?: number;
}

export const ContactGroupContextProvider: React.FC<
  ContactGroupContextProviderProps
> = ({children}) => {
  const history = useHistory();
  const notify = useAppNotifications();
  // Template Table
  const [
    {
      // States
      currentPage,
      filters,
      itemTotal,
      hasPermission,
      list,
      loading,
      pageSize,
      pageTotal,
      loadingSingleItem,
      sorting,
    },
    {
      // Getters
      getDownloads,
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
      setLoadingSingleItem,
    },
  ] = useTemplateTable<ContactGroup, ApiContactGroupGetRequest>({
    editPermission: 'Edit ContactGroup',
    addPermission: 'Add ContactGroup',
    deletePermission: 'Delete ContactGroup',
    downloadPermission: 'ContactGroup ListDownload',
    viewPermission: 'ContactGroup List',
  });

  const loadList = React.useCallback<TLoadList<ContactGroupList>>(
    async (options) => {
      setLoading(true);
      try {
        const requestObj = getRequestObj(['name', 'contractCode'], options);
        const response = await contactGroupApi.apiContactGroupGet(requestObj);
        return getResponse(response, options);
      } catch (e) {
        notify('error', e.message ?? 'Failed to load contact Group list');
      } finally {
        setLoading(false);
      }
    },
    [getRequestObj, getResponse, notify, setLoading]
  );

  const handleDelete = React.useCallback(
    async (row: ContactGroup) => {
      setLoading(true);
      try {
        if (row.id) {
          await contactGroupApi.apiContactGroupContactGroupIdDelete({
            contactGroupId: row.id,
          });
          await loadList();
          notify('success', 'Deleted contact Group');
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to delete');
      } finally {
        setLoading(false);
      }
    },
    [loadList, notify, setLoading]
  );

  const handleDownload = React.useCallback(
    () =>
      getDownloads('contactGroup', loadList, [{name: 'Name', path: 'name'}]),
    [getDownloads, loadList]
  );

  const handleAdd = React.useCallback(
    () => history.push('/contactgrouplist/add'),
    [history]
  );

  const handleNavigate = React.useCallback(
    (row) => history.push(`/contactgrouplist/${row.id}`),
    [history]
  );
  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  // Forms
  const [contactGroup, setContactGroup] = React.useState<
    ContactGroup | undefined
  >();
  const [contractList, setContractList] = React.useState<
    ContractContactGroup[]
  >([]);
  const [contactGroupId, setContactGroupId] = React.useState<
    number | undefined
  >();
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const detailsRef = React.useRef<FormikProps<TContactGroupForm>>(null);

  const loadContactGroup = React.useCallback(async () => {
    setLoadingSingleItem(true);
    try {
      if (contactGroupId) {
        const response = await contactGroupApi.apiContactGroupContactGroupIdGet(
          {
            contactGroupId,
          }
        );
        setContactGroup(response);
      } else {
        setContactGroup(undefined);
      }
    } catch (e) {
      notify('error', e.message ?? 'Failed to load contact Group');
    } finally {
      setLoadingSingleItem(false);
    }
  }, [setLoadingSingleItem, contactGroupId, notify]);

  const cleanupContractList = React.useCallback(() => setContractList([]), []);

  const loadContractList = React.useCallback<
    TLoadList<ContractContactGroup>
  >(async () => {
    setLoadingSingleItem(true);
    try {
      const response = await contractContactGroupApi.apiContactGroupContractGet(
        {
          contactGroupId,
          perPage: 10000,
        }
      );
      if (response.items) setContractList(response.items);
    } catch (e) {
      notify('error', e.message ?? 'Failed to load contracts');
    } finally {
      setLoadingSingleItem(false);
    }
  }, [contactGroupId, notify, setLoadingSingleItem]);

  const handleDetailsFormSubmit = React.useCallback(
    async (
      values: TContactGroupForm,
      formikHelpers: FormikHelpers<TContactGroupForm>
    ) => {
      setSubmitting(true);
      if (contactGroupId) {
        await contactGroupApi.apiContactGroupContactGroupIdPatch({
          body: values,
          contactGroupId,
        });
      } else {
        await contactGroupApi.apiContactGroupPost({
          body: {...values},
        });
      }
      history.push('/contactgrouplist');
      notify(
        'success',
        `${contactGroupId ? 'Updated' : 'Added'} contact group`
      );
      formikHelpers.setSubmitting(false);
      setSubmitting(false);
    },
    [contactGroupId, history, notify]
  );

  const handleUpdateContactGroup = React.useCallback(
    async (updatedContactGroup: ContactGroupUpdate) => {
      setLoadingSingleItem(true);
      if (contactGroupId) {
        const response =
          await contactGroupApi.apiContactGroupContactGroupIdPatch({
            contactGroupId,
            body: updatedContactGroup,
          });
        notify('success', 'Added Contacts');
        setContactGroup(response);
      }
      setLoadingSingleItem(false);
    },
    [contactGroupId, notify, setLoadingSingleItem]
  );

  const value: ContactGroupContextProps = {
    // Template Table
    list,
    loadList,
    hasPermission,
    loading: loading || loadingSingleItem,
    cleanupList,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onAdd: hasPermission.add ? handleAdd : undefined,
    onDownload: hasPermission.download ? handleDownload : undefined,
    onNavigate: handleNavigate,
    onDelete: hasPermission.delete ? handleDelete : undefined,
    onRefresh: handleRefresh,
    // Forms
    contractList,
    loadContactGroup,
    loadContractList,
    cleanupContractList,
    onUpdateContactGroup: handleUpdateContactGroup,
    onDetailsFormSubmit: handleDetailsFormSubmit,
    setContactGroupId,
    submitting,
    contactGroup,
    contactGroupId,
    detailsRef,
  };

  React.useEffect(() => {
    loadContactGroup();
    return () => setContactGroup(undefined);
  }, [loadContactGroup]);

  return (
    <ContactGroupContext.Provider value={value}>
      {children}
    </ContactGroupContext.Provider>
  );
};
