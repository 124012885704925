import React from 'react';
import {
  SequencedTask,
  TaskTemplateType,
} from '@onroadvantage/onroadvantage-api';
import {IconButton} from '@mui/material';
import {Camera, Edit, PlaylistAddCheck, List} from '@mui/icons-material';

export interface TripStopTaskListPayloadProps {
  value?: string | null;
  row: SequencedTask;
  onPress: (type: string, value?: string | null | undefined) => Promise<void>;
}

export const TripStopTaskListPayload = ({
  value,
  row,
  onPress,
}: TripStopTaskListPayloadProps): JSX.Element => {
  const [content, setContent] = React.useState<React.ReactNode>();
  const {name: templateTypeName} =
    row.template?.type || ({} as TaskTemplateType);

  const handleCameraPress = React.useCallback(() => {
    onPress('Photo', value);
  }, [onPress, value]);

  const handleSignaturePress = React.useCallback(() => {
    onPress('Signature', value);
  }, [onPress, value]);

  const handleChecklistPress = React.useCallback(() => {
    onPress('Checklist', value);
  }, [onPress, value]);

  const handleQuestionnairePress = React.useCallback(() => {
    onPress('Questionnaire', value);
  }, [onPress, value]);

  React.useEffect(() => {
    switch (templateTypeName) {
      case 'Photo':
      case 'Document':
        setContent(
          value && (
            <IconButton size="small" onClick={handleCameraPress}>
              <Camera />
            </IconButton>
          )
        );
        break;
      case 'Signature':
        setContent(
          value && (
            <IconButton size="small" onClick={handleSignaturePress}>
              <Edit />
            </IconButton>
          )
        );
        break;
      case 'SegmentedControl':
        try {
          if (value) {
            setContent(`${JSON.parse(value).name} ${JSON.parse(value).value}`);
          }
        } catch (e) {
          setContent(`Error: ${JSON.stringify(e)}`);
        }
        break;
      case 'Checklist':
        setContent(
          value && (
            <IconButton size="small" onClick={handleChecklistPress}>
              <PlaylistAddCheck />
            </IconButton>
          )
        );
        break;
      case 'Questionnaire':
        setContent(
          value && (
            <IconButton size="small" onClick={handleQuestionnairePress}>
              <List />
            </IconButton>
          )
        );
        break;
      default:
        setContent(value);
    }
    return () => setContent(undefined);
  }, [
    value,
    templateTypeName,
    handleCameraPress,
    handleSignaturePress,
    handleChecklistPress,
    handleQuestionnairePress,
  ]);

  return <>{content}</>;
};
