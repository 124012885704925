import React from 'react';
import _ from 'lodash';
import {
  ITemplateControllerRoute,
  TemplateController,
} from '../../factory/template';
import {
  NotificationConfigContext,
  NotificationConfigContextProvider,
} from './NotificationConfigContext';
import {NotificationConfigList} from './NotificationConfigList';
import {NotificationConfigIdHandler} from './NotificationConfigIdHandler';
import {NotificationConfigForm} from './NotificationConfigForm';
import {NotificationConfig} from './NotificationConfig';
import {
  NotificationConfigTask,
  NotificationConfigTaskContext,
  NotificationConfigTaskContextProvider,
  NotificationConfigTaskIdHandler,
} from './notificationConfigTask';
import {
  NotificationConfigWorkflowTask,
  NotificationConfigWorkflowTaskContext,
  NotificationConfigWorkflowTaskContextProvider,
  NotificationConfigWorkflowTaskIdHandler,
} from './notificationConfigWorkflowTask';
import {
  NotificationConfigOperationalEventContext,
  NotificationConfigOperationalEventContextProvider,
} from './notificationConfigOperationalEvent/NotificationConfigOperationalEventContext';
import {
  NotificationConfigOperationalEscalationContext,
  NotificationConfigOperationalEventEscalationContextProvider,
} from './notificationConfigOperationalEscalation/NotificationConfigOperationalEscalationContext';
import {NotificationConfigOperationalEvent} from './notificationConfigOperationalEvent/NotificationConfigOperationalEvent';
import {NotificationConfigOperationalEventIdHandler} from './notificationConfigOperationalEvent/NotificationConfigOperationalEventIdHandler';
import {
  NotificationConfigCriticalEventContext,
  NotificationConfigCriticalEventContextProvider,
} from './notificationConfigCriticalEvent/NotificationConfigCriticalEventContext';
import {NotificationConfigCriticalEvent} from './notificationConfigCriticalEvent/NotificationConfigCriticalEvent';
import {NotificationConfigCriticalEventIdHandler} from './notificationConfigCriticalEvent/NotificationConfigCriticalEventIdHandler';
import {
  NotificationConfigCriticalEscalationContext,
  NotificationConfigCriticalEventEscalationContextProvider,
} from './notificationConfigCriticalEscalation/NotificationConfigCriticalEscalationContext';
import {NotificationConfigOperationalEscalation} from './notificationConfigOperationalEscalation/NotificationConfigOperationalEscalation';
import {NotificationConfigCriticalEscalation} from './notificationConfigCriticalEscalation/NotificationConfigCriticalEscalation';
import {NotificationConfigCriticalEscalationIdHandler} from './notificationConfigCriticalEscalation/NotificationConfigCriticalEscalationIdHandler';
import {NotificationConfigOperationalEscalationIdHandler} from './notificationConfigOperationalEscalation/NotificationConfigOperationalEscalationIdHandler';

export const NotificationConfigController: React.FC = () => {
  return (
    <NotificationConfigContextProvider>
      <NotificationConfigTaskContextProvider>
        <NotificationConfigWorkflowTaskContextProvider>
          <NotificationConfigOperationalEventContextProvider>
            <NotificationConfigCriticalEventContextProvider>
              <NotificationConfigOperationalEventEscalationContextProvider>
                <NotificationConfigCriticalEventEscalationContextProvider>
                  <NotificationConfigControllerComponent />
                </NotificationConfigCriticalEventEscalationContextProvider>
              </NotificationConfigOperationalEventEscalationContextProvider>
            </NotificationConfigCriticalEventContextProvider>
          </NotificationConfigOperationalEventContextProvider>
        </NotificationConfigWorkflowTaskContextProvider>
      </NotificationConfigTaskContextProvider>
    </NotificationConfigContextProvider>
  );
};

const NotificationConfigControllerComponent: React.FC = () => {
  const {notificationConfig, loading} = React.useContext(
    NotificationConfigContext
  );
  const {notificationConfigTask, loading: loadingTask} = React.useContext(
    NotificationConfigWorkflowTaskContext || NotificationConfigTaskContext
  );

  const {notificationConfigEvent, loading: loadingEvent} = React.useContext(
    NotificationConfigOperationalEventContext ||
      NotificationConfigCriticalEventContext
  );

  const {notificationConfigEventEscalation, loading: loadingEscalation} =
    React.useContext(
      NotificationConfigOperationalEscalationContext ||
        NotificationConfigCriticalEscalationContext
    );

  const routes: ITemplateControllerRoute[] = React.useMemo(
    () => [
      {title: 'Notification Config List', component: NotificationConfigList},
      {
        path: '/add',
        title: 'New Notification Config',
        name: 'Add NotificationConfig',
        type: 'Add',
        component: () => <NotificationConfigForm isAdd />,
      },
      {
        path: '/:notificationConfigId',
        title: (params) =>
          loading
            ? 'Notification Config Loading...'
            : notificationConfig?.name ??
              `Notification Config ${_.get(
                params,
                'notificationConfigId',
                'Single'
              )}`,
        component: NotificationConfig,
        IdHandler: NotificationConfigIdHandler,
      },
      {
        path: '/:notificationConfigId/task/:notificationConfigTaskId',
        title: (params) =>
          loadingTask
            ? 'Task Trigger Loading...'
            : (notificationConfigTask?.workflowConfigTaskTemplate?.taskTemplate
                ?.name ||
                notificationConfigTask?.contractTaskTemplate?.taskTemplate
                  ?.name) ??
              `Task Trigger ${_.get(
                params,
                'notificationConfigTaskId',
                'Single'
              )}`,
        component: NotificationConfigWorkflowTask || NotificationConfigTask,
        IdHandler:
          NotificationConfigWorkflowTaskIdHandler ||
          NotificationConfigTaskIdHandler,
      },
      {
        path: '/:notificationConfigId/event/:notificationConfigEventId',
        title: (params) =>
          loadingEvent
            ? 'Event Trigger Loading...'
            : (notificationConfigEvent?.contractOperationalEventTypeConfig
                ?.contract?.name ||
                notificationConfigEvent?.contractCriticalEventTypeConfig
                  ?.contract?.name) ??
              `Event Trigger ${_.get(
                params,
                'notificationConfigEventId',
                'Single'
              )}`,
        component:
          NotificationConfigOperationalEvent || NotificationConfigCriticalEvent,
        IdHandler:
          NotificationConfigOperationalEventIdHandler ||
          NotificationConfigCriticalEventIdHandler,
      },
      {
        path: '/:notificationConfigId/escalation/:notificationConfigEventEscalationId',
        title: (params) =>
          loadingEscalation
            ? 'Escalation Trigger Loading...'
            : (notificationConfigEventEscalation
                ?.operationalEventEscalationConfig
                ?.contractOperationalEventTypeConfig?.contract?.name ||
                notificationConfigEventEscalation?.criticalEventEscalationConfig
                  ?.contractCriticalEventTypeConfig?.contract?.name) ??
              `Escalation Trigger ${_.get(
                params,
                'notificationConfigEventEscalationId',
                'Single'
              )}`,
        component:
          NotificationConfigOperationalEscalation ||
          NotificationConfigCriticalEscalation,
        IdHandler:
          NotificationConfigOperationalEscalationIdHandler ||
          NotificationConfigCriticalEscalationIdHandler,
      },
    ],
    [
      loading,
      loadingTask,
      loadingEvent,
      loadingEscalation,
      notificationConfig?.name,
      notificationConfigTask?.workflowConfigTaskTemplate?.taskTemplate?.name,
      notificationConfigTask?.contractTaskTemplate?.taskTemplate?.name,
      notificationConfigEvent?.contractCriticalEventTypeConfig,
      notificationConfigEvent?.contractOperationalEventTypeConfig,
      notificationConfigEventEscalation?.criticalEventEscalationConfig,
      notificationConfigEventEscalation?.operationalEventEscalationConfig,
    ]
  );
  return (
    <TemplateController rootPath="/notificationconfiglist" routes={routes} />
  );
};
