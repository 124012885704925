import React from 'react';
import {
  TemplateTable,
  TOnInlineAdd,
  TOnInlineEdit,
} from '../../../factory/template';
import {useContractCriticalEventTypeConfigContext} from './ContractCriticalEventTypeConfigContext';
import {ContractCriticalEventTypeEscalationConfig} from '@onroadvantage/onroadvantage-api';
import {criticalEventEscalationConfigApi} from '../../../api';
import {useAppNotifications} from '../../../contexts';
import {RoleService} from '../../../service';
import * as Yup from 'yup';

const addValidation = Yup.object({
  type: Yup.string().required('Required'),
  threshold: Yup.string().required('Required'),
});
export const ContractCriticalEventTypeConfigEscalationList = () => {
  const notify = useAppNotifications();
  const {
    loading,
    contractCriticalEventTypeConfigId,
    contractCriticalEventTypeConfig,
    setContractCriticalEventTypeConfig,
  } = useContractCriticalEventTypeConfigContext();
  const [localSubmitting, setLocalSubmitting] = React.useState<boolean>(false);

  const onAddEscalation = React.useCallback<TOnInlineAdd>(
    async (changes) => {
      setLocalSubmitting(true);
      try {
        if (contractCriticalEventTypeConfigId != null) {
          for (const change of changes) {
            try {
              if ('type' in change && 'threshold' in change) {
                const response =
                  await criticalEventEscalationConfigApi.apiCriticalEventEscalationConfigPost(
                    {
                      body: {
                        contractCriticalEventTypeConfigId,
                        type: change.type,
                        threshold: change.threshold,
                      },
                    }
                  );
                setContractCriticalEventTypeConfig((prevState) =>
                  prevState
                    ? {
                        ...prevState,
                        criticalEventEscalationConfigs: [
                          ...(prevState.criticalEventEscalationConfigs ?? []),
                          {
                            ...response,
                          },
                        ],
                      }
                    : undefined
                );
              }
            } catch (e) {
              notify('error', `Failed to create escalation ${change.type}`);
            }
          }
        }
      } finally {
        setLocalSubmitting(false);
      }
    },
    [
      contractCriticalEventTypeConfigId,
      notify,
      setContractCriticalEventTypeConfig,
    ]
  );

  const onEditEscalation = React.useCallback<TOnInlineEdit>(
    async (changes) => {
      setLocalSubmitting(true);
      try {
        if (contractCriticalEventTypeConfigId != null) {
          for (const change of changes) {
            try {
              if (change.id) {
                const response =
                  await criticalEventEscalationConfigApi.apiCriticalEventEscalationConfigCriticalEventEscalationConfigIdPatch(
                    {
                      criticalEventEscalationConfigId: +change.id,
                      body: {
                        contractCriticalEventTypeConfigId,
                        type: change.newValues.type,
                        threshold: change.newValues.threshold,
                      },
                    }
                  );

                setContractCriticalEventTypeConfig((prevState) =>
                  prevState
                    ? {
                        ...prevState,
                        criticalEventEscalationConfigs:
                          prevState.criticalEventEscalationConfigs?.reduce(
                            (
                              acc: ContractCriticalEventTypeEscalationConfig[],
                              curr
                            ) => {
                              if (curr.id === response.id) {
                                return [
                                  ...acc,
                                  {
                                    ...response,
                                  },
                                ];
                              }
                              return [...acc, curr];
                            },
                            []
                          ),
                      }
                    : undefined
                );
              }
            } catch (e) {
              notify('error', `Failed to create escalation`);
            }
          }
        }
      } finally {
        setLocalSubmitting(false);
      }
    },
    [
      contractCriticalEventTypeConfigId,
      notify,
      setContractCriticalEventTypeConfig,
    ]
  );

  const onDelete = React.useCallback(
    async (row: ContractCriticalEventTypeEscalationConfig) => {
      setLocalSubmitting(true);
      try {
        if (row.id) {
          await criticalEventEscalationConfigApi.apiCriticalEventEscalationConfigCriticalEventEscalationConfigIdDelete(
            {
              criticalEventEscalationConfigId: row.id,
            }
          );

          setContractCriticalEventTypeConfig((prevState) =>
            prevState
              ? {
                  ...prevState,
                  criticalEventEscalationConfigs:
                    prevState.criticalEventEscalationConfigs?.filter(
                      ({id}) => id !== row.id
                    ),
                }
              : undefined
          );
        }
      } catch (e) {
        notify('error', 'Failed to delete escalation');
      } finally {
        setLocalSubmitting(false);
      }
    },
    [notify, setContractCriticalEventTypeConfig]
  );

  return (
    <TemplateTable<ContractCriticalEventTypeEscalationConfig>
      config={{
        columns: [
          {
            name: 'type',
            label: 'Type',
            type: 'string',
            enableEditing: true,
            enableSelect: true,
            selectOptions: ['Unassigned', 'Open'],
          },
          {
            name: 'threshold',
            label: 'Threshold',
            type: 'string',
            enableEditing: true,
          },
        ],
        disableToolbar: true,
        disablePagination: true,
        deleteMessage: (row) => ({
          title: 'Critical Event Type Escalation Config',
          items: {
            ContractCriticalEventTypeConfig: `${contractCriticalEventTypeConfig?.criticalEventType?.name} - ${contractCriticalEventTypeConfig?.contract?.code}`,
            Type: row.type,
            Threshold: row.threshold,
          },
        }),
        identifier: 'CONTRACT_CRITICAL_EVENT_TYPE_CONFIG_ESCALATION_LIST',
      }}
      addValidation={addValidation}
      currentPage={1}
      list={
        contractCriticalEventTypeConfig?.criticalEventEscalationConfigs ?? []
      }
      onInlineEdit={
        RoleService.hasPermission(
          'Edit ContractCriticalEventTypeConfig',
          'Edit'
        )
          ? onEditEscalation
          : undefined
      }
      onInlineAdd={
        RoleService.hasPermission(
          'Edit ContractCriticalEventTypeConfig',
          'Edit'
        )
          ? onAddEscalation
          : undefined
      }
      onDelete={
        RoleService.hasPermission(
          'Edit ContractCriticalEventTypeConfig',
          'Edit'
        )
          ? onDelete
          : undefined
      }
      loading={loading || localSubmitting}
    />
  );
};
