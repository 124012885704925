import React from 'react';
import history from '../../service/history';
import {observer} from 'mobx-react';
import {
  CriticalEventTypeController,
  AdminTelematicsConfigController,
  BroadcastController,
  ContactGroupController,
  ContactController,
  ContractGroupController,
  ContractController,
  CustomerController,
  Dashboard,
  DashboardDataStudioFullscreen,
  DashboardController,
  DashboardStandAlone,
  DocumentTemplateController,
  DriverController,
  DriverDocumentController,
  DriverGroupController,
  DriverRankIncentiveController,
  FreshdeskTicketAndArticles,
  MasterRouteController,
  NodeTypeController,
  NotificationConfigController,
  OrderController,
  PermissionController,
  PlanningBoardController,
  PlanningSkillController,
  ProductController,
  ProductGroupController,
  ProfileController,
  OrderRecipientController,
  MasterTripRecipientController,
  ReportController,
  RoleController,
  SiteController,
  SpeedTableController,
  TaskController,
  TaskTemplateController,
  TransporterController,
  TripController,
  TripDebriefController,
  TripDebriefConfigController,
  Upload,
  UserController,
  VantageVoice,
  VehicleController,
  VehicleGroupController,
  VehicleTypeController,
  VehicleBookingAvailabilityController,
  TrainingCourseController,
  UserTrainingCourseController,
  WorkflowController,
  WorkflowConfigController,
  DeveloperApiDocs,
  FunctionalDocs,
  TrackingApiDocs,
  IntegrationApiDocs,
  ClientApiDocs,
  EventController,
  EventTypes,
  EventsBulkClosingController,
  ContractEventTypeConfigs,
} from '..';
import {Redirect, Route, Router} from 'react-router-dom';
import {PlanningController} from '../planning';
import {AllowedRoute} from './AllowedRoute';
import {Logout} from '../authentication';
import {VantageFrame} from '../navigation';
import {GlobalAuthedContextProvider} from '../../contexts';
import {UserSettings} from '../userSettings';
import {ErrorBoundary} from '../../factory/template';
import {TemplateEditor} from '../template/TemplateEditor';

export const AuthedRouterComponent = () => {
  return (
    <Router history={history}>
      <AllowedRoute
        exact
        path="/dashboard_standalone"
        component={DashboardStandAlone}
      />
      <AllowedRoute
        exact
        path="/dashboard_datastudio"
        component={DashboardDataStudioFullscreen}
      />
      <AllowedRoute exact path="/logout" component={Logout} />
      {/*<Route exact path="/error-401" component={ErrorUnauthenticated} />*/}
      <VantageFrame>
        <ErrorBoundary>
          <Route
            exact
            path="/oidc/login/callback"
            component={() => <Redirect to="/dashboard" />}
          />
          <AllowedRoute exact path="/" name="Dashboard" component={Dashboard} />
          <AllowedRoute
            exact
            path="/dashboard"
            name="Dashboard"
            component={Dashboard}
          />
          <AllowedRoute
            path="/dashboardlist"
            component={DashboardController}
            name="Dashboard List"
          />
          <AllowedRoute
            path="/recipient/:uuid"
            component={OrderRecipientController}
          />
          <AllowedRoute
            path="/master_trip_recipient/:uuid"
            component={MasterTripRecipientController}
          />
          <AllowedRoute
            path="/userlist"
            name="User List"
            component={UserController}
          />
          <AllowedRoute
            path="/profilelist"
            name="Profile List"
            component={ProfileController}
          />
          <AllowedRoute
            path="/nodetypelist"
            name="NodeType List"
            component={NodeTypeController}
          />
          <AllowedRoute
            path="/tasktemplatelist"
            name="TaskTemplate List"
            component={TaskTemplateController}
          />
          <AllowedRoute
            path="/rolelist"
            name="Role List"
            component={RoleController}
          />
          <AllowedRoute
            path="/permissionlist"
            name="Permission List"
            component={PermissionController}
          />
          <AllowedRoute
            path="/orderlist"
            name="Order List"
            component={OrderController}
          />
          <AllowedRoute
            path="/tasklist"
            name="Task List"
            component={TaskController}
          />
          <AllowedRoute
            path="/trainingcourselist"
            name="TrainingCourse List"
            component={TrainingCourseController}
          />
          <AllowedRoute
            path="/usertrainingcourselist"
            name="UserTrainingCourse List"
            component={UserTrainingCourseController}
          />
          <AllowedRoute
            path="/tripdebrieflist"
            name="TripDebrief List"
            component={TripDebriefController}
          />
          <AllowedRoute
            path="/tripdebriefconfiglist"
            name="MasterTripDebriefConfig List"
            component={TripDebriefConfigController}
          />
          <AllowedRoute
            path="/productlist"
            name="Product List"
            component={ProductController}
          />
          <AllowedRoute
            path="/productgrouplist"
            name="ProductGroup List"
            component={ProductGroupController}
          />
          <AllowedRoute
            path="/customerlist"
            component={CustomerController}
            name="Customer List"
          />
          <AllowedRoute
            path="/transporterlist"
            component={TransporterController}
            name="Transporter List"
          />
          <AllowedRoute
            exact
            path="/upload"
            name="Upload Data"
            component={Upload}
          />
          <AllowedRoute
            path="/triplist"
            name="Trip List"
            component={TripController}
          />
          <AllowedRoute
            path="/vehiclelist"
            name="Vehicle List"
            component={VehicleController}
          />
          <AllowedRoute
            path="/vehiclegrouplist"
            name="VehicleGroup List"
            component={VehicleGroupController}
          />
          <AllowedRoute
            path="/vehicle_booking_availability"
            name="Booking List"
            component={VehicleBookingAvailabilityController}
          />
          <AllowedRoute
            path="/sitelist"
            name="Site List"
            component={SiteController}
          />
          <AllowedRoute
            path="/speedtablelist"
            name="SpeedTable List"
            component={SpeedTableController}
          />
          <AllowedRoute
            path="/contractlist"
            component={ContractController}
            name="Contract List"
          />
          <AllowedRoute
            path="/contactlist"
            component={ContactController}
            name="Contact List"
          />
          <AllowedRoute
            exact
            path="/support/create"
            component={FreshdeskTicketAndArticles}
          />
          <AllowedRoute
            path="/contractgrouplist"
            component={ContractGroupController}
            name="ContractGroup List"
          />
          <AllowedRoute
            path="/reportlist"
            name="Report List"
            component={ReportController}
          />
          <AllowedRoute
            path="/broadcastlist"
            name="Broadcast List"
            component={BroadcastController}
          />
          <AllowedRoute
            exact
            path="/broadcast"
            name="Broadcast"
            component={
              // todo: revert to BroadcastWizard
              VantageVoice
            }
          />
          <AllowedRoute
            exact
            path="/vantage_voice"
            name="Vantage Voice"
            component={VantageVoice}
          />
          <AllowedRoute
            path="/driverlist"
            name="Driver List"
            component={DriverController}
          />
          <AllowedRoute
            path="/driverdocumentlist"
            name="DriverDocument List"
            component={DriverDocumentController}
          />
          <AllowedRoute
            path="/drivergrouplist"
            name="DriverGroup List"
            component={DriverGroupController}
          />
          <AllowedRoute
            path="/driverrankincentivelist"
            name="DriverRankIncentive List"
            component={DriverRankIncentiveController}
          />
          <AllowedRoute
            path="/criticaleventtypelist"
            name="CriticalEventType List"
            component={CriticalEventTypeController}
          />
          <AllowedRoute
            path="/planningsolutionlist"
            name="PlanningSolution List"
            component={PlanningController}
          />
          <AllowedRoute
            path="/masterroutelist"
            name="MasterRoute List"
            component={MasterRouteController}
          />
          <AllowedRoute
            path="/vehicletypelist"
            name="VehicleType List"
            component={VehicleTypeController}
          />
          <AllowedRoute
            path="/planningskilllist"
            name="PlanningSkill List"
            component={PlanningSkillController}
          />
          <AllowedRoute
            path="/contactgrouplist"
            name="ContactGroup List"
            component={ContactGroupController}
          />
          <AllowedRoute
            exact
            path="/experimental/wysiwyg"
            permissions={[{name: 'View Vantage Labs'}]}
            component={TemplateEditor}
          />
          <AllowedRoute
            path="/notificationconfiglist"
            permissions={[
              {name: 'View Vantage Labs'},
              {name: 'NotificationConfig List'},
            ]}
            component={NotificationConfigController}
          />
          <AllowedRoute
            path="/documenttemplatelist"
            permissions={[
              {name: 'View Vantage Labs'},
              {name: 'DocumentTemplate List'},
            ]}
            component={DocumentTemplateController}
          />
          <AllowedRoute exact path="/usersettings" component={UserSettings} />
          <AllowedRoute
            path="/telematicsconfiglist"
            component={AdminTelematicsConfigController}
          />
          <AllowedRoute
            path="/planningBoard"
            name="PlanningBoard View"
            component={PlanningBoardController}
          />
          <AllowedRoute
            path="/workflowlist"
            name="Workflow List"
            component={WorkflowController}
          />
          <AllowedRoute
            path="/workflowconfiglist"
            name="WorkflowConfig List"
            component={WorkflowConfigController}
          />
          <AllowedRoute
            path="/developerapidocs"
            name="Developer API Docs"
            component={DeveloperApiDocs}
          />
          <AllowedRoute
            path="/clientapidocs"
            name="Client API Docs"
            component={ClientApiDocs}
          />
          <AllowedRoute
            path="/integrationapidocs"
            name="Integration API Docs"
            component={IntegrationApiDocs}
          />
          <AllowedRoute
            path="/trackingapidocs"
            name="Tracking API Docs"
            component={TrackingApiDocs}
          />
          <AllowedRoute
            path="/functionaldocs"
            name="Functional Docs"
            component={FunctionalDocs}
          />
          <AllowedRoute
            path="/event/:type/:id"
            permissions={[
              {name: 'CriticalEvent List'},
              {name: 'OperationalEvent List'},
            ]}
            component={EventController}
          />
          <AllowedRoute
            path="/eventtypes"
            permissions={[
              {name: 'CriticalEventType List'},
              {name: 'OperationalEventType List'},
            ]}
            component={EventTypes}
          />
          <AllowedRoute
            /* eventVariant -> 'critical' | 'operational' */
            path="/contracteventtypeconfigs/:eventVariant"
            permissions={[
              {name: 'ContractCriticalEventTypeConfig List'},
              {name: 'ContractOperationalEventTypeConfig List'},
            ]}
            component={ContractEventTypeConfigs}
          />
          <AllowedRoute
            path="/bulkclosing"
            permissions={[{name: 'CriticalEvent List'}]}
            component={EventsBulkClosingController}
          />
        </ErrorBoundary>
      </VantageFrame>
      {/*<Route path="*" render={() => <Redirect to="/" />} />*/}
    </Router>
  );
};

export const AuthedRouterContextWrapper: React.FC = () => (
  <GlobalAuthedContextProvider>
    <AuthedRouterComponent />
  </GlobalAuthedContextProvider>
);

export const AuthedRouter = observer(AuthedRouterContextWrapper);
