import React from 'react';
import {
  useTripSettings,
  useTripSettingsResponse,
  useTripSettingsResponseInitial,
} from './useTripSettings';
import {useTripContext} from '../../tripContext';

export type TripSettingsContextProps = useTripSettingsResponse;

export const TripSettingsContext =
  React.createContext<TripSettingsContextProps>(useTripSettingsResponseInitial);

export const TripSettingsContextProvider: React.FC = ({children}) => {
  const {masterTripId} = useTripContext();
  const tripSettingsValues = useTripSettings();

  const {loadSettings, setDidLoadSettings} = tripSettingsValues;

  React.useEffect(() => {
    if (masterTripId) {
      loadSettings();
    }
    return () => {
      setDidLoadSettings(false);
    };
    /** Only load the settings on first render when it has a masterTripId */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterTripId]);

  return (
    <TripSettingsContext.Provider value={tripSettingsValues}>
      {children}
    </TripSettingsContext.Provider>
  );
};

export const useTripSettingsContext = () =>
  React.useContext(TripSettingsContext);
