import React from 'react';
import {IconButton} from '@mui/material';
import {Edit, OpenInNew} from '@mui/icons-material';
import {useHistory} from 'react-router-dom';
import {useVehicleTypeCapacityCompartmentTruckStyles} from './VehicleTypeCapacityCompartmentTruckActions.style';
import {VehicleTypeContext} from '../../VehicleTypeContext';
import {useFormikContext} from 'formik';
import {IVehicleTypeCapacityForm} from '../VehicleTypeCapacityForm';
import {IVantageDialogRef} from '../../../dialog';
import {VehicleTypeCapacityCompartmentTruckLayersView} from './VehicleTypeCapacityCompartmentTruckLayersView';
import {VehicleTypeCapacityCompartmentTruckLayersAdd} from './VehicleTypeCapacityCompartmentTruckLayersAdd';
import {VehicleCompartmentWithCapacity} from './VehicleTypeCapacityCompartmentTruckCompartment';

export interface VehicleTypeCapacityCompartmentTruckActionsProps {
  compartmentWithCapacity: VehicleCompartmentWithCapacity | undefined;
  compartmentDialogRef: React.RefObject<IVantageDialogRef>;
  setFieldFocus: React.Dispatch<React.SetStateAction<number | undefined>>;
}

export const VehicleTypeCapacityCompartmentTruckActions: React.FC<
  VehicleTypeCapacityCompartmentTruckActionsProps
> = ({compartmentWithCapacity, compartmentDialogRef, setFieldFocus}) => {
  const history = useHistory();
  const classes = useVehicleTypeCapacityCompartmentTruckStyles();
  const {vehicleTypeId} = React.useContext(VehicleTypeContext);
  const {values} = useFormikContext<IVehicleTypeCapacityForm>();

  const vehicleCompartmentCapacityDimension = React.useMemo(
    () =>
      values.capacityCompartments.find(
        ({compartmentId}) => compartmentId === compartmentWithCapacity?.id
      ),
    [compartmentWithCapacity?.id, values.capacityCompartments]
  );

  const handleEdit = React.useCallback(() => {
    setFieldFocus(compartmentWithCapacity?.id ?? undefined);
    compartmentDialogRef.current?.openDialog();
  }, [compartmentDialogRef, compartmentWithCapacity?.id, setFieldFocus]);

  const handleNavigate = React.useCallback(() => {
    history.push(
      `/vehicletypelist/${vehicleTypeId}/compartment/${compartmentWithCapacity?.id}`
    );
  }, [compartmentWithCapacity, history, vehicleTypeId]);

  return (
    <div className={classes.actions}>
      {vehicleCompartmentCapacityDimension && compartmentWithCapacity && (
        <VehicleTypeCapacityCompartmentTruckLayersView
          compartment={compartmentWithCapacity}
          values={values}
          vehicleCompartmentCapacityDimension={
            vehicleCompartmentCapacityDimension
          }
        />
      )}
      {vehicleCompartmentCapacityDimension && (
        <VehicleTypeCapacityCompartmentTruckLayersAdd
          vehicleCompartmentCapacityDimension={
            vehicleCompartmentCapacityDimension
          }
        />
      )}
      <IconButton onClick={handleEdit} size="large">
        <Edit />
      </IconButton>
      <IconButton onClick={handleNavigate} size="large">
        <OpenInNew />
      </IconButton>
    </div>
  );
};
