import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

const ResetPasswordCriteriaStyles = (theme: Theme) =>
  createStyles({
    container: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
  });

export const useResetPasswordCriteriaStyles = makeStyles(
  ResetPasswordCriteriaStyles,
  {name: 'ResetPasswordCriteriaStyles'}
);
