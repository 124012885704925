/**
 * Created by waldo on 2017/07/19.
 */
import React from 'react';
import {observer, Provider} from 'mobx-react';
import * as mobxStores from './stores/mobxStores';
import {authStore} from './store';
import {MasterRouter} from './components/router';
import Loader from './components/loader/Loader';
import {AuthProvider, AuthProviderProps} from 'oidc-react';
import ConfigService from './service/ConfigService/ConfigService';
import {userApi} from './api';
import {appNotificationStore} from './stores/mobxStores';
import history from './service/history';
import Notifier from './components/navigation/Notifier';
import {GlobalContextProvider} from './contexts';
import {OnlineStatusProvider} from './contexts';

const {oidcAuthority, oidcClientId, oidcRedirectUri} = ConfigService;

export const AppComponent = () => {
  const getUser: AuthProviderProps['onSignIn'] = async (user) => {
    if (user) {
      authStore.setAuth({
        authenticated: true,
        oauthAuthenticated: true,
        active: true,
        token: user.access_token,
        email: '', // TODO
        roles: [], // TODO
      });
      const data = await userApi.apiUserMeGet();
      if (data) {
        authStore.setAuth({
          authenticated: true,
          oauthAuthenticated: true,
          active: true,
          token: user.access_token,
          email: data.email,
          roles: data.roles || [],
        });
      }
    } else {
      appNotificationStore.enqueueNotification('ERROR', 'Failed to login');
      history.push('/login');
    }
  };

  const handleSignOut = () => {
    authStore.setAuth(null);
    authStore.clearStore();
  };

  React.useEffect(() => {
    const el = document.querySelector('#lottie-loader');
    if (el) {
      el.remove();
    }
  }, []);

  // check if the authStore has been synchronized from storage
  if (!authStore.isSynchronized) {
    return <Loader />;
  }

  return (
    <OnlineStatusProvider>
      <Provider {...mobxStores}>
        <AuthProvider
          authority={oidcAuthority || ''}
          clientId={oidcClientId || ''}
          redirectUri={oidcRedirectUri || ''}
          postLogoutRedirectUri={`${window.location.origin}/logout/oidc/callback`}
          onSignIn={getUser}
          onSignOut={handleSignOut}
          autoSignIn={false}
        >
          <GlobalContextProvider>
            <MasterRouter />
            <Notifier />
          </GlobalContextProvider>
        </AuthProvider>
      </Provider>
    </OnlineStatusProvider>
  );
};

export const App = observer(AppComponent);
