import React from 'react';
import {OrderDump} from '@onroadvantage/onroadvantage-api';
import {useTripEditContext} from '../tripEditContext';
import {TemplateTable, TemplateTableConfig} from '../../../../factory/template';
import {TripEditOrderAddList} from './tripEditOrderAddList';
import {useTripEditOrderListStyles} from './TripEditOrderList.style';

export const TripEditOrderList: React.FC = () => {
  const classes = useTripEditOrderListStyles();
  const {
    hasEditPermission,
    isAddingOrders,
    orders,
    getInitialOrder,
    openIsAddingOrders,
    loadingStops,
    onDeleteOrder,
  } = useTripEditContext();

  const config: TemplateTableConfig<OrderDump> = React.useMemo(
    () => ({
      columns: [
        {
          name: 'orderNumber',
          label: 'Order Number',
          type: 'string',
          model: 'Order',
          enableEditing: true,
          enableAutocomplete: true,
          enableMulti: true,
        },
        {
          name: 'upliftPoint',
          label: 'Uplift Point',
          type: 'string',
          getValue: ({upliftPoint}) => upliftPoint?.name,
        },
        {
          name: 'offloadPoint',
          label: 'Offload Point',
          type: 'string',
          getValue: ({offloadPoint}) => offloadPoint?.name,
        },
      ],
      deleteMessage: (row) => ({
        title: 'Order',
        items: {
          OrderNumber: row.orderNumber,
          Product: row.product,
          UpliftPoint: row.upliftPoint.name,
          OffloadPoint: row.offloadPoint.name,
        },
      }),
      getRowClass: (row) => {
        const initialOrder = getInitialOrder(row.id);
        if (!initialOrder) {
          return classes.newRow;
        }
      },
      disablePagination: true,
      enableGrouping: false,
      identifier: 'TRIP_EDIT_ORDER_LIST',
    }),
    [classes.newRow, getInitialOrder]
  );

  if (isAddingOrders) {
    return <TripEditOrderAddList />;
  }

  return (
    <TemplateTable
      config={config}
      currentPage={1}
      list={orders ?? []}
      loading={loadingStops}
      onAdd={hasEditPermission ? openIsAddingOrders : undefined}
      onDelete={hasEditPermission ? onDeleteOrder : undefined}
    />
  );
};
