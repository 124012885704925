import {makeStyles, createStyles} from '@mui/styles';
import {Theme} from '@mui/material';

const PlanningBoardGanttCompartmentTruckOrderFillStyles = (theme: Theme) =>
  createStyles({
    tooltip: {
      background: '#eee',
      padding: theme.spacing(0.2),
      paddingRight: theme.spacing(0.6),
      paddingLeft: theme.spacing(0.6),
    },
    tooltipArrow: {color: '#eee'},
    tooltipContent: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
    },
    fillArrow: {zIndex: 3},
    fillText: {color: '#fefefe', opacity: 0},
    fillContainer: {
      position: 'absolute',
      bottom: 0,
      top: 0,
      left: 0,
      right: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'flex-end',
    },
    fill: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      flex: 0.5,
      zIndex: 0,
      opacity: 0.5,
      borderTop: '0.5px solid #eee',
      '&:hover': {
        opacity: 1,
        zIndex: 2,
        boxShadow: '0px -3px 5px rgba(50,50,50,0.3)',
        '& #percentage': {
          opacity: 1,
        },
        '& #fillText': {
          opacity: 1,
        },
        '& #compartmentContent': {
          opacity: 0.4,
        },
      },
    },
    fillDraggable: {cursor: 'move'},
    fillMax: {background: theme.palette.error.main},
    orderVariantLoading: {background: '#008270'},
    orderVariantOffloading: {background: '#008270'},
    orderVariantInProgress: {background: '#687b78'},
  });

export const usePlanningBoardGanttCompartmentTruckOrderFillStyles = makeStyles(
  PlanningBoardGanttCompartmentTruckOrderFillStyles,
  {name: 'PlanningBoardGanttCompartmentTruckOrderFillStyles'}
);
