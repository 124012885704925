import React from 'react';
import {useContractCriticalEventTypeConfigContext} from './ContractCriticalEventTypeConfigContext';
import {useParams} from 'react-router-dom';

interface IParams {
  contractCriticalEventTypeConfigId?: string;
}

export const ContractCriticalEventTypeConfigIdHandler: React.FC = () => {
  const {setContractCriticalEventTypeConfigId} =
    useContractCriticalEventTypeConfigContext();

  const {contractCriticalEventTypeConfigId} = useParams<IParams>();

  React.useEffect(() => {
    if (
      contractCriticalEventTypeConfigId != null &&
      contractCriticalEventTypeConfigId !== 'add'
    ) {
      const parsedId = contractCriticalEventTypeConfigId
        ? parseInt(contractCriticalEventTypeConfigId)
        : undefined;
      setContractCriticalEventTypeConfigId(parsedId);
    }
    return () => setContractCriticalEventTypeConfigId(undefined);
  }, [contractCriticalEventTypeConfigId, setContractCriticalEventTypeConfigId]);

  return null;
};
