import {makeStyles, createStyles} from '@mui/styles';

const TripDebriefSummaryStyles = () =>
  createStyles({
    input: {
      marginTop: 0,
      paddingBottom: 0,
      flex: 1,
      display: 'flex',
      borderColor: 'red !important',
    },
  });

export const useTripDebriefSummaryStyles = makeStyles(
  TripDebriefSummaryStyles,
  {name: 'TripDebriefSummaryStyles'}
);
