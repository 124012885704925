import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from '@mui/material';

const PlanningBoardBookingFormStyles = (theme: Theme) =>
  createStyles({
    field: {marginBottom: theme.spacing(1.3)},
    durationValueField: {flex: 1},
    durationUnitField: {flex: 0.5},
  });

export const usePlanningBoardBookingFormStyles = makeStyles(
  PlanningBoardBookingFormStyles,
  {
    name: 'PlanningBoardBookingFormStyles',
  }
);
