import React from 'react';
import {NotificationConfigContext} from './NotificationConfigContext';
import {
  TemplateTable,
  TemplateTableConfig,
  TOnInlineAdd,
} from '../../factory/template';
import {
  DocumentTemplate,
  Listing,
  NotificationConfigUpdate,
} from '@onroadvantage/onroadvantage-api';
import {useAppNotifications} from '../../contexts';
import {RoleService} from '../../service';
import {notificationConfigApi} from '../../api';

export const NotificationConfigDocuments: React.FC = () => {
  const notify = useAppNotifications();
  const {
    notificationConfig,
    notificationConfigId,
    loadingSingleItem,
    loadNotificationConfig,
  } = React.useContext(NotificationConfigContext);
  const [localLoading, setLocalLoading] = React.useState<boolean>(false);
  const list = React.useMemo(
    () => notificationConfig?.emailAttachmentDocumentTemplates ?? [],
    [notificationConfig?.emailAttachmentDocumentTemplates]
  );
  const config: TemplateTableConfig<DocumentTemplate> = React.useMemo(
    () => ({
      columns: [
        {
          name: 'name',
          label: 'Document Name',
          type: 'string',
          model: 'DocumentTemplate',
          enableEditing: true,
          enableAutocomplete: true,
        },
        {
          name: 'documentType',
          label: 'Document Type',
          type: 'string',
          enableEditing: false,
        },
        {
          name: 'contractCode',
          label: 'Contract Code',
          type: 'string',
          enableEditing: false,
          getValue: ({contract}) => contract?.code,
        },
      ],
      disablePagination: true,
      disableToolbar: true,
      deleteMessage: (row) => ({
        title: 'Notification Config Document',
        items: {
          NotificationConfigName: notificationConfig?.name,
          DocumentName: row.name,
          DocumentType: row.documentType,
          ContractCode: row.contract?.code,
        },
      }),
      identifier: 'NOTIFICATION_CONFIG_DOCUMENTS_LIST',
    }),
    [notificationConfig?.name]
  );

  const handleInlineAdd = React.useCallback<TOnInlineAdd>(
    async (changes) => {
      setLocalLoading(true);
      try {
        const documentTemplate = changes[0]?.name as Listing | undefined;
        if (notificationConfigId && documentTemplate?.value) {
          const documentTemplateIds: number[] =
            notificationConfig?.emailAttachmentDocumentTemplates?.map(
              ({id}) => id as number
            ) ?? [];
          documentTemplateIds.push(documentTemplate.value);
          await notificationConfigApi.apiNotificationConfigNotificationConfigIdPatch(
            {
              notificationConfigId,
              body: {
                ...(notificationConfig as NotificationConfigUpdate),
                documentTemplateIds,
              },
            }
          );
          notify(
            'success',
            'Notification Config link to Document Templates updated.'
          );
          await loadNotificationConfig();
        }
      } catch (e) {
        notify(
          'error',
          e.message ?? 'Failed to link document template to notification config'
        );
      } finally {
        setLocalLoading(false);
      }
    },
    [loadNotificationConfig, notificationConfig, notificationConfigId, notify]
  );

  const handleDelete = React.useCallback(
    async (row: DocumentTemplate) => {
      setLocalLoading(true);
      try {
        if (notificationConfigId && row.id) {
          const documentTemplateIds: number[] =
            notificationConfig?.emailAttachmentDocumentTemplates
              ?.map(({id}) => id as number)
              .filter((id) => id !== row.id) ?? [];
          await notificationConfigApi.apiNotificationConfigNotificationConfigIdPatch(
            {
              notificationConfigId,
              body: {
                ...(notificationConfig as NotificationConfigUpdate),
                documentTemplateIds,
              },
            }
          );
          notify(
            'success',
            'Notification Config unlink to Document Templates updated.'
          );
          await loadNotificationConfig();
        }
      } catch (e) {
        notify(
          'error',
          e.message ??
            'Failed to unlink document template to notification config'
        );
      } finally {
        setLocalLoading(false);
      }
    },
    [loadNotificationConfig, notificationConfig, notificationConfigId, notify]
  );

  return (
    <TemplateTable
      config={config}
      currentPage={1}
      list={list}
      loading={loadingSingleItem || localLoading}
      onInlineAdd={
        RoleService.hasPermission('Add NotificationConfig', 'Add')
          ? handleInlineAdd
          : undefined
      }
      onDelete={
        RoleService.hasPermission('Delete NotificationConfig', 'Delete')
          ? handleDelete
          : undefined
      }
    />
  );
};
