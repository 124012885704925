import {decorate, observable, action, computed} from 'mobx';

class Tab {
  constructor() {
    this.activeRowsTabsObject = {};
    this.initializeActiveRowTab = this.initializeActiveRowTab.bind(this);
    this.changeActiveRowTab = this.changeActiveRowTab.bind(this);
  }

  initializeActiveRowTab(id) {
    this.activeRowsTabsObject[id] = 0;
  }

  get activeRowsTabs() {
    return this.activeRowsTabsObject;
  }

  changeActiveRowTab(id, newTabValue) {
    this.activeRowsTabsObject[id] = newTabValue;
  }
}

export default decorate(Tab, {
  activeRowsTabsObject: observable,
  initializeActiveRowTab: action,
  activeRowsTabs: computed,
  changeActiveRowTab: action,
});
