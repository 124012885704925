import {PlanningOrder} from '@onroadvantage/onroadvantage-api';

export const getCapacityDimension = (
  uom: string | undefined | null,
  planningOrder: PlanningOrder | undefined | null
) => {
  if (uom == null || planningOrder == null) {
    return null;
  }

  if (uom === planningOrder.capacityDimension1Unit) {
    return planningOrder.capacityDimension1;
  }

  if (uom === planningOrder.capacityDimension2Unit) {
    return planningOrder.capacityDimension2;
  }

  if (uom === planningOrder.capacityDimension3Unit) {
    return planningOrder.capacityDimension3;
  }

  if (uom === planningOrder.capacityDimension4Unit) {
    return planningOrder.capacityDimension4;
  }

  return null;
};
