import React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import withStyles from '@mui/styles/withStyles';
import classnames from 'classnames';
import Avatar from '@mui/material/Avatar';
import CloudIcon from '@mui/icons-material/CloudQueue';
import arrayMove from 'array-move';
import _ from 'lodash';
import styles from '../../../styles/Card';
import TripEditActions from './TripEditActions';
import {
  AddCancelHeader,
  ToggleEditingHeader,
} from '../../../table/TableCommands';
import {RoleService, TripService} from '../../../../service';
import {nodeApi, tripApi} from '../../../../api';
import {appNotificationStore} from '../../../../stores/mobxStores';
import {TripEditCardSortableList} from './TripEditCardSortableList';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import {TripEditOrders} from './TripEditOrders';
import clone from 'rfdc';
import {getStopPlannedTAT} from '../../../../utils';

const initialState = {
  contractCode: null, // TODO change to contract id
  expanded: false,
  editingRowIds: [],
  rowChanges: {},
  expandedRowIds: [],
  creatingRow: false,
  loading: false,
  editSequence: false,
  tripStops: [],
  initialTripStops: [],
  tripOrders: [],
  tabValue: 0,
  changedPlannedTAT: null,
};

class TripEditCardComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  componentDidMount = async () => {
    const {trip, tripInitial} = this.props;
    const clonedTrip = clone()(trip);
    const clonedTripInitial = clone()(tripInitial);
    const {contractCode, stops} = clonedTrip;

    const tripStops = TripService.loadEditTripStops(clonedTrip);

    // get all orders assigned to trip stops
    const tripStopOrders = [];
    stops.forEach((ts) => {
      tripStopOrders.push.apply(tripStopOrders, ts.orders);
    });
    const tripOrders = _.uniqBy(tripStopOrders, 'id');

    this.setState({
      contractCode,
      loading: false,
      tripStops,
      initialTripStops: clonedTripInitial?.stops ?? clone()(tripStops),
      tripOrders,
    });
  };

  handleExpandClick = () => {
    this.setState((prevState) => ({expanded: !prevState.expanded}));
  };

  onSortEnd = async (sortProps) => {
    const {oldIndex, newIndex} = sortProps;
    const {tripStops} = this.state;
    const moved = arrayMove(tripStops, oldIndex - 1, newIndex - 1);
    const movedFilter = (el) => el != null;
    const movedMap = (el, index) => ({...el, sequence: index + 1});
    const updatedSequences = moved.filter(movedFilter).map(movedMap);
    this.setState({tripStops: updatedSequences});
  };

  createNewRow = () => {
    const {tripStops} = this.state;
    const newItems = [{}, ...tripStops];
    this.setState({
      tripStops: newItems,
      expandedRowIds: [0],
      creatingRow: true,
    });
  };

  postCommitChanges = async () => {
    this.setState({
      expandedRowIds: [],
      creatingRow: false,
      filters: [],
      sorting: [],
    });
  };

  cancelNewRow = () => {
    const {tripStops, creatingRow} = this.state;
    if (creatingRow) {
      const newStops = tripStops.splice(1);
      this.setState({
        tripStops: newStops,
        expandedRowIds: [],
        creatingRow: false,
      });
    }
  };

  onExpandedRowIdsChange = (expandedRowIds) => {
    this.setState({
      expandedRowIds: [expandedRowIds[expandedRowIds.length - 1]],
    });
  };

  changeEditingRowIds = (editingRowIds) => {
    this.setState({editingRowIds});
  };

  changeRowChanges = (rowChanges) => {
    this.setState({rowChanges});
  };

  commitChanges = async ({changed, added, deleted}) => {
    const {tripStops} = this.state;

    // deep copy first
    const stops = JSON.parse(JSON.stringify(tripStops));

    if (deleted) {
      // a trip can never have less than 2 stops
      const minStopCount = 2;
      if (stops.length - deleted.length >= minStopCount) {
        deleted.forEach((d) => {
          // check if any stops follow and update sequence
          if (stops.length > d + 1) {
            const nextStops = [...stops].slice(d + 1);
            nextStops.forEach((ns, idx) => {
              stops[idx + d].sequence -= 1;
            });
          }
          stops.splice(d, 1);
        });
      } else {
        // notify the user that the last stop cannot be deleted.
        appNotificationStore.enqueueNotification(
          'error',
          'Cannot delete stop. A trip must have at least 2 stops.'
        );
      }
    }

    if (changed) {
      const changedItem = changed[Object.keys(changed)[0]];
      const tripId = changedItem.id;

      const stopIndex = stops.findIndex((t) => t.id === tripId);
      if (stopIndex !== -1) {
        const stop = stops[stopIndex];

        // check if stop taskTemplateNodeType has changed
        if (changedItem.taskTemplateNodeType !== stop.taskTemplateNodeType) {
          stop.taskTemplateNodeType = changedItem.taskTemplateNodeType;
        }

        // check if node changed
        const nodeId = _.get(changedItem, 'node.id', null);

        // check if departureTime has changed
        const departureTime = _.get(
          changedItem,
          'departureTime',
          stop.departureTime
        );

        // check if totalServiceTime has changed
        const totalServiceTime = _.get(
          changedItem,
          'totalServiceTime',
          stop.totalServiceTime
        );

        // check if totalServiceTimeChangeReason has changed
        const totalServiceTimeChangeReason = _.get(
          changedItem,
          'totalServiceTimeChangeReason',
          stop.totalServiceTimeChangeReason
        );

        if (nodeId) {
          // ensure no stop id gets kept
          const node = await nodeApi.apiNodeNodeIdGet({nodeId});
          const {id, type, name, externalReference} = node;
          const {sequence} = stop;
          stops[stopIndex] = {
            ...stop,
            sequence,
            nodeId: id,
            siteType: type,
            siteName: name,
            externalReference,
            departureTime,
            totalServiceTime,
            totalServiceTimeChangeReason,
          };
        }
      }
    }

    if (added) {
      // remove placeholder
      stops.splice(0, 1);
      // ensure the lastStop remains at the end by removing it from the array
      const lastStop = stops.pop();

      // calc sequence
      const newSequence = lastStop.sequence;
      lastStop.sequence += 1;

      const node = await nodeApi.apiNodeNodeIdGet({nodeId: added.nodeId});

      const {id, type, name, externalReference} = node;

      // check if totalServiceTime is added
      const totalServiceTime = _.get(added, 'totalServiceTime', undefined);

      // check if totalServiceTimeChangeReason is added
      const totalServiceTimeChangeReason = _.get(
        added,
        'totalServiceTimeChangeReason',
        undefined
      );

      const addedStop = {
        node,
        taskTemplateNodeType: added.taskTemplateNodeType,
        sequence: newSequence,
        nodeId: id,
        siteType: type,
        siteName: name,
        externalReference,
        totalServiceTime,
        totalServiceTimeChangeReason,
      };

      // add the new stop
      stops.push(addedStop);

      // add the last stop (depot)
      stops.push(lastStop);
    }

    this.setState({tripStops: stops});
    this.postCommitChanges();
  };

  toggleEditSequence = () => {
    this.setState((prevState) => ({editSequence: !prevState.editSequence}));
    this.cancelNewRow();
  };

  handleSubmit = async (clonedTrip, tripStops) => {
    const t = TripService.formulateEditPayload(clonedTrip, tripStops);
    return await tripApi.apiMasterTripEditPost({
      body: t,
    });
  };

  handleOrdersAdd = async (orders) => {
    this.setState({loading: true});
    const {tripStops, tripOrders} = this.state;

    // deep copy first
    const stops = JSON.parse(JSON.stringify(tripStops));
    const newOrders = JSON.parse(JSON.stringify(tripOrders));

    const mapOrderToStops = async (order, baseSequence) => {
      const fromNodeId = _.get(order, 'upliftPointId');
      const toNodeId = _.get(order, 'offloadPointId');

      const fromNode = await nodeApi.apiNodeNodeIdGet({nodeId: fromNodeId});
      const toNode = await nodeApi.apiNodeNodeIdGet({nodeId: toNodeId});

      return [
        // from stop
        {
          // id - required
          nodeId: fromNodeId,
          // sequence - required
          sequence: baseSequence,
          orders: [order],

          siteName: _.get(fromNode, 'name', null),
          siteType: _.get(fromNode, 'type', null),
          taskTemplateNodeType: _.get(fromNode, 'type', null),
          totalServiceTime: null,
          externalReference: _.get(fromNode, 'externalReference', null),
          arrivalTime: null,
          departureTime: null,
          unloadingTimeInMinutes: _.get(
            fromNode,
            'unloadingTimeInMinutes',
            null
          ),
        },
        // to stop
        {
          // id - required
          nodeId: toNodeId,
          // sequence - required
          sequence: baseSequence + 1,
          orders: [order],

          siteName: _.get(toNode, 'name', null),
          siteType: _.get(toNode, 'type', null),
          taskTemplateNodeType: _.get(toNode, 'type', null),
          totalServiceTime: null,
          externalReference: _.get(toNode, 'externalReference', null),
          arrivalTime: null,
          departureTime: null,
          unloadingTimeInMinutes: _.get(toNode, 'unloadingTimeInMinutes', null),
        },
      ];
    };

    try {
      // ensure the lastStop remains at the end by removing it from the array
      const lastStop = stops.pop();

      // calc sequence
      let nextSequence = lastStop.sequence;

      await Promise.all(
        orders.map(async (o) => {
          const newStops = await mapOrderToStops(o, nextSequence);
          nextSequence += newStops.length;
          // add the order
          newOrders.push(o);

          // add the new stops
          stops.push.apply(stops, newStops);
        })
      );

      // add the last stop (depot)
      lastStop.sequence = nextSequence;
      stops.push(lastStop);

      this.setState({
        tripStops: stops,
        tripOrders: newOrders,
      });
    } catch (e) {
      // TODO handle exception
    } finally {
      this.setState({loading: false});
    }
  };

  handleOrdersRemove = (order) => {
    this.setState({loading: true});
    const {tripStops, tripOrders} = this.state;

    // deep copy first
    const stops = JSON.parse(JSON.stringify(tripStops));
    const orders = JSON.parse(JSON.stringify(tripOrders));

    try {
      // remove from orders
      orders.splice(
        orders.findIndex((o) => o.id === order.id),
        1
      );

      // remove from trips
      const removeStopOrder = (stop, order) => {
        // check if order in trip.orders
        const newOrders = [];
        stop.orders.forEach((o) => {
          // remove if order in trip.orders
          if (o.id !== order.id) {
            newOrders.push(o);
          }
        });
        stop.orders = newOrders;
      };

      stops.forEach((s) => {
        removeStopOrder(s, order);
      });

      // update state
      this.setState({
        tripStops: stops,
        tripOrders: orders,
      });
    } finally {
      this.setState({loading: false});
    }
  };

  handleTabChange = (event, value) => {
    if (this.state.tabValue === value) {
      return;
    }

    this.cancelNewRow();
    this.setState({tabValue: value});
  };

  generalHandleSubmit = async () => {
    const {trip} = this.props;
    const clonedTrip = clone()(trip);
    const {tripStops} = this.state;

    // TODO setting the tripStops to [] and then reusing it directly after
    //  this could possibly cause issues
    this.setState({loading: true, tripStops: []});

    if (this.props.overrideSubmit) {
      await this.props.overrideSubmit(clonedTrip, tripStops);
    } else {
      try {
        await this.handleSubmit(clonedTrip, tripStops);
        // TODO find a better way of refreshing if possible
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } catch (e) {
        appNotificationStore.enqueueNotification('warning', 'Cannot edit trip');
        // TODO find a better way of refreshing if possible
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } finally {
        this.setState({loading: false});
      }
    }

    this.setState({tripStops: []});
  };

  generalHandleReset = async () => {
    this.cancelNewRow();
    const {trip} = this.props;
    const clonedTrip = clone()(trip);
    const {contractCode, stops} = clonedTrip;

    const tripStops = TripService.loadEditTripStops(clonedTrip);

    // get all orders assigned to trip stops
    const tripStopOrders = [];
    stops.forEach((ts) => {
      tripStopOrders.push.apply(tripStopOrders, ts.orders);
    });
    const tripOrders = _.uniqBy(tripStopOrders, 'id');

    this.setState({
      contractCode,
      loading: false,
      tripStops,
      initialTripStops: clone()(tripStops),
      tripOrders,
    });
  };

  cancelTripStopTotalServiceTime = (stopId) => {
    if (!stopId) return;

    const {tripStops, initialTripStops} = this.state;
    const clonedTripStops = clone()(tripStops);
    const clonedInitialTripStops = clone()(initialTripStops);
    if (clonedInitialTripStops) {
      const tripStop = clonedInitialTripStops.find(({id}) => id === stopId);
      const tripStopIndex = clonedInitialTripStops.indexOf(tripStop);
      const initialStopTAT =
        clonedInitialTripStops[tripStopIndex].totalServiceTime ??
        getStopPlannedTAT(clonedInitialTripStops[tripStopIndex]);

      clonedTripStops[tripStopIndex] = {
        ...clonedTripStops[tripStopIndex],
        totalServiceTime: initialStopTAT,
      };

      this.setState({
        tripStops: clonedTripStops,
      });
    }
  };

  cancelTripStopDepartureTime = (stopId) => {
    if (!stopId) return;

    const {tripStops, initialTripStops} = this.state;
    const clonedTripStops = clone()(tripStops);
    const clonedInitialTripStops = clone()(initialTripStops);
    if (clonedTripStops) {
      const tripStop = clonedInitialTripStops.find(({id}) => id === stopId);
      const tripStopIndex = clonedInitialTripStops.indexOf(tripStop);

      if (tripStopIndex === 0) {
        clonedTripStops[tripStopIndex] = {
          ...clonedTripStops[tripStopIndex],
          departureTime: clonedInitialTripStops[tripStopIndex]?.departureTime,
        };

        this.setState({
          tripStops: clonedTripStops,
        });
      }
    }
  };

  render() {
    const {classes, style, withoutForm} = this.props;
    const {
      contractCode,
      expanded,
      creatingRow,
      expandedRowIds,
      editingRowIds,
      rowChanges,
      editSequence,
      loading = false,
      tripStops,
      initialTripStops,
      tripOrders,
      tabValue,
    } = this.state;

    return (
      <Card style={style}>
        <CardHeader
          style={{cursor: 'pointer'}}
          title="Trip Edit"
          avatar={
            <Avatar aria-label="TripEdit">
              <CloudIcon />
            </Avatar>
          }
          onClick={this.handleExpandClick}
          action={
            <IconButton
              className={classnames(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              aria-expanded={expanded}
              aria-label="Show more"
              size="large"
            >
              <ExpandMoreIcon />
            </IconButton>
          }
        />
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Tabs
            value={tabValue}
            onChange={this.handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            style={{width: 350}}
          >
            <Tab label="Stops" />
            <Tab label="Orders" />
          </Tabs>
          <Card>
            <CardContent style={{padding: 0}}>
              {tabValue === 0 && (
                <>
                  <ToggleEditingHeader
                    value={editSequence}
                    toggle={this.toggleEditSequence}
                    style={{position: 'relative', margin: 20}}
                  />
                  <AddCancelHeader
                    loading={loading}
                    createNewRow={this.createNewRow}
                    cancelNewRow={this.cancelNewRow}
                    creatingRow={creatingRow}
                    showAdd={
                      RoleService.hasPermission('Edit MasterTrip', 'Edit') &&
                      !editSequence
                    }
                    style={{position: 'relative'}}
                  />
                  <TripEditCardSortableList
                    // need to deep copy as some component/function is editing the referenced objects within the array
                    items={JSON.parse(JSON.stringify(tripStops))}
                    itemsInitial={JSON.parse(JSON.stringify(initialTripStops))}
                    onSortEnd={this.onSortEnd}
                    creatingRow={creatingRow}
                    expandedRowIds={expandedRowIds}
                    editingRowIds={editingRowIds}
                    rowChanges={rowChanges}
                    loading={loading}
                    editSequence={editSequence}
                    onExpandedRowIdsChange={this.onExpandedRowIdsChange}
                    changeRowChanges={this.changeRowChanges}
                    changeEditingRowIds={this.changeEditingRowIds}
                    commitChanges={this.commitChanges}
                    contractCode={contractCode}
                    allowDepotEdits={
                      this.props.allowDepotEdits
                        ? this.props.allowDepotEdits
                        : false
                    }
                    withoutForm={withoutForm}
                  />
                </>
              )}
              {tabValue === 1 && (
                <TripEditOrders
                  tripOrders={tripOrders}
                  contractCode={contractCode}
                  loading={loading}
                  onOrdersAdd={this.handleOrdersAdd}
                  onOrdersRemove={this.handleOrdersRemove}
                  onGeneralSubmit={this.generalHandleSubmit}
                  onGeneralReset={this.generalHandleReset}
                />
              )}

              <TripEditActions
                isDirty={initialTripStops !== tripStops}
                handleSubmit={this.generalHandleSubmit}
                handleReset={this.generalHandleReset}
              />
            </CardContent>
          </Card>
        </Collapse>
      </Card>
    );
  }
}

export const TripEditCard = withStyles(styles)(TripEditCardComponent);
