import React from 'react';
import {WebListDocumentStorageDump} from '@onroadvantage/onroadvantage-api';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../../../factory/template';
import {
  TripStopDocumentListContext,
  TripStopDocumentListContextProvider,
} from './TripStopDocumentListContext';

export const TripStopDocumentList: React.FC = () => {
  const [config] = React.useState<
    TemplateTableConfig<WebListDocumentStorageDump>
  >({
    columns: [
      {
        name: 'id',
        label: 'ID',
        type: 'number',
        enableFilter: false,
        enableSort: false,
      },
      {
        name: 'documentType',
        label: 'Document Type',
        type: 'string',
        enableFilter: true,
      },
      {name: 'status', label: 'Status', type: 'string', enableFilter: true},
      {
        name: 'createdAt',
        label: 'Created Date',
        type: 'datetime',
        enableFilter: true,
      },
    ],
    enableFilter: true,
    enableSort: true,
    enableGrouping: false,
    identifier: 'TRIP_STOP_DOCUMENT_LIST',
  });

  return (
    <TripStopDocumentListContextProvider>
      <TemplateTableWrapper
        config={config}
        context={TripStopDocumentListContext}
      />
    </TripStopDocumentListContextProvider>
  );
};
