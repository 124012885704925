import React from 'react';
import {BroadcastVantageMobileMessageStep} from './BroadcastVantageMobileMessageStep';
import {BroadcastVantageMobileDriversStep} from './broadcastVantageMobileDriversStep/BroadcastVantageMobileDriversStep';

export const broadcastVantageMobileSteps = ['drivers', 'message'] as const;

export type BroadcastVantageMobileSteps =
  (typeof broadcastVantageMobileSteps)[number];

export const broadcastVantageMobileStepsElements: {
  [key in BroadcastVantageMobileSteps]: React.ReactNode;
} = {
  drivers: <BroadcastVantageMobileDriversStep />,
  message: <BroadcastVantageMobileMessageStep />,
};

export const broadcastVantageMobileStepsLabels: {
  [key in BroadcastVantageMobileSteps]: string;
} = {
  drivers: 'Select Drivers with Mobile',
  message: 'Send Message',
};

const getAdjacentStep = (
  step: BroadcastVantageMobileSteps,
  side: 'next' | 'prev'
) => {
  const indexOfStep = broadcastVantageMobileSteps.indexOf(step);
  if (side === 'next' && broadcastVantageMobileSteps.length > indexOfStep + 1) {
    return broadcastVantageMobileSteps[
      indexOfStep + 1
    ] as BroadcastVantageMobileSteps;
  }
  if (side === 'prev' && indexOfStep > 0) {
    return broadcastVantageMobileSteps[
      indexOfStep - 1
    ] as BroadcastVantageMobileSteps;
  }
  return null;
};

export const useBroadcastVantageMobile = () => {
  const [activeStep, setActiveStep] =
    React.useState<BroadcastVantageMobileSteps>('drivers');
  const [completedSteps, setCompletedSteps] = React.useState<
    BroadcastVantageMobileSteps[]
  >([]);

  const handleNextStep = React.useCallback(
    (currentStep: BroadcastVantageMobileSteps) => {
      const nextStep = getAdjacentStep(currentStep, 'next');
      if (nextStep != null) {
        setActiveStep(nextStep);
        if (
          broadcastVantageMobileSteps.indexOf(nextStep) ===
          broadcastVantageMobileSteps.length - 1
        ) {
          setCompletedSteps((currentCompletedSteps) => [
            ...currentCompletedSteps,
            currentStep,
            nextStep,
          ]);
        } else {
          setCompletedSteps((currentCompletedSteps) => [
            ...currentCompletedSteps,
            currentStep,
          ]);
        }
      }
    },
    []
  );

  const handlePreviousStep = React.useCallback(() => {
    setActiveStep(
      (currentStep) => getAdjacentStep(currentStep, 'prev') ?? currentStep
    );
  }, []);

  const handleGoToStep = React.useCallback(
    (targetStep: BroadcastVantageMobileSteps) => {
      if (completedSteps.includes(targetStep)) {
        setActiveStep(targetStep);
      }
    },
    [completedSteps]
  );

  return {
    activeStep,
    completedSteps,
    onPreviousStep: handlePreviousStep,
    onGoToStep: handleGoToStep,
    onNextStep: handleNextStep,
  };
};
