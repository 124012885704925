import React from 'react';
import {
  illustrationRatio,
  useCompartmentTruckStyles,
} from './CompartmentTruck.style';
import {VehicleCompartment} from '@onroadvantage/onroadvantage-api';
import {IVantageDialogRef} from '../dialog';
import {Paper} from '@mui/material';
import clsx from 'clsx';
import {Pageview} from '@mui/icons-material';
import truck from '../../img/background/compartmentBackground.png';
import {CompartmentVertical} from './CompartmentVertical';
import {
  CompartmentTruckCompartment,
  CompartmentTruckCompartmentProps,
} from './CompartmentTruckCompartment';
import {CompartmentVerticalCompartmentProps} from './CompartmentVerticalCompartment';

export interface CompartmentTruckProps
  extends Omit<CompartmentTruckCompartmentProps, 'compartment'>,
    Omit<
      CompartmentVerticalCompartmentProps,
      'compartment' | 'onNavigateInDialog'
    > {
  compartments: VehicleCompartment[];
  disableVerticalView?: boolean;
  paperClass?: string;
  maxWidth?: React.CSSProperties['maxWidth'] | undefined;
  compartmentDialogRef?: React.RefObject<IVantageDialogRef>;
}

export const CompartmentTruck: React.FC<CompartmentTruckProps> = ({
  compartments,
  maxWidth,
  children,
  compartmentDialogRef: compartmentDialogRefProp,
  disableCompartmentTooltip,
  disableVerticalView,
  onDelete,
  onNavigate,
  paperClass,
  getActive,
  getVerticalCompartmentContent,
  getVerticalCompartment,
  getCompartmentContent,
  getCompartment,
  tooltip,
}) => {
  const classes = useCompartmentTruckStyles();
  const [totalWidth, setTotalWidth] = React.useState<number>(0);
  const [imageLoaded, setImageLoaded] = React.useState<boolean>(false);
  const parentRef = React.useRef<HTMLDivElement>(null);
  const compartmentDialogRef = React.useRef<IVantageDialogRef>(null);

  React.useEffect(() => {
    if (parentRef.current) {
      setTotalWidth(parentRef?.current?.offsetWidth);
    }
    window.onresize = () => {
      if (parentRef.current) {
        setTotalWidth(parentRef?.current?.offsetWidth);
      }
    };
    return () => setTotalWidth(0);
  }, []);

  const handleOpenCompartmentDialog = React.useCallback(() => {
    if (compartmentDialogRefProp) {
      compartmentDialogRefProp.current?.openDialog();
    } else {
      compartmentDialogRef.current?.openDialog();
    }
  }, [compartmentDialogRefProp]);

  const handleImageLoad = React.useCallback(() => setImageLoaded(true), []);

  return (
    <>
      {!disableVerticalView && (
        <CompartmentVertical
          compartmentDialogRef={
            compartmentDialogRefProp ?? compartmentDialogRef
          }
          compartments={compartments}
          onNavigate={onNavigate}
          getVerticalCompartmentContent={getVerticalCompartmentContent}
          getVerticalCompartment={getVerticalCompartment}
          getActive={getActive}
        />
      )}
      <Paper
        elevation={0}
        ref={parentRef}
        className={clsx(classes.root, paperClass)}
      >
        {!disableVerticalView && (
          <Pageview
            className={classes.pageView}
            fontSize="large"
            color="inherit"
          />
        )}
        <img
          src={truck}
          className={classes.truck}
          alt="background"
          style={{maxWidth}}
          onLoad={handleImageLoad}
          onClick={
            !disableVerticalView ? handleOpenCompartmentDialog : undefined
          }
        />
        {imageLoaded && (
          <div
            className={classes.container}
            style={{
              left: totalWidth * 0.23,
              width: totalWidth * 0.97 - totalWidth * 0.23,
              height: totalWidth * illustrationRatio * 0.61,
            }}
          >
            {compartments
              ?.sort((a, b) =>
                a.position > b.position ? 1 : a.position < b.position ? -1 : 0
              )
              ?.map((compartment, index) => (
                <CompartmentTruckCompartment
                  key={compartment.id ?? index}
                  compartment={compartment}
                  onNavigate={onNavigate}
                  onDelete={onDelete}
                  getActive={getActive}
                  getCompartmentContent={getCompartmentContent}
                  disableCompartmentTooltip={disableCompartmentTooltip}
                  getCompartment={getCompartment}
                  tooltip={tooltip}
                />
              ))}
            {children}
          </div>
        )}
      </Paper>
    </>
  );
};
