import React from 'react';
import {OrderForm} from './orderForm';
import {ITemplateTabControllerTab, TemplateTabs} from '../../factory/template';
import {PlanningOrderCreateForm} from './planningOrderCreateForm';

export const OrderAdd: React.FC = () => {
  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {title: 'Details', element: <OrderForm isAdd />},
    {
      title: 'Create Planning Order',
      element: <PlanningOrderCreateForm />,
    },
  ]);

  const path = React.useMemo(() => `/orderlist/add`, []);

  return <TemplateTabs path={path} tabs={tabs} />;
};
