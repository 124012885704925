import React from 'react';
import {MasterRouteContext} from './MasterRouteContext';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../factory/template';
import {MasterRoute} from '@onroadvantage/onroadvantage-api';

export const MasterRouteList: React.FC = () => {
  const {loadList, cleanupList} = React.useContext(MasterRouteContext);

  const [config] = React.useState<TemplateTableConfig<MasterRoute>>({
    columns: [
      {name: 'name', label: 'Name', type: 'string'},
      {name: 'masterTripPrefix', label: 'Master Trip Prefix', type: 'string'},
      {
        name: 'contractCode',
        label: 'Contract Code',
        type: 'string',
        getValue: ({contract}) => contract.code,
      },
    ],
    enableFilter: true,
    enableSort: true,
    deleteMessage: (row) => ({
      title: 'Master Route',
      items: {Name: row.name},
    }),
    identifier: 'MASTER_ROUTE_LIST',
  });

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [loadList, cleanupList]);

  return <TemplateTableWrapper config={config} context={MasterRouteContext} />;
};
