const pad = (value: number) => `0${value}`.slice(-2);

// eslint-disable-next-line import/prefer-default-export
export const DurationService = {
  getDDHHMMSS: (value: number) => {
    const days = Math.floor(value / 86400);
    const hours = Math.floor((value % 86400) / 3600);
    const minutes = Math.floor((value % 3600) / 60);
    const seconds = value % 60;

    return `${days}:${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
  },
};
