import React from 'react';
import {useHistory} from 'react-router-dom';
import {
  ApiTransporterGetRequest,
  Transporter as TransporterType,
  TransporterListingResponse,
  TransporterPost,
} from '@onroadvantage/onroadvantage-api';
import {FormikHelpers, FormikProps} from 'formik/dist/types';
import {transporterApi} from '../../api';
import {TemplateTableContextProps} from '../../factory/template';
import {useTemplateTable, TLoadList} from '../../factory/template';
import {useAppNotifications} from '../../contexts';

export interface TransporterContextProps
  extends TemplateTableContextProps<
    TransporterType,
    TransporterListingResponse
  > {
  onDetailsFormSubmit: (
    values: TransporterPost,
    formikHelpers: FormikHelpers<TransporterPost>
  ) => void;
  setTransporterId: (value: number | undefined) => void;
  submitting: boolean;
  transporter?: TransporterType;
  transporterId?: number;
  detailsRef?: React.Ref<FormikProps<TransporterPost>>;
}

export const TransporterContext = React.createContext<TransporterContextProps>({
  // Template Table Defaults
  loading: false,
  list: [],
  currentPage: 1,
  // Transporter
  loadList: async () => {},
  onDetailsFormSubmit: () => null,
  setTransporterId: () => null,
  submitting: false,
});

interface TransporterContextProviderProps {
  transporterId?: number;
}

export const TransporterContextProvider: React.FC<
  TransporterContextProviderProps
> = ({children}) => {
  const history = useHistory();
  const notify = useAppNotifications();
  // Template Table
  const [
    {
      currentPage,
      pageSize,
      pageTotal,
      sorting,
      hasPermission,
      itemTotal,
      list,
      loading,
      loadingSingleItem,
      filters,
    },
    {
      // Getters
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
      setLoadingSingleItem,
    },
  ] = useTemplateTable<TransporterType, ApiTransporterGetRequest>({
    editPermission: 'Edit Transporter',
    addPermission: 'Add Transporter',
    deletePermission: 'Delete Transporter',
    downloadPermission: 'Transporter ListDownload',
    viewPermission: 'Transporter List',
  });

  const loadList = React.useCallback<TLoadList<TransporterListingResponse>>(
    async (options) => {
      setLoading(true);
      try {
        return getResponse(
          await transporterApi.apiTransporterGet(
            getRequestObj(['name', 'description'], options)
          ),
          options
        );
      } catch (e) {
        notify('error', e.message ?? 'Failed to load Transporter List');
      } finally {
        setLoading(false);
      }
    },
    [getRequestObj, getResponse, notify, setLoading]
  );

  const handleDelete = React.useCallback(
    async (row: TransporterType) => {
      setLoading(true);
      try {
        if (row.id) {
          await transporterApi.apiTransporterTransporterIdDelete({
            transporterId: row.id,
          });
          await loadList();
          notify('success', 'Deleted Transporter');
        }
      } catch (e) {
        notify('error', e.message);
      } finally {
        setLoading(false);
      }
    },
    [loadList, notify, setLoading]
  );
  const handleAdd = React.useCallback(
    () => history.push('/transporterlist/add'),
    [history]
  );
  const handleNavigate = React.useCallback(
    (row) => history.push(`/transporterlist/${row.id}`),
    [history]
  );
  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  // Forms
  const [transporter, setTransporter] = React.useState<
    TransporterType | undefined
  >();
  const [transporterId, setTransporterId] = React.useState<number>();
  const [submitting, setSubmitting] = React.useState(false);

  const detailsRef = React.useRef<FormikProps<TransporterPost>>(null);

  const loadTransporter = React.useCallback(async () => {
    setLoadingSingleItem(true);
    try {
      if (transporterId) {
        const response = await transporterApi.apiTransporterTransporterIdGet({
          transporterId,
        });
        setTransporter(response);
      } else {
        setTransporter(undefined);
      }
    } catch (e) {
      notify('error', e.message ?? 'Failed to load Transporter');
    } finally {
      setLoadingSingleItem(false);
    }
  }, [notify, setLoadingSingleItem, transporterId]);

  const handleDetailsFormSubmit = React.useCallback(
    async (
      values: TransporterPost,
      formikHelpers: FormikHelpers<TransporterPost>
    ) => {
      setSubmitting(true);
      try {
        const newValues: TransporterPost = values;

        if (transporter && transporter.id) {
          await transporterApi.apiTransporterTransporterIdPatch({
            transporterId: transporter.id,
            body: newValues,
          });
        } else {
          await transporterApi.apiTransporterPost({
            body: newValues,
          });
        }
        history.push('/transporterlist');
        notify(
          'success',
          `${transporter?.id ? 'Updated' : 'Added'} Transporter`
        );
      } catch (e) {
        notify(
          'error',
          e.message ??
            `Failed to ${transporter?.id ? 'update' : 'add'} Transporter`
        );
      } finally {
        formikHelpers.setSubmitting(false);
        setSubmitting(false);
      }
    },
    [history, notify, transporter]
  );

  const value: TransporterContextProps = {
    // Template Table
    list,
    loadList,
    hasPermission,
    loading: loading || loadingSingleItem,
    cleanupList,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onAdd: hasPermission.add ? handleAdd : undefined,
    onNavigate: handleNavigate,
    onDelete: hasPermission.delete ? handleDelete : undefined,
    onRefresh: handleRefresh,
    // Forms
    onDetailsFormSubmit: handleDetailsFormSubmit,
    setTransporterId,
    submitting,
    transporter,
    transporterId,
    detailsRef,
  };

  React.useEffect(() => {
    loadTransporter();
    return () => setTransporter(undefined);
  }, [loadTransporter]);

  return (
    <TransporterContext.Provider value={value}>
      {children}
    </TransporterContext.Provider>
  );
};
