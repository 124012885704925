import React from 'react';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import EditIcon from '@mui/icons-material/Edit';
import {DriverDetailsForm} from './DriverDetailsForm';

export const DriverDetails: React.FC = () => {
  return (
    <Card>
      <CardHeader
        title="Driver Details Edit"
        avatar={
          <Avatar>
            <EditIcon />
          </Avatar>
        }
      />
      <CardContent>
        <DriverDetailsForm />
      </CardContent>
    </Card>
  );
};
