import React from 'react';
import {useTripSetupStopsStepStyles} from './TripSetupStopsStep.styles';
import {TripSetupFormStop, useTripSetupContext} from '../TripSetupContext';
import {IconButton, Stack, Tooltip, Typography} from '@mui/material';
import {Delete, DragIndicator} from '@mui/icons-material';

interface TripSetupStopsStepSortingStopProps {
  stop: TripSetupFormStop;
  editingState: 'sorting' | 'removing';
}

export const TripSetupStopsStepSortingStop: React.FC<
  TripSetupStopsStepSortingStopProps
> = ({stop, editingState}) => {
  const classes = useTripSetupStopsStepStyles();
  const {setOrders, setStops} = useTripSetupContext();

  const order = stop.orders && stop.orders.length > 0 ? stop.orders[0] : null;

  const handleRemoveStop = React.useCallback<
    React.MouseEventHandler<HTMLButtonElement>
  >(
    (event) => {
      event.stopPropagation();
      setStops((currentStops) =>
        currentStops
          .filter(({sequence}) => sequence !== stop.sequence)
          .map((stop, index) => ({...stop, sequence: index + 1}))
      );
      const order =
        stop.orders && stop.orders.length > 0 ? stop.orders[0] : null;

      if (order?.orderNumber) {
        setOrders((currentOrders) =>
          currentOrders.filter(
            ({orderNumber}) => orderNumber !== order.orderNumber
          )
        );
      }
    },
    [setOrders, setStops, stop.orders, stop.sequence]
  );

  const label =
    order?.upliftPointNode?.label && order?.offloadPointNode?.label
      ? `${order?.upliftPointNode?.label} -> ${order?.offloadPointNode?.label}`
      : stop.node.label ?? null;

  if (label == null) {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        flex={1}
        className={classes.stop}
      >
        <Typography>Could not find node for the current site</Typography>
      </Stack>
    );
  }

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      style={{cursor: editingState === 'sorting' ? 'move' : 'default'}}
      spacing={2}
      flex={1}
      className={classes.stop}
    >
      <Stack direction="row" alignItems="center" spacing={2}>
        {editingState === 'removing' ? (
          <Tooltip
            title={
              stop.orders && stop.orders.length > 0
                ? 'Remove stops for this order'
                : 'Remove Stop'
            }
            arrow
          >
            <IconButton onClick={handleRemoveStop} size="small">
              <Delete />
            </IconButton>
          </Tooltip>
        ) : (
          <IconButton
            size="small"
            style={{cursor: 'move'}}
            disableFocusRipple
            disableRipple
            disableTouchRipple
          >
            <DragIndicator />
          </IconButton>
        )}
        <Typography>{label}</Typography>
      </Stack>
      {order?.orderNumber != null ? (
        <Typography color="text.secondary">{order.orderNumber}</Typography>
      ) : (
        <Typography color="text.secondary">Depot</Typography>
      )}
    </Stack>
  );
};
