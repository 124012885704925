import React from 'react';
import {Table} from '@devexpress/dx-react-grid-material-ui';
import {useTableRowStyles} from './TableRow.style';
import {useInternalTableContext} from '../../TemplateTable';
import clsx from 'clsx';

export type TOnRightClick<T = any> = (
  event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
  row: T
) => void;

export const TableRow: React.FC<Table.DataRowProps> = ({
  children,
  row,
  ...props
}) => {
  const {onDragStart, onRightClick, selection, config} =
    useInternalTableContext();
  const classes = useTableRowStyles();
  const [rowClass, setRowClass] = React.useState<string>('');

  const {getRowClass} = config;

  React.useEffect(() => {
    if (getRowClass) {
      const getClass = getRowClass(row);
      if (getClass) setRowClass(getClass);
    }
    return () => setRowClass('');
  }, [getRowClass, row]);

  const handleRightClick = React.useCallback(
    (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
      if (onRightClick) {
        event.preventDefault();
        onRightClick(event, row);
      }
    },
    [onRightClick, row]
  );

  const handleDragStart = React.useCallback(
    (event: React.DragEvent<HTMLTableRowElement> & {target: {id: any}}) => {
      if (onDragStart) {
        event.dataTransfer.effectAllowed = 'move';
        onDragStart(event, row);
      }
    },
    [onDragStart, row]
  );

  return (
    <Table.Row
      {...props}
      className={clsx(classes.root, {
        [classes.selected]: selection?.includes(row),
        [classes.rootRightClick]: !!onRightClick,
        [rowClass ?? '']: !!rowClass,
      })}
      onContextMenu={handleRightClick}
      onDragStart={handleDragStart}
      draggable={!!onDragStart}
      row={row}
    >
      {children}
    </Table.Row>
  );
};
