import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

const OrderRecipientStyles = (theme: Theme) =>
  createStyles({
    mainLayout: {
      display: 'flex',
      maxWidth: '100%',
      paddingLeft: '1rem',
      [theme.breakpoints.down('md')]: {
        paddingLeft: '0',
        gap: '1.5rem',
        flexDirection: 'column',
      },
    },
    refreshBanner: {
      maxWidth: '100%',
      display: 'grid',
      placeContent: 'center',
      padding: '0.3rem 0.8rem',
      marginBottom: '1rem',
      margin: '0 1rem 1rem',
      borderRadius: '5px',
      background: theme.palette.warning.light,
    },
    root: {width: '100%', margin: '1rem auto'},
    title: {margin: '0 1rem 0.5rem', fontSize: '26px'},
  });

export const useOrderRecipientStyles = makeStyles(OrderRecipientStyles, {
  name: 'OrderRecipientStyles',
});
