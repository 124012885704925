import React from 'react';
import _ from 'lodash';
import {
  ITemplateControllerRoute,
  TemplateController,
} from '../../factory/template';
import {NodeTypeContext, NodeTypeContextProvider} from './NodeTypeContext';
import {NodeTypeList} from './NodeTypeList';
import {NodeTypeIdHandler} from './NodeTypeIdHandler';
import {NodeTypeForm} from './NodeTypeForm';
import {NodeType} from './NodeType';

export const NodeTypeController: React.FC = () => {
  return (
    <NodeTypeContextProvider>
      <NodeTypeControllerComponent />
    </NodeTypeContextProvider>
  );
};

const NodeTypeControllerComponent: React.FC = () => {
  const {nodeType, loading} = React.useContext(NodeTypeContext);
  const routes: ITemplateControllerRoute[] = React.useMemo(
    () => [
      {title: 'Node Type List', component: NodeTypeList},
      {
        path: '/add',
        title: 'New node Type',
        name: 'Add NodeType',
        type: 'Add',
        component: () => <NodeTypeForm isAdd />,
      },
      {
        path: '/:nodeTypeId',
        title: (params) =>
          loading
            ? 'Node Type Loading...'
            : nodeType?.name ??
              `Node Type ${_.get(params, 'nodeTypeId', 'Single')}`,
        component: NodeType,
        IdHandler: NodeTypeIdHandler,
      },
    ],
    [loading, nodeType?.name]
  );
  return <TemplateController rootPath="/nodetypelist" routes={routes} />;
};
