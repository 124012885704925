import React from 'react';
import * as Yup from 'yup';
import {MasterTripDebriefSnapshotData} from '@onroadvantage/onroadvantage-api';
import {TripDebriefContext} from './TripDebriefContext';
import {
  NUMBER_ERROR_MESSAGE,
  TemplateCard,
  TemplateForm,
  TOnFormikSubmit,
} from '../../factory/template';
import {TripDebriefDisabledTextField} from './form/TripDebriefDisabledTextField';
import {TripDebriefTextField} from './form/TripDebriefTextField';
import {useAppNotifications} from '../../contexts';
import {ITripDebriefForm} from './form/TripDebriefForm.types';
import {FormikTextField} from '../formik';
import {InputAdornment, Typography} from '@mui/material';
import {getTripActualDistance} from './helpers/getTripActualDistance';

/** ITripDebriefKilometersFormSnapshot -> For the editable field values that are overridable  */
export interface ITripDebriefKilometersFormSnapshot {
  actualTripDistance?: number | null | undefined;
  creditKilometers?: number | null | undefined;
}

/**
 * ITripDebriefKilometersForm -> For the interface typing of the form, which extends from ITripDebriefForm which is a
 * generic type that maps the ITripDebriefKilometersFormSnapshot interface to a snapshot and override key. We then also
 * pass any other props the debrief has that is editable, but what is not overridable (in other words it's not in the
 * MasterTripDebriefSnapshot interface, only on the root MasterTripDebriefSnapshotData interface).
 */
export interface ITripDebriefKilometersForm
  extends ITripDebriefForm<ITripDebriefKilometersFormSnapshot> {
  manualStartOdometer?: number | null | undefined;
  manualEndOdometer?: number | null | undefined;
}

/**
 * ITripDebriefKilometersFormDisplayOnly -> For the form values that are not editable, thus we keep them separate from
 * the formik typing so that we can ensure they can't get edited by accident
 */
export interface ITripDebriefKilometersFormDisplayOnly {
  plannedTripDistance?: number | null | undefined;
  actualDistance?: number | null | undefined; //was odometerDifference
  varianceFromPlan?: string | null | undefined;
}

/**
 * snapshotValidation -> Validation schema for the ITripDebriefKilometersFormSnapshot formik typed fields. We put them
 * in a separate schema so that we don't need to declare the schema twice in the root validationSchema.
 */
const snapshotValidation: Yup.SchemaOf<ITripDebriefKilometersFormSnapshot> =
  Yup.object({
    actualTripDistance: Yup.number().typeError(NUMBER_ERROR_MESSAGE).nullable(),
    creditKilometers: Yup.number().typeError(NUMBER_ERROR_MESSAGE).nullable(),
  });

/** validationSchema -> Validation schema for the form's typing (ITripDebriefKilometersForm)  put together */
const validationSchema: Yup.SchemaOf<ITripDebriefKilometersForm> = Yup.object({
  manualStartOdometer: Yup.number()
    .typeError(NUMBER_ERROR_MESSAGE)
    .required('Required')
    .test(
      'checkEndGTStart',
      'End Odo must be more than Start Odo',
      function () {
        const value1: number = this.resolve(Yup.ref('manualStartOdometer'));
        const value2: number = this.resolve(Yup.ref('manualEndOdometer'));
        return value2 > value1;
      }
    )
    .test(
      'check2000MaxDiff',
      'End Odo and Start Odo cannot be more than 2000km apart',
      function () {
        const value1: number = this.resolve(Yup.ref('manualStartOdometer'));
        const value2: number = this.resolve(Yup.ref('manualEndOdometer'));
        return Math.abs(value2 - value1) < 2000;
      }
    ),
  manualEndOdometer: Yup.number()
    .typeError(NUMBER_ERROR_MESSAGE)
    .required('Required')
    .test(
      'checkEndGTStart',
      'End Odo must be more than Start Odo',
      function () {
        const value1: number = this.resolve(Yup.ref('manualStartOdometer'));
        const value2: number = this.resolve(Yup.ref('manualEndOdometer'));
        return value2 > value1;
      }
    )
    .test(
      'check2000MaxDiff',
      'End Odo and Start Odo cannot be more than 2000km apart',
      function () {
        const value1: number = this.resolve(Yup.ref('manualStartOdometer'));
        const value2: number = this.resolve(Yup.ref('manualEndOdometer'));
        return Math.abs(value2 - value1) < 2000;
      }
    ),
  snapshot: snapshotValidation,
  override: snapshotValidation,
});

/**
 * Reason why we want to keep the snapshot and override separate here is because in the TripDebriefTextField Component
 * we use the snapshot value as its initialValue, and the override value is its currentValue. Then when they don't match
 * we can give them the option to reset the changedValue (override value) back to the initialValue (snapshot value)
 */
const getInitialValues = (
  masterTripDebrief: MasterTripDebriefSnapshotData | null | undefined
): ITripDebriefKilometersForm | undefined => {
  if (masterTripDebrief?.snapshotData) {
    const {snapshotData, overrideData} = masterTripDebrief;

    return {
      manualEndOdometer: masterTripDebrief.manualEndOdometer,
      manualStartOdometer: masterTripDebrief.manualStartOdometer,
      snapshot: {
        actualTripDistance: snapshotData.actualTripDistance,
        creditKilometers: snapshotData.creditKilometers,
      },
      override: overrideData
        ? {
            actualTripDistance: overrideData.actualTripDistance,
            creditKilometers: overrideData.creditKilometers,
          }
        : {},
    };
  }
  return undefined;
};

/**
 * Reason for keeping this separate from the getInitialValues, is that we want to keep editable and none-editable fields
 * separate to ensure none-editable don't get edited, as the BE doesn't currently check for the none-editable fields.
 */
const getDisplayOnlyValues = (
  masterTripDebrief: MasterTripDebriefSnapshotData | null | undefined
): ITripDebriefKilometersFormDisplayOnly | undefined => {
  if (masterTripDebrief) {
    const {displayVariance, actualDistance, plannedTripDistance} =
      getTripActualDistance(masterTripDebrief);

    return {
      plannedTripDistance,
      actualDistance,
      varianceFromPlan: displayVariance,
    };
  }
  return undefined;
};

export const TripDebriefKilometersForm: React.FC = () => {
  const notify = useAppNotifications();
  const {masterTripDebriefData, submitting, loading, onUpdateSubmit} =
    React.useContext(TripDebriefContext);
  /**
   *  Destructure masterTripDebriefData, need to add || ({} as MasterTripDebriefSnapshotData) for typescript, since
   *  masterTripDebriefData is nullable.
   */
  const {approvedForBilling} =
    masterTripDebriefData || ({} as MasterTripDebriefSnapshotData);

  const [initialValues, setInitialValues] = React.useState<
    ITripDebriefKilometersForm | undefined
  >({override: {}, snapshot: {}});

  const [displayOnlyValues, setDisplayOnlyValues] = React.useState<
    ITripDebriefKilometersFormDisplayOnly | undefined
  >();

  /**
   * handleSubmit -> Handles the formik's submit.
   * We extract the relevant ITripDebriefKilometersForm values, such as override, and send them to the onUpdateSubmit
   * handler (from the Context) in the correct format whether it is overrideData (MasterTripDebriefSnapshot) or
   * debriefData (MasterTripDebrief). We also handle the formik submitting state in this formik handler, thus not making
   * it necessary for a formik innerRef.
   * We also add a notify exception for 'No data was change', but this should never be reached.
   */
  const handleSubmit = React.useCallback<
    TOnFormikSubmit<ITripDebriefKilometersForm>
  >(
    async (values, formikHelpers) => {
      formikHelpers.setSubmitting(true);
      try {
        if (values.override) {
          const {manualEndOdometer, manualStartOdometer, override} = values;
          const {actualTripDistance, creditKilometers} = override;

          await onUpdateSubmit({
            debriefData: {manualStartOdometer, manualEndOdometer},
            overrideData: {
              actualTripDistance,
              creditKilometers,
            },
          });
        } else {
          notify('info', 'No data was changed');
        }
      } finally {
        formikHelpers.setSubmitting(false);
      }
    },
    [notify, onUpdateSubmit]
  );

  React.useEffect(() => {
    const values = getInitialValues(masterTripDebriefData);

    if (values) {
      setInitialValues(values);
    }

    setDisplayOnlyValues(getDisplayOnlyValues(masterTripDebriefData));
    return () => {
      setInitialValues({override: {}, snapshot: {}});
    };
  }, [masterTripDebriefData]);

  /**
   * FormikTextField -> None overridable, but editable fields
   * TripDebriefDisabledTextField -> Display only fields, thus none editable
   * TripDebriefTextField -> Overridable and editable fields. Also handles the reset to initialValue functionality
   */
  return (
    <TemplateCard title="Trip Kilometers Edit" loading={loading} elevation={0}>
      <TemplateForm<ITripDebriefKilometersForm>
        onSubmit={handleSubmit}
        initialValues={initialValues}
        submitting={submitting}
        validationSchema={validationSchema}
        permission={{name: 'Trip Debrief', type: 'Edit'}}
        disabled={approvedForBilling}
        enableReinitialize
      >
        <FormikTextField
          name="manualStartOdometer"
          label="Trip Debrief Manual Start Odometer"
          placeholder="Enter the trip debrief manual start odometer"
          type="number"
          disabled={approvedForBilling}
          InputLabelProps={{shrink: true}}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Typography component="legend">km</Typography>
              </InputAdornment>
            ),
          }}
          fullWidth
        />
        <FormikTextField
          name="manualEndOdometer"
          label="Trip Debrief Manual End Odometer"
          placeholder="Enter the trip debrief manual end odometer"
          type="number"
          disabled={approvedForBilling}
          InputLabelProps={{shrink: true}}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Typography component="legend">km</Typography>
              </InputAdornment>
            ),
          }}
          fullWidth
        />
        <TripDebriefDisabledTextField
          name="tripDebriefVariance"
          label="Trip Debrief KM Variance from Plan"
          value={displayOnlyValues?.varianceFromPlan}
        />
        <TripDebriefDisabledTextField
          name="tripDebriefOdometerDifference"
          label="Trip Debrief Odometer Difference"
          type="kilometers"
          value={displayOnlyValues?.actualDistance}
        />
        <TripDebriefDisabledTextField
          name="plannedTripDistance"
          label="Planned Trip Kilometers"
          type="kilometers"
          value={displayOnlyValues?.plannedTripDistance}
        />
        <TripDebriefTextField
          name="actualTripDistance"
          label="Trip Actual"
          placeholder="Enter actual trip start for the mobile"
          type="kilometers"
          fullWidth
        />
        <TripDebriefTextField
          name="creditKilometers"
          label="Trip Credit"
          placeholder="Enter trip credit"
          type="kilometers"
          fullWidth
        />
      </TemplateForm>
    </TemplateCard>
  );
};
