import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

const PlanningBoardGanttCompartmentTruckCompartmentStyles = (theme: Theme) =>
  createStyles({
    compartment: {
      position: 'relative',
      display: 'flex',
      overflow: 'hidden',
      padding: theme.spacing(0.5),
      alignSelf: 'stretch',
      flex: 1,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      textAlign: 'center',
    },
    compartmentActive: {
      border: '3px dashed #111',
    },
    content: {
      pointerEvents: 'none',
      display: 'flex',
      alignSelf: 'stretch',
      flex: 1,
      alignItems: 'stretch',
      flexDirection: 'column',
      position: 'relative',
      zIndex: 1,
    },
    divider: {
      height: 1,
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.6),
      backgroundColor: '#555',
      width: '100%',
      display: 'flex',
    },
    fillContainer: {
      position: 'absolute',
      bottom: 0,
      top: 0,
      left: 0,
      right: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'flex-end',
    },
    tooltip: {
      background: '#eee',
      padding: theme.spacing(0.6),
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
    tooltipDisabled: {display: 'none'},
    tooltipArrow: {color: '#eee'},
    tooltipContent: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
    },
  });

export const usePlanningBoardGanttCompartmentTruckCompartmentStyles =
  makeStyles(PlanningBoardGanttCompartmentTruckCompartmentStyles, {
    name: 'PlanningBoardGanttCompartmentTruckCompartmentStyles',
  });
