import React from 'react';
import {Theme} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import InfoIcon from '@mui/icons-material/Info';
import withStyles from '@mui/styles/withStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import _ from 'lodash';
import Chip from '@mui/material/Chip';

const styles = createStyles((theme: Theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

interface Props {
  classes: any;
  items: any[];
}

const KeyValuePairTable = (props: Props) => {
  const {classes, items} = props;
  const chunks = _.chunk(items, 3);
  return (
    <Card>
      <CardContent>
        <div style={{display: 'flex'}}>
          {chunks.map((chunk: any[], index) => {
            return (
              <List className={classes.root} key={index}>
                {chunk.map((item: any) => {
                  if (item.value === 'undefined' || item.value === '') {
                    return null;
                  }
                  return (
                    <ListItem key={item.key}>
                      <ListItemAvatar>
                        <Avatar>
                          <InfoIcon />
                        </Avatar>
                      </ListItemAvatar>
                      {item.key === 'Status' ? (
                        <div style={{paddingLeft: 10}}>
                          <Chip
                            color={
                              item.value === 'Completed'
                                ? 'primary'
                                : 'secondary'
                            }
                            label={item.value}
                          ></Chip>
                        </div>
                      ) : (
                        <ListItemText
                          primary={item.key}
                          secondary={item.value}
                        />
                      )}
                    </ListItem>
                  );
                })}
              </List>
            );
          })}
        </div>
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(KeyValuePairTable);
