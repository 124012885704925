import React from 'react';
import {VantageReportButton} from '../../../reportButton';
import moment from 'moment/moment';
import {Stack} from '@mui/material';
import {usePlanningBoardContext} from '../../planningBoardContext';

export const PlanningBoardPlanToolbarReportButton: React.FC = () => {
  const {contractIds, selectedContractListings, endDate, startDate} =
    usePlanningBoardContext();
  return (
    <Stack
      px={1}
      flex={1}
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
    >
      <VantageReportButton
        disableFormFields
        disableCustomDateRange
        fullWidth={false}
        variant="outlined"
        name="Planning Board Report"
        params={[
          {
            booking_master_trip_ids: [0],
            contract_ids: contractIds,
            master_trip_ids: [0],
            planning_master_trip_ids: [0],
            date: {
              endDate: endDate.toJSDate(),
              startDate: startDate.toJSDate(),
            },
          },
        ]}
        initialValues={{
          startDate: moment(startDate.toJSDate()),
          endDate: moment(endDate.toJSDate()),
          parameters: [
            {
              name: 'master_trip_ids',
              type: 'arrint',
              required: true,
              disabled: true,
              value: [
                {
                  value: 0,
                  label: 'All master_trips',
                },
              ],
            },
            {
              name: 'booking_master_trip_ids',
              type: 'arrint',
              required: true,
              disabled: true,
              value: [
                {
                  value: 0,
                  label: 'All booking_master_trips',
                },
              ],
            },
            {
              name: 'planning_master_trip_ids',
              type: 'arrint',
              required: true,
              disabled: true,
              value: [
                {
                  value: 0,
                  label: 'All planning_master_trips',
                },
              ],
            },
            {
              name: 'contract_ids',
              type: 'arrint',
              required: true,
              disabled: true,
              value: selectedContractListings,
            },
          ],
        }}
      />
    </Stack>
  );
};
