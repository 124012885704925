import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {Paper} from '@mui/material';
import {useMasterRouteControllerStyles} from './MasterRouteController.style';
import {
  MasterRouteContext,
  MasterRouteContextProvider,
} from './MasterRouteContext';
import {
  BreadcrumbController,
  BreadcrumbRoute,
} from '../breadcrumbs/BreadcrumbController';
import _ from 'lodash';
import {MasterRouteList} from './MasterRouteList';
import {MasterRouteIdHandler} from './MasterRouteIdHandler';
import {MasterRoute} from './MasterRoute';
import {MasterRouteDetails} from './masterRouteDetails';
import {
  MasterRouteLegDetails,
  MasterRouteLeg,
  MasterRouteLegIdHandler,
  MasterRouteLegContext,
  MasterRouteLegContextProvider,
} from './masterRouteLeg';
import {AllowedRoute} from '../router';

export const MasterRouteControllerMasterRoute: React.FC = () => {
  const classes = useMasterRouteControllerStyles();
  const {masterRoute, loading} = React.useContext(MasterRouteContext);

  const {masterRouteLeg, loading: legLoading} = React.useContext(
    MasterRouteLegContext
  );

  const routes: BreadcrumbRoute[] = [
    {
      path: '/masterroutelist',
      matchOptions: undefined,
      title: 'Master Route List',
    },
    {
      path: '/masterroutelist/add',
      matchOptions: undefined,
      title: 'New Master Route',
    },
    {
      path: '/masterroutelist/:masterRouteId',
      matchOptions: undefined,
      title: (params) =>
        loading
          ? 'Master Route Loading...'
          : masterRoute?.name ??
            `MasterRoute ${_.get(params, 'masterRouteId', 'Single')}`,
    },
    {
      path: '/masterroutelist/:masterRouteId/leg/add',
      matchOptions: undefined,
      title: 'New Master Route Leg',
    },
    {
      path: '/masterroutelist/:masterRouteId/leg/:masterRouteLegId',
      matchOptions: undefined,
      title: (params) =>
        legLoading
          ? 'Master Route Leg Loading...'
          : masterRouteLeg?.customer?.name ??
            `Master Route Leg ${_.get(params, 'masterRouteLegId', 'Single')}`,
    },
  ];

  return (
    <div className={classes.root}>
      <BreadcrumbController routes={routes} />
      <Paper className={classes.container}>
        <Route exact path="/masterroutelist" component={MasterRouteList} />
        <Route
          path="/masterroutelist/:masterRouteId"
          component={MasterRouteIdHandler}
        />
        <Route
          path="/masterroutelist/:masterRouteId/leg/:masterRouteLegId"
          component={MasterRouteLegIdHandler}
        />
        <Switch>
          <AllowedRoute
            exact
            path="/masterroutelist/add"
            name="Add MasterRoute"
            type="Add"
            component={() => <MasterRouteDetails isAdd />}
          />
          <Route
            exact
            path="/masterroutelist/:masterRouteId"
            component={MasterRoute}
          />
          <Route
            exact
            path="/masterroutelist/:masterRouteId/leg/add"
            component={() => <MasterRouteLegDetails isAdd />}
          />
          <Route
            exact
            path="/masterroutelist/:masterRouteId/leg/:masterRouteLegId"
            component={MasterRouteLeg}
          />
        </Switch>
      </Paper>
    </div>
  );
};

export const MasterRouteController: React.FC = () => {
  return (
    <MasterRouteContextProvider>
      <MasterRouteLegContextProvider>
        <MasterRouteControllerMasterRoute />
      </MasterRouteLegContextProvider>
    </MasterRouteContextProvider>
  );
};
