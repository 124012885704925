import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogTitleProps,
  DialogContent,
  Button,
  ButtonProps,
  DialogActions,
  IconButton,
  IconButtonProps,
  Icon,
  PaperProps,
  ModalProps,
} from '@mui/material';
import {useVantageDialogButtonStyles} from './VantageDialogButton.style';
import {getTransitionComponent} from './TransitionComponents';
import {
  MuiButtonColorTypes,
  MuiButtonVariantTypes,
  SxStyleType,
} from '../../typings/mui';

export type ReactMouseEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>;

export interface VantageDialogStyleClasses {
  acceptButton?: string;
  actions?: string;
  content?: string;
  declineButton?: string;
  dialog?: string;
  title?: string;
}

interface VantageDialogActionProps {
  color?: MuiButtonColorTypes;
  startIcon?: React.ReactNode;
  disabled?: boolean;
  onClick?: (e: ReactMouseEvent) => void;
  sx?: SxStyleType;
  style?: React.CSSProperties;
  title?: string;
  type?: 'button' | 'submit';
  variant?: MuiButtonVariantTypes;
}

interface dialogOptions {
  closeDialog?: 'before' | 'after';
}

export interface VantageDialogButtonProps {
  acceptProps?: VantageDialogActionProps;
  acceptTitle?: string;
  actionsSx?: SxStyleType;
  actionsStyle?: React.CSSProperties;
  contentSx?: SxStyleType;
  dataCy?: string;
  declineProps?: VantageDialogActionProps;
  declineTitle?: string;
  dialogActionOptions?: dialogOptions;
  DialogTitleProps?: DialogTitleProps;
  dialogSx?: SxStyleType;
  dialogTitle?: string | React.ReactNode;
  disableAccept?: boolean;
  disableDecline?: boolean;
  disableTitle?: boolean;
  enableDividers?: boolean;
  fullScreen?: boolean;
  fullWidth?: boolean;
  iconButton?: boolean;
  IconButtonProps?: IconButtonProps;
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  onAccept?: (e: ReactMouseEvent) => void;
  onClose?: ModalProps['onClose'];
  onDecline?: (e: ReactMouseEvent) => void;
  PaperComponent?: React.ComponentType<PaperProps>;
  reverseActions?: boolean;
  styles?: VantageDialogStyleClasses;
  sx?: SxStyleType;
  title: string | React.ReactElement;
  titleSx?: SxStyleType;
  transition?: 'slide' | 'fade' | 'collapse';
}

export const VantageDialogButton: React.FC<
  VantageDialogButtonProps & Omit<ButtonProps, 'title'>
> = ({
  acceptProps,
  declineProps,
  onAccept,
  acceptTitle,
  actionsSx,
  actionsStyle,
  children,
  contentSx,
  dataCy,
  onDecline,
  declineTitle,
  dialogActionOptions,
  DialogTitleProps,
  dialogSx,
  dialogTitle,
  disableAccept,
  disableDecline,
  disableTitle,
  enableDividers,
  fullScreen,
  fullWidth,
  iconButton,
  IconButtonProps,
  maxWidth,
  onClose,
  PaperComponent,
  reverseActions,
  styles,
  sx,
  title,
  titleSx,
  transition,
  onClick,
  ...props
}) => {
  const classes = useVantageDialogButtonStyles();
  const [open, setOpen] = React.useState<boolean>(false);

  const handleOpen = React.useCallback(
    (e) => {
      setOpen(true);
      if (onClick) {
        onClick(e);
      }
    },
    [setOpen, onClick]
  );

  const handleClose = React.useCallback(
    (event, reason) => {
      setOpen(false);
      onClose && onClose(event, reason);
    },
    [onClose]
  );

  const handleClick = React.useCallback(
    (action: 'Accept' | 'Decline') => async (e: ReactMouseEvent) => {
      if (
        dialogActionOptions?.closeDialog === 'before' ||
        !dialogActionOptions
      ) {
        setOpen(false);
      }
      if (action === 'Accept' && onAccept) {
        await onAccept(e);
      } else if (action === 'Decline' && onDecline) {
        await onDecline(e);
      }
      if (dialogActionOptions?.closeDialog === 'after') {
        setOpen(false);
      }
    },
    [dialogActionOptions, onAccept, onDecline]
  );

  return (
    <>
      {iconButton ? (
        <IconButton
          {...IconButtonProps}
          className={IconButtonProps?.className ?? props.className}
          size={IconButtonProps?.size}
          disabled={props.disabled}
          color={props.color}
          onClick={handleOpen}
          type="button"
          data-cy={dataCy}
        >
          {title ?? <Icon />}
        </IconButton>
      ) : (
        <Button
          {...props}
          color={props.color ?? 'primary'}
          onClick={handleOpen}
          variant={props.variant}
          type="button"
          data-cy={dataCy}
        >
          {title ?? 'button'}
        </Button>
      )}
      <Dialog
        className={styles?.dialog ?? classes.dialog}
        sx={dialogSx}
        open={open}
        onClose={handleClose}
        maxWidth={maxWidth}
        aria-label={`${title} - Vantage Dialog`}
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        PaperComponent={PaperComponent}
        TransitionComponent={getTransitionComponent(transition)}
      >
        {!disableTitle && (
          <DialogTitle
            className={styles?.title ?? classes.title}
            sx={titleSx}
            aria-label={`${title} - Vantage Dialog Title`}
            {...DialogTitleProps}
          >
            {dialogTitle ?? 'dialog'}
          </DialogTitle>
        )}
        {children && (
          <DialogContent
            className={styles?.content}
            sx={contentSx}
            dividers={enableDividers}
            aria-label={`${title} - Vantage Dialog Content`}
          >
            {children}
          </DialogContent>
        )}
        <DialogActions
          className={styles?.actions ?? classes.actions}
          sx={{
            ...(reverseActions && {flexDirection: 'row-reverse'}),
            ...actionsSx,
          }}
          style={actionsStyle}
          aria-label={`${title} - Vantage Dialog Actions`}
        >
          {!disableAccept && (
            <Button
              type={acceptProps?.type ?? 'button'}
              disabled={acceptProps?.disabled}
              onClick={handleClick('Accept')}
              color={acceptProps?.color ?? 'primary'}
              startIcon={acceptProps?.startIcon}
              variant={acceptProps?.variant}
              className={styles?.acceptButton ?? classes.acceptButton}
              sx={acceptProps?.sx}
              data-cy="VantageDialogAccept"
            >
              {acceptTitle ?? 'Yes'}
            </Button>
          )}
          {!disableDecline && (
            <Button
              type={declineProps?.type ?? 'button'}
              disabled={declineProps?.disabled}
              onClick={handleClick('Decline')}
              color={declineProps?.color ?? 'inherit'}
              className={styles?.declineButton ?? classes.declineButton}
              sx={declineProps?.sx}
              variant={declineProps?.variant}
              data-cy="VantageDialogDecline"
            >
              {declineTitle ?? 'No'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
