import {makeStyles, createStyles} from '@mui/styles';
import {Theme} from '@mui/material/styles';

const TripDebriefSummaryCardStyles = (theme: Theme) =>
  createStyles({
    root: {background: theme.palette.background.paper},
    content: {},
    contentRow: {
      flexDirection: 'row',
      [theme.breakpoints.up('md')]: {flexDirection: 'column'},
    },
    list: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    listItem: {cursor: 'default'},
    listItemText: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1.5),
      justifyContent: 'space-between',
    },
    listItemTextPrimary: {whiteSpace: 'normal'},
    listItemTextSecondary: {whiteSpace: 'nowrap'},
    children: {flex: 2},
  });

export const useTripDebriefSummaryCardStyles = makeStyles(
  TripDebriefSummaryCardStyles,
  {
    name: 'TripDebriefSummaryCardStyles',
  }
);
