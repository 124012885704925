import React from 'react';
import {Stack} from '@mui/material';
import {ApiDocsFrame} from './ApiDocsFrame';

export const FunctionalDocs: React.FC = () => {
  return (
    <Stack flex={1}>
      <ApiDocsFrame title="Functional Docs" url="/api/docs" />
    </Stack>
  );
};
