import {DateRangeSchemaType, ParameterSchemaType} from '../ReportSchema';
import {getDaterange} from './getDaterange';

export interface Daterange {
  endDate: Date;
  startDate: Date;
}

export type ReportGenerateParameterValue =
  | string
  | (string | number)[]
  | Date
  | Daterange;

export interface ReportGenerateParameter {
  type: string;
  name: string;
  value: ReportGenerateParameterValue;
}

export const getDaterangeParameter = ({
  name,
  value,
}: ParameterSchemaType): ReportGenerateParameter | null => {
  const v = value as DateRangeSchemaType;
  const range = getDaterange(v.value);

  if (range) {
    return {
      type: 'type' || 'unknown',
      name: name || 'unknown',
      value: range,
    };
  }

  return null;
};
