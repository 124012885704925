import React from 'react';
import {IconButton} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {useNavMenuButtonStyles} from './NavMenuButton.style';
import clsx from 'clsx';
import {WindowInfoContext} from '../../../contexts';

export interface NavMenuButtonProps {
  onClick?: () => void;
}

export const NavMenuButton: React.FC<NavMenuButtonProps> = ({onClick}) => {
  const classes = useNavMenuButtonStyles();
  const {isDesktop} = React.useContext(WindowInfoContext);

  return (
    <IconButton
      data-test="leftDrawerToggle"
      color="inherit"
      aria-label="open left drawer"
      onClick={onClick}
      edge="start"
      size="large"
    >
      <MenuIcon
        className={clsx(classes.icon, {
          [classes.iconDesktop]: isDesktop,
        })}
      />
    </IconButton>
  );
};
