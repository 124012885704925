import React from 'react';
import {Loader} from '../loader';
import {VehicleContext} from './VehicleBookingAvailabilityContext';
import {VehicleBookingAvailabilityForm} from './vehicleBookingAvailabilityForm';
import {VehicleBookingAvailabilityList} from './VehicleBookingAvailabilityList';
import {useVehicleBookingAvailabilityStyles} from './VehicleBookingAvailability.style';

export const VehicleBookingAvailability: React.FC = () => {
  const classes = useVehicleBookingAvailabilityStyles();
  const {loading, listRendered} = React.useContext(VehicleContext);

  const renderList = () => {
    if (loading) return <Loader />;
    if (!listRendered) return null;
    return <VehicleBookingAvailabilityList />;
  };

  return (
    <div className={classes.root}>
      <VehicleBookingAvailabilityForm />
      {renderList()}
    </div>
  );
};
