import React from 'react';
import {Marker} from 'react-leaflet';
import {inject, observer} from 'mobx-react';
import StopPopup from './popup/StopPopup';
import ActualStopPopup from './popup/ActualStopPopup';
import VehiclePopup from './popup/VehiclePopup';
import SitePopup from './popup/SitePopup';
import EventPopup from './popup/EventPopup';
import AddressPopup from './popup/AddressPopup';
import PlannedResourcePopup from './popup/PlannedResourcePopup';
import ActualResourcePopup from './popup/ActualResourcePopup';
import {
  CriticalEventDump,
  OperationalEventDump,
} from '@onroadvantage/onroadvantage-api';

interface Props {
  position: [number, number]; // lat , lng
  stop?: any;
  actualStop?: any;
  vehicle?: any;
  site?: any;
  event?: CriticalEventDump | OperationalEventDump;
  address?: any;
  icon?: any;
  plannedResource?: any;
  actualResource?: any;
  mapDisplayStore?: any;
}

class BaseMarker extends React.Component<Props> {
  render() {
    const {
      position,
      stop,
      actualStop,
      vehicle,
      site,
      event,
      address,
      icon,
      plannedResource,
      actualResource,
    } = this.props;

    return (
      <Marker position={position} icon={icon}>
        {stop && <StopPopup stop={stop} />}
        {actualStop && (
          <ActualStopPopup actualStop={actualStop} position={position} />
        )}
        {vehicle && <VehiclePopup vehicle={vehicle} />}
        {site && <SitePopup site={site} />}
        {event && <EventPopup event={event} />}
        {address && <AddressPopup address={address} />}
        {plannedResource && (
          <PlannedResourcePopup plannedResource={plannedResource} />
        )}
        {actualResource && (
          <ActualResourcePopup actualResource={actualResource} />
        )}
      </Marker>
    );
  }
}

export default inject('mapDisplayStore')(observer(BaseMarker));
