import React from 'react';
import {DataTypeProvider} from '@devexpress/dx-react-grid';
import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Typography,
} from '@mui/material';

export const getPercentage = (value?: string | number): number => {
  const progressInt =
    typeof value === 'string' ? parseInt(value, 10) : value ?? 0;
  return Math.round(Math.min(Math.max(progressInt * 100, 0), 100));
};

interface CircularProgressWithLabelProps extends CircularProgressProps {
  value: number;
}

const CircularProgressWithLabel: React.FC<CircularProgressWithLabelProps> = (
  props
) => {
  return (
    <Box sx={{position: 'relative', display: 'inline-flex'}}>
      <CircularProgress size={45} variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
};

export const TableProgressCell: React.FC<
  DataTypeProvider.ValueFormatterProps
> = ({value}) => {
  const [progress, setProgress] = React.useState<number>(0);

  React.useEffect(() => {
    setProgress(getPercentage(value) ?? 0);
    return () => {
      setProgress(0);
    };
  }, [value]);

  return (
    <CircularProgressWithLabel
      value={progress}
      color={
        progress < 25
          ? 'error'
          : progress < 50
          ? 'warning'
          : progress < 75
          ? 'info'
          : progress < 100
          ? 'secondary'
          : progress === 100
          ? 'primary'
          : 'inherit'
      }
    />
  );
};
