import React from 'react';
import {ContactContext, ContactContextProps} from './ContactContext';
import {ContactUser} from './contactUser';
import {ContactForm} from './contactForm';
import {ITemplateTabControllerTab, TemplateTabs} from '../../factory/template';

export const Contact: React.FC = () => {
  const {loading, contactId} =
    React.useContext<ContactContextProps>(ContactContext);

  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {title: 'Details', element: <ContactForm />},
    {title: 'User', element: <ContactUser />},
  ]);

  const path = React.useMemo(() => `/contactlist/${contactId}`, [contactId]);

  return <TemplateTabs path={path} tabs={tabs} loading={loading} />;
};
