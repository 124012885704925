import React from 'react';
import {CardProps} from '@mui/material';
import {
  Form,
  Formik,
  FormikConfig,
  FormikValues,
  useFormikContext,
} from 'formik';
import {TemplateStepperFormikContext} from './TemplateStepperFormikContext';
import {TemplateStepper} from '../TemplateStepper';
import {PlanningAddStepProps} from '../../../../components';
import _ from 'lodash';
import {FormikHelpers} from 'formik/dist/types';

export const TemplateStepperFormikStepperComponent: React.FC<CardProps> = ({
  children,
  ...other
}) => {
  const {setSubmitting, ...formik} = useFormikContext();
  const {
    activeIndex,
    activeStep,
    maxSteps,
    onNextStep,
    onPrevStep,
    previousDisabled,
    steps,
  } = React.useContext(TemplateStepperFormikContext);

  const childrenArr = React.Children.toArray(children) as Array<
    React.ReactElement<PlanningAddStepProps>
  >;

  const handleNext = async () => {
    if (activeIndex === maxSteps - 1) {
      await formik.submitForm();
    } else {
      const response = await formik.validateForm();
      await formik.submitForm();

      if (response && _.keys(response).length === 0) {
        onNextStep();
      }
    }
  };

  const handlePrev = () => {
    onPrevStep();
  };

  // workaround for race condition on next handler
  React.useEffect(() => {
    setSubmitting(false);
  }, [activeIndex, setSubmitting]);

  return (
    <TemplateStepper
      activeStep={activeIndex}
      prevDisabled={previousDisabled}
      steps={steps.map(({title}) => title)}
      nextTitle={activeStep?.nextTitle ?? 'Next'}
      onNext={handleNext}
      onPrev={handlePrev}
      prevTitle={activeStep?.prevTitle ?? 'Back'}
      {...other}
    >
      {childrenArr[activeIndex]}
    </TemplateStepper>
  );
};

export const TemplateStepperFormikStepper: React.FC<
  Omit<CardProps, 'onSubmit'> &
    Pick<FormikConfig<FormikValues>, 'initialValues' | 'onSubmit'>
> = ({children, initialValues, onSubmit, ...other}) => {
  const {activeIndex, activeStep, maxSteps} = React.useContext(
    TemplateStepperFormikContext
  );

  const handleSubmit = async (
    values: FormikValues,
    helpers: FormikHelpers<any>
  ) => {
    if (activeIndex === maxSteps - 1) {
      await onSubmit(values, helpers);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={activeStep?.validationSchema}
    >
      <Form>
        <TemplateStepperFormikStepperComponent {...other}>
          {children}
        </TemplateStepperFormikStepperComponent>
      </Form>
    </Formik>
  );
};
