import React from 'react';
import L from 'leaflet';
import BaseMarker from './BaseMarker';

interface Props {
  position: [number, number]; // lat , lng
}

export default class PlannedResourceMarker extends React.Component<Props> {
  render() {
    const Icon = L.ExtraMarkers.icon({
      icon: 'fa-user-astronaut',
      markerColor: 'blue',
      shape: 'penta',
      prefix: 'fa',
    });
    const {position} = this.props;

    const plannedResource = {
      title: 'Planned Trip Ghost',
      latitude: position[0],
      longitude: position[1],
    };

    return (
      <BaseMarker
        position={position}
        icon={Icon}
        plannedResource={plannedResource}
      />
    );
  }
}
