import React from 'react';
import {
  PlanningSkillVehicleTypeMinimal,
  PlanningSkillVehicleTypeListResponse,
  ApiWebPlanningSkillPlanningSkillIdVehicleTypesGetRequest,
  Listing,
} from '@onroadvantage/onroadvantage-api';
import {webPlanningSkillVehicleTypeApi} from '../../../api';
import {
  TemplateTableContextProps,
  TLoadList,
  TOnInlineAdd,
  useTemplateTable,
} from '../../../factory/template';
import {useAppNotifications} from '../../../contexts';
import {PlanningSkillContext} from '../PlanningSkillContext';

export type PlanningSkillVehicleTypeListContextProps =
  TemplateTableContextProps<
    PlanningSkillVehicleTypeMinimal,
    PlanningSkillVehicleTypeListResponse
  >;

export const PlanningSkillVehicleTypeListContext =
  React.createContext<PlanningSkillVehicleTypeListContextProps>({
    loading: false,
    list: [],
    currentPage: 1,
    loadList: () => null,
  });

export const usePlanningSkillVehicleTypeListContext = () =>
  React.useContext(PlanningSkillVehicleTypeListContext);

export const PlanningSkillVehicleTypeListContextProvider: React.FC = ({
  children,
}) => {
  const notify = useAppNotifications();
  const {planningSkillId} = React.useContext(PlanningSkillContext);
  // Template Table
  const [
    {
      // States
      currentPage,
      filters,
      hasPermission,
      itemTotal,
      list,
      loading,
      pageSize,
      pageTotal,
      sorting,
    },
    {
      // Getters
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
    },
  ] = useTemplateTable<
    PlanningSkillVehicleTypeMinimal,
    ApiWebPlanningSkillPlanningSkillIdVehicleTypesGetRequest
  >({
    editPermission: 'Edit PlanningSkill',
  });

  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const loadList = React.useCallback<
    TLoadList<PlanningSkillVehicleTypeListResponse>
  >(
    async (options) => {
      setLoading(true);
      try {
        if (planningSkillId) {
          const requestObj = getRequestObj(['name', 'description'], options, {
            planningSkillId,
          });
          const response =
            await webPlanningSkillVehicleTypeApi.apiWebPlanningSkillPlanningSkillIdVehicleTypesGet(
              {...requestObj, planningSkillId}
            );
          return getResponse(response, options);
        }
      } catch (e) {
        notify('error', 'Failed to load vehicle types for planning skill');
      } finally {
        setLoading(false);
      }
    },
    [setLoading, planningSkillId, getRequestObj, getResponse, notify]
  );

  const handleDelete = React.useCallback(
    async (row: PlanningSkillVehicleTypeMinimal) => {
      setSubmitting(true);
      try {
        if (planningSkillId && row.id) {
          const response =
            await webPlanningSkillVehicleTypeApi.apiWebPlanningSkillPlanningSkillIdVehicleTypeVehicleTypeIdDelete(
              {planningSkillId, vehicleTypeId: row.id}
            );
          notify('success', 'Unassigned planning skill from vehicle type');
          return getResponse(response);
        }
      } catch (e) {
        notify('error', 'Failed to unassign planning skill from vehicle type');
      } finally {
        setSubmitting(false);
      }
    },
    [getResponse, notify, planningSkillId]
  );

  const handleInlineAdd = React.useCallback<TOnInlineAdd>(
    async (changes) => {
      setSubmitting(true);
      try {
        if (planningSkillId && changes) {
          const vehicleTypeLists = changes[0]?.name as Listing[] | undefined;
          const vehicleTypeIds = vehicleTypeLists
            ?.filter(({value}) => !!value)
            .map(({value}) => value as number);
          if (vehicleTypeIds && vehicleTypeIds.length > 0) {
            const response =
              await webPlanningSkillVehicleTypeApi.apiWebPlanningSkillPlanningSkillIdVehicleTypesPost(
                {
                  planningSkillId,
                  body: {vehicleTypeIds},
                }
              );
            notify('success', 'Assigned planning skill to vehicle types');
            return getResponse(response);
          }
        }
      } catch (e) {
        notify('error', 'Failed to assign planning skill to vehicle types');
      } finally {
        setSubmitting(false);
      }
    },
    [getResponse, notify, planningSkillId]
  );

  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  const value: PlanningSkillVehicleTypeListContextProps = {
    loadList,
    cleanupList,
    hasPermission,
    loading: submitting || loading,
    list,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onInlineAdd: hasPermission.edit ? handleInlineAdd : undefined,
    onDelete: hasPermission.edit ? handleDelete : undefined,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onRefresh: handleRefresh,
  };

  return (
    <PlanningSkillVehicleTypeListContext.Provider value={value}>
      {children}
    </PlanningSkillVehicleTypeListContext.Provider>
  );
};
