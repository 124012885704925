import {makeStyles, createStyles} from '@mui/styles';
import {Theme} from '@mui/material';
import {tabletSize} from '../../contexts';

const AuthenticationCookiePolicyStyles = (theme: Theme) =>
  createStyles({
    body: {
      padding: '10px 50px 40px 50px',
    },
    closeIcon: {
      textAlign: 'right',
      padding: '10px 10px 0 0',
    },
    container: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      top: 0,
      height: '100vh',
      zIndex: 100,
      [theme.breakpoints.down(tabletSize)]: {
        left: 0,
        width: '100vw',
      },
      [theme.breakpoints.up(tabletSize)]: {
        left: 480,
        width: 'calc(100vw - 480px)',
      },
    },
    content: {
      [theme.breakpoints.down('md')]: {
        left: 0,
        width: '100vw',
        height: '100vh',
      },
      [theme.breakpoints.between('sm', tabletSize)]: {
        left: 0,
        width: '100vw',
        height: '100vh',
      },
      [theme.breakpoints.up(tabletSize)]: {
        maxWidth: 390,
      },
    },
    header: {
      fontSize: 36,
      fontWeight: 'bold',
      textAlign: 'center',
    },
  });

export const useAuthenticationCookiePolicyStyles = makeStyles(
  AuthenticationCookiePolicyStyles,
  {name: 'AuthenticationCookiePolicyStyles'}
);
