import {decorate, observable, action, computed, toJS} from 'mobx';

class CriticalEventsActivities {
  constructor() {
    this.criticalEvents = {};
  }

  get criticalEventActivities() {
    return toJS(this.criticalEvents);
  }

  addCriticalEventActivities = (events, id) => {
    if (events && id) {
      this.criticalEvents.events = events;
      this.criticalEvents.id = id;
    }
  };

  clearCriticalEvents = () => {
    this.criticalEvents = {};
  };
}

export default decorate(CriticalEventsActivities, {
  criticalEvents: observable,
  criticalEventActivities: computed,
  addCriticalEventActivities: action,
  clearCriticalEvents: action,
});
