import React from 'react';
import {useHistory} from 'react-router-dom';
import {
  DriverGroup,
  DriverGroupListResponse,
  ApiWebPlanningSkillPlanningSkillIdCustomersGetRequest,
} from '@onroadvantage/onroadvantage-api';
import {driverGroupApi} from '../../../api';
import {
  TemplateTableContextProps,
  TLoadList,
  useTemplateTable,
} from '../../../factory/template';
import {useAppNotifications} from '../../../contexts';

export type DriverGroupListContextProps = TemplateTableContextProps<
  DriverGroup,
  DriverGroupListResponse
>;

export const DriverGroupListContext =
  React.createContext<DriverGroupListContextProps>({
    loading: false,
    list: [],
    currentPage: 1,
    loadList: () => null,
  });

export const useDriverGroupListContext = () =>
  React.useContext(DriverGroupListContext);

export const DriverGroupListContextProvider: React.FC = ({children}) => {
  const history = useHistory();
  const notify = useAppNotifications();
  // Template Table
  const [
    {
      // States
      currentPage,
      filters,
      hasPermission,
      itemTotal,
      list,
      loading,
      pageSize,
      pageTotal,
      sorting,
    },
    {
      // Getters
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
    },
  ] = useTemplateTable<
    DriverGroup,
    ApiWebPlanningSkillPlanningSkillIdCustomersGetRequest
  >({
    editPermission: 'Edit DriverGroup',
    addPermission: 'Add DriverGroup',
  });

  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const loadList = React.useCallback<TLoadList<DriverGroupListResponse>>(
    async (options) => {
      setLoading(true);
      try {
        const requestObj = getRequestObj(['name', 'description'], options);
        const response = await driverGroupApi.apiDriverGroupGet(requestObj);
        return getResponse(response, options);
      } catch (e) {
        notify('error', 'Failed to load driver groups');
      } finally {
        setLoading(false);
      }
    },
    [setLoading, getRequestObj, getResponse, notify]
  );

  const handleDelete = React.useCallback(
    async (row: DriverGroup) => {
      setSubmitting(true);
      try {
        if (row.id) {
          await driverGroupApi.apiDriverGroupDriverGroupIdDelete({
            driverGroupId: row.id,
          });
          notify('success', 'Unassigned driver from driver group');
          await loadList();
        }
      } catch (e) {
        notify('error', 'Failed to unassign driver from driver group');
      } finally {
        setSubmitting(false);
      }
    },
    [loadList, notify]
  );

  const handleAdd = React.useCallback(() => {
    history.push('/drivergrouplist/add');
  }, [history]);

  const handleNavigate = React.useCallback(
    (row: DriverGroup) => {
      if (row.id) {
        history.push(`/drivergrouplist/${row.id}`);
      }
    },
    [history]
  );

  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  const value: DriverGroupListContextProps = {
    loadList,
    cleanupList,
    hasPermission,
    loading: submitting || loading,
    list,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onAdd: hasPermission.add ? handleAdd : undefined,
    onNavigate: handleNavigate,
    onDelete: hasPermission.edit ? handleDelete : undefined,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onRefresh: handleRefresh,
  };

  return (
    <DriverGroupListContext.Provider value={value}>
      {children}
    </DriverGroupListContext.Provider>
  );
};
