import React from 'react';
import {useHistory} from 'react-router-dom';
import {
  ApiNotificationConfigGetRequest,
  Listing,
  NotificationConfig,
  NotificationConfigList,
  EscalationNotificationConfig,
  EscalationNotificationConfigPost,
} from '@onroadvantage/onroadvantage-api';
import {useAppNotifications} from '../../../contexts';
import {escalationNotificationConfigApi} from '../../../api';
import {
  TemplateTableContextProps,
  TOnInlineAdd,
  TOnInlineEdit,
  useTemplateTable,
} from '../../../factory/template';
import {NotificationConfigContext} from '../NotificationConfigContext';

export interface NotificationConfigOperationalEventContextProps
  extends Omit<
    TemplateTableContextProps<
      EscalationNotificationConfig,
      NotificationConfigList
    >,
    'loadList'
  > {
  loadNotificationConfigEventEscalation: () => Promise<void>;
  setNotificationConfigEventEscalationId: (value: number | undefined) => void;
  submitting?: boolean;
  loadingSingleItem: boolean;
  notificationConfigEventEscalation?: EscalationNotificationConfig;
  notificationConfigEventEscalationId?: number;
}

export const NotificationConfigOperationalEscalationContext =
  React.createContext<NotificationConfigOperationalEventContextProps>({
    // Template Table Defaults
    loading: false,
    list: [],
    currentPage: 1,
    // NotificationConfig
    loadNotificationConfigEventEscalation: async () => {},
    setNotificationConfigEventEscalationId: () => null,
    submitting: false,
    loadingSingleItem: false,
  });

interface NotificationConfigOperationalEventEscalationContextProviderProps {
  notificationConfigId?: number;
}

export const NotificationConfigOperationalEventEscalationContextProvider: React.FC<
  NotificationConfigOperationalEventEscalationContextProviderProps
> = ({children}) => {
  const history = useHistory();
  const notify = useAppNotifications();
  const {
    notificationConfig,
    notificationConfigId,
    loadNotificationConfig,
    loadingSingleItem: loadingNotificationConfig,
  } = React.useContext(NotificationConfigContext);
  // Template Table
  const [
    {
      // States
      currentPage,
      itemTotal,
      hasPermission,
      pageSize,
      pageTotal,
      loadingSingleItem,
    },
    {
      // Handlers
      handlePageSizeCountsChange,
      // Setters
      setLoadingSingleItem,
    },
  ] = useTemplateTable<NotificationConfig, ApiNotificationConfigGetRequest>({
    editPermission: 'Edit NotificationConfig',
    addPermission: 'Add NotificationConfig',
    deletePermission: 'Delete NotificationConfig',
    downloadPermission: 'NotificationConfig ListDownload',
    viewPermission: 'NotificationConfig List',
  });
  const [localLoading, setLocalLoading] = React.useState<boolean>(false);

  const handleDelete = React.useCallback(
    async (row: EscalationNotificationConfig) => {
      setLocalLoading(true);
      try {
        if (row.id) {
          await escalationNotificationConfigApi.apiEscalationNotificationConfigEscalationNotificationConfigIdDelete(
            {
              escalationNotificationConfigId: row.id,
            }
          );
          notify(
            'success',
            'Notification Config unlink to Escalation Triggers updated.'
          );
          await loadNotificationConfig();
        }
      } catch (e) {
        notify(
          'error',
          e.message ??
            'Failed to unlink Escalation Triggers to Notification Config'
        );
      } finally {
        setLocalLoading(false);
      }
    },
    [loadNotificationConfig, notify]
  );

  const handleInlineAdd = React.useCallback<TOnInlineAdd>(
    async (changes) => {
      setLocalLoading(true);
      try {
        const values = changes[0] as EscalationNotificationConfig;

        const eventEscalationType = values?.operationalEventEscalationConfig as
          | Listing
          | undefined;
        if (notificationConfigId && eventEscalationType?.value) {
          const requestObj: EscalationNotificationConfigPost = {
            notificationConfigId,
            operationalEventEscalationConfigId: undefined,
            notifyContractContactGroups: !!values.notifyContractContactGroups,
            notifyCustomerContactGroups: !!values.notifyCustomerContactGroups,
            notifyDriver: !!values.notifyDriver,
            notifyNextStop: !!values.notifyNextStop,
            notifyNodeContactGroups: !!values.notifyNodeContactGroups,
            notifyVehicleGroupContactGroups:
              !!values.notifyVehicleGroupContactGroups,
            notifyOffloadingStops: !!values.notifyOffloadingStops,
            notifyVehicle: !!values.notifyVehicle,
            notifyWeb: !!values.notifyWeb,
          };

          if (eventEscalationType?.metaData === 'OperationalEvent') {
            requestObj.operationalEventEscalationConfigId =
              eventEscalationType.value;
          }
          await escalationNotificationConfigApi.apiEscalationNotificationConfigPost(
            {
              body: requestObj,
            }
          );
          notify(
            'success',
            'Notification Config link to Operational Event Triggers updated.'
          );
          await loadNotificationConfig();
        }
      } catch (e) {
        notify(
          'error',
          e.message ??
            'Failed to link Operational Event Trigger to Notification Config.'
        );
      } finally {
        setLocalLoading(false);
      }
    },
    [loadNotificationConfig, notificationConfigId, notify]
  );

  const handleInlineEdit = React.useCallback<TOnInlineEdit>(
    async (changes) => {
      setLocalLoading(true);
      try {
        for (const item of changes) {
          const escalationNotificationConfigId = parseInt(item.id);
          const values =
            item.newValues as Partial<EscalationNotificationConfig>;

          const requestObj: {
            [key: string]: unknown;
          } = {};
          Object.keys(values).forEach((key) => {
            requestObj[key] =
              values[key as keyof Partial<EscalationNotificationConfig>] ??
              undefined;
          });

          if (escalationNotificationConfigId) {
            if (Object.keys(requestObj).length > 0) {
              await escalationNotificationConfigApi.apiEscalationNotificationConfigEscalationNotificationConfigIdPatch(
                {
                  escalationNotificationConfigId,
                  body: requestObj,
                }
              );
            }
            notify(
              'success',
              'successfully edited operational Escalation Trigger.'
            );
          }
        }
        await loadNotificationConfig();
      } catch (e) {
        notify(
          'error',
          e.message ?? 'Failed to edit operational escalation trigger '
        );
      } finally {
        setLocalLoading(false);
      }
    },
    [loadNotificationConfig, notify]
  );

  const handleNavigate = React.useCallback(
    (row) =>
      history.push(
        `/notificationconfiglist/${notificationConfigId}/escalation/${row.id}`
      ),
    [history, notificationConfigId]
  );
  const handleRefresh = React.useCallback(
    async () => await loadNotificationConfig(),
    [loadNotificationConfig]
  );

  // Forms
  const [
    notificationConfigEventEscalation,
    setNotificationConfigEventEscalation,
  ] = React.useState<EscalationNotificationConfig | undefined>();
  const [
    notificationConfigEventEscalationId,
    setNotificationConfigEventEscalationId,
  ] = React.useState<number | undefined>();

  // verify endpoint

  const loadNotificationConfigEventEscalation = React.useCallback(async () => {
    setLoadingSingleItem(true);
    try {
      if (notificationConfigEventEscalationId) {
        const response =
          await escalationNotificationConfigApi.apiEscalationNotificationConfigEscalationNotificationConfigIdGet(
            {
              escalationNotificationConfigId:
                notificationConfigEventEscalationId,
            }
          );
        setNotificationConfigEventEscalation(response);
      } else {
        setNotificationConfigEventEscalation(undefined);
      }
    } catch (e) {
      notify(
        'error',
        e.message ??
          'Failed to load notification config operational escalation trigger'
      );
    } finally {
      setLoadingSingleItem(false);
    }
  }, [setLoadingSingleItem, notificationConfigEventEscalationId, notify]);

  const filterEventEscalationNotificationConfigs = React.useMemo(() => {
    return (
      notificationConfig?.escalationNotificationConfigs?.filter(
        (config) => config.operationalEventEscalationConfig
      ) ?? []
    );
  }, [notificationConfig?.escalationNotificationConfigs]);

  const value: NotificationConfigOperationalEventContextProps = {
    // Template Table
    list: filterEventEscalationNotificationConfigs,
    hasPermission,
    loading: loadingNotificationConfig || localLoading,
    loadingSingleItem,
    currentPage,
    itemTotal,
    pageSize,
    pageTotal,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onInlineAdd: hasPermission.edit ? handleInlineAdd : undefined,
    //onInlineEdit: undefined,
    onNavigate: handleNavigate,
    onDelete: hasPermission.delete ? handleDelete : undefined,
    onRefresh: handleRefresh,
    onInlineEdit: handleInlineEdit,
    // Forms
    loadNotificationConfigEventEscalation,
    setNotificationConfigEventEscalationId,
    notificationConfigEventEscalation,
    notificationConfigEventEscalationId,
  };

  React.useEffect(() => {
    loadNotificationConfigEventEscalation();
    return () => setNotificationConfigEventEscalation(undefined);
  }, [loadNotificationConfigEventEscalation]);

  return (
    <NotificationConfigOperationalEscalationContext.Provider value={value}>
      {children}
    </NotificationConfigOperationalEscalationContext.Provider>
  );
};
