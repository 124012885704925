import React from 'react';
import {ISiteDetailsForm, SiteContext} from '../SiteContext';
import Map from '../../map/map';
import {MapProps} from 'react-leaflet';
import {Container} from '@mui/material';
import {useFormikContext} from 'formik';
import {useSiteDetailsMapStyles} from './SiteDetailsMap.style';

interface IAddress {
  lat: string;
  lng: string;
  label: string;
}

export const SiteDetailsMap: React.FC = () => {
  const classes = useSiteDetailsMapStyles();
  const {site, siteEditCallback, siteAddCallback} =
    React.useContext(SiteContext);
  const {values, getFieldMeta, setFieldValue} =
    useFormikContext<ISiteDetailsForm>();
  const [mapOptions, setMapOptions] = React.useState<Partial<MapProps>>({
    zoom: site && site.latitude ? 20 : 5,
  });
  const [addressState, setAddressState] = React.useState({
    address: getFieldMeta('address').value as string,
    latitude: getFieldMeta('latitude').value as string,
    longitude: getFieldMeta('longitude').value as string,
  });

  const handleAddressClick = React.useCallback(
    (address: IAddress) => {
      setFieldValue('address', address.label);
      setFieldValue('latitude', address.lat);
      setFieldValue('longitude', address.lng);
      setAddressState({
        address: address.label,
        latitude: address.lat,
        longitude: address.lng,
      });
    },
    [setFieldValue]
  );
  React.useEffect(() => {
    if (addressState.longitude && addressState.latitude) {
      setMapOptions((prevOptions) => ({
        ...prevOptions,
        center: [
          parseInt(addressState.latitude),
          parseInt(addressState.longitude),
        ],
        latitude: addressState.latitude,
        longitude: addressState.longitude,
      }));
    } else {
      setMapOptions((prevOptions) => ({
        ...prevOptions,
        center: [-28.48322, 24.676997],
        latitude: -28.48322,
        longitude: 24.676997,
      }));
    }
  }, [addressState]);

  return (
    <Container className={classes.container} disableGutters>
      <Map
        siteEditCallback={siteEditCallback}
        siteAddCallback={siteAddCallback}
        siteEdit={{...site, ...values}}
        vehicle={{}}
        site={values}
        trip={{}}
        actualTrip={{}}
        onAddressClick={handleAddressClick}
        route={{}}
        telematicsEvents={undefined}
        {...mapOptions}
      />
    </Container>
  );
};
