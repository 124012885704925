import React from 'react';
import {Listing, Order} from '@onroadvantage/onroadvantage-api';
import {Button, Stack} from '@mui/material';
import {TripSetupFormStopOrder, useTripSetupContext} from '../TripSetupContext';
import {Add} from '@mui/icons-material';
import {TripSetupAutocompleteOrders} from '../components';
import {useTripSetupStopsStepContext} from './TripSetupStopsStepContext';

const convertOrderToTripSetupFormStopOrder = (
  order: Order
): TripSetupFormStopOrder => ({
  orderNumber: order.orderNumber,
  deliveryNoteNumber: order.deliveryNoteNumber,
  offloadPointNode: {
    value: order.offloadPointId,
    label: order.offloadPoint.name,
  },
  upliftPointNode: {
    value: order.upliftPointId,
    label: order.upliftPoint.name,
  },
  product: {label: order.product},
  quantity: order.quantity,
});

export const TripSetupStopsStepExistingOrder = () => {
  const {setEditing, setStops, setOrders} = useTripSetupContext();
  const {setViewState} = useTripSetupStopsStepContext();
  const [values, setValues] = React.useState<Listing[]>([]);

  const handleAddOrders = React.useCallback(() => {
    if (values.length > 0) {
      const orders: TripSetupFormStopOrder[] = [];

      values.forEach(({metaData}) => {
        if (metaData && JSON.parse(metaData)) {
          const parsedOrder = JSON.parse(metaData);
          if (parsedOrder) {
            const order = convertOrderToTripSetupFormStopOrder(parsedOrder);
            orders.push(order);
          }
        }
      });

      setOrders((currentOrders) => [...currentOrders, ...orders]);
      setStops((currentStops) => [
        ...currentStops,
        ...orders.map((order, index) => ({
          node: {},
          sequence: currentStops.length + index + 1,
          orders: [order],
        })),
      ]);
      setValues([]);
      setEditing(false);
      setViewState('sortStops');
    }
  }, [setEditing, setOrders, setStops, setViewState, values]);

  const handleChangeValues = React.useCallback(
    (value: Listing[] | null) => {
      setValues(value ?? []);
      if (value != null && value.length > 0) {
        setEditing(true);
      } else {
        setEditing(false);
      }
    },
    [setEditing]
  );

  return (
    <Stack spacing={2.5}>
      <TripSetupAutocompleteOrders
        value={values}
        onChange={handleChangeValues}
      />
      <Button
        sx={{alignSelf: 'flex-start'}}
        variant="contained"
        color="primary"
        onClick={handleAddOrders}
        disabled={!values || values.length === 0}
        fullWidth={false}
        startIcon={<Add />}
      >
        Add {values && values.length > 1 ? 'Orders' : 'Order'}
      </Button>
    </Stack>
  );
};
