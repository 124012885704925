import {Theme} from '@mui/material/styles';

import {makeStyles, createStyles} from '@mui/styles';

const ErrorBoundaryStyles = (theme: Theme) =>
  createStyles({
    errorMessage: {
      paddingBottom: theme.spacing(0),
      paddingTop: theme.spacing(0),
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
      borderRadius: 5,
      backgroundColor: 'rgba(248,225,139,0.52)',
      color: '#000000',
    },
    root: {
      display: 'grid',
      placeContent: 'center',
      padding: theme.spacing(5),
    },
  });

export const useErrorBoundaryStyles = makeStyles(ErrorBoundaryStyles, {
  name: 'ErrorBoundaryStyles',
});
