import React from 'react';
import {AdminTelematicsConfigContext} from './AdminTelematicsConfigContext';
import {
  AdminTelematicsConfig,
  AdminTelematicsConfig as AdminTelematicsConfigType,
} from '@onroadvantage/onroadvantage-api';
import {TemplateTable, TemplateTableConfig} from '../../../factory/template';
import {useHistory} from 'react-router-dom';

interface AdminTelematicsConfigListProps {
  id?: string;
}

export const AdminTelematicsConfigList: React.FC<
  AdminTelematicsConfigListProps
> = () => {
  const {
    loading,
    loadList,
    list,
    listFilters,
    listItemTotal,
    listPage,
    listPageSize,
    listPageTotal,
    listSorting,
    onListDelete,
    onListFilterChange,
    onListPageChange,
    onListPageSizeChange,
    onListSortingChange,
  } = React.useContext(AdminTelematicsConfigContext);

  const history = useHistory();

  const [config] = React.useState<
    TemplateTableConfig<AdminTelematicsConfigType>
  >({
    columns: [
      {name: 'providerName', label: 'Provider Name', type: 'string'},
      {
        name: 'tile38Enabled',
        label: 'Tile38 Enabled',
        type: 'bool',
        hidden: true,
      },
      {
        name: 'lastTile38TelematicsEventId',
        label: 'Last Tile38 Event ID',
        type: 'number',
        hidden: true,
      },

      {
        name: 'lastCriticalEventToken',
        label: 'Last Critical Event Token',
        type: 'string',
        hidden: true,
      },
      {
        name: 'lastEventDatetime',
        label: 'Last Event Date',
        type: 'datetime',
        hidden: true,
      },
      {
        name: 'lastExternalEventId',
        label: 'Last External Event ID',
        type: 'number',
        hidden: true,
      },
      {
        name: 'mixCriticalEventsUseTimeSlice',
        label: 'MiX Critical Event Use Time Slice',
        type: 'bool',
        hidden: true,
      },

      {
        name: 'lastExternalGpsId',
        label: 'Last External GPS ID',
        type: 'number',
        hidden: true,
      },
      {
        name: 'lastGpsDatetime',
        label: 'Last GPS Date',
        type: 'datetime',
        hidden: true,
      },
      {
        name: 'lastTelematicsEventToken',
        label: 'Last Telematics Event Token',
        type: 'string',
        hidden: true,
      },
      {
        name: 'mixTelematicsEventsUseTimeSlice',
        label: 'MiX Telematics Event Use Time Slice',
        type: 'bool',
        hidden: true,
      },

      {
        name: 'mixGroupsEnabled',
        label: 'MiX Groups Enabled',
        type: 'bool',
        hidden: true,
      },
      {
        name: 'mixOrganisationEnabled',
        label: 'MiX Organisation Enabled',
        type: 'bool',
        hidden: true,
      },
      {
        name: 'mixIntegrateLocationSyncEnabled',
        label: 'MiX Location Sync Enabled',
        type: 'bool',
        hidden: true,
      },
      {
        name: 'mixOrganisationGroupId',
        label: 'MiX Organisation Group ID',
        type: 'number',
        hidden: true,
      },
      {
        name: 'mixOrganisationGroupName',
        label: 'MiX Organisation Group Name',
        type: 'string',
      },
      {name: 'profileId', label: 'Profile ID', type: 'number', hidden: true},
      // TODO
      // {name: 'isDeleted', label: 'Is Deleted', type: 'boolean'},
    ],
    enableFilter: true,
    enableSort: true,
    deleteMessage: (row) => ({
      title: 'Telematics Config',
      items: {Name: row.providerName, Id: row.id},
    }),
  });

  const handleAdd = React.useCallback(() => {
    history.push('/telematicsconfiglist/add');
  }, [history]);

  const handleNavigate = React.useCallback(
    (row: AdminTelematicsConfig) => {
      history.push(`/telematicsconfiglist/${row.id}`);
    },
    [history]
  );

  React.useEffect(() => {
    loadList();
  }, [loadList]);

  return (
    <TemplateTable
      config={config}
      currentPage={listPage}
      filters={listFilters}
      itemTotal={listItemTotal}
      list={list}
      loading={loading}
      onAdd={handleAdd}
      onDelete={onListDelete}
      onCurrentPageChange={onListPageChange}
      onFiltersChange={onListFilterChange}
      onNavigate={handleNavigate}
      onPageSizeCountsChange={onListPageSizeChange}
      onRefresh={loadList}
      onSortingChange={onListSortingChange}
      pageSize={listPageSize}
      pageTotal={listPageTotal}
      sorting={listSorting}
    />
  );
};
