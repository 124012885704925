import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

const VehicleTypeCompartmentFormStyles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      flex: 1,
    },
    form: {
      minWidth: 200,
      maxWidth: 260,
      marginRight: theme.spacing(2),
    },
    total: {marginTop: 3, paddingBottom: 8},
    [theme.breakpoints.down('lg')]: {
      root: {flexDirection: 'column'},
      form: {
        minWidth: '100%',
        width: '100%',
        marginBottom: theme.spacing(2),
        marginRight: 0,
      },
    },
  });

export const useVehicleTypeCompartmentFormStyles = makeStyles(
  VehicleTypeCompartmentFormStyles,
  {
    name: 'VehicleTypeCompartmentFormStyles',
  }
);
