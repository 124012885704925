import React from 'react';
import {Vehicle} from '@onroadvantage/onroadvantage-api';
import {VehicleGroupContext} from '../VehicleGroupContext';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../../factory/template';
import {VehicleGroupVehiclesContext} from './VehicleGroupVehiclesContext';

export const VehicleGroupVehicles: React.FC = () => {
  const {vehicleGroup} = React.useContext(VehicleGroupContext);
  const {loadList, cleanupList} = React.useContext(VehicleGroupVehiclesContext);

  const config: TemplateTableConfig<Vehicle> = React.useMemo(
    () => ({
      columns: [
        {
          name: 'registrationNumber',
          label: 'Vehicle Registration',
          type: 'string',
          model: 'Vehicle',
          enableEditing: true,
          enableAutocomplete: true,
          enableMulti: true,
        },
        {
          name: 'description',
          label: 'Description',
          type: 'string',
          enableEditing: false,
        },
        {
          name: 'fleetNumber',
          label: 'Fleet Number',
          type: 'string',
          enableEditing: false,
        },
        {
          name: 'contract.code',
          label: 'Contract Code',
          type: 'string',
          enableEditing: false,
          getValue: ({contract}) => contract?.code,
        },
        {
          name: 'vehicleType.name',
          label: 'Vehicle Type',
          type: 'string',
          enableEditing: false,
          getValue: ({type}) => type?.name,
        },
      ],
      enableFilter: true,
      enableSort: true,
      disableToolbar: true,
      mapMessageTitle: () => 'Are you sure you want to unassign this vehicle?',
      deleteMessage: (row) => ({
        title: 'Vehicle Group Vehicle',
        items: {
          VehicleGroup: vehicleGroup?.name,
          RegistrationNumber: row.registrationNumber,
          fleetNumber: row.fleetNumber,
        },
      }),
      identifier: 'VEHICLE_GROUP_VEHICLES',
    }),
    [vehicleGroup?.name]
  );

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [loadList, cleanupList]);

  return (
    <TemplateTableWrapper
      config={config}
      context={VehicleGroupVehiclesContext}
    />
  );
};
