import React from 'react';
import _ from 'lodash';
import {
  ITemplateControllerRoute,
  TemplateController,
} from '../../factory/template';
import {
  TransporterContext,
  TransporterContextProvider,
} from './TransporterContext';
import {TransporterList} from './TransporterList';
import {TransporterIdHandler} from './TransporterIdHandler';
import {TransporterDetails} from './TransporterDetails';
import {Transporter} from './Transporter';

export const TransporterController: React.FC = () => {
  return (
    <TransporterContextProvider>
      <TransporterControllerComponent />
    </TransporterContextProvider>
  );
};

const TransporterControllerComponent: React.FC = () => {
  const {transporter, loading} = React.useContext(TransporterContext);
  const routes: ITemplateControllerRoute[] = React.useMemo(
    () => [
      {title: 'Transporter List', component: TransporterList},
      {
        path: '/add',
        title: 'New Transporter',
        name: 'Add Transporter',
        type: 'Add',
        component: () => <TransporterDetails isAdd />,
      },
      {
        path: '/:transporterId',
        title: (params) =>
          loading
            ? 'Transporter Loading...'
            : transporter?.name ??
              `transporter ${_.get(params, 'transporterId', 'Single')}`,
        component: Transporter,
        IdHandler: TransporterIdHandler,
      },
    ],
    [loading, transporter?.name]
  );
  return <TemplateController rootPath="/transporterlist" routes={routes} />;
};
