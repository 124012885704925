import React from 'react';
import * as Yup from 'yup';
import {
  NUMBER_ERROR_MESSAGE,
  requiredListingSchema,
  TemplateCard,
  TemplateForm,
} from '../../../factory/template';
import {
  FormikAutocompleteWithListing,
  FormikSwitch,
  FormikTextField,
} from '../../formik';
import {useContractOperationalEventTypeConfigContext} from './ContractOperationalEventTypeConfigContext';
import {Listing} from '@onroadvantage/onroadvantage-api';

export interface ContractOperationalEventTypeConfigDetailsFormSchema {
  operationalEventType: Listing;
  contract: Listing;
  allowEventMuting: boolean;
  allowEventRepetition: boolean;
  autoClose: boolean;
  enabled: boolean;
  arrivalDelayThreshold: number | undefined | null;
  eventPostThreshold: number | undefined | null;
  eventPreThreshold: number | undefined | null;
  varianceAllowed: number | undefined | null;
}

const contractOperationalEventTypeConfigDetailsFormSchema: Yup.SchemaOf<ContractOperationalEventTypeConfigDetailsFormSchema> =
  Yup.object({
    operationalEventType: requiredListingSchema,
    contract: requiredListingSchema,
    allowEventMuting: Yup.boolean().required('Required'),
    allowEventRepetition: Yup.boolean().required('Required'),
    autoClose: Yup.boolean().required('Required'),
    enabled: Yup.boolean().required('Required'),
    arrivalDelayThreshold: Yup.number()
      .typeError(NUMBER_ERROR_MESSAGE)
      .nullable(true),
    eventPostThreshold: Yup.number()
      .typeError(NUMBER_ERROR_MESSAGE)
      .nullable(true),
    eventPreThreshold: Yup.number()
      .typeError(NUMBER_ERROR_MESSAGE)
      .nullable(true),
    varianceAllowed: Yup.number()
      .typeError(NUMBER_ERROR_MESSAGE)
      .nullable(true),
  });

export const ContractOperationalEventTypeConfigDetailsForm: React.FC<{
  isAdd?: boolean;
}> = ({isAdd}) => {
  const {
    loading,
    submitting,
    contractOperationalEventTypeConfig,
    onDetailsSubmit,
  } = useContractOperationalEventTypeConfigContext();
  const initialValues =
    React.useMemo<ContractOperationalEventTypeConfigDetailsFormSchema>(() => {
      if (isAdd || contractOperationalEventTypeConfig == null) {
        return {
          operationalEventType: {},
          contract: {},
          allowEventMuting: false,
          allowEventRepetition: false,
          autoClose: false,
          enabled: false,
          arrivalDelayThreshold: undefined,
          eventPostThreshold: undefined,
          eventPreThreshold: undefined,
          varianceAllowed: undefined,
        };
      }

      return {
        operationalEventType: {
          value: contractOperationalEventTypeConfig.operationalEventTypeId,
          label: contractOperationalEventTypeConfig.operationalEventType?.name,
        },
        contract: {
          value: contractOperationalEventTypeConfig.contractId,
          label: contractOperationalEventTypeConfig.contract?.name,
        },
        allowEventMuting: contractOperationalEventTypeConfig.allowEventMuting,
        allowEventRepetition:
          contractOperationalEventTypeConfig.allowEventRepetition,
        autoClose: contractOperationalEventTypeConfig.autoClose,
        enabled: contractOperationalEventTypeConfig.enabled,
        arrivalDelayThreshold:
          contractOperationalEventTypeConfig.arrivalDelayThreshold,
        eventPostThreshold:
          contractOperationalEventTypeConfig.eventPostThreshold,
        eventPreThreshold: contractOperationalEventTypeConfig.eventPreThreshold,
        varianceAllowed: contractOperationalEventTypeConfig.varianceAllowed,
      };
    }, [contractOperationalEventTypeConfig, isAdd]);

  return (
    <TemplateCard
      title={`Contract Operational Event Type Config Details ${
        isAdd ? 'Add' : 'Edit'
      }`}
      loading={loading}
    >
      <TemplateForm<ContractOperationalEventTypeConfigDetailsFormSchema>
        initialValues={initialValues}
        onSubmit={onDetailsSubmit}
        permission={{
          name: `${isAdd ? 'Add' : 'Edit'} ContractOperationalEventTypeConfig`,
          type: isAdd ? 'Add' : 'Edit',
        }}
        submitting={submitting}
        validationSchema={contractOperationalEventTypeConfigDetailsFormSchema}
      >
        <FormikAutocompleteWithListing
          name="operationalEventType"
          model="OperationalEventType"
          label="Operational Event Type"
        />
        <FormikAutocompleteWithListing
          name="contract"
          model="Contract"
          label="Contract"
        />
        <FormikTextField
          name="arrivalDelayThreshold"
          label="Arrival Delay Threshold"
          fullWidth
        />
        <FormikTextField
          name="eventPreThreshold"
          label="Event Pre Threshold"
          fullWidth
        />
        <FormikTextField
          name="eventPostThreshold"
          label="Event Post Threshold"
          fullWidth
        />
        <FormikTextField
          name="varianceAllowed"
          label="Variance Allowed"
          fullWidth
        />
        <FormikSwitch label="Allow Event Muting" name="allowEventMuting" />
        <FormikSwitch
          label="Allow Event Repetition"
          name="allowEventRepetition"
        />
        <FormikSwitch label="Auto Close" name="autoClose" />
        <FormikSwitch label="Enabled" name="enabled" />
      </TemplateForm>
    </TemplateCard>
  );
};
