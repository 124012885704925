import React from 'react';
import {useHistory} from 'react-router-dom';
import {FormikHelpers, FormikProps} from 'formik/dist/types';
import {TemplateTableContextProps} from '../../factory/template';
import {useTemplateTable, TLoadList} from '../../factory/template';
import {
  DriverDocument,
  DriverDocumentList,
  ApiDriverDocumentGetRequest,
  DriverDocumentPost,
  Listing,
} from '@onroadvantage/onroadvantage-api';
import {driverDocumentApi} from '../../api';
import moment, {Moment} from 'moment';
import {useAppNotifications} from '../../contexts';

export interface IDriverDocumentDetails {
  actionByDate?: Moment | string | Date | null;
  active?: boolean;
  contract?: Listing;
  description?: string;
  // documentId: number;
  driverDocumentType: Listing;
  uploadId?: string;
}

export interface DriverDocumentContextProps
  extends TemplateTableContextProps<DriverDocument, DriverDocumentList> {
  onDetailsFormSubmit: (
    values: IDriverDocumentDetails,
    formikHelpers: FormikHelpers<IDriverDocumentDetails>
  ) => void;
  setDriverDocumentId: (value: number | undefined) => void;
  submitting: boolean;
  driverDocument?: DriverDocument;
  driverDocumentId?: number;
  detailsRef?: React.Ref<FormikProps<IDriverDocumentDetails>>;
}

export const DriverDocumentContext =
  React.createContext<DriverDocumentContextProps>({
    // Template Table Defaults
    loading: false,
    list: [],
    currentPage: 1,
    // DriverDocument
    loadList: async () => {},
    onDetailsFormSubmit: () => null,
    setDriverDocumentId: () => null,
    submitting: false,
  });

interface DriverDocumentContextProviderProps {
  driverDocumentId?: number;
}

export const DriverDocumentContextProvider: React.FC<
  DriverDocumentContextProviderProps
> = ({children}) => {
  const notify = useAppNotifications();
  const history = useHistory();
  // Template Table
  const [
    {
      // States
      currentPage,
      filters,
      hasPermission,
      itemTotal,
      list,
      loading,
      pageSize,
      pageTotal,
      loadingSingleItem,
      sorting,
    },
    {
      // Getters
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
      setLoadingSingleItem,
    },
  ] = useTemplateTable<DriverDocument, ApiDriverDocumentGetRequest>({
    editPermission: 'Edit DriverDocument',
    addPermission: 'Add DriverDocument',
    deletePermission: 'Delete DriverDocument',
    downloadPermission: 'DriverDocument ListDownload',
    viewPermission: 'DriverDocument List',
  });

  const loadList = React.useCallback<TLoadList<DriverDocumentList>>(
    async (options) => {
      setLoading(true);
      try {
        getResponse(
          await driverDocumentApi.apiDriverDocumentGet(
            getRequestObj(['name', 'description'], options)
          ),
          options
        );
      } catch (e) {
        notify('error', e.message ?? 'Failed to load driver document list');
      } finally {
        setLoading(false);
      }
    },
    [setLoading, getResponse, getRequestObj, notify]
  );

  const handleDelete = React.useCallback(
    async (row: DriverDocument) => {
      setLoading(true);
      try {
        if (row.id) {
          await driverDocumentApi.apiDriverDocumentDriverDocumentIdDelete({
            driverDocumentId: row.id,
          });
          await loadList();
          notify('success', 'Driver Document Deleted');
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to delete document');
      } finally {
        setLoading(false);
      }
    },
    [loadList, notify, setLoading]
  );
  const handleAdd = React.useCallback(
    () => history.push('/driverdocumentlist/add'),
    [history]
  );
  const handleNavigate = React.useCallback(
    (row) => history.push(`/driverdocumentlist/${row.id}`),
    [history]
  );
  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  // Forms
  const [driverDocument, setDriverDocument] = React.useState<
    DriverDocument | undefined
  >();
  const [driverDocumentId, setDriverDocumentId] = React.useState<
    number | undefined
  >();
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const detailsRef = React.useRef<FormikProps<IDriverDocumentDetails>>(null);

  const loadDriverDocument = React.useCallback(async () => {
    setLoadingSingleItem(true);
    try {
      if (driverDocumentId) {
        setDriverDocument(
          await driverDocumentApi.apiDriverDocumentDriverDocumentIdGet({
            driverDocumentId,
          })
        );
      } else {
        setDriverDocument(undefined);
      }
    } catch (e) {
      notify('error', e.message ?? 'Failed to load driver document');
    } finally {
      setLoadingSingleItem(false);
    }
  }, [driverDocumentId, notify, setLoadingSingleItem]);

  const handleDetailsFormSubmit = React.useCallback(
    async (
      values: IDriverDocumentDetails,
      formikHelpers: FormikHelpers<IDriverDocumentDetails>
    ) => {
      setSubmitting(true);
      try {
        const {contract, driverDocumentType, actionByDate, ...otherValues} =
          values;
        if (driverDocumentType.value) {
          const newValues: DriverDocumentPost = {
            ...otherValues,
            actionByDate: moment(actionByDate).toDate(),
            contractId: contract?.value,
            driverDocumentTypeId: driverDocumentType.value,
          };

          if (driverDocument && driverDocument.id) {
            await driverDocumentApi.apiDriverDocumentDriverDocumentIdPatch({
              driverDocumentId: driverDocument.id,
              body: {...newValues, documentId: driverDocument.documentId},
            });
          } else {
            await driverDocumentApi.apiDriverDocumentPost({
              body: newValues,
            });
          }
          history.push('/driverdocumentlist');
          notify(
            'success',
            `${driverDocument?.id ? 'Updated' : 'Added'} driver document`
          );
        }
      } catch (e) {
        notify(
          'error',
          e.message ??
            `Failed to ${driverDocument?.id ? 'update' : 'add'} driver document`
        );
      } finally {
        formikHelpers.setSubmitting(false);
        setSubmitting(false);
      }
    },
    [driverDocument, history, notify]
  );

  const value: DriverDocumentContextProps = {
    // Template Table
    list,
    loadList,
    hasPermission,
    loading: loading || loadingSingleItem,
    cleanupList: cleanupList,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onAdd: hasPermission.add ? handleAdd : undefined,
    onNavigate: handleNavigate,
    onDelete: hasPermission.delete ? handleDelete : undefined,
    onRefresh: handleRefresh,
    // Forms
    onDetailsFormSubmit: handleDetailsFormSubmit,
    setDriverDocumentId,
    submitting,
    driverDocument,
    driverDocumentId,
    detailsRef,
  };

  React.useEffect(() => {
    loadDriverDocument();
    return () => setDriverDocument(undefined);
  }, [loadDriverDocument]);

  return (
    <DriverDocumentContext.Provider value={value}>
      {children}
    </DriverDocumentContext.Provider>
  );
};
