import React from 'react';
import {TableFilterRow} from '@devexpress/dx-react-grid-material-ui';
import {useTableFilterRowCellStyles} from './TableFilterRowCell.style';

export const TableFilterRowCell: React.FC<TableFilterRow.CellProps> = (
  props
) => {
  const classes = useTableFilterRowCellStyles();
  return <TableFilterRow.Cell className={classes.root} {...props} />;
};
