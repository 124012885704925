import React from 'react';
import 'typeface-roboto';
import Lottie from 'react-lottie';
import PlayCircleFilled from '@mui/icons-material/PlayCircleFilled';
import PauseCircleFilled from '@mui/icons-material/PauseCircleFilled';
import {getStyle} from '../helpers';
import vantageLoading from '../../../../img/json/ex-veiwMetricsLogoOnly-bm.json';

const componentStyle = (brandColors) => ({
  default: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100vw / 1920 * 160)',
      width: '100vw',
      backgroundColor: brandColors.brandBackground,
    },
    content: {
      display: 'flex',
      height: 'calc(100vw / 1920 * 160)',
      marginTop: 'calc(100vw / 1920 * -20)',
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
    },
    head: {
      backgroundColor: brandColors.brandPrimaryLighter,
      borderRadius: '0 0 calc(100vw / 1920 * 24) 0',
      // position: 'absolute',
      display: 'flex',
      height: 'calc(100vw / 1920 * 100)',
      width: 'calc(100vw / 1920 * 357)',
      justifyContent: 'center',
      alignItems: 'center',
    },
    title: {
      fontStyle: 'normal',
      fontWeight: 900,
      textTransform: 'uppercase',
      fontSize: 'calc(100vw / 1920 * 64)',
      color: brandColors.brandPrimaryText,
      fontFamily: 'Roboto',
      whiteSpace: 'nowrap',
    },
    side: {
      backgroundColor: brandColors.brandPrimary,
      display: 'flex',
      flex: 1,
      height: 'calc(100vw / 1920 * 80)',
      flexDirection: 'row',
      // width: '100vw',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    side1: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginRight: 'calc(100vw / 1920 * 710)',
      alignSelf: 'center',
    },
    side2: {
      display: 'flex',
      marginRight: 'calc(100vw / 1920 * 211)',
      justifyContent: 'flex-start',
      alignItems: 'center',
      paddingRight: 'calc(100vw / 1920 * 48)',
    },
    side3: {
      marginRight: 'calc(100vw / 1920 * 32)',
    },
    sideText: {
      color: brandColors.brandPrimaryText,
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontFamily: 'Roboto',
      fontSize: 'calc(100vw / 1920 * 40)',
    },
    controls: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      // alignItems: 'center',
    },
    legend: {
      display: 'flex',
      flexDirection: 'row',
      marginLeft: 'calc(100vw / 1920 * 336)',
      // width: 'calc(100vw / 1920 * 1334)',
    },
    legendItem: {
      display: 'flex',
      flexDirection: 'row',
      height: 'calc(100vw / 1920 * 40)',
      margin:
        'calc(100vw / 1920 * 30) 0 calc(100vw / 1920 * 10) calc(100vw / 1920 * 32)',
      alignItems: 'center',
    },
    legendItemDot: {
      borderRadius: 'calc(100vw / 1920 * 40)',
      height: 'calc(100vw / 1920 * 40)',
      width: 'calc(100vw / 1920 * 40)',
    },
    legendItemTitle: {
      marginLeft: 'calc(100vw / 1920 * 12)',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: 'calc(100vw / 1920 * 20)',
      textTransform: 'uppercase',
    },
    control: {
      display: 'flex',
      flexDirection: 'row',
      borderRadius: 'calc(100vw / 1920 * 8)',
      backgroundColor: brandColors.brandBackground,
      height: 'calc(100vw / 1920 * 40)',
      alignItems: 'center',
      // justifyContent: 'space-between',
      borderColor: brandColors.brandBackground,
      borderStyle: 'none',
      width: 'calc(100vw / 1920 * 197)',
    },
    controlIcon: {
      fontSize: 'calc(100vw / 1920 * 24)',
    },
    controlText: {
      color: brandColors.brandPrimary,
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: 'calc(100vw / 1920 * 18)',
      marginLeft: 'calc(100vw / 1920 * 16)',
      textTransform: 'uppercase',
    },
    legendItemPlanned: {
      color: brandColors.brandPrimaryHighlight,
    },
    legendItemActual: {
      color: brandColors.brandSecondaryHighlight,
    },
    legendItemBudgeted: {
      color: brandColors.brandTertiaryHighlight,
    },
    legendItemOwnFleet: {
      color: `linear-gradient(180deg, ${brandColors.brandSecondaryHighlight} 33%, ${brandColors.brandTertiaryHighlight} 33.1%, ${brandColors.brandTertiaryHighlight} 66%, ${brandColors.brandPrimaryHighlight} 66.1%)`,
    },
    legendItemOtherFleet: {
      color: `linear-gradient(180deg, ${brandColors.brandSecondaryHighlightLighter} 33%, ${brandColors.brandTertiaryHighlightLighter} 33.1%, ${brandColors.brandTertiaryHighlightLighter} 66%, ${brandColors.brandPrimaryHighlightLighter} 66.1%)`,
    },
    logo: {
      display: 'none',
    },
  },
  unitrans: {
    head: {
      width: 'auto',
    },
    logo: {
      display: 'flex',
      marginLeft: 'calc(100vw / 1920 * 30)',
      height: 'calc(100vw / 1920 * 80)',
      width: 'calc(100vw / 1920 * 80)',
      alignItems: 'center',
    },
    title: {
      paddingLeft: 'calc(100vw / 1920 * 40)',
      paddingRight: 'calc(100vw / 1920 * 31)',
    },
    side: {},
    side1: {
      display: 'none',
    },
    side2: {
      paddingRight: 'auto',
    },
    controls: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      width: '100vw',
    },
    legend: {
      alignItems: 'flex-end',
      width: 'auto',
      marginRight: 'calc(100vw / 1920 * 57)',
    },
    legendItemPlanned: {
      color: brandColors.brandPrimary,
    },
    legendItemActual: {
      color: brandColors.brandTertiaryHighlight,
    },
    legendItemBudgeted: {
      color: brandColors.brandPrimaryHighlight,
    },
  },
});

const Legend = ({children}) => {
  const styles = getStyle(componentStyle);
  return <div style={styles.legend}>{children}</div>;
};

const LegendItem = ({title, color = 'white'}) => {
  const styles = getStyle(componentStyle);
  return (
    <div style={styles.legendItem}>
      <div style={{...styles.legendItemDot, background: color}} />
      <div style={styles.legendItemTitle}>{title}</div>
    </div>
  );
};

const UtilisationHeader = ({isPaused, onStart, onStop, title}) => {
  const styles = getStyle(componentStyle);
  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div style={styles.head}>
          <div style={styles.logo}>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: vantageLoading,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              isClickToPauseDisabled
            />
          </div>
          <div style={styles.title}>{title}</div>
        </div>
        <div style={styles.side}>
          <div style={styles.side1}>
            <div style={styles.sideText}>Kilometers</div>
          </div>
          <div style={styles.side2}>
            <div style={styles.sideText}>Vehicles</div>
          </div>
          <div style={styles.side3}>
            <button
              type="button"
              style={styles.control}
              onClick={isPaused ? onStart : onStop}
            >
              {isPaused ? (
                <PlayCircleFilled color="primary" style={styles.controlIcon} />
              ) : (
                <PauseCircleFilled color="primary" style={styles.controlIcon} />
              )}
              <div style={styles.controlText}>
                {isPaused ? 'Play' : 'Pause'} Screen
              </div>
            </button>
          </div>
        </div>
      </div>
      <div style={styles.content}>
        <div style={styles.controls}>
          <Legend>
            <LegendItem
              color={styles.legendItemPlanned.color}
              title="Planned"
            />
            <LegendItem color={styles.legendItemActual.color} title="Actual" />
            <LegendItem
              color={styles.legendItemBudgeted.color}
              title="Budgeted"
            />
            <span style={{width: 'calc(100vw/1920*275)'}} />
            <LegendItem
              color={styles.legendItemOwnFleet.color}
              title="Own Fleet"
            />
            <LegendItem
              color={styles.legendItemOtherFleet.color}
              title="Other Fleet"
            />
          </Legend>
        </div>
      </div>
    </div>
  );
};

export default UtilisationHeader;
