import React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import {Loader} from '../loader';

interface Props {
  count: number;
  countType: string;
  loading: boolean;
}

class GeoSelectionCard extends React.Component<Props> {
  render() {
    const {count, countType, loading} = this.props;
    return (
      <Card style={{marginRight: 10, flexGrow: 1, flex: 1}}>
        {loading ? (
          <div style={{margin: 20, padding: 20}}>
            <Loader size={40} />
          </div>
        ) : (
          <CardHeader
            title={`${countType}: ${count}`}
            avatar={
              <Avatar>
                <LocalShippingIcon />
              </Avatar>
            }
          />
        )}
      </Card>
    );
  }
}

export default GeoSelectionCard;
