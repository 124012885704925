import {renderToString} from 'react-dom/server';
import React from 'react';
import {WebPlanningBoardMasterTripWithIdType} from '../planningBoardContext';
import './getMasterTripContent.css';

export const getMasterTripContent = (
  masterTrip: WebPlanningBoardMasterTripWithIdType
) => {
  const uniqueOrderIds: number[] = [];
  masterTrip.trip?.stops?.forEach(({orders}) => {
    orders?.forEach(({id}) => {
      if (id && !uniqueOrderIds.includes(id)) {
        uniqueOrderIds.push(id);
      }
    });
  });

  /*
  this is a conditional (ternary) operator that determines the value of the orderNumberHtml variable,
   based on the condition uniqueOrderIds.length > 0. if condition is not true the orderNumberHtml is assigned null.
   */
  const uniqueOrderHtml =
    uniqueOrderIds.length > 0 ? (
      <div className="orderNumber" key="numberOfOrders">
        <strong>{uniqueOrderIds.length} &nbsp;</strong>
      </div>
    ) : null;

  const html = (
    <div className="superContainer">
      {uniqueOrderHtml}
      <div>{masterTrip.tripNumber ?? masterTrip.trip?.tripNumber}</div>
    </div>
  );
  return renderToString(html);
};
