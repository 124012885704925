import React from 'react';
import {connect} from 'formik';
import clone from 'rfdc';
import {
  Grid,
  Table,
  TableColumnVisibility,
  TableEditColumn,
  TableHeaderRow,
  TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import OpenIcon from '@mui/icons-material/OpenInNew';
import IconButton from '@mui/material/IconButton';
import {
  ChangeSet,
  EditingState,
  TableColumnWidthInfo,
} from '@devexpress/dx-react-grid';
import _ from 'lodash';
import history from '../../../service/history';
import {Command} from '../../table';
import generateUUID from '../../../service/UUID';
import PlanningTripsRoutes from './planningSolutionTripsRoutes/PlanningTripsRoutes';
import {IFormTrip, PlanningSolutionFormValues} from './PlanningSolutionForm';
import {usePlanningSolutionData} from '../../../stores/context';
import {getTripTAT} from '../../../service/Util';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProps {}

const TripAction = (row: IFormTrip) => {
  let path = `${history.location.pathname}/t/${row.id}`;
  if (row.id === -1 && row.tempId) {
    path = `${path}?tempId=${row.tempId}`;
  } else if (row.id === -1 && !row.tempId) {
    return null;
  }
  return (
    <IconButton onClick={() => history.push(path)} size="large">
      <OpenIcon />
    </IconButton>
  );
};
const TableRow = (
  props: JSX.IntrinsicAttributes &
    Table.DataCellProps & {
      [x: string]: any;
      className?: string | undefined;
      style?: React.CSSProperties | undefined;
    }
) => <Table.Cell {...props} style={{padding: 0}} />;
// eslint-disable-next-line import/prefer-default-export
export const PlanningSolutionTrips = connect<
  IProps,
  PlanningSolutionFormValues
>(({formik}) => {
  const rows = formik.values.trips;

  const [columnWidths, setColumnWidths] = React.useState<
    TableColumnWidthInfo[]
  >([
    {columnName: 'id', width: 80},
    {columnName: 'actions', width: 80},
    {columnName: 'tripNumber', width: 80},
    {columnName: 'vehicle', width: 120},
    {columnName: 'vehicleType', width: 80},
    {columnName: 'allowedSkills', width: 80},
    {columnName: 'driver', width: 80},
    {columnName: 'numberOfOrders', width: 80},
    {columnName: 'numberOfStops', width: 80},
    {columnName: 'usedSkills', width: 80},
    {columnName: 'overallCapacityUtilization', width: 80},
    {columnName: 'overallTimeUtilization', width: 80},
    {columnName: 'tripDistance', width: 100},
    {columnName: 'tripDuration', width: 100},
  ]);

  const {planningSolution} = usePlanningSolutionData((store) => ({
    planningSolution: store.planningSolution,
  }));
  const columns = [
    {name: 'id', title: 'ID'},
    {name: 'actions', title: 'Actions', getCellValue: TripAction},
    {
      name: 'tripNumber',
      title: 'Trip Number',
      getCellValue: (row: IFormTrip) => row.tripNumber,
    },
    {
      name: 'vehicle',
      title: 'Vehicle',
      getCellValue: (row: IFormTrip) => {
        return _.get(row, 'vehicle.label');
      },
    },
    {
      name: 'vehicleType',
      title: 'Vehicle Type',
      getCellValue: (row: IFormTrip) => {
        const planningMasterTrip = _.find(
          // @ts-expect-error upgrade
          planningSolution.planningMasterTrips,
          ['trip.id', row.id]
        );
        return _.get(planningMasterTrip, 'trip.vehicle.type.name');
      },
    },
    {
      name: 'allowedSkills',
      title: 'Allowed Skills',
      getCellValue: (row: IFormTrip) => {
        const planningMasterTrip = _.find(
          // @ts-expect-error upgrade
          planningSolution.planningMasterTrips,
          ['trip.id', row.id]
        );
        return _.get(planningMasterTrip, 'trip.vehicle.type.name');
      },
    },
    {
      name: 'driver',
      title: 'Driver',
      getCellValue: (row: IFormTrip) => {
        return _.get(row, 'driver.label');
      },
    },
    {
      name: 'numberOfOrders',
      title: 'Orders',
      getCellValue: (row: IFormTrip) => {
        return row.orders.length;
      },
    },
    {
      name: 'numberOfStops',
      title: 'Stops',
      getCellValue: (row: IFormTrip) => {
        const planningMasterTrip = _.find(
          // @ts-expect-error upgrade
          planningSolution.planningMasterTrips,
          ['trip.id', row.id]
        );
        return _.get(planningMasterTrip, 'trip.stops')
          ? _.get(planningMasterTrip, 'trip.stops').length
          : 0;
      },
    },
    {
      name: 'usedSkills',
      title: 'Used Skills',
      getCellValue: (row: IFormTrip) => {
        const planningMasterTrip = _.find(
          // @ts-expect-error upgrade
          planningSolution.planningMasterTrips,
          ['trip.id', row.id]
        );
        return _.get(planningMasterTrip, 'usedSkills');
      },
    },
    {
      name: 'overallCapacityUtilization',
      title: 'Capacity %',
      getCellValue: (row: IFormTrip) => {
        const planningMasterTrip = _.find(
          // @ts-expect-error upgrade
          planningSolution.planningMasterTrips,
          ['trip.id', row.id]
        );
        const overallCapacityUtilization =
          _.get(planningMasterTrip, 'overallCapacityUtilization') || 0;
        return `${Math.round(overallCapacityUtilization * 100)}%`;
      },
    },
    {
      name: 'overallTimeUtilization',
      title: 'Shift %',
      getCellValue: (row: IFormTrip) => {
        const planningMasterTrip = _.find(
          // @ts-expect-error upgrade
          planningSolution.planningMasterTrips,
          ['trip.id', row.id]
        );
        const overallTimeUtilization =
          _.get(planningMasterTrip, 'overallTimeUtilization') || 0;
        return `${Math.round(overallTimeUtilization * 100)}%`;
      },
    },
    {
      name: 'tripDistance',
      title: 'Distance (km)',
      getCellValue: (row: IFormTrip) => {
        const planningMasterTrip = _.find(
          // @ts-expect-error upgrade
          planningSolution.planningMasterTrips,
          ['trip.id', row.id]
        );
        return !isNaN(_.get(planningMasterTrip, 'trip.tripDistance'))
          ? Math.round(_.get(planningMasterTrip, 'trip.tripDistance') * 100) /
              100
          : '-';
      },
    },
    {
      name: 'tripDuration',
      title: 'Duration (min)',
      getCellValue: (row: IFormTrip) => {
        const planningMasterTrip = _.find(
          // @ts-expect-error upgrade
          planningSolution.planningMasterTrips,
          ['trip.id', row.id]
        );
        const tripTAT = getTripTAT(
          _.get(planningMasterTrip, 'trip.tripStart'),
          _.get(planningMasterTrip, 'trip.tripEnd')
        );
        return Math.round(tripTAT * 100) / 100;
      },
    },
  ];

  const changeColumnWidths = (columnWidths: TableColumnWidthInfo[]) => {
    setColumnWidths(columnWidths);
  };

  const handleAddTrip = () => {
    const trip: IFormTrip = {
      id: -1,
      orders: [],
      tempId: generateUUID(),
      driver: null,
      vehicle: null,
      tripNumber: '',
    };

    formik.setFieldValue('trips', [
      ...clone()(formik.values.trips || []),
      trip,
    ]);
    history.push(
      `${history.location.pathname}/t/${trip.id}?tempId=${trip.tempId}`
    );
  };

  const handleCommitChanges = ({deleted}: ChangeSet) => {
    if (deleted) {
      const trips = clone()(formik.values.trips || []);
      deleted.forEach((d) => {
        if (d || d === 0) {
          trips.splice(+d, 1);
        }
      });
      formik.setFieldValue('trips', trips);
      formik.submitForm();
    }
  };

  return (
    <Grid columns={columns} rows={rows || []}>
      {planningSolution && planningSolution.planningMasterTrips && (
        <PlanningTripsRoutes
          routes={rows}
          planningSolution={planningSolution}
        />
      )}
      <EditingState
        onCommitChanges={handleCommitChanges}
        onAddedRowsChange={handleAddTrip}
      />
      <Table cellComponent={TableRow} />
      <TableColumnResizing
        minColumnWidth={10}
        columnWidths={columnWidths}
        onColumnWidthsChange={changeColumnWidths}
      />
      <TableHeaderRow />
      <TableColumnVisibility defaultHiddenColumnNames={['id']} />
      <TableEditColumn
        commandComponent={Command}
        // TODO: check permissions
        // showAddCommand={showAddCommand}
        showAddCommand
        // TODO: check permissions
        // showDeleteCommand={showDeleteCommand}
        showDeleteCommand
      />
    </Grid>
  );
});
