import React from 'react';
import {
  ITemplateControllerRoute,
  TemplateController,
} from '../../factory/template';
import {ProfileContextProvider} from './ProfileContext';
import {ProfileList} from './ProfileList';

export const ProfileController: React.FC = () => {
  return (
    <ProfileContextProvider>
      <ProfileControllerComponent />
    </ProfileContextProvider>
  );
};

const ProfileControllerComponent: React.FC = () => {
  const routes: ITemplateControllerRoute[] = React.useMemo(
    () => [{title: 'Profile List', component: ProfileList}],
    []
  );
  return <TemplateController rootPath="/profilelist" routes={routes} />;
};
