import React from 'react';
import {
  MasterTripDebriefSnapshot,
  MasterTripDebriefTripStopExecutionSnapshot,
} from '@onroadvantage/onroadvantage-api';
import {Collapse, Divider, Stack} from '@mui/material';
import Typography from '@mui/material/Typography';
import {
  TripDebriefSummaryCard,
  TripDebriefSummaryCardList,
} from '../tripDebriefSummaryCard';
import {TripDebriefContext} from '../../TripDebriefContext';
import {getTatThreshold, getTripStopExecution} from '../../helpers';
import {TripDebriefSummaryTimesForm} from './TripDebriefSummaryTimesForm';

export const TripDebriefSummaryTimes: React.FC = () => {
  const {masterTripDebriefConfig, masterTripDebriefData, onTabNavigate} =
    React.useContext(TripDebriefContext);
  /**
   *  Destructure snapshotData, need to add || ({} as MasterTripDebriefSnapshot) for typescript, since
   *  snapshotData is nullable.
   */
  const {stops, contract, plannedStartTime, plannedEndTime} =
    masterTripDebriefData?.snapshotData || ({} as MasterTripDebriefSnapshot);
  /**
   *  Destructure overrideData, need to add || ({} as MasterTripDebriefSnapshot) for typescript, since
   *  overrideData is nullable.
   */
  const {stops: stopsOverride} =
    masterTripDebriefData?.overrideData || ({} as MasterTripDebriefSnapshot);

  const [tatThresholdExceeds, setTatThresholdExceeds] =
    React.useState<boolean>(false);

  const {displayTatThreshold, tatThresholdAbs} = React.useMemo(
    () => getTatThreshold(masterTripDebriefData),
    [masterTripDebriefData]
  );

  const list: TripDebriefSummaryCardList = React.useMemo(() => {
    const {firstStopExecution, lastStopExecution} = getTripStopExecution(
      stops,
      contract
    );

    let firstStopExecutionOverride:
      | MasterTripDebriefTripStopExecutionSnapshot
      | null
      | undefined;
    let lastStopExecutionOverride:
      | MasterTripDebriefTripStopExecutionSnapshot
      | null
      | undefined;

    if (stopsOverride && stopsOverride.length > 0) {
      firstStopExecutionOverride = stopsOverride[0].tripStopExecution;
      lastStopExecutionOverride =
        stopsOverride[stopsOverride.length - 1].tripStopExecution;
    }

    return [
      {
        primary: 'Planned start time',
        secondary: plannedStartTime,
        type: 'datetime',
        changed: false,
      },
      {
        primary: 'Actual Start (Geofence)',
        secondary: firstStopExecution?.gpsDepartureTime,
        type: 'datetime',
        changed: false,
      },
      {
        primary: 'Actual Start (Mobile)',
        secondary:
          firstStopExecutionOverride?.mobileDepartureTime ??
          firstStopExecution?.mobileDepartureTime,
        type: 'datetime',
        changed: !!firstStopExecutionOverride?.mobileDepartureTime,
      },
      {
        primary: 'Planned end time',
        secondary: plannedEndTime,
        type: 'datetime',
        changed: false,
      },
      {
        primary: 'Actual End (Geofence)',
        secondary: lastStopExecution?.gpsArrivalTime,
        type: 'datetime',
        changed: false,
      },
      {
        primary: 'Actual End (Mobile)',
        secondary:
          lastStopExecutionOverride?.mobileArrivalTime ??
          lastStopExecution?.mobileArrivalTime,
        type: 'datetime',
        changed: !!lastStopExecutionOverride?.mobileArrivalTime,
      },
      {
        primary: 'Trip Tat Threshold',
        secondary: displayTatThreshold,
        type: 'string',
        changed: false,
      },
    ];
  }, [
    contract,
    displayTatThreshold,
    plannedEndTime,
    plannedStartTime,
    stops,
    stopsOverride,
  ]);

  const handleNavigate = React.useCallback(
    () => onTabNavigate('Times'),
    [onTabNavigate]
  );

  React.useEffect(() => {
    if (
      masterTripDebriefConfig?.tatThreshold &&
      tatThresholdAbs > masterTripDebriefConfig?.tatThreshold
    ) {
      setTatThresholdExceeds(true);
    }

    return () => {
      setTatThresholdExceeds(false);
    };
  }, [
    masterTripDebriefConfig?.tatThreshold,
    masterTripDebriefData,
    tatThresholdAbs,
  ]);

  return (
    <TripDebriefSummaryCard
      title="Times"
      list={list}
      onNavigate={handleNavigate}
    >
      <Collapse in={tatThresholdExceeds}>
        <Stack spacing={2}>
          <Divider />
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2">Config Tat Threshold: </Typography>
            <Typography variant="body2" color="text.secondary">
              {masterTripDebriefConfig?.tatThreshold} minutes
            </Typography>
          </Stack>
          <TripDebriefSummaryTimesForm />
        </Stack>
      </Collapse>
    </TripDebriefSummaryCard>
  );
};
