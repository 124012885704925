import React from 'react';
import vis, {
  DataGroupCollectionType,
  DataItemCollectionType,
  DateType,
  IdType,
  TimelineAnimationOptions,
  TimelineOptions,
} from 'vis';

export interface VisTimelineProps {
  groups: DataGroupCollectionType;
  items: DataItemCollectionType;
  options?: TimelineOptions;
  visible?: boolean;
  moveTo?: {
    time: DateType;
    options?: TimelineAnimationOptions | undefined;
    callback?: ((properties?: any) => void) | undefined;
  };
  onRightClick?: (properties?: any) => void;
  onDoubleClick?: (properties?: any) => void;
  onSetSelection?: (items: IdType[]) => IdType[];
  selection?: IdType[];
}

export const VisTimeline: React.FC<VisTimelineProps> = ({
  groups = [],
  items = [],
  options,
  moveTo,
  onRightClick,
  onDoubleClick,
  onSetSelection,
  selection,
}) => {
  const visJsRef = React.useRef<HTMLDivElement>(null);
  const [timeline, setTimeline] = React.useState<vis.Timeline>();

  React.useEffect(() => {
    // add events
    if (visJsRef.current) {
      setTimeline(new vis.Timeline(visJsRef.current, items, groups, options));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    timeline?.setGroups(groups);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups]);

  React.useEffect(() => {
    timeline?.setItems(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  React.useEffect(() => {
    if (options) {
      timeline?.setOptions(options);
    } else {
      timeline?.setOptions({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const startTime = options?.start;
  const endTime = options?.end;
  React.useEffect(() => {
    const UNIQUE_START_ID = 'startTimeUniqueId';
    const UNIQUE_END_ID = 'endTimeUniqueId';
    if (startTime) {
      timeline?.addCustomTime(startTime, UNIQUE_START_ID);
      timeline?.setCustomTimeTitle('Start', UNIQUE_START_ID);
    }
    if (endTime) {
      timeline?.addCustomTime(endTime, UNIQUE_END_ID);
      timeline?.setCustomTimeTitle('End', UNIQUE_END_ID);
    }
    return () => {
      startTime && timeline?.removeCustomTime(UNIQUE_START_ID);
      endTime && timeline?.removeCustomTime(UNIQUE_END_ID);
    };
  }, [timeline, startTime, endTime]);

  // Handle rightClick
  React.useEffect(() => {
    if (onRightClick) {
      timeline?.on('contextmenu', onRightClick);
    }
    return () => {
      if (onRightClick) {
        timeline?.off('contextmenu');
      }
    };
  }, [timeline, onRightClick]);

  // Handle doubleClick
  React.useEffect(() => {
    if (onDoubleClick) {
      timeline?.on('doubleClick', onDoubleClick);
    }
    return () => {
      if (onDoubleClick) {
        timeline?.off('doubleClick');
      }
    };
  }, [timeline, onDoubleClick]);

  // Handle selection
  React.useEffect(() => {
    if (onSetSelection) {
      timeline?.on('select', (e) => {
        timeline?.setSelection(onSetSelection(e.items));
      });
    }
    return () => {
      if (onSetSelection) {
        timeline?.off('select');
      }
    };
  }, [timeline, onSetSelection]);

  // Handle selection
  React.useEffect(() => {
    if (moveTo) {
      timeline?.moveTo(moveTo.time, moveTo.options, moveTo.callback);
      setTimeout(() => {
        timeline?.zoomIn(1);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moveTo]);

  // Handle manually set selection
  React.useEffect(() => {
    if (selection) {
      timeline?.setSelection(selection);

      const scrollerElement = visJsRef.current?.querySelector(
        '.vis-vertical-scroll'
      );
      if (scrollerElement) {
        scrollerElement.scrollTo({top: 0});
      }
    }
  }, [timeline, selection]);

  return <div id="vis-timeline" ref={visJsRef} />;
};
