import {makeStyles, createStyles} from '@mui/styles';
import {Theme} from '@mui/material/styles';

const TablePagingPanelContainerStyles = (theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
      '& .Pagination-text': {
        color: theme.palette.text.primary,
      },
    },
  });

export const useTablePagingPanelContainerStyles = makeStyles(
  TablePagingPanelContainerStyles,
  {name: 'TablePagingPanelContainerStyles'}
);
