import React from 'react';
import {
  AutocompleteOptionType,
  AutocompleteProps,
  TAutocompleteOnInputChange,
} from '../../../autocomplete';
import {FormikAutocompleteHooks} from '../../../formik';
import {Listing} from '@onroadvantage/onroadvantage-api';
import {listingApi} from '../../../../api';
import {debounce} from '@mui/material';
import _ from 'lodash';

interface Props extends AutocompleteProps {
  listName: string;
}

export const ReportParameterMultiSelect: React.FC<Props> = ({
  listName,
  loading,
  options,
  ...props
}) => {
  const [uniqueOptions, setUniqueOptions] = React.useState<
    AutocompleteOptionType[]
  >([]);
  const [listing, setListing] = React.useState<Listing[]>([]);
  const [localLoading, setLocalLoading] = React.useState<boolean>(false);

  const getListing = React.useCallback(
    async (filter?: string) => {
      setLocalLoading(true);
      const response = await listingApi.apiListingGet({
        model: _.upperFirst(_.camelCase(listName)),
        query: filter,
      });
      setListing(response.items ?? []);
      setLocalLoading(false);
    },
    [listName, setListing, setLocalLoading]
  );

  const getListingDebounce = React.useRef(debounce(getListing, 500)).current;

  const handleInputChange: TAutocompleteOnInputChange = async (
    _event,
    value: string
  ) => {
    setLocalLoading(true);
    await getListingDebounce(value);
  };

  React.useEffect(() => {
    getListing();
  }, [getListing]);

  React.useEffect(() => {
    const tempOptions: AutocompleteOptionType[] = [
      {value: 0, label: `All ${listName === 'node' ? 'Site' : listName}s`},
      ...(listing ?? []),
    ];
    setUniqueOptions(tempOptions);
  }, [listName, listing, setUniqueOptions]);

  return (
    <FormikAutocompleteHooks
      {...props}
      loading={localLoading || localLoading}
      options={uniqueOptions}
      onInputChange={handleInputChange}
    />
  );
};
