import React from 'react';
import {useMasterTripRecipientStyles} from './MasterTripRecipient.style';
import {RecipientDetails} from './recipientDetails';
import {Typography} from '@mui/material';
import {MasterTripRecipientContext} from './MasterTripRecipientContext';
import {RecipientMap} from './recipientMap';
import {RecipientInvalid} from '../recipientInvalid';
import {MasterTripRecipientGantt} from './MasterTripRecipientGantt';

export const MasterTripRecipient: React.FC = () => {
  const classes = useMasterTripRecipientStyles();
  const {invalid, refreshTime, masterTrip} = React.useContext(
    MasterTripRecipientContext
  );

  if (invalid) return <RecipientInvalid />;

  return (
    <div className={classes.root}>
      <div className={classes.refreshBanner}>
        <Typography variant="subtitle1">
          Last Auto Refresh at {refreshTime}
        </Typography>
      </div>
      <Typography component="h1" variant="h3" className={classes.title}>
        Recipient Tracking: {masterTrip?.tripNumber}{' '}
        {masterTrip?.trip?.referenceNumber}
      </Typography>
      <div className={classes.mainLayout}>
        <div className={classes.gantt}>
          <MasterTripRecipientGantt />
        </div>
      </div>
      <div className={classes.mainLayout}>
        <RecipientMap />
        <RecipientDetails />
      </div>
    </div>
  );
};
