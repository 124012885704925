import React from 'react';
import {UserForm} from './UserForm';
import {ITemplateTabControllerTab, TemplateTabs} from '../../factory/template';
import {UserContext} from './UserContext';
import {UserRoles} from './UserRoles';
import {UserCustomers} from './UserCustomers';
import {UserContracts} from './UserContracts';

export const User: React.FC = () => {
  const {userId, loading} = React.useContext(UserContext);

  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {title: 'Details', element: <UserForm />},
    {title: 'Roles', element: <UserRoles />},
    {title: 'Contract', element: <UserContracts />},
    {title: 'Customers', element: <UserCustomers />},
  ]);

  const path = React.useMemo(() => `/userlist/${userId}`, [userId]);

  return <TemplateTabs tabs={tabs} path={path} loading={loading} />;
};
