import React from 'react';
import {WebEvent} from '@onroadvantage/onroadvantage-api';
import {useAppNotifications} from '../../contexts';
import {EventsBulkClosingSteps} from './useEventsBulkClosing';
import {webEventApi} from '../../api';

interface EventsBulkClosingContextProps {
  setSelectedEvents: React.Dispatch<
    React.SetStateAction<WebEvent[] | undefined>
  >;
  setEventsList: React.Dispatch<React.SetStateAction<WebEvent[] | undefined>>;
  selectedEvents: WebEvent[] | undefined;
  eventsList: WebEvent[] | undefined;
  commentInputRef: React.RefObject<HTMLInputElement>;
  validSteps: {
    [key in EventsBulkClosingSteps]: {
      isValid: boolean | undefined | null;
      message: string;
    };
  };
  submitting: boolean;
  onSubmit: () => Promise<void>;
  onNextStep(currentStep: EventsBulkClosingSteps): void;
  localLoading: boolean;
  setLocalLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

interface EventRequestBody {
  comment: string;
  criticalEventIds?: number[];
  operationalEventIds?: number[];
}

export const EventsBulkClosingContext =
  React.createContext<EventsBulkClosingContextProps | null>(null);

export const useEventsBulkClosingContext = () => {
  const eventsBulkClosingContext = React.useContext(EventsBulkClosingContext);

  if (eventsBulkClosingContext == null) {
    throw new Error(
      'useEventsBulkClosingContext has to be used within <EventsBulkClosingContext.Provider>'
    );
  }

  return eventsBulkClosingContext;
};

export const EventsBulkClosingContextProvider: React.FC<{
  onNextStep(currentStep: EventsBulkClosingSteps): void;
}> = ({children, onNextStep}) => {
  const notify = useAppNotifications();
  const [selectedEvents, setSelectedEvents] = React.useState<
    WebEvent[] | undefined
  >();
  const [eventsList, setEventsList] = React.useState<WebEvent[] | undefined>();
  const commentInputRef = React.useRef<HTMLInputElement>(null);
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [localLoading, setLocalLoading] = React.useState<boolean>(false);
  const cleanup = React.useCallback(async () => {
    if (
      commentInputRef.current != null &&
      eventsList &&
      selectedEvents &&
      eventsList.length > 0 &&
      selectedEvents.length > 0
    ) {
      commentInputRef.current.value = '';
      setSelectedEvents([]);
      setEventsList([]);
    }
  }, [eventsList, selectedEvents]);

  const handleSubmit = React.useCallback(async () => {
    setSubmitting(true);
    try {
      if (
        commentInputRef.current &&
        eventsList &&
        selectedEvents &&
        eventsList.length > 0 &&
        selectedEvents.length > 0 &&
        commentInputRef.current.value !== null
      ) {
        const criticalEventIds: number[] = [];
        const operationalEventIds: number[] = [];

        selectedEvents.forEach((event) => {
          if (event && event.id) {
            switch (event.metaData?.model) {
              case 'CriticalEvent':
                criticalEventIds.push(event.id);
                break;
              case 'OperationalEvent':
                operationalEventIds.push(event.id);
                break;
              default:
                notify('warning', 'No valid Ids found');
                break;
            }
          }
        });

        const requestBody: EventRequestBody = {
          comment: commentInputRef.current.value,
        };

        if (operationalEventIds.length > 0) {
          requestBody.operationalEventIds = operationalEventIds;
        }

        if (criticalEventIds.length > 0) {
          requestBody.criticalEventIds = criticalEventIds;
        }

        await webEventApi.apiWebEventBulkClosePost({
          body: requestBody,
        });
        notify('success', 'Events Bulk Closed ');
        await cleanup();
      } else {
        notify('warning', 'Missing required fields');
      }
    } catch (e) {
      notify('error', 'Failed to bulk close events');
    } finally {
      setSubmitting(false);
    }
  }, [cleanup, selectedEvents, eventsList, notify]);

  const value = React.useMemo<EventsBulkClosingContextProps>(
    () => ({
      selectedEvents,
      setSelectedEvents,
      eventsList,
      setEventsList,
      commentInputRef,
      submitting,
      onSubmit: handleSubmit,
      onNextStep,
      localLoading,
      setLocalLoading,
      validSteps: {
        filterEvents: {
          isValid:
            selectedEvents &&
            eventsList &&
            selectedEvents.length > 0 &&
            eventsList.length > 0,
          message: 'Search for Events first',
        },
        confirmation: {
          isValid: selectedEvents && selectedEvents.length > 0,
          message: 'Select at least 1 event',
        },
        comment: {
          isValid: true,
          message: 'N/A',
        },
      },
    }),
    [
      onNextStep,
      handleSubmit,
      eventsList,
      selectedEvents,
      submitting,
      commentInputRef,
      localLoading,
    ]
  );

  return (
    <EventsBulkClosingContext.Provider value={value}>
      {children}
    </EventsBulkClosingContext.Provider>
  );
};
