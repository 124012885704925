import React from 'react';
import {Listing} from '@onroadvantage/onroadvantage-api';
import {AutocompleteProps, TextField} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {Loader} from '../../../loader';
import {useAutocompleteStyles} from '../../../autocomplete/Autocomplete.style';

interface TripSetupAutocompleteProps<T extends Listing | Listing[]> {
  name: string;
  label: string;
  value: T | null;
  options: Listing[];
  onInputChange: AutocompleteProps<T, any, any, any>['onInputChange'];
  onChange: (value: T | null) => void;
  loading?: boolean;
  multiple?: boolean;
  placeholder?: string;
  helperText?: string;
}

export const TripSetupAutocomplete = <T extends Listing | Listing[]>({
  options,
  name,
  label,
  value,
  onInputChange,
  onChange,
  loading,
  multiple,
  placeholder,
  helperText,
}: TripSetupAutocompleteProps<T>) => {
  /** Classes specific to autocomplete fields */
  const classes = useAutocompleteStyles();

  const handleChange = React.useCallback(
    (_event, value) => {
      onChange(value);
    },
    [onChange]
  );

  return (
    <Autocomplete
      id={name}
      options={options}
      value={value}
      onChange={handleChange}
      multiple={multiple}
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      onInputChange={onInputChange}
      renderOption={(props, option) => (
        <li {...props} key={option.value}>
          {option.label}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          name={name}
          label={label}
          helperText={helperText}
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <Loader
                    size={20}
                    classes={{loaderContainer: classes.loader}}
                  />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
