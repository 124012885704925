import React from 'react';
import {Stack} from '@mui/material';
import {
  WebPlanningBoardOrder,
  WebPlanningBoardTripStopOrder,
} from '@onroadvantage/onroadvantage-api';
import {Loader} from '../../../loader';
import {
  TemplateTable,
  TemplateTableConfig,
  TOnDragStart,
  TOnRightClick,
  TOnWarning,
} from '../../../../factory/template';
import {IVantageMenuRef, VantageMenu} from '../../../menu';
import {usePlanningBoardContext} from '../../planningBoardContext';
import {IPlanningBoardGanttItem} from '../../index';
import {usePlanningBoardOrdersStyles} from './PlanningBoardOrders.style';
import {usePlanningBoardOrders} from './usePlanningBoardOrders';

export const PlanningBoardOrders: React.FC = () => {
  const classes = usePlanningBoardOrdersStyles();
  const menuRef = React.useRef<IVantageMenuRef>(null);
  const {
    unassignedOrders,
    selectedPlanningSkillListings,
    loadOptimalTrips,
    loadingOrders,
    loadingInitial,
  } = usePlanningBoardContext();
  const {list, filters, sorting, onSortingChange, onFiltersChange} =
    usePlanningBoardOrders({
      orders: unassignedOrders,
      selectedPlanningSkillListings,
    });

  const [selectedOrder, setSelectedOrder] = React.useState<
    WebPlanningBoardOrder | WebPlanningBoardTripStopOrder
  >();
  const [config] = React.useState<
    TemplateTableConfig<WebPlanningBoardOrder | WebPlanningBoardTripStopOrder>
  >({
    columns: [
      {name: 'orderNumber', label: 'Order Number', type: 'string'},
      {
        name: 'customerReferenceNumber',
        label: 'Customer Reference Number',
        type: 'string',
      },
      {
        name: 'upliftPoint',
        label: 'Loading Site',
        type: 'string',
        getValue: ({upliftPoint}) => upliftPoint?.name ?? '-',
      },
      {
        name: 'offloadPoint',
        label: 'Offloading Site',
        type: 'string',
        getValue: ({offloadPoint}) => offloadPoint?.name ?? '-',
      },
      {
        name: 'planningSkill',
        label: 'Planning Skill',
        type: 'string',
        getValue: ({planningOrder}) => planningOrder?.skill ?? '-',
      },
      {
        name: 'deliverByDatetime',
        label: 'Deliver By',
        type: 'datetime',
      },
      {
        name: 'orderTrackingNumber',
        label: 'Order Tracking Number',
        type: 'string',
      },
    ],
    actionsColumn: {width: 70},
    disableToolbar: true,
    disablePagination: true,
    enableFilter: true,
    enableSort: true,
    identifier: 'PLANNING_BOARD_UNASSIGNED_ORDER_LIST',
  });

  const handleWarning = React.useCallback<
    TOnWarning<WebPlanningBoardOrder | WebPlanningBoardTripStopOrder>
  >(({deliverByDatetime}) => {
    return {
      visible: !!(deliverByDatetime && deliverByDatetime < new Date()),
      message: 'Order Deliver By date has passed',
    };
  }, []);

  // Gantt related utils
  const handleDragStart = React.useCallback<
    TOnDragStart<WebPlanningBoardOrder | WebPlanningBoardTripStopOrder>
  >((event, order) => {
    if (order.id && order.orderNumber) {
      const item: Partial<IPlanningBoardGanttItem> = {
        id: 'order',
        content: order.orderNumber,
        variant: 'order',
        data: order,
      };
      event.target.id = order.id;
      event.dataTransfer.setData('text', JSON.stringify(item));
    }
  }, []);

  const handleRightClick = React.useCallback<
    TOnRightClick<WebPlanningBoardOrder | WebPlanningBoardTripStopOrder>
  >((event, order) => {
    setSelectedOrder(order);
    menuRef.current?.onClick(event);
  }, []);

  const handleFilterByPlanningSkills = React.useCallback(async () => {
    if (selectedOrder) {
      await loadOptimalTrips(selectedOrder);
    }
  }, [loadOptimalTrips, selectedOrder]);

  if (loadingOrders) return <Loader text="Fetching orders..." />;

  if (loadingInitial) return <Loader text="Initializing..." />;

  return (
    <Stack flex={1}>
      <VantageMenu
        options={[
          {
            value: 1,
            label: 'Optimally Allocate',
            onClick: handleFilterByPlanningSkills,
          },
        ]}
        ref={menuRef}
        enableContextMenu
      />
      <TemplateTable
        config={config}
        currentPage={1}
        loading={loadingOrders}
        list={list}
        onDragStart={handleDragStart}
        onWarning={handleWarning}
        onRightClick={handleRightClick}
        onFiltersChange={onFiltersChange}
        onSortingChange={onSortingChange}
        filters={filters}
        sorting={sorting}
        elevation={0}
        classes={{paper: classes.paper}}
        scrollable
      />
    </Stack>
  );
};
