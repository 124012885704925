import React from 'react';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import {Assignment, Save} from '@mui/icons-material';
import {useTripDebriefSummaryCardStyles} from './tripDebriefSummaryCard/TripDebriefSummaryCard.style';
import {Loader} from '../../loader';
import {TripDebriefContext} from '../TripDebriefContext';
import {MasterTripDebriefSnapshotData} from '@onroadvantage/onroadvantage-api';

export const TripDebriefSummaryComment: React.FC = () => {
  const {masterTripDebriefData, onUpdateSubmit, loading, submitting} =
    React.useContext(TripDebriefContext);
  const {comment: masterTripDebriefDataComment, approvedForBilling} =
    masterTripDebriefData || ({} as MasterTripDebriefSnapshotData);
  const classes = useTripDebriefSummaryCardStyles();
  const [localSubmitting, setLocalSubmitting] = React.useState<boolean>(false);
  const [comment, setComment] = React.useState<string | null | undefined>();

  const handleSubmit = React.useCallback(async () => {
    setLocalSubmitting(true);
    try {
      await onUpdateSubmit({debriefData: {comment}});
    } finally {
      setLocalSubmitting(false);
    }
  }, [comment, onUpdateSubmit]);

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setComment(e.target.value);
    },
    []
  );

  React.useEffect(() => {
    setComment(masterTripDebriefDataComment);
    return () => {
      setComment(undefined);
    };
  }, [masterTripDebriefDataComment]);

  return (
    <Card elevation={3}>
      <CardHeader
        title="Additional Debriefing Comments"
        avatar={
          <Avatar>
            <Assignment />
          </Avatar>
        }
      />
      <CardContent className={classes.content}>
        <TextField
          disabled={
            loading || localSubmitting || submitting || approvedForBilling
          }
          onChange={handleChange}
          value={comment}
          defaultValue={masterTripDebriefDataComment}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  disabled={
                    localSubmitting ||
                    comment === masterTripDebriefDataComment ||
                    loading ||
                    submitting ||
                    approvedForBilling
                  }
                  onClick={handleSubmit}
                >
                  {localSubmitting ? <Loader size={30} /> : <Save />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          multiline
          fullWidth
        />
      </CardContent>
    </Card>
  );
};
