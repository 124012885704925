import React from 'react';
import {Stack} from '@mui/material';
import {Listing} from '@onroadvantage/onroadvantage-api';
import {TemplateForm, TOnFormikSubmit} from '../../../../factory/template';
import {FormikAutocompleteWithListing, FormikSubmit} from '../../../formik';
import {useTripDebriefSummaryStyles} from '../TripDebriefSummary.style';
import {TripDebriefContext} from '../../TripDebriefContext';
import {useAppNotifications} from '../../../../contexts';

export interface ITripDebriefSummaryTimesForm {
  reasonCode: Listing | null | undefined;
}

export const TripDebriefSummaryTimesForm: React.FC = () => {
  const notify = useAppNotifications();
  const classes = useTripDebriefSummaryStyles();
  const {masterTripDebriefConfig, onUpdateSubmit, masterTripDebriefData} =
    React.useContext(TripDebriefContext);
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const initialValues = React.useMemo<ITripDebriefSummaryTimesForm>(
    () => ({
      reasonCode: masterTripDebriefData?.tatThresholdReasonCode?.id
        ? {
            value: masterTripDebriefData?.tatThresholdReasonCode?.id,
            label: masterTripDebriefData?.tatThresholdReasonCode?.description,
          }
        : null,
    }),
    [masterTripDebriefData?.tatThresholdReasonCode]
  );

  const handleSubmit = React.useCallback<
    TOnFormikSubmit<ITripDebriefSummaryTimesForm>
  >(
    async (values, formikHelpers) => {
      setSubmitting(true);
      try {
        if (
          masterTripDebriefConfig?.requiredFields?.tatThresholdReasonCode &&
          !values.reasonCode?.value
        ) {
          notify('warning', 'It is required to add a reason code.');
        } else {
          await onUpdateSubmit({
            debriefData: {tatThresholdReasonCodeId: values.reasonCode?.value},
          });
        }
      } catch (e) {
        notify('error', 'Failed to add reason code');
      } finally {
        setSubmitting(false);
        formikHelpers.setSubmitting(false);
      }
    },
    [
      masterTripDebriefConfig?.requiredFields?.tatThresholdReasonCode,
      notify,
      onUpdateSubmit,
    ]
  );

  const contractId = masterTripDebriefData?.snapshotData?.contract?.id;

  return (
    <TemplateForm<ITripDebriefSummaryTimesForm>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      submitting={submitting}
      disableActions
    >
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        flex={1}
      >
        <FormikAutocompleteWithListing
          name="reasonCode"
          label="Tat Threshold Exceed Reason Code"
          model="MasterTripDebriefConfigTatThresholdReasonCode"
          size="small"
          contractId={contractId}
          classes={{input: classes.input, root: classes.input}}
        />
        <FormikSubmit title="Add" variant="outlined" size="small" />
      </Stack>
    </TemplateForm>
  );
};
