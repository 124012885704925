import React from 'react';
import {WebPlanningBoardTrip} from '@onroadvantage/onroadvantage-api';
import {List, ListItem, ListItemText, Stack} from '@mui/material';
import {List as ListIcon} from '@mui/icons-material';
import {Loader} from '../../../loader';
import {TemplateTable, TemplateTableConfig} from '../../../../factory/template';
import {VantageDialogButton} from '../../../dialog';
import {usePlanningBoardContext} from '../../planningBoardContext';

interface PlanningBoardTripTableProps {
  trips: WebPlanningBoardTrip[];
  identifier: string;
}

export const PlanningBoardOverviewTripList: React.FC<
  PlanningBoardTripTableProps
> = ({trips, identifier}) => {
  const {assignedOrders, loadingMasterTrips} = usePlanningBoardContext();
  const [list, setList] = React.useState<WebPlanningBoardTrip[]>([]);
  const config = React.useMemo<TemplateTableConfig<WebPlanningBoardTrip>>(
    () => ({
      columns: [
        {
          name: 'startingLocation',
          label: 'Starting Location',
          type: 'string',
          getValue: ({stops}) => (stops && stops[0]?.node?.name) ?? '-',
        },
        {
          name: 'loading',
          label: 'Loading',
          type: 'string',
          getValue: ({stops}) =>
            stops && stops.length > 0 ? stops[0]?.node?.name : undefined,
        },
        {
          name: 'offloading',
          label: 'Offloading',
          type: 'string',
          getValue: ({stops}) =>
            stops && stops.length > 0
              ? stops[stops.length - 1]?.node?.name
              : undefined,
        },
        {
          name: 'endingLocation',
          label: 'Ending Location',
          type: 'string',
          getValue: ({stops}) =>
            (stops && stops[stops.length - 1]?.node?.name) ?? '-',
        },
        {
          name: 'orderNumbers',
          label: 'Order numbers',
          type: 'string',
          getValue: ({stops}) => (
            <>
              <VantageDialogButton
                title={<ListIcon />}
                color="inherit"
                declineTitle="Close"
                dialogTitle="Order Numbers"
                maxWidth="sm"
                declineProps={{sx: {width: '100%'}}}
                fullWidth
                iconButton
                disableAccept
              >
                <List disablePadding>
                  {stops?.map(({orders}) =>
                    orders?.map((order) => (
                      <ListItem key={order.id}>
                        <ListItemText primary={order.orderNumber} />
                      </ListItem>
                    ))
                  )}
                </List>
              </VantageDialogButton>
            </>
          ),
        },
        {
          name: 'planningSkills',
          label: 'Planning Skills',
          type: 'string',
          getValue: ({stops}) => {
            const skills: any[] = [];
            assignedOrders.forEach((o) => {
              stops?.forEach((stop) => {
                stop.orders?.forEach((o2) => {
                  if (o2.id === o.id && o.planningOrder?.skill)
                    skills.push(...o.planningOrder?.skill?.split(', '));
                });
              });
            });
            return (
              <>
                <VantageDialogButton
                  title={<ListIcon />}
                  color="inherit"
                  declineTitle="Close"
                  dialogTitle="Planning Skills"
                  maxWidth="sm"
                  declineProps={{sx: {width: '100%'}}}
                  fullWidth
                  iconButton
                  disableAccept
                >
                  <List disablePadding>
                    {skills
                      .filter((skill, index) => skills.indexOf(skill) === index)
                      ?.map((skill) => (
                        <ListItem key={skill}>
                          <ListItemText primary={skill} />
                        </ListItem>
                      ))}
                  </List>
                </VantageDialogButton>
              </>
            );
          },
        },
      ],
      disableToolbar: true,
      disablePagination: true,
      identifier: `PLANNING_BOARD_${identifier}_MASTER_TRIP`,
    }),
    [assignedOrders, identifier]
  );

  React.useEffect(() => {
    setList(trips ?? []);
    return () => setList([]);
  }, [trips]);

  if (loadingMasterTrips) return <Loader text="Fetching master trips..." />;

  return (
    <Stack flex={1}>
      <TemplateTable
        config={config}
        currentPage={1}
        list={list}
        elevation={0}
        loading={loadingMasterTrips}
        scrollable
      />
    </Stack>
  );
};
