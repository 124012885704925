import React from 'react';
import {DocumentTemplateContext} from './DocumentTemplateContext';
import {useParams} from 'react-router-dom';

interface IParams {
  documentTemplateId?: string;
}

export const DocumentTemplateIdHandler: React.FC = () => {
  const {setDocumentTemplateId} = React.useContext(DocumentTemplateContext);

  const {documentTemplateId} = useParams<IParams>();

  React.useEffect(() => {
    const parsedId = documentTemplateId
      ? parseInt(documentTemplateId)
      : undefined;
    setDocumentTemplateId(parsedId);
    return () => setDocumentTemplateId(undefined);
  }, [documentTemplateId, setDocumentTemplateId]);

  return null;
};
