import {makeStyles, createStyles} from '@mui/styles';

const TableRowStyles = () =>
  createStyles({
    root: {paddingLeft: 0, height: 50},
    rootRightClick: {cursor: 'context-menu'},
    selected: {background: 'rgba(0,130,112,0.2)'},
  });

export const useTableRowStyles = makeStyles(TableRowStyles, {
  name: 'TableRowStyles',
});
