import React from 'react';
import {VehicleTypeContext} from './VehicleTypeContext';
import {
  TemplateTableConfig,
  TemplateTableWrapper,
} from '../../factory/template';
import {VehicleType} from '@onroadvantage/onroadvantage-api';

export const VehicleTypeList: React.FC = () => {
  const {loadList, cleanupList} = React.useContext(VehicleTypeContext);

  const [config] = React.useState<TemplateTableConfig<VehicleType>>({
    columns: [
      {name: 'name', label: 'Name', type: 'string'},
      {name: 'description', label: 'Description', type: 'string'},
      {
        name: 'externalId',
        label: 'External Id',
        type: 'number',
        enableFilter: false,
      },
      {
        name: 'externalSiteId',
        label: 'External Site Id',
        type: 'number',
        enableFilter: false,
      },
      {
        name: 'externalGroupId',
        label: 'External Group Id',
        type: 'number',
        enableFilter: false,
      },
      {
        name: 'planningFixedCost',
        label: 'Planning Fixed Cost',
        type: 'number',
        enableFilter: false,
      },
      {
        name: 'planningDistanceCost',
        label: 'Planning Distance Cost',
        type: 'number',
        enableFilter: false,
      },
      {
        name: 'planningTimeCost',
        label: 'Planning Time Cost',
        type: 'number',
        enableFilter: false,
      },
      {
        name: 'planningServiceCost',
        label: 'Planning Service Cost',
        type: 'number',
        enableFilter: false,
      },
      {
        name: 'planningWaitCost',
        label: 'Planning Wait Cost',
        type: 'number',
        enableFilter: false,
      },
    ],
    enableFilter: true,
    enableSort: true,
    deleteMessage: (row) => ({title: 'Vehicle Type', items: {Name: row.name}}),
    identifier: 'VEHICLE_TYPE_LIST',
  });

  React.useEffect(() => {
    loadList();
    return cleanupList;
  }, [loadList, cleanupList]);

  return <TemplateTableWrapper config={config} context={VehicleTypeContext} />;
};
