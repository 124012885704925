import React from 'react';
import {useFormikContext} from 'formik';
import {
  Listing,
  NodeDump,
  WebPlanningBoardNodeMinimal,
  WebPlanningBoardOrder,
} from '@onroadvantage/onroadvantage-api';
import {
  Button,
  DialogContent,
  Dialog,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import {Cached} from '@mui/icons-material';
import {usePlanningBoardTripFormStyles} from './PlanningBoardTripForm.style';
import {
  usePlanningBoardContext,
  usePlanningBoardGanttContext,
} from '../../../planningBoardContext';
import {checkHasRequiredSkill} from '../../../helpers';
import {
  FormikAutocompleteWithListing,
  FormikRadioGroup,
  FormikSubmit,
} from '../../../../formik';
import {PlanningBoardTripFormikAutocomplete} from './PlanningBoardTripFormikAutocomplete';
import {
  PlanningBoardTripFormDateTimePicker,
  planningBoardTripDateTimePickerRadioOptions,
} from './PlanningBoardTripFormDateTimePicker';
import {usePlanningBoardTripContext} from '../planningBoardTripContext';
import {PlanningBoardTripSubmitWithErrors} from '../PlanningBoardTripSubmitWithErrors';

export type TPlanningBoardTripFormSelectedTimeOptions =
  | null
  | 'tripStartTime'
  | 'loadingArrivalTime'
  | 'offloadingArrivalTime'
  | 'loadingOffloadingArrivalTime';

export interface IPlanningBoardTripPanelForm {
  tripStartTime?: Date | undefined;
  loadingArrivalTime?: Date | undefined;
  offloadingArrivalTime?: Date | undefined;
  selectedTimeOption: TPlanningBoardTripFormSelectedTimeOptions;
  startingNode?: WebPlanningBoardNodeMinimal;
  startingPointNode?: Listing;
  endingNode?: NodeDump;
  endingPointNode?: Listing;
  driver?: Listing;
  routeDistance?: number;
  routeDuration?: number;
  vehicle?: Listing;
}

interface PlanningBoardTripFormProps {
  isCommittedTrip?: boolean;
}

export const PlanningBoardTripForm: React.FC<PlanningBoardTripFormProps> = ({
  isCommittedTrip,
}) => {
  const classes = usePlanningBoardTripFormStyles();
  const {values, initialValues} =
    useFormikContext<IPlanningBoardTripPanelForm>();
  const {onGenerateTripPreview, loadingTripPreview, constraintErrors} =
    usePlanningBoardTripContext();
  const {getVehicle, hasPermission} = usePlanningBoardContext();
  const {selectedGanttItem} = usePlanningBoardGanttContext();
  const [vehicleWarningMessage, setVehicleWarningMessage] = React.useState<
    string | undefined
  >(undefined);
  const [openSubmitWithErrorsDialog, setOpenSubmitWithErrorsDialog] =
    React.useState<boolean>(false);

  const handleOpenSubmitWithErrorsDialog = React.useCallback(
    () => setOpenSubmitWithErrorsDialog(true),
    []
  );

  const handleCloseSubmitWithErrorsDialog = React.useCallback(
    () => setOpenSubmitWithErrorsDialog(false),
    []
  );

  const handleHasRequiredSkill = React.useCallback(
    (vehicleListing: Listing | undefined) => {
      const vehicle = getVehicle(vehicleListing?.value);
      if (selectedGanttItem?.variant === 'order' && vehicle) {
        const hasRequiredSkill = checkHasRequiredSkill(
          selectedGanttItem.data as WebPlanningBoardOrder,
          vehicle
        );
        if (!hasRequiredSkill) {
          setVehicleWarningMessage(`Vehicle doesn't have the required skill`);
        } else setVehicleWarningMessage(undefined);
      }
    },
    [getVehicle, selectedGanttItem?.data, selectedGanttItem?.variant]
  );

  const disableAll = loadingTripPreview;
  const timeSelected =
    values.selectedTimeOption !== null &&
    (values.tripStartTime ||
      values.loadingArrivalTime ||
      values.offloadingArrivalTime);

  React.useEffect(() => {
    handleHasRequiredSkill(values?.vehicle);
    return () => setVehicleWarningMessage(undefined);
  }, [handleHasRequiredSkill, values?.vehicle]);

  const handleGenerateTripPreview = React.useCallback(async () => {
    await onGenerateTripPreview(values);
  }, [onGenerateTripPreview, values]);

  if (!initialValues) return <Typography>No initial values</Typography>;

  return (
    <Stack flex={1}>
      <Stack>
        <FormikRadioGroup
          name="selectedTimeOption"
          options={planningBoardTripDateTimePickerRadioOptions}
          title="Select Time"
          className={classes.radio}
          direction="row"
          disabled={disableAll || isCommittedTrip || !hasPermission.edit}
        />
        {values.selectedTimeOption === 'tripStartTime' ? (
          <PlanningBoardTripFormDateTimePicker
            name="tripStartTime"
            disabled={disableAll || isCommittedTrip}
          />
        ) : values.selectedTimeOption === 'loadingArrivalTime' ? (
          <PlanningBoardTripFormDateTimePicker
            name="loadingArrivalTime"
            disabled={disableAll || isCommittedTrip}
          />
        ) : values.selectedTimeOption === 'offloadingArrivalTime' ? (
          <PlanningBoardTripFormDateTimePicker
            name="offloadingArrivalTime"
            disabled={disableAll || isCommittedTrip}
          />
        ) : values.selectedTimeOption === 'loadingOffloadingArrivalTime' ? (
          <>
            <PlanningBoardTripFormDateTimePicker
              name="loadingArrivalTime"
              disabled={disableAll || isCommittedTrip}
            />
            <PlanningBoardTripFormDateTimePicker
              name="offloadingArrivalTime"
              disabled={disableAll || isCommittedTrip}
            />
          </>
        ) : null}
        <Divider className={classes.divider} />
        <Stack
          direction={{xs: 'column', lg: 'row'}}
          flex={1}
          spacing={{xs: 0, lg: 2}}
        >
          <FormikAutocompleteWithListing
            name="startingPointNode"
            label="Starting Point"
            model="Node"
            disabled={disableAll || isCommittedTrip || !hasPermission.edit}
            fullWidth
          />
          <FormikAutocompleteWithListing
            name="endingPointNode"
            label="Ending Point"
            model="Node"
            disabled={disableAll || isCommittedTrip || !hasPermission.edit}
            fullWidth
          />
        </Stack>
        <Stack
          direction={{xs: 'column', lg: 'row'}}
          flex={1}
          spacing={{xs: 0, lg: 2}}
        >
          <PlanningBoardTripFormikAutocomplete
            name="driver"
            label="Driver"
            model="Driver"
            disabled={disableAll}
            fullWidth
          />
          <PlanningBoardTripFormikAutocomplete
            name="vehicle"
            label="Vehicle"
            model="Vehicle"
            disabled={disableAll || isCommittedTrip || !hasPermission.edit}
            onChange={(event, value) => {
              handleHasRequiredSkill(value as Listing);
            }}
            error={vehicleWarningMessage}
            fullWidth
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          {constraintErrors && constraintErrors.length > 0 ? (
            <Button
              variant="contained"
              onClick={handleOpenSubmitWithErrorsDialog}
              fullWidth
            >
              Submit
            </Button>
          ) : (
            <FormikSubmit
              variant="contained"
              color="primary"
              permission={{name: `Edit PlanningBoard`, type: 'Edit'}}
              fullWidth
              disableIsDirty
              disabled={!timeSelected || disableAll}
            />
          )}
          <Dialog
            open={openSubmitWithErrorsDialog}
            onClose={handleCloseSubmitWithErrorsDialog}
          >
            <DialogContent>
              <PlanningBoardTripSubmitWithErrors
                onCloseSubmitWithErrorsDialog={
                  handleCloseSubmitWithErrorsDialog
                }
              />
            </DialogContent>
          </Dialog>
          {!isCommittedTrip ? (
            <Button
              variant="outlined"
              startIcon={<Cached />}
              onClick={handleGenerateTripPreview}
              fullWidth
            >
              Generate Trip Preview
            </Button>
          ) : null}
        </Stack>
      </Stack>
    </Stack>
  );
};
