import React from 'react';
import {Avatar, CardHeader, Theme} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import {WithStyles} from '@mui/styles';
import Card from '@mui/material/Card';
import {SettingsApplications as SettingsApplicationsIcon} from '@mui/icons-material';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import moment from 'moment';
import {connect} from 'formik';
import {FormikProps} from 'formik/dist/types';
import {Listing} from '@onroadvantage/onroadvantage-api';
import {IPlanningAddFormValues} from './PlanningAdd';
import _ from 'lodash';

const styles = (theme: Theme) =>
  createStyles({
    container: {},
    header: {
      borderBottomColor: theme.palette.divider,
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
    },
    contentCard: {},
    chip: {
      margin: 2,
    },
  });

const stylesContent = (theme: Theme) =>
  createStyles({
    content: {
      paddingTop: theme.spacing(1),
    },
  });

interface IFormikProps {
  formik: FormikProps<IPlanningAddFormValues>;
}

interface IProps extends WithStyles<typeof styles> {
  drivers: Array<Listing>;
  orders: Array<Listing>;
  vehicles: Array<Listing>;
}

interface IContentRow extends WithStyles<typeof stylesContent> {
  title?: string;
}

const ContentRowComponent: React.FC<IContentRow> = ({classes, title}) => (
  <div className={classes.content}>{title}</div>
);

const ContentRow = withStyles(stylesContent)(ContentRowComponent);

const PlanningAddReviewComponent: React.FC<IProps & IFormikProps> = ({
  classes,
  drivers,
  formik,
  orders,
  vehicles,
}) => {
  return (
    <Card className={classes.container}>
      <CardHeader
        title="Review Planning Solution"
        avatar={
          <Avatar>
            <SettingsApplicationsIcon />
          </Avatar>
        }
      />
      <CardContent>
        <Card className={classes.contentCard} elevation={0}>
          <CardHeader className={classes.header} subheader="Details" />
          <CardContent>
            <ContentRow title={`Solution Name: ${formik.values.name}`} />
            <ContentRow
              title={`Contract Name: ${_.get(
                formik,
                'values.contract.label',
                '-'
              )}`}
            />
            <ContentRow
              title={`Start Date: ${moment(formik.values.startDate).format(
                'LLL'
              )}`}
            />
            <ContentRow
              title={`End Date: ${moment(formik.values.endDate).format('LLL')}`}
            />
          </CardContent>
        </Card>
        <Card className={classes.contentCard} elevation={0}>
          <CardHeader
            className={classes.header}
            subheader={`Orders (${formik.values.allocatedOrders.length})`}
          />
          <CardContent>
            {orders
              .filter((o) => {
                if (o && o.value) {
                  return formik.values.allocatedOrders.indexOf(o.value) !== -1;
                }
                return false;
              })
              .map((i) => (
                <Chip className={classes.chip} key={i.value} label={i.label} />
              ))}
          </CardContent>
        </Card>
        <Card className={classes.contentCard} elevation={0}>
          <CardHeader
            className={classes.header}
            subheader={`Vehicles (${formik.values.allocatedVehicles.length})`}
          />
          <CardContent>
            {vehicles
              .filter((o) => {
                if (o && o.value) {
                  return (
                    formik.values.allocatedVehicles.indexOf(o.value) !== -1
                  );
                }
                return false;
              })
              .map((i) => (
                <Chip className={classes.chip} key={i.value} label={i.label} />
              ))}
          </CardContent>
        </Card>
        <Card className={classes.contentCard} elevation={0}>
          <CardHeader
            className={classes.header}
            subheader={`Drivers (${formik.values.allocatedDrivers.length})`}
          />
          <CardContent>
            {drivers
              .filter((o) => {
                if (o && o.value) {
                  return formik.values.allocatedDrivers.indexOf(o.value) !== -1;
                }
                return false;
              })
              .map((i) => (
                <Chip className={classes.chip} key={i.value} label={i.label} />
              ))}
          </CardContent>
        </Card>
      </CardContent>
    </Card>
  );
};

// eslint-disable-next-line import/prefer-default-export
export const PlanningAddReview = withStyles(styles)(
  connect<IProps, IPlanningAddFormValues>(PlanningAddReviewComponent)
);
