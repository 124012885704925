import React from 'react';
import {DashboardDataStudio} from './DashboardDataStudio';

const dashboardStyle: React.CSSProperties = {
  display: 'flex',
  flex: 1,
};

export const DashboardDataStudioFullscreen = () => (
  <DashboardDataStudio dashboardStyle={dashboardStyle} />
);

export default DashboardDataStudioFullscreen;
