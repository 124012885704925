import React from 'react';
import {AuthenticationContainer} from '../AuthenticationContainer';
import {LoginForm} from './LoginForm';
import {AuthenticationVideo} from '../AuthenticationVideo';
import {AuthenticationAcknowledge} from '../AuthenticationAcknowledge';

export const Login = () => {
  React.useEffect(() => {
    document.title = 'Vantage | Login';
  }, []);

  return (
    <AuthenticationContainer>
      <LoginForm>
        <AuthenticationAcknowledge />
      </LoginForm>
      <AuthenticationVideo />
    </AuthenticationContainer>
  );
};
