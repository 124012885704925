import React from 'react';
import Lottie from 'react-lottie';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {
  AccountCircle,
  PlaylistAddCheck as RolesIcon,
} from '@mui/icons-material';

import vantageLogoAnimation from '../../img/json/vantageLogo.json';
import {WindowInfoConsumer} from '../../contexts';
import mobileLogo from '../../img/svg/vantageMobileLogo.svg';
import {authStore} from '../../store';
import {VersionStamp} from './VersionStamp';
import {Link} from 'react-router-dom';

const styles = (theme) => ({
  container: {},
  logo: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      width: '70%',
    },
  },
  profile: {
    paddingBottom: 0,
    marginLeft: 10,
    marginRight: 10,
  },
  user: {
    display: 'flex',
    displayDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: 1,
  },
  userIcon: {
    marginRight: theme.spacing(1),
    minWidth: 'fit-content',
  },
  userIconItem: {},
  userEmail: {},
  userEmailText: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 400,
    fontFamily: 'Roboto ,Helvetica, Arial, sans-serif',
    wordWrap: 'break-word',
    lineHeight: '1.5em',
  },
  roles: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  rolesIcon: {marginRight: theme.spacing(1), minWidth: 'fit-content'},
  rolesIconItem: {},
  rolesList: {
    position: 'relative',
    padding: 0,
  },
  roleName: {
    padding: 0,
  },
  roleNameText: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 400,
    fontFamily: 'Roboto ,Helvetica, Arial, sans-serif',
    wordWrap: 'break-word',
    lineHeight: '1.5em',
  },
  htmlTooltip: {
    position: 'absolute',
    marginTop: -5,
    backgroundColor: theme.palette.background.default,
    fontSize: 14,
    border: '1px solid #dadde9',
    '& b': {
      fontWeight: 'bold',
    },
  },
  appBarPlaceholder: {
    ...theme.mixins.toolbar,
  },
});

const emailMaxLength = 24;

function truncate(string) {
  if (string.length > emailMaxLength) {
    return `${string.substring(0, emailMaxLength)}...`;
  }
  return string;
}

class LeftSidebarTop extends React.Component {
  state = {
    arrowRef: null,
  };

  render() {
    const {classes} = this.props;
    const {auth} = authStore;
    return (
      <WindowInfoConsumer>
        {({isDesktop}) => (
          <div className={classes.container}>
            {!isDesktop && <div className={classes.appBarPlaceholder} />}
            <div className={classes.logo}>
              <Link to="/">
                {!isDesktop && (
                  <>
                    <img alt="Vantage Logo" src={mobileLogo} />
                  </>
                )}
                {isDesktop && (
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: vantageLogoAnimation,
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice',
                      },
                    }}
                    height={60}
                    width={250}
                    isStopped={false}
                    isClickToPauseDisabled
                  />
                )}
              </Link>
            </div>
            <VersionStamp />
            <List className={classes.profile}>
              <Tooltip
                placement="bottom"
                classes={{tooltip: classes.htmlTooltip}}
                TransitionComponent={Zoom}
                PopperProps={{
                  popperOptions: {
                    modifiers: {
                      arrow: {
                        enabled: Boolean(this.state.arrowRef),
                        element: this.state.arrowRef,
                      },
                    },
                  },
                }}
                title={
                  <React.Fragment>
                    <Typography className={classes.roleNameText}>
                      {auth.email}
                    </Typography>
                  </React.Fragment>
                }
              >
                <ListItem
                  className={classes.user}
                  dense
                  disablePadding
                  disableGutters
                >
                  <ListItemIcon className={classes.userIcon}>
                    <AccountCircle />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.userEmail}
                    primary={
                      <Typography className={classes.userEmailText}>
                        {truncate(auth.email)}
                      </Typography>
                    }
                  />
                </ListItem>
              </Tooltip>
              <Tooltip
                placement="bottom"
                classes={{
                  popper: classes.htmlPopper,
                  tooltip: classes.htmlTooltip,
                }}
                TransitionComponent={Zoom}
                PopperProps={{
                  popperOptions: {
                    modifiers: {
                      arrow: {
                        enabled: Boolean(this.state.arrowRef),
                        element: this.state.arrowRef,
                      },
                    },
                  },
                }}
                title={
                  <React.Fragment>
                    {auth &&
                      auth.roles &&
                      auth.roles.map((role) => {
                        const roleName =
                          role.name.charAt(0).toUpperCase() +
                          role.name.slice(1);
                        return (
                          <ListItem className={classes.roleName} key={roleName}>
                            <ListItemText
                              primary={
                                <Typography className={classes.roleNameText}>
                                  {roleName}
                                </Typography>
                              }
                            />
                          </ListItem>
                        );
                      })}
                  </React.Fragment>
                }
              >
                <ListItem
                  className={classes.roles}
                  dense
                  disablePadding
                  disableGutters
                >
                  <ListItemIcon className={classes.rolesIcon}>
                    <RolesIcon className={classes.rolesIconItem} />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.userEmail}
                    primary={
                      <Typography className={classes.userEmailText}>
                        User Roles
                      </Typography>
                    }
                  />
                </ListItem>
              </Tooltip>
            </List>
          </div>
        )}
      </WindowInfoConsumer>
    );
  }
}

export default withStyles(styles)(LeftSidebarTop);

export const leftSideBarStyles = styles;
