import React from 'react';
import {
  ITemplateTabControllerTab,
  TemplateTabs,
} from '../../../factory/template';
import {NotificationConfigWorkflowTaskContext} from './NotificationConfigWorkflowTaskContext';
import {NotificationConfigWorkflowTaskContractContacts} from './NotificationConfigWorkflowTaskContractContacts';
import {NotificationConfigWorkflowTaskCustomerContacts} from './NotificationConfigWorkflowTaskCustomerContacts';
import {NotificationConfigWorkflowTaskNodeContacts} from './NotificationConfigWorkflowTaskNodeContacts';
import {NotificationConfigContext} from '../NotificationConfigContext';
import {NotificationConfigWorkflowTaskVehicleGroupContacts} from './NotificationConfigWorkflowTaskVehicleGroupContacts';

export const NotificationConfigWorkflowTask: React.FC = () => {
  const {notificationConfigId} = React.useContext(NotificationConfigContext);
  const {notificationConfigTaskId, loading} = React.useContext(
    NotificationConfigWorkflowTaskContext
  );

  const [tabs] = React.useState<ITemplateTabControllerTab[]>([
    {
      title: 'Contract Contact',
      element: <NotificationConfigWorkflowTaskContractContacts />,
    },
    {
      title: 'Customer Contact',
      element: <NotificationConfigWorkflowTaskCustomerContacts />,
    },
    {
      title: 'Node Contact',
      element: <NotificationConfigWorkflowTaskNodeContacts />,
    },
    {
      title: 'Vehicle Group Contact',
      element: <NotificationConfigWorkflowTaskVehicleGroupContacts />,
    },
  ]);

  const path = React.useMemo(
    () =>
      `/notificationconfiglist/${notificationConfigId}/task/${notificationConfigTaskId}`,
    [notificationConfigId, notificationConfigTaskId]
  );

  return <TemplateTabs tabs={tabs} path={path} loading={loading} />;
};
