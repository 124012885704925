import React from 'react';
import Paper from '@mui/material/Paper';
import clone from 'rfdc';
import {ILaneCard, ILaneFilter, VantageBoard} from '../../board';
import {
  getBoardData,
  getFilteredCardData,
  getLaneFilters,
  TGetCardMoveHandlerResponse,
} from '../../../service/laneUtils';
import {TFilterValue} from '../../../stores/context';

interface IProps {
  all: Array<ILaneCard>;
  allocated: Array<ILaneCard>;
  available: Array<ILaneCard>;
  title?: string;
  updateAllocated: (data: Array<ILaneCard>) => void;
  updateAvailable: (data: Array<ILaneCard>) => void;
}

// eslint-disable-next-line import/prefer-default-export
export const PlanningAddBoard: React.FC<IProps> = ({
  all,
  allocated,
  available,
  title,
  updateAllocated,
  updateAvailable,
}) => {
  // filters
  const [allocatedFilter, setAllocatedFilter] = React.useState<string>('');
  const [availableFilter, setAvailableFilter] = React.useState<string>('');

  const laneFilters: Array<ILaneFilter> = getLaneFilters(
    allocatedFilter,
    availableFilter
  );

  const {filteredAllocatedData, filteredAvailableData} = getFilteredCardData(
    allocated,
    available,
    allocatedFilter,
    availableFilter
  );

  const boardData = getBoardData(filteredAllocatedData, filteredAvailableData);

  // eslint-disable-next-line no-undef
  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filterValue: TFilterValue = e.target.value;
    let changeFn: ((value: string) => void) | undefined;

    switch (e.target.name) {
      case 'allocated':
        changeFn = setAllocatedFilter;
        break;
      case 'available':
        changeFn = setAvailableFilter;
        break;
      default:
        throw new Error('filter not found');
    }

    changeFn(filterValue);
  };

  const handleCardMove: TGetCardMoveHandlerResponse = (
    fromLaneId,
    toLaneId,
    cardId,
    index
  ) => {
    let allocatedIndex: number;
    let availableIndex: number;

    const allCards = clone()(all);
    const allocatedCards = clone()(allocated);
    const availableCards = clone()(available);

    const card = allCards.find((c) => c.id === cardId);

    if (!card) {
      // TODO handle error
      throw new Error(`card not found ${cardId}`);
    }

    if (fromLaneId === toLaneId) {
      // order of cards in same lane
      // TODO handle???
    } else {
      // from one lane to the other
      switch (toLaneId) {
        case 'available':
          availableIndex = index;
          allocatedIndex = allocatedCards.findIndex((c) => c.id === cardId);
          allocatedCards.splice(allocatedIndex, 1);
          availableCards.splice(availableIndex, 0, card);
          break;
        case 'allocated':
          availableIndex = available.findIndex((c) => c.id === cardId);
          allocatedIndex = index;
          allocatedCards.splice(allocatedIndex, 0, card);
          availableCards.splice(availableIndex, 1);
          break;
        default:
          throw new Error('should not get here ever');
      }

      updateAllocated(allocatedCards);
      updateAvailable(availableCards);
    }
  };

  const handleAddAll = () => {
    const allocatedCards = clone()(allocated);
    const filtered: ILaneCard[] = filteredAvailableData.map((v) => ({
      id: v.id,
      title: v.title,
      description: v.description,
      label: v.label,
    }));
    updateAllocated(allocatedCards.concat(filtered));
    updateAvailable([]);
  };

  const handleRemoveAll = () => {
    // TODO possibly the same issue as with Add?
    updateAllocated([]);
    updateAvailable(clone()(all));
  };

  return (
    <Paper elevation={0}>
      <VantageBoard
        title={title}
        addAll={handleAddAll}
        removeAll={handleRemoveAll}
        data={boardData}
        onCardMoveAcrossLanes={handleCardMove}
        onFilterChange={handleFilterChange}
        filters={laneFilters}
      />
    </Paper>
  );
};
