import React from 'react';
import {Filter, Sorting} from '@devexpress/dx-react-grid';
import {
  Listing,
  WebPlanningBoardOrder,
  WebPlanningBoardTripStopOrder,
} from '@onroadvantage/onroadvantage-api';
import _ from 'lodash';
import {findCommonElements} from '../../../../factory/template';

export interface usePlanningBoardOrderListParams {
  orders: (WebPlanningBoardOrder | WebPlanningBoardTripStopOrder)[] | undefined;
  selectedPlanningSkillListings: Listing[];
}

const initialSorting: Sorting = {
  columnName: 'deliverByDatetime',
  direction: 'desc',
};

export const usePlanningBoardOrders = ({
  orders,
  selectedPlanningSkillListings,
}: usePlanningBoardOrderListParams) => {
  const [list, setList] = React.useState<
    (WebPlanningBoardOrder | WebPlanningBoardTripStopOrder)[]
  >(orders ?? []);
  const [filters, setFilters] = React.useState<Filter[]>();
  const [sorting, setSorting] = React.useState<Sorting[]>([initialSorting]);

  const handleColumnFilter = React.useCallback(
    (newFilters: Filter[]) =>
      (order: WebPlanningBoardOrder | WebPlanningBoardTripStopOrder) =>
        !newFilters ||
        newFilters.length === 0 ||
        !newFilters
          .map((f) => {
            if (f.value) {
              const fValue = f.value.toLowerCase();
              if (f.columnName === 'orderNumber') {
                return order.orderNumber?.toLowerCase()?.includes(fValue);
              }
              if (f.columnName === 'upliftPoint') {
                return order.upliftPoint?.name?.toLowerCase()?.includes(fValue);
              }
              if (f.columnName === 'offloadPoint') {
                return order.offloadPoint?.name
                  ?.toLowerCase()
                  ?.includes(fValue);
              }
              if (f.columnName === 'planningSkill') {
                return order.planningOrder?.skill
                  ?.toLowerCase()
                  ?.includes(fValue);
              }
              if (f.columnName === 'deliverByDatetime') {
                return order.deliverByDatetime?.toISOString()?.includes(fValue);
              }
              if (f.columnName === 'orderTrackingNumber') {
                return order.orderTrackingNumber
                  ?.toLowerCase()
                  ?.includes(fValue);
              } else return true;
            } else return true;
          })
          .includes(false),
    []
  );

  const handleColumnSort = React.useCallback(
    (newSorting: Sorting[]) =>
      (
        a: WebPlanningBoardOrder | WebPlanningBoardTripStopOrder,
        b: WebPlanningBoardOrder | WebPlanningBoardTripStopOrder
      ) => {
        const column = newSorting[0]?.columnName;
        const direction = newSorting[0]?.direction;

        if (!column || !direction) {
          return 0;
        }

        switch (column) {
          case 'orderNumber': {
            if (!a.orderNumber || !b.orderNumber) {
              return 0;
            }
            if (a.orderNumber > b.orderNumber) {
              return direction === 'asc' ? -1 : 1;
            }
            return direction === 'asc' ? 1 : -1;
          }
          case 'customerReferenceNumber': {
            if (!a.customerReferenceNumber || !b.customerReferenceNumber) {
              return 0;
            }
            if (a.customerReferenceNumber > b.customerReferenceNumber) {
              return direction === 'asc' ? -1 : 1;
            }
            return direction === 'asc' ? 1 : -1;
          }
          case 'upliftPoint': {
            if (!a.upliftPoint?.name || !b.upliftPoint?.name) {
              return 0;
            }
            if (a.upliftPoint.name > b.upliftPoint.name) {
              return direction === 'asc' ? -1 : 1;
            }
            return direction === 'asc' ? 1 : -1;
          }
          case 'offloadPoint': {
            if (!a.offloadPoint?.name || !b.offloadPoint?.name) {
              return 0;
            }
            if (a.offloadPoint.name > b.offloadPoint.name) {
              return direction === 'asc' ? -1 : 1;
            }
            return direction === 'asc' ? 1 : -1;
          }
          case 'deliverByDatetime': {
            if (!a.deliverByDatetime || !b.deliverByDatetime) {
              return 0;
            }
            if (a.deliverByDatetime > b.deliverByDatetime) {
              return direction === 'asc' ? -1 : 1;
            }
            return direction === 'asc' ? 1 : -1;
          }
          case 'planningSkill': {
            if (!a.planningOrder?.skill || !b.planningOrder?.skill) {
              return 0;
            }
            if (a.planningOrder?.skill > b.planningOrder?.skill) {
              return direction === 'asc' ? -1 : 1;
            }
            return direction === 'asc' ? 1 : -1;
          }
          case 'orderTrackingNumber': {
            if (!a.orderTrackingNumber || !b.orderTrackingNumber) {
              return 0;
            }
            if (a.orderTrackingNumber > b.orderTrackingNumber) {
              return direction === 'asc' ? -1 : 1;
            }
            return direction === 'asc' ? 1 : -1;
          }
          default:
            return 0;
        }
      },
    []
  );

  const handleFilterOrders = React.useCallback(
    (newFilters: Filter[]) => {
      setList(orders?.filter(handleColumnFilter(newFilters)) ?? []);
      setFilters(newFilters);
    },
    [handleColumnFilter, orders]
  );

  const handleSortingChange = React.useCallback(
    (newSorting: Sorting[]) => {
      setList((prevList) => prevList.sort(handleColumnSort(newSorting)));
      setSorting(newSorting);
    },
    [handleColumnSort]
  );

  // Set list and filter with selectedPlanningSkills or if there are any column filters
  React.useEffect(() => {
    const planningSkills =
      selectedPlanningSkillListings.length > 0
        ? selectedPlanningSkillListings.map(({label}) => label?.split(' - ')[0])
        : undefined;

    if (!orders) {
      setList([]);
    } else if (planningSkills && planningSkills.length > 0) {
      setList(
        orders
          .filter(({planningOrder}) => {
            const planningOrderSkills = planningOrder?.skill?.split(',');
            return findCommonElements(
              planningOrderSkills ?? [],
              planningSkills
            );
          })
          .sort(handleColumnSort([initialSorting]))
      );
    } else {
      setList(orders.sort(handleColumnSort([initialSorting])));
    }
  }, [selectedPlanningSkillListings, orders, handleColumnSort]);

  return {
    list,
    filters,
    sorting,
    onSortingChange: handleSortingChange,
    onFiltersChange: _.debounce(handleFilterOrders, 700),
  };
};
