import React from 'react';
import {useHistory} from 'react-router-dom';
import {
  ApiContractGroupGetRequest,
  ContractGroup as ContractGroupType,
  ContractGroupListingResponse,
  ContractGroupUpdate,
  ContractGroupNestedContract,
} from '@onroadvantage/onroadvantage-api';
import {FormikHelpers, FormikProps} from 'formik/dist/types';
import {useAppNotifications} from '../../contexts';
import {contractGroupApi} from '../../api';
import {
  TemplateTableContextProps,
  TLoadList,
  useTemplateTable,
} from '../../factory/template';
import {IContractGroupForm} from './ContractGroupForm';

export interface ContractGroupContextProps
  extends TemplateTableContextProps<
    ContractGroupType,
    ContractGroupListingResponse
  > {
  onDetailsFormSubmit: (
    values: IContractGroupForm,
    formikHelpers: FormikHelpers<IContractGroupForm>
  ) => void;
  onUpdateContractGroup: (updatedContractGroup: ContractGroupUpdate) => void;
  setContractGroupId: (value: number | undefined) => void;
  submitting: boolean;
  contractGroup?: ContractGroupType;
  contractGroupId?: number;
  detailsRef?: React.Ref<FormikProps<IContractGroupForm>>;
}

export const ContractGroupContext =
  React.createContext<ContractGroupContextProps>({
    // Template Table Defaults
    loading: false,
    list: [],
    currentPage: 1,
    hasPermission: {edit: false},
    // ContractGroup
    loadList: async () => {},
    onDetailsFormSubmit: () => null,
    onUpdateContractGroup: () => null,
    setContractGroupId: () => null,
    submitting: false,
  });

interface ContractGroupContextProviderProps {
  contractGroupId?: number;
}

export const ContractGroupContextProvider: React.FC<
  ContractGroupContextProviderProps
> = ({children}) => {
  const history = useHistory();
  const notify = useAppNotifications();
  // Template Table
  const [
    {
      currentPage,
      pageSize,
      pageTotal,
      sorting,
      hasPermission,
      itemTotal,
      list,
      loading,
      loadingSingleItem,
      filters,
    },
    {
      // Getters
      getDownloads,
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // Setters
      cleanupList,
      setLoading,
      setLoadingSingleItem,
    },
  ] = useTemplateTable<ContractGroupType, ApiContractGroupGetRequest>({
    editPermission: 'Edit ContractGroup',
    addPermission: 'Add ContractGroup',
    deletePermission: 'Delete ContractGroup',
    downloadPermission: 'ContractGroup ListDownload',
    viewPermission: 'ContractGroup List',
  });

  const loadList = React.useCallback<TLoadList<ContractGroupListingResponse>>(
    async (options) => {
      setLoading(true);
      try {
        const requestObj = getRequestObj(['name'], options);
        const response = await contractGroupApi.apiContractGroupGet(requestObj);
        return getResponse(response, options);
      } catch (e) {
        notify('error', e.message ?? 'Failed to load contract Group list');
      } finally {
        setLoading(false);
      }
    },
    [getRequestObj, getResponse, notify, setLoading]
  );

  const handleDelete = React.useCallback(
    async (row: ContractGroupType) => {
      setLoading(true);
      try {
        if (row.id) {
          await contractGroupApi.apiContractGroupContractGroupIdDelete({
            contractGroupId: row.id,
          });
          await loadList();
          notify('success', 'Deleted contract Group');
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to delete');
      } finally {
        setLoading(false);
      }
    },
    [loadList, notify, setLoading]
  );

  const handleDownload = React.useCallback(
    () =>
      getDownloads('contractGroups', loadList, [
        {name: 'Contract Group Name', path: 'name'},
      ]),
    [getDownloads, loadList]
  );

  const handleAdd = React.useCallback(
    () => history.push('/contractgrouplist/add'),
    [history]
  );

  const handleNavigate = React.useCallback(
    (row) => history.push(`/contractgrouplist/${row.id}`),
    [history]
  );
  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  // Forms
  const [contractGroup, setContractGroup] = React.useState<
    ContractGroupType | undefined
  >();
  const [contractGroupId, setContractGroupId] = React.useState<
    number | undefined
  >();
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const detailsRef = React.useRef<FormikProps<IContractGroupForm>>(null);

  const loadContractGroup = React.useCallback(async () => {
    setLoadingSingleItem(true);
    try {
      if (contractGroupId) {
        const response =
          await contractGroupApi.apiContractGroupContractGroupIdGet({
            contractGroupId,
          });
        setContractGroup(response);
      } else {
        setContractGroup(undefined);
      }
    } catch (e) {
      notify('error', e.message ?? 'Failed to load contract Group');
    } finally {
      setLoadingSingleItem(false);
    }
  }, [setLoadingSingleItem, contractGroupId, notify]);

  const handleDetailsFormSubmit = React.useCallback(
    async (
      values: IContractGroupForm,
      formikHelpers: FormikHelpers<IContractGroupForm>
    ) => {
      setSubmitting(true);
      try {
        const {...otherValues} = values;
        const newValues: ContractGroupType = {...otherValues};

        if (contractGroupId && contractGroup) {
          await contractGroupApi.apiContractGroupContractGroupIdPatch({
            contractGroupId,
            body: {
              contracts: contractGroup?.contracts?.map(({id}) => ({
                id,
              })) as ContractGroupNestedContract[],
              users: contractGroup?.users,
              name: newValues.name,
            },
          });
        } else {
          await contractGroupApi.apiContractGroupPost({
            body: newValues,
          });
        }
        history.push('/contractgrouplist');
        notify(
          'success',
          `${contractGroupId ? 'Updated' : 'Added'} ContractGroup`
        );
      } catch (e) {
        notify(
          'error',
          e.message ??
            `Failed to ${contractGroupId ? 'update' : 'add'} contract Group`
        );
      } finally {
        formikHelpers.setSubmitting(false);
        setSubmitting(false);
      }
    },
    [contractGroupId, contractGroup, history, notify]
  );

  const handleUpdateContractGroup = React.useCallback(
    async (updatedContractGroup: ContractGroupUpdate) => {
      setLoadingSingleItem(true);
      try {
        if (contractGroupId) {
          await contractGroupApi.apiContractGroupContractGroupIdPatch({
            contractGroupId,
            body: updatedContractGroup,
          });
          notify('success', 'Updated Contract Group');
          await loadContractGroup();
        }
      } catch (e) {
        notify('error', e.message ?? 'Failed to update contract group');
      } finally {
        setLoadingSingleItem(false);
      }
    },
    [contractGroupId, loadContractGroup, notify, setLoadingSingleItem]
  );

  const value: ContractGroupContextProps = {
    // Template Table
    list,
    loadList,
    hasPermission,
    loading: loading || loadingSingleItem,
    cleanupList,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onAdd: hasPermission.add ? handleAdd : undefined,
    onDownload: hasPermission.download ? handleDownload : undefined,
    onNavigate: handleNavigate,
    onDelete: hasPermission.delete ? handleDelete : undefined,
    onRefresh: handleRefresh,
    // Forms
    onDetailsFormSubmit: handleDetailsFormSubmit,
    onUpdateContractGroup: handleUpdateContractGroup,
    setContractGroupId,
    submitting,
    contractGroup,
    contractGroupId,
    detailsRef,
  };

  React.useEffect(() => {
    loadContractGroup();
    return () => setContractGroup(undefined);
  }, [loadContractGroup]);

  return (
    <ContractGroupContext.Provider value={value}>
      {children}
    </ContractGroupContext.Provider>
  );
};
