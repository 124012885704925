import React from 'react';
import {Stack} from '@mui/material';
import {FormikTextField, FormikTimePicker} from '../../../formik';

export const ContractTripTimesFormFields: React.FC = () => {
  return (
    <Stack>
      <FormikTextField
        name="shortBreakTrigger"
        placeholder="Enter the contract short break trigger"
        label="Short Break Trigger"
        type="number"
        fullWidth
      />
      <FormikTextField
        name="shortBreakDuration"
        placeholder="Enter the contract short break duration"
        label="Short Break Duration"
        type="number"
        fullWidth
      />
      <FormikTextField
        name="longBreakTrigger"
        placeholder="Enter the contract long break trigger"
        label="Long Break Trigger"
        type="number"
        fullWidth
      />
      <FormikTextField
        name="longBreakDuration"
        placeholder="Enter the contract long break duration"
        label="Long Break Duration"
        type="number"
        fullWidth
      />
      <FormikTextField
        name="maxShiftDrivingTime"
        placeholder="Enter the contract max shift duration"
        label="Max Shift Duration"
        type="number"
        fullWidth
      />
      <FormikTextField
        name="shiftDuration"
        placeholder="Enter the contract shift duration"
        label="Shift Duration"
        type="number"
        fullWidth
      />
      <FormikTextField
        name="dailyBreakDuration"
        placeholder="Enter the contract daily break duration"
        label="Daily Break Duration"
        type="number"
        fullWidth
      />
      <FormikTextField
        name="maxWeeklyDuty"
        placeholder="Enter the contract max weekly duty"
        label="Max Weekly Duty"
        type="number"
        fullWidth
      />
      <FormikTextField
        name="weeklyBreak"
        placeholder="Enter the contract weekly break"
        label="Weekly Break"
        type="number"
        fullWidth
      />
      <FormikTimePicker
        name="drivingBanStart"
        label="Driving Ban Start"
        disableDefaultValue
        fullWidth
      />
      <FormikTimePicker
        name="drivingBanEnd"
        label="Driving Ban End"
        disableDefaultValue
        fullWidth
      />
      <FormikTextField
        name="actualCalculationPreBuffer"
        placeholder="Enter the contract actual calculation pre buffer"
        label="Actual Calculation Pre Buffer"
        type="number"
        fullWidth
      />
      <FormikTextField
        name="actualCalculationPostBuffer"
        placeholder="Enter the contract actual calculation post buffer"
        label="Actual Calculation Post Buffer"
        type="number"
        fullWidth
      />
      <FormikTextField
        name="targetHoursPerDay"
        placeholder="Enter the contract target hours per day"
        label="Target Hours Per Day"
        type="number"
        fullWidth
      />
      <FormikTextField
        name="targetHoursPerVehiclePerDay"
        placeholder="Enter the contract target hours per vehicle per day"
        label="Target Hours Per Vehicle Per Day"
        type="number"
        fullWidth
      />
      <FormikTextField
        name="targetKmPerDay"
        placeholder="Enter the contract target km per day"
        label="Target Km Per Day"
        type="number"
        fullWidth
      />
      <FormikTextField
        name="targetKmPerVehiclePerDay"
        placeholder="Enter the contract target km per vehicle per day"
        label="Target Km Per Vehicle Per Day"
        type="number"
        fullWidth
      />
      <FormikTextField
        name="driverTripQueryPreToleranceHours"
        placeholder="Enter the contract driver trip query pre tolerance hours"
        label="Driver Trip Query Pre Tolerance Hours"
        type="number"
        fullWidth
      />
      <FormikTextField
        name="driverTripQueryPostToleranceHours"
        placeholder="Enter the contract driver trip query post tolerance hours"
        label="Driver Trip Query Post Tolerance Hours"
        type="number"
        fullWidth
      />
    </Stack>
  );
};
