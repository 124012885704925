import {makeStyles, createStyles} from '@mui/styles';

const OrderControllerStyles = () => {
  return createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      minHeight: 10,
    },
    root: {},
  });
};

export const useOrderControllerStyles = makeStyles(OrderControllerStyles, {
  name: 'OrderControllerStyles',
});
