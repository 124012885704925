import React from 'react';
import {useHistory} from 'react-router-dom';
import {
  ApiVehicleTypeGetRequest,
  Listing,
  VehicleType as VehicleTypeType,
  VehicleTypeList,
  VehicleTypeUpdate,
} from '@onroadvantage/onroadvantage-api';
import {FormikHelpers, FormikProps} from 'formik/dist/types';
import {vehicleTypeApi} from '../../api';
import {
  TemplateTableContextProps,
  TLoadList,
  useTemplateTable,
} from '../../factory/template';
import {LuxonService, RoleService} from '../../service';
import {useAppNotifications} from '../../contexts';

export type CompartmentStrategyTypes =
  | 'front_to_back'
  | 'back_to_front'
  | 'custom';

export const compartmentStrategyOptions: CompartmentStrategyTypes[] = [
  'front_to_back',
  'back_to_front',
  'custom',
];

export const compartmentStrategyListingOptions = compartmentStrategyOptions.map(
  (option) => ({
    label: option
      .split('_')
      .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
      .join(' '),
    value: option,
  })
);

export const getStrategy = (type?: CompartmentStrategyTypes | string) =>
  compartmentStrategyListingOptions.find(({value}) => value === type);

export interface IVehicleTypeForm {
  availableEnd?: Date | null;
  availableStart?: Date | null;
  description: string;
  compartmentLoadingStrategy: ReturnType<typeof getStrategy>;
  compartmentOffloadingStrategy: ReturnType<typeof getStrategy>;
  externalGroupId?: number;
  externalId?: number;
  externalSiteId?: number;
  name: string;
  planningDistanceCost: number;
  planningFixedCost: number;
  planningServiceCost: number;
  planningTimeCost: number;
  planningWaitCost: number;
  planningSkills?: Listing[] | undefined;
  speedTables?: Listing[] | undefined;
}

export interface VehicleTypeContextProps
  extends TemplateTableContextProps<VehicleTypeType, VehicleTypeList> {
  loadVehicleType: () => Promise<void>;
  onDetailsFormSubmit: (
    values: IVehicleTypeForm,
    formikHelpers: FormikHelpers<IVehicleTypeForm>
  ) => void;
  setVehicleTypeId: (value: number | undefined) => void;
  submitting: boolean;
  hasEditPermission?: boolean;
  vehicleType?: VehicleTypeType;
  vehicleTypeId?: number;
  detailsRef?: React.Ref<FormikProps<IVehicleTypeForm>>;
}

export const VehicleTypeContext = React.createContext<VehicleTypeContextProps>({
  // Template Table Defaults
  loading: false,
  list: [],
  currentPage: 1,
  // VehicleType
  loadList: async () => {},
  loadVehicleType: async () => {},
  cleanupList: () => null,
  onDetailsFormSubmit: () => null,
  setVehicleTypeId: () => null,
  submitting: false,
});

interface VehicleTypeContextProviderProps {
  vehicleTypeId?: number;
}

export const VehicleTypeContextProvider: React.FC<
  VehicleTypeContextProviderProps
> = ({children}) => {
  const history = useHistory();
  const notify = useAppNotifications();
  // Template Table
  const [
    {
      // States
      loadingSingleItem,
      loading,
      list,
      filters,
      hasPermission,
      currentPage,
      pageSize,
      pageTotal,
      itemTotal,
      sorting,
    },
    {
      // Getters
      getDownloads,
      getRequestObj,
      getResponse,
      // Handlers
      handleCurrentPageChange,
      handleFiltersChange,
      handlePageSizeCountsChange,
      handleSortingChange,
      // States
      cleanupList,
      setLoading,
      setLoadingSingleItem,
    },
  ] = useTemplateTable<VehicleTypeType, ApiVehicleTypeGetRequest>({
    editPermission: 'Edit VehicleType',
    addPermission: 'Add VehicleType',
    deletePermission: 'Delete VehicleType',
    downloadPermission: 'VehicleType ListDownload',
    viewPermission: 'VehicleType List',
  });
  // VehicleType
  const [vehicleType, setVehicleType] = React.useState<
    VehicleTypeType | undefined
  >();
  const [vehicleTypeId, setVehicleTypeId] = React.useState<
    number | undefined
  >();
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [hasEditPermission] = React.useState<boolean>(
    RoleService.hasPermission('Edit VehicleType', 'Edit')
  );

  const detailsRef = React.useRef<FormikProps<IVehicleTypeForm>>(null);

  const loadList = React.useCallback<TLoadList<VehicleTypeList>>(
    async (options) => {
      setLoading(true);
      try {
        const requestObj = getRequestObj(['name', 'description'], options);
        const response = await vehicleTypeApi.apiVehicleTypeGet(requestObj);
        return getResponse(response, options);
      } catch (e) {
        notify('error', 'Failed to load vehicle type list');
      } finally {
        setLoading(false);
      }
    },
    [getRequestObj, getResponse, notify, setLoading]
  );

  const loadVehicleType = React.useCallback(async () => {
    setLoadingSingleItem(true);
    try {
      if (vehicleTypeId) {
        const response = await vehicleTypeApi.apiVehicleTypeVehicleTypeIdGet({
          vehicleTypeId,
        });
        setVehicleType(response);
      } else {
        setVehicleType(undefined);
      }
    } catch (e) {
      notify('error', 'Failed to load vehicle type');
    } finally {
      setLoadingSingleItem(false);
    }
  }, [notify, setLoadingSingleItem, vehicleTypeId]);

  const handleDetailsFormSubmit = React.useCallback(
    async (
      values: IVehicleTypeForm,
      formikHelpers: FormikHelpers<IVehicleTypeForm>
    ) => {
      setSubmitting(true);
      try {
        const {
          availableEnd,
          availableStart,
          planningSkills,
          speedTables,
          compartmentLoadingStrategy,
          compartmentOffloadingStrategy,
          ...otherValues
        } = values;
        const newValues: VehicleTypeUpdate = {
          ...otherValues,
          availableEnd: availableEnd
            ? LuxonService.fromLocalDateToServerTimeOnly(availableEnd)
            : undefined,
          availableStart: availableStart
            ? LuxonService.fromLocalDateToServerTimeOnly(availableStart)
            : undefined,
          compartmentLoadingStrategy:
            compartmentLoadingStrategy?.value as string,
          compartmentOffloadingStrategy:
            compartmentOffloadingStrategy?.value as string,
          planningSkillIds:
            planningSkills?.map(({value}) => !!value) &&
            planningSkills?.map(({value}) => value as number),
          speedTableIds:
            speedTables?.map(({value}) => !!value) &&
            speedTables?.map(({value}) => value as number),
        };
        if (vehicleTypeId) {
          await vehicleTypeApi.apiVehicleTypeVehicleTypeIdPatch({
            vehicleTypeId: vehicleTypeId,
            body: newValues,
          });
        } else {
          await vehicleTypeApi.apiVehicleTypePost({
            body: newValues,
          });
        }
        notify(
          'success',
          `${vehicleTypeId ? 'Updated' : 'Added'} vehicle type`
        );
        history.push('/vehicletypelist');
      } catch (e) {
        notify(
          'error',
          `Failed to ${vehicleTypeId ? 'update' : 'add'} vehicle type`
        );
      } finally {
        formikHelpers.setSubmitting(false);
        setSubmitting(false);
      }
    },
    [history, notify, vehicleTypeId]
  );

  const handleDelete = React.useCallback(
    async (row: VehicleTypeType) => {
      setLoading(true);
      try {
        if (row.id) {
          await vehicleTypeApi.apiVehicleTypeVehicleTypeIdDelete({
            vehicleTypeId: row.id,
          });
          await loadList();
          notify('success', 'Vehicle Type Deleted');
        }
      } catch (e) {
        notify('error', 'Failed to delete vehicle type');
      } finally {
        setLoading(false);
      }
    },
    [loadList, notify, setLoading]
  );

  const handleDownload = React.useCallback(async () => {
    getDownloads('vehicleTypes', loadList, [
      {name: 'Name', path: 'name'},
      {name: 'Description', path: 'description'},
      {name: 'ExternalId', path: 'externalId'},
      {name: 'ExternalSiteId', path: 'externalSiteId'},
      {name: 'ExternalGroupId', path: 'externalGroupId'},
      {name: 'Planning Fixed Cost', path: 'planningFixedCost'},
      {name: 'Planning Distance Cost', path: 'planningDistanceCost'},
      {name: 'Planning Time Cost', path: 'planningTimeCost'},
      {name: 'Planning Service Cost', path: 'planningServiceCost'},
      {name: 'Planning Wait Cost', path: 'planningWaitCost'},
    ]);
  }, [getDownloads, loadList]);

  const handleAdd = React.useCallback(
    () => history.push('/vehicletypelist/add'),
    [history]
  );

  const handleNavigate = React.useCallback(
    (row: VehicleTypeType) => history.push(`/vehicletypelist/${row.id}`),
    [history]
  );

  const handleRefresh = React.useCallback(
    async () => await loadList(),
    [loadList]
  );

  const value: VehicleTypeContextProps = {
    // Template Table
    loading: loadingSingleItem || loading,
    list,
    currentPage,
    filters,
    itemTotal,
    pageSize,
    pageTotal,
    sorting,
    hasPermission,
    onAdd: hasPermission.add ? handleAdd : undefined,
    onDownload: hasPermission.download ? handleDownload : undefined,
    onNavigate: handleNavigate,
    onDelete: hasPermission.delete ? handleDelete : undefined,
    onFiltersChange: handleFiltersChange,
    onCurrentPageChange: handleCurrentPageChange,
    onPageSizeCountsChange: handlePageSizeCountsChange,
    onSortingChange: handleSortingChange,
    onRefresh: handleRefresh,
    // VehicleType
    loadList,
    loadVehicleType,
    cleanupList,
    onDetailsFormSubmit: handleDetailsFormSubmit,
    setVehicleTypeId,
    submitting,
    vehicleType,
    vehicleTypeId,
    detailsRef,
    hasEditPermission,
  };

  React.useEffect(() => {
    loadVehicleType();
    return () => setVehicleType(undefined);
  }, [loadVehicleType]);

  return (
    <VehicleTypeContext.Provider value={value}>
      {children}
    </VehicleTypeContext.Provider>
  );
};
