import {MasterTripDebriefSnapshotData} from '@onroadvantage/onroadvantage-api';
import {DateTime} from 'luxon';
import {isNegativeNumber} from '../../../factory/template';

export const getTatThreshold = (
  masterTripDebriefData: MasterTripDebriefSnapshotData | null | undefined
) => {
  const plannedTripStart =
    masterTripDebriefData?.overrideData?.plannedStartTime ??
    masterTripDebriefData?.snapshotData?.plannedStartTime;
  const plannedTripEnd =
    masterTripDebriefData?.overrideData?.plannedEndTime ??
    masterTripDebriefData?.snapshotData?.plannedEndTime;

  let plannedTat = 0;

  if (plannedTripStart && plannedTripEnd) {
    const plannedTatDiff = DateTime.fromJSDate(plannedTripEnd)
      .diff(DateTime.fromJSDate(plannedTripStart))
      .shiftTo('minutes');

    plannedTat = plannedTatDiff.minutes;
  }

  const overrideStops = masterTripDebriefData?.overrideData?.stops;
  const snapshotStops = masterTripDebriefData?.snapshotData?.stops;

  let actualTripStart: Date | null | undefined;
  let actualTripEnd: Date | null | undefined;
  let actualTat = 0;

  if (snapshotStops && snapshotStops.length > 0) {
    actualTripStart = snapshotStops[0].tripStopExecution?.mobileDepartureTime;
    actualTripEnd =
      snapshotStops[snapshotStops.length - 1].tripStopExecution
        ?.mobileArrivalTime;
  }

  if (overrideStops && overrideStops.length > 0) {
    actualTripStart =
      overrideStops[0].tripStopExecution?.mobileDepartureTime ??
      actualTripStart;
    actualTripEnd =
      overrideStops[overrideStops.length - 1].tripStopExecution
        ?.mobileArrivalTime ?? actualTripEnd;
  }

  if (actualTripStart && actualTripEnd) {
    const actualTatDiff = DateTime.fromJSDate(actualTripEnd)
      .diff(DateTime.fromJSDate(actualTripStart))
      .shiftTo('minutes');
    actualTat = actualTatDiff.minutes;
  }

  const tatThreshold = Math.floor(actualTat - plannedTat);
  const tatThresholdAbs = Math.abs(tatThreshold);

  return {
    plannedTat,
    actualTat,
    tatThreshold,
    tatThresholdAbs,
    isNegativeThreshold: isNegativeNumber(tatThreshold),
    displayTatThreshold:
      tatThresholdAbs === 0
        ? '0'
        : `${tatThresholdAbs} minutes (${
            isNegativeNumber(tatThreshold) ? 'less' : 'more'
          } than planned)`,
  };
};
