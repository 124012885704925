import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';

const RecipientDetailsStyles = (theme: Theme) =>
  createStyles({
    details: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1.5rem',
      margin: '0 1rem',
      [theme.breakpoints.down('md')]: {
        margin: '0 0.5rem',
      },
    },
  });

export const useRecipientDetailsStyles = makeStyles(RecipientDetailsStyles, {
  name: 'RecipientDetailsStyles',
});
