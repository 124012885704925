import React, {useEffect, useState, useCallback, useRef} from 'react';
import QRCode from 'qrcode.react';
import {UserService} from '../../service';
import {DriverContext} from './DriverContext';
import {useDownloadQRAsPDF} from '../trip/tripSummary/useDownloadQrAsPdf';
import {useAppNotifications} from '../../contexts';
import {VantageDialog, IVantageDialogRef} from '../dialog';

export const DriverQrDialog: React.FC = () => {
  const {startDownload, setStartDownload, selectedDriver, userId} =
    React.useContext(DriverContext);
  const [userKey, setUserKey] = useState('');
  const [isDownloading, setIsDownloading] = useState(false);
  const {downloadQRasPDF, qrRef} = useDownloadQRAsPDF(userKey, selectedDriver);
  const notify = useAppNotifications();
  const dialogRef = useRef<IVantageDialogRef>(null);

  const generateUserQr = useCallback(async () => {
    if (!userId) {
      notify('warning', 'User ID is not available. Cannot generate QR.');
      return;
    }

    try {
      const response = await UserService.getUserQr(userId);

      if (!response.data) {
        notify('warning', 'Received empty response. Cannot generate QR.');
        return;
      }

      const data = await response.data;
      setUserKey(data.key);
      notify('success', 'QR Generated Successfully');
      dialogRef.current?.openDialog(); // Open the dialog when the key is set
    } catch (error) {
      let errorMessage = 'Failed to generate QR';

      // error type checks,
      if (error.response) {
        errorMessage = `Received ${error.response.status} from server.`;
      } else if (error.request) {
        errorMessage = 'No response received from server.';
      } else {
        errorMessage = 'An error occurred while generating the QR code.';
      }

      notify('error', errorMessage);
    }
  }, [userId, notify]);

  const handleDownload = useCallback(async () => {
    try {
      if (selectedDriver && userKey) {
        await downloadQRasPDF({userKey, driver: selectedDriver});
        dialogRef.current?.closeDialog(); // Close the dialog after download
      }
    } catch (error) {
      notify('error', 'Failed to download pdf');
    }
  }, [selectedDriver, userKey, downloadQRasPDF, notify]);

  // Automatically generate the QR code if userKey is empty and driver and user are available
  useEffect(() => {
    if (!userKey && selectedDriver && userId && !isDownloading) {
      (async () => {
        setIsDownloading(true); // Set download state
        try {
          await generateUserQr();
        } catch (error) {
          notify('error', 'Failed to generate QR:');
        }
        setIsDownloading(false); // Reset download state
      })();
    }
  }, [userKey, selectedDriver, userId, generateUserQr, isDownloading, notify]);

  // Automatically download the PDF when the dialog is open and userKey is available
  useEffect(() => {
    if (dialogRef.current?.isOpen && userKey) {
      (async () => {
        try {
          await handleDownload();
        } catch (error) {
          notify('error', 'Failed to download:');
        }
      })();
    }
  }, [userKey, handleDownload, notify]);

  useEffect(() => {
    if (startDownload && !isDownloading) {
      setIsDownloading(true);
      if (selectedDriver) {
        // Making sure driver details are available
        (async () => {
          try {
            await generateUserQr();
            await handleDownload();
          } catch (error) {
            notify('error', `A Download error occurred:`);
          }
          setStartDownload(false); // Reset download state
          setIsDownloading(false);
        })();
      } else {
        // Notify the user that driver details are not available
        notify('error', 'Driver details are not available for downloading.');
        setStartDownload(false); // Reset download state
        setIsDownloading(false);
      }
    }
  }, [
    startDownload,
    setStartDownload,
    generateUserQr,
    handleDownload,
    selectedDriver,
    isDownloading,
    notify,
  ]);

  return (
    <>
      <VantageDialog
        ref={dialogRef}
        title="Driver QR"
        declineProps={{onClick: () => dialogRef.current?.closeDialog()}}
        disableDecline={true}
        disableAccept={true}
      >
        <div ref={qrRef}>
          <QRCode value={userKey} size={256} level="L" />
        </div>
      </VantageDialog>
    </>
  );
};
