import React from 'react';
import {Typography, CardActions, Stack} from '@mui/material';
import {VantageDialogButton} from '../../../dialog';
import {RoleService} from '../../../../service';

interface TripEditActionsProps {
  isDirty: boolean;
  handleSubmit: () => Promise<void>;
  handleReset: () => Promise<void>;
}

const TripEditActions: React.FC<TripEditActionsProps> = ({
  isDirty,
  handleSubmit,
  handleReset,
}) => {
  const onSubmit = React.useCallback(async () => {
    await handleSubmit();
  }, [handleSubmit]);

  const onReset = React.useCallback(async () => {
    await handleReset();
  }, [handleReset]);

  return (
    <CardActions>
      <Stack direction="row" spacing={1}>
        <VantageDialogButton
          disabled={!RoleService.hasPermission('Edit MasterTrip', 'Edit')}
          title={
            <Typography>
              {isDirty ? 'UPDATE TRIP AND RE-CALCULATE' : 'RE-CALCULATE'}
            </Typography>
          }
          color="primary"
          dialogTitle={isDirty ? 'Edit Trip' : 'Re-calculate Trip'}
          onAccept={onSubmit}
          acceptTitle="Accept"
          declineTitle="Decline"
          variant="contained"
          enableDividers
        >
          Are you sure you want to{' '}
          <strong>
            {isDirty ? 'edit this trip' : 're-calculate this trip'}
          </strong>
          ?<br />
          {isDirty ? 'These changes will impact the data of the trip.' : ''}
        </VantageDialogButton>
        <VantageDialogButton
          disabled={
            !isDirty || !RoleService.hasPermission('Edit MasterTrip', 'Edit')
          }
          title={<Typography>RESET TRIP</Typography>}
          color="inherit"
          dialogTitle="Reset Trip"
          onAccept={onReset}
          acceptTitle="Accept"
          declineTitle="Decline"
          variant="outlined"
          enableDividers
        >
          Are you sure you want to <strong>reset this trip</strong>?
        </VantageDialogButton>
      </Stack>
    </CardActions>
  );
};

export default TripEditActions;
