import {ITripEditTripStop, ITripEditStopForm} from '../tripEdit';

export const mapToNewStop = async (
  values: ITripEditStopForm,
  sequence: number
) => {
  const {
    taskTemplateNodeType,
    siteName,
    departureTime,
    totalServiceTime,
    totalServiceTimeChangeReason,
  } = values;

  if (
    !taskTemplateNodeType?.label ||
    !siteName?.value ||
    (departureTime && sequence !== 1)
  ) {
    return undefined;
  }

  /**
   * Add negative value id since the TemplateTable requires an id and without an id the draggable sorting will break.
   * Reason for adding a negative id is just so that it won't conflict with any existing ids, and reason for using the
   * sequence is so that it also won't conflict with any newly added stops.
   */
  const newStop: ITripEditTripStop = {
    id: -sequence,
    node: {
      id: siteName?.value,
      name: siteName?.label,
      type: taskTemplateNodeType?.label,
    },
    taskTemplateNodeType: taskTemplateNodeType?.label,
    sequence,
    totalServiceTime:
      typeof totalServiceTime === 'string'
        ? parseFloat(totalServiceTime)
        : totalServiceTime ?? undefined,
    totalServiceTimeChangeReason: totalServiceTimeChangeReason ?? undefined,
    departureTime:
      sequence === 1 && departureTime ? new Date(departureTime) : undefined,
  };

  return newStop;
};
