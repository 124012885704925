import React from 'react';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import EditIcon from '@mui/icons-material/Edit';
import {Card, CardContent} from '@mui/material';
import {ReportGenerateForm} from './ReportGenerateForm';
import {ReportContext, ReportContextProps} from '../ReportContext';

export const ReportGenerate: React.FC = () => {
  const {report, submitting, loading} =
    React.useContext<ReportContextProps>(ReportContext);

  return (
    <Card>
      <CardHeader
        title="Report Generate"
        avatar={
          <Avatar>
            <EditIcon />
          </Avatar>
        }
      />
      <CardContent>
        <ReportGenerateForm
          report={report}
          submitting={submitting}
          loading={loading}
        />
      </CardContent>
    </Card>
  );
};
